import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import Avatar from "../avatar/avatar";
import colors, { background_Green } from "../../utils/colors/colors";
import moment, { updateLocale } from 'moment';
import Label from "../../utils/constants/labels";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import ICICIHelath from "../../utils/assets/icicilambard.png";
import ICICILife from "../../utils/assets/icicprodential.png";
import ErrorBoundary from '../Errorhandling/Errorhandle';
import { NegativeFinding, replaceNull } from '../../utils/commonfunction'
import { Redirect } from 'react-router-dom';
import labels from '../../utils/constants/labels';
const styles = theme => ({
    card: {
        color: window.globalConfig.color.SECONDARY_BLUE,
        fontSize: "14px",
        flex: "4 1",
        fontWeight: 500,
        borderSpacing: "0px 3.5px",
        margin: '0% auto',
        borderRadius: '8px',
        marginBottom: '10px',
    },
    row: {
        boxShadow: "0px 1px 2px 1px #e0e5eb",
        // border: `solid 1px ${window.globalConfig.color.PRIMARY_BORDER}`,
        borderRadius: "8px",
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '17% 18% 18% 15% 14% 14% 4%',
        alignItems: 'center',
        [theme.breakpoints.down("md")]: {
            gridTemplateColumns: '17% 18% 18% 15% 14% 14% 4%',
            padding: '9px 0px',
        },
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: '30% 25% 25% 20%',
        },
        [theme.breakpoints.down("xs")]: {
            gridTemplateColumns: '100% ',
            rowGap: '10px',
        }
    },
    secondRow: {
        boxShadow: "0px 1px 2px 1px #e0e5eb",
        padding: '20px 0px',
        borderRadius: "8px",
        display: 'grid',
        width: '100%',
        gridTemplateColumns: '17% 18% 18% 15% 15% 15% 3%',
        alignItems: 'center',
        [theme.breakpoints.down("md")]: {
            gridTemplateColumns: '17% 18% 18% 15% 15% 15% 3%',
            padding: '20px 0px',
        },
        [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: '35% 27% 20% 15%',
            rowGap: '10px',
        },
        [theme.breakpoints.down("xs")]: {
            gridTemplateColumns: '100%',
            rowGap: '10px',
        },
    },
    activeTitleStatus: {
        background: "rgb(231, 246, 237)",
        color: window.globalConfig.color.GREEN,
        borderRadius: '5px',
        padding: '5px',
        // fontWeight: '600',
        width: '12%',
        marginBottom: '10px',
        [theme.breakpoints.down("xs")]: {
            width: '30%',
        },
    },
    inactiveTitleStatus: {
        background: "rgb(252, 233, 233)",
        color: "red",
        borderRadius: '5px',
        padding: '5px',
        // fontWeight: '600',
        width: '12%',
        marginBottom: '10px',
        [theme.breakpoints.down("xs")]: {
            width: '30%',
        },
    },
    expirestatusTitle: {
        background: window.globalConfig.color.RosyBrown,
        color: window.globalConfig.color.BROWN,
        borderRadius: '5px',
        padding: '5px',
        // fontWeight: '600',
        width: '12%',
        marginBottom: '10px',
        [theme.breakpoints.down("xs")]: {
            width: '30%',
        },
    },
    titleButton: {
        background: window.globalConfig.color.ZERO_BACKGROUND,
        color: window.globalConfig.color.PRIMARY,
        borderRadius: '5px',
        padding: '5px',
        // fontWeight: '600',
        marginTop: '5px',
        width: '50%',
        fontSize: 10,
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    titleRow: {
        margin: "0px !important",
        padding: '5px 0px',
        fontSize: "14px !important",
        fontWeight: "normal !important",
        color: window.globalConfig.color.LABLE_GRAY,
        textAlign: 'center',
    },
    titleval: {
        margin: "0px !important",
        fontSize: "16px !important",
        color: window.globalConfig.color.BLACK_PRIMARY,
        wordBreak:"break-word"
        // fontWeight: "600",
    },
    subTitile: {
        padding: '0px 0px 5px 0px',
        fontSize: '10px',
        color: window.globalConfig.color.BLACK,
        fontWeight: "600",
    },
    heading: {
        margin: '2px 0px',
    },
    upDownArrow: {
        width: 35, height: 35, minWidth: 25, minHeight: 25, 
        color: window.globalConfig.color.LABLE_GRAY
    }

});
class InsuranceBlockContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            dropdownDownArrow: false,
        }
    }
    componentDidMount() {
    }

    render() {
        const { classes, list, } = this.props;
        var item = replaceNull(list, labels.messages.NA)
        try {
            return (
                <ErrorBoundary>
                    {/* <h2 className={classes.heading}>{this.props.flag=="Life"?Label.InsuranceReport.lifeInsurance:Label.InsuranceReport.healthInsurance}</h2> */}
                    <div style={{marginTop:20}}>
                        <div className={item.catagories == "Expired" ? classes.expirestatusTitle : item.catagories == "Active" ? classes.activeTitleStatus : classes.inactiveTitleStatus}>
                            {item.catagories != "" || item.catagories != undefined ? item.catagories : labels.messages.NA}
                        </div>
                        {item.data != null && item.data.length != 0 && item.data.map((val) => {
                            return <InsurancePolicyBlock classes={classes} val={val} flag={this.props.flag} />
                        })}

                    </div>
                </ErrorBoundary>
            )
        }
        catch (err) {
         
            return <Redirect to={Label.Path.NotFound}></Redirect>
        }
    }
}
const mapStateToProps = (state) => {
    return {
    }
}
const mapDispatchToProps = dispatch => {
    return {

    }
};
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InsuranceBlockContainer))
class InsurancePolicyBlock extends React.Component {
    constructor() {
        super();
        this.state = {
            dropdownDownArrow: false,
            show: true
        }
    }
    expandedClick = () => {
        this.setState({
            dropdownDownArrow: !this.state.dropdownDownArrow
        })
    }
    handleError = () => {
        this.setState({ show: false })
    }
    render() {
        const { classes, val, flag } = this.props

        return (
            <div className={classes.card}>
                <div className={classes.row}>
                    <div className={classes.imageContainer}>
                        <div className={classes.titleButton}>
                            {/* {val.titleBlock} */}
                            {val.PolicyFor != undefined && val.PolicyFor != null && val.PolicyFor != "" && val.PolicyFor != null ? val.PolicyFor :labels.messages.NA}
                        </div>
                        {this.state.show?
                        <img
                            // src={this.props.imageSrc} 
                            onError={this.handleError}
                            src={val.Image}
                            width="150px"
                            style={{ padding: '5px 10px' }}
                        />:<div>{val.PolicyName}</div>}
                        <div className={classes.subTitile}>
                            {val.PolicyName != undefined && val.PolicyName != null && val.PolicyName != "" && val.PolicyName != null ? val.PolicyName : labels.messages.NA}
                        </div>
                    </div>
                    <div>
                        <div className={classes.titleRow}>{Label.InsuranceReport.coverAmount}</div>
                        <div className={classes.titleval}>{val.CoverAmount != undefined && val.CoverAmount != null && val.CoverAmount != "" && val.CoverAmount != 0 && val.CoverAmount != "NA" ?NegativeFinding(val.CoverAmount,"","","","rupee") : labels.messages.NA}
                        </div>
                    </div>
                    <div>
                        <div className={classes.titleRow}>{Label.InsuranceReport.preminum}</div>
                        <div className={classes.titleval}>{val.PremiumAmount != undefined && val.PremiumAmount != null && val.PremiumAmount != "" && val.PremiumAmount != "NA" && val.PremiumAmount != 0 ?NegativeFinding(val.PremiumAmount,"","","","rupee") : labels.messages.NA}
                        </div>
                    </div>
                    <div>
                        <div className={classes.titleRow}>{Label.InsuranceReport.frequency}</div>
                        <div className={classes.titleval}>{val.Frequency != undefined && val.Frequency != null && val.Frequency != ""&& val.Frequency != "NA" ? val.Frequency : labels.messages.NA}
                        </div>
                    </div>
                    <div>
                        <div className={classes.titleRow}>{Label.InsuranceReport.renewalDate}</div>
                        <div className={classes.titleval}>{val.RenewalDate != undefined && val.RenewalDate != null && val.RenewalDate != ""&& val.RenewalDate != "NA" ? val.RenewalDate : labels.messages.NA}
                        </div>
                    </div>
                    <div>
                        <div className={classes.titleRow}>{this.props.flag == "Life" ? Label.InsuranceReport.currentValue : Label.InsuranceReport.type}</div>
                        <div className={classes.titleval}> {this.props.flag == "Life" ? val.CurrentValue != "NA"? NegativeFinding(val.CurrentValue,"","","","rupee"):val.CurrentValue : (val.Type != undefined && val.Type != null && val.Type != ""&& val.Type != "NA" ? val.Type : labels.messages.NA)}
                        </div>
                    </div>
                    <div onClick={this.expandedClick} style={{cursor:"pointer"}}>
                        {this.state.dropdownDownArrow == false ?
                            <BiChevronDown className={classes.upDownArrow} />
                            :
                            <BiChevronUp className={classes.upDownArrow} />
                        }

                    </div>
                </div>
                {this.state.dropdownDownArrow == true &&
                    <div className={classes.secondRow}>
                        <div>
                            <div className={classes.titleRow}>{Label.InsuranceReport.policyNo}</div>
                            <div className={classes.titleval}>{this.props.flag == "Life" ?
                                val.PolicyNo != undefined && val.PolicyNo != undefined && val.PolicyNo != "" && val.PolicyNo != 0 ? val.PolicyNo : labels.messages.NA
                                :
                                val.PolicyNumber != undefined && val.PolicyNumber != null && val.PolicyNumber != "" && val.PolicyNumber != 0 ? val.PolicyNumber : labels.messages.NA
                            }

                            </div>
                        </div>
                        <div>
                            <div className={classes.titleRow}>{Label.InsuranceReport.issueDate}</div>
                            <div className={classes.titleval}>{val.IssueDate != undefined && val.IssueDate != null && val.IssueDate != "" && val.IssueDate != "NA" ?val.IssueDate : labels.messages.NA}
                            </div>
                        </div>
                        <div>
                            <div className={classes.titleRow}>{Label.InsuranceReport.term}</div>
                            <div className={classes.titleval}>{this.props.flag == "Life" ?
                                val.PolicyTerm != undefined && val.PolicyTerm != null && val.PolicyTerm != "" ? val.PolicyTerm : labels.messages.NA
                                :
                                val.Term != undefined && val.Term != null && val.Term != "" ? val.Term : labels.messages.NA
                            }
                            </div>
                        </div>
                        <div>
                            <div className={classes.titleRow}>{Label.InsuranceReport.PPT}</div>
                            <div className={classes.titleval}>{val.PremiumPayingTerm != undefined && val.PremiumPayingTerm != null && val.PremiumPayingTerm != "" ? val.PremiumPayingTerm : labels.messages.NA}
                            </div>
                        </div>
                        <div>
                            <div className={classes.titleRow}>{Label.InsuranceReport.expirationDate}</div>
                            <div className={classes.titleval}>{val.ExpirationDate != undefined && val.ExpirationDate != null && val.ExpirationDate != "" && val.ExpirationDate != "NA" ? val.ExpirationDate : labels.messages.NA}
                            </div>
                        </div>
                        <div >
                        </div>
                    </div>
                }
            </div>
        )
    }
}