import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from "../../utils/constants/labels"
import Networking from '../../utils/api/apiaccess';
function getRiskQuestionReportDetails(email, name, dob, mobile) {


  // var myHeaders = new Headers();
  // myHeaders.append("api-key", labels.apiKey.apiKeys);
  // myHeaders.append("Cookie", "PHPSESSID=ourjosq9mg8p2v4iotf2b9vo2u");
  var myHeaders = new Headers();
  // myHeaders.append("Authorization", "kjsfhskjfhsjdhkjdfhdkjfh");
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    "email": email
  });

  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow'
  };

  // return fetch("https://planapi.finnovate.in/api/risk/getriskquestions", requestOptions)
  //   .then(response => response.json())
  //   .then(result => { return result })
  //   .catch(error => {
  //     return { "status": "F", "message": labels.messages.apierror }
  //   });
  return Networking.PostApi(ApiValues.RiskQuestionReport, raw,).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default getRiskQuestionReportDetails