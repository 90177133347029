// import React, { Component } from 'react'
// import { connect } from 'react-redux';
// import { bindActionCreators } from 'redux';
// import { Redirect } from 'react-router';
// import { withStyles } from '@material-ui/core';
// import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
// import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle';
// import CommonSnackbar from '../../components/snackbar/snackbar';
// import labels from '../../utils/constants/labels';
// import colors from '../../utils/colors/colors';
// import { getL5reportDetails } from "../../redux/actions/L5Report/L5ReportAction";
// import { scrollToTopPage } from '../../utils/commonfunction';
// import DatePicker from '../../components/datepickers/date_pickers';
// import CommonDropdown from '../../components/dropdown/dropdownschemes';
// import Textinput from '../../components/textinput/textinput';
// import moment from 'moment';
// import Subheader from '../../components/subheader/subheader';
// import { npsListing } from '../../redux/actions/nps/npsaction';
// import { updateAsset } from '../../redux/actions/DataEntry/common_dropdown_equity';
// import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
// import CommonButton from '../../components/custombutton/button'
// import { AlphaNumbericandSpecifiedCharValidation, amountCommaSeperator, CurrencyFormat, numbersandDeciamlOnly, replaceComma, uniqueId } from '../../utils/commonfunction';
// import getNPSDropdown from '../../api/nps/nps_dropdown';
// import getNPSDataEntry from '../../api/nps/nps_data_entry';
// import OutlinedButton from '../../components/payment/button';
// import DynamicList from '../../components/list/dynamicList';
// const styles = theme => ({

//     grid: {
//         display: 'grid',
//         gridTemplateColumns: 'repeat(4,1fr)',
//         columnGap: '20px',
//         [theme.breakpoints.down("920")]: {
//             gridTemplateColumns: 'repeat(1,1fr)',
//         }
//     },
//     flex: {
//         display: 'flex',
//         flexDirection: 'row',
//         [theme.breakpoints.down("920")]: {
//             flexDirection: 'column',
//             justifyContent: 'center',
//             alignItems: 'center'
//         }
//     },
//     sideSpace: {
//         width: '12%',
//         [theme.breakpoints.down("920")]: {
//             display: 'none'
//         }
//     },
//     mainCard: {
//         display: "flex", marginBottom: "3%",
//         boxShadow: "rgb(19 89 161 / 10%) 0px 0px 20px 0px",
//         borderRadius: 5,
//         // minHeight: "",
//         height: "auto",
//         width: "89%", marginTop: "30px",
//         padding: '4%',
//         paddingTop: "1%",
//         display: 'flex',
//         flexDirection: 'column',
//         [theme.breakpoints.down("920")]: {
//             margin: 'auto',

//         }
//     },
//     firstContainer: {
//         display: "grid",
//         gridTemplateColumns: "repeat(2,1fr)",
//         gap: 20,
//         [theme.breakpoints.down("920")]: {
//             gridTemplateColumns: "repeat(1,1fr)",
//         }
//     }
//     , secondContainerFirstHalf: {
//         display: "grid",
//         gridTemplateColumns: "repeat(3,1fr)",
//         gap: 20,
//         height: "23%",
//         [theme.breakpoints.down("920")]: {
//             gridTemplateColumns: "repeat(1,1fr)",
//         }
//     },
//     secondContainerSecondHalf: {
//         display: "grid",
//         gridTemplateColumns: "repeat(4,1fr)",
//         gap: 20,
//         [theme.breakpoints.down("920")]: {
//             gridTemplateColumns: "repeat(2,1fr)",
//         }
//     },
//     secondContainerThirdHalf: {
//         display: "grid",
//         gridTemplateColumns: "repeat(2,1fr)",
//         gap: 20,
//         [theme.breakpoints.down("920")]: {
//             gridTemplateColumns: "repeat(1,1fr)",
//         }
//     }
//     , subHeader: {
//         textAlign: 'left',
//         marginTop: '1pc',
//         color: window.globalConfig.color.LABLE_GRAY,
//         fontWeight: 500,
//         // marginLeft: 10,
//         [theme.breakpoints.down("920")]: {
//             display: "none"
//         }
//     },
//     subHeader1: {
//         textAlign: 'left',
//         color: window.globalConfig.color.LABLE_GRAY,
//         fontSize: 14,
//         marginTop: 0, marginBottom: 8,
//         // marginLeft: 10,
//         // [theme.breakpoints.down("920")]: {
//         //     display: "none"
//         // }
//     },
//     cardSeparatorLine: {
//         height: 0.5,
//         width: "100%",
//         // backgroundColor: window.globalConfig.color.AliceBlue,
//         backgroundColor: window.globalConfig.color.LABLE_GRAY,
//         marginBottom: '1pc',
//         marginTop: '6px',
//         // marginLeft: 10,
//         [theme.breakpoints.down("920")]: {
//             display: "none"
//         }
//     },
//     allocationUnitsGrid: {
//         display: "grid",
//         gridTemplateColumns: "repeat(4,1fr)",
//         gap: 5,
//         width: "50%",
//         [theme.breakpoints.down("920")]: {
//             gridTemplateColumns: "repeat(2,1fr)",
//         }
//     },
//     buttonStyle: {
//         width: "20%",
//         [theme.breakpoints.down("920")]: {
//             width: "50%"
//         }
//     },
//     popupListGrid: {
//         display: "grid",
//         gridTemplateColumns: "17% 13% 16% 12% 12% 13% 13% 2%"
//     },
//     spanmsg: {
//         width: "240%",
//         marginTop: "-10%",
//         marginLeft: "2%"
//     },
//     spanerr: {
//         color: "rgb(225, 45, 45)",
//         fontSize: "13px",
//     }

// });
// class Nps_DataEntry extends Component {
//     constructor() {
//         super();
//         this.myDiv = React.createRef();

//         this.state = {
//             currentPensionFundManagerList: [],
//             currentPensionFundManagerName: "",
//             edit: false,
//             currentPensionFundManagerID: 0,
//             currentPensionFundManagerError: "",
//             pranNo: "",
//             pranNoError: "",
//             amount: "",

//             amountError: "",
//             transactionTypeID: "",
//             transactionTypeName: "",
//             transactionTypeError: "",
//             investmentDateTemp: moment().format("DD MMM YYYY"),
//             investmentDate: "",
//             investmentDateError: "",
//             latestValueDateTemp: moment().format("DD MMM YYYY"),
//             latestValueDate: "",
//             latestValueDateError: "",
//             latestValue: "",
//             latestValueError: "",
//             anchorEl: null,
//             anchorElLatestValue: null,
//             message: "",
//             open: false,
//             equity: "",
//             equityError: "",
//             commodity: "",
//             commodityError: "",
//             other: "",
//             otherError: "",
//             gold: "",
//             goldError: "",
//             goldNav: "",
//             goldNavError: "",
//             otherNav: "",
//             otherNavError: "",
//             commodityNav: "",
//             commodityNavError: "",
//             equityNav: "",
//             equityNavError: "",
//             commonError: "",
//             valuationGoldNav: "",
//             valuationGoldNavError: "",
//             valuationOtherNav: "",
//             valuationOtherNavError: "",
//             valuationCommodityNav: "",
//             valuationCommodityNavError: "",
//             valuationEquityNav: "",
//             valuationEquityNavError: "",

//             loader: false,
//             addMoreLoader: false,
//             transactionListing: [],
//             transactionListingID: '',
//             transactionBackupListing: [],
//             transactionLoader: false,
//             transactionListingError: ""

//         }

//     }
//     componentDidMount() {
//         scrollToTopPage()
//         let data = {}
//         data.clientID = this.props.clientID
//         getNPSDropdown(data).then((res) => {
//             if (res.status == "S") {
//                 if (res.table1 != null && res.table1.length != 0) {
//                     this.setState({
//                         currentPensionFundManagerList: res.table1.map((item) => {
//                             return { "label": item.Name, "value": item.ID }
//                         })
//                     })
//                 }
//             }
//         }).catch((err) => {

//         })
//         if (this.props.transactionTypeList != "NA" && this.props.transactionTypeList.length != 0) {
//             this.setState({ transactionTypeName: "Invested", transactionTypeID: this.props.transactionTypeList.filter((item) => item.label == "Invested")[0].value })
//         }
//     }
//     clearAsset = () => {
//         this.setState({
//             currentPensionFundManagerName: "",

//             currentPensionFundManagerID: 0,
//             currentPensionFundManagerError: "",
//             pranNo: "",
//             pranNoError: "",
//             amount: "",

//             amountError: "",
//             transactionTypeID: "",
//             transactionTypeName: "",
//             transactionTypeError: "",
//             investmentDateTemp: moment().format("DD MMM YYYY"),
//             investmentDate: "",
//             investmentDateError: "",
//             latestValueDateTemp: moment().format("DD MMM YYYY"),
//             latestValueDate: "",
//             latestValueDateError: "",
//             latestValue: "",
//             latestValueError: "",
//             anchorEl: null,
//             anchorElLatestValue: null,
//             message: "",
//             open: false,
//             equity: "",
//             equityError: "",
//             commodity: "",
//             commodityError: "",
//             other: "",
//             otherError: "",
//             gold: "",
//             goldError: "",
//             goldNav: "",
//             goldNavError: "",
//             otherNav: "",
//             otherNavError: "",
//             commodityNav: "",
//             commodityNavError: "",
//             equityNav: "",
//             equityNavError: "",
//             transactionListing: [],
//             transactionListingID: '',
//             transactionBackupListing: [],
//             valuationGoldNav: "",
//             valuationGoldNavError: "",
//             valuationOtherNav: "",
//             valuationOtherNavError: "",
//             valuationCommodityNav: "",
//             valuationCommodityNavError: "",
//             valuationEquityNav: "",
//             valuationEquityNavError: "",
//         })
//     }
//     handleCurrentPensionFundManager = (val) => {
//         if (val.length != 0) {
//             this.setState({ currentPensionFundManagerID: val[0].value, currentPensionFundManagerName: val[0].label, currentPensionFundManagerError: "" })
//         }
//     }
//     handlePranNoChange = (e) => {
//         if (AlphaNumbericandSpecifiedCharValidation(e.target.value)) {
//             this.setState({ pranNo: e.target.value, pranNoError: "" })
//         }
//     }
//     handleInvestmentDateClick = (e) => {
//         this.setState({ anchorEl: e.currentTarget })
//     }
//     handleInvestmentDateClose = () => {
//         this.setState({ anchorEl: null })
//     }
//     handleInvestmentDateChange = (value) => {
//         this.setState({ investmentDateTemp: moment(value).format("DD MMM YYYY") })

//     }
//     handleInvestmentDateApply = (date) => {
//         this.setState({ investmentDate: moment(date).format("DD MMM YYYY"), investmentDateError: "" })
//     }
//     // handleLatestValueChange = (e) => {
//     //     this.setState({ latestValue: e.target.value, latestValueError: "" })
//     // }
//     handleLatestValueDateChange = (value) => {
//         this.setState({ latestValueDateTemp: moment(value).format("DD MMM YYYY") })

//     }
//     handleLatestValueDateApply = (date) => {
//         this.setState({ latestValueDate: moment(date).format("DD MMM YYYY"), latestValueDateError: "" })

//     }
//     handleLatestValueDateClick = (e) => {
//         this.setState({ anchorElLatestValue: e.currentTarget })
//     }
//     handleLatestValueDateClose = () => {
//         this.setState({ anchorElLatestValue: null })
//     }
//     handleAmountChange = (e) => {
//         let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
//         if (numbersandDeciamlOnly(removeComma.trim(), 14)) {
//             this.setState({ amount: amountCommaSeperator(removeComma.trim()), amountError: "" })
//         }

//     }
//     handleEquityChange = (e) => {
//         let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

//         if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
//             this.setState({ equity: amountCommaSeperator(removeComma.trim()), equityError: "" })
//         }
//     }
//     handleCommodityChange = (e) => {
//         let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

//         if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
//             this.setState({ commodity: amountCommaSeperator(removeComma.trim()), commodityError: "" })
//         }
//     }
//     handleGoldChange = (e) => {
//         let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

//         if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
//             this.setState({ gold: amountCommaSeperator(removeComma.trim()), goldError: "" })
//         }
//     }
//     handleOtherChange = (e) => {
//         let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

//         if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
//             this.setState({ other: amountCommaSeperator(removeComma.trim()), otherError: "" })
//         }
//     }
//     handleEquityNavChange = (e) => {
//         let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

//         if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
//             this.setState({ equityNav: amountCommaSeperator(removeComma.trim()), equityNavError: "" })
//         }
//     }
//     handleCommodityNavChange = (e) => {
//         let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
//         if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
//             this.setState({ commodityNav: amountCommaSeperator(removeComma.trim()), commodityNavError: "" })
//         }
//     }
//     handleGoldNavChange = (e) => {
//         let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

//         if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
//             this.setState({ goldNav: amountCommaSeperator(removeComma.trim()), goldNavError: "" })
//         }
//     }
//     handleOtherNavChange = (e) => {
//         let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

//         if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
//             this.setState({ otherNav: amountCommaSeperator(removeComma.trim()), otherNavError: "" })
//         }
//     }

//     handleTransactionTypeChange = (val) => {
//         // let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

//         // if (numbersandDeciamlOn/ly(removeComma.trim())) {
//         this.setState({ transactionTypeName: val[0].label, transactionTypeID: val[0].value, transactionTypeError: "" })
//         // }
//     }
//     handleValuationEquityNavChange = (e) => {
//         let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

//         if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
//             this.setState({ valuationEquityNav: amountCommaSeperator(removeComma.trim()), valuationEquityNavError: "" })
//         }
//     }
//     handleValuationCommodityNavChange = (e) => {
//         let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

//         if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
//             this.setState({ valuationCommodityNav: amountCommaSeperator(removeComma.trim()), valuationCommodityNavError: "" })
//         }

//     }
//     handleValuationGoldNavChange = (e) => {
//         let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

//         if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
//             this.setState({ valuationGoldNav: amountCommaSeperator(removeComma.trim()), valuationGoldNavError: "" })
//         }
//     }
//     handleValuationOtherNavChange = (e) => {
//         let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

//         if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
//             this.setState({ valuationOtherNav: amountCommaSeperator(removeComma.trim()), valuationOtherNavError: "" })
//         }
//     }
//     handleSubmit = (type) => {
//         let isValid = true
//         if (this.state.currentPensionFundManagerID == 0) {
//             isValid = false
//             this.setState({ currentPensionFundManagerError: labels.messages.required, })
//         }
//         if (this.state.pranNo.trim() == "") {
//             isValid = false
//             this.setState({ pranNoError: labels.messages.required, })
//         }
//         if (this.state.latestValueDate.trim() == "") {

//             isValid = false
//             this.setState({ latestValueDateError: labels.messages.required, })
//         }
//         if (this.state.valuationEquityNav.trim() == "") {
//             isValid = false
//             this.setState({ valuationEquityNavError: labels.messages.required, })
//         }
//         if (this.state.valuationCommodityNav.trim() == "") {
//             isValid = false
//             this.setState({ valuationCommodityNavError: labels.messages.required, })
//         }
//         if (this.state.valuationGoldNav.trim() == "") {
//             isValid = false
//             this.setState({ valuationGoldNavError: labels.messages.required, })
//         }
//         if (this.state.valuationOtherNav.trim() == "") {
//             isValid = false
//             this.setState({ valuationOtherNavError: labels.messages.required, })
//         }
//         let TransactionData = []
//         if (this.state.amount != "" || this.state.investmentDate != "" || this.state.equity != "" || this.state.equityNav != "" || this.state.gold != "" || this.state.goldNav != "" || this.state.other != "" || this.state.otherNav != "" || this.state.commodity != "" || this.state.commodityNav != "") {
//             let res = this.handleAddTransaction()
//             //isValid = res.valid

//             TransactionData = res.response

//         } else {
//             if (this.state.transactionBackupListing.length == 0) {
//                 let res = this.handleAddTransaction()
//                 isValid = res.valid
//                 TransactionData = res.response
//                 this.setState({ openSnackBar: true, message: "Please Add Transaction", severity: "warning" })
//             } else {
//                 TransactionData = this.state.transactionBackupListing
//             }
//         }

//         if (isValid) {
//             // console.log(TransactionData)
//             let transaction = TransactionData.map((item) => {
//                 return {
//                     "Amount": replaceComma(item.amount), "Date": item.investmentDate, "TransactionType": item.transactionTypeID,
//                     "EqUnits": replaceComma(item.equityUnits), "EqNAV": replaceComma(item.equityNav), "CoUnits": replaceComma(item.commodityUnits),
//                     "CoNAV": replaceComma(item.commodityNav), "GoUnits": replaceComma(item.goldUnits), "GoNAV": replaceComma(item.goldNav),
//                     "OtUnits": replaceComma(item.otherUnits), "OtNAV": replaceComma(item.otherNav)
//                 }
//             })
//             let data = {}
//             data.id = 0
//             data.clientID = this.props.logindetails.ClientID
//             data.accountTypeID = this.props.logindetails.L4_AccountTypeID
//             data.accountID = this.props.L5ID
//             data.schemeId = this.state.currentPensionFundManagerID
//             data.valuation = 0
//             data.valuationDate = this.state.latestValueDate
//             data.createdBy = this.props.clientID
//             data.flag = 1
//             data.veAllocation = Number(replaceComma(this.state.valuationEquityNav))
//             data.vcAllocation = Number(replaceComma(this.state.valuationCommodityNav))
//             data.vgallocation = Number(replaceComma(this.state.valuationGoldNav))
//             data.voAllocation = Number(replaceComma(this.state.valuationCommodityNav))
//             data.pranno = this.state.pranNo
//             data.npsTransactionParam = JSON.stringify(transaction)
//             if (type == "save") {
//                 this.setState({ loader: true })
//             } else {
//                 this.setState({ addMoreLoader: true })
//             }
//             getNPSDataEntry(data).then((res) => {
//                 if (type == "save") {
//                     this.setState({ loader: false })
//                 } else {
//                     this.setState({ addMoreLoader: false })
//                 }
//                 if (res.status == "S") {

//                     this.clearAsset()
//                     if (type == "save") {
//                         this.props.history.push(labels.Path.Nps)
//                     }
//                     this.setState({ openSnackBar: true, message: res.message, severity: "success" })
//                 } else {
//                     this.setState({ openSnackBar: true, message: res.message, severity: "warning" })

//                 }

//             })
//         }

//     }
//     handleEditTransaction = (item) => {
//         // if(this.state.transactionListingID)
//         this.setState({
//             amount: item.Amount.name,
//             transactionTypeID: item.TransactionType.name,
//             transactionTypeName: item.TransactionType.name,
//             investmentDate: item.InvestmentDate.name,
//             equity: item.Equity.name,
//             equityNav: item.Equity.subHeader1,
//             gold: item.Gold.name,
//             goldNav: item.Gold.subHeader1,
//             other: item.Other.name,
//             otherNav: item.Other.subHeader1,
//             commodity: item.Other.name,
//             commodityNav: item.Other.subHeader1,
//             transactionListingID: item.ID.name,
//             edit: true
//         })
//         // this.handleDeleteTransaction(item)
//     }
//     clearTransaction = () => {
//         this.setState({
//             equity: "",
//             equityError: "",
//             commodity: "",
//             commodityError: "",
//             other: "",
//             otherError: "",
//             gold: "",
//             goldError: "",
//             goldNav: "",
//             goldNavError: "",
//             otherNav: "",
//             otherNavError: "",
//             commodityNav: "",
//             commodityNavError: "",
//             equityNav: "",
//             equityNavError: "",
//             amount: '',
//             amountError: '',
//             investmentDate: '',
//             investmentDateError: '',
//             // transactionTypeID: '',
//             // transactionTypeName: '',
//             // transactionTypeError: ''
//         })
//         if (this.props.transactionTypeList != "NA" && this.props.transactionTypeList.length != 0) {
//             this.setState({ transactionTypeName: "Invested", transactionTypeID: this.props.transactionTypeList.filter((item) => item.label == "Invested")[0].value })
//         }

//     }
//     handleAddTransaction = () => {
//         let isValid = true
//         let data = []
//         this.setState({ edit: false })
//         if (this.state.amount == "") {
//             isValid = false
//             this.setState({ amountError: labels.messages.required, })
//         }
//         // if (this.state.transactionTypeID == "") {
//         //     isValid = false
//         //     this.setState({ transactionTypeError: labels.messages.required, })
//         // }
//         if (this.state.investmentDate == "") {
//             isValid = false
//             this.setState({ investmentDateError: labels.messages.required, })
//         }
//         if (this.state.equity == "") {
//             isValid = false
//             this.setState({ equityError: labels.messages.required, })
//         }
//         if (this.state.equityNav == "") {
//             isValid = false
//             this.setState({ equityNavError: labels.messages.required, })
//         }
//         if (this.state.commodity == "") {
//             isValid = false
//             this.setState({ commodityError: labels.messages.required, })
//         }
//         if (this.state.commodityNav == "") {
//             isValid = false
//             this.setState({ commodityNavError: labels.messages.required, })
//         }
//         if (this.state.gold == "") {
//             isValid = false
//             this.setState({ goldError: labels.messages.required, })
//         }
//         if (this.state.goldNav == "") {
//             isValid = false
//             this.setState({ goldNavError: labels.messages.required, })
//         }
//         if (this.state.other == "") {
//             isValid = false
//             this.setState({ otherError: labels.messages.required, })
//         }
//         if (this.state.otherNav == "") {
//             isValid = false
//             this.setState({ otherNavError: labels.messages.required, })
//         }

//         if (this.state.equity == "" && this.state.commodity == "" && this.state.gold == "" && this.state.other == "") {
//             isValid = false
//             this.setState({
//                 equityError: labels.messages.required,
//                 commodityError: labels.messages.required,
//                 goldError: labels.messages.required,
//                 otherError: labels.messages.required
//             })
//         }
//         // else if (Number(replaceComma(this.state.equity)) == 0 && Number(replaceComma(this.state.commodity)) == 0 && Number(replaceComma(this.state.gold)) == 0 && Number(replaceComma(this.state.other)) == 0) {
//         //     isValid = false
//         //     // this.setState({ equityError : labels.messages.Amount,
//         //     //     commodityError : labels.messages.Amount,
//         //     //     goldError : labels.messages.Amount,
//         //     //     otherError : labels.messages.Amount
//         //     // })
//         //     //this.setState({ openSnackBar: true, message: labels.messages.Error, severity: 'warning'})
//         //     this.setState({ commonError: labels.messages.Error })
//         // }
//         // else {
//         //     isValid = true
//         //     this.setState({
//         //         equityError: "",
//         //         commodityError: "",
//         //         goldError: "",
//         //         otherError: "",
//         //         commonError: ""
//         //     })
//         // }


//         if (isValid) {
//             let id = uniqueId()
//             this.setState({ transactionListingError: "" })
//             if (this.state.transactionBackupListing.length != 0 && this.state.transactionBackupListing.filter((value) => value.ID == this.state.transactionListingID).length != 0) {

//                 let modifiedArray = [...this.state.transactionBackupListing.filter((val) => val.ID != this.state.transactionListingID), {
//                     'ID': this.state.transactionListingID,
//                     'transactionType': this.state.transactionTypeName, 'investmentDate': this.state.investmentDate, 'amount': this.state.amount,
//                     'equityUnits': this.state.equity, 'equityNav': this.state.equityNav,
//                     'commodityUnits': this.state.commodity, 'commodityNav': this.state.commodityNav,
//                     'goldUnits': this.state.gold, 'goldNav': this.state.goldNav,
//                     'otherUnits': this.state.other, 'otherNav': this.state.otherNav
//                 }]
//                 data = modifiedArray
//                 this.setState({
//                     transactionListingID: '',
//                     transactionBackupListing: modifiedArray,
//                     transactionListing: [...modifiedArray.map((items) => {
//                         return {
//                             "TransactionType": { name: items.transactionType, subHeader1: "", type: "" },
//                             "InvestmentDate": { name: items.investmentDate, subHeader1: "", type: "" }
//                             , "Amount": { name: items.amount, subHeader1: "", type: "amount" }
//                             , "Equity": { name: items.equityUnits, subHeader1: items.equityNav, subHeaderType: 'amount', type: "amount" }
//                             , "Commodity": { name: items.commodityUnits, subHeader1: items.commodityNav, subHeaderType: 'amount', type: "amount" }
//                             , "Gold": { name: items.goldUnits, subHeader1: items.goldNav, subHeaderType: 'amount', type: "amount" }
//                             , "Other": { name: items.otherUnits, subHeader1: items.otherNav, subHeaderType: 'amount', type: "amount" }
//                             , 'ID': { name: items.ID, subHeader1: '', type: '' }
//                         }
//                     }),]

//                 })
//                 // this.setState({ openSnackBar: true, message: labels.messages.transact, severity: 'success'})
//             } else {
//                 data = [...this.state.transactionBackupListing, {
//                     'ID': id,
//                     'transactionTypeID': this.state.transactionTypeID,
//                     'transactionType': this.state.transactionTypeName, 'investmentDate': this.state.investmentDate, 'amount': this.state.amount,
//                     'equityUnits': this.state.equity, 'equityNav': this.state.equityNav,
//                     'commodityUnits': this.state.commodity, 'commodityNav': this.state.commodityNav,
//                     'goldUnits': this.state.gold, 'goldNav': this.state.goldNav,
//                     'otherUnits': this.state.other, 'otherNav': this.state.otherNav
//                 }]
//                 this.setState({
//                     transactionBackupListing: [...this.state.transactionBackupListing, {
//                         'ID': id,
//                         'transactionTypeID': this.state.transactionTypeID,
//                         'transactionType': this.state.transactionTypeName, 'investmentDate': this.state.investmentDate, 'amount': this.state.amount,
//                         'equityUnits': this.state.equity, 'equityNav': this.state.equityNav,
//                         'commodityUnits': this.state.commodity, 'commodityNav': this.state.commodityNav,
//                         'goldUnits': this.state.gold, 'goldNav': this.state.goldNav,
//                         'otherUnits': this.state.other, 'otherNav': this.state.otherNav
//                     }],
//                     transactionListing: [...this.state.transactionListing, {
//                         "TransactionType": { name: this.state.transactionTypeName, subHeader1: "", type: "" },
//                         "InvestmentDate": { name: this.state.investmentDate, subHeader1: "", type: "" }
//                         , "Amount": { name: this.state.amount, subHeader1: "", type: "amount" }
//                         , "Equity": { name: this.state.equity, subHeader1: this.state.equityNav, subHeaderType: 'amount', type: "amount" }
//                         , "Commodity": { name: this.state.commodity, subHeader1: this.state.commodityNav, subHeaderType: 'amount', type: "amount" }
//                         , "Gold": { name: this.state.gold, subHeader1: this.state.goldNav, subHeaderType: 'amount', type: "amount" }
//                         , "Other": { name: this.state.other, subHeader1: this.state.otherNav, subHeaderType: 'amount', type: "amount" }
//                         , 'ID': { name: id, subHeader1: '', type: '' }
//                     }]

//                 })
//                 this.setState({ openSnackBar: true, message: labels.messages.transact, severity: 'success' })
//             }

//             this.clearTransaction()
//         }
//         return { valid: isValid, response: data }
//     }
//     handleDeleteTransaction = (item) => {
//         this.setState({ transactionBackupListing: this.state.transactionBackupListing.filter((value) => value.ID != item.ID.name) })
//         this.setState({
//             transactionListing: this.state.transactionBackupListing.filter((value) => value.ID != item.ID.name).map((val) => {
//                 return {
//                     "TransactionType": { name: val.transactionType, subHeader1: "", type: "" },
//                     "InvastmentDate": { name: val.investmentDate, subHeader1: "", type: "" }
//                     , "Amount": { name: val.amount, subHeader1: "", type: "amount" }
//                     , "Equity": { name: val.equityUnits, subHeader1: val.equityNav, subHeaderType: 'amount', type: "amount" }
//                     , "Commodity": { name: val.commodityUnits, subHeader1: val.commodityNav, subHeaderType: 'amount', type: "amount" }
//                     , "Gold": { name: val.goldUnits, subHeader1: val.goldNav, subHeaderType: 'amount', type: "amount" }
//                     , "Other": { name: val.otherUnits, subHeader1: val.otherNav, subHeaderType: 'amount', type: "amount" }
//                     , 'ID': { name: val.ID, subHeader1: '', type: '' }
//                 }
//             })
//         })
//     }
//     handleFocus = (event) => event.target.select();
//     handleToastClose = () => {
//         this.setState({ openSnackBar: false })
//     }
//     render() {
//         const { classes } = this.props;
//         //   
//         try {
//             return (
//                 <ErrorBoundary>
//                     <Subheader heading={labels.productList.NPS} description={labels.Transact.aifContent} />
//                     <div className={classes.flex}>
//                         <div className={classes.sideSpace}></div>
//                         <div className={classes.mainCard} ref={this.myDiv}>
//                             <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between" }}>
//                                 <div className={classes.subHeader}>{labels.Nps.addNpsInformation}</div>
//                             </div>
//                             <div className={classes.cardSeparatorLine}></div>
//                             <div className={classes.firstContainer}>
//                                 <CommonDropdown
//                                     //  autoselect={true}
//                                     handleOnChange={this.handleCurrentPensionFundManager}
//                                     value={this.state.currentPensionFundManagerName}
//                                     error={this.state.currentPensionFundManagerError}
//                                     type={"normalwithsearch"}
//                                     id="ddl_broker"
//                                     placeholder={labels.Nps.currentPensionFundManagerPlaceholder}
//                                     label={labels.Nps.currentPensionFundManager}
//                                     height={190}

//                                     List={this.state.currentPensionFundManagerList}
//                                 // value={this.state.broker}
//                                 />
//                                 <Textinput handleChange={this.handlePranNoChange}
//                                     value={this.state.pranNo}
//                                     error={this.state.pranNoError}
//                                     page={"normal"} label={labels.Nps.pranNo}
//                                     placeholder={labels.Nps.pranNoPlaceholder}
//                                 />
//                             </div>
//                             <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between" }}>
//                                 <div className={classes.subHeader}>{labels.Nps.transaction}</div>
//                             </div>
//                             <div className={classes.cardSeparatorLine}></div>
//                             <div className={classes.secondContainerFirstHalf}>
//                                 <Textinput handleChange={this.handleAmountChange}
//                                     value={this.state.amount}

//                                     error={this.state.amountError}
//                                     align={"right"}
//                                     otherProps={{ autoFocus: true, name: "amount", }}

//                                     page={"normal"} label={labels.Nps.amount}
//                                     placeholder={labels.messages.amountZeroPlaceholder}
//                                 />
//                                 <CommonDropdown
//                                     //  autoselect={true}
//                                     handleOnChange={this.handleTransactionTypeChange}
//                                     value={this.state.transactionTypeName}
//                                     error={this.state.transactionTypeError}
//                                     type={"normalwithsearch"}
//                                     disabled={this.state.transactionBackupListing.length == 0 || this.state.edit ? true : false}
//                                     id="ddl_transactiontype"
//                                     placeholder={labels.Nps.transactionTypePlaceholder}
//                                     label={labels.Nps.transactionType}
//                                     height={190}
//                                     List={this.props.transactionTypeList}
//                                 // value={this.state.broker}
//                                 />
//                                 <DatePicker
//                                     handleClick={(e) => this.handleInvestmentDateClick(e)}
//                                     handleApply={this.handleInvestmentDateApply}
//                                     showingdate={this.state.investmentDateTemp}
//                                     dateChange={this.handleInvestmentDateChange}
//                                     handleClose={this.handleInvestmentDateClose}
//                                     anchorEl={this.state.anchorEl}
//                                     error={this.state.investmentDateError}
//                                     maxdate={moment().format("DD MMM YYYY")}
//                                     // allowed={false} 
//                                     id="dp_aifInvestmentDate"
//                                     label={labels.Nps.investmentDate}
//                                     placeholder={labels.Nps.investmentDatePlaceholder}
//                                     date={this.state.investmentDate} />

//                             </div>
//                             <div className={classes.subHeader1}>{labels.Nps.allocationUnits}</div>

//                             <div className={classes.allocationUnitsGrid}>
//                                 <Textinput handleChange={this.handleEquityChange}
//                                     value={this.state.equity}
//                                     error={this.state.equityError}
//                                     otherProps={{ autoFocus: true, }}
//                                     align={"right"}
//                                     paddingAlign={10}
//                                     page={"sideLabel"} label={labels.Nps.equity}
//                                 //  placeholder={labels.Nps.latestValuePlaceholder}
//                                 />
//                                 <Textinput handleChange={this.handleCommodityChange}
//                                     value={this.state.commodity}
//                                     error={this.state.commodityError}
//                                     otherProps={{ autoFocus: true, }}
//                                     align={"right"}
//                                     paddingAlign={10}
//                                     page={"sideLabel"} label={labels.Nps.commodity}
//                                 //  placeholder={labels.Nps.latestValuePlaceholder}
//                                 />
//                                 <Textinput handleChange={this.handleGoldChange}
//                                     value={this.state.gold}
//                                     error={this.state.goldError}
//                                     otherProps={{ autoFocus: true, }}
//                                     align={"right"}
//                                     paddingAlign={10}
//                                     page={"sideLabel"} label={labels.Nps.gold}
//                                 //placeholder={labels.Nps.latestValuePlaceholder}
//                                 />
//                                 <Textinput handleChange={this.handleOtherChange}
//                                     value={this.state.other}
//                                     error={this.state.otherError}
//                                     otherProps={{ autoFocus: true, }}
//                                     align={"right"}
//                                     paddingAlign={10}
//                                     page={"sideLabel"} label={labels.Nps.other}
//                                 //  placeholder={labels.Nps.latestValuePlaceholder}
//                                 />
//                                 <div className={classes.spanmsg}>
//                                     <span className={classes.spanerr}>{this.state.commonError}</span>
//                                 </div>
//                             </div>
//                             <div className={classes.subHeader1} >{labels.Nps.allocationNav}</div>

//                             <div className={classes.allocationUnitsGrid}>
//                                 <Textinput handleChange={this.handleEquityNavChange}
//                                     value={this.state.equityNav}
//                                     error={this.state.equityNavError}
//                                     otherProps={{ autoFocus: true, }}
//                                     paddingAlign={10}
//                                     align={"right"}
//                                     page={"sideLabel"} label={labels.Nps.equity}
//                                 //  placeholder={labels.Nps.latestValuePlaceholder}
//                                 />
//                                 <Textinput handleChange={this.handleCommodityNavChange}
//                                     value={this.state.commodityNav}
//                                     error={this.state.commodityNavError}
//                                     align={"right"}
//                                     paddingAlign={10}
//                                     otherProps={{ autoFocus: true, }}
//                                     page={"sideLabel"} label={labels.Nps.commodity}
//                                 //  placeholder={labels.Nps.latestValuePlaceholder}
//                                 />
//                                 <Textinput handleChange={this.handleGoldNavChange}
//                                     value={this.state.goldNav}
//                                     error={this.state.goldNavError}
//                                     align={"right"}
//                                     paddingAlign={10}
//                                     otherProps={{ autoFocus: true, }}
//                                     page={"sideLabel"} label={labels.Nps.gold}
//                                 //placeholder={labels.Nps.latestValuePlaceholder}
//                                 />
//                                 <Textinput handleChange={this.handleOtherNavChange}
//                                     value={this.state.otherNav}
//                                     error={this.state.otherNavError}
//                                     align={"right"}
//                                     paddingAlign={10}
//                                     otherProps={{ autoFocus: true, }}
//                                     page={"sideLabel"} label={labels.Nps.other}
//                                 //  placeholder={labels.Nps.latestValuePlaceholder}
//                                 />
//                             </div>
//                             <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
//                                 <div style={{ color: window.globalConfig.color.red, fontSize: 12 }}>{this.state.transactionListingError}</div>
//                                 <div style={{ textAlignLast: "right", marginLeft: 10, marginTop: 10, marginBottom: 10 }}>
//                                     <OutlinedButton text={labels.Nps.addTransaction} onClick={this.handleAddTransaction} />
//                                 </div>
//                             </div>

//                             {this.state.transactionBackupListing.length != 0 && <>
//                                 <div className={classes.popupListGrid} style={{ fontSize: 14, textTransform: "uppercase", color: window.globalConfig.color.LABLE_GRAY, letterSpacing: 2, marginBottom: 10, textAlign: 'right' }}>
//                                     <div></div> <div></div> <div></div>
//                                     <div>{labels.Nps.equity}</div>
//                                     <div>{labels.Nps.commodity}</div>
//                                     <div>{labels.Nps.gold}</div>
//                                     <div>{labels.Nps.other}</div>
//                                 </div>
//                                 <DynamicList loader={this.state.transactionLoader} list={this.state.transactionListing} grid={classes.popupListGrid} header={[{ "label": "Transaction Type", "align": "left" }, { "label": "Investment Date", "align": "left" }, { "label": "Amount", "align": "right" }, { "label": "Units", "subLabel": "Nav", "align": "right" }, { "label": "Units", "subLabel": "Nav", "align": "right" }, { "label": "Units", "subLabel": "Nav", "align": "right" }, { "label": "Units", "subLabel": "Nav", "align": "right" }]} count={7} enableIcon={true} popupList={[{ 'name': 'Edit', 'onClick': this.handleEditTransaction }, { 'name': 'Delete', 'onClick': this.handleDeleteTransaction }]}></DynamicList>
//                             </>}
//                             <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between" }}>
//                                 <div className={classes.subHeader}>{labels.Nps.valuation}</div>
//                             </div>
//                             <div className={classes.cardSeparatorLine}></div>
//                             <div className={classes.secondContainerThirdHalf}>
//                                 <div>
//                                     <div className={classes.subHeader1} >{labels.Nps.valuationNav}</div>

//                                     <div className={classes.allocationUnitsGrid}>
//                                         <Textinput handleChange={this.handleValuationEquityNavChange}
//                                             value={this.state.valuationEquityNav}
//                                             error={this.state.valuationEquityNavError}
//                                             align={"right"}
//                                             paddingAlign={10}
//                                             otherProps={{ autoFocus: true, }}
//                                             page={"sideLabel"} label={labels.Nps.equity}
//                                         //  placeholder={labels.Nps.latestValuePlaceholder}
//                                         />
//                                         <Textinput handleChange={this.handleValuationCommodityNavChange}
//                                             value={this.state.valuationCommodityNav}
//                                             error={this.state.valuationCommodityNavError}
//                                             otherProps={{ autoFocus: true, }}
//                                             align={"right"}
//                                             paddingAlign={10}
//                                             page={"sideLabel"} label={labels.Nps.commodity}
//                                         //  placeholder={labels.Nps.latestValuePlaceholder}
//                                         />
//                                         <Textinput handleChange={this.handleValuationGoldNavChange}
//                                             value={this.state.valuationGoldNav}
//                                             error={this.state.valuationGoldNavError}
//                                             otherProps={{ autoFocus: true, }}
//                                             align={"right"}
//                                             paddingAlign={10}
//                                             page={"sideLabel"} label={labels.Nps.gold}
//                                         //placeholder={labels.Nps.latestValuePlaceholder}
//                                         />
//                                         <Textinput handleChange={this.handleValuationOtherNavChange}
//                                             value={this.state.valuationOtherNav}
//                                             error={this.state.valuationOtherNavError}
//                                             otherProps={{ autoFocus: true }}
//                                             align={"right"}
//                                             paddingAlign={10}
//                                             page={"sideLabel"} label={labels.Nps.other}
//                                         //  placeholder={labels.Nps.latestValuePlaceholder}
//                                         />
//                                     </div>
//                                 </div>
//                                 <div>
//                                     <div className={classes.subHeader1}>{labels.Nps.latestValueDate}</div>

//                                     <DatePicker
//                                         handleClick={(e) => this.handleLatestValueDateClick(e)}
//                                         handleApply={this.handleLatestValueDateApply}
//                                         showingdate={this.state.latestValueDateTemp}
//                                         dateChange={this.handleLatestValueDateChange}
//                                         handleClose={this.handleLatestValueDateClose}
//                                         anchorEl={this.state.anchorElLatestValue}
//                                         error={this.state.latestValueDateError}
//                                         maxdate={moment().format("DD MMM YYYY")}
//                                         // allowed={false} 
//                                         id="dp_latestvalue"
//                                         // label={""}
//                                         placeholder={labels.Nps.latestValueDatePlaceholder}
//                                         date={this.state.latestValueDate} />

//                                 </div>
//                             </div>

//                             <div style={{ marginTop: "2%", display: "flex", flexDirection: "row", marginBottom: "2%", justifyContent: "flex-end" }} >
//                                 <div className={classes.buttonStyle}>
//                                     <CommonButton name={labels.Nps.save} page={"primary"}
//                                         loader={this.state.loader}
//                                         disabled={this.state.loader || this.state.addLoader}
//                                         loading={"true"}
//                                         onClick={() => this.handleSubmit("save")} />
//                                     {/* <CommonButton name={"Cancel"} onClick={this.handleClose} /> */}
//                                 </div>
//                                 <div style={{ marginLeft: "5%" }} className={classes.buttonStyle}>
//                                     <CommonButton name={labels.Nps.saveAndMore} page={"primary"}
//                                         loader={this.state.addLoader}
//                                         loading={"true"}
//                                         disabled={this.state.loader || this.state.addLoader}

//                                         onClick={() => this.handleSubmit("addMore")} />
//                                 </div>

//                             </div>
//                         </div>


//                         <div className={classes.sideSpace}></div>

//                     </div>
//                     <CommonSnackbar message={this.state.message} open={this.state.openSnackBar} severity={this.state.severity} handlesnanckClose={this.handleToastClose} />

//                 </ErrorBoundary>
//             )

//         } catch (err) {
//             return <Redirect to={labels.Path.NotFound} />
//         }
//     }
// }
// const mapStateToProps = state => {
//     //
//     return {
//         sessionID: state.Login.sessionID,
//         clientID: state.Login.logindetails.ClientID,
//         logindetails: state.Login.logindetails,
//         L5ID: state.Login.L5ID,
//         transactionTypeList: state.Dataentry.npsAndInsuranceDropdownList != null && state.Dataentry.npsAndInsuranceDropdownList.length != 0 && state.Dataentry.npsAndInsuranceDropdownList.table1 != null && state.Dataentry.npsAndInsuranceDropdownList.table1.length != 0 ? state.Dataentry.npsAndInsuranceDropdownList.table1.map((item) => {
//             return { "label": item.TEXT, "value": item.VALUE }
//         }) : [],

//     }
// }
// const mapDispatchToProps = dispatch => {
//     return bindActionCreators({ getL5reportDetails, npsListing }, dispatch)
// }
// const mergedStyle = combineStyles(styles, CommonTableStyles)
// export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(Nps_DataEntry))
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router';
import { withStyles } from '@material-ui/core';
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle';
import CommonSnackbar from '../../components/snackbar/snackbar';
import labels from '../../utils/constants/labels';
import { getL5reportDetails } from "../../redux/actions/L5Report/L5ReportAction";
import { numbersOnly, scrollToTopPage,NameValidation } from '../../utils/commonfunction';
import DatePicker from '../../components/datepickers/date_pickers';
import CommonDropdown from '../../components/dropdown/dropdownschemes';
import Textinput from '../../components/textinput/textinput';
import moment from 'moment';
import Subheader from '../../components/subheader/subheader';
import { npsListing } from '../../redux/actions/nps/npsaction';
import { updateAsset } from '../../redux/actions/DataEntry/common_dropdown_equity';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import CommonButton from '../../components/custombutton/button'
import { AlphaNumbericandSpecifiedCharValidation, amountCommaSeperator, CurrencyFormat, numbersandDeciamlOnly, replaceComma, uniqueId } from '../../utils/commonfunction';
import getNPSDropdown from '../../api/nps/nps_dropdown';
import getNPSDataEntry from '../../api/nps/nps_data_entry';
import OutlinedButton from '../../components/payment/button';
import DynamicList from '../../components/list/dynamicList';
import CommonCheckbox from '../../components/checkbox/checkbox';
// import { MenuHideShow } from '../../redux/actions/cart/menuhideandshow';

const styles = theme => ({

    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4,1fr)',
        columnGap: '20px',
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: 'repeat(1,1fr)',
        }
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down("920")]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    sideSpace: {
        width: '12%',
        [theme.breakpoints.down("920")]: {
            display: 'none'
        }
    },
    mainCard: {
        display: "flex", marginBottom: "3%",
        boxShadow: "rgb(19 89 161 / 10%) 0px 0px 20px 0px",
        borderRadius: 5,
        // minHeight: "",
        height: "auto",
        width: "214%", marginTop: "30px",
        padding: '4%',
        paddingTop: "1%",
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down("920")]: {
            margin: 'auto',

        }
    },
    firstContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        gap: 20,
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(1,1fr)",
        },
        padding: '0 0 20px 0'
    }
    , secondContainerFirstHalf: {
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        gap: 20,
        height: "23%",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(1,1fr)",
        }
    },
    secondContainerSecondHalf: {
        display: "grid",
        gridTemplateColumns: "repeat(4,1fr)",
        gap: 20,
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(2,1fr)",
        }
    },
    secondContainerThirdHalf: {
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)",
        gap: 20,
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(1,1fr)",
        }
    },
    secondContainerFourthHalf: {
        display: "grid",
        gridTemplateColumns: "repeat(4,1fr)",
        gap: 20,
        height: "23%",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(1,1fr)",
        }
    }
    , subHeader: {
        textAlign: 'left',
        marginTop: '1pc',
        color: window.globalConfig.color.LABLE_GRAY,
        fontWeight: 500,
        // marginLeft: 10,
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
    subHeader1: {
        textAlign: 'left',
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: 14,
        marginTop: 0,// marginBottom: 6,
        paddingBottom: 6
        // marginLeft: 10,
        // [theme.breakpoints.down("920")]: {
        //     display: "none"
        // }
    },
    cardSeparatorLine: {
        height: 0.5,
        width: "100%",
        // backgroundColor: window.globalConfig.color.AliceBlue,
        backgroundColor: window.globalConfig.color.LABLE_GRAY,
        marginBottom: '1pc',
        marginTop: '6px',
        // marginLeft: 10,
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
    allocationUnitsGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(4,1fr)",
        gap: 5,
        width: "50%",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(2,1fr)",
        }
    },
    buttonStyle: {
        width: "20%",
        [theme.breakpoints.down("920")]: {
            width: "50%"
        }
    },
    popupListGrid: {
        display: "grid",
        gridTemplateColumns: "17% 13% 16% 12% 12% 13% 13% 2%"
    },
    spanmsg: {
        width: "240%",
        marginTop: "-10%",
        marginLeft: "2%"
    },
    spanerr: {
        color: window.globalConfig.color.red,
        fontSize: "13px",
    },
    unitsPadding: {
        padding: '10px 0 0 0'
    }
});
class Nps_DataEntry extends Component {
    constructor() {
        super();
        this.myDiv = React.createRef();

        this.state = {
            currentPensionFundManagerList: [],
            currentPensionFundManagerName: "",
            edit: false,
            currentPensionFundManagerID: 0,
            currentPensionFundManagerError: "",
            pranNo: "",
            pranNoError: "",
            amount: "",

            amountError: "",
            transactionTypeID: "",
            transactionTypeName: "",
            transactionTypeError: "",
            investmentDateTemp: moment().format("DD MMM YYYY"),
            investmentDate: "",
            investmentDateError: "",
            latestValueDateTemp: moment().format("DD MMM YYYY"),
            latestValueDate: "",
            latestValueDateError: "",
            latestValue: "",
            latestValueError: "",
            anchorEl: null,
            anchorElLatestValue: null,
            message: "",
            open: false,
            equity: "",
            equityError: "",
            commodity: "",
            commodityError: "",
            other: "",
            otherError: "",
            gold: "",
            goldError: "",
            goldNav: "",
            goldNavError: "",
            otherNav: "",
            otherNavError: "",
            commodityNav: "",
            commodityNavError: "",
            equityNav: "",
            equityNavError: "",
            commonError: "",
            valuationGoldNav: "",
            valuationGoldNavError: "",
            valuationOtherNav: "",
            valuationOtherNavError: "",
            valuationCommodityNav: "",
            valuationCommodityNavError: "",
            valuationEquityNav: "",
            valuationEquityNavError: "",

            loader: false,
            addMoreLoader: false,
            transactionListing: [],
            transactionListingID: '',
            transactionBackupListing: [],
            transactionLoader: false,
            transactionListingError: "",
            inceptionDateTemp: moment().format("DD MMM YYYY"),
            inceptionDate: "",
            inceptionDateError: "",

            isMinor: false,
            valuationEquityAmount: "",
            valuationEquityAmountError: "",
            valuationCommodityAmount: "",
            valuationCommodityAmountError: "",
            latestValue: "",
            latestValueError: "",
            valuationGoldAmount: "",
            valuationGoldAmountError: "",
            valuationOtherAmount: "",
            valuationOtherAmountError: "",
            nomineeType: "",
            nomineeTypeID: "",
            nomineeTypeError: "",
            nomineeName: "",
            nomineeNameError: "",
            nomineeRelationship: "",
            nomineeRelationshipID: "",
            nomineeRelationshipError: "",
            nomineeDOB: "",
            nomineeDOBError: "",
            nomineeDOBTemp: moment().format("DD MMM YYYY"),
            nomineepercentage: "",
            nomineepercentageError: "",
            guardianName: "",
            guardianNameError: "",

            //nomineeLoader: false,
            addMoreNomineeLoader: false,
            nomineeListing: [],
            nomineeListingID: '',
            nomineeBackupListing: [],
            nomineeLoader: false,
            nomineeListingError: "",
            nomineeTypeList: [
                { label: 'primary', value: 1 },
                { label: 'Secondary', value: 2 }
            ],
            nomineeRelationshipList: [],
            validRange: 100,
            nomineeButtonDisable: false
        }

    }
    componentDidMount() {

        let keyPairChange = []
        const relationDropdown = [...this.props.nomineeRelationshipList || ""]
        relationDropdown?.map((item) => {
            keyPairChange.push(
                { label: item.RelationShip, value: item.ID }
            )
        })
        this.setState((prevState) => ({ ...prevState, nomineeRelationshipList: keyPairChange }))


        // this.props.MenuHideShow(true)
        scrollToTopPage()
        let data = {}
        data.clientID = this.props.clientID
        getNPSDropdown(data).then((res) => {
            if (res.status == "S") {
                if (res.table1 != null && res.table1.length != 0) {
                    this.setState({
                        currentPensionFundManagerList: res.table1.map((item) => {
                            return { "label": item.Name, "value": item.ID }
                        })
                    })
                }
            }
        }).catch((err) => {

        })
        if (this.props.transactionTypeList != "NA" && this.props.transactionTypeList.length != 0) {
            this.setState({ transactionTypeName: "Invested", transactionTypeID: this.props.transactionTypeList.filter((item) => item.label == "Invested")[0].value })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.nomineeListing.length != this.state.nomineeListing.length) {
            let isCountReach = 0;
            this.state.nomineeListing?.map((item) => (
                isCountReach = Number(item.nomineepercentage.name) + isCountReach
            ))
            if (isCountReach >= 100) {
                this.setState({
                    nomineeButtonDisable: true
                })
            }
        }
    }

    clearAsset = () => {
        this.setState({
            currentPensionFundManagerName: "",

            currentPensionFundManagerID: 0,
            currentPensionFundManagerError: "",
            pranNo: "",
            pranNoError: "",
            amount: "",

            amountError: "",
            transactionTypeID: "",
            transactionTypeName: "",
            transactionTypeError: "",
            investmentDateTemp: moment().format("DD MMM YYYY"),
            investmentDate: "",
            investmentDateError: "",
            latestValueDateTemp: moment().format("DD MMM YYYY"),
            latestValueDate: "",
            latestValueDateError: "",
            latestValue: "",
            latestValueError: "",
            anchorEl: null,
            anchorElLatestValue: null,
            message: "",
            open: false,
            equity: "",
            equityError: "",
            commodity: "",
            commodityError: "",
            other: "",
            otherError: "",
            gold: "",
            goldError: "",
            goldNav: "",
            goldNavError: "",
            otherNav: "",
            otherNavError: "",
            commodityNav: "",
            commodityNavError: "",
            equityNav: "",
            equityNavError: "",
            transactionListing: [],
            transactionListingID: '',
            transactionBackupListing: [],
            valuationGoldNav: "",
            valuationGoldNavError: "",
            valuationOtherNav: "",
            valuationOtherNavError: "",
            valuationCommodityNav: "",
            valuationCommodityNavError: "",
            valuationEquityNav: "",
            valuationEquityNavError: "",
            nomineeBackupListing: [],
            nomineeListing:[],
            nomineeListingID:"",
            inceptionDate:"",
            valuationGoldAmount: "",
            valuationOtherAmount: "",
            valuationEquityAmount: "",
            valuationCommodityAmount: "",

        })
    }

    // componentWillUnmount(){
    //     this.props.MenuHideShow(true)

    // }
    handleCurrentPensionFundManager = (val) => {
        if (val.length != 0) {
            this.setState({ currentPensionFundManagerID: val[0].value, currentPensionFundManagerName: val[0].label, currentPensionFundManagerError: "" })
        }
    }
    handlePranNoChange = (e) => {
        if (AlphaNumbericandSpecifiedCharValidation(e.target.value)) {
            this.setState({ pranNo: e.target.value, pranNoError: "" })
        }
    }
    handleLatestValueChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnly(removeComma.trim(), 14)) {
            this.setState({ latestValue: amountCommaSeperator(removeComma.trim()), latestValueError: "" })
        }
    }
    handleInvestmentDateClick = (e) => {
        this.setState({ anchorEl: e.currentTarget })
    }
    handleInvestmentDateClose = () => {
        this.setState({ anchorEl: null })
    }
    handleInvestmentDateChange = (value) => {
        this.setState({ investmentDateTemp: moment(value).format("DD MMM YYYY") })

    }
    handleInvestmentDateApply = (date) => {
        this.setState({ investmentDate: moment(date).format("DD MMM YYYY"), investmentDateError: "" })
    }
    // handleLatestValueChange = (e) => {
    //     this.setState({ latestValue: e.target.value, latestValueError: "" })
    // }
    handleLatestValueDateChange = (value) => {
        this.setState({ latestValueDateTemp: moment(value).format("DD MMM YYYY") })

    }
    handleLatestValueDateApply = (date) => {
        this.setState({ latestValueDate: moment(date).format("DD MMM YYYY"), latestValueDateError: "" })

    }
    handleLatestValueDateClick = (e) => {
        this.setState({ anchorElLatestValue: e.currentTarget })
    }
    handleLatestValueDateClose = () => {
        this.setState({ anchorElLatestValue: null })
    }
    handleAmountChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnly(removeComma.trim(), 14)) {
            this.setState({ amount: amountCommaSeperator(removeComma.trim()), amountError: "" })
        }

    }

    handleNomineeNameChange=(e)=>{
        if(NameValidation(e.target.value)){
            this.setState({ ...this.state, nomineeName:e.target.value, nomineeNameError: "" })
        }        
    }

    handleNomineeGuardianNameChange=(e)=>{
        if(NameValidation(e.target.value)){
            this.setState({ ...this.state, guardianName:e.target.value, guardianNameError: "" })
        }        
    }

    handlePercentageChange = (e) => {
        let isCountReach = 0
        if (this.state.nomineeListing?.length > 0 && this.state.nomineeListingID == "") {

            this.state.nomineeListing?.map((item) => (
                isCountReach = Number(item.nomineepercentage.name) + isCountReach
            ))
        } else if (Number(this.state.nomineeListingID) > 0) {
            this.state.nomineeListing?.filter((item) => item.ID.name != this.state.nomineeListingID)?.map((e) => {
                isCountReach = Number(e.nomineepercentage.name) + isCountReach
            })
        }

        const percent = e.target.value
        if (isNaN(Number(percent)) || Number(percent) < 0 || Number(percent) > (this.state.validRange - isCountReach)) {

        } else {
            this.setState({
                ...this.state,
                nomineepercentageError: "",
                nomineepercentage: e.target.value
            })
        }

    }
    handleEquityChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ equity: amountCommaSeperator(removeComma.trim()), equityError: "" })
        }
    }
    handleCommodityChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ commodity: amountCommaSeperator(removeComma.trim()), commodityError: "" })
        }
    }
    handleGoldChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ gold: amountCommaSeperator(removeComma.trim()), goldError: "" })
        }
    }
    handleOtherChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ other: amountCommaSeperator(removeComma.trim()), otherError: "" })
        }
    }
    handleEquityNavChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ equityNav: amountCommaSeperator(removeComma.trim()), equityNavError: "" })
        }
    }
    handleCommodityNavChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ commodityNav: amountCommaSeperator(removeComma.trim()), commodityNavError: "" })
        }
    }
    handleGoldNavChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ goldNav: amountCommaSeperator(removeComma.trim()), goldNavError: "" })
        }
    }
    handleOtherNavChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ otherNav: amountCommaSeperator(removeComma.trim()), otherNavError: "" })
        }
    }

    handleTransactionTypeChange = (val) => {
        // let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        // if (numbersandDeciamlOn/ly(removeComma.trim())) {
        this.setState({ transactionTypeName: val[0].label, transactionTypeID: val[0].value, transactionTypeError: "" })
        // }
    }
    handleValuationEquityNavChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ valuationEquityNav: amountCommaSeperator(removeComma.trim()), valuationEquityNavError: "" })
        }
    }
    handleValuationCommodityNavChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ valuationCommodityNav: amountCommaSeperator(removeComma.trim()), valuationCommodityNavError: "" })
        }

    }
    handleValuationGoldNavChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ valuationGoldNav: amountCommaSeperator(removeComma.trim()), valuationGoldNavError: "" })
        }
    }
    handleValuationOtherNavChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ valuationOtherNav: amountCommaSeperator(removeComma.trim()), valuationOtherNavError: "" })
        }
    }
    handleValuationEquityAmountChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ valuationEquityAmount: amountCommaSeperator(removeComma.trim()), valuationEquityAmountError: "" })
        }
    }
    handleValuationCommodityAmountChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ valuationCommodityAmount: amountCommaSeperator(removeComma.trim()), valuationCommodityAmountError: "" })
        }

    }
    handleValuationGoldAmountChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ valuationGoldAmount: amountCommaSeperator(removeComma.trim()), valuationGoldAmountError: "" })
        }
    }
    handleValuationOtherAmountChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ valuationOtherAmount: amountCommaSeperator(removeComma.trim()), valuationOtherAmountError: "" })
        }
    }

    handleNomineeMadatory = () => {

        let isNomineeValid = true

        if (this.state.nomineeType.trim() == "") {

            isNomineeValid = false
            this.setState({ nomineeTypeError: labels.messages.required, })
        }
        if (this.state.nomineeName.trim() == "") {
            isNomineeValid = false
            this.setState({ nomineeNameError: labels.messages.required, })
        }
        if (this.state.nomineeRelationship.trim() == "") {
            isNomineeValid = false
            this.setState({ nomineeRelationshipError: labels.messages.required, })
        }
        if (this.state.nomineeDOB.trim() == "") {
            isNomineeValid = false
            this.setState({ nomineeDOBError: labels.messages.required, })
        }
        if (this.state.nomineepercentage.trim() == "") {
            isNomineeValid = false
            this.setState({ nomineepercentageError: labels.messages.required, })
        }

        if (this.state.isMinor == true && this.state.guardianName == "") {
            isNomineeValid = false
            this.setState({ guardianNameError: labels.messages.required, })
        }

        return isNomineeValid;
    }


    handleSubmit = (type) => {
        let isValid = true
        if (this.state.currentPensionFundManagerID == 0) {
            isValid = false
            this.setState({ currentPensionFundManagerError: labels.messages.required, })
        }
        if (this.state.pranNo.trim() == "") {
            isValid = false
            this.setState({ pranNoError: labels.messages.required, })
        }
        if (this.state.latestValueDate.trim() == "") {

            isValid = false
            this.setState({ latestValueDateError: labels.messages.required, })
        }
        if (this.state.valuationEquityNav.trim() == "") {
            isValid = false
            this.setState({ valuationEquityNavError: labels.messages.required, })
        }
        if (this.state.valuationCommodityNav.trim() == "") {
            isValid = false
            this.setState({ valuationCommodityNavError: labels.messages.required, })
        }
        if (this.state.valuationGoldNav.trim() == "") {
            isValid = false
            this.setState({ valuationGoldNavError: labels.messages.required, })
        }
        if (this.state.valuationOtherNav.trim() == "") {
            isValid = false
            this.setState({ valuationOtherNavError: labels.messages.required, })
        }

        if (this.state.valuationEquityAmount.trim() == "") {
            isValid = false
            this.setState({ valuationEquityAmountError: labels.messages.required, })
        }
        if (this.state.valuationCommodityAmount.trim() == "") {
            isValid = false
            this.setState({ valuationCommodityAmountError: labels.messages.required, })
        }
        if (this.state.valuationGoldAmount.trim() == "") {
            isValid = false
            this.setState({ valuationGoldAmountError: labels.messages.required, })
        }
        if (this.state.valuationOtherAmount.trim() == "") {
            isValid = false
            this.setState({ valuationOtherAmountError: labels.messages.required, })
        }

        if (this.state.inceptionDate.trim() == "") {

            isValid = false
            this.setState({ inceptionDateError: labels.messages.required, })
        }
        if (this.state.latestValue.trim() == "") {

            isValid = false
            this.setState({ latestValueError: labels.messages.required, })
        }
        let TransactionData = []
        if (this.state.amount != "" || this.state.investmentDate != "" || this.state.equity != "" || this.state.equityNav != "" || this.state.gold != "" || this.state.goldNav != "" || this.state.other != "" || this.state.otherNav != "" || this.state.commodity != "" || this.state.commodityNav != "") {
            let res = this.handleAddTransaction()
            //isValid = res.valid

            TransactionData = res.response

        } else {
            if (this.state.transactionBackupListing.length == 0) {
                let res = this.handleAddTransaction()
                isValid = res.valid
                TransactionData = res.response
                this.setState({ openSnackBar: true, message: "Please Add Transaction", severity: "warning" })
            } else {
                TransactionData = this.state.transactionBackupListing
            }
        }

        let NomineeData = []
        if (this.state.nomineeType != "" || this.state.nomineeName != "" || this.state.nomineeRelationship != "" || this.state.nomineeDOB != "" || this.state.nomineepercentage != "") {
            //isValid = this.handleNomineeMadatory()
            let res = this.handleAddNomineeDetails("summary")
            NomineeData = res.response
            isValid = res.valid
        }else if (this.state.nomineeBackupListing.length != 0) {
            let res = this.handleAddNomineeDetails()
            NomineeData = res.response
        }

        // } else {
        //     if (this.state.nomineeBackupListing.length == 0) {
        //         let res = this.handleAddNomineeDetails()
        //         isValid = res.valid
        //         NomineeData = res.response
        //         this.setState({ openSnackBar: true, message: "Please Add Nominee", severity: "warning" })
        //     } else {
        //         NomineeData = this.state.nomineeBackupListing
        //     }
        // }

        // if (this.state.nomineeType != "" || this.state.nomineeName != "" || this.state.nomineeRelationship != "" || this.state.nomineeDOB != "" || this.state.nomineepercentage != "" || this.state.guardianName != "") {
        //     isValid = this.handleNomineeMadatory()
        // }

        if (isValid) {
            let transaction = TransactionData.map((item) => {
                return {
                    "Amount": replaceComma(item.amount), "Date": item.investmentDate, "TransactionType": item.transactionTypeID,
                    "EqUnits": replaceComma(item.equityUnits), "EqNAV": replaceComma(item.equityNav), "CoUnits": replaceComma(item.commodityUnits),
                    "CoNAV": replaceComma(item.commodityNav), "GoUnits": replaceComma(item.goldUnits), "GoNAV": replaceComma(item.goldNav),
                    "OtUnits": replaceComma(item.otherUnits), "OtNAV": replaceComma(item.otherNav)
                }
            })
            let data = {}
            data.id = 0
            data.clientID = this.props.logindetails.ClientID ||0
            data.accountTypeID = this.props.logindetails.L4_AccountTypeID || 0
            data.accountID = this.props.L5ID || 0
            data.schemeId = this.state.currentPensionFundManagerID
            data.valuation = 0
            data.valuationDate = this.state.latestValueDate
            data.createdBy = this.props.clientID
            data.flag = 1
            data.veAllocation = Number(replaceComma(this.state.valuationEquityNav))
            data.vcAllocation = Number(replaceComma(this.state.valuationCommodityNav))
            data.vgallocation = Number(replaceComma(this.state.valuationGoldNav))
            data.voAllocation = Number(replaceComma(this.state.valuationCommodityNav))
            data.veAmountAllocation = Number(replaceComma(this.state.valuationEquityAmount))
            data.vcAmountAllocation = Number(replaceComma(this.state.valuationCommodityAmount))
            data.vgAmountAllocation = Number(replaceComma(this.state.valuationGoldAmount))
            data.voAmountAllocation = Number(replaceComma(this.state.valuationCommodityAmount))
            data.Latestvalue = replaceComma(this.state.latestValue)
            data.InceptionDate = this.state.inceptionDate
            data.npsNomineeParam = JSON.stringify(NomineeData)
            data.pranno = this.state.pranNo
            data.npsTransactionParam = JSON.stringify(transaction)
            if (type == "save") {
                this.setState({ loader: true })
            } else {
                this.setState({ addMoreLoader: true })
            }
            getNPSDataEntry(data).then((res) => {
                if (type == "save") {
                    this.setState({ loader: false })
                } else {
                    this.setState({ addMoreLoader: false })
                }
                if (res.status == "S") {

                    this.clearAsset()
                    if (type == "save") {
                        this.props.history.push(labels.Path.Nps)
                    }
                    this.setState({ openSnackBar: true, message: res.message, severity: "success" })
                } else {
                    this.setState({ openSnackBar: true, message: res.message, severity: "warning" })

                }

            })
        }

    }
    handleEditTransaction = (item) => {
        this.setState({
            amount: item.Amount.name,
            transactionTypeID: item.TransactionType.name,
            transactionTypeName: item.TransactionType.name,
            investmentDate: item.InvestmentDate.name,
            equity: item.Equity.name,
            equityNav: item.Equity.subHeader1,
            gold: item.Gold.name,
            goldNav: item.Gold.subHeader1,
            other: item.Other.name,
            otherNav: item.Other.subHeader1,
            commodity: item.Other.name,
            commodityNav: item.Other.subHeader1,
            transactionListingID: item.ID.name,
            edit: true
        })
        // this.handleDeleteTransaction(item)
    }
    clearTransaction = () => {
        this.setState({
            equity: "",
            equityError: "",
            commodity: "",
            commodityError: "",
            other: "",
            otherError: "",
            gold: "",
            goldError: "",
            goldNav: "",
            goldNavError: "",
            otherNav: "",
            otherNavError: "",
            commodityNav: "",
            commodityNavError: "",
            equityNav: "",
            equityNavError: "",
            amount: '',
            amountError: '',
            investmentDate: '',
            investmentDateError: '',
            //inceptionDate:"",

            
            //latestValue: "",
            
            // transactionTypeID: '',
            // transactionTypeName: '',
            // transactionTypeError: ''
        })
        if (this.props.transactionTypeList != "NA" && this.props.transactionTypeList.length != 0) {
            this.setState({ transactionTypeName: "Invested", transactionTypeID: this.props.transactionTypeList.filter((item) => item.label == "Invested")[0].value })
        }

    }
    handleAddTransaction = () => {
        let isValid = true
        let data = []
        this.setState({ edit: false })
        if (this.state.amount == "") {
            isValid = false
            this.setState({ amountError: labels.messages.required, })
        }
        // if (this.state.transactionTypeID == "") {
        //     isValid = false
        //     this.setState({ transactionTypeError: labels.messages.required, })
        // }
        if (this.state.investmentDate == "") {
            isValid = false
            this.setState({ investmentDateError: labels.messages.required, })
        }
        if (this.state.equity == "") {
            isValid = false
            this.setState({ equityError: labels.messages.required, })
        }
        if (this.state.equityNav == "") {
            isValid = false
            this.setState({ equityNavError: labels.messages.required, })
        }
        if (this.state.commodity == "") {
            isValid = false
            this.setState({ commodityError: labels.messages.required, })
        }
        if (this.state.commodityNav == "") {
            isValid = false
            this.setState({ commodityNavError: labels.messages.required, })
        }
        if (this.state.gold == "") {
            isValid = false
            this.setState({ goldError: labels.messages.required, })
        }
        if (this.state.goldNav == "") {
            isValid = false
            this.setState({ goldNavError: labels.messages.required, })
        }
        if (this.state.other == "") {
            isValid = false
            this.setState({ otherError: labels.messages.required, })
        }
        if (this.state.otherNav == "") {
            isValid = false
            this.setState({ otherNavError: labels.messages.required, })
        }

        if (this.state.equity == "" && this.state.commodity == "" && this.state.gold == "" && this.state.other == "") {
            isValid = false
            this.setState({
                equityError: labels.messages.required,
                commodityError: labels.messages.required,
                goldError: labels.messages.required,
                otherError: labels.messages.required
            })
        }
        // else if (Number(replaceComma(this.state.equity)) == 0 && Number(replaceComma(this.state.commodity)) == 0 && Number(replaceComma(this.state.gold)) == 0 && Number(replaceComma(this.state.other)) == 0) {
        //     isValid = false
        //     // this.setState({ equityError : labels.messages.Amount,
        //     //     commodityError : labels.messages.Amount,
        //     //     goldError : labels.messages.Amount,
        //     //     otherError : labels.messages.Amount
        //     // })
        //     //this.setState({ openSnackBar: true, message: labels.messages.Error, severity: 'warning'})
        //     this.setState({ commonError: labels.messages.Error })
        // }
        // else {
        //     isValid = true
        //     this.setState({
        //         equityError: "",
        //         commodityError: "",
        //         goldError: "",
        //         otherError: "",
        //         commonError: ""
        //     })
        // }


        if (isValid) {
            let id = uniqueId()
            this.setState({ transactionListingError: "" })
            if (this.state.transactionBackupListing.length != 0 && this.state.transactionBackupListing.filter((value) => value.ID == this.state.transactionListingID).length != 0) {

                let modifiedArray = [...this.state.transactionBackupListing.filter((val) => val.ID != this.state.transactionListingID), {
                    'ID': this.state.transactionListingID,
                    'transactionType': this.state.transactionTypeName, 'investmentDate': this.state.investmentDate, 'amount': this.state.amount,
                    'equityUnits': this.state.equity, 'equityNav': this.state.equityNav,
                    'commodityUnits': this.state.commodity, 'commodityNav': this.state.commodityNav,
                    'goldUnits': this.state.gold, 'goldNav': this.state.goldNav,
                    'otherUnits': this.state.other, 'otherNav': this.state.otherNav
                }]
                data = modifiedArray
                this.setState({
                    transactionListingID: '',
                    transactionBackupListing: modifiedArray,
                    transactionListing: [...modifiedArray.map((items) => {
                        return {
                            "TransactionType": { name: items.transactionType, subHeader1: "", type: "" },
                            "InvestmentDate": { name: items.investmentDate, subHeader1: "", type: "" }
                            , "Amount": { name: items.amount, subHeader1: "", type: "amount" }
                            , "Equity": { name: items.equityUnits, subHeader1: items.equityNav, subHeaderType: 'amount', type: "amount" }
                            , "Commodity": { name: items.commodityUnits, subHeader1: items.commodityNav, subHeaderType: 'amount', type: "amount" }
                            , "Gold": { name: items.goldUnits, subHeader1: items.goldNav, subHeaderType: 'amount', type: "amount" }
                            , "Other": { name: items.otherUnits, subHeader1: items.otherNav, subHeaderType: 'amount', type: "amount" }
                            , 'ID': { name: items.ID, subHeader1: '', type: '' }
                        }
                    }),]

                })
                // this.setState({ openSnackBar: true, message: labels.messages.transact, severity: 'success'})
            } else {
                data = [...this.state.transactionBackupListing, {
                    'ID': id,
                    'transactionTypeID': this.state.transactionTypeID,
                    'transactionType': this.state.transactionTypeName, 'investmentDate': this.state.investmentDate, 'amount': this.state.amount,
                    'equityUnits': this.state.equity, 'equityNav': this.state.equityNav,
                    'commodityUnits': this.state.commodity, 'commodityNav': this.state.commodityNav,
                    'goldUnits': this.state.gold, 'goldNav': this.state.goldNav,
                    'otherUnits': this.state.other, 'otherNav': this.state.otherNav
                }]
                this.setState({
                    transactionBackupListing: [...this.state.transactionBackupListing, {
                        'ID': id,
                        'transactionTypeID': this.state.transactionTypeID,
                        'transactionType': this.state.transactionTypeName, 'investmentDate': this.state.investmentDate, 'amount': this.state.amount,
                        'equityUnits': this.state.equity, 'equityNav': this.state.equityNav,
                        'commodityUnits': this.state.commodity, 'commodityNav': this.state.commodityNav,
                        'goldUnits': this.state.gold, 'goldNav': this.state.goldNav,
                        'otherUnits': this.state.other, 'otherNav': this.state.otherNav
                    }],
                    transactionListing: [...this.state.transactionListing, {
                        "TransactionType": { name: this.state.transactionTypeName, subHeader1: "", type: "" },
                        "InvestmentDate": { name: this.state.investmentDate, subHeader1: "", type: "" }
                        , "Amount": { name: this.state.amount, subHeader1: "", type: "amount" }
                        , "Equity": { name: this.state.equity, subHeader1: this.state.equityNav, subHeaderType: 'amount', type: "amount" }
                        , "Commodity": { name: this.state.commodity, subHeader1: this.state.commodityNav, subHeaderType: 'amount', type: "amount" }
                        , "Gold": { name: this.state.gold, subHeader1: this.state.goldNav, subHeaderType: 'amount', type: "amount" }
                        , "Other": { name: this.state.other, subHeader1: this.state.otherNav, subHeaderType: 'amount', type: "amount" }
                        , 'ID': { name: id, subHeader1: '', type: '' }
                    }]

                })
                this.setState({ openSnackBar: true, message: labels.messages.transact, severity: 'success' })
            }

            this.clearTransaction()
        }
        return { valid: isValid, response: data }
    }
    handleDeleteTransaction = (item) => {
        this.setState({ transactionBackupListing: this.state.transactionBackupListing.filter((value) => value.ID != item.ID.name) })
        this.setState({
            transactionListing: this.state.transactionBackupListing.filter((value) => value.ID != item.ID.name).map((val) => {
                return {
                    "TransactionType": { name: val.transactionType, subHeader1: "", type: "" },
                    "InvastmentDate": { name: val.investmentDate, subHeader1: "", type: "" }
                    , "Amount": { name: val.amount, subHeader1: "", type: "amount" }
                    , "Equity": { name: val.equityUnits, subHeader1: val.equityNav, subHeaderType: 'amount', type: "amount" }
                    , "Commodity": { name: val.commodityUnits, subHeader1: val.commodityNav, subHeaderType: 'amount', type: "amount" }
                    , "Gold": { name: val.goldUnits, subHeader1: val.goldNav, subHeaderType: 'amount', type: "amount" }
                    , "Other": { name: val.otherUnits, subHeader1: val.otherNav, subHeaderType: 'amount', type: "amount" }
                    , 'ID': { name: val.ID, subHeader1: '', type: '' }
                }
            })
        })
    }
    handleFocus = (event) => event.target.select();
    handleToastClose = () => {
        this.setState({ openSnackBar: false })
    }


    clearNomineeDetails = () => {
        this.setState({
            nomineeTypeID: "",
            nomineeType: "",
            nomineeName: "",
            nomineeRelationshipID: "",
            nomineeRelationship: "",
            nomineeDOB: "",
            nomineepercentage: "",
            guardianName: "",
        })
    }

    handleEditNomineeDetails = (item) => {
        this.setState({
            //nomineeTypeID: "",
            nomineeType: item.nomineeType.name,
            nomineeName: item.nomineeName.name,
            //nomineeRelationshipID: "",
            nomineeRelationship: item.nomineeRelationship.name,
            nomineeDOB: item.nomineeDOB.name,
            nomineepercentage: item.nomineepercentage.name,
            guardianName: item.guardianName.name,
            isMinor: (item.isMinor.name == "Yes") ? true : false,
            nomineeButtonDisable: false,
            nomineeListingID: item.ID.name,
            edit: true
        })
    }

    handleDeleteNomineeDetails = (item) => {
        this.setState({ nomineeBackupListing: this.state.nomineeBackupListing.filter((value) => value.ID != item.ID.name) })
        this.setState({
            nomineeListing: this.state.nomineeBackupListing.filter((value) => value.ID != item.ID.name).map((val) => {
                return {
                    "nomineeType": { name: this.state.nomineeType, subHeader1: "", type: "" },
                    "nomineeName": { name: this.state.nomineeName, subHeader1: "", type: "" },
                    "nomineeRelationship": { name: this.state.nomineeRelationship, subHeader1: "", type: "" },
                    "nomineeDOB": { name: this.state.nomineeDOB, subHeader1: "", type: "" },
                    "nomineepercentage": { name: this.state.nomineepercentage, subHeader1: "", type: "" },
                    "isMinor": { name: (this.state.isMinor) ? "Yes" : "No", subHeader1: "", type: "" },
                    "guardianName": { name: (this.state.guardianName?.length > 0) ? this.state.guardianName : "-", subHeader1: "", type: "" },
                }
            })
        })
    }



    handleAddNomineeDetails = (isTable="") => {
        let isValid;
        if(isTable=="summary"){
            isValid = this.handleNomineeMadatory()
        }
        

        let data = []
        this.setState({ edit: false })
        // if (this.state.amount == "") {
        //     isValid = false
        //     this.setState({ amountError: labels.messages.required, })
        // }

        // if (this.state.nomineeType == "") {
        //     isValid = false
        //     this.setState({ nomineeTypeError: labels.messages.required, })
        // }
        // if (this.state.nomineeName == "") {
        //     isValid = false
        //     this.setState({ nomineeNameError: labels.messages.required, })
        // }
        // if (this.state.nomineeRelationship == "") {
        //     isValid = false
        //     this.setState({ nomineeRelationshipError: labels.messages.required, })
        // }
        // if (this.state.nomineeDOB == "") {
        //     isValid = false
        //     this.setState({ nomineeDOBError: labels.messages.required, })
        // }
        // if (this.state.nomineepercentage == "") {
        //     isValid = false
        //     this.setState({ nomineepercentageError: labels.messages.required, })
        // }


        if (isValid) {
            let id = uniqueId()
            this.setState({ nomineeListingError: "" })
            if (this.state.nomineeBackupListing.length != 0 && this.state.nomineeBackupListing.filter((value) => value.ID == this.state.nomineeListingID).length != 0) {

                let modifiedArray = [...this.state.nomineeBackupListing.filter((val) => val.ID != this.state.nomineeListingID), {
                    'ID': this.state.nomineeListingID,
                    'nomineeType': this.state.nomineeType, 'nomineeName': this.state.nomineeName,
                    'nomineeRelationship': this.state.nomineeRelationship,
                    'nomineeDOB': this.state.nomineeDOB, 'nomineepercentage': this.state.nomineepercentage,
                    'isMinor': this.state.isMinor, 'guardianName': this.state.guardianName
                }]
                data = modifiedArray
                this.setState({
                    nomineeListingID: '',
                    nomineeBackupListing: modifiedArray,
                    nomineeListing: [...modifiedArray.map((items) => {
                        return {
                            "nomineeType": { name: items.nomineeType, subHeader1: "", type: "" },
                            "nomineeName": { name: items.nomineeName, subHeader1: "", type: "" },
                            "nomineeRelationship": { name: items.nomineeRelationship, subHeader1: "", type: "" },
                            "nomineeDOB": { name: items.nomineeDOB, subHeader1: "", type: "" },
                            "nomineepercentage": { name: items.nomineepercentage, subHeader1: "", type: "" },
                            "isMinor": { name: (items.isMinor) ? "Yes" : "No", subHeader1: "", type: "" },
                            "guardianName": { name: (items.guardianName?.length > 0 && items.isMinor) ? items.guardianName : "-", subHeader1: "", type: "" },
                            'ID': { name: items.ID, subHeader1: '', type: '' }
                        }
                    }),]

                })
                // this.setState({ openSnackBar: true, message: labels.messages.transact, severity: 'success'})
            } else {
                data = [...this.state.nomineeBackupListing, {
                    'ID': id,
                    'nomineeType': this.state.nomineeType, 'nomineeName': this.state.nomineeName,
                    'nomineeRelationship': this.state.nomineeRelationship,
                    'nomineeDOB': this.state.nomineeDOB, 'nomineepercentage': this.state.nomineepercentage,
                    'isMinor': this.state.isMinor, 'guardianName': this.state.guardianName
                }]
                this.setState({
                    nomineeBackupListing: [...this.state.nomineeBackupListing, {
                        'nomineeType': this.state.nomineeType, 'nomineeName': this.state.nomineeName,
                        'nomineeRelationship': this.state.nomineeRelationship,
                        'nomineeDOB': this.state.nomineeDOB, 'nomineepercentage': this.state.nomineepercentage,
                        'isMinor': this.state.isMinor, 'guardianName': this.state.guardianName,
                        'ID': id,
                    }],
                    nomineeListing: [...this.state.nomineeListing, {

                        "nomineeType": { name: this.state.nomineeType, subHeader1: "", type: "" },
                        "nomineeName": { name: this.state.nomineeName, subHeader1: "", type: "" },
                        "nomineeRelationship": { name: this.state.nomineeRelationship, subHeader1: "", type: "" },
                        "nomineeDOB": { name: this.state.nomineeDOB, subHeader1: "", type: "" },
                        "nomineepercentage": { name: this.state.nomineepercentage, subHeader1: "", type: "" },
                        "isMinor": { name: (this.state.isMinor) ? "Yes" : "No", subHeader1: "", type: "" },
                        "guardianName": { name: (this.state.guardianName?.length > 0) ? this.state.guardianName : "-", subHeader1: "", type: "" },
                        'ID': { name: id, subHeader1: '', type: '' },
                    }]

                })
                this.setState({ openSnackBar: true, message: labels.messages.nominee, severity: 'success' })
            }

            this.clearNomineeDetails()
        }
        return { valid: isValid, response: data }
    }


    render() {
        const { classes } = this.props;
       
        //   
        try {
            return (
                <ErrorBoundary>
                    <Subheader heading={labels.productList.NPS} description={labels.Transact.aifContent} />
                    <div className={classes.flex}>
                        <div className={classes.sideSpace}></div>
                        <div className={classes.mainCard} ref={this.myDiv}>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between" }}>
                                <div className={classes.subHeader}>{labels.Nps.addNpsInformation}</div>
                            </div>
                            <div className={classes.cardSeparatorLine}></div>
                            <div className={classes.firstContainer}>
                                <CommonDropdown
                                    //  autoselect={true}
                                    handleOnChange={this.handleCurrentPensionFundManager}
                                    value={this.state.currentPensionFundManagerName}
                                    error={this.state.currentPensionFundManagerError}
                                    type={"normalwithsearch"}
                                    id="ddl_broker"
                                    placeholder={labels.Nps.currentPensionFundManagerPlaceholder}
                                    label={labels.Nps.currentPensionFundManager}
                                    height={190}

                                    List={this.state.currentPensionFundManagerList}
                                // value={this.state.broker}
                                />
                                <Textinput handleChange={this.handlePranNoChange}
                                    value={this.state.pranNo}
                                    error={this.state.pranNoError}
                                    page={"normal"} label={labels.Nps.pranNo}
                                    placeholder={labels.Nps.pranNoPlaceholder}
                                />
                                <DatePicker
                                    handleClick={(e) => this.setState({ anchorEl: e.currentTarget })}
                                    handleApply={(date) => this.setState({ inceptionDate: moment(date).format("DD MMM YYYY"), inceptionDateError: "" })}
                                    showingdate={this.state.inceptionDateTemp}
                                    dateChange={(value) => this.setState({ inceptionDateTemp: moment(value).format("DD MMM YYYY") })}
                                    handleClose={() => this.setState({ anchorEl: null })}
                                    anchorEl={this.state.anchorEl}
                                    error={this.state.inceptionDateError}
                                    maxdate={moment().format("DD MMM YYYY")}
                                    // allowed={false} 
                                    id="dp_aifInvestmentDate"
                                    label={labels.Nps.inceptionDate}
                                    placeholder={labels.Nps.inceptionDatePlaceHolder}
                                    date={this.state.inceptionDate} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between" }}>
                                <div className={classes.subHeader}>{labels.Nps.transaction}</div>
                            </div>
                            <div className={classes.cardSeparatorLine}></div>
                            <div className={classes.secondContainerFirstHalf}>
                                <Textinput handleChange={this.handleAmountChange}
                                    value={this.state.amount}

                                    error={this.state.amountError}
                                    align={"right"}
                                    otherProps={{ autoFocus: true, name: "amount", }}

                                    page={"normal"} label={labels.Nps.amount}
                                    placeholder={labels.messages.amountZeroPlaceholder}
                                />
                                <CommonDropdown
                                    //  autoselect={true}
                                    handleOnChange={this.handleTransactionTypeChange}
                                    value={this.state.transactionTypeName}
                                    error={this.state.transactionTypeError}
                                    type={"normalwithsearch"}
                                    //disabled={this.state.transactionBackupListing.length == 0 || this.state.edit ? true : false}
                                    id="ddl_transactiontype"
                                    placeholder={labels.Nps.transactionTypePlaceholder}
                                    label={labels.Nps.transactionType}
                                    height={190}
                                    List={this.props.transactionTypeList}
                                // value={this.state.broker}
                                />
                                <DatePicker
                                    handleClick={(e) => this.handleInvestmentDateClick(e)}
                                    handleApply={this.handleInvestmentDateApply}
                                    showingdate={this.state.investmentDateTemp}
                                    dateChange={this.handleInvestmentDateChange}
                                    handleClose={this.handleInvestmentDateClose}
                                    anchorEl={this.state.anchorEl}
                                    error={this.state.investmentDateError}
                                    maxdate={moment().format("DD MMM YYYY")}
                                    // allowed={false} 
                                    id="dp_aifInvestmentDate"
                                    label={labels.Nps.investmentDate}
                                    placeholder={labels.Nps.investmentDatePlaceholder}
                                    date={this.state.investmentDate} />

                            </div>
                            <div className={classes.subHeader1}>{labels.Nps.allocationUnits}</div>

                            <div className={classes.allocationUnitsGrid}>
                                <Textinput handleChange={this.handleEquityChange}
                                    value={this.state.equity}
                                    error={this.state.equityError}
                                    otherProps={{ autoFocus: true, }}
                                    align={"right"}
                                    paddingAlign={10}
                                    page={"sideLabel"} label={labels.Nps.equity}
                                //  placeholder={labels.Nps.latestValuePlaceholder}
                                />
                                <Textinput handleChange={this.handleCommodityChange}
                                    value={this.state.commodity}
                                    error={this.state.commodityError}
                                    otherProps={{ autoFocus: true, }}
                                    align={"right"}
                                    paddingAlign={10}
                                    page={"sideLabel"} label={labels.Nps.commodity}
                                //  placeholder={labels.Nps.latestValuePlaceholder}
                                />
                                <Textinput handleChange={this.handleGoldChange}
                                    value={this.state.gold}
                                    error={this.state.goldError}
                                    otherProps={{ autoFocus: true, }}
                                    align={"right"}
                                    paddingAlign={10}
                                    page={"sideLabel"} label={labels.Nps.gold}
                                //placeholder={labels.Nps.latestValuePlaceholder}
                                />
                                <Textinput handleChange={this.handleOtherChange}
                                    value={this.state.other}
                                    error={this.state.otherError}
                                    otherProps={{ autoFocus: true, }}
                                    align={"right"}
                                    paddingAlign={10}
                                    page={"sideLabel"} label={labels.Nps.other}
                                //  placeholder={labels.Nps.latestValuePlaceholder}
                                />
                                <div className={classes.spanmsg}>
                                    <span className={classes.spanerr}>{this.state.commonError}</span>
                                </div>
                            </div>
                            <div className={classes.subHeader1} >{labels.Nps.allocationAmount}</div>

                            <div className={classes.allocationUnitsGrid}>
                                <Textinput handleChange={this.handleEquityNavChange}
                                    value={this.state.equityNav}
                                    error={this.state.equityNavError}
                                    otherProps={{ autoFocus: true, }}
                                    paddingAlign={10}
                                    align={"right"}
                                    page={"sideLabel"} label={labels.Nps.equity}
                                //  placeholder={labels.Nps.latestValuePlaceholder}
                                />
                                <Textinput handleChange={this.handleCommodityNavChange}
                                    value={this.state.commodityNav}
                                    error={this.state.commodityNavError}
                                    align={"right"}
                                    paddingAlign={10}
                                    otherProps={{ autoFocus: true, }}
                                    page={"sideLabel"} label={labels.Nps.commodity}
                                //  placeholder={labels.Nps.latestValuePlaceholder}
                                />
                                <Textinput handleChange={this.handleGoldNavChange}
                                    value={this.state.goldNav}
                                    error={this.state.goldNavError}
                                    align={"right"}
                                    paddingAlign={10}
                                    otherProps={{ autoFocus: true, }}
                                    page={"sideLabel"} label={labels.Nps.gold}
                                //placeholder={labels.Nps.latestValuePlaceholder}
                                />
                                <Textinput handleChange={this.handleOtherNavChange}
                                    value={this.state.otherNav}
                                    error={this.state.otherNavError}
                                    align={"right"}
                                    paddingAlign={10}
                                    otherProps={{ autoFocus: true, }}
                                    page={"sideLabel"} label={labels.Nps.other}
                                //  placeholder={labels.Nps.latestValuePlaceholder}
                                />
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div style={{ color: window.globalConfig.color.red, fontSize: 12 }}>{this.state.transactionListingError}</div>
                                <div style={{ textAlignLast: "right", marginLeft: 10, marginTop: 10, marginBottom: 10 }}>
                                    <OutlinedButton text={labels.Nps.addTransaction} onClick={this.handleAddTransaction} />
                                </div>
                            </div>

                            {this.state.transactionBackupListing.length != 0 && <>
                                <div className={classes.popupListGrid} style={{ fontSize: 14, textTransform: "uppercase", color: window.globalConfig.color.LABLE_GRAY, letterSpacing: 2, marginBottom: 10, textAlign: 'right' }}>
                                    <div></div> <div></div> <div></div>
                                    <div>{labels.Nps.equity}</div>
                                    <div>{labels.Nps.commodity}</div>
                                    <div>{labels.Nps.gold}</div>
                                    <div>{labels.Nps.other}</div>
                                </div>
                                <DynamicList loader={this.state.transactionLoader} list={this.state.transactionListing} grid={classes.popupListGrid}
                                    header={[{ "label": "Transaction Type", "align": "left" }, { "label": "Investment Date", "align": "left" }, { "label": "Amount", "align": "right" }, { "label": "Units", "subLabel": "Nav", "align": "right" }, { "label": "Units", "subLabel": "Nav", "align": "right" }, { "label": "Units", "subLabel": "Nav", "align": "right" }, { "label": "Units", "subLabel": "Nav", "align": "right" }]}
                                    count={7} enableIcon={true} popupList={[{ 'name': 'Edit', 'onClick': this.handleEditTransaction }, { 'name': 'Delete', 'onClick': this.handleDeleteTransaction }]}></DynamicList>
                            </>}
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between" }}>
                                <div className={classes.subHeader}>{labels.Nps.valuation}</div>
                            </div>
                            <div className={classes.cardSeparatorLine}></div>
                            <div className={classes.secondContainerThirdHalf}>
                                <div>
                                    {/* <div className={classes.subHeader1}>{labels.Nps.latestValueDate}</div> */}

                                    <Textinput handleChange={this.handleLatestValueChange}
                                        value={this.state.latestValue}
                                        error={this.state.latestValueError}
                                        page={"normal"} label={labels.Nps.latestValue}
                                        placeholder={labels.Nps.latestValuePlaceHolder}
                                    />

                                </div>
                                <div>
                                    <div className={classes.subHeader1}>{labels.Nps.latestValueDate}</div>

                                    <DatePicker
                                        handleClick={(e) => this.handleLatestValueDateClick(e)}
                                        handleApply={this.handleLatestValueDateApply}
                                        showingdate={this.state.latestValueDateTemp}
                                        dateChange={this.handleLatestValueDateChange}
                                        handleClose={this.handleLatestValueDateClose}
                                        anchorEl={this.state.anchorElLatestValue}
                                        error={this.state.latestValueDateError}
                                        maxdate={moment().format("DD MMM YYYY")}
                                        // allowed={false} 
                                        id="dp_latestvalue"
                                        // label={""}
                                        placeholder={labels.Nps.latestValueDatePlaceholder}
                                        date={this.state.latestValueDate} />

                                </div>
                            </div>
                            <div className={classes.unitsPadding}>
                                <div className={classes.subHeader1} >{labels.Nps.valuationUnit}</div>

                                <div className={classes.allocationUnitsGrid}>
                                    <Textinput handleChange={this.handleValuationEquityNavChange}
                                        value={this.state.valuationEquityNav}
                                        error={this.state.valuationEquityNavError}
                                        align={"right"}
                                        paddingAlign={10}
                                        otherProps={{ autoFocus: true, }}
                                        page={"sideLabel"} label={labels.Nps.equity}
                                    //  placeholder={labels.Nps.latestValuePlaceholder}
                                    />
                                    <Textinput handleChange={this.handleValuationCommodityNavChange}
                                        value={this.state.valuationCommodityNav}
                                        error={this.state.valuationCommodityNavError}
                                        otherProps={{ autoFocus: true, }}
                                        align={"right"}
                                        paddingAlign={10}
                                        page={"sideLabel"} label={labels.Nps.commodity}
                                    //  placeholder={labels.Nps.latestValuePlaceholder}
                                    />
                                    <Textinput handleChange={this.handleValuationGoldNavChange}
                                        value={this.state.valuationGoldNav}
                                        error={this.state.valuationGoldNavError}
                                        otherProps={{ autoFocus: true, }}
                                        align={"right"}
                                        paddingAlign={10}
                                        page={"sideLabel"} label={labels.Nps.gold}
                                    //placeholder={labels.Nps.latestValuePlaceholder}
                                    />
                                    <Textinput handleChange={this.handleValuationOtherNavChange}
                                        value={this.state.valuationOtherNav}
                                        error={this.state.valuationOtherNavError}
                                        otherProps={{ autoFocus: true }}
                                        align={"right"}
                                        paddingAlign={10}
                                        page={"sideLabel"} label={labels.Nps.other}
                                    //  placeholder={labels.Nps.latestValuePlaceholder}
                                    />
                                </div>

                            </div>
                            <div>
                                <div className={classes.subHeader1} >{labels.Nps.valuationAmount}</div>

                                <div className={classes.allocationUnitsGrid}>
                                    <Textinput handleChange={this.handleValuationEquityAmountChange}
                                        value={this.state.valuationEquityAmount}
                                        error={this.state.valuationEquityAmountError}
                                        align={"right"}
                                        paddingAlign={10}
                                        otherProps={{ autoFocus: true, }}
                                        page={"sideLabel"} label={labels.Nps.equity}
                                    //  placeholder={labels.Nps.latestValuePlaceholder}
                                    />
                                    <Textinput handleChange={this.handleValuationCommodityAmountChange}
                                        value={this.state.valuationCommodityAmount}
                                        error={this.state.valuationCommodityAmountError}
                                        otherProps={{ autoFocus: true, }}
                                        align={"right"}
                                        paddingAlign={10}
                                        page={"sideLabel"} label={labels.Nps.commodity}
                                    //  placeholder={labels.Nps.latestValuePlaceholder}
                                    />
                                    <Textinput handleChange={this.handleValuationGoldAmountChange}
                                        value={this.state.valuationGoldAmount}
                                        error={this.state.valuationGoldAmountError}
                                        otherProps={{ autoFocus: true, }}
                                        align={"right"}
                                        paddingAlign={10}
                                        page={"sideLabel"} label={labels.Nps.gold}
                                    //placeholder={labels.Nps.latestValuePlaceholder}
                                    />
                                    <Textinput handleChange={this.handleValuationOtherAmountChange}
                                        value={this.state.valuationOtherAmount}
                                        error={this.state.valuationOtherAmountError}
                                        otherProps={{ autoFocus: true }}
                                        align={"right"}
                                        paddingAlign={10}
                                        page={"sideLabel"} label={labels.Nps.other}
                                    //  placeholder={labels.Nps.latestValuePlaceholder}
                                    />
                                </div>

                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between" }}>
                                <div className={classes.subHeader}>{labels.Nps.nomineeDetails}</div>
                            </div>
                            <div className={classes.cardSeparatorLine}></div>
                            <div className={classes.secondContainerFourthHalf}>

                                <CommonDropdown
                                    //  autoselect={true}
                                    handleOnChange={(val) => this.setState({ nomineeType: val[0].label, nomineeTypeID: val[0].value, nomineeTypeError: "" })}
                                    value={this.state.nomineeType}
                                    error={this.state.nomineeTypeError}
                                    type={"normalwithsearch"}
                                    //disabled={this.state.transactionBackupListing.length == 0 || this.state.edit ? true : false}
                                    id="ddl_transactiontype"
                                    placeholder={labels.Nps.nomineeTypePlaceHolder}
                                    label={labels.Nps.nomineeType}
                                    height={190}
                                    List={this.state.nomineeTypeList}
                                // value={this.state.broker}
                                />
                                <Textinput handleChange={this.handleNomineeNameChange}
                                    value={this.state.nomineeName}
                                    error={this.state.nomineeNameError}
                                    page={"normal"} label={labels.Nps.nomineeName}
                                    placeholder={labels.Nps.nomineeNamePlaceHolder}
                                />
                                <CommonDropdown
                                    //  autoselect={true}
                                    handleOnChange={(val) => this.setState({ nomineeRelationship: val[0].label, nomineeRelationshipID: val[0].value, nomineeRelationshipError: "" })}
                                    value={this.state.nomineeRelationship}
                                    error={this.state.nomineeRelationshipError}
                                    type={"normalwithsearch"}
                                    //disabled={this.state.transactionBackupListing.length == 0 || this.state.edit ? true : false}
                                    id="ddl_transactiontype"
                                    placeholder={labels.Nps.nomineeRelationshipPlaceHolder}
                                    label={labels.Nps.nomineeRelationship}
                                    height={190}
                                    List={this.state.nomineeRelationshipList}
                                // value={this.state.broker}
                                />
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <CommonCheckbox label={labels.Nps.isMinor} checked={this.state.isMinor} onChange={() => this.setState({ ...this.state, isMinor: !this.state.isMinor })}
                                    ></CommonCheckbox>
                                    {/* <label>{labels.Nps.isMinor}</label> */}
                                </div>
                                <DatePicker
                                    handleClick={(e) => this.setState({ anchorEl: e.currentTarget })}
                                    handleApply={(date) => this.setState({ nomineeDOB: moment(date).format("DD MMM YYYY"), nomineeDOBError: "" })}
                                    showingdate={this.state.nomineeDOBTemp}
                                    dateChange={(value) => this.setState({ nomineeDOBTemp: moment(value).format("DD MMM YYYY") })}
                                    handleClose={() => this.setState({ anchorEl: null })}
                                    anchorEl={this.state.anchorEl}
                                    error={this.state.nomineeDOBError}
                                    maxdate={moment().format("DD MMM YYYY")}
                                    // allowed={false} 
                                    id="dp_aifInvestmentDate"
                                    label={labels.Nps.nomineeDOB}
                                    placeholder={labels.Nps.nomineeDOBPlaceHolder}
                                    date={this.state.nomineeDOB}
                                />
                                <Textinput handleChange={this.handlePercentageChange}
                                    value={this.state.nomineepercentage}
                                    error={this.state.nomineepercentageError}
                                    page={"normal"} label={labels.Nps.nomineepercentage}
                                    placeholder={labels.Nps.nomineepercentagePlaceHolder}
                                />
                                {
                                    this.state.isMinor
                                    && <>
                                        <Textinput handleChange={this.handleNomineeGuardianNameChange}
                                            value={this.state.guardianName}
                                            error={this.state.guardianNameError}
                                            page={"normal"} label={labels.Nps.guardianName}
                                            placeholder={labels.Nps.guardianNamePlaceHolder}
                                        />
                                    </>
                                }


                            </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div style={{ color: window.globalConfig.color.red, fontSize: 12 }}>{this.state.transactionListingError}</div>
                                <div style={{ textAlignLast: "right", marginLeft: 10, marginTop: 10, marginBottom: 10 }}>
                                    <OutlinedButton disabled={this.state.nomineeButtonDisable} text={labels.Nps.addNominee} onClick={()=>this.handleAddNomineeDetails("summary")} />
                                </div>
                            </div>
                            {
                                this.state.nomineeBackupListing.length != 0
                                && <DynamicList loader={this.state.nomineeLoader} list={this.state.nomineeListing} grid={classes.popupListGrid}
                                    header={[{ "label": "Nominee Type", "align": "left" }, { "label": "Nominee Name", "align": "left" }, { "label": "Nominee RelationShip", "align": "right" }, { "label": "Nominee DOB", "align": "right" }, { "label": "Nominee Percentage", "align": "right" }, { "label": "Is Minor", "align": "right" }, { "label": "Guardian Name", "align": "right" }]}
                                    count={7} enableIcon={true} popupList={[{ 'name': 'Edit', 'onClick': this.handleEditNomineeDetails }, { 'name': 'Delete', 'onClick': this.handleDeleteNomineeDetails }]}>
                                </DynamicList>
                            }
                            <div style={{ marginTop: "2%", display: "flex", flexDirection: "row", marginBottom: "2%", justifyContent: "flex-end" }} >
                                <div className={classes.buttonStyle}>
                                    <CommonButton name={labels.Nps.save} page={"primary"}
                                        loader={this.state.loader}
                                        disabled={this.state.loader || this.state.addMoreLoader}
                                        loading={"true"}
                                        onClick={() => this.handleSubmit("save")} />
                                    {/* <CommonButton name={"Cancel"} onClick={this.handleClose} /> */}
                                </div>
                                <div style={{ marginLeft: "5%" }} className={classes.buttonStyle}>
                                    <CommonButton name={labels.Nps.saveAndMore} page={"primary"}
                                        loader={this.state.addMoreLoader}
                                        loading={"true"}
                                        disabled={this.state.loader || this.state.addMoreLoader}

                                        onClick={() => this.handleSubmit("addMore")} />
                                </div>

                            </div>
                        </div>


                        <div className={classes.sideSpace}></div>

                    </div>
                    <CommonSnackbar message={this.state.message} open={this.state.openSnackBar} severity={this.state.severity} handlesnanckClose={this.handleToastClose} />

                </ErrorBoundary>
            )

        } catch (err) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => {
    //
    return {
        sessionID: state.Login.sessionID,
        clientID: state.Login.logindetails.ClientID,
        logindetails: state.Login.logindetails,
        L5ID: state.Login.L5ID,
        transactionTypeList: state.Dataentry.npsAndInsuranceDropdownList != null && state.Dataentry.npsAndInsuranceDropdownList.length != 0 && state.Dataentry.npsAndInsuranceDropdownList.table1 != null && state.Dataentry.npsAndInsuranceDropdownList.table1.length != 0 ? state.Dataentry.npsAndInsuranceDropdownList.table1.map((item) => {
            return { "label": item.TEXT, "value": item.VALUE }
        }) : [],
        nomineeRelationshipList: state.Dropdown.dropdowndetails.relationShipList

    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getL5reportDetails, npsListing, }, dispatch)
}
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(Nps_DataEntry))
