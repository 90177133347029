import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Menu from '../../components/menu/menu'
import Usercard from '../../components/profile/usercard';
// import Footer from '../../components/footer/footer';
import { withRouter } from 'react-router'
import RedemptionDetails from './redemptiondetails'
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { bindActionCreators } from 'redux';
import RedemptionReview from './redemptionreview'
import Common from '../../components/commonpaymentconfirm/common'
import { amountCommaSeperator, AmountValidation, NegativeFinding, numbersandDeciamlOnly,replaceNull, openRequestedSinglePopup, scrollToTopPage } from '../../utils/commonfunction'
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import getRedemptionApidetails from '../../api/redemption/redemption';
import constants from '../../utils/constants/apiconstant'
import getPortfolioxraySchemeDetails from '../../api/portfolioxrayschemedetail/portfolioxrayschemedetail'
import secureStorage from '../../utils/securestorage';
import CommonSnackbar from '../../components/snackbar/snackbar';
import color from "../../utils/colors/colors";
import IpaddressFinding from '../../api/ip_address/ip_address';
import { RedemptionChange } from '../../redux/actions/filter/reportsfilter';
import colors from '../../utils/colors/colors';
import getPaymentGatewayDetails from '../../api/paymentgateway/paymentgateway';
import Deletepopup from '../../components/popup/deletepopup';
import getAllDeviceToken from '../../api/alert/getalldevicetoken';
import getSendPushNotification from '../../api/alert/sendpushnotification';
const styles = theme => ({
  collabel: {
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: "14px",
    textAlign: "left",
  },
  bodyHeader: {
    height: 75,
    paddingLeft: "5.4%",
    // paddingTop: "5%",
    paddingRight: "6.5%",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: window.globalConfig.color.PRIMARY,

    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.between('600', '851')]: {
      height: 60,
      paddingTop: "10%",
    },
    [theme.breakpoints.down('600')]: {
      paddingBottom: 11,
      alignItems: "end"
    },
  },
  redemptionHeader: {
    textAlign: "left",
    fontSize: 25,
    fontWeight: "bolder",
    color: window.globalConfig.color.WHITE,
    // marginTop: "1.8%",
    marginLeft: "6%",
    [theme.breakpoints.down('600')]: {
      textAlign: "left",
      fontSize: 20,
      fontWeight: "bolder",
      color: window.globalConfig.color.WHITE,
      marginTop: "11%",
      marginLeft: "0%",
    },
  },

  btnApply: {
    background: window.globalConfig.color.PRIMARY_BORDER,
    marginLeft: "7px",
    padding: 0,
    lineHeight: "36px",
    color: window.globalConfig.color.WHITE,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    width: "100px",
    float: "right",
    borderRadius: "5px",
    border: "1px solid transparent",
    '&:hover': {
      background: window.globalConfig.color.lightBlue,
      color: window.globalConfig.color.WHITE,
    }
  },
  btnBack: {
    background: window.globalConfig.color.RosyBrown,
    marginLeft: "7px",
    padding: 0,
    lineHeight: "36px",
    color: window.globalConfig.color.BLACK,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    width: "100px",
    float: "right",
    borderRadius: "5px",
    border: "1px solid transparent",
  },
  divMain: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "9%",
    marginTop: "3%",
    paddingBottom: "3%",
    [theme.breakpoints.down(1037)]: {
      flexDirection: "column"
    },

  },
  divDetail: {
    height: '-webkit-fill-available',
    width: "-webkit-fill-available",
    // width: "63%",
    backgroundColor: window.globalConfig.color.WHITE,
    marginLeft: "2.5%",
    marginBottom: "4%",
    border: `1px solid ${window.globalConfig.color.WHITE}`,
    boxShadow: "0 0 5px 8px rgba(200, 200, 250, 0.1)",
    paddingBottom: "30px",
    borderRadius: 6,
    [theme.breakpoints.down(920)]: {
      width: "100%",
    },
    [theme.breakpoints.down(600)]: {
      width: "94%",
      margin: "auto"
    },
  },
  divDetail1: {
    height: '-webkit-fill-available',
    width: "99%",
    backgroundColor: window.globalConfig.color.WHITE,
    border: `1px solid ${window.globalConfig.color.WHITE}`,
    boxShadow: "0 0 5px 8px rgba(200, 200, 250, 0.1)",
    paddingBottom: "30px",
    borderRadius: 6,
  },

  //Added by Celsia on 29 May 2021 for Mobile Responsive Started
  // desktopResponsive: {
  //   display: 'block !important',
  //   [theme.breakpoints.down("600")]: {
  //     display: 'none !important',
  //   },
  // },
  redemptionHeader1: {
    textAlign: "left",
    fontSize: 20,
    fontWeight: "bolder",
    color: window.globalConfig.color.WHITE,
    marginTop: "10.6%",
  },
  // mobileResponsive: {
  //   display: 'none !important',
  //   [theme.breakpoints.down("600")]: {
  //     display: 'block!important',
  //   },
  // },
  bodyHeader1: {
    height: 86, marginTop: "4.5%",
    paddingLeft: "3.4%",
    paddingTop: "2%",
    backgroundColor: window.globalConfig.color.PRIMARY,
    color: window.globalConfig.color.red,
    display: "flex",
    flexDirection: "column"
  },
  divMainResponsive: {
    margin: "10px",
    paddingBottom: "7%"
  },
  divDetailResponsive: {
    height: '-webkit-fill-available',
    width: "100%",
    backgroundColor: window.globalConfig.color.WHITE,
    marginBottom: "4%",
    border: `1px solid ${window.globalConfig.color.BORDER}`,
    boxShadow: "0 0 10px 0 rgb(19 89 161 / 17%)",
    paddingBottom: "30px",
    borderRadius: "10px",
    marginTop: "10px"
  },
  //Ended
  backStyle: {
    display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: "5%",
    [theme.breakpoints.down('600')]: {
      alignItems: "end"
    },
  },
  backArrowStyle: {
    color: window.globalConfig.color.WHITE,
    [theme.breakpoints.down('600')]: {
      [theme.breakpoints.down('600')]: {
        margin: 0
      },
    }
  }


});
let timer = ""
class Redemption extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      confirmFlag: false,
      paymentFlag: false,
      amount: "",
      units: "",
      redemptiontype: "Amount (Rs)",
      amountValue: "",
      availableUnit: "",
      amountDisabled: false,
      isAllAvailableUnit: false, errorenable: false, errormessage: "",
      status: "",
      message: "",
      SchemeDetails: [],
      folionumber: "",
      foliodropdown: [],
      shortterm: "",
      longterm: "",
      exitunit: "",
      availableAmount: "",
      Totalavailableunits: "",
      loader: false,
      schemeCode: '',
      selectedDataTable: [],
      open: false,
      schemecodeerror: '',
      orderID: "",
      totalTable: [],
      value: "",
      ucc: "NA",
      openConfirmPopup: false

    }
  }
  async componentDidMount() {
    scrollToTopPage()
    timer = setTimeout(() => {
      this.setState({ show: true })
    }, 7000);
    this.setState({ loader: false })

    getPortfolioxraySchemeDetails(this.props.logindetails.ClientID, 0, this.props.location.state.data.SchemeID, true, "REDEEM").then((item) => {
      if (item.status === "S") {
        this.setState({ SchemeDetails: item })
        if (item.length != 0) {
          if (item.folioNumber != null && item.folioNumber?.length != 0) {
            this.setState({ folionumber: item.folioNumber[0]?.FolioNo, ucc: item.folioNumber[0]?.MutualFundUCC, foliodropdown: item.folioNumber })
          } else {
            this.setState({ ucc: "" })
          }
          if (item.totalTable != null && item.totalTable?.length != 0) {
            let TotalTable = item.totalTable.filter((val) => val.FolioNo == item.folioNumber[0]?.FolioNo)
            this.setState({
              shortterm: TotalTable[0]?.TotalShortTermUnits,
              longterm: TotalTable[0]?.TotalLongTermUnits,
              exitunit: TotalTable[0]?.TotalExitLoadUnits,
              availableAmount: TotalTable[0]?.TotalAvailableAmount,
              Totalavailableunits: TotalTable[0]?.TotalAvailableUnits,
              totalTable: item.totalTable
            })
          }
          if (item.dataTable != null && item.dataTable?.length > 0) {
            this.setState({
              selectedDataTable: item.dataTable,
            })
          } else {
            this.setState({
              selectedDataTable: [],
            })
          }
        }

      } else {
        this.setState({
          selectedDataTable: [],
          ucc: ""
        })
      }
    })
    if (this.props.bankdetails.filter((items, index) => items.UCC == this.state.ucc && items.IsDefault).length == 0) {
      this.setState({ value: this.props.bankdetails.filter((items, index) => items.UCC == this.state.ucc)[0]?.BankAccountNumber })

    } else {
      this.setState({ value: this.props.bankdetails.filter((items, index) => items.UCC == this.state.ucc && items.IsDefault)[0]?.BankAccountNumber })

    }
    scrollToTopPage();
  }
  componentWillUnmount() {
    clearTimeout(timer)
  }
  handlesnanckClose = (reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false })
  };
  onClickOrder = (value) => {
    this.setState({
      confirmFlag: value
    })
  }
  onClickPayment = () => {
    if (this.state.selectedDataTable.length > 0) {
      let selectedMappingDetails = '';
      let schemeCode
      if (!this.state.loader) {
        this.setState({ loader: true, openConfirmPopup: false })
        selectedMappingDetails = this.state.selectedDataTable
        if (selectedMappingDetails.length > 0) {
          var val = ""
          var currancyvalue = ""
          if (this.state.redemptiontype === "Amount (Rs)") {
            val = this.state.amountValue.replaceAll(",", "");
            currancyvalue = val.replaceAll(labels.currency.rupeesymbol, "");
          } else {
            currancyvalue = 0
          }
          schemeCode = selectedMappingDetails[0]?.SchemeCode;


          getRedemptionApidetails(constants.Redemption.TransCode, 0,
            this.state.ucc, schemeCode,
            constants.Redemption.BuySell, constants.Redemption.BuySellType,
            constants.Redemption.DPTxn, Number(currancyvalue) == null ? 0 : Number(currancyvalue), Number(this.state.units) == null ? 0 : Number(this.state.units),
            this.state.redemptiontype === "Amount (Rs)" || this.state.redemptiontype === "Custom No. of Units" ? "N" : "Y", this.state.folionumber, constants.Redemption.Remarks
            , constants.Redemption.KYCStatus, "REF_0001", this.props.logindetails.SubBrokerCode,

            this.props.location.state.data.BSESchemeType === 1 ? "" : constants.Common.EUINNumber, this.props.location.state.data.BSESchemeType === 1 ? "N" : "Y", constants.Redemption.MinRedeem, constants.Redemption.DPC, secureStorage.getItem("ip"),
            constants.Redemption.Param1, constants.Redemption.Param2, this.state.value, this.props.location.state.data.SchemeID, this.props.logindetails.ClientID, constants.Common.Source,false).then(async (res) => {
              // if (res.status == "Success") {
              this.setState({
                paymentFlag: true, message: res.response.message, status: res.response.status, orderID: res.response.orderID
              })
              if (this.props.logindetails.ClientID == 20427 || this.props.logindetails.ClientID == 21827) {
                // push notification on mobile.. commited by dulcy
                let data = {}
                data.ClientID = this.props.logindetails.ClientID
                await getAllDeviceToken(data).then(async (response) => {
                  if (response.status == "S") {
                    if (replaceNull(response.table1, "NA") != "NA") {

                      let params = {}
                      params.DeviceTokens = response.table1.map((item) => {
                        return item.DeviceToken
                      })
                      params.Title = res.status == "S" ? "Trading completed successfully" : "Trading failed"
                      params.Body = replaceNull(res.response.message, "NA")
                      await getSendPushNotification(params).then((response) => {

                      })
                    }

                  }
                })
              }
              // } else {
              //   this.setState({
              //     message: res.response.message, status: res.response.status, loader: false
              //   })
              // }
              this.setState({ loader: false })
            }).catch((err) => {
              this.setState({
                loader: false,
                // schemecodeerror: labels.messages.apierror,
                // open: true,
                paymentFlag: true,
                status: "F",
                message: labels.messages.apierror
              });
            })
        } else {
          this.setState({
            loader: false,
            schemecodeerror: labels.investmentPopup.redeemNotAllowed,
            open: true
          });
        }
      }
    } else {
      this.setState({
        loader: false,
        schemecodeerror: labels.investmentPopup.redeemNotAllowed,
        open: true
      });
    }
  }
  redemTypeChange = (event) => {
    this.setState({ redemptiontype: event[0].label, errorenable: false, errormessage: "" })
    if (event[0].label == "All Exit Load Free Units") {
      this.setState({ amountValue: "", availableUnit: 10200, amountDisabled: true, isAllAvailableUnit: false })
    }
    else if (event.label == "All Available No. of Units") {
      this.setState({ amountValue: this.props.location.state.data.currentvalue, availableUnit: this.props.location.state.data.units, amountDisabled: true, isAllAvailableUnit: true })
    }
    else {
      this.setState({ amountValue: "", availableUnit: 0, amountDisabled: false, isAllAvailableUnit: false })
    }
    this.props.RedemptionChange(event, labels.TradingType)
  }

  amountChange = (event) => {
    var val = event.target.value.replaceAll(",", "");
    var currancyvalue = val.replaceAll(labels.currency.rupeesymbol, "").trim();
    if (currancyvalue.trim() == '') {
      this.setState({ amountValue: "" })
    }
    else {

      if (!numbersandDeciamlOnly(currancyvalue, 16)) {
        event.preventDefault();
      }
      else {
        // console.log(typeof(currancyvalue),Number(currancyvalue).toLocaleString('en-IN'))
        this.setState({ errorenable: false, errormessage: "" })
        this.setState({ amountValue: `${labels.currency.rupeesymbol} ${amountCommaSeperator(currancyvalue.trim())}` })


      }
    }
  }
  confirmPopup = () => {
    this.setState({ openConfirmPopup: true })
  }
  unitsChange = (event) => {
    this.setState({ errorenable: false, errormessage: "" })
    if (numbersandDeciamlOnly(event.target.value, 10)) {
      this.setState({ units: event.target.value })
    } else {
      event.preventDefault()
    }
  }
  confirmOrder = () => {
    var isValid = true
    if (this.state.redemptiontype === "Amount (Rs)") {
      var val = this.state.amountValue.replaceAll(",", "");
      var currancyvalue = val.replaceAll(labels.currency.rupeesymbol, "");
      if (currancyvalue != "") {
        if (currancyvalue > 999) {
          if (this.state.totalTable.length != 0) {
            if (currancyvalue < this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalAvailableAmount) {
            } else {
              isValid = false
              this.setState({ errorenable: true, errormessage: `Amount should be less than ${NegativeFinding(this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalAvailableAmount || 0, "", "", "", "rupee")}` })
            }
          } else {
            isValid = false
            this.setState({ open: true, schemecodeerror: labels.investmentPopup.redeemNotAllowed })
          }
        }
        else {
          isValid = false
          this.setState({ errorenable: true, errormessage: `Minimum Amount ${labels.currency.rupeesymbol} 1000` })
        }
      } else {
        isValid = false
        this.setState({ errorenable: true, errormessage: labels.messages.required })

      }
    } else if (this.state.redemptiontype === "Custom No. of Units") {
      if (this.state.units != "") {
        if (this.state.units > 9) {
          if (this.state.totalTable.length != 0) {
            if (this.state.units < this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalAvailableUnits) {
            } else {
              isValid = false
              this.setState({ errorenable: true, errormessage: `Units should be less than  ${NegativeFinding(this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalAvailableUnits || 0, "", "", "", "number")}` })
            }
          }
          else {
            isValid = false
            this.setState({ open: true, schemecodeerror: labels.messages.apierror })
          }
        } else {
          isValid = false
          this.setState({ errorenable: true, errormessage: `Minimum Units 10` })
        }
      } else {
        isValid = false
        this.setState({ errorenable: true, errormessage: labels.messages.required })
      }
    } else if (this.state.redemptiontype === "All Available No. of Units") {
      if (this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalAvailableUnits == undefined || this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalAvailableUnits == 0) {

        isValid = false
        this.setState({ open: true, schemecodeerror: labels.messages.Zerovalidation })
      }
    } else if (this.state.redemptiontype === "All Exit Load Free Units") {
      if (this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalExitLoadUnits == undefined || this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalExitLoadUnits == 0) {

        isValid = false
        this.setState({ open: true, schemecodeerror: labels.messages.Zerovalidation })
      }

    } else if (this.state.redemptiontype === "All Short Term Gain Units") {
      if (this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalShortTermUnits == undefined || this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalShortTermUnits != 0) {

        isValid = false
        this.setState({ open: true, schemecodeerror: labels.messages.Zerovalidation })
      }
    } else if (this.state.redemptiontype === "All Long Term Gain Units") {
      if (this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalLongTermUnits == undefined || this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalLongTermUnits != 0) {
        // } else {
        isValid = false
        this.setState({ open: true, schemecodeerror: labels.messages.Zerovalidation })
      }
    }
    if (isValid) {
      if (this.props.bankdetails.filter((items, index) => items.UCC == this.state.ucc && items.IsDefault).length == 0) {
        this.setState({ value: this.props.bankdetails.filter((items, index) => items.UCC == this.state.ucc)[0]?.BankAccountNumber })

      } else {
        this.setState({ value: this.props.bankdetails.filter((items, index) => items.UCC == this.state.ucc && items.IsDefault)[0]?.BankAccountNumber })

      }
      if (this.state.selectedDataTable.length > 0) {
        let selectedMappingDetails = '';
        // let schemeCode
        selectedMappingDetails = this.state.selectedDataTable
        if (selectedMappingDetails.length > 0) {
          this.onClickOrder(true)
        } else {
          this.setState({
            schemecodeerror: labels.investmentPopup.redeemNotAllowed,
            open: true
          });
        }
      } else {
        this.setState({
          schemecodeerror: labels.investmentPopup.redeemNotAllowed,
          open: true
        });
      }
    }
  }
  handleClickfolio = (val) => {
    this.setState({ folionumber: val.FolioNo, ucc: val.MutualFundUCC })
    // let TotalTable = this.state.totalTable.filter((item) => item.FolioNo == val.FolioNo)
    // this.setState({
    //   shortterm: TotalTable[0]?.TotalShortTermUnits,
    //   longterm: TotalTable[0]?.TotalLongTermUnits,
    //   exitunit: TotalTable[0]?.TotalExitLoadUnits,
    //   availableAmount: TotalTable[0]?.TotalAvailableAmount,
    //   Totalavailableunits: TotalTable[0]?.TotalAvailableUnits,
    // })
  }
  back = () => {
    this.setState({ confirmFlag: false })
  }
  bankChange = (event) => {
    //console.log(val)
    this.setState({ value: event.target.value })
  }
  render() {
    const { classes } = this.props;
    try {
      return (<>
        <div style={{ height: "auto", maxHeight: "auto" }}  >
          {/* <Menu childFunc={this.props.childFunc} /> */}
          <div className={classes.bodyHeader}>
            <div className={classes.redemptionHeader}>
              {labels.Redemption.redemptionName}
            </div>
            <div className={classes.backStyle} style={{ opacity: this.state.loader ? 0.5 : 1, cursor: this.state.viewloader ? "not-allowed" : "pointer" }} onClick={() => {
              if (!this.state.loader) {
                this.props.history.push({
                  pathname: labels.Path.MutualTab,
                  state: { tabValue: 1 }
                })
              }
            }}>
              <ArrowBackIos
                color={window.globalConfig.color.WHITE}

                className={classes.backArrowStyle}
              />
              <p
                className={classes.backArrowStyle}
              >
                {"Back"}
              </p>
            </div>
          </div>
          {this.state.paymentFlag && <div className={classes.divMain}>
            <div className={classes.divDetail1}>
              <Common page='RedemptionDetails'
                longterm={this.state.longterm} shortterm={this.state.shortterm}
                availableamount={this.state.availableAmount}
                exitunit={this.state.exitunit}
                UCC={this.state.ucc}
                AccountNumber={this.state.value}
                folionumber={this.state.folionumber} amountValue={this.state.amountValue}
                units={this.state.units} redemptiontype={this.state.redemptiontype}
                data={this.props.location.state.data}
                Totalavailableunits={this.state.Totalavailableunits}
                status={this.state.status} message={this.state.message}
                orderID={this.state.orderID}></Common>
            </div>
          </div>
          }
          {!this.state.paymentFlag && <div className={classes.divMain}>
            <Usercard page="redemptionDesktop" />
            <div className={classes.divDetail}>
              {this.state.confirmFlag && <RedemptionReview loader={this.state.loader}
                longterm={this.state.longterm} shortterm={this.state.shortterm}
                availableamount={this.state.availableAmount}
                UCC={this.state.ucc}
                bankChange={this.bankChange}
                totalTable={this.state.totalTable}
                value={this.state.value}
                Totalavailableunits={this.state.Totalavailableunits} exitunit={this.state.exitunit}
                folionumber={this.state.folionumber}
                amountValue={this.state.amountValue}
                units={this.state.units}
                redemptiontype={this.state.redemptiontype}
                data={this.props.location.state.data}
                onConfirmOrder={(value) => this.onClickOrder(value)} confirmPayment={() => this.confirmPopup()} />}
              {!this.state.confirmFlag && <RedemptionDetails
                handleClickfolio={this.handleClickfolio}
                totalTable={this.state.totalTable}
                longterm={this.state.longterm}
                shortterm={this.state.shortterm}
                availableamount={this.state.availableAmount}
                Totalavailableunits={this.state.Totalavailableunits}
                exitunit={this.state.exitunit}
                foliodropdown={this.state.foliodropdown}
                folionumber={this.state.folionumber}
                SchemeDetails={this.state.SchemeDetails}
                redemptiontype={this.state.redemptiontype}
                redemTypeChange={this.redemTypeChange}
                unitsChange={this.unitsChange}
                amountChange={this.amountChange}
                availableUnit={this.state.availableUnit}
                errormessage={this.state.errormessage}
                errorenable={this.state.errorenable}
                amountValue={this.state.amountValue}
                units={this.state.units}
                UCC={this.state.ucc}
                onConfirmOrder={this.confirmOrder}
                data={this.props.location.state.data}
              />
              }
            </div>
          </div>}
        </div>
        <Deletepopup header={"Confirmation"} text={labels.messages.placeOrderMsg} open={this.state.openConfirmPopup} onClose={() => this.setState({ openConfirmPopup: false })} onClick={this.onClickPayment}>

        </Deletepopup>
        {/* Ended */}
        <CommonSnackbar timer={10000} open={this.state.open} severity={'warning'} message={this.state.schemecodeerror} handlesnanckClose={this.handlesnanckClose} />
      </>
      );
    } catch (err) {
      return <Redirect to={labels.Path.NotFound} />
    }
  }
}
const mapStateToProps = state => {
  return {
    logindetails: state.Login.logindetails,
    bankdetails: state.ProfileDetails.AllProfileDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.bankDetails != "NA" && state.ProfileDetails.AllProfileDetails.bankDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.bankDetails : [] : []

  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ RedemptionChange }, dispatch)
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Redemption)))
