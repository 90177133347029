// import React from "react";
// import { Grid } from "@material-ui/core";
// import color from "../../utils/colors/colors";
// import Label from "../../utils/constants/labels";
// import { withStyles } from "@material-ui/core/styles";
// import ErrorBoundary from "../../components/Errorhandling/Errorhandle";
// import Typography from "@material-ui/core/Typography";
// import MyPlanImage from "../../utils/assets/images/myplanimg.jpg";
// import MyPlanImage1 from "../../utils/assets/images/MyPlanimg1.jpg";
// import MyPlanImage2 from "../../utils/assets/images/MyPlanimg2.jpg";
// import Button from "../../components/custombutton/button";
// import Badges from "../../components/badge/badge";
// import { connect } from "react-redux";
// import { GetMyPlanDetails, updateMyPlanDetails } from "../../redux/actions/profile/profile";
// import { withRouter } from "react-router";
// //import * as types from "../../redux/constants/actionsType";
// import { getESignDownloadReport } from "../../redux/actions/esigndownload/esigndownload";
// import { getRiaFlowOnLinePaymentReport } from "../../redux/actions/riaflowonlinepayment/riaflowonlinepayment";
// import ToggleButton from "../../components/toggle/toggle";
// import NoData from "../nodata/nodata";
// import { getPurchasedPlan } from "../../redux/actions/profile/profile";
// import Skeleton from "@material-ui/lab/Skeleton";
// import labels from "../../utils/constants/labels";
// import { Redirect } from "react-router";
// import colors from "../../utils/colors/colors";
// import PlanListing from "./planListing";
// import Loader from "../loader/loader";
// import GetMyPlanDetailsapi from "../../api/profile/myplanapi";
// import { replaceNull } from "../../utils/commonfunction";
// import Subscriptioncard from "../card/subscriptioncard";
// const styles = (theme) => ({
//   root: {
//     width: "100%",
//   },
//   container: {
//     marginLeft: "-7.5vw",
//     display: 'grid',
//     gridTemplateColumns: '10% 80% 10%',
//     // marginTop: "15%",
//     [theme.breakpoints.down("xs")]: {
//       marginLeft: "0vw",
//     },
//   },
//   HeaderText: {
//     fontWeight: "bold",
//     // marginTop: "2vh",
//     fontSize: "18px",
//   },
//   containerCard: {
//     // backgroundColor: window.globalConfig.color.WHITE,
//     backgroundColor: window.globalConfig.color.PRIMARY,
//     border: `1px solid ${window.globalConfig.color.WHITE}`,
//     boxShadow: "0 0  10px 10px rgba(200, 200, 250, 0.1)",
//     borderRadius: 10,
//     display: "grid",
//     marginLeft: "auto",
//     marginRight: "auto",
//     marginTop: "2vh",
//     marginBottom: "5vh",
//     width: "100%",
//     height: "100%",
//     [theme.breakpoints.down("xs")]: {
//       margin: "10px",
//       width: "unset",
//       marginBottom: "5vh",
//       padding: "10px",
//       paddingBottom: "6vh",
//     },
//   },
//   closeIcon: {
//     "&:hover": {
//       cursor: "pointer",
//     },
//   },
//   containerCardNodata: {
//     backgroundColor: window.globalConfig.color.WHITE,
//     height: "25vh",
//     borderRadius: 6,
//     paddingTop: "22vh",
//     margin: "-0.5vw -0.5vw -0.5vw -0.5vw",
//   },
//   containerCardEquityNodata: {
//     backgroundColor: window.globalConfig.color.WHITE,
//     height: "25vh",
//     borderRadius: 6,
//     paddingTop: "22vh",
//     margin: "-0.5vw -0.5vw -0.5vw -0.5vw",
//     [theme.breakpoints.down("xs")]: {
//       margin: "10px",
//       width: "unset",
//       marginBottom: "5vh",
//       padding: "10px",
//       paddingBottom: "6vh",
//     },
//   },
//   containerCardEquity: {
//     // backgroundColor: window.globalConfig.color.WHITE,
//     backgroundColor: window.globalConfig.color.BROWN,
//     border: `1px solid ${window.globalConfig.color.WHITE}`,
//     boxShadow: "0 0  10px 10px rgba(200, 200, 250, 0.1)",
//     borderRadius: 10,
//     display: "grid",
//     marginLeft: "auto",
//     marginRight: "auto",
//     marginTop: "2vh",
//     marginBottom: "5vh",
//     width: "100%",
//     height: "100%",
//     [theme.breakpoints.down("xs")]: {
//       margin: "10px",
//       width: "unset",
//       marginBottom: "5vh",
//       padding: "10px",
//       paddingBottom: "6vh",
//     },
//   },

//   imageContainer: {
//     display: "grid",
//     height: "100%",
//   },
//   smallButtonCard: {
//     // backgroundColor:window.globalConfig.color.WHITE,
//     backgroundColor: window.globalConfig.color.PRIMARY,
//     display: "grid",
//     gridTemplateColumns: "repeat(3,1fr)",
//     gridColumnGap: "20px",
//     gridTemplateRows: "auto",
//     borderRadius: 10,
//     [theme.breakpoints.down("xs")]: {
//       gridTemplateColumns: "repeat(1,1fr)",
//     },
//   },
//   largeButtonCard: {
//     // backgroundColor:window.globalConfig.color.WHITE,
//     backgroundColor: window.globalConfig.color.PRIMARY,
//     display: "grid",
//     gridTemplateColumns: "repeat(2,1fr)",
//     gridColumnGap: "20px",
//     gridTemplateRows: "auto",
//     borderRadius: 10,
//   },
//   text: {
//     color: window.globalConfig.color.WHITE,
//     // margin: '2% 2% 0% 4%',
//     float: "right",
//     clear: "right",
//     textAlign: "left",
//     // padding: "0px 10px",
//     fontSize: "12px",
//     marginBottom: "1vh",
//     lineHeight: 1.5,
//     fontWeight: 100,
//     letterSpacing: 1,
//     wordSpacing: 1,
//   },
//   text1: {
//     color: window.globalConfig.color.WHITE,
//     // margin: '2% 2% 0% 4%',
//     float: "left",
//     clear: "left",
//     textAlign: "left",
//     // padding: "0px 10px",
//     fontSize: "12px",
//     marginBottom: "1vh",
//     lineHeight: 1.5,
//     fontWeight: 100,
//     letterSpacing: 1,
//     wordSpacing: 1,
//   },

//   textMain: {
//     color: window.globalConfig.color.WHITE,
//     // margin: '2% 2% 0% 4%',
//     float: "right",
//     clear: "right",
//     textAlign: "left",
//     // padding: "0px 10px",
//     fontSize: "12px",
//     marginBottom: "1vh",
//     lineHeight: 2,
//     fontWeight: 100,
//     letterSpacing: 1,
//     wordSpacing: 1,
//   },
//   plusCard: {
//     // borderRight: '2px solid',
//     // borderRightColor: window.globalConfig.color.WHITE,
//     [theme.breakpoints.down("xs")]: {
//       borderRight: "none",
//     },
//   },
//   proPluscard: {
//     borderRight: "2px solid",
//     borderRightColor: window.globalConfig.color.WHITE,
//     [theme.breakpoints.down("xs")]: {
//       borderRight: "none",
//     },
//   },
//   mainBorder: {
//     borderRight: "2px solid",
//     borderRightColor: window.globalConfig.color.WHITE,
//     [theme.breakpoints.down("xs")]: {
//       borderRight: "2px solid",
//       borderRightColor: window.globalConfig.color.WHITE,
//     },
//   },
//   plusText: {
//     textAlign: "left",
//     fontSize: "16px",
//     color: window.globalConfig.color.WHITE,
//     // marginTop: 20
//     // margin:'0% 2% 0% 4%',
//     // padding: "0px 10px",
//     fontWeight: "500",
//     marginBottom: "1vh",
//   },
//   subText: {
//     textAlign: "left",
//     fontSize: "15px",
//     color: window.globalConfig.color.WHITE,
//     // padding: "0px 10px",
//     fontWeight: "500",
//     float: "left",
//     marginBottom: "1vh",
//   },
//   image: {
//     //   height: "100%",
//     width: "80%",
//     [theme.breakpoints.down("xs")]: {
//       // width: "unset"
//     },
//   },
//   marginTop: {
//     marginTop: 25,
//   },
//   cardPadding: {
//     padding: 10,
//   },
//   flexDirection: {
//     display: "flex",
//     flexDirection: "row",
//     margin: 20,
//     [theme.breakpoints.down("xs")]: {
//       flexDirection: "column-reverse",
//     },
//   },
//   button: {
//     display: 'grid',
//     gridTemplateColumns: '18% 18% 17%',
//     justifyContent: 'center',
//     gap: '2%',
//     // marginLeft: "50px",
//     // marginRight: "50px",
//     // [theme.breakpoints.down("xs")]: {
//     //   marginBottom: "0vh",
//     // },
//   },

//   schemeUnselect: {
//     marginRight: 10,
//   },
//   categorySelect: {
//     marginLeft: 10,
//   },
//   categoryUnselect: {
//     color: window.globalConfig.color.PRIMARY_BORDER,
//     marginLeft: 10,
//   },
//   centerDivider: {
//     height: "100%",
//     size: 2,
//   },
//   selectedList: {
//     backgroundColor: window.globalConfig.color.BROWN,
//     color: window.globalConfig.color.WHITE,
//     height: 35,
//     padding: 0,
//     lineHeight: "36px",
//     border: `1px solid ${window.globalConfig.color.LABLE_GRAY}`,
//     fontWeight: 600,
//     fontSize: "14px",
//     lineHeight: "36px",
//     width: "140px",
//     marginLeft: "7px",
//     borderRadius: "5px",
//     "&:hover": {
//       backgroundColor: window.globalConfig.color.BROWN,
//       color: window.globalConfig.color.WHITE,
//     },
//   },
//   background: {
//     height: 35,
//     padding: 0,
//     background: window.globalConfig.color.WHITE,
//     lineHeight: "36px",
//     color: window.globalConfig.color.LABLE_GRAY,
//     border: `1px solid ${window.globalConfig.color.LABLE_GRAY}`,
//     fontWeight: 600,
//     fontSize: "14px",
//     lineHeight: "36px",
//     width: "140px",
//     marginLeft: "7px",
//     borderRadius: "5px",
//   },
//   leftAlign: {
//     float: "left",
//   },
//   cardRoot: {
//     margin: "0vh 15.5vw 18vh",
//     [theme.breakpoints.down("768")]: {
//       margin: "0vh",
//     },
//   },
//   myPlan: {
//     textAlign: "left",
//     // paddingBottom: "12px",
//     // marginBottom: "13px",

//     fontSize: "21px",
//     color: colors.BLACK_PRIMARY,
//     fontWeight: "bolder",
//     paddingTop: "7px"
//   },
// });
// class MyPlan extends React.Component {
//   constructor() {
//     super();
//     this.state = {
//       index: 0,
//       checked: true,
//       planCode: " ",
//       planName: " ",
//       selected: true,
//       financialPlanVisible: true,
//       equityAdvisorVisible: false,
//       allInOne: false,
//       isValid: true,
//       myPlanLoading: false,
//       myPlanData: []
//     };
//   }
//   componentDidMount() {
//     this.setState({ myPlanLoading: true })
//     GetMyPlanDetailsapi(this.props.logindetails.ClientID).then((res) => {
//       if (res.status == "S") {
//         let response = replaceNull(res.table1, "NA")
//         if (response != "NA") {
//           let grpelss = response
//             .sort((a, b) => {
//               var textA = a.product_name.toUpperCase();
//               var textB = b.product_name.toUpperCase();
//               return textA < textB ? -1 : textA > textB ? 1 : 0;
//             })
//             .filter(
//               (ele, ind) =>
//                 ind ===
//                 response.findIndex(
//                   (elem) => elem.product_name === ele.product_name
//                 )
//             );

//           let fulldata = grpelss.map((item, index) => {
//             return {
//               ProductName: item.product_name,
//               PlanCode: item.plan_code,
//               isPlanSelected: index == 0 ? true : false,
//               Data: response.filter(
//                 (value) => value.product_name == item.product_name
//               ),
//             };
//           });
//           this.setState({ myPlanData: fulldata })
//         } else {
//           this.setState({ myPlanData: [] })
//         }

//       } else {
//         this.setState({ myPlanData: [] })
//       }
//       this.setState({ myPlanLoading: false })
//     })
//     if (this.props.location.state != undefined) {

//     } else {
//       if (this.props.purchasedPlans != "" && this.props.purchasedPlans != "NA" && this.props.purchasedPlans.length != 0) {
//         this.props.history.push({
//           pathname: Label.Path.PurchasedPlan,
//         });
//       } else {
//         // To do empty the online API data
//         this.props.getRiaFlowOnLinePaymentReport();

//       }
//     }

//   }
//   componentDidUpdate(prevProps) {
//     const { purchasedPlans, location } = this.props;
//     if (location.state != undefined) {

//     } else {
//       if (prevProps.purchasedPlans != purchasedPlans) {
//         if (purchasedPlans != "" && purchasedPlans != "NA" && purchasedPlans.length != 0) {
//           this.props.history.push({
//             pathname: Label.Path.PurchasedPlan,
//             state: { data: 4 },
//           });
//         }
//       }
//     }

//     // if (this.props.purchasedPlans.length != 0) {

//     // }
//   }

//   handleClick = (value) => {
//     this.setState({ index: value });
//     if (value == 0) {
//       this.setState({
//         financialPlanVisible: true,
//         equityAdvisorVisible: false,
//         allInOne: false,
//       });
//     }
//     if (value == 1) {
//       this.setState({
//         financialPlanVisible: false,
//         equityAdvisorVisible: true,
//         allInOne: false,
//       });
//     }
//     if (value == 2) {
//       this.setState({
//         financialPlanVisible: false,
//         equityAdvisorVisible: false,
//         allInOne: true,
//       });
//     }
//   };
//   togglechange = () => {
//     this.setState({ selected: !this.state.selected });
//   };

//   subscribeClick = (item) => {
//     let planCode = item.Data[0]?.plan_code
//     let planName = item.Data[0]?.plan_name
//     let Amount = item.Data[0]?.recurring_price
//     // This action is call for Whenever select the new planName that time to do empty the Esign payload(SurePass url) and Show the View & Sign Button
//     if (
//       this.props.purchasedPlanProfile != undefined &&
//       this.props.purchasedPlanProfile != null &&
//       this.props.purchasedPlanProfile != ""
//     ) {
//       if (this.props.purchasedPlanProfile[0]?.planCode != planCode) {
//         this.props.getESignDownloadReport();
//       }
//     }

//     if (item.Data[0].plan_code != undefined && item.Data[0].plan_name != undefined && item.Data[0].recurring_price != undefined) {

//       this.props.getPurchasedPlan({ planCode, planName, Amount });
//     }

//     this.props.history.push({
//       pathname: Label.Path.StepperAdvisory,
//       state: {
//         data: item,
//       },
//     });
//   };
//   planClick = (item) => {
//     let data = this.state.myPlanData.map((val) => {
//       return { ...val, isPlanSelected: val.ProductName == item.ProductName ? true : false }
//     })
//     this.setState({ myPlanData: data })
//     this.props.updateMyPlanDetails(data)
//   }
//   // advisoryButton = (planCode, planName, Amount) => {
//   //   // This action is call for Whenever select the new planName that time to do empty the Esign payload(SurePass url) and Show the View & Sign Button
//   //   if (
//   //     this.props.purchasedPlanProfile != undefined &&
//   //     this.props.purchasedPlanProfile != null &&
//   //     this.props.purchasedPlanProfile != ""
//   //   ) {
//   //     if (this.props.purchasedPlanProfile[0]?.planCode != planCode) {
//   //       this.props.getESignDownloadReport();
//   //     }
//   //   }

//   //   if (planCode != undefined && planName != undefined && Amount != undefined) {
//   //     this.props.getPurchasedPlan({ planCode, planName, Amount });
//   //   }

//   //   //To do empty the Token Data in reducer

//   //   this.props.history.push({
//   //     pathname: Label.Path.StepperAdvisory,
//   //     state: {
//   //       data: { planName: planName, planCode: planCode, Amount: Amount },
//   //     },
//   //   });
//   // };
//   render() {
//     //
//     const { classes } = this.props;
//     try {
//       return (
//         <>
//           <ErrorBoundary>
//             {this.state.myPlanLoading == true ? (
//               <div>
//                 <Loader page="skeletonreport"></Loader>
//               </div>
//             ) : (
//               <div xs={12} className={classes.container} >
//                 <div></div>
//                 <div>
//                   {this.state.myPlanData != "NA" ? (
//                     // <>
//                     //   <Grid>
//                     // <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
//                     //   <div className={classes.myPlan}>
//                     //     {labels.PurchasedPlan.myPlan}
//                     //   </div>

//                     //   <div variant="h5" className={classes.HeaderText}>
//                     //     {labels.riaPlanComplete.subscriptionPlan}
//                     //   </div>
//                     //   <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", }}>
//                     //     <ToggleButton
//                     //       label1={labels.riaPlanComplete.billMonthly}
//                     //       label2={labels.riaPlanComplete.billSixMonth}
//                     //       selected={this.state.selected}
//                     //       toggleSelected={this.togglechange}
//                     //     />
//                     //   </div>
//                     //   <div style={{ display: "grid", gridTemplateColumns: "23% 23% 23% 23%", justifyContent: "center", gap: 10 }}>
//                     //     {this.state.myPlanData.map((item) => {
//                     //       return <div onClick={() => this.planClick(item)} style={{ backgroundColor: item.isPlanSelected ? colors.BROWN : colors.RosyBrown, border: "1px solid #e6e7ea", color: item.isPlanSelected ? colors.WHITE : colors.BLACK_PRIMARY, borderRadius: 5, padding: 5, textAlign: "center", display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", fontSize: 14, cursor: "pointer", boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px" }}>
//                     //         <span> {item.ProductName}</span>
//                     //         {/* <Button
//                     //           onClick={() => this.planClick(item)}
//                     //           page={item.isPlanSelected ? "primary" : "secondary"}
//                     //           name={item.ProductName}
//                     //         /> */}
//                     //       </div>
//                     //     })}
//                     //   </div>

//                     //   <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", }}>

//                     //     {
//                     //       this.state.myPlanData.map((item) => {
//                     //         return <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 30, }}>
//                     //           <PlanListing item={item} toggle={this.state.selected} onClick={() => this.subscribeClick(item)}></PlanListing></div>
//                     //       })
//                     //     }
//                     //   </div>
//                     // </div>
//                     <div style={{ display: "flex", flexDirection: "column", gap: 10, marginTop: "2.9em" }}>
//                       {/* <div className={classes.myPlan}>
//                       {labels.PurchasedPlan.myPlan}
//                     </div> */}

//                       <div variant="h5" className={classes.HeaderText} style={{
//                         // display: "flex", flexDirection: "row", paddingLeft: '28%',
//                         display: "flex", flexDirection: "row",
//                         justifyContent: 'space-evenly', fontFamily: 'PlusJakartaSansBold',
//                         fontSize: 'x-large', width: "120vh", marginLeft: "200%"
//                       }}>
//                         {labels.riaPlanComplete.subscriptionPlan}
//                         <div style={{
//                           fontSize: 'small',
//                           marginTop: '1%',
//                           fontFamily: 'PlusJakartaSansBold',
//                           paddingRight: "10em"
//                         }}>
//                           <ToggleButton
//                             label1={labels.riaPlanComplete.billMonthly}
//                             label2={labels.riaPlanComplete.billSixMonth}
//                             selected={this.state.selected}
//                             toggleSelected={this.togglechange}
//                           />
//                         </div>
//                       </div>
//                       <div
//                         style={{
//                           //width: '100Vh',
//                           //paddingLeft: "16.5em",
//                           //marginLeft: '1em',
//                           display: "flex",
//                           flexDirection: "row",
//                           marginTop: '1em'
//                         }}
//                       >
//                         <div style={{
//                           display: "grid",
//                           gridTemplateColumns: "26% 26% 26% 26%",
//                           //justifyContent: "center",
//                           gap: 1,
//                           marginTop: "2%",
//                           paddingLeft: "5.9em",
//                           paddingRight: "5.9em"
//                           //marginRight: "10em"
//                         }}>
//                           {this.state.myPlanData.map((item) => {
//                             return <div onClick={() => this.planClick(item)}
//                               style={{
//                                 backgroundColor: item.isPlanSelected ? colors.BROWN : colors.RosyBrown,
//                                 border: "1px solid #e6e7ea", color: item.isPlanSelected ? colors.WHITE : colors.BLACK_PRIMARY,
//                                 borderRadius: 5,
//                                 padding: 5,
//                                 textAlign: "center",
//                                 display: "flex",
//                                 alignItems: "center",
//                                 //flexDirection: "column",
//                                 flexDirection: "row",
//                                 justifyContent: "center",
//                                 fontSize: 14,
//                                 cursor: "pointer",
//                                 boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
//                                 borderBottomLeftRadius: "1%",
//                                 borderBottomRightRadius: '1%',
//                                 width: "250px",
//                               }}>
//                               <span> {item.ProductName}</span>
//                               {/* <Button
//                             onClick={() => this.planClick(item)}
//                             page={item.isPlanSelected ? "primary" : "secondary"}
//                             name={item.ProductName}
//                           /> */}
//                             </div>
//                           })}
//                         </div>
//                       </div>
//                       <div className={classes.borderBottomLine}></div>
//                       {/* <div style={{ paddingLeft: '6%', paddingRight: '6%' }}> */}
//                       {this.state.myPlanData.map((item) => {
//                         return <div style={{ display: 'flex' }}>
//                           <div style={{
//                             //width: '100%',  // Set the width to 100% to make it responsive
//                             display: 'flex',
//                             justifyContent: 'center',
//                             //marginLeft: '1%',
//                             paddingLeft: '7em'
//                           }}>

//                             <p style={{ fontFamily: 'PlusJakartaSansBold', marginTop: "2em" }}>Financial Planning</p>
//                             <div style={{ display: 'flex', flexWrap: 'wrap' }}>
//                               <div style={{ paddingRight: '17em', paddingLeft: '17em' }}>
//                                 <div style={{ fontSize: 'smaller' }}>
//                                   A Financial Plan that encompasses the five main pillars of personal finance - risk
//                                 </div>
//                                 <div style={{ fontSize: 'smaller' }}>management, financial goals, wealth management, taxation, and wealth</div>
//                                 <div style={{ fontSize: 'smaller' }}>succession.</div>
//                               </div>
//                             </div>
//                             <div style={{ marginTop: '25px' }}>
//                               <Subscriptioncard data={item} toggle={this.state.selected} onClick={() => this.subscribeClick(item)} />
//                             </div>
//                           </div>

//                         </div>
//                       })}

//                     </div>
//                     //   </Grid>

//                     // </>
//                   ) : (
//                     <>
//                       <NoData />
//                     </>
//                   )}
//                   <div></div>
//                 </div>
//               </div>
//             )}
//           </ErrorBoundary>
//         </>
//       );
//     } catch (err) {
//       return <Redirect to={labels.Path.NotFound} />;
//     }
//   }
// }
// const mapStateToProps = (state) => {
//   return {
//     myplandetails:
//       state.ProfileDetails.myplandetails != null
//         ? state.ProfileDetails.myplandetails.length != 0
//           ? state.ProfileDetails.myplandetails
//           : []
//         : [],
//     purchasedPlans:
//       state.Login.riaplandetails != null && state.Login.riaplandetails != "NA"
//         ? state.Login.riaplandetails.length != 0
//           ? state.Login.riaplandetails
//           : []
//         : [],
//     logindetails: state.Login.logindetails,
//     purchasedPlanProfile:
//       state.ProfileDetails.purchasedPlan != null
//         ? state.ProfileDetails.purchasedPlan.length != 0
//           ? state.ProfileDetails.purchasedPlan
//           : []
//         : [],
//     isMyPlanDetailsLoading: state.ProfileDetails.isMyPlanDetailsLoading,
//   };
// };
// const mapDispatchToProps = (dispatch) => {
//   return {
//     GetMyPlanDetails: (from, status) =>
//       dispatch(GetMyPlanDetails(from, status)),
//     getESignDownloadReport: () => dispatch(getESignDownloadReport()),
//     getRiaFlowOnLinePaymentReport: () =>
//       dispatch(getRiaFlowOnLinePaymentReport()),
//     getPurchasedPlan: (planCode, planName, Amount) =>
//       dispatch(getPurchasedPlan(planCode, planName, Amount)),
//     updateMyPlanDetails: (data) => dispatch(updateMyPlanDetails(data))
//   };
// };
// export default withStyles(styles)(
//   withRouter(connect(mapStateToProps, mapDispatchToProps)(MyPlan))
// );
import React from "react";
import { Grid } from "@material-ui/core";
import color from "../../utils/colors/colors";
import Label from "../../utils/constants/labels";
import { withStyles } from "@material-ui/core/styles";
import ErrorBoundary from "../../components/Errorhandling/Errorhandle";
import Typography from "@material-ui/core/Typography";
import MyPlanImage from "../../utils/assets/images/myplanimg.jpg";
import MyPlanImage1 from "../../utils/assets/images/MyPlanimg1.jpg";
import MyPlanImage2 from "../../utils/assets/images/MyPlanimg2.jpg";
import Button from "../../components/custombutton/button";
import Badges from "../../components/badge/badge";
import { connect } from "react-redux";
import { GetMyPlanDetails, updateMyPlanDetails } from "../../redux/actions/profile/profile";
import { withRouter } from "react-router";
//import * as types from "../../redux/constants/actionsType";
import { getESignDownloadReport } from "../../redux/actions/esigndownload/esigndownload";
import { getRiaFlowOnLinePaymentReport } from "../../redux/actions/riaflowonlinepayment/riaflowonlinepayment";
import ToggleButton from "../../components/toggle/toggle";
import NoData from "../nodata/nodata";
import { getPurchasedPlan } from "../../redux/actions/profile/profile";
import Skeleton from "@material-ui/lab/Skeleton";
import labels from "../../utils/constants/labels";
import { Redirect } from "react-router";
import colors from "../../utils/colors/colors";
import PlanListing from "./planListing";
import Loader from "../loader/loader";
import GetMyPlanDetailsapi from "../../api/profile/myplanapi";
import { replaceNull } from "../../utils/commonfunction";
import SubscriptionCard from "../card/subscriptioncard";
import Card from '@material-ui/core/Card';
import Financial from '../../utils/assets/Financial.png'

const subscriptionData =
  [{ amount: "₹15,000/-", plan: "Premium Financial plan", buttonText: "Subscribe" },
  { amount: "₹75000/-", plan: "Xpert Plan Financial planning", buttonText: "Subscribe" },
  { amount: "₹15,000/-", plan: "Investment Financial plan", buttonText: "Subscribe" },
  { amount: "₹15,000/-", plan: "Investment Financial plan", buttonText: "Subscribe" },
  ]
const styles = (theme) => ({
  root: {
    width: "100%",
  },
  container: {
    // marginLeft: "-7.5vw",
    // display: 'grid',
    // gridTemplateColumns: '11% 83% 10%',
    // // marginTop: "15%",
    // [theme.breakpoints.down("xs")]: {
    //   marginLeft: "0vw",
    // },
  },
  HeaderText: {
    fontWeight: "bold",
    // marginTop: "2vh",
    fontSize: "18px",
  },
  containerCard: {
    // backgroundColor: window.globalConfig.color.WHITE,
    backgroundColor: window.globalConfig.color.PRIMARY,
    border: `1px solid ${window.globalConfig.color.WHITE}`,
    boxShadow: "0 0  10px 10px rgba(200, 200, 250, 0.1)",
    borderRadius: 10,
    display: "grid",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "2vh",
    marginBottom: "5vh",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      margin: "10px",
      width: "unset",
      marginBottom: "5vh",
      padding: "10px",
      paddingBottom: "6vh",
    },
  },
  closeIcon: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  containerCardNodata: {
    backgroundColor: window.globalConfig.color.WHITE,
    height: "25vh",
    borderRadius: 6,
    paddingTop: "22vh",
    margin: "-0.5vw -0.5vw -0.5vw -0.5vw",
  },
  containerCardEquityNodata: {
    backgroundColor: window.globalConfig.color.WHITE,
    height: "25vh",
    borderRadius: 6,
    paddingTop: "22vh",
    margin: "-0.5vw -0.5vw -0.5vw -0.5vw",
    [theme.breakpoints.down("xs")]: {
      margin: "10px",
      width: "unset",
      marginBottom: "5vh",
      padding: "10px",
      paddingBottom: "6vh",
    },
  },
  containerCardEquity: {
    // backgroundColor: window.globalConfig.color.WHITE,
    backgroundColor: window.globalConfig.color.BROWN,
    border: `1px solid ${window.globalConfig.color.WHITE}`,
    boxShadow: "0 0  10px 10px rgba(200, 200, 250, 0.1)",
    borderRadius: 10,
    display: "grid",
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: "2vh",
    marginBottom: "5vh",
    width: "100%",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      margin: "10px",
      width: "unset",
      marginBottom: "5vh",
      padding: "10px",
      paddingBottom: "6vh",
    },
  },

  imageContainer: {
    display: "grid",
    height: "100%",
  },
  smallButtonCard: {
    // backgroundColor:window.globalConfig.color.WHITE,
    backgroundColor: window.globalConfig.color.PRIMARY,
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    gridColumnGap: "20px",
    gridTemplateRows: "auto",
    borderRadius: 10,
    [theme.breakpoints.down("xs")]: {
      gridTemplateColumns: "repeat(1,1fr)",
    },
  },
  largeButtonCard: {
    // backgroundColor:window.globalConfig.color.WHITE,
    backgroundColor: window.globalConfig.color.PRIMARY,
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gridColumnGap: "20px",
    gridTemplateRows: "auto",
    borderRadius: 10,
  },
  text: {
    color: window.globalConfig.color.WHITE,
    // margin: '2% 2% 0% 4%',
    float: "right",
    clear: "right",
    textAlign: "left",
    // padding: "0px 10px",
    fontSize: "12px",
    marginBottom: "1vh",
    lineHeight: 1.5,
    fontWeight: 100,
    letterSpacing: 1,
    wordSpacing: 1,
  },
  text1: {
    color: window.globalConfig.color.WHITE,
    // margin: '2% 2% 0% 4%',
    float: "left",
    clear: "left",
    textAlign: "left",
    // padding: "0px 10px",
    fontSize: "12px",
    marginBottom: "1vh",
    lineHeight: 1.5,
    fontWeight: 100,
    letterSpacing: 1,
    wordSpacing: 1,
  },

  textMain: {
    color: window.globalConfig.color.WHITE,
    // margin: '2% 2% 0% 4%',
    float: "right",
    clear: "right",
    textAlign: "left",
    // padding: "0px 10px",
    fontSize: "12px",
    marginBottom: "1vh",
    lineHeight: 2,
    fontWeight: 100,
    letterSpacing: 1,
    wordSpacing: 1,
  },
  plusCard: {
    // borderRight: '2px solid',
    // borderRightColor: window.globalConfig.color.WHITE,
    [theme.breakpoints.down("xs")]: {
      borderRight: "none",
    },
  },
  proPluscard: {
    borderRight: "2px solid",
    borderRightColor: window.globalConfig.color.WHITE,
    [theme.breakpoints.down("xs")]: {
      borderRight: "none",
    },
  },
  mainBorder: {
    borderRight: "2px solid",
    borderRightColor: window.globalConfig.color.WHITE,
    [theme.breakpoints.down("xs")]: {
      borderRight: "2px solid",
      borderRightColor: window.globalConfig.color.WHITE,
    },
  },
  plusText: {
    textAlign: "left",
    fontSize: "16px",
    color: window.globalConfig.color.WHITE,
    // marginTop: 20
    // margin:'0% 2% 0% 4%',
    // padding: "0px 10px",
    fontWeight: "500",
    marginBottom: "1vh",
  },
  subText: {
    textAlign: "left",
    fontSize: "15px",
    color: window.globalConfig.color.WHITE,
    // padding: "0px 10px",
    fontWeight: "500",
    float: "left",
    marginBottom: "1vh",
  },
  image: {
    //   height: "100%",
    width: "80%",
    [theme.breakpoints.down("xs")]: {
      // width: "unset"
    },
  },
  marginTop: {
    marginTop: 25,
  },
  cardPadding: {
    padding: 10,
  },
  flexDirection: {
    display: "flex",
    flexDirection: "row",
    margin: 20,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  button: {
    display: 'grid',
    gridTemplateColumns: '18% 18% 17%',
    justifyContent: 'center',
    gap: '2%',
    // marginLeft: "50px",
    // marginRight: "50px",
    // [theme.breakpoints.down("xs")]: {
    //   marginBottom: "0vh",
    // },
  },

  schemeUnselect: {
    marginRight: 10,
  },
  categorySelect: {
    marginLeft: 10,
  },
  categoryUnselect: {
    color: window.globalConfig.color.PRIMARY_BORDER,
    marginLeft: 10,
  },
  centerDivider: {
    height: "100%",
    size: 2,
  },
  selectedList: {
    backgroundColor: window.globalConfig.color.BROWN,
    color: window.globalConfig.color.WHITE,
    height: 35,
    padding: 0,
    lineHeight: "36px",
    border: `1px solid ${window.globalConfig.color.LABLE_GRAY}`,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    width: "140px",
    marginLeft: "7px",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: window.globalConfig.color.BROWN,
      color: window.globalConfig.color.WHITE,
    },
  },
  background: {
    height: 35,
    padding: 0,
    background: window.globalConfig.color.WHITE,
    lineHeight: "36px",
    color: window.globalConfig.color.LABLE_GRAY,
    border: `1px solid ${window.globalConfig.color.LABLE_GRAY}`,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    width: "140px",
    marginLeft: "7px",
    borderRadius: "5px",
  },
  leftAlign: {
    float: "left",
  },
  cardRoot: {
    margin: "0vh 15.5vw 18vh",
    [theme.breakpoints.down("768")]: {
      margin: "0vh",
    },
  },
  myPlan: {
    textAlign: "left",
    // paddingBottom: "12px",
    // marginBottom: "13px",

    fontSize: "21px",
    color: colors.BLACK_PRIMARY,
    fontWeight: "bolder",
    paddingTop: "7px"
  },
  borderBottomLine: {
    borderBottomStyle: 'solid',
    borderBottomColor: 'hsl(41.86deg 81.13% 79.22%)',
    //marginRight: '2%',
    borderBottomWidth: '2px',
    // marginTop: '-9%',
    // marginLeft: '6em',
    // marginRight: '3em',
    // width: '113vh'
  }
});
class MyPlan extends React.Component {
  constructor() {
    super();
    this.state = {
      index: 0,
      checked: true,
      planCode: " ",
      planName: " ",
      selected: true,
      financialPlanVisible: true,
      equityAdvisorVisible: false,
      allInOne: false,
      isValid: true,
      myPlanLoading: false,
      myPlanData: []
    };
  }
  componentDidMount() {
    this.setState({ myPlanLoading: true })
    GetMyPlanDetailsapi(this.props.logindetails.ClientID).then((res) => {
      if (res.status == "S") {
        let response = replaceNull(res.table1, "NA")
        if (response != "NA") {
          let grpelss = response
            .sort((a, b) => {
              var textA = a.product_name.toUpperCase();
              var textB = b.product_name.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            })
            .filter(
              (ele, ind) =>
                ind ===
                response.findIndex(
                  (elem) => elem.product_name === ele.product_name
                )
            );

          let fulldata = grpelss.map((item, index) => {
            return {
              ProductName: item.product_name,
              PlanCode: item.plan_code,
              isPlanSelected: index == 0 ? true : false,
              Data: response.filter(
                (value) => value.product_name == item.product_name
              ),
            };
          });
          this.setState({ myPlanData: fulldata })
        } else {
          this.setState({ myPlanData: [] })
        }

      } else {
        this.setState({ myPlanData: [] })
      }
      this.setState({ myPlanLoading: false })
    })
    if (this.props.location.state != undefined) {

    } else {
      if (this.props.purchasedPlans != "" && this.props.purchasedPlans != "NA" && this.props.purchasedPlans.length != 0) {
        this.props.history.push({
          pathname: Label.Path.PurchasedPlan,
        });
      } else {
        // To do empty the online API data
        this.props.getRiaFlowOnLinePaymentReport();

      }
    }

  }
  componentDidUpdate(prevProps) {
    const { purchasedPlans, location } = this.props;
    if (location.state != undefined) {

    } else {
      if (prevProps.purchasedPlans != purchasedPlans) {
        if (purchasedPlans != "" && purchasedPlans != "NA" && purchasedPlans.length != 0) {
          this.props.history.push({
            pathname: Label.Path.PurchasedPlan,
            state: { data: 4 },
          });
        }
      }
    }

    // if (this.props.purchasedPlans.length != 0) {

    // }
  }

  handleClick = (value) => {
    this.setState({ index: value });
    if (value == 0) {
      this.setState({
        financialPlanVisible: true,
        equityAdvisorVisible: false,
        allInOne: false,
      });
    }
    if (value == 1) {
      this.setState({
        financialPlanVisible: false,
        equityAdvisorVisible: true,
        allInOne: false,
      });
    }
    if (value == 2) {
      this.setState({
        financialPlanVisible: false,
        equityAdvisorVisible: false,
        allInOne: true,
      });
    }
  };
  togglechange = () => {
    this.setState({ selected: !this.state.selected });
  };

  subscribeClick = (item) => {
    let planCode = item.Data[0]?.plan_code
    let planName = item.Data[0]?.plan_name
    let Amount = item.Data[0]?.recurring_price
    // This action is call for Whenever select the new planName that time to do empty the Esign payload(SurePass url) and Show the View & Sign Button
    if (
      this.props.purchasedPlanProfile != undefined &&
      this.props.purchasedPlanProfile != null &&
      this.props.purchasedPlanProfile != ""
    ) {
      if (this.props.purchasedPlanProfile[0]?.planCode != planCode) {
        this.props.getESignDownloadReport();
      }
    }

    if (item.Data[0].plan_code != undefined && item.Data[0].plan_name != undefined && item.Data[0].recurring_price != undefined) {

      this.props.getPurchasedPlan({ planCode, planName, Amount });
    }

    this.props.history.push({
      pathname: Label.Path.StepperAdvisory,
      state: {
        data: item,
      },
    });
  };
  planClick = (item) => {
    let data = this.state.myPlanData.map((val) => {
      return { ...val, isPlanSelected: val.ProductName == item.ProductName ? true : false }
    })
    this.setState({ myPlanData: data })
    this.props.updateMyPlanDetails(data)
  }
  // advisoryButton = (planCode, planName, Amount) => {
  //   // This action is call for Whenever select the new planName that time to do empty the Esign payload(SurePass url) and Show the View & Sign Button
  //   if (
  //     this.props.purchasedPlanProfile != undefined &&
  //     this.props.purchasedPlanProfile != null &&
  //     this.props.purchasedPlanProfile != ""
  //   ) {
  //     if (this.props.purchasedPlanProfile[0]?.planCode != planCode) {
  //       this.props.getESignDownloadReport();
  //     }
  //   }

  //   if (planCode != undefined && planName != undefined && Amount != undefined) {
  //     this.props.getPurchasedPlan({ planCode, planName, Amount });
  //   }

  //   //To do empty the Token Data in reducer

  //   this.props.history.push({
  //     pathname: Label.Path.StepperAdvisory,
  //     state: {
  //       data: { planName: planName, planCode: planCode, Amount: Amount },
  //     },
  //   });
  // };
  render() {
    //
    const { classes } = this.props;
    try {
      return (
        <div>
          <ErrorBoundary>
            {this.state.myPlanLoading == true ? (
              <div>
                <Loader page="skeletonreport"></Loader>
              </div>
            ) : (
              <div className={classes.container} >
                {/* <div></div> */}
                <div>
                  {this.state.myPlanData != "NA" ? (
                    // <>
                    //   <Grid>
                    <div style={{ display: "flex", flexDirection: "column", gap: 10, marginTop: "2.9em" }}>
                      {/* <div className={classes.myPlan}>
                        {labels.PurchasedPlan.myPlan}
                      </div> */}

                      <div variant="h5" className={classes.HeaderText} style={{
                        // display: "flex", flexDirection: "row", paddingLeft: '28%',
                        display: "flex", flexDirection: "row",
                        justifyContent: 'space-evenly', fontFamily: 'PlusJakartaSansBold',
                        fontSize: 'x-large',
                      }}>
                        {labels.riaPlanComplete.subscriptionPlan}
                        <div style={{
                          fontSize: 'small',
                          marginTop: '1%',
                          fontFamily: 'PlusJakartaSansBold',
                          paddingRight: "10em"
                        }}>
                          <ToggleButton
                            label1={labels.riaPlanComplete.billMonthly}
                            label2={labels.riaPlanComplete.billSixMonth}
                            selected={this.state.selected}
                            toggleSelected={this.togglechange}
                          />
                        </div>
                      </div>
                      <div
                        style={{
                          //width: '100Vh',
                          //paddingLeft: "16.5em",
                          //marginLeft: '1em',
                          display: "flex",
                          flexDirection: "row",
                          marginTop: '1em'
                        }}
                      >
                        <div style={{
                          // display: "grid",
                          // gridTemplateColumns: "26% 26% 26% 26%",
                          //justifyContent: "center",
                           gap: 5,
                          marginTop: "2%",
                          display:"flex"
                          // paddingLeft: "5.9em",
                          // paddingRight: "5.9em"
                          //marginRight: "10em"
                        }}>
                          {this.state.myPlanData.map((item) => {
                            return <div onClick={() => this.planClick(item)}
                              style={{
                                backgroundColor: item.isPlanSelected ? colors.BROWN : colors.RosyBrown,
                                border: "1px solid #e6e7ea", color: item.isPlanSelected ? colors.WHITE : colors.BLACK_PRIMARY,
                                borderRadius: 5,
                                padding: 5,
                                textAlign: "center",
                                display: "flex",
                                alignItems: "center",
                                //flexDirection: "column",
                                flexDirection: "row",
                                justifyContent: "center",
                                fontSize: 14,
                                cursor: "pointer",
                                boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px",
                                borderBottomLeftRadius: "1%",
                                borderBottomRightRadius: '1%',
                                width: "250px",
                              }}>
                              <span> {item.ProductName}</span>
                              {/* <Button
                              onClick={() => this.planClick(item)}
                              page={item.isPlanSelected ? "primary" : "secondary"}
                              name={item.ProductName}
                            /> */}
                            </div>
                          })}
                        </div>
                      </div>
                      {/* <div className={classes.borderBottomLine}></div> */}
                      {/* <div style={{ paddingLeft: '6%', paddingRight: '6%' }}> */}
                      {this.state.myPlanData.map((item) => {
                         


                         return <SubscriptionCard data={item} toggle={this.state.selected} onClick={() => this.subscribeClick(item)} />
                          {/* Add your subscription data rendering here */}

                       
                      })}


                    </div>
                    //   </Grid>

                    // </>
                  ) : (
                    <>
                      <NoData />
                    </>
                  )}
                  <div></div>
                </div>
              </div >
            )
            }
          </ErrorBoundary >
        </div>
      );
    } catch (err) {
      // return <Redirect to={labels.Path.NotFound} />;
      return <Redirect to={labels.Path.subscriptionCard} />
    }
  }
}
const mapStateToProps = (state) => {
  return {
    myplandetails:
      state.ProfileDetails.myplandetails != null
        ? state.ProfileDetails.myplandetails.length != 0
          ? state.ProfileDetails.myplandetails
          : []
        : [],
    purchasedPlans:
      state.Login.riaplandetails != null && state.Login.riaplandetails != "NA"
        ? state.Login.riaplandetails.length != 0
          ? state.Login.riaplandetails
          : []
        : [],
    logindetails: state.Login.logindetails,
    purchasedPlanProfile:
      state.ProfileDetails.purchasedPlan != null
        ? state.ProfileDetails.purchasedPlan.length != 0
          ? state.ProfileDetails.purchasedPlan
          : []
        : [],
    isMyPlanDetailsLoading: state.ProfileDetails.isMyPlanDetailsLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    GetMyPlanDetails: (from, status) =>
      dispatch(GetMyPlanDetails(from, status)),
    getESignDownloadReport: () => dispatch(getESignDownloadReport()),
    getRiaFlowOnLinePaymentReport: () =>
      dispatch(getRiaFlowOnLinePaymentReport()),
    getPurchasedPlan: (planCode, planName, Amount) =>
      dispatch(getPurchasedPlan(planCode, planName, Amount)),
    updateMyPlanDetails: (data) => dispatch(updateMyPlanDetails(data))
  };
};
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(MyPlan))
);
