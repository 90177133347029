import React, { Component } from 'react'
// import Tabs from '../tabs/tabs';
import '../../utils/stylesheets/layout/layout.css'
import Toolbar from '../../components/toolbar/toolbar';
import Label from '../../utils/constants/labels'
import { connect } from 'react-redux';
import Tabs from '../tabs/equity_fund_tabs'
import constants from '../../utils/constants/apiconstant';
import { withStyles } from '@material-ui/core/styles';
import { scrollToTopPage } from '../../utils/commonfunction';
const styles = (theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "10% 80% 10%",
    marginTop: "5%",
    // [theme.breakpoints.down("920")]: {
    //   marginTop: "30%",
    // },
    // [theme.breakpoints.down("600")]: {
    //   marginTop: "55%",
    // }
  },
  sticky: {
    position: "fixed",
    top: 0,
    width: "100%",
  }
})
class Equity_Layout extends Component {


  componentDidMount() {


    scrollToTopPage()
    // window.onscroll = function () {
    //   console.log("Calling this function");
    // }
  }

  render() {
    const { classes } = this.props
    return (
      <div >
        <Toolbar title={constants.Common.Stocks} productID={2} childFunc={this.props.childFunc} data={this.props.mfHeader2}></Toolbar>
        {/* <Tabs page={this.props.location} /> */}
        {/* <div className="reportDesign"> */}
        <div className={classes.root} >
          <div ></div>
          <div >
            <Tabs page={this.props.location} />
          </div>
          <div ></div>
        </div>
        {/* </div>
        */}
        {/* <Footer></Footer> */}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  date: state.Login.date,
  mfHeader2: state.Card.equity,
  sessionID: state.Login.sessionID,
  clientID: state.Login.logindetails.ClientID,
  userID: state.Login.userID,
  date: state.Login.date,
  logindetails: state.Login.logindetails
})
export default withStyles(styles)(connect(mapStateToProps, null)(Equity_Layout))
