import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import importIcon from '../../utils/assets/importIcon.png'
import labels from '../../utils/constants/labels';
import colors from '../../utils/colors/colors';
import { Grid, Divider } from '@material-ui/core';
import { NegativeFinding, moneyFormatter } from '../../utils/commonfunction';
import { Skeleton } from '@material-ui/lab';
const styles = theme => ({
    container: {
        boxShadow: "0px 5px 22px rgba(154, 154, 156, 0.02), 0px 4px 11px rgba(155, 155, 155, 0.02), 0px 1px 15px rgba(44, 44, 45, 0.03)",
        // background: "#fff",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        height: 170,
        border: "1px solid #EDEFF2",

    },
    headingContainer: {
        display: "flex",
        flexDirection: "row",
        padding: "18px 41px 10px 11px"
    },
    gridContainer: {
        padding: '10px 11px',
        paddingRight: 10
    },
    heading: {
        fontSize: 13,
        textAlign: "start"
    },
    headerValue: {
        fontSize: 26,
        fontWeight: "700",
        textAlign: "start", paddingTop: 7
    },
    othersValue: {
        fontSize: 16,
        textAlign: "start",
        fontWeight: "600"
    },
    othersHeader: {
        fontSize: 12,
        textAlign: "start",
        color: window.globalConfig.color.LABLE_GRAY
    },
    importContainer: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "center",
        cursor: "pointer",
        alignItems: "center",
        background: "rgba(176, 185, 193, 0.2);",
        borderRadius: "15px",
        padding: "3px 8px"
    }
})
class TotalAssetCards extends React.Component {
    constructor() {
        super();
        this.state = {

        }
    }
    render() {
        const { classes } = this.props;
        return (

            this.props.isLoading ? <Skeleton className={classes.container} ></Skeleton> :
                <div className={classes.container} style={this.props.isTotal ? { backgroundColor: window.globalConfig.color.PRIMARY } : { backgroundColor: window.globalConfig.color.WHITE }}>
                    <div className={classes.headingContainer}>
                        <div style={{ display: "flex", flexDirection: "column", flex: 3 }}>
                            <Typography className={classes.heading} style={this.props.isTotal ? { color: window.globalConfig.color.WHITE } : {}} >{this.props.header}</Typography>
                            <Typography className={classes.headerValue} style={this.props.isTotal ? { color: window.globalConfig.color.WHITE } : {}} >{moneyFormatter(this.props.mainAmount)}</Typography>
                        </div>
                        <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", flex: 1 }}>
                            {this.props.isImport &&
                                <div className={classes.importContainer} onClick={() => this.props.history.push({
                                    pathname: labels.Path.Tracking, state: { importedstatus: true },
                                })}>
                                    <Typography className={classes.heading} style={{ padding: 2, paddingRight: 7 }}  >IMPORT</Typography>
                                    <img src={importIcon} style={{ width: 6, height: 6 }}></img>
                                </div>
                            }
                        </div>
                    </div>

                    <div>
                        <Grid container style={{ borderTop: "solid 1px #e1ecf6" }} >
                            <Grid item xs={6} className={classes.gridContainer} style={{ borderRight: "solid 1px #e1ecf6" }}>
                                {this.props.isTotal ?
                                    <div>
                                        <Typography style={{ fontSize: 12, textAlign: "start", paddingBottom: 6, color: window.globalConfig.color.TABLE_HEADER, fontWeight: "500" }} >INVESTMENTS</Typography>
                                        <Typography style={{ fontSize: 16, textAlign: "start", paddingBottom: 6, fontWeight: "600", color: window.globalConfig.color.WHITE }} >{moneyFormatter(this.props.firstContainerValue)}</Typography>

                                    </div>
                                    :
                                    <div>
                                        <Typography style={{ fontSize: 12, textAlign: "start", color: window.globalConfig.color.LABLE_GRAY, fontWeight: "500" }} >PORTFOLIO</Typography>
                                        <Typography style={{ fontSize: 16, textAlign: "start", fontWeight: "600" }} >{moneyFormatter(this.props.firstContainerValue)}</Typography>
                                        <Typography style={{ fontSize: 10, textAlign: "start", color: NegativeFinding(this.props.firstContainerSubValue), fontWeight: "600" }} >XIRR :{NegativeFinding(this.props.firstContainerSubValue, "", "", "", "percentage")}</Typography>
                                    </div>
                                }
                            </Grid>

                            <Grid item xs={6} className={classes.gridContainer}>
                                <Typography className={classes.othersHeader} style={this.props.isTotal ? { color: window.globalConfig.color.TABLE_HEADER } : {}} >OTHERS & TRANSFER IN</Typography>
                                <Typography className={classes.othersValue} style={this.props.isTotal ? { color: window.globalConfig.color.WHITE } : {}} >{moneyFormatter(this.props.secondContainerValue)}</Typography>
                            </Grid>
                        </Grid>
                    </div>
                </div >

        )
    }
}
export default withStyles(styles)(TotalAssetCards)