import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { dateChage } from '../../redux/actions/login/loginaction';
import { connect } from 'react-redux';
import secureStorage from '../../utils/securestorage';
import SwitchButton from "../switchbutton/switchbutton";
import Button from '../custombutton/button';
import ClearIcon from '@material-ui/icons/Clear';
import Three_State_Toggle_Switch from '../switchbutton/three_state_toggle_switch';
import CommonCheckbox from '../checkbox/checkbox';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: window.globalConfig.color.PRIMARY,
    zIndex: "1 !important",
    // paddingTop: 75,
  },
  toolbars: {
    flex: 1,
    minHeight: 30,
    alignItems: 'flex-start',
    backgroundColor: window.globalConfig.color.PRIMARY
  },
  titles: {
    justifyContent: 'flex-start',
    fontSize: 24,
    fontWeight: 'bold',
    color: window.globalConfig.color.WHITE,
    paddingTop: '18px 0',
    float: "left",
    marginLeft: 13,
    [theme.breakpoints.down('md')]: {
      marginLeft: 0,
    },
    //Added by Celsia on 27 May 2021 for Mobile Responsive
    [theme.breakpoints.down('xs')]: {
      marginLeft: -28,
    },
  },
  subtitles: {
    fontSize: 14,
    marginLeft: 12,
    color: window.globalConfig.color.cyanBluish,
    paddingTop: '18px 0',
    [theme.breakpoints.down('xs')]: {
      whiteSpace: 'nowrap',
      marginLeft: '-15%',
      marginTop: "2%"
    },
  },
  tab: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginLeft: 45,
    [theme.breakpoints.down('600')]: {
      marginLeft: 34,
    },
    //marginLeft:25
  },
  card: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: 5,
    marginRight: 10,
    borderRadius: 1
  },
  menu: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '0.5%',
    //   marginBottom: 20,
    marginLeft: -20,
    [theme.breakpoints.down('sm')]: {
      // marginTop: '10%',
    },
    [theme.breakpoints.down('xs')]: {
      // marginTop: '17%',
      marginBottom: 0,
    },
  },
  right: {
    float: "right",
    paddingTop: "24px",
    color: window.globalConfig.color.cyanBluish,
    position: "relative",
    fontSize: 13,
    marginRight: 30,
    [theme.breakpoints.down('sm')]: {
      paddingTop: "0",
      marginTop: '5%',
      marginRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: "0",
      marginTop: '17%',
    },
    [theme.breakpoints.between('500', '600')]: {
      paddingTop: "0",
      marginTop: '11%',
    },
    [theme.breakpoints.down('500')]: {
      marginRight: "-13px"
    }
  },
  date: {
    margin: 5,
    fontWeight: "bold",
    marginRight: 20,

    //Added by Celsia on 27 May 2021 for Mobile Responsive
    [theme.breakpoints.down('xs')]: {
      margin: 0,
    },
  },
  dateContainer: {
    flex: 1
  },
  cardSpareaterLine: {
    height: 0.5,
    width: "100%",
    backgroundColor: window.globalConfig.color.PRIMARY_BORDER,
  },
  toggle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingRight: "2.5rem",
    [theme.breakpoints.down("600")]: {
      marginTop: "8%",
    }
  },
  cartSwitch: {
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down("900")]: {
      alignItems: "center",
    }
  }
}));
let cartList = ["Cart", "Order Again"]
function ToolBar(props) {
  const [name, setname] = useState("");
  const [familyName, setfamilyName] = useState('')
  useEffect(() => {
    var session = secureStorage.getItem('user');
    var session_store = session;
    if (session_store != null) {
      setname(session_store.UserName)
      setfamilyName(session_store.FamilyName)
    }
    // if (window.globalConfig.isBasketAllowed) {
    //   cartList = ["Cart", "Basket", "Order Again"]
    // }
  }, [name, familyName, secureStorage.getItem('user')]);
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const [date, setDate] = useState('');

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleApply = () => {

    props.onChange(date)
    setAnchorEl(null);

  }

  return (
    <div className={[classes.root, "divRoot"]} style={props.rootstyle}  >
      <AppBar position="static" color="transparent">
        <Toolbar className={classes.toolbars} >
          <Grid className={classes.menu} container spacing={1} style={props.menustyle}>
            <Grid item xs={4} className={classes.tab} >
              <div>
                <Typography className={classes.subtitles} align={"left"}>{props.userName} ({props.logindetails.FamilyName})</Typography>
                <Typography className={classes.titles} variant="paragraph" noWrap>
                  {props.title}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={6}  className={classes.cartSwitch}>
              {/* <div
                className={classes.toggle}
              > */}
              <Three_State_Toggle_Switch values={cartList}
                handleChangeToggle={(val) => { props.onSwitchDonut(val) }}
                selectedFlag={props.cartSwitch}>

              </Three_State_Toggle_Switch>

              {/* <SwitchButton
                  onSwitchDonut={props.onSwitchDonut}
                  label1={"Cart"}
                  label2={"Order Again"}
                  page={
                    "cart"
                  }
                  selectedDebt={props.cartSwitch}
                  selectedEquity={props.placeOrderSwitch}
                /> */}
              {/* </div> */}
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}
const mapStateToProps = state => ({
  date: state.Login.date,
  mfHeader2: state.Card.mfHeader2,
  mfHeader1: state.Card.mfHeader1,
  userName: state.Login.changedUserName,
  logindetails: state.Login.logindetails
})
const dispatchToProps = (dispatch) => ({
  onChange: (date, toDate) => dispatch(dateChage(date))
});
export default connect(mapStateToProps, dispatchToProps)(ToolBar)
