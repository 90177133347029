import { combineReducers } from 'redux'
import SampleReducer from './sample/samplereducer'
import SummaryReducer from './summary/summaryreducer';
import ProfitLossReducer from './profitloss/profitlossreducer'
import transactionSummaryReducer from './transaction/summaryreducer'
import PortfolioXray from './portfolioxray/xrayreducer';
import LoginReducer from './login/loginreducer';
import CardmenuReducer from './cardmenu/cardmenureducer';
import Dropdownreducer from './dropdown/dropdownreducer';
import TransactionDetailsreducer from './transactiondetails/transactiondetailsreducer';
import Dataentryreducer from './dataentry/commondropdownreducer';
import UpcommingSIP from './upcommingsip/upcommingsipreducer';
import Mandatereducer from './mandate/mandatereducer';
import SchemeFilter from './schemes/schemereducer';
import OnetimeOrderReducer from './purchaseorder/onetimeorderreducer';
import SipOrderReducer from './purchaseorder/siporderreducer';
import SchemeDetails from './schemes/schemereducer';
import CartReducer from './cart/cartreducer'
import ResetPassword from './resetpassword/resetpasswordreducer'
import PurchaseOrder from './purchaseorder/purchaseorderreducer'
import Siptracker from './siptracker/siptrackerreducer';
import capitalgain from './capitalgain/capitalgain';
import TransactionOrderReport from './transactionorderreport/transactionorderreport';
import KYCreducer from './kyc/kycreducer';
import Productreducer from './product/productreducer';
import AFRreducer from './afr/afrreducer';
import Portfolioreportreducer from './portfolioreport/portfolioreportreducer';
import Assetallocationreducer from './assetallocation/assetallocationreducer';
import MarketCapAllocationReducer from './marketcapallocation/marketcapallocationreducer'
import Accountreducer from './accountstatement/accountstatement';
import XrayWithRedemptionReducer from './xraywithredemption/xraywithremptionreducer'
import DashboardAssetReducer from './dashboard/dashboard_assetallocation_reducer'
import RunningSIP from './runningsip/runningsip';
import AIFandPMS from './aifandpms/aifandpms_reducer';
import Profile from './profile/profile';
import RiskQuestion from './riskquestion/riskquestion';
import RiskScore from './riskscore/riskscore';
import Filter from './filter/filter';
import ESignDownload from "./esigndownload/esigndownload";
import L5report from "./L5reportreducer/L5reportreducer";
import RiaQuestionResponse from "./riaquestionresponse/riaquestionresponse";
import RiaOnLinePayment from "./riaflowonlinepayment/riaflowonlinepayment";
import RiaOffLinePayment from "./riaflowofflinepayment/riaflowofflinepayment"
//import Addbankreducer from '../reducer/Profile/Addbankreducer'
import KYCDetails from "./profile/kyc_profile_reducer";
import Track from "./track/trackreducer";
import RiaOnLinePaymentDetails from './riaonlinepaymentdetails/riaonlinepaymentdetails';
import StoreGstNumberBankDetails from './storegstnumberbankdetails/storegstnumberbankdetails';
import Trackequityimported from './track/trackequityimported';
import RealEstateDetails from './dataentry/real_estate_dataentry';
import LinePerformaceChart from './schemedetails/performancechart'
import NPSReducer from './nps/npsreducer'
import PortfolioReducer from './portfolio/portfolioreducer'
import AlertReducer from './alert/alertreducer'
import MaturityTrackerReducer from './maturitytracker/maturitytrackerreducer'
import EPFReducer from './epf/epfreducer'
export default combineReducers({
    Sample: SampleReducer,
    Portfolio: PortfolioReducer,
    Summary: SummaryReducer,
    ProfitLoss: ProfitLossReducer,
    transactionList: transactionSummaryReducer,
    PortfolioXray: PortfolioXray,
    Login: LoginReducer,
    Card: CardmenuReducer,
    Dropdown: Dropdownreducer,
    TransactionDetails: TransactionDetailsreducer,
    Kyc: KYCreducer,
    Product: Productreducer,
    MaturityTracker: MaturityTrackerReducer,
    schemeFilterCollection: SchemeFilter,
    onetimeOrder: OnetimeOrderReducer,
    Dashboard:DashboardAssetReducer,
    sipOrder: SipOrderReducer,
    SchemeDetails: SchemeDetails,
    CartReducer: CartReducer,
    ResetPassword: ResetPassword,
    PurchaseOrder: PurchaseOrder,
    SipTracker: Siptracker,
    capitalgain: capitalgain,
    TransactionOrderReport: TransactionOrderReport,
    UpcommingSIP: UpcommingSIP,
    Mandate: Mandatereducer,
    AFRdata: AFRreducer,
    Portfolioreport: Portfolioreportreducer,
    Asset: Assetallocationreducer,
    Account: Accountreducer,
    MarketCap: MarketCapAllocationReducer,
    Redemption: XrayWithRedemptionReducer,
    RunningSIP: RunningSIP,
    ProfileDetails: Profile,
    RiskQuestion: RiskQuestion,
    RiskScore: RiskScore,
    Filter: Filter,
    Alert: AlertReducer,
    Epf: EPFReducer,
    ESignDownload: ESignDownload,
    RiaQuestionResponse: RiaQuestionResponse,
    RiaOnLinePayment: RiaOnLinePayment,
    RiaOffLinePayment: RiaOffLinePayment,
    KYCDetails: KYCDetails,
    Track: Track,
    RiaOnLinePaymentDetails: RiaOnLinePaymentDetails,
    StoreGstNumberBankDetails: StoreGstNumberBankDetails,
    Trackequityimported: Trackequityimported,
    L5report: L5report,
    Dataentry: Dataentryreducer,
    LinePerformaceChart: LinePerformaceChart,
    RealEstate: RealEstateDetails,
    AIFandPMS: AIFandPMS,
    NPS: NPSReducer
    //Addbank:Addbankreducer
})
