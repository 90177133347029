import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import Button from '../../components/custombutton/button';
import Text from '../../components/text/text';
import color from "../../utils/colors/colors";
import labels from '../../utils/constants/labels';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import colors from '../../utils/colors/colors';
import { NegativeFinding } from '../../utils/commonfunction';
import { scrollToTopPage } from '../../utils/commonfunction';
const styles = theme => ({
  collabel: {
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: "14px",
    textAlign: "left",
    marginBottom: 10
  },
  folio: {
    fontSize: 14,
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontWeight: 'normal'
  },
  content: {
    display: 'block',
    // width: "92%",
    // margin:"0.4rem",
    padding: "1rem",
    color: window.globalConfig.color.PRIMARY_BLUE,
    // marginLeft: "4%",
     marginTop: "1rem",
    backgroundColor: window.globalConfig.color.RosyBrown,
    // height: "88%",
    // paddingBottom: "15%",
    borderRadius: 5,
    [theme.breakpoints.between("1000", "1050")]: {
      height: "auto",
      // paddingBottom: "20%"
    },
  },
  btnApply: {
    // width: "20%",
    [theme.breakpoints.between("1000", "1050")]: {
      // marginRight: 23,
      width: "40%",
    },
    // [theme.breakpoints.up("1050")]: {
    //   marginRight: 23,
    // },
    [theme.breakpoints.down("600")]: {
      marginLeft: 9,
      width: "40%",
      // marginRight: 15,
    },
    // marginLeft:420,
    // [theme.breakpoints.between("1250","1300")]: {
    //   marginLeft:324,
    // },
    // [theme.breakpoints.between("1300","1400")]: {
    //   marginLeft:365,
    // },
  },
  desktopResponsive: {
    margin: "0.4rem"
  },
  btnBack: {
    width: "20%",
    // marginLeft: 30,
    [theme.breakpoints.between("1000", "1050")]: {
      // marginLeft: 20,
      width: "40%",
    },
    [theme.breakpoints.down("600")]: {
      marginLeft: 9,
      width: "40%",
    },
  },
  divMain: {
    display: "flex",
    flexDirection: "row",
    marginTop: "3%",
    marginLeft: "9%",
    marginRight: "3%",
    marginBottom: "4%",
    paddingBottom: "3%"
  },
  divDetail: {
    height: 'auto',
    width: "60%",
    backgroundColor: window.globalConfig.color.WHITE,
    marginLeft: "7%",
    marginBottom: "4%",
    border: `1px solid ${window.globalConfig.color.BORDER}`,
    boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.3)",
    paddingBottom: "30px"
  },
  divLine: {
    height: "0.5px",
    width: "95%",
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    marginTop: 20,
    marginLeft: 15
  },
  divLine1: {
    height: "0.5px",
    width: "100%",
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    margin: "1rem"
    ,marginLeft:0
    // marginBottom: "-4%",
    // boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.3)",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "33% 33% 33%",
    gap: 10,
    marginTop:"1rem"
  }
});
let status = ""
class SwitchReview extends React.Component {
  onBackToConfirmOrder = () => {
    this.props.onPlaceorder(false);
  }
  componentDidMount() {
    scrollToTopPage();
  }
  render() {
    const { classes } = this.props;
    return (<ErrorBoundary>
      <div className={classes.desktopResponsive}>

        <Text label={labels.switch.ReviewSwitchDetails} />

        <div className={classes.content}>

          <div style={{ textAlign: "left" }}>
            <div className={classes.collabel}>{labels.switch.Switchoutfrom}</div>
            <div style={{ fontWeight: "bold" }}>{this.props.Switchout} <span className={classes.folio}>(Folio: {this.props.folionumber})</span></div>
          </div>

          <div className={classes.divLine}></div>
          <Grid container >
            <Grid item xs={12} style={{ marginTop: "4%" }}>
              <div style={{ textAlign: "left" }}>
                <div className={classes.collabel}>{labels.switch.Switchinto}</div>
                <div style={{ fontWeight: "bold" }}>{this.props.Switchin}</div>
              </div>
            </Grid>
          </Grid>
          <Grid container className={classes.grid}>

            <div style={{ textAlign: "left" }}>
              <div className={classes.collabel}>{labels.switch.OrderType}</div>
              <div style={{ fontSize: 14 }}>{labels.switch.Switch}</div>
            </div>

            {this.props.type === "Amount (Rs)" &&
              <div style={{ textAlign: "left" }}>
                <div className={classes.collabel}> {labels.switch.Amount}</div>
                <div style={{ fontSize: 14 }}> {this.props.amount}</div>
              </div>
            }
            {this.props.type === "Custom No. of Units" &&
              <div style={{ textAlign: "left" }}>
                <div className={classes.collabel}> {labels.switch.Units}</div>
                <div style={{ fontSize: 14 }}> {NegativeFinding(this.props.units, "", "", "", "number")}</div>
              </div>
            }
            {this.props.type === "All Exit Load Free Units" &&
              <div style={{ textAlign: "left" }}>
                <div className={classes.collabel}> {labels.switch.ExitLoad}</div>
                <div style={{ fontSize: 14 }}> {this.props.Additionaldata.TotalExitLoadUnits}</div>
              </div>
            }
            {this.props.type === "All Available No. of Units" &&
              <div style={{ textAlign: "left" }}>
                <div className={classes.collabel}> {labels.switch.AvailableAmount}</div>
                <div style={{ fontSize: 14 }}> {this.props.Additionaldata.TotalAvailableUnits}({NegativeFinding(this.props.Additionaldata.TotalAvailableAmount, "", "", "", "rupee")})</div>
              </div>
            }
            {this.props.type === "All Short Term Gain Units" &&

              <div style={{ textAlign: "left" }}>
                <div className={classes.collabel}> {labels.switch.ShortTermUnit}</div>
                <div style={{ fontSize: 14 }}> {this.props.Additionaldata.TotalShortTermUnits}</div>
              </div>

            }
            {this.props.type === "All Long Term Gain Units" &&

              <div style={{ textAlign: "left" }}>
                <div className={classes.collabel}> {labels.switch.LongTermUnit}</div>
                <div style={{ fontSize: 14 }}> {this.props.Additionaldata.TotalLongTermUnits}</div>
              </div>

            }

            <div style={{ textAlign: "left" }}>
              <div className={classes.collabel}>{labels.STP.UCC}</div>
              <div style={{ fontSize: 14 }}>{this.props.UCC}</div>
            </div>

          </Grid>
        </div>
        <div className={classes.divLine1}></div>
        <div style={{ display: 'flex', justifyContent: "space-between" }}>
          <div className={classes.btnBack}>
            <Button name={"Back"} page={"normal"} disabled={this.props.loader} onClick={() => this.onBackToConfirmOrder()} />
          </div>
          <div className={classes.btnApply} >
            {/* <Button name={"Confirm Order"} page={"primary"} loader={this.props.loader} onClick={() => this.props.confirmPayment(true)}/> */}
            <Button name={"Confirm Order"} page={"primary"} disabled={this.props.loader} loader={this.props.loader} onClick={() => this.props.confirmPayment(true)} />

          </div>
        </div>
      </div>
    </ErrorBoundary>
    );
  }
}
export default withStyles(styles)(withRouter(connect(null, null)(SwitchReview)))
