
import React, { Component } from 'react'
import '../../utils/stylesheets/layout/layout.css'
import Toolbar from '../../components/toolbar/toolbar';
import { connect } from 'react-redux';
import constants from '../../utils/constants/apiconstant';
import Epfreport from '../epf/epfreport';
import { withStyles } from '@material-ui/core';
import NoData from '../../components/nodata/nodata';
import Portfoliochart from '../../components/chart/portfoliochart';
import colors from '../../utils/colors/colors';
import { NegativeFinding, replaceNull } from '../../utils/commonfunction';
import Expandabletable from '../../components/expandable-table/expandabletable';
import labels from '../../utils/constants/labels';
import getNPSReport from '../../api/nps/nps_report';
import { scrollToTopPage } from '../../utils/commonfunction';
import getFamilyNPSReport from '../../api/nps/nps_family_report';
import { EMPTY_REALESTATE_PORTFOLIO_X_RAY } from '../../redux/constants/actionsType';

let data = []
const styles = (theme) => ({
  assetAllocationContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3,32%)",
    gap: 10
  },
  header: {
    fontSize: 21,
    fontWeight: 600,
    color: colors.BLACK_PRIMARY,
    textAlign: 'left'
  },
  SchemeContainer: {
    display: "grid",
    gridTemplateColumns: "20% 18% 26% 33%",
    gap: 10,
    [theme.breakpoints.down('920',)]: {
      gridTemplateColumns: "50% 50%",
      "& .dataOne": {
        gridArea: "auto"
      },
      "& .dataTwo": {
        gridArea: "auto",
        alignItems: "flex-end"
      },
      "& .dataThree": {
        gridArea: "auto",
        alignItems: "flex-start"
      },
      "& .dataFour": {
        gridArea: "auto",
        alignItems: "flex-end"
      }
    }
  },
  assetAllocationSummaryContainer: {
    display: "grid", gridTemplateColumns: "44% 26% 27%",
    padding: 17,
    fontSize: "large",
    fontWeight: 500,
    "& .dataTwoSpecialChar": {
      fontSize: "1rem",
      color: colors.PRIMARY_BORDER
    },
    "& .dataTwo": {
      alignItems: "center",
      gap: 2
    },
    [theme.breakpoints.down('920',)]: {
      gridTemplateColumns: "50% 50%",
      "& .dataOne": {
        gridArea: "auto"
      },
      "& .dataTwo": {
        gridArea: "auto"
      },
      "& .dataThree": {
        gridArea: "auto"
      }
    }
  },
  subAssetAllocationContainer: {
    display: "grid", gridTemplateColumns: "44% 26% 27%",
    [theme.breakpoints.down('920',)]: {
      // gridTemplateColumns: "50% 50%"
    }
  },
  allocationContainer: {
    display: "grid", gridTemplateColumns: "45% 53%", alignItems: "center",
    [theme.breakpoints.down('920',)]: {
      gridTemplateColumns: "100%"
    }
  }
})
class NPS_Layout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      NPSHeaderData: [],
      assetChart: [],
      assetData: [],
      anchorEl: null,
      schemeData: []
    }
  }
  NPSReportData = (ClientID, L5ID, AsOnDate,) => {
    if (!this.state.loader) {
      this.setState({ loader: true })
      let data = {
        "AsOnDate": AsOnDate,
        "ClientID": ClientID,
        "SessionID": this.props.logindetails.SessionID,
        "AccountLevel": 0,
        "AccountType": 0,
        "L5ID": L5ID,
        "L4ID": 0,
        "FamilyID": 0
      }
      getNPSReport(data).then(async (response) => {
        this.setState({ loader: false })
        let res = replaceNull(response, labels.messages.NA)
        if (res.status == "S") {
          if (res.table1 != null && res.table1 != "NA" && res.table1.length != 0) {
            this.setState({
              NPSHeaderData: res.table1.map((item) => {
                return { dataOne: item.NpsManager, dataTwo: NegativeFinding(item.InvestedAmount == "NA" ? 0 : item.InvestedAmount, "", "", "", "rupee"), dataThree: item.Pranno, dataFour: item.InceptionDate || "NA", dataFive: item.LastValuationDate, dataSix: NegativeFinding(item.LastValuationAmount == "NA" ? 0 : item.LastValuationAmount, "", "", "", "rupee"), subDataSix: item.AbsoluteReturns }
              })
            })
          } else {
            this.setState({
              NPSHeaderData: [{ dataOne: labels.messages.NA, dataTwo: NegativeFinding(0, "", "", "", "rupee"), dataThree: labels.messages.NA, dataFour: labels.messages.NA, dataFive: labels.messages.NA, dataSix: NegativeFinding(0, "", "", "", "rupee"), }]
              ,
            })
          }
          if (res.table2 != null && res.table2 != "NA" && res.table2.length != 0) {
            this.setState({
              assetChart: res.table2.map((item, index) => {
                return { name: item.Assets, value: item.AllocatedAmount, displayValue: NegativeFinding(item.AllocatedAmount, "", "", "", "rupee",), color: item.ColorCode }
              })
            })
            this.setState({
              assetData: res.table2.map((item, index) => {
                return { dataOne: item.Assets, dataTwo: NegativeFinding(item.AllocatedAmount, "", "", "", "rupee"), showCheckBox: true, isExpand: false, dataThree: NegativeFinding(item.AllocatedUnits, "", "", "", "number"), color: item.ColorCode }
              })
            })
          } else {
            this.setState({ assetChart: [], assetData: [] })
          }
          if (res.table3 != null && res.table3 != "NA" && res.table3.length != 0) {
            await this.setState({
              schemeData: res.table3.map((item) => {
                return { dataOne: item.Date, dataTwo: item.TxnType, dataThree: NegativeFinding(item.Amount, "", "", "", "rupee"), dataFour: item.Allocation }
              })
            })
          } else {
            this.setState({ schemeData: [] })
          }
        } else {
          this.setState({
            NPSHeaderData:
              [{ dataOne: labels.messages.NA, dataTwo: NegativeFinding(0, "", "", "", "rupee"), dataThree: labels.messages.NA, dataFour: labels.messages.NA, dataFive: labels.messages.NA, dataSix: NegativeFinding(0, "", "", "", "rupee") }
              ],
            schemeData: []
          })
          this.setState({
            assetChart: [],
            assetData: [],
            schemeData: [],
            loader: false
          })
        }
      })
    }
  }
  componentDidMount() {
    scrollToTopPage()
    if (this.props.page == "FamilyReport") {
      this.FamilyNPSReportData(this.props.logindetails.ClientID, this.props.L5ID, this.props.date)
    } else {
      this.NPSReportData(this.props.logindetails.ClientID, this.props.L5ID, this.props.date)
    }
  }
  FamilyNPSReportData = (ClientID, L5ID, AsOnDate,) => {
    if (!this.state.loader) {
      this.setState({ loader: true })
      let data = {
        "AsOnDate": AsOnDate,
        "ClientID": ClientID,
        "SessionID": this.props.logindetails.SessionID,
        "AccountLevel": 0,
        "AccountType": 0,
        "L5ID": L5ID,
        "L4ID": 0,
        "FamilyID": 0
      }
      getFamilyNPSReport(data).then(async (response) => {
        this.setState({ loader: false })
        let res = replaceNull(response, labels.messages.NA)
        if (res.status == "S") {
          if (res.table1 != null && res.table1 != "NA" && res.table1.length != 0) {
            this.setState({
              NPSHeaderData: res.table1.map((item) => {
                return { dataOne: item.NpsManager, dataTwo: NegativeFinding(item.InvestedAmount == "NA" ? 0 : item.InvestedAmount, "", "", "", "rupee"), dataThree: item.Pranno, dataFour: item.InceptionDate || "NA", dataFive: item.LastValuationDate || "NA", dataSix: NegativeFinding(item.LastValuationAmount == "NA" ? 0 : item.LastValuationAmount, "", "", "", "rupee"), subDataSix: item.AbsoluteReturns }
              })
            })
          } else {
            this.setState({ NPSHeaderData: [{ dataOne: labels.messages.NA, dataTwo: NegativeFinding(0, "", "", "", "rupee"), dataThree: labels.messages.NA, dataFour: labels.messages.NA, dataFive: labels.messages.NA, dataSix: NegativeFinding(0, "", "", "", "rupee"), }] })
          }
          if (res.table2 != null && res.table2 != "NA" && res.table2.length != 0) {
            this.setState({
              assetChart: res.table2.map((item, index) => {
                return { name: item.Assets, value: item.AllocatedAmount, displayValue: NegativeFinding(item.AllocatedAmount, "", "", "", "rupee",), color: item.ColorCode }
              })
            })
            this.setState({
              assetData: res.table2.map((item, index) => {
                return { dataOne: item.Assets, dataTwo: NegativeFinding(item.AllocatedAmount, "", "", "", "rupee"), showCheckBox: true, isExpand: false, dataThree: NegativeFinding(item.AllocatedUnits, "", "", "", "number"), color: item.ColorCode }
              })
            })
          } else {
            this.setState({ assetChart: [], assetData: [] })
          }
          if (res.table3 != null && res.table3 != "NA" && res.table3.length != 0) {
            await this.setState({
              schemeData: res.table3.map((item) => {
                return { dataOne: item.Date, dataTwo: item.TxnType, dataThree: NegativeFinding(item.Amount, "", "", "", "rupee"), dataFour: item.Allocation }
              })
            })
          }
        } else {
          this.setState({
            NPSHeaderData:
              [{ dataOne: labels.messages.NA, dataTwo: NegativeFinding(0, "", "", "", "rupee"), dataThree: labels.messages.NA, dataFour: labels.messages.NA, dataFive: labels.messages.NA, dataSix: NegativeFinding(0, "", "", "", "rupee") }
              ]
          })
          this.setState({
            assetChart: [],
            assetData: [],
            schemeData: [],
            loader: false
          })
        }
      })
    }
  }
  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  componentDidUpdate(prevProps) {
    const { ClientID, L5ID, date, location } = this.props;
    if (prevProps.ClientID != ClientID || prevProps.L5ID != L5ID || prevProps.date != date) {
      if (this.props.page == "FamilyReport") {
      } else {
        this.NPSReportData(ClientID, L5ID, date)
      }
    }
  }
  handleMenuClose = () => {
    this.setState({ anchorEl: null })
  }
  render() {
    const { classes } = this.props
    // { console.log(this.state.assetChart) }
    return (
      <div >
        <Toolbar loader={this.state.loader} title={constants.Common.NPS} childFunc={this.props.childFunc} showCard={true} data={this.state.NPSHeaderData} {...this.props} anchorEl={this.state.anchorEl} handleMenuClose={this.handleMenuClose} handleMenuClick={this.handleMenuClick} ></Toolbar>
        <div style={{ display: "grid", gridTemplateColumns: "11% 79% 10%", marginTop: "6%" }}>
          <div></div>
          <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
            <div className={classes.header}>Allocation</div>
            <div className={classes.allocationContainer}>
              <Portfoliochart height={148} loader={this.state.loader} data={this.state.assetChart.filter((ele, ind) => ind === replaceNull(this.state.assetChart, "NA").findIndex(elem => elem.name === ele.name) && ele.value != 0)} />
              <Expandabletable loader={this.state.loader} search={labels.currency.rupeesymbol} grid={{ mainGrid: classes.assetAllocationSummaryContainer, subGrid: classes.subAssetAllocationContainer }} tableHeader={["Assets", "Allocated Amount", "Allocated Units"]} data={this.state.assetData}></Expandabletable>
            </div>
            <div className={classes.header}>Transactions</div>
            {
              !this.state.loader && this.state.schemeData.length == 0 ? <div style={{ textAlign: 'center', width: "98%", border: `1px solid ${window.globalConfig.color.BORDER}`, borderRadius: 8, height: 70, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", marginBottom: "10px" }}>
                <NoData height={1} style={{ display: "flex", flexDirection: "column", alignItems: "center" }} />
              </div> : <Epfreport loader={this.state.loader} showHeader={false} grid={classes.SchemeContainer} data={this.state.schemeData} title={[{ "header": "Date", "subHeader": "", align: "left" }, { header: "Txn Type", align: "left", subHeader: "" }, { header: "Amount", align: "right", subHeader: "" }, { header: "AssetWise Allocation", align: "right", subHeader: "" }]}></Epfreport>
            }
          </div>
          <div></div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  date: state.Login.date,
  mfHeader2: state.Card.NPS,
  SessionID: state.Login.sessionID,
  ClientID: state.Login.logindetails.ClientID,
  L5ID: state.Login.L5ID,
  userID: state.Login.userID,
  date: state.Login.date,
  logindetails: state.Login.logindetails
})


export default withStyles(styles)(connect(mapStateToProps, null)(NPS_Layout))