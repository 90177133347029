import ApiValues from '../url/apiurl'
var axios = require('axios');
import Networking from '../../utils/api/apiaccess'
import labels from '../../utils/constants/labels';
/* name should be camel case in all function */
function getAllAccountstatementDetails(ClientID, AsonDate, Foliono, SinceInception, FromDate, WithRedeem, ProductID, FamilyID, L5ID, L4ID, SchemeID) {
    // var myHeaders = new Headers();
    //     myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
        "FolioNo": Foliono,
        "WithRedeem": WithRedeem,
        "SinceInception": SinceInception,
        "FromDate": FromDate,
        "AsOnDate": AsonDate,
        "ClientID": ClientID,
        "ProductID": ProductID,
        "FamilyID": FamilyID,
        "L5ID": L5ID,
        "L4ID": L4ID,
        "SchemeID": SchemeID
    });

    // var requestOptions = {
    //     method: 'POST',
    //     headers: myHeaders,
    //     body: raw,
    //     redirect: 'follow'
    // };
    //return [{data:"failed"}]
    return Networking.PostApi(ApiValues.AccountStatementView, raw)
        .then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }

            // 
        });

}
export default getAllAccountstatementDetails
