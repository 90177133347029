import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
function getFamilySchemeWiseXrayList(ClientID, InceptionDate, SchemeID, FolioNo, ProductID) {
    //let BaseUrl = "https://ms.zoifintech.com/Dashboard/GetMutualFundDetails"
    let data = {};

    // data.Account = Account
    data.L3 = ClientID
    data.AsOnDate = InceptionDate
    // data.FromXIRR = FromXIRR
    // data.ToXIRR = AccountLevel
    // data.Type = Type
    // data.AssetClass = AssetClass
    // data.AccountLevel = ToXIRR
    // data.SessionID = SessionID
    data.SchemeID = SchemeID
    data.ProductID = ProductID
    // data.FamilyID = FamilyID
    data.L5 = 0
    data.FolioNo = FolioNo
    data.Quantity = 0
    // data.L4ID = L4ID
    return Networking.PostApi(ApiValues.SchemeWisePortfolioFamily, data).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });
    // var config = {
    //     method: 'post',
    //     url: ApiValues.SchemeWisePortfolio,
    //     headers: {
    //         'Content-Type': 'application/json',
    //         'Authorization': "L]ak;YzY!47FqLUbhcC:4b8Zaw"
    //     },
    //     data: data
    // };
    // return axios(config)
    //     .then(function (response) {
    //         return response.data;
    //     })
    //     .catch(function (error) {
    //         if (error.message = "Network Error") {
    //             var data = {}
    //             data.status = "N"
    //             data.message = error.message
    //             return data
    //         } else {
    //             return { "status": "F", "message": labels.messages.apierror }

    //         }
    //     });

}
export default getFamilySchemeWiseXrayList;