import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import AvatarBadge from "../../components/avatar/avatar";
import getPortfolioxraySchemeDetails from "../../api/portfolioxrayschemedetail/portfolioxrayschemedetail";
import DeleteIcon from '@material-ui/icons/Delete';
import "./cart.css";
import "../../utils/stylesheets/common/global/filters/categoryfilter.css";
import Avatar from "../avatar/avatar";
import Popover from "@material-ui/core/Popover";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import Popup from "../tradingpopup/tradingpopup";
import Grid from "@material-ui/core/Grid";
import EmptyCart from "./emptycart.js";
import Loader from "../loader/loader";
// import Cookies from 'universal-cookie';
import SwitchButton from "../switchbutton/switchbutton";
import CartCard from "../../components/cart/cartrowcard.js";
import {
  // GetAllCart,
  GetAllCartValues,
} from "../../redux/actions/cart/getallcartaction";
import GetAllCartsBasket from "../../api/basket/getallcartbasketapi";
import Label from "../../utils/constants/labels";
import { SchemeDetails } from "../../redux/actions/schemes/schemeaction";
// import { GetCartByID } from "../../redux/actions/cart/getcartbyidaction";
// import { DeleteSipCartReducer } from "../../redux/actions/cart/deletecartaction";
import { OneTimeOrder } from "../../redux/actions/purchaseorder/onetimeorderaction";
// import { DeleteOnetimeCartReducer } from "../../redux/actions/cart/deletecartaction";
import {
  UpdateOnetimeCartReducer,
  UpdateSipCartReducer,
} from "../../redux/actions/cart/updatecartaction";
import { DeleteLoader } from "../../redux/actions/cart/deletecartaction";
import GetSchemeDetails from "../../api/schemedetails/schemedetails";
import moment from "moment";
import FolioPopup from "../tradingpopup/foliopopup";
import DeleteCart from "../../api/cart/deletecartapi";
import UpdateCart from "../../api/cart/updatecartapi";
import secureStorage from "../../utils/securestorage";
import CommonSnackbar from "../../components/snackbar/snackbar";
import CommonButton from "../custombutton/button";
import labels from "../../utils/constants/labels";
import ErrorBoundary from "../Errorhandling/Errorhandle";
import colors from "../../utils/colors/colors";
import CommonCheckbox from "../checkbox/checkbox";
import ClearIcon from '@material-ui/icons/Clear';
import Avatars from '@material-ui/core/Avatar';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import { NegativeFinding, formatDate } from "../../utils/commonfunction";
import Three_State_Toggle_Switch from "../switchbutton/three_state_toggle_switch";
import Deletepopup from "../popup/deletepopup";
import Button from "../custombutton/button";
import MoveToCart from "../../api/cart/movetocart";
import GetAllCartsBaskets from "../../api/basket/getallcartbasketapi";
import { MContext } from '../../components/pagination/renderpage';
import Pagination from '../../components/pagination/renderpage';

// const cookies = new Cookies();
const styles = (theme) => ({

  emptyCart: {
    margin: "auto",
  },

  popoverList: {
    width: "175px",
    [theme.breakpoints.down("900")]: {
      width: "125px",
    },
  },
  cartFirstContainer: {
    [theme.breakpoints.down("900")]: {
      display: "none"
    }
  },

  btnApply: {
    background: window.globalConfig.color.BROWN,
    marginLeft: "7px",
    padding: 0,
    lineHeight: "36px",
    color: window.globalConfig.color.WHITE,
    cursor: "pointer",
    fontWeight: 600,
    width: "max-content",
    fontSize: "14px",
    lineHeight: "36px",
    borderRadius: "5px",
    padding: "5px 20px",
    border: "1px solid transparent",
    marginTop: 30,
  },
  table: {
    width: "100%",
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: "14px",
    flex: "4 1",
    fontWeight: 500,
    borderSpacing: "0px 3.5px",
    marginTop: '10px',
  },
  list: {
    borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    cursor: "pointer",
  },
  tr: {
    boxShadow: "0px 1px 2px 1px #e0e5eb",
    // border:`1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    padding: " 0px",
    borderRadius: "8px",
    cursor: "pointer",
    margin: '5px 0px',
  },

  selectedtr: {
    boxShadow: `0px 0px 0px 0.5px ${window.globalConfig.color.BROWN} !important`,
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    padding: "0px !important",
    borderRadius: "8px !important",
    cursor: "pointer !important",
    margin: '5px 0px',
  },
  rupees: {
    margin: "0px !important",
    fontSize: "13px !important",
    fontWeight: "normal !important",
    color: `${window.globalConfig.color.LABLE_GRAY} !important`,
    padding: "1px 0px",
  },

  popoverListItem: {
    padding: "8px 16px !important",
    margin: "auto",

  },
  secondrow: {
    margin: "0px !important",
    fontSize: "11px !important",
    fontWeight: "normal !important",
    color: `${window.globalConfig.color.LABLE_GRAY} !important`,
    padding: "1px 4px",
    textAlign: "left",
  },
  firstrow: {
    margin: "0px !important",
    fontSize: "14px !important",
    padding: "4px",
    textAlign: "left",
  },
  amountrow: {
    margin: "0px !important",
    fontSize: "14px !important",
    padding: "4px",
    textAlign: "right",
  },
  titlesecondrowheading: {
    margin: "0px !important",
    fontSize: "11px !important",
    fontWeight: "normal !important",
    color: `${window.globalConfig.color.LABLE_GRAY} !important`,
  },
  titlesecondrow: {
    margin: "0px !important",
    fontSize: "11px !important",
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontWeight: "normal",
  },
  vertical: {
    borderLeft: `1px solid ${window.globalConfig.color.LABLE_GRAY}`,
    margin: "0px 10px",
    height: "11px",
  },
  icon: {
    cursor: "pointer",
    fontSize: '21px',
    color: window.globalConfig.color.LABLE_GRAY,
    backgroundColor: "transparent",
    border: window.globalConfig.color.WHITE,
    padding: "0px",
    [theme.breakpoints.down("md")]: {
      padding: "0px",
    }
  },

  //Added by Celsia on 29 May 2021 for Mobile Responsive Started
  desktopResponsive: {
    // display: "block !important",
    height: "auto",
    // [theme.breakpoints.down("900")]: {
    //   display: "none !important",
    // },
  },
  mobileResponsive: {
    display: "none !important",
    [theme.breakpoints.down("900")]: {
      display: "block!important",
    },
  },

  trResponsive: {
    height: "auto",
    boxShadow: "0px 1px 2px 1px #e0e5eb",
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    padding: "10px 0",
    borderRadius: "8px",
    fontSize: "13px",
    fontWeight: 400,
    width: "100%",
  },
  flexResponsive: {
    display: "flex",
    flexDirection: "row",
  },
  flexResponsive1: {
    display: "flex",
    flexDirection: "column",
  },

  btnOrderResponsive: {
    background: window.globalConfig.color.RosyOrange,
    padding: 0,
    lineHeight: "36px",
    color: window.globalConfig.color.LABLE_GRAY,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    width: "100%",
    borderRadius: "5px",
    border: "1px solid transparent",
  },
  btnApplyResponsive: {
    background: window.globalConfig.color.BROWN,
    padding: 0,
    lineHeight: "30px",
    cursor: "pointer",
    color: window.globalConfig.color.WHITE,
    fontWeight: 600,
    width: "100%",
    fontSize: "13px",
    borderRadius: "5px",
    padding: "5px 20px",
    border: "1px solid transparent",
    marginTop: 15,
  },
  iconResponsive: {
    cursor: "pointer",
    float: "right",
    marginTop: -58,
    paddingRight: 5,
  },
  leftValueResponsive: {
    margin: "0px !important",
    fontSize: "14px !important",
    padding: "4px",
    textAlign: "left",
  },
  rightValueResponsive: {
    margin: "0px !important",
    fontSize: "14px !important",
    padding: "4px",
    textAlign: "right",
  },
  leftLabelResponsive: {
    margin: "0px !important",
    fontSize: "11px !important",
    fontWeight: "normal !important",
    color: `${window.globalConfig.color.LABLE_GRAY} !important`,
    padding: "1px 4px",
    textAlign: "left",
  },
  rightLabelResponsive: {
    margin: "0px !important",
    fontSize: "11px !important",
    fontWeight: "normal !important",
    color: `${window.globalConfig.color.LABLE_GRAY} !important`,
    padding: "1px 4px",
    textAlign: "right",
  },
  spaceResponsive: {
    justifyContent: "space-between",
    padding: "0px 10px",
  },
  paddingResponsive: {
    padding: "0px 10px",
  },
  frequencyResponsive: {
    padding: "10px 10px",
    justifyContent: "flex-end",
  },
  schemeResponsive: {
    textAlign: "left",
    padding: "6px 0px",
    paddingRight: 10,
  },
  titlesResponsive: {
    margin: "0px !important",
    fontSize: "11px !important",
    paddingRight: 4,
  },
  schemeNameResponsive: {
    [theme.breakpoints.between("320", "369")]: {
      width: "11em",
    },
    [theme.breakpoints.between("370", "419")]: {
      width: "15em",
    },
    [theme.breakpoints.between("420", "540")]: {
      width: "19em",
    },
  },

  allOrder: {
    marginLeft: 18,
  },
  cartContainer: {
    display: "grid",
    //  flexDirection: "row",
    gridTemplateColumns: "15% 23% 4%",
    justifyContent: "space-around",
    alignContent: "center",
    alignItems: "end",
    paddingTop: 25,
    [theme.breakpoints.down("900")]: {
      gridTemplateColumns: "30% 47%",
      alignItems: "center",
    }
  },
  //Ended
  endTextDesign: {
    textAlign: "left",
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: 12,
  },
  btnConfirm: {
    width: "90%",
    [theme.breakpoints.down("lg")]: {
      width: "80%",
      padding: "13px 0px",
      float: "right",
      marginTop: 4,
    },
  },

  //24-03-2022
  rowContainer: {
    display: 'grid',
    gridTemplateColumns: "10% 80% 10%",
  },
  uccbar: {
    textAlign: "left",
    width: "max-content",
    boxShadow: "0px 1px 2px 1px #e0e5eb",
    backgroundColor: colors.WHITE,
    border: `1px solid ${colors.RosyBrown}`,
    borderRadius: 7,
    padding: 7,
    [theme.breakpoints.down("600")]: {
      marginTop: "3%",
    }
  },
  deleteContainer: {
    display: "flex", flexDirection: "row",
    [theme.breakpoints.down("900")]: {
      alignItems: "flex-end",
      justifyContent: "flex-end"
    }
  }
});

// const user = secureStorage.getItem("user")
let userDetails = [];
// const folioNumbers = [
//   {
//     FolioNo: "10391505481",
//   },
// ];
let selectedOneTimeUcc = []
let selectedUcc = []
class CartList extends React.Component {
  constructor(props) {
    super(props); //since we are extending class Table so we have to use super in order to override Component class constructor
    this.state = {
      //state is by default an object
      selectedSwitch: true,
      anchorEl: null,
      editData: [],
      openPopup: false,
      alertMessage: "",
      alertType: "",
      selected: null,
      popupType: "",
      popupTypeFlag: "",
      sipdata: [],
      onetimeData: [],
      openFolioPopup: false,
      sipAndOneTimeSwitch: true,
      onetimeSwitch: false,
      sipConfirmNetwork: false,
      onetimeConfirmNetwork: false,
      sipSelectedCount: 0,
      selectedSipList: [],
      oneTimeSelectedCount: 0,
      selectedOneTimeList: [],
      errormessage: "",
      loaderupdate: false,
      loaderSipEdit: false,
      loaderOneTimeEdit: false,
      checked: false,
      amcImageFlag: false,
      open: false,
      deleteloader: false,
      openDeletePopup: false,
      loader: false
    };
  }

  async componentDidMount() {
    userDetails = secureStorage.getItem("user");

    this.setState({ sipdata: [], onetimeData: [] });
    this.setState({ sipdata: this.props.sipCartData, onetimeData: this.props.onetimeCartData, selectedSwitch: "SIP" });

    setTimeout(() => {
      if (this.props.location.state != undefined) {

        if (window.location.pathname.includes(labels.Path.cartSip)) {
          this.sipSelectedClick(this.props.location.state.data)

        } else {
          this.oneTimeSelectedClick(this.props.location.state.data)
          this.setState({ selectedSwitch: "One Time" })
        }
        history.replaceState(window.location.pathname, null)
      }
    }, 900);

  }

  componentDidUpdate(prevProps) {
    const { sipCartData, onetimeCartData, clientId, L5ID, page } = this.props;
    if (prevProps.clientId != clientId || prevProps.L5ID != L5ID) {
      this.props.GetAllCart()
      GetAllCartsBasket(this.props.logindetails.SessionID, this.props.logindetails.ClientID)
      if (prevProps.sipCartData != sipCartData) {
        this.setState({ sipdata: [] });
        this.setState({ sipdata: sipCartData });
        // }

      }

      if (prevProps.onetimeCartData != onetimeCartData) {
        this.setState({ onetimeData: [] });
        this.setState({ onetimeData: onetimeCartData });
      }
    }
    // if (prevProps.page != page) {
    if (prevProps.sipCartData != sipCartData) {
      this.setState({ sipdata: [] });
      this.setState({ sipdata: sipCartData });

      // }

    }

    if (prevProps.onetimeCartData != onetimeCartData) {
      this.setState({ onetimeData: [] });
      this.setState({ onetimeData: onetimeCartData });
    }
    // }

  }
  deleteSipCart = () => {
    // this.props.DeleteLoader(true);
    this.setState({ anchorEl: null, loader: true })

    DeleteCart(
      1,
      this.props.logindetails.ClientID,
      userDetails.SessionID,
      this.state.editData.ID.toString(),
      false
    )
      .then((data) => {
        this.setState({ loader: false })
        // this.props.DeleteLoader(false);

        if (data.status == "S") {
          this.setState({
            openPopup: false,
            openAlert: true,
            alertMessage:
              this.state.editData.SchemeName + Label.cart.removedcart,
            alertType: "success",
          });
          // this.props.DeleteSipCartReducer(this.state.editData.ID.toString());
          this.props.GetAllCart()

        } else {
          this.setState({
            openPopup: false,
            openAlert: true,
            alertMessage: labels.messages.apierror,
            alertType: "warning",
          });
        }
      })
      .catch(() => {
        // this.props.DeleteLoader(false);

        this.setState({
          openPopup: false,
          openAlert: true,
          alertMessage: labels.messages.apierror,
          alertType: "warning",
        });
      });
  };

  deleteOnetimeCart = () => {
    // this.props.DeleteLoader(true);
    this.setState({ anchorEl: null, loader: true })
    DeleteCart(
      1,
      this.props.logindetails.ClientID,
      userDetails.SessionID,
      this.state.editData.ID.toString(),
      false
    )
      .then((data) => {
        this.setState({ loader: false })
        // this.props.DeleteLoader(false);

        if (data.status == "S") {
          this.setState({
            openDeletePopup: false,
            openAlert: true,
            alertMessage:
              this.state.editData.SchemeName + Label.cart.removedcart,
            alertType: "success",
          });
          // if (this.state.selectedSwitch == "SIP") {

          // } else {
          //   this.props.DeleteOnetimeCartReducer(
          //     this.state.editData.ID.toString()
          //   );
          this.props.GetAllCart()

          // }

        } else {
          this.setState({
            openDeletePopup: false,
            openAlert: true,
            alertMessage: labels.messages.apierror,
            alertType: "warning",
          });
        }
      })
      .catch(() => {
        this.props.DeleteLoader(false);

        this.setState({
          openPopup: false,
          openAlert: true,
          alertMessage: labels.messages.apierror,
          alertType: "warning",
        });
      });

    //  this.props.DeleteOnetimeCartReducer(this.state.editData.ID)
  };
  oneTimeSelectedClickCount = (data) => {
    this.setState({
      oneTimeSelectedCount: data.filter((e) => e.multipleSelect == true).length,
      selectedOneTimeList: data.filter((e) => e.multipleSelect == true),
    });
  };

  editSip = (amount, installments, frequency, checked, SIPdate) => {
    this.setState({ loaderSipEdit: true });
    this.setState({ anchorEl: null })

    UpdateCart(
      this.state.editData.ID,
      this.state.editData.SchemeID,
      this.props.logindetails.ClientID,
      Number(amount),
      this.state.editData.AUM,
      Number(installments),
      false,
      this.state.selectedSwitch == "One Time" ? true : false,
      false,
      false,
      this.state.editData.Ranking,
      this.state.editData.Risk,
      this.state.selectedSwitch == "SIP" ? true : false,
      frequency,
      SIPdate,
      1,
      false,
      this.state.editData.ratings,
      this.state.editData.AMCID,
      this.state.editData.AssetClass,
      this.state.editData.ISIN,
      this.state.editData.RTAID,
      this.state.editData.RTAProdCode,
      this.state.editData.SchemeCode,
      checked,
      userDetails.SessionID,
      this.props.transactionType.filter((item) => item.TransactionType.toLowerCase() == "sip")[0]?.ID || 0
    ).then((data) => {

      // if (data != undefined) {
      let updateddata = this.state.editData;
      updateddata.Sipdate = SIPdate;
      updateddata.Amount = Number(amount);
      updateddata.Installments = Number(installments);
      if (data.status == "S") {

        this.props.UpdateSipCartReducer(updateddata);
        this.props.GetAllCart(

        )
        this.setState({
          openPopup: false,
          openAlert: true,
          alertMessage: data.message,
          alertType: "success",
        });
      } else {
        this.setState({
          openPopup: false,
          openAlert: true,
          alertMessage: data.message,
          alertType: "warning",
        });
      }
      // }
      this.setState({ loaderSipEdit: false });
    });

  };
  multipleSelectPlaceOrder = async (singleCart) => {
    let sipList = singleCart.filter((item) => item.multipleSelect)
    let oneTimeList = singleCart.filter((item) => item.multipleSelect)
    if (this.props.page == "order again") {
      let CartList = []
      let cartID = []
      let data = []
      if (sipList.length > 0 && this.state.selectedSwitch == "SIP") {
        CartList = sipList.map((item) => {
          return { "CartID": item.ID, "TransactionTypeID": item.TransactionTypeID }
        })
        cartID = CartList.map((item) => {
          return item.CartID
        })
        data = await this.state.sipdata.map((item) => {
          return { ...item, isLoading: cartID.includes(item) }
        })
        await this.setState({
          sipdata: [...data]
        })
      } else {
        CartList = oneTimeList.map((item) => {
          return { "CartID": item.ID, "TransactionTypeID": item.TransactionTypeID }
        }
        )
        cartID = CartList.map((item) => {
          return item.CartID
        })
        data = await this.state.onetimeData.map((item) => {
          return { ...item, isLoading: cartID.includes(item) }
        })
        await this.setState({
          onetimeData: [...data]
        })
      }
      MoveToCart(this.props.logindetails.ClientID, this.props.logindetails.SessionID, JSON.stringify(CartList), singleCart[0]?.MutualFundUCC).then(async (res) => {
        this.setState({ openAlert: true, alertMessage: res.message, alertType: res.status == "S" ? "success" : "warning" })
        if (sipList.length > 0 && this.state.selectedSwitch == "SIP") {
          data = await this.state.sipdata.map((item) => {
            return { ...item, isLoading: false }
          })
          await this.setState({
            sipdata: [...data]
          })
          if (res.status == "S") {
            this.props.GetAllCart()
            // this.props.history.push(labels.Path.cartSip)
          }

        } else {
          data = await this.state.onetimeData.map((item) => {
            return { ...item, isLoading: false }
          })
          await this.setState({
            onetimeData: [...data]
          })
          if (res.status == "S") {
            this.props.GetAllCart()
            // this.props.history.push(labels.Path.cartSip)
          }

        }
      })
    } else {

      if (sipList.length > 0 && this.state.selectedSwitch == "SIP") {
        let data = "";
        let selectedSipList = sipList.map((item) => {
          data = this.props.schemeList.filter(
            (value) => value.SchemeID === item.SchemeID
          )[0]
          return { ...item, BSESchemeType: data?.BSESchemeType, InvesmentType: "Sip", MutualFundUCC: singleCart.MutualFundUCC };
        });
        let purchaseData = {};
        purchaseData.isFromCart = this.props.page == "order again" ? false : true;
        purchaseData.InvesmentType = "Sip";
        purchaseData.StepperActive = 1;
        purchaseData.multipleSelect = true;
        purchaseData.Ucc = singleCart[0].MutualFundUCC
        purchaseData.MutualFundUCC = singleCart[0].MutualFundUCC
        purchaseData.cartType = this.props.page
        purchaseData.multipleScheme = selectedSipList.map((item) => {
          return { ...item, installments: item.Instalments }
        });;
        this.props.history.push({
          pathname: Label.Path.Payment,
          state: { data: purchaseData },
        });
      } else if (oneTimeList.length > 0 && this.state.selectedSwitch == "One Time") {

        let data = "";
        let selectedOneTimeList = oneTimeList.map((item) => {
          data = this.props.schemeList.filter(
            (value) => value.SchemeID === item.SchemeID
          )[0];
          return { ...item, BSESchemeType: data?.BSESchemeType, InvesmentType: "One time", MutualFundUCC: singleCart.MutualFundUCC };
        });
        let purchaseData = {};
        purchaseData.InvesmentType = "One time";
        purchaseData.StepperActive = 1;
        purchaseData.isFromCart = this.props.page == "order again" ? false : true;;
        purchaseData.multipleSelect = true;
        purchaseData.Ucc = singleCart[0].MutualFundUCC
        purchaseData.MutualFundUCC = singleCart[0].MutualFundUCC
        purchaseData.cartType = this.props.page
        purchaseData.multipleScheme = selectedOneTimeList
        purchaseData.date = this.props.asOnDate;
        this.props.history.push({
          pathname: Label.Path.Payment,
          state: { data: purchaseData },
        });
        // }
        //}
      }
    }

  };
  editOnetime = (amount, installments, frequency, checked) => {
    this.setState({ loaderOneTimeEdit: true })
    this.setState({ anchorEl: null })

    if (amount > 4999) {

      UpdateCart(
        this.state.editData.ID,
        this.state.editData.SchemeID,
        this.props.logindetails.ClientID,
        Number(amount),
        this.state.editData.AUM,
        Number(installments),
        false,
        true,
        false,
        false,
        this.state.editData.Ranking,
        this.state.editData.Risk,
        false,
        frequency,
        "",
        1,
        false,
        this.state.editData.ratings,
        this.state.editData.AMCID,
        this.state.editData.AssetClass,
        this.state.editData.ISIN,
        this.state.editData.RTAID,
        this.state.editData.RTAProdCode,
        this.state.editData.SchemeCode,
        checked,
        userDetails.SessionID, this.props.TransactionType.filter((item) => item.TransactionType.toLowerCase() == "purchase")[0]?.ID || 0
      ).then((data) => {
        // if (data != undefined) {
        if (data.status == "S") {
          let updateData = {
            ID: this.state.editData.ID,
            amount: amount,
          };
          this.props.UpdateOnetimeCartReducer(updateData);
          this.props.GetAllCart(

          ),
            this.setState({
              openPopup: false,
              errormessage: "",
              alertMessage: data.message,
              openAlert: true,
              alertType: "success",
            });
        } else {

          this.setState({
            openPopup: false,
            errormessage: "",
            alertMessage: data.message,
            openAlert: true,
            alertType: "warning",
          });
        }
        // }
        this.setState({ loaderOneTimeEdit: false })
      });
    } else {
      this.setState({
        errormessage: labels.cart.minimumPurchaseAmount,
      });
      this.setState({ loaderOneTimeEdit: false })
    }
  };

  mappingDetailsErrorClose = () => {
    this.setState({
      openAlert: true,
      openPopup: false,
      alertMessage: labels.messages.apierror,
      alertType: labels.messages.warning,
    });
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  };

  handleOpenAlert = () => {
    this.setState({ openAlert: true });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, errormessage: "" });
  };

  handleClickSip = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
      // editData: value,
      popupTypeFlag: 1,
    });
    this.setState({
      sipdata: this.state.sipdata.map((item) => {
        return { ...item, multipleSelect: false }
      }), checked: false
    })
  };

  onError = (event) => {
    event.target.style.display = 'none';
    this.setState({
      amcImageFlag: true,
    })
  }
  sipSelectedClickcount = (data) => {
    this.setState({
      sipSelectedCount: data.filter((e) => e.multipleSelect == true).length,
      selectedSipList: data.filter((e) => e.multipleSelect == true),
    });
    //  //
  };
  sipSelectedClick = (data) => {
    if (this.props.page == "basket") {

    } else {

      let list = this.state.sipdata;
      const index = data.ID == undefined ? list.findIndex((item) => item.SchemeID == data.SchemeID) : list.findIndex((item) => item.ID === data.ID);
      if (selectedUcc.length == 0) {
        selectedUcc.push({ schemeID: data.SchemeID, ucc: list[index]?.MutualFundUCC })
      }
      if (selectedUcc.filter((item) => item.ucc == list[index]?.MutualFundUCC).length != 0) {

        if (data.ID == undefined) {

          list[index] = { ...list[index], multipleSelect: true };
          this.setState({
            sipdata: list,
          });
          this.sipSelectedClickcount(this.state.sipdata);
          history.replaceState('', null)
        } else {

          data.multipleSelect = data.multipleSelect ? false : true;
          list[index] = data;
          this.setState({
            sipdata: list,
          });
          this.sipSelectedClickcount(this.state.sipdata);
        }
        if (list.filter((item) => item.multipleSelect).length == list.length) {
          this.setState({ checked: true })
        } else {
          this.setState({ checked: false })
        }
      }
    }

  };
  onClickSelectAllCheckBox = () => {
    if (!this.state.checked) {
      if (this.state.selectedSwitch == "One Time") {
        this.setState({
          onetimeData: this.state.onetimeData.map((item) => {
            return { ...item, multipleSelect: true }
          })
        })
      } else {
        this.setState({
          sipdata: this.state.sipdata.map((item) => {
            return { ...item, multipleSelect: true }
          })
        })
      }
    } else {
      this.setState({
        onetimeData: this.state.onetimeData.map((item) => {
          return { ...item, multipleSelect: false }
        })
      })
      this.setState({
        sipdata: this.state.sipdata.map((item) => {
          return { ...item, multipleSelect: false }
        })
      })
    }
    this.setState({ checked: !this.state.checked })
  }

  oneTimeSelectedClick = (data) => {
    // console.log(this.props.page, data)
    if (this.props.page == "basket") {

    } else {
      let list = this.state.onetimeData;
      const index = data.ID == undefined ? list.findIndex((item) => item.SchemeID == data.SchemeID) : list.findIndex((item) => item.ID === data.ID);
      // console.log(index, selectedOneTimeUcc)
      if (selectedOneTimeUcc.length == 0) {
        selectedOneTimeUcc.push({ schemeID: data.SchemeID, ucc: list[index]?.MutualFundUCC })
      }
      if (selectedOneTimeUcc.filter((item) => item.ucc == list[index].MutualFundUCC).length != 0) {
        if (data.ID == undefined) {
          // const index = list.findIndex((item) => item.SchemeID === data.SchemeID);
          list[index] = { ...list[index], multipleSelect: true };
          this.setState({
            onetimeData: list,
          });
          history.replaceState('', null)
          this.oneTimeSelectedClickCount(this.state.sipdata);
        } else {
          data.multipleSelect = data.multipleSelect ? false : true;
          // const index = list.findIndex((item) => item.ID === data.ID);
          list[index] = data;
          // console.log(list)
          this.setState({
            onetimeData: list,
          });
          this.oneTimeSelectedClickCount(this.state.onetimeData);
        }

      }
      if (list.filter((item) => item.multipleSelect).length == list.length) {
        this.setState({ checked: true })
      } else {
        this.setState({ checked: false })
      }
    }

  };

  // changingswitch = () => {
  //   //
  // };
  handleClickOnetime = (event) => {
    // 

    this.setState({
      anchorEl: event.currentTarget,
      // editData: value,
      popupTypeFlag: 2,
    });
    this.setState({
      onetimeData: this.state.onetimeData.map((item) => {
        return { ...item, multipleSelect: false }
      }),
      checked: false
    })
  };


  showEditPopup = async () => {
    await this.setState({
      onetimeData: this.state.onetimeData.map((item) => {
        return { ...item, multipleSelect: false }
      }),
      checked: false
    })
    await this.setState({
      sipdata: this.state.sipdata.map((item) => {
        return { ...item, multipleSelect: false }
      }), checked: false
    })
    this.setState({ openPopup: true, anchorEl: null });
    if (this.state.popupTypeFlag === 1) {
      this.setState({ popupType: "sipPopup", popupName: "editCart" });
    } else {
      this.setState({ popupType: "onetimePopup", popupName: "editCart" });
    }
  };

  showDeletePopup = () => {
    this.setState({ openPopup: true, anchorEl: null, popupName: "deletecart" });
    if (this.state.popupTypeFlag === 1) {
      this.setState({ popupType: "sipPopup" });
    } else {
      this.setState({ popupType: "onetimePopup" });
    }
  };

  closePopup = () => {
    this.setState({ openPopup: false, openAlert: false, loaderOneTimeEdit: false, loaderSipEdit: false });
  };

  closeFolioPopup = () => {
    this.setState({ openFolioPopup: false });
  };

  onSwitchDonut = (switchDonut) => {
    this.setState({
      selectedSwitch: switchDonut,
      openAlert: false,
      alertMessage: "",
      checked: false
    });

    this.setState({
      sipdata: this.state.sipdata.map((item) => {
        return {
          ...item,
          multipleSelect: false,
        };
      }),
    });

    this.setState({
      onetimeData: this.state.onetimeData.map((item) => {
        return {
          ...item,
          multipleSelect: false,
        };
      }),
    });
    if (!switchDonut) {
      this.props.onSwitching(switchDonut);
      this.setState({ sipAndOneTimeSwitch: false, onetimeSwitch: true });
    } else {
      this.props.onSwitching(switchDonut);
      this.setState({ sipAndOneTimeSwitch: true, onetimeSwitch: false });
    }
  };
  clearerror = () => {
    this.setState({ errormessage: "" });
  };
  placeAllSip = async (singleCart) => {
    if (this.props.page == "order again") {
      let CartList = this.state.sipdata.map((item) => {
        return { "CartID": item.ID, "TransactionTypeID": item.TransactionTypeID }
      })
      let data = await this.state.sipdata.map((item) => {
        return { ...item, isLoading: CartList.includes(item) }
      })
      this.setState({
        sipdata: [...data]
      })
      MoveToCart(this.props.logindetails.ClientID, this.props.logindetails.SessionID, JSON.stringify(CartList), singleCart.MutualFundUCC).then(async (res) => {
        this.setState({ openAlert: true, alertMessage: res.message, alertType: res.status == "S" ? "success" : "warning" })
        data = await this.state.sipdata.map((item) => {
          return { ...item, isLoading: false }
        })
        if (res.status == "S") {
          this.props.GetAllCart()
          // this.props.history.push(labels.Path.cartSip)
        }
        await this.setState({
          sipdata: [...data]
        })
      })
    } else {
      if (this.state.sipdata.filter((item) => item.MutualFundUCC == singleCart.MutualFundUCC).length > 1) {
        let data = "";
        let sipData = this.state.sipdata.map((item) => {
          data = this.props.schemeList.filter(
            (value) => value.SchemeID === item.SchemeID
          )[0];
          return { ...item, BSESchemeType: data?.BSESchemeType, InvesmentType: "Sip", };
        });
        let purchaseData = {};
        purchaseData.isFromCart = this.props.page == "order again" ? false : true;;
        purchaseData.InvesmentType = "Sip";
        purchaseData.StepperActive = 1;
        purchaseData.multipleSelect = true;
        purchaseData.cartType = this.props.page
        purchaseData.multipleScheme = sipData.filter((item) => item.MutualFundUCC == singleCart.MutualFundUCC).map((item) => {
          return { ...item, installments: item.Instalments }
        });
        purchaseData.Ucc = singleCart.MutualFundUCC
        purchaseData.MutualFundUCC = singleCart.MutualFundUCC


        this.props.history.push({
          pathname: Label.Path.Payment,
          state: { data: purchaseData },
        });
      } else {
        let value = this.state.sipdata[0];
        //
        // console.log(value)
        let data = this.props.schemeList.filter(
          (item) => item.SchemeID === value.SchemeID
        )[0];

        let purchaseData = {};
        purchaseData.InvesmentType = "Sip";
        purchaseData.ID = value.ID;
        purchaseData.FolioList = value.FolioList
        purchaseData.SchemeID = value.SchemeID;
        purchaseData.Ucc = value.MutualFundUCC
        purchaseData.MutualFundUCC = value.MutualFundUCC
        purchaseData.cartType = this.props.page
        purchaseData.SchemeName =
          value.SchemeName != undefined ? value.SchemeName : "Scheme Name";
        purchaseData.AssetClass = value.AssetClass;
        purchaseData.AUM = value.AUM;
        purchaseData.BSESchemeType = data?.BSESchemeType;
        purchaseData.Risk = value.Risk;
        purchaseData.Amount = value.Amount;
        purchaseData.isFromCart = this.props.page == "order again" ? false : true;;
        purchaseData.multipleScheme = [value].filter((item) => item.MutualFundUCC == singleCart.MutualFundUCC);
        purchaseData.StepperActive = 1;
        purchaseData.ISIN = value.ISIN;
        purchaseData.SchemeCode = value.SchemeCode;
        purchaseData.threeYearReturn = value.ThreeYear;
        purchaseData.date = this.props.asOnDate;
        purchaseData.Sipdate = value.Sipdate;
        purchaseData.MutualFundUCC = value.MutualFundUCC

        purchaseData.installments = value.Instalments;
        purchaseData.ISFirstPayment = value.ISFirstPayment;
        this.props.history.push({
          pathname: Label.Path.Payment,
          state: { data: purchaseData },
        });
      }
    }
  };
  placeAllOneTime = async (singleCart) => {
    if (this.props.page == "order again") {
      let CartList = await this.state.onetimeData.map((item) => {
        return { "CartID": item.ID, "TransactionTypeID": item.TransactionTypeID }
      }
      )
      let data = await this.state.onetimeData.map((item) => {
        return { ...item, isLoading: CartList.includes(item) }
      })
      await this.setState({
        onetimeData: [...data]
      })
      MoveToCart(this.props.logindetails.ClientID, this.props.logindetails.SessionID, JSON.stringify(CartList), singleCart.MutualFundUCC).then(async (res) => {
        this.setState({ openAlert: true, alertMessage: res.message, alertType: res.status == "S" ? "success" : "warning" })
        if (res.status == "S") {
          this.props.GetAllCart()
          // this.props.history.push(labels.Path.cartOneTime)
        }
        data = await this.state.onetimeData.map((item) => {
          return { ...item, isLoading: false }
        })
        await this.setState({
          onetimeData: [...data]
        })
      })
    } else {
      if (this.state.onetimeData.filter((item) => item.MutualFundUCC == singleCart.MutualFundUCC).length > 1) {
        let data = "";
        let onetimeData = this.state.onetimeData.map((item) => {
          data = this.props.schemeList.filter(
            (value) => value.SchemeID === item.SchemeID
          )[0];
          return { ...item, BSESchemeType: data?.BSESchemeType, InvesmentType: "One time", };
        });
        let purchaseData = {};
        purchaseData.InvesmentType = "One time";
        purchaseData.StepperActive = 1;
        purchaseData.isFromCart = this.props.page == "order again" ? false : true;;
        purchaseData.multipleSelect = true;
        purchaseData.cartType = this.props.page
        purchaseData.multipleScheme = onetimeData.filter((val) => val.MutualFundUCC == singleCart.MutualFundUCC);
        purchaseData.Ucc = singleCart.MutualFundUCC
        purchaseData.MutualFundUCC = singleCart.MutualFundUCC
        purchaseData.date = this.props.asOnDate;
        this.props.history.push({
          pathname: Label.Path.Payment,
          state: { data: purchaseData },
        });
        //   }
        // }
      } else {

        let data = "";
        let onetimeData = this.state.onetimeData.map((item) => {
          data = this.props.schemeList.filter(
            (value) => value.SchemeID === item.SchemeID
          )[0];
          return { ...item, BSESchemeType: data?.BSESchemeType, InvesmentType: "One time" };
        });

        let purchaseData = {}
        purchaseData.InvesmentType = "One time";
        purchaseData.ID = onetimeData[0].ID;
        purchaseData.FolioList = onetimeData[0].FolioList
        purchaseData.Ucc = singleCart.MutualFundUCC
        purchaseData.MutualFundUCC = singleCart.MutualFundUCC
        purchaseData.cartType = this.props.page
        purchaseData.SchemeID = onetimeData[0].SchemeID;
        purchaseData.SchemeName =
          onetimeData[0].SchemeName != undefined ? onetimeData[0].SchemeName : "Scheme Name";
        purchaseData.AssetClass = onetimeData[0].AssetClass;
        purchaseData.AUM = onetimeData[0].AUM;
        purchaseData.BSESchemeType = onetimeData[0]?.BSESchemeType;
        purchaseData.Risk = onetimeData[0].Risk;
        purchaseData.Amount = onetimeData[0].Amount;
        purchaseData.isFromCart = this.props.page == "order again" ? false : true;;
        purchaseData.multipleScheme = onetimeData.filter((val) => val.MutualFundUCC == singleCart.MutualFundUCC);
        purchaseData.StepperActive = 1;
        purchaseData.ISIN = onetimeData[0].ISIN;
        purchaseData.RTAProdCode = onetimeData[0].RTAProdCode;
        purchaseData.SchemeCode = onetimeData[0].SchemeCode;
        purchaseData.threeYearReturn = onetimeData[0].ThreeYear;
        purchaseData.date = this.props.asOnDate;
        purchaseData.Sipdate = onetimeData[0].Sipdate;
        purchaseData.installments = onetimeData[0].Instalments;
        purchaseData.ISFirstPayment = onetimeData[0].ISFirstPayment;

        this.props.history.push({
          pathname: Label.Path.Payment,
          state: { data: purchaseData },
        });
        //   }
      }
    }

  };

  //SIP Payment SANKAR
  handleconfirmclick = async (value) => {
    if (this.props.page == "order again") {
      let CartList = [{ "CartID": value.ID, "TransactionTypeID": value.TransactionTypeID }]

      let data = await this.state.sipdata.map((item) => {

        return { ...item, isLoading: value.ID == item.ID }
      })
      await this.setState({
        sipdata: [...data]
      })
      MoveToCart(this.props.logindetails.ClientID, this.props.logindetails.SessionID, JSON.stringify(CartList), value.MutualFundUCC).then(async (res) => {
        this.setState({ openAlert: true, alertMessage: res.message, alertType: res.status == "S" ? "success" : "warning" })
        data = await this.state.sipdata.map((item) => {
          return { ...item, isLoading: !value.ID == item.ID }
        })
        if (res.status == "S") {
          this.props.GetAllCart()
          // this.props.history.push(labels.Path.cartSip)
        }
        await this.setState({
          sipdata: [...data]
        })
      })
    } else {
      // console.log(this.props.schemeList,value)
      let data = this.props.schemeList.filter(
        (item) => item.SchemeID == value?.SchemeID
      )[0];
      //
      let purchaseData = {};
      purchaseData.InvesmentType = "Sip";
      purchaseData.ID = value.ID;
      purchaseData.SchemeID = value.SchemeID;
      purchaseData.cartType = this.props.page
      purchaseData.SchemeName =
        value.SchemeName != undefined ? value.SchemeName : "Scheme Name";
      purchaseData.AssetClass = value.AssetClass;
      purchaseData.AUM = value.AUM;
      purchaseData.Risk = value.Risk;
      purchaseData.Amount = value.Amount;
      purchaseData.isFromCart = this.props.page == "order again" ? false : true;;
      purchaseData.multipleScheme = [{ ...value, MutualFundUCC: value.MutualFundUCC, installments: value.Instalments, InvesmentType: "Sip" }];
      purchaseData.StepperActive = 1;
      purchaseData.ISIN = value.ISIN;
      purchaseData.FolioList = value.FolioList
      purchaseData.Ucc = value.MutualFundUCC
      purchaseData.MutualFundUCC = value.MutualFundUCC

      purchaseData.SchemeCode = data?.SchemeCode;
      purchaseData.threeYearReturn = value.ThreeYear;
      purchaseData.date = this.props.asOnDate;
      purchaseData.Sipdate = value.Sipdate;
      purchaseData.installments = value.Instalments;
      purchaseData.ISFirstPayment = value.ISFirstPayment;
      purchaseData.BSESchemeType = data?.BSESchemeType;
      if (
        value.SipFrequency.toLowerCase() ==
        Label.bigPopup.monthly.toLowerCase()
      )
        purchaseData.btnFrequency = 1;
      else if (
        value.SipFrequency.toLowerCase() ==
        Label.bigPopup.quarterly.toLowerCase()
      )
        purchaseData.btnFrequency = 2;
      else purchaseData.btnFrequency = 3;
      purchaseData.Frequency = value.SipFrequency;
      this.props.history.push({
        pathname: Label.Path.Payment,
        state: { data: purchaseData },
      });

    }

  };

  sipDataFunction = () => {
    return this.state.sipdata;
  };

  //OneTime Payment SANKAR
  oneTimePopup = async (value) => {
    if (this.props.page == "order again") {
      let CartList = [{ "CartID": value.ID, "TransactionTypeID": value.TransactionTypeID }]
      let data = await this.state.onetimeData.map((item) => {
        return { ...item, isLoading: value.ID == item.ID }
      })
      this.setState({
        onetimeData: [...data]
      })
      MoveToCart(this.props.logindetails.ClientID, this.props.logindetails.SessionID, JSON.stringify(CartList), value.MutualFundUCC).then(async (res) => {
        data = await this.state.onetimeData.map((item) => {
          return { ...item, isLoading: !value.ID == item.ID }
        })
        if (res.status == "S") {
          this.props.GetAllCart()
          // this.props.history.push(labels.Path.cartOneTime)
        }
        await this.setState({
          onetimeData: [...data]
        })
        this.setState({ openAlert: true, alertMessage: res.message, alertType: res.status == "S" ? "success" : "warning" })

      })
    } else {

      let data = this.props.schemeList.filter(
        (item) => item.SchemeID === value.SchemeID
      )[0]
      let purchaseData = {};
      purchaseData.InvesmentType = "One time";
      purchaseData.FolioList = value.FolioList
      purchaseData.ID = value.ID;
      purchaseData.SchemeID = value.SchemeID;
      purchaseData.cartType = this.props.page
      purchaseData.SchemeName =
        value.SchemeName != undefined ? value.SchemeName : "Scheme Name";
      purchaseData.AssetClass = value.AssetClass;
      purchaseData.AUM = value.AUM;
      purchaseData.Risk = value.Risk;
      purchaseData.StepperActive = 1;
      purchaseData.isFromCart = this.props.page == "order again" ? false : true;;
      purchaseData.multipleScheme = [{ ...value, MutualFundUCC: value.MutualFundUCC, InvesmentType: "One time", }];
      purchaseData.BSESchemeType = data?.BSESchemeType;
      purchaseData.Amount = value.Amount;
      purchaseData.ISIN = value.ISIN;
      purchaseData.SchemeCode = value.SchemeCode;
      purchaseData.Ucc = value.MutualFundUCC
      // purchaseData.multipleSelect=true
      purchaseData.MutualFundUCC = value.MutualFundUCC
      purchaseData.threeYearReturn = value.ThreeYear;
      purchaseData.date = this.props.asOnDate;
      this.props.history.push({
        pathname: Label.Path.Payment,
        state: { data: purchaseData },
      });
    }
  };
  deleteCart = () => {
    let Id = this.state.selectedSwitch == "SIP" ?
      this.state.sipdata.filter((val) => val.multipleSelect).map((item) => {
        return `${item.ID}`
      }) : this.state.onetimeData.filter((val) => val.multipleSelect).map((item) => {
        return `${item.ID}`
      })
    if (Id.length >= 1) {
      this.setState({ deleteloader: true })

      DeleteCart(
        1,
        this.props.logindetails.ClientID,
        userDetails.SessionID,
        Id.toString().replaceAll(",", "|"),
        false
      ).then((data) => {

        // this.props.DeleteLoader(false);
        this.setState({ deleteloader: false })
        if (data.status == "S") {
          this.setState({
            openPopup: false,
            open: false,
            openAlert: true,
            alertMessage: data.message,
            checked: false,
            //   this.state.editData.SchemeName + Label.cart.removedcart,
            alertType: "success",
          });
          this.props.GetAllCart()
          // this.props.DeleteOnetimeCartReducer(
          //   this.state.editData.ID.toString()
          // );
        } else {
          this.setState({
            openPopup: false,
            open: false,
            openAlert: true,
            alertMessage: labels.messages.apierror,
            alertType: "warning",
          });
        }
      })
        .catch(() => {
          // this.props.DeleteLoader(false);

          this.setState({
            openPopup: false,
            openAlert: true,
            alertMessage: labels.messages.apierror,
            alertType: "warning",
          });
        });
    }

  }
  onClose = () => {
    this.setState({ open: false })
  }
  openMultipleDeletePopup = () => {
    let isValid = true
    if (this.state.selectedSwitch == "SIP") {
      isValid = this.state.sipdata.filter((item) => item.multipleSelect).length != 0 ? true : false
    } else {
      isValid = this.state.onetimeData.filter((item) => item.multipleSelect).length != 0 ? true : false

    }
    this.setState({ open: isValid })
  }
  render() {

    const { classes } = this.props;
    let grpSIP = this.props.page == "basket" ? this.state.sipdata?.filter(
      (ele, ind) =>
        ind ===
        this.state.sipdata.findIndex((elem) => elem.BasketID === ele.BasketID)
    ) : this.state.sipdata?.filter(
      (ele, ind) =>
        ind ===
        this.state.sipdata.findIndex((elem) => elem.MutualFundUCC === ele.MutualFundUCC)
    )
    let grpOneTime = this.props.page == "basket" ? this.state.onetimeData?.filter(
      (ele, ind) =>
        ind ===
        this.state.onetimeData.findIndex((elem) => elem.BasketID === ele.BasketID)
    ) : this.state.onetimeData?.filter(
      (ele, ind) =>
        ind ===
        this.state.onetimeData.findIndex((elem) => elem.MutualFundUCC === ele.MutualFundUCC)
    )
    let showDelete = false
    if (this.props.page == "cart") {
      if (this.state.selectedSwitch == "One Time") {
        showDelete = grpOneTime.length >= 1 ? true : false
      } else {
        showDelete = grpSIP.length >= 1 ? true : false
      }
    }
    return (
      <ErrorBoundary>
        <div className={classes.desktopResponsive}>
          <div
            className={classes.cartContainer}
          >
            <div className={classes.cartFirstContainer}></div>
            <Three_State_Toggle_Switch
              values={['SIP', "One Time",]}
              handleChangeToggle={this.onSwitchDonut}
              selectedFlag={this.state.selectedSwitch}
            >

            </Three_State_Toggle_Switch>
            {/* <SwitchButton
              onSwitchDonut={this.onSwitchDonut}
              label1={"SIP"}
              label2={"One Time"}
              page={
                this.props.match.params.selected == "onetime"
                  ? "paymentCart"
                  : "cart"
              }
              selectedDebt={!this.props.sipOneTimeSwitch}
              selectedEquity={this.props.sipOneTimeSwitch}
            /> */}
            {showDelete && <div className={classes.deleteContainer}>
              <CommonCheckbox
                checked={this.state.checked}
                onChange={this.onClickSelectAllCheckBox}
              ></CommonCheckbox>
              <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <DeleteIcon style={{ verticalAlign: 'middle', color: window.globalConfig.color.PRIMARY, cursor: "pointer" }} onClick={this.openMultipleDeletePopup} />
                <div>{this.state.selectedSwitch == "SIP" ? this.state.sipdata.filter((item) => item.multipleSelect).length == 0 ? "" : `(${this.state.sipdata.filter((item) => item.multipleSelect).length})` : this.state.onetimeData.filter((item) => item.multipleSelect).length == 0 ? "" : `(${this.state.onetimeData.filter((item) => item.multipleSelect).length})`}</div>
              </div>
            </div>}
          </div>
          <div className={classes.rowContainer}>
            <div>
            </div>
            <Grid container>
              <div style={{ width: "100%" }}>
                {this.state.selectedSwitch == "SIP" ?
                  this.props.loading ? <Loader page="reportmodel"></Loader> :
                    grpSIP.length != 0 ?
                      grpSIP.map((item) => {
                        return <div style={{ marginTop: 10 }}>
                          <div style={{ textAlign: "left", width: "max-content", boxShadow: "0px 1px 2px 1px #e0e5eb", backgroundColor: colors.WHITE, border: `1px solid ${colors.RosyBrown}`, borderRadius: 7, padding: 7 }}>{this.props.page == "basket" ? item.BasketName : `${this.props.uccList.filter((val) => val.ClientCode == item.MutualFundUCC)[0]?.ClientName || labels.messages.NA} - ${item.MutualFundUCC}`}</div>
                          <Pagination data={this.state.sipdata.filter((val) => this.props.page == "basket" ? val.BasketID === item.BasketID : val.MutualFundUCC === item.MutualFundUCC)}>
                            <MContext.Consumer>
                              {(context) => {
                                return context.map((value, index) => {
                                  return <CartCard
                                    isLoading={value.isLoading}
                                    page={this.props.page}
                                    cardClass={[value.multipleSelect == true
                                      ? classes.selectedtr
                                      : classes.tr,
                                    "sip-" + value.SchemeID,

                                    ].join(" ")}
                                    id={"cart" + index}
                                    cartHandleClick={() => this.sipSelectedClick(value)}
                                    avatar={value.SchemeName}
                                    color={value.color}
                                    schemeName={value.SchemeName}
                                    amcImage={window.globalConfig.AmcImage + value.AMCImage}
                                    orderType={Label.cart.SIP}
                                    Amount={value.Amount}
                                    Amountlable={Label.cart.SIPAmount}
                                    orderTypeLable={Label.cart.OrderType}
                                    folioNo={Label.cart.Ucc}
                                    foliotype={value.MutualFundUCC != "" ? value.MutualFundUCC : "New"}
                                    frequencyType={Label.cart.Frequency}
                                    frequency={value.SipFrequency}
                                    DateTitle={Label.cart.SIPStartDate}
                                    Date={formatDate(value.Sipdate)}
                                    InstalmentsNumber={Label.cart.NOofInstallments}
                                    Instalments={value.Instalments}
                                    confirmHandleCilck={() => this.handleconfirmclick(value)}
                                    // IconHandleCilck={() => this.handleClickSip(value)}
                                    iconButton={<button className={classes.icon} onClick={(event) => {
                                      this.setState({ editData: value })
                                      this.handleClickSip(event)
                                    }

                                    }>⠇
                                    </button>}
                                  ></CartCard>



                                })

                              }}
                            </MContext.Consumer>
                          </Pagination>



                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: 10,
                              marginTop: 10
                            }}
                          >

                            <div style={{ width: '24%' }}>
                              <Button page="primary" disabled={this.state.sipdata[0]?.isLoading} name={this.props.page == "order again" ? "Move all to Cart" : Label.cart.placesip} onClick={() => this.placeAllSip(item)}></Button>
                            </div>
                            {this.state.sipdata.filter((items) => items.MutualFundUCC == item.MutualFundUCC && items.multipleSelect).length > 1 ? (
                              // <div className={classes.allOrder}>

                              <div style={{ width: '25%' }}>
                                <Button page="primary" disabled={this.state.sipdata.filter((items) => items.MutualFundUCC == item.MutualFundUCC && items.multipleSelect)[0]?.isLoading} name={this.props.page == "order again" ? "Move selected orders to Cart" : Label.cart.placesipall} onClick={() => this.multipleSelectPlaceOrder(this.state.sipdata.filter((items) => items.MutualFundUCC == item.MutualFundUCC && items.multipleSelect))}></Button>
                              </div>
                              // </div>
                            ) : (
                              ""
                            )}
                          </div>

                        </div>
                      })
                      : <div className={classes.emptyCart}>

                        <EmptyCart page={this.props.page}></EmptyCart>

                      </div>

                  : this.props.loading ? <Loader page="reportmodel"></Loader> : grpOneTime != undefined &&
                    grpOneTime != null &&
                    grpOneTime.length > 0 ?
                    grpOneTime.map((item) => {
                      return <div>
                        <div className={classes.uccbar}>{this.props.page == "basket" ? item.BasketName : `${this.props.uccList.filter((val) => val.ClientCode == item.MutualFundUCC)[0]?.ClientName || labels.messages.NA} - ${item.MutualFundUCC}`}</div>
                        <Pagination data={this.state.onetimeData.filter((val) => this.props.page == "basket" ? val.BasketID == item.BasketID : val.MutualFundUCC == item.MutualFundUCC)} >
                          <MContext>
                            {(context) => {
                              return context.map((value, index) => (
                                <CartCard
                                  page={this.props.page}
                                  isLoading={value.isLoading}
                                  // type={"OneTime"}
                                  cardClass={value.multipleSelect == true
                                    ? classes.selectedtr
                                    : classes.tr
                                  }
                                  id={"cartonetime" + index}
                                  cartHandleClick={() => this.oneTimeSelectedClick(value)}
                                  avatar={value.SchemeName}
                                  color={value.color}
                                  schemeName={value.SchemeName}
                                  orderType={Label.cart.OneTime}
                                  Amount={value.Amount}
                                  Amountlable={Label.cart.Amount}
                                  orderTypeLable={Label.cart.OrderType}
                                  folioNo={Label.cart.Ucc}
                                  foliotype={value.MutualFundUCC != "" ? value.MutualFundUCC : "New"}
                                  verticalLine={false}
                                  amcImage={window.globalConfig.AmcImage + value.AMCImage}
                                  confirmHandleCilck={() => this.oneTimePopup(value)}
                                  iconButton={
                                    <button className={classes.icon}
                                      onClick={(event) => {
                                        this.setState({ editData: value })
                                        this.handleClickOnetime(event)
                                      }}
                                    >⠇
                                    </button>}
                                ></CartCard>
                              ))

                            }}
                          </MContext>
                        </Pagination>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            gap: 15,
                            marginTop: 10
                          }}
                        >
                          <div style={{ width: '24%' }}>
                            <Button page="primary" disabled={this.state.onetimeData[0]?.isLoading} name={this.props.page == "order again" ? "Move all to Cart" : Label.cart.placeonetime} onClick={() => this.placeAllOneTime(item)}></Button>
                          </div>
                          {/* <button
                            className={classes.btnApply}
                            onClick={() => this.placeAllOneTime(item)}
                          >
                            {Label.cart.placeonetime}
                          </button> */}
                          {this.state.onetimeData.filter((items) => items.MutualFundUCC == item.MutualFundUCC && items.multipleSelect).length > 1 ? (

                            <div style={{ width: '25%' }}>
                              <Button page="primary" disabled={this.state.onetimeData.filter((items) => items.MutualFundUCC == item.MutualFundUCC && items.multipleSelect)[0]?.isLoading} name={this.props.page == "order again" ? "Move selected orders to Cart" : Label.cart.selectedcart} onClick={() => this.multipleSelectPlaceOrder(this.state.onetimeData.filter((items) => items.MutualFundUCC == item.MutualFundUCC && items.multipleSelect))}></Button>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    })

                    : (
                      <div className={classes.emptyCart}>
                        <EmptyCart page={this.props.page}></EmptyCart>
                      </div>
                    )}
                {/* </table> */}
                <div onWheel={() => this.handleClose()}>
                  <Popover
                    open={Boolean(this.state.anchorEl)}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <div className={classes.popoverList}>
                      <div
                      >
                        <ListItem
                          button
                          className={classes.popoverListItem}
                          onClick={() =>
                            this.showEditPopup(this.state.popupTypeFlag)
                          }
                        >
                          <Typography>{Label.cart.Edit}</Typography>
                        </ListItem>

                      </div>
                      {this.props.page != "order again" && <> < hr className="divider" />
                        <div>
                          <ListItem
                            id="delete"
                            button
                            className={classes.popoverListItem}
                            onClick={() =>
                              this.setState({ openDeletePopup: true })
                            }
                          >
                            <Typography>{Label.cart.Delete}</Typography>
                          </ListItem>
                          {/* <hr className="divider" /> */}
                        </div></>}
                    </div>
                  </Popover>
                </div>
                <Popup
                  clearerror={this.clearerror}
                  open={this.state.openPopup}
                  sideClose={this.closePopup}
                  popupName={this.state.popupName}
                  editData={this.state.editData}
                  orderType={this.state.selectedSwitch == "One Time" ? Label.cart.OneTime : Label.cart.SIP}
                  errormessage={this.state.errormessage}
                  popupType={this.state.popupType}
                  // deleteSipCart={this.deleteSipCart}
                  // deleteOnetimeCart={this.deleteOnetimeCart}
                  isdeleteOntimeLoading={
                    this.state.isdeleteOntimeLoading
                  }
                  editOnetime={this.editOnetime}
                  editSip={this.editSip}
                  mappingDetailsErrorClose={
                    this.mappingDetailsErrorClose
                  }
                  loader={this.state.loaderSipEdit}
                  loaderOneTime={this.state.loaderOneTimeEdit}
                />

                {/* <FolioPopup
                  openFolioPopup={this.state.openFolioPopup}
                  closeFolioPopup={this.closeFolioPopup}
                  // folioNumbers={folioNumbers}
                /> */}


              </div>
            </Grid>
            <div></div>
          </div>
          <Deletepopup
            open={this.state.openDeletePopup}
            onClose={() => this.setState({ openDeletePopup: false })}
            text={`Do you want to delete the ${this.state.editData.SchemeName} from cart?`}
            header="Confirmation"
            loader={this.state.loader}
            onClick={this.deleteOnetimeCart}
          ></Deletepopup>
          <Deletepopup
            open={this.state.open}
            header={"Confirmation"}
            onClose={() => { this.setState({ open: false }) }}
            text={"Are you sure want to remove this scheme from cart?"}
            onClick={this.deleteCart}
          ></Deletepopup>
          <CommonSnackbar
            open={this.state.openAlert}
            message={this.state.alertMessage}
            severity={this.state.alertType}
            handlesnanckClose={this.handleCloseAlert}
          />

        </div>



      </ErrorBoundary>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    uccList: state.ProfileDetails.AllProfileDetails.length != 0 && state.ProfileDetails.AllProfileDetails?.uccList != undefined && state.ProfileDetails.AllProfileDetails?.uccList?.length != 0 ? state.ProfileDetails.AllProfileDetails.uccList != "NA" ? state.ProfileDetails.AllProfileDetails.uccList.filter(
      (ele, ind) =>
        ind ===
        state.ProfileDetails.AllProfileDetails.uccList.findIndex((elem) => elem.ClientCode === ele.ClientCode)
    ).map((item) => {
      return { ...item, "Name": `${item.DisplayName} ( ${item.ClientCode} )` }
    }) : [] : [],
    transactionType: state.Dropdown.dropdowndetails.transactionType,

  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      // GetCartByID,
      UpdateSipCartReducer,
      OneTimeOrder,
      DeleteCart,
      // GetAllCart,
      GetAllCartValues,
      UpdateCart,
      UpdateOnetimeCartReducer,
      // DeleteSipCartReducer,
      // DeleteOnetimeCartReducer,
      SchemeDetails,
      // DeleteLoader,
    },
    dispatch
  );
};
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(CartList))
);
