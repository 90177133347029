import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'

//action created by dulcy
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
import { Asset } from '../../utils/mainasset';
function AccountNumberVerification(AccountNumber, IFSC, UserID, FormNo) {


  var raw = JSON.stringify({
    "AccountNumber": AccountNumber,
    "Ifsc": IFSC,
    "UserID": UserID,
    "FormNo": FormNo,
    "Source": Asset.source,

  });


  return Networking.PostApi(ApiValues.VerifyAccountNumber, raw).then(result => { return result })
  .catch(function (error) {
    return { "status": "F", "message": labels.messages.apierror }
  });

}
export default AccountNumberVerification
