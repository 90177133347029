import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import NomineeDetails from '../../components/profile/nomineedetails';
import IncomeDetails from '../../components/profile/incomedetails';
import BankDetails from '../../components/profile/bankdetails';
import AccountDetails from '../../components/profile/accountdetails';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import CommonPopup from '../../components/popup/popup';
import { TradeL5IDDetails } from '../../redux/actions/login/loginaction'
import { Redirect } from 'react-router-dom'
import { scrollToTopPage } from '../../utils/commonfunction';
import { changeUcccode } from '../../redux/actions/profile/profile'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Box from '@material-ui/core/Box';
import '../../utils/stylesheets/tabs/tabs.css'
import colors from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div style={{
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: '0.00938rem',
            marginBlockStart: '1.5rem',
            marginBlockEnd: '1rem',
            margin: '1.3rem',
            // marginRight:'1rem'
          }}>{children}</div>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  indicator: {
    background: window.globalConfig.color.BROWN
  },
  tabRoot: {
    "& .MuiTab-root:focus": {
      color: window.globalConfig.color.BROWN
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: window.globalConfig.color.BROWN
    }
  },
  tabs: {
    borderBottom: `1px solid ${window.globalConfig.color.BORDER}`,
    columnCount: '2',
    // columnRuleWidth:'70% 30%',
    // display: "", flexDirection: "row", 
    display: 'grid',
    gridTemplateColumns: (p) => p.L5report != "NA" && p.L5report.length != 0 ? '66% 34%' : "auto",
    // gap: '10px',

    background: window.globalConfig.color.WHITE,
    [theme.breakpoints.down(600)]: {
      // width: "100%",
      overflow: "scroll",
      display: 'grid',
      gridTemplateColumns: (p) => p.L5report != "NA" && p.L5report.length != 0 ? '57% 38%' : "auto",
      gap: '10px',
      // columnCount:'2',

    },
    "& .MuiTabScrollButton-root": {
      width: 18
    },
    '& .heighttab .MuiAppBar-colorDefault': {
      backgroundColor: 'revert',
    },

  },
  flexContainer: {
    [theme.breakpoints.down(1400)]: {
      minHeight: "67px"

    },
  },
  widthtab: {
    // width: "68%",
    zIndex: 0,
    [theme.breakpoints.down(1400)]: {
      minHeight: "67px",
    },
    [theme.breakpoints.down('xs')]: {
      minHeight: "67px",
    },
    [theme.breakpoints.between(1000, 1050)]: {
      // width: "68%",
      height: "40px",
      marginTop: "15px"
    }
  },
  dropDownPopUp: {
    // [theme.breakpoints.down('xs')]: {
    //   marginTop: '-2%', marginLeft: '2%'
    // },
  },
  popupdesign: {
    // whiteSpace: 'nowrap',
    display: 'flex',
    // marginTop: "1%",
    [theme.breakpoints.down('1300')]: {
      minHeight: "67px",
      // whiteSpace: 'nowrap',
      display: 'flex',
    }
    // marginLeft: "15%"
  },

  accountMarginTop: {
    // marginTop:'-2%',
    [theme.breakpoints.down('601')]: {
      marginTop: '-3.5%',
    },
    [theme.breakpoints.between('400', '600')]: {
      marginLeft: '40%',
      width: "100%"
    },
    [theme.breakpoints.down('350')]: {
      marginLeft: "-12%",

    },
  },
  heighttab: {
    // [theme.breakpoints.only('1024')]: {
    //   height: "65px"
    // }
  }
}));
function ProfileTab(props) {

  const classes = useStyles(props);
  const [value, setValue] = React.useState(0);
  const [displayValue, setDisplayValue] = React.useState("");

  const [account, setAccount] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState('');
  const [accountnumber, setAccountNumber] = React.useState('');
  const [Ucccode, setUcccode] = React.useState('');

  useEffect(() => {
    // scrollToTopPage()

    let isCancelled = false;
    if (props.uccList != "NA" && props.uccList.length != 0) {

      // setAccount(props.TradingL5ID === 0 ? props.L5report[0].Name : props.L5report.filter((item) => item.L5ID === props.TradingL5ID).length != 0 ? props.L5report.filter((item) => item.L5ID === props.TradingL5ID)[0].Name : props.L5report[0].Name)
      // setAccountNumber(props.TradingL5ID === 0 ? props.L5report[0].L5ID : props.L5report.filter((item) => item.L5ID === props.TradingL5ID).length != 0 ? props.L5report.filter((item) => item.L5ID === props.TradingL5ID)[0].L5ID : props.L5report[0].L5ID)
      setUcccode(props.uccList[0].ClientCode)
      setDisplayValue(`${props.uccList[0].DisplayName} ( ${props.uccList[0].ClientCode} )`)

      // this.setState({ account: `${this.props.ProfileDetails[0].Name}`, AccountDetails: this.props.ProfileDetails.filter((value) => value.L5ID == this.props.ProfileDetails[0].L5ID), accountno: this.props.ProfileDetails[0].L5ID, UCCCode: this.props.ProfileDetails[0].MutualFundUCC })
    } else {
      setUcccode("NA")
    }
    if (props.location?.state != undefined) {
      setValue(props.location?.state?.tabValue)
    }
    // if (props.L5report != "NA" && props.L5report.length != 0) {

    //   setAccount(props.TradingL5ID === 0 ? props.L5report[0].Name : props.L5report.filter((item) => item.L5ID === props.TradingL5ID).length != 0 ? props.L5report.filter((item) => item.L5ID === props.TradingL5ID)[0].Name : props.L5report[0].Name)
    //   setAccountNumber(props.TradingL5ID === 0 ? props.L5report[0].L5ID : props.L5report.filter((item) => item.L5ID === props.TradingL5ID).length != 0 ? props.L5report.filter((item) => item.L5ID === props.TradingL5ID)[0].L5ID : props.L5report[0].L5ID)
    //   setUcccode(props.TradingL5ID === 0 ? props.L5report[0].MutualFundUCC : props.L5report.filter((item) => item.L5ID === props.TradingL5ID).length != 0 ? props.L5report.filter((item) => item.L5ID === props.TradingL5ID)[0].MutualFundUCC : props.L5report[0].MutualFundUCC)
    //   // this.setState({ account: `${this.props.ProfileDetails[0].Name}`, AccountDetails: this.props.ProfileDetails.filter((value) => value.L5ID == this.props.ProfileDetails[0].L5ID), accountno: this.props.ProfileDetails[0].L5ID, UCCCode: this.props.ProfileDetails[0].MutualFundUCC })
    // } else {
    //   setUcccode("NA")
    // }
    return () => {
      isCancelled = true;
    }
  }, [props.L5report, props.TradingL5ID, props.location?.state])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handlelistclick = (item) => {
    // 
    // setAccount(item.Name)
    // setAccountNumber(item.L5ID)
    setUcccode(item.ClientCode)
    setDisplayValue(`${item.DisplayName} ( ${item.ClientCode} )`)

    // props.TradeL5IDDetails(item.L5ID)
    // this.setState({ account: `${item.Name}`, accountno: item.L5ID, AccountDetails: this.props.ProfileDetails.filter((value) => value.L5ID == item.L5ID), JointHolder: this.props.JointHolder.filter((value) => value.L5ID == item.L5ID), UCCCode: item.MutualFundUCC })
    // props.changeUcccode(item.MutualFundUCC)
    setAnchorEl(null)
  }
  try {
    return (
      <div className={classes.root}>
        <div className={classes.tabs} >
          <AppBar position="static" color="default"
            classes={{ root: classes.widthtab }}>
            <Tabs

              classes={{ indicator: classes.indicator, root: [classes.heighttab, classes.tabRoot].join(' '), flexContainer: classes.flexContainer }}
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"

            >
              <Tab label="Account" {...a11yProps(0)} />
              <Tab label="Personal & Income" {...a11yProps(1)} />
              <Tab label="Bank" {...a11yProps(2)} />
              <Tab label="Nominee" {...a11yProps(3)} />

            </Tabs>

          </AppBar>
          {props.uccList != "NA" && props.uccList.length > 1 &&
            <CommonPopup slice={true} class={classes.popupdesign} Buttonstyle={{ width: "100%" }} text={displayValue} handleClick={handleClick} List={props.uccList} handlelistclick={handlelistclick} handleClose={handleClose} AnchorEl={anchorEl} className={classes.dropDownPopUp} />

          }
        </div>
        <TabPanel value={value} index={0}>
          <AccountDetails accountName={account} accountno={accountnumber} Ucc={Ucccode} uccList={props.uccList} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <IncomeDetails accountName={account} accountno={accountnumber} Ucc={Ucccode} />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <BankDetails accountName={account} accountno={accountnumber} Ucc={Ucccode} />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <NomineeDetails accountName={account} accountno={accountnumber} Ucc={Ucccode} />
        </TabPanel>
      </div>
    );
  }
  catch (err) {

    return <Redirect to={labels.Path.PageNotFound} />
  }
}
const mapStateToProps = state => ({
  TradingL5ID: state.Login.TradingL5ID,
  ProfileDetails: state.L5report.L5report.length != 0 && state.L5report.L5report != "NA"
    ? [...state.L5report?.L5report.map((item) => {
      return { ...item, "Name": item.PortfolioName }
    })] : []
  ,
  uccList: state.ProfileDetails.AllProfileDetails.length != 0 && state.ProfileDetails.AllProfileDetails?.uccList != undefined && state.ProfileDetails.AllProfileDetails?.uccList?.length != 0 ? state.ProfileDetails.AllProfileDetails.uccList != "NA" ? state.ProfileDetails.AllProfileDetails.uccList.filter(
    (ele, ind) =>
      ind ===
      state.ProfileDetails.AllProfileDetails.uccList.findIndex((elem) => elem.ClientCode === ele.ClientCode)
  ).map((item) => {
    return { ...item, "Name": `${item.DisplayName} ( ${item.ClientCode} )` }
  }) : [] : [],
  L5report: state.L5report.L5report != null && state.L5report.L5report != undefined && state.L5report.L5report != "NA" && state.L5report.L5report.length != 0 ? [...state.L5report.L5report.filter((item) => item.L4_AccountTypeID != 3)?.map((item) => {
    return { ...item, "Name": item.PortfolioName }
  })] : []
  ,
  JointHolder: state.ProfileDetails.AllProfileDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.jointHolderDetails != "NA" ? state.ProfileDetails.AllProfileDetails.jointHolderDetails : [] : [],
  logindetails: state.Login.logindetails,
})
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ changeUcccode, TradeL5IDDetails }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ProfileTab)