import React from 'react';
import color from "../../utils/colors/colors";
const App =(props)=>{
    const style={
        header:{
           fontSize:props.size,
           padding:"0px 0px 6px 0px",
           color:window.globalConfig.color.LABLE_GRAY,
           textAlign:'left'
        },
        headerRight: {
            fontSize:props.size,
           padding:"0px 10px 6px 0px",
           color:window.globalConfig.color.LABLE_GRAY,
           textAlign:'right'
        }
    }
    return(
        <React.Fragment>
            <div style={(props.flag != undefined && props.flag == 'text') ? style.headerRight : style.header}>{props.text}</div> 
        </React.Fragment>
    )
}
export default App;
