import React from "react";
import { withStyles } from '@material-ui/core';
import labels from "../../utils/constants/labels";
import colors from "../../utils/colors/colors";
const styles = theme => ({
    col: {
        display: "flex",
        flexDirection: "column"
    },
    flexDirection: {
        display: "flex",
        flexDirection: "row",
        height: "8vh",
        textAlign: 'left',
        marginLeft: '2vw',
        // paddingLeft:'3vw'
    },
    flexDirectionTwo: {
        display: "flex",
        flexDirection: "row",
        height: "10vh",
        textAlign: 'left',
        [theme.breakpoints.down(600)]: {
            display: "flex",
            flexDirection: "row",
            height: "7vh",
            textAlign: 'left',
        }
        // paddingLeft:'3vw'
    },
    flexDirectionone: {
        display: "flex",
        flexDirection: "row",
        // height: "8vh",
        textAlign: 'left',
        marginLeft: '2vw',
        paddingBottom: '26px',
        [theme.breakpoints.down(600)]: {
            marginRight: "5vw"
        }
        // paddingLeft:'3vw'
    },
    labelstyleone: {
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: "12px",
        width: '6vw',
        [theme.breakpoints.down(600)]: {
            color: window.globalConfig.color.black,
            fontSize: "15px",
            width: '50vw',
        }

    },
    labelstyletwo: {
        color: window.globalConfig.color.black,
        fontSize: "12px",
        width: '15vw',
        marginLeft: '3vw',
        overflowWrap: "break-word"
    },
    profilemainlabelone: {
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: "14px",
        width: '10vw',
        [theme.breakpoints.down(600)]: {
            color: window.globalConfig.color.LABLE_GRAY,
            fontSize: "14px",
            width: '100%',
        }

    },
    profilemainlabeltwo: {
        color: window.globalConfig.color.black,
        fontSize: "12px",
        width: '1vw',
        //  marginLeft:'2vw'

    },
    profilemainlabelinvestor: {
        color: window.globalConfig.color.black,
        fontSize: "13px",
        width: '20vw',
        fontWeight: 500,
        // marginLeft: '2vw',
        overflowWrap: "break-word",
        [theme.breakpoints.down('600')]: {
            width: '100%',
        }
    },
    profilemainlabelthree: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "13px",
        width: '8vw',
        //  marginLeft: '2vw',
        fontWeight: 500,
        overflowWrap: "break-word",
        [theme.breakpoints.down(600)]: {
            color: window.globalConfig.color.BLACK_PRIMARY,
            fontSize: "12px",
            width: '26vw',
            marginLeft: '2vw',
            overflowWrap: "break-word"
        }
    },
    colheader1: {
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: "14px",
        textAlign: "left"
    },
    colheader2: {
        textAlign: "left",
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "14px"
    },
    headerstp1: {
        color:window.globalConfig.color.PRIMARY_BORDER,
        fontSize: "12px",
        textAlign: "left",
        marginTop: 10
    },
    headerstp2: {
        textAlign: "left",
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "12px",
        marginTop: 10
    },
    header1: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "larger",
        fontWeight: 600,
        textAlign: "left",
        [theme.breakpoints.down('600')]: {
            fontSize: "16px",
            marginLeft: "10px"    //Added by Celsia on 2 Jun 2021 for Mobile Responsive 
        },
        [theme.breakpoints.down('350')]: {
            fontSize: "16px",
            marginLeft: "8px"    //Added by yogitha on 3 sep 2021 for Mobile Responsive ,changed by dulcy on 08 oct 2021 
        }
    },
    kycLabel: {
        width: "max-content", paddingRight: "15px", fontSize: 14, color: window.globalConfig.color.LABLE_GRAY,

    },
    kycText: {
        maxWidth: "15vw", overflowWrap: "break-word", fontSize: 14, color:window.globalConfig.color.BLACK_PRIMARY,
        [theme.breakpoints.down('600')]: {
            maxWidth: "max-content"
        }

    }
});
class Text extends React.Component {
    //  (props)
    render() {
        const { classes } = this.props
        if (this.props.page === "normal") {
            return (
                <div className={classes.col} >
                    <span className={classes.colheader1}>{this.props.label}</span>
                    <span className={classes.colheader2}>{this.props.text == "" ||this.props.text==" " ? labels.messages.NA : this.props.text==""? labels.messages.NA:this.props.text }</span>
                </div>
            );
        }
        else if (this.props.page === "stp") {
            return (
                <div className={classes.col} >
                    <span className={classes.headerstp1}>{this.props.label}</span>
                    <span className={classes.headerstp2}>{this.props.text == ""||this.props.text==" "  ? labels.messages.NA : this.props.text}</span>
                </div>
            );
        }
        else if (this.props.page === "profilestyleone") {
            return (
                <div className={classes.flexDirection} >
                    <span className={classes.labelstyleone}>{this.props.label}</span>
                    <span className={classes.labelstyletwo} >{this.props.text == ""||this.props.text==" "  ? labels.messages.NA : this.props.text}</span>
                </div>
            );
        }
        else if (this.props.page === "profilestyletwo") {
            return (
                <div className={this.props.addon === "height" ? classes.flexDirectionone : classes.flexDirectionTwo}  >
                    <span className={classes.profilemainlabelone}>{this.props.label}</span>

                    <span className={this.props.addon === "height" ? classes.profilemainlabelinvestor : classes.profilemainlabelthree}>{this.props.text == "" ? labels.messages.NA : this.props.text}</span>
                </div>
            );
        }
        else if (this.props.page === "profileStyleImage") {
            return (
                <div className={this.props.addon === "height" ? classes.flexDirectionone : classes.flexDirectionTwo}  >
                    <span className={classes.profilemainlabelone}>{this.props.label}</span>

                    <div className={this.props.addon === "height" ? classes.profilemainlabelinvestor : classes.profilemainlabelthree} style={{ wordBreak: "break-word" }}>
                        <span>{this.props.text == ""||this.props.text==" " ? labels.messages.NA : this.props.text}</span>
                        {this.props.text != 'N.A' &&
                           <img src={this.props.src} style={{width:20, marginLeft:10, marginTop:-3}}/>
                        }
                    </div>

                </div>
            );
        }
        else if (this.props.page === "kyc") {
            return (
                <div style={{ display: "flex", flexDirection: "row", paddingBottom: "15px", paddingTop: "15px" }}>
                    <span className={classes.kycLabel}>{this.props.label}</span>
                    <span className={classes.kycText}>{this.props.text == ""||this.props.text==" "  ? labels.messages.NA : this.props.text}</span>
                </div>

            )
        }
        else {
            return (
                <div className={classes.col} >
                    <span className={classes.header1}>{this.props.label}</span>
                </div>
            );
        }

    }

}
export default withStyles(styles)(Text)
