// common card created by yogitha on 30/07/21
import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import color from '../../utils/colors/colors';
const styles = theme => ({
    btnappaly: {
        height: 38,
        background: window.globalConfig.color.BROWN,
        padding: 2,
        lineHeight: "36px",
        color: window.globalConfig.color.WHITE,
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "32px",
        width: "100%",
        borderRadius: "5px",
        border: "1px solid transparent",
        cursor: 'pointer',
        '&:hover': {
            background: window.globalConfig.color.PRIMARY_LIGHT,
            color: window.globalConfig.color.WHITE,
        }
    },
    btnName:{
        verticalAlign: 'sub',
    marginLeft: '8%',
    },
    btnClear: {
        height: 38,
        background: "#af772d",
        padding: 2,
        lineHeight: "36px",
        color: window.globalConfig.color.WHITE,
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "32px",
        width: "100%",
        borderRadius: "5px",
        border: "1px solid transparent",
        cursor: 'pointer',
        '&:hover': {
            background: window.globalConfig.color.PRIMARY_LIGHT,
            color: window.globalConfig.color.WHITE,
        }
    },

})
class ImageButton extends Component {
    constructor() {
        super();
        this.state = {


        }
    }
    render() {
        const { classes } = this.props;
        if (this.props.page === "primary" ) {
        return (
            <>
            <div className={classes.btnappaly} style={{opacity:this.props.disabled?0.5:1,cursor:this.props.disabled?"not-allowed":"pointer"}}  loader={this.props.loader} disabled={this.props.loader} onClick={this.props.handleClick}>
                {this.props.children}
                <span className={classes.btnName}>{this.props.name}</span>
            </div>
             {this.props.error == "" ?
             <span style={{ color: window.globalConfig.color.PRIMARY_BORDER, fontSize: 13,whiteSpace:'nowrap' }}>{this.props.message}</span>
             :
             <span style={{ color: window.globalConfig.color.red, fontSize: 13,whiteSpace:'nowrap', }}>{this.props.error}</span>
         }
         </>
        )
        }
        else{
            return (
                <>
                <div className={classes.btnClear} style={{opacity:this.props.disabled?0.8:1,cursor:this.props.disabled?"not-allowed":"pointer"}} disabled={this.props.disabled} loader={this.props.loader} onClick={this.props.handleClick}>
                    {this.props.children}
                    <span className={classes.btnName}>{this.props.name}</span>
                </div>
                 {this.props.error == "" ?
                 <span style={{ color: window.globalConfig.color.PRIMARY_BORDER, fontSize: 13,whiteSpace:'nowrap' }}>{this.props.message}</span>
                 :
                 <span style={{ color: window.globalConfig.color.red, fontSize: 13,whiteSpace:'nowrap', }}>{this.props.error}</span>
             }
             </>
            )  
        }
    }
}
export default withStyles(styles)(ImageButton);