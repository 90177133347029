import * as types from '../../constants/actionsType'
const initialState = {
  Kycmessage: ""
 
}
const reducer = (state = initialState, action) => {
  switch (action.type) {

    case types.KYC:

      return {
        ...state,

        Kycmessage: action.payload.status
      
      }
    case types.EMPTY_KYC:

      return {
        ...state,
        Kycmessage: ""
 
      }
 

    default:
      return state
  }
}
export default reducer
