import * as types from '../../constants/actionsType'
import { bindActionCreators } from 'redux';
import { replaceNull } from '../../../utils/commonfunction';
import moment from 'moment';
const initialState = {
    npsList: [],
    npsBackupList: [],
    transactionNpsList: [],
    valuationNpsList: [],
    isNPSLoading: false,
    isNpsTransactionLoading: false,
  
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.NPS_LIST:
            let arrangeArray = action.payload != null ?
            action.payload.length != 0 ? replaceNull(action.payload, 'NA').map((item, index) => {

                return {
                    "SchemeName": { name: item.schemeName, subHeader1: '1 Transaction', subHeader2: '',subHeaderType:'link', type: "", label: "Scheme Name" },
                    "PranNo": { name: item.PRANNO, subHeader1: "NA", subHeader2: "", type: "", label: "AMC Ref No" },
                    "ValuationEquityNav": { name: item.valuationEquityNav, subHeader1: 'NA', subHeader2: '', type: "amount", label: "AIF Category" },
                    "ValuationCommodityNav": { name: item.valuationCommodityNav, subHeader1: 'NA', subHeader2: '', type: "amount", label: "First Investment Date" },
                    "ValuationGoldNav": { name:item.valuationGoldNav, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Current Value" },
                    "ValuationOtherNav": { name: item.ValuationOtherNav, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Investment Amount" },
                    "ValuationDate": { name: item.valuationDate, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },
                    "SchemeID": { name: item.schemeID, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },


                }
            })
                : [] : []
            return {
                ...state,
                npsList:[...arrangeArray],
                npsBackupList:action.payload
               
            }
        case types.UPDATE_NPS_LIST:
            return {
                ...state,
             
            }
        case types.DELETE_NPS_LIST:
            
            return {
                ...state,
               
            }
        case types.NPS_ADD_UPDATE_DELETE_TRANSACTION_LIST:
          
            return {
                ...state,
               
            }
        case types.EMPTY_NPS_LIST:
            return {
                npsList: [],
                npsBackupList: [],
                transactionNpsList: [],
                valuationNpsList: [],
                isNPSLoading: false,
                isNpsTransactionLoading: false,
            }
        case types.LOADER_NPS_LIST:
            return {
                ...state,
              isNPSLoading:action.payload

            }

       default:
            return state
    }
}
export default reducer