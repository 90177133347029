import React from 'react';
import '../../utils/stylesheets/table/table.css'
import Popover from '@material-ui/core/Popover';
import Filters from '../filter/xrayfilter'
import NoData from '../nodata/nodata'
import Loader from '../loader/loader'
import Label from '../../utils/constants/labels'
import Headers from '../report_common_header/report_common_header'
import { connect } from 'react-redux';
import { getProfitLossDetails, filterChange, profitandlossfilter, GetProfitLoss, equityprofitandlossfilter } from '../../redux/actions/profitloss/profitlossaction'
import { bindActionCreators } from 'redux';
import filterIco from '../../utils/assets/images/filter.png'
import { profitfilterChange, } from '../../redux/actions/filter/reportsfilter'
import Badges from '../badge/badge'
import LazyLoad from 'react-lazyload'
import { NegativeFinding } from '../../utils/commonfunction';
import { withStyles } from '@material-ui/core/styles';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle'
import ErrorBoundary from '../Errorhandling/Errorhandle';
import Scrolltotop from '../scrolltotop/scrolltotop';
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router-dom';
import colors from '../../utils/colors/colors';
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
const styles = theme => ({
    firstGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "firstGrid",
        }
    },
    secondGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "secondGrid",
            textAlign: "justify",
            padding: "10px",
            display: "grid"
        }
    },
    thirdGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "thirdGrid",
            textAlign: "justify",
            padding: "10px",
            display: "grid"
        }
    },
    fourthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "fourthGrid",
            paddingRight: "12px",
            display: "grid",

        }
    },
    fifthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "fifthGrid",
            paddingRight: "12px",
            display: "grid",

        }
    },
    sixthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "sixthGrid"
        }
    },
    hideDesktop: {
        [theme.breakpoints.down("920")]: {
            display: "flex",

        }
    },
    hide: {
        color: "#909090",
        [theme.breakpoints.up("920")]: {
            display: "none"
        }
    },
    align: {
        [theme.breakpoints.down("920")]: {
            justifyContent: "end"
        }
    }
})
class TableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            selectedIndex: "",
            ProfitLossList: [],
            TotalProfitLoss: [],
            selectFilter: 0,
            isDesktopView: true
        };
        this.handleClick = this.handleClick.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }
    resize() {
        let currentHideNav = (window.innerWidth >= 920);
        if (currentHideNav !== this.state.isDesktopView) {
            // 
            this.setState({ isDesktopView: currentHideNav });
        }

    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this))
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        //
        //  this.props.GetProfitLoss(this.props.profitLossfilter)
        this.setState({
            TotalProfitLoss: this.props.ProfitLoss
        })
        if (this.props.flag != labels.ManuHeading.familyPortfolio) {
            this.props.filterSubmit([])
            if (this.props.profitfilterChange != undefined) {
                this.props.profitfilterChange([], this.props.profitLossfilter?.map((item, index) => {
                    return { "value": index, "label": item.Scheme, "isChecked": false }
                }))
            }
        }
    }


    handleClick(event) {
        this.setState({ anchorEl: event.currentTarget })
    };

    handleClose() {
        this.setState({ anchorEl: null })
    };


    renderItem(item, mainindex) {
        const { classes } = this.props

        const itemRows = [
            <div key={mainindex} className={[this.props.grid, classes.subCategoryGroupContainer, mainindex == 0 && classes.borderRadiousFirstIndex].join(' ')}>
                {item.Scheme != undefined && <div style={{ textAlign: "left", paddingLeft: 8 }} className={[classes.subCatgoryValue, "firstGrid", classes.firstGrid].join(" ")}>
                    {item.Scheme}
                </div>}
                {item.units != undefined && <div className={[classes.subCatgoryValue, "secondGrid", classes.secondGrid].join(" ")}>
                    <span className={classes.hide}>{labels.ProfitLoss.Units}</span>
                    {NegativeFinding(parseFloat(item.units).toFixed(3), "", "", "", "number")}
                </div>}
                {item.PurchaseValue != undefined && <div className={[classes.subCatgoryValue, "thirdGrid", classes.thirdGrid].join(" ")}>
                    <span className={classes.hide}>{labels.ProfitLoss.PurchaseValue}</span>
                    <div className={classes.hideDesktop}>
                        <span className={classes.subcategoryRupee}>{Label.currency.Rupee}</span>
                        <span>{NegativeFinding(item.PurchaseValue, "", "", "", "number")}</span>
                    </div>
                </div>}
                {item.SaleValue != undefined && <div className={[classes.subCatgoryValue, "fourthGrid", classes.fourthGrid].join(" ")}>
                    <span className={classes.hide}>{labels.ProfitLoss.SellValue}</span>
                    <div className={[classes.hideDesktop, classes.align].join(" ")}>
                        <span className={classes.subcategoryRupee}>{Label.currency.Rupee}</span>
                        <span> {NegativeFinding(item.SaleValue, "", "", "", "number")}</span>
                    </div>
                </div>}
                {this.props.flag != "equity" && item.Dividend != undefined &&
                    <div className={[classes.subCatgoryValue, "fifthGrid", classes.fifthGrid].join(" ")}>
                        <span className={classes.hide}>{labels.ProfitLoss.DividendValue}</span>
                        <div className={[classes.hideDesktop, classes.align].join(" ")}>
                            <span className={classes.subcategoryRupee}>{Label.currency.Rupee}</span>
                            <span>{NegativeFinding(item.Dividend, "", "", "", "number")}</span>
                        </div>
                    </div>}
                {item.ShortTermAmount != undefined &&
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                        <div className={[classes.subCatgoryValue, "sixthGrid", classes.sixthGrid].join("  ")} style={{ paddingRight: 10 }}>
                            <span className={classes.subcategoryRupee}>{Label.currency.Rupee}</span>
                            <span className="greencolorwithbold" style={{ color: NegativeFinding(item.ShortTermAmount) }}>{NegativeFinding(item.ShortTermAmount, "", "", "", "number")}</span>
                        </div>
                        {item.LongTermAmount != undefined && <div className={[classes.subCatgoryValue, "seventhGrid", classes.seventhGrid].join("  ")} style={{ paddingRight: 10 }}>
                            <span className={classes.subcategoryRupee}>{Label.currency.Rupee}</span>
                            <span className="greencolorwithbold" style={{ color: NegativeFinding(item.LongTermAmount) }}>{NegativeFinding(item.LongTermAmount, "", "", "", "number")}</span>
                        </div>}
                    </div>
                }
                {item.Gain != undefined &&
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                        <div className={[classes.subCatgoryValue, "sixthGrid", classes.sixthGrid].join("  ")} style={{ paddingRight: 10 }}>
                            <span className={classes.subcategoryRupee}>{Label.currency.Rupee}</span>
                            <span className="greencolorwithbold" style={{ color: NegativeFinding(item.Gain) }}>{NegativeFinding(item.Gain, "", "", "", "number")}</span><span className="greencolor" style={{ color: NegativeFinding(item.GainPercent) }}>({NegativeFinding(item.GainPercent, "", "", "", "percentage")})</span>
                        </div>
                        {/* {item.BifurcationofTaxes != undefined && <div className={[classes.subCatgoryValue, "seventhGrid", classes.seventhGrid].join("  ")} style={{ paddingRight: 10 }}>
                            <span className={classes.subcategoryRupee}>{Label.currency.Rupee}</span>
                            <span className="greencolorwithbold" style={{ color: NegativeFinding(item.BifurcationofTaxes) }}>{NegativeFinding(item.BifurcationofTaxes, "", "", "", "number")}</span>
                        </div>} */}
                    </div>
                }

            </div>
        ];
        return itemRows;
    }

    filter = (scheme) => {
        this.props.filterSubmit(scheme)
        this.setState({ anchorEl: null, selectFilter: scheme })
    }
    handleclear = () => {

        this.props.filterSubmit([])
        this.setState({ anchorEl: null, selectFilter: [] })

    }

    render() {
        let allItemRows = [];
        let PURCHASEVALUE = 0;
        let SELLVALUE = 0;
        let DIVIDENDVALUE = 0;
        let GAINVALUE = 0;
        let BifurcationOfTaxes = 0
        let ShortTerm = 0
        let LongTerm = 0
        const { classes } = this.props
        // console.log(this.props.ProfitLoss)
        try {
            this.props.ProfitLoss != null && this.props.ProfitLoss != undefined && this.props.ProfitLoss != "NA" && this.props.ProfitLoss.length != 0 && this.props.ProfitLoss.forEach((item, index) => {
                const perItemRows = this.renderItem(item, index);
                allItemRows = allItemRows.concat(perItemRows);

            });
            if (this.props.ProfitLoss != "NA" && this.props.ProfitLoss.length != 0) {
                PURCHASEVALUE = this.props.ProfitLoss[0]?.TotalPurchase != "NA" ? NegativeFinding(this.props.ProfitLoss[0]?.TotalPurchase, "", "", "", "number") : 0;
                SELLVALUE = this.props.ProfitLoss[0]?.TotalSell != "NA" ? NegativeFinding(this.props.ProfitLoss[0]?.TotalSell, "", "", "", "number") : 0;
                DIVIDENDVALUE = this.props.ProfitLoss[0]?.TotalDividend != "NA" ? NegativeFinding(this.props.ProfitLoss[0]?.TotalDividend, "", "", "", "number") : 0;
                GAINVALUE = this.props.ProfitLoss[0]?.TotalGainLoss != "NA" ? NegativeFinding(this.props.ProfitLoss[0]?.TotalGainLoss, "", "", "", "number") : 0;
                BifurcationOfTaxes = this.props.ProfitLoss[0].TotalBifurcationOfTaxes != "NA" ? NegativeFinding(this.props.ProfitLoss[0].TotalBifurcationOfTaxes, "", "", "", "number") : 0;
                ShortTerm = this.props.ProfitLoss[0].TotalShortTerm != "NA" ? NegativeFinding(this.props.ProfitLoss[0].TotalShortTerm, "", "", "", "number") : 0;
                LongTerm = this.props.ProfitLoss[0].TotalLongTerm != "NA" ? NegativeFinding(this.props.ProfitLoss[0].TotalLongTerm, "", "", "", "number") : 0;

            }


            var a = this.props.glAmount != undefined ? "" : this.props.glAmount?.toString();
            if (Boolean(a)) {
                a = a.replace(/\,/g, ''); // 1125, but a string, so convert it to number
                a = Number(a, 10);
            }

            ////
            // console.log(this.props.List)
            return (
                <ErrorBoundary>
                    {this.props.isProfitLossLoading ?
                        // <>
                        <Loader page="skeletonreport" />
                        // </>
                        :
                        <>
                            {/* <div className="ProfitHeaderBlock"> */}
                            <Headers
                                flag={this.props.flag}
                                data={this.props.ProfitLoss}
                                Reportlist={this.props.ReportList}
                                selectvalue={this.state.selectFilter == 0 ? "" : this.state.selectFilter}
                                scheme={this.props.ProfitLoss.length == 0 ? <NoData /> :
                                    this.props.ProfitLoss.length == 1 ?
                                        this.props.ProfitLoss.length + " " + this.props.header
                                        :
                                        this.props.ProfitLoss.length + " " + this.props.header + "s"
                                }
                                onChange={this.props.onChange}
                                submit={(value) => { this.filter(value) }}
                                List={this.props.List}
                                handleclear={this.handleclear}
                                showfilter={true}
                                showdownload={false}
                                showtoggle={false}
                                value={this.props.YearCode}
                                showsortby={false}
                                selected={false}
                                header={this.props.header}
                                pdfload={this.state.accpdf}
                                newxcelLoad={this.state.accexcel}
                                page={"Profit"}
                            />
                            {/* </div> */}
                            <div className="paddingResponsive" >
                                <div >
                                    <div style={{ width: '100%' }}>
                                        {/* <div className="mobileResponsive">
                                            {this.props.ProfitLoss.length != 0 ?
                                                < div className={classes.tableHeaderResponsive}>
                                                    <div className={classes.headerFlex}>
                                                        <div className={classes.leftResponsive}  >
                                                            {this.props.SchemeName}
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div style={{ paddingTop: '5%' }}>
                                                    <NoData />
                                                </div>
                                            }
                                        </div> */}
                                        <div className="profitlossBlock">
                                            {this.props.isLoadingProfitLoss == false ? this.props.ProfitLoss != null && this.props.ProfitLoss != undefined && this.props.ProfitLoss != "NA" && this.props.ProfitLoss.length != 0
                                                ? (<>
                                                    <div className={[this.props.grid, classes.tableHeader].join(' ')} style={{ marginTop: "3%" }}>
                                                        <div style={{ textAlign: "left", paddingLeft: 10 }}>
                                                            {this.props.SchemeName}
                                                        </div>
                                                        {this.props.Units != undefined &&
                                                            <div style={{ textAlign: "right" }} className={classes.hideDiv}>
                                                                {this.props.Units}
                                                            </div>}
                                                        {this.props.PurchaseValueLabel != undefined && <div style={{ textAlign: "right" }} className={classes.hideDiv}>
                                                            {this.props.PurchaseValueLabel}
                                                        </div>}
                                                        {this.props.SellValueLabel != undefined &&
                                                            <div style={{ textAlign: "right" }} className={classes.hideDiv}>
                                                                {this.props.SellValueLabel}
                                                            </div>}
                                                        {this.props.DividendValueLabel != undefined && <div style={{ textAlign: "right" }} className={classes.hideDiv}>
                                                            {this.props.DividendValueLabel}
                                                        </div>}
                                                        {this.props.shortTerm != undefined && <div style={{ textAlign: "right", paddingRight: 10, display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                                            <span> {this.props.shortTerm}</span>
                                                            {this.props.longTerm != undefined && <div style={{ textAlign: "right", }}>
                                                                {this.props.longTerm}
                                                            </div>}
                                                        </div>}
                                                        {this.props.GainValue != undefined && <div style={{ textAlign: "right", paddingRight: 10, display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                                            <span> {this.props.GainValue}</span>
                                                            {this.props.BifurcationOfTaxes != undefined && <div style={{ textAlign: "right", }}>
                                                                {this.props.BifurcationOfTaxes}
                                                            </div>}
                                                        </div>}

                                                    </div>
                                                    {allItemRows}
                                                    <div className={[this.props.grid, classes.displayTotalContainer].join(' ')}>
                                                        <div style={{ textAlign: "left", paddingLeft: 10, display: "flex", flexDirection: "column" }} className={[classes.firstGrid, "firstGrid"].join(" ")}>
                                                            {Label.messages.grandtotal}
                                                            <div >{this.props.profitLossfilter != "NA" && this.props.profitLossfilter.length <= 1 ? `${this.props.profitLossfilter.length} ${this.props.header}` : `${this.props.profitLossfilter.length} ${this.props.header}s`}</div>
                                                        </div>
                                                        {this.props.Units != undefined &&
                                                            <div style={{ textAlign: "right" }} className={classes.hideDiv}>
                                                            </div>}
                                                        <div style={{ textAlign: "right" }} className={classes.hideDiv}>
                                                            <span className={classes.totalRupee}>{Label.currency.Rupee}</span><span className="footerfontsize">{PURCHASEVALUE}</span>
                                                        </div>
                                                        {SELLVALUE != undefined &&
                                                            <div style={{ textAlign: "right" }} className={classes.hideDiv}>
                                                                <span className={classes.totalRupee}>{Label.currency.Rupee}</span><span className="footerfontsize">{SELLVALUE}</span>
                                                            </div>}
                                                        {this.props.flag != "equity" && DIVIDENDVALUE != undefined && this.props.DividendValueLabel != undefined &&
                                                            <div style={{ textAlign: "right" }} className={classes.hideDiv}>
                                                                <span className={classes.totalRupee}>{Label.currency.Rupee}</span><span className="footerfontsize">{DIVIDENDVALUE}</span>
                                                            </div>
                                                        }
                                                        {this.props.shortTerm != undefined && <div style={{ textAlign: "right", paddingRight: 10, flexDirection: "column", display: "flex" }}>
                                                            <div style={{ textAlign: "right" }} className={classes.hideDiv}>
                                                                <span className={classes.totalRupee}>{Label.currency.Rupee}</span><span className="footerfontsize">{ShortTerm}</span>
                                                            </div>
                                                            <div style={{ textAlign: "right" }} className={classes.hideDiv}>
                                                                <span className={classes.totalRupee}>{Label.currency.Rupee}</span><span className="footerfontsize">{LongTerm}</span>
                                                            </div>
                                                        </div>}

                                                        <div style={{ textAlign: "right", paddingRight: 10, display: "flex", flexDirection: "column" }} className={[classes.sixthGrid, "sixthGrid"].join(" ")}>
                                                            <div>
                                                                <div style={{ marginBottom: "0.1em" }}><span className={classes.totalRupee}>{Label.currency.Rupee}</span><span style={{ color: NegativeFinding(a, colors.GREEN, colors.Red, colors.ZERO_BACKGROUND) }}>{GAINVALUE}</span></div>
                                                                <Badges color={NegativeFinding(a, "", "", window.globalConfig.color.ZERO_BACKGROUND)} textcolor={NegativeFinding(a, window.globalConfig.color.WHITE, window.globalConfig.color.WHITE, window.globalConfig.color.BLACK_PRIMARY)} text={`${NegativeFinding(this.props.GainPercentage, "", "", "", "percentage")}`} />
                                                            </div>
                                                            {/* {this.props.BifurcationOfTaxes != undefined && <div style={{ textAlign: "right", paddingRight: 10 }}>
                                                                <div style={{ textAlign: "right" }} className={classes.hideDiv}>
                                                                    <span className={classes.totalRupee}>{Label.currency.Rupee}</span><span className="footerfontsize">{BifurcationOfTaxes}</span>
                                                                </div>
                                                            </div>} */}
                                                        </div>


                                                    </div>
                                                </>
                                                )
                                                : (
                                                    <></>
                                                )
                                                :
                                                (
                                                    <Loader />
                                                )
                                            }
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {/* <Scrolltotop /> */}
                </ErrorBoundary >

            );
        }
        catch (err) {
            // console.log(err)
            return <Redirect to={labels.Path.NotFound} />

        }
    }
}
const mapStateToProps = state => ({
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getProfitLossDetails, profitfilterChange, equityprofitandlossfilter, filterChange, profitandlossfilter, GetProfitLoss }, dispatch)
}
const mergedTableStyle = combineStyles(styles, CommonTableStyles)
export default withStyles(mergedTableStyle)(connect(mapStateToProps, mapDispatchToProps)(TableComponent))