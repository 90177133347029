import * as types from '../../constants/actionsType';
import getAllMaturityTracker from '../../../api/maturitytracker/maturitytrackerapi';
export function getMaturityTrackerDetails(ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, flag) {
    return (dispatch) => {
        getAllMaturityTrackerAction(dispatch, ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, flag);
    };
}
function getAllMaturityTrackerAction(dispatch, ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, flag) {
    // if (flag === constants.Common.mutualfund) {
    if (ProductID == 5) {
        dispatch({
            type: types.EMPTY_MATURITY_TRACKER,
            payload: [],
        });
        dispatch({
            type: types.LOADER_MATURITY_TRACKER,
            payload: [],
        });
    } else {
        dispatch({
            type: types.EMPTY_MATURITY_TRACKER_BONDS,
            payload: [],
        });
        dispatch({
            type: types.LOADER_MATURITY_TRACKER_BONDS,
            payload: [],
        });
    }


    getAllMaturityTracker(ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID)
        .then((data) => {

            if (data.status == "S") {
                dispatch({
                    type: types.PREVENT_API_CALL,
                    payload: [{ ProductID: ProductID, api: "MaturityTracker" }],
                });

                if (ProductID == 5) {
                    dispatch({
                        type: types.MATURITY_TRACKER,
                        payload: data,
                    });
                } else {
                    dispatch({
                        type: types.MATURITY_TRACKER_BONDS,
                        payload: data,
                    });
                }

            } else {
                dispatch({
                    type: types.PREVENT_API_CALL,
                    payload: [{ ProductID: ProductID, api: "MaturityTracker" }],
                });
                if (ProductID == 5) {
                    dispatch({
                        type: types.MATURITY_TRACKER,
                        payload: [],
                    });
                }
                else {
                    dispatch({
                        type: types.MATURITY_TRACKER_BONDS,
                        payload: [],
                    });
                }

            }

        })
        .catch((error) => {

            if (ProductID == 5) {
                dispatch({
                    type: types.EMPTY_MATURITY_TRACKER,
                    payload: [],
                });
            }
            else {
                dispatch({
                    type: types.EMPTY_MATURITY_TRACKER_BONDS,
                    payload: [],
                });
            }
            dispatch({
                type: types.PREVENT_API_CALL,
                payload: [{ ProductID: ProductID, api: "MaturityTracker" }],
            });


        })
}
export const filterMaturityTracker = (data) => {
    return {
        type: types.FILTER_MATURITY_TRACKER,
        payload: data
    }
}
export const filterMaturityTrackerBonds = (data) => {
    return {
        type: types.FILTER_MATURITY_TRACKER_BONDS,
        payload: data
    }
}
