import React from 'react'
import NoData from '../nodata/nodata'
import '../../utils/stylesheets/layout/layout.css'
import { withStyles } from '@material-ui/core/styles';
import ProgressBar from './progressbar'
import ErrorBoundary from '../Errorhandling/Errorhandle';
import OutlineButton from '../payment/button'
const styles = theme => ({
  chartWrap: {
    // overflow: 'hidden',
  },
  chartFluid: {
    width: '90%',
    paddingLeft: 30,
    paddingRight: 25,
    height: 350,
    minHeight: 300,
  },
  chartFluid1: {
    width: '90%',
    paddingLeft: 30,
    paddingRight: 25,
    height: 870,
    overflowY: "auto"
  },
  root: {
    marginTop: 100,
  },
  barchat: {
    [theme.breakpoints.down("600")]: {
      width: "90%",
    },
  }
});
class Example extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: [],
      btnLabel: "Show More",
      Flag: "More",
      clickcount: 0
    }
  }

  render() {
    try {
      const { classes, data } = this.props;
      if (this.props.data.length == 0) {
        return (
          <div>
            <NoData height={"320px"} />
          </div>
        );
      } else {
        return (<div style={{ paddingLeft: 10, overflowY: "overlay", maxHeight: 357,overflowX:"hidden" }}>
          <ErrorBoundary>
            <div style={{ height: this.props.Flag === "Less" ? 'auto' : 290, maxHeight: 750}} className={classes.barchat}>
              {this.props.data.map((val, index) => {
                // console.log(val)
                return <ProgressBar key={index} amount={val.CurrentValue} percentage={val.percentage} text={val.Sector} color={val.color} ></ProgressBar>
              })}
            </div>

          </ErrorBoundary>
        </div>
        );
      }
    } catch (err) {
      return (
        <div>
          <NoData height={"320px"} />
        </div>
      );
    }
  }
}
export default withStyles(styles)(Example);
