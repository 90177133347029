import * as types from '../../constants/actionsType';
import getAllAssetAllocation from '../../../api/assetallocation/assetallocationapi'
import constants from '../../../utils/constants/apiconstant';
export function getAssetAllocationDetails(ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, flag) {
  return (dispatch) => {
    getAllAssetAllocationAction(dispatch, ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, flag);
  };
}
function getAllAssetAllocationAction(dispatch, ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, flag) {
  // if (flag === constants.Common.mutualfund) {
    dispatch({
      type: types.EMPTY_ASSET_ALLOCATION,
      payload: [],
    });
    dispatch({
      type: types.LOADER_ASSET_ALLOCATION,
      payload: true,
    });
  // } else {
  //   dispatch({
  //     type: types.EMPTY_EQUITY_ASSET_ALLOCATION,
  //     payload: [],
  //   });
  //   dispatch({
  //     type: types.LOADER_EQUITY_ASSET_ALLOCATION,
  //     payload: [],
  //   });
  // }
  getAllAssetAllocation(ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID)
    .then((data) => {
     
        if (data.status == "S") {
          dispatch({
            type: types.PREVENT_API_CALL,
            payload:[{ProductID:ProductID,api:"AssetAllocation"}],
        });
    
        
          dispatch({
            type: types.ASSET_ALLOCATION,
            payload: data,
          });
         
        } else {
          dispatch({
            type: types.PREVENT_API_CALL,
            payload:[{ProductID:ProductID,api:"AssetAllocation"}],
        });
          dispatch({
            type: types.ASSET_ALLOCATION,
            payload: [],
          });
       
        }
      // } else {
      //   if (data.status == "S") {
      //     dispatch({
      //       type: types.PREVENT_API_CALL,
      //       payload:[{ProductID:ProductID,api:"AssetAllocation"}],
      //   });
      //     dispatch({
      //       type: types.EQUITY_ASSET_ALLOCATION,
      //       payload: data,
      //     });
        
      //   } else {
      //     dispatch({
      //       type: types.PREVENT_API_CALL,
      //       payload:[{ProductID:ProductID,api:"AssetAllocation"}],
      //   });
      //     dispatch({
      //       type: types.EQUITY_ASSET_ALLOCATION,
      //       payload: [],
      //     });
         
      //   }
      // }
      // //     }
    })
    .catch((error) => {
      
      if (flag === constants.Common.mutualfund) {
        dispatch({
          type: types.EMPTY_ASSET_ALLOCATION,
          payload: [],

        });
        dispatch({
          type: types.PREVENT_API_CALL,
          payload:[{ProductID:ProductID,api:"AssetAllocation"}],
      });
    } else {
      dispatch({
        type: types.PREVENT_API_CALL,
        payload:[{ProductID:ProductID,api:"AssetAllocation"}],
    });
    dispatch({
          type: types.EMPTY_EQUITY_ASSET_ALLOCATION,
          payload: [],
        })
      
      }
    })
}

