import React, { } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import '../../utils/stylesheets/sidemenu/sidemenu.css';
// // import colors, { basicColor, basicColorReact } from '../../utils/colors/colors';
// let colors=window.globalConfig.color
import colors from '../../utils/colors/colors';
import { bindActionCreators } from 'redux';
import Typography from '@material-ui/core/Typography';
import Popover from "@material-ui/core/Popover";
import Grid from '@material-ui/core/Grid';
import familyIcon from '../../utils/assets/familyIcon.png';
import dropdownIcon from '../../utils/assets/dropdownIcon.png';
import secureStorage from '../../utils/securestorage'
import ListItem from "@material-ui/core/ListItem";
import DashboardCard from '../../components/dashboardcards/totalassetcards';
import QuickActionCards from '../../components/dashboardcards/quickactioncards';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonStyles from '../../utils/stylesheets/commonstyles';
import Group_482294 from '../../utils/assets/Group_482294.png'
import Group_482662 from '../../utils/assets/Group_482662.png';
import Vector from '../../utils/assets/Vector.png'
import Badge from '../../components/badge/badge';
import Grow from "@material-ui/core/Grow";
import ProgressBarExample from '../../components/summary/progressbar';
import Three_State_Toggle_Switch from '../../components/switchbutton/three_state_toggle_switch';
import getAllDashboardAssetAllocation from '../../api/dashboard/assetallocationapi';
import getAllDashboardProductAllocation from '../../api/dashboard/productallocationapi';
import labels from '../../utils/constants/labels';
import { moneyFormatter, NegativeFinding, replaceNull } from '../../utils/commonfunction';
import NoData from '../../components/nodata/nodata';
import { Skeleton } from '@material-ui/lab';
import InformationTooltip from '../../components/tooltip/informationtooltip';
import axios from 'axios';
import { scrollToTopPage } from '../../utils/commonfunction';
import Notificationblock from '../../components/notificationblock/notificationblock';
import encryptKYC from '../../api/profile/encryptionkyc';
import apiurl from '../../api/url/apiurl';
import moment from 'moment';
import Networking from '../../utils/api/apiaccess';
import Toolbar from '../../components/toolbar/toolbar';
import getL5report from '../../api/L5report/L5report';
import { getDashboardAssetAllocation } from '../../redux/actions/dashboard/dashboard_assetallocation';
const assetData = [{ name: "Equity" }]
const styles = (theme) => ({
  emptyWidthDiv: {
    width: "11%",
    [theme.breakpoints.down("600")]: {
      display: "none",
    },
  },

  containerDiv: {
    width: "79%",
    [theme.breakpoints.down("900")]: {
      // width: "100%",
      // padding: "0% 3% 0% 6%",
      width: "95%",
      margin: 10
    },
  },
  sectionHeader: {
    fontSize: 21,
    fontWeight: 600,
    textAlign: "start",
    color: colors.BLACK_PRIMARY
  },
  sectionSubHeader: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: "start",
    color: colors.LABLE_GRAY
  },
  familyButton: {
    cursor: "pointer",
    border: `1px solid ${window.globalConfig.color.PRIMARY}`,
    borderRadius: 15,
    padding: "3px 7px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  familyText: {
    color: window.globalConfig.color.PRIMARY,
    fontSize: 10,
    padding: "2px 5px"
  },
  users: {
    display: "block",
    padding: "15px 20px 12px !important",
    color: window.globalConfig.color.LABLE_GRAY,
    borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    cursor: "default",
    '&:hover': {
      backgroundColor: window.globalConfig.color.RosyBrown,
      color: `${window.globalConfig.color.BROWN} !important`
    },
  },
  assetAllocationContainer: {
    display: "flex", flexDirection: "row", gap: 35,
    [theme.breakpoints.down("900")]: {
      display: "grid",
      gridTemplateColumns: "repeat(3,1fr)",
      gap: 2
    }
  },
  productAllocationContainer: {
    display: "grid", gridTemplateColumns: "repeat(4,1fr)", gap: 10,
    [theme.breakpoints.down("900")]: {
      gridTemplateColumns: "repeat(1,1fr)"
    }
  }
});
let assetAllocation = [{ name: "Equity", ID: 1, color: colors.PRIMARY, isSelected: false, percentage: "30%" },
{ name: "Debt", color: "#08847C", ID: 2, isSelected: false, percentage: "70%" },
{ name: "Bullions", color: "#EE616A", ID: 3, isSelected: false, percentage: "10%" },
{ name: "Properties", color: "#6DCD88", ID: 4, isSelected: false, percentage: "40%" },
{ name: "Hybrid", color: "#C79E5B", ID: 5, isSelected: false, percentage: "5%" },
{ name: "All Assets", color: colors.BLACK_PRIMARY, ID: 6, isSelected: false, percentage: "100%" },
]
class dashboard extends React.Component {
  constructor() {
    super();
    this.timer = null
    this.state = {
      isFamilyPopover: false,
      familydetails: [],
      visibility: false, assetflag: "All",
      productFlag: "Memberwise",
      topCard: [],
      assetAllocationList: [],
      assetFullList: [],
      assetAllocationLoader: false,
      productAllocationList: [],
      productFullList: [],
      productAllocationLoader: false,
      SelectionType: 2,
      AssetSelectionType: 0,
      selectedAssetClassID: 0,
      ClientID: 0,
      selectedFamilyName: "All",
      index: 0,
      selectedcarosel: 0,
      opensession: false,
      startindex: 0,
      endindex: 4,
      showcarousel: false,
      showDesktopcarousel: false,
      loader: false,
      open: false,
      message: "",
      isReadMore: true,
      ShowTooltip: false,
      openDialogBox: false,
      count: 10,
      mainClientId: 0
    }
  }
  familyList = (familydetails) => {
    let familyDetailsList;
    let clientID = this.props.mainClientId
    if (familydetails != "NA" && !this.props.familyLoader) {
      this.setState({
        selectedFamilyName: `All (${familydetails[0]?.FamilyName})`
      })
      familyDetailsList = familydetails.length != 0 ? [{
        ClientName: `All (${familydetails[0].FamilyName})`,
        ClientID: 0
      }, ...familydetails?.filter(
        (ele, ind) =>
          ind ===
          familydetails.findIndex((elem) => elem.ClientID === ele.ClientID))]
        : []
    }
    else {
      familyDetailsList = [];
    }
    if (this.props.IsFamilyHead) {
      if (familydetails.length == 0 || familydetails.filter((item) => item.ClientID != clientID).length != 0) {
        clientID = 0
        this.setState({ ClientID: 0 })
      } else {
        this.setState({ ClientID: this.props.mainClientId })
      }
    }
    else {
      this.setState({ ClientID: clientID })
    }
    this.setState({ mainClientId: clientID })
    this.setState({ familydetails: familyDetailsList })
  }
  async componentDidMount() {
    // Encryption(this.props.logindetails.UserID.toString())
    //this.handleImage(mainindex)
    document.title = window.globalConfig.companyDetails.companyName + " - Dashboard"
    this.setState({ count: 10 })
    scrollToTopPage()
    let clientID = this.props.mainClientId
    await this.setState({ mainClientId: clientID })
    // if (this.props.IsFamilyHead) {
    //   if (this.props.familydetails.filter((item) => item.ClientID != clientID).length != 0) {
    //     clientID = 0
    //     this.setState({ ClientID: 0 })

    //   } else {
    //     this.setState({ ClientID: clientID })
    //   }
    // }
    // else {
    //   this.setState({ ClientID: clientID })
    // }

    await this.familyList(this.props.familydetails)
    this.assetAllocation(this.state.ClientID)
    // if (this.props.assetDashboard.length == 0) {
    // }

  }
  componentDidUpdate(prev, prevState) {
    const { assetDashboard, familydetails, familyLoader } = this.props;
    const { ClientID } = this.state;
    if (prev.familydetails != familydetails) {

      this.familyList(familydetails);

    }
    if (prev.assetDashboard != assetDashboard || prevState.ClientID != ClientID) {
      if (replaceNull(assetDashboard.table3, "NA") != "NA")
        this.assetAllocation(this.state.ClientID)

    }
  }
  toggleChange = (val) => {
    let type;
    switch (val) {
      case "All":
        type = 0
        // this.setState({ SelectionType: 0 })
        break;
      case window.globalConfig.companyDetails.ShortName:
        type = 1
        // this.setState({ SelectionType: 1 })
        break;
      case "Tracker":
        type = 2
        // this.setState({ SelectionType: 2 })
        break;
      default:
        break;
    }
    this.changeAssetAllocationData(this.state.assetFullList, this.state.ClientID, type)
    this.productAllocation(this.state.ClientID, 0, 2, type)
    this.setState({ assetflag: val, productFlag: "Memberwise", SelectionType: 2, AssetSelectionType: type })
    // this.assetAllocation(this.state.ClientID,this.state.SelectionType)
  }
  changeAssetAllocationData = (res, ClientID, type) => {
    if (res.table4 != null && res.table4 != "NA" && res.table4.length != 0) {
      if (res.table4.filter((val) => val.ClientID == ClientID && val.TypeID == type).length != 0) {
        let a = []
        a = res.table4.filter((val) => val.ClientID == ClientID && val.TypeID == type).map(({ ClientID, TypeID, ID, ...item }) => {
          // delete item.ClientID
          // delete item.TypeID
          // delete item.ID
          return Object.keys(item).map((key, index) => {
            if (key.toLowerCase() == "allassets" || res.table1.filter((val) => val.AssetClass.replaceAll(" ", "").toLowerCase() == key.toLowerCase()).length != 0) {
              return {
                name: res.table1.filter((val) => key.toLowerCase() == "allassets" ? val.AssetClass.replaceAll(" ", "").toLowerCase() == "all" : val.AssetClass.replaceAll(" ", "").toLowerCase() == key.toLowerCase())[0]?.AssetClass,
                percentage: `${item[key]}%`,
                ID: res.table1.filter((val) => key.toLowerCase() == "allassets" ? val.AssetClass.replaceAll(" ", "").toLowerCase() == "all" : val.AssetClass.replaceAll(" ", "").toLowerCase() == key.toLowerCase()).length == 0 ? index : res.table1.filter((val) => key.toLowerCase() == "allassets" ? val.AssetClass.replaceAll(" ", "").toLowerCase() == "all" : val.AssetClass.replaceAll(" ", "").toLowerCase() == key.toLowerCase())[0]?.AssetClassID,
                isSelected: key.includes("All") ? true : false,
                color: res.table1.filter((val) => key.toLowerCase() == "allassets" ? val.AssetClass.replaceAll(" ", "").toLowerCase() == "all" : val.AssetClass.replaceAll(" ", "").toLowerCase() == key.toLowerCase()).length == 0 ? colors.PRIMARY : res.table1.filter((val) => key.toLowerCase() == "allassets" ? val.AssetClass.replaceAll(" ", "").toLowerCase() == "all" : val.AssetClass.replaceAll(" ", "").toLowerCase() == key.toLowerCase())[0].ColorCode
              }

            }
          })
        })

        this.setState({
          assetAllocationList: a[0].length == 0 ? [] : a[0]?.filter(value => value != undefined).map((item) => {
            return { ...item, name: item.name.toLowerCase() == "realestate" ? "Real Estate" : item.name }
          })
        })
      }
      else {
        this.setState({ assetAllocationList: [] })
      }

    }
    if (res.table3 != null && res.table3 != "NA" && res.table3.length != 0) {
      // if (ClientID == 0) {
      //   this.setState({ topCard: res.table1.filter((item) => item.ClientID == 0) })
      // } else {
      if (res.table3.filter((item) => item.ClientID == ClientID).length != 0) {
        this.setState({
          topCard: res.table3.filter((item) => item.ClientID == ClientID)
        })
      }
      // }


    } else {
      this.setState({
        topCard: []
      })
    }
  }
  assetAllocation = async (ClientID) => {
    let res = this.props.assetDashboard
    if (replaceNull(res.status, "NA") == "S") {
      this.setState({ assetFullList: res })
      this.productAllocation(ClientID, 0, this.state.SelectionType, this.state.AssetSelectionType)
      if (res.status == "S") {
        this.changeAssetAllocationData(res, this.state.ClientID, 0)
      } else {
        this.setState({ assetAllocationList: [], topCard: [], assetFullList: [] })
      }
    } else {
      await this.props.getDashboardAssetAllocation(this.props.mainClientId, moment(this.props.date).format("DD MMM YYYY"))
    }


  }
  productToggleChange = (val) => {
    this.setState({ productFlag: val, SelectionType: val == "Familywise" ? 1 : 2 })
    this.productAllocation(this.state.ClientID, this.state.selectedAssetClassID, val == "Familywise" ? 1 : 2, this.state.AssetSelectionType)
  }

  openPopover = (e) => {
    this.setState({ isFamilyPopover: e.currentTarget })
  }

  productAllocation = (ClientID, AssetClassID, SelectionType, AssetSelectionType) => {
    if (!this.state.productAllocationLoader) {
      this.setState({ productAllocationLoader: true })
      var data = JSON.stringify({
        "AssetClassID": AssetClassID,
        "ClientID": ClientID == 0 ? this.props.mainClientId : ClientID,
        "FamilyID": ClientID == 0 ? this.props.mainClientId : 0,
        "SelectionType": SelectionType,
        "PortfolioType": AssetSelectionType,
        "AsOnDate": this.props.date
      });
      Networking.PostApi(apiurl.ProductAllocationDashboard, data).then((response) => {
        this.setState({
          productAllocationLoader: false
        })
        let res = response
        if (res.status == "S" && res.data != null && res.data.length != 0) {

          this.setState({

            productAllocationList: res.data.map((item, index) => {
              return { ...item, isVisible: false, ID: index, isFinancial: this.state.assetFullList?.table2?.filter((val) => val.ProductID == item.ProductId)[0]?.IsFinancial || false, Image: this.state.assetFullList?.table2?.filter((val) => val.ProductID == item.ProductId)[0]?.Icon || "", ProductName: this.state.assetFullList?.table2?.filter((val) => val.ProductID == item.ProductId)[0]?.Product || labels.messages.NA }
            })
          })
        } else {
          this.setState({ productAllocationList: [] })
        }
      }).catch((err) => {
        this.setState({ productAllocationList: [], productAllocationLoader: false })
      })


    }


  }
  handleAssetClick = (val) => {
    this.setState({ SelectionType: 2, productFlag: "Memberwise" })
    var percentage = val.percentage.replace("%", "")
    if (Number(percentage) != 0) {
      this.productAllocation(this.state.ClientID, val.ID, 2, this.state.AssetSelectionType)
    } else {
      this.setState({ productAllocationList: [] })
    }
    this.setState({ selectedAssetClassID: val.ID })
    assetAllocation = this.state.assetAllocationList.map((item) => {
      return { ...item, isSelected: item.ID == val.ID ? true : false }
    })
    this.setState({ assetAllocationList: assetAllocation })
  }

  handleProductClick = (val) => {
    this.setState({
      productAllocationList: this.state.productAllocationList.map((item) => {
        return { ...item, isVisible: item.ID == val.ID ? !item.isVisible : false }
      })
    })

  }

  handleClientNameChange = (val) => {
    this.setState({ ClientID: val.ClientID, selectedFamilyName: val.ClientName, SelectionType: 2, AssetSelectionType: 0 })
    this.changeAssetAllocationData(this.state.assetFullList, val.ClientID, 0)
    this.productAllocation(val.ClientID, 0, 2, 0)
    this.setState({
      isFamilyPopover: null, assetflag: "All",
      productFlag: "Memberwise",
    })
  }
  onError = (event) => {
    event.target.src = Group_482294
  }

  handleMembersSwitch = async (val) => {
    if (this.state.productFlag != "Familywise") {
      if (this.state.assetflag == "Tracker") {
        let track = []

        await getL5report(val.ClientID).then((response) => {
          let res = replaceNull(response, "NA")
          if (res.status == "S" && res.data.length != 0 && res.data != "NA") {
            track = res.data.filter((item) => item.L4_AccountTypeID == 3).length == 1 ? res.data.filter((item) => item.L4_AccountTypeID == 3).map((value) => {
              return { ...value, ClientID: val.ClientID, Name: value.PortfolioName }
            })
              : [{ Name: "Combined", L5ID: 0, ClientID: val.ClientID }]
          } else {
            track = []
          }
        })
        if (track.length != 0) {
          if (this.props.l5Func != undefined) {
            this.props.l5Func.current(track[0])
          }
        }
      } else {
        if (this.props.memberFunc != undefined) {
          this.props.memberFunc.current(val)

        }
      }
      if (val.ProductId == 1) {
        this.props.history.push(labels.Path.MutualTab)
      } else if (val.ProductId == 2) {
        this.props.history.push(labels.Path.Equity)
      } else if (val.ProductId == 3) {
        this.props.history.push(labels.Path.BondsTab)
      } else if (val.ProductId == 4) {
        this.props.history.push(labels.Path.GoldTab)
      } else if (val.ProductId == 5) {
        this.props.history.push(labels.Path.FDTab)
      } else if (val.ProductId == 6) {
        this.props.history.push(labels.Path.AIFTab)
      } else if (val.ProductId == 7) {
        this.props.history.push(labels.Path.RealEstateTab)
      }
      else if(val.ProductId == 102){
        this.props.history.push(labels.Path.LiquiloansTab)
      }
      else if (val.ProductId == 8) {
        this.props.history.push(labels.Path.NPSTab)
      }
      else if (val.ProductId == 9) {
        this.props.history.push(labels.Path.InsuranceTab)
      }
      else if (val.ProductId == 10) {
        this.props.history.push(labels.Path.MLDTab)
      }
    } else {

    }

  }
  render() {

    const { classes } = this.props;
    try {
      return (<div style={{ marginBottom: "4vh" }}>
        {/* //  <CommonMenu /> */}
        <ErrorBoundary>
          <Toolbar page="NA" l5Func={this.props.l5Func} childFunc={this.props.childFunc}></Toolbar>
          {/* {window.globalConfig.isKycAllowed && <Notificationblock
          open={this.state.open} message={this.state.message} severity={"warning"} dialogBoxOpen={this.state.openDialogBox} handleClose={this.closeDialogBox} count={this.state.count}
          handlesnanckClose={this.handlesnanckClose} buttonName={labels.Dashboard.createnow} text={labels.Dashboard.alertmessage} onClick={this.createnow} loader={this.state.loader} />} */}
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className={classes.emptyWidthDiv}>

            </div>
            <div className={classes.containerDiv}>
              <div>
                <div style={{ display: "flex" }}>
                  <div style={{ flex: 2 }}>
                    <Typography className={classes.sectionHeader} style={{ marginTop: 10 }}>Dashboard</Typography>
                    <Typography className={classes.sectionSubHeader} style={{ marginBottom: 10 }}>A bird’s eye view of all your assets</Typography>
                  </div>
                  <div style={{ display: "flex", flex: 1, justifyContent: "flex-end", alignItems: "center" }}>
                    {!this.props.familyLoader && this.props.IsFamilyHead && this.props.familydetails.filter((item) => item.ClientID != this.state.mainClientId).length != 0 &&
                      <div className={classes.familyButton} onClick={(e) => this.openPopover(e)}>

                        <img src={familyIcon} style={{ height: 12, width: 12 }}></img>
                        <Typography className={classes.familyText} >{this.state.selectedFamilyName} </Typography>
                        <img src={dropdownIcon} style={{ height: 7, width: 7 }}></img>
                      </div>
                    }

                    <Popover
                      open={Boolean(this.state.isFamilyPopover)}
                      anchorEl={this.state.isFamilyPopover}
                      onClose={() => this.setState({ isFamilyPopover: null })}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <div className={classes.popup}>

                        {this.state.familydetails.map((i, index) => {

                          return (
                            <ListItem key={index}
                              autoFocus={true}

                              className={classes.users}
                              style={{ cursor: 'pointer', backgroundColor: i.ClientID == this.state.ClientID ? colors.RosyBrown : "transparent", color: i.ClientID == this.state.ClientID ? colors.BROWN : colors.BLACK_PRIMARY }}
                              onClick={() => this.handleClientNameChange(i)}
                            >
                              <Typography
                                className={classes.username}
                              >{i.ClientName}</Typography>
                            </ListItem>
                          )
                        })}
                      </div>
                    </Popover>
                  </div>
                </div>
                <Grid container spacing={1}>
                  <Grid item md={6} xs={12} lg={4}>
                    <DashboardCard isLoading={this.props.isDashboardLoading} isTotal={true} header={"Total Asset"} mainAmount={this.state.topCard[0]?.TotalAssets || 0} firstContainerValue={this.state.topCard[0]?.TotalInvestments || 0} secondContainerValue={this.state.topCard[0]?.TotalOthers || 0} firstContainerSubValue={""} />
                  </Grid>
                  <Grid item md={6} xs={12} lg={4} >
                    <DashboardCard isLoading={this.props.isDashboardLoading} header={window.globalConfig.companyDetails.ShortName} mainAmount={this.state.topCard[0]?.OwnTotal || 0} firstContainerValue={this.state.topCard[0]?.OwnPortfolio || 0} secondContainerValue={this.state.topCard[0]?.OwnOthers || 0} firstContainerSubValue={this.state.topCard[0]?.OwnXIRR || 0} />
                  </Grid>
                  <Grid item md={6} xs={12} lg={4}>
                    <DashboardCard isLoading={this.props.isDashboardLoading} mainAmount={this.state.topCard[0]?.TrackTotal || 0} firstContainerValue={this.state.topCard[0]?.TrackPortfolio || 0} secondContainerValue={this.state.topCard[0]?.TrackOthers || 0} firstContainerSubValue={this.state.topCard[0]?.TrackXIRR || 0} isImport={true} header={"Tracker"} history={this.props.history} />
                  </Grid>
                </Grid>
                <div style={{ display: "flex", flexDirection: "row", margin: "35px 0px", justifyContent: "space-between" }}>
                  <Typography className={classes.sectionHeader} style={{ marginTop: 10, marginBottom: 10 }}>Asset Allocation</Typography>
                  <Three_State_Toggle_Switch
                    values={['All', window.globalConfig.companyDetails.ShortName, 'Tracker']}
                    handleChangeToggle={(val) => { this.toggleChange(val) }}
                    selectedFlag={this.state.assetflag}
                  ></Three_State_Toggle_Switch>
                </div>
                {this.props.isDashboardLoading ? <Skeleton style={{ height: 260, marginTop: -78 }}></Skeleton> : this.state.assetAllocationList.length != 0 ? <div style={{ width: "-webkit-fill-available", boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)", padding: 25, borderRadius: 10, marginBottom: 10 }}>
                  <div className={classes.assetAllocationContainer}>

                    {this.state.assetAllocationList.map((item, index) => {
                      return <div key={index} style={{ borderBottom: `3px solid ${item.isSelected ? item.color : colors.BORDER}`, cursor: "pointer", paddingBottom: 15, paddingRight: 21, width: "fit-content", textAlign: "left", display: "flex", flexDirection: "column", gap: 8 }} onClick={() => this.handleAssetClick(item)}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 4, fontSize: 13, fontWeight: 500 }}>
                          <div style={{ height: 7, width: 15, backgroundColor: item.color, borderRadius: 5 }}></div>
                          <span>{item.name}</span>

                        </div>
                        <span style={{ fontWeight: "bolder", fontSize: 18 }}>{item.percentage}</span>
                      </div>
                    })}
                  </div>
                </div> : <NoData></NoData>}
                <div style={{ display: "flex", flexDirection: "row", margin: "35px 0px", justifyContent: "space-between" }}>
                  <Typography className={classes.sectionHeader} style={{ marginTop: 10, marginBottom: 10 }}>Product Allocation</Typography>

                  {this.state.ClientID == 0 && <Three_State_Toggle_Switch
                    values={['Memberwise', 'Familywise']}
                    handleChangeToggle={(val) => { this.productToggleChange(val) }}
                    selectedFlag={this.state.productFlag}
                  ></Three_State_Toggle_Switch>}
                </div>
                {this.state.productAllocationLoader ? <Skeleton style={{ height: 260, marginTop: -78 }}></Skeleton> : this.state.productAllocationList.length != 0 ?

                  <div className={classes.productAllocationContainer}>
                    {this.state.productAllocationList.map((item, index) => {
                      return (
                        <div onClick={() => this.handleMembersSwitch(item)} key={index} style={{ backgroundColor: colors.WHITE, transition: "all .2s", gap: 10, textAlign: "left", boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)", display: "flex", alignItems: "center", flexDirection: "row", borderRadius: 10, cursor: "pointer" }}>
                          <div style={{ display: "flex", flexDirection: "column", padding: 15, paddingTop: 0, gap: 7 }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginTop: 10 }}>
                              <img src={`${window.globalConfig.DashboardImage}${item.Image}`} onError={(event) => this.onError(event)} style={{ width: "18%" }}></img>
                              {!item.isVisible && <img src={Vector} style={{ width: 8, height: 10, cursor: "pointer" }} onMouseEnter={() => this.handleProductClick(item)} />
                              }                  </div>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "0px 10px 0px 0px", alignItems: "center", borderRight: !this.state.visibility ? "" : `1px solid ${colors.BORDER}` }}>
                              <span style={{ fontSize: "14px", fontWeight: 400 }}>{item.ProductName || labels.messages.NA} </span>
                              {item.isVisible && <img src={Group_482662} style={{ width: 8, height: 10, cursor: "pointer" }} onMouseEnter={() => this.handleProductClick(item)} />
                              }                  </div>
                            <div style={{ display: "flex", flexDirection: "row", gap: 3 }}>
                              <span style={{ fontWeight: "bold" }}>{moneyFormatter(item.Amount)} </span>
                              <span style={{ color: NegativeFinding(item.Amount), transform: item.Amount.toString().search("-") > -1 ? "rotateX(180deg) rotateY(180deg)" : "none" }}>  ^</span>
                            </div>
                            <ProgressBarExample bgcolor={colors.PRIMARY} percentage={item.Allocation} height={3} backgroundColor={colors.BORDER} page="primary"></ProgressBarExample>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10, justifyContent: "space-between" }}>
                              <span style={{ fontSize: "x-small" }}>{item.Allocation}% Allocation</span>
                              <div style={{ display: "flex", flexDirection: "row", gap: 10 }}>
                                {this.state.productFlag == "Familywise" && replaceNull(item.TransferInAmount, "NA") != "NA" && replaceNull(item.TransferInAmount, "NA") != 0 && <Badge
                                  page={"primary"}
                                  verified={true}
                                  style={{ padding: "2px 2px", fontSize: 10, color: colors.PRIMARY, borderColor: colors.PRIMARY }}
                                  text={"TransferIn"}
                                ></Badge>}
                                {this.state.productFlag == "Familywise" && <Badge
                                  page={"primary"}
                                  verified={true}
                                  style={{ padding: "2px 2px", fontSize: 10, color: item.isFinancial ? colors.GREEN : colors.WARNING, borderColor: item.isFinancial ? colors.GREEN : colors.WARNING }}
                                  text={
                                    item.isFinancial ? "Portfolio" : "Others"
                                  }
                                ></Badge>}
                              </div>
                            </div>
                            {this.state.productFlag != "Familywise" &&
                              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10, justifyContent: "space-between" }}>
                                <Badge color={colors.BORDER} textColor={colors.BLACK_PRIMARY} text={item.ClientName || labels.messages.NA}></Badge>
                                {replaceNull(item.TransferInAmount, "NA") != "NA" && replaceNull(item.TransferInAmount, "NA") != 0 && <Badge
                                  page={"primary"}
                                  verified={true}
                                  style={{ padding: "2px 2px", fontSize: 10, color: colors.PRIMARY, borderColor: colors.PRIMARY }}
                                  text={"TransferIn"}
                                ></Badge>}
                                <Badge
                                  page={"primary"}
                                  verified={true}
                                  style={{ padding: "2px 2px", fontSize: 10, color: item.isFinancial ? colors.GREEN : colors.WARNING, borderColor: item.isFinancial ? colors.GREEN : colors.WARNING }}
                                  text={
                                    item.isFinancial ? "Portfolio" : "Others"
                                  }
                                ></Badge>
                              </div>
                            }

                          </div>
                          {item.isVisible &&
                            <Grow
                              in={item.isVisible}
                              style={{ transformOrigin: "0 0 0", paddingRight: 18 }}
                              {...(item.isVisible ? { timeout: 1000 } : {})}
                            >
                              <div >
                                <div style={{ display: "flex", flexDirection: "column", backgroundColor: colors.BORDER, borderRadius: 10, lineHeight: 1.5, padding: 10 }}>
                                  <div style={{ display: "flex", flexDirection: "column", padding: 5 }}>
                                    <span>{moneyFormatter(item.OwnAmount)}</span>
                                    <span style={{ fontSize: "xx-small" }}>{window.globalConfig.companyDetails.ShortName}</span>
                                  </div>
                                  {replaceNull(item.TransferInAmount, "NA") != "NA" && replaceNull(item.TransferInAmount, "NA") != 0 && < div style={{ borderTop: `1px solid ${colors.PRIMARY_BORDER}`, display: "flex", flexDirection: "column", padding: 5 }}>
                                    <span>{moneyFormatter(item.TransferInAmount)}</span>
                                    <span style={{ fontSize: "xx-small" }}>TransferIn</span>
                                  </div>}
                                  <div style={{ display: "flex", flexDirection: "column", padding: 5, borderTop: `1px solid ${colors.PRIMARY_BORDER}` }}>
                                    <span>{moneyFormatter(item.HeldawayAmount)}</span>
                                    <span style={{ fontSize: "xx-small" }}>Heldaway</span>
                                  </div>

                                </div>
                              </div>
                            </Grow>}
                        </div>
                      )
                    })}
                  </div> : <NoData></NoData>}
              </div>
              {window.globalConfig.isTradeAllowed && <div style={{ marginBottom: 20 }}>
                <Typography className={classes.sectionHeader} style={{ margin: "35px 0px", }}>Quick Actions</Typography>
                <QuickActionCards history={this.props.history} logindetails={this.props.logindetails} />
              </div>}
            </div>
          </div >

        </ErrorBoundary >
      </div >
      );
    } catch (err) {
      return <div></div>
    }

  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getDashboardAssetAllocation
    },
    dispatch
  );
};
const mapStateToProps = state => ({
  sessionId: state.Login.sessionID,
  IsFamilyHead: state.Login.logindetails.IsFamilyHead,
  logindetails: state.Login.logindetails,
  companyDetails: state.Login.companyDetails,
  familydetails: state.Login.familydetails,
  familyLoader: state.Login.familyLoader,
  mainClientId: state.Login.mainClientID,
  date: state.Login.date,
  isDashboardLoading: state.Dashboard.isDashboardAssetAllocationLoading,
  assetDashboard: state.Dashboard.dashboardAssetAllocation
});
const mergedStyle = combineStyles(styles, CommonStyles)
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(dashboard));