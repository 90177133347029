import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ClosefilterImage from "../../utils/assets/images/img15.jpg";
import "../../utils/stylesheets/common/global/schemes/schemefilter.css";
import "../../utils/stylesheets/common/global/x-ray-header/x-ray-header.css";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { getTransactionDetailsListing } from "../../redux/actions/transactiondetails/transactiondetailsaction";
import {
  SchemeFilters,
  Filterclick,
  TopTen,
} from "../../redux/actions/dropdown/dropdownaction";
import { bindActionCreators } from "redux";
import labels from "../../utils/constants/labels";
import { SchemeDetails } from "../../redux/actions/schemes/schemeaction";
import secureStorage from "../../utils/securestorage";
import { NetworkErrorSchemeDetails } from "../../redux/actions/schemes/schemeaction";
import Button from "../custombutton/button";
import CloseImage from "../../utils/assets/images/img24.png";
import Grow from "@material-ui/core/Grow";
import { replaceNull } from "../../utils/commonfunction";
//import Autocomplete from '@material-ui/lab/Autocomplete';
const styles = (theme) => ({


  filterBoxSHow: {
    border: 0,
    // minHeight: "97px",
    width: "100%",
    position: "relative",
    float: "left",
    width: "62px",
    height: "60px",
    //transition:"width 2s linear 1s"
  },

  filClose: {
    background: `url(${CloseImage}) center center no-repeat`,
    width: "13px",
    height: "13px",
    display: "block",
    position: "absolute",
    right: "20px",
    top: "20px",
    cursor: "pointer",
  },

  filterBox: {
    boxShadow: "7px 7px 20px 0 rgb(0 0 0 / 20%)",
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    backgroundColor: window.globalConfig.color.WHITE,
    width: "340px",
    position: "absolute",
    right: 0,
    zIndex: 1,
    top: "35px",
    borderRadius: "8px",
    padding: "15px 20px 20px",
    fontSize: "14px",
    color: window.globalConfig.color.LABLE_GRAY,
    fontWeight: 500,
    [theme.breakpoints.down(900)]: {
      top: "31px",
      //  right: "revert",
      width: "41vw",
    },
    [theme.breakpoints.down(600)]: {
      top: "31px",
      //right: "revert",
      width: "64vw",
    },
  },
  //This class used only Buy and sell Mobile Filter Popup Responsive
  filterBoxSecond: {
    boxShadow: "7px 7px 20px 0 rgb(0 0 0 / 20%)",
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    backgroundColor: window.globalConfig.color.WHITE,
    width: "340px",
    position: "absolute",
    right: 0,
    zIndex: 1,
    top: "35px",
    borderRadius: "8px",
    padding: "15px 20px 20px",
    fontSize: "14px",
    color: window.globalConfig.color.LABLE_GRAY,
    fontWeight: 500,
    [theme.breakpoints.down(600)]: {
      top: "31px",
      width: "64vw",
      left: "3px",
    },
  },
  h5style: {
    fontSize: "14px",
    fontWeight: "bold",
    color: window.globalConfig.color.BLACK_PRIMARY,
    paddingBottom: "20px",
  },
});
const user = secureStorage.getItem("user");
class FilterPopup extends PureComponent {
  constructor(props) {
    super(props);
    this.filter = React.createRef("");
    this.state = {
      openfilterpopup: false,
      showselected: false,
      apply: false,
    };
  }
  componentDidMount = () => {
    window.addEventListener("keydown", (e) => {
      var a = document.activeElement;
      //  
      // this.filter.current.focus();
      this.keyDownHandler(e, a);
    });
  };

  componentWillUnmount() {
    window.removeEventListener("keydown", (e) => {
      var a = document.activeElement;
      // 
      this.keyDownHandler(e, a);
    });
  }
  keyDownHandler = (e, activeTextarea) => {
    // 
    // only execute if tab is pressed
    //const activeTextarea = document.activeElement;
    if (e.key === "Tab") {
      if (activeTextarea.id == `${"normalwithsearch"}${this.props.id}`) {

      } else {
        //  this.setState({style:"1px solid black"})
        //this.closeDropdown()
      }
      // 
    }
    // 
    if (e.key === "Enter") {
      if (activeTextarea.id == `${"normalwithsearch"}${this.props.id}`) {
        // this.openListDropdown()
      } else {
        //this.closeDropdown()
      }
    }
  };
  handleappyclick = () => {
    //   
    this.props.onClick();
    // console.log(this.props.selectedvalue)
    if (this.props.selectedvalue.length == 0) {
      this.setState({ showselected: false });
    } else {
      this.setState({ showselected: true });
    }

    this.closePopupbox();
  };
  componentDidUpdate(prev) {
    const { selectedvalue, transactionlist } = this.props;
    //
    if (prev.selectedvalue != selectedvalue) {
      if (replaceNull(selectedvalue, "NA") == "NA") {
        this.setState({ showselected: false });
      } else {
        this.setState({ showselected: true });
      }
    }
  }
  openPopupbox = () => {
    //
    this.setState({ openfilterpopup: true });
  };
  closePopupbox = () => {

    this.setState({ openfilterpopup: false });
  };
  onClickAwayPopupbox = () => {
    if (this.props.onOutsideClick != undefined) {
      this.props.onOutsideClick();
    }

    this.setState({ openfilterpopup: false });
  };
  handleClearFilter = () => {
    this.handleClear();
    this.setState({ showselected: false });
  };
  handleClear = () => {
    if (this.props.onOutsideClick != undefined) {
      this.props.onOutsideClick();
    }
    if (this.props.handleClear != undefined) {
      this.props.handleClear();
    }
  };
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.filterBoxSHow} ref={this.filter}>
        {!this.props.showclose ? (
          <div></div>
        ) : (

          this.state.showselected && (
            <img
              src={ClosefilterImage}
              onClick={this.handleClearFilter}
              style={{
                height: 15,
                width: 16,
                marginRight: -28,
                // position: "absolute",
                right: 5,
                top: 5,
                cursor: "pointer",
              }}
            />
          )
        )}
        {this.state.showselected ? (
          <div
            className={this.props.selectedFilterImagestyle}
            onClick={this.openPopupbox}
          ></div>
        ) : (
          <div
            className={this.props.filterImagestyle}
            onClick={this.openPopupbox}
          ></div>
        )}

        {this.state.openfilterpopup && (
          <ClickAwayListener onClickAway={this.onClickAwayPopupbox}>
            <Grow
              in={this.state.openfilterpopup}
              style={{ transformOrigin: "0 0 0" }}
              {...(this.state.openfilterpopup ? { timeout: 1000 } : {})}
            >
              <div
                className={[
                  classes.filterBox,
                  this.props.flag == "BuyandSellMobile"
                    ? classes.filterBoxSecond
                    : classes.noClass,
                ].join(" ")}
                style={{
                  minHeight:
                    this.props.minHeight == undefined
                      ? 97
                      : this.props.minHeight,
                }}
              >
                <div style={{ display: "flex", flexDirection: "row", }}>
                  <span className={classes.h5style}>{labels.messages.filterBy}</span>
                  <span
                    className={classes.filClose}
                    onClick={this.onClickAwayPopupbox}
                  ></span>
                </div>
                {this.props.children}
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ display: "flex", flex: 1 }}></div>
                  <div style={{ display: "flex", flexDirection: "row", gap: '10px', paddingTop:"10px" }}>
                    <Button
                      page="secondary"
                      onClick={this.handleClear}
                      name={labels.button.clear}
                    ></Button>
                    <Button
                      page="primary"
                      onClick={this.handleappyclick}
                      name={labels.button.apply}
                    ></Button>
                  </div>
                </div>
              </div>
            </Grow>
          </ClickAwayListener>
        )}
      </div>

    );
  }
}
const mapStateToProps = (state) => {
  return {

  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getTransactionDetailsListing,
      TopTen,

      Filterclick,
      SchemeFilters,
      SchemeDetails,
      NetworkErrorSchemeDetails,
    },
    dispatch
  );
};
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(FilterPopup)
);
