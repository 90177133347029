import React, { useEffect } from 'react'
import CommonHeaders from '../../components/report_common_header/report_common_header'
import { connect } from 'react-redux';
import NoData from '../../components/nodata/nodata';
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import { NegativeFinding } from '../../utils/commonfunction';
import getAllcommonDownloadDetails from '../../api/commondownload/commondownload';
import moment from 'moment';
import Loader from '../../components/loader/loader'
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyle from '../../utils/stylesheets/table/commontablestyle'
import { withStyles } from '@material-ui/core';
import colors from '../../utils/colors/colors';
import apiurl from '../../api/url/apiurl';
import CommonSnackbar from '../../components/snackbar/snackbar'
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import Constants from '../../utils/constants/apiconstant';
import Pagination from '../../components/pagination/renderpage';
import { MContext } from '../../components/pagination/renderpage';
import Networking from '../../utils/api/apiaccess';
import { async } from 'q';
const styles = (theme) => ({
    schemeNameWidth: {
        // width: '15vw', 
        textAlign: "left",
        [theme.breakpoints.down("600")]: {
            width: '100%',
            // paddingTop: '3%',
            paddingBottom: '2%',
            fontWeight: "500"
        },
    },
    otherWidth: {
        // width: 100
    },
    otherWidthAlignment: {
        // width: 100, 
        display: "flex", flexDirection: "column"
    },
    otherWidthAlignmentResponsive: {
        // width: 100, 
        display: "flex", flexDirection: "column",
        textAlign: "right"
    },
    mainTitle: {
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: "16px"
    },
    mainTitleResponsive: {
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: "14px",
        fontWeight: "500"
    },
    // mobile responsive added by yogitha on 19/05/2021
    desktopResponsive: {
        display: 'block !important',
        [theme.breakpoints.down("928")]: {
            display: 'none !important',
        },
    },
    mobileResponsive: {
        display: 'none !important',
        [theme.breakpoints.down("928")]: {
            display: 'block !important',
            // width: '100%',
            padding: "0px 10px"
        },
    },
    row: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,2fr)',
        padding: '0% 2% 0% 2%',
    },
    categoryGroupContainerResponsive: {
        height: "auto",
        // marginTop: "10px",
        textAlign: "right",
        backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
        borderWidth: "1px",
        // borderTopLeftRadius: '8px',
        // borderTopRightRadius: '8px',
        borderRadius: "0px",
        padding: "10px",
        // display: "grid",
        // gridTemplateColumns: "repeat(10,1fr)",
        alignItems: "center",
        color: window.globalConfig.color.PRIMARY_BLUE,
        lineHeight: 1.5,

    },
    subCategoryGroupContainerResponsive: {
        border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        height: 'auto',
        // padding: "10px 0",
        fontSize: '13px',
        textAlign: "right",
        borderRadius: "5px",
        fontWeight: 400,
        alignItems: "center",
        lineHeight: 1.5,
        boxShadow: "0 0 5px 0 rgb(19 89 161 / 6%)"
        // paddingTop:"12px",
        // paddingBottom:"17px",
    },
    girdAlignmentResponsive: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 27% [col-start] 32% [col-middle] 30%[col-end])',
        columnGap: '5%',
        paddingBottom: '3%',
        paddingTop: '5%',
        textAlign: 'left',
    },
    girdAlignmentMobileResponsive: {
        display: 'flex',
        // paddingBottom: '3%',
        // paddingTop: '3%',
        justifyContent: "space-between"
    },
    leftAlign: {
        textAlign: 'left',
    },
    rightAlign: {
        textAlign: 'right',
    },
    centerAlign: {
        textAlign: 'center',
    },
    subTileFontSize: {
        fontSize: 12,
        color: window.globalConfig.color.LABLE_GRAY,
        alignItems: 'center',
        fontWeight: 500,
        lineHeight: 1.5,
        borderRadius: '10px 10px 0px 0px',
        textTransform: 'uppercase',
    },
    categoryRupeeSize: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: '14px',
    },
    subCatgoryValueResponsive: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: '15px',
        fontWeight: 500,
    },
    GranttotalContainerResponsive: {
        fontSize: "16px",
        color: window.globalConfig.color.WHITE,
        fontWeight: 500,
        // borderRadius: '8px',
        backgroundColor: window.globalConfig.color.BLACK_PRIMARY,
        height: "100px",
        marginTop: "2px",
        textAlign: "right", alignItems: "center",
        marginBottom: "30%",
        lineHeight: 1.5,
    },
    totalInvest: {
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: '13px',
    },
    cardAlignmentResponsive: {
        paddingLeft: 10,
        backgroundColor: 'white',
        border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        // borderRadius:'10px',
        [theme.breakpoints.down('xs')]: {
            borderRadius: "3px",
            padding: 10,
        }
    },
    noDataDesign: {
        [theme.breakpoints.down('xs')]: {
            height: 50,
            width: "100%",
            paddingTop: 30,
            paddingBottom: 30
        }
    },
    headerMobileResponsive: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        }
    },
    firstGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "firstGrid"
        }
    },
    secondGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "secondGrid"
        }
    },
    thirdGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "thirdGrid"
        }
    },
    fourthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "fourthGrid"
        }
    },
    fifthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "fifthGrid"
        }
    },
    sixthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "sixthGrid"
        }
    },
    seventhGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "seventhGrid"
        }
    },
    eighthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "eighthGrid"
        }
    },
    ninthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "ninthGrid"
        }
    },
    tenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "tenthGrid"
        }
    },
    eleventhGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "eleventhGrid"
        }
    },
    twelfthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "twelfthGrid"
        }
    },
    thirteenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "thirteenthGrid"
        }
    },
    fourteenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "fourteenthGrid"
        }
    },
    fifteenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "fifteenthGrid"
        }
    },
    sixteenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "sixteenthGrid"
        }
    },
    seventeenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "seventeenthGrid"
        }
    },
    eighteenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "eighteenthGrid"
        }
    },
    ninteenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "ninteenthGrid"
        }
    },
    twentiethGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "twentiethGrid"
        }
    },
    mobileResponsive: {
        display: "none",
        [theme.breakpoints.down("920")]: {
            display: "grid"
        }
    },
    labelStyle: {
        fontSize: 14,
        fontWeight: 500,
        color: colors.LABLE_GRAY
    },
    desktopResponsive: {
        display: "grid",
        [theme.breakpoints.down("920")]: {
            display: "none !important"
        }
    },
    //     fontSize: "16px",
    //       textTransform:"uppercase"
    // }
});
var title;
class XrayWithRedemption extends React.Component {
    isComponentMounted = false;
    constructor() {
        super()
        this.state = {
            checked: true,
            accpdf: false,
            accexcel: false,
            Open: false,
            productID: '',
            pdfurl: '',
            xcelurl: '',
        }
    }

    componentDidMount = () => {
        this.isComponentMounted = true;
        title = [{ "name": this.props.schemeNameLabel }, { "name": this.props.units }, { "name": this.props.invested, 'subheader': this.props.amountLabel }, { "name": this.props.current, "subheader": this.props.value, 'subheader1': this.props.Gainloss },
            , { "name": this.props.units }, { "name": this.props.invested, 'subheader': this.props.amountLabel }, { "name": this.props.redemption, "subheader": this.props.value, 'subheader1': this.props.Gainloss },
            , { "name": this.props.units }, { "name": this.props.invested, 'subheader': this.props.amountLabel }, { "name": this.props.value, 'subheader1': this.props.Gainloss }]

        switch (this.props.flag) {
            case "mutualfunds":
                return this.setState({
                    productID: 1,
                    pdfurl: `${apiurl.XrayWithRedemptionpdf}`,
                    xcelurl: `${apiurl.XrayWithRedemptionexcel}`,
                });

            case "equity":
                return this.setState({
                    productID: 2,
                    pdfurl: `${apiurl.xrayWithRedemptionEquityPdfUrl}`,
                    xcelurl: `${apiurl.xrayWithRedemptionEquityXcelUrl}`,
                });

            case Constants.Common.Bonds:
                return this.setState({
                    productID: 3,
                    pdfurl: `${apiurl.xrayWithRedemptionBondsPdfUrl}`,
                    xcelurl: `${apiurl.xrayWithRedemptionBondsXcelUrl}`,
                });
            case Constants.Common.GB:
                return this.setState({
                    productID: 4,
                    pdfurl: `${apiurl.xrayWithRedemptionGoldBondsPdfUrl}`,
                    xcelurl: `${apiurl.xrayWithRedemptionGoldBondsXcelUrl}`,
                });
            default:
                this.setState({
                    productID: 5,
                    pdfurl: "",
                    xcelurl: '',
                });
        }
    }

    componentWillUnmount = () => {
        this.isComponentMounted = false;
    }

    handleChange = (event) => {
        this.setState({ checked: event.target.checked });
    };

    onclickpdf = async (ext) => {
        var pdfexcel = false;
        var filename = `${this.state.productID == 1 ? labels.DownloadLabel.xraywithredemption : labels.DownloadLabel.xrayWithSoldUnits}.${ext}`
        var url = ""
        let data = {}
        if (this.state.accpdf == false) {
            if (ext === "pdf") {
                this.setState({ accpdf: true })
                //MM/DD/YYYY
                data = {
                    "ClientID": this.props.logindetails.ClientID, "AccountID": 0, "ReportID": labels.Report.xrayWithRedemption, "WithRedeem": false, "AsOnDate": moment(this.props.date).format("DD MMM YYYY"),
                    "SubReport": 0, "DownloadAs": 1, "ProductID": this.state.productID, "FamilyID": 0, "L5ID": 0, "L4ID": 0
                }
                url = `${this.state.pdfurl}${this.props.logindetails.ClientID}&asOnDate=${this.props.date == "" || this.props.date == null ? moment().format("DD MMM YYYY") : moment(this.props.date).format("DD MMM YYYY")}&accountId=${0}&ProductID=${this.state.productID}&FamilyID=${0}&L5ID=${this.props.L5ID}&L4ID=${0}`
                pdfexcel = true;
            }
        }
        if (this.state.accexcel == false) {
            if (ext === "xlsx") {
                url = `${this.state.xcelurl}${this.props.logindetails.ClientID}&asOnDate=${this.props.date == "" || this.props.date == null ? moment().format("DD MMM YYYY") : moment(this.props.date).format("DD MMM YYYY")}&accountId=${0}&ProductID=${this.state.productID}&FamilyID=${0}&L5ID=${this.props.L5ID}&L4ID=${0}`
                data = {
                    "ClientID": this.props.logindetails.ClientID, "AccountID": 0, "ReportID": labels.Report.xrayWithRedemption, "WithRedeem": false, "AsOnDate": moment(this.props.date).format("DD MMM YYYY"),
                    "SubReport": 0, "DownloadAs": 2, "ProductID": this.state.productID, "FamilyID": 0, "L5ID": 0, "L4ID": 0
                }
                this.setState({ accexcel: true })
                pdfexcel = true;
                // setaccexcel(true)
            }
        }
        // url = await Networking.PostApi(apiurl.ExportDownloadURL, data).then((res) => {
        //     let a = ""
        //     if (res.status == "S" && res.data != "") {
        //         a = res.data
        //     } else {
        //         this.setState({ Open: true })
        //         if (ext === "pdf") {
        //             this.setState({ accpdf: false })
        //         }
        //         if (ext === "xlsx") {
        //             this.setState({ accexcel: false })
        //         }
        //     }
        //     return a
        // }).catch((er) => {
        //     this.setState({ Open: true })
        //     if (ext === "pdf") {
        //         this.setState({ accpdf: false })
        //     }
        //     if (ext === "xlsx") {
        //         this.setState({ accexcel: false })
        //     }
        //     return ""
        // })
        if (pdfexcel == true && url.length != 0) {
            getAllcommonDownloadDetails(url, ext, filename, this.props.logindetails?.SessionID).then((response) => {
                if (response === "success") {
                    this.setState({ accpdf: false, accexcel: false })
                } else {

                    this.setState({ accpdf: false, accexcel: false, Open: true })
                }
            }).catch(error => {
                this.setState({ Open: true })
                if (ext === "pdf") {
                    this.setState({ accpdf: false })
                }
                if (ext === "xlsx") {
                    this.setState({ accexcel: false })
                }
            });
        } else {
            this.setState({ Open: true })
        }

    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ Open: false })
    };
    // onCheck = () => {
    //     this.props.GetPortfolioReport(this.props.logindetails.ClientID, 0, "", !this.state.checked)
    //     this.setState({ checked: !this.state.checked })
    // }
    render() {
        const { classes } = this.props;
        let combainStyle = Object.assign({}, classes, this.props.externalClasses)
        try {
            return (
                <div>
                    {this.props.isXrayWithRedemptionLoading == true ?
                        // <>
                        <Loader page="skeletonreport" />
                        // </>
                        :
                        <>
                            <CommonSnackbar open={this.state.Open} severity={"warning"} message={labels.messages.apierror} handlesnanckClose={this.handlesnanckClose} />
                            <div style={{ marginBottom: '3%' }}>
                                {/* <div className={this.props.xraywithredemption.length == 0 ? combainStyle.headerMobileResponsive : combainStyle.noclass}> */}
                                <CommonHeaders
                                    showfilter={false}
                                    showdownload={true}
                                    flag={this.props.flag}
                                    //  showcheckbox={true}
                                    header={this.props.header}
                                    List={this.props.xraywithredemption}
                                    data={this.props.xraywithredemption}
                                    handleclear={this.handleclear}
                                    pdfload={this.state.accpdf}
                                    newxcelLoad={this.state.accexcel}
                                    scheme={this.props.xraywithredemption.length == 0 ? <NoData /> : this.props.xraywithredemption.length == 1 ? `${this.props.xraywithredemption.length} ${this.props.header}` : `${this.props.xraywithredemption.length} ${this.props.header}s`}
                                    check={this.state.checked} onclickCheck={this.onCheck}

                                    onclick={this.onclickpdf} page={"redemption"} />
                                {/* </div> */}
                                <ErrorBoundary>
                                    {this.props.xraywithredemptiongrouped.length != 0 ?
                                        <><div className={[combainStyle.FourSplitContainer, combainStyle.mainTitle].join(' ')} style={{ marginTop: "1%" }}>
                                            <div className={combainStyle.desktopResponsive}>
                                            </div>
                                            <div style={{ textAlign: "left", paddingLeft: 10 }}>
                                                {this.props.Balancedunits}
                                            </div>
                                            <div>
                                                {this.props.Redeemedunits}
                                            </div>
                                            <div>
                                                {this.props.total}
                                            </div>
                                        </div>

                                            <div className={[combainStyle.xrayWithRedemptionFirstContainer, combainStyle.desktopResponsive, combainStyle.tableHeader].join(' ')} style={{ paddingLeft: 10 }}>
                                                {
                                                    this.props.title.map((item, titleindex) => {
                                                        return (<div key={titleindex} className={item.name === "Scheme Name" || item.name == "Scrip Name" || item.name == "Bond Name" || item.name == "Instrument Name" ? combainStyle.schemeNameWidth : ''} style={{ display: "flex", flexDirection: "column", textAlign: item.name == "Scheme Name" || item.name == "Scrip Name" || item.name == "Bond Name" || item.name == "Instrument Name" ? "left" : 'right', }}><div>
                                                            {item.name}
                                                        </div>
                                                            {item.subheader != undefined ? <div>{item.subheader}</div> : <div></div>}

                                                            {item.subheader1 != undefined ? <div>{item.subheader1}</div> : <div></div>}
                                                        </div>
                                                        )
                                                    })}
                                            </div>
                                            <div className={[combainStyle.tableHeader, combainStyle.mobileResponsive].join(' ')} style={{ paddingLeft: 10, textAlign: "left" }}>
                                                {this.props.title[0].name}
                                            </div>
                                            <div >
                                                <Pagination data={this.props.xraywithredemption}>
                                                    <MContext>
                                                        {(context) => {
                                                            return <> {context.filter((ele, ind) => ind === context.findIndex(elem => elem.SubAssetClassName === ele.SubAssetClassName)).map((item, mainindex) => {
                                                                return <div key={mainindex}>
                                                                    <div className={[combainStyle.xrayWithRedemptionFirstContainer, combainStyle.categoryGroupContainerXray, mainindex === 0 && combainStyle.borderRadiousFirstIndex].join(' ')} style={{ paddingLeft: 10, fontSize: 16, marginTop: mainindex === 0 ? 0 : "1%" }}>
                                                                        <div className={[combainStyle.firstGrid, "firstGrid", combainStyle.categoryMainHeaderXray].join(' ')}>
                                                                            {item.AssetClassName}: {item.SubAssetClassName}
                                                                        </div>
                                                                        <div className={[combainStyle.secondGrid, "secondGrid"].join(" ")}>
                                                                        </div>
                                                                        <div className={[combainStyle.thirdGrid, "thirdGrid", combainStyle.categoryMainValueXray].join(' ')}>
                                                                            <div>
                                                                                <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                {NegativeFinding(item.AssetSubAsset_BU_InvestedValue, "", "", "", "number")}
                                                                            </div>
                                                                        </div>
                                                                        <div className={[combainStyle.fourthGrid, "fourthGrid", combainStyle.categoryMainValueXray].join(' ')}>
                                                                            <div>
                                                                                <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                {NegativeFinding(item.AssetSubAsset_BU_Currentvalue, "", "", "", "number")}
                                                                            </div>
                                                                            <div style={{ color: NegativeFinding(item.AssetSubAsset_BU_GainLoss) }}>
                                                                                <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                {NegativeFinding(item.AssetSubAsset_BU_GainLoss, "", "", "", "number")}
                                                                            </div>
                                                                        </div>
                                                                        <div className={[combainStyle.fifthGrid, "fifthGrid"].join(" ")}>
                                                                        </div>
                                                                        <div className={[combainStyle.sixthGrid, "sixthGrid", combainStyle.categoryMainValueXray].join(' ')}>
                                                                            <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                            {NegativeFinding(item.AssetSubAsset_RU_InvestedValue, "", "", "", "number")}
                                                                        </div>
                                                                        <div className={[combainStyle.seventhGrid, "seventhGrid", combainStyle.categoryMainValueXray].join(' ')}>
                                                                            <div>
                                                                                <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                {NegativeFinding(item.AssetSubAsset_RU_Currentvalue, "", "", "", "number")}
                                                                            </div>
                                                                            <div style={{ color: NegativeFinding(item.AssetSubAsset_RU_GainLoss) }}>
                                                                                <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                {NegativeFinding(item.AssetSubAsset_RU_GainLoss, "", "", "", "number")}
                                                                            </div>
                                                                        </div>
                                                                        <div className={[combainStyle.eighthGrid, "eighthGrid"].join(" ")}>
                                                                        </div>
                                                                        <div className={[combainStyle.ninthGrid, "ninthGrid", combainStyle.categoryMainValueXray].join(' ')}>
                                                                            <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                            {NegativeFinding(item.AssetSubAsset_InvestedValue, "", "", "", "number")}
                                                                        </div>
                                                                        <div className={[combainStyle.tenthGrid, "tenthGrid", combainStyle.categoryMainValueXray].join(' ')}>
                                                                            <div>
                                                                                <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                {NegativeFinding(item.AssetSubAsset_Currentvalue, "", "", "", "number")}
                                                                            </div>
                                                                            <div style={{ color: NegativeFinding(item.AssetSubAsset_GainLoss) }}>
                                                                                <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                {NegativeFinding(item.AssetSubAsset_GainLoss, "", "", "", "number")}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {context.filter((val) => val.SubAssetClassName === item.SubAssetClassName).map((value, index) => {
                                                                        return (
                                                                            <div key={index} className={[combainStyle.xrayWithRedemptionSecondContainer, combainStyle.subCategoryGroupContainer, index == 0 && combainStyle.borderRadiousFirstIndex].join(' ')} style={{ paddingLeft: 10 }}>
                                                                                <div className={[combainStyle.eleventhGrid, "eleventhGrid", combainStyle.subCatgoryValue].join(" ")}>
                                                                                    {value.SchemeName}
                                                                                </div>
                                                                                <div className={[combainStyle.twelfthGrid, "twelfthGrid", combainStyle.subCatgoryValue].join(' ')}>
                                                                                    <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>Balanced Units</span>

                                                                                    {NegativeFinding(value.BU_Quantity, "", "", "", "number")}
                                                                                </div>
                                                                                <div className={[combainStyle.thirteenthGrid, "thirteenthGrid", combainStyle.subCatgoryValue].join(' ')}>
                                                                                    <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                    {NegativeFinding(value.BU_InvestedValue, "", "", "", "number")}
                                                                                </div>
                                                                                <div className={[combainStyle.fourteenthGrid, "fourteenthGrid", combainStyle.subCatgoryValue].join(' ')}>
                                                                                    <div>
                                                                                        <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                        {NegativeFinding(value.BU_Currentvalue, "", "", "", "number")}
                                                                                    </div>
                                                                                    <div style={{ color: NegativeFinding(value.BU_Gainloss) }}>
                                                                                        <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                        {NegativeFinding(value.BU_Gainloss, "", "", "", "number")}
                                                                                    </div>
                                                                                </div>
                                                                                <div className={[combainStyle.fifteenthGrid, "fifteenthGrid", combainStyle.subCatgoryValue].join(' ')}>
                                                                                    <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>Redeemed Units</span>
                                                                                    {NegativeFinding(value.RU_Quantity, "", "", "", "number")}
                                                                                </div >
                                                                                <div className={[combainStyle.sixteenthGrid, "sixteenthGrid", combainStyle.subCatgoryValue].join(' ')}>
                                                                                    <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                    {NegativeFinding(value.RU_InvestedValue, "", "", "", "number")}
                                                                                </div>
                                                                                <div className={[combainStyle.seventeenthGrid, "seventeenthGrid", combainStyle.subCatgoryValue].join(' ')}>
                                                                                    <div>
                                                                                        <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                        {NegativeFinding(value.RU_SellValue, "", "", "", "number")}
                                                                                    </div>
                                                                                    <div style={{ color: NegativeFinding(value.RU_Gainloss) }}>
                                                                                        <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                        {NegativeFinding(value.RU_Gainloss, "", "", "", "number")}
                                                                                    </div>
                                                                                </div>
                                                                                <div className={[combainStyle.eighteenthGrid, "eighteenthGrid", combainStyle.subCatgoryValue].join(' ')}>
                                                                                    <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>Total     </span>

                                                                                    {NegativeFinding(value.TotalUnits, "", "", "", "number")}
                                                                                </div>
                                                                                <div className={[combainStyle.ninteenthGrid, "ninteenthGrid", combainStyle.subCatgoryValue].join(' ')}>
                                                                                    <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                    {NegativeFinding(value.TotalInvestedValue, "", "", "", "number")}
                                                                                </div>
                                                                                <div className={[combainStyle.twentiethGrid, "twentiethGrid", combainStyle.subCatgoryValue].join(' ')} >
                                                                                    <div>
                                                                                        <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                        {NegativeFinding(value.TotalCurrentvalue, "", "", "", "number")}
                                                                                    </div>
                                                                                    <div style={{ color: NegativeFinding(Number(value.TotalGainLoss)) }}>
                                                                                        {NegativeFinding(value.TotalGainLoss, "", "", "", "number")}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })}
                                                                </div>
                                                            })
                                                            }
                                                                {title != undefined && title.length > 0 && this.props.totalxraywithredemption.map((item, index) => {
                                                                    return (
                                                                        <div key={index} className={[combainStyle.xrayWithRedemptionFirstContainer, combainStyle.totalContainer].join(' ')} >
                                                                            <div className={[combainStyle.firstGrid, "firstGrid"].join(" ")} style={{ paddingLeft: 10 }}>
                                                                                {this.props.grandtotal}
                                                                            </div>
                                                                            <div className={[combainStyle.secondGrid, "secondGrid"].join(" ")}>
                                                                            </div>
                                                                            <div className={[combainStyle.thirdGrid, "thirdGrid"].join(" ")}>
                                                                                <div>
                                                                                    <span className={combainStyle.totalRupee}>{labels.currency.rupeesymbol}</span>
                                                                                    {NegativeFinding(item.Grand_BU_InvestedValue, "", "", "", "number")}
                                                                                </div>
                                                                            </div>
                                                                            <div className={[combainStyle.fourthGrid, "fourthGrid"].join(" ")}>
                                                                                <div>
                                                                                    <span className={combainStyle.totalRupee}>{labels.currency.rupeesymbol}</span>
                                                                                    {NegativeFinding(item.Grand_BU_Currentvalue, "", "", "", "number")}
                                                                                </div>
                                                                                <div style={{ color: NegativeFinding(item.Grand_BU_GainLoss) }}>
                                                                                    <span className={combainStyle.totalRupee}>{labels.currency.rupeesymbol}</span>
                                                                                    {NegativeFinding(item.Grand_BU_GainLoss, "", "", "", "number")}
                                                                                </div>
                                                                            </div>
                                                                            <div className={[combainStyle.fifthGrid, "fifthGrid"].join(" ")}>
                                                                            </div>
                                                                            <div className={[combainStyle.sixthGrid, "sixthGrid"].join(" ")}>
                                                                                <span className={combainStyle.totalRupee}>{labels.currency.rupeesymbol}</span>
                                                                                {NegativeFinding(item.Grand_RU_InvestedValue, "", "", "", "number")}
                                                                            </div>
                                                                            <div className={[combainStyle.seventhGrid, "seventhGrid"].join(" ")}>
                                                                                <div>
                                                                                    <span className={combainStyle.totalRupee}>{labels.currency.rupeesymbol}</span>
                                                                                    {NegativeFinding(item.Grand_RU_Currentvalue, "", "", "", "number")}
                                                                                </div>
                                                                                <div style={{ color: NegativeFinding(item.Grand_RU_GainLoss) }}>
                                                                                    <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                    {NegativeFinding(item.Grand_RU_GainLoss, "", "", "", "number")}
                                                                                </div>
                                                                            </div>
                                                                            <div className={[combainStyle.eighthGrid, "eighthGrid"].join(" ")}>
                                                                            </div>
                                                                            <div className={[combainStyle.ninthGrid, "ninthGrid"].join(" ")}>
                                                                                <span className={combainStyle.totalRupee}>{labels.currency.rupeesymbol}</span>
                                                                                {NegativeFinding(item.Grand_InvestedValue, "", "", "", "number")}
                                                                            </div>
                                                                            <div className={[combainStyle.tenthGrid, "tenthGrid"].join(" ")} >
                                                                                <div>
                                                                                    <span className={combainStyle.totalRupee}>{labels.currency.rupeesymbol}</span>
                                                                                    {NegativeFinding(item.Grand_Currentvalue, "", "", "", "number")}
                                                                                </div>
                                                                                <div style={{ color: NegativeFinding(item.Grand_GainLoss) }}>
                                                                                    <span className={combainStyle.totalRupee}>{labels.currency.rupeesymbol}</span>
                                                                                    {NegativeFinding(item.Grand_GainLoss, "", "", "", "number")}
                                                                                </div>
                                                                                <div style={{ color: NegativeFinding(item.Grand_XIRR) }}>
                                                                                    {/* <span className={combainStyle.totalRupee}>{labels.currency.rupeesymbol}</span> */}
                                                                                    {NegativeFinding(item.Grand_XIRR, "", "", "", "percentage")}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </>
                                                        }}


                                                    </MContext>
                                                </Pagination>
                                                {/* {this.props.xraywithredemptiongrouped.map((item, mainindex) => {
                                                    return (<div key={mainindex}>

                                                        <div className={[combainStyle.xrayWithRedemptionContainer, combainStyle.categoryGroupContainerXray, mainindex === 0 && combainStyle.borderRadiousFirstIndex].join(' ')} style={{ paddingLeft: 10, fontSize: 16, marginTop: mainindex === 0 ? 0 : "1%" }}>
                                                            <div className={[combainStyle.schemeNameWidth, combainStyle.categoryMainHeaderXray].join(' ')}>
                                                                {item.Category}: {item.SubAssetclass}
                                                            </div>
                                                            <div className={combainStyle.otherWidth}>
                                                            </div>
                                                            <div className={[combainStyle.otherWidthAlignment, combainStyle.categoryMainValueXray].join(' ')}>
                                                                <div>
                                                                    <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                    {NegativeFinding(item.AssetSubAsset_BU_InvestedValue, "", "", "", "number")}
                                                                </div>
                                                            </div>
                                                            <div className={[combainStyle.otherWidthAlignment, combainStyle.categoryMainValueXray].join(' ')}>
                                                                <div>
                                                                    <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                    {NegativeFinding(item.AssetSubAsset_BU_Currentvalue, "", "", "", "number")}
                                                                </div>
                                                                <div style={{ color: NegativeFinding(item.AssetSubAsset_BU_GainLoss) }}>
                                                                    <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                    {NegativeFinding(item.AssetSubAsset_BU_GainLoss, "", "", "", "number")}
                                                                </div>
                                                            </div>
                                                            <div className={combainStyle.otherWidth}>
                                                            </div>
                                                            <div className={[combainStyle.otherWidth, combainStyle.categoryMainValueXray].join(' ')}>
                                                                <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                {NegativeFinding(item.AssetSubAsset_RU_InvestedValue, "", "", "", "number")}
                                                            </div>
                                                            <div className={[combainStyle.otherWidthAlignment, combainStyle.categoryMainValueXray].join(' ')}>
                                                                <div>
                                                                    <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                    {NegativeFinding(item.AssetSubAsset_RU_Currentvalue, "", "", "", "number")}
                                                                </div>
                                                                <div style={{ color: NegativeFinding(item.AssetSubAsset_RU_GainLoss) }}>
                                                                    <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                    {NegativeFinding(item.AssetSubAsset_RU_GainLoss, "", "", "", "number")}
                                                                </div>
                                                            </div>
                                                            <div className={combainStyle.otherWidth}>
                                                            </div>
                                                            <div className={[combainStyle.otherWidth, combainStyle.categoryMainValueXray].join(' ')}>
                                                                <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                {NegativeFinding(item.AssetSubAsset_InvestedValue, "", "", "", "number")}
                                                            </div>
                                                            <div className={[combainStyle.otherWidthAlignment, combainStyle.categoryMainValueXray].join(' ')} style={{ paddingRight: 10 }}>
                                                                <div>
                                                                    <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                    {NegativeFinding(item.AssetSubAsset_Currentvalue, "", "", "", "number")}
                                                                </div>
                                                                <div style={{ color: NegativeFinding(item.AssetSubAsset_GainLoss) }}>
                                                                    <span className={combainStyle.categoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                    {NegativeFinding(item.AssetSubAsset_GainLoss, "", "", "", "number")}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {item.data.map((value, index) => {
                                                            return (
                                                                <div key={index} className={[combainStyle.xrayWithRedemptionContainer, combainStyle.subCategoryGroupContainer, index == 0 && combainStyle.borderRadiousFirstIndex].join(' ')} style={{ paddingLeft: 10 }}>
                                                                    <div className={[combainStyle.schemeNameWidth, combainStyle.subCatgoryValue].join(' ')}>
                                                                        {value.SchemeName}
                                                                    </div>
                                                                    <div className={[combainStyle.otherWidth, combainStyle.subCatgoryValue].join(' ')}>
                                                                        {NegativeFinding(value.BU_Quantity, "", "", "", "number")}
                                                                    </div>
                                                                    <div className={[combainStyle.otherWidth, combainStyle.subCatgoryValue].join(' ')}>
                                                                        <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                        {NegativeFinding(value.BU_InvestedValue, "", "", "", "number")}
                                                                    </div>
                                                                    <div className={[combainStyle.otherWidthAlignment, combainStyle.subCatgoryValue].join(' ')}>
                                                                        <div>
                                                                            <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                            {NegativeFinding(value.BU_Currentvalue, "", "", "", "number")}
                                                                        </div>
                                                                        <div style={{ color: NegativeFinding(value.BU_Gainloss) }}>
                                                                            <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                            {NegativeFinding(value.BU_Gainloss, "", "", "", "number")}
                                                                        </div>
                                                                    </div>
                                                                    <div className={[combainStyle.otherWidth, combainStyle.subCatgoryValue].join(' ')}>
                                                                        {NegativeFinding(value.RU_Quantity, "", "", "", "number")}
                                                                    </div >
                                                                    <div className={[combainStyle.otherWidth, combainStyle.subCatgoryValue].join(' ')}>
                                                                        <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                        {NegativeFinding(value.RU_InvestedValue, "", "", "", "number")}
                                                                    </div>
                                                                    <div className={[combainStyle.otherWidthAlignment, combainStyle.subCatgoryValue].join(' ')}>
                                                                        <div>
                                                                            <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                            {NegativeFinding(value.RU_SellValue, "", "", "", "number")}
                                                                        </div>
                                                                        <div style={{ color: NegativeFinding(value.RU_Gainloss) }}>
                                                                            <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                            {NegativeFinding(value.RU_Gainloss, "", "", "", "number")}
                                                                        </div>
                                                                    </div>
                                                                    <div className={[combainStyle.otherWidth, combainStyle.subCatgoryValue].join(' ')}>
                                                                        {NegativeFinding(value.TotalUnits, "", "", "", "number")}
                                                                    </div>
                                                                    <div className={[combainStyle.otherWidth, combainStyle.subCatgoryValue].join(' ')}>
                                                                        <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                        {NegativeFinding(value.TotalInvestedValue, "", "", "", "number")}
                                                                    </div>
                                                                    <div className={[combainStyle.otherWidthAlignment, combainStyle.subCatgoryValue].join(' ')} style={{ paddingRight: 10 }}>
                                                                        <div>
                                                                            <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                            {NegativeFinding(value.TotalCurrentvalue, "", "", "", "number")}
                                                                        </div>
                                                                        <div style={{ color: NegativeFinding(Number(value.TotalGainLoss)) }}>
                                                                            {NegativeFinding(value.TotalGainLoss, "", "", "", "number")}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    )
                                                })} */}

                                            </div>
                                        </>
                                        : <div ></div>}
                                </ErrorBoundary></div>

                        </>
                    }
                </div>
            )
        }
        catch (err) {
            // 
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => {
    return {
        sessionID: state.Login.sessionID,
        clientID: state.Login.logindetails.ClientID,
        userID: state.Login.userID,
        date: state.Login.date,
        L5ID: state.Login.L5ID,
        logindetails: state.Login.logindetails,
        L5ID: state.Login.L5ID
    }
}
const mergedStyle = combineStyles(styles, CommonTableStyle)
export default withStyles(mergedStyle)(connect(mapStateToProps, null)(XrayWithRedemption))
