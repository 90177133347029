import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Button from '../../components/custombutton/button'
import { withRouter } from 'react-router'
import Text from '../../components/text/text'
import color from "../../utils/colors/colors";
import labels from '../../utils/constants/labels'
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import NoData from '../../components/nodata/nodata';
import colors from '../../utils/colors/colors';
import { NegativeFinding } from '../../utils/commonfunction';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { scrollToTopPage } from '../../utils/commonfunction';
const styles = theme => ({
  collabel: {
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: "12px",
    textAlign: "left",
    marginBottom: 10
  },
  schemeText: {
    fontWeight: "bold",
    fontSize: 17,
    color: window.globalConfig.color.black
  },
  folioText: {
    fontSize: 14,
    color: window.globalConfig.color.black,
    fontWeight: 'normal'
  },
  redemption: {
    fontSize: 14,
    width: 170,
    color: window.globalConfig.color.PRIMARY_BLUE
  },
  orderTypeText: {
    fontSize: 14,
    color: window.globalConfig.color.black
  },

  btnApply: {
    // width: "20%",
    marginRight: 26,
    [theme.breakpoints.down("1000")]: {
      width: "40%",
      marginRight: 26
    },

  },
  btnBack: {
    width: "20%", marginLeft: 30,
    [theme.breakpoints.down("1000")]: {
      width: "40%",
      marginLeft: 18
    },
  },
  divMain: {
    display: "flex",
    flexDirection: "row",
    marginTop: "3%",
    marginLeft: "9%",
    marginRight: "3%",
    marginBottom: "4%",
    paddingBottom: "3%"
  },
  divDetail: {
    height: 'auto',
    width: "60%",
    backgroundColor: window.globalConfig.color.WHITE,
    marginLeft: "7%",
    marginBottom: "4%",
    border: `1px solid ${window.globalConfig.color.BORDER}`,
    boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.3)",
    paddingBottom: "30px",

  },
  root: {
    marginLeft: 30,
    marginTop: 20
  },
  content: {
    display: 'block',
    width: "90.5%",
    padding: 10,
    // marginLeft: "4.5%",
    margin: "auto",
    marginTop: "2%",
    backgroundColor: window.globalConfig.color.RosyBrown,
    height: "88%",
    paddingBottom: "5%",
    borderRadius: 5,
    [theme.breakpoints.down("600")]: {
      width: "88.5%"
    }
  },
  redeemFrom: {
    marginLeft: 15,
    float: "left",
    marginTop: "4%"
  },
  bankDetailsGrid: {
    gap: 46,
    marginTop: 10,
    textAlign: "left",
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    [theme.breakpoints.down("600")]: {
      gridTemplateColumns: "repeat(2,1fr)",
    }
  },
  orderTypes: {
    // marginLeft: 15,
    // float: "left",
    // padding:10,
    gap: 10,
    marginTop: 10,
    textAlign: "left",
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    [theme.breakpoints.down("600")]: {
      gridTemplateColumns: "repeat(2,1fr)",
    }
  },
  divLine: {
    height: "1px",
    width: "95%",
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    marginTop: "2.9%",
    marginBottom: "0%",
    marginLeft: 15,
  },
  divLine1: {
    height: "1px",
    width: "100%",
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    marginTop: "4%",
    marginBottom: "-4%",
    // boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.3)",
  },
  bankBranch: {
    //marginTop: "4%",
    marginBottom: "2%",
    // marginLeft: 15,
    color: window.globalConfig.color.PRIMARY_BLUE
  },
  //Added by Celsia on 01 Jun 2021 for Mobile Responsive Started
  desktopResponsive: {
    display: 'block !important',
    [theme.breakpoints.down("600")]: {
      display: 'none !important',
    },
  },
  mobileResponsive: {
    display: 'none !important',
    [theme.breakpoints.down("600")]: {
      display: 'block!important',
    },
  },
  collabelresponsive: {
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: "12px",
    marginBottom: 10
  },
  redeemFromResponsive: {
    float: "left",
    padding: "10px"
  },
  leftResponsive: {
    padding: "10px",
    textAlign: "left"
  },
  rightResponsive: {
    padding: "10px",
    textAlign: "right"
  },
  valueResponsive: {
    fontSize: 12,
    color: window.globalConfig.color.PRIMARY_BLUE
  },
  rootResponsive: {
    marginTop: 20,
    marginLeft: "2%"
  },
  divLineResponsive: {
    height: "0.5px",
    width: "100%",
    backgroundColor: window.globalConfig.color.lightGray,
  },
  radioButton: {
    "& .MuiFormControlLabel-root": {
      display: "flex",
      alignItems: "flex-start"
    },
    "& .MuiIconButton-root": {
      paddingTop: 0
    }
  }
  //Ended
});
let status = ""
class RedemptionReview extends React.Component {
  onBackToConfirmOrder = () => {
    this.props.onConfirmOrder(false);
  }
  componentDidMount() {
    scrollToTopPage();
  }
  render() {
    const { classes } = this.props;
    return (<ErrorBoundary>
      <div style={{ marginTop: 10, marginLeft: "1.4rem" }}>
        <Text label={labels.Redemption.ReviewRedemptionDetails} />

      </div>
      <div className={classes.content}>
        <div style={{ textAlign: "left" }}>
          <div className={classes.collabel}>{labels.Redemption.RedeemFrom}</div>
          <div className={classes.schemeText}>{this.props.data.SchemeName}<span className={classes.folioText}> (Folio: {this.props.folionumber})</span></div>
        </div>
        <div className={classes.orderTypes}>
          <div style={{ textAlign: "left" }}>
            <div className={classes.collabel}>{labels.Redemption.OrderType}</div>
            <div className={classes.orderTypeText}>{labels.Redemption.redemptionName}</div>
          </div>

          <div style={{ textAlign: "left" }}>
            <div className={classes.collabel}>{labels.Redemption.RedemptionType}</div>
            <div className={classes.redemption}>{this.props.redemptiontype}</div>
          </div>
          {this.props.redemptiontype === "Amount (Rs)" &&
            <div style={{ textAlign: "left" }}>
              <div className={classes.collabel}>{labels.Redemption.Amount}</div>
              <div className={classes.orderTypeText}> {this.props.amountValue}</div>
            </div>}
          {this.props.redemptiontype === "Custom No. of Units" &&
            <div style={{ textAlign: "left" }}>
              <div className={classes.collabel}>{labels.Redemption.Units}</div>
              <div className={classes.orderTypeText}>{NegativeFinding(this.props.units, "", "", "", "number")} </div>
            </div>}
          {/* {console.log(this.props.Totalavailableunits,this.props.availableamount)} */}
          {this.props.redemptiontype === "All Available No. of Units" &&
            <div style={{ textAlign: "left" }}>

              <div className={classes.collabelresponsive}>{labels.Redemption.Units}</div>
              <div className={classes.valueResponsive}>{this.props.totalTable[0].TotalAvailableUnits} ({NegativeFinding(this.props.totalTable[0].TotalAvailableAmount, "", "", "", "rupee")})</div>
            </div>}
          {this.props.redemptiontype === "All Exit Load Free Units" &&
            <div style={{ textAlign: "left" }}>
              <div className={classes.collabel}>{labels.Redemption.ExitLoad}</div>
              <div className={classes.orderTypeText}>{NegativeFinding(this.props.totalTable[0].TotalExitLoadUnits, "", "", "", "number")} </div>
            </div>}
          {this.props.redemptiontype === "All Short Term Gain Units" &&
            <div style={{ textAlign: "left" }}>
              <div className={classes.collabel}>{labels.Redemption.ShortTermUnit}</div>
              <div className={classes.orderTypeText}>{NegativeFinding(this.props.totalTable[0].TotalShortTermUnits, "", "", "", "number")} </div>
            </div>}
          {this.props.redemptiontype === "All Long Term Gain Units" &&
            <div style={{ textAlign: "left" }}>
              <div className={classes.collabel}>{labels.Redemption.LongTermUnit}</div>
              <div className={classes.orderTypeText}>{NegativeFinding(this.props.totalTable[0].TotalLongTermUnits, "", "", "", "number")} </div>
            </div>}
          <div style={{ textAlign: "left" }}>
            <div className={classes.collabel}>{labels.STP.UCC}</div>
            <div className={classes.redemption}>{this.props.UCC}</div>
          </div>
        </div>
        <div className={classes.divLine}></div>
        {/* {console.log(this.props.bankdetails)} */}
        {this.props.bankdetails.length != 0 ? this.props.bankdetails.filter((items, index) => items.UCC == this.props.UCC)?.map((item) => {
          return (<div style={{ marginTop: 10 }}>

            <RadioGroup className={classes.radioButton} value={this.props.value} aria-label="bank" name="bank" onChange={this.props.bankChange}>
              <FormControlLabel value={item.BankAccountNumber} control={<Radio style={{ color: window.globalConfig.color.BROWN }} />} label={<>  <div item xs={12} className={classes.bankBranch}>
                <div style={{ textAlign: "left" }}>
                  <div style={{ fontSize: 15, marginBottom: 10, fontWeight: 550 }}>{item.BankName}</div>
                  <div style={{ fontSize: 12 }}>{labels.Redemption.BranchName}:<span> {item.BranchName}</span></div>
                </div>
              </div>

                <div container className={classes.bankDetailsGrid}>
                  {/* <div item xs={4}> */}
                  <div style={{ textAlign: "left" }}>
                    <div className={classes.collabel}> {labels.Redemption.BankAccountType}</div>
                    <div style={{ fontSize: 14 }}>{item.AccountType}</div>
                  </div>
                  {/* </div> */}
                  {/* <div item xs={4}> */}
                  <div style={{ textAlign: "left" }}>
                    <div className={classes.collabel}> {labels.Redemption.AccountNumber}</div>
                    <div style={{ fontSize: 14 }}>{item.BankAccountNumber}</div>
                  </div>
                  {/* </div> */}
                  {/* <div item xs={4}> */}
                  <div style={{ textAlign: "left" }}>
                    <div className={classes.collabel}> {labels.Redemption.IFSCCode}</div>
                    <div style={{ fontSize: 14 }}>{item.IFSCCode}</div>
                  </div>
                  {/* </div> */}
                </div></>} />

            </RadioGroup>
            <div className={classes.divLine}></div>
          </div>)
        }) : <div style={{ marginTop: "5%" }}><NoData /></div>}

      </div>
      <div className={classes.divLine1}></div>
      <div style={{ display: 'flex', marginTop: "10%", justifyContent: "space-between" }}>
        <div className={classes.btnBack}>
          <Button name={"Back"} page={"normal"} disabled={this.props.loader} onClick={() => this.onBackToConfirmOrder()} />
        </div>
        <div className={classes.btnApply} >
          <Button name={"Confirm Order"} page={"primary"} disabled={this.props.bankdetails.length != 0 ? false : true} loader={this.props.loader} onClick={() => this.props.confirmPayment(true)} />
        </div>
      </div>

    </ErrorBoundary>
    );
  }
}
const mapStateToProps = state => {
  return {
    bankdetails: state.ProfileDetails.AllProfileDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.bankDetails != "NA" && state.ProfileDetails.AllProfileDetails.bankDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.bankDetails : [] : []
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(RedemptionReview)))
