import * as types from '../../constants/actionsType'
const initialState = {
  transactionDetailsList:[],
 // isLoadingPerformance:true,
  isTransactionDetailsLoading:false
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.TRANSACTION_DETAILS:
    // 
        return {
          ...state,
          transactionDetailsList: action.payload,
          //isLoadingPerformance:false,
          isTransactionDetailsLoading:false
        }
    case types.EMPTY_TRANSACTION_DETAILS:
            return {
              ...state,
              transactionDetailsList: [],
              // isLoadingPerformance:false,
              // isTransactionDetailsLoading:false
            }
    case types.LOADER_TRANSACTION_DETAILS:
            return {
               ...state,
               isTransactionDetailsLoading:action.payload
            }
    default:
      return state
  }
}
export default reducer
