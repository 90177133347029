import * as types from '../../constants/actionsType';
import getAllCapitalGainDetails from '../../../api/capitalgain/capitalgain'
import constants from '../../../utils/constants/apiconstant';
export function getCapitalGainDetails(ClientID, ProductID, FamilyID, L5ID, L4ID, flag) {
  return (dispatch) => {
    getAllCapitalGainAction(dispatch, ClientID, ProductID, FamilyID, L5ID, L4ID, flag);
  };
}
function getAllCapitalGainAction(dispatch, ClientID, ProductID, FamilyID, L5ID, L4ID, flag) {
  if (ProductID === 1) {
    dispatch({
      type: types.EMPTY_CAPITAL_GAIN,
      payload: [],
    });
    dispatch({
      type: types.LOADER_CAPITAL_GAIN,
      payload: true,
    });
  }else if(ProductID==2){
    dispatch({
      type: types.EMPTY_EQUITY_CAPITAL_GAIN,
      payload: [],
    });
    dispatch({
      type: types.LOADER_EQUITY_CAPITAL_GAIN,
      payload: true,
    });
  }
  else if (ProductID === 3){
    dispatch({
      type: types.EMPTY_BONDS_TAXATION,
      payload: [],
    });
    dispatch({
      type: types.LOADER_BONDS_TAXATION,
      payload: true,
    });
  } else if (ProductID === 4){
    dispatch({
      type: types.EMPTY_GB_TAXATION,
      payload: [],
    });
    dispatch({
      type: types.LOADER_GB_TAXATION,
      payload: true,
    });
  }else{
    
      dispatch({
        type: types.EMPTY_FD_TAXATION,
        payload: [],
      });
      dispatch({
        type: types.LOADER_FD_TAXATION,
        payload:true,
      });
  
  }
  getAllCapitalGainDetails(ClientID, ProductID, FamilyID, L5ID, L4ID)
    .then((data) => {
      // 
      if (ProductID === 1) {
       
        dispatch({
          type: types.LOADER_CAPITAL_GAIN,
          payload:false,
        });
      }else if(ProductID==2){
       
        dispatch({
          type: types.LOADER_EQUITY_CAPITAL_GAIN,
          payload: false,
        });
      }
      else if (ProductID === 3){
       
        dispatch({
          type: types.LOADER_BONDS_TAXATION,
          payload: false,
        });
      } 
      if(data.status=="S"){
      dispatch({
        type: types.PREVENT_API_CALL,
        payload:[{ProductID:ProductID,api:"CapitalGain"}],
    });
      if (ProductID ===1) {
        dispatch({
          type: types.CAPITAL_GAIN,
          payload: data,
        });
        
      } else  if (ProductID ===2){
        dispatch({
          type: types.EQUITY_CAPITAL_GAIN,
          payload: data,
        });
      }
      else  if (ProductID ===3){
        dispatch({
          type: types.BONDS_TAXATION,
          payload: data,
        });
      //   dispatch({
      //     type: types.PREVENT_API_CALL,
      //     payload:"EquityTaxationReport",
      // });
      }
      else  if (ProductID ===4){
        dispatch({
          type: types.GB_TAXATION,
          payload: data,
        });
      //   dispatch({
      //     type: types.PREVENT_API_CALL,
      //     payload:"EquityTaxationReport",
      // });
      }
      else {
        dispatch({
          type: types.FD_TAXATION,
          payload: data,
        });
      //   dispatch({
      //     type: types.PREVENT_API_CALL,
      //     payload:"EquityTaxationReport",
      // });
      }}else{
        if (ProductID ===1) {
          dispatch({
            type: types.CAPITAL_GAIN,
            payload: [],
          });
          
        } else  if (ProductID ===2){
          dispatch({
            type: types.EQUITY_CAPITAL_GAIN,
            payload: [],
          });
        }
        else  if (ProductID ===3){
          dispatch({
            type: types.BONDS_TAXATION,
            payload: [],
          });
        //   dispatch({
        //     type: types.PREVENT_API_CALL,
        //     payload:"EquityTaxationReport",
        // });
        }
        else  if (ProductID ===4){
          dispatch({
            type: types.GB_TAXATION,
            payload: [],
          });
        //   dispatch({
        //     type: types.PREVENT_API_CALL,
        //     payload:"EquityTaxationReport",
        // });
        }
        else {
          dispatch({
            type: types.FD_TAXATION,
            payload: [],
          });
        //   dispatch({
        //     type: types.PREVENT_API_CALL,
        //     payload:"EquityTaxationReport",
        // });
        }
      }
      //   if (data != undefined) {
      //     if (data.status == "S") {

      //     } else {

      //     }
      //   }
    })
    .catch((error) => {
      dispatch({
        type: types.PREVENT_API_CALL,
        payload:[{ProductID:ProductID,api:"CapitalGain"}],
    });

      if (ProductID ===1) {
        dispatch({
          type: types.EMPTY_CAPITAL_GAIN,
          payload: [],
        });
      } else if (ProductID ===2) {
        dispatch({
          type: types.EMPTY_EQUITY_CAPITAL_GAIN,
          payload: [],
        });
      }
      else if (ProductID ===3) {
        dispatch({
          type: types.EMPTY_BONDS_TAXATION,
          payload: [],
        });
          }
      else if (ProductID ===4) {
        dispatch({
          type: types.EMPTY_GB_TAXATION,
          payload: [],
        });
       
      }
      else {
        dispatch({
          type: types.EMPTY_FD_TAXATION,
          payload: [],
        });
      }
    })
}