import { Grid, Typography, FormControlLabel, } from '@material-ui/core';
import React from 'react'
import Button from '../../components/custombutton/button'
import { withStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import { NegativeFinding, scrollToTopPage } from '../../utils/commonfunction';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Textinput from '../textinput/textinput';
import Dropdown from '../dropdown/dropdownschemes';
import Addbankpopup from '../popup/Addbankpopup';
import ErrorBoundary from '../Errorhandling/Errorhandle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Three_State_Toggle_Switch from '../switchbutton/three_state_toggle_switch';
import CommonCheckbox from '../checkbox/checkbox';
const styles = theme => ({
    root: {
        width: 360,
        marginLeft: '1vh',
        marginTop: '-2.5vh'
    },

    labelSize: {
        fontSize: 14
    },
    header: {
        fontSize: 18,
        fontWeight: 'bold',
        color: window.globalConfig.color.BLACK_PRIMARY,
    },
    amount: {
        fontSize: 15
    }
})
class Payment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            radio: "add",
            radio1: '',
            onLineVisible: true,
            offLineVisible: false,
            yesVisible: true,
            noVisible: false,
            Amount: '',
            modeOfPayment: "",
            planName: '',
            planCode: '',
            openbank: false,
            value: "currentaddress"
        }
    }

    componentDidMount() {
        scrollToTopPage();
        this.toggleChange("Online")
        if (this.props.location.state.data != undefined && this.props.location.state.data != null && this.props.location.state.data != "") {
            this.setState({
                planName: this.props.location.state.data.Data[0].plan_name,
                planCode: this.props.location.state.data.Data[0].plan_code,
                Amount: this.props.location.state.data.Data[0].recurring_price
            })
            this.handleChange({ target: { value: "currentaddress" } })
        }
    }

    // closeRedirection = () => {
    //     this.props.history.push({
    //         pathname: labels.Path.UserDetails,
    //     })
    // }

    handleChange = (event) => {
        let address;

        this.setState({ value: event.target.value });
        if (event.target.value == "currentaddress") {
            address = this.props.mainDetails.currentAddress
        } else {
            address = this.props.mainDetails.correspondenceAddress
        }
        this.props.callBack(address)
        // if (event.target.value != undefined) {
        //     this.props.radioButton('value')
        // }
    };


    handleChange1 = (event) => {
        this.setState({ radio1: event.target.value });

    };
    // handleChangeCorrespondenceAddress = () => {
    //     this.setState({ correspondenceAddress: event.target.value });
    // }

    onLineBtn = () => {
        this.setState({
            onLineVisible: true,
            offLineVisible: false
        })
        this.props.onLineShow('true onLine')
    }

    offLineBtn = () => {
        this.setState({
            offLineVisible: true,
            onLineVisible: false,
        })
        this.props.offLineShow('true OffLine')
    }



    yesButton = () => {
        this.setState({
            yesVisible: true,
            noVisible: false
        })
        this.props.yesButton('true')
    }
    noButton = () => {
        this.setState({
            yesVisible: false,
            noVisible: true
        })
        this.props.noButton('true')
    }
    toggleChange = (val) => {
        this.setState({ modeOfPayment: val })
        this.props.onLineShow(val)
    }
    handleCheckBox = () => {
        this.setState({ yesVisible: !this.state.yesVisible })
        this.props.yesButton(!this.state.yesVisible)
    }
    render() {
        const { classes } = this.props;
        // 
        try {
            return (
                <ErrorBoundary>
                    <div>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <h1 className={classes.header}>{labels.advisory.payment}</h1>
                            <Three_State_Toggle_Switch
                                values={['Online', 'Offline']}
                                handleChangeToggle={(val) => { this.toggleChange(val) }}
                                selectedFlag={this.state.modeOfPayment}
                            ></Three_State_Toggle_Switch>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column", gap: 30, opacity: this.state.modeOfPayment == "Online" ? 1 : 0.5 }}>
                            <div style={{ display: "grid", gridTemplateColumns: "32% 2% 60%", gap: 20, alignItems: "baseline", justifyItems: "baseline" }}>
                                <Typography className={classes.amount}>{labels.advisory.amountoBePaid}</Typography>
                                <span>:</span>
                                <Typography className={classes.amount} style={{ textAlign: "left" }}>{NegativeFinding(this.state.Amount, "", "", "", "rupee")} {labels.advisory.includingGST}</Typography>
                            </div>

                            <div style={{ display: "grid", gridTemplateColumns: "32% 2% 60%", gap: 20, alignItems: "center", justifyItems: "baseline" }}>
                                <Typography className={classes.labelSize}>{"Bank"}</Typography>
                                <span>:</span>
                                <div style={{ display: "grid", gridTemplateColumns: "55% 45%", alignItems: "center", width: "-webkit-fill-available" }}>

                                    <Dropdown isSearchable={true}
                                        searchPlaceholder={labels.advisory.searchBankName}
                                        type={"normalwithsearch"}
                                        autoselect={true}
                                        handleOnChange={this.props.handleBankChange}
                                        List={this.props.List}
                                        placeholder={labels.bankDetails.SelectBankName}
                                        error={this.props.errorDropdown}
                                    />

                                    <div style={{ color: colors.BLACK_PRIMARY, textDecoration: "underline", cursor: "pointer", fontSize: 15 }} onClick={() => { this.setState({ openbank: true }) }} >{labels.advisory.addNewBankAccount}</div>
                                </div>
                            </div>

                            {this.props.mainDetails.currentAddress != null && this.props.mainDetails.currentAddress != undefined && this.props.mainDetails.currentAddress != "" &&
                                <div style={{ display: "grid", gridTemplateColumns: "32% 2% 60%", gap: 20, alignItems: "baseline", justifyItems: "baseline" }}>

                                    <div xs={3} className={classes.labelSize}>{labels.advisory.billingAddress}</div>
                                    <span>:</span>
                                    <div xs={12} style={{ display: "flex", width: "-webkit-fill-available" }}>
                                        <RadioGroup
                                            aria-label="Gender"
                                            name="gender1"
                                            className={classes.group}
                                            value={this.state.value}
                                            onChange={this.handleChange}
                                        > <>{this.props.mainDetails.currentAddress != null && this.props.mainDetails.currentAddress != undefined && this.props.mainDetails.currentAddress != "" &&
                                            <FormControlLabel value="currentaddress" control={<Radio style={{ color: window.globalConfig.color.BROWN }} />} label={<label for="vehicle1">
                                                <div style={{}}>
                                                    <div className={classes.labelSize}>{this.props.mainDetails.currentAddress[0].AddressLine1}</div>
                                                    <div className={classes.labelSize}>{this.props.mainDetails.currentAddress[0].AddressLine2}</div>
                                                    <div className={classes.labelSize}>{this.props.mainDetails.currentAddress[0].City}{this.props.mainDetails.currentAddress[0].state}{this.props.mainDetails.currentAddress[0].country}-{this.props.mainDetails.currentAddress[0].Pincode}</div>
                                                </div>
                                            </label>} />}
                                                <div style={{ marginTop: '2vh' }}></div>
                                                {this.props.mainDetails.correspondenceAddress != null && this.props.mainDetails.correspondenceAddress != undefined && this.props.mainDetails.correspondenceAddress != "" &&

                                                    <FormControlLabel value="correspondance" control={<Radio style={{ color: window.globalConfig.color.BROWN }} />} label={<label for="vehicle1">
                                                        <div>
                                                            <div className={classes.labelSize}>{this.props.mainDetails.correspondenceAddress[0].AddressLine1}</div>
                                                            <div className={classes.labelSize}>{this.props.mainDetails.correspondenceAddress[0].AddressLine2}</div>
                                                            <div className={classes.labelSize}>{this.props.mainDetails.correspondenceAddress[0].City}{this.props.mainDetails.correspondenceAddress[0].state}{this.props.mainDetails.correspondenceAddress[0].country}-{this.props.mainDetails.correspondenceAddress[0].Pincode}</div>
                                                        </div>
                                                    </label>} />}
                                            </>
                                        </RadioGroup>

                                    </div>

                                </div>}
                            <div style={{ display: "grid", gridTemplateColumns: "32% 2% 60%", gap: 20, alignItems: "baseline", justifyItems: "baseline" }}>
                                <Typography className={classes.labelSize}>{labels.advisory.doYouHaveGST}</Typography>
                                <span>:</span>
                                <div style={{ width: "-webkit-fill-available" }}>
                                    <CommonCheckbox checked={this.state.yesVisible} onChange={this.handleCheckBox}></CommonCheckbox>
                                    <Textinput disabled={!this.state.yesVisible} page={"stp"} handleChange={this.props.gstChange} placeholder={labels.advisory.enterGSTNumber} value={this.props.values} error={this.props.errorGstMessage} />
                                </div>
                            </div>
                        </div>
                        <Addbankpopup
                            open={this.state.openbank}
                            onClose={() => { this.setState({ openbank: false }) }} />
                        {/* <Grid container xs={12} style={{ textAlign: "left", width: "88%" }}>
                            <Grid xs={12}>
                                <h1 className={classes.header}>{labels.advisory.payment}</h1>
                            </Grid>
                            <Grid xs={12} style={{ paddingTop: 5 }}>
                                <Typography className={classes.amount}>{labels.advisory.amountoBePaid}{this.state.Amount} {labels.advisory.includingGST}</Typography>
                            </Grid>
                            <Grid xs={12} container style={{ paddingTop: "5vh" }}>
                                <Grid xs={12} style={{ display: "flex" }}>
                                    <Typography className={classes.labelSize}>{labels.advisory.modeOfPayment}</Typography>
                                 
                                    {this.state.onLineVisible == true ?
                                        <span style={{ marginLeft: "3vw", width: 100, marginTop: '-1vh' }}><Button onClick={this.onLineBtn} page={"primary"} name={labels.advisory.online} /></span>
                                        :
                                        <span style={{ marginLeft: "3vw", width: 100, marginTop: '-1vh' }}><Button onClick={this.onLineBtn} page={"secondary"} name={labels.advisory.online} /></span>
                                    }
                                   
                                    {this.state.offLineVisible == false ?
                                        <span style={{ marginLeft: "3vw", width: 100, height: 30, marginTop: '-1vh' }}><Button onClick={this.offLineBtn} page={"secondary"} name={labels.advisory.offline} /></span>
                                        :
                                        <span style={{ marginLeft: "3vw", width: 100, height: 30, marginTop: '-1vh' }}><Button onClick={this.offLineBtn} page={"primary"} name={labels.advisory.offline} /></span>
                                    }
                                </Grid>
                            </Grid>
                            {this.state.onLineVisible == true &&
                                <>
                                    <Grid container xs={12} style={{ paddingTop: "5vh" }}>
                                        <Grid xs={12} style={{ display: "flex" }}>
                                            <Typography className={classes.labelSize}>{labels.advisory.selectBank}</Typography>
                                            <div style={{ width: 303, marginLeft: '2vh', marginTop: '-2.5vh' }}>
                                                <Dropdown isSearchable={true}
                                                    searchPlaceholder={labels.advisory.searchBankName}
                                                    type={"normalwithsearch"}
                                                    autoselect={true}
                                                    handleOnChange={this.props.handleBankChange}
                                                    List={this.props.List}
                                                    placeholder={labels.bankDetails.SelectBankName}
                                                    error={this.props.errorDropdown}
                                                />
                                            </div>
                                            <div style={{ marginLeft: "3vw", color: colors.BLACK_PRIMARY, textDecoration: "underline", cursor: "pointer", fontSize: 15 }} onClick={() => { this.setState({ openbank: true }) }} >{labels.advisory.addNewBankAccount}</div>
                                            <Addbankpopup
                                                open={this.state.openbank}
                                                onClose={() => { this.setState({ openbank: false }) }} />
                                        </Grid>
                                    </Grid>

                                    <Grid container xs={12} style={{ paddingTop: "5vh" }}>
                                        <Grid xs={12} style={{ display: "flex" }}>
                                            <Typography className={classes.labelSize}>{labels.advisory.doYouHaveGST}</Typography>
                                            {this.state.yesVisible == true ?
                                                <span style={{ marginLeft: "3vw", width: 100, marginTop: '-1vh' }}><Button onClick={this.yesButton} page={"primary"} name={labels.advisory.yes} /></span>
                                                :
                                                <span style={{ marginLeft: "3vw", width: 100, marginTop: '-1vh' }}><Button onClick={this.yesButton} page={"secondary"} name={labels.advisory.yes} /></span>
                                            }
                                            {this.state.noVisible == false ?
                                                <span style={{ marginLeft: "3vw", width: 100, marginTop: '-1vh' }}><Button onClick={this.noButton} page={"secondary"} disabled={false} name={labels.advisory.no} /></span>
                                                :
                                                <span style={{ marginLeft: "3vw", width: 100, marginTop: '-1vh' }}><Button onClick={this.noButton} page={"primary"} disabled={false} name={labels.advisory.no} /></span>
                                            }
                                        </Grid>
                                    </Grid>
                                    {this.state.yesVisible == true &&
                                        <Grid container xs={12} style={{ paddingTop: "5vh" }}>
                                            <Typography className={classes.labelSize}>{labels.advisory.gstNumber}</Typography>
                                            <div className={classes.root}>
                                                <Textinput page={"stp"} handleChange={this.props.gstChange} placeholder={labels.advisory.enterGSTNumber} value={this.props.values} error={this.props.errorGstMessage} />
                                            </div>
                                        </Grid>
                                    }
                                    {this.props.mainDetails.currentAddress != null && this.props.mainDetails.currentAddress != undefined && this.props.mainDetails.currentAddress != "" &&
                                        <Grid container xs={12} style={{ paddingTop: "5vh" }}>
                                            <Grid xs={12} style={{ display: "flex" }}>
                                                <Grid xs={3} className={classes.labelSize}>{labels.advisory.billingAddress}</Grid>

                                                <RadioGroup
                                                    aria-label="Gender"
                                                    name="gender1"
                                                    className={classes.group}
                                                    value={this.state.value}
                                                    onChange={this.handleChange}
                                                > <>{this.props.mainDetails.currentAddress != null && this.props.mainDetails.currentAddress != undefined && this.props.mainDetails.currentAddress != "" &&
                                                    <FormControlLabel value="currentaddress" control={<Radio style={{ color: window.globalConfig.color.BROWN }} />} label={<label for="vehicle1">
                                                        <div>
                                                            <div className={classes.labelSize}>{this.props.mainDetails.currentAddress[0].AddressLine1}</div>
                                                            <div className={classes.labelSize}>{this.props.mainDetails.currentAddress[0].AddressLine2}</div>
                                                            <div className={classes.labelSize}>{this.props.mainDetails.currentAddress[0].City}{this.props.mainDetails.currentAddress[0].state}{this.props.mainDetails.currentAddress[0].country}-{this.props.mainDetails.currentAddress[0].Pincode}</div>
                                                        </div>
                                                    </label>} />}
                                                        <div style={{ marginTop: '2vh' }}></div>
                                                        {this.props.mainDetails.correspondenceAddress != null && this.props.mainDetails.correspondenceAddress != undefined && this.props.mainDetails.correspondenceAddress != "" &&

                                                            <FormControlLabel value="correspondance" control={<Radio style={{ color: window.globalConfig.color.BROWN }} />} label={<label for="vehicle1">
                                                                <div>
                                                                    <div className={classes.labelSize}>{this.props.mainDetails.correspondenceAddress[0].AddressLine1}</div>
                                                                    <div className={classes.labelSize}>{this.props.mainDetails.correspondenceAddress[0].AddressLine2}</div>
                                                                    <div className={classes.labelSize}>{this.props.mainDetails.correspondenceAddress[0].City}{this.props.mainDetails.correspondenceAddress[0].state}{this.props.mainDetails.correspondenceAddress[0].country}-{this.props.mainDetails.correspondenceAddress[0].Pincode}</div>
                                                                </div>
                                                            </label>} />}
                                                    </>
                                                </RadioGroup>

                                            </Grid>

                                        </Grid>}
                                    <Grid container xs={12} style={{ paddingTop: "5vh" }}>

                                    </Grid>

                                </>
                            }


                        </Grid> */}

                    </div>
                </ErrorBoundary>
            )
        }
        catch (err) {
            console.log(err)
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => ({
    RiaFlowOnlinePayment: state.RiaOnLinePayment.riaFlowOnLinePaymentOrder != null ? state.RiaOnLinePayment.riaFlowOnLinePaymentOrder.length != 0 ? state.RiaOnLinePayment.riaFlowOnLinePaymentOrder : [] : [],
    mainDetails: state.KYCDetails.KycDatas != null ? state.KYCDetails.KycDatas.length != 0 ? state.KYCDetails.KycDatas : [] : [],
})
const mapDispatchToProps = dispatch => {

}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Payment)))