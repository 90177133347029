import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Labels from '../../utils/constants/labels';
import Divider from '@material-ui/core/Divider';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import Dropdown from '../../components/dropdown/dropdownschemes';
import Grid from '@material-ui/core/Grid';
import '../../utils/stylesheets/investment/investment.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import color from "../../utils/colors/colors";
import { Redirect, withRouter } from "react-router-dom";
import labels from '../../utils/constants/labels';
import secureStorage from '../../utils/securestorage'
import CommonCheckbox from '../../components/checkbox/checkbox';
import { NegativeFinding, returnDateList } from '../../utils/commonfunction';
import colors from '../../utils/colors/colors';
import Button from '../custombutton/button';
import Textinput from '../textinput/textinput';
import Epfreport from '../../containers/epf/epfreport';
const styles = (theme) => ({

  inputRoot: {
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontSize: 12,
    // width: 200,
    height: 45,
    width: "-webkit-fill-available",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: window.globalConfig.color.LinkWater
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: window.globalConfig.color.BROWN
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "rgb(175,119,45)",
      outline: "none",
    },
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 0
    }
    // [theme.breakpoints.between('300', '350')]: {

    //   width: "260px !important"
    // },
    // [theme.breakpoints.between('350', '400')]: {

    //   width: "300px !important"
    // },
    // [theme.breakpoints.between('400', '900')]: {

    //   width: "350px !important"
    // },
    // [theme.breakpoints.between('1000', '1200')]: {

    //   width: "170px !important"
    // },
    // [theme.breakpoints.between('1500', '2000')]: {

    //   width: "230px !important"
    // }

  },

  bodyPadding: {
    // padding: 25
  },

  leftBigHeading: {
    fontWeight: "bold",
    fontSize: 20,
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      width: "250px"
    }
    // marginleft : 0
    // paddingLeft: 6
  },

  change: {
    fontSize: 12,
    cursor: 'pointer',
    color: window.globalConfig.color.PRIMARY,
    paddingLeft: 10
  },

  leftSmallHeading: {
    display: "flex",
    paddingTop: 20,
    flexDirection: "row",
    alignItems: "self-start",
    gap: 10,
    [theme.breakpoints.down('920')]: {
      flexDirection: "column",
    }
  },
  bodyRightHeading: {
    fontWeight: "bold",
    fontSize: 20,
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      textAlign: "Left",
      marginTop: 10
    }
  },

  investmentColumn: {
    display: "inline-block",
    // float: 'right'
    flexDirection: "column",
    width: '100%',
    [theme.breakpoints.up("2560")]: {
      marginRight: '12.3em',
    }
  },
  investmentColumncheck: {
    display: "flex",
    alignItems: "self-start",
    flexDirection: "column",
    width: '100%',
    // [theme.breakpoints.down("600px")]: {
    //   width: 'calc(100% - 35%)',
    // },
    [theme.breakpoints.down("sm")]: {
      width: 'calc(100% - 2%)',
    },
    [theme.breakpoints.up("2560")]: {
      marginRight: '12.3em',
    }
  },

  investmentLabelSpace: {
    // paddingTop: 10,
    [theme.breakpoints.down("sm")]: {
      marginRight: '0',
      textAlign: 'left',
    },
    marginRight: '2.7em',

    // [theme.breakpoints.between("1000", "1050")]: {
    //   marginRight: '40.7em',
    // },
  },

  checkBox: {
    // marginLeft: '-4%',
    // marginTop: "31px",
    // padding: "10px 0px 10px 0px",
    // float: 'left',
    // [theme.breakpoints.down("320px")]: {
    //   marginLeft: 0,
    // },
    // [theme.breakpoints.up("1050")]: {
    //   fontSize: 14,
    //   float: "none",
    //   marginLeft: "20px"
    // },
    // [theme.breakpoints.between("1000", "1050")]: {
    //   fontSize: 12,
    //   float: "none",
    //   marginLeft: "25px !important",
    //   width: 200
    // },
    // [theme.breakpoints.between("1250", "1300")]: {
    //   fontSize: 13,
    //   width: "101%"
    // },
    // [theme.breakpoints.up("1350")]: {
    //   fontSize: 14,
    //   float: "none",
    //   marginLeft: "19px !important",
    //   width: "19vw"
    // },
  },
  threeYearReturn: {
    fontWeight: 'bold', fontSize: 28, textAlign: 'left'
  },

  btnSelected: {
    height: 45, width: 150,
    background: window.globalConfig.color.BROWN,
    borderWidth: 0,
    fontWeight: 500,
    color: window.globalConfig.color.WHITE,
    '&:hover': {
      background: window.globalConfig.color.PRIMARY_LIGHT,
      color: window.globalConfig.color.WHITE,
    },
    '&:focus': {
      border: "1px solid #fffbf3 !important",
      outline: "none",
    },
    [theme.breakpoints.down('350')]: {
      width: 100,
      marginRight: "160px !important"
    },
    [theme.breakpoints.between('350', '400')]: {
      width: 100,
      marginRight: "200px !important"
    },
    [theme.breakpoints.between('400', '500')]: {
      width: 100,
      marginRight: "240px !important"
    },
  },

  investmentLabel: {
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: 13,
    textAlign: "left",
    paddingBottom: 5,
    // paddingTop: 5
  },

  divider: {
    height: 15,
    marginTop: 3,
    size: 2,
    [theme.breakpoints.down('920')]: {
      display: "none"
    }
  },

  smallDetailLeft: {
    fontSize: 14,
    padding: "0px 6px 6px 6px",
    color: window.globalConfig.color.LABLE_GRAY,
    [theme.breakpoints.between('1250', '1300')]: {
      padding: "0px 3px 6px 2px",
    }
  },
  subHeader: {
    fontSize: 14,
    color: window.globalConfig.color.LABLE_GRAY,
  },
  subContent: {
    fontSize: 14,
    color: window.globalConfig.color.BLACK_PRIMARY,
    textAlign: "left",
    fontWeight: "normal"
  },
  subContentDate: {
    fontSize: 14,
    color: window.globalConfig.color.BLACK_PRIMARY,
  },
  subContentLeft: {
    flex: "30%",
    textAlign: "left !important",
  },
  subContentRight: {
    flex: "80%",
    textAlign: "right !important",
  },
  ReturnValue: {
    [theme.breakpoints.down('sm')]: {
      fontSize: 16,
      fontWeight: "bold",
      marginTop: "10px"
    }
  },
  DivideLine: {
    [theme.breakpoints.down('sm')]: {
      border: "none",
      height: "1px",
      margin: 0,
      backgroundColor: window.globalConfig.color.BLACK_PRIMARY,
      marginTop: "10px"
    }
  },
  smallDetailRight: {
    fontSize: 14,
    padding: "0px 10px 6px 0px",
    color: window.globalConfig.color.BLACK_PRIMARY,
    textAlign: "left",
    fontWeight: "normal",
    [theme.breakpoints.between('1250', '1300')]: {
      padding: "0px 5px 6px 0px",
    }
  },
  // textField: {
  //   border: '1px pink '
  // },

  textBoxMonthly: {
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    color: window.globalConfig.color.LABLE_GRAY,
    borderWidth: 0,
    fontSize: 12,
    padding: 15,
    marginLeft: "20%", //Changed by Celsia
    // fontFamily: "Roboto",
    fontWeight: "500",
    [theme.breakpoints.down('sm')]: {
      marginLeft: "0",
    },
  },

  btnYes: {
    height: 30, width: 60,
    background: window.globalConfig.color.BROWN,
    borderWidth: 0,
    borderRadius: 3,
    fontWeight: 500,
    textAlign: 'center',
    marginLeft: 10
  },
  InvestmentBlock: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    [theme.breakpoints.down('920')]: {
      gridTemplateColumns: "100%",
    }
  },
  investmentSecondGrid: {
    [theme.breakpoints.up('920')]: {
      borderLeft: `1px solid ${window.globalConfig.color.BORDER}`, textAlign: 'left', paddingLeft: 10
    },
    [theme.breakpoints.down('920')]: {
      marginTop: 10,
      borderTop: `1px solid ${window.globalConfig.color.BORDER}`, textAlign: 'left',
      paddingBottom: 10
    }
  },
  InvestmentResponsive: {
    display: "none !important",
    [theme.breakpoints.down('sm')]: {
      display: "block !important",
    }
  },
  SubHeaderBlock: {
    display: "flex",
  },
  SubHeaderLeft: {
    flex: "50%",
    textAlign: 'left !important'
  },
  SubHeaderRight: {
    flex: "50%",
    textAlign: 'right !important',
    float: 'right !important'
  },
  investmentContainer: {
    // padding: '20px 0px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 10
    },

  },
  sideMainDiv: {
    paddingLeft: 20,
    paddingBottom: 50
    // [theme.breakpoints.up("1024")]:{
    //   paddingLeft:100
    // }
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "45% 55%",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "100%",
    }
  },
  investmentGrid: {
    display: "grid", gridTemplateColumns: "repeat(2,1fr)", alignItems: "center", gap: 25,
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "100%",
    }
  },
  firstGrid: {
    paddingLeft: 18, paddingTop: 18, display: "flex", flexDirection: "column", gap: 10,
    borderRight: `1px solid ${colors.BORDER}`,
    [theme.breakpoints.down("920")]: {
      borderBottom: `1px solid ${colors.BORDER}`,

    }
  },
  secondGrid: {
    paddingTop: 10, paddingLeft: 17, paddingRight: 17, paddingBottom: 20, display: "flex", flexDirection: "column", gap: 10,

  },
  oneTimeGridContainer: {
    display: "grid", gridTemplateColumns: "45%",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "100%",
    }
  },
  amountDetails: {
    display: "grid", gridTemplateColumns: "67% 8% 25%",
  }

});
class Invesment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orientation: "",
      data: []
    }
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    if (this.props.data.page == "basket" && this.props.data.multipleScheme.length != 0) {
      this.setState({
        data: this.props.data.multipleScheme.map((item) => {
          return {
            ...item,
            dataOne: item.SchemeName, dataTwo: NegativeFinding(item.Weightage, "", "", "", "percentage"), dataThree: NegativeFinding(item.Amount, "", "", "", "rupee")
          }
        })
      })
    }

  }
  componentDidUpdate(prev) {
    const { data } = this.props
    if (prev.data != data && data.page == "basket") {
      this.setState({
        data: data.multipleScheme.map((item) => {
          return {
            ...item,
            dataOne: item.SchemeName, dataTwo: NegativeFinding(item.Weightage, "", "", "", "percentage"), dataThree: NegativeFinding(item.Amount, "", "", "", "rupee")
          }
        })
      })
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this))
  }
  resize() {

    if (window.innerWidth < 920) {
      // 
      this.setState({ orientation: "horizontal" });
    }
    else {
      this.setState({ orientation: "vertical" });
    }
  }
  findDate = (val) => {
    // console.log(val)
    let a = Number(moment().format("DD"))
    // console.log(a)
    let arr = [...Array(6)].map((item, i) => {
      return a + i
    })
    // console.log(arr)
    // console.log(arr.includes(Number(val)))
    return arr.includes(Number(val))

  }
  render() {
    const { classes } = this.props;
    try {
      return (<>
        {this.props.page == "basket" ? <div style={{ padding: 15 }}>
          <div className={classes.leftBigHeading} >
            {this.props.data.basketName}
          </div>
          <Epfreport showHeader={false} data={this.state.data} grid={classes.amountDetails} title={[{ "header": "Scheme Name", "subHeader": "", align: "left" }, { header: "Weightage", align: "right", subHeader: "" }, { header: "Amount", align: "right", subHeader: "" },]}></Epfreport>
        </div> : <div className={classes.gridContainer}>
          <div className={classes.firstGrid}>
            <div className={classes.leftBigHeading} >
              {this.props.data.SchemeName}
              <u className={classes.change} onClick={this.props.handleChange}>{Labels.bigPopup.change}</u>
            </div>
            <div style={{ display: "flex", flexDirection: "row" }}>

              <div>
                <span className={classes.smallDetailLeft} style={{ paddingLeft: 0 }}>{Labels.bigPopup.category} :</span>
                <span className={classes.smallDetailRight}> {this.props.data.AssetClass}</span>
              </div>
              <Divider orientation="vertical" flexItem className={classes.divider} />
              <div>
                <span className={classes.smallDetailLeft}>{Labels.bigPopup.risk} :</span>
                <span className={classes.smallDetailRight}> {this.props.data.Risk}</span>

              </div>
              <Divider orientation="vertical" flexItem className={classes.divider} />
              <div>
                <span className={classes.smallDetailLeft}>{Labels.bigPopup.AUM} :</span>
                <span className={classes.smallDetailRight}>{NegativeFinding(this.props.data.AUM, "", "", "", "rupee")} </span>
              </div>

            </div>

            <div style={{ textAlign: "left" }}>
              <div className={classes.threeYearReturn}>{NegativeFinding(this.props.data.threeYearReturn, "", "", "", "number")}</div>
              <div className={classes.smallDetailLeft} style={{ paddingLeft: 0 }}>{Labels.bigPopup.nav} {this.props.data.date}</div>
            </div>

          </div >

          <div className={classes.secondGrid}>
            <h3 style={{ padding: 0, margin: 0, textAlign: "left" }}>Invesment Details</h3>
            <div style={{ width: "30%" }}>
              <Button page="primary" name={this.props.data.InvesmentType == "One time" ? Labels.bigPopup.onetime : Labels.bigPopup.sip}></Button>
            </div>
            {this.props.data.InvesmentType == "One time" ? <div className={classes.oneTimeGridContainer}>
              <Textinput label={Labels.bigPopup.amount} otherProps={{ maxLength: 12 }} error={this.props.amountErrorMessage} handleChange={this.props.onchangeAmount} value={this.props.amount != 0 ? NegativeFinding(this.props.amount, "", "", "", "rupee") : ''}></Textinput>
            </div> : <div style={{ display: "flex", flexDirection: "column", gap: 20, marginTop: 10 }}>

              <div className={classes.investmentGrid} >
                <div className={classes.investmentColumncheck} >
                  {/* <Textinput label={Labels.bigPopup.amount} error={this.props.amountValidationMessage} handleChange={this.props.onchangeAmount} value={this.props.amount != 0 ? NegativeFinding(this.props.amount, "", "", "", "rupee") : ''}></Textinput> */}
                  <div className={classes.investmentLabel} >{Labels.bigPopup.amount}</div>

                  <OutlinedInput
                    classes={classes}
                    tabIndex={0}
                    className={classes.inputRoot}
                    id="outlined-adornment-amount"
                    value={this.props.amount != 0 ? NegativeFinding(this.props.amount, "", "", "", "number") : ''}
                    onChange={this.props.onchangeAmount}
                    mask
                    // onKeyDown={this.props.onAmountKeypress}
                    inputProps={{ maxLength: 12 }}
                    autoComplete="off"
                    startAdornment={<InputAdornment position="start">{labels.currency.rupeesymbol}</InputAdornment>}
                    endAdornment={<div style={{ float: "right" }}><button className={classes.textBoxMonthly}>{Labels.bigPopup.monthly}</button></div>}

                    size='small'
                  />

                  {this.props.amountErrorMessage != "" && <div><span style={{ color: window.globalConfig.color.red, fontSize: 12 }}>{this.props.amountErrorMessage}</span></div>}

                </div>
                {/* <div className={classes.investmentColumn} style={{ marginTop: 8 }}> */}



                <Dropdown placeholder={labels.messages.select}
                  id="investment3"
                  label={Labels.bigPopup.sipDate} height={135}
                  type={"normalwithsearch"}
                  value={this.props.startDate}
                  List={labels.Date}
                  error={this.props.sipDateMessage}
                  handleOnChange={this.props.dropdowndata}
                  autoselect={false} />


                {/* {this.props.sipDateValidation == true && <div><span style={{ color: window.globalConfig.color.red, fontSize: 12, }}>{this.props.sipDateMessage}</span></div>} */}
                {/* </div> */}
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Textinput value={this.props.installments} label={Labels.bigPopup.noOfInstallments} error={this.props.instalmentErrorMessage} onKeyDown={this.props.onAmountKeypress}
                    handleChange={this.props.onchangeInstallment} otherProps={{ maxLength: 8 }} placeholder={`Minimum ${this.props.minimumInstalment} Instalments`}></Textinput>
                </div>
                <div className={classes.checkBox} >

                  <CommonCheckbox label={Labels.bigPopup.checkBox}
                    checked={this.props.checked} onChange={this.props.handleCheckbox} />

                  {/* {<span style={{ fontSize: "smaller" }}>{this.props.checked ? Labels.bigPopup.firstInstallmentsDate : Labels.bigPopup.firstInstallmentWillBe} <span style={{ fontWeight: "bold" }}> */}
                  {/* {this.props.checked
                    ? moment(moment(this.props.selectedDate, "DD MMM YYYY").add(1, "months").toString()).format("DD MMM YYYY"):moment(this.props.selectedDate).diff(moment().format("DD MMM YYYY"),"days") <= 6 ? moment(
                      moment(
                        this.props.selectedDate,
                        "DD MMM YYYY"
                      )
                        .add(1, "months")
                        .toString()
                    ).format("DD MMM YYYY")
                    : this.props.selectedDate} */}

                  <div style={{ fontSize: 14, marginTop: '0.7em', textAlign: 'left', display: "flex", flexDirection: "column", gap: 10 }}>
                    <span>
                      SIP Start date will be <b> {this.props.selectedDate}</b>
                    </span>
                    <span>
                      Next installment will be <b> {
                        moment(moment(
                          this.props.selectedDate,
                          "DD MMM YYYY"
                        )
                          .add(1, 'M')
                          .toString()
                        ).format("DD MMM YYYY")}</b>
                    </span>
                  </div>
                  {/* </span></span>} */}
                </div>
              </div>
            </div>

            }

          </div>

        </div>}
      </>
      )
    } catch (err) {
      // console.log(err)
      return <Redirect to={labels.Path.NotFound}></Redirect>
    }

  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}
export default withStyles(styles)(
  withRouter(connect(null, mapDispatchToProps)(Invesment))
);



