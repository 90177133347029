import React, { Component } from 'react';
import { withStyles, } from '@material-ui/core/styles';
import colors from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import { connect, shallowEqual } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import { withRouter } from "react-router";
import Arrow from '../../utils/assets/leftArrow.png';
import getRiskScoreReportDetails from "../../api/riskscoreapi/riskscoreapi";
import { getRiskScoreReport } from '../../redux/actions/riskscore/riskscore';
import Buttons from '../../components/custombutton/button'
import ErrorBoundary from '../Errorhandling/Errorhandle';
import CommonSnackbar from '../snackbar/snackbar';
const styles = theme => ({
    header: {
        width: "100%",
        padding: "10px 0px 10px 0px",

    },
    headerContent: {
        marginTop: "5%",
        marginLeft: 30
        // width: 400,
        // height: 450
    },
    question: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    optionButton: {
        backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
        color: window.globalConfig.color.BLACK_PRIMARY,
        borderRadius: 3,
        border: 'none',
        marginTop: 30,
        height:30,
        width: "100%",
        fontSize: 12,
        '&:hover': {
            backgroundColor: window.globalConfig.color.RosyBrown,
            color: window.globalConfig.color.BROWN,
        }
    },
    optionButtonSelect: {
        backgroundColor: window.globalConfig.color.floralWhite,
        color: window.globalConfig.color.BROWN,
        borderRadius: 3,
        border: 'none',
        marginTop: 30,
        height:30,
        width: "100%",
        fontSize: 12,
        '&:hover': {
            backgroundColor: window.globalConfig.color.floralWhite,
            color: window.globalConfig.color.BROWN,
        }
    },
    modelBox: {
        width: 300,
        padding: '5px 30px 20px 30px',
        height: 'auto',
        borderRadius: 10
    },
    questionNumber: {
        fontSize: 20,
        fontWeight: 'bold',
        borderBottom: `4px solid ${window.globalConfig.color.BROWN}`
    },
    middle: {
        marginLeft: 5,
        fontSize: 10,
        color: window.globalConfig.color.PRIMARY_BORDER
    },
    questionCount: {
        marginLeft: 5,
        fontSize: 12,
        color: window.globalConfig.color.PRIMARY_BORDER
    },
})
class Popup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // questionPopUp: true,
            //AssessButtonVisible: true,
            answerID: '',
            questionID: '',
            form_data: [],
            questionEnd: false,
            data: [],
            count: 0,
            riskAnswers: '',
            selectAns: 0,
            onlyQuestion: [],
            allAnswers: [],
            allQuestionsFromAPI: [],
            loader:false
        }
    }


    componentDidMount() {

        if (this.props.riskQuestions != undefined && this.props.riskQuestions != null && this.props.riskQuestions != '') {
            //Question Datas
            this.setState({ allQuestionsFromAPI: this.props.riskQuestions.response_data.question })
            let questions = this.props.riskQuestions.response_data.question;

            let onlyQuestions = questions.map(item => {
                return {
                    label: item.label,
                    ques_id: item.ques_id,
                    key: item.key,
                    ans_required: item.ans_required,
                    ques_hint: item.ques_hint,
                    type: item.type
                }
            })

            let allanswers = questions.map((item, index) => {
                return item.answers.map(i => {
                    return { ...i, answered: false, ques_id: item.ques_id }
                })
            })

            this.setState({
                onlyQuestion: onlyQuestions,
                allAnswers: allanswers
            })
            this.setState({
                data: this.props.riskQuestions.response_data.question,
            })

            const formData = this.props.riskQuestions.response_data.question.map(item => {
                return {
                    ques_id: item.ques_id,
                    key: '',
                    ans_id: '',
                }
            })
            this.setState({
                form_data: formData
            })
        }

    }

    componentDidUpdate(prevProps) {

    }

    nextQuestion = (ans, ques, key, index) => {
        if (this.state.count >= 14) {
            this.setState({
                questionEnd: true,
                // questionPopUp: true,
                count: this.state.count,
                answerID: ans,
            })
        }
        else {
            this.setState({
                count: this.state.count + 1,
                answerID: ans,
                questionID: ques,
            })
        }

        // Create form_data - used in answer API parameter
        if (ans != undefined && ans != "" && ans != null && ques != undefined && ques != "" && ques != null && key != undefined && key != "" && key != null) {


            let allanswers = this.state.allAnswers.map((item) => {
                return item.map(i => {
                    if (i.ans_id == ans && i.ques_id == ques) {
                        return { ...i, answered: true }
                    } else if (i.ans_id != ans && i.ques_id == ques) {
                        return { ...i, answered: false }
                    } else {
                        return i;
                    }
                })
            })

            //Create form_data
            this.setState({ allAnswers: allanswers });
            const Data = this.state.form_data.map((item) => {
                if (ques == item.ques_id) {
                    return {
                        ...item,
                        key: key,
                        ans_id: ans,
                    }
                }
                else {
                    return item;
                }
            })
            this.setState({
                form_data: Data,
            })
        }

    }

    submit = () => {
        if (this.state.questionEnd == true) {
            this.setState({
                // questionPopUp: false,
                count: this.state.count,
            })
            //  // this props value is pass to the advisoryaccount.js page
            // this.props.count("14");
            this.setState({loader:true})
            getRiskScoreReportDetails(this.props.riskQuestions.response_data.set_id, this.props.mainDetails[0]?.EmailID, this.state.form_data,this.props.mainDetails[0]?.DOB,this.props.mainDetails[0]?.MobileNo,this.props.mainDetails[0]?.ClientName,this.props.mainDetails[0].ClientName).then((response) => {
                this.props.getRiskScoreReport(response)
                if (this.props.onsubmitClick != undefined) {
                    this.props.onsubmitClick(response)
                }
                this.setState({loader:false})
                this.props.onClose();
                // }

            }).catch((err) => {
                this.props.onClose();
                this.setState({loader:false})

            })
            //just checked -- This 'data' is create to the Known the what are parameter send to the api call in object types
            // let data = {};

            // data.set_id = 3;
            // data.email = "ashutoshajgaonkar@hotmail.com";
            // data.form_data = this.state.form_data
            //this.props.mainDetails[0].EmailID

            //This is a submit button click API - (getRiskScoreReport = hit to the redux action)

        }
        else {
            // this.setState({
            //     questionPopUp: true,

            // })
        }


    }


    prevSelect = () => {
        if (this.state.count >= 1) {
            this.setState({
                count: this.state.count - 1
            })
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <ErrorBoundary>
                <div>
                    <Dialog
                        // open={this.state.questionPopUp}
                        // onClose={this.closePopup}

                        open={this.props.open}
                        onClose={this.props.onClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        disableBackdropClick={true}
                    >

                        {this.state.onlyQuestion.map((item, index) => {
                            return (
                                this.state.count == index && (
                                    <div key={index}>
                                        <div className={classes.header}>
                                            <div className={classes.headerContent}>
                                                {
                                                    index > 8 ?
                                                        <span className={classes.questionNumber}>{index + 1}</span>
                                                        :
                                                        <span className={classes.questionNumber}>0{index + 1}</span>
                                                }

                                                <span className={classes.middle}>{labels.advisory.of}</span>
                                                <span className={classes.questionCount}>{labels.advisory.fifteen}</span>
                                            </div>
                                        </div>
                                        <div className={classes.modelBox}>
                                            <div style={{ marginTop: '5%' }}>
                                                <span className={classes.question}>{item.label}</span>
                                            </div>

                                            {this.state.allAnswers[index].map((i, ind) => {

                                                return (
                                                    !i.answered ?
                                                        <div key={ind}>
                                                            <button className={[classes.optionButton].join(' ')} onClick={() => this.nextQuestion(i.ans_id, item.ques_id, item.key, index)}>{i.name}</button><br />
                                                        </div>
                                                        :
                                                        <div key={ind}>
                                                            <button className={[classes.optionButtonSelect].join(' ')} onClick={() => this.nextQuestion(i.ans_id, item.ques_id, item.key, index)}>{i.name}</button><br />
                                                        </div>
                                                )
                                            })}
                                            {
                                                this.state.count >= 1 ?

                                                    <div style={{ marginTop: 20 }}>
                                                        <img src={Arrow} style={{ width: 20, height: 20 }} onClick={this.prevSelect} />
                                                    </div>
                                                    :
                                                    <div style={{ marginTop: 25 }}>

                                                    </div>
                                            }

                                        </div>
                                        {this.state.count == 14 &&
                                            <>
                                                <div style={{ width: 130, margin: '0px auto' }}>
                                                    <Buttons onClick={this.submit} loderAlign="align" page="primary" name={labels.advisory.submit} loader={this.state.loader} />
                                                </div>
                                                <div style={{ marginTop: '10%' }}></div>
                                            </>
                                        }
                                    </div>
                                )
                            )
                        })
                        }
                    </Dialog>
                </div>
            </ErrorBoundary>
        )
    }
}
const mapStateToProps = state => ({
    mainDetails: state.ProfileDetails?.AllProfileDetails?.mainDetails != "NA" ? state.ProfileDetails.AllProfileDetails?.mainDetails?.length != 0 ? state.ProfileDetails?.AllProfileDetails?.mainDetails : [] : [],
    riskQuestions: state.RiskQuestion.questionOrder != null ? state.RiskQuestion.questionOrder.length != 0 ? state.RiskQuestion.questionOrder : [] : [],
    riskAnswers: state.RiskScore.answerOrder != null ? state.RiskScore.answerOrder != undefined ? state.RiskScore.answerOrder : [] : [],
    logindetails: state.Login.logindetails,
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getRiskScoreReport }, dispatch)
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Popup)))
