import React, { PureComponent } from "react";
import moment from "moment";
import CustomTooltip from "../tooltip/customtooltip";
import getPerformanceDetails from "../../api/schemedetails/performace";
import ReactHighcharts from "react-highcharts/ReactHighstock.src";
import "./switchbutton.css";
import lable from "../../utils/constants/labels";
import labels from "../../utils/constants/labels";
import Skeleton from "@material-ui/lab/Skeleton";
import NoData from "../../components/nodata/nodata";
import { withStyles } from "@material-ui/core/styles";
import { Redirect, withRouter } from "react-router";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import secureStorage from '../../utils/securestorage'
import colors from "../../utils/colors/colors";
import { NegativeFinding } from "../../utils/commonfunction";
const styles = (theme) => ({
  // noDate: {
  //   marginTop: "5%",
  //   height: "36em",
  //   width: "35em",
  //   [theme.breakpoints.up('1700')]: {
  //     height: "32em",
  //   }
  // }
});
let name = "";
let value = "";
class Linechart extends PureComponent {
  state = {
    opacity: {
      uv: 1,
      value: 1,
    },
    chatData: [],
    select: 2,
    loader: false,
    performance3Y: [],
    performance6M: [],
    performance1Y: [],
    performance5Y: [],
  };

  render() {
    try {

      const options = { style: "currency", currency: "INR" };
      const numberFormat = new Intl.NumberFormat("en-IN", options);
      const configPrice = {
        yAxis: [
          {
            offset: 20,
            labels: {
              formatter: function () {
                return `${NegativeFinding(this.value, "", "", "", "rupee")}`;
              },
              x: -15,
              style: {
                color: window.globalConfig.color.BLACK_PRIMARY,
                position: "absolute",
                fontSize: 10,
                // top:10
              },
              align: "left",
            },
          },
        ],
        plotOptions: {
          series: {
            pointPadding: 1, // Increase this value to increase the gap between data points  
            groupPadding: 10, // Increase this value to increase the gap between series   
          },
        },
        rangeSelector: {
          enabled: true
        },
        tooltip: {
          shared: true,
          formatter: function () {
            return (
              numberFormat.format(this.y, 0) +
              "</b><br/>" +
              moment(this.x).format("MMMM DD YYYY")
            );
          },
        },
        chart: {
          height: this.props.data.length == 0 ? 30 : 400,
          backgroundColor: "transparent"
        },

        credits: {
          enabled: false,
        },
        xAxis: {
          type: "date",
        },
        navigator: {
          enabled: false,
        },
        series: this.props.data
      };
      return (

        <div>

          {this.props.isTransactionDetailsLoading ? (
            <Skeleton
              animation="wave"
              variant="rect"
              style={{
                height: "23vh",
                marginBottom: "2vw",
                marginTop: "0vw",
                marginLeft: "2vw",
                marginRight: "2vw",
              }}
            />
          ) : (
            <div>

              {this.props.data.length == 0 ? (
                <NoData height={5} />
              ) : (
                <div style={{ width: "100%" }}>
                  <ReactHighcharts
                    config={configPrice}
                    className="mainHighcharts"
                  ></ReactHighcharts>
                </div>
              )}

              <div style={{ marginBottom: "5%", textAlign: "center" }}>
                {this.props.filterData.map((item) => {
                  return <a
                    className={
                      item.isActive
                        ? "lable active"

                        : "lable"
                    }
                    onClick={() => this.props.handleFilter(item.name)}
                  >
                    {item.name}
                  </a>
                })}

              </div>

            </div>
          )}

        </div>

      );
    } catch (err) {
      return <Redirect path={labels.Path.NotFound} />
    }
  }
}
const mapStateToProps = state => {

  return {
    // lineChartPerfomance: state.LinePerformaceChart.linePerformance != undefined && state.LinePerformaceChart.linePerformance != 0 ? state.LinePerformaceChart.linePerformance : [],
    // sixMonthPerformance: state.LinePerformaceChart.sixMonthPerformance != undefined && state.LinePerformaceChart.sixMonthPerformance != 0 ? state.LinePerformaceChart.sixMonthPerformance : [],
    // threeYearPerformance: state.LinePerformaceChart.threeYearPerformance != undefined && state.LinePerformaceChart.threeYearPerformance != 0 ? state.LinePerformaceChart.threeYearPerformance : [],
    // maxYeadPerformance: state.LinePerformaceChart.maxYeadPerformance != undefined && state.LinePerformaceChart.maxYeadPerformance != 0 ? state.LinePerformaceChart.maxYeadPerformance : [],
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}
// export default withStyles(styles)(Linechart);
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Linechart)))
