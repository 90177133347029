import moment from 'moment';
import ApiValues from '../url/apiurl';
import secureStorage from '../../utils/securestorage'
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
function MandateStatusCheck(mandateID,UCC) {
  let userDetails = secureStorage.getItem('user')
  let data = {};
    data.FromDate = "01/01/2000",
    data.ToDate = "31/12/2099",
    data.ClientCode =UCC,
    data.MandateId = mandateID
  return Networking.PostApi(ApiValues.mandateStatusCheck, data,labels.apiKey.apiKeys).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
}
export default MandateStatusCheck
