import * as types from '../../constants/actionsType'
const initialState = {
    VerifyOTP: [],
    ConfirmOTP: [],
    PasswordConfirm: []
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SENDOTP:
            return {
                ...state,
                VerifyOTP: action.payload,
            }
            default:
                return state
        case types.VERIFYOTP:
            return {
                ...state,
                ConfirmOTP: action.payload
            }
        case types.LOGIN_CHANGE:
            return {
                ...state,
                PasswordConfirm: action.payload
            }
    }
}
export default reducer
