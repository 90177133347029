import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
// import { updatePassword, closeUpdatePasswordMsgAction } from '../../redux/actions/authActions';
// import MessagesForm from '../Forms/MessagesForm';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Colors from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import CircularProgress from '@material-ui/core/CircularProgress';
import { PostSendOtp } from '../../redux/actions/resetpassword/otpsend'
import TextInput from '../../components/textinput/textinput'
const styles = (theme) => ({


    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        // color: theme.palette.grey[500],
        color: window.globalConfig.color.WHITE,
    },
    header: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: window.globalConfig.color.WHITE,
    },
    btnSubmit: {
        backgroundColor: window.globalConfig.color.BROWN,
        color: window.globalConfig.color.WHITE,
        '&:hover': {
            background: window.globalConfig.color.PRIMARY_LIGHT,
            color: window.globalConfig.color.WHITE,
        },
    },
    btnCancel: {
        backgroundColor: window.globalConfig.color.RosyBrown,
        color: window.globalConfig.color.BLACK_PRIMARY,
        '&:hover': {
            background: window.globalConfig.color.PRIMARY_LIGHT,
            color: window.globalConfig.color.WHITE,

        }
    },
    changepwd: {
        minWidth: "31%"
        , left: "38%",
        inset: '0px',
    },
    // password:{
    //     width:"450px",
    //     marginLeft: "30%",

    // }
});
export const requireValidator = value => {
    if (!value || value.length <= 0) return labels.messages.required;
    return '';
};
export const confirmPasswordValidator = (value, account) => {
    if (!value || value.length <= 0) return labels.messages.required;
    if (value != account) return labels.messages.passwordmismatch
    return '';
};
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);
function ChangePassword(props) {
    const [newPassword, setNewPassword] = useState({ value: '', error: '' })
    const [confirmPassword, setconfirmPassword] = useState({ value: '', error: '' })
    const [loading, setLoading] = useState(false)

    const handleSubmit = () => {
        const newPasswoedError = requireValidator(newPassword.value);
        const confirmPasswordError = confirmPasswordValidator(confirmPassword.value, newPassword.value);
        //    ("confirmPasswordError",confirmPasswordError)
        const hasUppercase = /[A-Z]/.test(newPassword.value);
        const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(newPassword.value);
        const hasNumber = /\d/.test(newPassword.value);

        setconfirmPassword({ ...confirmPassword, error: confirmPasswordError })
        if (newPasswoedError || confirmPasswordError) {
            setNewPassword({ ...newPassword, error: newPasswoedError })
            setLoading(false)

        } else {
            // 
            if (!hasNumber || !hasSpecialChar || !hasUppercase) {
                // formisvalid = false;
                setNewPassword({ ...newPassword, error: "Password must  contain at least one uppercase letter, one special character, and one number." })
                // this.setState({
                //   newpassworderror: ,
                //   formisvalid: false,
                // });
            }
            else if (newPassword.value.length < 7) {
                // 
                setNewPassword({ ...newPassword, error: labels.messages.MinimumPasswordError })
            }
            // else if (newPassword.value != confirmPassword.value) {
            //     setconfirmPassword({ ...confirmPassword, error: "Password mismatch" })
            // }
            else {
                setLoading(true)
                props.updatePassword(confirmPassword.value)
                setLoading(false)

                setNewPassword({ value: '', error: '' })
                setconfirmPassword({ value: '', error: '' })
                // 

                //newPassword.error://labels.messages.MinimumPasswordError
            }

        }
    }
    const { classes } = props
    return (
        <div>
            <Dialog
                //   fullWidth={"60%"}
                className={classes.password}
                onClose={() => props.close()}
                open={props.open}>

                <div className={classes.header}>
                    <DialogTitle onClose={() => props.close()}>
                        {labels.ForgotPassword.changePassword}
                    </DialogTitle>
                </div>
                <DialogContent dividers>
                    {/* <Typography style={{ paddingTop: 5 }} variant="h5">Welcome</Typography> */}
                    <form >
                        <div>
                            <FormControl style={{ width: "100%", padding: 5 }}>
                                <TextInput page="TextFieldpassword"
                                    // type={showPassword}
                                    label="New Password"
                                    otherProps={{ name: "newPassword" }}
                                    classes={{ input: classes.input }}
                                    value={newPassword.value}
                                    // onKeyDown={this.onPasswordKeypress}
                                    onChange={(e) => setNewPassword({ value: e.target.value, error: "" })}
                                    errormessage={newPassword.error}

                                // onClick={this.handleClickShowPassword}
                                // onMouseDown={this.handleMouseDownPassword}
                                />
                                {/* <TextField
                                    id="standard-basic"
                                    name="newPassword"
                                    label="New Password"
                                    type="password"
                                    className="text-field-cursor"
                                    value={newPassword.value}
                                    onChange={(e) => { const error = requireValidator(e.target.value); setNewPassword({ value: e.target.value, error: error }) }}
                                    error={newPassword.error.length > 0}
                                    helperText={newPassword.error}
                                /> */}
                            </FormControl>
                            <FormControl style={{ width: "100%", padding: 5 }}>
                                <TextInput page="TextFieldpassword"

                                    label="Confirm Password"
                                    classes={{ input: classes.input }}
                                    value={confirmPassword.value}
                                    otherProps={{ name: "confirmPassword" }}

                                    onChange={(e) => setconfirmPassword({ value: e.target.value, error: "" })}
                                    errormessage={confirmPassword.error}

                                />
                                {/* <TextField
                                    id="standard-basic"
                                    name="confirm Password"
                                    label="Confirm Password"
                                    type="password"
                                    className="text-field-cursor"
                                    value={confirmPassword.value}
                                    onChange={(e) => { const error = confirmPasswordValidator(e.target.value, newPassword.value); setconfirmPassword({ value: e.target.value, error: error }) }}
                                    error={confirmPassword.error.length > 0}
                                    helperText={confirmPassword.error}
                                /> */}

                            </FormControl>

                        </div>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus
                        className={classes.btnCancel}
                        onClick={() => props.close()} color="secondary">
                        {labels.ForgotPassword.cancel}
                    </Button>
                    <Button className={classes.btnSubmit} autoFocus onClick={() => handleSubmit()} color="primary">
                        {loading == true &&
                            <CircularProgress size={24} style={{ color: window.globalConfig.color.WHITE, position: "absolute", marginLeft: 15 }} />
                        }
                        {labels.ForgotPassword.save}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

// const dispatchToProps = (dispatch) => {
//     return {
//         updatePassword: (userId, password) => dispatch(updatePassword(userId, password)),
//         closeMsg: () => dispatch(closeUpdatePasswordMsgAction()),
//     };
// };
const mapStateToProps = state => ({
    logindetails: state.Login.logindetails,
    login: state.Login.login,
    isSendMail: state.ResetPassword.VerifyOTP,
    // isChangePassword: state.isChangePassword,
    // isSendMail: state.isSendMail,
    // isVerifyOTP: state.isVerifyOTP,
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ PostSendOtp }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ChangePassword))

