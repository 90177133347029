import React, { useEffect } from 'react';
import BigText from './bigtext';
import SmallText from './smalltext'
import Check from '@material-ui/icons/Check';
import { Divider, } from '@material-ui/core';
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Rupee from './rupee';
import { withRouter } from "react-router";
import Label from '../../utils/constants/paymentlabels';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Loader from '../loader/loader'
import CancelIcon from '@material-ui/icons/Cancel';

// import MultipleSchemeTabs from '../tab/multiple_schemetabs'
import DeleteCart from '../../api/cart/deletecartapi';
import { GetAllCart } from '../../redux/actions/cart/getallcartaction';
import { DeleteSipCartReducer } from '../../redux/actions/cart/deletecartaction'
import { DeleteOnetimeCartReducer } from '../../redux/actions/cart/deletecartaction';
import secureStorage from '../../utils/securestorage'
import NoData from '../nodata/nodata';
import CancelImage from "../../utils/assets/images/failureImg.png";
import Capture from "../../utils/assets/images/capture.png";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import color from "../../utils/colors/colors";
import moment from 'moment';
import labels from '../../utils/constants/labels';
import { NegativeFinding, formatDate } from '../../utils/commonfunction';
import ErrorBoundary from '../Errorhandling/Errorhandle';
import { Redirect } from 'react-router';
import colors from '../../utils/colors/colors';
import OutlinedButtons from './button';
import Epfreport from '../../containers/epf/epfreport';
import Button from '../custombutton/button';
import CommonCheckbox from '../checkbox/checkbox';
import Text from '../text/text';
function TabPanel(props) {

    const { children, value, index, ...other } = props;



    return (

        <div

            role="tabpanel"

            hidden={value !== index}

            id={`scrollable-auto-tabpanel-${index}`}

            aria-labelledby={`scrollable-auto-tab-${index}`}

            {...other}

        >

            {value === index && (

                <Box>

                    {children}

                </Box>

            )}

        </div>

    );

}
TabPanel.propTypes = {

    children: PropTypes.node,

    index: PropTypes.any.isRequired,

    value: PropTypes.any.isRequired,

};
function a11yProps(index) {

    return {

        id: `scrollable-auto-tab-${index}`,

        'aria-controls': `scrollable-auto-tabpanel-${index}`,

    };

}
const styles = (theme) => ({

    singleSchemeContainer: {

        width: '100%',

        height: '45vh',

    },

    multipleSchemeContainer: {

        width: '100%',

        // height: '55vh',

    },



    multipleSipSchemeContainer: {

        width: '100%',

        // height: '63vh',

    },

    singleSchemeLeft: {

        display: 'flex',

        justifyContent: 'center',

        marginTop: '3%'

    },

    multipleSchemeLeft: {

        display: 'flex',

        justifyContent: 'center',



    },

    header: {

        width: '100%',

        height: '26vh',

        display: 'flex',

        flexDirection: 'column',

        justifyContent: 'center',

        alignItems: 'center',

        // marginTop: -20,

        marginBottom: '20px',

    },

    smallSpacing: {

        padding: 5

    }, bigSpacing: {

        padding: 8,

    },

    bigSmSpacing: {

        padding: 10,

        display: "flex",

        justifyContent: 'space-between',

        width: '100%'

    },

    smSmallSpacing: {

        padding: 2.5

    },

    flex: {

        display: "flex",

        justifyContent: 'space-between',

        width: '80%'

    },

    Faild: {

        color: window.globalConfig.color.WHITE,

        zIndex: 1,

        fontSize: 35,

        border: '1px solid red',

        borderRadius: 25,

        backgroundColor: 'red'

    },

    completed: {

        color: window.globalConfig.color.WHITE,

        zIndex: 1,

        fontSize: 35,

        border: `1px solid ${window.globalConfig.color.GREEN}`,

        borderRadius: 25,

        backgroundColor: window.globalConfig.color.GREEN,

        // [theme.breakpoints.between('300','600')]: {

        //     fontSize: 25,

        // },

    },

    headerflex: {

        display: 'flex'

    },

    dividerLine: {

        marginTop: '-2%', width: '105%',

        marginLeft: -27,



    },

    headerfolio: {

        display: 'flex',

        marginTop: '-17px',

        paddingBottom: '20px'

    },

    flex: {

        display: 'flex',

        justifyContent: 'space-between',

        width: '90%'

    },

    tdPadding: {

        paddingRight: '60px'

    },

    label: {

        fontSize: 13,

        padding: "0px 0px 6px",

        color: colors.LABLE_GRAY,

        textAlign: "left"

    },

    widthtab: {

        borderBottom: `1px solid ${colors.BORDER}`

    },

    header: {

        fontSize: 17,

        padding: "0px 3px 6px 0px",

        color: colors.BLACK_PRIMARY,

        textAlign: "left",

        fontWeight: 500

    },

    tabRoot: {

        "& .MuiTab-root:focus": {

            color: window.globalConfig.color.BROWN

        },

        "& .MuiTab-textColorPrimary.Mui-selected": {

            color: window.globalConfig.color.BROWN

        }

    },

    paymentConfirmationContainer: {

        display: "grid", gridTemplateColumns: "48% 2% 48%",

        [theme.breakpoints.down("920")]: {

            gridTemplateColumns: "100%",

        }

    },

    amountDetails: {

        display: "grid", gridTemplateColumns: "50% 11% 27% 10%", gap: 7, textAlign: "start"

    }

})
class PaymentConfirmation extends React.Component {

    constructor(props) {

        super(props);

        this.state = {

            value: this.props.data?.multipleScheme[0]?.ID,

            data: [],

            checked: false

        }

    }

    componentDidMount = () => {
        this.setState({ value: this.props.data?.multipleScheme[0]?.ID })
        if (this.props.page == "basket") {
            this.setState({
                data: this.props.data?.multipleScheme?.map((item) => {
                    return {
                        ...item,
                        dataOne: item.SchemeName,
                        dataTwo: NegativeFinding(item.Amount, "", "", "", "rupee"),

                        dataThree: item.OrderResponse,

                        dataFour: item.Status == "F" ? <Button page="primary" name="Retry" onClick={() => this.props.confirmOrder(item)} ></Button> : <img src={Capture} style={{ height: 29, width: 24, }} />

                    }

                })

            })

        }
    }
    componentDidUpdate(prev) {
        const { data } = this.props;
        if (prev.data != data) {
            if (this.props.page == "basket") {
                this.setState({
                    data: data?.multipleScheme?.map((item) => {
                        return {
                            ...item,
                            dataOne: item.SchemeName,
                            dataTwo: NegativeFinding(item.Amount, "", "", "", "rupee"),

                            dataThree: item.OrderResponse,

                            dataFour: item.Status == "F" ? <Button page="primary" loading={item.isLoading} name="Retry" onClick={() => this.props.confirmOrder(item)} ></Button> : <img src={Capture} style={{ height: 29, width: 24, }} />

                        }

                    })

                })

            }
        }
    }
    back = () => {
        this.props.history.push(labels.Path.MFTransaction)
    }

    handleChange = (event, newValue) => {
        this.setState({ value: newValue })
    }

    handleCheckBox = () => {

        this.setState({ checked: !this.state.checked });

        if (!this.state.checked) {

            this.props.confirmOrder();

        }

    }

    render() {

        const { classes } = this.props

        let data = this.props.data.multipleScheme?.filter((item) => item.ID == this.state.value)[0]

        try {

            return (

                <ErrorBoundary>

                    {this.props.page == "basket" ? <div style={{ padding: 10 }}>

                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 5, marginTop: 5, marginBottom: 5 }}>

                            {this.props.data.response == "S" ? <img src={Capture} style={{ height: 49, width: 54, }} /> : <img src={CancelImage} style={{ height: 49, width: 54, }} />}

                            {this.props.data.response == "S" ? <span style={{ fontWeight: "bold", fontSize: 20 }}>{Label.Payment.Submit}</span> : <span style={{ fontWeight: "bold", fontSize: 20 }}>{Label.Payment.Faild}</span>}

                            <span>{this.props.data.basketName}</span>

                            <span>{this.props.logindetails.UserName}</span>

                        </div>
                        <div style={{ display: "grid", gridTemplateColumns: "80% 20%", gap: 10, borderTop: `1px solid ${colors.BORDER}`, paddingTop: 10 }}>
                            <div>
                                {this.props.data.multipleScheme[0].InvesmentType != undefined && <Text label="Investment Type" page="normal" text={this.props.data.multipleScheme[0].InvesmentType}></Text>}
                                {this.props.data.multipleScheme[0].Sipdate != undefined && <Text label="SIP Date" page="normal" text={formatDate(this.props.data.multipleScheme[0].Sipdate)}></Text>}
                                {this.props.data.multipleScheme[0].ISFirstPayment != undefined && <Text label="Is FirstOrder Today" page="normal" text={this.props.data.multipleScheme[0].ISFirstPayment ? "Yes" : "No"}></Text>}
                            </div>
                            <CommonCheckbox checked={this.state.checked} label={"Retry All Failure Orders"} onChange={this.handleCheckBox}></CommonCheckbox>
                        </div>
                        <Epfreport showHeader={false} data={this.state.data} grid={classes.amountDetails} title={[{ "header": "Scheme Name", "subHeader": "", align: "left" }, { header: "Amount", align: "right", subHeader: "" }, { header: "Status", align: "left", subHeader: "" }, { header: "", align: "left", subHeader: "" },]} ></Epfreport>

                    </div> : <>  {Object.keys(this.props.data?.multipleScheme).length != 0 ?

                        <div style={{ display: "flex", flexDirection: "column", }}>

                            {!this.props.data.multipleSelect && <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 5, marginTop: 5, marginBottom: 5 }}>

                                {data.response.status == "S" ? <img src={Capture} style={{ height: 49, width: 54, }} /> : <img src={CancelImage} style={{ height: 49, width: 54, }} />}

                                {data.response.status == "S" ? <span style={{ fontWeight: "bold", fontSize: 20 }}>{Label.Payment.Submit}</span> : <span style={{ fontWeight: "bold", fontSize: 20 }}>{Label.Payment.Faild}</span>}

                                <span style={{ color: data.response.status != "S" ? colors.red : colors.GREEN }}>{data.response.message}</span>

                                {data.response.orderID != 0 && <span>Order : {data.response.orderID || "NA"}</span>}

                                <span>{this.props.logindetails.UserName}{`(Folio : ${data.folioNumber})`}</span>

                            </div>}

                            {!this.props.data.multipleSelect && <Divider></Divider>}

                            <div className={classes.paymentConfirmationContainer}>

                                <div>

                                    {this.props.data.multipleSelect ?

                                        <>

                                            <div className={classes.root} >

                                                <AppBar

                                                    position="static"

                                                    color="default"

                                                    classes={{ root: classes.widthtab }}>

                                                    <Tabs

                                                        value={this.state.value}

                                                        onChange={this.handleChange}

                                                        indicatorColor="primary"

                                                        classes={{ root: classes.tabRoot }}

                                                        textColor="primary"

                                                        variant="scrollable"

                                                        scrollButtons="auto"

                                                        aria-label="scrollable auto tabs example"

                                                        TabIndicatorProps={{ style: { background: window.globalConfig.color.BROWN } }}

                                                    >

                                                        {this.props.data.multipleScheme.map((item, index) => {

                                                            return <Tab label={"Scheme " + (index + 1)} value={item.ID}  {...a11yProps(index)} className={classes.tab} />

                                                        })}



                                                    </Tabs>



                                                </AppBar>

                                            </div>



                                            {this.props.data.multipleScheme.map((item, index) => {



                                                return <TabPanel value={this.state.value} index={item.ID}>

                                                    <PaymentTemplate logindetails={this.props.logindetails} data={item} classes={classes} mandateDetails={this.props.mandateDetails} multipleSelect={true}></PaymentTemplate>

                                                </TabPanel>

                                            })}

                                            <span style={{ display: "flex", flexDirection: "column", textAlign: "left", paddingLeft: 10, paddingBottom: 10,color: data.response.status != "S" ? colors.red : colors.GREEN }}>{data.response.message}</span>

                                        </>

                                        : this.props.data.multipleScheme.map((item) => {

                                            return <PaymentTemplate data={item} classes={classes} mandateDetails={this.props.mandateDetails} multipleSelect={false}></PaymentTemplate>

                                        })

                                    }</div>

                                {this.props.data.InvesmentType != "One time" && <><Divider orientation='vertical'></Divider>

                                    {this.props.mandateDetails.map((item) => {

                                        return (<div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 20, textAlign: "left", padding: 10 }}>

                                            <div style={{ display: "flex", flexDirection: "column" }}>

                                                <span className={classes.label}>Bank Details</span>

                                                <span style={{ fontSize: 14 }}>{item.bankName || labels.messages.NA}</span>

                                            </div>

                                            <div style={{ display: "grid", gridTemplateColumns: "repeat(3,1fr)", gap: 20 }}>

                                                <div style={{ display: "flex", flexDirection: "column" }}>

                                                    <span className={classes.label}>Bank Account Type</span>

                                                    <span style={{ fontSize: 14 }}>{item.AccountType || labels.messages.NA}</span>

                                                </div>

                                                <div style={{ display: "flex", flexDirection: "column" }}>

                                                    <span className={classes.label}>Account Number</span>

                                                    <span style={{ fontSize: 14 }}>{item.bankAccNo || labels.messages.NA}</span>

                                                </div>

                                                <div style={{ display: "flex", flexDirection: "column" }}>

                                                    <span className={classes.label}>IFSC Code</span>

                                                    <span style={{ fontSize: 14 }}>{item.IFSC_Code || labels.messages.NA}</span>

                                                </div>

                                                <div style={{ display: "flex", flexDirection: "column" }}>

                                                    <span className={classes.label}>Mandate ID</span>

                                                    <span style={{ fontSize: 14 }}>{item.mandateId || labels.messages.NA}</span>

                                                </div>

                                            </div>

                                        </div>)

                                    })}</>}



                            </div>

                            <Divider></Divider>

                            <div style={{ marginTop: 10, marginBottom: 10 }}>

                                <OutlinedButtons text={"Continue to Authorise / Payment"} onClick={this.back}></OutlinedButtons>



                            </div>

                        </div> : <NoData></NoData>}</>}

                </ErrorBoundary>

            )

        } catch (err) {

            console.log(err)

            return <Redirect to={labels.Path.NotFound}></Redirect>

        }



    }

}
class PaymentTemplate extends React.Component {

    constructor() {

        super()

        this.state = {



        }

    }

    render() {

        const orderType = this.props.data

        const { classes, data } = this.props;



        return (<div>



            {orderType.InvesmentType == "One time" ?

                <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 20, textAlign: "left", padding: 10, justifyContent: "center" }}>

                    <div style={{ display: "flex", flexDirection: "column" }}>

                        <span className={classes.label}>Scheme Name</span>

                        <span className={classes.header} style={{ display: "flex", gap: 10 }}>{this.props.data.SchemeName}

                            {this.props.multipleSelect && <>{this.props.data.response.status == "S" ? <img src={Capture} style={{ height: 20, width: 20, }} /> : <img src={CancelImage} style={{ height: 20, width: 20, }} />}</>}

                        </span>

                    </div>

                    <div style={{ display: "grid", gridTemplateColumns: "20% 20% 20% 40%", alignSelf: "normal", gap: 20 }}>

                        <div style={{ display: "flex", flexDirection: "column" }}>

                            <span className={classes.label}>Order Type</span>

                            <span>{orderType.InvesmentType}</span>

                        </div>

                        <div style={{ display: "flex", flexDirection: "column" }}>

                            <span className={classes.label}>Amount</span>

                            <span>{NegativeFinding(this.props.data.Amount, "", "", "", "rupee")}</span>

                        </div>

                        {this.props.multipleSelect && <div style={{ display: "flex", flexDirection: "column" }}>

                            <span className={classes.label}>Order No</span>

                            <span>{this.props.data.response?.orderID || "NA"}</span>

                        </div>}

                        {this.props.multipleSelect && <div style={{ display: "flex", flexDirection: "column" }}>

                            <span className={classes.label}>Client Name(Folio)</span>

                            <span>{this.props.logindetails?.UserName}{`(${this.props.data?.folioNumber || "New"})`}</span>

                        </div>}

                    </div>

                    {/* <span>{this.props.data.response.message}skjfjsdgfkjsdgfjhsgdfdsfhgsdjfgsdkjgfsdjfgsdjfgsdhjfsdgjdsgfjsdfgjdsgfdjsfgdsjgdsjfhsgdkgsdskjfsgdjfsgdjgsdkgsd</span> */}

                </div> :

                <div >

                    <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 20, textAlign: "left", padding: 10 }}>

                        <div style={{ display: "flex", flexDirection: "column" }}>

                            <span className={classes.label}>Scheme Name</span>

                            <span className={classes.header} style={{ display: "flex", gap: 10 }}>{this.props.data.SchemeName}

                                {this.props.data.multipleSelect && <>{this.props.data.response.status == "S" ? <img src={Capture} style={{ height: 20, width: 20, }} /> : <img src={CancelImage} style={{ height: 20, width: 20, }} />}</>}

                            </span>

                        </div>

                        <div style={{ display: "grid", gridTemplateColumns: "repeat(3,1fr)", gap: 20 }}>

                            <div style={{ display: "flex", flexDirection: "column" }}>

                                <span className={classes.label}>Order Type</span>

                                <span style={{ fontSize: 14 }}>{this.props.data.InvesmentType || labels.messages.NA}</span>

                            </div>

                            <div style={{ display: "flex", flexDirection: "column" }}>

                                <span className={classes.label}>SIP Amount</span>

                                <span style={{ fontSize: 14 }}>{NegativeFinding(this.props.data.Amount, "", "", "", "rupee")}</span>

                            </div>

                            <div style={{ display: "flex", flexDirection: "column" }}>

                                <span className={classes.label}>SIP Frequency</span>

                                <span style={{ fontSize: 14 }}>{this.props.data.SipFrequency || labels.messages.NA}</span>

                            </div>

                            <div style={{ display: "flex", flexDirection: "column" }}>

                                <span className={classes.label}>SIP Start Date</span>

                                <span style={{ fontSize: 14 }}>{formatDate(this.props.data.Sipdate) || labels.messages.NA}</span>

                            </div>

                            <div style={{ display: "flex", flexDirection: "column" }}>

                                <span className={classes.label}>No. of Instalments</span>

                                <span style={{ fontSize: 14 }}>{this.props.data.Instalments || labels.messages.NA}</span>

                            </div>

                            <div style={{ display: "flex", flexDirection: "column" }}>

                                <span className={classes.label}>First Payment is Today</span>

                                <span style={{ fontSize: 14 }}>{this.props.data.ISFirstPayment ? "YES" : "NO"}</span>

                            </div>

                            {this.props.multipleSelect && <div style={{ display: "flex", flexDirection: "column" }}>

                                <span className={classes.label}>Order No</span>

                                <span>{this.props.data.response.orderID || "NA"}</span>

                            </div>}

                            {this.props.multipleSelect && <div style={{ display: "flex", flexDirection: "column" }}>

                                <span className={classes.label}>Client Name(Folio)</span>

                                <span>{this.props.logindetails?.UserName}{`(${this.props.data?.folioNumber || "New"})`}</span>

                            </div>}

                        </div>

                    </div>





                </div>}



        </div>)

    }

}
const mapStateToProps = state => ({
    PurchaseOrder: state.PurchaseOrder.purchaseOrder,
    clientID: state.Login.logindetails.ClientID,
    logindetails: state.Login.logindetails
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ DeleteCart, GetAllCart, DeleteSipCartReducer, DeleteOnetimeCartReducer }, dispatch)
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(PaymentConfirmation)))