import * as types from '../../constants/actionsType';
import GetRealEstateListing from '../../../api/real_estate_dataentey/real_estate_listing_api';
import GetBullionListing from '../../../api/real_estate_dataentey/bullion_listing_api';
export const RealEstateListing = (data, flag, status) => {
  if (flag == 'realestate') {
    return {
      type: types.ADD_UPDATE_REAL_ESTATE_LIST,
      data, flag, status
    }
  }
  else {
    return {
      type: types.ADD_UPDATE_BULLION_LIST,
      data, flag, status
    }
  }
}
export const RealEstate = (data) => {
  return {
    // type: types.LISTING_REALESTATE,
     data
  }

}
export const Listing_Bullion = (data) => {
  return {
    type: types.LISTING_BULLION,
    payload: data
  }

}

// real estate delete
export const deleteListing = (data) => {
  return {
    type: types.DELETE_REAL_ESTATE_LIST,
    payload: data
  }
}
// real estate delete
export const deleteBullionListing = (data) => {
  return {
    type: types.DELETE_BULLION_LIST,
    payload: data
  }
}
//real estate listing action
export function getRealEstateList(id, clientID) {
  return (dispatch) => {
    getRealEstateListAction(dispatch, id, clientID);
  };
}
function getRealEstateListAction(dispatch, id, clientID) {
  dispatch({
    type: types.EMPTY_REAL_ESTATE_LIST,
    payload: [],
  });
  dispatch({
    type: types.LOADER_REAL_ESTATE_LIST,
    payload: true,
  });
  GetRealEstateListing(id, clientID)
    .then((data) => {
      if (data != undefined) {
        if (data.status == "S") {
          dispatch({
            type: types.GET_REAL_ESTATE_LIST,
            payload: data,
          });
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "realEstaeList" }],
          });

          dispatch({
            type: types.LOADER_REAL_ESTATE_LIST,
            payload: false,
          });
        }
        else {
          dispatch({
            type: types.GET_REAL_ESTATE_LIST,
            payload: [],
          });
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "realEstaeList" }],
          });

          dispatch({
            type: types.LOADER_REAL_ESTATE_LIST,
            payload: false,
          });
        }
      }
      else {
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: 0, api: "realEstaeList" }],
        });

        dispatch({
          type: types.LOADER_REAL_ESTATE_LIST,
          payload: false,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: 0, api: "realEstaeList" }],
      });

      dispatch({
        type: types.LOADER_REAL_ESTATE_LIST,
        payload: false,
      });
    })
}
//REAL ESTATE SELL ADD FUNCTION
export function GetSellList() {

}


//bullion listing action
export function getBullionList(id, clientID) {
  return (dispatch) => {
    getBullionListAction(dispatch, id, clientID);
  };
}
function getBullionListAction(dispatch, id, clientID) {
  dispatch({
    type: types.EMPTY_BULLION_LIST,
    payload: [],
  });
  dispatch({
    type: types.LOADER_GET_BULLION_LIST,
    payload: true,
  });
  GetBullionListing(id, clientID)
    .then((data) => {
      if (data != undefined) {
        if (data.status == "S") {
          dispatch({
            type: types.GET_BULLION_LIST,
            payload: data,
          });
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "buliionList" }],
          });

          dispatch({
            type: types.LOADER_GET_BULLION_LIST,
            payload: false,
          });
        }
        else {
          dispatch({
            type: types.GET_BULLION_LIST,
            payload: [],
          });
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "buliionList" }],
          });

          dispatch({
            type: types.LOADER_GET_BULLION_LIST,
            payload: false,
          });
        }
      }
      else {
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: 0, api: "buliionList" }],
        });

        dispatch({
          type: types.LOADER_GET_BULLION_LIST,
          payload: false,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: 0, api: "buliionList" }],
      });

      dispatch({
        type: types.LOADER_GET_BULLION_LIST,
        payload: false,
      });
    })
}