import React, { PureComponent } from 'react';
import '../summary/progress.css'
import ErrorBoundary from '../Errorhandling/Errorhandle';
import labels from '../../utils/constants/labels';
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom"; //import Select from '@material-ui/core/Select';
import { NegativeFinding } from '../../utils/commonfunction';
export default class ProgressBarExample extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      percentage: 0
    }
  }
  render() {
    if (this.props.page == "primary") {
      return (

        <ProgressBar {...this.props} />

      )
    } else {
      return (

        <div>
          <div className="ProgressBarBlock">
            <div className="progressBarDiv">
              <div className="progress-bar-title">
                {this.props.text.length >= 16 ? <Tooltip
                  TransitionComponent={Zoom}
                  title={this.props.text}
                >
                  <label style={{ textOverflow: "ellipsis" }}>
                    {Object.keys(this.props.text).length === 0
                      ? labels.messages.NA
                      : this.props.text.substring(0, 16 - 3) + "..."}
                  </label>
                </Tooltip> : this.props.text}
              </div>
              <div>
                <ProgressBar percentage={this.props.percentage} bgcolor={this.props.color} />
              </div>
              <div className="percentagestyle">{`${NegativeFinding(this.props.amount, "", "", "", "rupee")}`}</div>
              <div className="separatepercentage">{NegativeFinding(this.props.percentage, "", "", "", "percentage")}</div>
            </div>
          </div>
          <div className="ProgressBar">
            <div className="progressResponsive">
              <div className="progress-bar-title">{this.props.text} </div>
              <div className="ProgessDesign">
                <ProgressBar percentage={this.props.percentage} bgcolor={this.props.color} />
                <div style={{ fontSize: 12, }}>{`${NegativeFinding(this.props.percentage, "", "", "", "rupee")} (${NegativeFinding(this.props.percentage, "", "", "", "percentage")})`}</div>
              </div>
            </div>
          </div>
        </div>

      )
    }

  }
}
const ProgressBar = (props) => {
  return (
    <div className="progress-bar" style={{ height: props.height, backgroundColor: props.backgroundColor }}>
      <Filler percentage={props.percentage} bgcolor={props.bgcolor} />
    </div>
  )
}
const Filler = (props) => {
  return <div className="filler" style={{ width: `${props.percentage>100?100:props.percentage}%`, backgroundColor: props.bgcolor }} />
}