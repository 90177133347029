import React from 'react';
import Button from '../../components/custombutton/button'
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import colors from '../../utils/colors/colors';
import color from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import DatePicker from '../../components/datepickers/date_pickers';
import CommonDropdown from '../../components/dropdown/dropdownschemes';
import Textinput from '../../components/textinput/textinput';
import moment from 'moment';
import { updatebankdetails, getSearchBranchDetails } from '../../redux/actions/profile/profile'
import { getAIFandPMSDetails } from "../../redux/actions/aifandpms/aifandpms_action";
import { numbersandDeciamlOnly, hasNull, round, notAllowdSpecialCharater, amountCommaSeperator, replaceComma } from '../../utils/commonfunction';
import CommonSnackbar from '../../components/snackbar/snackbar';
import { Listing } from '../../redux/actions/aifandpms/aifandpms_action';
import { bindActionCreators } from 'redux';
import getAddUpdateDeleteAssetTransaction from '../../api/aifandpms/add_update_delete_asset_transaction';
import { addUpdateDeleteTransaction, updateAsset } from '../../redux/actions/aifandpms/aifandpms_action';
import getAddAssetValuation from '../../api/aifandpms/add_asset_valuation';
import updateNPSValuationList from '../../api/nps/nps_update_valuation';
const styles = theme => ({

    col: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.between('320', '374')]: {
            marginTop: '4%',
            width: '94%',
        },
        [theme.breakpoints.between('375', '424')]: {
            marginTop: '4%',
            width: '96%',
        },
        [theme.breakpoints.between('425', '600')]: {
            marginTop: '4%',
            width: '98%',
        },
    },
    cols: {
        marginLeft: '2%',
        marginTop: '5%',
        width: '100%',
        display: "flex", flexDirection: "row",
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: ' wrap',
            justifyContent: 'center',
            marginLeft: '0%',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: ' wrap',
            justifyContent: 'center',
        },
    },
    // col3: {
    //     display: "flex",
    //     flexDirection: "row",modified by dulcy
    //     marginLeft: "20%"
    // },
    colheader1: {
        color: window.globalConfig.color.PRIMARY_BORDER,
        fontSize: "14px",
        textAlign: "left"
    },
    colheader2: {
        textAlign: "left",
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "14px"
    },

    header: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: "white",
        height: 50,
        fontWeight: 500,
        position:"sticky",
        top:"0px"
    },
    txtHeader: {
        padding: 15,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    dialogPaper: {
        //width: "400px",
        // height: "174px",
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    dialogPaper2: {
        width: "auto",
        height: "auto",
    },
    footerStyle: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        bottom: 0,
        right: "18px",
        // position: "absolute",
        paddingBottom: 10,
        justifyContent: "flex-end"
    },
    twoGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)',
        gap: 10,
        padding: 10
    },
    clearIcon: {
        cursor: "pointer"
    }
});
class UpdateNpsEvaluation extends React.Component {
    constructor() {
        super();
        this.state = {
            latestValueDateTemp: moment().format("DD MMM YYYY"),
            latestValueDate: "",
            latestValueDateError: "",
            open: false,
            valuationGoldNav: "",
            valuationGoldNavError: "",
            valuationOtherNav: "",
            valuationOtherNavError: "",
            valuationCommodityNav: "",
            valuationCommodityNavError: "",
            valuationEquityNav: "",
            valuationEquityNavError: "",
            anchorEl: null,
            amount: "",
            flag: "",
            message: "",
            severity: "success",
            openSnackBar: false,
            selectedList: []

        }
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };
    onClosePopup = () => {
        this.clearState()
        this.props.onClose()
    }


    componentDidMount() {
        this.setState({ selectedList:this.props.editList })
    }
    componentDidUpdate(prev) {
        const { editList } = this.props

        if (prev.editList != editList) {

            this.setState({ selectedList: editList })
        }

    }
    handleFocus = (event) => event.target.select();
    handleToastClose = () => {
        this.setState({ openSnackBar: false })
    }
    handleSubmit = (type) => {
        let isValid = true
        if (this.state.latestValueDate == "") {
            isValid = false
            this.setState({ latestValueDateError: labels.messages.required, })
        }
        if (this.state.valuationEquityNav.trim() == "") {
            isValid = false
            this.setState({ valuationEquityNavError: labels.messages.required, })
        } else if(Number(replaceComma(this.state.valuationEquityNav))==0){
            isValid = false
            this.setState({ valuationEquityNavError: labels.messages.Amount})
        }
        if (this.state.valuationCommodityNav.trim() == "") {
            isValid = false
            this.setState({ valuationCommodityNavError: labels.messages.required, })
        }else if(Number(replaceComma(this.state.valuationCommodityNav))==0){
            isValid = false
            this.setState({ valuationCommodityNavError: labels.messages.Amount})
        }
        if (this.state.valuationGoldNav.trim() == "") {
            isValid = false
            this.setState({ valuationGoldNavError: labels.messages.required, })
        }else if(Number(replaceComma(this.state.valuationGoldNav))==0){
            isValid = false
            this.setState({ valuationGoldNavError: labels.messages.Amount})
        }
        if (this.state.valuationOtherNav.trim() == "") {
            isValid = false
            this.setState({ valuationOtherNavError: labels.messages.required, })
        } else if(Number(replaceComma(this.state.valuationOtherNav))==0){
            isValid = false
            this.setState({ valuationOtherNavError: labels.messages.Amount})
        }
        if (isValid) {
          
            let data = {}
            data.clientID = this.props.logindetails.ClientID
            data.npsid = this.state.selectedList.ID.name
            data.veAllocation = Number(replaceComma(this.state.valuationEquityNav))
            data.vcAllocation = Number(replaceComma(this.state.valuationCommodityNav))
            data.vgAllocation = Number(replaceComma(this.state.valuationGoldNav))
            data.voAllocation = Number(replaceComma(this.state.valuationOtherNav))
            data.valuation = 0
            data.valuationDate = this.state.latestValueDate

            if(type=='save'){
                this.setState({loader:true})
            }else{
                this.setState({addMoreLoader:true})
            }
            updateNPSValuationList(data).then((response) => {
                if(type=='save'){
                    this.setState({loader:false})
                    this.onClosePopup()
                }else{
                    this.setState({addMoreLoader:false})
                    this.clearState()
                }
                if (response.status == 'S') {

                    this.setState({ openSnackBar: true, message: response.message, severity: 'success' })
                } else {
                    this.setState({ openSnackBar: true, message: response.message, severity: 'warning' })

                }
                if(this.props.callBack!=undefined){
                    this.props.callBack(response.table1)
                }
               
            }).catch((err) => {
                this.setState({ openSnackBar: true, message: labels.messages.apierror, severity: 'success' })

            })
        }
    }
    handleLatestValueDateChange = (value) => {
        this.setState({ latestValueDateTemp: moment(value).format("DD MMM YYYY") })

    }
    handleLatestValueDateApply = (date) => {
        this.setState({ latestValueDate: moment(date).format("DD MMM YYYY"), latestValueDateError: "" })

    }
    handleLatestValueDateClick = (e) => {
        this.setState({ anchorEl: e.currentTarget })
    }
    handleLatestValueDateClose = () => {
        this.setState({ anchorEl: null })
    }
    handleValuationEquityNavChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(),6)) {
            this.setState({ valuationEquityNav: amountCommaSeperator(removeComma.trim()), valuationEquityNavError: "" })
        }
    }
    handleValuationCommodityNavChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(),6)) {
            this.setState({ valuationCommodityNav:amountCommaSeperator(removeComma.trim()), valuationCommodityNavError: "" })
        }

    }
    handleValuationGoldNavChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(),6)) {
            this.setState({ valuationGoldNav: amountCommaSeperator(removeComma.trim()), valuationGoldNavError: "" })
        }
    }
    handleValuationOtherNavChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(),6)) {
            this.setState({ valuationOtherNav: amountCommaSeperator(removeComma.trim()), valuationOtherNavError: "" })
        }
    }
    clearState=()=>{
        this.setState({
            open: false,
            latestValueDateTemp: moment().format("DD MMM YYYY"),
            latestValueDate: "",
            latestValueDateError: "",
            valuationGoldNav: "",
            valuationGoldNavError: "",
            valuationOtherNav: "",
            valuationOtherNavError: "",
            valuationCommodityNav: "",
            valuationCommodityNavError: "",
            valuationEquityNav: "",
            valuationEquityNavError: "",
            anchorEl: null,
            flag: "",
            message: "",
            severity: "success",
            openSnackBar: false,
            loader: false,
            addSaveMore: false
        })
    }
    render() {
        const { classes } = this.props;
        return (<div>
            <Dialog

                open={this.props.open}
                onClose={this.onClosePopup}
                onScroll={this.props.onScroll}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: classes.dialogPaper }}
                style={this.props.style}

            >
                <div className={classes.popupcard}>
                    <div className={classes.header}>
                        <div className={classes.txtHeader}>{this.props.header}
                            <span className={classes.clearIcon} onClick={this.onClosePopup}>&#x2715;</span>
                        </div>
                    </div>

                    <div className={classes.twoGrid}>
                        <DatePicker
                            handleClick={(e) => this.handleLatestValueDateClick(e)}
                            handleApply={this.handleLatestValueDateApply}
                            showingdate={this.state.latestValueDateTemp}
                            dateChange={this.handleLatestValueDateChange}
                            handleClose={this.handleLatestValueDateClose}
                            anchorEl={this.state.anchorEl}
                            error={this.state.latestValueDateError}
                            maxdate={moment().format("DD MMM YYYY")}
                            id="dp_aifInvestmentDate"
                            label={labels.PmsAndAif.date}
                            placeholder={labels.PmsAndAif.datePlaceholder}
                            date={this.state.latestValueDate} />
                        <div></div>
                        <Textinput handleChange={this.handleValuationEquityNavChange}
                            value={this.state.valuationEquityNav}
                            error={this.state.valuationEquityNavError}
                            otherProps={{ autoFocus: true,  }}
                            align={'right'}
                            page={"normal"} label={labels.Nps.equity}
                        //  placeholder={labels.Nps.latestValuePlaceholder}
                        />
                        <Textinput handleChange={this.handleValuationCommodityNavChange}
                            value={this.state.valuationCommodityNav}
                            error={this.state.valuationCommodityNavError}
                            otherProps={{ autoFocus: true, }}
                            align={'right'}
                            page={"normal"} label={labels.Nps.commodity}
                        //  placeholder={labels.Nps.latestValuePlaceholder}
                        />
                        <Textinput handleChange={this.handleValuationGoldNavChange}
                            value={this.state.valuationGoldNav}
                            error={this.state.valuationGoldNavError}
                            otherProps={{ autoFocus: true }}
                            align={'right'}
                            page={"normal"} label={labels.Nps.gold}
                        //placeholder={labels.Nps.latestValuePlaceholder}
                        />
                        <Textinput handleChange={this.handleValuationOtherNavChange}
                            value={this.state.valuationOtherNav}
                            error={this.state.valuationOtherNavError}
                            otherProps={{ autoFocus: true, }}
                            align={'right'}
                            page={"normal"} label={labels.Nps.other}
                        //  placeholder={labels.Nps.latestValuePlaceholder}
                        />

                    </div>
                    <div className={classes.footerStyle}>

                        {/* {this.props.flag == "Transaction" && this.props.transactionFlag == "update" && <div style={{ width: "22%", marginLeft: 10, marginRight: 15 }}>
                            <Button page={"secondary"} disabled={this.state.loader} name={labels.messages.cancel} onClick={this.onClosePopup} loader={this.props.loader} show={this.props.loader} loading={"true"} />
                        </div>} */}
                        <div style={{ width: "22%", marginRight: 15 }}>
                            <Button page={"primary"} disabled={this.state.loader||this.state.addMoreLoader} name={this.props.transactionFlag == "update" ? "Update" : labels.messages.saveButton} onClick={() => this.handleSubmit("save")} loader={this.props.loader} show={this.props.loader} loading={"true"} />
                        </div>

                        <div style={{ marginLeft: 10, marginRight: 15 }}>
                            <Button page={"primary"} disabled={this.state.loader||this.state.addMoreLoader} name={labels.messages.saveAddMoreButton} onClick={() => this.handleSubmit("addMore")} loader={this.state.addMoreLoader} show={this.state.addMoreLoader} loading={"true"} />
                        </div>

                    </div>
                </div>
            </Dialog>
            <CommonSnackbar message={this.state.message} open={this.state.openSnackBar} severity={this.state.severity} handlesnanckClose={this.handleToastClose} />

        </div>
        )
    }
}
const mapStateToProps = state => ({
    login: state.Login.loginError,
    productList: state.Dataentry.alternativeAsset != undefined && state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset.table12 != "NA" && state.Dataentry.alternativeAsset.table12.length != 0 ?
        state.Dataentry.alternativeAsset?.table12 : [],
    logindetails: state.Login.logindetails,
    transactionTypeList: state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset.length != 0 && state.Dataentry.alternativeAsset.table6 != "NA" && state.Dataentry.alternativeAsset.table6.length != 0 ? state.Dataentry.alternativeAsset.table6.map((item) => {
        return { "label": item.TransactionType, "value": item.ID }
    }) : [],
    aifList: state.AIFandPMS.aifList

})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ updateAsset, addUpdateDeleteTransaction, getAIFandPMSDetails, Listing }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UpdateNpsEvaluation))

