import * as types from "../../constants/actionsType";
import { getAllSummaryDetails } from "../../../api/summary/summaryapi";
import constants from "../../../utils/constants/apiconstant";
import { replaceNull } from "../../../utils/commonfunction";
export function getSummaryDetails(
  ClientID,
  AsOnDate,
  SessionID,
  InstrumentType,
  ProductID,
  FamilyID,
  L5ID,
  L4ID,
  flag
) {
  return (dispatch) => {
    getAllSummaryDetailsAction(
      dispatch,
      ClientID,
      AsOnDate,
      SessionID,
      InstrumentType,
      ProductID,
      FamilyID,
      L5ID,
      L4ID,
      flag
    );
  };
}
function getAllSummaryDetailsAction(
  dispatch,
  ClientID,
  AsOnDate,
  SessionID,
  InstrumentType,
  ProductID,
  FamilyID,
  L5ID,
  L4ID,
  flag
) {
  //  (SessionID)

  if (ProductID === 1) {
    dispatch({
      type: types.LOADER_SUMMARY,
      payload: true,
    });
  } else if (ProductID === 2) {
    dispatch({
      type: types.LOADER_EQUITY_SUMMARY,
      payload: true,
    });
  }

  getAllSummaryDetails(
    SessionID,
    ClientID,
    AsOnDate,

    ProductID,
    FamilyID,
    L5ID,
    L4ID,

  )
    .then((data) => {
      //    (data)

      if (data.status == "S") {
        dispatch({
          type: types.PREVENT_API_CALL,

          payload: [{ ProductID: ProductID, api: "SectorAllocation" }],
        });
        // 
        if (ProductID === 1) {
          dispatch({
            type: types.LOADER_SUMMARY,
            payload: false,
          });


          dispatch({
            type: types.GET_SUMMARY_DATA,
            payload: replaceNull(data.data, "NA") == "NA" ? [] : data.data,
          });
        } else if (ProductID === 2) {
          //Equity
          dispatch({
            type: types.LOADER_EQUITY_SUMMARY,
            payload: false,
          });
          dispatch({
            type: types.EQUITY_SUMMARY,
            payload: replaceNull(data.data, "NA") == "NA" ? [] : data.data,
          });
        }

      } else {

        dispatch({
          type: types.PREVENT_API_CALL,

          payload: [{ ProductID: ProductID, api: "SectorAllocation" }],
        });
        if (ProductID === 1) {
          dispatch({
            type: types.LOADER_SUMMARY,
            payload: false,
          });

          dispatch({
            type: types.GET_SUMMARY_DATA,
            payload: [],
          });
        } else if (ProductID === 2) {
          dispatch({
            type: types.LOADER_EQUITY_SUMMARY,
            payload: false,
          });

          dispatch({
            type: types.EQUITY_SUMMARY,
            payload: [],
          });
        }

      }

    })
    .catch((error) => {
      // console.log(error)
      dispatch({
        type: types.PREVENT_API_CALL,

        payload: [{ ProductID: ProductID, api: "SectorAllocation" }],
      });
      if (ProductID === 1) {
        dispatch({
          type: types.LOADER_SUMMARY,
          payload: false,
        });

        dispatch({
          type: types.GET_SUMMARY_DATA,
          payload: [],
        });
      } else if (ProductID === 2) {
        dispatch({
          type: types.LOADER_EQUITY_SUMMARY,
          payload: false,
        });
        // dispatch({
        //   type: types.PREVENT_API_CALL,
        //   payload: "EquitySectorAllocation",
        // });
        dispatch({
          type: types.EQUITY_SUMMARY,
          payload: [],
        });
      }

      //  ("erroraaaaa", error)
    });
}
export const handleExpand = (rowId, flag, data) => {
  if (flag == "mutualfunds") {
    return {
      type: types.MF_CATEGORY_ALLOCATION_EXPAND,
      rowId,
      data,
    };
  } else if (flag == "equity") {
    return {
      type: types.EQUITY_CATEGORY_ALLOCATION_EXPAND,
      rowId,
      data,
    };
  }
  else if (flag == "portfolio") {
    return {
      type: types.PORTFOLIO_ALLOCATION_EXPAND,
      rowId,
      data,
    }
  }
  else if (flag == constants.Common.Bonds) {
    return {
      type: types.BONDS_CATEGORY_ALLOCATION_EXPAND,
      rowId,
      data,
    };
  }
  else if (flag == constants.Common.GB) {
    return {
      type: types.GB_CATEGORY_ALLOCATION_EXPAND,
      rowId,
      data,
    };
  }
  else if (flag == constants.Common.FD) {
    return {
      type: types.FD_CATEGORY_ALLOCATION_EXPAND,
      rowId,
      data,
    };
  }
  else if (flag == constants.Common.RealEstate) {
    return {
      type: types.REALESTATE_CATEGORY_ALLOCATION_EXPAND,
      rowId,
      data,
    };
  }
  else if (flag == constants.Common.AIF) {
    return {
      type: types.AIF_CATEGORY_ALLOCATION_EXPAND,
      rowId,
      data,
    };
  }
  else if (flag == constants.Common.NPS) {
    return {
      type: types.NPS_CATEGORY_ALLOCATION_EXPAND,
      rowId,
      data,
    };
  } else if (flag == constants.Common.Insurance) {
    return {
      type: types.INSURANCE_CATEGORY_ALLOCATION_EXPAND,
      rowId,
      data,
    };
  }
};
export const GetSummary = (data, instrument, chart) => {
  return {
    type: types.GET_SUMMARY,
    data,
    instrument,
    chart,
  };
};
