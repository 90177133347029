import React, { useState,useEffect } from 'react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
// import '../../stylesheets/common/global/app.css'
import '../../utils/stylesheets/common/global/datepickers/datepickers.css'
import color from "../../utils/colors/colors";
const Datepicker = ({ onDateChange, date, divStyle, minDates, maxDates, }) => {
    const [dates, setdates] = useState(new Date(date))
    var fdate = minDates==""?new Date("2000/01/01"):new Date(minDates);
    var ldate=maxDates==""?new Date("2100/01/01"):new Date(maxDates)
    var firstDay = new Date(fdate.getFullYear(), fdate.getMonth(), fdate.getDate());
    var lastDay = new Date(ldate.getFullYear(), ldate.getMonth(), ldate.getDate());
    // 
    const [minDate, setMinDate] = useState(new Date(minDates != undefined ? firstDay : "2000/01/01"))
    const [maxDate, setMaxDate] = useState(new Date(maxDates != undefined ? lastDay : "2100/01/01"))
    const onChange = (item) => {
        setdates(item)
        onDateChange(item)
    }
    useEffect(() => {
        setdates(new Date(date))
    }, [date])
    if (maxDates != undefined || minDate != undefined) {
        // 
        return (
            <div style={divStyle}>
                <Calendar
                    date={dates}
                    maxDate={maxDate}
                    minDate={minDate}
                    rangeColors={[window.globalConfig.color.RosyOrange]}
                    onChange={item => onChange(item)}
                    color={window.globalConfig.color.BROWN}
                />
            </div>
        )
    }
    else {
        return (
            <div style={divStyle}>
                <Calendar
                    date={dates}
                    color={window.globalConfig.color.BROWN}
                    onChange={item => onChange(item)}
                />
            </div>
        )
    }
    //  ("date",date)

}
export default Datepicker;
