import React from 'react';
import DynamicTable from '../../components/table/dynamictable';
import { Chip } from '@material-ui/core';
import Carousel from '../../components/trading/carousel';
import Tables from '../../components/trading/funddetails';
import DonutChart from '../../components/trading/donutchart';
import { connect } from 'react-redux';
import { getTransactionDetailsListing } from '../../redux/actions/transactiondetails/transactiondetailsaction';
import { bindActionCreators } from 'redux';
import NoData from '../../components/nodata/nodata';
import LineChart from '../../components/trading/linechart';
import lable from '../../utils/constants/labels';
import { SimilarSchemeDetails } from '../../redux/actions/schemes/similarschemesaction';
import { SchemeLinePerformationDetails } from '../../redux/actions/schemes/lineperformancecard'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router'
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import colors from '../../utils/colors/colors';
import UCCPopup from "../../components/popup/uccpopup";
import { NegativeFinding, replaceNull, scrollToTopPage } from '../../utils/commonfunction';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import getTransactionDetails from '../../api/transactiondetails/transactiondetailsapi';
import getsimilarSchemeDetails from '../../api/schemedetails/similarschemes';
import Details_screen from '../../components/template/details_screen';
import getKYCDetails from '../../api/kyc/kycapi';
import EPFReport from '../epf/epfreport';
import getPerformanceDetails from '../../api/schemedetails/performace';
const styles = theme => ({
    header: {
        fontSize: 21,
        fontWeight: 600,
        textAlign: "start",
        color: colors.BLACK_PRIMARY
    },

    cardMenu: {
        marginBottom: "0%",
        marginLeft: "1.8%",
        marginRight: "1.8%",
        // height: 82,
        gridTemplateColumns: "18% 18% 15% 18% 18% 13%",
        alignItems: "baseline",
        // paddingTop: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        "& .data1": {
            height: "48px !important",
            padding: 10,
            borderRight: `1px solid ${colors.BORDER}`,
            [theme.breakpoints.down("900")]: {
                borderBottom: `1px solid ${colors.BORDER}`
            }
        },
        "& .data2": {
            // height: "0px !important",
            padding: 10,
            borderRight: `1px solid ${colors.BORDER}`,
            [theme.breakpoints.down("900")]: {
                borderBottom: `1px solid ${colors.BORDER}`
            }
        },
        "& .data3": {
            padding: 10,
            // height: "0px !important",
            borderRight: `1px solid ${colors.BORDER}`,
            [theme.breakpoints.down("900")]: {
                borderBottom: `1px solid ${colors.BORDER}`
            }
        },
        "& .data4": {
            padding: 10,
            // height: "0px !important",
            borderRight: `1px solid ${colors.BORDER}`,
            [theme.breakpoints.down("900")]: {
                borderBottom: `1px solid ${colors.BORDER}`
            }
        },
        "& .data5": {
            padding: 10,
            // height: "0px !important",
            borderRight: `1px solid ${colors.BORDER}`,
            [theme.breakpoints.down("900")]: {
                borderBottom: `1px solid ${colors.BORDER}`
            }
        },
        [theme.breakpoints.down("900")]: {
            gridTemplateColumns: "100%",

        },


    },
    firstContainer: {
        backgroundColor: window.globalConfig.color.PRIMARY, display: "grid", gridTemplateColumns: "9.5% 81% 9.5%"
    },                             
    schemeAndNavSpliting: {
        display: "grid", gridTemplateColumns: "50% 48%", paddingLeft: "15px",
        [theme.breakpoints.down("900")]: {
            display: "flex",
            flexDirection: "column",
            // gridTemplateColumns: "100%",
            alignItems: "center"
        }

    },
    schemeAndAvatar: {
        display: "flex", flexDirection: "row", alignItems: "center", gap: 10, padding: "15px 0px"
    },
    navRsAndNavDate: {
        display: "flex", flexDirection: "column", gap: 10, alignItems: "flex-end", padding: "15px 0px",
        [theme.breakpoints.down("900")]: {
            alignItems: "flex-start"
        }
    },
    bodyContainer: {
        display: "grid", gridTemplateColumns: "11% 78% 10%", marginBottom: "3%"
    },
    secondContainer: {
        boxShadow: "rgb(19 89 161 / 10%) 0px 13px 20px 0px", borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10, width: '100%', overflowY: "hidden", overflowX: "hidden", borderTop: `1px solid ${colors.BORDER}`
    },
    thirdContainer: {
        boxShadow: "0 0 20px 0 rgba(19, 89, 161, 0.1)", borderRadius: 10, overflowY: "auto", overflowX: "hidden"
    },
    fourthContainer: {
        display: "grid", gridTemplateColumns: "49% 49%", gap: 20,
        [theme.breakpoints.down("900")]: {
            gridTemplateColumns: "100%",
        }
    },
    cardContainer: {
        // minHeight: "450px", 
        boxShadow: "0 0 20px 0 rgba(19, 89, 161, 0.1)", borderRadius: 10, overflowY: "auto",
        overflowX: "hidden"
    },
    performanceAndSideTab: {
        display: "grid", gridTemplateColumns: "66% 34%",
        [theme.breakpoints.down("900")]: {
            gridTemplateColumns: "100%",
        }
    },
    topHoldingDetails: {
        display: "grid",
        gridTemplateColumns: "30% 30% 20% 9%;",
        gap: 10,
        [theme.breakpoints.down("920")]: {

            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateAreas: `"${"dataOne"} ${"dataOne"} ${"dataFive"}"
            "${"dataThree"} ${"dataFour"} ${"dataTwo"}"
           `,
            padding: 10,
            gap: 10,
            "& .dataTwo": {
                alignItems: "end"
            },
            "& .dataFive": {
                alignItems: "end"
            },
            "& .dataThree": {
                textAlign: "left !important"
            },
            "& .dataFour": {
                textAlign: "center !important",
                alignItems: "center"
            }
        }
    },
})
// let LineChartData = []
class Tradingschemedetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showmore: false,
            Performance: [],
            open: true,
            dataLineChart: [],
            schemeCode: "",
            schemeID: "",
            similarSchemes: [],
            schemeDetail: [],
            FundDetail: [],
            returns: [],
            assetAllocation: [],
            sectorAllocation: [],
            topHoldings: [],
            loader: false,
            loaderSimilarSchemes: false,
            data: [],
            performaceChartLoader: false,
            performance3Y: [],
            performance6M: [],
            performance1Y: [],
            performance5Y: [],
            performanceFilter: [{ name: "6M", value: 1, isActive: true, data: [] }, { name: "1Y", value: 2, isActive: false, data: [] }, { name: "3Y", value: 3, isActive: false, data: [] }, { name: "5Y", value: 4, isActive: false, data: [] }]
        }
    }
    getSchemeDetails = (schemecode) => {
        this.setState({ loader: true })
        getTransactionDetails(schemecode).then((response) => {
            let res = replaceNull(response, labels.messages.NA)
            this.setState({ loader: false })
            // if (res.status=="S") {
            let data = res.schemeDetail == "NA" ? null : res.schemeDetail[0]
            // console.log(data,response)
            if (data != null) {
                data.dataOne = window.globalConfig.AmcImage + data?.AMCImage
                data.dataTwo = data?.SchemeName
                data.subDataOne = data?.SchemeType
                data.subDataTwo = data?.Risk
                data.dataThree = data?.NavRs
                data.dataFour = data.navDiffence
                data.dataFive = `(${data.OneDayReturn != "NA" ? data.OneDayReturn : 0}%)`
                data.subDataThree = `Nav as on ${data.NavDate != undefined ? data.NavDate : "NA"}`
            }

            if (res.topHoldings != null && res.topHoldings != "NA" && res.topHoldings.length != 0) {
                this.setState({
                    topHoldings: res.topHoldings.map((item) => {
                        return {
                            ...item, dataOne: item.CompanyName, dataTwo: item.SectorName, dataThree: item.Asset, dataFour: item.HoldPercentage
                        }
                    })
                })
            } else {
                this.setState({ topHoldings: [] })
            }
            this.setState({
                cardData: res.mF_FundDetail == "NA" ? [] : [{ "firstName": "Min SIP Amount", "lastName": NegativeFinding(res.mF_FundDetail[0]?.MinSIPAmount, "", "", "", "rupee") }, { "firstName": "Fund Started", "lastName": res.mF_FundDetail[0]?.InceptDate }, { "firstName": "Expense Ratio", "lastName": res.mF_FundDetail[0]?.ExpenseRatio }, { "firstName": "Fund Manager", "lastName": res.mF_FundDetail[0]?.FundManager }, { "firstName": "Fund Size (Rs. in CR)", "lastName": NegativeFinding(res.mF_FundDetail[0]?.FundSize, "", "", "", "rupee") }, { 'firstName': "CAGR (3 yrs)", "lastName": data?.ThreeYearReturn }],
                schemeDetail: data, FundDetail: res.mF_FundDetail != "NA" ? res.mF_FundDetail[0] : [],
                returns: res.mfReturns != "NA" ? res.mfReturns.slice(0, 2) : [], assetAllocation: res.mF_AssetAllocation != "NA" ? res.mF_AssetAllocation : [], sectorAllocation: res.mF_OtherInformations != "NA" ? res.mF_OtherInformations : []
            })
            // } else {
            //     this.setState({
            //         schemeDetail: [],
            //         FundDetail: [],
            //         returns: [],
            //         assetAllocation: [],
            //         sectorAllocation: [],
            //         topHoldings: []
            //     })
            // }
        })
    }
    getSimilarSchemes = (schemecode) => {
        this.setState({ loaderSimilarSchemes: true })
        getsimilarSchemeDetails(schemecode).then((res) => {
            this.setState({ loaderSimilarSchemes: false })
            if (res.success && res.schemeData != null) {
                let colors = labels.chartColor
                const arr = JSON.parse(res.schemeData);
                const result = arr.map((obj, i) => Object.assign({ color: colors[i] }, obj));
                this.setState({ similarSchemes: result })
            } else {
                this.setState({ similarSchemes: null })
            }
        })
    }
    onClickSchemes = (schemecode) => {
        this.state.Performance = []
        // this.props.SchemeLinePerformationDetails(schemecode)
        let data = this.state.data
        data.MFSchC = schemecode
        this.setState({ data: data })
        this.setState({ schemeCode: schemecode })
        this.getSimilarSchemes(schemecode)
        this.getSchemeDetails(schemecode)
        scrollToTopPage();
    }
    handlekycyesclick = () => {
        getKYCDetails(this.props.logindetails.PhoneNumber,
            this.props.logindetails.EmailID,
            "").then((res) => {
                if (res.status == "F" || res.data == "") {
                    this.setState({ openKyc: true })
                } else {
                    window.open(res.data)
                }
            })
    }
    openKycPopup = () => {
        this.setState({ openKyc: true })
    }
    openUccPopup = () => {
        this.setState({ openUcc: true })
    }
    componentDidUpdate(prev, prevState) {
        const { schemeCode } = this.state;
        if (prevState.schemeCode != schemeCode) {
            this.setState({ performance1Y: [], performance3Y: [], performance5Y: [], performance6M: [] })
            this.lineChartPerfomance("6M")
        }
    }
    componentDidMount() {
        if (this.props.location.state != undefined) {
            scrollToTopPage();

            this.setState({ data: this.props.location.state.data })
            this.setState({ schemeCode: this.props.location.state.data.MFSchC })
            this.getSchemeDetails(this.props.location.state.data.MFSchC)
            this.getSimilarSchemes(this.props.location.state.data.MFSchC)
            this.lineChartPerfomance("6M")
        }
        // console.log(this.props.location.state.data)
    }
    Close = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    }
    onPopupClose = () => {
        this.setState({ openKyc: false, openUcc: false })
    }
    lineChartPerfomance = (year) => {
        this.setState({ performaceChartLoader: true })
        if (this.state.performanceFilter.filter((item) => item.name == year)[0].data.length == 0) {
            getPerformanceDetails(this.props.location.state.data.MFSchC, year).then((res) => {
                this.setState({ performaceChartLoader: false })
                if (res.status == "S") {

                    this.setState({
                        performanceFilter: this.state.performanceFilter.map((val) => {
                            return {
                                ...val, data: val.name == year ? [{
                                    color: colors.PRIMARY,
                                    tooltip: {
                                        valueDecimals: 2,
                                    },
                                    data: res.data.map((item) => {
                                        return [Number(new Date(item.NavDate).valueOf()), item.NAVRs];
                                    })
                                }]
                                    : val.data, isActive: val.name == year
                            }
                        })
                    })
                    // if (year == "1Y" && this.state.performance1Y.length == 0) {
                    //     this.setState({ performance1Y: Boolean(res.data) ? res.data : [] })
                    // }
                    // if (year == "6M" && this.state.performance6M.length == 0) {
                    //     this.setState({ performance6M: Boolean(res.data) ? res.data : [] })

                    // }
                    // if (year == "3Y" && this.state.performance3Y.length == 0) {
                    //     this.setState({ performance3Y: Boolean(res.data) ? res.data : [] })

                    // }
                    // if (year == "5Y" && this.state.performance5Y.length == 0) {
                    //     this.setState({ performance5Y: Boolean(res.data) ? res.data : [] })
                    // }
                }

            })
        } else {
            this.setState({
                performanceFilter: this.state.performanceFilter.map((val) => {
                    return {
                        ...val, isActive: val.name == year
                    }
                })
            })
        }

    }
    render() {
        const { classes } = this.props;
        // let data = [];
        // if (this.state.select == 1 && this.state.performance6M.length != 0) {
        //     data = [{
        //         color: colors.PRIMARY,

        //         tooltip: {
        //             valueDecimals: 2,
        //         },
        //         data: this.state.performance6M.map((val) => {
        //             return [Number(new Date(val.NavDate).valueOf()), val.NAVRs];

        //         })
        //     }]
        // } else if (this.state.select == 2 && this.state.performance1Y.length != 0) {
        //     data = [{
        //         color: colors.PRIMARY,

        //         tooltip: {
        //             valueDecimals: 2,
        //         },
        //         data: this.state.performance1Y.map((val) => {
        //             return [Number(new Date(val.NavDate).valueOf()), val.NAVRs];
        //         })
        //     }]

        // } else if (this.state.select == 3 && this.state.performance3Y.length != 0) {
        //     data = [{
        //         color: colors.PRIMARY,
        //         tooltip: {
        //             valueDecimals: 2,
        //         },
        //         data: this.state.performance3Y.map((val) => {
        //             return [Number(new Date(val.NavDate).valueOf()), val.NAVRs];
        //         })
        //     }]


        // } else if (this.state.select == 4 && this.state.performance5Y.length != 0) {
        //     data = [{
        //         color: colors.PRIMARY,
        //         tooltip: {
        //             valueDecimals: 2,
        //         },
        //         data: this.state.performance5Y.map((val) => {
        //             return [Number(new Date(val.NavDate).valueOf()), val.NAVRs];
        //         })
        //     }]
        // }
        try {
            return <React.Fragment>

                <ErrorBoundary>
                    <Details_screen {...this.state} {...this.props}
                        page={"scheme"}
                        list={this.state.schemeDetail}
                        data={this.state.data}
                        firstHeader="Performance"
                        firstContainer={<LineChart
                            data={this.state.performanceFilter.filter((item) => item.isActive)[0]?.data}
                            filterData={this.state.performanceFilter}
                            isTransactionDetailsLoading={
                                this.state.loader
                            }
                            handleFilter={this.lineChartPerfomance}
                        />}
                        secondHeader={"Invest in this Fund on"}
                        thirdHeader="Returns"
                        openUccPopup={this.openUccPopup}
                        thirdContainer={this.state.returns.length == 0 ? <NoData style={{ height: 30, display: "flex", justifyContent: "center", padding: 20 }}></NoData> : <><Tables returns={this.state.returns} />
                            <Chip className="showmore" onClick={() => { this.setState({ showmore: this.state.showmore == false ? true : false }) }} label={this.state.showmore == false ? lable.tranactionSchemesDetails.SimilarView : lable.tranactionSchemesDetails.SimilarHide}></Chip>
                            {this.state.showmore && <div style={{ backgroundColor: "rgb(239 239 239)", paddingTop: 40, paddingLeft: "10px", paddingRight: "10px", paddingBottom: "40px", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, margin: 1 }}>
                                {this.state.similarSchemes == null ||
                                    this.state.similarSchemes == undefined
                                    ? labels.messages.Dataempty
                                    :
                                    <Carousel data={this.state.similarSchemes} onClick={this.onClickSchemes} />
                                }
                            </div>}</>}
                        // fourthHeader=""
                        fifthContainer={<DonutChart header={"Allocation"} isTransactionDetailsLoading={this.state.loader} data={this.state.assetAllocation.map((item) => {
                            return { name: item.Text, value: item.Value }
                        })} barchartdata={this.state.sectorAllocation} />}
                        fourthContainer={
                            <DynamicTable isTransactionDetailsLoading={this.state.loader} data={this.state.topHoldings}></DynamicTable>

                        }

                    ></Details_screen>

                    <UCCPopup open={this.state.openUcc} onClose={this.onPopupClose}></UCCPopup>
                </ErrorBoundary>

            </React.Fragment >
        } catch (err) {
            // console.log(err)
            return <Redirect to={labels.Path.NotFound} />
        }
    }

}
const mapStateToProps = state => {
    return {
        logindetails: state.Login.logindetails,
        Kycmessage: state.Kyc.Kycmessage,
    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getTransactionDetailsListing, SimilarSchemeDetails, SchemeLinePerformationDetails }, dispatch)
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Tradingschemedetails)));
