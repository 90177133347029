//fd form creation by yogitha
import React, { Component } from "react";
import colors from '../../utils/colors/colors';
import { Redirect } from 'react-router-dom'
import labels from '../../utils/constants/labels';
import { withStyles } from '@material-ui/core';
import Dropdown from '../../components/dropdown/dropdownschemes';
import Textinput from '../textinput/textinput';
import CommonCheckbox from '../../components/checkbox/checkbox';
import DatePicker from '../../components/datepickers/date_pickers';
import GetFDRNumber from '../../api/fixed_deposit/fdr_dropdown';
import Autocomplete from '../../components/autocomplete/autocomplete';
import Button from '../../components/custombutton/button';
import TextArea from '../textarea/textarea';
import { getL5reportDetails } from "../../redux/actions/L5Report/L5ReportAction";
import { NegativeFinding, numbersOnly } from "../../../src/utils/commonfunction";
import { numbersandDeciamlOnly, alphaNumericOnly, replaceComma, amountCommaSeperator } from "../../../src/utils/commonfunction";
import { alphabetSpaceOnly, numberWithCommas } from "../../../src/utils/commonfunction";
import moment from 'moment';
import getFixedDepositAndDebentures from '../../api/fixed_deposit/fd_debentures';
import GetAssetName from "../../api/fixed_deposit/asset_name";
import { bindActionCreators } from 'redux';
import { getAlterativeAsset } from '../../redux/actions/DataEntry/common_dropdown_equity';
import dropdownschemes from "../../components/dropdown/dropdownschemes";
import CommonSnackbar from '../snackbar/snackbar';
import { scrollToTopPage } from '../../utils/commonfunction';
import {
    getTransactionDropdowns, getAssetNameAction,
    // getAssetNameListdata,
    // getFDRNumberList
} from '../../redux/actions/DataEntry/common_dropdown_equity';
// import { AssetNameLists } from '../../redux/actions/DataEntry/asset_name_action';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withRouter } from "react-router";
import PlusIcon from '../../../src/utils/assets/images/plus-46.png';
import InformationTooltip from "../tooltip/informationtooltip";
const styles = theme => ({
    // main container style
    root: {
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '10% 88% 10%',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '10% 88% 10%',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '30px',
            display: 'grid',
            gridTemplateColumns: '5% 100% 0%',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '5px',
            display: 'grid',
            gridTemplateColumns: '4% 100% 0% ',
        },

    },
    // paper card container css
    card: {
        // height: 'calc(100% - 20%)',
        height: 'auto',
        padding: '3% 5% 0% 5%',
        width: "80%",
        margin: "30px 0px 35px 0px",
        boxShadow: 'rgb(19 89 161 / 10%) 0px 0px 20px 0px',
        transition: '0.3s',
        borderRadius: '5px',
        [theme.breakpoints.down('xs')]: {
            marginTop: "10px",
            // height: 'calc(100% - 7%)',
            padding: '3% 5% 3% 5%',
        },
    },
    // herizontal line css
    cardSpareaterLine: {
        height: 0.5,
        width: "100%",
        // backgroundColor: window.globalConfig.color.AliceBlue,
        backgroundColor: window.globalConfig.color.BLACK_PRIMARY,
        marginBottom: '1pc',
        marginTop: '6px',
    },
    // main title css
    header: {
        textAlign: 'left',
    },



    buttonContaniner: {
        display: 'flex',
        flexDirection: 'row',
        margin: '2pc 0px 3pc 0px',
        width: '50%',
        gap: '20px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            margin: '1pc 0px 3pc 0px',
        },
    },
    popupfooterContainer:
    {
        width: '60%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        gap: '10px',
    },

    AssetRow: {
        display: 'grid',
        gap: '10px',
        gridTemplateColumns: "93% 5%",
        minHeight: '90px',
        [theme.breakpoints.down('xs')]: {
            display: 'grid',
            gap: '10px',
            gridTemplateColumns: "91% 5%",
        }
    },
    centerAlignment: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        alignItem: 'center',
        cursor: 'pointer',
        // marginTop: '1.5pc',
    },
    popupWidth: {
        width: '320px',
        [theme.breakpoints.down("xs")]: {
            width: '255px',
        }
    },
    popupheader: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: window.globalConfig.color.WHITE,
        height: 50,
        fontWeight: 500
    },
    txtHeader: {
        padding: 15,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        [theme.breakpoints.down("600")]: {
            padding: '15px 20px 15px 15px',
            justifyContent: "revert",
        },
        [theme.breakpoints.down("600")]: {
            padding: '15px 20px 15px 15px',
            justifyContent: 'space-between',
        },
    },
    footercontainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    clearIcon: {
        cursor: "pointer",
        padding: 0,
        [theme.breakpoints.down("600")]: {
            padding: '0% 0% 0% 30%',
        },
        [theme.breakpoints.down("350")]: {
            padding: '0% 0% 0% 13% !important',

        },
    },
    popupBodyContainer: {
        margin: "4%",
        // [theme.breakpoints.down("xs")]: {
        //     // padding: '3% 0%',
        // },
    },
    popupButtonCantainer: {
        width: '90%',
        display: 'flex',
        textAlign: 'right',
        /* position: relative; */
        alignItems: 'end',
        justifyContent: 'right',
        margin: '0% 4% 3% 8%',
    },
    cardSpareaterLinelight: {
        width: '100%',
        height: '0.5px',
        backgroundColor: window.globalConfig.color.PRIMARY_BORDER,
        marginBottom: '0.8pc',
        marginTop: '6px',
    },
    firstRowContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(3,1fr)',
        columnGap: '20px',
        [theme.breakpoints.down("xs")]: {
            gridTemplateColumns: 'repeat(1,1fr)',
        },
    },
    SecondRowContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(3,1fr)',
        columnGap: '20px',
        [theme.breakpoints.down("xs")]: {
            gridTemplateColumns: 'repeat(1,1fr)',
        },
    },
    scendHeader: {
        textAlign: 'left',
        marginTop: '10px',
    },
    checkBoxContent: {
        margin: '-10px 0px 0px 0px'
    }
});
var path;
var Totals;
var Netvalue;
var WithdrawalNetAmount;
var NetError;
class FdForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: true,
            AssetSample: [],
            lockchecked: false,
            date: moment().format("DD MMM YYYY"),
            defaultdate: moment().format("DD MMM YYYY"),
            anchorEl: null,
            anchorEl1: null,
            anchorEldate: null,
            openpopup: false,
            maturitydate: '',
            lockindate: '',
            maturitydate1: moment().format("DD MMM YYYY"),
            lockindate1: moment().format("DD MMM YYYY"),
            GetAssetNameList: [],
            Autocompletevalue: '',
            AssetId: '',
            Autocompleteerrror: "",
            Assetname: '',
            Assesterror: '',
            // interest textbox value 
            Interestvalue: '',
            errormessageinterest: "",
            // interest textbox value in withdrowal dropdon option click
            Interest: '',
            Interesterror: '',
            //interesttype dropdown
            investmentType: '',
            investmentTypeId: '',
            investmentTypeError: '',
            // interestpayment dropdown
            InterestPaymentListvalue: '',
            interestpaymentId: '',
            InterestPaymentListvalueerror: "",
            //error msg of transaction date
            transactionDate: '',
            Quantityvalue: "",
            errorMsgQuantity: '',
            AmountValue: "",
            // trasaction type dropdown 
            transactionType: "",
            transactionTypeId: '',
            Narrationtextarea: "",
            // principalAmount textbox in fd
            principalAmount: '',
            principalAmounterrorMessage: '',
            // tds textbox in fd
            tds: '',
            tdserrormessage: '',
            // netamount text box in fd
            netamount: '',
            netamounterrormessge: '',
            errormessageQuantity: "",
            errormessageRate: "",
            errormessageAmount: "",
            errormessageAmountdebentures: "",
            typeerror: "",
            // CommonSnackbar errormessage box
            message: "",
            open: false,
            loader: false,
            severity: '',
            InterestTypeList: [
                { label: "Cumulative", value: 0 },
                { label: "Payout", value: 1 }
            ],
            InterestPaymentList: [
                { label: "Yearly", value: 0 },
                { label: "Half Yearly", value: 1 },
                { label: "Quarterly", value: 2 },
                { label: "Monthly", value: 3 }
            ],
            Ratevalue: "",
            errorMsgRate: '',
            issureName: "",
            issureErrorMessage: "",
            // page identitfy the flag
            isdataEntryFlag: ' ',
            Header: "",
            fdrNumber: "",
            fdrTextError: "",
            fdrNewDropDown: "",
            fdrNewError: '',
            fdrList: [],
            fdrNewErrortId: '',
            categoryValue: '',
            categoryValuetId: '',
            categoryError: '',
            fdOldDropDown: '',
            fdOldDropDownValuetId: '',
            fdOldDropDownerror: '',
            isFD: false,
            dummyAssetNameData: '',
            dummyCategoryAssetData: '',
            dummyCategoryAssetCopyList: '',
            categoryProductId: '',
        };
    }
    componentDidMount() {
        document.title = window.globalConfig.companyDetails.companyName + " - Fixed Income"
        scrollToTopPage();
        // GetFDRNumber(this.props.logindetails.ClientID)
        //     .then((resp) => {
        //         if (resp != undefined) {
        //             if (resp.status == "S") {
        //                 let list = resp.data.map((item) => {
        //                     return {
        //                         value: item.ID,
        //                         label: item.Text,
        //                     }
        //                 })
        //                 this.setState({
        //                     fdrList: list,
        //                 })

        //             }
        //             else {
        //                 this.setState({
        //                     fdrList: [],
        //                 })
        //             }
        //         }
        //         else {

        //         }
        //     })
        GetFDRNumber(this.props.logindetails.ClientID)
            .then((resp) => {
                if (resp != undefined && resp.data != null && resp.data != undefined && resp.status != null & resp.status != null) {
                    if (resp.status == "S") {
                        let list = resp.data != null && resp.data != undefined & resp.data != 0 ? resp.data.map((item) => {
                            return {
                                value: item.ID,
                                label: item.Text,
                            }
                        }) :
                            [];
                        this.setState({
                            fdrList: list,
                        })

                    }
                    else {
                        this.setState({
                            fdrList: [],
                        })
                    }
                }
                else {

                }
            })
        let navigationPath = "fd"
        if (this.props.location.pathname == "/Debentures") {
            this.setState({ isdataEntryFlag: "debentures" })
            navigationPath = "debentures"
        } else if (this.props.location.pathname == "/Bonds") {
            this.setState({ isdataEntryFlag: "bonds" })
            navigationPath = "bonds"
        } else {
            this.setState({ isdataEntryFlag: "fd" })
            navigationPath = "fd"
        }

    }
    async componentDidUpdate(prevProps, prevState) {
        const { isdataEntryFlag, Header } = this.state;
        const { location, AssetNameList, AssetNameListBonds, AssetNameListDebencture, CategoryFDAssetNameList, CategoryBondAssetNameList, CategoryDebenturesAssetNameList } = this.props;

        //  if ( location.pathname) {

        let navigationPath = "fd"
        if (location.pathname == "/Debentures") {
            navigationPath = "debentures"
        } else if (location.pathname == "/Bonds") {
            navigationPath = "bonds"
        } else {
            navigationPath = "fd"
        }
        if (this.state.isdataEntryFlag != navigationPath) {

            if (navigationPath == "fd") {
                this.setState({
                    isdataEntryFlag: 'fd',
                    Header: labels.fdForm.fd,
                    // Interestvalue: ""

                });
            } else if (navigationPath == "bonds") {

                this.setState({
                    isdataEntryFlag: 'bonds',
                    Header: labels.fdForm.Bonds,
                    // Interestvalue: ""

                });
            } else {
                this.setState({
                    isdataEntryFlag: 'debentures',
                    Header: labels.fdForm.Debentures,
                    // Interestvalue: ""

                });
            }
            this.cancelClick()
        }
        if (location.pathname == "/trade/FixedDeposit") {

            if (prevProps.AssetNameList != AssetNameList) {
                if (AssetNameList.length != 0) {
                    this.setState({ dummyAssetNameData: AssetNameList })
                }
            }
            if (prevProps.CategoryFDAssetNameList != CategoryFDAssetNameList) {
                if (CategoryFDAssetNameList.length != 0) {
                    this.setState({ dummyCategoryAssetData: CategoryFDAssetNameList })
                }
            }
            // this.cancelClick()
        }
        if (location.pathname == "/Bonds") {
            if (prevProps.AssetNameListBonds != AssetNameListBonds) {
                if (AssetNameListBonds.length != 0) {
                    this.setState({ dummyAssetNameData: AssetNameListBonds })
                }
            }
            if (prevProps.CategoryBondAssetNameList != CategoryBondAssetNameList) {
                if (CategoryBondAssetNameList.length != 0) {
                    this.setState({ dummyCategoryAssetData: CategoryBondAssetNameList })
                }
            }
            // this.cancelClick()
        }
        if (location.pathname == "/Debentures") {
            if (prevProps.AssetNameListDebencture != AssetNameListDebencture) {
                if (AssetNameListDebencture.length != 0) {
                    this.setState({ dummyAssetNameData: AssetNameListDebencture })
                }
            }
            if (prevProps.CategoryDebenturesAssetNameList != CategoryDebenturesAssetNameList) {
                if (CategoryDebenturesAssetNameList.length != 0) {
                    this.setState({ dummyCategoryAssetData: CategoryDebenturesAssetNameList })
                }
            }
            // this.cancelClick()
        }

    }
    componentWillUnmount() {
        this.cancelClick();
    }


    //transaction type dropdown onchange method
    transactionTypeHandleOnChange = (value) => {
        this.setState({
            transactionType: value[0].label,
            transactionTypeId: value[0].value,
            typeerror: '',
            Autocompletevalue: '',
            Autocompleteerrror: '',
            fdrNumber: "",
            fdrTextError: '',
            fdOldDropDownerror: '',
            fdOldDropDownValuetId: '',
            investmentTypeId: '',
            investmentType: '',
            errormessageinterest: '',
            investmentTypeError: '',
            InterestPaymentListvalueerror: '',
            transactionDate: '',
            errormessageAmount: '',
            date: moment().format("DD MMM YYYY"),
            AssetId: '',
            Autocompletevalue: '',
            Interestvalue: '',
            interestpaymentId: '',
            InterestPaymentListvalue: '',
            // checked: '',
            maturitydate: '',
            lockindate: '',
            lockchecked: false,
            Quantityvalue: '',
            Ratevalue: '',
            AmountValue: '',
            Totals: '',
            Narrationtextarea: '',
            errormessageAmountdebentures: '',
            tds: '',
            tdserrormessage: '',
            principalAmount: "",
            Interest: "",
            AmountValue: "",
            principalAmounterrorMessage: '',
            errorMsgRate: '',
            errorMsgQuantity: '',
            Interesterror: '',
            netamount: '',
            netamounterrormessge: '',
            errormaturitydatedata: '',
            lockindateerrormsg: '',
            fdrTextError: '',
            dummyAssetNameData: '',
            dummyCategoryAssetData: '',
            fdOldDropDownValuetId: "",
            fdOldDropDown: "",
        })
        // this.cancelClick();
    }
    // asset dropdown onchange
    AssethandleOnChange = (value) => {
        this.setState({
            Autocompletevalue: value[0].label,
            AssetId: value[0].value,
            Autocompleteerrror: '',
        })
    }
    // Asset name popup open
    handleClickOpen = () => {
        this.setState({
            openpopup: true,
            Assetname: '',
        })
    };
    // Asset name popup close
    handleClose = () => {
        this.setState({
            openpopup: false,
            Assesterror: '',
            issureErrorMessage: '',
            categoryError: '',
            categoryValue: '',
            issureName: '',
        })
    };
    //category dropdown onchange
    categoryHandleChange = (value) => {
        // this.props.CategoryFDAssetNameCopy.table6.ID!=value[0].value:this.props.location.pathname == "/Debentures"?this.props.CategoryDebenturesAssetNameCopy.table6.ID==value[0].value:this.props.CategoryDebenturesAssetNameCopy.table6.ID==value[0].value
        this.setState({
            categoryValue: value[0].label,
            categoryValuetId: value[0].value,
            categoryError: '',
        })
    }
    // asset name popup textbox 
    AssetTextboxhandleChange(event) {
        if (alphabetSpaceOnly(event.target.value)) {
            if (event.target.value.length > 50) {
                event.preventDefault();
            }
            this.setState({
                Assetname: event.target.value,
                Assesterror: '',
            });
        }
    }
    // add asset popup issureName textbox
    IssureTextboxhandleChange(event) {
        if (alphabetSpaceOnly(event.target.value)) {
            if (event.target.value.length > 10) {
                event.preventDefault();
            }
            this.setState({
                issureName: event.target.value,
                issureErrorMessage: '',
            });
        }
    }
    // oldfdrdropdown onChange method
    fdrOldHandleChange = (value) => {
        this.setState({
            fdOldDropDown: value[0].label,
            fdOldDropDownValuetId: value[0].value,
            fdOldDropDownerror: '',
        })
    }
    // fdr textbox onchange
    fdrHandleChange(event) {
        if (alphaNumericOnly(event.target.value)) {
            if (event.target.value.length > 16) {
                event.preventDefault();
            }

            var value = event.target.value
            this.setState({
                fdrNumber: value,
                fdrTextError: '',

            });

        }
    }
    // interest textbox onchange method
    interesthandleChange(event) {
        if (numbersandDeciamlOnly(event.target.value)) {
            if (event.target.value.length > 10) {
                event.preventDefault();
            }
            var value = event.target.value
            this.setState({
                Interestvalue: value,
                errormessageinterest: '',
            });

        }
    }
    // //tansaction block interest textbox onChanges method
    interestTextboxHandleChanges(event) {
        if (numbersandDeciamlOnly(event.target.value)) {
            if (event.target.value.length > 5) {
                event.preventDefault();
            }
            var value = event.target.value
            this.setState({
                Interest: value,
                Interesterror: '',
            });

        }
    }
    // investment dropdown onchange 
    investmentTypehandlechange = (value) => {
        this.setState({
            investmentType: value[0].label,
            investmentTypeId: value[0].value,
            investmentTypeError: '',
        })
    }
    // //interestpayment onchange method
    InterestPaymenthandlecange = (value) => {
        this.setState({
            InterestPaymentListvalue: value[0].label,
            interestpaymentId: value[0].value,
            InterestPaymentListvalueerror: '',

        })
    }
    // maturity date felid datepicker onchange method
    dateChange = (value) => {
        this.setState({ maturitydate1: moment(value).format("DD MMM YYYY") })
    }
    // maturity date feild onclik function
    handleClick = (e) => {
        this.setState({ anchorEl: e.currentTarget })
    }
    // maturityhandleApply date applay onclick funtion
    maturityhandleApply = (date) => {
        this.setState({
            maturitydate: moment(date).format("DD MMM YYYY"), anchorEl: null,
            errormaturitydatedata: '',
        })
    }
    // maturityhandleApply date feild close popup method
    handleClose1 = () => {
        this.setState({ anchorEl: null })
    }
    // lockin onchange method 
    lockingOnChange = () => {
        this.setState({ lockchecked: !this.state.lockchecked })
    }
    // lockin date picker click method
    LockhandleClick = (e) => {
        if (!this.state.lockchecked) {
        } else {
            this.setState({ anchorEl1: e.currentTarget })
        }
    }
    //lockin datepicker apply click method
    lockinhandleApply = (date) => {
        this.setState({
            lockindate: moment(date).format("DD MMM YYYY"), anchorEl1: null,
            lockindateerrormsg: ""
        })
    }
    // lockin datepicker onchange method
    LockindateChange = (value) => {
        this.setState({
            lockindate1: moment(value).format("DD MMM YYYY"),
            lockindateerrormsg: ''
        })
    }
    // lockin datepicker popup close method
    LockinhandleClose = () => {
        this.setState({ anchorEl1: null })
    }

    // current date onclik method
    defaultdateClick = (e) => {
        if (!this.state.date) {
        } else {
            this.setState({
                anchorEldate: e.currentTarget,
                transactionDate: '',
            })
        }
    }
    // current date datepicker apply click method
    defaultdateApply = (date) => {
        this.setState({
            defaultdate: moment(date).format("DD MMM YYYY"),
            anchorEldate: null,
            transactionDate: ""
        })
    }
    // current date datepicker popup close method
    defaulthandleClose = () => {
        this.setState({ anchorEldate: null })
    }
    // current date datepicker onchange method
    defaultdateChange = (value) => {
        this.setState({
            date: moment(value).format("DD MMM YYYY"),
            transactionDate: '',
        })
    }
    // Quantity onchange method
    quantityHandleChange(event) {
        if (numbersandDeciamlOnly(event.target.value)) {
            if (event.target.value.length > 10) {
                event.preventDefault();
            }
            this.setState({
                Quantityvalue: event.target.value,
                errorMsgQuantity: '',
            });
        }
    }
    // rate textbox onchange method
    rateHandleChange(event) {
        if (numbersandDeciamlOnly(event.target.value)) {

            this.setState({
                Ratevalue: event.target.value,
                errorMsgRate: '',
            });
        }
    }
    // amount textbox onchange method
    amountHandleChange(event) {
        let removeComma = event.target.value == "" ? event.target.value : event.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnly(removeComma.trim(), 14)) {
            // if (removeComma.length > 10) {
            //     event.preventDefault();
            // }
            this.setState({
                AmountValue: amountCommaSeperator(removeComma.trim()),
                errormessageAmount: '',

            });

        }
    }
    // principle amount onchange method
    principalAmounthandleChange(event) {
        let removeComma = event.target.value == "" ? event.target.value : event.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnly(removeComma.trim(), 14)) {
            this.setState({
                principalAmount: amountCommaSeperator(removeComma.trim()),
                errormessageAmount: '',

            });

        }
    }
    // interest textbox onchange method
    interestTextboxHandleChange(event) {
        let removeComma = event.target.value == "" ? event.target.value : event.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnly(removeComma)) {
            if (removeComma.length > 10) {
                event.preventDefault();
            }
            var value = removeComma
            this.setState({
                Interest: value,
                Interesterror: '',
            });

        }
    }
    // tds amount onchange method
    tdsHandleChange(event) {
        let removeComma = event.target.value == "" ? event.target.value : event.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnly(removeComma.trim(), 14)) {
            // if (removeComma.length > 10) {
            //     event.preventDefault();
            // }
            this.setState({
                tds: amountCommaSeperator(removeComma.trim()),
                tdserrormessage: '',
            });

        }
    }
    //netamounthandleChange amount onchange method
    netAmountHandleChange(event) {
        let removeComma = event.target.value == "" ? event.target.value : event.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnly(removeComma.trim(), 14)) {
            if (this.state.tds != "" || this.state.principalAmount != "" || this.state.Interest != "") {
                this.setState({
                    netamount: amountCommaSeperator(removeComma.trim()),
                    netamounterrormessge: '',
                });
            }
        }
        // if (numbersandDeciamlOnly(removeComma.trim(), 14)) {
        //     // if (removeComma.length > 10) {
        //     //     event.preventDefault();
        //     // }
        //     this.setState({
        //         netamount: amountCommaSeperator(removeComma.trim()),
        //         netamounterrormessge: '',
        //     });

        // }
    }
    // Narration textarea onchange
    narrationTextareaHandleChange = (event) => {
        this.setState({
            Narrationtextarea: event.target.value,
            errormessageNarration: '',
        });
    }
    // AssetName add method
    AssetNameClick = () => {
        this.setState({
            Autocompleteerrror: '',
        })
        var assetNameTextBoxValid = false;
        var categoryListData = false;
        var issureTextBoxValid = false;
        // Asset Name Popup
        if (this.state.Assetname == undefined || this.state.Assetname == "" || this.state.Assetname == null) {
            assetNameTextBoxValid = false;
            this.setState({
                Assesterror: labels.messages.required,
            })
        }
        else {
            assetNameTextBoxValid = true;
            this.setState({
                Assesterror: '',
            })
        }
        //Asset popup category dropdown
        if (this.state.categoryValue == undefined || this.state.categoryValue == "") {
            categoryListData = false;
            this.setState({
                categoryError: labels.messages.required,
            })
        }
        else {
            categoryListData = true;
            this.setState({
                categoryError: '',
            })
        }
        // Asset  Popup Issuer Name textbox
        if (this.state.issureName == undefined || this.state.issureName == "" || this.state.issureName == null) {
            issureTextBoxValid = false;
            this.setState({
                issureErrorMessage: labels.messages.required,
            })
        }
        else {
            issureTextBoxValid = true;
            this.setState({
                issureErrorMessage: '',
            })
        }

        let data = {
            ClientID: this.props.logindetails.ClientID,
            AssetName: this.state.Assetname,
            ProductID: this.props.catagories.length > 0 ? this.props.catagories.filter((item) => item.ID == this.state.categoryValuetId)[0]["ProductID"] : 0,
            CategoryID: this.state.categoryValuetId,
            IssuerName: this.state.issureName,
            Type: 0,
        }
        if (assetNameTextBoxValid == true && categoryListData == true && issureTextBoxValid == true) {
            this.setState({ loader: true })
            GetAssetName(data).then((resp) => {
                this.setState({ loader: true })
                if (resp.status === "S") {
                    this.setState({ loader: false })
                    let a = resp.data.map((item) => {
                        return {
                            value: item.AssetID,
                            label: item.AssetName,
                        }
                    })
                    this.props.getAssetNameAction(resp.data[0].AssetID, resp.data[0].AssetName, resp.data[0].AssetClassCode)
                    var assetNameLabel = a.filter((item) => this.state.Assetname == item.label)
                    this.setState({
                        Autocompletevalue: assetNameLabel[0].label,
                        AssetId: assetNameLabel[0].value,

                    })

                    this.setState({
                        message: labels.messages.AssetSuccess,
                        Autocompletevalue: this.state.Assetname,
                        openpopup: false,
                        open: true,
                        severity: "success",
                        issureName: '',
                        categoryValuetId: '',
                    });
                }
                else {
                    this.setState({
                        message: resp.message,
                        loader: false,
                        open: true,
                        openpopup: false,
                        severity: "warning",
                        issureName: '',
                        categoryValuetId: '',

                    });
                }
            })
                .catch((err) => {
                    this.setState({
                        open: true,
                        message: labels.messages.apierror,
                        severity: 'warning'
                    })
                })
        }
        else {

        }
    }

    // cancel button click
    cancelClick = () => {
        this.setState({
            typeerror: '',
            Autocompletevalue: '',
            Autocompleteerrror: '',
            fdrNumber: "",
            fdrTextError: '',
            fdOldDropDownerror: '',
            fdOldDropDownValuetId: '',
            investmentTypeId: '',
            investmentType: '',
            transactionType: "",
            transactionTypeId: 1,
            errormessageinterest: '',
            investmentTypeError: '',
            InterestPaymentListvalueerror: '',
            transactionDate: '',
            errormessageAmount: '',
            date: moment().format("DD MMM YYYY"),
            AssetId: '',
            Autocompletevalue: '',
            Interestvalue: '',
            interestpaymentId: '',
            InterestPaymentListvalue: '',
            // checked: '',
            maturitydate: '',
            lockindate: '',
            lockchecked: false,
            Quantityvalue: '',
            Ratevalue: '',
            AmountValue: '',
            Totals: '',
            Narrationtextarea: '',
            errormessageAmountdebentures: '',
            tds: '',
            tdserrormessage: '',
            principalAmounterrorMessage: '',
            errorMsgRate: '',
            errorMsgQuantity: '',
            Interesterror: '',
            netamount: '',
            netamounterrormessge: '',
            errormaturitydatedata: '',
            lockindateerrormsg: '',
            fdrTextError: '',
            dummyAssetNameData: '',
            dummyCategoryAssetData: '',
        });
        if (this.state.isdataEntryFlag == "debentures") {
            let transactionTypeList = this.props.debentureTransactionTypedropdownvalues
            if (transactionTypeList.length != 0) {
                this.setState({ transactionType: transactionTypeList[0].label })
            } else {
                this.setState({ transactionType: "" })
            }
        } else {
            let transactionTypeList = this.props.transactionTypedropdownvalues
            if (transactionTypeList.length != 0) {
                this.setState({ transactionType: transactionTypeList[0].label })
            }
            else {
                this.setState({ transactionType: "" })
            }
        }
    }
    // Submit click function
    submitClick = () => {
        // varabible declaration
        var transactiontypedata = false;
        var Autocompleteassetdata = false;
        var investmentdata = false;
        var fdOldDropDowndata = false;
        var fdrNewDropDowndata = false;
        var investmentTypedata = false;
        var InterestPaymentListdata = false;
        var datedata = false;
        var amountdata = false;
        var amountdebentures = false;

        var tdsdata = false;
        var principalAmountdata = false;
        var WithdrawalNetAmountdata = false;
        var Netvaluedata = false;
        var interestdata = false;
        var QuarterlyData = false;
        var Ratedata = false;
        var maturitydatedata = false;
        var lockindata = false;
        var Netvalueamountdata = false;
        // transactiontype dropdown
        if (this.state.transactionType == undefined || this.state.transactionType == "") {
            transactiontypedata = false;
            this.setState({
                typeerror: labels.messages.required,
            })
        }
        else {
            transactiontypedata = true;
            this.setState({
                typeerror: '',
            })
        }
        // asset name Autocomplete
        if (this.state.Autocompletevalue == undefined || this.state.Autocompletevalue == "") {
            Autocompleteassetdata = false;
            this.setState({
                Autocompleteerrror: labels.messages.required,
            })
        }
        else {
            Autocompleteassetdata = true;
            this.setState({
                Autocompleteerrror: '',
            })
        }
        if (this.state.transactionTypeId != 4) {
            // new fdr number textbox validation
            if (this.state.fdrNumber == undefined || this.state.fdrNumber == "") {
                fdrNewDropDowndata = false;
                this.setState({
                    fdrTextError: labels.messages.required,
                })
            }
            else {
                fdrNewDropDowndata = true;
                this.setState({
                    fdrTextError: '',
                })
            }
        } else {
            fdrNewDropDowndata = true;
        }

        // old fdr number dropdown validation
        if (this.state.fdOldDropDown == undefined || this.state.fdOldDropDown == "") {
            fdOldDropDowndata = false;
            this.setState({
                fdOldDropDownerror: labels.messages.required,
            })
        }
        else {
            fdOldDropDowndata = true;
            this.setState({
                fdOldDropDownerror: '',
            })
        }
        // interest value textbox
        if (this.state.Interestvalue <= 0 || this.state.Interestvalue == undefined || this.state.Interestvalue == "") {
            investmentdata = false;
            this.setState({
                errormessageinterest: labels.messages.required,
            })
        }
        else {
            investmentdata = true;
            this.setState({
                errormessageinterest: "",
            })
        }
        // investmentType dropdown
        if (this.state.investmentType == undefined || this.state.investmentType == "") {
            // 
            investmentTypedata = false;
            this.setState({
                investmentTypeError: labels.messages.required,
            })
        }
        else {
            investmentTypedata = true;
            // 
            this.setState({
                investmentTypeError: '',
            })
        }
        // InterestPaymentListvalue dropdown
        if (this.state.InterestPaymentListvalue == undefined || this.state.InterestPaymentListvalue == "") {
            // 
            InterestPaymentListdata = false;
            this.setState({
                InterestPaymentListvalueerror: labels.messages.required,
            })
        }
        else {
            InterestPaymentListdata = true;
            // 
            this.setState({
                InterestPaymentListvalueerror: '',
            })
        }
        // maturitydate validation in fd and debentures
        if (this.state.maturitydate == "" || this.state.maturitydate == undefined || this.state.maturitydate == 'DD MMM YYYY') {
            maturitydatedata = false;
            this.setState({
                errormaturitydatedata: labels.messages.required,
            })
        }
        else {
            maturitydatedata = true;
            this.setState({
                errormaturitydatedata: '',
            })
        }
        // lockindate validation in fd and debentures pages
        if (this.state.lockchecked == true) {

            if (this.state.lockindate == "" || this.state.lockindate == undefined || this.state.lockindate == 'DD MMM YYYY') {
                lockindata = false;
                this.setState({
                    lockindateerrormsg: labels.messages.required,
                })
            }
            else {
                lockindata = true;
                this.setState({
                    lockindateerrormsg: '',
                })
            }
        }
        else {
            lockindata = true;
            this.setState({
                lockindateerrormsg: '',
            })
        }
        //date calender
        if (this.state.date == undefined || this.state.date == "") {
            datedata = false;
            this.setState({
                transactionDate: labels.messages.required,
            })
        }
        else {
            datedata = true;
            this.setState({
                transactionDate: '',
            })
        }
        // Qulantity textbox
        if (this.state.Quantityvalue == "" || this.state.Quantityvalue == undefined) {
            QuarterlyData = false;
            this.setState({
                errorMsgQuantity: labels.messages.required,
            })
        }
        else {
            QuarterlyData = true;
            this.setState({
                errorMsgQuantity: '',
            })
        }
        // rate textbox
        if (this.state.Ratevalue <= 0 || this.state.Ratevalue == "" || this.state.Ratevalue == undefined) {
            Ratedata = false;
            this.setState({
                errorMsgRate: labels.messages.required,
            })
        }
        else {
            Ratedata = true;
            this.setState({
                errorMsgRate: '',
            })
        }
        if (this.state.isdataEntryFlag == 'debentures') {
            //debentures Amount textbox
            if ((this.state.transactionTypeId == 1 || this.state.transactionTypeId == 4) && this.stateTotals <= 0 || Totals == "" || Totals == undefined) {
                amountdebentures = false;
                this.setState({
                    errormessageAmount: labels.messages.required,
                })
            }
            else {
                amountdebentures = true;
                this.setState({
                    errormessageAmount: '',
                })
            }
            if ((this.state.transactionTypeId == 2 || this.state.transactionTypeId == 3) && this.state.AmountValue <= 0 || this.state.AmountValue == " " || this.state.AmountValue == undefined) {
                amountdata = false;
                this.setState({
                    errormessageAmount: labels.messages.required,
                })
            } else {
                amountdata = true;
                this.setState({
                    errormessageAmount: '',
                })
            }
        }
        if (this.state.isdataEntryFlag == 'fd' || this.state.isdataEntryFlag == 'bonds') {
            if (this.state.AmountValue <= 0 || this.state.AmountValue == " " || this.state.AmountValue == undefined) {
                amountdata = false;
                this.setState({
                    errormessageAmount: labels.messages.required,
                })
            } else {
                amountdata = true;
                this.setState({
                    errormessageAmount: '',
                })
            }

            if (this.state.transactionTypeId == 4) {
                //principle Amount textbox
                if (this.state.principalAmount <= 0 || this.state.principalAmount == undefined || this.state.principalAmount == " ") {
                    principalAmountdata = false;
                    this.setState({
                        errormessageAmount: labels.messages.required,
                    })
                }
                else {
                    principalAmountdata = true;
                    this.setState({
                        errormessageAmount: '',
                    })
                }
            }
        }
        //transaction interest validation
        if (this.state.Interest <= 0 || this.state.Interest == " " || this.state.Interest == undefined) {
            interestdata = false;
            this.setState({
                Interesterror: labels.messages.required,

            })
        }
        else {
            interestdata = true;
            this.setState({
                Interesterror: '',

            })
        }
        // tds validation
        if (this.state.tds <= 0 || this.state.tds == undefined || this.state.tds == "") {
            tdsdata = false;
            this.setState({
                tdserrormessage: labels.messages.required,
            })
        }
        else {
            tdsdata = true;
            this.setState({
                tdserrormessage: '',
            })
        }
        //net amount calculation withdrawel (-Net Amount = From Principal Amount + Interest – TDS)
        if ((this.state.isdataEntryFlag == 'bonds' || this.state.isdataEntryFlag == 'fd') && this.state.transactionTypeId == 4) {
            if (WithdrawalNetAmount <= 0 || WithdrawalNetAmount == "" || WithdrawalNetAmount == undefined || this.state.tds <= 0 || this.state.tds == undefined || this.state.tds == "") {
                WithdrawalNetAmountdata = false;
                if (WithdrawalNetAmount == "" && this.state.tds == "") {
                    this.setState({
                        netamounterrormessge: labels.messages.required,
                    })
                }
            }
            else {
                WithdrawalNetAmountdata = true;
                NetError = '';
                this.setState({
                    netamounterrormessge: '',
                })
            }
        }
        // Net Amount = Amount – TDS
        if ((this.state.isdataEntryFlag == 'bonds' || this.state.isdataEntryFlag == 'fd') && this.state.transactionTypeId == 1 || this.state.transactionTypeId == 2 || this.state.transactionTypeId == 3 || this.state.transactionTypeId == 5) {
            if (Netvalue <= 0 || Netvalue == "" || Netvalue == undefined) {
                Netvaluedata = false;
                this.setState({
                    netamounterrormessge: labels.messages.required,
                })
            }
            else {
                Netvaluedata = true;
                NetError = "",
                    this.setState({
                        netamounterrormessge: '',
                    })
            }
        }
        if (this.state.isdataEntryFlag == 'debentures') {
            if (this.state.netamount <= 0 || this.state.netamount == "" || this.state.netamount == undefined) {
                Netvalueamountdata = false;
                this.setState({
                    netamounterrormessge: labels.messages.required,
                })
            }
            else {
                Netvalueamountdata = true;
                NetError = "",
                    this.setState({
                        netamounterrormessge: '',
                    })
            }
        }
        // validation process
        var isValidData = false;
        if (this.state.isdataEntryFlag == 'bonds' || this.state.isdataEntryFlag == 'fd') {
            if (this.state.transactionTypeId == 1) {
                if (
                    transactiontypedata &&
                    Autocompleteassetdata &&
                    fdrNewDropDowndata &&
                    investmentdata &&
                    investmentTypedata &&
                    InterestPaymentListdata &&
                    maturitydatedata &&
                    lockindata &&
                    datedata &&
                    amountdata) {
                    isValidData = true;
                }
                else {
                    isValidData = false;
                }

            }
            else if (this.state.transactionTypeId == 5) {
                if (
                    transactiontypedata &&
                    Autocompleteassetdata &&
                    fdOldDropDowndata &&
                    fdrNewDropDowndata &&
                    investmentdata &&
                    investmentTypedata &&
                    InterestPaymentListdata &&
                    maturitydatedata &&
                    lockindata &&
                    datedata &&
                    amountdata) {
                    isValidData = true;
                }
                else {
                    isValidData = false;
                }
            }
            // Interest(Cumulative) and Interest(Payout) validation in fd
            else if (this.state.transactionTypeId == 2 || this.state.transactionTypeId == 3) {
                if (transactiontypedata &&
                    fdOldDropDowndata &&
                    datedata &&
                    amountdata &&
                    tdsdata &&
                    Netvaluedata) {
                    isValidData = true;
                }
                else {
                    isValidData = false;
                }
            }

            // Withdrawal validation  in fd 
            else if (this.state.transactionTypeId == 4) {
                if (transactiontypedata &&
                    fdrNewDropDowndata &&
                    datedata &&
                    principalAmountdata &&
                    interestdata &&
                    tdsdata &&
                    WithdrawalNetAmountdata) {
                    isValidData = true;
                }
                else {
                    isValidData = false;
                }
            }

        }
        else {
            // Buy and Sell validation in debentures
            if (this.state.transactionTypeId == 1) {
                if (
                    transactiontypedata &&
                    Autocompleteassetdata &&
                    investmentdata &&
                    investmentTypedata &&
                    InterestPaymentListdata &&
                    maturitydatedata &&
                    lockindata &&
                    datedata &&
                    amountdebentures &&
                    QuarterlyData &&
                    Ratedata
                ) {
                    isValidData = true;
                }
                else {
                    isValidData = false;
                }
            }
            else if (this.state.transactionTypeId == 2 || this.state.transactionTypeId == 3) {
                if (transactiontypedata && Autocompleteassetdata && datedata && amountdebentures && tdsdata && Netvalueamountdata) {
                    isValidData = true;
                }
                else {
                    isValidData = false;
                }
            }
            else {
                if (
                    transactiontypedata &&
                    Autocompleteassetdata &&
                    investmentdata &&
                    investmentTypedata &&
                    InterestPaymentListdata &&
                    maturitydatedata &&
                    lockindata &&
                    datedata &&
                    amountdebentures &&
                    QuarterlyData &&
                    Ratedata
                ) {
                    isValidData = true;
                }
                else {

                    isValidData = false;
                }
            }
        }

        if (isValidData) {

            let data;
            if (this.state.transactionTypeId == 1 || this.state.transactionTypeId == 5) {
                data = {
                    FDNCDDebentureID: 0,
                    ClientID: this.props.logindetails.ClientID,
                    StockTypeID: this.state.isdataEntryFlag == 'fd' ? 1 : this.state.isdataEntryFlag == 'debentures' ? 2 : 3,
                    TransTypeID: this.state.transactionTypeId,
                    NewFDRNumber: this.state.isdataEntryFlag == 'fd' || this.state.isdataEntryFlag == "bonds" ? this.state.fdrNumber : "",
                    OldFDRNumberID: (this.state.transactionTypeId == 5 && (this.state.isdataEntryFlag == 'fd' || this.state.isdataEntryFlag == "bonds")) ? this.state.fdOldDropDownValuetId : 0,
                    TransDate: this.state.date,
                    AssetID: this.state.AssetId == "" ? 0 : this.state.AssetId,
                    InterestRate: Number(this.state.Interestvalue),
                    InterestTypeID: this.state.investmentTypeId,
                    InterestPaymentID: this.state.interestpaymentId,
                    IsMaturityDateKnown: this.state.checked,
                    MaturityDate: this.state.maturitydate == 'DD MMM YYYY' ? "" : this.state.maturitydate,
                    IsLockInPeriodKnown: this.state.lockchecked,
                    LockInPeriodDate: this.state.lockindate,
                    Quantity: (this.state.isdataEntryFlag == 'fd' || this.state.isdataEntryFlag == "bonds") ? 0 : Number(this.state.Quantityvalue),
                    Rate: (this.state.isdataEntryFlag == 'fd' || this.state.isdataEntryFlag == "bonds") ? 0 : Number(this.state.Ratevalue),
                    Amount: (this.state.isdataEntryFlag == 'fd' || this.state.isdataEntryFlag == "bonds") ? Number(replaceComma(this.state.AmountValue)) : this.state.transactionTypeId == 2 || this.state.transactionTypeId <= 3 ? Number(replaceComma(this.state.AmountValue)) : Number(replaceComma(Totals)),
                    Narration: this.state.Narrationtextarea
                }
            }

            else if (this.state.transactionTypeId > 1) {
                data = {
                    FDNCDDebentureID: 0,
                    ClientID: this.props.logindetails.ClientID,
                    StockTypeID: this.state.isdataEntryFlag == 'fd' ? 1 : this.state.isdataEntryFlag == 'debentures' ? 2 : 3,
                    TransTypeID: this.state.transactionTypeId,
                    TransDate: this.state.date,
                    AssetID: this.state.AssetId == "" ? 0 : this.state.AssetId,
                    NewFDRNumber: "",
                    OldFDRNumberID: (this.state.isdataEntryFlag == 'fd' || this.state.isdataEntryFlag == "bonds") ? this.state.fdOldDropDownValuetId : 0,
                    Interest: Number(this.state.Interestvalue) == "" ? 0 : Number(this.state.Interestvalue),
                    FromPrincipalAmount: (this.state.isdataEntryFlag == 'fd' || this.state.isdataEntryFlag == "bonds") && this.state.transTypeID == 4 ? Number(this.state.principalAmount) : Number(this.state.AmountValue),
                    TDS: (this.state.isdataEntryFlag == 'fd' || this.state.isdataEntryFlag == "bonds") ? Number(replaceComma(this.state.tds)) : (this.state.transactionTypeId >= 2 && this.state.transactionTypeId <= 3) ? Number(replaceComma(this.state.tds)) : 0,
                    NetAmount: (this.state.isdataEntryFlag == 'fd' || this.state.isdataEntryFlag == "bonds") ? this.state.transactionTypeId == 4 ? Number(replaceComma(WithdrawalNetAmount)) : Number(replaceComma(Netvalue)) : (this.state.transactionTypeId >= 2 && this.state.transactionTypeId <= 3) ? Number(replaceComma(this.state.netamount)) : 0,
                    Quantity: this.state.transactionTypeId == 4 && this.state.isdataEntryFlag == 'debentures' ? Number(this.state.Quantityvalue) : 0,
                    Rate: this.state.transactionTypeId == 4 && this.state.isdataEntryFlag == 'debentures' ? Number(this.state.Ratevalue) : 0,
                    Amount: (this.state.isdataEntryFlag == 'fd' || this.state.isdataEntryFlag == "bonds") ? Number(replaceComma(this.state.AmountValue)) : this.state.transactionTypeId == 2 || this.state.transactionTypeId <= 3 ? Number(replaceComma(this.state.AmountValue)) : Number(replaceComma(Totals)),
                    Narration: this.state.Narrationtextarea,
                }
            }
            // api method
            getFixedDepositAndDebentures(data).then((response) => {
                this.setState({
                    loader: true,
                })
                if (response.status == "S") {
                    this.props.getL5reportDetails(this.props.logindetails.ClientID)
                    this.setState({
                        message: labels.messages.transactionSucessMessage,
                        open: true,
                        severity: "success",

                        loader: false
                    });
                    this.cancelClick();
                    // {console.log(this.props.logindetails)}
                    GetFDRNumber(this.props.logindetails.ClientID)
                        .then((resp) => {
                            if (resp != undefined && resp.data != null && resp.data != undefined && resp.status != null & resp.status != null) {
                                if (resp.status == "S") {
                                    let list = resp.data != null && resp.data != undefined & resp.data != 0 ? resp.data.map((item) => {
                                        return {
                                            value: item.ID,
                                            label: item.Text,
                                        }
                                    }) :
                                        [];
                                    this.setState({
                                        fdrList: list,
                                    })

                                }
                                else {
                                    this.setState({
                                        fdrList: [],
                                    })
                                }
                            }
                            else {

                            }
                        })
                }
                else {
                    this.setState({
                        loader: false,
                        open: true,
                        severity: "warning",
                        message: response.message,

                    });
                    this.cancelClick();
                }
            })
                .catch((err) => {
                    this.setState({
                        open: true,
                        message: labels.messages.apierror,
                        severity: 'warning'
                    })

                })

        }
    }

    // snackbar message method 
    handlesnanckClose = () => {
        this.setState({ open: false })
    }
    render() {
        path = window.location.pathname
        let transactionTypeList = window.location.pathname == '/Debentures' ? this.props.debentureTransactionTypedropdownvalues : this.props.transactionTypedropdownvalues
        let FdrList = this.props.FdrNumberList;
        const { classes } = this.props;
        if (this.state.Quantityvalue != "" && this.state.Ratevalue != "") {
            var TotalAmount = parseFloat(this.state.Quantityvalue) * parseFloat(this.state.Ratevalue);
            var Total = Number.parseFloat(TotalAmount).toFixed(2);
            // Textbox only allowed String Type       
            Totals = String(Total);
        }
        else {
            Totals = '';

        }
        // fd form Interest(Cumulative) calculation tex box
        if (this.state.AmountValue != "" && this.state.tds != "") {
            var NetAmountValue = parseFloat(this.state.AmountValue) - parseFloat(this.state.tds);
            var Netmountavalues = Number.parseFloat(NetAmountValue).toFixed(2);
            Netvalue = String(Netmountavalues);
            NetError = "";
        }
        else {
            Netvalue = '';
        }
        if (this.state.principalAmount != "" && this.state.tds != "" && this.state.Interest != "") {
            var WithdrawalAmount = parseFloat(this.state.principalAmount) + parseFloat(this.state.Interest);
            var calulatedAmount = WithdrawalAmount - parseFloat(this.state.tds)
            var Withdrawal = Number.parseFloat(calulatedAmount).toFixed(2);
            WithdrawalNetAmount = String(Withdrawal);
            NetError = "";
        }
        else {
            WithdrawalNetAmount = '';
        }
        try {
            return (
                <>
                    <div className={classes.root} ref={this.MyDebentures}>
                        <div></div>
                        <div className={classes.card}>
                            {/* title paragraph */}
                            <div className={classes.header}>
                                <div className={classes.header}>{this.props.location.pathname == "/Debentures" ? labels.fdForm.Debentures : this.props.location.pathname == "/trade/FixedDeposit" ? labels.fdForm.fd : labels.fdForm.Bonds}</div>                            </div>
                            <div className={classes.cardSpareaterLine}></div>
                            <div >
                                <div className={classes.firstRowContainer}>
                                    <Dropdown type={"normalwithsearch"}
                                        label={labels.fdForm.Type}
                                        autoselect={true}
                                        id={"fd1"}
                                        loader={this.props.isLoading}
                                        isSearchable={false}
                                        value={this.state.transactionType}
                                        List={transactionTypeList}
                                        placeholder={labels.fdForm.selectTransactionType}
                                        error={this.state.typeerror}
                                        handleOnChange={this.transactionTypeHandleOnChange}
                                    />
                                    {(this.state.isdataEntryFlag == "debentures" || this.state.transactionTypeId == 1 || this.state.transactionTypeId == 5) &&
                                        <div className={classes.AssetRow}>
                                            <Dropdown type={"normalwithsearch"}
                                                placeholder={labels.fdForm.select + " " + labels.fdForm.fixed}
                                                id={"fd2"}
                                                label={labels.fdForm.fixed}
                                                autoselect={false}
                                                isSearchable={true}
                                                value={this.state.Autocompletevalue}
                                                List={this.state.isdataEntryFlag == "fd" ? this.props.AssetNameList : this.state.isdataEntryFlag == "debentures" ? this.props.AssetNameListDebencture : this.props.AssetNameListBonds}
                                                error={this.state.Autocompleteerrror}
                                                handleOnChange={this.AssethandleOnChange}
                                            />
                                            <InformationTooltip title={"Add Deposits Name"} Icon={<div className={classes.centerAlignment} onClick={this.handleClickOpen}>
                                                <img src={PlusIcon} width="20px" height="20px" alt="AddIcon" />
                                            </div>}>

                                            </InformationTooltip>
                                            <Dialog
                                                disablePortal={true}
                                                maxWidth="xs"
                                                aria-labelledby="alert-dialog-title"
                                                aria-describedby="alert-dialog-description"
                                                open={this.state.openpopup}
                                                onClose={this.handleClose}
                                            >
                                                <div className={classes.popupWidth}>
                                                    <div className={classes.popupheader}>
                                                        <div className={classes.txtHeader}>{labels.fdForm.popupAsset}
                                                            <span className={classes.clearIcon} onClick={this.handleClose}>&#x2715;</span>
                                                        </div>
                                                    </div>
                                                    <div className={classes.popupBodyContainer}>
                                                        <div style={{
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            gap: "20px",
                                                            justifyContent: "center",
                                                            alignContent: "center"
                                                        }}>
                                                            <Dropdown
                                                                id={"category3"}
                                                                type={"normalwithsearch"}
                                                                label={labels.fdForm.Category}
                                                                autoselect={false}
                                                                isSearchable={true}
                                                                value={this.state.categoryValue}
                                                                List={this.state.isdataEntryFlag == "fd" ? this.props.CategoryFDAssetNameList : this.state.isdataEntryFlag == "debentures" ? this.props.CategoryDebenturesAssetNameList : this.props.CategoryBondAssetNameList}
                                                                // List={this.props.CategoryAssetNameList}
                                                                placeholder={labels.fdForm.select + " " + labels.fdForm.Category}
                                                                handleOnChange={this.categoryHandleChange}
                                                                error={this.state.categoryError}
                                                            />
                                                            <Textinput
                                                                page="normal"
                                                                placeholder={labels.fdForm.fixed}
                                                                label={labels.fdForm.fixed}
                                                                paddingAlign="5px"
                                                                paddingLeftAlignment="-1px"
                                                                value={this.state.Assetname}
                                                                handleChange={(event) => this.AssetTextboxhandleChange(event)}
                                                                error={this.state.Assesterror}
                                                            />
                                                            <div style={{ marginTop: '-22px' }}>
                                                                <Textinput
                                                                    page="normal"
                                                                    placeholder={labels.fdForm.Issurename}
                                                                    label={labels.fdForm.Issurename}
                                                                    paddingAlign="5px"
                                                                    paddingLeftAlignment="-1px"
                                                                    value={this.state.issureName}
                                                                    handleChange={(event) => this.IssureTextboxhandleChange(event)}
                                                                    error={this.state.issureErrorMessage}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={classes.cardSpareaterLinelight}></div>
                                                    <div className={classes.popupButtonCantainer}>
                                                        <div className={classes.popupfooterContainer}>
                                                            <Button name={labels.fdForm.cancel} onClick={this.handleClose} />
                                                            <Button name={labels.fdForm.add} page={"primary"} loading="true" loader={this.state.loader} onClick={() => { this.AssetNameClick() }} />

                                                        </div>
                                                    </div>
                                                </div>
                                            </Dialog>
                                        </div>
                                    }

                                    {(this.state.transactionTypeId == 1 || this.state.transactionTypeId == 5 || (this.state.isdataEntryFlag == "debentures" && this.state.transactionTypeId == 4)) ?
                                        <>
                                            {this.state.isdataEntryFlag != "debentures" && this.state.transactionTypeId == 5 &&
                                                <Dropdown type={"normalwithsearch"}
                                                    placeholder={labels.fdForm.selectOldFDRNo}
                                                    id={"fd2"}
                                                    label={labels.fdForm.fdrOldValue}
                                                    autoselect={false}
                                                    isSearchable={true}
                                                    value={this.state.fdOldDropDown}
                                                    List={this.state.fdrList}
                                                    // List={this.props.FdrNumberList}
                                                    handleOnChange={this.fdrOldHandleChange}
                                                    error={this.state.fdOldDropDownerror}
                                                />
                                            }
                                            {this.state.isdataEntryFlag != "debentures" &&
                                                <Textinput
                                                    page="normal"
                                                    placeholder={labels.fdForm.enterFDRNumber}
                                                    label={this.state.transactionTypeId == 5 ? labels.fdForm.fdrNewTextValue : labels.fdForm.fdrnumber}
                                                    // align="right"
                                                    paddingAlign="5px"
                                                    paddingLeftAlignment="-1px"
                                                    value={this.state.fdrNumber}
                                                    otherProps={{ maxLength: 15 }}
                                                    handleChange={(event) => this.fdrHandleChange(event)}
                                                    error={this.state.fdrTextError}
                                                />}
                                            <Textinput
                                                page="normal"
                                                placeholder={labels.fdForm.value}
                                                label={labels.fdForm.investment}
                                                align="right"
                                                paddingAlign="5px"
                                                paddingLeftAlignment="-1px"
                                                value={this.state.Interestvalue}
                                                otherProps={{ maxLength: 4 }}
                                                handleChange={(event) => this.interesthandleChange(event)}
                                                error={this.state.errormessageinterest}
                                            />
                                            <Dropdown type={"normalwithsearch"}
                                                label={labels.fdForm.investmentType}
                                                autoselect={false}
                                                isSearchable={false}
                                                loader={this.props.isLoading}
                                                id={"fd12"}
                                                value={this.state.investmentType}
                                                List={this.props.interestTypedropdownvalues}
                                                placeholder={labels.fdForm.selectInterestType}
                                                error={this.state.investmentTypeError}
                                                handleOnChange={this.investmentTypehandlechange}
                                            />
                                            <Dropdown type={"normalwithsearch"}
                                                label={labels.fdForm.InvvestPayment}
                                                autoselect={false}
                                                isSearchable={false}
                                                id={"fd13"}

                                                loader={this.props.isLoading}
                                                value={this.state.InterestPaymentListvalue}
                                                List={this.props.interestPaymentdropdownvalues}
                                                placeholder={labels.fdForm.selectPaymentType}
                                                error={this.state.InterestPaymentListvalueerror}
                                                handleOnChange={this.InterestPaymenthandlecange}
                                            />
                                            <div className={classes.subContent}>
                                                <div >
                                                    <div style={{
                                                        color: window.globalConfig.color.LABLE_GRAY, textAlign: "left",
                                                        fontSize: '14px',
                                                        fontWeight: 'normal',
                                                        // padding: '5px 0px 0px 0px'
                                                    }}>{labels.fdForm.Maturity}</div>
                                                </div>
                                                <DatePicker
                                                    pages="FdForm"
                                                    id={"fddatepicker3"}
                                                    handleClick={(e) => this.handleClick(e)}
                                                    handleApply={this.maturityhandleApply}
                                                    showingdate={this.state.maturitydate1}
                                                    dateChange={this.dateChange}
                                                    handleClose={this.handleClose1}
                                                    anchorEl={this.state.anchorEl}
                                                    error={this.state.errormaturitydatedata}
                                                    allowed={!this.state.checked}
                                                    date={this.state.maturitydate}
                                                    placeholder={'DD MMM YYYY'}
                                                />

                                            </div>
                                            <div className={classes.checkBoxContent}>
                                                <CommonCheckbox
                                                    label={labels.fdForm.Lockin}
                                                    checked={this.state.lockchecked}
                                                    onChange={this.lockingOnChange} />
                                                <DatePicker
                                                    pages="FdForm"
                                                    placeholder="DD MMM YYYY"
                                                    id="fddatepicker4"
                                                    handleClick={(e) => this.LockhandleClick(e)}
                                                    handleApply={this.lockinhandleApply}
                                                    showingdate={this.state.lockindate1}
                                                    dateChange={this.LockindateChange}
                                                    handleClose={this.LockinhandleClose}
                                                    anchorEl={this.state.anchorEl1}
                                                    error={this.state.lockindateerrormsg}
                                                    allowed={!this.state.lockchecked}
                                                    date={this.state.lockindate}
                                                />
                                            </div>
                                        </>
                                        :
                                        this.state.isdataEntryFlag != "debentures" &&

                                        <Dropdown type={"normalwithsearch"}
                                            placeholder={labels.fdForm.selectFDRNo}
                                            id={"fd2"}
                                            label={this.state.transactionTypeId == 2 || this.state.transactionTypeId == 3 ? labels.fdForm.fdrnumber : labels.fdForm.fdrOldValue}
                                            autoselect={false}
                                            isSearchable={true}
                                            value={this.state.fdOldDropDown}
                                            List={this.state.fdrList}
                                            // List={this.props.FdrNumberList}
                                            handleOnChange={this.fdrOldHandleChange}
                                            error={this.state.fdOldDropDownerror}
                                        />

                                    }

                                </div>
                                <div className={classes.scendHeader}>{labels.fdForm.Transaction}</div>
                                <div className={classes.cardSpareaterLine}></div>
                                <div className={classes.SecondRowContainer}>
                                    <DatePicker
                                        pages="FdForm"
                                        id="fddatepicker5"
                                        handleClick={(e) => this.defaultdateClick(e)}
                                        handleApply={this.defaultdateApply}
                                        handleClose={this.defaulthandleClose}
                                        label={labels.fdForm.date}
                                        anchorEl={this.state.anchorEldate}
                                        allowed={!this.state.date}
                                        showingdate={this.state.defaultdate}
                                        dateChange={this.defaultdateChange}
                                        maxdate={moment().format("DD MMM YYYY")}
                                        date={this.state.date}
                                        error={this.state.transactionDate}
                                    />
                                    {this.state.isdataEntryFlag == "debentures" && (this.state.transactionTypeId == 1 || this.state.transactionTypeId == 4) &&
                                        <>
                                            <Textinput
                                                page="normal"
                                                placeholder={labels.fdForm.value}
                                                label={labels.fdForm.Quantity}
                                                align="right"
                                                paddingAlign="5px"
                                                paddingLeftAlignment="-1px"
                                                value={this.state.Quantityvalue}
                                                handleChange={(event) => { this.quantityHandleChange(event) }}
                                                error={this.state.errorMsgQuantity}
                                            />
                                            <Textinput
                                                page="normal"
                                                placeholder={labels.fdForm.value}
                                                label={labels.fdForm.Rate}
                                                align="right"
                                                paddingAlign="5px"
                                                paddingLeftAlignment="-1px"
                                                value={this.state.Ratevalue}
                                                handleChange={(event) => { this.rateHandleChange(event) }}
                                                error={this.state.errorMsgRate}
                                            />
                                        </>
                                    }

                                    <Textinput
                                        page="normal"
                                        placeholder={labels.fdForm.value}
                                        label={(this.state.isdataEntryFlag != "debentures" && this.state.transactionTypeId == 4) ? labels.fdForm.principalAmount : labels.fdForm.Amount}
                                        align="right"
                                        paddingAlign="5px"
                                        value={this.state.isdataEntryFlag != "debentures" ? (this.state.transactionTypeId == 4 ? this.state.principalAmount : this.state.AmountValue) : (this.state.transactionTypeId == 2 || this.state.transactionTypeId == 3) ? this.state.AmountValue : Totals}
                                        handleChange={(event) => { (this.state.isdataEntryFlag != "debentures" && this.state.transactionTypeId == 4) ? this.principalAmounthandleChange(event) : this.amountHandleChange(event) }}
                                        error={this.state.errormessageAmount}
                                    />
                                    {(this.state.isdataEntryFlag != "debentures" && this.state.transactionTypeId == 4) &&
                                        <Textinput
                                            page="normal"
                                            placeholder={labels.fdForm.value}
                                            label={labels.fdForm.Interest}
                                            align="right"
                                            paddingAlign="5px"
                                            otherProps={{ maxLength: 5 }}
                                            paddingLeftAlignment="-1px"
                                            value={NegativeFinding(this.state.Interest, "", "", "", "number")}
                                            handleChange={(event) => this.interestTextboxHandleChanges(event)}
                                            error={this.state.Interesterror}
                                        />
                                    }
                                    {this.state.isdataEntryFlag == "debentures" && this.state.transactionTypeId == 4 ? <></> : (this.state.transactionTypeId == 2 || this.state.transactionTypeId == 3 || this.state.transactionTypeId == 4) &&
                                        <>
                                            <Textinput
                                                page="normal"
                                                placeholder={labels.fdForm.value}
                                                label={labels.fdForm.tds}
                                                align="right"
                                                paddingAlign="5px"
                                                paddingLeftAlignment="-1px"
                                                value={this.state.tds}
                                                handleChange={(event) => this.tdsHandleChange(event)}
                                                error={this.state.tdserrormessage}
                                            />
                                            {/* {this.state.isdataEntryFlag!="debentures"&& */}
                                            <Textinput
                                                page="normal"
                                                placeholder={labels.fdForm.value}
                                                label={labels.fdForm.netAmount}
                                                align="right"
                                                paddingAlign="5px"
                                                paddingLeftAlignment="-1px"
                                                value={this.state.isdataEntryFlag != "debentures" ? (this.state.transactionTypeId == 4 ? WithdrawalNetAmount : Netvalue) : this.state.netamount}
                                                handleChange={(event) => this.netAmountHandleChange(event)}
                                                // error={this.state.netamounterrormessge}
                                                error={NetError}
                                            />
                                            {/* } */}
                                        </>
                                    }
                                    <TextArea label={labels.fdForm.Narration}
                                        value={this.state.Narrationtextarea}
                                        handleChange={(event) => { this.narrationTextareaHandleChange(event) }}
                                        placeholder={labels.fdForm.enterNarration}
                                    />


                                </div>
                            </div>
                            <div className={classes.footercontainer}>
                                <div className={classes.buttonContaniner}>
                                    <Button name={labels.fdForm.cancel} onClick={this.cancelClick} />
                                    <Button name={labels.fdForm.save} page={"primary"} loading="true" loader={this.state.loader} onClick={this.submitClick} />

                                </div>
                            </div>
                            <CommonSnackbar severity={this.state.severity} handlesnanckClose={this.handlesnanckClose} open={this.state.open} message={this.state.message} />
                        </div>
                        <div></div>
                    </div>
                </>
            )
        }
        catch (err) {
            return <Redirect to={labels.Path.PageNotFound} />
        }

    }
}
const mapStateToProps = (state) => {
    return {
        logindetails: state.Login.logindetails,
        ClientID: state.Login.logindetails.ClientID,
        isLoading: state.Dataentry.isLoading,
        isLoadingBroker: state.Dataentry.isLoadingBroker,
        transactionTypedropdownvalues: state.Dataentry.transactionTypeDropDown != null && state.Dataentry.transactionTypeDropDown != undefined && state.Dataentry.transactionTypeDropDown != "" && state.Dataentry.transactionTypeDropDown.table1 != null && state.Dataentry.transactionTypeDropDown.table1.length != 0 ?
            state.Dataentry.transactionTypeDropDown?.table1.sort((a, b) => a.ID > b.ID ? 1 : -1).map((item) => {
                return {
                    value: item.ID,
                    label: item.Text,
                }
            }) : [],
        debentureTransactionTypedropdownvalues: state.Dataentry.transactionTypeDropDown != null && state.Dataentry.transactionTypeDropDown != undefined && state.Dataentry.transactionTypeDropDown != "" && state.Dataentry.transactionTypeDropDown.table4 != null && state.Dataentry.transactionTypeDropDown.table4.length != 0 ?
            state.Dataentry.transactionTypeDropDown?.table4.map((item) => {
                return {
                    value: item.ID,
                    label: item.Text,
                }
            }) : [],
        interestTypedropdownvalues: state.Dataentry.transactionTypeDropDown != null && state.Dataentry.transactionTypeDropDown != undefined && state.Dataentry.transactionTypeDropDown != "" && state.Dataentry.transactionTypeDropDown.table2 != null && state.Dataentry.transactionTypeDropDown.table2.length != 0 ?
            state.Dataentry.transactionTypeDropDown?.table2.map((item) => {
                return {
                    value: item.ID,
                    label: item.Text,
                }
            }) : [],
        interestPaymentdropdownvalues: state.Dataentry.transactionTypeDropDown != null && state.Dataentry.transactionTypeDropDown != undefined && state.Dataentry.transactionTypeDropDown != "" && state.Dataentry.transactionTypeDropDown.table3 != null && state.Dataentry.transactionTypeDropDown.table3.length != 0 ?
            state.Dataentry.transactionTypeDropDown?.table3.map((item) => {
                return {
                    value: item.ID,
                    label: item.Text,
                }
            }) : [],

        AssetNameList: state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != undefined && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset.table1 != "NA" && state.Dataentry.alternativeAsset.table1.length != 0 ?
            state.Dataentry.alternativeAsset.table1?.filter((items, index) => (items.AssetClassCode == "FD")).map((item) => {
                return {
                    value: item.ID,
                    label: item.AssetName,
                }
            }) : [],
        AssetNameListBonds: state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != undefined && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset.table1 != "NA" && state.Dataentry.alternativeAsset.table1.length != 0 ?
            state.Dataentry.alternativeAsset.table1.filter((items, index) => (items.AssetClassCode == "OB")).map((item) => {
                return {
                    value: item.ID,
                    label: item.AssetName,
                }
            }) : [],
        AssetNameListDebencture: state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != undefined && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset.table1 != "NA" && state.Dataentry.alternativeAsset.table1.length != 0 ?
            state.Dataentry.alternativeAsset.table1.filter((items, index) => (items.AssetClassCode == "OT")).map((item) => {
                return {
                    value: item.ID,
                    label: item.AssetName,
                }
            }) : [],
        CategoryFDAssetNameList: state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != undefined && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset.table2 != "NA" && state.Dataentry.alternativeAsset.table2.length != 0 ?
            state.Dataentry.alternativeAsset?.table2.filter((items, index) => (items.AssetClassCode == "FD")).map((item) => {
                return {
                    value: item.ID,
                    label: item.SubAssetClassName,
                }
            }) : [],
        CategoryBondAssetNameList: state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != undefined && state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset.table2 != "NA" && state.Dataentry.alternativeAsset.table2.length != 0 ?
            state.Dataentry.alternativeAsset?.table2.filter((items, index) => (items.AssetClassCode == "OB")).map((item) => {
                return {
                    value: item.ID,
                    label: item.SubAssetClassName,
                }
            }) : [],
        CategoryDebenturesAssetNameList: state.Dataentry.alternativeAsset != undefined && state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset.table2 != "NA" && state.Dataentry.alternativeAsset.table2.length != 0 ?
            state.Dataentry.alternativeAsset?.table2.map((item) => {
                return {
                    value: item.ID,
                    label: item.SubAssetClassName,
                }
            }) : [],

        //dummy debentures category copy list
        catagories: state.Dataentry.alternativeAsset != undefined && state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset.table2 != "NA" && state.Dataentry.alternativeAsset.table2.length != 0 ?
            state.Dataentry.alternativeAsset?.table2 : [],

        // FdrNumberList: state.Dataentry.FdrNumber != null && state.Dataentry.FdrNumber.length != 0 && state.Dataentry.FdrNumber.length != undefined  && state.Dataentry.FdrNumber != null ? state.Dataentry.FdrNumber : [],
    }

}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        // getAssetNameListdata,
        // getFDRNumberList,
        getTransactionDropdowns, getAssetNameAction, getL5reportDetails,
        getAlterativeAsset
    }, dispatch)


};
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(FdForm)));