import React from "react";
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import Avatars from "../../components/avatar/avatar";
import colors from "../../utils/colors/colors";
import { NegativeFinding, countNAValues, getValuesFromString, replaceNull, sortArray } from "../../utils/commonfunction";
import Low from '../../utils/assets/low.PNG'
import Medium from '../../utils/assets/medium.PNG'
import Critical from '../../utils/assets/critical.PNG'
import Dropdownschemes from "../../components/dropdown/dropdownschemes";
import labels from "../../utils/constants/labels";
import getBasketListing from "../../api/basket/basketlisting";
import SearchIcon from '@material-ui/icons/Search';
import { connect } from "react-redux";
import Loader from "../../components/loader/loader";
import ResetIcon from "../../utils/assets/images/reset.png";
import Filter_screen from "../../components/template/filter_screen";
import Label from "../../utils/constants/labels";
import { classes } from "istanbul-lib-coverage";
const styles = (theme) => ({

    gridContainer: {
        display: "grid",
        gridTemplateColumns: "5% 32% 18% 14% 14% 14%",
        gap: 5,
        alignItems: "center",
        cursor: "pointer",
        boxShadow: "0 0 5px 0 rgb(19 89 161 / 6%)",
        border: "solid 1px #ecf3fc",
        marginBottom: 5,
        borderRadius: 9,
        padding: 6,
        "& .secondGrid": {
            alignItems: "flex-start",
            textAlign: "left",
            gap: 5
        },
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(4,1fr)",
            gridTemplateAreas: `"firstGrid secondGrid secondGrid secondGrid"
          "thirdGrid fourthGrid fifthGrid sixthGrid"
          "seventhGrid . . eighthGrid"
                                  `,
            "& .firstGrid": {
                alignItems: "center"
            },
            "& .secondGrid": {
                textAlign: "left"
            },
            gap: 2
        }

    },
    basketContainer: {
        display: "grid",
        gridTemplateColumns: "10% 40% 15% 15% 20%",
        padding: "1%",
        cursor: "pointer",
        borderBottom: `1px solid ${colors.PRIMARY_BORDER}`,
        alignItems: "center",
        backgroundColor: "white",
        // "& :hover": {
        //     boxShadow: "rgb(19 89 161 / 10%) 0px 0px 20px 0px"
        //     , border: `1px solid ${colors.PRIMARY_BORDER}`,
        //     borderRadius: 10
        // }



    },
    sumBox: {
        // marginBottom: "50px",
        boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
        backgroundColor: window.globalConfig.color.WHITE,
        borderRadius: "10px",
        height: 97,
        display: "grid",
        gridTemplateColumns: "40% 25% 25% 10%",
        marginTop: "16px",
    }, labelStyle: {
        display: "block",
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: "14px",
        marginLeft: 12,
        padding: 0,
        border: 0,
        textAlign: "left",
    },
    searchBox: {
        padding: "20px 0 10px 6px",
        verticalAlign: "baseline",
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
        [theme.breakpoints.down(600)]: {
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottomLeftRadius: "0px",
        },
    },
    container: {
    },
    dropdown: {
        border: `1px solid transparent !important`
    },
    dataFive: {
        border: `1px solid ${colors.PRIMARY_BORDER}`
        ,
        width: "fit-content",
        borderRadius: 5,
        padding: 8,
        fontSize: 14,
        "& :nth-child(1)": {
            display: "flex", flexDirection: "row", gap: 5,
            alignItems: "center",
            fontSize: 14,
        }

    },
    dataThree: {
        display: "flex", flexDirection: "column", gap: 5,
        "& :nth-child(1)": {
            fontSize: 14,
            color: colors.PRIMARY_BORDER,

        }
    },
    formControl: {
        display: "block",
        width: "75%",
        height: `calc(${"2.25rem"} + ${"2px"})`,
        padding: ".375rem .75rem",
        fontSize: "14px",
        lineHeight: 1.5,
        color: window.globalConfig.color.BLACK_PRIMARY,
        backgroundColor: window.globalConfig.color.WHITE,
        backgroundClip: "padding-box",
        border: 0,
        borderRadius: 0,
        transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
        fontWeight: 500,
        overflow: "visible",
        outline: "none",
        [theme.breakpoints.down(1344)]: {
            width: "85%",
        },
    },
    heading: {
        fontSize: 24,
        color: colors.WHITE,
        fontWeight: "bold",
        paddingTop: 10,
        textAlign: "left"
    },
    dataFour: {
        display: "flex", flexDirection: "column", gap: 5,
        "& :nth-child(1)": {
            fontSize: 14,
            color: colors.PRIMARY_BORDER,
        },
        "& :nth-child(2)": {
            fontWeight: "bold"
        }
    },
    clearSelectfromDiv: {
        background: `url(${ResetIcon})  no-repeat`,
        width: "18px",
        height: "18px",
        zIndex: 0,
        // position: "absolute",
        right: 10,
        // display: "none",
        top: "55px",
        [theme.breakpoints.down("900")]: {
            width: "37px",
        },
    },
    dataTwo: {
        display: "flex", flexDirection: "column", gap: 5,
        textAlign: "left",

        "& :nth-child(1)": {
            fontSize: 18,
            color: colors.BLACK_PRIMARY,
            fontWeight: "bold"
        },
        "& :nth-child(2)": {
            fontSize: 14,
            color: colors.BLACK_PRIMARY,
            lineHeight: 1.57
            //fontWeight: "bold"
        },
        "& :nth-child(3)": {
            fontSize: 14,
            color: colors.PRIMARY_BORDER,
            //fontWeight: "bold"
        }

    },
    filterGap: {
        padding: 13,
        borderRight: `1px solid ${colors.BORDER}`
    },
    searchIcon: {
        backgroundColor: colors.RosyBrown,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        borderLeft: `1px solid ${colors.BORDER}`,
        // marginLeft: 4,
        cursor: "pointer",
        borderBottomRightRadius: 5,
        borderTopRightRadius: 5
    },
    searchTopBar: {
        backgroundColor: colors.WHITE, borderRadius: 5, boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
        display: "grid",
        gridTemplateColumns: "34% 18% 18% 18% 12%"
        //  gridTemplateColumns: "28% 28% 28% 16%"
    }

})
let Subscription = [{ label: "Show All", value: 1 }, { label: "Free Access", value: 2 }, { label: "Fee based", value: 3 }]
class Basket extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            SubscriptionType: [],
            investmentAmountList: [],
            volatilityList: [],
            investmentStrategyList: [],
            basketList: [],
            backupBasketList: [],
            loader: false,
            filterBasket: { subscriptionType: [], minAmount: [], volatility: [], basketName: [] },
            basket: "",
            color: colors.RosyBrown,
            filter: [],
            searchContent: "",
            finalSearch: false
        }
    }
    async componentDidMount() {
        this.setState({ loader: true })
        window.scrollTo(0, 0)
        await getBasketListing(this.props.logindetails.UserID).then((response) => {
            this.setState({ loader: false })
            let res = replaceNull(response, "NA")
            if (res.status == "S") {

                if (res.investmentAmountList != "NA" && res.investmentAmountList.length != 0) {
                    this.setState({
                        investmentAmountList: res.investmentAmountList.map((item) => {
                            return { label: item.Text, value: item.Value, isChecked: false, data: getValuesFromString(item.Text) }
                        })
                    })
                }
                if (res.volatilityList != "NA" && res.volatilityList.length != 0) {
                    this.setState({
                        volatilityList: res.volatilityList.map((item) => {
                            return { label: item.Text, value: item.Value, isChecked: false }
                        })
                    })
                }
                if (res.basketLists != "NA" && res.basketLists.length != 0) {

                    this.setState({
                        basketList: res.basketLists.slice(0, 10).map((item,index) => {
                            return {
                                avatar: "",
                                color:labels.schemeColors[index],
                                ID: item.basketID,
                                basketName: item.basketName,
                                dataOne: item.bImage,
                                dataTwo: item.basketName,
                                dataThree: item.userName,
                                dataThreeHeader: "Publisher",
                                dataFourHeader: "Min Amount",
                                dataFour: NegativeFinding(item.minAmount, "", "", "", "rupee"),
                                dataFourStyle: { color: NegativeFinding(item.minAmount) },
                                dataFive: item.riskProfile,
                                dataFiveHeader: "Volatility",
                                dataSix: NegativeFinding(item.cagr == "NA" ? 0 : item.cagr, "", "", "", "percentage"),
                                dataSixHeader: "CAGR",
                                subDataOne: item.description,
                                dataSixStyle: { color: NegativeFinding(item.cagr) },
                                // subDataTwoHeader: "Publisher :",
                                // subDataTwo: item.createdBy,
                                basketDescription: item.description,
                                publisherName: "by Windmill Capital",
                                minAmount: item.minAmount,
                                cagrYear: 1,
                                cagrPercentage: item.cagr == "NA" ? 0 : item.cagr,
                                volatility: item.riskProfile,
                                basketIcon: `data:image/png;base64,${item.bImage}`,
                                access: "Free",
                            }
                        }),
                        backupBasketList: res.basketLists.map((item,index) => {
                            return {
                                avatar: "",
                                color:labels.schemeColors[index],
                                ID: item.basketID,
                                basketName: item.basketName,
                                dataOne: item.bImage,
                                dataTwo: item.basketName,
                                dataThree: item.userName,
                                dataThreeHeader: "Publisher",
                                dataFourHeader: "Min Amount",
                                dataFour: NegativeFinding(item.minAmount, "", "", "", "rupee"),
                                dataFourStyle: { color: NegativeFinding(item.minAmount) },
                                dataFive: item.riskProfile,
                                dataFiveHeader: "Volatility",
                                dataSix: NegativeFinding(item.cagr == "NA" ? 0 : item.cagr, "", "", "", "percentage"),
                                dataSixHeader: "CAGR",
                                subDataOne: item.description,
                                dataSixStyle: { color: NegativeFinding(item.cagr) },
                                // subDataTwoHeader: "Publisher :",
                                // subDataTwo: item.createdBy,
                                basketDescription: item.description,
                                publisherName: "by Windmill Capital",
                                minAmount: item.minAmount,
                                cagrYear: 1,
                                cagrPercentage: item.cagr == "NA" ? 0 : item.cagr,
                                volatility: item.riskProfile,
                                basketIcon: `data:image/png;base64,${item.bImage}`,
                                access: "Free",
                            }
                        })
                    })
                }
            } else {
                this.setState({ investmentAmountList: [], volatilityList: [], backupBasketList: [], basketList: [] })
            }
        })
        this.setState({
            filter: [
                { header: "Search", type: { name: "textbox", category: "" }, onChange: this.onChange },
                { header: "Investment Amount", type: { name: "dropdown", category: "withoutsearchable" }, List: this.state.investmentAmountList, onChange: this.onInvestmentAmountChange, onClear: this.handleOnClearInvestmentAmountChange }, { header: "Volatility", List: this.state.volatilityList, type: { name: "dropdown", category: "withoutsearchable" }, onChange: this.onVolatilityChange, onClear: this.handleOnClearVolatilityChange }
                , {
                    header: "", type: {
                        name: "search", category: ""
                    }
                }]
        })
    }
    routeChange = (value) => {
        this.props.history.push({
            pathname: Label.Path.BasketDetails,
            state: { data: value },
        });
    }
    handleOnClearInvestmentAmountChange = () => {
        this.setState({
            investmentAmountList: this.state.investmentAmountList.map((item) => {
                return { ...item, isChecked: false }
            }),
            filterBasket: { ...this.state.filterBasket, minAmount: [] },
        })
    }
    handleOnClearVolatilityChange = () => {
        this.setState({

            volatilityList: this.state.volatilityList.map((item) => {
                return { ...item, isChecked: false }
            }),
            filterBasket: { ...this.state.filterBasket, volatility: [] },
        })

    }
    renderImage = (val) => {
        let srcImage = [{ status: "mediocary", image: Low }, { status: "agreesive", image: Medium }, { status: "cautious", image: Critical }]
        let img = srcImage.filter((item) => item.status.toLowerCase() == val.toLowerCase())[0]?.image
        return img
    }
    onSubscriptionChange = async (item) => {
        let value = item.map((val) => { return val.label })
        await this.setState({ filterBasket: { ...this.state.filterBasket, subscriptionType: value } })
        // console.log(this.state.SubscriptionType)
    };
    onInvestmentAmountChange = async (item) => {
        // console.log(item)
        let value = item.map((val) => { return val.data })
        await this.setState({ filterBasket: { ...this.state.filterBasket, minAmount: value } })
    }
    onVolatilityChange = async (item) => {
        let value = item.map((val) => { return val.label })
        await this.setState({ filterBasket: { ...this.state.filterBasket, volatility: value } })

    }

    onSearchClick = () => {
        let a = replaceNull(this.state.filterBasket, "NA")
        let filterBasketListing = this.state.backupBasketList

        if (countNAValues(a) != 4) {
            Object.keys(a).map((item, index) => {
                if (a[item] != "NA") {
                    filterBasketListing = filterBasketListing.filter((tar) => {
                        if (typeof (a[item]) == "string") {
                            return a[item].toLowerCase().includes(tar[item].toLowerCase());
                        } else {
                            return a[item].some((des) => {
                                if (tar[item] != undefined && typeof (tar[item]) != "number") {
                                    return des.toLowerCase() == tar[item].toLowerCase();
                                } else {
                                    let a = ''
                                    if (des.search("<=") == -1) {
                                        a = des
                                    } else {
                                        a = des.replace("<=", ` && ${tar[item]} <=`)
                                    }
                                    // console.log(a)
                                    return eval(`${tar[item]}${a}`)
                                }

                            });
                        }

                    });
                }

            })
            this.setState({ basketList: filterBasketListing, finalSearch: true })
        }


    }
    onChange = async (e) => {
        await this.setState({ basket: e.target.value, filterBasket: { ...this.state.filterBasket, basketName: e.target.value } })
        this.onSearchClick()
    }
    onClickSearchClear = async () => {
        await this.setState({
            basket: "", finalSearch: false,
            investmentAmountList: this.state.investmentAmountList.map((item) => {
                return { ...item, isChecked: false }
            }),
            volatilityList: this.state.volatilityList.map((item) => {
                return { ...item, isChecked: false }
            }),
            filterBasket: { subscriptionType: [], minAmount: [], volatility: [], basketName: []}, basketList: this.state.backupBasketList
        })
        // console.log(this.state.filterBasket)
        this.setState({
            filter: [
                { header: "Search", type: { name: "textbox", category: "" }, onChange: this.onChange },
                { header: "Investment Amount", type: { name: "dropdown", category: "withoutsearchable" }, List: this.state.investmentAmountList, onChange: this.onInvestmentAmountChange }, { header: "Volatility", List: this.state.volatilityList, type: { name: "dropdown", category: "withoutsearchable" }, onChange: this.onVolatilityChange }
                , {
                    header: "", type: {
                        name: "search", category: ""
                    }
                }]
        })
    }
    sortByClick = (name) => {
        if (name == "A-Z") {
            this.setState({ basketList: sortArray(this.state.basketList, "BasketName", "DESC") })
        } else {
            this.setState({ basketList: sortArray(this.state.basketList, "BasketName", "ASC") })

        }

    }
    amountClick = () => {

    }
    render() {
        const { classes } = this.props
        return (
            <Filter_screen {...this.props} {...this.state} isLoading={this.state.loader} routeChange={this.routeChange} pageHeader={"Basket"}
                filter={this.state.filter} filterGrid={classes.sumBox} listdata={this.state.basketList}
                subHeader={"Basket Lists"}
                enableSortBy={true}
                searchContent={this.state.basket}
                resetClick={this.onClickSearchClear}
                sortBy={[{ name: "A-Z", key: "basketName", isInversible: true }, { name: "Amount", key: "minAmount", isInversible: false }]}
                onFinalSearchClick={this.onSearchClick}
                onClickSearchClear={this.onClickSearchClear} />
            // // <div style={{ display: "grid", gridTemplateColumns: "10% 80% 10%" }}>
            // //     <div></div>
            // <>
            //     <div style={{ display: "flex", backgroundColor: colors.PRIMARY, height: 120, flexDirection: "column", gap: 10, width: "-webkit-fill-available" }}>
            //         {/* <div className={classes.heading}>Basket</div> */}
            //         <div style={{ display: "grid", gridTemplateColumns: "10% 80% 10%" }}>
            //             <div></div>
            //             <div style={{ gap: 10, display: "flex", flexDirection: "column" }}>
            //                 <div className={classes.heading}>Basket</div>
            //                 <div
            //                     style={{
            //                         textAlign: "left",
            //                         fontSize: 14,
            //                         color: window.globalConfig.color.cyanBluish,
            //                     }}
            //                 >
            //                     {this.props.logindetails.UserName}
            //                     {this.props.logindetails.FamilyName != ""
            //                         ? `(${this.props.logindetails.FamilyName})`
            //                         : ""}
            //                 </div>
            //                 <div className={classes.searchTopBar}>

            //                     <div
            //                         className={[classes.searchBox, classes.filterGap].join(" ")}
            //                         style={{ backgroundColor: this.state.color }}
            //                     >
            //                         <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
            //                             <label className={classes.labelStyle}>{labels.schemes.search}</label>
            //                             {this.state.basket != "" && (
            //                                 <div
            //                                     className={classes.clearSelectfromDiv}
            //                                     onClick={this.onClickSearchClear}
            //                                 ></div>
            //                             )}
            //                         </div>
            //                         {/* <a className={classes.searchClose}></a> */}
            //                         <input
            //                             type="text"
            //                             placeholder={labels.schemes.enterYourSearch}
            //                             value={this.state.basket}
            //                             onChange={this.onChange}
            //                             onFocus={() => this.setState({ color: window.globalConfig.color.RosyBrown })}
            //                             onBlur={() => this.setState({ color: window.globalConfig.color.WHITE })}
            //                             onCopy={"return true"}
            //                             onCut={"return true"}
            //                             onPaste={"return true"}
            //                             className={[
            //                                 classes.formControl,
            //                                 "placeholdercolor",
            //                             ].join(" ")}
            //                             style={{ backgroundColor: 'transparent' }}
            //                         ></input>

            //                     </div>

            //                     <div className={classes.filterGap} >
            //                         <Dropdownschemes
            //                             type={"withoutsearchable"}
            //                             handleOnChange={this.onSubscriptionChange}
            //                             List={Subscription}
            //                             label={labels.schemes.SubscriptionType}
            //                             placeholder={labels.schemes.select}
            //                             autoselect={false}
            //                             isSearchable={false}
            //                             showborder={false}
            //                         >

            //                         </Dropdownschemes></div>
            //                     <div className={classes.filterGap} >

            //                         <Dropdownschemes
            //                             type={"withoutsearchable"}
            //                             handleOnChange={this.onInvestmentAmountChange}
            //                             List={this.state.investmentAmountList}
            //                             label={labels.schemes.investmentAmount}
            //                             placeholder={labels.schemes.select}
            //                             autoselect={false}
            //                             isSearchable={false}
            //                             showborder={false}
            //                         >

            //                         </Dropdownschemes></div>
            //                     <div className={classes.filterGap} >

            //                         <Dropdownschemes
            //                             type={"withoutsearchable"}
            //                             handleOnChange={this.onVolatilityChange}
            //                             List={this.state.volatilityList}
            //                             label={labels.schemes.volatility}
            //                             placeholder={labels.schemes.select}
            //                             autoselect={false}
            //                             isSearchable={false}
            //                             showborder={false}
            //                         >

            //                         </Dropdownschemes></div>
            //                     {/* <div className={classes.filterGap} >

            //                 <Dropdownschemes
            //                     type={"normalwithsearch"}
            //                     handleOnChange={this.onSubscriptionChange}
            //                     List={this.state.investmentStrategyList}
            //                     label={labels.schemes.investmentStrategy}
            //                     placeholder={labels.schemes.select}
            //                     autoselect={false}
            //                     style={classes.dropdown}
            //                     isSearchable={true}
            //                     showborder={false}
            //                 >

            //                 </Dropdownschemes>
            //                 </div> */}
            //                     <div className={classes.searchIcon} onClick={this.onSearchClick}>
            //                         <SearchIcon style={{ color: colors.BROWN, fontSize: "xx-large" }} color={colors.BROWN}></SearchIcon>
            //                     </div>
            //                 </div>
            //             </div>
            //             <div></div>
            //         </div>

            //     </div>
            //     <div style={{ display: "grid", gridTemplateColumns: "10% 80% 10%", marginTop: 74 }}>
            //         <div></div>
            //         <div className={classes.container} style={{ width: "-webkit-fill-available" }}>
            //             {this.state.loader ? <Loader page="reportmodel"></Loader> : this.state.basketList.map((item, index) => {

            //                 return <div className={classes.basketContainer} onClick={() => { this.props.history.push({ pathname: labels.Path.BasketDetails, state: { data: item } }) }}>
            //                     <div className={[classes.dataOne, "dataOne"].join(" ")}><Avatars id={"basket" + index} avatar={item.basketName} url={item.basketIcon} color={colors.PRIMARY}></Avatars></div>
            //                     <div className={[classes.dataTwo, "dataTwo"].join(" ")} >
            //                         <span> {item.basketName}</span>
            //                         <span>{item.basketDescription}</span>
            //                         <span>{item.publisherName}</span>
            //                     </div>
            //                     <div className={[classes.dataThree, "dataThree"].join(" ")}>
            //                         <span>Min. Amount</span>
            //                         <span >{NegativeFinding(item.minAmount, "", "", "", "rupee")}</span>
            //                     </div>
            //                     <div className={[classes.dataFour, "dataFour"].join(" ")}><span>{item.cagrYear}Y CAGR</span>
            //                         <span style={{ color: NegativeFinding(item.cagrPercentage) }}>{NegativeFinding(item.cagrPercentage, "", "", "", "percentage")}</span></div>
            //                     <div className={[classes.dataFive, "dataFive"].join(" ")}>
            //                         <div>
            //                             <img src={this.renderImage(item.volatility)} width={20}></img>
            //                             <span>{item.volatility} Volatility</span>
            //                         </div>
            //                     </div>
            //                 </div>
            //             })}
            //         </div>
            //         <div></div>
            //     </div>
            // </>
            // //     <div></div>
            // // </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        logindetails: state.Login.logindetails
    }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps)(Basket)))