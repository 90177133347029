import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { deepOrange, green } from '@material-ui/core/colors';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { Skeleton } from '@material-ui/lab';
import { id } from 'date-fns/locale';
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'none',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  square: {
    // color: theme.palette.getContrastText(deepOrange[500]),
    // backgroundColor: deepOrange[500],
    height: "37px",
    width: "41px",
    borderRadius: "7px",
  },

}));
export default function Avatars(props) {
  const classes = useStyles();
  const [show, setshow] = React.useState(false)
  const onError = (e, id) => {
    e.target.id = `img${id}-err`;
    document.getElementById(`img${id}-err`).style.display = 'none';
    document.getElementById(`avatar${id}`).style.display = "block"
    // setshow(true)
  }
  useEffect(() => {
    // console.log(document.getElementById(`img${props.id}`))
    if (document.getElementById(`img${props.id}`) != null) {
      document.getElementById(`img${props.id}`).style.display = 'block';
      document.getElementById(`avatar${props.id}`).style.display = "none"
    }
  })
  if (props.flag == "primary") {
    return (

      <div className={classes.root}>
        <Avatar variant="square" className={classes.square} style={{ background: props.color }}>
          {props.avatar.substring(0, 2).toUpperCase()}
        </Avatar>
      </div>
    );
  }
  else {
    // console
    return (

      <>
        {props.loader ? <Skeleton className={classes.square}></Skeleton> : <> <img
          src={props.url}
          id={`img${props.id}`}
          width="46px"
          onError={(event) => onError(event, props.id)}

        />
          <div className={classes.root} id={`avatar${props.id}`}>
            <Avatar variant="square" className={classes.square} style={{ background: props.color }}>
              {props.avatar?.substring(0, 2).toUpperCase()}
            </Avatar>
          </div>
        </>}
      </>
    )
  }
}