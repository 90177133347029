import React from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Check from "@material-ui/icons/Check";
import StepConnector from "@material-ui/core/StepConnector";
import Button from "../custombutton/button";
import "../../utils/stylesheets/payment/stepper.css";
import MandateStatusCheck from '../../api/mandate/mandatestatuscheck'
import { DeleteSipCartReducer } from '../../redux/actions/cart/deletecartaction'
import { DeleteOnetimeCartReducer } from '../../redux/actions/cart/deletecartaction';
import DeleteCart from '../../api/cart/deletecartapi';
import Investment from "./investment";
import PaymentDetails from "./paymentdetails";
import { formatDate, replaceNull, scrollToTopPage, sortArray, uniqueId } from "../../utils/commonfunction";
import Summary from "./summary";
import PaymentConfirmation from "./paymentconfirmation";
import Label from "../../utils/constants/paymentlabels";
import Labels from "../../utils/constants/labels";
import labels from "../../utils/constants/labels";
import { Redirect } from "react-router";
import moment from "moment";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  XSipOrder,
  getPurchaseOrder,
} from "../../redux/actions/purchaseorder/xsiporderaction";
import { OneTimeOrder } from "../../redux/actions/purchaseorder/onetimeorderaction";
import Constant from "../../utils/constants/apiconstant";
import { NegativeFinding, numbersOnly, replaceComma, SchemeCodeFinding } from "../../utils/commonfunction";
import ErrorBountry from "../Errorhandling/Errorhandle";
import CommonSnackBar from "../../components/snackbar/snackbar";
import constants from "../../utils/constants/apiconstant";
import colors from "../../utils/colors/colors";
import getOneTimeOrder from "../../api/purchaseorder/onetimeorderapi";
import getxSipOrder from "../../api/purchaseorder/xsiporderapi";
import getAllDeviceToken from "../../api/alert/getalldevicetoken";
import getSendPushNotification from "../../api/alert/sendpushnotification";
import Deletepopup from "../popup/deletepopup";
import Loader from "../loader/loader";
import UpdateFolioCart from "../../api/cart/updatefolioapi";
import PlaceBasketOrders from "../../api/basket/placebasket";
const QontoConnector = withStyles({
  alternativeLabel: {
    top: 6,
    left: "calc(-50% + 10px)",
    right: "calc(50% + 10px)",
    color: "green",
  },
  active: {
    color: window.globalConfig.color.GREEN,
    "& $line": {
      borderColor: window.globalConfig.color.GREEN,
    },
  },
  completed: {
    "& $line": {
      borderColor: window.globalConfig.color.GREEN,
    },
  },
  line: {
    borderColor: window.globalConfig.color.Lightgrayishblue,
    borderTopWidth: 4,
    borderRadius: 1,
  },
})(StepConnector);



const useQontoStepIconStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    height: "100vh",
    width: "80%",
  },
  root: {
    color: window.globalConfig.color.Lightgrayishblue,
    display: "flex",
    height: 15,
    alignItems: "center",
    width: "100%",
    flexGrow: 1,
  },
  active: {
    color: window.globalConfig.color.BROWN,
  },
  circle: {
    width: 10,
    height: 10,
    borderRadius: "50%",
    backgroundColor: window.globalConfig.color.WHITE,
    borderColor: "currentColor",
    border: "4px solid",
  },
  completed: {
    color: window.globalConfig.color.WHITE,
    zIndex: 1,
    fontSize: 18,
    border: `1px solid ${window.globalConfig.color.GREEN}`,
    borderRadius: 25,
    backgroundColor: window.globalConfig.color.GREEN,
  },
}));



function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <Check className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  );
}



QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};





const styles = (theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    //gridTemplateColumns: "100%",
    // width: "100%",
    justifyContent: "space-between",
    backgroundColor: colors.WHITE,
    boxShadow: "rgb(19 89 161 / 10%) 0px 0px 20px 0px",
    borderRadius: 10
  },
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "10% 80% 10%",
    gap: 10,
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "100%",
      marginLeft: "2%",
      marginRight: "2%"
    }
  },
  twoSplitContanier: {
    display: "grid",
    gridTemplateColumns: "60% 38%",
    gap: "1.5%",
    marginBottom: "3%",
    [theme.breakpoints.down("920")]: {
      display: "flex",
      flexDirection: "column-reverse",
      gap: 10
    }
  },
  oneContainer: {
    display: "grid",
    gridTemplateColumns: "100%",
  },
  btn: {
    //    width: "auto"
  }
})

const steps = [
  Label.Header.Investment,
  Label.Header.PaymentDetails,
  Label.Header.Payment,
];

let array = [];

let sipInterval = ''

let onetimeInterval = ''

let popupinterval = ''

class Payment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      activeLabel: "Next",
      amount: "",
      startDate: "",
      noOfInstalment: 180,
      checked: false,
      selectedDate: "",
      mandateValidation: false,
      dateError: "",
      currentDateBackUp: "",
      checkedFlag: "N",
      activeLabel: "Next",
      mandateID: "",
      amountValidation: false,
      sipDateValidation: false,
      tradeDetails: [],
      amountErrorMessage: "",
      instalmentErrorMessage: "",
      bankAccountNumber: "",
      mandateError: "",
      showBankDetails: false,
      summaryDetails: [],
      loader: false,
      mandateDetails: [],
      bankName: "",
      bankBranch: "",
      ifsc: "",
      mandateStatus: "",
      mandateAccountNumber: "",
      accountNumber: "",
      accountType: "",
      mandateID: "",
      open: false,
      message: "",
      severity: "warning",
      errorMandateMessage: "",
      validationMessage: "",
      validation: true, multipleApiCall: 0,
      frequency: "MONTHLY",
      openRedirectPopup: false,
      count: 14,
      showSaveForLater: false,
      minimumInstalment: 12,
      dropDownValue: 1,
      openPopup: false, tradeResponse: {}, validateMandate: false, placeOrder: false, openConfirmPopup: false
    }
  }
  componentWillUnmount() {
    clearInterval(sipInterval)
    clearInterval(onetimeInterval)
  }
  componentDidMount() {
    scrollToTopPage()
    this.setState({ activeStep: this.props.location.state.data.StepperActive, activeLabel: this.props.location.state.data.StepperActive == 1 ? "Place Order" : "Next" })
    if (this.state.tradeDetails.length == 0) {
      let data = replaceNull(this.props.location.state.data, "NA")
      // console.log(data)
      if (this.props.location.state.data.page != "basket") {
        data.multipleScheme = data.multipleScheme.map((item) => {
          return {
            ...item, folioNumber: item?.FolioNumber == undefined || item.FolioNumber == "NA" ? item?.FolioList == "NA" || item?.FolioList == undefined ? "New" : JSON.parse(item?.FolioList).length == 0 ? "New" : sortArray(JSON.parse(item?.FolioList).filter((val) => val.MutualFundUCC != "" && val.MutualFundUCC == this.props.location.state.data.MutualFundUCC).map((res) => {
              return { ...item, id: item?.FolioNumber == res?.FolioNo || item.SchemeID == res.SchemeID ? 1 : 2 }
            }), "id", "ASC")[0]?.FolioNo : item?.FolioNumber
          }
        })
      }
      this.setState({ tradeDetails: data })
      if (this.state.selectedDate == "") {
        let date = moment(this.props.location.state.data.Sipdate, "DD MMM YYYY").format("DD")
        this.setState({ startDate: labels.Date.filter((item) => item.value === date)[0]?.label, selectedDate: this.props.location.state.data.Sipdate, currentDateBackUp: this.props.location.state.data.Sipdate })
      }
      if (this.props.location.state.data.InvesmentType != "Sip") {
        this.setState({ mandateValidation: false })
      } else {
        this.setState({ dateError: "" })
      }
      if (this.props.location.state.data.Amount != "") {
        this.setState({ amount: this.props.location.state.data.Amount })
      }
      if (this.props.location.state.data.ISFirstPayment != "") {
        this.setState({ checked: this.props.location.state.data.ISFirstPayment })
      }
      if (this.props.location.state.data.SipFrequency != "") {
        this.setState({ frequency: this.props.location.state.data.multipleScheme[0].SipFrequency })
      }
    }
    if (this.props.location.state.data.minimumInstalment != undefined) {
      this.setState({ minimumInstalment: this.props.location.state.data.minimumInstalment || 12 })
    }
    if (this.props.location.state.data.StepperActive == 1) {
      if (this.props.location.state.data.InvesmentType != "One time") {
        this.setState({ validateMandate: true })
      }
    }
  }
  failure = async (SchemeID, error, nextStep, response) => {
    let paymentDetails = this.state.tradeDetails
    paymentDetails.multipleScheme = this.state.tradeDetails.multipleScheme.map((item) => {
      return { ...item, response: item.SchemeID == SchemeID ? response : item.response == undefined ? [] : item.response }
    })
    paymentDetails.response = paymentDetails.multipleScheme.map((item) => {
      return item.response
    })
    if (nextStep == true) {
      this.setState({ placeOrder: false })
      if (this.props.logindetails.ClientID == 20427 || this.props.logindetails.ClientID == 21827) {
        let data = {}
        data.ClientID = this.props.logindetails.ClientID
        await getAllDeviceToken(data).then(async (res) => {
          if (res.status == "S") {
            if (res.table1 != null) {
              let params = {}
              params.DeviceTokens = res.table1.map((item) => {
                return item.DeviceToken
              })
              params.Title = "Trading Failed"
              params.Body = replaceNull(response.message, "NA")
              await getSendPushNotification(params).then((response) => {
              })
            }
          }
        })
      }
    }
    this.setState({ tradeDetails: paymentDetails })
    // if (this.state.tradeDetails.multipleScheme.length == paymentDetails.response.length) {
    //   if (paymentDetails.response.filter((item) => item.status == "S").length >= 1) {
    //     this.setState({ openRedirectPopup: true })
    //     popupinterval = setInterval(() => {
    //       if (this.state.count + 14 >= 100) {
    //         this.setState({ count: 100 })
    //       } else {
    //         this.setState({ count: this.state.count + 14 })
    //       }
    //     }, 1000);
    //     setTimeout(() => {
    //       this.setState({ openRedirectPopup: false })
    //       clearInterval(popupinterval)
    //       let data = {
    //         "Clientcode": paymentDetails.Ucc,
    //         "primaryholder": "1",
    //         "loopbackURL": "",
    //         "Internalrefno": paymentDetails.response.filter((item) => item.status == "S")[0]?.UniqueRefNo?.toString(),
    //         "allowloopBack": ""
    //       }
    //       getPayment2FA(data).then((response) => {
    //         if (response.status == "S") {
    //           openRequestedSinglePopup(response.data)
    //         } else {
    //           this.setState({ open: true, message: response.message, severity: "warning" })
    //         }
    //       })
    //     }, 7000);
    //   }
    // }
    this.setState({ activeStep: 3, })
  }
  handleNext = () => {
    if (this.state.activeStep == 0) {
      if (this.props.location.state.data.InvesmentType == "One time") {
        if (this.stepOneTimeValidation()) {
          this.setState({ activeStep: 1, activeLabel: "Place Order" })
        }
      } else {
        if (this.stepOneSipValidation()) {
          this.setState({ activeStep: 1, activeLabel: "Place Order", validateMandate: true })
        }
      }
    } else {
      this.confirmOrder()
    }
  }
  success = async (SchemeID, status, nextStep, response, ID, refNo) => {
    let paymentDetails = this.state.tradeDetails
    paymentDetails.multipleScheme = this.state.tradeDetails.multipleScheme.map((item) => {
      return { ...item, response: item.SchemeID == SchemeID ? response : item.response == undefined ? [] : item.response }
    })
    if (nextStep == true) {
      this.setState({ placeOrder: false })
    }
    paymentDetails.response = paymentDetails.multipleScheme.map((item) => {
      return item.response;
    })
    this.setState({ tradeDetails: paymentDetails })
    // console.log(SchemeID, status, nextStep, response,ID)
    if (paymentDetails.isFromCart) {
      DeleteCart(1, this.props.logindetails.ClientID, this.props.logindetails.SessionID, ID?.toString(), status == "S" ? true : false, status == "S" ? this.state.tradeDetails.multipleScheme.filter((item) => item.SchemeID == SchemeID)[0].folioNumber : "")
        .then((data) => {
          this.setState({ activeStep: 3 })
          if (data.status == "S") {
            if (paymentDetails.InvesmentType == 'Sip') {
              this.props.DeleteSipCartReducer(SchemeID.toString())
            } else {
              this.props.DeleteOnetimeCartReducer(SchemeID.toString())
            }
          }
        })
    } else {
      this.setState({ activeStep: 3 })
    }
    if (this.props.logindetails.ClientID == 20427 || this.props.logindetails.ClientID == 21827) {
      // push notification on mobile.. commited by dulcy
      let data = {}
      data.ClientID = this.props.logindetails.ClientID
      params.MfOrderTransactionId = response.orderID
      params.TransactionSessionID = refNo
      await getAllDeviceToken(data).then(async (res) => {
        if (res.status == "S") {
          if (res.table1 != null) {
            let params = {}
            params.DeviceTokens = res.table1.map((item) => {
              return item.DeviceToken
            })
            params.Title = "Trading successfully"
            params.MfOrderTransactionId = response.orderID
            params.TransactionSessionID = refNo
            params.Body = replaceNull(response.message, "NA")
            await getSendPushNotification(params).then((response) => {
            })
          }
        }
      })
    }
  }
  confirmOrder = (item) => {
    if (item != undefined) {
      this.setState({ placeSelectedBasket: item.SchemeId })
    }
    this.setState({ openConfirmPopup: true })
  }
  stepOneSipValidation = () => {
    let isValid = true
    if (this.props.location.state.data.page != "basket") {
      if (this.state.amount == "") {
        isValid = false
        this.setState({ amountErrorMessage: "Amount is mandaory" })
      } else if (Number(this.state.amount) < 1000) {
        isValid = false
        this.setState({ amountErrorMessage: "Amount should be minimum " + NegativeFinding(1000, "", "", "", "rupee") })
      }
      if (this.state.noOfInstalment == "") {
        isValid = false
        this.setState({ instalmentErrorMessage: "No of instalments  is mandaory" })
      } else if (Number(this.state.noOfInstalment) < Number(this.state.minimumInstalment)) {
        isValid = false
        this.setState({ instalmentErrorMessage: `Minimum ${this.state.minimumInstalment} Instalments` })
      }
    }
    return isValid
  }
  stepOneTimeValidation = () => {
    let isValid = true
    if (this.props.location.state.data.page != "basket") {
      if (this.state.amount.toString().trim() == "") {
        isValid = false
        this.setState({ amountErrorMessage: "Amount is mandaory" })
      } else if (Number(this.state.amount) < 5000) {
        isValid = false
        this.setState({ amountErrorMessage: "Amount should be minimum " + NegativeFinding(5000, "", "", "", "rupee") })
      }
    }
    return isValid
  }
  folioChange = (val, detail) => {
    let purchaseData = this.state.tradeDetails
    let data = purchaseData.multipleScheme.filter((val) => val.MutualFundUCC != "").map((item) => {
      // console.log(item.SchemeID == detail.SchemeID ? val[0].value : item.folioNumber == undefined ? "New" : item.folioNumber)
      return { ...item, folioNumber: item.SchemeID == detail.SchemeID ? val[0].value : item.folioNumber == undefined ? "New" : item.folioNumber }
    })
    purchaseData.multipleScheme = data
    this.setState({ tradeDetails: purchaseData })
  }
  // dateSetting = (date, isFirstPaymentToday) => {
  //   let todayDate = moment(new Date()).format("DD MMM YYYY");
  //   let dropdownDate = moment(date, "DD MMM YYYY").format("DD MMM YYYY");
  //   let backupDropDownDate = dropdownDate
  //   let purchaseData = this.state.tradeDetails
  //   if (!moment.isMoment(todayDate)) todayDate = moment(todayDate);
  //   if (!moment.isMoment(dropdownDate)) dropdownDate = moment(dropdownDate);
  //   if (!moment(dropdownDate).isAfter(todayDate) || dropdownDate.diff(todayDate, "days") <= 6) {
  //     let data = purchaseData.multipleScheme.filter((val) => val.MutualFundUCC != "").map((item) => {
  //       return {
  //         ...item, Sipdate: moment(
  //           moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
  //         ).format("DD MMM YYYY")
  //       }
  //     })
  //     purchaseData.multipleScheme = data
  //     this.setState({
  //       selectedDate: moment(
  //         moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
  //       ).format("DD MMM YYYY")
  //     })
  //   } else {
  //     this.setState({ selectedDate: backupDropDownDate })
  //     let data = purchaseData.multipleScheme.filter((val) => val.MutualFundUCC != "").map((item) => {
  //       return { ...item, Sipdate: backupDropDownDate }
  //     })
  //     purchaseData.multipleScheme = data
  //   }
  //   this.setState({ tradeDetails: purchaseData })
  // };
  mandateOpenAndClosePopup = () => {
    this.setState({ openPopup: !this.state.openPopup })
  }
  handleCheckbox = () => {
    this.setState({ checked: !this.state.checked, sipDateValidation: false })
    let purchaseData = this.state.tradeDetails
    this.dateSetting(this.state.dropDownValue, !this.state.checked)
    let data = purchaseData.multipleScheme.filter((val) => val.MutualFundUCC != "").map((item) => {
      // console.log(item.SchemeID == detail.SchemeID ? val[0].value : item.folioNumber == undefined ? "New" : item.folioNumber)
      return { ...item, ISFirstPayment: !this.state.checked }
    })
    purchaseData.multipleScheme = data
    purchaseData.ISFirstPayment = !this.state.checked
    if (!this.state.checked) {
      this.setState({ checkedFlag: "Y" })
    } else {
      this.setState({ checkedFlag: "N" })
    }
    this.setState({ tradeDetails: purchaseData })
  }
  onChangeAmount = (event) => {
    let purchaseData = this.state.tradeDetails
    let amount = event.target.value.toString().replace("₹", "")
    if (numbersOnly(Number(replaceComma(amount)))) {
      let data = purchaseData.multipleScheme.filter((val) => val.MutualFundUCC != "").map((item) => {
        return { ...item, Amount: Number(replaceComma(amount).trim()) }
      })
      purchaseData.multipleScheme = data
      this.setState({ amount: Number(replaceComma(amount).trim()), amountErrorMessage: "", tradeDetails: purchaseData })
    }
  }
  placeBasket = (trade) => {
    // console.log(trade,this.state.placeSelectedBasket)
    let schemeDetails = this.state.placeSelectedBasket == undefined ? trade.multipleScheme : trade.multipleScheme?.filter((res) => res.SchemeId == this.state.placeSelectedBasket)
    let data = {
      SchemeData: schemeDetails.map((item) => {
        return { Amount: item.Amount, ISIN: item.ISIN }
      }),
      "ClientID": this.props.logindetails.ClientID,
      "TransactionType": trade.multipleScheme[0].InvesmentType,
      "IsFirstOrder": trade.multipleScheme[0].ISFirstPayment || "N",
      "StartDate": trade.multipleScheme[0].Sipdate || "",
      "MandateID": Number(this.state.mandateID)
    }
    let tradeDetails = this.state.tradeDetails;
    tradeDetails.multipleScheme = this.state.tradeDetails.multipleScheme.map((item, index) => {
      return {
        ...item,
        isLoading: this.state.placeSelectedBasket == undefined ? true : item.SchemeId == this.state.placeSelectedBasket
      }
    });
    PlaceBasketOrders(data).then(async (response) => {
      this.setState({ placeOrder: false })
      let res = replaceNull(response, "NA")
      // let res = {
      //   "status": "S",
      //   "message": "SUCCESS",
      //   "data": [
      //     {
      //       "SchemeId": 2633,
      //       "Status": "F",
      //       "OrderResponse": "BSE : CLIENT DOES NOT EXISTS. ORDER ENTRY FAILED"
      //     },
      //     {
      //       "SchemeId": 6608,
      //       "Status": "F",
      //       "OrderResponse": "BSE : CLIENT DOES NOT EXISTS. ORDER ENTRY FAILED"
      //     },
      //   ],
      // }
      if (res.status === "S") {
        tradeDetails.response = res.data.filter((item) => item.Status == "S")?.length != 0 ? "S" : "F",
          tradeDetails.multipleScheme = this.state.tradeDetails.multipleScheme.map((item, index) => {
            return {
              ...item,
              ID: index,
              SipFrequency: tradeDetails.SipFrequency,
              Sipdate: tradeDetails.Sipdate,
              isLoading: this.state.placeSelectedBasket == undefined ? false : item.SchemeId == this.state.placeSelectedBasket ? false : item.isLoading,
              ISFirstPayment: tradeDetails.ISFirstPayment,
              InvesmentType: tradeDetails.InvesmentType,
              Status: res.data?.filter((val) => val.SchemeId == item.SchemeId)[0]?.Status || "F",
              OrderResponse: res.data?.filter((val) => val.SchemeId == item.SchemeId)[0]?.OrderResponse || "NA"
            }
          })
        this.setState({
          tradeDetails: tradeDetails,
          activeStep: 3,
        })
      } else {
        this.setState({
          open: true,
          message: res.message,
          severity: "warning"
        })
      }
      if (this.state.placeSelectedBasket != undefined) {
        this.setState({
          open: true,
          message: res.data[0].OrderResponse,
          severity: res.data[0].Status == "S" ? "success" : "warning"
        })
      }
    })
  }
  handleMultipleScheme = async () => {
    if (!this.state.placeOrder) {
      let paymentDetails = this.state.tradeDetails
      let refNo = uniqueId()
      this.setState({ placeOrder: true, openConfirmPopup: false })
      if (paymentDetails.page == "basket") {
        this.placeBasket(paymentDetails);
        // Delay before proceeding to the next iteration
      } else {
        if (paymentDetails.InvesmentType == "Sip") {
          const API_CALL_DELAY = 1000; // Adjust as needed
          // const processSipOrders = async (paymentDetails) => {
          for (let i = 0; i < paymentDetails.multipleScheme.length; i++) {
            const scheme = paymentDetails.multipleScheme[i];
            // Perform the SIP order processing for the current scheme
            const amount = typeof scheme.Amount === 'string' ? Number(scheme.Amount.trim()) : scheme.Amount;
            const validationMessage = await SchemeCodeFinding(amount, Labels.investmentPopup.keySIP, scheme.ISIN, scheme.RTAProdCode, this.props.clientID, "SIP");
            if (validationMessage.status === "success") {
              const res = await getxSipOrder(Constant.sipPurchase.TransactionCode,
                paymentDetails.Ucc,
                validationMessage.response.SchemeCode != undefined
                  ? validationMessage.response.SchemeCode
                  : "",
                refNo,
                Constant.sipPurchase.TransMode,
                Constant.sipPurchase.DpTxnMode,
                paymentDetails.multipleScheme[i].Sipdate,
                paymentDetails.multipleScheme[i].SipFrequency,
                Constant.sipPurchase.FrequencyAllowed,
                amount,
                paymentDetails.multipleScheme[i].Instalments || paymentDetails.multipleScheme[i].installments,
                Constant.sipPurchase.Remarks,
                paymentDetails.multipleScheme[i].folioNumber != "" &&
                  paymentDetails.multipleScheme[i].folioNumber != null
                  ? paymentDetails.multipleScheme[i].folioNumber
                  : "New",
                paymentDetails.multipleScheme[i].ISFirstPayment ? "Y" : "N",
                "",
                paymentDetails.multipleScheme[i].BSESchemeType === 1
                  ? ""
                  : constants.Common.EUINNumber,
                paymentDetails.multipleScheme[i].BSESchemeType === 1 ? "N" : "Y",
                Constant.sipPurchase.DPC,
                Constant.sipPurchase.RegId,
                Constant.sipPurchase.IPAdd,
                Constant.sipPurchase.Param1,
                Constant.sipPurchase.Param2,
                Constant.sipPurchase.Param3,
                paymentDetails.multipleScheme[i].SchemeID,
                this.props.clientID,
                Number(this.state.mandateID),
                Constant.Common.Source,);
              if (res.status === "S") {
                await this.success(paymentDetails.multipleScheme[i].SchemeID, res.status, paymentDetails.multipleScheme.length - 1 == i, res, paymentDetails.multipleScheme[i].ID, refNo);
              } else {
                await this.failure(paymentDetails.multipleScheme[i].SchemeID, res.status, paymentDetails.multipleScheme.length - 1 == i, res, paymentDetails.multipleScheme[i].ID, refNo);
              }
            }
            //  else {
            //   await this.failure(paymentDetails.multipleScheme[i].SchemeID, res.status, paymentDetails.multipleScheme.length - 1 == i, res, paymentDetails.multipleScheme[i].ID);
            // }
            // Delay before proceeding to the next iteration
            await new Promise(resolve => setTimeout(resolve, API_CALL_DELAY));
          }
          // All SIP orders processed
          await this.setState({ placeOrder: false });
        }
        else {
          const API_CALL_DELAY = 1000; // Adjust as needed
          for (let i = 0; i < paymentDetails.multipleScheme.length; i++) {
            const scheme = paymentDetails.multipleScheme[i];
            const amount = typeof scheme.Amount === 'string' ? Number(scheme.Amount.trim()) : scheme.Amount;
            const validationMessage = await SchemeCodeFinding(amount,
              Labels.investmentPopup.keyOneTime,
              paymentDetails.multipleScheme[i].ISIN,
              paymentDetails.multipleScheme[i].RTAProdCode,
              this.props.clientID, "BUY");
            if (validationMessage.status === "success") {
              const res = await getOneTimeOrder(
                Constant.oneTime.TransCode,
                Constant.oneTime.OrderId,
                paymentDetails.Ucc,
                validationMessage.response.SchemeCode != undefined
                  ? validationMessage.response.SchemeCode
                  : "",
                paymentDetails.multipleScheme[i].SchemeID,
                Constant.oneTime.BuySell,
                paymentDetails.multipleScheme[i].folioNumber != "" && paymentDetails.multipleScheme[i].folioNumber != undefined && paymentDetails.multipleScheme[i].folioNumber != "NA" && paymentDetails.multipleScheme[i].folioNumber != null
                  ? "ADDITIONAL"
                  : Constant.oneTime.BuySellType,
                Constant.oneTime.DPTxn,
                amount,
                Constant.oneTime.Qty,
                Constant.oneTime.AllRedeem,
                paymentDetails.multipleScheme[i].folioNumber != "" &&
                  paymentDetails.multipleScheme[i].folioNumber != null
                  ? paymentDetails.multipleScheme[i].folioNumber
                  : "",
                Constant.oneTime.Remarks,
                Constant.oneTime.KYCStatus,
                refNo,
                "",
                paymentDetails.multipleScheme[i].BSESchemeType === 1
                  ? ""
                  : constants.Common.EUINNumber,
                paymentDetails.multipleScheme[i].BSESchemeType === 1 ? "N" : "Y",
                Constant.oneTime.MinRedeem,
                Constant.oneTime.DPC,
                Constant.oneTime.IPAdd,
                Constant.oneTime.Param1,
                Constant.oneTime.Param2,
                Constant.oneTime.Param3,
                this.props.logindetails.ClientID,
                Constant.Common.Source,
                paymentDetails.multipleScheme[i].ID,
              )
              if (res.status === "S") {
                await this.success(paymentDetails.multipleScheme[i].SchemeID, res.status, paymentDetails.multipleScheme.length - 1 == i, res, paymentDetails.multipleScheme[i].ID);
              } else {
                await this.failure(paymentDetails.multipleScheme[i].SchemeID, res.status, paymentDetails.multipleScheme.length - 1 == i, res, paymentDetails.multipleScheme[i].ID);
              }
            } else {
              await this.failure(paymentDetails.multipleScheme[i].SchemeID, res.status, paymentDetails.multipleScheme.length - 1 == i, res, paymentDetails.multipleScheme[i].ID);
            }
            // Delay before proceeding to the next iteration
            await new Promise(resolve => setTimeout(resolve, API_CALL_DELAY));
          }
          // All SIP orders processed
          await this.setState({ placeOrder: false });
        }
      }
    }
  }
  onChangeInstallment = (event) => {
    let purchaseData = this.state.tradeDetails
    if (numbersOnly(replaceComma(event.target.value))) {
      let data = purchaseData.multipleScheme.filter((val) => val.MutualFundUCC != "").map((item) => {
        return { ...item, Instalments: Number(replaceComma(event.target.value)) }
      })
      purchaseData.multipleScheme = data
      this.setState({ noOfInstalment: event.target.value, instalmentErrorMessage: "", tradeDetails: purchaseData })
    }
  }
  handleChange = () => {
    this.props.history.push(labels.Path.Scheme)
  }
  handleBack = () => {
    if (this.state.activeStep == 1) {
      this.setState({ validateMandate: false })
    }
    this.setState({ activeStep: this.state.activeStep - 1 })
  };
  getmandateID = (value) => {
    this.setState({ mandateID: value })
    if (this.state.tradeDetails.InvesmentType == "Sip") {
      // if (sevenDaysLogic(paymentDetails.ISFirstPayment)) {
      if (value != undefined && value != "" && this.state.tradeDetails.Ucc != "") {
        this.setState({ mandateValidation: false })
        //setMandateValidation(false);
      } else {
        this.setState({ mandateValidation: true })
        // setMandateValidation(true);
      }
      // setDateError("");
      this.setState({ dateError: "" })
      // } else {
      //   setDateError(labels.bigPopup.dateMismatch);
      // }
    } else {
      if (this.state.tradeDetails.Ucc != "") {
        this.setState({ mandateValidation: false })
      } else {
        this.setState({ mandateValidation: true })
      }
    }
  };
  sevenDaysLogic = (isChecked, checkdate) => {
    let todayDate = moment().format("DD MMM YYYY");
    let date = this.state.selectedDate == "" || this.state.selectedDate == undefined ? checkdate == "" || checkdate == undefined ? todayDate :
      checkdate
      : this.state.selectedDate
    date = formatDate(date)
    let validation = moment.duration(moment(date, "DD MMM YYYY").diff(moment(todayDate, "DD MMM YYYY"))).asDays() >= 0
    array.push(validation)
    if (!validation && typeof (this.state.validateMandate) != "string") {
      // console.log("hihihasd")
      this.setState({ validateMandate: "dateerror" })
    }
    return validation ? "" : labels.bigPopup.dateMismatch
  };
  getMandateDetails = (details) => {
    this.setState({ mandateDetails: details })
  }
  onMandateChange = (value) => {
    this.setState({ mandateAccountNumber: value[0].label, showBankDetails: false })
    if (value.length != 0) {
      if (value[0].value != "") {
        this.setState({ loader: true, validationMessage: "", errorMandateMessage: "" })
        MandateStatusCheck(value[0].value, this.state.tradeDetails?.Ucc)
          .then((data) => {
            this.setState({ loader: false })
            if (data != undefined) {
              if (data.status == "S") {
                this.setState({
                  showBankDetails: true, mandateDetails: data.data,
                  bankBranch: data.data[0].bankBranch,
                  ifsc: this.props.bankListing.length != 0 && this.props.bankListing.filter((item) => item.BankAccountNumber === data.data[0].bankAccNo).length != 0 ? this.props.bankListing.filter((item) => item.BankAccountNumber === data.data[0].bankAccNo)[0].IFSCCode : "",
                  mandateStatus: data.data[0].status,
                  bankName: data.data[0].bankName,
                  mandateID: data.data[0].mandateId,
                  accountType: this.props.bankListing.length != 0 && this.props.bankListing.filter((item) => item.BankAccountNumber === data.data[0].bankAccNo).length != 0 ? this.props.bankListing.filter((item) => item.BankAccountNumber === data.data[0].bankAccNo)[0].AccountType : "",
                  bankAccountNumber: data.data[0].bankAccNo
                })
                let mandateData = data.data[0]
                mandateData.IFSC_Code = this.props.bankListing.length != 0 && this.props.bankListing.filter((item) => item.BankAccountNumber === data.data[0].bankAccNo).length != 0 ? this.props.bankListing.filter((item) => item.BankAccountNumber === data.data[0].bankAccNo)[0].IFSCCode : ""
                mandateData.AccountType = this.props.bankListing.length != 0 && this.props.bankListing.filter((item) => item.BankAccountNumber === data.data[0].bankAccNo).length != 0 ? this.props.bankListing.filter((item) => item.BankAccountNumber === data.data[0].bankAccNo)[0].AccountType : ""
                this.getMandateDetails([mandateData]);
                if (this.state.validateMandate == "dateerror") {
                  this.setState({ validateMandate: true, validationMessage: "SIP date is not allowed,please update the cart and place the order" })
                } else {
                  if (this.state.tradeDetails.multipleSelect) {
                    if (data.data[0].status.toLowerCase() == "approved") {
                      // setValidation(false)
                      this.setState({ validateMandate: false, validationMessage: "" })
                    } else {
                      if (this.state.tradeDetails.multipleScheme.filter((item) => !item.ISFirstPayment).length > 0) {
                        // setValidation(true)
                        this.setState({ validationMessage: labels.messages.InvalidMandateID, validateMandate: true, })
                        // setValidationMessage(labels.messages.InvalidMandateID)
                      } else {
                        this.setState({ validateMandate: false, validationMessage: "" })
                      }
                    }
                  } else {
                    if (Number(this.state.amount) > data.data[0].amount) {
                      this.setState({ validationMessage: Label.Bank.AmountValidation, validateMandate: true, })
                    } else {
                      if (this.state.tradeDetails.ISFirstPayment) {
                        this.setState({ validateMandate: false, })
                      } else {
                        if (data.data[0].status.toLowerCase() == "approved") {
                          this.setState({ validateMandate: false, })
                        } else {
                          this.setState({ validateMandate: true, })
                          this.setState({ validationMessage: labels.messages.InvalidMandateID })
                        }
                      }
                    }
                  }
                }
              } else {
                this.setState({ errorMandateMessage: data.message })
              }
            }
          })
      }
    }
  }
  dropdowndata = async (value) => {
    let todayDate = moment(new Date()).format("DD MMM YYYY");
    if (value.length != 0) {
      this.setState({ currentDateBackUp: "" })
      this.setState({ validationMessage: "" });
      let dropdownDate = moment(value[0].value, "DD MMM YYYY").format(
        "DD MMM YYYY"
      );
      dropdownDate = moment(dropdownDate).isBefore(todayDate) ? moment(
        moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
      ).format("DD MMM YYYY") : dropdownDate
      this.setState({
        startDate: labels.Date.filter((item) => item.value == value[0].value)[0]
          .label,
        dropDownValue: value[0].value
      });
      await this.setState({ currentDateBackUp: dropdownDate });
      // console.log(value[0])
      this.dateSetting(value[0].value, this.state.checked);
    }
  };
  dateSetting = (date, isChecked) => {
    let todayDate = moment(new Date()).format("DD MMM YYYY");
    let dropdownDate = moment(date, "DD MMM YYYY").format("DD MMM YYYY");
    let purchaseData = this.state.tradeDetails
    if (isChecked && !moment(dropdownDate).isBefore(todayDate) && moment(dropdownDate).diff(moment().format("DD MMM YYYY"), "days") <= 6 || moment(dropdownDate).diff(moment().format("DD MMM YYYY"), "days") > 6) {
      this.setState({
        selectedDate: moment(
          moment(dropdownDate, "DD MMM YYYY").toString()
        ).format("DD MMM YYYY"),
      });
    }
    else {
      this.setState({
        selectedDate: moment(
          moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
        ).format("DD MMM YYYY"),
      });
      dropdownDate = moment(
        moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
      ).format("DD MMM YYYY")
    }
    let data = purchaseData.multipleScheme.filter((val) => val.MutualFundUCC != "").map((item) => {
      return { ...item, Sipdate: dropdownDate }
    })
    purchaseData.multipleScheme = data
    this.setState({ tradeDetails: purchaseData })
  };
  onMandatePopupClose = (value, val) => {
    this.mandateOpenAndClosePopup()
    if (value.status === "S") {
      this.setState({ severity: "success" })
    } else {
      this.setState({ severity: "warning" })
    }
    this.setState({ open: true, message: value.message })
  }
  handleSnackBarClose = () => {
    this.setState({ open: false })
  };
  findDate = (val) => {
    let a = Number(moment().format("DD"))
    let arr = [...Array(6)].map((item, i) => {
      return a + i
    })
    return arr.includes(Number(val))
  }
  saveForLater = () => {
    if (!this.state.showSaveForLater) {
      let folioUpdate = this.state.tradeDetails.multipleScheme.map((item) => {
        return {
          cartID: item.ID, folioNo: item.folioNumber
        }
      })
      UpdateFolioCart(folioUpdate).then((res) => {
        if (res.status == "S") {
          this.setState({ showSaveForLater: true })
          this.setState({ open: true, message: "Scheme's Saved For Later", severity: "success" })
        } else {
          this.setState({ showSaveForLater: false, open: true, message: res.message, severity: "warning" })
        }
      })
    }
  }
  checkDateValid = (IsFirstPayment, date) => {
    let todayDate = ""
    let dropdownDate = ""
    if (!moment.isMoment(moment().format("DD MMM YYYY"))) {
      todayDate = moment(moment().format("DD MMM YYYY"));
    }
    if (!moment.isMoment(date)) {
      dropdownDate = moment(date);
    }
    let isValidDate = IsFirstPayment ? moment(dropdownDate).isBefore(todayDate) : dropdownDate.diff(todayDate, "days") <= 6 && moment(dropdownDate).isBefore(todayDate)
    // console.log(isValidDate)
    return isValidDate
  }
  render() {
    const { classes } = this.props;
    try {
      return (
        <ErrorBountry>
          <Stepper
            alternativeLabel
            activeStep={this.state.activeStep}
            connector={<QontoConnector />}
          >
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <div className={classes.gridContainer}>
            <div>
            </div>
            <div className={this.state.activeStep == 1 ? classes.twoSplitContanier : classes.oneContainer}>
              <div className={classes.card}>
                {this.state.activeStep == 0 &&
                  <Investment
                    page={this.props.location.state.data.page}
                    handleChange={this.handleChange}
                    data={this.state.tradeDetails}
                    dropDownValue={this.state.dropDownValue}
                    minimumInstalment={this.state.minimumInstalment}
                    amount={this.state.amount}
                    amountErrorMessage={this.state.amountErrorMessage}
                    startDate={this.state.startDate}
                    installments={this.state.noOfInstalment}
                    instalmentErrorMessage={this.state.instalmentErrorMessage}
                    onchangeAmount={this.onChangeAmount}
                    onchangeInstallment={this.onChangeInstallment}
                    handleCheckbox={this.handleCheckbox}
                    checked={this.state.checked}
                    dropdowndata={this.dropdowndata}
                    selectedDate={this.state.selectedDate}
                  />
                }
                {this.state.activeStep == 1 &&
                  <PaymentDetails
                    sipAmount={this.state.amount}
                    // type={type}
                    showSaveForLater={this.state.showSaveForLater}
                    saveForLater={this.saveForLater}
                    mandateAccountNumber={this.state.mandateAccountNumber}
                    data={this.state.tradeDetails}
                    onMandateChange={this.onMandateChange}
                    showBankDetails={this.state.showBankDetails}
                    onSuccess={this.onMandatePopupClose}
                    validationMessage={this.state.validationMessage}
                    mandateDetails={this.state.mandateDetails}
                    errorMandateMessage={this.state.errorMandateMessage}
                    UCC={this.state.tradeUcc}
                    loader={this.state.loader}
                    open={this.state.openPopup}
                    mandateOpenAndClosePopup={this.mandateOpenAndClosePopup}
                    folioChange={this.folioChange}
                    folioNumber={this.state.folioNumber}
                    folioNumberList={this.state.folioNumberList}
                    errorFolioNumber={this.state.errorFolioNumber}
                  />
                }
                {this.state.activeStep >= 2 &&
                  <PaymentConfirmation
                    confirmOrder={this.confirmOrder}
                    //folioNumber={folioNumber}
                    page={this.props.location.state.data.page}
                    response={this.state.tradeResponse}
                    data={this.state.tradeDetails}
                    mandateDetails={this.state.mandateDetails}
                    activeStep={this.state.activeStep}
                    //accountno={tradeAccountNumber}
                    UCC={this.props.location.state.data.UCC}
                  />
                }
                {this.state.activeStep < 2 && <footer style={{ display: "flex", flexDirection: "row", borderTop: `1px solid ${colors.PRIMARY_BORDER}`, gap: 30, padding: "16px 16px", justifyContent: "space-between" }}>
                  <div style={{ minWidth: "20%" }}>
                    <Button
                      page="secondary"
                      disabled={
                        this.state.activeStep === 0 ||
                        this.state.tradeDetails.StepperActive === 1 || this.state.isLoading
                      }
                      onClick={this.handleBack}
                      className={
                        [classes.button, classes.prevButton
                        ].join(" ")}
                      name={Label.Investment.Previous}
                    >
                    </Button>
                  </div>
                  <div style={{ minWidth: "20%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <Button
                      page="primary"
                      onClick={
                        this.handleNext
                      }
                      className={classes.btn}
                      loader={this.state.placeOrder}
                      disabled={this.state.validateMandate}
                      name={this.state.activeLabel}
                    >
                    </Button>
                  </div>
                </footer>}
              </div>
              {
                this.state.activeStep == 1 && <Summary
                  page={this.props.location.state.data.page}
                  type={this.state.tradeDetails.InvesmentType}
                  amount={this.state.amount}
                  frequency={this.state.frequency}
                  Date={this.state.selectedDate}
                  summaryDetails={this.state.tradeDetails}
                  folioChange={this.folioChange}
                  dateError={this.state.sipDateValidation}
                  sevenDaysLogic={this.sevenDaysLogic}
                  folioNumber={this.state.folioNumber}
                  folioNumberList={this.state.folioNumberList}
                />
              }
            </div>
            <div></div>
          </div>
          <Deletepopup open={this.state.openRedirectPopup} showdefault={false}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 38, padding: 30 }}>
              <Loader size={40} page="progress" value={this.state.count} thickness={6} color={"#af762e"}></Loader>
              <div>Redirecting to Payment GateWay....</div>
            </div>
          </Deletepopup>
          <Deletepopup header={"Confirmation"} text={labels.messages.placeOrderMsg} open={this.state.openConfirmPopup} onClose={() => this.setState({ openConfirmPopup: false })} onClick={this.handleMultipleScheme}>
          </Deletepopup>
          <CommonSnackBar
            open={this.state.open}
            handlesnanckClose={this.handleSnackBarClose}
            severity={this.state.severity}
            message={this.state.message}
          />
        </ErrorBountry>
      )
    } catch (err) {
      // console.log(err)
      return <Redirect to={labels.Path.NotFound}></Redirect>
    }
  }
}

const mapStateToProps = (state) => ({
  mappingDetails: state.SchemeDetails.mappingDetails,
  folioNumber: state.SchemeDetails.folioNumber,
  PurchaseOrder: state.PurchaseOrder.purchaseOrder,
  isLoading: state.PurchaseOrder.isLoading,
  clientID: state.Login.logindetails.ClientID,
  logindetails: state.Login.logindetails,
  BSEMFAccountList:
    state.L5report.L5report != null &&
      state.L5report.L5report != undefined &&
      state.L5report.L5report != "NA" &&
      state.L5report.L5report.length != 0
      ? [
        ...state.L5report.L5report.map((item) => {
          return {
            ...item,
            label: `${item.PortfolioName}(${item.MutualFundUCC})`,
            value: item.L5ID,
          };
        }),
      ]
      : [],
  bankListing: state.ProfileDetails.AllProfileDetails.bankDetails == labels.messages.NA ? [] : state.ProfileDetails.AllProfileDetails.bankDetails,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { OneTimeOrder, XSipOrder, getPurchaseOrder, DeleteOnetimeCartReducer, DeleteSipCartReducer },
    dispatch
  );
};

export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Payment))
);