import fileDownload from 'js-file-download'
var axios = require('axios');
function getESignDownloadPdf(url, ext, filename) {

    return axios(url, {
        method: 'GET',
        responseType: 'blob' //Force to receive data in a Blob Format
    })
        .then(response => {
            //Create a Blob from the PDF Stream
            const file = new Blob(
                [response.data],
                { type: `${"application/"}${ext}` });
           
            if (ext === "pdf") {
                fileDownload(response.data, filename)
            }
            return 'success'
        })
        .catch(error => {
            return 'fail'
        });
}
export default getESignDownloadPdf