import React from "react";
import { withStyles } from '@material-ui/core';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle'
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import colors from "../../utils/colors/colors";
import TextField from '@material-ui/core/TextField';
import Color from "../../utils/colors/colors"
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import InputAdornment from '@material-ui/core/InputAdornment';
const styles = theme => ({
    text: {
        height: "39px",
        //  width: 241,
        border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        color: window.globalConfig.color.BLACK_PRIMARY,
        paddingLeft: "13px",
        outline: "none !important",
        fontSize: "15px",
        '&:focus': {
            border: `1px solid ${window.globalConfig.color.BROWN} !important`,
        },

        [theme.breakpoints.down("600")]: {
            // width: "98%",
            height: "38px",
            border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
            color: window.globalConfig.color.BLACK_PRIMARY,
            outline: "none !important",
            fontSize: "13px",

            fontWeight: "600"
        },
    },
    textInput: {
        height: "38px",
        width: "80%",
        border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        color: window.globalConfig.color.BLACK_PRIMARY,
        outline: "none !important",
        fontSize: "15px",
        paddingLeft: "13px",
        paddingTop: "2.5px",
        paddingBottom: "2.5px",

        [theme.breakpoints.between("320", "499")]: {
            width: "95%",
            height: "38px",
            border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
            color: window.globalConfig.color.BLACK_PRIMARY,
            outline: "none !important",
            fontSize: "14px",

        },
        [theme.breakpoints.between("500", "600")]: {
            width: "96%",
            height: "38px",
            border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
            color: window.globalConfig.color.BLACK_PRIMARY,
            outline: "none !important",
            fontSize: "14px",

        },
        '&:focus': {
            border: `1px solid ${window.globalConfig.color.BROWN} !important`,
        }

    },
    textInputprofile: {
        height: "40.8px",
        width: "auto",
        border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        color: window.globalConfig.color.BLACK_PRIMARY,
        outline: "none !important",
        fontSize: "15px",
        paddingLeft: "13px",

        [theme.breakpoints.between("320", "499")]: {
            width: "94%",
            height: "38px",
            border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
            color: window.globalConfig.color.BLACK_PRIMARY,
            outline: "none !important",
            fontSize: "14px",

        },
        [theme.breakpoints.between("500", "600")]: {
            width: "96%",
            height: "38px",
            border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
            color: window.globalConfig.color.BLACK_PRIMARY,
            outline: "none !important",
            fontSize: "14px",


        },
        '&:focus': {
            border: `1px solid ${window.globalConfig.color.BROWN} !important`,
        }
    },
    Flex: {
        display: "flex",
        flexDirection: "column",
        minHeight: '90px',
        // [theme.breakpoints.down("xs")]: {
        //     marginRight: "22px"

        // },
    },
    FlexRow: {
        display: "flex",
        flexDirection: "row",
        //  minHeight: '64px',
        alignItems: "baseline",
        columnGap: 10
        // [theme.breakpoints.down("xs")]: {
        //     marginRight: "22px"

        // },
    },
    txtDisabled: {
        display: "flex",
        flexDirection: "column",
        opacity: 0.5,
        cursor: 'not-allowed !important',
        [theme.breakpoints.down("xs")]: {
            marginRight: "10px"

        },
    },
    label: {
        color: window.globalConfig.color.LABLE_GRAY, fontSize: 14, textAlign: 'left', paddingBottom: 6,
        // [theme.breakpoints.down("xs")]: {
        //     fontSize: 12,
        //     fontWeight: "500",
        //     width: "48vw"
        // },
    }

});
class TextInput extends React.Component {
    //  (props)cons
    constructor(props) {
        super(props);
        this.state = {
            type: false
        }
    }
    handleFocus = (event) => event.target.select();
    onClick = () => {
        this.setState({ type: !this.state.type })
    }
    onMouseDown = () => {
        this.setState({ type: !this.state.type })
    }
    render() {
        const { classes } = this.props
        if (this.props.page === "normal") {
            return (

                <div disabled={this.props.disabled} className={this.props.disabled ? classes.txtDisabled : classes.Flex}>
                    {this.props.label != undefined && <span className={classes.label}>{this.props.label}</span>
                    }<input
                        placeholder={this.props.placeholder}
                        type={this.props.encrypted == undefined ? this.props.type == undefined ? "text" : this.props.type : "password"}
                        value={this.props.value}
                        onFocus={this.props.onFocus}
                        className={[classes.text, "text-field-cursor"].join(' ')}
                        id={this.props.id}
                        style={{ textAlign: this.props.align != undefined ? this.props.align : "left", cursor: this.props.disabled ? "not-allowed" : "pointer", paddingRight: this.props != undefined ? this.props.paddingAlign : "0px", paddingLeft: this.props != undefined ? this.props.paddingLeftAlignment : "13px" }}
                        {...this.props.otherProps}
                        autoComplete="new-password"
                        disabled={this.props.disabled}
                        onChange={this.props.handleChange}
                    />
                    {this.props.error == "" ?
                        <span style={{ color: window.globalConfig.color.PRIMARY_BORDER, fontSize: 13, textAlign: "left", width: "77%" }}>{this.props.message}</span>
                        :
                        <span style={{ color: window.globalConfig.color.red, fontSize: 13, textAlign: "left", width: "77%" }}>{this.props.error}</span>
                    }
                </div>
            );
        }
        if (this.props.page === "sideLabel") {
            return (

                <div disabled={this.props.disabled} className={this.props.disabled ? classes.txtDisabled : classes.FlexRow}>
                    {this.props.label != undefined && <span className={classes.label}>{this.props.label}</span>
                    }
                    <div style={{ display: "flex", minHeight: 64, alignItems: "flex-start", flexDirection: "column" }}>
                        <input
                            placeholder={this.props.placeholder}
                            type={this.props.encrypted == undefined ? "text" : "password"}
                            value={this.props.value}
                            onFocus={this.props.onFocus}
                            className={[classes.text, "text-field-cursor"].join(' ')}
                            id={this.props.id}
                            style={{ textAlign: this.props.align != undefined ? this.props.align : "left", paddingRight: this.props.paddingAlign != undefined ? this.props.paddingAlign : "7px", paddingLeft: this.props.paddingLeftAlignment != undefined ? this.props.paddingLeftAlignment : "13px", width: 90 }}
                            {...this.props.otherProps}
                            autoComplete="new-password"
                            disabled={this.props.disabled}
                            onChange={this.props.handleChange}
                        />
                        {this.props.error == "" ?
                            <span style={{ color: window.globalConfig.color.PRIMARY_BORDER, fontSize: 13, textAlign: "left" }}>{this.props.message}</span>
                            :
                            <span style={{ color: window.globalConfig.color.red, fontSize: 13, textAlign: "left" }}>{this.props.error}</span>
                        }
                    </div>
                </div>
            );
        }
        else if (this.props.page == "TextField") {
            return (<>
                <TextField
                    // id="standard-basic"
                    name={this.props.label}
                    label={this.props.label}
                    autoFocus={this.props.autoFocus}
                    value={this.props.value}
                    size='medium'
                    className="text-field-cursor"
                    onBlur={this.props.onBlur}
                    inputProps={this.props.inputProps == undefined ? {
                        autoComplete: 'new-password'
                    } : this.props.inputProps}
                    style={this.props.style}
                    onChange={(e) => { this.props.onChange(e) }}
                    disabled={this.props.disabled}
                />
                <span style={{ float: "left", color: Color.red, width: "100%", textAlign: "left", fontSize: "small" }}>{this.props.errormessage}</span>
            </>)
        }
        else if (this.props.page == "TextFieldpassword") {
            return (<>
                <TextField
                    name="newpassword"
                    type={this.state.type ? 'text' : 'password'}
                    label={this.props.label}
                    size='medium'
                    onFocus={this.props.onFocus}
                    //    style={{ marginTop: "7%" }}
                    style={this.props.style}
                    value={this.props.value}
                    autoFocus={this.props.autoFocus}
                    onKeyDown={(e) => {
                        if (this.props.onKeyDown === undefined) {

                        } else {
                            this.props.onKeyDown(e)
                        }
                    }}
                    onChange={(e) => { this.props.onChange(e) }}
                    InputProps={{
                        autoComplete: 'new-password',
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={() => {
                                        // if (this.props.onClick === undefined) {
                                        this.onClick()
                                        // } else {
                                        //     this.props.onClick()
                                        // }
                                    }}
                                    onMouseDown={() => {
                                        // if (this.props.onMouseDown === undefined) {
                                        this.onMouseDown()
                                        // } else {
                                        //     this.props.onMouseDown()
                                        // }
                                    }}
                                    className="passwordShowIcon"
                                >
                                    {this.state.type ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    className={[classes.field, "text-field-cursor"].join(' ')}
                    {...this.props.otherProps}
                />
                <span style={{ float: "left", color: Color.red, width: "100%", textAlign: "left", fontSize: "small" }}>{this.props.errormessage}</span>
            </>)
        }
        else if (this.props.page == "lineTextbox") {
            return (
                <div className={[classes.desktopResponsive, this.props.className].join(" ")} style={{ display: "flex", flexDirection: "column", textAlign: "left", opacity: this.props.disabled ? 0.5 : 1, }}>
                    <span style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 14, textAlign: 'left', paddingBottom: 6 }}>{this.props.label}</span>
                    <TextField
                        id="standard-full-width"
                        style={{ textAlign: "center", marginTop: "7%" }}
                        className={[classes.textAligns, classes.textFieldCursor]}
                        placeholder="₹0"
                        margin="normal"
                        value={this.props.value}
                        // label=""
                        disabled={this.props.disabled}
                        onChange={this.props.handleChange}
                    // {...this.props}
                    />
                    {this.props.error == "" ?
                        <span style={{ color: window.globalConfig.color.PRIMARY_BORDER, fontSize: 13, }}>{this.props.message}</span>
                        :
                        this.props.error != "" && <span style={{ color: window.globalConfig.color.red, fontSize: 13, }}>{this.props.error}</span>}

                </div>
            )
        }
        else {
            return (

                <div className={classes.desktopResponsive} style={{ display: "flex", flexDirection: "column", textAlign: "left", opacity: this.props.disabled ? 0.5 : 1, }}>
                    <span style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 14, textAlign: 'left', paddingBottom: 6 }}>{this.props.label}</span>
                    <input
                        placeholder={this.props.placeholder}
                        type="text"
                        value={this.props.value}

                        className={[classes.textInputprofile, "text-field-cursor"].join(' ')}
                        id={this.props.id}
                        disabled={this.props.disabled}
                        onChange={(e) => {
                            if (this.props.disabled == true) {
                                e.preventDefault()
                            }
                            else {
                                this.props.handleChange(e)
                            }
                        }}
                        onFocus={this.props.onFocus}
                        style={{ fontSize: 14, textAlign: this.props.align != undefined ? this.props.align : "left", paddingRight: this.props != undefined ? this.props.paddingAlign : "0px" }}
                        {...this.props.otherProps}
                    />
                    {this.props.error == "" ?
                        <span style={{ color: window.globalConfig.color.PRIMARY_BORDER, fontSize: 13, }}>{this.props.message}</span>
                        :
                        this.props.error != "" && <span style={{ color: window.globalConfig.color.red, fontSize: 13, }}>{this.props.error}</span>}

                </div>

            );
        }

    }

}
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles(mergedStyle)(TextInput)
