import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import labels from '../../utils/constants/labels';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Summary from '../../components/summary/index';
import Table from '../../components/table/profitlossreport';
import Portifolio from '../portfolio-x-ray/portfolio-x-ray'
import CapitalGain from '../capitalgain/capitalgain'
import SIPTracker from '../siptracker/siptracker'
import AccountStatement from '../accountstatement/accountstatement'
import PortfolioReport from '../portfolioreport/portfolioreport'
import AssetAllocation from '../assetallocation/assetallocation'
import AFRReport from '../afr_report/afr'
import RunningSIP from '../runningsip/runningsip'
import Transaction from '../transaction/transactionreport'
import XrayWithRedemption from '../x-raywithredemption/x-raywithredemption'
import MarketCapAllocation from '../marketcapallocation/marketcapallocation'
import '../../utils/stylesheets/tabs/tabs.css'
import secureStorage from '../../utils/securestorage';
import { connect } from 'react-redux';
import { replaceNull, sortArray } from '../../utils/commonfunction';
import Label from '../../utils/constants/labels';
import {
  filterChange,

} from "../../redux/actions/portfolioxray/portfolioxray";
import { transactionfilter } from '../../redux/actions/transaction/actions';
import { getProfitLossDetails, profitandlossfilter } from '../../redux/actions/profitloss/profitlossaction'
import { bindActionCreators } from "redux";
import combineStyle from "../../utils/stylesheets/combainstyle/combainstyle";
import commonstyletable from "../../utils/stylesheets/table/commontablestyle";
import getMargetCapWiseList from '../../api/portfolioxrayreport/margetcapwise';
import moment from 'moment';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Typography>{children}</Typography>      
        <div
          style={{
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: '0.00938rem',
          }}
        >{children}</div>

      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const Styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
  },
  tabRoot: {
    "& .MuiTab-root:focus": {
      color: window.globalConfig.color.BROWN
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: window.globalConfig.color.BROWN
    }
  },
  portfolioXrayCatrgorySevenSplitContainer: {
    display: "grid",
    gridTemplateColumns: "27% 12% 14% 14% 14% 14% 5% !important",
    // paddingLeft: "10px"
    // marginLeft: "20px",
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr) !important',
      gridTemplateAreas: `"categorySchemeGrid categoryMarketValueGrid"
      
     `,
      "& .categoryAbsoluteGainLossGrid": {
        display: "none"
      },
      "& .categoryUnitGrid": {
        display: "none"
      }
    }
  },
  portfolioXraySevenSplitContainer: {
    display: "grid",
    gridTemplateColumns: "27% 12% 14% 14% 14% 14% 5% !important",
    // paddingLeft: "10px"
    // marginLeft: "20px",
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr) !important',
      gap: 1,
      gridTemplateRows: 'auto',
      paddingRight: 10,
      paddingTop: 10,
      gridTemplateAreas: `"schemeFirstGrid schemeFifthGrid  myLastGrid"
  "schemeSecondGrid . schemeSixthGrid"
  "schemeThirdGrid . investedAmountGrid"`,
      "& .schemeFourthGrid": {
        display: "none"
      }
      , "& .categoryAbsoluteGainLossGrid": {
        display: "none"
      },
      "& .categoryMarketValueGrid": {
        display: "none !important"
      }

    },


  },
  sipTitleContainer: {
    display: "grid",
    gridTemplateColumns: "32% 11% 16% 13% 15% 11%",
    //marginLeft: "20px",
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "100%",

      padding: 10,
      gap: 10
    }
  },
  sipCategoryContainer: {
    display: "grid",
    gridTemplateColumns: "32% 11% 16% 13% 15% 11%",
    // marginLeft: "20px",
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(3,1fr)",
      padding: 10,
      gap: 10
    }
  },
  sipTrackerSevenSplitContainer: {
    display: "grid",
    gridTemplateColumns: "32% 11% 16% 13% 15% 11%",
    //marginLeft: "20px",
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr)",
      gridTemplateAreas: `"${"firstColumn"} ${"secondColumn"}"
      "${"firstColumn"} ${"thirdColumn"}"
      "${"firstColumn"} ${"fourthColumn"}"
      "${"fifthColumn"} ${"sixthColumn"} "
    `,
      padding: 10,
      gap: 10
    }
  },
  marketContainer: {
    display: "grid",
    gridTemplateColumns: "35% 13% 13% 13% 13% 13%",
  },
  transactionContainer: {
    display: "grid",
    gridTemplateColumns: "10% 25% 10% 10% 12% 12% 10% 11%",
    marginLeft: "20px",
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr)",
      gridTemplateAreas: `"${"dategrid"} ${"dategrid"}"
      "${"schemegrid"} ${"schemegrid"}"
      "${"assetClassgrid"} ${"transactionTypegrid"}"
      "${"folioNumbergrid"} ${"unitgrid"}"
      "${"navgrid"} ${"amountgrid"}"`,
      padding: 10,
      gap: 10
    }
  },
  BookedglContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(6,1fr)",
    marginLeft: "7px",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr)",
      gridTemplateAreas: `"${"firstGrid"} ${"sixthGrid"}"
                         "${"thirdGrid"} ${"fourthGrid"}"
                         "${"secondGrid"} ${"fifthGrid"}"`,
    }
  },
  runningSipContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(8,1fr)",
    // marginLeft: "20px",

    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr)",
      gridTemplateAreas: `"${"firstGrid"} ${"eighthGrid"}"
      "${"fifthGrid"} ${"secondGrid"}"
      "${"fourthGrid"} ${"sixthGrid"}"
      "${"thirdGrid"} ${"seventhGrid"}"`,
      padding: 10,
      gap: 10
    }
  },
  marketcapContainer: {
    display: "grid",
    gridTemplateColumns: "35% 13% 13% 13% 13% 13%",
    // marginLeft: "20px",
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr)",
      gridTemplateAreas: `"${"firstGrid"} ${"sixthGrid"}"
      "${"secondGrid"} ${"thirdGrid"}"
      "${"fourthGrid"} ${"fifthGrid"}"`,
      padding: 10,
      gap: 10,
      "& .secondGrid": {
        textAlign: "left"
      },
      "& .fourthGrid": {
        textAlign: "left"
      }
    }
  },
  portfolioTwoSplitContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    [theme.breakpoints.down("920")]: {
      backgroundColor: "transparent !important",
      minHeight: "4% !important",
      alignContent: "end",
      marginTop: "1% !important"
    }
  },
  portfolioReportEightSplitContainer: {
    display: "grid",
    gridTemplateColumns: "30% 10% 10% 10% 10% 10% 10% 10%",
    // marginLeft: "20px",
    padding: 8,

    alignContent: "center",
    "& .firstGrid": {
      alignItems: "flex-start",
      textAlign: "left"
    },
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(3,1fr)",
      gridTemplateAreas: `"${"firstGrid"} ${"firstGrid"} ${"eighthGrid"}"
      "${"secondGrid"} ${"thirdGrid"} ${"fourthGrid"} "
      " ${"fifthGrid"} . ${"sixthGrid"}"`,
      gap: 10,
      "& .firstGrid": {
        alignItems: "flex-start",
        textAlign: "left"
      },
      "& .secondGrid": {
        textAlign: "left"
      },
      "& .thirdGrid": {
        // alignItems: "flex-start",
        textAlign: "center"
      },
      "& .fourthGrid": {
        alignItems: "center"
      },
      "& .fifthGrid": {
        textAlign: "left"
      },
      "& .sixthGrid": {
        alignItems: "flex-start",
        textAlign: "right"
      },
      "& .seventhGrid": {
        alignItems: "center",
        display: "none"
      },
      "& .eighthGrid": {
        alignItems: "flex-end"
      }
    }
    // columnGap:'1%',
    // paddingLeft: "10px"
  },
  portfolioReportTenSplitContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(10,1fr)",
    alignContent: "center",
    "& .ninthGrid": {
      textAlign: "left"
    },
    paddingRight: 10,
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(3,1fr) !important",
      gridTemplateAreas: `"${"ninthGrid"} ${"twelfthGrid"} eighteenthGrid"
      "${"tenthGrid"} ${"thirteenthGrid"} ${"fifteenthGrid"} "
      " ${"eleventhGrid"} ${"seventeenthGrid"} ${"sixteenthGrid"}"
      `,
      padding: 10,
      gap: 10,
      "& .ninthGrid": {
        textAlign: "left"
      },
      "& .tenthGrid": {
        textAlign: "left"
      },
      "& .fourteenth": {
        display: "none",
      },
      "& .fifteenthGrid": {
        //  display: "none",
        textAlign: "right"
      },
      "& .seventeenthGrid": {
        textAlign: "center",
      },
      "& .sixteenthGrid": {
        // display: "none",
        textAlign: "right",
        alignItems: "center"
      },
      "& .eleventhGrid": {
        textAlign: "left",
      },
      "& .thirteenthGrid": {
        textAlign: "center"
      },
      "& .twelfthGrid": {
        textAlign: "center"
      },
      "& .eighteenthGrid": {
        textAlign: "right"
      }
    }

  },

  accountStatementContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(6,1fr)",
    // marginLeft: "20px",
    alignContent: "center",
    paddingLeft: 10,
    paddingRight: 10,
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(3,1fr)",
      gridTemplateAreas: `"${"firstGrid"} ${"secondGrid"} ${"thirdGrid"} "
      "${"fourthGrid"} ${"fifthGrid"} ${"sixthGrid"}"
      `,
      "& .firstGrid": {
        alignItems: "start !important"
      },
      "& .secondGrid": {
        alignItems: "center !important",
        textAlign: "center"
      },
      "& .thirdGrid": {
        alignItems: "end !important",
        textAlign: "end"
      },
      "& .fourthGrid": {
        alignItems: "start !important"
      },
      "& .fifthGrid": {
        alignItems: "center !important",
        textAlign: "center"
      },
      "& .sixthGrid": {
        alignItems: "end !important",
        textAlign: "end"
      },
      padding: 10,
      gap: 10
    }
  },
  TenSplitContainerFirstContainerAFR: {
    display: "grid",
    // gridTemplateColumns: "22% 13% 13% 13% 13% 13% 13%",
    gridTemplateColumns: "29% 14% 14% 14% 15% 14%",
    // paddingLeft:"10px"
    //marginLeft: "20px",
    paddingLeft: 10,

    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr)",
      padding: 5,
      gap: 10,
      gridTemplateAreas: `"firstGrid firstGrid"
      "ninthGrid tenthGrid"
     `,
      "& .firstGrid": {
        alignItems: "flex-start",
        textAlign: "left"
      },
      "& .ninthGrid": {
        alignItems: "flex-start",
        textAlign: "left !important"
      }
    }

  },
  xrayWithRedemptionFirstContainer: {
    display: "grid",
    gridTemplateColumns: "16% 8% 10% 10% 8% 10% 10% 8% 10% 10% !important",
    // paddingLeft:"10px"
    //marginLeft: "20px",

    paddingLeft: 10,
    paddingRight: 10,
    "& .firstGrid": {
      alignItems: "flex-start",
      textAlign: "left"
    },
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr) !important",
      padding: 5,
      gap: 10,
      // marginRight: 9,
      gridTemplateAreas: `"firstGrid firstGrid"
      "ninthGrid tenthGrid"
     `,
      "& .firstGrid": {
        alignItems: "flex-start",
        textAlign: "left"
      },
      "& .secondGrid": {
        display: "none"
      },
      "& .thirdGrid": {
        display: "none"
      },
      "& .fourthGrid": {
        display: "none"
      },
      "& .fifthGrid": {
        display: "none"
      },
      "& .sixthGrid": {
        display: "none"
      },
      "& .seventhGrid": {
        display: "none"
      },
      "& .eighthGrid": {
        display: "none"
      },
      "& .ninthGrid": {
        alignItems: "flex-start",
        textAlign: "left !important"
      }
    }
  },
  xrayWithRedemptionSecondContainer: {
    display: "grid",
    gridTemplateColumns: "16% 8% 10% 10% 8% 10% 10% 8% 10% 10%",
    // gap: 3,
    paddingRight: 10,
    "& .eleventhGrid": {
      textAlign: "left",
      alignItems: "flex-start"
    },
    [theme.breakpoints.down("920")]: {
      gap: 6,
      gridTemplateColumns: "repeat(3,1fr)",
      gridTemplateAreas: `"eleventhGrid eleventhGrid eleventhGrid"
      "twelfthGrid thirteenthGrid fourteenthGrid"
      "fifteenthGrid sixteenthGrid seventeenthGrid"
      "eighteenthGrid ninteenthGrid twentiethGrid"`,
      "& .eleventhGrid": {
        textAlign: "left",
        alignItems: "flex-start"
      },
      "& .thirteenthGrid": {
        textAlign: "center"
      },
      "& .fourteenthGrid": {
        textAlign: "right"
      },
      "& .seventeenthGrid": {
        textAlign: "right"
      },
      "& .sixteenthGrid": {
        textAlign: "center"
      },
      "& .ninteenthGrid": {
        textAlign: "center"
      },
      "& .twelfthGrid": {
        textAlign: "left"
      },
      "& .fifteenthGrid": {
        textAlign: "left"
      },
      "& .eighteenthGrid": {
        textAlign: "left"

      }

    }
  },

  FourSplitContainer: {
    display: "grid",
    gridTemplateColumns: "38% 20% 30% 12%",
    paddingLeft: "10px",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(3,1fr)",
    }
  },
  taxationFourSplitContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",
    paddingLeft: "10px",

    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr)",
      gridTemplateAreas: `"firstGrid firstGrid"
      "secondGrid thirdGrid"
      "fourthGrid ."`,
      gap: 5,
      marginTop: 10,
      padding: 10,
      "& .firstGrid": {
        textAlign: "center !important"
      },
      "& .secondGrid": {
        alignItems: "flex-start",
        textAlignLast: "left"
      },
      "& .thirdGrid": {
        alignItems: "end",
        textAlignLast: "right"
      },
      "& .fourthGrid": {
        alignItems: "flex-start",
        textAlignLast: "left"
      }
    }
  },
  TenSplitContainerSecondContainerAFR: {
    display: "grid",
    //gridTemplateColumns: "22% 13% 13% 13% 13% 13% 13%",
    gridTemplateColumns: "29% 14% 14% 14% 15% 14%",

    // paddingLeft:"10px"
    paddingLeft: 10,
    paddingRight: 10,
    //marginLeft: "20px",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr)",
      padding: 5,
      gap: 10,
      gridTemplateAreas: `"eleventhGrid  twentiethGrid"
      
      "eighteenthGrid ninteenthGrid"`,
      "& .eleventhGrid": {
        textAlign: "left"
      },
      "& .thirteenthGrid": {
        textAlign: "center"
      },
      "& .fourteenthGrid": {
        textAlign: "right"
      },
      "& .seventeenthGrid": {
        textAlign: "right"
      },
      "& .sixteenthGrid": {
        textAlign: "center"
      },
      "& .ninteenthGrid": {
        textAlign: "right"
      },
      "& .twelfthGrid": {
        textAlign: "left"
      },
      "& .fifteenthGrid": {
        textAlign: "left"
      },
      "& .eighteenthGrid": {
        textAlign: "left"

      }

    }
  },
  assetAllocationSixSplitContainer: {

    display: "grid",
    gridTemplateColumns: "repeat(6,1fr)",
    // paddingLeft:"10px"
    marginLeft: "7px",
    "& .firstGrid": {
      textAlign: "left"
    },
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr) !important",
      gridTemplateAreas: `"${"firstGrid"} ${"sixthGrid"}"
      "${"secondGrid"} ${"thirdGrid"}"
      "${"fourthGrid"} ${"fifthGrid"}"`,
      padding: 10,
      gap: 10,
      "& .firstGrid": {
        textAlign: "left"
      },
      "& .secondGrid": {
        textAlign: "left",
        alignItems: "flex-start"
      },
      "& .fourthGrid": {
        textAlign: "left",
        alignItems: "flex-start"
      }
    }

  },
});
// shortTermHolding={} longTermHolding={} totalHolding={}
const portfoliotitle = [{ "name": labels.PortfolioReport.type }, { "name": labels.PortfolioReport.date }, { "name": labels.PortfolioReport.units, "subheader": labels.PortfolioReport.PRICE },
{ "name": labels.PortfolioReport.gross, "subheader": labels.PortfolioReport.grossPurPrice }, { "name": labels.PortfolioReport.currentamt }, { "name": labels.PortfolioReport.profitloss }, { "name": labels.PortfolioReport.divpay, "subheader": labels.PortfolioReport.divrev },
{ "name": labels.PortfolioReport.netG, "subheader": labels.PortfolioReport.loss }, { "name": labels.PortfolioReport.day }, { "name": labels.PortfolioReport.absret, "subheader": labels.PortfolioReport.xirr }]
const siptrackertitle = [{ "name": labels.siptracker.Schemename }, { "name": labels.siptracker.startdate }, { "name": labels.siptracker.sipamount }, { "name": labels.siptracker.Frequency }, { "name": `${labels.siptracker.sip} ${labels.siptracker.received}` }, { "name": `${labels.siptracker.sip} ${labels.siptracker.missed}` }]
const portfolioxraytitle = [{ "name": Label.Tableheader.SchemeName }, { "name": Label.Tableheader.Units, "subheader": Label.Tableheader.Subheadingunits }, { "name": Label.Tableheader.InvestedValue, "subheader": labels.Tableheader.amount }, { "name": Label.Tableheader.CurrentValue, "subheader": Label.Tableheader.Subheadingcurrent }, { "name": Label.Tableheader.unrealised, "subheader": Label.Tableheader.Realised }, { "name": Label.Tableheader.Net, "subheader": Label.Tableheader.SubheadingNet }]
const xraywithredeemptiontitle = [{ "name": "Scheme Name" }, { "name": "units" }, { "name": "invested", 'subheader': "Amount" }, { "name": "current", "subheader": "Value", 'subheader1': "gainloss" },
  , { "name": "units" }, { "name": "invested", 'subheader': "Amount" }, { "name": "Redemption", "subheader": "Value", 'subheader1': "gainloss" },
  , { "name": "units" }, { "name": "invested", 'subheader': "Amount" }, { "name": "Value", 'subheader1': "gainloss" }]
const shorttermtitle = [
  {
    mainTitle: [{ name: labels.AFRreport.short }, { name: labels.AFRreport.long },
      //  { name: labels.AFRreport.total }
    ],
    list: [{ "name": labels.AFRreport.schemeName }, { "name": labels.AFRreport.InvestedAmount + "Amount ", "subheader": labels.AFRreport.Units }, { "name": `${labels.AFRreport.current}`, "subheader": "", 'subheader1': labels.AFRreport.gl },
    { "name": labels.AFRreport.InvestedAmount + " Amount", "subheader": labels.AFRreport.Units }, { "name": `${labels.AFRreport.current}`, 'subheader1': labels.AFRreport.gl },
    { "name": "Grand", subheader: "Fathering", "subheader1": "Indexation" },
      // { "name": labels.AFRreport.InvestedAmount + " Amount ", "subheader": labels.AFRreport.Units },

      // { "name": `${labels.AFRreport.current}`, "subheader": "", 'subheader1': labels.AFRreport.gl },

    ]
  }
]
const elssMaturityTitle = [
  {
    mainTitle: [{ name: "Locked-In Holding" }, { name: "Free Long Term Holding" },
      //  { name: labels.AFRreport.total }
    ],
    list: [{ "name": labels.AFRreport.schemeName }, { "name": labels.AFRreport.InvestedAmount + "Amount ", "subheader": labels.AFRreport.Units }, { "name": `${labels.AFRreport.current}`, "subheader": "", 'subheader1': labels.AFRreport.gl },
    { "name": labels.AFRreport.InvestedAmount + " Amount", "subheader": labels.AFRreport.Units }, { "name": `${labels.AFRreport.current}`, 'subheader1': labels.AFRreport.gl },
    { "name": "Grand", subheader: "Fathering", "subheader1": "Indexation" },
      // { "name": labels.AFRreport.InvestedAmount + " Amount ", "subheader": labels.AFRreport.Units },

      // { "name": `${labels.AFRreport.current}`, "subheader": "", 'subheader1': labels.AFRreport.gl },

    ]
  }
]
const elsstitle = [{ "name": labels.AFRreport.investmentDate }, { "name": labels.AFRreport.maturitydate }, { "name": labels.AFRreport.investmentAmount }, { "name": labels.AFRreport.presentvalue }]
const accountstatementtitle = [{ "name": labels.Accountstatement.Date, "align": "left" }, { "name": labels.Accountstatement.Txntype, "align": "left" }, { "name": labels.Accountstatement.Amount, "align": "right" }, { "name": labels.Accountstatement.units, "align": "right" }, { "name": labels.Accountstatement.Nav, "align": "right" }, { "name": labels.Accountstatement.Balunits, "align": "right" }]
class MutualFundTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      xray: [],
      selectedSwitch: "Scheme",
      List: [],
      key: "Category",
      YearCode: "All",
      xrayLoader: false
    }
  }

  componentDidMount() {
    document.title = window.globalConfig.companyDetails.companyName + " - Mutual Funds"
    const { page, xray } = this.props;
    if (page.state != undefined) {
      // if (prev.page.state.tabValue != page.state.tabValue) {
      this.setState({ value: page.state.tabValue })
      this.props.filterChange([])
      // }
    }
    // this.setState({ List: this.props.xray })
    this.categorySwitch("Category")
  }

  componentDidUpdate(prev) {
    const { page, xray, isLoadinggroupXray } = this.props;
    if (prev.page != page) {
      if (prev.page.state != undefined) {
        if (prev.page.state.tabValue != page.state.tabValue) {
          this.setState({ value: page.state.tabValue })
          this.props.filterChange([])
        }
      }
    }
    if (prev.xray != xray) {
      this.setState({ List: xray })
    }
    if (prev.isLoadinggroupXray != isLoadinggroupXray) {
      this.categorySwitch(this.state.selectedSwitch)
    }
  }


  handleChange = (event, newValue) => {
    // 

    this.setState({ value: newValue })
  };
  categorySwitch = (value) => {
    // console.log(value)
    this.setState({ selectedSwitch: value })
    if (value == "Category" || value == "Scheme") {
      this.setState({ List: this.props.xray, key: "Category", xrayLoader: this.props.isLoadinggroupXray })
    } else if (value == "Folio") {
      // console.log(this.props.folioXrayData)
      this.setState({
        List: this.props.folioXrayData.length != 0 && this.props.folioXrayData != "NA" ? this.props.folioXrayData?.map(({ AbsoluteGainORLossPercentage, CategoryValuetAtCost
          , CategoryMarketValue,
          CategoryAbsoluteGainOrLoss,
          CategoryNetPl,
          CategoryXIRR, AvgDaysInvested, ...item }) => {
          return { ...item, CategoryFolioNo: item.FolioNo, CategoryUnits: "", TotalUnits: "", AnnualReturn: 0 }
        }) : [], key: "CategoryFolioNo", xrayLoader: this.props.isLoadinggroupXray
      })
    } else if (value == "Market Cap wise") {
      this.setState({ xrayLoader: true })
      getMargetCapWiseList(0, this.props.logindetails.ClientID, moment(this.props.date, 'DD MMM YYYY').format("DD MMM YYYY"), 0, 0, 0, 0, this.props.logindetails.SessionID, 100, 0, 1, 0, this.props.L5ID, 0,).then((res) => {
        if (res.status == "S") {
          this.setState({ List: replaceNull(res.data, "NA"), xrayLoader: false, key: "Category" })
        } else {
          this.setState({ xrayLoader: false, List: [] })
        }
      })
    }
  }
  onChange = (item) => {
    this.setState({ YearCode: item[0]?.label })
    this.props.getProfitLossDetails(0, this.props.logindetails.ClientID, this.props.date, this.props.date,
      this.props.date, 0, 0, this.props.logindetails.SessionID, 0, 1, 0, this.props.L5ID, 0, "", item[0]?.YearCode)
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            classes={{ root: classes.tabRoot }}

            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{ style: { background: window.globalConfig.color.BROWN } }}
          >
            <Tab label="Summary" {...a11yProps(0)} />
            <Tab label="Portfolio X-Ray" {...a11yProps(1)} />
            <Tab label="Transactions" {...a11yProps(2)} />
            <Tab label="Booked G/L" {...a11yProps(3)} />
            <Tab label="Taxation Report" {...a11yProps(4)} />
            <Tab label="SIP Tracker" {...a11yProps(5)} />
            <Tab label="Account Statement" {...a11yProps(6)} />
            <Tab label="Portfolio Details" {...a11yProps(7)} />
            <Tab label="AFR Report" {...a11yProps(8)} />
            <Tab label="X-Ray with Redemption" {...a11yProps(9)} />
            <Tab label="Asset Allocation" {...a11yProps(10)} />
            <Tab label="Market Cap Allocation" {...a11yProps(11)} />
            <Tab label="Running SIP Summary" {...a11yProps(12)} />
          </Tabs>
        </AppBar>
        <TabPanel value={this.state.value} index={0}>
          <Summary CategoryAllocation={labels.summaryPage.CategoryAllocation}
            splitByInstrument={labels.summaryPage.splitByInstrument}
            EquityFundSector={labels.summaryPage.EquityFundSector}
            flag='mutualfunds'
            showChart={true}
            EquityLoading={this.props.isLoadingCategoryEquity}
            DebtLoading={this.props.isLoadingCategoryDebt}
            barChartData={this.props.barChartData}
            chart={this.props.chart}
            Deptdata={this.props.Deptdata}
            categorySummary={this.props.categorySummary}
            isLoadingcategorySummary={this.props.isLoadingcategorySummary}
            isLoadingportfolioSummary={this.props.isLoadingportfolioSummary}
            isLoadingportfolioChart={this.props.isLoadingportfolioChart}
            tableHeader={[Label.summaryPage.Category, Label.summaryPage.Amount, Label.summaryPage.categoryPercentage, Label.summaryPage.holdingPercentage]
            }
            DonutData={this.props.DonutData}
            grandtotal={[labels.messages.grandtotal, labels.currency.rupeesymbol + this.props.grandtotalsummary, "", this.props.categorySummary.length == 0 ? "0%" : "100.0%"]}
          />
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
          <Portifolio flag='mutualfunds'
            externalClasses={classes}
            Total={labels.ProfitLoss.Total}
            dropdowndetails={this.props.dropdowndetails}
            hideGrandTotalUnitsColumn={true}
            catagorize={this.state.key}

            // grid={classes.portfolioXraySevenSplitContainer}CategoryFolioNo
            List={this.state.List}
            filterSubmit={this.props.filterChange}
            MarketValue={labels.Tableheader.MarketValue}
            CurrentGain={labels.Tableheader.CurrentGain}
            amountLabel={labels.Tableheader.Nav}
            units={labels.Tableheader.Units}
            Subheadingunits={labels.Tableheader.Subheadingunits}
            InvestedValue={labels.Tableheader.InvestedValue}
            AsOn={labels.Tableheader.AsOn}
            totaltext={labels.Tableheader.totaltext}
            Sip={labels.Tableheader.Sip}
            header={"Scheme"}
            switchList={["Category", "Scheme", "Folio"]}
            selected={this.state.selectedSwitch}
            categorySwitch={this.categorySwitch}
            title={portfolioxraytitle}
            xray={this.state.List}
            isLoadinggroupXray={this.state.xrayLoader}
            logindetails={this.props.logindetails}
            enableToggleList={true}
            schemeList={this.state.List} />
        </TabPanel>
        <TabPanel value={this.state.value} index={2}>
          <Transaction
            ReportList={this.props.financialYearsList.length == 0 ? [] : sortArray([{ label: "All", value: 0, }, ...this.props.financialYearsList.map((item, index) => {
              return { ...item, label: item.Year, value: index + 1 }
            }),], "ID", "DESC")}
            isReachedBottom={this.props.isReachedBottom}
            title={
              [{ 'name': labels.transactionSummary.date }, { 'name': labels.transactionSummary.schemeName }, { 'name': labels.transactionSummary.assetClass }, { 'name': labels.transactionSummary.transactionType }, { 'name': labels.transactionSummary.folioNumber }, { 'name': labels.transactionSummary.units }, { 'name': labels.transactionSummary.nav }, { 'name': labels.transactionSummary.amount }]
            }

            flag='mutualfunds' schemeName={labels.transactionSummary.schemeName}
            isLoadingtransactionList={this.props.isLoadingtransactionList} L5ID={this.props.L5ID}
            maxDate={this.props.maxDate} minDate={this.props.minDate}
            TransactionxcelLoad={this.props.TransactionxcelLoad}
            TransactionLoad={this.props.TransactionLoad}
            logindetails={this.props.logindetails}
            catchmessage={this.props.catchmessage}
            date={this.props.date}
            grid={classes.transactionContainer}
            userID={this.props.userID}
            clientID={this.props.clientID}
            sessionID={this.props.sessionID}
            transactionList={this.props.transactionList != "NA" && this.props.transactionList.length != 0 ? this.props.transactionList : []}
            filterSubmit={this.props.transactionfilter}
            dropdowntransaction={this.props.dropdowntransaction}
            List={this.props.dropdowntransaction}
            header={"Transaction"}
            filterHeader={"Scheme"}
          />
        </TabPanel>
        <TabPanel value={this.state.value} index={3}>
          {/* PROFITANDLOSS */}
          <Table header={"Scheme"} GainValue={labels.ProfitLoss.GainValue}
            ReportList={this.props.financialYearsList.length == 0 ? [] : sortArray([{ label: "All", value: 0, }, ...this.props.financialYearsList.map((item, index) => {
              return { ...item, label: item.Year, value: index + 1 }
            }),], "ID", "DESC")}
            YearCode={this.state.YearCode}
            onChange={this.onChange}
            Units={labels.ProfitLoss.Units} SellValueLabel={labels.ProfitLoss.SellValue}
            PurchaseValueLabel={labels.ProfitLoss.PurchaseValue} DividendValueLabel={labels.ProfitLoss.DividendValue} SchemeName={labels.ProfitLoss.SchemeName}
            Total={labels.ProfitLoss.Total}
            flag='mutualfunds' ProfitLoss={this.props.ProfitLoss}
            profitLossfilter={this.props.profitLossfilter}
            List={this.props.profitLossfilter}
            filterSubmit={this.props.profitandlossfilter}
            grid={classes.BookedglContainer}
            isLoadingProfitLoss={this.props.isLoadingProfitLoss} PurchaseValue={this.props.PurchaseValue}
            DividendValue={this.props.DividendValue} SellValue={this.props.SellValue}
            glAmount={this.props.glAmount} GainPercentage={this.props.GainPercentage}
            isProfitLossLoading={this.props.isProfitLossLoading} />
        </TabPanel>
        <TabPanel value={this.state.value} index={4}>
          <CapitalGain flag="mutualfunds" header={"Scheme"} externalClasses={classes} title={[{ "name": Label.capitalGain.FinancialYear }, { "name": Label.capitalGain.valuationreport }, { "name": Label.capitalGain.capitalgains }, { "name": Label.capitalGain.DividendReport }]}
            isCapitalgainLoading={this.props.isCapitalgainLoading} capitalGain={this.props.capitalGain} />
        </TabPanel>
        <TabPanel value={this.state.value} index={5}>
          <SIPTracker header={"Scheme"} flag="mutualfunds"
            titleStyle={classes.sipTitleContainer}
            sipCategoryContainer={classes.sipCategoryContainer}

            grid={classes.sipTrackerSevenSplitContainer}
            siptracker={this.props.siptracker}
            List={this.props.backupSipTracker}
            groupsiptracker={this.props.groupsiptracker}
            totalsiptracker={this.props.totalsiptracker}
            isSipTrackerLoading={this.props.isSipTrackerLoading}
            title={siptrackertitle} />
        </TabPanel>
        <TabPanel value={this.state.value} index={6}>
          <AccountStatement header={"Scheme"} flag='mutualfunds'
            foliono={this.props.foliono}
            title={accountstatementtitle}
            grid={classes.accountStatementContainer}
            accountstatement={this.props.accountstatement}
            showwarning={this.props.showwarning}
            totalaccountstatement={this.props.totalaccountstatement}
            withredeemed={this.props.withredeemed}
            withoutredeemed={this.props.withoutredeemed} />
        </TabPanel>
        <TabPanel value={this.state.value} index={7}>
          <PortfolioReport header={"Transaction"} externalClasses={classes} flag='mutualfunds' portfolioreport={this.props.portfolioreport}
            purprice={labels.PortfolioReport.pureNav} units={labels.PortfolioReport.units}
            totalreport={this.props.totalreport}
            portfoliocount={this.props.portfoliocount}
            isPortfolioReportLoading={this.props.isPortfolioReportLoading}
            title={portfoliotitle} />
        </TabPanel>
        <TabPanel value={this.state.value} index={8}>
          <AFRReport header={"Scheme"} flag="mutualfunds"
            title={shorttermtitle}
            grid={classes.TenSplitContainerAFR}
            externalClasses={classes}
            elssMaturityTitle={elssMaturityTitle}
            elsstitle={elsstitle} grandtotal={labels.AFRreport.grandtotal}
            elssMaturity={this.props.elssMaturity}
            totalElssMaturity={this.props.totalElssMaturity}
            isElssMaturityLoading={this.props.isElssMaturityLoading}
            exitload={this.props.exitload}
            totalshortterm={this.props.totalshortterm}
            totalexitload={this.props.totalexitload} totalelssdata={this.props.totalelssdata}
            shortterm={this.props.shortterm} Elssdata={this.props.Elssdata}
            elssgroupdata={this.props.elssgroupdata}
            isShorttermLoading={this.props.isShorttermLoading} isExitloadLoading={this.props.isExitloadLoading}
            isElssLoading={this.props.isElssLoading} topheaderexitload={labels.AFRreport.topheaderexitload} />
        </TabPanel>
        <TabPanel value={this.state.value} index={9}>
          <XrayWithRedemption header={"Scheme"} externalClasses={classes} title={xraywithredeemptiontitle} flag="mutualfunds"
            schemeNameLabel={labels.xraywithredemption.SchemeName} invested={labels.xraywithredemption.Invested} amountLabel={labels.xraywithredemption.amount} current={labels.xraywithredemption.current} value={labels.xraywithredemption.value} Gainloss={labels.xraywithredemption.Gainloss}
            total={labels.xraywithredemption.total} units={labels.xraywithredemption.units} InvestedValue={labels.xraywithredemption.InvestedValue} currentvalue={labels.xraywithredemption.currentvalue}
            redemption={labels.xraywithredemption.redemption}
            GainLoss={labels.xraywithredemption.GainLoss} XrayTotal={labels.xraywithredemption.XrayTotal} Balancedunits={labels.xraywithredemption.Balancedunits}
            Redeemedunits={labels.xraywithredemption.Redeemedunits} grandtotal={labels.xraywithredemption.grandtotal}
            xraywithredemption={this.props.xraywithredemption} xraywithredemptiongrouped={this.props.xraywithredemptiongrouped}
            totalxraywithredemption={this.props.totalxraywithredemption} isXrayWithRedemptionLoading={this.props.isXrayWithRedemptionLoading} />
        </TabPanel>
        <TabPanel value={this.state.value} index={10}>
          <AssetAllocation header={"Scheme"}
            AsOn={labels.Tableheader.AsOn}
            externalClasses={classes}
            flag="mutualfunds" title={[{ "name": labels.assetAllocation.equity }, { 'name': labels.assetAllocation.debt }, { 'name': labels.assetAllocation.gold }, { 'name': labels.assetAllocation.others }]}
            titleAmountAge={[{ 'name': labels.Tableheader.SchemeName, 'age': '' }, { 'name': labels.assetAllocation.amountAge, 'age': labels.assetAllocation.Age },
            { 'name': labels.assetAllocation.amountAge, 'age': labels.assetAllocation.Age }, { 'name': labels.assetAllocation.amountAge, 'age': labels.assetAllocation.Age },
            { 'name': labels.assetAllocation.amountAge, 'age': labels.assetAllocation.Age }, { 'name': labels.assetAllocation.totalamount, 'age': '' }]}
            isAssetAllocationLoading={this.props.isAssetAllocationLoading} sessionID={this.props.sessionID}
            clientID={this.props.clientID} userID={this.props.userID} date={this.props.date} logindetails={this.props.logindetails}
            assetallocationgrouped={this.props.assetallocationgrouped} totalassetallocation={this.props.totalassetallocation}
            assetallocation={this.props.assetallocation} isNetworkLow={this.props.isNetworkLow} L5ID={this.props.L5ID} />

        </TabPanel>
        <TabPanel value={this.state.value} index={11}>
          <MarketCapAllocation grid={classes.marketcapContainer} AsOn={labels.Tableheader.AsOn} externalClasses={classes}
            header={"Scheme"} flag="mutualfunds" title={[{ 'name': labels.marketCapAllocation.smallCap }, { 'name': labels.marketCapAllocation.midCap }, { 'name': labels.marketCapAllocation.largeCap }, { "name": labels.marketCapAllocation.others }]}
            titleAmountAge={[{ 'name': labels.Tableheader.SchemeName, 'age': '' }, { 'name': labels.assetAllocation.amountAge, "age": labels.assetAllocation.Age },
            { 'name': labels.assetAllocation.amountAge, "age": labels.assetAllocation.Age }, { 'name': labels.assetAllocation.amountAge, "age": labels.assetAllocation.Age },
            { 'name': labels.assetAllocation.amountAge, "age": labels.assetAllocation.Age }, { "name": labels.assetAllocation.totalamount, 'age': "" }]}
            isMarketCapLoading={this.props.isMarketCapLoading} sessionID={this.props.sessionID} clientID={this.props.clientID}
            userID={this.props.userID} date={this.props.date} logindetails={this.props.logindetails}
            marketcapallocation={this.props.marketcapallocation} totalmarketcapallocation={this.props.totalmarketcapallocation} L5ID={this.props.L5ID} />
        </TabPanel>
        <TabPanel value={this.state.value} index={12}>
          <RunningSIP header={"Scheme"} flag="mutualfunds"
            title={[{ "name": Label.runningSip.scheme },
            { "name": Label.runningSip.simamt, "subheader": Label.runningSip.sipFrequency },
            { "name": Label.runningSip.startdate, "subheader": Label.runningSip.endDate },
            { "name": Label.runningSip.totalUnits },
            { "name": Label.runningSip.puramount, "subheader": Label.runningSip.purprice },
            { "name": Label.runningSip.amount },
            { "name": Label.runningSip.curAmount, "subheader": Label.runningSip.nav },
            { "name": Label.runningSip.profit, "subheader": Label.runningSip.xirr },
            ]}
            sessionID={this.props.sessionID}
            clientID={this.props.clientID}
            userID={this.props.userID}
            logindetails={this.props.logindetails}
            L5ID={this.props.L5ID}
            date={this.props.date}
            runningSIP={this.props.runningSIP}
            totalrunningSIP={this.props.totalrunningSIP}
            isRunningSipLoading={this.props.isRunningSipLoading}
            grid={classes.runningSipContainer}

          />
        </TabPanel>
      </div>
    );
  }
}
const mapStateToProps = state => {
  // //
  // console.log(state.Dropdown)
  return {
    //COMMON PARAM
    sessionID: state.Login.sessionID,
    clientID: state.Login.logindetails.ClientID,
    date: state.Login.date,
    userID: state.Login.userID,
    logindetails: state.Login.logindetails,
    L5ID: state.Login.L5ID,

    //SIPTRACKER
    siptracker: replaceNull(state.SipTracker.siptracker, "NA") != "NA" ? state.SipTracker.siptracker.map((item) => {
      return { ...item, Scheme: item.SchemeName }
    }) : [],
    groupsiptracker: state.SipTracker.groupsiptracker,
    backupSipTracker: state.SipTracker.backupSiptracker,
    totalsiptracker: state.SipTracker.totalsiptracker,
    isSipTrackerLoading: state.SipTracker.isSipTrackerLoading,

    //ACCOUNT STATEMENT
    foliono: replaceNull(state.Dropdown.dropdowndetails.folioNo, "NA") == "NA" ? [] : state.Dropdown.dropdowndetails.folioNo,
    // accountstatement: state.Account.accountstatement != null ? state.Account.accountstatement : [],
    // showwarning: state.Account.showwarning,
    // totalaccountstatement: state.Account.totalaccountstatement,
    withredeemed: replaceNull(state.Dropdown.dropdowndetails.folioNo, "NA") == "NA" ? [] : [{ "label": "All Folio", "value": "all folio" }, ...state.Dropdown.dropdowndetails.folioNo.map((item) => {
      return { "label": item.Description, "value": item.FolioNo }
    })],
    withoutredeemed: replaceNull(state.Dropdown.dropdowndetails.folioNo, "NA") == "NA" ? [] : [{ "label": "All Folio", "value": "all folio" }, ...state.Dropdown.dropdowndetails.folioNo.filter((value) => value.IsRedeemed === 0).map((item) => {
      return { "label": item.Description, "value": item.FolioNo }
    })],

    // RunningSIP
    date: state.Login.date,
    runningSIP: state.RunningSIP.runningSIP,
    totalrunningSIP: state.RunningSIP.totalrunningSIP,
    isRunningSipLoading: state.RunningSIP.isRunningSipLoading,

    //MARKET CAP ALLOCATION
    marketcapallocation: state.MarketCap.marketcapallocation == null ? [] : state.MarketCap.marketcapallocation,
    totalmarketcapallocation: state.MarketCap.totalmarketcapallocation,
    isMarketCapLoading: state.MarketCap.isMarketCapLoading,

    //TAXATION REPORT
    capitalGain: state.capitalgain.capitalgain,
    isCapitalgainLoading: state.capitalgain.isCapitalgainLoading,

    //TRANSACTION
    transactionList: state.transactionList.transactionList,
    dropdowntransaction: state.transactionList.dropdowntransaction,
    isLoadingtransactionList: state.transactionList.isLoadingtransactionList,
    catchmessage: state.transactionList.catchmessage,
    TransactionLoad: state.transactionList.isLoadingPdf,
    TransactionxcelLoad: state.transactionList.isLoadingExcel,
    minDate: state.transactionList.minDate,
    maxDate: state.transactionList.maxDate,

    //ASSET ALLOCATION
    assetallocationgrouped: state.Asset.assetallocationgrouped,
    totalassetallocation: state.Asset.totalassetallocation,
    assetallocation: state.Asset.assetallocation,
    isAssetAllocationLoading: state.Asset.isAssetAllocationLoading,
    isNetworkLow: state.Asset.isNetworkLow,
    //PORTFOLIO REPORT
    portfolioreport: state.Portfolioreport.portfolioreport != undefined && state.Portfolioreport.portfolioreport != null ? state.Portfolioreport.portfolioreport : [],
    totalreport: state.Portfolioreport.totalreport,
    portfoliocount: state.Portfolioreport.portfoliocount,
    isPortfolioReportLoading: state.Portfolioreport.isPortfolioReportLoading,
    //SUMMARY
    barChartData: state.Summary.summary,
    chart: state.Summary.chart,
    categorySummary: state.Summary.categorySummary,
    isLoadingcategorySummary: state.Summary.isLoadingcategorySummary,
    isLoadingportfolioChart: state.Sample.isLoadingportfolioChart,
    isLoadingportfolioSummary: state.Sample.isLoadingportfolioSummary,
    DonutData: state.Summary.Donut,
    grandtotalsummary: state.Summary.grandtotalIV,
    Deptdata: state.Summary.getdeptdata,
    isLoadingCategoryEquity: state.Summary.isLoadingCategoryEquity,
    isLoadingCategoryDebt: state.Summary.isLoadingCategoryDebt,
    // PROFITANDLOSS
    ProfitLoss: state.ProfitLoss.profitLoss,
    profitLossfilter: state.ProfitLoss.profitLossfilter,
    isLoadingProfitLoss: state.ProfitLoss.isLoadingProfitLoss,
    PurchaseValue: state.ProfitLoss.grandpurchasetotal,
    DividendValue: state.ProfitLoss.granddivdend,
    SellValue: state.ProfitLoss.grandselltotal,
    glAmount: state.ProfitLoss.grandglamount,
    GainPercentage: state.ProfitLoss.GainPercentage,
    isProfitLossLoading: state.ProfitLoss.isProfitLossLoading,

    //AFR REPORT
    shortgroupeddata: state.AFRdata.shortgroupeddata !== undefined ? state.AFRdata.shortgroupeddata : [],
    exitload: state.AFRdata.exitload !== undefined ? state.AFRdata.exitload : [],
    elssgroupdata: state.AFRdata.elssgroupdata !== undefined ? state.AFRdata.elssgroupdata : [],
    totalshortterm: state.AFRdata.totalshortterm !== undefined ? state.AFRdata.totalshortterm : [],
    totalexitload: state.AFRdata.totalexitload !== undefined ? state.AFRdata.totalexitload : [],
    totalelssdata: state.AFRdata.totalelssdata !== undefined ? state.AFRdata.totalelssdata : [],
    shortterm: state.AFRdata.shortterm !== undefined ? state.AFRdata.shortterm : [],
    Elssdata: state.AFRdata.Elssdata !== undefined ? state.AFRdata.Elssdata : [],
    isShorttermLoading: state.AFRdata.isShorttermLoading,
    isExitloadLoading: state.AFRdata.isExitloadLoading,
    isElssLoading: state.AFRdata.isElssLoading,
    elssMaturity: state.AFRdata.elssMaturity,
    totalElssMaturity: state.AFRdata.totalElssMaturity,
    isElssMaturityLoading: state.AFRdata.isElssMaturityLoading,

    //X-RAY WITH REDEEM
    xraywithredemption: state.Redemption.xraywithredemption,
    xraywithredemptiongrouped: state.Redemption.xraywithredemptiongrouped,
    totalxraywithredemption: state.Redemption.totalxraywithredemption,
    isXrayWithRedemptionLoading: state.Redemption.isXrayWithRedemptionLoading,

    //PORTFOLIO_X_RAY
    xray: state.PortfolioXray.xray,
    folioXrayData: state.PortfolioXray.folioXrayData,

    List: state.PortfolioXray.XrayData.length == 0 ? [] : state.PortfolioXray.XrayData.filter(
      (ele, ind) =>
        ind ===
        state.PortfolioXray.XrayData.findIndex((elem) => elem.Scheme === ele.Scheme)
    ),

    isLoadinggroupXray: state.PortfolioXray.isXrayLoading,

    unrealised: state.Card.mfHeader2.filter((val) => val.firstName === "Unrealised G / L (%)").map((val) => {
      return val.lastName
    }),
    margetvalue: state.Card.mfHeader2.filter((val) => val.firstName === "MF Market Value").map((val) => {
      return val.lastName
    }),
    investedvalue: state.Card.mfHeader2.filter((val) => val.firstName === "Investment Value").map((val) => {
      return val.lastName
    }),
    unrealisedpercentage: state.Card.mfHeader2.filter((val) => val.firstName === "Unrealised G / L (%)").map((item) => {
      return item.val
    }),
    dropdowndetails: state.Dropdown.dropdowndetails.scheme,
    schemeList: state.PortfolioXray.XrayData.sort(function (a, b) {
      var textA = a.Scheme?.toUpperCase();
      var textB = b.Scheme?.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }),
    financialYearsList: replaceNull(state.Dropdown.dropdowndetails.financialYearsList, "").length != 0 ? state.Dropdown.dropdowndetails.financialYearsList : [],

  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {

      filterChange,
      transactionfilter,
      profitandlossfilter,
      getProfitLossDetails
    },
    dispatch
  );
};
const mergestyles = combineStyle(Styles, commonstyletable);
export default withStyles(mergestyles)(connect(mapStateToProps, mapDispatchToProps)(MutualFundTab))