import React, { useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Summary from '../../containers/mandatereport/mandatereport';
import UpComingSIP from '../../containers/trading/upcomingsip';
import TransactionOrder from '../../components/transactionorder/transactionorder';
import PropTypes from 'prop-types';
import DeletePopup from '../../components/popup/deletepopup';
import CommonSnackbar from "../../components/snackbar/snackbar";
import { getUpcommingSIPReport } from "../../redux/actions/upcomming/upcommingsipaction";
import { getMandateDetails } from "../../redux/actions/mandate/mandateaction";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import '../../utils/stylesheets/tabs/tabs.css'
import colors from '../../utils/colors/colors';
import secureStorage from '../../utils/securestorage';
import getCancelSip from '../../api/cancel_sip/cancelsip';
import { bindActionCreators } from "redux";
import { connect } from 'react-redux';
import labels from '../../utils/constants/labels';
import RunningSIP from '../runningsip/runningsip'
import Siptracker from '../siptracker/siptracker';
import { NegativeFinding, replaceNull } from '../../utils/commonfunction';
import { getTransactionOrderReport } from '../../redux/actions/transactionorderreport/transactionorderreport';
import moment from 'moment';
import EPFReport from '../epf/epfreport';
import getOnlineSTP from '../../api/tradereport/onlinestp';
import getSTPApidetails from '../../api/stp/stp';
import constants from '../../utils/constants/apiconstant';
import getCancelOnlineSTP from '../../api/tradereport/cancelstp';
import getTransactionOrderReportDetails from '../../api/transactionorderapi/transactionorderapi';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Box p={3}>
        //   <Typography>{children}</Typography>
        // </Box>
        <Box>
          <div
            style={{
              fontSize: '1rem',
              fontWeight: 400,
              lineHeight: 1.5,
              letterSpacing: '0.00938rem',
              //  marginBlockStart: '1rem',
              //  marginBlockEnd: '1rem',
              //margin: '1.5rem',
            }}
          >{children}</div>
        </Box>
      )
      }
    </div >
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const Styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
  },
  tabRoot: {
    "& .MuiTab-root:focus": {
      color: window.globalConfig.color.BROWN
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: window.globalConfig.color.BROWN
    }
  },
  sipTitleContainer: {
    display: "grid",
    gridTemplateColumns: "43% 15% 18% 17% 7%",
    marginLeft: "20px",
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "100%",

      padding: 10,
      gap: 10
    }
  },
  displayTotalContainer: {
    fontSize: "16px",
    color: window.globalConfig.color.WHITE,
    fontWeight: 500,
    borderRadius: '8px',
    alignContent: "center",
    backgroundColor: window.globalConfig.color.BLACK_PRIMARY,
    minHeight: "85px",
    marginTop: "10px",
    textAlign: "right", alignItems: "center",
    marginBottom: "30px",
    lineHeight: 1.5,
    cursor: "auto",
    // marginRight: "1.5%",

  },
  sipCategoryContainer: {
    display: "grid",
    gridTemplateColumns: "32% 11% 16% 13% 15% 11%",
    marginLeft: "20px",
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(3,1fr)",
      padding: 10,
      gap: 10
    }
  },
  onlineSTPRoot: {
    marginBottom: "0% !important"
  },
  sipTrackerSevenSplitContainer: {
    display: "grid",
    gridTemplateColumns: "43% 15% 18% 17% 7%",
    marginLeft: "20px",
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(3,1fr)",
      gridTemplateAreas: `"${"firstColumn"} ${"firstColumn"} ${"seventhColumn"}"
      "${"secondColumn"} ${"thirdColumn"} ${"fourthColumn"}"    
    `,
      padding: 10,
      gap: 10
    }
  },
  onlineSTPContainer: {
    display: "grid",
    gridTemplateColumns: "27% 27% 11% 9% 15% 9% 2%",
    marginLeft: "20px",
    alignContent: "center",
    "& .dataFive": {
      textAlign: "right !important"
    },
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(3,1fr)",
      padding: 10,
      gap: 10
    }
  },
});
const siptrackertitle = [{ "name": labels.siptracker.Schemename }, { "name": labels.siptracker.startdate }, { "name": labels.siptracker.sipamount }, { "name": labels.siptracker.Frequency },]
class TradingTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      data: [],
      totalSiptrackerAmount: [],
      loader: false,
      onlineStpLoader: false,
      onlineSTP: [],
      List: [],
      cancelLoader: false,
      openCancelPopup: false,
      open: false,
      message: "",
      severity: "warning",
      schemecodeerror: "",
      transactionOrder: [],
      orderStatusLoader: false,
      isJointHolderAccount: false, totalOnlineSTP: []
    }
  }

  componentDidMount() {
    document.title = window.globalConfig.companyDetails.companyName + " - MFTrading"
    const { page, xray, uccList, ucc } = this.props;
    if (page.state != undefined) {
      this.setState({ value: page.state.tabValue })
    }
    if (uccList.length != 0) {
      this.setState({ isJointHolderAccount: Boolean(uccList.filter((item) => item.ClientCode == this.props.ucc)[0]?.SecondApplicantName) })
    } else {
      this.setState({ isJointHolderAccount: false })
    }
    this.getReportData(this.props.ucc)
    this.onlineSTPReportAPI(this.props.ucc)
    this.tradeDetails()
  }
  tradeDetails = () => {
    this.setState({ orderStatusLoader: true })
    getTransactionOrderReportDetails(this.props.logindetails.SessionID, this.props.logindetails.ClientID, moment().format("YYYY-MM-DD")).then((res) => {
      if (res.status == "S") {
        this.setState({ transactionOrder: res.data == null || res.data.length == 0 ? [] : replaceNull(res.data, ""), orderStatusLoader: false })
      } else {
        this.setState({ transactionOrder: [], orderStatusLoader: false })
      }
    })
  }
  getReportData = (ucc) => {
    let param = {}
    param.clientID = this.props.clientID
    param.asOnDate = this.props.date
    param.sessionID = 0
    param.clientCode = ucc
    //param.clientCode = this.props.L5ID == 0 ? this.props.l5Report != null && this.props.l5Report.length != 0 ? this.props.l5Report[0].MutualFundUCC : 0 : this.props.l5Report != null && this.props.l5Report.length != 0 ? this.props.l5Report.filter((item) => item.L5ID == this.props.L5ID)[0].MutualFundUCC : 0,
    param.status = "ACTIVE"
    this.setState({ loader: true })
    getCancelSip(param).then((response) => {
      this.setState({ loader: false })
      if (response.status == "S") {
        if (response.table1 != null && response.table1?.length != 0) {
          this.setState({
            data: replaceNull(response.table1.map((item) => {
              return { ...item, SIPRegNo: item.XSIPRegnNo, SIPAmount: item.InstallmentAmount, SchemeName: item.SchemeName, StartDate: item.StartDate }
            }), "NA")
          })
        } else {
          this.setState({ data: [] })
        }
        if (response.table2 != null && response.table2?.length != 0 && response.table2 != undefined) {
          this.setState({
            totalSiptrackerAmount: replaceNull(response.table2.map((item) => {
              return { ...item }
            }), "NA")
          })
        }
        else {
          this.setState({ totalSiptrackerAmount: [] })
        }

      } else {
        this.setState({ data: [] })

      }
    }).catch((err) => {
      this.setState({ data: [], loader: false })
    })
  }
  onlineSTPReportAPI = (ucc) => {
    this.setState({ onlineStpLoader: true })
    let param = {}
    param.clientID = this.props.clientID
    param.asOnDate = this.props.date
    param.sessionID = 0
    param.clientCode = ucc
    //param.clientCode = this.props.L5ID == 0 ? this.props.l5Report != null && this.props.l5Report.length != 0 ? this.props.l5Report[0].MutualFundUCC : 0 : this.props.l5Report != null && this.props.l5Report.length != 0 ? this.props.l5Report.filter((item) => item.L5ID == this.props.L5ID)[0].MutualFundUCC : 0,
    param.status = "ACTIVE"
    getOnlineSTP(param).then((res) => {
      this.setState({ onlineStpLoader: false })
      this.setState({ onlineSTP: [] })
      if (res.status == "S") {
        if (res.table1 != null && res.table1.length != 0) {
          this.setState({
            onlineSTP: replaceNull(res.table1.map((item) => {
              return { ...item, isShowVert: true, dataOne: item.FromSchemeName, subDataOne: "Folio No : " + item.FolioNo, dataTwo: item.ToSchemeName, dataThree: item.STPStartDate, subDataThree: item.STPEndDate, dataFour: item.Frequency, dataFive: NegativeFinding(item.Amount, "", "", "", "rupee"), dataSix: item.Type }
            }), "NA")
          })
        } else {
          this.setState({ onlineSTP: [] })
        }
        if (res.table2 != null && res.table2.length != 0) {
          this.setState({ totalOnlineSTP: replaceNull(res.table2, "NA") })
        }


      } else {
        this.setState({ onlineSTP: [] })
      }
    })
  }
  componentDidUpdate(prev) {
    const { page, clientID, date, L5ID, ucc, uccList } = this.props;
    if (prev.page?.state?.tabValue != page?.state?.tabValue) {
      this.handleChange("", page?.state?.tabValue)
    }
    if (prev.uccList != uccList) {
      if (uccList.length != 0) {
        this.setState({ isJointHolderAccount: Boolean(uccList.filter((item) => item.ClientCode == ucc)[0]?.SecondApplicantName) })
      } else {
        this.setState({ isJointHolderAccount: false })
      }
    }
    if (prev.clientID != clientID || prev.ucc != ucc) {
      this.getReportData(ucc)
      this.onlineSTPReportAPI(ucc)
      this.tradeDetails()
      this.props.getUpcommingSIPReport(clientID, 1, 0, L5ID, 0, ucc);
      this.props.getMandateDetails(clientID, 1, 0, L5ID, 0, ucc);
    }
  }

  componentWillUnmount() {
    this.setState({ value: "" })
  }
  handleChange = (event, newValue) => {
    const { page, clientID, date, L5ID, ucc, uccList } = this.props;
    this.setState({ value: newValue })
    if (newValue == 3) {
      this.getReportData(this.props.ucc)
    }
    else if (newValue == 4) {
      this.onlineSTPReportAPI(this.props.ucc)
    }
    else if (newValue == 2) {
      this.props.getMandateDetails(clientID, 1, 0, L5ID, 0, ucc);
    }
    else if (newValue == 0) {
      this.tradeDetails()
    }
  };
  cancelClick = (item) => {
    this.setState({ List: item, openCancelPopup: true })
  }
  onClose = () => {
    this.setState({ openCancelPopup: false })
  }
  handlesnanckClose = () => {
    this.setState({ open: false });
  }
  handleCancel = () => {
    this.setState({ cancelLoader: true })
    getCancelOnlineSTP(
      this.state.List.ClientCode,
      this.state.List.FromBSESchemeCode,
      this.state.List.ToBSESchemeCode,
      constants.STP.BuySellType,
      constants.STP.TransactionMode,
      this.state.List.FolioNo,
      this.state.List.STPRegNo,
      "123",
      this.state.List.STPStartDate,
      this.state.List.Frequency,
      this.state.List.NoOfTransfers.toString(),
      this.state.List.Amount.toString(),
      this.state.List.InstUnit.toString(),
      this.state.List.FirstOrderToday,
      "",
      this.state.List.EUIN_DECLARATION,
      this.state.List.EUIN_NUMBER,
      constants.STP.Remarks,
      this.state.List.STPEndDate,
      "",
      "",
      "",
      "",
      "",
      "",
      this.state.List.Type == "AMC" ? true : false,
      this.props.logindetails.ClientID,
      this.state.List.FromSchemeID,
      this.state.List.ToSchemeID,
      constants.Common.Source,
    ).then((res) => {
      this.setState({ cancelLoader: false, open: true, message: res.message, openCancelPopup: false })
      if (res.status == "S") {
        this.setState({ severity: "success" })
      } else {
        this.setState({ severity: "warning" })
      }
    })
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            classes={{ root: classes.tabRoot }}
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{ style: { background: window.globalConfig.color.BROWN } }}
          >
            <Tab label="MF Order Status" {...a11yProps(0)} />
            <Tab label="Upcoming SIPs" {...a11yProps(1)} />
            <Tab label="Mandate" {...a11yProps(2)} />
            <Tab label="Online SIPs" {...a11yProps(3)} />
            <Tab label="Online STPs" {...a11yProps(4)} />

          </Tabs>
        </AppBar>
        <TabPanel value={this.state.value} index={0}>
          <TransactionOrder isJointHolderAccount={this.state.isJointHolderAccount} transactionOrder={this.state.transactionOrder} loader={this.state.orderStatusLoader} />
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
          <UpComingSIP />
        </TabPanel>
        <TabPanel value={this.state.value} index={2}>
          <Summary />
        </TabPanel>
        <TabPanel value={this.state.value} index={3}>
          <div style={{ marginBottom: 50 }}>
            <Siptracker header={"Scheme"} flag="onlinesip"
              siptracker={this.state.data}
              List={[]}
              titleStyle={classes.sipTitleContainer}
              sipCategoryContainer={classes.sipCategoryContainer}
              grid={classes.sipTrackerSevenSplitContainer}
              totalSiptrackerAmount={this.state.totalSiptrackerAmount}
              groupsiptracker={[]}
              totalsiptracker={[]}
              isSipTrackerLoading={this.state.loader}
              title={siptrackertitle}></Siptracker>
          </div>
        </TabPanel>
        <TabPanel value={this.state.value} index={4}>
          <>
            <EPFReport grid={classes.onlineSTPContainer} showHeader={true}
              showfilter={false}
              root={classes.onlineSTPRoot}
              popupList={[{ "label": "Cancel STP", onClick: this.cancelClick, showIn: "All" }]} showVertIcon={true}
              singleScheme={"Active STP"} multipleScheme={"Active STPs"} loader={this.state.onlineStpLoader}
              data={this.state.onlineSTP}
              title={[{ "header": "From Scheme Name", "subHeader": "", align: "left" }, { header: "To Scheme Name", align: "left", subHeader: "" }, { header: "Start Date", align: "left", subHeader: "End Date" }, { header: "Frequency", align: "center", subHeader: "" }, { "header": "Amount", align: "right", subHeader: "" }, { "header": "STP Type", align: "center", subHeader: "" },]}></EPFReport>
            {this.state.totalOnlineSTP.length != 0 && this.state.totalOnlineSTP != "NA" && this.state.totalOnlineSTP.map((item, index) => {
              return (<div key={index} className={[classes.onlineSTPContainer, classes.displayTotalContainer].join(' ')} style={{ paddingLeft: 10, paddingRight: 10 }}>
                <div className={[classes.directionColumn].join(' ')} style={{ textAlign: "left" }}>
                  {labels.messages.grandtotal}
                  {/* <span className={classes.folioNumber}>
                    {this.state.onlineSTP.length}
                  </span> */}
                </div>
                <div>

                </div>
                <div>
                  {/* <span className={classes.totalRupee}>{Label.currency.rupeesymbol}</span> */}
                </div>
                <div>

                </div>
                <div>
                  {NegativeFinding(item.TotalAmount, "", "", "", "rupee")}

                </div>
                <div>

                </div>

              </div>)
            })}
          </>
        </TabPanel>
        <DeletePopup header={"Confirmation"} onClick={this.handleCancel} loader={this.state.cancelLoader} open={this.state.openCancelPopup} text={labels.runningSip.cancelSTPpopup} onClose={this.onClose} />
        <CommonSnackbar
          open={this.state.open}
          severity={this.state.severity}
          timer={9000}
          message={this.state.message}
          handlesnanckClose={this.handlesnanckClose}
        />
      </div>
    );
  }

}
const mapStateToProps = state => {
  // //
  return {
    //COMMON PARAM
    sessionID: state.Login.sessionID,
    clientID: state.Login.logindetails.ClientID,
    clientCode: state.Login.logindetails.ClientCode,
    date: state.Login.date,
    userID: state.Login.userID,
    logindetails: state.Login.logindetails,
    L5ID: state.Login.L5ID,
    l5Report: state?.L5report?.L5report,
    ucc: state.Login.uccAccount,
    uccList: state.ProfileDetails.AllProfileDetails.length != 0 && state.ProfileDetails.AllProfileDetails?.uccList != undefined && state.ProfileDetails.AllProfileDetails?.uccList?.length != 0 ? replaceNull(state.ProfileDetails.AllProfileDetails.uccList != "NA" ? state.ProfileDetails.AllProfileDetails.uccList.filter(
      (ele, ind) =>
        ind ===
        state.ProfileDetails.AllProfileDetails.uccList.findIndex((elem) => elem.ClientCode === ele.ClientCode)
    ).map((item) => {
      return { ...item, "Name": `${item.DisplayName} ( ${item.ClientCode} )`, "L5ID": item.ClientCode, "Value": item.ClientCode }
    }) : [], "", "NA") : [],

  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getTransactionOrderReport,
      getUpcommingSIPReport,
      getMandateDetails
    },
    dispatch
  );
};
// const mergestyles = combineStyle(Styles, commonstyletable);
export default withStyles(Styles)(connect(mapStateToProps, mapDispatchToProps)(TradingTab))
// export default withStyles(Styles)(TradingTab)