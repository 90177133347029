import * as types from '../../constants/actionsType';
import getL5report from '../../../api/L5report/L5report';
export function getL5reportDetails(ClientID) {
    return (dispatch) => {
        getAllL5report(dispatch, ClientID);
        // dispatch({
        //   type: types.GET_PROFITLOSS_DATA,
        //   payload: Summary,
        // })
    }
}
function getAllL5report(dispatch, ClientID) {
    dispatch({
        type: types.EMPTY_L5REPORT,
        payload: []
    });
    dispatch({
        type: types.LOADER_L5REPORT,
        payload: []
    });
    getL5report(ClientID).then((data) => {
        if (data.status == "S") {
            dispatch({
                type: types.PREVENT_API_CALL,
                payload: [{ ProductID: 0, api: "L5Report" }],
            });

            dispatch({
                type: types.L5REPORT,
                payload: data.data
            })

        } else {
            dispatch({
                type: types.PREVENT_API_CALL,
                payload: [{ ProductID: 0, api: "L5Report" }],
            });
            dispatch({
                type: types.PREVENT_API_CALL,
                payload: "L5Report",
            });
            dispatch({
                type: types.EMPTY_L5REPORT,
                payload: []
            });

        }

    })
        .catch((error) => {
            dispatch({
                type: types.PREVENT_API_CALL,
                payload: [{ ProductID: 0, api: "L5Report" }],
            });
            dispatch({
                type: types.EMPTY_L5REPORT,
                payload: []
            });

        })
}
export const storeL5Report = (data) => {
    return {
        type: types.L5REPORT,
        payload: data
    };
}
