/* name should be camel case in all function */
//const BaseUrl='https://jsonplaceholder.typicode.com/todos';
import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
function getFamilyCategoryAllocation(ClientID, AsOnDate, SessionID, ProductID,) {
  //  let BaseUrl="https://ms.zoifintech.com/Dashboard/GetSectorAnalysisAllocation"
  let data = {};
  data.ClientID = ClientID
  data.AsOnDate = AsOnDate
  data.SessionID = SessionID
  data.ProductID = ProductID
  // data.FamilyID = 0
  // data.L5ID = 0
  // data.L4ID = 0
  // var data = JSON.stringify({"ClientID":20319,"AsOnDate":"16 Feb 2021","SessionID":"ABE48CB1-C378-4DE8-A77A-035160F4C41C"});
  return Networking.PostApi(ApiValues.FamilyCategoryAllocation, data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
  // var config = {
  //   method: 'post',
  //   url: ApiValues.SectorAllocation,
  //   headers: {
  //           'Content-Type': 'application/json'
  //   },
  //   data: data
  // };

  // return axios(config)
  //   .then(function (response) {
  //     return response.data;
  //   })
  //   .catch(function (error) {
  //     return {"status":"F","message":labels.messages.apierror}
  //     // if(error.message="Network Error"){
  //     //   var data={}
  //     //   data.status="N"
  //     //   data.message=ex.message
  //     //   return  data
  //     // }
  //   });


  // fetch method ends here

}
export { getFamilyCategoryAllocation, };
