import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Label from '../../utils/constants/labels';
import Button from '../custombutton/button';
// import Color from '../../utils/colors/colors';
import Color from '../../utils/colors/colors';
import RiskChart from '../../components/profile/risk_chart';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import '../../components/profile/popup.css';
import Popup from "../../components/advisoryaccount/popup";
import { connect } from 'react-redux';
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import CommonSnackBar from '../../components/snackbar/snackbar'
import InformationTooltip from '../../components/tooltip/informationtooltip'
import colors from '../../utils/colors/colors';
const styles = theme => ({
    image: {
        width: 20,
        height: 20,
    },
    // topBlock:{
    //     width:"100%",
    //     backgroundColor: window.globalConfig.color.ShadeBlue,
    //     paddingTop:5,
    //     paddingBottom:5
    // },
    // waringText:{
    //     color: window.globalConfig.color.INKCOLOR,
    //     fontSize: 14,
    //     fontWeight:500
    // },
    // waringButton:{
    //     backgroundColor:window.globalConfig.color.INKCOLOR,
    //     color:window.globalConfig.color.WHITE,
    //     marginLeft:30,
    //     borderRadius:3,
    //     border:'none',
    //     [theme.breakpoints.down('600')]: {
    //         marginLeft:10,
    //      }
    // },
    riskBlock: {
        display: 'flex',
        textAlign: 'left',
    },
    mainBlock: {
        marginTop: 20,
        marginLeft: "1vw"
    },
    risk: {
        display: 'flex',
        marginTop: 30,
        height: '90vh',
        [theme.breakpoints.down('600')]: {
            display: "none !important"
        }
    },
    riskResponsive: {
        display: "none !important",
        [theme.breakpoints.down('600')]: {
            display: "block !important"
        }
    },
    emptySpace: {
        width: "10%",
    },
    resultEmptySpace: {
        width: "50%",
        marginLeft: "25px",
        textAlign: "left",
        lineHeight: 1.5,
        letterSpace: "1px",
        fontStyle: "italic"
    },
    chart: {
        [theme.breakpoints.between('300', '350')]: {
            marginLeft: 40,
            marginTop: 20
        },
        [theme.breakpoints.between('350', '400')]: {
            marginLeft: 70,
            marginTop: 20
        },
        [theme.breakpoints.between('400', '600')]: {
            marginLeft: 90,
            marginTop: 20
        },
        [theme.breakpoints.between('1020', '1030')]: {
            marginLeft: 100,
            marginTop: 20
        },
    },
    resultRiskBlock: {
         textAlign: 'left',
        width: '100%',
        marginTop: 80,
        [theme.breakpoints.down('600')]: {
            // marginLeft: 100
        }
    },
    resultRisk: {
        // marginLeft: 115,
        fontSize: 16,
        fontWeight: 500,
        color: window.globalConfig.color.GrayDark,
        [theme.breakpoints.down('600')]: {
            marginLeft: 0,
            width: 100
        }
    },
    riskProfile: {
        width: "60%",
        [theme.breakpoints.down('600')]: {
            width: "100%",
        }
    },
    topSpace: {
        height: "50px !important",
        [theme.breakpoints.down('600')]: {
            height: "30px !important",
        }
    },
    riskHeader: {
        textAlign: "left",
        // paddingBottom: "12px",
        margin: "0px",

        fontSize: "21px",
        color: colors.BLACK_PRIMARY,
        fontWeight: "bolder",
        paddingTop: "7px"
    },
    riskSubContent: {
        fontSize: 14
    },
    testCard: {
        display: "grid",
        gridTemplateColumns: '55% 15% 30%',
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        borderRadius: "10px",
        height: 30,
        padding: '30px 10px 30px 10px',
        [theme.breakpoints.down('600')]: {
            width: "90%",
            padding: '10px 0px 40px 0px',
        },
        [theme.breakpoints.between('300', '600')]: {
            marginLeft: 15
        },
        [theme.breakpoints.between('600', '1100')]: {
            width: 400,
        },
    },
    riskCard: {
        display: "grid",
        gridTemplateColumns: '30% 27% 43%',
        boxShadow: "0 6px 20px 0 rgba(0, 0, 0, 0.19)",
        borderRadius: "10px",
        height: 30,
        padding: '20px 10px 20px 10px',
        width: 250,
        marginTop: 20,
        marginLeft: 0,
        // [theme.breakpoints.between('300', '350')]: {
        //     marginLeft: 20
        // },
        // [theme.breakpoints.between('350', '400')]: {
        //     marginLeft: 40
        // },
        // [theme.breakpoints.between('400', '600')]: {
        //     marginLeft: 60
        // },
    },
    riskPage: {
        height: 500,
        [theme.breakpoints.down('600')]: {
            height: 800,
        }
    },
    cardResponsive: {
        [theme.breakpoints.down('600')]: {
            marginTop: 30
        }
    },
    riskReport: {
        width: "30%",
        marginTop: 20,
        [theme.breakpoints.down('600')]: {
            width: "100%"
        }
    },
    riskEmptySpace: {
        width: "40%",
        [theme.breakpoints.down('600')]: {
            display: "none !important"
        }
    },
    riskSubContentBlock: {
        marginTop: 10
    },
    startButton: {
        backgroundColor: window.globalConfig.color.BROWN,
        color: window.globalConfig.color.WHITE,
        marginLeft: 50,
        borderRadius: 3,
        border: 'none',
        padding: '10px 30px 10px 30px',
        [theme.breakpoints.down('600')]: {
            marginTop: 10,
            marginLeft: 0,
            marginLeft: 10,
        }
    },
    retestButton: {
        backgroundColor: window.globalConfig.color.brown,
        color: window.globalConfig.color.WHITE,
        marginLeft: 70,
        borderRadius: 3,
        border: 'none',
        padding: '10px 30px 10px 30px',
    },
    reportText: {
        fontSize: 16
    },
    testText: {
        fontSize: 14
    },
    mailButton: {
        backgroundColor: window.globalConfig.color.GRAY_LIGHT,
        color: window.globalConfig.color.BLACK,
        borderRadius: 3,
        border: 'none',
        marginTop: 15,
        padding: '10px 10px 10px 10px'
    },
    viewButton: {
        backgroundColor: window.globalConfig.color.GRAY_LIGHT,
        color: window.globalConfig.color.BLACK,
        borderRadius: 3,
        border: 'none',
        marginTop: 30,
        padding: '10px 40px 10px 40px ',
    },
    helpButton: {
        backgroundColor: window.globalConfig.color.GRAY_LIGHT,
        color: window.globalConfig.color.BLACK,
        borderRadius: 3,
        border: 'none',
        marginTop: 15,
        padding: '10px 30px 10px 30px ',
    },
    skipButton: {
        backgroundColor: window.globalConfig.color.BROWN,
        color: window.globalConfig.color.WHITE,
        borderRadius: 3,
        border: 'none',
        marginTop: 15,
        marginRight: "350px !important",
        padding: '10px 30px 10px 30px ',
        [theme.breakpoints.down('600')]: {
            marginLeft: "0px !important",
            marginRight: "0px !important",
            padding: '10px 60px 10px 60px ',
            marginTop: 35,
        },
        // [theme.breakpoints.between('1000','1030')]: {
        //     marginLeft: '-30px !important',
        // },
        // [theme.breakpoints.between('1250','1300')]: {
        //     marginLeft: '0px !important',
        // }
    },
    heightSpace: {
        height: '80px'
    },
    cardSize: {
        width: "30%",
        [theme.breakpoints.between('1250', '1300')]: {
            width: "35%",
        },
        [theme.breakpoints.between('1000', '1250')]: {
            width: "40%",
        },
    },
    iconDesign: {
        marginLeft: 10,
        cursor: 'pointer'
    },
    modelBox: {
        width: 250,
        padding: '5px 20px 20px 20px',
        height: 'auto',
        borderRadius: 10
    },
    question: {
        fontSize: 14,
        fontWeight: 'bold'
    },
    optionButton: {
        backgroundColor: window.globalConfig.color.VeryLight_Gray,
        color: window.globalConfig.color.BLACK,
        borderRadius: 3,
        border: 'none',
        marginTop: 30,
        width: "100%",
        fontSize: 12,
        '&:hover': {
            backgroundColor: window.globalConfig.color.floralWhite,
            color: window.globalConfig.color.BROWN,
        }
    },
    header: {
        width: "100%",
        padding: "10px 0px 10px 0px"
    },
    headerContent: {
        marginLeft: 20
    },
    questionNumber: {
        fontSize: 16,
        fontWeight: 'bold',
        borderBottom: "4px solid rgb(175, 118, 46)"
    },
    middle: {
        marginLeft: 5,
        fontSize: 10,
        color: window.globalConfig.color.GRAY_LIGHT
    },
    requestLink: {
        cursor: 'pointer',
        color: window.globalConfig.color.lightBlue,
        fontSize: 14
    },
    questionCount: {
        marginLeft: 5,
        fontSize: 12,
        color: window.globalConfig.color.GRAY_LIGHT
    },
    selectCart: {
        backgroundColor: 'red'
    }

});
let status = ""
class RiskProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // show: false,
            count: 1,
            popupVisible: false,
            openToast: false,
            severity: '',
            message: '',
            riskfailureloader: false
        };
    }

    showModal = () => {
        if (this.props.Questionorder.length == 0) {

        } else {
            this.setState({
                popupVisible: true
            })
        }

    }

    onClick = () => {
        if (this.props.Questionorder.length == 0) {

        } else {
            this.setState({
                popupVisible: true
            })
        }
    }
    count = (value) => {
     
    }
    closePopup = () => {
        this.setState({
            popupVisible: false
        })
    }

    closeToast = () => {
        this.setState({
            openToast: false
        })
    }
    onsubmitClick = (data) => {
        if (data.status_type === "Error") {
            this.setState({
                openToast: true,
                message: data.status_message,
                severity: 'error'
            })
        } else {
          

        }
    }

    render() {
        const { classes } = this.props;
        try {
            var RiskDescription = '';
            if (this.props.logindetails != undefined && this.props.logindetails != null && this.props.logindetails != "") {
                if (typeof (this.props.logindetails.RiskDescription) != "object") {
                    RiskDescription = this.props.logindetails.RiskDescription
                }
            }
            if (this.props.riskAnswers.response_data != undefined && this.props.riskAnswers.response_data != null && this.props.riskAnswers.response_data != "") {
                RiskDescription = this.props.riskAnswers.response_data.risk_description
            }
            // 

            var riskText = "";
            if (typeof (this.props.logindetails.RiskText) != "object" && this.props.logindetails.RiskText != "" && this.props.logindetails.RiskText != null && this.props.logindetails.RiskText != undefined) {
                riskText = this.props.logindetails.RiskText
            }
            if (this.props.riskAnswers.response_data != undefined && this.props.riskAnswers.response_data != null && this.props.riskAnswers.response_data != "") {
                riskText = this.props.riskAnswers.response_data.risk_text
            }

            return (
                <div className={classes.mainBlock}>
                    <CommonSnackBar open={this.state.openToast} handlesnanckClose={this.closeToast} severity={this.state.severity} message={this.state.message} />
                    <div className={classes.riskBlock}>
                        {/* <div className={classes.emptySpace}></div> */}
                        <div className={classes.riskProfile}>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    <span className={classes.riskHeader}>{Label.riskProfile.riskProfile}</span>
                                </div>
                                <InformationTooltip title={Label.riskProfile.riskProfile}/>
                            </div>

                            <div className={classes.riskSubContentBlock}>
                                <span className={classes.riskSubContent}>{Label.riskProfile.riskContent}</span>
                            </div>
                        </div>

                    </div>
                    {(this.props.riskAnswers.status_type == 'Error' || this.props.riskAnswers == '') && this.props.logindetails.RiskDescription == "" &&
                        <>
                            <div className={classes.risk}>
                                {/* <div className={classes.emptySpace}></div> */}
                                <div className={classes.cardSize}>
                                    <div className={classes.testCard}>
                                        <div style={{ paddingTop: '2.5%' }}>
                                            <span className={classes.testText}>{Label.riskProfile.riskTest}</span>
                                        </div>
                                        <div></div>
                                        <div style={{ width: 80, marginTop: '-3%' }}>
                                            <Button page={"primary"} onClick={this.showModal} disabled={this.props.Questionorder.length == 0 ? true : false} name={Label.riskProfile.start} />
                                        </div>
                                    </div>

                                    {/* <RiskPopup onClose={this.closeModal} open={this.state.show} list={ListItem} /> */}
                                    {/* <RiskChart />  */}
                                    {this.state.popupVisible == true &&
                                        <Popup count={this.count} onsubmitClick={this.onsubmitClick} open={this.state.popupVisible} onClose={this.closePopup} />
                                    }
                                    <div className={classes.heightSpace}></div>
                                    {/* <button className={classes.skipButton}>{Label.riskProfile.skip}</button> */}
                                </div>
                                <div className={classes.riskEmptySpace}></div>
                            </div>
                            {/* Mobile Responsive */}
                            <div className={classes.riskResponsive}>
                                <div className={classes.cardResponsive}>
                                    <div className={classes.testCard}>
                                        <div style={{ paddingTop: '10%' }}>
                                            <span className={classes.testText}>{Label.riskProfile.riskTest}</span>
                                        </div>
                                        <div></div>
                                        <div style={{ width: 70, marginTop: '10%' }}>
                                            <Button page={"primary"} onClick={this.showModal} disabled={this.props.Questionorder.length == 0 ? true : false} name={Label.riskProfile.start} />
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.riskReport}>
                                    <span className={classes.reportText}>{Label.riskProfile.riskReport}</span><br />
                                </div>
                            </div>
                        </>
                    }
                    {this.props.riskAnswers.status_type == 'Success' || this.props.logindetails.RiskDescription != "" ?
                        <>
                            <div className={classes.risk}>

                                <div className={classes.cardSize}>
                                    <RiskChart />
                                    <div className={classes.resultRiskBlock}>
                                        <span className={classes.resultRisk}>{Label.riskProfile.riskProfile}</span>
                                    </div>
                                    <div className={classes.riskCard}>
                                        <div style={{ paddingTop: '5%' }}>
                                            <span className={classes.testText}>{Label.riskProfile.retest}</span>
                                        </div>
                                        <div></div>
                                        <div style={{ width: 80, marginTop: '-5%' }}>
                                            <Button page={"primary"} onClick={this.onClick} disabled={this.props.Questionorder.length == 0 ? true : false} name={Label.riskProfile.start} />
                                        </div>
                                    </div>
                                    {this.state.popupVisible == true &&
                                        <Popup count={this.count} open={this.state.popupVisible} onsubmitClick={this.onsubmitClick} onClose={this.closePopup} />
                                    }
                                </div>
                                <div className={classes.resultEmptySpace}>
                                    <div style={{ fontWeight: 500, fontSize: "18px", fontStyle: "initial" }}>
                                        {RiskDescription != '' && RiskDescription != null && RiskDescription != undefined && RiskDescription != "NA" ? RiskDescription : ''}
                                    </div>
                                    <div>
                                        {riskText != '' && riskText != undefined && riskText != null && riskText != "NA" ? riskText : ''}
                                    </div>
                                </div>
                            </div>
                            <div className={classes.riskEmptySpace}></div>
                            {/* Mobile Responsive */}
                            <div className={classes.riskResponsive}>
                                <div className={classes.resultEmptySpace}></div>
                                <div className={classes.cardSize}>
                                    <div className={classes.chart}>
                                        <RiskChart />
                                    </div>

                                    <div className={classes.resultRiskBlock}>
                                        <span className={classes.resultRisk}>{Label.riskProfile.riskProfile}</span>
                                    </div>
                                    <div className={classes.riskCard}>
                                        <div style={{ paddingTop: '5%' }}>
                                            <span className={classes.testText}>{Label.riskProfile.retest}</span>
                                        </div>
                                        <div></div>
                                        <div style={{ width: 80, marginTop: '-5%' }}>
                                            <Button page={"primary"} onClick={this.onClick} disabled={this.props.Questionorder.length == 0 ? true : false} name={Label.riskProfile.start} />
                                        </div>
                                    </div>

                                </div>
                                <div style={{ marginTop: '10%' }}>
                                    <div style={{ fontWeight: 500, fontSize: "18px", fontStyle: "initial" }}>
                                        {RiskDescription != '' && RiskDescription != null && RiskDescription != undefined ? RiskDescription : ''}
                                    </div>
                                    <div style={{ marginTop: '3%' }}>
                                        {riskText != '' && riskText != undefined && riskText != null ? riskText : ''}
                                    </div>
                                </div>
                            </div>

                        </>
                        :
                        <></>
                    }
                    <div style={{ width: 100, height: 50 }}></div>
                </div >
            );
        } catch (err) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => ({
    riskAnswers: state.RiskScore.answerOrder != null ? state.RiskScore.answerOrder != undefined ? state.RiskScore.answerOrder : [] : [],
    logindetails: state.Login.logindetails,
    Questionorder: state.RiskQuestion.questionOrder
})
export default withStyles(styles)(connect(mapStateToProps)(RiskProfile))
