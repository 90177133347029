import React from "react";
import Subheader from '../../components/subheader/subheader';
import labels from "../../utils/constants/labels";
import CustomizedStepper from "./stepper";
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Textinput from "../../components/textinput/textinput";
import Button from "../../components/custombutton/button";
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import getGenerateOTP from "../../api/epf/epfgenerateotp";
import getEpfOTPVerification from "../../api/epf/epfotpverification";
import CommonSnackbar from "../../components/snackbar/snackbar";
import { numbersOnly, scrollToTopPage } from "../../utils/commonfunction";
import { EpfList } from "../../redux/actions/epf/epfaction";
import { bindActionCreators } from "redux";
import EpfImage from "../../utils/assets/images/epfi.png";
const styles = (theme) => ({
    header: {
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "left",
        textTransform: "uppercase",
        color: window.globalConfig.color.BLACK_PRIMARY
    },
    subHeader: {
        fontSize: 17,
        fontWeight: "bold",
        textAlign: "left",
        textTransform: "uppercase",
        color: window.globalConfig.color.BLACK_PRIMARY
    },
    card: {
        padding: "2%", borderRadius: 10, boxShadow: `0 0 20px 0 ${window.globalConfig.color.BOX_SHADOW}`,
        marginTop: "3%",
        marginBottom: "6%"
    },
    notificationGrid: {
        display: "grid", gridTemplateColumns: "12% 75% 12%", gap: 10
    },
    text: {
        fontSize: 15,

        textAlign: "left",

        color: window.globalConfig.color.BLACK_PRIMARY
    },
    forgotUanNumber: {
        fontSize: 15,
        fontWeight: "bold",
        cursor: "pointer",
        textAlign: "right",
        color: window.globalConfig.color.LABLE_GRAY
    },
    continueButton: {
        width: "30%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    uanStep: {
        width: "60%",
        display: "flex",
        flexDirection: "column",
        gap: 20
    },
    disableCursor: {
        cursor: "not-allowed",
    }

})
const icons = {
    1: <PermIdentityOutlinedIcon />,
    2: <LockOutlinedIcon />,
    3: <CheckCircleOutlineOutlinedIcon />,
}
let verticalIcons = {
    1: <div>1</div>,
    2: <div>2</div>,
    3: <div>3</div>
}
class EPF extends React.Component {
    constructor() {
        super();
        this.state = {
            activeStep: 0,
            uanNumber: "",
            uanNumberError: "",
            otpNumberError: "",
            OtpNumber: "",
            uanLoader: false,
            verifyLoader: false,
            open: false,
            severity: "",
            message: "",
            List: []

        }
    }
    componentDidMount() {
        document.title = window.globalConfig.companyDetails.companyName + " - EPF"
        scrollToTopPage()
    }
    // handleNext = () => {
    //     this.setState((prevState) => ({ activeStep: prevState.activeStep + 1 }))
    //     // setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // };
    handleUANNumber = (event) => {
        if (numbersOnly(event.target.value)) {
            this.setState({ uanNumber: event.target.value, uanNumberError: "" })

        }
    }
    uanSubmitClick = () => {
        if (this.state.uanNumber != "") {
            if (!this.state.uanLoader) {
                this.setState({ uanLoader: true })
                getGenerateOTP(this.state.uanNumber, this.props.logindetails?.UserID).then((res) => {
                    this.setState({ uanLoader: false })
                    if (res.status == "S") {
                        this.setState({ List: res.data })
                        this.setState((prevState) => ({ activeStep: prevState.activeStep + 1 }))

                    } else {
                        this.setState({ open: true, severity: "warning", message: res.message })

                    }

                }).catch((err) => {
                    this.setState({ open: true, severity: "warning", message: labels.messages.apierror })

                    this.setState({ List: [], uanLoader: false })
                })
            }
        }
        else {
            this.setState({ uanNumberError: labels.messages.required })
        }

    }
    handleOTP = (event) => {
        if (numbersOnly(event.target.value)) {
            this.setState({ OtpNumber: event.target.value, otpNumberError: "" })

        }

    }
    verifyOTPClick = () => {

        let data = {};
        data.clientID = this.state.List?.client_id
        data.otp = this.state.OtpNumber
        data.userID = this.props.logindetails?.UserID
        data.companyID = this.props.companyDetails[0].ID
        data.l3ID = this.props.logindetails.ClientID
        data.l4ID = this.props.logindetails.L4_AccountTypeID
        data.l5ID = this.props.L5ID
        if (this.state.OtpNumber != "") {
            if (!this.state.verifyLoader) {
                this.setState({ verifyLoader: true })
                getEpfOTPVerification(data).then((res) => {
                    this.setState({ verifyLoader: false })
                    if (res.status == "S") {
                        this.setState((prevState) => ({ activeStep: 3 }))

                    } else {
                        this.setState({ open: true, severity: "warning", message: res.message })
                    }
                }).catch((err) => {
                    this.setState({ open: true, severity: "warning", message: labels.messages.apierror })

                })
            }
        } else {
            this.setState({ otpNumberError: labels.messages.required })
        }
    }
    handleSnackClose = () => {
        this.setState({ open: false })
    }
    continueClick = () => {
        this.props.history.push(labels.Path.EPFTab)
    }
    backClick = () => {
        this.props.history.push(labels.Path.Tracking)
    }
    render() {
        const { classes } = this.props
        return (
            <div>
                <Subheader showImage={true} redirectionURL={this.backClick} icon={<img src={EpfImage} style={{ backgroundColor: window.globalConfig.color.WHITE, borderRadius: "50%" }}></img>} heading={labels.messages.Epf}></Subheader>
                <div className={classes.notificationGrid}>
                    <div></div>
                    <div className={classes.card}>
                        <div className={classes.header}>
                            {labels.epf.addEpf}

                        </div>

                        <CustomizedStepper steps={["", "", ""]} icons={icons} flag="horizontal" activeStep={this.state.activeStep}></CustomizedStepper>
                        {this.state.activeStep == 0 && <div className={classes.uanStep}>
                            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                <div className={classes.subHeader}>{labels.epf.epfDetails}</div>
                                <div className={classes.text} style={{ display: "flex", flexDirection: "row", gap: 5 }} >{labels.epf.uanDescription}
                                    <span style={{ fontWeight: "bold" }}>{" Continue "} </span>
                                    <span> Button</span></div>

                            </div>
                            <Textinput otherProps={{ maxLength: 12 }} error={this.state.uanNumberError} label={labels.epf.uanNumber} value={this.state.uanNumber} handleChange={this.handleUANNumber}></Textinput>
                            <div className={[classes.forgotUanNumber, classes.disableCursor].join(' ')}>{labels.epf.forgotUanNumber}</div>
                            <div style={{ width: "40%" }}>
                                <Button name={labels.epf.continue} loader={this.state.uanLoader} onClick={this.uanSubmitClick} className={classes.continueButton} page={"primary"} ></Button>
                            </div>
                        </div>}
                        {this.state.activeStep == 1 && <div className={classes.uanStep}>
                            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                <div className={classes.subHeader}>{labels.epf.otpVerification}</div>
                                <div className={classes.text} style={{ display: "flex", flexDirection: "row", gap: 5 }} >{labels.epf.otpDescription}{this.state.List?.masked_mobile_number}{labels.epf.enterOTP}

                                </div>

                            </div>
                            <Textinput otherProps={{ maxLength: 6 }} error={this.state.otpNumberError} label={labels.epf.otp} value={this.state.OtpNumber} handleChange={this.handleOTP}></Textinput>
                            <div style={{ width: "40%" }}>
                                <Button name={labels.epf.verify} loader={this.state.verifyLoader} onClick={this.verifyOTPClick} className={classes.continueButton} page={"primary"} ></Button>
                            </div>
                        </div>}
                        {this.state.activeStep >= 2 && <div className={classes.uanStep}>
                            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                <div className={classes.subHeader}>{labels.epf.inputSuccessful}</div>

                            </div>
                            <CustomizedStepper activeStep={3} steps={["Your request has been accepted", "Processing your request", "Sucessfully updated"]} icons={verticalIcons} flag="vertical"></CustomizedStepper>
                            <div style={{ width: "40%" }}>
                                <Button name={labels.epf.continue} className={classes.continueButton} page={"primary"} onClick={this.continueClick} ></Button>
                            </div>
                        </div>}
                    </div>
                    <div></div>
                </div>
                <CommonSnackbar open={this.state.open} handlesnanckClose={this.handleSnackClose} severity={this.state.severity} message={this.state.message} />
            </div >
        )
    }
}
const mapStateToProps = (state) => ({
    date: state.Login.date,
    logindetails: state.Login.logindetails,
    L5ID: state.Login.L5ID,
    companyDetails: state.Login.companyDetails,
    userDetails:
        state.ProfileDetails.AllProfileDetails.length != 0
            ? state.ProfileDetails.AllProfileDetails.mainDetails != "NA"
                ? state.ProfileDetails.AllProfileDetails.mainDetails
                : []
            : [],
});

// const dispatchToProps = (dispatch) => ({
//     onChange: (date, toDate) => dispatch(dateChage(date))
// });
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        { EpfList },
        dispatch
    );
};
export default withStyles(styles)(withRouter(
    connect(mapStateToProps, mapDispatchToProps)(EPF))
);