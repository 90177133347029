import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
import Networking from '../../utils/api/apiaccess';
var axios = require('axios');
function getSWPApidetails(ClientCode, BSESchemeCode, TransactionMode
  , FolioNumber,  StartDate,InstallmentUnits,FrequencyType,InstallmentAmount,  
  NoOfWithdrawls,   FirstOrderToday, SubBrokerCode, EUINDeclaration,
  EUINNumber, Remarks,  ClientID,SchemeID,Source,flag,OrderId) {

  var data = {};
  data.ClientCode = ClientCode;
  data.BSESchemeCode = BSESchemeCode;
  data.TransactionMode = TransactionMode;
  data.FolioNumber = FolioNumber;
 // data.InternalRefNumber = InternalRefNumber;
  data.StartDate = StartDate;
  data.NoOfWithdrawls = NoOfWithdrawls;
  data.FrequencyType = FrequencyType;
  data.InstallmentAmount = InstallmentAmount;
  data.InstallmentUnits = InstallmentUnits;
  data.FirstOrderToday = FirstOrderToday;
  data.SubBrokerCode = "";
  data.EUINDeclaration = EUINDeclaration;
  data.EUINNumber = EUINNumber;
  data.Remarks = Remarks;
  data.ClientID = ClientID
  data.Source=Source
  data.SchemeID = SchemeID
  if(flag===undefined){

  }else{
    data.OrderId=OrderId
  }
  // var config = {
  //   method: 'post',
  //   url: ApiValues.SWP,
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   data: data
  // };

  // return axios(config)
  //   .then(function (response) {
  //     return response.data
  //   })
  //   .catch(function (error) {
  //     return {"status":"F","message":labels.messages.apierror}
  //   });
  return Networking.PostApi(ApiValues.SWP, data,labels.apiKey.apiKeys).then(result => { return result })
  .catch(function (error) {
    return { "status": "F", "message": labels.messages.apierror }
  });

}
export default getSWPApidetails
