import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
//action created by dulcy
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
function getAddNominee(NomineeID, ClientID, NomineeType, NomineeName,L5ID, RelationWithAccountHolder, NomineePercentage, IsNomineeMinor,NomineeGuardian) {
 

    var raw = JSON.stringify({
        "NomineeID" : NomineeID,
        "ClientID" : ClientID,
        "NomineeType" : NomineeType,
        "NomineeName" : NomineeName,
        "L5ID":L5ID,
        "RelationWithAccountHolder" : RelationWithAccountHolder,
        "NomineePercentage" :NomineePercentage,
        "IsNomineeMinor" : IsNomineeMinor,
        "NomineeGuardian" : NomineeGuardian
    });
  //   var config = {
  //       method: 'post',
  //       url: ApiValues.AddNominee,
  //       headers: { 
  //         'Content-Type': 'application/json'
  //       },
  //       data : raw
  //     };
      
  //  return   axios(config)
  //     .then(function (response) {
  //     return response.data
  //     })
  //     .catch(function (error) {
  //       return{status:"F",message:labels.messages.apierror}
  //     });
  return Networking.PostApi(ApiValues.AddNominee, raw).then(result => { return result })
  .catch(function (error) {
    return { "status": "F", "message": labels.messages.apierror }
  });
}
export default getAddNominee
