import * as types from '../../constants/actionsType';
import getSchemeDetails  from '../../../api/schemedetails/schemedetails';
export function SchemeDetails(Amount,ISIN,RTACode,ClientID) {
  return (dispatch) => {
    postSchemeDetails(dispatch,Amount,ISIN,RTACode,ClientID);
  }
}
function postSchemeDetails(dispatch,Amount,ISIN,RTACode,ClientID) {
    getSchemeDetails(Amount,ISIN,RTACode,ClientID)
    .then((data) => {
      
      if (data != undefined) {
        if (data.status == "S") {
          dispatch({
            type: types.SCHEME_ID,
            payload: data,
          }); 
        } else {
      dispatch({
                type: types.SCHEME_ID,
                payload: [],
              }); 
        }
      }
    })
    .catch((error) => {

    })
}
export const NetworkErrorSchemeDetails=(online)=>{
  return{
    type: types.NETWORK_SCHEME_DETAILS,
    payload:online
  }
  }
