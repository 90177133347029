import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import Summary from '../../components/summary/index';
import Table from '../../components/table/profitlossreport';
import Portifolio from '../portfolio-x-ray/portfolio-x-ray'
import CapitalGain from '../capitalgain/capitalgain'
import AccountStatement from '../accountstatement/accountstatement'
import PortfolioReport from '../portfolioreport/portfolioreport'
import combineStyle from "../../utils/stylesheets/combainstyle/combainstyle";
import commonstyletable from "../../utils/stylesheets/table/commontablestyle";
import Transaction from '../transaction/transactionreport'
import XrayWithRedemption from '../x-raywithredemption/x-raywithredemption'
import MarketCapAllocation from '../marketcapallocation/marketcapallocation'
import '../../utils/stylesheets/tabs/tabs.css'
import secureStorage from '../../utils/securestorage';
import labels from '../../utils/constants/labels';
import Label from '../../utils/constants/labels';
import { equity_transactionfilter, } from '../../redux/actions/transaction/actions';
import { equityprofitandlossfilter, getProfitLossDetails } from '../../redux/actions/profitloss/profitlossaction'
import {
  equityFilterChange,
} from "../../redux/actions/portfolioxray/portfolioxray";
import { bindActionCreators } from "redux";
import getAllTransferIn from '../../api/transferin/transferin';
import { replaceNull, sortArray } from '../../utils/commonfunction';
import getIndustryWiseXrayList from '../../api/portfolioxrayreport/industrywiseapi';
import getMargetCapWiseList from '../../api/portfolioxrayreport/margetcapwise';
import moment from 'moment';
import Equity_marketcapallocation from '../marketcapallocation/equity_marketcapallocation';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Typography>{children}</Typography>      
        <div
          style={{
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: '0.00938rem',
          }}
        >{children}</div>

      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const Styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
  },
  tabRoot: {
    "& .MuiTab-root:focus": {
      color: window.globalConfig.color.BROWN
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: window.globalConfig.color.BROWN
    }
  },
  portfolioXrayCatrgorySevenSplitContainer: {
    display: "grid",
    gridTemplateColumns: "32% 12% 14% 14% 14% 14%!important",
    // paddingLeft: "10px"
    marginLeft: "20px",
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr) !important',
      gridTemplateAreas: `"categorySchemeGrid categoryInvestedGrid"
      ". categoryNetpl"
     `,
    }
  },
  portfolioXraySevenSplitContainer: {
    display: "grid",
    gridTemplateColumns: "35% 10% 13% 14% 14% 14%",
    marginLeft: 20,
    [theme.breakpoints.down("920")]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr) !important',
      gap: 1,
      gridTemplateRows: 'auto',
      paddingRight: 10,
      gridTemplateAreas: `"schemeFirstGrid  . schemeFifthGrid"
  "schemeSecondGrid . schemeSixthGrid"
  "schemeThirdGrid . investedAmountGrid"`,
      "& .schemeFourthGrid": {
        display: "none"
      }
    },
  },
  marketContainer: {
    display: "grid",
    gridTemplateColumns: "35% 16% 16% 16% 16%",
    // marginLeft: "20px",
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr)",
      gridTemplateAreas: `"${"firstGrid"} ${"sixthGrid"}"
      "${"secondGrid"} ${"thirdGrid"}"
      "${"fourthGrid"} ${"fifthGrid"}"`,
      padding: 10,
      gap: 10
    }
  },
  transactionContainer: {
    display: "grid",
    gridTemplateColumns: "10% 36%  10% 10% 17% 17%",
    marginLeft: "20px",
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr)",
      gridTemplateAreas: `"${"dategrid"} ${"dategrid"}"
      "${"schemegrid"} ${"schemegrid"}"
      "${"assetClassgrid"} ${"transactionTypegrid"}"
      "${"folioNumbergrid"} ${"unitgrid"}"
      "${"navgrid"} ${"amountgrid"}"`,
      padding: 10,
      gap: 10
    }
  },
  accountStatementContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(6,1fr)",
    // marginLeft: "20px",
    paddingRight: 10,
    paddingLeft: 10,
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr)",
      gridTemplateAreas: `"${"firstGrid"} ${"eighthGrid"}"
      "${"fifthGrid"} ${"secondGrid"}"
      "${"fourthGrid"} ${"sixthGrid"}"
      "${"thirdGrid"} ${"seventhGrid"}"`,
      padding: 10,
      gap: 10
    }
  },
  taxationFourSplitContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",
    paddingLeft: "10px",

    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr)",
      gridTemplateAreas: `"firstGrid firstGrid"
      "secondGrid thirdGrid"
      "fourthGrid ."`,
      gap: 5,
      marginTop: 10,
      padding: 10,
      "& .firstGrid": {
        textAlign: "center !important"
      },
      "& .secondGrid": {
        alignItems: "flex-start",
        textAlignLast: "left"
      },
      "& .thirdGrid": {
        alignItems: "end",
        textAlignLast: "right"
      },
      "& .fourthGrid": {
        alignItems: "flex-start",
        textAlignLast: "left"
      }
    }
  },

  FourSplitContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",
    paddingLeft: "10px",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(3,1fr)",
    }
  },
  xrayWithRedemptionFirstContainer: {
    display: "grid",
    gridTemplateColumns: "16% 8% 10% 10% 8% 10% 10% 8% 10% 10%!important",
    // paddingLeft:"10px"
    //marginLeft: "20px",

    paddingLeft: 10,
    paddingRight: 10,
    "& .firstGrid": {
      alignItems: "flex-start",
      textAlign: "left"
    },
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr) !important",
      padding: 5,
      gap: 10,
      // marginRight: 9,
      gridTemplateAreas: `"firstGrid firstGrid"
      "ninthGrid tenthGrid"
     `,
      "& .firstGrid": {
        alignItems: "flex-start",
        textAlign: "left"
      },
      "& .secondGrid": {
        display: "none"
      },
      "& .thirdGrid": {
        display: "none"
      },
      "& .fourthGrid": {
        display: "none"
      },
      "& .fifthGrid": {
        display: "none"
      },
      "& .sixthGrid": {
        display: "none"
      },
      "& .seventhGrid": {
        display: "none"
      },
      "& .eighthGrid": {
        display: "none"
      },
      "& .ninthGrid": {
        alignItems: "flex-start",
        textAlign: "left !important"
      }
    }
  },
  xrayWithRedemptionSecondContainer: {
    display: "grid",
    gridTemplateColumns: "16% 8% 10% 10% 8% 10% 10% 8% 10% 10%",
    // gap: 3,
    paddingRight: 10,
    "& .eleventhGrid": {
      textAlign: "left",
      alignItems: "flex-start"
    },
    [theme.breakpoints.down("920")]: {
      gap: 6,
      gridTemplateColumns: "repeat(3,1fr)",
      gridTemplateAreas: `"eleventhGrid eleventhGrid eleventhGrid"
      "twelfthGrid thirteenthGrid fourteenthGrid"
      "fifteenthGrid sixteenthGrid seventeenthGrid"
      "eighteenthGrid ninteenthGrid twentiethGrid"`,
      "& .eleventhGrid": {
        textAlign: "left",
        alignItems: "flex-start"
      },
      "& .thirteenthGrid": {
        textAlign: "center"
      },
      "& .fourteenthGrid": {
        textAlign: "right"
      },
      "& .seventeenthGrid": {
        textAlign: "right"
      },
      "& .sixteenthGrid": {
        textAlign: "center"
      },
      "& .ninteenthGrid": {
        textAlign: "center"
      },
      "& .twelfthGrid": {
        textAlign: "left"
      },
      "& .fifteenthGrid": {
        textAlign: "left"
      },
      "& .eighteenthGrid": {
        textAlign: "left"

      }

    }
  },
  portfolioReportEightSplitContainer: {
    display: "grid",
    gridTemplateColumns: "30% 10% 10% 10% 10% 10% 10% 10%",
    // marginLeft: "20px",
    padding: 8,

    alignContent: "center",
    "& .firstGrid": {
      alignItems: "flex-start",
      textAlign: "left"
    },
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(3,1fr)",
      gridTemplateAreas: `"${"firstGrid"} ${"firstGrid"} ${"eighthGrid"}"
      "${"secondGrid"} ${"thirdGrid"} ${"fourthGrid"} "
      " ${"fifthGrid"} . ${"sixthGrid"}"`,
      gap: 10,
      "& .firstGrid": {
        alignItems: "flex-start",
        textAlign: "left"
      },
      "& .secondGrid": {
        textAlign: "left"
      },
      "& .thirdGrid": {
        // alignItems: "flex-start",
        textAlign: "center"
      },
      "& .fourthGrid": {
        alignItems: "center"
      },
      "& .fifthGrid": {
        textAlign: "left"
      },
      "& .sixthGrid": {
        alignItems: "flex-start",
        textAlign: "right"
      },
      "& .seventhGrid": {
        alignItems: "center",
        display: "none"
      },
      "& .eighthGrid": {
        alignItems: "flex-end"
      }
    }
    // columnGap:'1%',
    // paddingLeft: "10px"
  },
  portfolioReportTenSplitContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(10,1fr)",
    alignContent: "center",
    "& .ninthGrid": {
      textAlign: "left"
    },
    paddingRight: 10,
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(3,1fr) !important",
      gridTemplateAreas: `"${"ninthGrid"} ${"twelfthGrid"} eighteenthGrid"
      "${"tenthGrid"} ${"thirteenthGrid"} ${"fifteenthGrid"} "
      " ${"eleventhGrid"} ${"seventeenthGrid"} ${"sixteenthGrid"}"
      `,
      padding: 10,
      gap: 10,
      "& .ninthGrid": {
        textAlign: "left"
      },
      "& .tenthGrid": {
        textAlign: "left"
      },
      "& .fourteenth": {
        display: "none",
      },
      "& .fifteenthGrid": {
        //  display: "none",
        textAlign: "right"
      },
      "& .seventeenthGrid": {
        textAlign: "center",
      },
      "& .sixteenthGrid": {
        // display: "none",
        textAlign: "right",
        alignItems: "center"
      },
      "& .eleventhGrid": {
        textAlign: "left",
      },
      "& .thirteenthGrid": {
        textAlign: "center"
      },
      "& .twelfthGrid": {
        textAlign: "center"
      },
      "& .eighteenthGrid": {
        textAlign: "right"
      }
    }

  },
  equityTransferinPortfolioXray: {
    display: "grid",
    gridTemplateColumns: "33% 33% 33% !important",
    "& .investedAmountGrid": {
      display: "none"
    },
    "& .myLastGrid": {
      display: "none"
    },
    "& .schemeFifthGrid": {
      display: "none"
    },
    "& .schemeSixthGrid": {
      display: "none"
    },
    [theme.breakpoints.down("920")]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr) !important',
      gap: 10,
      gridTemplateRows: 'auto',
      paddingRight: 10,
      gridTemplateAreas: `"schemeFirstGrid ."
      "schemeFourthGrid  schemeThirdGrid"
      `,
      "& .schemeFourthGrid": {
        alignItems: "flex-start"
      },
      "& .schemeThirdGrid": {
        alignItems: "flex-end"
      }
    },
  },
  equityTransferinPortfolioXrayCategory: {
    display: "grid",
    gridTemplateColumns: "33% 33% 33% !important",
    // paddingLeft: "10px"
    //marginLeft: "20px",
    alignContent: "center",
    "& .categoryMarketValueGrid": {
      display: "none !important"
    },
    "& .categoryAbsoluteGainLossGrid": {
      display: "none"
    },
    [theme.breakpoints.down("920")]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr) !important',
      gridTemplateAreas: `"categorySchemeGrid categoryInvestedGrid"
      
     `,
      "& .categoryNetpl": {
        display: "none"
      }
    }
  }
});
const portfoliotitle = [{ "name": labels.PortfolioReport.type }, { "name": labels.PortfolioReport.date }, { "name": labels.PortfolioReport.quantity, "subheader": labels.PortfolioReport.PRICE },
{ "name": labels.PortfolioReport.gross, "subheader": labels.PortfolioReport.PRICE }, { "name": labels.PortfolioReport.currentamt }, { "name": labels.PortfolioReport.profitloss }, { "name": labels.PortfolioReport.divpay },
{ "name": labels.PortfolioReport.netG, "subheader": labels.PortfolioReport.loss }, { "name": labels.PortfolioReport.day }, { "name": labels.PortfolioReport.absret, "subheader": labels.PortfolioReport.xirr }]
const portfolioxraytitle = [{ "name": labels.Tableheader.ScripName }, { "name": labels.Tableheader.quantity, "subheader": labels.Tableheader.Avgprice }, { "name": labels.Tableheader.InvestedValue, "subheader": labels.Tableheader.amount }, { "name": labels.Tableheader.CurrentValue, "subheader": labels.Tableheader.Subheadingcurrent }, { "name": labels.Tableheader.unrealised, "subheader": labels.Tableheader.dividend }, { "name": labels.Tableheader.Net, "subheader": labels.Tableheader.SubheadingNet }]
const transferIntitle = [{ "name": labels.Tableheader.ScripName }, { "name": labels.Tableheader.quantity, }, { "name": labels.Tableheader.CurrentValue, },]
const xraywithredeemptiontitle = [{ "name": labels.xraywithredemption.scripName }, { "name": labels.xraywithredemption.quantity }, { "name": "invested", 'subheader': "Amount" }, { "name": "current", "subheader": "Value", 'subheader1': "gainloss" },
  , { "name": labels.xraywithredemption.quantity }, { "name": "invested", 'subheader': "Amount" }, { "name": "Sold", "subheader": "Value", 'subheader1': "gainloss" },
  , { "name": labels.xraywithredemption.quantity }, { "name": "invested", 'subheader': "Amount" }, { "name": "Value", 'subheader1': "gainloss" }]
const accountstatementtitle = [{ "name": labels.Accountstatement.Date, "align": "left" }, { "name": labels.Accountstatement.Txntype, "subheader": labels.Accountstatement.Txnnature, "align": "left" }, { "name": labels.Accountstatement.Amount, "align": "right" }, { "name": labels.Accountstatement.quantity, "align": "right" }, { "name": labels.Accountstatement.Price, "align": "right" }, { "name": labels.Accountstatement.Balquantity, "align": "right" }]
class EquityFundTab extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: 0, transferIn: [], transferInLoader: false, List: [], selectedSwitch: "Industry wise",
      key: "Category",
      xrayLoader: false,
      YearCode: "All"
    }
  }

  componentDidMount() {
    document.title = window.globalConfig.companyDetails.companyName + " - Stocks/ETF"
    const { page, xray } = this.props;
    // 
    //if (prev.page != page) {
    if (page.state != undefined) {
      // if (prev.page.state.tabValue != page.state.tabValue) {
      this.setState({ value: page.state.tabValue })
      // }
    }
    // this.setState({ List: this.props.xray })
    //}
    this.transferInReport()
    this.categorySwitch("Industry wise")
  }

  componentDidUpdate(prev) {
    const { page, clientID, date, L5ID, xray } = this.props;
    // 

    if (prev.page != page) {
      if (prev.page.state != undefined) {
        if (prev.page.state.tabValue != page.state.tabValue) {
          this.setState({ value: page.state.tabValue })
        }
      }
    }
    if (prev.xray != xray) {
      this.setState({ List: xray })
    }
    //
    if (prev.clientID != clientID || prev.date != date || prev.L5ID != L5ID) {
      this.transferInReport()
    }
  }

  transferInReport = () => {
    this.setState({ transferInLoader: true })

    getAllTransferIn(this.props.logindetails.ClientID, this.props.date, this.props.logindetails.SessionID, 2, this.props.L5ID, 0).then((res) => {
      this.setState({ transferInLoader: false })
      if (res.status == "S" && res.data != null && res.data.length != 0) {
        this.setState({
          transferIn: res.data.map((item) => {
            return { Scheme: item.ScripName, TotalMarketValue: item.TotalCurrentMarketValuation, CurrentMarketValuation: item.CurrentMarketValuation, Units: item.Units }
          })
        })
      } else {
        this.setState({ transferIn: [] })
      }
    }).catch((err) => {
      this.setState({ transferIn: [], transferInLoader: false })

    })
  }
  handleChange = (event, newValue) => {
    // 

    this.setState({ value: newValue })
  };
  categorySwitch = (value) => {
    this.setState({ selectedSwitch: !this.state.selectedSwitch })
    // this.setState({ selectedSwitch: value })
    // if (value == "Scheme") {
    //   this.setState({ List: this.props.xray, xrayLoader: this.props.isLoadinggroupXray })
    // } else if (value == "Industry wise") {
    //   this.setState({ xrayLoader: true })
    //   getIndustryWiseXrayList(0, this.props.logindetails.ClientID, moment(this.props.date, 'DD MMM YYYY').format("DD MMM YYYY"), 0, 0, 0, 0, this.props.logindetails.SessionID, 100, 0, 2, 0, this.props.L5ID, 0,).then((res) => {
    //     this.setState({ xrayLoader: false })
    //     if (res.status == "S" && replaceNull(res.data, "NA") != "NA") {
    //       this.setState({
    //         List: replaceNull(res.data, "NA").map((item) => {
    //           return { ...item, CategoryUnits: "" }
    //         }), key: "Category",
    //       })
    //     } else {
    //       this.setState({ List: [], key: "" })
    //     }
    //   })
    // } else if (value == "Market Cap wise") {
    //   this.setState({ xrayLoader: true })
    //   getMargetCapWiseList(0, this.props.logindetails.ClientID, moment(this.props.date, 'DD MMM YYYY').format("DD MMM YYYY"), 0, 0, 0, 0, this.props.logindetails.SessionID, 100, 0, 2, 0, this.props.L5ID, 0,).then((res) => {
    //     this.setState({ xrayLoader: false })
    //     if (res.status == "S" && replaceNull(res.data, "NA") != "NA") {
    //       this.setState({
    //         List: replaceNull(res.data, "NA").map((item) => {
    //           return { ...item, CategoryUnits: "" }
    //         }), key: "Category",
    //       })
    //     } else {
    //       this.setState({ List: [], key: "" })
    //     }
    //   })
    // }
  }
  onChange = (item) => {
    this.setState({ YearCode: item[0]?.label })

    this.props.getProfitLossDetails(0, this.props.logindetails.ClientID, this.props.date, this.props.date,
      this.props.date, 0, 0, this.props.logindetails.SessionID, 0, 2, 0, this.props.L5ID, 0, "", item[0]?.YearCode)
  }
  render() {
    //   
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            classes={{ root: classes.tabRoot }}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{ style: { background: window.globalConfig.color.BROWN } }}>
            <Tab label="Summary" {...a11yProps(0)} />
            <Tab label="Portfolio X-Ray" {...a11yProps(1)} />
            <Tab label="Transactions" {...a11yProps(2)} />
            <Tab label="Booked G/L" {...a11yProps(3)} />
            <Tab label="Taxation Report" {...a11yProps(4)} />
            {/* <Tab label="SIP Tracker" {...a11yProps(5)} /> */}
            <Tab label="Statement" {...a11yProps(5)} />
            <Tab label="Portfolio Details" {...a11yProps(6)} />
            {/* <Tab label="AFR Report" {...a11yProps(8)} /> */}
            <Tab label="X-Ray With Sold Units" {...a11yProps(7)} />
            {/* <Tab label="Asset Allocation" {...a11yProps(8)} /> */}
            <Tab label="Market Cap Allocation" {...a11yProps(8)} />
            <Tab label="Transfer In" {...a11yProps(9)} />
            {/* <Tab label="Running SIP Summary" {...a11yProps(12)} /> */}
          </Tabs>
        </AppBar>
        <TabPanel value={this.state.value} index={0}>
          <Summary CategoryAllocation={labels.summaryPage.CategoryAllocation}
            Deptdata={this.props.Deptdata}
            splitByInstrument={labels.summaryPage.splitByMarketCap}
            EquityFundSector={labels.summaryPage.EquitySector}
            flag='equity'
            showChart={true}
            barChartData={this.props.barChartData}
            chart={this.props.chart}
            isLoadingportfolioSummary={this.props.isLoadingportfolioSummary}
            isLoadingportfolioChart={this.props.isLoadingportfolioChart}
            categorySummary={this.props.categorySummary}
            isLoadingcategorySummary={this.props.isLoadingcategorySummary}
            DonutData={this.props.DonutData}
            grandtotal={[labels.messages.grandtotal, labels.currency.rupeesymbol + this.props.grandtotalsummary, "", this.props.categorySummary.length == 0 ? "0%" : "100.0%"]}
            EquityLoading={this.props.isLoadingMarketCapEquity}
            DebtLoading={this.props.isLoadingMarketCapDebt}
            tableHeader={[Label.summaryPage.Category, Label.summaryPage.Amount, Label.summaryPage.categoryPercentage, Label.summaryPage.holdingPercentage]
            }
          />
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
          <Portifolio
            flag='equity'
            externalClasses={classes}
            grid={classes.stockSixSplitContainer}
            Total={labels.ProfitLoss.Total}
            MarketValue={labels.Tableheader.MarketValue}
            CurrentGain={labels.Tableheader.CurrentGain}
            amountLabel={labels.Tableheader.price}
            units={labels.Tableheader.Quantity}
            Subheadingunits={labels.Tableheader.Subheadingprice}
            InvestedValue={labels.Tableheader.InvestedValue}
            AsOn={labels.Tableheader.AsOn}
            totaltext={labels.Tableheader.totaltext}
            Sip={labels.Tableheader.Sip}
            xray={this.props.xray}
            catagorize={this.state.key}
            selected={this.state.selectedSwitch}
            switchList={["Scheme", "Industry wise", "Market Cap wise"]}
            categorySwitch={this.categorySwitch}
            enableToggleList={false}
            Onswitchchange={this.categorySwitch}
            showtoggle={true}
            header={"Scrip"}
            filterSubmit={this.props.equityFilterChange}
            isLoadinggroupXray={this.props.isLoadinggroupXray}
            List={this.props.xray}
            logindetails={this.props.logindetails}
            hideGrandTotalUnitsColumn={true}
            dropdowndetails={this.props.dropdowndetails}
            title={portfolioxraytitle}
            schemeList={this.state.List} />
        </TabPanel>
        <TabPanel value={this.state.value} index={2}>
          {/* TRANSACTION */}
          <Transaction
            ReportList={this.props.financialYearsList.length == 0 ? [] : sortArray([{ label: "All", value: 0, isChecked: false }, ...this.props.financialYearsList.map((item, index) => {
              return { ...item, label: item.Year, value: index + 1, isChecked: false }
            }),], "ID", "DESC")}

            title={[{ 'name': labels.transactionSummary.date }, { 'name': labels.transactionSummary.scripName }, { 'name': labels.transactionSummary.assetClass }, { 'name': labels.transactionSummary.transactionType, "subheader": labels.transactionSummary.TransactionNature }, { 'name': labels.transactionSummary.contractno }, { 'name': labels.transactionSummary.quantity }, { 'name': labels.transactionSummary.price }, { 'name': labels.transactionSummary.amount }]} flag='equity' isLoadingtransactionList={this.props.isLoadingtransactionList} L5ID={this.props.L5ID} maxDate={this.props.maxDate} minDate={this.props.minDate}
            schemeName={labels.transactionSummary.scripName} TransactionxcelLoad={this.props.TransactionxcelLoad} TransactionLoad={this.props.TransactionLoad} logindetails={this.props.logindetails} catchmessage={this.props.catchmessage}
            date={this.props.date} userID={this.props.userID} clientID={this.props.clientID} sessionID={this.props.sessionID}
            transactionList={this.props.transactionList != "NA" && this.props.transactionList.length != 0 ? this.props.transactionList : []}
            filterSubmit={this.props.equity_transactionfilter}
            dropdowntransaction={this.props.dropdowntransaction}
            grid={classes.transactionContainer}
            List={this.props.dropdowntransaction}
            header={"Transaction"}
            filterHeader={"Scrip"}
          />
        </TabPanel>
        <TabPanel value={this.state.value} index={3}>
          {/* PROFIT&LOSS */}
          <Table List={this.props.profitLossfilter} header={"Scrip"} flag={'equity'}
            ReportList={this.props.financialYearsList.length == 0 ? [] : sortArray([{ label: "All", value: 0, }, ...this.props.financialYearsList.map((item, index) => {
              return { ...item, label: item.Year, value: index + 1 }
            }),], "ID", "DESC")}
            YearCode={this.state.YearCode}
            onChange={this.onChange}
            GainValue={labels.ProfitLoss.GainValue} Units={labels.ProfitLoss.quantity} SellValueLabel={labels.ProfitLoss.SellValue}
            PurchaseValueLabel={labels.ProfitLoss.PurchaseValue} SchemeName={labels.ProfitLoss.scripName}
            Total={labels.ProfitLoss.Total}
            filterSubmit={this.props.equityprofitandlossfilter}
            grid={classes.FiveSplitContainer}
            ProfitLoss={this.props.ProfitLoss} profitLossfilter={this.props.profitLossfilter}
            isLoadingProfitLoss={this.props.isLoadingProfitLoss} PurchaseValue={this.props.PurchaseValue}
            DividendValue={this.props.DividendValue} SellValue={this.props.SellValue}
            glAmount={this.props.glAmount} GainPercentage={this.props.GainPercentage}
            isProfitLossLoading={this.props.isProfitLossLoading} />
        </TabPanel>
        <TabPanel value={this.state.value} index={4}>
          <CapitalGain flag="equity" externalClasses={classes} title={[{ "name": Label.capitalGain.FinancialYear }, { "name": Label.capitalGain.valuationreport }, { "name": Label.capitalGain.capitalgains }, { "name": Label.capitalGain.DividendReport }]}
            header={"Scrip"} isCapitalgainLoading={this.props.isCapitalgainLoading} capitalGain={this.props.capitalGain} />
        </TabPanel>
        {/* <TabPanel value={this.state.value} index={5}>
          <SIPTracker header={"Scrip"} flag="equity"
            siptracker={this.props.siptracker}
            groupsiptracker={this.props.groupsiptracker}
            totalsiptracker={this.props.totalsiptracker}
            isSipTrackerLoading={this.props.isSipTrackerLoading}p
            title={siptrackertitle} />
        </TabPanel> */}
        <TabPanel value={this.state.value} index={5}>
          <AccountStatement header={"Scrip"} flag='equity'
            title={accountstatementtitle}
            foliono={this.props.foliono}
            grid={classes.accountStatementContainer}
            // accountstatement={this.props.accountstatement}
            // showwarning={this.props.showwarning}
            // totalaccountstatement={this.props.totalaccountstatement}
            withredeemed={this.props.withredeemed}
            withoutredeemed={this.props.withoutredeemed} />
        </TabPanel>
        <TabPanel value={this.state.value} index={6}>
          <PortfolioReport externalClasses={classes} header={"Transaction"} flag='equity' portfolioreport={this.props.portfolioreport}
            purprice={labels.PortfolioReport.purePrice} units={labels.PortfolioReport.quantity}
            totalreport={this.props.totalreport}
            portfoliocount={this.props.portfoliocount}
            isPortfolioReportLoading={this.props.isPortfolioReportLoading} title={portfoliotitle} />
        </TabPanel>
        {/* <TabPanel value={this.state.value} index={8}>
          <AFRReport
            title={shorttermtitle}
            elsstitle={elsstitle} grandtotal={labels.AFRreport.grandtotal} flag='equity' header={"Scrip"}
            shortTermHolding={labels.AFRreport.short} longTermHolding={labels.AFRreport.long} totalHolding={labels.AFRreport.total}
            shortgroupeddata={this.props.shortgroupeddata} exitload={this.props.exitload}
            elssgroupdata={this.props.elssgroupdata} totalshortterm={this.props.totalshortterm}
            totalexitload={this.props.totalexitload} totalelssdata={this.props.totalelssdata}
            shortterm={this.props.shortterm} Elssdata={this.props.Elssdata}
            isShorttermLoading={this.props.isShorttermLoading} isExitloadLoading={this.props.isExitloadLoading}
            isElssLoading={this.props.isElssLoading} topheaderexitload={labels.AFRreport.topheaderexitload} />
        </TabPanel> */}
        <TabPanel value={this.state.value} index={7}>
          <XrayWithRedemption
            externalClasses={classes}
            schemeNameLabel={labels.xraywithredemption.scripName} invested={labels.xraywithredemption.Invested} amountLabel={labels.xraywithredemption.amount} current={labels.xraywithredemption.current} value={labels.xraywithredemption.value} Gainloss={labels.xraywithredemption.Gainloss}
            total={labels.xraywithredemption.total} units={labels.xraywithredemption.quantity} InvestedValue={labels.xraywithredemption.InvestedValue} currentvalue={labels.xraywithredemption.currentvalue}
            redemption={labels.xraywithredemption.redemption}
            GainLoss={labels.xraywithredemption.GainLoss} XrayTotal={labels.xraywithredemption.XrayTotal} Balancedunits={labels.xraywithredemption.BalancedQuantity}
            Redeemedunits={labels.xraywithredemption.RedeemedQuantity} grandtotal={labels.xraywithredemption.grandtotal}
            title={xraywithredeemptiontitle} header={"Scrip"} flag="equity"
            xraywithredemption={this.props.xraywithredemption} xraywithredemptiongrouped={this.props.xraywithredemptiongrouped}
            totalxraywithredemption={this.props.totalxraywithredemption} isXrayWithRedemptionLoading={this.props.isXrayWithRedemptionLoading} />
        </TabPanel>
        {/* <TabPanel value={this.state.value} index={8}>
          <AssetAllocation flag="equity" header={"Scrip"} title={[{ "name": labels.assetAllocation.equity }, { 'name': labels.assetAllocation.debt }, { 'name': labels.assetAllocation.gold }, { 'name': labels.assetAllocation.others }]}
            titleAmountAge={[{ 'name': labels.Tableheader.ScripName, 'age': '' }, { 'name': labels.assetAllocation.amountAge, 'age': labels.assetAllocation.Age },
            { 'name': labels.assetAllocation.amountAge, 'age': labels.assetAllocation.Age }, { 'name': labels.assetAllocation.amountAge, 'age': labels.assetAllocation.Age },
            { 'name': labels.assetAllocation.amountAge, 'age': labels.assetAllocation.Age }, { 'name': labels.assetAllocation.totalamount, 'age': '' }]}
            isAssetAllocationLoading={this.props.isAssetAllocationLoading} sessionID={this.props.sessionID}
            clientID={this.props.clientID} userID={this.props.userID} date={this.props.date} logindetails={this.props.logindetails}
            assetallocationgrouped={this.props.assetallocationgrouped} totalassetallocation={this.props.totalassetallocation}
            assetallocation={this.props.assetallocation} isNetworkLow={this.props.isNetworkLow} L5ID={this.props.L5ID} />
        </TabPanel> */}
        <TabPanel value={this.state.value} index={8}>
          <Equity_marketcapallocation flag="equity" grid={classes.marketContainer} header={"Scrip"} title={[{ 'name': labels.marketCapAllocation.smallCap }, { 'name': labels.marketCapAllocation.midCap }, { 'name': labels.marketCapAllocation.largeCap }, { "name": labels.marketCapAllocation.others }]}
            titleAmountAge={[{ 'name': labels.Tableheader.ScripName, 'age': '' }, { 'name': labels.assetAllocation.amountAge, "age": '' },
            { 'name': labels.assetAllocation.Age, "age": '' },]}
            isMarketCapLoading={this.props.isMarketCapLoading} sessionID={this.props.sessionID} clientID={this.props.clientID}
            userID={this.props.userID} date={this.props.date} logindetails={this.props.logindetails}
            marketcapallocation={this.props.marketcapallocation} totalmarketcapallocation={this.props.totalmarketcapallocation} L5ID={this.props.L5ID} />
        </TabPanel>
        <TabPanel value={this.state.value} index={9}>
          <Portifolio
            flag='equity'
            grid={classes.stockSixSplitContainer}
            externalClasses={{
              portfolioXrayCatrgorySevenSplitContainer: classes.equityTransferinPortfolioXrayCategory,
              portfolioXraySevenSplitContainer: classes.equityTransferinPortfolioXray
            }}

            hideCategory={false}
            showfilter={false}
            showdownload={false}
            showsortby={false}
            showtoggle={false}
            Total={labels.ProfitLoss.Total}
            // MarketValue={labels.Tableheader.MarketValue}
            //  CurrentGain={labels.Tableheader.CurrentGain}
            //  amountLabel={labels.Tableheader.price}
            units={labels.Tableheader.Quantity}
            //  Subheadingunits={labels.Tableheader.Subheadingprice}
            InvestedValue={labels.Tableheader.InvestedValue}
            AsOn={labels.Tableheader.AsOn}
            totaltext={labels.Tableheader.totaltext}
            //   Sip={labels.Tableheader.Sip}
            xray={this.state.transferIn}
            header={"Transaction"}
            filterSubmit={this.props.equityFilterChange}
            xraysort={this.state.transferIn}
            xrayfilter={this.state.transferIn}
            List={this.state.transferIn}
            groupXray={this.state.transferIn}
            isLoadinggroupXray={this.state.transferInLoader}
            // unrealised={this.props.unrealised}
            // margetvalue={this.props.margetvalue}
            // investedvalue={this.props.investedvalue}
            // unrealisedpercentage={this.props.unrealisedpercentage}
            // catchmessage={this.props.equity_transferin_catchmessage}
            // grandtotalnetpl={this.props.grandtotalnetpl}
            // grandtotaltotaldividend={this.props.grandtotaltotaldividend}
            // grandtotalinvestmentvalue={this.props.grandtotalinvestmentvalue}
            // grandtotalmarketvalue={this.props.grandtotalmarketvalue}
            // grandtotalunrealised={this.props.grandtotalunrealised}
            logindetails={this.props.logindetails}
            // isXrayLoading={this.props.isXrayLoading}
            // Load={this.props.Load}
            hideGrandTotalUnitsColumn={true}
            // TransactionLoad={this.props.TransactionLoad}
            xcelLoad={this.props.xcelLoad}
            TransactionxcelLoad={this.props.TransactionxcelLoad}
            dropdowndetails={this.props.dropdowndetails}
            title={transferIntitle}
            schemeList={this.state.transferIn}
          /></TabPanel>
        {/* <TabPanel value={this.state.value} index={12}>
          <RunningSIP header={"Scrip"} flag="equity" sessionID={this.props.sessionID}
            clientID={this.props.clientID}
            userID={this.props.userID}
            logindetails={this.props.logindetails}
            L5ID={this.props.L5ID}
            date={this.props.date}
            runningSIP={this.props.runningSIP}
            totalrunningSIP={this.props.totalrunningSIP}
            isRunningSipLoading={this.props.isRunningSipLoading}
            title={runningSIPtitle}
          />
        </TabPanel> */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  // //
  return {

    //COMMON STATE
    sessionID: state.Login.sessionID,
    clientID: state.Login.clientID,
    userID: state.Login.userID,
    date: state.Login.date,
    logindetails: state.Login.logindetails,
    L5ID: state.Login.L5ID,
    //SIPTRACKER
    siptracker: state.SipTracker.equity_siptracker,
    groupsiptracker: state.SipTracker.equity_groupsiptracker,
    totalsiptracker: state.SipTracker.equity_totalsiptracker,
    isSipTrackerLoading: state.SipTracker.equity_isSipTrackerLoading,
    //ACCOUNT STATEMENT
    foliono: replaceNull(state.Dropdown.dropdowndetails.folioNo, "NA") == "NA" ? [{ "label": "No Data Found", "value": 1, "isredeemed": 0 }] : state.Dropdown.dropdowndetails.folioNo,
    // accountstatement: state.Account.equity_accountstatement != null ? state.Account.equity_accountstatement : [],
    // showwarning: state.Account.equity_showwarning,
    // totalaccountstatement: state.Account.equity_totalaccountstatement,
    withredeemed: replaceNull(state.Dropdown.dropdowndetails.folioNo, "NA") == "NA" ? [] : [{ "label": "All Folio", "value": "all folio" }, ...state.Dropdown.dropdowndetails.folioNo.map((item) => {
      return { "label": item.Description, "value": item.FolioNo }
    })],
    withoutredeemed: replaceNull(state.Dropdown.dropdowndetails.folioNo, "NA") == "NA" ? [] : [{ "label": "All Folio", "value": "all folio" }, ...state.Dropdown.dropdowndetails.folioNo.filter((value) => value.IsRedeemed === 0).map((item) => {
      return { "label": item.Description, "value": item.FolioNo }
    })],

    // RunningSIP
    date: state.Login.date,
    runningSIP: state.RunningSIP.runningSIP,
    totalrunningSIP: state.RunningSIP.totalrunningSIP,
    isRunningSipLoading: state.RunningSIP.isRunningSipLoading,

    //MARKET CAP ALLOCATION
    marketcapallocation: state.MarketCap.equity_marketcapallocation == null ? [] : state.MarketCap.equity_marketcapallocation,
    totalmarketcapallocation: state.MarketCap.equity_totalmarketcapallocation,
    isMarketCapLoading: state.MarketCap.equity_isMarketCapLoading,


    //TAXATION REPORT
    capitalGain: state.capitalgain.equity_capitalgain,
    isCapitalgainLoading: state.capitalgain.equity_isCapitalgainLoading,

    //TRANSACTION
    transactionList: state.transactionList.equity_transactionList,
    dropdowntransaction: state.transactionList.equity_dropdowntransaction,
    isLoadingtransactionList: state.transactionList.equity_isLoadingtransactionList,
    // sessionID: state.Login.sessionID,
    // clientID: state.Login.logindetails.ClientID,
    // userID: state.Login.userID,
    // date: state.Login.date,
    catchmessage: state.transactionList.equity_catchmessage,
    TransactionLoad: state.transactionList.equity_isLoadingPdf,
    TransactionxcelLoad: state.transactionList.equity_isLoadingExcel,
    minDate: state.transactionList.equity_minDate,
    maxDate: state.transactionList.equity_maxDate,

    //ASSET ALLOCATION
    assetallocationgrouped: state.Asset.equity_assetallocationgrouped,
    totalassetallocation: state.Asset.equity_totalassetallocation,
    assetallocation: state.Asset.equity_assetallocation,
    isAssetAllocationLoading: state.Asset.equity_isAssetAllocationLoading,
    isNetworkLow: state.Asset.equity_isNetworkLow,

    //PORTFOLIO REPORT
    portfolioreport: state.Portfolioreport.equity_portfolioreport != undefined && state.Portfolioreport.equity_portfolioreport != null ? state.Portfolioreport.equity_portfolioreport : [],
    totalreport: state.Portfolioreport.equity_totalreport,
    portfoliocount: state.Portfolioreport.equity_portfoliocount,
    isPortfolioReportLoading: state.Portfolioreport.equity_isPortfolioReportLoading,
    //SUMMARY
    barChartData: state.Summary.equity_summary != undefined ? state.Summary.equity_summary : [],
    chart: state.Summary.equity_chart != undefined ? state.Summary.equity_chart : [],
    categorySummary: state.Summary.equity_categorySummary != undefined ? state.Summary.equity_categorySummary : [],
    isLoadingcategorySummary: state.Summary.equity_isLoadingcategorySummary != undefined ? state.Summary.equity_isLoadingcategorySummary : false,
    DonutData: state.Summary.equity_Donut != undefined ? state.Summary.equity_Donut : [],
    grandtotalsummary: state.Summary.equity_grandtotalIV != undefined ? state.Summary.equity_grandtotalIV : [],
    Deptdata: state.Summary.equity_getdeptdata,
    isLoadingportfolioChart: state.Sample.isLoadingportfolioChart,
    isLoadingportfolioSummary: state.Sample.isLoadingportfolioSummary,
    isLoadingMarketCapEquity: state.Summary.isLoadingMarketCapEquity,
    isLoadingMarketCapDebt: state.Summary.isLoadingMarketCapDebt,


    // PROFITANDLOSS
    ProfitLoss: state.ProfitLoss.equity_profitLoss,
    profitLossfilter: state.ProfitLoss.equity_profitLossfilter,
    isLoadingProfitLoss: state.ProfitLoss.equity_isLoadingProfitLoss,
    PurchaseValue: state.ProfitLoss.equity_grandpurchasetotal,
    DividendValue: state.ProfitLoss.equity_granddivdend,
    SellValue: state.ProfitLoss.equity_grandselltotal,
    glAmount: state.ProfitLoss.equity_grandglamount,
    GainPercentage: state.ProfitLoss.equity_GainPercentage,
    isProfitLossLoading: state.ProfitLoss.equity_isProfitLossLoading,

    //AFR REPORT
    shortgroupeddata: state.AFRdata.equity_shortgroupeddata !== undefined ? state.AFRdata.equity_shortgroupeddata : [],
    exitload: state.AFRdata.equity_exitload !== undefined ? state.AFRdata.equity_exitload : [],
    elssgroupdata: state.AFRdata.equity_elssgroupdata !== undefined ? state.AFRdata.equity_elssgroupdata : [],
    totalshortterm: state.AFRdata.equity_totalshortterm !== undefined ? state.AFRdata.equity_totalshortterm : [],
    totalexitload: state.AFRdata.equity_totalexitload !== undefined ? state.AFRdata.equity_totalexitload : [],
    totalelssdata: state.AFRdata.equity_totalelssdata !== undefined ? state.AFRdata.equity_totalelssdata : [],
    shortterm: state.AFRdata.equity_shortterm !== undefined ? state.AFRdata.equity_shortterm : [],
    Elssdata: state.AFRdata.equity_Elssdata !== undefined ? state.AFRdata.equity_Elssdata : [],
    isShorttermLoading: state.AFRdata.equity_isShorttermLoading,
    isExitloadLoading: state.AFRdata.equity_isExitloadLoading,
    isElssLoading: state.AFRdata.equity_isElssLoading,

    //X-RAY WITH REDEEM
    xraywithredemption: state.Redemption.equity_xraywithredemption,
    xraywithredemptiongrouped: state.Redemption.equity_xraywithredemptiongrouped,
    totalxraywithredemption: state.Redemption.equity_totalxraywithredemption,
    isXrayWithRedemptionLoading: state.Redemption.equity_isXrayWithRedemptionLoading,


    //PORTFOLIO-X-RAY
    xray: state.PortfolioXray.equity_xray,

    isLoadinggroupXray: state.PortfolioXray.equity_isXrayLoading,
    List: state.PortfolioXray.equity_XrayData.length == 0 ? [] : state.PortfolioXray.equity_XrayData.filter(
      (ele, ind) =>
        ind ===
        state.PortfolioXray.equity_XrayData.findIndex((elem) => elem.Scheme === ele.Scheme)
    ),
    unrealised: state.Card.mfHeader2.filter((val) => val.firstName === "Unrealised G / L (%)").map((val) => {
      return val.lastName
    }),
    margetvalue: state.Card.mfHeader2.filter((val) => val.firstName === "MF Market Value").map((val) => {
      return val.lastName
    }),
    investedvalue: state.Card.mfHeader2.filter((val) => val.firstName === "Investment Value").map((val) => {
      return val.lastName
    }),
    unrealisedpercentage: state.Card.mfHeader2.filter((val) => val.firstName === "Unrealised G / L (%)").map((item) => {
      return item.val
    }),
    dropdowndetails: state.Dropdown.dropdowndetails.scheme,
    // xray: state.PortfolioXray.xraysort,
    schemeList: state.PortfolioXray.equity_XrayData.sort(function (a, b) {
      var textA = a.Scheme.toUpperCase();
      var textB = b.Scheme.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }),
    financialYearsList: replaceNull(state.Dropdown.dropdowndetails.financialYearsList, "").length != 0 ? state.Dropdown.dropdowndetails.financialYearsList : [],


  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      equity_transactionfilter,
      equityFilterChange,
      equityprofitandlossfilter,
      getProfitLossDetails
    },
    dispatch
  );
};
const mergestyles = combineStyle(Styles, commonstyletable);
export default withStyles(mergestyles)(connect(mapStateToProps, mapDispatchToProps)(EquityFundTab))