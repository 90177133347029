import * as types from '../../constants/actionsType';
import GetPortfolioReportApi from '../../../api/portfolioreport/portfolioreportapi'
import constants from '../../../utils/constants/apiconstant';
import { replaceNull } from '../../../utils/commonfunction';
export function GetPortfolioReport(ClientID, AccountID, AsOnDate, WithRedemption, ProductID, FamilyID, L5ID, L4ID, flag) {
  return (dispatch) => {
    GetPortfolioReportAction(dispatch, ClientID, AccountID, AsOnDate, WithRedemption, ProductID, FamilyID, L5ID, L4ID, flag);
  }
}
function GetPortfolioReportAction(dispatch, ClientID, AccountID, AsOnDate, WithRedemption, ProductID, FamilyID, L5ID, L4ID, flag) {
  //mf report
  if (ProductID == 1) {
    dispatch({
      type: types.EMPTY_PORTFOLIO_REPORT,
      payload: [],
    });
    dispatch({
      type: types.LOADER_PORTFOLIO_REPORT,
      payload: true,
    });
  }
  //equity report  loading
  else if (ProductID == 2) {
    dispatch({
      type: types.EMPTY_EQUITY_PORTFOLIO_REPORT,
      payload: [],
    });
    dispatch({
      type: types.LOADER_EQUITY_PORTFOLIO_REPORT,
      payload: true,
    });
  }
  //bonds reports loading
  else if (ProductID == 3) {
    dispatch({
      type: types.EMPTY_BONDS_PORTFOLIO_REPORT,
      payload: [],
    });
    dispatch({
      type: types.LOADER_BONDS_PORTFOLIO_REPORT,
      payload: true,
    });
  }
  //gold bonds reports loading
  else if (ProductID == 4) {
    dispatch({
      type: types.EMPTY_GB_PORTFOLIO_REPORT,
      payload: [],
    });
    dispatch({
      type: types.LOADER_GB_PORTFOLIO_REPORT,
      payload: true,
    });
  }
  else {
    dispatch({
      type: types.EMPTY_FD_PORTFOLIO_REPORT,
      payload: [],
    });
    dispatch({
      type: types.LOADER_FD_PORTFOLIO_REPORT,
      payload: true,
    });
  }
  GetPortfolioReportApi(ClientID, AccountID, AsOnDate, WithRedemption, ProductID, FamilyID, L5ID, L4ID)
    .then((response) => {
      let data = replaceNull(response, "NA")
      try {
        if (data.status == "S") {
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: ProductID, api: "PortfolioReport" }],
          });
          // mf report
          if (ProductID == 1) {
            dispatch({
              type: types.PORTFOLIO_REPORT,
              payload: data,
            });

          }
          // equity report
          else if (ProductID === 2) {
            dispatch({
              type: types.EQUITY_PORTFOLIO_REPORT,
              payload: data,
            });

          }
          //bonds report
          else if (ProductID === 3) {
            dispatch({
              type: types.BONDS_PORTFOLIO_REPORT,
              payload: data,
            });

          }
          //gold bonds report
          else if (ProductID === 4) {
            dispatch({
              type: types.GB_PORTFOLIO_REPORT,
              payload: data,
            });

          }
          else {
            dispatch({
              type: types.FD_PORTFOLIO_REPORT,
              payload: data,
            });

          }
        }
        else {
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: ProductID, api: "PortfolioReport" }],
          });
          // mf report prevent api call
          if (ProductID === 1) {
            dispatch({
              type: types.PORTFOLIO_REPORT,
              payload: [],
            });

          }
          // equity report prevent api call
          else if (ProductID === 2) {
            dispatch({
              type: types.EQUITY_PORTFOLIO_REPORT,
              payload: [],
            });

          }
          // bonds report prevent api call
          else if (ProductID === 3) {
            dispatch({
              type: types.BONDS_PORTFOLIO_REPORT,
              payload: data,
            });

          }
          // gold bonds report prevent api call
          else if (ProductID === 4) {
            dispatch({
              type: types.GB_PORTFOLIO_REPORT,
              payload: data,
            });

          }
          // fixed deposite report prevent api call
          else {
            dispatch({
              type: types.FD_PORTFOLIO_REPORT,
              payload: data,
            });

          }

        }
      } catch (err) {
        // console.log(err)
      }

    })
    .catch((error) => {
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: ProductID, api: "PortfolioReport" }],
      });
      //empty portfolio report
      if (ProductID == 1) {
        dispatch({
          type: types.EMPTY_PORTFOLIO_REPORT,
          payload: [],
        });

      }
      //empty equity report
      else if (ProductID == 2) {
        dispatch({
          type: types.EMPTY_EQUITY_PORTFOLIO_REPORT,
          payload: [],
        });

      }
      //bonds empty report
      else if (ProductID == 3) {
        dispatch({
          type: types.EMPTY_BONDS_PORTFOLIO_REPORT,
          payload: [],
        });

      }
      //gold bonds  empty report
      else if (ProductID == 4) {
        dispatch({
          type: types.EMPTY_GB_PORTFOLIO_REPORT,
          payload: [],
        });

      }
      //fixed deposits empty report
      else {
        dispatch({
          type: types.EMPTY_FD_PORTFOLIO_REPORT,
          payload: [],
        });

      }

    })
}
