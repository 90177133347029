import * as types from '../../constants/actionsType'
const initialState = {
    eSignDownloadOrder: [],
    iseSignDownloadOrderLoading: false,
    isSigned: false,
    url:""
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ESIGN_DOWNLOAD:         
            return {
                ...state,
                eSignDownloadOrder: action.payload != null ? action.payload : state.eSignDownloadOrder,
                iseSignDownloadOrderLoading: false
            }
        case types.LOADER_ESIGN_DOWNLOAD:
            return {
                ...state,
                iseSignDownloadOrderLoading: true
            }
        case types.IS_SIGNED:
            return {
                ...state,
                isSigned:action.status,
                url:action.url
            }
        default:
            return state
    }
}
export default reducer