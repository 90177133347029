import * as types from '../../constants/actionsType'
import { bindActionCreators } from 'redux';
const initialState = {
    transactionOrder: [],
    isTransactionOrderLoading:false
}
const reducer = (state = initialState, action) => {
    switch (action.type) {        
        case types.TRANSACTION_ORDER_REPORT:
            return {
                ...state,
                transactionOrder: action.payload != null ? action.payload.data : state.transactionOrder,
                isTransactionOrderLoading:false
            }
        case types.LOADER_TRANSACTION_ORDER_REPORT:
            return {
                ...state,
                isTransactionOrderLoading:true
            }
            default:
                return state
    }
}
export default reducer
