import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Label from '../../utils/constants/labels'
import ScrollToTop from '../scrolltotop/scrolltotop.js';
import CommonMenu from '../menu/menu';
// import Footer from '../footer/footer'
import { connect } from 'react-redux';
import '../../utils/stylesheets/sidemenu/sidemenu.css';
import ErrorBountry from '../Errorhandling/Errorhandle';
import MyFamily from '../../components/profile/myfamily'
import MyAccount from '../../components/profile/my_account';
import Risk from '../../components/profile/risk_profile';
import MyPlan from '../../components/profile/myplan';
import MainScreenProfile from '../profile/mainscreen_profile';
import Notificationblock from '../../components/notificationblock/notificationblock';
import encryptKYC from '../../api/profile/encryptionkyc';
import { Redirect } from 'react-router';
import labels from '../../utils/constants/labels';
import AccountNormal from '../../utils/assets/account-blue.png'
import AccountHover from '../../utils/assets/account-brown.png'
import AccountClick from '../../utils/assets/account-white.png'
import RiskNormal from '../../utils/assets/Risk-blue.png'
import RiskHover from '../../utils/assets/risk-brown.png'
import RiskClick from '../../utils/assets/risk-white.png'
import ProfileNormal from '../../utils/assets/profile-blue.png'
import ProfileHover from '../../utils/assets/profile-brown.png'
import ProfileClick from '../../utils/assets/profile-white.png'
import FamilyNormal from '../../utils/assets/family-blue.png'
import FamilyHover from '../../utils/assets/family-brown.png'
import FamilyClick from '../../utils/assets/family-white.png'
import PlanNormal from '../../utils/assets/plan-blue.png'
import PlanHover from '../../utils/assets/plan-brown.png'
import PlanClick from '../../utils/assets/plan-white.png'
import MyFinancialPlan from '../../components/profile/my_financialplan';
import { Route, Link, Switch, Router } from 'react-router-dom';
// import RiaPlanComplete from '../riaplancomplete/riaplancomplete';
//import PurchasedPlan from '../purchasedplan/purchasedplan';
import RiaPlanComplete from '../advisoryaccount/riaplancomplete';
import PurchasedPlan from '../advisoryaccount/purchasedplan';
const styles = theme => ({
    root: {
        height: '100%',
        width: '100%',
        // marginTop:'5%',
    },
    navLeftFix: {
        position: "fixed",
        top: "225px",
        // zIndex: 22,
        marginTop: -50,
        boxShadow: "0 3px 20px 0 rgb(19 89 161 / 10%)",
        borderTopRightRadius: 9,
        //     [theme.breakpoints.down("00")]: {
        //         top: "34%",
        //     }
        // ,
        [theme.breakpoints.down("800")]: {
            borderTopRightRadius: 9,
            borderTopLeftRadius: 9,
        }
        ,
        [theme.breakpoints.between("600", "800")]: {
            // top: "70%",
            top: "auto",
            bottom: 59,
            left: "47%",
            margin: "0 0 0 -248px",
            boxShadow: "none",
            zIndex: 1,
        },
        [theme.breakpoints.down("600")]: {
            top: "auto",
            bottom: 59,
            left: "52%",
            margin: "0 0 0 -38vw",
            boxShadow: "none"
        },
    },

    //Vaikundamani
    navLeftFixSecond: {
        position: "fixed",
        top: "225px",
        // zIndex: 22,
        marginTop: -50,
        // boxShadow: "0 3px 20px 0 rgb(19 89 161 / 10%)",
        boxShadow: "0 3px 23px 0 rgb(19 89 161 / 10%)",
        zIndex: 1,
        borderTopRightRadius: 9,
        [theme.breakpoints.down("800")]: {
            borderTopRightRadius: 9,
            borderTopLeftRadius: 9,
        },
        // ,
        // [theme.breakpoints.down("800")]: {
        //    // top: "80%",
        //     bottom: 0,
        //     left: "60%",
        //     margin: "0 0 0 -248px",
        //     boxShadow: "none"
        // },
        [theme.breakpoints.down("600")]: {
            top: "auto",
            bottom: 59,
            left: "66%",
            margin: "0 0 0 -38vw",
            boxShadow: 'none'
        },
        [theme.breakpoints.between("600", "800")]: {
            top: "auto",
            bottom: 59,
            left: "66%",
            margin: "0 0 0 -38vw",
            boxShadow: 'none',
            zIndex: 1,
        }
    },

    boxStyle: {
        width: '86px',
        height: '52px',
        display: "block",
        textAlign: "center",
        backgroundColor: "#fff",
        color: "#31455e",
        borderBottom: "solid 1px #e4e5e8",
        fontSize: "11px",
        lineHeight: 1.36,
        paddingTop: "10px",
        paddingBottom: "10px",
        cursor: "pointer",
        '&:hover': {
            backgroundColor: window.globalConfig.color.RosyBrown,
            color:window.globalConfig.color.BROWN
        },
        [theme.breakpoints.down("800")]: {//Vaikundamani - theme.breakpoints.down("900")
            top: "59px",
            width: '14vw',
            display: "block",
            textAlign: "center",
            backgroundColor: "#fff",
            color: "#31455e",
            borderBottom: "solid 1px #e4e5e8",
            fontSize: "11px",
            lineHeight: 1.36,
            paddingTop: "9px",
            cursor: "pointer",
            paddingBottom: '5px',
        },
        //Vaikundamani
        [theme.breakpoints.between("800", "900")]: {
            //  top: "59px",
            width: '86px',
            display: "block",
            textAlign: "center",
            backgroundColor: "#fff",
            color: "#31455e",
            borderBottom: "solid 1px #e4e5e8",
            fontSize: "11px",
            lineHeight: 1.36,
            paddingTop: "9px",
            cursor: "pointer",
            paddingBottom: '5px',
        },
        [theme.breakpoints.down(600)]: {
            lineHeight: 1,
            width: "16vw",
            height: "60px"
        }
    },
    //Vaikundamani End
    portfolioContainer: {
        '&:hover': {
            color: window.globalConfig.color.BROWN
        }
    },
    navDropBox: {
        //  display: 'none',
        width: 152,
        boxShadow: "7px 7px 20px 0 rgb(0 0 0 / 20%)",
        position: "absolute",
        top: '0px',
        left: '80px',
        cursor: "pointer",
        [theme.breakpoints.down("800")]: {
            top: "auto",
            bottom: '15vh',
            left: '-57px',
            width: 202,
            // marginLeft:"-67px"
            zIndex: 9,
            cursor: "default"
        },
        [theme.breakpoints.down("600")]: {
            top: "auto",
            bottom: 82,
            left: '-6vw',
            width: 202,
            // marginLeft:"-67px"
            zIndex: 9,
            cursor: "default"
        }
    },
    navDropBoxtwo: {
        //  display: 'none',
        width: 202,
        boxShadow: "7px 7px 20px 0 rgb(0 0 0 / 20%)",
        position: "absolute",
        top: '0px',
        left: '242px',
        maxHeight: 350,
        overflowY: "scroll",
        overflowX: 'none',
        cursor: "pointer",
        [theme.breakpoints.down("800")]: {
            top: "auto",
            bottom: '31vh',
            left: '-54px',
            maxHeight: 293,
            // marginLeft:"-67px"
            zIndex: 9,
            cursor: "default"
        },
        [theme.breakpoints.down("600")]: {
            top: "auto",
            bottom: '31vh',
            left: '-5vw',
            maxHeight: 250,
            zIndex: 9,
            cursor: "default"
        }
    },
    navDropBoxList: {
        width: "87%",
        height: 40,
        lineHeight: 3,
        borderBottom: "solid 1px #e4e5e8",
        padding: 0,
        textAlign: "left",
        paddingLeft: 30,
        fontSize: 14,
        backgroundColor: "#fff",
        color: "#31455e",
        '&:hover': {
            backgroundColor:  window.globalConfig.color.RosyBrown,
            color: window.globalConfig.color.BROWN
        }
    },
    selectedList: {
        backgroundColor: window.globalConfig.color.BROWN,
        color: "#fff",
        '&:hover': {
            backgroundColor:  window.globalConfig.color.BROWN,
            color: "#fff",
        }
    },
    listStyle: {
        position: "relative",
        [theme.breakpoints.down("800")]: {
            float: "left",
            borderRight: "solid 1px #f5f5f5"
        }
    },
    topRadius: {
        borderTopRightRadius: 9,
        [theme.breakpoints.down("800")]: {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 9,
        }
    },
    bottomRadius: {
        borderBottomRightRadius: 9,
        [theme.breakpoints.down("800")]: {
            borderBottomRightRadius: 0,
            borderTopRightRadius: 9,
        }
    },
    Profile: {
        "& span": {
            width: 52,
            height: 33,
            background: `url(${ProfileClick}) no-repeat center top`,
        },
    },
    myfamily: {
        "& span": {
            width: 52,
            height: 33,
            background: `url(${FamilyClick}) no-repeat center top`,
        },
    },
    myAccount: {
        "& span": {
            width: 52,
            height: 33,
            background: `url(${AccountClick}) no-repeat center top`,
        },
    },
    risk: {
        "& span": {
            width: 52,
            height: 33,
            background: `url(${RiskClick}) no-repeat center top`,
        },
    },
    myplan: {
        "& span": {
            width: 52,
            height: 33,
            background: `url(${PlanClick}) no-repeat center top`,
        },
    },
    myFinancialPlans: {
        "& span": {
            width: 52,
            height: 33,
            background: `url(${PlanClick}) no-repeat center top`,
        },
    },
    hoverprofile: {
        "& span": {
            width: 52,
            height: 33,
            background: `url(${ProfileNormal}) no-repeat center top`,
        },
        '&:hover': {
            "& span": {
                width: 52,
                height: 33,
                background: `url(${ProfileHover}) no-repeat center top`,
            },
        }
    },
    hoverfamily: {
        "& span": {
            width: 52,
            height: 33,
            background: `url(${FamilyNormal}) no-repeat center top`,
        },
        '&:hover': {
            "& span": {
                width: 52,
                height: 33,
                background: `url(${FamilyHover}) no-repeat center top`,
            },
        }
    },
    hoveraccount: {
        "& span": {
            width: 52,
            height: 33,
            background: `url(${AccountNormal}) no-repeat center top`,
        },
        '&:hover': {
            "& span": {
                width: 52,
                height: 33,
                background: `url(${AccountHover}) no-repeat center top`,
            },
        }
    },
    hoverrisk: {
        "& span": {
            width: 52,
            height: 33,
            background: `url(${RiskNormal}) no-repeat center top`,
        },
        '&:hover': {
            "& span": {
                width: 52,
                height: 33,
                background: `url(${RiskHover}) no-repeat center top`,
            },
        }
    },
    hoverplans: {
        "& span": {
            width: 52,
            height: 33,
            background: `url(${PlanNormal}) no-repeat center top`,
        },
        '&:hover': {
            "& span": {
                width: 52,
                height: 33,
                background: `url(${PlanHover}) no-repeat center top`,
            },
        }
    },
    hoverfinance: {
        "& span": {
            width: 52,
            height: 33,
            // marginBottom: "8px",

            background: `url(${PlanNormal}) no-repeat center top`,
        },
        '&:hover': {
            "& span": {
                width: 52,
                height: 33,
                // marginBottom: "8px",

                background: `url(${PlanHover}) no-repeat center top`,
            },
        }
    },
    container: {
        display: "grid",
        gridTemplateColumns: "10% 80% 10%"
    },
    marginLeft: {
        marginLeft: "10vw",
        // marginTop:-10,
        [theme.breakpoints.down("600")]: {
            marginLeft: 0
        },
        [theme.breakpoints.between("1250", "1300")]: {
            marginLeft: "11vw"
        },
        [theme.breakpoints.between("1000", "1050")]: {
            marginLeft: "13vw",
            marginRight: "3vw"
        },
        [theme.breakpoints.between("1050", "1250")]: {
            marginLeft: "14vw",
            marginRight: "4vw"
        },
    },
    topAlignmentDeskTop: {
        marginTop: "-10px",

    },
    topAlignmentMobile: {
        marginTop: "134px",
        [theme.breakpoints.down('xs')]: {
            marginTop: "60px",
        },

    },
    firstdiv: {
        [theme.breakpoints.up('xs')]: {
            width: "11.5"
        }
    },
    seconddiv: {
        [theme.breakpoints.up('xs')]: {
            width: "100%"
        }
    },
    thirddiv: {
        [theme.breakpoints.up('xs')]: {
            width: "8%"
        }
    },
    emptyDivContainer: {
        width: "11.5%",
        [theme.breakpoints.up('md')]: {
            width: "12.5%",
        },
    },
    width: {
        width: "100%",
        [theme.breakpoints.between("1000", "1200")]: {
            width: "82%",
        },
    }
})
class ProfileSideMenu extends React.Component {
    constructor() {
        super();
        this.state = {
            index: 0,
            loader: false,
            open: false,
            message: ""
        }
    }
    handleClick = (value) => {
        this.setState({ index: value })
        if (value == 0) {
            this.props.history.push(labels.Path.UserDetails)
        }
        if (value == 1) {
            this.props.history.push(labels.Path.MyFamily)
        }
        if (value == 2) {
            this.props.history.push(labels.Path.RiskPlan)
        }
        if (value == 3) {
            this.props.history.push(labels.Path.Account)
        }
        if (value == 4) {
            //if Plan Subscribed redirect Purchased Plan Page
            if (this.props.purchasedRiaPlanDetails != null && this.props.purchasedRiaPlanDetails != undefined && this.props.purchasedRiaPlanDetails.length != 0) {

                //   
                this.props.history.push({
                    pathname: labels.Path.PurchasedPlan,
                    state: { data: 4 }
                }
                )

            }

            else {
                this.props.history.push(labels.Path.MyPlan)
            }
        }
        if (value == 5) {
            this.props.history.push(labels.Path.MyFinancialPlan)
        }

    }
    componentDidMount() {
        //  
        if (this.props.data != undefined) {
            if (this.props.data.state != undefined) {

                this.setState({ index: this.props.data.state.data })
            }
            else {
                this.setState({ index: 0 })
            }
            // if (this.props.data.state != undefined) {

            // }
        } else {
            this.setState({ index: 0 })
        }
    }
    componentDidUpdate(prevProps) {
        const { data, location } = this.props;
        const { index } = this.state
        if (prevProps.data != data) {
            if (data.state != undefined) {
                ///  
                this.setState({ index: data.state.data })
            }
        }
        let a = ""
        if (location.pathname == "/profile/myplan") {
            a = 4
        }
        if (location.pathname == "/profile/myfinancialplan") {
            a = 5
        }
        if (location.pathname == "/profile/myaccount") {
            a = 3
        }
        if (location.pathname == "/profile/riskplan") {
            a = 2
        }
        if (location.pathname == "/profile/myfamily") {
            a = 1
        }
        if (location.pathname == "/profile/userdetails") {
            a = 0
        }
        if (location.pathname == "/profile/planpurchased") {
            a = 4
        }
        // var pathname = this.props.location.pathname
        //  
        if (index != a) {

            this.setState({ index: a })
        }
    }
    createNow = () => {
        this.setState({ loader: true })
        encryptKYC(this.props.logindetails.EmailID, this.props.logindetails.PhoneNumber).then((res) => {
            this.setState({ loader: false, })
            if (res.status === "F") {
                this.setState({ open: true, message: labels.messages.apierror })
            }

        })
        //      var a= KYCRedirection(this.props.logindetails.EmailID,this.props.logindetails.PhoneNumber)
        //    
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };
    render() {
        // 
        const { classes } = this.props;
        return (
            <div className={classes.root} tabIndex="0" location={this.props.location}>
                <ScrollToTop />
                <div>
                    {/* <CommonMenu history={this.props.history} /> */}
                    <Notificationblock open={this.state.open} message={this.state.message} severity={"warning"} handlesnanckClose={this.handlesnanckClose} page={'notifyBlock'} onClick={this.createNow} text={Label.riskProfile.Warning} buttonName={Label.riskProfile.getStart} loader={this.state.loader} />
                    <ErrorBountry>
                        <div className={this.props.logindetails.L4_AccountTypeID === 2 ? classes.navLeftFix : classes.navLeftFixSecond}>
                            <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>

                                {/* My Account SideMenu */}
                                <li style={{ position: "relative" }} className={[classes.boxStyle, classes.portfolioContainer, classes.topRadius, classes.listStyle, this.state.index === 3 && classes.selectedList, this.state.index == 3 ? classes.myAccount : classes.hoveraccount].join(' ')} onClick={() => {
                                    this.handleClick(3)
                                }} >
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                                    >
                                        <span style={{ marginBottom: 3 }}></span>
                                        <div>{Label.ProfileSideMenu.myaccount}</div>
                                    </div>
                                </li>

                                {/* My Family Side Menu */}
                                <li
                                    className={[classes.boxStyle, classes.portfolioContainer, classes.listStyle, this.state.index == 1 ? classes.selectedList : '', this.state.index == 1 ? classes.myfamily : classes.hoverfamily].join(' ')}
                                    onClick={() => {
                                        this.handleClick(1)
                                    }
                                    } >
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                                    >
                                        <span style={{ marginBottom: 3 }}></span>
                                        <div>{Label.ProfileSideMenu.myfamily}</div>
                                    </div>
                                </li>

                                {/* My KYC Side Menu */}
                                <li className={[classes.boxStyle, classes.portfolioContainer, classes.listStyle, this.props.logindetails.L4_AccountTypeID !== 2 && classes.bottomRadius, this.state.index == 0 && classes.selectedList, this.state.index == 0 ? classes.Profile : classes.hoverprofile].join(' ')} onClick={() => {
                                    this.handleClick(0)
                                }
                                } >
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                                    >
                                        <span style={{ marginBottom: 3 }}></span>
                                        <div>{Label.ProfileSideMenu.profile}</div>
                                    </div>
                                </li>

                                {this.props.logindetails.L4_AccountTypeID === 2 &&
                                    // <>
                                    //     {typeof (this.props.logindetails.KYCFormNo) != "object" && this.props.logindetails.KYCFormNo != "" && this.props.logindetails.KYCFormNo != 0 &&
                                    <li style={{ position: "relative" }} className={[classes.boxStyle, classes.portfolioContainer, classes.listStyle, this.state.index === 2 && classes.selectedList, this.state.index == 2 ? classes.risk : classes.hoverrisk].join(' ')} onClick={() => {
                                        this.handleClick(2)
                                    }} >
                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                                        >
                                            <span style={{ marginBottom: 3 }}></span>
                                            <div>{Label.ProfileSideMenu.riskprofile}</div>
                                        </div>
                                    </li>
                                    //         }
                                    // </>
                                }

                                {this.props.logindetails.L4_AccountTypeID === 2 &&
                                    <>
                                        {/* {typeof (this.props.logindetails.KYCFormNo) != "object" && this.props.logindetails.KYCFormNo != "" && this.props.logindetails.KYCFormNo != 0 && */}

                                        <li
                                            className={[classes.boxStyle, classes.bottomRadius, classes.portfolioContainer, classes.listStyle, this.state.index == 4 ? classes.selectedList : '', this.state.index == 4 ? classes.myplan : classes.hoverplans].join(' ')}
                                            onClick={() => {
                                                this.handleClick(4)
                                            }
                                            }
                                        >
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                                            >
                                                <span style={{ marginBottom: 3 }}></span>
                                                <div>{Label.ProfileSideMenu.myplan}</div>
                                            </div>
                                        </li>
                                        {/* } */}
                                    </>}
                                {/* <li style={{ position: "relative" }} className={[classes.boxStyle, classes.portfolioContainer, classes.listStyle, classes.bottomRadius, this.state.index === 5 && classes.selectedList, this.state.index == 5 ? classes.myFinancialPlans : classes.hoverfinance].join(' ')} onClick={() => {
                                    this.handleClick(5)
                                }} >
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                                    >
                                        <span style={{ marginBottom: 10 }}></span>
                                        <div>{Label.ProfileSideMenu.myfinancial}</div>
                                    </div>
                                </li> */}

                            </ul>
                        </div>
                        <div className={classes.container}>
                            <div ></div>
                            <div className={classes.width}>
                                <Switch>
                                    <Route path={Label.Path.UserDetails} component={MainScreenProfile} exact />
                                    {/* <Route path={Label.Path.Reports} component={Layout} /> */}
                                    <Route path={Label.Path.MyFamily} component={MyFamily} />
                                    <Route path={Label.Path.Account} component={MyAccount} />
                                    {this.props.logindetails.L4_AccountTypeID === 2 && <Route path={Label.Path.RiskPlan} component={Risk} />
                                    } {/* <Route path={Label.Path.Profile} component={Profile} /> */}
                                    {this.props.logindetails.L4_AccountTypeID === 2 && <Route path={Label.Path.MyPlan} component={MyPlan} />
                                    } <Route path={Label.Path.MyFinancialPlan} component={MyFinancialPlan} />
                                    {this.props.logindetails.L4_AccountTypeID === 2 && <Route path={Label.Path.RiaPlanComplete} component={RiaPlanComplete}></Route>
                                    }   {this.props.logindetails.L4_AccountTypeID === 2 && <Route path={Label.Path.PurchasedPlan} component={PurchasedPlan}></Route>
                                    }<Redirect from='*' to={Label.Path.PageNotFound} />
                                </Switch>
                            </div>
                            <div ></div>
                        </div>
                    </ErrorBountry>
                </div>
                {/* <Footer /> */}
            </div >
        );
    }
}
const mapDispatchToProps = dispatch => ({
});
const mapStateToProps = state => ({
    sessionId: state.Login.sessionID,
    clientId: state.Login.logindetails.ClientID,
    date: state.Login.date,
    logindetails: state.Login.logindetails,
    purchasedRiaPlanDetails: state.Login.riaplandetails != null ? state.Login.riaplandetails.length != 0 ? state.Login.riaplandetails : [] : []
    ,
    // riaOnLinePaymentDetails: state.RiaOnLinePaymentDetails.riaOnLinePaymentDetailsOrder != null ? state.RiaOnLinePaymentDetails.riaOnLinePaymentDetailsOrder.length != 0 ? state.RiaOnLinePaymentDetails.riaOnLinePaymentDetailsOrder : [] : [],
    // riaOffLinePayment: state.RiaOffLinePayment.riaFlowOffLinePaymentOrder != null ? state.RiaOffLinePayment.riaFlowOffLinePaymentOrder.length != 0 ? state.RiaOffLinePayment.riaFlowOffLinePaymentOrder : [] : [],
});
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ProfileSideMenu));
