import * as types from "../../constants/actionsType";
import { bindActionCreators } from "redux";
import { replaceNull } from "../../../utils/commonfunction";
import secureStorage from "../../../utils/securestorage";
const initialState = {
  maindetails: [],
  familydetails: [],
  personalncomeDetails: [],
  bankDetails: [],
  mandateDetails: [],
  nomineeDetails: [],
  AllProfileDetails: [],
  AllEquityProfileDetails: [],
  loaderprofile: false,
  status: "",
  BranchName: [],
  myplandetails: [],
  isMyPlanDetailsLoading: false,
  purchasedPlan: [],
  isLoading: false,
  isEquityProfileDetailsLoading: false
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.PROFILE_DETAILS:
      // secureStorage
      let profileFullList = action.payload != null ? action.payload : []
      let mandateDetails = []
      let bankDetails = []
      if (action.payload != null) {
        mandateDetails = action.payload.mandateDetails != null ? action.payload.mandateDetails.map((item) => {
          return { ...item, isMandateNotApproved: item.MandateStatus.toLowerCase() == "REGISTERED BY MEMBER".toLowerCase() || item.MandateStatus.toLowerCase() == "MANDATE REGISTRATION DONE SUCCESSFULLY".toLowerCase() || item.MandateStatus.toLowerCase() == "WAITING FOR CLIENT AUTHENTICATION".toLowerCase() ? true : false, isLoading: false }
        }) : []
        bankDetails = action.payload.bankDetails != null ? action.payload.bankDetails.map((item) => {
          return { ...item, ID: item.BankAccountNumber, BankID: item.ID }
        }) : []
      }
      profileFullList.mandateDetails = mandateDetails
      profileFullList.bankDetails = bankDetails

      return {
        ...state,
        AllProfileDetails:
          action.payload != null
            ? replaceNull(profileFullList, "NA")
            : state.AllProfileDetails,
      };
    case types.LOADER_PROFILE_DETAILS:
      // secureStorage
      return {
        ...state,
        isLoading: action.payload
      };
    case types.EMPTY_PROFILE_DETAILS:
      return {
        ...state,
        maindetails: [],
        familydetails: [],
        personalncomeDetails: [],
        bankDetails: [],
        mandateDetails: [],
        nomineeDetails: [],
        AllProfileDetails: [],
        loaderprofile: false,
        status: "",
        BranchName: [],
        myplandetails: [],
        isMyPlanDetailsLoading: false,
        purchasedPlan: [],
      };

    case types.ADD_COUNTRY:
      // 
      let countrylist = state.AllProfileDetails;
      let country = {
        TaxPayingCountryID: action.data.id,
        ClientID: action.ClientID,
        Country: action.Country,
        IdentificationType: action.IdentificationType,
        TIN: action.TIN,
        ID: action.data.id,
        UCC: action.UCC,
        L5ID: action.L5ID
      };
      let a = state.AllProfileDetails.taxDetails;
      countrylist.taxDetails = a == "NA" ? [country] : [...a, country];
      return {
        ...state,

        AllProfileDetails: replaceNull(countrylist, "NA"),
      };
    case types.ADD_NOMINEE:
      let nomineelist = state.AllProfileDetails;
      let update = action.flag;
      //   
      if (update === "update") {
        const elementsIndex = state.AllProfileDetails.nominee.findIndex(
          (item) => item.NomineeID === action.NomineeID
        );
        let newArray = [...state.AllProfileDetails.nominee];
        newArray[elementsIndex] = {
          ...newArray[elementsIndex],
          NomineeID: action.NomineeID,
          NomineeGuardian: action.NomineeGuardian,
          IsNomineeMinor: action.IsNomineeMinor,
          AllocationPercentage: action.NomineePercentage,
          Relation: action.Relation,
          NomineeName: action.NomineeName,
          NomineeType: action.NomineeType,
          ClientID: action.ClientID,
          UCC: action.UCC,
          L5ID: action.L5ID
        };
        nomineelist.nominee = newArray;
        //  
        return {
          ...state,

          AllProfileDetails: replaceNull(nomineelist, "NA"),
        };
      } else {
        let nominee = {
          NomineeID: action.NomineeID,
          ClientID: action.ClientID,
          NomineeType: action.NomineeType,
          NomineeName: action.NomineeName,
          Relation: action.Relation,
          AllocationPercentage: action.NomineePercentage,
          IsNomineeMinor: action.IsNomineeMinor,
          NomineeGuardian: action.NomineeGuardian,
          UCC: action.UCC,
          L5ID: action.L5ID
          // "ID": action.data.ID
        };
        let b = state.AllProfileDetails.nominee;
        nomineelist.nominee = b == "NA" ? [nominee] : [...b, nominee];
        return {
          ...state,

          AllProfileDetails: replaceNull(nomineelist, "NA"),
        };
      }
    case types.ADD_BANK:
      // 
      let bankdetails = state.AllProfileDetails;
      let bank = {
        ClientID: action.ClientID,
        AccountType: action.AccountType,
        BranchName: action.response.data[0].BranchName,
        BankName: action.response.data[0].BankName,
        IFSCCode: action.IFSCCode,
        BranchMICRCode: action.BranchMICRCode,
        BankAccountNumber: action.BankAccountNumber,
        IsDefault: false,
        BankID: action.response.data[0].BankID,
        StartDate: "",
        EndDate: "",
        UCC: action.UCC,
        L5ID: action.L5ID
      };
      let bankList = state.AllProfileDetails.bankDetails;
      bankdetails.bankDetails = bankList == "NA" ? [bank] : [...bankList, bank];
      //  
      return {
        ...state,

        AllProfileDetails: replaceNull(bankdetails, "NA"),
      };
    case types.UPDATE_PROFILE_DETAILS:

      return {
        ...state,
        AllProfileDetails: action.data
      }
    case types.UPDATE_MANDATE:
      let mandatelist = state.AllProfileDetails;
      // 
      let mandate = {
        Amount: Number(action.payload.Amount),
        BankAccNo: action.payload.BankAccNo,
        BankBranch: action.payload.BranchName,
        BankName: action.payload.BankName,
        MandateId: action.payload.MandateID,
        MandateStatus: action.payload.Status,
        RegistrationDate: action.payload.date,
        IFSC: action.payload.IFSC,
        BankID: action.payload.BankID,
        UCC: action.payload.UCC,
        L5ID: action.payload.L5ID,
        isMandateNotApproved: action.payload.Status.toLowerCase() == "registered by member" || action.payload.Status.toLowerCase() == "MANDATE REGISTRATION DONE SUCCESSFULLY".toLowerCase() || action.payload.Status.toLowerCase() == "WAITING FOR CLIENT AUTHENTICATION".toLowerCase() ? true : false,
        isLoading: false
        // "ID": action.data.ID
      };
      let mandateobject = state.AllProfileDetails.mandateDetails;


      mandatelist.mandateDetails =
        mandateobject == null ? [mandate] : [...mandateobject, mandate];
      return {
        ...state,

        AllProfileDetails: replaceNull(mandatelist, "NA"),
      };

    case types.LOADER_PROFILE:
      return {
        ...state,
        loaderprofile: action.payload,
      };
    case types.STATUS_INFORMER_PROFILE:
      state.status = "";
      return {
        ...state,
        status: action.payload,
      };
    case types.SEARCH_BRANCH:
      return {
        ...state,
        BranchName: action.payload != null ? action.payload : [],
      };
    case types.UPDATE_PROFILE_MY_PLAN:
      return {
        ...state,
        myplandetails: action.payload,
        isMyPlanDetailsLoading: false,
      }
    case types.PROFILE_MY_PLAN:
      let fulldata = [];
      // let intervalfulldata = []
      // let myplan = action.payload
      let grpelss = action.payload
        .sort((a, b) => {
          var textA = a.product_name.toUpperCase();
          var textB = b.product_name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        })
        .filter(
          (ele, ind) =>
            ind ===
            action.payload.findIndex(
              (elem) => elem.product_name === ele.product_name
            )
        );
      fulldata = grpelss.map((item, index) => {
        return {
          ProductName: item.product_name,
          PlanCode: item.plan_code,
          isPlanSelected: index == 0 ? true : false,
          Data: action.payload.filter(
            (value) => value.product_name == item.product_name
          ),
        };
      });
      // let grouping = action.payload.sort((a, b) => {
      //     var textA = a.intervals;
      //     var textB = b.intervals;
      //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
      //   }).filter((ele, ind) => ind === action.payload.findIndex(elem => elem.intervals === ele.intervals))
      // intervalfulldata = grouping.map((item) => {
      //     return { Intervals: item.intervals, Data: action.payload.filter((value) => value.intervals == item.intervals) }

      // })
      return {
        ...state,
        myplandetails: fulldata,
        isMyPlanDetailsLoading: false,
        // intervaldetails: intervalfulldata
        // myplanfulldata:  myplan
      };

    case types.LOADER_PROFILE_MY_PLAN:
      return {
        ...state,
        isMyPlanDetailsLoading: action.payload,
      };

    //Purchased Plan Stored
    case types.GET_PURCHASED_PLAN:
      // 
      if (action.payload != "") {
        return {
          ...state,
          //  purchasedPlan: state.purchasedPlan.length ==0 ? [action.payload] : state.purchasedPlan
          purchasedPlan:
            action.payload != null ? [action.payload] : state.purchasedPlan,
        };
      } else {
        return {
          ...state,
          purchasedPlan: action.payload,
        };
      }

    case types.UPDATE_PERSONAL_DETAILS:
      ///
      // let personalist = state.AllProfileDetails
      // // 
      // // ID, Nationality, CountryofBirth, PlaceofBirth, Occupation, AnnualIncome, PoliticalStatus, UserID

      // let personalobject = state.AllProfileDetails.personalDetails
      // personalobject.AnnualIncome = action.AnnualIncome;
      // personalobject.PoliticalExperience = action.PoliticalStatus;
      // personalobject.CitizenshipID = action.Nationality

      // personalist.personalDetails = personalobject

      // let mainobject = state.AllProfileDetails.mainDetails
      // mainobject.PlaceOfBirth = action.PlaceofBirth
      // mainobject.CountryOfBirth = action.CountryofBirth
      // mainobject.Occupation = action.Occupation
      // personalist.mainDetails = mainobject
      // 
      // state.AllProfileDetails = personalist
      //  
      //   const elementsIndex = state.AllProfileDetails.mainDetails.findIndex((item) => item.NomineeID === action.NomineeID)
      let mainlist = state.AllProfileDetails;
      let newArray = [...state.AllProfileDetails.mainDetails];
      newArray[0] = {
        ...newArray[0],
        PlaceOfBirth: action.PlaceofBirth,
        CountryOfBirth: action.CountryofBirth,
        Occupation: action.Occupation,
      };
      let newArraypersonal = [...state.AllProfileDetails.personalDetails];
      newArraypersonal[0] = {
        ...newArraypersonal[0],
        CitizenshipID: action.Nationality,
        PoliticalExperience: action.PoliticalStatus,
        AnnualIncome: action.AnnualIncome,
      };

      mainlist.mainDetails = newArray;
      mainlist.personalDetails = newArraypersonal;
      // 
      return {
        ...state,

        AllProfileDetails: mainlist,
      };
    case types.DELETE_COUNTRY:
      let alldata = state.AllProfileDetails;
      let updateddata = state.AllProfileDetails.taxDetails;
      if (updateddata.length != 0) {
        updateddata = updateddata.filter((tar) => {
          // 
          return action.CountryID != tar.TaxPayingCountryID;
        });
      }
      // 
      alldata.taxDetails = updateddata;
      return {
        AllProfileDetails: replaceNull(alldata, "NA"),
      };
    case types.DELETE_NOMINEE:
      let alldatanominee = state.AllProfileDetails;
      let updateddatanominee = state.AllProfileDetails.nominee;
      if (updateddatanominee.length != 0) {
        updateddatanominee = updateddatanominee.filter((tar) => {
          // 
          return action.NomineeID != tar.NomineeID;
        });
      }
      alldatanominee.nominee = updateddatanominee;
      return {
        AllProfileDetails: replaceNull(alldatanominee, "NA"),
      };
    case types.MARK_AS_PRIMARY:
      let allDetails = state.AllProfileDetails;
      // const elementsIndex = state.AllProfileDetails.bankDetails.findIndex((item) => item.BankAccountNumber === action.BankID)
      let newArraybankdetails = [...state.AllProfileDetails.bankDetails];
      // newArraybankdetails[elementsIndex] = { ...newArraybankdetails[elementsIndex], IsDefault: action.IsDefault, }

      allDetails.bankDetails = newArraybankdetails.map((item) => {
        return { ...item, IsDefault: item.ID == action.BankID ? true : false };
      });
      allDetails.bankDetails = [...allDetails.bankDetails.filter((item) => item.IsDefault == true), ...allDetails.bankDetails.filter((item) => item.IsDefault === false)]
      // 
      return {
        ...state,

        AllProfileDetails: replaceNull(allDetails, "NA"),
      };
    case types.LOADER_EQUITY_PROFILE_DETAILS:
      return {
        ...state,
        isEquityProfileDetailsLoading: action.payload
      }
    case types.EMPTY_EQUITY_PROFILE_DETAILS:
      return {
        ...state,
        AllEquityProfileDetails: []
      }
    case types.EQUITY_PROFILE_DETAILS:
      return {
        ...state,
        AllEquityProfileDetails: action.payload
      }
    default:
      return state;
  }
};
export default reducer;
