import Networking from '../../utils/api/apiaccess';
import { labelcolor } from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import Apivalues from '../url/apiurl'
function MoveToCart(ClientID, SessionID,CartList,MutualFundUcc) {
    let BaseUrl = Apivalues.MoveToCart
    let data = {};
    data.ClientID = ClientID,
        data.SessionID = SessionID
    data.CartList = CartList,
        data.MutualFundUcc = MutualFundUcc

    return Networking.PostApi(BaseUrl, data).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });

}
export default MoveToCart
