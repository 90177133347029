import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Zoom from "@material-ui/core/Zoom"; //import Select from '@material-ui/core/Select';
import Badges from "../../components/badge/badge";
import Headers from "../../components/report_common_header/report_common_header";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import secureStorage from "../../utils/securestorage";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { replaceNull } from "../../utils/commonfunction";
import {
  filterChange,
  GetPortfolioXray,
  equityFilterChange,
} from "../../redux/actions/portfolioxray/portfolioxray";
import FolioPopup from "../../components/tradingpopup/foliopopup";
import Label from "../../utils/constants/labels";
import getAllcommonDownloadDetails from "../../api/commondownload/commondownload";
import { xrayfilterChange } from "../../redux/actions/filter/reportsfilter";
import labels from "../../utils/constants/labels";
import { Redirect } from "react-router";
import { MContext } from '../../components/pagination/renderpage';
import Pagination from '../../components/pagination/renderpage';
import getSchemeWiseXrayList from "../../api/portfolioxrayreport/schemewisexrayapi";
import getFamilySchemeWiseXrayList from "../../api/portfolioxrayreport/familyschemewisexrayapi";
import {
  portfolioXraySortByAtoZ,
  portfolioXraySortByMarketValue,
  portfolioXraySortByReturns,
} from "../../redux/actions/portfolioxray/portfolioxray";
import Loader from "../../components/loader/loader";
import NoData from "../../components/nodata/nodata";
import { withStyles } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import { NegativeFinding, SchemeCodeFinding, sortArray } from "../../utils/commonfunction";
import combineStyle from "../../utils/stylesheets/combainstyle/combainstyle";
import commonstyletable from "../../utils/stylesheets/table/commontablestyle";
import Popover from "@material-ui/core/Popover";
import apivalues from "../../api/url/apiurl";
import CommonSnackbar from "../../components/snackbar/snackbar";
import ErrorBoundary from "../../components/Errorhandling/Errorhandle";
// import dropdown from '../../components/mandate/dropdown';
import {
  RedemptionChange,
  SchemeChange,
  TypeChange,
} from "../../redux/actions/filter/reportsfilter";
import constants from "../../utils/constants/apiconstant";
import CommonDialogBox from "../../components/dialog/dialog";
import getPortfolioxraySchemeDetails from "../../api/portfolioxrayschemedetail/portfolioxrayschemedetail";
import Deletepopup from "../../components/popup/deletepopup";
import Epfreport from "../epf/epfreport";
import Networking from "../../utils/api/apiaccess";
const styles = (theme) => ({

  popoverlist: {
    width: "150px",
  },
  desktopresponsive: {
    [theme.breakpoints.down("924")]: {
      display: "none !important",
    },
  },
  thead: {

    [theme.breakpoints.down("924")]: {
      display: "grid !important",
      gridTemplateColumns: "50% 50% !important",
      paddingRight: 10
    },
  },
  theadResponsive: {
    display: "none !important",
    [theme.breakpoints.down("924")]: {
      display: "block !important",
      margin: " 0px 10px",
    },
  },
  portfolioSchemeBlock: {
    display: "none !important",
    [theme.breakpoints.down("924")]: {
      display: "Block !important",
      // marginLeft: -20,
      borderRadius: 6,
      padding: "5px",
      boxShadow: "0 0 5px 0 rgb(19 89 161 / 6%)",
      backgroundColor: window.globalConfig.color.WHITE,
    },
  },
  // popup:{
  //   cursor: "pointer",
  //   margin: "-1px 0px 0px 7px",
  //   maxWidth:"100%"
  // },
  headerBlock: {

    [theme.breakpoints.down("924")]: {
      display: "none !important",
    },
  },
  responsiveBlock: {
    display: "none !important",
    [theme.breakpoints.down("924")]: {
      display: "Block !important",
      // marginLeft: -20,
      borderRadius: 6,
      width: "100%",
    },

  },

  SchemeNameSpan: {
    fontSize: "16px !important",
    fontWeight: "bold",
  },

  unReleasedtd: {
    width: "12%",
    fontSize: "16px",
    fontWeight: "bold",

  },
  stickyHeader: {
    position: "sticky",
    height: "100%",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 3px",
    },
  },
  footerToatal: {
    fontSize: "20px",
  },
  footerUnreleasedGl: {
    width: "10%",
    fontSize: "20px",
    color: window.globalConfig.color.GREEN,
  },
  footerUnreleasedGlR: {
    width: "10%",
    fontSize: "20px",
    color: window.globalConfig.color.red,
  },
  footerBlock: {
    display: "none !important",
    [theme.breakpoints.down("924")]: {
      display: "block !important",
      padding: "0px 10px",
    },

  },
  footerBlockHide: {
    // backgroundColor:window.globalConfig.color.BLACK_PRIMARY,
    [theme.breakpoints.down("924")]: {
      display: "none !important",
    },
  },
  net: {
    marginLeft: "2px",
    marginTop: 3,
  },
  directionColumn: {
    display: "flex",
    flexDirection: "column",
  },
  schemeNameColumn: {
    //width: "18vw",
    textAlign: "left",
  },
  unitsColumn: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    [theme.breakpoints.down("920")]: {
      flexDirection: "row !important",
      gap: 0
    },
    //  width: "4.3vw",
  },
  investedValueColumn: {
    //  width: "12vw",
  },
  currentValueColumn: {
    //  width: "13vw",
  },
  unrealisedColumn: {
    //  width: "12vw",
  },
  netColumn: {
    //  width: "12vw",
  },
  noDataMobile: {
    display: "none !important",
    [theme.breakpoints.down("xs")]: {
      display: "block !important",
      width: "100%",
      height: 50,
      paddingTop: 30,
      paddingBottom: 30,
    },
  },
  iconShowDesktop: {
    cursor: "pointer"
  },
  header: {
    [theme.breakpoints.down('920')]: {
      display: "none !important"
    }
  },
  hideDiv: {
    [theme.breakpoints.down('920')]: {
      display: "none !important"
    }
  },
  hideForDesktopDiv: {
    [theme.breakpoints.up('920')]: {
      display: "none !important"
    }
  },

  mobileHeader: {
    display: "none",
    [theme.breakpoints.down('920')]: {
      display: "flex !important",
      fontSize: "smaller",
      color: window.globalConfig.color.LABLE_GRAY
    }

  },

  alignStart: {
    display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 10,
    textAlign: "left",
    [theme.breakpoints.down('920')]: {
      gap: 0
    }
  },
  alignEnd: {
    display: "flex", flexDirection: "column", alignItems: "flex-end", gap: 10,
    [theme.breakpoints.down('920')]: {
      gap: 0
    }
  },
  alignBaseLine: {
    [theme.breakpoints.down('920')]: {
      alignItems: "baseline !important"
    }
  },
  startDate: {
    [theme.breakpoints.down('920')]: {
      // alignItems: "flex-start",
      display: "flex",
      textAlign: "left"
    }
  },
  accessGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: 'schemeFirstGrid',
      // width: "fit-content"
    }
  },
  popup: {
    maxWidth: "100%"
  },
  secondGrid: {

    marginRight: 3,
    paddingRight: 3,
    borderRight: `1.5px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    [theme.breakpoints.down("920")]: {
      borderRight: "none",
      gridArea: 'schemeSecondGrid',
      margin: 0,
      padding: 0
      // width: "fit-content"
    }
  },
  thirdGrid: {
    gap: 30,

    [theme.breakpoints.down("920")]: {
      gridArea: 'schemeThirdGrid',
      gap: 0,
      alignItems: "flex-start",
      // width: "fit-content"
    }
  },
  fourthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: 'schemeFourthGrid',
      // width: "fit-content"
    }
  },
  investedAmountGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: 'investedAmountGrid',
      // width: "fit-content"
    }
  },
  fifthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: 'schemeFifthGrid',
      alignSelf: "flex-start"
      // width: "fit-content"
    }
  },
  sixthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: 'schemeSixthGrid',
      alignSelf: "flex-start"
      // width: "fit-content"
    }
  },
  footerGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: 'myLastGrid',
      alignSelf: "self-start",
      marginTop: 10
      // width: "fit-content"
    }
  },
  setMaxWidth: {
    width: "max-content"
  },
  folioNumber: {
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: "13px",
    fontWeight: 500,
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("920")]: {
      flexDirection: "column",
    }
  },
  schemeFamilyTransactionDetails: {
    display: "grid",
    gridTemplateColumns: "repeat(11,1fr)",
    gap: 10,
    "& .title0": {
      alignItems: "start",
      textAlign: "left !important"
    },
    "& .title1": {
      alignItems: "start",
      textAlign: "left !important"
    },
    "& .title10": {
      alignItems: "start",
      textAlign: "left !important"
    },
    "& .dataTwo": {
      alignItems: "start"
    },
    "& .dataOne": {
      alignItems: "start"
    },
    "& .dataEleven": {
      alignItems: "start",
      textAlign: "left !important"
    },
    [theme.breakpoints.down("920")]: {

      gridTemplateColumns: "repeat(3,1fr)",
      gridTemplateAreas: `"${"dataOne"} . ."
        "${"dataTwo"} ${"dataThree"} ${"dataFour"}"
        "${"dataFive"} ${"dataSix"} ${"dataSeven"}"
        "${"dataEight"} ${"dataNine"} ${"dataTen"}"
        "${"dataEleven"} ${"dataTwelve"} ${"dataThirteen"}"
       `,
      padding: 10,
      gap: 10,
      "& .dataTwo": {
        alignItems: "start"
      },
      "& .dataThree": {
        // textAlign: "center !important"
        alignItems: "center"
      },
      "& .dataFour": {
        // textAlign: "center !important",
        alignItems: "end"
      }
    }
  },
  schemeTransactionDetails: {
    display: "grid",
    gridTemplateColumns: "repeat(10,1fr)",
    gap: 10,
    "& .title0": {
      alignItems: "start",
      textAlign: "left !important"
    },
    "& .title1": {
      alignItems: "start",
      textAlign: "left !important"
    },
    "& .title10": {
      alignItems: "start",
      textAlign: "left !important"
    },
    "& .dataTwo": {
      alignItems: "start"
    },
    "& .dataOne": {
      alignItems: "start"
    },
    [theme.breakpoints.down("920")]: {

      gridTemplateColumns: "repeat(3,1fr)",
      gridTemplateAreas: `"${"dataOne"} . ."
        "${"dataTwo"} ${"dataThree"} ${"dataFour"}"
        "${"dataFive"} ${"dataSix"} ${"dataSeven"}"
        "${"dataEight"} ${"dataNine"} ${"dataTen"}"
        "${"dataEleven"} ${"dataTwelve"} ${"dataThirteen"}"
       `,
      padding: 10,
      gap: 10,
      "& .dataTwo": {
        alignItems: "start"
      },
      "& .dataThree": {
        // textAlign: "center !important"
        alignItems: "center"
      },
      "& .dataFour": {
        // textAlign: "center !important",
        alignItems: "end"
      }
    }
  },
});
let TransactionListTitle = [{ header: "Txn Type" }, { header: "Txn Date" }, { header: "Units" }, { header: "Pur Price" }, { header: "Pur Value" }, { header: "Current Nav", subheader: "NAV Date" }, { header: "Current Value" }, { header: "Unrealised G/L" }, { header: "No Of Days" }, { header: "Abs Ret.%" }]
let FamilyTransactionListTitle = [{ header: "Txn Type" }, { header: "Txn Date" }, { header: "Units" }, { header: "Pur Price" }, { header: "Pur Value" }, { header: "Current Nav", subheader: "NAV Date" }, { header: "Current Value" }, { header: "Unrealised G/L" }, { header: "No Of Days" }, { header: "Abs Ret.%" }, { header: "Name" }]
let folio = []
class PortfolioXRay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      anchorEl: null,
      xraysort: [],
      totalxray: [],
      sortbyAtoZ: false,
      sortbyMarkertValue: false,
      sortbyReturns: false,
      selectFilter: 0,
      open: false,
      selectedList: [],
      backupList: [],
      // maturitydata: [{
      //   category: "Sample", data: [{ issuerName: "shriram transport", issuerID: "12333", investmentAmount: "400000", frequency: "one time", rateofInterest: "8%", type: "yearly payout", startdate: "27 feb 2022", tenure: "36 months", maturitydate: "27 feb 2025", timeLeft: "23 M 4 days left", maturityoption: "renewal", status: "live" },
      //   { issuerName: "shriram transport", issuerID: "12333", investmentAmount: "400000", frequency: "one time", rateofInterest: "8%", type: "yearly payout", startdate: "27 feb 2022", tenure: "36 months", maturitydate: "27 feb 2025", timeLeft: "23 M 4 days left", maturityoption: "renewal", status: "matured" }]
      // }
      // ],
      purchasemore: false,
      switch: false,
      stp: false,
      swp: false,
      Redemption: false,
      accexcel: false,
      accpdf: false,
      show: false,
      data: 0,
      productID: 1,
      pdfUrl: "",
      excelUrl: "",
      isDesktopView: true,
      openFolioPopup: false,
      openAccount: false,
      folioNumberList: [],
      xray: [], selectedIndex: 1,
      SubReport: 1,
      waitScreen: false
    };
  }
  handleChange = (checked) => {
    this.setState({ checked: !this.state.checked });
    this.props.filterSubmit([]);
  };
  resize() {
    let currentHideNav = (window.innerWidth >= 920);
    if (currentHideNav !== this.state.isDesktopView) {
      // 
      this.setState({ isDesktopView: currentHideNav });
    }

  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this))
  }
  componentDidMount() {
    if (this.props.hideCategory != undefined) {
      this.setState({ checked: this.props.hideCategory })
    }
    this.setState({ backupList: this.props.List })
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    this.props.RedemptionChange([], Label.TradingType);
    this.props.TypeChange([], Label.SWPtype);
    if (this.props.filterSubmit != undefined) {
      this.props.filterSubmit([]);
    }


    this.setState({ sortbyAtoZ: false });

    switch (this.props.flag) {
      case "mutualfunds":
        if (this.props.selected == "Folio") {
          return this.setState({ productID: 1, SubReport: 1, pdfUrl: apivalues.pdfXray, excelUrl: apivalues.excelXray })
          break;
        } else {
          return this.setState({ productID: 1, SubReport: 1, pdfUrl: apivalues.pdfXray, excelUrl: apivalues.excelXray })
          break;
        }


      case "equity":
        return this.setState({ productID: 2, SubReport: 1, pdfUrl: apivalues.pdfEquityXray, excelUrl: apivalues.excelEquityXray })
        break;
      case constants.Common.Bonds:
        return this.setState({ productID: 3, SubReport: 1, pdfUrl: apivalues.pdfBondsXray, excelUrl: apivalues.excelBondsXray })
        break;
      case constants.Common.GB:
        return this.setState({ productID: 4, pdfUrl: apivalues.pdfGBXray, excelUrl: apivalues.excelGBXray })
        break;
      case constants.Common.FD:
        return this.setState({ productID: 5, pdfUrl: apivalues.pdfXray, excelUrl: apivalues.excelXray })
        break;
      case labels.ManuHeading.familyPortfolio:
        this.setState({ productID: this.props.productID })
        break;
      default:
        break;
    }
  }
  componentDidUpdate(prevProps) {
    const {
      clientID,
      date,
      sessionID,
      dataTab,
      catchmessage,
      isLoadinggroupXray,
      xray,
      xrayfilter,
      xrayfilterTotal,
    } = this.props;
    if (prevProps.dataTab !== dataTab && this.props.xray !== xrayfilter) {
      this.state.selectFilter = [];
    }

    if (prevProps.catchmessage !== catchmessage) {
      this.setState({ open: catchmessage });
    }
  }
  folioContinue = async (value) => {
    // let schemecode = "";
    // let Details = this.state.selectedList[0];
    // Details.AMCID = this.state.selectedList[0]?.AI
    // Details.ISIN = this.state.selectedList[0]?.ISIN
    // Details.RTAProdCode = this.state.selectedList[0]?.PC
    // Details.EUIN = ""
    // Details.EUINFlag = ""
    // Details.FolioList = this.state.selectedList[0].FolioList
    // Details.InvesmentType = "One time";
    // Details.SchemeName = this.state.selectedList[0].Scheme;
    // Details.MutualFundUCC = value
    // let purchaseData = {};
    // purchaseData.InvesmentType = "One time";
    // purchaseData.SchemeID = this.state.selectedList[0].SchemeID;
    // purchaseData.SchemeName = this.state.selectedList[0].Scheme;
    // purchaseData.AssetClass = this.state.selectedList[0].Category;
    // purchaseData.AMCID = this.state.selectedList[0]?.AI
    // purchaseData.AUM = this.state.selectedList[0].AUM;
    // purchaseData.StepperActive = 0;
    // purchaseData.Risk = this.state.selectedList[0].Risk;
    // purchaseData.Amount = "";
    // purchaseData.ISIN = this.state.selectedList[0].ISIN;
    // purchaseData.threeYearReturn = this.state.selectedList[0].CurrentNAV;
    // purchaseData.date = this.state.selectedList[0].AsonDate;
    // purchaseData.SchemeCode = schemecode;
    // purchaseData.isFromCart = false;
    // purchaseData.RTAProdCode = this.state.selectedList[0]?.PC
    // purchaseData.BuySellType = "ADDITIONAL";
    // purchaseData.folioNumber = "";
    // purchaseData.Ucc = value
    // purchaseData.MutualFundUCC = value
    // purchaseData.page = "portfolioxray";
    // purchaseData.FolioList = this.state.selectedList[0].FolioList
    // purchaseData.multipleScheme = [Details]
    // if (this.state.selectedIndex == 1) {
    //   this.setState({ show: true });
    //   this.props.history.push({
    //     pathname: Label.Path.Payment,
    //     state: { data: purchaseData },
    //   });


    // }
    // if (this.state.selectedIndex == 2) {
    //   this.props.history.push({
    //     pathname: Label.Path.Redemption,
    //     state: { data: purchaseData },
    //   });
    //   this.setState({ anchorEl: null });
    // }
    // if (this.state.selectedIndex == 3) {
    //   //
    //   this.props.SchemeChange(
    //     [
    //       {
    //         label: this.state.selectedList[0].Scheme,
    //         value: this.state.selectedList[0].SchemeID,
    //         isChecked: true,
    //       },
    //     ],
    //     this.props.schemeList
    //       // .filter((item) => item.SWPAllowed === true)
    //       .map((val) => {
    //         return {
    //           label: val.Scheme,
    //           value: val.SchemeID,
    //           isChecked: false,
    //         };
    //       })
    //   );
    //   this.props.history.push({
    //     pathname: Label.Path.SWP,
    //     state: { data: purchaseData },
    //   });
    //   this.setState({ anchorEl: null });
    // }

    // if (this.state.selectedIndex == 4) {
    //   this.props.SchemeChange(
    //     [
    //       {
    //         label: this.state.selectedList[0].Scheme,
    //         value: this.state.selectedList[0].SchemeID,
    //         isChecked: true,
    //       },
    //     ],
    //     this.props.schemeList
    //       // .filter((item) => item.STPAllowed === true)
    //       .map((val) => {
    //         return { label: val.Scheme, value: val.SchemeID, isChecked: false };
    //       })
    //   );

    //   this.props.history.push({
    //     pathname: Label.Path.STP,
    //     state: { data: purchaseData },
    //   });
    //   this.setState({ anchorEl: null });
    //   //this.props.history.push(labels.Path.Switch)
    // }
    // if (this.state.selectedIndex == 5) {
    //   this.props.SchemeChange(
    //     [
    //       {
    //         label: this.state.selectedList[0].Scheme,
    //         value: this.state.selectedList[0].SchemeID,
    //         isChecked: true,
    //       },
    //     ],
    //     this.props.schemeList
    //       // .filter((item) => item.SwitchAllowed === true)
    //       .map((val) => {
    //         return {
    //           label: val.Scheme,
    //           value: val.SchemeID,
    //           isChecked: false,
    //         };
    //       })
    //   );

    //   this.props.history.push({
    //     pathname: Label.Path.Switch,
    //     state: { data: purchaseData },
    //   });
    //   this.setState({ anchorEl: null });

    //   //this.props.history.push(labels.Path.Switch)
    // }
    let purchaseData = await this.passDataToTradePage(1, value)
    this.props.history.push({
      pathname: Label.Path.Payment,
      state: { data: purchaseData },
    });
    this.setState({ anchorEl: null, show: false });

  }



  submit = (value) => {
    this.props.filterSubmit(value);

    this.setState({
      selectFilter: value,
    });
  };

  handleClick = async (event, data) => {
    this.setState({ anchorEl: event.currentTarget });
    this.state.selectedList = [];
    this.setState({
      selectedList: [{ ...data, SchemeName: data.Scheme, InvesmentType: "One time" }],
      stp: data.STPAllowed,
      switch: data.SwitchAllowed,
      swp: data.SWPAllowed,
      purchasemore: data.PurchaseAllowed,
      Redemption: data.RedemptionAllowed,
    });

  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  passDataToTradePage = async (id, ucc) => {
    let purchaseData = {};
    purchaseData.InvesmentType = "One time";
    purchaseData.BSESchemeType = this.state.selectedList[0].BSESchemeType
    purchaseData.SchemeID = this.state.selectedList[0].SchemeID;
    purchaseData.SchemeName = this.state.selectedList[0].Scheme;
    purchaseData.AssetClass = this.state.selectedList[0].Category;
    purchaseData.threeYearReturn = this.state.selectedList[0].CurrentNAV;
    purchaseData.InvesmentType = "One time"
    purchaseData.date = this.state.selectedList[0].AsonDate;
    purchaseData.StepperActive = 0;
    purchaseData.AMCID = "";
    purchaseData.EUIN = "";
    purchaseData.EUINFlag = "";
    purchaseData.AUM = this.state.selectedList[0]?.AUM;
    purchaseData.Risk = this.state.selectedList[0]?.Risk;
    purchaseData.Amount = "";
    purchaseData.ISIN = this.state.selectedList[0]?.ISIN;
    purchaseData.threeYearReturn = this.state.selectedList[0]?.CurrentNAV;
    purchaseData.MutualFundUCC = ucc == undefined ? this.props.uccList[0]?.ClientCode : ucc
    purchaseData.folioNumber = ""
    purchaseData.BuySellType = "ADDITIONAL";
    purchaseData.isFromCart = false;
    purchaseData.RTAProdCode = this.state.selectedList[0]?.PC

    let addUCC = this.state.selectedList[0]
    addUCC.isFromCart = false;
    addUCC.RTAProdCode = this.state.selectedList[0]?.PC
    purchaseData.Ucc = this.props.uccList[0].ClientCode
    purchaseData.MutualFundUCC = ucc == undefined ? this.props.uccList[0].ClientCode : ucc
    addUCC.MutualFundUCC = ucc == undefined ? this.props.uccList[0].ClientCode : ucc
    purchaseData.multipleScheme = [addUCC]
    if (id == 1) {
      folio = []
      this.setState({ show: true })
      await getPortfolioxraySchemeDetails(
        this.props.logindetails.ClientID,
        0,
        this.state.selectedList[0].SchemeID,
        false, "ADDPUR"
      ).then((response) => {
        this.setState({ show: false })
        if (response.status == "S") {
          if (response.folioNumber.length != 0) {
            folio = response.folioNumber
          } else {
            folio = []
          }
        } else {
          folio = []
        }
      })
      purchaseData.FolioList = JSON.stringify(folio)
      addUCC.FolioList = JSON.stringify(folio)
    }
    if (id == 4) {
      this.setState({ waitScreen: true })

      let getMinimumInstalment = await SchemeCodeFinding(
        0,
        labels.investmentPopup.keySTP,
        purchaseData.ISIN,
        purchaseData.RTAProdCode,
        this.props.logindetails.ClientID, "STPOUT", "Monthly",
      )
      purchaseData.minimumInstalment = getMinimumInstalment.response?.MINIMUM_INSTALLMENT_NUMBERS || 6
      this.setState({ waitScreen: false })

    }
    return purchaseData
  }
  handleListItemClick = async (event, index, Param3) => {

    this.setState({ selectedIndex: index });
    let purchaseData = await this.passDataToTradePage(index)
    if (index == 2) {
      this.props.history.push({
        pathname: Label.Path.Redemption,
        state: { data: purchaseData },
      });
      this.setState({ anchorEl: null });
    }else if (index == 3) {
      this.props.history.push({
        pathname: Label.Path.SWP,
        state: { data: purchaseData },
      });
      this.setState({ anchorEl: null });
    }
     else {

      if (index == 4) {

        this.props.SchemeChange(
          [
            {
              label: this.state.selectedList[0].Scheme,
              value: this.state.selectedList[0].SchemeID,
              isChecked: true,
            },
          ],
          this.props.schemeList
            // .filter((item) => item.STPAllowed === true)
            .map((val) => {
              return { label: val.Scheme, value: val.SchemeID, isChecked: false };
            })
        );

        this.props.history.push({
          pathname: Label.Path.STP,
          state: { data: purchaseData },
        });
        this.setState({ anchorEl: null, waitScreen: false });
        //this.props.history.push(labels.Path.Switch)
      }
      else if (index == 5) {
        this.props.SchemeChange(
          [
            {
              label: this.state.selectedList[0].Scheme,
              value: this.state.selectedList[0].SchemeID,
              isChecked: true,
            },
          ],
          this.props.schemeList
            // .filter((item) => item.SwitchAllowed === true)
            .map((val) => {
              return {
                label: val.Scheme,
                value: val.SchemeID,
                isChecked: false,
              };
            })
        );

        this.props.history.push({
          pathname: Label.Path.Switch,
          state: { data: purchaseData },
        });
        this.setState({ anchorEl: null });

        //this.props.history.push(labels.Path.Switch)
      } else {
        if (this.props.uccList.length != 0) {


          if (this.props.uccList.length == 1) {

            this.setState({ anchorEl: null, show: false });
            if (index == 1) {
              this.setState({ show: true });
              this.props.history.push({
                pathname: Label.Path.Payment,
                state: { data: purchaseData },
              });
            }


          } else {
            this.setState({
              folioNumberList: this.props.uccList.map((item) => {
                return { ...item, label: item.ClientCode, value: item.ClientCode }
              })
            });
            this.setState({ anchorEl: null });
            this.setState({ openFolioPopup: true });
          }


        }
        else {
          this.setState({
            anchorEl: null,
            show: false,
            openAccount: true,
            message: labels.messages.uccnotavailble
          });
        }
      }


      // }
      // else {
      //   this.setState({
      //     open: true,
      //     message: index == 1 ? Label.investmentPopup.purchaseNotAllowed : index == 2 ? labels.investmentPopup.redeemNotAllowed : index == 3 ? labels.investmentPopup.swpNotAllowed : index == 4 ? labels.investmentPopup.stpNotAllowed : labels.investmentPopup.switchNotAllowed, anchorEl: null, show: false
      //   });

      // }
    }



  };
  downloadclick = async (ext) => {
    var pdfexcel = "";
    var filename = "";
    let url = "";
    filename = `${Label.DownloadLabel.portfolioxray}${ext}`;
    let data = {}
    // if () {
    if (this.state.accpdf == false && ext === "pdf") {
      this.setState({ accpdf: true });
      pdfexcel = true;
      //format("MM/DD/YYYY")
      data = {
        "ClientID": this.props.logindetails.ClientID, "AccountID": 0, "ReportID": labels.Report.portfolioXray, "WithRedeem": false, "AsOnDate": moment(this.props.date).format("DD MMM YYYY"),
        "SubReport": 0, "DownloadAs": 1, "ProductID": this.state.productID, "FamilyID": 0, "L5ID": 0, "L4ID": 0
      }

      url = this.state.pdfUrl +
        `?clientId=${this.props.logindetails.ClientID}&asOnDate=${moment(this.props.date).format("DD MMM YYYY")}&ProductID=${this.state.productID
        }&FamilyID=${0}&L5ID=${this.props.L5ID}&L4ID=${0}`;
    }
    // }
    // if () {
    if (this.state.accexcel == false && ext === "xlsx") {
      this.setState({ accexcel: true });
      pdfexcel = true;
      data = {
        "ClientID": this.props.logindetails.ClientID, "AccountID": 0, "ReportID": labels.Report.portfolioXray, "WithRedeem": false, "AsOnDate": moment(this.props.date).format("DD MMM YYYY"),
        "SubReport": 0, "DownloadAs": 2, "ProductID": this.state.productID, "FamilyID": 0, "L5ID": 0, "L4ID": 0
      }
      url = this.state.excelUrl +
        `?clientId=${this.props.logindetails.ClientID}&asOnDate=${moment(this.props.date).format("DD MMM YYYY")}&ProductID=${this.state.productID
        }&FamilyID=${0}&L5ID=${this.props.L5ID}&L4ID=${0}`;
    }
    // }
    // url =
    //   await Networking.PostApi(apivalues.ExportDownloadURL, data).then((res) => {
    //     let a = ""
    //     if (res.status == "S" && res.data != "") {
    //       a = res.data
    //     } else {
    //       if (ext === "pdf") {
    //         this.setState({ accpdf: false });
    //       }
    //       if (ext === "xlsx") {
    //         this.setState({ accexcel: false });
    //       }
    //     }
    //     return a
    //   }).catch((er) => {
    //     if (ext === "pdf") {
    //       this.setState({ accpdf: false });
    //     }
    //     if (ext === "xlsx") {
    //       this.setState({ accexcel: false });
    //     }
    //     return ""
    //   })
    if (pdfexcel === true && url.length != 0) {
      getAllcommonDownloadDetails(url, ext, filename, this.props.logindetails?.SessionID).then((response) => {

        if (response === "success") {
          if (ext === "pdf") {
            this.setState({ accpdf: false });
          }
          if (ext === "xlsx") {
            this.setState({ accexcel: false });
          }
        } else {
          this.setState({ open: true, message: Label.messages.apierror });
          if (ext === "pdf") {
            this.setState({ accpdf: false });
          }
          if (ext === "xlsx") {
            this.setState({ accexcel: false });
          }
        }
      });
    } else {
      this.setState({ open: true, message: Label.messages.apierror });

    }
  };
  getValues = (menu, value) => {
    if (this.props.flag == labels.ManuHeading.familyPortfolio) {
      this.props?.sortBy(menu, value)
      if (value == 0) {
        this.setState({ sortbyAtoZ: !this.state.sortbyAtoZ });

      }
    } else {
      // let data = [...this.props.xray].sort((a, b) => (a.Scheme.toLowerCase() < b.Scheme.toLowerCase()) ? 1 : -1)
      if (value == 0) {
        // data = sortArray(data, "Scheme", !this.state.sortbyAtoZ)
        this.props.portfolioXraySortByAtoZ(
          !this.state.sortbyAtoZ,
          this.props.flag,
          [...this.props.xray]
        );
        this.setState({ sortbyAtoZ: !this.state.sortbyAtoZ });

      }
      if (value == 1) {
        this.setState({ sortbyMarkertValue: !this.state.sortbyMarkertValue });
        this.props.portfolioXraySortByMarketValue(
          this.state.sortbyMarkertValue,
          this.props.flag,
          [...this.props.xray]
        );
      }
      if (value == 2) {
        this.setState({ sortbyReturns: !this.state.sortbyReturns });
        this.props.portfolioXraySortByReturns(
          this.state.sortbyReturns,
          this.props.flag,
          [...this.props.xray]
        );
      }
    }


  };
  onClose = () => {
    this.setState({ openFolioPopup: false })
  }
  handlesnanckClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };
  handleAccountClose = () => {
    this.setState({
      openAccount: false
    })
  }

  handleclear = () => {
    this.setState({ selectFilter: "" });
    this.props.filterSubmit([]);

  };
  openTransactionPopup = (val) => {
    if (this.props.flag == "mutualfunds") {
      this.setState({ transactionPopup: true, loaderSchemeWiseHolding: true, selectedList: val })
      getSchemeWiseXrayList(this.props.logindetails.ClientID, this.props.date, val.SchemeID, this.props.L5ID,
        val.CategoryFolioNo == "NA" ? "" : val.CategoryFolioNo, this.state.productID).then((response) => {
          let res = replaceNull(response, "NA")

          if (res.status == "S" && res.data != "NA") {
            this.setState({
              transactionData: replaceNull(res.data, "NA").map((item) => {
                return {
                  dataOne: item.TransactionType || labels.messages.NA
                  , dataTwo: item.TransactionDate, dataThree: NegativeFinding(item.Units, "", "", "", "number"),
                  dataFour: NegativeFinding(item.PurchaseNAV, "", "", "", "rupee") || labels.messages.NA,
                  dataFive: NegativeFinding(item.PurchaseValue, "", "", "", "rupee") || labels.messages.NA,
                  dataSix: NegativeFinding(item.CurrentNAV, "", "", "", "rupee") || labels.messages.NA,
                  dataSeven: NegativeFinding(item.CurrentValue, "", "", "", "rupee"),
                  dataEightStyle: { color: NegativeFinding(item.UnrealizedGainLoss) },
                  dataEight: NegativeFinding(item.UnrealizedGainLoss, "", "", "", "rupee"),
                  dataNine: NegativeFinding(item.NoOfDays, "", "", "", "number"),
                  dataTenStyle: { color: NegativeFinding(item.AbsReturns) }
                  , dataTen: NegativeFinding(item.AbsReturns, "", "", "", "percentage") || labels.messages.NA,
                }
              })
            })
          } else {
            this.setState({ transactionData: [] })
          }
          this.setState({ loaderSchemeWiseHolding: false })
        })
    } else if (this.props.flag == labels.ManuHeading.familyPortfolio) {
      this.setState({ transactionPopup: true, loaderSchemeWiseHolding: true, selectedList: val })
      getFamilySchemeWiseXrayList(this.props.logindetails.ClientID, this.props.date, val.SchemeID,
        val.CategoryFolioNo == "NA" || val.CategoryFolioNo == undefined ? "" : val.CategoryFolioNo,
        this.state.productID).then((response) => {
          let res = replaceNull(response, "NA")

          if (res.status == "S" && res.data != "NA") {
            this.setState({
              transactionData: replaceNull(res.data, "NA").map((item) => {
                return {
                  dataOne: item.TransactionType || labels.messages.NA
                  , dataTwo: item.TransactionDate, dataThree: NegativeFinding(item.Units, "", "", "", "number"),
                  dataFour: NegativeFinding(item.PurchaseNAV, "", "", "", "rupee") || labels.messages.NA,
                  dataFive: NegativeFinding(item.PurchaseValue, "", "", "", "rupee") || labels.messages.NA,
                  dataSix: NegativeFinding(item.CurrentNAV, "", "", "", "rupee") || labels.messages.NA,
                  dataSeven: NegativeFinding(item.CurrentValue, "", "", "", "rupee"),
                  dataEightStyle: { color: NegativeFinding(item.UnrealizedGainLoss) },
                  dataEight: NegativeFinding(item.UnrealizedGainLoss, "", "", "", "rupee"), dataNine: NegativeFinding(item.NoOfDays, "", "", "", "number")
                  , dataTen: NegativeFinding(item.AbsReturns, "", "", "", "percentage") || labels.messages.NA,
                  dataTenStyle: { color: NegativeFinding(item.AbsReturns) }
                  , dataEleven: item.Name || labels.messages.NA
                }
              })
            })
          } else {
            this.setState({ transactionData: [] })
          }
          this.setState({ loaderSchemeWiseHolding: false })
        })
    }

  }

  render() {
    let { classes } = this.props;
    let combainstyle = Object.assign({}, classes, this.props.externalClasses)
    let listBackup = this.props.List
    let List = this.props.flag == labels.ManuHeading.FamilyReport ? this.props.xray.map(({ ...item }) => {
      return item
    }) : this.props.xray.map(({ AbsoluteGainORLossPercentage, ...item }) => {
      return item
    })
    try {

      return (
        <ErrorBoundary>
          {this.props.isLoadinggroupXray ?

            <Loader page="skeletonreport" />

            :

            <div style={{ marginBottom: '3%' }}>
              <div className={[combainstyle.stickyHeader].join(" ")} >
                <Headers
                  toggleName="Category"
                  switchList={this.props.switchList}
                  categorySwitch={this.props.categorySwitch}
                  enableToggleList={this.props.enableToggleList}
                  onclick={this.downloadclick}
                  flag={this.props.flag}
                  disableExports={this.props.flag == constants.Common.FD ? true : this.props.selected == "Folio" ? true : false}
                  showpdf={this.state.checked == false ? true : false}
                  showfilter={this.props.showfilter == undefined ? true : this.props.showfilter}
                  showdownload={this.props.showdownload == undefined ? true : this.props.showdownload}
                  showtoggle={this.props.showtoggle == undefined ? true : this.props.showtoggle}
                  List={this.state.backupList}
                  data={List}
                  showsortby={this.props.showsortby == undefined ? true : this.props.showsortby}
                  selected={this.props.selected || this.state.checked}
                  handleclear={this.handleclear}
                  pdfload={this.state.accpdf}
                  header={this.props.header}
                  newxcelLoad={this.state.accexcel}
                  selectvalue={
                    this.state.selectFilter == 0 ? "" : this.state.selectFilter
                  }
                  scheme={
                    List.length == 1 ? (
                      this.props.xray.length + " " + this.props.header
                    ) : List.length +
                      " " +
                      this.props.header +
                      "s" && List.length > 0 ? (
                      List.length + " " + this.props.header + "s"
                    ) : <NoData /> && List.length == 0 ? (
                      <NoData />
                    ) : (
                      List.length + " " + this.props.header + "s"
                    )
                  }

                  datavalue={this.getValues}
                  Onswitchchange={this.handleChange}
                  page={"PortfolioX-Ray"}
                  aToZ={this.state.sortbyAtoZ}
                  submit={this.submit}
                />
              </div>
              {List.length != 0 &&
                <ErrorBoundary>
                  <div
                    className={[
                      combainstyle.portfolioXraySevenSplitContainer,
                      combainstyle.tableHeader,
                      combainstyle.thead,
                    ].join(" ")}
                    style={{ marginTop: "1%", padding: "10px" }}
                  >
                    {this.props.title.filter((val) => this.state.checked ? val.id != 2 && val.id != 3 : true).map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={index == 0 || this.props.title.length - 1 == index ? "" : combainstyle.hideDiv}
                          style={{
                            // display: "flex",
                            flexDirection: "column",
                            textAlign: index == 0 ? "left" : "right",

                          }}

                        >
                          <div>{item.name}</div>
                          {item.subheader != undefined ? (
                            <div>{item.subheader}</div>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      );
                    })}

                  </div>
                  <Pagination data={List}>
                    <MContext.Consumer>
                      {(context) => {
                        return <>{context.filter(
                          (ele, ind) =>
                            ind ===
                            context.findIndex((elem) => elem[this.props.catagorize || "Category"] === ele[this.props.catagorize || "Category"])
                        ).map((item, mainIndex) => {
                          return (
                            <div key={mainIndex}>
                              {(typeof (this.props.selected) == "string" ? this.props.selected != "Scheme" : this.state.checked) && <div className={[
                                combainstyle.portfolioXrayCatrgorySevenSplitContainer,
                                combainstyle.categoryGroupContainer].join(" ")} style={{ padding: "0px 10px" }}>
                                <div className="categorySchemeGrid" style={{ textAlign: "left", display: "flex", flexDirection: "column", gap: 10 }}>
                                  <span> {item[this.props.catagorize || "Category"]}</span>

                                </div>
                                {/* {item.CategoryFolioNo != undefined &&
                                  <div className={[combainstyle.unitsColumn, "categoryUnitGrid"].join(" ")}>{item.CategoryFolioNo}</div>
                                } */}
                                {item.CategoryUnits != undefined &&
                                  <div className={[combainstyle.unitsColumn, "categoryUnitGrid"].join(" ")}>{item.CategoryUnits}</div>
                                }

                                {/* {item.CategoryNoOfDays != undefined &&
                                  <div className={[combainstyle.unitsColumn, "categoryUnitGrid"].join(" ")}>{item.CategoryNoOfDays}</div>
                                } */}
                                {item.CategoryValuetAtCost != undefined && < div className={[combainstyle.hideDiv, "categoryInvestedGrid"].join(" ")}>
                                  <span className={combainstyle.categoryRupee}>
                                    {Label.currency.Rupee}
                                  </span>
                                  <span>
                                    {NegativeFinding(item.CategoryValuetAtCost, "", "", "", "number")}
                                  </span>
                                </div>}
                                {item.CategoryMarketValue != undefined && <div className={[combainstyle.hideDiv, "categoryMarketValueGrid"].join(" ")} style={{ display: "flex", flexDirection: "column" }}>
                                  <div>
                                    <span className={combainstyle.categoryRupee}>
                                      {Label.currency.Rupee}
                                    </span>
                                    <span>
                                      {NegativeFinding(item.CategoryMarketValue, "", "", "", "number")}
                                    </span>
                                  </div>
                                  <div>
                                    <Badges
                                      color={window.globalConfig.color.ZERO_BACKGROUND}
                                      textcolor={window.globalConfig.color.PRIMARY_BLUE}
                                      text={` ${item.CategorycurrentValuePercentage} %`}
                                    />
                                  </div>
                                </div>}
                                {item.CategoryAbsoluteGainOrLoss != undefined && <div className="categoryAbsoluteGainLossGrid" style={{
                                  color: NegativeFinding(item.CategoryAbsoluteGainOrLoss),
                                }}>
                                  <div>
                                    <span className={combainstyle.categoryRupee}>
                                      {Label.currency.Rupee}
                                    </span>
                                    <span>
                                      {NegativeFinding(
                                        item.CategoryAbsoluteGainOrLoss, "", "", "", "number"
                                      )}
                                    </span>
                                  </div>

                                  {item.CategoryDividend != undefined &&
                                    <div >
                                      <Badges
                                        color={NegativeFinding(item.CategoryDividend)}
                                        textcolor={
                                          NegativeFinding(item.CategoryDividend) == "gray"
                                            ? window.globalConfig.color.BLACK_PRIMARY
                                            : window.globalConfig.color.WHITE
                                        }
                                        text={`${NegativeFinding(item.CategoryDividend, "", "", "", "rupee")}`}
                                      />
                                    </div>}
                                </div>}
                                <div className={[combainstyle.hideDiv, "categoryNetpl"].join(" ")}>
                                  {item.CategoryNetPl != undefined && <div
                                    style={{ color: NegativeFinding(item.CategoryNetPl) }}
                                  >
                                    <span className={combainstyle.categoryRupee}>
                                      {Label.currency.Rupee}
                                    </span>
                                    <span>{NegativeFinding(item.CategoryNetPl, "", "", "", "number")}</span>
                                  </div>}
                                  {item.CategoryXIRR != undefined && <div>
                                    <Badges
                                      color={NegativeFinding(item.CategoryXIRR)}
                                      textcolor={
                                        NegativeFinding(item.CategoryXIRR) == "gray"
                                          ? window.globalConfig.color.BLACK_PRIMARY
                                          : window.globalConfig.color.WHITE
                                      }
                                      text={`${NegativeFinding(item.CategoryXIRR, "", "", "", "percentage")}`}
                                    />
                                  </div>}

                                </div>
                                {/* <div className={[combainstyle.hideDiv, "categoryNetpl"].join(" ")}>
                                  {item.CategoryAbs != undefined && <div
                                    style={{ color: NegativeFinding(item.CategoryAbs) }}
                                  >

                                    <span>{NegativeFinding(item.CategoryAbs, "", "", "", "percentage")}</span>
                                  </div>}
                                  {item.CategoryAnnRet != undefined && <div>
                                    <Badges
                                      color={NegativeFinding(item.CategoryANNRet)}
                                      textcolor={
                                        NegativeFinding(item.CategoryAnnRet) == "gray"
                                          ? window.globalConfig.color.BLACK_PRIMARY
                                          : window.globalConfig.color.WHITE
                                      }
                                      text={`${NegativeFinding(item.CategoryAnnRet, "", "", "", "percentage")}`}
                                    />
                                  </div>}

                                </div> */}

                                <div className={combainstyle.hideDiv}></div>
                                {/* <div className={[combainstyle.categorySubHeader, "schemeLine"].join(" ")}>
                                  <span>
                                    {List.filter(
                                      (element) => item["Category"] === element["Category"]
                                    ).length < 2
                                      ? `${List.filter(
                                        (element) => item["Category"] === element["Category"]
                                      ).length
                                      } ${this.props.header}`
                                      : `${List.filter(
                                        (element) => item["Category"] === element["Category"]
                                      ).length
                                      } ${this.props.header}s`}
                                  </span>
                                </div> */}
                              </div>}

                              {
                                context.filter((val) => val[this.props.catagorize || "Category"] === item[this.props.catagorize || "Category"]).map((value, index) => {
                                  return (
                                    <div key={index} className={[
                                      combainstyle.portfolioXraySevenSplitContainer,
                                      combainstyle.alignBaseLine,
                                      combainstyle.subCategoryGroupContainer,
                                      index == 0 && combainstyle.borderRadiousFirstIndex].join(' ')} style={{ paddingRight: 10, paddingLeft: 10 }}>
                                      <div style={{ cursor: this.props.flag == "mutualfunds" || this.props.flag == labels.ManuHeading.familyPortfolio ? 'pointer' : 'auto' }} className={[combainstyle.alignStart, combainstyle.startDate, "schemeFirstGrid", combainstyle.accessGrid].join(" ")}
                                        onClick={() => this.openTransactionPopup(value)}
                                      >

                                        <div style={{ display: "flex", flexDirection: "column", gap: 10, alignItems: "flex-start" }}>
                                          {value.Scheme != undefined ? (
                                            value.Scheme.length < 70 ?
                                              <span
                                                style={{ borderBottom: this.props.flag == "mutualfunds" || this.props.flag == labels.ManuHeading.familyPortfolio ? "1px dashed" : "" }}
                                              >{value.Scheme}</span>
                                              :
                                              <Tooltip
                                                TransitionComponent={Zoom}
                                                title={value.Scheme}
                                              >
                                                <span style={{
                                                  textOverflow: "ellipsis",
                                                  borderBottom: this.props.flag == "mutualfunds" || this.props.flag == labels.ManuHeading.familyPortfolio ? "1px dashed" : ""
                                                }}>
                                                  {Object.keys(value.Scheme).length === 0
                                                    ? Label.messages.NA
                                                    : value.Scheme.substring(0, 70 - 3) + "..."}
                                                </span>
                                              </Tooltip>

                                          ) : (
                                            ""
                                          )}

                                          <div className={[combainstyle.folioNumber,].join(" ")} style={{ color: window.globalConfig.color.LABLE_GRAY }}>
                                            {value.CurrentNAV != undefined && <span className={combainstyle.secondGrid}>   {this.props.amountLabel?.toUpperCase()} :{" "}{
                                              value.CurrentNAV}</span>}

                                            {value.AsonDate != undefined && <span className={["schemeSecondGrid",].join(' ')}>
                                              {this.props.AsOn + " " + value.AsonDate}</span>}
                                          </div>
                                          {/* } */}
                                        </div>

                                      </div>
                                      {/* {value.FolioNo != undefined &&
                                        <div className={[combainstyle.alignEnd, "schemeThirdGrid", combainstyle.thirdGrid].join(" ")} >
                                          <div className={combainstyle.hideForDesktopDiv} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10 }}> {this.props.units.toUpperCase()}
                                            {this.props.folioNo != undefined && (
                                              this.props.folioNo)}</div>
                                          {value.FolioNo != undefined &&
                                            <div
                                              className={[
                                                combainstyle.directionColumn,
                                                combainstyle.subCatgoryValue,
                                                combainstyle.unitsColumn,
                                              ].join(" ")}
                                            >
                                              {value.FolioNo}

                                            </div>}
                                        </div>} */}
                                      {/* {value.AvgDaysInvested != undefined &&
                                        <div className={[combainstyle.alignEnd, "schemeThirdGrid", combainstyle.thirdGrid].join(" ")} >
                                          <div className={combainstyle.hideForDesktopDiv} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10 }}> {this.props.units.toUpperCase()}
                                            {this.props.noOfDays != undefined && (
                                              this.props.noOfDays)}</div>
                                          {value.AvgDaysInvested != undefined &&
                                            <div
                                              className={[
                                                combainstyle.directionColumn,
                                                combainstyle.subCatgoryValue,
                                                combainstyle.unitsColumn,
                                              ].join(" ")}
                                            >
                                              {value.AvgDaysInvested}

                                            </div>}
                                        </div>} */}
                                      {value.Units != undefined &&
                                        <div className={[combainstyle.alignEnd, "schemeThirdGrid", combainstyle.thirdGrid].join(" ")} >
                                          <div className={combainstyle.hideForDesktopDiv} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10 }}> {this.props.units.toUpperCase()}
                                            {this.props.Subheadingunits != undefined && (
                                              this.props.Subheadingunits)}</div>
                                          {value.Units != undefined &&
                                            <div
                                              className={[
                                                combainstyle.directionColumn,
                                                combainstyle.subCatgoryValue,
                                                combainstyle.unitsColumn,
                                              ].join(" ")}
                                            >
                                              {NegativeFinding(value.Units, "", "", "", "number")}
                                              {value.PurchaseNav != undefined &&
                                                <div>
                                                  <span className={[combainstyle.hideForDesktopDiv].join(" ")} style={{ color: window.globalConfig.color.LABLE_GRAY }}>{"("}</span>
                                                  <span className={combainstyle.subcategoryRupee}>
                                                    {Label.currency.Rupee}
                                                  </span>
                                                  {NegativeFinding(value.PurchaseNav, "", "", "", "number")}
                                                  <span className={[combainstyle.hideForDesktopDiv].join(" ")} style={{ color: window.globalConfig.color.LABLE_GRAY }}>{")"}</span>
                                                </div>}
                                            </div>}
                                        </div>}
                                      <div className={[combainstyle.alignEnd, combainstyle.startDate, "investedAmountGrid", combainstyle.investedAmountGrid].join(" ")}>
                                        <div className={combainstyle.hideForDesktopDiv} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10 }} > {this.props.InvestedValue.toUpperCase()} </div>
                                        {value.ValueAtcost != undefined &&
                                          <div style={{ gap: 10 }}
                                            className={[
                                              combainstyle.directionColumn,
                                              combainstyle.subCatgoryValue,
                                              combainstyle.investedValueColumn,
                                            ].join(" ")}
                                          >
                                            <div>
                                              <span className={combainstyle.subcategoryRupee}>
                                                {Label.currency.Rupee}
                                              </span>
                                              {NegativeFinding(value.ValueAtcost, "", "", "", "number")}
                                            </div>
                                            {value.SIP != undefined &&
                                              <div>
                                                {Object.keys(value.SIP.toString()).length === 0 ||
                                                  value.SIP === 0 ? (
                                                  <div></div>
                                                ) : (
                                                  <div>
                                                    <Badges
                                                      className="badges"
                                                      color={window.globalConfig.color.ZERO_BACKGROUND}
                                                      textcolor={window.globalConfig.color.LABLE_GRAY}
                                                      text={`${this.props.Sip}  ${Label.currency.Rupee} ${value.SIP}`}
                                                    />
                                                  </div>
                                                )}
                                              </div>}
                                          </div>}
                                      </div>
                                      <div className={[combainstyle.alignEnd, "schemeFourthGrid", combainstyle.startDate, combainstyle.fourthGrid].join(" ")}>
                                        {/* <div className={combainstyle.mobileHeader} >{table[3].header}</div> */}
                                        {value.CurrentMarketValuation != undefined &&

                                          <div style={{ gap: 10 }}
                                            className={[
                                              combainstyle.directionColumn,
                                              combainstyle.subCatgoryValue,
                                              combainstyle.currentValueColumn,
                                              // combainstyle.hideDiv
                                            ].join(" ")}
                                          >
                                            {value.CurrentMarketValuation != undefined &&

                                              <div>
                                                <div className={combainstyle.hideForDesktopDiv} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10 }} > {"current value".toUpperCase()} </div>

                                                <span className={combainstyle.subcategoryRupee}>
                                                  {Label.currency.Rupee}
                                                </span>
                                                {NegativeFinding(value.CurrentMarketValuation, "", "", "", "number")}
                                              </div>}
                                            {value.currentValuePercentage != undefined &&
                                              <div>
                                                <Badges
                                                  color={window.globalConfig.color.ZERO_BACKGROUND}
                                                  text={`${value.currentValuePercentage} ${this.props.totaltext}`}
                                                  textcolor={window.globalConfig.color.LABLE_GRAY}
                                                />
                                              </div>}
                                          </div>}
                                      </div>
                                      {value.AbsoluteGainOrLoss != undefined && <div className={[combainstyle.alignEnd, "schemeFifthGrid", combainstyle.fifthGrid].join(" ")}>
                                        {/* <div className={combainstyle.mobileHeader} >{table[4].header}</div> */}

                                        <div style={{ gap: 10 }}
                                          className={[
                                            combainstyle.directionColumn,
                                            combainstyle.subCatgoryValue,
                                            combainstyle.unrealisedColumn,
                                          ].join(" ")}
                                        >
                                          {value.AbsoluteGainOrLoss != undefined &&
                                            <div style={{ color: NegativeFinding(value.AbsoluteGainOrLoss) }}>
                                              <span className={combainstyle.subcategoryRupee}>
                                                {Label.currency.Rupee}
                                              </span>
                                              {NegativeFinding(value.AbsoluteGainOrLoss, "", "", "", "number")}
                                            </div>}
                                          {this.props.flag != "equity" && value.DividendPayout != undefined &&
                                            <div style={{ color: NegativeFinding(value.DividendPayout) }}>
                                              <span className={combainstyle.subcategoryRupee}>
                                                {Label.currency.Rupee}
                                              </span>
                                              {NegativeFinding(value.DividendPayout, "", "", "", "number")}
                                            </div>}
                                          {this.props.flag == "equity" && value.Dividend != undefined &&
                                            <div style={{ color: NegativeFinding(value.DividendPayout) }}>
                                              <span className={combainstyle.subcategoryRupee}>
                                                {Label.currency.Rupee}
                                              </span>
                                              {NegativeFinding(value.Dividend, "", "", "", "number")}
                                            </div>}
                                        </div>
                                      </div>}
                                      <div className={[combainstyle.alignEnd, combainstyle.startDate, "schemeSixthGrid", combainstyle.sixthGrid].join(" ")}>
                                        {value.NetPL != undefined &&

                                          <div
                                            className={[
                                              combainstyle.directionColumn,
                                              combainstyle.subCatgoryValue,
                                              combainstyle.netColumn,

                                              ""
                                            ].join(" ")}
                                            style={{ color: NegativeFinding(value.NetPL), gap: 10 }}
                                          >
                                            {value.NetPL != undefined &&
                                              <div className={combainstyle.hideDiv}>
                                                <span className={combainstyle.subcategoryRupee}>
                                                  {Label.currency.Rupee}
                                                </span>
                                                {NegativeFinding(value.NetPL, "", "", "", "number")}
                                              </div>}
                                            {value.XIRR != undefined &&
                                              <div >
                                                <Badges
                                                  textcolor={NegativeFinding(
                                                    value.XIRR,
                                                    "",
                                                    "",
                                                    window.globalConfig.color.ZERO_BACKGROUND_TEXT
                                                  )}
                                                  color={NegativeFinding(
                                                    value.XIRR,
                                                    window.globalConfig.color.LIGHT_GREEN_BACKGROUND,
                                                    window.globalConfig.color.LIGHT_RED_BACKGROUND,
                                                    window.globalConfig.color.ZERO_BACKGROUND
                                                  )}
                                                  text={NegativeFinding(value.XIRR, "", "", "", "percentage")}
                                                />
                                              </div>}
                                          </div>
                                        }
                                      </div>

                                      {value.AbsoluteGainORLossPercentage != undefined &&
                                        <div className={[combainstyle.alignEnd, "schemeThirdGrid"].join(" ")} >
                                          <div className={combainstyle.hideForDesktopDiv} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10 }}> {this.props.units.toUpperCase()}
                                            {this.props.absRet != undefined && (
                                              this.props.absRet)}</div>
                                          {value.AbsoluteGainORLossPercentage != undefined &&
                                            <div
                                              className={[
                                                combainstyle.directionColumn,
                                                combainstyle.subCatgoryValue,
                                                combainstyle.unitsColumn,
                                              ].join(" ")}
                                            >
                                              {NegativeFinding(value.AbsoluteGainORLossPercentage, "", "", "", "percentage")}

                                            </div>}
                                          {/* {value.AnnualReturn != undefined &&
                                            <div
                                              className={[
                                                combainstyle.directionColumn,
                                                combainstyle.subCatgoryValue,
                                                combainstyle.unitsColumn,
                                              ].join(" ")}
                                            >
                                              {NegativeFinding(value.AnnualReturn, "", "", "", "percentage")}

                                            </div>} */}
                                        </div>}

                                      <div className={[combainstyle.alignEnd, combainstyle.startDate, "myLastGrid", combainstyle.footerGrid].join(" ")} >
                                        {value.PurchaseAllowed == false && value.RedemptionAllowed == false && value.STPAllowed == false && value.SWPAllowed == false && value.SwitchAllowed == false
                                          ?

                                          <> </> : <>
                                            {this.props.flag == "mutualfunds" && window.globalConfig.isTradeAllowed &&

                                              (
                                                <MoreVertIcon className={combainstyle.iconShowDesktop} onClick={(e) => this.handleClick(e, value)}></MoreVertIcon>
                                              )}
                                          </>
                                        }
                                      </div>
                                    </div>
                                  )
                                })
                              }

                            </div >
                          )
                        })}
                          <div style={{ padding: "0px 10px" }} className={[combainstyle.portfolioXraySevenSplitContainer, , combainstyle.displayTotalContainer].join(" ")}>

                            <div

                              className={[
                                combainstyle.schemeNameColumn,
                                combainstyle.directionColumn,
                              ].join(" ")}
                            >
                              {Label.messages.grandtotal}
                              <span>

                                {this.props.schemeList.length > 1
                                  ? `${this.props.schemeList.length} ${this.props.header}s`
                                  : `${this.props.schemeList.length} ${this.props.header}`}
                              </span>

                            </div>
                            {this.props.hideGrandTotalUnitsColumn == undefined || this.props.hideGrandTotalUnitsColumn &&
                              <div className={[combainstyle.unitsColumn]}></div>}

                            {this.props.xray[0]?.TotalValuetAtCost != undefined && <div className={[combainstyle.investedValueColumn, combainstyle.hideDiv].join(" ")}>
                              <span className={combainstyle.totalRupee}>
                                {Label.currency.Rupee}
                              </span>
                              {NegativeFinding(this.props.xray[0]?.TotalValuetAtCost, "", "", "", "number")}
                            </div>}
                            <div
                              className={[
                                combainstyle.currentValueColumn,
                                combainstyle.directionColumn,
                                combainstyle.hideDiv
                              ].join(" ")}
                            >
                              <div>
                                <span className={combainstyle.totalRupee}>
                                  {Label.currency.Rupee}
                                </span>
                                {NegativeFinding(List[0]?.TotalMarketValue, "", "", "", "number")}
                              </div>
                              {List[0]?.currentValuePercentage != undefined && <div>
                                <Badges
                                  color={window.globalConfig.color.LABLE_GRAY}
                                  text={`${List.length === 0
                                    ? NegativeFinding(0, "", "", "", "percentage")
                                    : NegativeFinding(100, "", "", "", "percentage")
                                    }`}
                                />
                              </div>}
                            </div>
                            {List[0]?.TotalAbsoluteGainOrLoss != undefined && <div
                              className={[
                                combainstyle.unrealisedColumn,
                                combainstyle.directionColumn,
                                combainstyle.hideDiv
                              ].join(" ")}
                            >
                              <div>
                                <span className={combainstyle.totalRupee}>
                                  {Label.currency.Rupee}
                                </span>
                                <span
                                  style={{
                                    color: NegativeFinding(
                                      parseInt(
                                        List[0]?.TotalAbsoluteGainOrLoss)
                                    ),
                                  }}
                                >
                                  {NegativeFinding(List[0]?.TotalAbsoluteGainOrLoss, "", "", "", "number")}
                                </span>
                              </div>
                              <div>
                                <Badges
                                  color={NegativeFinding(
                                    parseInt(
                                      List[0]?.TotalDividend
                                    ),
                                    "",
                                    "",
                                    window.globalConfig.color.LABLE_GRAY
                                  )}
                                  textcolor={window.globalConfig.color.WHITE}
                                  text={`${NegativeFinding(List[0]?.TotalDividend, "", "", "", "rupee")}`}
                                />
                              </div>
                            </div>}
                            {List[0]?.TotalNetPl != undefined && <div
                              className={[
                                combainstyle.netColumn,
                                combainstyle.directionColumn,
                                // combainstyle.hideDiv
                              ].join(" ")}
                            >
                              <div>
                                <span className={combainstyle.totalRupee}>
                                  {Label.currency.Rupee}
                                </span>
                                <span
                                  style={{
                                    color: NegativeFinding(
                                      parseInt(List[0]?.TotalNetPl)
                                    ),
                                  }}
                                >
                                  {NegativeFinding(List[0]?.TotalNetPl, "", "", "", "number")}
                                </span>
                              </div>
                              {List[0]?.TotalXIRR != undefined && <div>
                                <Badges
                                  color={NegativeFinding(
                                    parseInt(
                                      List[0]?.TotalXIRR,
                                      "",
                                      "",
                                      window.globalConfig.color.LABLE_GRAY
                                    )
                                  )}
                                  textcolor={window.globalConfig.color.WHITE}
                                  text={`${NegativeFinding(List[0]?.TotalXIRR, "", "", "", "percentage")}`}
                                />
                              </div>}

                            </div>}
                            <div className={combainstyle.hideDiv}>

                            </div>
                            <div className={combainstyle.hideDiv}>

                            </div>

                          </div>
                        </>


                      }}
                    </MContext.Consumer>
                  </Pagination>



                </ErrorBoundary>}
              <div
                onKeyUp={() => this.handleClose()}
                onKeyDown={() => this.handleClose()}
                onWheel={() => this.handleClose()}
              >
                {this.props.flag != "equity" && (
                  <Popover

                    open={Boolean(this.state.anchorEl)}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  //   disablePortal={true}
                  >
                    <div style={{ width: 200 }}>
                      {this.state.show ? <div style={{ padding: 10 }}> <Loader page="loadermandate" size={10} ></Loader></div> : <div>
                        {this.state.purchasemore == true && (
                          <>
                            <ListItem
                              button
                              selected={this.selectedIndex === 1}
                              className="popoverlist"
                              onClick={(event) => this.handleListItemClick(event, 1)}
                            >
                              <ListItemText primary={"Purchase"} />
                              {this.state.show && (
                                <CircularProgress
                                  size={10}
                                  thickness={1}
                                  style={{ color: window.globalConfig.color.LABLE_GRAY, position: "absolute" }}
                                />
                              )}
                            </ListItem>
                            <hr className="divider" />
                          </>
                        )}

                        {this.state.Redemption == true && (
                          <>

                            <ListItem
                              button
                              selected={this.selectedIndex === 2}
                              className="popoverlist"
                              onClick={(event) => this.handleListItemClick(event, 2)}
                            >
                              <ListItemText primary={"Redeem"} />
                            </ListItem>
                            <hr className="divider" />
                          </>
                        )}
                        {this.state.swp && (
                          <>

                            <ListItem
                              button
                              selected={this.selectedIndex === 3}
                              className="popoverlist"
                              onClick={(event) => this.handleListItemClick(event, 3)}
                            >
                              <ListItemText primary={"SWP"} />
                            </ListItem>
                            <hr className="divider" />
                          </>
                        )}
                        {this.state.stp && (
                          <>

                            <ListItem
                              button
                              selected={this.selectedIndex === 4}
                              className="popoverlist"
                              onClick={(event) => this.handleListItemClick(event, 4)}
                            >
                              <ListItemText primary={"STP"} />
                            </ListItem>
                            <hr className="divider" />
                          </>
                        )}
                        {this.state.switch && (
                          <ListItem
                            button
                            selected={this.selectedIndex === 5}
                            className="popoverlist"
                            onClick={(event) => this.handleListItemClick(event, 5)}
                          >
                            <ListItemText primary={"Switch"} />
                          </ListItem>
                        )}
                      </div>}
                    </div>
                  </Popover>
                )}
              </div>
              <Deletepopup open={this.state.waitScreen} showdefault={false}>
                <div style={{ display: "flex", flexDirection: "column", width: 252, overflow: "hidden", gap: 10, padding: 10 }}>
                  <p style={{ fontSize: 14, textAlign: "center", margin: 0, }}>
                    Please wait..
                  </p>
                  {/* <div> */}
                  <Loader page={"floatingdot"}></Loader>
                  {/* </div> */}
                  <div style={{ fontSize: "smaller", color: window.globalConfig.color.PRIMARY_BORDER }}>
                    We are redirecting you in few seconds...
                  </div>
                </div>
              </Deletepopup>
              <Deletepopup header={`Transactions for ${this.state.selectedList.Scheme}  ${this.state.selectedList.CategoryFolioNo == undefined || this.state.selectedList.CategoryFolioNo == "NA" ? "" : " - " + this.state.selectedList.CategoryFolioNo}`} className={classes.popup} onClose={() => { this.setState({ transactionPopup: false }) }} open={this.state.transactionPopup} showdefault={false}>
                <div style={{ padding: 10 }}>
                  <Epfreport showNoDataAvailable={true} loader={this.state.loaderSchemeWiseHolding} showHeader={false} title={this.props.flag == labels.ManuHeading.familyPortfolio ? FamilyTransactionListTitle : TransactionListTitle} singleScheme={"Transaction"} multipleScheme={"Transactions"} grid={this.props.flag == labels.ManuHeading.familyPortfolio ? classes.schemeFamilyTransactionDetails : classes.schemeTransactionDetails} data={this.state.transactionData}></Epfreport>
                </div>
              </Deletepopup>
              <FolioPopup
                onClick={this.folioContinue}
                onClose={this.onClose}
                openFolioPopup={this.state.openFolioPopup}
                folioNumbers={this.state.folioNumberList}
              />
              <CommonSnackbar
                open={this.state.open}
                severity={"warning"}
                handlesnanckClose={this.handlesnanckClose}
                message={this.state.message}
              />
              <CommonDialogBox
                flag="primary"
                open={this.state.openAccount}
                handleClose={this.handleAccountClose}
                onClick={this.handleAccountClose}
              />
              {/* <Scrolltotop /> */}
            </div>
          }
        </ErrorBoundary >
      );
    } catch (err) {
      return <Redirect to={labels.Path.NotFound} />;
    }
  }
}
const mapStateToProps = (state) => {
  //////
  return {
    sessionID: state.Login.sessionID,
    uccList: state.ProfileDetails.AllProfileDetails.length != 0 && state.ProfileDetails.AllProfileDetails?.uccList != undefined && state.ProfileDetails.AllProfileDetails?.uccList?.length != 0 ? state.ProfileDetails.AllProfileDetails.uccList != "NA" ? state.ProfileDetails.AllProfileDetails.uccList?.filter(
      (ele, ind) =>
        ind ===
        state.ProfileDetails.AllProfileDetails.uccList.findIndex((elem) => elem.ClientCode === ele.ClientCode)
    ).map((item) => {
      return { ...item, "Name": item.ClientCode }
    }) : [] : [],
    profileUcc: state.ProfileDetails.AllProfileDetails?.uccList,
    clientID: state.Login.logindetails.ClientID,
    userID: state.Login.userID,
    date: state.Login.date,
    L5ID: state.Login.L5ID,
    logindetails: state.Login.logindetails,
    userDetails:
      state.ProfileDetails.AllProfileDetails.length != 0
        ? state.ProfileDetails.AllProfileDetails.mainDetails != "NA"
          ? state.ProfileDetails.AllProfileDetails.mainDetails
          : []
        : [],
    permissionList: state.Login.companyDetails.length != 0 ? state.Login.companyDetails[0] : [],
    dropdowndetails: state.Dropdown.dropdowndetails?.scheme
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      GetPortfolioXray,
      equityFilterChange,
      SchemeChange,
      TypeChange,
      xrayfilterChange,
      RedemptionChange,
      portfolioXraySortByReturns,
      portfolioXraySortByAtoZ,
      portfolioXraySortByMarketValue,
      filterChange,
    },
    dispatch
  );
};
const mergestyles = combineStyle(styles, commonstyletable);
export default withRouter(
  withStyles(mergestyles)(
    connect(mapStateToProps, mapDispatchToProps)(PortfolioXRay)
  )
);