import * as types from '../../constants/actionsType'
const initialState = {
    riaOnLinePaymentDetailsOrder: [],
    isRiaOnLinePaymentDetailsLoading: false
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RIA_ONLINE_PAYMENT_COMPLETE:  
      //   
            return {
                ...state,
                riaOnLinePaymentDetailsOrder: action.payload != null ? action.payload : state.riaOnLinePaymentDetailsOrder ,
                isRiaOnLinePaymentDetailsLoading: false
            }
        case types.LOADER_RIA_ONLINE_PAYMENT_COMPLETE:
            return {
                ...state,
                isRiaOnLinePaymentDetailsLoading: true
            }
        default:
            return state
    }
}
export default reducer