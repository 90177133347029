import moment from 'moment';
import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
var axios = require('axios');
/* name should be camel case in all function */
// function getTransactionOrderReportDetails(ClientID, SessionID, AsOnDate)
import Networking from '../../utils/api/apiaccess';
function getTransactionOrderReportDetails(ClientID, SessionID, AsOnDate) {       
    // let userDetails = secureStorage.getItem('user')

    let data = {};
    data.ClientID =  SessionID
    data.SessionID =ClientID
    data.AsOnDate = AsOnDate
    return Networking.PostApi(ApiValues.GetTransactionOrderReport , data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
   
}
export default getTransactionOrderReportDetails