import Networking from "../../utils/api/apiaccess";
import labels from "../../utils/constants/labels";
import Apivalues from '../url/apiurl'
function GetAllCartsBaskets(SessionID,ClientID)
{
    let BaseUrl = Apivalues.GetAllCartsBasket
    let data = {};
    data.ClientID = ClientID,
    data.SessionID = SessionID
    return Networking.PostApi(BaseUrl,data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
}
export default GetAllCartsBaskets