import * as types from '../../constants/actionsType';
import getsimilarSchemeDetails  from '../../../api/schemedetails/similarschemes';
import { parseJSON } from 'date-fns';
export function SimilarSchemeDetails(SchemeID) {
  return (dispatch) => {
    getAllSimilarSchemeDetails(dispatch,SchemeID);
  }
}
function getAllSimilarSchemeDetails(dispatch,SchemeID) {
    getsimilarSchemeDetails(SchemeID)
    .then((data) => {
      
     if(data.success==true){
          var SchemeData=JSON.parse(data.schemeData);
          dispatch({
            type: types.SIMILAR_SCHEMES,
            payload: SchemeData,
          }); 
     }
     else{
      dispatch({
        type: types.SIMILAR_SCHEMES,
        payload: [],
      }); 
     }
    })
    .catch((error) => {

    })
}


