/* name should be camel case in all function */
//const BaseUrl='https://jsonplaceholder.typicode.com/todos';
import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
function getAllSummaryDetails(ClientID, AsOnDate, SessionID,ProductID,FamilyID,L5ID,L4ID) {
  //  let BaseUrl="https://ms.zoifintech.com/Dashboard/GetSectorAnalysisAllocation"
  let data = {};
  data.ClientID = AsOnDate
  data.AsOnDate = SessionID
  data.SessionID = ClientID
  data.ProductID = ProductID
  data.FamilyID = FamilyID
  data.L5ID = L5ID
  data.L4ID = L4ID
  // var data = JSON.stringify({"ClientID":20319,"AsOnDate":"16 Feb 2021","SessionID":"ABE48CB1-C378-4DE8-A77A-035160F4C41C"});
  return Networking.PostApi(ApiValues.SectorAllocation, data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
}
export { getAllSummaryDetails, };
