import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
function getOrderStatusForInitPayment(data) {
  // var data = JSON.stringify({"ClientCode":Clientcode,"LogoutURL":`${window.globalConfig.Domain6}/payment`});


  return Networking.PostApi(ApiValues.CheckForInitPayment, data,labels.apiKey.apiKeys).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default getOrderStatusForInitPayment
