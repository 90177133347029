import moment from 'moment';
import Api from '../url/apiurl';
import secureStorage from '../../utils/securestorage'
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
function MandateRedirection(mandateID,UCC) {
    // let userDetails = secureStorage.getItem('user')
    let data = {};
    data.MandateID = String(mandateID),
        data.ClientCode = UCC

    // return fetch(Api.MandateRedirection, {
        
    //     method: 'POST',
    //     headers: {
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(data),
    // }).then((response) => response.json())
    //     //If response is in json then in success
    //     .then((responseJson) => {
    //         //   (responseJson)

    //         return responseJson
    //     })
    //     //If response is not in json then in error
    //     .catch((error) => {
    //         //Error 
    //         return { 'status': "F", "message": labels.messages.apierror }
    //     });
    
  return Networking.PostApi(Api.MandateRedirection,data,labels.apiKey.apiKeys).then(result => { return result })
  .catch(function (error) {
    return { "status": "F", "message": labels.messages.apierror }
  });
}
export default MandateRedirection
