import ApiValues from '../url/apiurl'
var axios = require('axios');
import Networking from '../../utils/api/apiaccess'
import labels from '../../utils/constants/labels';
/* name should be camel case in all function */
function getAllStocksandTradeDetails(data) {

    return Networking.PostApi(ApiValues.StocksandTrade, data)
        .then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }

           });

}
export default getAllStocksandTradeDetails
