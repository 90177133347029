//component created by dulcy
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import selectedcheckbox from '../../utils/assets/selectedcheckbox.png'
import clsx from 'clsx'
import colors from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import selectedTick from '../../utils/assets/images/img29.png'
const useStyles = makeStyles((theme) => ({

    listStyle: {
       // padding: "5px 0px",
        margin: "auto",
        height:'26px',
        maxWidth: "100% !important",
        fontSize: "0.6rem !important",
        fontWeight: 500,
        overflow: "hidden"
      },
      containeritem: {
        position: "relative",
        paddingLeft: "30px !important",
        cursor: "pointer",
        userSelect: "none",
        alignItems: "center",
        margin: 0,
        width: "auto !important",
        padding: "0.25rem 0px 0.25rem 33px !important",
        fontSize: "14px",
        display: "block",
        fontWeight: "normal",
        textAlign: "left"
      },
      selectedcheckmark: {
        position: 'absolute',
       // top: "4px",
        left: 0,
        height: "18px",
        width: "18px",
     //   marginLeft: '3px',
        borderRadius: '3px',
        backgroundColor: window.globalConfig.color.WHITE,
        border: `1px solid ${window.globalConfig.color.BROWN} !important`,
    
        //  transform:`rotate(${1}deg)`,
        background: `url(${selectedTick}) 4px 5px no-repeat`,
        cursor:"auto",
    
      },
      checkmark: {
        position: 'absolute',
       // top: "4px",
        left: 0,
        height: "18px",
        width: "18px",
       // marginLeft: '3px',
        borderRadius: '3px',
        backgroundColor: window.globalConfig.color.WHITE,
        border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER} !important`,
      },
      ullist:{
          padding:0,
          margin:0
      }
}));
export default function CommonCheckbox(props) {
    const classes = useStyles();
        return (
            <ul className={classes.ullist}>
                <li className={classes.listStyle} onClick={props.onChange}>
                    <label className={classes.containeritem} style={{color:props.checked?window.globalConfig.color.BLACK_PRIMARY:window.globalConfig.color.LABLE_GRAY}}>
                        {props?.label}
                        {props?.checked ?
                            <span className={classes.selectedcheckmark}>

                            </span> : <span className={classes.checkmark}></span>}
                    </label>
                </li>
            </ul>
        );
}
