import PropTypes from "prop-types";
import React, { Component } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles'
// import "./togglebutton.css";
import colors from "../../utils/colors/colors";
import labels from "../../utils/constants/labels";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom"; //import Select from '@material-ui/core/Select';
import secureStorage from "../../utils/securestorage";
const styles = (theme) => ({
  rowDirection: {
    display: "flex", flexDirection: "row",
    [theme.breakpoints.down('600')]: {
      display: "flex", flexDirection: "column", alignItems: "center",
       fontSize: "small"
    },
  },
  secLabel: {
    marginLeft: 8,
    [theme.breakpoints.down('600')]: {
      marginLeft: 0,
    },
  },
  toggleContainer:{
    width: "33px",
    backgroundColor: window.globalConfig.color.BROWN,
  
    userSelect: "none",
    borderRadius: "21px",
    padding: "2px",
    height: "14px",
    position: "relative",
  },
  disabledButton:{
    fontSize: "14px",
    lineHeight: "16px",
    fontWeight: "bold",
    cursor: "pointer",
    backgroundColor: "white",
    color: "white",
    padding: "1px 2px",
    borderRadius: "21px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    minWidth: "46px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "16px",
    height: "14px",
    marginBottom: "1px",
    minWidth: "unset",
    borderRadius: "21px",
    boxSizing: "border-box",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.25)",
    position: "absolute",
    left: "19px",
    transition: "all 0.3s ease"
  },
  disabled :{
    backgroundColor: window.globalConfig.color.WHITE,
    left: "2px"
  }
})
class ToggleButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  disableClick = () => {

  }
  render() {
    const { selected, toggleSelected, classes } = this.props;
    return (
      <>
        <div className={classes.rowDirection} style={{ opacity: this.props.disable ? 0.5 : 1, cursor: this.props.disable ? "not-allowed" : "pointer" }}>

          <div>
            <span style={{ color: selected ?window.globalConfig.color.PRIMARY_BORDER: window.globalConfig.color.BLACK_PRIMARY, marginRight: 8 }}>
              {this.props.label1.length>=20?<Tooltip
                            TransitionComponent={Zoom}
                            title={this.props.label1}
                          >
                            <label style={{ textOverflow: "ellipsis" }}>
                              {Object.keys(this.props.label1).length === 0
                                ? labels.messages.NA
                                : this.props.label1.substring(0, 20 - 3) + "..."}
                            </label>
                          </Tooltip>:this.props.label1}</span>
          </div>
          <div className={classes.toggleContainer} onClick={this.props.disable ? this.disableClick : toggleSelected}>
            <div className={[classes.disabledButton,selected ? "" : classes.disabled].join(" ")}>
              {selected ? "" : ""}
            </div>
          </div>
          <div>
            <span style={{ color: !selected ? window.globalConfig.color.PRIMARY_BORDER: window.globalConfig.color.BLACK_PRIMARY }} className={classes.secLabel}>{this.props.label2.length>=20?<Tooltip
                            TransitionComponent={Zoom}
                            title={this.props.label2}
                          >
                            <label style={{ textOverflow: "ellipsis" }}>
                              {Object.keys(this.props.label2).length === 0
                                ? labels.messages.NA
                                : this.props.label2.substring(0, 20 - 3) + "..."}
                            </label>
                          </Tooltip>:this.props.label2}</span>
          </div>
        </div>
      </>
    );
  }
}
ToggleButton.propTypes = {
  selected: PropTypes.bool.isRequired,
  toggleSelected: PropTypes.func.isRequired
};
export default withStyles(styles)(ToggleButton)

