import React from 'react';
import Typography from "@material-ui/core/Typography";
import PortfolioTable from '../../components/table/portfoliotable';
import Portfoliotab from '../../components/tab/portfoliotab';
import Grid from '@material-ui/core/Grid';
import Portfoliochart from '../../components/chart/portfoliochart';
import Portfolioblock from '../../components/block/portfolioblock';
import CircularProgress from "@material-ui/core/CircularProgress";
import { scrollToTopPage } from '../../utils/commonfunction';
import Label from '../../utils/constants/labels'
import Tollbar from '../../components/toolbar/toolbar';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Loader from '../../components/loader/loader'
import NoData from '../../components/nodata/nodata'
import { withRouter } from "react-router";
import secureStorage from '../../utils/securestorage'
import { bindActionCreators } from 'redux';
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import Toggle from "../../components/toggle/toggle";
import TreeTable from '../../components/expandable-table/expandabletable'
import pdfImage from "../../utils/assets/images/pdf-file-format-symbol-2.png";
import CommonSnackbar from '../../components/snackbar/snackbar'
import { replaceNull, NegativeFinding } from '../../utils/commonfunction';
import { getPortfolio, GETPortfolioDetail } from '../../redux/actions/portfolio/portfolioaction';
import colors from '../../utils/colors/colors';
import getAllcommonDownloadDetails from '../../api/commondownload/commondownload';
import apiurl from '../../api/url/apiurl';
import Cardportfolio from '../../components/card/cardportfolio';
import getBenchMarkDetails from '../../api/portfolio/benchmarkapi';
import getBenchMarkData from '../../api/basket/benchmarkdata';
import { Dialog } from '@material-ui/core';
import Deletepopup from '../../components/popup/deletepopup';
// import { enableLoaderForAllPages } from '../../redux/actions/login/loginresetsuccessaction';
const styles = theme => ({
    root: {
        marginTop: "60px",
        display: 'flex',
        width: '100%',
        [theme.breakpoints.down('900',)]: {
            width: "95%"
        },
        // [theme.breakpoints.down('350',)]: {
        //     marginTop: '63%',
        // },
    },
    label: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: 18,
        alignItems: 'flex-start',
        fontWeight: 'bold',
        marginLeft: '1%',
        [theme.breakpoints.down('xs',)]: {
            marginLeft: '5px',
        },
    },
    container: {
        marginTop: 15,
        width: '100%',
        [theme.breakpoints.down('xs',)]: {
            marginTop: '0px',
        },
    },
    PdfImagestyle: {
        height: 35,
        width: 35,
        cursor: "pointer",
        [theme.breakpoints.down("600")]: {
            width: 28,
        },
    },
    tableval: {
        color: window.globalConfig.color.BLACK_PRIMARY, fontWeight: 'bold',
        fontSize: 18,
    },
    removeborder: {
        borderRight: "0px !important",
        align: "left",
        marginTop: -25,
        [theme.breakpoints.down('xs',)]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: 0,
            borderBottom: "0px !important ",
        },
        [theme.breakpoints.down('sm',)]: {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginTop: 0,
        },
    },

    flex1: {
        flex: 1.5
    },
    flex8: {
        flex: 11,
        width: '100%',
        [theme.breakpoints.down('600',)]: {
            flex: '100%',
            width: '100%',
        },
    },
    portfoilotabresponsive: {
        width: '100%'
    },
    portfoliochartResponsive: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    toolbarcard: {
        position: 'relative',
    },
    checkBorder: {
        width: "15px",
        height: " 15px",
        margin: 10,
        marginTop: 5,
    },
    checkBox: {
        width: "11px",
        height: "11px",
        textAlign: 'center',
        // position: 'absolute',
        margin: 2
    },
    pdficon: {
        display: "flex",
        position: 'relative',
        flexDirection: "column",
        alignItems: "end",
        [theme.breakpoints.down("600")]: {
            paddingRight: "5px",
        }
    },
    assetAllocationContainer: {
        display: "grid", gridTemplateColumns: "37% 28% 22% 13%",
        padding: 17,
        fontSize: "large",
        fontWeight: 500,
        "& .dataTwoSpecialChar": {
            fontSize: "1rem",
            color: colors.PRIMARY_BORDER
        },
        "& .dataTwo": {
            alignItems: "center",
            gap: 2
        },
        [theme.breakpoints.down('920',)]: {
            gridTemplateColumns: "50% 50%",
            gridTemplateAreas: `"dataOne dataTwo"
            "dataThree dataFour"`
        }
    },
    subAssetAllocationContainer: {
        display: "grid", gridTemplateColumns: "37% 26% 24% 13%",
        padding: "14px 40px",
        paddingRight: "19px",
        [theme.breakpoints.down('920',)]: {
            gridTemplateColumns: "50% 50%",
            gridTemplateAreas: `"subDataOne subDataTwo"
            "subDataThree subDataFour"`
        }
    },
    productAllocationContainer: {
        display: "grid", gridTemplateColumns: "37% 28% 22% 13%",
        [theme.breakpoints.down('920',)]: {
            gridTemplateColumns: "50% 50%"
        }
    },
    nonFinancialContainer: {
        display: "grid", gridTemplateColumns: '31% 31% 31%', justifyContent: "space-around", gap: 10, marginTop: 10
    }
});
let data = [];
class Portfolio extends React.Component {
    constructor() {
        super();
        this.state = {
            selected: false,
            open: false,
            severity: "warning",
            message: "",
            loader: false, assetData: [], nonFinancial: [],
            card: [],
            BenchMarkID: 0, loadBenchMark: false, benchMarkData: [], openBenchMark: false
        }
    }
    componentDidMount() {
        //   this.props.enableLoaderForAllPages(true)
        // this.benchMarkDataList()
        document.title = window.globalConfig.companyDetails.companyName + " - Portfolio"
        scrollToTopPage();
        data = this.props.productChart
        this.assetAllocationData(this.props.categorySummary)
        this.nonFinancialData(replaceNull(this.props.nonFinancial, "NA"))
        // this.benchAPI(0)
    }
    // bindBenchMark = (BenchMarkID) => {
    //     this.setState({
    //         card: [...this.props.mfHeader2, { firstName: "BenchMark Xirr", lastName: NegativeFinding(BenchMarkID, "", "", "", "percentage"), enableLink: true }]
    //     })
    // }
    // benchMarkDataList = () => {
    //     getBenchMarkData().then((response) => {
    //         let res = replaceNull(response, "NA")
    //         if (res.status == "S" && res.data != "NA") {
    //             this.setState({
    //                 benchMarkData: res.data.map((item) => {
    //                     return {
    //                         ...item,
    //                         label: item.BenchMarkName,
    //                         value: item.ID,
    //                         isChecked: false
    //                     }
    //                 }),
    //                 benchMarkID: res.data[0]?.ID, benchMarkName: res.data[0]?.BenchMarkName
    //             })
    //         } else {
    //             this.setState({ benchMarkData: [] })
    //         }
    //     })
    // }
    // openBenchMarkPopup = () => {
    //     this.setState({ openBenchMark: true })
    // }
    // onChangeBenchMark = (data) => {
    //     this.setState({ BenchMarkID: data.label })
    //     this.setState({
    //         benchMarkData: this.state.benchMarkData.map((item) => {
    //             return { ...item, isChecked: item.label == data.label }
    //         })
    //     })
    //     this.benchAPI(data.value)
    // }
    // benchAPI = (BenchMarkID) => {
    //     this.setState({ loadBenchMark: true })
    //     getBenchMarkDetails(this.props.logindetails.SessionID, this.props.logindetails.ClientID, this.props.date, 0, 0, this.props.L5ID, 0, 0, BenchMarkID).then((res) => {
    //         this.setState({ loadBenchMark: false })
    //         if (res.status == "S") {
    //             this.bindBenchMark(res.data[0].XIRR)
    //         } else {
    //             this.bindBenchMark(0)
    //         }
    //     })
    //     this.setState({ openBenchMark: false })
    //     // this.openBenchMarkPopup()
    // }

    assetAllocationData = (data) => {
        if (data.length != 0) {
            this.setState({
                assetData: data.map((item) => {
                    return {
                        dataOne: item.category, dataTwo: NegativeFinding(item.amount, "", "", "", "rupee"), dataThree: item.categoryPercentage, dataFour: item.holdingPercentage, isExpand: item.isExpand, showCheckBox: true, color: item.color, subData: item.rowitem.map((value) => {
                            return { dataOne: value.category, dataTwo: NegativeFinding(value.amount, "", "", "", "rupee"), dataThree: NegativeFinding(value.categoryPercentage, '', "", "", "percentage"), dataFour: value.holdingPercentage }
                        })
                    }
                })
            })
        } else {
            this.setState({ assetData: [] })
        }
    }
    nonFinancialData = (data) => {
        if (data.length != 0 && data != "NA") {
            let removeZero = data?.filter((item) => item.CurrentValue != 0 && item.CurrentValue != "NA")
            if (removeZero.length != 0) {
                if (removeZero.filter((item) => item.ScripName.toLowerCase().includes("other investments")).length != 0) {
                    this.setState({
                        nonFinancial: [...removeZero.filter((val) => val.ScripName == "Other Investments").map((item) => {
                            return { label: item.ScripName, value: NegativeFinding(item.CurrentValue, "", "", "", "rupee") }
                        }), ...removeZero.filter((val) => val.ScripName != "Other Investments").map((item) => {
                            return { label: item.ScripName, value: NegativeFinding(item.CurrentValue, "", "", "", "rupee") }
                        })]
                    })
                } else {
                    this.setState({ nonFinancial: [] })

                }

            }
        } else {
            this.setState({ nonFinancial: [] })
        }
    }
    componentDidUpdate(prevProps) {
        const { clientID, date, sessionID, isLoadingportfolioChart, categorySummary, portfolioSummary, nonFinancial } = this.props;
        if (prevProps.clientID !== clientID || prevProps.date !== date || prevProps.sessionID !== sessionID) {
            this.props.getPortfolio(sessionID, clientID, date);
            // this.benchAPI(0)
        }
        if (prevProps.nonFinancial != nonFinancial) {
            this.nonFinancialData(nonFinancial)
        }

        if (prevProps.categorySummary != categorySummary) {
            this.assetAllocationData(categorySummary)
        }

    }
    togglechange = () => {
        this.setState({ selected: !this.state.selected })
    }
    onClickPdf = () => {
        this.setState({ loader: true })
        getAllcommonDownloadDetails(`${apiurl.ExportAssetWisePortfolioAllocation}clientId=${this.props.clientID}&asOnDate=${this.props.date}&ProductID=1&FamilyID=0&L5ID=${this.props.L5ID}&L4ID=0&withredemption=true&sinceInception=false&folioNo=0&fromDate=${this.props.date}`, "pdf", "Portfolio.pdf", this.props.logindetails?.SessionID).then((response) => {
            this.setState({ loader: false })
            if (response != "success") {
                this.setState({ open: true, message: labels.messages.apierror, severity: "warning" })
            }
        })
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };

    render() {
        const { classes } = this.props;
        data = this.state.selected ? this.props.assetChart : this.props.productChart
        // this.props.productChart.map((item) => {
        // })
        try {
            return (
                <React.Fragment>
                    <Tollbar childFunc={this.props.childFunc} productID={0} title={Label.menu.tabname} data={this.props.mfHeader2} className={classes.toolbarcard} style={{ top: -5 }} />
                    <div className={classes.root}>
                        <div className={classes.flex1}>
                        </div>
                        <div className={classes.flex8}>

                            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                <div style={{ display: "flex", flexDirection: "row", marginTop: "1%", justifyContent: "space-between" }}>
                                    <Typography variant="h2" align='left' className={[classes.label, "portfolioSizeChange"].join(' ')}>{Label.portfolio.title}</Typography>
                                    <div style={{ display: "flex", flexDirection: "row", gap: 10, alignItems: "center" }}>
                                        {window.globalConfig.enableEquity && <Toggle
                                            label2={"Asset Allocation"}
                                            label1={"Product Wise"}
                                            selected={this.state.selected}
                                            toggleSelected={this.togglechange}
                                        />}
                                        {window.globalConfig.enableEquity && <div className={classes.pdficon}>
                                            {this.props.categorySummary.length == 0 && this.props.portfolioSummary.length == 0 ? <img
                                                src={pdfImage}
                                                style={{ opacity: 0.5 }}
                                                className={classes.PdfImagestyle}

                                            /> :
                                                <img
                                                    src={pdfImage}
                                                    style={{ cursor: "pointer", pointerEvents: this.state.loader ? "none" : "auto", opacity: this.state.loader ? 0.5 : 1 }}
                                                    className={classes.PdfImagestyle}
                                                    onClick={this.onClickPdf}

                                                />}
                                            {this.state.loader && <CircularProgress
                                                size={30}
                                                thickness={1}
                                                style={{
                                                    color: window.globalConfig.color.LABEL_GRAY,
                                                    position: "absolute",
                                                }}
                                            />}
                                        </div>}
                                    </div>
                                </div>

                            </div>
                            <Grid className={classes.container}>
                                <Grid container className="portfolioChartSheets" style={{ justifyContent: "flex-end" }}>
                                    <Grid item md={4} xs={12} sm={12} className={classes.portfoliochartResponsive}>
                                        {this.props.loader ?
                                            <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
                                                <Loader page="chartnormal" />
                                            </div>
                                            :
                                            <>
                                                {this.state.selected ? this.props.assetChart.length > 0 && this.props.assetChart.filter((item) => item.value == 0).length != this.props.assetChart.length ?
                                                    <Portfoliochart loader={this.props.loader} data={this.props.assetChart.filter((ele, ind) => ind === replaceNull(this.props.assetChart, "NA").findIndex(elem => elem.name === ele.name))} />
                                                    : <div style={{ width: '86%', textAlign: 'center', border: `1px solid ${window.globalConfig.color.BORDER}`, borderRadius: 8, height: 235, }}>
                                                        <NoData height={15} />
                                                    </div> :
                                                    this.props.productChart.length > 0 && this.props.productChart.filter((item) => item.value == 0).length != this.props.productChart.length ?
                                                        <Portfoliochart loader={this.props.loader} data={this.props.productChart.filter((ele, ind) => ind === replaceNull(this.props.productChart, "NA").findIndex(elem => elem.name === ele.name))} />
                                                        : <div style={{ width: '86%', textAlign: 'center', border: `1px solid ${window.globalConfig.color.BORDER}`, borderRadius: 8, height: 235, }}>
                                                            <NoData height={15} />
                                                        </div>

                                                }

                                            </>
                                        }
                                    </Grid>

                                    <Grid item md={8} sm={12} xs={12} >
                                        <div style={{ display: "flex", flexDirection: "column" }}>
                                            {this.state.selected ?
                                                (<TreeTable search={labels.currency.rupeesymbol} grid={{ mainGrid: classes.assetAllocationContainer, subGrid: classes.subAssetAllocationContainer }} summaryData={this.props.categorySummary} tableHeader={[Label.summaryPage.Asset, Label.summaryPage.Amount, Label.summaryPage.AssetPercentage, Label.summaryPage.holding]
                                                } flag={"portfolio"} grandtotal={[labels.messages.grandtotal, this.props.categorySummary.length == 0 ? 0 : this.props.categorySummary[0]?.grandTotal, "", this.props.categorySummary.length == 0 ? "0%" : "100.0%"]}
                                                    loader={this.props.loader} data={this.state.assetData} />
                                                ) : <PortfolioTable />}

                                        </div>
                                    </Grid>

                                    {!this.state.selected && !this.props.loader && this.state.nonFinancial.length != 0 &&
                                        <div style={{ marginTop: 10, marginBottom: 10, width: "-webkit-fill-available" }}>   <Cardportfolio data={this.state.nonFinancial}></Cardportfolio></div>

                                    }
                                    {/* {this.state.nonFinancial.map((item, index) => {
                                                    return (<div key={index} style={{ display: "flex", flexDirection: "row", backgroundColor: "#fff7f5", borderRadius: 10, padding: "10px 0px", border: "1px solid #e6e7ea" }}>
                                                        <div className={classes.checkBorder} style={{ border: `1px solid ${item.color}` }} >
                                                            <div className={classes.checkBox} style={{ backgroundColor: item.color }}></div>
                                                        </div>
                                                        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 10 }}>
                                                            <div className={classes.tableval}>{item.name}</div>
                                                            <div className={classes.tableval}>{NegativeFinding(item.investValue, "", "", "", "rupee")}</div>

                                                        </div>
                                                    </div>
                                                    )
                                                })} */}
                                    <Grid item md={12} className={classes.portfoilotabresponsive}>
                                        <Portfoliotab />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </div>
                        <div className={classes.flex1}></div>
                    </div>
                    <Grid item xs={12} className={classes.portfoilotabresponsive} >
                        <Portfolioblock />
                    </Grid>
                    <Deletepopup showdefault={false} header={"Select BenchMark"} open={this.state.openBenchMark} onClose={() => { this.setState({ openBenchMark: false }) }}>
                        {this.state.benchMarkData.map((item) => {
                            return <div onClick={() => { this.onChangeBenchMark(item) }} style={{ color: item.isChecked ? window.globalConfig.color.BROWN : window.globalConfig.color.BLACK_PRIMARY, backgroundColor: item.isChecked ? window.globalConfig.color.RosyBrown : "", padding: 5, cursor: "pointer" }}>
                                {item.label}
                            </div>
                        })}
                    </Deletepopup>
                    <CommonSnackbar open={this.state.open} severity={this.state.severity} handlesnanckClose={this.handlesnanckClose} message={labels.messages.apierror} />

                </React.Fragment >
            )
        } catch (err) {
            console.log(err)
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => ({
    sessionID: state.Login.sessionID,
    clientID: state.Login.logindetails.ClientID,
    loader: state.Portfolio.isLoading,
    date: state.Login.date,
    L5ID: state.Login.L5ID,
    mfHeader2: state.Card.mfHeader2,
    nonFinancial: state.Portfolio.nonFinancial,

    portfolioSummary: state.Portfolio.productTable,
    categorySummary: state.Portfolio.assetWisePortfolio,
    productChart: state.Portfolio.productChart,
    assetChart: state.Portfolio.assetChart,
    logindetails: state.Login.logindetails,
    grandtotalsummary: state.Summary.grandtotalIV,
    isLoadingportfolioChart: state.Sample.isLoadingportfolioChart,
    isLoadingportfolioSummary: state.Sample.isLoadingportfolioSummary,
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getPortfolio, GETPortfolioDetail,
        // enableLoaderForAllPages
    }, dispatch)
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Portfolio)));
