//fd form creation by yogitha
import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import labels from '../../utils/constants/labels';
import colors from '../../utils/colors/colors';
import FdForm from '../../components/fdDebentures/fixeddeposit';
import Subheader from '../../components/subheader/subheader';
const styles = theme => ({
});
class fdDebentures extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataEntryFlag:'',
        }
    }
    componentDidMount() {
       
      
    }
    render() {
        const { classes } = this.props;
        return (<>
                <Subheader heading={window.location.pathname == "/trade/FixedDeposit"?labels.productList.FD:window.location.pathname == "/Debentures" ?labels.productList.Debentures : labels.productList.physicalBonds} description={window.location.pathname == "/trade/FixedDeposit"? labels.Transact.fdsubContent :window.location.pathname == "/Debentures" ?labels.Transact.debenturessubContent:labels.Transact.bondsubContent} />
           
            <FdForm dataEntryFlag={this.props.location.pathname}/>
        </>

        )
    }
}
export default withStyles(styles)(fdDebentures);