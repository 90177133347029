import React, { useEffect } from 'react'
import colors from '../../utils/colors/colors';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
let x = ''
let y = ''
export default function App(props) {
  const [seconds, setSeconds] = React.useState(59);
  const [userSeconds, setUserSeconds] = React.useState(119);
  const [mins, setmins] = React.useState(1);
  const [secs, setsecs] = React.useState(59);
  const [eventDate, seteventDate] = React.useState(moment.duration().add({ minutes: 2, seconds: 0 }));
  const [otpTime, setotpTime] = React.useState(true);
  React.useEffect(() => {
    if (props.userInputSecond == "true") {
      if (userSeconds > 0) {
        // setTimeout(() => setUserSeconds(userSeconds - 1), 2000);
        updateTimer();
      }
    }
    else {
      if (seconds > 0) {
        y = setTimeout(() => setSeconds(seconds - 1), 1000);
      }
    }
    return () => { // ComponentWillUnmount in Class Component
      clearInterval(x)
      clearInterval(y)
    }
  });
  const updateTimer = () => {
    x = setInterval(() => {
      let Date = eventDate;
      if (Date <= 0) {
        clearInterval(x)
      } else {
        Date = Date.subtract("s", 1)
        const mins = Date.minutes()
        const secs = Date.seconds()
        setmins(mins)
        setsecs(secs)
        seteventDate(Date)
        if (secs.toString().length === 1) {
          setsecs("0") + secs
        }
        if (mins === 0 && secs === 0) {
          setotpTime(false);
        }
      }
    }, 2000)
  }
  const resend = () => {
    setSeconds(59)
    props.onclick()
  }
  return (
    <>

      <div style={{ padding: 8 }}>
        {seconds == 0 ? <div onClick={resend} style={{ color: window.globalConfig.color.brown, textDecorationLine: "underline", cursor: "pointer" }}>{props.text}</div> : seconds > 9 ? `00:${seconds}` : `00:0${seconds}`}
      </div>
    </>
  );
}