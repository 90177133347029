import { fade } from '@material-ui/core/styles/colorManipulator';
import cyan from '@material-ui/core/colors/cyan';
import grey from '@material-ui/core/colors/blueGrey';
import red from '@material-ui/core/colors/red';
// import deco from 'enl-images/decoration/hexaMonochrome.svg';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import color from "../../utils/colors/colors"
import colors from '../../utils/colors/colors';
const rootWraper = {
  display: 'flex',
  width: '100%',
  zIndex: 1,
  position: 'absolute'
};
const wrapper = theme => ({
  padding: theme.spacing(3),
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  [theme.breakpoints.up('lg')]: {
    padding: `${theme.spacing(6)}px ${theme.spacing(20)}px`,
  },
});
const styles = theme => ({
  root: {
    ...rootWraper
  },
  imagePlayStoreStyle: {
    width: 177,
    // opacity: 0.5,
    cursor: "pointer",
    [theme.breakpoints.down('xs')]: {
      width: 152
    },
  },
  imageAppStoreStyle: {
    width: 169,
    height: 45,
    marginTop: 12,
    // opacity: 0.5,
    cursor: "pointer",
    [theme.breakpoints.down('xs')]: {
      width: 130,
      height: 41,
      marginTop: 10
    },
  },
  subTitleNew: {
    color: colors.GREEN,
    fontWeight: 400,
    fontSize: 13,
    margin: 0,

    textTransform: "uppercase",
    [theme.breakpoints.down('sm')]: {
      fontSize: 15,
    }
  },
  titleNew: {
    color: color.LABLE_GRAY,
    fontWeight: 700,
    fontSize: 19,
    margin: 0,
    paddingTop: 5,
    letterSpacing: 1,
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    }
  },
  input: {
    font: "inherit",
    //color: "currentColor";
    maxWidth: "100%",
    border: 0,
    height: "1.1876em",
    margin: 0,
    display: "block",
    padding: "6px 0 7px 7px",
    minWidth: 0,
    background: "none",
    boxSizing: "content - box",
    marginLeft: "-2%",
    animationName: "mui - auto - fill - cancel",
    letterSpacing: "inherit",
    animationDuration: "10ms",
    // -webkit - tap - highlight - color: transparent;
  },

  rootFull: {
    ...rootWraper,
    height: '100%',
    width: '100%',
    // overflow:'scroll',
    borderRadius: '0px !important'
  },
  // rootSignupFull: {
  //   ...rootWraper,
  //   height: '100%',
  //   width:'100%',
  //  // overflow:'scroll',
  //   borderRadius:'0px !important'
  // },
  // root: { 
  //   height: '100vh', 
  // }, 
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      overflow: 'hidden'
    },
  },
  forget: {
    cursor: 'pointer'
  },
  errorMessage: {
    color: "red",
    fontSize: 14,
    [theme.breakpoints.down('md')]: {
      marginLeft: '41px !important'
    },
  },
  textFieldCursor: {
    caretColor: color.BROWN,
  },
  containerSide: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      overflow: 'hidden'
    },
  },
  paperWrap: {
    background: theme.palette.background.paper,
    padding: `${theme.spacing(6)}px ${theme.spacing(1)}px`,
  },
  // sideWrap: {
  //   ...wrapper(theme),
  //   height: '100%',
  //   borderRadius: 0,

  // },
  fullWrap: {
    ...wrapper(theme),
    height: '100%',
    borderRadius: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    '& $topBar': {
      width: '100%'
    }
  },
  image: {
    // backgroundColor: color.FreeSpeechBlue,
    backgroundColor: color.ZERO_BACKGROUND,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      flex: "100%",
      flexDirection: "row",
      boxShadow: 'revert',
      justifyContent: "revert",
      alignItems: "revert",
      backgroundSize: 'revert',
      // height: '44%',
      display: 'revert',
    }
  },
  icon: {},
  topBar: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    maxWidth: 480,
    marginLeft: 'auto',
    marginRight: 'auto',
    borderBottom: `1px solid ${theme.palette.divider}`,
    justifyContent: 'space-between',
    '& $icon': {
      margin: `0 ${theme.spacing(1)}px`
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      marginBottom: theme.spacing(3),
    }
  },
  divider: {
    textAlign: 'center',
    borderTop: `1px solid ${color.PRIMARY_BORDER}`,
    fontSize: 14,
    color: theme.palette.text.secondary,
    margin: `${theme.spacing(6)}px 0 ${theme.spacing(3)}px`,
    '& span': {
      background: theme.palette.background.paper,
      top: -10,
      position: 'relative',
      padding: '0 10px',
    }
  },
  outer: {},
  centerFlex: {},
  invert: {},
  brand: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    fontSize: 22,
    marginBottom: theme.spacing(2),
    color: color.WHITE,
    textDecoration: 'none',
    [theme.breakpoints.down('xs')]: {
      top: '45px !important',
    },
    '&$centerFlex': {
      justifyContent: 'center'
    },
    '&$outer': {
      color: color.WHITE,
    },
    [theme.breakpoints.down('md')]: {
      margin: `${theme.spacing(2)}px 0`
    },
    '& img': {
      width: 185,
      marginRight: 10,
      [theme.breakpoints.down('md')]: {
        width: '100%',
      },
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },
    '&$invert': {
      color: color.PRIMARY,
    }
  },
  hideShowContent: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
  headLogo: {
    textAlign: 'center',
    marginBottom: theme.spacing(4),
    '& $brand': {
      display: 'inline-block',
      color: theme.palette.text.primary
    },

  },

  formWrap: {
    [theme.breakpoints.up('sm')]: {
      padding: '0 100px'
    },
    [theme.breakpoints.up('md')]: {
      padding: '0 150px'
    },
  },
  pageFormWrap: {
    width: '100%',
    margin: `${theme.spacing(2)}px auto`,
    [theme.breakpoints.up('sm')]: {
      width: 480,
    },
  },
  pageFormSideWrap: {
    // margin: '0 auto',
    height: '100%',
    display: "flex",
    backgroundPosition: 'center',
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
    // [theme.breakpoints.only('sm')]: {
    //   width: 480,
    // },
  },
  signupFormLogin: {
    // marginTop: '2%',
    width: '89% !important',
    // [theme.breakpoints.down('600')]: {
    //     width:'100%'
    //   },
    [theme.breakpoints.up('xl')]: {
      marginTop: '40%',
      width: '89% !important',

    },
    [theme.breakpoints.up('lg')]: {
      marginTop: '12%',
      width: '89% !important',
    },

    [theme.breakpoints.down('sm')]: {
      marginTop: '30%',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '18%',
      width: '90% !important',
    },
  },
  signupFormLogincard: {
    // marginTop: '2%',
    width: '50% !important',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    // [theme.breakpoints.down('600')]: {
    //     width:'100%'
    //   },
    [theme.breakpoints.up('xl')]: {
      // marginTop: '40%',
      width: '50% !important',

    },
    [theme.breakpoints.up('lg')]: {
      // marginTop: '12%',
      width: '50% !important',
    },

    [theme.breakpoints.down('sm')]: {
      // marginTop: '30%',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: '18%',
      width: '90% !important',
    },
  },
  // styles added by yogitha
  formLogin: {
    // marginTop: '28%',
    width: '70% !important',
    // [theme.breakpoints.down('600')]: {
    //     width:'100%'
    //   },
    // [theme.breakpoints.up('xl')]: {
    //   // marginTop: '40%',
    //   width: '50% !important',

    // },
    // [theme.breakpoints.up('lg')]: {
    //   // marginTop: '24%',
    //   width: '50% !important',
    // },

    // [theme.breakpoints.down('sm')]: {
    //   // marginTop: '30%',
    //   width: '65% !important',
    // },
    [theme.breakpoints.down('900')]: {
      // marginTop: '13%',
      width: '90% !important',
    },
    // [theme.breakpoints.between('1250', '1300')]: {
    //   // marginTop: '27%',
    //   width: '50% !important',
    // },
  },

  formControl: {
    width: '100%',
    marginBottom: theme.spacing(1),
    // [theme.breakpoints.down('sm')]: {
    //   marginLeft:'10%',
    //   },
    // [theme.breakpoints.down('xs')]: {
    // marginLeft:'10%',
    // },
  },
  socmedLogin: {
    [theme.breakpoints.up('sm')]: {
      padding: '24px 100px 1px',
    },
    '& button': {
      padding: '4px 24px'
    }
  },
  LoginFormCard: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down('xs')]: {
      // top: '10px',
      borderRadius: 10,
      marginLeft: '2%',
      marginRight: '2%',
      marginTop: '-10%',
      // overflowX: 'hidden',

    }
  },
  socmedSideLogin: {
    padding: '24px 24px',
    margin: '0 auto',
    background: fade(theme.palette.text.disabled, 0.05),
    borderRadius: 40,
    display: 'flex',
    justifyContent: 'center',
    '& button': {
      padding: '4px 16px',
      margin: `0 ${theme.spacing(1)}px`,
      [theme.breakpoints.down('xs')]: {
        fontSize: 0
      },
      '& svg': {
        fill: theme.palette.common.white,
        [theme.breakpoints.up('sm')]: {
          marginRight: theme.spacing(1)
        }
      }
    },
    [theme.breakpoints.only('sm')]: {
      width: 480,
    },
  },
  userFormWrap: {
    width: '94%',
    [theme.breakpoints.up('md')]: {
      width: 720
    },
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(3)
    },
  },
  // sideFormWrap: {
  //   height: '100%',
  //   width: '45%',
  //   zIndex: 1,
  //   position: 'relative',
  //   [theme.breakpoints.down('sm')]: {
  //     width: '100%'
  //   },
  // },
  msgUser: {
    margin: `${theme.spacing(1)}px auto`,
    '& > div:first-child': {
      flex: 1
    },
    [theme.breakpoints.down('lg')]: {
      margin: '8px 1px',
      minWidth: '200px',
    },
    [theme.breakpoints.down('md')]: {
      margin: '8px 10px',
      minWidth: '36%',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '8px 8px',
      minWidth: '100px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '-8px 26px',
    },
  },
  fullFormWrap: {
    height: '100%',
    width: '100%'
  },
  title: {
    textAlign: "center", color: color.LABLE_GRAY,
    //  fontFamily:"Roboto-Black", 
    fontWeight: 700,
    fontSize: 32,
    margin: "0px 10px 5px 10px",
    textTransform: "uppercase",
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    }
  },
  subtitle: {
    fontSize: 14
  },
  subtitleBig: {
    color: theme.palette.text.secondary
  },
  titleColor: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('sm')]: {
      fontSize: '2.55em'
    }
  },
  opening: {
    // overflow: 'hidden',
    position: 'relative',
    '&:before': {
      content: "''",
      width: '100%',
      height: '100%',
      position: 'absolute',
      opacity: 0.1,
      //  background: `url(${deco}) no-repeat 230px 480px`
    },
    '& h1': {
      color: theme.palette.common.white,
      lineHeight: '3.6rem',
      display: 'block',
      [theme.breakpoints.down('md')]: {
        fontSize: 32,
        lineHeight: '48px'
      }
    },
    '& p': {
      color: theme.palette.common.white,
    }
  },
  openingWrap: {
    padding: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      justifyItems: 'flex-start',
      padding: '0px',
    }

  },
  openingHead: {
    // marginBottom: 200,
    marginBottom: "3.5%",
    marginLeft: '5%',
    marginRight: '10%',
    [theme.breakpoints.down('xs')]: {
      marginBottom: "100%",
      // width:'100%',
    }
  },
  openingFooter: {
    position: 'relative',
    display: 'flex',
    padding: theme.spacing(6),
    justifyContent: 'space-between',
    alignItems: 'center',
    '& a': {
      fontSize: 14,
      color: fade(theme.palette.common.white, 0.64),
      textDecoration: 'none'
    },
  },
  label: {},
  btnArea: {
    display: 'flex',
    justifyContent: 'center',
    margin: `21px 0 0px`,
    fontSize: 12,
    '& $label': {
      fontSize: 12,
      '& span': {
        fontSize: 12
      }
    },
    '& button': {
      //margin: `0 ${theme.spacing(1)}px`
      // margin: '0 -17%',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& button': {
        width: '100%',
        // margin: 5
        margin: `0 ${theme.spacing(1)}px`
      }
    },
  },
  noMargin: {
    margin: 0
  },
  redBtn: {
    color: theme.palette.common.white,
    background: red[500],
    '&:hover': {
      background: red[700],
    },
  },
  greyBtn: {
    color: theme.palette.common.white,
    background: grey[300],
    '&:hover': {
      background: grey[500],
    },
  },
  cyanBtn: {
    color: theme.palette.common.white,
    background: cyan[500],
    '&:hover': {
      background: cyan[700],
    },
  },
  buttonLink: {
    background: 'none',
    padding: 0,
    textTransform: 'none',
    transition: 'color ease 0.3s',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: '0.875rem',
    '&:hover': {
      background: 'none',
      color: theme.palette.secondary.main
    }
  },
  leftIcon: {
    marginRight: theme.spacing(1)
  },
  rightIcon: {
    marginLeft: theme.spacing(1)
  },
  iconSmall: {
    fontSize: 20,
  },
  footer: {
    textAlign: 'center',
    padding: 5,
    background: theme.palette.grey[100],
    fontSize: 14,
    position: 'relative'
  },
  welcomeWrap: {
    position: 'relative'
  },
  tab: {
    margin: `${theme.spacing(3)}px 0 ${theme.spacing(1)}px`,
  },
  link: {
    fontSize: '0.875rem',
    color: theme.palette.secondary.main,
    textDecoration: 'none',
    position: 'relative',
    top: 1,
    left: 0,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  socmedFull: {
    textAlign: 'center',
    width: '100%',
    margin: `${theme.spacing(3)}px ${theme.spacing(1)}px`,
    '& button': {
      width: '100%',
      display: 'block',
      margin: `0 auto ${theme.spacing(2)}px`
    },
    [theme.breakpoints.up('sm')]: {
      '& button': {
        width: 400,
      }
    }
  },
  lockWrap: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  signArrow: {
    transform: theme.direction === 'rtl' ? 'rotate(180deg)' : 'none',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  lockForm: {
    display: 'flex',
    alignItems: 'baseline',
  },
  notifyForm: {
    alignItems: 'baseline',
    [theme.breakpoints.down('xs')]: {
      '& button': {
        marginTop: theme.spacing(3),
        width: '100%'
      },
    },
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      '& button': {
        width: 'auto'
      }
    }
  },
  lockField: {
    marginRight: theme.spacing(1),
    '& label': {
      color: `${theme.palette.common.white} !important`,
    },
    '& label + div': {
      background: fade(theme.palette.primary.light, 0.3),
      border: 'none',
      '& svg': {
        fill: fade(theme.palette.common.white, 0.7)
      }
    }
  },
  avatar: {
    width: 150,
    height: 150,
    [theme.breakpoints.up('lg')]: {
      margin: theme.spacing(3),
    },
    boxShadow: theme.shadows[8]
  },
  userName: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(3),
      textAlign: 'center'
    }
  },
  hint: {
    padding: theme.spacing(1)
  },
  brandCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(3),
  },
  centerAdornment: {
    justifyContent: 'center',
    '& > div': {
      width: '100%'
    },
    '& aside': {
      top: -10,
      [theme.breakpoints.up('sm')]: {
        left: 10,
      },
      position: 'relative'
    },
    '& svg': {
      fill: theme.palette.text.secondary
    }
  },
  centerV: {
    justifyContent: 'center'
  },
  optArea: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(0.5)}px`
  },
  lang: {
    borderRadius: 8,
    '& div': {
      color: theme.palette.common.white
    },
    '& svg': {
      fill: theme.palette.common.white
    }
  },
  success: {
    backgroundColor: green[600],
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  warning: {
    backgroundColor: amber[700],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  iconMsg: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  buttonProgress: {
    color: theme.palette.text.secondary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  cardBox: {
    // height: 0.5,
    // width: "100%",
    // backgroundColor: color.PRIMARY_BORDER
    // , marginTop: "3%",
    border: `1px solid ${color.PRIMARY_BORDER}`,
    padding: "10px",
    borderRadius: "15px"

  },
  loginRightBlock: {
    top: 150,
    left: 40,
    position: 'absolute',
    textAlign: 'left',
    marginTop: 30,
  },
  subContentBlock: {
    marginTop: 15,
    [theme.breakpoints.between('1000', '1300')]: {
      marginTop: 0,
    },
  },
  loginMain: {
    marginTop: -30,
    [theme.breakpoints.between('900', '600')]: {
      marginTop: '0 !important',
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: -30,
    }
  },
  logoImage: {
    width: 150,
  },
  secondaryLogo: {
    marginTop: 30, display: 'flex'
  },
  topContent: {
    textAlign: 'left',
    marginTop: 20,
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  firstSubContent: {
    fontSize: 28,
    color: color.GREEN,
    fontWeight: 500,
    [theme.breakpoints.down('900')]: {
      fontSize: 22,
    },
    [theme.breakpoints.down('1000')]: {
      fontSize: 22,
    },

    // [theme.breakpoints.between('1000','1300')]: {
    //   fontSize:28,
    // },
    [theme.breakpoints.between('1000', '1200')]: {
      fontSize: 24,
    },

  },
  secSubContent: {
    fontSize: 28,
    color: color.LABLE_GRAY,
    fontWeight: 'normal',
    [theme.breakpoints.down('1000')]: {
      fontSize: 21,
    },
    [theme.breakpoints.between('1200', '1300')]: {
      fontSize: 22,
    },
    [theme.breakpoints.between('1000', '1200')]: {
      fontSize: 24,
    },
  },
  centerImg: {
    width: 400,
    marginTop: 10,

  },
  subContent: {
    color: color.LABLE_GRAY,
    fontSize: 20
  },
  subContentTop: {
    marginTop: 20,
  },
  loginLogoBlock: {
    top: 30, left: 70, position: 'absolute'
  },
  finnovateEnd: {
    color: color.greencolor, fontSize: 16, marginTop: 40
  },
  firstLogo: {
    marginLeft: -10, fontSize: 11, color: color.LABLE_GRAY
  },
  secondLogo: {
    marginLeft: -7, fontSize: 11, color: color.LABLE_GRAY
  }
});
export default styles;