import ApiValues from '../url/apiurl'
import moment from 'moment'
import labels from '../../utils/constants/labels';
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
/* name should be camel case in all function */
function getAllSIPTrackerDetails(ClientID,L4,L5,SchemeID,FolioNo,AsOnDate,ProductID,FamilyID,L5ID,L4ID) {
var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
var raw = JSON.stringify({"ClientId":typeof(ClientID)==="string"?Number(ClientID):ClientID,"L4":L4,
"L5":L5,"SchemeID":SchemeID,"FolioNo":FolioNo,
"AsOnDate": moment(AsOnDate).format("DD MMM YYYY"),
"ProductID": ProductID,
"FamilyID": FamilyID,
"L5ID": L5ID,
"L4ID": L4ID});
return Networking.PostApi(ApiValues.SIPTracker, raw).then(result => { return result })
.catch(function (error) {
  return { "status": "F", "message": labels.messages.apierror }
});

}
export default getAllSIPTrackerDetails
