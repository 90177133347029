import * as types from '../../constants/actionsType'
const initialState = {
    riaFlowOffLinePaymentOrder: [],
    isRiaFlowOffLinePaymentLoading: false
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RIA_FLOW_OFFLINE_PAYMENT:  
       //  
            return {
                ...state,
                riaFlowOffLinePaymentOrder: action.payload != null ? action.payload : state.riaFlowOffLinePaymentOrder ,
                isRiaFlowOffLinePaymentLoading: false
            }
        case types.LOADER_RIA_FLOW_OFFLINE_PAYMENT:
            return {
                ...state,
                isRiaFlowOffLinePaymentLoading: true
            }
        default:
            return state
    }
}
export default reducer