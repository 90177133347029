import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Logo from '../../utils/assets/images/03.png';
//import Logo from '../../utils/assets/images/zoilogo.png';
import labels from '../../utils/constants/labels';
import { Asset } from '../../utils/mainasset';
import { connect } from 'react-redux';
import secureStorage from '../../utils/securestorage';
import { basicColor } from '../../utils/colors/colors';
import localforage from 'localforage';
import moment from 'moment';
import { replaceNull } from '../../utils/commonfunction';
import { bindActionCreators } from 'redux';
import { dateChage } from '../../redux/actions/login/loginaction';
import Tollbar from '../toolbar/admintoolbar';
import colors from '../../utils/colors/colors';
import Common_login from '../commonLogin/common_login';
import Loader from '../loader/loader';
import Reset from '../../containers/login/resetpassword';
import { LoginResetSuccess } from '../../redux/actions/login/loginresetsuccessaction'
import ResetPasswords from '../../api/login/resetpassword';
// import { Asset } from '../../utils/mainasset';
import Button from '../custombutton/button';
import CancelImage from "../../utils/assets/images/failureImg.png";
import Capture from "../../utils/assets/images/capture.png";
import { Toolbar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { getEmptyAction, ResetPreventAPICallAction, accountselection, closeLogin, storeLoginData } from '../../redux/actions/login/loginaction';
import { withRouter } from "react-router";
import storage from "redux-persist/lib/storage";
import getEkycRedirectionDetails from '../../api/ekyc_redirection/ekyc_redirection';
import getL5report from '../../api/L5report/L5report';
import { storeL5Report } from '../../redux/actions/L5Report/L5ReportAction';
import { Encryption } from '../../utils/commonfunction';
const styles = theme => ({
  root: {
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    // height: window.innerHeight,
    // overflow: "hidden",
    marginTop: "5%"
    // flexGrow: 1,
    // marginBottom: 30,
    // position: 'fixed',
    // top: 0,
    // zIndex: 2,
    // backgroundColor: window.globalConfig.color.WHITE,
    // boxShadow: 'none',
    // width: "100%"
    // marginTop: "-15px"
  },

  toolbar: {
    flex: 1,
    minHeight: 10,
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_LIGHT}`
  },

  grid: {
    display: "grid",
    minHeight: "100vh",
    gridTemplateColumns: "50% 50%"
  },
  menu: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'left',
    marginLeft: "8%"
  },


  logo: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    float: 'left',
    marginLeft: -11,
  },
});
let timer = ""
class Ekyc extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      message: "", ClientID: "",
      openResetPopup: false,
      headerMessage: ""
    }
  }
  async componentDidMount() {
    //  secureStorage.removeItem('user')
    // secureStorage.setItem("api", "admin")
    secureStorage.setItem("menu", 0)
    var path = window.location.href.split("/")
    let L5Report = []
    if (this.props.match.params.id != null && this.props.match.params.id != undefined) {
      window.globalConfig.color = basicColor
      storage.removeItem('persist:root')
      this.props?.getEmptyAction("logout")
      this.props?.ResetPreventAPICallAction()
      secureStorage.removeItem("apiPending")
      localforage.removeItem("root")
      localforage.clear();
      secureStorage.clear();
      localStorage.clear();
      secureStorage.removeItem("colors")
      secureStorage.removeItem("loginDetails")
      secureStorage.setItem("apidate", "login")
      // secureStorage.setItem("logout", "done")
      secureStorage.removeItem('user')
      secureStorage.removeItem("mfRedeemUnits")
      secureStorage.removeItem("stocksRedeemUnits")
      secureStorage.removeItem("bondsRedeemUnits")
      secureStorage.removeItem('clientid')
      secureStorage.removeItem('mainclientid')
      // secureStorage.removeItem('familydetails')
      // secureStorage.removeItem('admin')
      // secureStorage.removeItem('familyname')

      // secureStorage.removeItem('folioNumber')

      // secureStorage.removeItem('mobileno')
      // secureStorage.removeItem('email')
      secureStorage.removeItem('purchaseorderstatus')
      this.props?.accountselection(0)
      this.setState({ loader: true })
      await getEkycRedirectionDetails(this.props.match.params.id).then(async (response) => {
        this.setState({ loader: false })
        let data = replaceNull(response, "NA")
        if (data.status == "S" && data.data != "NA") {
          this.setState({ message: "Please wait.... We are redirecting you in few seconds....", headerMessage: "Authentication Successful" })
          if (data.data[0].length === 0) {
            this.props.closeLogin(false)
          } else {
            this.props.closeLogin(true)
          }
          // if (data.baseUrl != "NA") {
          //   window.globalConfig.Domain = data.baseUrl.filter((item) => item.Name == "Jade Microservice")[0]?.ApplicationUrl
          //   window.globalConfig.Export = data.baseUrl.filter((item) => item.Name == "Jade Export Service")[0]?.ApplicationUrl
          //   window.globalConfig.Wadmin = data.baseUrl.filter((item) => item.Name == "Wadmin")[0]?.ApplicationUrl
          //   window.globalConfig.Ekyc = data.baseUrl.filter((item) => item.Name == "Ekyc")[0]?.ApplicationUrl
          //   window.globalConfig.OthersAPI = data.baseUrl.filter((item) => item.Name == "Finnovate Plans")[0]?.ApplicationUrl
          //   window.globalConfig.WadminService = data.baseUrl.filter((item) => item.Name == "Jade WadminService(Upload)")[0]?.ApplicationUrl
          //   window.globalConfig.AMCImagePath = data.baseUrl.filter((item) => item.Name == "Jade Amc Image Path")[0]?.ApplicationUrl
          //   window.globalConfig.DashboardImagePath = data.baseUrl.filter((item) => item.Name == "Jade Dashboard Image Path")[0]?.ApplicationUrl

          // }
          await secureStorage.setItem("Source", data.data[0]?.Source)
          await secureStorage.setItem(`nodeToken-${data.data[0]?.SessionID}`, `${data.token}`)
          await secureStorage.setItem(`authToken-${data.data[0]?.SessionID}`, `${data.data[0]?.AuthToken} `)

          await secureStorage.setItem("sessionID", data.data[0]?.SessionID)

          // if (data.familyDetails == null || data.familyDetails == undefined || data.familyDetails.length == 0) {
          //   secureStorage.setItem("familydetails", [])
          // } else {
          //   secureStorage.setItem("familydetails", data.familyDetails)
          // }
          if (
            data.companieMaster == null ||
            data.companieMaster == undefined ||
            data.companieMaster.length == 0
          ) {
            secureStorage.removeItem("company");
          } else {
            secureStorage.setItem("company", data.companieMaster[0]);
          }

          await getL5report(data.data[0].ClientID).then((res) => {
            if (res.status == "S") {
              L5Report = res.data == null || res.data == undefined ? [] : res.data
              this.props.storeL5Report(L5Report)
            } else {
              L5Report = []
              this.props.storeL5Report([])
            }
          })
          data.L5ID = L5Report.length == 1 ? L5Report[0]?.L5ID : 0
          secureStorage.setItem('user', replaceNull(data.data[0], "NA"))
          // secureStorage.setItem('admin', "success")
          secureStorage.setItem("apidate", "login")
          // secureStorage.setItem("logout", "done")
          secureStorage.removeItem("apiPending")
          // this.props.ResetPreventAPICallAction()
          this.props.storeLoginData(replaceNull(data, "NA"))
          this.props.dateChage(moment().format("DD MMM YYYY"))
          const currentTime = new Date().getTime();  //current unix timestamp
          const execTime = new Date().setHours(9, 30, 0, 0);  //API call time = today at 20:00
          let timeLeft;
          if (currentTime < execTime) {

            timeLeft = execTime - currentTime;
          } else {
            //it's currently later than 20:00, schedule for tomorrow at 20:00
            timeLeft = execTime + 86400000 - currentTime
          }
          try {
            if (this.props.childFunc == undefined) {
              if (window.globalConfig.isDashboardAllowed) {
                this.props.history.push(labels.Path.Dashboard);
              }
              else {
                this.props.history.push(labels.Path.Portfolio)
              }
            } else {
              if (this.props.childFunc.current == undefined) {
                timer = setTimeout(function () {
                  this.props.childFunc.current(data.data[0].SessionID, data.data[0].ClientID, moment().format("DD MMM YYYY"), L5Report.length == 1 ? L5Report[0].L5ID : 0, "none")
                }, timeLeft);
                this.props.childFunc.current(data.data[0].SessionID, data.data[0].ClientID, moment().format("DD MMM YYYY"), L5Report.length == 1 ? L5Report[0].L5ID : 0, "none")
              } else {
                this.props.childFunc.current(data.data[0].SessionID, data.data[0].ClientID, moment().format("DD MMM YYYY"), L5Report.length == 1 ? L5Report[0].L5ID : 0, "none")
                timer = setTimeout(function () {
                  this.props.childFunc.current(data.data[0].SessionID, data.data[0].ClientID, moment().format("DD MMM YYYY"), L5Report.length == 1 ? L5Report[0].L5ID : 0, "none")
                }, timeLeft);
                if (window.globalConfig.isDashboardAllowed) {
                  this.props.history.push(labels.Path.Dashboard);
                }
                else {
                  this.props.history.push(labels.Path.Portfolio)
                }
              }
            }
          } catch (err) {
          }
        } else {
          this.props.closeLogin(false)
          this.setState({ show: true, message: data.message })
          this.setState({ headerMessage: "Authentication Failed" })

        }

      }).catch((err) => {
        console.log(err)
        this.setState({ loader: false })

        this.props.closeLogin(false)
        this.setState({ headerMessage: "Authentication Failed" })

        this.setState({ show: true, message: labels.messages.apierror })
      })
    }
    timer = setTimeout(() => {
      this.setState({ show: true })
    }, 7000);
  }
  componentWillUnmount() {
    clearTimeout(timer)
  }
  componentDidUpdate(prevProps) {
  }
  loginClick = () => {
    this.props.history.push(labels.Path.Login)
  }
  // postResetPassword = (value) => {
  //   ResetPasswords(this.props.match.params.id, value,0).then((data) => {
  //     if (data != undefined) {
  //       if (data.status == "S") {
  //         this.props?.LoginResetSuccess(data.status)
  //         this.setState({
  //           open: true,
  //           color: "success",
  //           Message: data.message
  //         })
  //       }
  //     }
  //   })
  // }
  render() {
    //  //  (this.props.logindetails)
    const { classes } = this.props;
    return (<div className={classes.root}>
      {/* <div className={classes.grid}>
        <Common_login></Common_login>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          {this.state.loader ? <div> <Loader page="loadermandate"></Loader> </div> : <div style={{ maxWidth: "58%", border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, color: window.globalConfig.BLACK_PRIMARY, borderRadius: 10, padding: "11%" }}>{this.state.message}</div>}
          <a style={{ textDecorationLine: "underline", marginTop: "5%", color: window.globalConfig.color.PRIMARY, cursor: "pointer", }} onClick={this.loginClick} >{labels.Login.Signin}</a>
        </div>
      </div> */}
      <div className={classes.root}>
        {/* <div className={classes.grid}>
        <Common_login></Common_login>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
          {this.state.loader ? <div> <Loader page="loadermandate"></Loader> </div> : <div style={{ maxWidth: "58%", border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, color: window.globalConfig.BLACK_PRIMARY, borderRadius: 10, padding: "11%" }}>{this.state.message}</div>}
          <a style={{ textDecorationLine: "underline", marginTop: "5%", color: window.globalConfig.color.PRIMARY, cursor: "pointer", }} onClick={this.loginClick} >{labels.Login.Signin}</a>
        </div>
      </div> */}
        <div style={{ display: "flex", gap: 50, flexDirection: "column", alignItems: "center", overflow: "hidden" }}>
          <img src={Asset.logoUrlLogin} style={{ width: "fit-content", height: 50 }}></img>
          <div>
            {this.state.loader ? <div> <Loader page="loadermandate"></Loader> </div> : <div style={{ display: "flex", boxShadow: "0 0 20px 0 rgb(19 89 161 / 4%)", flexDirection: "column", gap: 10, border: `1px solid ${colors.BORDER}`, padding: 10, borderRadius: 6, justifyContent: "center", alignItems: "center" }}>
              <>
                <img src={this.state.headerMessage.includes("Authentication Failed") ? CancelImage : Capture} style={{ height: 49, width: 54, }} />
                <p style={{ fontSize: 20, fontWeight: 500, color: colors.BLACK_PRIMARY, padding: 0, margin: 0 }}>{this.state.headerMessage}</p></>
              <p style={{ fontSize: 16, color: colors.LABLE_GRAY, maxWidth: 350 }}>{this.state.message}</p>
              <Button name="Go to Login" page="primary" onClick={() => { this.props.history.push(labels.Path.Login) }}></Button>
            </div>}
          </div>
          <div style={{ marginTop: "3%", }}>
            <p style={{ color: colors.LABLE_GRAY }}>Powered by </p>
            <p style={{ textTransform: "capitalize", fontWeight: "500" }}>FINNTRACK</p>

          </div>
        </div>

      </div>
      {/* {<Reset open={this.state.openResetPopup}
          close={() => this.setState({ openResetPopup: false })} updatePassword={(value) => this.postResetPassword(value)}
        />
        } */}
    </div>
    );
  }
}
const mapStateToProps = state => ({
  login: state.Login.loginError,
  L5report:
    state.L5report.L5report.length != 0 && state.L5report.L5report != "NA"
      ? [

        ...state.L5report.L5report.map((item) => {
          return { ...item, Name: item.PortfolioName };
        }),
      ]
      : [],
})
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ storeL5Report, LoginResetSuccess, getEmptyAction, dateChage, ResetPreventAPICallAction, accountselection, closeLogin, storeLoginData }, dispatch)
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Ekyc)))
