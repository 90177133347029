import * as types from "../../constants/actionsType";
import { NegativeFinding, sortArray } from "../../../utils/commonfunction";
import Constant from "../../../utils/constants/apiconstant";
import constants from "../../../utils/constants/apiconstant";
const initialState = {
  xray: [],
  isXrayLoading: false,
  schemeList: [],
  XrayData: [],
  isFolioWiseXrayLoading: false,
  folioXrayData: [],

  //EQUITY

  equity_xray: [],
  equity_isXrayLoading: false,
  equity_schemeList: [],
  equity_XrayData: [],

  //Bonds

  bonds_xray: [],
  bonds_isXrayLoading: false,
  bonds_schemeList: [],
  bonds_XrayData: [],

  ///Gold Bonds
  gb_xray: [],
  gb_isXrayLoading: false,
  gb_schemeList: [],
  gb_XrayData: [],


  ///RealEstate
  realEstate_xray: [],
  realEstate_isXrayLoading: false,
  realEstate_schemeList: [],
  realEstate_XrayData: [],

  // FD
  fd_xray: [],
  fd_isXrayLoading: false,
  fd_schemeList: [],
  fd_XrayData: [],

  // AIF
  aif_xray: [],
  aif_isXrayLoading: false,
  aif_schemeList: [],
  aif_XrayData: [],


  // NLD
  mld_xray: [],
  mld_isXrayLoading: false,
  mld_schemeList: [],
  mld_XrayData: [],

};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.XRAYDATA:

      return {
        ...state,
        XrayData: action.payload == null || action.payload.length == 0
          ? []
          : action.payload.map((item) => {
            return { ...item, CategoryUnits: "" }
          }),
        xray:
          action.payload == null || action.payload.length == 0
            ? []
            : action.payload.map((item) => {
              return { ...item, CategoryUnits: "" }
            }),

        isXrayLoading: false,

      };
    case types.GET_XRAYDATA:
      var filterResult = [];
      var filterdata = state.XrayData;
      // console.log(action.payload)
      if (action.payload.Scheme != "" && action.payload.Scheme != null) {
        var xraySchemeName = action.payload.Scheme.map((item) => {
          return item.label;
        });

        if (xraySchemeName != null) {
          filterdata = filterdata.filter((i) => {
            return xraySchemeName.includes(i.Scheme);
          });
        } else {
          filterdata = filterdata;
        }
      } else {
       // filterdata = filterdata;
      }
      // if (action.payload.folioList != "" && action.payload.folioList != null) {
      //   var xrayFolioNo = action.payload.folioList.map((item) => {
      //     return item.label;
      //   });
      //   if (xrayFolioNo != null) {
      //     filterResult = filterdata.filter((i) => {
      //       return xrayFolioNo.includes(i.FolioNo);
      //     });
      //   } else {
      //     filterResult = filterdata;
      //   }
      // }
      if (action.payload.AMC != "" && action.payload.AMC != null) {
        var xrayAMC = action.payload.AMC.map((item) => {
          return item.label;
        });
        if (xrayAMC != null) {
          filterdata = filterdata.filter((i) => {
            // console.log(i)
            return xrayAMC.includes(i.AMC);
          });
        } else {
          filterdata = filterdata;
        }
      }
      state.xray = filterdata;
      return {
        ...state,
        // isLoadinggroupXray: false,
        // isXrayLoading: false,
      };
    case types.GET_EQUITY_XRAYDATA:
      var equity_filterResult = [];
      var equity_filterdata = state.equity_XrayData;

      if (action.payload.Scheme != "" && action.payload.Scheme != null) {
        var equity_xraySchemeName = action.payload.Scheme.map((item) => {
          return item.label;
        });
        if (equity_xraySchemeName != null) {
          equity_filterdata = equity_filterdata.filter((i) => {
            return equity_xraySchemeName.includes(i.Scheme);
          });
        } else {
          equity_filterdata = equity_filterdata;
        }
      } else {
       // equity_filterdata = equity_filterdata;
      }
      // if (action.payload.AMC != "" && action.payload.AMC != null) {
      //   var equity_xrayAMC = action.payload.AMC.map((item) => {
      //     return item.label;
      //   });
      //   if (equity_xrayAMC != null) {
      //     equity_filterResult = equity_filterdata.filter((i) => {
      //       // console.log(i)
      //       return equity_xrayAMC.includes(i.AMC);
      //     });
      //   } else {
      //     equity_filterResult = equity_filterdata;
      //   }
      // }
      state.equity_xray = equity_filterdata;
      return {
        ...state,
        // equity_isLoadinggroupXray: false,
        // equity_isXrayLoading: false,
      };
    case types.BONDS_FILTER_XRAY_DATA:
      var bonds_filterResult = [];
      var bonds_filterdata = state.bonds_XrayData;

      if (action.payload.Scheme != "" && action.payload.Scheme != null) {
        var bonds_xraySchemeName = action.payload.Scheme.map((item) => {
          return item.label;
        });
        if (bonds_xraySchemeName != null) {
          bonds_filterdata = bonds_filterdata.filter((i) => {
            return bonds_xraySchemeName.includes(i.Scheme);
          });
        } else {
          bonds_filterdata = bonds_filterdata;
        }
      } else {
        bonds_filterdata = bonds_filterdata;
      }
      // if (action.payload.AMC != "" && action.payload.AMC != null) {
      //   var bonds_xrayAMC = action.payload.AMC.map((item) => {
      //     return item.label;
      //   });
      //   if (bonds_xrayAMC != null) {
      //     bonds_filterResult = bonds_filterdata.filter((i) => {
      //       // console.log(i)
      //       return bonds_xrayAMC.includes(i.AMC);
      //     });
      //   } else {
      //     bonds_filterResult = bonds_filterdata;
      //   }
      // }
      state.bonds_xray = bonds_filterdata;
      return {
        ...state,
        // bonds_isLoadinggroupXray: false,
        // bonds_isXrayLoading: false,
      };
    case types.FD_FILTER_XRAY_DATA:
      var fd_filterResult = [];
      var fd_filterdata = state.fd_XrayData;

      if (action.payload.Scheme != "" && action.payload.Scheme != null) {
        var fd_xraySchemeName = action.payload.Scheme.map((item) => {
          return item.label;
        });
        if (fd_xraySchemeName != null) {
          fd_filterdata = fd_filterdata.filter((i) => {
            return fd_xraySchemeName.includes(i.Scheme);
          });
        } else {
          fd_filterdata = fd_filterdata;
        }
      } else {
        fd_filterdata = fd_filterdata;
      }
      // if (action.payload.AMC != "" && action.payload.AMC != null) {
      //   var fd_xrayAMC = action.payload.AMC.map((item) => {
      //     return item.label;
      //   });
      //   if (fd_xrayAMC != null) {
      //     fd_filterResult = fd_filterdata.filter((i) => {
      //       // console.log(i)
      //       return fd_xrayAMC.includes(i.AMC);
      //     });
      //   } else {
      //     fd_filterResult = fd_filterdata;
      //   }
      // }
      state.fd_xray = fd_filterdata;
      return {
        ...state,
        // fd_isLoadinggroupXray: false,
        fd_isXrayLoading: false,
      };
    case types.AIF_FILTER_XRAY_DATA:
      var aif_filterResult = [];
      var aif_filterdata = state.aif_XrayData;
      // console.log(action.payload)
      if (action.payload.Scheme != "" && action.payload.Scheme != null) {
        var aif_xraySchemeName = action.payload.Scheme.map((item) => {
          return item.label;
        });
        if (aif_xraySchemeName != null) {
          aif_filterdata = aif_filterdata.filter((i) => {
            return aif_xraySchemeName.includes(i.Scheme);
          });
        } else {
          aif_filterdata = aif_filterdata;
        }
      } else {
        aif_filterdata = aif_filterdata;
      }
      // if (action.payload.AMC != "" && action.payload.AMC != null) {
      //   var aif_xrayAMC = action.payload.AMC.map((item) => {
      //     return item.label;
      //   });
      //   if (aif_xrayAMC != null) {
      //     aif_filterResult = aif_filterdata.filter((i) => {
      //       // console.log(i)
      //       return aif_xrayAMC.includes(i.AMC);
      //     });
      //   } else {
      //     aif_filterResult = aif_filterdata;
      //   }
      // }
      state.aif_xray = aif_filterdata;
      return {
        ...state,
        // aif_isLoadinggroupXray: false,
        aif_isXrayLoading: false,
      };
    //NLD
    case types.MLD_FILTER_XRAY_DATA:
      var mld_filterResult = [];
      var mld_filterdata = state.mld_XrayData;

      if (action.payload != "" && action.payload != null) {
        var mld_xraySchemeName = action.payload.map((item) => {
          return item.label;
        });
        if (mld_xraySchemeName != ull) {
          mld_filterdata = mld_filterdata.filter((i) => {
            return mld_xraySchemeName.includes(i.Scheme);
          });
        } else {
          mld_filterdata = mld_filterdata;
        }
      } else {
        mld_filterdata = mld_filterdata;
      }
      // if (action.payload.AMC != "" && action.payload.AMC != null) {
      //   var mld_xrayAMC = action.payload.AMC.map((item) => {
      //     return item.label;
      //   });
      //   if (mld_xrayAMC != null) {
      //     mld_filterResult = mld_filterdata.filter((i) => {
      //       // console.log(i)
      //       return mld_xrayAMC.includes(i.AMC);
      //     });
      //   } else {
      //     mld_filterResult = mld_filterdata;
      //   }
      // }
      state.mld_xray = mld_filterdata;
      return {
        ...state,
        // mld_isLoadinggroupXray: false,
        // mld_isXrayLoading: false,
      };
    //REAL ESTATE
    case types.REALESTATE_FILTER_XRAY_DATA:
      var realEstate_filterResult = [];
      var realEstate_filterdata = state.realEstate_XrayData;

      if (action.payload != "" && action.payload != null) {
        var realEstate_xraySchemeName = action.payload.map((item) => {
          return item.label;
        });
        if (realEstate_xraySchemeName != null) {
          realEstate_filterResult = realEstate_filterdata.filter((i) => {
            return realEstate_xraySchemeName.includes(i.Scheme);
          });
        } else {
          realEstate_filterResult = realEstate_filterdata;
        }
      } else {
        realEstate_filterResult = realEstate_filterdata;
      }
      // if (action.payload.AMC != "" && action.payload.AMC != null) {
      //   var realEstate_xrayAMC = action.payload.AMC.map((item) => {
      //     return item.label;
      //   });
      //   if (realEstate_xrayAMC != null) {
      //     realEstate_filterResult = realEstate_filterdata.filter((i) => {
      //       // console.log(i)
      //       return realEstate_xrayAMC.includes(i.AMC);
      //     });
      //   } else {
      //     realEstate_filterResult = realEstate_filterdata;
      //   }
      // }
      state.realEstate_xray = realEstate_filterResult;
      return {
        ...state,
        // realEstate_isLoadinggroupXray: false,
        // realEstate_isXrayLoading: false,
      };

    // case types.NPS_FILTER_XRAY_DATA:
    //   var nps_filterResult = [];
    //   var nps_filterdata = state.nps_xrayfilter;

    //   if (action.payload != "" && action.payload != null) {
    //     var nps_xraySchemeName = action.payload.map((item) => {
    //       return item.label;
    //     });
    //     if (nps_xraySchemeName != null) {
    //       nps_filterResult = nps_filterdata.filter((i) => {
    //         return nps_xraySchemeName.includes(i.Scheme);
    //       });
    //     } else {
    //       nps_filterResult = nps_filterdata;
    //     }
    //   } else {
    //     nps_filterResult = nps_filterdata;
    //   }

    //   state.nps_xray = nps_filterResult;
    //   return {
    //     ...state,
    //     nps_isLoadinggroupXray: false,
    //     nps_isXrayLoading: false,
    //   };

    case types.GB_FILTER_XRAY_DATA:
      var gb_filterResult = [];
      var gb_filterdata = state.gb_XrayData;

      if (action.payload != "" && action.payload != null) {
        var gb_xraySchemeName = action.payload.map((item) => {
          return item.label;
        });
        if (gb_xraySchemeName != null) {
          gb_filterResult = gb_filterdata.filter((i) => {
            return gb_xraySchemeName.includes(i.Scheme);
          });
        } else {
          gb_filterResult = gb_filterdata;
        }
      } else {
        gb_filterResult = gb_filterdata;
      }
      // if (action.payload.AMC != "" && action.payload.AMC != null) {
      //   var gb_xrayAMC = action.payload.AMC.map((item) => {
      //     return item.label;
      //   });
      //   if (gb_xrayAMC != null) {
      //     gb_filterResult = gb_filterdata.filter((i) => {
      //       // console.log(i)
      //       return gb_xrayAMC.includes(i.AMC);
      //     });
      //   } else {
      //     gb_filterResult = gb_filterdata;
      //   }
      // }
      state.gb_xray = gb_filterResult;
      return {
        ...state,
        // gb_isLoadinggroupXray: false,
        // gb_isXrayLoading: false,
      };
    case types.SORT_BY_A_Z:
      let data = action.payload.data
      if (action.payload.flag) {
        data = data.sort((a, b) => (a.Scheme.toLowerCase() < b.Scheme.toLowerCase()) ? 1 : -1)
      } else {
        data = data.sort((a, b) => (a.Scheme.toLowerCase() > b.Scheme.toLowerCase()) ? 1 : -1)
      }
      if (action.payload.flagsort == 'equity') {
        return {
          ...state,

          equity_xray: data
        };

      }

      else if (action.payload.flagsort == Constant.Common.Bonds) {
        return {
          ...state,

          bonds_xray: data,
        };
      } else if (action.payload.flagsort == Constant.Common.FD) {
        return {
          ...state,

          fd_xray: data,
        };
      }
      else if (action.payload.flagsort == Constant.Common.MLD) {
        return {
          ...state,

          mld_xray: data,
        };
      }
      else if (action.payload.flagsort == Constant.Common.AIF) {
        return {
          ...state,

          aif_xray: data,
        };
      }
      else if (action.payload.flagsort == Constant.Common.GB) {
        return {
          ...state,
          gb_xray: data,
        };
      }
      else if (action.payload.flagsort == Constant.Common.RealEstate) {
        return {
          ...state,

          realEstate_xray: data,
        };
      }
      else {
        return {
          ...state,
          xray: data,
        };
      }

    case types.SORT_BY_MARKET_VALUE:
      let marketvalue = []
      if (action.payload.flag == true) {
        marketvalue = action.payload.data.sort((a, b) => {
          return b.CurrentMarketValuation - a.CurrentMarketValuation;
        })
      } else {
        marketvalue = action.payload.data.sort((a, b) => {
          return a.CurrentMarketValuation - b.CurrentMarketValuation;
        })
      }

      if (action.payload.flagsort == "equity") {

        return {
          ...state,
          equity_xray: marketvalue,
        };

      } else if (action.payload.flagsort == Constant.Common.Bonds) {

        return {
          ...state,
          bonds_xray: marketvalue,
        };



      } else if (action.payload.flagsort == Constant.Common.FD) {
        return {
          ...state,
          fd_xray: marketvalue,
        };
      } else if (action.payload.flagsort == Constant.Common.NPS) {
        return {
          ...state,
          nps_xray: marketvalue,
        }
      }
      else if (action.payload.flagsort == Constant.Common.RealEstate) {
        return {
          ...state,
          realEstate_xray: marketvalue,
        };
      }
      else if (action.payload.flagsort == Constant.Common.AIF) {
        return {
          ...state,
          aif_xray: marketvalue,
        };
      }
      else if (action.payload.flagsort == Constant.Common.GB) {
        return {
          ...state,
          gb_xray: marketvalue,
        };
      } else {
        return {
          ...state,
          xray: marketvalue,
        };
      }
    case types.SORT_BY_RETURNS:
      let xirr = []
      if (action.payload.flag == true) {
        xirr = action.payload.data.sort((a, b) => {
          return b.XIRR - a.XIRR;
        })

      } else {
        xirr = action.payload.data.sort((a, b) => {
          return a.XIRR - b.XIRR;
        })

      }
      if (action.payload.flagsort == "equity") {
        return {
          ...state,
          equity_xray: xirr
        }
      } else if (action.payload.flagsort == Constant.Common.Bonds) {
        return {
          ...state,
          bonds_xray: xirr,
        };
      } else if (action.payload.flagsort == Constant.Common.FD) {
        return {
          ...state,
          fd_xray: xirr,
        };
      }
      else if (action.payload.flagsort == Constant.Common.AIF) {
        return {
          ...state,
          aif_xray: xirr,
        };
      }
      else if (action.payload.flagsort == Constant.Common.MLD) {
        return {
          ...state,
          mld_xray: xirr,
        };
      }
      else if (action.payload.flagsort == Constant.Common.NPS) {
        return {
          ...state,
          nps_xray: xirr,
        };
      }
      else if (action.payload.flagsort == Constant.Common.RealEstate) {
        return {
          ...state,
          realEstate_xray: xirr,
        };
      }

      else if (action.payload.flagsort == Constant.Common.GB) {
        return {
          ...state,
          gb_xray: xirr,
        };
      } else {

        return {
          ...state,
          xray: xirr,
        };
      }

    case types.EMPTY_PORTFOLIOXRAY:
      return {
        ...state,
        xray: [],
        //  isXrayLoading: false,
        schemeList: [],
        XrayData: [],


      };

    case types.LOADER_PORTFOLIOXRAY:
      // console.log(action.payload)
      return {
        ...state,
        isXrayLoading: action.payload,
      };
    case types.EQUITY_PORTFOLIOXRAY:

      return {
        ...state,
        equity_XrayData:
          action.payload != null || action.payload.length != 0
            ? action.payload.map((item) => {
              return { ...item, Scheme: item.ScripName, CategoryUnits: "" };
            })
            : [],

        equity_xray:
          action.payload == null || action.payload.length == 0
            ? []
            : action.payload.map((item) => {
              return { ...item, Scheme: item.ScripName, CategoryUnits: "" };
            }),


        equity_isXrayLoading: false,



      };
    case types.LOADER_EQUITY_PORTFOLIOXRAY:
      return {
        ...state,
        equity_isXrayLoading: action.payload,
      };
    case types.EMPTY_EQUITY_PORTFOLIOXRAY:
      return {
        ...state,
        equity_xray: [],
        // equity_isXrayLoading: false,
        equity_schemeList: [],
        equity_XrayData: [],

      };

    //bonds
    case types.BONDS_PORTFOLIO_X_RAY:

      return {
        ...state,
        bonds_XrayData:
          action.payload != null || action.payload.length != 0
            ? action.payload.map((item) => {
              return { ...item, Scheme: item.ScripName, CategoryUnits: "" }
            })
            : [],
        bonds_schemeList:
          action.payload == null || action.payload.length == 0
            ? []
            : action.payload.map((item) => {
              return { ...item, Scheme: item.ScripName }
            }),
        bonds_xray:
          action.payload == null || action.payload.length == 0
            ? []
            : action.payload.map((item) => {
              return { ...item, Scheme: item.ScripName, CategoryUnits: "" }
            }),


        bonds_isXrayLoading: false,


      };
    case types.LOADER_BONDS_PORTFOLIO_X_RAY:
      return {
        ...state,
        bonds_isXrayLoading: action.payload,
      };
    case types.EMPTY_BONDS_PORTFOLIO_X_RAY:
      return {
        ...state,

        bonds_xray: [],

        // bonds_isXrayLoading: false,
        bonds_schemeList: [],
        bonds_XrayData: [],

      };

    //aif
    case types.AIF_PORTFOLIO_X_RAY:

      return {
        ...state,
        aif_XrayData:
          action.payload != null || action.payload.length != 0
            ? action.payload.map((item) => {
              delete item.Units
              return { ...item, Scheme: item.ScripName }
            })
            : [],
        aif_schemeList:
          action.payload == null || action.payload.length == 0
            ? []
            : action.payload.map((item) => {
              delete item.Units
              return { ...item, Scheme: item.ScripName }
            }),
        aif_xray:
          action.payload == null || action.payload.length == 0
            ? []
            : action.payload.map((item) => {
              delete item.Units
              return { ...item, Scheme: item.ScripName }
            }),

      };
    case types.LOADER_AIF_PORTFOLIO_X_RAY:
      return {
        ...state,
        aif_isXrayLoading: action.payload,
      };
    case types.EMPTY_AIF_PORTFOLIO_X_RAY:
      return {
        ...state,

        aif_xray: [],

        // aif_isXrayLoading: false,
        aif_schemeList: [],
        aif_XrayData: [],
      };

    //MLD
    case types.MLD_PORTFOLIO_X_RAY:

      return {
        ...state,
        mld_XrayData:
          action.payload != null || action.payload.length != 0
            ? action.payload.map((item) => {
              delete item.Units
              return { ...item, Scheme: item.ScripName }
            })
            : [],
        mld_schemeList:
          action.payload == null || action.payload.length == 0
            ? []
            : action.payload.map((item) => {
              delete item.Units
              return { ...item, Scheme: item.ScripName }
            }),
        mld_xray:
          action.payload == null || action.payload.length == 0
            ? []
            : action.payload.map((item) => {
              delete item.Units
              return { ...item, Scheme: item.ScripName }
            }),
        mld_isXrayLoading: false,
      };
    case types.LOADER_MLD_PORTFOLIO_X_RAY:
      return {
        ...state,
        mld_isXrayLoading: action.payload,
      };
    case types.EMPTY_MLD_PORTFOLIO_X_RAY:
      return {
        ...state,

        mld_xray: [],

        // mld_isXrayLoading: false,
        mld_schemeList: [],
        mld_XrayData: [],
      };

    //Gold Bond
    case types.GB_PORTFOLIO_X_RAY:

      return {
        ...state,
        gb_XrayData:
          action.payload != null || action.payload.length != 0
            ? action.payload.map((item) => {
              delete item.PurchaseNav
              delete item.Units
              return { ...item, Scheme: item.ScripName }
            })
            : [],
        gb_schemeList:
          action.payload == null || action.payload.length == 0
            ? []
            : action.payload.map((item) => {
              delete item.PurchaseNav
              delete item.Units
              return { ...item, Scheme: item.ScripName }
            }),
        gb_xray:
          action.payload == null || action.payload.length == 0
            ? []
            : action.payload.map((item) => {
              delete item.PurchaseNav
              delete item.Units
              return { ...item, Scheme: item.ScripName }
            }),

        gb_isXrayLoading: false,

      };
    case types.LOADER_GB_PORTFOLIO_X_RAY:
      return {
        ...state,
        gb_isXrayLoading: action.payload,
      };
    case types.EMPTY_GB_PORTFOLIO_X_RAY:
      return {
        ...state,

        gb_xray: [],

        // gb_isXrayLoading: false,
        gb_schemeList: [],
        gb_XrayData: [],
      };




    //RealEstate
    case types.REALESTATE_PORTFOLIO_X_RAY:

      return {
        ...state,
        realEstate_XrayData:
          action.payload != null || action.payload.length != 0
            ? action.payload.map((item) => {
              delete item.Units
              return { ...item, Scheme: item.ScripName }
            })
            : [],
        realEstate_schemeList:
          action.payload == null || action.payload.length == 0
            ? []
            : action.payload.map((item) => {
              delete item.Units
              return { ...item, Scheme: item.ScripName }
            }),
        realEstate_xray:
          action.payload == null || action.payload.length == 0
            ? []
            : action.payload.map((item) => {
              delete item.Units
              return { ...item, Scheme: item.ScripName }
            }),


        realEstate_isXrayLoading: false,

      };
    case types.LOADER_REALESTATE_PORTFOLIO_X_RAY:
      return {
        ...state,
        realEstate_isXrayLoading: action.payload,

      };
    case types.EMPTY_REALESTATE_PORTFOLIO_X_RAY:
      return {
        ...state,
        realEstate_xray: [],

        // realEstate_isXrayLoading: false,
        realEstate_schemeList: [],
        realEstate_XrayData: [],

      };

    //FD
    case types.FD_PORTFOLIO_X_RAY:

      return {
        ...state,
        fd_XrayData:
          action.payload != null || action.payload.length != 0
            ? action.payload.map((item) => {
              delete item.Units
              delete item.CurrentNAV
              return { ...item, Scheme: item.ScripName }
            })
            : [],
        fd_schemeList:
          action.payload == null || action.payload.length == 0
            ? state.fd_schemeList
            : action.payload.map((item) => {
              delete item.Units
              delete item.CurrentNAV
              return { ...item, Scheme: item.ScripName }
            }),
        fd_xray:
          action.payload == null || action.payload.length == 0
            ? state.fd_xray
            : action.payload.map((item) => {
              delete item.Units
              delete item.CurrentNAV
              return { ...item, Scheme: item.ScripName }
            }),
        fd_isXrayLoading: false,

      };
    case types.LOADER_FD_PORTFOLIO_X_RAY:
      return {
        ...state,
        fd_isXrayLoading: action.payload,
      };
    case types.EMPTY_FD_PORTFOLIO_X_RAY:
      return {
        ...state,

        fd_xray: [],

        // fd_isXrayLoading: false,
        fd_schemeList: [],
        fd_XrayData: [],

      };
      case types.FOLIO_WISE_PORTFOLIO_XRAY:
        // console.log(action.payload)
        return {
          ...state,
          folioXrayData: action.payload
  
        };
      case types.LOADER_FOLIO_WISE_PORTFOLIO_XRAY:
        return {
          ...state,
          isFolioWiseXrayLoading: action.payload
  
        };
      case types.EMPTY_FOLIOWISE_XRAYDATA:
        return {
          ...state,
          isFolioWiseXrayLoading: false,
          folioXrayData: []
  
  
        }
    default:
      return state;
  }
};
export default reducer;
