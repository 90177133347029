// ==========================
// New changes
// ==========================

const Domain = window.globalConfig.Domain;
const Domain2 = window.globalConfig.Domain2;
const Domain3 = window.globalConfig.Domain3;
const Domain4 = window.globalConfig.Domain4;
const Domain6 = window.globalConfig.Domain6;
const KYCDomain = window.globalConfig.KYCDomain;
const Domain8 = window.globalConfig.Domain8;
const Domain9 = window.globalConfig.Domain9;
const DomainWadminService = window.globalConfig.Domain + "/wadmin";
const DomainCDS = window.globalConfig.Domain + '/cds/'
// const AmcImage = window.globalConfig.AmcImage;
const Portfolio = "Report/GetCategoryAllocation";
const AssetWisePortfolio = "Report/GetPortfolioAllocation";
const Sector = "Report/GetEquityFundSectorAnalysisAllocation";
const Instrument = "Report/GetSplitByCategory";
const Xray = "Report/GetPortfolioXray";
const Transaction = "Report/GetTransactions";
const ProfitLoss = "Report/GetBookedGainLoss";
const StructuredProduct = "OtherProductReport/GetLiquiLoansReport";
const Dropdown = "api/Master/GetFilters";
const SendOTP = "Account/ForgetPasword";
const ReSendOTP = "Account/ResendOTP";
const Reset = "Account/ResetPasword";
const change = "Account/ChangePassword";
const VerifyOTP = "Account/VerifyOTP";

const Header = "Report/GetHeaderDetails";
const GetSchemeDetail = "/OT/Trade/GetSchemeDetails";
const MandateCreate = "/Trading/BSEStar/MandateRegistration";
const getXraypdf = "/PortfolioReport/GetSchemeWiseReportFile";
const getXrayexcel = "/PortfolioReport/GetSchemeWiseExcel";
const gettranpdf = "/PortfolioReport/GetTransactionReportFile";
const gettranexcel = "/PortfolioReport/GetTransactionReportExcel";
const tradingschemedetails = "/OT/api/schemes/";
const GetAllReport = "/PortfolioReport/GetAllReports";
const xSIPorder = "/Trading/BSEStar/XSIPOrderEntry";
const SIPorder = "/Trading/BSEStar/SIPOrderEntry";
const OneTimeorder = "/Trading/BSEStar/PlaceMFOrder";
const AddCart = "/OT/cart/AddCart";
const DeleteCart = "/OT/cart/DeleteCart";
const GetAllCarts = "/OT/cart/GetAllCarts";
const AddCartBasket = "/bt/api/Cart/AddCart";
const GetAllCartsBasket = "/bt/api/Cart/GetAllCarts";
const GetCartByID = "/OT/cart/GetCartByID";
const UpdateCart = "/OT/cart/UpdateCart";
const GetProfileDetail = "Account/GetProfileDetails";
const TransactionOrderReport = "MFOrderReport/GetMFOrderStatus";
const MandateStatusCheck = "/trading/BSEStar/MandateStatusCheck";
const RiskQuestion = "/api/risk/getriskquestions";
const RiskScore = "/api/risk/GenerateRiskScore";
const RiaQuestion = "riaplan/ManageRIAQuestionsResponse";
const RiaOffLine = "/Dashboard/ManageRIAPlanPayments";
const RiaOnLine = "/api/payment";
//const RiaOnLine="payment_test.php";
const KYCDetails = "Account/GetKYCData";
const PaymentComplete = "profile/paymentcomplete";
//const RiaOnLinePaymentComplete = "/api/payment";
// const Plan = "/api/plans";
const Plan = "/OT/api/PlanAndSubscription/GetRiaPlan";

let finnovateURL = {
  //Domain
  Domain,
  Domain3,
  KYCDomain,
  Login: `${DomainCDS}${"Account/Login"}`,
  LoginwithOtp: `${DomainCDS}${"Account/LoginWithOTP"}`,
  VerifyOTPLoginWithEmail: `${DomainCDS}${"Account/VerifyOTPLoginWithEmail"}`,
  FamilyDetails: `${DomainCDS}api/Master/GetFamilyDetails?clientID=`,
  LoginOut: `${DomainCDS}${"Account/Logout?logoutToken="}`,
  GetProfileDetail: `${DomainCDS}${GetProfileDetail}`,
  SendOtpPassword: `${DomainCDS}${SendOTP}`,
  ReSendOtpPassword: `${DomainCDS}${ReSendOTP}`,
  ResetPassword: `${DomainCDS}${Reset}`,
  VerifyPassword: `${DomainCDS}${VerifyOTP}`,
  changePassword: `${DomainCDS}${change}`,
  SignUp: `${DomainCDS}${"Account/SignUp"}`,
  BenchMarkDetails: `${DomainCDS}Report/GetBenchMark `,
  MobileVerification: `${DomainCDS}${"Account/VerifyMobileNumber"}`,
  MobileOTPVerification: `${DomainCDS}${"Account/VerifyMobileOTP"}`,
  EmailOTPVerification: `${DomainCDS}${"Account/VerifyEmailOTP"}`,
  CheckDuplication: `${DomainCDS}${"Account/CheckDuplication"}`,
  ChangeEmail: `${DomainCDS}${"Account/ChangeEmail "}`,
  AssetAllocationDashboard: `${DomainCDS}${"Report/GetAssetAllocationFamilyDashboardReport"}`,
  ProductAllocationDashboard: `${DomainCDS}${"Report/GetProductAllocationFamilyDashboardReport "}`,
  HeaderDetails: `${DomainCDS}${Header}`,
  AssetWisePortfolioAllocation: `${DomainCDS}${AssetWisePortfolio}`,
  PortfolioDetail: `${DomainCDS}${Portfolio}`,
  SectorAllocation: `${DomainCDS}${Sector}`,
  InstrumentDetails: `${DomainCDS}${Instrument}`,
  PortfolioXray: `${DomainCDS}${Xray}`,
  Transactions: `${DomainCDS}${Transaction}`,
  ProfitAndLoss: `${DomainCDS}${ProfitLoss}`,
  CapitalGain: `${DomainCDS}${"Report/GetTaxationReport"}`,
  SIPTracker: `${DomainCDS}${"Report/GetSIPTracker"}`,
  AccountStatementView: `${DomainCDS}${"Report/GetAccountStatement"}`,
  PortfolioReport: `${DomainCDS}${"Report/GetPortfolioDetails"}`,
  Shortterm: `${DomainCDS}${"Report/GetAFRSTLTReport"}`,
  ELSS: `${DomainCDS}${"Report/GetAFRELSSTransaction"}`,
  XrayWithRedemption: `${DomainCDS}${"Report/GetXrayWithRedemptionReport"}`,
  // Exitload: `${Domain}${"/Dashboard/AFRExitLoadReport"}`,
  AssetAllocation: `${DomainCDS}${"Report/GetAssetAllocation"}`,
  MarketCapAllocationReport: `${DomainCDS}${"Report/GetMarketCapAllocation "}`,
  RunningSip: `${DomainCDS}${"Report/GetRunningSIPSummary"}`,
  MaturityTracker: `${DomainCDS}${"Report/GetMaturityTracker"}`,
  FamilyMaturityTracker: `${DomainCDS}${"FamilyReport/GetMaturityTrackerFamily"}`,
  TransferIn: `${DomainCDS}${"Report/GetTransferIn"}`,
  FamilyTransferIn: `${DomainCDS}${"FamilyReport/GetTransferInFamily"}`,
  structuredProductReport: `${DomainCDS}${StructuredProduct}`,
  familyLiquiloansReport: `${DomainCDS}FamilyReport/GetLiquiLoansReportFamily`,
  familyLiquiloansReportHeader: `${DomainCDS}FamilyReport/GetLiquiLoanReportHeaderFamily`,
  getInsuranceReport: `${DomainCDS}${"OtherProductReport/GetInsuranceReport"}`,
  getFamilyInsuranceReport: `${DomainCDS}${"FamilyReport/GetInsuranceReportFamily"}`,
  EpfReport: `${DomainCDS}${"OtherProductReport/GetEPFReport?"}`,
  NPSReport: `${DomainCDS}${'OtherProductReport/NPSReport'}`,
  FamilyNPSReport: `${DomainCDS}${'FamilyReport/GetNPSReportFamily'}`,
  UpcommingSip: `${DomainCDS}${"MFOrderReport/GetUpComingSIPs"}`,
  GetMandateDetails: `${DomainCDS}${"MFOrderReport/GetMandateReport"}`,
  OnlineSTPReport: `${DomainCDS}${"MFOrderReport/GetOnlineSTP"}`,
  GetTransactionOrderReport: `${DomainCDS}${TransactionOrderReport}`,
  RedeemParameter: `${Domain}/OT/Trade/GetTradeRequest?transNo=`,
  MargetCapWisePortfolio: `${DomainCDS}Report/PortfolioXRayMCAwise`,
  IndustryWisePortfolio: `${DomainCDS}Report/PortfolioXRayIndustrywise`,
  EquityUCC: `${DomainCDS}api/Master/GetEquityProfile`,
  CancelSip: `${DomainCDS}${"MFOrderReport/GetOnlineSIP"}`,
  ProfileDetails: `${DomainCDS}${"Account/GetProfile"}`,
  FilterDetail: `${DomainCDS}${Dropdown}`,
  BennyDrop: "https://kyc-api.aadhaarkyc.io/api/v1/bank-verification/",
  payment2FA: `${Domain}/trading/BSEStar/BSEStarTwoFA`,
  SchemeDetails: `${Domain}${GetSchemeDetail}`,
  BenchMarkDetails: `${DomainCDS}Report/GetBenchMark `,
  mandateCreate: `${Domain}${MandateCreate}`,
  mandateStatusCheck: `${Domain}${MandateStatusCheck}`,
  SchemeDetail: `${Domain}${tradingschemedetails}`,
  xSip: `${Domain}${xSIPorder}`,
  SipOrders: `${Domain}${SIPorder}`,
  OneTimeOrders: `${Domain}${OneTimeorder}`,
  AddCart: `${Domain}${AddCart}`,
  DeleteCart: `${Domain}${DeleteCart}`,
  GetAllCart: `${Domain}${GetAllCarts}`,
  GetAllCartsBasket: `${Domain}${GetAllCartsBasket}`,
  AddCartBasket: `${Domain}${AddCartBasket}`,
  FolioCartUpdate: `${Domain}${"/OT/Cart/UpdateCartFolio"}`,
  GetOldCart: `${Domain}${"/OT/Cart/GetOrderPlacedCarts"}`,
  MoveToCart: `${Domain}${"/OT/Cart/CloneCart"}`,
  BasketTrading: `${Domain}/bt/api/basket/placebasketorder`,
  SplitBasketAmount: `${Domain}/bds/api/Basket/GetBasketsSplitedAmount?basketID=`,
  GetCartByID: `${Domain}${GetCartByID}`,
  CheckForInitPayment: `${Domain}/trading/BSEStar/OrderStatusCheck4InitPayment`,
  UpdateCart: `${Domain}${UpdateCart}`,
  CheckStatus: `${Domain}${"/trading/BSEStar/ClientOrderPaymentStatus"}`,
  KYCDetails: `${DomainCDS}${KYCDetails}`,
  ClientData: `${DomainWadminService}/api/ClientMain/GetClientData?ClientID=`,
  MandateRedirection: `${Domain}${"/Trading/BSEStar/EMandateAuthURL"}`,
  KycLink: `${Domain}${"/Dashboard/GetKYCLink"}`,
  EncryptedParameter: `${DomainCDS}${"Account/GetEncryptedParamters"}`,
  //  SignUp: `${Domain}${"/Dashboard/SignUpAsQuest"}`,
  LiquiloansHeader: `${DomainCDS}OtherProductReport/LiquiLoanReportHeader`,
  GetRazorPayUrl: `${Domain}${"/RIAPlan/CreateSubscription"}`,
  BillingDetails: `${DomainCDS}RIAPlan/BillingDetails`,
  CreateSubscription: `${DomainCDS}RIAPlan/CreateSubscription`,
  EPFSubmitOTP: `${Domain}${"/WadminService/EPFO/SubmitOtp"}`,
  EPFGenerateOTP: `${Domain}${"/cds/EPFO/GenerateOTP?"}`,
  CancelOnlineSTPReport: `${Domain}${"/trading/BSEStar/stpcancellation"}`,
  StockList: `${Domain}${"/OT/api/Equity/GetStocks"}`,
  Broker: `${Domain}${"/OT/api/Equity/GetBrokers"}`,
  StocksandTrade: `${Domain}${"/OT/api/Equity/AddUpdateStockTradedBonds"}`,
  PaymentGateway: `${Domain}${"/trading/BSEStar/PaymentGatewayValues"}`,
  Addbank: `${Domain}${"/Dashboard/AddUpdateClientBankDetails"}`,
  SubscriptionBankList: `${Domain}${"/RIAPlan/GetBankAndMandateDetails"}`,
  SubscriptionAddMandate: `${Domain}${"/RIAPlan/AddClientMandateDetails"}`,
  SubscriptionAddBank: `${Domain}${"/RIAPlan/AddClientBankDetails"}`,
  SubscriptionAutoCharge: `${Domain}${"/RIAPlan/UpdateIsAutoPayForSubscritpion"}`,
  ScheduleIPV: `${Domain}${"/Dashboard/ScheduleIPV"}`,
  VerifyAccountNumber: `${Domain}${"/kyc/SurePassAPI/VerifyBankAccount"}`,
  AddNominee: `${Domain}${"/Dashboard/AddUpdateNominee"}`,
  DeleteNominee: `${Domain}${"/Dashboard/DeleteNominee"}`,
  UpdatePersonalDetails: `${Domain}${"/Dashboard/UpdatePersonalDetails"}`,
  DeleteCountry: `${Domain}${"/Dashboard/DeleteTaxPayingCountry"}`,
  AddCountry: `${Domain}${"/Dashboard/AddUpdateTaxPayingCountry"}`,
  Searchapi: `${Domain}api/${"Master/GetBranches"}`,
  ProductWisePortfolioSummaryFamily: `${DomainCDS}FamilyReport/GetProductWisePortfolioSummaryFamily`,
  FamilyCategoryAllocation: `${DomainCDS}FamilyReport/GetCategoryAllocationFamily`,
  FamilySectorAllocation: `${DomainCDS}FamilyReport/GetEquityFundSectorAnalysisAllocationFamily`,
  FamilySplityByCategory: `${DomainCDS}FamilyReport/GetSplitByCategoryFamily`,
  FamilyPortfolioXray: `${DomainCDS}FamilyReport/GetPortfolioXrayFamily`,
  FamilyTransactions: `${DomainCDS}FamilyReport/GetTransactionsFamily`,
  FamilyProfitAndLoss: `${DomainCDS}FamilyReport/GetBookedGainLossFamily`,
  FolioWisePortfolio: `${DomainCDS}Report/PortfolioXRayFoliowise`,
  SchemeWisePortfolio: `${DomainCDS}Report/GetSchemeWiseHolding`,
  SchemeWisePortfolioFamily: `${DomainCDS}FamilyReport/GetMutualFundsPortfolioSummaryFamily`,
  DownloadReport: `${Domain}${"/Dashboard/DownloadReport"}`,
  PaymentStatuscheck: `${Domain}${"/trading/BSEStar/ClientOrderPaymentStatus"}`,
  GetTradeDetailsForAction: `${Domain}${"/OT/api/Schemes/GetTradeDetailsForAction"}`,
  GetProductList: `${Domain}${"/Dashboard/GetProductList"}`,
  Redeem: `${Domain}${"/Trading/BSEStar/Redeem"}`,
  CancelRunningSIP: `${Domain}${"/OT/trade/GetXSIPCancelDetails"}`,
  Performance: `${Domain}${"/OT/api/Schemes/MFNavHistory/"}`,
  SimilarScheme: `${Domain}${"/OT/api/Schemes/SimilarSchemes?SchemeCode="}`,
  STP: `${Domain}${"/Trading/BSEStar/stp"}`,
  STPNew: `${Domain}${"/trading/BSEStar/STPRegistration"}`,
  Switch: `${Domain}${"/Trading/BSEStar/SwitchOrderEntry"}`,
  SWP: `${Domain}${"/Trading/BSEStar/swp"}`,
  MarkasPrimary: `${Domain}${"/Dashboard/MarkAsPrimary"}`,
  AIFTransaction: `${Domain}${'/OT/api/Equity/AddAIFAndTransaction'}`,
  AIFandPMSList: `${Domain}${'/OT/api/AlternateAssets/GetAlternateAssets'}`,
  RealEstateDataEntry: `${Domain}${'/OT/api/ReconciledRealEstate/AddReconciledRealEstate'}`,
  BullionDataEntry: `${Domain}${'/OT/api/ReconcilationBullion/AddUpdateDeleteReconcilationBullion'}`,
  RealEstateListing: `${Domain}${'/OT/api/ReconciledRealEstate/GetReconciledRealEstate'}`,
  DeleteRealEstateList: `${Domain}${'/OT/api/ReconciledRealEstate/AddReconciledRealEstate'}`,
  bullionListing: `${Domain}${'/OT/api/ReconcilationBullion/GetReconcilationBullion'}`,
  AddValutionRealEstateList: `${Domain}${'/OT/api/ReconciledRealEstate/AddReconciledRealEstateValuation'}`,
  AddValutionBullionList: `${Domain}${'/OT/api/ReconcilationBullion/AddReconcilationBullionEvaluation'}`,
  AddSellRealEstateList: `${Domain}${'/OT/api/ReconciledRealEstate/AddReconciledRealEstate'}`,
  AddSellBullionList: `${Domain}${'/OT/api/ReconcilationBullion/AddUpdateDeleteReconcilationBullion'}`,
  alertNotification: `${Domain}/OT/api/Notification/GetNotification`,
  getAllDeviceToken: `${DomainCDS}PushNotification/GetAllDeviceToken`,
  sendPushNotification: `${DomainCDS}PushNotification/SendNotification`,
  getBasketList: `${Domain}/bds/api/Basket/GetBasketList?`,
  getBasketDetails: `${Domain}/bds/api/Basket/GetBasketsByID?`,
  getBacketPerformance: `${Domain}/bds/api/Basket/GetBasketsPerformance?`,
  getBenchMarkData: `${Domain}/bds/api/Basket/GetBasketsBenchMark`,
  getRebalanceTimeLine: `${Domain}/bds/api/Basket/GetBasketsTimeLine?basketId=`,
  alertTemplate: `${DomainCDS}Notification/GetMailBody`,//Notification/GetMailBody
  downloadAttachments: `${DomainCDS}Notification/DownloadFile`,
  DeleteBullionList: `${Domain}${'/OT/api/ReconcilationBullion/AddUpdateDeleteReconcilationBullion'}`,
  AIFAddAsset: `${Domain}${'/OT/api/AlternateAssets/AddAlternateAssets'}`,
  AIFandPMSList: `${Domain}${'/OT/api/AlternateAssets/GetAlternateAssets'}`,
  DeleteAIFList: `${Domain}${'/OT/api/AlternateAssets/DeleteAlternateAssets'}`,
  AddAifAssetList: `${Domain}${'/OT/api/AlternateAssets/AddUpdateAlternateAssetsValuation'}`,
  Add_Update_Delete_AifTransactionList: `${Domain}${'/OT/api/AlternateAssets/AddUpdateAlternateAssetsTransaction'}`,
  //NPS
  AddUpdateDeleteNPSDataEntry: `${Domain}${'/OT/api/NPS/AddUpdateDeleteNPS'}`,
  NPSList: `${Domain}${'/OT/api/NPS/GetNPS'}`,
  NPSTransactionList: `${Domain}${'/OT/api/NPS/GetTransactionNPS'}`,
  AddUpdateDeleteNPSTransactionList: `${Domain}${'/OT/api/NPS/AddUpdateDeleteTransactionNPS'}`,
  UpdateValuationNPS: `${Domain}${'/OT/api/NPS/UpdateValuvationNPS'}`,
  NPSandInsuranceDropdown: `${Domain}${'/OT/api/AlternateAssets/GetInsuranceAndNPSMasters'}`,
  NPSDropdown: `${Domain}${'/OT/api/NPS/GetMasterNPSAndinsurance'}`,
  //Insurance
  addInsuranceBank: `${Domain}${"/OT/api/Insurance/AddBankListInsurance"}`,
  insuranceAdd: `${Domain}${"/OT/api/Insurance/AddDeleteInsurance"}`,
  lifeInsuranceAddUpdateDelete: `${Domain}${"/OT/api/Insurance/AddUpdateDeleteLifeInsurance"}`,
  insuranceEdit: `${Domain}${"/OT/api/Insurance/GetEditInsurance"}`,
  getInsuranceList: `${Domain}${"/OT/api/Insurance/GetInsurance"}`,
  // Rider Name Dropdown
  getRiderName: `${Domain}${"/OT/api/Insurance/GetRiderName"}`,
  //Domain 5
  L5reportdata: `${DomainCDS}${"api/Master/GetL5AccountDetails"}`,
  AssetName: `${Domain}${"/OT/api/Equity/AddAsset"}`,
  GetAssetName: `${Domain}${"/OT/api/Equity/GetAssetName"}`,
  GetFDRNumber: `${Domain}${"/OT/api/Equity/GetNewFDRNumber"}`,
  //Domain 2
  AccountStatement: `${Domain}${"/Dashboard/AccountSummaryReport"}`,
  downloadInvoice: `${Domain2}/PortfolioReport/GetTaxInvoiceReportFile?billId=`,
  ExportDownloadURL: `${Domain2}/ExportURL/DownloadReport`,
  ExportAccountStatementURL: `${Domain2}/ExportURL/AccountSummaryReport`,
  ReportAll: `${Domain2}${GetAllReport}`,
  pdfXray: `${Domain2}${getXraypdf}`,
  ExportAssetWisePortfolioAllocation: `${Domain2}/PortfolioReport/PortfolioAssetReportFile?`,
  pdfEquityXray: `${Domain2}/EquityReport/GetScripWiseReportFile?clientId=`,
  pdfBondsXray: `${Domain2}${"/BondReport/GetScripWiseReportFile?clientId="}`,
  pdfGBXray: `${Domain2}${"/GoldReport/GetScripWiseReportFile?clientId="}`,
  excelXray: `${Domain2}${getXrayexcel}`,
  excelEquityXray: `${Domain2}/EquityReport/GetScripWiseExcel?clientId=`,
  excelBondsXray: `${Domain2}/BondReport/GetScripWiseExcel?clientId=`,
  excelGBXray: `${Domain2}/GoldReport/GetScripWiseExcel?clientId=`,
  // pdftran: `${Domain2}${gettranpdf}`,
  pdfEquityTrans: `${Domain2}/EquityReport/GetTransactionReportFile?clientId=`,
  pdfBondsTrans: `${Domain2}/BondReport/GetTransactionReportFile?clientId=`,
  pdfGBTrans: `${Domain2}/GoldReport/GetTransactionReportFile?clientId=`,
  pdfEquityXray: `${Domain2}/EquityReport/GetScripWiseReportFile`,
  pdfBondsXray: `${Domain2}${"/BondReport/GetScripWiseReportFile"}`,
  pdfGBXray: `${Domain2}${"/GoldReport/GetScripWiseReportFile"}`,
  excelXray: `${Domain2}${getXrayexcel}`,
  excelEquityXray: `${Domain2}/EquityReport/GetScripWiseExcel`,
  excelBondsXray: `${Domain2}/BondReport/GetScripWiseExcel`,
  excelGBXray: `${Domain2}/GoldReport/GetScripWiseExcel`,
  pdftran: `${Domain2}${gettranpdf}`,
  pdfEquityTrans: `${Domain2}/EquityReport/GetTransactionReportFile`,
  pdfBondsTrans: `${Domain2}/BondReport/GetTransactionReportFile`,
  pdfGBTrans: `${Domain2}/GoldReport/GetTransactionReportFile`,
  // pdfFDTrans:`${Domain2}/BondReport/GetTransactionReportFile?clientId=`,
  exceltran: `${Domain2}${gettranexcel}`,
  excelEquityTrans: `${Domain2}/EquityReport/GetTransactionReportExcel`,
  excelBondsTrans: `${Domain2}/BondReport/GetTransactionReportExcel`,
  excelGBTrans: `${Domain2}/GoldReport/GetTransactionReportExcel`,
  // excelFDTrans: `${Domain2}/BondReport/GetTransactionReportExcel?clientId=`,
  SIPTrackerpdf: `${Domain2}${"/PortfolioReport/GetSIPTrackerPdf?clientId="}`,
  SIPTrackerexcel: `${Domain2}${"/PortfolioReport/GetSIPTrackerExcel?clientId="}`,
  // xray with redemption mutual funds
  XrayWithRedemptionpdf: `${Domain2}${"/PortfolioReport/GetXraywithRedemptionReportFile?clientId="}`,
  XrayWithRedemptionexcel: `${Domain2}${"/PortfolioReport/GetXraywithRedemptionReportExcel?clientId="}`,
  //equity xray with soild units 
  xrayWithRedemptionEquityPdfUrl: `${Domain2}${"/EquityReport/GetXraywithRedemptionReportFile?clientId="}`,
  xrayWithRedemptionEquityXcelUrl: `${Domain2}${"/EquityReport/GetXraywithRedemptionReportExcel?clientId="}`,
  //bonds xray with soild units
  xrayWithRedemptionBondsPdfUrl: `${Domain2}${"/BondReport/GetXraywithRedemptionReportFile?clientId="}`,
  xrayWithRedemptionBondsXcelUrl: `${Domain2}${"/BondReport/GetXraywithRedemptionReportExcel?clientId="}`,
  //gold bonds xray with soild units
  xrayWithRedemptionGoldBondsPdfUrl: `${Domain2}${"/GoldReport/GetXraywithRedemptionReportFile?clientId="}`,
  xrayWithRedemptionGoldBondsXcelUrl: `${Domain2}${"/GoldReport/GetXraywithRedemptionReportExcel?clientId="}`,
  AssetAllocationpdf: `${Domain2}${"/PortfolioReport/GetAssetClassAllocationReportFile?clientId="}`,
  AssetAllocationexcel: `${Domain2}${"/PortfolioReport/GetAssetClassAllocationReportExcel?clientId="}`,
  // MargetCapAllocation muthual fund
  MargetCapAllocationpdf: `${Domain2}${"/PortfolioReport/GetMarketCapAllocationReportFile?clientId="}`,
  MargetCapAllocationexcel: `${Domain2}${"/PortfolioReport/GetMarketCapAllocationReportExcel?clientId="}`,
  // MargetCapAllocation equity
  EquityMargetCapAllocationpdf: `${Domain2}${"/EquityReport/GetMarketCapAllocationReportFile?clientId="}`,
  EquityMargetCapAllocationexcel: `${Domain2}${"/EquityReport/GetMarketCapAllocationReportExcel?clientId="}`,
  RunningSippdf: `${Domain2}${"/PortfolioReport/GetRunningSipFile?clientId="}`,
  RunningSipexcel: `${Domain2}${"/PortfolioReport/GetRunningSipReportExcel?clientId="}`,
  //Domain 1
  // SIPTracker: `${Domain1}${"/dashboard/GetTrackSIP"}`,
  //Domain 4
  RiskQuestionReport: `${Domain4}${RiskQuestion}`,
  RiskScoreReport: `${Domain4}${RiskScore}`,
  RiaOnLineResponse: `${Domain4}${RiaOnLine}`,
  PaymentComplete: `${Domain6}${PaymentComplete}`,
  PaymentCompleteLocal: `${Domain6}${PaymentComplete}`,
  //Domain 5
  InitializeESign: `${Domain}${"/kyc/SurePassAPI/InitializeESign"}`,
  ESignDownloadFile: `${Domain}${"/kyc/SurePassAPI/DownloadSignedDocument"}`,
  //yogitha
  TrackingEquity: `${Domain9}${"/CASFileUpload/ParseCAMSFile"}`,
  // TrackingEquity: `${Domain9}${"/CASFileUpload/ReadCAMSFile"}`,

  TrackImported: `${DomainCDS}${"HeldAway/GetTradeDetails"}`,
  NSDLEquity: `${Domain9}${"/CASFileUpload/ParseNSDLFile"}`,
  CSDLEquity: `${Domain9}${"/CASFileUpload/ParseCDSLFile"}`,
  // NSDLEquity: `${Domain9}${"/CASFileUpload/ReadNSDLFile"}`,
  // CSDLEquity: `${Domain9}${"/CASFileUpload/ReadCDSLFile"}`,

  allProductXirr: `${Domain}${"/Process/AllProductXIRR"}`,
  updateProcessStatus: `${Domain3}${"CASPDFUpload/UpdateProcessStatus"}`,
  allProductsEtlProcess: `${Domain}${"/Process/AllProductsETLProcess"}`,
  nsdlHoldingsEquity: `${Domain}${"/Process/NSDLCalculateHoldingsEquity"}`,
  nsdlGetDailyEquity: `${Domain}${"/Process/NSDLGetDailyHoldingEquity"}`,
  nsdlFifoCalculation: `${Domain}${"/Process/NSDLEQUITYFIFOCalculation"}`,
  nsdlEtProcess: `${Domain}${"/Process/NSDLEQUITYETLProcess"}`,
  cdslHoldingBonds: `${Domain}${"/Process/NSDLCalculateHoldingsBonds"}`,
  cdslGetDailyBonds: `${Domain}${"/Process/NSDLGetDailyHoldingBonds"}`,
  cdslFifoCalculationBonds: `${Domain}${"/Process/NSDLFIFOCalculationBonds"}`,
  cdslEtlProcessBonds: `${Domain}${"/Process/NSDLETLProcessBonds"}`,
  camsHoldings: `${Domain}${"/Process/CAMSCalculateHoldings"}`,
  camsHistoric: `${Domain}${"/Process/CAMSFIFOCalculation"}`,
  camsEtlProcess: `${Domain}${"/Process/CAMSETLProcess"}`,
  camsFamilyHolding: `${Domain}${"/Process/CAMSGetFamilyDailyHolding"}`,
  camsComplete: `${Domain}${"/Process/CAMSGetDailyHolding"}`,
  // added by:yogitha
  fixedDeposit: `${Domain}${"/OT/api/Equity/AddUpdateFDNCDDebentures"}`,
  TransactionDropdown: `${Domain}${"/OT/api/Equity/GetFDDebentureMasters"}`,
  InterestRateDropdown: `${Domain}${"/OT/api/Equity/GetFDDebentureMasters"}`,
  InterestPaymentDropdown: `${Domain}${"/OT/api/Equity/GetFDDebentureMasters"}`,
  AlternateAssets: `${Domain}${"/OT/api/AlternateAssets/GetAlternateAssetMasters"}`,
  // https://msuat.zoiftspl.com/Dashboard/GetTradeDetails?clientID=
  RiaQuestionResponse: `${DomainCDS}${RiaQuestion}`,
  // Same Url but parameter depositeID is extra added in RiaOnlinePaymentCompleteDetails.
  RiaOffLineResponse: `${Domain}${RiaOffLine}`,
  RiaOnlinePaymentCompleteDetails: `${Domain}${RiaOffLine}`,
  //Domain7
  MyPlan: `${Domain}${Plan}`,
  //Domain8
  blogs: `${Domain8}/learn/api/postLatest`,
  GetL5FolioList: `${DomainCDS}api/Master/GetL5FolioList`,
  GetSchemeDetails: `${DomainCDS}api/Master/GetSchemes`,
  GetMaster: `${DomainCDS}api/Master/GetMasters`
};
//let FinalURL = window.globalConfig.enableAnandrathi ? anandRathiURL : finnovateURL
let FinalURL = finnovateURL
export default FinalURL
