import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import MyPlanImage3 from "../../utils/assets/MyPlanImage3.jpg";
import AdvisoryAccount from './advisoryaccount';
import InvestmentAdvisory from './investmentadvisory';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { withRouter } from "react-router";
import StepConnector from '@material-ui/core/StepConnector';
import clsx from 'clsx';
import Check from '@material-ui/icons/Check';
import Payment from "./payment";
import { getRiaFlowOffLinePaymentReport } from "../../redux/actions/riaflowofflinepayment/riaflowofflinepayment"
import { getRiaFlowOnLinePaymentReport } from "../../redux/actions/riaflowonlinepayment/riaflowonlinepayment"
import Buttons from "../custombutton/button";
import { getPurchasedPlan } from "../../redux/actions/profile/profile";
import { gstValidation, replaceNull } from "../../../src/utils/commonfunction";
import { storeGstNumberBankDetails } from '../../redux/actions/storegstnumberbankdetails/storegstnumberbankdetails';
import ErrorBoundary from '../Errorhandling/Errorhandle';
import getRiaPaymentDetails from '../../api/riaflowonlinepaymentapi/payment';
import moment from 'moment';
import Snackbar from '../snackbar/snackbar';
const useStyles = makeStyles((theme) => ({
    desktopResponsive: {
        display: 'block !important',
        [theme.breakpoints.down("600")]: {
            display: 'none !important',
        },
    },
    gridContainer: {
        display: "grid",
        gridTemplateColumns: '34% 4% 53%',
        gap: 10
        // [theme.breakpoints.down('xs')]: {
        //     display: "grid",
        //     gridTemplateColumns: '100%',
        // },

    },
    gridContainerSecond: {
        display: "grid",
        gridTemplateColumns: '49% 49%',
        marginRight: '5%',
        textAlign: 'left',
        // [theme.breakpoints.down('xs')]: {
        //     display: "grid",
        //     gridTemplateColumns: '100%',
        // }
    },
    continueButton: {
        width: 150,
        textTransform: "none !important",
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: 15,
        fontWeight: 'bold'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    // paper: {
    //     backgroundColor: theme.palette.background.paper,
    //     width: 780,
    //     height: 900,
    // },
    mobileResponsive: {
        display: 'none !important',
        [theme.breakpoints.down("600")]: {
            display: 'block !important',
        },
    },
    frame: {
        marginTop: '15vh'
    }
}));
const useQontoStepIconStyles = makeStyles((theme) => ({
    root: {
        color: window.globalConfig.color.PRIMARY_BLUE,
        display: 'flex',
        height: 78,
        alignItems: 'center',
        width: '100%',
        flexGrow: 1,
    },
    active: {
        color: window.globalConfig.color.BROWN,
    },
    circle: {
        width: 10,
        height: 10,
        borderRadius: '50%',
        backgroundColor: window.globalConfig.color.WHITE,
        borderColor: 'currentColor',
        border: '4px solid',
    },
    completed: {
        width: 20,
        height: 20,
        color: window.globalConfig.color.WHITE,
        zIndex: 1,
        fontSize: 23,
        border: `1px solid ${window.globalConfig.color.GREEN}`,
        borderRadius: "50%",
        backgroundColor: window.globalConfig.color.GREEN
    },
}));
const QontoConnector = withStyles({
    alternativeLabel: {
        top: -38,
        left: 'calc(-7% + 10px)',
        right: 'calc(10% + 10px)',
        color: 'green'
    },
    active: {
        color: window.globalConfig.color.GREEN,

        '& $line': {
            borderColor: window.globalConfig.color.GREEN,

        },
    },
    completed: {
        '& $line': {
            borderColor: window.globalConfig.color.GREEN,

        },
    },
    line: {
        borderColor: window.globalConfig.color.Lightgrayishblue,
        borderTopWidth: 4,
        borderRadius: 2,
        height: 70,
        borderLeftWidth: 3
    },
})(StepConnector);
function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}
function getSteps() {
    return ['', '', ''];
}
function getStepContent(step) {
    switch (step) {
        case 0:
            return "";
        case 1:
            return '';
        case 2:
            return ``;
        default:
            return 'Unknown step';
    }
}
function StepperAdvisory(props) {
    const [activeStep, setActiveStep] = React.useState(0);
    const [AdvisoryButton, setAdvisoryButton] = React.useState(true);
    const [buttonName, setButtonName] = React.useState("Next");
    const [openSnack, setOpenSnack] = React.useState(false);
    const [snackMessage, setSnackMessage] = React.useState("");
    const [investButton, setInvestButton] = React.useState(false);
    const [paymentContinueButton, setPaymentContinueButton] = React.useState(false);
    const [onLineButtonShow, setOnLineButtonShow] = React.useState("Online");
    const [offLineButtonShow, setOffLineButtonShow] = React.useState(false);
    const [planName, setPlanName] = React.useState();
    const [planCode, setPlanCode] = React.useState();
    const steps = getSteps();
    const [open, setOpen] = React.useState(false);
    const [gstValue, setGstValue] = React.useState();
    const [errorGstMessage, setErrorGstMessage] = React.useState('');
    const [errorDropdown, setErrorDropdown] = React.useState('');
    const [list, setList] = React.useState([]);
    const [bankDetails, setBankDetails] = React.useState();
    const [gstTextBoxVisible, setGstTextBoxVisible] = React.useState(true);
    const [paymentContinueLoader, setPaymentContinueLoader] = React.useState(false);
    const [billingAddress, setBillingAddress] = React.useState(false);
    // const []
    const [radioError, setRadioError] = React.useState('')
    const [modeOfPayment, setModeOfPayment] = React.useState('Online')
    const [openSnackBar, setOpenSnackBar] = React.useState(false)
    const [snackBarMessage, setSnackBarMessage] = React.useState('')
    const [subscriptionID, setSubscriptionID] = React.useState(0)

    useEffect(() => {
        setPaymentContinueLoader(false);
        if (props.location.state.data != undefined && props.location.state.data != null && props.location.state.data != "") {
            setPlanName(props.location.state.data.Data[0].plan_name);
            setPlanCode(props.location.state.data.Data[0].plan_code);
        }

        if (props.BankList != undefined) {
            setList([...props.BankList]);
            setBankDetails(props.BankList[0])
        }

        if (props.logindetails.RiskDescription != undefined && props.logindetails.RiskDescription != null && props.logindetails.RiskDescription != "" && props.logindetails.RiskDescription != "NA") {
            setAdvisoryButton(false);
        }
        if (props.riskAnswers.response_data != undefined && props.riskAnswers.response_data != null && props.riskAnswers.response_data != '') {
            setAdvisoryButton(false);
        }
    }, [props.eSignDownloadFile, props.isSigned]);


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);

        if (props.eSignDownloadFile.data != undefined && props.eSignDownloadFile.data != null && props.eSignDownloadFile.data != '') {
            setAdvisoryButton(false);
        } else if (props.isSigned) {
            setAdvisoryButton(false);
        }

    };

    const handleNextSecond = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setAdvisoryButton(false);
        setButtonName("Subscribe Plan")
        //To do empty the online api value in redux
        props.getRiaFlowOnLinePaymentReport();
    };

    const disableSubscribeButton = (value) => {
        // console.log(value)
        setAdvisoryButton(value)
    }

    const back = () => {
        if (activeStep >= 1) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        } else {
            props.history.push(
                labels.Path.MyPlan
            )
            //To do Empty the Reducer, stored PlanCode,PlanName,Amount
            // props.getPurchasedPlan()
        }
        if (props.eSignDownloadFile.data != undefined && props.eSignDownloadFile.data != null && props.eSignDownloadFile.data != '') {
            setInvestButton(true)
        }
        else {
            setInvestButton(false)
        }
    }

    const count = (value) => {
        if (value != undefined) {
            setAdvisoryButton(true)
        }
    }

    const visible = (value) => {

        if (value != undefined) {
            setInvestButton(true)
        } else {
            setInvestButton(false)
        }
    }

    const onLineShow = (value) => {
        setModeOfPayment(value)
        if (value != undefined) {
            setOnLineButtonShow(true)
            setOffLineButtonShow(false)
        }
    }

    const offLineShow = (value) => {
        if (value != undefined) {
            setOffLineButtonShow(true)
            setOnLineButtonShow(false)

        }
    }

    const yesButton = (value) => {
        // if (value != undefined) {
        setGstTextBoxVisible(value)
        setErrorGstMessage('')
        // }
    }

    const noButton = (value) => {
        if (value != undefined) {
            setGstTextBoxVisible(false)
            setErrorGstMessage('')
        }
    }
    const getSubscriptionID = (value) => {
        // console.log(value)
        setSubscriptionID(value)
    }
    const handleNextThird = () => {
        if (modeOfPayment == "Online") {
            // if (bankDetails == '' || bankDetails == undefined || bankDetails == null) {
            //     setErrorDropdown(labels.messages.required)
            //     return false;
            // }          
            if (gstTextBoxVisible == true) {
                if (gstValue == '' || gstValue == undefined || gstValue == null) {
                    setErrorGstMessage(labels.messages.required)
                }
                else if (!gstValidation(gstValue)) {
                    setErrorGstMessage(labels.messages.gst)
                }
                else if (gstValidation(gstValue)) {
                    //ONLINE API CALL
                    if (replaceNull(props.mainDetails[0]?.ClientName, "NA") != "NA" &&
                        planCode != undefined && planCode != null && planCode != '' &&
                        replaceNull(props.mainDetails[0]?.EmailID, "NA") != "NA" &&
                        replaceNull(props.mainDetails[0]?.MobileNo, "NA") != "NA" &&
                        props.formNo != undefined && props.formNo != null && props.formNo != "" &&
                        replaceNull(props.mainDetails[0]?.ClientID, "NA") != "NA") {
                        setPaymentContinueLoader(true);
                        let data = {}
                        data.L3ClientID = props.mainDetails[0]?.ClientID
                        data.PlanID = props.location.state.data.Data[0].PlanID
                        data.PlaceOfSupplyID = billingAddress[0].StateId
                        data.IsHasGSTStatement = true
                        data.SubscriptionStatusID = 1
                        data.SubscriptionID = subscriptionID
                        data.GSTNumber = gstValue
                        data.ActivationDate = moment().format("DD MMM YYYY")
                        data.endDate = moment().add("year", 1).format("DD MMM YYYY")
                        getRiaPaymentDetails(data).then((res) => {
                            if (res.status == "S") {


                                props.history.push({
                                    pathname: labels.Path.RiaPlanComplete,
                                    state: {
                                        data: {
                                            type: "online",
                                            isGstEnable: true,
                                            details: props.location.state.data.Data[0],


                                        }
                                    }
                                })
                                // openRequestedSinglePopup(res.short_url)
                            } else {
                                // else {
                                setOpenSnackBar(true)
                                setSnackBarMessage(res.message)
                                // }
                            }
                        })
                        // props.getRiaFlowOnLinePaymentReport(props.logindetails.UserName, planCode, props.logindetails.EmailID, props.logindetails.PhoneNumber, apiUrl.PaymentComplete, props.formNo, props.logindetails.EmailID, planCode, '', 2, props.logindetails.ClientID)
                        //Store GstValue and bankDetails in Reducer
                        props.storeGstNumberBankDetails({ gstValue, bankDetails })
                        setPaymentContinueLoader(false);
                    }

                }
            }
            else {

                if (
                    planCode != undefined && planCode != null && planCode != '' &&

                    props.formNo != undefined && props.formNo != null && props.formNo != "" &&
                    replaceNull(props.mainDetails[0]?.ClientID, "NA") != "NA") {
                    setPaymentContinueLoader(true);
                    let data = {}
                    data.ClientID = props.mainDetails[0]?.ClientID
                    data.PlanID = props.location.state.data.Data[0].PlanID
                    data.PlaceOfSupplyID = billingAddress[0].StateId
                    data.IsHasGSTStatement = false
                    data.SubscriptionStatusID = 1
                    data.SubscriptionID = subscriptionID
                    data.GSTNumber = ""
                    data.ActivationDate = moment().format("DD MMM YYYY")
                    data.endDate = moment().add("year", 1).format("DD MMM YYYY")
                    getRiaPaymentDetails(data).then((res) => {
                        if (res.status == "S") {


                            props.history.push({
                                pathname: labels.Path.RiaPlanComplete,
                                state: {
                                    data: {
                                        type: "online",
                                        isGstEnable: false,
                                        details: props.location.state.data.Data[0],


                                    }
                                }
                            })
                            // openRequestedSinglePopup(res.short_url)
                        } else {
                            setOpenSnackBar(true)
                            setSnackBarMessage(res.message)
                        }
                    })
                    // props.getRiaFlowOnLinePaymentReport(props.logindetails.UserName, planCode, props.logindetails.EmailID, props.logindetails.PhoneNumber, apiUrl.PaymentComplete, props.formNo, props.logindetails.EmailID, planCode, '', 2, props.logindetails.ClientID)
                    //Store GstValue and bankDetails in Reducer
                    props.storeGstNumberBankDetails({ gstValue, bankDetails })
                    setPaymentContinueLoader(false);
                }
            }

        }
        else {
            //OFFLINE API CALL  
            //Parameters are - FormNo,EmailID,PlanCode,GstNumber,PaymentType,ClientID
            // props.getRiaFlowOffLinePaymentReport(props.logindetails.KYCFormNo, props.mainDetails[0].EmailID, planCode, "AAAAA0000A", 2,props.logindetails.ClientID, props.history)

            // props.history.push({
            //     pathname:
            //         labels.Path.RiaPlanComplete,
            //     state: { data: "Offline", }
            // }
            // );


            props.history.push({
                pathname: labels.Path.RiaPlanComplete,
                state: {
                    data: {
                        type: "offline",
                        isGstEnable: false,
                        details: props.location.state.data.Data[0],
                    }
                }
            })
        }
    }
    const callBack = (val) => {
        setBillingAddress(val)
    }
    const gstChange = (event) => {
        // Also checked Maximum length
        if (event.target.value.length < 16) {
            setGstValue(event.target.value);
            setErrorGstMessage('')
        }

    }

    const handleBankChange = (value) => {
        if (value != undefined) {
            setBankDetails(value);
            setErrorDropdown('');
        }
    }
    const handleClick = () => {
        if (activeStep == 0) {
            handleNext()
        } else if (activeStep == 1) {
            handleNextSecond()
        } else {
            handleNextThird()
        }
    }
    const handlesnanckClose = () => {
        setOpenSnackBar(false)
        setSnackBarMessage("")
    }
    const classes = useStyles();
    try {
        return (
            <ErrorBoundary>
                {/* <Snackbar open={openSnack} severity={'warning'} message={snackMessage} handlesnanckClose={onCloseSnack}></Snackbar> */}
                <div className={classes.gridContainer} style={{ marginTop: "17px", }}>
                    <img src={MyPlanImage3} className={classes.image}></img>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        {activeStep == 0 &&
                            <ArrowBackIcon style={{ color: window.globalConfig.color.BROWN, width: 40, height: 40, cursor: "pointer" }} onClick={back} />
                        }
                        {activeStep == 1 &&
                            <ArrowBackIcon style={{ color: window.globalConfig.color.BROWN, width: 40, height: 40, cursor: "pointer" }} onClick={back} />
                        }
                        <Stepper alternativeLabel activeStep={activeStep} orientation="vertical" connector={<QontoConnector />} style={{ marginTop: '30%' }}>
                            {steps.map((label, index) => (
                                <Step key={label}>
                                    <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                                    <StepContent>
                                        <Typography>{getStepContent(index)}</Typography>
                                    </StepContent>
                                </Step>
                            ))}
                        </Stepper>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                        {activeStep == 0 &&
                            <AdvisoryAccount visible={visible} count={count} location={props.location} />
                        }
                        {activeStep == 1 &&
                            <InvestmentAdvisory visible={visible} getSubscriptionID={getSubscriptionID} disableSubscribeButton={disableSubscribeButton} />
                        }
                        {activeStep == 2 &&
                            <Payment callBack={callBack} subscriptionID={subscriptionID} onLineShow={onLineShow} offLineShow={offLineShow} yesButton={yesButton} noButton={noButton} gstChange={gstChange} values={gstValue} errorGstMessage={errorGstMessage} errorDropdown={errorDropdown} List={list} handleBankChange={handleBankChange} />
                        }
                        <div style={{ display: "grid", gridTemplateColumns: "40% 40% 20%" }}>
                            <div></div>
                            <div></div>
                            <Buttons page="primary" onClick={handleClick} disabled={AdvisoryButton} name={buttonName} />
                        </div>
                    </div>
                </div>
                <Snackbar open={openSnackBar} message={snackBarMessage} severity={"warning"} handlesnanckClose={handlesnanckClose}></Snackbar>
            </ErrorBoundary>
        )
    } catch (err) {
        return <Redirect to={labels.Path.NotFound} />
    }
}
const mapStateToProps = state => ({
    eSignDownloadFile: state.ESignDownload.eSignDownloadOrder != null ? state.ESignDownload.eSignDownloadOrder.length != 0 ? state.ESignDownload.eSignDownloadOrder : [] : [],
    mainDetails: state.ProfileDetails?.AllProfileDetails?.mainDetails != "NA" ? state.ProfileDetails?.AllProfileDetails.mainDetails?.length != 0 ? state.ProfileDetails?.AllProfileDetails?.mainDetails : [] : [],
    logindetails: state.Login.logindetails != null ? state.Login.logindetails.length != 0 ? state.Login.logindetails : [] : [],
    RiaFlowOnlinePayment: state.RiaOnLinePayment.riaFlowOnLinePaymentOrder != null ? state.RiaOnLinePayment.riaFlowOnLinePaymentOrder.length != 0 ? state.RiaOnLinePayment.riaFlowOnLinePaymentOrder : [] : [],
    logindetails: state.Login.logindetails,
    riskAnswers: state.RiskScore.answerOrder != null ? state.RiskScore.answerOrder != undefined ? state.RiskScore.answerOrder : [] : [],
    BankList: state.ProfileDetails?.AllProfileDetails?.clientBankDetails != "NA" ? state.ProfileDetails?.AllProfileDetails?.clientBankDetails?.filter((val) => val.BankAccountNumber != "" && val.BankName != "")?.map((item) => {

        return { "label": `${item.BankAccountNumber}-${item.BankName}`, "name": item.BankName, "value": item.ID, "isChecked": item.IsDefault }

    }) : [],
    isSigned: state.ESignDownload.isSigned,
    formNo: state.Login.formNo,


})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getRiaFlowOffLinePaymentReport, getRiaFlowOnLinePaymentReport, getPurchasedPlan, storeGstNumberBankDetails }, dispatch)
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StepperAdvisory))