import * as types from '../../constants/actionsType'
import { bindActionCreators } from 'redux';
import labels from '../../../utils/constants/labels';
import { replaceNull } from '../../../utils/commonfunction';
const initialState = {
  transactions: [],
  users: [],
  MFOrder: [],
  upCommingSIPs: [],
  AMOOrder: [],
  transaction: [],
  Mandate: [],
  transactionList: [],
  portfolioSummary: [],
  portfolioChart: [],
  upCommingTransaction: [],
  isLoadingupCommingTransaction: false,
  isLoadingportfolioChart: false,
  isLoadingportfolioSummary: false,
  isLoadingtransactionList: false,
  recentTransaction: [],
  portfolioallocation: [],
  portfolioGroupProduct: [],

  //EQUITY

  equity_transactions: [],
  equity_users: [],
  equity_MFOrder: [],
  equity_upCommingSIPs: [],
  equity_AMOOrder: [],
  equity_transaction: [],
  equity_Mandate: [],
  equity_transactionList: [],
  equity_portfolioSummary: [],
  equity_portfolioChart: [],
  equity_upCommingTransaction: [],
  equity_isLoadingupCommingTransaction: false,
  equity_isLoadingportfolioChart: false,
  equity_isLoadingportfolioSummary: false,
  equity_isLoadingtransactionList: false,
  equity_recentTransaction: [],
  equity_portfolioallocation: [],
  equity_portfolioGroupProduct: []
}
const color = labels.chartColor
const reducer = (state = initialState, action) => {
  switch (action.type) {


    case types.PORTFOLIO_ALLOCATION:
      let product = action.payload == undefined || action.payload == null || action.payload.length == 0 || action.payload.summary == undefined || action.payload.summary.length == 0 ? [] : action.payload.summary.filter((ele, ind) => ind === action.payload.summary.findIndex(elem => elem.Product === ele.Product))
      // let gropProduct = product.length != 0 ? product.map(i => {

      let gropProduct = product.length != 0 ? product.map(i => {

        return {
          product: i.Product, value:
            action.payload.summary.filter(item => item.Product == i.Product)
        }
      }) : []
      // console.log(action.payload == undefined || action.payload == null || action.payload.length == 0 || action.payload.chart == undefined || action.payload.chart.length == 0 ? state.portfolioChart : replaceNull(action.payload.chart, "NA").map((chartvalue, index) => {

      //   return { name: chartvalue.Product, value: chartvalue.Percentofallocation == "NA" ? 0 : Number(chartvalue.Percentofallocation), color: color[chartvalue.ProductId] }
      // }), action.payload != undefined || action.payload != null || action.payload.length != 0 ? gropProduct.map((i, index) => {
      //   return { scheme: i.value[0].NoOfScrips, xirrPercentage: i.value[0].ProductXIRR, investValue: i.value[0].TotalInvestmentValue, marketValue: i.value[0].TotalCurrentValue, prestage: (Number((i.value.reduce((a, v) => a = a + v.XIRR, 0))) / i.value.length), unrealised: i.value[0].TotalCurrentGainLoss, name: i.product, color: color[i.value[0].ProductId] }
      // }) : state.portfolioSummary)
      return {
        ...state,

        portfolioChart: action.payload == undefined || action.payload == null || action.payload.length == 0 || action.payload.chart == undefined || action.payload.chart.length == 0 ? state.portfolioChart : replaceNull(action.payload.chart, "NA").map((chartvalue, index) => {

          return { name: chartvalue.Product, value: chartvalue.Percentofallocation == "NA" ? 0 : Number(chartvalue.Percentofallocation), color: color[chartvalue.ProductId] }
        }),

        portfolioSummary: action.payload != undefined || action.payload != null || action.payload.length != 0 ? gropProduct.map((i, index) => {
          return { scheme: i.value[0].NoOfScrips, xirrPercentage: i.value[0].ProductXIRR, investValue: i.value[0].TotalInvestmentValue, marketValue: i.value[0].TotalCurrentValue, prestage: (Number((i.value.reduce((a, v) => a = a + v.XIRR, 0))) / i.value.length), unrealised: i.value[0].TotalCurrentGainLoss, name: i.product, color: color[i.value[0].ProductId] }
        }) : state.portfolioSummary,
        isLoadingportfolioChart: false,
        isLoadingportfolioSummary: false,
      };
    case types.GET_PORTFOLIO:
      let products = action.data == null || action.data == undefined || action.data.length == 0 ? [] : action.data.summary.filter((ele, ind) => ind === action.data.summary.findIndex(elem => elem.Product === ele.Product))
      let gropProducts = products.length != 0 ? products.map(i => {
        return {
          product: i.Product, value:
            action.data.summary.filter(item => item.Product == i.Product)
        }
      }) : []
     
      return {
        ...state,

        portfolioChart: action.data == null || action.data == undefined || action.data.length == 0 ? state.portfolioChart : action.data.chart.map((chartvalue) => {
          return { name: chartvalue.Product, value: Number(chartvalue.Percentofallocation) }
        }),

        portfolioSummary: action.data == null || action.data == undefined || action.data.length == 0 ? state.portfolioSummary : gropProducts.map(i => {
          return { scheme: i.value[0].NoOfScrips, xirrPercentage: i.value[0].ProductXIRR, investValue: i.value[0].TotalInvestmentValue, marketValue: i.value[0].TotalCurrentValue, prestage: (Number((i.value.reduce((a, v) => a = a + v.XIRR, 0))) / i.value.length), unrealised: i.value[0].TotalCurrentGainLoss, name: i.product, color: color[i.value[0].ProductId] }
        }),
        // isLoadingportfolioChart: false,
        // isLoadingportfolioSummary: false,
      };
    case types.LOADER_PORTFOLIO:
      return {
        ...state,
        isLoadingportfolioChart: action.payload,
        isLoadingportfolioSummary: action.payload,
      }
    case types.EMPTY_PORTFOLIO:

      return {
        ...state,
        transactions: [],
        users: [],
        MFOrder: [],
        upCommingSIPs: [],
        AMOOrder: [],
        transaction: [],
        Mandate: [],
        transactionList: [],
        portfolioSummary: [],
        portfolioChart: [],
        upCommingTransaction: [],
        isLoadingupCommingTransaction: false,
        isLoadingportfolioChart: false,
        isLoadingportfolioSummary: true,
        isLoadingtransactionList: true,
        recentTransaction: [],
        portfolioallocation: [],
        portfolioGroupProduct: [],

      }
    case types.EQUITY_PORTFOLIO:
      let equity_product = action.payload != undefined && action.payload != null && action.payload.length != 0 && action.payload.summary != null && action.payload.summary.length != 0 ? action.payload.summary.filter((ele, ind) => ind === action.payload.summary.findIndex(elem => elem.Product === ele.Product)) : []
      let equity_gropProduct = equity_product.length != 0 ? equity_product.map(i => {
        return {
          product: i.Product, value:
            action.payload.summary.filter(item => item.Product == i.Product)
        }
      }) : []
      return {
        ...state,

        equity_portfolioChart: action.payload != undefined && action.payload != null && action.payload.length != 0 && action.payload.chart != null && action.payload.chart.length != 0 ? action.payload.chart.filter((item) => item.ProductId == 2).length == 0 ? [] : action.payload.chart.filter((item) => item.ProductId == 2).map((chartvalue) => {

          return { name: chartvalue.Product, value: Number(chartvalue.Percentofallocation), color: color[chartvalue.ProductId] }
        }) : state.equity_portfolioChart,

        equity_portfolioSummary: action.payload != undefined && action.payload != null && action.payload.length != 0 ? equity_gropProduct.map(i => {
          return { scheme: i.value[0].NoOfScrips, xirrPercentage: i.value[0].ProductXIRR, investValue: i.value[0].TotalInvestmentValue, marketValue: i.value[0].TotalCurrentValue, prestage: (Number((i.value.reduce((a, v) => a = a + v.XIRR, 0))) / i.value.length), unrealised: i.value[0].TotalCurrentGainLoss, name: i.product, color: color[i.value[0].ProductId] }
        }) : state.equity_portfolioSummary,
        equity_isLoadingportfolioChart: false,
        equity_isLoadingportfolioSummary: false,
      };

    case types.LOADER_EQUITY_PORTFOLIO:
      return {
        ...state,
        equity_isLoadingportfolioChart: true,
        equity_isLoadingportfolioSummary: true,
      }
    case types.EMPTY_EQUITY_PORTFOLIO:

      return {
        ...state,
        equity_transactions: [],
        equity_users: [],
        equity_MFOrder: [],
        equity_upCommingSIPs: [],
        equity_AMOOrder: [],
        equity_transaction: [],
        equity_Mandate: [],
        equity_transactionList: [],
        equity_portfolioSummary: [],
        equity_portfolioChart: [],
        equity_upCommingTransaction: [],
        equity_isLoadingupCommingTransaction: false,
        equity_isLoadingportfolioChart: false,
        equity_isLoadingportfolioSummary: true,
        equity_isLoadingtransactionList: true,
        equity_recentTransaction: [],
        equity_portfolioallocation: [],
        equity_portfolioGroupProduct: []
      }
    default:
      return state
  }
}
export default reducer