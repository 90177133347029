import React, { useState, useEffect } from 'react';
import AppBar from '@material-ui/core/AppBar';
import CalenderImage from '../../utils/assets/images/img13-over.png';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import colors from '../../utils/colors/colors';
import Card from '@material-ui/core/Card';
import Label from '../../utils/constants/labels'
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import classNames from 'classnames';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: window.globalConfig.color.PRIMARY,
    // marginTop: '-1.9em',
    paddingTop: "6%",
    height: "10px",

  },
  toolbars: {
    flex: 1,
    minHeight: 30,
    alignItems: 'flex-start',
    marginLeft: 10
  },
  titles: {
    justifyContent: 'flex-start',
    fontSize: 24,
    fontWeight: 'bold',
    color: window.globalConfig.color.WHITE,
    paddingTop: '18px 0',
    float: "left",
    marginLeft: -20
  },
  subtitles: {
    fontSize: 14,
    marginLeft: -20,
    color: window.globalConfig.color.cyanBluish,
    paddingTop: '18px 0',
  },
  tab: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    //marginLeft:25
  },
  card: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: 5,
    marginTop: 1,
    marginRight: 10,
    borderRadius: 1
  },
  menu: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 25,
    marginBottom: 20,
    marginLeft: -20
  },
  right: {
    float: "right",
    paddingTop: "24px",
    color: window.globalConfig.color.cyanBluish,
    position: "relative",
    fontSize: 13
  },
  date: {
    margin: 5,
    fontWeight: "bold"
  },
  dateContainer: {
    flex: 1
  },
  cardroot: {
    minWidth: 275,
  },
  cardbullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  cardtitle: {
    fontSize: 14,
  },
  cardpos: {
    marginBottom: 12,
  },


}));
function AdminToolBar(props) {
  const classes = useStyles();
  useEffect(() => {
    //    (props.login)
  }, []);
  return (
    <div>
      <div>
        <div className={classes.root}>
          <AppBar position="static" color="transparent">
            <Toolbar className={classes.toolbars}>
            </Toolbar>
            <Toolbar className={classes.card}>
              <Grid item xs={10} md={10}>
                <div className={classes.mainviews}>
                  <Grid container style={{ width: "100%" }}>
                    <Grid item md={4} xs={2} style={{ width: "30%" }} >
                    </Grid>
                    <Grid item md={3} xs={2}>

                    </Grid>
                    <Grid item md={3} xs={2}>

                    </Grid>
                    <Grid item md={1} xs={2}>
                      <div>

                      </div>
                    </Grid>
                    <Grid item md={1} xs={4} className={classNames(classes.span)}  >
                      <span >

                        {/* <SearchIcon /> */}
                      </span>
                    </Grid>
                  </Grid>
                </div>
              </Grid>
            </Toolbar>
          </AppBar>
        </div>
      </div>

      <Grid className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={1}>
          </Grid>
          <Grid item xs={10} style={{ marginTop: -36, position: "absolute", width: "100%", marginLeft: 110 }}>
          
            {props.message != "" ?
              <Card className={classes.cardroot} id="card_shadow">
                <CardContent>
                  <Typography className={classes.cardtitle} color="textSecondary" gutterBottom>
                  </Typography>
                  <Typography variant="h5" component="h2">
                  </Typography>
                  <Typography className={classes.cardpos} color="textSecondary">
                  </Typography>
                  <Typography variant="body2" component="p">
                    {props.message}
                  </Typography>
                </CardContent>
                <CardActions>
                </CardActions>
              </Card> : ""}

          </Grid>
          <Grid item xs={1}>
          </Grid>
        </Grid>
      </Grid>


    </div>




  );
}
const mapStateToProps = state => ({
  login: state.Login.loginError,
  // admin: state.Login.adminmessage,
  ekycmessage: state.Login.ekycmessage
})
export default connect(mapStateToProps, "")(AdminToolBar)


