//page created by dulcy
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core';
import CommonDropdown from '../../components/dropdown/dropdownschemes'
import Checkbox from '@material-ui/core/Checkbox';
import Label from '../../utils/constants/labels'
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Button from '../../components/custombutton/button';
import pdfImage from '../../utils/assets/images/pdf-file-format-symbol-2.png';
import xlImage from '../../utils/assets/images/xls-open-file-format.png';
import getAllcommonDownloadDetails from '../../api/commondownload/commondownload';
import getAllAccountstatementDetails from '../../api/accountstatement/accountstatement';
import constants from '../../utils/constants/apiconstant';
import CommonSnackbar from '../../components/snackbar/snackbar'
import Loader from '../../components/loader/loader'
import CommonCheckbox from '../../components/checkbox/checkbox';
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyle from '../../utils/stylesheets/table/commontablestyle'
import apiurl from '../../api/url/apiurl';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import CommonHeader from '../../components/report_common_header/report_common_header';
import DatePicker from '../../components/datepickers/date_pickers';
import NoData from '../../components/nodata/nodata';
import labels from '../../utils/constants/labels';
import Avatars from '@material-ui/core/Avatar';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import { Redirect } from 'react-router';
import colors from '../../utils/colors/colors';
import { EncryptURL, NegativeFinding, replaceNull } from '../../utils/commonfunction';
import { MContext } from '../../components/pagination/renderpage';
import Pagination from '../../components/pagination/renderpage';
import GetL5FolioList from '../../api/dropdown/l5foliolist';
import secureStorage from '../../utils/securestorage';
import { Asset } from '../../utils/mainasset';
const styles = theme => ({
    mobileResponsive: {
        display: "none",
        [theme.breakpoints.down('900')]: {
            display: "block"
        }
    },
    label: {

        paddingBottom: 6,
        fontWeight: "normal",
        // display: "block",
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: "14px",
        // textAlign: "left"

    },
    buttonAccountStatement: {
        margin: "0% 4% 0% -2%",
    },
    contentcolumnAccountstatementequity: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "66%",
        margin: "0% 5% 0% 20%",
        [theme.breakpoints.down('900')]: {
            width: "66%",
            margin: "0% 5% 0% 17%",
        },
        [theme.breakpoints.down('600')]: {
            width: "89%",
            margin: "0%",
        },
    },
    AccountStatementmaincontainer: {
        width: "94%",
        height: "auto",
        marginBottom: 60,
        marginTop: "5%",
        padding: "2%",
        borderRadius: 10,
        boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.3)",
        marginLeft: "8px",
        [theme.breakpoints.down('900')]: {
            width: "87%"
        },
    },
    accountstatementCard: {
        margin: '0% 0% 0% 14%',
        [theme.breakpoints.down("md")]: {
            margin: 'unset',
        }
    },
    right: {
        textAlign: "right"
    },
    left: {
        textAlign: "left"
    },
    buttoncontainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        // margin: "3% 0% 0% -1%",
        width: '100%', height: 46,
        gap: 30
    },
    AccountStatementmaincontainerResponsive: {
        width: "100%",
        height: "100%",
        boxShadow: "0 0 4px 0 rgb(19 89 161 / 18%)",
        [theme.breakpoints.down("928")]: {
            marginBottom: "23%"
        },
    },
    firstGrid: {
        [theme.breakpoints.down('900')]: {
            gridArea: "firstGrid"
        }
    },
    secondGrid: {
        [theme.breakpoints.down('900')]: {
            gridArea: "secondGrid"
        }

    },
    thirdGrid: {
        [theme.breakpoints.down('900')]: {
            gridArea: "thirdGrid"
        }

    }, fourthGrid: {
        [theme.breakpoints.down('900')]: {
            gridArea: "fourthGrid"
        }

    }, fifthGrid: {
        [theme.breakpoints.down('900')]: {
            gridArea: "fifthGrid"
        }

    }, sixthGrid: {
        [theme.breakpoints.down('900')]: {
            gridArea: "sixthGrid"
        }

    },
    desktopResponsive: {
        // display: "block",
        [theme.breakpoints.down('900')]: {
            display: "none"
        }
    },
    // fromdateResponsive: {
    //     height: 35,
    //     width: 242,
    //     textAlign: "left",
    //     border: "1px solid #c7c7c7",
    //     borderRadius: 5,
    //     paddingTop: "5%",
    //     paddingLeft: "2%",
    //     paddingRight: "2%",
    //     marginBottom: '5%',
    // },
    // todateResponsive: {
    //     height: 39,
    //     width: 242,
    //     textAlign: "left",
    //     border: "1px solid #c7c7c7",
    //     marginLeft: "26%",
    //     borderRadius: 5,
    //     paddingTop: "4%",
    //     paddingLeft: "2%",
    //     paddingRight: "2%",
    //     cursor: "pointer",
    // },
    buttoncontainerResponsive: {
        display: "flex", flexDirection: "row", justifyContent: "space-evenly",
        marginTop: 13,
        paddingBottom: 20,
        [theme.breakpoints.down("xs")]: {
            gap: '5%',
        },
    },
    PdfImagestyle: {
        height: 35,
        width: 35,
        cursor: "pointer",
        display: 'none',
        [theme.breakpoints.down("900")]: {
            display: 'block',
        }
    },
    xlsxImagestyle: {
        height: 35,
        width: 35,
        cursor: "pointer",
        display: 'none',
        [theme.breakpoints.down("900")]: {
            display: 'block',
        }
    },
    foliocontainerResponsive: {
        backgroundColor: window.globalConfig.color.AliceBlueLight,
        padding: "5% 2% 5% 5%",
        borderRadius: '6px 0px 0px 0px',
        color: window.globalConfig.color.PRIMARY_BLUE,
        fontSize: '15px',
        fontWeight: '600',
        margin: "0px 10px"
    },
    mobiletodate: {
        [theme.breakpoints.down("600")]: {
            display: 'flex !important',
            marginTop: '5%',
        },
    },
    SchemeNameResponsive: {
        display: 'flex', flex: 1, justifyContent: "flex-start"
    },
    folioNoResponsive: {
        display: 'flex', flex: 1, justifyContent: "flex-start", marginTop: '3%',
    },
    AmountResponsive: {
        display: 'flex', flex: 1, justifyContent: "flex-end"
    },
    popover: {
        display: "none"

    },
    datepickerresponsive: {

    },

    equityDatePicker: {
        display: "grid", gridTemplateColumns: "48% 48%", width: "100%", gap: '10px',
        marginTop: 10,
        [theme.breakpoints.down('900')]: {
            display: "flex",
            flexDirection: "column"
        }
    },
    withsoldCheckbox: {
        position: 'absolult',
        marginTop: '10px',
    },
    mutalfundaccountstatementCheckBox: {
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
        [theme.breakpoints.down('900')]: {
            justifyContent: 'revert',
            marginTop: '1pc',
        },
        [theme.breakpoints.down('600')]: {
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
        },
    },
    accountstatementDropdown: {
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
        marginTop: '2pc',
        [theme.breakpoints.down('600')]: {
            display: 'flex',
            flexDirection: 'column',
            rowGap: '10px',
            marginTop: '0px',
        },
    },
    dropdownWidth: {
        width: '90%',
        [theme.breakpoints.down('600')]: {
            width: '90%',
        },
    },
    mutalFundcard: {
        display: "grid",
        gridTemplateColumns: "20% 60% 20%",

        gap: 10,
        [theme.breakpoints.down('900')]: {
            gridTemplateColumns: "1% 86% 1%",
        }
        // marginBottom: '-25px',
        // [theme.breakpoints.down('600')]: {
        //     margin: '0% 8% 0% 16%',
        // },
    },
    equityCard: {
        // [theme.breakpoints.down('600')]: {
        //     margin: '0% 8% 0% 16%',
        // },
    },
    folioTextBox: {
        width: '93%',
        margin: '2% 0% 3% 16.4%',
        [theme.breakpoints.down('900')]: {
            margin: '3% 0% 8% 16%',
        },
        [theme.breakpoints.down('600')]: {
            margin: '6% 8% 8% 0',
        },
    },
    CommonDropdown: {
        width: "110%",
        [theme.breakpoints.down('900')]: {
            width: "97%",
        },
    },

    scrolled: {
        position: "fixed",
        top: 65,
        width: "72.5%"
    },
    firstRowGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)",
        gap: 10,
        [theme.breakpoints.down('900')]: {
            gridTemplateColumns: "repeat(1,1fr)",
        }
    },
    secondRowGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)",
        gap: 10,
        [theme.breakpoints.down('900')]: {
            gridTemplateColumns: "repeat(1,1fr)",
        }

    },
    thirdRowGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)",
        gap: 10,
        [theme.breakpoints.down('900')]: {
            gridTemplateColumns: "repeat(1,1fr)",
        }
    },
    fourthRowGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        gap: 10,
        [theme.breakpoints.down('900')]: {
            display: "flex",
            flexDirection: "row-reverse"
            // alignItems: "repeat(1,1fr)",
        }
    },
    download: {
        [theme.breakpoints.down('900')]: {
            display: "none"
        }

    },
    overrideTwoSplitContainer: {
        [theme.breakpoints.down('900')]: {
            display: "grid",
            gridTemplateColumns: "repeat(1,1fr)",
            justifyItems: "flex-start"
        }
    }
});
let foliodropdown = []
class AccountStatement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: true,
            anchorEl: null,
            anchorEl1: null,
            open: false, accpdf: false,
            accexcel: false,
            foliono: "",
            folionolabel: "All Folio",
            since: true,
            fromdate: "",
            todate: moment().format("DD MMM YYYY"),
            fromdate1: moment().format("DD MMM YYYY"),
            todate1: moment().format("DD MMM YYYY"),
            folioNoerror: "",
            fromdateerror: "",
            todateerror: "",
            view: false,
            viewloader: false,
            WithRedeemedfolio: [],
            withoutredeemedfolio: [],
            showbutton: false,
            addStickyClass: false,
            productId: 1,
            accountList: [],
            isclicked: false,
            accountStatementList: [],
            folioList: [],
            SchemeID: 0
        };
    }
    getFolioList = (productID) => {
        GetL5FolioList(this.props.logindetails.ClientID, this.props.logindetails.SessionID, this.props.L5ID, 0, 0, productID).then((res) => {
            if (res.status == "S") {
                if (res.data.length != 0) {
                    let folio = res.data.map((item, index) => {
                        return { label: item.FolioDescription, value: index + 1, FolioNo: item.FolioNo, SchemeID: item.SchemeID }
                    })
                    this.setState({
                        folioList: [{ label: "All Folio", value: "0", SchemeID: 0 }, ...folio]
                    })
                } else {
                    this.setState({ folioList: [] })
                }

            } else {
                this.setState({ folioList: [] })
            }
        })
    }
    async componentDidMount() {
        let abortController;
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        window.addEventListener('scroll', this.handleScroll)
        foliodropdown = this.props.withoutredeemed
        if (this.props.flag == "mutualfunds") {
            this.getFolioList(1)
            await this.setState({
                productId: 1,
            });
        } else if (this.props.flag == "equity") {
            // this.getFolioList(2)
            await this.setState({
                productId: 2,
            });
        } else {
            // this.getFolioList(3)
            await this.setState({
                productId: 3,
            });
        }

        // this.onclickview()
    }
    handleScroll = () => {
        const offset = window.scrollY;
        if (offset > 650) {
            this.setState({ addStickyClass: true });
        }
        else {
            this.setState({ addStickyClass: false });
        }
    }
    resize() {
        let currentHideNav = (window.innerWidth <= 920);
        if (currentHideNav !== this.state.showbutton) {
            this.setState({ showbutton: currentHideNav });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { dataTab, showwarning, equity_showwarning, L5ID, logindetails, date, ClientID } = this.props;

        if (prevProps.dataTab !== dataTab) {
            this.setState({
                checked: false,
                anchorEl: null,
                anchorEl1: null,
                open: false, accpdf: false,
                accexcel: false,
                foliono: "0",
                since: true,
                fromdate: "",
                folionolabel: "",
                todate: moment().format("DD MMM YYYY"),
                fromdate1: moment().format("DD MMM YYYY"),
                todate1: moment().format("DD MMM YYYY"),
                folioNoerror: "",
                fromdateerror: "",
                todateerror: "", view: false
            })
        }
        // if (prevState.productId != productId) {
        //     if (productId != 2) {
        //         this.getFolioList(productId)
        //     }
        //     this.onclickview()
        // }
        if (prevProps.ClientID != ClientID || prevProps.L5ID != L5ID || prevProps.date != date) {
            if (this.state.productId == 1) {
                this.getFolioList(this.state.productId)
            }
            this.setState({ view: false, accountStatementList: [] })
            // this.onclickview()
        }

    }
    componentWillUnmount() {
        this.setState({
            checked: false,
            anchorEl: null,
            anchorEl1: null,
            open: false, accpdf: false,
            accexcel: false,
            foliono: "",
            since: true,
            fromdate: "",
            todate: moment().format("DD MMM YYYY"),
            fromdate1: moment().format("DD MMM YYYY"),
            todate1: moment().format("DD MMM YYYY"),
            folioNoerror: "",
            fromdateerror: "",
            todateerror: "", view: false, productId: 0
        })

        window.removeEventListener('scroll', this.handleScroll)
        window.removeEventListener("resize", this.resize.bind(this));

    }
    onclickpdf = (ext, filename) => {
        var isValid = false
        if (this.state.since) {
            isValid = true
            this.setState({ fromdate: "" })
        } else {
            if (this.state.fromdate === "") {
                isValid = false
                this.setState({ fromdateerror: `${Label.messages.required}` })
            }
            else {
                isValid = true
            }
        }

        if (isValid) {
            if (ext === "pdf") {
                this.setState({ accpdf: true })
            }
            if (ext === "xlsx") {
                this.setState({ accexcel: true })
            }
            let authToken = ''
            if (!Boolean(secureStorage.getItem(`authToken-${secureStorage.getItem("sessionID")}`))) {
                authToken = labels.key.apiKey
            } else {
                authToken = secureStorage.getItem(`authToken-${secureStorage.getItem("sessionID")}`)
            }
            let nodeToken = secureStorage.getItem(`nodeToken-${secureStorage.getItem("sessionID")}`)

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            
            myHeaders.append('SessionID', secureStorage.getItem("sessionID"));
            myHeaders.append('Access-Control-Allow-Origin', `${window.globalConfig.Domain}`,
            )
            myHeaders.append('Source', Asset.source
            )
            myHeaders.append("Authorization", `Bearer ${nodeToken}`)
            myHeaders.append('Token', labels.key.apiKey)
            myHeaders.append('Export', false)
            myHeaders.append('gw',EncryptURL(apiurl.AccountStatement))
            myHeaders.append('Strict-Transport-Security', 'max-age=31536000; includeSubDomains; preload')
            var raw = JSON.stringify({ "ClientID": this.props.logindetails.ClientID, "AccountID": 0, "FolioNo": this.state.foliono == "" ? "" : this.state.foliono, "WithRedeem": true, "SinceInception": this.state.since, "DownloadAs": ext == "pdf" ? 1 : 2, "FromDate": this.state.since ? moment().format("DD MMM YYYY") : moment(this.state.fromdate).format("DD MMM YYYY"), "ToDate": moment(this.state.todate).format("DD MMM YYYY"), "ProductID": this.state.productId, "FamilyID": 0, "L5ID": this.props.L5ID, "L4ID": 0 });
            var requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };
            fetch("/gw", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status == "S") {
                        getAllcommonDownloadDetails(result.data, ext, filename, this.props.logindetails?.SessionID).then((response) => {
                            if (response === "success") {
                                if (ext === "pdf") {
                                    this.setState({ accpdf: false })
                                }
                                if (ext === "xlsx") {
                                    this.setState({ accexcel: false })
                                }
                            } else {
                                this.setState({ open: true })
                                if (ext === "pdf") {
                                    this.setState({ accpdf: false })
                                }
                                if (ext === "xlsx") {
                                    this.setState({ accexcel: false })
                                }
                            }
                        }).catch(err => {
                            this.setState({ open: true })
                            if (ext === "pdf") {
                                this.setState({ accpdf: false })
                            }
                            if (ext === "xlsx") {
                                this.setState({ accexcel: false })
                            }
                        })
                    } else {
                        this.setState({ open: true })
                        if (ext === "pdf") {
                            this.setState({ accpdf: false })
                        }
                        if (ext === "xlsx") {
                            this.setState({ accexcel: false })
                        }
                    }
                })
                .catch(error => {
                    this.setState({ open: true })
                    if (ext === "pdf") {
                        this.setState({ accpdf: false })
                    }
                    if (ext === "xlsx") {
                        this.setState({ accexcel: false })
                    }
                });
        }
        //}
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };
    handleClick = (e) => {
        if (this.state.since) {
        } else {
            this.setState({ anchorEl: e.currentTarget })
        }
    }
    handlemobileClick = (e) => {
        if (this.state.since) {
        } else {
            this.setState({ anchorEl: e.currentTarget })
        }
    }
    handleClose = () => {
        this.setState({ anchorEl: null })
    }
    handleApply = (date) => {
        this.setState({ fromdate: moment(date).format("DD MMM YYYY"), anchorEl: null, fromdateerror: "" })
    }
    handleClicktodate = (e) => {
        this.setState({ anchorEl1: e.currentTarget })
    }
    handleMobileClicktodate = (e) => {
        this.setState({ anchorEl1: e.currentTarget })
    }
    handleClosetodate = () => {
        this.setState({ anchorEl1: null })
    }
    handleApplytodate = (date) => {
        this.setState({ todate: moment(date).format("DD MMM YYYY"), anchorEl1: null, todateerror: "" })
    }
    dateChangetodate = (value) => {
        this.setState({ todate1: moment(value).format("DD MMM YYYY") })
    }
    dateChange = (value) => {
        this.setState({ fromdate1: moment(value).format("DD MMM YYYY"), todate1: moment().format("DD MMM YYYY") })
    }
    onChangefolio = (e) => {
        // console.log(this.state.folioList)
        if (e[0].label === "All Folio") {
            this.setState({ foliono: "0", folioNoerror: "", folionolabel: e[0].label, SchemeID: 0 })
        } else {
            this.setState({ foliono: e[0].FolioNo, folioNoerror: "", folionolabel: e[0].label, SchemeID: e[0].SchemeID })
        }
    }


    onclickview = () => {
        let isValid = true
        if (!this.state.since) {
            if (this.state.fromdate == "") {
                isValid = false
                this.setState({ fromdateerror: labels.messages.required })
            }
        }
        if (isValid) {

            this.setState({ viewloader: true })
            getAllAccountstatementDetails(this.props.logindetails.ClientID, this.state.todate, this.state.foliono, this.state.since, this.state.fromdate == "" ? moment().format("DD MMM YYYY") : this.state.fromdate, true, this.state.productId, 0, this.props.L5ID, 0, this.state.SchemeID).then(async (response) => {
                this.setState({ viewloader: false })
                let res = replaceNull(response, labels.messages.NA)
                if (res.status === "S") {
                    if (res.dataTable === null) {
                        this.setState({ isclicked: true })
                    }
                    // console.log(res.dataTable.filter((item) => item.Foliono == "8129413/83"))
                    await this.setState({
                        accountStatementList: res.dataTable != null ? res.dataTable.length != 0 && res.dataTable != "NA" ? res.dataTable.sort((a, b) => {
                            var textA = this.state.productId == 1 ? a.SchemeName.toUpperCase() : a.ScripName.toUpperCase();
                            var textB = this.state.productId == 1 ? b.SchemeName.toUpperCase() : b.ScripName.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                            // return new Date(b.TransactionDate) - new Date(a.TransactionDate);
                        }).map((item) => {
                            return { ...item, "SchemeName": this.state.productId == 1 ? item.SchemeName : item.ScripName, }
                        }) : [] : []
                    })
                    //   this.props.getAllAccountStatementMutualfunds(res)
                    this.setState({ view: true })
                    // if (this.state.productId === 1) {

                    // }
                    // else if (this.state.productId === 2) {
                    //     this.props.getAllAccountStatementEquity(res)
                    //     this.setState({ view: true })
                    // }
                    // else if (this.state.productId === 3) {
                    //     this.props.getAllAccountStatementBonds(res)
                    //     this.setState({ view: true })
                    // }

                } else {
                    this.setState({ accountStatementList: [] })
                    //   this.props.getAllAccountStatementMutualfunds(res)
                    this.setState({ view: false })
                    // if (this.state.productId === 1) {
                    //     this.props.getAllAccountStatementMutualfunds(res)
                    //     this.setState({ view: false, })

                    // }
                    // else if (this.state.productId === 2) {
                    //     this.props.getAllAccountStatementEquity(res)
                    //     this.setState({ view: false })
                    // }
                    // else if (this.state.productId === 3) {
                    //     this.props.getAllAccountStatementBonds(res)
                    //     this.setState({ view: false })
                    // }

                    this.setState({ open: true })
                }
            })
        }

    }
    redeemed = () => {
        this.setState({ checked: !this.state.checked })
        foliodropdown = []
        if (!this.state.checked) {
            foliodropdown = this.props.withredeemed
        } else {
            foliodropdown = this.props.withoutredeemed
        }
    }
    SinceInception = () => {
        this.setState({ since: !this.state.since })
        if (this.state.since == false) {
            this.setState({ fromdate: "", fromdateerror: "", todate: moment().format("DD MMM YYYY") })
        }
    }
    render() {
        const { classes } = this.props
        try {
            return (<ErrorBoundary>

                <CommonHeader page={"noHeader"} showfilter={false} />

                <div className={classes.AccountStatementmaincontainer}>
                    <div className={classes.mutalFundcard}>
                        <div></div>
                        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                            <div className={classes.firstRowGrid}>
                                <CommonCheckbox label={"Since Inception  "} checked={this.state.since} onChange={this.SinceInception} />
                                {/* <CommonCheckbox label={this.props.flag === "equity" ? "With Redeemed Quantity" : "With Redeemed Units"} checked={this.state.checked} onChange={this.redeemed} /> */}

                            </div>
                            <div className={classes.secondRowGrid}>
                                <DatePicker
                                    id={"accountstatementMF1"}
                                    handleClick={(e) => this.handleClick(e)}
                                    handleApply={this.handleApply}
                                    showingdate={this.state.fromdate1}
                                    dateChange={this.dateChange}
                                    handleClose={this.handleClose}
                                    maxdate={moment().format("DD MMM YYYY")}

                                    anchorEl={this.state.anchorEl}
                                    error={this.state.fromdateerror}
                                    allowed={this.state.since}
                                    placeholder={"From Date"}
                                    date={this.state.fromdate}
                                    errorStyle={"Account"}
                                />
                                <DatePicker
                                    id={"accountstatementMF2"}
                                    handleClick={(e) => this.handleClicktodate(e)}
                                    handleApply={this.handleApplytodate}
                                    showingdate={this.state.todate1}
                                    mindate={this.state.since ? "01 Jan 1900" : this.state.fromdate1}
                                    maxdate={moment().format("DD MMM YYYY")}
                                    dateChange={this.dateChangetodate}
                                    handleClose={this.handleClosetodate}
                                    anchorEl={this.state.anchorEl1}
                                    error={this.state.todateerror}
                                    allowed={false}
                                    date={this.state.todate}
                                    placeholder={"To Date"} />
                            </div>
                            <div className={classes.thirdRowGrid}>
                                {this.state.productId == 1 && <CommonDropdown
                                    type={"normalwithsearch"}
                                    handleOnChange={this.onChangefolio}
                                    // values={this.state.BankAccounttype}
                                    error={this.state.folioNoerror}
                                    isSearchable={true}
                                    placeholder={"Select"}
                                    autoselect={true}
                                    label={"Folio No"}
                                    height={150}
                                    List={this.state.folioList}
                                    value={this.state.folionolabel}
                                />}
                            </div>
                            <div className={classes.fourthRowGrid}>
                                {/* <div style={{ marginRight: 10, width: "32%" }}> */}
                                <Button name={"View"} page={"primary"} disabled={this.state.viewloader} loader={this.state.viewloader} onClick={this.onclickview} loderAlign="align" marginAlign="0px" />
                                <img xs={4} src={xlImage} className={classes.xlsxImagestyle} page={"solid"} style={{ cursor: this.state.accountStatementList.length != 0 ? "pointer" : "not-allowed" }} onClick={() => this.onclickpdf("xlsx", "Accountstatement.xlsx")} />
                                <img xs={4} src={pdfImage} className={classes.PdfImagestyle} style={{ cursor: this.state.accountStatementList.length != 0 ? "pointer" : "not-allowed" }} page={"solid"} onClick={() => this.onclickpdf("pdf", "Accountstatement.pdf")} />

                                {/* </div>
                                <div style={{ marginRight: 10, width: "34%" }}> */}
                                <div className={classes.download}>
                                    <Button name={"Download PDF"} marginAlig="0px" loader={this.state.accpdf} disabled={this.state.accountStatementList.length != 0 ? false : true} page={"primary"} onClick={() => this.onclickpdf("pdf", "Accountstatement.pdf")} />
                                </div>
                                {/* </div>
                                <div style={{ marginRight: 10, width: "34%" }}> */}
                                <div className={classes.download}>
                                    <Button name={"Download Excel"} marginAlign="0px" page={"primary"} disabled={this.state.accountStatementList.length != 0 ? false : true} loader={this.state.accexcel} onClick={() => this.onclickpdf("xlsx", "Accountstatement.xlsx")} />
                                </div>  {/* </div> */}
                            </div>
                        </div>
                        <div></div>

                    </div>

                    <div style={{ marginTop: '1pc' }}>
                        {/* <Scrolltotop /> */}
                        {this.state.viewloader ?
                            <Loader page="reportmodel" />
                            :
                            <ErrorBoundary>
                                {
                                    this.state.view ?
                                        this.state.accountStatementList.length != 0 ?
                                            <div>
                                                <div className={[this.props.grid, classes.desktopResponsive, classes.tableHeader, this.state.addStickyClass && classes.scrolled].join(' ')} style={{ textAlign: "left", marginRight: 0 }}>

                                                    {this.props.title.map((item) => {
                                                        return (
                                                            <div style={{
                                                                textAlign: item.align,
                                                            }}>
                                                                {item.name}
                                                                {item.subheader != undefined ? <div>{item.subheader}</div> : <div></div>}
                                                            </div>
                                                        )
                                                    })}

                                                </div>
                                                <Pagination data={this.state.accountStatementList}>

                                                    <MContext.Consumer>
                                                        {(context) => {
                                                            return context.filter(
                                                                (ele, ind) =>
                                                                    this.state.productId == 1 ? ind ===
                                                                        context.findIndex(
                                                                            (elem) => elem.SchemeName === ele.SchemeName && elem.Foliono === ele.Foliono
                                                                        ) : ind ===
                                                                    context.findIndex(
                                                                        (elem) => elem.SchemeName === ele.SchemeName)
                                                            ).map((value, mainindex) => {
                                                                return (<div key={mainindex}>
                                                                    <div style={{ paddingRight: 10, marginTop: mainindex == 0 ? 0 : "2%", marginRight: 0 }} className={[classes.TwoSplitContainer, classes.overrideTwoSplitContainer, classes.categoryGroupContainer, mainindex == 0 && classes.borderRadiousFirstIndex].join(' ')} >

                                                                        <div className={classes.categoryMainHeader} style={{ paddingLeft: 10 }}>
                                                                            {value.SchemeName}
                                                                        </div>
                                                                        <div className={classes.categoryMainValue} style={{ paddingRight: 10 }}>
                                                                            {this.state.productId == 1 && Label.Accountstatement.folio + value.Foliono}
                                                                        </div>
                                                                    </div>
                                                                    {/* {console.log(context)} */}
                                                                    {context.filter((val) => this.state.productId == 1 ? val.Foliono == value.Foliono && val.SchemeName == value.SchemeName : val.SchemeName == value.SchemeName).sort((a, b) => {
                                                                        return new Date(a.TransactionDate) - new Date(b.TransactionDate)
                                                                    }).map((item, index) => {
                                                                        return (
                                                                            <div key={index} className={[this.props.grid, classes.subCategoryGroupContainer, index == 0 ? classes.borderRadiousFirstIndex : classes.borderRadiousOtherIndex].join(' ')} style={{ textAlign: "left", borderBottomLeftRadius: context.filter((val) => val.SchemeName == value.SchemeName).length - 1 == index ? 0 : 5, borderBottomRightRadius: context.filter((val) => val.SchemeName == value.SchemeName).length - 1 == index ? 0 : 5, marginRight: 0 }}>
                                                                                <div className={[classes.subCatgoryValue, classes.firstGrid, "firstGrid"].join(" ")} style={{ textAlign: this.props.title[0].align }}>
                                                                                    <div className={[classes.mobileResponsive, classes.label].join(" ")}>{this.props.title[0].name}</div>
                                                                                    {moment(item.TransactionDate).format("DD MMM YYYY")}
                                                                                </div>
                                                                                <div className={[classes.subCatgoryValue, classes.secondGrid, "secondGrid"].join(" ")} style={{ textAlign: this.props.title[1].align }} >
                                                                                    <div className={[classes.mobileResponsive, classes.label].join(" ")}>{this.props.title[1].name}</div>
                                                                                    {item.TransactionType}<br />
                                                                                    {this.props.flag != "mutualfunds" ?
                                                                                        <span style={{ color: item.TransactionNature == "B" ? "green" : "red" }}>{item.TransactionNature == "B" ? "Buy" : "Sell"}</span> : ""}
                                                                                </div>
                                                                                <div className={[classes.subCatgoryValue, classes.thirdGrid, "thirdGrid"].join(" ")} style={{ textAlign: this.props.title[2].align }}>
                                                                                    <div className={[classes.mobileResponsive, classes.label].join(" ")}>{this.props.title[2].name}</div>
                                                                                    <span className={classes.subcategoryRupee}>{Label.currency.Rupee}</span>{NegativeFinding(item.Amount, "", "", "", "number")}
                                                                                </div>
                                                                                <div className={[classes.subCatgoryValue, classes.fourthGrid, "fourthGrid"].join(" ")} style={{ textAlign: this.props.title[3].align }}>
                                                                                    <div className={[classes.mobileResponsive, classes.label].join(" ")}>{this.props.title[3].name}</div>
                                                                                    {NegativeFinding(item.Units, "", "", "", "number")}
                                                                                </div>
                                                                                <div className={[classes.subCatgoryValue, classes.fifthGrid, "fifthGrid"].join(" ")} style={{ textAlign: this.props.title[4].align }}>
                                                                                    <div className={[classes.mobileResponsive, classes.label].join(" ")}>{this.props.title[4].name}</div>
                                                                                    <span className={classes.subcategoryRupee}>{Label.currency.Rupee}</span>{NegativeFinding(item.Nav, "", "", "", "number")}
                                                                                </div>
                                                                                <div className={[classes.subCatgoryValue, classes.sixthGrid, "sixthGrid"].join(" ")} style={{ textAlign: this.props.title[5].align }}>
                                                                                    <div className={[classes.mobileResponsive, classes.label].join(" ")}>{this.props.title[5].name}</div>
                                                                                    {NegativeFinding(item.BalanceUnits, "", "", '', "number")}
                                                                                </div>

                                                                            </div>
                                                                        )
                                                                    })}
                                                                    {/* {console.log(context)} */}
                                                                    {(context.filter((val) => val.SchemeName == value.SchemeName)[0].Footer != undefined && context.filter((val) => val.SchemeName == value.SchemeName)[0].Footer != "NA") &&
                                                                        <div className={classes.displayTotalContainer}
                                                                            style={{ textAlign: "left", borderTopLeftRadius: 0, borderTopRightRadius: 0, backgroundColor: "rgb(244,246,249)", color: colors.LABLE_GRAY, fontSize: 14, padding: 6, minHeight: 50, margin: "auto", display: "flex", marginLeft: 0 }}>
                                                                            {context.filter((val) => val.SchemeName == value.SchemeName)[0].Footer}
                                                                        </div>
                                                                    }
                                                                </div>)
                                                            })
                                                        }}
                                                    </MContext.Consumer>
                                                </Pagination>


                                            </div>
                                            :
                                            <NoData />

                                        :
                                        ""
                                }

                            </ErrorBoundary>
                        }
                        <CommonSnackbar open={this.state.open} message={Label.messages.apierror} handlesnanckClose={this.handlesnanckClose} severity={'warning'} />
                    </div>
                </div>



            </ErrorBoundary>
            )
        }
        catch (err) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => {

    return {
        //     isloader: state.Account.isLoading,
        logindetails: state.Login.logindetails,
        ClientID: state.Login.logindetails.ClientID,
        L5ID: state.Login.L5ID,
        date: state.Login.date

    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch)
}
const mergedStyle = combineStyles(styles, CommonTableStyle)
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(AccountStatement))
