import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCartOutlined';
import { Toolbar, Badge } from '@material-ui/core';
import Label from '../../utils/constants/labels';
import { withRouter } from "react-router-dom";
import CartIcon from '../../utils/assets/images/img44.png'
import colors from '../../utils/colors/colors';
const styles = theme => ({
    note: {
        color: `${window.globalConfig.color.LABLE_GRAY} !important`,
        margin: "0px !important",
        fontSize: "16px !important",
        fontWeight: "normal !important",
        padding: "10px 0px"
    },
    icon: {
        // fontSize: "4em",
        width: "fit-content",
        height: "fit-content"
    },
    btnApply: {
        background: window.globalConfig.color.BROWN,
        marginLeft: "7px",
        padding: 0,
        lineHeight: "36px",
        color: window.globalConfig.color.WHITE,
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "36px",
        borderRadius: "5px",
        padding: "5px 20px",
        border: "1px solid transparent",
        cursor: "pointer"
    },
    badge: {
        padding: 1
    },
    root: {
        padding: "2% 10% 3% 10%",
        display: "flex",
        flexDirection: "column",
        gap: 10,
        justifyContent: "center",
        alignItems: "center"
    },
    button: {
        // marginTop: "50px"
    },

});
class Cart extends React.Component {
    renderMore = () => {
        if(this.props.page=="basket"){
            this.props.history.push(Label.Path.Basket)

        }else{
            this.props.history.push(Label.Path.Scheme)

        }

    };
    render() {
        const { classes } = this.props;
        return <div className={classes.root}>
            <img src={CartIcon} className={classes.icon}></img>
            <span className={classes.note}> {Label.cart.emptyCart}</span>

            <button className={classes.btnApply} onClick={this.renderMore}> {this.props.page == "basket" ? "Search Baskets" : Label.cart.moreButton} </button>

        </div>
    }
}
export default withRouter(withStyles(styles)(Cart))
