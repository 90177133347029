import axios from 'axios'
import Networking from '../../utils/api/apiaccess';
import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
/* name should be camel case in all function */
function getAllHeaderDetails(Account, AccountLevel, ClientID, ReportDate, AsOnDate,ProductID,FamilyID,L5ID,L4ID) {

  let data = {};
  data.Account = Account
  data.AccountLevel = AccountLevel
  data.ClientID = Number(ClientID)
  data.ReportDate = ReportDate
  data.AsOnDate = AsOnDate
  data.ProductID = ProductID
  data.FamilyID = FamilyID
  data.L5ID = L5ID
  data.L4ID = L4ID
  //AsOnDate
  // var config = {
  //   method: 'post',
  //   url: ApiValues.HeaderDetails,
  //   headers: { 
  //     'Content-Type': 'application/json'
  //   },
  //   data : data
  // };
  
  // return axios(config)
  // .then(function (response) {
  //   return response.data;
  // })
  // .catch(function (error) {
  //   return { "status": "F", "message": labels.messages.apierror }
  // });
return Networking.PostApi(ApiValues.HeaderDetails,data).then(result => { return result })
.catch(function (error) {
  return { "status": "F", "message": labels.messages.apierror }
});
}
export default getAllHeaderDetails
