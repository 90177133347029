import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import colors from '../../utils/colors/colors';
export default function RadioButtons(props) {
    // const [value, setValue] = React.useState('');

    // const handleChange = (event) => {
    //     setValue(event.target.value);
    // };

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend">{props.label}</FormLabel>
            <RadioGroup aria-label="radio" name="radio1" value={props.value} onChange={props.handleChange}>
                {props.List.map((item) => {
                    return <FormControlLabel value={item.value} control={<Radio style={{ color: colors.BROWN }} />} label={item.label} />
                })}
            </RadioGroup>

        </FormControl>
    );
}
