import ApiValues from '../url/apiurl'
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
var axios = require('axios');
function getValuationRealEstate(data) {
    // var raw = JSON.stringify({
    //     "clientID":ClientID,
    //     "alternatesStagingID": alternatesStagingID,
    //     "valuationAmountValue": valuationAmountValue,
    //     "valuationDate": valuationDate,
    // });

    return Networking.PostApi(ApiValues.AddValutionRealEstateList, data).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });

}
export default getValuationRealEstate
