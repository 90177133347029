import * as types from '../../constants/actionsType'
import { bindActionCreators } from 'redux';
const initialState = {
    xraywithredemption: [],
    xraywithredemptiongrouped: [],
    totalxraywithredemption: [],
    isXrayWithRedemptionLoading: false,

    //EQUITY

    equity_xraywithredemption: [],
    equity_xraywithredemptiongrouped: [],
    equity_totalxraywithredemption: [],
    equity_isXrayWithRedemptionLoading: false,

    //BONDS

    bonds_xraywithredemption: [],
    bonds_xraywithredemptiongrouped: [],
    bonds_totalxraywithredemption: [],
    bonds_isXrayWithRedemptionLoading: false,

    //GOLD BONDS

    gb_xraywithredemption: [],
    gb_xraywithredemptiongrouped: [],
    gb_totalxraywithredemption: [],
    gb_isXrayWithRedemptionLoading: false,

    //FIXED DEPOSITES

    fd_xraywithredemption: [],
    fd_xraywithredemptiongrouped: [],
    fd_totalxraywithredemption: [],
    fd_isXrayWithRedemptionLoading: false,

}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        //mf xray with redemption
        case types.XRAY_WITH_REDEMPTION:
            let grp = action.payload.dataTable != null ? action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SubAssetClassName === ele.SubAssetClassName)) : []
            return {
                ...state,
                xraywithredemption: action.payload.dataTable != null ? action.payload.dataTable.map((item) => {
                    return {
                        ...item, SchemeName: item.SchemeName
                    }
                }) : [],
                xraywithredemptiongrouped: action.payload.dataTable != null ? grp.length != 0 ? grp.sort((a, b) => {
                    var textA = a.SchemeName.toUpperCase();
                    var textB = b.SchemeName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }).map((item) => {
                    return { "Category": item.AssetClassName, "SubAssetclass": item.SubAssetClassName, "AssetSubAsset_BU_InvestedValue": item.AssetSubAsset_BU_InvestedValue, "AssetSubAsset_BU_Currentvalue": item.AssetSubAsset_BU_Currentvalue, "AssetSubAsset_BU_GainLoss": item.AssetSubAsset_BU_GainLoss, "AssetSubAsset_RU_InvestedValue": item.AssetSubAsset_RU_InvestedValue, "AssetSubAsset_RU_Currentvalue": item.AssetSubAsset_RU_Currentvalue, "AssetSubAsset_RU_GainLoss": item.AssetSubAsset_RU_GainLoss, "GrandOtherTotal": item.GrandOtherTotal, "AssetSubAsset_InvestedValue": item.AssetSubAsset_InvestedValue, "AssetSubAsset_Currentvalue": item.AssetSubAsset_Currentvalue, "AssetSubAsset_GainLoss": item.AssetSubAsset_GainLoss, "data": action.payload.dataTable.filter((val) => val.SubAssetClassName === item.SubAssetClassName) }
                }) : [] : []
                ,
                totalxraywithredemption: action.payload.totalTable != null ? action.payload.totalTable : []
                , isXrayWithRedemptionLoading: false
            }
        //empty mf xray with redemption report
        case types.EMPTY_XRAY_WITH_REDEMPTION:
            return {
                ...state,
                xraywithredemption: [],
                xraywithredemptiongrouped: [],
                totalxraywithredemption: [],
                isXrayWithRedemptionLoading: false
            }
        //loader mf xray with redemption
        case types.LOADER_XRAY_WITH_REDEMPTION:
            return {
                ...state,
                isXrayWithRedemptionLoading: action.payload
            }

        //equity xray with sold  units report
        case types.EQUITY_XRAY_WITH_REDEMPTION:
            let equity_grp = action.payload.dataTable != null ?
                action.payload.dataTable.length != 0 ?
                    action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SubAssetClassName === ele.SubAssetClassName)) : [] : []
            return {
                ...state,
                equity_xraywithredemption: action.payload.dataTable != null ?
                    action.payload.dataTable.map((item) => {
                        return {
                            ...item, SchemeName: item.ScripName
                        }
                    }) : [],
                equity_xraywithredemptiongrouped: action.payload.dataTable != null ?
                    equity_grp.length != 0 ? equity_grp.sort((a, b) => {
                        var textA = a.ScripName.toUpperCase();
                        var textB = b.ScripName.toUpperCase();
                        return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                    }).map((item) => {
                        return {
                            "Category": item.AssetClassName,
                            "SubAssetclass": item.SubAssetClassName,
                            "AssetSubAsset_BU_InvestedValue": item.AssetSubAsset_BU_InvestedValue,
                            "AssetSubAsset_BU_Currentvalue": item.AssetSubAsset_BU_Currentvalue,
                            "AssetSubAsset_BU_GainLoss": item.AssetSubAsset_BU_GainLoss,
                            "AssetSubAsset_RU_InvestedValue": item.AssetSubAsset_RU_InvestedValue,
                            "AssetSubAsset_RU_Currentvalue": item.AssetSubAsset_RU_Currentvalue,
                            "AssetSubAsset_RU_GainLoss": item.AssetSubAsset_RU_GainLoss,
                            "GrandOtherTotal": item.GrandOtherTotal,
                            "AssetSubAsset_InvestedValue": item.AssetSubAsset_InvestedValue,
                            "AssetSubAsset_Currentvalue": item.AssetSubAsset_Currentvalue,
                            "AssetSubAsset_GainLoss": item.AssetSubAsset_GainLoss,
                            "data": action.payload.dataTable.filter((val) => val.SubAssetClassName === item.SubAssetClassName).map((item) => {
                                return { ...item, SchemeName: item.ScripName }
                            })
                        }
                    }) : [] : []
                ,
                equity_totalxraywithredemption: action.payload.totalTable != null ? action.payload.totalTable : []
                , equity_isXrayWithRedemptionLoading: false
            }
        //empty equity xray with sold units
        case types.EMPTY_EQUITY_XRAY_WITH_REDEMPTION:
            return {
                ...state,
                equity_xraywithredemption: [],
                equity_xraywithredemptiongrouped: [],
                equity_totalxraywithredemption: [],
                equity_isXrayWithRedemptionLoading: false
            }
        //loader equity xrey with sold units
        case types.LOADER_EQUITY_XRAY_WITH_REDEMPTION:
            return {
                ...state,
                equity_isXrayWithRedemptionLoading: action.payload
            }

        // bonds xray with redmption
        case types.BONDS_XRAY_WITH_REDEMPTION:
            let bonds_grp = action.payload.dataTable != null ? action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SubAssetClassName === ele.SubAssetClassName)) : []
            return {
                ...state,
                bonds_xraywithredemption: action.payload.dataTable != null ? action.payload.dataTable.map((item) => {
                    return {
                        ...item, SchemeName: item.ScripName
                    }
                }) : [],
                bonds_xraywithredemptiongrouped: action.payload.dataTable != null ? bonds_grp.length != 0 ? bonds_grp.sort((a, b) => {
                    var textA = a.ScripName.toUpperCase();
                    var textB = b.ScripName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }).map((item) => {
                    return {
                        "Category": item.AssetClassName, "SubAssetclass": item.SubAssetClassName, "AssetSubAsset_BU_InvestedValue": item.AssetSubAsset_BU_InvestedValue, "AssetSubAsset_BU_Currentvalue": item.AssetSubAsset_BU_Currentvalue, "AssetSubAsset_BU_GainLoss": item.AssetSubAsset_BU_GainLoss, "AssetSubAsset_RU_InvestedValue": item.AssetSubAsset_RU_InvestedValue, "AssetSubAsset_RU_Currentvalue": item.AssetSubAsset_RU_Currentvalue, "AssetSubAsset_RU_GainLoss": item.AssetSubAsset_RU_GainLoss, "GrandOtherTotal": item.GrandOtherTotal, "AssetSubAsset_InvestedValue": item.AssetSubAsset_InvestedValue, "AssetSubAsset_Currentvalue": item.AssetSubAsset_Currentvalue, "AssetSubAsset_GainLoss": item.AssetSubAsset_GainLoss, "data": action.payload.dataTable.filter((val) => val.SubAssetClassName === item.SubAssetClassName).map((item) => {
                            return { ...item, SchemeName: item.ScripName }
                        })
                    }
                }) : [] : []
                ,
                bonds_totalxraywithredemption: action.payload.totalTable != null ? action.payload.totalTable : []
                , bonds_isXrayWithRedemptionLoading: false
            }
        //empty bonds xray with redemption report
        case types.EMPTY_BONDS_XRAY_WITH_REDEMPTION:
            return {
                ...state,
                bonds_xraywithredemption: [],
                bonds_xraywithredemptiongrouped: [],
                bonds_totalxraywithredemption: [],
                bonds_isXrayWithRedemptionLoading: false,
            }
        //loader xray with redemption
        case types.LOADER_BONDS_XRAY_WITH_REDEMPTION:
            return {
                ...state,
                bonds_isXrayWithRedemptionLoading: action.payload
            }
        //goldbonds xray with sold  units report
        case types.GB_XRAY_WITH_REDEMPTION:
            let gb_grp = action.payload.dataTable != null ? action.payload.dataTable.length != 0 ? action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SubAssetClassName === ele.SubAssetClassName)) : [] : []

            return {
                ...state,
                gb_xraywithredemption: action.payload.dataTable != null ? action.payload.dataTable.map((item) => {
                    return {
                        ...item, SchemeName: item.ScripName
                    }
                }) : [],
                gb_xraywithredemptiongrouped: action.payload.dataTable != null ? gb_grp.length != 0 ? gb_grp.sort((a, b) => {
                    var textA = a.ScripName.toUpperCase();
                    var textB = b.ScripName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }).map((item) => {
                    return {
                        "Category": item.AssetClassName, "SubAssetclass": item.SubAssetClassName, "AssetSubAsset_BU_InvestedValue": item.AssetSubAsset_BU_InvestedValue, "AssetSubAsset_BU_Currentvalue": item.AssetSubAsset_BU_Currentvalue, "AssetSubAsset_BU_GainLoss": item.AssetSubAsset_BU_GainLoss, "AssetSubAsset_RU_InvestedValue": item.AssetSubAsset_RU_InvestedValue, "AssetSubAsset_RU_Currentvalue": item.AssetSubAsset_RU_Currentvalue, "AssetSubAsset_RU_GainLoss": item.AssetSubAsset_RU_GainLoss, "GrandOtherTotal": item.GrandOtherTotal, "AssetSubAsset_InvestedValue": item.AssetSubAsset_InvestedValue, "AssetSubAsset_Currentvalue": item.AssetSubAsset_Currentvalue, "AssetSubAsset_GainLoss": item.AssetSubAsset_GainLoss, "data": action.payload.dataTable.filter((val) => val.SubAssetClassName === item.SubAssetClassName).map((item) => {
                            return { ...item, SchemeName: item.ScripName }
                        })
                    }
                }) : [] : []
                ,
                gb_totalxraywithredemption: action.payload.totalTable != null ? action.payload.totalTable : []
                , gb_isXrayWithRedemptionLoading: false
            }
        //empty gold bonds xray with sold units
        case types.EMPTY_GB_XRAY_WITH_REDEMPTION:
            return {
                ...state,
                gb_xraywithredemption: [],
                gb_xraywithredemptiongrouped: [],
                gb_totalxraywithredemption: [],
                gb_isXrayWithRedemptionLoading: false,

            }
        //loader gold bonds xrey with sold units
        case types.LOADER_GB_XRAY_WITH_REDEMPTION:
            return {
                ...state,
                gb_isXrayWithRedemptionLoading: action.payload
            }

        //fds xray with sold  units report
        case types.FD_XRAY_WITH_REDEMPTION:
            let fd_grp = action.payload.dataTable != null ? action.payload.dataTable.length != 0 ? action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SubAssetClassName === ele.SubAssetClassName)) : [] : []

            return {
                ...state,
                fd_xraywithredemption: action.payload.dataTable != null ? action.payload.dataTable.map((item) => {
                    return {
                        ...item, SchemeName: item.ScripName
                    }
                }) : [],
                fd_xraywithredemptiongrouped: action.payload.dataTable != null ? fd_grp.length != 0 ? fd_grp.sort((a, b) => {
                    var textA = a.ScripName.toUpperCase();
                    var textB = b.ScripName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }).map((item) => {
                    return {
                        "Category": item.AssetClassName, "SubAssetclass": item.SubAssetClassName, "AssetSubAsset_BU_InvestedValue": item.AssetSubAsset_BU_InvestedValue, "AssetSubAsset_BU_Currentvalue": item.AssetSubAsset_BU_Currentvalue, "AssetSubAsset_BU_GainLoss": item.AssetSubAsset_BU_GainLoss, "AssetSubAsset_RU_InvestedValue": item.AssetSubAsset_RU_InvestedValue, "AssetSubAsset_RU_Currentvalue": item.AssetSubAsset_RU_Currentvalue, "AssetSubAsset_RU_GainLoss": item.AssetSubAsset_RU_GainLoss, "GrandOtherTotal": item.GrandOtherTotal, "AssetSubAsset_InvestedValue": item.AssetSubAsset_InvestedValue, "AssetSubAsset_Currentvalue": item.AssetSubAsset_Currentvalue, "AssetSubAsset_GainLoss": item.AssetSubAsset_GainLoss, "data": action.payload.dataTable.filter((val) => val.SubAssetClassName === item.SubAssetClassName).map((item) => {
                            return { ...item, SchemeName: item.ScripName }
                        })
                    }
                }) : [] : []
                ,
                fd_totalxraywithredemption: action.payload.totalTable != null ? action.payload.totalTable : []
                , fd_isXrayWithRedemptionLoading: false
            }
        //fds xray with sold units
        case types.EMPTY_FD_XRAY_WITH_REDEMPTION:
            return {
                ...state,
                fd_xraywithredemption: [],
                fd_xraywithredemptiongrouped: [],
                fd_totalxraywithredemption: [],
                fd_isXrayWithRedemptionLoading: false,
            }
        //loader fds xrey with sold units
        case types.LOADER_FD_XRAY_WITH_REDEMPTION:
            return {
                ...state,
                fd_isXrayWithRedemptionLoading: action.payload
            }
        default:
            return state
    }
}
export default reducer