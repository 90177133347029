import * as types from '../../constants/actionsType';
import getRiaFlowOffLinePaymentReportDetails from "../../../api/riaflowofflinepaymentapi/riaflowofflinepaymentapi"
import Label from '../../../utils/constants/labels';
export function getRiaFlowOffLinePaymentReport(FormNo, EmailID, PlanCode, GstNumber, PaymentType, ClientID) {
    return (dispatch) => {
        getAllRiaFlowOffLinePaymentReport(dispatch, FormNo, EmailID, PlanCode, GstNumber, PaymentType, ClientID);
    };
}
function getAllRiaFlowOffLinePaymentReport(dispatch, FormNo, EmailID, PlanCode, GstNumber, PaymentType, ClientID) {
    dispatch({
        type: types.LOADER_RIA_FLOW_OFFLINE_PAYMENT,
        payload: []
    });

    getRiaFlowOffLinePaymentReportDetails(FormNo, EmailID, PlanCode, GstNumber, PaymentType, ClientID).then((data) => {
        dispatch({
            type: types.RIA_FLOW_OFFLINE_PAYMENT,
            payload: [],
        });

        dispatch({
            type: types.RIA_FLOW_OFFLINE_PAYMENT_LOGIN,
            payload: []
        });

        dispatch({
            type: types.RIA_FLOW_OFFLINE_PAYMENT_LOGIN_PLAN_FEATURE,
            payload: []
        });

        if (data != undefined) {
            if (data.status == "S") {

                //Redirect to RiaPlanComplete Page in Success Response
                // history.push(Label.Path.RiaPlanComplete);

                dispatch({
                    type: types.RIA_FLOW_OFFLINE_PAYMENT,
                    payload: data
                });
                // This one is used for stored a data is LoginDetails
                dispatch({
                    type: types.RIA_FLOW_OFFLINE_PAYMENT_LOGIN,
                    payload: data
                });
                // This one is used for stored a data is LoginDetails - Plan Features
                dispatch({
                    type: types.RIA_FLOW_OFFLINE_PAYMENT_LOGIN_PLAN_FEATURE,
                    payload: data
                });
            }
            else {
                //  
                dispatch({
                    type: types.RIA_FLOW_OFFLINE_PAYMENT,
                    payload: [],
                });
                dispatch({
                    type: types.RIA_FLOW_OFFLINE_PAYMENT_LOGIN,
                    payload: []
                });
                dispatch({
                    type: types.RIA_FLOW_OFFLINE_PAYMENT_LOGIN_PLAN_FEATURE,
                    payload: []
                });
            }
        }
        else {
            // 
            dispatch({
                type: types.RIA_FLOW_OFFLINE_PAYMENT,
                payload: [],
            });

            dispatch({
                type: types.RIA_FLOW_OFFLINE_PAYMENT_LOGIN,
                payload: []
            });

            dispatch({
                type: types.RIA_FLOW_OFFLINE_PAYMENT_LOGIN_PLAN_FEATURE,
                payload: []
            });
        }
    })
        .catch((error) => {
            dispatch({
                type: types.RIA_FLOW_OFFLINE_PAYMENT,
                payload: [],
            });
        })

}