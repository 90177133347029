import React, { PureComponent } from 'react';
import {
    PieChart, Pie, Cell, Tooltip, ResponsiveContainer
} from 'recharts';
import SwitchButton from '../switchbutton/switchbutton';
import '../../utils/stylesheets/layout/layout.css'
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
//import { getSummaryDetails,  } from '../../redux/actions/summary/summaryAction'
import { getAllInstrumentDetails } from '../../redux/actions/summary/switchdonutaction'
import '../../utils/stylesheets/expandable-table/expandabletable.css'
import { bindActionCreators } from 'redux';
import NoData from '../nodata/nodata'
import Paper from '@material-ui/core/Paper';
import CustomTooltip from '../tooltip/customtooltip'
import ErrorBoundary from '../Errorhandling/Errorhandle';
import Loader from '../../components/loader/loader'

//import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import CommonDescription from '../../components/chartdescription/chart_description'
import labels from '../../utils/constants/labels';
const summaryEquityData = [];
const summaryDebtData = [];
const COLORS = labels.chartColor
const styles = theme => ({
    chartWrap: {
        // overflow: 'hidden',
    },
    chartFluid: {
        width: '100%',
        minWidth: 400,
        height: 300,
        [theme.breakpoints.down('400')]: {
            minWidth: 200,
        },

    },
    SwitchButtonDesign: {
        display: "flex",
        marginRight: '5%',
        justifyContent: 'flex-end',
        marginTop: '-10%',
        // [theme.breakpoints.down('350')]: {
        //     marginRight:'80px'
        // },
        // [theme.breakpoints.between('350','400')]: {
        //     marginRight:'100px'
        // },
        // [theme.breakpoints.between('400','600')]: {
        //     marginRight:'130px'
        // },
        [theme.breakpoints.down('600')]: {
            flex: '100%',
            justifyContent: "center",
            marginTop:0
        },
    },
    root: {
        flexGrow: 1,

    },
    switchContentBlock: {
        width: '100%',
        justifyContent: 'flex-start',
        paddingBottom: '3em',
        paddingLeft: '5%',
        paddingRight: "5%",
        // marginLeft: 5,
        // marginRight: 5,
        // width: 600,
        // [theme.breakpoints.between('300', '350')]: {
        //     width: 300,
        //     marginLeft: 10
        // },
        // [theme.breakpoints.between('350', '400')]: {
        //     width: 370,
        //     marginLeft: 20
        // },
        // [theme.breakpoints.between('400', '600')]: {
        //     width: 350,
        //     marginLeft: 30
        // },
    },
    // containerBlock:{
    //     display: 'grid',
    //     gridTemplateColumns: 'repeat(3, 24px [col-start] 140px [col-middle] 94px [col-end])',
    //     marginLeft:'-17px',
    // },
});
class Dount extends PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            summaryEquityData: [],
            summaryData: [],
            selectedSwitch: true,
            selectedState: 2,
            name: "",
            bordercolor: "",
            value: "",
            name1: "",
            bordercolor1: "",
            value1: ""

        }
    }


    onSwitchDonut(switchDonut) {
        this.setState({
            selectedSwitch: switchDonut
        })


    }
    componentDidMount() {
        this.setState({ summaryData: this.props.summaryData })
        this.setState({
            summaryEquityData: summaryEquityData,
            summaryDebtData: summaryDebtData
        })
    }
    pieenter = (item) => {
        //
        this.setState({ name: item.name, bordercolor: item.fill, value: item.value })
    }
    rendercusontooltip = () => {
        return <CustomTooltip page={"barchart"} name={this.state.name} bordercolor={this.state.bordercolor} value={this.state.value} />

    }
    pieenter1 = (item) => {
        // 
        this.setState({ name1: item.name, bordercolor1: item.fill, value1: item.value })
    }
    rendercusontooltip1 = () => {
        return <CustomTooltip page={"barchart"} name={this.state.name1} bordercolor={this.state.bordercolor1} value={this.state.value1} />

    }

    render() {
        //  
        const { classes } = this.props;
        return (
            <ErrorBoundary>
                <div>
                    {/* {this.props.EquityLoading==false||this.props.DebtLoading===false&& */}
                    {this.props.flag != "equity" &&
                        <div className={classes.SwitchButtonDesign}>
                            <SwitchButton label1={"Equity"} label2={"Debt"} onSwitchDonut={this.onSwitchDonut.bind(this)} selectedDebt={false} selectedEquity={true} />
                        </div>}
                    {/* } */}
                    {this.state.selectedSwitch ?
                        this.props.EquityLoading ? <Loader page="switchdonut" /> :
                            this.props.summaryData.length != 0 ?
                                <div>
                                    <div className={classes.chartWrap}>
                                        <div className={classes.chartFluid} >
                                            <ResponsiveContainer>
                                                <PieChart width={500} height={300}>
                                                    <Pie
                                                        data={this.props.summaryData}
                                                        outerRadius={"80%"} innerRadius={60}
                                                        dataKey="value"
                                                        onMouseEnter={this.pieenter}
                                                    >
                                                        {this.props.summaryData.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % labels.chartColor.length]} />)}
                                                    </Pie>
                                                    <Tooltip content={this.rendercusontooltip} wrapperStyle={{
                                                        paddingLeft: "30px", fontSize: "14px"
                                                    }} />
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                    <div className={classes.root}>
                                        <Grid container spacing={2}>
                                            <Grid container item spacing={5} className={classes.switchContentBlock}>
                                                {/* <div style={{ padding: '15px', display: "flex", justifyContent: "space-evenly" }} > */}
                                                {this.props.summaryData.length !== 0 && this.props.summaryData.map((item, index) => {
                                                    //  if (index % 2 != 0) {
                                                    return (
                                                        <CommonDescription key={index} name={item.name} value={item.value} color={COLORS[index % labels.chartColor.length]} />

                                                    )
                                                })}
                                                {/* </div> */}
                                            </Grid>
                                        </Grid>

                                    </div>
                                </div> : <div style={{ padding: "8px" }}>
                                    <NoData height={"320px"} time={200} />
                                </div> :
                        this.props.DebtLoading ? <Loader page="switchdonut" /> :
                            this.props.Deptdata.length != 0 ?
                                <div>
                                    <div className={classes.chartWrap}>
                                        <div className={classes.chartFluid} >
                                            <ResponsiveContainer>
                                                <PieChart width={500} height={300}>
                                                    <Pie
                                                        data={this.props.Deptdata}
                                                        outerRadius={"80%"} innerRadius={60}
                                                        dataKey="value"
                                                        onMouseEnter={this.pieenter1}
                                                    >
                                                        {this.props.Deptdata.map((entry, index) =>
                                                            <Cell key={`cell-${index}`} fill={COLORS[index % labels.chartColor.length]} />)}
                                                    </Pie>
                                                    <Tooltip content={this.rendercusontooltip1} wrapperStyle={{
                                                        paddingLeft: "30px", fontSize: "14px"
                                                    }} />
                                                </PieChart>
                                            </ResponsiveContainer>
                                        </div>
                                    </div>
                                    <div className={classes.root} >
                                        <Grid container spacing={2}>
                                            <Grid container item xs={12} spacing={5}>
                                                {/* <div style={{ padding: '15px', display: "flex", justifyContent: "space-evenly" }} > */}
                                                {this.props.Deptdata.length !== 0 && this.props.Deptdata.map((item, index) => {
                                                    //  if (index % 2 != 0) {
                                                    return (
                                                        <CommonDescription name={item.name} value={item.value} color={COLORS[index % labels.chartColor.length]} index={index} />
                                                    )
                                                })}
                                                {/* </div> */}
                                            </Grid>
                                        </Grid>

                                    </div>
                                </div> : <div style={{ padding: "8px" }}>
                                    <NoData height={"320px"} time={200} />
                                </div>
                    }
                </div>
            </ErrorBoundary>
        );
    }
}
const mapStateToProps = state => ({
    sessionID: state.Login.sessionID,
    clientID: state.Login.logindetails.ClientID,
    userID: state.Login.userID,
    date: state.Login.date,
    // barChartData: state.Summary.summary,
    // chart: state.Summary.chart,
    // categorySummary: state.Summary.categorySummary,
    // DonutData: state.Summary.Donut,
    // deptdata: state.Summary.getdeptdata
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getAllInstrumentDetails, }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Dount));
