import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router';
import { withStyles } from '@material-ui/core';
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle';
import CommonSnackbar from '../../components/snackbar/snackbar';
import labels from '../../utils/constants/labels';
import { getL5reportDetails } from "../../redux/actions/L5Report/L5ReportAction";
import DeletePopup from '../../components/popup/deletepopup';
import Subheader from '../../components/subheader/subheader';
import DynamicList from '../../components/list/dynamicList';
import OutlinedButton from '../../components/payment/button';
import { deleteListing } from '../../redux/actions/aifandpms/aifandpms_action';
import { getAIFandPMSDetails } from "../../redux/actions/aifandpms/aifandpms_action";
import getNPSTransactionDataEntry from '../../api/nps/nps_transaction_data_entry';
import { scrollToTopPage } from '../../utils/commonfunction';
import { replaceNull } from '../../utils/commonfunction';
import moment from 'moment';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import { npsListing } from '../../redux/actions/nps/npsaction';
import Addnpstransaction from './add_nps_transaction';
import UpdateNpsValuation from './update_nps_valuation'
import getNPSTransactionList from '../../api/nps/nps_transaction_list';
import getNPSList from '../../api/nps/nps_list';
import getNPSDataEntry from '../../api/nps/nps_data_entry';
import colors from '../../utils/colors/colors';
const styles = theme => ({

    grid: {
        display: 'grid',
        gridTemplateColumns: '27% 27% 25% 3%',
        gap: '16px',
        height: "auto",
        padding: 10,
        //[theme.breakpoints.down('920')]:{
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateAreas: `"${"myArea ."}"`,
            height: "auto !important",
            rowGap: "20px",
            paddingTop: 10,
            display: "grid",
        }
        // }
    },
    valuationGrid: {
        display: 'grid',
        gridTemplateColumns: '40% 40% 5%',
        gap: '20px'
    },
    transactionGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5,1fr)',
        gap: '20px'
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down("920")]: {
            flexDirection: 'column'
        }
    },
    sideSpace: {
        width: '12%',
        [theme.breakpoints.down("920")]: {
            display: 'none'
        }
    },
    mainCard: {
        display: "flex", marginBottom: "3%",
        boxShadow: "rgb(19 89 161 / 10%) 0px 0px 20px 0px",
        borderRadius: 5,
        // minHeight: "",
        height: "auto",
        width: "89%", marginTop: "18px",
        padding: '4%',
        paddingTop: "13px !important",
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down("920")]: {
            margin: 'auto'
        }
    },
    subHeader: {
        textAlign: 'left',
        marginTop: '1pc',
        color: window.globalConfig.color.LABLE_GRAY,
        // marginLeft: 10,
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
    cardSpereaterLine: {
        height: 0.5,
        width: "100%",
        // backgroundColor: window.globalConfig.color.AliceBlue,
        backgroundColor: window.globalConfig.color.LABLE_GRAY,
        marginBottom: '1pc',
        marginTop: '6px',
        // marginLeft: 10,
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
    twoColumnGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)'
    },
    sixColumnGrid: {
        display: 'grid',
        gridTemplateColumns: '23% 9% 12% 13% 9% 11% 13% 1%',
        gap: 10,
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(3,1fr)",
            gridTemplateAreas: `"${"myArea myArea."}"`,
            height: "auto !important",
            rowGap: "20px",
            paddingTop: 10,
            display: "grid",
        }
    },
    headerGrid: {
        textAlign: 'left',
        paddingLeft: 15
    },
    buttonStyle: {
        width: "20%",
        [theme.breakpoints.down("920")]: {
            width: "50%"
        }
    },
    textboxWidth: {
        // width:"inherit !important"
    },
    ContainerWithWidth: {
        [theme.breakpoints.up("920")]: {
            display: "grid",
            gridTemplateColumns: "50% 50%",
            // paddingLeft: "10px"
            // marginLeft: "20px",
            width: "100%", marginRight: 0,
        },
        // paddingBottom: 11, paddingTop: 11,
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "50% 50%",
            marginLeft: 5,
            display: "grid",
            //  width: "89.2%"
        }
    },
    assetGrid: {
        //  gridTemplateColumns: "19% auto auto auto auto auto auto auto",
        gridTemplateColumns: "19% 12% 17% 17% 15% 16% 3%",
        display: "grid",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(3,1fr)",
            gridTemplateAreas: `"${"myArea myArea."}"`,
            height: "auto !important",
            rowGap: "20px",
            paddingTop: 10,
            display: "grid",
        }
    },
    dialogDesign: {
        [theme.breakpoints.up("920")]: {
            width: "62%",
            maxWidth: "58%",
        },
        [theme.breakpoints.down('920')]: {
            width: '100%',
        },
    },
    marginTopAlignment: {
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        marginBottom: 10
    },
    buttonAlignment: {
        display: "flex",
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "end",
        columnGap: 10
    },
    popupListGrid: {
        display: "grid",
        gridTemplateColumns: "16% 13% 20% 12% 12% 12% 14% 1%"
    },
    DynamicList4: {
        [theme.breakpoints.down("920")]: {
            textAlign: "left !important"
            // color:"red !important"
        }
    }
});
class Nps extends Component {
    constructor() {
        super();
        this.myDiv = React.createRef();
        this.accessAddTransaction = React.createRef(null)

        this.state = {
            list: [],
            listBackUp: [],
            headerList: [],
            openListPopup: false,
            popupHeaderList: [],
            listingPopup: [],
            listingBackupPopup: [],
            deletePopupLoader: false,
            openDeletePopup: false,
            openAddPopup: false,
            header: "",
            grid: "",
            popupGrid: "",
            enableDelete: false,
            enableEdit: false,
            enableIcon: false,
            count: 7,
            popupList: [],
            flag: 'asset',
            id: 0,
            popupTransactionList: [],
            transactionID: 0,
            transactionFlag: "",
            selectedTransactionList: [],
            selectedAssetList: [],
            editList: [],
            transactionLoader: false,
            deletePopupText: '',
            listData: [],
            selected: false,
            loader: false,
            openAddTransactionPopup: false,
            openTransactionListPopup: false,
            openDeleteTransactionPopup: false,
            transactionListingPopup: [],
            transactionBackupListingPopup: [],
            deleteTransactionPopupLoader: false,
            listLoader: false,
            listID: "",
            dropData: ""

        }

    }
    componentDidMount() {
        document.title = window.globalConfig.companyDetails.companyName + " -NPS"
        scrollToTopPage()
        const { classes } = this.props
        this.setState({
            count: 7, grid: classes.sixColumnGrid, enableIcon: true, popupList: [{ "name": "Add Transaction", "onClick": this.handleAddTransaction }, { "name": "Delete Asset", "onClick": this.handleDeleteAsset }, { "name": "Update Current Value", 'onClick': this.handleAddEvaluation }],
        })
        this.npsList()

    }
    npsList = () => {
        let data = {}
        data.clientID = this.props.logindetails.ClientID
        this.setState({ listLoader: true })
        getNPSList(data).then((response) => {
            this.setState({ listLoader: false })
            if (response.status == 'S') {
                if (response.table1 != null && response.table1.length != 0) {
                    let responseData = [...replaceNull(response.table1, "NA")]
                    responseData = responseData.map((val, index) => {
                        return { ...val, enableIcon: true }
                    })
                    this.setState({ listBackUp: [...responseData] })
                    this.setState({
                        list: responseData.map((item, index) => {

                            return {
                                "NPSSchemeName": { name: item.NPSSchemeName == undefined ? "NA" : item.NPSSchemeName, subHeader1: item.Count == 0 ? "Add Transaction" : item.Count > 1 ? `${item.Count} Transactions` : `${item.Count} Transaction`, subHeader2: '', subHeaderType: 'link', type: "", label: "Scheme Name" },
                                "PranNo": { name: item.PRANNO, subHeader1: "NA", subHeader2: "", type: "", label: "PRAN No" },
                                "ValuationEquityNav": { name: item.EquityNAV == undefined ? 0 : item.EquityNAV, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Equity Nav" },
                                "ValuationCommodityNav": { name: item.CommodityNAV == undefined ? 0 : item.CommodityNAV, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Commodity Nav" },
                                "ValuationGoldNav": { name: item.GoldNAV == undefined ? 0 : item.GoldNAV, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Gold Nav" },
                                "ValuationOtherNav": { name: item.OtherNAV == undefined ? 0 : item.OtherNAV, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Other Nav" },
                                "ValuationDate": { name: item.ValuationDate == undefined || item.ValuationDate == "" || item.ValuationDate == "NA" ? "NA" : moment(item.ValuationDate).format('DD MMM YYYY'), subHeader1: 'NA', subHeader2: '', type: "", label: "Valuation Date" },
                                "SchemeID": { name: item.schemeID, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },
                                "ID": { name: item.ID, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },
                                "enableIcon": { name: item.enableIcon }
                            }
                        })
                    })
                } else {
                    this.setState({ list: [], listBackUp: [] })
                }

            } else {
                this.setState({ listLoader: false,list: [], listBackUp: [] })

            }

        }).catch((err) => {
            this.setState({ listLoader: false ,list: [], listBackUp: []})

        })
    }
    handleAddTransaction = (item) => {
        if (item.length != 0)
            this.setState({ openAddTransactionPopup: true, id: item.ID.name, header: 'Add Transaction', selectedTransactionList: [], selectedAssetList: item, })
        else
            this.setState({ openAddTransactionPopup: true, header: 'Add Transaction', selectedTransactionList: [], selectedAssetList: item, })

    }
    handlePopupClose = () => {
        this.setState({ openDeletePopup: false })

    }
    handleAddEvaluation = (item) => {
        this.setState({ openAddPopup: true, header: 'Update Latest Value', id: item.ID.name, selectedAssetList: item, })

    }
    handleEvaluationClose = () => {
        this.setState({ openAddPopup: false })
    }
    handleDeleteAsset = (item) => {
        this.setState({ selectedAssetList: item, openDeletePopup: true, id: item.ID.name })
    }

    handleTransactionClose = () => {
        this.setState({ openAddTransactionPopup: false })
    }
    npsTransactionList = (ID) => {
        let data = {}
        data.clientID = this.props.logindetails.ClientID
        data.NPSID = ID
        this.setState({ transactionLoader: true })
        getNPSTransactionList(data).then((response) => {
            this.setState({ transactionLoader: false })
            if (response.status == "S") {
                if (response.table1 != null && response.table1.length != 0) {
                    this.setState({ transactionBackupListingPopup: replaceNull(response.table1, "NA") })
                    this.setState({
                        transactionListingPopup: replaceNull(response.table1, "NA").map((value) => {
                            return {
                                "TransactionType": { name: this.props.transactionTypeList.length != 0 ? this.props.transactionTypeList.filter((val) => val.value == value.TransactionTypeID).length != 0 ? this.props.transactionTypeList.filter((val) => val.value == value.TransactionTypeID)[0]?.label : 'NA' : "NA", subHeader1: "", type: "" },
                                "InvestmentDate": { name: value.InvestmentDate != "NA" ? moment(value.InvastmentDate).format("DD MMM YYYY") : "NA", subHeader1: "", type: "" }
                                , "Amount": { name: value.Amount != "NA" ? value.Amount : "NA", subHeader1: "", type: "amount" }
                                , "Equity": { name: value.EquityUnits != "NA" ? value.EquityUnits : "NA", subHeader1: value.EquityNAV != "NA" ? value.EquityNAV : "NA", subHeaderType: 'amount', type: "amount" }
                                , "Commodity": { name: value.CommodityUnits != "NA" ? value.CommodityUnits : "NA", subHeader1: value.CommodityNAV != "NA" ? value.CommodityNAV : "NA", subHeaderType: 'amount', type: "amount" }
                                , "Gold": { name: value.GoldUnits != "NA" ? value.GoldUnits : "NA", subHeader1: value.GoldNAV != "NA" ? value.GoldNAV : "NA", subHeaderType: 'amount', type: "amount" }
                                , "Other": { name: value.OtherUnits != "NA" ? value.OtherUnits : "NA", subHeader1: value.OtherNAV != "NA" ? value.OtherNAV : "NA", subHeaderType: 'amount', type: "amount" }
                                , 'TransactionTypeID': { name: value.TransactionTypeID, subHeader1: '', type: '' }
                                , 'ID': { name: value.ID, subHeader1: '', type: '' }
                                , 'NPSID': { name: value.NPSID, subHeader1: '', type: '' }

                            }
                        })
                    })
                } else {
                    this.setState({ transactionListingPopup: [], transactionBackupListingPopup: [] })
                }
            } else {

            }
        })
    }
    transactionListClick = (item) => {
        // console.log(item)
        this.setState({ id: item.ID.name, selectedAssetList: item, dropData: item.NPSSchemeName.subHeader1 })
        if (item.NPSSchemeName.subHeader1.includes("Add Transaction")) {
            this.handleAddTransaction(item)
        } else {
            this.npsTransactionList(item.ID.name)
            this.setState({ openTransactionListPopup: true })

        }
    }
    handleTransactionListPopupClose = () => {
        this.setState({ openTransactionListPopup: false })
    }

    addNps = () => {
        this.props.history.push(labels.Path.NpsDataEntry)
    }
    callBack = (data, transactionData) => {

        if (data != null && data.length != 0) {
            let responseData = [...replaceNull(data, "NA")]
            responseData = responseData.map((val, index) => {
                return { ...val, enableIcon: true }
            })
            this.setState({ listBackUp: [...responseData] })
            this.setState({
                list: responseData.map((item, index) => {

                    return {
                        "NPSSchemeName": { name: item.NPSSchemeName == undefined ? "NA" : item.NPSSchemeName, subHeader1: item.Count == 0 ? "Add Transaction" : item.Count > 1 ? `${item.Count} Transactions` : `${item.Count} Transaction`, subHeader2: '', subHeaderType: 'link', type: "", label: "Scheme Name" },
                        "PranNo": { name: item.PRANNO, subHeader1: "NA", subHeader2: "", type: "", label: "PRAN No" },
                        "ValuationEquityNav": { name: item.EquityNAV == undefined ? 0 : item.EquityNAV, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Equity Nav" },
                        "ValuationCommodityNav": { name: item.CommodityNAV == undefined ? 0 : item.CommodityNAV, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Commodity Nav" },
                        "ValuationGoldNav": { name: item.GoldNAV == undefined ? 0 : item.GoldNAV, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Gold Nav" },
                        "ValuationOtherNav": { name: item.OtherNAV == undefined ? 0 : item.OtherNAV, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Other Nav" },
                        "ValuationDate": { name: item.ValuationDate == undefined ? "NA" : moment(item.ValuationDate).format('DD MMM YYYY'), subHeader1: 'NA', subHeader2: '', type: "", label: "Valuation Date" },
                        "SchemeID": { name: item.schemeID, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },
                        "ID": { name: item.ID, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },
                        "enableIcon": { name: item.enableIcon }
                    }
                })
            })
        }
        if (transactionData != null && transactionData.length != 0) {
            this.setState({ transactionBackupListingPopup: replaceNull(transactionData, "NA") })
            this.setState({
                transactionListingPopup: replaceNull(transactionData, "NA").map((value) => {
                    return {
                        "TransactionType": { name: this.props.transactionTypeList.length != 0 ? this.props.transactionTypeList.filter((val) => val.value == value.TransactionTypeID).length != 0 ? this.props.transactionTypeList.filter((val) => val.value == value.TransactionTypeID)[0]?.label : 'NA' : "NA", subHeader1: "", type: "" },
                        "InvestmentDate": { name: value.InvestmentDate != "NA" ? moment(value.InvastmentDate).format("DD MMM YYYY") : "NA", subHeader1: "", type: "" }
                        , "Amount": { name: value.Amount != "NA" ? value.Amount : "NA", subHeader1: "", type: "amount" }
                        , "Equity": { name: value.EquityUnits != "NA" ? value.EquityUnits : "NA", subHeader1: value.EquityNAV != "NA" ? value.EquityNAV : "NA", subHeaderType: 'amount', type: "amount" }
                        , "Commodity": { name: value.CommodityUnits != "NA" ? value.CommodityUnits : "NA", subHeader1: value.CommodityNAV != "NA" ? value.CommodityNAV : "NA", subHeaderType: 'amount', type: "amount" }
                        , "Gold": { name: value.GoldUnits != "NA" ? value.GoldUnits : "NA", subHeader1: value.GoldNAV != "NA" ? value.GoldNAV : "NA", subHeaderType: 'amount', type: "amount" }
                        , "Other": { name: value.OtherUnits != "NA" ? value.OtherUnits : "NA", subHeader1: value.OtherNAV != "NA" ? value.OtherNAV : "NA", subHeaderType: 'amount', type: "amount" }
                        , 'TransactionTypeID': { name: value.TransactionTypeID, subHeader1: '', type: '' }
                        , 'ID': { name: value.ID, subHeader1: '', type: '' }
                        , 'NPSID': { name: value.NPSID, subHeader1: '', type: '' }

                    }
                })
            })
        }

        // this.setState({ transactionListingPopup: list })
    }
    handleYesClick = () => {

        let data = {}
        data.id = this.state.selectedAssetList.ID.name
        data.clientID = this.props.logindetails.ClientID
        data.schemeId = this.state.selectedAssetList.SchemeID.name == undefined ? 0 : this.state.selectedAssetList.SchemeID.name
        data.createdBy = this.props.logindetails.ClientID
        data.pranno = this.state.selectedAssetList.PranNo.name
        data.flag = 2
        getNPSDataEntry(data).then((response) => {
            if (response.status === 'S') {
                this.updateCallBack(response.table1)
                this.npsList()
                this.setState({ openSnackBar: true, openDeletePopup: false, message: response.message, severity: 'success' })
            } else {
                this.setState({ openSnackBar: true, openDeletePopup: false, message: response.message, severity: 'warning' })

            }
        }).catch((err) => {
            this.setState({ openSnackBar: true, openDeletePopup: false, message: labels.messages.apierror, severity: 'success' })

        })
    }
    handleToastClose = () => {
        this.setState({ openSnackBar: false })
    }
    handleEditTransaction = (item) => {
        this.setState({ openAddTransactionPopup: true, header: 'Update Transaction', selectedTransactionList: item, })
        this.accessAddTransaction.current(item)
    }
    handleDeleteTransaction = (item) => {
        this.setState({ openDeleteTransactionPopup: true, selectedTransactionList: item, })

    }
    handleTransactionPopupClose = () => {
        this.setState({ openDeleteTransactionPopup: false })
    }
    handleTransactionYesClick = () => {
        // console.log(this.state.selectedAssetList)
        let data = {}
        data.id = this.state.selectedTransactionList?.ID?.name
        data.clientID = this.props.logindetails.ClientID
        data.NPSID = this.state.selectedAssetList?.ID?.name
        data.flag = 3
        this.setState({ deleteTransactionPopupLoader: true })
        if (!this.state.deleteTransactionPopupLoader) {
            getNPSTransactionDataEntry(data).then((response) => {
                this.setState({ deleteTransactionPopupLoader: false })
                if (response.status == "S") {
                    let modifiedArray = replaceNull(this.state.transactionBackupListingPopup, "NA").filter((item) => item.ID != this.state.selectedTransactionList.ID.name)
                    this.setState({ transactionBackupListingPopup: modifiedArray })
                    this.setState({
                        transactionListingPopup: modifiedArray.map((value) => {
                            return {
                                "TransactionType": { name: this.props.transactionTypeList.length != 0 ? this.props.transactionTypeList.filter((val) => val.value == value.TransactionTypeID).length != 0 ? this.props.transactionTypeList.filter((val) => val.value == value.TransactionTypeID)[0]?.label : 'NA' : "NA", subHeader1: "", type: "" },
                                "InvestmentDate": { name: value.InvestmentDate != "NA" ? moment(value.InvastmentDate).format("DD MMM YYYY") : "NA", subHeader1: "", type: "" }
                                , "Amount": { name: value.Amount != "NA" ? value.Amount : "NA", subHeader1: "", type: "amount" }
                                , "Equity": { name: value.EquityUnits != "NA" ? value.EquityUnits : "NA", subHeader1: value.EquityNAV != "NA" ? value.EquityNAV : "NA", subHeaderType: 'amount', type: "amount" }
                                , "Commodity": { name: value.CommodityUnits != "NA" ? value.CommodityUnits : "NA", subHeader1: value.CommodityNAV != "NA" ? value.CommodityNAV : "NA", subHeaderType: 'amount', type: "amount" }
                                , "Gold": { name: value.GoldUnits != "NA" ? value.GoldUnits : "NA", subHeader1: value.GoldNAV != "NA" ? value.GoldNAV : "NA", subHeaderType: 'amount', type: "amount" }
                                , "Other": { name: value.OtherUnits != "NA" ? value.OtherUnits : "NA", subHeader1: value.OtherNAV != "NA" ? value.OtherNAV : "NA", subHeaderType: 'amount', type: "amount" }
                                , 'TransactionTypeID': { name: value.TransactionTypeID, subHeader1: '', type: '' }
                                , 'ID': { name: value.ID, subHeader1: '', type: '' }
                                , 'NPSID': { name: value.NPSID, subHeader1: '', type: '' }

                            }
                        })
                    })

                    this.updateCallBack(response.table2)
                    this.setState({ openSnackBar: true, openDeleteTransactionPopup: false, message: response.message, severity: 'success' })

                } else {
                    this.setState({ openSnackBar: true, openDeleteTransactionPopup: false, message: response.message, severity: 'warning' })

                }
            }).catch((err) => {
                this.setState({ openSnackBar: true, openDeleteTransactionPopup: false, message: labels.messages.apierror, severity: 'warning' })
                this.setState({ deleteTransactionPopupLoader: false })
            })
        }
    }
    updateCallBack = (data) => {
        if (data != null && data.length != 0) {
            let responseData = [...replaceNull(data, "NA")]
            responseData = responseData.map((val, index) => {
                return { ...val, enableIcon: true }
            })
            this.setState({ listBackUp: [...responseData] })
            this.setState({
                list: responseData.map((item, index) => {

                    return {
                        "NPSSchemeName": { name: item.NPSSchemeName == undefined ? "NA" : item.NPSSchemeName, subHeader1: item.Count == 0 ? "Add Transaction" : item.Count > 1 ? `${item.Count} Transactions` : `${item.Count} Transaction`, subHeader2: '', subHeaderType: 'link', type: "", label: "Scheme Name" },
                        "PranNo": { name: item.PRANNO, subHeader1: "NA", subHeader2: "", type: "", label: "PRAN No" },
                        "ValuationEquityNav": { name: item.EquityNAV == undefined ? 0 : item.EquityNAV, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Equity Nav" },
                        "ValuationCommodityNav": { name: item.CommodityNAV == undefined ? 0 : item.CommodityNAV, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Commodity Nav" },
                        "ValuationGoldNav": { name: item.GoldNAV == undefined ? 0 : item.GoldNAV, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Gold Nav" },
                        "ValuationOtherNav": { name: item.OtherNAV == undefined ? 0 : item.OtherNAV, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Other Nav" },
                        "ValuationDate": { name: item.ValuationDate == undefined ? "NA" : moment(item.ValuationDate).format('DD MMM YYYY'), subHeader1: 'NA', subHeader2: '', type: "", label: "Valuation Date" },
                        "SchemeID": { name: item.schemeID, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },
                        "ID": { name: item.ID, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },
                        "enableIcon": { name: item.enableIcon }
                    }
                })
            })
        }
    }
    render() {
        const { classes } = this.props;
        try {

            return (
                <ErrorBoundary>
                    <Subheader heading={labels.productList.NPS} description={labels.Transact.npsContent} />
                    <div className={classes.flex}>
                        <div className={classes.sideSpace}></div>
                        <div className={classes.mainCard}>
                            <div className={classes.buttonAlignment}>
                                <div style={{ textAlignLast: "left", marginLeft: 10, marginTop: 10, marginBottom: 10 }}>
                                    <OutlinedButton text={labels.Nps.addNps} onClick={this.addNps} />
                                </div>


                            </div>
                            <DynamicList style={classes} loader={this.state.listLoader} transactionListClick={this.transactionListClick} list={this.state.list} grid={this.state.grid} header={[{
                                label: "Scheme Name", align: 'left'
                            }, { label: "PRAN No", align: 'left' }, { label: "Equity Nav", align: 'right' }, { label: "Commodity Nav", align: 'right' }, { label: "Gold Nav", align: 'right' }, { label: "Other Nav", align: 'right', }, { label: "Valuation Date", align: "left" }]} count={7} enableIcon={false} popupList={this.state.popupList}></DynamicList>

                            {/* <DynamicList loader={this.props.isNPSLoading}  list={this.props.npsList} grid={this.state.grid} header={[]} count={7} enableIcon={TRACK_EQUITY_DETAILS} popupList={this.state.popupList}></DynamicList> */}
                        </div>
                        <div className={classes.sideSpace}></div>
                    </div>
                    <CommonSnackbar message={this.state.message} open={this.state.openSnackBar} severity={this.state.severity} handlesnanckClose={this.handleToastClose} />
                    <UpdateNpsValuation page={"NPS"} callBack={this.updateCallBack} editList={this.state.selectedAssetList} transactionFlag={this.state.transactionFlag}
                        listID={this.state.id} header={this.state.header} flag={this.state.flag}
                        loader={this.state.loader} addMoreLoader={this.state.addmoreloader} open={this.state.openAddPopup} onClose={this.handleEvaluationClose} >
                    </UpdateNpsValuation>
                    <Addnpstransaction npsTransactionList={this.npsTransactionList} accessAddTransaction={this.accessAddTransaction} page={"NPS"} List={this.state.transactionBackupListingPopup} callBack={this.callBack} editList={this.state.selectedTransactionList} transactionFlag={this.state.transactionFlag}
                        listID={this.state.id} header={this.state.header} flag={this.state.flag} dropData={this.state.dropData}
                        loader={this.state.loader} addMoreLoader={this.state.addmoreloader} open={this.state.openAddTransactionPopup} onClose={this.handleTransactionClose} >
                    </Addnpstransaction>
                    <DeletePopup showdefault={true} className={classes.dialogDesign} header={"Transactions"} open={this.state.openTransactionListPopup} onClose={this.handleTransactionListPopupClose} >
                        <div style={{ textAlignLast: "right", marginLeft: 10, marginTop: 10, marginBottom: 10, marginRight: 26 }}>
                            <OutlinedButton text={labels.Nps.addTransaction} onClick={() => {
                                this.handleAddTransaction(this.state.listData)
                                this.setState({ openListPopup: false })
                            }} />
                        </div>
                        <div style={{ width: "90%", margin: "auto", paddingTop: "10px", paddingBottom: 10 }}>

                            <div className={classes.popupListGrid} style={{ fontSize: 12, textTransform: "uppercase", color: colors.LABLE_GRAY, marginBottom: 10, textAlign: 'right' }}>
                                <div></div> <div></div> <div></div>
                                <div>{labels.Nps.equityFullForm}</div>
                                <div>{labels.Nps.commodityFullForm}</div>
                                <div>{labels.Nps.goldFullForm}</div>
                                <div>{labels.Nps.othersFullForm}</div>
                            </div>
                            <DynamicList loader={this.state.transactionLoader} list={this.state.transactionListingPopup} grid={classes.popupListGrid} header={[{ "label": "Transaction Type", "align": "left" }, { "label": "Investment Date", "align": "left" }, { "label": "Amount", "align": "right" }, { "label": "Units", "subLabel": "Nav", "align": "right" }, { "label": "Units", "subLabel": "Nav", "align": "right" }, { "label": "Units", "subLabel": "Nav", "align": "right" }, { "label": "Units", "subLabel": "Nav", "align": "right" }]} count={7} enableIcon={true} popupList={[{ 'name': 'Edit', 'onClick': this.handleEditTransaction }, { 'name': 'Delete', 'onClick': this.handleDeleteTransaction }]}></DynamicList>
                        </div>
                    </DeletePopup>
                    <DeletePopup header={labels.Nps.confirmation} onClick={this.handleYesClick} loader={this.state.deletePopupLoader} open={this.state.openDeletePopup} text={labels.Nps.assetDeleteMsg} onClose={this.handlePopupClose} />
                    <DeletePopup header={labels.Nps.confirmation} onClick={this.handleTransactionYesClick} loader={this.state.deleteTransactionPopupLoader} open={this.state.openDeleteTransactionPopup} text={labels.Nps.transactionDeleteMsg} onClose={this.handleTransactionPopupClose} />

                </ErrorBoundary>
            )

        } catch (err) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => {
    //
    return {
        sessionID: state.Login.sessionID,
        logindetails: state.Login.logindetails,
        transactionTypeList: state.Dataentry.npsAndInsuranceDropdownList != null && state.Dataentry.npsAndInsuranceDropdownList.length != 0 && state.Dataentry.npsAndInsuranceDropdownList.table1 != null && state.Dataentry.npsAndInsuranceDropdownList.table1.length != 0 ? state.Dataentry.npsAndInsuranceDropdownList.table1.map((item) => {
            return { "label": item.TEXT, "value": item.VALUE }
        }) : [],
        loader: state.NPS.isNPSLoading,
        npsList: state.NPS.npsList
        // pmsLoader: state.AIFandPMS.isPMSLoading,

    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getL5reportDetails, deleteListing, npsListing, getAIFandPMSDetails }, dispatch)
}
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(Nps))
