import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl';
var axios=require('axios')
import Networking from '../../utils/api/apiaccess';
function getProfileKYCDetails(FormNo){
    let data={};
    data.FormNo=FormNo
    return Networking.PostApi(ApiValues.KYCDetails, data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
    // var config={
    //     method:'post',
    //     url:ApiValues.KYCDetails,
    //     headers:{
    //       'Content-Type': 'application/json'
    //     },
    //     data : data
    // };
    // return axios(config)
    //     .then(function(response){
    //         return response.data;
    //     })
    //     .catch(function(error){
    //         return{status:"F",message:labels.messages.apierror}
    //     });
}
export default getProfileKYCDetails