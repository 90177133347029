import React, { Component } from 'react';
import TradingTab from '../tabs/trading_tabs';
import Tollbar from '../../components/toolbar/toolbar';
import Label from '../../utils/constants/labels'
import { withStyles } from '@material-ui/core/styles';
import '../../utils/stylesheets/layout/layout.css'
import { connect } from "react-redux";
const transactionTab = ["MF Order Status", "Upcoming SIPs", "Transaction", "Mandate", "AMO Orders"];

//Added by Celsia for Mobile Responsive on 09 Jun 2021 
const styles = theme => ({
    root: {
        display: "flex",
        flexDirection: "row",
        marginTop: "100px",
        [theme.breakpoints.down("600")]: {
            display: "block",
            marginTop: "100px",
        },
    },
    divResponsive: {
        display: "none",
        [theme.breakpoints.down("600")]: {
            display: "block",
            height: "50px",
        },

    }
    , tradetab: {

        [theme.breakpoints.up("900")]: {
            display: 'flex', width: "100%", marginTop: 10
        }
    }
    , firstdiv: {
        [theme.breakpoints.up("900")]: {
            width: "10%"
        }
    },
    seconddiv: {
        [theme.breakpoints.up("900")]: {
            width: "80%"
        }
    }
})
class MFTransaction extends Component {
    constructor(props) {
        super(props);
        this.state = {
            transactionList: [],
            MFOrder: [],
            upCommingSIPs: [],
            AMOOrder: [],
            transaction: [],
            Mandate: []
        }
    }
    render() {
        const { classes } = this.props
        return (
            <div>

                <Tollbar title={Label.menu.trading} tradeFunc={this.props.tradeFunc} childFunc={this.props.childFunc} style={{ top: -5 }} />

                <div className={classes.tradetab}>
                    <div className={classes.firstdiv}></div>
                    <div className={classes.seconddiv}>
                        <TradingTab page={this.props.location} tradeFunc={this.props.tradeFunc} />
                    </div>
                    
                </div>
            

            </div>
        )
    }
}
const mapStateToProps = state => ({
    date: state.Login.date,
    mfHeader2: state.Card.mfHeader2,
    sessionID: state.Login.sessionID,
    clientID: state.Login.logindetails.ClientID,
    userID: state.Login.userID,
    date: state.Login.date,
    logindetails: state.Login.logindetails
})
export default withStyles(styles)(connect(mapStateToProps, null)(MFTransaction));
