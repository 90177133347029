import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Lable from '../../utils/constants/labels'
import { Divider } from '@material-ui/core';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import labels from '../../utils/constants/labels';
import moment from 'moment';
import OutlineButton from '../../components/payment/button'
import color from "../../utils/colors/colors";
import CancelImage from "../../utils/assets/images/failureImg.png";
import Capture from "../../utils/assets/images/capture.png";
import NoData from '../nodata/nodata';
import colors from '../../utils/colors/colors';
import { NegativeFinding } from '../../utils/commonfunction';
const Styles = theme => ({
  root: {
    flexGrow: 1, color: window.globalConfig.color.BLACK_PRIMARY,
  },
  Top: {
    marginTop: 100
  },
  allText: {
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: "14px",
    textAlign: "left",
  },
  redemptionScheme: {
    textAlign: "left",
    fontSize: "16px",
    fontWeight: 'bold'
  },
  allSchemeText: {
    textAlign: "left",
    fontSize: '14px'
  },
  //Added by Celsia on 29 May 2021 for Mobile Responsive Started
  desktopResponsive: {
    display: 'block !important',
    [theme.breakpoints.down("920")]: {
      display: 'none !important',
    },
  },
  mobileResponsive: {
    display: 'none !important',
    [theme.breakpoints.down("920")]: {
      display: 'block!important',
    },
  },
  leftResponsive: {
    padding: "10px",
    textAlign: "left"
  },
  rightResponsive: {
    padding: "10px",
    textAlign: "right"
  },
  centerResponsive: {
    padding: "10px",
    textAlign: "center"
  },
  valueResponsive: {
    fontSize: 12,
    color: window.globalConfig.color.BLACK_PRIMARY,
  },
  collabelresponsive: {
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: "12px",
    marginBottom: 10
  },
  labelResponsive: {
    fontSize: 12,
    marginBottom: 10,
    fontWeight: 550
  },
  schemeNameResponsive: {
    fontSize: 14
  },
  dividerHeightResponsive: {
    width: "100%"
  },
  containerWidthResponsive: {
    width: "100%"
  },
  messageResponsive: {
    color: window.globalConfig.color.BLACK_PRIMARY,
    margin: "3px"
  },
  orderResponsive: {
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontSize: "13px",
  },
  footerButton: {
    marginTop: '2%',
    [theme.breakpoints.down(920)]: {
      marginTop: '7%',
    },
  },
  usernameResponsive: {
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontSize: "13px",
    margin: "15px 0px"
  },
  marginResponsive: {
    marginTop: 30,
    marginLeft: "10px",
    marginRight: "10px"
  },
  cancelIconResponsive: {
    color: window.globalConfig.color.WHITE,
    zIndex: 1,
    fontSize: 35,
    border: `1px solid ${window.globalConfig.color.red}`,
    borderRadius: 25,
    backgroundColor: window.globalConfig.color.red
  },
  checkIconResponsive: {
    color: window.globalConfig.color.WHITE,
    zIndex: 1,
    fontSize: 45,
    border: `1px solid ${window.globalConfig.color.GREEN}`,
    borderRadius: 25,
    backgroundColor: window.globalConfig.color.GREEN,
  },
  statusText: {
    fontWeight: 'bold',
    fontSize: 16
  },
  tableStyle: {
    padding: 10, width: '100%', paddingLeft: 20, paddingBottom: 20
  },
  responsiveFlex: {
    [theme.breakpoints.down(920)]: {
      justifyContent: "space-between"
    }
  },

});
class Common extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
  }


  RedemptionDetails = () => {
    const { classes } = this.props;
    return (
      <Grid container item xs={12} style={{ marginTop: 10, color: window.globalConfig.color.BLACK_PRIMARY }} >
        <Divider style={{ width: '100%' }}></Divider>
        <Grid item xs={6}>
          <table className={classes.tableStyle}>
            <tr >
              <td colSpan={3}>
                <table >
                  <tr>
                    <td className={classes.allText}>{labels.Redemption.redempationHeader}</td>
                  </tr>
                  <tr>
                    <td className={classes.redemptionScheme}>{this.props.data.SchemeName}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr >
              <td style={{ width: "35%", paddingTop: 10 }}  >
                <tr>
                  <td className={classes.allText}>{labels.Redemption.OrderType}</td>
                </tr>
                <tr>
                  <td className={classes.allSchemeText}>{labels.Redemption.redemptionName}</td>
                </tr>
              </td>
              <td style={{ paddingTop: 10, width: "35%", }}>
                <tr>
                  <td className={classes.allText}>{labels.Redemption.RedemptionType}</td>
                </tr>
                <tr>
                  <td className={classes.allSchemeText}>{this.props.redemptiontype}</td>
                </tr>
              </td>
              <td style={{ paddingTop: 10, width: "35%", }}>
                {this.props.redemptiontype === "All Available No. of Units" && <>
                  <tr>
                    <td className={classes.allText}>{labels.Redemption.AvailableNoofUnits}</td>
                  </tr>
                  <tr>
                    <td className={classes.allSchemeText}>{NegativeFinding(this.props.availableamount, "", "", "", "rupee")}</td>
                  </tr></>}
                {this.props.redemptiontype === "All Exit Load Free Units" && <>
                  <tr>
                    <td className={classes.allText}>{labels.Redemption.ExitLoadFreeUnits}</td>
                  </tr>
                  <tr>
                    <td className={classes.allSchemeText}>{this.props.exitunit}</td>
                  </tr></>}
                {this.props.redemptiontype === "All Short Term Gain Units" && <>
                  <tr>
                    <td className={classes.allText}>{labels.Redemption.ShortTermGainUnits}</td>
                  </tr>
                  <tr>
                    <td className={classes.allSchemeText}>{this.props.shortterm}</td>
                  </tr></>}
                {this.props.redemptiontype === "Long Term Gain Units" && <>
                  <tr>
                    <td className={classes.allText}>{labels.Redemption.LongTermGainUnits}</td>
                  </tr>
                  <tr>
                    <td className={classes.allSchemeText}>{this.props.longterm}</td>
                  </tr></>}
                {this.props.redemptiontype === "Custom No. of Units" && <>
                  <tr>
                    <td sclassName={classes.allText}>{labels.Redemption.Units}</td>
                  </tr>
                  <tr>
                    <td className={classes.allSchemeText}>{this.props.units}</td>
                  </tr></>}
                {this.props.redemptiontype === "Amount (Rs)" && <>
                  <tr>
                    <td className={classes.allText}>{labels.Redemption.Amount}</td>
                  </tr>
                  <tr>
                    <td className={classes.allSchemeText}>{this.props.amountValue}</td>
                  </tr>
                </>
                }
              </td>
            </tr>
            <tr >
              <td style={{ paddingTop: 10 }}>
                <tr>
                  <td className={classes.allText}>{labels.Redemption.ExitLoad}</td>
                </tr>
                <tr>
                  <td className={classes.allSchemeText}>{labels.currency.rupeesymbol} {this.props.exitunit}</td>
                </tr>
              </td>
            </tr>

          </table>

        </Grid>
        <Divider orientation='vertical' style={{ height: '100%' }}></Divider>
        <Grid item xs={5}>
          {this.props.bankdetails.length != 0 ? this.props.bankdetails.filter((items, index) => items.UCC == this.props.UCC && items.BankAccountNumber == this.props.AccountNumber)?.map((item) => {
            return (
              <table style={{ padding: 10, width: '100%', paddingLeft: 20 }}>
                <tr>
                  <td colSpan={3}>
                    <tr >
                      <td className={classes.allText}>{labels.Redemption.ProceedBankDetails}</td>
                    </tr>
                    <tr>
                      <td className={classes.redemptionScheme}>{item.BankName}</td>
                    </tr>
                  </td>

                </tr>
                <tr style={{ display: 'grid', gridTemplateColumns: 'repeat(3,41%)', gridColumnGap: '10px' }}>
                  <td style={{ paddingTop: 10 }}>
                    <tr>
                      <td className={classes.allText}>{labels.Redemption.BankAccountType}</td>
                    </tr>
                    <tr>
                      <td className={classes.allSchemeText}>{item.AccountType}</td>
                    </tr>
                  </td>
                  <td style={{ paddingTop: 10, }}>
                    <tr>
                      <td className={classes.allText}>{labels.Redemption.AccountNumber}</td>
                    </tr>
                    <tr>
                      <td className={classes.allSchemeText}>{item.BankAccountNumber}</td>
                    </tr>
                  </td>
                  <td style={{ paddingTop: 10, }}>
                    <tr>
                      <td className={classes.allText}>{labels.Redemption.IFSCCode}</td>
                    </tr>
                    <tr className={classes.allSchemeText}>
                      <td>{item.IFSCCode}</td>
                    </tr>
                  </td>
                </tr>
              </table>
            )
          }) : <div style={{ marginTop: "21%", marginLeft: "21%" }}><NoData /></div>}

        </Grid>
        <Divider style={{ width: '100%' }}></Divider>
      </Grid>
    )
  }
  RedemptionDetailsMobile = () => {
    const { classes } = this.props;
    return <Grid className={classes.dividerHeightResponsive}>
      <Divider className={classes.containerWidthResponsive}></Divider>
      <Grid container className={classes.responsiveFlex}>
        <Grid item xs={12} className={classes.leftResponsive}>
          <div className={classes.labelResponsive}>{labels.Redemption.redempationHeader}</div>
          <div className={classes.schemeNameResponsive}><span>{this.props.data.SchemeName}</span></div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={6} className={classes.leftResponsive}>
          <div className={classes.collabelresponsive}> {labels.Redemption.OrderType}</div>
          <div className={classes.valueResponsive}>{labels.Redemption.redemptionName}</div>
        </Grid>
        <Grid item xs={6} className={classes.rightResponsive}>
          <div className={classes.collabelresponsive}> {labels.Redemption.ExitLoad}</div>
          <div className={classes.valueResponsive}>{this.props.exitunit}</div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} className={classes.leftResponsive}>
          <div className={classes.collabelresponsive}>{labels.Redemption.RedemptionType}</div>
          <div className={classes.valueResponsive}>{this.props.redemptiontype}</div>
        </Grid>
        {this.props.redemptiontype === "All Available No. of Units" &&
          <Grid item xs={6} className={classes.rightResponsive}>
            <div className={classes.collabelresponsive}>{labels.Redemption.AvailableNoofUnits}</div>
            <div className={classes.valueResponsive}>{NegativeFinding(this.props.availableamount, "", "", "", "rupee")}</div>
          </Grid>}
        {this.props.redemptiontype === "All Exit Load Free Units" &&
          <Grid item xs={6} className={classes.rightResponsive}>
            <div className={classes.collabelresponsive}>{labels.Redemption.ExitLoadFreeUnits}</div>
            <div className={classes.valueResponsive}>{this.props.exitunit}</div>
          </Grid>}
        {this.props.redemptiontype === "All Short Term Gain Units" &&
          <Grid item xs={6} className={classes.rightResponsive}>
            <div className={classes.collabelresponsive}>{labels.Redemption.ShortTermGainUnits}</div>
            <div className={classes.valueResponsive}>{this.props.shortterm}</div>
          </Grid>}
        {this.props.redemptiontype === "Long Term Gain Units" &&
          <Grid item xs={6} className={classes.rightResponsive}>
            <div className={classes.collabelresponsive}>{labels.Redemption.LongTermGainUnits}</div>
            <div className={classes.valueResponsive}>{this.props.longterm}</div>
          </Grid>}
        {this.props.redemptiontype === "Custom No. of Units" &&
          <Grid item xs={6} className={classes.rightResponsive}>
            <div className={classes.collabelresponsive}>{labels.Redemption.Units}</div>
            <div className={classes.valueResponsive}>{this.props.units}</div>
          </Grid>}
        {this.props.redemptiontype === "Amount (Rs)" &&
          <Grid item xs={6} className={classes.rightResponsive}>
            <div className={classes.collabelresponsive}>{labels.Redemption.Amount}</div>
            <div className={classes.valueResponsive}>{this.props.amountValue}</div>
          </Grid>}
      </Grid>
      <Divider className={classes.dividerHeightResponsive}></Divider>

      {this.props.bankdetails.length != 0 ? this.props.bankdetails.filter((items, index) => items.UCC == this.props.UCC && items.BankAccountNumber == this.props.AccountNumber)?.map((item) => {
        return (
          <div>
            <Grid container>
              <Grid item xs={12} className={classes.leftResponsive}>
                <div className={classes.labelResponsive}>{labels.Redemption.ProceedBankDetails}</div>
                <div className={classes.schemeNameResponsive}><span>{item.BankName}</span></div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} className={classes.leftResponsive}>
                <div className={classes.collabelresponsive}>{labels.Redemption.BankAccountType}</div>
                <div className={classes.valueResponsive}>{item.AccountType}</div>
              </Grid>
              <Grid item xs={6} className={classes.rightResponsive}>
                <div className={classes.collabelresponsive}>{labels.Redemption.AccountNumber}</div>
                <div className={classes.valueResponsive}>{item.BankAccountNumber}</div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6} className={classes.leftResponsive}>
                <div className={classes.collabelresponsive}>{labels.Redemption.IFSCCode}</div>
                <div className={classes.valueResponsive}>{item.IFSCCode}</div>
              </Grid>
            </Grid>
          </div>)
      }) : <div style={{ marginTop: "5%" }}><NoData /></div>}
    </Grid>
  }

  STP = () => {
    const { classes } = this.props;
    return <Grid container item xs={12} style={{ marginTop: 10, color: window.globalConfig.color.BLACK_PRIMARY }}>
      <Divider style={{ width: '100%', backgroundColor: window.globalConfig.color.ZERO_BACKGROUND, }}></Divider>
      <Grid item xs={3}></Grid>
      <Grid item xs={6}>
        <table style={{ padding: 10, width: '100%' }}>
          <tr>
            <tr>
              <td className={classes.allText}>{labels.STP.STPoutFrom}</td>
            </tr>
            <tr>
              <td style={{
                textAlign: "left",
                fontWeight: 'bold'
              }}>{this.props.STPscheme}</td>
            </tr>
          </tr>
        </table>
        <Divider style={{ width: '100%', backgroundColor: window.globalConfig.color.ZERO_BACKGROUND, marginLeft: '2.2%' }}></Divider>
        <table style={{ padding: 10, width: '100%' }}>
          <tr>
            <td colSpan={3} >
              <table>
                <tr>
                  <td className={classes.allText}>{labels.STP.STPinto}</td>
                </tr>
                <tr>
                  <td style={{
                    textAlign: "left",
                    fontWeight: 'bold'
                  }}>{this.props.scheme}</td>
                </tr>
              </table>
            </td>
          </tr>

          <tr>
            <td style={{ paddingTop: 10, }}>
              <tr>
                <td className={classes.allText}>{labels.STP.STPType}</td>
              </tr>
              <tr>
                <td className={classes.allSchemeText}>{this.props.type}</td>
              </tr>
            </td>
            <td style={{ paddingTop: 10 }}>
              <tr>
                <td className={classes.allText}>{labels.STP.Amount}</td>
              </tr>
              <tr>
                <td className={classes.allSchemeText}>{this.props.amount}</td>
              </tr>
            </td>
            <td style={{ paddingTop: 10, }}>
              <tr>
                <td className={classes.allText}>{labels.STP.STPInstalments}</td>
              </tr>
              <tr>
                <td className={classes.allSchemeText}>{this.props.instalment}</td>
              </tr>
            </td>

          </tr>
          <tr>
            <td style={{ paddingTop: 10, }}>
              <tr>
                <td className={classes.allText}>{labels.STP.StartDate}</td>
              </tr>
              <tr>
                <td className={classes.allSchemeText}> {moment(this.props.startdate).format(" DD MMM YYYY")}</td>
              </tr>
            </td>
            <td style={{ paddingTop: 10, }}>
              <tr>
                <td className={classes.allText}>{labels.STP.firstOrder}</td>
              </tr>
              <tr>
                <td className={classes.allSchemeText}>{this.props.firstorder}</td>
              </tr>
            </td>
          </tr>

        </table>
      </Grid>
      <Grid item xs={3}></Grid>
      <Divider style={{ width: '100%', backgroundColor: window.globalConfig.color.ZERO_BACKGROUND, }}></Divider>
    </Grid>
  }
  STPMobile = () => {
    const { classes } = this.props;
    return <Grid className={classes.containerWidthResponsive}>

      <Divider className={classes.dividerHeightResponsive}></Divider>
      <Grid container>
        <Grid item xs={12} className={classes.leftResponsive}>
          <div className={classes.labelResponsive}>{labels.STP.STPoutFrom}</div>
          <div className={classes.schemeNameResponsive}><span>{this.props.STPscheme}</span></div>
        </Grid>
      </Grid>

      <Divider className={classes.dividerHeightResponsive}></Divider>
      <Grid container>
        <Grid item xs={12} className={classes.leftResponsive}>
          <div className={classes.labelResponsive}>{labels.STP.STPinto}</div>
          <div className={classes.schemeNameResponsive}><span>{this.props.scheme}</span></div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4} className={classes.leftResponsive}>
          <div className={classes.collabelresponsive}>{labels.STP.STPType}</div>
          <div className={classes.valueResponsive}>{this.props.type}</div>
        </Grid>
        <Grid item xs={4} className={classes.centerResponsive}>
          <div className={classes.collabelresponsive}>{labels.STP.Amount}</div>
          <div className={classes.valueResponsive}>{this.props.amount}</div>
        </Grid>
        <Grid item xs={4} className={classes.rightResponsive}>
          <div className={classes.collabelresponsive}>{labels.STP.STPInstalments}</div>
          <div className={classes.valueResponsive}>{this.props.instalment}</div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} className={classes.leftResponsive}>
          <div className={classes.collabelresponsive}>{labels.STP.StartDate}</div>
          <div className={classes.valueResponsive}>{moment(this.props.startdate).format(" DD MMM YYYY")}</div>
        </Grid>
        <Grid item xs={6} className={classes.rightResponsive}>
          <div className={classes.collabelresponsive}>{labels.STP.firstOrder}</div>
          <div className={classes.valueResponsive}>{this.props.firstorder}</div>
        </Grid>
      </Grid>

    </Grid>
  }

  Switch = () => {
    const { classes } = this.props;
    return <Grid container item xs={12} style={{ marginTop: 10, color: window.globalConfig.color.BLACK_PRIMARY }}>
      <Divider style={{ width: '100%', backgroundColor: window.globalConfig.color.ZERO_BACKGROUND }}></Divider>
      <Grid item xs={4}></Grid>
      <Grid item xs={4}>
        <table style={{ padding: 10, width: '100%' }}>
          <tr>
            <td style={{ paddingTop: 10 }}>
              <tr>
                <td className={classes.allText}>{labels.switch.Switchoutfrom}</td>
              </tr>
              <tr>
                <td style={{
                  textAlign: "left",
                  fontWeight: 'bold'
                }}>{this.props.Switchout}</td>
              </tr>
            </td>
          </tr>
        </table>
        <Divider style={{ width: '100%', backgroundColor: window.globalConfig.color.ZERO_BACKGROUND, marginLeft: '3%' }}></Divider>
        <table style={{ padding: 10, width: '100%' }}>
          <tr>
            <td style={{ paddingTop: 10 }} colSpan={2}>
              <tr>
                <td className={classes.allText}>{labels.switch.Switchinto}</td>
              </tr>
              <tr>
                <td style={{
                  textAlign: "left",
                  fontWeight: 'bold',
                }}>{this.props.Switchin}</td>
              </tr>
            </td>
          </tr>
          <tr>
            <td style={{ paddingTop: 10 }}>
              <tr>
                <td className={classes.allText}>{labels.switch.OrderType}</td>
              </tr>
              <tr>
                <td className={classes.allSchemeText}>{labels.switch.Switch}</td>

              </tr>
            </td>
            <td style={{ paddingTop: 10 }}>
              {this.props.type === "Amount (Rs)" && <>
                <tr>
                  <td className={classes.allText}>{labels.switch.Amount}</td>
                </tr>
                <tr>
                  <td className={classes.allSchemeText}>{this.props.amount}</td>
                </tr>
              </>}{this.props.type === "Custom No. of Units" && <><tr>
                <td className={classes.allText}>{labels.switch.Units}</td>
              </tr>
                <tr>
                  <td className={classes.allSchemeText}>{NegativeFinding(this.props.units, "", "", "", "number")}</td>
                </tr></>}
              {this.props.type === "All Available No. of Units" && <>
                <tr>
                  <td className={classes.allText}>{labels.switch.AvailableAmount}</td>
                </tr>
                <tr>
                  <td className={classes.allSchemeText}>{this.props.Additionaldata.TotalExitLoadUnits}</td>
                </tr>
              </>}
              {this.props.type === "All Exit Load Free Units" && <>
                <tr>
                  <td className={classes.allText}>{labels.switch.ExitLoad}</td>
                </tr>
                <tr>
                  <td className={classes.allSchemeText}>{this.props.Additionaldata.TotalAvailableUnits}({NegativeFinding(this.props.Additionaldata.TotalAvailableAmount, "", "", "", "rupee")})</td>
                </tr>
              </>}
              {this.props.type === "All Short Term Gain Units" && <>
                <tr>
                  <td className={classes.allText}>{labels.switch.ShortTermUnit}</td>
                </tr>
                <tr>
                  <td className={classes.allSchemeText}>{this.props.Additionaldata.TotalShortTermUnits}</td>
                </tr>
              </>}
              {this.props.type === "All Long Term Gain Units" && <>
                <tr>
                  <td className={classes.allText}>{labels.switch.LongTermUnit}</td>
                </tr>
                <tr>
                  <td className={classes.allSchemeText}>{this.props.Additionaldata.TotalLongTermUnits}</td>
                </tr>
              </>}
            </td>
          </tr>


        </table>
      </Grid>
      <Grid item xs={4}></Grid>
      <Divider style={{ width: '100%', backgroundColor: window.globalConfig.color.AliceBlue }}></Divider>
    </Grid>
  }
  SwitchMobile = () => {
    const { classes } = this.props;
    return <Grid className={classes.containerWidthResponsive}>

      <Divider className={classes.dividerHeightResponsive}></Divider>
      <Grid container>
        <Grid item xs={12} className={classes.leftResponsive}>
          <div className={classes.labelResponsive}>{labels.switch.Switchoutfrom}</div>
          <div className={classes.schemeNameResponsive}>{this.props.Switchout}</div>
        </Grid>
      </Grid>

      <Divider className={classes.dividerHeightResponsive}></Divider>
      <Grid container>
        <Grid item xs={12} className={classes.leftResponsive}>
          <div className={classes.labelResponsive}>{labels.switch.Switchinto}</div>
          <div className={classes.schemeNameResponsive}>{this.props.Switchin}</div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} className={classes.leftResponsive}>
          <div className={classes.collabelresponsive}>{labels.switch.OrderType}</div>
          <div className={classes.valueResponsive}>{labels.switch.Switch}</div>
        </Grid>
        {this.props.type === "Amount (Rs)" && <>
          <Grid item xs={6} className={classes.rightResponsive}>
            <div className={classes.collabelresponsive}>{labels.switch.Amount}</div>
            <div className={classes.valueResponsive}>{this.props.amount}</div>
          </Grid>
        </>}
        {this.props.type === "Custom No. of Units" && <>
          <Grid item xs={6} className={classes.rightResponsive}>
            <div className={classes.collabelresponsive}>{labels.switch.Units}</div>
            <div className={classes.valueResponsive}>{NegativeFinding(this.props.units, "", "", "", "number")}</div>
          </Grid></>
        }
        {this.props.type === "All Exit Load Free Units" && <Grid item xs={6}>
          <div className={classes.rightResponsive}>
            <div className={classes.collabelresponsive}> {labels.switch.ExitLoad}</div>
            <div className={classes.valueResponsive}>{this.props.Additionaldata.TotalExitLoadUnits}</div>
          </div>
        </Grid>}
        {this.props.type === "All Available No. of Units" && <Grid item xs={6}>
          <div className={classes.rightResponsive}>
            <div className={classes.collabelresponsive}> {labels.switch.AvailableAmount}</div>
            <div className={classes.valueResponsive}>{this.props.Additionaldata.TotalAvailableUnits}({NegativeFinding(this.props.Additionaldata.TotalAvailableAmount, "", "", "", "rupee")})</div>
          </div>
        </Grid>}
        {this.props.type === "All Short Term Gain Units" && <Grid item xs={6}>
          <div className={classes.rightResponsive}>
            <div className={classes.collabelresponsive}> {labels.switch.ShortTermUnit}</div>
            <div className={classes.valueResponsive}>{this.props.Additionaldata.TotalShortTermUnits}</div>
          </div>
        </Grid>}
        {this.props.type === "All Long Term Gain Units" && <Grid item xs={6}>
          <div className={classes.rightResponsive}>
            <div className={classes.collabelresponsive}> {labels.switch.LongTermUnit}</div>
            <div className={classes.valueResponsive}>{this.props.Additionaldata.TotalLongTermUnits}</div>
          </div>
        </Grid>}
      </Grid>

    </Grid>

  }

  SWP = () => {
    const { classes } = this.props;
    return (
      <Grid container item xs={12} style={{ marginTop: 20, color: window.globalConfig.color.BLACK_PRIMARY }}>
        <Divider style={{ width: '100%' }}></Divider>
        <Grid item xs={6}>
          <table style={{ padding: 10, width: '100%', paddingLeft: 20 }}>
            <tr >
              <td colSpan={3}>
                <table >
                  <tr>
                    <td className={classes.allText}>{labels.Redemption.redempationHeader} </td>
                  </tr>
                  <tr>
                    <td className={classes.redemptionScheme}>{this.props.data.Scheme}</td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td style={{ width: "35%", paddingTop: 10 }}>
                <tr>
                  <td className={classes.allText}>{labels.SWP.SWPtype}</td>
                </tr>
                <tr>
                  <td className={classes.allSchemeText}>{this.props.Swptype}</td>
                </tr>
              </td>
              <td style={{ paddingTop: 10 }}>
                <tr>
                  <td className={classes.allText}>{labels.SWP.Amount}</td>
                </tr>
                <tr>
                  <td className={classes.allSchemeText}>{this.props.amount}</td>
                </tr>
              </td>
              <td style={{ paddingTop: 10 }}>
                <tr>
                  <td className={classes.allText}>{labels.SWP.SWPInstalments}</td>
                </tr>
                <tr>
                  <td className={classes.allSchemeText}>{this.props.installment}</td>
                </tr>
              </td>
            </tr>
            <tr>
              <td style={{ paddingTop: 10 }}>
                <tr>
                  <td className={classes.allText}>{labels.SWP.SWPStartDate}</td>
                </tr>
                <tr>
                  <td className={classes.allSchemeText}>{this.props.swpdate}</td>
                </tr>
              </td>
              <td style={{ paddingTop: 10 }}>
                <tr>
                  <td className={classes.allText}>{labels.SWP.FirstOrderToday}</td>
                </tr>
                <tr>
                  <td className={classes.allSchemeText}>{this.props.firstorder ? "Yes" : "No"}</td>
                </tr>
              </td>
            </tr>

          </table>
        </Grid>

        <Divider orientation='vertical' style={{ height: '100%' }}></Divider>

        <Grid item xs={5}>

          {this.props.bankdetails.length != 0 ? this.props.bankdetails.filter((items, index) => items.UCC == this.props.UCC && items.BankAccountNumber == this.props.AccountNumber)?.map((item) => {
            return (<>
              <table style={{ padding: 10, width: '100%', paddingLeft: 20 }}>
                <tr>
                  <td>
                    <tr>
                      <td className={classes.allText}> {labels.Redemption.ProceedBankDetails}</td>
                    </tr>
                    <tr>
                      <td className={classes.redemptionScheme}>{item.BankName}</td>
                    </tr>
                  </td>

                </tr>
                <tr>
                  <td style={{ paddingTop: 10 }}>
                    <tr>
                      <td className={classes.allText}>{labels.SWP.BankAccountType}</td>
                    </tr>
                    <tr>
                      <td className={classes.allSchemeText}>{item.AccountType}</td>
                    </tr>
                  </td>
                  <td style={{ paddingTop: 10, width: "40%" }}>
                    <tr>
                      <td className={classes.allText}>{labels.SWP.AccountNumber}</td>
                    </tr>
                    <tr>
                      <td className={classes.allSchemeText}>{item.BankAccountNumber}</td>
                    </tr>
                  </td>
                  <td style={{ paddingTop: 10 }}>
                    <tr>
                      <td className={classes.allText}>{labels.SWP.IFSCCode}</td>
                    </tr>
                    <tr className={classes.allSchemeText}>
                      <td>{item.IFSCCode}</td>
                    </tr>
                  </td>
                </tr>
              </table>
            </>)
          }) : <div><NoData /></div>}
        </Grid>
        <Divider style={{ width: '100%' }}></Divider>
      </Grid>
    )
  }

  SWPMobile = () => {
    const { classes } = this.props;

    return <Grid className={classes.dividerHeightResponsive}>
      <Divider className={classes.containerWidthResponsive}></Divider>
      <Grid container>
        <Grid item xs={12} className={classes.leftResponsive}>
          <div className={classes.labelResponsive}>{labels.Redemption.redempationHeader}</div>
          <div className={classes.schemeNameResponsive}>{this.props.data.Scheme}</div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4} className={classes.leftResponsive}>
          <div className={classes.collabelresponsive}>{labels.SWP.SWPtype}</div>
          <div className={classes.valueResponsive}>{this.props.Swptype}</div>
        </Grid>
        <Grid item xs={4} className={classes.centerResponsive}>
          <div className={classes.collabelresponsive}>{labels.SWP.Amount}</div>
          <div className={classes.valueResponsive}>{this.props.amount}</div>
        </Grid>
        <Grid item xs={4} className={classes.rightResponsive}>
          <div className={classes.collabelresponsive}>{labels.SWP.SWPInstalments}</div>
          <div className={classes.valueResponsive}>{this.props.installment}</div>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={6} className={classes.leftResponsive}>
          <div className={classes.collabelresponsive}>{labels.SWP.SWPStartDate}</div>
          <div className={classes.valueResponsive}>{this.props.swpdate}</div>
        </Grid>
        <Grid item xs={6} className={classes.rightResponsive}>
          <div className={classes.collabelresponsive}>{labels.SWP.FirstOrderToday}</div>
          <div className={classes.valueResponsive}>{this.props.firstorder ? "Yes" : "No"}</div>
        </Grid>
      </Grid>
      <Divider className={classes.dividerHeightResponsive}></Divider>

      {this.props.bankdetails.length != 0 ? this.props.bankdetails.filter((items, index) => items.UCC == this.props.UCC && items.IsDefault == true)?.map((item) => {
        return (
          <div>
            <Grid container>
              <Grid item xs={12} className={classes.leftResponsive}>
                <div className={classes.labelResponsive}>{labels.Redemption.ProceedBankDetails}</div>
                <div className={classes.schemeNameResponsive}><span>{item.BankName}</span></div>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={6} className={classes.leftResponsive}>
                <div className={classes.collabelresponsive}>{labels.SWP.BankAccountType}</div>
                <div className={classes.valueResponsive}>{item.AccountType}</div>
              </Grid>
              <Grid item xs={6} className={classes.rightResponsive}>
                <div className={classes.collabelresponsive}>{labels.SWP.AccountNumber}</div>
                <div className={classes.valueResponsive}>{item.BankAccountNumber}</div>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={6} className={classes.leftResponsive}>
                <div className={classes.collabelresponsive}>{labels.SWP.IFSCCode}</div>
                <div className={classes.valueResponsive}>{item.IFSCCode}</div>
              </Grid>
            </Grid>
          </div>)
      }) : <div style={{ marginTop: "5%" }}><NoData /></div>}
    </Grid>

  }


  render() {
    const { classes } = this.props;
    //
    return (
      <div >
        <div className={[classes.root, classes.desktopResponsive].join(' ')}>
          <Grid container >
            <Grid item xs={12} style={{ marginTop: 30 }} >
              <Grid xs={12}>
                <div>

                  {this.props.status == "F" ?

                    <img src={CancelImage} style={{ height: 57, width: 62 }} /> : <img src={Capture} style={{ height: 60, width: 65 }} />
                  }
                  {this.props.status == "F" ?
                    <div className={classes.statusText}>{labels.paymentFaild.Failed}</div> : <div className={classes.statusText}>{labels.paymentSuccess.Success}</div>
                  }
                </div>
                <div style={{ color: this.props.status == "F" ? colors.red : colors.GREEN, marginTop: '0.5%', fontSize: 18, fontWeight: 600 }}>{this.props.message}</div>
              </Grid >
              {this.props.status != "F" &&
                <Grid xs={12} style={{ color: window.globalConfig.color.BLACK_PRIMARY, marginTop: '0.5%' }}>
                  <lable style={{ color: window.globalConfig.color.GRAY_DARK }}>{Lable.paymentSuccess.Order}</lable><lable>&nbsp;&nbsp;{this.props.orderID != "" && this.props.orderID != undefined ? this.props.orderID : "New"}</lable>
                </Grid>}
              <Grid xs={12} style={{ color: window.globalConfig.color.BLACK_PRIMARY, marginTop: '2.3%' }}>
                <lable style={{ fontWeight: 'bold' }}>{this.props.logindetails.UserName}</lable>
                <lable style={{ paddingLeft: 8 }}>  ({Lable.STP.Folio}: {this.props.folionumber})</lable>
              </Grid>
            </Grid>
            {this.props.page == 'RedemptionDetails' && this.RedemptionDetails()}
            {this.props.page == 'SWP' && this.SWP()}
            {this.props.page == 'STP' && this.STP()}
            {this.props.page == 'Switch' && this.Switch()}
          </Grid>
          <div style={{ marginTop: "3%" }}>
            <OutlineButton text={"Continue to Authorise / Payment"} onClick={() => { this.props.history.push(labels.Path.MFTransaction) }} />
          </div>
        </div>
        {/* Added by Celsia on 1 Jun 2021 for Mobile Responsive Started */}
        <div className={[classes.root, classes.mobileResponsive].join(' ')}>
          <Grid container >
            <Grid item xs={12} className={classes.marginResponsive} >
              <Grid xs={12}>
                {this.props.status == "F" ?
                  <img src={CancelImage} style={{ height: 57, width: 62 }} /> : <img src={Capture} style={{ height: 60, width: 65 }} />
                }
                <h5 className={classes.messageResponsive}>{this.props.message}</h5>
              </Grid >
              <Grid xs={12} className={classes.orderResponsive}>
                <lable>{Lable.paymentSuccess.Order}</lable><lable>&nbsp;&nbsp;(New)</lable>
              </Grid>
              <Grid xs={12} className={classes.usernameResponsive}>
                <lable style={{ fontWeight: "bold" }}>{this.props.logindetails.UserName}</lable><br />
                <lable>(Folio : {this.props.folionumber})</lable>
              </Grid>
            </Grid>
            {this.props.page == 'RedemptionDetails' && this.RedemptionDetailsMobile()}
            {this.props.page == 'SWP' && this.SWPMobile()}
            {this.props.page == 'STP' && this.STPMobile()}
            {this.props.page == 'Switch' && this.SwitchMobile()}
          </Grid>
          <div className={classes.footerButton}>
            <OutlineButton text={"Continue to Authorise / Payment"} onClick={() => { this.props.history.push(labels.Path.MFTransaction) }} />
          </div>
        </div>
        {/* Ended */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    logindetails: state.Login.logindetails,
    bankdetails: state.ProfileDetails.AllProfileDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.bankDetails != "NA" && state.ProfileDetails.AllProfileDetails.bankDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.bankDetails.filter((val) => val.IsDefault === true) : [] : []

  }
}
export default withStyles(Styles)(withRouter(connect(mapStateToProps, null)(Common)))

