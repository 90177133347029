import axios from 'axios'
import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
/* name should be camel case in all function */
import Networking from '../../utils/api/apiaccess';
function getEkycRedirectionDetails(email) {
  const BaseUrl = ApiValues.GetProfileDetail + `?clientCode=${""}&email=${email}&source=WAdmin`


  //AsOnDate
  return Networking.GetApi(BaseUrl, "", labels.apiKey.apiKeys, labels.apiKey.apiKeys).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
  // return fetch(BaseUrl, {
  //   method: 'POST',
  //   headers: {
  //     'Accept': 'application/json',
  //     'Content-Type': 'application/json',
  //   },
  // })
  //   .then((response) => response.json())
  //   .then((responseJson) => {
  //     return responseJson
  //   })
  //   .catch((error) => {
  //     return {"status":"F","message":labels.messages.apierror}
  //   });

}
export default getEkycRedirectionDetails
