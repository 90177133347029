import { replaceNull } from '../../../utils/commonfunction';
import * as types from '../../constants/actionsType'
import { bindActionCreators } from 'redux';
const initialState = {
    portfolioreport: [],
    totalreport: [],
    portfoliocount: "",
    isPortfolioReportLoading: false,

    //EQUITY
    equity_portfolioreport: [],
    equity_totalreport: [],
    equity_portfoliocount: "",
    equity_isPortfolioReportLoading: false,

    //BONDS
    bonds_portfolioreport: [],
    bonds_totalreport: [],
    bonds_portfoliocount: "",
    bonds_isPortfolioReportLoading: false,

    //GOLD BONDS
    gb_portfolioreport: [],
    gb_totalreport: [],
    gb_portfoliocount: "",
    gb_isPortfolioReportLoading: false,

    //FIXED DEPOSITS
    fd_portfolioreport: [],
    fd_totalreport: [],
    fd_portfoliocount: "",
    fd_isPortfolioReportLoading: false,


}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        // mf report
        case types.PORTFOLIO_REPORT:
            // let fulldata = []
            // let grpelss = action.payload.dataTable.sort((a, b) => {
            //     var textA = a.SchemeName.toUpperCase();
            //     var textB = b.SchemeName.toUpperCase();
            //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            // }).filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.AssetClassName === ele.AssetClassName))

            // fulldata = grpelss.map((item) => {
            //     let Scheme = action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.FolioNo === ele.FolioNo) && ele.AssetClassName == item.AssetClassName).map((item) => {
            //         return { "SchemeName": item.SchemeName, "FolioNo": item.FolioNo, "investedvalue": item.FolioSchemeWise_CostOfAquisition, "currectvalue": item.FolioSchemeWise_CurrentValue, "profitloss": item.FolioSchemeWise_ProfitLoss, "divpay": item.FolioSchemeWise_DivPay, "divreinv": item.FolioSchemeWise_DivReInv, "netloss": item.FolioSchemeWise_NetLoss, "abs": item.FolioSchemeWise_AbsReturn, "xirr": item.FolioSchemeWise_Xirr }
            //     })

            //     return {
            //         "AssetClassName": item.AssetClassName, "SchemeData": Scheme.map((item) => {
            //             return { "SchemeName": item.SchemeName, "FolioNo": item.FolioNo, "investedvalue": item.investedvalue, "currentvalue": item.currectvalue, "profitloss": item.profitloss, "divpay": item.divpay, "divreinv": item.divreinv, "netloss": item.netloss, "abs": item.abs, "xirr": item.xirr, "data": action.payload.dataTable.filter((val) => val.SchemeName === item.SchemeName && val.FolioNo === item.FolioNo) }
            //         })

            //     }
            // })
            return {
                ...state,
                portfolioreport: action.payload.dataTable == "NA" ? [] : action.payload.dataTable,
                portfoliocount: action.payload.dataTable == "NA" && action.payload.dataTable.length > 1 ? `${action.payload.dataTable.length}` : `${action.payload.dataTable.length}`,
                totalreport: replaceNull(action.payload.totalTable, "")
                , isPortfolioReportLoading: false
            }
        //empty mf report
        case types.EMPTY_PORTFOLIO_REPORT:

            return {
                ...state,
                portfolioreport: [],
                totalreport: [],
                portfoliocount: "",
                isPortfolioReportLoading: false
            }
        // loader protfolio report
        case types.LOADER_PORTFOLIO_REPORT:
            return {
                ...state,
                isPortfolioReportLoading: action.payload
            }
        // equity report
        case types.EQUITY_PORTFOLIO_REPORT:
            // let equity_fulldata = []
            // let equity_grpelss = action.payload.dataTable.length != 0 ? action.payload.dataTable.sort((a, b) => {
            //     var textA = a.ScripName.toUpperCase();
            //     var textB = b.ScripName.toUpperCase();
            //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            // }).filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.AssetClassName === ele.AssetClassName)) : []

            // equity_fulldata = equity_grpelss.length != 0 ? equity_grpelss.map((item) => {
            //     let equity_Scheme = action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.ScripName === ele.ScripName) && ele.AssetClassName == item.AssetClassName).map((item) => {
            //         return { "SchemeName": item.ScripName, "FolioNo": "", "investedvalue": item.FolioSchemeWise_CostOfAquisition, "currectvalue": item.FolioSchemeWise_CurrentValue, "profitloss": item.FolioSchemeWise_ProfitLoss, "divpay": item.FolioSchemeWise_DivPay, "divreinv": item.FolioSchemeWise_DivReInv, "netloss": item.FolioSchemeWise_NetLoss, "abs": item.FolioSchemeWise_AbsReturn, "xirr": item.FolioSchemeWise_Xirr }
            //     })

            //     return {
            //         "AssetClassName": item.AssetClassName, "SchemeData": equity_Scheme.map((item) => {
            //             return { "SchemeName": item.SchemeName, "FolioNo": "", "investedvalue": item.investedvalue, "currentvalue": item.currectvalue, "profitloss": item.profitloss, "divpay": item.divpay, "divreinv": item.divreinv, "netloss": item.netloss, "abs": item.abs, "xirr": item.xirr, "data": action.payload.dataTable.filter((val) => val.ScripName === item.SchemeName) }
            //         })

            //     }
            // }) : []
            // 
            //     let grp = action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SchemeName === ele.SchemeName))
            return {
                ...state,
                equity_portfolioreport: action.payload.dataTable == "NA" || action.payload.dataTable == null || action.payload.dataTable.length == 0 ? [] : action.payload.dataTable.map((item) => {
                    return { ...item, SchemeName: item.ScripName }
                }),
                equity_portfoliocount: action.payload.dataTable != null && action.payload.dataTable != "NA" && action.payload.dataTable.length != 0 ? action.payload.dataTable.length > 1 ? `${action.payload.dataTable.length}` : `${action.payload.dataTable.length}` : "0",
                equity_totalreport: replaceNull(action.payload.totalTable, "")
                , equity_isPortfolioReportLoading: false
            }
        //empty equity report
        case types.EMPTY_EQUITY_PORTFOLIO_REPORT:

            return {
                ...state,
                equity_portfolioreport: [],
                equity_totalreport: [],
                equity_portfoliocount: "",
                equity_isPortfolioReportLoading: false
            }
        //loader equity report
        case types.LOADER_EQUITY_PORTFOLIO_REPORT:
            return {
                ...state,
                equity_isPortfolioReportLoading: action.payload
            }


        // bonds loader protfolio report
        case types.LOADER_BONDS_PORTFOLIO_REPORT:
            return {
                ...state,
                bonds_isPortfolioReportLoading: action.payload
            }
        //bonds report
        case types.BONDS_PORTFOLIO_REPORT:
            // let bonds_fulldata = []
            // let bonds_grpelss = action.payload.dataTable != null && action.payload.dataTable.length != 0 ? action.payload.dataTable.sort((a, b) => {
            //     var textA = a.ScripName.toUpperCase();
            //     var textB = b.ScripName.toUpperCase();
            //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            // }).filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.AssetClassName === ele.AssetClassName)) : []
            // bonds_fulldata = bonds_grpelss.length != 0 ? bonds_grpelss.map((item) => {
            //     let bonds_Scheme = action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.ScripName === ele.ScripName) && ele.AssetClassName == item.AssetClassName).map((item) => {
            //         return { "SchemeName": item.ScripName, "FolioNo": "", "investedvalue": item.FolioSchemeWise_CostOfAquisition, "currectvalue": item.FolioSchemeWise_CurrentValue, "profitloss": item.FolioSchemeWise_ProfitLoss, "divpay": item.FolioSchemeWise_DivPay, "divreinv": item.FolioSchemeWise_DivReInv, "netloss": item.FolioSchemeWise_NetLoss, "abs": item.FolioSchemeWise_AbsReturn, "xirr": item.FolioSchemeWise_Xirr }
            //     })

            //     return {
            //         "AssetClassName": item.AssetClassName, "SchemeData": bonds_Scheme.map((item) => {
            //             return { "SchemeName": item.SchemeName, "FolioNo": "", "investedvalue": item.investedvalue, "currentvalue": item.currectvalue, "profitloss": item.profitloss, "divpay": item.divpay, "divreinv": item.divreinv, "netloss": item.netloss, "abs": item.abs, "xirr": item.xirr, "data": action.payload.dataTable.filter((val) => val.ScripName === item.SchemeName) }
            //         })

            //     }
            // }) : []
            // console.log(replaceNull(action.payload.dataTable, "NA") != "NA" ? action.payload.dataTable.map((item) => {
            //     return { ...item, SchemeName: item.ScripName }
            // }) : [])
            return {
                ...state,
                bonds_portfolioreport: replaceNull(action.payload.dataTable, "NA") != "NA" ? action.payload.dataTable.map((item) => {
                    return { ...item, SchemeName: item.ScripName }
                }) : [],
                bonds_portfoliocount: action.payload.dataTable == "NA" ? 0 : action.payload.dataTable.length > 1 ? `${action.payload.dataTable.length}` : `${action.payload.dataTable.length}`,
                bonds_totalreport: replaceNull(action.payload.totalTable, "")
                , bonds_isPortfolioReportLoading: false
            }

        //empty bonds report
        case types.EMPTY_BONDS_PORTFOLIO_REPORT:

            return {
                ...state,
                bonds_portfolioreport: [],
                bonds_totalreport: [],
                bonds_portfoliocount: "",
                bonds_isPortfolioReportLoading: false,
            }
        //empty loader bonds report
        case types.EMPTY_LOADER_BONDS_PORTFOLIO_REPORT:
            return {
                ...state,
                bonds_isPortfolioReportLoading: action.payload
            }


        // gold bonds loader protfolio report
        case types.LOADER_GB_PORTFOLIO_REPORT:
            return {
                ...state,
                gb_isPortfolioReportLoading: action.payload
            }
        // gold bonds report
        case types.GB_PORTFOLIO_REPORT:
            // let gb_fulldata = []
            // let gb_grpelss = action.payload.dataTable.sort((a, b) => {
            //     var textA = a.SchemeName.toUpperCase();
            //     var textB = b.SchemeName.toUpperCase();
            //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            // }).filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.AssetClassName === ele.AssetClassName))

            // gb_fulldata = gb_grpelss.map((item) => {
            //     let Scheme = action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SchemeName === ele.SchemeName) && ele.AssetClassName == item.AssetClassName).map((item) => {
            //         return { "SchemeName": item.SchemeName, "FolioNo": item.FolioNo, "investedvalue": item.FolioSchemeWise_CostOfAquisition, "currectvalue": item.FolioSchemeWise_CurrentValue, "profitloss": item.FolioSchemeWise_ProfitLoss, "divpay": item.FolioSchemeWise_DivPay, "divreinv": item.FolioSchemeWise_DivReInv, "netloss": item.FolioSchemeWise_NetLoss, "abs": item.FolioSchemeWise_AbsReturn, "xirr": item.FolioSchemeWise_Xirr }
            //     })

            //     return {
            //         "AssetClassName": item.AssetClassName, "SchemeData": Scheme.map((item) => {
            //             return { "SchemeName": item.SchemeName, "FolioNo": item.FolioNo, "investedvalue": item.investedvalue, "currentvalue": item.currectvalue, "profitloss": item.profitloss, "divpay": item.divpay, "divreinv": item.divreinv, "netloss": item.netloss, "abs": item.abs, "xirr": item.xirr, "data": action.payload.dataTable.filter((val) => val.SchemeName === item.SchemeName) }
            //         })

            //     }
            // })
            return {
                ...state,
                gb_portfolioreport: action.payload.dataTable == null || action.payload.dataTable.length == 0 ? [] : action.payload.dataTable.map((item) => {
                    return { ...item, SchemeName: item.ScripName }
                }),
                gb_portfoliocount: action.payload.dataTable.length > 1 ? `${action.payload.dataTable.length}` : `${action.payload.dataTable.length}`,
                gb_totalreport: replaceNull(action.payload.totalTable, "")
                , gb_isPortfolioReportLoading: false
            }

        //empty gold bonds report
        case types.EMPTY_GB_PORTFOLIO_REPORT:

            return {
                ...state,
                gb_portfolioreport: [],
                gb_totalreport: [],
                gb_portfoliocount: "",
                gb_isPortfolioReportLoading: false,
            }
        //empty loader gold bonds report
        case types.EMPTY_LOADER_GB_PORTFOLIO_REPORT:
            return {
                ...state,
                gb_isPortfolioReportLoading: action.payload
            }


        // fd loader protfolio report
        // case types.LOADER_FD_PORTFOLIO_REPORT:
        //     return {
        //         ...state,
        //         fd_isPortfolioReportLoading: action.payload
        //     }
        //fd report
        case types.FD_PORTFOLIO_REPORT:
            // let fd_fulldata = []
            // let fd_grpelss = action.payload.dataTable.sort((a, b) => {
            //     var textA = a.SchemeName.toUpperCase();
            //     var textB = b.SchemeName.toUpperCase();
            //     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            // }).filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.AssetClassName === ele.AssetClassName))

            // fd_fulldata = fd_grpelss.map((item) => {
            //     let Scheme = action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SchemeName === ele.SchemeName) && ele.AssetClassName == item.AssetClassName).map((item) => {
            //         return { "SchemeName": item.SchemeName, "FolioNo": item.FolioNo, "investedvalue": item.FolioSchemeWise_CostOfAquisition, "currectvalue": item.FolioSchemeWise_CurrentValue, "profitloss": item.FolioSchemeWise_ProfitLoss, "divpay": item.FolioSchemeWise_DivPay, "divreinv": item.FolioSchemeWise_DivReInv, "netloss": item.FolioSchemeWise_NetLoss, "abs": item.FolioSchemeWise_AbsReturn, "xirr": item.FolioSchemeWise_Xirr }
            //     })

            //     return {
            //         "AssetClassName": item.AssetClassName, "SchemeData": Scheme.map((item) => {
            //             return { "SchemeName": item.SchemeName, "FolioNo": item.FolioNo, "investedvalue": item.investedvalue, "currentvalue": item.currectvalue, "profitloss": item.profitloss, "divpay": item.divpay, "divreinv": item.divreinv, "netloss": item.netloss, "abs": item.abs, "xirr": item.xirr, "data": action.payload.dataTable.filter((val) => val.SchemeName === item.SchemeName) }
            //         })

            //     }
            // })
            return {
                ...state,
                fd_portfolioreport: action.payload.dataTable == null || action.payload.dataTable.length == 0 ? [] : action.payload.dataTable.map((item) => {
                    return { ...item, SchemeName: item.ScripName }
                }),
                fd_portfoliocount: action.payload.dataTable.length > 1 ? `${action.payload.dataTable.length}` : `${action.payload.dataTable.length}`,
                fd_totalreport: replaceNull(action.payload.totalTable, "")
                , fd_isPortfolioReportLoading: false
            }

        //empty fd report
        case types.EMPTY_FD_PORTFOLIO_REPORT:

            return {
                ...state,
                fd_portfolioreport: [],
                fd_totalreport: [],
                fd_portfoliocount: "",
                fd_isPortfolioReportLoading: false,
            }
        //empty loader bonds report
        case types.LOADER_FD_PORTFOLIO_REPORT:
            return {
                ...state,
                fd_isPortfolioReportLoading: action.payload
            }

        default:
            return state
    }
}
export default reducer
