import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux';
import moment from 'moment';
import CommonHeaders from '../../components/report_common_header/report_common_header'
import { GetPortfolioReport } from '../../redux/actions/portfolioreport/portfolioreportaction'
import Label from '../../utils/constants/labels'
import getAllcommonDownloadDetails from '../../api/commondownload/commondownload';
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import { EncryptURL, Encryption, NegativeFinding } from '../../utils/commonfunction';
import NoData from '../../components/nodata/nodata';
import Loader from '../../components/loader/loader'
import colors from '../../utils/colors/colors'
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
// import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle';
import '../portfolioreport/portfolioreportCss.css'
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle'
import CommonSnackbar from '../../components/snackbar/snackbar'
import apiurl from '../../api/url/apiurl';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import constants from '../../utils/constants/apiconstant';
import secureStorage from '../../utils/securestorage';
import { FilterTiltShiftSharp } from '@material-ui/icons';
import Pagination from '../../components/pagination/renderpage';
import { MContext } from '../../components/pagination/renderpage';
import { Asset } from '../../utils/mainasset';
const styles = (theme) => ({
    mainHeaderWidth: {
        width: '221px',
        textAlign: "left",

    },
    firstGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "firstGrid"
        }
    },
    secondGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "secondGrid"
        }
    },
    thirdGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "thirdGrid"
        }
    },
    fourthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "fourthGrid"
        }
    },
    fifthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "fifthGrid"
        }
    },
    sixthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "sixthGrid"
        }
    },
    seventhGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "seventhGrid"
        }
    },
    eighthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "eighthGrid"
        }
    },
    ninthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "ninthGrid"
        }
    },
    tenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "tenthGrid"
        }
    },
    eleventhGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "eleventhGrid"
        }
    },
    twelfthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "twelfthGrid"
        }
    },
    thirteenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "thirteenthGrid"
        }
    },
    fourteenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "fourteenthGrid"
        }
    },
    fifteenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "fifteenthGrid"
        }
    },
    sixteenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "sixteenthGrid"
        }
    },
    seventeenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "seventeenthGrid"
        }
    },
    eighteenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "eighteenthGrid"
        }
    },
    mobileResponsive: {
        display: "none",
        [theme.breakpoints.down("920")]: {
            display: "block"
        }
    },
    directionColumn: {
        display: "flex", flexDirection: "column"
    },
    otherWidthAlignment: {
        display: "flex", flexDirection: "column"
    },
    portfolioDetails: {
        // [theme.breakpoints.between('300','350')]: {
        //    display:"none !important"
        // }
    },
    mainTitle: {
        color: window.globalConfig.color.GRAY,
        fontSize: "16px"
    },
    assetClassName: {
        backgroundColor: window.globalConfig.color.PRIMARY_BORDER,
        padding: "1px 9px",
        fontSize: "18px",
        borderRadius: "9px",
        width: "max-content"
    },
    flexRow: {
        display: 'flex',
        flexDirection: "row",
        justifyContent: "space-between"
    },
    noDataDesign: {
        [theme.breakpoints.down('xs')]: {
            height: 50,
            width: "100%",
            paddingTop: 30,
            paddingBottom: 30
        }
    },
    labelStyle: {
        fontSize: 10, color: colors.PRIMARY_BORDER
    },
    headerMobileResponsive: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        }
    }
    , desktopResponsive: {
        display: "block",
        [theme.breakpoints.down("920")]: {
            display: "none !important"
        }
    }
});
class PortfolioReport extends React.Component {
    constructor() {
        super();
        this.state = {
            checked: true,
            accpdf: false,
            accexcel: false,
            Open: false,
            ProductID: '',
            isDesktopView: true
        }
    }

    resize() {
        let currentHideNav = (window.innerWidth >= 920);
        if (currentHideNav !== this.state.isDesktopView) {
            // 
            this.setState({ isDesktopView: currentHideNav });
        }

    }
    componentDidUpdate() {
        if (secureStorage.getItem("withRedeemChecked") != null) {

            if (secureStorage.getItem("withRedeemChecked") != this.state.checked) {
                this.setState({ checked: secureStorage.getItem("withRedeemChecked") })
            }
        }

    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this))
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));

        this.resize();
        switch (this.props.flag) {
            case "mutualfunds":
                return this.setState({
                    ProductID: 1, checked: secureStorage.getItem("mfRedeemUnits") == null ? true : secureStorage.getItem("mfRedeemUnits")
                });
            case "equity":
                return this.setState({
                    ProductID: 2, checked: secureStorage.getItem("stocksRedeemUnits") == null ? true : secureStorage.getItem("stocksRedeemUnits")
                });
            case constants.Common.Bonds:
                return this.setState({
                    ProductID: 3, checked: secureStorage.getItem("bondsRedeemUnits") == null ? true : secureStorage.getItem("bondsRedeemUnits")
                });
            case constants.Common.GB:
                return this.setState({
                    ProductID: 4, checked: true
                });
            default:
                this.setState({
                    ProductID: 5, checked: true
                });
        }
    }
    handleChange = (event) => {
        this.setState({ checked: event.target.checked });
    };
    onclickpdf = (ext) => {
        var pdfexcel = false;
        var filename = `${Label.DownloadLabel.portfoliodetails}${ext}`
        if (this.state.accpdf == false) {
            if (ext === "pdf") {
                this.setState({ accpdf: true })
                pdfexcel = true;
            }
        }
        if (this.state.accexcel == false) {
            if (ext === "xlsx") {
                this.setState({ accexcel: true })
                pdfexcel = true;
            }
        }
        let authToken = ''
        if (!Boolean(secureStorage.getItem(`authToken-${secureStorage.getItem("sessionID")}`))) {
            authToken = labels.key.apiKey
        } else {
            authToken = secureStorage.getItem(`authToken-${secureStorage.getItem("sessionID")}`)
        }
        let nodeToken = secureStorage.getItem(`nodeToken-${secureStorage.getItem("sessionID")}`)

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', `Bearer ${nodeToken}`);
        myHeaders.append('Token', labels.key.apiKey);
        myHeaders.append("Export", false)
        myHeaders.append('SessionID', secureStorage.getItem("sessionID"));
        myHeaders.append('Access-Control-Allow-Origin', `${window.globalConfig.Domain}`,
        )
        myHeaders.append('Source', Asset.source
        )
       
        myHeaders.append('gw', EncryptURL(apiurl.DownloadReport))
        myHeaders.append('Strict-Transport-Security', 'max-age=31536000; includeSubDomains; preload')

        // myHeaders.append( 'ClientID',Encryption(this.props.logindetails.ClientID))
        var raw = JSON.stringify({
            "ClientID": this.props.logindetails.ClientID, "AccountID": 0, "ReportID": 1,
            "AsOnDate": moment().format("DD MMM YYYY"),
            "WithRedeem": this.state.checked, "SubReport": 0, "DownloadAs": ext == "pdf" ? 1 : 2,
            "ProductID": this.state.ProductID, "FamilyID": 0, "L5ID": this.props.L5ID, "L4ID": 0
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        if (pdfexcel == true) {
            fetch("/gw", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status == "S" && result.data != "") {
                        if (ext === "pdf") {
                            // setaccpdf(true)
                            this.setState({ accpdf: true })
                        }
                        if (ext === "xlsx") {
                            //  setaccexcel(true)
                            this.setState({ accexcel: true })
                        }

                        getAllcommonDownloadDetails(result.data, ext, filename, this.props.logindetails?.SessionID).then((response) => {
                            if (response === "success") {
                                if (ext === "pdf") {
                                    this.setState({ accpdf: false })
                                }
                                if (ext === "xlsx") {
                                    this.setState({ accexcel: false })
                                }
                            } else {
                                this.setState({ Open: true })
                                if (ext === "pdf") {
                                    this.setState({ accpdf: false })
                                }
                                if (ext === "xlsx") {
                                    this.setState({ accexcel: false })
                                }
                            }
                        })

                    } else {
                        this.setState({ Open: true })
                        if (ext === "pdf") {
                            // setaccpdf(true)
                            this.setState({ accpdf: false })
                        }
                        if (ext === "xlsx") {
                            //  setaccexcel(true)
                            this.setState({ accexcel: false })
                        }
                    }
                })
                .catch(error => {
                    this.setState({ Open: true })
                    if (ext === "pdf") {
                        this.setState({ accpdf: false })
                    }
                    if (ext === "xlsx") {
                        this.setState({ accexcel: false })
                    }
                });
        }
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ Open: false })
    };

    onCheck = () => {
        //DD/MM/YYYY
        this.props.GetPortfolioReport(this.props.logindetails.ClientID, 0, this.props.date, !this.state.checked, this.state.ProductID, 0, this.props.L5ID, 0, this.state.ProductID)
        this.setState({ checked: !this.state.checked })
        if (this.state.ProductID == 1) {
            secureStorage.setItem("mfRedeemUnits", !this.state.checked)

        } else if (this.state.ProductID == 2) {
            secureStorage.setItem('stocksRedeemUnits', !this.state.checked)

        } else if (this.state.ProductID == 3) {
            secureStorage.setItem("bondsRedeemUnits", !this.state.checked)

        }
    }
    render() {
        const { classes } = this.props
        let combainStyle = Object.assign({}, classes, this.props.externalClasses)
        try {
            return (
                <div>
                    <div>
                        {
                            this.props.isPortfolioReportLoading == true ?

                                <Loader page="skeletonreport" />

                                :
                                <>
                                    <div >
                                        <CommonHeaders
                                            header={this.props.header}
                                            showfilter={false}
                                            showdownload={true}
                                            showcheckbox={true}
                                            flag={this.props.flag}
                                            pdfload={this.state.accpdf}
                                            List={this.props.portfolioreport == "NA" ? [] : this.props.portfolioreport}
                                            newxcelLoad={this.state.accexcel}
                                            data={this.props.portfolioreport == "NA" ? [] : this.props.portfolioreport}
                                            // scheme={}
                                            checked={this.state.checked}
                                            onclickCheck={this.onCheck}

                                            onclick={this.onclickpdf} page={"portfolioreport"} />
                                    </div>
                                    {/* {console.log(this.props.portfolioreport)} */}
                                    <ErrorBoundary>
                                        {this.props.portfolioreport.length > 0 && <> <div style={{ marginTop: "3%", marginBottom: "3%" }}>
                                            <div className={[classes.TenSplitContainer, combainStyle.tableHeader, combainStyle.desktopResponsive].join(' ')} >
                                                {this.props.title.map((item, index) => {
                                                    return (<div key={index} style={{ display: "flex", flexDirection: "column", paddingLeft: item.name.toLowerCase() == "TXN TYPE".toLowerCase() ? 10 : 0, paddingRight: item.name === "ABS.Ret(%)" ? 10 : 0, textAlign: item.name.toLowerCase() == "TXN TYPE".toLowerCase() ? "left" : 'right' }}><div>
                                                        {item.name}
                                                    </div>
                                                        {item.subheader != undefined ? <div>{item.subheader}</div> : <div></div>}
                                                    </div>
                                                    )
                                                })}
                                            </div>
                                            <div className={[combainStyle.tableHeader, combainStyle.mobileResponsive].join(' ')} >
                                                <div style={{ textAlign: "left", paddingLeft: 10 }}>{this.props.title[0].name}</div>
                                            </div>
                                            {/* {console.log(this.props.portfolioreport)} */}
                                            <Pagination enableLazyLoading={false} data={this.props.portfolioreport}>
                                                <MContext.Consumer>
                                                    {(response) => {
                                                        let context = []
                                                        context = response
                                                        // console.log(context.filter((ele, ind) => ind === context.findIndex(elem => elem.AssetClassName === ele.AssetClassName)))

                                                        return <>{context.filter((ele, ind) => ind === context.findIndex(elem => elem.AssetClassName === ele.AssetClassName)).map((item, mainindex) => {
                                                            return <>
                                                                <div className={[combainStyle.portfolioTwoSplitContainer, combainStyle.categoryGroupContainer, mainindex == 0 && combainStyle.borderRadiousFirstIndex].join(' ')} style={{ marginTop: mainindex == 0 ? 0 : "1%", }}>
                                                                    <div className={[combainStyle.mainHeaderWidth, combainStyle.categoryMainHeader].join(' ')}>
                                                                        <div className={combainStyle.assetClassName} style={{ marginLeft: 10 }}>
                                                                            {item.AssetClassName}
                                                                        </div>
                                                                    </div>
                                                                    <div className={combainStyle.otherWidth}>
                                                                    </div>
                                                                </div>
                                                                {context.filter((ele, ind) => this.props.flag != "mutualfunds" ? (ind === context.findIndex(elem => elem.SchemeName === ele.SchemeName) && ele.AssetClassName == item.AssetClassName) : (ind === context.findIndex(elem => elem.FolioNo === ele.FolioNo && elem.AssetClassName == item.AssetClassName))).map((values, index1) => {
                                                                    // console.log(index1 == 1 ? values : "")
                                                                    return <>
                                                                        <div className={[combainStyle.portfolioReportEightSplitContainer, combainStyle.additionalCategoryheader, index1 != 0 ? combainStyle.borderRadiousOtherIndex : combainStyle.borderRadiousFirstIndex].join(' ')} style={{ paddingLeft: 10, }}>
                                                                            <div className={[combainStyle.firstGrid, "firstGrid", combainStyle.subCatgoryValue, combainStyle.directionColumn].join(' ')}>
                                                                                {values.SchemeName}
                                                                                {this.props.flag == "mutualfunds" &&
                                                                                    <div> Folio No: {values.FolioNo}</div>}
                                                                            </div>

                                                                            <div className={[combainStyle.secondGrid, "secondGrid", combainStyle.subCatgoryValue].join(' ')}>
                                                                                <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>{"Gross Pur.Price(₹)"}</span>

                                                                                <div>
                                                                                    <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                    {NegativeFinding(values.FolioSchemeWise_CostOfAquisition, "", "", "", "number")}

                                                                                </div>

                                                                            </div>
                                                                            <div className={[combainStyle.thirdGrid, "thirdGrid", combainStyle.subCatgoryValue].join(' ')}>
                                                                                <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>{"Curr.amt(₹)"}</span>
                                                                                <div >
                                                                                    <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                    {NegativeFinding(values.FolioSchemeWise_CurrentValue, "", "", "", "number")}
                                                                                </div>

                                                                            </div >
                                                                            <div className={[combainStyle.fourthGrid, "fourthGrid", combainStyle.subCatgoryValue].join(' ')}>
                                                                                <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>{"Gain/Loss(₹)"}</span>

                                                                                <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                <span style={{ color: NegativeFinding(values.FolioSchemeWise_ProfitLoss) }}>{NegativeFinding(values.FolioSchemeWise_ProfitLoss, "", "", "", "number")}</span>

                                                                            </div>
                                                                            <div className={[combainStyle.fifthGrid, "fifthGrid", combainStyle.directionColumn, combainStyle.subCatgoryValue].join(' ')}>
                                                                                <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>{"Div.pay(₹) / Div.Rein"}</span>

                                                                                <div>
                                                                                    <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                    {NegativeFinding(values.FolioSchemeWise_DivPay, "", "", "", "number")}
                                                                                </div>
                                                                                {this.props.flag != "equity" &&
                                                                                    <div>
                                                                                        <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                        {NegativeFinding(values.FolioSchemeWise_DivReInv, "", "", "", "number")}
                                                                                    </div>}

                                                                            </div>
                                                                            <div className={[combainStyle.sixthGrid, "sixthGrid", combainStyle.subCatgoryValue].join(' ')}>
                                                                                <span className={combainStyle.mobileResponsive}>{"Net Gain / Loss"}</span>

                                                                                <div style={{ color: NegativeFinding(values.netloss) }}>
                                                                                    <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                    {NegativeFinding(values.FolioSchemeWise_NetLoss, "", "", "", "number")}
                                                                                </div>
                                                                            </div>
                                                                            <div className={[combainStyle.seventhGrid, "seventhGrid", combainStyle.subCatgoryValue].join(' ')}>
                                                                                <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>{this.props.title[8].name}</span>

                                                                            </div>
                                                                            <div className={[combainStyle.eighthGrid, "eighthGrid", combainStyle.directionColumn, combainStyle.subCatgoryValue].join(' ')}>
                                                                                <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>{"ABS.RET(%)/XIRR(%)"}</span>

                                                                                <div style={{ color: NegativeFinding(Number(values.FolioSchemeWise_AbsReturn)) }}>
                                                                                    {NegativeFinding(values.FolioSchemeWise_AbsReturn, "", "", "", "percentage")}
                                                                                </div>
                                                                                <div style={{ color: NegativeFinding(Number(values.FolioSchemeWise_Xirr)) }}>
                                                                                    {NegativeFinding(values.FolioSchemeWise_Xirr, "", "", "", "percentage")}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        {context.filter((val) => this.props.flag != "mutualfunds" ? val.SchemeName === values.SchemeName : val.FolioNo == values.FolioNo && val.AssetClassName == item.AssetClassName).map((value, subindex) => {
                                                                            return <div key={subindex} className={[combainStyle.portfolioReportTenSplitContainer, combainStyle.subCategoryGroupContainer, subindex === 0 ? combainStyle.borderRadiousFirstIndex : combainStyle.borderRadiousOtherIndex].join(' ')} style={{ paddingLeft: 10 }}>
                                                                                <div className={[combainStyle.ninthGrid, "ninthGrid", combainStyle.subCatgoryValue, combainStyle.directionColumn].join(' ')} >
                                                                                    {value.TransactionType}
                                                                                </div>
                                                                                <div className={[combainStyle.subCatgoryValue, combainStyle.tenthGrid, "tenthGrid"].join(' ')}>
                                                                                    <span>{moment(value.TransactionDate).format("DD MMM YYYY")}</span>

                                                                                </div>
                                                                                <div className={[combainStyle.subCatgoryValue, combainStyle.eleventhGrid, "eleventhGrid", combainStyle.directionColumn].join(' ')}>
                                                                                    <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>{"Pur.Price / Qty"}</span>
                                                                                    {value.Quantity}
                                                                                    <div>
                                                                                        <span className={combainStyle.subcategoryRupee}>{Label.currency.Rupee}</span>
                                                                                        {NegativeFinding(value.PurchaseNAV, "", "", "", "number")}
                                                                                    </div>
                                                                                </div>
                                                                                <div className={[combainStyle.subCatgoryValue, combainStyle.directionColumn, combainStyle.twelfthGrid, "twelfthGrid"].join(' ')}>
                                                                                    <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>Gross Amt</span>
                                                                                    <div>
                                                                                        <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                        {NegativeFinding(value.CostOfAquisition, "", "", "", "number")}
                                                                                    </div>

                                                                                </div>
                                                                                <div className={[combainStyle.subCatgoryValue, combainStyle.thirteenthGrid, "thirteenthGrid"].join(' ')}>
                                                                                    <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>{"Curr.amt(₹)"}</span>
                                                                                    <div >
                                                                                        <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                        {NegativeFinding(value.CurrentValue, "", "", "", "number")}
                                                                                    </div>

                                                                                </div >
                                                                                <div style={{ color: NegativeFinding(value.ProfitLoss) }} className={[combainStyle.subCatgoryValue, combainStyle.fourteenthGrid, "fourteenth"].join(' ')}>
                                                                                    <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                    {NegativeFinding(value.ProfitLoss, "", "", "", "number")}

                                                                                </div>
                                                                                <div className={[combainStyle.subCatgoryValue, combainStyle.directionColumn, combainStyle.fifteenthGrid, "fifteenthGrid"].join(' ')}>
                                                                                    <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>{"Div.pay(₹)/Div.Rein"}</span>
                                                                                    <div>

                                                                                        <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                        {NegativeFinding(value.DivPay, "", "", "", "number")}
                                                                                    </div>
                                                                                    {this.props.flag != "equity" &&
                                                                                        <div>
                                                                                            <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                            {NegativeFinding(value.DivReInv, "", "", "", "number")}
                                                                                        </div>}

                                                                                </div>
                                                                                <div className={[combainStyle.subCatgoryValue, combainStyle.sixteenthGrid, "sixteenthGrid"].join(' ')}>
                                                                                    <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>{"Net Gain / Loss"}</span>
                                                                                    <div style={{ color: NegativeFinding(Number(value.NetLoss)) }}>
                                                                                        <span className={combainStyle.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                                                                        {NegativeFinding(value.NetLoss, "", "", "", "number")}
                                                                                    </div>
                                                                                </div>
                                                                                <div className={[combainStyle.subCatgoryValue, "seventeenthGrid", combainStyle.seventeenthGrid].join(' ')}>
                                                                                    <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>Days</span>
                                                                                    {value.Days}
                                                                                </div>
                                                                                <div className={[combainStyle.absnetWidth, combainStyle.subCatgoryValue, "eighteenthGrid", combainStyle.eighteenthGrid].join(' ')}>
                                                                                    <div style={{ color: NegativeFinding(value.AbsReturn), }}>
                                                                                        {NegativeFinding(value.AbsReturn, "", "", "", "percentage")}

                                                                                    </div>
                                                                                    <div>
                                                                                        {"-"}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        })}
                                                                    </>
                                                                })}
                                                            </>
                                                        })}
                                                            {this.props.totalreport != "NA" && this.props.totalreport.map((item, index) => {
                                                                return (
                                                                    <div key={index} className={[combainStyle.portfolioReportEightSplitContainer, combainStyle.totalContainer].join(' ')} >
                                                                        <div className={[combainStyle.firstGrid, "firstGrid"].join(" ")} style={{ paddingLeft: 10 }}>
                                                                            {labels.xraywithredemption.grandtotal}
                                                                        </div>

                                                                        <div className={[combainStyle.secondGrid, "secondGrid"].join(" ")}>
                                                                            <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>{"Gross Pur.Price(₹)"}</span>

                                                                            <div>
                                                                                <span className={combainStyle.totalRupee}>{labels.currency.rupeesymbol}</span>
                                                                                {NegativeFinding(item.CostOfAquisition, "", "", "", "number")}
                                                                            </div>
                                                                        </div>
                                                                        <div className={[combainStyle.thirdGrid, "thirdGrid"].join(" ")}>
                                                                            <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>{"Curr.amt(₹)"}</span>
                                                                            <span className={combainStyle.totalRupee}>{labels.currency.rupeesymbol}</span>
                                                                            {NegativeFinding(item.CurrentValue, "", "", "", "number")}
                                                                        </div>
                                                                        <div className={[combainStyle.fourthGrid, "fourthGrid"].join(" ")}>
                                                                            <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>{"Gain/Loss(₹)"}</span>

                                                                            <div style={{ color: NegativeFinding(item.ProfitLoss) }}>
                                                                                <span className={combainStyle.totalRupee}>{labels.currency.rupeesymbol}</span>
                                                                                {NegativeFinding(item.ProfitLoss, "", "", "", "number")}
                                                                            </div>
                                                                        </div>
                                                                        <div className={[combainStyle.fifthGrid, "fifthGrid", combainStyle.directionColumn].join(' ')}>
                                                                            <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>{"Div.pay(₹) / Div.Rein"}</span>

                                                                            <div>
                                                                                <span className={combainStyle.totalRupee}>{labels.currency.rupeesymbol}</span>
                                                                                {NegativeFinding(item.DivPay, "", "", "", "number")}
                                                                            </div>
                                                                            <div>
                                                                                <span className={combainStyle.totalRupee}>{labels.currency.rupeesymbol}</span>
                                                                                {item.DivReInv}
                                                                            </div>

                                                                        </div>
                                                                        <div className={[combainStyle.sixthGrid, "sixthGrid"].join(' ')} style={{ color: NegativeFinding(item.NetLoss) }}>
                                                                            <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>{"Net Gain / Loss"}</span>

                                                                            <span className={combainStyle.totalRupee}>{labels.currency.rupeesymbol}</span>
                                                                            {NegativeFinding(item.NetLoss, "", "", "", "number")}
                                                                        </div>
                                                                        <div className={[combainStyle.seventhGrid, "seventhGrid"].join(" ")} >

                                                                        </div>
                                                                        <div className={[combainStyle.eighthGrid, "eighthGrid", combainStyle.directionColumn].join(' ')} style={{ paddingRight: 10 }}>
                                                                            <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>{"ABS.RET(%)/XIRR(%)"}</span>
                                                                            <span style={{ color: NegativeFinding(item.AbsReturn) }}>{NegativeFinding(item.AbsReturn, "", "", "", "percentage")}</span>
                                                                            <span style={{ color: NegativeFinding(item.Xirr) }}>{NegativeFinding(item.Xirr, "", "", "", "percentage")}</span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </>
                                                    }}
                                                </MContext.Consumer>
                                            </Pagination>
                                        </div>
                                        </>
                                        }


                                        <CommonSnackbar open={this.state.Open} severity={"warning"} handlesnanckClose={this.handlesnanckClose} message={labels.messages.apierror} />

                                    </ErrorBoundary>

                                </>
                        }
                    </div >
                </div >
            )
        }
        catch (err) {
            // console.log(err)
            return <Redirect to={labels.Path.NotFound} />
        }
    }

}
const mapDispatchToProps = dispatch => ({
    GetPortfolioReport: (ClientID, AccountID, AsOnDate, WithRedemption, ProductID, FamilyID, L5ID, L4ID, flag) => dispatch(GetPortfolioReport(ClientID, AccountID, AsOnDate, WithRedemption, ProductID, FamilyID, L5ID, L4ID, flag))

});
const mapStateToProps = state => ({
    sessionId: state.Login.sessionID,
    clientId: state.Login.logindetails.ClientID,
    date: state.Login.date,
    logindetails: state.Login.logindetails,
    L5ID: state.Login.L5ID,
});
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(PortfolioReport));
