import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
import { Encryption } from '../../utils/commonfunction';
function LoginWithSendOTP(email) {

  let data = {};
  data.EmailID = Encryption(email);


  // var config = {
  //   method: 'post',
  //   url: ApiValues.SendOtpPassword,
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   data: data
  // };

  // return axios(config)
  //   .then(function (response) {
  //     return response.data;
  //   })
  //   .catch(function (error) {
  //     return {"status":"F","message":labels.messages.apierror}
  //   });
  
  return Networking.PostApi(ApiValues.LoginwithOtp,data,labels.apiKey.apiKeys,labels.apiKey.apiKeys,true).then(result => { return result })
  .catch(function (error) {
    return { "status": "F", "message": labels.messages.apierror }
  });
}
export default LoginWithSendOTP
