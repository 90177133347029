import React from 'react';
import classnames from 'classnames';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { usePagination, DOTS } from './usepagination';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import colors from '../../utils/colors/colors';
import './pagination.css';
import { useEffect } from 'react';
import { scrollToTopPage } from '../../utils/commonfunction';
import { connect } from 'react-redux';
const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    handleChange,
    callbackPagination
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });
 
  useEffect(() => {
    callbackPagination(paginationRange)
 
  })
  // const paginationCount=()=>{
    
  // }
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }
  const onNext = () => {
    onPageChange(currentPage + 1);
    //   scrollToTopPage()
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange?.length - 1];
  return (
    props.enableLazyLoading?<></>:
    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
        <div style={{ color: colors.LABLE_GRAY, fontSize: 14 }}>pg.size</div>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={pageSize}
          onChange={handleChange}
        >
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={25}>20</MenuItem>
          <MenuItem value={50}>50</MenuItem>
          <MenuItem value={100}>100</MenuItem>
        </Select>
      </div>
      <ul
        className={classnames('pagination-container', { [className]: className })}
      >
        <li
          className={classnames('pagination-item', {
            disabled: currentPage === 1
          })}
          style={{ cursor: currentPage === 1 ? "not-allowed" : "pointer", opacity: currentPage === 1 ? 0.5 : 1 }}
          onClick={currentPage === 1 ? () => { } : onPrevious}
        >
          <ChevronLeftIcon></ChevronLeftIcon>
        </li>
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return <li key={`dot${index}`} className="pagination-item dots">&#8230;</li>;
          }

          return (
            <li
              key={`main${index}`}
              className={classnames('pagination-item', {
                selected: pageNumber === currentPage
              })}
              onClick={() => {
                onPageChange(pageNumber)
                scrollToTopPage()
              }}
            >
              {pageNumber}
            </li>
          );
        })}
        <li
          className={classnames('pagination-item', {
            disabled: currentPage === lastPage
          })}
          style={{ cursor: currentPage === lastPage ? "not-allowed" : "pointer", opacity: currentPage === lastPage ? 0.5 : 1 }}
          onClick={currentPage === lastPage ? () => { } : onNext}
        >
          <ChevronRightIcon></ChevronRightIcon>
        </li>
      </ul>
    </div>

  );
};
const mapStateToProps = state => {
  return {
    isReachedBottom:state.Login.isReachedBottom
  }
}
export default connect(mapStateToProps,null)(Pagination);
