import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
import Networking from '../../utils/api/apiaccess';
var axios = require('axios');

/* name should be camel case in all function */
function getAllFamilyProfitLossDetails(ClientID, CommonDate, SessionID, ProductID,) {
  //let BaseUrl = "https://ms.zoifintech.com/Dashboard/GetProfitLossDetails"
  let data = {};
  // data.Account = 0
  data.ClientID = ClientID
  data.CommonDate = CommonDate
  // data.Date = ""
  // data.ToDate = ""
  data.SessionID = SessionID
  // data.AccountType = 0
  // data.AssetClass = 0
  // data.Scheme = 0
  data.ProductID = ProductID
  // data.FamilyID = 0
  // data.L5ID = 0
  // data.L4ID = 0


  // var config = {
  //   method: 'post',
  //   url: ApiValues.ProfitAndLoss,
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   data: data
  // };

  // return axios(config)
  //   .then(function (response) {
  //     return response.data;
  //   })
  //   .catch(function (error) {
  //     return { "status": "F", "message": labels.messages.apierror }

  //   });
  return Networking.PostApi(ApiValues.FamilyProfitAndLoss, data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default getAllFamilyProfitLossDetails
