import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Route, Switch, Router } from 'react-router-dom'
import Label from '../../utils/constants/labels'
import Layout from '../../containers/layout/layout';
import Real_Estate_layout from '../../containers/layout/real_estate_layout';
import Bonds_Layout from '../../containers/layout/bonds_layout';
import NPS_Layout from '../../containers/layout/nps_layout';
import Insurance_Layout from '../../containers/layout/insurance_layout';
// import InsuranceReport from '../insureanceReport/insureanceReport';
import FD_Layout from "../../containers/layout/FD_layout"
import Transact from '../../containers/transact/transact'

import GB_Layout from '../../containers/layout/GB_layout'
import EquityLayout from '../../containers/layout/equity_layout';
import Payment from '../../containers/payment/index';
// import { Trading, } from '../../containers/pagelistAsync';
import TradingDetails from '../../containers/trading/tradingschemedetails'
// import { MFTransaction } from '../../containers/pagelistasync';
import MFTransaction from '../../containers/trading/mftransaction'
import Schemes from '../../containers/schemes/schemes';
import BasketScreen from '../../containers/basketScreen/basket_screen';
import Basket from '../../containers/basket/basketdetails';
// import window.globalConfig.color from '../../utils/window.globalConfig.color/window.globalConfig.color'
import CommonMenu from '../menu/menu';
import { dateChage } from '../../redux/actions/login/loginaction';
// import Footer from '../footer/footer'
import { ClickAwayListener } from '@material-ui/core';
import { loginsession } from '../../redux/actions/login/loginaction'
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import '../../utils/stylesheets/sidemenu/sidemenu.css';
import Cart from '../../containers/cart/cart.js';
import TransactionOrder from '../transactionorder/transactionorder';
import { getTransactionOrderReport } from '../../redux/actions/transactionorderreport/transactionorderreport';
import moment from 'moment';
import secureStorage from '../../utils/securestorage'
// import { getEmptyAction } from '../../redux/actions/login/loginaction';
import { currentDate } from '../../utils/commonfunction';
import ErrorBountry from '../Errorhandling/Errorhandle'
import labels from '../../utils/constants/labels';
import Portfolio from '../../containers/portfolio/portfolio';
import PortfolioImageNormal from '../../utils/assets/images/img7.png'
import PortfolioImageHover from '../../utils/assets/images/img7-3.png'
import PortfolioImageClick from '../../utils/assets/images/img7-2.png'
import ReportsNormal from '../../utils/assets/images/img8.png'
import ReportsHover from '../../utils/assets/images/img8-3.png'
import ReportsClick from '../../utils/assets/images/img8-2.png'
import BuysellNormal from '../../utils/assets/images/img9.png'
import BuysellHover from '../../utils/assets/images/img9-3.png'
import BuysellClick from '../../utils/assets/images/img9-2.png'
import constants from '../../utils/constants/apiconstant';
import Aif_layout from '../../containers/layout/aif_layout';
import Epf_layout from '../../containers/layout/epf_layout';
import Mld_layout from '../../containers/layout/mld_layout';
import Liquiloans_layout from '../../containers/layout/liquiloans_layout';
const styles = theme => ({
    root: {
        width: '100%',
        maxWidth: "85px !important",
        top: '40%',
        marginTop: "-3.5em",
        backgroundColor: window.globalConfig.color.WHITE,
        position: 'fixed',
        // [theme.breakpoints.up("1024")]:{
        //     maxWidth: "80px !important",
        // }, 
        // [theme.breakpoints.down("1440")]:{
        // maxWidth: "85px !important",
        // },
        // [theme.breakpoints.up("1440")]:{
        //     maxWidth: "100px !important",
        // },
        boxShadow: '0 3px 20px 0 rgba(19, 89, 161, 0.1)',
        zIndex: 32,
        padding: 0,
        borderRadius: "0px 9px 0px 0px",
        [theme.breakpoints.down("800")]: {
            top: 'unset',
            bottom: 0,
            justifyContent: 'center',
            backgroundColor: 'unset',
            display: "flex",
            boxShadow: 'none'
            //  marginLeft:'34vw'
        },
    },
    display: {
        display: "inline-block",
        // width: 100,
        marginLeft: -13,
        textAlign: "center",
        [theme.breakpoints.between("350", "400")]: {
            // width: 'unset',
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: 'row',
            width: 115,
            paddingLeft: 20
        },
        [theme.breakpoints.between("400", "600")]: {
            // width: 'unset',
            whiteSpace: 'nowrap',
            display: 'flex',
            flexDirection: 'row',
            width: 125,
            paddingLeft: 20
        }
    },
    img: {
        height: 28,
    },
    heading: {
        fontSize: "10px",
        marginTop: "1em",
        textDecoration: 'none',
        [theme.breakpoints.down("800")]: {
            //marginTop: "unset",
            marginTop: "5px",
            fontSize: "12px",
            lineHeight: "none"
        },
    },

    link: {

        textDecoration: 'none',
        color: window.globalConfig.color.LABLE_GRAY,
        [theme.breakpoints.down("600")]: {
            backgroundColor: window.globalConfig.color.WHITE,
        },
    },
    popover: {
        cursor: "pointer",
        borderRadius: "0px !important",
    },
    paper: {
        borderTopLeftRadius: "5px !important",
        borderTopRightRadius: "5px !important",
        [theme.breakpoints.down("600")]: {
            top: '128vw !important',
            // position:'relative',
        }
    },
    popup: {
        backgroundColor: window.globalConfig.color.WHITE,
        fontSize: 10,
        width: 200,
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,
        [theme.breakpoints.down("600")]: {
            width: 167,
            borderRadius: 10,

        },
    },
    list: {
        cursor: "pointer",

        [theme.breakpoints.down("600")]: {
            width: 165,
            borderRadius: 10,

        },
    },

    header: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: "white",
        height: 50,
        fontWeight: 500
    },
    txtHeader: {
        padding: 15,
    },
    linkContainer: {
        maxHeight: '26vw', overflowY: "scroll",
        [theme.breakpoints.down("600")]: {
            maxHeight: '100vw',
        }
    },
    submenucontainer: {
        [theme.breakpoints.down("600")]: {
            top: '-54vh !important',
            left: "0px !important",
            height: '54vh',
            overflow: "scroll",
            width: "60vw !important"
        }
    }, mainmenucontainer: {
        [theme.breakpoints.down("600")]: {
            left: "-40vw !important", top: "-15vh !important",
            width: "60vw !important"
        }

    }
    , showcontainer: {
        display: "block"
    }, hidecontainer: {
        display: "none"
    },
    firstmenu: {
        borderTopRightRadius: "8px",
        [theme.breakpoints.down("800")]: {
            borderTopRightRadius: "0px",
            borderRight: `1px solid ${window.globalConfig.color.BORDER}`

        }
    },
    secondmenu: {
        [theme.breakpoints.down("800")]: {
            // borderTopRightRadius:"0px",
            borderRight: `1px solid ${window.globalConfig.color.BORDER}`

        }
    },
    labscreen: {
        [theme.breakpoints.down("800")]: {
            backgroundColor: window.globalConfig.color.WHITE,
            boxShadow: "0 3px 20px 0 rgba(19, 89, 161, 0.1)"
        }
    },
    navLeftFix: {
        position: "fixed",
        top: "50%",
        zIndex: 22,
        marginTop: -120,
        boxShadow: "0 3px 20px 0 rgb(19 89 161 / 10%)",
        borderTopRightRadius: 9,
        borderBottomRightRadius: 9,
        [theme.breakpoints.down("920")]: {
            display: "none",
        }

    },
    boxStyle: {
        width: '80px',
        height: '65px',
        display: "block",
        textAlign: "center",
        backgroundColor: window.globalConfig.color.WHITE,
        color: window.globalConfig.color.BLACK_PRIMARY,
        borderBottom: `1px solid ${window.globalConfig.color.BORDER}`,
        fontSize: "11px",
        lineHeight: 1.36,
        paddingTop: "17px",
        cursor: "pointer",
        '&:hover': {
            backgroundColor: window.globalConfig.color.RosyBrown,
            color: window.globalConfig.color.BROWN
        },
        [theme.breakpoints.down("800")]: {
            borderRight: `1px solid ${window.globalConfig.color.BORDER}`,
        }


    },
    blockedboxStyle: {
        width: '80px',
        height: '65px',
        display: "block",
        textAlign: "center",
        backgroundColor: window.globalConfig.color.WHITE,
        color: window.globalConfig.color.BLACK_PRIMARY,
        borderBottom: `1px solid ${window.globalConfig.color.BORDER}`,
        fontSize: "11px",
        lineHeight: 1.36,
        paddingTop: "17px",
        cursor: "not-allowed",

        [theme.breakpoints.down("800")]: {
            borderRight: `1px solid ${window.globalConfig.color.BORDER}`,
        }
    },
    portfolioContainer: {
        '&:hover': {
            color: window.globalConfig.color.BROWN
        }
    },
    navDropBox: {
        //  display: 'none',
        width: 152,
        boxShadow: "7px 7px 20px 0 rgb(0 0 0 / 20%)",
        position: "absolute",
        top: "-203px",
        left: '80px',
        cursor: "pointer",
        [theme.breakpoints.down("800")]: {
            top: "auto",
            bottom: '15vh',
            left: '-57px',
            width: 202,
            // marginLeft:"-67px"
            zIndex: 9,
            cursor: "default"
        },
        [theme.breakpoints.down("600")]: {
            top: "auto",
            bottom: 82,
            left: '-6vw',
            width: 202,
            // marginLeft:"-67px"
            zIndex: 9,
            cursor: "default"
        }
    },
    navDropBoxtwo: {
        //  display: 'none',
        width: 202,
        boxShadow: "7px 7px 20px 0 rgb(0 0 0 / 20%)",
        position: "absolute",
        top: '0px',
        left: '242px',
        maxHeight: 280,
        overflowY: "scroll",
        overflowX: "hidden",
        overflowX: 'none',
        cursor: "pointer",
        [theme.breakpoints.down("800")]: {
            top: "auto",
            bottom: '38vh',
            left: '-54px',
            maxHeight: 293,
            // marginLeft:"-67px"
            zIndex: 9,
            cursor: "default"
        },
        [theme.breakpoints.down("600")]: {
            top: "auto",
            bottom: '38vh',
            left: '-5vw',
            maxHeight: 250,
            zIndex: 9,
            cursor: "default"

        }
    },
    navDropBoxList: {
        width: "87%",
        height: 40,
        lineHeight: 3,
        borderBottom: `1px solid ${window.globalConfig.color.BORDER}`,
        padding: 0,
        textAlign: "left",
        paddingLeft: 30,
        fontSize: 14,
        backgroundColor: window.globalConfig.color.WHITE,
        // overflowY: "scroll",
        // overflowX: "hidden",
        color: window.globalConfig.color.BLACK_PRIMARY,
        '&:hover': {
            backgroundColor: window.globalConfig.color.RosyBrown,
            color: window.globalConfig.color.BROWN
        }

    },
    selectedList: {
        backgroundColor: window.globalConfig.color.BROWN,
        color: window.globalConfig.color.WHITE,
        '&:hover': {
            backgroundColor: window.globalConfig.color.BROWN,
            color: window.globalConfig.color.WHITE,
        }
    },
    listStyle: {
        position: "relative",
        [theme.breakpoints.down("800")]: {
            float: "left"
        }
    },
    topRadius: {
        borderTopRightRadius: 9,
        [theme.breakpoints.down("800")]: {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 9,
        }
    },
    bottomRadius: {
        borderBottomRightRadius: 9,
        [theme.breakpoints.down("800")]: {
            borderBottomRightRadius: 0,
            borderTopRightRadius: 9,
        }
    },
    PortfolioImage: {

        "& span": {
            height: 27,
            display: "block",
            marginBottom: "8px",
            width: "100%",
            background: `url(${PortfolioImageNormal}) no-repeat center top`,

        },
        '&:hover': {
            "& span": {
                height: 27,
                display: "block",
                marginBottom: "8px",
                width: "100%",
                background: `url(${PortfolioImageHover}) no-repeat center top`,
            },
        }

    },
    blockedPortfolioImage: {

        "& span": {
            height: 27,
            display: "block",
            marginBottom: "8px",
            width: "100%",
            background: `url(${PortfolioImageNormal}) no-repeat center top`,

        },
    },
    ReportStyle: {
        "& span": {
            height: 27,
            display: "block",
            marginBottom: "8px",
            width: "100%",
            background: `url(${ReportsNormal}) no-repeat center top`,

        },
        '&:hover': {
            "& span": {
                height: 27,
                display: "block",
                marginBottom: "8px",
                width: "100%",
                background: `url(${ReportsHover}) no-repeat center top`,
            },
        }

    },
    blockedReportStyle: {
        "& span": {
            height: 27,
            display: "block",
            marginBottom: "8px",
            width: "100%",
            background: `url(${ReportsNormal}) no-repeat center top`,

        },
    },
    BuysellStyle: {
        "& span": {
            height: 27,
            display: "block",
            marginBottom: "8px",
            width: "100%",
            background: `url(${BuysellNormal}) no-repeat center top`,

        },
        '&:hover': {
            "& span": {
                height: 27,
                display: "block",
                marginBottom: "8px",
                width: "100%",
                background: `url(${BuysellHover}) no-repeat center top`,
            },
        }

    },
    mutualfundTopSpace: {
        overflowX: 'hidden',
        [theme.breakpoints.up(900)]: {
            marginTop: "-253%"
        }

    },
    equityTopSpace: {
        overflowX: 'hidden',
        [theme.breakpoints.up(900)]: {
            marginTop: "-200%"
        }

    },
    bondsTopSpace: {
        overflowX: 'hidden',
        [theme.breakpoints.up(900)]: {
            marginTop: "-150%"
        }
    },

    GBTopSpace: {
        overflowX: 'hidden',
        [theme.breakpoints.up(900)]: {
            marginTop: "60%"
        }
    },
    FDTopSpace: {
        overflowX: 'hidden',
        [theme.breakpoints.up(900)]: {
            marginTop: "-103%"
        }
    },

    AifTopSpace: {
        overflowX: 'hidden',
        [theme.breakpoints.up(900)]: {
            marginTop: "-50%"
        }
    },
    NPSTopSpace: {
        overflowX: 'hidden',
        [theme.breakpoints.up(900)]: {
            marginTop: "100%"
        }
    },
    Structured: {
        overflowX: 'hidden',
        [theme.breakpoints.up(900)]: {
            marginTop: "258%"
        }

    },
    InsuranceTopSpace: {
        overflowX: 'hidden',
        [theme.breakpoints.up(900)]: {
            marginTop: "150%"
        }
    },
    RealEstateTopSpace: {
        overflowX: 'hidden',
        [theme.breakpoints.up(900)]: {
            marginTop: "3%"
        }
    },
    tradeTopSpace: {
        [theme.breakpoints.up(900)]: {
            marginTop: "150%",
            overflowX: 'hidden',
        }
    },

})
class SideMenu extends React.Component {
    constructor() {
        super();

        this.myDiv = React.createRef()
        this.state = {
            index: 0,
            anchorEl: '',
            popover: null,
            opensession: false,
            report: true,
            isOpen: false,
            reportOpen: false,
            mutualfundsOpen: false,
            portfolio: PortfolioImageNormal,
            Reportmenu: ReportsNormal,
            buysell: BuysellNormal,
            flag: "mutualfunds",
            openMfTrading: false
        }
    }
    ClickAwayListenerClose = () => {
        if (screen.width < 800) {
            this.setState({ mutualfundsOpen: false, reportOpen: false })
        }
    }

    componentDidMount() {

        if (localStorage.getItem("cartpath") == Label.Path.Cart) {
        }
        var session = secureStorage.getItem('user');
        var session_store = session;

        var pathname = this.props.location.pathname
        if (pathname == Label.Path.MutualTab || pathname == Label.Path.LiquiloansTab || pathname == Label.Path.MLDTab || pathname == Label.Path.EPFTab || pathname === Label.Path.RealEstateTab || pathname === Label.Path.AIFTab || pathname === Label.Path.NPSTab || pathname === Label.Path.InsuranceTab || pathname == Label.Path.Equity || pathname == Label.Path.BondsTab || pathname == Label.Path.GoldBondsTab || pathname == Label.Path.FDTab) {
            this.setState({ index: 1 })
        } else if (pathname == Label.Path.Equity) {
            this.setState({ index: 1 })
        } else if (pathname == Label.Path.TradingDetail) {
            this.setState({ index: 3 })
        } else if (pathname == Label.Path.Scheme) {
            this.setState({ index: 3 })
        } else if (pathname == Label.Path.Cart || pathname == Label.Path.Basket) {
            this.setState({ index: 3 })
        } else if (pathname == Label.Path.Payment) {
            this.setState({ index: 3 })
        }
        var session = secureStorage.getItem('user');
        var session_store = session;
        var a = this.props.location.pathname.split("/")
        if (session_store == null) {
            if (a[1] === "admin") {
                this.props.history.push("/admin/id")
            } else {
                return (<Redirect to={Label.Path.Login} />);
            }
        } else {
            if (session_store.status == "F") {
                return (<Redirect to={Label.Path.Login} />);
            }
        }

    }
    handleClickofReport = () => {
        this.setState({ reportOpen: true })
        displayone = true
    }
    mfTradingOpen = () => {
        this.setState({ openMfTrading: true })
    }
    handleClick = async (value) => {
        if (value == 0) {
            this.props.history.push(Label.Path.Portfolio);
            this.setState({ index: 0 })
        }
        else if (value == 6) {
            // this.props.history.push(Label.Path.Product);
            await this.setState({ index: 6 })
        }
        else if (value == 1) {
            this.props.history.push(Label.Path.MutualTab);
            this.setState({ index: 1 })
        } else if (value == 2) {
            this.props.history.push(Label.Path.MFTransaction);
            this.setState({ index: 1 })
        }
        else if (value == 3) {
            this.props.history.push(Label.Path.Transact);
            this.setState({ index: 3 })
        }
        else if (value == 4) {
            var userDetails = []
            userDetails = secureStorage.getItem("user")
            this.props.history.push(Label.Path.Cart);
            this.setState({ index: 3 })
        }
        else if (value === "Summary") {
            this.props.history.push({
                pathname: Label.Path.MutualTab,
                state: { data: "Summary", tabValue: 0 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Portfolio X-Ray") {
            // 
            this.props.history.push({
                pathname: Label.Path.MutualTab,
                state: { data: "PortfolioX-Ray", tabValue: 1 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Transactions") {
            this.props.history.push({
                pathname: Label.Path.MutualTab,
                state: { data: "Transaction", tabValue: 2 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Booked G/L") {
            this.props.history.push({
                pathname: Label.Path.MutualTab,
                state: { data: "Profit&Loss", tabValue: 3 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Taxation Report") {
            this.props.history.push({
                pathname: Label.Path.MutualTab,
                state: { data: "CapitalGain", tabValue: 4 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "SIP Tracker") {
            this.props.history.push({
                pathname: Label.Path.MutualTab,
                state: {
                    data: "SIPTracker",
                    tabValue: 5
                }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Account Statement") {
            this.props.history.push({
                pathname: Label.Path.MutualTab,
                state: { data: "AccountStatement", tabValue: 6 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Portfolio Details") {
            this.props.history.push({
                pathname: Label.Path.MutualTab,
                state: { data: "Portfolioreport", tabValue: 7 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "AFR Report") {
            this.props.history.push({
                pathname: Label.Path.MutualTab,
                state: { data: "AFR", tabValue: 8 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "X-Ray With Redemption") {
            this.props.history.push({
                pathname: Label.Path.MutualTab,
                state: { data: "XrayWithRedemption", tabValue: 9 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Asset Allocation") {
            this.props.history.push({
                pathname: Label.Path.MutualTab,
                state: { data: "AssetAllocation", tabValue: 10 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Market Cap Allocation") {
            this.props.history.push({
                pathname: Label.Path.MutualTab,
                state: { data: "MarketCapAllocation", tabValue: 11 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Running SIP Summary") {
            this.props.history.push({
                pathname: Label.Path.MutualTab,
                state: { data: "RunningSIPSummary", tabValue: 12 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
    }
    handleEquityClick = async (value) => {
        if (value === "Summary") {
            this.props.history.push({
                pathname: Label.Path.Equity,
                state: { data: "Summary", tabValue: 0 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Portfolio X-Ray") {
            this.props.history.push({
                pathname: Label.Path.Equity,
                state: { data: "PortfolioX-Ray", tabValue: 1 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Transactions") {
            this.props.history.push({
                pathname: Label.Path.Equity,
                state: { data: "Transaction", tabValue: 2 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Booked G/L") {
            this.props.history.push({
                pathname: Label.Path.Equity,
                state: { data: "Profit&Loss", tabValue: 3 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Taxation Report") {
            this.props.history.push({
                pathname: Label.Path.Equity,
                state: { data: "CapitalGain", tabValue: 4 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Statement") {
            this.props.history.push({
                pathname: Label.Path.Equity,
                state: { data: "AccountStatement", tabValue: 5 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Portfolio Details") {
            this.props.history.push({
                pathname: Label.Path.Equity,
                state: { data: "Portfolioreport", tabValue: 6 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "X-Ray With Sold Units") {
            this.props.history.push({
                pathname: Label.Path.Equity,
                state: { data: "XrayWithRedemption", tabValue: 7 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }

        else if (value === "Market Cap Allocation") {
            this.props.history.push({
                pathname: Label.Path.Equity,
                state: { data: "MarketCapAllocation", tabValue: 8 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Transfer In") {
            this.props.history.push({
                pathname: Label.Path.Equity,
                state: { data: "Transferin", tabValue: 9 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }


    }
    handleFDClick = async (value) => {
        if (value === "Summary") {
            this.props.history.push({
                pathname: Label.Path.FDTab,
                state: { data: "Summary", tabValue: 0 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Portfolio X-Ray") {
            this.props.history.push({
                pathname: Label.Path.FDTab,
                state: { data: "PortfolioX-Ray", tabValue: 1 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Transactions") {
            this.props.history.push({
                pathname: Label.Path.FDTab,
                state: { data: "Transaction", tabValue: 2 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Booked G/L") {
            this.props.history.push({
                pathname: Label.Path.FDTab,
                state: { data: "Profit&Loss", tabValue: 3 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Maturity Tracker") {
            this.props.history.push({
                pathname: Label.Path.FDTab,
                state: { data: "MaturityTracker", tabValue: 4 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Account Statement") {
            this.props.history.push({
                pathname: Label.Path.FDTab,
                state: { data: "AccountStatement", tabValue: 5 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Portfolio Details") {
            this.props.history.push({
                pathname: Label.Path.FDTab,
                state: { data: "Portfolioreport", tabValue: 6 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "X-Ray With Sold Units") {
            this.props.history.push({
                pathname: Label.Path.FDTab,
                state: { data: "XrayWithRedemption", tabValue: 7 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
    }
    handleAIFClick = async (value) => {

        if (value === "Summary") {
            this.props.history.push({
                pathname: Label.Path.AIFTab,
                state: { data: "Summary", tabValue: 0 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Portfolio X-Ray") {
            this.props.history.push({
                pathname: Label.Path.AIFTab,
                state: { data: "PortfolioX-Ray", tabValue: 1 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Transactions") {
            this.props.history.push({
                pathname: Label.Path.AIFTab,
                state: { data: "Transaction", tabValue: 2 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Booked G/L") {
            this.props.history.push({
                pathname: Label.Path.AIFTab,
                state: { data: "Profit&Loss", tabValue: 3 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }

    }
    handleNPSClick = async (value) => {
        if (value === "Summary") {
            this.props.history.push({
                pathname: Label.Path.NPSTab,
                state: { data: "Summary", tabValue: 0 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Portfolio X-Ray") {
            this.props.history.push({
                pathname: Label.Path.NPSTab,
                state: { data: "PortfolioX-Ray", tabValue: 1 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Transactions") {
            this.props.history.push({
                pathname: Label.Path.NPSTab,
                state: { data: "Transaction", tabValue: 2 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Booked G/L") {
            this.props.history.push({
                pathname: Label.Path.NPSTab,
                state: { data: "Transaction", tabValue: 3 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
    }
    handleRealEstateClick = async (value) => {
        if (value === "Summary") {
            this.props.history.push({
                pathname: Label.Path.RealEstateTab,
                state: { data: "Summary", tabValue: 0 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Portfolio X-Ray") {
            this.props.history.push({
                pathname: Label.Path.RealEstateTab,
                state: { data: "PortfolioX-Ray", tabValue: 1 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Transactions") {
            this.props.history.push({
                pathname: Label.Path.RealEstateTab,
                state: { data: "Transaction", tabValue: 2 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Booked G/L") {
            this.props.history.push({
                pathname: Label.Path.RealEstateTab,
                state: { data: "Profit&Loss", tabValue: 3 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }

    }
    handleGBClick = async (value) => {
        if (value === "Summary") {
            this.props.history.push({
                pathname: Label.Path.GoldBondsTab,
                state: { data: "Summary", tabValue: 0 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Portfolio X-Ray") {
            this.props.history.push({
                pathname: Label.Path.GoldBondsTab,
                state: { data: "PortfolioX-Ray", tabValue: 1 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Transactions") {
            this.props.history.push({
                pathname: Label.Path.GoldBondsTab,
                state: { data: "Transaction", tabValue: 2 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Booked G/L") {
            this.props.history.push({
                pathname: Label.Path.GoldBondsTab,
                state: { data: "Profit&Loss", tabValue: 3 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Taxation Report") {
            this.props.history.push({
                pathname: Label.Path.GoldBondsTab,
                state: { data: "CapitalGain", tabValue: 4 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Account Statement") {
            this.props.history.push({
                pathname: Label.Path.GoldBondsTab,
                state: { data: "AccountStatement", tabValue: 5 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Portfolio Details") {
            this.props.history.push({
                pathname: Label.Path.GoldBondsTab,
                state: { data: "Portfolioreport", tabValue: 6 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "X-Ray With Sold Units") {
            this.props.history.push({
                pathname: Label.Path.GoldBondsTab,
                state: { data: "XrayWithRedemption", tabValue: 7 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
    }
    handleEPFClick = (value) => {
        if (value == "Portfolio Details") {
            this.props.history.push({
                pathname: Label.Path.EPFTab,
                state: { data: "PortfolioDetails", tabValue: 0 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        } else {
            this.props.history.push({
                pathname: Label.Path.EPFTab,
                state: { data: "Transactions", tabValue: 1 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
    }
    handleBondsClick = async (value) => {
        if (value === "Summary") {
            this.props.history.push({
                pathname: Label.Path.BondsTab,
                state: { data: "Summary", tabValue: 0 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Portfolio X-Ray") {
            this.props.history.push({
                pathname: Label.Path.BondsTab,
                state: { data: "PortfolioX-Ray", tabValue: 1 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Transactions") {
            this.props.history.push({
                pathname: Label.Path.BondsTab,
                state: { data: "Transaction", tabValue: 2 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Booked G/L") {
            this.props.history.push({
                pathname: Label.Path.BondsTab,
                state: { data: "Profit&Loss", tabValue: 3 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Taxation Report") {
            this.props.history.push({
                pathname: Label.Path.BondsTab,
                state: { data: "CapitalGain", tabValue: 4 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Account Statement") {
            this.props.history.push({
                pathname: Label.Path.BondsTab,
                state: { data: "AccountStatement", tabValue: 5 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Portfolio Details") {
            this.props.history.push({
                pathname: Label.Path.BondsTab,
                state: { data: "Portfolioreport", tabValue: 6 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "X-Ray With Sold Units") {
            this.props.history.push({
                pathname: Label.Path.BondsTab,
                state: { data: "XrayWithRedemption", tabValue: 7 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Transfer In") {
            this.props.history.push({
                pathname: Label.Path.BondsTab,
                state: { data: "Transferin", tabValue: 8 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else if (value === "Maturity Tracker") {
            this.props.history.push({
                pathname: Label.Path.BondsTab,
                state: { data: "MaturityTracker", tabValue: 9 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }

    }
    componentDidUpdate() {
        const { index } = this.state
        var pathname = this.props.location.pathname.toString()
        let a = ''
        if (pathname == Label.Path.Portfolio) {
            a = 0
        }
        else if (pathname == Label.Path.MutualTab || pathname == Label.Path.LiquiloansTab || pathname == Label.Path.MLDTab || pathname == Label.Path.EPFTab || pathname === Label.Path.RealEstateTab || pathname === Label.Path.AIFTab || pathname === Label.Path.NPSTab || pathname === Label.Path.InsuranceTab || pathname == Label.Path.Equity || pathname == Label.Path.BondsTab || pathname == Label.Path.GoldBondsTab || pathname == Label.Path.FDTab) {
            a = 1
        } else if (pathname == Label.Path.MFTransaction) {
            a = 6
        } else if (pathname == Label.Path.TradingDetail) {
            a = 3
        } else if (pathname == Label.Path.Scheme) {
            a = 3
        } else if (pathname.search('/user/cart') != -1 || pathname == Label.Path.Basket) {
            a = 3
        } else if (pathname == Label.Path.Payment) {
            a = 3
        }
        else if (pathname == Label.Path.Transact) {
            a = 3
        }
        // var pathname = this.props.location.pathname
        // 
        if (index != a) {

            this.setState({ index: a })
        }
    }
    mutualfundsclose = () => {
        if (screen.width > 800) {
            this.setState({ mutualfundsOpen: false })
        }
    }
    reportclose = () => {
    }
    reportopen = () => {
        if (screen.width > 800) {
            this.setState({ reportOpen: true })
        }
    }
    bothreportclose = () => {
        if (screen.width > 800) {
            this.setState({ mutualfundsOpen: false, reportOpen: false, openMfTrading: false })
        }
    }
    handleTradeClick = (value) => {
        this.setState({ index: 6, reportOpen: false, mutualfundsOpen: false, openMfTrading: false })
        if (value === "MF Order Status") {
            // this.props.getTransactionOrderReport(this.props.logindetails.SessionID, this.props.logindetails.ClientID, moment().format("YYYY-MM-DD"))
            this.props.history.push({
                pathname: Label.Path.MFTransaction,
                state: { data: "MF Order Status", tabValue: 0 }
            })

            //    this.handleImage(1)
        }
        else if (value === "Upcoming SIPs") {
            // 
            this.props.history.push({
                pathname: Label.Path.MFTransaction,
                state: { data: "Upcoming SIPs", tabValue: 1 }
            })
            this.setState({ index: 6, reportOpen: false, mutualfundsOpen: false })
        }

        else if (value === "Mandate") {
            this.props.history.push({
                pathname: Label.Path.MFTransaction,
                state: {
                    data: "Mandate",
                    tabValue: 2
                }
            })
            this.setState({ index: 6, reportOpen: false, mutualfundsOpen: false })

        }
        else if (value === "Online SIPs") {
            this.props.history.push({
                pathname: Label.Path.MFTransaction,
                state: {
                    data: "Online SIPs",
                    tabValue: 3
                }
            })
            this.setState({ index: 6, reportOpen: false, mutualfundsOpen: false })
            //this.handleImage(1)
        }
        else if (value === "Online STPs") {
            this.props.history.push({
                pathname: Label.Path.MFTransaction,
                state: {
                    data: "Online STPs",
                    tabValue: 4
                }
            })
            this.setState({ index: 6, reportOpen: false, mutualfundsOpen: false })
            //this.handleImage(1)
        }

    }
    mutualfundClick = () => {
        this.setState({ mutualfundsOpen: true, flag: "mutualfunds" })
    }
    equityClick = () => {
        this.setState({ mutualfundsOpen: true, flag: labels.sidemenu.Equity })
    }
    FDClick = () => {
        this.setState({ mutualfundsOpen: true, flag: labels.sidemenu.FD })
    }
    aifClick = () => {
        this.setState({ mutualfundsOpen: true, flag: labels.sidemenu.AIF })

    }
    npsClick = () => {
        this.bothreportclose()
        this.props.history.push(labels.Path.NPSTab)

        //  this.setState({ mutualfundsOpen: false,this.reportopen, flag: labels.sidemenu.NPS })

    }
    epfClick = () => {
        this.setState({ mutualfundsOpen: true, flag: labels.sidemenu.epf })

    }
    structuredClick = () => {
        this.setState({ mutualfundsOpen: true, flag: labels.sidemenu.Structured })

    }
    insuranceClick = () => {
        this.setState({
            reportOpen: true,
            flag: labels.sidemenu.Insurance,
            mutualfundsOpen: true,
        })
        // this.props.history.push({
        //     pathname: labels.Path.InsuranceTab,
        // })

    }
    RealEstateClick = () => {
        this.setState({ mutualfundsOpen: true, flag: labels.sidemenu.RealEstate })

    }
    // GoldClick=()=>{
    //     this.setState({ mutualfundsOpen: true, flag: labels.sidemenu.RealEstate })

    // }
    bondsClick = () => {
        this.setState({ mutualfundsOpen: true, flag: labels.sidemenu.Bonds })
    }
    goldBondsClick = () => {
        this.setState({ mutualfundsOpen: true, flag: labels.sidemenu.Gold })

    }
    handleStructuredProductClick = (value) => {
        if (value === "MLD") {
            this.props.history.push({
                pathname: Label.Path.MLDTab,
                state: { data: "Summary", tabValue: 0 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }
        else {
            this.props.history.push({
                pathname: Label.Path.LiquiloansTab,
                state: { data: "Summary", tabValue: 0 }
            })
            this.setState({ index: 1, reportOpen: false, mutualfundsOpen: false })
        }

        this.setState({ reportOpen: false, mutualfundsOpen: false })
    }
    handleInsuranceClick = (value) => {
        if (value == "Health Insurance") {
            this.props.history.push({
                pathname: labels.Path.InsuranceTab,
                state: { tabValue: 0 }
            })

        } else if (value == "General Insurance") {
            this.props.history.push({
                pathname: labels.Path.InsuranceTab,
                state: { tabValue: 1 }
            })
        } else {
            this.props.history.push({
                pathname: labels.Path.InsuranceTab,
                state: { tabValue: 2 }
            })
        }
        this.setState({ reportOpen: false, mutualfundsOpen: false })
    }
    render() {
        const { classes } = this.props;
        return (
            <div tabIndex="0" ref={this.myDiv}>
                <Router history={this.props.history}>
                    <div>
                        <ErrorBountry>
                            {this.props?.logindetails?.ClientID == 0 && this.props.logindetails.EntityTypeID === 9 ?

                                <div className={[classes.navLeftFix].join('')}>
                                    <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>

                                        <li style={{ position: "relative" }} className={[classes.boxStyle, classes.portfolioContainer, classes.listStyle, classes.bottomRadius, this.state.index === 3 && classes.selectedList, classes.BuysellStyle].join(' ')} onClick={() => {
                                            if (this.props.logindetails.EntityTypeID != 9) {
                                                this.handleClick(3)
                                                this.bothreportclose()

                                            }
                                        }}
                                            onMouseEnter={() => {
                                                this.bothreportclose()
                                            }} onMouseLeave={() => {
                                                this.bothreportclose()
                                            }}
                                        >
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                {this.state.index != 3 ?
                                                    <span ></span>
                                                    : <img src={BuysellClick} style={{ width: 20, height: 26, marginBottom: 12 }} ></img>
                                                }                                                  <div>{Label.sidemenu.transactions}</div>
                                            </div>
                                        </li>
                                    </ul>
                                </div> : <div className={[classes.navLeftFix].join('')}>
                                    <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                                        <li className={[classes.boxStyle, classes.portfolioContainer, classes.listStyle, classes.topRadius, classes.PortfolioImage, this.state.index == 0 && classes.selectedList,].join(' ')}
                                            onClick={() => {
                                                this.handleClick(0)
                                                this.bothreportclose()
                                            }
                                            }
                                            onMouseEnter={() => {
                                                this.bothreportclose()
                                            }} onMouseLeave={() => {
                                                this.bothreportclose()
                                            }}
                                        >
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                                            >{this.state.index != 0 ?
                                                <span ></span>
                                                : <img src={PortfolioImageClick} style={{ width: 26, marginBottom: 12 }} ></img>
                                                }<div>{Label.sidemenu.portfolio}</div>
                                            </div>
                                        </li>

                                        <li
                                            onMouseLeave={() => {
                                                this.bothreportclose()
                                            }}
                                            className={[classes.boxStyle, classes.portfolioContainer, classes.listStyle, this.state.index == 1 || this.state.index == 2 ? classes.selectedList : '', classes.ReportStyle, !window.globalConfig.isTradeAllowed && classes.bottomRadius].join(' ')}>
                                            <div onClick={() => this.setState({ reportOpen: true })}
                                                onMouseEnter={() => {
                                                    this.reportopen()
                                                }}
                                            >
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                                                >
                                                    {this.state.index != 1 ?
                                                        <span ></span>
                                                        :
                                                        <img src={ReportsClick} style={{ width: 21, height: 26, marginBottom: 12 }} ></img>
                                                    }   <div>{Label.sidemenu.reports}</div>
                                                </div>

                                            </div>


                                            <div
                                                onMouseLeave={this.bothreportclose}
                                            >

                                                {this.state.reportOpen &&
                                                    <ClickAwayListener onClickAway={() => this.setState({ reportOpen: false })}>
                                                        <div className={classes.navDropBox}

                                                        >

                                                            <div className={classes.navDropBoxList} onClick={this.mutualfundClick}
                                                                onMouseEnter={() => {
                                                                    this.setState({ mutualfundsOpen: true, reportOpen: true, flag: "mutualfunds" })
                                                                }}

                                                            >
                                                                {Label.sidemenu.MutualFunds}
                                                            </div>
                                                            <div className={classes.navDropBoxList} onClick={this.equityClick}
                                                                onMouseEnter={() => {
                                                                    this.setState({ mutualfundsOpen: true, reportOpen: true, flag: Label.sidemenu.Equity })
                                                                }}
                                                            >
                                                                {Label.sidemenu.Equity}
                                                            </div>

                                                            <div className={classes.navDropBoxList} onClick={this.bondsClick}
                                                                onMouseEnter={() => {
                                                                    this.setState({ mutualfundsOpen: true, reportOpen: true, flag: Label.sidemenu.Bonds })
                                                                }}
                                                            >
                                                                {Label.productList.tradedbond}
                                                            </div>

                                                            <div className={classes.navDropBoxList} onClick={this.FDClick}
                                                                onMouseEnter={() => {
                                                                    this.setState({ mutualfundsOpen: true, reportOpen: true, flag: Label.sidemenu.FD })
                                                                }}
                                                            >
                                                                {Label.sidemenu.FD}
                                                            </div>

                                                            <div className={classes.navDropBoxList} onClick={this.aifClick}
                                                                onMouseEnter={() => {
                                                                    this.setState({ mutualfundsOpen: true, reportOpen: true, flag: Label.sidemenu.AIF })
                                                                }}
                                                            >
                                                                {Label.sidemenu.AIF}
                                                            </div>

                                                            <div className={classes.navDropBoxList} onClick={this.RealEstateClick}
                                                                onMouseEnter={() => {
                                                                    this.setState({ mutualfundsOpen: true, reportOpen: true, flag: Label.sidemenu.RealEstate })
                                                                }}
                                                            >
                                                                {Label.sidemenu.RealEstate}
                                                            </div>
                                                            <div className={classes.navDropBoxList} onClick={this.goldBondsClick}
                                                                onMouseEnter={() => {
                                                                    this.setState({ mutualfundsOpen: true, reportOpen: true, flag: Label.sidemenu.Gold })
                                                                }}
                                                            >
                                                                {Label.sidemenu.Gold}
                                                            </div>


                                                            <div className={classes.navDropBoxList} onClick={this.npsClick}
                                                                onMouseEnter={() => {
                                                                    this.setState({ mutualfundsOpen: false, reportOpen: true, flag: Label.sidemenu.NPS })
                                                                }}
                                                            >
                                                                {Label.sidemenu.NPS}
                                                            </div>
                                                            <div className={classes.navDropBoxList} onClick={this.insuranceClick}
                                                                onMouseEnter={() => {
                                                                    this.setState({ mutualfundsOpen: true, reportOpen: true, flag: Label.sidemenu.Insurance })
                                                                }}
                                                            >
                                                                {Label.sidemenu.Insurance}
                                                            </div>
                                                            <div className={classes.navDropBoxList} onClick={this.epfClick}
                                                                onMouseEnter={() => {
                                                                    this.setState({ mutualfundsOpen: true, reportOpen: true, flag: Label.sidemenu.epf })
                                                                }}
                                                            >
                                                                {Label.sidemenu.epf}
                                                            </div>
                                                            <div className={classes.navDropBoxList} onClick={this.structuredClick}
                                                                style={{ minHeight: 40, lineHeight: 1.3 }}
                                                                onMouseEnter={() => {
                                                                    this.setState({ mutualfundsOpen: true, reportOpen: true, flag: Label.sidemenu.Structured })
                                                                }}
                                                            >
                                                                {Label.sidemenu.Structured}
                                                            </div>
                                                            {/* {window.globalConfig.isTradeAllowed && <div className={classes.navDropBoxList}
                                                                onMouseEnter={() => {
                                                                    this.setState({ mutualfundsOpen: true, reportOpen: true, flag: "trading" })
                                                                }}
                                                                onClick={() => {

                                                                    this.setState({ mutualfundsOpen: true, flag: "trading" })
                                                                }}
                                                                onMouseLeave={() => {
                                                                    this.reportclose()
                                                                }}
                                                            >
                                                                {Label.sidemenu.Trading}
                                                            </div>
                                                            } */}
                                                        </div>
                                                    </ClickAwayListener>}
                                                {this.state.mutualfundsOpen &&
                                                    <ClickAwayListener onClickAway={this.ClickAwayListenerClose}>
                                                        <div className={[classes.navDropBoxtwo, this.state.flag === "mutualfunds" ? classes.mutualfundTopSpace : this.state.flag === labels.sidemenu.Structured ? classes.Structured : this.state.flag === Label.sidemenu.Equity ? classes.equityTopSpace : this.state.flag === labels.sidemenu.Bonds ? classes.bondsTopSpace : this.state.flag === labels.sidemenu.RealEstate ? classes.RealEstateTopSpace : this.state.flag === labels.sidemenu.Gold ? classes.GBTopSpace : this.state.flag === labels.sidemenu.FD ? classes.FDTopSpace : this.state.flag === labels.sidemenu.NPS ? classes.NPSTopSpace : this.state.flag === labels.sidemenu.Insurance ? classes.InsuranceTopSpace : this.state.flag === labels.sidemenu.AIF ? classes.AifTopSpace : classes.tradeTopSpace].join(' ')}
                                                            id="sideTabScroll"
                                                            onMouseLeave={this.bothreportclose}

                                                        >
                                                            {this.state.flag === "mutualfunds" ? labels.data.map((item, index) => {
                                                                return (
                                                                    <div key={index} className={[classes.navDropBoxList].join(' ')} onClick={() => {
                                                                        this.handleClick(item.tabTitle)
                                                                    }
                                                                    }>
                                                                        {item.tabTitle}
                                                                    </div>
                                                                )
                                                            }) : this.state.flag === Label.sidemenu.Equity ? labels.EquityTabdata.map((item, index) => {
                                                                return (
                                                                    <div key={index} className={[classes.navDropBoxList].join(' ')} onClick={() => {
                                                                        this.handleEquityClick(item.tabTitle)
                                                                    }
                                                                    }>
                                                                        {item.tabTitle}
                                                                    </div>
                                                                )
                                                            }) : this.state.flag === labels.sidemenu.Bonds ? labels.BondsTabdata.map((item, index) => {
                                                                return (
                                                                    <div key={index} className={[classes.navDropBoxList].join(' ')} onClick={() => {
                                                                        this.handleBondsClick(item.tabTitle)
                                                                    }
                                                                    }>
                                                                        {item.tabTitle}
                                                                    </div>
                                                                )
                                                            }) : this.state.flag === labels.sidemenu.epf ? labels.EpfTabData.map((item, index) => {
                                                                return (
                                                                    <div key={index} className={[classes.navDropBoxList].join(' ')} onClick={() => {
                                                                        this.handleEPFClick(item.tabTitle)
                                                                    }
                                                                    }>
                                                                        {item.tabTitle}
                                                                    </div>
                                                                )
                                                            }) : this.state.flag === labels.sidemenu.AIF ? labels.AIFTabdata.map((item, index) => {
                                                                return (
                                                                    <div key={index} className={[classes.navDropBoxList].join(' ')} onClick={() => {
                                                                        this.handleAIFClick(item.tabTitle)
                                                                    }
                                                                    }>
                                                                        {item.tabTitle}
                                                                    </div>
                                                                )
                                                            }) :
                                                                //  this.state.flag === labels.sidemenu.NPS ? labels.RealEstateTabdata.map((item, index) => {
                                                                //     return (
                                                                //         <div key={index} className={[classes.navDropBoxList].join(' ')} onClick={() => {
                                                                //             this.handleNPSClick(item.tabTitle)
                                                                //         }
                                                                //         }>
                                                                //             {item.tabTitle}
                                                                //         </div>
                                                                //     )
                                                                // })
                                                                //     :
                                                                this.state.flag === labels.sidemenu.Insurance ? labels.InsuranceTabData.map((item, index) => {
                                                                    return (
                                                                        <div key={index} className={[classes.navDropBoxList].join(' ')} onClick={() => {
                                                                            this.handleInsuranceClick(item.tabTitle)
                                                                        }
                                                                        }>
                                                                            {item.tabTitle}
                                                                        </div>
                                                                    )
                                                                }) : this.state.flag === labels.sidemenu.Structured ? labels.StructuredMenu.map((item, index) => {
                                                                    return (
                                                                        <div key={index} className={[classes.navDropBoxList].join(' ')} onClick={() => {
                                                                            this.handleStructuredProductClick(item.tabTitle)
                                                                        }
                                                                        }>
                                                                            {item.tabTitle}
                                                                        </div>
                                                                    )
                                                                })
                                                                    : this.state.flag === labels.sidemenu.FD ? labels.FixedDepositsTabdata.map((item, index) => {
                                                                        return (
                                                                            <div key={index} className={[classes.navDropBoxList].join(' ')} onClick={() => {
                                                                                this.handleFDClick(item.tabTitle)
                                                                            }
                                                                            }>
                                                                                {item.tabTitle}
                                                                            </div>
                                                                        )
                                                                    }) :
                                                                        this.state.flag === labels.sidemenu.Gold ? labels.GoldBondsTabdata.map((item, index) => {
                                                                            return (
                                                                                <div key={index} className={[classes.navDropBoxList].join(' ')} onClick={() => {
                                                                                    this.handleGBClick(item.tabTitle)
                                                                                }
                                                                                }>
                                                                                    {item.tabTitle}
                                                                                </div>
                                                                            )
                                                                        })
                                                                            :
                                                                            this.state.flag === labels.sidemenu.RealEstate ? labels.RealEstateTabdata.map((item, index) => {
                                                                                return (
                                                                                    <div key={index} className={[classes.navDropBoxList].join(' ')} onClick={() => {
                                                                                        this.handleRealEstateClick(item.tabTitle)
                                                                                    }
                                                                                    }>
                                                                                        {item.tabTitle}
                                                                                    </div>
                                                                                )
                                                                            }) : labels.TradingTabdata.map((item, index) => {
                                                                                return (

                                                                                    <div key={index} className={[classes.navDropBoxList].join(' ')} onClick={() => {
                                                                                        this.handleTradeClick(item.tabTitle)
                                                                                    }
                                                                                    }>
                                                                                        {item.tabTitle}
                                                                                    </div>
                                                                                )
                                                                            })
                                                            }

                                                        </div></ClickAwayListener>}
                                            </div>
                                        </li>
                                        {window.globalConfig.isTradeAllowed && <li style={{ position: "relative" }} className={[classes.boxStyle, classes.portfolioContainer, classes.listStyle, this.state.index === 3 && classes.selectedList, classes.BuysellStyle].join(' ')} onClick={() => {
                                            this.handleClick(3)
                                            this.bothreportclose()
                                        }}
                                            onMouseEnter={() => {
                                                // this.handleMouseenter(3)
                                                this.bothreportclose()
                                            }} onMouseLeave={() => {
                                                // this.handleMouseleave(3)
                                                this.bothreportclose()
                                            }}
                                        >
                                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                                            >
                                                {this.state.index != 3 ?
                                                    <span ></span>
                                                    : <img src={BuysellClick} style={{ width: 20, height: 26, marginBottom: 12 }} ></img>
                                                }                                                  <div>{Label.sidemenu.transactions}</div>
                                            </div>
                                        </li>}
                                        <li
                                            onMouseLeave={() => {
                                                this.bothreportclose()
                                            }}
                                            onClick={() => {
                                                this.handleClick(6)
                                            }}
                                            className={[classes.boxStyle, classes.portfolioContainer, classes.listStyle, this.state.index == 6 ? classes.selectedList : '', classes.ReportStyle, classes.bottomRadius].join(' ')}>
                                            <div onClick={() => this.mfTradingOpen()}
                                                onMouseEnter={() => {
                                                    this.mfTradingOpen()
                                                }}
                                            >
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                                                >
                                                    {this.state.index != 6 ?
                                                        <span ></span>
                                                        :
                                                        <img src={ReportsClick} style={{ width: 21, height: 26, marginBottom: 12 }} ></img>
                                                    }   <div>{Label.sidemenu.MFtrading}</div>
                                                </div>

                                            </div>


                                            <div
                                                onMouseLeave={this.bothreportclose}
                                            >

                                                {this.state.openMfTrading &&
                                                    <ClickAwayListener onClickAway={() => this.setState({ openMfTrading: false })}>
                                                        <div className={classes.navDropBox}
                                                            style={{ top: -70 }}
                                                        >
                                                            {labels.TradingTabdata.map((item, index) => {
                                                                return (

                                                                    <div key={index} className={[classes.navDropBoxList].join(' ')} onClick={() => {
                                                                        this.handleTradeClick(item.tabTitle)
                                                                    }
                                                                    }>
                                                                        {item.tabTitle}
                                                                    </div>
                                                                )
                                                            })}


                                                        </div>
                                                    </ClickAwayListener>}

                                            </div>
                                        </li>
                                    </ul>
                                </div>}
                        </ErrorBountry>
                    </div>
                    {this.props.logindetails.EntityTypeID === 9 && this.props.logindetails.ClientID == 0 ?
                        <Switch>
                            <Route path={Label.Path.Transact} render={(props) => (
                                <Transact childFunc={this.childFunc} {...props} />
                            )} />

                            <Route path={Label.Path.TradingDetail} component={TradingDetails} />
                            <Route path={Label.Path.Scheme} render={(props) => (<Schemes myRef={this.props.myRef} />)} exact />
                            <Redirect from='*' to={Label.Path.PageNotFound} />
                        </Switch>
                        :
                        <Switch>

                            < Route path={Label.Path.Portfolio} render={(props) => (
                                <Portfolio childFunc={this.props.childFunc} {...props} />
                            )} exact />
                            <Route path={Label.Path.MutualTab} render={(props) => (
                                <Layout childFunc={this.props.childFunc} {...props} isReachedBottom={this.props.isReachedBottom} />
                            )} />
                            {window.globalConfig.isBasketAllowed && <Route path={Label.Path.Basket} component={BasketScreen} />
                            }{window.globalConfig.isBasketAllowed && <Route path={Label.Path.BasketDetails} component={Basket} />}
                            <Route path={Label.Path.FDTab} render={(props) => (
                                <FD_Layout childFunc={this.props.childFunc} {...props} />
                            )} />

                            <Route path={Label.Path.AIFTab} render={(props) => (
                                <Aif_layout childFunc={this.props.childFunc} {...props} />
                            )} />
                            <Route path={Label.Path.RealEstateTab} render={(props) => (
                                <Real_Estate_layout childFunc={this.props.childFunc} {...props} />
                            )} />
                            <Route path={Label.Path.GoldBondsTab} render={(props) => (
                                <GB_Layout childFunc={this.props.childFunc} {...props} />
                            )} />
                            <Route path={Label.Path.NPSTab} render={(props) => (
                                <NPS_Layout childFunc={this.props.childFunc} {...props} />
                            )} />
                            <Route path={Label.Path.InsuranceTab} render={(props) => (
                                <Insurance_Layout childFunc={this.props.childFunc} {...props} />
                            )} />
                            <Route path={Label.Path.MLDTab} render={(props) => (
                                <Mld_layout childFunc={this.props.childFunc} {...props} />
                            )} />
                            <Route path={Label.Path.LiquiloansTab} render={(props) => (
                                <Liquiloans_layout childFunc={this.props.childFunc} {...props} />
                            )} />
                            <Route path={Label.Path.AIFTab} render={(props) => (
                                <Aif_layout childFunc={this.props.childFunc} {...props} />
                            )} />
                            <Route path={Label.Path.BondsTab} render={(props) => (
                                <Bonds_Layout childFunc={this.props.childFunc} {...props} />
                            )} />
                            <Route path={Label.Path.RealEstateTab} render={(props) => (
                                <Real_Estate_layout childFunc={this.props.childFunc} {...props} />
                            )} />
                            {/* <Route path={Label.Path.GoldTab} render={(props) => (
                    <Gold_Layout childFunc={this.props.childFunc} {...props} />
                  )} /> */}
                            {window.globalConfig.isTradeAllowed && <Route path={Label.Path.TradingDetail} component={TradingDetails} />}
                            {window.globalConfig.isTradeAllowed && <Route path={Label.Path.Cart} component={Cart} />}
                            <Route path={Label.Path.Equity} render={(props) => (
                                <EquityLayout childFunc={this.props.childFunc} {...props} />
                            )} />
                            <Route path={Label.Path.EPFTab} render={(props) => (
                                <Epf_layout childFunc={this.props.childFunc} {...props}></Epf_layout>
                            )} />
                            <Route path={Label.Path.Transact} render={(props) => (
                                <Transact childFunc={this.childFunc} {...props} />
                            )} />

                            {window.globalConfig.isTradeAllowed && <Route path={Label.Path.Payment} component={Payment} />}
                            {window.globalConfig.isTradeAllowed && <Route path={Label.Path.MFTransaction}
                                render={(props) => (
                                    <MFTransaction tradeFunc={this.props.tradeFunc} childFunc={this.props.childFunc} {...props} />
                                )}
                            />}
                            {window.globalConfig.isTradeAllowed &&
                                <Route path={Label.Path.Scheme} render={(props) => (<Schemes isReachedBottom={this.props.isReachedBottom} myRef={this.props.myRef} />)} />}
                            <Route path={Label.Path.TransactionOrder} component={TransactionOrder} />
                            <Redirect from='*' to={Label.Path.PageNotFound} />
                        </Switch>
                    }
                </Router>
            </div>
        );
    }
}
const mapDispatchToProps = dispatch => ({
    TradingDetails: (data) => dispatch(schemedata(data)),
    onChange: (date) => dispatch(dateChage(date)),
    getTransactionOrderReport: (ClientID, SessionID, AsOnDate) => dispatch(getTransactionOrderReport(ClientID, SessionID, AsOnDate)),
});
const mapStateToProps = state => ({
    sessionId: state.Login.sessionID,
    clientId: state.Login.logindetails.ClientID,
    date: state.Login.date,
    logindetails: state.Login.logindetails,

});
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SideMenu));