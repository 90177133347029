import React, { Component } from 'react';
import { render } from 'react-dom';
import { Switch, SwitchLabel, SwitchRadio, SwitchSelection } from './three_state_button_style';
import colors from '../../utils/colors/colors';
import InformationTooltip from '../tooltip/informationtooltip';
const ConcealedRadio = ({ value, selected }) =>
    <SwitchRadio type="radio" name="switch" defaultChecked={selected === value} />
const ClickableLabel = ({ selected, title, onChange, id, }) =>
    <SwitchLabel onClick={() => onChange(title)} style={{ backgroundColor: selected == title ? colors.WHITE : "transparent", borderRadius: 16, padding: "0px 6px", color: selected == title ? colors.BROWN : colors.BLACK_PRIMARY }} className={id}>
        {/* {titleCase(title)} */}
        {title?.length > 28 ?
            <InformationTooltip type="normal" title={title} length={28} /> : title}
        {/* {title} */}
    </SwitchLabel>;

// const titleCase = str =>
//     str.split(/\s+/).map(w => w[0].toUpperCase() + w.slice(1)).join(' ');
class Three_State_Toggle_Switch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: "",
            values: []
        }
    }

    componentDidMount() {
        if (this.props.values != undefined && this.props.values != "" && this.props.values != null) {
            this.setState({
                values: this.props.values
            })
        }

        if (this.props.selectedFlag != undefined && this.props.selectedFlag != "" && this.props.selectedFlag != null) {
            this.setState({
                selected: this.props.selectedFlag
            })
        }
    }

    componentDidUpdate() {
        if (this.props.selectedFlag != this.state.selected) {
            if (this.props.selectedFlag != undefined && this.props.selectedFlag != "" && this.props.selectedFlag != null) {
                this.setState({
                    selected: this.props.selectedFlag
                })
            }
        }
    }

    selectionStyle = () => {
        return {
            left: `${this.state.values.indexOf(this.state.selected) / 3 * 100}%`
        };
    };

    handleChange = (val) => {
        this.setState({ selected: val });
        this.props.handleChangeToggle(val);
    };

    render() {
        const { selected } = this.state;
        return (
            <Switch>
                {this.props.values != undefined && this.props.values.map((val, index) => {
                    return (
                        <div key={index}>
                            <ConcealedRadio value={val} selected={selected} />
                            <ClickableLabel selected={this.props.selectedFlag} title={val} onChange={this.handleChange} />
                        </div>
                    )
                })}
                {/* <SwitchSelection style={this.selectionStyle()} /> */}
            </Switch>
        );
    }
}
export default Three_State_Toggle_Switch;