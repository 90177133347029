import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors/colors';
const styles = theme => ({
  box: {
    border: `1px solid ${window.globalConfig.color.GREEN}`,
    color: window.globalConfig.color.GREEN,
    fontSize: "12px",
    padding: "4px 4px",
    // lineHeight: "100%",
    display: "inline-block",
    borderRadius: "3px",
    float: "left",
    // marginLeft: "5px",
    backgroundColor: window.globalConfig.color.WHITE,
    // fontWeight:"normal",
    height: "fit-content"
  },
  boxError: {
    border: `1px solid ${window.globalConfig.color.red}`,
    color: window.globalConfig.color.red,
    fontSize: "12px",
    padding: "3px 4px 3px 4px",
    lineHeight: "100%",
    display: "inline-block",
    borderRadius: "3px",
    float: "left",
    marginLeft: "5px",
    backgroundColor: window.globalConfig.color.WHITE,
    // fontWeight:"normal",
    height: "fit-content",
    [theme.breakpoints.down('380')]: {
      padding: "4px 4px 4px 4px"
    }

  },
  badges: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    /* background-color: #e7f6ed; */
    padding: "1px 6px 0px",
    /* background-color: #e7f6ed; */
    fontSize: "13px",
    display: "inline-block",
    textAlign: "center",
    lineHeight: "1.33",
    /* float: right; */
    borderRadius: "7px"
  }
})
class Badges extends Component {
  constructor() {
    super();
    this.state = {

    }
  }
  render() {
    const { classes } = this.props;
    if (this.props.page === "primary") {
      return (
        <div className={this.props.verified ? classes.box : classes.boxError} {...this.props}>{this.props.text}</div>
      )
    }
    else if (this.props.page === "myplan") {
      return (
        <div className={classes.badges} style={{ backgroundColor: this.props.color, color: this.props.textcolor, padding: "10px 20px", borderRadius: "25px" }}>{this.props.text}/{this.props.value}</div>
      )
    }
    else {
      return (
        <div className={classes.badges} style={{ backgroundColor: this.props.color, padding: "3px 6px 3px", fontSize: 11, color: this.props.textcolor, width: "fit-content" }}>{this.props.text}</div>
      )
    }
  }

}
export default withStyles(styles)(Badges)