import React from "react";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import labels from "../../utils/constants/labels";
import { Redirect } from "react-router";
import NoData from "../nodata/nodata";
import color from "../../utils/colors/colors";
import ErrorBoundary from "../Errorhandling/Errorhandle";
import Badge from "../badge/badge";
import Grid from "@material-ui/core/Grid";
import SyncIcon from "@material-ui/icons/Sync";
import { getProfileDetails } from "../../redux/actions/profile/profile";
import Skeleton from "@material-ui/lab/Skeleton";
import Loader from '../loader/loader';
import GetMyPlanDetailsapi from "../../api/profile/myplanapi";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom"; //import Select from '@material-ui/core/Select';
import { GetMyPlanDetails } from '../../redux/actions/profile/profile'
import colors from "../../utils/colors/colors";
import Button from "../custombutton/button";
import { getFamily } from "../../redux/actions/login/loginaction";
import { getEquityUccProfileDetails } from "../../redux/actions/profile/equityucc_profile";

const styles = (theme) => ({
  colheader1: {
    color: window.globalConfig.color.LABLE_GRAY,
    //color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: "14px",
    textAlign: "left",
    fontWeight: "normal",
    // flex: 8,
  },
  colheader2: {
    [theme.breakpoints.down("xl")]: {
      color: window.globalConfig.color.LABLE_GRAY,
      fontSize: "14px",
      textAlign: "left",
      width: 140,
    },
  },
  colheader3: {
    [theme.breakpoints.down("xl")]: {
      color: window.globalConfig.color.PRIMARY_BORDER,
      fontSize: "14px",
      textAlign: "left",
      width: 140,
      paddingLeft: "2%",
    },
  },
  container: {
    // [theme.breakpoints.down("xl")]: {
    minHeight: 270,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${colors.WHITE}`,
    boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
    borderRadius: 6,
    // },

  },
  primaryInvestorCard: {
    [theme.breakpoints.down("xl")]: {
      borderTopRightRadius: 6,
      borderTopLeftRadius: 6,
      backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
      // height: 82,
      // width: 390,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
      [theme.breakpoints.down("lg")]: {
        // height: 45,
        paddingTop: 10,
        paddingBottom: 10,
      },
      [theme.breakpoints.between("601", "999")]: {
        width: "100%",
      },
    },
  },
  primaryInvestorHeader: {
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("xl")]: {
      textAlign: "left",
      marginLeft: "7%",

      // marginTop: "5%"
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "5%",
    },
    [theme.breakpoints.between("601", "999")]: {
      marginLeft: "5%",
    },
  },
  profileUserNameCard: {
    [theme.breakpoints.down("xl")]: {
      textAlign: "left",
      marginLeft: "7%",
      marginTop: "1%",
      color: window.globalConfig.color.PRIMARY_BLUE,
      fontWeight: "bold",
      // display: "flex",
      // flexDirection: "row",
      // marginBottom: "5%"
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "5%",
    },
  },
  profileUserName: {
    [theme.breakpoints.down("xl")]: {
      fontSize: 17,
      marginRight: 10,
      color: window.globalConfig.color.InkBlack,
    },
    [theme.breakpoints.between("601", "999")]: {
      marginLeft: "-3.5%",
    },
  },

  portfolio: {
    [theme.breakpoints.down("xl")]: {
      textAlign: "left",
      marginLeft: "10.5%",
      marginTop: "1%",
      color: window.globalConfig.color.PRIMARY_BLUE,
    },
  },
  profileDetailesCard: {
    [theme.breakpoints.down("xl")]: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "7%",
      paddingTop: "5%",
      paddingBottom: "5%",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "5%",
    },
    [theme.breakpoints.between("601", "999")]: {
      paddingLeft: "5%",
    },
  },

  profileDetailesColumn: {
    [theme.breakpoints.down("xl")]: {
      display: "flex",
      flexDirection: "row",
    },
    [theme.breakpoints.between("601", "999")]: {
      // display: "flex",
      // flexDirection: "row",
      // display:'grid',
    },
  },
  profileDetailesColumns: {
    [theme.breakpoints.down("xl")]: {
      display: "flex",
      flexDirection: "row",
      marginTop: "3.5%",
    },
    [theme.breakpoints.down("xs")]: {
      wordBreak: "break-word",
    },
  },
  profileDetailesColumnsPan: {
    [theme.breakpoints.down("xl")]: {
      display: "flex",
      flexDirection: "row",
      marginTop: "4%",
    },
  },

  familyLinageTitile: {
    [theme.breakpoints.down("xl")]: {
      fontSize: 17,
      marginRight: 10,
      fontWeight: "bold",
    },
  },
  familyLinageName: {
    fontSize: 14,
    marginLeft: 0,
    paddingRight: 5,
    textAlign: "left",
    [theme.breakpoints.between("300", "400")]: {
      fontSize: 14,
      textAlign: "left",
      width: 300,
    },
  },
  familyLinagePan: {
    [theme.breakpoints.down("xl")]: {
      color: window.globalConfig.color.LABLE_GRAY,
      fontSize: "14px",
      textAlign: "left",
      width: 140,
      marginBottom: "3%",
    },
  },
  familyLinageCard: {
    color: window.globalConfig.color.PRIMARY_BLUE,
    marginBottom: 50,
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      flexDirection: "column",
      paddingLeft: "7%",
      marginTop: "5%",
      //height: "40%",
      paddingTop: "3.5%",
      border: `1px solid ${colors.WHITE}`,
      boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
      borderRadius: 6,
      paddingBottom: "3.5%",
    },
    [theme.breakpoints.down("xs")]: {
      paddingLeft: "5%",
      display: "flex",
      flexDirection: "column",
      marginTop: "5%",
      paddingTop: "5%",
      border: `1px solid ${colors.WHITE}`,
      boxShadow: "0 0 5px 8px rgba(200, 200, 250, 0.1)",
      borderRadius: 6,
      // margin: "3%",
    },
    [theme.breakpoints.between("601", "999")]: {
      paddingLeft: "5%",
      display: "flex",
      flexDirection: "column",
      marginTop: "5%",
      paddingTop: "5%",
      border: `1px solid ${colors.WHITE}`,
      boxShadow: "0 0 5px 8px rgba(200, 200, 250, 0.1)",
      borderRadius: 6,
      // margin: "3%",
      width: "95%",
    },
  },
  panNumber: {
    color: window.globalConfig.color.PRIMARY_BLUE,
  },
  cardSpareaterLine: {
    height: 0.5,
    width: "90%",
    backgroundColor: window.globalConfig.color.AliceBlue,
    marginTop: "2%",
  },
  marginCard: {
    margin: "0% 3%",
    [theme.breakpoints.down("xs")]: {
      margin: "0% 3%",
    },
  },

  //Added by Celsia on 5 Jun 2021 for Mobile Responsive Started
  primaryInvestorCardResponsive: {
    backgroundColor: window.globalConfig.color.AliceBlueLight,
    height: 72,
    width: "100%",
    borderTopRightRadius: 6,
    borderTopLeftRadius: 6,
  },
  primaryInvestorHeaderResponsive: {
    textAlign: "left",
    marginLeft: "4.5%",
    marginTop: "3%",
  },

  profileUserNameCardResponsive: {
    textAlign: "left",
    marginLeft: "4.5%",
    marginTop: "1%",
    color: window.globalConfig.color.PRIMARY_BLUE,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
  },
  profileUserNameResponsive: {
    fontSize: 14,
    marginRight: 10,
  },
  profileDetailesCardResponsive: {
    display: "flex",
    flexDirection: "column",
    padding: "5%",
  },

  colheader2Responsive: {
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: "13px",
    textAlign: "left",
    width: 140,
  },
  valueResponsive: {
    fontSize: "14px",
    width: "17vw",
    textAlign: "left",
    inlineSize: "220px",
    overflowWrap: 'break-word',
    [theme.breakpoints.down("600")]: {
      width: "52vw",
    },
    [theme.breakpoints.between("601", "999")]: {
      width: "60vw",
    },
  },
  containerResponsive: {
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.3)",
    borderRadius: 5,
  },
  noData: {
    marginTop: "7%",
    marginBottom: "10%",
    marginRight: "66px",
    marginLeft: "-2px",
    [theme.breakpoints.down("600")]: {
      width: "100%",
    },
  },
  account: {
    justifyContent: "center",
    // display : "contents",
    marginTop: "-4%",
    maxWidth: "40.333333% !important",
    flexBasis: "35.333333% !important",
    [theme.breakpoints.down('380')]: {
      marginLeft: "0%",
      marginTop: "2%",
      maxWidth: "50.333333% !important",
      flexBasis: "50.333333% !important",
    },
    [theme.breakpoints.up('1024')]: {
      flexBasis: "40.333333% !important",
      maxWidth: "40.333333% !important",
      marginLeft: "72%",
    },
    [theme.breakpoints.up('1440')]: {
      flexBasis: "40.333333% !important",
      maxWidth: "40.333333% !important",
      marginLeft: "70%"
    }
  },
});
class UserCard extends React.Component {
  isComponentMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  componentDidMount() {
    document.title = window.globalConfig.companyDetails.companyName + " - MyAccounts"
    this.isComponentMounted = true;
  }
  componentWillUnmount() {
    this.isComponentMounted = false;
  }
  syncClick = () => {
    this.props.getProfileDetails(this.props.login.ClientID, this.props.login.EmailID);
    this.props.GetMyPlanDetails(this.props.login.ClientID)
    this.props.getFamily(this.props.login.ClientID)
    this.props.getEquityUccProfileDetails(this.props.login.ClientID)
  };
  componentDidUpdate(prevProps) { }
  render() {
    const { classes } = this.props;
    return (
      <ErrorBoundary>
        <div>
          {this.props.isLoading ? (
            <div>
              <Skeleton
                className={classes.container}
                style={{ marginTop: -64 }}
              />
            </div>
          ) : (
            <div className={classes.container} style={{ justifyContent: this.props.userDetails.length == 0 ? "center" : "" }}>
              {this.props.userDetails.length != 0 ? (
                // this.props.userDetails.map((item, index) => {
                //     return (
                <div>
                  <div className={classes.primaryInvestorCard}>
                    <div className={classes.primaryInvestorHeader}>
                      <span className={classes.colheader1}>
                        {labels.userCard.PrimaryInvestor}
                      </span>
                      <div style={{ marginRight: "5%" }}>
                        <Badge

                          page={"primary"}
                          verified={this.props.userDetails[0].KYCValid}
                          text={
                            this.props.userDetails[0].KYCValid
                              ? labels.userCard.KYCVerified
                              : labels.userCard.KYCNotVerified
                          }
                        />
                      </div>
                    </div>
                    <div className={classes.profileUserNameCard}>
                      {/* changed by dulcy */}
                      <Grid container item xs={12}>
                        <Grid item xs={8}>
                          <span className={classes.profileUserName}>
                            {this.props.userDetails[0].ClientName}
                          </span>
                        </Grid>
                        <Grid item xs={4}>
                          {this.props.page === "Profile" ?
                            <div style={{ flex: 1, marginLeft: "65%" }}>

                              <div
                                onClick={this.syncClick}
                                style={{ cursor: "pointer" }}
                              > <Tooltip
                                TransitionComponent={Zoom}
                                title={labels.userCard.syncToolTip}
                              >
                                  <SyncIcon style={{ color: colors.LABLE_GRAY }} />
                                </Tooltip>
                              </div>
                            </div> : ""}
                          {/* </div> */}
                        </Grid>
                      </Grid>
                    </div>

                  </div>
                  <div className={classes.profileDetailesCard}>
                    <div className={classes.profileDetailesColumn}>
                      <span className={classes.colheader2}>
                        {window.globalConfig.enableAnandrathi ? "PAN" : labels.userCard.PANasperKYC}
                      </span>
                      <span className={classes.valueResponsive}>
                        {this.props.userDetails[0].PAN}
                      </span>
                    </div>
                    <div className={classes.profileDetailesColumns}>
                      <span className={classes.colheader2}>
                        {labels.userCard.DateofBirth}
                      </span>
                      <span className={classes.valueResponsive}>
                        {this.props.userDetails[0].DOB == "NA" || this.props.userDetails[0].DOB == "" ? "" : moment(this.props.userDetails[0].DOB).format(
                          "DD MMM YYYY"
                        )}
                      </span>
                    </div>
                    <div className={classes.profileDetailesColumns}>
                      <span className={classes.colheader2}>
                        {labels.userCard.MobileNumber}
                      </span>
                      <span className={classes.valueResponsive}>
                        {this.props.userDetails[0].MobileNo}
                      </span>
                    </div>
                    <div className={classes.profileDetailesColumns}>
                      <span className={classes.colheader2}>
                        {labels.userCard.EmailLoginid}
                      </span>
                      <span className={classes.valueResponsive}>
                        {this.props.userDetails[0].EmailID}
                      </span>
                    </div>
                    <div className={classes.profileDetailesColumns}>
                      <span className={classes.colheader2}>
                        {labels.userCard.Address}
                      </span>
                      <span className={classes.valueResponsive}>
                        {this.props.userDetails[0].Address}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                //     )
                // })
                <div style={{ display: "flex", flexDirection: "column", gap: 10, alignItems: "center" }}>
                  <span style={{ fontSize: "medium", color: colors.LABLE_GRAY }}>{labels.userCard.syncToolTip}</span>
                  <div style={{ width: 200 }}>
                    <Button name="Refresh" page="primary" onClick={this.syncClick}></Button>
                  </div>
                  {/* <NoData /> */}
                </div>
              )}
            </div>
          )}
          {this.props.isLoading ? (
            <div>
              <Skeleton
                className={classes.container}
                style={{ marginTop: -64 }}
              />
            </div>
          ) : this.props.guardianDetails.length != 0 ? (
            <div className={classes.familyLinageCard}>
              <div className={classes.profileDetailesColumns}>
                <span className={classes.familyLinageTitile}>
                  {"Guardian"}
                </span>
              </div>
              {this.props.guardianDetails.map((item, index) => {
                return <>
                  <div className={classes.profileDetailesColumns}>
                    <span className={classes.familyLinageName}>
                      {item.GuardianName}
                    </span>
                  </div>
                  <div className={classes.profileDetailesColumnsPan}>
                    <span className={classes.familyLinagePan}>
                      {labels.userCard.PAN}:{" "}
                      <span className={classes.panNumber}>
                        {item.GuardianPAN}
                      </span>
                    </span>
                  </div>
                  {index != this.props.guardianDetails.length - 1 && (
                    <div className={classes.cardSpareaterLine}></div>
                  )}
                </>

              })}
            </div>) : <></>}
          {this.props.isLoading ? (
            <div>
              <Skeleton
                className={classes.container}
                style={{ marginTop: -64 }}
              />
            </div>
          ) : (
            <div className={classes.familyLinageCard}>
              <div className={classes.profileDetailesColumns}>
                <span className={classes.familyLinageTitile}>
                  {labels.userCard.FamilyLinkage}
                </span>
              </div>
              {/* {console.log(this.props.familyDetails)} */}
              {this.props.familyDetails.length == 0 || this.props.familyDetails.filter((val) => val.PAN != this.props.familyDetails.PAN).length == 0 ? (
                <>

                  <div className={classes.noData}>
                    <NoData />
                  </div>

                </>
              ) : (

                this.props.familyDetails.filter((val) => val.PAN != this.props.familyDetails.PAN).map((familydetail, index) => {
                  return (
                    <>

                      <div className={classes.profileDetailesColumns}>
                        <span className={classes.familyLinageName}>
                          {familydetail.ClientName}
                        </span>
                        {/* <span className={classes.colheader2}> (N.A) </span> */}
                        <span className={classes.colheader2}></span>
                      </div>
                      <div className={classes.profileDetailesColumnsPan}>
                        <span className={classes.familyLinagePan}>
                          {labels.userCard.PAN}:{" "}
                          <span className={classes.panNumber}>
                            {familydetail.PAN}
                          </span>
                        </span>
                      </div>
                      {index != this.props.familyDetails.length - 1 && (
                        <div className={classes.cardSpareaterLine}></div>
                      )}
                    </>
                  );
                })
              )}
            </div>
          )}
        </div>
      </ErrorBoundary>
    );
    // }
  }
}
const mapStateToProps = (state) => ({
  login: state.Login.logindetails,
  userDetails:
    state.ProfileDetails.AllProfileDetails.length != 0
      ? state.ProfileDetails.AllProfileDetails.mainDetails != "NA"
        ? state.ProfileDetails.AllProfileDetails.mainDetails
        : []
      : [],
  guardianDetails:
    state.ProfileDetails.AllProfileDetails.length != 0
      ? state.ProfileDetails.AllProfileDetails.guardianDetails != undefined && state.ProfileDetails.AllProfileDetails.guardianDetails != "NA"
        ? state.ProfileDetails.AllProfileDetails.guardianDetails
        : []
      : [],
  familyDetails:
    state.Login.familydetails.length != 0
      ? state.Login.familydetails != "NA"
        ? state.Login.familydetails
        : []
      : [],

  isLoading: state.ProfileDetails.isLoading,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ getProfileDetails, GetMyPlanDetails, getFamily, getEquityUccProfileDetails }, dispatch);
};
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(UserCard)
);
