import * as types from '../../constants/actionsType';
// import getAIFandPMSList from '../../../api/aifandpms/aifandpms_list'
export const npsListing = (data, ) => {
           return {
            type: types.NPS_LIST,
            payload: data
        }
     
    
}
export const deleteNpsListing = (data, ) => {
        return {
            type: types.DELETE_NPS_LIST,
            payload: data
        }
}
export const addUpdateDeleteTransaction = (data, flag) => {
  
        return {
            type: types.NPS_ADD_UPDATE_DELETE_TRANSACTION_LIST,
            data, flag
        }
  

}
export const updateAsset = (data, flag) => {
   
        return {
            type: types.UPDATE_NPS_LIST,
            payload: data
        }


}
export function getNPSDetails(data) {
    return (dispatch) => {
        getNPSAction(dispatch, data);
    };
}
function getNPSAction(dispatch,data) {
    
    //mf loading
   
        dispatch({
            type: types.EMPTY_NPS_LIST,
            payload: [],
        });
        dispatch({
            type: types.LOADER_NPS_LIST,
            payload: true,
        });
  

    getAIFandPMSList(data)
        .then((data) => {
            // 
         
                dispatch({
                    type: types.EMPTY_NPS_LIST,
                    payload: [],
                });
                dispatch({
                    type:types.LOADER_NPS_LIST,
                    payload:false
                })

            
            if (data.status == "S") {
                // mf report

              
                    dispatch({
                        type: types.NPS_LIST,
                        payload: data.table1,
                    });

              
               
            } else {
               
                    dispatch({
                        type: types.EMPTY_NPS_LIST,
                        payload: [],
                    });
                    dispatch({
                        type:types.LOADER_NPS_LIST,
                        payload:false
                    })
              
            }
        })
        .catch((error) => {
            // Mf empty

         
                dispatch({
                    type: types.EMPTY_NPS_LIST,
                    payload: [],
                });
                dispatch({
                    type:types.LOADER_NPS_LIST,
                    payload:false
                })
            
        })
}