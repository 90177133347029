import ApiValues from '../url/apiurl'
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
var axios = require('axios');
function getAllMaturityTracker(ClientID,AccountID,AsOnDate,ProductID,FamilyID,L5ID,L4ID) {
    //let BaseUrl = "https://ms.zoifintech.com/Dashboard/GetProfitLossDetails"
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
     "ClientID":ClientID,
     "AccountID":AccountID,
     "AsOnDate":AsOnDate,
     "ProductID": ProductID,
     "FamilyID": FamilyID,
     "L5ID": L5ID,
     "L4ID": L4ID
    });
    // var requestOptions = {
    //     method: 'POST',
    //     headers: myHeaders,
    //     body: raw,
    //     redirect: 'follow'
    // };
    // return fetch(ApiValues.AssetAllocation, requestOptions)
    //     .then(response => response.json())
    //     .then(result => { return result })
    //     .catch(error =>{
            
    //         if(error.message=="Failed to fetch"){
    //             var data={};
    //             data.status="N"
    //             data.message=error.message
    //             return data
    //         }else{
    //             return { "status": "F", "message": labels.messages.apierror }

    //         }
    //     });
    return Networking.PostApi(ApiValues.MaturityTracker,raw).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default getAllMaturityTracker
