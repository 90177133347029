import * as types from '../../constants/actionsType'
import { bindActionCreators } from 'redux';
import { FormatColorReset } from '@material-ui/icons';
import { replaceNull } from '../../../utils/commonfunction';
import labels from '../../../utils/constants/labels';
const initialState = {
    dashboardAssetAllocation: [],
    isDashboardAssetAllocationLoading: true,

}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.DASHBOARD_ASSET:
            return {
                ...state,
                dashboardAssetAllocation: action.payload,
                isDashboardAssetAllocationLoading: false
            }

        case types.EMPTY_DASHBOARD_ASSET:
            // console.log("empty")
            return {
                dashboardAssetAllocation: [],
                isDashboardAssetAllocationLoading: false
            }
        case types.LOADER_DASHBOARD_ASSET:
            // console.log("loader")
            return {
                ...state,
                isDashboardAssetAllocationLoading: action.payload,
                // isNetworkLow: false
            }

        default:
            return state
    }
}
export default reducer
