import ApiValues from '../url/apiurl'
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
var axios = require('axios');

function getAllTransferIn( ClientID, InceptionDate,  SessionID,  ProductID, L5ID, L4ID) {
  //let BaseUrl = "https://ms.zoifintech.com/Dashboard/GetProfitLossDetails"
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  let data = {};
  data.Account = 0
  data.ClientID = ClientID
  data.InceptionDate = InceptionDate
  data.FromXIRR = 0
  data.ToXIRR = 0
  data.Type = 0
  data.AssetClass = 0
  data.AccountLevel = 0
  data.SessionID = SessionID
  data.SchemeID = 0
  data.ProductID = ProductID
  data.FamilyID = 0
  data.L5ID = L5ID
  data.L4ID = L4ID
  // var requestOptions = {
  //     method: 'POST',
  //     headers: myHeaders,
  //     body: raw,
  //     redirect: 'follow'
  // };
  // return fetch(ApiValues.AssetAllocation, requestOptions)
  //     .then(response => response.json())
  //     .then(result => { return result })
  //     .catch(error =>{
  //         if(error.message=="Failed to fetch"){
  //             var data={};
  //             data.status="N"
  //             data.message=error.message
  //             return data
  //         }else{
  //             return { "status": "F", "message": labels.messages.apierror }
  //         }
  //     });
  return Networking.PostApi(ApiValues.TransferIn, data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
}

export default getAllTransferIn
