// import filter from '../../../components/schemeFilter/filter'
import { NegativeFinding } from "../../../utils/commonfunction";
import * as types from "../../constants/actionsType";
const initialState = {
  profitLoss: [],
  profitLossfilter: [],
  isLoadingProfitLoss: true,
  grandpurchasetotal: "",
  grandselltotal: "",
  granddivdend: "",
  grandglamount: "",
  isProfitLossLoading: false,

  //EQUITY
  equity_profitLoss: [],
  equity_profitLossfilter: [],
  equity_isLoadingProfitLoss: true,
  equity_grandpurchasetotal: "",
  equity_grandselltotal: "",
  equity_granddivdend: "",
  equity_grandglamount: "",
  equity_isProfitLossLoading: false,

  //Bonds
  bonds_profitLoss: [],
  bonds_profitLossfilter: [],
  bonds_isLoadingProfitLoss: false,
  bonds_grandpurchasetotal: "",
  bonds_grandselltotal: "",
  bonds_granddivdend: "",
  bonds_grandglamount: "",
  bonds_isProfitLossLoading: false,

  //Gold Bonds
  gb_profitLoss: [],
  gb_profitLossfilter: [],
  gb_isLoadingProfitLoss: false,
  gb_grandpurchasetotal: "",
  gb_grandselltotal: "",
  gb_granddivdend: "",
  gb_grandglamount: "",
  gb_isProfitLossLoading: false,

  //FD
  fd_profitLoss: [],
  fd_profitLossfilter: [],
  fd_isLoadingProfitLoss: false,
  fd_grandpurchasetotal: "",
  fd_grandselltotal: "",
  fd_granddivdend: "",
  fd_grandglamount: "",
  fd_isProfitLossLoading: false,

  //AIF
  aif_profitLoss: [],
  aif_profitLossfilter: [],
  aif_isLoadingProfitLoss: false,
  aif_grandpurchasetotal: "",
  aif_grandselltotal: "",
  aif_granddivdend: "",
  aif_grandglamount: "",
  aif_isProfitLossLoading: false,

  //REAL ESTATE
  realEstate_profitLoss: [],
  realEstate_profitLossfilter: [],
  realEstate_isLoadingProfitLoss: false,
  realEstate_grandpurchasetotal: "",
  realEstate_grandselltotal: "",
  realEstate_granddivdend: "",
  realEstate_grandglamount: "",
  realEstate_isProfitLossLoading: false,

  //NPS
  nps_profitLoss: [],
  nps_profitLossfilter: [],
  nps_isLoadingProfitLoss: false,
  nps_grandpurchasetotal: "",
  nps_grandselltotal: "",
  nps_granddivdend: "",
  nps_grandglamount: "",
  nps_isProfitLossLoading: false,
  //INSURANCE
  insurance_profitLoss: [],
  insurance_profitLossfilter: [],
  insurance_isLoadingProfitLoss: false,
  insurnace_grandpurchasetotal: "",
  insurance_grandselltotal: "",
  insurance_granddivdend: "",
  insurance_grandglamount: "",
  insurance_isProfitLossLoading: false,
  insurance_grandpurchasetotal: ""
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PROFITLOSS_DATA:
      return {
        ...state,
        profitLoss:
          action.payload == null ||
            action.payload == undefined ||
            action.payload.length == 0
            ? state.profitLoss
            : action.payload.map(({ LongTermAmount, ShortTermAmount, ...item }) => {
              return { ...item, "schemeName": item.Scheme }
            }),
        profitLossfilter:
          action.payload == null ||
            action.payload == undefined ||
            action.payload.length == 0
            ? state.profitLossfilter
            : action.payload.map(({ LongTermAmount, ShortTermAmount, ...item }) => {
              return { ...item, "schemeName": item.Scheme }
            }),
        isLoadingProfitLoss: false,
        GainPercentage:
          action.payload == null || action.payload.length == 0
            ? state.GainPercentage
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalPercentage === ele.TotalPercentage
                  )
              )
              .map((val) => {
                return Number(val.TotalPercentage);
              }),
        grandpurchasetotal:
          action.payload == null || action.payload.length == 0
            ? state.grandpurchasetotal
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalPurchase === ele.TotalPurchase
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalPurchase, "", "", "", "number");
              }),
        grandselltotal:
          action.payload == null || action.payload.length == 0
            ? state.grandselltotal
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalSell === ele.TotalSell
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalSell, "", "", "", "number");
              }),
        granddivdend:
          action.payload == null || action.payload.length == 0
            ? state.granddivdend
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalDividend === ele.TotalDividend
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalDividend, "", "", "", "number");
              }),
        grandglamount:
          action.payload == null || action.payload.length == 0
            ? state.grandglamount
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalGainLoss === ele.TotalGainLoss
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalGainLoss, "", "", "", "number");
              }),
        isProfitLossLoading: false,
      };
    case types.GET_PROFIT_LOSS:
      return {
        ...state,
        profitLoss:
          action.data == null ||
            action.data == undefined ||
            action.data.length == 0
            ? state.profitLossfilter
            : action.data,
        isLoadingProfitLoss: false,
        isProfitLossLoading: false,
      };
    case types.PROFITLOSS_DATA:
      return {
        ...state,
        profitLoss: [],
        profitLossfilter: [],
        isLoadingProfitLoss: false,
        isProfitLossLoading: false,
      };
    case types.FILTER_EQUITY_PROFIT_PROFIT:
      var equity_filterResult = [];
      var equity_filterdata = state.equity_profitLossfilter;

      //    (filterdata,"action.payload[0]")
      // if (action.payload.label != undefined) {
      //   filterResult = filterdata.filter(data => data.Scheme.toUpperCase().includes(action.payload.label.toUpperCase()))
      // }

      if (action.payload.Scheme != "" && action.payload.Scheme != null) {
        var equity_schemeName = action.payload.Scheme.map((item) => {
          return item.label;
        });
        //
        if (equity_schemeName != null) {
          //
          equity_filterResult = equity_filterdata.filter((i) => {
            return equity_schemeName.includes(i.Scheme);
          });
        } else {
          //
          equity_filterResult = equity_filterdata;
        }
      } else {
        equity_filterResult = equity_filterdata;
      }
      state.equity_profitLoss = equity_filterResult;
      return {
        ...state,

        equity_isLoadingProfitLoss: false,
      };
    case types.FILTER_BONDS_PROFIT_PROFIT:
      var bonds_filterResult = [];
      var bonds_filterdata = state.bonds_profitLossfilter;
      if (action.payload.Scheme != "" && action.payload.Scheme != null) {
        var bonds_schemeName = action.payload.Scheme.map((item) => {
          return item.label;
        });
        //
        if (bonds_schemeName != null) {
          //
          bonds_filterResult = bonds_filterdata.filter((i) => {
            return bonds_schemeName.includes(i.Scheme);
          });
        } else {
          //
          bonds_filterResult = bonds_filterdata;
        }
      } else {
        bonds_filterResult = bonds_filterdata;
      }
      state.bonds_profitLoss = bonds_filterResult;
      return {
        ...state,

        bonds_isLoadingProfitLoss: false,
      };
    case types.FILTER_FD_PROFIT_PROFIT:
      var fd_filterResult = [];
      var fd_filterdata = state.fd_profitLossfilter;
      if (action.payload != "" && action.payload != null) {
        var fd_schemeName = action.payload.map((item) => {
          return item.label;
        });
        //
        if (fd_schemeName != null) {
          //
          fd_filterResult = fd_filterdata.filter((i) => {
            return fd_schemeName.includes(i.Scheme);
          });
        } else {
          //
          fd_filterResult = fd_filterdata;
        }
      } else {
        fd_filterResult = fd_filterdata;
      }
      state.fd_profitLoss = fd_filterResult;
      return {
        ...state,

        fd_isLoadingProfitLoss: false,
      };
    case types.FILTER_BONDS_PROFIT_PROFIT:
      var bonds_filterResult = [];
      var bonds_filterdata = state.bonds_profitLossfilter;
      if (action.payload.Scheme != "" && action.payload.Scheme != null) {
        var bonds_schemeName = action.payload.Scheme.map((item) => {
          return item.label;
        });
        //
        if (bonds_schemeName != null) {
          //
          bonds_filterResult = bonds_filterdata.filter((i) => {
            return bonds_schemeName.includes(i.Scheme);
          });
        } else {
          //
          bonds_filterResult = bonds_filterdata;
        }
      } else {
        bonds_filterResult = bonds_filterdata;
      }
      state.bonds_profitLoss = bonds_filterResult;
      return {
        ...state,

        bonds_isLoadingProfitLoss: false,
      };
    case types.FILTER_AIF_PROFIT_PROFIT:
      var aif_filterResult = [];
      var aif_filterdata = state.aif_profitLossfilter;
      if (action.payload != "" && action.payload != null) {
        var aif_schemeName = action.payload.map((item) => {
          return item.label;
        });
        //
        if (aif_schemeName != null) {
          //
          aif_filterResult = aif_filterdata.filter((i) => {
            return aif_schemeName.includes(i.Scheme);
          });
        } else {
          //
          fd_filterResult = fd_filterdata;
        }
      } else {
        aif_filterResult = aif_filterdata;
      }
      state.aif_profitLoss = aif_filterResult;
      return {
        ...state,

        aif_isLoadingProfitLoss: false,
      };
    case types.FILTER_GB_PROFIT_PROFIT:
      var gb_filterResult = [];
      var gb_filterdata = state.gb_profitLossfilter;
      if (action.payload != "" && action.payload != null) {
        var gb_schemeName = action.payload.map((item) => {
          return item.label;
        });
        //
        if (gb_schemeName != null) {
          //
          gb_filterResult = gb_filterdata.filter((i) => {
            return gb_schemeName.includes(i.Scheme);
          });
        } else {
          //
          gb_filterResult = gb_filterdata;
        }
      } else {
        gb_filterResult = gb_filterdata;
      }
      state.gb_profitLoss = gb_filterResult;
      return {
        ...state,

        gb_isLoadingProfitLoss: false,
      };
    case types.FILTER_PROFIT_PROFIT:
      var filterResult = [];
      var filterdata = state.profitLossfilter;
      if (action.payload.Scheme != "" && action.payload.Scheme != null) {
        var schemeName = action.payload.Scheme.map((item) => {
          return item.label;
        });
        //
        if (schemeName != null) {
          //
          filterResult = filterdata.filter((i) => {
            return schemeName.includes(i.Scheme);
          });
        } else {
          //
          filterResult = filterdata;
        }
      } else {
        filterResult = filterdata;
      }
      state.profitLoss = filterResult;
      return {
        ...state,

        isLoadingProfitLoss: false,
      };
    case types.EMPTY_PROFITLOSS:
      return {
        ...state,
        profitLoss: [],
        profitLossfilter: [],
        isLoadingProfitLoss: false,
        grandpurchasetotal: "",
        grandselltotal: "",
        granddivdend: "",
        grandglamount: "",
        isProfitLossLoading: false,
      };
    case types.LOADER_PROFIT_LOSS:
      return {
        ...state,
        isProfitLossLoading: action.payload,
      };
    case types.EQUITY_PROFIT_LOSS:
      return {
        ...state,
        equity_profitLoss:
          action.payload == null ||
            action.payload == undefined ||
            action.payload.length == 0
            ? state.equity_profitLoss
            : action.payload.map(({ LongTermAmount, ShortTermAmount, ...item }) => {
              return { ...item, Scheme: item.ScripName };
            }),
        equity_profitLossfilter:
          action.payload == null ||
            action.payload == undefined ||
            action.payload.length == 0
            ? state.equity_profitLossfilter
            : action.payload.map(({ LongTermAmount, ShortTermAmount, ...item }) => {
              return { ...item, schemeName: item.ScripName, Scheme: item.ScripName };
            }),
        equity_isLoadingProfitLoss: false,
        equity_GainPercentage:
          action.payload == null || action.payload.length == 0
            ? state.equity_GainPercentage
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalPercentage === ele.TotalPercentage
                  )
              )
              .map((val) => {
                return Number(val.TotalPercentage);
              }),
        equity_grandpurchasetotal:
          action.payload == null || action.payload.length == 0
            ? state.equity_grandpurchasetotal
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalPurchase === ele.TotalPurchase
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalPurchase, "", "", "", "number");
              }),
        equity_grandselltotal:
          action.payload == null || action.payload.length == 0
            ? state.equity_grandselltotal
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalSell === ele.TotalSell
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalSell, "", "", "", "number");
              }),
        equity_granddivdend:
          action.payload == null || action.payload.length == 0
            ? state.equity_granddivdend
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalDividend === ele.TotalDividend
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalDividend, "", "", "", "number");
              }),
        equity_grandglamount:
          action.payload == null || action.payload.length == 0
            ? state.equity_grandglamount
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalGainLoss === ele.TotalGainLoss
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalGainLoss, "", "", "", "number");
              }),
        equity_isProfitLossLoading: false,
      };
    case types.EMPTY_EQUITY_PROFIT_LOSS:
      return {
        ...state,
        equity_profitLoss: [],
        equity_profitLossfilter: [],
        equity_isLoadingProfitLoss: false,
        equity_grandpurchasetotal: "",
        equity_grandselltotal: "",
        equity_granddivdend: "",
        equity_grandglamount: "",
        equity_isProfitLossLoading: false,
      };
    case types.LOADER_EQUITY_PROFIT_LOSS:
      return {
        ...state,
        equity_isProfitLossLoading: action.payload,
      };
    //bonds

    case types.BONDS_PROFIT_LOSS:
      return {
        ...state,
        bonds_profitLoss:
          action.payload == null ||
            action.payload == undefined ||
            action.payload.length == 0
            ? state.bonds_profitLoss
            : action.payload.map(({ ShortTermAmount, LongTermAmount, ...item }) => (
              { ...item, schemeName: item.ScripName, Scheme: item.ScripName }
            )),
        bonds_profitLossfilter:
          action.payload == null ||
            action.payload == undefined ||
            action.payload.length == 0
            ? state.bonds_profitLossfilter
            : action.payload.map(({ ShortTermAmount, LongTermAmount, ...item }) => (
              { ...item, schemeName: item.ScripName, Scheme: item.ScripName }
            )),
        bonds_isLoadingProfitLoss: false,
        bonds_GainPercentage:
          action.payload == null || action.payload.length == 0
            ? state.bonds_GainPercentage
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalPercentage === ele.TotalPercentage
                  )
              )
              .map((val) => {
                return Number(val.TotalPercentage);
              }),
        bonds_grandpurchasetotal:
          action.payload == null || action.payload.length == 0
            ? state.bonds_grandpurchasetotal
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalPurchase === ele.TotalPurchase
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalPurchase, "", "", "", "number");
              }),
        bonds_grandselltotal:
          action.payload == null || action.payload.length == 0
            ? state.bonds_grandselltotal
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalSell === ele.TotalSell
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalSell, "", "", "", "number");
              }),
        bonds_granddivdend:
          action.payload == null || action.payload.length == 0
            ? state.bonds_granddivdend
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalDividend === ele.TotalDividend
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalDividend, "", "", "", "number");
              }),
        bonds_grandglamount:
          action.payload == null || action.payload.length == 0
            ? state.bonds_grandglamount
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalGainLoss === ele.TotalGainLoss
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalGainLoss, "", "", "", "number");
              }),
        bonds_isProfitLossLoading: false,
      };
    case types.EMPTY_BONDS_PROFIT_LOSS:
      return {
        ...state,
        bonds_profitLoss: [],
        bonds_profitLossfilter: [],
        bonds_isLoadingProfitLoss: false,
        bonds_grandpurchasetotal: "",
        bonds_grandselltotal: "",
        bonds_granddivdend: "",
        bonds_grandglamount: "",
        bonds_isProfitLossLoading: false,
      };
    case types.LOADER_BONDS_PROFIT_LOSS:
      return {
        ...state,
        bonds_isProfitLossLoading: action.payload,
      };

    //Gold bonds
    case types.GB_PROFIT_LOSS:
      return {
        ...state,
        gb_profitLoss:
          action.payload == null ||
            action.payload == undefined ||
            action.payload.length == 0
            ? state.gb_profitLoss
            : action.payload.map(({ units, Dividend, ShortTermAmount, LongTermAmount, ...item }) => (
              { ...item, schemeName: item.ScripName, Scheme: item.ScripName }
            )),
        gb_profitLossfilter:
          action.payload == null ||
            action.payload == undefined ||
            action.payload.length == 0
            ? state.gb_profitLossfilter
            : action.payload.map(({ units, Dividend, ShortTermAmount, LongTermAmount, ...item }) => (
              { ...item, schemeName: item.ScripName, Scheme: item.ScripName }
            )),
        gb_isLoadingProfitLoss: false,
        gb_GainPercentage:
          action.payload == null || action.payload.length == 0
            ? state.gb_GainPercentage
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalPercentage === ele.TotalPercentage
                  )
              )
              .map((val) => {
                return Number(val.TotalPercentage);
              }),
        gb_grandpurchasetotal:
          action.payload == null || action.payload.length == 0
            ? state.gb_grandpurchasetotal
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalPurchase === ele.TotalPurchase
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalPurchase, "", "", "", "number");
              }),
        gb_grandselltotal:
          action.payload == null || action.payload.length == 0
            ? state.gb_grandselltotal
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalSell === ele.TotalSell
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalSell, "", "", "", "number");
              }),
        gb_granddivdend:
          action.payload == null || action.payload.length == 0
            ? state.gb_granddivdend
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalDividend === ele.TotalDividend
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalDividend, "", "", "", "number");
              }),
        gb_grandglamount:
          action.payload == null || action.payload.length == 0
            ? state.gb_grandglamount
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalGainLoss === ele.TotalGainLoss
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalGainLoss, "", "", "", "number");
              }),
        gb_isProfitLossLoading: false,
      };
    case types.EMPTY_GB_PROFIT_LOSS:
      return {
        ...state,
        gb_profitLoss: [],
        gb_profitLossfilter: [],
        gb_isLoadingProfitLoss: false,
        gb_grandpurchasetotal: "",
        gb_grandselltotal: "",
        gb_granddivdend: "",
        gb_grandglamount: "",
        gb_isProfitLossLoading: false,
      };
    case types.LOADER_GB_PROFIT_LOSS:
      return {
        ...state,
        gb_isProfitLossLoading: action.payload,
      };

    //FD
    case types.FD_PROFIT_LOSS:
      return {
        ...state,
        fd_profitLoss:
          action.payload == null ||
            action.payload == undefined ||
            action.payload.length == 0
            ? state.fd_profitLoss
            : action.payload.map(({ LongTermAmount, ShortTermAmount, ...item }) => (
              { ...item, schemeName: item.ScripName, Scheme: item.ScripName }
            )),
        fd_profitLossfilter:
          action.payload == null ||
            action.payload == undefined ||
            action.payload.length == 0
            ? state.fd_profitLossfilter
            : action.payload.map(({ ...item }) => (
              { ...item, schemeName: item.ScripName, Scheme: item.ScripName }
            )),
        fd_isLoadingProfitLoss: false,
        fd_GainPercentage:
          action.payload == null || action.payload.length == 0
            ? state.fd_GainPercentage
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalPercentage === ele.TotalPercentage
                  )
              )
              .map((val) => {
                return Number(val.TotalPercentage);
              }),
        fd_grandpurchasetotal:
          action.payload == null || action.payload.length == 0
            ? state.fd_grandpurchasetotal
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalPurchase === ele.TotalPurchase
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalPurchase, "", "", "", "number");
              }),
        fd_grandselltotal:
          action.payload == null || action.payload.length == 0
            ? state.fd_grandselltotal
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalSell === ele.TotalSell
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalSell, "", "", "", "number");
              }),
        fd_granddivdend:
          action.payload == null || action.payload.length == 0
            ? state.fd_granddivdend
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalDividend === ele.TotalDividend
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalDividend, "", "", "", "number");
              }),
        fd_grandglamount:
          action.payload == null || action.payload.length == 0
            ? state.fd_grandglamount
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalGainLoss === ele.TotalGainLoss
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalGainLoss, "", "", "", "number");
              }),
        fd_isProfitLossLoading: false,
      };
    case types.EMPTY_FD_PROFIT_LOSS:
      return {
        ...state,
        fd_profitLoss: [],
        fd_profitLossfilter: [],
        fd_isLoadingProfitLoss: false,
        fd_grandpurchasetotal: "",
        fd_grandselltotal: "",
        fd_granddivdend: "",
        fd_grandglamount: "",
        fd_isProfitLossLoading: false,
      };
    case types.LOADER_FD_PROFIT_LOSS:
      return {
        ...state,
        fd_isProfitLossLoading: action.payload,
      };
    // case types.AIF_PROFITLOSS:

    //   return {
    //     ...state,
    //     aif_profitLoss:
    //       action.payload == null ||
    //         action.payload == undefined ||
    //         action.payload.length == 0
    //         ? state.aif_profitLoss
    //         : action.payload.map(({ units, ...item }) => (
    //           { ...item, schemeName: item.ScripName, Scheme: item.ScripName }
    //         )),
    //     aif_profitLossfilter:
    //       action.payload == null ||
    //         action.payload == undefined ||
    //         action.payload.length == 0
    //         ? state.aif_profitLossfilter
    //         : action.payload.map(({ units, ...item }) => (
    //           { ...item, schemeName: item.ScripName, Scheme: item.ScripName }
    //         )),
    //     aif_isLoadingProfitLoss: false,
    //     aif_GainPercentage:
    //       action.payload == null || action.payload == undefined || action.payload.length == 0
    //         ? state.aif_GainPercentage
    //         : action.payload
    //           .filter(
    //             (ele, ind) =>
    //               ind ===
    //               action.payload.findIndex(
    //                 (elem) => elem.TotalPercentage === ele.TotalPercentage
    //               )
    //           )
    //           .map((val) => {
    //             return Number(val.TotalPercentage);
    //           }),
    //     aif_grandpurchasetotal:
    //       action.payload == null || action.payload == undefined || action.payload.length == 0
    //         ? state.aif_grandpurchasetotal
    //         : action.payload
    //           .filter(
    //             (ele, ind) =>
    //               ind ===
    //               action.payload.findIndex(
    //                 (elem) => elem.TotalPurchase === ele.TotalPurchase
    //               )
    //           )
    //           .map((val) => {
    //             return NegativeFinding(val.TotalPurchase, "", "", "", "number");
    //           }),
    //     aif_grandselltotal:
    //       action.payload == null || action.payload == undefined || action.payload.length == 0
    //         ? state.aif_grandselltotal
    //         : action.payload
    //           .filter(
    //             (ele, ind) =>
    //               ind ===
    //               action.payload.findIndex(
    //                 (elem) => elem.TotalSell === ele.TotalSell
    //               )
    //           )
    //           .map((val) => {
    //             return NegativeFinding(val.TotalSell, "", "", "", "number");
    //           }),
    //     aif_granddivdend:
    //       action.payload == null || action.payload == undefined || action.payload.length == 0
    //         ? state.aif_granddivdend
    //         : action.payload
    //           .filter(
    //             (ele, ind) =>
    //               ind ===
    //               action.payload.findIndex(
    //                 (elem) => elem.TotalDividend === ele.TotalDividend
    //               )
    //           )
    //           .map((val) => {
    //             return NegativeFinding(val.TotalDividend, "", "", "", "number");
    //           }),
    //     aif_grandglamount:
    //       action.payload == null || action.payload == undefined || action.payload.length == 0
    //         ? state.aif_grandglamount
    //         : action.payload
    //           .filter(
    //             (ele, ind) =>
    //               ind ===
    //               action.payload.findIndex(
    //                 (elem) => elem.TotalGainLoss === ele.TotalGainLoss
    //               )
    //           )
    //           .map((val) => {
    //             return NegativeFinding(val.TotalGainLoss, "", "", "", "number");
    //           }),
    //     aif_isProfitLossLoading: false,
    //   };
    // case types.EMPTY_AIF_PROFITLOSS:
    //   return {
    //     ...state,
    //     aif_profitLoss: [],
    //     aif_profitLossfilter: [],
    //     aif_isLoadingProfitLoss: false,
    //     aif_grandpurchasetotal: "",
    //     aif_grandselltotal: "",
    //     aif_granddivdend: "",
    //     aif_grandglamount: "",
    //     aif_isProfitLossLoading: false,
    //   };
    // case types.LOADER_AIF_PROFITLOSS:
    //   return {
    //     ...state,
    //     aif_isProfitLossLoading: action.payload,
    //   };


    //REAL ESTATE REPORT ACTION

    case types.REALESTATE_PROFIT_LOSS:
    
      return {
        ...state,
        realEstate_profitLoss: 
          action.payload == null ||
            action.payload == undefined ||
            action.payload.length == 0
            ? state.realEstate_profitLoss
            : action.payload.map(({ Dividend, ShortTermAmount, LongTermAmount,TotalDividend, ...item }) => (
              { ...item, schemeName: item.Scheme, Scheme: item.Scheme }
            )),
        realEstate_profitLossfilter:
        action.payload == null ||
            action.payload == undefined ||
            action.payload.length == 0
            ? state.realEstate_profitLossfilter
            : action.payload.map(({ Dividend, ShortTermAmount, LongTermAmount,TotalDividend, ...item }) => (
              { ...item, schemeName: item.Scheme, Scheme: item.Scheme }
            )),
        realEstate_isLoadingProfitLoss: false,
        realEstate_GainPercentage:
          action.payload == null || action.payload.length == 0
            ? state.realEstate_GainPercentage
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalPercentage === ele.TotalPercentage
                  )
              )
              .map((val) => {
                return Number(val.TotalPercentage);
              }),
        realEstate_grandpurchasetotal:
          action.payload == null || action.payload.length == 0
            ? state.realEstate_grandpurchasetotal
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalPurchase === ele.TotalPurchase
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalPurchase, "", "", "", "number");
              }),
        realEstate_grandselltotal:
          action.payload == null || action.payload.length == 0
            ? state.realEstate_grandselltotal
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalSell === ele.TotalSell
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalSell, "", "", "", "number");
              }),
        // realEstate_granddivdend:
        //   action.payload == null || action.payload.length == 0
        //     ? state.realEstate_granddivdend
        //     : action.payload
        //       .filter(
        //         (ele, ind) =>
        //           ind ===
        //           action.payload.findIndex(
        //             (elem) => elem.TotalDividend === ele.TotalDividend
        //           )
        //       )
        //       .map((val) => {
        //         return NegativeFinding(val.TotalDividend, "", "", "", "number");
        //       }),
        realEstate_grandglamount:
          action.payload == null || action.payload.length == 0
            ? state.realEstate_grandglamount
            : action.payload
              .filter(
                (ele, ind) =>
                  ind ===
                  action.payload.findIndex(
                    (elem) => elem.TotalGainLoss === ele.TotalGainLoss
                  )
              )
              .map((val) => {
                return NegativeFinding(val.TotalGainLoss, "", "", "", "number");
              }),
        realEstate_isProfitLossLoading: false,
      };
    case types.EMPTY_REALESTATE_PROFITLOSS:
      return {
        ...state,
        realEstate_profitLoss: [],
        realEstate_profitLossfilter: [],
        realEstate_isLoadingProfitLoss: false,
        realEstate_grandpurchasetotal: "",
        realEstate_grandselltotal: "",
        realEstate_granddivdend: "",
        realEstate_grandglamount: "",
        realEstate_isProfitLossLoading: false,
      };
    case types.LOADER_REALESTATE_PROFITLOSS:
      return {
        ...state,
        realEstate_isProfitLossLoading: action.payload,
      };
    case types.FILTER_REALESTATE_PROFIT_PROFIT:
      var realEstate_filterResult = [];
      var realEstate_filterdata = state.realEstate_profitLossfilter;
      if (action.payload != "" && action.payload != null) {
        var realEstate_schemeName = action.payload.map((item) => {
          return item.label;
        });
        //
        if (realEstate_schemeName != null) {
          //
          realEstate_filterResult = realEstate_filterdata.filter((i) => {
            return realEstate_schemeName.includes(i.Scheme);
          });
        } else {
          //
          realEstate_filterResult = realEstate_filterdata;
        }
      } else {
        realEstate_filterResult = realEstate_filterdata;
      }
      state.realEstate_profitLoss = realEstate_filterResult;
      return {
        ...state,

        realEstate_isLoadingProfitLoss: false,
      };


    // case types.NPS_PROFIT_LOSS:
    //   return {
    //     ...state,
    //     nps_profitLoss:
    //       action.payload == null ||
    //         action.payload == undefined ||
    //         action.payload.length == 0
    //         ? state.nps_profitLoss
    //         : action.payload.map(({Dividend,...item}) => (
    //           { ...item, schemeName: item.ScripName, Scheme: item.ScripName }
    //        )),
    //     nps_profitLossfilter:
    //       action.payload == null ||
    //         action.payload == undefined ||
    //         action.payload.length == 0
    //         ? state.nps_profitLossfilter
    //         : action.payload.map(({Dividend,...item}) => (
    //           { ...item, schemeName: item.ScripName, Scheme: item.ScripName }
    //        )),
    //     nps_isLoadingProfitLoss: false,
    //     nps_GainPercentage:
    //       action.payload == null || action.payload == undefined || action.payload.length == 0
    //         ? state.nps_GainPercentage
    //         : action.payload
    //           .filter(
    //             (ele, ind) =>
    //               ind ===
    //               action.payload.findIndex(
    //                 (elem) => elem.TotalPercentage === ele.TotalPercentage
    //               )
    //           )
    //           .map((val) => {
    //             return Number(val.TotalPercentage);
    //           }),
    //     nps_grandpurchasetotal:
    //       action.payload == null || action.payload == undefined || action.payload.length == 0
    //         ? state.nps_grandpurchasetotal
    //         : action.payload
    //           .filter(
    //             (ele, ind) =>
    //               ind ===
    //               action.payload.findIndex(
    //                 (elem) => elem.TotalPurchase === ele.TotalPurchase
    //               )
    //           )
    //           .map((val) => {
    //             return NegativeFinding(val.TotalPurchase,"","","","number");
    //           }),
    //     nps_grandselltotal:
    //       action.payload == null || action.payload == undefined || action.payload.length == 0
    //         ? state.nps_grandselltotal
    //         : action.payload
    //           .filter(
    //             (ele, ind) =>
    //               ind ===
    //               action.payload.findIndex(
    //                 (elem) => elem.TotalSell === ele.TotalSell
    //               )
    //           )
    //           .map((val) => {
    //             return NegativeFinding(val.TotalSell,"","","","number");
    //           }),
    //     nps_granddivdend:
    //       action.payload == null || action.payload == undefined || action.payload.length == 0
    //         ? state.nps_granddivdend
    //         : action.payload
    //           .filter(
    //             (ele, ind) =>
    //               ind ===
    //               action.payload.findIndex(
    //                 (elem) => elem.TotalDividend === ele.TotalDividend
    //               )
    //           )
    //           .map((val) => {
    //             return NegativeFinding(val.TotalDividend,"","","","number");
    //           }),
    //     nps_grandglamount:
    //       action.payload == null || action.payload == undefined || action.payload.length == 0
    //         ? state.nps_grandglamount
    //         : action.payload
    //           .filter(
    //             (ele, ind) =>
    //               ind ===
    //               action.payload.findIndex(
    //                 (elem) => elem.TotalGainLoss === ele.TotalGainLoss
    //               )
    //           )
    //           .map((val) => {
    //             return NegativeFinding(val.TotalGainLoss,"","","","number");
    //           }),
    //     nps_isProfitLossLoading: false,
    //   };
    // case types.EMPTY_NPS_PROFIT_LOSS:
    //   return {
    //     ...state,
    //     nps_profitLoss: [],
    //     nps_profitLossfilter: [],
    //     nps_isLoadingProfitLoss: false,
    //     nps_grandpurchasetotal: "",
    //     nps_grandselltotal: "",
    //     nps_granddivdend: "",
    //     nps_grandglamount: "",
    //     nps_isProfitLossLoading: false,
    //   };
    //  case types.LOADER_NPS_PROFIT_LOSS:
    // return {
    //   ...state,
    //   nps_isProfitLossLoading: true,
    // };


    // case types.INSURANCE_PROFIT_LOSS:
    //   return {
    //     ...state,
    //     insurance_profitLoss:
    //       action.payload == null ||
    //         action.payload == undefined ||
    //         action.payload.length == 0
    //         ? state.insurance_profitLoss
    //         : action.payload.map((item) => {
    //           return { ...item, Scheme: item.ScripName };
    //         }),
    //     insurance_profitLossfilter:
    //       action.payload == null ||
    //         action.payload == undefined ||
    //         action.payload.length == 0
    //         ? state.insurance_profitLossfilter
    //         : action.payload.map((item) => {
    //           return { ...item, schemeName: item.ScripName, Scheme: item.ScripName };
    //         }),
    //     insurance_isLoadingProfitLoss: false,
    //     insurance_GainPercentage:
    //       action.payload == null || action.payload == undefined || action.payload.length == 0
    //         ? state.insurance_GainPercentage
    //         : action.payload
    //           .filter(
    //             (ele, ind) =>
    //               ind ===
    //               action.payload.findIndex(
    //                 (elem) => elem.TotalPercentage === ele.TotalPercentage
    //               )
    //           )
    //           .map((val) => {
    //             return Number(val.TotalPercentage);
    //           }),
    //     insurance_grandpurchasetotal:
    //       action.payload == null || action.payload == undefined || action.payload.length == 0
    //         ? state.insurance_grandpurchasetotal
    //         : action.payload
    //           .filter(
    //             (ele, ind) =>
    //               ind ===
    //               action.payload.findIndex(
    //                 (elem) => elem.TotalPurchase === ele.TotalPurchase
    //               )
    //           )
    //           .map((val) => {
    //             return NegativeFinding(val.TotalPurchase,"","","","number");
    //           }),
    //     insurance_grandselltotal:
    //       action.payload == null || action.payload == undefined || action.payload.length == 0
    //         ? state.insurance_grandselltotal
    //         : action.payload
    //           .filter(
    //             (ele, ind) =>
    //               ind ===
    //               action.payload.findIndex(
    //                 (elem) => elem.TotalSell === ele.TotalSell
    //               )
    //           )
    //           .map((val) => {
    //             return NegativeFinding(val.TotalSell,"","","","number");
    //           }),
    //     insurance_granddivdend:
    //       action.payload == null || action.payload == undefined || action.payload.length == 0
    //         ? state.insurance_granddivdend
    //         : action.payload
    //           .filter(
    //             (ele, ind) =>
    //               ind ===
    //               action.payload.findIndex(
    //                 (elem) => elem.TotalDividend === ele.TotalDividend
    //               )
    //           )
    //           .map((val) => {
    //             return NegativeFinding(val.TotalDividend,"","","","number");
    //           }),
    //     insurance_grandglamount:
    //       action.payload == null || action.payload == undefined || action.payload.length == 0
    //         ? state.insurance_grandglamount
    //         : action.payload
    //           .filter(
    //             (ele, ind) =>
    //               ind ===
    //               action.payload.findIndex(
    //                 (elem) => elem.TotalGainLoss === ele.TotalGainLoss
    //               )
    //           )
    //           .map((val) => {
    //             return NegativeFinding(val.TotalGainLoss,"","","","number");
    //           }),
    //     insurance_isProfitLossLoading: false,
    //   };
    // case types.EMPTY_INSURANCE_PROFIT_LOSS:
    //   return {
    //     ...state,
    //     insurance_profitLoss: [],
    //     insurance_profitLossfilter: [],
    //     insurance_isLoadingProfitLoss: false,
    //     insurance_grandpurchasetotal: "",
    //     insurance_grandselltotal: "",
    //     insurance_granddivdend: "",
    //     insurance_grandglamount: "",
    //     insurance_isProfitLossLoading: false,
    //   };
    // case types.LOADER_INSURANCE_PROFIT_LOSS:
    //   return {
    //     ...state,
    //     insurance_isProfitLossLoading: true,
    //   };

    default:
      return state;
  }
};
export default reducer;
