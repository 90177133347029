import * as types from '../../constants/actionsType';
import getdropdownDetails from '../../../api/dropdown/dropdownapi';
import generateMandateType from '../../../api/mandatecreation'
import secureStorage from '../../../utils/securestorage'
import moment from 'moment';
import MandateRedirection from '../../../api/mandate/mandateredirection';
import { openRequestedSinglePopup } from '../../../utils/commonfunction';
import GetSchemeList from '../../../api/dropdown/schemelist';
import GetMasterList from '../../../api/dropdown/masterlist';
export function getAllDropdownDetails(sessionID, clientID,) {
  return (dispatch) => {
    getAllDropdownDetailsAction(dispatch, sessionID, clientID,);
  }
}
export function generateMandate(Amount, MandateType, BankID, additionalBankDetails, selectedBankName, flag, branchname, ifsc, accountnumber, accounttype, BSEMFAccount, UCC, L5ID, selectedAccountNumber, returnMandate) {
  return (dispatch) => {
    // console.log(Amount, MandateType, BankID, additionalBankDetails, selectedBankName, flag, branchname, ifsc, accountnumber, accounttype, BSEMFAccount, UCC, L5ID, selectedAccountNumber, returnMandate)
    generateMandateType(Amount, MandateType, BankID, additionalBankDetails, BSEMFAccount, UCC, L5ID, selectedAccountNumber).then((data) => {
      var response = data
      //if (data != undefined) {
      if (data.status == "S") {
        let userDetails = secureStorage.getItem('user')
        let clientid = userDetails.ClientID
        let mandateid = data.data
        let status = data.status
        let date = moment().format("DD MMM YYYY")
        response.BankID = BankID
        response.MandateID = data.data
        response.Amount = Number(Amount)
        response.ClientID = userDetails.ClientID
        response.BankName = selectedBankName
        response.BranchName = branchname
        response.IFSC = ifsc
        response.BankAccNo = selectedAccountNumber
        response.AccountType = accounttype
        response.Status = data.status
        response.date = date
        response.UCC = UCC
        response.L5ID = BSEMFAccount
        //For Storing Mandate response with amount and bank
        data.id = BankID
        data.amount = Amount
        data.MandateType = MandateType
        let returnData = data
        returnData.response = response
        returnMandate(returnData)
        dispatch({
          type: types.GENERATE_MANDATE,
          payload: data
        });

        dispatch({
          type: types.UPDATE_MANDATE,
          payload: response
        });
        // }
        //open netbanking
        MandateRedirection(mandateid, UCC).then((res) => {
          if (res.status == "S") {
            openRequestedSinglePopup(res.data)
            // window.open(res.data, "_self")
          } else {

          }
        })

      }
      else if (data.status == "N") {
        dispatch({
          type: types.NETWORK_MANDATE,
          payload: data
        });
        returnMandate(response)
      }
      else {
        returnMandate(response)
        dispatch({
          type: types.GENERATE_MANDATE,
          payload: data
        });
      }
      //  }
    }).catch(() => {
      dispatch({
        type: types.GENERATE_MANDATE,
        payload: { "status": "F" }
      });
    })
  }
}
function getAllDropdownDetailsAction(dispatch, sessionID, clientID) {


  dispatch({
    type: types.EMPTY_DROPDOWN,
    payload: [],

  });
  dispatch({
    type: types.LOADER_DROPDOWN,
    payload: true,
  })
  getdropdownDetails(sessionID, clientID)
    .then((data) => {

      dispatch({
        type: types.LOADER_DROPDOWN,
        payload: false,
      });
      if (data != undefined) {
        if (data.status == "S") {
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "Dropdown" }],
          });
          dispatch({
            type: types.GET_DROPDOWN_DETAILS,
            payload: data,
          });
        } else {
          token = ""
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "Dropdown" }],
          });
          dispatch({
            type: types.GET_DROPDOWN_DETAILS,
            payload: [],
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: 0, api: "Dropdown" }],
      });
      dispatch({
        type: types.LOADER_DROPDOWN,
        payload: false,
      });
    })

}
export const dropdownvalue = (data) => {
  return {
    type: types.GET_DROPDOWN,
    data
  }
}
export const SchemeFilters = (data) => {
  return {
    type: types.SCHEME_FILTER,
    payload: data
  }
}
export const Filterclick = (data) => {
  return {
    type: types.FILTER_CLICK,
    payload: data
  }
}
export const TopTen = () => {
  return {
    type: types.TOP_TEN,
    payload: []
  }
}
export const getMandateList = (data) => {
  return {
    type: types.GET_MANDATE_TYPES,
    payload: data
  }
}
export const getMandateDetails = (data) => {
  return {
    type: types.GET_MANDATE_CREATION_RESPONSE,
    payload: data
  }
}
export function getAllSchemeDetails(data) {
  return (dispatch) => {
    dispatch({
      type: types.EMPTY_SCHEMES_DETAILS,
      payload: []
    })
    dispatch({
      type: types.LOADER_SCHEMES_DETAILS,
      payload: true
    })
    GetSchemeList(data).then((res) => {
      if (res.status == "S") {
        dispatch({
          type: types.SCHEMES_DETAILS,
          payload: res.scheme
        })
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: 0, api: "Dropdown" }],
        });
      } else {
        dispatch({
          type: types.EMPTY_SCHEMES_DETAILS,
          payload: []
        })
        dispatch({
          type: types.LOADER_SCHEMES_DETAILS,
          payload: false
        })
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: 0, api: "Dropdown" }],
        });
      }
    }).catch((error) => {
      dispatch({
        type: types.EMPTY_SCHEMES_DETAILS,
        payload: []
      })
      dispatch({
        type: types.LOADER_SCHEMES_DETAILS,
        payload: false
      })
    })
  }
}
export function getAllMasterDetails(data) {
  return (dispatch) => {
    dispatch({
      type: types.EMPTY_MASTER,
      payload: []
    })
    dispatch({
      type: types.LOADER_MASTER,
      payload: true
    })
    GetMasterList(data).then((res) => {
      if (res.status == "S") {
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: 0, api: "Dropdown" }],
        });

        dispatch({
          type: types.GET_MASTER,
          payload: res
        })
      } else {
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: 0, api: "Dropdown" }],
        });
        dispatch({
          type: types.EMPTY_MASTER,
          payload: []
        })
        dispatch({
          type: types.LOADER_MASTER,
          payload: false
        })
      }
    }).catch((error) => {
      dispatch({
        type: types.EMPTY_MASTER,
        payload: []
      })
      dispatch({
        type: types.LOADER_MASTER,
        payload: false
      })
    })
  }
}