import * as types from "../../constants/actionsType";
import { bindActionCreators } from "redux";
const initialState = {
  UpcommingSIPdata: [],
  isUpcomingSIPLoading: false,
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPCOMMING_SIP:
      return {
        ...state,
        UpcommingSIPdata: action.payload != null ? action.payload.data : [],
        isUpcomingSIPLoading: false,
      };
    case types.LOADER_UPCOMMING_SIP:
      return {
        ...state,
        isUpcomingSIPLoading: action.payload,
      };
    case types.EMPTY_UPCOMMING_SIP:
      return {
        ...state,
        UpcommingSIPdata: [],
        isUpcomingSIPLoading: false,
      };
    default:
      return state;
  }
};
export default reducer;
