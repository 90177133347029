import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/styles';
import { withRouter } from 'react-router-dom';
import reactDom from 'react-dom';
const styles = theme => ({
  root: {
    "& .MuiDialogContent-root": {
      padding: "0px !important"
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "none !important",
      width: "25% !important",
      overflow: "hidden"
    },
    "& .MuiDialogContent-root": {
      overflowY: "hidden",
      padding: "0px !important"
    }
  },
  header: {
    backgroundColor: window.globalConfig.color.PRIMARY,
    color: window.globalConfig.color.WHITE,
    height: 50,
    fontWeight: 500,
    position: "sticky",
    top: "0px"
  },
  txtHeader: {
    padding: 15,
  },
  clearIcon: {
    float: 'right',
    cursor: 'pointer'
  },
  outlineButton: {
    width: 109,
    cursor: "pointer",
    height: 39,
    alignItems: "center",
    borderRadius: 5,
    justifyContent: "center",
    border: `1px solid ${window.globalConfig.color.red}`,
    display: "flex",
    flexDirection: "column",
    color: window.globalConfig.color.red,
    fontWeight: 'bold'
  },
});

// function DialogBox(props) {
//   const [open, setOpen] = React.useState(false);
//   const [scroll, setScroll] = React.useState('paper');

//   //   const handleClickOpen = (scrollType) => () => {
//   //     setOpen(true);
//   //     setScroll(scrollType);
//   // //   };

//   //   const handleClose = () => {
//   //     setOpen(false);
//   //   };
//   const descriptionElementRef = React.useRef(null);
//   React.useEffect(() => {
//     if (props.open) {
//       const { current: descriptionElement } = descriptionElementRef;
//       if (descriptionElement !== null) {
//         descriptionElement.focus();
//       }
//     }
//   }, [props.open]);

// }
class CommonDialogBox extends React.Component {
  render() {
    const { classes } = this.props;
    if (this.props.flag == "primary") {
      return (

        <Dialog root={classes.root} open={this.props.open} onClose={this.props.handleClose}>
          <div className={classes.header}>
            <div className={classes.txtHeader}>{"Account Status"}
              <span className={classes.clearIcon} onClick={this.props.onClick}>&#x2715;</span>
            </div>
          </div>
          <div style={{ display: "flex", color: window.globalConfig.color.PRIMARY_BLACK, flexDirection: "column", padding: 12, gap: 10, justifyContent: "space-between", alignItems: "center" }}>
            <div className={classes.outlineButton}>
              Not Active
            </div>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 6 }}>
              <span>For further details.</span>
              <span>Please call us at <b>{window.globalConfig.isUAT ? "899*****09" : "9152022446"}</b></span>
            </div>
          </div>
        </Dialog>


      )
    } else {
      return (
        <div>
          <Dialog
            open={this.props.open}
            onClose={this.props.handleClose}
            scroll={"paper"}
            classes={{ root: this.props.root }}

            // aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
          >
            {/* <DialogTitle id="scroll-dialog-title"> */}
            {this.props.header}
            {/* </DialogTitle> */}
            <DialogContent >
              {this.props.children}

            </DialogContent>
            {this.props.footer}
          </Dialog>
        </div>
      )

    }

  }
}
export default withStyles(styles)(withRouter(CommonDialogBox));
