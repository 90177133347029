import Networking from '../../utils/api/apiaccess';
import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
/* name should be camel case in all function */
function getProductWisePortfolioSummaryFamily(ClientID, ReportDate, AsOnDate,) {

    let data = {};
    data.Account = 0
    data.AccountLevel = 0
    data.ClientID = Number(ClientID)
    data.ReportDate = ReportDate
    data.AsOnDate = AsOnDate
    data.ProductID = 1
    data.FamilyID = 0
    data.L5ID = 0
    data.L4ID = 0
    //AsOnDate
    // var config = {
    //   method: 'post',
    //   url: ApiValues.HeaderDetails,
    //   headers: { 
    //     'Content-Type': 'application/json'
    //   },
    //   data : data
    // };

    // return axios(config)
    // .then(function (response) {
    //   return response.data;
    // })
    // .catch(function (error) {
    //   return { "status": "F", "message": labels.messages.apierror }
    // });
    return Networking.PostApi(ApiValues.ProductWisePortfolioSummaryFamily, data).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });
}
export default getProductWisePortfolioSummaryFamily
