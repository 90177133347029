import * as types from '../../constants/actionsType';
import getTransactionOrderReportDetails from '../../../api/transactionorderapi/transactionorderapi';
export function getTransactionOrderReport(SessionID, ClientID, AsOnDate) {
    return (dispatch) => {
        getAllTransactionOrderReportAction(dispatch, SessionID, ClientID, AsOnDate);
    };
}
function getAllTransactionOrderReportAction(dispatch, SessionID, ClientID, AsOnDate) {
    dispatch({
        type: types.LOADER_TRANSACTION_ORDER_REPORT,
        payload: []
    });
    getTransactionOrderReportDetails(SessionID, ClientID, AsOnDate).then((data) => {
        dispatch({
            type: types.TRANSACTION_ORDER_REPORT,
            payload: [],
        });
       // 
        if (data != undefined) {
            if (data.status == "S") {
               // 
                dispatch({
                    type: types.TRANSACTION_ORDER_REPORT,
                    payload: data
                });
                dispatch({
                    type: types.PREVENT_API_CALL,
                    payload:[{ProductID:11,api: "MFTransactionOrderStatus"}]
                });
            }           
            else {
               // 
                dispatch({
                    type: types.TRANSACTION_ORDER_REPORT,
                    payload: [],
                });
                dispatch({
                    type: types.PREVENT_API_CALL,
                    payload:[{ProductID:11,api: "MFTransactionOrderStatus"}]
                });
            }

        }
        else {
           // 
            dispatch({
                type: types.TRANSACTION_ORDER_REPORT,
                payload: [],
            });
            dispatch({
                type: types.PREVENT_API_CALL,
                payload:[{ProductID:11,api: "MFTransactionOrderStatus"}]
            });
        }
    })
        .catch((error) => {
            dispatch({
                type: types.TRANSACTION_ORDER_REPORT,
                payload: [],
            });
            dispatch({
                type: types.PREVENT_API_CALL,
                payload:[{ProductID:11,api: "MFTransactionOrderStatus"}]
            });
        })
    // .then((data) => {
    //     // 

    //     dispatch({
    //         type: types.CAPITAL_GAIN,
    //         payload: data,
    //     });

    //     //   if (data != undefined) {
    //     //     if (data.status == "S") {

    //     //     } else {

    //     //     }
    //     //   }
    // })
    // .catch((error) => {
    // })
}
