import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router';
import { withStyles } from '@material-ui/core';
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle';
import CommonSnackbar from '../../components/snackbar/snackbar';
import labels from '../../utils/constants/labels';
import { getL5reportDetails } from "../../redux/actions/L5Report/L5ReportAction";
import { getRealEstateList, deleteListing, deleteBullionListing, getBullionList } from '../../redux/actions/DataEntry/real_estate_report';
import { Listing_RealEstate, Listing_Bullion } from '../../redux/actions/DataEntry/real_estate_report';
import DeletePopup from '../../components/popup/deletepopup';
import Subheader from '../../components/subheader/subheader';
import DynamicList from '../../components/list/dynamicList';
import AddSell from '../../components/popup/add_sell_popup';
import OutlinedButton from '../../components/payment/button';
import deleteRealEstateList from '../../api/real_estate_dataentey/delete_real_estate_api'
import deleteBullionList from '../../api/real_estate_dataentey/delete_bullion_dataentry_api';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import ConfirmationPopup from '../../components/popup/deletepopup';
import colors from '../../utils/colors/colors';
import { scrollToTopPage } from '../../utils/commonfunction';
const styles = theme => ({

    grid: {
        display: 'grid',
        gridTemplateColumns: '30% 30% 29% 3%',
        gap: '20px'
    },
    valuationGrid: {
        display: 'grid',
        gridTemplateColumns: '40% 40% 5%',
        gap: '20px'
    },
    transactionGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5,1fr)',
        gap: '20px'
    },
    flex: {
        display: 'flex',
        flexDirection: 'row'
    },
    sideSpace: {
        width: '12%'
    },
    mainCard: {
        display: "flex", marginBottom: "3%",
        boxShadow: "rgb(19 89 161 / 10%) 0px 0px 20px 0px",
        borderRadius: 5,
        // minHeight: "",
        height: "auto",
        width: "89%", marginTop: "30px",
        padding: '2% 4% 4% 4%',
        display: 'flex',
        flexDirection: 'column'
    },
    subHeader: {
        textAlign: 'left',
        marginTop: '1pc',
        color: window.globalConfig.color.LABLE_GRAY,
        // marginLeft: 10,
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
    cardSpereaterLine: {
        height: 0.5,
        width: "100%",
        // backgroundColor: window.globalConfig.color.AliceBlue,
        backgroundColor:window.globalConfig.color.LABLE_GRAY,
        marginBottom: '1pc',
        marginTop: '6px',
        // marginLeft: 10,
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
    twoColumnGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)'
    },
    headerGrid: {
        textAlign: 'left',
        paddingLeft: 15
    },
    buttonStyle: {
        width: "20%",
        [theme.breakpoints.down("920")]: {
            width: "50%"
        }
    },
    textboxWidth: {
        // width:"inherit !important"
    },
    ContainerWithWidth: {
        [theme.breakpoints.up("920")]: {
            display: "grid",
            gridTemplateColumns: "50% 50%",

            width: "100%", marginRight: 0,
        },
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "50% 50%",
            marginLeft: 5,
            display: "grid",
        }
    },
    assetGrid: {
        display: "grid",
        gap: '20px',
        // gridTemplateColumns: "18% 10% 10% 13% 10% 10% 10% 1%",
        gridTemplateColumns: '17% 16% 10% 13% 7% 17% 7%',
        // alignItems: 'baseline',
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(3,1fr)",
            gridTemplateAreas: `"${"myArea myArea."}"`,
            height: "auto !important",
            rowGap: "20px",
            paddingTop: 10,
            display: "grid",
        }
    },
    bullionGrid: {
        display: "grid",
        gap: '30px',
        
        gridTemplateColumns: ' 12% 14% 10% 13% 9% 16% 6%',
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(3,1fr)",
            gridTemplateAreas: `"${"myArea myArea."}"`,
            height: "auto !important",
            rowGap: "20px",
            paddingTop: 10,
            display: "grid",
        }
        
    },
    DynamicList4: {
        [theme.breakpoints.down("920")]: {
            textAlign: "left !important"
        }
    },
    DynamicList2: {
        [theme.breakpoints.down("920")]: {
            textAlign: "left !important"
        }
    },
    DynamicList3: {
        [theme.breakpoints.down("920")]: {
            textAlign: "left !important"
        }
    },
    DynamicList5: {
        [theme.breakpoints.down("920")]: {
            textAlign: "left !important"
        }
    },
    DynamicList6: {
        [theme.breakpoints.down("920")]: {
            textAlign: "left !important"
        }
    },
    DynamicList1: {
        [theme.breakpoints.down("920")]: {
            textAlign: "left !important"
        }
    },

});
class RealestateListing extends Component {
    constructor() {
        super();
        this.myDiv = React.createRef();
        this.state = {
            isFlag: true,
            headerList: [],
            list: [],
            deletePopupLoader: false,
            openDeletePopup: false,
            openAddPopup: false,
            header: "",
            grid: "",
            enableDelete: false,
            enableEdit: false,
            enableIcon: false,
            count: 0,
            popupList: [],
            flag: 'asset',
            id: '',
            Location: '',
            ValuationsValueDate: '',
            loader: false,
            openSellPopup: false,
        }

    }
    componentDidMount() {
        document.title = window.globalConfig.companyDetails.companyName +" - Real Estate"
        scrollToTopPage();
        const { classes } = this.props
        if (this.props.isListing != "Bullion") {
            this.props.getRealEstateList(1, this.props.clientID)
            this.setState({
                loader: this.props.isLoading,
                isFlag: true,
                grid: classes.assetGrid, enableDelete: false,
                enableEdit: false,
                count: 7,
                popupList:
                    [{ "name": "Edit Asset", "onClick": this.handleRedirectionDataentry },
                    { "name": "Sell Asset", "onClick": this.sellClick },
                    { "name": "Delete Asset", "onClick": this.handleDeleteListing },
                    { "name": "Add Valuation", 'onClick': this.handleAddEvaluation }],
                enableIcon: false,
                // enableIcon: this.props.realEstateList.map(item.REStatus)[0].name==2 ?false :true,
                headerList: [{ label: "Property Name", "subLabel": "property Type",align: 'left' },
                // { label: "property Type", align: 'left' },
                { label: "Buy Price",  "subLabel": "Buy Date",align: 'right' },
                // { label: "Buy Date", align: 'left' },
                { label: "Location", align: 'left' },
                { label: "Property Size (Sq. Unit)", align: 'right' },
                { label: "Usage", align: 'left' },
                { label: "Latest Value","subLabel": "Latest Value Date", align: 'right' },
                // { label: "Latest Value Date", align: 'left' }
                ]
            })



        } else {
            this.props.getBullionList(1, this.props.clientID)
            this.setState({
                loader: this.props.isLoading,
                isFlag: false,
                grid: classes.bullionGrid, enableDelete: false,
                enableEdit: false,
                count: 6,
                popupList: [{ "name": "Edit Asset", "onClick": this.handleRedirectionDataentry },
                { "name": "Sell Asset", "onClick": this.sellClick },
                { "name": "Delete Asset", "onClick": this.handleDeleteListing },
                { "name": "Add Valuation", 'onClick': this.handleAddEvaluation }],
                enableIcon: false,
                // enableIcon: this.props.bullionList.BullionStatus.name==2 ?false :true,
                headerList: [
                    { label: "Bullion", align: 'left' },
                    { label: "Bullion Type", align: 'left' },
                    { label: "Invested Value", align: 'right' },
                    { label: "Invested Date", align: 'left' },
                    { label: "Latest Value", align: 'right' },
                    { label: "Latest Value Date", align: 'left' }]
            })
        }


    }
    handleRedirectionDataentry = (item) => {
        scrollToTopPage();
        if (this.props.isListing != "Bullion") {
            this.props.history.push({
                pathname: labels.Path.RealEstate,
                state: {
                    data: item,
                    isStatus: "update",
                }
            })
        }
        else {
            this.props.history.push({
                pathname: labels.Path.Bullion,
                state: {
                    data: item,
                    isStatus: "update",
                }
            })
        }
    }
    navigationOfDataEntryPage = () => {
        scrollToTopPage();
        if (this.state.isFlag == true) {
            this.props.history.push({
                pathname: labels.Path.RealEstate,
                state: {
                    isStatus: "add",
                }
            })

        }
        else {

            this.props.history.push({
                pathname: labels.Path.Bullion,
                state: {
                    isStatus: "add",
                }
            })
        }
    }
    handleAddEvaluation = (item) => {
        this.setState({ flag: "valuation", header: "Add valuation", openAddPopup: true, id: item.ID.name, Location: this.props.location.pathname, ValuationsValueDate: this.props.isListing != "Bullion" ? item.BuyPrice.subHeader1 : item.InvestedDate.name })

    }
    handleAddSell = (item) => {
        this.setState({
            id: item.ID.name,
            Location: this.props.location.pathname,
            ValuationsValueDate: this.props.isListing != "Bullion" ? item.BuyPrice.subHeader1 : item.InvestedDate.name,
            enableIcon:false,

        },
        )
    }
    sellClick = (item) => {
        this.setState({
            openSellPopup: true,
        })
        this.handleAddSell(item);
    }
    handleSellYesClick = (item) => {
        this.setState({
            openSellPopup: false,
            openAddPopup: true,
        })
        this.setState({
            header: "Add Sell",
            flag: "sell",
            //   id: item.ID.name, 
            //   ValuationsValueDate: this.props.isListing != "Bullion" ? item.BuyDate.name : item.InvestedDate.name
        },
        )
        // this.handleAddSell(item)

    }
    handleDeleteListing = (item) => {
        this.setState({
            openDeletePopup: true,
            id: item.ID.name
        })
    }
    //real esate popup yes button click
    handleYesClick = () => {
        this.setState({ deletePopupLoader: true })
        let data = {
            id: this.state.id,
            clientID: this.props.logindetails.ClientID,
            flag: 3
        }
        if (this.props.isListing != "Bullion") {
            deleteRealEstateList(data).then((response) => {
                this.setState({ deletePopupLoader: false })
                if (response.status == "S") {
                    this.props.deleteListing(data)
                    
                    this.setState({
                        openDeletePopup: false,
                        openSnackBar: true,
                        message: response.message,
                        severity: "success"
                    })


                } else {
                    this.setState({
                        openDeletePopup: false,
                        openSnackBar: true,
                        message: response.message,
                        severity: "warning"
                    })
                }
            })
        } else {
            deleteBullionList(data).then((response) => {
                this.setState({ deletePopupLoader: false })
                if (response.status == "S") {
                    this.props.deleteBullionListing(data)
                    this.setState({
                        openDeletePopup: false,
                        openSnackBar: true,
                        message: response.message,
                        severity: "success"
                    })


                } else {
                    this.setState({
                        openDeletePopup: false,
                        openSnackBar: true,
                        message: response.message,
                        severity: "warning"
                    })
                }
            })
        }


    }
    handleToastClose = () => {
        this.setState({ openSnackBar: false })
    }
    handlePopupClose = () => {
        this.setState({ openDeletePopup: false })

    }
    handleTransactionClose = () => {
        this.setState({ openAddPopup: false })

    }
    evaluationListClick = () => {
        const { classes } = this.props
        this.setState({
            list: [{
                "Amount": {
                    "name": "1,000",
                    subHeader1: "",
                    subHeader2: "",
                },
                "Date": {
                    "name": "11 Jun 2022",
                    subHeader1: "",
                    subHeader2: "",
                },

            },
            ], count: 2,
            enableIcon: false,
            // enableIcon: this.props.realEstateList.REStatus.name==2||this.props.bullionList.BullionStatus.name==2 ?false :true,

        })
        this.setState({
            grid: classes.valuationGrid,
            headerList: [{ label: "Valuation Amount", align: 'right' },
            { label: "Valuation Date", align: 'left' }],
            popupList: [{ "name": "Edit", "onClick": this.handleEdit },
            { "name": "Delete", "onClick": this.handleDelete },]
        })
    }
    handleEdit = () => {

    }
    handleDelete = () => {

    }

    handleSellPopupClose = () => {
        this.setState({
            openSellPopup: false,
        })
    }
    moreOptionCallBack = (item) => {
        if (this.props.isListing != "Bullion") {
            let valuationDateItem = item.BuyPrice.subHeader1;
            this.setState({
                ValuationsValueDate: valuationDateItem
            })
            let filterHeader = (item.REStatus.name == 2) ?
                [{ "name": "Delete Asset", "onClick": this.handleDeleteListing }]
                :
                [{ "name": "Edit Asset", "onClick": this.handleRedirectionDataentry },
                { "name": "Sell Asset", "onClick": this.sellClick },
                { "name": "Delete Asset", "onClick": this.handleDeleteListing },
                { "name": "Add Valuation", 'onClick': this.handleAddEvaluation }];
            this.setState({
                popupList: filterHeader,
            });
        }
        else {
            let valuationDateItem = item.InvestedDate.name;
            this.setState({
                ValuationsValueDate: valuationDateItem
            })
            let filterBullionHeader = (item.BullionStatus.name == 2) ?
                [{ "name": "Delete Asset", "onClick": this.handleDeleteListing }]
                :
                [{ "name": "Edit Asset", "onClick": this.handleRedirectionDataentry },
                { "name": "Sell Asset", "onClick": this.sellClick },
                { "name": "Delete Asset", "onClick": this.handleDeleteListing },
                { "name": "Add Valuation", 'onClick': this.handleAddEvaluation }];
            this.setState({
                popupList: filterBullionHeader,
            });
        }

    }
    render() {
        let dataEntryList;
        if (this.state.isFlag == true) {
            dataEntryList = this.props.realEstateList
            // .sort(function (a, b) {
            //     // return b.length - a.length;
                
            // });
        }
        else {
            dataEntryList = this.props.bullionList
            // .sort(function (a, b) {
            //     // return b.length - a.length;
            // });
        }
        const { classes } = this.props;
        try {
            return (
                <ErrorBoundary>
                    <Subheader heading={this.props.isListing != "Bullion" ? labels.productList.RealEstate : labels.productList.Bullion} description={this.state.isFlag == true ? labels.Transact.realEstate : labels.Transact.bullion} />
                    <div className={classes.flex}>
                        <div className={classes.sideSpace}></div>
                        <div className={classes.mainCard}>
                            <div style={{ textAlignLast: "right", marginLeft: 10, marginTop: 10, marginBottom: 10 }}>
                                <OutlinedButton text={this.props.isListing == "Bullion" ? labels.RealEstate.addBullion : labels.RealEstate.addRealEstate} onClick={() => this.navigationOfDataEntryPage()}
                                />
                            </div>
                            <DynamicList loader={this.props.isLoading} evaluationListClick={this.evaluationListClick} transactionListClick={this.transactionListClick} list={dataEntryList} grid={this.state.grid} header={this.state.headerList} count={this.props.isListing == "Bullion" ? 6 : 6} enableIcon={this.state.enableIcon} popupList={this.state.popupList} moreOptionCallBack={this.moreOptionCallBack}></DynamicList>
                        </div>
                        <div className={classes.sideSpace}></div>
                    </div>
                    <CommonSnackbar message={this.state.message} open={this.state.openSnackBar} severity={this.state.severity} handlesnanckClose={this.handleToastClose} />
                    <DeletePopup header={"Confirmation"} onClick={this.handleYesClick} loader={this.state.deletePopupLoader} open={this.state.openDeletePopup} text={labels.PmsAndAif.confirmationText} onClose={this.handlePopupClose} />
                    <ConfirmationPopup header={"Confirmation"} onClick={this.handleSellYesClick} loader={this.state.openSellLoader} open={this.state.openSellPopup} text={this.props.isListing != "Bullion" ?labels.RealEstate.RealEstateconfirmationSellText:labels.RealEstate.BullionconfirmationSellText} onClose={this.handleSellPopupClose} />
                    <AddSell
                        header={this.state.header}
                        location={this.props.location}
                        flag={this.state.flag}
                        open={this.state.openAddPopup}
                        id={this.state.id}
                        onClose={this.handleTransactionClose}
                        ValuationsValueDate={this.state.ValuationsValueDate}

                    />
                </ErrorBoundary>
            )

        } catch (err) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => {
    return {
        sessionID: state.Login.sessionID,
        clientID: state.Login.logindetails.ClientID,
        logindetails: state.Login.logindetails,
        realEstateList: state.RealEstate.realEstateList,
        bullionList: state.RealEstate.bullionList,
        isLoading: state.RealEstate.isLoading

    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getL5reportDetails,
        getRealEstateList,
        deleteListing,
        deleteBullionListing,
        getBullionList
    }, dispatch)
}
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(RealestateListing))
