import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { TradeL5IDDetails } from '../../redux/actions/login/loginaction'
import { Redirect } from 'react-router-dom'
import { changeUcccode } from '../../redux/actions/profile/profile'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { replaceNull } from '../../utils/commonfunction';
import Box from '@material-ui/core/Box';
import '../../utils/stylesheets/tabs/tabs.css'
import labels from '../../utils/constants/labels';
import Notificationlist from '../../components/list/notificationlist';
import Loader from '../../components/loader/loader';
import NoData from '../../components/nodata/nodata';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <div style={{
                        fontSize: '1rem',
                        fontWeight: 400,
                        lineHeight: 1.5,
                        letterSpacing: '0.00938rem',
                        marginBlockStart: '1.5rem',
                        marginBlockEnd: '1rem',
                        margin: '1.3rem',
                        // marginRight:'1rem'
                    }}>{children}</div>
                </Box>
            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        width: "100%",
        backgroundColor: theme.palette.background.paper,
    },
    indicator: {
        background: window.globalConfig.color.BROWN
    },

    tabs: {
        borderBottom: `1px solid ${window.globalConfig.color.BORDER}`,
        columnCount: '2',
        // columnRuleWidth:'70% 30%',
        // display: "", flexDirection: "row", 
        display: 'grid',
        gridTemplateColumns: "auto",
        // gap: '10px',

        background: window.globalConfig.color.WHITE,
        [theme.breakpoints.down(600)]: {
            // width: "100%",
            overflow: "scroll",
            display: 'grid',
            gridTemplateColumns: "auto",
            gap: '10px',
            // columnCount:'2',

        },
        '& .heighttab .MuiAppBar-colorDefault': {
            backgroundColor: 'revert',
        },

    },
    tabRoot: {
        "& .MuiTab-root:focus": {
            color: window.globalConfig.color.BROWN
        },
        "& .MuiTab-textColorPrimary.Mui-selected": {
            color: window.globalConfig.color.BROWN
        }
    },
    flexContainer: {
        [theme.breakpoints.down(1400)]: {
            minHeight: "67px"

        },
    },
    widthtab: {
        // width: "68%",
        zIndex: 0,
        [theme.breakpoints.down(1400)]: {
            minHeight: "67px",
        },
        [theme.breakpoints.down('xs')]: {
            minHeight: "67px",
        },
        [theme.breakpoints.between(1000, 1050)]: {
            // width: "68%",
            height: "40px",
            marginTop: "15px"
        }
    },
    dropDownPopUp: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '-2%', marginLeft: '2%'
        },
    },
    popupdesign: {
        // whiteSpace: 'nowrap',
        display: 'flex',
        // marginTop: "1%",
        [theme.breakpoints.down('1300')]: {
            minHeight: "67px",
            // whiteSpace: 'nowrap',
            display: 'flex',
        }
        // marginLeft: "15%"
    },

    accountMarginTop: {
        // marginTop:'-2%',
        [theme.breakpoints.down('601')]: {
            marginTop: '-3.5%',
        },
        [theme.breakpoints.between('400', '600')]: {
            marginLeft: '40%',
            width: "100%"
        },
        [theme.breakpoints.down('350')]: {
            marginLeft: "-12%",

        },
    },
    badge: {
        display: "flex",
        border: `1px solid ${window.globalConfig.color.BROWN}`,
        borderRadius: "50%",
        padding: 2,
        minWidth: 16,
        minHeight: 16,
        alignItems: "center",
        justifyContent: "center",
        fontSize: "x-small"
    },
    tabAlignment: {
        display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: 10
    }
}));
const Transaction = [{ status: "success", statusText: "SIP Successfully Proceed", statusDescription: "Your order for NIPPON SMALL CAP has been proceed", days: "1 Day ago" },
{ status: "warning", statusText: "Orders: Pending for Approval", statusDescription: "Your order NIPPON SMALL CAP is pending for approval", days: "2 Days ago" },
{ status: "error", statusText: "Upcoming SIP", statusDescription: "SIP of NIPPON SMALL CAP is on 20 June 2022. Please maintain sufficient Balance", days: "2 Days ago" },
{ status: "success", statusText: "SIP Successfully Proceed", statusDescription: "Your order NIPPON SMALL CAP is pending for approval", days: "2 Days ago" },
]
const Accounts = [{ status: "success", statusText: "RM and Support Numbers", statusDescription: "Contact us in case of any issues you face. We are just a call away", days: "1 Day ago" },
]
const advisory = [{ status: "success", statusText: "Financial Plan Shared", statusDescription: "Contact us in case of any issues you face. We are just a call away", days: "1 Day ago" },
]
const products = []
const other = []
function AlertTab(props) {

    const classes = useStyles(props);
    const [value, setValue] = React.useState(0);
    const [account, setAccount] = React.useState('');
    const [anchorEl, setAnchorEl] = React.useState('');
    const [accountnumber, setAccountNumber] = React.useState('');
    const [Ucccode, setUcccode] = React.useState('');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    let grpData = props.list.filter((ele, ind) => ind === props.list.findIndex(elem => elem.Category === ele.Category))
    let data = grpData.map((item) => {
        return {
            Category: item.Category,
            data: props.list.filter((val) => val.Category == item.Category)
        }
    })
    try {
        return (
            <div className={classes.root}>
                {props.loader ? <Loader page={'reportmodel'}></Loader> : data.length != 0 ? <>
                    <div className={classes.tabs} >
                        <AppBar position="static" color="default"
                            classes={{ root: classes.widthtab }}>
                            <Tabs

                                classes={{ indicator: classes.indicator, root: [classes.heighttab, classes.tabRoot].join(' '), flexContainer: classes.flexContainer }}
                                value={value}
                                onChange={handleChange}
                                indicatorColor="primary"
                                textColor="primary"
                                variant="scrollable"
                                scrollButtons={false}
                                aria-label="scrollable prevent tabs example"

                            >
                                {data.map((item, index) => {
                                    return <Tab label={<div className={classes.tabAlignment}>
                                        <p>{item.Category}</p>
                                        {item.data.length != 0 &&
                                            <div className={classes.badge}>{item.data.length}</div>}
                                    </div>} {...a11yProps(index)} />
                                })}

                            </Tabs>

                        </AppBar>
                    </div>
                    {data.map((item, index) => {
                        return <TabPanel value={value} index={index}>
                            {props.loader ? <Loader page={'reportmodel'}></Loader> :
                                <Notificationlist List={item.data} />}
                        </TabPanel>
                    })}
                </> : <NoData></NoData>}
            </div>
        );
    }
    catch (err) {

        return <Redirect to={labels.Path.PageNotFound} />
    }
}
const mapStateToProps = state => ({
    TradingL5ID: state.Login.TradingL5ID,
    ProfileDetails:    state.L5report.L5report.length != 0&&state.L5report.L5report!="NA"
    ? [...state.L5report.L5report.map((item) => {
        return { ...item, "Name": item.PortfolioName }
    })] : []
    ,
    L5report: state.L5report.L5report != null && state.L5report.L5report != undefined && state.L5report.L5report != "NA" && state.L5report.L5report.length != 0 ? [...state.L5report.L5report.filter((item) => item.L4_AccountTypeID != 3)?.map((item) => {
        return { ...item, "Name": item.PortfolioName }
    })] : []
    ,
    JointHolder: state.ProfileDetails.AllProfileDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.jointHolderDetails != "NA" ? state.ProfileDetails.AllProfileDetails.jointHolderDetails : [] : [],
    logindetails: state.Login.logindetails,
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ changeUcccode, TradeL5IDDetails }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(AlertTab)