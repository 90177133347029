import Networking from '../../utils/api/apiaccess';
import { EncryptClientID, Encryption } from '../../utils/commonfunction';
import labels from '../../utils/constants/labels';
import Apivalues from '../url/apiurl'
function getProfile(ClientID, EmailID) {
  let encryptClientID = Encryption(ClientID?.toString())

  let encryptEmailID = Encryption(EmailID)
  let data = {}
  if (ClientID == 0 && EmailID != "NA") {
    data.EmailID = encryptEmailID
  } else {
    data.ClientCode = encryptClientID
  }

  return Networking.PostApi(`${Apivalues.ProfileDetails}`, data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
  // return Networking.GetApi(`${Apivalues.ProfileDetails}?clientcode=${ClientID}`, '').then(result => { return result })
  //   .catch(function (error) {
  //     return { "status": "F", "message": labels.messages.apierror }
  //   });
}
export default getProfile;
