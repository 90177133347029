import * as types from '../../constants/actionsType';
import ChangePassword from '../../../api/login/changepassword';
export function PasswordChanges(email, Password, ClientID) {
    return (dispatch) => {
        PasswordChange(dispatch, email, Password, ClientID);
        // dispatch({
        //   type: types.GET_PROFITLOSS_DATA,
        //   payload: Summary,
        // })
    }
}
function PasswordChange(dispatch, email, Password, ClientID) {
    ChangePassword(email, Password, ClientID).then((data) => {
        if (data != undefined) {
            if (data.status == "S") {
                dispatch({
                    type: types.LOGIN_CHANGE,
                    payload: data,
                });
            }
        }
    })
}
