import * as types from '../../constants/actionsType'
import { bindActionCreators } from 'redux';
import { FormatColorReset } from '@material-ui/icons';
import { replaceNull } from '../../../utils/commonfunction';
import labels from '../../../utils/constants/labels';
const initialState = {
    assetallocation: [],
    assetallocationgrouped: [],
    totalassetallocation: [],
    isAssetAllocationLoading: false,
    isNetworkLow: false,

    //EQUITY

    // equity_assetallocation: [],
    // equity_assetallocationgrouped: [],
    // equity_totalassetallocation: [],
    // equity_isAssetAllocationLoading: false,
    // equity_isNetworkLow: false,


    //Bond


    //FD


    //GB

}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ASSET_ALLOCATION:
            let grp =action.payload.dataTable==null?[]: action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.AssetClassName === ele.AssetClassName))

            return {
                ...state,
                assetallocation: action.payload.dataTable != null ? replaceNull(action.payload.dataTable, labels.messages.NA) : state.assetallocation,
                assetallocationgrouped: grp.length != 0 ? replaceNull(grp, labels.messages.NA).map((item) => {
                    return {
                        "Category": item.AssetClassName, "GrandDebtPer": item.AssetDebtPer, "GrandDebtTotal": item.AssetDebtTotal, "GrandEquityPer": item.AssetEquityPer, "GrandEquityTotal": item.AssetEquityTotal, "GrandGoldPer": item.AssetGoldPer, "GrandGoldTotal": item.AssetGoldTotal, "GrandOtherPer": item.AssetOtherPer, "GrandOtherTotal": item.AssetOtherTotal, "GrandPerTotal": item.AssetTotalPer, "GrandTotal": item.AssetTotal, "data": action.payload.dataTable.sort((a, b) => {
                            var textA = a.SchemeName.toUpperCase();
                            var textB = b.SchemeName.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        }).filter((val) => val.AssetClassName === item.AssetClassName)
                    }
                }) : state.assetallocationgrouped
                ,
                totalassetallocation: action.payload.totalTable != null ? replaceNull(action.payload.totalTable, labels.messages.NA) : state.totalassetallocation
                , isAssetAllocationLoading: false
                , isNetworkLow: false
            }

        case types.EMPTY_ASSET_ALLOCATION:
            return {
                assetallocation: [],
                assetallocationgrouped: [],
                totalassetallocation: []
                , isAssetAllocationLoading: false
                , isNetworkLow: false
            }
        case types.LOADER_ASSET_ALLOCATION:
            return {
                ...state,
                isAssetAllocationLoading: action.payload,
                isNetworkLow: false
            }
        // case types.ASSET_ALLOCATION_NETWORK:
        //     return {
        //         ...state,
        //         isAssetAllocationLoading: false,
        //         isNetworkLow: true
        //     }
        // case types.EQUITY_ASSET_ALLOCATION:
        //     let equity_grp = action.payload.dataTable != null ? action.payload.dataTable.length != 0 ? action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.AssetClassName === ele.AssetClassName)) : []:[]

        //     return {
        //         ...state,
        //         equity_assetallocation: action.payload != null ? action.payload.dataTable != null? action.payload.dataTable.length != 0 ? replaceNull(action.payload.dataTable, labels.messages.NA) : []:[]:[],
        //         equity_assetallocationgrouped: action.payload != null ? action.payload.dataTable != null ? action.payload.dataTable.length != 0 ? replaceNull(equity_grp, labels.messages.NA).map((item) => {
        //             return {
        //                 "Category": item.AssetClassName, "GrandDebtPer": item.AssetDebtPer, "GrandDebtTotal": item.AssetDebtTotal, "GrandEquityPer": item.AssetEquityPer, "GrandEquityTotal": item.AssetEquityTotal, "GrandGoldPer": item.AssetGoldPer, "GrandGoldTotal": item.AssetGoldTotal, "GrandOtherPer": item.AssetOtherPer, "GrandOtherTotal": item.AssetOtherTotal, "GrandPerTotal": item.AssetTotalPer, "GrandTotal": item.AssetTotal, "data": action.payload.dataTable.sort((a, b) => {
        //                     var textA = a.SchemeName.toUpperCase();
        //                     var textB = b.SchemeName.toUpperCase();
        //                     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        //                 }).filter((val) => val.AssetClassName === item.AssetClassName)
        //             }
        //         }) : []:[]:[]
        //         ,
        //         equity_totalassetallocation: action.payload != null ? action.payload.totalTable != null ? action.payload.totalTable.length != 0 ? replaceNull(action.payload.totalTable, labels.messages.NA) : []:[]:[]
        //         , equity_isAssetAllocationLoading: false
        //         , equity_isNetworkLow: false
        //     }

        // case types.EMPTY_EQUITY_ASSET_ALLOCATION:
        //     return {
        //         ...state,
        //         equity_assetallocation: [],
        //         equity_assetallocationgrouped: [],
        //         equity_totalassetallocation: []
        //         , equity_isAssetAllocationLoading: false
        //         , equity_isNetworkLow: false
        //     }
        // case types.LOADER_EQUITY_ASSET_ALLOCATION:
        //     return {
        //         ...state,
        //         equity_isAssetAllocationLoading: action.payload,
        //         equity_isNetworkLow: false
        //     }
        default:
            return state
    }
}
export default reducer
