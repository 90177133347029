import * as types from '../../constants/actionsType';
import getAllShorttermtaxation from '../../../api/afrreport/shorttermtaxation'
import constants from '../../../utils/constants/apiconstant';
export function getShorttermDetails(ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, isELSS, flag) {
  return (dispatch) => {
    getAllShorttermAction(dispatch, ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, isELSS, flag);
  };
}
function getAllShorttermAction(dispatch, ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, isELSS, flag) {
  if (!isELSS) {
    dispatch({
      type: types.EMPTY_SHORT_TERM,
      payload: [],
    });
    dispatch({
      type: types.LOADER_SHORT_TERM,
      payload: true,
    });
  } else {
    dispatch({
      type: types.EMPTY_ELSS_MATURITY,
      payload: [],
    });
    dispatch({
      type: types.LOADER_ELSS_MATURITY,
      payload: true,
    });
  }
  getAllShorttermtaxation(ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, isELSS, L4ID)
    .then((data) => {
      // 
      // console.log(data,isELSS)
      if (!isELSS) {
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: ProductID, api: "ShortTerm" }],
        });
        // console.log(data)
        dispatch({
          type: types.SHORT_TERM,
          payload: data,
        });

      } else {
        dispatch({
          type: types.ELSS_MATURITY,
          payload: data,
        });
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: ProductID, api: "ShortTerm" }],
        });
      }
      //   if (data != undefined) {
      //     if (data.status == "S") {

      //     } else {

      //     }
      //   }
    })
    .catch((error) => {
      // dispatch({
      //   type: types.PREVENT_API_CALL,
      //   payload: [{ ProductID: ProductID, api: "ShortTerm" }],
      // });
      if (!isELSS) {
        dispatch({
          type: types.EMPTY_SHORT_TERM,
          payload: [],
        });
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: ProductID, api: "ShortTerm" }],
        });

      } else {
        dispatch({
          type: types.EMPTY_ELSS_MATURITY,
          payload: [],

        });
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: ProductID, api: "ShortTerm" }],
        });

      }
    })
}
