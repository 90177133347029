import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Menu from "../../components/menu/menu";
import Usercard from "../../components/profile/usercard";
// import Footer from '../../components/footer/footer';
import { withRouter } from "react-router";
import STPDetails from "./stpdetails";
import STPReview from "./stpreview";
import Common from "../../components/commonpaymentconfirm/common";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import {
  amountCommaSeperator,
  AmountValidation,
  NegativeFinding,
  numbersandDeciamlOnly,
  replaceNull,
  ordinal,
  SchemeCodeFinding,
} from "../../utils/commonfunction";
import { installmentValidation } from "../../utils/commonfunction";
import getSTPApidetails from "../../api/stp/stp";
import getPortfolioxraySchemeDetails from "../../api/portfolioxrayschemedetail/portfolioxrayschemedetail";
import labels from "../../utils/constants/labels";
import { Redirect } from "react-router";
import constants from "../../utils/constants/apiconstant";
import moment from "moment";
import secureStorage from "../../utils/securestorage";
import CommonSnackbar from "../../components/snackbar/snackbar";
import color from "../../utils/colors/colors";
import {
  SchemeChange,
  TypeChange,
} from "../../redux/actions/filter/reportsfilter";
import { bindActionCreators } from "redux";
import colors from "../../utils/colors/colors";
import { scrollToTopPage } from '../../utils/commonfunction';
import getSchemeDetails from "../../api/schemedetails/schemedetails";
import Deletepopup from "../../components/popup/deletepopup";
import getAllDeviceToken from "../../api/alert/getalldevicetoken";
import getSendPushNotification from "../../api/alert/sendpushnotification";
const styles = (theme) => ({
  collabel: {
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: "14px",
    textAlign: "left",
  },
  stpHeader: {
    height: 75,
    paddingLeft: "5.4%",
    // paddingTop: "5%",
    paddingRight: "6.5%",
    backgroundColor: window.globalConfig.color.PRIMARY,
    color: "red",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.between("600", "851")]: {
      height: 60,
      paddingTop: "10%",
    },
    [theme.breakpoints.down("600")]: {
      paddingLeft: "0%",
      marginTop: "45px",
    },
  },
  stpText: {
    textAlign: "left",
    fontSize: 25,
    fontWeight: "bolder",
    color: window.globalConfig.color.WHITE,
    // marginTop: "1.8%",
    marginLeft: "6%",
  },
  btnApply: {
    background: window.globalConfig.color.brown,
    marginLeft: "7px",
    padding: 0,
    lineHeight: "36px",
    color: window.globalConfig.color.WHITE,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    width: "100px",
    float: "right",
    borderRadius: "5px",
    border: "1px solid transparent",
    "&:hover": {
      background: window.globalConfig.color.lightBlue,
      color: window.globalConfig.color.WHITE,
    },
  },
  btnBack: {
    background: window.globalConfig.color.RosyBrown,
    marginLeft: "7px",
    padding: 0,
    lineHeight: "36px",
    color: "black",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    width: "100px",
    float: "right",
    borderRadius: "5px",
    border: "1px solid transparent",
  },
  userCardWidth: {
    // width: "33%",
    // [theme.breakpoints.down("1100")]: {
    //   width: "44%",
    // },
    // [theme.breakpoints.down("920")]: {
    //   width: "96%",
    // },
    // [theme.breakpoints.down("600")]: {
    //   width: "105%",
    // },
  },
  divMain1: {
    display: "grid",
    // flexDirection: "row",
    gap: "3%",
    // gridTemplateColumns: "10% 80% 10%",
    marginTop: "3%",
    marginLeft: "11%",
    marginRight: "10%",
    marginBottom: "4%",
    paddingBottom: "3%",

    [theme.breakpoints.down(920)]: {
      gridTemplateColumns: "100%",
      margin: "4%"

    },
  },
  divMain: {
    display: "grid",
    // flexDirection: "row",
    gap: "3%",
    gridTemplateColumns: "38% 59%",
    marginTop: "3%",
    marginLeft: "11%",
    marginRight: "10%",
    marginBottom: "4%",
    paddingBottom: "3%",
    // [theme.breakpoints.down(920)]: {
    //   flexDirection: "column",
    //   marginLeft: "6%",
    //   marginRight: "6%",
    // },
    [theme.breakpoints.down(920)]: {
      gridTemplateColumns: "100%",
      margin: "4%"
      // marginLeft: "0%",
      // marginRight: "4%",
    },
  },
  divDetail: {
    height: "-webkit-fill-available",
    // width: "63%",
    backgroundColor: window.globalConfig.color.WHITE,
    // marginLeft: "2.5%",
    marginBottom: "4%",
    border: `1px solid ${window.globalConfig.color.WHITE}`,
    boxShadow: "0 0 5px 8px rgba(200, 200, 250, 0.1)",
    paddingBottom: "30px",
    borderRadius: 6,
    // [theme.breakpoints.down(920)]: {
    //   width: "100%",
    // },
  },
  divDetail1: {
    height: "-webkit-fill-available",
    width: "100%",
    // display:"flex",
    // justifyContent:"center",
    backgroundColor: window.globalConfig.color.WHITE,
    border: `1px solid ${window.globalConfig.color.WHITE}`,
    boxShadow: "0 0 5px 8px rgba(200, 200, 250, 0.1)",
    paddingBottom: "30px",
    borderRadius: 6,
    [theme.breakpoints.down("920")]: {
      width: "100%",
    },
  },
  //Added by Celsia on 02 Jun 2021 for Mobile Responsive Started
  desktopResponsive: {
    display: "block !important",
    // [theme.breakpoints.down("600")]: {
    //   display: 'none !important',
    // },
  },
  stpHeader1: {
    height: 86,
    marginTop: "4.5%",
    paddingLeft: "3.4%",
    paddingTop: "2%",
    backgroundColor: window.globalConfig.color.PRIMARY,
    color: "red",
    display: "flex",
    flexDirection: "column",

  },
  stpText1: {
    textAlign: "left",
    fontSize: 18,
    fontWeight: "bolder",
    color: window.globalConfig.color.WHITE,
    marginTop: "10.6%",
  },
  // mobileResponsive: {
  //   display: 'none !important',
  //   [theme.breakpoints.down("600")]: {
  //     display: 'block!important',
  //   },
  // },
  divMainResponsive: {
    margin: "10px",
    paddingBottom: "7%",
  },
  divDetailResponsive: {
    height: "-webkit-fill-available",
    width: "100%",
    backgroundColor: window.globalConfig.color.WHITE,
    marginBottom: "4%",
    border: `1px solid ${window.globalConfig.color.BORDER}`,
    boxShadow: "0 0 10px 0 rgb(19 89 161 / 17%)",
    paddingBottom: "30px",
    borderRadius: "10px",
    marginTop: "10px",
  },
  //Ended
});
let status = "";
class STP extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      confirmFlag: false,
      paymentFlag: false,
      stpType: "M",
      amount: "",
      minimumInstalment: 1,
      instalment: "",
      erroramountmessage: "",
      errorinstalmentmessage: "",
      STPscheme: "",
      scheme: "",
      type: "",
      errorschememessage: "",
      selectedDate: "",
      currentDateBackUp: "",
      errorSTPmessage: "",
      errortypemessage: "",
      schemecodeerror: "",
      check: false,
      FromSchemeCd: "",
      folionumber: "",
      status: "",
      folioList: [],
      // SchemeDetail: [],
      startdateList: [],
      startdate: "",
      errorstartdatemessage: "",
      selecteddate: moment().format("DD MMM YYYY"),
      viewloader: false,
      message: "",
      toSchemeID: "",
      ToSchemeCd: "",
      open: false,
      selectedMappingDetails: "",
      orderID: "",
      BSESchemeType: "",
      totalTable: [],
      AMCID: "",
      UCC: "",
      data: {},
      toSchemeData: [],
      selected: false,
      firstStepLoader: false,
      openConfirmPopup: false,
      dateList: [],
      dropDownValue: 1, toSchemeList: []
    };
  }
  async componentDidMount() {
    //
    await this.getFolioList(this.props.location.state.data.SchemeID)
    await this.setState({
      STPscheme: this.props.location.state.data.SchemeName,
      AMCID: this.props.location.state.data?.AMCID,
      data: this.props.location.state.data,
      minimumInstalment: this.props.location.state.data.minimumInstalment,
      BSESchemeType: this.props.location.state.data.BSESchemeType,
    });
    try {
      this.findFromSchemeCode("Instalment")

    } catch (err) {
      // console.log(err)
    }
    scrollToTopPage();
  }
  getFolioList = (SchemeID) => {
    getPortfolioxraySchemeDetails(
      this.props.logindetails.ClientID,
      0,
      SchemeID,
      false, "STP"
    ).then((item) => {
      if (item.status == "F") {

      } else {
        if (item.length != 0) {
          var a = [];
          var b = "";
          var folio = "";
          if (item.dataTable != undefined && item.dataTable != null) {
            if (item.dataTable.length != 0) {
              var a = item.dataTable[0].DatesAllowed.split(",").map(
                (values, index) => {
                  return {
                    label: ordinal(Number(values)),
                    value: Number(values),
                  };
                }
              );
              let selectedMappingDetails = item.dataTable
              // .filter((item) => {
              //   if (item.STPFLAG.trim() == labels.investmentPopup.yFlag) {
              //     return item;
              //   }
              // });
              b = selectedMappingDetails[0]?.SchemeCode;
            }
          }
          let ucc = ""
          if (item.folioNumber != undefined) {
            if (item.folioNumber.length != 0) {
              folio = item.folioNumber[0].FolioNo;
              ucc = item.folioNumber[0].MutualFundUCC
            }
            this.setState({
              folioList: item.folioNumber,
              totalTable: item.totalTable,
              UCC: ucc
            });
          }
          if (item.schemeList != undefined) {
            if (item.schemeList.length != 0) {
              this.setState({
                toSchemeList: replaceNull(item.schemeList, "NA").map((item) => {
                  return {
                    label: item.SN,
                    value: item.SI,
                    data: item,
                  }
                })
              })
            }

          }
          this.setState({
            // SchemeDetail: item,
            startdateList: a,
            //  FromSchemeCd: b,
            folionumber: folio,
          });
        }
      }
    });
  }
  findFromSchemeCode = async (flag) => {
    // console.log(flag, this.state.type)
    var val = this.state.amount.replaceAll(",", "");
    var currancyvalue = val.replaceAll(labels.currency.rupeesymbol, "");
    // try {
    let fromSchemeValidationMessage = await SchemeCodeFinding(
      Number(currancyvalue),
      labels.investmentPopup.keySTP,
      this.state.data.ISIN,
      this.state.data.RTAProdCode,
      this.props.logindetails.ClientID, "STPOUT", this.state.type, this.state.STPscheme
    );
    // console.log(fromSchemeValidationMessage)
    if (fromSchemeValidationMessage.status === labels.investmentPopup.successMsg) {
      if (flag == "Instalment") {
        let date = fromSchemeValidationMessage.response.DatesAllowed.split(",")
        date = date.map((item) => {
          return { label: ordinal(item), value: item }
        })
        this.setState({ dateList: date, })
      }
      this.setState({ FromSchemeCd: fromSchemeValidationMessage.response?.SchemeCode, minimumInstalment: fromSchemeValidationMessage.response?.MINIMUM_INSTALLMENT_NUMBERS });
    } else {
      if (flag != "Instalment") {
        this.setState({
          schemecodeerror: fromSchemeValidationMessage.status,
          open: true,
        });
      }
    }
    // } catch (err) {
    //   console.log(err)
    // }

    return fromSchemeValidationMessage.status === labels.investmentPopup.successMsg
  }
  findToSchemeCode = async () => {
    var val = this.state.amount.replaceAll(",", "");
    var currancyvalue = val.replaceAll(labels.currency.rupeesymbol, "");
    let validationMessage = await SchemeCodeFinding(
      Number(currancyvalue),
      labels.investmentPopup.keySTP,
      this.state.toSchemeData.ISIN,
      this.state.toSchemeData.RTAProdCode,
      this.props.logindetails.ClientID, "STPIN", this.state.type, this.state.scheme
    );
    if (validationMessage.status === labels.investmentPopup.successMsg) {
      let selectedMappingDetails = validationMessage.response
      this.setState({ ToSchemeCd: selectedMappingDetails.SchemeCode });

    } else {

      this.setState({
        schemecodeerror: validationMessage.status,
        open: true,
      });
    }
    return validationMessage.status === labels.investmentPopup.successMsg
  }
  onClickOrder = async (value) => {
    //Confirm Success time
    this.setState({ firstStepLoader: true })
    var val = this.state.amount.replaceAll(",", "");
    var currancyvalue = val.replaceAll(labels.currency.rupeesymbol, "");
    let isValid = true;
    if (this.state.STPscheme == "") {
      isValid = false;
      this.setState({ errorSTPmessage: labels.messages.required });
    }
    if (this.state.scheme == "") {
      isValid = false;
      this.setState({ errorschememessage: labels.messages.required });
    }
    if (this.state.type == "") {
      isValid = false;
      this.setState({ errortypemessage: labels.messages.required });
    }
    if (this.state.selectedDate == "") {
      isValid = false;
      this.setState({ errorstartdatemessage: labels.messages.required });
    }
    if (currancyvalue == "") {
      isValid = false;
      this.setState({
        erroramountmessage: labels.messages.required,
      });
    } else if (Number(currancyvalue) < 1000) {
      isValid = false;
      this.setState({
        erroramountmessage: `Minimum Amount ${labels.currency.rupeesymbol} 1,000`,
      });
    }
    if (this.state.instalment == "") {
      isValid = false;
      this.setState({ errorinstalmentmessage: labels.messages.required });
    } else if (Number(this.state.instalment) < Number(this.state.minimumInstalment)) {
      isValid = false;
      this.setState({ errorinstalmentmessage: `Minimum Instalments ${this.state.minimumInstalment}` });
    }
    if (isValid) {
      isValid = await this.findFromSchemeCode()
    }
    if (isValid) {
      isValid = await this.findToSchemeCode()
    }
    // console.log(isValid)

    this.setState({ firstStepLoader: false })
    if (isValid) {
      this.setState({
        confirmFlag: value,
      });
      this.setState({
        errorinstalmentmessage: " ",
      });
    }
  };
  handleback = (value) => {
    this.setState({
      confirmFlag: value,
    });
  };

  onClickPayment = (value) => {
    if (!this.state.viewloader) {
      var val = this.state.amount.replaceAll(",", "");
      var currancyvalue = val.replaceAll(labels.currency.rupeesymbol, "");
      if (this.state.FromSchemeCd != "" && this.state.ToSchemeCd != "") {
        this.setState({ viewloader: true, openConfirmPopup: false });

        getSTPApidetails(
          this.state.selected,
          this.state.UCC,
          this.state.FromSchemeCd,
          this.state.ToSchemeCd,
          constants.STP.BuySellType,
          constants.STP.TransactionMode,
          this.state.folionumber,
          moment(this.state.selectedDate).format("DD/MM/YYYY"),
          this.state.type,
          Number(this.state.instalment),
          Number(currancyvalue),
          this.state.check ? "Y" : "N",
          this.props.logindetails.SubBrokerCode,
          this.state.BSESchemeType === 1 ? "N" : constants.STP.EUINDeclaration,
          this.state.BSESchemeType === 1 ? "" : constants.Common.EUINNumber,
          constants.STP.Remarks,
          this.state.data.SchemeID == undefined ? this.state.data.ID : this.state.data.SchemeID,
          this.state.toSchemeID,
          this.props.logindetails.ClientID,
          constants.Common.Source,
          ""

        ).then(async (res) => {
          this.setState({ viewloader: false });
          if (res.status == "S") {
            this.setState({
              paymentFlag: true,
              viewloader: false,
              message: res.message,
              orderID: res.orderID,
              status: res.status,
            });
          } else {
            this.setState({
              paymentFlag: true,
              viewloader: false,
              message: res.message,
              orderID: "",
              status: res.status,
              open: true,
              schemecodeerror: res.message
            });
          }
          if (this.props.logindetails.ClientID == 20427 || this.props.logindetails.ClientID == 21827) {
            // push notification on mobile.. commited by dulcy
            let data = {}
            data.ClientID = this.props.logindetails.ClientID
            await getAllDeviceToken(data).then(async (response) => {
              if (response.status == "S") {
                if (replaceNull(response.table1, "NA") != "NA") {

                  let params = {}
                  params.DeviceTokens = response.table1.map((item) => {
                    return item.DeviceToken
                  })
                  params.Title = res.status == "S" ? "Trading completed successfully" : "Trading failed"
                  params.Body = replaceNull(res.message, "NA")
                  await getSendPushNotification(params).then((response) => {

                  })
                }

              }
            })
          }
        });
      } else {
        this.setState({
          schemecodeerror: labels.investmentPopup.purchaseNotAllowed,
          open: true,
        });
      }
    }
  };

  handlesnanckClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };
  onchangeSTP = (event) => {
    let schemedetail = [];
    this.findFromSchemeCode("Instalment")
    schemedetail =
      this.props.schemeList != null

        ? this.props.schemeList.filter(
          (value) => value.SchemeID === event[0].value
        )
        : []

    let schemeUCC = schemedetail[0]?.UCC

    this.setState({ BSESchemeType: schemedetail.length != 0 ? schemedetail[0].BSESchemeType : "", AMCID: schemedetail.length != 0 ? schemedetail[0].AI : "", UCC: schemeUCC });
    //
    this.setState({ data: schemedetail[0] })
    this.getFolioList(event[0]?.value)
    this.setState({ STPscheme: event[0]?.label, errorSTPmessage: "", scheme: "", errorinstalmentmessage: "" });
    this.props.SchemeChange(
      event,
      this.props.schemeList
        .map((val) => {
          return { label: val.Scheme, value: val.SchemeID, isChecked: false };
        })
    );
  };
  onchangeScheme = async (val) => {
    //
    if (val != null) {

      this.setState({ toSchemeID: val.value, toSchemeData: val.data });

      this.setState({ scheme: val.label, errorschememessage: "" });
    } else {
      this.setState({
        toSchemeID: "",
        scheme: "",
      });
    }
  };
  // onchangeStartDate = (value) => {
  //   this.setState({
  //     selecteddate: moment(value).format("DD MMM YYYY"),
  //   });
  // };
  onchangeSTPtype = async (event) => {
    //  console.log(event[0].label.slice(0, 1))
    await this.setState({ type: event[0]?.label, stpType: event[0]?.label?.slice(0, 1), errortypemessage: "", selecteddate: "" });
    if (event[0]?.label == "Weekly") {
      this.setState({ dateList: labels.Date })
    } else {
      this.findFromSchemeCode("Instalment")
    }
  };
  handleStartDateChange = async (value) => {
    // console.log(moment().add(1, 'Q'))
    let todayDate = moment(new Date()).format("DD MMM YYYY");
    if (value.length != 0) {
      let dropdownDate = moment(value[0].value, "DD MMM YYYY").format(
        "DD MMM YYYY"
      );
      dropdownDate = moment(dropdownDate).isBefore(todayDate) ? moment(
        moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
      ).format("DD MMM YYYY") : dropdownDate
      this.setState({
        startDate: labels.Date.filter(i => i.value.includes(value[0].value))[0].label,
        dropDownValue: value[0].value,
        selectedDate: dropdownDate
      });
    }
  };
  Amountchange = (event) => {
    var val = event.target.value.replaceAll(",", "");
    var currancyvalue = val.replaceAll("₹", "").trim();
    if (currancyvalue.trim() == "") {
      this.setState({ amount: "" });
    } else {
      if (!numbersandDeciamlOnly(currancyvalue.trim(), 16)) {
        event.preventDefault();
      } else {
        this.setState({ erroramountmessage: "", amount: `${labels.currency.rupeesymbol} ${amountCommaSeperator(currancyvalue)}` });
      }
    }
  };
  onCheck = () => {
    this.setState({ check: !this.state.check });

  };
  instalmentchange = (event) => {
    if (installmentValidation(event.target.value)) {
      this.setState({ errorinstalmentmessage: "" });
      this.setState({ instalment: event.target.value });
    } else {
      event.preventDefault();
    }
  };
  handleClickfolio = (val) => {
    this.setState({ folionumber: val.FolioNo, UCC: val.MutualFundUCC });
  };
  handleApply = (date) => {
    this.setState({ startdate: moment(date).format("DD MMM YYYY"), errorstartdatemessage: "" });
  };
  toggleChange = () => {
    this.setState({ selected: !this.state.selected })
  }
  getConfirmation = () => {
    this.setState({ openConfirmPopup: true })
  }
  render() {
    const { classes } = this.props;
    try {
      return (
        <>
          <div
            style={{ height: "auto", maxHeight: "auto" }}
            className={classes.desktopResponsive}
          >
            {/* <Menu childFunc={this.props.childFunc} /> */}
            <div className={classes.stpHeader}>
              <div className={classes.stpText}>{labels.STP.STP}</div>
              <div style={{ display: "flex", cursor: this.state.viewloader ? "not-allowed" : "pointer", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: "5%", opacity: this.state.viewloader ? 0.5 : 1 }} onClick={() => {
                if (!this.state.viewloader) {
                  this.props.history.push({
                    pathname: labels.Path.MutualTab,
                    state: { tabValue: 1 }
                  })
                }
              }}>
                <ArrowBackIos
                  color={window.globalConfig.color.WHITE}
                  style={{ color: window.globalConfig.color.WHITE }}

                />
                <p
                  style={{

                    color: window.globalConfig.color.WHITE,

                  }}

                >
                  {"Back"}
                </p>
              </div>
            </div>
            {this.state.paymentFlag && (
              <div className={classes.divMain1}>
                <div></div>
                <div className={classes.divDetail1}>

                  <Common
                    page="STP"
                    UCC={this.state.UCC}
                    message={this.state.message}
                    orderID={this.state.orderID}
                    status={this.state.status}
                    firstorder={this.state.check ? "Yes" : "No"}
                    type={this.state.type}
                    startdate={this.state.selectedDate}
                    instalment={this.state.instalment}
                    folionumber={this.state.folionumber}
                    amount={this.state.amount}
                    scheme={this.state.scheme}
                    STPscheme={this.state.STPscheme}
                  ></Common>

                </div>
                <div></div>
              </div>

            )}
            {!this.state.paymentFlag && (
              <div className={classes.divMain}>
                <div className={classes.userCardWidth}>
                  <Usercard />
                </div>
                {/* {console.log(this.state.AMCID)} */}
                <div className={classes.divDetail}>
                  {!this.state.confirmFlag && (
                    <STPDetails
                      minimumInstalment={this.state.minimumInstalment}
                      selected={this.state.selected}
                      toggleChange={this.toggleChange}
                      AMCID={this.state.AMCID}
                      data={this.state.data}
                      stpType={this.state.stpType}
                      dateList={this.state.dateList}
                      totalTable={this.state.totalTable}
                      selecteddate={this.state.selectedDate}
                      folionumber={this.state.folionumber}
                      handleClickfolio={this.handleClickfolio}
                      folioList={this.state.folioList}
                      toSchemeList={this.state.toSchemeList}
                      dropDownValue={this.state.dropDownValue}
                      // onchangeStartDate={this.onchangeStartDate}
                      errorstartdatemessage={this.state.errorstartdatemessage}
                      startdate={this.state.startdate}
                      startdateList={this.state.startdateList}
                      handleStartDateChange={this.handleStartDateChange}
                      // SchemeDetail={this.state.SchemeDetail}
                      type={this.state.type}
                      check={this.state.check}
                      firstorder={this.onCheck}
                      handleApply={this.handleApply}
                      onchangeSTP={this.onchangeSTP}
                      onchangeScheme={this.onchangeScheme}
                      onchangeSTPtype={this.onchangeSTPtype}
                      Amountchange={this.Amountchange}
                      instalmentchange={this.instalmentchange}
                      errortypemessage={this.state.errortypemessage}
                      errorschememessage={this.state.errorschememessage}
                      errorSTPmessage={this.state.errorSTPmessage}
                      errorinstalmentmessage={this.state.errorinstalmentmessage}
                      erroramountmessage={this.state.erroramountmessage}
                      scheme={this.state.scheme}
                      STPscheme={this.state.STPscheme}
                      instalment={this.state.instalment}
                      amount={this.state.amount}
                      UCC={this.state.UCC}
                      loader={this.state.firstStepLoader}
                      confirmOrder={(value) => this.onClickOrder(value)}
                    />
                  )}
                  {this.state.confirmFlag && (
                    <STPReview
                      UCC={this.state.UCC}
                      loader={this.state.viewloader}
                      type={this.state.type}
                      selecteddate={this.state.selectedDate}
                      folionumber={this.state.folionumber}
                      firstorder={this.state.check ? "Yes" : "No"}
                      scheme={this.state.scheme}
                      STPscheme={this.state.STPscheme}
                      instalment={this.state.instalment}
                      amount={this.state.amount}
                      confirmOrder={(value) => this.handleback(value)}
                      confirmPayment={(value) => this.getConfirmation()}
                    />
                  )}
                </div>
              </div>
            )}
            {/* <Footer page='footer' /> */}
          </div>

          {/* this.onClickPayment(value) */}
          <Deletepopup header={"Confirmation"} text={labels.messages.placeOrderMsg} open={this.state.openConfirmPopup} onClose={() => this.setState({ openConfirmPopup: false })} onClick={this.onClickPayment}>

          </Deletepopup>
          <CommonSnackbar
            open={this.state.open}
            severity={"warning"}
            timer={9000}
            message={this.state.schemecodeerror}
            handlesnanckClose={this.handlesnanckClose}
          />
        </>
      );
    } catch (err) {
      // console.log(err)
      return <Redirect to={labels.Path.NotFound} />;
    }
  }
}
const mapStateToProps = (state) => {
  //
  return {
    schemeList: state.PortfolioXray.XrayData.filter((ele, ind) => ind === state.PortfolioXray.XrayData.findIndex(elem => elem.Scheme === ele.Scheme))
      .sort(function (a, b) {
        var textA = a.Scheme.toUpperCase();
        var textB = b.Scheme.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      }),
    filterData:
      state.Dropdown.dropdowndetails.scheme != null
        ? state.Dropdown.dropdowndetails.scheme.length != 0
          ? state.Dropdown.dropdowndetails.scheme
          : []
        : [],
    logindetails: state.Login.logindetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ SchemeChange, TypeChange }, dispatch);
};
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(STP))
);
