import { Asset } from "../mainasset"
const constants = {

    Common: {
        Source:Asset.source ,
        mutualfund:"Mutual Funds",
        equity:"Equity",
        FD:"Fixed Deposits",
        GB:"Bullion",
        gold:"Gold / Silver",
        AIF:"AIF / PMS",
        MLD:"MLD",
        RealEstate:"Real Estate",
        NPS:"NPS",
        Insurance:"Insurance",
        Bonds:"Bonds",
        Stocks:"Stocks / ETF",
        RealEstate:"Real Estate",
        EUINNumber: "E136635",
    },
    sipPurchase: {
        TransactionCode: "NEW",
        CancelTransactionCode:"CXL",
        InternalRefNo: "",
        TransMode: "P",
        DpTxnMode: "P",
        FrequencyAllowed: 1,
        Remarks: `Order from ${Asset.source}`,
        SubberCode: "",
        EuinVal: "Y",
        DPC: "N",
        RegId: null,
        IPAdd: "",
        Param1: "",
        Param2: "",
        Param3: "",
        Brokerage: 50
    },

    oneTime: {
        TransCode: "NEW",
        CancelTransCode:"CXL",
        OrderId: null,
        BuySell: "P",
        BuySellType: "FRESH",
        DPTxn: "P",
        Qty: 0,
        AllRedeem: "N",
        Remarks:  `Order from ${Asset.source}`,
        KYCStatus: "Y",
        RefNo: "",
        SubBrCode: "",
        EUINVal: "Y",
        MinRedeem: "N",
        DPC: "N",
        IPAdd: "",
        Param1: "",
        Param2: "",
        Param3: "",

    },

    Redemption: {
        CancelTransCode:"CXL",
        TransCode: "NEW",
        OrderId: null,
        BuySell: "R",
        BuySellType: "FRESH",
        DPTxn: "P",
        Qty: 0,
        Remarks: `Order from ${Asset.source}`,
        KYCStatus: "Y",
        RefNo: "",
        SubBrCode: "",
        EUINVal: "Y",
        MinRedeem: "N",
        DPC: "N",
        IPAdd: "",
        Param1: "",
        Param2: "",
        Param3: "",

    },
    SWP: {
        CancelTransCode:"CXL",
        BSESchemeCode: "02-DP",
        TransactionMode: "P",
        FolioNumber: "Folio2021",
        InternalRefNumber: "REF0012457",
        StartDate: "08/06/2021",
        NoOfWithdrawls: 0,
        FrequencyType: "Weekly",
        InstallmentAmount: 1000.100,
        InstallmentUnits: 0,
        FirstOrderToday: "N",
        SubBrokerCode: "627240002",
        EUINDeclaration: "Y",
        EUINNumber: "EUIN",
        Remarks:  `Order from ${Asset.source}`,
        SubBrokerARN: "ARNCode"
    },
    TransactionOrderStatus: {
        Segment: "BSEMF",
        MemberCode:17548
    },
    Switch: {
        CancelTransCode:"CXL",
        OrderId: 0,
        UserId: 1244,
        MemberId: "",
        ClientCode: "100F10011",
        FromSchemeCd: "IFG-HDFC-DP",
        ToSchemeCd: "IFGT-GR",
        BuySell: "SO",
        BuySellType: "Fresh",
        DPTxn: "P",
        OrderVal: 0,
        SwitchUnits: 0,
        AllUnitsFlag: "N",
        TransactionMode: "P",
        InternalRefNumber: "REF0012456",
        FrequencyType: "Weekly remove",
        NoOfTransfers: 10, InstallmentAmount: "100.100 ",
        FirstOrderToday: "N", EUINDeclaration: "Y",
        SubBrokerARN: "",
        FolioNo: "Folio2021",
        Remarks: `Order from ${Asset.source}`,
        KYCStatus: "Y",
        EUIN: "",
        EUINVal: "Y",
        MinRedeem: "N",
        IPAdd: "",
        Password: "",
        PassKey: "",
        Param1: "",
        Param2: "",
        Param3: "",
        SchemeID: "",
        ClientID: "20427",
        TransCode: "New"
    },

    STP: {
        CancelTransCode:"CXL",
        BuySellType: "Fresh",
        TransactionMode: "P",
        InternalRefNumber: "REF0012456",
        NoOfTransfers: 10,
        InstallmentAmount: 100.100,
        FirstOrderToday: "N",
        SubBrokerCode: "627240002",
        EUINDeclaration: "Y",
        EUINNumber: "E136635",
        Remarks: `Order from ${Asset.source}`,
        SubBrokerARN: "123"
    },
}
export default constants
