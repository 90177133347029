import * as types from '../../constants/actionsType';
import ResendOTP from '../../../api/login/resend';
export function ResendOTPs(email) {
    return (dispatch) => {
        PasswordChanges(dispatch, email);
        // dispatch({
        //   type: types.GET_PROFITLOSS_DATA,
        //   payload: Summary,
        // })
    }
}
function PasswordChanges(dispatch, email) {
    ResendOTP(email).then((data) => {
        if (data != undefined) {
            if (data.status == "S") {
                dispatch({
                    type: types.SENDOTP,
                    payload: data.clientID,
                });
            }
        }
    })
}
