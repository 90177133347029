import React, { Component } from 'react'
// import Tabs from '../tabs/tabs';
import '../../utils/stylesheets/layout/layout.css'
import Toolbar from '../../components/toolbar/toolbar';
import Label from '../../utils/constants/labels'
import { connect } from 'react-redux';
import { loginsession } from '../../redux/actions/login/loginaction'
import { bindActionCreators } from 'redux';
import secureStorage from '../../utils/securestorage';
import Tabs from '../tabs/RealEstate_tabs'
import constants from '../../utils/constants/apiconstant';
import { withStyles } from '@material-ui/core/styles';
import { scrollToTopPage } from '../../utils/commonfunction';
const styles = (theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "10% 80% 10%",
    marginTop: "5%",
    // [theme.breakpoints.down("920")]: {
    //   marginTop: "30%",
    // },
    // [theme.breakpoints.down("600")]: {
    //    marginTop: "55%",
    // }
  }
})
class RealEstate extends Component {

  componentDidMount() {
    scrollToTopPage()
  }

  render() {
    const { classes } = this.props
    return (
      <div >
        <Toolbar title={Label.sidemenu.RealEstate} productID={7} childFunc={this.props.childFunc} data={this.props.mfHeader2}></Toolbar>
        {/* <div className="reportDesign"> */}
        <div className={classes.root}>
          <div ></div>
          <div >
            <Tabs page={this.props.location} />
          </div>
          <div ></div>
        </div>
        {/* </div> */}

        {/* <Footer></Footer> */}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  date: state.Login.date,
  mfHeader2: state.Card.RealEstate,
  sessionID: state.Login.sessionID,
  clientID: state.Login.logindetails.ClientID,
  userID: state.Login.userID,
  date: state.Login.date,
  logindetails: state.Login.logindetails
})
export default withStyles(styles)(connect(mapStateToProps, null)(RealEstate))