import * as types from '../../constants/actionsType'
import { bindActionCreators } from 'redux';
const initialState = {
    capitalgain: [],
    isCapitalgainLoading: false,

    ///EQUITY
    equity_capitalgain: [],
    equity_isCapitalgainLoading: false,

    ///Bonds
    bonds_capitalgain: [],
    bonds_isCapitalgainLoading: false,

//     //GB
//     gb_capitalgain: [],
//     gb_isCapitalgainLoading: false,

//     //FD
//     fd_capitalgain: [],
//    fd_isCapitalgainLoading: false,
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.CAPITAL_GAIN:

            return {
                ...state,
                capitalgain: action.payload != null ? action.payload.data : state.capitalgain
                , isCapitalgainLoading: false
            }

        case types.EMPTY_CAPITAL_GAIN:
            return {
                ...state,
                capitalgain: [],
                isCapitalgainLoading: false
            }
        case types.LOADER_CAPITAL_GAIN:
            return {
                ...state,
                isCapitalgainLoading: action.payload
            }
        case types.EQUITY_CAPITAL_GAIN:

            return {
                ...state,
                equity_capitalgain: action.payload != null ? action.payload.data : state.capitalgain
                , equity_isCapitalgainLoading: false
            }

        case types.EMPTY_EQUITY_CAPITAL_GAIN:
            return {
                ...state,
                equity_capitalgain: [],
                equity_isCapitalgainLoading: false
            }
        case types.LOADER_EQUITY_CAPITAL_GAIN:
            return {
                ...state,
                equity_isCapitalgainLoading: action.payload
            }
            //Bonds
            case types.BONDS_TAXATION:

                return {
                    ...state,
                    bonds_capitalgain: action.payload != null ? action.payload.data : state.bonds_capitalgain
                    , bonds_isCapitalgainLoading: false
                }
    
            case types.EMPTY_BONDS_TAXATION:
                return {
                    ...state,
                    bonds_capitalgain: [],
                    bonds_isCapitalgainLoading: false
                }
            case types.LOADER_BONDS_TAXATION:
                return {
                    ...state,
                    bonds_isCapitalgainLoading: action.payload
                }
                // //Gb
                // case types.GB_TAXATION:

                //     return {
                //         ...state,
                //         gb_capitalgain: action.payload != null ? action.payload.data : state.gb_capitalgain
                //         , gb_isCapitalgainLoading: false
                //     }
        
                // case types.EMPTY_GB_TAXATION:
                //     return {
                //         ...state,
                //         gb_capitalgain: [],
                //         gb_isCapitalgainLoading: false
                //     }
                // case types.LOADER_GB_TAXATION:
                //     return {
                //         ...state,
                //         gb_isCapitalgainLoading: true
                //     }
                //     //FD
                //     case types.FD_TAXATION:

                //         return {
                //             ...state,
                //             fd_capitalgain: action.payload != null ? action.payload.data : state.fd_capitalgain
                //             , fd_isCapitalgainLoading: false
                //         }
            
                //     case types.EMPTY_FD_TAXATION:
                //         return {
                //             ...state,
                //             fd_capitalgain: [],
                //             fd_isCapitalgainLoading: false
                //         }
                //     case types.LOADER_FD_TAXATION:
                //         return {
                //             ...state,
                //             fd_isCapitalgainLoading: action.payload
                //         }
        default:
            return state
    }
}
export default reducer
