import React from "react";
import { Card } from "@material-ui/core";
import { withStyles } from "@material-ui/core";
// import { FaCircleChevronRight } from "react-icons/fa6";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import '../../components/profile/semicircle.css';
import NoData from "../nodata/nodata";
import { NegativeFinding } from "../../utils/commonfunction";

const styles = theme => ({
    card: {
        minHeight: "250px",
        width: "250px",
        border: "2px solid #AF762E",
        borderRadius: "13px",
        backgroundColor: "hsl(36.67deg 100% 92.94%)",

    },
    SubscriptionCardDiv: {
        // marginTop: "4%",
        display: "flex",
        marginBottom: "2%",
        gap: 15,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
    },
    cardAmount: {
        fontSize: '1.2em',
        color: "#AF762E",
        marginTop: "54%"
    },
    cardContent: {
        color: "#AF762E",
        // marginTop: "-8%",
        fontSize: 'small',
    },
    button: {
        borderColor: "#AF762E",
        color: "#AF762E",
        fontSize: "1em",
        borderRadius: "50px",
        // justifyContent: "space-between",
        width: "100%",
        height: "12%",
    },
    commonDiv: {
        // lineHeight: "50px",
        display: "grid",
        gridTemplateColumns: "repeat(4,1fr)",
        // flexDirection: "row",
        justifyContent: 'space-evenly',
        //padding: '3em',
        // [theme.breakpoints.down("sm")]: {
        //     padding: '3em',
        //     gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
        //     gap: '20px',
        // },
        gridGap: '1.3em'
    },
    buttonSubscribe: {
        marginRight: "14%",
        fontSize: "small"
    },
    iconSubscribe: {
        // marginRight: "-2%"
        display: "flex"
    }
})

class SubscriptionCard extends React.Component {
    constructor() {
        super();
        this.state = {
            index: 0,
            checked: true,
            planCode: " ",
            planName: " ",
            selected: true,
            financialPlanVisible: true,
            equityAdvisorVisible: false,
            allInOne: false,
            isValid: true,
        };
    }
    componentDidMount() {
        if (this.props.data.length != 0) {
            if (this.props.data.Data.filter((val) => val.intervals >= 6).length) {
                this.setState({ selected: true })
            } else {
                this.setState({ selected: false })
            }
        } else {
            this.setState({ selected: true })
        }

    }
    render() {
        const { classes, data } = this.props;
        // console.log(data)
        return (
            <div className={classes.commonDiv}>
                {data.isPlanSelected ? data.Data.filter((val) => this.props.toggle ? val.intervals >= 6 : val.intervals < 6).length == 0 ? <div style={{
                    lineHeight: 2, borderRadius: 10, display: "flex", padding: 14, flexDirection: "column", gap: 30
                }}> <NoData></NoData></div> : data.Data.filter((val) => this.state.selected ? val.intervals >= 6 : val.intervals < 6).map((item, index) => (
                    <Card variant="outlined" className={`${classes.card} semicircle`} style={{ display: "flex" }}>
                        <div className={classes.SubscriptionCardDiv}>
                            <div className={classes.cardAmount}>
                                {NegativeFinding(item.recurring_price, "", "", "", "rupee")}
                            </div>
                            <div className={classes.cardContent}>
                                {item.plan_name}
                            </div>
                            <button className="rounded-button" onClick={() => this.props.onClick(item.plan_code,
                                item.plan_name,
                                item.recurring_price)}>
                                <div className={classes.buttonSubscribe}>
                                    Subscribe
                                </div>
                                <div className={classes.iconSubscribe}>
                                    <ChevronRightIcon style={{ fontSize: "2.1em", backgroundColor: window.globalConfig.color.BROWN, color: window.globalConfig.color.WHITE, borderRadius: "50%" }} />
                                </div>
                            </button>
                            {/* <div style={{ padding: '0.6em' }}></div> */}
                        </div>
                    </Card>
                )) : ""}
            </div>
        )
    }
}
export default withStyles(styles)(SubscriptionCard)