import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import colors from '../../utils/colors/colors';
import { Button, Grid } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
const styles = theme => ({
  selectedList: {
    backgroundColor: window.globalConfig.color.RosyBrown,
    color: window.globalConfig.color.BROWN,
  },
  List: {
    '&:hover': {
      backgroundColor: window.globalConfig.color.RosyBrown,
      color: window.globalConfig.color.BROWN
    },
  },
  cardSpareaterLine: {
    height: 0.5,
    width: "100%",
    backgroundColor: window.globalConfig.color.BORDER,
  },
})
class CommonPopup extends React.Component {
  constructor() {
    super();
  }
  render() {
    const { classes } = this.props;
    return (
      <div style={this.props.container == "" ? { display: "flex" } : this.props.container} className={this.props.class == undefined ? "" : this.props.class}>
        <Button endIcon={<KeyboardArrowDownIcon />} onClick={this.props.handleClick} style={this.props.Buttonstyle == "" ? { display: "flex" } : this.props.Buttonstyle}>
          {this.props.slice != undefined ? this.props.text.length > 19 ? `${this.props.text.slice(0, 16)}...)` : this.props.text : this.props.text}
        </Button>
        {this.props.List.length != 0 &&
          <Menu
            id="simple-menu"
            style={{ zIndex: 0, position: "absolute" }}
            disableScrollLock={true}
            anchorEl={this.props.AnchorEl}
            keepMounted
            open={Boolean(this.props.AnchorEl)}
            onClose={this.props.handleClose}
          // onScroll={this.props.handleClose}
          // onWheel={this.props.handleClose}
          >
            {this.props.List.map((item, index) => {
              // console.log(item)
              return (<div key={index}>
                <MenuItem key={index} style={this.props.applyUccStyle == undefined ? { paddingLeft: item.L4_AccountTypeID != 3 && item.L5ID != 0 ? 25 : 10 } : this.props.applyUccStyle}
                  className={[this.props.selectedAccount == item.Value && classes.selectedList, classes.List].join(" ")}
                  onClick={() => this.props.handlelistclick(item)}>{item.Name}
                </MenuItem>
                <div className={classes.cardSpareaterLine}></div>
              </div>
              )
            })}
          </Menu>}
      </div>
    )
  }
}
export default withStyles(styles)(CommonPopup);
