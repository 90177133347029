import './App.css';
import Apps from '../src/containers/application/app';
import React from 'react';
import { Switch, Route, BrowserRouter } from 'react-router-dom';
// import { withSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import withClearCache from "./ClearCache";
import Snackbar from '@material-ui/core/Snackbar';
import reducer from './redux/reducer';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import labels from './utils/constants/labels';
import { PersistGate } from 'redux-persist/integration/react'
import { encryptTransform } from 'redux-persist-transform-encrypt';
import localForage from 'localforage';
const enhancers = [applyMiddleware(thunk)];
const composeEnhancers = process.env.NODE_ENV !== 'production'
  && typeof window === 'object'
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    shouldHotReload: false
  })
  : compose;
const persistConfig = {
  key: 'root',
  storage: localForage,
  transforms: [
    encryptTransform({
      secretKey: labels.apiKey.apiKeys,
      onError: function (error) {
        // Handle the error.
      },
    }),
  ],
  // storage,
}
const persistedReducer = persistReducer(persistConfig, reducer)
const store = createStore(persistedReducer, composeEnhancers(...enhancers))
class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newVersionAvailable: false,
    };
  }


  componentDidMount() {

  }

  render() {
    //render components
    let persistor = persistStore(store)

    return (<div className="App" id="app_scroll">
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>

            <Apps />
          </PersistGate>
        </Provider>
      </BrowserRouter>

    </div>)
  }
}
export default App
