import { NegativeFinding } from '../../../utils/commonfunction'
import * as types from '../../constants/actionsType'
import labels from '../../../utils/constants/labels'
const initialState = {
    headerdetails: [],
    mfHeader2: [],
    mfHeader1: [],
    mutualfund: [],
    equity: [],
    bonds: [],
    FD: [],
    GB: [],
    NPS: [],
    MLD: [],
    derivatives: [],
    Liquiloans: [],
    Aif: [],
    RealEstate: [],
    Insurance: [],
    PortfolioNetworth: "",
    Invested: "",
    Unrealised: "",
    marget: "",
    Dividend: "",
    Total: "",
    percentageunrealised: "",
    isHeaderLoading: false
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.PORTFOLIO_HEADER_DETAILS: {
            return {
                ...state,
                // mfHeader2: action.payload == "NA" ? [{ firstName: 'Portfolio Value', lastName: NegativeFinding(0, "", "", "", "rupee") },
                // { firstName: 'Invested Amount', lastName: NegativeFinding(0, "", "", "", "rupee") },
                // { firstName: 'Current Value', lastName: NegativeFinding(0, "", "", "", "rupee") },
                // { firstName: 'Unrealized G/L', lastName: NegativeFinding(0, "", "", "", "rupee") },
                // { firstName: 'Dividend', lastName: NegativeFinding(0, "", "", "", "rupee") },
                // { firstName: 'Net G/L (XIRR%)', lastName: `${labels.currency.rupeesymbol}0 (0%)` },
                // ] : [
                //     { firstName: 'Portfolio Value', lastName: NegativeFinding(action.payload[0].PortfolioValue, "", "", "", "rupee"), },
                //     { firstName: 'Invested Amount', lastName: action.payload.length == 0 ? 0 : NegativeFinding(action.payload[0].TotalInvestmentAmt, "", "", "", "rupee") },
                //     { firstName: 'Current Value', lastName: action.payload.length == 0 ? 0 : NegativeFinding(action.payload[0].CurrentValue, "", "", "", "rupee") },
                //     { firstName: 'Unrealized G/L', lastName: action.payload.length == 0 ? 0 : NegativeFinding(action.payload[0].CurrentGainloss, "", "", "", "rupee"), originalData: action.payload[0].TotalGainLoss },
                //     { firstName: 'Dividend', lastName: action.payload.length == 0 ? 0 : NegativeFinding(action.payload[0].TotalDividendEarned, "", "", "", "rupee") },
                //     { firstName: 'Net G/L (XIRR%)', lastName: action.payload.length == 0 ? 0 : NegativeFinding(action.payload[0].TotalGainLoss, "", "", "", "rupee"), val: "(" + action.payload[0].LiveXIRR + "%)", checkPercentage: action.payload[0].LiveXIRR, originalData: action.payload[0].TotalGainLoss },

                // ],
                Liquiloans: [{ firstName: 'Portfolio Value', lastName: NegativeFinding(0, "", "", "", "rupee") },
                { firstName: 'Invested Amount', lastName: NegativeFinding(0, "", "", "", "rupee") },
                { firstName: 'Current Value', lastName: NegativeFinding(0, "", "", "", "rupee") },
                { firstName: 'Current Gain', lastName: NegativeFinding(0, "", "", "", "rupee") },
                { firstName: 'Dividend', lastName: NegativeFinding(0, "", "", "", "rupee") },
                { firstName: 'Net G/L (XIRR%)', lastName: `${labels.currency.rupeesymbol}0 (0% )` },
                ],
            }
        }
        case types.GET_HEADERDETAILS:
            return {
                ...state,
                mfHeader1: action.payload.mfHeader2.filter((item) => item.ProductID == 0).length == 0 ? [] : [
                    { firstName: 'Portfolio Value 1', lastName: Object.keys(action.payload.mfHeader2[0]).length === 0 ? "0" : NegativeFinding(action.payload.mfHeader2[0].PortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Invested Amount', lastName: Object.keys(action.payload.mfHeader2[0]).length === 0 ? "0" : NegativeFinding(action.payload.mfHeader2[0].InvestmentValue, "", "", "", "rupee") },
                    { firstName: 'Unrealized G/L', lastName: Object.keys(action.payload.mfHeader2[0]).length === 0 ? "0" : NegativeFinding(action.payload.mfHeader2[0].UnRealizedGainLoss, "", "", "", "rupee") },
                    { firstName: 'Dividend', lastName: Object.keys(action.payload.mfHeader2[0]).length === 0 ? "0" : NegativeFinding(action.payload.mfHeader2[0].Dividend, "", "", "", "rupee") },
                    { firstName: 'Net G/L (XIRR%)', lastName: Object.keys(action.payload.mfHeader2[0]).length === 0 ? "0" : NegativeFinding(action.payload.mfHeader2[0].NetPL, "", "", "", "rupee"), val: " (" + action.payload.mfHeader2[0].UnRealizedGainLossPercent + "%)" },
                ],

                mfHeader2: action.payload.mfHeader2.filter((item) => item.ProductID == 0).length == 0 ? [] : [
                    { firstName: 'Portfolio Value 1', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 0).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 0)[0].PortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Invested Amount', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 0).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 0)[0].InvestmentValue, "", "", "", "rupee") },
                    // { firstName: 'Current Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 0).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 0)[0].MFPortFolioNetworth, "", "", "", "rupee")},
                    { firstName: 'Unrealized G/L', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 0).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 0)[0].UnRealizedGainLoss, "", "", "", "rupee"), originalData: action.payload.mfHeader2.filter((item) => item.ProductID == 0)[0].UnRealizedGainLoss  },
                    { firstName: 'Dividend', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 0).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 0)[0].Dividend, "", "", "", "rupee") },
                    { firstName: 'Net G/L(%)', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 0).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 0)[0].NetPL, "", "", "", "rupee"), val: " (" + action.payload.mfHeader2.filter((item) => item.ProductID == 0)[0].UnRealizedGainLossPercent + "%)", checkPercentage: action.payload.mfHeader2.filter((item) => item.ProductID == 0)[0].UnRealizedGainLossPercent },

                ],
                mutualfund: action.payload.mfHeader2.filter((item) => item.ProductID == 1).length == 0 ? [] : [
                    { firstName: 'Portfolio Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 1).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 1)[0].PortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Invested Amount', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 1).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 1)[0].InvestmentValue, "", "", "", "rupee") },
                    // { firstName: 'Current Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 1).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 1)[0].MFPortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Unrealized G/L', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 1).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 1)[0].UnRealizedGainLoss, "", "", "", "rupee") },
                    { firstName: 'Dividend', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 1).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 1)[0].Dividend, "", "", "", "rupee") },
                    { firstName: 'Net G/L (XIRR%)', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 1).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 1)[0].NetPL, "", "", "", "rupee"), val: " (" + action.payload.mfHeader2.filter((item) => item.ProductID == 1)[0].UnRealizedGainLossPercent + "%)", checkPercentage: action.payload.mfHeader2.filter((item) => item.ProductID == 1)[0].UnRealizedGainLossPercent },

                ],
                equity: action.payload.mfHeader2.filter((item) => item.ProductID == 2).length == 0 ? [] : [
                    { firstName: 'Portfolio Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 2).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 2)[0].PortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Invested Amount', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 2).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 2)[0].InvestmentValue, "", "", "", "rupee") },
                    // { firstName: 'Current Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 2).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 2)[0].MFPortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Unrealized G/L', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 2).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 2)[0].UnRealizedGainLoss, "", "", "", "rupee") },
                    { firstName: 'Dividend', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 2).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 2)[0].Dividend, "", "", "", "rupee") },
                    { firstName: 'Net G/L (XIRR%)', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 2).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 2)[0].NetPL, "", "", "", "rupee"), val: " (" + action.payload.mfHeader2.filter((item) => item.ProductID == 2)[0].UnRealizedGainLossPercent + "%)", checkPercentage: action.payload.mfHeader2.filter((item) => item.ProductID == 2)[0].UnRealizedGainLossPercent },

                ],
                bonds: action.payload.mfHeader2.filter((item) => item.ProductID == 3).length == 0 ? [] : [
                    { firstName: 'Portfolio Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 3).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 3)[0].PortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Invested Amount', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 3).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 3)[0].InvestmentValue, "", "", "", "rupee") },
                    // { firstName: 'Current Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 3).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 3)[0].MFPortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Unrealized G/L', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 3).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 3)[0].UnRealizedGainLoss, "", "", "", "rupee") },
                    { firstName: 'Interest', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 3).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 3)[0].Dividend, "", "", "", "rupee") },
                    { firstName: 'Net G/L (XIRR%)', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 3).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 3)[0].NetPL, "", "", "", "rupee"), val: " (" + action.payload.mfHeader2.filter((item) => item.ProductID == 3)[0].UnRealizedGainLossPercent + "%)", checkPercentage: action.payload.mfHeader2.filter((item) => item.ProductID == 3)[0].UnRealizedGainLossPercent },

                ],
                GB: action.payload.mfHeader2.filter((item) => item.ProductID == 4).length == 0 ? [] : [
                    { firstName: 'Portfolio Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 4).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 4)[0].PortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Invested Amount', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 4).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 4)[0].InvestmentValue, "", "", "", "rupee") },
                    // { firstName: 'Current Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 4).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 4)[0].MFPortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Unrealized G/L', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 4).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 4)[0].UnRealizedGainLoss, "", "", "", "rupee") },
                    { firstName: 'Dividend', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 4).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 4)[0].Dividend, "", "", "", "rupee") },
                    { firstName: 'Net G/L (XIRR%)', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 4).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 4)[0].NetPL, "", "", "", "rupee"), val: " (" + action.payload.mfHeader2.filter((item) => item.ProductID == 4)[0].UnRealizedGainLossPercent + "%)", checkPercentage: action.payload.mfHeader2.filter((item) => item.ProductID == 4)[0].UnRealizedGainLossPercent },

                ],
                FD: action.payload.mfHeader2.filter((item) => item.ProductID == 5).length == 0 ? [] : [
                    { firstName: 'Portfolio Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 5).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 5)[0].PortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Invested Amount', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 5).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 5)[0].InvestmentValue, "", "", "", "rupee") },
                    // { firstName: 'Current Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 5).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 5)[0].MFPortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Unrealized G/L', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 5).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 5)[0].UnRealizedGainLoss, "", "", "", "rupee") },
                    { firstName: 'Interest', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 5).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 5)[0].Dividend, "", "", "", "rupee") },
                    { firstName: 'Net G/L (XIRR%)', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 5).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 5)[0].NetPL, "", "", "", "rupee"), val: " (" + action.payload.mfHeader2.filter((item) => item.ProductID == 5)[0].UnRealizedGainLossPercent + "%)", checkPercentage: action.payload.mfHeader2.filter((item) => item.ProductID == 5)[0].UnRealizedGainLossPercent },

                ],
                derivatives: action.payload.mfHeader2.filter((item) => item.ProductID == 12).length == 0 ? [
                    { firstName: 'Portfolio Value', lastName: 0 },
                    { firstName: 'Invested Amount', lastName: 0 },
                    // { firstName: 'Current Value', lastName: 0 },
                    { firstName: 'Unrealized G/L', lastName: 0 },
                    { firstName: 'Interest', lastName: 0 },
                    { firstName: 'Net G/L (XIRR%)', lastName: 0 }

                ] : [
                    { firstName: 'Portfolio Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 12).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 12)[0].PortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Invested Amount', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 12).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 12)[0].InvestmentValue, "", "", "", "rupee") },
                    // { firstName: 'Current Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 12).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 12)[0].MFPortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Unrealized G/L', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 12).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 12)[0].UnRealizedGainLoss, "", "", "", "rupee") },
                    { firstName: 'Interest', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 12).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 12)[0].Dividend, "", "", "", "rupee") },
                    { firstName: 'Net G/L (XIRR%)', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 12).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 12)[0].NetPL, "", "", "", "rupee"), val: "(" + action.payload.mfHeader2.filter((item) => item.ProductID == 12)[0].UnRealizedGainLossPercent + "%)", checkPercentage: action.payload.mfHeader2.filter((item) => item.ProductID == 12)[0].UnRealizedGainLossPercent },

                ],
                RealEstate: action.payload.mfHeader2.filter((item) => item.ProductID == 7).length == 0 ? [] : [
                    { firstName: 'Portfolio Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 7).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 7)[0].PortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Invested Amount', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 7).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 7)[0].InvestmentValue, "", "", "", "rupee") },
                    // { firstName: 'Current Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 7).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 7)[0].MFPortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Unrealized G/L', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 7).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 7)[0].UnRealizedGainLoss, "", "", "", "rupee") },
                    { firstName: 'Dividend', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 7).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 7)[0].Dividend, "", "", "", "rupee") },
                    { firstName: 'Net G/L (XIRR%)', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 7).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 7)[0].NetPL, "", "", "", "rupee"), val: "(" + action.payload.mfHeader2.filter((item) => item.ProductID == 7)[0].UnRealizedGainLossPercent + "%)", checkPercentage: action.payload.mfHeader2.filter((item) => item.ProductID == 7)[0].UnRealizedGainLossPercent },

                ],
                NPS: action.payload.mfHeader2.filter((item) => item.ProductID == 8).length == 0 ? [] : [
                    { firstName: 'Portfolio Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 8).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 8)[0].PortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Invested Amount', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 8).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 8)[0].InvestmentValue, "", "", "", "rupee") },
                    // { firstName: 'Current Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 8).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 8)[0].MFPortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Unrealized G/L', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 8).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 8)[0].UnRealizedGainLoss, "", "", "", "rupee") },
                    { firstName: 'Dividend', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 8).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 8)[0].Dividend, "", "", "", "rupee") },
                    { firstName: 'Net G/L (XIRR%)', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 8).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 8)[0].NetPL, "", "", "", "rupee"), val: "(" + action.payload.mfHeader2.filter((item) => item.ProductID == 8)[0].UnRealizedGainLossPercent + "%)", checkPercentage: action.payload.mfHeader2.filter((item) => item.ProductID == 8)[0].UnRealizedGainLossPercent },

                ],
                Aif: action.payload.mfHeader2.filter((item) => item.ProductID == 6).length == 0 ? [{ firstName: 'Portfolio Value', lastName: 0 },
                { firstName: 'Invested Amount', lastName: 0 },
                // { firstName: 'Current Value', lastName: 0 },
                { firstName: 'Unrealized G/L', lastName: 0 },
                { firstName: 'Dividend/Payout', lastName: 0 },
                { firstName: 'Net G/L (XIRR%)', lastName: 0 },
                ] : [
                    { firstName: 'Portfolio Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 6).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 6)[0].PortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Invested Amount', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 6).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 6)[0].InvestmentValue, "", "", "", "rupee") },
                    // { firstName: 'Current Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 6).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 6)[0].MFPortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Unrealized G/L', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 6).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 6)[0].UnRealizedGainLoss, "", "", "", "rupee") },
                    { firstName: 'Dividend/Payout', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 6).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 6)[0].Dividend, "", "", "", "rupee") },
                    { firstName: 'Net G/L (XIRR%)', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 6).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 6)[0].NetPL, "", "", "", "rupee"), val: "(" + action.payload.mfHeader2.filter((item) => item.ProductID == 6)[0].UnRealizedGainLossPercent + "%)", checkPercentage: action.payload.mfHeader2.filter((item) => item.ProductID == 6)[0].UnRealizedGainLossPercent },

                ],
                Liquiloans: [{ firstName: 'Portfolio Value', lastName: NegativeFinding(0, "", "", "", "rupee") },
                { firstName: 'Invested Amount', lastName: NegativeFinding(0, "", "", "", "rupee") },
                // { firstName: 'Current Value', lastName: NegativeFinding(0, "", "", "", "rupee") },
                { firstName: 'Current Gain', lastName: NegativeFinding(0, "", "", "", "rupee") },
                { firstName: 'Dividend', lastName: NegativeFinding(0, "", "", "", "rupee") },
                { firstName: 'Net G/L (XIRR%)', lastName: `${labels.currency.rupeesymbol}0 (0%)` },
                ],
                MLD: action.payload.mfHeader2.filter((item) => item.ProductID == 10).length == 0 ? [] : [
                    { firstName: 'Portfolio Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 10).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 10)[0].PortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Invested Amount', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 10).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 10)[0].InvestmentValue, "", "", "", "rupee") },
                    // { firstName: 'Current Value', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 10).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 10)[0].MFPortFolioNetworth, "", "", "", "rupee") },
                    { firstName: 'Unrealized G/L', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 10).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 10)[0].UnRealizedGainLoss, "", "", "", "rupee") },
                    { firstName: 'Dividend', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 10).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 10)[0].Dividend, "", "", "", "rupee") },
                    { firstName: 'Net G/L (XIRR%)', lastName: action.payload.mfHeader2.filter((item) => item.ProductID == 10).length == 0 ? 0 : NegativeFinding(action.payload.mfHeader2.filter((item) => item.ProductID == 10)[0].NetPL, "", "", "", "rupee"), val: "(" + action.payload.mfHeader2.filter((item) => item.ProductID == 10)[0].UnRealizedGainLossPercent + "%)", checkPercentage: action.payload.mfHeader2.filter((item) => item.ProductID == 10)[0].UnRealizedGainLossPercent },

                ],
                PortfolioNetworth: action.payload.mfHeader2.filter((item) => item.ProductID == 0).length == 0 ? "0" : Number(action.payload.mfHeader2.filter((item) => item.ProductID == 0)[0].PortFolioNetworth),
                Invested: action.payload.mfHeader2.filter((item) => item.ProductID == 0).length == 0 ? "0" : Number(action.payload.mfHeader2.filter((item) => item.ProductID == 0)[0].InvestmentValue),
                Market: action.payload.mfHeader2.filter((item) => item.ProductID == 0).length == 0 ? "0" : Number(action.payload.mfHeader2.filter((item) => item.ProductID == 0)[0].MFPortFolioNetworth),
                Unrealisedvalue: action.payload.mfHeader2.filter((item) => item.ProductID == 0).length == 0 ? "0" : Number(action.payload.mfHeader2.filter((item) => item.ProductID == 0)[0].UnRealizedGainLoss),
                Dividend: action.payload.mfHeader2.filter((item) => item.ProductID == 0).length == 0 ? "0" : Number(action.payload.mfHeader2.filter((item) => item.ProductID == 0)[0].Dividend),
                Total: action.payload.mfHeader2.filter((item) => item.ProductID == 0).length == 0 ? "0" : Number(action.payload.mfHeader2.filter((item) => item.ProductID == 0)[0].NetPL),
                percentageunrealised: action.payload.mfHeader2.filter((item) => item.ProductID == 0).length == 0 ? "0" : Number(action.payload.mfHeader2.filter((item) => item.ProductID == 0)[0].UnRealizedGainLossPercent)
            }
        case types.EMPTY_CARD:
            return {
                headerdetails: [],
                mfHeader2: [],
                mfHeader1: [],
                mutualfund: [],
                equity: [],
                bonds: [],
                FD: [],
                GB: [],
                NPS: [],
                MLD: [],
                Liquiloans: [],
                Aif: [],
                RealEstate: [],
                Insurance: [],
                PortfolioNetworth: "",
                Invested: "",
                Unrealised: "",
                marget: "",
                Dividend: "",
                Total: "",
                percentageunrealised: "",
                isHeaderLoading: false
            }
        case types.LOADER_HEADERDETAILS:
            return {
                ...state,
                isHeaderLoading: action.payload
            }
        default:
            return state
    }
}
export default reducer