import * as types from '../../constants/actionsType'
import { bindActionCreators } from 'redux';
const initialState = {
    trackEquity: [], isLoading: false
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.TRACK_DETAILS:
            return {
                ...state,
                trackEquity: action.payload
            }
        case types.EMPTY_TRACK_DETAILS:
            return {
                ...state,
                trackEquity: [],
                isLoading: false
            }
        case types.LOADER_TRACK_DETAILS:
            return {
                ...state,
                isLoading: action.payload
            }
        default:
            return state
    }
}
export default reducer
