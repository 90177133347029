import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import Summary from '../../components/summary/index';
import Portifolio from '../portfolio-x-ray/portfolio-x-ray'
import Transaction from '../transaction/transactionreport'
import '../../utils/stylesheets/tabs/tabs.css'
import secureStorage from '../../utils/securestorage';
import labels from '../../utils/constants/labels';
import Label from '../../utils/constants/labels';
import constants from '../../utils/constants/apiconstant';
import combineStyle from "../../utils/stylesheets/combainstyle/combainstyle";
import commonstyletable from "../../utils/stylesheets/table/commontablestyle";
import { replaceNull, sortArray } from '../../utils/commonfunction';
// import {
//   bondsFilterChange,
// } from "../../redux/actions/portfolioxray/portfolioxray";
import { MLDFilterChange } from '../../redux/actions/portfolioxray/portfolioxray';
import { bindActionCreators } from "redux";
import { mld_transactionfilter, } from '../../redux/actions/transaction/actions';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Typography>{children}</Typography>      
        <div
          style={{
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: '0.00938rem',
          }}
        >{children}</div>

      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const Styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
  },
  tabRoot: {
    "& .MuiTab-root:focus": {
      color: window.globalConfig.color.BROWN
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: window.globalConfig.color.BROWN
    }
  },
  portfolioXraySevenSplitContainer: {
    display: "grid",
    gridTemplateColumns: "35% 16% 16% 16% 16%",
    marginLeft: 20,
    [theme.breakpoints.down("920")]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr) !important',
      gap: 1,
      gridTemplateRows: 'auto',
      paddingRight: 10,
      gridTemplateAreas: `"schemeFirstGrid  schemeFirstGrid   schemeFifthGrid"
      "schemeSecondGrid . schemeSixthGrid"
      "schemeThirdGrid . investedAmountGrid"`,
      "& .schemeFourthGrid": {
        display: "none"
      }
    },
  },
  mldTransactionFifthContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr)",
    marginLeft: "20px",
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr)",
      gridTemplateAreas: `"${"dategrid"} ${"dategrid"}"
      "${"schemegrid"} ${"schemegrid"}"
      "${"assetClassgrid"} ${"transactionTypegrid"}"
      "${"folioNumbergrid"} ${"unitgrid"}"
      "${"navgrid"} ${"amountgrid"}"`,
      padding: 10,
      gap: 10
    }
    //     gridTemplateAreas: `"schemeFirstGrid  . schemeFifthGrid"
    // "schemeSecondGrid . schemeSixthGrid"
    // "schemeThirdGrid . investedAmountGrid"`,

  },
  portfolioXrayCatrgorySevenSplitContainer: {
    display: "grid",
    gridTemplateColumns: "35% 16% 16% 16% 16% !important",
    // paddingLeft: "10px"
    marginLeft: "20px",
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr) !important',
      gridTemplateAreas: `"categorySchemeGrid categoryInvestedGrid"
      ". categoryNetpl"
     `,
    }
  },

});
const portfolioxraytitle = [{ "name": labels.Tableheader.AssetName }, { "name": labels.Tableheader.InvestedValue, "subheader": labels.Tableheader.amount }, { "name": labels.Tableheader.CurrentValue, "subheader": labels.Tableheader.Subheadingcurrent }, { "name": labels.Tableheader.redemption, "subheader": "Dividend" }, { "name": labels.Tableheader.Net, "subheader": labels.Tableheader.SubheadingNet }]
class MLDTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    }
  }

  componentDidMount() {
    document.title = window.globalConfig.companyDetails.companyName + " - Structured Products"
    const { page, xray } = this.props;
    // 
    // console.log(page)
    //if (prev.page != page) {
    if (page.state != undefined) {
      // if (prev.page.state.tabValue != page.state.tabValue) {
      this.setState({ value: page.state.tabValue })
      // }
    }
    //}

  }

  componentDidUpdate(prev) {
    const { page } = this.props;
    // 

    if (prev.page != page) {
      if (prev.page.state != undefined) {
        if (prev.page.state.tabValue != page.state.tabValue) {
          this.setState({ value: page.state.tabValue })
        }
      }
    }
    //

  }


  handleChange = (event, newValue) => {
    // 

    this.setState({ value: newValue })
  };
  render() {
    //   
    const { classes } = this.props;
    try {
      return (
        <div className={classes.root}>
          <AppBar position="static" color="default">
            <Tabs
              value={this.state.value}
              onChange={this.handleChange}
              indicatorColor="primary"
              classes={{ root: classes.tabRoot }}
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              TabIndicatorProps={{ style: { background: window.globalConfig.color.BROWN } }}>
              <Tab label="Summary" {...a11yProps(0)} />
              <Tab label="Portfolio X-Ray" {...a11yProps(1)} />
              <Tab label="Transactions" {...a11yProps(2)} />
              {/* <Tab label="Profit & Loss" {...a11yProps(3)} /> */}
            </Tabs>
          </AppBar>
          <TabPanel value={this.state.value} index={0}>
            <Summary CategoryAllocation={labels.summaryPage.CategoryAllocation}
              Deptdata={this.props.Deptdata}
              splitByInstrument={labels.summaryPage.splitByMarketCap}
              EquityFundSector={labels.summaryPage.EquitySector}
              flag={'mld'}
              barChartData={this.props.barChartData}
              chart={this.props.chart}
              showChart={false}
              isLoadingportfolioSummary={this.props.isLoadingportfolioSummary}
              isLoadingportfolioChart={this.props.isLoadingportfolioChart}
              categorySummary={this.props.categorySummary}
              isLoadingcategorySummary={this.props.isLoadingcategorySummary}
              DonutData={this.props.DonutData}
              grandtotal={[labels.messages.grandtotal, labels.currency.rupeesymbol + this.props.grandtotalsummary, "", this.props.categorySummary.length == 0 ? "0%" : "100.0%"]}
              EquityLoading={this.props.isLoadingMarketCapEquity}
              DebtLoading={this.props.isLoadingMarketCapDebt}
              header={"MLD"}
              tableHeader={[Label.summaryPage.Category, Label.summaryPage.Amount, Label.summaryPage.categoryPercentage, Label.summaryPage.holdingPercentage]
              }
            />
          </TabPanel>
          <TabPanel value={this.state.value} index={1}>
            <Portifolio
              grid={classes.mldFifthSplitContainer}
              flag={'mld'}
              externalClasses={classes}
              hideGrandTotalUnitsColumn={false}
              Total={labels.ProfitLoss.Total}
              MarketValue={labels.Tableheader.MarketValue}
              CurrentGain={labels.Tableheader.CurrentValue}
              amountLabel={labels.Tableheader.price}
              units={labels.Tableheader.Quantity}
              Subheadingunits={labels.Tableheader.Subheadingprice}
              InvestedValue={labels.Tableheader.InvestedValue}
              AsOn={labels.Tableheader.AsOn}
              totaltext={labels.Tableheader.totaltext}
              Sip={labels.Tableheader.Sip}
              xray={this.props.xray}
              header={"Asset"}
              List={this.props.List}
              filterSubmit={this.props.MLDFilterChange}
              xraysort={this.props.xraysort}
              xrayfilter={this.props.xrayfilter}
              groupXray={this.props.groupXray}
              isLoadinggroupXray={this.props.isLoadinggroupXray}
              unrealised={this.props.unrealised}
              margetvalue={this.props.margetvalue}
              investedvalue={this.props.investedvalue}
              unrealisedpercentage={this.props.unrealisedpercentage}
              catchmessage={this.props.catchmessage}
              grandtotal={this.props.grandtotal}
              grandtotalnetpl={this.props.grandtotalnetpl}
              grandtotaltotaldividend={this.props.grandtotaltotaldividend}
              grandtotalinvestmentvalue={this.props.grandtotalinvestmentvalue}
              grandtotalmarketvalue={this.props.grandtotalmarketvalue}
              grandtotalunrealised={this.props.grandtotalunrealised}
              logindetails={this.props.logindetails}
              isXrayLoading={this.props.isXrayLoading}
              Load={this.props.Load}
              TransactionLoad={this.props.TransactionLoad}
              xcelLoad={this.props.xcelLoad}
              TransactionxcelLoad={this.props.TransactionxcelLoad}
              dropdowndetails={this.props.dropdowndetails}
              title={portfolioxraytitle}
              schemeList={this.props.schemeList} />
          </TabPanel>
          <TabPanel value={this.state.value} index={2}>
            {/* TRANSACTION */}
            <Transaction grid={classes.mldTransactionFifthContainer}
              ReportList={this.props.financialYearsList.length == 0 ? [] : sortArray([{ label: "All", value: 0, isChecked: false }, ...this.props.financialYearsList.map((item, index) => {
                return { ...item, label: item.Year, value: index + 1, isChecked: false }
              }),], "ID", "DESC")}

              title={[{ 'name': labels.transactionSummary.date }, { 'name': labels.Tableheader.AssetName }, { 'name': labels.transactionSummary.assetClass }, { 'name': labels.transactionSummary.transactionType, "subheader": labels.transactionSummary.TransactionNature }, { 'name': labels.transactionSummary.contractno }, { 'name': "" }, { 'name': "" }, { 'name': labels.transactionSummary.amount }]}
              flag={'mld'} isLoadingtransactionList={this.props.isLoadingtransactionList} L5ID={this.props.L5ID} maxDate={this.props.maxDate} minDate={this.props.minDate}
              schemeName={labels.transactionSummary.scripName} TransactionxcelLoad={this.props.TransactionxcelLoad} TransactionLoad={this.props.TransactionLoad} logindetails={this.props.logindetails} catchmessage={this.props.catchmessage}
              date={this.props.date} userID={this.props.userID} clientID={this.props.clientID} sessionID={this.props.sessionID}
              transactionList={this.props.transactionList != "NA" && this.props.transactionList.length != 0 ? this.props.transactionList : []}
              dropdowntransaction={this.props.dropdowntransaction}
              List={this.props.dropdowntransaction}
              filterSubmit={this.props.mld_transactionfilter}
              header={"Transaction"}
            filterHeader={"Asset"}
            />
          </TabPanel>


        </div>
      );
    } catch (err) {
      console.log(err)
    }

  }
}
const mapStateToProps = state => {
  // //
  return {

    //COMMON STATE
    sessionID: state.Login.sessionID,
    clientID: state.Login.logindetails.ClientID,
    userID: state.Login.userID,
    date: state.Login.date,
    logindetails: state.Login.logindetails,
    L5ID: state.Login.L5ID,

    //TRANSACTION
    transactionList: state.transactionList.mld_transactionList,
    dropdowntransaction: state.transactionList.mld_dropdowntransaction,
    isLoadingtransactionList: state.transactionList.mld_isLoadingtransactionList,
    catchmessage: state.transactionList.mld_catchmessage,
    TransactionLoad: state.transactionList.mld_isLoadingPdf,
    TransactionxcelLoad: state.transactionList.mld_isLoadingExcel,
    minDate: state.transactionList.mld_minDate,
    maxDate: state.transactionList.mld_maxDate,

    //SUMMARY
    barChartData: state.Summary.mld_summary != undefined ? state.Summary.mld_summary : [],
    chart: state.Summary.mld_chart != undefined ? state.Summary.mld_chart : [],
    categorySummary: state.Summary.mld_categorySummary != undefined ? state.Summary.mld_categorySummary : [],
    isLoadingcategorySummary: state.Summary.mld_isLoadingcategorySummary != undefined ? state.Summary.mld_isLoadingcategorySummary : false,
    DonutData: state.Summary.mld_Donut != undefined ? state.Summary.mld_Donut : [],
    grandtotalsummary: state.Summary.mld_grandtotalIV != undefined ? state.Summary.mld_grandtotalIV : [],
    Deptdata: state.Summary.mld_getdeptdata,
    isLoadingportfolioChart: state.Sample.isLoadingportfolioChart,
    isLoadingportfolioSummary: state.Sample.isLoadingportfolioSummary,
    isLoadingMarketCapEquity: state.Summary.isLoadingMarketCapEquity,
    isLoadingMarketCapDebt: state.Summary.isLoadingMarketCapDebt,



    //PORTFOLIO-X-RAY
    xray: state.PortfolioXray.mld_xray,

    isLoadinggroupXray: state.PortfolioXray.mld_isLoadinggroupXray,
    List: state.PortfolioXray.mld_XrayData.length == 0 ? [] : state.PortfolioXray.mld_XrayData.filter(
      (ele, ind) =>
        ind ===
        state.PortfolioXray.mld_XrayData.findIndex((elem) => elem.Scheme === ele.Scheme)
    ),
    unrealised: state.Card.mfHeader2.filter((val) => val.firstName === "Unrealised G / L (%)").map((val) => {
      return val.lastName
    }),
    margetvalue: state.Card.mfHeader2.filter((val) => val.firstName === "MF Market Value").map((val) => {
      return val.lastName
    }),
    investedvalue: state.Card.mfHeader2.filter((val) => val.firstName === "Investment Value").map((val) => {
      return val.lastName
    }),
    unrealisedpercentage: state.Card.mfHeader2.filter((val) => val.firstName === "Unrealised G / L (%)").map((item) => {
      return item.val
    }),

    logindetails: state.Login.logindetails,

    dropdowndetails: state.Dropdown.dropdowndetails.scheme,
    financialYearsList: replaceNull(state.Dropdown.dropdowndetails.financialYearsList, "").length != 0 ? state.Dropdown.dropdowndetails.financialYearsList : [],

    // xray: state.PortfolioXray.xraysort,
    schemeList: state.PortfolioXray.mld_XrayData.sort(function (a, b) {
      var textA = a.Scheme.toUpperCase();
      var textB = b.Scheme.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }),

  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {

      MLDFilterChange,
      mld_transactionfilter,

    },
    dispatch
  );
};
const mergestyles = combineStyle(Styles, commonstyletable);
export default withStyles(mergestyles)(connect(mapStateToProps, mapDispatchToProps)(MLDTab))