import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'

//action created by dulcy
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
import { Asset } from '../../utils/mainasset';
function BennyDropAPI(AccountNumber, IFSC) {


    //   var raw = JSON.stringify({
    //     "id_number": AccountNumber,
    //     "Ifsc": IFSC,


    //   });


    //   return Networking.PostApi(ApiValues.BennyDrop, raw).then(result => { return result })
    //   .catch(function (error) {
    //     return { "status": "F", "message": labels.messages.apierror }
    //   });

    var data = JSON.stringify({
        "id_number": AccountNumber,
        "ifsc": IFSC
    });

    var config = {
        method: 'post',
        url: 'https://kyc-api.aadhaarkyc.io/api/v1/bank-verification/',
        headers: {
            'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2MjUwMzg1NTAsIm5iZiI6MTYyNTAzODU1MCwianRpIjoiNTg1NGE1YWQtYjhkNS00YjI5LTg5NTQtNjRjM2E0YWE5MzkxIiwiZXhwIjoxOTQwMzk4NTUwLCJpZGVudGl0eSI6ImRldi5maW5ub3ZhdGVfYWN0dWFsQGFhZGhhYXJhcGkuaW8iLCJmcmVzaCI6ZmFsc2UsInR5cGUiOiJhY2Nlc3MiLCJ1c2VyX2NsYWltcyI6eyJzY29wZXMiOlsicmVhZCJdfX0.5y4ywaEkUGleVUCDq82en3oZbGWc8E_jIuaGkagDCNE',

            'Content-Type': 'application/json'
        },
        data: data
    };

    return axios(config)
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            return error
        });


}
export default BennyDropAPI

