import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Badges from '../../components/badge/badge';
import Label from '../../utils/constants/labels';
import { Grid, withStyles } from '@material-ui/core';
import { NegativeFinding } from '../../utils/commonfunction'
import NoData from '../../components/nodata/nodata';
import CommonHeaders from '../../components/report_common_header/report_common_header';
import CommonSnackbar from '../../components/snackbar/snackbar';
import getAllcommonDownloadDetails from '../../api/commondownload/commondownload'
import moment from 'moment'
import Loader from '../../components/loader/loader'
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle'
import colors from '../../utils/colors/colors'
import apiurl from '../../api/url/apiurl';
import labels from '../../utils/constants/labels';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import Avatars from '@material-ui/core/Avatar';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import { Redirect } from 'react-router';
import Pagination from '../../components/pagination/renderpage';
import { MContext } from '../../components/pagination/renderpage';
import { async } from 'q';
import Networking from '../../utils/api/apiaccess';
const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  popperDisablePortal: {
    position: 'relative',
  },
  img: {
    height: "20px",
    width: "50px",
    borderRadius: "10px",
    paddingRight: "10px"
  },

  table: {
    width: "100%",
    color: window.globalConfig.color.PRIMARY_BLUE,
    fontSize: "14px",
    flex: "4 1",
    fontWeight: 500,
    borderCollapse: "collapse",
    marginTop: "2em",
    marginBottom: "10%"
  },


  rupees: {
    margin: "0px !important",
    fontSize: "13px !important",
    fontWeight: "normal !important",
    color: window.globalConfig.color.LABLE_GRAY,
    padding: "1px 0px",
  },
  secondrow: {
    margin: "0px !important",
    fontSize: "11px !important",
    fontWeight: "normal !important",
    color: window.globalConfig.color.LABLE_GRAY,
    padding: "1px 4px",
    textAlign: "left",
  },
  firstrow: {
    margin: "0px !important",
    fontSize: "14px !important",
    padding: "4px",
    textAlign: "left"
  },
  titlesecondrowheading: {
    margin: "0px !important",
    fontSize: "11px !important",
    fontWeight: "normal !important",
    color: window.globalConfig.color.LABLE_GRAY,
  },
  titlesecondrow: {
    margin: "0px !important",
    fontSize: "11px !important",
  },
  span: {
    fontWeight: 500,
    marginLeft: "2px"
  },
  left: {
    textAlign: "left",
    paddingBottom: "1em",
    fontSize: "12px", fontWeight: 500, color: window.globalConfig.color.LABLE_GRAY
  },
  SchemeNametd: {
    width: "30%",
    paddingLeft: "10px",
    textAlign: "left"
  },
  SchemeNametdSpan: {
    fontSize: "20px",
    fontWeight: "bold"
  },
  bgcolor: {
    backgroundColor: window.globalConfig.color.RosyBrown
  },
  value: {
    fontSize: "16px",
    fontWeight: "bold"
  },
  rupee: {
    fontSize: "16px"
  },
  footerToatal: {
    fontSize: "20px",
    fontWeight: "bold",
    [theme.breakpoints.down('xs')]: {
      color: window.globalConfig.color.WHITE,
    }
  },
  footerCurrentValue: {
    width: "10%",
    marginLeft: "6px",
    fontSize: "20px"
  },
  footerreleasedGl: {
    width: "12%",
    fontSize: "20px"
  },
  footernetpl: {
    width: "10%",
    fontSize: "20px",
  },
  tablefooter: {
    width: "100%",
    color: window.globalConfig.color.PRIMARY_BLUE,
    fontSize: "14px",
    flex: "4 1",
    fontWeight: 500,
    borderCollapse: "collapse",
    marginTop: "3em",
    backgroundColor: window.globalConfig.color.BLACK_PRIMARY, borderRadius: "5px", color: window.globalConfig.color.WHITE,
  },
  footerBlock: {
    display: "none !important",
  },
  net: {
    marginLeft: "2px",
    marginTop: 3,
  },

  // Added by Celsia {Start}
  desktopResponsive: {
    display: 'grid !important',

    [theme.breakpoints.down("920")]: {
      display: "none !important"
    },
  },
  mobileResponsive: {
    display: 'none !important',
    [theme.breakpoints.down("920")]: {
      display: 'grid!important',
      padding: "0px 10px"
    },
  },
  trResponsive: {
    height: "auto",
    boxShadow: "0 0 5px 0 rgb(19 89 161 / 6%)",
    border: `1px solid ${window.globalConfig.color.BORDER}`,
    padding: "10px 0",
    borderRadius: "8px",
    fontSize: "13px",
    fontWeight: 400,
    width: "100%"
  },
  trResponsiveBlock: {
    height: "auto",
    boxShadow: "0 0 5px 0 rgb(19 89 161 / 6%)",
    border: `1px solid ${window.globalConfig.color.BORDER}`,
    padding: "10px 0",
    borderRadius: "8px",
    fontSize: "13px",
    fontWeight: 400,
    width: "100%",
    backgroundColor: window.globalConfig.color.BLACK_PRIMARY
  },
  SchemeNametdResponsive: {
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontWeight: '600',
  },
  leftTdResponsive: {
    textAlign: 'left',
    marginTop: '4%',
    flex: '0 0 50%'
  },
  rightTdResponsive: {
    textAlign: 'right',
    flex: '0 0 50%',
    marginTop: '4%',
  },
  tdResponsive: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "space-between",
    padding: "0px 10px"
  },
  headerBlockResponsive: {
    height: "68px",
    // borderWidth: 1,
    borderRadius: "8px !important",
    textAlign: "right",
    // marginTop: "10px !important",
    padding: "10px 10px"
    //Commended by Celsia 20 May 2021
    // [theme.breakpoints.down('xs')]: {
    //   display: "none !important"
    // }
  },

  totalResponsive: {
    fontWeight: "500",
    fontSize: "16px",
  },

  mainHeaderWidth: {
    width: '221px', textAlign: "left"
  },
  schemeNameWidth: {
    width: 179, textAlign: "left"
  },
  otherWidth: {
    width: 100
  },
  directionColumn: {
    display: "flex", flexDirection: "column"
  },
  otherWidthAlignment: {
    width: 100, display: "flex", flexDirection: "column"
  },
  noDataDesign: {
    [theme.breakpoints.down('xs')]: {
      height: 50,
      width: "100%",
      paddingTop: 30,
      paddingBottom: 30
    }
  },
  headerMobileResponsive: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    }
  },
  firstGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "firstGrid"
    }
  },
  schemeColumn:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 4
  },
  secondGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "secondGrid"
    }
  },
  thirdGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "thirdGrid"
    }
  },
  fourthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "fourthGrid"
    }
  },
  fifthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "fifthGrid"
    }
  },
  sixthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "sixthGrid"
    }
  },
  hideforDesktop: {
    [theme.breakpoints.up("920")]: {
      display: "none !important"
    }
  },
  hideDiv: {
    // [theme.breakpoints.down("920")]: {
    //   display: "none !important"
    // }
  },
  first: {
    [theme.breakpoints.down("920")]: {
      textAlign: "left",

    }
  },
  align: {
    [theme.breakpoints.down("920")]: {
      textAlign: "justify",

    }
  }


});
class AssetAllocation extends Component {
  constructor() {
    super();
    this.state = {
      accexcel: false,
      open: false,
      accpdf: false,
      assetList: [],
      isDesktopView: true
    }
  }
  resize() {
    let currentHideNav = (window.innerWidth >= 920);
    if (currentHideNav !== this.state.isDesktopView) {
      // 
      this.setState({ isDesktopView: currentHideNav });
    }

  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this))
  }
  downloadclick = async (ext) => {
    var pdfexcel = false;
    var filename = ""
    var url = ""
    filename = `${labels.DownloadLabel.assetallocation}${ext}`
    let data = {}
    if (this.state.accpdf == false) {
      if (ext === "pdf") {
        this.setState({ accpdf: true })
        //DD/MM/YYYY
        pdfexcel = true;
        data = {
          "ClientID": this.props.logindetails.ClientID, "AccountID": 0, "ReportID": labels.Report.assetAllocation, "WithRedeem": false, "AsOnDate": moment(this.props.date).format("DD MMM YYYY"),
          "SubReport": 0, "DownloadAs": 1, "ProductID": 1, "FamilyID": 0, "L5ID": 0, "L4ID": 0
        }
        url = `${apiurl.AssetAllocationpdf}${this.props.logindetails.ClientID}&asOnDate=${this.props.date == null || this.props.date == undefined ? moment().format("DD MMM YYYY") : moment(this.props.date).format("DD MMM YYYY")}&accountId=${0}&ProductID=${this.props.flag === "equity" ? 2 : 1}&FamilyID=${0}&L5ID=${this.props.L5ID}&L4ID=${0}`
      }
    }
    if (this.state.accexcel == false) {
      if (ext === "xlsx") {
        this.setState({ accexcel: true })
        pdfexcel = true;
        data = {
          "ClientID": this.props.logindetails.ClientID, "AccountID": 0, "ReportID": labels.Report.assetAllocation, "WithRedeem": false, "AsOnDate": moment(this.props.date).format("DD MMM YYYY"),
          "SubReport": 0, "DownloadAs": 2, "ProductID": 1, "FamilyID": 0, "L5ID": 0, "L4ID": 0
        }
        url = `${apiurl.AssetAllocationexcel}${this.props.logindetails.ClientID}&asOnDate=${this.props.date == null || this.props.date == undefined ? moment().format("DD MMM YYYY") : moment(this.props.date).format("DD MMM YYYY")}&accountId=${0}&ProductID=${this.props.flag === "equity" ? 2 : 1}&FamilyID=${0}&L5ID=${this.props.L5ID}&L4ID=${0}`
      }
    }
    // url = await Networking.PostApi(apiurl.ExportDownloadURL, data).then((res) => {
    //   let a = ""
    //   if (res.status == "S" && res.data != "") {
    //     a = res.data
    //   } else {
    //     if (ext === "pdf") {
    //       this.setState({ accpdf: false })
    //     }
    //     if (ext === "xlsx") {
    //       this.setState({ accexcel: false })
    //     }
    //   }
    //   return a
    // }).catch((er) => {
    //   if (ext === "pdf") {
    //     this.setState({ accpdf: false })
    //   }
    //   if (ext === "xlsx") {
    //     this.setState({ accexcel: false })
    //   }
    //   return ""
    // })
    if (pdfexcel == true && url.length != 0) {
      getAllcommonDownloadDetails(url, ext, filename, this.props.logindetails?.SessionID).then((response) => {
        if (response === "success") {
          if (ext === "pdf") {
            this.setState({ accpdf: false })
          }
          if (ext === "xlsx") {
            this.setState({ accexcel: false })
          }
        } else {
          this.setState({ open: true })
          if (ext === "pdf") {
            this.setState({ accpdf: false })
          }
          if (ext === "xlsx") {
            this.setState({ accexcel: false })
          }
        }
      })
    } else {
      this.setState({ open: true })
    }
  }
  close = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false })
  };
  render() {
    const { classes } = this.props
    let combainStyle = Object.assign({}, classes, this.props.externalClasses)
    try {
      return (<>

        {this.props.isAssetAllocationLoading == true ?
          // <>
          <Loader page="skeletonreport" />
          // </>
          :
          <>
            <div >
              <CommonHeaders
                flag={this.props.flag}
                showfilter={false}
                header={this.props.header}
                showdownload={true}
                handleclear={this.handleclear}
                pdfload={this.state.accpdf}
                newxcelLoad={this.state.accexcel}
                data={this.props.assetallocation}
                List={this.props.assetallocation}
                scheme={this.props.assetallocation.length == 0 ? <NoData /> : `${this.props.assetallocation.length} ${this.props.flag == "equity" ? 'Scrips' : 'Schemes'}` && this.props.assetallocation.length == 1 ? `${this.props.assetallocation.length} ${this.props.flag == "equity" ? 'Scrip' : 'Scheme'}` : `${this.props.assetallocation.length} ${this.props.flag == "equity" ? 'Scrips' : 'Schemes'}`} page={"asset"} onclick={this.downloadclick} />
            </div>
            <ErrorBoundary>

              <div style={{ marginTop: "1%" }}>
                {this.props.assetallocation.length != 0 ?
                  <>
                    <div>
                      <div className={[combainStyle.assetAllocationSixSplitContainer, combainStyle.mainTitle, combainStyle.desktopResponsive].join(' ')} style={{ paddingLeft: 0, paddingRight: 23 }}>
                        <div style={{ textAlign: "left" }}>

                        </div>
                        {this.props.title.map((item, index) => {
                          return (
                            <div key={index} className={combainStyle.hideDiv} style={{ textAlign: "right" }}>
                              {item.name}
                            </div>
                          )
                        })}

                        <div style={{ textAlign: "right" }}>

                        </div>
                      </div>
                      <div className={[combainStyle.assetAllocationSixSplitContainer, combainStyle.tableHeader, combainStyle.desktopResponsive].join(' ')} style={{ paddingLeft: 10, paddingRight: 10 }}>

                        {this.props.titleAmountAge.map((item, index) => {
                          return (
                            <div key={index} className={[combainStyle.directionColumn, index == 0 || this.props.titleAmountAge.length - 1 == index ? "" : combainStyle.hideDiv].join(' ')} style={{ textAlign: item.name == "Scheme Name" || item.name == "Scrip Name" ? "left" : "right" }}>
                              <div>{item.name}</div>
                              <div>{item.age}</div>
                            </div>
                          )
                        })}
                      </div>
                      <div className={[combainStyle.assetAllocationSixSplitContainer, combainStyle.tableHeader, combainStyle.mobileResponsive].join(' ')} style={{ paddingLeft: 10, paddingRight: 10 }}>


                        <div style={{ textAlign: "left" }}>{this.props.titleAmountAge[0].name}</div>

                      </div>
                      <div >
                        {this.props.assetallocation != null && this.props.assetallocation.length != 0 && <Pagination data={this.props.assetallocation}>
                          <MContext.Consumer>
                            {(context) => {
                              return <>
                                {context.filter((ele, ind) => ind === context.findIndex(elem => elem.AssetClassName === ele.AssetClassName)).map((item, mainindex) => {
                                  return <div key={mainindex}>
                                    <div className={[combainStyle.assetAllocationSixSplitContainer, combainStyle.categoryGroupContainer, mainindex == 0 && combainStyle.borderRadiousFirstIndex].join(' ')} style={{ paddingLeft: 10, paddingRight: 10, marginTop: mainindex === 0 ? 0 : '1%', }}>
                                      <div className={[combainStyle.categoryMainHeader, "firstGrid", combainStyle.firstGrid]} style={{ textAlign: "left" }}>
                                        {item.AssetClassName}
                                      </div>
                                      <div className={[combainStyle.directionColumn, "secondGrid", combainStyle.secondGrid, combainStyle.categoryMainValue, combainStyle.align].join(' ')}>
                                        <div>
                                          <span className={combainStyle.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                            {labels.summaryPage.equity}
                                          </span>
                                          <span className={combainStyle.categoryRupee}>{Label.currency.rupeesymbol}</span>

                                          {NegativeFinding(item.AssetEquityTotal, "", "", "", "number")}
                                        </div>
                                        <div>
                                          <Badges textcolor={NegativeFinding(item.AssetEquityPer, "", "", window.globalConfig.color.WHITE)} color={NegativeFinding(item.AssetEquityPer, window.globalConfig.color.LIGHT_GREEN_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND, window.globalConfig.color.ZERO_BACKGROUND_TEXT)} text={`${NegativeFinding(item.AssetEquityPer, "", "", "", "percentage")}`} />
                                        </div>
                                      </div>
                                      <div className={[combainStyle.categoryMainValue, "thirdGrid", combainStyle.thirdGrid].join(' ')}>
                                        <div>
                                          <span className={combainStyle.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                            {labels.summaryPage.dept}
                                          </span>
                                          <span className={combainStyle.categoryRupee}>{Label.currency.rupeesymbol}</span>

                                          {NegativeFinding(item.AssetDebtTotal, "", "", "", "number")}
                                        </div>
                                        <Badges textcolor={NegativeFinding(item.AssetDebtPer, "", "", window.globalConfig.color.WHITE)} color={NegativeFinding(item.AssetDebtPer, window.globalConfig.color.LIGHT_GREEN_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND, window.globalConfig.color.ZERO_BACKGROUND_TEXT)} text={`${NegativeFinding(item.AssetDebtPer, "", "", "", "percentage")}`} />
                                      </div>
                                      <div className={[combainStyle.categoryMainValue, "fourthGrid", combainStyle.fourthGrid, combainStyle.align].join(' ')}>
                                        <div>
                                          <span className={combainStyle.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                            {labels.summaryPage.gold}
                                          </span>
                                          <span className={combainStyle.categoryRupee}>{Label.currency.rupeesymbol}</span>

                                          {NegativeFinding(item.AssetGoldTotal, "", "", "", "number")}
                                        </div>
                                        <Badges textcolor={NegativeFinding(item.AssetGoldPer, "", "", window.globalConfig.color.WHITE)} color={NegativeFinding(item.AssetGoldPer, window.globalConfig.color.LIGHT_GREEN_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND, window.globalConfig.color.ZERO_BACKGROUND_TEXT)} text={`${NegativeFinding(item.AssetGoldPer, "", "", "", "percentage")}`} />
                                      </div>
                                      <div className={[combainStyle.categoryMainValue, "fifthGrid", combainStyle.fifthGrid].join(' ')}>
                                        <div>
                                          <span className={combainStyle.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                            {labels.summaryPage.others}
                                          </span>
                                          <span className={combainStyle.categoryRupee}>{Label.currency.rupeesymbol}</span>

                                          {NegativeFinding(item.AssetOtherTotal, "", "", "", "number")}
                                        </div>
                                        <Badges textcolor={NegativeFinding(item.AssetOtherPer, "", "", window.globalConfig.color.WHITE)} color={NegativeFinding(item.AssetOtherPer, window.globalConfig.color.LIGHT_GREEN_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND, window.globalConfig.color.ZERO_BACKGROUND_TEXT)} text={`${NegativeFinding(item.AssetOtherPer, "", "", "", "percentage")}`} />
                                      </div>
                                      <div className={[combainStyle.categoryMainValue, "sixthGrid", combainStyle.sixthGrid].join(' ')}>
                                        <div>
                                          <span className={combainStyle.categoryRupee}>{Label.currency.rupeesymbol}</span>

                                          {NegativeFinding(item.AssetTotal, "", "", "", "number")}
                                        </div>
                                        <Badges textcolor={NegativeFinding(item.AssetTotalPer, "", "", window.globalConfig.color.WHITE)} color={NegativeFinding(item.AssetTotalPer, window.globalConfig.color.LIGHT_GREEN_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND, window.globalConfig.color.ZERO_BACKGROUND_TEXT)} text={`${NegativeFinding(item.AssetTotalPer, "", "", "", "percentage")}`} />
                                      </div>
                                    </div>
                                    {context.filter((val) => val.AssetClassName == item.AssetClassName).map((value, index) => {
                                      return <div key={index}>
                                        <div className={[this.props.grid, combainStyle.assetAllocationSixSplitContainer, combainStyle.subCategoryGroupContainer, index == 0 && combainStyle.borderRadiousFirstIndex].join(' ')} style={{ paddingLeft: 10, paddingRight: 10 }} >
                                          <div className={[combainStyle.subCatgoryValue, "firstGrid", combainStyle.firstGrid, combainStyle.first,classes.schemeColumn].join(' ')}>
                                            <span style={{ textAlign: 'left' }} >{value.SchemeName}</span>
                                            <div style={{ color: window.globalConfig.color.LABLE_GRAY, textAlign: 'left' }}>
                                              {value.AsOnDate != undefined && <span >{this.props.AsOn + " " + value.AsOnDate}</span>}
                                            </div>
                                          </div>
                                          <div className={[combainStyle.directionColumn, "secondGrid", combainStyle.secondGrid, combainStyle.subCatgoryValue, combainStyle.align].join(' ')}>
                                            <div>
                                              <span className={combainStyle.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                                {labels.summaryPage.equity}
                                              </span>
                                              <span className={combainStyle.subcategoryRupee}>{Label.currency.rupeesymbol}</span>

                                              {NegativeFinding(value.EquityValue, "", "", "", "number")}
                                            </div>
                                            <div>
                                              <Badges textcolor={NegativeFinding(value.EquityPer, "", "", window.globalConfig.color.ZERO_BACKGROUND_TEXT)} color={NegativeFinding(value.EquityPer, window.globalConfig.color.LIGHT_GREEN_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND, window.globalConfig.color.ZERO_BACKGROUND)} text={`${NegativeFinding(value.EquityPer, "", "", "", "percentage")}`} />
                                            </div></div>
                                          <div className={[combainStyle.subCatgoryValue, "thirdGrid", combainStyle.thirdGrid].join(' ')}>
                                            <div>
                                              <span className={combainStyle.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                                {labels.summaryPage.dept}
                                              </span>
                                              <span className={combainStyle.subcategoryRupee}>{Label.currency.rupeesymbol}</span>

                                              {NegativeFinding(value.DebtValue, "", "", "", "number")}
                                            </div>
                                            <Badges textcolor={NegativeFinding(value.DebtPer, "", "", window.globalConfig.color.ZERO_BACKGROUND_TEXT)} color={NegativeFinding(value.DebtPer, window.globalConfig.color.LIGHT_GREEN_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND, window.globalConfig.color.ZERO_BACKGROUND)} text={`${NegativeFinding(value.DebtPer, "", "", "", "percentage")}`} />
                                          </div>
                                          <div className={[combainStyle.subCatgoryValue, "fourthGrid", combainStyle.fourthGrid, combainStyle.align].join(' ')}>
                                            <div>
                                              <span className={combainStyle.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                                {labels.summaryPage.gold}
                                              </span>
                                              <span className={combainStyle.subcategoryRupee}>{Label.currency.rupeesymbol}</span>

                                              {NegativeFinding(value.GoldValue, "", "", "", "number")}
                                            </div>
                                            <div>
                                              <Badges textcolor={NegativeFinding(value.GoldPer, "", "", window.globalConfig.color.ZERO_BACKGROUND_TEXT)} color={NegativeFinding(value.GoldPer, window.globalConfig.color.LIGHT_GREEN_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND, window.globalConfig.color.ZERO_BACKGROUND)} text={`${NegativeFinding(value.GoldPer, "", "", "", "percentage")}`} />
                                            </div> </div>
                                          <div className={[combainStyle.subCatgoryValue, "fifthGrid", combainStyle.fifthGrid].join(' ')}>
                                            <div>
                                              <span className={combainStyle.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                                {labels.summaryPage.others}
                                              </span>
                                              <span className={combainStyle.subcategoryRupee}>{Label.currency.rupeesymbol}</span>

                                              {NegativeFinding(value.OtherValue, "", "", "", "number")}
                                            </div>
                                            <div>
                                              <Badges textcolor={NegativeFinding(value.OtherPer, "", "", window.globalConfig.color.ZERO_BACKGROUND_TEXT)} color={NegativeFinding(value.OtherPer, window.globalConfig.color.LIGHT_GREEN_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND, window.globalConfig.color.ZERO_BACKGROUND)} text={NegativeFinding(value.OtherPer, "", "", "", "percentage")} />
                                            </div></div>
                                          <div className={[combainStyle.subCatgoryValue, "sixthGrid", combainStyle.sixthGrid].join(' ')}>
                                            <div>
                                              <span className={combainStyle.subcategoryRupee}>{Label.currency.rupeesymbol}</span>

                                              {NegativeFinding(value.TotalValue, "", "", "", "number")}
                                            </div>
                                            <div>
                                              <Badges textcolor={NegativeFinding(value.TotalPer, "", "", window.globalConfig.color.ZERO_BACKGROUND_TEXT)} color={NegativeFinding(value.TotalPer, window.globalConfig.color.LIGHT_GREEN_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND, window.globalConfig.color.ZERO_BACKGROUND)} text={`${NegativeFinding(value.TotalPer, "", "", "", "percentage")} `} />
                                            </div> </div>
                                        </div>


                                      </div>
                                    })}
                                  </div>
                                })}
                                {this.props.totalassetallocation.map((item, index) => {
                                  return (
                                    <div key={index} className={[combainStyle.displayTotalContainer, combainStyle.assetAllocationSixSplitContainer,].join(' ')} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                      <div className={[combainStyle.firstGrid, "firstGrid"].join(" ")}>
                                        {item.Total}
                                      </div>
                                      <div className={[combainStyle.directionColumn, combainStyle.hideDiv, combainStyle.secondGrid, "secondGrid"].join(' ')}>
                                        <span className={combainStyle.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                          {labels.summaryPage.equity}
                                        </span>
                                        <div>
                                          <span className={combainStyle.totalRupee}>{Label.currency.rupeesymbol}</span>

                                          {NegativeFinding(item.GrandEquityTotal, "", "", "", "number")}
                                        </div>
                                        <div>
                                          <Badges textcolor={NegativeFinding(item.GrandEquityPer, "", "", window.globalConfig.color.ZERO_BACKGROUND_TEXT)} color={NegativeFinding(item.GrandEquityPer, window.globalConfig.color.LIGHT_GREEN_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND, window.globalConfig.color.ZERO_BACKGROUND)} text={`${NegativeFinding(item.GrandEquityPer, "", "", "", "percentage")}`} />
                                        </div>
                                      </div>
                                      <div className={[combainStyle.hideDiv, combainStyle.thirdGrid, "thirdGrid"].join(" ")}>
                                        <span className={combainStyle.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                          {labels.summaryPage.dept}
                                        </span>
                                        <div>
                                          <span className={combainStyle.totalRupee}>{Label.currency.rupeesymbol}</span>

                                          {NegativeFinding(item.GrandDebtTotal, "", "", "", "number")}
                                        </div>
                                        <Badges textcolor={NegativeFinding(item.GrandDebtPer, "", "", window.globalConfig.color.ZERO_BACKGROUND_TEXT)} color={NegativeFinding(item.GrandDebtPer, window.globalConfig.color.LIGHT_GREEN_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND, window.globalConfig.color.ZERO_BACKGROUND)} text={`${NegativeFinding(item.GrandDebtPer, "", "", "", "percentage")}`} />
                                      </div>
                                      <div className={[combainStyle.hideDiv, combainStyle.fourthGrid, "fourthGrid"].join(" ")}>
                                        <span className={combainStyle.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                          {labels.summaryPage.gold}
                                        </span>
                                        <div>
                                          <span className={combainStyle.totalRupee}>{Label.currency.rupeesymbol}</span>

                                          {NegativeFinding(item.GrandGoldTotal, "", "", "", "number")}
                                        </div>
                                        <Badges textcolor={NegativeFinding(item.GrandGoldPer, "", "", window.globalConfig.color.ZERO_BACKGROUND_TEXT)} color={NegativeFinding(item.GrandGoldPer, window.globalConfig.color.LIGHT_GREEN_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND, window.globalConfig.color.ZERO_BACKGROUND)} text={`${NegativeFinding(item.GrandGoldPer, "", "", "", "percentage")}`} />
                                      </div>
                                      <div className={[combainStyle.hideDiv, combainStyle.fifthGrid, "fifthGrid"].join(" ")}>
                                        <span className={combainStyle.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                          {labels.summaryPage.other}
                                        </span>
                                        <div >
                                          <span className={combainStyle.totalRupee}>{Label.currency.rupeesymbol}</span>

                                          {NegativeFinding(item.GrandOtherTotal, "", "", "", "number")}
                                        </div>
                                        <Badges textcolor={NegativeFinding(item.GrandOtherPer, "", "", window.globalConfig.color.ZERO_BACKGROUND_TEXT)} color={NegativeFinding(item.GrandOtherPer, window.globalConfig.color.LIGHT_GREEN_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND, window.globalConfig.color.ZERO_BACKGROUND)} text={`${NegativeFinding(item.GrandOtherPer, "", "", "", "percentage")}`} />
                                      </div>
                                      <div className={[combainStyle.sixthGrid, "sixthGrid"].join(" ")}>
                                        <div>
                                          <span className={combainStyle.totalRupee}>{Label.currency.rupeesymbol}</span>

                                          {NegativeFinding(item.GrandTotal, "", "", "", "number")}
                                        </div>
                                        <Badges textcolor={NegativeFinding(item.GrandPerTotal, "", "", window.globalConfig.color.ZERO_BACKGROUND_TEXT)} color={NegativeFinding(item.GrandPerTotal, window.globalConfig.color.LIGHT_GREEN_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND, window.globalConfig.color.ZERO_BACKGROUND)} text={`${NegativeFinding(item.GrandPerTotal, "", "", "", "percentage")}`} />
                                      </div>
                                    </div>
                                  )
                                })
                                }
                              </>
                            }}

                          </MContext.Consumer>
                        </Pagination>}


                      </div></div>
                  </>

                  : <div></div>}
              </div>


            </ErrorBoundary>
          </>
        }

        <CommonSnackbar open={this.state.open} handlesnanckClose={this.close} message={Label.messages.apierror} severity={"warning"} />
      </>
      )
    }
    catch (err) {

      return <Redirect to={labels.Path.NotFound} />
    }
  }
}
const mapStateToProps = state => {
  //
  return {
    // sessionID: state.Login.sessionID,
    // clientID: state.Login.logindetails.ClientID,
    // userID: state.Login.userID,
    // date: state.Login.date,
    // logindetails: state.Login.logindetails,
    // assetallocationgrouped: state.Asset.assetallocationgrouped,
    // totalassetallocation: state.Asset.totalassetallocation,
    // assetallocation: state.Asset.assetallocation,
    // isAssetAllocationLoading: state.Asset.isAssetAllocationLoading,
    // isNetworkLow: state.Asset.isNetworkLow,
    // L5ID: state.Login.L5ID,

  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(AssetAllocation))
