/* name should be camel case in all function */
//const BaseUrl='https://jsonplaceholder.typicode.com/todos';
import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
function getMandateApiDetails(ClientCode, ProductID, FamilyID, L5ID, L4ID, UCC) {
  var data = JSON.stringify({
    "ClientID": ClientCode,
    "ProductID": ProductID,
    "FamilyID": FamilyID,
    "L5ID": L5ID,
    "L4ID": L4ID,
    "ClientCode": UCC
  });
  return Networking.PostApi(ApiValues.GetMandateDetails, data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });


}
export default getMandateApiDetails;
