// import { Repeat } from "@material-ui/icons";
import colors from "../../colors/colors";

// import deco from 'enl-images/decoration/hexaMonochrome.svg';
const rootWraper = {
  display: 'flex',
  width: '100%',
  zIndex: 1,
  position: 'absolute'
};
const styles = theme => ({

  categoryMainHeader: {
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "left",
    color: window.globalConfig.color.PRIMARY_BLUE,
  },
  categoryMainHeaderXray: {
    fontSize: "14px",
    fontWeight: 500,
    textAlign: "left",
    color: window.globalConfig.color.PRIMARY_BLUE,
  },
  categorySubHeader: {
    fontSize: "12px",
    fontWeight: 500,
    color: window.globalConfig.color.GRAY,
    textAlign: "left"
  },
  categoryMainValue: {
    fontSize: "16px",
    fontWeight: 500,
    color: window.globalConfig.color.PRIMARY_BLUE,
    // textAlign: "right"
  },
  categoryMainValueXray: {
    fontSize: "13px",
    fontWeight: 500,
    color: window.globalConfig.color.PRIMARY_BLUE,
    textAlign: "right"
  },
  tableHeader: {
    fontSize: "12px",
    fontWeight: "500 !important",
    backgroundColor: window.globalConfig.color.TABLE_HEADER,
    // padding:"10px",
    borderRadius: "10px 10px 0px 0px",
    color: window.globalConfig.color.PRIMARY,
    // height: "63px",
    alignItems: "center",
    textTransform: "uppercase",
    lineHeight: 1.5,
    paddingTop: "18px",
    paddingBottom: "18px",
    cursor: "auto",
    // marginRight: "1.5%"
    // verticalAlign:"bottom"
  },
  tableHeaderInsurance: {
    fontSize: "12px",
    fontWeight: "500 !important",
    backgroundColor: window.globalConfig.color.TABLE_HEADER,
    // padding:"10px",
    borderRadius: "10px 10px 0px 0px",
    color: window.globalConfig.color.PRIMARY,
    // height: "63px",
    alignItems: "center",
    textTransform: "uppercase",
    lineHeight: 1.5,
    paddingTop: "18px",
    paddingBottom: "18px",
    cursor: "auto",
    // marginRight: "1.5%"
    // verticalAlign:"bottom"
  },
  tableHeaderResponsive: {
    fontSize: "12px",
    fontWeight: "500 !important",
    backgroundColor: window.globalConfig.color.TABLE_HEADER,
    // borderRadius: "5px",
    borderRadius: "10px 10px 0px 0px",
    color: window.globalConfig.color.PRIMARY,
    alignItems: "center",
    textTransform: "uppercase",
    lineHeight: 1.5,
    padding: "15px 10px",
    marginTop: "36px"
  },
  headerFlex: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: "space-between"
  },
  leftResponsive: {
    textAlign: "left"
  },
  rightResponsive: {
    textAlign: "right"
  },
  subCatgoryValue: {
    //  marginRight: "5%",
    fontWeight: 500,
    fontSize: "12px",
    color: window.globalConfig.color.PRIMARY_BLUE
  },
  tableHeaderSIP: {
    fontSize: "12px",
    fontWeight: "500 !important",
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    // padding:"10px",
    borderRadius: "10px 10px 0px 0px",
    color: window.globalConfig.color.PRIMARY,
    // height: "63px",
    alignItems: "right",
    textTransform: "uppercase",
    lineHeight: 1.5,
    paddingTop: "18px",
    paddingBottom: "18px",
    cursor: "auto",
    // marginRight:"1.5%"
    // verticalAlign:"bottom"
  },
  subcategoryRupee: {
    fontWeight: "normal",
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: "12px",
  },
  categoryRupee: {
    fontSize: "16px",
    fontWeight: "normal",
    color: window.globalConfig.color.PRIMARY_BORDER
  },
  mainTitle: {
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: "16px",
    textTransform: "uppercase",
    marginBottom: "1%"
  },

  totalRupee: {
    fontSize: "16px",
    color: window.globalConfig.color.LABLE_GRAY
  },
  categoryGroupContainer: {
    minHeight: "67px",
    // marginTop: "10px",
    textAlign: "right",
    backgroundColor: 'rgb(244,246,249)',
    borderWidth: "1px",
    borderTopLeftRadius: '0px',
    borderTopRightRadius: '0px',
    // marginRight: "1.5%",
    display: "grid",
    // gridTemplateColumns: "repeat(10,1fr)",
    alignItems: "center",
    color: window.globalConfig.color.BLACK_PRIMARY,
    lineHeight: 1.5,
    cursor: "auto"
  },
  categoryGroupContainerXray: {
    minHeight: "85px",
    // marginTop: "10px",
    textAlign: "right",
    backgroundColor: 'rgb(244,246,249)',
    borderWidth: "1px",
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px',
    display: "grid",
    gridTemplateColumns: "repeat(10,1fr)",
    alignItems: "center",
    color: window.globalConfig.color.BLACK_PRIMARY,
    lineHeight: 1.5,
    cursor: "auto",
    // marginRight: "15px"
  },
  TenSplitContainer: {

    display: "grid",
    gridTemplateColumns: "repeat(10,1fr)",
    // paddingLeft:"10px"
    // marginLeft:"20px"
  },
  NineSplitContainer: {

    display: "grid",
    gridTemplateColumns: "repeat(8,1fr)",
    alignContent: "center"
    // paddingLeft:"10px"
    // marginLeft:"20px"
  },

 

  TwoSplitContainer: {

    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    // paddingLeft:"10px",
    // marginLeft: "20px"

  },
  AccountTwoSplitContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    // paddingLeft:"10px",
    marginLeft: "20px",
    // marginRight: "16px !important"
  },

  // FourSplitContainer: {

    // display: "grid",
    // gridTemplateColumns: "repeat(4,1fr)",
    // paddingLeft: "10px"

  // },
  FiveSplitContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(5,1fr)",
    // paddingLeft: "10px"
    marginLeft: "20px"
  },
  FiveSplitContainerWithWidth: {
    display: "grid",
    gridTemplateColumns: "40% 15% 15% 15% 15%",
    // paddingLeft: "10px"
    marginLeft: "20px"
  },
  sevenSplitContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(7,1fr)",
    // paddingLeft: "10px"
    marginLeft: "20px"
  },
  // sipTrackerSevenSplitContainer:{
  //   display:"grid",
  //   gridTemplateColumns:"32% 11% 16% 13% 15% 11%"
  // },
  // portfolioXraySevenSplitContainer: {
  //   display: "grid",
  //   gridTemplateColumns: "27% 12% 14% 14% 14% 14% 5% !important",
  //   // paddingLeft: "10px"
  //   marginLeft: "20px",
  //   alignContent: "center"
  // },
  portfolioXrayFiveSplitContainer: {
    display: "grid",
    gridTemplateColumns: "31% 17% 17% 17% 17% !important",
    // paddingLeft: "10px"
    marginLeft: "20px",
    alignContent: "center"
  },
  portfolioXraySixSplitContainer: {
    display: "grid",
    gridTemplateColumns: "28% 14% 14% 14% 14% 14% ",
    // paddingLeft: "10px"
    marginLeft: "20px",
    alignContent: "center"
  },
  EightSplitContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(8,1fr)",
    marginLeft: "20px",
    alignContent: "center"
    // columnGap:'1%',
    // paddingLeft: "10px"
  },
  ElevenSplitContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(11,1fr)",
    // paddingLeft: "10px",
    marginLeft: "20px"
  },

  towelSplitContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(12,1fr)",
    // paddingLeft: "10px",
    marginLeft: "20px"
  },
  ThreeSplitContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    paddingLeft: "10px"
  },

  subCategoryGroupContainers: {
    border: `1px solid ${window.globalConfig.color.BORDER}`,
    minHeight: '60px',
    // padding: "10px 0",
    fontSize: '13px',
    textAlign: "right",
    borderRadius: "8px",
    fontWeight: 400,
    alignItems: "center",
    alignContent: "center",
    lineHeight: 1.5,
    boxShadow: "0 0 5px 0 rgb(19 89 161 / 6%)",
    cursor: "auto",
    // marginRight: "1.5%",
    // columnGap:'1%',
  },

  subCategoryGroupContainer: {
    border: `1px solid ${window.globalConfig.color.BORDER}`,
    minHeight: '60px',
    // padding: "10px 0",
    fontSize: '13px',
    textAlign: "right",
    borderRadius: "8px",
    fontWeight: 400,
    alignItems: "center",
    lineHeight: 1.5,
    boxShadow: "0 0 5px 0 rgb(19 89 161 / 6%)",
    cursor: "auto",
    // marginRight: "1.5%",
    // columnGap:'1%',
    // paddingTop:"12px",
    // paddingBottom:"17px",
  },
  subCategoryGroupContainerSIP: {
    border: `1px solid ${window.globalConfig.color.BORDER} !important`,
    height: '73px',
    borderRadius: "8px !important",
    boxShadow: "0 0 5px 0 rgb(19 89 161 / 6%) !important",
    cursor: "auto",
  },
  additionalCategoryheader: {
    backgroundColor: window.globalConfig.color.TABLE_HEADER,
    border: window.globalConfig.color.WHITE,
    minHeight: '73px',
    // paddingTop:"18px",
    // paddingBottom:"18px",
    // // padding: "10px 0",
    fontSize: '13px',
    textAlign: "right",
    // borderRadius: "8px",
    fontWeight: 400,
    alignItems: "center",
    lineHeight: 1.5,
    cursor: "auto",
    marginLeft: "0px"
  },
  borderRadiousOtherIndex: {
    borderTopLeftRadius: 8, borderTopRightRadius: 8
  },
  borderRadiousFirstIndex: {
    borderTopLeftRadius: "0 !important", borderTopRightRadius: "0 !important"
  },

  totalContainer: {
    fontSize: "16px",
    color: window.globalConfig.color.WHITE,
    fontWeight: 500,
    borderRadius: '8px',
    backgroundColor: window.globalConfig.color.BLACK_PRIMARY,
    minHeight: "85px",
    marginTop: "10px",
    textAlign: "right", alignItems: "center",
    marginBottom: "30px",
    lineHeight: 1.5,
    cursor: "auto",
    marginLeft: "0px",
    // marginRight: "1.5%"
  },
  displayTotalContainer: {
    fontSize: "16px",
    color: window.globalConfig.color.WHITE,
    fontWeight: 500,
    borderRadius: '8px',
    alignContent: "center",
    backgroundColor: window.globalConfig.color.BLACK_PRIMARY,
    minHeight: "85px",
    marginTop: "10px",
    textAlign: "right", alignItems: "center",
    marginBottom: "30px",
    lineHeight: 1.5,
    cursor: "auto",
    // marginRight: "1.5%",

  },
  totalContainerResponsive: {
    fontSize: "16px",
    color: window.globalConfig.color.WHITE,
    fontWeight: 500,
    borderRadius: '8px',
    backgroundColor: "black",
    // paddingTop: "10px",
    // textAlign: "right", 
    // alignItems: "center",
    marginBottom: "30px",
    lineHeight: 1.5,
    cursor: "auto",
    // marginLeft:10
    marginTop: "2%",

  },
  hideDiv: {
    [theme.breakpoints.down("920")]: {
      display: "none"
    }
  },
  paddingResponsive: {
    marginTop: "4%",
    padding: "0px 10px"
  },
  labelResponsive: {
    marginBottom: '2%',
    color: window.globalConfig.color.LABLE_GRAY,
    fontWeight: "500",
    fontSize: 12
  },
  labelValueResponsive: {
    color: window.globalConfig.color.LABLE_GRAY,
    fontWeight: 500,
    fontSize: "14px",
  },
  badgeResponsive: {
    padding: "0px",
    fontSize: "13px",
    display: "inline-block",
    color: window.globalConfig.color.LABLE_GRAY
  },
  tableBody: {
    boxShadow: "0 0 5px 0 rgb(19 89 161 / 6%)",
    // border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    backgroundColor: window.globalConfig.color.WHITE,
    padding: "5px 0",
    borderRadius: "8px",
  },
  tableBodyResponsive: {
    boxShadow: "0 0 5px 0 rgb(19 89 161 / 6%)",
    // border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    backgroundColor: window.globalConfig.color.WHITE,
    padding: "5px 0",
    borderRadius: "8px",
  }
});
export default styles;
