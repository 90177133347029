import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import CartList from '../../components/cart/cartlist.js';
//import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import '../../components/cart/cart.css';
import { SaveCartValues } from '../../redux/actions/cart/getallcartaction'
import ToolBar from '../../components/toolbar/carttoolbar';
import { OneTimeOrder } from '../../redux/actions/purchaseorder/onetimeorderaction';
import secureStorage from '../../utils/securestorage'
import ErrorBoundary from '../../components/Errorhandling/Errorhandle.js';
import GetOldCart from '../../api/cart/oldcartlist.js';
import labels from '../../utils/constants/labels';
import { replaceNull } from '../../utils/commonfunction.js';
let userDetails = {}
import { scrollToTopPage } from '../../utils/commonfunction';
import GetAllCartsBaskets from '../../api/basket/getallcartbasketapi.js';
import GetAllCart from '../../api/cart/getallcartapi.js';
const styles = theme => ({
    root: {
        display: 'flex',
        marginBottom: "100px",
        zIndex: "1 !important",
        paddingTop: "0%",

    },
    container: {
        marginTop: 45
    },
    toolbars: {
        flex: 1,
        minHeight: 10,
        alignItems: 'flex-start',
        marginLeft: 20
    },
    note: {
        color: window.globalConfig.color.LABLE_GRAY,
        margin: "0px !important",
        fontSize: "14px !important",
        fontWeight: "normal !important",
        float: "left",
        padding: "10px 0px"
    },
    btnApply: {
        background: window.globalConfig.color.BROWN,
        marginLeft: "7px",
        padding: 0,
        marginTop: 25,
        lineHeight: "36px",
        color: window.globalConfig.color.WHITE,
        fontWeight: 600,
        fontSize: "14px",
        lineHeight: "36px",
        borderRadius: "5px",
        padding: "5px 20px",
        border: "1px solid transparent",
    },
});
const colors = labels.schemeColors
const repeatFor = (arr, size, data) => {
    var newArr = new Array(size);

    for (var i = 0; i < size; i++) {
        newArr[i] = arr[i % i.length];
        var b = arr[i];
        newArr[i] = colors[Math.floor(Math.random() * colors.length)]
    }
    var a = secureStorage.getItem("amc");
    var a = a.map((val1, index1) => {
        data.map((val, index) => {
            if (val.AMCID == val1.ID) {
                return val.color = colors[index1]
            }
        })
    })
    return data;
}
class Cart extends Component {

    constructor(props) {
        super(props);
        this.child = React.createRef();
        this.state = {
            selectedSwitch: true,
            sipSelectedCount: 0,
            sipSwitch: true,
            onetimeSwitch: false,
            oneTimeSelectedCount: 0,
            sipdata: [],
            sipdataCopy: [],
            onetimeData: [],
            onetimeDataCopy: [],
            selectedOneTimeList: [],
            selectedSipList: [],
            switchFlag: 0, open: true,
            oldCartLoader: false,
            oldCartList: [],
            cartSwitch: "Cart",
            placeOrderSwitch: false,
            onetimeCartData: [],
            sipCartData: [],
            onetimeBasketCartData: [],
            sipBasketCartData: [],
            sipAndOneTimeSwitch: false,
            sipSchemeCartData: [],
            oneTimeSchemeCartData: [],
            schemeLoader: false
        };

    }

    componentDidMount = async () => {

        document.title = window.globalConfig.companyDetails.companyName + " - Cart"
        scrollToTopPage()

        userDetails = secureStorage.getItem("user")
        if (this.props.match.params.selected == "onetime") {
            this.setState({ selectedSwitch: false, onetimeSwitch: true, sipSwitch: true })
        }
        this.getCart()
        this.setState({ oldCartLoader: true })
        // GetAllCartsBaskets(this.props.logindetails.SessionID, this.props.logindetails.ClientID).then((response) => {
        //     let res = replaceNull(response, "NA")
        //     if (res.status == "S") {
        //         if (res.data.length != 0 && res.data != "NA") {
        //             this.setState({
        //                 sipBasketCartData: res.data.filter((item) => item.SipAllowed).map((val) => {
        //                     return { ...val, Ucc: val.MutualFundUCC }
        //                 }), onetimeBasketCartData: res.data.filter((item) => item.OneTimeAllowed).map((val) => {
        //                     return { ...val, Ucc: val.MutualFundUCC }
        //                 })
        //             })
        //         }
        //         else {
        //             this.setState({ sipBasketCartData: [], onetimeBasketCartData: [] })
        //         }
        //     } else {
        //         this.setState({ sipBasketCartData: [], onetimeBasketCartData: [] })
        //     }
        // })
        GetOldCart(this.props.logindetails.SessionID, this.props.logindetails.ClientID).then((res) => {
            this.setState({ oldCartLoader: false })
            if (res.status == "S") {
                let response = replaceNull(res, "NA")
                if (response.data.length != 0 && response.data != "NA") {

                    this.setState({
                        sipCartData: response.data.filter((item) => item.SipAllowed).map((val) => {
                            return { ...val, Ucc: val.MutualFundUCC }
                        }), onetimeCartData: response.data.filter((item) => item.OneTimeAllowed).map((val) => {
                            return { ...val, Ucc: val.MutualFundUCC }
                        })
                    })
                } else {
                    this.setState({ sipCartData: [], onetimeCartData: [] })
                }
            } else {
                this.setState({ sipCartData: [], onetimeCartData: [] })
            }
        })
    }

    getCart = () => {
        // console.log("sddssdf")
        this.setState({ schemeLoader: true })
        // console.log(this.props.logindetails.SessionID, this.props.logindetails.ClientID)
        GetAllCart(this.props.logindetails.SessionID, this.props.logindetails.ClientID).then((response) => {
            this.setState({ schemeLoader: false })
            let res = replaceNull(response, "NA")
            if (res.status == "S" && res.data != "NA") {
                this.setState({
                    sipSchemeCartData: res.data.filter((item) => item.SipAllowed == true),
                    oneTimeSchemeCartData: res.data.filter((item) => item.OneTimeAllowed == true)
                })
            } else {
                this.setState({
                    sipSchemeCartData: [],
                    oneTimeSchemeCartData: []
                })
            }
        })
    }
    onSwitchDonut(switchDonut) {
        this.setState({
            selectedSwitch: switchDonut
        })
    }
    onSwitching = (data) => {
        this.setState({ sipAndOneTimeSwitch: data })
    }

    handlesnanckClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };
    onSwitchDonut = (value) => {
        this.setState({ cartSwitch: value, })
        if (value == "Cart") {
            this.getCart()
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <ErrorBoundary>
                <div className={classes.root}>
                    <AppBar id="tollbar" style={{ zIndex: "1" }} position="static" color="transparent">
                        <ToolBar title="Cart" onSwitchDonut={this.onSwitchDonut} cartSwitch={this.state.cartSwitch} placeOrderSwitch={!this.state.cartSwitch} ></ToolBar>
                        {this.state.cartSwitch == "Cart" ? <CartList GetAllCart={this.getCart} loading={this.state.schemeLoader} onetimeCartData={this.state.oneTimeSchemeCartData} sipCartData={this.state.sipSchemeCartData} schemeList={this.props.schemeList} page="cart" onSwitching={this.onSwitching} sipOneTimeSwitch={this.state.sipAndOneTimeSwitch} sessionId={this.props.logindetails.SessionID} clientId={this.props.logindetails.ClientID} logindetails={this.props.logindetails}>
                        </CartList> :
                        //  this.state.cartSwitch == "Basket" ? <CartList onetimeCartData={this.state.onetimeBasketCartData} sipCartData={this.state.sipBasketCartData} schemeList={this.props.schemeList} page="basket" onSwitching={this.onSwitching} sipOneTimeSwitch={this.state.sipAndOneTimeSwitch} sessionId={this.props.logindetails.SessionID} clientId={this.props.logindetails.ClientID} logindetails={this.props.logindetails}>
                        // </CartList> : 
                        <CartList GetAllCart={this.getCart} schemeList={this.props.schemeList} page="order again" onSwitching={this.onSwitching} sipOneTimeSwitch={this.state.sipAndOneTimeSwitch} loading={this.state.oldCartLoader} sipCartData={this.state.sipCartData} onetimeCartData={this.state.onetimeCartData} sessionId={this.props.logindetails.SessionID} clientId={this.props.logindetails.ClientID} logindetails={this.props.logindetails}>
                        </CartList>}
                    </AppBar>
                </div>
                <div style={{ marginBottom: "1%" }}></div>
            </ErrorBoundary>
        )

    }
}
const mapStateToProps = state => ({
    // getAllBasketSipCart: state.CartReducer.getAllSipCart == "NA" ? [] : state.CartReducer.getAllSipCart.filter((item) =>
    //     item.hasOwnProperty("IsBasket") && item.IsBasket == true
    // ),
    // getAllBasketOnetimeCart: state.CartReducer.getAllOnetimeCart == "NA" ? [] : state.CartReducer.getAllOnetimeCart.filter((item) =>
    //     item.hasOwnProperty("IsBasket") && item.IsBasket == true
    // ),
    getCartByID: state.CartReducer.getCartByID,
    asOnDate: state.Login.date,
    mappingDetails: state.SchemeDetails.mappingDetails,
    folioNumber: state.SchemeDetails.folioNumber,
    isNetworkLow: state.CartReducer.isNetworkLow,
    logindetails: state.Login.logindetails,
    cartData: state.CartReducer.getAllOnetimeCart,
    // sipCartData: state.CartReducer.getAllSipCart == "NA" ? [] : state.CartReducer.getAllSipCart.filter((item) =>
    //     item.hasOwnProperty("IsBasket") ? item.IsBasket == false : true
    // ),
    // onetimeCartData: state.CartReducer.getAllOnetimeCart == "NA" ? [] : state.CartReducer.getAllOnetimeCart.filter((item) =>
    //     item.hasOwnProperty("IsBasket") ? item.IsBasket == false : true
    // ),
    updateCart: state.CartReducer.updateCart,

    getCartByID: state.CartReducer.getCartByID,
    loading: state.CartReducer.isLoading,
    asOnDate: state.Login.date,
    mappingDetails: state.SchemeDetails.mappingDetails,
    folioNumber: state.SchemeDetails.folioNumber,
    sessionId: state.Login.sessionID,
    clientId: state.Login.logindetails.ClientID,
    logindetails: state.Login.logindetails,
    schemeList:
        state.Dropdown.dropdowndetails == null ||
            state.Dropdown.dropdowndetails.scheme == undefined
            ? []
            : state.Dropdown.dropdowndetails.scheme,
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        OneTimeOrder,
        // SetPurchaseResponseEmpty, 
        SaveCartValues
    }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Cart))

