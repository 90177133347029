import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Loginwithotpform from '../../components/login/loginwithotpform';
import styles from '../../components/login/user-jss'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLogin, clearLoginData, clearLoader } from '../../redux/actions/login/loginaction'
import Label from '../../utils/constants/labels'
import { PostSendOtp } from '../../redux/actions/resetpassword/otpsend'
import { PasswordChange } from '../../redux/actions/resetpassword/restpassword'
import { PasswordChanges } from '../../redux/actions/resetpassword/changepassword'
import { ResendOTPs } from '../../redux/actions/resetpassword/resendotp'
import { LoginResetSuccess } from '../../redux/actions/login/loginresetsuccessaction'
import { VerifyOTPChangePassword } from '../../redux/actions/resetpassword/verifyotp'
import SendOTPs from './sendotp';
import VerifyOTP from './verifyotp';
import ChangePassword from './changepassword';
// Api
import LoginWithSendOTP from '../../api/loginwithotp/loginwithotp';
import VerifyOTPLoginWithEmail from '../../api/loginwithotp/VerifyOTPLoginWithEmail';
import SendOTP from '../../api/login/sendotp';
import VerifyOTPs from '../../api/login/verifyotp';
import ResendOTP from '../../api/login/resend';
import ChangeforgetPassword from '../../api/login/changepassword';
import ResetPasswords from '../../api/login/resetpassword';
import moment from 'moment';
import Reset from './resetpassword';
// import Snackbar from '@material-ui/core/Snackbar';
// import MuiAlert from '@material-ui/lab/Alert';
import secureStorage from '../../utils/securestorage'
import CommonSnackbar from '../../components/snackbar/snackbar'
// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }
class Loginwithotp extends React.Component {
  state = {
    valueForm: [],
    RestPassword: "",
    isForgetpassword: false,
    isVerifiedOtp: false,
    isChangePassword: false,
    open: false,
    Message: "",
    ClientID: "",
    color: "success",
    resetFlag: false,
    isLoading: false,
    email: { value: '', error: '' }

  }
  submitForm(values, pass) {
    const { valueForm } = this.state;
    LoginWithSendOTP(values, pass, this.props?.history, this.props?.childFunc);

  }
  openResetPassword = (email) => {
    this.setState({ resetFlag: true, email: email })
  }
  componentDidMount() {

    var session = secureStorage.getItem('user');
    var session_store = session;
    //   (session_store)
    // this.setState({ resetFlag: this.props?.ResetFlag })
    this.props?.clearLoader()
    if (this.props?.location.state != undefined) {
      if (this.props?.location.state.data == "from welcome") {
        this.props?.history.push(Label.Path.Login)
      }
    } else {
      if (session_store != null && session_store.status != "F") {
        if (this.props?.logindetails.EntityTypeID === 9) {
          this.props?.history.push(Label.Path.Scheme);

        } else {
          this.props?.history.push(Label.Path.Portfolio);

        }
      }
      else {
        //   ("login",session_store)

        this.props?.history.push(Label.Path.Login)

      }

    }


  }
  componentDidUpdate(prevProps) {
    // const { login, location, ResetFlag } = this.props;

    // if (prevProps.ResetFlag != ResetFlag) {
    //   this.setState({ resetFlag: ResetFlag })
    // }
  }

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({
      open: false
    })
  };
  ResetPassword = (value) => {
    this.setState({
      isLoading: true
    })
    SendOTP(value).then((data) => {
      //  
      this.setState({
        isLoading: false
      })
      if (data.status == "S") {
        // if (data.clientID != 0) {
        this.setState({
          RestPassword: value,
          isVerifiedOtp: true,
          // isForgetpassword: false,
          ClientID: data.clientID,
          open: true,
          isLoading: false,
          Message: data.message,
          color: "success"
        })
        // }
      }
      else {
        this.setState({
          isVerifiedOtp: false,
          // isForgetpassword: false,
          open: true,
          isLoading: false,
          Message: data.message,
          color: "warning"
        })
      }
    })
  }
  verifyOtp = (value) => {
    VerifyOTPs(this.state.RestPassword, this.state.ClientID, value).then((data) => {
      if (data.status == "S") {
        this.setState({
          isVerifiedOtp: false,
          isForgetpassword: false,
          isChangePassword: true
        })
      }
      else {
        this.setState({
          isVerifiedOtp: false,
          isForgetpassword: false,
          isChangePassword: false,
          Message: data.message,
          open: true,
          color: "warning"
        })
      }
    })
  }

  reSendOtp = (value) => {
    ResendOTP(this.state.RestPassword).then((data) => {
      this.setState({
        isVerifiedOtp: true,
        // isForgetpassword: false
      })
    })
  }

  postResetPassword = (value) => {
    ResetPasswords(this.state.email, value, 0).then((data) => {
      // if (data != undefined) {
      if (data.status == "S") {
        this.props?.LoginResetSuccess(data.status)
        this.setState({
          color: "success",
        })
      } else {
        this.setState({ color: "warning" })
      }
      this.setState({
        open: true,
        Message: data.message
      })
    })
  }
  postChangePassword = (value) => {
    // this.props?.PasswordChanges
    // if (this.props?.ResetFlag == false) {

    ChangeforgetPassword(this.state.RestPassword, value, this.state.ClientID).then((data) => {
      if (data != undefined) {
        if (data.status == "S") {
          this.setState({
            open: true,
            isChangePassword: false,
            Message: data.message,
            color: "success",
          })
        }
      }
    })
    // }
  }

  getdetailsEmail = (value) => {
    this.setState({
      RestPassword: value
    })

  }
  GetEmail = (value) => {
    if (value === 'F') {
      this.setState({
        RestPassword: "",
      })
    }
    else {
      this.setState({
        RestPassword: value,
        isForgetpassword: true
      })
    }
  }
  PlayStoreClick = () => {
     window.location.href = "https://play.google.com/store/apps/details?id=com.finnovate.wealth";
  }
  onAppStoreClick = () => {
    window.location.href = "https://apps.apple.com/my/app/finnovate-wealth/id6444800128"
  }
  render() {
    const { classes } = this.props;
    return (
      <div >

        <Loginwithotpform
          ResetPassword={(value) => {
            this.ResetPassword(value)
          }}
          childFunc={this.props.childFunc}
          openResetPassword={this.openResetPassword}
          // this.ResetPassword(value) }}
          PlayStoreClick={() => { this.PlayStoreClick() }}
          // GetEmail={(value) => this.GetEmail(value)}
          onSubmit={(values, pass, deviceType, os, timeStamp, timeZone, browserNameVersion, referrelUrl) => { this.submitForm(values, pass, deviceType, os, timeStamp, timeZone, browserNameVersion, referrelUrl) }}
          onChangeEmail={(value) => this.getdetailsEmail(value)}
        />

        {this.state.isForgetpassword && <SendOTPs open={this.state.isForgetpassword}
          email={this.state.RestPassword}
          close={() => this.setState({ isForgetpassword: false })}
          onSubmit={(value) => this.ResetPassword(value)}
        />}


        {this.state.isVerifiedOtp &&
          <VerifyOTP open={this.state.isVerifiedOtp}
            close={() => this.setState({ isVerifiedOtp: false })}
            onSubmit={(value) => this.verifyOtp(value)}
            reSendOTP={(value) => this.reSendOtp(value)}
            resetPassword={(value) => this.ResetPassword(value)}
            emailvalue={this.state.RestPassword}
          />
        }
        {this.state.isChangePassword &&
          <ChangePassword open={this.state.isChangePassword}
            updatePassword={(value) => this.postChangePassword(value)} close={() => this.setState({ isChangePassword: false })}
          />
        }

        {/* {this.props?.ResetFlag && <Reset open={this.props?.ResetFlag}
          close={() => this.props?.ResetFlag(false)} updatePassword={(value) => this.postResetPassword(value)}
        />
        } */}
        {/* {} */}
        {this.state.resetFlag && <Reset open={this.state.resetFlag}
          close={() => this.setState({ resetFlag: false })} updatePassword={(value) => this.postResetPassword(value)}
        />
        }
        <CommonSnackbar message={this.state.Message} open={this.state.open} handlesnanckClose={this.handleClose} severity={this.state.color} />

      </div>
    );
  }
}
const mapStateToProps = state => ({
  logindetails: state?.Login?.logindetails,
  login: state?.Login?.login,
  // isSendMail: state.ResetPassword?.VerifyOTP,
  ResetFlag: state?.Login?.ResetFlag,
  ConfirmOTP: state?.Login?.ConfirmOTP,
  // isChangePassword: state.isChangePassword,
  // isSendMail: state.isSendMail,
  // isVerifyOTP: state.isVerifyOTP,
})
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getLogin, clearLoader, clearLoginData, PostSendOtp, PasswordChange, VerifyOTPChangePassword, PasswordChanges, ResendOTPs, LoginResetSuccess }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Loginwithotp))
