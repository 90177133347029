import React from 'react';
import Button from '../custombutton/button'
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import colors from '../../utils/colors/colors';
import color from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import DatePicker from '../../components/datepickers/date_pickers';
import CommonDropdown from '../../components/dropdown/dropdownschemes';
import Textinput from '../../components/textinput/textinput';
import moment from 'moment';
import { updatebankdetails, getSearchBranchDetails } from '../../redux/actions/profile/profile'
import { getAIFandPMSDetails } from "../../redux/actions/aifandpms/aifandpms_action";
import { numbersandDeciamlOnly, hasNull, round, notAllowdSpecialCharater, amountCommaSeperator, replaceComma } from '../../utils/commonfunction';
import CommonSnackbar from '../../components/snackbar/snackbar';
import { Listing } from '../../redux/actions/aifandpms/aifandpms_action';
import { bindActionCreators } from 'redux';
import getAddUpdateDeleteAssetTransaction from '../../api/aifandpms/add_update_delete_asset_transaction';
import { addUpdateDeleteTransaction, addUpdateDeleteCommitmentInstalment, updateAsset } from '../../redux/actions/aifandpms/aifandpms_action';
import getAddAssetValuation from '../../api/aifandpms/add_asset_valuation';
const styles = theme => ({

    col: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.between('320', '374')]: {
            marginTop: '4%',
            width: '94%',
        },
        [theme.breakpoints.between('375', '424')]: {
            marginTop: '4%',
            width: '96%',
        },
        [theme.breakpoints.between('425', '600')]: {
            marginTop: '4%',
            width: '98%',
        },
    },
    cols: {
        marginLeft: '2%',
        marginTop: '5%',
        width: '100%',
        display: "flex", flexDirection: "row",
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: ' wrap',
            justifyContent: 'center',
            marginLeft: '0%',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: ' wrap',
            justifyContent: 'center',
        },
    },
    // col3: {
    //     display: "flex",
    //     flexDirection: "row",modified by dulcy
    //     marginLeft: "20%"
    // },
    colheader1: {
        color: window.globalConfig.color.PRIMARY_BORDER,
        fontSize: "14px",
        textAlign: "left"
    },
    colheader2: {
        textAlign: "left",
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "14px"
    },

    header: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: window.globalConfig.color.WHITE,
        height: 50,
        fontWeight: 500,
        position: "sticky",
        top: "0px"
    },
    txtHeader: {
        padding: 15,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    dialogPaper: {
        width: "400px",
        // height: "174px",
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    dialogPaper2: {
        width: "auto",
        height: "auto",
    },
    footerStyle: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        bottom: 0,
        right: "18px",
        // position: "absolute",
        paddingBottom: 10,
        justifyContent: "flex-end"
    },
    clearIcon: {
        cursor: "pointer"
    }
});
let timer = ''
class AddTransationAndEvaluation extends React.Component {
    constructor() {
        super();
        this.state = {

            open: false,
            date: "",
            dateTemp: moment().format("DD MMM YYYY"),
            dateError: "",
            amount: "",
            amountError: "",
            transactionType: "",
            transactionTypeName: "",
            transactionTypeError: "",
            anchorEl: null,
            flag: "",
            message: "",
            severity: "",
            openSnackBar: false,
            selectedList: []

        }
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };
    onClosePopup = () => {
        this.setState({
            open: false,
            date: "",
            dateTemp: moment().format("DD MMM YYYY"),
            dateError: "",
            amount: "",
            amountError: "",
            transactionType: "",
            transactionTypeName: "",
            transactionTypeError: "",
            anchorEl: null,
            flag: "",
            // message: "",
            // severity: "success",
            openSnackBar: false,
            loader: false,
            addSaveMore: false
        })
        this.props.onClose()
    }
    handleDateClick = () => {
        this.setState({ anchorEl: true })
    }
    handleDateApply = (value) => {
        this.setState({ date: moment(value).format("DD MMM YYYY"), dateError: "" })
    }
    handleDateChange = (value) => {
        this.setState({ dateTemp: moment(value).format("DD MMM YYYY") })
    }
    handleDateClose = () => {
        this.setState({ anchorEl: null })
    }
    handleTransactionType = (value) => {
        this.setState({ transactionType: value[0].value, transactionTypeName: value[0].label, transactionTypeError: "" })
    }
    handleAmountChange = (e) => {

        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 14)) {
            this.setState({ amount: amountCommaSeperator(removeComma), amountError: "" })
        }
    }
    clearState = () => {
        this.setState({
            date: "",
            dateTemp: moment().format("DD MMM YYYY"),
            dateError: "",
            amount: "",
            amountError: "",
            transactionType: "",
            transactionTypeName: "",
            transactionTypeError: "",
            anchorEl: null,

            loader: false,
            addSaveMore: false
        })

    }
    handleSaveMore = (type) => {
        let isValid = true

        if (this.props.header.includes("Transaction")) {
            if (this.state.transactionType == "") {
                isValid = false
                this.setState({ transactionTypeError: labels.messages.required })
            }
        }
        if (this.state.amount == "") {
            isValid = false
            this.setState({ amountError: labels.messages.required })
        } else if (Number(replaceComma(this.state.amount)) == 0) {
            isValid = false
            this.setState({ amountError: `Amount should not be zero` })
        }
        if (this.state.date == "") {
            isValid = false
            this.setState({ dateError: labels.messages.required })
        }

        if (isValid) {
            if (this.props.header.includes("Transaction") || this.props.header.includes("Instalments")) {
                let data = {}
                if (this.props.transactionFlag == "Add" && this.props.header.includes("Transaction")) {
                    data.clientID = this.props.logindetails.ClientID,
                        data.alternatesStagingID = this.props.listID,
                        data.transactionAmountValue = Number(replaceComma(this.state.amount)),
                        data.transactionDate = this.state.date,
                        data.transactionType = this.state.transactionType,
                        data.flag = 1
                } else if (this.props.transactionFlag == "Add" && this.props.header.includes("Instalments")) {
                    data.clientID = this.props.logindetails.ClientID,
                        data.alternatesStagingID = this.props.listID,
                        data.transactionAmountValue = Number(replaceComma(this.state.amount)),
                        data.transactionDate = this.state.date,
                        data.flag = 4
                } else if (this.props.transactionFlag == "update") {
                    data.id = this.props.id
                    data.clientID = this.props.logindetails.ClientID,
                        data.alternatesStagingID = this.props.listID,
                        data.transactionAmountValue = Number(replaceComma(this.state.amount)),
                        data.transactionDate = this.state.date,
                        data.transactionType = this.state.transactionType,
                        data.flag = 2

                } else {
                    data.id = this.props.id
                    data.clientID = this.props.logindetails.ClientID,
                        data.flag = 3
                }
                if (type === "save") {
                    this.setState({ loader: true })
                } else {
                    this.setState({ addMoreLoader: true })
                }
                data.assetClassCode = this.props.productList.length == 0 ? 0 : this.props.productList.filter((item) => item.TEXT == this.props.page)[0].VALUE
                getAddUpdateDeleteAssetTransaction(data).then((response) => {

                    if (type === "save") {
                        this.setState({ loader: false })
                    } else {
                        this.setState({ addMoreLoader: false })
                    }
                    if (response.status == "S") {
                        this.props.getAIFandPMSDetails(0, this.props.logindetails.ClientID, this.props.productList.length == 0 ? 0 : this.props.productList.filter((item) => item.TEXT == this.props.page)[0].VALUE, 0)
                        if (this.props.transactionFlag === "update" && this.props.editCallBack != undefined) {
                            this.props.editCallBack(data, this.props.transactionFlag)
                        }
                        if (type === "save") {
                            timer = setTimeout(() => {
                                this.clearState()
                                this.onClosePopup()
                            }, 2000);
                        } else {
                            this.clearState()
                        }


                        this.setState({ openSnackBar: true, message: response.message, severity: "success" })
                    } else {
                        if (type === "save") {
                            timer = setTimeout(() => {
                                this.clearState()
                                this.onClosePopup()
                            }, 9000);
                        } else {
                            this.clearState()
                        }

                        this.setState({ openSnackBar: true, message: response.message, severity: "warning" })

                    }

                }).catch((err) => {
                    this.clearState()
                    if (type === "save") {
                        this.onClosePopup()
                        this.setState({ loader: false })
                    } else {
                        this.setState({ addMoreLoader: false })
                    }
                    this.setState({ openSnackBar: true, message: labels.messages.apierror, severity: "warning" })
                })
            }
            else {
                let data = {}
                data.clientID = this.props.logindetails.ClientID
                data.alternatesStagingID = this.props.listID
                data.valuationAmountValue = Number(replaceComma(this.state.amount))
                data.valuationDate = this.state.date
                data.assetClassCode = this.props.productList.length == 0 ? 0 : this.props.productList.filter((item) => item.TEXT == this.props.page)[0].VALUE
                this.setState({ loader: true })
                getAddAssetValuation(data).then((response) => {
                    if (response.status == "S") {
                        this.setState({ openSnackBar: true, loader: false, message: response.message, severity: "success" })
                        if (type === "save") {
                            let updateData = {}
                            updateData.ID = this.props.listID
                            updateData.CurrentValue = Number(replaceComma(this.state.amount))
                            this.props.updateAsset(updateData, this.props.page)
                            this.clearState()
                            // this.props.getAIFandPMSDetails(0, this.props.logindetails.ClientID, this.props.productList.length == 0 ? 0 : this.props.productList.filter((item) => item.TEXT == this.props.page)[0].VALUE, 0)
                            timer = setTimeout(() => {
                                    this.onClosePopup()
                            }, 1000);
                        } else {
                            this.clearState()
                        }
                    } else {
                        this.setState({ openSnackBar: true, loader: false, message: response.message, severity: "warning" })
                    }
                }).catch((err) => {
                    this.setState({ openSnackBar: true, loader: false, message: labels.messages.apierror, severity: "warning" })

                })
            }
        }
    }
    componentWillUnmount() {
        clearTimeout(timer)
    }
    componentDidMount() {
        this.setState({ flag: this.props.transactionFlag, id: this.props.id })
    }
    componentDidUpdate(prev) {
        const { editList, listData, transactionFlag } = this.props

        if (prev.editList != editList) {
            if (editList.length != 0) {

                this.setState({ amount: editList[0]?.amount == "" ? "" : editList[0]?.amount, dateTemp: editList[0].transactionDate == "NA" || editList[0].transactionDate == "" ? moment().format("DD MMM YYYY") : editList[0].transactionDate, date: editList[0].transactionDate == "" ? "" : editList[0].transactionDate, transactionType: this.props.transactionTypeList.length != 0 ? this.props.transactionTypeList.filter((item) => item.label == editList[0].transactionType == "" ? this.props.transactionTypeList[0].label : editList[0].transactionType)[0].value : "NA", transactionTypeName: editList[0].transactionType == "" ? this.props.transactionTypeList[0].label : editList[0].transactionType, })
                if (editList[0]?.IsInvested == 0) {
                    this.setState({ enable: true })
                }

            }
            this.setState({ selectedList: editList[0] })
        }

    }
    handleFocus = (event) => event.target.select();
    handleToastClose = () => {
        this.setState({ openSnackBar: false })
    }
    render() {
        const { classes } = this.props;
        return (<div>
            <Dialog

                open={this.props.open}
                onClose={this.onClosePopup}
                onScroll={this.props.onScroll}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: classes.dialogPaper }}
                style={this.props.style}

            >
                <div className={classes.popupcard}>
                    <div className={classes.header}>
                        <div className={classes.txtHeader}>{this.props.header}
                            <span className={classes.clearIcon} onClick={this.onClosePopup}>&#x2715;</span>
                        </div>
                    </div>
                    <div style={{ padding: 15, display: "flex", flexDirection: "column", gap: 15 }}>

                        <DatePicker
                            handleClick={(e) => this.handleDateClick(e)}
                            handleApply={this.handleDateApply}
                            showingdate={this.state.dateTemp}
                            dateChange={this.handleDateChange}
                            handleClose={this.handleDateClose}
                            anchorEl={this.state.anchorEl}
                            error={this.state.dateError}
                            maxdate={moment().format("DD MMM YYYY")}
                            id="dp_aifInvestmentDate"
                            label={labels.PmsAndAif.date}
                            placeholder={labels.PmsAndAif.datePlaceholder}
                            date={this.state.date} />

                        {this.props.flag == "Transaction" && <>{this.state.selectedList.IsInvested == 0 || this.props.transactionFlag == "update" ?
                            <div>
                                <span style={{ color: colors.LABLE_GRAY, fontSize: 14, paddingBottom: 6, textAlign: "left" }}>{labels.PmsAndAif.transactionType}</span>
                                <div style={{ height: 21, fontSize: 15, border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, padding: 10, display: "flex", alignItem: "center", color: window.globalConfig.color.BLACK_PRIMARY }}>{this.state.transactionTypeName}</div>
                            </div>
                            : <CommonDropdown
                                handleOnChange={this.handleTransactionType}
                                error={this.state.transactionTypeError}
                                type={"normalwithsearch"}
                                value={this.state.transactionTypeName}
                                placeholder={labels.PmsAndAif.transactionTypePlaceholder}
                                label={labels.PmsAndAif.transactionType}
                                height={190}
                                id="ddl_assetClass"
                                List={this.props.transactionTypeList}
                            />}</>
                        }
                        <Textinput handleChange={this.handleAmountChange}
                            value={this.state.amount}
                            error={this.state.amountError}
                            align={"right"}
                            otherProps={{ autoFocus: true, name: "amount", onFocus: this.handleFocus, }}
                            page={"normal"} label={labels.PmsAndAif.amount}
                            placeholder={labels.messages.amountZeroPlaceholder}
                        />
                    </div>
                    <div className={classes.footerStyle}>
                        {this.props.transactionFlag == "update" && <div style={{ width: "22%", marginLeft: 10, marginRight: 15 }}>
                            <Button page={"secondary"} disabled={this.state.loader || this.state.addMoreLoader} name={labels.messages.cancel} onClick={this.onClosePopup} loader={this.props.loader} show={this.props.loader} loading={"true"} />
                        </div>}
                        <div style={{ width: "22%", marginRight: 15 }}>
                            <Button page={"primary"} disabled={this.state.loader || this.state.addMoreLoader} name={this.props.transactionFlag == "update" ? "Update" : labels.messages.saveButton} onClick={() => this.handleSaveMore("save")} loader={this.props.loader} show={this.props.loader} loading={"true"} />
                        </div>
                        {this.props.transactionFlag != "update" &&
                            <div style={{ marginLeft: 10, marginRight: 15 }}>
                                <Button page={"primary"} disabled={this.state.loader || this.state.addMoreLoader} name={labels.messages.saveAddMoreButton} onClick={() => this.handleSaveMore("addMore")} loader={this.state.addMoreLoader} show={this.state.addMoreLoader} loading={"true"} />
                            </div>}

                    </div>
                </div>
            </Dialog>

            <CommonSnackbar message={this.state.message} open={this.state.openSnackBar} severity={this.state.severity} handlesnanckClose={this.handleToastClose} />

        </div>
        )
    }
}
const mapStateToProps = state => ({
    login: state.Login.loginError,
    productList: state.Dataentry.alternativeAsset != undefined && state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset.table12 != "NA" && state.Dataentry.alternativeAsset.table12.length != 0 ?
        state.Dataentry.alternativeAsset?.table12 : [],
    logindetails: state.Login.logindetails,
    transactionTypeList: state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset.length != 0 && state.Dataentry.alternativeAsset.table6 != "NA" && state.Dataentry.alternativeAsset.table6.length != 0 ? state.Dataentry.alternativeAsset.table6.map((item) => {
        return { "label": item.TransactionType, "value": item.ID }
    }) : [],
    aifList: state.AIFandPMS.aifList

})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ updateAsset, addUpdateDeleteTransaction, addUpdateDeleteCommitmentInstalment, getAIFandPMSDetails, Listing }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddTransationAndEvaluation))

