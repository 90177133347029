import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import '../../utils/stylesheets/tradingdropdown/dropdown.css';
import CalenderImage from '../../utils/assets/images/group_3740.png';
import Calendar from '../daterangepicker/calender';
import Popover from '@material-ui/core/Popover';
import Label from '../../utils/constants/labels'
import color from "../../utils/colors/colors";
import useKeyPress from "../dropdown/useRoveFocus";
import colors from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import moment from 'moment';
const useStyles = makeStyles(theme => ({

  gray: {
    fontSize: 14,
    padding: 10,
    fontWeight: "400 !important"
  },
  CalenderImagestyle: {
    float: "right", 
    clear: "right", 
    paddingLeft: 3, 
    height: 30, 
    width: 30, 
    cursor: "pointer"
  },
  inActiveImg:{
     filter:"blur(1px)"
  },
  active: {
    // height: 30,
    minWidth: 190,
    textAlign: "left",
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    justifyContent: "space-between",
    display: 'flex',
    alignItems: 'center',
    height: "43px",
    paddingLeft: 10,
    cursor: "pointer",
    paddingRight: 10,
    // paddding:'3% 2%',
    "&:focus": {
      border: `1px solid ${window.globalConfig.color.BROWN}`
    },
    [theme.breakpoints.between("928", "1050")]: {
      //  paddingTop: "3%",
      minWidth: 160
    },
    [theme.breakpoints.between("300", "928")]: {
      //  paddingTop: "3%",
      minWidth: 'unset'
    },
    // [theme.breakpoints.between("350", "400")]: {
    //   paddingTop: "3%",
    //   width: 'unset'
    // },
    // [theme.breakpoints.between("400", "600")]: {
    //   paddingTop: "3%",
    //   width: 'unset'
    // }
  },
  inactive: {
    // height: 30,
    minWidth: 190,
    textAlign: "left",
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    justifyContent: "space-between",
    display: 'flex',
    alignItems: 'center',
    // paddingTop: "5%",
    // paddingLeft: "2%",
    // paddingRight: "2%",
    // paddingBottom: "3%",
    color: window.globalConfig.color.LABLE_GRAY,
    height: "43px",
    paddingLeft: 10,
    cursor: "not-allowed",
    opacity: '0.5px',
    paddingRight: 10,
    [theme.breakpoints.between("928", "1050")]: {
      //  paddingTop: "3%",
      minWidth: 160
    },
    [theme.breakpoints.between("300", "928")]: {
      // paddingTop: "3%",
      minWidth: 'unset'
    },
    // [theme.breakpoints.between("350", "400")]: {
    //   paddingTop: "3%",
    //   width: 'unset'
    // },
    // [theme.breakpoints.between("400", "600")]: {
    //   paddingTop: "3%",
    //   width: 'unset'
    // }
  },

  errorstyle: {
    color: window.globalConfig.color.red,
    fontSize: 13,
    // marginLeft: '-82.8%',
    //  marginTop: '2%'
  },
  cardSpareaterLine: {
    height: 0.5,
    width: "100%",
    backgroundColor: window.globalConfig.color.PRIMARY_BORDER,
  },

  colorChange: {
    color: window.globalConfig.color.GRAY_LIGHT,
    fontSize: 14,
    fontWeight: 'normal'
  },
  colorChangeDate: {
    color: window.globalConfig.color.GRAY_LIGHT,
    fontSize: 14,
    // paddingTop: "10px",
    fontWeight: 'normal'
  },
  dateAlign: {
    // marginTop: 20
  },
  labelDropdownGap: {
    marginBottom: '1%'
  },
  flexResponsive: {
    display: "flex",
    flexDirection: "column",
    // minHeight: '90px',
    // justifyContent:'center',
    [theme.breakpoints.down('928')]: {
      width: "100%"
    },
    [theme.breakpoints.down('xs')]: {
      width: "100%"
    }
  }
  //Ended
}));
export default function Datepicker(props) {
  const inputRef = useRef();
  const classes = useStyles();
  const TabPressed = useKeyPress("Tab");
  const EnterPressed = useKeyPress("Enter");
  // const [dateValue,setdateValue]=useState('')
  const [anchorEl, setanchorEl] = useState(null)

  useEffect((e) => {
    var doc = document.activeElement
    if (EnterPressed) {
      if (doc.id === `datepicker-${props.id}`) {
        //
        inputRef.current.click()
      }
    }
    //
  }, [EnterPressed, inputRef]);
  const handleClick = (e) => {
    setanchorEl(e.currentTarget)
  }
  const handleClose = () => {
    setanchorEl(null)
  }
  return (<>
    <div className={classes.flexResponsive} >
      {props.label != undefined && <span style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 14, paddingBottom: "6px", textAlign: "left" }}>{props.label}</span>
      }{props.allowed ?
        <div style={props.style == undefined ? {} : props.style} className={props.inactiveclass == undefined ? classes.inactive : props.inactiveclass} >
          {props.date == ""||props.date==undefined||props.date==null||props.date=="NA" ? <span style={{ color: window.globalConfig.color.BORDER, fontSize: 14, fontWeight: "400" }} >{props.placeholder}
          </span> : <span className={[classes.date, classes.gray].join(' ')} >{props.date}
          </span>}
          <img src={CalenderImage} className={[classes.CalenderImagestyle, classes.inActiveImg].join(" ")} style={{cursor:"not-allowed"}}/>
        </div> : <div
          ref={el => { inputRef.current = el; }}
          style={props.style == undefined ? {} : props.style}
          tabIndex={0} id={`datepicker-${props.id}`} className={props.activeclass == undefined ? classes.active : props.activeclass} onClick={(e) => handleClick(e)}  >
          {props.date == "" ||props.date==undefined||props.date==null||props.date=="NA"? <span style={{ color: window.globalConfig.color.BORDER, fontSize: 14, fontWeight: "400" }} >{props.placeholder == undefined || props.placeholder == "" ? labels.messages.select : props.placeholder}
          </span> : <span className={[classes.date, classes.gray].join(' ')} >{props.date}
          </span>}
          <img src={CalenderImage} className={classes.CalenderImagestyle} onClick={(e) => handleClick(e)} />
        </div>}
      {props.error != "" && <span className={classes.errorstyle} style={{ textAlign: "left" }}>{props.error}</span>
      }

    </div>
    {/* <div onWheel={handleClose}> */}
    <Popover
      className={props.popover}
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}

    >
      <div style={{ zIndex: 0 }} >
        <div>
          <Calendar
            divStyle={{ padding: "0px" }}
            date={props.showingdate==undefined||props.showingdate==""?moment().format("DD MMM YYYY"):props.showingdate}
            shownDate={props.date == "" ? props.showingdate : props.date}
            calenderpage={props.calenderpage}
            // maxDate={moment().format()}
            maxDates={props.maxdate}
            minDates={props.mindate}
            onDateChange={(value) => {
              props.dateChange(value)
              if (props.handleApply != undefined) {
                props.handleApply(value)
              }
              handleClose()
            }}
          />
        </div>

      </div>
    </Popover>
    {/* </div> */}
  </>
  )
}

