import secureStorage from '../securestorage'
export const basicColor = {
  PRIMARY: "#1359a0",
  PRIMARY_LIGHT: "rgb(48, 105, 175)",
  BROWN: "#af762e",
  RosyBrown: "#fffbf3",//use #faf7f2 
  LIGHT_ROSE: "#fff7f5",
  BOX_SHADOW: "rgb(19 89 161 / 10%)",
  BLACK_PRIMARY: "#38465F",//'#1e1f1f',
  LABLE_GRAY: "#626E84",//"#909090",//need to rename the name
  PRIMARY_BORDER: '#c7c7c7',//used rename Primary border,
  BORDER: "#e6e7ea",
  WHITE: '#FFFFFF',
  GREEN: '#13a84c',//use
  red: '#e12d2d', ///Error color Red
  WARNING: "#ff9800",//use
  LIGHT_GREEN_BACKGROUND: "rgb(231, 246, 237)",
  LIGHT_RED_BACKGROUND: "rgb(252, 233, 233)",
  cyanBluish: '#c6d3e1',//usergb(250, 250, 250)
  ZERO_BACKGROUND: "#f4f6f9",//rename to halfwhite 
  TABLE_HEADER: "#f0f0f0",
}
window.globalConfig = {
  ...window.globalConfig,
  color: secureStorage.getItem("colors") != null ? secureStorage.getItem("colors") : basicColor
}
const colors = window.globalConfig.color
export default colors