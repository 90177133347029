import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import moment, { updateLocale } from 'moment';
import labels from '../../utils/constants/labels';
import Button from '../../components/custombutton/button';
import FilterImage from "../../utils/assets/images/filter.png";
import InsuranceBlockContainer from '../../components/block/Insuranceblockcontainer';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import { Redirect } from 'react-router-dom';
import { replaceNull } from '../../utils/commonfunction'
import SchemeDropdown from "../../components/dropdown/dropdownschemes";
import FilterPopup from "../../components/filter/filterpopup";
import SelectedFilterImage from "../../utils/assets/selectedfilter.png";
import hoverSelectedFilterImage2 from "../../utils/assets/img23-Copy.png";
import { getL5reportDetails } from "../../redux/actions/L5Report/L5ReportAction";
import hoverFilterIcons from "../../utils/assets/img23.png";
import GetInsuranceReport from '../../api/insurance/insurance_report';
import { bindActionCreators } from 'redux';
import NoData from "../../components/nodata/nodata";
import Loader from "../../components/loader/loader";
import Headers from "../../components/report_common_header/report_common_header";
const styles = theme => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '100%',
        marginTop: "2%"
    },
    card: {
        marginBottom: '40px',
    },
    buttonContainer: {
        display: 'flex',
        columnGap: '20px',
        width: '90%',
        padding: '20px 0px',
        flexDirection: 'row',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        },
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            flexDirection: 'column',
            rowGap: '10px',
        },
    },
    filterContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)',
        alignItems: 'center',
        justifyContent: 'center',
    },
    filterIcon: {
        width: "29px",
        height: "29px",
        cursor: "pointer",
    },
    align: {
        padding: '35px 0px 0px 0px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignIitems: 'inherit',
        [theme.breakpoints.down("sm")]: {
            padding: '10px 0px 0px 0px',
        },
    },
    sumBoxFil2: {
        float: "left",
        // width: "7%",
    },
    selectedFilterImagestyle: {
        width: 35,
        height: 37,
        background: `url(${SelectedFilterImage}) center center no-repeat`,
        position: "absolute",
        cursor: "pointer",
        "&:hover": {
            background: `url(${hoverSelectedFilterImage2}) center center no-repeat`,
        },
        [theme.breakpoints.between("320", "900")]: {
            left: 0,
            marginLeft: 0,
            top: "revert",
        },
    },
    filterImagestyle: {
        [theme.breakpoints.up("900")]: {
            width: 35,
            height: 37,
            background: `url(${FilterImage}) center center no-repeat`,
            position: "absolute",
            cursor: "pointer",
            "&:hover": {
                background: `url(${hoverFilterIcons}) center center no-repeat`,
            },
        },
        [theme.breakpoints.down("900")]: {
            position: "unset",
            paddingTop: "27px",
            width: 35,
            height: 37,
            left: 0,
            background: `url(${FilterImage}) center center no-repeat`,
        },
    },
    noDataCard: {
        minWidth: '100%',
        padding: '10% 0px',
    }

});
class InsuranceReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            lifeInsurance: false,
            healthInsurance: false,
            generalinsurance: false,
            open: true,
            flag: 'Health',
            list: [],
            loader: false,
            lifeInsuranceList: [],
            healthInsuranceList: [],
            generalInsuranceList: [],
            apply: false,
            insuranceList: [],
            insuranceListBackup: []
        }
    }

    componentDidMount() {
        // let data = {}
        // data.Id = 1,
        //     data.clientID = this.props.clientID,
        //     data.L5ID = this.props.L5ID,
        //     data.L4ID = this.props.L4ID,
        //     this.setState({
        //         loader: true,
        //     })
        // //api call
        // GetInsuranceReport(data).then((response) => {
        //     this.setState({
        //         loader: false,
        //     })
        //     if (response.status == "S") {
        //         var responseData = this.props.flag == "Health" ? response.table1 : response.table2
        //         if (responseData != null) {
        //             const identifyHealthInsuranceCategory = responseData != null ? responseData.length != 0 ? responseData : [] : []
        //             const groupByHealthCategory = identifyHealthInsuranceCategory != null ? identifyHealthInsuranceCategory.length != 0 ? replaceNull(identifyHealthInsuranceCategory.filter((ele, ind) => ind === identifyHealthInsuranceCategory.findIndex(elem => elem.Category === ele.Category)), labels.messages.NA) : [] : []
        //             var groupedHealth = groupByHealthCategory.length != 0 ? groupByHealthCategory.map((items) => {
        //                 return {
        //                     catagories: replaceNull(items.Category, labels.messages.NA),
        //                     data: replaceNull(identifyHealthInsuranceCategory.filter((val) => val.Category === items.Category), labels.messages.NA),
        //                 }
        //             }) : []
        //             this.setState({ insuranceList: groupedHealth, insuranceListBackup: responseData })
        //         }
        //     } else {
        //         this.setState({ loader: false, })
        //     }
        // }).catch((err) => {
        //     this.setState({ loader: false })
        // })
    }
    componentDidUpdate(prev) {
        // const { clientID, date,L5ID } = this.props;
        // if (prev.clientID != clientID || prev.date != date||prev.L5ID!=L5ID) {
        //     if (clientID != null||date!=null) {
        //         let data = {}
        //         data.Id = 1,
        //             data.clientID = this.props.clientID,
        //             data.L5ID = this.props.L5ID,
        //             data.L4ID = this.props.L4ID,

        //             this.setState({
        //                 loader: true,
        //             })

        //         GetInsuranceReport(data).then((response) => {
        //             this.setState({
        //                 loader: false,
        //             })
        //             if (response.status == "S") {
        //                 var responseData = this.props.flag == "Health" ? response.table1.filter((item)=>item.InsuranceTypeID==1) :this.props.flag=="General"?response.table1.filter((item)=>item.InsuranceTypeID==2): response.table2

        //                 if (responseData != null) {

        //                     // health insurance flag ==>InsuranceTypeID =1
        //                     const identifyHealthInsuranceCategory = responseData.length != 0 ? responseData : []


        //                     const groupByHealthCategory = identifyHealthInsuranceCategory != null ? identifyHealthInsuranceCategory.length != 0 ? replaceNull(identifyHealthInsuranceCategory.filter((ele, ind) => ind === identifyHealthInsuranceCategory.findIndex(elem => elem.Category === ele.Category)), labels.messages.NA) : [] : []

        //                     var groupedHealth = groupByHealthCategory.length != 0 ? groupByHealthCategory.map((items) => {
        //                         return {
        //                             catagories: replaceNull(items.Category, labels.messages.NA),
        //                             data: replaceNull(identifyHealthInsuranceCategory.filter((val) => val.Category === items.Category), labels.messages.NA),
        //                         }
        //                     }) : []

        //                     this.setState({ insuranceList: groupedHealth, insuranceListBackup: responseData })


        //                 }

        //             } else {
        //                 this.setState({ loader: false, })
        //             }
        //         }).catch((err) => {
        //             this.setState({ loader: false })
        //         })
        //     }
        // }

    }
    lifeInsuranceClick = () => {
        this.setState({
            lifeInsurance: true,
            healthInsurance: false,
            generalinsurance: false,
            flag: 'Life'
        })
    }
    handleclear = () => {
        this.props.submit([])
    }
    render() {
        const { classes } = this.props;
        // console.log(this.props.insuranceListBackup.filter((ele, ind) => ind === this.props.insuranceListBackup.findIndex(elem => elem.Type === ele.Type)).map((item) => {
        //     return { ...item, schemeName: item.InsurancePolicyName == undefined ? item.PolicyName : item.InsurancePolicyName }
        // }))
        //  console.log(this.props.insuranceListBackup,this.props.insuranceList)
        try {
            return (
                <ErrorBoundary>
                    {this.props.loader ? <Loader page="skeletonreport"></Loader> : <div>  <Headers
                        flag={this.props.flag}
                        showfilter={true}
                        header={"Policy"}
                        multipleListHeader={"Policies"}
                        submit={this.props.submit}
                        scheme={this.props.backup.length == 0 ? <NoData></NoData> : this.props.backup.length <= 1 ? `${this.props.backup.length} ${"Policy"}` : `${this.props.backup.length} ${"Policies"}`}
                        page={this.props.flag}
                        data={this.props.insuranceListBackup}
                        List={this.props.insuranceListBackup}
                        selected={false}
                    />
                        <div className={classes.root}>
                            <div className={classes.card}>
                                {this.props.insuranceList.length != 0 &&
                                    this.props.insuranceList?.sort((a, b) => {
                                        var textA = a.catagories?.toUpperCase();
                                        var textB = b.catagories?.toUpperCase();
                                        return textA < textB ? -1 : textA > textB ? 1 : 0;
                                    }).map((item) => {
                                        return <InsuranceBlockContainer
                                            flag={this.props.flag}
                                            list={item}
                                            loader={this.props.loader}
                                        />
                                    })
                                    // :
                                    // <div className={classes.noDataCard}>
                                    //     <NoData />
                                    // </div>
                                }
                            </div>
                        </div></div>}
                </ErrorBoundary>
            )
        }
        catch (err) {
            // console.log(err)
            return <Redirect to={labels.Path.NotFound}></Redirect>
        }
    }
}
const mapStateToProps = (state) => {
    return {
        sessionID: state.Login.sessionID,
        clientID: state.Login.logindetails.ClientID,
        L5ID: state.Login.L5ID,
        date: state.Login.date,
        L4ID: state.Login.logindetails.L4_AccountTypeID,
        login: state.Login.logindetails,
    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getL5reportDetails,
    }, dispatch)
};
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(InsuranceReport))