import * as types from '../../constants/actionsType';
import getAllBrokerListApi from '../../../api/Dataentry/broker';
import getTransactionDropdown from '../../../api/fixed_deposit/transaction_dropdown';
import getAssetNameList from '../../../api/fixed_deposit/get_assetname';
import GetFDRNumber from '../../../api/fixed_deposit/fdr_dropdown';
import getAlternativeAsset from '../../../api/alternative_asset/alternative_asset';
import getNPSandInsuranceDropdownList from '../../../api/Dataentry/nps_insurance_dropdown';
import { replaceNull } from '../../../utils/commonfunction';
export function getAllBrokerList() {
  return (dispatch) => {
    getAllBrokerListAction(dispatch);
  };
}
function getAllBrokerListAction(dispatch) {
  dispatch({
    type: types.EMPTY_EQUITY_BROKER,
    payload: [],
  });
  dispatch({
    type: types.LOADER_EQUITY_BROKER,
    payload: true,
  });
  //   dispatch({
  //     type: types.LOADER_DROPDOWN,
  //     payload: true,
  //   });
  getAllBrokerListApi()
    .then((data) => {

      if (data != undefined) {
        if (data.status == "S") {
          //
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "BrokerList" }],
          });
          dispatch({
            type: types.EQUITY_BROKER,
            payload: data,
          });
          dispatch({
            type: types.LOADER_EQUITY_BROKER,
            payload: false,
          });


          //   dispatch({
          //     type: types.LOADER_DROPDOWN,
          //     payload: false,
          //   });
        } else {
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "BrokerList" }],
          });
          dispatch({
            type: types.LOADER_EQUITY_BROKER,
            payload: false,
          });

        }
      } else {
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: 0, api: "BrokerList" }],
        });
        dispatch({
          type: types.LOADER_EQUITY_BROKER,
          payload: false,
        });

      }
    })
    .catch((error) => {
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: 0, api: "BrokerList" }],
      });
      //   dispatch({
      //     type: types.LOADER_DROPDOWN,
      //     payload: false,
      //   });
    })
}
// transitionstype action creation
export function getTransactionDropdowns() {
  return (dispatch) => {
    getTransactionDropdownsAction(dispatch);
  };
}
function getTransactionDropdownsAction(dispatch) {
  dispatch({
    type: types.EMPTY_TRANSACTION_TYPE_DROPDOWN,
    payload: [],
  });
  dispatch({
    type: types.LOADER_TRANSACTION_TYPE_DROPDOWN,
    payload: true,
  });
  getTransactionDropdown()
    .then((data) => {
      if (data != undefined) {
        if (data.status == "S") {
          dispatch({
            type: types.GET_TRANSACTION_TYPE_DROPDOWN,
            payload: data,
          });
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "TransactionTypeDropdown" }],
          });

          dispatch({
            type: types.LOADER_TRANSACTION_TYPE_DROPDOWN,
            payload: false,
          });
          // 
        } else {
          dispatch({
            type: types.GET_TRANSACTION_TYPE_DROPDOWN,
            payload: [],
          });
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "TransactionTypeDropdown" }],
          });

          dispatch({
            type: types.LOADER_TRANSACTION_TYPE_DROPDOWN,
            payload: false,
          });
        }
      } else {
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: 0, api: "TransactionTypeDropdown" }],
        });

        dispatch({
          type: types.LOADER_TRANSACTION_TYPE_DROPDOWN,
          payload: false,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: 0, api: "TransactionTypeDropdown" }],
      });

      dispatch({
        type: types.LOADER_TRANSACTION_TYPE_DROPDOWN,
        payload: false,
      });
    })
}
export const getAssetNameAction = (AssetID,AssetName,AssetClassCode) => {
  return {
    type: types.GET_ASSET_NAME,
     AssetID,AssetName,AssetClassCode
  }

}



// common  alternative dropdown 
export function getAlterativeAsset() {
  return (dispatch) => {
    getAlterativeAssetAction(dispatch);
  };
}
function getAlterativeAssetAction(dispatch,) {
  getAlternativeAsset()
    .then((data) => {
      // console.log(data)
      // if (data != undefined) {
        if (data.status == "S") {
          dispatch({
            type: types.GET_ALTERNATIVE_LIST,
            payload:replaceNull(data,"NA"),
          });
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "AlternativeAsset" }],
          });

          dispatch({
            type: types.LOADER_ALTERNATIVE_LIST,
            payload: false,
          });
        }
        else{
          dispatch({
            type: types.GET_ALTERNATIVE_LIST,
            payload: [],
          });
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "AlternativeAsset" }],
          });

          dispatch({
            type: types.LOADER_ALTERNATIVE_LIST,
            payload: false,
          });
        }
      // }
      // else {
      //   dispatch({
      //     type: types.PREVENT_API_CALL,
      //     payload: [{ ProductID: 0, api: "AlternativeAsset" }],
      //   });

      //   dispatch({
      //     type: types.LOADER_ALTERNATIVE_LIST,
      //     payload: false,
      //   });
      // }
    })
    .catch((error) => {
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: 0, api: "AlternativeAsset" }],
      });

      dispatch({
        type: types.LOADER_ALTERNATIVE_LIST,
        payload: false,
      });
    })
}

// common  nps and insurance dropdown 
export function getNPSAndInsuranceDropdownList() {
  return (dispatch) => {
    getNPSAndInsuranceDropdown(dispatch);
  };
}
function getNPSAndInsuranceDropdown(dispatch,) {
 
  getNPSandInsuranceDropdownList()
    .then((data) => {
      if (data != undefined) {
        if (data.status == "S") {
          dispatch({
            type: types.NPS_INSURANCE_DROPDOWN_LIST,
            payload: data,
          });
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "NpsandInsuranceDropdown" }],
          });         
        }
        else{
          dispatch({
            type: types.NPS_INSURANCE_DROPDOWN_LIST,
            payload: [],
          });
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "NpsandInsuranceDropdown" }],
          });

         
        }
      }
      else {
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: 0, api: "NpsandInsuranceDropdown" }],
        });

      
      }
    })
    .catch((error) => {
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: 0, api: "NpsandInsuranceDropdown" }],
      });

      
    })
}
export const updateAsset=(data)=>{
  return{
    type:types.UPDATE_ALTERNATIVE_LIST,
    payload:data
  }
}
