/* name should be camel case in all function */
//const BaseUrl='https://jsonplaceholder.typicode.com/todos';
import Networking from '../../utils/api/apiaccess';
import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
function getsimilarSchemeDetails(SchemeID) {
    //   return fetch(`${ApiValues.SimilarScheme}${SchemeID}`, {
    //     method: 'GET',
    //      })
    //     .then((response) => response.json())
    //     //If response is in json then in success
    //     .then((responseJson) => {
    //         return responseJson

    //     })
    //     //If response is not in json then in error
    //     .catch((error) => {
    //         //Error 
    //         return { "status": "F","message":labels.messages.apierror }

    //     });
    return Networking.GetApi(`${ApiValues.SimilarScheme}${SchemeID}`, "").then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
    // fetch method ends here

}
export default getsimilarSchemeDetails;
