import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
function getPaymentGatewayDetails(Clientcode,orderid) {
    var data = JSON.stringify({"ClientCode":Clientcode,"LogoutURL":`${window.globalConfig.Domain6}/payment/${orderid}`});
    

  return Networking.PostApi(ApiValues.PaymentGateway, data,labels.apiKey.apiKeys).then(result => { return result })
  .catch(function (error) {
    return { "status": "F", "message": labels.messages.apierror }
  });
    
}
export default getPaymentGatewayDetails
