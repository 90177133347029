import React from "react";
import { withStyles } from '@material-ui/core';
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CommonSnackbar from '../../components/snackbar/snackbar';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NoData from "../../components/nodata/nodata";
import Loader from "../../components/loader/loader";
import Headers from "../../components/report_common_header/report_common_header";
import Badges from '../../components/badge/badge';
import { NegativeFinding, replaceNull } from '../../utils/commonfunction'
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { Redirect } from "react-router-dom";
import { Label } from "@material-ui/icons";
import labels from "../../utils/constants/labels";
const styles = theme => ({
    iconShowDesktop: {
        cursor: "pointer"
    },
    header: {
        [theme.breakpoints.down('920')]: {
            display: "none !important"
        }
    },
    mobileHeader: {
        display: "none",
        [theme.breakpoints.down('920')]: {
            display: "flex !important",
            fontSize: "smaller",
            color: window.globalConfig.color.LABLE_GRAY
        }

    },
    maturityTrackerContainer: {
        display: "grid",
        gridTemplateColumns: "18% 15% 15% 13% 14% 12% 12% 2%",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(3,1fr)",
            gridTemplateAreas: `"${"myFirstGrid myFirstGrid ."}"`,
            height: "auto !important",
            rowGap: "20px",
            paddingTop: 10,
            display: "grid",
        }
    },
    alignStart: {
        display: "flex", flexDirection: "column", alignItems: "flex-start", gap: 10,
        [theme.breakpoints.down('920')]: {
            gap: 0
        }
    },
    alignEnd: {
        display: "flex", flexDirection: "column", alignItems: "flex-end", gap: 10,
        [theme.breakpoints.down('920')]: {
            gap: 0
        }
    },
    alignBaseLine: {
        [theme.breakpoints.down('920')]: {
            alignItems: "baseline !important"
        }
    },
    startDate: {
        [theme.breakpoints.down('920')]: {
            alignItems: "flex-start",
            display: "flex",
            textAlign: "left"
        }
    },
    accessGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: 'myFirstGrid',
            width: "fit-content"
        }
    },
    setMaxWidth: {
        width: "max-content"
    }

})
const table = [{ "header": "Issuer Name", "subHeader": "" },
{ "header": "Investment Amount", "subHeader": "Frequency" },
{ "header": "Rate of Interest", "subHeader": "Type" },
{ "header": "Start Date", "subHeader": "Tenure" }
    , { "header": "Maturity Date", "subHeader": "Time Left" },
{ "header": "Maturity Option", "subHeader": "" },
{ "header": "Status", "subHeader": "" }]
class MaturityTracker extends React.Component {
    constructor() {
        super();
        this.state = {
            maturitydata: [],
            checked: false,
            anchorEl: false,
            selectedList: [],
            maturityFilterData: []
        }
    }

    componentDidMount() {
        this.props.filterSubmit([])
        if (replaceNull(this.props.List, labels.messages.NA) != "NA") {
            let data = this.props.List.filter(
                (ele, ind) =>
                    ind ===
                    this.props.List.findIndex(
                        (elem) => elem.Category === ele.Category
                    )
            )
            this.setState({
                maturitydata: data.map((response) => {
                    return {
                        Category: response.Category,
                        data: this.props.List.filter((item) => item.Category === response.Category)
                    }
                })
            }, () => this.callBackMaturity(this.state.maturitydata))
        }

    }
    componentDidUpdate(prev) {
        const { List } = this.props
        if (prev.List != List && List != undefined) {

            let data = List.filter(
                (ele, ind) =>
                    ind ===
                    List.findIndex(
                        (elem) => elem.Category === ele.Category
                    )
            )
            this.setState({
                maturitydata: data.map((response) => {
                    return {
                        Category: response.Category,
                        data: List.filter((item) => item.Category === response.Category)
                    }
                })
            }, () => this.callBackMaturity(this.state.maturitydata))
        }

    }

    callBackMaturity = (maturitydata) => {
        this.setState({
            maturityfilter: maturitydata[0]?.data
        })
    }

    handleChange = (checked) => {
        this.setState({ checked: !this.state.checked });
        this.props.filterSubmit([]);

    };
    handleClick = (e, value) => {
        this.setState({ selectedList: value, anchorEl: e.currentTarget })
    }
    handleListItemClick = () => {

    }
    handleClose = () => {
        this.setState({ anchorEl: null })
    }
    submit = (value) => {
        this.props.filterSubmit(value)
    }
    render() {
        const { classes } = this.props
        try {
            return (<div style={{ marginBottom: "5%" }}>
                {this.props.isLoading ? <Loader page="skeletonreport"></Loader> :

                    <>  <Headers
                        flag={this.props.flag}
                        showfilter={true}
                        showtoggle={true}
                        showdownload={true}
                        showsortby={false}
                        Onswitchchange={this.handleChange}
                        header={this.props.header}
                        filter={this.submit}
                        scheme={this.props.List?.length == 0 ? <NoData></NoData> : this.props.List?.length <= 1 ? `${this.props.List?.length} ${this.props.header}` : `${this.props.List?.length} ${this.props.header}s`}
                        page={this.props.flag}
                        data={this.props.List}
                        List={this.props.maturityTrackerBackupList}
                        selected={this.state.checked}
                    />
                        {this.state.maturitydata?.length != 0 && <div style={{ marginTop: "2%", paddingLeft: 10 }} className={[classes.maturityTrackerContainer, classes.tableHeader,].join(" ")}>

                            {table.map((item, index) => {
                                return (
                                    <div className={index != 0 && classes.header} style={{ display: "flex", flexDirection: "column", textAlign: index == 0 ? "left" : "right", alignItems: index == 0 ? "flex-start" : "flex-end", gap: 10 }}>
                                        <div>
                                            {item.header}
                                        </div>
                                        <div style={{ width: "max-content" }}>
                                            {item.subHeader}
                                        </div>
                                    </div>

                                )
                            })}
                        </div>}
                        {this.state.maturitydata.map((item) => {
                            return (<div>
                                {this.state.checked &&
                                    <div className={[classes.maturityTrackerContainer, classes.categoryGroupContainer].join(" ")}>
                                        <div style={{ textAlign: "left", paddingLeft: 10 }}>{item.Category}</div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>}
                                {item.data.map((value, index) => {
                                    return (
                                        <div className={[classes.maturityTrackerContainer, classes.alignBaseLine, classes.subCategoryGroupContainer, index == 0 && classes.borderRadiousFirstIndex].join(' ')} style={{ paddingRight: 10, paddingLeft: 10 }}>
                                            <div className={[classes.alignStart, classes.startDate, "myFirstGrid", classes.accessGrid].join(" ")}>

                                                <div style={{ textAlign: "left" }}>
                                                    {value.IssuerName}
                                                </div>
                                                <div className={classes.setMaxWidth}>
                                                    <Badges textcolor={window.globalConfig.color.LABLE_GRAY} color={window.globalConfig.color.ZERO_BACKGROUND} text={`${value.NewFDRNumber}`} />
                                                </div></div>
                                            <div className={classes.alignEnd}>
                                                <div>
                                                    {NegativeFinding(value.InvestmentAmount, "", "", "", "rupee")}
                                                </div>
                                                <div className={classes.setMaxWidth}>
                                                    <Badges textcolor={window.globalConfig.color.LABLE_GRAY} color={window.globalConfig.color.ZERO_BACKGROUND} text={`${value.Frequency}`} />
                                                </div>
                                            </div>
                                            <div className={[classes.alignEnd, classes.startDate].join(" ")}>
                                                <div className={classes.mobileHeader} >{table[2].header}</div>
                                                <div>{NegativeFinding(value.InterestRate, "", "", "", "percentage")}</div>
                                                <div className={classes.setMaxWidth}>
                                                    {value.InterestPayout}
                                                </div>
                                            </div>
                                            <div className={[classes.alignEnd, classes.startDate].join(" ")}>
                                                <div className={classes.mobileHeader} >{table[3].header}</div>
                                                <div>{value.TransactionDate}</div>
                                                <div className={classes.setMaxWidth}>
                                                    {value.Tenure}
                                                </div>
                                            </div>
                                            <div className={classes.alignEnd}>
                                                <div className={classes.mobileHeader} >{table[4].header}</div>
                                                <div>{value.MaturityDate}</div>
                                                <div className={classes.setMaxWidth}>
                                                    {value.TimeLeft}
                                                </div>
                                            </div>
                                            <div className={[classes.alignEnd, classes.startDate].join(" ")}>
                                                <div className={classes.mobileHeader} >{table[5].header}</div>
                                                <div>{value.MaturityOption}</div>
                                            </div>
                                            <div className={[classes.alignEnd, classes.startDate].join(" ")} >
                                                <div className={classes.mobileHeader} >{table[6].header}</div>
                                                <div style={{ color: value.Status == "Live" ? window.globalConfig.color.GREEN : window.globalConfig.color.BLACK_PRIMARY }}>{value.Status}</div>
                                            </div>
                                            {/* <MoreVertIcon className={classes.iconShowDesktop} onClick={(e) => this.handleClick(e,value)}></MoreVertIcon> */}
                                        </div>
                                    )
                                })}

                            </div>
                            )
                        })}
                    </>}

                <Popover
                    //    disableScrollLock={true}
                    classes={classes.popperDisablePortal}
                    open={Boolean(this.state.anchorEl)}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',

                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}

                >

                    <ListItem
                        button
                        // selected={this.selectedIndex === 1}
                        className="popoverlist"
                        onClick={() => this.handleListItemClick()}
                    >
                        <ListItemText primary={"Review"} />
                    </ListItem>

                </Popover>
            </div>

            )
        } catch (err) {
            console.log(err)
            return <Redirect to={labels.Path.NotFound}></Redirect>
        }


    }
}
const mapStateToProps = state => {
    return {
        // maturityTrackerBackupList: state.MaturityTracker.maturityTrackerBackup == "NA" ? [] : state.MaturityTracker.maturityTrackerBackup,

        //  sessionID: state.Login.sessionID,
        clientID: state.Login.logindetails.ClientID,
        logindetails: state.Login.logindetails,
        userID: state.Login.userID,
        date: state.Login.date,
        L5ID: state.Login.L5ID,
    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch)
}
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(MaturityTracker))

