import getProfile from '../../../api/profile/profile'
import SearchBranch from '../../../api/profile/searchbranch'
import GetMyPlanDetailsapi from '../../../api/profile/myplanapi'
import * as types from '../../constants/actionsType';
import { replaceNull } from '../../../utils/commonfunction';
//Basic Profile Details Of user
export function getProfileDetails(ClientID, EmailID) {
    return (dispatch) => {
        getProfileDetail(dispatch, ClientID, EmailID)
        // console.log(EmailID,"EmailID")
    }
}
function getProfileDetail(dispatch, ClientID, EmailID) {
    dispatch({
        type: types.EMPTY_PROFILE_DETAILS,
        payload: [],
    });
    dispatch({
        type: types.LOADER_PROFILE_DETAILS,
        payload: true
    })

    getProfile(ClientID, EmailID).then((data) => {

        if (data.status == "S") {
            dispatch({
                type: types.PREVENT_API_CALL,
                payload: [{ ProductID: 0, api: "ProfileDetails" }],
            });
            try {
                let res = {}
                res.EmailID = data?.mainDetails[0]?.EmailID
                res.KYCFormNo = data?.mainDetails[0]?.KYCFormNo
                dispatch({
                    type: types.STOREKYCFORMNO,
                    payload: res
                })
            }
            catch (err) {
                dispatch({
                    type: types.STOREKYCFORMNO,
                    payload: { EmailID: "", KYCFormNo: 0 }
                })
            }
            // dispatch({
            //     type: types.PREVENT_API_CALL,
            //     payload:"ProfileDetails",
            // });
            dispatch({
                type: types.PROFILE_DETAILS,
                payload: data,
            });

        } else {
            dispatch({
                type: types.PREVENT_API_CALL,
                payload: [{ ProductID: 0, api: "ProfileDetails" }],
            });
            // dispatch({
            //     type: types.PREVENT_API_CALL,
            //     payload:"ProfileDetails",
            // });
            dispatch({
                type: types.EMPTY_PROFILE_DETAILS,
                payload: [],
            });
        }
        dispatch({
            type: types.LOADER_PROFILE_DETAILS,
            payload: false
        })
    }).catch((err) => {
        dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "ProfileDetails" }],
        });
        // dispatch({
        //     type: types.PREVENT_API_CALL,
        //     payload:"ProfileDetails",
        // });
        dispatch({
            type: types.LOADER_PROFILE_DETAILS,
            payload: false
        })
    })
}
///Add Country
export const getAddCountryDetails = (TaxPayingCountryID, ClientID, Country, IdentificationType, TIN, data, UCC, L5ID) => {

    return {
        type: types.ADD_COUNTRY,
        TaxPayingCountryID, ClientID, Country, IdentificationType, TIN, data, UCC, L5ID
    }
}
//mark As default in bank
export const getMarkAsDefault = (BankID, IsDefault) => {

    return {
        type: types.MARK_AS_PRIMARY,
        BankID, IsDefault
    }
}
///update mandate
export const updateMandateStatus = (data) => {

    return {
        type: types.UPDATE_PROFILE_DETAILS,
        data
    }
}
///Add Nominee
export const getAddNomineeDetails = (NomineeID, ClientID, NomineeType, NomineeName, RelationWithAccountHolder, NomineePercentage, IsNomineeMinor, NomineeGuardian, Relation, flag, UCC, L5ID) => {

    return {
        type: types.ADD_NOMINEE,
        NomineeID, ClientID, NomineeType, NomineeName, RelationWithAccountHolder, NomineePercentage, IsNomineeMinor, NomineeGuardian, Relation, flag, UCC, L5ID
    }
}
//update ucccode in login
export const changeUcccode = (uccode) => {

    return {
        type: types.UPDATE_UCCCODE,
        uccode
    }
}

///Add  Bank with ifsc
export const updatebankdetails = (ClientID, AccountType, IFSCCode, BranchMICRCode, BankAccountNumber, response, UCC, L5ID) => {
    return {
        type: types.ADD_BANK,
        ClientID, AccountType, IFSCCode, BranchMICRCode, BankAccountNumber, response, UCC, L5ID
    }
};
//Update personal details
export const updatepersonaldetails = (ID, Nationality, CountryofBirth, PlaceofBirth, Occupation, AnnualIncome, PoliticalStatus, UserID) => {
    return {
        type: types.UPDATE_PERSONAL_DETAILS,
        ID, Nationality, CountryofBirth, PlaceofBirth, Occupation, AnnualIncome, PoliticalStatus, UserID
    }
};
//delete country details
export const deletecountrydetails = (CountryID, UserID) => {
    return {
        type: types.DELETE_COUNTRY,
        CountryID, UserID
    }
};
//delete nominee
export const deletenomineedetails = (NomineeID, UserID) => {
    //
    return {
        type: types.DELETE_NOMINEE,
        NomineeID, UserID
    }
};
///Search Branch
export function getSearchBranchDetails(BankID, SearchValue,) {
    return (dispatch) => {
        SearchBranchDetails(dispatch, BankID, SearchValue)
    }
}
function SearchBranchDetails(dispatch, BankID, SearchValue) {

    SearchBranch(BankID, SearchValue).then((data) => {
        if (data.status == "S") {
            dispatch({
                type: types.SEARCH_BRANCH,
                payload: data
            });

        }

    })
}
export const updateMyPlanDetails = (data) => {
    return {
        type: types.UPDATE_PROFILE_MY_PLAN,
        payload: data
    }

}

// Selected PlanName,PlanCode,Amount stored
export const getPurchasedPlan = (data) => {
    if (data != undefined) {
        //   
        return {
            type: types.GET_PURCHASED_PLAN,
            payload: data

        }
    }
    else {
        return {
            type: types.GET_PURCHASED_PLAN,
            payload: []

        }
    }

}
export function GetMyPlanDetails(ClientID) {
    return (dispatch) => {
        GetMyPlanDetailsAction(dispatch, ClientID);
    };
}
function GetMyPlanDetailsAction(dispatch, ClientID) {
    // dispatch({
    //     type: types.EMPTY_PROFILE_MY_PLAN,
    //     payload: [],
    // });
    dispatch({
        type: types.LOADER_PROFILE_MY_PLAN,
        payload: true,
    });
    GetMyPlanDetailsapi(ClientID).then((data) => {
        dispatch({
            type: types.PROFILE_MY_PLAN,
            payload: [],
        });
        //  

        if (data.status == "S") {
            // console.log(data.table1)
            if (data.table1 != undefined && data.table1 != null) {
                dispatch({
                    type: types.PREVENT_API_CALL,
                    payload: [{ ProductID: 0, api: "Plan" }],
                });
                dispatch({
                    type: types.PROFILE_MY_PLAN,
                    payload: replaceNull(data.table1, "NA"),
                });
                dispatch({
                    type: types.LOADER_PROFILE_MY_PLAN,
                    payload: false,
                });
            }

        } else {
            dispatch({
                type: types.PREVENT_API_CALL,
                payload: [{ ProductID: 0, api: "Plan" }],
            });
            dispatch({
                type: types.PROFILE_MY_PLAN,
                payload: [],
            });
            dispatch({
                type: types.LOADER_PROFILE_MY_PLAN,
                payload: false,
            });
        }


    })
        .catch((error) => {
            dispatch({
                type: types.PREVENT_API_CALL,
                payload: [{ ProductID: 0, api: "Plan" }],
            });
            dispatch({
                type: types.PROFILE_MY_PLAN,
                payload: [],
            });
            dispatch({
                type: types.LOADER_PROFILE_MY_PLAN,
                payload: false,
            });
        })
}

