import fileDownload from 'js-file-download'
var axios = require('axios');
function getESignDownloadPdfOpenView(url, ext) {
    return axios(url, {
        method: 'GET',
        responseType: 'blob' //Force to receive data in a Blob Format
    })
        .then(response => {
            //Create a Blob from the PDF Stream
            const file = new Blob(
                [response.data],
                { type: `${"application/"}${ext}` });
            //Build a URL from the file
            if (ext === "pdf") {
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
            } else {
                const fileURL = URL.createObjectURL(file);
                //Open the URL on new Window
                window.open(fileURL);
            }
            return 'success'
        })
        .catch(error => {
            return 'fail'
        });
}
export default getESignDownloadPdfOpenView