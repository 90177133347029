import Networking from '../../utils/api/apiaccess';
import { labelcolor } from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
function addtocart(ID, SchemeID, ClientID, Amount, AUM, Instalments, IsBSEStar, OneTimeAllowed, OneWeek, OneYear, Ranking, Risk,
  SipAllowed, SipFrequency, Sipdate, SipdateID, ThreeYear, ratings, AMCID, AssetClass, ISIN, RTAID, RTAProdCode, SchemeCode, ISFirstPayment, SessionID, FolioNo, ucc, folioList, IsBasket = false, BasketID = 0, TransactionTypeID) {
  let BaseUrl = ApiValues.AddCart;
  let data = {};
  data.ID = ID,
    data.SchemeID = SchemeID,
    data.ClientID = ClientID,
    data.Amount = Amount,
    data.AUM = AUM,
    data.Instalments = Instalments,
    data.IsBSEStar = IsBSEStar,
    data.OneTimeAllowed = OneTimeAllowed,
    data.OneWeek = OneWeek,
    data.OneYear = OneYear,
    data.Ranking = Ranking,
    data.Risk = Risk,
    data.SipAllowed = SipAllowed,
    data.SipFrequency = SipFrequency,
    data.Sipdate = Sipdate,
    data.SipdateID = SipdateID,
    data.ThreeYear = ThreeYear,
    data.ratings = ratings,
    data.AMCID = AMCID,
    data.AssetClass = AssetClass,
    data.ISIN = ISIN,
    data.RTAID = RTAID,
    data.RTAProdCode = RTAProdCode,
    data.SchemeCode = SchemeCode,
    data.ISFirstPayment = ISFirstPayment,
    data.SessionID = SessionID,
    data.FolioNumber = FolioNo != null && FolioNo != undefined && FolioNo != "" ? FolioNo.toString() : "",
    data.MutualFundUCC = ucc
  data.FolioList = folioList
  data.IsBasket = IsBasket,
    data.BasketID = BasketID
  data.TransactionTypeID = TransactionTypeID
  // return fetch(BaseUrl, {
  //     method: 'POST',
  //     headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(data),
  // })
  //     .then((response) => response.json())
  //     //If response is in json then in success
  //     .then((responseJson) => {
  //         return responseJson

  //     })
  //     //If response is not in json then in error
  //     .catch((error) => {
  //         //Error 
  //         return { "status": "F","message":labels.messages.apierror }

  //     });
  return Networking.PostApi(BaseUrl, data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
}
export default addtocart
