import * as types from '../../constants/actionsType';
import getRunningSIPs from '../../../api/runningsip/runningsip'
import constants from '../../../utils/constants/apiconstant';
export function getRunningSIP(ClientID,AccountID,AsonDate,ProductID,FamilyID,L5ID,L4ID,flag) {
  return (dispatch) => {
    getAllRunningSIPAction(dispatch, ClientID,AccountID,AsonDate,ProductID,FamilyID,L5ID,L4ID,flag);
  };
}
function getAllRunningSIPAction(dispatch, ClientID,AccountID,AsonDate,ProductID,FamilyID,L5ID,L4ID,flag) {
  if(ProductID == 1){
  dispatch({
    type: types.EMPTY_RUNNINGSIP,
    payload: [],
  });
  dispatch({
    type: types.LOADER_RUNNING_SIP,
    payload: true,
  });}else{
    dispatch({
      type: types.EMPTY_EQUITY_RUNNING_SIP,
      payload: [],
    });
    dispatch({
      type: types.LOADER_EQUITY_RUNNING_SIP,
      payload: true,
    });
  }
  getRunningSIPs(ClientID,AccountID,AsonDate,ProductID,FamilyID,L5ID,L4ID)
    .then((data) => {
      dispatch({
        type: types.PREVENT_API_CALL,
      
        payload:[{ProductID:ProductID,api:"RunningSip"}],
      }); 
      if(ProductID == 1){
      dispatch({
        type: types.RUNNINGSIP,
        payload: data,
      });
       
    }else{
        dispatch({
          type: types.EQUITY_RUNNING_SIP,
          payload: data,
        });
       
      }
      // if (data != undefined) {
      //     if (data.status == "S") {
      //       dispatch({
      //           type: types.RUNNINGSIP,
      //           payload: data,
      //         });
      //     } else {
      //       dispatch({
      //           type: types.RUNNINGSIP,
      //           payload: [],
      //         });
      //     }
      //   }
    })
    .catch((error) => {
      
      dispatch({
        type: types.PREVENT_API_CALL,
      
        payload:[{ProductID:ProductID,api:"RunningSip"}],
      }); 

      if(flag===constants.Common.mutualfund){
        dispatch({
          type: types.EMPTY_RUNNINGSIP,
          payload: [],
        });
       
       }else{
          dispatch({
            type: types.EMPTY_EQUITY_RUNNING_SIP,
            payload: [],
          });
          
        }
    })
}
