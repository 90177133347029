import moment from "moment";
import secureStorage from "./securestorage";
import Label from "./constants/labels";
import labels from "./constants/labels";
import ApiValues from "../api/url/apiurl";
import encryptKYC from "../api/profile/encryptionkyc";
import getSchemeDetails from "../api/schemedetails/schemedetails";
import { format, parse, isValid } from 'date-fns';
import colors from "./colors/colors";
var CryptoJS = require("crypto-js");
var windowObjectReference = null; // global variable
var PreviousUrl;
let convertFromString = [{
  name: "greater", value: ">",
},
{ name: "below", value: "<" },
{
  name: "less", value: "<",
}, {
  name: "lakh", value: "00000",
},
{
  name: "lac", value: "00000",
},
{
  name: "lacs", value: "00000",
},
{ name: "crore", value: "0000000" }]
export function uniqueId() {
  return (Math.random().toString() + "00000000000000000").slice(2, 10);
}
export const getLast12Months = () => {
  let months = [];
  let monthsRequired = 12
  for (let i = monthsRequired; i >= 1; i--) {
    months.push(moment().subtract(i, 'months').endOf('month').format('DD MMM YYYY'))
  }
  return months.map((item, i) => {
    return { id: i, name: item }
  })
}
export function countNAValues(obj) {
  var count = 0; for (var key in obj) { if (obj.hasOwnProperty(key) && obj[key] === "NA") { count++; } }
  return count;
}
export const getValuesFromString = (text) => {
  var inputText = text;
  // Use regular expressions to split the string
  var parts = inputText.match(/([^\d\s]+|\d+|\s+)/g);
  // Iterate through the parts and classify them
  var specialChars = [];
  var numbers = [];
  var alphabets = [];
  for (var i = 0; i < parts.length; i++) {
    var part = parts[i].trim();
    if (/^\d+$/.test(part)) {
      numbers.push(part); // It's a number
    } else if (/^[a-zA-Z]+$/.test(part)) {
      alphabets.push(part); // It's an alphabet

    } else {
      specialChars.push(part); // It's a special character (not a digit or an alphabet)
    }
  }
  if (alphabets.length == 1) {
    numbers.map((item) => {
      inputText = inputText.replaceAll(" ", "")
      inputText = inputText.replaceAll(alphabets[0], "")
      inputText = inputText.replace(item, `${item}${convertFromString.filter((val) => val.name.toLowerCase() == alphabets[0].toLowerCase())[0]?.value || ""}`)
    })
  } else {
    alphabets.map((item) => {
      inputText = inputText.replaceAll(" ", "")
      inputText = inputText.replaceAll(item, convertFromString.filter((val) => val.name.toLowerCase() == item.toLowerCase())[0]?.value || "")
    })
  }

  return inputText
  // console.log(numbers, alphabets, specialChars)
}
export function scrollToTopPage() {
  document.getElementById('scroller').scrollTo({
    top: 0, behavior:
      'smooth'
  })
}
export const hexToRgba = (hex, alpha = 1) => {
  const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgb(${r + 29},${g + 16},${b + 15})`;
};
export const moneyFormatter = (value) => {
  return new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
    currencyDisplay: "code",
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    notation: 'compact',
    compactDisplay: 'long',
  }).format(value).replace("INR", "").replace("T", "K").trim()
}
export function replaceNull(someObj, replaceValue = "", replaceThisValue) {
  const replacer = (key, value) => {
    //
    let convertReplaceValue = replaceThisValue == undefined ? "" : replaceThisValue
    if (typeof value === "number") {
      return value;
    } else if (typeof value === "boolean") {
      return value;
    } else {
      return String(value) === "null" ||
        String(value) === "undefined" ||
        Object.keys(value).length === 0 ||
        String(value).trim() === "" ||
        // String(value) === " " ||
        String(value) === convertReplaceValue
        ? replaceValue
        : value;
    }
  };
  //^ because you seem to want to replace (strings) "null" or "undefined" too
  //

  return JSON.parse(JSON.stringify(someObj, replacer));
}
export function openRequestedSinglePopup(url) {
  if (windowObjectReference == null || windowObjectReference.closed) {
    windowObjectReference = window.open(
      url,
      "SingleSecondaryWindowName",
      "scrollbars,status,width"
    );
  } else if (PreviousUrl != url) {
    windowObjectReference = window.open(
      url,
      "SingleSecondaryWindowName",
      "scrollbars=yes,status=yes, width=50%,"
    );
    /* if the resource to load is different,
       then we load it in the already opened secondary window and then
       we bring such window back on top/in front of its parent window. */
    windowObjectReference.focus();
  } else {
    windowObjectReference.focus();
  }

  PreviousUrl = url;
  /* explanation: we store the current url in order to compare url
     in the event of another call of this function. */
}
export const emailValidator = (email) => {
  const re = /\S+@\S+\.\S+/;
  if (!email || email.length <= 0) return Label.messages.required;
  if (!re.test(email)) {
    return Label.messages.InvalidEmail;
  }
  if (email.length > 200) return Label.messages.MaximumEmail;
  return "";
};
export const passValidator = (password) => {

  if (password.length > 20) return Label.messages.MaximumEmail;
  return "";
};
export const requireValidator = (value) => {
  if (!value || value.length <= 0) return Label.messages.required;
  return "";
};
export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
// currencyrupee symbal and comma seperater functon creaded by yogitha on 16june-2021
export const CurrencyFormat = (value) =>
  new Intl.NumberFormat("en-IN", {
    style: "decimal",
    currency: "INR",
    // minimumFractionDigits: 1,
  }).format(value);

// intallment functions created by yogitha on 16 june 2021
export const installmentValidation = (value) => {
  var number = /^\d*$/;
  if (number.test(value) && value.length < 6) {
    return true;
  } else {
    return false;
  }
};
// Name validation created by yogitha on 17 june 2021
export const NameValidation = (value) => {
  var number = new RegExp("^[a-zA-Z ]*$");
  if (number.test(value) && value.length < 31) {
    return true;
  } else {
    return false;
  }
};
//  created by Dulcy 
export const AlphaNumbericandSpecifiedCharValidation = (value) => {
  var number = new RegExp("^[a-zA-Z0-9\/-]*$");
  if (number.test(value)) {
    return true;
  } else {
    return false;
  }
};
//functions created by dulcy----start
export const AmountValidation = (value) => {
  var number = /^\d*$/;
  if (number.test(value) && value.length < 11) {
    return true;
  } else {
    return false;
  }
};
export const toIndianRupee = (amount) => {
  if (amount != undefined) {
    const Rupee = amount
      .toString()
      .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");

    return Rupee;
  }
};
export function NullEmptyAndNullCheck(val) {
  if (val != undefined && val != "" && val != null) return true;

  return false;

}
export function hasNull(element, index, array) {
  return Object.keys(element).some(function (key) {
    return element[key] == "" || element[key] == "0" || element[key] == "NA";
  });
}
export const IFSCvalidation = (value) => {
  var number = /^[A-Za-z]{4}[a-zA-Z0-9]{7}$/;

  if (number.test(value)) {
    return true;
  } else {
    return false;
  }
};
export const amountCommaSeperator = (number) => {
  const parts = number.toString().split('.');
  const integerPart = parts[0].replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ',')

  let formattedNumber = integerPart;
  if (parts.length > 1) {
    const decimalPart = parts[1];
    formattedNumber += '.' + decimalPart;
  }
  return formattedNumber;
}
export const ordinal = (number) => {
  const english_ordinal_rules = new Intl.PluralRules("en", {
    type: "ordinal",
  });
  const suffixes = {
    one: "st every month",
    two: "nd every month",
    few: "rd every month",
    other: "th every month",
  };
  const suffix = suffixes[english_ordinal_rules.select(number)];
  return number + suffix;
};
export const ColorSetting = (value,
  maincolor,
  errorcolor,
  zerovaluecolor) => {
  let a =
    maincolor === undefined || maincolor == null || maincolor == ""
      ? colors.GREEN
      : maincolor;
  let b =
    errorcolor === undefined || errorcolor == null || errorcolor == ""
      ? colors.red
      : errorcolor;
  let c =
    zerovaluecolor === undefined ||
      zerovaluecolor == null ||
      zerovaluecolor == ""
      ? colors.LABLE_GRAY
      : zerovaluecolor;
  let val = value == null || value == undefined || value == 'NA' ? 0 : typeof (value) == "number" ? value : value.match(/₹|,|%/ig) != null ? Number(value.replace(/₹|,|%/ig, "")) : value
  if (Math.sign(val) === 1) {
    return a;
  } else if (Math.sign(val) === 0) {
    return c;
  }
  else if (isNaN(val)) {
    return colors.BLACK_PRIMARY
  }
  else {
    return b;
  }
}
export const ReturnValue = (
  value,
  maincolor,
  errorcolor,
  zerovaluecolor,
  flag
) => {
  let returnValue = ""

  if (flag == "color") {
    returnValue = ColorSetting(value,
      maincolor,
      errorcolor,
      zerovaluecolor,)
  }
  else if (flag == "percentage") {
    returnValue = value != null && value != undefined && value != "" ? `${value.toString().replace("-", "")}%` : `${0}%`
  } else if (flag == "rupee") {
    returnValue = value != null && value != undefined && value != "" ? `${labels.currency.rupeesymbol} ${typeof (value) == "number" ? value.toLocaleString("en-IN").replace("-", "") : Number(value).toLocaleString("en-IN").replace("-", "")}` : `NA`
  } else {
    returnValue = value != null && value != undefined && value != "" ? `${typeof (value) == "number" ? value.toLocaleString("en-IN").replace("-", "") : Number(value).toLocaleString("en-IN").replace("-", "")}` : 'NA'
  }
  return returnValue
}
export const NegativeFinding = (
  value,
  maincolor,
  errorcolor,
  zerovaluecolor,
  flag
) => {
  let returnValue = ""
  if (flag == "number") {
    returnValue = value != null && value != undefined && value != "" ? `${typeof (value) == "number" ? amountCommaSeperator(value).replace("-", "") : Number(value).toLocaleString("en-IN") == NaN ? value : amountCommaSeperator(value).replace("-", "")}` : "0"
  } else if (flag == "removeMinus") {
    returnValue = value != null && value != undefined && value != "" ? `${amountCommaSeperator(value).replace("-", "")}` : ""

  }
  else if (flag == "percentage") {
    returnValue = value != null && value != undefined && value != "" && value != "NA" ? `${amountCommaSeperator(value).replace("-", "")}%` : `${0}%`
  } else if (flag == "rupee") {
    returnValue = value != null && value != undefined && value != "" ? `${labels.currency.rupeesymbol}${typeof (value) == "number" ? amountCommaSeperator(value).replace("-", "") : amountCommaSeperator(value).replace("-", "")}` : `${labels.currency.rupeesymbol} 0`
  } else {
    returnValue = ColorSetting(value,
      maincolor,
      errorcolor,
      zerovaluecolor,)
  }
  return returnValue
};
export const currentDate = () => {
  var nowDate = new Date();

  var date =
    nowDate.getFullYear() +
    "-" +
    (nowDate.getMonth() + 1) +
    "-" +
    nowDate.getDate();

  var time =
    nowDate.getHours() +
    ":" +
    nowDate.getMinutes() +
    ":" +
    nowDate.getSeconds();

  var dateTime = date + " " + time;
  return dateTime;
};
///------end
export const numbersOnly = (value) => {
  if (value != undefined) {
    return /^\d+$/.test(value.toString()) || value === "" ? true : false;
  }
};
export const numbersandDeciamlOnly = (value, maxLength) => {
  if (value != undefined) {
    let max = maxLength != undefined ? maxLength : 10
    return /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(value.toString()) ||
      value === ""
      ? value.replaceAll(".", "").length <= max
        ? true
        : false
      : false;
  }
};
export const numbersandDeciamlOnlyExtraLength = (value) => {
  if (value != undefined) {
    return /^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(value.toString()) ||
      value === ""
      ? true
      : false;
  }
};
export const replaceComma = (value) => {
  return value.toString().search(",").length != 0 ? value.toString().replaceAll(",", "") : value
}
//Create validation function by Muthu Lakshmi K
export const alphabetSpaceOnly = (value) => {
  if (value != undefined) {
    return /^[A-Za-z]+(\s?$|\s{1,}[A-Za-z]+)+$/.test(value.toString()) ||
      value === ""
      ? true
      : false;
  }
};
export function commify(n) {
  var parts = n.toString().split(".");

  const numberPart = parts[0];
  const decimalPart = parts[1];
  const thousands = /\B(?=(\d{3})+(?!\d))/g;

  return numberPart.replace(thousands, ",") + (decimalPart ? "." + decimalPart : "");
}
export const round = (value, decimals) => {
  // if(Number.isInteger(value)){
  //   return value+".00"
  // }
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
};
export const alphaNumericOnly = (value) => {
  if (value != undefined) {
    return /^[A-Za-z0-9]+$/.test(value.toString()) || value === ""
      ? true
      : false;
  }
};
export const delay = (ms) => new Promise((res) => setTimeout(res, ms));
export const alphabetOnly = (value) => {
  if (value != undefined) {
    return /^[A-Za-z]+$/.test(value.toString()) || value === "" ? true : false;
  }
};
// export const mask=(word)=> {
//   if (word.length <=4) {
//     return word
//   } else {
//     var masked = word.substring(0, word.length - 4).replace(/[a-z\d]/gi,"*") +
//     word.substring(word.length - 4, word.length)
//     return masked;
//   }
// }
export const maskName = (name) => {
  const mask = "*";
  let changedname = name.toString();
  let maskedName =
    changedname.substring(0, 1) +
    mask.repeat(changedname.length - 2) +
    changedname.slice(-1);
  return maskedName;
};
export const returnDateList = (date, IsFirstPayment) => {

  const addDate = [1, 1, 1, 1, 1];
  let returnedDate = date == undefined || date == "" ? new Date().getDate() : date;
  let currentMonth = new Date().getMonth() + 1;
  let currentYear = new Date().getFullYear();
  let isLeafYear = false;
  if ((0 == currentYear % 4 && 0 != currentYear % 100) || 0 == currentYear % 400) {
    isLeafYear = true;
  } else {
    isLeafYear = false;
  }
  let fullYear = [
    { month: 1, endDate: 30 },
    { month: 2, endDate: isLeafYear ? 28 : 27 },
    { month: 3, endDate: 30 },
    { month: 4, endDate: 29 },
    { month: 5, endDate: 30 },
    { month: 6, endDate: 29 },
    { month: 7, endDate: 30 },
    { month: 8, endDate: 30 },
    { month: 9, endDate: 29 },
    { month: 10, endDate: 30 },
    { month: 11, endDate: 29 },
    { month: 12, endDate: 30 },
  ];
  let blockedDates = addDate.map((item) => {
    let mainDate = item;
    if (
      returnedDate <=
      fullYear.filter((val) => val.month == currentMonth)[0].endDate
    ) {
      returnedDate = mainDate + returnedDate;
    } else {
      mainDate = 1;
      returnedDate = 1;
    }
    return returnedDate;
  });
  let finalDate = []
  if (IsFirstPayment) {
    finalDate = [new Date().getDate()];
  } else {
    finalDate = [new Date().getDate(), ...blockedDates];
  }


  let finalResult = labels.Date.filter((item) => !finalDate.includes(item.id));
  return finalResult;
};
export function sortArray(data, keyName, typeofSorting) {
  //true -Ascending 
  //false -decending
  var temp = [];
  var temp2 = [];
  if (Array.isArray(data)) {
    if (typeofSorting == "ASC") {
      data = data.sort((a, b) => a[keyName] - b[keyName])
    } else {
      data = data.sort((a, b) => b[keyName] - a[keyName])
    }

    return data;
  }
}
export const isValidDate = (date, IsFirstPayment, findDate) => {

  const addDate = [1, 1, 1, 1, 1];
  let returnedDate = date == undefined || date == "" ? new Date().getDate() : date;
  let currentMonth = new Date().getMonth() + 1;
  let currentYear = new Date().getFullYear();
  let isLeafYear = false;
  if ((0 == currentYear % 4 && 0 != currentYear % 100) || 0 == currentYear % 400) {
    isLeafYear = true;
  } else {
    isLeafYear = false;
  }
  let fullYear = [
    { month: 1, endDate: 30 },
    { month: 2, endDate: isLeafYear ? 28 : 27 },
    { month: 3, endDate: 30 },
    { month: 4, endDate: 29 },
    { month: 5, endDate: 30 },
    { month: 6, endDate: 29 },
    { month: 7, endDate: 30 },
    { month: 8, endDate: 30 },
    { month: 9, endDate: 29 },
    { month: 10, endDate: 30 },
    { month: 11, endDate: 29 },
    { month: 12, endDate: 30 },
  ];
  let blockedDates = addDate.map((item) => {
    let mainDate = item;
    if (
      returnedDate <=
      fullYear.filter((val) => val.month == currentMonth)[0].endDate
    ) {
      returnedDate = mainDate + returnedDate;
    } else {
      mainDate = 1;
      returnedDate = 1;
    }
    return returnedDate;
  });
  let finalDate = []
  if (IsFirstPayment) {
    finalDate = [new Date().getDate()];
  } else {
    finalDate = [new Date().getDate(), ...blockedDates];
  }


  let finalResult = labels.Date.filter((item) => !finalDate.includes(item.id));
  let returnResult = finalResult.filter((items) => [findDate].includes(items.id)).length == 0 ? false : true;
  return returnResult;
};
export const maskNumber = (inputnumber) => {
  const mask = "*";
  let number = inputnumber.toString();
  let maskedNumber =
    number.substring(0, 2) + mask.repeat(number.length - 4) + number.slice(-2);
  return maskedNumber;
};
export const alphaNumbericSpaceandCommaOnly = (value) => {
  if (value != undefined) {
    return /^[\.a-zA-Z0-9,\s]*$/.test(value.toString()) || value === ""
      ? true
      : false;
  }
};
export const alphaSpaceandCommaOnly = (value) => {
  if (value != undefined) {
    return /^[\.a-zA-Z,\s]*$/.test(value.toString()) || value === ""
      ? true
      : false;
  }
};
export const accountNumberOnly = (value) => {
  if (value != undefined) {
    return /^\s*-?[0-9]{1,18}\s*$/.test(value.toString()) || value === ""
      ? true
      : false;
  }
};
export const AmountOnly = (value) => {
  if (value != undefined) {
    return /^\s*-?[0-9 ,]{1,8}\s*$/.test(value.toString()) || value === ""
      ? true
      : false;
  }
};
export const notAllowdSpecialCharater = (value) => {
  if (value != undefined) {

    return /^[\a-zA-Z0-9]+(\s?$|\s{1,}[\a-zA-Z0-9]+)+$/.test(value.toString()) || value === ""
      ? true
      : false;
  }
};
export const gstValidation = (value) => {
  var number = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
  if (number.test(value) && value.length < 16) {
    return true;
  } else {
    return false;
  }
};
export const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}
export const SchemeCodeFinding = async (
  amountParam,
  PurahaseKey,
  ISIN,
  RTAProdCode,
  ClientID,
  TransactionType, Frequency, field
) => {
  let amount = replaceNull(amountParam, 0)
  // Scheme Code selection Code changed by Srinath
  let selectedMappingDetails;
  let flagCheckedSchemes = "";
  let ApiResponse = await getSchemeDetails(Number(amount), ISIN, RTAProdCode, ClientID, TransactionType, Frequency);
  //
  let mappingDetails = ApiResponse.status == "F" || ApiResponse.mappingDetails == null ? "" : ApiResponse.mappingDetails;

  if (
    mappingDetails != null &&
    mappingDetails != undefined &&
    mappingDetails.length > 0
  ) {
    mappingDetails = mappingDetails.sort(function (a, b) {
      return (
        moment(a.PurchaseCutoffTime, "HH:mm:ss").format("HH:mm:ss") >
        moment(b.PurchaseCutoffTime, "HH:mm:ss").format("HH:mm:ss")
      );
    });

    if (Label.investmentPopup.keySIP === PurahaseKey) {
      flagCheckedSchemes = mappingDetails
    } else if (Label.investmentPopup.keyOneTime === PurahaseKey) {
      flagCheckedSchemes = mappingDetails
    } else if (Label.investmentPopup.keySwitch === PurahaseKey) {
      flagCheckedSchemes = mappingDetails
      //removed at 22 feb
      // .filter(
      //   (item) => item.PurchaseAllowed.trim() === Label.investmentPopup.yFlag
      // );
    } else if (Label.investmentPopup.keySTP === PurahaseKey) {
      flagCheckedSchemes = mappingDetails
    }
    if (Label.investmentPopup.keySTP === PurahaseKey ||
      Label.investmentPopup.keySwitch === PurahaseKey) {
      selectedMappingDetails = flagCheckedSchemes[0];
      let validationAmounts = "success";
      let data = {};
      data.status = validationAmounts;
      data.response = selectedMappingDetails;
      return data;
    }
    else if (
      Label.investmentPopup.keySIP === PurahaseKey
    ) {
      let amountValid = flagCheckedSchemes
      // .filter((item) => {
      //   // Amount Validation
      //   if (Number(item.MaximumPurchaseAmount) == 0) {
      //     if (Number(amount) >= Number(item.MinimumPurchaseAmount)) {
      //       return item;
      //     }
      //   } else if (
      //     Number(amount) >= Number(item.MinimumPurchaseAmount) &&
      //     Number(amount) <= Number(item.MaximumPurchaseAmount)
      //   ) {
      //     return item;
      //   }
      // });
      if (amountValid.length > 0) {
        let sortvalue = amountValid.sort(function (a, b) {
          return (
            moment(a.PurchaseCutoffTime, "HH:mm:ss").format("HH:mm:ss") >
            moment(b.PurchaseCutoffTime, "HH:mm:ss").format("HH:mm:ss")
          );
        });
        selectedMappingDetails = sortvalue[0];
        let validationAmounts = "success";
        let data = {};
        data.status = validationAmounts;
        data.response = selectedMappingDetails;

        return data;
      } else {
        let data = {};
        data.status = `Minimum Amount is ${labels.currency.rupeesymbol} ${flagCheckedSchemes[0]?.MinimumPurchaseAmount}`;
        // data.messages
        data.response = [];
        return data;
      }


    } else {
      if (flagCheckedSchemes != "") {
        //Time Validation
        let futurScheme = flagCheckedSchemes.filter((item) =>

          moment(item.PurchaseCutoffTime, "HH:mm:ss").format("HH:mm:ss") >
          moment(new Date(), "HH:mm:ss").format("HH:mm:ss")

        );
        if (futurScheme.length > 0) {
          let sortvalue = futurScheme.sort(function (a, b) {
            return (
              moment(a.PurchaseCutoffTime, "HH:mm:ss").format("HH:mm:ss") >
              moment(b.PurchaseCutoffTime, "HH:mm:ss").format("HH:mm:ss")
            );
          });
          let amountValid = sortvalue
          // .filter((item) => {
          //   // Amount Validation
          //   if (Number(item.MaximumPurchaseAmount) == 0) {
          //     if (Number(amount) >= Number(item.MinimumPurchaseAmount)) {
          //       return item;
          //     }
          //   } else if (
          //     Number(amount) >= Number(item.MinimumPurchaseAmount) &&
          //     Number(amount) <= Number(item.MaximumPurchaseAmount)
          //   ) {
          //     return item;
          //   }
          // });
          // if (amountValid.length == 0) {
          //   minimumAmount = futurScheme
          //     .map((item) => {
          //       return item.MinimumPurchaseAmount;
          //     })
          //     .sort(function (a, b) {
          //       return a - b;
          //     });
          //   maximumAmount = futurScheme
          //     .map((item) => {
          //       return item.MaximumPurchaseAmount;
          //     })
          //     .sort(function (a, b) {
          //       return a - b;
          //     });
          //   if (minimumAmount[0] > amount) {
          //     let validationMessage =
          //       Label.investmentPopup.minimumAmount +
          //       NegativeFinding(minimumAmount[0], "", "", "", "rupee")

          //     let data = {};
          //     data.status = validationMessage;
          //     data.response = [];
          //     return data;
          //   } else {
          //     if (maximumAmount[0] == 0) {
          //       let validationMessage = Label.investmentPopup.tooLargeAmount;
          //       let data = {};
          //       data.status = validationMessage;
          //       data.response = [];
          //       return data;
          //     } else {
          //       let validationMessage =
          //         Label.investmentPopup.maximumAmount +

          //         NegativeFinding(maximumAmount[0], "", "", "", "rupee")
          //         ;
          //       let data = {};
          //       data.status = validationMessage;
          //       data.response = [];
          //       return data;
          //     }
          //   }
          // } else {
          // Time Validation
          let sortvalue_Time = amountValid.sort(function (a, b) {
            return (
              moment(a.PurchaseCutoffTime, "HH:mm:ss").format("HH:mm:ss") >
              moment(b.PurchaseCutoffTime, "HH:mm:ss").format("HH:mm:ss")
            );
          });
          selectedMappingDetails = sortvalue_Time[0];

          let validationAmounts = "success";
          let data = {};
          data.status = validationAmounts;
          data.response = selectedMappingDetails;

          return data;
          // }
        } else {
          // Time Validation not pass out from stoke time so doing Amount Validation
          let amountValid = flagCheckedSchemes
          // .filter((item) => {
          //   if (item.MaximumPurchaseAmount == 0) {
          //     if (amount >= item.MinimumPurchaseAmount) {
          //       return item;
          //     }
          //   } else if (
          //     amount >= item.MinimumPurchaseAmount &&
          //     amount <= item.MaximumPurchaseAmount
          //   ) {
          //     return item;
          //   }
          // });
          // if (amountValid.length == 0) {
          //   minimumAmount = flagCheckedSchemes
          //     .map((item) => {
          //       return item.MinimumPurchaseAmount;
          //     })
          //     .sort(function (a, b) {
          //       return a - b;
          //     });
          //   maximumAmount = flagCheckedSchemes
          //     .map((item) => {
          //       return item.MaximumPurchaseAmount;
          //     })
          //     .sort(function (a, b) {
          //       return a - b;
          //     });
          //   if (minimumAmount[0] > amount) {
          //     let validationMessage =
          //       Label.investmentPopup.minimumAmount +
          //       " ₹" +
          //       minimumAmount[0]
          //         .toString()
          //         .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
          //     let data = {};
          //     data.status = validationMessage;
          //     data.response = [];

          //     return data;
          //   } else {
          //     if (maximumAmount[0] == 0) {
          //       let validationMessage = Label.investmentPopup.tooLargeAmount;
          //       let data = {};
          //       data.status = validationMessage;
          //       data.response = [];

          //       return data;
          //     } else {
          //       let validationMessage =
          //         Label.investmentPopup.maximumAmount +
          //         " ₹" +
          //         maximumAmount[0]
          //           .toString()
          //           .replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
          //       let data = {};
          //       data.status = validationMessage;
          //       data.response = [];

          //       return data;
          //     }
          //   }
          // } else {
          let sortvalue_Amount = amountValid.sort(function (a, b) {
            return (
              moment(a.PurchaseCutoffTime, "HH:mm:ss").format("HH:mm:ss") >
              moment(b.PurchaseCutoffTime, "HH:mm:ss").format("HH:mm:ss")
            );
          });
          selectedMappingDetails = sortvalue_Amount[0];

          let validationAmounts = "success";
          let data = {};
          data.status = validationAmounts;
          data.response = selectedMappingDetails;

          return data;
          // }
        }
      } else {
        let validationAmounts = "";
        if (Label.investmentPopup.keySIP === PurahaseKey) {
          validationAmounts = field != undefined ? `SIP Not Allowed for ${field}` : Label.investmentPopup.sipNotAllowed;
        } else if (Label.investmentPopup.keyOneTime === PurahaseKey) {
          validationAmounts = field != undefined ? `Purchase Not Allowed for ${field}` : Label.investmentPopup.purchaseNotAllowed;
        } else if (Label.investmentPopup.keySwitch === PurahaseKey) {
          validationAmounts = field != undefined ? `Switch Not Allowed for ${field}` : Label.investmentPopup.switchNotAllowed;
        } else if (Label.investmentPopup.keySTP === PurahaseKey) {
          validationAmounts = field != undefined ? `STP Not Allowed for ${field}` : Label.investmentPopup.stpNotAllowed;
        }

        let data = {};
        data.status = validationAmounts;
        data.response = [];

        return data;
      }
    }
  } else {
    let validationAmounts = "";
    if (Label.investmentPopup.keySIP === PurahaseKey) {
      validationAmounts = field != undefined ? `SIP Not Allowed for ${field}` : Label.investmentPopup.sipNotAllowed;
    } else if (Label.investmentPopup.keyOneTime === PurahaseKey) {
      validationAmounts = field != undefined ? `Purchase Not Allowed for ${field}` : Label.investmentPopup.purchaseNotAllowed;
    } else if (Label.investmentPopup.keySwitch === PurahaseKey) {
      validationAmounts = field != undefined ? `Switch Not Allowed for ${field}` : Label.investmentPopup.switchNotAllowed;
    } else if (Label.investmentPopup.keySTP === PurahaseKey) {
      validationAmounts = field != undefined ? `STP Not Allowed for ${field}` : Label.investmentPopup.stpNotAllowed;
    }

    let data = {};
    data.status = validationAmounts;
    data.response = [];

    return data;
  }
};
export function Encryption(data) {
  const iv = 'bfcvJCbmwS0qaQRmamEyJg==';
  const key = 'WHH4nv43Let4huxP6vBqoabnE7JkpibkMf6wCGRPJBc=';

  const fkey = CryptoJS.enc.Base64.parse(key);
  const fiv = CryptoJS.enc.Base64.parse(iv);

  const enc = CryptoJS.AES.encrypt(data, fkey, {
    iv: fiv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });
  const final = enc.ciphertext.toString(CryptoJS.enc.Base64);
  return final;
}
export const EncryptURL = (originalURL) => {
  const key = 'WHH4nv43Let4huxP6vBqoabnE7JkpibkMf6wCGRPJBc'; // Ensure this is kept secure
  const encryptedURL = CryptoJS.AES.encrypt(originalURL, key).toString();

  return encryptedURL
}
export const EncryptClientID = (data) => {
  // let ivData = labels.key.clientIDEncryptionPassword;

  // var iv16bit = labels.key.ivKey.slice(0, 16);

  // var key = CryptoJS.enc.Utf8.parse(labels.key.clientIDEncryptionPassword);

  // var iv = CryptoJS.enc.Utf8.parse(iv16bit);
  // var encryptedText = data



  // var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(encryptedText), key,

  //   {

  //     keySize: 256,

  //     iv: iv,

  //     mode: CryptoJS.mode.CBC,

  //     padding: CryptoJS.pad.ZeroPadding

  //   });
  const iv = 'bfcvJCbmwS0qaQRmamEyJg==';
  const key = 'WHH4nv43Let4huxP6vBqoabnE7JkpibkMf6wCGRPJBc=';

  const fkey = CryptoJS.enc.Base64.parse(key);
  const fiv = CryptoJS.enc.Base64.parse(iv);
  // const secretKey = 'your-secret-key'; // Ensure to use a strong secret key
  const encrypted = CryptoJS.AES.encrypt(data.toString(), fkey, {
    iv: fiv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  }).toString();
  const base64Encoded = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encrypted));
  // const final = encrypted.ciphertext.toString(CryptoJS.enc.Base64);
  // return final;
  return base64Encoded;
}
export const Browser = (value) => {
  if (value != undefined) {
    if (value.match(/Firefox\/([0-9.]+)/)) {
      return {
        "browserName": 'Firefox',
        "browserVersion": value.match(/Firefox\/([0-9.]+)/)[1]
      }

    } else if (value.match(/Chrome\/([0-9.]+)/)) {
      return {
        "browserName": 'Chrome',
        "browserVersion": value.match(/Chrome\/([0-9.]+)/)[1]
      }

    } else if (value.match(/Safari\/([0-9.]+)/)) {
      return {
        "browserName": 'Safari',
        "browserVersion": value.match(/Version\/([0-9.]+)/)[1]
      }

    } else if (value.match(/MSIE ([0-9.]+)/) || value.match(/Trident.*rv:([0-9.]+)/)) {
      return {
        "browserName": 'Internet Explorer',
        "browserVersion": value.match(/MSIE ([0-9.]+)/)[1]
      }

    } else if (value.match(/Edge\/([0-9.]+)/)) {
      return {
        "browserName": 'Microsoft Edge',
        "browserVersion": value.match(/Edge\/([0-9.]+)/)[1]
      }

    } else {
      return {
        "browserName": 'Unknown',
        "browserVersion": 'N/A'
      }

    }
  }
}
export const DetectDeviceType = () => {
  const isMobile = /iPhone|iPad|iPod|Android|Windows Phone/i.test(navigator.userAgent);
  const isTablet = /iPad|Android/i.test(navigator.userAgent) && !isMobile;
  const isDesktop = !isMobile && !isTablet;

  if (isMobile) {
    return "Mobile Device";
  } else if (isTablet) {
    return "Tablet Device";
  } else if (isDesktop) {
    return "Desktop Device";
  } else {
    return "Unknown Device";
  }

}
// export const callFrequently = () => {
//   let colors = window.globalConfig.color
//   setInterval(() => {
//     return colors

//   }, 2000);
//   return colors
// }
export function findDateFormat(dateString) {
  const possibleFormats = ['dd-MMM-yyyy', 'dd/mm/yyyy', 'dd/MMM/yyyy', 'dd MMM yyyy',
    // Add more formats as needed
  ];
  let parsedDate;
  for (const format of possibleFormats) {
    try {
      parsedDate = parse(dateString, format, new Date());
      break;
    } catch (error) {
      parsedDate = 'dd MMM yyyy';
    }
  } return parsedDate;
}
export const compareDataSet = (actualData, targetData) => {
  let isEqual = true;
  let a = replaceNull(targetData, "NA")
  let b = replaceNull(actualData, "NA")
  if (a == b) {

  } else if ((a == "NA" && b != "NA") || (a != "NA" && b == "NA")) {
    isEqual = false;

  }
  else if (a?.length !== b?.length) {
    isEqual = false;
  } else {
    for (let i = 0; i < a.length; i++) {
      let objA = a[i];
      let objB = b[i];

      // Get the keys of the object
      let keysA = Object?.keys(objA);
      let keysB = Object?.keys(objB);

      // Compare the length of the keys arrays
      if (keysA?.length !== keysB?.length) {
        isEqual = false;
        break;
      }

      // Compare the values of each key
      for (let j = 0; j < keysA.length; j++) {
        let key = keysA[j];
        if (objA[key] !== objB[key]) {
          isEqual = false;
          break;
        }
      }

      // Break if objects are not equal
      if (!isEqual) {
        break;
      }
    }
  }
  return isEqual
}
export const formatDate = (inputDate) => {
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  // Split the input date based on various separators 
  const parts = inputDate.split(/[\/-]/);
  // Check if the day, month, and year parts are present 
  if (parts.length === 3) {
    const day = parts[0].trim();
    const month = months[parseInt(parts[1]) - 1];
    const year = parts[2].trim();     // Create the formatted date   
    return `${day} ${month} ${year}`;
  } else {
    return inputDate;
  }
} 
