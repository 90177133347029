import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
import { Asset } from '../../utils/mainasset';
import { Browser, DetectDeviceType, Encryption } from '../../utils/commonfunction';

function getLoginDetails(email, password,) {
  const timeStamp = new Date().getTime().toString();
  const timeZone = Intl.DateTimeFormat(undefined, { timeZoneName: 'short' }).resolvedOptions().timeZone;
  const referrelUrl = window.location.href
  const operatingSystem = navigator.userAgentData.platform;
  const userAgent = navigator.userAgent;
  let browser = Browser(userAgent)
  browser = `${browser.browserName} ${browser.browserVersion}`
  const deviceType = DetectDeviceType();
  
  let data = {};
  data.EmailID = Encryption(email);
  data.Password = Encryption(password);
  data.IP = ""
  data.BrowserVersion = browser
  data.MobileAppVersion = ""
  data.DeviceType = deviceType
  data.OSType = operatingSystem
  data.TimeStamp = timeStamp
  data.TimeZone = timeZone
  data.ReferrelURL = referrelUrl
  // data.EmailID = email;
  // data.Password =password;
  data.Source = Asset.source
  return Networking.PostApi(ApiValues.Login, data, labels.key.apiKey, labels.key.apiKey, true).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
  // fetch method ends here
}

export default getLoginDetails
