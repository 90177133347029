import React, { Component } from "react";
import { Toolbar, Badge, CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import { LocalMall } from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import Grow from "@material-ui/core/Grow";
// import colorsConstant from "../../utils/colors/colors";
import labels from "../../utils/constants/labels";
import Popover from "@material-ui/core/Popover";
import Basket from '../../utils/assets/basket.png'
import ListItem from "@material-ui/core/ListItem";
import { Link } from "react-router-dom";
import { getEmptyAction, ResetPreventAPICallAction, storeFormNo, } from "../../redux/actions/login/loginaction";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import "../../utils/stylesheets/menu/menu.css";
import {
  accountselection, uccSelection
} from "../../redux/actions/login/loginaction";
import { storeL5Report } from "../../redux/actions/L5Report/L5ReportAction";
import {

  dropdownvalue,
} from "../../redux/actions/dropdown/dropdownaction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ClickAwayListener } from "@material-ui/core";
// import Cookies from 'universal-cookie';
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import secureStorage from "../../utils/securestorage";
import { withRouter } from "react-router-dom";
import storage from "redux-persist/lib/storage";
import { Asset } from "../../utils/mainasset";
import DrawerRight from "../drawer/drawerRight";
import localforage from "localforage";
import moment from "moment";
import {
  clientIDchange,
  TradeL5IDDetails
} from "../../redux/actions/login/loginaction";
import { basicColor } from "../../utils/colors/colors";
import { replaceNull, hexToRgba } from "../../utils/commonfunction";
// import { enableLoaderForAllPages } from "../../redux/actions/login/loginresetsuccessaction";
import getL5report from "../../api/L5report/L5report";
import logOutUser from "../../api/login/logout";
import Loader from "../loader/loader";


// const cookies = new Cookies();
// let cartBadge = false;
const styles = (theme) => ({
  root: {
    // flexGrow: 1,
    // marginBottom: 30,
    margin: "auto",
    // position: "fixed",
    // top: 0,
    // zIndex: 2,
    backgroundColor: window.globalConfig.color.PRIMARY,
    boxShadow: "none",
    // borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_LIGHT}`,
    width: "100%",
    marginLeft: "0px",
  },

  toolbar: {
    display: 'grid',
    gridTemplateColumns: '10% 80% 10%',
    [theme.breakpoints.down('xs')]: {
      display: 'grid',
      gridTemplateColumns: '2% 95% 2%',
    },

  },

  title: {
    paddingRight: 28, //modified y yogitha
    // paddingBottom: 20, //Modified from 40 to 20 by Celsia
    // paddingTop: 20,
    fontSize: 15,
    color: window.globalConfig.color.cyanBluish,
    cursor: "pointer",

    [theme.breakpoints.down("xs")]: {
      padding: "7px",
      //modified by Celsia
    },

    [theme.breakpoints.between("600", "851")]: {
      paddingRight: 6,
      //modified by Celsia
    },
    [theme.breakpoints.between("1000", "2000")]: {
      paddingRight: 13,
      //modified by Celsia
    },
    "&:hover": {
      color: window.globalConfig.color.WHITE,
    },
  },
  titleSelect: {
    paddingRight: 28, //modified y yogitha
    // paddingBottom: 20, //Modified from 40 to 20 by Celsia
    // paddingTop: 20,
    fontSize: 15,
    color: window.globalConfig.color.WHITE,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      padding: "7px",
      //modified by Celsia
    },

    [theme.breakpoints.between("600", "851")]: {
      paddingRight: 6,
      //modified by Celsia
    },
    [theme.breakpoints.between("1000", "2000")]: {
      paddingRight: 13,
      //modified by Celsia
    },
    "&:hover": {
      color: window.globalConfig.color.WHITE,
    },
  },
  iconSelect: {

    paddingRight: 28, //modified y yogitha
    // paddingBottom: 20, //Modified from 40 to 20 by Celsia
    // paddingTop: 20,
    fontSize: 15,
    color: window.globalConfig.color.WHITE,
    cursor: "pointer",

    [theme.breakpoints.down("xs")]: {
      padding: "7px",
      //modified by Celsia
    },

    [theme.breakpoints.between("600", "851")]: {
      paddingRight: 6,
      //modified by Celsia
    },
    [theme.breakpoints.between("1000", "2000")]: {
      paddingRight: 13,
      //modified by Celsia
    },
    "&:hover": {
      color: window.globalConfig.color.WHITE,
    },
  },
  profileTitle: {
    paddingBottom: 20, //Modified from 40 to 20 by Celsia
    paddingTop: 20,
    fontSize: 15,
    color: window.globalConfig.color.cyanBluish,
    cursor: "pointer",
    // marginLeft:10, //Commended by Celsia
    marginRight: -5,
    [theme.breakpoints.down("xs")]: {
      padding: "7px",
      //modified by Celsia
    },
    [theme.breakpoints.between("600", "851")]: {
      paddingRight: 10,
      //modified by Celsia
    },
  },

  titles: {
    justifyContent: "flex-start",
    fontSize: 24,
    fontWeight: "bold",
    color: window.globalConfig.color.WHITE,
  },
  icon: {
    fontSize: 26,
    //color: window.globalConfig.color.cyanBluish,
    cursor: "pointer",
  },
  //modified by Celsia {Start}
  accounticon: {
    fontSize: 26,
    color: window.globalConfig.color.cyanBluish,
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      paddingRight: 25,
    },
  },
  //  {end}
  menu: {

    width: '100%',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down("xs")]: {
      height: 20,
      bottom: 20,
    },
  },


  logo: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "flex-start",
    float: "left",
    cursor: "pointer"
    // marginLeft: "2vw",
  },


  users: {
    display: "block",
    padding: "15px 20px 12px !important",
    color: window.globalConfig.color.LABLE_GRAY,
    borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    cursor: "default",
    '&:hover': {
      backgroundColor: window.globalConfig.color.RosyBrown,
      color: `${window.globalConfig.color.BROWN} !important`
    },
  },
  userstext: {
    display: "block",
    color: window.globalConfig.color.LABLE_GRAY,
    borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,

    padding: "9px 20px 9px !important",
    cursor: "default",
    '&:hover': {
      backgroundColor: window.globalConfig.color.RosyBrown,
      color: `${window.globalConfig.color.BROWN} !important`
    },
  },
  protxt: {
    display: "block",
    color: window.globalConfig.color.LABLE_GRAY,
    borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    padding: "8px 20px 12px !important",
    '&:hover': {
      backgroundColor: window.globalConfig.color.RosyBrown,
      color: `${window.globalConfig.color.BROWN} !important`
    },
  },
  protext: {
    display: "block",
    fontSize: 14,
    color: window.globalConfig.color.BLACK_PRIMARY,
    '&:hover': {
      backgroundColor: window.globalConfig.color.RosyBrown,
      color: `${window.globalConfig.color.BROWN} !important`
    },
  },

  selectedList: {
    display: "block",
    color: window.globalConfig.color.LABLE_GRAY,
    borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    padding: "8px 20px 12px !important",
    backgroundColor: window.globalConfig.color.RosyBrown,
    color: window.globalConfig.color.BROWN,
  },

  List: {
    display: "block",
    fontSize: 14,
    color: window.globalConfig.color.BLACK_PRIMARY,
    backgroundColor: window.globalConfig.color.RosyBrown,
    color: window.globalConfig.color.BROWN,
  },

  username: {
    fontSize: 10,
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontWeight: 500,
    '&:hover': {
      backgroundColor: window.globalConfig.color.RosyBrown,
      color: `${window.globalConfig.color.BROWN} !important`
    },
  },
  popup: {
    boxShadow: "7px 7px 10px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: window.globalConfig.color.WHITE,
    fontSize: 10,
    width: 240,
    cursor: "pointer",
  },
  logoSize: {
    width: "230px",
    marginLeft: "-4% !important",
    //....finnovate login logo only this property use that.....
    padding: '1% 0% 1% 0%',
    // height:"60px",
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      width: 137,
      marginTop: "-4px",
    },
  },
  // Mobile Responsive created by Muthu Lakshmi K
  logoSizeCard: {
    width: 164,
    height: 48

  },
  arrowBackCard: {
    display: "flex",
    flexDirection: "row",
    paddingTop: "1%",
    // marginLeft: "52%",
    // [theme.breakpoints.down('600px')]: {
    //   marginLeft:'17%',
    //   marginTop:10,
    // },
    [theme.breakpoints.between("600", "1000")]: {
      marginLeft: "28%",
    },
    [theme.breakpoints.between("300", "350")]: {
      marginLeft: "10% !important",
      marginTop: 8,
    },
    [theme.breakpoints.between("350", "400")]: {
      // marginLeft: "15% !important",
      marginTop: 10,
    },
    [theme.breakpoints.between("400", "600")]: {
      marginLeft: "17%",
      marginTop: 10,
    },
    // [theme.breakpoints.between('1000', '1100')]: {
    //   marginLeft: "41% !important",
    // },
    // [theme.breakpoints.between('1250', '1300')]: {
    //   marginLeft: "47.5% !important",
    // },
    // [theme.breakpoints.between('1300', '1400')]: {
    //   marginLeft: "49% !important",
    // },
  },

  accountIconDesign: {
    paddingRight: 1,
    marginLeft: 54,
    color: window.globalConfig.color.cyanBluish,
    [theme.breakpoints.between("300", "350")]: {
      marginLeft: "30px !important",
    },
    [theme.breakpoints.between("350", "600")]: {
      marginLeft: "30px !important",
    },
  },


  welcome: {
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: 10,
    '&:hover': {
      backgroundColor: window.globalConfig.color.RosyBrown,
      color: `${window.globalConfig.color.BROWN} !important`
    },
    // marginTop: "-10px"
  },
  link: {
    textDecoration: "none",
    color: window.globalConfig.color.LABLE_GRAY,
    borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,

    '&:hover': {
      backgroundColor: window.globalConfig.color.RosyBrown,
      color: `${window.globalConfig.color.BROWN} !important`
    },
  },

  rightMenu: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',

  },

  //Added properties for Mobile Responsive by Celsia

  shoppingcart: {
    paddingRight: 2,
    height: 24,
    [theme.breakpoints.down("600")]: {
      height: 24,
    },
  },
  //Added properties for Mobile Responsive by Celsia
  notification: {
    paddingRight: 1,
    height: 28,
    [theme.breakpoints.down("600")]: {
      height: 25,
    },
  },
  //Added properties for Mobile Responsive by Celsia

  accounticon: {
    paddingRight: 1,
    [theme.breakpoints.down("600")]: {
      height: 28,
      paddingRight: "4px"
    },
  },
  profileIconSelect: {
    padding: "5px 0px 3px",
    paddingBottom: "7px",
    color: window.globalConfig.color.WHITE,
  },
  profileIconStyle: {
    padding: "5px 0px 3px",
    paddingBottom: "7px",
  },
  menuHeader: {
    display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "0px 144px",
    alignItems: 'center',
    [theme.breakpoints.down("600")]: {
      display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "0px 15px",
      alignItems: 'center',
    },
  }
});
let showUcc = false;
class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: "",
      name: "",
      cartBadge: false,
      sipCart: [],
      onetimeCart: [],
      redirect: false,
      showHideContianer: false,
      hidemenu: false,
      logindetails: {},
      Clientcode: '',
      ClientID: '',
      familyName: '',
      familymembers: '',
      List: [],
      selectedaccount: "Combined",
      Account: 0,
      Date: '',
      backgroundColor: '',
      color: ''
    };
  }
  componentDidMount() {
    var session = secureStorage.getItem("user");
    var session_store = session;
    var family = this.props.logindetails.FamilyName;
    //   (family)
    var color = hexToRgba(window.globalConfig.color.PRIMARY, 0.2)
    var element = document.getElementsByClassName("menu");
    if (element.length != 0) {
      element[0].style.borderBottom = `1px solid ${color}`
      element[0].style.backgroundColor = window.globalConfig.color.PRIMARY
    }
    this.setState({ familyName: family });
    if (session_store != null) {
      this.setState({ name: session_store.UserName });
    }
    this.setState({ Date: moment(new Date()).format('DD MMM YYYY') })
    var clientcode = this.props.mainClientID
    this.setState({ Clientcode: clientcode })

    let familydetails;
    if (this.props.familydetails != "NA" && this.props.familydetails != null) {
      familydetails = this.props.familydetails.length != 0 ? this.props.familydetails?.filter(
        (ele, ind) =>
          ind ===
          this.props.familydetails.findIndex((elem) => elem.ClientID === ele.ClientID && ele.ClientID != this.props.mainClientID))
        : []
      this.setState({ List: familydetails })
    }
    else {
      familydetails = [];
      this.setState({ List: [] })
    }
    // if (this.props?.L5report != null && props.L5report != undefined) {
    //   if (this.props?.L5report?.length != 0) {
    //     if (this.props?.L5report?.length > 1) {
    //       showUcc = true
    //       let defaultUcccode = props.L5report
    //       // 
    //       // if (defaultUcccode.length > 2) {
    //       this.setState({ List: defaultUcccode });
    //       if (
    //         defaultUcccode?.filter((item) => item.L5ID === props.L5ID)?.length != 0
    //       ) {
    //         this.setState({
    //           SelectedAccount:
    //             defaultUcccode?.filter((item) => item.L5ID === props.L5ID)[0].Name
    //         });
    //         this.setState({
    //           Account:
    //             defaultUcccode?.filter((item) => item.L5ID === props.L5ID)[0].L5ID
    //         });
    //       } else {
    //         this.setState({ SelectedAccount: "Combined" })
    //         this.setState({ Account: 0 })
    //       }
    //       // }
    //     } else {
    //       showUcc = false;
    //     }
    //   } else {
    //     showUcc = false;
    //   }
    // } else {
    //   showUcc = false;
    // }
    this.setState({ logindetails: this.props?.logindetails })
    this.state.sipCart = this.props?.sipCartData;
    this.state.onetimeCart = this.props?.onetimeCartData;
    var session = secureStorage.getItem("user");

    var session_store = session;
    if (session_store != null) {
      this.setState({ name: session_store?.UserName });
    }
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();

    if (
      this.props?.sipCartData?.length == 0 ||
      this.props?.onetimeCartData?.length == 0
    ) {
      this.state.cartBadge = false;
    } else {
      this.state.cartBadge = true;
    }
    this.setState({ logindetails: this.props?.logindetails })
    if (this.props.memberFunc != undefined) {
      this.props.memberFunc.current = this.handleclickName
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
  }

  componentDidUpdate(prevState) {
    let list;
    const { allCart, onetimeCartData, sipCartData, logindetails, apiPending, familydetails, mainClientID } = this.props
    let { sipCart, onetimeCart } = this.state
    if (
      sipCartData?.length == 0 ||
      onetimeCartData?.length == 0
    ) {
      this.state.cartBadge = false;
    } else {
      this.state.cartBadge = true;
    }
    if (prevState.allCart != allCart) {
      this.state.sipCart = sipCartData;
      this.state.onetimeCart = onetimeCartData;
      if (sipCartData?.length == 0) {
        this.state.cartBadge = false;
      } else {
        this.state.cartBadge = true;
      }
    }
    if (logindetails != null && logindetails != undefined) {
      if (prevState.logindetails != logindetails && Object.keys(logindetails)?.length != 0) {
        this.setState({ logindetails: logindetails });
      }
    }

    if (prevState.familydetails != familydetails || prevState.mainClientID != mainClientID) {
      if (this.props.familydetails != "NA" && this.props.familydetails != null) {
        list = this.props.familydetails.length != 0 ? this.props.familydetails?.filter(
          (ele, ind) =>
            ind ===
            this.props.familydetails.findIndex((elem) => elem.ClientID === ele.ClientID && ele.ClientID != this.props.mainClientID))
          : []
        this.setState({ List: list })
      }
      else {
        familydetails = [];
        this.setState({ List: [] })
      }
    }
    var color = hexToRgba(window.globalConfig.color.PRIMARY, 0.2)
    var element = document.getElementsByClassName("menu");
    if (element.length != 0) {
      element[0].style.borderBottom = `1px solid ${color}`
      element[0].style.backgroundColor = window.globalConfig.color.PRIMARY
    }


  }

  resize() {
    let currentHideNav = window.innerWidth >= 600 && window.innerWidth >= 1000;
    if (currentHideNav !== this.state.hidemenu) {
      this.setState({ hidemenu: currentHideNav });
    }
  }

  logout = async () => {
    window.globalConfig.color = basicColor

    logOutUser(this.props.logindetails.SessionID, this.props.logindetails.ClientID, "", false).then((res) => {

    }).catch((err) => {
      // console.log(err)
    })
    secureStorage.removeItem(`invalidAuth${this.props.logindetails.SessionID}`)

    storage.removeItem('persist:root')
    this.props?.getEmptyAction("logout")
    this.props?.ResetPreventAPICallAction()
    secureStorage.removeItem("apiPending")
    localforage.removeItem("root")
    localforage.clear();
    secureStorage.clear();
    localStorage.clear();
    secureStorage.removeItem("colors")
    secureStorage.removeItem("loginDetails")
    secureStorage.setItem("apidate", "login")
    // secureStorage.setItem("logout", "done")
    secureStorage.removeItem('user')
    secureStorage.removeItem('sessionID')
    // secureStorage.removeItem('invalidAuth')
    secureStorage.removeItem("mfRedeemUnits")
    secureStorage.removeItem("stocksRedeemUnits")
    secureStorage.removeItem("bondsRedeemUnits")
    secureStorage.removeItem('clientid')
    secureStorage.removeItem('mainclientid')
    // secureStorage.removeItem('familydetails')
    // secureStorage.removeItem('admin')
    // secureStorage.removeItem('familyname')
    // secureStorage.removeItem('mappingDetails')
    // secureStorage.removeItem('selectedMappingDetails')
    // secureStorage.removeItem('folioNumber')
    // secureStorage.removeItem('details')
    // secureStorage.removeItem('purchaseDetails')
    // secureStorage.removeItem('productid')
    // secureStorage.removeItem('xray')
    // secureStorage.removeItem('mobileno')
    // secureStorage.removeItem('email')
    secureStorage.removeItem('purchaseorderstatus')
    this.props?.accountselection(0)

    this.props?.history.push(labels.Path.Login)


  }
  handleclickaway = () => {
    this.setState({ showHideContianer: false });
  };
  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  navigationPortfolio = (event) => {

    if (event == 0) {
      if (this.props?.RedirectUrl == undefined) {
        this.props?.history.goBack();
      } else {
        this.props?.history.push(this.props?.RedirectUrl)
      }
    }
    else {

    }
  };
  handleClose = (event) => {
    this.setState({
      anchorEl: null,
    });
  };
  l5ReportDataCall = async (ClientID) => {
    let L5report = []
    this.setState({
      List: this.state.List.map((item) => {
        return { ...item, isLoading: ClientID == item.ClientID ? true : false }
      })
    })
    await getL5report(ClientID).then(async (res) => {
      if (res.status == "S") {
        L5report = res.data == null || res.data == undefined ? [] : res.data
        this.props.storeL5Report(L5report)
      } else {
        L5report = []
      }

    }).catch(async (err) => {
      L5report = []
      // await this.props?.getEmptyAction("family", "loader", false)
    })
    return L5report
  }
  handleclickName = async (code) => {
    // console.log(code)
    let L5report = []
    var session = secureStorage.getItem("user");
    var session_store = session;
    if (code.ClientID != this.props.logindetails.ClientID) {
      L5report = await this.l5ReportDataCall(code.ClientID)
      this.props?.getEmptyAction("family")
      await this.props.storeFormNo(replaceNull(code, ""))
      this.props.uccSelection("")
      // console.log(code.ClientID, this.props.date, code)

      await this.props?.childFunc.current(this.props?.logindetails.SessionID, code.ClientID, this.props?.date, L5report.length > 1 || L5report.length == 0 ? 0 : replaceNull(L5report[0]?.L5ID, 0), "family");
      this.props?.clientIDchange(code.ClientID, code.ClientName);
      this.setState({
        List: this.state.List.map((item) => {
          return { ...item, isLoading: false }
        })
      })
      this.props?.accountselection(L5report.length > 1 || L5report.length == 0 ? 0 : replaceNull(L5report[0]?.L5ID, 0))
      this.props?.TradeL5IDDetails(0)

      // secureStorage.setItem("clientid", code.ClientID);
      // this.props.enableLoaderForAllPages(true)
      this.handleClose();

    }

  };
  handleclickmainName = async () => {
    let L5report = []
    var session = secureStorage.getItem("user");
    var clientcode = this.props.mainClientID
    if (this.props.mainClientID != this.props.logindetails.ClientID) {
      L5report = await this.l5ReportDataCall(clientcode)
      // console.log(L5report)
      this.props?.getEmptyAction("family")
      this.props.storeFormNo(replaceNull(session, ""))
      this.props.uccSelection("")
      await this.props?.childFunc.current(this.props?.logindetails.SessionID, clientcode, this.props?.date, L5report.length == 1 ? L5report[0].L5ID : 0, "family")
      this.setState({ Clientcode: clientcode });
      this.props?.clientIDchange(clientcode, session.mainUser)
      this.props?.accountselection(L5report.length > 1 || L5report.length == 0 ? 0 : replaceNull(L5report[0]?.L5ID, 0))
      this.props?.TradeL5IDDetails(0)
      this.handleClose();
    }


  };
  addDefaultSrc(ev) {

    ev.target.src = Asset.logoUrl
  }
  trackRedirection = () => {
    this.props?.history.push(labels.Path.Tracking);
  }

  render() {

    const open = Boolean(this.state.anchorEl);
    const { classes } = this.props;
    if (this.props?.page === "payment") {
      return (


        <div className="menu" style={{ height: 64, width: "100%", position: "sticky", top: 0, zIndex: 2, backgroundColor: window.globalConfig.color.PRIMARY, paddingTop: 10 }}>
          <div className={classes.menuHeader}>
            <div onClick={() => this.props.history.push(labels.Path.Dashboard)} style={{ cursor: "pointer" }}>
              <img src={`data:image/png;base64,${window.globalConfig.companyDetails.icon}`} onError={this.addDefaultSrc} className={classes.logoSize} />
            </div>
            <div className={classes.arrowBackCard}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <ArrowBackIos
                  color={window.globalConfig.color.WHITE}
                  style={{ color: window.globalConfig.color.WHITE, cursor: "pointer" }}
                  onClick={() => this.navigationPortfolio(0)}
                />
                <p
                  style={{
                    marginTop: 4,
                    color: window.globalConfig.color.WHITE,
                    cursor: "pointer",
                  }}
                  onClick={() => this.navigationPortfolio(0)}
                >
                  {"Back"}
                </p>
              </div>
              <div style={{ marginLeft: "2%" }}>
                <span noWrap style={{ marginTop: "1%", float: "right" }}>
                  <Badge className={classes.accountIconDesign}>
                    <AccountCircleIcon
                      id="profile-icon"
                      className={classes.icon}
                      onClick={this.handleClick}
                    />
                  </Badge>
                </span>
                <Popover
                  //  disableScrollLock={true}
                  // disablePortal={true}
                  open={open}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <div className={classes.popup}>
                    {this.props?.isfamilyhead == true && this.props.familydetails?.length != 0 ?
                      <ListItem
                        id="user-popup"
                        autoFocus={true}
                        className={classes.users}
                        style={{ cursor: 'pointer' }}
                        onClick={() => this.handleclickmainName()}
                      >
                        <Typography
                          id="popup-tab"
                          className={classes.welcome}
                        >
                          {labels.logoutmenu.welcome}
                        </Typography>

                        <Typography
                          id="user-name"
                          className={classes.username}
                        >

                          {this.props?.logindetails?.mainUser}
                        </Typography>

                        {/* 
                        <Typography>
                          yogitha
                        </Typography> */}

                      </ListItem>
                      :
                      <ListItem
                        id="user-popup"
                        autoFocus={true}
                        className={classes.users}
                      // onClick={() => this.handleclickmainName()}
                      >
                        <Typography
                          id="popup-tab"
                          className={classes.welcome}
                        >
                          {labels.logoutmenu.welcome}
                        </Typography>

                        <Typography
                          id="user-name"
                          className={classes.username}
                        >
                          {this.props?.logindetails?.mainUser}
                        </Typography>
                      </ListItem>

                    }
                    {this.props.showFamilyMember && this.props?.isfamilyhead == true && this.state.List?.filter((item) => item.ClientID != this.state.Clientcode)?.length != 0 ?
                      <div >
                        <Typography
                          id="popup-tab"
                          className={classes.welcome}
                          style={{ textIndent: "1.5em" }}
                        >
                          {labels.menu.members}
                        </Typography>
                        <div>
                          <div>
                            {this.state.List?.length !=
                              0 ?
                              (
                                this.state.List
                                  .map((item, index) => {
                                    return (
                                      item.ClientName != "" ? (
                                        // <List
                                        // >
                                        <ListItem
                                          key={index}
                                          className={[this.props.logindetails.ClientID === item.ClientID ? classes.selectedList : classes.protxt]}
                                          onClick={() => this.handleclickName(item)}
                                        >
                                          <Typography
                                            className={
                                              this.props.logindetails.ClientID === item.ClientID
                                                ? classes.List
                                                : classes.protext
                                            }
                                          >{`${item.ClientName}`}{item.isLoading && <CircularProgress size={20} thickness={1}></CircularProgress>}</Typography>
                                        </ListItem>
                                        // </List>
                                      )
                                        : (
                                          <div></div>
                                        )
                                    );
                                  }
                                  )
                              ) : (
                                <Typography
                                  className={classes.protext}
                                  style={{
                                    marginLeft: 16,
                                    marginTop: 10,
                                    marginBottom: 10,
                                  }}
                                >
                                  {labels.menu.noMembers}
                                </Typography>
                              )}
                          </div>
                        </div>
                      </div>
                      :
                      <></>
                    }
                    <div >
                      <Link
                        to={{
                          pathname: labels.Path.UserDetails,
                          state: { data: 0 },
                        }}
                        className={classes.link}
                        onClick={this.handleClose}
                      >
                        <ListItem
                          id="user-popup"
                          autoFocus={true}
                          style={{ cursor: "pointer" }}
                          className={classes.userstext}
                        >
                          <Typography className={classes.protext}>
                            {labels.logoutmenu.profile}
                          </Typography>
                        </ListItem>
                      </Link>
                    </div>
                    <ListItem
                      onClick={() => {
                        this.logout();
                      }}
                      className={classes.protxt}
                      id="logout"
                    >
                      <Link to="/" className={classes.link}>
                        <Typography className={classes.protext}>
                          {" "}
                          {labels.logoutmenu.logout}
                        </Typography>
                      </Link>
                    </ListItem>
                  </div>
                </Popover>
              </div>
            </div>
          </div>
        </div>

      );
    } else {

      return (
        <header style={{ position: "sticky", top: 0, zIndex: 2 }}>

          <div className={[classes.root, "menu"].join(" ")} >
            <div className={classes.toolbar}>
              <div></div>
              <Toolbar >
                <DrawerRight
                  isGuestUser={this.props?.logindetails?.EntityTypeID}
                />

                <Grid className={classes.menu}>
                  <Grid item xs={4} className={classes.logo} onClick={() => this.props.history.push(labels.Path.Dashboard)}>
                    <img src={`data:image/png;base64,${window.globalConfig.companyDetails.icon}`} onError={this.addDefaultSrc} className={classes.logoSize} />

                  </Grid>
                  <Grid
                    item
                    xs={8}
                    className={classes.rightMenu}
                    id="rightMenuAlign"
                  >
                    {/* {this.state.hidemenu && ( */}
                    {window.globalConfig.isDashboardAllowed && <Typography
                      className={[
                        "portfoliobutton",
                        window.location.pathname == "/Dashboard"
                          ? classes.titleSelect
                          : classes.title,
                      ].join(" ")}
                      variant="button"
                      noWrap
                      onClick={() => {
                        this.props?.history.push(labels.Path.Dashboard);
                      }}
                    >
                      {labels.menu.Dashboard}
                    </Typography>}
                    {Boolean(window.globalConfig.IsFamilyReportAllowed) && this.props?.isfamilyhead && this.props.familydetails.length > 1 && <Typography
                      className={[
                        "portfoliobutton",
                        window.location.pathname == labels.Path.FamilyReport
                          ? classes.titleSelect
                          : classes.title,
                      ].join(" ")}
                      variant="button"
                      noWrap
                      onClick={() => {
                        this.props?.history.push(labels.Path.FamilyReport);
                      }}
                    >
                      {labels.menu.FamilyReport}
                    </Typography>}
                    {/* ) */}
                    {/* }
                    {this.state.hidemenu && */}
                    {/* // this.props?.logindetails.EntityTypeID != 9 && ( */}
                    <Typography
                      className={[
                        window.location.pathname == "/Tracking"
                          ? classes.titleSelect
                          : classes.title,
                        "portfoliobutton",
                      ].join(" ")}
                      variant="button"
                      noWrap
                      onClick={this.trackRedirection}
                    >
                      {labels.menu.track}
                    </Typography>

                    {/* // ) */}
                    {/* }
                    {this.state.hidemenu && ( */}
                    {window.globalConfig.isTradeAllowed && <Typography
                      className={[
                        window.location.pathname == "/Transact"
                          ? classes.titleSelect
                          : classes.title,
                        "portfoliobutton",
                      ].join(" ")}
                      variant="button"
                      noWrap
                      onClick={() => {
                        this.props?.history.push(labels.Path.Transact);

                      }}
                    >
                      {labels.menu.transact}
                    </Typography>}
                    {/* )} */}

                    {(this.props.logindetails.ClientID != 0 || this.props.logindetails.EntityTypeID != 9) &&
                      (
                        <Typography
                          className={[
                            window.location.pathname == "/user/portfolio"
                              ? classes.titleSelect
                              : classes.title,
                            "portfoliobutton",
                          ].join(" ")}
                          variant="button"
                          noWrap
                          onClick={() => {
                            if (this.props.logindetails.ClientID != 0 || this.props?.logindetails?.EntityTypeID != 9) {
                              this.props?.history.push(labels.Path.Portfolio);
                            }

                          }}
                        >
                          {labels.menu.portfolio}
                        </Typography>
                      )}
                    {window.globalConfig.isBasketAllowed && (this.props.logindetails.ClientID != 0 || this.props.logindetails.EntityTypeID != 9) && !window.globalConfig.enableAnandrathi &&
                      (
                        <span nowrap="true"
                          style={{ paddingRight: 13, paddingBottom: 4 }}
                          className={[
                            window.location.pathname == "/user/basket"
                              ? classes.iconSelect
                              : classes.title,
                          ].join(" ")} onClick={() => {
                            this.props?.history.push(labels.Path.Basket);
                          }}>
                          <Badge>
                            <LocalMall variant="outlined" />
                          </Badge>
                        </span>
                      )}
                    {window.globalConfig.isTradeAllowed && (this.props.logindetails.ClientID != 0 || this.props.logindetails.EntityTypeID != 9) &&
                      this.props?.uccList?.length != 0 && (
                        <span
                          nowrap="true"
                          className={[
                            window.location.pathname == "/user/cart/:selected"
                              ? classes.iconSelect
                              : classes.title,
                          ].join(" ")}
                          onClick={() => {
                            this.props?.history.push(labels.Path.Cart);
                          }}
                        >
                          <Badge
                            className={classes.shoppingcart}
                            badgeContent={
                              this.props.allCart && (
                                <span
                                  style={{
                                    fontSize: "50px",
                                    color: window.globalConfig.color.BROWN,
                                    marginTop: "-28px",
                                    marginLeft: "-9px",
                                  }}
                                >
                                  .
                                </span>
                              )
                            }
                            color="primary"
                          >
                            <ShoppingCartIcon className={classes.icon} />
                          </Badge>
                        </span>
                      )}
                    <span nowrap="true"
                      className={[
                        window.location.pathname == "/alerts"
                          ? classes.iconSelect
                          : classes.title,

                      ].join(" ")}
                      onClick={() => this.props?.history.push(labels.Path.Alert)}>
                      <Badge className={classes.notification} badgeContent={
                        this.props?.isAlertHadData == true && (
                          <span
                            style={{
                              fontSize: "50px",
                              color: window.globalConfig.color.BROWN,
                              marginTop: "-22px",
                              marginLeft: "-16px",
                            }}
                          >
                            .
                          </span>
                        )
                      }
                        color="primary">
                        {/* <NotificationsNoneIcon className={classes.icon} /> */}

                        <NotificationsNoneIcon className={classes.icon} />
                        {/* <img src={Notification} className={classes.image}></img> */}
                      </Badge>
                    </span>
                    <span nowrap="true" className={classes.profileTitle}>
                      <span
                        //className={classes.profileIconStyle}
                        nowrap="true"
                        className={[
                          this.state.anchorEl
                            ? classes.profileIconSelect
                            : classes.profileIconStyle,

                        ].join(" ")}
                        onClick={this.handleClick}
                      >
                        <Badge className={classes.accounticon}>
                          {/* Modified by Celsia */}
                          {/* <img src={AccountCircle} className={classes.image} onClick={handleClick}></img> */}
                          <AccountCircleIcon
                            id="profile-icon"
                            className={classes.accounticon}
                          />
                        </Badge>
                      </span>
                    </span>
                    <Popover
                      //  disableScrollLock={true}
                      // disablePortal={true}
                      open={open}
                      anchorEl={this.state.anchorEl}
                      onClose={this.handleClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <div className={classes.popup}>

                        {this.props?.isfamilyhead == true &&
                          this.state.List?.filter((item) => item.ClientID != this.state.Clientcode)?.length != 0 ?
                          <ListItem
                            id="user-popup"
                            autoFocus={true}
                            className={classes.users}
                            style={{ cursor: 'pointer' }}
                            onClick={() => this.handleclickmainName()}
                          >
                            <Typography
                              id="popup-tab"
                              className={classes.welcome}
                            >
                              {labels.logoutmenu.welcome}
                            </Typography>

                            <Typography
                              id="user-name"
                              className={classes.username}
                            >

                              {this.props?.logindetails?.mainUser}
                            </Typography>


                          </ListItem>
                          :
                          <ListItem

                            autoFocus={true}
                            className={classes.users}
                          // onClick={() => this.handleclickmainName()}
                          >
                            <Typography

                              className={classes.welcome}
                            >
                              {labels.logoutmenu.welcome}
                            </Typography>

                            <Typography

                              className={classes.username}
                            >
                              {this.props?.logindetails?.mainUser}
                            </Typography>

                            {/* 
                      <Typography>
                        yogitha
                      </Typography> */}

                          </ListItem>


                        }
                        {this.props.showFamilyMember && this.props?.isfamilyhead == true &&
                          (this.props.familyLoader
                            ?
                            <div style={{ paddingBottom: 10, borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}` }}>
                              <Typography
                                id="popup-tab"
                                className={classes.welcome}
                                style={{ textIndent: "1.5em" }}
                              >
                                {"Members"}
                              </Typography>
                              <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }}>
                                <Loader size={20}></Loader>
                              </div>
                            </div>
                            :
                            this.state?.List.filter((item) => item.ClientID != this.state.Clientcode && item.FamilyName == this.props.logindetails.FamilyName)?.length != 0 ?
                              <div id="user-option">
                                <Typography
                                  id="popup-tab"
                                  className={classes.welcome}
                                  style={{ textIndent: "1.5em" }}
                                >
                                  {"Members"}
                                </Typography>
                                <div>
                                  <div>
                                    {this.state.List?.length !=
                                      0 ?
                                      (
                                        this.state.List.filter((item) => item.ClientName != "").map((item, index) => {

                                          return (

                                            <ListItem
                                              key={index}
                                              className={[this.props.logindetails.ClientID === item.ClientID ? classes.selectedList : classes.protxt]}
                                              onClick={() => this.handleclickName(item)}
                                            >
                                              <Typography
                                                className={
                                                  this.props.logindetails.ClientID === item.ClientID
                                                    ? classes.List
                                                    : classes.protext
                                                }
                                              >{`${item.ClientName}`} {item.isLoading && <CircularProgress size={20} thickness={1}></CircularProgress>}</Typography>
                                            </ListItem>
                                            // </List>

                                          );
                                        }
                                        )
                                      ) : (
                                        <Typography
                                          className={classes.protext}
                                          style={{
                                            marginLeft: 16,
                                            marginTop: 10,
                                            marginBottom: 10,
                                          }}
                                        >
                                          {"No Members Found"}
                                        </Typography>
                                      )}
                                  </div>
                                </div>
                              </div>
                              :
                              <></>)

                        }
                        <div id="user-option">
                          <Link
                            to={{
                              pathname: labels.Path.Account,
                              // state: { data: 0 },
                            }}
                            className={classes.link}
                            onClick={this.handleClose}
                          >
                            <ListItem
                              id="user-popup"
                              autoFocus={true}
                              style={{ cursor: "pointer" }}
                              className={classes.userstext}
                            >
                              <Typography className={classes.protext}>
                                {labels.logoutmenu.profile}
                              </Typography>
                            </ListItem>
                          </Link>
                        </div>
                        <ListItem
                          onClick={() => {
                            this.logout();
                          }}
                          className={classes.protxt}
                          id="logout"
                        >
                          <Link to="/" className={classes.link}>
                            <Typography className={classes.protext}>
                              {" "}
                              {labels.logoutmenu.logout}
                            </Typography>
                          </Link>
                        </ListItem>
                      </div>
                    </Popover>
                  </Grid>
                </Grid>
              </Toolbar>
              <div></div>
            </div>
          </div>
        </header>
      );
    }
    //
  }
}
const mapStateToProps = (state) => {
  // 
  // a = state.Login.signupmessage
  return {
    sessionID: state?.Login?.sessionID,
    clientID: state?.Login?.logindetails.ClientID,
    date: state?.Login?.date,
    sipCartData: state?.CartReducer?.getAllSipCart == "NA" ? [] : state?.CartReducer?.getAllSipCart,
    onetimeCartData: state?.CartReducer?.getAllOnetimeCart == "NA" ? [] : state?.CartReducer?.getAllOnetimeCart,
    allCart: state?.CartReducer?.allCart == "NA" ? [] : state?.CartReducer?.allCart,
    logindetails: state?.Login?.logindetails,
    isfamilyhead: state?.Login?.isfamilyhead,
    mainClientID: state.Login.mainClientID,
    familydetails: state.Login.familydetails,
    l5Report: state?.L5report?.L5report,
    apiPending: state?.Login?.apiPending,
    permissionList: state?.Login?.permissionList,
    isAlertHadData: state.Alert.isAlertHadData,
    uccList: state.ProfileDetails.AllProfileDetails.length != 0 && state.ProfileDetails.AllProfileDetails?.uccList != undefined && state.ProfileDetails.AllProfileDetails?.uccList?.length != 0 ? state.ProfileDetails.AllProfileDetails.uccList != "NA" ? state.ProfileDetails.AllProfileDetails.uccList.filter(
      (ele, ind) =>
        ind ===
        state.ProfileDetails.AllProfileDetails.uccList.findIndex((elem) => elem.ClientCode === ele.ClientCode)
    ).map((item) => {
      return { ...item, "Name": `${item.DisplayName} ( ${item.ClientCode} )` }
    }) : [] : [],
    companyDetails: state.Login.companyDetails,
    familyLoader: state.Login.familyLoader
  }
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      storeL5Report,

      dropdownvalue,
      storeFormNo,
      getEmptyAction,
      ResetPreventAPICallAction,
      accountselection,
      clientIDchange,
      TradeL5IDDetails,
      // , enableLoaderForAllPages,
      uccSelection

    },
    dispatch
  );
};
export default withRouter(
  withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Menu))
);
