import * as types from "../../constants/actionsType";
import { getInstrumentDetails } from "../../../api/summary/switchdonutapi";
import constants from "../../../utils/constants/apiconstant";
export function getAllInstrumentDetails(
  ClientID,
  AsOnDate,
  SessionID,
  InstrumentType,
  ProductID,
  FamilyID,
  L5ID,
  L4ID,
  flag
) {
  return (dispatch) => {
    getAllInstrumentDetailsAction(
      dispatch,
      ClientID,
      AsOnDate,
      SessionID,
      InstrumentType,
      ProductID,
      FamilyID,
      L5ID,
      L4ID,
      flag
    );
  };
}
function getAllInstrumentDetailsAction(
  dispatch,
  ClientID,
  AsOnDate,
  SessionID,
  InstrumentType,
  ProductID,
  FamilyID,
  L5ID,
  L4ID,
  flag
) {
  //  (SessionID)
  if (ProductID === 1) {
    dispatch({
      type: types.LOADER_SPLIT_BY_CATEGORY_DEBT,
      payload: true,
    });
    dispatch({
      type: types.LOADER_SPLIT_BY_CATEGORY_EQUITY,
      payload: true,
    });
  } else if (ProductID === 2) {
    dispatch({
      type: types.LOADER_SPLIT_BY_MARKET_CAP_DEBT,
      payload: true,
    });
    dispatch({
      type: types.LOADER_SPLIT_BY_MARKET_CAP_EQUITY,
      payload: true,
    });
  } 
  getInstrumentDetails(
    SessionID,
    ClientID,
    AsOnDate,
    InstrumentType,
    ProductID,
    FamilyID,
    L5ID,
    L4ID
  )
    .then((data) => {
      if (data != undefined) {
        if (data.status == "S") {
          if(InstrumentType==1){
            dispatch({
              type: types.PREVENT_API_CALL,
              payload: [{ProductID:1,api:"SplitByCategoryEquity"}],
            });
          }else{
            dispatch({
              type: types.PREVENT_API_CALL,
              payload: [{ProductID:1,api:"SplitByCategoryDebt"}],
            });
          }
          if (ProductID === 1) {
          
            if (InstrumentType === 1) {
              dispatch({
                type: types.EMPTY_DONUT,
                payload: [],
              });
              dispatch({
                type: types.LOADER_SPLIT_BY_CATEGORY_EQUITY,
                payload: false,
              });
              dispatch({
                type: types.GET_DONUT,
                payload: data.data,
              });
            
            } else {
              //
            
              dispatch({
                type: types.LOADER_SPLIT_BY_CATEGORY_DEBT,
                payload: false,
              });
              dispatch({
                type: types.EMPTY_DEBT_DONUT,
                payload: [],
              });
              dispatch({
                type: types.GET_DEBT_DONUT,
                payload: data.data,
              });
            
            }
          } else if (ProductID === 2) {
          
            if (InstrumentType === 1) {
           
              dispatch({
                type: types.LOADER_SPLIT_BY_MARKET_CAP_EQUITY,
                payload: false,
              });
             
              dispatch({
                type: types.EMPTY_EQUITY_INSTRUMENT,
                payload: [],
              });
              dispatch({
                type: types.EQUITY_INSTRUMENT,
                payload: data.data,
              });
            } else {
              //
              
              dispatch({
                type: types.LOADER_SPLIT_BY_MARKET_CAP_DEBT,
                payload: false,
              });
            
              dispatch({
                type: types.EMPTY_DEBT_INSTRUMENT,
                payload: [],
              });
              dispatch({
                type: types.DEBT_INSTRUMENT,
                payload: data.data,
              });
            }
          } 
        } else {
          if (ProductID === 1) {
           
            dispatch({
              type: types.LOADER_SPLIT_BY_CATEGORY_DEBT,
              payload: false,
            });
            dispatch({
              type: types.LOADER_SPLIT_BY_CATEGORY_EQUITY,
              payload: false,
            });
            dispatch({
              type: types.EMPTY_DONUT,
              payload: [],
            });

            dispatch({
              type: types.EMPTY_DEBT_DONUT,
              payload: [],
            });
          } else if (ProductID === 2) {
          
            dispatch({
              type: types.LOADER_SPLIT_BY_MARKET_CAP_DEBT,
              payload: false,
            });
            dispatch({
              type: types.LOADER_SPLIT_BY_MARKET_CAP_EQUITY,
              payload: false,
            });
            dispatch({
              type: types.EMPTY_EQUITY_INSTRUMENT,
              payload: [],
            });

            dispatch({
              type: types.EMPTY_DEBT_INSTRUMENT,
              payload: [],
            });
          } 

        }
      } else {
        if(InstrumentType==1){
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ProductID:1,api:"SplitByCategoryEquity"}],
          });
        }else{
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ProductID:1,api:"SplitByCategoryDebt"}],
          });
        }
        if (ProductID === 1) {
        
          dispatch({
            type: types.LOADER_SPLIT_BY_CATEGORY_DEBT,
            payload: false,
          });
          dispatch({
            type: types.LOADER_SPLIT_BY_CATEGORY_EQUITY,
            payload: false,
          });
          dispatch({
            type: types.EMPTY_DONUT,
            payload: [],
          });

          dispatch({
            type: types.EMPTY_DEBT_DONUT,
            payload: [],
          });
        } else if (ProductID === 2) {
        
          dispatch({
            type: types.LOADER_SPLIT_BY_MARKET_CAP_DEBT,
            payload: false,
          });
          dispatch({
            type: types.LOADER_SPLIT_BY_MARKET_CAP_EQUITY,
            payload: false,
          });
          dispatch({
            type: types.EMPTY_EQUITY_INSTRUMENT,
            payload: [],
          });

          dispatch({
            type: types.EMPTY_DEBT_INSTRUMENT,
            payload: [],
          });
        } 
      }
    })
    .catch(() => {
      //  ("erroraaaaa", error)
      if(InstrumentType==1){
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ProductID:1,api:"SplitByCategoryEquity"}],
        });
      }else{
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ProductID:1,api:"SplitByCategoryDebt"}],
        });
      }
    
      if (ProductID === 1) {
      
        dispatch({
          type: types.LOADER_SPLIT_BY_CATEGORY_DEBT,
          payload: false,
        });
        dispatch({
          type: types.LOADER_SPLIT_BY_CATEGORY_EQUITY,
          payload: false,
        });
        dispatch({
          type: types.EMPTY_DONUT,
          payload: [],
        });

        dispatch({
          type: types.EMPTY_DEBT_DONUT,
          payload: [],
        });
      } else if (ProductID === 2) {
     
        dispatch({
          type: types.LOADER_SPLIT_BY_MARKET_CAP_DEBT,
          payload: false,
        });
        dispatch({
          type: types.LOADER_SPLIT_BY_MARKET_CAP_EQUITY,
          payload: false,
        });
        dispatch({
          type: types.EMPTY_EQUITY_INSTRUMENT,
          payload: [],
        });

        dispatch({
          type: types.EMPTY_DEBT_INSTRUMENT,
          payload: [],
        });
      } 
    });
}
