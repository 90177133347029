import React from 'react';
import color from "../../utils/colors/colors";
const App =(props)=>{
    const style={
        header:{
           fontSize:14,
           padding:"0px 10px 8px 0px",
           color:window.globalConfig.color.BLACK_PRIMARY,
           fontWeight:500
        },
    }
    return(
        <React.Fragment>
            <div style={style.header}>{props.text}</div> 
        </React.Fragment>
    )
}
export default App;
