import React, { useState } from 'react';
import { DateRangePicker, DateRange, com } from 'react-date-range';
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import '../../utils/stylesheets/common/global/app.css'
import moment from 'moment'
import color from "../../utils/colors/colors";
const Datepicker = ({ onDateChange, monthcount, divStyle, startDate, endDate, maxDate, minDate }) => {
  const [state, setState] = useState([
    {
      startDate: startDate === undefined ? new Date() : new Date(startDate),
      endDate: addDays(endDate == undefined ? new Date() : new Date(endDate), 0),
      key: 'selection'
    }
  ]);
  const [singlestate, singlesetState] = useState([
    {
      startDate: new Date(),
      key: 'selection'
    }
  ]);
  const onChange = (item) => {
    if (monthcount == 2) {
      setState([item.selection])
    }
    else {
      singlesetState([item.selection])
    }
    onDateChange(item.selection)
  }
  const selectionRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection',
  }
  return (
    <div
      style={divStyle}
    >
      <DateRange
        onChange={item => onChange(item)}
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={monthcount}
        rangedCalendars={false}
        ranges={monthcount == 2 ? state : singlestate}
        rangeColors={[window.globalConfig.color.RosyOrange]}

        color={window.globalConfig.color.BROWN}
        direction="horizontal"
        showDateDisplay={false}
        definedRangesWrapper={false}
        
        maxDate={new Date(maxDate)}
        showMonthAndYearPickers={true}
      />
    </div>
  )
}
export default Datepicker;
