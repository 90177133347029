import React, { Component } from 'react';
import labels from '../../utils/constants/labels';
import CalenderImage from '../../utils/assets/images/img17.jpg';
import '../../utils/stylesheets/common/global/filters/categoryfilter.css';
import DateRangePicker from '../daterangepicker/rangepicker';
import Popover from '@material-ui/core/Popover';
import SchemeDropdown from '../../components/dropdown/dropdownschemes'
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import colors from '../../utils/colors/colors';
import Button from '../custombutton/button';
const styles = theme => ({
    mobileResponseDate: {
        [theme.breakpoints.down(600)]: {
            width: "100%",
        }
    },
    btnAlignment: {
        display: "grid",
        gridTemplateColumns: "39% 28% 27%",
        gap: 10
    },
    calendercontainer: {
        maxHeight: '570px',
        [theme.breakpoints.down(600)]: {
            maxHeight: '440px',
        }
    }
})
class TransactionFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            schemeList: [],
            transactionTypeList: [],
            assetClassList: [],
        }
    }
    componentDidMount() {
        let data = this.props.scheme.length == 0 ? [] : this.props.scheme.filter((ele, ind) => ind === this.props.scheme.findIndex(elem => elem.label === ele.label)).map((item, index) => {
            // console.log(item.Scheme)
            return {
                ...item,
                label: item.label

                , value: item.value || index, isChecked: false
            }
        })
        let transactionType = this.props.Transaction.length == 0 ? [] : this.props.Transaction.filter((ele, ind) => ind === this.props.Transaction.findIndex(elem => elem.label === ele.label)).map((item, index) => {
            // console.log(item.Scheme)
            return {
                ...item,
                label: item.label

                , value: item.value || index, isChecked: false
            }
        })
        let assetClass = this.props.Asset.length == 0 ? [] : this.props.Asset.filter((ele, ind) => ind === this.props.Asset.findIndex(elem => elem.label === ele.label)).map((item, index) => {
            // console.log(item.Scheme)
            return {
                ...item,
                label: item.label

                , value: item.value || index, isChecked: false
            }
        })
        if (this.props.selectedScheme.length != 0) {
            let ID = this.props.selectedScheme.map((item) => {
                return item.value
            })
            data = data.map((i) => {
                return { ...i, isChecked: ID.includes(i.value) }
            })
        }
        if (this.props.selectedAsset.length != 0) {
            let ID = this.props.selectedAsset.map((item) => {
                return item.value
            })
            assetClass = assetClass.map((i) => {
                return { ...i, isChecked: ID.includes(i.value) }
            })
        }
        if (this.props.selectedTransaction.length != 0) {
            let ID = this.props.selectedTransaction.map((item) => {
                return item.value
            })
            transactionType = transactionType.map((i) => {
                return { ...i, isChecked: ID.includes(i.value) }
            })
        }
        // console.log(this.props.selectvalue)
        this.setState({ schemeList: data, transactionTypeList: transactionType, assetClassList: assetClass })
    }

    componentDidUpdate(prevValue) {
        const { selectedAsset, selectedScheme, selectedTransaction } = this.props;
        if (prevValue.selectedAsset.length != selectedAsset.length) {
            let ID = selectedAsset.map((item) => {
                return item.value
            })
            this.setState({
                assetClassList: this.state.assetClassList.map((i) => {
                    return { ...i, isChecked: ID.includes(i.value) }
                })
            })
        }
        if (prevValue.selectedScheme.length != selectedScheme.length) {
            let ID = selectedScheme.map((item) => {
                return item.value
            })
            this.setState({
                schemeList: this.state.schemeList.map((i) => {
                    return { ...i, isChecked: ID.includes(i.value) }
                })
            })
        }
        if (prevValue.selectedTransaction.length != selectedTransaction.length) {
            let ID = selectedTransaction.map((item) => {
                return item.value
            })
            this.setState({
                transactionTypeList: this.state.transactionTypeList.map((i) => {
                    return { ...i, isChecked: ID.includes(i.value) }
                })
            })
        }
    }
    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handleClose = () => {
        this.setState({ anchorEl: null });
    };


    render() {
        const { classes } = this.props
        //  
        return (
            <div >
                <SchemeDropdown
                    type="Searchable"
                    handleOnChange={this.props.handleschemeschange}
                    // callBack={this.props.callBack}
                    id={1}
                    List={this.state.schemeList}
                    // label={this.props.flag === "equity" ? "Scrips" : "Schemes"}
                    label={`${this.props.filterHeader}s`}
                    placeholder={ `Select ${this.props.filterHeader}`}
                    isSearchable={true}
                    showborder={true}
                    filter={"close"}
                    searchPlaceholder={`Search ${this.props.filterHeader}`} />
                <SchemeDropdown
                    type="Searchable"
                    id={2}
                    handleOnChange={this.props.handletransactiontype}
                    List={this.state.transactionTypeList}
                    //callBack={this.props.callBack}
                    label={labels.transactionFilter.transactionType}
                    placeholder={labels.transactionFilter.all}
                    isSearchable={true}
                    showborder={true}
                    filter={"close"}
                    searchPlaceholder={labels.transactionFilter.search} />
                <SchemeDropdown
                    type="Searchable"
                    id={3}
                    // callBack={this.props.callBack}
                    handleOnChange={this.props.handleAssetclassChange}
                    List={this.state.assetClassList}
                    label={labels.transactionFilter.assetClass}
                    placeholder={labels.transactionFilter.all}
                    isSearchable={true}
                    showborder={true}
                    filter={"close"}
                    searchPlaceholder={labels.transactionFilter.search} />

                <div style={{ textAlign: 'left', color: window.globalConfig.color.LABLE_GRAY, fontSize: 14, fontWeight: "normal", paddingBottom: 8 }}>{labels.transactionFilter.dateRange}</div>
                <div className={[classes.mobileResponseDate, "datePicker dropdownBorder"].join(" ")} onClick={this.handleClick}  >
                    {this.props.startDate == "" || this.props.endDate == "" ?
                        <span style={{ paddingLeft: "1em" }}>{this.props.mindate + ' - ' + this.props.maxdate}</span>
                        :
                        <span style={{ paddingLeft: "1em" }}>{this.props.startDate + ' - ' + this.props.endDate}</span>
                    }
                    <img src={CalenderImage} style={{ float: "right", paddingRight: "1em" }} />
                </div>
                <div>
                    <Popover
                        style={{ height: '35em' }}
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                    >
                        <div style={{ width: '100%', zIndex: 10, overflow: 'scroll' }} className={classes.calendercontainer}>
                            {/* <div> */}
                            <DateRangePicker monthcount={2}
                                minDate={this.props.mindate}
                                startDate={this.props.startDate}
                                endDate={this.props.endDate}
                                maxDate={this.props.maxdate}
                                onDateChange={this.props.onChange}
                            />
                            {/* </div> */}
                            <hr />
                            {/* <div> */}
                            <div className={classes.btnAlignment}
                                style={{ padding: "8px 15px", }} >
                                <span className="drp-selected">{this.props.startDate}  {this.props.endDate}</span>
                                <Button onClick={this.handleClose} name={labels.button.cancel}></Button>
                                <Button page="primary" onClick={this.handleClose} name={labels.button.apply}></Button>
                            </div>
                            {/* </div> */}
                        </div>
                    </Popover>
                </div>

            </div>
        )
    }
}
const mapStateToProps = state => ({
})
export default withStyles(styles)(connect(mapStateToProps)(TransactionFilter))
