import moment from 'moment';
import secureStorage from '../../utils/securestorage';
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
import ApiValues from '../url/apiurl';
function addSubscriptionMandate(Amount, MandateType, additionalBankDetails) {
    let userDetails = secureStorage.getItem('user')
    let data = {};
    data.amount = parseFloat(Amount),
        data.mandateType = MandateType,
        data.accountNo = additionalBankDetails.BankAccountNumber,
        data.accountTypeCode = additionalBankDetails.AccountType,
        data.iFSC_Code = additionalBankDetails.IFSCCode,
        data.mICR_Code = additionalBankDetails.BranchMICRCode,
        data.startDate = moment().utcOffset('+05:30').format('YYYY-MM-DD'),//additionalBankDetails.StartDate,
        data.endDate = "2099-12-31",
        data.bankID = additionalBankDetails.ID,
        data.clientID = userDetails.ClientID
    return Networking.PostApi(ApiValues.SubscriptionAddMandate, data).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });

}
export default addSubscriptionMandate
