import * as types from "../../constants/actionsType";
import getXrayList from "../../../api/portfolioxrayreport/xrayapi";
import secureStorage from "../../../utils/securestorage";
import constants from "../../../utils/constants/apiconstant";
import { replaceNull } from "../../../utils/commonfunction";
import getFolioWiseXrayList from "../../../api/portfolioxrayreport/foliowisereport";
export function getPortfolioxrayDetails(
  Account,
  ClientID,
  InceptionDate,
  FromXIRR,
  ToXIRR,
  Type,
  AssetClass,
  SessionID,
  AccountLevel,
  scheme,
  ProductID,
  FamilyID,
  L5ID,
  L4ID,
  flag
) {
  return (dispatch) => {
    getAllportfolioxrayDetailsAction(
      dispatch,
      Account,
      ClientID,
      InceptionDate,
      FromXIRR,
      ToXIRR,
      Type,
      AssetClass,
      SessionID,
      AccountLevel,
      scheme,
      ProductID,
      FamilyID,
      L5ID,
      L4ID,
      flag
    );
    // dispatch({
    //   type: types.GET_PROFITLOSS_DATA,
    //   payload: Summary,
    // })
  };
}
export const filterChange = (data) => {
  return {
    type: types.GET_XRAYDATA,
    payload: data,
  };
};
export const equityFilterChange = (data) => {
  return {
    type: types.GET_EQUITY_XRAYDATA,
    payload: data,
  };
};
export const bondsFilterChange = (data) => {
  return {
    type: types.BONDS_FILTER_XRAY_DATA,
    payload: data,
  };
};
export const FDFilterChange = (data) => {
  return {
    type: types.FD_FILTER_XRAY_DATA,
    payload: data,
  };
};
export const GBFilterChange = (data) => {
  return {
    type: types.GB_FILTER_XRAY_DATA,
    payload: data,
  };
};
export const AIFFilterChange = (data) => {
  return {
    type: types.AIF_FILTER_XRAY_DATA,
    payload: data,
  };
};
export const MLDFilterChange = (data) => {
  return {
    type: types.MLD_FILTER_XRAY_DATA,
    payload: data,
  };
};
export const RealEstateFilterChange = (data) => {
  return {
    type: types.REALESTATE_FILTER_XRAY_DATA,
    payload: data,
  };
};
export const NPSFilterChange = (data) => {
  return {
    type: types.NPS_FILTER_XRAY_DATA,
    payload: data,
  };
};
export const InsuranceFilterChange = (data) => {
  return {
    type: types.INSURANCE_FILTER_XRAY_DATA,
    payload: data,
  };
};
function getAllportfolioxrayDetailsAction(
  dispatch,
  Account,
  ClientID,
  InceptionDate,
  FromXIRR,
  ToXIRR,
  Type,
  AssetClass,
  SessionID,
  AccountLevel,
  scheme,
  ProductID,
  FamilyID,
  L5ID,
  L4ID,
  flag
) {
  if (ProductID === 1) {
    dispatch({
      type: types.LOADER_PORTFOLIOXRAY,
      payload: true,
    });
    dispatch({
      type: types.EMPTY_PORTFOLIOXRAY,
      payload: [],
    });
  } else if (ProductID === 2) {
    dispatch({
      type: types.LOADER_EQUITY_PORTFOLIOXRAY,
      payload: true,
    });
    dispatch({
      type: types.EMPTY_EQUITY_PORTFOLIOXRAY,
      payload: [],
    });
  } else if (ProductID === 3) {
    dispatch({
      type: types.LOADER_BONDS_PORTFOLIO_X_RAY,
      payload: true,
    });
    dispatch({
      type: types.EMPTY_BONDS_PORTFOLIO_X_RAY,
      payload: [],
    });
  } else if (ProductID === 4) {
    dispatch({
      type: types.LOADER_GB_PORTFOLIO_X_RAY,
      payload: true,
    });
    dispatch({
      type: types.EMPTY_GB_PORTFOLIO_X_RAY,
      payload: [],
    });
  } else if (ProductID === 5) {
    dispatch({
      type: types.LOADER_FD_PORTFOLIO_X_RAY,
      payload: true,
    });
    dispatch({
      type: types.EMPTY_FD_PORTFOLIO_X_RAY,
      payload: [],
    });
  } else if (ProductID === 6) {

    dispatch({
      type: types.LOADER_AIF_PORTFOLIO_X_RAY,
      payload: true,
    });
    dispatch({
      type: types.EMPTY_AIF_PORTFOLIO_X_RAY,
      payload: [],
    });

  }
  else if (ProductID === 10) {

    dispatch({
      type: types.LOADER_MLD_PORTFOLIO_X_RAY,
      payload: true,
    });
    dispatch({
      type: types.EMPTY_MLD_PORTFOLIO_X_RAY,
      payload: [],
    });

  }
  if (ProductID == 1) {
    dispatch({
      type: types.LOADER_FOLIO_WISE_PORTFOLIO_XRAY,
      payload: false,
    });
    getFolioWiseXrayList(Account,
      ClientID,
      InceptionDate,
      FromXIRR,
      ToXIRR,
      Type,
      AssetClass,
      SessionID,
      AccountLevel,
      scheme,
      ProductID,
      FamilyID,
      L5ID,
      L4ID).then((res) => {
        dispatch({
          type: types.LOADER_FOLIO_WISE_PORTFOLIO_XRAY,
          payload: false,
        });
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: ProductID, api: "FoliowisePortfolioXray" }],
        });
        if (res.status == "S") {
          dispatch({
            type: types.FOLIO_WISE_PORTFOLIO_XRAY,
            payload: replaceNull(res.data, "NA"),
          });
        } else {
          dispatch({
            type: types.FOLIO_WISE_PORTFOLIO_XRAY,
            payload: [],
          });
        }
      })
  }
  getXrayList(
    Account,
    ClientID,
    InceptionDate,
    FromXIRR,
    ToXIRR,
    Type,
    AssetClass,
    SessionID,
    AccountLevel,
    scheme,
    ProductID,
    FamilyID,
    L5ID,
    L4ID
  )
    .then((data) => {
      if (data != undefined) {
        //    (data)

        if (data.status == "S") {
          //
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: ProductID, api: "PortfolioXray" }],
          });
          if (ProductID === 1) {
            dispatch({
              type: types.LOADER_PORTFOLIOXRAY,
              payload: false,
            });

            dispatch({
              type: types.XRAYDATA,
              payload: data.data != null && data.data.length != 0 ? replaceNull(data.data, "NA").sort((a, b) => {
                var textA = a.Scheme.toUpperCase();
                var textB = b.Scheme.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }) : [],
            });

          } else if (ProductID === 2) {
            dispatch({
              type: types.LOADER_EQUITY_PORTFOLIOXRAY,
              payload: false,
            });
            dispatch({
              type: types.EQUITY_PORTFOLIOXRAY,
              payload: data.data != null && data.data.length != 0 ? replaceNull(data.data, "NA").sort((a, b) => {
                var textA = a.ScripName.toUpperCase();
                var textB = b.ScripName.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }) : [],
            });

          }
          else if (ProductID === 3) {
            dispatch({
              type: types.LOADER_BONDS_PORTFOLIO_X_RAY,
              payload: false,
            });
            dispatch({
              type: types.BONDS_PORTFOLIO_X_RAY,
              payload: data.data != null && data.data.length != 0 ? replaceNull(data.data, "NA").sort((a, b) => {
                var textA = a.ScripName.toUpperCase();
                var textB = b.ScripName.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }) : [],
            });

          }
          else if (ProductID === 4) {
            dispatch({
              type: types.LOADER_GB_PORTFOLIO_X_RAY,
              payload: false,
            });
            dispatch({
              type: types.GB_PORTFOLIO_X_RAY,
              payload: data.data != null && data.data.length != 0 ? replaceNull(data.data, "NA").sort((a, b) => {
                var textA = a.ScripName.toUpperCase();
                var textB = b.ScripName.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }) : [],
            });

          }
          else if (ProductID === 5) {
            dispatch({
              type: types.LOADER_FD_PORTFOLIO_X_RAY,
              payload: false,
            });
            dispatch({
              type: types.FD_PORTFOLIO_X_RAY,
              payload: data.data != null && data.data.length != 0 ? replaceNull(data.data, "NA").sort((a, b) => {
                var textA = a.ScripName.toUpperCase();
                var textB = b.ScripName.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }) : [],
            });

          }
          else if (ProductID === 6) {
            dispatch({
              type: types.LOADER_AIF_PORTFOLIO_X_RAY,
              payload: false,
            });
            dispatch({
              type: types.AIF_PORTFOLIO_X_RAY
              ,
              payload: data.data != null && data.data.length != 0 ? replaceNull(data.data, "NA").sort((a, b) => {
                var textA = a.ScripName.toUpperCase();
                var textB = b.ScripName.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }) : [],
            });
          }
          else if (ProductID === 7) {
            dispatch({
              type: types.LOADER_REALESTATE_PORTFOLIO_X_RAY,
              payload: false,
            });
            dispatch({
              type: types.REALESTATE_PORTFOLIO_X_RAY,
              payload: data.data != null && data.data.length != 0 ? replaceNull(data.data, "NA").sort((a, b) => {
                var textA = a.ScripName.toUpperCase();
                var textB = b.ScripName.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }) : [],
            });
          }

          else if (ProductID === 10) {
            dispatch({
              type: types.LOADER_MLD_PORTFOLIO_X_RAY,
              payload: false,
            });
            dispatch({
              type: types.MLD_PORTFOLIO_X_RAY,
              payload: data.data != null && data.data.length != 0 ? data.data.sort((a, b) => {
                var textA = a.ScripName.toUpperCase();
                var textB = b.ScripName.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }) : [],
            });
          }
        } else {
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: ProductID, api: "PortfolioXray" }],
          });
          if (ProductID === 1) {

            dispatch({
              type: types.LOADER_PORTFOLIOXRAY,
              payload: false,
            });
            dispatch({
              type: types.XRAYDATA,
              payload: [],
            });
          } else if (ProductID === 2) {
            dispatch({
              type: types.LOADER_EQUITY_PORTFOLIOXRAY,
              payload: false,
            });
            dispatch({
              type: types.EQUITY_PORTFOLIOXRAY,
              payload: [],
            });

          }
          else if (ProductID === 3) {
            dispatch({
              type: types.LOADER_BONDS_PORTFOLIO_X_RAY,
              payload: false,
            });
            dispatch({
              type: types.BONDS_PORTFOLIO_X_RAY,
              payload: [],
            });

          }
          else if (ProductID === 4) {
            dispatch({
              type: types.LOADER_GB_PORTFOLIO_X_RAY,
              payload: false,
            });
            dispatch({
              type: types.GB_PORTFOLIO_X_RAY,
              payload: [],
            });

          }
          else if (ProductID === 5) {
            dispatch({
              type: types.LOADER_FD_PORTFOLIO_X_RAY,
              payload: false,
            });
            dispatch({
              type: types.FD_PORTFOLIO_X_RAY,
              payload: [],
            });

          } else if (ProductID === 6) {
            dispatch({
              type: types.LOADER_AIF_PORTFOLIO_X_RAY,
              payload: false,
            });
            dispatch({
              type: types.AIF_PORTFOLIO_X_RAY,
              payload: [],
            });
          }
          else if (ProductID === 7) {
            dispatch({
              type: types.LOADER_REALESTATE_PORTFOLIO_X_RAY,
              payload: false,
            });
            dispatch({
              type: types.REALESTATE_PORTFOLIO_X_RAY,
              payload: [],
            });
          } else if (ProductID === 10) {
            dispatch({
              type: types.LOADER_MLD_PORTFOLIO_X_RAY,
              payload: false,
            });
            dispatch({
              type: types.MLD_PORTFOLIO_X_RAY,
              payload: [],
            });
          }
        }
      } else {
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: ProductID, api: "PortfolioXray" }],
        });
        if (ProductID === 1) {

          dispatch({
            type: types.LOADER_PORTFOLIOXRAY,
            payload: false,
          });
          dispatch({
            type: types.XRAYDATA,
            payload: [],
          });
        } else if (ProductID === 2) {
          dispatch({
            type: types.LOADER_EQUITY_PORTFOLIOXRAY,
            payload: false,
          });
          dispatch({
            type: types.EQUITY_PORTFOLIOXRAY,
            payload: [],
          });

        }
        else if (ProductID === 3) {
          dispatch({
            type: types.LOADER_BONDS_PORTFOLIO_X_RAY,
            payload: false,
          });
          dispatch({
            type: types.BONDS_PORTFOLIO_X_RAY,
            payload: [],
          });

        }
        else if (ProductID === 4) {
          dispatch({
            type: types.LOADER_GB_PORTFOLIO_X_RAY,
            payload: false,
          });
          dispatch({
            type: types.GB_PORTFOLIO_X_RAY,
            payload: [],
          });

        }
        else if (ProductID === 5) {
          dispatch({
            type: types.LOADER_FD_PORTFOLIO_X_RAY,
            payload: false,
          });
          dispatch({
            type: types.FD_PORTFOLIO_X_RAY,
            payload: [],
          });

        } else if (ProductID === 6) {
          dispatch({
            type: types.LOADER_AIF_PORTFOLIO_X_RAY,
            payload: false,
          });
          dispatch({
            type: types.AIF_PORTFOLIO_X_RAY,
            payload: [],
          });
        }
        else if (ProductID === 7) {
          dispatch({
            type: types.LOADER_REALESTATE_PORTFOLIO_X_RAY,
            payload: false,
          });
          dispatch({
            type: types.REALESTATE_PORTFOLIO_X_RAY,
            payload: [],
          });
        } else if (ProductID === 10) {
          dispatch({
            type: types.LOADER_MLD_PORTFOLIO_X_RAY,
            payload: false,
          });
          dispatch({
            type: types.MLD_PORTFOLIO_X_RAY,
            payload: [],
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: ProductID, api: "PortfolioXray" }],
      });
      if (ProductID === 1) {

        dispatch({
          type: types.LOADER_PORTFOLIOXRAY,
          payload: false,
        });
        dispatch({
          type: types.XRAYDATA,
          payload: [],
        });
      } else if (ProductID === 2) {
        dispatch({
          type: types.LOADER_EQUITY_PORTFOLIOXRAY,
          payload: false,
        });
        dispatch({
          type: types.EQUITY_PORTFOLIOXRAY,
          payload: [],
        });

      }
      else if (ProductID === 3) {
        dispatch({
          type: types.LOADER_BONDS_PORTFOLIO_X_RAY,
          payload: false,
        });
        dispatch({
          type: types.BONDS_PORTFOLIO_X_RAY,
          payload: [],
        });

      }
      else if (ProductID === 4) {
        dispatch({
          type: types.LOADER_GB_PORTFOLIO_X_RAY,
          payload: false,
        });
        dispatch({
          type: types.GB_PORTFOLIO_X_RAY,
          payload: [],
        });

      }
      else if (ProductID === 5) {
        dispatch({
          type: types.LOADER_FD_PORTFOLIO_X_RAY,
          payload: false,
        });
        dispatch({
          type: types.FD_PORTFOLIO_X_RAY,
          payload: [],
        });

      } else if (ProductID == 6) {
        dispatch({
          type: types.LOADER_AIF_PORTFOLIO_X_RAY,
          payload: false,
        });
        dispatch({
          type: types.AIF_PORTFOLIO_X_RAY,
          payload: []
        });
      }
      else if (ProductID == 7) {
        dispatch({
          type: types.LOADER_REALESTATE_PORTFOLIO_X_RAY,
          payload: false,
        });
        dispatch({
          type: types.REALESTATE_PORTFOLIO_X_RAY,
          payload: []
        });
      } else if (ProductID === 10) {
        dispatch({
          type: types.LOADER_MLD_PORTFOLIO_X_RAY,
          payload: false,
        });
        dispatch({
          type: types.MLD_PORTFOLIO_X_RAY,
          payload: [],
        });
      }
    });
}
export const portfolioXraySortByAtoZ = (flag, flagsort, data) => {
  return {
    type: types.SORT_BY_A_Z,
    payload: { flag, flagsort, data },
  };
};
export const portfolioXraySortByMarketValue = (flag, flagsort, data) => {
  return {
    type: types.SORT_BY_MARKET_VALUE,
    payload: { flag, flagsort, data },
  };
};
export const portfolioXraySortByReturns = (flag, flagsort, data) => {
  return {
    type: types.SORT_BY_RETURNS,
    payload: { flag, flagsort, data },
  };
};
export const GetPortfolioXray = (data) => {
  return {
    type: types.GET_PORTFOLIO_XRAY,
    data,
  };
};
