import * as types from '../../constants/actionsType';
import getTransactionDetails from '../../../api/transactiondetails/transactiondetailsapi';
export function getTransactionDetailsListing(ProductID) {
  return (dispatch) => {
    getTransactionDetailsListAction(dispatch, ProductID);

  }
}
function getTransactionDetailsListAction(dispatch, ProductID) {
  dispatch({
    type: types.EMPTY_TRANSACTION_DETAILS,
    payload: [],
  });
  dispatch({
    type: types.LOADER_TRANSACTION_DETAILS,
    payload:true,
  });
  //
  getTransactionDetails(ProductID).then((data) => {
   // 
    if (data != undefined) {
        dispatch({
          type: types.TRANSACTION_DETAILS,
          payload: data,
        });
        dispatch({
          type: types.LOADER_TRANSACTION_DETAILS,
          payload: false,
        });
      
    } else {
      dispatch({
        type: types.LOADER_TRANSACTION_DETAILS,
        payload:false,
      });
    }
  })
    .catch((error) => {
      //  ("erroraaaaa", error)
      dispatch({
        type: types.LOADER_TRANSACTION_DETAILS,
        payload:false,
      });
        dispatch({
          type: types.EMPTY_TRANSACTION_DETAILS,
          payload: [],
        });
    })

}
export const schemedata = (data) => {
  return {
    type: types.SCHEME_DATA,
    data
  }
};
