import * as types from '../../constants/actionsType'
import { replaceNull } from '../../../utils/commonfunction';
import labels from '../../../utils/constants/labels';
const initialState = {
    maturityTracker: [],
    maturityTrackerBackup: [],
    isMaturityTrackerLoading: false,
    maturityTrackerBonds: [],
    maturityTrackerBondsBackup: [],
    isMaturityTrackerBondsLoading: false,
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.MATURITY_TRACKER:
            return {
                ...state,
                maturityTrackerBackup: replaceNull(action.payload.data, labels.messages.NA),
                maturityTracker: action.payload.data != null ? replaceNull(action.payload.data, labels.messages.NA) : state.maturityTracker,
                isMaturityTrackerLoading: false
            }

        case types.EMPTY_MATURITY_TRACKER:
            return {
                maturityTracker: [],
                isMaturityTrackerLoading: false,
            }
        case types.LOADER_MATURITY_TRACKER:
            return {
                ...state,
                isMaturityTrackerLoading: action.payload
            }
        case types.MATURITY_TRACKER_BONDS:
            return {
                ...state,
                maturityTrackerBondsBackup: replaceNull(action.payload.data, labels.messages.NA),
                maturityTrackerBonds: replaceNull(action.payload.data, labels.messages.NA),
                isMaturityTrackerBondsLoading: false
            }

        case types.EMPTY_MATURITY_TRACKER_BONDS:
            return {
                maturityTrackerBonds: [],
                isMaturityTrackerBondsLoading: false,
            }
        case types.LOADER_MATURITY_TRACKER_BONDS:
            return {
                ...state,
                isMaturityTrackerBondsLoading: action.payload
            }
        case types.FILTER_MATURITY_TRACKER:
            var filterdata = state.maturityTrackerBackup;
            var filteredList = filterdata;
            var issuerName;
            var status;
            // console.log(action.payload)
            // MultiSelect Scheme Filter
            if (
                action.payload.issuerName != undefined &&
                action.payload.issuerName != null &&
                action.payload.issuerName.length != 0
            ) {
                issuerName = action.payload.issuerName.map((i) => {
                    return i.label; // Taking only Schemes name from the labels as array
                });
                if (issuerName != undefined) {
                    filteredList = filteredList.filter((data) => {
                        return issuerName.includes(data.IssuerName);
                    });
                }
            }

            // MultiSelect Transaction Filter
            if (
                action.payload.status != undefined &&
                action.payload.status != null &&
                action.payload.status != 0
            ) {
                status = action.payload.status.map((i) => {
                    return i.label; // Taking only Transaction name from the labels as array
                });
                if (status != undefined) {
                    filteredList = filteredList.filter((data) => {
                        return status.includes(data.Status);
                    });
                }
            }


            if (
                action.payload.startDate != 0 &&
                action.payload.startDate != "" && action.payload.startDate != "NA" &&
                action.payload.startDate != null
            ) {
                filteredList = filteredList.filter((data) => {
                    return data.TransactionDate == action.payload.startDate
                });
            }

            if (
                action.payload.maturityDate != 0 &&
                action.payload.maturityDate != "" && action.payload.maturityDate != "NA" &&
                action.payload.maturityDate != null
            ) {
                filteredList = filteredList.filter((data) => {
                    return data.MaturityDate == action.payload.maturityDate
                });
            }
            state.maturityTracker = filteredList;
            return {
                ...state,
            }
        case types.FILTER_MATURITY_TRACKER_BONDS:
            var filterdata = state.maturityTrackerBondsBackup;
            var filteredList = filterdata;
            var issuerName;
            var status;
            // console.log(action.payload)
            // MultiSelect Scheme Filter
            if (
                action.payload.issuerName != undefined &&
                action.payload.issuerName != null &&
                action.payload.issuerName.length != 0
            ) {
                issuerName = action.payload.issuerName.map((i) => {
                    return i.label; // Taking only Schemes name from the labels as array
                });
                if (issuerName != undefined) {
                    filteredList = filteredList.filter((data) => {
                        return issuerName.includes(data.IssuerName);
                    });
                }
            }

            // MultiSelect Transaction Filter
            if (
                action.payload.status != undefined &&
                action.payload.status != null &&
                action.payload.status != 0
            ) {
                status = action.payload.status.map((i) => {
                    return i.label; // Taking only Transaction name from the labels as array
                });
                if (status != undefined) {
                    filteredList = filteredList.filter((data) => {
                        return status.includes(data.Status);
                    });
                }
            }


            if (
                action.payload.startDate != 0 &&
                action.payload.startDate != "" && action.payload.startDate != "NA" &&
                action.payload.startDate != null
            ) {
                filteredList = filteredList.filter((data) => {
                    return data.TransactionDate == action.payload.startDate
                });
            }

            if (
                action.payload.maturityDate != 0 &&
                action.payload.maturityDate != "" && action.payload.maturityDate != "NA" &&
                action.payload.maturityDate != null
            ) {
                filteredList = filteredList.filter((data) => {
                    return data.MaturityDate == action.payload.maturityDate
                });
            }
            state.maturityTrackerBonds = filteredList;
            return {
                ...state,
            }
        default:
            return state
    }
}
export default reducer
