import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField';
import '../../utils/stylesheets/sample/sample.css';
import Labels from '../../utils/constants/labels';
import Dropdown from '../dropdown/dropdownschemes';
import Button from '../custombutton/button';
// import Cookies from 'universal-cookie';
import moment from 'moment';
import DatePicker from '../../utils/assets/images/img17.jpg';
import Label from '../../utils/constants/labels'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { generateMandate, getMandateDetails } from '../../redux/actions/dropdown/dropdownaction'
import Loader from '../loader/loader'
import secureStorage from '../../utils/securestorage'
import color from "../../utils/colors/colors";
import labels from '../../utils/constants/labels';
import { NegativeFinding } from '../../utils/commonfunction';
import addSubscriptionMandate from '../../api/subscription/addmandate';
import Snackbar from '../snackbar/snackbar';
import colors from '../../utils/colors/colors';
const useStyles = makeStyles((theme) => ({
    sipContainer: {
        //  height: 410,
        width: 350
    },

    onetimeContainer: {
        //  height: 490,
        width: 465,
        [theme.breakpoints.between('300', '350')]: {
            width: 250,
        },
        [theme.breakpoints.between('350', '400')]: {
            width: 300,
        },
        // [theme.breakpoints.between('400', '450')]: {
        //     width: 350,
        // },
        [theme.breakpoints.between('400', '600')]: {
            width: 350,
        },
    },

    header: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: window.globalConfig.color.WHITE,
        height: 50,
        fontWeight: 500,
        position: "sticky",
        top: "0px",
        zIndex: "1"
    },

    clearIcon: {
        float: 'right',
        cursor: 'pointer'
    },

    txtHeader: {
        padding: 15,

    },

    txtBody: {
        padding: "10px 10px 0px 10px",
        fontSize: 13,
        color: window.globalConfig.color.LABLE_GRAY
    },
    textAligns: {
        width: 250,
        textAlign: "center",

    },

    body: {
        margin: 20,
        textAlign: 'center',
        [theme.breakpoints.down('lg')]: {
            margin: "0px"
        }

    },
    btnContinue: {
        color: "white",
        width: 250,
        height: 40,
        backgroundColor: window.globalConfig.color.BROWN,
        borderWidth: 0,
        borderRadius: 5,
        '&hover': {
            color: window.globalConfig.color.WHITE,
            background: window.globalConfig.color.PRIMARY_LIGHT
        },
        [theme.breakpoints.down('xs')]: {
            width: 200,
        }
    },

    btnContinueDisabled: {
        color: window.globalConfig.color.WHITE,
        width: 250,
        height: 40,
        backgroundColor: window.globalConfig.color.BROWN,
        borderWidth: 0,
        borderRadius: 5,
        cursor: "not-allowed",
        [theme.breakpoints.down('xs')]: {
            width: 200,
        },
    },

    btnParent: {
        // padding: "15px 10px 10px 10px",
        textAlign: 'center',
        width: "62%",
        marginLeft: "80px",
        [theme.breakpoints.down('xs')]: {
            marginLeft: "50px",
        },
    },

    dropdown: {
        paddingLeft: 67,
        paddingTop: 20,
        paddingRight: 71,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },

    },
    date: {
        paddingLeft: "26%",
        [theme.breakpoints.down('xs')]: {
            paddingLeft: "0%",
            marginLeft: "20px"
        }
    },
    dateContent: {
        paddingLeft: '3%'
    },

    footer: {
        padding: "0px 0px 14px 0px",
        justifyContent: "center",
        alignContent: 'center',
        // marginTop: -15,

    },

    cartParent: {
        textAlign: 'center'
    },
    textFieldCursor: {
        caretColor: window.globalConfig.color.BROWN,
    },

    validateBlock: {
        color: 'red',
        fontSize: 14,
        //  paddingLeft: 88, 
        display: "block",
        textAlign: "center",
        paddingTop: "3%",
        paddingBottom: "3%"
    },
    validateNone: { display: "none" },
    dropBoxLabel: {
        paddingBottom: 6,
        fontWeight: "normal",
        display: "block",
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: "14px",
        textAlign: "left"
    },

}));
const user = secureStorage.getItem("user")
function Mandate(props) {
    const classes = useStyles();
    const [selectedBankList, setDropdownValue] = React.useState([]);
    const [selectedBankName, setSelectedBankName] = React.useState()
    const [selectedAccountNumber, setSelectedAccountNumber] = React.useState("");
    const [bankNameAccountNumber, setbankNameAccountNumber] = React.useState("");
    const [dropdownLabel, setDropdownLabel] = React.useState();
    const [Mandate, setMandate] = React.useState("");
    const [displayAmount, setDisplayAmount] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [severity, setSeverity] = React.useState("warning");

    const [selectedMandateType, setMandateType] = React.useState([]);
    const [isValidate, setValidateBool] = React.useState(true);
    const [validateMessage, setValidateMessage] = React.useState('');
    const [List, setBankList] = React.useState([])
    const [prevMandateDetailsResponse, setMandateResponse] = React.useState('');
    const [isMandateCreationClick, setMandateCreationClick] = React.useState();
    const [tradeAccountNumber, setTradeAccountNumber] = React.useState('');
    const [tradeUcc, setTradeUcc] = React.useState('');

    const [isLoad, setIsLoading] = React.useState();
    useEffect(() => {



        if (props.mandateList.length != 0 && props.mandateList.filter((item) => item.label == "E Mandate [Net Banking]").length != 0) {
            setMandate("E Mandate [Net Banking]")
        }

        setBankList(props.bankDetails == "NA" ? [] : props.bankDetails)
        // console.log(props.bankList,props.additionalBankDetails)
        if (props.flag == "subscription") {
            setDropdownValue(props.List.filter((item) => item.value == props.additionalBankDetails.ID).length != 0 ? props.List.filter((item) => item.value == props.additionalBankDetails.ID)[0].label : props.bankList[0].value)

        } else {
            if (props.bankmandateId != undefined) {
                if (props.bankList != undefined && props.bankList != null && props.bankList.length != 0) {
                    let bankName = props.bankList.filter((item) => item.value == props.bankmandateId).length != 0 ? props.bankList.filter((item) => item.value == props.bankmandateId)[0].label : props.bankList[0].label
                    setSelectedBankName(bankName)
                    setSelectedAccountNumber(props.fullBankList.filter((val) => val.ID == props.bankmandateId)[0].BankAccountNumber)
                    // setSelectedBankName(bankName)
                    // setbankNameAccountNumber(value[0].label)
                    setDropdownValue(props.bankList.filter((item) => item.value == props.bankmandateId).length != 0 ? props.bankList.filter((item) => item.value == props.bankmandateId)[0].label : props.bankList[0].value)
                }
            } else {
                setSelectedAccountNumber(props.accountnumber)
            }

            setTradeAccountNumber(props.accountno)
            setTradeUcc(props.UCC)
        }

        fillBasicDetails()


    }, [props.bankmandateId]
    );
    const fillBasicDetails = () => {

        setDisplayAmount("")
        setDropdownValue("")
        if (props.mandateList.length != 0 && props.mandateList.filter((item) => item.label == "E Mandate [Net Banking]").length != 0) {
            setMandate("E Mandate [Net Banking]")
        }
        setSelectedBankName("")

    }
    const dropdowndata = (value) => {
        //   
        setSelectedAccountNumber(props.fullBankList.filter((val) => val.ID == value[0].value)[0].BankAccountNumber)
        setSelectedBankName(value[0].label)
        // setbankNameAccountNumber(value[0].label)
        setDropdownValue(value[0].value)
        if (selectedBankList == null || selectedBankList == undefined || selectedBankList.length <= 0) {
            setValidateMessage("")
        }
        else {

        }
    }
    const fillMandate = (data) => {

        if (props.fillMandate != undefined && Object.keys(data).length != 0 && data.status == "S") {
            props.fillMandate([{ label: `${data.response?.MandateID}-${data.response?.BankName}`, value: data.response?.MandateID }])
        }
        // if (props.mandateDetailsResponse != undefined && props.mandateDetailsResponse != {} && isMandateCreationClick) {
        setIsLoading(false);
        props.mandateResponseCallBack(data, selectedBankName);
        //}
        fillBasicDetails()
    }

    const mandateType = (value) => {
        //  
        setMandate(value[0].label)
        setMandateType(value[0].value)
    }
    const handleContinue = () => {
        //
        // console.log(selectedBankList) 
        if (selectedMandateType == null || selectedMandateType == undefined || selectedMandateType.length <= 0) {
            setValidateBool(false);
            setValidateMessage(`${Label.messages.MandateTypeempty}`)
            //   ("validation mandate not done")
        }
        else if (selectedBankList == null || selectedBankList == undefined || selectedBankList.length <= 0) {
            setValidateBool(false);
            setValidateMessage(`${Label.messages.Bankempty}`)
        } else if (displayAmount == null || displayAmount == undefined || displayAmount == '') {
            setValidateBool(false);
            setValidateMessage(`${Label.messages.Amountempty}`)
        }
        else if (displayAmount.replace(/,/g, '').split('₹')[1] < 1000) {
            setValidateBool(false);
            setValidateMessage(`${Label.messages.minimumAmount}`)
        }
        else {
            setValidateBool(true);
            setMandateCreationClick(true);
            setIsLoading(true);
            setValidateMessage('');
            var additionalBankDetails = []
            //
            props.fullBankList.map(function (data) {
                if (data.ID == selectedBankList) {
                    additionalBankDetails = data
                }
            })
            if (props.flag == "subscription") {
                addSubscriptionMandate(displayAmount.replace(/,/g, '').split('₹')[1], selectedMandateType, props.additionalBankDetails).then((res) => {
                    props.mandateResponseCallBack(res)
                })
            } else {
                props.generateMandate(displayAmount.replace(/,/g, '').split('₹')[1], selectedMandateType, selectedBankList, additionalBankDetails, selectedBankName, props.flag, props.branchName, props.ifsc, props.accountnumber, props.AccountType, tradeAccountNumber, tradeUcc, props.accountno, selectedAccountNumber, fillMandate)
                props.getMandateDetails()
            }



        }
    }


    const bindAmount = (event) => {
        // 
        const regex = new RegExp("^[₹0-9]+$");
        var val = event.target.value.replaceAll(",", "");
        if (!regex.test(val)) {
            event.preventDefault();
        }
        else {
            if (event.target.value.length == 16) {
                event.preventDefault();
            }
            else {
                if (event.target.value == "₹") {
                    setDisplayAmount("");
                }
                else {
                    setValidateMessage("")
                    if (event.target.value.charAt(0) != "₹") {
                        event.target.value = event.target.value.replace("₹", "")
                    }
                    if (event.target.value.length != 0 && event.target.value.includes("₹") == false) {
                        event.target.value = event.target.value.replace("₹", "").replace(/,/g, "")
                        var commaValue = NegativeFinding(event.target.value, "", "", "", "number")
                        setDisplayAmount("₹" + commaValue);
                    }
                    else if (event.target.value.length == 1 && event.target.value == "₹") {
                        setDisplayAmount("" + commaValue);
                    }
                    else if (event.target.value.length == 1) {
                        event.target.value = event.target.value.replace("₹", "").replace(/,/g, "")
                        var commaValue = NegativeFinding(event.target.value, "", "", "", "number")
                        setDisplayAmount("₹" + commaValue);
                    }
                    else {
                        event.target.value = event.target.value.replace("₹", "").replace(/,/g, "")
                        var commaValue = NegativeFinding(event.target.value, "", "", "", "number")
                        setDisplayAmount("₹" + commaValue);
                    }
                }
            }
        }
    };

    const date = moment()
        .utcOffset('+05:30')
        .format('DD MMM YYYY');

    let options = props.fullBankList?.filter((value) => value.UCC == tradeUcc)?.map((item) => {
        return { "label": `${item.BankName}(${item.BankAccountNumber})`, "value": item.ID }
    })
    // 

    // 
    const onclose = () => {
        if (!isLoad) {
            fillBasicDetails()
            props.close()
        }

    }
    return (

        <Dialog onClose={onclose}
            maxheight={'md'} maxWidth={'md'}

            aria-labelledby="simple-dialog-title" open={props.open}
        >

            <div className={props.popupName === "sip" ? classes.sipContainer : classes.onetimeContainer}>
                <div className={classes.header}>
                    <div className={classes.txtHeader}>{Labels.mandate.header}<span className={classes.clearIcon} style={{ cursor: !isLoad ? "pointer" : "not-allowed" }} onClick={onclose}>&#x2715;</span></div>
                </div>

                <div className={classes.body}>
                    {props.flag == "profile" || props.flag == "subscription" ? <div></div> :
                        <div className={classes.dropdown}>
                            <div className={classes.dropBoxLabel}>{labels.Mandate.Account}</div>
                            <div style={{ textAlign: "left" }}>{props.displayName}</div>
                            {/* <Dropdown
                                type={"normalwithsearch"}
                                handleOnChange={AccountSelection}
                                autoselect={true}
                                id={`mandate1`}
                                // values={this.state.BankAccounttype}
                                //  error={this.state.errorbankaccounttype}
                                // page={"normal"} 
                                placeholder={labels.messages.selectAccount}
                                label={labels.Mandate.Account}
                                List={props.BSEMFAccountList}
                            /> */}
                        </div>}
                    <div className={classes.dropdown}><Dropdown
                        type={"normalwithsearch"}
                        handleOnChange={mandateType}
                        id={`mandate2`}
                        value={Mandate}
                        loader={props.loaderdropdown}
                        placeholder={labels.messages.selectMandateType}
                        label={labels.Mandate.Mandate}
                        List={props.mandateList}
                    />
                    </div>
                    {props.flag == "subscription" ?
                        <div className={classes.dropdown}>
                            <div className={classes.dropBoxLabel}>{labels.Mandate.Bank}</div>
                            <div style={{ textAlign: "left", border: `1px solid #c7c7c7`, padding: 10 }}>{props.additionalBankDetails.BankName}-{props.additionalBankDetails.BankAccNo}</div>

                        </div> : <div className={classes.dropdown}> <Dropdown
                            id={`mandate3`}
                            type={"normalwithsearch"}
                            handleOnChange={dropdowndata}
                            height={135}
                            placeholder={labels.messages.selectBank}
                            label={labels.Mandate.Bank}
                            List={options}
                            // value={selectedBankName}
                            value={selectedBankName}
                        /> </div>}



                    <div className={classes.txtBody}>{Labels.mandate.amount} </div>
                    <TextField
                        id="standard-full-width"
                        style={{ textAlign: "center", }}
                        // className={classes.textAligns}
                        margin="normal"
                        autoComplete="off"
                        placeholder="₹0"
                        className={classes.textFieldCursor}
                        value={displayAmount}
                        onChange={bindAmount}
                    />
                </div>
                <div className={classes.date}><img src={DatePicker} height={20} width={20}></img>
                    <span className={classes.dateContent}>{date} - {moment().add(30, "years").subtract(3, 'days').format("DD MMM YYYY")} </span></div>
                <div className={classes.footer}>
                    <span className={(!props.isValidate) ? classes.validateBlock : classes.validateNone}>{validateMessage}</span>
                    <div className={classes.btnParent}>
                        <Button name={Labels.mandate.createMandate} page={"primary"} disabled={isLoad} loader={isLoad} onClick={handleContinue} />

                    </div>

                </div>
            </div>
        </Dialog>

    );
}
const mapStateToProps = state => {
    return {
        loaderdropdown: state.Dropdown.loaderdropdown,

        L5List: state.ProfileDetails.AllProfileDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.accountDetails != "NA" ? state.ProfileDetails.AllProfileDetails.accountDetails.map((item) => {
            return { "label": ` Account -${item.L5ID}`, "value": item.L5ID }
        }) : [] : [],
        BSEMFAccountList: state.L5report.L5report != null && state.L5report.L5report != undefined && state.L5report.L5report != "NA" && state.L5report.L5report.length != 0 ? [...state.L5report.L5report.filter((val) => val.MutualFundUCC != "NA").map((item) => {
            return { "label": `${item.PortfolioName}(${item.MutualFundUCC})`, "value": item.L5ID }
        })] : [],
        mandateDetailsResponse: state.Dropdown.mandateDetails,
        bankList: state.ProfileDetails.AllProfileDetails.bankDetails == "NA" ? [] : state.ProfileDetails.AllProfileDetails?.bankDetails?.filter((ele, ind) => ele.BankName != "").map((item) => {

            return { "label": `${item.BankName}(${item.BankAccountNumber})`, "value": item.ID, }

        }),
        TradingL5ID: state.Login.TradingL5ID,
        fullBankList: state.ProfileDetails.AllProfileDetails.bankDetails == "NA" ? [] : state.ProfileDetails.AllProfileDetails?.bankDetails?.filter((ele, ind) =>
            ele.BankName != ""),
        mandateList: state.Dropdown.dropdowndetails == null || state.Dropdown.dropdowndetails.mandateType == undefined || state.Dropdown.dropdowndetails.mandateType == null ? [] : state.Dropdown.dropdowndetails.mandateType.map(val => {
            return { "label": val.Description, "value": val.Type }
        }),
        BSEMFUCCList: state.L5report.L5report != null && state.L5report.L5report != undefined && state.L5report.L5report != "NA" && state.L5report.L5report.length != 0 ? [...state.L5report.L5report.filter((val) => val.MutualFundUCC != "NA").map((item) => {
            return { ...item, "label": `${item.PortfolioName}(${item.MutualFundUCC})`, "value": item.L5ID }
        })] : []
    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ generateMandate, getMandateDetails }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Mandate)



