 import React, { Component } from 'react';
import '../../utils/stylesheets/common/global/x-ray/transaction.css';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
// import {getTransactionListings } from '../../redux/actions/transaction/dropdownactions';
import { bindActionCreators } from 'redux';
import NoData from '../../components/nodata/nodata';
import Loader from '../../components/loader/loader';
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import CommonHeader from '../../components/report_common_header/report_common_header'
import CommonSnackbar from '../../components/snackbar/snackbar'
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle'
import colors from '../../utils/colors/colors'
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import { NegativeFinding } from '../../utils/commonfunction';
import Epfreport from '../epf/epfreport';
const styles = theme => ({
    //Added by Celsia on 29 May 2021 for Mobile Responsive Started
    desktopResponsive: {
        display: 'block !important',
        [theme.breakpoints.down("600")]: {
            display: 'none !important',
        },
    },
    mobileResponsive: {
        display: 'none !important',
        [theme.breakpoints.down("600")]: {
            display: 'block!important',
            padding: "0px 10px"
        },
    },
    trResponsive: {
        height: "auto",
        boxShadow: "0 0 5px 0 rgb(19 89 161 / 6%)",
        border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        padding: "10px 10px",
        borderRadius: "8px",
        fontSize: "13px",
        fontWeight: 400,
        // width: "100%",
    },

    leftResponsive: {
        textAlign: 'left',
        paddingLeft: '10px',
        marginTop: '3%',
        // flex: '0 0 50%'
    },
    rightResponsive: {
        marginBottom: '1%',
        textAlign: 'right',
        paddingRight: '10px',
        // flex: '0 0 50%',
        marginTop: '3%',
        marginLeft: '-6%'
    },
    centerResponsive: {
        textAlign: 'center',
        // marginTop: '3%',
    },
    tdResponsive: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between",
        padding: "10px 0px"
    },
    fontResponsive: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "12px",
        fontWeight: "bold"
    },
    divFontResponsive: {
        color: window.globalConfig.color.labelcolor,
        fontSize: "12px",
    },
    rootResponsive: {
        display: "flex",
        flexDirection: "row",
        paddingTop: "2em",
        [theme.breakpoints.down("600")]: {
            display: "block",
            paddingTop: "0px",
            marginTop: "15px"
        }
    }
    ,
    direction: {
        display: 'grid',
        gridTemplateColumns: "7% 90%",
        gap: 4,
        alignItems: "center",

    },
    nodataAvailable: {
        // display: 'none !important',
        [theme.breakpoints.down("600")]: {
            display: 'block !important',
            marginTop: '2.35rem',
            marginBottom: '3rem'
        },
    },
    success: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: colors.GREEN
    },
    pending: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: colors.WARNING
    },
    failure: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: colors.red
    },
    normal: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: colors.BLACK_PRIMARY
    },
    mandateContainer: {
        display: "grid",
        marginLeft: "20px",
        gap: "9px",
        gridTemplateColumns: "10% 13% 20% 18% 11% 10% 11%"
    }

})
let statusReport = []
let title = [{ header: "MANDATE CODE", align: "left" }, { header: "ACCOUNT NUMBER", align: "left" }, { header: "BANK NAME", align: "left" }, { header: "BANK BRANCH", align: "left" }, { header: "AMOUNT", align: "right" }, { header: "REGN DATE", align: "left" }, { header: "STATUS", align: "left" }]
class MandateReport extends Component {
    _isMount = false
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            mandateReport: []
        }
    }
    handlesnanckClose = () => {
        this.setState({ open: false })
    }
    componentDidUpdate(prevProps) {
        const { Mandate } = this.props;
        if (prevProps.Mandate != Mandate) {
            this.setState({
                mandateReport: Mandate.map((item) => {
                    return {
                        ...item, dataOne: item.ID, dataTwo: item.BankAccNo, dataThree: item.BankName
                        , dataFour: item.BankBranch, dataFive: NegativeFinding(item.Amount, "", "", "", "rupee"),
                        dataSix: item.RegistrationDate, dataSeven: <div style={{ textAlign: "left", display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
                            <div className={this.returnClass(item.Status)}></div>
                            {item.Status}
                        </div>
                    }
                })
            })
        }
    }
    componentDidMount() {
        this._isMount = true
        this.setState({
            mandateReport: this.props.Mandate.map((item) => {
                return {
                    ...item, dataOne: item.ID, dataTwo: item.BankAccNo, dataThree: item.BankName
                    , dataFour: item.BankBranch, dataFive: NegativeFinding(item.Amount, "", "", "", "rupee"),
                    dataSix: item.RegistrationDate, dataSeven: <div style={{ textAlign: "left", display: "flex", flexDirection: "row", alignItems: "center", gap: 10 }}>
                        <div className={this.returnClass(item.Status)}></div>
                        {item.Status}
                    </div>
                }
            })
        })

    }
    returnClass = (status) => {
        const { classes } = this.props
        statusReport = [{ name: "Approved", class: classes.success }, { name: "REGISTERED BY MEMBER", class: classes.pending }, { name: "rejected", class: classes.failure }, { name: "Failed", class: classes.failure }, { name: "Pending", class: classes.pending }]
        if (statusReport.filter((item) => item.name.toLowerCase() == status.toLowerCase()).length != 0) {
            return statusReport.filter((item) => item.name.toLowerCase() == status.toLowerCase())[0].class

        } else {
            return classes.normal
        }
    }
    componentWillUnmount() {
        this._isMount = false
    }

    render() {
        const { classes } = this.props;
        try {
            return (
                <ErrorBoundary>
                    {
                        // <>
                        <div style={{ marginBottom: "0%" }}>
                            <Epfreport singleScheme="List" showfilter={false} multipleScheme="Lists" loader={this.props.isMandateLoading} grid={classes.mandateContainer} data={this.state.mandateReport} title={title}></Epfreport>
                            {/* <CommonHeader data={this.props.Mandate}
                                showfilter={false}
                                showdownload={false}
                                header={"List"}
                                scheme={this.props.Mandate != undefined && this.props.Mandate != null && this.props.Mandate != "NA" ? this.props.Mandate.length > 0 ? this.props.Mandate.length == 1 ? `${this.props.Mandate.length} List` : `${this.props.Mandate.length} Lists` : <NoData /> : <NoData />}
                                page={"Trading"} /> */}

                            {/* <div className={classes.desktopResponsive}>
                                <div style={{ flexDirection: "row", marginBottom: '4%' }} className="contentBlock">
                                    {this.props.Mandate != null && this.props.Mandate != "NA" && this.props.Mandate != undefined && this.props.Mandate.length != 0
                                        ? (
                                            <div >
                                                <div className={[classes.sevenSplitContainer, classes.tableHeader].join(' ')}>
                                                    <div style={{ textAlign: "left", paddingLeft: 10 }}>
                                                        MANDATE CODE
                                                    </div>
                                                    <div style={{ textAlign: "left" }}>
                                                        ACCOUNT NUMBER

                                                    </div>
                                                   
                                                    <div style={{ textAlign: "left" }}>
                                                        BANK NAME
                                                    </div>
                                                    <div style={{ textAlign: "left" }}>

                                                        BANK BRANCH
                                                    </div>
                                                    <div style={{ textAlign: "right" }}>
                                                        AMOUNT
                                                    </div>
                                                    <div style={{ textAlign: "center" }}>
                                                        REGN DATE

                                                    </div>
                                                    <div style={{ textAlign: "left" }}>
                                                        STATUS
                                                    </div>
                                                </div>
                                                {this.props.Mandate.map((row) => {
                                                    return (
                                                        <div className={[classes.sevenSplitContainer, classes.subCategoryGroupContainer].join(' ')}>
                                                            <div className={classes.subCatgoryValue} style={{ textAlign: "left", paddingLeft: 10 }}>
                                                                {row.ID}
                                                            </div >
                                                            <div className={classes.subCatgoryValue} style={{ textAlign: "left" }}>
                                                                {row.BankAccNo == "" ? 'NA' : row.BankAccNo}
                                                            </div>
                                                          
                                                            <div className={classes.subCatgoryValue} style={{ textAlign: "left" }}>
                                                                {row.BankName}
                                                            </div>
                                                            <div className={classes.subCatgoryValue} style={{ textAlign: "left" }}>
                                                                {row.BankBranch == "" ? 'NA' : row.BankBranch}
                                                            </div>
                                                            <div className={classes.subCatgoryValue} >
                                                                <span className={classes.subcategoryRupee}>{labels.currency.Rupee}</span> {NegativeFinding(row.Amount, "", "", "", "number")}
                                                            </div>
                                                            <div className={classes.subCatgoryValue} style={{ textAlign: "center" }}>
                                                                {row.RegistrationDate}
                                                            </div>
                                                            <div className={[classes.subCatgoryValue, classes.direction].join(" ")} style={{ textAlign: "left", }}>
                                                                <div className={this.returnClass(row.Status)}></div>
                                                                {row.Status}
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        )
                                        : (
                                            <div  >
                                              
                                            </div>
                                        )

                                    }
                                </div >
                            </div> */}
                            <CommonSnackbar handlesnanckClose={this.handlesnanckClose} severity={'warning'} open={this.state.open} message={labels.messages.apierror}></CommonSnackbar>

                            {/* <div className={classes.mobileResponsive}>
                                <div className={classes.rootResponsive}>
                                    <div className="contentBlock">
                                        {this.props.Mandate != null && this.props.Mandate != "NA" && this.props.Mandate != undefined && this.props.Mandate.length != 0
                                            ? (
                                                <div >
                                                    {this.props.Mandate.map((row) => (
                                                        <div xs={12} className={classes.trResponsive}>
                                                            <div xs={12} className={classes.tdResponsive}>
                                                                <div xs={6} className={classes.leftResponsive}>
                                                                    <span className={classes.fontResponsive} >{"BANK NAME"}</span><br />
                                                                    <span className={classes.divFontResponsive}>{row.BankName}</span>
                                                                </div>
                                                                <div xs={6} className={classes.rightResponsive}>
                                                                    <span className={classes.fontResponsive}>{"ACCOUNT NUMBER"}</span> <br />
                                                                    <span className={classes.divFontResponsive}>{row.BankAccNo}</span>
                                                                </div>
                                                            </div>
                                                            <div xs={12} className={classes.tdResponsive}>
                                                                <div xs={6} className={classes.leftResponsive}>
                                                                    <span className={classes.fontResponsive}>{"BANK BRANCH"}</span>  <br />
                                                                    <span className={classes.divFontResponsive}>{row.BankBranch}</span>
                                                                </div>
                                                                <div xs={6} className={classes.rightResponsive}>
                                                                    <span className={classes.fontResponsive}>{"AMOUNT"}</span> <br />
                                                                    <span className={classes.divFontResponsive}>{labels.currency.Rupee}{NegativeFinding(row.Amount, "", "", "", "number")}</span><br />
                                                                    <span className={classes.divFontResponsive}>{row.RegistrationDate}</span>
                                                                </div>
                                                            </div>
                                                            <div xs={12} className={classes.tdResponsive}>
                                                                <div xs={4} className={classes.leftResponsive}>
                                                                    <span className={classes.fontResponsive}>{"MANDATE CODE"}</span>   <br />
                                                                    <span className={classes.divFontResponsive}>{row.ID}</span>
                                                                </div>
                                                                <div xs={4} className={classes.centerResponsive}>
                                                                    <span className={classes.fontResponsive}>{"STATUS"}</span> <br />
                                                                    <span className={classes.divFontResponsive} style={{ textAlign: "left", color: row.Status.includes("Approved".toUpperCase()) ? colors.GREEN : row.Status.includes("REGISTERED BY MEMBER".toUpperCase()) ? colors.WARNING : row.Status.includes("rejected".toUpperCase()) ? colors.red : window.globalConfig.color.PRIMARY }}>{row.Status}</span>
                                                                </div>
                                                                <div xs={4} className={classes.rightResponsive}>
                                                                    <span className={classes.fontResponsive}>{"MEMBER CODE"}</span> <br />
                                                                    <span className={classes.divFontResponsive}>{row.MemberCode}</span>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    ))}
                                                </div>
                                            )
                                            : (
                                                <div className={classes.nodataAvailable}><NoData /></div>
                                            )

                                        }
                                        <CommonSnackbar handlesnanckClose={this.handlesnanckClose} severity={'warning'} open={this.state.open} message={labels.messages.apierror}></CommonSnackbar>

                                    </div >
                                </div>
                            </div> */}




                        </div>


                        // </>
                    }
                </ErrorBoundary>
            )
        }
        catch (err) {
            // console.log(err)
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => {
    return {
        transactionList: state.transactionList.transactionList,
        dropdowntransaction: state.transactionList.dropdowntransaction,
        isLoadingtransactionList: state.transactionList.isLoadingtransactionList,
        sessionID: state.Login.sessionID,
        clientID: state.Login.logindetails.ClientID,
        userID: state.Login.userID,
        date: state.Login.date,
        Mandate: state.Mandate.mandatedata,
        catchmessage: state.transactionList.catchmessage,
        isMandateLoading: state.Mandate.isMandateLoading
    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch)
}
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles((theme) => ({
    ...styles(theme),
    ...CommonTableStyles(theme),
}),
    { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(MandateReport));
