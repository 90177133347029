import ApiValues from '../url/apiurl'
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
var axios = require('axios');
function getAIFAddAsset(data) {

    return Networking.PostApi(ApiValues.AIFAddAsset,data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default getAIFAddAsset
