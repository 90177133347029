import React, { Component } from 'react';
import '../../utils/stylesheets/common/global/x-ray/transaction.css';
import labels from '../../utils/constants/labels';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
    desktopResponsive: {
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },

    footerStyle: {
        // position: "absolute",
        // left: 0,
        // bottom: 0,
        // right: 0,
        // height: `calc(${"100%"} - ${"60px"}),
        // `,
        color: "#abb4bf",
        backgroundColor: "#e7eaec",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: 2,
        fontSize: 14
    }
})
class Footer extends Component {
    componentDidMount() {
        var path = window.location.href.split("/")
    }
    componentDidUpdate() {
        var path = window.location.href.split("/")
        // 
    }

    render() {

        var path = window.location.pathname
        // 
        // var path = new URL(url).pathname;
        const { classes } = this.props
        return (


            <footer className={[classes.footerStyle, classes.desktopResponsive].join(" ")}>
                {labels.messages.PoweredBy}
            </footer>


        )

    }

}
export default withStyles(styles)(Footer)
