import React from "react";
import { withStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import colors from "../../utils/colors/colors";
import Three_State_Toggle_Switch from "../switchbutton/three_state_toggle_switch";
import Textinput from "../textinput/textinput";
import labels from "../../utils/constants/labels";
import { Chip } from "@material-ui/core";
import Dropdown from "../dropdown/dropdownschemes";
import moment from "moment";
import { NegativeFinding, numbersOnly, replaceNull, returnDateList, sleep, uniqueId } from "../../utils/commonfunction";
import CommonCheckbox from "../checkbox/checkbox";
import Button from "../custombutton/button";
import CommonSnackbar from "../snackbar/snackbar";
import FolioPopup from "../tradingpopup/foliopopup";
import { SchemeCodeFinding } from "../../utils/commonfunction";
import addtocart from "../../api/cart/addtocartapi";
import Loader from "../loader/loader";
import ApiValues from '../../api/url/apiurl'
import { withRouter } from "react-router";
import getPortfolioxraySchemeDetails from "../../api/portfolioxrayschemedetail/portfolioxrayschemedetail";
import { GetAllCart } from "../../redux/actions/cart/getallcartaction";
import { object } from "prop-types";
import addtocartbasket from "../../api/basket/addtocartbasket";
import Networking from "../../utils/api/apiaccess";
import SplitBasketAmount from "../../api/basket/splitbasketamount";
let timer = ''
const styles = (theme) => ({
  container: {

  },
  header: {
    fontSize: 18,
    fontWeight: "bold",
    color: colors.BLACK_PRIMARY
  },
  amountBadge: {
    display: "flex",
    flexDirection: "row",
    gap: 15,
    justifyContent: "space-between"
  },
  textInput: {
    alignItems: "center"
  }
})
let todayDate = moment(new Date()).format("DD MMM YYYY");
class SideTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      purchaseType: "SIP",
      amount: "",
      selectedDate: "",
      checked: false,
      folioNumberList: [],
      open: false,
      message: "",
      startDate: "",
      color: "severity",
      folioLoader: false,
      openFolioPopup: false,
      dropDownValue: 1,
      currentDateBackUp: "",
      disabled: false,
      currentDateBackUp: "", flag: "", tradingLoader: false, cartLoader: false
    }
  }
  componentDidMount() {
    let dropdownList = labels.Date
    this.toggleChange("SIP")
    this.onSipDateChange([dropdownList[0]])
    if (this.props.data.minAmount != undefined) {
      this.setState({ amount: NegativeFinding(this.props.data.minAmount, "", "", "", "rupee") })
    }
    this.setState({ startDate: dropdownList[0]?.label, selectedDate: dropdownList[0]?.label })
  }
  toggleChange = async (val) => {
    let changeVal;

    if (this.props.page == "basket") {
      changeVal = val
    } else {
      if (this.props.schemeDetails.OneTimeAllowed == 1 && this.props.schemeDetails.SipAllowed == 1) {
        changeVal = val
      } else if (this.props.schemeDetails.OneTimeAllowed == 0 && this.props.schemeDetails.SipAllowed == 1) {
        changeVal = "SIP"
      }
      else if (this.props.schemeDetails.OneTimeAllowed == 1 && this.props.schemeDetails.SipAllowed == 0) {
        changeVal = "One Time"
      }
    }


    await getPortfolioxraySchemeDetails(this.props.logindetails.ClientID, 0, this.props.schemeDetails.SI, false, val).then((res) => {
      if (res.status == "S") {
        if (res.folioNumber.length != 0) {
          this.setState({ folioNumberList: res.folioNumber })
        } else {
          this.setState({ folioNumberList: [] })
        }
      } else {
        this.setState({ folioNumberList: [] })
      }
    })
    let dropdownList = labels.Date
    this.onSipDateChange([dropdownList[0]])
    if (this.props.data.minAmount != undefined) {
      this.setState({ amount: NegativeFinding(this.props.data.minAmount, "", "", "", "rupee") })
    } else {
      this.setState({ amount: "" })
    }
    this.setState({ purchaseType: val, startDate: dropdownList[0]?.label, selectedDate: dropdownList[0]?.label, checked: false })
  }
  addAmountbtnClk = (val) => {
    let a = this.state.amount.toString().replace(labels.currency.rupeesymbol, "").replaceAll(",", "")
    if (a != undefined) {
      this.setState({ amount: NegativeFinding(Number(a) + val, "", "", "", "rupee") })
    }
  }
  onChangeAmount = (event) => {
    var val = event.target.value.replaceAll(",", "");
    var currancyvalue = val.replaceAll(labels.currency.rupeesymbol, "").trim();
    if (currancyvalue.trim() == "") {
      this.setState({ amount: "" })
    }
    else {
      if (currancyvalue != undefined && currancyvalue != null && currancyvalue.length <= 10 && numbersOnly(currancyvalue)) {
        this.setState({ amount: NegativeFinding(currancyvalue, "", "", "", "rupee") })
      }
    }
  }
  onSipDateChange = async (value) => {
    let dropdownDate = moment(value[0].value, "DD MMM YYYY").format(
      "DD MMM YYYY"
    );
    this.setState({ dropDownValue: value[0].value })
    dropdownDate = moment(dropdownDate).isBefore(todayDate) ? moment(
      moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
    ).format("DD MMM YYYY") : dropdownDate
    await this.setState({
      currentDateBackUp: dropdownDate,
      startDate: labels.Date.filter((item) => item.value == value[0].value)[0].label
    })
    await this.dateSetting(value[0].value, this.state.checked)
  }

  onHandleClick = (val) => {
    if (this.props.page == "basket") {
      this.onClickAddtoCart()
    } else {
      if (this.state.purchaseType == "SIP") {
        this.handleSIP()
      } else {
        this.handleOneTime()
      }
    }
  }
  onClickAddtoCart = () => {
    if (this.state.purchaseType == "SIP") {
      this.onClickAddtoCartSIP()
    } else {
      this.onClickAddtoCartOneTime()
    }
  }
  onClickAddtoCartSIP = (data) => {
    this.setState({ flag: "SipCart" })
    let finalAmount = this.state.amount.replace(/,/g, "").split("₹")[1];
    let cart = this.props.schemeDetails;
    if (this.props.logindetails.L4_AccountTypeID == 2 && !this.props.userDetails[0].KYCValid && this.props.companyDetails[0].IsKYCAllowed) {
      this.props.openKycPopup()
    } else {
      let amount = this.props.page == "basket" ? NegativeFinding(this.props.list.minAmount, "", "", "", "rupee") : 1000
      let amountCalculation = this.props.page == "basket" ? Number(finalAmount) < this.props.list.minAmount : Number(finalAmount) < 1000
      if (this.props.uccList.length != 0 && this.props.uccList.filter((item) => item.ClientCode == "NA").length != this.props.uccList.length) {

        if (finalAmount == undefined || finalAmount == "") {
          this.setState({ open: false, color: "warning", message: labels.tranactionSchemesDetails.SIPandOneTimeErrorMessage, open: true })

        } else if (amountCalculation) {
          this.setState({ open: false, color: "warning", message: labels.sipPopup.minimumValue + amount, open: true })
        } else if (this.state.selectedDate == "") {
          this.setState({ open: false, color: "warning", message: labels.sipPopup.sipValidation, open: true })
        } else if (!this.state.checked && moment.duration(moment(this.state.selectedDate, "DD MMM YYYY").diff(moment(todayDate, "DD MMM YYYY"))).asDays() < 6) {
          this.setState({ open: false, color: "warning", message: labels.investmentPopup.minimum7days, open: true })
        } else {
          if (this.props.uccList.length == 1) {
            this.finalSipAddToCart(this.props.uccList[0].ClientCode);
          } else {
            this.setState({
              folioNumberList: this.props.uccList.map((item) => {
                return { ...item, label: item.ClientCode, value: item.ClientCode }
              }), openFolioPopup: true
            })
          }
        }
      } else {
        this.setState({ open: false })

        this.props.openUccPopup()
        // setOpen(true);
      }
    }
  };

  onClickAddtoCartOneTime = async () => {
    this.setState({ flag: "OneTimeCart" })
    let finalAmount = this.state.amount.replace(/,/g, "").split("₹")[1];
    if (this.props.logindetails.L4_AccountTypeID == 2 && !this.props.userDetails[0].KYCValid && this.props.companyDetails[0].IsKYCAllowed) {
      this.props.openKycPopup()
    } else {
      if (this.props.uccList.length != 0 && this.props.uccList.filter((item) => item.ClientCode == "NA").length != this.props.uccList.length) {
        let amount = this.props.page == "basket" ? NegativeFinding(this.props.list.minAmount, "", "", "", "rupee") : 5000
        let amountCalculation = this.props.page == "basket" ? Number(finalAmount) < this.props.list.minAmount : Number(finalAmount) < 1000
        if (finalAmount == undefined || finalAmount == "") {
          this.setState({
            open: false, color: "warning", message: labels.tranactionSchemesDetails.SIPandOneTimeErrorMessage, open: true
          })

        } else if (amountCalculation) {
          this.setState({ open: false, color: "warning", message: labels.sipPopup.minimumValue + amount, open: true })
        }
        else {
          finalAmount = Number(finalAmount);

          // Scheme Code selection Code changed by Srinath

          if (this.props.uccList.length == 1) {
            this.finalOneTimeAddToCart(this.props.uccList[0].ClientCode);
          } else {
            this.setState({
              folioNumberList: this.props.uccList.map((item) => {
                return { ...item, label: item.ClientCode, value: item.ClientCode }
              }), openFolioPopup: true
            })

          }



        }
      } else {
        // setOpen(false);
        // setColor("warning");
        // setOpenUCC(true)
        this.props.openUccPopup()
        // setMessage(lable.messages.uccnotavailble);
        // setOpen(true);
      }
    }
  };
  handleSIP = () => {
    this.setState({ flag: "SipContinue" })

    let finalAmount = this.state.amount.replace(/,/g, "").split("₹")[1];

    if (this.props.logindetails.L4_AccountTypeID == 2 && !this.props.userDetails[0].KYCValid && this.props.companyDetails[0].IsKYCAllowed) {
      this.props.openKycPopup()
    } else {
      if (this.props.uccList.length != 0 && this.props.uccList.filter((item) => item.ClientCode == "NA").length != this.props.uccList.length) {
        if (finalAmount == undefined || finalAmount == "") {
          this.setState({ open: false, color: "warning", open: true, message: labels.tranactionSchemesDetails.SIPandOneTimeErrorMessage })


        } else if (Number(finalAmount) < 1000) {
          this.setState({ open: false, color: "warning", open: true, message: labels.sipPopup.minimumValue + 1000 })

        } else if (this.state.selectedDate == "") {
          this.setState({ open: false, color: "warning", open: true, message: labels.sipPopup.sipValidation })


        } else if (

          !this.state.checked && moment
            .duration(
              moment(this.state.selectedDate, "DD MMM YYYY").diff(
                moment(todayDate, "DD MMM YYYY")
              )
            )
            .asDays() < 6
        ) {
          this.setState({ open: false, color: "warning", open: true, message: labels.investmentPopup.minimum7days })
        } else {

          if (this.props.uccList.length == 1) {
            this.finalSipContinue(this.props.uccList[0].ClientCode);
          } else {
            this.setState({
              folioNumberList: this.props.uccList.map((item) => {
                return { ...item, label: item.ClientCode, value: item.ClientCode }
              }), openFolioPopup: true
            })

          }

        }
        //}
      } else {
        this.props.openUccPopup()
      }
    }
  };
  handleOneTime = () => {
    this.setState({ flag: "OneTimeContinue" })
    // if (props.logindetails.UCCCode != "") {
    if (this.props.logindetails.L4_AccountTypeID == 2 && !this.props.userDetails[0].KYCValid && this.props.companyDetails[0].IsKYCAllowed) {

      // if ((props.logindetails.L4_AccountTypeID == 2 && !props.userDetails[0].KYCValid) || (props.logindetails.EntityTypeID === 9)) {
      this.props.openKycPopup()

    } else {
      if (this.props.uccList.length != 0 && this.props.uccList.filter((item) => item.ClientCode == "NA").length != this.props.uccList.length) {
        let finalAmount = this.state.amount.replace(/,/g, "").split("₹")[1];
        if (finalAmount == "" || finalAmount == undefined) {
          this.setState({ open: true, color: "warning", message: labels.tranactionSchemesDetails.SIPandOneTimeErrorMessage })

        } else {

          if (this.props.uccList.length == 1) {
            this.finalOneTimeContinue(this.props.uccList[0].ClientCode);
          } else {
            this.setState({
              folioNumberList: this.props.uccList.map((item) => {
                return { ...item, label: item.ClientCode, value: item.ClientCode }
              }), openFolioPopup: true
            })


          }


        }
      } else {

        this.props.openUccPopup()
      }
    }
  };
  //Old one
  // dateSetting = async (date, isFirstPaymentToday) => {
  //   let todayDate = moment(new Date()).format("DD MMM YYYY");
  //   let dropdownDate = moment(date, "DD MMM YYYY").format("DD MMM YYYY");

  //   dropdownDate = moment(dropdownDate).isBefore(todayDate) ? moment(
  //     moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
  //   ).format("DD MMM YYYY") : dropdownDate
  //   let backupDropDownDate = dropdownDate
  //   //console.log(moment(dropdownDate).isAfter(todayDate))
  //   if (!moment.isMoment(todayDate)) todayDate = moment(todayDate);
  //   if (!moment.isMoment(dropdownDate)) dropdownDate = moment(dropdownDate);
  //   if (!moment(dropdownDate).isAfter(todayDate) || dropdownDate.diff(todayDate, "days") <= 6) {
  //     this.setState({
  //       selectedDate: moment(
  //         moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
  //       ).format("DD MMM YYYY"),
  //     });
  //   } else {
  //     this.setState({ selectedDate: backupDropDownDate });
  //   }
  // }

  dateSetting = (date, isChecked) => {
    // console.log(date)
    // let todayDate = moment(new Date()).format("DD MMM YYYY");
    // let dropdownDate = moment(date, "DD MMM YYYY").format("DD MMM YYYY");
    // if (!moment.isMoment(todayDate)) todayDate = moment(todayDate);
    // if (!moment.isMoment(dropdownDate)) dropdownDate = moment(dropdownDate);
    // dropdownDate = moment(dropdownDate).isBefore(todayDate) ? moment(
    //   moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
    // ).format("DD MMM YYYY") : dropdownDate

    // : dropdownDate.diff(todayDate, "days") <= 6 ? moment(
    //   moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
    // ).format("DD MMM YYYY")
    // moment(this.state.selectedDate).diff(moment().format("DD MMM YYYY"), "days") <= 6 ? moment(
    //   moment(
    //     this.state.selectedDate,
    //     "DD MMM YYYY"
    //   )
    //     .add(1, "months")
    //     .toString()
    // ).format("DD MMM YYYY") :
    // console.log(moment(this.state.selectedDate).diff(moment().format("DD MMM YYYY"), "days") <= 6)
    // if (!moment(dropdownDate).isAfter(todayDate) || dropdownDate.diff(todayDate, "days") <= 6) {
    // if ((!moment(dropdownDate).isAfter(todayDate) && !moment(dropdownDate).isSame(todayDate)) || moment(dropdownDate).diff(moment().format("DD MMM YYYY"), "days") <= 6) {

    //   this.setState({
    //     selectedDate: moment(
    //       moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
    //     ).format("DD MMM YYYY"),
    //   });
    // } else {
    //   this.setState({ selectedDate: this.state.currentDateBackUp });


    // }
    let todayDate = moment(new Date()).format("DD MMM YYYY");
    let dropdownDate = moment(date, "DD MMM YYYY").format("DD MMM YYYY");
    // console.log(isChecked, moment(dropdownDate).diff(moment().format("DD MMM YYYY"), "days"), dropdownDate)
    if (isChecked && !moment(dropdownDate).isBefore(todayDate) && moment(dropdownDate).diff(moment().format("DD MMM YYYY"), "days") <= 6 || moment(dropdownDate).diff(moment().format("DD MMM YYYY"), "days") > 6) {
      this.setState({
        selectedDate: moment(
          moment(dropdownDate, "DD MMM YYYY").toString()
        ).format("DD MMM YYYY"),
      });
    }
    else {
      this.setState({
        selectedDate: moment(
          moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
        ).format("DD MMM YYYY"),
      });
    }
  };
  finalSipContinue = async (ucc) => {

    let finalAmount = this.state.amount.replace(/,/g, "").split("₹")[1];
    let schemeData = this.props.schemeDetails;
    if (this.props.logindetails.L4_AccountTypeID == 2 && !this.props.userDetails[0].KYCValid && this.props.companyDetails[0].IsKYCAllowed) {
      this.props.openKycPopup()
    } else {
      if (this.props.uccList.length != 0 && this.props.uccList.filter((item) => item.ClientCode == "NA").length != this.props.uccList.length) {
        if (this.props.page == "basket" || this.props.sipCartData.length > 0) {
          this.finalSipAddToCart(ucc)
        } else {
          this.setState({ tradingLoader: true })
          let validationMessage = await SchemeCodeFinding(finalAmount,
            labels.investmentPopup.keySIP,
            this.props.schemeDetails.ISIN,
            this.props.schemeDetails.PC,
            this.props.logindetails.ClientID, "SIP")
          if (validationMessage.status != "success") {
            this.setState({ tradingLoader: false, open: false, color: "warning", message: validationMessage.status, open: true })

          } else {


            // if (Details != undefined) {
            let purchaseData = this.props.schemeDetails;
            purchaseData.InvesmentType = "Sip";
            purchaseData.SchemeID = schemeData.SI;
            purchaseData.SchemeName = schemeData.SN;
            purchaseData.AssetClass = schemeData.AC;
            purchaseData.SipFrequency = "MONTHLY"
            purchaseData.minimumInstalment = validationMessage.response?.MINIMUM_INSTALLMENT_NUMBERS || 12
            purchaseData.AUM = schemeData.AUM;
            purchaseData.Risk = schemeData.Risk;
            purchaseData.Amount = finalAmount;
            purchaseData.ISIN = schemeData.ISIN;
            purchaseData.StepperActive = 0;
            purchaseData.ISFirstPayment = this.state.checked;
            purchaseData.threeYearReturn = schemeData.Nav;
            purchaseData.date = schemeData.NavDate;
            purchaseData.SchemeCode = schemeData.SC;
            purchaseData.Ucc = ucc
            purchaseData.MutualFundUCC = ucc
            purchaseData.Sipdate = moment(this.state.selectedDate, "DD MMM YYYY").format(
              "DD/MM/YYYY"
            );
            purchaseData.btnFrequency = 1;
            purchaseData.BSESchemeType = schemeData.BSEST;
            purchaseData.FolioList = JSON.stringify(this.state.folioNumberList)
            delete this.props.schemeDetails.multipleScheme
            purchaseData.multipleScheme = [{ ...replaceNull(this.props.schemeDetails, labels.messages.NA), Instalments: 180 }]
            this.props.history.push({
              pathname: labels.Path.Payment,
              state: { data: replaceNull(purchaseData, labels.messages.NA) },
            });
            this.setState({ openFolioPopup: false })
            // } else {
            //   this.setState({ open: false, color: "warning", message: labels.tranactionSchemesDetails.Somethingwentwrong, open: true })

            // }
          }

        }
      } else {
        // setOpen(false);
        // setColor("warning");
        this.setState({ open: false })
        // setMessage(lable.messages.uccnotavailble);
        // setOpen(true);
        // setOpenUCC(true)
        this.props.openUccPopup()
      }
    }
  };
  finalOneTimeContinue = async (ucc) => {
    let finalAmount = this.state.amount.replace(/,/g, "").split("₹")[1];
    if (this.props.logindetails.L4_AccountTypeID == 2 && !this.props.userDetails[0].KYCValid && this.props.companyDetails[0].IsKYCAllowed) {
      this.props.openKycPopup()

    } else {
      if (this.props.uccList.length != 0 && this.props.uccList.filter((item) => item.ClientCode == "NA").length != this.props.uccList.length) {
        // if (Details != undefined) {
        let purchaseData = this.props.schemeDetails;
        let schemeData = this.props.schemeDetails;
        finalAmount = Number(finalAmount);
        this.setState({ tradingLoader: true })
        let validationMessage = await SchemeCodeFinding(
          finalAmount,
          labels.investmentPopup.keyOneTime,
          this.props.schemeDetails.ISIN,
          this.props.schemeDetails.PC,
          this.props.logindetails.ClientID, "BUY"
        );

        if (validationMessage.status != "success") {

          this.setState({ open: false, tradingLoader: false, color: "warning", message: validationMessage.status, open: true })
        } else {
          if (
            validationMessage.response.PurchaseAmountMultiplier > 1 &&
            finalAmount % validationMessage.response.PurchaseAmountMultiplier != 0
          ) {
            this.setState({
              open: false, color: "warning", message: labels.investmentPopup.amountMultiplerValidation +
                validationMessage.response.PurchaseAmountMultiplier, open: true
            })

          } else if (this.props.onetimeCartData.length > 0 || this.props.page == "basket") {
            this.finalOneTimeAddToCart(ucc)

          } else {
            let schemeData = this.props.schemeDetails;
            purchaseData.InvesmentType = "One time";
            purchaseData.SchemeID = schemeData.SI;
            purchaseData.SchemeCode = validationMessage.response.SchemeCode;
            purchaseData.SchemeName = schemeData.SN;
            purchaseData.AssetClass = schemeData.AC;
            purchaseData.AUM = schemeData.AUM;
            purchaseData.StepperActive = 0;
            purchaseData.Risk = schemeData.Risk;
            purchaseData.SipFrequency = "MONTHLY"
            purchaseData.Amount = finalAmount;
            purchaseData.threeYearReturn = schemeData.Nav;
            purchaseData.date = schemeData.NavDate;
            purchaseData.Ucc = ucc
            purchaseData.MutualFundUCC = ucc
            purchaseData.BSESchemeType = schemeData.BSEST;
            purchaseData.FolioList = JSON.stringify(this.state.folioNumberList)
            delete this.props.schemeDetails.multipleScheme
            purchaseData.multipleScheme = [{ ...replaceNull(this.props.schemeDetails, ""), Instalments: 180 }]

            this.props.history.push({
              pathname: labels.Path.Payment,
              state: { data: replaceNull(purchaseData, labels.messages.NA) },
            });
            this.setState({ openFolioPopup: false })
          }
        }
        // } else {
        this.setState({ open: false })
        // setMessage(lable.messages.uccnotavailble);
        // setOpen(true);
        // setOpenUCC(true)
        this.props.openUccPopup()
      }
    }
  };
  finalSipAddToCart = async (ucc) => {
    let finalAmount = this.state.amount.replace(/,/g, "").split("₹")[1];
    if (this.props.logindetails.L4_AccountTypeID == 2 && !this.props.userDetails[0].KYCValid && this.props.companyDetails[0].IsKYCAllowed) {
      this.props.openKycPopup()
    } else {
      if (this.props.uccList.length != 0 && this.props.uccList.filter((item) => item.ClientCode == "NA").length != this.props.uccList.length) {
        if (this.props.page == "basket") {

          this.setState({ cartLoader: true })
          // let data = {
          //   "status": "S",
          //   "message": "Success",
          //   "data": [
          //     {
          //       "SchemeName": "HDFC FMP 1115D JANUARY 2015 (1) - REGULAR OPTION - NORMAL IDCW PAYOUT OPTION",
          //       "Weightage": 50.00,
          //       "Amount": 50000.000000,
          //       "SchemeId": 2633,
          //       "ISIN": "ISIN23423423"
          //     },
          //     {
          //       "SchemeName": "HDFC FMP-1120Days-Mar2019(1)(XLIV)-Dir (IDCW)",
          //       "Weightage": 50.00,
          //       "Amount": 50000.000000,
          //       "SchemeId": 6608,
          //       "ISIN": "ISIN234565563"
          //     }
          //   ],
          //   "table1": null
          // }

          // SplitBasketAmount(finalAmount, this.props.schemeDetails?.ID).
          //   then((res) => {
          //     this.setState({ cartLoader: false })
          //     if (res.status == "S") {
          //       data = res

          //     } else {

          //     }
          //   })
          let data = []
          // console.log(this.props.schemeDetails.ID)
          await SplitBasketAmount(finalAmount
            , this.props.schemeDetails?.ID).
            then((res) => {
              this.setState({ cartLoader: false })
              let changeData = res
              if (res.status == "S") {
                changeData = res
                changeData.data = res.data.map((val) => {
                  return { ...val, SchemeName: val.instrument || "NA", Weightage: val.weightage, Amount: val.amount, SchemeId: val.instrumentID }
                })
              } else {
                changeData = []
                this.setState({ open: true, message: labels.messages.apierror, color: "warning" })
              }
              data = changeData
            })

          if (data.length != 0) {
            let purchaseData = this.props.schemeDetails;
            purchaseData.InvesmentType = "Sip";
            purchaseData.SipFrequency = "MONTHLY"
            purchaseData.minimumInstalment = 12
            purchaseData.StepperActive = 0;
            purchaseData.Ucc = ucc
            purchaseData.ISFirstPayment = this.state.checked
            purchaseData.MutualFundUCC = ucc
            purchaseData.Sipdate = moment(this.state.selectedDate, "DD MMM YYYY").format("DD/MM/YYYY");
            purchaseData.btnFrequency = 1;
            purchaseData.FolioList = JSON.stringify(this.state.folioNumberList)
            delete this.props.schemeDetails.multipleScheme
            purchaseData.page = "basket"
            purchaseData.multipleSelect = true
            purchaseData.multipleScheme = replaceNull(data.data.map((item) => {
              return {
                ...item,
                SipFrequency: "MONTHLY",
                Sipdate: moment(this.state.selectedDate, "DD MMM YYYY").format("DD/MM/YYYY")
              }
            }), labels.messages.NA)
            this.props.history.push({
              pathname: labels.Path.Payment,
              state: { data: replaceNull(purchaseData, labels.messages.NA) },
            });
          }
        } else {
          const cartID = uniqueId();

          this.setState({ cartLoader: true })
          let validationMessage = await SchemeCodeFinding(
            finalAmount,
            labels.investmentPopup.keySIP,
            this.props.schemeDetails.ISIN,
            this.props.schemeDetails.PC,
            this.props.logindetails.ClientID, "SIP"
          );
          if (validationMessage.status != "success") {
            this.setState({ cartLoader: false, open: false, color: "warning", message: validationMessage.status, open: true })

          } else {
            this.setState({ tradingLoader: false })
            if (this.props.sipCartData.filter((item) => item.SchemeID == this.props.schemeDetails.SI && item.MutualFundUCC == ucc).length == 0) {
              if (this.props.sipCartData.filter((item) => item.MutualFundUCC == ucc).length >= 10) {

                this.setState({ color: "warning", message: "Maximum Limit Exceed", open: true, cartLoader: false, tradingLoader: false })
              } else {
                let cart = this.props.schemeDetails;
                let cartdata = cart;
                cartdata.ID = Number(cartID);
                cartdata.Instalments = 180;
                cartdata.Amount = Number(finalAmount);
                cartdata.SipFrequency = "MONTHLY";
                cartdata.MutualFundUCC = ucc
                cartdata.Sipdate = moment(this.state.selectedDate, "DD MMM YYYY").format(
                  "DD/MM/YYYY"
                );
                cartdata.FolioList = []
                addtocart(
                  Number(cartID),
                  this.props.schemeDetails.SI,
                  this.props.logindetails.ClientID,
                  Number(finalAmount),
                  cart.AUM,
                  180,
                  false,
                  false,
                  false,
                  false,
                  cart.Ranking,
                  cart.Risk,
                  true,
                  "MONTHLY",
                  moment(this.state.selectedDate, "DD MMM YYYY").format(
                    "DD/MM/YYYY"
                  ),
                  1,
                  false,
                  cart.ratings,
                  cart.AI,
                  cart.AC,
                  cart.ISIN,
                  cart.RI,
                  cart.PC,
                  validationMessage.response.SchemeCode == undefined ? "" : validationMessage.response.SchemeCode,
                  this.state.checked,
                  this.props.logindetails.SessionID,
                  "",
                  ucc, JSON.stringify(this.state.folioNumberList)
                ).then((data) => {
                  if (data != undefined) {
                    // setLoader(false)
                    this.setState({ cartLoader: false })
                    if (data.status == "S") {

                      this.props.GetAllCart(this.props.logindetails.SessionID, this.props.logindetails.ClientID)

                      let dropdownList = labels.Date
                      this.onSipDateChange([dropdownList[0]])
                      this.setState({ amount: "", checked: false, open: true, color: "success", message: labels.sipPopup.addTocart, openFolioPopup: false })


                    } else {
                      this.setState({ cartLoader: false, open: false, color: "warning", message: data.message, open: true })

                    }
                  }
                });
              }
            } else {
              this.setState({ cartLoader: false, open: false, color: "warning", message: labels.sipPopup.alreadyInCart, open: true })
              timer = setTimeout(() => {
                // this.props.history.push(labels.Path.cartSip)
                this.props.history.push({
                  pathname: labels.Path.cartSip,
                  state: { data: this.props.schemeDetails },
                });
              }, 1000);
            }
          }
        }

      } else {
        this.setState({ open: false })

        this.props.openUccPopup()
      }
    }

  };
  finalOneTimeAddToCart = async (ucc) => {
    if (this.props.logindetails.L4_AccountTypeID == 2 && !this.props.userDetails[0].KYCValid && this.props.companyDetails[0].IsKYCAllowed) {
      this.props.openKycPopup()
    } else {
      if (this.props.uccList.length != 0 && this.props.uccList.filter((item) => item.ClientCode == "NA").length != this.props.uccList.length) {

        let finalAmount = this.state.amount.replace(/,/g, "").split("₹")[1];
        this.setState({ cartLoader: true })
        let schemeData = this.props.schemeDetails
        if (this.props.page == "basket") {
          // Networking.GetApi()
          let data = []
          // console.log(this.props.schemeDetails.ID)
          await SplitBasketAmount(finalAmount
            , this.props.schemeDetails?.ID).
            then((response) => {
              let res = replaceNull(response, "NA")
              this.setState({ cartLoader: false })
              let changeData = res
              if (res.status == "S" && res.data != "NA") {
                changeData = res
                changeData.data = res.data?.map((val) => {
                  return { ...val, SchemeName: val.instrument || "NA", Weightage: val.weightage, Amount: val.amount, SchemeId: val.instrumentID }
                })
              } else {
                changeData = []
                this.setState({ open: true, message: labels.messages.apierror, color: "warning" })
              }
              data = changeData
            })
          if (data.length != 0) {
            let purchaseData = this.props.schemeDetails;
            purchaseData.InvesmentType = "One time";
            purchaseData.SipFrequency = "MONTHLY"
            // purchaseData.minimumInstalment = 12
            purchaseData.StepperActive = 0;
            purchaseData.Ucc = ucc
            purchaseData.MutualFundUCC = ucc
            //purchaseData.Sipdate = moment(this.state.selectedDate, "DD MMM YYYY").format("DD/MM/YYYY");
            purchaseData.btnFrequency = 1;
            purchaseData.FolioList = JSON.stringify(this.state.folioNumberList)
            delete this.props.schemeDetails.multipleScheme
            purchaseData.page = "basket";
            purchaseData.multipleSelect = true;
            purchaseData.multipleScheme = replaceNull(data.data, labels.messages.NA)
            this.props.history.push({
              pathname: labels.Path.Payment,
              state: { data: replaceNull(purchaseData, labels.messages.NA) },
            });
          }

        } else {
          let validationMessage = await SchemeCodeFinding(
            finalAmount,
            labels.investmentPopup.keyOneTime,
            this.props.schemeDetails.ISIN,
            this.props.schemeDetails.PC,
            this.props.logindetails.ClientID, "BUY"
          );
          // let schemeData =props.scheme.filter((item)=>item.SchemeID==props.schemeID)[0];
          if (validationMessage.status != "success") {
            this.setState({ cartLoader: false, color: "warning", message: validationMessage.status, open: true })

          } else {
            this.setState({ tradingLoader: false })
            if (this.props.onetimeCartData.filter((item) => item.SchemeID == this.props.schemeDetails.SI && item.MutualFundUCC == ucc).length == 0) {

              if (this.props.onetimeCartData.filter((item) => item.MutualFundUCC == ucc).length >= 10) {
                // setOpen(false);
                this.setState({ color: "warning", message: "Maximum Limit Exceed", open: true, cartLoader: false, tradingLoader: false })

              } else {
                let cartID = uniqueId()
                let cart = this.props.schemeDetails;
                let cartdata = cart;
                cartdata.ID = Number(cartID);
                cartdata.Instalments = 180;
                cartdata.Amount = Number(finalAmount);
                cartdata.SipFrequency = "MONTHLY";
                cartdata.MutualFundUCC = ucc
                cartdata.Sipdate = moment(this.state.selectedDate, "DD MMM YYYY").format(
                  "DD/MM/YYYY"
                );
                cartdata.FolioList = []
                cartdata.BSESchemeType = schemeData.BSESchemeType;

                addtocart(
                  Number(cartID),
                  this.props.schemeDetails.SI,
                  this.props.logindetails.ClientID,
                  Number(finalAmount),
                  cart.AUM,
                  180,
                  false,
                  true,
                  false,
                  false,
                  cart.Ranking,
                  cart.Risk,
                  false,
                  "MONTHLY",
                  "",
                  1,
                  false,
                  cart.ratings,
                  cart.AI,
                  cart.AC,
                  cart.ISIN,
                  cart.RI,
                  cart.PC,
                  validationMessage.response.SchemeCode == undefined ? "" : validationMessage.response.SchemeCode,

                  false,
                  this.props.logindetails.SessionID,
                  "", ucc, JSON.stringify(this.state.folioNumberList), this.props.page == "basket" ? true : false, this.props.page == "basket" ? this.props.list?.ID : 0
                ).then((data) => {
                  if (data != undefined) {
                    this.setState({ cartLoader: false, openFolioPopup: false })
                    if (data.status == "S") {
                      this.props.GetAllCart(this.props.logindetails.SessionID, this.props.logindetails.ClientID)
                      let dropdownList = labels.Date
                      this.setState({ startDate: dropdownList[0]?.label, checked: false, amount: "", open: true, color: "success", message: labels.sipPopup.addTocart })
                    } else {
                      this.setState({ open: true, color: "warning", message: data.message })
                    }
                  }
                }).catch((err) => {
                  // console.log(err)
                });
              }
            } else {
              this.setState({ color: "warning", message: labels.sipPopup.alreadyInCart, cartLoader: false, open: true })
              timer = setTimeout(() => {
                this.props.history.push({
                  pathname: labels.Path.cartOneTime,
                  state: { data: this.props.schemeDetails },
                });
              }, 1000);

            }
          }
        }

      } else {
        this.setState({ open: false })
        this.props.openUccPopup()
      }
    }

  };
  handleCheckbox = () => {
    if (this.state.selectedDate == "") {
      this.setState({ message: "Please select SIP start date", open: true, color: "warning" });
    } else {
      this.setState({ checked: !this.state.checked, message: "" });

      this.dateSetting(
        this.state.dropDownValue
        ,
        !this.state.checked
      );
    }
  };
  onClose = () => {
    this.setState({ openFolioPopup: false })
  }
  handleClose = () => {
    this.setState({ open: false })
  }
  folioContinue = (value) => {
    this.setState({ folioNumber: value })
    if (this.state.flag == "SipContinue") {
      this.finalSipContinue(value);
    } else if (this.state.flag == "OneTimeContinue") {
      this.finalOneTimeContinue(value);
    } else if (this.state.flag == "SipCart") {
      this.finalSipAddToCart(value);
    } else if (this.state.flag == "OneTimeCart") {
      this.finalOneTimeAddToCart(value);
    }
  };
  componentWillUnmount() {
    clearTimeout(timer)
  }
  findDate = (val) => {
    // console.log(val)
    let a = Number(moment().format("DD"))
    // console.log(a)
    let arr = [...Array(6)].map((item, i) => {
      return a + i
    })
    // console.log(arr)
    // console.log(arr.includes(Number(val)))
    return arr.includes(Number(val))

  }
  render() {
    // console.log(this.props.ucc)
    const { classes } = this.props
    return (

      <div style={{ backgroundColor: colors.RosyBrown, height: "100%", gap: 20, padding: "0px 30px", paddingTop: 20, display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "center" }}>

        <div className={classes.header}>{this.props.header}</div>
        <Three_State_Toggle_Switch
          values={['SIP', "One Time"]}
          handleChangeToggle={(val) => { this.toggleChange(val) }}
          selectedFlag={this.state.purchaseType}
        ></Three_State_Toggle_Switch>
        <Textinput className={classes.textInput} handleChange={this.onChangeAmount}
          page="lineTextbox" value={this.state.amount}
          disabled={this.props.disabled}
          label={this.state.purchaseType != "SIP" ? labels.tranactionSchemesDetails.OneTimeTextHeader : labels.tranactionSchemesDetails.SIPHeader}></Textinput>
        {this.props.page != "basket" && <div
          className={classes.amountBadge}
          style={{ padding: "34px 0px !important" }}
        >
          <Chip
            color="secondary"
            style={{ color: window.globalConfig.color.LABLE_GRAY, backgroundColor: "#f8ecdd" }}
            label={this.state.purchaseType != "SIP" ? labels.schemes.fiveThousand : labels.schemes.fiveHundred}
            onClick={() => this.addAmountbtnClk(this.state.purchaseType != "SIP" ? 5000 : 500)}
          />

          <Chip
            color="secondary"
            style={{ color: window.globalConfig.color.LABLE_GRAY, backgroundColor: "#f8ecdd" }}
            label={this.state.purchaseType != "SIP" ? labels.schemes.tenThousand : labels.schemes.thousand}
            onClick={() => this.addAmountbtnClk(this.state.purchaseType != "SIP" ? 10000 : 1000)}
          />

          <Chip
            color="secondary"
            style={{ color: window.globalConfig.color.LABLE_GRAY, backgroundColor: "#f8ecdd" }}
            label={this.state.purchaseType != "SIP" ? labels.schemes.fifteenThousand : labels.schemes.tenThousand}
            onClick={() => this.addAmountbtnClk(this.state.purchaseType != "SIP" ? 15000 : 10000)}
          />
        </div>}
        {this.state.purchaseType == "SIP" && <><div style={{ width: "-webkit-fill-available" }}>
          <Dropdown
            height={135}
            id="tradingSchemeDetails"
            placeholder="Select SIP start date"
            type={"normalwithsearch"}
            value={this.state.startDate}
            // autoselect={true}
            List={labels.Date}
            handleOnChange={this.onSipDateChange}
          />
        </div>
          <div >
            <div>
              <CommonCheckbox
                label={labels.bigPopup.checkBox}
                checked={this.state.checked}
                onChange={this.handleCheckbox}
              />
            </div>
            {this.state.selectedDate != undefined && (
              <div style={{ fontSize: 14, marginTop: '0.7em', marginLeft: 10, textAlign: 'left', display: "flex", flexDirection: "column", gap: 10 }}>
                <span>
                  SIP Start date will be <b> {this.state.selectedDate}</b>
                </span>
                <span>
                  Next installment will be <b> {
                    moment(moment(
                      this.state.selectedDate,
                      "DD MMM YYYY"
                    )
                      .add(1, "M")
                      .toString()
                    ).format("DD MMM YYYY")}</b>
                </span>
              </div>
            )}
            <div>

            </div>
          </div></>}


        <Button page="primary" loader={this.state.tradingLoader}
          disabled={Object.keys(this.props.schemeDetails).length == 0 || this.props.uccList.length == 0 ? true : false}
          name={this.state.purchaseType != "SIP" ? labels.tranactionSchemesDetails.OneTimeButton : labels.tranactionSchemesDetails.SIPButton} onClick={this.onHandleClick}></Button>
        {/* <div style={{ color: colors.BLACK_PRIMARY, fontSize: 14, textDecoration: "underline", cursor: "pointer" }} onClick={this.onClickAddtoCart}>
          {this.state.cartLoader && <div style={{ marginLeft: "10%", position: "absolute" }}><Loader size={20} color={window.globalConfig.color.WHITE}></Loader></div>}
          {labels.tranactionSchemesDetails.addToCart}</div> */}
        {this.props.page != "basket" && <Button page="linkButton" name={labels.tranactionSchemesDetails.addToCart} disabled={Object.keys(this.props.schemeDetails).length == 0 || this.props.uccList.length == 0 ? true : false} loader={this.state.cartLoader} onClick={this.onClickAddtoCart}></Button>}

        <CommonSnackbar
          open={this.state.open}
          handlesnanckClose={this.handleClose}
          severity={this.state.color}
          message={this.state.message}
        />
        <FolioPopup
          onClick={this.folioContinue}
          onClose={this.onClose}
          loader={this.state.folioLoader}
          openFolioPopup={this.state.openFolioPopup}
          folioNumbers={this.state.folioNumberList}
        />

      </div>
    )
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ GetAllCart }, dispatch)
}
const mapStateToProps = state => {
  return {
    logindetails: state.Login.logindetails,
    companyDetails: state.Login.companyDetails,
    scheme: state.Dropdown.dropdowndetails.scheme,
    transactionType: state.Dropdown.dropdowndetails.transactionType,
    uccList: state.ProfileDetails.AllProfileDetails?.uccList != undefined && state.ProfileDetails.AllProfileDetails.length != 0 && state.ProfileDetails.AllProfileDetails?.uccList?.length != 0 ? state.ProfileDetails.AllProfileDetails.uccList != "NA" ? state.ProfileDetails.AllProfileDetails.uccList.filter(
      (ele, ind) =>
        ind ===
        state.ProfileDetails.AllProfileDetails.uccList.findIndex((elem) => elem.ClientCode === ele.ClientCode)
    ).map((item) => {
      return { ...item, "Name": item.ClientCode }
    }) : [] : [],
    userDetails:
      state.ProfileDetails.AllProfileDetails.length != 0
        ? state.ProfileDetails.AllProfileDetails.mainDetails != "NA"
          ? state.ProfileDetails.AllProfileDetails.mainDetails
          : []
        : [],
    sipCartData: state.CartReducer.getAllSipCart == "NA" ? [] : state.CartReducer.getAllSipCart.filter((item) =>
      item.hasOwnProperty("IsBasket") ? item.IsBasket == false : true
    ),
    onetimeCartData: state.CartReducer.getAllOnetimeCart == "NA" ? [] : state.CartReducer.getAllOnetimeCart.filter((item) =>
      item.hasOwnProperty("IsBasket") ? item.IsBasket == false : true
    ),

  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(SideTab)))