import * as types from '../../constants/actionsType'
const initialState = {
    riaQuestionResponseOrder: [],
    isRiaQuestionResponseOrderLoading: false
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RIA_QUESTION_RESPONSE:         
            return {
                ...state,
                riaQuestionResponseOrder: action.payload != null ? action.payload : state.riaQuestionResponseOrder ,
                isRiaQuestionResponseOrderLoading: false
            }
        case types.LOADER_RIA_QUESTION_RESPONSE:
            return {
                ...state,
                isRiaQuestionResponseOrderLoading: true
            }
        default:
            return state
    }
}
export default reducer