import * as types from "../../constants/actionsType";
import { bindActionCreators } from "redux";
import { FormatColorReset } from "@material-ui/icons";
import labels from "../../../utils/constants/labels";

const initialState = {
  SIPtrackerList: [],
  ProfitList: [],
  Portfolioxraylist: [],
  transactionlist: [
    {
      Schemes: [],
      Transaction: [],
      Asset: [],
      Date: "",
    },
  ],
  startdate: "",
  enddate: "",
  AmcList: [],
  AvailableInvest: [],
  Redemptionlist: [],
  typelist: [],
  schemeList: [],
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SIPTRACKER:
      let list = action.list;
      let data = action.data;
      let filter = [];
      let schemes = [];
      if (data.length == 0) {
        list.map((value, index) => {
          schemes.push({ value: index, label: value.label, isChecked: false });
        });
        filter = schemes;
      } else {
        list.map((value, index) => {
          schemes.push({ value: index, label: value.label, isChecked: false });
        });
        data.map((item) => {
          const elementsIndex = schemes.findIndex(
            (element) => element.value == item.value
          );

          let newArray = [...schemes];
          //this.state.amcList=""
          newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            isChecked: !newArray[elementsIndex].isChecked,
          };

          schemes = newArray;
          //
        });
        filter = schemes;
      }
      return {
        ...state,
        SIPtrackerList: filter,
      };

    case types.GET_PROFITLIST:
      let profitlist = action.list;
      let profitdata = action.data;
      let profitfilter = [];
      let profitschemes = [];
      if (profitdata.length == 0) {
        profitlist.map((value, index) => {
          profitschemes.push({
            value: index,
            label: value.label,
            isChecked: false,
          });
        });
        //filter = schemes
        profitfilter = profitschemes;
      } else {
        profitlist.map((value, index) => {
          profitschemes.push({
            value: index,
            label: value.label,
            isChecked: false,
          });
        });
        profitdata.map((item) => {
          const elementsIndex = profitschemes.findIndex(
            (element) => element.value == item.value
          );

          let newArray = [...profitschemes];
          //this.state.amcList=""
          newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            isChecked: !newArray[elementsIndex].isChecked,
          };

          profitschemes = newArray;
          //
        });
        profitfilter = profitschemes;
      }
      //
      return {
        ...state,
        ProfitList: profitfilter,
      };
    case types.GET_PORTFOLIOXRAYLIST:
      let xraylist = action.list;
      let xraydata = action.data;
      let xrayfilter = [];
      let xrayschemes = [];
      //
      if (xraydata.length == 0) {
        xraylist.map((value, index) => {
          xrayfilter.push({
            value: index,
            label: value.label,
            isChecked: false,
          });
        });
        //xrayfilter = xrayschemes
        //xrayfilter = xrayschemes
      } else {
        //
        xraylist.map((value, index) => {
          xrayschemes.push({
            value: index,
            label: value.label,
            isChecked: false,
          });
        });
        //
        xraydata.map((item) => {
          const elementsIndex = xrayschemes.findIndex(
            (element) => element.value == item.value
          );

          let newArray = [...xrayschemes];
          //this.state.amcList=""
          newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            isChecked: !newArray[elementsIndex].isChecked,
          };

          xrayschemes = newArray;
          //
          //
        });
        xrayfilter = xrayschemes;
      }
      //
      return {
        ...state,
        Portfolioxraylist: xrayfilter,
      };
    case types.GET_TRANSACTIONDATALIST:
      let transactionList = action.list;
      let transactionData = action.data;
      let transactionfilter = [];

      let transactionScheme = [];
      let transcationAsset = [];
      let transactionType = [];
      //
      let transactionschemeFilter = [];
      let transactionassetFilter = [];
      let transactiontypeFilter = [];
      //
      if (transactionData[0].Schemes.length == 0) {
        transactionList[0].Schemes.map((value, index) => {
          transactionschemeFilter.push({
            value: index,
            label: value.label,
            isChecked: false,
          });
        });
        //xrayfilter = xrayschemes
      } else {
        //
        transactionList[0].Schemes.map((value, index) => {
          transactionScheme.push({
            value: index,
            label: value.label,
            isChecked: false,
          });
        });
        //
        transactionData[0].Schemes.map((item) => {
          const elementsIndex = transactionScheme.findIndex(
            (element) => element.value == item.value
          );

          let newArray = [...transactionScheme];
          //this.state.amcList=""
          newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            isChecked: !newArray[elementsIndex].isChecked,
          };

          transactionScheme = newArray;
          //
          //
        });
        transactionschemeFilter = transactionScheme;
      }

      if (transactionData[0].Asset.length == 0) {
        transactionList[0].Asset.map((value, index) => {
          transcationAsset.push({
            value: index,
            label: value.label,
            isChecked: false,
          });
        });
        transactionassetFilter = transcationAsset;
        //xrayfilter = xrayschemes
      } else {
        transactionList[0].Asset.map((value, index) => {
          transcationAsset.push({
            value: index,
            label: value.label,
            isChecked: false,
          });
        });
        //
        transactionData[0].Asset.map((item) => {
          const elementsIndex = transcationAsset.findIndex(
            (element) => element.value == item.value
          );

          let newArray = [...transcationAsset];
          //this.state.amcList=""
          newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            isChecked: !newArray[elementsIndex].isChecked,
          };

          transcationAsset = newArray;
          //
          //
        });
        transactionassetFilter = transcationAsset;
      }

      if (transactionData[0].Transaction.length == 0) {
        transactionList[0].Transaction.map((value, index) => {
          transactionType.push({
            value: index,
            label: value.label,
            isChecked: false,
          });
        });
        transactiontypeFilter = transactionType;
        //xrayfilter = xrayschemes
      } else {
        //
        transactionList[0].Transaction.map((value, index) => {
          transactionType.push({
            value: index,
            label: value.label,
            isChecked: false,
          });
        });
        //
        transactionData[0].Transaction.map((item) => {
          const elementsIndex = transactionType.findIndex(
            (element) => element.value == item.value
          );

          let newArray = [...transactionType];
          //this.state.amcList=""
          newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            isChecked: !newArray[elementsIndex].isChecked,
          };

          transactionType = newArray;
          //
          //
        });
        transactiontypeFilter = transactionType;
      }
      //

      transactionfilter = [
        {
          Schemes: transactionschemeFilter,
          Asset: transactionassetFilter,
          Transaction: transactiontypeFilter,
          Date: "",
        },
      ];
      //
      return {
        ...state,
        transactionlist: transactionfilter,
      };

    case types.GET_TRANSACTIONDATALIST_EQUITY:
      let transactionList_equity = action.list;
      let transactionData_equity = action.data;
      let transactionfilter_equity = [];

      let transactionScheme_equity = [];
      let transcationAsset_equity = [];
      let transactionType_equity = [];
      //
      let transactionschemeFilter_equity = [];
      let transactionassetFilter_equity = [];
      let transactiontypeFilter_equity = [];
      //
      if (transactionData_equity[0].Schemes.length == 0) {
        transactionList_equity[0].Schemes.map((value, index) => {
          transactionschemeFilter_equity.push({
            value: index,
            label: value.label,
            isChecked: false,
          });
        });
        //xrayfilter = xrayschemes
      } else {
        //
        transactionList_equity[0].Schemes.map((value, index) => {
          transactionScheme_equity.push({
            value: index,
            label: value.label,
            isChecked: false,
          });
        });
        //
        transactionData_equity[0].Schemes.map((item) => {
          const elementsIndex = transactionScheme_equity.findIndex(
            (element) => element.value == item.value
          );

          let newArray = [...transactionScheme_equity];
          //this.state.amcList=""
          newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            isChecked: !newArray[elementsIndex].isChecked,
          };

          transactionScheme_equity = newArray;
          //
          //
        });
        transactionschemeFilter_equity = transactionScheme_equity;
      }

      if (transactionData_equity[0].Asset.length == 0) {
        transactionList_equity[0].Asset.map((value, index) => {
          transcationAsset_equity.push({
            value: index,
            label: value.label,
            isChecked: false,
          });
        });
        transactionassetFilter_equity = transcationAsset_equity;
        //xrayfilter = xrayschemes
      } else {
        transactionList_equity[0].Asset.map((value, index) => {
          transcationAsset_equity.push({
            value: index,
            label: value.label,
            isChecked: false,
          });
        });
        //
        transactionData_equity[0].Asset.map((item) => {
          const elementsIndex = transcationAsset_equity.findIndex(
            (element) => element.value == item.value
          );

          let newArray = [...transcationAsset_equity];
          //this.state.amcList=""
          newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            isChecked: !newArray[elementsIndex].isChecked,
          };

          transcationAsset_equity = newArray;
          //
          //
        });
        transactionassetFilter_equity = transcationAsset_equity;
      }

      if (transactionData_equity[0].Transaction.length == 0) {
        transactionList_equity[0].Transaction.map((value, index) => {
          transactionType_equity.push({
            value: index,
            label: value.label,
            isChecked: false,
          });
        });
        transactiontypeFilter_equity = transactionType_equity;
        //xrayfilter = xrayschemes
      } else {
        //
        transactionList_equity[0].Transaction.map((value, index) => {
          transactionType_equity.push({
            value: index,
            label: value.label,
            isChecked: false,
          });
        });
        //
        transactionData_equity[0].Transaction.map((item) => {
          const elementsIndex = transactionType_equity.findIndex(
            (element) => element.value == item.value
          );

          let newArray = [...transactionType_equity];
          //this.state.amcList=""
          newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            isChecked: !newArray[elementsIndex].isChecked,
          };

          transactionType_equity = newArray;
          //
          //
        });
        transactiontypeFilter_equity = transactionType_equity;
      }
      //

      transactionfilter_equity = [
        {
          Schemes: transactionschemeFilter_equity,
          Asset: transactionassetFilter_equity,
          Transaction: transactiontypeFilter_equity,
          Date: "",
        },
      ];
      //
      return {
        ...state,
        equity_transactionlist: transactionfilter_equity,
      };

    case types.GET_DATECHANGE:
      return {
        ...state,
        startdate: action.startdate,
        enddate: action.enddate,
      };
    case types.GET_AMC_LIST:

      let originalData = action.list.length != 0 ? action.list : [];
      let selectedData = action.data.length != 0 ? action.data : [];
      let finalData = originalData;
      if (action.flag == "create") {
        finalData = originalData.map((item) => {
          return { ...item, isChecked: false }
        });
      } else if (action.flag == "update") {
        if (selectedData.length != 0) {
          selectedData.map((item) => {
            const elementsIndex = finalData.findIndex(element => element.value == item.value)
            let newArray = [...finalData]
            newArray[elementsIndex] = { ...newArray[elementsIndex], isChecked: !newArray[elementsIndex].isChecked }
            finalData = newArray
          })
        }
      }
      return {
        ...state,
        AmcList: finalData,
      };
    case types.GET_AVAILABLEINVEST:

      let originalInvestData = action.list.length != 0 ? action.list : [];
      let selectedInvestData = action.data.length != 0 ? action.data : [];
      let finalInvestData = originalInvestData;
      if (action.flag == "create") {
        finalInvestData = originalInvestData.map((item) => {
          return { ...item, isChecked: false }
        });
      } else if (action.flag == "update") {
        if (selectedInvestData.length != 0) {
          selectedInvestData.map((item) => {
            const elementsIndex = finalInvestData.findIndex(element => element.value == item.value)
            let newArrayInvest = [...finalInvestData]
            newArrayInvest[elementsIndex] = { ...newArrayInvest[elementsIndex], isChecked: !newArrayInvest[elementsIndex].isChecked }
            finalInvestData = newArrayInvest
          })
        }

      }
      return {
        ...state,
        AvailableInvest: finalInvestData,
      };
    case types.GET_REDEMPTIONLIST:
      let redemptionlist =labels.TradingType;
      let redemptiondata = action.data;
      let redemptionfilter = [];
      let redemptionschemes = [];
      //
      if (redemptiondata.length == 0) {
        redemptionlist.map((value, index) => {
          redemptionschemes.push({
            value: value.value,
            label: value.label,
            isChecked: value.label === "Amount (Rs)" ? true : false,
          });
        });
        redemptionfilter = redemptionschemes;
      } else {
        redemptionlist.map((value, index) => {
          redemptionschemes.push({
            value: value.value,
            label: value.label,
            isChecked: false,
          });
        });
        //
        redemptiondata.map((item) => {
          const elementsIndex = redemptionschemes.findIndex(
            (element) => element.value == item.value
          );

          let newArray = [...redemptionschemes];
          //this.state.amcList=""
          newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            isChecked: !newArray[elementsIndex].isChecked,
          };

          redemptionschemes = newArray;
          //
        });
        redemptionfilter = redemptionschemes;
      }
      //
      return {
        ...state,
        Redemptionlist: redemptionfilter,
      };
    case types.GET_TYPELIST:
      // let typelist = action.list;
      // let typedata = action.data;
      // let typefilter = []
      // let typeschemes = []
      // //
      // if (typedata.length == 0) {
      //     typelist.map((value, index) => {
      //         typeschemes.push({ "value": value.value, "label": value.label, "isChecked": false })
      //     })
      //     typefilter = typeschemes
      // }
      // else {
      //     typelist.map((value, index) => {
      //         typeschemes.push({ "value": value.value, "label": value.label, "isChecked": false })
      //     })
      //     //
      //     typedata.map((item) => {
      //         const elementsIndex = typeschemes.findIndex(element => element.value == item.value)

      //         let newArray = [...typeschemes]
      //         //this.state.amcList=""
      //         newArray[elementsIndex] = { ...newArray[elementsIndex], isChecked: !newArray[elementsIndex].isChecked }

      //         typeschemes = newArray
      //         //
      //     })
      //     typefilter = typeschemes
      // }
      return {
        ...state,
        typelist: action.list,
      };
    case types.GET_SCHEMELIST:
      let swpschemelist = action.list;
      let swpschemedata = action.data;
      let swpschemefilter = [];
      let swpscheme = [];
      //
      // console.log(action.list)
      if (swpschemedata.length == 0) {
        swpschemelist.map((value, index) => {
          swpscheme.push({
            value: value.value,
            label: value.label,
            isChecked: false,
          });
        });
        swpschemefilter = swpscheme;
      } else {
        swpschemelist.map((value, index) => {
          swpscheme.push({
            value: value.value,
            label: value.label,
            isChecked: false,
          });
        });
        //
        swpschemedata.map((item) => {
          const elementsIndex = swpscheme.findIndex(
            (element) => element.value == item.value
          );

          let newArray = [...swpscheme];
          //this.state.amcList=""
          newArray[elementsIndex] = {
            ...newArray[elementsIndex],
            isChecked: !newArray[elementsIndex].isChecked,
          };

          swpscheme = newArray.filter((item) => item.SWPAllowed);
          //
        });
        swpschemefilter = swpscheme;
      }
      return {
        ...state,
        schemeList: swpschemefilter,
      };
    case types.EMPTY_FILTERDATA:
      return {
        ...state,
        SIPtrackerList: [],
        ProfitList: [],
        Portfolioxraylist: [],
        transactionlist: [
          {
            Schemes: [],
            Transaction: [],
            Asset: [],
            Date: "",
          },
        ],
        startdate: "",
        enddate: "",
        AmcList: [],
        AvailableInvest: [],
        Redemptionlist: [],
        typelist: [],
        schemeList: [],
      };
    case types.EMPTY_PORTFOLIOXRAYLIST:
      return {
        ...state,
        Portfolioxraylist: [],
      };
    case types.EMPTY_TRANSACTIONDATALIST:
      return {
        ...state,
        transactionlist: [],
      };
    default:
      return state;
  }
};
export default reducer;
