import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import div from '@material-ui/core/div';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Textinput from '../../components/textinput/textinput';
import Dropdown from '../../components/dropdown/dropdownschemes';
import Datepicker from '../../components/datepickers/date_pickers';
import Toggle from "../../components/toggle/toggle";
import Checkbox from '../../components/checkbox/checkbox';
import Autocomplete from '../../components/autocomplete/autocomplete';
import Label from '../../utils/constants/labels';
import Buttons from '../../components/custombutton/button';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment';
import Question from "../../utils/assets/images/img5.png";
import color from "../../utils/colors/colors";
import CommonCheckbox from '../../components/checkbox/checkbox';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import Text from '../../components/text/text';
import ArrowIcon from "../../utils/assets/images/img14.png";
import labels from '../../utils/constants/labels';
import InformationTooltip from '../../components/tooltip/informationtooltip';
import colors from '../../utils/colors/colors';
import { NegativeFinding } from '../../utils/commonfunction';
import { scrollToTopPage } from '../../utils/commonfunction';
const styles = theme => ({

  root: {
    // marginLeft: 29,
    // marginTop: 20,
    // float: "left",
    fontWeight: "bolder",
    textAlign: "left"
  },
  container: {
    // width: '101%',
    // marginLeft: 9,
    //marginRight: -20
  },
  divLine1: {
    height: "0.5px",
    width: "100%",
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    marginTop: "6%",
    // marginBottom: "-4%",
    // boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.3)",
  },
  btnApply: {
    background: window.globalConfig.color.BROWN,
    marginLeft: "7px",
    padding: 0,
    lineHeight: "36px",
    color: window.globalConfig.color.WHITE,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    // float: "right",
    borderRadius: "5px",
    border: "1px solid transparent",
    // width: "22%",
    paddingTop: 5,
    paddingBottom: 5,
    '&:hover': {
      background: window.globalConfig.color.PRIMARY,
      color: window.globalConfig.color.WHITE,
    }
  },
  btnBlock: {
    marginTop: "8%",
    marginLeft: "2.5%",
    marginRight: 20
  },
  label: {
    paddingBottom: 6,
    fontWeight: "normal",
    display: "block",
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: "14px",
    textAlign: "left"
  },

  label1: {
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: 14,
  },
  autoErrorMsg: {
    color: window.globalConfig.color.red,
    fontSize: 14,
    // float: 'left',
    textAlign: "left",
    marginLeft: 0,
    marginTop: '1%'
  },
  checkBox: {
    fontSize: 12,
    marginLeft: -235,
  },
  dropDown: {
    marginTop: 10,
    // marginRight: 27,
    [theme.breakpoints.between("1000", "1050")]: {
      marginRight: 35,
    },
  },
  contentBlock: {
    // width: "93.8%",
    backgroundColor: window.globalConfig.color.RosyBrown,
    padding: 10,
    gap: 10,
    height: "fit-content",
    paddingTop: 15,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "space-between",

    [theme.breakpoints.down("920")]: {
      flexDirection: "column",
    },
    // [theme.breakpoints.down("920")]: {
    //   width: "85.8%",
    // },
    [theme.breakpoints.down("600")]: {
      flexDirection: "column"
    },
    // [theme.breakpoints.down("375")]: {
    //   width: "85.8%",
    // },
  },
  folioBlock: {
    padding: 2,
    //paddingRight: 15,
    //paddingLeft: 15,
    backgroundColor: window.globalConfig.color.BORDER,
    borderRadius: 100,
    minWidth: '30%',
    fontWeight: 400,
    fontSize: 13,
    textTransform: "none !important",
    [theme.breakpoints.between("1000", "1050")]: {
      minWidth: "52%",
    },
    [theme.breakpoints.down("920")]: {
      minWidth: '100%',
    },

  },
  Balance: {
    width: '100%',
    display: 'inline-flex',
    padding: 10
  },
  BalanceBlock: {
    width: '50%',
    // float: 'left'
  },
  subContentBlock: {
    textAlign: 'left',
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: '14px',
    marginTop: 5, marginBottom: 5
  },
  confirmButton: {
    // display: "flex",
    // justifyContent: "flex-end",
    marginTop: "4%",
    // marginRight: "2.5%",
    float: "right",
    // marginLeft: "3%",
    width: "20%",
    // 
    // [theme.breakpoints.up("920")]: { float: 'right' },
    [theme.breakpoints.between("1000", "1050")]: {
      width: "50%",
    },
    [theme.breakpoints.down("920")]: {
      width: "94%",
      marginLeft: 0
    }
  },
  paddingTopAlignment: {
    paddingTop: '1em',
    [theme.breakpoints.down("920")]: {
      paddingTop: '0em',
    }
  },

  //Added by Celsia on 3 Jun 2021 for Mobile Responsive Started
  desktopResponsive: {
    display: 'block !important',
    padding: "1rem"
    // [theme.breakpoints.down("600")]: {
    //   display: 'none !important',
    // },
  },
  mobileResponsive: {
    display: 'none !important',
    [theme.breakpoints.down("600")]: {
      display: 'block!important',
    },
  },
  headerResponsive: {
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontSize: "15px",
    textAlign: "left",
    padding: "2%",
    fontWeight: "bold",
    marginLeft: -18
  },
  containerResponsive: {
    margin: 10,
    width: "100%"
  },
  autoErrorMsg1: {
    color: window.globalConfig.color.red,
    fontSize: 14,
    // float: 'left',
    marginLeft: 10
  },
  autoErrorMsg1Responsive: {
    color: window.globalConfig.color.red,
    fontSize: 14,
    // float: 'left',
  },
  textInputResponsive: {
    paddingLeft: "10px",
    height: "38px",
    width: "97%",
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    color: window.globalConfig.color.black,
    outline: "none !important",
    fontSize: "14px",
    '&:hover': {
      border: `1px solid ${window.globalConfig.color.BROWN}`,
    },
  },
  contentBlockResponsive: {
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    padding: 10,
    height: 30
  },
  dateWidth: {
    // width: "93%",
    height: 30, textAlign: "left", border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    justifyContent: "space-between", paddingTop: "3%", paddingLeft: "2%",
    paddingRight: "3%",
    [theme.breakpoints.between("1000", "1050")]: {
      paddingTop: "5%",
      marginTop: "1%"
    },
  },
  stpScheme: {
    // width: "96.4%",
    // [theme.breakpoints.between("1000", "1050")]: {
    //   width: "103%",
    // },
    // [theme.breakpoints.down("920")]: {
    //   width: "92%",
    // },

  },
  autoCompleteInput: {
    textAlign: "left"
    // marginRight: "4%",
    // [theme.breakpoints.between("1000", "1050")]: {
    //   marginRight: 30,
    // },

    // [theme.breakpoints.down("920")]: {
    //   marginRight: 32,
    // },
  },
  flexContainer: {
    display: "grid",
    gridTemplateColumns: "48% 48%",
    gap: 20,
    // flexDirection: "row",
    marginTop: "4%",
    [theme.breakpoints.down("920")]: {
      // flexDirection: "column",
      // marginTop: 0
      display: "block",
      marginTop: "4%"
    },
    [theme.breakpoints.down("600")]: {
      // flexDirection: "column",
      // marginTop: 0
      display: "block",
      marginTop: "2%"
    },
  },

  folioBlockResponsive: {
    // float: 'left',
    padding: 2,
    paddingRight: 15,
    paddingLeft: 15,
    backgroundColor: window.globalConfig.color.BORDER,
    borderRadius: 100,
    width: "100%",
    textTransform: "none !important"
  },
  btnApplyResponsive: {
    background: window.globalConfig.color.BROWN,
    marginLeft: "7px",
    padding: 0,
    lineHeight: "36px",
    color: window.globalConfig.color.WHITE,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    width: "100%",
    // float: "right",
    borderRadius: "5px",
    border: "1px solid transparent",
    width: "22%",
    '&:hover': {
      background: window.globalConfig.color.PRIMARY,
      color: window.globalConfig.color.WHITE,
    }
  },
  dropDownResponsive: {
    marginTop: 10,
    marginRight: 20,
  },
  stpInstalment: {
    // width: "45.3%",
    // [theme.breakpoints.down("920")]: {
    //   width: "93%",
    //   marginTop: "2%"
    // },
    // [theme.breakpoints.down("600")]: {
    //   width: "93%",
    //   marginTop: "4%"
    // },
    // [theme.breakpoints.between("1300", "1500")]: {
    //   width: "100%",
    // },
  },
  dropDownResponsive1: {
    marginTop: 10,
    [theme.breakpoints.up(920)]: {
      display: "none"
    }
  },
  confirmButton1: {
    display: "flex",
    justifyContent: "center",
    marginTop: "8%",
    marginRight: "4%",
    marginLeft: "4%",

  },
  firstWidth: {
    // width: "47%", 
    // marginRight: "4%",
    // [theme.breakpoints.down("920")]: {
    //   width: "93%",
    //   marginTop: "2%"
    // },
    // [theme.breakpoints.down("375")]: {
    //   width: "93%",
    //   marginTop: "8%"
    // },
    // [theme.breakpoints.down("600")]: {
    //   width: "93%",
    //   marginTop: "10%"
    // },
  },
  stpDate: {
    // width: "47%", marginRight: "4%",
    // [theme.breakpoints.down("920")]: {
    //   width: "93%",
    //   marginTop: "2%"
    // },

  },
  switchButton: {
    display: "flex", flexDirection: "row", justifyContent: "space-between"
  },
  secondWidth: {
    width: "50%",
    [theme.breakpoints.down("920")]: {
      width: "90%",
    },
  },
  folioDropdownAndUnits: {
    display: "grid", gridTemplateColumns: "30% 70%", gap: 10, alignItems: "center", width: "-webkit-fill-available"
    , [theme.breakpoints.down("700")]: {
      gridTemplateColumns: "100%",
      alignItems: "flex-start",

    }
  },
  totalUnits: {
    display: "flex", flexDirection: "row", gap: 10, alignItems: "center",
    [theme.breakpoints.down("700")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    }
  }
  //Ended
});
let status = ""
class STPDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      showOption: false,
      anchorEl: false,
      setanchorEl: false
    };
  }
  componentDidMount() {
    scrollToTopPage();
  }
  onConfirmOrder = () => {
    this.props.confirmOrder(true);
  }
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handledateClick = (e) => {
    this.setState({
      setanchorEl: e.currentTarget,

    })
  }
  handleApply = () => {
    this.setState({
      setanchorEl: null,
    })
  }
  handledateClose = () => {
    this.setState({
      setanchorEl: false,
    })
  }
  handleClose = () => {
    this.setState({ anchorEl: false })
  }
  handlefolio = (item) => {
    this.props.handleClickfolio(item)
    this.setState({ anchorEl: false })
  }
  render() {
    //
    const { classes } = this.props;
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    // 
    return (<ErrorBoundary>
      <div className={classes.desktopResponsive}>

        <div className={classes.container}>
          <div className={classes.switchButton}>
            <div className={classes.root}>
              {Label.STP.stpDetails}
              <InformationTooltip title={Label.STP.stpDetails} Icon={<img src={Question} className={classes.image} style={{ marginLeft: 7 }}></img>}>

              </InformationTooltip>
            </div>
            <Toggle
              label1={"AMC"}
              label2={"BSE"}
              selected={this.props.selected}
              toggleSelected={this.props.toggleChange}
            />
          </div>

          <div className={classes.dropDown}>
            <div className={classes.stpScheme}>
              <Dropdown type={"normalwithsearch"}
                // placeholder={this.props.placeholder} 
                label={Label.STP.stpof}
                id={"STP_one"}
                autoselect={false}
                value={this.props.STPscheme}
                List={this.props.schemeList}
                placeholder={"Select Schemes"}
                error={this.props.errorSTPmessage}
                handleOnChange={this.props.onchangeSTP}
              />
            </div>
            {/* <div> */}
            <div className={classes.contentBlock}>
              <div className={classes.folioDropdownAndUnits} >
                <Button onClick={this.handleClick} className={classes.folioBlock}>
                  {Label.STP.Folio}: {this.props.folionumber == "" ? "NA" : this.props.folionumber}<img src={ArrowIcon} style={{ marginLeft: '5%' }}></img>
                </Button>

                {this.props.folioList != undefined && this.props.folioList.length != 0 &&

                  <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.handleClose}
                  >
                    {this.props.folioList.map((item) => {
                      return <MenuItem onClick={() => this.handlefolio(item)}>{Label.STP.Folio}: {item.FolioNo}</MenuItem>

                    })}
                  </Menu>}
                {/* <div className={classes.totalUnits} >
                  <p style={{ fontSize: 14, paddingRight: 14 }}>Total Amount :<span> {this.props.totalTable.length != 0 && this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber).length != 0 ? <>{NegativeFinding(this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber)[0].TotalAvailableAmount, "", "", "", "rupee")}</> : `${labels.currency.rupeesymbol}0`}</span></p>
                  <p style={{ fontSize: 14 }}>Total Units :<span> {this.props.totalTable.length != 0 && this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber).length != 0 ? <> {this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber)[0].TotalAvailableUnits}</> : `0`}</span></p>
                  <p style={{ fontSize: 14 }}>UCC :<span> {this.props.UCC}</span></p>
                </div>
              </div>
              {this.props.UCC == "" && <div style={{ fontSize: 12, color: colors.red }}>BSE star UCC is not mapped to this Folio</div>}

            </div> */}
                {this.props.UCC != "" ? <p style={{ fontSize: 14,textAlign:"left" }}>UCC :<span> {this.props.UCC || "NA"}</span></p> : <div style={{ fontSize: 12, color: colors.red,textAlign:"left" }}>BSE star UCC is not mapped to this Folio</div>}
              </div>
              <div className={classes.totalUnits}>
                <p style={{ fontSize: 14, paddingRight: 14 }}>Total Amount :<span> {this.props.totalTable.length != 0 && this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber).length != 0 ? <>{NegativeFinding(this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber)[0].TotalAvailableAmount, "", "", "", "rupee")}</> : `${labels.currency.rupeesymbol}0`}</span></p>
                <p style={{ fontSize: 14 }}>Total Units :<span> {this.props.totalTable.length != 0 && this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber).length != 0 ? <> {NegativeFinding(this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber)[0].TotalAvailableUnits, "", "", "", "number")}</> : `0`}</span></p>
              </div>
            </div>

          </div>
          <div className={classes.autoCompleteInput}>
            <Autocomplete
              label="Scheme to STP to"
              placeholder="Select Schemes"
              default={true}
              value={this.props.scheme}
              options={this.props.toSchemeList}
              selectvalue={(val) => this.props.onchangeScheme(val)}
              onChange={(e) => { }}
            />
            <label className={classes.autoErrorMsg} >{this.props.errorschememessage}</label>
          </div>

          <div className={classes.flexContainer}>
            <div className={classes.firstWidth} >
              <Dropdown type={"normalwithsearch"}
                placeholder={"Select"}
                label={Label.STP.stpType}
                id={"STP_two"}
                autoselect={true}
                error={this.props.errortypemessage}
                // values={this.props.schemevalue}
                List={labels.SWPtype}
                handleOnChange={this.props.onchangeSTPtype}
              />
            </div>
            <div className={classes.stpInstalment}>
              <Textinput error={this.props.erroramountmessage} message={Label.STP.minimumAmount} page={"normal"} label={Label.STP.amount} value={this.props.amount} handleChange={this.props.Amountchange} placeholder={"₹ Enter amount"} />
            </div>


          </div>
          <div className={classes.flexContainer}>

            <div className={classes.stpDate}>
              <Dropdown List={this.props.dateList}
                type={"normalwithsearch"}
                placeholder={"Select"}
                label={Label.STP.stpstart}
                autoselect={true}
                id={"STP_three"} handleOnChange={this.props.handleStartDateChange}></Dropdown>

              <div className={classes.paddingTopAlignment}>
                <CommonCheckbox label={labels.checkBox.firstOrderToday} onChange={this.props.firstorder} checked={this.props.check} />
              </div>
              {this.props.selecteddate != "" &&
                <div style={{ fontSize: 14, marginTop: '0.7em', textAlign: 'left', display: "flex", flexDirection: "column", gap: 10 }}>
                  <span>
                    STP Start date will be <b> {this.props.selecteddate}</b>
                  </span>
                  <span>
                    Next installment will be <b> {
                      moment(moment(
                        this.props.selecteddate,
                        "DD MMM YYYY"
                      )
                        .add(1, this.props.stpType)
                        .toString()
                      ).format("DD MMM YYYY")}</b>
                  </span>
                </div>
                // <div style={{ fontSize: 14, marginTop: '0.7em', textAlign: 'left' }}>{Label.STP.STPStartDate} <span style={{ fontSize: 14, fontWeight: 600 }}>{moment(
                //   moment(
                //     this.props.selecteddate,
                //     "DD MMM YYYY"
                //   )
                //     .add(1, "months")
                //     .toString()
                // ).format("DD MMM YYYY")}
                // </span></div>
              }
            </div>
            <div className={classes.stpInstalment}>
              <Textinput page={"normal"} error={this.props.errorinstalmentmessage} message={`Minimum Instalment ${this.props.minimumInstalment || 0}`} label={Label.STP.instalment} value={this.props.instalment} handleChange={this.props.instalmentchange} placeholder={"Enter No Of Instalments"} />
            </div>

          </div>

        </div>
        <div className={classes.divLine1}></div>
        <div className={classes.confirmButton}>
          <Buttons name={"Next"} page={"primary"} disabled={this.props.loader || this.props.UCC == "" ? true : false} loader={this.props.loader} onClick={() => this.props.confirmOrder(true)} className={classes.btnApply} />
        </div>
      </div>

    </ErrorBoundary>);
  }
}
const mapStateToProps = state => {
  //
  return {
    typelist: state.Filter.typelist,
    schemeList: state.PortfolioXray.XrayData.filter((item) => item.STPAllowed === true)
      .sort(function (a, b) {
        var textA = a.Scheme.toUpperCase();
        var textB = b.Scheme.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
      .map((val) => {
        return { label: val.Scheme, value: val.SchemeID, isChecked: false };
      }),
    FullschemeList: state.Dropdown.dropdowndetails.scheme != null ? state.Dropdown.dropdowndetails.scheme.length != 0 ? state.Dropdown.dropdowndetails.scheme.sort(function (a, b) {
      var textA = a.SN.toUpperCase();
      var textB = b.SN.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    }).map((val) => {
      return { "label": val.SN, "value": val.SI, "data": val }
    }) : [] : [],
    logindetails: state.Login.logindetails
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(STPDetails)))