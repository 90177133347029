import React, { Component } from 'react';
import labels from '../../utils/constants/labels';
import '../../utils/stylesheets/common/global/filters/categoryfilter.css';
import SchemeDropdown from '../../components/dropdown/dropdownschemes'
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Datepicker from '../datepickers/date_pickers';
import moment from 'moment';
const styles = theme => ({
    mobileResponseDate: {
        [theme.breakpoints.down(600)]: {
            width: "100%",
        }
    },
    btnAlignment: {
        display: "grid",
        gridTemplateColumns: "39% 28% 27%",
        gap: 10
    },
    calendercontainer: {
        maxHeight: '570px',
        [theme.breakpoints.down(600)]: {
            maxHeight: '440px',
        }
    }
})
class MaturityFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,

        }
    }



    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };
    handleClose = () => {
        this.setState({ anchorEl: null });
    };


    render() {
       
        return (
            <div style={{marginBottom:10}}>
                <SchemeDropdown
                    type="Searchable"
                    handleOnChange={this.props.handleStatusChange}
                    id={1}
                    List={this.props.statusList}
                    label={labels.maturityFilter.status}
                    placeholder={labels.maturityFilter.all}
                    isSearchable={false}
                    showborder={true}
                    filter={"close"} />
                <SchemeDropdown
                    type="Searchable"
                    id={2}
                    handleOnChange={this.props.handleIssuerNameChange}
                    List={this.props.issuerList}
                    label={labels.maturityFilter.issuerName}
                    placeholder={labels.maturityFilter.all}
                    isSearchable={false}
                    showborder={true}
                    filter={"close"}
                />
                <Datepicker
                    id={"startdate"}
                    dateChange={this.props.startDateChange}
                    handleClose={this.handleClose}
                    label={"Start Date"}
                    // allowed={true}
                    showingdate={this.props.startDate}
                    // showingdate={moment().format("DD MMM YYYY")}
                    placeholder={"Select Start Date"}
                    date={this.props.startDate}

                />
                <Datepicker
                    id={"maturitydate"}
                    dateChange={this.props.maturityDateChange}
                    handleClose={this.handleClose}
                    label={"Maturity Date"}
                    showingdate={this.props.maturityDate}
                    placeholder={"Select Maturity Date"}
                    date={this.props.maturityDate}

                />

            </div>
        )
    }
}
const mapStateToProps = state => ({
})
export default withStyles(styles)(connect(mapStateToProps)(MaturityFilter))
