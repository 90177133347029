// common card created by yogitha on 23/07/21
import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import labels from '../../utils/constants/labels';
import Button from '../../components/custombutton/button';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import CheckIcon from '@material-ui/icons/Check';
import Capture from "../../utils/assets/images/capture.png";
import WarningIcon from '@material-ui/icons/Warning';
import { withRouter } from "react-router";
import colors from "../../utils/colors/colors";
import { bindActionCreators } from 'redux';
// import getTrackEquityImported from '../../redux/actions/track/equitytrack_import_action';
import CustomizedSteppers from "../../containers/epf/stepper";
import camsFamilyHolding from "../../api/tradeupload/camsstep/cams_familyholding";
import camsComplete from "../../api/tradeupload/camsstep/cams_complete";
import camsEtlProcess from "../../api/tradeupload/camsstep/cams_etlprocess";
import camsHistoric from "../../api/tradeupload/camsstep/cams_historic";
import camsHoldings from "../../api/tradeupload/camsstep/cams_holdings";
import cdslEtlProcessBonds from "../../api/tradeupload/cdslstep/cdsl_etlprocess_bonds";
import cdslHoldingsBonds from "../../api/tradeupload/cdslstep/cdsl_holdings_bonds";
import cdslGetDailyBonds from "../../api/tradeupload/cdslstep/cdsl_getdaily_bonds";
import cdslFifoCalculationBonds from "../../api/tradeupload/cdslstep/cdsl_fifo_calculation_bonds";
import nsdlEtProcess from "../../api/tradeupload/nsdlstep/nsdl_etprocess";
import nsdlFifoCalculation from "../../api/tradeupload/nsdlstep/nsdl_fifocalculation";
import nsdlGetDailyEquity from "../../api/tradeupload/nsdlstep/nsdl_getdailyequity";
import nsdlHoldingsEquity from "../../api/tradeupload/nsdlstep/nsdl_holdingsequity";
import allProductsXirr from "../../api/tradeupload/allproductxirr";
import allProductsEtlprocess from "../../api/tradeupload/allproducts_etlprocess";
import Subheader from "../subheader/subheader";
import getL5report from "../../api/L5report/L5report";
import { getL5reportDetails, storeL5Report } from "../../redux/actions/L5Report/L5ReportAction";
import updateProcessStatus from "../../api/tradeupload/updateprocessstatus";
import { clientIDchange } from "../../redux/actions/login/loginaction";
import Dialog from "../dialog/dialog";
import OutlinedButtons from "../payment/button";
const styles = theme => ({
    root: {
        backgroundColor: window.globalConfig.color.WHITE,
        display: "grid",
        gridTemplateColumns: "10% 80% 10%",
        alignItems: "center",
        // marginTop: 70
    },
    fontWeightHeader: {
        fontWeight: "normal"
    },
    card: {
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        borderRadius: '5px',
        // width: '13%',
        justifyContent: 'flex-start',
        margin: '2% 42%',
        padding: '2% 2% 2% 2%',
        gap: '20px',
        display: 'grid',
        gridTemplateColumns: '23px 147px',
        cursor: 'pointer',
        [theme.breakpoints.down("md")]: {
            // width: '18%',
            margin: '4% 40%'
        },
        [theme.breakpoints.down("sm")]: {
            // width: '23%',
            margin: '4% 37%'
        },
        [theme.breakpoints.down("xs")]: {
            // width: '42%',
            margin: '6% 26%',
            padding: '3% 3% 3% 3%',
        },
    },

    TopAlignment: {
        marginTop: '3%',
        fontSize: '1.17em',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        fontWeight: 'bold',
    },
    footerLine: {
        display: 'grid',
        gridTemplateColumns: "repeat(2,50%)",
        margin: '3% 20% 3% 22%',
        gap: '5%',
        [theme.breakpoints.down("sm")]: {
            margin: '3% 15%',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down("xs")]: {
            margin: '3% 0%',
            gridTemplateColumns: "repeat(1,100%)",
        },
    },
    footerLinEquity: {
        margin: '3% 20% 3% 20%',
        display: 'grid',
        /* grid-template-columns: repeat(2,50%); */
        justifyContent: 'center',
        [theme.breakpoints.down("sm")]: {
            margin: '3% 15%',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down("xs")]: {
            margin: '3% 0%',
        },
    },
    btnButtonText: {
        color: window.globalConfig.color.BROWN,
        fontWeight: "normal",
        textAlign: 'right',
        cursor: "pointer",
        // textIndent:'23em',
        [theme.breakpoints.down("xs")]: {
            textAlign: 'center',
        },
    },
    btntrack: {
        width: "150px",
        marginTop: '10px',
        [theme.breakpoints.down("xs")]: {
            // margin: '1% 33% 0% 30%',
            margin: '0% 0% 0% 2%',

        },
    },
    popupBodyContainer: {
        // padding: '5% 0% 0% 0%',
        margin: "0% 0% 0% 4%",
        [theme.breakpoints.down("xs")]: {
            // padding: '3% 0%',
        },
    },
    headingAlignment: {
        margin: "0% 15% 0% 0%",
        padding: '5% 0% 0% 0%',
        [theme.breakpoints.down("xs")]: {
            padding: '3% 0% 0% 0%',
        },
    },
    sucessPopupBodyContainer: {
        padding: '10% 0%',
        overflow: 'hidden',
        [theme.breakpoints.down("xs")]: {
            width: '270px',
        },
    },
    footerPopup: {
        display: 'flex',
        justifyContent: "center",
        margin: '5% 0%',
        padding: '0px 16px 0px 4px',
        // [theme.breakpoints.down("xs")]: {
        //     padding: '0% 3% 2% 2%',
        // }
    },
    footerSuccessPopup: {
        display: 'flex',
        justifyContent: "center",
        margin: '0% 7%'
    },
    btnUploadPopup: {
        margin: '6% 21% 6% 0%',
        [theme.breakpoints.down("xs")]: {
            margin: '6% 3% 6% 0%',
        }
    },
    imagecenter: {
        marginLeft: '40%',
        marginRight: '40%',
        [theme.breakpoints.down("xs")]: {
            marginLeft: '36%',
            marginRight: '36%',
        },
    },
    popupContentcenter: {
        margin: '2% 22%',
        [theme.breakpoints.down("xs")]: {
            margin: '2% 19%',
        },
    },
    popupSuccessContentcenter: {
        margin: '5% 3%',
        width: '300px',
        [theme.breakpoints.down("xs")]: {
            margin: '2% 3%',
        },
    },
    dialogPopup: {
        "& .MuiDialog-paperWidthXs": {
            maxWidth: "320px !important",
            width: "320px",
        },
    },
    popupWidth: {
        width: '320px',
    },

    flexRow: {
        // display: 'flex',
        // flexDirection: 'row',
        display: 'grid',
        gridTemplateColumns: 'repeat(2,43%)',
        margin: '2% 0%',
        // columnGap: '5%',
        [theme.breakpoints.down("xs")]: {
            display: 'grid',
            columnGap: '5%',
            gridTemplateColumns: 'repeat(2,43%)',
        }
    },
    fileNameDiv: {
        display: 'flex',
        flexWrap: 'wrap',
        // width: '45%',
        // wordBreak: 'break-all',
    },
    fileDiv: {
        width: '100px',
        padding: '8% 0% 1% 0%',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down("350")]: {
            whiteSpace: 'revert',
            padding: 0,
            margin: '10px 0px 0px -6px',
        },
    },
    errorBoxWidth: {
        width: '291px',
        [theme.breakpoints.down("600")]: {
            width: '281px',
        },
        [theme.breakpoints.down("350")]: {
            width: '231px',
        }
    },

    popupheader: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: window.globalConfig.color.WHITE,
        height: 50,
        fontWeight: 500,
        position: "sticky",
        top: "0px"
    },
    txtHeader: {
        padding: 15,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        [theme.breakpoints.down("600")]: {
            padding: '15px 20px 15px 15px',
            justifyContent: "revert",
        },
        [theme.breakpoints.down("600")]: {
            padding: '15px 20px 15px 15px',
            justifyContent: 'revert',
        },
    },
    closeIcon: {
        marginTop: -24,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 35,
        width: 35,
        borderRadius: 17,
        cursor: "pointer",
        padding: 0,
        backgroundColor: window.globalConfig.color.BORDER
    },
    clearIcon: {
        cursor: "pointer",
        padding: 0,
        [theme.breakpoints.down("600")]: {
            padding: '0% 0% 0% 30%',
        },
        [theme.breakpoints.down("350")]: {
            padding: '0% 0% 0% 13% !important',

        },
    },
    commonBadgeStyle: {
        width: 25,
        height: 25,
        borderRadius: 25,
        color: colors.WHITE,
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 10,
        // backgroundColor: colors.PRIMARY_BORDER
    },
    h2design: {
        fontSize: '1.5em',
        marginlockStart: '0.83em',
        marginBlockEnd: '0.83em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        fontWeight: 'bold',
    },
    instructionLine: {
        marginBottom: 5,
        marginTop: 11,
        color: window.globalConfig.color.LABLE_GRAY
    }
}
)
let clientDetails = {}
class UploadCamsAndNsdl extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openDialogPopup:false,
            activeStep: 0,
            processStep: [],
            stepOne: false,
            stepTwo: false,
            stepThree: false,
            stepFour: false,
            status: [],
            steps: 0,
            subSteps: "",
            header: "", description: "",
            subProcessStep: [],
            enableTrack: true
        }
    }

    componentDidMount() {
        const { classes } = this.props
        clientDetails = this.props.location.state.clientDetails != "NA" ? this.props.location.state.clientDetails : { clientID: 0, batchNo: "" }
        this.uploadCamsAndNsdl()
        if (window.location.pathname.includes("/cams")) {
            this.setState({ header: "CAMS", description: labels.Transact.camsContent, processStep: [{ name: "Validating data" }, { name: "Transforming data" }, { name: "Analysing data" }, { name: "Generating reports" }, { name: "Family Daily Holding" }, { name: "Finalizing data" }, { name: "Completing" }] })
        } else if (window.location.pathname.includes("/nsdl")) {
            this.setState({
                header: "NSDL", description: labels.Transact.nsdlContent, processStep: [{ name: "Validating data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.BROWN }}>1</div> }, { name: "Transforming data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>2</div> }, { name: "Analysing data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>3</div> }, { name: "Generating reports for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>4</div> }, { name: "Validating data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>5</div> },],
                subProcessStep: [{ name: "Transforming data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>6</div> }, { name: "Analysing data for other asset", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>7</div> }, { name: "Generating reports for other asset", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>8</div> }, { name: "Finalizing data", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>9</div> }, { name: "Completing", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>10</div> }]
            })
        } else {
            this.setState({
                header: "CDSL", description: labels.Transact.cdslContent, processStep: [{ name: "Validating data for other asset", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.BROWN }}>1</div> }, { name: "Transforming data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>2</div> }, { name: "Analysing data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>3</div> }, { name: "Generating reports for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>4</div> }, { name: "Validating data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>5</div> },],
                subProcessStep: [{ name: "Transforming data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>6</div> }, { name: "Analysing data for other asset", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>7</div> }, { name: "Generating reports for other asset", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>8</div> }, { name: "Finalizing data", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>9</div> }, { name: "Completing", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>10</div> }]
            })

        }
    }
    componentDidUpdate(prev, prevState) {
        const { steps, subSteps } = this.state
        if (prevState.steps != steps && prevState.steps < steps || prevState.subSteps != subSteps) {
            this.uploadCamsAndNsdl()
        }
    }
    uploadCamsAndNsdl = () => {
        switch (this.state.steps) {
            case 0:
                if (window.location.pathname.includes("/cams")) {
                    this.camsHistoryProcess()
                } else {
                    this.nsdlFifoCalculationProcess()
                }
                break;
            case 1:
                if (window.location.pathname.includes("/cams")) {
                    this.camsHoldingProcess()
                } else {
                    this.nsdlHoldingsEquityProcess()
                }
                break;
            case 2:
                if (window.location.pathname.includes("/cams")) {
                    this.camsCompleteProcess()
                } else {
                    this.nsdlGetDailyEquityProcess()
                }
                break;
            case 3:
                if (window.location.pathname.includes("/cams")) {
                    this.camsETProcess()
                } else {
                    this.nsdlEtProcess()
                }
                break;
            case 4:
                if (window.location.pathname.includes("/cams")) {
                    this.camsFamilyHoldingProcess()
                } else {
                    this.cdslFifoCalculationBondsProcess()
                }

                break;
            case 5:
                // if (window.location.pathname.includes("/cams")) {
                //     this.a()
                // } else {
                this.allProductXirr()
                // }

                break;
            case 6:
                this.allProductETLProcess()
                break;

            default:
                break;
        }
        switch (this.state.subSteps) {
            // case 0:
            //     this.cdslFifoCalculationBondsProcess()
            //     break;
            case 0:
                this.cdslHoldingProcess()
                break;
            case 1:
                this.cdslGetDailyBondsProcess()
                break;
            case 2:
                this.cdslETProcess()
                break;
            case 3:
                this.allProductXirr()
                break;
            case 4:
                this.allProductETLProcess()
                break;
            default:
                break;
        }

    }
    camsCompleteProcess = () => {
        let data = []
        this.setState({ stepThree: true })
        camsComplete(clientDetails.clientID, clientDetails.batchNo).then((res) => {
            this.setState({ stepThree: false })
            if (res.status == "S") {
                this.setState({ steps: 3 })
            } else {
                this.setState({ steps: 2 })

            }
        })
    }
    camsETProcess = () => {
        let data = []
        this.setState({ stepFour: true })
        camsEtlProcess(clientDetails.clientID, clientDetails.batchNo).then((res) => {
            this.setState({ stepFour: false })
            if (res.status == "S") {
                this.setState({ steps: 4 })
            } else {
                this.setState({ steps: 3 })

            }
        })
    }
    camsFamilyHoldingProcess = () => {
        camsFamilyHolding(clientDetails.clientID, clientDetails.batchNo).then((res) => {
            if (res.status == "S") {
                this.setState({ steps: 5 })
            } else {
                this.setState({ steps: 4 })


            }
        })
    }
    camsHistoryProcess = () => {
        let data = []
        this.setState({ stepOne: true })
        camsHistoric(clientDetails.clientID, clientDetails.batchNo).then((res) => {
            this.setState({ stepOne: false, })
            if (res.status == "S") {
                this.setState({ steps: 1 })
            } else {
                this.setState({ steps: 0 })

            }
        })
    }

    camsHoldingProcess = () => {
        let data = []
        this.setState({ stepTwo: true })
        camsHoldings(clientDetails.clientID, clientDetails.batchNo).then((res) => {
            this.setState({ stepTwo: false })
            if (res.status == "S") {
                this.setState({ steps: 2 })
            } else {
                this.setState({ steps: 1 })

            }
        })
    }
    cdslETProcess = () => {
        let data = []
        this.setState({ stepFour: true })
        const { classes } = this.props;
        cdslEtlProcessBonds(clientDetails.clientID, clientDetails.batchNo).then((res) => {
            this.setState({ stepFour: false })
            if (res.status == "S") {
                this.setState({
                    subProcessStep: [{ name: "Transforming data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Analysing data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Generating reports for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Finalizing data", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.BROWN }}>9</div> }, { name: "Completing", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>10</div> }]
                })
                this.setState({ subSteps: 3 })
            } else {
                this.setState({
                    subProcessStep: [{ name: "Transforming data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Analysing data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Generating reports for other assets", error: false, icon: <div className={classes.commonBadgeStyle} ><WarningIcon style={{ fontSize: 10, color: colors.red }}></WarningIcon></div> }, { name: "Finalizing data", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>9</div> }, { name: "Completing", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>10</div> }]
                })
                this.setState({ subSteps: 2 })

            }

        })
    }
    cdslHoldingProcess = () => {
        let data = []
        this.setState({ stepTwo: true })
        const { classes } = this.props;
        cdslHoldingsBonds(clientDetails.clientID, clientDetails.batchNo).then((res) => {
            this.setState({ stepTwo: false })
            if (res.status == "S") {
                this.setState({
                    subProcessStep: [{ name: "Transforming data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Analysing data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.BROWN }}>7</div> }, { name: "Generating reports for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>8</div> }, { name: "Finalizing data", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>9</div> }, { name: "Completing", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>10</div> }]
                })
                this.setState({ subSteps: 1 })
            } else {
                this.setState({ subSteps: 0 })

            }
        })
    }
    cdslGetDailyBondsProcess = () => {
        let data = []
        this.setState({ stepThree: true })
        const { classes } = this.props;
        cdslGetDailyBonds(clientDetails.clientID, clientDetails.batchNo).then((res) => {
            this.setState({ stepThree: false })
            data.push(res.status)
            if (res.status == "S") {
                this.setState({
                    subProcessStep: [{ name: "Transforming data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Analysing data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Generating reports for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.BROWN }}>8</div> }, { name: "Finalizing data", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>9</div> }, { name: "Completing", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>10</div> }]
                })
                this.setState({ subSteps: 2 })
            } else {
                this.setState({
                    subProcessStep: [{ name: "Transforming data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Analysing data for other assets", error: false, icon: <WarningIcon style={{ fontSize: 10, color: colors.red }}></WarningIcon> }, { name: "Generating reports for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>8</div> }, { name: "Finalizing data", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>9</div> }, { name: "Completing", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>10</div> }]
                })
                this.setState({ subSteps: 1 })

            }
        })
    }
    cdslFifoCalculationBondsProcess = () => {
        let data = []
        this.setState({ stepOne: true })
        const { classes } = this.props;
        cdslFifoCalculationBonds(clientDetails.clientID, clientDetails.batchNo).then((res) => {
            this.setState({ stepOne: false })
            if (res.status == "S") {
                this.setState({
                    processStep: [{ name: "Validating data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Transforming data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Analysing data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Generating reports for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Validating data for other assets", style: { color: colors.BLACK_PRIMARY }, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> },]
                })
                this.setState({
                    subProcessStep: [{ name: "Transforming data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.BROWN }}>6</div> }, { name: "Analysing data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>7</div> }, { name: "Generating reports for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>8</div> }, { name: "Finalizing data", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>9</div> }, { name: "Completing", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>10</div> }]
                })
                this.setState({ subSteps: 0, steps: 9 })
            } else {
                this.setState({
                    subProcessStep: [{ name: "Transforming data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>6</div> }, { name: "Analysing data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>7</div> }, { name: "Generating reports for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>8</div> }, { name: "Finalizing data", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>9</div> }, { name: "Completing", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>10</div> }]
                })
                this.setState({ steps: 4 })

            }

        })
    }
    nsdlEtProcess = () => {
        const { classes } = this.props;
        let data = []
        this.setState({ stepFour: true })
        nsdlEtProcess(clientDetails.clientID, clientDetails.batchNo).then((res) => {
            this.setState({ stepFour: false })
            data.push(res.status)
            if (res.status == "S") {
                this.setState({
                    processStep: [{ name: "Validating data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Transforming data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Analysing data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Generating reports for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Validating data for other assets", style: { color: colors.BLACK_PRIMARY }, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.BROWN }}>5</div> },]
                })
                // this.setState({
                //     subProcessStep: [{ name: "Validating data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.BROWN }}>5</div> }, { name: "Transforming data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.BROWN }}>2</div> }, { name: "Analysing data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>3</div> }, { name: "Generating reports for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>4</div> }, { name: "Finalizing data", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>5</div> }, { name: "Completing", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>6</div> }]
                // })
                this.setState({ steps: 4 })
            } else {
                this.setState({ steps: 3 })

            }
        })
    }
    nsdlFifoCalculationProcess = () => {
        const { classes } = this.props
        let data = "Validating data for equity"
        this.setState({ stepOne: true })
        nsdlFifoCalculation(clientDetails.clientID, clientDetails.batchNo).then((res) => {
            this.setState({ stepOne: false, })
            if (res.status == "S") {
                this.setState({
                    processStep: [{ name: "Validating data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Transforming data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.BROWN }}>2</div> }, { name: "Analysing data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>3</div> }, { name: "Generating reports for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>4</div> }, { name: "Validating data for other assets", style: { color: colors.BLACK_PRIMARY }, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>5</div> },]
                })
                this.setState({ steps: 1 })
            } else {
                this.setState({
                    processStep: [{ name: "Validating data for equity", style: { color: colors.BLACK_PRIMARY }, icon: <div className={classes.commonBadgeStyle} style={{ color: colors.red }}><WarningIcon></WarningIcon></div> }, { name: "Transforming data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>2</div> }, { name: "Analysing data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>3</div> }, { name: "Generating reports for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>4</div> }, { name: "Validating data for other assets", style: { color: colors.BLACK_PRIMARY }, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>5</div> },]
                })
                this.setState({ steps: 0 })

            }
        })
    }
    nsdlGetDailyEquityProcess = () => {
        const { classes } = this.props;
        let data = []
        this.setState({ stepThree: true })
        nsdlGetDailyEquity(clientDetails.clientID, clientDetails.batchNo).then((res) => {
            this.setState({ stepThree: false })
            if (res.status == "S") {
                this.setState({
                    processStep: [{ name: "Validating data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Transforming data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Analysing data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Generating reports for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>4</div> }, { name: "Validating data for other assets", style: { color: colors.BLACK_PRIMARY }, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>5</div> },]
                })
                this.setState({ steps: 3 })
            } else {
                this.setState({ steps: 2 })

            }
        })
    }
    nsdlHoldingsEquityProcess = () => {
        const { classes } = this.props;
        let data = []
        this.setState({ stepTwo: true })
        nsdlHoldingsEquity(clientDetails.clientID, clientDetails.batchNo).then((res) => {
            this.setState({ stepTwo: false })
            if (res.status == "S") {
                this.setState({
                    processStep: [{ name: "Validating data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Transforming data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Analysing data for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>3</div> }, { name: "Generating reports for equity", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>4</div> }, { name: "Validating data for other assets", style: { color: colors.BLACK_PRIMARY }, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>5</div> },]
                })
                this.setState({ steps: 2 })
            } else {
                this.setState({ steps: 1 })

            }
        })
    }
    allProductXirr = () => {
        const { classes } = this.props;
        allProductsXirr(clientDetails.clientID, clientDetails.batchNo).then((res) => {
            if (res.status == "S") {

                if (window.location.pathname.includes("/cams")) {
                    this.setState({ steps: 6 })
                } else {
                    this.setState({
                        subProcessStep: [{ name: "Transforming data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Analysing data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Generating reports for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Finalizing data", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }} ><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Completing", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.BROWN }}>10</div> }]
                    })
                    this.setState({ subSteps: 4 })
                }
            } else {
                if (window.location.pathname.includes("/cams")) {
                    this.setState({ steps: 6 })
                } else {
                    this.setState({
                        subProcessStep: [{ name: "Transforming data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Analysing data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Generating reports for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Finalizing data", icon: <div className={classes.commonBadgeStyle} ><WarningIcon style={{ fontSize: 10, color: colors.WHITE }}></WarningIcon></div> }, { name: "Completing", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.PRIMARY_BORDER }}>10</div> }]
                    })
                    this.setState({ subSteps: 3 })
                }

            }
        })
    }
    l5ReportCall = (clientID) => {
        let L5Report = []
        getL5report(clientID).then(async(res) => {
            L5Report = res.data == null || res.data == undefined ? [] : res.data
            this.props.storeL5Report(L5Report)
            if (res.status == "S") {
             await   this.props.childFunc.current(this.props.logindetails.SessionID, clientID, this.props.date, res.data?.filter((item) => item.L4_AccountTypeID == 3)[0]?.L5ID, "L5")
            }
        })
    }
    allProductETLProcess = () => {
        const { classes } = this.props;
        allProductsEtlprocess(clientDetails.clientID, clientDetails.batchNo).then((res) => {
            if (res.status == "S") {
                this.setState({ enableTrack: false,openDialogPopup:true })
                if (this.props.logindetails.ClientID == 0) {
                    this.props.clientIDchange(clientDetails.clientID)
                    this.l5ReportCall(clientDetails.clientID)
                } else {
                    this.l5ReportCall(this.props.logindetails.ClientID)
                }
                updateProcessStatus(clientDetails.batchNo).then((res) => {

                })
                
                if (window.location.pathname.includes("/cams")) {
                    this.setState({ steps: 7 })
                } else {
                    this.setState({
                        subProcessStep: [{ name: "Transforming data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Analysing data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Generating reports for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Finalizing data", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }} ><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Completing", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }]
                    })
                    this.setState({ subSteps: 6 })
                }
            } else {
                this.setState({ enableTrack: true })
                if (window.location.pathname.includes("/cams")) {
                    this.setState({ steps: 7 })
                } else {
                    this.setState({
                        subProcessStep: [{ name: "Transforming data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Analysing data for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Generating reports for other assets", error: false, icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }}><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Finalizing data", icon: <div className={classes.commonBadgeStyle} style={{ backgroundColor: colors.GREEN }} ><CheckIcon style={{ fontSize: 17, color: colors.WHITE }}></CheckIcon></div> }, { name: "Completing", icon: <WarningIcon style={{ color: colors.red, fontSize: 10 }}></WarningIcon> }]
                    })
                    this.setState({ subSteps: 5 })
                }
            }
        })
    }
    backToTrack = () => {

        // getL5report(this.props.logindetails.ClientID).then((res)=>{
        //     if(res.status=="S"){

        //     }
        // })
        this.props.history.push({
            pathname: labels.Path.Tracking,
            state: { importedstatus: true },
        })
    }
    render() {

        const { classes } = this.props;
        try {
            return (<>
                <Subheader heading={this.state.header} description={this.state.description}></Subheader>
                <div className={classes.root}>
                    <div>

                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

                        {/* <CustomizedSteppers activeStep={this.state.steps} steps={this.state.processStep} icons={verticalIcons} flag="vertical"></CustomizedSteppers> */}

                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                            <CustomizedSteppers type="cutomizedsteps" activeStep={this.state.steps} steps={this.state.processStep} orientation="vertical" />
                            {!window.location.pathname.includes("cams") &&
                                <CustomizedSteppers type="cutomizedsteps" activeStep={this.state.subSteps} steps={this.state.subProcessStep} orientation="vertical" ></CustomizedSteppers>}

                        </div>
                        <div style={{ width: "40%", marginBottom: "3%" }}>
                            <Dialog open={this.state.openDialogPopup}>
                                <div style={{display:"flex",flexDirection:"column",justifyContent:"center",gap:25,alignItems:"center"}}>
                                <img src={Capture} style={{ height: 49, width: 54, }} />
                                <div style={{fontSize:18,color:colors.BLACK_PRIMARY,width:300,textAlign:"center"}}>Your document has been uploaded Successfully </div>
                                <OutlinedButtons text="Back to Portfolio" onClick={()=>this.props.history.push(labels.Path.Portfolio)}></OutlinedButtons>
                                </div>
                            </Dialog>
                            {/* <Button onClick={this.backToTrack} page={!window.location.pathname.includes("cams") ? this.state.subSteps == 6 : this.state.steps == 6 ? "primary" : "secondary"}
                                disabled={this.state.enableTrack} name="Back to Track"></Button> */}
                        </div>
                    </div>

                    <div>

                    </div>
                </div></>
            )
        } catch (err) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = (state) => {
    return {
        // trackEquity: state.trackEquity,
        userName: state.Login.changedUserName,
        profileDetails: state.ProfileDetails.AllProfileDetails,
        logindetails: state.Login.logindetails,
        companyDetails: state.Login.companyDetails,
        date: state.Login.date,

    }

}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getL5reportDetails, clientIDchange,storeL5Report }, dispatch)
};
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(UploadCamsAndNsdl)));