import * as types from '../../constants/actionsType'
const initialState = {
    riaFlowOnLinePaymentOrder: [],
    isRiaFlowOnLinePaymentLoading: false
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RIA_FLOW_ONLINE_PAYMENT:  
       //  
            return {
                ...state,
                riaFlowOnLinePaymentOrder: action.payload != null ? action.payload : state.riaFlowOnLinePaymentOrder ,
                isRiaFlowOnLinePaymentLoading: false
            }
        case types.LOADER_RIA_FLOW_ONLINE_PAYMENT:
            return {
                ...state,
                isRiaFlowOnLinePaymentLoading: true
            }
        default:
            return state
    }
}
export default reducer