import { withStyles } from "@material-ui/core";
import React from "react";
import colors from "../../utils/colors/colors";
import Avatars from "../../components/avatar/avatar";
import { NegativeFinding, replaceNull } from "../../utils/commonfunction";
import DynamicTable from '../../components/table/dynamictable';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { SchemeLinePerformationDetails } from '../../redux/actions/schemes/lineperformancecard'
import TreeTable from '../../components/expandable-table/expandabletable'
import { Chip } from '@material-ui/core';
import Carousel from '../../components/trading/carousel';
import Tables from '../../components/trading/funddetails';
import DonutChart from '../../components/trading/donutchart';
import Low from '../../utils/assets/low.PNG'
import Medium from '../../utils/assets/medium.PNG'
import Critical from '../../utils/assets/critical.PNG'
import CardMenu from '../cardmenu/cardmenu';
import LineChart from '../../components/trading/linechart';
import lable from '../../utils/constants/labels';
import Sidetabs from '../../components/trading/sidetabs';
import Schemeloader from '../../components/trading/schemeloader';
import labels from "../../utils/constants/labels";
import Progressline from "../../components/ProgressLine/progressline";
import getBasketDetails from "../../api/basket/basketdetails";
import Details_screen from "../../components/template/details_screen";
import BarProgressLine from "../../components/ProgressLine/apexchartprogressline";
import moment from "moment";
import { chart1, chart2 } from "./data";
import { BiChevronDown } from "react-icons/bi";
import CustomizedSteppers from "../epf/stepper";
import getRebalanceTimeLine from "../../api/basket/rebalancetimeline";
import getBasketPerformance from "../../api/basket/basketperformance";
import getBenchMarkData from "../../api/basket/benchmarkdata";
import Dropdownschemes from "../../components/dropdown/dropdownschemes";
import Loader from "../../components/loader/loader";
import NoData from "../../components/nodata/nodata";
const styles = (theme) => ({
    basketDetails: {
        backgroundColor: colors.PRIMARY,
        padding: 10,
        width: "100%"
    },
    header: {
        fontSize: 21,
        //marginBottom: 10,
        fontWeight: 600,
        textAlign: "start",
        color: colors.BLACK_PRIMARY,
        display:"flex",
        flexDirection:"row",
        justifyContent:"initial",alignItems:"center"
    },
    root: {
        flexGrow: 1,
    },
    container: {
        // marginTop: 395,       
        marginLeft: '2.5%',

    },
    // mobile responsive added by yogitha on 01/06/2021
    desktopResponsive: {
        display: 'block !important',

        [theme.breakpoints.down("900")]: {
            display: 'none !important',
        },

    },
    mobileResponsive: {
        display: 'none !important',
        [theme.breakpoints.down("900")]: {
            display: 'block !important',
            width: '100%',
        }
    },
    dataFive: {
        border: `1px solid ${colors.PRIMARY_BORDER}`
        ,
        width: "fit-content",
        borderRadius: 5,
        padding: 8,
        fontSize: 14,
        "& :nth-child(1)": {
            display: "flex", flexDirection: "row", gap: 5,
            alignItems: "center",
            fontSize: 14,
        }

    },
    cardHeading: {
        // float: "left",
        margin: 0,
        textAlign: "left",
        // marginLeft: 30,
        // marginTop: 30,
        fontWeight: 'bold',
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: 18,
        [theme.breakpoints.down("600")]: {
            float: "unset",
            marginLeft: 10,
            marginTop: 0,
            paddingTop: '4%',
        },
    },
    returnPaper: {
        paddingBottom: "10px",
        minHeight: "98%",
        boxShadow: "0 0 20px 0 rgba(19, 89, 161, 0.1)",
        [theme.breakpoints.down("900")]: {
            width: '90%',
            marginLeft: '2%',
            borderRadius: '10px !important',
        }
    },
    assetAllocationContainer: {
        display: "grid", gridTemplateColumns: "75% 25% !important",
        padding: 17,
        // fontSize: "large",
        fontWeight: "500",
        alignItems: "center",
        "& .dataOne": {
            display: "grid",
            gridTemplateColumns: "5% 91%"
        },
        "& .dataTwoSpecialChar": {
            fontSize: "1rem",
            color: colors.PRIMARY_BORDER
        },
        "& .dataTwo": {
            alignItems: "center",
            gap: 2
        },
        [theme.breakpoints.down('920',)]: {
            gridTemplateColumns: "50% 50%",
            gridTemplateAreas: `"dataOne dataTwo"
          "dataThree dataFour"`
        }
    },
    instrumentContainer: {
        display: "grid", gridTemplateColumns: "41% 59% !important",
        padding: 17,
        paddingLeft: 5,
        // fontSize: "large",
        fontWeight: "500",
        alignItems: "center",
        "& .dataOne": {
            display: "grid",
            textAlign: "left",
            // gridTemplateColumns: "5% 91%"
        },
        "& .dataTwoSpecialChar": {
            // fontSize: "1rem",
            color: colors.PRIMARY_BORDER
        },
        "& .dataTwo": {
            alignItems: "center",
            justifyContent: "right",
            textAlign: "right",
            gap: 2
        },
        [theme.breakpoints.down('920',)]: {
            gridTemplateColumns: "50% 50%",
            gridTemplateAreas: `"dataOne dataTwo"
          "dataThree dataFour"`
        }
    },
    subInstrumentContainer: {
        display: "grid", gridTemplateColumns: "70% 30% !important",
        padding: 17,
        // fontSize: "large",
        fontWeight: "500",
        alignItems: "center",
        "& .dataOne": {
            display: "grid",
            gridTemplateColumns: "5% 91%"
        },
        "& .dataTwoSpecialChar": {
            // fontSize: "1rem",
            color: colors.PRIMARY_BORDER
        },
        "& .dataTwo": {
            alignItems: "center",
            gap: 2
        },
        [theme.breakpoints.down('920',)]: {
            gridTemplateColumns: "50% 50%",
            gridTemplateAreas: `"dataOne dataTwo"
          "dataThree dataFour"`
        }
    },
    headerStyle: {
        fontSize: "15px !important",
        paddingTop: "0px !important"
    },
    subAssetAllocationContainer: {
        display: "grid", gridTemplateColumns: "50% 50%",
        padding: "15px 18px 15px 16px",
        [theme.breakpoints.down('920',)]: {
            gridTemplateColumns: "50% 50%",
            gridTemplateAreas: `"subDataOne subDataTwo"
          "subDataThree subDataFour"`
        }
    },
    returnLabel: {
        marginTop: '10%',
        paddingLeft: '10px',
        fontWeight: 'bold',
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: 18,
        [theme.breakpoints.down("900")]: {
            marginTop: '4%',
        },
    },
    cardMenu: {
        marginBottom: "0%",
        // marginLeft: "1.8%",
        // marginRight: "1.8%",
        // height: 82,
        gridTemplateColumns: "25% 19% 19% 19% 18%",
        alignItems: "baseline",
        // paddingTop: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,

        "& .data1": {
            height: "48px !important",
            padding: 10,
            borderRight: `1px solid ${colors.BORDER}`
        },
        "& .data2": {
            // height: "0px !important",
            padding: 10,
            borderRight: `1px solid ${colors.BORDER}`
        },
        "& .data3": {
            padding: 10,
            // height: "0px !important",
            borderRight: `1px solid ${colors.BORDER}`
        },
        "& .data4": {
            padding: 10,
            // height: "0px !important",
            borderRight: `1px solid ${colors.BORDER}`
        },

    },
    cardSpareaterLine: {
        height: 0.5,
        width: "100%",
        backgroundColor: window.globalConfig.color.PRIMARY_BORDER
        , marginTop: "2%"
    },
    carouselCardResponsive: {
        backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
        paddingTop: 40,
        paddingLeft: "0px",
        paddingRight: "15px",
        [theme.breakpoints.down("900")]: {
            paddingBottom: 20,
            paddingTop: 20,
        },
    },
    topHoldingCard: {
        height: '83%',
        width: '90%',
        margin: '8% 2% 0% 2%',
        boxShadow: 'rgb(19 89 161 / 10%) 0px 0px 20px 0px',
        [theme.breakpoints.down("900")]: {
            margin: '4% 2% 0% 2%',
        },
        [theme.breakpoints.down("900")]: {
            margin: '8% 2% 0% 2%',
        },
    },
    // topHoldingCardResponsive:{
    // maxHeight:'433px',
    // },
    dropdownbox: {
        float: "left",
        minWidth: 179,
        width: '100%',
        // height: 69,
        // borderLeft: `1px solid ${window.globalConfig.color.BORDER}`,
        paddingLeft: "10px",
        cursor: "pointer",
    },
    dropdown: {
        width: 177,
        paddingTop: 13,
        cursor: "pointer",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: "center",
        // height: "45px",
        [theme.breakpoints.down("600")]: {
            width: "100%",
        },
    },
    donutChartResponsive: {
        height: "100%",
        margin: '4% 0% 13% 4%',
        width: '92%',
        boxShadow: "0 0 20px 0 rgba(19, 89, 161, 0.1)"
    },
    allocationCard: {
        height: '83%', overflowY: 'scroll', overflowX: 'hidden',
        width: '92%',
        margin: '8% 4% 13% 4%',
        boxShadow: 'rgb(19 89 161 / 10%) 0px 0px 20px 0px',
        [theme.breakpoints.down("sm")]: {
            height: '83%', overflowY: 'scroll', overflowX: 'hidden',
            width: '90%',
            margin: '3% 0% 13% -7%',
            boxShadow: 'rgb(19 89 161 / 10%) 0px 0px 20px 0px',
        },
        [theme.breakpoints.down("xs")]: {
            height: '83%', overflowY: 'scroll', overflowX: 'hidden',
            width: '92%',
            margin: '8% 4% 13% 4%',
            boxShadow: 'rgb(19 89 161 / 10%) 0px 0px 20px 0px',
        },
    },
    basketContainer: {
        display: "grid",
        gridTemplateColumns: "10% 40% 15% 15% 20%",
        padding: "1%",
        cursor: "pointer",
        borderBottom: `1px solid ${colors.PRIMARY_BORDER}`,
        alignItems: "center",
        backgroundColor: "white",
    },
    container: {
    },
    dropdown: {
        border: `1px solid transparent !important`
    },
    dataFive: {
        border: `1px solid ${colors.PRIMARY_BORDER}`
        ,
        width: "fit-content",
        borderRadius: 5,
        padding: 8,
        fontSize: 14,
        backgroundColor: "white",
        "& :nth-child(1)": {
            display: "flex", flexDirection: "row", gap: 5,
            alignItems: "center",
            fontSize: 14,
        }

    },
    dataThree: {
        display: "flex", flexDirection: "column", gap: 5,
        "& :nth-child(1)": {
            fontSize: 14,
            color: colors.PRIMARY_BORDER,

        }
    },
    heading: {
        fontSize: 24,
        color: colors.BLACK_PRIMARY,
        fontWeight: "bold",
        textAlign: "left"
    },
    dataFour: {
        display: "flex", flexDirection: "column", gap: 5,
        "& :nth-child(1)": {
            fontSize: 14,
            color: colors.PRIMARY_BORDER,
        },
        "& :nth-child(2)": {
            fontWeight: "bold"
        }
    },
    dataTwo: {
        display: "flex", flexDirection: "column", gap: 10,
        textAlign: "left",

        "& :nth-child(1)": {
            fontSize: 18,
            color: colors.BLACK_PRIMARY,
            fontWeight: "bold"
        },
        "& :nth-child(2)": {
            fontSize: 14,
            color: colors.BLACK_PRIMARY,
            lineHeight: 1.57
            //fontWeight: "bold"
        },
        "& :nth-child(3)": {
            fontSize: 14,
            color: colors.PRIMARY_BORDER,
            //fontWeight: "bold"
        }

    }
})
//const data = '';/*"All Weather Investing is a popular strategy that ensures your investments do well in good as well as bad times. This is a long-term investment strategy that you can use to build wealth over the years to come.smallcase invests in 3 asset classes--equity, debt and gold .The portfolio is rebalanced periodically to generate relatively higher returns by assuming the least possible risks .This smallcase is ideal for all types of market conditions. It will ensure that neither will your investment ship sink, nor will the investment flight soar to scary heights. What you will get here is a steady ride to help you meet your long-term investment goals."*/
class BasketDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            basketDetails: [],
            loader: false, tableData: [],
            donutChartData: [],
            cardData: [],
            data: "NA",
            timeLine: [],
            benchMarkData: [],
            benchMarkID: 0,
            benchMarkName: "",
            performanceFilter: [{ name: "6M", value: 1, isActive: true, data: [] }, { name: "1Y", value: 2, isActive: false, data: [] }, { name: "3Y", value: 3, isActive: false, data: [] }, { name: "5Y", value: 4, isActive: false, data: [] }]
            , performaceChartLoader: false,
            timeLineLoader: false



        }
    }
    async componentDidMount() {
        let data = this.props.location.state.data
        data.dataOne = this.props.location.state.data.basketIcon
        data.dataTwo = this.props.location.state.data.basketName
        data.subDataOne = this.props.location.state.data.basketDescription
        data.dataThree = `${this.props.location.state.data.cagrPercentage} %`
        delete (data.dataFour)
        data.subDataThree = this.props.location.state.data.dataFive + " " + this.props.location.state.data.dataFiveHeader
        this.setState({ basketDetails: data })
        this.setState({ loader: true })
        getBasketDetails(this.props.logindetails.UserID, this.props.location.state.data.ID).then((response) => {
            let res = replaceNull(response, "NA")
            this.setState({ loader: false })
            if (res.status == "S") {
                let groupBasket = []

                if (res.basketDetailsList != "NA") {
                    this.setState({
                        tableData: res.basketDetailsList.filter((ele, ind) => ind === res.basketDetailsList.findIndex((elem) => elem.instrument === ele.instrument)).map((item, index) => {
                            return {
                                "dataOne": item.instrument,
                                "isExpand": false,
                                "color": labels.chartColor[index],
                                "showCheckBox": true,
                                "product": item.product,
                                "dataTwo": `${item.weightage}%`,
                                // "subData": [],
                            }
                        }),
                        donutChartData: res.basketDetailsList.map((val) => {
                            return { name: val.instrument, value: val.weightage }
                        })
                    })

                }
                if (res.basketLists != "NA") {
                    this.setState({
                        data: res.basketLists?.overView || labels.messages.NA
                    })
                    this.setState({
                        cardData: [{ firstName: "Minimum Investment Amount", lastName: NegativeFinding(res.basketLists?.minAmount, "", "", "", "rupee"), },
                        { firstName: "Instruments", lastName: res.basketLists.etf },
                        { firstName: "Basket Type", lastName: res.basketDetailsList[0]?.product || labels.messages.NA },
                        { firstName: "Rebalance Frequency", lastName: res.basketLists.rebalanceFrequency },
                        { firstName: "Last Rebalance", lastName: res.basketLists.lastRebalance == "NA" ? res.basketLists.lastRebalance : moment(res.basketLists.lastRebalance, "DD-MMM-YYYY").format("DD MMM YYYY") }]
                    })

                }
            } else {

            }
        })
        await this.benchMarkDataList()
        await this.rebalanceTimeLine()
        this.basketPerformanceChart("6M")

        // console.log(this.props.location.state.data)
    }
    componentDidUpdate(prevProps, prevState) {
        const { timeLine } = this.state;
        if (prevState.timeLine != timeLine) {
            for (let i = 0; i < this.state.timeLine.length; i++) {
                this.state.timeLine[i].subData.map((item) => {
                    let elements = Array.from(document.getElementsByClassName(`subDataOne${item.type}`));
                    if (item.type == "Buy") {
                        elements.map(element => {
                            element.style.color = window.globalConfig.color.GREEN;
                        })
                    } else {
                        elements.map(element => {
                            element.style.color = window.globalConfig.color.red;
                        })
                    }
                })
            }
        }

    }
    benchMarkDataList = () => {
        getBenchMarkData().then((response) => {
            let res = replaceNull(response, "NA")
            if (res.status == "S" && res.data != "NA") {
                this.setState({
                    benchMarkData: res.data.map((item) => {
                        return {
                            ...item,
                            label: item.BenchMarkName,
                            value: item.ID
                        }
                    }),
                    benchMarkID: res.data[0].ID, benchMarkName: res.data[0].BenchMarkName
                })
            } else {
                this.setState({ benchMarkData: [] })
            }
        })
    }
    rebalanceTimeLine = () => {
        this.setState({ timeLineLoader: true })
        getRebalanceTimeLine(this.props.location.state.data.ID).then((response) => {
            this.setState({ timeLineLoader: false })
            let res = replaceNull(response, "")
            // let res = {
            //     "status": "S",
            //     "message": "Success",
            //     "data": [
            //         {
            //             "productID": 0,
            //             "product": null,
            //             "instrumentID": 0,
            //             "instrument": "Tata Arbitrage Fund - Direct (G)",
            //             "weightage": 31.33,
            //             "approvedOn": "19 Sep 2023",
            //             "type": "Buy",
            //             "effectiveFrom": "19 Sep 2023 17:34:38",
            //             "amount": null
            //         },
            //         {
            //             "productID": 0,
            //             "product": null,
            //             "instrumentID": 0,
            //             "instrument": "",
            //             "weightage": 34.33,
            //             "approvedOn": "19 Sep 2023",
            //             "type": "Buy",
            //             "effectiveFrom": "19 Sep 2023 17:34:38",
            //             "amount": null
            //         },
            //         {
            //             "productID": 0,
            //             "product": null,
            //             "instrumentID": 0,
            //             "instrument": "HDFC Small Cap Fund (G)",
            //             "weightage": 34.34,
            //             "approvedOn": "19 Sep 2023",
            //             "type": "Buy",
            //             "effectiveFrom": "19 Sep 2023 17:34:38",
            //             "amount": null
            //         },
            //         {
            //             "productID": 0,
            //             "product": null,
            //             "instrumentID": 0,
            //             "instrument": "HDFC Small Cap Fund (G)",
            //             "weightage": 1.5,
            //             "approvedOn": "19 Sep 2023",
            //             "type": "Buy",
            //             "effectiveFrom": "19 Sep 2023 18:02:47",
            //             "amount": null
            //         },
            //         {
            //             "productID": 0,
            //             "product": null,
            //             "instrumentID": 0,
            //             "instrument": "",
            //             "weightage": 1.5,
            //             "approvedOn": "19 Sep 2023",
            //             "type": "Buy",
            //             "effectiveFrom": "19 Sep 2023 18:02:47",
            //             "amount": null
            //         },
            //         {
            //             "productID": 0,
            //             "product": null,
            //             "instrumentID": 0,
            //             "instrument": "Tata Arbitrage Fund - Direct (G)",
            //             "weightage": 3,
            //             "approvedOn": "19 Sep 2023",
            //             "type": "Sell",
            //             "effectiveFrom": "19 Sep 2023 18:02:47",
            //             "amount": null
            //         },

            //     ],
            //     "table1": [
            //         {
            //             "EffectiveFrom": "19 Sep 2023 18:02:47",
            //             "BuyCount": "+ 2 Instruments",
            //             "SellCount": "- 1 Instruments"
            //         },
            //         {
            //             "EffectiveFrom": "19 Sep 2023 17:34:38",
            //             "BuyCount": "+ 3 Instruments",
            //             "SellCount": ""
            //         }
            //     ]
            // }
            if (res.status == "S" && res.table1.length != 0) {
                this.setState({
                    timeLine: res.table1.map((item, index) => {
                        return {
                            ...item,
                            "dataOne": item.EffectiveFrom,
                            "isExpand": index == 0 ? true : false,
                            "color": labels.chartColor[index],
                            "showCheckBox": false,
                            // "product": item.product,
                            "dataTwo": `${item.BuyCount}  ${item.SellCount == "" ? "" : `| ${item.SellCount}`}`,
                            "subData": res.data.filter((val) => val.effectiveFrom == item.EffectiveFrom).map((value) => {
                                return {
                                    ...value,
                                    dataOne: value.instrument == "" ? "NA" : value.instrument,
                                    dataTwo: `${value.weightage}%`,
                                    // dataThree: value.type
                                }
                            }),
                        }
                    })
                })

            } else {
                this.setState({ timeLine: [] })
            }
        })
    }
    renderImage = (val) => {
        let srcImage = [{ status: "mediocary", image: Low }, { status: "agreesive", image: Medium }, { status: "cautious", image: Critical }]
        let img = srcImage.filter((item) => item.status.toLowerCase() == val.toLowerCase())[0]?.image
        return img
    }
    onChangeBenchMarkData = async (value) => {
        this.setState({ benchMarkID: value[0].value, benchMarkName: value[0].label })
        await this.basketPerformanceChart("6M")
    }
    basketPerformanceChart = (year) => {
        this.setState({ performaceChartLoader: true })
        getBasketPerformance(this.props.location.state.data.ID, year, this.state.benchMarkID).then((response) => {
            let res = replaceNull(response, "NA")
            this.setState({ performaceChartLoader: false })
            if (res.status == "S" && res.data != "NA") {
                this.setState({
                    performanceFilter: this.state.performanceFilter.map((val, index) => {
                        return {
                            ...val, isActive: val.name == year,
                            data: val.name != year ? val.data : [
                                {

                                    name: "Line1",
                                    type: "spline",
                                    color: colors.PRIMARY,
                                    tooltip: {
                                        valueDecimals: 2,
                                    },
                                    data: res.data?.filter((item) => item.Flag == "B1")?.map((item) => {
                                        return [Number(new Date(item.NavDate).valueOf()), item.NAVRs];
                                    }),
                                    // _symbolIndex: 0
                                },
                                {
                                    name: "Line2",
                                    type: "spline",
                                    color: colors.BROWN,
                                    tooltip: {
                                        valueDecimals: 2,
                                    },
                                    data: res.data?.filter((item) => item.Flag == "B2")?.map((item) => {
                                        return [Number(new Date(item.NavDate).valueOf()), item.NAVRs];
                                    }),
                                    // _symbolIndex: 1
                                }
                            ]
                        }
                    })
                })
            } else {
                this.setState({
                    performanceFilter: this.state.performanceFilter.map((val, index) => {
                        return {
                            ...val, isActive: val.name == year,
                        }
                    })
                })
            }
        })
    }
    collapseTable = (rowId) => {
        let newArray = [...this.state.timeLine];
        newArray[rowId] = {
            ...newArray[rowId],
            isExpand: !newArray[rowId].isExpand,
        };
        // const scrollToElement = document.getElementById(`mainContent${rowId}`);
        // console.log(scrollToElement)
        // if (scrollToElement != null || scrollToElement != undefined) {
        //     scrollToElement.scrollIntoView()
        // }
        this.setState({ timeLine: [...newArray] })
    }
    render() {
        const { classes } = this.props;
        // console.log(this.state.tableData)
        for (let i = 0; i < this.state.timeLine.length; i++) {
            this.state.timeLine[i].subData.map((item) => {
                let elements = Array.from(document.getElementsByClassName(`subDataOne${item.type}`));
                if (item.type == "Buy") {
                    elements.map(element => {
                        element.style.color = window.globalConfig.color.GREEN;
                    })
                } else {
                    elements.map(element => {
                        element.style.color = window.globalConfig.color.red;
                    })
                }
            })
        }
        return (<div style={{ width: "100%" }}>
            <Details_screen list={this.state.basketDetails}
                disabled={true}
                {...this.state} {...this.props}
                data={this.props.location.state.data}
                page="basket"
                firstContainer={<div style={{ textAlign: "justify", paddingLeft: 20, display: "flex", flexDirection: "column", gap: 10 }}>
                    {this.state.loader ? <Loader page="reportmodel"></Loader> : this.state.data == "NA" ? <NoData></NoData> : <div dangerouslySetInnerHTML={{ __html: this.state.data }}></div>}
                </div>}
                firstHeader="Overview"
                secondHeader="Invest in this basket on"
                // thirdContainer={<div style={{ padding: "12px 15px" }}>
                //     <BarProgressLine></BarProgressLine>
                // </div>}
                // thirdHeader={"Market Cap Distribution"}
                fourthHeader={"Instruments"}
                fourthContainer={<div style={{ padding: "12px 15px" }}>
                    <div className={classes.header} >Instruments</div>
                    {this.state.loader ? <Loader page="table"></Loader> : <TreeTable search={labels.currency.rupeesymbol} grid={{ mainGrid: classes.assetAllocationContainer, subGrid: classes.subAssetAllocationContainer }}
                        summaryData={this.state.tableData} tableHeader={[
                            this.state.tableData[0]?.product || "Product",
                            "Weightage (%)"
                        ]} flag={labels.ManuHeading.familyPortfolio}
                        data={this.state.tableData} grandtotal={[
                            "Grand Total",

                            "100.0%"
                        ]} />}
                </div>
                }
                sixthHeader={<div style={{ display: "flex", flexDirection: "row", gap: 20, alignItems: "center", justifyContent: "flex-start" }}>
                    <div className={classes.header} >Basket Performance</div>
                    <span style={{ fontSize: 18 }}>VS</span>
                    <Dropdownschemes
                        type={"normalList"}
                        List={this.state.benchMarkData}
                        handleOnChange={this.onChangeBenchMarkData}
                    >
                        <div className={classes.dropdownbox}  >
                            <div
                                className={[classes.dropdown, classes.header].join(" ")}
                            // style={{ color: window.globalConfig.color.black }}
                            >
                                <span>{this.state.benchMarkName}</span>
                                <BiChevronDown
                                    style={{  color: "grey" }}
                                />
                            </div>
                        </div>
                    </Dropdownschemes>

                </div>}
                sixthContainer={<LineChart
                    data={this.state.performanceFilter.filter((item) => item.isActive)[0]?.data}
                    filterData={this.state.performanceFilter}
                    isTransactionDetailsLoading={
                        this.state.performaceChartLoader
                    }
                    handleFilter={this.basketPerformanceChart}
                >

                </LineChart>}
                seventhContainer={
                    <div>
                        <div className={classes.header} >Rebalance Timeline</div>
                        {this.state.timeLineLoader ? <Loader page="table"></Loader> :
                            <TreeTable search={labels.currency.rupeesymbol} grid={{ mainGrid: classes.instrumentContainer, subGrid: classes.subInstrumentContainer }}
                                summaryData={this.state.timeLine} tableHeader={[
                                    "Instrument",
                                    "Weightage (%)",
                                ]} flag={labels.ManuHeading.familyPortfolio}
                                collapseTable={this.collapseTable}
                                data={this.state.timeLine} />}
                    </div>
                }
            // fifthContainer={
            //     <DonutChart headerStyle={classes.header} header={"Segment Composition"} showSwitch={false} isTransactionDetailsLoading={this.state.loader} data={this.state.donutChartData} />}
            ></Details_screen>



        </div>
        )
    }
}
const mapStateToProps = state => {

    return {
        logindetails: state.Login.logindetails,
        basketPerformanceChart: state.LinePerformaceChart.linePerformance != undefined && state.LinePerformaceChart.linePerformance != 0 ? state.LinePerformaceChart.linePerformance : [],
        sixMonthPerformance: state.LinePerformaceChart.sixMonthPerformance != undefined && state.LinePerformaceChart.sixMonthPerformance != 0 ? state.LinePerformaceChart.sixMonthPerformance : [],
        threeYearPerformance: state.LinePerformaceChart.threeYearPerformance != undefined && state.LinePerformaceChart.threeYearPerformance != 0 ? state.LinePerformaceChart.threeYearPerformance : [],
        maxYeadPerformance: state.LinePerformaceChart.maxYeadPerformance != undefined && state.LinePerformaceChart.maxYeadPerformance != 0 ? state.LinePerformaceChart.maxYeadPerformance : [],
    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ SchemeLinePerformationDetails }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BasketDetails))