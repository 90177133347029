import { replaceNull } from '../../../utils/commonfunction'
import * as types from '../../constants/actionsType'
const initialState = {
    epf_List: {}
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.EPF_LIST:
            return {
                ...state,
                epf_List: replaceNull(action.payload,"NA")
            }
        case types.EMPTY_EPF_LIST:
            return {
                ...state,
                epf_List: {}
            }

        default:
            return state
    }
}
export default reducer
