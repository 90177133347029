import * as types from '../../constants/actionsType'
const initialState = {
    ProductList:[]
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
   
    case types.PRODUCT:
    
      return {
        ...state,

        ProductList: action.payload,
      }
    case types.EMPTY_PRODUCT:

      return {
        ...state,
        ProductList:[]
      }

    default:
      return state
  }
}
export default reducer
