import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import Textinput from '../../components/textinput/textinput';
import Dropdown from '../../components/dropdown/dropdownschemes'
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CommonPopup from '../../components/popup/popup';
import DatePicker from '../../components/datepickers/date_pickers';
import moment from 'moment';
import Question from "../../utils/assets/images/img5.png";
import color from "../../utils/colors/colors";
import CommonCheckbox from '../../components/checkbox/checkbox';
import labels from '../../utils/constants/labels';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import Text from '../../components/text/text';
import ArrowIcon from "../../utils/assets/images/img14.png";
import InformationTooltip from '../../components/tooltip/informationtooltip';
import colors from '../../utils/colors/colors';
import { NegativeFinding } from '../../utils/commonfunction';
import CustomButton from '../../components/custombutton/button'
import { scrollToTopPage } from '../../utils/commonfunction';
const styles = theme => ({
  dateWidth: {
    // width: 318,
    height: 33, textAlign: "left",
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    justifyContent: "space-between", paddingTop: "3%", paddingLeft: "2%",
    paddingRight: "2%",
    [theme.breakpoints.between("1000", "1050")]: {
      paddingTop: "10px",
    },
    [theme.breakpoints.between("1250", "1300")]: {
      paddingTop: "4%",
    },
  },
  textInput: {
    height: "38px",
    width: 665,
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    color: window.globalConfig.color.BLACK_PRIMARY,
    outline: "none !important",
    fontSize: "14px",
    marginLeft: -21,
    '&:hover': {
      border: `1px solid ${window.globalConfig.color.BROWN}`,
    },
  },
  folio: {
    width: "auto",
    backgroundColor: window.globalConfig.color.RosyBrown,
    padding: 10,
    gap: 10,
    height: "fit-content",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {

      display: 'flex',
      alignItems: 'center',
      paddingTop: '15px',
      paddingLeft: '10px',
      /* padding-bottom: 10px; */
      /* justify-content: space-around; */

      flexWrap: 'wrap',
    },
  },
  folioBlock: {
    float: 'left',
    padding: 2,
    minWidth: "30%",
    //paddingRight: 15,
    //paddingLeft: 15,
    backgroundColor: window.globalConfig.color.BORDER,
    borderRadius: 100,
    fontWeight: 400,
    fontSize: 13,
    // width: '30%',
    textTransform: "none !important",
    // marginLeft:10,
    // [theme.breakpoints.down("md")]: {
    //   width: "40%",
    // },
  },
  root: {
    marginLeft: -293,
    marginTop: 20
  },
  container: {
    // marginLeft: 18.5,
    width: '100%'
    // marginRight:20
  },
  schemeDropDown: {
    // marginTop: 18, marginRight: 50,
    // [theme.breakpoints.between("1000", "1050")]: {
    //   marginRight: 20,

    // },
  },
  schemeDropDownBlock: {
    width: "100%"
  },
  swpInstalment: {
    // width:"103%"
    //   width: '85%',
    //  [ theme.breakpoints.down("920")]:{
    //   width:"93%"
    //  }
  },
  divLine1: {
    height: "0.5px",
    width: "100%",
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    marginTop: "3%",
    marginBottom: "-4%",
    // boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.3)",
  },
  btnApply: {
    background: window.globalConfig.color.BROWN,
    marginLeft: "7px",
    padding: 0,
    lineHeight: "36px",
    color: window.globalConfig.color.WHITE,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    width: "100px",
    float: "right",
    borderRadius: "5px",
    // width: "40%",
    border: "1px solid transparent",
    '&:hover': {
      background: window.globalConfig.color.PRIMARY_LIGHT,
      color: window.globalConfig.color.WHITE,
    },
  },
  label: {
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: 14,
    marginLeft: -645
  },

  checkBox: {
    fontSize: 12,
    marginLeft: 0,
  },
  //Added by Celsia on 4 Jun 2021 for Mobile Responsive Started
  desktopResponsive: {
    display: 'block !important',
    [theme.breakpoints.down("600")]: {
      display: 'none !important',
    },
  },
  mobileResponsive: {
    display: 'none !important',
    [theme.breakpoints.down("600")]: {
      display: 'block!important',
    },
  },
  folioResponse: {
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    width: '100%',
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    paddingTop: '15px',
    flexWrap: 'wrap',
  },
  rootResponsive: {
    marginLeft: "15px",
    marginTop: 20,
    textAlign: "left",
    marginBottom: "15px",
  },
  marginResponsive: {
    marginRight: 15,
    marginLeft: 15,
    textAlign: "left"
  },
  folioBlockResponsive: {
    float: 'left',
    padding: 2,
    paddingRight: 15,
    paddingLeft: 15,
    backgroundColor: window.globalConfig.color.wispLightPink,
    borderRadius: 100,
    width: "100%",
    textTransform: "none !important",

  },
  placeOrderButtonResponse: {
    marginTop: "30px",
    marginRight: "4.5%",
    marginLeft: "4.5%",
    height: "40px"
  },
  amountInput: {
    //  width:"103%",

  },
  typeInput: {
    width: "100%",
    // [theme.breakpoints.down("920")]: {
    //   width: "91%"
    // }
  },
  swpstarttime: {
    display: "flex",
    flexDirection: "column",
    // [theme.breakpoints.down("920")]: {
    //   width: "91%"
    // }
  },
  secondPartGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: 10,
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(1,1fr)",

    }
  },
  image: {
    height: "fit-content"
  },
  folioDropdownAndUnits: {
    display: "grid", gridTemplateColumns: "30% 70%", gap: 10, alignItems: "center", width: "-webkit-fill-available"
    , [theme.breakpoints.down("700")]: {
      gridTemplateColumns: "100%",
      alignItems: "flex-start",

    }
  },
  totalUnits: {
    display: "flex", flexDirection: "row", gap: 10, alignItems: "center",
    [theme.breakpoints.down("700")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    }
  }
});
let status = ""
class SWPDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      showOption: false,
      setAnchorEl: false,
      anchorEl: false,

    }
  }
  componentDidMount() {
    scrollToTopPage();
  }
  onConfirmOrder = () => {
    this.props.confirmOrder(true);
  }

  handleClick = (event) => {
    this.setState({ setAnchorEl: event.currentTarget })
  }
  handledateClick = (e) => {
    this.setState({ anchorEl: e.currentTarget })
    // this.props.swpdatechange()

  }
  handledateclose = () => {
    this.setState({ anchorEl: false })

  }
  handleClose = () => {
    this.setState({ setAnchorEl: false })
  }
  handlefolio = (item) => {
    this.props.handleClickfolio(item)
    this.handleClose()
  }
  handleApply = (value) => {
    this.setState({ anchorEl: false })
    this.props.handleApplyClick(value)
  }
  render() {

    const { classes } = this.props;
    const open = Boolean(this.state.setAnchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (<ErrorBoundary>
      <div >
        <div container >
          <div xs={12} style={{ flexDirection: 'row', display: "flex", alignItems: "baseline" }}>
            <div xs={10} style={{ marginRight: 10, }} >
              <Text label={labels.SWP.SWPDetails} />
            </div>
            {/* <div style={{ marginTop: 22, }}> */}
            <InformationTooltip title={labels.SWP.SWPDetails} Icon={<img src={Question} className={classes.image}></img>}></InformationTooltip>
            {/* </div> */}
          </div>
        </div>

        <div container direction={"row"} spacing={3} className={classes.container}>
          <div item xs={12} className={classes.schemeDropDown}>
            <Dropdown type={"normalwithsearch"}
              label={labels.SWP.SchemestoSWPof}
              value={this.props.schemevalue}
              List={this.props.schemeList}
              handleOnChange={this.props.onChange}
            />
            <div className={classes.folio}>
              <div className={classes.folioDropdownAndUnits}>
                <Button onClick={this.handleClick} className={classes.folioBlock}>
                  {labels.SWP.Folio}: {this.props.folionumber == "" ? "NA" : this.props.folionumber} <img src={ArrowIcon} style={{ marginLeft: '5%' }}></img>
                </Button>
                {/* {console.log(this.props.folionumberList)} */}
                {this.props.folionumberList.length != 0 &&

                  <Menu
                    id="simple-menu"
                    anchorEl={this.state.setAnchorEl}
                    keepMounted
                    open={Boolean(this.state.setAnchorEl)}
                    onClose={this.handleClose}
                  >
                    {this.props.folionumberList?.map((item) => {
                      return <MenuItem onClick={() => this.handlefolio(item)}>{labels.SWP.Folio} : {item.FolioNo}</MenuItem>

                    })}
                  </Menu>}
                {/* <div className={classes.totalUnits}>
                  <p style={{ fontSize: 14, paddingRight: 14 }}>Total Amount :<span> {this.props.totalTable.length != 0 && this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber).length != 0 ? <>{NegativeFinding(this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber)[0].TotalAvailableAmount, "", "", "", "rupee")}</> : `${labels.currency.rupeesymbol}0`}</span></p>
                  <p style={{ fontSize: 14 }}>Total Units :<span> {this.props.totalTable.length != 0 && this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber).length != 0 ? <> {this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber)[0].TotalAvailableUnits}</> : `0`}</span></p>
                  <p style={{ fontSize: 14 }}>UCC :<span> {this.props.UCC}</span></p>

                </div>
              </div>
              {this.props.UCC == "" && <div style={{ fontSize: 12, color: colors.red }}>BSE star UCC is not mapped to this Folio</div>} */}
                {/* {console.log(this.props)} */}

                {this.props.UCC != "" ? <p style={{ fontSize: 14, textAlign: "left" }}>UCC :<span> {this.props.UCC || "NA"}</span></p> : <div style={{ fontSize: 12, color: colors.red, textAlign: "left" }}>BSE star UCC is not mapped to this Folio</div>}
              </div>
              <div className={classes.totalUnits}>
                <p style={{ fontSize: 14, paddingRight: 14 }}>Total Amount :<span> {this.props.totalTable.length != 0 && this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber).length != 0 ? <>{NegativeFinding(this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber)[0].TotalAvailableAmount, "", "", "", "rupee")}</> : `${labels.currency.rupeesymbol}0`}</span></p>
                <p style={{ fontSize: 14 }}>Total Units :<span> {this.props.totalTable.length != 0 && this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber).length != 0 ? <> {NegativeFinding(this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber)[0].TotalAvailableUnits, "", "", "", "number")}</> : `0`}</span></p>
              </div>
            </div>


          </div>


          <div className={classes.secondPartGrid}>
            <div className={classes.typeInput}>
              <Dropdown type={"normalwithsearch"}
                placeholder={"Select"}
                label={labels.SWP.SWPtype}
                error={this.props.errorswptypemessage}
                value={this.props.Swptype}
                List={labels.SWPtype}
                handleOnChange={this.props.SWPonchange}
              />
            </div>
            <div className={classes.amountInput}>
              <Textinput page={"stp"} message={"Minimum Amount ₹1,000"} error={this.props.erroramountmessage} label={labels.SWP.Amount} value={this.props.amount} placeholder={"₹ Enter amount"} handleChange={this.props.amountChange} />
            </div>

            <div className={classes.swpstarttime}>
              <DatePicker id={"swpdatepicker"}
                handleClose={this.handledateclose}
                anchorEl={this.state.anchorEl}
                handleClick={this.handledateClick}
                allowed={false}
                placeholder={"Select date"}
                handleApply={this.handleApply}
                dateChange={this.props.swpdatechange}
                error={this.props.errorswpdatemessage}
                label={labels.SWP.SWPStartDate}
                mindate={moment().format("DD MMM YYYY")}
                date={this.props.swpdate}
                showingdate={this.props.Selecteddate}
              />

              <CommonCheckbox label={labels.checkBox.firstOrderToday} onChange={this.props.onCheck} checked={this.props.check} />
              {this.props.check == true &&
                <div>
                  {this.props.swpdate != "Select date" || this.props.swpdate != "" ?
                    <div style={{ fontSize: 14, marginLeft: '-26%', marginTop: '2%' }} className>{labels.SWP.YourSWPStartDate} <span style={{ fontSize: 14, fontWeight: 600 }}>{this.props.swpdate}</span></div>
                    :
                    <div style={{ fontSize: 14, marginLeft: '-26%', marginTop: '2%' }}>{labels.SWP.YourSWPStartDate} <span style={{ fontSize: 14, fontWeight: 600 }}>{moment().format("DD MMM YYYY")}</span></div>

                  }
                </div>
              }
            </div>
            <div className={classes.swpInstalment}>
              <Textinput message={`Minimum Instalment ${this.props.minimumInstalment || 1}`} error={this.props.errorinstallmentmessage} page={"stp"} label={labels.SWP.SWPInstalments} value={this.props.installment} placeholder={"Enter Number of Instalments"} handleChange={this.props.installmentchange} />
            </div>
          </div>



        </div>
        <div className={classes.divLine1}></div>
        <div style={{ display: "grid", gridTemplateColumns: "70% 30%", marginTop: "8%" }}>
          <div></div>
          <CustomButton page="primary" onClick={() => this.props.onPlaceorder(true)} name={"Next"} disabled={this.props.UCC == "" ? true : false}></CustomButton>
          {/* <button className={classes.btnApply} style={{ height: "40px" }} onClick={() => this.props.onPlaceorder(true)}>{labels.SWP.PlaceOrder}</button> */}
        </div>
      </div>

    </ErrorBoundary>
    );
  }
}
const mapStateToProps = state => {
  return {
    typelist: state.Filter.typelist,
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(SWPDetails)))