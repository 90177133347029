import React from 'react';
import Button from '../custombutton/button'
import Dropdown from '../dropdown/dropdownschemes';
import Textinput from '../textinput/textinput';
import ToggleButton from '../toggle/toggle';
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import getAddbank from '../../api/profile/addbank'
import AccountNumberVerification from '../../api/profile/Accountnumberverification'
import getSearchBranch from '../../api/profile/searchbranch';
import { IFSCvalidation } from '../../utils/commonfunction'
import Autocomplete from '../../components/autocomplete/autocomplete';
import constants from '../../utils/constants/labels';
import Select from 'react-select'
import Search from '../../utils/assets/images/img1.png';
import colors from '../../utils/colors/colors';
import color from '../../utils/colors/colors';
import label from '../../utils/constants/labels';
import CommonSnackbar from '../snackbar/snackbar';
import Text from '../../components/text/text';
import { updatebankdetails, getSearchBranchDetails } from '../../redux/actions/profile/profile'
import { alphabetSpaceOnly, alphabetOnly, alphaNumericOnly, numbersOnly, accountNumberOnly } from '../../utils/commonfunction'
import { bindActionCreators } from 'redux';
import labels from '../../utils/constants/labels';
import BennyDropAPI from '../../api/profile/bennydropapi';
import getSubscriptionAddbank from '../../api/subscription/addbank';
let branchList = []
const styles = theme => ({
    heading1: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "larger",
        fontWeight: 600,
        textAlign: "left"
    },
    heading2: {
        color: window.globalConfig.color.PRIMARY_BLUE,
        fontSize: 17,
        fontWeight: 400,
        textAlign: "left"
    },
    headingMandate: {
        color: window.globalConfig.color.PRIMARY_BLUE,
        fontSize: 15,
        fontWeight: 600,
        textAlign: "left"
    },
    col: {
        display: "flex",
        flexDirection: "column",
        width: "45%",
        [theme.breakpoints.between('320', '374')]: {
            // marginTop: '4%',
            width: '90%',
        },
        [theme.breakpoints.between('375', '424')]: {
            // marginTop: '4%',
            width: '90%',
        },
        [theme.breakpoints.between('425', '600')]: {
            // marginTop: '4%',
            width: '90%',
        },
    },
    // col2: {
    //     display: "flex",
    //     flexDirection: "row", modified by dulcy
    //     marginLeft: "5%",
    //     justifyContent: "flex-end"
    // },
    cols: {
        // marginLeft: '2%',
        marginTop: '5%',
        width: '100%',
        display: "flex", flexDirection: "row",
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: ' wrap',
            justifyContent: 'center',
            marginLeft: '0%',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: ' wrap',
            justifyContent: 'center',
        },
    },
    // col3: {
    //     display: "flex",
    //     flexDirection: "row",modified by dulcy
    //     marginLeft: "20%"
    // },
    colheader1: {
        color: window.globalConfig.color.PRIMARY_BORDER,
        fontSize: "14px",
        textAlign: "left"
    },
    colheader2: {
        textAlign: "left",
        color: window.globalConfig.color.black,
        fontSize: "14px"
    },

    header: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: "white",
        height: 50,
        fontWeight: 500
    },
    txtHeader: {
        padding: 15,
    },
    dialogPaper: {
        height: 383,
        width: 535,
        // [theme.breakpoints.down('md')]: {
        //     width: '55%',
        // },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },

        //  overflowY: "hidden"
    },
    dialogPaperKnowIFSC: {
        height: "auto",
        width: 586,
        overflowX: 'hidden',
        // [theme.breakpoints.down('md')]: {
        //     width: '55%',
        // },
        // [theme.breakpoints.down('xs')]: {
        //     width: '55%',
        // },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        //  overflowY: "hidden"
    },
    dialogPaperKnowIFSCFirst: {
        height: 580,
        width: 647,
        // [theme.breakpoints.down('md')]: {
        //     width: '45%',
        // },
        // [theme.breakpoints.down('xs')]: {
        //     width: '55%',
        // },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        //  overflowY: "hidden"
    },
    bankDetailsDiv: {
        //  paddingLeft: "4%",
    },
    bankName: {
        display: 'flex',
        flexDirection: "row",
        marginTop: "3%",
        flex: 1
    },
    title: {
        flex: "5 1 25%",
        display: "flex",
        justifyContent: "flex-start"
    },
    imageDiv: {
        flex: 0.5,
        display: "flex",
        justifyContent: "flex-start",
    },
    image: {
        height: 20,
        width: 20,
        cursor: "pointer"
    },
    mainDiv: {
        //marginTop: "3%",
    },
    bankdetailsCardFirstRow: {
        display: 'Grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: '25px',
        marginTop: "3%",
        [theme.breakpoints.down('xs')]: {
            display: 'Grid',
            gridTemplateColumns: 'repeat(2, 50%)',
            // gridGap: '25px',
            gridColumnGap: '25px',
            gridRowGap: '10px',
        },

    },
    bankdetailsCardsecondRow: {
        //     display:'grid',
        //    gridTemplateColumns:'repeat(2, [col-start] 60% [col-end]'
        display: 'Grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: '25px',
        marginTop: "3%",
        marginBottom: "3%",
        [theme.breakpoints.down('xs')]: {
            display: 'Grid',
            gridTemplateColumns: 'repeat(2, 50%)',
            gridGap: '25px',
        },
    },
    branchNameDiv: {
        // display: "flex",
        // flexDirection: "column",
        // flex: 0.857,
        // marginTop: '-0.4%',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, [col-start] 65% [col-end])',
        gridGap: '25px',
        marginTop: '4%',
        [theme.breakpoints.down('xs')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 100%)',
            gridGap: '10px',
            marginTop: '4%',
            marginBottom: '6%',
        },
    },
    branchName: {
        display: "flex",
        flexDirection: "row",
        marginTop: "3%",
        [theme.breakpoints.down('xs')]: {
            // justifyContent: 'center',
        },
    },

    mandateCard: {
        padding: "1% 0 2% 2%",
        marginTop: "3% 0 3% 0",
        width: "92%",
        backgroundColor: window.globalConfig.color.lightGrayishOrange,

        [theme.breakpoints.down('xs')]: {
            width: "96%",
            padding: "1% 0 2% 1%",
        },
    },
    CardDiv: {
        // display: 'flex',
        // flexDirection: "row",
        marginTop: "3%",
        display: 'grid',
        gridTemplateColumns: 'repeat(2,69%)',
        // flex: 1,
        [theme.breakpoints.down('xs')]: {
            marginTop: "3%",
            display: 'grid',
            gridTemplateColumns: 'repeat(2, [col-start] 50% [col-end])',
        },
    },
    downloadMandateDiv: {
        flex: "0.5 1 25%",
        display: "flex",
        justifyContent: "flex-start",
        '&:hover': {
            cursor: 'pointer',

            color: window.globalConfig.color.BLACK_PRIMARY,
        }
    },
    headingMandateDownload: {
        color: window.globalConfig.color.PRIMARY_BLUE,
        fontSize: 15,
        fontWeight: 600,
        textAlign: "left",
        '&:hover': {
            cursor: 'pointer',
            color: window.globalConfig.color.highlightcolor,
        }
    },
    cardSpareaterLine: {
        height: 0.5,
        width: "100%",
        backgroundColor: window.globalConfig.color.AliceBlue
        , marginTop: "3%",
    },
    cardSpareaterLines: {
        height: 0.5,
        width: "100%",
        backgroundColor: window.globalConfig.color.PRIMARY_BORDER,
        marginTop: "3%",
        width: "96%",
    },
    madatebtn: {
        float: "left",
    },
    addBankbtn: {
        display: "flex",
        flexDirection: "row",
        //marginTop: "3%",
        marginBottom: "3%",
        justifyContent: "center",
    },
    outlinebtn: {
        marginRight: "5%",
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
        },
    },
    popupcard: {
        width: "100%",
        // paddingBottom: "2%"
    },
    popupFooterDiv: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        [theme.breakpoints.down('xs')]: {
            justifyContent: "space-between"
        },
    },
    ifscCodeMainCard: {
        //       padding: "5% 3% 3% 3%",
        marginTop: "2%",
        padding: "0px 15px",
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            marginLeft: '4.5%'
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.between('320', '374')]: {
            marginLeft: '5.5%'
        },
        [theme.breakpoints.between('601', '959')]: {
            marginLeft: '2.8%'
        },
    },
    ifscCodeCard: {
        display: "flex",
        flexDirection: "row",
        marginBottom: "4%",
        //  marginLeft: '2%',
        [theme.breakpoints.down('xs')]: {
            // justifyContent: 'center',
            marginLeft: '0%',
        },

    },
    ifscCodeCardResponsive: {
        display: "flex",
        flexDirection: "row",
        marginBottom: "4%",
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },

    },
    iFSCCode: {
        marginRight: "4%", color: window.globalConfig.color.LABLE_GRAY, fontSize: 14,
    },
    dropDownPopup:
    {
        width: '45%',
        display: "flex", flexDirection: "column", marginRight: "4%",
        cursor: 'pointer',
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        },
    },
    marginTopBankDetail: {
        marginTop: 12
    },
    iFSCCodeDiv: {
        //marginLeft: '2%',
        display: "flex", flexDirection: "row",
        [theme.breakpoints.down('xs')]: {
            display: "flex", flexDirection: "column",
            //  marginTop: "4%", 
            marginLeft: '0%',
        },
    },
    dropdownDiv: {
        marginRight: "4%",
        [theme.breakpoints.down('xs')]: {
            width: '98%',
        },
    },
    dropdownBank: {
        // width: "260px !important",
        //marginTop:23

        [theme.breakpoints.up('600')]: {
            width: '46.5%',
        },
        [theme.breakpoints.down('600')]: {
            width: '90%',
        },
    },
    reAccountNumber: {
        marginRight: "4%",
        width: "45.5%",
        [theme.breakpoints.down('xs')]: {
            width: '90%',
        },
    },
    // footerCard: {
    //     display: "flex", flex: "0.5 0.5 42%",
    // },
    noDatastyle: {
        marginTop: '7%',
        marginBottom: '7%',
        marginLeft: '-18%'
    },
    button: {
        width: 'auto',
        padding: "7px 22px",
        borderRadius: '22px',
        borderColor: window.globalConfig.color.PRIMARY_BLUE,
        color: window.globalConfig.color.PRIMARY_BLUE,
        textTransform: "none",
        [theme.breakpoints.down('xs')]: {
            width: '95%',
        },
    },
    clearIcon: {
        float: 'right',
        cursor: 'pointer'
    },
    marginTopTextAlign: {
        width: '45%',
        // marginLeft:"4%",
        [theme.breakpoints.down('600')]: {
            marginTop: '13px',
            width: '90%',
        },
    },
    submitButton: {
        width: "133px !important"
    },
    buttonStyle: {
        width: "122px",
        marginRight: "10px"
    },
    marginTopTextBoxAlign: {
        // marginRight: '10%',
        // [theme.breakpoints.down('xs')]: {
        //     marginTop: '4%',
        //     width: '0',
        //     marginLeft: '0%'
        //     // width:'95%',
        // },
        width: '45%',
        marginLeft: '5%',
        [theme.breakpoints.up("600")]: {
            marginLeft: "5.7%"
        },
        [theme.breakpoints.between('320', '374')]: {
            marginTop: '4%',
            width: '90%',
            marginLeft: '0%',
        },
        [theme.breakpoints.between('375', '424')]: {
            marginTop: '4%',
            width: '90%',
            marginLeft: '0%',
        },
        [theme.breakpoints.between('425', '600')]: {
            marginTop: '4%',
            marginLeft: '0%',
            width: '90%',
        },
        [theme.breakpoints.between('601', '959')]: {
            marginTop: '4%',
            width: '46.5%',
            marginLeft: '-0.2%',
        },
    },
    marginAlignText: {
        marginLeft: '4%',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0%',
            width: '95%',
            marginTop: '4%',
        },
    },
    marginAlignmenHeading: {
        //  marginLeft: "6%",
        [theme.breakpoints.down('xs')]: {
            //   marginLeft: "2%",
        },
    },
    buttonDivNoIFSC: {
        float: 'right',
        // marginRight: '5%',
        marginTop: 38,
        display: "flex",
        flexDirection: "row",
        gap: 10,
        marginRight: 10,
        justifyContent: "space-between",
        [theme.breakpoints.down('xs')]: {
            float: 'none',
            margin: 10,
            padding: "20px 0px",
            [theme.breakpoints.between('500', '600')]: {
                padding: "10px 0px",
            },
        },
    },
    buttonDivIFSC: {
        float: 'right',
        //marginRight: '3%',
        //  marginTop: 55,
        marginBottom: "2%",
        display: "flex",
        flexDirection: "row",
        gap: 10,
        marginRight: 10,
        [theme.breakpoints.down('xs')]: {
            float: 'none',
            margin: 10,
            // padding: "20px 0px",
            [theme.breakpoints.between('500', '600')]: {
                padding: "10px 0px",
            },
        },
    },
    selectedbankDetails: {
        // height: '26%'
    },
    imageDesign: {
        width: 30,
        height: 30,
        cursor: "pointer"
    }

});
let timer = ''
class AddbankPopup extends React.Component {
    constructor() {
        super();
        this.state = {
            show: false,
            openbankpopup: false,
            selected: false,
            checked: false,
            popupOpen: false,
            open: false,
            severity: "",
            message: "",
            BankAccounttype: "",
            BankAccounttypeID: 0,
            accountnumber: "",
            iFSCCode: "",
            errorbankaccounttype: "",
            erroraccountnumber: "",
            errorifsccode: "",
            bankarray: [],
            bankname: "",
            bankid: "",
            errorbankName: "", branchName: "",
            reenteraccountnumber: "",
            errorreenteraccountnumber: "",
            branchFullList: [],
            errorbranchname: "",
            loader: false,
            branchList: [],
            branchDetails: [],
            isLoading: false
        }
    }
    showBankDetails = (value, bankname) => {
        // 
        this.setState({ popupOpen: false })
        if (value != null && value != undefined && value.status == "S") {
            this.setState({ open: true, severity: "success", message: label.mandate.registrationSuccessMessage })
        } else if (value.status == 400) {
            this.setState({ open: true, severity: "warning", message: label.messages.apierror })
        }
        else {
            this.setState({ open: true, severity: "warning", message: value.message })
        }
    }
    componentDidMount() {
        this.setState({ selected: false })
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };
    handlechange = (event) => {
        // 
        this.setState({ BankAccounttype: event[0].label, errorbankaccounttype: "", BankAccounttypeID: event[0].value })
    }
    handleonchangeaccountnumber = (event) => {
        if (accountNumberOnly(event.target.value)) {
            this.setState({ accountnumber: event.target.value, erroraccountnumber: "" })
        }
    }
    handleonchangereenteraccountnumber = (event) => {
        if (accountNumberOnly(event.target.value)) {
            this.setState({ reenteraccountnumber: event.target.value, errorreenteraccountnumber: "" })
        }

    }
    handleonchangeifsc = (event) => {
        if (event.target.value.length < 12) {
            if (alphaNumericOnly(event.target.value)) {

                this.setState({ iFSCCode: event.target.value, errorifsccode: "" })
            }
        } else {
            event.preventDefault()
        }

    }
    togglechange = () => {
        branchList = []
        this.setState({ selected: !this.state.selected })
        this.setState({
            show: false,
            openbankpopup: false,
            //selected: false,
            checked: false,
            popupOpen: false,
            open: false,
            severity: "",
            message: "",
            BankAccounttype: "",
            BankAccounttypeID: 0,
            accountnumber: "",
            iFSCCode: "",
            errorbankaccounttype: "",
            erroraccountnumber: "",
            errorifsccode: "",
            bankarray: [],
            bankname: "",
            bankid: "",
            errorbankName: "", branchName: "",
            reenteraccountnumber: "",
            errorreenteraccountnumber: "",
            branchFullList: [],
            errorbranchname: "",
            loader: false,
            branchList: [],
            branchDetails: []
        })
    }
    onChangebranch = (value) => {
        // 

        this.setState({ errorbranchname: "" })

        if (value.length > 2) {
            if (this.state.bankid != "") {
                this.setState({ isLoading: true })
                getSearchBranch(this.state.bankid, value).then((res) => {
                    this.setState({ isLoading: false })
                    if (res.status == "S") {

                        //this.getbranch(res.data)
                        if (res.data != null) {
                            branchList = res.data.length != 0 ? res.data.map((item) => {
                                return { "label": item.BranchName, "value": item.IFSCCode }
                            }) : []
                            this.setState({ branchDetails: [...res.data] })
                        } else {

                        }
                    }
                }).catch((err) => {
                    this.setState({ isLoading: false })
                })
            } else {
                this.setState({ errorbankName: label.messages.bankrequired })
            }
        }

    }
    getbranch = (event) => {
        // 
        if (event == null || event == undefined || event == "") {
            this.setState({ branchName: "", iFSCCode: "" })
        } else {
            this.setState({ branchName: event.label, iFSCCode: event.value })
        }
    }
    addbankclick = () => {
        let isValid = true;
        let typeIsValid = true;
        let accountIsValid = true;
        let bankIsValid = true;
        let confirmAccountIsvalid = true;
        let branchIsValid = true;

        if (!this.state.selected) {
            if (this.state.BankAccounttype == "") {
                typeIsValid = false
                this.setState({ errorbankaccounttype: label.messages.required })
            }
            if (this.state.accountnumber == "") {
                accountIsValid = false
                this.setState({ erroraccountnumber: label.messages.required })
            }
            if (this.state.iFSCCode == "") {
                isValid = false
                this.setState({ errorifsccode: label.messages.required })
            } else {
                if (IFSCvalidation(this.state.iFSCCode)) {
                    isValid = true
                } else {
                    isValid = false
                    this.setState({ errorifsccode: label.messages.Ifscerror })
                }
            }

        } else {
            if (this.state.BankAccounttype == "") {
                typeIsValid = false
                this.setState({ errorbankaccounttype: label.messages.required })
            }
            if (this.state.accountnumber == "") {
                accountIsValid = false
                this.setState({ erroraccountnumber: label.messages.required })
            }
            if (this.state.reenteraccountnumber == "") {
                confirmAccountIsvalid = false
                this.setState({ errorreenteraccountnumber: label.messages.required })
            }
            if (this.state.bankname == "") {
                bankIsValid = false
                this.setState({ errorbankName: label.messages.required })
            }
            if (this.state.branchName === "") {
                branchIsValid = false
                this.setState({ errorbranchname: label.messages.required })
            }
            if (this.state.accountnumber != this.state.reenteraccountnumber) {
                confirmAccountIsvalid = false
                this.setState({ errorreenteraccountnumber: label.messages.mismatch })
            }
        }
        if (isValid && typeIsValid && bankIsValid && accountIsValid && confirmAccountIsvalid && branchIsValid) {
            if (this.state.selected) {
                if (this.state.iFSCCode != "") {
                    if (!this.state.loader) {
                        this.setState({ loader: true })
                        BennyDropAPI(this.state.accountnumber, this.state.iFSCCode,).then((result) => {
                            if (result.success) {
                                getSubscriptionAddbank(0, this.props.logindetails.ClientID, this.state.BankAccounttypeID, this.state.iFSCCode, 0, this.state.accountnumber, this.props.logindetails.UserID).then((response) => {
                                    if (response.status == "S") {
                                        // this.props.updatebankdetails(this.props.logindetails.ClientID, this.state.BankAccounttype, this.state.iFSCCode, 0, this.state.accountnumber, response, this.props.TradeUcccode, this.props.accountno)
                                        this.setState({ openbankpopup: false })
                                        this.props.onClose()
                                        this.setState({
                                            show: false,
                                            openbankpopup: false,
                                            selected: false,
                                            checked: false,
                                            popupOpen: false,
                                            open: true,
                                            severity: "success",
                                            message: "Bank Added Successfully",
                                            BankAccounttype: "",
                                            BankAccounttypeID: 0,
                                            accountnumber: "",
                                            iFSCCode: "",
                                            errorbankaccounttype: "",
                                            erroraccountnumber: "",
                                            errorifsccode: "",
                                            bankarray: [],
                                            bankname: "",
                                            bankid: "",
                                            errorbankName: "", branchName: "",
                                            reenteraccountnumber: "",
                                            errorreenteraccountnumber: "",
                                            errorbranchname: "",
                                        })
                                        this.setState({ loader: false })
                                    }
                                    else {
                                        this.setState({
                                            loader: false, open: true,
                                            severity: "warning",
                                            message: response.message,
                                            loader: false
                                        })
                                    }
                                })
                                // getAddbank(0, this.props.logindetails.ClientID, this.state.BankAccounttypeID, this.state.iFSCCode, 0, this.state.accountnumber, 1, this.props.TradingL5ID).then((response) => {
                                //     if (response.status == "S") {
                                //         this.props.updatebankdetails(this.props.logindetails.ClientID, this.state.BankAccounttype, this.state.iFSCCode, 0, this.state.accountnumber, response, this.props.TradeUcccode, this.props.accountno)
                                //         this.setState({ openbankpopup: false })
                                //         this.props.onClose()
                                //         this.setState({
                                //             show: false,
                                //             openbankpopup: false,
                                //             selected: false,
                                //             checked: false,
                                //             popupOpen: false,
                                //             open: true,
                                //             severity: "success",
                                //             message: "Bank Added Successfully",
                                //             BankAccounttype: "",
                                //             BankAccounttypeID: 0,
                                //             accountnumber: "",
                                //             iFSCCode: "",
                                //             errorbankaccounttype: "",
                                //             erroraccountnumber: "",
                                //             errorifsccode: "",
                                //             bankarray: [],
                                //             bankname: "",
                                //             bankid: "",
                                //             errorbankName: "", branchName: "",
                                //             reenteraccountnumber: "",
                                //             errorreenteraccountnumber: "",
                                //             errorbranchname: "",
                                //         })
                                //         this.setState({ loader: false })
                                //     }
                                //     else {
                                //         this.setState({
                                //             loader: false, open: true,
                                //             severity: "warning",
                                //             message: response.message,
                                //             loader: false
                                //         })
                                //     }
                                // })
                            } else {
                                this.setState({
                                    loader: false, open: true,
                                    severity: "warning",
                                    message: result.message
                                })
                            }
                        }).catch((err) => {
                            this.setState({
                                loader: false, open: true,
                                severity: "warning",
                                message: label.messages.apierror
                            })
                            // this.setState({ erroraccountnumber: label.messages.apierror })
                        })
                    }
                }
                else {
                    this.setState({
                        loader: false, open: true,
                        severity: "warning",
                        message: "Please select Branch Name"
                    })
                }
            } else {
                if (!this.state.loader) {
                    this.setState({ loader: true })

                    BennyDropAPI(this.state.accountnumber, this.state.iFSCCode,).then((result) => {
                        if (result.success) {
                            getSubscriptionAddbank(0, this.props.logindetails.ClientID, this.state.BankAccounttypeID, this.state.iFSCCode, 0, this.state.accountnumber, 1).then((response) => {
                                if (response.status == "S") {
                                    // this.props.updatebankdetails(this.props.logindetails.ClientID, this.state.BankAccounttype, this.state.iFSCCode, 0, this.state.accountnumber, response, this.props.TradeUcccode, this.props.accountno)
                                    this.setState({ openbankpopup: false })
                                    this.props.onClose()
                                    this.setState({
                                        show: false,
                                        openbankpopup: false,
                                        selected: false,
                                        checked: false,
                                        popupOpen: false,
                                        open: true,
                                        severity: "success",
                                        message: "Bank Added Successfully",
                                        BankAccounttype: "",
                                        BankAccounttypeID: 0,
                                        accountnumber: "",
                                        iFSCCode: "",
                                        errorbankaccounttype: "",
                                        erroraccountnumber: "",
                                        errorifsccode: "",
                                        bankarray: [],
                                        bankname: "",
                                        bankid: "",
                                        errorbankName: "", branchName: "",
                                        reenteraccountnumber: "",
                                        errorreenteraccountnumber: "",
                                        errorbranchname: "",
                                    })
                                    this.setState({ loader: false })
                                }
                                else {
                                    this.setState({
                                        loader: false, open: true,
                                        severity: "warning",
                                        message: response.message,
                                        loader: false
                                    })
                                }
                            })
                        } else {
                            this.setState({
                                loader: false, open: true,
                                severity: "warning",
                                message: result.message,
                                loader: false
                            })

                        }
                    }).catch((err) => {
                        this.setState({
                            loader: false, open: true,
                            severity: "warning",
                            message: labels.messages.apierror
                        })
                    })
                }
            }

        }
        timer = setTimeout(() => {
            this.setState({ open: false, loader: false })
        }, 2000);
    }
    componentWillUnmount() {
        clearTimeout(timer)
    }
    handlebankchange = (event) => {
        /// 
        /// this.getbranch("")
        branchList = []
        this.setState({
            bankname: event[0].label, bankid: event[0].value, errorbankName: "",
            bankList: [], branchName: "", iFSCCode: ""
        })

    }
    onClosePopup = () => {
        branchList = []
        this.setState({
            BankAccounttype: "",
            selected: false,
            accountnumber: "",
            iFSCCode: "",
            reenteraccountnumber: "",
            errorbankaccounttype: "",
            erroraccountnumber: "",
            errorbankaccounttype: "",
            errorreenteraccountnumber: "",
            errorifsccode: "",
            errorbankName: "",
            errorbranchname: "", branchList: [],
            branchFullList: [],
            openbankpopup: true, branchDetails: [], bankname: "", branchName: ""
        })
        this.props.onClose()
    }
    render() {
        const { classes } = this.props;
        //        
        return (
            <Dialog

                open={this.props.open}
                onClose={this.onClosePopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: !this.state.selected ? classes.dialogPaper : this.props.branchDetail != null && this.props.branchDetail.length == 0 ? classes.dialogPaperKnowIFSC : classes.dialogPaperKnowIFSCFirst }}

            >
                <div className={classes.popupcard}>
                    <div className={classes.header}>
                        <div className={classes.txtHeader}>{label.bankDetails.AddNewBank}
                            <span className={classes.clearIcon} onClick={this.onClosePopup}>&#x2715;</span>
                        </div>
                    </div>
                    <div className={classes.ifscCodeMainCard}>
                        <div className={classes.ifscCodeCard}>
                            <span className={classes.iFSCCode}>{label.bankDetails.IKnowBankIFSCCode}</span>
                            <ToggleButton label1={labels.bankDetails.yes} label2={labels.bankDetails.no} selected={this.state.selected}
                                toggleSelected={this.togglechange} />
                        </div>
                        {!this.state.selected ? <>  <div className={classes.cols}>
                            <div className={classes.dropDownPopup}>
                                <Dropdown type={"normalwithsearch"}
                                    autoselect={true}
                                    height={135}
                                    handleOnChange={this.handlechange}
                                    values={this.state.BankAccounttype}
                                    error={this.state.errorbankaccounttype}
                                    id={4}
                                    placeholder={label.bankDetails.SelectBankAccountType}
                                    label={label.bankDetails.BankAccountType}
                                    List={constants.AccountType} />
                            </div>
                            <div className={classes.marginTopTextAlign}>
                                <Textinput handleChange={this.handleonchangeaccountnumber} value={this.state.accountnumber} error={this.state.erroraccountnumber} page={"normal"} label={label.bankDetails.AccountNumber} id="txt_accountno" placeholder={label.bankDetails.EnterAccountNumber} />
                            </div>
                        </div>
                            <div className={classes.iFSCCodeDiv} >
                                <div className={classes.col} >
                                    <Textinput handleChange={this.handleonchangeifsc} value={this.state.iFSCCode} error={this.state.errorifsccode} page={"normal"} id="txt_ifsc" label={label.bankDetails.IFSCCode} placeholder={label.bankDetails.EnterIFSCCode} />
                                    <div>
                                    </div>
                                </div>
                            </div></> : <><div className={classes.cols}>

                                <div className={classes.dropdownBank} >
                                    <Dropdown isSearchable={true}
                                        searchPlaceholder={labels.bankDetails.searchBankName}
                                        type={"normalwithsearch"}
                                        height={135}
                                        id={1}
                                        handleOnChange={this.handlebankchange}
                                        List={this.props.BankList}
                                        placeholder={label.bankDetails.SelectBankName}
                                        label={label.bankDetails.BankName} />

                                    <span className={classes.labelDropdownGap} style={{ color: window.globalConfig.color.red, fontSize: 14 }}>{this.state.errorbankName}</span>

                                </div>
                                <div className={classes.marginTopTextBoxAlign} >
                                    <Autocomplete placeholder={labels.bankDetails.searchBranchName}
                                        value={this.state.branchName}
                                        // selectedOption={this.state.branchName}
                                        selectvalue={value => this.getbranch(value)}
                                        label={label.bankDetails.Branch}
                                        options={branchList}
                                        Loading={this.state.isLoading}
                                        error={this.state.errorbranchname}
                                        onChange={(e) => this.onChangebranch(e)}
                                        startingmessage={labels.bankDetails.enterThreeOrMoreChar}
                                        style={{ outline: "none", padding: "14px 0px 14px 7px", border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, color: window.globalConfig.color.BLACK_PRIMARY, fontSize: 13, minHeight: 15, textAlign: "left", paddingLeft: 0, paddingRight: 10 }} />

                                    <span className={classes.labelDropdownGap} style={{ color: window.globalConfig.color.red, fontSize: 14 }}>{this.state.errorbranchname}</span>
                                </div>
                            </div>

                            {this.state.branchDetails.length != 0 && this.state.branchName != "" && this.state.bankname != "" && this.state.branchDetails.filter((value) => value.BranchName === this.state.branchName && value.IFSCCode === this.state.iFSCCode).length > 0 && this.state.branchDetails.filter((value) => value.BranchName === this.state.branchName && value.IFSCCode === this.state.iFSCCode).slice(0, 1).map((item) => {
                                return (
                                    <div className={[classes.iFSCCodeDiv, classes.marginTopBankDetail].join(' ')} style={{ width: '97% ', }}>
                                        <div style={{ backgroundColor: window.globalConfig.color.lightGrayishOrange, height: "auto", padding: '0px 0px 0px 10px' }}>
                                            <div className={classes.CardDiv}>
                                                <div className={classes.title} >
                                                    <span className={classes.headingMandate}>{label.bankDetails.BranchName} : <span>{item.BranchName}</span></span>
                                                </div>
                                                <div className={classes.downloadMandateDiv} style={{ textAlign: 'right' }}>
                                                    <span className={classes.headingMandate}>{label.bankDetails.IFSC} : <span>{item.IFSCCode}</span></span>
                                                </div>
                                            </div>
                                            <div className={classes.bankdetailsCardFirstRow}>
                                                <Text label={label.bankDetails.AddressLine} text={item.AddressLine1} page={"normal"} />
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                            <div className={classes.cols}>

                                <div className={classes.dropdownBank} >
                                    <Dropdown type={"normalwithsearch"}
                                        autoselect={true}
                                        handleOnChange={this.handlechange}
                                        values={this.state.BankAccounttype}
                                        error={this.state.errorbankaccounttype}
                                        page={"normal"}
                                        id={3}
                                        placeholder={label.bankDetails.SelectBankAccountType}
                                        label={label.bankDetails.BankAccountType}
                                        List={constants.AccountType} />

                                </div>
                                <div className={classes.marginTopTextBoxAlign}>
                                    {/* <AutoComplete getbranch={this.getbranch} errormessage={this.state.errorbranchname} onChange={this.onChangebranch} placeholder={label.bankDetails.SelectBranch} label={label.bankDetails.Branch} suggestions={this.state.branchList} />                         */}
                                    <Textinput encrypted={true} page={"normal"} label={label.bankDetails.AccountNumber} error={this.state.erroraccountnumber} handleChange={this.handleonchangeaccountnumber} value={this.state.accountnumber} placeholder={label.bankDetails.EnterAccountNo} />

                                </div>
                            </div>
                            <div className={classes.iFSCCodeDiv}>
                                <div className={classes.reAccountNumber}>
                                    <Textinput page={"normal"} label={label.bankDetails.ReEnterAccountNumber} error={this.state.errorreenteraccountnumber} handleChange={this.handleonchangereenteraccountnumber} value={this.state.reenteraccountnumber} placeholder={label.bankDetails.EnterReEnterAccountNo} />
                                </div>
                            </div>
                        </>}
                    </div>
                    <div className={classes.selectedbankDetails}>
                        {/* Bank Details comes here */}
                    </div>
                    {!this.state.selected ?

                        <div className={classes.buttonDivNoIFSC}>
                            {/* <div className={classes.popupFooterDiv} >
                                <div className={classes.buttonStyle}> */}
                            <Button page={"secondary"} name={label.bankDetails.Cancel} onClick={this.onClosePopup} />
                            {/* </div><div className={classes.buttonStyle} style={{ marginRight: 19 }}> */}
                            <Button page={this.state.loader ? "secondary" : "primary"} className={classes.submitButton} disabled={this.state.loader} name={label.bankDetails.AddBank} onClick={this.addbankclick} loader={this.state.loader} show={this.state.loader} />
                            {/* </div> */}
                            {/* </div> */}
                        </div>
                        :

                        <div className={classes.buttonDivIFSC} >
                            {/* <div className={classes.footerCard} style={{ display: "flex", flex: "0.5 0.5 42%" }}>
        </div> */}
                            <div className={classes.popupFooterDiv}>
                                {/* <div className={classes.buttonStyle}> */}
                                <Button page={"secondary"} name={label.bankDetails.Cancel} onClick={this.onClosePopup} />
                                {/* </div>
                                <div className={classes.buttonStyle} style={{ marginRight: 19 }}> */}
                                <Button page={this.state.loader ? "secondary" : "primary"} className={classes.submitButton} show={this.state.loader} name={label.bankDetails.AddBank} disabled={this.state.loader} onClick={this.addbankclick} loader={this.state.loader} />
                                {/* </div> */}
                            </div>
                        </div>
                    }

                </div>
                <CommonSnackbar severity={this.state.severity} handlesnanckClose={this.handlesnanckClose} open={this.state.open} message={this.state.message} />

            </Dialog>
        )
    }
}
const mapStateToProps = state => ({
    login: state.Login.loginError,
    bankDetails: state.ProfileDetails.AllProfileDetails.bankDetails != "NA" ? state.ProfileDetails.AllProfileDetails.bankDetails : [],
    mandateDetails: state.ProfileDetails.AllProfileDetails.mandateDetails != "NA" ? state.ProfileDetails.AllProfileDetails.mandateDetails : [],
    logindetails: state.Login.logindetails,
    branchDetail: state.ProfileDetails.BranchName != null ? state.ProfileDetails.BranchName.length != 0 ? state.ProfileDetails.BranchName.data : [] : [],
    // BranchName: state.ProfileDetails.BranchName != null ? state.ProfileDetails.BranchName.length != 0 ? state.ProfileDetails.BranchName.data.length != 0 ? state.ProfileDetails.BranchName.data.map((item) => {
    //     return item.BranchName
    // }) : [] : [] : []
    //,\
    TradingL5ID: state.Login.TradingL5ID,
    TradeUcccode: state.Login.TradeUcccode,
    BankList: state.Dropdown.dropdowndetails.banks != null ? state.Dropdown.dropdowndetails.banks.length != 0 ? state.Dropdown.dropdowndetails.banks.map((item) => {
        return { "label": item.BankName, "value": item.BankID }
    }) : [] : []
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ updatebankdetails, getSearchBranchDetails }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddbankPopup))

