import React from 'react';
import '../../utils/stylesheets/expandable-table/expandabletable.css'
import Loader from '../../components/loader/loader'
import NoData from '../../components/nodata/nodata'
import Label from '../../utils/constants/labels'
import color from "../../utils/colors/colors";
import { handleExpand } from '../../redux/actions/summary/summaryaction';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router'
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import labels from '../../utils/constants/labels';
import colors from '../../utils/colors/colors';
import { NegativeFinding } from '../../utils/commonfunction';
const styles = theme => ({
    tableHeader: {
        display: "grid", textAlign: "right", padding: "13px 2px", borderRadius: 2, borderBottom: `1px solid ${colors.BORDER}`,

    },
    mainContent: {
        textAlign: "left", borderBottom: `1px solid ${color.BORDER}`, padding: "15px 6px", backgroundColor: color.LIGHT_ROSE, cursor: "pointer"
        ,
        [theme.breakpoints.down('920',)]: {
            gridTemplateColumns: "50% 50%"
        }
    },
    subContent: {
        padding: "15px 10px", borderBottom: `1px solid ${color.BORDER}`, fontSize: 14, fontWeight: 400, color: colors.LABLE_GRAY

    },
    totalContainer: {
        display: "grid",
        borderBottomLeftRadius: 10,
        borderBottomRightRadius: 10,
        backgroundColor: colors.BLACK_PRIMARY, color: colors.WHITE, gridTemplateColumns: "37% 25% 22% 14%", textAlign: "right", padding: "15px 0px"
        , [theme.breakpoints.down('920',)]: {
            gridTemplateColumns: "50% 50%"
        }
    },
    justifyContentEnd: {
        display: "flex", flexDirection: "row", justifyContent: "flex-end"
    },
    alignLeft: {
        textAlign: "left"
    },
    alignRight: {
        textAlign: "right"
    },
    flexRow: {
        display: "flex", flexDirection: "row", alignItems: "center"
    },
    checkBoxOuter: {
        display: "flex", padding: 2, flexDirection: "column", justifyContent: "center", alignItems: "center", height: 11, width: 11
    },
    checkBoxInner: {
        width: "100%", height: "100%"
    },
    mobileResponsive: {
        [theme.breakpoints.down('920',)]: {
            display: "none"
        },
    },
    mainHeader: {
        maxHeight: 400,
        [theme.breakpoints.down('920',)]: {
            marginLeft: 10,
            marginRight: 10
        }
    },
    dataOne: {
        [theme.breakpoints.down("920")]: {
            gridArea: "dataOne"
        }
    },
    dataTwo: {
        [theme.breakpoints.down("920")]: {
            gridArea: "dataTwo"
        }
    },
    dataThree: {
        [theme.breakpoints.down("920")]: {
            gridArea: "dataThree"
        }
    },
    dataFour: {
        [theme.breakpoints.down("920")]: {
            gridArea: "dataFour"
        }
    },
    subDataOne: {
        [theme.breakpoints.down("920")]: {
            gridArea: "subDataOne"
        }
    },
    subDataTwo: {
        [theme.breakpoints.down("920")]: {
            gridArea: "subDataTwo"
        }
    },
    subDataThree: {
        [theme.breakpoints.down("920")]: {
            gridArea: "subDataThree"
        }
    },
    subDataFour: {
        [theme.breakpoints.down("920")]: {
            gridArea: "subDataFour"
        }
    },
    subDataTwoSpecialChar:{
        marginRight : 4
    }

})
class ParentComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            expandedRows: []
        };
    }
    componentDidMount() {

    }

    handleRowClick(e, rowId, category, data) {
        if (this.props.handleExpand != undefined && data != undefined && this.props.flag != labels.ManuHeading.familyPortfolio) {
            this.props.handleExpand(rowId, this.props.flag, this.props.summaryData)
        }else{
            if(this.props.collapseTable!=undefined){
                this.props.collapseTable(rowId, this.props.flag, this.props.summaryData)
            }
        }
    }



    render() {
        const { classes } = this.props
        // {console.log(this.props.loader)}
        return (<div className={classes.mainHeader}>
            {this.props.loader ? <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
                <Loader page="table" />
            </div> : <><div style={{ borderTop: `1px solid ${colors.BORDER}`, borderBottomLeftRadius: this.props.grandtotal == undefined ? 10 : 0, borderBottomRightRadius: this.props.grandtotal == undefined ? 10 : 0, borderBottom: this.props.grandtotal == undefined ? `1px solid ${colors.BORDER}` : "none", borderTopLeftRadius: 10, borderTopRightRadius: 10, borderLeft: `1px solid ${colors.BORDER}`, borderRight: `1px solid ${colors.BORDER}` }}>
                <div className={[classes.tableHeader, this.props.grid.subGrid].join(" ")}>
                    {this.props.tableHeader.map((item, index) => {
                        return <div key={index} style={{ textAlign: index == 0 ? "left" : "right", fontSize: 12, fontWeight: 500, textTransform: "uppercase", color: colors.LABLE_GRAY, }} className={[index == 3 || index == 2 ? classes.mobileResponsive : "",].join(" ")}>{item}</div>
                    })}
                </div>

                <div style={{ maxHeight: 300, overflowY: "overlay" }}>
                    {this.props.data.length != 0 ? this.props.data.map((item, index) => {
                        return (<div key={index} >
                            <div onClick={(e) => this.handleRowClick(index, index, item.dataOne, item.subData)} style={{ cursor: item.subData != undefined ? "pointer" : "auto" }} className={[classes.mainContent, this.props.grid.mainGrid].join(" ")} id={`mainContent${index}`} >
                                {item.dataOne != undefined && <div className={[classes.flexRow, classes.dataOne, "dataOne",`dataOne${index}`].join(" ")}>
                                    {item.showCheckBox && <div style={{ border: `1px solid ${item.color}`, }} className={classes.checkBoxOuter} >
                                        <div style={{ backgroundColor: item.color, }} className={classes.checkBoxInner}></div>
                                    </div>}
                                    <div style={{ display: 'flex', paddingLeft: 8, textAlign: "left" }} >

                                        {this.props.search != undefined && item.dataOne?.toString()?.search(this.props.search) != -1 && <span className={"dataOneSpecialChar"}>{this.props.search}{" "} </span>}
                                        {item.dataOne?.toString().replace(this.props.search, "")}
                                    </div>
                                </div>}
                                {item.dataTwo != undefined && <div className={[classes.justifyContentEnd, classes.dataTwo, "dataTwo",`dataTwo${index}`].join(" ")}>
                                    {this.props.search != undefined && item.dataTwo?.toString()?.search(this.props.search) != -1 && <span className={classes.subDataTwoSpecialChar}>{this.props.search} {" "}</span>}
                                    {item.dataTwo?.toString().replace(this.props.search, "")}

                                </div>}
                                {item.dataThree != undefined && <div className={[classes.alignRight, classes.mobileResponsive, classes.dataThree, "dataThree",`dataThree${index}`].join(" ")}>
                                    {this.props.search != undefined && item.dataThree?.toString()?.search(this.props.search) != -1 && <span className={"dataThreeSpecialChar"}>{this.props.search} {" "}</span>}
                                    {item.dataThree?.toString().replace(this.props.search, "")}

                                </div>}
                                {item.dataFour != undefined && <div className={[classes.justifyContentEnd, classes.mobileResponsive, classes.dataFour, "dataFour",`dataFour${index}`].join(" ")}>

                                    {this.props.search != undefined && item.dataFour?.toString()?.search(this.props.search) != -1 && <span className={"dataFourSpecialChar"}>{this.props.search} {" "}</span>}

                                    {item.dataFour?.toString().replace(this.props.search, "")}

                                </div>}
                            </div>
                            {item?.isExpand &&
                                item.subData != undefined && <div className={classes.alignLeft} >

                                    {item?.subData.map((value, index) => {
                                        return (
                                            <div key={index} className={[classes.subContent, this.props.grid.subGrid].join(" ")}>
                                                {value.dataOne != undefined && <div className={[classes.subDataOne, "subDataOne",`subDataOne${value.type}`].join(" ")}>
                                                    {this.props.search != undefined && value.dataOne?.toString()?.search(this.props.search) != -1 && <span className={"subDataOneSpecialChar"}>{this.props.search} </span>}
                                                    {value.dataOne?.toString()?.replace(this.props.search, "")}
                                                </div>}
                                                {value.dataTwo != undefined && <div className={[classes.justifyContentEnd, classes.subDataTwo, "subDataTwo",`subDataTwo${value.type}`].join(" ")}>
                                                    {this.props.search != undefined && value.dataTwo?.toString()?.search(this.props.search) != -1 && <span className={classes.subDataTwoSpecialChar}>{this.props.search} </span>}
                                                    {value.dataTwo?.toString()?.replace(this.props.search, "")}
                                                </div>}
                                                {value.dataThree != undefined && <div className={[classes.alignRight, classes.mobileResponsive, classes.subDataThree,`subDataThree${value.type}`, "subDataThree"].join(" ")}>
                                                    {this.props.search != undefined && value.dataThree?.toString()?.search(this.props.search) != -1 && <span className={"subDataThreeSpecialChar"}>{this.props.search} </span>}
                                                    {value.dataThree?.toString()?.replace(this.props.search, "")}
                                                </div>}
                                                {value.dataFour != undefined && <div className={[classes.alignRight, classes.mobileResponsive, classes.subDataFour,`subDataFour${value.type}`, "subDataFour"].join(" ")}>
                                                    {this.props.search != undefined && value.dataFour?.toString()?.search(this.props.search) != -1 && <span className={"subDataFourSpecialChar"}>{this.props.search} </span>}
                                                    {value.dataFour?.toString()?.replace(this.props.search, "")}
                                                </div>}
                                            </div>
                                        )
                                    })}
                                </div>}
                        </div>)
                    }) : <div style={{ padding: 40 }}><NoData></NoData></div>}
                </div>


            </div>
                {this.props.grandtotal != undefined && <div className={[classes.totalContainer, this.props.grid.mainGrid].join(" ")} style={{ paddingRight: 10 }}>
                    {this.props.grandtotal.map((item, index) => {
                        return (
                            <div key={index} style={{ textAlign: index == 0 ? "left" : "right", marginLeft: index == 0 ? "10px" : "0px" }} className={[index == 3 || index == 2 ? classes.mobileResponsive : ""].join(" ")}>{typeof (item) == "number" ? NegativeFinding(item, "", "", "", "rupee") : item}</div>
                        )
                    })}
                </div>} </>}
        </div>

        );
    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ handleExpand, }, dispatch)
}
export default withStyles(styles)(withRouter(connect(null, mapDispatchToProps)(ParentComponent)))