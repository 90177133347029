import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
/* name should be camel case in all function */

 function GetL5FolioList(clientID,sessionID,l5,l4,familyID,productID) {
  let data={};
  data.SessionID=sessionID
  data.ClientID=clientID
  data.L5ID=l5
  data.L4ID=l4
  data.ProductID=productID
  data.FamilyID=familyID
return Networking.PostApi(ApiValues.GetL5FolioList,data).then(result => { return result })
.catch(function (error) {
  return { "status": "F", "message": labels.messages.apierror }
});
   



  }
  
 export default GetL5FolioList