import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Headers from '../../components/report_common_header/report_common_header'
import Label from '../../utils/constants/labels';
import constants from '../../utils/constants/apiconstant';
import NoData from '../../components/nodata/nodata'
import { withStyles } from '@material-ui/core';
import moment from 'moment';
import { siptrackerfilterChange, } from '../../redux/actions/filter/reportsfilter'
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import cancelRunningSIP from '../../api/runningsip/cancelsip';
import getAllcommonDownloadDetails from '../../api/commondownload/commondownload';
import Loader from '../../components/loader/loader'
import { SIPtrackerSortBy, SIPTrackerClear, SIPTrackerFilter, SIPtrackerCurrentvalue, SIPtrackerSipAmount } from '../../redux/actions/siptracker/siptracteraction'
import CommonSnackbar from '../../components/snackbar/snackbar'
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle'
import colors from '../../utils/colors/colors'
import apiurl from '../../api/url/apiurl';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import DeletePopup from '../../components/popup/deletepopup';
import getxSipOrder from '../../api/purchaseorder/xsiporderapi';
import { NegativeFinding, replaceNull } from '../../utils/commonfunction';
import { MContext } from '../../components/pagination/renderpage';
import Pagination from '../../components/pagination/renderpage';
import { async } from 'q';
import Networking from '../../utils/api/apiaccess';
const styles = theme => ({
    popperDisablePortal: {
        position: 'relative',
    },
    tableResponsive: {
        width: "100%",
        color: window.globalConfig.color.PRIMARY_BLUE,
        fontSize: "14px",
        flex: "4 1",
        fontWeight: 500,
        borderCollapse: "collapse",
        marginTop: "2em",
        marginBottom: "10%",
    },

    headertr: {
        height: "91px",
        borderWidth: 1,
        borderRadius: "8px !important",
        textAlign: "right",
        // marginTop: "10px !important"
    },
    rupees: {
        margin: "0px !important",
        fontSize: "13px !important",
        fontWeight: "normal !important",
        color: window.globalConfig.color.LABLE_GRAY,
        padding: "1px 0px",
    },



    SchemeNametdSpan: {
        fontSize: "20px",
        fontWeight: "bold"
    },


    schemeNametdResponsive: {
        width: "50%",
        paddingLeft: "10px",
        fontWeight: 500,
    },

    leftalign: {
        textAlign: "left",
    },
    rightalign: {
        textAlign: "right",
    },
    subDate: {
        marginTop: -6,
        marginBottom: 10,
        color: window.globalConfig.color.LABLE_GRAY,
        [theme.breakpoints.down("600")]: {// Added by Celsia
            marginTop: "5px",
            position: "relative"
        },
    },


    currentValuetd: {
        width: "7.5%%",
        fontWeight: "bold",
        // [theme.breakpoints.down("600")]:{
        //     display:'none!important',
        // },
    },

    stickyHeader: {
        position: "sticky",
        height: "100%",
        width: "100%"
    },
    footerToatalSub: {
        color: window.globalConfig.color.LABLE_GRAY,
        lineHeight: 2
    },
    footerToatal: {
        fontSize: "20px"
    },

    // Added bY Celsia on 17 May 2021 for Mobile Responsive Start
    desktopResponsive: {
        display: 'grid !important',

        [theme.breakpoints.down("920")]: {
            display: 'none !important',
        },
    },
    mobileResponsive: {
        display: 'none !important',
        [theme.breakpoints.down("920")]: {
            display: 'grid !important',
            // padding: "0px 10px"
        },
    },
    rowhead1: {
        fontWeight: 500,
        color: window.globalConfig.color.LABLE_GRAY,
        marginLeft: "-35px",
        width: "100px",
        fontSize: "12px",
        [theme.breakpoints.up("540")]: {
            fontSize: "13px",
        },
    },
    rowhead2: {
        width: "100px",
        fontSize: "12px",
        fontWeight: 500,
        color: window.globalConfig.color.LABLE_GRAY,
        textAlign: "center",
    },

    trResponsive: {
        height: "auto",
        boxShadow: "0px 1px 2px 1px #e0e5eb",
        border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        padding: "10px 0",
        borderRadius: "8px",
        fontSize: "13px",
        fontWeight: 400,
        width: "100%"
    },
    SchemeNametdResponsive: {
        width: "100%",
        padding: "0px 10px",
        textAlign: "left",
        marginTop: '-2%',
        color: window.globalConfig.color.PRIMARY_BLUE,
        fontWeight: '600',
    },
    leftTdResponsive: {
        textAlign: 'left',
        paddingLeft: '10px',
        marginTop: '3%',
        flex: '0 0 50%',
        color: window.globalConfig.color.PRIMARY_BORDER
    },
    rightTdResponsive: {
        marginBottom: '1%',
        textAlign: 'right',
        paddingRight: '10px',
        flex: '0 0 50%',
        marginTop: '4%',
        marginLeft: '-6%',
        color: window.globalConfig.color.PRIMARY_BORDER
    },
    sipGrandTotalContainer: {
        display: "grid",
        gridTemplateColumns: "43% 15% 18% 17% 7%",
        marginLeft: "20px",
        alignContent: "center",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(3,1fr)",
            padding: 10,
            gap: 10
        }
    },
    tdResponsive: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: "space-between"
    },
    headerBlockResponsive: {
        height: "68px",
        borderWidth: 1,
        borderRadius: "8px !important",
        textAlign: "right",
        marginTop: "10px !important",
        padding: "10px 0px"
        //Commended by Celsia 20 May 2021
        // [theme.breakpoints.down('xs')]: {
        //   display: "none !important"
        // }
    },
    tablefooterResponsive: {
        // width: "100%",
        color: window.globalConfig.color.PRIMARY_BLUE,
        fontSize: "14px",
        flex: "4 1",
        height: "91px",
        fontWeight: 500,
        borderCollapse: "collapse",
        padding: 10,
        backgroundColor: window.globalConfig.color.BLACK_PRIMARY, borderRadius: "5px", color: window.globalConfig.color.WHITE,
        //Commended by Celsia 20 May 2021
        // [theme.breakpoints.down('xs')]: {
        //   display: "none !important"
        // }
    },
    fontResponsive: {
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: "14px",
        fontWeight: "bold"
    },
    divFontResponsive: {
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: "12px",
        fontWeight: "bold",
        minWidth: 93,
    },
    valuesColor: {
        color: window.globalConfig.color.BLACK_PRIMARY,
    },
    footerResponsive: {
        marginBottom: '1%',
        textAlign: 'right',
        paddingRight: '10px',
        flex: '0 0 50%',
        marginLeft: '-5%',
        paddingTop: "4px",
        fontSize: "500!important"
    },
    divone: {
        textAlign: "left"
    },
    divtwo: {
        textAlign: "center"
    },
    divthree: {
        textAlign: "right"

    },
    footerToatalResponsive: {
        fontSize: "20px",
        fontWeight: "bold"
    },

    //  End
    schemeNameWidth: {
        width: 155, textAlign: "left"
    },
    otherWidth: {
        width: 100
    },
    otherWidthAlignment: {
        width: 100, display: "flex", flexDirection: "column"
    },
    mainTitle: {
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: "16px"
    },
    directionColumn: {
        display: "flex",
        flexDirection: "column"
    },
    noDataDesign: {
        [theme.breakpoints.down('xs')]: {
            height: 50,
            width: "100%",
            paddingTop: 30,
            paddingBottom: 30
        }
    },
    headerMobileResponsive: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        }
    },
    SchemeName: {
        [theme.breakpoints.down('920')]: {
            gridArea: "firstColumn"
        }
    },
    StartDate: {
        [theme.breakpoints.down('920')]: {
            gridArea: "secondColumn",
        }

    },
    SIPAmount: {
        [theme.breakpoints.down('920')]: {
            gridArea: "thirdColumn"
        }
    },
    Frequency: {
        [theme.breakpoints.down('920')]: {
            gridArea: "fourthColumn",
        }
    },
    InstalmentReceived: {
        [theme.breakpoints.down('920')]: {
            gridArea: "fifthColumn",
            textAlign: "left",
            color: colors.LABLE_GRAY

        }
    },
    InstalmentMissed: {
        [theme.breakpoints.down('920')]: {
            gridArea: "sixthColumn",
            textAlign: "right",
            color: colors.LABLE_GRAY
        }
    },
    dot: {
        [theme.breakpoints.down('920')]: {
            gridArea: "seventhColumn"
        }
    }



});
class SipTracker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            siptracker: [],
            totalsiptracker: [],
            groupsiptracker: [],
            checked: true,
            accexcel: false,
            accpdf: false,
            open: false,
            selectFilter: 0,
            cancelPopupOpen: false,
            anchorEl: null,
            UCCCode: "",
            SchemeDetails: "",
            folioNumber: "",
            List: [],
            loader: false,
            message: "", severity: "warning"
        }
    }
    componentDidMount() {
        this.setState({
            siptracker: this.props.siptracker, groupsiptracker: this.props.groupsiptracker, totalsiptracker: this.props.totalsiptracker
        })
        if (this.props.flag == "onlinesip") {
            this.setState({ checked: false })
        }
        this.props.SIPTrackerClear()
    }
    handleListItemClick = () => {
        // console.log(this.state.List)
        if (this.state.List.length != 0) {
            this.setState({ cancelPopupOpen: true, anchorEl: null })
        } else {
            this.setState({ open: true, message: labels.messages.apierror, severity: "warning", anchorEl: null })
        }
    };
    handleSortby = (value) => {
        this.props.SIPtrackerSortBy(value)
        this.setState({ siptracker: [] })
        this.setState({ siptracker: this.props.siptracker })
    }
    handleSipAmount = (value) => {
        this.props.SIPtrackerSipAmount(value)
        this.setState({ siptracker: [] })
        this.setState({ siptracker: this.props.siptracker })
    }
    handleClear = () => {
        this.props.SIPTrackerClear()
        this.setState({ siptracker: [] })
        this.setState({ siptracker: this.props.siptracker })
    }
    handleFilter = (value) => {
        this.props.SIPTrackerFilter(value)
        this.setState({
            selectFilter: value
        })
    }
    handleCurrentAmount = (value) => {
        this.props.SIPtrackerCurrentvalue(value)
        this.setState({ siptracker: [] })
        this.setState({ siptracker: this.props.siptracker })
    }
    handleChange = () => {
        this.props.SIPTrackerClear()
        this.props.siptrackerfilterChange([], this.props.siptracker.length != 0 ? this.props.siptracker.map((value, index) => {
            return { value: index, label: value.SchemeName, isChecked: false }
        }) : [])
        this.setState({ checked: !this.state.checked });
    }
    handleClick = (e, value) => {
        this.setState({ anchorEl: e.currentTarget })

        this.setState({ UCCCode: value.UCC })
        this.setState({ folioNumber: value.FolioNo || "" })
        this.setState({ SchemeDetails: value })
        cancelRunningSIP(value.XSIPRegnNo, value.FolioNo || "", this.props.logindetails.ClientID).then((response) => {
            if (response.status == "S") {
                if (response.data != null && response.data.length != 0) {
                    this.setState({ List: [...response.data, value] })
                }
            } else {
                this.setState({ List: [] })
            }
        })
    }
    componentDidUpdate(prevProps) {
        const { List, date, sessionID, dataTab, catchmessage, siptracker, groupsiptracker, totalsiptracker } = this.props;
        // console.log(List)
        if (prevProps.siptracker !== siptracker) {
            this.setState({
                siptracker: siptracker
            })
        }
    }
    handleClose = () => {
        this.setState({ anchorEl: null })
    }
    // renderCategoryMethod() {
    //     const { classes } = this.props
    //     return (
    //         <>
    //             {this.props.groupsiptracker != undefined && this.props.groupsiptracker.length != 0 && this.props.groupsiptracker.map((group, mainindex) => {
    //                 //    (group)
    //                 return (

    //                 )
    //             })
    //             }
    //         </>
    //     );
    // }
    // renderSchemeMethod(data) {
    //     const { classes } = this.props
    //     return (

    //     );
    // }

    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };
    onClose = () => {
        this.setState({ cancelPopupOpen: false })
    }
    onclickdownload = async (ext) => {
        var url = ext == "pdf" ? `${apiurl.SIPTrackerpdf}${this.props.logindetails.ClientID}&asondate=${this.props.date != null || this.props.date != undefined ? moment(this.props.date).format("DD MMM YYYY") : moment().format("DD MMM YYYY")}&l4_Id=${0}&l5_Id=${0}&schemeID=0&folioNo=0&ProductID=${this.props.flag == "mutualfunds" ? 1 : 2}&FamilyID=${0}&L5ID=${this.props.L5ID}&L4ID=${0}` :
            `${apiurl.SIPTrackerexcel}${this.props.logindetails.ClientID}&asondate=${this.props.date != null || this.props.date != undefined ? moment(this.props.date).format("DD MMM YYYY") : moment().format("DD MMM YYYY")}&l4_Id=${0}&l5_Id=${0}&schemeID=0&folioNo=0&ProductID=${this.props.flag == "mutualfunds" ? 1 : 2}&FamilyID=${0}&L5ID=${this.props.L5ID}&L4ID=${0}`

        let data = {}
        var filename = `${Label.DownloadLabel.siptracker}.${ext}`
        if (ext === "pdf") {
            this.setState({ accpdf: true })
            data = {
                "ClientID": this.props.logindetails.ClientID, "AccountID": 0, "ReportID": labels.Report.sipTracker, "WithRedeem": false, "AsOnDate": moment(this.props.date).format("DD MMM YYYY"),
                "SubReport": 0, "DownloadAs": 1, "ProductID": this.state.productID, "FamilyID": 0, "L5ID": 0, "L4ID": 0
            }
        }
        if (ext === "xlsx") {
            this.setState({ accexcel: true })
            data = {
                "ClientID": this.props.logindetails.ClientID, "AccountID": 0, "ReportID": labels.Report.sipTracker, "WithRedeem": false, "AsOnDate": moment(this.props.date).format("DD MMM YYYY"),
                "SubReport": 0, "DownloadAs": 1, "ProductID": this.state.productID, "FamilyID": 0, "L5ID": 0, "L4ID": 0
            }
        }
        // url = await Networking.PostApi(apiurl.ExportDownloadURL, data).then((res) => {
        //     let a = ""
        //     if (res.status == "S" && res.data != "") {
        //         a = res.data
        //     } else {
        //         if (ext === "pdf") {
        //             this.setState({ accpdf: false });
        //         }
        //         if (ext === "xlsx") {
        //             this.setState({ accexcel: false });
        //         }
        //     }
        //     return a
        // }).catch((er) => {
        //     if (ext === "pdf") {
        //         this.setState({ accpdf: false });
        //     }
        //     if (ext === "xlsx") {
        //         this.setState({ accexcel: false });
        //     }
        //     return ""
        // })
        if (url.length != 0) {
            getAllcommonDownloadDetails(url, ext, filename, this.props.logindetails?.SessionID).then((response) => {
                if (response === 'success') {
                    if (ext === "pdf") {
                        this.setState({ accpdf: false })
                    }
                    if (ext === "xlsx") {
                        this.setState({ accexcel: false })
                    }
                } else {
                    this.setState({ open: true })
                    if (ext === "pdf") {
                        this.setState({ accpdf: false })
                    }
                    if (ext === "xlsx") {
                        this.setState({ accexcel: false })
                    }
                }
            })
        } else {
            this.setState({ open: true })
        }



    }
    handleCancel = () => {
        this.setState({ loader: true })
        this.setState({ anchorEl: null })
        if (this.state.List != null && this.state.List.length != 0) {
           
            getxSipOrder(
                this.state.List[0]?.TransactionCode, this.state.List[0]?.UCC, this.state.List[0]?.SchemeCd,
                this.state.List[0]?.UniqueReferenceNumber,
                this.state.List[0]?.TRANS_MODE, this.state.List[0]?.DPTransactionMode,
                this.state.List[1].StartDate, this.state.List[0]?.FREQUENCYTYPE,
                constants.sipPurchase.FrequencyAllowed, Number(this.state.List[0]?.INSTALLMENT_AMOUNT),
                Number(this.state.List[0]?.NO_OF_INSTALLMENTS), constants.sipPurchase.Remarks,
                this.state.SchemeDetails.FolioNo,
                this.state.List[0]?.FIRST_ORDER_FLAG, "", this.state.List[0]?.EUINNo,
                this.state.List[0]?.EUINflag, this.state.List[0]?.DPC,
                parseInt(this.state.List[1]?.XSIPRegnNo), constants.sipPurchase.IPAdd,
                constants.sipPurchase.Param1,
                constants.sipPurchase.Param2, constants.sipPurchase.Param3,
                this.state.SchemeDetails.SchemeID, this.props.logindetails.ClientID,
                typeof (this.state.List[0]?.XSIPMANDATEID) == "string" ? parseInt(this.state.List[0].XSIPMANDATEID) : this.state.List[0].XSIPMANDATEID,
                constants.Common.Source
            ).then((response) => {
                //   
                if (response.status == "S") {
                    this.setState({ open: true, message: response.message, severity: "success", loader: false, anchorEl: null })
                    this.onClose()
                } else {
                    // console.log(response)
                    this.setState({ loader: false })
                    this.setState({ open: true, message: response.message, severity: "warning" })

                    this.onClose()
                }
            })


        } else {
            this.setState({ loader: false })
            // console.log(this.state.List)
            this.setState({ open: true, message: labels.messages.apierror })
            this.onClose()
        }
    }
    render() {
        const { classes } = this.props
        try {
            return (<ErrorBoundary>

                {this.props.isSipTrackerLoading == true ?
                    <>
                        <Loader page="skeletonreport" />
                    </>
                    :
                    <div>
                        <div className={classes.stickyHeader} >
                            <Headers
                                List={this.props.List}
                                flag={this.props.flag}
                                showfilter={this.props.flag == "onlinesip" ? false : true}
                                header={this.props.header}
                                showdownload={this.props.flag == "onlinesip" ? false : true}
                                showtoggle={this.props.flag == "onlinesip" ? false : true}
                                showsortby={this.props.flag == "onlinesip" ? false : true}
                                selected={this.state.checked}
                                handleclear={this.handleclear}
                                pdfload={this.state.accpdf}
                                newxcelLoad={this.state.accexcel}
                                data={this.props.siptracker}
                                handleSipAmount={this.handleSipAmount}
                                selectvalue={this.state.selectFilter == 0 ? "" : this.state.selectFilter}
                                submit={this.handleFilter}
                                handleCurrentAmount={this.handleCurrentAmount}
                                handleSortby={this.handleSortby}
                                handleClear={this.handleClear}
                                onclick={this.onclickdownload}
                                scheme={this.props.siptracker.length == 1 ? `${this.props.siptracker.length} ${this.props.header}` : `${this.props.siptracker.length} ${this.props.header}s` && this.props.siptracker.length == 0 ? <NoData /> : `${this.props.siptracker.length} ${this.props.header}s`}
                                page={"SIPTracker"}
                                Onswitchchange={this.handleChange} />
                            {/* <div style={{ flex: 1, height: 40 }}></div> */}
                        </div>
                        <DeletePopup header={"Confirmation"} onClick={this.handleCancel} loader={this.state.loader} open={this.state.cancelPopupOpen} text={labels.runningSip.cancelpopup} onClose={this.onClose} />

                        <ErrorBoundary>
                            <div style={{ marginTop: "3%" }}>
                                {this.props.siptracker.length != 0 ? <>
                                    <div className={[this.props.titleStyle, classes.tableHeader, classes.desktopResponsive].join(' ')} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                        {this.props.title.map((item, index) => {
                                            return (
                                                <div key={index} className={index != 0 ? classes.desktopResponsive : ""} style={{ display: "flex", flexDirection: "column", textAlign: item.name == "SCHEME NAME" || item.name == "SCRIP NAME" ? "left" : 'right', marginLeft: item.name == " Instalment   Missed" ? 20 : 0 }} >
                                                    {item.name}
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className={[classes.tableHeader, classes.mobileResponsive].join(' ')} style={{ textAlign: "left" }}>
                                        {this.props.title[0].name}
                                    </div>
                                    <div>
                                        <Pagination data={this.props.siptracker}>
                                            <MContext.Consumer>
                                                {(context) => {
                                                    return <div >

                                                        {context.filter(
                                                            (ele, ind) =>
                                                                ind ===
                                                                context.findIndex((elem) => elem.CategoryName === ele.CategoryName)
                                                        ).map((item, mainindex) => {
                                                            return <div>
                                                                {this.state.checked && <div className={[this.props.sipCategoryContainer, classes.categoryGroupContainer, mainindex == 0 && classes.borderRadiousFirstIndex].join(' ')} style={{ paddingRight: 10, paddingLeft: 10, marginTop: mainindex == 0 ? 0 : "1%", }}>
                                                                    <div className={classes.directionColumn} style={{ textAlign: "left" }}>
                                                                        <div className={classes.categoryMainHeader} >
                                                                            {item.CategoryName}
                                                                        </div>
                                                                        <div className={classes.categorySubHeader}>
                                                                            {context.filter((val) => val.CategoryName == item.CategoryName).length} {this.props.header}{context.filter((val) => val.CategoryName == item.CategoryName).length > 1 ? "s" : ""}
                                                                        </div>
                                                                    </div>
                                                                    <div>

                                                                    </div>
                                                                    <div className={classes.categoryMainValue}>
                                                                        <span className={classes.categoryRupee}>{Label.currency.Rupee}</span>
                                                                        {NegativeFinding(item.CategorySIPAmount, "", "", "", "number")}
                                                                    </div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>}
                                                                {context.length != 0 && context.filter((val) => val.CategoryName == item.CategoryName)
                                                                    .map((element, index) => {
                                                                        return (
                                                                            <div key={index} className={[this.props.grid, classes.subCategoryGroupContainer, index == 0 && classes.borderRadiousFirstIndex].join(' ')} style={{ paddingRight: 10, paddingLeft: 10 }}>
                                                                                <div style={{ textAlign: "left" }} className={[classes.directionColumn, classes.SchemeName, "firstColumn", classes.subCatgoryValue].join(' ')}>
                                                                                    {element.SchemeName}
                                                                                    <span className={classes.folioNumber}>{`${"Folio No:"} ${element.FolioNo || labels.messages.NA}`}</span>
                                                                                </div>

                                                                                <div className={[classes.subCatgoryValue, "secondColumn", classes.StartDate].join(" ")}>
                                                                                    <span>{moment(element.StartDate).format("DD MMM YYYY")}</span>
                                                                                </div>

                                                                                <div className={[classes.subCatgoryValue, "thirdColumn", classes.SIPAmount].join(" ")}>
                                                                                    <div>
                                                                                        <span className={classes.subcategoryRupee}>{Label.currency.Rupee}</span>
                                                                                        <span>{NegativeFinding(element.SIPAmount, "", "", "", "number")}</span>
                                                                                    </div>

                                                                                </div>

                                                                                <div className={[classes.subCatgoryValue, "fourthColumn", classes.Frequency].join(" ")}>
                                                                                    <span >{element.Frequency || labels.messages.NA}</span>
                                                                                </div>
                                                                                {element.SIPReceived != undefined &&
                                                                                    <div className={["fifthColumn", classes.InstalmentReceived].join(" ")}>
                                                                                        <span className={classes.mobileResponsive}>{this.props.title[4].name}</span>
                                                                                        <div className={[classes.subCatgoryValue, "fifthColumn", classes.InstalmentReceived].join(" ")}>
                                                                                            {NegativeFinding(element.SIPReceived, "", "", "", "number")}

                                                                                        </div></div>}
                                                                                {element.SIPMissed != undefined && <div className={["sixthColumn", classes.InstalmentMissed].join(" ")}>
                                                                                    <span className={classes.mobileResponsive}>{this.props.title[5].name}</span>

                                                                                    <div className={[classes.subCatgoryValue]}>
                                                                                        {NegativeFinding(element.SIPMissed, "", "", "", "number")}
                                                                                    </div></div>}
                                                                                {element.SIPRegNo != undefined && element.SIPRegNo != "" && element.SIPRegNo != "N.A" && <div className={[classes.subCatgoryValue, classes.dot, "seventhColumn"].join(" ")}>
                                                                                    <div className="icons" onClick={(e) => this.handleClick(e, element)} ></div>
                                                                                    <div onMouseLeave={() => this.handleClose()} onKeyUp={() => this.handleClose()} onKeyDown={() => this.handleClose()}>
                                                                                    </div></div>
                                                                                }
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        })}
                                                        <div>

                                                        </div>
                                                        {/* {this.props.siptracker.length != 0 && this.renderSchemeMethod(this.props.siptracker
                                                            .filter(element => group.CategoryName === element.CategoryName)
                                                        )
                                                        } */}
                                                    </div>
                                                }}
                                            </MContext.Consumer>
                                        </Pagination>
                                        {/* {!this.state.checked ?
                                            this.renderSchemeMethod(this.state.siptracker) : this.renderCategoryMethod()} */}

                                    </div>

                                    {this.props.flag === 'onlinesip' && <div className={[classes.sipGrandTotalContainer, classes.displayTotalContainer].join(' ')} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                        <div className={[classes.directionColumn].join(' ')} style={{ textAlign: "left" }}>
                                            {Label.messages.grandtotal}
                                            <span className={classes.folioNumber}>
                                                {this.props.siptracker.length > 1
                                                    ? `${this.props.siptracker.length} ${this.props.header}s`
                                                    : `${this.props.siptracker.length} ${this.props.header}`}
                                            </span>
                                        </div>
                                        <div>

                                        </div>
                                        <div>
                                            <span className={classes.totalRupee}>{Label.currency.rupeesymbol}</span>
                                            {NegativeFinding(replaceNull(this.props.totalSiptrackerAmount[0]?.TotalAmount, 0), '', '', '', 'number')}
                                        </div>
                                        <div>

                                        </div>
                                        <div>

                                        </div>
                                        <div>

                                        </div>
                                        <div></div>
                                    </div>}

                                    {this.props.totalsiptracker.length != 0 && this.props.totalsiptracker.map((item, index) => {
                                        return (<div key={index} className={[this.props.sipCategoryContainer, classes.displayTotalContainer].join(' ')} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                            <div className={[classes.directionColumn].join(' ')} style={{ textAlign: "left" }}>
                                                {Label.messages.grandtotal}
                                                <span className={classes.folioNumber}>
                                                    {item.Totalscheme}
                                                </span>
                                            </div>
                                            <div>

                                            </div>
                                            <div>
                                                <span className={classes.totalRupee}>{Label.currency.rupeesymbol}</span>
                                                {NegativeFinding(item.Totalsipamount, "", "", "", "number")}
                                            </div>
                                            <div>

                                            </div>
                                            <div>

                                            </div>
                                            <div>

                                            </div>
                                            <div></div>
                                        </div>)
                                    })}
                                </>
                                    : <></>
                                    //  <NoData />
                                }
                            </div>
                        </ErrorBoundary>
                    </div>

                }
                <CommonSnackbar open={this.state.open} timer={70000} severity={this.state.severity} handlesnanckClose={this.handlesnanckClose} message={this.state.message} />
                <div className={classes.cancePopup}
                    onMouseLeave={() => this.handleClose()}
                    onKeyUp={() => this.handleClose()}
                    onKeyDown={() => this.handleClose()}
                    onWheel={() => this.handleClose()}
                >

                    <Popover
                        //    disableScrollLock={true}
                        classes={classes.popperDisablePortal}
                        open={Boolean(this.state.anchorEl)}
                        anchorEl={this.state.anchorEl}
                        onClose={this.handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',

                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}

                    >
                        <div className="xraypopover"  >



                            <ListItem
                                button
                                className="popoverlist"
                                onClick={() => this.handleListItemClick()}
                            >
                                <ListItemText primary={"Cancel SIP"} />
                            </ListItem>

                        </div>
                    </Popover>

                </div>



            </ErrorBoundary>
            )
        }
        catch (err) {
            console.log(err)
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => {
    return {

        //  sessionID: state.Login.sessionID,
        clientID: state.Login.logindetails.ClientID,
        logindetails: state.Login.logindetails,
        userID: state.Login.userID,
        date: state.Login.date,
        L5ID: state.Login.L5ID,
    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ SIPtrackerSortBy, siptrackerfilterChange, SIPTrackerClear, SIPTrackerFilter, SIPtrackerCurrentvalue, SIPtrackerSipAmount }, dispatch)
}
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(SipTracker))
