import * as types from "../../constants/actionsType";
import { replaceNull } from "../../../utils/commonfunction";
import labels from "../../../utils/constants/labels";
import moment from "moment";
const initialState = {
    realEstateList: [],
    realEstateListBackup: [],
    bullionList: [],
    bullionListBackup: [],
    isLoading: false,
};
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.EMPTY_REAL_ESTATE_LIST:
            return {
                ...state,
                realEstateList: [],
                realEstateListBackup: [],
            };
        case types.LOADER_REAL_ESTATE_LIST:
            return {
                ...state,
                isLoading: action.payload,

            }
        case types.GET_REAL_ESTATE_LIST:
            let RealEstateArray = action.payload != null && action.payload.table1 != null && action.payload.table1.length != 0 ? action.payload.table1 : [];

            let responseData = [...replaceNull(RealEstateArray)]
            responseData = responseData.map((val, index) => {
                return { ...val, enableIcon: val.REStatus == 2 ? false : true }
            })
            let arrangeArray = responseData != null && responseData != undefined && responseData.length != 0 ?
                replaceNull(responseData.map((item, index) => {
                    return {
                        "PropertyName": { name: item.PropertyName, subHeader1: item.PropertyType, subHeader2: '', subHeaderType: '', type: "", label: "Property Name" },
                        // "PropertyType": { name: item.PropertyType, subHeader1: '', subHeader2: '', type: "" },
                        "BuyPrice": { name: item.BuyPrice, subHeader1: moment(item.BuyDate).format("DD MMM YYYY"), subHeader2: "", type: "amount", subHeaderType: '', label: "Buy Price" },
                        // "BuyDate": { name: moment(item.BuyDate).format("DD MMM YYYY"), subHeader1: '', subHeader2: '', type: "",subHeaderType: '', label: "Scheme Name"  },
                        "Location": { name: item.Location, subHeader1: ' ', subHeader2: '', type: "", subHeaderType: '', label: "Location" },
                        "PropertySize": { name: item.PropertySize, subHeader1: " ", subHeader2: "", type: "numeric", subHeaderType: '', label: "Property Size" },
                        "Useage": { name: item.Useage, subHeader1: ' ', subHeader2: '', type: "", subHeaderType: '', label: "Useage" },
                        "ValuationsValue": { name: item.ValuationsValue, subHeader1: moment(item.ValuationsValueDate).format("DD MMM YYYY"), subHeader2: "", type: "amount", subHeaderType: '', label: "ValuationsValue" },
                        // "ValuationsValueDate": { name: moment(item.ValuationsValueDate).format("DD MMM YYYY"), subHeader1: '', subHeader2: '', type: "" ,subHeaderType: '', label: "Scheme Name" },
                        "ID": { name: item.ID, subHeader1: ' ', subHeader2: ' ', type: "", subHeaderType: '', label: "Property Name" },
                        "UseageID": { name: item.UseageID, subHeader1: ' ', subHeader2: '', type: "", subHeaderType: '', label: "Useage" },
                        "PropertyTypeID": { name: item.PropertyTypeID, subHeader1: ' ', subHeader2: '', type: "", subHeaderType: '', label: "PropertyType" },
                        "REStatus": { name: item.REStatus, subHeader1: ' ', subHeader2: '', type: "", subHeaderType: '', label: "REStatus" },
                        "enableIcon": { name: item.enableIcon, soldOut: " Sold Out" },
                    }

                }), "NA")
                : []
            return {
                ...state,
                realEstateList: action.payload != null && action.payload != undefined ? state.realEstateList.length == 0 ? [...arrangeArray] : [...arrangeArray, ...state.realEstateList] : [],
                realEstateListBackup: responseData != null && responseData != undefined && responseData.length != 0 ?
                    replaceNull(responseData) : [],
                isLoading: false
            }







        //add and update real estate
        case types.ADD_UPDATE_REAL_ESTATE_LIST:
            let oldArray = state.realEstateListBackup;
            let newArray = []
            let realEstateListBackupList = [...state.realEstateListBackup];
            if (action.status == "add") {
                realEstateListBackupList = [...action.data]
                newArray = replaceNull([...action.data.map((item, index) => {
                    return {
                        "PropertyName": { name: item.PropertyName, subHeader1: item.PropertyType, subHeader2: '', subHeaderType: '', type: "" },
                        // "PropertyType": { name: item.PropertyType, subHeader1: '', subHeader2: '', type: "" },
                        "BuyPrice": { name: item.BuyPrice, subHeader1: moment(item.BuyDate).format("DD MMM YYYY"), subHeader2: "", type: "amount", subHeaderType: '' },
                        // "BuyDate": { name: moment(item.BuyDate).format("DD MMM YYYY"), subHeader1: '', subHeader2: '', type: "",subHeaderType: '' },
                        "Location": { name: item.Location, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "PropertySize": { name: item.PropertySize, subHeader1: "", subHeader2: "", type: "numeric", subHeaderType: '' },
                        "Useage": { name: item.Useage, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "ValuationsValue": { name: item.ValuationsValue, subHeader1: moment(item.ValuationsValueDate).format("DD MMM YYYY"), subHeader2: "", type: "amount", subHeaderType: '' },
                        // "ValuationsValueDate": { name: moment(item.ValuationsValueDate).format("DD MMM YYYY"), subHeader1: '', subHeader2: '', type: "" ,subHeaderType: ''},
                        "ID": { name: item.ID, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "UseageID": { name: item.UseageID, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "PropertyTypeID": { name: item.PropertyTypeID, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "REStatus": { name: item.REStatus, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "enableIcon": { name: item.enableIcon, soldOut: " Sold Out" }
                    }
                })], "NA")
                // console.log(realEstateListBackupList, newArray)
            }

            else if (action.status == "update") {
                const elementsIndex = state.realEstateListBackup.findIndex(
                    (item) => item.ID == action.data[0].ID
                );
                realEstateListBackupList[elementsIndex] = {
                    ...realEstateListBackupList[elementsIndex],
                    PropertyName: action.data[0].PropertyName,
                    PropertyType: action.data[0].PropertyType,
                    BuyPrice: action.data[0].BuyPrice,
                    Location: action.data[0].Location,
                    PropertySize: action.data[0].PropertySize,
                    Useage: action.data[0].Useage,
                    ValuationsValue: action.data[0].ValuationsValue,
                    ValuationsValueDate: action.data[0].ValuationsValueDate,
                    ID: action.data[0].ID,
                    UseageID: action.data[0].UseageID,
                    PropertyTypeID: action.data[0].PropertyTypeID,
                };
                newArray = replaceNull(realEstateListBackupList.map((item, index) => {
                    return {
                        "PropertyName": { name: item.PropertyName, subHeader1: item.PropertyType, subHeader2: '', subHeaderType: '', type: "" },
                        // "PropertyType": { name: item.PropertyType, subHeader1: '', subHeader2: '', type: "" },
                        "BuyPrice": { name: item.BuyPrice, subHeader1: moment(item.BuyDate).format("DD MMM YYYY"), subHeader2: "", type: "amount", subHeaderType: '' },
                        // "BuyDate": { name: moment(item.BuyDate).format("DD MMM YYYY"), subHeader1: '', subHeader2: '', type: "",subHeaderType: '' },
                        "Location": { name: item.Location, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "PropertySize": { name: item.PropertySize, subHeader1: "", subHeader2: "", type: "numeric", subHeaderType: '' },
                        "Useage": { name: item.Useage, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "ValuationsValue": { name: item.ValuationsValue, subHeader1: moment(item.ValuationsValueDate).format("DD MMM YYYY"), subHeader2: "", type: "amount", subHeaderType: '' },
                        // "ValuationsValueDate": { name: moment(item.ValuationsValueDate).format("DD MMM YYYY"), subHeader1: '', subHeader2: '', type: "" ,subHeaderType: ''},
                        "ID": { name: item.ID, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "UseageID": { name: item.UseageID, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "PropertyTypeID": { name: item.PropertyTypeID, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "REStatus": { name: item.REStatus, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "enableIcon": { name: item.enableIcon, soldOut: " Sold Out" }
                    }

                }), "NA")

            }
            else {
                newArray = oldArray.filter((item) => item.ID == action.data.ID)
            }
            // console.log(newArray)
            return {
                ...state,
                realEstateList: newArray,
                realEstateListBackup: realEstateListBackupList
            }


        //delete arrary real estate
        case types.DELETE_REAL_ESTATE_LIST:
            let backupArray = state.realEstateListBackup.filter((item) => item.ID != action.payload.id);
            let realEstateArray = backupArray != null ?
                backupArray.length != 0 ? replaceNull(backupArray.map((item, index) => {
                    return {
                        "PropertyName": { name: item.PropertyName, subHeader1: item.PropertyType, subHeader2: '', subHeaderType: '', type: "" },
                        // "PropertyType": { name: item.PropertyType, subHeader1: '', subHeader2: '', type: "" },
                        "BuyPrice": { name: item.BuyPrice, subHeader1: moment(item.BuyDate).format("DD MMM YYYY"), subHeader2: "", type: "amount", subHeaderType: '' },
                        // "BuyDate": { name: moment(item.BuyDate).format("DD MMM YYYY"), subHeader1: '', subHeader2: '', type: "",subHeaderType: '' },
                        "Location": { name: item.Location, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "PropertySize": { name: item.PropertySize, subHeader1: "", subHeader2: "", type: "numeric", subHeaderType: '' },
                        "Useage": { name: item.Useage, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "ValuationsValue": { name: item.ValuationsValue, subHeader1: moment(item.ValuationsValueDate).format("DD MMM YYYY"), subHeader2: "", type: "amount", subHeaderType: '' },
                        // "ValuationsValueDate": { name: moment(item.ValuationsValueDate).format("DD MMM YYYY"), subHeader1: '', subHeader2: '', type: "" ,subHeaderType: ''},
                        "ID": { name: item.ID, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "UseageID": { name: item.UseageID, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "PropertyTypeID": { name: item.PropertyTypeID, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "REStatus": { name: item.REStatus, subHeader1: '', subHeader2: '', type: "", subHeaderType: '' },
                        "enableIcon": { name: item.enableIcon, soldOut: " Sold Out" }

                    }
                }), "NA") :
                    [] : []
            return {
                ...state,
                realEstateList: replaceNull([...realEstateArray], "NA"),
                realEstateListBackup: replaceNull(backupArray, "NA")
            }


        case types.EMPTY_BULLION_LIST:
            return {
                ...state,
                bullionList: [],
                isLoading: false,
            };
        case types.LOADER_GET_BULLION_LIST:
            return {
                ...state,
                isLoading: action.payload,

            }

        case types.GET_BULLION_LIST:
            let ArrayOFBullion = action.payload != null && action.payload.table1 != null && action.payload.table1.length != 0 ? action.payload.table1 : [];

            let Data = [...replaceNull(ArrayOFBullion)]
            Data = Data.map((val, index) => {
                return { ...val, enableIcon: val.BullionStatus == 2 ? false : true }
            })
            let bulionArray = Data != null && Data != undefined && Data.length != 0 ?
                replaceNull(Data.map((item, index) => {
                    return {

                        "Bullion": { name: item.Bullion, subHeader1: '', subHeader2: '', type: "", label: "Bullion" },
                        "BullionType": { name: item.BullionType, subHeader1: '', subHeader2: '', type: "", label: "BullionType" },
                        "InvestedValue": { name: item.InvestedValue, subHeader1: "", subHeader2: "", type: "amount", label: "Invested Value" },
                        "InvestedDate": { name: moment(item.InvestedDate).format("DD MMM YYYY"), subHeader1: '', subHeader2: '', type: "", label: "Invested Date" },
                        "ValuationsValue": { name: item.ValuationsValue, subHeader1: "", subHeader2: "", type: "amount", label: "Valuations Value" },
                        "ValuationsValueDate": { name: moment(item.ValuationsValueDate).format("DD MMM YYYY"), subHeader1: '', subHeader2: '', type: "", label: "Valuations Value Date" },
                        "BullionStatus": { name: item.BullionStatus, subHeader1: '', subHeader2: "", type: "", label: "Bullion Status" },
                        "ID": { name: item.ID, subHeader1: '', subHeader2: "", type: "", label: "ID" },
                        "BullionID": { name: item.BullionID, subHeader1: '', subHeader2: '', type: "", label: "Bullion ID" },
                        "BullionTypeID": { name: item.BullionTypeID, subHeader1: '', subHeader2: '', type: "", label: "Bullion Type ID" },
                        "enableIcon": { name: item.enableIcon, soldOut: " Sold Out", }
                    }
                }), "NA")
                : []
            return {
                ...state,
                bullionList: action.payload != null && action.payload != undefined ? state.bullionList.length == 0 ? [...bulionArray] : [...bulionArray, ...state.bullionList] : [],
                bullionListBackup: Data != null && Data != undefined && Data.length != 0 ?
                    replaceNull(Data) : [],
                isLoading: false,
            }
        //delete arrary real estate
        case types.DELETE_BULLION_LIST:
            let backupBullionArray = state.bullionListBackup.filter((item) => item.ID != action.payload.id);
            let bullionArray = backupBullionArray != null ?
                backupBullionArray.length != 0 ? replaceNull(backupBullionArray.map((item, index) => {
                    return {
                        "Bullion": { name: item.Bullion, subHeader1: '', subHeader2: '', type: "" },
                        "BullionType": { name: item.BullionType, subHeader1: '', subHeader2: '', type: "" },
                        "InvestedValue": { name: item.InvestedValue, subHeader1: "", subHeader2: "", type: "amount" },
                        "InvestedDate": { name: moment(item.InvestedDate).format("DD MMM YYYY"), subHeader1: '', subHeader2: '', type: "" },
                        "ValuationsValue": { name: item.ValuationsValue, subHeader1: "", subHeader2: "", type: "amount" },
                        "ValuationsValueDate": { name: moment(item.ValuationsValueDate).format("DD MMM YYYY"), subHeader1: '', subHeader2: '', type: "" },
                        "BullionStatus": { name: item.BullionStatus, subHeader1: '', subHeader2: "" },
                        "ID": { name: item.ID, subHeader1: '', subHeader2: "" },
                        "enableIcon": { name: item.enableIcon, soldOut: " Sold Out" }

                    }
                }), "NA") :
                    [] : []
            return {
                ...state,
                bullionList: [...bullionArray],
                bullionListBackup: [...backupBullionArray]
            }

        //bullion add and update
        case types.ADD_UPDATE_BULLION_LIST:
            let oldBullionArray = state.bullionListBackup;
            let BullionList = state.bullionList
            let newBullionArray = []
            let BullionBackupList = [...state.bullionListBackup];
            if (action.status == "add") {
                BullionBackupList = oldBullionArray.length != 0 ? [...action.data, ...state.bullionListBackup] : [...action.data]
                newBullionArray = replaceNull([...BullionBackupList.map((item, index) => {
                    return {
                        "Bullion": { name: item.Bullion, subHeader1: '', subHeader2: '', type: "" },
                        "BullionType": { name: item.BullionType, subHeader1: '', subHeader2: '', type: "" },
                        "InvestedValue": { name: item.InvestedValue, subHeader1: "", subHeader2: "", type: "amount" },
                        "InvestedDate": { name: moment(item.InvestedDate).format("DD MMM YYYY"), subHeader1: '', subHeader2: '', type: "" },
                        "ValuationsValue": { name: item.ValuationsValue, subHeader1: "", subHeader2: "", type: "amount" },
                        "ValuationsValueDate": { name: moment(item.ValuationsValueDate).format("DD MMM YYYY"), subHeader1: '', subHeader2: '', type: "" },
                        "BullionStatus": { name: item.BullionStatus, subHeader1: '', subHeader2: "" },
                        "ID": { name: item.ID, subHeader1: '', subHeader2: "" },
                        "BullionID": { name: item.BullionID, subHeader1: '', subHeader2: '', type: "" },
                        "BullionTypeID": { name: item.BullionTypeID, subHeader1: '', subHeader2: '', type: "" },
                        "enableIcon": { name: item.enableIcon, soldOut: " Sold Out" }

                    }
                })], "NA")

            }

            else if (action.status == "update") {
                const elementsIndex = state.bullionListBackup.findIndex(
                    (item) => item.ID == action.data[0].ID
                );
                BullionBackupList[elementsIndex] = {
                    ...BullionBackupList[elementsIndex],
                    Bullion: action.data[0].Bullion,
                    BullionID: action.data[0].BullionID,
                    BullionType: action.data[0].BullionType,
                    InvestedValue: action.data[0].InvestedValue,
                    InvestedDate: action.data[0].InvestedDate,
                    ValuationsValue: action.data[0].ValuationsValue,
                    ValuationsValueDate: action.data[0].ValuationsValueDate,
                    ID: action.data[0].ID,
                };
                newBullionArray = replaceNull(BullionBackupList.map((item, index) => {
                    return {
                        "Bullion": { name: item.Bullion, subHeader1: '', subHeader2: '', type: "" },
                        "BullionType": { name: item.BullionType, subHeader1: '', subHeader2: '', type: "" },
                        "InvestedValue": { name: item.InvestedValue, subHeader1: "", subHeader2: "", type: "amount" },
                        "InvestedDate": { name: moment(item.InvestedDate).format("DD MMM YYYY"), subHeader1: '', subHeader2: '', type: "" },
                        "ValuationsValue": { name: item.ValuationsValue, subHeader1: "", subHeader2: "", type: "amount" },
                        "ValuationsValueDate": { name: moment(item.ValuationsValueDate).format("DD MMM YYYY"), subHeader1: '', subHeader2: '', type: "" },
                        "BullionStatus": { name: item.BullionStatus, subHeader1: '', subHeader2: "" },
                        "ID": { name: item.ID, subHeader1: '', subHeader2: "" },
                        "BullionID": { name: item.BullionID, subHeader1: '', subHeader2: '', type: "" },
                        "BullionTypeID": { name: item.BullionTypeID, subHeader1: '', subHeader2: '', type: "" },
                        "enableIcon": { name: item.enableIcon, soldOut: " Sold Out" }
                    }

                }), "NA")

            }
            else {
                newBullionArray = oldBullionArray.filter((item) => item.ID == action.data.ID)
            }

            return {
                ...state,
                bullionList: newBullionArray,
                bullionListBackup: BullionBackupList
            }


        default:
            return state;
    }
};
export default reducer;
