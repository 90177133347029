import React, { Component } from 'react';
import '../../components/summary/progress.css';
import colors from '../../utils/colors/colors';
import { NegativeFinding } from '../../utils/commonfunction';
export default class CustomTooltip extends React.Component {

    render() {
        if (this.props.page === "barchart") {
            return (<>
                <div className={"customtooltip"} style={{ borderLeftColor: this.props.bordercolor||this.props.fill, padding: "3%", display: this.props.name == "" ? "none" : "block" }} >
                    <div style={{ display: "grid", gridTemplateColumns: "30% 70%", marginTop: "2%", textAlign: "left" }}>
                        <span >Name :</span>
                        <span style={{ textAlign: "left" }}> {this.props.name}</span>
                    </div>
                    <div style={{ display: "grid", gridTemplateColumns: "30% 70%", textAlign: "left" }}>
                        <p >Value : </p>
                        <p > {this.props.value}</p>
                    </div>
                </div>
            </>

            )
        }
        else {
            // console.log(this.props.selectionField, this.props.item, this.props.name, this.props.item.filter((i) => i[this.props.selectionField] == this.props.name),)
            return (
                <div className={"customtooltip"} key={this.props.name} style={{ borderLeftColor: this.props.fill, padding: "3%" }}>

                    <div style={{ flexDirection: "row" }}>
                        {this.props.item.filter((i) => i[this.props.selectionField] == this.props.name).map((items, index) => {

                            return <div key={index}>

                                <p style={{ fontSize: 12, color: window.globalConfig.color.BLACK_PRIMARY, fontWeight: "bold", width: 150, textAlign: "left", wordWrap: "break-word" }}> {this.props.name} ({NegativeFinding(items.holdingPercentage, "", "", "", "percentage")})</p>

                                <p style={{ fontSize: 12, color: window.globalConfig.color.BLACK_PRIMARY, fontWeight: "bold", marginRight: "7px", textAlign: "right", flex: 1, alignContent: "flex-end", alignSelf: "flex-end", marginTop: "-13%" }}> {NegativeFinding(items.amount, "", "", "", "rupee")}</p>
                            </div>
                        })}

                    </div>
                    {this.props.item.filter((i) => i.category === this.props.name).map((items) => {
                        return items.rowitem.map((val, index) => {
                            return <>
                                <div key={index} style={{ display: "flex", flexDirection: "row", marginLeft: -3 }}>
                                    <span style={{ fontSize: 10, flex: 1, color: window.globalConfig.color.LABLE_GRAY, marginLeft: 5, marginRight: 5, textAlign: "left" }}> {val.category}</span>
                                    <span style={{ fontSize: 10, flex: 1, color: window.globalConfig.color.LABLE_GRAY, textAlign: "right", alignSelf: "flex-end", alignContent: "flex-end" }}> {NegativeFinding(val.amount, "", "", "", "rupee")}
                                    </span>
                                    <span style={{ fontSize: 10, flex: 0.1, color: window.globalConfig.color.LABLE_GRAY, textAlign: "right", alignSelf: "flex-end", alignContent: "flex-end" }}> </span>

                                </div>
                            </>
                        })

                    })}
                    <div style={{ padding: 5 }}></div>
                </div>

            )
        }
    }
}