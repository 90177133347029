import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import { Chip } from "@material-ui/core";
import { withRouter } from "react-router";
import "../../utils/stylesheets/sample/sample.css";
import Labels from "../../utils/constants/labels";
// import withStyles from "@material-ui/core/styles";
import Dropdown from "../dropdown/dropdownschemes";
import Divider from "@material-ui/core/Divider";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import InputAdornment from "@material-ui/core/InputAdornment";
// import Cookies from 'universal-cookie';
import { NegativeFinding, amountCommaSeperator, findDateFormat, formatDate, numbersOnly, replaceComma, returnDateList, uniqueId } from "../../utils/commonfunction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import addtocart from "../../api/cart/addtocartapi";
import { AddToCart } from "../../redux/actions/cart/addtocartaction";
import { GetAllCart } from "../../redux/actions/cart/getallcartaction";
import moment from "moment";
import { SchemeCodeFinding } from "../../utils/commonfunction";
import secureStorage from "../../utils/securestorage";
import CircularProgress from "@material-ui/core/CircularProgress";
import CommonCheckbox from "../../components/checkbox/checkbox";
import Question from "../../utils/assets/images/img5.png";
import labels from "../../utils/constants/labels";
import Button from "../custombutton/button";
import colors from "../../utils/colors/colors";
const styles = (theme) => ({

  // bigSip: {
  //   [theme.breakpoints.up(900)]: {
  //     width: 930,
  //   },
  //   [theme.breakpoints.only(768)]: {
  //     width: 513
  //   },
  //   height: 500,
  // },

  bigOnetime: {
    [theme.breakpoints.up(600)]: {
      width: 900,
    },
    // height: 500,
  },
  // txtHeader: {
  //   padding: 15,
  // },
  header: {
    backgroundColor: window.globalConfig.color.PRIMARY,
    color: window.globalConfig.color.WHITE,
    // height: 50,
    padding: 15,
    fontWeight: 500,
    position: "sticky",
    top: "0px",
    // [theme.breakpoints.down("sm")]: {
    //   height: 44,
    // },
    // [theme.breakpoints.down("xs")]: {
    //   height: 50,
    // },
  },
  inputWidth: {
    height: 47,
    width: 180,
    paddingLeft: 10,
    [theme.breakpoints.down(600)]: {
      width: "100%",
    },

    "&:focus": {
      border: `1px solid ${window.globalConfig.color.BROWN} !important`,
    },
  },
  inputRoot: {
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontSize: 12,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: window.globalConfig.color.cyanBluish,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: window.globalConfig.color.BROWN,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: window.globalConfig.color.BROWN,
    },
    "&:hover .MuiOutlinedInput-root": {
      borderColor: window.globalConfig.color.BROWN,
    },
    "&.Mui-focused .MuiOutlinedInput-root": {
      borderColor: window.globalConfig.color.BROWN,
    }
  },

  clearIcon: {
    float: "right",
    cursor: "pointer",
  },


  cart: {
    fontSize: 12,
    cursor: "pointer",
    color: window.globalConfig.color.PRIMARY,
  },

  bigBody: {
    // width: "95%",
    [theme.breakpoints.up(900)]: {
      // minHeight: "75%",
      // display: "inline-flex",
      display: "grid",
      gridTemplateColumns: "48% 48%",
      overflow: "hidden"
    },
    [theme.breakpoints.down(600)]: {
      display: "flex",
      flexDirection: "column",
    },

  },

  bodyLeft: {
    display: "inline-block",
    borderRight: `1px solid ${colors.BORDER}`
    // [theme.breakpoints.up(900)]: {
    //   width: "49.5%",
    // },
    // [theme.breakpoints.only(768)]: {
    //   width: "80.5px"
    // }
  },

  bodyPadding: {
    padding: "25px 0px 25px 15px",
    [theme.breakpoints.down(600)]: {
      padding: 25,
    },
  },

  leftBigHeading: {
    fontWeight: "bold",
    fontSize: 20,
    // [theme.breakpoints.up(768)]:{
    //   marginLeft:"10%"
    // }
    // paddingLeft: 6
  },

  change: {
    fontSize: 12,
    cursor: "pointer",
    color: window.globalConfig.color.BLACK_PRIMARY,
    paddingLeft: 10,
  },
  topSpace: {
    display: "flex",
    flexDirection: "row"
  },
  leftSmallHeading: {
    display: "flex",
    paddingTop: 20,
    // [theme.breakpoints.up(768)]:{
    //   marginLeft: "10%"
    // },
    // [theme.breakpoints.up(600)]: {
    //   width: 436,
    // },
    [theme.breakpoints.down(600)]: {
      display: "grid",
      gridTemplateColumns: "repeat(2,55%)",
    },
  },

  bodyCenter: {
    width: "1%",
    display: "inline-block",
  },

  centerDivider: {
    height: "100%",
    size: 2,
  },

  bodyRight: {
    // width: "49.5%",
    width: "auto",
    float: "right",
    [theme.breakpoints.up(600)]: {
      display: "contents"
    }

  },

  bodyRightHeading: {
    fontWeight: "bold",
    fontSize: 20,
    [theme.breakpoints.up(768)]: {
      marginLeft: 4
    }
  },
  bodyRightButton: {
    paddingTop: 15,
    [theme.breakpoints.up(768)]: {
      marginLeft: 4
    }
  },

  questionMark: {
    color: window.globalConfig.color.LABLE_GRAY,
    position: "absolute",
    marginLeft: 10,
    marginTop: 2,
  },

  investmentSpace: {
    paddingTop: 30,
  },

  investmentRow: {
    display: "inline-block",
    width: 180,
    [theme.breakpoints.down("600")]: {
      width: "104%",
    },
  },
  investmentBlock: {
    display: "flex",
    marginTop: 30,
    [theme.breakpoints.up(768)]: {
      marginLeft: 4,
    },
    [theme.breakpoints.down("600")]: {
      flexDirection: "column",
      marginTop: 0,
    },
  },
  investmentDropDown: {
    marginLeft: 25,
    [theme.breakpoints.down("600")]: {
      marginLeft: 0,
      marginTop: "10px",
      marginBottom: "10px",
    },
  },
  investmentColumn: {
    display: "inline-block",
    width: "104%",

    [theme.breakpoints.up("600")]: {
      float: "right",
      width: 200,
    },
  },
  investmentColumnCheck: {
    display: "inline-block",
    width: "270px",
    [theme.breakpoints.down('xs')]: {
      width: "245px",
      marginLeft: "-12px"
    },
    [theme.breakpoints.between("600", "2560")]: {
      float: "right",
      marginLeft: 4,
      width: 270,
    },

  },
  investmentLabelSpace: {
    paddingTop: 3,
    "& div.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
    {
      border: `1px solid ${window.globalConfig.color.BROWN} !important`,
    },
    "& div .MuiOutlinedInput-roo :hover": {
      border: `1px solid ${window.globalConfig.color.BROWN} !important`,
    },
  },
  root: {
    "& .MuiDialog-paperScrollPaper": {
      maxHeight: "calc(100% - 40px)"
    },
    "& .MuiDialog-paperWidthMd": {
      maxWidth: "fit-content"
    }
  },
  topDateSpace: {
    [theme.breakpoints.up(600)]: {
      marginTop: 10,
    },
  },
  bodyBottom: {
    height: "5%",
    width: "100%",
  },

  bottomDivider: {
    width: "100%",
    size: 2,
  },

  bottomButton: {
    float: "right",
    padding: "20px 30px 20px 0px",
  },
  checkBox: {
    paddingTop: 25,
    marginLeft: 22,
    [theme.breakpoints.down(600)]: {
      marginLeft: 0,
    },
  },
  btnOnetime: {
    height: 40,
    width: 120,
    background: window.globalConfig.color.BROWN,
    borderWidth: 0,
    marginLeft: 10,
  },

  btnSelected: {
    height: 40,
    width: 120,
    background: window.globalConfig.color.BROWN,
    color: "#FFF",
    borderWidth: 0,
    [theme.breakpoints.down(600)]: {
      width: "104%",
    },
  },
  investmentLabel: {
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: 13,
  },

  divider: {
    height: 15,
    marginTop: 2,
    marginLeft: 4,
    size: 2,
    [theme.breakpoints.down(600)]: {
      display: "none",
    },
  },

  smallDetailLeft: {
    fontSize: 14,
    padding: "0px 6px 6px 6px",
    color: window.globalConfig.color.LABLE_GRAY,
    [theme.breakpoints.down(600)]: {
      padding: "0px 6px 6px 0px",
    },
  },

  smallDetailRight: {
    fontSize: 14,
    // padding: "0px 10px 6px 0px",
    color: window.globalConfig.color.BLACK_PRIMARY,
    textAlign: "left",
    display: "flex",
    flexDirection: "row",
    fontWeight: "normal",
  },
  textField: {

  },

  clearIcon: {
    float: "right",
    cursor: "pointer",
  },
  btnNo: {
    backgroundColor: window.globalConfig.color.RosyBrown,
    height: 30,
    width: 60,
    borderWidth: 0,
    borderRadius: 3,
    fontWeight: 500,
    color: window.globalConfig.color.BLACK_PRIMARY,
    textAlign: "center",
    cursor: "pointer",

  },

  btnYes: {
    height: 30,
    width: 60,
    background: window.globalConfig.color.BROWN,
    borderWidth: 0,
    borderRadius: 3,
    fontWeight: 200,
    textAlign: "center",
    color: colors.WHITE,
    cursor: "pointer",
    marginLeft: 10,
    textTransform: "none",
  },

  textBoxMonthly: {
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    color: window.globalConfig.color.LABLE_GRAY,
    borderWidth: 0,
    fontSize: 12,
    padding: 15, //changed by dulcy
    marginLeft: "18.5%", //Changed by Celsia
    // fontFamily: "Roboto",
    fontWeight: "500",
    borderTopRightRadius: 3,
    borderBottomRightRadius: 3,
    [theme.breakpoints.down("600")]: {
      padding: 21,
      marginLeft: "24.5%",
    },
  },
  textFieldCursor: {
    caretColor: window.globalConfig.color.BROWN,
  },

  // Added by Celsia on 24 May 2021 for Mobile Responsive   Started
  desktopResponsive: {
    display: "block !important",

    [theme.breakpoints.down("600")]: {
      display: "none !important",
    },
  },
  mobileResponsive: {
    display: "none !important",
    [theme.breakpoints.down("600")]: {
      display: "block!important",
    },
  },
  sipContainerResponsive: {
    maxheight: 470,
    width: "100%",
  },
  btnContinueResponsive: {
    color: window.globalConfig.color.WHITE,
    width: "100%",
    height: 40,
    backgroundColor: window.globalConfig.color.BROWN,
    borderWidth: 0,
    borderRadius: 5,
  },
  bodyResponsive: {
    textAlign: "center",
  },
  textAlignsResponsive: {
    width: 220,
    textAlign: "center",
  },
  btnParentResponsive: {
    textAlign: "center",
    paddingTop: "8px",
  },
  badgesResponsive: {
    paddingTop: 10,
    paddingBottom: 10,
  },
  txtBodyResponsive: {
    padding: 10,
    fontSize: 13,
    color: window.globalConfig.color.LABLE_GRAY,
    marginTop: "20px",
  },
  cartParentResponsive: {
    textAlign: "center",
    paddingBottom: 5,
    paddingTop: 10,
  },
  onetimeContainerResponsive: {
    maxheight: 470,
    width: "100%",
  },
  ValidateResponsive: {
    color: "red",
    fontSize: 12,
    textAlign: "center",
    marginTop: 20,
  },
  chipResponsive: {
    color: window.globalConfig.color.LABLE_GRAY,
    backgroundColor: `${window.globalConfig.color.ZERO_BACKGROUND} !important`,
    margin: 2,
    height: 20,
    fontSize: 12,
    cursor: "pointer",
  },

  headingResponsive: {
    fontWeight: "bold",
    fontSize: 15,
  },
  bodyResponsive: {
    padding: 20,
  },
  leftValueResponsive: {
    margin: "0px !important",
    fontSize: "14px !important",
    padding: "4px",
    textAlign: "left",
  },
  rightValueResponsive: {
    margin: "0px !important",
    fontSize: "14px !important",
    padding: "4px",
    textAlign: "right",
  },

  centerDivider: {
    // width: "100%",
    size: 2,
  },
  btnSelectedResponsive: {
    height: 40,
    width: "100%",
    background: window.globalConfig.color.BROWN,
    borderWidth: 0,
    borderRadius: "4px",
  },
  btnCartResponsive: {
    height: 40,
    width: "100%",
    background: window.globalConfig.color.BROWN,
    borderWidth: 0,
    borderRadius: 4,
    fontWeight: 500,
  },
  smallDetailLeftResponsive: {
    fontSize: 14,
    color: window.globalConfig.color.LABLE_GRAY,
  },
  bottomButtonResponsive: {
    float: "right",
    padding: "20px 16px 20px 0px",
  },
  adornedEnd: {
    paddingTop: "0 !important",
    paddingBottom: "0 !important",
    height: "45px",
    [theme.breakpoints.down(600)]: {
      paddingRight: 21,
    },
  },
  // amountwidth: {
  //   [theme.breakpoints.down(600)]: {
  //     width: "106%",
  //   },
  // },
  // smallDetailRightResponsive: {
  //   fontSize: 14,
  //   paddingLeft: "10px",
  //   color: window.globalConfig.color.BLACK_PRIMARY,
  //   textAlign: "left",
  //   fontWeight: "normal",
  // },
  SubHeaderBlock: {
    display: "flex",
  },

  SubHeaderLeft: {
    flex: "50%",
    textAlign: "left !important",
  },
  SubHeaderRight: {
    flex: "50%",
    textAlign: "right !important",
    float: "right !important",
  },
  padding: {
    paddingTop: "20px",
    // [theme.breakpoints.up(768)]:{
    //   marginLeft:"10%"
    // }
  }

  //Ended
});
class EditTrading extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: "",
      installments: "",
      installmentsValidation: "",
      amountValidation: "",
      amountValidationMessage: "",
      selectedDate: "",
      frequency: "MONTHLY",
      backupDate: "",
      dateList: [],
      showMessage: "",
      checked: false,
      dropDownValue: 1,
      currentDateBackUp: "",
    }
  }
  async componentDidMount() {
    if (this.props.editData.ISFirstPayment != undefined) {
      this.setState({ checked: this.props.editData.ISFirstPayment })
      // setChecked(props.editData.ISFirstPayment);
    }
    if (this.props.editData.Amount != undefined) {
      await this.setState({ amount: amountCommaSeperator(this.props.editData.Amount) })
      // setAmount(this.props.editData.Amount);
    }
    if (this.props.editData.Instalments != undefined) {
      this.setState({ installments: this.props.editData.Instalments })
      // setInstallments(props.editData.Instalments);
    }
    let date = "";

    let datelist = labels.Date;
    let dateoption = []
    if (this.props.editData.Sipdate != undefined) {
      date = moment(this.props.editData.Sipdate, "DD MMM YYYY").format("DD");
      dateoption = datelist.map((item) => {
        return {
          ...item,
          isChecked: item.value === date.toString() ? true : false,
        };
      });
      this.setState({ selectedDate: formatDate(this.props.editData.Sipdate) })
    } else {
      dateoption = datelist.map((item, index) => {
        return {
          ...item,
          isChecked: index == 0 ? true : false,
        };
      });
    }
    this.setState({ dateList: dateoption })

  }
  async componentDidUpdate(prev) {
    const { editData, open } = this.props;
    if (prev.open != open) {
      this.handleEdit()
    }

  }
  checkDateValid = (IsFirstPayment, date) => {
    let todayDate = ""
    let dropdownDate = ""
    if (!moment.isMoment(moment().format("DD MMM YYYY"))) {
      todayDate = moment(moment().format("DD MMM YYYY"));
    }
    if (!moment.isMoment(date)) {
      dropdownDate = moment(moment(date).format("DD MMM YYYY"));
    }
    let isValidDate = IsFirstPayment ? moment(dropdownDate).isAfter(todayDate) : dropdownDate.diff(todayDate, "days") >= 6 && moment(dropdownDate).isAfter(todayDate)
    this.setState({ showMessage: !isValidDate ? labels.bigPopup.cartDateMismatch : "" })
    return !isValidDate ? labels.bigPopup.cartDateMismatch : ""
  }
  dropdowndata = async (value) => {
    let todayDate = moment(new Date()).format("DD MMM YYYY");
    if (value.length != 0) {
      this.setState({ currentDateBackUp: "" })
      this.setState({ validationMessage: "" });
      let dropdownDate = moment(value[0].value, "DD MMM YYYY").format(
        "DD MMM YYYY"
      );
      dropdownDate = moment(dropdownDate).isBefore(todayDate) ? moment(
        moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
      ).format("DD MMM YYYY") : dropdownDate
      this.setState({
        startDate: labels.Date.filter((item) => item.value == value[0].value)[0].label,
        dropDownValue: value[0].value
      });
      await this.setState({ currentDateBackUp: formatDate(dropdownDate) });
      this.checkDateValid(this.state.checked, formatDate(dropdownDate))
      this.dateSetting(value[0].value, this.state.checked);
    }
  };
  dateSetting = (date, isChecked) => {
    let todayDate = moment(new Date()).format("DD MMM YYYY");
    let dropdownDate = moment(date, "DD MMM YYYY").format("DD MMM YYYY");
    if (isChecked && !moment(dropdownDate).isBefore(todayDate) && moment(dropdownDate).diff(moment().format("DD MMM YYYY"), "days") <= 6 || moment(dropdownDate).diff(moment().format("DD MMM YYYY"), "days") > 6) {
      this.setState({
        selectedDate: moment(
          moment(dropdownDate, "DD MMM YYYY").toString()
        ).format("DD MMM YYYY"),
      });
    } else {
      this.setState({
        selectedDate: moment(
          moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
        ).format("DD MMM YYYY"),
      });
    }

  };
  handleEdit = () => {
    this.setState({ amountValidationMessage: "", installmentsMessage: "" })
    if (this.props.editData.ISFirstPayment != undefined) {
      this.setState({ checked: this.props.editData.ISFirstPayment })
    }
    if (this.props.editData.Amount != undefined) {
      this.setState({ amount: amountCommaSeperator(this.props.editData.Amount) })
    }
    // if (this.props.popupType == "sipPopup") {
    if (this.props.editData.Instalments != undefined) {
      this.setState({ installments: this.props.editData.Instalments })
    }
    this.checkDateValid(this.props.editData.ISFirstPayment, formatDate(this.props.editData.Sipdate))
    let date = "";

    let datelist = labels.Date;
    let dateoption = []
    if (this.props.editData.Sipdate != undefined && this.props.editData.Sipdate != "NA") {
      date = moment(this.props.editData.Sipdate, "DD MMM YYYY").format("DD");
      dateoption = datelist.map((item) => {
        return {
          ...item,
          isChecked: item.value === date.toString() ? true : false,
        };
      });
      this.setState({ selectedDate: formatDate(this.props.editData.Sipdate), startDate: datelist.filter((item) => item.value === date?.toString())[0]?.label })
    } else {
      dateoption = datelist.map((item, index) => {
        return {
          ...item,
          isChecked: index == 0 ? true : false,
        };
      });
    }
    this.setState({ dateList: dateoption })
    // }
  }
  handleSideClose = () => {
    this.props?.sideClose();
    this.handleEdit()
    // let date = "";
    // var dateFormat = "DD/MM/YYYY";
    // let datelist = labels.Date;
    // let dateoption = []
    // if (this.props.editData.Sipdate != undefined && moment(this.props.editData.Sipdate, dateFormat, true).isValid()) {
    //   date = moment(this.props.editData.Sipdate, "DD MMM YYYY").format("DD");
    //   dateoption = datelist.map((item) => {
    //     return {
    //       ...item,
    //       isChecked: item.value === date.toString() ? true : false,
    //     };
    //   });
    // } else {
    //   dateoption = datelist.map((item, index) => {
    //     return {
    //       ...item,
    //       isChecked: index == 0 ? true : false,
    //     };
    //   });
    // }
    // this.setState({ dateList: dateoption })
    // if (moment(this.props.editData.Sipdate, dateFormat, true).isValid()) {
    //   let dropdownDate = "";
    //   let todayDate = ""
    //   this.dropdowndata(
    //     labels.Date.filter((item) => item.value == date.toString())
    //   )
    //   if (!moment.isMoment(moment().format("DD MMM YYYY"))) { todayDate = moment(moment().format("DD MMM YYYY")); }
    //   if (!moment.isMoment(this.state.selectedDate)) { dropdownDate = moment(this.state.selectedDate); }
    //   let isValidDate = dropdownDate.diff(todayDate, "days") <= 6
    //   this.setState({ showMessage: isValidDate })
    // }
  }
  handleChange = (value) => {
    this.props.history.push(labels.Path.Scheme)
  }
  handleInstalment = (event) => {
    if (numbersOnly(event.target.value) && event.target.value.length <= 10) {
      this.setState({ installments: event.target.value, installmentsMessage: "" })
    } else {
      this.setState({ installmentsMessage: "" })
    }
  }
  onChangeAmount = (event) => {
    var val = event.target.value.replaceAll(",", "");
    var currancyvalue = val.replaceAll(labels.currency.rupeesymbol, "").trim();
    if (currancyvalue.length <= 15 && numbersOnly(currancyvalue)) {
      this.setState({ amount: `${amountCommaSeperator(currancyvalue.trim())}` })
    }
  }
  handleCheckbox = () => {
    this.setState({ checked: !this.state.checked })
    this.dateSetting(
      this.state.dropDownValue
      ,
      !this.state.checked
    );
  }
  onClick = () => {
    let isValid = true;
    if (this.state.amount == "0" || this.state.amount == "") {
      isValid = false
      this.setState({ amountValidationMessage: labels.messages.required })
    }
    if (this.props.orderType == "SIP") {
      if (replaceComma(this.state.amount) < 1000) {
        isValid = false
        this.setState({ amountValidationMessage: labels.messages.minimumAmount })
      }
    } else {
      if (replaceComma(this.state.amount) < 5000) {
        isValid = false
        this.setState({ amountValidationMessage: `Amount should be minimum ₹5,000` })
      }
    }
    if (this.props.orderType != "One Time") {
      if (this.state.installments == "0" || this.state.installments == "") {
        isValid = false
        this.setState({ installmentsMessage: labels.messages.required })
      } else if (this.state.installments < 12) {
        isValid = false
        this.setState({ installmentsMessage: "Minimum 12 Instalments" })
      }
      else {
        this.setState({ installmentsMessage: "" })
      }
    }
    if (isValid) {
      this.props.editSip(replaceComma(this.state.amount), this.state.installments, this.state.frequency, this.state.checked, this.props.orderType == "SIP" ? this.state.selectedDate : "")
    }
  }
  findDate = (val) => {
    let a = Number(moment().format("DD"))
    let arr = [...Array(6)].map((item, i) => {
      return a + i
    })
    return arr.includes(Number(val))
  }
  render() {
    const { classes } = this.props;
    return (
      <Dialog
        onClose={this.handleSideClose}
        classes={classes}
        className={classes.root}
        maxHeight={"md"}
        maxWidth={"md"}
        aria-labelledby="simple-dialog-title"
        open={this.props.open}
      >

        <div
          className={
            classes.bigOnetime
          }
        >
          <div className={[classes.header].join(" ")}>
            {/* <div className={classes.txtHeader}> */}
            {`Edit ${this.props.orderType}`}
            <span
              className={classes.clearIcon}
              onClick={() => this.handleSideClose()}
            >
              &#x2715;
            </span>
            {/* </div> */}
          </div>
          <div className={classes.bigBody}>
            <div className={classes.bodyLeft}>
              <div className={classes.bodyPadding}>
                <div className={classes.leftBigHeading}>
                  {this.props.editData.SchemeName}
                  <u className={classes.change} onClick={this.handleChange}>
                    {Labels.bigPopup.change}
                  </u>
                </div>
                <div className={classes.leftSmallHeading}>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span
                      className={classes.smallDetailLeft}
                      style={{ paddingLeft: 0 }}
                    >
                      {Labels.bigPopup.category} :
                    </span>
                    <span className={classes.smallDetailRight}>
                      {this.props.editData?.AssetClass?.charAt(0)}
                      {this.props.editData?.AssetClass?.slice(1).toLowerCase()}
                      <Divider
                        orientation="vertical"
                        flexItem
                        className={classes.divider}
                      />
                    </span>
                  </div>


                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <span className={classes.smallDetailLeft}>
                      {Labels.bigPopup.risk} :
                    </span>
                    <span className={classes.smallDetailRight}>
                      {this.props.editData.Risk}
                      <Divider
                        orientation="vertical"
                        flexItem
                        className={classes.divider}
                      />
                    </span>
                  </div>


                  <div className={classes.topSpace} >

                    <span className={classes.smallDetailLeft}>
                      {Labels.bigPopup.AUM} :
                    </span>
                    <span className={classes.smallDetailRight}>
                      {NegativeFinding(this.props.editData.AUM, "", "", "", "rupee")}
                    </span>
                  </div>
                </div>
                <div className={classes.padding}>
                  <div style={{ fontWeight: "bold", fontSize: 28 }}>
                    {this.props.editData.Nav != undefined ? amountCommaSeperator(this.props.editData.Nav) : "NA"}
                  </div>
                  <span
                    className={classes.smallDetailLeft}
                    style={{ paddingLeft: 0 }}
                  >
                    {Labels.bigPopup.nav} {this.props.editData.NavDate}
                  </span>
                </div>
              </div>
            </div>

            <div className={classes.bodyRight}>
              <div className={classes.bodyPadding}>
                <div className={classes.bodyRightHeading}>
                  {Labels.bigPopup.investmentDetails}
                  <span>
                    <img src={Question} style={{ marginLeft: 9 }}></img>
                  </span>
                </div>
                <div className={classes.bodyRightButton}>
                  <button className={classes.btnSelected}>
                    {this.props.orderType}
                  </button>
                </div>

                <div className={classes.investmentBlock}>
                  <div>
                    <div className={classes.investmentRow}>
                      <div
                        className={classes.investmentLabel}
                        style={{ marginTop: 3 }}
                      >
                        {Labels.bigPopup.amount}
                      </div>
                      <div className={classes.investmentLabelSpace}>
                        <OutlinedInput
                          type="text"
                          style={{
                            paddingTop: "0 !important",
                            paddingBottom: "0 !important",
                          }}
                          // classes={{}}
                          className={[
                            classes.inputRoot,
                            classes.inputWidth,
                          ].join(" ")}
                          id="outlined-adornment-amount"
                          defaultValue={this.state.amount}
                          value={
                            this.state.amount != 0
                              ? this.state.amount
                              : ""
                          }
                          // onKeyDown={onAmountKeypress}
                          onChange={this.onChangeAmount}
                          autoComplete="off"
                          startAdornment={
                            <InputAdornment position="start">
                              ₹
                            </InputAdornment>
                          }
                          endAdornment={
                            this.props.orderType == "SIP" && (
                              <div style={{ float: "right" }}>
                                <button
                                  className={classes.textBoxMonthly}
                                >
                                  {Labels.bigPopup.monthly}
                                </button>
                              </div>
                            )
                          }
                          size="small"
                        />
                      </div>
                      <span
                        style={{
                          color: "red",
                          fontSize: 12,
                          textAlign: "left",
                        }}
                      >
                        {this.state.amountValidationMessage}
                      </span>
                      {/* )} */}
                    </div>
                  </div>
                  <div className={classes.investmentDropDown}>
                    {this.props.popupType === "sipPopup" && (
                      <div className={classes.investmentColumn}>
                        <div
                          className={[
                            classes.investmentLabelSpace,
                            classes.topDateSpace,
                          ].join("")}
                        >
                          <Dropdown
                            placeholder="Select SIP start date"
                            label={Labels.bigPopup.sipDate}
                            height={135}
                            id="editSipCart"
                            type={"normalwithsearch"}
                            List={this.state.dateList}
                            error={this.state.dateError}
                            handleOnChange={this.dropdowndata}
                            value={this.state.startDate}
                          />
                          {/* // autoselect={true} /> */}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className={classes.investmentBlock}>
                  {this.props.popupType === "sipPopup" && (
                    <>


                      <div className={classes.investmentRow}>
                        <div className={classes.investmentLabel}>
                          {Labels.bigPopup.noOfInstallments}
                        </div>
                        <div
                          className={[
                            classes.investmentLabelSpace,
                            classes.amountwidth,
                          ].join("")}
                        >
                          <OutlinedInput
                            type="text"
                            className={[
                              classes.textField,
                              classes.inputRoot,
                              classes.inputWidth,
                              classes.textFieldCursor,
                            ].join(" ")}
                            defaultValue={this.props.editData.Installments}
                            classes={{
                              adornedEnd: classes.adornedEnd,
                            }}
                            value={this.state.installments}
                            // onKeyDown={onAmountKeypress}
                            onChange={this.handleInstalment}
                            autoComplete="off"
                            // style={{ height: 40, width: 180, paddingLeft: 10 }}
                            size="small"
                          />
                        </div>

                        <span
                          style={{
                            color: this.state.installmentsMessage != "" ? "red" : "grey",
                            fontSize: 12,
                            textAlign: "left",
                          }}
                        >
                          {this.state.installmentsMessage != "" ? this.state.installmentsMessage : Labels.bigPopup.minimumInstallment}
                        </span>

                      </div>
                      <div className={classes.investmentColumnCheck}>
                        <div className={classes.checkBox}>
                          <CommonCheckbox
                            label={Labels.bigPopup.checkBox}
                            checked={this.state.checked}
                            onChange={this.handleCheckbox}
                          />
                          {/* {this.state.selectedDate != "" && ( */}
                          <div style={{ fontSize: 14, marginTop: '0.7em', textAlign: 'left', display: "flex", flexDirection: "column", gap: 10 }}>
                            <span>
                              SIP Start date will be <b> {this.state.selectedDate}</b>
                            </span>
                            <span>
                              Next installment will be <b> {
                                moment(moment(
                                  this.state.selectedDate,
                                  "DD MMM YYYY"
                                )
                                  .add(1, 'M')
                                  .toString()
                                ).format("DD MMM YYYY")}</b>
                            </span>
                          </div>
                          {/* <span style={{ fontSize: 14 }}>

                            {this.state.checked
                              ? labels.bigPopup.firstInstallmentsDate
                              : labels.bigPopup.firstInstallmentWillBe}
                            <b>
                              {this.state.checked && !this.findDate(this.state.dropDownValue)
                                ? moment(
                                  moment(
                                    this.state.selectedDate,
                                    "DD MMM YYYY"
                                  )
                                    .add(1, "months")
                                    .toString()
                                ).format("DD MMM YYYY")
                                : this.state.selectedDate}
                            </b>
                          </span> */}
                          {/* )} */}
                        </div>
                      </div>

                    </>
                  )}
                </div>

                <div
                  style={{
                    color: "red",
                    fontSize: 12,
                    textAlign: "left",
                    marginTop: 14
                  }}
                >
                  {this.props.orderType != "One Time" && this.state.showMessage}
                </div>
              </div>
            </div>
          </div>
          <div className={classes.bodyBottom}>
            <Divider className={classes.bottomDivider} />
            <div className={classes.bottomButton}>

              <Button
                name={Labels.bigPopup.saveDetails}
                page="primary"
                onClick={() => this.onClick()}
                loader={this.props.loader}
              ></Button>

            </div>
          </div>
        </div>

      </Dialog>
    )
  }

}
const mapStateToProps = (state) => ({
  date: state.Login.date,
  getCartByID: state.CartReducer.getCartByID,
  sipCartData: state.CartReducer.getAllSipCart == "NA" ? [] : state.CartReducer.getAllSipCart,
  onetimeCartData: state.CartReducer.getAllOnetimeCart == "NA" ? [] : state.CartReducer.getAllOnetimeCart,
  clientID: state.Login.logindetails.ClientID,
  isCartDeleteLoading: state.CartReducer.isDeleteLoading,
  logindetails: state.Login.logindetails,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ AddToCart, GetAllCart }, dispatch);
};
export default withStyles(styles)(withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditTrading))
);
