import * as types from '../../constants/actionsType';
import getRiaFlowOnLinePaymentReportDetails from "../../../api/riaflowonlinepaymentapi/riaflowonlinepaymentapi"
import getRiaFlowOffLinePaymentReportDetails from "../../../api/riaflowofflinepaymentapi/riaflowofflinepaymentapi"
import { SUCCESS } from '../../../utils/colors/colors';
export function getRiaFlowOnLinePaymentReport(display_name, plan_code, email, mobile, redirect_url, FormNo, EmailID, PlanCode, GstNumber, PaymentType, ClientID) {
    return (dispatch) => {
        if (display_name != undefined && plan_code != undefined && email != undefined && mobile != undefined && redirect_url != undefined) {
            getAllRiaFlowOnLinePaymentReport(dispatch, display_name, plan_code, email, mobile, redirect_url, FormNo, EmailID, PlanCode, GstNumber, PaymentType, ClientID);
        }
        else {
            getRiaFlowOnLinePaymentReportEmpty(dispatch)
        }

    };
}

//This function is used for To do Empty the payload
function getRiaFlowOnLinePaymentReportEmpty(dispatch) {
    // 
    dispatch({
        type: types.RIA_FLOW_ONLINE_PAYMENT,
        payload: [],
    });
}
function getAllRiaFlowOnLinePaymentReport(dispatch, display_name, plan_code, email, mobile, redirect_url, FormNo, EmailID, PlanCode, GstNumber, PaymentType, ClientID) {
    let response
    dispatch({
        type: types.LOADER_RIA_FLOW_ONLINE_PAYMENT,
        payload: []
    });

    getRiaFlowOnLinePaymentReportDetails(display_name, plan_code, email, mobile, redirect_url).then((data) => {
        dispatch({
            type: types.RIA_FLOW_ONLINE_PAYMENT,
            payload: [],
        });
        if (data != undefined) {
            if (data.hostedpage.status == "fresh") {
                //  window.open(data.hostedpage.url,"_self")

                //Second API call (get a url after call offline api)
                // if (data.hostedpage.url != undefined) {
                //     //                   
                //     getRiaFlowOffLinePaymentReportDetails(FormNo, EmailID, PlanCode, GstNumber, PaymentType, ClientID).then((data) => {
                //         if (data != undefined) {
                //             return 'success'
                //         }
                //     })
                // }
                // --------------------\\
                dispatch({
                    type: types.RIA_FLOW_ONLINE_PAYMENT,
                    //payload: data.hostedpage.url
                    payload: data.hostedpage
                });
            }
            else {
                // 
                dispatch({
                    type: types.RIA_FLOW_ONLINE_PAYMENT,
                    payload: [],
                });
            }
        }
        else {
            // 
            dispatch({
                type: types.RIA_FLOW_ONLINE_PAYMENT,
                payload: [],
            });
        }
        response = data
    })
        .catch((error) => {
            response = []
            dispatch({
                type: types.RIA_FLOW_ONLINE_PAYMENT,
                payload: [],
            });
        })
    return response
}