import * as types from '../../constants/actionsType'
import { bindActionCreators } from 'redux';
import { replaceNull } from '../../../utils/commonfunction';
import moment from 'moment';
const initialState = {
    aifList: [],
    aifBackupList: [],
    pmsList: [],
    transactionAifList: [],
    valuationAifList: [],
    transactionPmsList: [],
    valuationPmsList: [],
    isAIFLoading: false,
    isPmsLoading: false,
    isAifTransactionLoading: false,
    isPmsTransactionLoading: false
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.AIF_LIST:

            let arrangeArray = action.payload != null ?
                action.payload.length != 0 ? replaceNull(action.payload, 'NA').map((item, index) => {

                    return {
                        "SchemeName": { name: item.SchemeName, subHeader1: item.TransactionCounts, subHeader2: item.CommitedInstalmentCounts==0?"Add Instalment":item.CommitedInstalmentCounts>1?`${item.CommitedInstalmentCounts} Instalments`:`${item.CommitedInstalmentCounts} Instalment`,subHeader2Type:'link',subHeaderType:'link', type: "", label: "Scheme Name" },
                        "AMCRefNo": { name: item.AMCRefCode, subHeader1: "NA", subHeader2: "", type: "", label: "AMC Ref No" },
                        "AIFCategory": { name: item.Category, subHeader1: 'NA', subHeader2: '', type: "", label: "AIF Category" },
                        "FirstInvestmentDate": { name: moment(item.InvestmentDate).format("DD MMM YYYY"), subHeader1: 'NA', subHeader2: '', type: "", label: "First Investment Date" },
                        "CurrentValue": { name: item.CurrentValue == undefined ? 0 : item.CurrentValue, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Current Value" },
                        "InvestmentAmount": { name: item.TotalInvestedAmount, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Investment Amount" },
                        "ID": { name: item.ID, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },
                        "IsInvested": { name: item.IsInvested, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },


                    }
                })
                    : [] : []
                    // console.log(action.payload)
            return {
                ...state,
                aifList: arrangeArray != null && arrangeArray != undefined ? state.aifList.length == 0 ? [...arrangeArray] : [...arrangeArray, ...state.aifList] : [],
                aifBackupList: replaceNull(state.aifBackupList == undefined || state.aifBackupList.length == 0 ? [...action.payload] : [...action.payload, ...state.aifBackupList], 'NA'),
                isAIFLoading: false
            }
        case types.UPDATE_AIF_LIST:

            const elementsIndex = state.aifBackupList.findIndex(
                (item) => item.ID === action.payload.ID
            );
            let aifBackupList = [...state.aifBackupList];
            aifBackupList[elementsIndex] = {
                ...aifBackupList[elementsIndex],
                CurrentValue: action.payload.CurrentValue,

            };

            let arrangeUpdatedArray = aifBackupList != null ?
                aifBackupList.length != 0 ? aifBackupList.map((item, index) => {

                    return {
                        "SchemeName": { name: item.SchemeName, subHeader1: item.TransactionCounts, subHeader2: item.CommitedInstalmentCounts==0?"Add Instalment":item.CommitedInstalmentCounts>1?`${item.CommitedInstalmentCounts} Instalments`:`${item.CommitedInstalmentCounts} Instalment`,subHeader2Type:'link',subHeaderType:'link', type: "", label: "Scheme Name" },
                        "AMCRefNo": { name: item.AMCRefCode, subHeader1: "NA", subHeader2: "", type: "", label: "AMC Ref No" },
                        "AIFCategory": { name: item.Category, subHeader1: 'NA', subHeader2: '', type: "", label: "AIF Category" },
                        "FirstInvestmentDate": { name: moment(item.InvestmentDate).format("DD MMM YYYY"), subHeader1: 'NA', subHeader2: '', type: "", label: "First Investment Date" },
                        "CurrentValue": { name: item.CurrentValue == undefined ? 0 : item.CurrentValue, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Current Value" },
                        "InvestmentAmount": { name: item.TotalInvestedAmount, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Investment Amount" },
                        "ID": { name: item.ID, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },
                        "IsInvested": { name: item.IsInvested, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },


                    }
                })
                    : [] : []
            return {
                ...state,
                aifList: replaceNull(arrangeUpdatedArray),
                aifBackupList: replaceNull(aifBackupList, "NA")
            }
        case types.DELETE_AIF_LIST:
            let backupArray = state.aifBackupList.filter((item) => item.ID != action.payload)
            let arrangeAIFArray = backupArray != null ?
                backupArray.length != 0 ? backupArray.map((item, index) => {
                    return {
                        "SchemeName": { name: item.SchemeName, subHeader1: item.TransactionCounts, subHeader2: item.CommitedInstalmentCounts==0?"Add Instalment":item.CommitedInstalmentCounts>1?`${item.CommitedInstalmentCounts} Instalments`:`${item.CommitedInstalmentCounts} Instalment`,subHeader2Type:'link',subHeaderType:'link', type: "", label: "Scheme Name" },
                        "AMCRefNo": { name: item.AMCRefCode, subHeader1: "NA", subHeader2: "", type: "", label: "AMC Ref No" },
                        "AIFCategory": { name: item.Category, subHeader1: 'NA', subHeader2: '', type: "", label: "AIF Category" },
                        "FirstInvestmentDate": { name: moment(item.InvestmentDate).format("DD MMM YYYY"), subHeader1: 'NA', subHeader2: '', type: "", label: "First Investment Date" },
                        "CurrentValue": { name: item.CurrentValue == undefined ? 0 : item.CurrentValue, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Current Value" },
                        "InvestmentAmount": { name: item.TotalInvestedAmount, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Investment Amount" },
                        "ID": { name: item.ID, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },
                        "IsInvested": { name: item.IsInvested, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },
                    }
                }) :
                    [] : []
            return {
                ...state,
                aifList: [...arrangeAIFArray],
                aifBackupList: backupArray
            }
        case types.ADD_UPDATE_DELETE_TRANSACTION:
            let oldArray = state.transactionAifList
            let newArray = []
            if (action.flag == "add") {
                newArray = oldArray.length != 0 ? [...action.data, ...oldArray] : [...action.data]
            } else if (action.flag == "update") {
                const elementsIndex = state.transactionAifList.findIndex(
                    (item) => item.ID === action.data.ID
                );
                newArray = [...state.transactionAifList];
                newArray[elementsIndex] = {
                    ...newArray[elementsIndex],
                    transactionAmountValue: action.data.transactionAmountValue,
                    transactionDate: action.data.transactionDate,
                    transactionType: action.data.transactionType,

                };
            } else {
                newArray = oldArray.filter((item) => item.ID == action.data.ID)
            }
            return {
                ...state,
                transactionAifList: newArray
            }
        case types.EMPTY_AIF_LIST:
            return {
                aifList: [],
                pmsList: [],
                transactionAifList: [],
                valuationAifList: [],
                transactionPmsList: [],
                valuationPmsList: [],
                isAIFLoading: false,
                isPmsLoading: false,
                isAifTransactionLoading: false,
                isPmsTransactionLoading: false
            }
        case types.LOADER_AIF_LIST:
            return {
                ...state,
                isAIFLoading: action.payload,

            }
        case types.PMS_LIST:

            let arrangePmsArray = action.payload != null ?
                action.payload.length != 0 ? replaceNull(action.payload, 'NA').map((item, index) => {

                    return {
                        "SchemeName": { name: item.SchemeName, subHeader1: item.TransactionCounts, subHeader2: item.CommitedInstalmentCounts==0?"Add Instalment":item.CommitedInstalmentCounts>1?`${item.CommitedInstalmentCounts} Instalments`:`${item.CommitedInstalmentCounts} Instalment`,subHeader2Type:'link',subHeaderType:'link', type: "", label: "Scheme Name" },
                        "AMCRefNo": { name: item.AMCRefCode, subHeader1: "NA", subHeader2: "", type: "", label: "AMC Ref No" },
                        "AIFCategory": { name: item.Category, subHeader1: 'NA', subHeader2: '', type: "", label: "AIF Category" },
                        "FirstInvestmentDate": { name: moment(item.InvestmentDate).format("DD MMM YYYY"), subHeader1: 'NA', subHeader2: '', type: "", label: "First Investment Date" },
                        "CurrentValue": { name: item.CurrentValue == undefined ? 0 : item.CurrentValue, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Current Value" },
                        "InvestmentAmount": { name: item.TotalInvestedAmount, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Investment Amount" },
                        "ID": { name: item.ID, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },
                        "IsInvested": { name: item.IsInvested, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },



                    }
                })
                    : [] : []
            return {
                ...state,
                pmsList: arrangePmsArray != null && arrangePmsArray != undefined ? state.pmsList.length == 0 ? [...arrangePmsArray] : [...arrangePmsArray, ...state.pmsList] : [],
                pmsBackupList: replaceNull(state.pmsBackupList == undefined || state.pmsBackupList.length == 0 ? [...action.payload] : [...action.payload, ...state.pmsBackupList], 'NA'),
                isPMSLoading: false
            }
        case types.UPDATE_PMS_LIST:

            const elementsIndexPms = state.pmsBackupList.findIndex(
                (item) => item.ID === action.payload.ID
            );
            let pmsBackupList = [...state.pmsBackupList];
            pmsBackupList[elementsIndexPms] = {
                ...pmsBackupList[elementsIndexPms],
                CurrentValue: action.payload.CurrentValue,

            };

            let arrangeUpdatedPmsArray = pmsBackupList != null ?
                pmsBackupList.length != 0 ? pmsBackupList.map((item, index) => {

                    return {
                        "SchemeName": { name: item.SchemeName, subHeader1: item.TransactionCounts, subHeader2: item.CommitedInstalmentCounts==0?"Add Instalment":item.CommitedInstalmentCounts>1?`${item.CommitedInstalmentCounts} Instalments`:`${item.CommitedInstalmentCounts} Instalment`,subHeader2Type:'link',subHeaderType:'link', type: "", label: "Scheme Name" },
                        "AMCRefNo": { name: item.AMCRefCode, subHeader1: "NA", subHeader2: "", type: "", label: "AMC Ref No" },
                        "AIFCategory": { name: item.Category, subHeader1: 'NA', subHeader2: '', type: "", label: "AIF Category" },
                        "FirstInvestmentDate": { name: moment(item.InvestmentDate).format("DD MMM YYYY"), subHeader1: 'NA', subHeader2: '', type: "", label: "First Investment Date" },
                        "CurrentValue": { name: item.CurrentValue == undefined ? 0 : item.CurrentValue, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Current Value" },
                        "InvestmentAmount": { name: item.TotalInvestedAmount, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Investment Amount" },
                        "ID": { name: item.ID, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },
                        "IsInvested": { name: item.IsInvested, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },



                    }
                })
                    : [] : []
            return {
                ...state,
                pmsList: replaceNull(arrangeUpdatedPmsArray),
                pmsBackupList: replaceNull(pmsBackupList, "NA")
            }
        case types.DELETE_PMS_LIST:
            let backupPmsArray = state.pmsBackupList.filter((item) => item.ID != action.payload)
            let arrangePMSArray = backupPmsArray != null ?
                backupPmsArray.length != 0 ? backupPmsArray.map((item, index) => {
                    return {
                        "SchemeName": { name: item.SchemeName, subHeader1: item.TransactionCounts, subHeader2: item.CommitedInstalmentCounts==0?"Add Instalment":item.CommitedInstalmentCounts>1?`${item.CommitedInstalmentCounts} Instalments`:`${item.CommitedInstalmentCounts} Instalment`,subHeader2Type:'link',subHeaderType:'link', type: "", label: "Scheme Name" },
                        "AMCRefNo": { name: item.AMCRefCode, subHeader1: "NA", subHeader2: "", type: "", label: "AMC Ref No" },
                        "AIFCategory": { name: item.Category, subHeader1: 'NA', subHeader2: '', type: "", label: "AIF Category" },
                        "FirstInvestmentDate": { name: moment(item.InvestmentDate).format("DD MMM YYYY"), subHeader1: 'NA', subHeader2: '', type: "", label: "First Investment Date" },
                        "CurrentValue": { name: item.CurrentValue == undefined ? 0 : item.CurrentValue, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Current Value" },
                        "InvestmentAmount": { name: item.TotalInvestedAmount, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Investment Amount" },
                        "ID": { name: item.ID, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },
                        "IsInvested": { name: item.IsInvested, subHeader1: 'NA', subHeader2: '', type: "", label: "Scheme Name" },



                    }
                }) :
                    [] : []
            return {
                ...state,
                pmsList: [...arrangePMSArray],
                pmsBackupList: backupPmsArray
            }
        case types.ADD_UPDATE_DELETE_PMS_TRANSACTION:
            let oldPmsArray = state.transactionPmsList
            let newPmsArray = []
            if (action.flag == "add") {
                newPmsArray = oldPmsArray.length != 0 ? [...action.data, ...oldPmsArray] : [...action.data]
            } else if (action.flag == "update") {
                const elementsIndex = state.transactionPmsList.findIndex(
                    (item) => item.ID === action.data.ID
                );
                newPmsArray = [...state.transactionPmsList];
                newPmsArray[elementsIndex] = {
                    ...newPmsArray[elementsIndex],
                    transactionAmountValue: action.data.transactionAmountValue,
                    transactionDate: action.data.transactionDate,
                    transactionType: action.data.transactionType,

                };
            } else {
                newPmsArray = oldPmsArray.filter((item) => item.ID == action.data.ID)
            }
            return {
                ...state,
                transactionPmsList: newPmsArray
            }
        case types.EMPTY_PMS_LIST:
            return {
                ...state,
                pmsList: [],
                transactionPmsList: [],
                valuationPmsList: [],
                transactionPmsList: [],
                valuationPmsList: [],

                isPmsLoading: false,

                isPmsTransactionLoading: false
            }
        case types.LOADER_PMS_LIST:
            return {
                ...state,
                isPMSLoading: action.payload,

            }

        default:
            return state
    }
}
export default reducer