import React, { Component } from 'react'
import { withStyles } from '@material-ui/core';
import { connect } from 'react-redux';
import labels from '../../utils/constants/labels';
import FilterImage from "../../utils/assets/images/filter.png";
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import { Redirect } from 'react-router-dom';
import cancelRunningSIP from '../../api/runningsip/cancelsip';
import CommonSnackbar from '../../components/snackbar/snackbar'
import CircularProgress from '@material-ui/core/CircularProgress';
import SelectedFilterImage from "../../utils/assets/selectedfilter.png";
import hoverSelectedFilterImage2 from "../../utils/assets/img23-Copy.png";
import { getL5reportDetails } from "../../redux/actions/L5Report/L5ReportAction";
import hoverFilterIcons from "../../utils/assets/img23.png";
import { bindActionCreators } from 'redux';
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle'
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import NoData from "../../components/nodata/nodata";
import Loader from "../../components/loader/loader";
import Headers from "../../components/report_common_header/report_common_header";
import Pagination from '../../components/pagination/renderpage';
import { MContext } from '../../components/pagination/renderpage';
import colors from '../../utils/colors/colors';
const styles = theme => ({
    root: {
        display: 'grid',
        gridTemplateColumns: '100%',
        marginTop: "2%",
        marginBottom: "5%"
    },
    card: {
        marginBottom: '40px',
    },
    buttonContainer: {
        display: 'flex',
        columnGap: '20px',
        width: '90%',
        padding: '20px 0px',
        flexDirection: 'row',
        [theme.breakpoints.down("sm")]: {
            width: '100%',
        },
        [theme.breakpoints.down("sm")]: {
            width: '100%',
            flexDirection: 'column',
            rowGap: '10px',
        },
    },
    filterContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)',
        alignItems: 'center',
        justifyContent: 'center',
    },
    filterIcon: {
        width: "29px",
        height: "29px",
        cursor: "pointer",
    },
    align: {
        padding: '35px 0px 0px 0px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignIitems: 'inherit',
        [theme.breakpoints.down("sm")]: {
            padding: '10px 0px 0px 0px',
        },
    },
    directionColumn: {
        display: "grid",
        flexDirection: "column",
        [theme.breakpoints.down("900")]: {
            // display: "none"
        }
    },
    label: {

        paddingBottom: 6,
        fontWeight: "normal",
        display: "block",
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: "14px",
        textAlign: "left"

    },
    displayMobileResponsive: {
        [theme.breakpoints.down("900")]: {
            // display: "none !important"
        }
    },
    sumBoxFil2: {
        float: "left",
        // width: "7%",
    },
    selectedFilterImagestyle: {
        width: 35,
        height: 37,
        background: `url(${SelectedFilterImage}) center center no-repeat`,
        position: "absolute",
        cursor: "pointer",
        "&:hover": {
            background: `url(${hoverSelectedFilterImage2}) center center no-repeat`,
        },
        [theme.breakpoints.between("320", "900")]: {
            left: 0,
            marginLeft: 0,
            top: "revert",
        },
    },
    mobileResponsive: {
        display: "none",
        [theme.breakpoints.down("920")]: {
            display: "block"
        }
    },
    filterImagestyle: {
        [theme.breakpoints.up("900")]: {
            width: 35,
            height: 37,
            background: `url(${FilterImage}) center center no-repeat`,
            position: "absolute",
            cursor: "pointer",
            "&:hover": {
                background: `url(${hoverFilterIcons}) center center no-repeat`,
            },
        },
        [theme.breakpoints.down("900")]: {
            position: "unset",
            paddingTop: "27px",
            width: 35,
            height: 37,
            left: 0,
            background: `url(${FilterImage}) center center no-repeat`,
        },
    },
    noDataCard: {
        minWidth: '100%',
        padding: '10% 0px',
    },
    dataTwo: {
        display: "flex", flexDirection: "column", gap: 10,
        [theme.breakpoints.down("900")]: {
            gridArea: "dataTwo"
            , gap: 0
        }
    },
    dataOne: {
        display: "flex", flexDirection: "column", gap: 10,
        [theme.breakpoints.down("900")]: {
            gridArea: "dataOne"
            , gap: 0
        }
    }
    , dataThree: {
        display: "flex", flexDirection: "column", gap: 10,
        [theme.breakpoints.down("900")]: {
            gridArea: "dataThree"
            , gap: 0
            , marginTop: "5%"
        }
    },
    dataFour: {
        display: "flex", flexDirection: "column", gap: 10,
        [theme.breakpoints.down("900")]: {
            gridArea: "dataFour",
            gap: 0,
        }
    },
    dataFive: {
        display: "flex", flexDirection: "column",
        [theme.breakpoints.down("900")]: {
            gridArea: "dataFive",
            gap: 0,
        }
    },
    dataSix: {
        display: "flex", flexDirection: "column", gap: 10,
        [theme.breakpoints.down("900")]: {
            gridArea: "dataSix",
            gap: 1,
        }
    },
    dataSeven: {
        display: "flex", flexDirection: "column", gap: 10,
        [theme.breakpoints.down("900")]: {
            gridArea: "dataSeven",
            gap: 0,
        }
    },
    dataEight: {
        display: "flex", flexDirection: "column", gap: 10,
        [theme.breakpoints.down("900")]: {
            gridArea: "dataEight",
            gap: 0
        }
    },
    dataNine: {
        display: "flex", flexDirection: "column", gap: 10,
        [theme.breakpoints.down("900")]: {
            gridArea: "dataNine",
            gap: 0,
        }
    },
    dataTen: {
        display: "flex", flexDirection: "column", gap: 10,
        [theme.breakpoints.down("900")]: {
            gridArea: "dataTen"
        }
    },
    showVertIcon: {
        [theme.breakpoints.down("900")]: {
            gridArea: "showVertIcon"
        }
    },
    hideDiv: {
        [theme.breakpoints.down("920")]: {
            display: "none !important"
        }
    },
});
class EPFReport extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cancelPopupOpen: false,
            anchorEl: null,
            UCCCode: "",
            SchemeDetails: "",
            folioNumber: "",
            List: [],
            singleList: [],
            loader: false,
            message: "", severity: "warning"
        }
    }

    componentDidMount() {

    }
    handleClose = () => {
        this.setState({ anchorEl: null })
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };
    handleClick = async (e, value) => {
        // console.log(value)
        await this.setState({ anchorEl: e.currentTarget, singleList: value })
        // this.setState({ UCCCode: value.UCC })
        // this.setState({ folioNumber: value.FolioNo || "" })
        // this.setState({ SchemeDetails: value })
        // cancelRunningSIP(value.XSIPRegnNo, value.FolioNo || "", this.props.logindetails.ClientID).then((response) => {
        //     if (response.status == "S") {
        //         if (response.data != null && response.data.length != 0) {
        //             this.setState({ List: [...response.data, value] })
        //         }
        //     } else {
        //         this.setState({ List: [] })
        //     }
        // })
    }
    // handleListItemClick = () => {
    //     if (this.state.List.length != 0) {
    //         this.setState({ cancelPopupOpen: true, anchorEl: null })
    //     } else {
    //         this.setState({ open: true, message: labels.messages.apierror, severity: "warning", anchorEl: null })
    //     }
    // };
    render() {
        const { classes } = this.props;
        let a = this.state.singleList?.Type?.toLowerCase()

        try {


            return (
                <ErrorBoundary>
                    {this.props.loader ? <Loader page={!this.props.showHeader ? "table" : "skeletonreport"}></Loader> : <>
                        {this.props.showHeader != false && <Headers
                            flag={this.props.flag}
                            showfilter={this.props.showfilter != undefined ? this.props.showfilter : true}
                            showdownload={this.props.showdownload != undefined ? this.props.showdownload : true}
                            header={this.props.singleScheme}
                            submit={this.props.submit}
                            scheme={this.props.data.length == 0 ? <NoData></NoData> : this.props.data.length <= 1 ? `${this.props.data.length} ${this.props.singleScheme}` : `${this.props.data.length} ${this.props.multipleScheme}`}
                            page={this.props.flag}
                            data={this.props.data}
                            List={[]}
                            selected={false}
                        />}
                        {this.props.data?.length != 0 &&
                            <div className={[classes.root, this.props.root].join(" ")}>

                                {this.props.title != undefined && this.props.showTitle != false &&
                                    <div className={[classes.tableHeader, this.props.grid, classes.displayMobileResponsive].join(' ')} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                        {this.props.title.map((item, index) => {
                                            return (
                                                <div key={index} className={[classes.directionColumn, `title${index}`, index == 0 || this.props.title.length - 1 == index ? "" : classes.hideDiv].join(" ")} style={{ textAlign: item.name == "Scheme Name" || item.name == "Scrip Name" ? "left" : "right" }}>
                                                    <div style={{ textAlign: item?.align }}>{item.header}</div>
                                                    <div style={{ textAlign: item?.align }}>{item.subHeader}</div>
                                                </div>
                                            )
                                        })}
                                    </div>}
                                <Pagination data={this.props.data}>
                                    <MContext.Consumer>

                                        {(context) => {
                                            return context.map((item, index) => {
                                                return (
                                                    <div key={index} className={[this.props.grid, classes.subCategoryGroupContainer, index == 0 && classes.borderRadiousFirstIndex].join(' ')} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                        {item.dataOne != undefined && <div className={[classes.dataOne, "dataOne"].join(" ")} style={{ textAlign: this.props.title[0]?.align }}>
                                                            {/* <div key={index} className={[classes.mobileResponsive].join(" ")} > */}
                                                            <div className={[classes.label, classes.mobileResponsive].join(" ")}>{this.props.title[0].header}</div>
                                                            {/* </div> */}
                                                            <span> {item.dataOne || labels.messages.NA}</span>
                                                            {item.subDataOne != undefined && <span> {item.subDataOne}</span>}
                                                        </div>}
                                                        {item.dataTwo != undefined && <div className={[classes.dataTwo, "dataTwo"].join(" ")} style={{ textAlign: this.props.title[1]?.align, }}>
                                                            {/* <div key={index} className={[classes.mobileResponsive].join(" ")} > */}
                                                            <div className={[classes.label, classes.mobileResponsive].join(" ")}>{this.props.title[1].header}</div>
                                                            {/* </div> */}
                                                            <span> {item.dataTwo || labels.messages.NA}</span>
                                                            {item.subDataTwo != undefined && item.subDataTwo != "NA" && <span> {item.subDataTwo}</span>}
                                                        </div>}
                                                        {item.dataThree != undefined && <div className={[classes.dataThree, "dataThree"].join(" ")} style={{ textAlign: this.props.title[2]?.align, }}>
                                                            {/* <div key={index} className={[classes.mobileResponsive].join(" ")} > */}
                                                            <div className={[classes.label, classes.mobileResponsive].join(" ")}>{this.props.title[2].header}</div>
                                                            {/* </div> */}
                                                            <span> {item.dataThree}</span>
                                                            {item.subDataThree != undefined && <span> {item.subDataThree}</span>}
                                                        </div>}
                                                        {item.dataFour != undefined && <div className={[classes.dataFour, "dataFour"].join(" ")} style={{ textAlign: this.props.title[3]?.align }}>
                                                            {/* <div key={index} className={[classes.mobileResponsive].join(" ")} > */}
                                                            <div className={[classes.label, classes.mobileResponsive].join(" ")}>{this.props.title[3].header}</div>
                                                            {/* </div> */}
                                                            {item.dataFour}</div>}
                                                        {item.dataFive != undefined && <div className={[classes.dataFive, "dataFive"].join(" ")} style={{ textAlign: this.props.title[4].align, color: this.props.title[4].header.toLowerCase() != "status" || (this.props.title[4].header.toLowerCase() == "status" && item.dataFiveDot != undefined) ? colors.BLACK_PRIMARY : item.dataFive == "Failed" ? colors.red : colors.GREEN }}>
                                                            {/* <div key={index} className={[classes.mobileResponsive].join(" ")} > */}
                                                            {this.props.title[4].header != undefined && <div className={[classes.label, classes.mobileResponsive].join(" ")}>{this.props.title[4].header}</div>}
                                                            <div style={item.subDataFive != undefined ? { display: "grid", gridTemplateColumns: "3% 98%", gap: 10, alignItems: "center" } : {}}>
                                                                {item.dataFiveDot != undefined && <div className={item.dataFiveDot}>
                                                                    {" "}
                                                                </div>
                                                                }
                                                                <span>  {item.dataFive || labels.messages.NA}</span>
                                                            </div>
                                                            {item.subDataFive != undefined && <span> {item.subDataFive}</span>}
                                                        </div>}

                                                        {item.dataSix != undefined && <> {item.dataSix == "" ? <div> </div> : <div className={[classes.dataSix, "dataSix"].join(" ")} style={{ textAlign: this.props.title[5]?.align, opacity: item?.dataSixValidation ? 0.5 : 1, cursor: item?.dataSixValidation ? "not-allowed" : "pointer" }} onClick={() => item?.dataSixClick != undefined && item.dataSixValidation == false && item?.dataSixClick(item)}>
                                                            {/* <div key={index} className={[classes.mobileResponsive].join(" ")}> */}
                                                            {this.props.title[5]?.header != undefined && <div className={[classes.label, classes.mobileResponsive].join(" ")}>{this.props.title[5]?.header}</div>
                                                            }
                                                            {item?.dataSixLoader ? <CircularProgress size={30} thickness={1} color='white'></CircularProgress> : item.dataSix || labels.messages.NA}                                                           {/* </div> */}
                                                        </div>}</>}
                                                        {item.dataSeven != undefined && <> {item.dataSeven == "" ? <div> </div> : <div className={[classes.dataSeven, "dataSeven"].join(" ")} style={{ textAlign: this.props.title[6]?.align, opacity: item?.dataSevenValidation ? 0.5 : 1, cursor: item?.dataSevenValidation ? "not-allowed" : "pointer" }} onClick={() => item?.dataSevenClick != undefined && item.dataSevenValidation == false && item?.dataSevenClick(item)}>
                                                            {/* <div key={index} className={[classes.mobileResponsive].join(" ")}> */}
                                                            {this.props.title[6]?.header != undefined && <div className={[classes.label, classes.mobileResponsive].join(" ")}>{this.props.title[6]?.header}</div>
                                                            }
                                                            {item?.dataSevenLoader ? <CircularProgress size={30} thickness={1} color='white'></CircularProgress> : item.dataSeven || labels.messages.NA}                                                           {/* </div> */}
                                                        </div>}</>}
                                                        {item.dataEight != undefined && <div className={[classes.dataEight, "dataEight"].join(" ")} style={{ textAlign: this.props.title[7]?.align, ...item?.dataEightStyle }} >
                                                            {/* <div key={index} className={[classes.mobileResponsive].join(" ")} > */}
                                                            <div className={[classes.label, classes.mobileResponsive].join(" ")}>{this.props.title[7].header}</div>
                                                            {/* </div> */}
                                                            {item.dataEight || labels.messages.NA}</div>}
                                                        {item.dataNine != undefined && <div className={[classes.dataNine, "dataNine"].join(" ")} style={{ textAlign: this.props.title[8]?.align }}>
                                                            {/* <div key={index} className={[classes.mobileResponsive].join(" ")} > */}
                                                            <div className={[classes.label, classes.mobileResponsive].join(" ")}>{this.props.title[8].header}</div>
                                                            {/* </div> */}
                                                            {item.dataNine || labels.messages.NA}</div>}
                                                        {item.dataTen != undefined && <div className={[classes.dataTen, "dataTen"].join(" ")} style={{ textAlign: this.props.title[9]?.align, ...item?.dataTenStyle }}>
                                                            {/* <div key={index} className={[classes.mobileResponsive].join(" ")} > */}
                                                            <div className={[classes.label, classes.mobileResponsive].join(" ")}>{this.props.title[9].header}</div>
                                                            {/* </div> */}
                                                            {item.dataTen || labels.messages.NA}</div>}
                                                        {item.dataEleven != undefined && <div className={[classes.dataEleven, "dataEleven"].join(" ")} style={{ textAlign: this.props.title[10]?.align }}>
                                                            {/* <div key={index} className={[classes.mobileResponsive].join(" ")} > */}
                                                            <div className={[classes.label, classes.mobileResponsive].join(" ")}>{this.props.title[10].header}</div>
                                                            {/* </div> */}
                                                            {item.dataEleven || labels.messages.NA}</div>}


                                                        {item.isShowVert && <div className={[classes.showVertIcon, "showVertIcon"].join(" ")}>
                                                            {item.isLoading == true ? <CircularProgress style={{ color: window.globalConfig.color.brown }} size={24} /> : <MoreVertIcon style={{ cursor: "pointer" }} onClick={(e) => this.handleClick(e, item)}></MoreVertIcon>
                                                            }
                                                        </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }}
                                    </MContext.Consumer>
                                </Pagination>

                            </div>}
                        {/* {console.log(this.state.singleList)} */}
                        {this.props.showNoDataAvailable && this.props.data.length == 0 && <NoData></NoData>}
                        {this.props.popupList != undefined && <div className={classes.cancePopup}
                            onMouseLeave={() => this.handleClose()}
                            onKeyUp={() => this.handleClose()}
                            onKeyDown={() => this.handleClose()}
                            onWheel={() => this.handleClose()}
                        >
                            <Popover
                                //    disableScrollLock={true}
                                classes={classes.popperDisablePortal}
                                open={Boolean(this.state.anchorEl)}
                                anchorEl={this.state.anchorEl}
                                onClose={this.handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',

                                }}
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}

                            >
                                <div className="xraypopover"  >
                                    {/* {console.log(this.props.popupList?.filter((val) => val.showIn == "All" || val.showIn?.includes(this.state.singleList?.Type?.toLowerCase())))} */}
                                    {this.props.popupList?.filter((val) => val.showIn == "All" || val.showIn?.includes(this.state.singleList?.Type?.toLowerCase())).map((item) => {

                                        return <ListItem
                                            button
                                            className="popoverlist"
                                            onClick={() => {
                                                item.onClick(this.state.singleList)
                                                this.setState({ anchorEl: null })
                                            }}
                                        >
                                            <ListItemText primary={item.label} />
                                        </ListItem>
                                    })}



                                </div>
                            </Popover>
                        </div>}
                    </>}
                    <CommonSnackbar open={this.state.open} timer={70000} severity={this.state.severity} handlesnanckClose={this.handlesnanckClose} message={this.state.message} />

                </ErrorBoundary>
            )
        }
        catch (err) {
            console.log(err)
            return <Redirect to={labels.Path.NotFound}></Redirect>
        }
    }
}
// class TableBaseComponent extends React.Component {
//     constructor(props) {
//         super(props)
//     }
//     render() {
//         return (

//         )
//     }
// }
const mapStateToProps = (state) => {
    return {
        sessionID: state.Login.sessionID,
        clientID: state.Login.logindetails.ClientID,
        L5ID: state.Login.L5ID,
        date: state.Login.date,
        L4ID: state.Login.logindetails.L4_AccountTypeID,
        logindetails: state.Login.logindetails,
    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        getL5reportDetails,
    }, dispatch)
};
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(EPFReport))