import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CommonSnackbar from '../../components/snackbar/snackbar';
import Headers from '../../components/report_common_header/report_common_header'
import Badges from '../../components/badge/badge';
import Label from '../../utils/constants/labels';
import { withStyles } from '@material-ui/core';
import NoData from '../../components/nodata/nodata'
import { NegativeFinding } from '../../utils/commonfunction';
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import getAllcommonDownloadDetails from '../../api/commondownload/commondownload'
import moment from 'moment'
import Loader from '../../components/loader/loader'
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle'
import colors from '../../utils/colors/colors'
import apiurl from '../../api/url/apiurl';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import constants from '../../utils/constants/apiconstant';
import { MContext } from '../../components/pagination/renderpage';
import Pagination from '../../components/pagination/renderpage';
import Networking from '../../utils/api/apiaccess';
const styles = theme => ({


    mainTitle: {
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: "16px",
        textTransform: "uppercase"
    },
    table: {
        width: "100%",
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "14px",
        flex: "4 1",
        fontWeight: 500,
        borderCollapse: "collapse",
        // marginTop: "1em",
        marginBottom: "10%",
        [theme.breakpoints.down('xs')]: {
            marginTop: "1em",
        },
    },
    footerreleasedGlResponsive: {
        fontSize: "20px"
    },
    leftTdResponsive: {
        textAlign: 'left',
        marginTop: '4%',
        flex: '0 0 50%'
    },
    rightTdResponsive: {
        textAlign: 'right',
        flex: '0 0 50%',
        marginTop: '4%',
    },

    // mobile responsive added by yogitha on 19/05/2021
    // desktopResponsive: {
    //     display: 'grid !important',

    //     [theme.breakpoints.down("600")]: {
    //         // display: 'none !important',
    //     },
    // },
    mobileResponsive: {
        display: 'none !important',
        [theme.breakpoints.down("600")]: {
            display: 'block !important',
            // width: '100%',
            padding: "0px 10px"
        },
    },
    trResponsive: {
        height: "auto",
        boxShadow: "0 0 5px 0 rgb(19 89 161 / 6%)",
        padding: "10px 10px",
        borderRadius: "8px",
        // textAlign: "right",
        fontSize: "13px",
        fontWeight: 400,
        // commended by yogitha 19/05/2020 for mobile responsive
        // [theme.breakpoints.down('xs')]: {
        //   display:"none !important"
        // }
    },
    SchemeNametdResponsive: {
        width: "100%",
        // paddingLeft: "10px",
        textAlign: "left",
        marginTop: '-2%',
        // color: 'black',
        fontWeight: '600',
    },

    headertrs: {
        // height: "50px",
        borderWidth: 1,
        borderRadius: "8px !important",
        textAlign: "left",
        display: 'flex',
        flexDirection: 'row',
        padding: '10px',
    },
    footerToatals: {
        fontSize: "20px",
        whiteSpace: 'nowrap',
        marginTop: '10px',
        flex: '0 0 50%',
    },
    footerreleasedGlResponsive: {
        fontSize: "20px",
        flex: '0 0 50%',
        textAlign: "right",
    },
    totalResponsive: {
        fontWeight: "500",
        fontSize: "16px",
    },
    noDataDesign: {
        [theme.breakpoints.down('xs')]: {
            height: 50,
            width: "100%",
            paddingTop: 30,
            paddingBottom: 30
        }
    },
    firstGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "firstGrid"
        },
    },
    secondGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "secondGrid"
        }
    },
    thirdGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "thirdGrid"
        }
    },
    fourthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "fourthGrid"
        }
    },
    fifthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "fifthGrid"
        }
    },
    sixthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "sixthGrid"
        }
    },
    hideforDesktop: {
        [theme.breakpoints.up("920")]: {
            display: "none !important"
        }
    },
    hideDiv: {
        [theme.breakpoints.down("920")]: {
            display: "none !important"
        }
    },
    smallCap: {
        [theme.breakpoints.down("920")]: {
            textAlign: "justify",
            marginTop: "3%",
        }
    },
    otherAmount: {
        [theme.breakpoints.down("920")]: {
            textAlign: "justify"
        }
    },
    mobileResponsive: {
        display: "none",
        [theme.breakpoints.down("920")]: {
            display: "grid"
        }
    },
    labelStyle: {
        fontSize: 14,
        fontWeight: 500,
        color: colors.LABLE_GRAY
    },
    subTotalContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)"
    },
    desktopResponsive: {
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        [theme.breakpoints.down("920")]: {
            display: "none !important"
        }
    },
    card: {
        border: `1px solid ${window.globalConfig.color.BORDER}`,
        minHeight: '60px',
        // padding: "10px 0",
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        fontSize: '13px',
        textAlign: "right",
        borderRadius: "8px",
        fontWeight: 400,
        alignItems: "center",
        lineHeight: 1.5,
        boxShadow: "0 0 5px 0 rgb(19 89 161 / 6%)",
        cursor: "auto",
    },
    totalSecondContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)"
    }


});
class Equity_MarketCapAllocation extends Component {
    constructor() {
        super();
        this.state = {
            open: false, accpdf: false,
            accexcel: false,
            ProductID: '',
            pdfurl: '',
            xcelurl: '',
            marketcapallocationList: [],
            isDesktopView: true
        }
    }
    resize() {
        let currentHideNav = (window.innerWidth >= 920);
        if (currentHideNav !== this.state.isDesktopView) {
            // 
            this.setState({ isDesktopView: currentHideNav });
        }

    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this))
        window.removeEventListener("scroll", this.handleScroll)
    }
    componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();

        window.addEventListener('scroll', this.handleScroll)
        switch (this.props.flag) {
            case "mutualfunds":
                return this.setState({
                    ProductID: 1,
                    pdfurl: `${apiurl.MargetCapAllocationpdf}`,
                    xcelurl: `${apiurl.MargetCapAllocationexcel}`,
                });
            case "equity":
                return this.setState({
                    ProductID: 2,
                    pdfurl: `${apiurl.EquityMargetCapAllocationpdf}`,
                    xcelurl: `${apiurl.EquityMargetCapAllocationexcel}`,
                });
            case constants.Common.Bonds:
                return this.setState({
                    ProductID: 3,
                });
            case constants.Common.GB:
                return this.setState({
                    ProductID: 4,
                });
            default:
                this.setState({
                    ProductID: 5,
                });
        }
    }
    // handleScroll = () => {
    //   const offset = window.scrollY;
    //   if (offset > 250) {
    //     // this.setState({ addStickyClass: true });
    //     this.setState((prev, props) => ({
    //       marketcapallocationList: this.props.marketcapallocation.slice(0, prev.marketcapallocationList.length + 10)
    //     }));
    //   }
    //   else {
    //     // this.setState({ addStickyClass: false });
    //   }
    // }
    componentDidUpdate(prevProps) {
        // window.addEventListener("scroll", this.handleScroll);
    }
    downloadclick = async (ext) => {
        var pdfexcel = false;
        var filename = ""
        var url = ""
        filename = `${"Market Cap Allocation ."}${ext}`
        let data = {}
        // if () {
        if (this.state.accpdf == false && ext === "pdf") {
            this.setState({ accpdf: true })
            //MM/DD/YYYY
            pdfexcel = true;
            data = {
                "ClientID": this.props.logindetails.ClientID, "AccountID": 0, "ReportID": labels.Report.marketCapAllocation, "WithRedeem": false, "AsOnDate": moment(this.props.date).format("DD MMM YYYY"),
                "SubReport": 0, "DownloadAs": 1, "ProductID": 1, "FamilyID": 0, "L5ID": this.props.L5ID, "L4ID": 0
            }
            url = `${this.state.pdfurl}${this.props.logindetails.ClientID}&asOnDate=${this.props.date == null || this.props.date == undefined ? moment().format("DD MMM YYYY") : moment(this.props.date).format("DD MMM YYYY")}&accountId=${0}&ProductID=${this.state.ProductID}&FamilyID=${0}&L5ID=${this.props.L5ID}&L4ID=${0}`
        }
        // }
        // if () {
        if (this.state.accexcel == false && ext === "xlsx") {
            this.setState({ accexcel: true })
            pdfexcel = true;
            data = {
                "ClientID": this.props.logindetails.ClientID, "AccountID": 0, "ReportID": labels.Report.marketCapAllocation, "WithRedeem": false, "AsOnDate": moment(this.props.date).format("DD MMM YYYY"),
                "SubReport": 0, "DownloadAs": 2, "ProductID": 1, "FamilyID": 0, "L5ID": this.props.L5ID, "L4ID": 0
            }
            url = `${this.state.xcelurl}${this.props.logindetails.ClientID}&asOnDate=${this.props.date == null || this.props.date == undefined ? moment().format("DD MMM YYYY") : moment(this.props.date).format("DD MMM YYYY")}&accountId=${0}&ProductID=${this.state.ProductID}&FamilyID=${0}&L5ID=${this.props.L5ID}&L4ID=${0}`
        }
        // }
        // url = await Networking.PostApi(apiurl.ExportDownloadURL, data).then((res) => {
        //   let a = ""
        //   if (res.status == "S" && res.data != "") {
        //     a = res.data
        //   } else {
        //     if (ext === "pdf") {
        //       this.setState({ accpdf: false })
        //     }
        //     if (ext === "xlsx") {
        //       this.setState({ accexcel: false })
        //     }
        //   }
        //   return a
        // }).catch((er) => {
        //   if (ext === "pdf") {
        //     this.setState({ accpdf: false })
        //   }
        //   if (ext === "xlsx") {
        //     this.setState({ accexcel: false })
        //   }
        //   return ""
        // })
        // else {
        //  }
        if (pdfexcel == true && url.length != 0) {
            getAllcommonDownloadDetails(url, ext, filename, this.props.logindetails?.SessionID).then((response) => {
                if (response === "success") {
                    if (ext === "pdf") {
                        this.setState({ accpdf: false })
                    }
                    if (ext === "xlsx") {
                        this.setState({ accexcel: false })
                    }
                } else {
                    this.setState({ open: true })
                    if (ext === "pdf") {
                        this.setState({ accpdf: false })
                    }
                    if (ext === "xlsx") {
                        this.setState({ accexcel: false })
                    }
                }
            })
        } else {
            this.setState({ open: true })
        }

    }
    close = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };
    render() {
        const { classes } = this.props
        // console.log(this.props)
        let combainStyle = Object.assign({}, classes, this.props.externalClasses)
        try {
            return (<ErrorBoundary>
                <>
                    {this.props.isMarketCapLoading == true ?

                        <Loader page="skeletonreport" />

                        :
                        <>
                            <div style={{ marginBottom: '3%' }}>
                                <Headers
                                    showdownload={true}
                                    flag={this.props.flag}
                                    selected={true}
                                    header={this.props.header}
                                    handleclear={this.handleclear}
                                    pdfload={this.state.accpdf}
                                    newxcelLoad={this.state.accexcel}
                                    List={this.props.marketcapallocation}
                                    data={this.props.marketcapallocation}
                                    scheme={this.props.marketcapallocation != null ? this.props.marketcapallocation.length == 0 ? <NoData /> : this.props.marketcapallocation.length == 1 ? `${this.props.marketcapallocation.length} ${this.props.flag == "equity" ? 'Scrip' : 'Scheme'}` : `${this.props.marketcapallocation.length} ${this.props.flag == "equity" ? 'Scrips' : 'Schemes'}` : <NoData />}
                                    page={"marketcaps"}
                                    onclick={this.downloadclick} />

                                {this.props.marketcapallocation != null && this.props.marketcapallocation.length != 0 ?
                                    <>
                                        <div style={{ marginTop: "1%" }}>
                                            <div className={[combainStyle.tableHeader, combainStyle.desktopResponsive].join(' ')} style={{ paddingLeft: 10, paddingRight: 10, marginLeft: 10, marginRight: 10 }}>
                                                {this.props.titleAmountAge.map((item, index) => {
                                                    return (
                                                        <div key={index} className={[index == 0 || this.props.titleAmountAge.length - 1 == index ? "" : combainStyle.hideDiv]} style={{ textAlign: item.name == "Scheme Name" || item.name == "Scrip Name" ? "left" : "right" }}>
                                                            <div>{item.name}</div>

                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            {/* <div className={[this.props.grid, combainStyle.mainTitle, combainStyle.desktopResponsive].join(' ')} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                <div style={{ textAlign: "left" }} className={combainStyle.desktopResponsive}>

                                                </div>
                                                {this.props.title.map((item, index) => {
                                                    return (
                                                        <div key={index} className={[combainStyle.hideDiv]} style={{ textAlign: "right" }}>
                                                            {item.name}
                                                        </div>
                                                    )
                                                })}
                                                <div style={{ textAlign: "right" }}>

                                                </div>
                                            </div>
                                            <div className={[combainStyle.tableHeader, this.props.grid, combainStyle.desktopResponsive].join(' ')} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                {this.props.titleAmountAge.map((item, index) => {
                                                    return (
                                                        <div key={index} className={[index == 0 || this.props.titleAmountAge.length - 1 == index ? "" : combainStyle.hideDiv]} style={{ textAlign: item.name == "Scheme Name" || item.name == "Scrip Name" ? "left" : "right" }}>
                                                            <div>{item.name}</div>
                                                            <div>{item.age}</div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                            <div className={[combainStyle.tableHeader, combainStyle.mobileResponsive].join(' ')} style={{ textAlign: "left", paddingLeft: 10 }}>
                                                {this.props.titleAmountAge[0].name}
                                            </div> */}
                                            <div >
                                                <Pagination data={this.props.marketcapallocation}>
                                                    <MContext.Consumer>
                                                        {(context) => {
                                                            return <>
                                                                {context.filter(
                                                                    (ele, ind) =>
                                                                        ind ===
                                                                        context.findIndex((elem) => elem.CapType === ele.CapType)
                                                                ).map((item, mainindex) => {
                                                                    return (
                                                                        <div key={mainindex}>
                                                                            <div style={{ paddingLeft: 10, paddingRight: 10 }} >
                                                                                {item.CapType != undefined && <div className={[combainStyle.subCatgoryValue, "firstGrid", combainStyle.firstGrid, combainStyle.subTotalContainer, combainStyle.categoryGroupContainer].join(' ')} style={{ textAlign: "left", padding: 5, borderRadius: 5, justifyContent: "left", fontSize: 15, textAlign: "center", paddingLeft: 8 }}>
                                                                                    <div style={{ textAlign: "left" }}> {item.CapType}</div>
                                                                                    {item?.SubTotal != undefined && <div style={{ textAlign: "right" }} >
                                                                                        <span className={[combainStyle.subcategoryRupee, combainStyle.hideForDesktop]}>{Label.currency.rupeesymbol}</span>
                                                                                        {NegativeFinding(item?.SubTotal, "", "", "", "number")}
                                                                                    </div>}
                                                                                    {item?.SubTotalPercentage != undefined && <div style={{ textAlign: "right" }} >
                                                                                        {/* <span className={[combainStyle.subcategoryRupee, combainStyle.hideForDesktop]}>{Label.currency.rupeesymbol}</span> */}
                                                                                        {NegativeFinding(item?.SubTotalPercentage, "", "", "", "percentage")}
                                                                                    </div>}

                                                                                </div>}

                                                                                {context.filter((val) => val.CapType == item.CapType).map((value, index) => {
                                                                                    return <div>
                                                                                        <div className={classes.card}>
                                                                                            <div style={{ textAlign: "left", paddingLeft: 10 }}> {value.ScripName}</div>
                                                                                            {value.Amount != undefined && <div className={[combainStyle.directionColumn, combainStyle.subCatgoryValue, "secondGrid", combainStyle.secondGrid, combainStyle.smallCap].join(' ')}>
                                                                                                <div>
                                                                                                    <span className={combainStyle.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                                                                                        {labels.summaryPage.small}
                                                                                                    </span>
                                                                                                    <span className={[combainStyle.subcategoryRupee, combainStyle.hideForDesktop]}>{Label.currency.rupeesymbol}</span>

                                                                                                    {NegativeFinding(value.Amount, "", "", "", "number")}
                                                                                                </div>

                                                                                            </div>}
                                                                                            {value.Per != undefined && <div style={{ marginRight: 10 }}>{NegativeFinding(value.Per, "", "", "", "percentage")}
                                                                                                {/* <Badges textcolor={NegativeFinding(item.Per, "", "", window.globalConfig.color.ZERO_BACKGROUND_TEXT)} color={NegativeFinding(item.Per, window.globalConfig.color.LIGHT_GREEN_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND, window.globalConfig.color.ZERO_BACKGROUND)} text={`${NegativeFinding(item.Per, "", "", "", "percentage")}`} /> */}
                                                                                            </div>}
                                                                                        </div>

                                                                                    </div>
                                                                                })}
                                                                            </div>
                                                                            {/* {this.props.subtotalmarketcapallocation.filter((val) => val.CapType == item.CapType).map((totalitem, index) => {
                                                                                return (
                                                                                    <div key={index} className={[combainStyle.displayTotalContainer, combainStyle.totalSecondContainer].join(' ')} style={{ marginLeft: 10, marginRight: 10 }}>
                                                                                        {<div style={{ textAlign: "left", fontSize: "larger" }} className={[combainStyle.firstGrid, "firstGrid"].join(" ")}>
                                                                                            Total
                                                                                        </div>}
                                                                                        {totalitem.Amount != undefined && <div className={[combainStyle.directionColumn, combainStyle.subCatgoryValue, "secondGrid", combainStyle.secondGrid, combainStyle.smallCap].join(' ')}>


                                                                                            <span className={[combainStyle.subcategoryRupee, combainStyle.hideForDesktop]}>{Label.currency.rupeesymbol}</span>

                                                                                            {NegativeFinding(totalitem.Amount, "", "", "", "number")}


                                                                                        </div>}


                                                                                    </div>
                                                                                )
                                                                            })} */}
                                                                        </div>
                                                                    )
                                                                })}
                                                                {this.props.totalmarketcapallocation.map((totalitem, index) => {
                                                                    return (
                                                                        <div key={index} className={[combainStyle.displayTotalContainer, combainStyle.totalSecondContainer].join(' ')} style={{ marginLeft: 10, marginRight: 10 }}>
                                                                            {<div style={{ textAlign: "left", fontSize: "larger", marginLeft: 10 }} className={[combainStyle.firstGrid, "firstGrid"].join(" ")}>
                                                                                Grand Total
                                                                            </div>}
                                                                            {totalitem.GrandTotal != undefined && <div style={{ fontSize: "large" }} className={[combainStyle.directionColumn, combainStyle.subCatgoryValue, "secondGrid", combainStyle.secondGrid, combainStyle.smallCap].join(' ')}>


                                                                                <span className={[combainStyle.subcategoryRupee, combainStyle.hideForDesktop]}>{Label.currency.rupeesymbol}</span>

                                                                                {NegativeFinding(totalitem.GrandTotal, "", "", "", "number")}


                                                                            </div>}

                                                                            {totalitem.GrandPerTotal != undefined && <div className={[combainStyle.directionColumn, combainStyle.subCatgoryValue, "secondGrid", combainStyle.secondGrid, combainStyle.smallCap].join(' ')} style={{ marginRight: 10, fontSize: "large" }}>


                                                                                {/* <span className={[combainStyle.subcategoryRupee, combainStyle.hideForDesktop]}>{Label.currency.rupeesymbol}</span> */}

                                                                                {NegativeFinding(totalitem.GrandPerTotal, "", "", "", "percentage")}


                                                                            </div>}
                                                                        </div>
                                                                    )
                                                                })}

                                                            </>
                                                        }
                                                        }

                                                    </MContext.Consumer >
                                                </Pagination>

                                            </div></div>
                                    </>
                                    : <div ></div>
                                }
                            </div>


                        </>
                    }
                </>
                <CommonSnackbar open={this.state.open} handlesnanckClose={this.close} message={labels.messages.apierror} severity={"warning"} />

            </ErrorBoundary>
            )
        }
        catch (err) {
            console.log(err)
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => {
    return {
        // sessionID: state.Login.sessionID,
        // clientID: state.Login.logindetails.ClientID,
        // userID: state.Login.userID,
        // date: state.Login.date,
        // logindetails: state.Login.logindetails,
        // marketcapallocation: state.MarketCap.marketcapallocation == null ? [] : state.MarketCap.marketcapallocation,
        subtotalmarketcapallocation: state.MarketCap.equity_subTotalMarketCapAllocation,
        // isMarketCapLoading: state.MarketCap.isMarketCapLoading,
        // L5ID: state.Login.L5ID,

    }
}
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles(mergedStyle)(connect(mapStateToProps, null)(Equity_MarketCapAllocation))
