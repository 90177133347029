import moment from 'moment';
import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
var axios = require('axios');
/* name should be camel case in all function */
// function getTransactionOrderReportDetails(ClientID, SessionID, AsOnDate)
import Networking from '../../utils/api/apiaccess';
function getCheckTransactionOrderStatus(ClientCode, OrderNo, Segment) {
    // let userDetails = secureStorage.getItem('user')

    let data = {};
    data.ClientCode = ClientCode
    data.OrderNo = OrderNo
    data.Segment = Segment
    return Networking.PostApi(ApiValues.CheckForInitPayment , data,labels.apiKey.apiKeys).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
    
}
export default getCheckTransactionOrderStatus;