// common card created by yogitha on 23/07/21
import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import labels from '../../utils/constants/labels';
import Button from '../../components/custombutton/button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import Textinput from '../../components/textinput/textinput';
import { Redirect } from "react-router-dom";
import ApiValues from '../../api/url/apiurl';
import { withRouter } from "react-router";
import Equity from '../../utils/assets/images/equity.png';
import CamsVdo from '../../utils/assets/camvdo.mp4';
import ImageButton from '../../components/image_button/image_button';
import Tooltip from '@material-ui/core/Tooltip';
import getTrackEquityDetails from '../../api/track/equity_trackapi';
import CommonSnackbar from '../snackbar/snackbar';
import { bindActionCreators } from 'redux';
import getTrackEquityImportedDetails from '../../api/track/equity_trackimported_api'
// import getTrackEquityImported from '../../redux/actions/track/equitytrack_import_action';
import { maskNumber, NegativeFinding, openRequestedSinglePopup, replaceNull } from "../../utils/commonfunction";
import DialogBox from "../dialog/dialog";
import OutlinedButtons from "../payment/button";
const styles = theme => ({
    root: {
        backgroundColor: window.globalConfig.color.WHITE,
        width: "100%",
        height: '100%',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
    },
    fontWeightHeader: {
        fontWeight: "normal"
    },
    card: {
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        borderRadius: '5px',
        // width: '13%',
        justifyContent: 'flex-start',
        margin: '2% 42%',
        padding: '2% 2% 2% 2%',
        gap: '20px',
        display: 'grid',
        gridTemplateColumns: '23px 147px',
        cursor: 'pointer',
        [theme.breakpoints.down("md")]: {
            // width: '18%',
            margin: '4% 40%'
        },
        [theme.breakpoints.down("sm")]: {
            // width: '23%',
            margin: '4% 37%'
        },
        [theme.breakpoints.down("xs")]: {
            // width: '42%',
            margin: '6% 26%',
            padding: '3% 3% 3% 3%',
        },
    },

    TopAlignment: {
        marginTop: '3%',
        fontSize: '1.17em',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        fontWeight: 'bold',
    },
    footerLine: {
        display: 'grid',
        gridTemplateColumns: "repeat(2,50%)",
        margin: '3% 20% 3% 22%',
        gap: '5%',
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            margin: '3% 15%',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down("xs")]: {
            margin: '3% 0%',
            gridTemplateColumns: "repeat(1,100%)",
        },
    },
    footerLinEquity: {
        margin: '3% 20% 3% 20%',
        display: 'grid',
        /* grid-template-columns: repeat(2,50%); */
        justifyContent: 'center',
        [theme.breakpoints.down("sm")]: {
            margin: '3% 15%',
            whiteSpace: 'nowrap',
        },
        [theme.breakpoints.down("xs")]: {
            margin: '3% 0%',
        },
    },
    btnButtonText: {
        color: window.globalConfig.color.BROWN,
        fontWeight: "normal",
        textAlign: 'right',
        cursor: "pointer",
        margin: 0,
        fontSize: 16,
        textDecoration: "underline",
        // textIndent:'23em',
        [theme.breakpoints.down("xs")]: {
            textAlign: 'center',
        },
    },
    btntrack: {
        width: "150px",
        // marginTop: '10px',
        [theme.breakpoints.down("xs")]: {
            // margin: '1% 33% 0% 30%',
            margin: '0% 0% 0% 2%',

        },
    },
    popupBodyContainer: {
        // padding: '5% 0% 0% 0%',
        margin: "0% 0% 0% 5%",
        [theme.breakpoints.down("xs")]: {
            // padding: '3% 0%',
        },
    },
    headingAlignment: {
        margin: "0% 14% 0% 0%",
        padding: '5% 0% 0% 0%',
        [theme.breakpoints.down("xs")]: {
            padding: '3% 0% 0% 0%',
        },
    },
    sucessPopupBodyContainer: {
        padding: '10% 0%',
        overflow: 'hidden',
        [theme.breakpoints.down("xs")]: {
            width: '270px',
        },
    },
    footerPopup: {
        display: 'flex',
        justifyContent: "center",
        margin: '5% 0%',
        padding: '0px 16px 0px 16px',
        // [theme.breakpoints.down("xs")]: {
        //     padding: '0% 3% 2% 2%',
        // }
    },
    footerSuccessPopup: {
        display: 'flex',
        justifyContent: "center",
        margin: '0% 7%'
    },
    btnUploadPopup: {
        margin: '6% 21% 6% 0%',
        [theme.breakpoints.down("xs")]: {
            margin: '6% 3% 6% 0%',
        }
    },
    imagecenter: {
        marginLeft: '40%',
        marginRight: '40%',
        [theme.breakpoints.down("xs")]: {
            marginLeft: '36%',
            marginRight: '36%',
        },
    },
    popupContentcenter: {
        margin: '2% 22%',
        [theme.breakpoints.down("xs")]: {
            margin: '2% 19%',
        },
    },
    popupSuccessContentcenter: {
        margin: '5% 3%',
        width: '300px',
        [theme.breakpoints.down("xs")]: {
            margin: '2% 3%',
        },
    },
    dialogPopup: {
        "& .MuiDialog-paperWidthXs": {
            maxWidth: "320px !important",
            width: "320px",
        },
    },
    popupWidth: {
        width: '320px',
    },

    flexRow: {
        // display: 'flex',
        // flexDirection: 'row',
        display: 'grid',
        gridTemplateColumns: 'repeat(2,43%)',
        margin: '2% 0%',
        // columnGap: '5%',
        [theme.breakpoints.down("xs")]: {
            display: 'grid',
            columnGap: '5%',
            gridTemplateColumns: 'repeat(2,43%)',
        }
    },
    fileNameDiv: {
        display: 'flex',
        flexWrap: 'wrap',
        // width: '45%',
        // wordBreak: 'break-all',
    },
    fileDiv: {
        width: '100px',
        padding: '8% 0% 1% 0%',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down("350")]: {
            whiteSpace: 'revert',
            padding: 0,
            margin: '10px 0px 0px -6px',
        },
    },
    errorBoxWidth: {
        width: '286px',
        [theme.breakpoints.down("600")]: {
            width: '281px',
        },
        [theme.breakpoints.down("350")]: {
            width: '231px',
        }
    },

    popupheader: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: window.globalConfig.color.WHITE,
        height: 50,
        fontWeight: 500,
        position: "sticky",
        top: "0px"
    },
    txtHeader: {
        padding: 15,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        [theme.breakpoints.down("600")]: {
            padding: '15px 20px 15px 15px',
            justifyContent: "revert",
        },
        [theme.breakpoints.down("600")]: {
            padding: '15px 20px 15px 15px',
            justifyContent: 'revert',
        },
    },
    closeIcon: {
        marginTop: -24,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 35,
        width: 35,
        borderRadius: 17,
        cursor: "pointer",
        padding: 0,
        backgroundColor: window.globalConfig.color.BORDER
    },
    clearIcon: {
        cursor: "pointer",
        padding: 0,
        [theme.breakpoints.down("600")]: {
            padding: '0% 0% 0% 30%',
        },
        [theme.breakpoints.down("350")]: {
            padding: '0% 0% 0% 13% !important',

        },
    },
    h2design: {
        fontSize: '1.5em',
        marginlockStart: '0.83em',
        marginBlockEnd: '0.83em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        fontWeight: 'bold',
    },
    instructionLine: {
        marginBottom: 5,
        marginTop: 11,
        color: window.globalConfig.color.LABLE_GRAY
    }
}
)
class EquityTrading extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openPopup: false,
            successPopup: false,
            guideVideo: false,
            selectedFiles: [],
            textValue: '',
            errorMessageInput: '',
            message: "",
            severity: "",
            open: false,
            loader: false,
            disable:false,
            successData: [],
            mfEquity: [],
            flag: '',
            CSDL: ApiValues.CSDLEquity,
            NSDL: ApiValues.NSDLEquity,
            CAMS: ApiValues.TrackingEquity

        }
    }

    componentDidMount() {
        if (this.props.location.state.data == undefined && this.props.location.state.data == null && this.props.location.state.data == "") {
            <Redirect to={labels.Path.NotFound} />
        }
    }
    handleEquityRedirection = () => {
        if (this.props.logindetails.ClientID != 0) {
            this.props.history.push({
                pathname: labels.Path.Stocks,
                state: { data: "stocks" }
            })
        }
    }
    navigationSuccessclick = () => {
        this.props.history.push(
            labels.Path.Tracking,
            { importedstatus: true },
        )

    }
    navigationDashboard() {
        this.props.history.push(labels.Path.Tracking);
    }
    handleClickOpen = (value) => {
        this.setState({
            openPopup: true,
            flag: value
        })
    };
    openSuccessPopup = (value) => {
        this.setState({ loader: true })
        var pan = this.props.profileDetails.mainDetails.length != 0 && this.props.profileDetails.mainDetails[0].PAN != "" && this.props.profileDetails.mainDetails[0].PAN != "NA" && this.props.profileDetails.mainDetails[0].PAN != undefined ? this.props.profileDetails.mainDetails[0]?.PAN : this.props.logindetails?.PAN != "" && this.props.logindetails?.PAN != "NA" ? this.props.logindetails.PAN : ""
        //  this.props.logindetails.EntityTypeID == 9 ? this.props.logindetails.PAN : this.props.profileDetails.mainDetails != "NA" && this.props.profileDetails.mainDetails.length != 0 ? this.props.profileDetails.mainDetails[0]["PAN"] : ""
        var password = this.state.textValue
        var clientid = this.props.logindetails.ClientID
        getTrackEquityDetails(`${value == "CAMS" ? this.state.CAMS : value == "NSDL" ? this.state.NSDL : this.state.CSDL}`, this.state.selectedFiles, pan, password, this.props.companyDetails[0].ID).then((response) => {
            this.setState({ loader: false })
            if (response.status == "S") {
                this.props.history.push({
                    pathname: labels.Path.UploadProcessing + value.toLowerCase(),
                    state: { clientDetails: replaceNull(response.data, labels.messages.NA) }
                })
                // openRequestedSinglePopup(labels.Path.UploadProcessing + value.toLowerCase())
                // this.props.getL5reportDetails(clientid)
                // {
                //     value == "CAMS" ?
                //         getTrackEquityImportedDetails(clientid, 1, 0, 0, 0)
                //             .then((response) => {
                //                 if (response.status === "S") {
                //                     if (response.data.length == 0) {
                //                         this.setState({ successData: [] })
                //                     } else {
                //                         this.setState({ successData: response.data })
                //                     } 


                //                     this.setState({
                //                         errorMessageInput: response.message,
                //                         message: response.message,

                //                     })

                //                 }
                //                 else {
                //                     this.setState({
                //                         errorMessageInput: response.message,
                //                         message: response.message,
                //                         open: true,
                //                         textValue: '',
                //                         selectedFiles: [],
                //                     })
                //                 }
                //             })
                //             .catch((error) => {
                //                 this.setState({
                //                     errorMessageInput: '',
                //                     message: labels.messages.apierror,

                //                 })
                //             })
                //         :
                //         getTrackEquityImportedDetails(clientid, 2, 0, 0, 0)
                //             .then((response) => {
                //                 if (response.status === "S") {

                //                     this.setState({ successData: response.data.filter((item)=>item.ProductID==2) })

                //                     this.setState({
                //                         errorMessageInput: response.message,
                //                         message: response.message,

                //                     })

                //                 }
                //                 else {
                //                     this.setState({
                //                         errorMessageInput: response.message,
                //                         message: response.message,
                //                         open: true,
                //                         textValue: '',
                //                         selectedFiles: [],
                //                     })
                //                 }
                //             })
                //             .catch((error) => {
                //                 this.setState({
                //                     errorMessageInput: '',
                //                     message: labels.messages.apierror,

                //                 })
                //             })
                // }
                this.setState({
                    successPopup: true,
                    openPopup: false,
                    loader: false
                })
            } else {
                // this.props.history.push(labels.Path.UploadProcessing + value.toLowerCase())

                ///   openRequestedSinglePopup(labels.Path.UploadProcessing + value.toLowerCase())
                this.setState({
                    errorMessageInput: response.message,
                    message: response.message,
                    open: true,
                    textValue: password,
                    selectedFiles: this.state.selectedFiles,
                })
            }
        })
            .catch((err) => {
                this.setState({
                    loader: false,
                })
                this.setState({
                    errorMessageInput: '',
                    message: labels.messages.apierror, open: true,
                    textValue: '',
                    selectedFiles: [],
                })

            })


    }

    closeSuccessPopup = () => {
        this.setState({
            successPopup: true,
        })

    }
    handleClose = () => {
        this.setState({
            openPopup: false,
            selectedFiles: [],
            textValue: '',
            errormessage: "",
            fileerrormessage: '',
            errorMessageInput: '',
            // severity: "",
            // open: false,
        })

    };

    // trigger the button click using window 
    openFileUpload = () => {
        if (this.state.textValue.length == 0 && this.state.selectedFiles.length == 0) {
            this.setState({
                // SelectedFile: [], 
                fileerrormessage: '',
                errorMessageInput: '',
            });
            this.refs.fileUploader.value = "",
                this.refs.fileUploader.click();
        }
        else {
            // event.preventDefault();
            this.refs.fileUploader.value = "",
                this.refs.fileUploader.click();
        }
    };
    handleStatementClick = () => {
        this.setState({ guideVideo: true })
    }
    handleGuideClose = () => {
        this.setState({ guideVideo: false })
    }
    //onchange method in file upload
    handleSelectedFile = (event) => {
        //***Convert file input result to an array, then store it in state.
        var extension = event.target.files[0].name.split('.').pop()
        if (extension == "pdf" || extension == "PDF") {
            this.setState({ selectedFiles: Array.from(event.target.files[0]) })
            this.setState({ selectedFiles: Array.from(event.target.files), fileerrormessage: "", textValue: "", errorMessageInput: "" })
        }
        else {
            this.setState({
                fileerrormessage: `Kindly upload  valid ${this.state.flag} pdf file`,
                selectedFiles: [], textValue: "", errorMessageInput: ""
            })
        }
        //this.setState({ selectedFiles: Array.from(event.target.files) })
    };
    //textbox value onchange
    handleChange(event) {
        this.setState({
            textValue: event.target.value,
            errorMessageInput: '',
        });
    }

    handlesnanckClose = () => {
        this.setState({ open: false })
    }
    playVideo = () => {
        // You can use the play method as normal on your video ref
        this.refs.vidRef.play();
    };
    render() {
        const { classes } = this.props;
        let pan = this.props.profileDetails?.mainDetails != undefined && this.props.profileDetails?.mainDetails?.length != 0 && this.props.profileDetails.mainDetails[0].PAN != "" && this.props.profileDetails.mainDetails[0].PAN != "NA" && this.props.profileDetails.mainDetails[0].PAN != undefined ? maskNumber(this.props.profileDetails.mainDetails[0]?.PAN) : this.props.logindetails?.PAN != "" && this.props.logindetails?.PAN != "NA" ? maskNumber(this.props.logindetails.PAN) : ""
        try {
            return (
                <div className={classes.root}>
                    <div xs={12}>
                        <div className={classes.h2design}>
                            {labels.EquityTackingLabels.equityHeader}
                            {this.props.location.state.data}
                            {labels.EquityTackingLabels.mfheaders}
                        </div>
                        {/* <h4 className={classes.fontWeightHeader}>{labels.EquityTackingLabels.directShare}</h4> */}
                        <div className={classes.TopAlignment}>
                            {labels.EquityTackingLabels.equityPortfolio}
                            {this.props.location.state.data}
                            {labels.EquityTackingLabels.portfolio}
                            {/* <h4 className={classes.fontWeightHeader}>{labels.EquityTackingLabels.SelectOne}</h4> */}
                        </div>
                        {this.props.location.state.data != "Equity" ?
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: "3%" }}>
                                < Button
                                    page="uploadButton"
                                    Icon={<CloudUploadIcon style={{ verticalAlign: 'middle', color: window.globalConfig.color.PRIMARY }} />}
                                    label={labels.EquityTackingLabels.MutualfunduplaodButton}
                                    onClick={() => this.handleClickOpen("CAMS")}
                                />
                            </div>
                            :
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", marginTop: "3%" }}>

                                < Button
                                    page="uploadButton"
                                    Icon={<CloudUploadIcon style={{ verticalAlign: 'middle', color: window.globalConfig.color.PRIMARY }} />}
                                    label={labels.EquityTackingLabels.EquityuplaodButton}
                                    onClick={() => this.handleClickOpen("NSDL")}
                                />
                                < Button
                                    page="uploadButton"
                                    Icon={<CloudUploadIcon style={{ verticalAlign: 'middle', color: window.globalConfig.color.PRIMARY }} />}
                                    label={labels.EquityTackingLabels.CDSLuplaodButton}
                                    onClick={() => this.handleClickOpen("CDSL")}
                                />
                                < Button
                                    page="uploadButton"
                                    disabled={this.props.logindetails.ClientID == 0 ? true : false}
                                    Icon={<AddCircleIcon style={{ verticalAlign: 'middle', color: window.globalConfig.color.PRIMARY }} />}
                                    label={labels.EquityTackingLabels.Addmanually}
                                    onClick={() => this.handleEquityRedirection()}
                                />
                            </div>
                        }
                        <div className={classes.dialogPopup}>
                            <Dialog
                                disablePortal={true}
                                maxWidth="xs"
                                open={this.state.openPopup}
                                // onClose={this.handleClose}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description">
                                <div className={classes.popupWidth}>
                                    <div className={classes.popupheader}>
                                        <div className={classes.txtHeader}>{`${labels.EquityTackingLabels.upload} ${this.state.flag} ${labels.EquityTackingLabels.Uploadstatement}`}
                                            <span className={classes.clearIcon} style={{ cursor: this.state.loader ? "not-allowed" : "pointer" }} onClick={this.handleClose}>&#x2715;</span>
                                        </div>
                                    </div>
                                    <div className={classes.headingAlignment}>{`${labels.EquityTackingLabels.popupSubTitle} ${this.state.flag}`}</div>
                                    <div className={classes.popupBodyContainer}>
                                        <CommonSnackbar severity={"warning"} handlesnanckClose={this.handlesnanckClose} open={this.state.open} message={this.state.message} />
                                        <div className={classes.flexRow}>
                                            <div className={classes.btnUploadPopup}>
                                                <ImageButton
                                                    page={this.state.textValue.length != 0 && this.state.selectedFiles.length != 0 ? "secondary" : "primary"}
                                                    name={labels.EquityTackingLabels.upload}
                                                    disabled={this.state.loader ? true : false}
                                                    handleClick={() => { this.openFileUpload() }}
                                                    //  onChange={(event) => this.onChange(event)}
                                                    error={this.state.fileerrormessage} >
                                                    <input type="file" hidden ref="fileUploader" onChange={this.handleSelectedFile} />
                                                    <CloudUploadIcon style={{ verticalAlign: 'middle', color: window.globalConfig.color.WHITE, marginLeft: '3px' }} />
                                                </ImageButton>
                                            </div>
                                            {pan != "" && <div style={{ display: "flex", flexDirection: "column", justifyContent: "flex-start", alignItems: "baseline", gap: 5, marginTop: 10 }}>
                                                <div style={{ fontSize: 12, color: window.globalConfig.color.LABLE_GRAY, fontWeight: 400 }}>{labels.EquityTackingLabels.pan}</div>
                                                <div>{pan}</div>
                                            </div>}
                                            <div className={classes.fileNameDiv}>
                                                {this.state.selectedFiles.map((file) => (
                                                    <Tooltip title={file.name} placement="bottom" arrow >
                                                        <p className={classes.fileDiv}>
                                                            {file.name.length > 25 ? file.name.slice(0, 20) + "...pdf" : file.name}
                                                        </p>
                                                    </Tooltip>
                                                ))}
                                            </div>
                                        </div>

                                        <div className={classes.errorBoxWidth}>
                                            <Textinput page="normal" maxLength={15} disabled={this.state.selectedFiles.length == 0 ? true : false || this.state.loader ? true : false} label={labels.EquityTackingLabels.equityPassword} value={this.state.textValue} placeholder={labels.EquityTackingLabels.password} encrypted={"password"} handleChange={() => { this.handleChange(event) }} error={this.state.errorMessageInput} />
                                        </div>
                                    </div>
                                </div>

                                <div className={classes.footerPopup}>
                                    < Button
                                        name={labels.messages.submit} page={"primary"} loader={this.state.loader} disabled={this.state.textValue.length == 0 ? true : false} loderAlign="align" onClick={() => { this.openSuccessPopup(this.state.flag) }} />
                                </div>
                                <div>

                                </div>
                            </Dialog>
                            <Dialog
                                disablePortal={true}
                                maxWidth="xs"
                                open={this.state.successPopup}
                                onClose={this.closeSuccessPopup}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description">
                                <div className={classes.sucessPopupBodyContainer}>
                                    <img src={Equity} height={50} width={50} className={classes.imagecenter} />
                                    <h3 className={classes.popupContentcenter}>{labels.EquityTackingLabels.successPopupHeading}</h3>

                                    <h5 className={[classes.fontWeightHeader, classes.popupSuccessContentcenter].join(' ')}>{labels.EquityTackingLabels.successPopupmsg}{this.state.successData.length != 0 ? NegativeFinding(this.state.successData[0].MF_CurrentValue_Imported, "", "", "", "rupee") : `${labels.currency.rupeesymbol}0`}</h5>
                                    <div className={classes.footerSuccessPopup}>
                                        <Button name={labels.EquityTackingLabels.continue} page={"primary"} disabled={this.props.loader} loader={this.props.loader} onClick={() => { this.navigationSuccessclick() }} />
                                    </div>
                                </div>
                            </Dialog>
                        </div>
                        <div xs={12} className={this.props.location.state.data != "Equity" ? classes.footerLine : classes.footerLinEquity}>
                            {this.props.location.state.data != "Equity" &&
                                <h3 className={classes.btnButtonText} onClick={() => this.handleStatementClick()}>{labels.EquityTackingLabels.statement}</h3>
                            }

                            <div className={classes.btntrack
                            }>
                                <Button name={labels.EquityTackingLabels.trackDashboard} page={"primary"} disabled={this.props.loader} loader={this.props.loader} onClick={() => { this.navigationDashboard() }} />
                            </div>
                        </div>
                    </div>
                    <DialogBox open={this.state.guideVideo} handleClose={this.handleGuideClose}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", gap: 30 }}>
                                <h3 style={{ marginTop: 0, color: window.globalConfig.color.BLACK_PRIMARY, marginLeft: "19%" }}>HERE IS THE VIDEO GUIDE</h3>
                                <span className={classes.closeIcon} onClick={this.handleGuideClose}>&#x2715;</span>
                            </div>

                            <video style={{ marginBottom: 20 }} key="https://www.youtube.com/watch?v=dwObaOLojCQ" width="400" height="200" controls="controls" autoplay={false} >
                                <source src={CamsVdo}></source>
                            </video>
                            <div style={{ marginLeft: "19%" }}>
                                <OutlinedButtons text="GENERATE NOW" onClick={() => window.open("https://www.camsonline.com/Investors/Statements/Consolidated-Account-Statement")}></OutlinedButtons>
                            </div><div>
                                <h4 className={classes.instructionLine}>1-CLICK GENERATE NOW.</h4>
                                <h4 className={classes.instructionLine}>2-FOLLOW THE GUIDE shown in the video </h4>
                                <h4 className={classes.instructionLine}>3-Download the statement received on mail.</h4>
                                <h4 className={classes.instructionLine}>4-Upload the statement and enter password</h4>
                            </div>
                        </div>
                    </DialogBox>
                </div>
            )
        } catch (err) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = (state) => {
    return {
        // trackEquity: state.trackEquity,
        profileDetails: state.ProfileDetails.AllProfileDetails,
        logindetails: state.Login.logindetails,
        companyDetails: state.Login.companyDetails,
        date: state.Login.date,

    }

}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch)
};
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(EquityTrading)));