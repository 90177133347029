import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
import Networking from '../../utils/api/apiaccess';
var axios = require('axios');

/* name should be camel case in all function */
function getAllXraywithredemptionDetails(ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
        "AccountID": AccountID,
        "AsOnDate": AsonDate,
        "ClientID": ClientID,
        "ProductID": ProductID,
        "FamilyID": FamilyID,
        "L5ID": L5ID,
        "L4ID": L4ID
    });
    return Networking.PostApi(ApiValues.XrayWithRedemption, raw).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });


}
export default getAllXraywithredemptionDetails
