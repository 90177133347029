import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
//action created by dulcy
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
function deleteCountry(ID, UserID) {


    var raw = JSON.stringify({
        "TaxPayingID": ID,
        "UserID": UserID,

    });
    // var config = {
    //     method: 'post',
    //     url: ApiValues.DeleteCountry,
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    //     data: raw
    // };

    // return axios(config)
    //     .then(function (response) {
    //         return response.data
    //     })
    //     .catch(function (error) {
    //         return { "status": "F","message":labels.messages.apierror }
    //     });
    return Networking.PostApi(ApiValues.DeleteCountry, raw).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default deleteCountry