import * as types from '../../constants/actionsType'
const initialState = {
  purchaseOrder: [],
  isLoading:false
}
const reducer = (state = initialState, action) => {

  switch (action.type) {
    case types.PURCHASE_ORDER:
      return {
        ...state,
        purchaseOrder: [action.payload,...state.purchaseOrder]
      }
      case types.LOADER_PURCHASE_ORDER:
        return {
          ...state,
          isLoading: action.payload
        }
    case types.PURCHASE_ORDER_EMPTY:
      return {
        ...state,
        purchaseOrder: [],
        isLoading:false
      }
    default:
      return state
  }
}
export default reducer
