import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import labels from '../../utils/constants/labels';
import moment from 'moment';
import colors from '../../utils/colors/colors';
import NoData from '../nodata/nodata';
import axios from "axios";
import fileDownload from "js-file-download";
import ErrorBoundary from '../Errorhandling/Errorhandle';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RefreshIcon from '@material-ui/icons/Refresh';
import CommonSnackbar from '../snackbar/snackbar';
import apiurl from '../../api/url/apiurl';
import { NegativeFinding } from '../../utils/commonfunction';
import { v4 as uuid } from 'uuid';
const styles = theme => ({
    threeColumnCard: {
        minHeight: "50%", fontFamily: "Inter",
        display: "grid", gridTemplateColumns: "40% 40% 20%",
        height: "max-content"
    },
    firstColumn: {
        display: "flex", flexDirection: "column", borderLeft: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, borderTop: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, borderRight: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, borderRadius: 5
    },
    planDiv: {
        display: "flex", flexDirection: "column", textAlign: "left", padding: 15, justifyContent: "space-between", borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, gap: 10
    },
    planNumber: {
        color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, letterSpacing: 0.5, fontWeight: "bold"
    },
    planName: {
        fontSize: 18, fontWeight: "bolder"
    },
    labelFont: {
        font: "small-caption"
    },
    invoiceDiv: {
        display: "flex", cursor: "pointer", flexDirection: "row", alignItems: "center"
    },
    invoiceIcon: {
        color: window.globalConfig.color.GREEN, fontSize: "xx-large", marginLeft: -12
    },
    invoiceText: {
        color: window.globalConfig.color.GREEN, fontWeight: "bolder", fontSize: "medium"
    },
    subscriptionDiv: {
        borderLeft: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, display: "grid", gridTemplateRows: "50% 50%"
    },
    yearlySubscriptionDiv: {
        display: "flex", flexDirection: "row", padding: '10px 29px 10px 10px', alignItems: "center", borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, justifyContent: "space-between"
    },
    subscriptionAmountDiv: {
        display: "flex", flexDirection: "column", gap: 10, alignItems: "flex-end"
    },
    subscriptionAmount: {
        fontSize: 18, fontWeight: "bolder"
    },
    dateDiv: {
        display: "grid", gridTemplateColumns: "50% 50%", justifyContent: "center"
    },
    innerDateDiv: {
        display: "flex", flexDirection: "column", gap: 10, alignItems: 'baseline', padding: '12px 10px 6px 23px '
    },
    dateText: {
        fontSize: "14px", fontWeight: "bolder"
    },
    statusDiv: {
        borderLeft: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        display: "grid", gridTemplateRows: "50% 50%", width: "-webkit-fill-available"
    }
    , innerStatusDiv: {
        borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, display: "flex", flexDirection: 'row', justifyContent: "space-between", padding: '15px 16px 10px 10px'
    },
    columnFlexStart: {
        display: "flex", flexDirection: "column", gap: 10, alignItems: "flex-start"
    },
    rowCenter: {
        display: "flex", flexDirection: "row", alignItems: "center"
    },
    cancelText: {
        color: window.globalConfig.color.red, fontWeight: 'bolder', textDecoration: 'underline', cursor: 'pointer'
    },
    statusText: {
        fontWeight: 'bold'
    },
    cancelIcon: {
        color: window.globalConfig.color.red, transform: "rotateY(180deg)", fontSize: "medium"
    },
    enableText: {
        color: window.globalConfig.color.PRIMARY, fontWeight: 'bolder', textDecoration: 'underline', 
    },
    enableIcon: {
        color: window.globalConfig.color.PRIMARY, fontSize: "medium"
    },
    column: {
        display: "flex", flexDirection: "column", gap: 10,
    },

    autoChargeStatus: {
        color: window.globalConfig.color.black, fontWeight: 'bolder', textTransform: 'uppercase'
    },
    outlineButton: {
        width: "max-content",
        padding: 5,
        cursor: "pointer",
        border: `1px solid ${window.globalConfig.color.GREEN}`,
        borderRadius: 4,
        fontSize: "x-small",
        color: window.globalConfig.color.GREEN,
        fontWeight: "bold",
        backgroundColor: window.globalConfig.color.WHITE
    },
    solidButton: {
        width: "max-content", padding: 5, cursor: "pointer", color: window.globalConfig.color.WHITE, backgroundColor: window.globalConfig.color.PRIMARY, borderRadius: 4, fontSize: "x-small", display: "flex", flexDirection: "row", alignItems: "center", gap: 5
    },
    success: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: colors.GREEN
    },
    pending: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: colors.WARNING
    },
    failure: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: colors.red
    },
    normal: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: colors.BLACK_PRIMARY
    },
})
class MyPlanCard extends Component {
    constructor() {
        super();
        this.state = {
            isInvoice: false,
            open: false,
            message: "",
            severity: "warning",
            pdfLoading: false,
            opacity: 1,

        }

    }
    returnClass = (status) => {
        const { classes } = this.props
        let statusReport = [{ name: "paid", class: classes.success }, { name: "void", class: classes.pending }, { name: "delete", class: classes.failure }, { name: "Failed", class: classes.failure }, { name: "overdue", class: classes.pending }]
        if (statusReport.filter((item) => item.name.toLowerCase() == status.toLowerCase()).length != 0) {
            return statusReport.filter((item) => item.name.toLowerCase() == status.toLowerCase())[0].class

        } else {
            return classes.normal
        }
    }
    clickInvoice = () => {
        this.setState({ isInvoice: !this.state.isInvoice })
    }
    downloadInvoice = (item) => {
        if (!this.state.pdfLoading) {
            this.setState({ pdfLoading: true })
            this.setState({ opacity: "0.5" })
            axios(`${item}`, {
                method: "GET",
                responseType: "blob", //Force to receive data in a Blob Format
            })
                .then((response) => {
                    this.setState({ pdfLoading: false })
                    //Create a Blob from the PDF Stream
                    const file = new Blob([response.data], {
                        type: `${"application/"}${"pdf"}`,
                    });
                    //

                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    window.open(fileURL);

                    fileDownload(
                        response.data,
                        "DownloadInvoice.pdf"
                    );


                })
                .catch((error) => {
                    this.setState({ open: true, message: labels.messages.apierror, severity: "warning" })

                });
        }

    }
    handlesnanckClose = () => {
        this.setState({ open: false })
    }
    render() {
        const { classes, style, pageName, list } = this.props;

        return (<ErrorBoundary>
            <CommonSnackbar open={this.state.open}
                handlesnanckClose={this.handlesnanckClose}
                message={this.state.message} severity={this.state.severity}></CommonSnackbar>
            <div className={classes.firstColumn} style={{ borderBottom: !this.state.isInvoice ? '' : `1px solid ${window.globalConfig.color.PRIMARY_BORDER}` }}>
                <div className={classes.threeColumnCard}>
                    <div className={classes.planDiv}>
                        <span className={classes.planNumber}>
                            {list.planNumber}
                        </span>
                        <span className={classes.planName}>
                            {list.planName}
                        </span>
                        <span className={classes.labelFont}>
                            {list.planDescription}
                        </span>
                        <div onClick={this.clickInvoice} className={classes.invoiceDiv}>
                            {this.state.isInvoice ? <ArrowDropDownIcon className={classes.invoiceIcon}></ArrowDropDownIcon> : <ArrowRightIcon className={classes.invoiceIcon}></ArrowRightIcon>
                            }
                            <span className={classes.invoiceText}>{labels.PurchasedPlan.Invoice}</span>
                        </div>
                    </div>
                    <div className={classes.subscriptionDiv}>
                        <div className={classes.yearlySubscriptionDiv}>
                            <span style={{ paddingLeft: 12 }} className={classes.labelFont}>{labels.PurchasedPlan.yearlySubscription}</span>
                            <div className={classes.subscriptionAmountDiv}>
                                <span className={classes.subscriptionAmount}>    {NegativeFinding(list.yearlySubscriptionAmount, "", "", "", "rupee")}</span>
                                <span className={classes.labelFont}>{list.yearlySubscriptionType}</span>
                            </div>
                        </div>
                        <div className={classes.dateDiv}>
                            <div className={classes.innerDateDiv}>
                                <span className={classes.labelFont}>{labels.PurchasedPlan.startedOn}</span>
                                <span className={classes.dateText}>{list.startedDate}</span>
                            </div>
                            <div className={classes.innerDateDiv} style={{ borderLeft: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}` }}>
                                <span className={classes.labelFont}>{labels.PurchasedPlan.renewalDate}</span>
                                <span className={classes.dateText}>{list.renewalDate}</span>

                            </div>
                        </div>
                    </div>
                    <div className={classes.statusDiv}>
                        <div style={{ borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, padding: 10, alignItems: "center", display: "flex", justifyContent: "space-between" }}>
                            <span className={classes.labelFont}>Status</span>
                            <span className={[classes.labelFont, classes.statusText].join(' ')} style={{ color: list.status.toLowerCase() == "live" ? window.globalConfig.color.GREEN : list.status.toLowerCase() == "cancelled" ? colors.WARNING : list.status.toLowerCase() == "expired" || list.status.toLowerCase() == "terminated" ? window.globalConfig.color.red : window.globalConfig.color.BLACK_PRIMARY, fontSize: 16 }}>{list.status}</span>
                        </div>
                        {/* <div style={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>
                            <span style={{ fontSize: 14 }}>Auto Charge</span>
                            <span>OFF</span>
                        </div> */}
                        <div className={classes.column} style={{ justifyContent: "center", padding: 10 }} onClick={() => this.props.onClickAutoCharge(list)}>
                            <div className={classes.labelFont} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                <div>{labels.PurchasedPlan.autoCharge}</div>
                                <div style={{ fontSize: 14, color: colors.red, fontWeight: "bold" }}>{list.IsAutoChargeEnable ? "On" : "Off"}</div>
                            </div>
                            {this.props.pageName == "active" && <div className={classes.rowCenter}>
                                <span className={[classes.labelFont, classes.enableText].join(' ')} >{labels.PurchasedPlan.enable}</span>
                                <RefreshIcon className={classes.enableIcon} />
                            </div>}

                        </div>


                    </div>
                </div>
                {this.state.isInvoice ? <div >
                    <div className={this.props.style.secondGrid}>
                        {this.props.header.map((item) => {
                            return <div style={{ color: window.globalConfig.color.BLACK_PRIMARY, fontSize: "12px" }}>{item.name}</div>
                        })}
                    </div>
                    <div> {list.invoiceData != undefined && list.invoiceData != "NA" && list.invoiceData.length != 0 ? list.invoiceData.map((item, mainindex) => {
                        return (<div className={this.props.style.secondGrid}>
                            <div className={"dataOne"}>{item.invoiceDate}</div>
                            <div className={"dataTwo"}>{item.invoiceHash}</div>
                            <div className='dataThree'>{item.Amount}</div>
                            <div className='dataFour'><div className={this.returnClass(item.Status)}></div>{item.Status}</div>
                            <div className='dataFive'>{item.dataFive}</div>
                            {item.dataSix != undefined && <div className='dataSix'>{item.dataSix}</div>}
                        </div>)
                    }) : <div style={{ marginTop: 30, marginBottom: 30 }}><NoData /></div>}
                    </div>
                </div> : <></>}
            </div><br /><br /></ErrorBoundary>)

    }

}
export default withStyles(styles)(MyPlanCard)