import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
import { Asset } from '../../utils/mainasset';
import { Browser, DetectDeviceType } from '../../utils/commonfunction';

function SignUp(name, email, pan, password) {
  const timeStamp = new Date().getTime().toString();
  const timeZone = Intl.DateTimeFormat(undefined, { timeZoneName: 'short' }).resolvedOptions().timeZone;
  const referrelUrl = window.location.href
  const operatingSystem = navigator.userAgentData.platform;
  const userAgent = navigator.userAgent;
  let browser = Browser(userAgent)
  browser = `${browser.browserName} ${browser.browserVersion}`
  const deviceType = DetectDeviceType();
  let data = {};
  data.Name = name;
  data.EmailID = email;
  data.PANNo = pan;
  data.Password = password;
  data.IP = ""
  data.BrowserVersion = browser
  data.MobileAppVersion = ""
  data.DeviceType = deviceType
  data.OSType = operatingSystem
  data.TimeStamp = timeStamp
  data.TimeZone = timeZone
  data.ReferrelURL = referrelUrl
  data.Source = `${Asset.source}`
  // var config = {
  //   method: 'post',
  //   url: ApiValues.SignUp,
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   data: data
  // };
  // return axios(config)
  //   .then(function (response) {
  //     return response.data;
  //   })
  //   .catch(function (error) {
  //     return {"status":"F","message":labels.messages.apierror}
  //   });
  return Networking.PostApi(ApiValues.SignUp, data, labels.apiKey.apiKeys, labels.apiKey.apiKeys, true).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
}

export default SignUp
