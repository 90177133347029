import React, { PureComponent } from "react";
import ListItemText from "@material-ui/core/ListItemText";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import { Chip } from "@material-ui/core";
import { Redirect, withRouter } from "react-router";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Dropdown from "../dropdown/dropdownschemes";
import { withStyles } from "@material-ui/core/styles";
import { BrowserRouter as Router, Route, Switch, useHistory } from "react-router-dom";
import SearchIcon from "../../utils/assets/images/searchIcon.png";
import hoverFilterIcons from "../../utils/assets/img23.png";
import CommonCheckbox from "../checkbox/checkbox";
import addtocart from "../../api/cart/addtocartapi";
import { AddToCart } from "../../redux/actions/cart/addtocartaction";
import {
  GetAllCart,
  GetAllCartValues,
} from "../../redux/actions/cart/getallcartaction";
// import Button from '../../components/custombutton/button';
import ListItem from "@material-ui/core/ListItem";
import colors from "../../utils/colors/colors";
import Popover from "@material-ui/core/Popover";
import { BiChevronDown } from "react-icons/bi";
import "../../utils/stylesheets/common/global/schemes/schemefilter.css";
import "../../utils/stylesheets/common/global/x-ray-header/x-ray-header.css";
import CancelIcon from "@material-ui/icons/Cancel";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {
  // getAllDropdownDetails,
  dropdownvalue,
} from "../../redux/actions/dropdown/dropdownaction";
import { getTransactionDetailsListing } from "../../redux/actions/transactiondetails/transactiondetailsaction";
import {
  SchemeFilters,
  Filterclick,
  TopTen,
} from "../../redux/actions/dropdown/dropdownaction";
import { bindActionCreators } from "redux";
import Popup from "../popup/deletepopup";
import NoData from "../nodata/nodata";
import AvatarBadge from "../avatar/avatar";
import ResetIcon from "../../utils/assets/images/reset.png";
import Label from "../../utils/constants/labels";
import labels from "../../utils/constants/labels";
import ArrowUpwardRoundedIcon from "@material-ui/icons/ArrowUpwardRounded";
import Avatar from "@material-ui/core/Avatar";
import { SchemeDetails } from "../../redux/actions/schemes/schemeaction";
import secureStorage from "../../utils/securestorage";
import {
  isValidDate,
  NegativeFinding,
  returnDateList,
  SchemeCodeFinding,
  uniqueId,
} from "../../utils/commonfunction";
import { NetworkErrorSchemeDetails } from "../../redux/actions/schemes/schemeaction";
import CommonSnackbar from "../snackbar/snackbar";
import FilterImage from "../../utils/assets/images/filter.png";
import SelectedFilterImage from "../../utils/assets/selectedfilter.png";
import hoverSelectedFilterImage2 from "../../utils/assets/img23-Copy.png";
import Button from "../custombutton/button";
import {
  amcOnChange,
  AvailableinvestChange,
} from "../../redux/actions/filter/reportsfilter";
import CloseImage from "../../utils/assets/images/img24.png";
import SchemeDropdown from "../dropdown/dropdownschemes";
import FilterPopup from "../filter/filterpopup";
import Loader from "../loader/loader";
//import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from "@material-ui/core/CircularProgress";
import getPortfolioxraySchemeDetails from "../../api/portfolioxrayschemedetail/portfolioxrayschemedetail";
import FolioPopup from "../tradingpopup/foliopopup";
import CommonDialogBox from "../dialog/dialog";
import InfiniteScroll from "../infinitescroll/infinitescroll";
import Pagination from '../pagination/renderpage'
import { MContext } from "../pagination/renderpage";
let options = labels.Date;
const styles = (theme) => ({
  // gridContainer: {
  //   display: "grid",
  //   gridTemplateColumns: "5% 32% 8% 8% 8% 15% 10% 10%",
  //   gap: 5,
  //   alignItems: "center",
  //   cursor: "pointer",
  //   boxShadow: "0 0 5px 0 rgb(19 89 161 / 6%)",
  //   border: "solid 1px #ecf3fc",
  //   marginBottom: 5,
  //   borderRadius: 9,
  //   padding: 6,
  //   "& .secondGrid": {
  //     alignItems: "flex-start",
  //     textAlign: "left",
  //     gap: 5
  //   },
  //   [theme.breakpoints.down("920")]: {
  //     gridTemplateColumns: "repeat(4,1fr)",
  //     gridTemplateAreas: `"firstGrid secondGrid secondGrid secondGrid"
  //     "thirdGrid fourthGrid fifthGrid sixthGrid"
  //     "seventhGrid . . eighthGrid"
  //                             `,
  //     "& .firstGrid": {
  //       alignItems: "center"
  //     },
  //     "& .secondGrid": {
  //       textAlign: "left"
  //     },
  //     gap: 2
  //   }

  // },
  columnDirection: {
    display: "flex",
    flexDirection: "column"
  },
  rowDirection: {
    display: "flex",
    gap: 5,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row"

  },
  firstGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "firstGrid"
    }
  },
  secondGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "secondGrid"
    }
  },
  thirdGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "thirdGrid"
    }
  },
  fourthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "fourthGrid"
    }
  },
  fifthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "fifthGrid"
    }
  },
  sixthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "sixthGrid"
    }
  },
  seventhGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "seventhGrid"
    }
  },
  eighthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "eighthGrid"
    }
  },
  label: {
    color: colors.LABLE_GRAY,
    fontSize: 12
  },
  txtHeader: {
    padding: 15,
  },
  clearIcon: {
    float: 'right',
    cursor: 'pointer'
  },
  root: {
    "& .MuiDialogContent-root": {
      padding: "0px !important"
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "none !important",
      width: "25% !important",
      overflow: "hidden"
    },
    "& .MuiDialogContent-root": {
      overflowY: "hidden",
      padding: "0px !important"
    }
  },
  outlineButton: {
    width: 109, cursor: "pointer", height: 39, alignItems: "center", borderRadius: 5, justifyContent: "center", border: `1px solid ${window.globalConfig.color.red}`, display: "flex", flexDirection: "column", color: window.globalConfig.color.red, fontWeight: 'bold'
  },
  // searchIcon: {
  //   color: window.globalConfig.color.BROWN,
  //   marginTop: "34px",
  //   height: "37px",
  // },

  resetIcon: {
    cursor: "pointer",
    height: 20,
    width: 16,
  },
  inputRoot: {
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontSize: 12,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: window.globalConfig.color.ZERO_BACKGROUND,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: window.globalConfig.color.BROWN,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: window.globalConfig.color.BROWN,
    },
  },


  badges: {
    paddingTop: 10,
  },
  roots: {
    marginTop: "87px",
    // marginLeft: 20,
    // marginRight: 20,
  },
  cart: {
    fontSize: 12,
    cursor: "pointer",
    color: window.globalConfig.color.PRIMARY,
  },

  // btnClear: {

  //   padding: 0,
  //   lineHeight: "36px",

  //   color: window.globalConfig.color.BLACK_PRIMARY,
  //   fontWeight: 550,
  //   fontSize: "13px",
  //   width: "100px",
  //   marginLeft: "5px",
  //   // borderRadius: "5px",
  //   // border: "1px solid transparent",
  //   "&:hover": {
  //     color: window.globalConfig.color.WHITE,
  //   },
  //   "&:focus": {
  //     borderColor: "rgb(175,119,45)",
  //   },
  // },
  // btnApply: {
  //   marginLeft: "7px",
  //   padding: 0,
  //   lineHeight: "36px",
  //   color: window.globalConfig.color.WHITE,
  //   fontWeight: 600,
  //   fontSize: "14px",
  //   lineHeight: "36px",
  //   width: "100px",
  //   // borderRadius: "5px",
  //   // border: "1px solid transparent",
  //   "&:hover": {
  //     color: window.globalConfig.color.WHITE,
  //   },
  // },

  // tr: {
  //   boxShadow: "0px 1px 2px 1px #e0e5eb",
  //   // border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
  //   // padding: "10px 0",
  //   borderRadius: "8px",
  //   padding: "0px 4px",
  //   cursor: "pointer",
  //   alignItems: "center",
  //   display: "grid",
  //   gridTemplateColumns: "33% 10% 10% 10% 10% 20%",
  //   gap: "10px",
  //   [theme.breakpoints.down("md")]: {
  //     gridTemplateColumns: "33% 7% 7% 7% 11% 20%",
  //   },
  // },
  // rupees: {
  //   margin: "0px !important",
  //   fontSize: "17px !important",
  //   fontWeight: "normal !important",
  //   color: `${window.globalConfig.color.BLACK_PRIMARY} !important`,
  //   padding: "1px 0px",
  // },
  labelStyle: {
    margin: "0px !important",
    fontSize: "12px !important",
    fontWeight: "normal !important",
    color: `${window.globalConfig.color.LABLE_GRAY} !important`,
    padding: "1px 0px",
  },
  firstrow: {
    margin: "0px !important",
    fontSize: "16px !important",
    padding: "4px",
    fontWeight: "500"
  },
  textFieldCursor: {
    caretColor: window.globalConfig.color.BROWN,
  },
  // titlesecondrowheading: {
  //   margin: "0px !important",
  //   fontSize: "11px !important",
  //   fontWeight: "normal !important",
  //   color: `${window.globalConfig.color.LABLE_GRAY} !important`,
  // },
  btnParent: {
    padding: "15px 10px 10px 10px",
    textAlign: "center",
    [theme.breakpoints.down("900px")]: {
      padding: "10px 10px 10px 0px",
    },
  },
  cartParent: {
    textAlign: "center",
    paddingBottom: 20,
  },
  titlesecondrow: {
    margin: "0px !important",
    fontSize: "12px !important",
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontWeight: 400,
  },
  vertical: {
    borderLeft: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    margin: "0px 5px",
    height: "9px",
  },
  schemeName: {
    fontSize: "16px",
  },



  header: {
    backgroundColor: window.globalConfig.color.PRIMARY,
    color: window.globalConfig.color.WHITE,
    height: 50,
    fontWeight: 500,
    position: "sticky",
    top: "0px"
  },

  // Added by Celsia on 24 May 2021 for Mobile Responsive   Started
  desktopResponsive: {
    display: "block !important",

    [theme.breakpoints.down("900")]: {
      display: "none !important",
    },
  },

  body: {
    margin: 20,
    textAlign: "center",
  },
  sipContainer: {
    maxheight: 470,
    // width: 360,
    //  [theme.breakpoints.down("920")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
    //},

  },
  txtBody: {
    padding: 10,
    fontSize: 13,
    color: window.globalConfig.color.BLACK_PRIMARY,
  },
  onetimeContainer: {
    maxheight: 400,
    width: 350,
    [theme.breakpoints.between("320", "374")]: {
      width: 240,
    },
    [theme.breakpoints.between("375", "424")]: {
      width: 300,
    },
  },

  footer: {
    padding: "14px 20px 20px 20px",
    marginTop: -15,
  },

  trResponsive: {
    height: "auto",
    boxShadow: "0px 1px 2px 1px #e0e5eb",
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    padding: "10px 0",
    borderRadius: "8px",
    fontSize: "13px",
    fontWeight: 400,
    width: "100%",
  },
  flexResponsive: {
    display: "flex",
    flexDirection: "row",
  },
  btnApplyResponsive: {
    background: window.globalConfig.color.BROWN,
    lineHeight: "36px",
    color: window.globalConfig.color.WHITE,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    width: "120px",
    borderRadius: "5px",
    padding: 0,
    border: "1px solid transparent",
    "&:hover": {
      background: window.globalConfig.color.PRIMARY_LIGHT,
      color: window.globalConfig.color.WHITE,
    },
  },
  btnClearResponsive: {
    background: window.globalConfig.color.ZERO_BACKGROUND,
    lineHeight: "36px",
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontWeight: 600,
    padding: 0,
    fontSize: "14px",
    lineHeight: "36px",
    width: "120px",
    marginLeft: "5px",
    borderRadius: "5px",
    border: "1px solid transparent",
    "&:hover": {
      background: window.globalConfig.color.PRIMARY_LIGHT,
      color: window.globalConfig.color.WHITE,
    },
  },
  summary: {
    height: 110,
    backgroundColor: window.globalConfig.color.PRIMARY,
    paddingTop: 10
    // paddingTop: "33px",
  },
  filterImagestyle: {
    [theme.breakpoints.up("600")]: {
      width: 35,
      height: 37,
      left: "3.5vh",
      marginLeft: "-11px",
      top: 13,
      background: `url(${FilterImage}) center center no-repeat`,
      position: "absolute",
      cursor: "pointer",
      "&:hover": {
        background: `url(${hoverFilterIcons}) center center no-repeat`,
      },
    },
    [theme.breakpoints.down("600")]: {
      position: "unset",
      paddingTop: "27px",
      width: 35,
      height: 37,
      left: "18px",
      marginLeft: "68px",
      top: 21,
      marginLeft: 11,
      background: `url(${FilterImage}) center center no-repeat`,
    },
  },
  container: {
    // maxWidth: "1070px",
    width: "79%",
    padding: "0px 20px",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down(600)]: {
      width: "89%",
    },
  },

  clear: {
    clear: "both",
    height: "0px",
    display: "block",
  },

  sumBoxLeft: {
    // maxWidth: "41%",
    // width: "50%",
    position: "relative",
    float: "left",
    height: 97,
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    [theme.breakpoints.up(900)]: {
      borderRight: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    },

    [theme.breakpoints.down(900)]: {
      width: "100%",
      maxWidth: "100%",
      borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
      borderBottomRightRadius: "10px",
    },
  },
  searchBox: {
    padding: "20px 0 10px 6px",
    verticalAlign: "baseline",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    [theme.breakpoints.down(600)]: {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
    [theme.breakpoints.down("sm")]: {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      borderBottomRightRadius: "10px",
    },
  },
  formControl: {
    display: "block",
    width: "75%",
    height: `calc(${"2.25rem"} + ${"2px"})`,
    padding: ".375rem .75rem",
    fontSize: "14px",
    lineHeight: 1.5,
    color: window.globalConfig.color.BLACK_PRIMARY,
    backgroundColor: window.globalConfig.color.WHITE,
    backgroundClip: "padding-box",
    border: 0,
    borderRadius: 0,
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    fontWeight: 500,
    overflow: "visible",
    outline: "none",
    [theme.breakpoints.down(1344)]: {
      width: "85%",
    },
  },
  sumBoxFil1: {
    // width: "21%",
    float: "left",
    padding: "6px",
    paddingRight: "0px",
    borderRight: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    minHeight: "86px",
    "&:hover": {
      backgroundColor: window.globalConfig.color.RosyBrown,
    },
    [theme.breakpoints.down(1344)]: {
      // width: "19%",
    },
    [theme.breakpoints.down(900)]: {
      width: "98%",
      borderRight: `none`,
    },
    [theme.breakpoints.down("sm")]: {
      borderBottom: "1px solid #c7c7c7",
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
    },

    // height: 58
  },
  sumBoxFil2: {
    float: "left",
    // width: "7%",
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "center",
    alignItems: "center",
    height: 97,
    [theme.breakpoints.down(900)]: {
      width: "49%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderBottom: "1px solid #c7c7c7",
      borderBottomLeftRadius: "10px",
    },

  },
  labelStyle: {
    display: "block",
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: "14px",
    marginLeft: 12,
    padding: 0,
    border: 0,
    textAlign: "left",
  },
  sumBoxFil3: {
    border: `1px solid ${window.globalConfig.color.WHITE}`,
    borderLeft: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    // width: "8%",
    float: "right",
    minHeight: "97px",
    borderRadius: "0 10px 10px 0",
    background: `${window.globalConfig.color.RosyBrown} url(${SearchIcon}) center center no-repeat`,
    // [theme.breakpoints.between("1250", "1300")]: {
    //   // width: "10%",
    // },
    // [theme.breakpoints.down(900)]: {
    //   // width: "50%",
    //   minHeight: "86px",
    //   display: "flex",

    //   borderRadius: 0
    // },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      borderBottom: "1px solid #c7c7c7",
    },
  },
  mobileResponsiveDiv: {
    [theme.breakpoints.down(900)]: {
      height: "74vh",
      boxShadow: "0 0 20px 0 rgba(19, 89, 161, 0.1)",
    },
  },
  clearSelectfromDiv: {
    background: `url(${ResetIcon})  no-repeat`,
    width: "18px",
    height: "18px",
    zIndex: 0,
    position: "absolute",
    cursor: "pointer",
    right: 10,
    // display: "none",
    top: "55px",
    [theme.breakpoints.down("900")]: {
      width: "37px",
    },
  },
  ButtonContainer: {
    display: "flex",
    // width: "22%",
    [theme.breakpoints.down("md")]: {
      margin: "13% 0%",
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "22%",
      marginTop: "revert",
      gridTemplateColumns: "revert",
    },
  },

  sortBy: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "6%",
      display: "flex",
    },
  },

  flexDirectionSchemes: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("900")]: {
      marginTop: "5%",
    },
  },
  openImageblock: {
    display: 'inline-block',
  },
  selectedSortBy: {
    backgroundColor: "#faf7f2 !important",
    color: "#af772d"
  }

});
const user = secureStorage.getItem("user");
// const a = secureStorage.getItem("selectedMappingDetails");
const purchaseAmount = [];
let todayDate = moment(new Date()).format("DD MMM YYYY");
let folio = [];
class Filter_Screen extends PureComponent {
  isComponentMounted = false;
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    // this.child = React.createRef();
    this.state = {
      sortBy: false,

      color: window.globalConfig.color.WHITE,

      showbutton: false,

      initialClick: false,
      Data: [],
      selectedSortBy: ""
    };
    // this.handleScroll = this.handleScroll.bind(this);
  }



  componentDidUpdate(prev) {
    const { listdata } = this.props
    // if (this.state.flag || this.props.filterSearch) {
    if (prev.listdata != listdata) {
      this.setState({ Data: listdata })
    }
    // }
    // else {
    //   this.setState({
    //     Data: this.state.Data
    //   })
    // }
    // }
    // else{
    //   this.setState({
    //     Data : this.state.Data
    //   })     
    // }
  }
  componentDidMount() {
    this.isComponentMounted = true;
    // window.addEventListener("scroll", this.handleScroll);
    this.setState({ Data: this.props.listdata })
    this.setState({ selectedSortBy: this.props.sortBy[0]?.name })

  }
  // handleScroll = () => {
  //   if (window.innerHeight + document.documentElement.scrollTop + 5 >= document.scrollingElement.scrollHeight) {
  //     // Do load more content here!
  //   }
  // }

  sortByClose = () => {
    this.setState({ sortBy: null })
  }
  resize() {
    let currentHideNav = window.innerWidth <= 920;
    if (currentHideNav !== this.state.showbutton) {
      //
      this.setState({ showbutton: currentHideNav });
    }
  }
  componentWillUnmount() {
    this.isComponentMounted = false;
    // window.removeEventListener("resize", this.resize.bind(this));
    // window.removeEventListener("scroll", this.handleScroll);
  }
  sortByIndexClick = (value, index) => {
    // this.props.sortFlag()
    // if (index == 0) {
    /// if (value.name == "A-Z") {
    var filterData;
    filterData = this.props.listdata.toSorted((a, b) => {
      if (typeof (a[value]) == "string") {

        var textA = a[value].toUpperCase();
        var textB = b[value].toUpperCase();
        return this.state.initialClick ? textA > textB ? -1 : textA < textB ? 1 : 0 : textA < textB ? -1 : textA > textB ? 1 : 0;
      } else {
        return this.state.initialClick ? a[value] - b[value] : b[value] - a[value];
      }

    });
    this.setState({ initialClick: !this.state.initialClick, Data: [...filterData], flag: false })

    // this.setState({
    //   Data: filterData,
    //   // sortBy: null,
    // });
  };
  orderStatusRedirection = () => {
    this.props.history.push(labels.Path.MFTransaction)
  }
  // handleFocus = (event) => event.target.select();
  render() {
    const { classes } = this.props;
    try {
      return (
        <div>


          <div className={classes.summary}>
            <div className={classes.container}>
              <div style={{ display: "grid", gridTemplateColumns: "50% 50%", alignItems: "center" }}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      textAlign: "left",
                      fontSize: 14,
                      color: window.globalConfig.color.cyanBluish,
                    }}
                  >
                    {this.props.logindetails.UserName}
                    {this.props.logindetails.FamilyName != ""
                      ? `(${this.props.logindetails.FamilyName})`
                      : ""}
                  </span>
                  <span
                    style={{
                      color: window.globalConfig.color.WHITE,
                      float: "left",
                      fontSize: 24,
                      fontWeight: "bold",
                      justifyContent: "flex-start",
                      textAlign: "left",
                    }}
                  >
                    {this.props.pageHeader}
                  </span>
                </div>
                {this.props.logindetails.EntityTypeID != 9 && <div onClick={this.orderStatusRedirection} style={{ textAlign: "end", color: colors.WHITE, cursor: "pointer", textDecoration: "underline" }}>
                  <span>My Order Status</span>
                </div>}
              </div>
              {/* <div className={classes.clear}></div> */}
              {this.props.isLoading ? <div style={{ backgroundColor: colors.WHITE, minHeight: 67, boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)", width: "100%", padding: 1, borderRadius: 10 }}><Loader page="customLoader"  ></Loader></div> : <div className={this.props.filterGrid}>

                {
                  this.props.filter != null && this.props.filter.length != 0 && this.props.filter.map((item) => {
                    return <>
                      {item.type.name == "dropdown" ? <ClickAwayListener onClickAway={this.closeCategory}>
                        <div className={classes.sumBoxFil1}>
                          <SchemeDropdown
                            type={"withoutsearchable"}
                            handleOnChange={item.onChange}
                            callBack={item?.onClear}
                            List={item.List}
                            loader={this.props.isLoading}
                            label={item.header}
                            placeholder={labels.schemes.allCategory}
                            autoselect={false}
                            isSearchable={false}
                            showborder={false}
                          />
                        </div>
                      </ClickAwayListener> : item.type.name == "textbox" ?
                        <div className={classes.sumBoxLeft}>
                          <div
                            className={classes.searchBox}
                            style={{ backgroundColor: this.state.color }}
                          >
                            <label className={classes.labelStyle}>{"Search"}</label>
                            <input
                              type="text"
                              placeholder={labels.schemes.enterYourSearch}
                              value={this.props.searchContent}
                              onChange={item.onChange}
                              onFocus={() => this.setState({ color: window.globalConfig.color.RosyBrown })}
                              onBlur={() => this.setState({ color: window.globalConfig.color.WHITE })}
                              // onPaste={this.props.searchContent}
                              onPaste={item.onChange}
                              className={[
                                classes.formControl,
                                "placeholdercolor",
                              ].join(" ")}
                              style={{ backgroundColor: 'transparent' }}
                            ></input>
                            {this.props.searchContent != "" && (
                              <div
                                className={classes.clearSelectfromDiv}
                                onClick={this.props.onClickSearchClear}
                              ></div>
                            )}
                          </div>
                        </div> :
                        item.type.name == "popup" ? <div className={classes.sumBoxFil2}>
                          <FilterPopup
                            flag="BuyandSellMobile"
                            filterImagestyle={classes.filterImagestyle}
                            selectedFilterImagestyle={
                              classes.selectedFilterImagestyle
                            }
                            showselected={
                              this.props.selectedAmcList.length != 0 ||
                                this.props.selectedinvestList.length != 0
                                ? true
                                : false
                            }
                            selectedvalue={
                              this.props.AmcList.filter(
                                (item) => item.isChecked == true
                              ).length != 0
                                ? this.props.AmcList.filter(
                                  (item) => item.isChecked == true
                                )
                                : []
                            }
                            onClick={this.props.handleappyclick}
                            onOutsideClick={this.props.clearselecteddata}
                            handleClear={this.props.handleClearFilter}
                            showclose={true}
                          >
                            <SchemeDropdown
                              type="Searchable"
                              id="amcid1"
                              //  setClick={click => this.closeRisk = click}
                              handleOnChange={this.props.setSelectedAmc}
                              List={this.props.AmcList}
                              label={labels.schemes.amc}
                              placeholder={labels.schemes.all}
                              isSearchable={true}
                              loader={this.props.isLoading}
                              showborder={true}
                              // autoselect={false}
                              // filter={"close"}
                              searchPlaceholder={labels.schemes.searchMutualFundCompanies}
                            />
                            {/* <SchemeDropdown
                              type="Searchable"
                              id="amcid2"
                              //  setClick={click => this.closeRisk = click}
                              handleOnChange={this.props.setSelectedInvest}
                              List={this.props.AvailabletoinvestList}
                              label={labels.schemes.availableToInvest}
                              placeholder={labels.schemes.all}
                              isSearchable={false}
                              showborder={true}
                              autoselect={false}
                              filter={"close"}
                            /> */}
                          </FilterPopup>
                        </div> : <div
                          className={classes.sumBoxFil3}
                          style={{ cursor: "pointer" }}
                          onClick={this.props.onFinalSearchClick}
                        ></div>
                      }



                    </>

                  })}
              </div>}
            </div>
          </div>
          <div className={classes.roots}>
            {/* <div style={{ flex: 12 }}> */}
            <Grid className={classes.container}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className={classes.flexDirectionSchemes}>
                    <div
                      style={{
                        justifyContent: "flex-start",
                        flex: 0.9,
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {this.props.finalSearch ? (
                        <>
                          <span
                            style={{
                              textAlign: "left",
                              fontWeight: 500,
                              marginTop: 3,
                            }}
                          >
                            {this.state.Data.length > 1
                              ? `${this.state.Data.length} ${labels.schemeFilterLabels.searchResult}`
                              : `${this.state.Data.length} ${labels.schemeFilterLabels.searchResults}`}
                          </span>
                          {/* {this.props.searchContent != "" && ( */}
                          <div
                            style={{
                              display: "flex",
                              fontSize: 15,
                              flexDirection: "row",
                              marginBottom: 13,
                              marginLeft: 12,
                              height: 21,
                              width: "auto",
                              padding: 3,
                              borderRadius: 10,
                              backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
                              cursor: "pointer",
                            }}
                            onClick={this.props.resetClick}
                          >
                            <CancelIcon
                              className={classes.resetIcon}
                            ></CancelIcon>
                            <a
                              style={{
                                cursor: "pointer",
                                marginLeft: 5,
                                marginTop: 2,
                              }}
                            >

                              {labels.schemeFilterLabels.reset}
                            </a>
                          </div>
                          {/* )} */}
                        </>
                      ) :
                        <span
                          style={{
                            textAlign: "left",
                            fontWeight: 500,
                            color: colors.BLACK_PRIMARY,
                            paddingBottom: "20px",
                          }}
                        >
                          {
                            this.props.subHeader
                          }
                        </span>
                      }

                    </div>
                    {this.props.listdata.length != 0 && this.props.enableSortBy && (
                      <div
                        style={{
                          justifyContent: "flex-end",
                          display: "flex",
                          marginRight: "-8vw",
                          position: "relative",
                        }}
                      >
                        <span
                          style={{
                            textAlign: "right",
                            paddingRight: "4px",
                            cursor: "pointer",
                            fontSize: 14,
                          }}
                          onClick={(event) => {
                            this.setState({
                              sortBy: event.currentTarget,
                            });
                          }}
                        >
                          {labels.schemeFilterLabels.sortBy}
                        </span>
                        <span>
                          <BiChevronDown
                            onClick={(event) => {
                              this.setState({
                                sortBy: event.currentTarget,
                              });
                            }}
                            style={{
                              width: 25,
                              height: 25,
                              marginTop: "-20%",
                              color: "grey",
                              cursor: "pointer"
                            }}
                          />
                        </span>
                        <div>
                          <Popover
                            // className="filterpop"
                            disableScrollLock={true}
                            disablePortal={true}
                            // className={classes.desktopResponsive}
                            style={{ top: -6 }}
                            open={Boolean(this.state.sortBy)}
                            anchorEl={this.state.sortBy}
                            onClose={this.sortByClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            onScroll={this.sortByClose}
                            onKeyDown={this.sortByClose}
                          >
                            <div style={{ width: "100px" }}>
                              {this.props.sortBy.map((item, index) => {
                                return <ListItem
                                  key={index}
                                  button
                                  className={this.state.selectedSortBy == item.name ? classes.selectedSortBy : "sortbylist"}
                                  onClick={(event) => {
                                    this.sortByIndexClick(item.key, index)
                                    this.sortByClose()
                                    this.setState({ selectedSortBy: item.name })

                                  }
                                  }
                                >
                                  <ListItemText
                                    primary={this.state.initialClick ? item.isInversible && item.name == this.state.selectedSortBy ? item.name.split('').reverse().join('') : item.name : item.name}
                                  />
                                </ListItem>
                              })}
                            </div>
                          </Popover>
                        </div>
                      </div>
                    )}
                  </div>
                  <div style={{ width: "100%", marginBottom: "5%" }}>
                    {this.props.isLoading ?
                      <>
                        <Loader page={"reportmodel"} />
                        <Loader page={"reportmodel"} />
                        <Loader page={"reportmodel"} />
                      </>

                      :
                      this.state.Data.length != 0 ?
                        <Pagination enableLazyLoading={true}
                          data={this.state.Data}>
                          <MContext.Consumer>
                            {(context) => {
                              return context.map((value, index) => (

                                <div className={classes.gridContainer} key={index} >
                                  {value.dataOne != undefined && <div className={[classes.columnDirection, classes.firstGrid, "firstGrid"].join(' ')} onClick={() => this.props.routeChange(value)}>
                                    <AvatarBadge
                                      color={value.color}
                                      url={value.dataOne}
                                      id={"scheme" + index}
                                      avatar={value.dataTwo.substring(0, 2)}
                                    />
                                  </div>}
                                  {value.dataTwo != undefined && <div className={[classes.columnDirection, classes.secondGrid, "secondGrid"].join(' ')} onClick={() => this.props.routeChange(value)}>
                                    <span className={classes.schemeName}>{value.dataTwo}</span>
                                    <div className={classes.rowDirection} style={{ fontSize: 12 }}>

                                      <span>{value.subDataOne.charAt(0)}{value.subDataOne.slice(1).toLowerCase()}</span>
                                      {value.subDataTwo != undefined && <> <span className={classes.label}>|</span>
                                        <span className={classes.label}>
                                          {value.subDataTwoHeader}
                                        </span>
                                        <span className={classes.titlesecondrow}>{value.subDataTwo}</span></>}
                                    </div>
                                  </div>}
                                  {value.dataThree != undefined && <div className={[classes.columnDirection, classes.thirdGrid, "thirdGrid"].join(' ')} onClick={() => this.props.routeChange(value)}>
                                    <span
                                      className={classes.firstrow}
                                      style={{ color: NegativeFinding(value.dataThree) }}
                                    >
                                      {value.dataThree}
                                    </span>
                                    <span className={classes.label}>{value.dataThreeHeader}</span>
                                  </div>}

                                  {value.dataFour != undefined && <div className={[classes.columnDirection, classes.fourthGrid, "fourthGrid"].join(' ')} onClick={() => this.props.routeChange(value)}>
                                    <span
                                      className={classes.firstrow}
                                      style={value.dataFourStyle}
                                    >
                                      {value.dataFour}
                                    </span>
                                    <span className={classes.label}>{value.dataFourHeader}</span>
                                  </div>}
                                  {value.dataFive != undefined && <div className={[classes.columnDirection, classes.fifthGrid, "fifthGrid"].join(' ')} onClick={() => this.props.routeChange(value)}>
                                    <span
                                      className={classes.firstrow}
                                      style={{ color: NegativeFinding(value.dataFive) }}
                                    >
                                      {value.dataFive}
                                    </span>
                                    <span className={classes.label}>{value.dataFiveHeader}</span>
                                  </div>}
                                  {value.dataSix != undefined && <div className={[classes.columnDirection, classes.sixthGrid, "sixthGrid"].join(' ')} onClick={() => this.props.routeChange(value)}>
                                    <span className={classes.firstrow} style={value.dataSixStyle}>
                                      {value.dataSix}
                                    </span>
                                    <span className={classes.label} >
                                      {value.dataSixHeader}
                                    </span>
                                  </div>}
                                  {value.dataSeven != undefined && <div className={[classes.seventhGrid, "seventhGrid"].join(' ')}>
                                    <Button
                                      name={value.dataSevenHeader}
                                      disabled={value.dataSeven === 1 ? false : true}
                                      onClick={() => this.props.oneTimePopup(value)}

                                    // loading="true" loader={this.state.loader}
                                    />
                                  </div>}
                                  {value.dataEight != undefined && <div className={[classes.eighthGrid, "eighthGrid"].join(' ')} style={{ width: "max-content" }}>
                                    <Button
                                      name={value.dataEightHeader}
                                      page={"primary"}
                                      disabled={value.dataEight === 1 ? false : true}
                                      onClick={() => this.props.sipPopup(value)}
                                    // loading="true" loader={this.state.loader}
                                    />
                                  </div>}


                                </div>
                              ))
                            }}
                          </MContext.Consumer>
                        </Pagination>
                        :
                        <div style={{ marginTop: "5%", marginBottom: "10%" }}>
                          <NoData height={5} time={15000} />
                        </div>

                    }
                  </div>
                </Grid>
              </Grid>
            </Grid>
            {/* </div> */}
          </div>


          <CommonSnackbar
            open={this.props.openAlert}
            message={this.props.alertMessage}
            severity={this.props.alertColor}
            handlesnanckClose={this.props.handleCloseAlert}
          />
        </div>
      )

    } catch (err) {
      return <Redirect to={labels.Path.PageNotFound} />;
    }

  }
}
const mapStateToProps = (state) => {
  return {
    sessionId: state.Login.sessionID,
    clientId: state.Login.logindetails.ClientID,
    userDetails:
      state.ProfileDetails.AllProfileDetails.length != 0
        ? state.ProfileDetails.AllProfileDetails.mainDetails != "NA"
          ? state.ProfileDetails.AllProfileDetails.mainDetails
          : []
        : [],

    schemeFilteredCount: state.Dropdown.schemeIndex,
    mappingDetails: state.SchemeDetails.mappingDetails,
    clientID: state.Login.logindetails.ClientID,
    logindetails: state.Login.logindetails,
    Kycmessage: state.Kyc.Kycmessage,

    AvailabletoinvestList: state.Filter.AvailableInvest,
    // isLoading: state.Dropdown.loaderdropdown,
    sipCartData: state.CartReducer.getAllSipCart == "NA" ? [] : state.CartReducer.getAllSipCart,
    onetimeCartData: state.CartReducer.getAllOnetimeCart == "NA" ? [] : state.CartReducer.getAllOnetimeCart,
    clientID: state.Login.logindetails.ClientID,
    l5Report: state.L5report.L5report.length != 0 && state.L5report.L5report != "NA"
      ? state.L5report.L5report : [],

    uccList: state.ProfileDetails.AllProfileDetails?.uccList != undefined && state.ProfileDetails.AllProfileDetails.length != 0 && state.ProfileDetails.AllProfileDetails?.uccList?.length != 0 ? state.ProfileDetails.AllProfileDetails.uccList != "NA" ? state.ProfileDetails.AllProfileDetails.uccList.filter(
      (ele, ind) =>
        ind ===
        state.ProfileDetails.AllProfileDetails.uccList.findIndex((elem) => elem.ClientCode === ele.ClientCode)
    ).map((item) => {
      return { ...item, "Name": item.ClientCode }
    }) : [] : [],
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getTransactionDetailsListing,
      GetAllCartValues,
      GetAllCart,
      AddToCart,
      amcOnChange,
      AvailableinvestChange,
      TopTen,
      // getAllDropdownDetails,
      // getKYCActionDetails,
      Filterclick,
      SchemeFilters,
      SchemeDetails,
      NetworkErrorSchemeDetails,
    },
    dispatch
  );
};
export default withStyles(styles)(withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Filter_Screen))
);
