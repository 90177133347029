import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router";
import CancelImage from "../../utils/assets/images/failureImg.png";
import Successfull from "../../utils/assets/images/successfull.jpg";
import Capture from "../../utils/assets/images/capture.png";
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Buttons from "../custombutton/button";
import { getRiaFlowOffLinePaymentReport } from "../../redux/actions/riaflowofflinepayment/riaflowofflinepayment"
import { getRiaOnLinePaymentDetailsReport } from "../../redux/actions/riaonlinepaymentdetails/riaonlinepaymentdetails"
import CommonMenu from '../menu/menu';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ErrorBoundary from '../Errorhandling/Errorhandle';
import { NegativeFinding, replaceNull, scrollToTopPage } from '../../utils/commonfunction';
import getBillingDetails from '../../api/riaflowonlinepaymentapi/billingaddress';
import moment from 'moment';
import Epfreport from '../../containers/epf/epfreport';
import OutlinedButtons from '../payment/button';
import Snackbar from '../snackbar/snackbar';

// const useStyles = makeStyles((theme) => ({
//     gridContainer: {
//         display: "grid",
//         marginTop: '5vh',
//         gridTemplateColumns: '30% 40% 30%',
//         [theme.breakpoints.down('xs')]: {
//             display: "grid",
//             marginTop: '-40vh',
//             gridTemplateColumns: '100%',
//         }
//     },
//     closeIcon: {
//         '&:hover': {
//             cursor: 'pointer'
//         }
//     },
//     containerResponsive: {
//         width: "100%",
//         display: "flex",
//         flexDirection: "column",
//         boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.3)",
//         borderRadius: 10,
//     },
//     title: {
//         marginTop: '2vh',
//         fontWeight: 550
//     },
//     titleTransaction: {
//         fontSize: 15,
//         marginTop: '0.5vh'
//     },
//     titleBank: {
//         fontSize: 15,
//         marginTop: '2vh'
//     },
//     containerResponsiveButton: {
//         // display: "grid",
//         marginTop: '3vh',
//         // gridTemplateColumns: '50% 50%',
//         display: "flex",
//         flexDirection: "column",
//         alignItems: "center",
//         [theme.breakpoints.down('xs')]: {
//             display: "grid",
//             gridTemplateColumns: '100%',
//         }
//     },
//     containerResponsiveButtonFirst: {
//         display: "grid",
//         marginTop: '3vh',
//         gridTemplateColumns: '50% 10% 50%',
//         [theme.breakpoints.down('xs')]: {
//             display: "grid",
//             gridTemplateColumns: '40% 15 45%',
//         },
//         [theme.breakpoints.between('601', '1024')]: {
//             display: "grid",
//             gridTemplateColumns: '100%',
//         }

//     },
//     btnWidth: {
//         [theme.breakpoints.between('601', '1024')]: {
//             display: "grid",
//             marginTop: '1vh'
//         }
//     },
//     containerResponsiveButton1: {
//         display: "grid",
//         // backgroundColor:'red',
//         marginTop: '7vh',
//         gridTemplateColumns: '50% 50%',
//         [theme.breakpoints.down('xs')]: {
//             display: "grid",
//             gridTemplateColumns: '100%',
//         }
//     },
// }));

// function RiaPlanComplete(props) {
//     useEffect(() => {
//         scrollToTopPage();
//         var gstNumber = ''
//         if (props.StoreGstNumberBankDetails.gstValue != undefined && props.StoreGstNumberBankDetails.gstValue != null && props.StoreGstNumberBankDetails.gstValue != "") {
//             gstNumber = props.StoreGstNumberBankDetails.gstValue
//         }
//         else {
//             gstNumber = ''
//         }
//         if (props.location.state.data != undefined && props.location.state.data != null && props.location.state.data != "") {
//             //OFFLINE API CALL  
//             //Parameters are - FormNo,EmailID,PlanCode,GstNumber = '',PaymentType = 2 is a offline Payment,ClientID
//             props.getRiaFlowOffLinePaymentReport(props.formNo, props.logindetails.EmailID, props.purchasedPlans[0].planCode, '', 2, props.logindetails.ClientID)
//         }
//         else {
//             // ONLINE API CALL
//             // Parmeter is FormNo = props.logindetails.KYCFormNo,EmailID,PlanCode,GstNumber,PaymentType = 1 is a online Payment,ClientID,DepositPageID = this Id is taken from success in Browser url
//             // props.getRiaOnLinePaymentDetailsReport(props.formNo, props.logindetails.EmailID, props.purchasedPlans[0].planCode, gstNumber, 1, props.logindetails.ClientID, String(props.history.location.search.split('?hostedpage_id=')[1]))
//             // props.getRiaOnLinePaymentDetailsReport("payment_status", String(props.history.location.search.split('?hostedpage_id=')[1]))

//         }
//     }, [props.history.location.search]);

//     // const closeRedirection = () => {
//     //     props.history.push({
//     //         pathname: labels.Path.UserDetails,
//     //     })
//     // }

//     const invoice = () => {
//         if (props.riaOnLinePaymentDetails.data != undefined && props.riaOnLinePaymentDetails.data != null && props.riaOnLinePaymentDetails.data != "") {
//             window.open(props.riaOnLinePaymentDetails.data.invoiceURL)
//         }
//     }

//     const dasBoard = () => {
//         props.history.push({
//             pathname: labels.Path.Dashboard,
//         })
//     }

//     const classes = useStyles();
//     try {
//         return (
//             <ErrorBoundary>

//                 <div>

//                     <div className={classes.gridContainer}>
//                         {props.riaOnLinePaymentDetails != undefined && props.riaOnLinePaymentDetails != "" && props.riaOnLinePaymentDetails != null && props.riaOnLinePaymentDetails.length != 0 ?
//                             <>
//                                 {/* ON LINE DESIGN */}
//                                 <div style={{ marginTop: '45vh', textAlign: 'left' }}>
//                                     <img src={Successfull} style={{ height: 200, width: 200 }} />
//                                 </div>
//                                 <div>
//                                     <div className={classes.containerResponsive}>
//                                         <div style={{ marginTop: '6vh' }}></div>
//                                         <div>
//                                             {/* <img src={CancelImage} style={{ height: 57, width: 62 }} /> */}
//                                             <img src={Capture} style={{ height: 58, width: 60 }} />
//                                         </div>
//                                         <div>
//                                             <Typography className={classes.title} variant="h5" color="textSecondary" gutterBottom>
//                                                 {props.riaOnLinePaymentDetails.message != undefined && props.riaOnLinePaymentDetails.message != null && props.riaOnLinePaymentDetails.message != "" ? props.riaOnLinePaymentDetails.message : 'NA'}
//                                             </Typography>
//                                         </div>
//                                         <div>
//                                             <Typography className={classes.titleTransaction} color="textSecondary" gutterBottom>
//                                                 {labels.riaPlanComplete.TransactionNumber}: {props.riaOnLinePaymentDetails.data != undefined && props.riaOnLinePaymentDetails.data != "" && props.riaOnLinePaymentDetails.data != null ? props.riaOnLinePaymentDetails.data.paymentID : 0}
//                                             </Typography>
//                                         </div>
//                                         <div className={classes.containerResponsiveButton1}>
//                                             <div ></div>
//                                             <div ></div>
//                                         </div>
//                                         <div>
//                                             <Typography className={classes.titleTransaction} color="textSecondary" gutterBottom>
//                                                 {labels.riaPlanComplete.AmountPaid}: Rs.{props.riaOnLinePaymentDetails.data != undefined && props.riaOnLinePaymentDetails.data != "" && props.riaOnLinePaymentDetails.data != null ? props.riaOnLinePaymentDetails.data.amount : 0}
//                                             </Typography>
//                                         </div>
//                                         <div>
//                                             <Typography className={classes.titleBank} color="textSecondary" gutterBottom>
//                                                 {labels.riaPlanComplete.Bank}: {props.StoreGstNumberBankDetails.bankDetails != null && props.StoreGstNumberBankDetails.bankDetails != "" && props.StoreGstNumberBankDetails.bankDetails != undefined ? props.StoreGstNumberBankDetails.bankDetails.name : labels.messages.NA}
//                                             </Typography>
//                                         </div>
//                                         <div style={{ marginTop: '6vh' }}></div>
//                                     </div>
//                                     <div style={{ marginTop: '5vh' }}>
//                                         <div>
//                                             <Typography className={classes.titleTransaction} color="textSecondary" gutterBottom>
//                                                 {labels.riaPlanComplete.InvoiceHasBeenSent}
//                                             </Typography>
//                                         </div>
//                                         <div className={classes.containerResponsiveButtonFirst}>

//                                             {props.riaOnLinePaymentDetails.data != undefined && props.riaOnLinePaymentDetails.data != null && props.riaOnLinePaymentDetails.data != "" &&
//                                                 <>
//                                                     {props.riaOnLinePaymentDetails.data.invoiceURL &&
//                                                         <div style={{ width: 150, }} className={classes.btnWidth}>
//                                                             <Buttons onClick={invoice} page='secondary' name={labels.riaPlanComplete.inVoice} />
//                                                         </div>
//                                                     }
//                                                 </>
//                                             }
//                                             <div></div>
//                                             <div style={{ width: 150, }} className={classes.btnWidth}>
//                                                 <Buttons onClick={dasBoard} page='primary' name={labels.riaPlanComplete.Dashboard} />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </>
//                             :
//                             <>
//                                 {/* OFF LINE DESIGN */}
//                                 <div style={{ marginTop: '45vh', textAlign: 'left' }}>
//                                     <img src={Successfull} style={{ height: 200, width: 200 }} />
//                                 </div>
//                                 <div>
//                                     <div className={classes.containerResponsive}>
//                                         <div style={{ marginTop: '6vh' }}></div>
//                                         <div>
//                                             {/* <img src={CancelImage} style={{ height: 57, width: 62 }} /> */}
//                                             <img src={Capture} style={{ height: 58, width: 60 }} />
//                                         </div>
//                                         <div>
//                                             <Typography className={classes.title} variant="h5" color="textSecondary" gutterBottom>
//                                                 {props.RiaOffLineDetails.message != undefined && props.RiaOffLineDetails.message != null && props.RiaOffLineDetails.message != "" ? props.RiaOffLineDetails.message : labels.messages.NA}
//                                             </Typography>
//                                         </div>
//                                         <div>
//                                             <Typography className={classes.titleTransaction} color="textSecondary" gutterBottom>
//                                                 {labels.riaPlanComplete.TransactionNumber}: {props.RiaOffLineDetails.data != undefined && props.RiaOffLineDetails.data != "" && props.RiaOffLineDetails.data != null ? props.RiaOffLineDetails.data.paymentID : 0}
//                                             </Typography>
//                                         </div>
//                                         <div className={classes.containerResponsiveButton1}>
//                                             <div style={{}}></div>
//                                             <div style={{}}></div>
//                                         </div>
//                                         <div>
//                                             <Typography className={classes.titleTransaction} color="textSecondary" gutterBottom>
//                                                 {labels.riaPlanComplete.AmountPaid}: Rs.{props.RiaOffLineDetails.data != undefined && props.RiaOffLineDetails.data != null && props.RiaOffLineDetails.data != "" ? props.RiaOffLineDetails.data.amount : 0}
//                                             </Typography>
//                                         </div>

//                                         <div style={{ marginTop: '6vh' }}></div>
//                                     </div>
//                                     <div style={{ marginTop: '5vh' }}>
//                                         <div>
//                                             <Typography className={classes.titleTransaction} color="textSecondary" gutterBottom>
//                                                 {labels.riaPlanComplete.InvoiceHasBeenSent}
//                                             </Typography>
//                                         </div>
//                                         <div className={classes.containerResponsiveButton}>
//                                             <div>

//                                             </div>
//                                             <div style={{ width: 150 }}>
//                                                 <Buttons onClick={dasBoard} page='primary' name={labels.riaPlanComplete.Dashboard} />
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </>
//                         }
//                     </div>
//                 </div>
//             </ErrorBoundary>
//         )
//     } catch (err) {
//         return <Redirect to={labels.Path.NotFound} />
//     }
// }
// const mapStateToProps = state => ({
//     RiaOffLineDetails: state.RiaOffLinePayment.riaFlowOffLinePaymentOrder != null ? state.RiaOffLinePayment.riaFlowOffLinePaymentOrder.length != 0 ? state.RiaOffLinePayment.riaFlowOffLinePaymentOrder : [] : [],
//     purchasedPlans: state.ProfileDetails.purchasedPlan != null ? state.ProfileDetails.purchasedPlan.length != 0 ? state.ProfileDetails.purchasedPlan : [] : [],
//     logindetails: state.Login.logindetails,
//     formNo: state.Login.formNo,

//     mainDetails: state.ProfileDetails.AllProfileDetails.mainDetails != "NA" ? state.ProfileDetails.AllProfileDetails.mainDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.mainDetails : [] : [],
//     riaOnLinePaymentDetails: state.RiaOnLinePaymentDetails.riaOnLinePaymentDetailsOrder != null ? state.RiaOnLinePaymentDetails.riaOnLinePaymentDetailsOrder.length != 0 ? state.RiaOnLinePaymentDetails.riaOnLinePaymentDetailsOrder : [] : [],
//     StoreGstNumberBankDetails: state.StoreGstNumberBankDetails.storeGstAndBankDetails != null ? state.StoreGstNumberBankDetails.storeGstAndBankDetails.length != 0 ? state.StoreGstNumberBankDetails.storeGstAndBankDetails : [] : [],
// })
// const mapDispatchToProps = dispatch => {
//     return bindActionCreators({ getRiaOnLinePaymentDetailsReport, getRiaFlowOffLinePaymentReport }, dispatch)
// }
// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RiaPlanComplete))
// import React from "react";
const title = [{ header: "Bill Range" }, { header: "Month Charge" }, { header: "CGST" }, { header: "SGST" }, { header: "IGST" }, { header: "Total" }]
const style = () => ({
    grid: {
        display: "grid",
        gridTemplateColumns: "46% 14% 10% 9% 9% 12%",

        '& .title0': {
            //alignItems: "left !important",
            justifyContent: "flex-start"
        }
        , "& .dataOne": {
            textAlign: "justify"
        }
    }
})
class RiaPlanComplete extends React.Component {
    constructor() {
        super();
        this.state = {
            onlineData: [],
            loaderOnlineData: false,
            url: "",
            open: false,
            message: "",
            severity: ""
        }
    }
    componentDidMount() {
        if (this.props.location.state != undefined) {
            if (this.props.location.state.data?.type == "online") {
                this.setState({ loaderOnlineData: true })
                var raw = JSON.stringify({
                    "ClientID": this.props.mainDetails[0]?.ClientID,
                    "MonthID": moment().endOf("month").format("DD MMM YYYY"),
                    "PlanID": this.props.location.state.data.details.PlanID,
                    "name": this.props.mainDetails[0]?.ClientName,
                    "email": this.props.mainDetails[0]?.EmailID,
                    "mobile": this.props.mainDetails[0]?.MobileNo,
                    "planname": this.props.location.state.data.details.plan_name,
                    "description": "",
                    "amount": this.props.location.state.data.details.recurring_price.toString()
                });

                getBillingDetails(raw).then((res) => {
                    if (res.status == "S" && replaceNull(res.data, "NA") != "NA") {
                        this.setState({
                            url: res.url,
                            onlineData: replaceNull(res.data, "NA").filter((val) => val.billRange.toLowerCase() != "total").map((item) => {
                                return { dataOne: item.billRange, dataTwo: NegativeFinding(item.selectMonthCharge, "", "", "", "rupee"), dataThree: NegativeFinding(item.cgst, "", "", "", "rupee"), dataFour: NegativeFinding(item.sgst, "", "", "", "rupee"), dataFive: NegativeFinding(item.igst, "", "", "", "rupee"), dataSix: NegativeFinding(item.total, "", "", "", "rupee") }
                            })
                        })
                    } else {
                        this.setState({ onlineData: [] })
                    }
                    this.setState({ loaderOnlineData: false })

                })
            }
        }
    }
    handlesnanckClose = () => {
        this.setState({ open: false, message: "", severity: "" })
    }
    payNow = () => {
        if (this.state.url.length != 0) {
            window.open(this.state.url)
        } else {
            this.setState({ open: true, message: "Payment Link was not generated..please try after sometime", severity: "warning" })
        }
    }
    back = () => {
        this.props.history.push(labels.Path.MyPlan, {
            state: { data: true }
        })
    }
    render() {
        const { classes } = this.props
        return (
            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 10 }}>
                    <img src={Capture} style={{ height: 49, width: 54, }} />
                    <div style={{ fontSize: 17, fontWeight: "bold" }}>Subscribed Successfully</div>
                    <div style={{ textAlign: "center", fontSize: "medium", color: "darkslategrey" }}>We are delighted to welcome you to our exclusive subscription service! Thank you for choosing to become a valued member of our community. Get ready to embark on an exciting journey with us!</div>

                </div>
                {this.props.location.state.data?.type == "online" ?
                    <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>

                        <div>
                            <h4 style={{ textAlign: "left" }}>Plan Details</h4>
                            <Epfreport showNoDataAvailable={true} showHeader={false} title={title} grid={classes.grid} data={this.state.onlineData} loader={this.state.loaderOnlineData}></Epfreport>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "end" }}>
                                <OutlinedButtons text="Pay Now" onClick={this.payNow}></OutlinedButtons>
                            </div>
                        </div>
                    </div> : <div style={{ display: "grid", gridTemplateColumns: "20% 60% 20%", marginTop: 15 }}>
                        <div></div>
                        <div style={{ display: "grid", gridTemplateColumns: "10% 80% 10%" }}>
                            <div></div>
                            <div style={{ display: "flex", flexDirection: "column", borderRadius: 10, padding: 10, gap: 10, boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px", border: `1px solid ${colors.BORDER}` }}>
                                <div style={{ display: "grid", gridTemplateColumns: "35% 5% 60%", textAlign: "left" }}>
                                    <span style={{ color: colors.LABLE_GRAY }}>Plan Name </span>
                                    <span style={{ textAlign: "center" }}>:</span>
                                    <span style={{ textAlign: "left", fontWeight: "bold" }}>{this.props.location.state.data.details.plan_name}</span>
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "35% 5% 60%", textAlign: "left" }}>
                                    <span style={{ color: colors.LABLE_GRAY }}>Amount to be Paid </span>
                                    <span style={{ textAlign: "center" }}>:</span>
                                    <span style={{ textAlign: "left", fontWeight: "bold" }}>{NegativeFinding(this.props.location.state.data.details.recurring_price, "", "", "", "rupee")}</span>
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "35% 5% 60%", textAlign: "left" }}>
                                    <span style={{ color: colors.LABLE_GRAY }}>Payment Mode </span>
                                    <span style={{ textAlign: "center" }}>:</span>
                                    <span style={{ textAlign: "left", fontWeight: "bold" }}>Offline</span>
                                </div>
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: 5, marginTop: 5 }}>
                                    <OutlinedButtons text="Back to Plan" onClick={this.back}></OutlinedButtons>
                                </div>
                            </div>
                            <div></div>
                        </div>
                        <div></div>
                    </div>}
                <Snackbar open={this.state.open} message={this.state.message} severity={this.state.severity} handlesnanckClose={this.handlesnanckClose} ></Snackbar>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    RiaOffLineDetails: state.RiaOffLinePayment.riaFlowOffLinePaymentOrder != null ? state.RiaOffLinePayment.riaFlowOffLinePaymentOrder.length != 0 ? state.RiaOffLinePayment.riaFlowOffLinePaymentOrder : [] : [],
    purchasedPlans: state.ProfileDetails.purchasedPlan != null ? state.ProfileDetails.purchasedPlan.length != 0 ? state.ProfileDetails.purchasedPlan : [] : [],
    logindetails: state.Login.logindetails,
    formNo: state.Login.formNo,

    mainDetails: state.ProfileDetails.AllProfileDetails.mainDetails != "NA" ? state.ProfileDetails.AllProfileDetails.mainDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.mainDetails : [] : [],
    riaOnLinePaymentDetails: state.RiaOnLinePaymentDetails.riaOnLinePaymentDetailsOrder != null ? state.RiaOnLinePaymentDetails.riaOnLinePaymentDetailsOrder.length != 0 ? state.RiaOnLinePaymentDetails.riaOnLinePaymentDetailsOrder : [] : [],
    StoreGstNumberBankDetails: state.StoreGstNumberBankDetails.storeGstAndBankDetails != null ? state.StoreGstNumberBankDetails.storeGstAndBankDetails.length != 0 ? state.StoreGstNumberBankDetails.storeGstAndBankDetails : [] : [],
})
export default withRouter(withStyles(style)(connect(mapStateToProps, null)(RiaPlanComplete)))