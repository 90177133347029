import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ToggleButton from '../toggle/toggle';
import OutlinedButtons from '../payment/button';
import Button from '../custombutton/button'
import Dropdown from '../dropdown/dropdownschemes';
import './popup.css'
import { alphabetSpaceOnly, NameValidation } from '../../utils/commonfunction'
import Textinput from '../textinput/textinput';
import Delete from '../../utils/assets/delete.png';
import Edit from '../../utils/assets/edit.png';
import Text from '../text/text';
import { getAddNomineeDetails, deletenomineedetails } from '../../redux/actions/profile/profile';
import getAddNominee from '../../api/profile/addnominee'
import deleteNominee from '../../api/profile/deletenominee'
import Loader from '../../components/loader/loader';
import NoData from '../../components/nodata/nodata'
import CommonSnackbar from '../snackbar/snackbar';
import color from "../../utils/colors/colors";
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import { numbersOnly } from '../../utils/commonfunction';
import ErrorBoundary from '../Errorhandling/Errorhandle';
import DownArrow from '../../utils/assets/downArrow.png';
import HoverDownArrow from '../../utils/assets/downArrow1.png';
import UpArrow from '../../utils/assets/upArrow.png';
import HoverUpArrow from '../../utils/assets/upArrow1.png';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom'
import Deletepopup from '../popup/deletepopup';
const styles = theme => ({
    heading1: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "larger",
        fontWeight: 600,
        textAlign: "left"
    },
    heading2: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: "left"
    },
    col: {
        display: "flex",
        flexDirection: "column",
        //   marginLeft: '2%',
        [theme.breakpoints.down('sm')]: {
            marginLeft: '-4%',
        },
        [theme.breakpoints.down('600')]: {
            marginLeft: '1%',
        },
        [theme.breakpoints.down('350')]: {
            marginLeft: '-5px',
        },
        [theme.breakpoints.between('601', '999')]: {
            marginLeft: '0.1%'
        }
    },
    columnAlign: {
        marginTop: '1%',
        width: "46.5%",
        //  marginLeft: '2%',
        display: "flex",
        flexDirection: "column",
        //  marginLeft: '2%',
        [theme.breakpoints.down('sm')]: {
            //  marginLeft: '-4%',
        },
        [theme.breakpoints.down('xs')]: {
            // marginLeft: '2%',
            width: "90%"
        }
    },
    cols: {
        marginTop: '1%',
        display: "flex", flexDirection: "row",
        [theme.breakpoints.down('xs')]: {
            flexWrap: 'wrap',
            justifyContent: 'center',
            flexDirection: "column",
        },
    },
    colheader1: {
        color: window.globalConfig.color.PRIMARY_BORDER,
        fontSize: "14px",
        textAlign: "left",
    },
    colheader2: {
        textAlign: "left",
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "14px"
    },
    colheader3: {
        textAlign: "center",
        color: window.globalConfig.color.PRIMARY_BORDER,
        fontSize: "11px",
    },
    header: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: window.globalConfig.color.WHITE,
        height: 50,
        fontWeight: 500
    },
    txtHeader: {
        padding: 15,
    },
    dialogPaper: {
        height: 'auto',
        width: 517,
        [theme.breakpoints.down('md')]: {
            width: '58%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '80%',
            height: 'auto',
        }
    },
    mainCard: {
        //  marginTop: "3%", paddingLeft: "4%"
    },
    nomineeContainer: {
        display: "flex", flexDirection: "row", marginTop: "3%", fontWeight: 500,
        [theme.breakpoints.down('xs')]: {
            whiteSpace: 'nowrap',
        },
    },
    imageContainer: {
        flex: "1%", justifyContent: "flex-start", marginLeft: "68%",
        [theme.breakpoints.down('xs')]: {
            marginLeft: "50%",
            marginRight: "6%",
        },
    },
    image: {
        height: 20,
        width: 20,
        marginLeft: "16%",
        cursor: "pointer"
    },
    nomineeCard: {
        // display: "flex",
        // flexDirection: "row",
        // justifyContent: "space-between",
        // paddingRight: "20%",
        //  margin: "3% 0 2% 0",
        display: 'grid',
        gridTemplateColumns: 'repeat(3,1fr)',
        gap: '1%',
        marginBottom: '2%',
        // width: "61vw",
        [theme.breakpoints.down('xs')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2,50%)',
            gridRowGap: '15%',
            gap: '1%',
            marginBottom: '0',
        },
    },
    cardSpareaterLine: {
        height: 0.5,
        width: "96.2%",
        backgroundColor: window.globalConfig.color.BORDER,
        marginTop: "1%",
        [theme.breakpoints.down('xs')]: {
            marginTop: '10%',
        },
    },
    addNomineeCard: {
        marginTop: "4%"
    },
    alignTop: {
        marginTop: "2%",
    },
    widthSize: {
        width: "100%",
    },
    majorNomineeConatiner: {
        marginLeft: "2%",
        marginTop: "2%",
        [theme.breakpoints.down('600')]: {
            marginLeft: "5%",
            marginTop: "2%",
        },
        // paddingLeft: "3%", paddingBottom: "3%", paddingRight: "3%", paddingTop: "5%"
    },
    majorNomineeCard: {
        display: "flex", flexDirection: "row", marginBottom: "4%",
        //   marginLeft: '2%',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'left',
            marginTop: '4%',
            marginBottom: 0
        },
    },
    majorNomineeCardResponsive: {
        display: "flex", flexDirection: "row",
        marginBottom: "4%",
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            marginTop: '0',
        },
    },
    nomineeTextSpan: {
        marginRight: "4%", color: window.globalConfig.color.LABLE_GRAY, fontSize: 14
    },
    allocation: {
        display: "flex",
        flexDirection: "row",
        marginTop: "4%",
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
            flexDirection: "column",
            flexWrap: 'wrap',
            // padding: '0% 3% 0% 1%',
        },
    },
    nomineeEnterText: {
        //  marginLeft: '2%',
        display: "flex", flexDirection: "column", marginRight: "4%",
        width: "46.5%",
        [theme.breakpoints.down('xs')]: {
            width: "90%",
        },
    },
    nomineeGuardianDiv: {
        [theme.breakpoints.up('600')]: {
            marginTop: '1%',
            marginLeft: "4.7%",
            width: "46%",
        },
        [theme.breakpoints.down('xs')]: {
            // marginLeft: "-1%",
            marginTop: '4%',
            width: "90%"
        },
    },
    emptySpacebtn: {
        display: "flex", flex: "0.5 0.5 42%",
    },
    footerbtn: {
        marginTop: '3.8%',
        //marginRight: '4.5%',
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        marginRight: "4%",
        [theme.breakpoints.down('xs')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2,1fr)',
            columnGap: '3%',
        },
    },
    footerDivText: {
        marginBottom: "3%", marginTop: "3%"
    },
    button: {
        width: 'auto',
        padding: "7px 22px",
        borderRadius: '22px',
        borderColor: window.globalConfig.color.PRIMARY_BLUE,
        color: window.globalConfig.color.PRIMARY_BLUE,
        textTransform: "none"
    },
    clearIcon: {
        float: 'right',
        cursor: 'pointer'
    },
    nomineeName: {
        //  marginLeft: "2%",
        [theme.breakpoints.down('xs')]: {
            marginLeft: "-2%",
        },
    },
    noDatastyle: {
        marginTop: '7%',
        marginBottom: '7%',
        marginLeft: '-5%'
    },
    marginRighAlign: {
        // marginLeft: '5%',
        width: "46.5%",
        [theme.breakpoints.down('xs')]: {
            marginTop: '3%',
            marginLeft: '0%',
            width: "90%"
        },
    },
    imageDesign: {
        width: 30,
        height: 30,
        cursor: "pointer"
    },
});
class NomineeDetails extends React.Component {
    isComponentMounted = false;
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            openbankpopup: false,
            selected: false,
            nomineename: "",
            guardianname: "",
            allocationpercentage: "",
            RelationtypeID: 0,
            ID: 0,
            Relationtype: "",
            errornomineename: "",
            errorguardianname: "",
            errorallocationpercentage: "",
            errorRelationtype: "", open: false,
            flag: "Add",
            popupname: "Add Nominee",
            openvalidation: false,
            downArrow: DownArrow,
            showDataCount: 3,
            showAllDatas: false,
            showUpArrow: false,
            upArrow: UpArrow,
            nomineeloader: false,
            message: "",
            severity: "",
            nomineedata: {

            },
            nomineedeleteloader: false,
            opendeletepopup: false,
            buttonname: "Add "

        }
    }
    async componentDidMount() {
        this.isComponentMounted = true
    }
    componentWillUnmount() {
        this.isComponentMounted = false
    }
    EditClick = (editvalue) => {
        // 
        // 
        var relationshipID = 0
        if (editvalue.Relation != "NA") {
            relationshipID = this.props.RelationtypeList.filter((item) => item.label == editvalue.Relation.toUpperCase())[0].value

        }
        this.setState({
            flag: "update", popupname: "Update Nominee", buttonname: "Update",
            nomineename: editvalue.NomineeName, Relationtype: editvalue.Relation, RelationtypeID: relationshipID, ID: editvalue.NomineeID,
            allocationpercentage: editvalue.AllocationPercentage, selected: editvalue.NomineeGuardian != "" && editvalue.NomineeGuardian != "NA" ? true : false, guardianname: editvalue.NomineeGuardian != "NA" ? editvalue.NomineeGuardian : "", openbankpopup: true
        })

    }
    DeleteClick = () => {
        this.setState({ nomineedeleteloader: true })
        //   
        deleteNominee(this.state.nomineedata.NomineeID, this.props.logindetails.ClientID).then((res) => {
            if (res.status == "S") {
                this.setState({ nomineedeleteloader: false, open: true, severity: "success", message: res.message, opendeletepopup: false })
                this.props.deletenomineedetails(this.state.nomineedata.NomineeID, this.props.logindetails.ClientID)

                // this.props.deletenomineedetails(1, this.state.nomineedata.NomineeID)
            } else {

                this.setState({ nomineedeleteloader: false, severity: "warning", open: true, message: res.message, opendeletepopup: false })

            }
        })
    }
    AddClick = () => {
        this.setState({
            nomineename: "", Relationtype: "", RelationtypeID: "", ID: 0, flag: "Add", popupname: "Add Nominee",
            allocationpercentage: "", selected: false, guardianname: "", openbankpopup: true, errornomineename: "", errorguardianname: "", errorRelationtype: "", errorallocationpercentage: ""
        })
    }
    Addnominee = () => {

        let nomineeNameValid = false;
        let nomineeRelationValid = false;
        let nomineeAllocationValid = false;
        let nomineeguardianValid = false;
        let allocationValidation = this.props.nomineeDetails.reduce(function (cnt, o) { return cnt + o.AllocationPercentage; }, 0)

        if (this.state.nomineename == "") {
            nomineeNameValid = false
            this.setState({ errornomineename: labels.messages.required })
        } else {
            nomineeNameValid = true
        }
        if (this.state.Relationtype == "") {
            nomineeRelationValid = false
            this.setState({ errorRelationtype: labels.messages.required })
        } else {
            nomineeRelationValid = true
        }
        //  
        let nomineeTotalpercentage = this.props.nomineeDetails.filter((response) =>
            response.NomineeID != this.state.ID && response.UCC === this.props.Ucc).reduce(function (accumulator, item) {
                return accumulator + item.AllocationPercentage
            }, 0);
        if (this.state.allocationpercentage == "") {
            nomineeAllocationValid = false
            this.setState({ errorallocationpercentage: labels.messages.required })
        } else if (!numbersOnly(this.state.allocationpercentage)) {
            nomineeAllocationValid = false
            this.setState({ errorallocationpercentage: labels.messages.allocationErrorMsg })
        } else if (this.state.allocationpercentage < 1 || this.state.allocationpercentage > 100) {
            nomineeAllocationValid = false
            this.setState({ errorallocationpercentage: labels.messages.allocationErrorMsg })
        }
        else if (nomineeTotalpercentage + Number(this.state.allocationpercentage) > 100) {
            nomineeAllocationValid = false
            //  
            this.setState({ message: `Already you have allocated ${nomineeTotalpercentage}%, max allocation should be 100% .`, open: true, severity: "warning" })
        }
        else {
            nomineeAllocationValid = true
        }

        if (this.state.selected) {
            if (this.state.guardianname == "") {
                nomineeguardianValid = false
                this.setState({ errorguardianname: labels.messages.required })
            } else {
                nomineeguardianValid = true
            }
        } else {
            nomineeguardianValid = true
        }
        if (nomineeNameValid &&
            nomineeRelationValid &&
            nomineeAllocationValid &&
            nomineeguardianValid) {
            this.setState({ nomineeloader: true })
            getAddNominee(this.state.ID, this.props.logindetails.ClientID, 0, this.state.nomineename, this.props.accountno, this.state.RelationtypeID, Number(this.state.allocationpercentage), this.state.selected, this.state.selected ? "" : this.state.guardianname, this.state.Relationtype).then((data) => {
                if (data.status == "S") {
                    this.props.getAddNomineeDetails(data.id, this.props.logindetails.ClientID, 1, this.state.nomineename, this.state.RelationtypeID, Number(this.state.allocationpercentage), this.state.selected, this.state.guardianname, this.state.Relationtype, this.state.flag, this.props.Ucc, this.props.accountno)
                    this.setState({ open: true, message: `Nominee ${this.state.ID === 0 ? "added" : "updated"} successfully`, severity: "success" })
                    this.setState({ openbankpopup: false, nomineename: '', errornomineename: '', Relationtype: '', errorRelationtype: '', allocationpercentage: '', errorallocationpercentage: '', guardianname: '', errorguardianname: '' })
                    this.setState({ nomineeloader: false })
                    this.setState({
                        //  selected: !this.state.selected,
                        errornomineename: "",
                        errorguardianname: "",
                        errorallocationpercentage: "",
                        errorRelationtype: "",
                        nomineename: "",
                        guardianname: "",
                        allocationpercentage: "",
                        Relationtype: ""
                    })


                } else {
                    this.setState({ openbankpopup: false, nomineename: '', errornomineename: '', Relationtype: '', errorRelationtype: '', allocationpercentage: '', errorallocationpercentage: '', guardianname: '', errorguardianname: '' })
                    this.setState({ nomineeloader: false })
                    this.setState({ open: true, message: data.message, severity: "warning" })
                }
            })
            // if (allocationValidation > 100 || this.props.nomineeDetails.length > 3 ||allocationValidation+Number(this.state.allocationpercentage)>100) {
            //     this.setState({ openvalidation: true })
            // } else {
            //

            // }
        }


    }
    componentDidUpdate(prevProps) {
        const { loader } = this.props;
        if (prevProps.loader != loader) {

        }
    }
    handlebankclose = () => {
        this.setState({ openbankpopup: false, nomineename: '', errornomineename: '', Relationtype: '', errorRelationtype: '', allocationpercentage: '', errorallocationpercentage: '', guardianname: '', errorguardianname: '' })
    }
    handlebankclick = () => {
        this.setState({ openbankpopup: false, })
    }
    handleallocation = (event) => {
        var number = /^\d*$/
        if (numbersOnly(event.target.value) && event.target.value.length < 4) {
            this.setState({ allocationpercentage: event.target.value, errorallocationpercentage: "" })
        }
        else {
            event.preventDefault();
        }
    }
    handleguardian = (event) => {
        var number = new RegExp("^[a-zA-Z ]*$");
        if (alphabetSpaceOnly(event.target.value) && event.target.value.length < 31) {
            this.setState({ guardianname: event.target.value, errorguardianname: "" })
        }
        else {
            event.preventDefault();
        }
    }
    handlenominee = (event) => {
        var number = new RegExp("^[a-zA-Z ]*$");
        if (alphabetSpaceOnly(event.target.value) && event.target.value.length < 31) {
            this.setState({ nomineename: event.target.value, errornomineename: "" })
        }
        else {
            event.preventDefault();
        }

    }

    handlerelationtype = (event) => {
        this.setState({ Relationtype: event[0].label, errorRelationtype: "", RelationtypeID: event[0].value })
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    }
    Onswitchchange = () => {
        this.setState({
            selected: !this.state.selected,
            errornomineename: "",
            errorguardianname: "",
            errorallocationpercentage: "",
            errorRelationtype: ""
        })

    }
    handlesnanckClosevalidation = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ openvalidation: false })
    }
    // added by yogitha
    MouseHover = () => {
        this.setState({
            downArrow: HoverDownArrow
        })
    }
    MouseLeave = () => {
        this.setState({
            downArrow: DownArrow
        })
    }
    upMouseHover = () => {
        this.setState({
            upArrow: HoverUpArrow
        })
    }
    upMouseLeave = () => {
        this.setState({
            upArrow: UpArrow
        })
    }
    ShowDatas = () => {
        this.setState({
            //showDataCount : this.state.showDataCount + 1
            showAllDatas: true,
            showUpArrow: true
        })
    }
    HideDatas = () => {
        this.setState({
            //showDataCount : this.state.showDataCount + 1
            showAllDatas: false,
            showUpArrow: false
        })
    }
    render() {
        // let nomineeTotalpercentage = this.props.nomineeDetails.filter((response) =>
        //     response.NomineeID != this.state.ID && response.L5ID === this.props.accountno).reduce(function (accumulator, item) {
        //         return accumulator + item.AllocationPercentage
        //     }, 0);

        //    const nomineeDetails = this.state.showAllDatas == false ? this.props.nomineeDetails.slice(0, this.state.showDataCount) : this.props.nomineeDetails
        const { classes } = this.props;
        try {
            return (
                <ErrorBoundary>
                    <CommonSnackbar handlesnanckClose={this.handlesnanckClosevalidation} message={labels.nomineeDetails.Therecanbe3Nominees} open={this.state.openvalidation} severity={"warning"} />
                    {/* {this.props.logindetails.KYCFormNo != undefined && this.props.logindetails.KYCFormNo != "" && this.props.logindetails.KYCFormNo != 0 ? */}
                    <div className={classes.mainCard} >
                        <div className={classes.nomineeName}>
                            <Text label={labels.nomineeDetails.NomineeDetails}></Text>
                        </div>
                        {this.props.isLoading ? <Loader page="tablerow" /> :

                            this.props.nomineeDetails.length != 0 && this.props.nomineeDetails.filter((item) => item.UCC === this.props.Ucc).length != 0 ?
                                this.props.nomineeDetails.filter((item) => item.UCC === this.props.Ucc).map((nomineedetail, index) => {
                                    return (
                                        <div key={index}>
                                            <div className={classes.col} >
                                                <div className={classes.nomineeContainer}>
                                                    <div style={{ whiteSpace: "nowrap" }}>
                                                        {labels.nomineeDetails.Nominee} {index + 1}
                                                    </div>
                                                    {/* <div className={classes.imageContainer} onClick={() => this.EditClick(nomineedetail)}>
                                                        <img src={Edit} className={classes.image}></img>
                                                    </div>
                                                    <div style={{ marginRight: '4.2%' }} onClick={() => { this.setState({ opendeletepopup: true, nomineedata: nomineedetail }) }}>
                                                        <img src={Delete} className={classes.image}></img>
                                                    </div> */}
                                                </div>
                                                <div className={classes.nomineeCard}>
                                                    <Text label={labels.nomineeDetails.NomineeName} text={nomineedetail.NomineeName} page={"normal"} />
                                                    <Text label={labels.nomineeDetails.Nomineerelationship} text={nomineedetail.Relation} page={"normal"} />
                                                    <Text label={labels.nomineeDetails.Allocation} text={nomineedetail.AllocationPercentage} page={"normal"} />
                                                    {nomineedetail.IsNomineeMinor &&
                                                        <div  >
                                                            <Text label={labels.nomineeDetails.NomineeGuardianName} text={nomineedetail.NomineeGuardian} page={"normal"} />
                                                        </div>
                                                    }
                                                </div>

                                                <div className={classes.cardSpareaterLine}>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <div style={{ marginTop: "6%" }}>
                                    <div className={classes.noDatastyle}  ><NoData /></div>
                                    <span className={classes.colheader1}>{labels.nomineeDetails.Youhavenotadded}</span>
                                </div>

                        }

                        {/*                        
                      
                            <div className={classes.addNomineeCard}>
                                <div className={classes.alignTop}>
                                    <OutlinedButtons disabled={this.props.nomineeDetails.filter((item) => item.L5ID === this.props.accountno).length < 3 &&nomineeTotalpercentage<100?false:true} text={"Add Nominee"} onClick={this.AddClick} />


                                </div>
                                <div className={classes.footerDivText}>
                                    <span className={classes.colheader3}>
                                        {labels.nomineeDetails.Therecanbe3Nominees}
                                    </span>
                                </div>
                            </div> */}
                        {/* {this.props.status != "" && */}
                        <CommonSnackbar handlesnanckClose={this.handlesnanckClose} message={this.state.message} open={this.state.open} severity={this.state.severity} />
                        {/* ///} */}
                        {/* <Dialog
                            //   disableScrollLock={true}
                            open={this.state.openbankpopup}
                            onClose={this.handlebankclose}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                            classes={{ paper: classes.dialogPaper }}
                        // style={{ backgroundColor: ' }}
                        >
                            <div className={classes.widthSize}>
                                <div className={classes.header}>
                                    <div className={classes.txtHeader}>{this.state.popupname}
                                        <span className={classes.clearIcon} onClick={() => { this.setState({ openbankpopup: false,nomineename:'',errornomineename:'',Relationtype:'',errorRelationtype:'',allocationpercentage:'',errorallocationpercentage:'',guardianname:'',errorguardianname:'' }) }}>&#x2715;</span>
                                    </div>
                                </div>
                                <div className={classes.majorNomineeConatiner}>
                                    <div className={classes.majorNomineeCard}>
                                        <span className={classes.nomineeTextSpan} >{labels.nomineeDetails.NomineeisMajor}</span>
                                        <ToggleButton label1={"Yes"} label2={"No"} selected={this.state.selected}
                                            toggleSelected={this.Onswitchchange} />
                                    </div>
                                    <div className={classes.cols} >
                                        <div className={classes.nomineeEnterText} >
                                            <Textinput handleChange={this.handlenominee} value={this.state.nomineename} error={this.state.errornomineename} page={"normal"} label={labels.nomineeDetails.NomineeName} placeholder={labels.nomineeDetails.Enternameofnominee} />
                                        </div>
                                        <div className={classes.marginRighAlign}>
                                            <Dropdown
                                                //isSearchable={true}
                                                autoselect={false}
                                                height={135}
                                                id={1}
                                                loader={this.props.loaderdropdown}
                                                // searchPlaceholder={"Search Bank Name"}
                                                value={this.state.Relationtype.toUpperCase()}
                                                type={"normalwithsearch"}
                                                handleOnChange={this.handlerelationtype}
                                                List={this.props.RelationtypeList}
                                                placeholder={labels.nomineeDetails.SelectRelationship}
                                                label={labels.nomineeDetails.HoldersrelationshipwithNominee}
                                                error={this.state.errorRelationtype}
                                            />
                                            {/* <Dropdown onChange={this.handlerelationtype} 
                                            values={this.state.Relationtype} error={this.state.errorRelationtype}
                                             page={"normal"}
                                              placeholder={labels.nomineeDetails.SelectRelationship} 
                                             label={labels.nomineeDetails.HoldersrelationshipwithNominee} 
                                             list={this.props.RelationtypeList} /> */}
                        {/* </div>
                                    </div>
                                    <div className={classes.allocation} >
                                        <div className={classes.columnAlign}>
                                            <Textinput
                                                handleChange={this.handleallocation}
                                                value={this.state.allocationpercentage}
                                                error={this.state.errorallocationpercentage}
                                                page={"normal"} label={labels.nomineeDetails.Allocation} placeholder={labels.nomineeDetails.EnterAllocation} />
                                        </div>
                                        {!this.state.selected ? <div className={classes.nomineeGuardianDiv} >
                                        </div> : <div className={classes.nomineeGuardianDiv} >
                                            <Textinput handleChange={this.handleguardian} value={this.state.guardianname} error={this.state.errorguardianname} page={"normal"} label={labels.nomineeDetails.NomineeGuardianName} placeholder={labels.nomineeDetails.EnterGuardiansName} />
                                        </div>}
                                    </div>
                                </div>
                                <div className={classes.majorNomineeCardResponsive} >

                                    <div className={classes.footerbtn}>
                                        <div style={{ marginRight: "18px" }}>
                                            <Button page={"secondary"} name={labels.nomineeDetails.Cancel} onClick={() => { this.setState({ openbankpopup: false,nomineename:'',errornomineename:'',Relationtype:'',errorRelationtype:'',allocationpercentage:'',errorallocationpercentage:'',guardianname:'',errorguardianname:'' }) }} />
                                        </div>
                                        <div >
                                            <Button page={this.state.nomineeloader ? "secondary" : "primary"} show={this.state.nomineeloader} name={this.state.popupname} disabled={this.state.nomineeloader} onClick={this.Addnominee} loader={this.state.nomineeloader} loderAlign="align" /> */}

                        {/* <Button page={"primary"} name={this.state.popupname} loader={this.state.nomineeloader} onClick={this.Addnominee} /> */}
                        {/* </div>

                                    </div>
                                </div>
                            </div>
                        </Dialog> */}
                    </div>
                    {/* // : <div className={classes.mainCard} >
                        //     <div className={classes.nomineeName}>
                        //         <Text label={labels.nomineeDetails.NomineeDetails}></Text>
                        //     </div>
                        //     <div style={{ marginTop: "6%" }}>
                        //         <span className={classes.colheader1}>{"You have not added the nominee"}</span>
                        //     </div>
                        // </div>} */}
                    {/* <Deletepopup onClick={() => { this.DeleteClick() }} loader={this.state.nomineedeleteloader} header={"Delete Nominee"} open={this.state.opendeletepopup} text={"Are you sure you want to delete?"} onClose={() => { this.setState({ opendeletepopup: false }) }} /> */}
                </ErrorBoundary>
            );
        } catch (err) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => ({
    loaderdropdown: state.Dropdown.loaderdropdown,
    RelationtypeList: state.Dropdown.dropdowndetails.relationShipList != null ? state.Dropdown.dropdowndetails.relationShipList.length != 0 ? state.Dropdown.dropdowndetails.relationShipList.map((item) => {
        return { "label": item.RelationShip, "value": item.ID }
    }) : [] : [],
    nomineeDetails: state.ProfileDetails.AllProfileDetails.length == 0 ? [] : state.ProfileDetails.AllProfileDetails.nominee != "NA" ? state.ProfileDetails.AllProfileDetails.nominee.length != 0 ? state.ProfileDetails.AllProfileDetails.nominee : [] : [],
    loader: state.ProfileDetails.loaderprofile,
    logindetails: state.Login.logindetails,
    status: state.ProfileDetails.status,
    isLoading: state.ProfileDetails.isLoading,
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getAddNomineeDetails, deletenomineedetails }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NomineeDetails))