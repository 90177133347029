import * as types from '../../constants/actionsType';
// import getRiskScoreReportDetails from "../../../api/riskscoreapi/riskscoreapi";
import getRiaQuestionResponseDetails from "../../../api/riaquestionresponseapi/riaquestionresponseapi"
export function getRiskScoreReport(data) {
    // 
    return (dispatch) => {
        getAllRiskScoreReport(dispatch, data);
    };
}
function getAllRiskScoreReport(dispatch, data) {
    // dispatch({
    //     type: types.EMPTY_RISK_SCORE,
    //     payload: []
    // });
    dispatch({
        type: types.LOADER_RISK_SCORE,
        payload: []
    });

    // getRiskScoreReportDetails(set_id, email, form_data).then((data) => {
    //     dispatch({
    //         type: types.RISK_SCORE,
    //         payload: [],
    //     });
    //     // 
    //     if (data != undefined) {           
    //         // let response = JSON.parse(data)

    //         if (data.status_type == "Success") {
    //  
    // This dispatch is a First API  Dispatch
    dispatch({
        type: types.RISK_SCORE,
        payload: data
    });

    //Second API call - Using First API call Parameter 
    //This API Response is used for Stored a Data Database only
     // json stringify removed by dulcy
    getRiaQuestionResponseDetails(data).then((data) => {
        dispatch({
            type: types.RIA_QUESTION_RESPONSE,
            payload: [],
        });

        //
        if (data != undefined) {
            if (data.data.status_type == "Success") {
                // 
                dispatch({
                    type: types.RIA_QUESTION_RESPONSE,
                    payload: data
                });
            }
            else {
                //  
                dispatch({
                    type: types.RIA_QUESTION_RESPONSE,
                    payload: [],
                });
            }
        }
        else {
            // 
            dispatch({
                type: types.RIA_QUESTION_RESPONSE,
                payload: [],
            });
        }
    })
        .catch((error) => {
            dispatch({
                type: types.RIA_QUESTION_RESPONSE,
                payload: [],
            });
        })
    //-------------- Second API Completed --------------------\\

}
            // else if (data.status_type == "Error") {
            //     // Without Finnovate Users are used Error status_type come
            //     dispatch({
            //         type: types.RISK_SCORE,
            //         payload: data
            //     });
            // }
            // else {
            //     dispatch({
            //         type: types.RISK_SCORE,
            //         payload: [],
            //     });
            // }
    //     }
    //     else {
    //         dispatch({
    //             type: types.RISK_SCORE,
    //             payload: [],
    //         });
    //     }

    // })
    //     .catch((error) => {
    //         dispatch({
    //             type: types.RISK_SCORE,
    //             payload: [],
    //         });
    //     })

// }