import React from 'react';
import Button from '../custombutton/button'
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import colors from '../../utils/colors/colors';
import color from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import DatePicker from '../../components/datepickers/date_pickers';
import CommonDropdown from '../../components/dropdown/dropdownschemes';
import Textinput from '../../components/textinput/textinput';
import moment from 'moment';
import { numbersandDeciamlOnly,amountCommaSeperator,replaceComma, hasNull, round, notAllowdSpecialCharater } from '../../utils/commonfunction';
import CommonSnackbar from '../../components/snackbar/snackbar';
import { getL5reportDetails } from "../../redux/actions/L5Report/L5ReportAction";
import { bindActionCreators } from 'redux';
import getAddSellRealEstate from '../../api/real_estate_dataentey/add_sell_real_estate';
import getAddSellBullion from '../../api/real_estate_dataentey/add_sell_bullion';
import getValuationRealEstate from '../../api/real_estate_dataentey/valution_realestate';
import getValuationBullion from '../../api/real_estate_dataentey/valuation_bullion';
import { getRealEstateList, getBullionList } from '../../redux/actions/DataEntry/real_estate_report';
import { Listing_RealEstate, Listing_Bullion } from '../../redux/actions/DataEntry/real_estate_report';
const styles = theme => ({

    col: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.between('320', '374')]: {
            marginTop: '4%',
            width: '94%',
        },
        [theme.breakpoints.between('375', '424')]: {
            marginTop: '4%',
            width: '96%',
        },
        [theme.breakpoints.between('425', '600')]: {
            marginTop: '4%',
            width: '98%',
        },
    },
    cols: {
        marginLeft: '2%',
        marginTop: '5%',
        width: '100%',
        display: "flex", flexDirection: "row",
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: ' wrap',
            justifyContent: 'center',
            marginLeft: '0%',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: ' wrap',
            justifyContent: 'center',
        },
    },
    // col3: {
    //     display: "flex",
    //     flexDirection: "row",modified by dulcy
    //     marginLeft: "20%"
    // },
    colheader1: {
        color: window.globalConfig.color.PRIMARY_BORDER,
        fontSize: "14px",
        textAlign: "left"
    },
    colheader2: {
        textAlign: "left",
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "14px"
    },

    header: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: window.globalConfig.color.WHITE,
        height: 50,
        fontWeight: 500,
        position:"sticky",
        top:"0px"
    },
    txtHeader: {
        padding: 15,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    dialogPaper: {
        width: "400px",
        // height: "174px",
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    dialogPaper2: {
        width: "auto",
        height: "auto",
    },
    footerStyle: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        bottom: 0,
        right: "18px",
        // position: "absolute",
        paddingBottom: 10,
        justifyContent: "flex-end"
    },
    clearIcon: {
        cursor: "pointer"
    }
});
class AddSell extends React.Component {
    constructor() {
        super();
        this.state = {
            open: false,
            date: "",
            dateTemp: moment().format("DD MMM YYYY"),
            dateError: "",
            amount: "",
            amountError: "",
            transactionType: "",
            transactionTypeName: "",
            transactionTypeError: "",
            anchorEl: null,
            flag: "",
            message: "",
            severity: "success",
            openSnackBar: false,
            id: '',
            openSellPopup: false,
            sellUpdate: "",
        }
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };
    onClosePopup = () => {
        this.setState({
            open: false,
            date: "",
            dateTemp: moment().format("DD MMM YYYY"),
            dateError: "",
            amount: "",
            amountError: "",
            transactionType: "",
            transactionTypeName: "",
            transactionTypeError: "",
            anchorEl: null,
            flag: "",
            message: "",
            severity: "success",
            openSnackBar: false,
            loader: false,
            addSaveMore: false
        })
        this.props.onClose()
    }
    handleDateClick = () => {
        this.setState({ anchorEl: true })
    }
    handleDateApply = (value) => {
        this.setState({ date: moment(value).format("DD MMM YYYY"), dateError: "" })
    }
    handleDateChange = (value) => {
        this.setState({ dateTemp: moment(value).format("DD MMM YYYY") })
    }
    handleDateClose = () => {
        this.setState({ anchorEl: null })
    }
    handleTransactionType = (value) => {
        this.setState({ transactionType: value[0].value, transactionTypeName: value[0].label, transactionTypeError: "" })
    }
    handleAmountChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnly(removeComma.trim(), 14)) {
            this.setState({ amount: amountCommaSeperator(removeComma.trim()), 
                amountError: "" })
        }
    }
    clearState = () => {
        this.setState({
            date: "",
            dateTemp: moment().format("DD MMM YYYY"),
            dateError: "",
            amount: "",
            amountError: "",
            transactionType: "",
            transactionTypeName: "",
            transactionTypeError: "",
            anchorEl: null,
            loader: false,
            addSaveMore: false,
            isDataEntryFlag: true,
            mindate: '',
        })

    }
    componentDidMount() {
        this.setState({
            flag: this.props.flag, id: this.props.id, Location: this.props.Location,
            mindate: this.props.ValuationsValueDate
        })
    }
    handleSaveMore = (type) => {
        let isValid = true
        if (this.state.amount.trim() == "") {
            isValid = false
            this.setState({ amountError: labels.messages.required })
        }
        if (this.state.date == "") {
            isValid = false
            this.setState({ dateError: labels.messages.required })
        }
        if (isValid) {
            if (this.props.location.pathname == "/realestate") {
                if (this.props.flag == "sell") {
                    this.setState({
                        openSellPopup: true,
                    })
                    let data = {
                        id: this.props.id,
                        clientID: this.props.logindetails.ClientID,
                        sellPrice: Number(replaceComma(this.state.amount)),
                        sellDate: this.state.date,
                        flag: 4,
                    }
                    getAddSellRealEstate(data).then((response) => {
                        this.setState({ deletePopupLoader: false })
                        if (response.status == "S") {
                            this.props.getRealEstateList(1, this.props.clientId)
                            this.onClosePopup()
                            this.setState({
                                openDeletePopup: false,
                                openSnackBar: true,
                                message: response.message,
                                severity: "success",
                                open: false,
                            })

                        }
                        else {
                            this.onClosePopup()
                            this.setState({
                                openDeletePopup: false,
                                openSnackBar: true,
                                message: response.message,
                                severity: "warning",
                                open: false,
                            })

                        }
                    })


                }
                else {
                    let data = {
                        clientID: this.props.logindetails.ClientID,
                        latestValue: Number(replaceComma(this.state.amount)),
                        latestValueDate: this.state.date,
                        realEstateID: this.props.id,
                        ValuationsTypeID: 1
                    }
                    getValuationRealEstate(data).then((response) => {
                        this.setState({ deletePopupLoader: false })
                        if (response.status == "S") {
                            this.props.getRealEstateList(1, this.props.clientId)
                            this.onClosePopup()
                            this.setState({
                                openDeletePopup: false,
                                openSnackBar: true,
                                message: response.message,
                                severity: "success",
                                open: false,
                            })

                        } else {
                            this.onClosePopup()
                            this.setState({
                                openDeletePopup: false,
                                openSnackBar: true,
                                message: response.message,
                                severity: "warning",
                                open: false,
                            })

                        }
                    })
                }

            }
            else {
                if (this.props.flag == "sell") {
                    let data = {
                        id: this.props.id,
                        clientID: this.props.logindetails.ClientID,
                        sellPrice: Number(replaceComma(this.state.amount)),
                        sellDate: this.state.date,
                        flag: 4,
                    }
                    getAddSellBullion(data).then((response) => {
                        this.setState({ deletePopupLoader: false })
                        if (response.status == "S") {
                            this.props.getBullionList(1, this.props.clientId)
                            this.onClosePopup()
                            this.setState({
                                openDeletePopup: false,
                                openSnackBar: true,
                                message: response.message,
                                severity: "success",
                                open: false,
                            })
                        } else {
                            this.onClosePopup()
                            this.setState({
                                openDeletePopup: false,
                                openSnackBar: true,
                                message: response.message,
                                severity: "warning",
                                open: false,
                            })
                        }
                    })
                }
                else {
                    let data = {
                        clientID: this.props.logindetails.ClientID,
                        latestValue: Number(replaceComma(this.state.amount)),
                        latestValueDate: this.state.date,
                        BullionID: this.props.id,
                        ValuationsTypeID: 1
                    }
                    getValuationBullion(data).then((response) => {
                        this.onClosePopup()
                        this.setState({ deletePopupLoader: false })
                        if (response.status == "S") {
                            this.props.getBullionList(1, this.props.clientId)
                            this.setState({
                                openDeletePopup: false,
                                openSnackBar: true,
                                message: response.message,
                                severity: "success",
                                open: false,
                            })
                     

                        } else {
                            this.onClosePopup()
                            this.setState({
                                openDeletePopup: false,
                                openSnackBar: true,
                                message: response.message,
                                severity: "warning",
                                open: false,
                            })
                        }
                    })
                }
            }
        }
    }

    componentDidUpdate(prev) {
        const { editList, flag, id, ValuationsValueDate } = this.props
        if (prev.editList != editList) {
            if (editList.length != 0) {
                this.setState({ amount: editList[0].amount, dateTemp: editList[0].transactionDate == "NA" || editList[0].transactionDate == "" ? moment().format("DD MMM YYYY") : editList[0].transactionDate, date: editList[0].transactionDate, transactionTypeName: editList[0].transactionType })
            }
            if (id.length != 0) {
                this.setState({ id: id })
            }
            if (ValuationsValueDate != "") {
                this.setState({
                    mindate: ValuationsValueDate
                })
            }
        }

    }
    handleFocus = (event) => event.target.select();
    handleToastClose = () => {
        this.setState({ openSnackBar: false })
        this.onClosePopup();
    }

    render() {
        const { classes } = this.props;
        return (<div>
            <Dialog
                open={this.props.open}
                onClose={this.onClosePopup}
                onScroll={this.props.onScroll}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: classes.dialogPaper }}
                style={this.props.style}

            >
                <div className={classes.popupcard}>
                    <div className={classes.header}>
                        <div className={classes.txtHeader}>{this.props.header}
                            <span className={classes.clearIcon} onClick={this.onClosePopup}>&#x2715;</span>
                        </div>
                    </div>
                    <div style={{ padding: 15, display: "flex", flexDirection: "column", gap: 15 }}>

                        <DatePicker
                            handleClick={(e) => this.handleDateClick(e)}
                            handleApply={this.handleDateApply}
                            showingdate={this.state.dateTemp}
                            dateChange={this.handleDateChange}
                            handleClose={this.handleDateClose}
                            anchorEl={this.state.anchorEl}
                            error={this.state.dateError}
                            mindate={this.props.ValuationsValueDate}
                            maxdate={moment().format("DD MMM YYYY")}
                            id="dp_aifInvestmentDate"
                            label={labels.PmsAndAif.date}
                            placeholder={labels.PmsAndAif.datePlaceholder}
                            date={this.state.date} />
                        <Textinput handleChange={this.handleAmountChange}
                            value={this.state.amount}
                            error={this.state.amountError}
                            align={"right"}
                            otherProps={{ autoFocus: true, name: "amount", onFocus: this.handleFocus, onBlur: this.handleDecimal }}

                            page={"normal"} label={labels.PmsAndAif.amount}
                            placeholder={labels.PmsAndAif.amountZeroPlaceholder}
                        />
                    </div>

                    <div className={classes.footerStyle}>
                        <div style={{ width: "22%", marginLeft: 10, marginRight: 15 }}>
                            <Button page={"secondary"} disabled={this.state.loader} name={labels.messages.cancel} onClick={this.onClosePopup} loader={this.props.loader} show={this.props.loader} loading={"true"} />
                        </div>
                        <div style={{ width: "22%", marginRight: 15 }}>
                            <Button page={"primary"} disabled={this.state.loader} name={labels.messages.saveButton} onClick={() => this.handleSaveMore("save")} loader={this.props.loader} show={this.props.loader} loading={"true"} />
                        </div>


                    </div>
                </div>
            </Dialog>
            <CommonSnackbar message={this.state.message} open={this.state.openSnackBar} severity={this.state.severity} handlesnanckClose={this.handleToastClose} />

        </div>
        )
    }
}
const mapStateToProps = state => ({
    login: state.Login.loginError,
    clientId: state.Login.logindetails.ClientID,
    logindetails: state.Login.logindetails,



})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getRealEstateList, getBullionList, getL5reportDetails }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddSell))

