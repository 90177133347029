import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from "../../utils/constants/labels"
import Networking from '../../utils/api/apiaccess';
function getRiaFlowOnLinePaymentReportDetails(display_name, plan_code, email, mobile, redirect_url) {
        let data = {};
        data.display_name = display_name,
        data.plan_code = plan_code,
        data.email = email,
        data.mobile = mobile,
        data.redirect_url = redirect_url     

    //     var config = {
    //       method: "post",
    //       url: ApiValues.RiaOnLineResponse,       
    //       headers: {            
    //            'Content-Type': 'application/json',
    //            'api-key': labels.apiKey.apiKeys,
    //       },
    //       data: data
    //   }; 
    //   return axios(config)
    //       .then(function (response) {     
    //           return response.data;
    //       })
    //       .catch(function (error) {        
    //           return { "status": "F", "message": labels.messages.apierror }
    //       });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    // var raw = JSON.stringify({
    //     "display_name": "KENNADY  DULCY NAVAROJ ",
    //     "plan_code": "AD30KH",
    //     "email": "dulcynavroj18@gmail.com",
    //     "mobile": "8220185362",
    //     "redirect_url": "https://et.finnovate.in/profile/paymentcomplete"
    // });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch("https://plan.finnovate.in/api/payment", requestOptions)
        .then(response => response.json())
        .then(result => { return result })
        .catch(error => { return { Staus: "F", message: "something went wrong" } });
}
export default getRiaFlowOnLinePaymentReportDetails