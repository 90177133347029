import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import Menu from "../../components/menu/menu";
import Usercard from "../../components/profile/usercard";
// import Footer from '../../components/footer/footer';
import { withRouter } from "react-router";
import SwitchDetails from "./switchdetails";
import SwitchReview from "./switchreview";
import Common from "../../components/commonpaymentconfirm/common";
import { amountCommaSeperator, AmountValidation, NegativeFinding, numbersandDeciamlOnly, replaceNull } from "../../utils/commonfunction";
import labels from "../../utils/constants/labels";
import { Redirect } from "react-router";
import getPortfolioxraySchemeDetails from "../../api/portfolioxrayschemedetail/portfolioxrayschemedetail";
import getSwitchapidetails from "../../api/switch/switchapi";
import constants from "../../utils/constants/apiconstant";
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import moment from "moment";
import { scrollToTopPage } from '../../utils/commonfunction';
import {
  SchemeCodeFinding,
  SchemeCodeSearching,
} from "../../utils/commonfunction";
import secureStorage from "../../utils/securestorage";
import CommonSnackbar from "../../components/snackbar/snackbar";
import color from "../../utils/colors/colors";
import IpaddressFinding from "../../api/ip_address/ip_address";
import {
  SchemeChange,
  TypeChange,
  RedemptionChange,
} from "../../redux/actions/filter/reportsfilter";
import { bindActionCreators } from "redux";
import colors from "../../utils/colors/colors";
import Deletepopup from "../../components/popup/deletepopup";
import getAllDeviceToken from "../../api/alert/getalldevicetoken";
import getSendPushNotification from "../../api/alert/sendpushnotification";
const styles = (theme) => ({
  collabel: {
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: "14px",
    textAlign: "left",
  },
  switchHeader: {
    height: 70,
    paddingLeft: "5.4%",
    paddingRight: "6.5%",
    // paddingTop: "5%",
    backgroundColor: window.globalConfig.color.PRIMARY,
    color: "red",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.between("600", "851")]: {
      height: 60,
      paddingTop: "10%",
    },
    [theme.breakpoints.down("600")]: {
      height: 60,
      marginTop: "10%",
      paddingLeft: "revert",
    },
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "10% 80% 10%",
    [theme.breakpoints.down("600")]: {
      gridTemplateColumns: "100%",

    }
  },

  switchText: {
    textAlign: "left",
    fontSize: 25,
    fontWeight: "bolder",
    color: window.globalConfig.color.WHITE,
    // marginTop: "1.6%",
    marginLeft: "6%",
  },
  btnApply: {
    background: window.globalConfig.color.brown,
    marginLeft: "7px",
    padding: 0,
    lineHeight: "36px",
    color: window.globalConfig.color.WHITE,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    width: "100px",
    float: "right",
    borderRadius: "5px",
    border: "1px solid transparent",
    "&:hover": {
      background: window.globalConfig.color.lightBlue,
      color: window.globalConfig.color.WHITE,
    },
  },
  btnBack: {
    background: window.globalConfig.color.RosyBrown,
    marginLeft: "7px",
    padding: 0,
    lineHeight: "36px",
    color: "black",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    width: "100px",
    float: "right",
    borderRadius: "5px",
    border: "1px solid transparent",
  },
  divMain: {
    display: "flex",
    flexDirection: "row",
    //margin: "3%",
    justifyContent: "center",
    marginTop: "3%",
    // marginLeft: "11%",
    // marginRight: "6%",//change 10% to 6% by Dulcy on 21-03-2022
    // marginBottom: "4%",
    // paddingBottom: "3%",
    [theme.breakpoints.down("920")]: {
      flexDirection: "column",
      margin: "3%"
    },

  },
  divMainResponsive: {
    flexDirection: "row",
    marginTop: "3%",
    marginLeft: "11%",
    marginRight: "10%",
    marginBottom: "4%",
    paddingBottom: "3%",
    [theme.breakpoints.down("920")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.down("600")]: {
      flexDirection: "column",
      marginLeft: "4%",
      marginRight: "4%",
      // width:'100%',
    },
  },
  divDetail: {
    height: "-webkit-fill-available",
    width: "63%",
    backgroundColor: window.globalConfig.color.WHITE,
    marginLeft: "5.5%",
    marginBottom: "4%",
    border: `1px solid ${window.globalConfig.color.WHITE}`,
    boxShadow: "0 0 5px 8px rgba(200, 200, 250, 0.1)",
    paddingBottom: "10px",
    borderRadius: 6,
    [theme.breakpoints.down("920")]: {
      width: "100%",
      margin: "auto",
      marginBottom: "4%",
    },
    // [theme.breakpoints.down("600")]: {
    //   width: "94%",
    // },
  },
  divDetail1: {
    height: "-webkit-fill-available",
    // width: "92%",
    backgroundColor: window.globalConfig.color.WHITE,
    border: `1px solid ${window.globalConfig.color.WHITE}`,
    boxShadow: "0 0 5px 8px rgba(200, 200, 250, 0.1)",
    paddingBottom: "30px",
    borderRadius: 6,
    [theme.breakpoints.down("600")]: {
      width: "100%",
    },
  },
  switchHeader1: {
    height: 86,
    marginTop: "4.5%",
    paddingLeft: "3.4%",
    paddingTop: "2%",
    backgroundColor: window.globalConfig.color.PRIMARY,
    color: "red",
    display: "flex",
    flexDirection: "column",
  },
  switchText1: {
    textAlign: "left",
    fontSize: 20,
    fontWeight: "bolder",
    color: window.globalConfig.color.WHITE,
    marginTop: "10.6%",
  },
  desktopResponsive: {

  },
  divDetailResponsive: {
    height: "-webkit-fill-available",
    width: "100%",
    backgroundColor: window.globalConfig.color.WHITE,
    marginTop: "3%",
    marginBottom: "11%",
    border: `1px solid ${window.globalConfig.color.BORDER}`,
    boxShadow: "0 0 10px 0 rgb(19 89 161 / 17%)",
    paddingBottom: "30px",
  },
  divDetail1Responsive: {
    height: "-webkit-fill-available",
    width: "100%",
    backgroundColor: window.globalConfig.color.WHITE,
    border: `1px solid ${window.globalConfig.color.BORDER}`,
    boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.3)",
    paddingBottom: "30px",
    marginBottom: "2em",
  },
  //Ended
});
let status = "";
class Switch extends React.Component {
  constructor() {
    super();
    this.state = {
      confimFlag: false,
      paymentFlag: false,
      open: false,
      schemecodeerror: "",
      type: "Amount (Rs)",
      Switchout: "",
      Switchin: "",
      // SchemeDetails: [],
      showOption: false,
      folionumber: "",
      SchemeID: 0,
      status: "",
      message: "",
      viewloader: false,
      FolioList: [],
      amount: "",
      units: "",
      erroramountmessage: "",
      errorunitsmessage: "",
      errorswitchinmessage: "",
      exitloadunits: "",
      exitloadamount: "",

      fromSchemeCd: "",
      toSchemeCd: "",
      toschemeID: "",
      toschemeData: [],
      toSchemeSelectedDataTable: [],
      selectedmappingDetails: "",
      orderID: "",
      longterm: "",
      shortterm: "",
      Totalavailableunits: "",
      exitunit: "",
      Additionaldata: "",
      BSESchemeType: "",
      totalTable: [],
      AMCID: "",
      UCC: "NA",
      data: {},
      toSchemeList: []
    };
  }

  handlesnanckClose = () => {
    this.setState({ open: false });
  };
  componentDidMount = async () => {

    await this.getFolioList(this.props.location.state.data.SchemeID)
    this.setState({
      Switchout: this.props.location.state.data.SchemeName,
      SchemeID: this.props.location.state.data.SchemeID,
    });
    this.setState({
      BSESchemeType: this.props.location.state.data.BSESchemeType,
      data: this.props.location.state.data,
      AMCID: this.props.location.state.data.AMCID,
    });
    scrollToTopPage();
  };
  getFolioList = async (SchemeID) => {
    await getPortfolioxraySchemeDetails(
      this.props.logindetails.ClientID,
      0,
      Number(SchemeID),
      false, "SWITCH"
    ).then((res) => {
      //
      if (res.length == 0) {
      } else {
        this.setState({
          folionumber: res.folioNumber[0].FolioNo,
          FolioList: res.folioNumber,
          UCC: res.folioNumber[0].MutualFundUCC,
        });
        if (res.totalTable.length != 0 && this.state.folionumber != "") {
          this.setState({ totalTable: res.totalTable });
          res.totalTable
            .filter((item) => item.FolioNo === this.state.folionumber)
            .map((value) => {
              return this.setState({ Additionaldata: value });
            });
        }
        if (res.schemeList.length != 0) {
          this.setState({
            toSchemeList: replaceNull(res.schemeList, "NA")?.map((item) => {
              return {
                label: item.SN,
                value: item.SI,
                data: item,
              }
            })
          })
        }
        if (res.dataTable != null) {
          if (res.dataTable?.length != 0) {
            let selectedMappingDetails = res.dataTable.filter((item) => {
              if (item.SwitchFLAG.trim() == labels.investmentPopup.yFlag) {
                return item;
              }
            });
            this.setState({ fromSchemeCd: selectedMappingDetails[0]?.SchemeCode });
          }
        }
      }
    });
  }
  onPlaceorder = async (value) => {
    var isValid = true;
    if (this.state.type === "Amount (Rs)") {
      var val = this.state.amount.replaceAll(",", "");
      var currancyvalue = val.replaceAll(labels.currency.rupeesymbol, "");
      if (currancyvalue < 1000) {
        isValid = false;
        this.setState({
          erroramountmessage: `Minimum Amount ${labels.currency.rupeesymbol} 1,000`,
        });
      } else {
        if (this.state.totalTable.length != 0) {
          let targetCurrentValue = this.state.totalTable.length != 0 && this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber).length != 0 ? this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalAvailableAmount || 0 : 0
          if (currancyvalue < targetCurrentValue) {
          } else {
            isValid = false;
            this.setState({
              erroramountmessage: `Amount Should be less than ${NegativeFinding(targetCurrentValue, "", "", "", "rupee")}`,
            });
          }
        } else {
          isValid = false
          this.setState({ open: true, schemecodeerror: labels.messages.apierror })
        }

      }
    } else if (this.state.type === "Custom No. of Units") {
      if (this.state.units < 10) {
        isValid = false;
        this.setState({ errorunitsmessage: `Minimum Units  10` });
      } else {
        if (this.state.totalTable.length != 0) {
          if (this.state.units < this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalAvailableUnits) {


          } else {
            isValid = false
            this.setState({ errorunitsmessage: `Units should be less than  ${NegativeFinding(this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalAvailableUnits || 0, "", "", "", "number")}` })

          }
        } else {
          isValid = false
          this.setState({ open: true, schemecodeerror: labels.messages.apierror })
        }

      }
    } else if (this.state.type === "All Exit Load Free Units") {
      if (this.state.totalTable.length != 0) {
        if (this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalExitLoadUnits != 0) {
        } else {
          isValid = false;
          this.setState({
            open: true,
            schemecodeerror: labels.messages.Zerovalidation,
          });
        }
      }
      else {
        isValid = false
        this.setState({ open: true, schemecodeerror: labels.messages.apierror })
      }
    } else if (this.state.type === "All Available No. of Units") {
      if (this.state.totalTable.length != 0) {
        if (this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalAvailableUnits != 0) {
        } else {
          isValid = false;
          this.setState({
            open: true,
            schemecodeerror: labels.messages.Zerovalidation,
          });
        }
      } else {
        isValid = false;
        this.setState({ open: true, schemecodeerror: labels.messages.apierror })

      }

    } else if (this.state.type === "All Short Term Gain Units") {
      if (this.state.totalTable.length != 0) {
        if (this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalShortTermUnits != 0) {
        } else {
          isValid = false;
          this.setState({
            open: true,
            schemecodeerror: labels.messages.Zerovalidation,
          });
        }
      }
      else {
        isValid = false;
        this.setState({ open: true, schemecodeerror: labels.messages.apierror })

      }

    } else if (this.state.type === "All Long Term Gain Units") {
      if (this.state.totalTable.length != 0) {
        if (this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalLongTermUnits != 0) {
        } else {
          isValid = false;
          this.setState({
            open: true,
            schemecodeerror: labels.messages.Zerovalidation,
          });
        }
      } else {
        isValid = false;
        this.setState({ open: true, schemecodeerror: labels.messages.apierror })

      }

    }
    if (this.state.Switchin == "") {
      isValid = false;
      this.setState({ errorswitchinmessage: labels.messages.required });
    }
    if (this.state.toschemeID != "") {
      let values = "";
      if (this.state.type === "Amount (Rs)") {
        values = Number(currancyvalue);
      } else {
        values = Number(this.state.totalTable.length == 0 ? 0 : this.state.totalTable.filter((item) => item.FolioNo == this.state.folionumber)[0]?.TotalAvailableAmount);
      }

      let validationMessage = await SchemeCodeFinding(
        currancyvalue == undefined || currancyvalue == "" ? this.state.totalTable?.filter((item) => item.FolioNo === this.state.folionumber)[0]?.TotalAvailableAmount : Number(currancyvalue),
        labels.investmentPopup.keySwitch,
        this.state.toschemeData.ISIN,
        this.state.toschemeData.RTAProdCode,
        this.props.logindetails.ClientID,
        "SWITCH", "", this.state.Switchin
      );
      if (validationMessage.status === labels.investmentPopup.successMsg) {

        let selectedMappingDetails = validationMessage.response
        await this.setState({
          selectedmappingDetails: selectedMappingDetails, toSchemeCd: selectedMappingDetails.SchemeCode
        });
      } else {
        isValid = false;
        this.setState({
          schemecodeerror: validationMessage.status,
          open: true,
        });
      }
    }
    if (isValid) {
      this.setState({
        confimFlag: true,
      });
    }
  };

  onchangefolio = (item) => {
    this.setState({ folionumber: item.FolioNo, UCC: item.MutualFundUCC });
  };
  handleClick = () => {
    this.setState({ showOption: !this.state.showOption });
  };
  Switchoutchange = async (event) => {
    let schemedetail = [];

    schemedetail =
      this.props.switchSchemeList != null &&
        this.props.switchSchemeList.length != 0

        ? this.props.switchSchemeList.filter(
          (item) => item.SchemeID == event[0].value
        )
        : []
    this.setState({
      BSESchemeType:
        schemedetail.length != 0 ? schemedetail[0]?.BSESchemeType : "",
      // AMCID: schemedetail.length != 0 ? schemedetail[0]?.AI : "",
      //  UCC: schemeUCC,
    });

    this.setState({
      data: schemedetail[0]

    });
    await this.getFolioList(event[0].value)

    this.setState({
      Switchout: event[0].label,
      SchemeID: event[0].value,
      Switchin: "",
    });
    this.props.SchemeChange(event, this.props.schemeList);
  };
  Switchinchange = async (val) => {

    if (val != null) {
      this.setState({
        Switchin: val.label,
        errorswitchinmessage: "",
        toschemeID: val.value,
        toschemeData: val.data
      });
    } else {
      this.setState({
        Switchin: "",
        toschemeID: "",
        toSchemeCd: ""
      });
    }
  };

  back = () => {
    this.setState({
      confimFlag: false,
    });
  };

  onClickPayment = (value) => {
    if (!this.state.viewloader) {
      var val = this.state.amount.replaceAll(",", "");
      var currancyvalue = val.replaceAll(labels.currency.rupeesymbol, "");
      if (this.state.fromSchemeCd != "" &&
        this.state.toSchemeCd != "" && this.state.selectedmappingDetails != "") {
        this.setState({ viewloader: true, openConfirmPopup: false });
        getSwitchapidetails(
          constants.Switch.OrderId,
          constants.Switch.MemberId,
          this.props.logindetails.ClientID,
          this.state.SchemeID,
          this.state.UCC,
          this.state.fromSchemeCd,
          this.state.toSchemeCd,
          constants.Switch.BuySell,
          constants.Switch.BuySellType,
          constants.Switch.DPTxn,
          Number(currancyvalue),
          Number(this.state.units) == null ? 0 : Number(this.state.units),
          this.state.type === "Amount (Rs)" ||
            this.state.type === "Custom No. of Units"
            ? "N"
            : "Y",
          constants.Switch.TransactionMode,
          this.state.folionumber,
          constants.Switch.KYCStatus,
          constants.Switch.FirstOrderToday,
          this.props.logindetails.SubBrokerCode,
          this.state.BSESchemeType === 1 ? "N" : constants.Switch.EUINDeclaration,
          constants.Switch.MinRedeem,
          this.state.BSESchemeType === 1 ? "N" : "Y",
          secureStorage.getItem("ip"),
          constants.Switch.PassKey,
          constants.Switch.Password,
          constants.Switch.Param1,
          constants.Switch.Param2,
          constants.Switch.Param3,
          this.state.BSESchemeType === 1 ? "" : constants.Common.EUINNumber,
          constants.Switch.Remarks,
          this.state.toschemeID,
          constants.Common.Source,
          constants.Switch.TransCode
        ).then(async (res) => {
          if (res.status == "S") {
            this.setState({
              paymentFlag: true,
              status: res.status,
              message: res.message,
              viewloader: false,
              orderID: res.orderID,
            });
          } else {
            this.setState({
              status: res.status,
              message: res.message,
              viewloader: false,
              paymentFlag: true,
              open: true,
              schemecodeerror: res.message
            });
          }
          if (this.props.logindetails.ClientID == 20427 || this.props.logindetails.ClientID == 21827) {
            // push notification on mobile.. commited by dulcy
            let data = {}
            data.ClientID = this.props.logindetails.ClientID
            await getAllDeviceToken(data).then(async (response) => {
              if (response.status == "S") {
                if (replaceNull(response.table1, "NA") != "NA") {

                  let params = {}
                  params.DeviceTokens = response.table1.map((item) => {
                    return item.DeviceToken
                  })
                  params.Title = res.status == "S" ? "Trading completed successfully" : "Trading failed"
                  params.Body = replaceNull(res.message, "NA")
                  await getSendPushNotification(params).then((response) => {

                  })
                }

              }
            })
          }
        });
      } else {
        this.setState({
          schemecodeerror: labels.investmentPopup.switchNotAllowed,
          open: true,
        });
      }
    }
  };

  typeChange = (event) => {
    this.setState({
      type: event[0].label,
      // amount: "",
      units: "",
      erroramountmessage: "",
      errorunitsmessage: "",
    });
    this.props.RedemptionChange(event, labels.TradingType);
  };
  Amountchange = (event) => {
    //Currency Rupees symbal and comma sepreater function created by yogitha on 16june 2021
    var val = event.target.value.replaceAll(",", "");
    var currancyvalue = val.replaceAll(labels.currency.rupeesymbol, "").trim();
    if (currancyvalue.trim() == "") {
      this.setState({ amount: "" });
    } else {
      if (!numbersandDeciamlOnly(currancyvalue.trim(), 16)) {
        event.preventDefault();
      } else {

        this.setState({ erroramountmessage: "", amount: `${labels.currency.rupeesymbol} ${amountCommaSeperator(currancyvalue)}` });
      }
    }
  };
  SwitchinTextchange = (event) => {
    this.setState({ Switchin: event.target.value });
  };
  unitschange = (event) => {
    if (AmountValidation(event.target.value)) {
      this.setState({ errorunitsmessage: "" });
      this.setState({ units: event.target.value });
    } else {
      event.preventDefault();
    }
  };
  render() {
    const { classes } = this.props;
    try {
      return (
        <>
          <div
            style={{ height: "auto", maxHeight: "auto" }}
            className={classes.desktopResponsive}
          >
            {/* <Menu childFunc={this.props.childFunc} /> */}
            <div className={classes.switchHeader}>
              <div className={classes.switchText}>{labels.switch.Switch}</div>
              <div style={{ display: "flex", opacity: this.state.viewloader ? 0.5 : 1, cursor: this.state.viewloader ? "not-allowed" : "pointer", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: "5%" }} onClick={() => {
                if (!this.state.viewloader) {
                  this.props.history.push({
                    pathname: labels.Path.MutualTab,
                    state: { tabValue: 1 }
                  })
                }
              }}>
                <ArrowBackIos
                  color={window.globalConfig.color.WHITE}
                  style={{ color: window.globalConfig.color.WHITE }}
                />
                <p
                  style={{
                    color: window.globalConfig.color.WHITE,

                  }}
                >
                  {"Back"}
                </p>
              </div>
            </div>
            {this.state.paymentFlag && (
              <div className={classes.divMain}>
                <div className={classes.divDetail1}>
                  <Common
                    page="Switch"
                    UCC={this.state.UCC}
                    status={this.state.status}
                    orderID={this.state.orderID}
                    Additionaldata={this.state.Additionaldata}
                    message={this.state.message}
                    folionumber={this.state.folionumber}
                    Switchout={this.state.Switchout}
                    Switchin={this.state.Switchin}
                    amount={this.state.amount}
                    units={this.state.units}
                    type={this.state.type}
                  ></Common>
                </div>
              </div>
            )}
            {!this.state.paymentFlag && (
              <div className={classes.grid}>
                <div></div>
                <div className={classes.divMain}>
                  <Usercard page={"switchDesktop"} />
                  <div className={classes.divDetail}>
                    {!this.state.confimFlag && (
                      <SwitchDetails
                        AMCID={this.state.AMCID}
                        data={this.state.data}
                        totalTable={this.state.totalTable}
                        Additionaldata={this.state.Additionaldata}
                        FullschemeList={this.props.FullschemeList}
                        FolioList={this.state.FolioList}
                        SwitchinTextchange={this.SwitchinTextchange}
                        SchemeID={this.state.SchemeID}
                        onchangefolio={this.onchangefolio}
                        folionumber={this.state.folionumber}
                        showOption={this.state.showOption}
                        handleClick={this.handleClick}
                        toSchemeList={this.state.toSchemeList}
                        // SchemeDetails={this.state.SchemeDetails}
                        errorswitchinmessage={this.state.errorswitchinmessage}
                        schemeList={this.props.schemeList}
                        units={this.state.units}
                        errorunitsmessage={this.state.errorunitsmessage}
                        unitschange={this.unitschange}
                        erroramountmessage={this.state.erroramountmessage}
                        amount={this.state.amount}
                        Amountchange={this.Amountchange}
                        Switchin={this.state.Switchin}
                        Switchout={
                          this.state.Switchout == ""
                            ? this.state.data.SchemeName
                            : this.state.Switchout
                        }
                        Switchoutchange={this.Switchoutchange}
                        Switchinchange={this.Switchinchange}
                        typeChange={this.typeChange}
                        type={this.state.type}
                        UCC={this.state.UCC}
                        onPlaceorder={(value) => this.onPlaceorder(value)}
                      />
                    )}
                    {this.state.confimFlag && (
                      <SwitchReview
                        loader={this.state.viewloader}
                        Additionaldata={this.state.Additionaldata}
                        folionumber={this.state.folionumber}
                        amount={this.state.amount}
                        units={this.state.units}
                        type={this.state.type}
                        UCC={this.state.UCC}
                        Switchin={this.state.Switchin}
                        Switchout={this.state.Switchout}
                        onPlaceorder={this.back}
                        confirmPayment={(value) => this.setState({ openConfirmPopup: true })}
                      />
                    )}
                  </div>
                </div>
                <div></div>
              </div>
            )}
          </div>
          <Deletepopup header={"Confirmation"} text={labels.messages.placeOrderMsg} open={this.state.openConfirmPopup} onClose={() => this.setState({ openConfirmPopup: false })} onClick={this.onClickPayment}>

          </Deletepopup>
          <CommonSnackbar
            timer={10000}
            open={this.state.open}
            severity={"warning"}
            message={this.state.schemecodeerror}
            handlesnanckClose={this.handlesnanckClose}
          />
        </>
      );
    } catch (err) {
      return <Redirect to={labels.Path.NotFound} />;
    }
  }
}
const mapStateToProps = (state) => {
  //
  return {
    switchSchemeList: state.PortfolioXray.XrayData.filter((ele, ind) => ind === state.PortfolioXray.XrayData.findIndex(elem => elem.Scheme === ele.Scheme))
      .sort(function (a, b) {
        var textA = a.Scheme.toUpperCase();
        var textB = b.Scheme.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
    ,
    FullschemeList:
      state.Dropdown.dropdowndetails.scheme != null
        ? state.Dropdown.dropdowndetails.scheme.length != 0
          ? state.Dropdown.dropdowndetails.scheme
            // .filter((item) => item.OneTimeAllowed == 1)
            .sort(function (a, b) {
              var textA = a.SN.toUpperCase();
              var textB = b.SN.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            })
            .map((val) => {
              return {
                label: val.SN,
                value: val.SI,
                data: val,
              };
            })
          : []
        : [],
    filterData:
      state.Dropdown.dropdowndetails.scheme != null
        ? state.Dropdown.dropdowndetails.scheme.length != 0
          ? state.Dropdown.dropdowndetails.scheme
          : []
        : [],
    logindetails: state.Login.logindetails,
    typelist: state.Filter.Redemptionlist,
    schemeList: state.PortfolioXray.XrayData.filter((ele, ind) => ind === state.PortfolioXray.XrayData.findIndex(elem => elem.Scheme === ele.Scheme))
      // .filter((item) => item.SwitchAllowed === true)
      .sort(function (a, b) {
        var textA = a.Scheme.toUpperCase();
        var textB = b.Scheme.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
      .map((val) => {
        return { label: val.Scheme, value: val.SchemeID, isChecked: false };
      }),
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { SchemeChange, TypeChange, RedemptionChange },
    dispatch
  );
};
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Switch))
);
