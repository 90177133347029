import * as types from '../../constants/actionsType';
import getRiskQuestionReportDetails from '../../../api/riskquestionapi/riskquestionapi'
export function getRiskQuestionReport(email,name,dob,mobile) {
   // 
    return (dispatch) => {
        getAllRiskQuestionReport(dispatch,email,name,dob,mobile);
    };
}
function getAllRiskQuestionReport(dispatch, email,name,dob,mobile) {
    dispatch({
        type: types.LOADER_RISK_QUESTION,
        payload: []
    });

    getRiskQuestionReportDetails(email,name,dob,mobile).then((data) => {
        dispatch({
            type: types.RISK_QUESTION,
            payload: [],
        });
        //  
        if (data != undefined) {
            if (data.status_type == "Success") {
                // 
                dispatch({
                    type: types.PREVENT_API_CALL,
                    payload:[{ProductID:0,api:"RiskQuestionReport"}],
                });     
                dispatch({
                    type: types.RISK_QUESTION,
                    payload: data                
                });         
              
            }
            else {
                //  
                dispatch({
                    type: types.RISK_QUESTION,
                    payload: [],
                });
                dispatch({
                    type: types.PREVENT_API_CALL,
                    payload:[{ProductID:0,api:"RiskQuestionReport"}],
                });    
            }
        }
        else {
            // 
            dispatch({
                type: types.RISK_QUESTION,
               payload: [],             
            });
            dispatch({
                type: types.PREVENT_API_CALL,
                payload:[{ProductID:0,api:"RiskQuestionReport"}],
            });      
        }
    })
        .catch((error) => {
            dispatch({
                type: types.RISK_QUESTION,
                payload: [],
            });
            dispatch({
                type: types.PREVENT_API_CALL,
                payload:[{ProductID:0,api:"RiskQuestionReport"}],
            });         
        })

}