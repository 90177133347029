// created by yogitha chart description
import React, { Component } from "react";
import colors from '../../utils/colors/colors';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import '../../utils/stylesheets/layout/layout.css'
const styles = theme => ({
  
    marginLeftpaper: {
        marginLeft: '5%',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('600')]: {
            marginLeft: '23%',
        },
        [theme.breakpoints.down('400')]: {
            marginLeft: '23%',
        },

    },
    gridBlock: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,72% 30%)',
        fontSize: 13,
        width: '15vw',
        marginBottom: '2%',
        [theme.breakpoints.down('600')]: {
            width: "34vw",
        },
        // [theme.breakpoints.down('600')]: {
        //     width: "44vw",
        // },
        // gridTemplateColumns:'repeat(2, 150px 66px)',
        columnGap: '2px',
    },
    // chartName:{
    //     width:150,
    //     [theme.breakpoints.down('md')]: {
    //         width:100,
    //     },
    //     [theme.breakpoints.down('600')]: {
    //         width:80,
    //     },

    // }

});
class CommonDescription extends Component {
    constructor() {
        super();
        this.state = {

        }
    }
    render() {
        const { classes } = this.props;
        return (
            <Grid item xs={5} className={classes.marginLeftpaper}>
                <Paper>
                    <Grid container spacing={3} className={"mycard"} style={{ textAlign: 'left', borderColor: window.globalConfig.color.PRIMARY_BORDER, borderWidth: 1, marginBottom: '10%' }}>
                        <div className={classes.gridBlock}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div className='bulletPointContainerBlock'>
                                    <div className='checkboxcontainer' style={{ borderColor: this.props.color, }}>
                                        <div className='checkboxfilldiv' style={{ backgroundColor: this.props.color }}>
                                        </div>
                                    </div>
                                </div>
                                <Tooltip title={this.props.name} placement="bottom" arrow >
                                    <div className={classes.chartName} >
                                        {this.props.name.length > 20 ? this.props.name.slice(0, 19) + "..." : this.props.name}
                                    </div>
                                </Tooltip>
                                
                            </div>
                            <strong style={{ textAlign: "right", whiteSpace: 'nowrap' }} >{this.props.value} % </strong>
                        </div>
                    </Grid>
                </Paper>
            </Grid>
        )
    }
}
export default withStyles(styles)(CommonDescription)