import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
//action created by dulcy
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
function UpdatePersonalDetails(ID, Nationality, CountryofBirth, PlaceofBirth, Occupation, AnnualIncome, PoliticalStatus, UserID) {


  var raw = JSON.stringify({
    "ID": ID,
    "Nationality": Nationality,
    "CountryofBirth": CountryofBirth,
    "PlaceofBirth": PlaceofBirth,
    "Occupation": Occupation,
    "AnnualIncome": AnnualIncome,
    "PoliticalStatus": PoliticalStatus,
    "UserID": UserID
  });
  //   var config = {
  //       method: 'post',
  //       url: ApiValues.UpdatePersonalDetails,
  //       headers: { 
  //         'Content-Type': 'application/json'
  //       },
  //       data : raw
  //     };

  //  return   axios(config)
  //     .then(function (response) {
  //     return response.data
  //     })
  //     .catch(function (error) {
  //       return{"status":"F","message":labels.messages.apierror}
  //     });
  return Networking.PostApi(ApiValues.UpdatePersonalDetails, raw).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default UpdatePersonalDetails
