import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "../../components/avatar/avatar";
import colors, { background_Green } from "../../utils/colors/colors";
import { Grid } from "@material-ui/core";
import Button from '../../components/custombutton/button';
import Label from "../../utils/constants/labels";
import labels from "../../utils/constants/labels";
import Avatars from "../../components/avatar/avatar";
import { NegativeFinding } from "../../utils/commonfunction";
import moment from "moment";
const styles = (theme) => ({
    card: {
        // width: "79%",
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "14px",
        flex: "4 1",
        fontWeight: 500,
        borderSpacing: "0px 3.5px",
        margin: '0% auto',
        borderRadius: '8px',
    },
    label: {
        color: window.globalConfig.color.LABLE_GRAY,
    },
    cardContainer: {
        display: 'grid',
        width: '100%',
        borderRadius: '8px',
        gridTemplateColumns: '6% 60% 9% 10% 12% 2%',
        // gap: '20px',
        alignItems: 'center',
        // padding:"5px !important",
        boxShadow: "0px 1px 2px 1px #e0e5eb",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "27% 50% 23%",
            gridTemplateAreas: `"${"firstColumn"} ${"secondColumn"} ${"sixthColumn"} "
      "${"firstColumn"} ${"secondColumn"} ${"sixthColumn"}"
      "firstSubColumn . secondSubColumn"
      "thirdSubColumn . fourthSubColumn"
      "${"thirdColumn"} . ${"fourthColumn"}"
      "${"fifthColumn"} ${"fifthColumn"} ${"fifthColumn"}"
      `,
            "& .secondSubColumn": {
                flexDirection: "column-reverse"
            },
            "& .fourthSubColumn": {
                flexDirection: "column-reverse",
            },
            "& .thirdSubColumn": {
                flexDirection: "column-reverse",
                alignItems: "center"

            },
            "&  .firstSubColumn": {
                // flexDirection: "column-reverse"
            },
            "& .thirdGrid": {
                alignItems: "end !important",
                textAlign: "end"
            },
            "& .fourthGrid": {
                alignItems: "start !important"
            },
            "& .fifthGrid": {
                marginTop: 10,
                alignItems: "center !important",
                textAlign: "center"
            },
            "& .sixthGrid": {
                alignItems: "end !important",
                textAlign: "end"
            },
            padding: 3,
            //gap: 10
        },
    },
    cardBasketContainer: {
        display: 'grid',
        width: '100%',
        borderRadius: '8px',
        gridTemplateColumns: '6% 65% 14% 14%',
        // gap: '20px',
        alignItems: 'center',
        // padding:"5px !important",
        boxShadow: "0px 1px 2px 1px #e0e5eb",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "27% 50% 23%",
            gridTemplateAreas: `"${"firstColumn"} ${"secondColumn"} ${"sixthColumn"} "
      "${"firstColumn"} ${"secondColumn"} ${"sixthColumn"}"
      "firstSubColumn . secondSubColumn"
      "thirdSubColumn . fourthSubColumn"
      "${"thirdColumn"} . ${"fourthColumn"}"
      "${"fifthColumn"} ${"fifthColumn"} ${"fifthColumn"}"
      `,
            "& .secondSubColumn": {
                flexDirection: "column-reverse"
            },
            "& .fourthSubColumn": {
                flexDirection: "column-reverse",
            },
            "& .thirdSubColumn": {
                flexDirection: "column-reverse",
                alignItems: "center"

            },
            "&  .firstSubColumn": {
                // flexDirection: "column-reverse"
            },
            "& .thirdGrid": {
                alignItems: "end !important",
                textAlign: "end"
            },
            "& .fourthGrid": {
                alignItems: "start !important"
            },
            "& .fifthGrid": {
                marginTop: 10,
                alignItems: "center !important",
                textAlign: "center"
            },
            "& .sixthGrid": {
                alignItems: "end !important",
                textAlign: "end"
            },
            padding: 3,
            //gap: 10
        },
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },

    rupees: {
        margin: "0px 0px 0px 0px !important",
        fontSize: "13px !important",
        fontWeight: "normal !important",
        color: `${window.globalConfig.color.LABLE_GRAY} !important`,
        padding: "4px 0px",
    },
    amountColumn: {
        margin: "0px 0px 0px 0px !important",
        fontSize: "14px !important",
        padding: "4px",
        textAlign: "right",
    },
    orderTypeColumn: {
        margin: "0px 0px 0px 0px !important",
        fontSize: "14px !important",
        // padding: "4px",
        textAlign: "left",
    },
    orderType: {
        margin: "0px 0px 0px 0px !important",
        fontSize: "11px !important",
        fontWeight: "normal !important",
        color: `${window.globalConfig.color.LABLE_GRAY} !important`,
        padding: "4px 4px",
        textAlign: "left",
    },
    schemeNamelabel: {
        textAlign: 'left',
    },
    titlesecondrowheading: {
        margin: "0px !important",
        padding: '5px 0px',
        fontSize: "11px !important",
        fontWeight: "normal !important",
        color: `${window.globalConfig.color.LABLE_GRAY} !important`,
        textAlign: 'left',
    },
    vertical: {
        borderLeft: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        margin: "0px 10px",
        height: "11px",
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
    firstrow: {
        display: "flex",
        textAlign: 'left',
        flexDirection: "column",
    },
    secondRow: {
        display: "inline-flex",
        textAlign: 'left',
        flexDirection: "row",
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    titlesecondrow: {
        margin: "0px !important",
        fontSize: "11px !important",
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontWeight: "normal",
    },
    openimage: {
        display: "inline-block",
    },
    columnDirection: {
        display: "flex", flexDirection: "column", gap: 5
    },
    rowDirection: {
        display: "flex", flexDirection: "row", gap: 5, alignItems: "center"
    },
    firstColumn: {
        [theme.breakpoints.down("920")]: {
            gridArea: "firstColumn"
        }
    },
    secondColumn: {
        textAlign: "left",
        [theme.breakpoints.down("920")]: {
            gridArea: "secondColumn"
        }
    },
    thirdColumn: {
        [theme.breakpoints.down("920")]: {
            gridArea: "thirdColumn"
        }
    }, fourthColumn: {
        [theme.breakpoints.down("920")]: {
            gridArea: "fourthColumn"
        }
    },
    fifthColumn: {
        [theme.breakpoints.down("920")]: {
            marginTop: 10,
            gridArea: "fifthColumn"
        }
    }, sixthColumn: {
        [theme.breakpoints.down("920")]: {
            gridArea: "sixthColumn"
        }
    },
    firstSubColumn: {
        fontSize: 10,
        [theme.breakpoints.down("920")]: {
            gridArea: "firstSubColumn"
        }
    },
    secondSubColumn: {
        fontSize: 10,
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down("920")]: {
            gridArea: "secondSubColumn"
        }
    },
    thirdSubColumn: {
        fontSize: 10,
        [theme.breakpoints.down("920")]: {
            gridArea: "thirdSubColumn"
        }
    }, fourthSubColumn: {
        fontSize: 10,
        [theme.breakpoints.down("920")]: {
            gridArea: "fourthSubColumn"
        }
    },
    fifthSubColumn: {
        fontSize: 12,
        [theme.breakpoints.down("920")]: {
            gridArea: "fifthSubColumn"
        }
    }, desktopResponsive: {
        display: "flex",
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
    mobileResponsive: {
        display: "none",
        [theme.breakpoints.down("920")]: {
            display: "flex"
        }
    }

});
class rowContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amcImageFlag: false,
        }
    };
    onError = (event) => {
        event.target.style.display = 'inline';
        this.setState({
            amcImageFlag: true,
        })
    }
    render() {
        const { classes } = this.props;
        return (
            <div className={[this.props.page == "basket" ? classes.cardBasketContainer : classes.cardContainer, this.props.cardClass].join(" ")} onClick={this.props.cartHandleClick}>
                <div className={[classes.firstColumn, "firstColumn"].join(" ")}>
                    <Avatars
                        color={this.props.color}
                        id={this.props.id}
                        url={this.props.amcImage}
                        avatar={this.props.schemeName.substring(
                            0,
                            2
                        )}
                    />
                </div>
                <div className={classes.columnDirection}>
                    <div className={[classes.secondColumn, "secondColumn"].join(" ")}>{this.props.schemeName}</div>
                    <div className={classes.rowDirection}>
                        <div className={[classes.rowDirection, classes.firstSubColumn, "firstSubColumn"].join(" ")}>
                            <span className={classes.label}> {this.props.folioNo}</span>
                            <span>{this.props.foliotype}</span>
                        </div>
                        {this.props.orderType == "SIP" && <> <div className={[classes.rowDirection, classes.desktopResponsive, classes.secondSubColumn, "secondSubColumn"].join(" ")}>
                            <div className={classes.vertical}></div>
                            <span className={classes.label}>{this.props.frequencyType} :</span>
                            <span>{this.props.frequency}</span>
                            <div className={classes.vertical}></div>
                        </div>
                            <div className={[classes.rowDirection, classes.desktopResponsive, classes.thirdSubColumn, "thirdSubColumn"].join(" ")}>
                                <span className={classes.label}>{this.props.DateTitle} :</span>
                                <span>{moment(this.props.Date, "DD MMM YYYY", true).isValid() ? this.props.Date : labels.messages.NA}</span>
                                <div className={classes.vertical}></div>
                            </div>
                            <div className={[classes.rowDirection, classes.desktopResponsive, classes.fourthSubColumn, "fourthSubColumn"].join(" ")}>
                                <span className={classes.label}>{this.props.InstalmentsNumber} :</span>
                                <span>{this.props.Instalments}</span>
                            </div></>}

                    </div>
                </div>
                <div className={[classes.rowDirection, classes.mobileResponsive, classes.secondSubColumn, "secondSubColumn"].join(" ")}>
                    <div className={classes.vertical}></div>
                    {/* <span>{this.props.frequencyType}</span> */}
                    <span style={{ fontSize: 12 }}>{this.props.frequency}</span>
                    <div className={classes.vertical}></div>
                </div>
                <div className={[classes.rowDirection, classes.mobileResponsive, classes.thirdSubColumn, "thirdSubColumn"].join(" ")}>
                    <span style={{ fontSize: 10, color: colors.LABLE_GRAY }}>{this.props.DateTitle}</span>
                    <span>{moment(this.props.Date, "DD MMM YYYY", true).isValid() ? this.props.Date : labels.messages.NA}</span>
                    <div className={classes.vertical}></div>
                </div>
                <div className={[classes.rowDirection, classes.mobileResponsive, classes.fourthSubColumn, "fourthSubColumn"].join(" ")}>
                    <span style={{ fontSize: 10, color: colors.LABLE_GRAY }}>{this.props.InstalmentsNumber}</span>
                    <span  >{this.props.Instalments}</span>
                </div>
                <div className={[classes.columnDirection, classes.thirdColumn, "thirdColumn"].join(" ")}><span>{this.props.orderType}</span>
                    <span style={{ fontSize: 12, color: colors.LABLE_GRAY }}>{this.props.orderTypeLable}</span>
                </div>
                <div className={[classes.columnDirection, classes.fourthColumn, "fourthColumn"].join(" ")}>
                    <span>{NegativeFinding(this.props.Amount, "", "", "", "rupee")}</span>
                    <span style={{ fontSize: 12, color: colors.LABLE_GRAY }}> {this.props.Amountlable}</span>
                </div>
                {this.props.page != "basket" && <div className={[classes.columnDirection, classes.fifthColumn, "fifthColumn"].join(" ")}>
                    <Button name={this.props.page == "cart" ? labels.cart.ConfirmOrder : "Move to Cart"} page={"primary"} disabled={this.props?.isLoading} onClick={this.props.confirmHandleCilck}></Button>

                </div>}
                {this.props.page != "basket" && <div className={[classes.columnDirection, classes.sixthColumn, "sixthColumn"].join(" ")} style={{ paddingLeft: "10px" }}>
                    {this.props.iconButton}
                </div>}
            </div>
        );
    }
}
export default withStyles(styles)(rowContainer)