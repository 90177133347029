import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from "react-router";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import { TableRow, TableFooter } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles';
import labels from '../../utils/constants/labels';
import NoData from '../nodata/nodata';
import { connect } from 'react-redux';
import { getPortfolio } from '../../redux/actions/portfolio/portfolioaction';
const COLORS = ['#F66D44', '#FEAE65', "#AADEA7"];
import { bindActionCreators } from 'redux';
import Loader from '../loader/loader'
import '../../utils/stylesheets/expandable-table/expandabletable.css'
import { NegativeFinding } from '../../utils/commonfunction';
import ErrorBoundary from '../Errorhandling/Errorhandle';
import secureStorage from '../../utils/securestorage'
import Theme from '../../utils/theme';
const themes = createTheme({
  overrides: {
    MuiTableCell: {
      root: {
        padding: '13px 10px',
        fontFamily: "Inter",
      },
    },
  },
});
const useRowStyles = makeStyles((theme) => ({

  root: {
    '& > *': {
      borderBottom: 'unset',
      fontFamily: "Inter",
    },
    cursor: "pointer"
  },

  backgroundrow: {
    backgroundColor: window.globalConfig.color.LIGHT_ROSE,
  },
  lineboader: {
    borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    cursor: "pointer"
  },
  //styles added by yogitha
  desktopResponsive: {
    display: 'block !important',

    [theme.breakpoints.down("600")]: {
      display: 'none !important',
    },
  },
  mobileResponsive1: {
    display: 'none !important',

    [theme.breakpoints.down("600")]: {
      display: 'block !important',
    },
  },
  mobileResponsive: {
    display: 'none !important',
    [theme.breakpoints.down("600")]: {

      display: 'flex !important',
      flexDirection: "row",
      overflowX: 'hidden !important'
    },
  },
  table: {
    width: '100%',
    marginLeft: 10,
    boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.06)",
    border: `1px solid ${window.globalConfig.color.BORDER}`,
    borderRadius: "10px!important",
    [theme.breakpoints.up("1024")]: {
      marginLeft: "9px !important",
      border: `1px solid ${window.globalConfig.color.BORDER}`,
      overflowX: " hidden !important",
    },
    [theme.breakpoints.up("1920")]: {
      marginLeft: "24px !important",
      border: `1px solid ${window.globalConfig.color.BORDER}`,
      overflowX: " hidden !important",
    },
    [theme.breakpoints.up("xs")]: {
      width: '100%',
      marginLeft: '0px !important',
    },

  },
  tableRow: {
    height: 10,
    width: 10,
    margin: 10,
    marginTop: 5,
    border: "double",
    borderColor: "aliceblue",

    [theme.breakpoints.up("1024")]: {
      height: "12px !important",
      width: "12px !important",
      margin: "6px !important",
      marginTop: "2px !important",
    },


  },
  checkBorder: {
    width: "15px",
    height: " 15px",
    margin: 10,
    marginTop: 5,
  },
  checkBox: {
    width: "11px",
    height: "11px",
    textAlign: 'center',
    // position: 'absolute',
    margin: 2
  },
  tableval: {
    color: window.globalConfig.color.BLACK_PRIMARY, fontWeight: 'bold',
    fontSize: 18,
    [theme.breakpoints.up("1024")]: {
      fontSize: "18px !important",
    },
    // [theme.breakpoints.down("xs")]:{
    //   lineHeight: "inherit !important",
    //   whiteSpace: "nowrap !important",
    // }
  },
  tablevalG: {
    fontWeight: 'bold',
    fontSize: 18,
    [theme.breakpoints.up("1024")]: {
      fontSize: "18px !important",
    }
  },


  tablevals: {
    fontWeight: 'bold',
    fontSize: 18
  },
  subrow: {
    color: window.globalConfig.color.LABLE_GRAY,
    fontsize: 12,
    fontWeight: 'normal'
  },
  row: {
    display: "flex",
    flexDirection: "row",
  },
  subrows: {
    color: window.globalConfig.color.GREEN,
    fontSize: 12,
    fontWeight: 'bold',
    paddingTop: "-30px"
  },
  footersubrows: {
    color: window.globalConfig.color.GREEN,
    fontSize: 10,
    fontWeight: 'bold',
    padding: 5
  },
  rowContents: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: 'center',
    gap: 2,
    lineHeight: "inherit !important",
    whiteSpace: "nowrap !important",
    [theme.breakpoints.down("xs")]: {
      // flex:'0 0 100% !important',
      lineHeight: "inherit !important",
      whiteSpace: "nowrap !important",
    }
  },
  rupee: {
    marginRight: 3,
    justifyContent: 'center',
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontsize: 20,
    marginTop: 0
  },
  footer: {
    backgroundColor: window.globalConfig.color.BLACK_PRIMARY,
    borderRadius: "20px 15px 15px 15px ",
    borderBottomRadius: "20px"

  },
  celltxt: {
    color: window.globalConfig.color.WHITE,
    fontWeight: 'bold',
    fontSize: 18
  },
  tablehead: {
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: 12,
    lineHeight: "inherit !important",
    whiteSpace: "nowrap !important",
    [theme.breakpoints.up("1024")]: {
      lineHeight: "inherit !important",
      whiteSpace: "nowrap !important",
    },
    // [theme.breakpoints.down("xs")]:{
    //   lineHeight: "inherit !important",
    //   whiteSpace: "nowrap !important",
    // },

  },
  subrowContents: {
    color: window.globalConfig.color.LABLE_GRAY,
    paddingLeft: 37,
    fontSize: 14,
    marginTop: -25,
    paddingLeft: "25px !important",
    [theme.breakpoints.up("1024")]: {
      paddingLeft: "36px !important",
      fontSize: "14 !important",
    }
  },
  subrowContent: {
    paddingLeft: 37,
    fontSize: 14,
    marginTop: -25,
    fontWeight: '500'
  },

  totalValue: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end"
    // marginTop:15,
  },
  ressponsiveHeader: {
    display: 'flex', flexDirection: 'row', justifyContent: 'flex-end',
  },
  responsivePadding: {
    paddingLeft: "22px",
    color: window.globalConfig.color.LABLE_GRAY,

  },
  responsiveInvester: {
    tableLayout: 'fixed', padding: "0px", border: '0px',
  },
  responsiveLable: {
    padding: "13px 25px",
  },
  responsiveFooterTxt: {
    width: '100%', right: 0, padding: 0, border: '0px',
  },
  reponsiveRow: {
    textAlign: 'right', paddingRight: '15px',
  },
  responsiveMarket: {
    justifyContent: 'flex-end', width: '100%', flex: '100%', margin: 0, padding: 0, display: 'flex', border: '0px'
  },
  responsiveInvestertxt: {
    width: '100%', padding: 0, margin: 0, display: 'flex', flex: '100%', justifyContent: 'flex-end', border: '0px'
  },
  tablevalResponsive: {
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontWeight: 'bold',
    fontSize: 14,
  },
  celltxt1: {
    color: window.globalConfig.color.LIGHT_ROSE,
    fontWeight: 'bold',
    fontSize: 18
  },
  celltxt2: {
    color: window.globalConfig.color.LIGHT_ROSE,
    fontWeight: 'bold',
    fontSize: 16
  },
  mobileResponsivefornodata: {
    [theme.breakpoints.up(601)]: {
      marginLeft: "34%"
    },
    [theme.breakpoints.between(600, 375)]: {
      marginLeft: "28px !important"
    },
    [theme.breakpoints.down(375)]: {
      marginLeft: "-15% !important",
      // marginRight:20
    }
  }
})

);
function Row(props) {
  const { row } = props;
  const classes = useRowStyles();
  // console.log(row)
  return (
    <React.Fragment >
      <TableRow className={classes.root} onClick={() => props.Redirection(row)}>
        <TableCell component="th" scope="row">
          <div className={classes.row}>
            <div className={classes.checkBorder} style={{ border: `1px solid ${row.color}` }} >
              <div className={classes.checkBox} style={{ backgroundColor: row.color }}></div>
            </div>
            <div className={classes.tableval}>{row.name}</div>
          </div>
        </TableCell>
        <TableCell align={"right"} >
          <div className={classes.rowContents}>
            <Typography className={classes.rupee}>{labels.currency.Rupee} {" "}</Typography>
            <Typography className={[classes.tableval, "tblCellPTag"].join(' ')}> {NegativeFinding(row.investValue, "", "", "", "number")}</Typography>
          </div>
        </TableCell>
        <TableCell align={"right"} >
          <div className={classes.rowContents}>
            <Typography className={classes.rupee}>{labels.currency.Rupee} {" "}</Typography>
            <Typography className={[classes.tableval, "tblCellPTag"].join(' ')}>{NegativeFinding(row.marketValue, "", "", "", "number")}</Typography>
          </div>
        </TableCell>
        <TableCell align={"right"} style={{ paddingRight: "18px" }}>
          <div className={classes.rowContents}>
            <Typography className={classes.rupee}>{labels.currency.Rupee}{" "} </Typography>
            <Typography className={[classes.tablevalG, "tblCellPTag"].join(' ')} style={{ color: NegativeFinding(Number(row.unrealised == undefined || row.unrealised == null || row.unrealised == "" || row.unrealised == "N.A" ? 0 : row.unrealised)) }}> {NegativeFinding(row.unrealised, "", "", "", "number")}</Typography>
          </div>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>

          <Typography className={classes.subrowContents}>{row.scheme}</Typography>

        </TableCell>
        <TableCell align={"right"} className={classes.subrow}></TableCell>
        <TableCell align={"right"} className={classes.subrow}></TableCell>
        <TableCell align={"right"} className={classes.subrows} style={{ paddingRight: "18px" }}>
          <Typography className={classes.subrowContent} style={{ color: " #38465F " }}>({NegativeFinding(row.xirrPercentage, "", "", "", "percentage")})</Typography>
        </TableCell>
      </TableRow>
    </React.Fragment >

  );
}
function ReponseRow(props) {
  const { row } = props;
  const classes = useRowStyles();

  return (
    <React.Fragment >
      <TableRow className={classes.lineboader} onClick={() => props.Redirection(row)}>
        {/* <div className={classes.lineboader}> */}
        <TableCell component="th" scope="row">
          <div className={classes.row}>
            <div className={classes.tableRow} style={{ background: row.color }}></div>
            <div className={classes.tableval}>{row.name}</div>
          </div>
        </TableCell>
        <TableCell className={classes.reponsiveRow}>
          <Table>
            <TableBody>
              <TableRow>
                {/* <div className={classes.reponsiveRow}> */}
                <TableCell className={classes.responsiveMarket} style={{ border: '0px' }} >
                  <Typography className={classes.rupee}>{labels.currency.Rupee}{" "}</Typography>
                  <Typography className="tblCellPTag1">{NegativeFinding(row.marketValue, "", "", "", "number")}</Typography>
                </TableCell>
                <TableCell className={classes.responsiveInvestertxt}>
                  <Typography className={classes.rupee}>{labels.currency.Rupee}{" "}</Typography>
                  <Typography className="tblCellPTag2">{NegativeFinding(row.investValue, "", "", "", "number")}</Typography>
                </TableCell>
                {/* </div> */}
              </TableRow>
            </TableBody>
          </Table>
        </TableCell>
        {/* </div> */}
      </TableRow>
    </React.Fragment >

  );
}
function PortfolioTable(props) {
  const classes = useRowStyles();
  useEffect(() => {
    // 

  }, []);
  let a = []
  let InvestmentValue = props.mfHeader2.length != 0 ? props.mfHeader2.filter((item) => item.firstName == "Invested Amount")[0]?.lastName : 0;
  let Marketvalue = props.mfHeader2.length != 0 ? NegativeFinding(props.Market,"","","","number") : 0;
  let Unrealisedvalue = props.mfHeader2.length != 0 ? props.mfHeader2.filter((item) => item.firstName == "Unrealized G/L")[0]?.originalData : 0;
  const Redirection = (item) => {
    // console.log(item.name)
    if (item.name.toLowerCase() === "mutual fund") {
      props.history.push(labels.Path.MutualTab)
    } else if (item.name.toLowerCase() === "stocks/etf" || item.name.toLowerCase() == "stocks / etfs" || item.name.toLowerCase() === "stocks and etf") {
      props.history.push(labels.Path.Equity)
    }
    else if (item.name.toLowerCase() === "gold") {
      props.history.push(labels.Path.GoldBondsTab)
    }
    else if (item.name.toLowerCase() === "aif/pms") {
      props.history.push(labels.Path.AIFTab)
    }
    else if (item.name.toLowerCase() === "bonds" || item.name.toLowerCase() == "traded bonds") {
      props.history.push(labels.Path.BondsTab)
    }
    else if (item.name.toLowerCase() === "fixed income") {
      props.history.push(labels.Path.FDTab)
    }
    else if (item.name.toLowerCase() === "bullion") {
      props.history.push(labels.Path.GoldBondsTab)
    }
    else if (item.name.toLowerCase() === "real estate") {
      props.history.push(labels.Path.RealEstateTab)
    }
  }
  // { console.log(props.portfolioSummary.filter((val) => val.ProductID <= 3)) }
  try {
    return (
      <ErrorBoundary>
        {/* {console.log(props.isLoadingportfolioSummary)} */}
        <Theme>
          {!props.isLoadingportfolioSummary ? props.portfolioSummary.length > 0
            ? (<div >
              <div style={{ width: '100%' }}>
                <div className={classes.desktopResponsive}>
                  <div className="tableRadius">
                    <TableContainer component={Paper} className={classes.table}>
                      <ThemeProvider theme={themes} >
                        <Table >
                          <TableHead>
                            <TableRow >
                              {/* start Changed by celsia */}
                              <TableCell align={"left"} style={{ paddingLeft: "22px" }} className={[classes.tablehead].join('')}>
                                {labels.portfolio.Assest}
                              </TableCell>
                              {/* end */}
                              <TableCell align={"right"} className={classes.tablehead}>{labels.portfolio.InvestedValue}</TableCell>
                              <TableCell align={"right"} className={classes.tablehead}>{labels.portfolio.MargetValue}</TableCell>
                              <TableCell align={"right"} style={{ paddingRight: "20px" }} className={classes.tablehead}>{labels.portfolio.Unrealized}</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className={classes.backgroundrow}>
                            {props.portfolioSummary.length > 0 && props.portfolioSummary.map((row) => (
                              <Row Redirection={Redirection} key={row.name} filcolor={COLORS[row]} row={row} Invested={props.Invested} Marget={props.Market} unrealised={props.Unrealisedvalue} percentageunrealised={props.percentageunrealised} />
                            ))}
                          </TableBody>
                          <TableFooter className={classes.footer}>
                            <TableRow>
                              <TableCell align={"left"} style={{ padding: "13px 25px" }} className={classes.celltxt}>{labels.ProfitLoss.Total}</TableCell>
                              <TableCell align={"right"} className={classes.celltxt}>
                                <div className={classes.rowContents}>
                                  <Typography className={classes.rupee}>{labels.currency.Rupee} </Typography>
                                  <Typography className={classes.celltxt}>
                                    {InvestmentValue?.toString()?.replace(labels.currency.rupeesymbol, "")}
                                  </Typography>
                                </div>
                              </TableCell>
                              <TableCell align={"right"} className={classes.celltxt}>
                                <div className={classes.rowContents}>
                                  <Typography className={classes.rupee}>{labels.currency.Rupee} </Typography>
                                  <Typography className={classes.celltxt}> {Marketvalue?.toString()?.replace(labels.currency.rupeesymbol, "")}</Typography>
                                </div>
                              </TableCell>
                              <TableCell align={"right"} className={classes.celltxt} style={{ paddingRight: "18px" }}>
                                <div className={classes.totalValue}>
                                  <Typography className={classes.rupee}>{labels.currency.Rupee} </Typography>
                                  <Typography className={classes.tablevals} style={{ color: NegativeFinding(Unrealisedvalue) }}> {NegativeFinding(Unrealisedvalue, "", "", "", "rupee")?.replace(labels.currency.rupeesymbol, "")}</Typography>
                                </div>
                              </TableCell>
                            </TableRow>
                          </TableFooter>
                        </Table>
                      </ThemeProvider>
                    </TableContainer>
                  </div>
                </div>

                <div className={classes.mobileResponsive}>
                  <div style={{ width: "7%" }}></div>
                  <TableContainer component={Paper} className={classes.table}>
                    <ThemeProvider theme={themes}>
                      <Table >
                        <TableHead>
                          <TableRow >
                            <TableCell align={"left"} className={classes.responsivePadding}>
                              {labels.portfolio.Assest}
                            </TableCell>
                            <TableCell className={classes.ressponsiveHeader}>
                              <div align={"right"} className={classes.tablehead}>
                                {labels.portfolio.MargetValue}
                                <div className={[classes.tablehead, classes.responsiveInvester].join(' ')}>
                                  {labels.portfolio.InvestedValue}
                                </div>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className={classes.backgroundrow}>
                          {props.portfolioSummary.length > 0 && props.portfolioSummary.map((row) => (
                            // <ReponseRow key={row.name} row={row} Invested={props.Invested} Marget={props.Market} />
                            <ReponseRow Redirection={Redirection} key={row.name} row={row} Invested={props.Invested} Marget={props.Market} unrealised={props.Unrealisedvalue} percentageunrealised={props.percentageunrealised} />

                          ))}
                        </TableBody>
                        <TableFooter className={classes.footer}>
                          <TableRow>
                            <TableCell align={"left"} style={{ padding: "13px 25px" }} className={classes.celltxt}>{labels.ProfitLoss.Total}</TableCell>
                            <TableCell align={"right"} className={classes.celltxt}>
                              <div className={classes.rowContents}>
                                <Typography className={classes.rupee}>{labels.currency.Rupee} </Typography>
                                <Typography className={classes.celltxt}>
                                  {InvestmentValue.toString().replace(labels.currency.rupeesymbol, "")}
                                </Typography>
                              </div>
                            </TableCell>
                            <TableCell align={"right"} className={classes.celltxt}>
                              <div className={classes.rowContents}>
                                <Typography className={classes.rupee}>{labels.currency.Rupee} </Typography>
                                <Typography className={classes.celltxt}> {Marketvalue?.toString().replace(labels.currency.rupeesymbol, "")}</Typography>
                              </div>
                            </TableCell>
                            <TableCell align={"right"} className={classes.celltxt} style={{ paddingRight: "18px" }}>
                              <div className={classes.totalValue}>
                                <Typography className={classes.rupee}>{labels.currency.Rupee} </Typography>
                                <Typography className={classes.tablevals} style={{ color: NegativeFinding(Unrealisedvalue) }}> {NegativeFinding(Unrealisedvalue, "", "", "", "rupee").replace(labels.currency.rupeesymbol, "")}</Typography>
                              </div>
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      </Table>
                    </ThemeProvider>
                  </TableContainer>
                  <div style={{ width: "7%" }}></div>
                </div>
                {/* </div> */}
              </div>

            </div>
            )
            : (<div >
              <div style={{ display: "flex", flexDirection: "row" }} className={classes.desktopResponsive}>
                <div style={{ width: "7%" }}></div>

                <TableContainer component={Paper} className={classes.table}>
                  <ThemeProvider theme={themes} >
                    <Table >
                      <TableHead>
                        <TableRow >
                          {/* start Changed by celsia */}
                          <TableCell align={"left"} style={{ paddingLeft: "22px" }} className={[classes.tablehead].join('')}>
                            {labels.portfolio.Assest}
                          </TableCell>
                          {/* end */}
                          <TableCell align={"right"} className={classes.tablehead}>{labels.portfolio.InvestedValue}</TableCell>
                          <TableCell align={"right"} className={classes.tablehead}>{labels.portfolio.MargetValue}</TableCell>
                          <TableCell align={"right"} style={{ paddingRight: "20px" }} className={classes.tablehead}>{labels.portfolio.Unrealized}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody >
                        {/* <NoData height={5} /> */}
                        <TableRow>
                          <TableCell colSpan={3}>
                            <div className={classes.mobileResponsivefornodata}>
                              <NoData height={5} />
                            </div>
                          </TableCell>

                        </TableRow>
                      </TableBody>
                      <TableFooter className={classes.footer}>
                        <TableRow>
                          <TableCell align={"left"} style={{ padding: "13px 25px" }} className={classes.celltxt}>{labels.ProfitLoss.Total}</TableCell>
                          <TableCell align={"right"} className={classes.celltxt}>
                            <div className={classes.rowContents}>
                              <Typography className={classes.rupee}>{labels.currency.Rupee} </Typography>
                              <Typography className={classes.celltxt}>
                                0
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell align={"right"} className={classes.celltxt}>
                            <div className={classes.rowContents}>
                              <Typography className={classes.rupee}>{labels.currency.Rupee} </Typography>
                              <Typography className={classes.celltxt}> {0}</Typography>
                            </div>
                          </TableCell>
                          <TableCell align={"right"} className={classes.celltxt} style={{ paddingRight: "18px" }}>
                            <div className={classes.totalValue}>
                              <Typography className={classes.rupee}>{labels.currency.Rupee} </Typography>
                              <Typography className={classes.tablevals} > {0}</Typography>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </ThemeProvider>
                </TableContainer>
                <div style={{ width: "7%" }}></div>
              </div>
              <div className={classes.mobileResponsive1} style={{ margin: "0% 7%" }}>
                <div style={{ width: "7%" }}></div>
                <TableContainer component={Paper} className={classes.table}>
                  <ThemeProvider theme={themes}>
                    <Table >
                      <TableHead>
                        <TableRow >
                          <TableCell align={"left"} className={classes.responsivePadding}>
                            {labels.portfolio.Assest}
                          </TableCell>
                          <TableCell className={classes.ressponsiveHeader}>
                            <div align={"right"} className={classes.tablehead}>
                              {labels.portfolio.MargetValue}
                              <div className={[classes.tablehead, classes.responsiveInvester].join(' ')}>
                                {labels.portfolio.InvestedValue}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody >
                        {/* <NoData height={5} /> */}
                        <TableRow>
                          <TableCell colSpan={3}>
                            <div className={classes.mobileResponsivefornodata}>
                              <NoData height={5} />
                            </div>
                          </TableCell>

                        </TableRow>
                      </TableBody>
                      <TableFooter className={classes.footer}>
                        <TableRow>
                          <TableCell align={"left"} className={[classes.celltxt, classes.responsiveLable].join(' ')}>{labels.ProfitLoss.Total}</TableCell>
                          <TableCell>
                            <div className={classes.ressponsiveHeader} style={{ padding: "10px" }}>
                              <div align={"right"} className={classes.celltxt1}>
                                <div className={classes.rowContents}>
                                  <Typography className={classes.rupee}>{labels.currency.Rupee} </Typography>
                                  <Typography className={classes.celltxt1}> {NegativeFinding(Marketvalue, "", "", "", "number")}</Typography>
                                </div>
                                <div align={"right"} className={[classes.celltxt2, classes.responsiveFooterTxt].join(' ')}>
                                  <div className={classes.rowContents} >
                                    <Typography className={classes.rupee}>{labels.currency.Rupee} </Typography>
                                    <Typography className={classes.celltxt2}>
                                      {NegativeFinding(InvestmentValue, "", "", "", "number")}
                                    </Typography>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    </Table>
                  </ThemeProvider>
                </TableContainer>
                <div style={{ width: "7%" }}></div>
              </div>
            </div>
            )
            :
            (
              <div style={{ width: '100%', height: '100%', textAlign: 'center' }}>
                <Loader page="table" />
              </div>
            )
          }
        </Theme>
      </ErrorBoundary>
    );
  } catch (err) {
    console.log(err)
  }

}
const mapStateToProps = state => {

  return {
    portfolioSummary: state.Portfolio.productTable != null && state.Portfolio.productTable.length != 0 ? state.Portfolio.productTable.filter((val) => val.hasOwnProperty("isFinancial") ? val.isFinancial == true : true) : [],
    isLoadingportfolioSummary: state.Portfolio.isLoading,
    PortfolioNetworth: state.Card.PortfolioNetworth,
    Invested: state.Card.Invested,
    mfHeader2: state.Card.Market,

    Market: state.Card.Market,
    Unrealisedvalue: state.Card.Unrealisedvalue,
    Dividend: state.Card.Dividend,
    Total: state.Card.Total,
    percentageunrealised: state.Card.percentageunrealised,

    mfHeader2: state.Card.mfHeader2,

  };
};
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getPortfolio }, dispatch)
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PortfolioTable));


