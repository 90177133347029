import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from "../../utils/constants/labels"
import Networking from '../../utils/api/apiaccess';
function getRiaFlowOnLinePaymentCompletedDetailsReport(FormNo,EmailID,PlanCode,GstNumber,PaymentType,ClientID,DepositPageID) {
    let data = {};
    data.FormNo = FormNo,
    data.EmailID = EmailID,
    data.PlanCode = PlanCode,
    data.GstNumber = GstNumber,
    data.PaymentType = PaymentType,
    data.ClientID = ClientID, 
    data.DepositPageID = DepositPageID  
   
    return Networking.PostApi(ApiValues.RiaOnlinePaymentCompleteDetails, data).then(result => { return result })
.catch(function (error) {
  return { "status": "F", "message": labels.messages.apierror }
});
}
export default getRiaFlowOnLinePaymentCompletedDetailsReport