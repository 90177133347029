import styled from "styled-components";
import colors from "../../utils/colors/colors";
export const Switch = styled.div`
  font-family: "Lucida Grande", Tahoma, Verdana, sans-serif;
  position: relative;
  height: 40px;
  display:flex;
  flex-direction:row;
  width:max-content;
  align-items:center;
  z-index:1;
  background-color:${window.globalConfig.color.BORDER};
  border-radius: 15px;  
  box-shadow:0 1.5px 1px 0 rgb(0 0 0 / 6%);
  padding-left: 3px;
  padding-right : 2px
`;
export const SwitchRadio = styled.input`
  display: none;
`;
export const SwitchSelection = styled.span`
  display: block;
  position: relative;
  min-width:50px;
  z-index: 1;
  top: 2.5px;
  height: 31px;
  background: ${window.globalConfig.color.WHITE};
  border-radius: 11px; 
`;
export const SwitchLabel = styled.label`
  position: relative;
  z-index: 2;
  float: left;
  min-width:50px;
  width:fit-content;
  line-height: 36px;
  font-size: 13px;
  color:${window.globalConfig.color.BLACK_PRIMARY};
  text-align: center;
  cursor: pointer;

  ${SwitchRadio}:checked + & {
    transition: 0.15s ease-out;
    color:${window.globalConfig.color.BROWN};
  }
`;