import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl';
var axios = require('axios')
import Networking from '../../utils/api/apiaccess';
function markAsPrimarydetails(bankID, isPrimary, userID) {
    let data = {};
    data.BankID = bankID
    data.UserID = userID
    data.IsPrimary = isPrimary
    // var config = {
    //     method: 'post',
    //     url: ApiValues.MarkasPrimary,
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    //     data: data
    // };
    // return axios(config)
    //     .then(function (response) {
    //         return response.data;
    //     })
    //     .catch(function (error) {
    //         return { status: "F", message:labels.messages.apierror}
    //     });
    return Networking.PostApi(ApiValues.MarkasPrimary, data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
}
export default markAsPrimarydetails