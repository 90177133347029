import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors/colors';
import { NegativeFinding, replaceNull } from '../../utils/commonfunction';
import { Skeleton } from '@material-ui/lab';
import BenchMarkCard from '../../components/card/benchmarkcard';
const styles = theme => ({
  root: {
    // flexGrow: 1,
    backgroundColor: window.globalConfig.color.WHITE,
    boxShadow: '0 0 20px 0 rgba(19, 89, 161, 0.1)',
    display: "grid",
    gridTemplateColumns: "17% 16% 16% 16% 21% 14%",
    // lineHeight: "6.5rem",
    marginBottom: "-5%",
    alignItems: "center",
    //  padding: "30px 0px",
    borderRadius: 6,
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "100%",
      // marginTop:"-65%",
      // height:"234px",
      gap: 10,
      "& .data1": {
        borderRight: "none",
        borderBottom: `1px solid ${colors.BORDER}`,

      },
      "& .data6": {
        paddingBottom: 10
      }
    }
  },
  label: {
    color: colors.LABLE_GRAY,
    fontSize: 14
  },
  tapTextToolBar: {
    borderRadius: '3% !important',
    marginBottom: '-5%',

    [theme.breakpoints.down('xs')]: {
      borderRadius: '10px 10px 10px 10px !important',
      boxShadow: '0 0 20px 0 rgb(19 89 161 / 10%) ',
      marginTop: '-20.5%',
      backgroundColor: 'white',
      padding: '0', //Added by Celsia
    },
  },
  specialChar: {
    color: colors.PRIMARY_BORDER
  },
  firstwidth: {
    width: "10vw",
    // marginTop: "10%",
    marginLeft: "2vw",
    height: "4vh",
    [theme.breakpoints.down('600')]: {
      width: "93%",
      height: "9vh",
    }
  },
  // text: {
  //   fontWeight: "bold"
  // },
  data1: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    height: 100,
    justifyContent: "center",
    borderRight: `1px solid ${colors.BORDER}`,
    [theme.breakpoints.down('920')]: {

    }
  },
  data2: {
    display: "flex",
    flexDirection: "column",
    gap: 10,

    [theme.breakpoints.down('920')]: {
      height: 60
    }
  },
  data3: {
    display: "flex",
    flexDirection: "column",
    gap: 10,

    [theme.breakpoints.down('920')]: {
      height: 60
    }
  },
  data4: {
    display: "flex",
    flexDirection: "column",
    gap: 10,

    [theme.breakpoints.down('920')]: {
      height: 60
    }
  },
  dataText1: {
    fontSize: "22px !important"
  },
  data5: {
    display: "flex",
    flexDirection: "column",
    gap: 10,

    [theme.breakpoints.down('920')]: {
      height: 60
    }
  },
  data6: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
    alignItems: "center",
    [theme.breakpoints.down('920')]: {
      height: 60
    }
  },
  //Added by Celsia
  maingrid: {
    [theme.breakpoints.down('xs')]: {
      boxShadow: '0 0 20px 0 rgb(19 89 161 / 10%) ',

    },
  },
  cardContainer: {
    boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)", borderRadius: 10, backgroundColor: colors.WHITE, padding: "15px 20px",
    gap: 20,
    marginBottom: "-6%",
    display: 'grid',

    [theme.breakpoints.up("920")]: {
      gridTemplateColumns: 'repeat(4, 1fr) !important',
      gridTemplateAreas: `"dataOne dataOne dataOne dataTwo"
      "dataThree dataFour dataFive dataSix"
     `,
      "& .dataOne": {
        textAlign: "left",
        //  alignItems:"center"
      },
      "& .dataTwo": {
        textAlign: "right",
        //  alignItems:"center"
      },
      "& .dataThree": {
        textAlign: "left",
        //  alignItems:"center"
      },
      "& .dataFour": {
        textAlign: "left",
        //  alignItems:"center"
      },
      "& .dataFive": {
        textAlign: "left",
        //  alignItems:"center"
      },
      "& .dataSix": {
        textAlign: "right",
        //  alignItems:"center"
      },
    },

  },
  label: {
    color: colors.LABLE_GRAY,
    fontSize: 14,

  },
  text: {
    fontSize: 18,
    fontWeight: "600",
    textWrap: "balance"
  },
  dataOne: {
    display: "flex", flexDirection: "column",
    gap: 5,
    [theme.breakpoints.up("920")]: {
      gridArea: "dataOne"
    }
  },
  dataTwo: {
    display: "flex", flexDirection: "column", gap: 5,
    [theme.breakpoints.up("920")]: {

      gridArea: "dataTwo"
    }
  }
  ,
  dataThree: {
    display: "flex", flexDirection: "column", gap: 5,
    [theme.breakpoints.up("920")]: {

      gridArea: "dataThree"
    }
  },
  dataFour: {
    display: "flex", flexDirection: "column", gap: 5,
    [theme.breakpoints.up("920")]: {
      gridArea: "dataFour"
    }
  },
  dataFive: {
    display: "flex", flexDirection: "column", gap: 5,
    [theme.breakpoints.up("920")]: {
      gridArea: "dataFive"
    }
  },
  dataSix: {
    display: "flex", flexDirection: "column", gap: 5,
    [theme.breakpoints.up("920")]: {
      gridArea: "dataSix"
    }
  }
});
class CardMenu extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      card: this.props.data, openBenchMark: false
    };
    this.myRef = React.createRef(null)

  }
  openBenchMarkPopup = () => {
    this.setState({ openBenchMark: true })
  }
  onClose = () => {
    this.setState({ openBenchMark: false })
  }
  componentDidMount() {
    // console.log(this.props.data)
    this.bindBenchMark(0)
  }
  componentDidUpdate(prev) {
    const { data } = this.props;
    if (prev.data != data) {
      this.setState({ card: data })
    }
  }
  bindBenchMark = (BenchMarkID) => {
    if (replaceNull(this.props.data, "NA") != "NA" && this.props.productID != 15) {
      this.setState({
        card: [...this.props.data,]
      })
    } else {
      this.setState({ card: this.props.data })
    }
  }
  render() {
    const { classes } = this.props;
    if (this.props.flag == "primary") {
      return (
        this.props.loader ?
          <div className={[classes.cardContainer, this.props.style].join(" ")}>
            <div className={[classes.dataOne, "dataOne"].join(" ")} >

              <span className={classes.label}>   <Skeleton className={classes.firstwidth} /></span>
              <span className={classes.text}>   <Skeleton className={classes.firstwidth} /></span>

            </div>
            <div className={[classes.dataTwo, "dataTwo"].join(" ")} >

              <span className={classes.label}>   <Skeleton className={classes.firstwidth} /></span>
              <span className={classes.text}>   <Skeleton className={classes.firstwidth} /></span>

            </div>
            <div className={[classes.dataThree, "dataThree"].join(" ")} >

              <span className={classes.label}>   <Skeleton className={classes.firstwidth} /></span>
              <span className={classes.text}>   <Skeleton className={classes.firstwidth} /></span>

            </div>
            <div className={[classes.dataFour, "dataFour"].join(" ")} >

              <span className={classes.label}>   <Skeleton className={classes.firstwidth} /></span>
              <span className={classes.text}>   <Skeleton className={classes.firstwidth} /></span>

            </div>
            <div className={[classes.dataFive, "dataFive"].join(" ")} >

              <span className={classes.label}>   <Skeleton className={classes.firstwidth} /></span>
              <span className={classes.text}>   <Skeleton className={classes.firstwidth} /></span>

            </div>
            <div className={[classes.dataSix, "dataSix"].join(" ")} >

              <span className={classes.label}>   <Skeleton className={classes.firstwidth} /></span>
              <span className={classes.text} >   <Skeleton className={classes.firstwidth} />
              </span>

            </div>


          </div> :
          this.props.data.map((item, index) => {

            return <div key={index} className={[classes.cardContainer, this.props.style].join(" ")}>
              <div className={[classes.dataOne, "dataOne"].join(" ")} >

                <span className={classes.label}>NPS Manager</span>
                <span className={classes.text}>{item.dataOne}</span>

              </div>
              <div className={[classes.dataTwo, "dataTwo"].join(" ")} >

                <span className={classes.label}>Invested Amount</span>
                <span className={classes.text}>{item.dataTwo}</span>

              </div>
              <div className={[classes.dataThree, "dataThree"].join(" ")} >

                <span className={classes.label}>PRAN No</span>
                <span className={classes.text}>{item.dataThree}</span>

              </div>
              <div className={[classes.dataFour, "dataFour"].join(" ")} >

                <span className={classes.label}>Inception Date</span>
                <span className={classes.text}>{item.dataFour}</span>

              </div>
              <div className={[classes.dataFive, "dataFive"].join(" ")} >

                <span className={classes.label}>Latest Valuation Date</span>
                <span className={classes.text}>{item.dataFive}</span>

              </div>
              <div className={[classes.dataSix, "dataSix"].join(" ")} >

                <span className={classes.label}>Latest Value (Absolute Returns)</span>
                <span className={classes.text} >{item.dataSix}
                  {item.subDataSix != undefined && <span style={{ color: NegativeFinding(Number(item.subDataSix.replace("%", ""))) }}> {` (${item.subDataSix.replace("-", "")})`}</span>}
                </span>

              </div>


            </div>
          }))
    }
    else {
      // { console.log(this.props.data) }
      return (
        <div className={[classes.root, this.props.style].join(" ")}>
          {this.props.loader ? <>
            <div className={classes.cardDesign} style={{ height: 100, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <Skeleton className={classes.firstwidth} />
              <Skeleton className={[classes.firstwidth, classes.mobileresponsive].join(' ')} />
            </div>
            <div className={classes.cardDesign}>
              <Skeleton className={classes.firstwidth} />
              <Skeleton className={[classes.firstwidth, classes.mobileresponsive].join(' ')} />
            </div>
            <div className={classes.cardDesign}>
              <Skeleton className={classes.firstwidth} />
              <Skeleton className={[classes.firstwidth, classes.mobileresponsive].join(' ')} />
            </div>
            <div className={classes.cardDesign}>
              <Skeleton className={classes.firstwidth} />
              <Skeleton className={[classes.firstwidth, classes.mobileresponsive].join(' ')} />
            </div>
            <div className={classes.cardDesign}>
              <Skeleton className={classes.firstwidth} />
              <Skeleton className={[classes.firstwidth, classes.mobileresponsive].join(' ')} />
            </div>

          </> :

            this.state.card?.map((data, index) => {
              return <><div key={index} className={[classes[`data${index + 1}`], `data${index + 1}`].join(" ")}>
                <span className={classes.label} style={{ borderBottom: data.enableLink ? "1px dashed" : "", width: data.enableLink ? "max-content" : "", cursor: data.enableLink ? "pointer" : "" }} onClick={() => {
                  if (replaceNull(data?.enableLink, "NA") == true) {
                    this.openBenchMarkPopup()
                  }
                }}>{data.firstName}</span>
                <span className={[classes.text, classes[`dataText${index + 1}`]].join(" ")}>
                  {this.props.search != undefined && data.lastName?.toString().search(this.props.search) != -1 &&
                    <span className={[`data${index + 1}SpecialChar`, classes.specialChar].join(" ")}>
                      {this.props.search}{" "}
                    </span>}
                  {data.lastName != undefined && data.lastName.toString().replace(this.props.search, "")}
                  {data.val != undefined && <span style={{ color: NegativeFinding(data?.checkPercentage) }}>{NegativeFinding(data?.val, "", "", "", "removeMinus")}</span>}
                </span>
              </div>
                {this.state.card.length - 1 == index && this.props.productID != 15 &&
                  <div key={index} className={[classes[`data${index + 1}`], `data${index + 1}`].join(" ")}>
                    <BenchMarkCard myRef={this.myRef} productID={this.props.productID
                    } bindBenchMark={this.bindBenchMark} onClose={this.onClose} openBenchMark={this.state.openBenchMark} openBenchMarkPopup={this.openBenchMarkPopup}></BenchMarkCard>
                  </div>}
              </>
            })

          }
          {/* {this.props.flag != undefined ? scheme :this.props.isHeaderLoading||this.props.loader==true?loading:this.props.data?.length == 0 ? empty : datas} */}
        </div>
      )
    }

  }
}
const mapStateToProps = state => ({
  //headerdetails: state.Card.headerdetails,
  sessionID: state.Login.sessionID,
  clientID: state.Login.logindetails.ClientID,
  userID: state.Login.userID,
  date: state.Login.date,
  logindetails: state.Login.logindetails,
  isTransactionDetailsLoading: state.TransactionDetails.isTransactionDetailsLoading,
  isHeaderLoading: state.Card.isHeaderLoading

})
export default withStyles(styles)(connect(mapStateToProps, null)(CardMenu));
