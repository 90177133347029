// Tracking details page created by yogitha on 15/07/21
import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import MfImage from "../../utils/assets/images/mutualfund.png";
import Equity from "../../utils/assets/equity.png";
import EquityGray from "../../utils/assets/equitygray.png";
import Insurance from "../../utils/assets/insurance.png";
import EpfImage from "../../utils/assets/images/epfi.png";
import FixedDeposit from "../../utils/assets/images/fixed.png";
import FixedDepositGray from "../../utils/assets/images/fixedgray.png";
import RealImage from "../../utils/assets/images/real.png";
import NpsImage from "../../utils/assets/images/nps.png";
import { scrollToTopPage } from '../../utils/commonfunction';
import {
    dateChage,
    clientIDchange,
    // getEmptyAction,
    TradeUCCDetails,
    accountselection,
    ResetPreventAPICallAction
} from "../../redux/actions/login/loginaction";
import PmsImage from "../../utils/assets/images/PMS.png";
import Gold from '../../utils/assets/goldImg.png';
import Bonds from '../../utils/assets/bonds.png';
import Bondsgray from '../../utils/assets/bondsGray.png';
import labels from "../../utils/constants/labels";
import { Redirect } from "react-router";
import Button from "../../components/custombutton/button";
import CommonCard from "../../components/tracking_card/cards";
import Toggle from "../../components/toggle/toggle";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Asset } from "../../utils/mainasset";
import { NegativeFinding } from "../../utils/commonfunction";
import Skeleton from "@material-ui/lab/Skeleton";
import { getTrackDetails } from "../../redux/actions/track/trackDetails";
const styles = (theme) => ({
    root: {
        backgroundColor: window.globalConfig.color.WHITE,
        width: "100%",
        height: "100%",
        marginBottom: "2%",
    },
    columnDivider: {
        display: "grid",
        gridTemplateColumns: "10% 80% 10%",
        marginTop: "5px",
        [theme.breakpoints.down("sm")]: {
            display: "grid",
            gridTemplateColumns: "5% 90% 5%",
        },
    },
    container: {
        display: "grid",
        gridTemplateColumns: "repeat(4,25%)",
        columnGap: "5px",
        [theme.breakpoints.down("sm")]: {
            display: "grid",
            gridTemplateColumns: "repeat(3,33%)",
        },
        [theme.breakpoints.down("600")]: {
            display: "grid",
            gridTemplateColumns: "repeat(1,1fr)",
        },
    },
    card: {
        boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
        transition: "0.3s",
        borderRadius: "5px",
        margin: "3%",
        padding: "10%",
    },
    subHeaders: {
        textAlign: "center",
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        paddingTop: "1.5%",
        paddingBottom: "7px",
        [theme.breakpoints.down("md")]: {
            paddingTop: "7%",
        },
        [theme.breakpoints.down("sm")]: {
            paddingTop: "10%",
            paddingBottom: "1%",
        },
        [theme.breakpoints.down("xs")]: {
            paddingTop: "60px",
            paddingBottom: "1%",
        },
    },
    Header: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        marginTop: "0",
        [theme.breakpoints.down("sm")]: {
            marginTop: "0",
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: "0",
        },
    },
    trackerHeader: {
        textAlign: "center",
        width: "60%",
        marginLeft: "auto",
        marginRight: "auto",
        color: window.globalConfig.color.GRAY,
    },
    h2FontSize: {
        fontSize: "1em !important",
        color: window.globalConfig.color.WHITE,
    },
    h1FontColor: {
        color: window.globalConfig.color.WHITE,
        fontSize: "25px",
        margin: "0px 0px 10px 0px",
    },
    subHeading: {
        backgroundColor: window.globalConfig.color.BROWN,
        color: window.globalConfig.color.WHITE,
        width: "15%",
        marginLeft: "auto",
        marginRight: "auto",
        lineHeight: "2",
        [theme.breakpoints.down("md")]: {
            width: "20%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "30%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "59%",
        },
    },
    whitecolor: {
        color: window.globalConfig.color.WHITE,
    },
    buttonpaddingTop: {
        paddingTop: "25px",
    },
    fontWeightHeader: {
        fontWeight: "normal",
    },
    trackingLable: {
        display: "grid",
        gridTemplateColumns: "repeat(2,50%)",
        columnGap: "0%",
        // margin: "0% 2% 0% 1%",
        alignItems: "center",
        [theme.breakpoints.down("920")]: {
            display: "grid",
            gridTemplateColumns: "repeat(1,100%)",
            columnGap: "0%",
            // margin: "0% 1% 0% 1%",
        },
    },
    trackingRow: {
        display: "flex",
        flexDirection: "row",
        [theme.breakpoints.down("xs")]: {
            display: "flex",
            flexDirection: "column",
        },
    },
    marginAlignment: {
        margin: "1% 1%",
        [theme.breakpoints.down("xs")]: {
            margin: "1% 20%",
        },
    },
    marginTopAlignment: {
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        // margin: "3% 0% 0% 4%",
        [theme.breakpoints.down("900")]: {
            alignItems: "center"
        },
    },
    labelcolor: {
        color: window.globalConfig.color.LABLE_GRAY,
    },
    flexRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        // lineHeight: "2.8em",
    },
    greenLableColor: {
        marginLeft: 5,
        display: 'flex',
        gap: '3px',
        // flexWrap:'initial',
    },
    smallCard: {
        width: "240px",
        height: "197px",
    },
    paddingAlignment: {
        padding: "10px 0px",
    },
    paddingImportCard: {
        padding: "2% 1% 2% 1%,"
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        whiteSpace: 'normal',
        flexWrap: 'wrap',
        justifyContent: "center",
        lineHeight: "25px",
        fontSize: "14px",
    },
    outlineButtonPadding: {
        minWidth: '75%',
        padding: '0% 13% 0% 13%',
        // position: 'absolute',
        // bottom: '0px',
        // padding: "0% 13% 8% 9%",
        // [theme.breakpoints.down("md")]: {
        //     padding: "0% 13% 4% 9%",
        // },
        // [theme.breakpoints.down("sm")]: {
        //     padding: "0% 9% 9% 7%",
        // },
        // [theme.breakpoints.down("xs")]: {
        //     padding: "0% 13% 9% 8%",
        // },
    },
    outlineButtonMargin: {
        margin: "0px 0px 0px 10px",
        // padding: "3% 0% 5% 0%",
        // position: 'absolute',
        // bottom: 0,
        width: '90%',
        // maxWidth: '100%',
    },
    totalCurrentValueSpan: {
        marginLeft: "0px",
    },
    ToTalAmountAlignment: {
        display: "block",
        marginBlockStart: "1.33em",
        marginBlockEnd: "1.33em",
        marginInlineStart: "0px",
        marginInlineEnd: "0px",
        fontWeight: "bold",
        [theme.breakpoints.down("xs")]: {
            margin: "0px 0px 6px 0px",
        },
    },
    bottomstyle: {
        margin: "0%",
        [theme.breakpoints.down("xs")]: {
            margin: "0px 0px 13% 0px",
        },
    },
    importedTitle: {
        // padding: '10px 0px',
        fontSize: '17px',
        fontWeight: '900',

    }
});
class TrackingCard extends Component {
    constructor() {
        super();
        this.state = {
            selected: false,
            imported: "",
            successData: [],
            isLoader: true,
            isLoderSwitch: false,
        };
    }
    componentDidMount() {
        document.title = window.globalConfig.companyDetails.companyName + " - Track"
        scrollToTopPage();
        if (this.props.location.state != undefined) {
            if (this.props.location.state.importedstatus != undefined) {
                this.setState({ selected: this.props.location.state.importedstatus });
            }
        }
        this.props.getTrackDetails(this.props.logindetails.ClientID, 1, 0, this.props.L5ID, 0)

    }
    componentDidUpdate(prev) {
        const { logindetails, L5ID, clientID } = this.props;
        if (prev.clientID !== clientID || prev.L5ID != L5ID) {
            this.props.getTrackDetails(logindetails.ClientID, 1, 0, L5ID, 0)
        }
    }
    navigationclick = () => {
        this.props.history.push(labels.Path.MutualTab);
    }
    heldAwayCall = () => {
        if (this.props.L5report != "NA" && this.props.L5report.length != 0) {
            this.props.accountselection(this.props.L5report.filter((item) => item.L4_AccountTypeID == 3)[0].L5ID)
            this.props.childFunc.current(this.props.logindetails.SessionID, this.props.logindetails.ClientID, this.props.date, this.props.L5report.filter((item) => item.L4_AccountTypeID == 3)[0].L5ID, "L5")
        }
    }

    handleDebenture = () => {
        // if (this.props.logindetails.EntityTypeID != 9) {
        this.props.history.push(labels.Path.Debentures)
        // }
    }
    handleFixedDeposits = () => {
        if (window.globalConfig.isFdAllowed) {
            this.props.history.push(labels.Path.FdDebentures)
        } else {
            this.props.history.push(labels.Path.FDTab)
        }
        // if (this.props.logindetails.EntityTypeID != 9) {

        // }
    }
    handleAIF = () => {
        // if (this.props.logindetails.EntityTypeID != 9) {
        this.props.history.push(labels.Path.Aif)
        // }
    }
    handleRealEstate = () => {
        // if (this.props.logindetails.EntityTypeID != 9) {
        this.props.history.push(labels.Path.RealEstateListing)
        // }
    }
    handleBullion = () => {
        this.props.history.push(labels.Path.Bullionlisting)
    }
    handleBonds = () => {
        // if (this.props.logindetails.EntityTypeID != 9) {
        this.props.history.push(labels.Path.TradedBonds)

        // this.props.history.push({
        //     pathname: labels.Path.TradedBonds,
        //     state: { data: "tradableBonds" }
        // })

    }
    Mutualfundclick = (mutualfunds) => {
        this.props.history.push({
            pathname: labels.Path.EquityTracking,
            state: { data: mutualfunds },
        });
    }
    Equityclick = (equity) => {
        this.props.history.push({
            pathname: labels.Path.EquityTracking,
            state: { data: "Equity" },
        });
    }
    equitynavigationclick = () => {
        this.props.history.push(labels.Path.Equity);
    }
    bondsnavigationclick = () => {
        this.props.history.push(labels.Path.BondsTab);
    }
    goldbondsnavigationclick = () => {
        this.props.history.push(labels.Path.GoldBondsTab);
    }
    fdsnavigationclick = () => {
        this.props.history.push(labels.Path.FDTab);
    }
    aifnavigationclick = () => {
        this.props.history.push(labels.Path.AIFTab);
    }
    npsnavigationclick = () => {
        this.props.history.push(labels.Path.Nps);
    }
    realestatenavigationclick = () => {
        this.props.history.push(labels.Path.RealEstateTab);
    }
    insurancenavigationclick = () => {
        this.props.history.push(labels.Path.InsuranceTab);
    }
    epfnavigationclick = () => {
        this.props.history.push(labels.Path.EPFTab);
    }
    togglechange = () => {
        this.setState({ selected: !this.state.selected, isLoderSwitch: true });
    };
    npsClick = () => {
        if (window.globalConfig.isNpsAllowed) {
            this.props.history.push(labels.Path.Nps)
        } else {
            this.props.history.push(labels.Path.NPSTab)
        }

    };
    epfClick = () => {
        this.props.history.push(labels.Path.EPF)
    };
    insuranceClick = () => {
        this.props.history.push({
            pathname: labels.Path.Insurance,
            state: { flag: "Health" }
        })
    }
    render() {
        const { classes } = this.props;
        try {
            return (
                <div className={classes.root}>
                    <div className={classes.Header}>
                        <div className={classes.subHeaders}>
                            <h1 className={classes.h1FontColor}>
                                {labels.Header.TrackHeader}
                            </h1>
                            <div className={classes.trackerHeader}>
                                <Typography className={classes.h2FontSize}>
                                    {labels.Header.TrackSubHeader}
                                    <strong className={classes.whitecolor}>
                                        {labels.Header.StrongHeader}
                                    </strong>{" "}
                                    {labels.Header.BreakHeader}
                                </Typography>
                            </div>
                            <div className={classes.subHeading}>
                                <h3>{labels.Header.TrackButton}</h3>
                            </div>
                        </div>
                    </div>
                    <div className={classes.columnDivider}>
                        <div></div>
                        <div className={classes.trackingLable}>
                            <div className={classes.trackingRow}>
                                <h4 className={classes.fontWeightHeader}>
                                    {labels.Header.totalInvestment}
                                </h4>
                                <h4 className={classes.ToTalAmountAlignment}>
                                    <span className={classes.totalCurrentValueSpan}>
                                        {this.state.selected == false ? (
                                            <>
                                                {this.props.successData.length != 0 ||
                                                    this.props.successData != " " ? (
                                                    <>
                                                        {this.props.successData.filter(
                                                            (val) => val.ProductID == 0
                                                        ).length != 0

                                                            ? this.props.successData
                                                                .filter((val) => val.ProductID == 0)
                                                                .map((item, titleindex) => (
                                                                    <span
                                                                        className={classes.totalCurrentValueSpan}
                                                                        key={titleindex}
                                                                    >
                                                                        {NegativeFinding(item.ToTalAmountInvested, "", "", "", "rupee")}
                                                                    </span>
                                                                ))
                                                            : NegativeFinding(0, "", "", "", "rupee")}
                                                    </>
                                                ) : (
                                                    <span
                                                        className={classes.totalCurrentValueSpan}
                                                        key={titleindex}
                                                    >
                                                        {item.ProductID == 0
                                                            ? NegativeFinding(item.ToTalAmountInvested, "", "", "", "rupee")
                                                            : NegativeFinding(0, "", "", "", "rupee")}
                                                    </span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                {this.props.successData.length != 0 ||
                                                    this.props.successData != " " ? (
                                                    <>
                                                        {this.props.successData.filter(
                                                            (val) => val.ProductID == 0
                                                        ).length != 0
                                                            ? this.props.successData
                                                                .filter((val) => val.ProductID == 0)
                                                                .map((item, titleindex) => (
                                                                    <span
                                                                        className={classes.totalCurrentValueSpan}
                                                                        key={titleindex}
                                                                    >
                                                                        {NegativeFinding(item.ToTalAmountInvested_Imported, "", "", "", "rupee")}
                                                                    </span>
                                                                ))
                                                            : NegativeFinding(0, "", "", "", "rupee")}
                                                    </>
                                                ) : (
                                                    <span
                                                        className={classes.totalCurrentValueSpan}
                                                        key={titleindex}
                                                    >
                                                        {NegativeFinding(item.ToTalAmountInvested_Imported, "", "", "", "rupee")}
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </span>
                                </h4>
                                <div className={classes.marginAlignment}>
                                    {this.props.successData.filter(
                                        (val) => val.ProductID == 0 && !this.state.selected ? val.ToTalAmountInvested != 0 : val.ToTalAmountInvested_Imported != 0
                                    ).length != 0
                                        ?
                                        <Button
                                            name={labels.Header.ViewAnalysisButton}
                                            page={"primary"}
                                            disabled={this.props.loader}
                                            loader={this.props.loader}
                                            onClick={() => {
                                                this.props.history.push(labels.Path.Portfolio);
                                            }}
                                        />
                                        :
                                        <Button
                                            name={labels.Header.ViewAnalysisButton}
                                            page={"primary"}
                                            disabled={true}
                                            loader={this.props.loader}

                                        />
                                    }
                                </div>
                            </div>
                            <div className={classes.marginTopAlignment}>
                                <Toggle
                                    label1={window.globalConfig.companyDetails.ShortName == undefined ? Asset.companyName : `${window.globalConfig.companyDetails.ShortName}`}
                                    label2={"Imported"}
                                    selected={this.state.selected}
                                    toggleSelected={this.togglechange}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={classes.columnDivider}>
                        <div></div>
                        <div className={classes.container}>


                            <CommonCard loader={this.props.isLoading} src={MfImage}
                                label={labels.productList.MutualFunds}
                                imported={this.state.selected} data={this.props.successData
                                    .filter((item) => item.ProductID == 1)}
                                reportClick={() => {
                                    if (this.state.selected && this.props.successData
                                        .filter((item) => item.ProductID == 1)[0]?.MF_CurrentValue_Imported != 0) {
                                        this.heldAwayCall()

                                    }
                                    this.navigationclick()
                                }}
                                //trackNowDisabled={this.props.logindetails.EntityTypeID==9?true:false }
                                reportDisabled={this.props.logindetails.ClientID == 0 ? true : false}

                                trackNowClick={this.Mutualfundclick}
                            >

                            </CommonCard>


                            <CommonCard loader={this.props.isLoading} src={Equity}
                                label={labels.productList.Equity}
                                imported={this.state.selected} data={this.props.successData
                                    .filter((item) => item.ProductID == 2)}
                                reportClick={() => {
                                    if (this.state.selected && this.props.successData
                                        .filter((item) => item.ProductID == 2)[0]?.MF_CurrentValue_Imported != 0) {
                                        this.heldAwayCall()

                                    }
                                    this.equitynavigationclick()
                                }}
                                // trackNowDisabled={this.props.logindetails.EntityTypeID==9?true:false }
                                reportDisabled={this.props.logindetails.ClientID == 0 ? true : false}

                                trackNowClick={this.Equityclick}
                            >

                            </CommonCard>
                            <CommonCard loader={this.props.isLoading} src={Bonds}
                                label={`${labels.productList.TradableBonds} / ${labels.productList.NCD}`}
                                imported={this.state.selected} data={this.props.successData
                                    .filter((item) => item.ProductID == 3)}
                                reportClick={() => {
                                    if (this.state.selected && this.props.successData
                                        .filter((item) => item.ProductID == 3)[0]?.MF_CurrentValue_Imported != 0) {
                                        this.heldAwayCall()

                                    }
                                    this.bondsnavigationclick()
                                }}
                                //trackNowDisabled={this.props.logindetails.EntityTypeID==9?true:false }
                                reportDisabled={this.props.logindetails.ClientID == 0 ? true : false}

                                trackNowClick={this.handleBonds}
                            >

                            </CommonCard>

                            <CommonCard loader={this.props.isLoading} src={FixedDeposit}
                                label={labels.productList.Fixed}
                                imported={this.state.selected} data={this.props.successData
                                    .filter((item) => item.ProductID == 5)}
                                reportClick={() => {
                                    if (this.state.selected && this.props.successData
                                        .filter((item) => item.ProductID == 5)[0]?.MF_CurrentValue_Imported != 0) {
                                        this.heldAwayCall()

                                    }
                                    this.fdsnavigationclick()
                                }}
                                trackNowDisabled={this.props.logindetails.ClientID == 0 ? true : false}
                                reportDisabled={this.props.logindetails.ClientID == 0 ? true : false}

                                trackNowClick={this.handleFixedDeposits}
                            >

                            </CommonCard>

                            <CommonCard loader={this.props.isLoading} src={PmsImage}
                                label={labels.productList.Pms}
                                imported={this.state.selected} data={this.props.successData
                                    .filter((item) => item.ProductID == 6)}
                                reportClick={() => {
                                    if (this.state.selected && this.props.successData
                                        .filter((item) => item.ProductID == 6)[0]?.MF_CurrentValue_Imported != 0) {
                                        this.heldAwayCall()

                                    }
                                    this.aifnavigationclick()
                                }}
                                reportDisabled={this.props.logindetails.ClientID == 0 ? true : false}

                                trackNowClick={this.handleAIF}
                            >

                            </CommonCard>
                            <CommonCard loader={this.props.isLoading} src={RealImage}
                                label={labels.productList.RealEstate}
                                imported={this.state.selected} data={this.props.successData
                                    .filter((item) => item.ProductID == 7)}
                                reportClick={() => {
                                    if (this.state.selected && this.props.successData
                                        .filter((item) => item.ProductID == 7)[0]?.MF_CurrentValue_Imported != 0) {
                                        this.heldAwayCall()

                                    }
                                    this.realestatenavigationclick()
                                }}
                                reportDisabled={this.props.logindetails.ClientID == 0 ? true : false}

                                trackNowClick={this.handleRealEstate}
                            >

                            </CommonCard>

                            <CommonCard loader={this.props.isLoading} src={Gold}
                                label={labels.productList.Gold}
                                imported={this.state.selected} data={this.props.successData
                                    .filter((item) => item.ProductID == 4)}
                                reportClick={() => {
                                    if (this.state.selected && this.props.successData
                                        .filter((item) => item.ProductID == 4)[0]?.MF_CurrentValue_Imported != 0) {
                                        this.heldAwayCall()

                                    }
                                    this.goldbondsnavigationclick()
                                }}
                                reportDisabled={this.props.logindetails.ClientID == 0 ? true : false}

                                trackNowClick={this.handleBullion}
                            >

                            </CommonCard>


                            <CommonCard
                                loader={this.props.isLoading}

                                src={Insurance}
                                label={labels.productList.Insurance}
                                buttonName={labels.button.trackNow}
                                buttonpage={"primary"}
                                disabled={false}
                                imported={this.state.selected} data={this.props.successData
                                    .filter((item) => item.ProductID == 9)}
                                reportClick={() => {
                                    if (this.state.selected && this.props.successData
                                        .filter((item) => item.ProductID == 9)[0]?.MF_CurrentValue_Imported != 0) {
                                        this.heldAwayCall()

                                    }
                                    this.insurancenavigationclick()
                                }}
                                reportDisabled={this.props.logindetails.ClientID == 0 ? true : false}

                                trackNowClick={this.insuranceClick}
                            ></CommonCard>

                            <CommonCard
                                loader={this.props.isLoading} src={NpsImage}
                                label={labels.productList.NPS}
                                imported={this.state.selected} data={this.props.successData
                                    .filter((item) => item.ProductID == 8)}
                                reportClick={() => {
                                    if (this.state.selected && this.props.successData
                                        .filter((item) => item.ProductID == 8)[0]?.MF_CurrentValue_Imported != 0) {
                                        this.heldAwayCall()

                                    }
                                    this.npsnavigationclick()
                                }}
                                reportDisabled={this.props.logindetails.ClientID == 0 ? true : false}
                                trackNowDisabled={this.props.logindetails.ClientID == 0 ? true : false}
                                trackNowClick={this.npsClick}
                            ></CommonCard>
                            <CommonCard
                                loader={this.props.isLoading} src={EpfImage}
                                label={labels.productList.Epf}
                                imported={this.state.selected} data={this.props.successData
                                    .filter((item) => item.ProductID == 11)}
                                reportClick={() => {
                                    if (this.state.selected && this.props.successData
                                        .filter((item) => item.ProductID == 11)[0]?.MF_CurrentValue_Imported != 0) {
                                        this.heldAwayCall()

                                    }
                                    this.epfnavigationclick()
                                }}
                                //trackNowDisabled={this.props.logindetails.EntityTypeID==9?true:false }
                                reportDisabled={this.props.logindetails.ClientID == 0 ? true : false}

                                trackNowClick={this.epfClick}
                            ></CommonCard>

                        </div>
                        <div></div>
                    </div >
                </div >
            );
        } catch (err) {
            console.log(err)
            return <Redirect to={labels.Path.NotFound} />;
        }
    }
}
const mapStateToProps = (state) => ({
    date: state.Login.date,
    mfHeader2: state.Card.mfHeader2,
    mfHeader1: state.Card.mfHeader1,
    equityImported: state.Trackequityimported.EquityImporteddata,
    logindetails: state.Login.logindetails,
    clientID: state.Login.clientID,
    isLoading: state.Track.isLoading,
    successData: state.Track.trackEquity,
    L5ID: state.Login.L5ID,
    L5report:
        state.L5report.L5report.length != 0 && state.L5report.L5report != "NA"
            ? [

                ...state.L5report.L5report.map((item) => {
                    return { ...item, Name: item.PortfolioName };
                }),
            ]
            : [],
});
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            getTrackDetails,
            accountselection
        },
        dispatch
    );
};
export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(TrackingCard)
);
