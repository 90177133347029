/* name should be camel case in all function */
//const BaseUrl='https://jsonplaceholder.typicode.com/todos';
import Networking from '../../utils/api/apiaccess';
import { replaceNull } from '../../utils/commonfunction';
import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
function getSchemeDetails(Amount, ISIN, RTACode, ClientID, TransactionType, Frequency) {
  let data = {};
  data.Amount = Amount
  data.ISIN = ISIN
  data.RTACode = RTACode
  data.ClientID = ClientID
  data.TransactionType = TransactionType
  data.STPFrequency = replaceNull(Frequency, '')
  return Networking.PostApi(ApiValues.SchemeDetails, data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
  // return fetch(ApiValues.SchemeDetails, {
  //     method: 'POST',
  //     headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(data),
  // })
  //     .then((response) => response.json())
  //     //If response is in json then in success
  //     .then((responseJson) => {
  //         return responseJson

  //     })
  //     //If response is not in json then in error
  //     .catch((error) => {
  //         //Error 
  //         if(error.message=='Failed to fetch')
  //         {
  //             var data={}
  //             data.status="N"
  //             data.message=error.message
  //             return data
  //         }
  //         else{
  //             return { "status": "F","message":labels.messages.apierror }
  //         }

  //     });

  // fetch method ends here

}
export default getSchemeDetails;
