export const SEARCH_PENDING = 'SEARCH_PENDING';
export const SEARCH_PENDING_FOR_NEXT = 'SEARCH_PENDING_FOR_NEXT';
export const SEARCH_DONE = 'SEARCH_DONE';
export const IDLE = 'IDLE';
export const GET_SAMPLE_DATA = 'GET_SAMPLE_DATA';
export const GET_TRANSACTION = 'GET_TRANSACTION';
export const EMPTY_L5REPORT = 'EMPTY_L5REPORT';
export const LOADER_FOLIO_WISE_PORTFOLIO_XRAY="LOADER_FOLIO_WISE_PORTFOLIO_XRAY";
export const FOLIO_WISE_PORTFOLIO_XRAY="FOLIO_WISE_PORTFOLIO_XRAY";
export const EMPTY_FOLIOWISE_XRAYDATA="EMPTY_FOLIOWISE_XRAYDATA"
export const LOADER_L5REPORT = 'LOADER_L5REPORT';
export const L5REPORT = 'L5REPORT'
export const ADD_TRANSACTION = 'ADD_TRANSACTION';
export const EDIT_TRANSACTION = 'EDIT_TRANSACTION';
export const DELETE_TRANSACTION = 'DELETE_TRANSACTION';
export const GET_TRANSACTIONLIST = 'GET_TRANSACTIONLIST';
export const GET_SUMMARY_DATA = 'GET_SUMMARY_DATA';
export const XRAYDATA = 'XRAY_DATA';
export const GET_XRAYDATA = 'GET_XRAYDATA';
export const GET_PORTFOLIOXRAYLIST = 'GET_PORTFOLIOXRAYLIST';
export const GET_PROFITLIST = 'GET_PROFITLIST';
export const GET_TRANSACTIONDATALIST = 'GET_TRANSACTIONDATALIST';
export const GET_TRANSACTIONDATALIST_EQUITY = 'GET_TRANSACTIONDATALIST_EQUITY';
export const GET_SIPTRACKER = 'GET_SIPTRACKER';
export const GET_SIPTRACKER_EQUITY = 'GET_SIPTRACKER_EQUITY';
export const GET_AMC_LIST = 'GET_AMC_LIST';
export const GET_AVAILABLEINVEST = 'GET_AVAILABLEINVEST';
export const GET_REDEMPTIONLIST = 'GET_REDEMPTIONLIST';
export const GET_TYPELIST = 'GET_TYPELIST';
export const GET_SCHEMELIST = 'GET_SCHEMELIST';
export const EMPTY_PORTFOLIOXRAYLIST = 'EMPTY_PORTFOLIOXRAYLIST';
export const EMPTY_PROFITLIST = 'EMPTY_PROFITLIST';
export const EMPTY_TRANSACTIONDATALIST = 'EMPTY_TRANSACTIONDATALIST';
export const GET_DATECHANGE = "GET_DATECHANGE";
export const EMPTY_SIPTRACKER = "EMPTY_SIPTRACKER";
export const IS_SIGNED = "IS_SIGNED";
export const MF_CATEGORY_ALLOCATION_EXPAND = "MF_CATEGORY_ALLOCATION_EXPAND";
export const EQUITY_CATEGORY_ALLOCATION_EXPAND = "EQUITY_CATEGORY_ALLOCATION_EXPAND"
export const PORTFOLIO_ALLOCATION_EXPAND = "PORTFOLIO_ALLOCATION_EXPAND"
export const BONDS_CATEGORY_ALLOCATION_EXPAND = "BONDS_CATEGORY_ALLOCATION_EXPAND"
export const FD_CATEGORY_ALLOCATION_EXPAND = "FD_CATEGORY_ALLOCATION_EXPAND"
export const GB_CATEGORY_ALLOCATION_EXPAND = "GB_CATEGORY_ALLOCATION_EXPAND"
export const LOGIN = 'GET_LOGIN_DATA';
export const PORTFOLIO_ALLOCATION = 'GET_PORTFOLIO_ALLOCATION';
export const PORTFOLIO_HEADER_DETAILS = 'PORTFOLIO_HEADER_DETAILS';
export const LOADER_PORTFOLIO_HEADER_DETAILS = 'LOADER_PORTFOLIO_HEADER_DETAILS';
export const EMPTY_PORTFOLIO_HEADER_DETAILS = 'EMPTY_PORTFOLIO_HEADER_DETAILS';
export const ASSET_PORTFOLIO_ALLOCATION = 'ASSET_PORTFOLIO_ALLOCATION';
export const ASSET_PORTFOLIO_CHART = 'ASSET_PORTFOLIO_CHART';
export const PRODUCT_PORTFOLIO_CHART = 'PRODUCT_PORTFOLIO_CHART';
export const LOADER_ASSET_PORTFOLIO_ALLOCATION = 'LOADER_ASSET_PORTFOLIO_ALLOCATION';
export const EMPTY_ASSET_PORTFOLIO_ALLOCATION = 'EMPTY_ASSET_PORTFOLIO_ALLOCATION';
// export const EMPTY_ASSET_PORTFOLIO_ALLOCATION = 'EMPTY_ASSET_PORTFOLIO_ALLOCATION';
export const STOREKYCFORMNO = 'STOREKYCFORMNO';
export const GET_PROFITLOSS_DATA = 'GET_PROFITLOSS_DATA';
export const LOADER_FAMILY_DETAILS = 'LOADER_FAMILY_DETAILS';
export const FAMILY_DETAILS = 'FAMILY_DETAILS';
export const EMPTY_FAMILY_DETAILS = 'EMPTY_FAMILY_DETAILS';
export const PROFITLOSS_DATA = 'PROFITLOSS_DATA';
export const GET_HEADERDETAILS = 'GET_HEADERDETAILS';
export const LOADER_HEADERDETAILS = 'LOADER_HEADERDETAILS';
export const LOADER_ALERT = 'LOADER_ALERT';
export const EMPTY_ALERT = 'EMPTY_ALERT';
export const ALERT_DATA = 'ALERT_DATA';
export const DASHBOARD_ASSET = 'DASHBOARD_ASSET';
export const EMPTY_DASHBOARD_ASSET = 'EMPTY_DASHBOARD_ASSET';
export const LOADER_DASHBOARD_ASSET = 'LOADER_DASHBOARD_ASSET';
export const GET_DROPDOWN_DETAILS = 'GET_DROPDOWN_DETAILS';
export const EMPTY_DROPDOWN_DETAILS = 'EMPTY_DROPDOWN_DETAILS';
export const UPDATE_PROFILE_MANDATE = 'UPDATE_PROFILE_MANDATE'
export const UPDATE_PROFILE_NEW_MANDATE = 'UPDATE_PROFILE_NEW_MANDATE'
export const LOGIN_DATA = 'LOGIN_DATA';
export const UCC_ACCOUNT = 'UCC_ACCOUNT';
export const UPDATE_SCROLL = 'UPDATE_SCROLL';
export const UPDATE_LOGIN_DATA = 'UPDATE_LOGIN_DATA';
export const LOGIN_ACCOUNT_SELECTION = 'LOGIN_ACCOUNT_SELECTION';
export const STEP_SIGNUP = 'STEP_SIGNUP';
export const LOADER_CONTINUE = 'LOADER_CONTINUE';
export const LOADER_SIGNUP = 'LOADER_SIGNUP';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGIN_RESET = 'LOGIN_RESET';
export const LOGIN_RESEND = 'LOGIN_RESEND';
export const LOGIN_CHANGE = 'LOGIN_CHANGE';
export const HIDE_MSG = 'HIDE_MSG';
export const CLEAR_LOADER = 'CLEAR_LOADER';
export const PREVENT_API_CALL = 'PREVENT_API_CALL';
export const RESET_PREVENT_API_CALL = 'RESET_PREVENT_API_CALL';
export const BACK_UP_PREVENT_API_CALL = "BACK_UP_PREVENT_API_CALL";
export const CLEAR_LOGINDATA = 'CLEAR_LOGINDATA';
export const DATE_CHANGE = 'DATE_CHANGE';
export const GET_CATEGORYALLOCATION = 'GET_CATEGORYALLOCATION';
export const GET_XRAYFILTER = 'GET_XRAYFILTER';
export const GET_DONUT = 'GET_DONUT';
export const GET_PURCHASED_PLAN = "GET_PURCHASED_PLAN";
export const SORT_BY_A_Z = 'SORT_BY_A_Z';
// export const Equity_SORT_BY_A_Z = 'Equity_SORT_BY_A_Z';
export const SORT_BY_MARKET_VALUE = 'SORT_BY_MARKET_VALUE';
export const SORT_BY_RETURNS = 'SORT_BY_RETURNS';
export const SIPTRACKER_SORT_ALPHA = 'SIPTRACKER_SORT_ALPHA';
export const SIPTRACKER_SORT_BY_CURRENT_VALUE = 'SIPTRACKER_SORT_BY_CURRENT_VALUE';
export const SIPTRACKER_SORT_BY_SIPAMOUNT = 'SIPTRACKER_SORT_BY_SIPAMOUNT';
export const SIPTRACKER_CLEAR = 'SIPTRACKER_CLEAR';
export const SIPTRACKER_FILTER = 'SIPTRACKER_FILTER';
export const SCHEME_CATEGORYLIST_CONTENT = 'SCHEME_CATEGORYLIST_CONTENT';
export const SCHEMES_AMC_DATA = 'SCHEMES_AMC_DATA';
export const SCHEMES_AVAILABLE_DATA = 'SCHEMES_AVAILABLE_DATA';
export const SCHEMES_RATING_DATA = 'SCHEMES_RATING_DATA'
export const TRANSACTION_DETAILS = 'TRANSACTION_DETAILS'
export const SORT_BY_AUM = 'SORT_BY_AUM'
export const SORT_BY_RATING = 'SORT_BY_RATING'
export const FILTER_XRAY_PROFIT = "FILTER_XRAY_PROFIT"
export const FILTER_PROFIT_PROFIT = "FILTER_PROFIT_PROFIT"
export const FILTER_PROFIT_PROFITDROPDOWN = "FILTER_PROFIT_PROFITDROPDOWN"
export const FILTER_TRAN_PROFIT = "FILTER_TRAN_PROFIT"
export const FILTER_MATURITY_TRACKER = "FILTER_MATURITY_TRACKER"
export const FILTER_MATURITY_TRACKER_BONDS = "FILTER_MATURITY_TRACKER_BONDS"

export const FILTER_TRAN_PROFIT_EQUITY = "FILTER_TRAN_PROFIT_EQUITY"
export const EMPTY_FILTERDATA = "EMPTY_FILTERDATA"
export const SCHEME_DATA = 'SCHEME_DATA'
export const ADMIN = 'ADMIN'
export const EKYC_REDIRECTION = 'EKYC_REDIRECTION'
export const SCHEME_FILTER = 'SCHEME_FILTER'
export const ONETIME_ORDER = 'ONETIME_ORDER'
export const SIP_ORDER = 'SIP_ORDER'
export const PURCHASE_ORDER = 'PURCHASE_ORDER'
export const LOADER_PURCHASE_ORDER = 'LOADER_PURCHASE_ORDER'
export const SCHEMES_DETAILS = 'SCHEMES_DETAILS';
export const EMPTY_SCHEMES_DETAILS = 'EMPTY_SCHEMES_DETAILS';
export const LOADER_SCHEMES_DETAILS = 'LOADER_SCHEMES_DETAILS';
export const GET_MASTER = 'GET_MASTER';
export const LOADER_MASTER = 'LOADER_MASTER';
export const EMPTY_MASTER = 'EMPTY_MASTER';
export const SCHEME_ID = 'SCHEME_ID'
export const INSERT_PURCHASEDETAILS = 'INSERT_PURCHASEDETAILS'
export const GET_PORTFOLIO = 'GET_PORTFOLIO'
export const GET_PORTFOLIO_XRAY = 'GET_PORTFOLIO_XRAY'
export const FILTER_PORTFOLIO_XRAY_MUTUAL_FUND = 'FILTER_PORTFOLIO_XRAY_MUTUAL_FUND'
export const GET_SUMMARY = 'GET_SUMMARY'
export const LOADER_ALERT_LIST = "LOADER_ALERT_LIST"
export const GET_TRANSACTION_DATA = 'GET_TRANSACTION_DATA'
export const GET_PROFIT_LOSS = 'GET_PROFIT_LOSS'
export const GET_DROPDOWN = 'GET_DROPDOWN'
export const GET_MANDATE_CREATION_RESPONSE = 'GET_MANDATE_CREATION_RESPONSE'
export const GENERATE_MANDATE = 'GENERATE_MANDATE'
export const GET_MANDATE_TYPES = 'GET_MANDATE_TYPES'
export const GET_MANDATE_COLLECTION = 'GET_MANDATE_COLLECTION'
export const UPDATE_MANDATE = 'UPDATE_MANDATE'
export const UPDATE_PROFILE_DETAILS = 'UPDATE_PROFILE_DETAILS'
export const GET_MANDATE_DETAILS = 'GET_MANDATE_DETAILS'
export const ADD_TO_CART = 'ADD_TO_CART'
export const UPDATE_CART = 'UPDATE_CART'
export const DELETE_CART = 'DELETE_CART'
export const GET_CART_BY_ID = 'GET_CART_BY_ID'
export const GET_ALL_CART = 'GET_ALL_CART'
export const GET_ALL_CART_WITHOUT_API = 'GET_ALL_CART_WITHOUT_API'
export const SAVE_CART_VALUE = 'SAVE_CART_VALUE'
export const EMPTY_PORTFOLIO = 'EMPTY_PORTFOLIO'
export const EMPTY_PROFITLOSS = 'EMPTY_PROFITLOSS'
export const EMPTY_PORTFOLIOXRAY = 'EMPTY_PORTFOLIOXRAY'
export const EMPTY_SUMMARY = 'EMPTY_SUMMARY'
export const EMPTY_TRANSACTION = 'EMPTY_TRANSACTION'
export const EMPTY_DROPDOWN = 'EMPTY_DROPDOWN'
export const EMPTY_DONUT = 'EMPTY_DONUT'
export const LOADER_SPLIT_BY_CATEGORY_EQUITY = 'LOADER_SPLIT_BY_CATEGORY_EQUITY'
export const LOADER_SPLIT_BY_CATEGORY_DEBT = 'LOADER_SPLIT_BY_CATEGORY_DEBT '
export const LOADER_SPLIT_BY_MARKET_CAP_DEBT = 'LOADER_SPLIT_BY_MARKET_CAP_DEBT '
export const LOADER_SPLIT_BY_MARKET_CAP_EQUITY = 'LOADER_SPLIT_BY_MARKET_CAP_EQUITY'
export const EMPTY_LOGIN = 'EMPTY_LOGIN'
export const LOADER_DROPDOWN = 'LOADER_DROPDOWN'
export const DELETE_SIP_CART_REDUCER = 'DELETE_SIP_CART_BY_REDUCER'
export const DELETE_ONETIME_CART_REDUCER = 'DELETE_ONETIME_CART_REDUCER'
export const SENDOTP = 'SENDOTP'
export const VERIFYOTP = 'VERIFYOTP'
export const LINEPERFORMANCE = "LINEPERFORMANCE"
export const EMPTY_LINEPERFORMANCE = "EMPTY_LINEPERFORMANCE"
export const SIX_MONTH_LINEPERFORMANCE = "SIX_MONTH_LINEPERFORMANCE"
export const EMPTY_SIX_MONTH_LINEPERFORMANCE = "EMPTY_SIX_MONTH_LINEPERFORMANCE"
export const THREE_YEAR_LINEPERFORMANCE = "THREE_YEAR_LINEPERFORMANCE"
export const EMPTY_THREE_YEAR_LINEPERFORMANCE = "EMPTY_THREE_YEAR_LINEPERFORMANCE"
export const MAX_LINEPERFORMANCE = "MAX_LINEPERFORMANCE"
export const EMPTY_MAX_LINEPERFORMANCE = "EMPTY_MAX_LINEPERFORMANCE"
export const LOGIN_FLAG = 'LOGIN_FLAG'
export const UPDATE_LOGIN_TIME = 'UPDATE_LOGIN_TIME'

//export const UPDATE_PROFILE_MANDATE="UPDATE_PROFILE_MANDATE"
export const COMPLETERESETPASSWORD = 'COMPLETERESETPASSWORD'
export const PDFXRAY = 'PDFXRAY'
export const PDFTRANSACTION = 'PDFTRANSACTION'
export const EXCELXRAY = 'EXCELXRAY'
export const EXCELTRANSACTION = 'EXCELTRANSACTION'
export const EXPORTREPORT = 'EXPORTREPORT'
export const PURCHASE_ORDER_EMPTY = 'PURCHASE_ORDER_EMPTY'
export const CLIENTID_CHANGE = 'CLIENTID_CHANGE'
export const EMPTY_CARD = 'EMPTY_CARD'
export const SIP_TRACKER = "SIP_TRACKER"
export const EMPTY_SIP_TRACKER = "EMPTY_SIP_TRACKER"
export const CAPITAL_GAIN = "CAPITAL_GAIN"
export const EMPTY_CAPITAL_GAIN = "EMPTY_CAPITAL_GAIN"
export const TOKEN = "TOKEN"
export const GET_DEBT_DONUT = "GET_DEBT_DONUT"
export const EMPTY_DEBT_DONUT = "EMPTY_DEBT_DONUT"
export const TRANSACTION_ORDER_REPORT = "TRANSACTION_ORDER_REPORT"
export const SWITCHING_SIP_TOGGLE = "SWITCHING_SIP_TOGGLE"
export const SWITCHING_ONETIME_TOGGLE = "SWITCHING_ONETIME_TOGGLE"
export const SIP_CART_ONCLICK = "SIP_CART_ONCLICK"
export const ONETIME_CART_ONCLICK = "ONETIME_CART_ONCLICK"
export const UPDATE_SIP_CART_REDUCER = "UPDATE_SIP_CART_REDUCER"
export const UPDATE_ONETIME_CART_REDUCER = "UPDATE_ONETIME_CART_REDUCER"
export const SIGN_UP = "SIGN_UP"
export const EMPTY_CART = "EMPTY_CART"
export const LOADER_PROFILE_DETAILS = "LOADER_PROFILE_DETAILS"
export const KYC = "KYC"
export const PRODUCT = "PRODUCT"
export const FILTER_CLICK = "FILTER_CLICK"
export const TOP_TEN = "TOP_TEN"
export const SIMILAR_SCHEMES = "SIMILAR_SCHEMES"
export const ACCOUNT_STATEMENT = "ACCOUNT_STATEMENT"
export const EPF_LIST = "EPF_LIST"
export const EMPTY_EPF_LIST = "EMPTY_EPF_LIST"
export const DOWNLOAD_REPORT = "DOWNLOAD_REPORT"
export const UPCOMMING_SIP = "UPCOMMING_SIP"
export const MANDATE_REPORT = "MANDATE_REPORT"
export const ASSET_ALLOCATON_REPORT = "ASSET_ALLOCATON_REPORT"
export const SHORT_TERM = "SHORT_TERM"
export const ELSS_MATURITY = "ELSS_MATURITY"
export const EMPTY_ELSS_MATURITY = "EMPTY_ELSS_MATURITY"
export const LOADER_ELSS_MATURITY = 'LOADER_ELSS_MATURITY'
export const EXIT_LOAD = "EXIT_LOAD"
export const ELSS = "ELSS"
export const PORTFOLIO_REPORT = "PORTFOLIO_REPORT"
export const ASSET_ALLOCATION = "ASSET_ALLOCATION"
export const MARKETCAP_ALLOCATION = "MARKETCAP_ALLOCATION"
export const XRAY_WITH_REDEMPTION = "XRAY_WITH_REDEMPTION"
export const RUNNINGSIP = "RUNNINGSIP"
export const EMPTY_ACCOUNT_STATEMENT = "EMPTY_ACCOUNT_STATEMENT"
export const EMPTY_DOWNLOAD_REPORT = "DOWNLOAD_REPORT"
export const EMPTY_UPCOMMING_SIP = "EMPTY_UPCOMMING_SIP"
export const EMPTY_MANDATE_REPORT = "EMPTY_MANDATE_REPORT"
export const EMPTY_XRAY_WITH_REDEMPTION = "EMPTY_XRAY_WITH_REDEMPTION"
export const EMPTY_RUNNINGSIP = "EMPTY_RUNNINGSIP"
export const EMPTY_REDEMPTION = "EMPTY_REDEMPTION"
export const EMPTY_MARKETCAP_ALLOCATION = "EMPTY_MARKETCAP_ALLOCATION"
export const EMPTY_ASSET_ALLOCATION = "EMPTY_ASSET_ALLOCATION"
export const EMPTY_MATURITY_TRACKER = "EMPTY_MATURITY_TRACKER"
export const EMPTY_PORTFOLIO_REPORT = "EMPTY_PORTFOLIO_REPORT"
export const EMPTY_ELSS = "EMPTY_ELSS"
export const EMPTY_EXIT_LOAD = "EMPTY_EXIT_LOAD"
export const EMPTY_SHORT_TERM = "EMPTY_SHORT_TERM"
export const PROFILE_DETAILS = "PROFILE_DETAILS"
export const EMPTY_PROFILE_DETAILS = "EMPTY_PROFILE_DETAILS"
export const LOADER_ACCOUNT_STATEMENT = "LOADER_ACCOUNT_STATEMENT"
export const LOADER_PORTFOLIOXRAY = "LOADER_PORTFOLIOXRAY"
export const LOADER_PROFIT_LOSS = "LOADER_PROFIT_LOSS"
export const LOADER_SIP_TRACKER = "LOADER_SIP_TRACKER"
export const LOADER_MARKETCAP_ALLOCATION = "LOADER_MARKETCAP_ALLOCATION"
export const LOADER_ASSET_ALLOCATION = "LOADER_ASSET_ALLOCATION"
export const LOADER_MATURITY_TRACKER = "LOADER_MATURITY_TRACKER"
export const LOADER_XRAY_WITH_REDEMPTION = "LOADER_XRAY_WITH_REDEMPTION"
export const LOADER_PORTFOLIO_REPORT = "LOADER_PORTFOLIO_REPORT"
export const LOADER_RUNNING_SIP = "LOADER_RUNNING_SIP"
export const LOADER_TRANSACTION_DETAILS = "LOADER_TRANSACTION_DETAILS"
export const EMPTY_TRANSACTION_DETAILS = "EMPTY_TRANSACTION_DETAILS"
export const LOADER_TRANSACTION = "LOADER_TRANSACTION"
export const LOADER_CAPITAL_GAIN = "LOADER_CAPITAL_GAIN"
export const LOADER_EXIT_LOAD = "LOADER_EXIT_LOAD"
export const LOADER_SHORT_TERM = "LOADER_SHORT_TERM"
export const LOADER_ELSS = "LOADER_ELSS"
export const LOADER_SUMMARY = "LOADER_SUMMARY"
export const LOADER_PORTFOLIO = "LOADER_PORTFOLIO"
export const LOADER_TRANSACTION_ORDER_REPORT = "LOADER_TRANSACTION_ORDER_REPORT"
export const LOADER_UPCOMMING_SIP = "LOADER_UPCOMMING_SIP"
export const LOADER_MANDATE_REPORT = "LOADER_MANDATE_REPORT"
export const LOADER_DELETE_CART = "LOADER_DELETE_CART"
export const ASSET_ALLOCATION_NETWORK = "ASSET_ALLOCATION_NETWORK"
export const NETWORK_XRAY = "NETWORK_XRAY"
export const NETWORK_SUMMARY_DATA = "NETWORK_SUMMARY_DATA"
export const NETWORK_SCHEME_DETAILS = "NETWORK_SCHEME_DETAILS"
export const NETWORK_ALL_CART = "NETWORK_ALL_CART"
export const LOADER_ALL_CART = "LOADER_ALL_CART"
export const NETWORK_MANDATE = "NETWORK_MANDATE"
export const ADD_NOMINEE = "ADD_NOMINEE"
export const UPDATE_UCCCODE = "UPDATE_UCCCODE"
export const UPDATE_TRADE_UCC_CODE = "UPDATE_TRADE_UCC_CODE"
export const UPDATE_TRADE_L5ID = "UPDATE_TRADE_L5ID"
export const UPDATE_RESET_FLAG = "UPDATE_RESET_FLAG"
export const LOADER_TRACK_DETAILS = "LOADER_TRACK_DETAILS"
export const TRACK_DETAILS = "TRACK_DETAILS"
export const EMPTY_TRACK_DETAILS = "EMPTY_TRACK_DETAILS"
export const REDEMPTION = "REDEMPTION"
export const SWP = "SWP"
export const SWITCH = "SWITCH"
export const ADD_BANK = "ADD_BANK"
export const UPDATE_PERSONAL_DETAILS = "UPDATE_PERSONAL_DETAILS"
export const DELETE_COUNTRY = "DELETE_COUNTRY"
export const DELETE_NOMINEE = "DELETE_NOMINEE"
export const EMPTY_ADD_BANK = "EMPTY_ADD_BANK"
export const EMPTY_SWITCH = "EMPTY_SWITCH"
export const EMPTY_SWP = "EMPTY_SWP"
export const EMPTY_SIGN_UP = "EMPTY_SIGN_UP"
export const EMPTY_KYC = "EMPTY_KYC"
export const EMPTY_PRODUCT = "EMPTY_PRODUCT"
export const ADD_COUNTRY = "ADD_COUNTRY"
export const MARK_AS_PRIMARY = "MARK_AS_PRIMARY"
export const MATURITY_TRACKER = "MATURITY_TRACKER"
export const MATURITY_TRACKER_BONDS = "MATURITY_TRACKER_BONDS"

export const EMPTY_MATURITY_TRACKER_BONDS = "EMPTY_MATURITY_TRACKER_BONDS"
export const LOADER_MATURITY_TRACKER_BONDS = "LOADER_MATURITY_TRACKER_BONDS"

export const SEARCH_BRANCH = "SEARCH_BRANCH"
export const LOADER_PROFILE = "LOADER_PROFILE"
export const STATUS_INFORMER_PROFILE = "STATUS_INFORMER_PROFILE"
export const PROFILE_MY_PLAN = "PROFILE_MY_PLAN"
export const UPDATE_PROFILE_MY_PLAN = "UPDATE_PROFILE_MY_PLAN"
export const RISK_QUESTION = "RISK_QUESTION"
export const LOADER_RISK_QUESTION = "LOADER_RISK_QUESTION "
export const LOADER_RIA_FLOW_ONLINE_PAYMENT = "LOADER_RIA_FLOW_ONLINE_PAYMENT"
export const RIA_FLOW_ONLINE_PAYMENT = "RIA_FLOW_ONLINE_PAYMENT"
export const LOADER_RIA_FLOW_OFFLINE_PAYMENT = "LOADER_RIA_FLOW_OFFLINE_PAYMENT"
export const LOADER_RIA_ONLINE_PAYMENT_COMPLETE = "LOADER_RIA_ONLINE_PAYMENT_COMPLETE"
export const RIA_ONLINE_PAYMENT_COMPLETE = "RIA_ONLINE_PAYMENT_COMPLETE"
export const RIA_ONLINE_PAYMENT_COMPLETE_LOGIN = "RIA_ONLINE_PAYMENT_COMPLETE_LOGIN"
export const RIA_ONLINE_PAYMENT_COMPLETE_LOGIN_PLAN_FEATURE = 'RIA_ONLINE_PAYMENT_COMPLETE_LOGIN_PLAN_FEATURE'
export const RIA_FLOW_OFFLINE_PAYMENT = "RIA_FLOW_OFFLINE_PAYMENT"
export const RIA_FLOW_OFFLINE_PAYMENT_LOGIN = "RIA_FLOW_OFFLINE_PAYMENT_LOGIN"
export const RIA_FLOW_OFFLINE_PAYMENT_LOGIN_PLAN_FEATURE = 'RIA_FLOW_OFFLINE_PAYMENT_LOGIN_PLAN_FEATURE'
export const EMPTY_RISK_SCORE = "EMPTY_RISK_SCORE"
export const RISK_SCORE = "RISK_SCORE"
export const LOADER_RISK_SCORE = "LOADER_RISK_SCORE"
export const INITIALIZE_ESign = "INITIALIZE_ESign"
export const LOADER_INITIALIZE_ESign = "LOADER_INITIALIZE_ESign"
export const ESIGN_DOWNLOAD = "ESIGN_DOWNLOAD"
export const LOADER_ESIGN_DOWNLOAD = "LOADER_ESIGN_DOWNLOAD"
export const RIA_QUESTION_RESPONSE = "RIA_QUESTION_RESPONSE"
export const LOADER_RIA_QUESTION_RESPONSE = 'LOADER_RIA_QUESTION_RESPONSE'
export const ESIGN_PDF_DOWNLOAD = "ESIGN_PDF_DOWNLOAD"
export const STORE_GST_BANK_DETAILS = 'STORE_GST_BANK_DETAILS'
export const EMPTY_PROFILE_MY_PLAN = "EMPTY_PROFILE_MY_PLAN"
export const LOADER_PROFILE_MY_PLAN = "LOADER_PROFILE_MY_PLAN"
export const PROFILE_MY_PLAN_NETWORK = "PROFILE_MY_PLAN_NETWORK"
export const KYC_PROFILE_DETAILS = "KYC_PROFILE_DETAILS"
export const EMPTY_KYC_PROFILE_DETAILS = "EMPTY_KYC_PROFILE_DETAILS"
export const LOADER_KYC_PROFILE_DETAILS = "LOADER_KYC_PROFILE_DETAILS"
// added by yogitha
export const TRACK_EQUITY_DETAILS = "TRACK_EQUITY_DETAILS"
export const EMPTY_TRACK_EQUITY_DETAILS = "EMPTY_TRACK_EQUITY_DETAILS"
export const TRACK_EQUITY_IMPORT_DETAILS = "TRACK_EQUITY_IMPORT_DETAILS"
export const EMPTY_TRACK_EQUITY_IMPORT_DETAILS = "EMPTY_TRACK_EQUITY_IMPORT_DETAILS"
export const FIXED_DEPOSIT_ASSET_NAME = "FIXED_DEPOSIT_ASSET_NAME"
export const GET_ASSET_NAME = "GET_ASSET_NAME"
export const FIXED_DEPOSIT_FDR_NUMBER = "FIXED_DEPOSIT_FDR_NUMBER"
export const FIXED_DePOSSIT_ADD_ASSET_LIST = "FIXED_DePOSSIT_ADD_ASSET_LIST"
export const GET_REAL_ESTATE_LIST = " GET_REAL_ESTATE_LIST"
export const ADD_UPDATE_REAL_ESTATE_LIST = "ADD_UPDATE_REAL_ESTATE_LIST"
export const LISTING_REALESTATE = "LISTING_REALESTATE"
export const LISTING_BULLION = "LISTING_BULLION"
export const ADD_UPDATE_BULLION_LIST = "ADD_UPDATE_BULLION_LIST"
export const GET_BULLION_LIST = " GET_BULLION_LIST"
export const GET_ALTERNATIVE_LIST = " GET_ALTERNATIVE_LIST"
export const UPDATE_ALTERNATIVE_LIST = " UPDATE_ALTERNATIVE_LIST"
export const ALTERNATIVE_ASSET_LIST = "ALTERNATIVE_ASSET_LIST"
export const DELETE_REAL_ESTATE_LIST = "DELETE_REAL_ESTATE_LIST"
export const DELETE_BULLION_LIST = "DELETE_BULLION_LIST"

//EQUITY

//loader
export const LOADER_EQUITY_ACCOUNT_STATEMENT = "LOADER_EQUITY_ACCOUNT_STATEMENT"
export const LOADER_EQUITY_PORTFOLIOXRAY = "LOADER_EQUITY_PORTFOLIOXRAY"
export const LOADER_EQUITY_PROFIT_LOSS = "LOADER_EQUITY_PROFIT_LOSS"
export const LOADER_EQUITY_SIP_TRACKER = "LOADER_EQUITY_SIP_TRACKER"
export const LOADER_EQUITY_MARKETCAP_ALLOCATION = "LOADER_EQUITY_MARKETCAP_ALLOCATION"
export const LOADER_EQUITY_ASSET_ALLOCATION = "LOADER_EQUITY_ASSET_ALLOCATION"
export const LOADER_EQUITY_XRAY_WITH_REDEMPTION = "LOADER_EQUITY_XRAY_WITH_REDEMPTION"
export const LOADER_EQUITY_PORTFOLIO_REPORT = "LOADER_EQUITY_PORTFOLIO_REPORT"
export const LOADER_EQUITY_RUNNING_SIP = "LOADER_EQUITY_RUNNING_SIP"
export const LOADER_EQUITY_TRANSACTION = "LOADER_EQUITY_TRANSACTION"
export const LOADER_EQUITY_CAPITAL_GAIN = "LOADER_EQUITY_CAPITAL_GAIN"
export const LOADER_EQUITY_EXIT_LOAD = "LOADER_EQUITY_EXIT_LOAD"
export const LOADER_EQUITY_SHORT_TERM = "LOADER_EQUITY_SHORT_TERM"
export const LOADER_EQUITY_ELSS = "LOADER_EQUITY_ELSS"
export const LOADER_EQUITY_SUMMARY = "LOADER_EQUITY_SUMMARY"
export const LOADER_EQUITY_INSTRUMENT = "LOADER_EQUITY_INSTRUMENT"
export const LOADER_DEBT_INSTRUMENT = "LOADER_DEBT_INSTRUMENT"
export const LOADER_EQUITY_PORTFOLIO = "LOADER_EQUITY_PORTFOLIO"
export const LOADER_TRANSACTION_TYPE_DROPDOWN = "LOADER_TRANSACTION_TYPE_DROPDOWN"
export const LOADER_EQUITY_BROKER = "LOADER_EQUITY_BROKER"
export const LOADER_ASSET_DROPDOWN_LIST = "LOADER_ASSET_DROPDOWN_LIST"
export const LOADER_FDR_NUMBER_DROPDOWN_LIST = "LOADER_FDR_NUMBER_DROPDOWN_LIST"
export const LOADER_REAL_ESTATE_LIST = "LOADER_REAL_ESTATE_LIST"
export const LOADER_ALTERNATIVE_LIST = "LOADER_ALTERNATIVE_LIST"
export const LOADER_GET_BULLION_LIST = "LOADER_GET_BULLION_LIST"
export const EQUITY_PROFILE_DETAILS="EQUITY_PROFILE_DETAILS"
export const EMPTY_EQUITY_PROFILE_DETAILS="EMPTY_EQUITY_PROFILE_DETAILS"
export const LOADER_EQUITY_PROFILE_DETAILS="LOADER_EQUITY_PROFILE_DETAILS"
//report
export const EQUITY_ACCOUNT_STATEMENT = "EQUITY_ACCOUNT_STATEMENT"
export const EQUITY_PORTFOLIOXRAY = "EQUITY_PORTFOLIOXRAY"
export const EQUITY_PROFIT_LOSS = "EQUITY_PROFIT_LOSS"
export const EQUITY_SIP_TRACKER = "EQUITY_SIP_TRACKER"
export const EQUITY_MARKETCAP_ALLOCATION = "EQUITY_MARKETCAP_ALLOCATION"
export const EQUITY_ASSET_ALLOCATION = "EQUITY_ASSET_ALLOCATION"
export const EQUITY_XRAY_WITH_REDEMPTION = "EQUITY_XRAY_WITH_REDEMPTION"
export const EQUITY_PORTFOLIO_REPORT = "EQUITY_PORTFOLIO_REPORT"
export const EQUITY_RUNNING_SIP = "EQUITY_RUNNING_SIP"
export const EQUITY_TRANSACTION = "EQUITY_TRANSACTION"
export const EQUITY_CAPITAL_GAIN = "EQUITY_CAPITAL_GAIN"
export const EQUITY_EXIT_LOAD = "EQUITY_EXIT_LOAD"
export const EQUITY_SHORT_TERM = "EQUITY_SHORT_TERM"
export const EQUITY_ELSS = "EQUITY_ELSS"
export const EQUITY_SUMMARY = "EQUITY_SUMMARY"
export const EQUITY_INSTRUMENT = "EQUITY_INSTRUMENT"
export const DEBT_INSTRUMENT = "DEBT_INSTRUMENT"
export const EQUITY_PORTFOLIO = "EQUITY_PORTFOLIO"
export const EQUITY_BROKER = "EQUITY_BROKER"
export const EQUITY_CATEGORYALLOCATION = "EQUITY_CATEGORYALLOCATION"
export const GET_EQUITY_XRAYDATA = "GET_EQUITY_XRAYDATA"
export const FILTER_EQUITY_PROFIT_PROFIT = "FILTER_EQUITY_PROFIT_PROFIT"
export const GET_TRANSACTION_TYPE_DROPDOWN = "GET_TRANSACTION_TYPE_DROPDOWN"

//Empty
export const EMPTY_EQUITY_ACCOUNT_STATEMENT = "EMPTY_EQUITY_ACCOUNT_STATEMENT"
export const EMPTY_EQUITY_PORTFOLIOXRAY = "EMPTY_EQUITY_PORTFOLIOXRAY"
export const EMPTY_EQUITY_PROFIT_LOSS = "EMPTY_EQUITY_PROFIT_LOSS"
export const EMPTY_EQUITY_SIP_TRACKER = "EMPTY_EQUITY_SIP_TRACKER"
export const EMPTY_EQUITY_MARKETCAP_ALLOCATION = "EMPTY_EQUITY_MARKETCAP_ALLOCATION"
export const EMPTY_EQUITY_ASSET_ALLOCATION = "EMPTY_EQUITY_ASSET_ALLOCATION"
export const EMPTY_EQUITY_XRAY_WITH_REDEMPTION = "EMPTY_EQUITY_XRAY_WITH_REDEMPTION"
export const EMPTY_EQUITY_PORTFOLIO_REPORT = "EMPTY_EQUITY_PORTFOLIO_REPORT"
export const EMPTY_EQUITY_RUNNING_SIP = "EMPTY_EQUITY_RUNNING_SIP"
export const EMPTY_EQUITY_TRANSACTION = "EMPTY_EQUITY_TRANSACTION"
export const EMPTY_EQUITY_CAPITAL_GAIN = "EMPTY_EQUITY_CAPITAL_GAIN"
export const EMPTY_EQUITY_EXIT_LOAD = "EMPTY_EQUITY_EXIT_LOAD"
export const EMPTY_EQUITY_SHORT_TERM = "EMPTY_EQUITY_SHORT_TERM"
export const EMPTY_EQUITY_ELSS = "EMPTY_EQUITY_ELSS"
export const EMPTY_EQUITY_SUMMARY = "EMPTY_EQUITY_SUMMARY"
export const EMPTY_EQUITY_INSTRUMENT = "EMPTY_EQUITY_INSTRUMENT"
export const EMPTY_DEBT_INSTRUMENT = "EMPTY_DEBT_INSTRUMENT"
export const EMPTY_EQUITY_PORTFOLIO = "EMPTY_EQUITY_PORTFOLIO"
export const EMPTY_EQUITY_BROKER = "EMPTY_EQUITY_BROKER"
export const EMPTY_TRANSACTION_TYPE_DROPDOWN = "EMPTY_TRANSACTION_TYPE_DROPDOWN"
export const EMPTY_FIXED_DEPOSIT_ASSET_NAME = "EMPTY_FIXED_DEPOSIT_ASSET_NAME"
export const EMPTY_FIXED_DEPOSIT_FDR_NUMBER = "EMPTY_FIXED_DEPOSIT_FDR_NUMBER"
export const EMPTY_ALTERNATIVE_ASSET = "EMPTY_ALTERNATIVE_ASSET"
export const EMPTY_REAL_ESTATE_LIST = "EMPTY_REAL_ESTATE_LIST"
export const EMPTY_BULLION_LIST = "EMPTY_BULLION_LIST"


//*..............Bonds............*

//Loader
export const LOADER_BONDS_SUMMARY = "LOADER_BONDS_SUMMARY"
export const LOADER_BONDS_MARKET_CAP_EQUITY = "LOADER_BONDS_MARKET_CAP_EQUITY"
export const LOADER_BONDS_MARKET_CAP_DEBT = "LOADER_BONDS_MARKET_CAP_DEBT"
export const LOADER_BONDS_PORTFOLIO_X_RAY = "LOADER_BONDS_PORTFOLIO_X_RAY"
export const LOADER_BONDS_TRANSACTION = "LOADER_BONDS_TRANSACTION"
export const LOADER_BONDS_PROFIT_LOSS = "LOADER_BONDS_PROFIT_LOSS"
export const LOADER_BONDS_TAXATION = "LOADER_BONDS_TAXATION"
export const LOADER_BONDS_PORTFOLIO = "LOADER_BONDS_PORTFOLIO"

//Action
export const BONDS_SUMMARY = "BONDS_SUMMARY"
export const BONDS_MARKET_CAP_EQUITY = "BONDS_MARKET_CAP_EQUITY"
export const BONDS_MARKET_CAP_DEBT = "BONDS_MARKET_CAP_DEBT"
export const BONDS_PORTFOLIO_X_RAY = "BONDS_PORTFOLIO_X_RAY"
export const BONDS_FILTER_XRAY_DATA = "BONDS_FILTER_XRAY_DATA"
export const BONDS_TRANSACTION = "BONDS_TRANSACTION"
export const FILTER_TRAN_PROFIT_BONDS = "FILTER_TRAN_PROFIT_BONDS"
export const BONDS_PROFIT_LOSS = "BONDS_PROFIT_LOSS"
export const FILTER_BONDS_PROFIT_PROFIT = "FILTER_BONDS_PROFIT_PROFIT"
export const BONDS_TAXATION = "BONDS_TAXATION"
export const BONDS_PORTFOLIO = "BONDS_PORTFOLIO"


//Empty
export const EMPTY_BONDS_SUMMARY = "EMPTY_BONDS_SUMMARY"
export const EMPTY_BONDS_MARKET_CAP_EQUITY = "EMPTY_BONDS_MARKET_CAP_EQUITY"
export const EMPTY_BONDS_MARKET_CAP_DEBT = "EMPTY_BONDS_MARKET_CAP_DEBT"
export const EMPTY_BONDS_PORTFOLIO_X_RAY = "EMPTY_BONDS_PORTFOLIO_X_RAY"
export const EMPTY_BONDS_TRANSACTION = "EMPTY_BONDS_TRANSACTION"
export const EMPTY_BONDS_PROFIT_LOSS = "EMPTY_BONDS_PROFIT_LOSS"
export const EMPTY_BONDS_TAXATION = "EMPTY_BONDS_TAXATION"
export const EMPTY_BONDS_PORTFOLIO = "EMPTY_BONDS_PORTFOLIO"


//*..............FD............*

//Loader
export const LOADER_FD_SUMMARY = "LOADER_FD_SUMMARY"
export const LOADER_FD_MARKET_CAP_EQUITY = "LOADER_FD_MARKET_CAP_EQUITY"
export const LOADER_FD_MARKET_CAP_DEBT = "LOADER_FD_MARKET_CAP_DEBT"
export const LOADER_FD_PORTFOLIO_X_RAY = "LOADER_FD_PORTFOLIO_X_RAY"
export const LOADER_FD_TRANSACTION = "LOADER_FD_TRANSACTION"
export const LOADER_FD_PROFIT_LOSS = "LOADER_FD_PROFIT_LOSS"
export const LOADER_FD_TAXATION = "LOADER_FD_TAXATION"
export const LOADER_FD_PORTFOLIO = "LOADER_FD_PORTFOLIO"


//Action
export const FD_SUMMARY = "FD_SUMMARY"
export const FD_MARKET_CAP_EQUITY = "FD_MARKET_CAP_EQUITY"
export const FD_MARKET_CAP_DEBT = "FD_MARKET_CAP_DEBT"
export const FD_PORTFOLIO_X_RAY = "FD_PORTFOLIO_X_RAY"
export const FD_FILTER_XRAY_DATA = "FD_FILTER_XRAY_DATA"
export const FD_TRANSACTION = "FD_TRANSACTION"
export const FILTER_TRAN_PROFIT_FD = "FILTER_TRAN_PROFIT_FD"
export const FD_PROFIT_LOSS = "FD_PROFIT_LOSS"
export const FILTER_FD_PROFIT_PROFIT = "FILTER_FD_PROFIT_PROFIT"
export const FD_TAXATION = "FD_TAXATION"
export const FD_PORTFOLIO = "FD_PORTFOLIO"


//Empty
export const EMPTY_FD_SUMMARY = "EMPTY_FD_SUMMARY"
export const EMPTY_FD_MARKET_CAP_EQUITY = "EMPTY_FD_MARKET_CAP_EQUITY"
export const EMPTY_FD_MARKET_CAP_DEBT = "EMPTY_FD_MARKET_CAP_DEBT"
export const EMPTY_FD_PORTFOLIO_X_RAY = "EMPTY_FD_PORTFOLIO_X_RAY"
export const EMPTY_FD_TRANSACTION = "EMPTY_FD_TRANSACTION"
export const EMPTY_FD_PROFIT_LOSS = "EMPTY_FD_PROFIT_LOSS"
export const EMPTY_FD_TAXATION = "EMPTY_FD_TAXATION"
export const EMPTY_FD_PORTFOLIO = "EMPTY_FD_PORTFOLIO"

//*......Loader.......*

//ACCOUNT STATEMENT
export const LOADER_BONDS_ACCOUNT_STATEMENT = "LOADER_BONDS_ACCOUNT_STATEMENT"
//PORTFOLIO REPORT
export const LOADER_BONDS_PORTFOLIO_REPORT = "LOADER_BONDS_PORTFOLIO_REPORT"
//XRAY WITH SOILD UNITS
export const LOADER_BONDS_XRAY_WITH_REDEMPTION = "LOADER_BONDS_XRAY_WITH_REDEMPTION"
//MARKET ALLOCATION
export const LOADER_BONDS_MARKETCAP_ALLOCATION = "LOADER_BONDS_MARKETCAP_ALLOCATION"
//TAXACTION REPORT
export const LOADER_BONDS_CAPITAL_GAIN = "LOADER_BONDS_CAPITAL_GAIN"

//*.......Report Action......*

// ACCOUNT STATEMENT
export const BONDS_ACCOUNT_STATEMENT = "BONDS_ACCOUNT_STATEMENT"
//PORTFOLIO REPORT
export const BONDS_PORTFOLIO_REPORT = "BONDS_PORTFOLIO_REPORT"
//XRAY WITH SOILD UNITS
export const BONDS_XRAY_WITH_REDEMPTION = "BONDS_XRAY_WITH_REDEMPTION"
//MARKETCAP ALLOCATION
export const BONDS_MARKETCAP_ALLOCATION = "BONDS_MARKETCAP_ALLOCATION"
//TAXACTION REPORT
export const BONDS_CAPITAL_GAIN = "BONDS_CAPITAL_GAIN"
//*......Empty......*

//ACCOUNT STATEMENT
export const EMPTY_BONDS_ACCOUNT_STATEMENT = "EMPTY_BONDS_ACCOUNT_STATEMENT"
export const EMPTY_LOADER_BONDS_ACCOUNT_STATEMENT = "EMPTY_LOADER_BONDS_ACCOUNT_STATEMENT"
//PORTFOLIO REPORT
export const EMPTY_BONDS_PORTFOLIO_REPORT = "EMPTY_BONDS_PORTFOLIO_REPORT"
export const EMPTY_LOADER_BONDS_PORTFOLIO_REPORT = "EMPTY_LOADER_BONDS_PORTFOLIO_REPORT"
//XRAY WITH SOILD UNITS
export const EMPTY_BONDS_XRAY_WITH_REDEMPTION = "EMPTY_BONDS_XRAY_WITH_REDEMPTION"
export const EMPTY_LOADER_BONDS_XRAY_WITH_REDEMPTION = "EMPTY_LOADER_BONDS_XRAY_WITH_REDEMPTION"
//MARKETCAP ALLOCATION
export const EMPTY_BONDS_MARKETCAP_ALLOCATION = "EMPTY_BONDS_MARKETCAP_ALLOCATION"
export const EMPTY_LOADER_BONDS_MARKETCAP_ALLOCATION = "EMPTY_LOADER_BONDS_MARKETCAP_ALLOCATION"
//empty Taxiaction report 
export const EMPTY_BONDS_CAPITAL_GAIN = "EMPTY_BONDS_CAPITAL_GAIN"

//*..............FD............*

//*....Loader....*

//ACCOUNT STATEMENT
export const LOADER_FD_ACCOUNT_STATEMENT = "LOADER_FD_ACCOUNT_STATEMENT"
//PORTFOLIO REPORT
export const LOADER_FD_PORTFOLIO_REPORT = "LOADER_FD_PORTFOLIO_REPORT"
//XRAY WITH SOILD UNITS
export const LOADER_FD_XRAY_WITH_REDEMPTION = "LOADER_FD_XRAY_WITH_REDEMPTION"
//MARKET ALLOCATION
export const LOADER_FD_MARKETCAP_ALLOCATION = "LOADER_FD_MARKETCAP_ALLOCATION"
//TAXACTION REPORT
export const LOADER_FD_CAPITAL_GAIN = "LOADER_FD_CAPITAL_GAIN"

//*.....Action.......*

//ACCCOUNT STATEMENT
export const FD_ACCOUNT_STATEMENT = "FD_ACCOUNT_STATEMENT"
//PORTFOLIO REPORT
export const FD_PORTFOLIO_REPORT = "FD_PORTFOLIO_REPORT"
//XRAY WITH SOILD UNITS
export const FD_XRAY_WITH_REDEMPTION = "FD_XRAY_WITH_REDEMPTION"
//MARKETCAP ALLOCATION
export const FD_MARKETCAP_ALLOCATION = "FD_MARKETCAP_ALLOCATION"
//TAXACTION REPORT
export const FD_CAPITAL_GAIN = "FD_CAPITAL_GAIN"

//*.......Empty......*

//ACCOUNT STATEMENT
export const EMPTY_FD_ACCOUNT_STATEMENT = "EMPTY_FD_ACCOUNT_STATEMENT"
export const EMPTY_LOADER_FD_ACCOUNT_STATEMENT = "EMPTY_LOADER_FD_ACCOUNT_STATEMENT"
//PORTFOLIO REPORT
export const EMPTY_FD_PORTFOLIO_REPORT = "EMPTY_FD_PORTFOLIO_REPORT"
export const EMPTY_LOADER_FD_PORTFOLIO_REPORT = "EMPTY_LOADER_FD_PORTFOLIO_REPORT"
//XRAY WITH SOILD UNITS
export const EMPTY_FD_XRAY_WITH_REDEMPTION = "EMPTY_FD_XRAY_WITH_REDEMPTION"
export const EMPTY_LOADER_FD_XRAY_WITH_REDEMPTION = "EMPTY_LOADER_FD_XRAY_WITH_REDEMPTION"
//MARKETCAP ALLOCATION
export const EMPTY_FD_MARKETCAP_ALLOCATION = "EMPTY_FD_MARKETCAP_ALLOCATION"
export const EMPTY_LOADER_FD_MARKETCAP_ALLOCATION = "EMPTY_LOADER_FD_MARKETCAP_ALLOCATION"
//empty Taxiaction report 
export const EMPTY_FD_CAPITAL_GAIN = "EMPTY_FD_CAPITAL_GAIN"

//*..............AIF...........*
//Loader
export const LOADER_AIF_PORTFOLIO_X_RAY = "LOADER_AIF_PORTFOLIO_X_RAY"
export const LOADER_AIF_TRANSACTION = "LOADER_AIF_TRANSACTION"
export const LOADER_AIF_PROFITLOSS = "LOADER_AIF_PROFITLOSS"

//Action
export const AIF_CATEGORY_ALLOCATION_EXPAND = "AIF_CATEGORY_ALLOCATION_EXPAND"
export const AIF_FILTER_XRAY_DATA = "AIF_FILTER_XRAY_DATA"
export const AIF_TRANSACTION = "AIF_TRANSACTION"
export const FILTER_TRAN_PROFIT_AIF = "FILTER_TRAN_PROFIT_AIF"
export const AIF_PROFITLOSS = "AIF_PROFITLOSS"
export const AIF_PORTFOLIO_X_RAY = "AIF_PORTFOLIO_X_RAY"
export const AIF_CATEGORYALLOCATION = "AIF_CATEGORYALLOCATION"

//Empty
export const EMPTY_AIF_PORTFOLIO_X_RAY = "EMPTY_AIF_PORTFOLIO_X_RAY"
export const EMPTY_AIF_TRANSACTION = "EMPTY_AIF_TRANSACTION"
export const EMPTY_AIF_PROFITLOSS = "EMPTY_AIF_PROFITLOSS"

//*..............GB............*

//Loader
export const LOADER_GB_SUMMARY = "LOADER_GB_SUMMARY"
export const LOADER_GB_MARKET_CAP_EQUITY = "LOADER_GB_MARKET_CAP_EQUITY"
export const LOADER_GB_MARKET_CAP_DEBT = "LOADER_GB_MARKET_CAP_DEBT"
export const LOADER_GB_PORTFOLIO_X_RAY = "LOADER_GB_PORTFOLIO_X_RAY"
export const LOADER_GB_TRANSACTION = "LOADER_GB_TRANSACTION"
export const LOADER_GB_PROFIT_LOSS = "LOADER_GB_PROFIT_LOSS"
export const LOADER_GB_TAXATION = "LOADER_GB_TAXATION"
export const LOADER_GB_PORTFOLIO = "LOADER_GB_PORTFOLIO"

//Action
export const GB_SUMMARY = "GB_SUMMARY"
export const GB_MARKET_CAP_EQUITY = "GB_MARKET_CAP_EQUITY"
export const GB_MARKET_CAP_DEBT = "GB_MARKET_CAP_DEBT"
export const GB_PORTFOLIO_X_RAY = "GB_PORTFOLIO_X_RAY"
export const GB_FILTER_XRAY_DATA = "GB_FILTER_XRAY_DATA"
export const GB_TRANSACTION = "GB_TRANSACTION"
export const FILTER_TRAN_PROFIT_GB = "FILTER_TRAN_PROFIT_GB"
export const GB_PROFIT_LOSS = "GB_PROFIT_LOSS"
export const FILTER_GB_PROFIT_PROFIT = "FILTER_GB_PROFIT_PROFIT"
export const FILTER_AIF_PROFIT_PROFIT = "FILTER_AIF_PROFIT_PROFIT"
export const GB_TAXATION = "GB_TAXATION"
export const GB_PORTFOLIO = "GB_PORTFOLIO"


//Empty
export const EMPTY_GB_SUMMARY = "EMPTY_GB_SUMMARY"
export const EMPTY_GB_MARKET_CAP_EQUITY = "EMPTY_GB_MARKET_CAP_EQUITY"
export const EMPTY_GB_MARKET_CAP_DEBT = "EMPTY_GB_MARKET_CAP_DEBT"
export const EMPTY_GB_PORTFOLIO_X_RAY = "EMPTY_GB_PORTFOLIO_X_RAY"
export const EMPTY_GB_TRANSACTION = "EMPTY_GB_TRANSACTION"
export const EMPTY_GB_PROFIT_LOSS = "EMPTY_GB_PROFIT_LOSS"
export const EMPTY_GB_TAXATION = "EMPTY_GB_TAXATION"
export const EMPTY_GB_PORTFOLIO = "EMPTY_GB_PORTFOLIO"


//*..............REALESTATE.......*
//LOADER
export const LOADER_REALESTATE_SUMMARY = "LOADER_REALESTATE_SUMMARY"
export const LOADER_REALESTATE_PROFITLOSS = "LOADER_REALESTATE_PROFITLOSS"
export const LOADER_REALESTATE_PORTFOLIO_X_RAY = "LOADER_REALESTATE_PORTFOLIO_X_RAY"
export const LOADER_REALESTATE_TRANSACTION = "LOADER_REALESTATE_TRANSACTION"




// EMPTY
export const EMPTY_REALESTATE_SUMMARY = "EMPTY_REALESTATE_SUMMARY"
export const EMPTY_REALESTATE_PROFITLOSS = "EMPTY_REALESTATE_PROFITLOSS"
export const EMPTY_REALESTATE_PORTFOLIO_X_RAY = "EMPTY_REALESTATE_PORTFOLIO_X_RAY"
export const EMPTY_REALESTATE_TRANSACTION = "EMPTY_REALESTATE_TRANSACTION"



//ACTION
export const REALESTATE_SUMMARY = "REALESTATE_SUMMARY"
export const REALESTATE_PROFIT_LOSS = "REALESTATE_PROFIT_LOSS"
export const FILTER_REALESTATE_PROFIT_PROFIT = "FILTER_REALESTATE_PROFIT_PROFIT"
export const REALESTATE_CATEGORY_ALLOCATION_EXPAND = "REALESTATE_CATEGORY_ALLOCATION_EXPAND"
export const REALESTATE_PORTFOLIO_X_RAY = "REALESTATE_PORTFOLIO_X_RAY"
export const REALESTATE_FILTER_XRAY_DATA = "REALESTATE_FILTER_XRAY_DATA"
export const REALESTATE_TRANSACTION = "REALESTATE_TRANSACTION"
export const FILTER_TRAN_PROFIT_REALESTATE = "FILTER_TRAN_PROFIT_REALESTATE"
export const REALESTATE_CATEGORYALLOCATION = "REALESTATE_CATEGORYALLOCATION"


//*..............NPS............*

//Loader
export const LOADER_NPS_PORTFOLIO_X_RAY = "LOADER_NPS_PORTFOLIO_X_RAY"
export const LOADER_NPS_TRANSACTION = "LOADER_NPS_TRANSACTION"
export const LOADER_NPS_PROFIT_LOSS = "LOADER_NPS_PROFIT_LOSS"

//Action
export const NPS_CATEGORY_ALLOCATION_EXPAND = "NPS_CATEGORY_ALLOCATION_EXPAND"
export const NPS_PORTFOLIO_X_RAY = "NPS_PORTFOLIO_X_RAY"
export const NPS_FILTER_XRAY_DATA = "NPS_FILTER_XRAY_DATA"
export const NPS_TRANSACTION = "NPS_TRANSACTION"
export const NPS_CATEGORYALLOCATION = "NPS_CATEGORYALLOCATION"
export const FILTER_TRAN_PROFIT_NPS = "FILTER_TRAN_PROFIT_NPS"
export const NPS_PROFIT_LOSS = "NPS_PROFIT_LOSS"
export const FILTER_NPS_PROFIT_PROFIT = "FILTER_NPS_PROFIT_PROFIT"


//Empty
export const EMPTY_NPS_PORTFOLIO_X_RAY = "EMPTY_NPS_PORTFOLIO_X_RAY"
export const EMPTY_NPS_TRANSACTION = "EMPTY_NPS_TRANSACTION"
export const EMPTY_NPS_PROFIT_LOSS = "EMPTY_NPS_PROFIT_LOSS"

//*..............Insurance............*

//Loader
export const LOADER_INSURANCE_PORTFOLIO_X_RAY = "LOADER_INSURANCE_PORTFOLIO_X_RAY"
export const LOADER_INSURANCE_TRANSACTION = "LOADER_INSURANCE_TRANSACTION"
export const LOADER_INSURANCE_PROFIT_LOSS = "LOADER_INSURANCE_PROFIT_LOSS"

//Action
export const INSURANCE_CATEGORY_ALLOCATION_EXPAND = "INSURANCE_CATEGORY_ALLOCATION_EXPAND"
export const INSURANCE_PORTFOLIO_X_RAY = "INSURANCE_PORTFOLIO_X_RAY"
export const INSURANCE_FILTER_XRAY_DATA = "INSURANCE_FILTER_XRAY_DATA"
export const INSURANCE_TRANSACTION = "INSURANCE_TRANSACTION"
export const INSURANCE_CATEGORYALLOCATION = "INSURANCE_CATEGORYALLOCATION"
export const FILTER_TRAN_PROFIT_INSURANCE = "FILTER_TRAN_PROFIT_INSURANCE"
export const INSURANCE_PROFIT_LOSS = "INSURANCE_PROFIT_LOSS"
export const FILTER_INSURANCE_PROFIT_PROFIT = "FILTER_INSURANCE_PROFIT_PROFIT"


//Empty
export const EMPTY_INSURANCE_PORTFOLIO_X_RAY = "EMPTY_INSURANCE_PORTFOLIO_X_RAY"
export const EMPTY_INSURANCE_TRANSACTION = "EMPTY_INSURANCE_TRANSACTION"
export const EMPTY_INSURANCE_PROFIT_LOSS = "EMPTY_INSURANCE_PROFIT_LOSS"

//*......Loader........*

//ACCOUNT STATEMENT
export const LOADER_GB_ACCOUNT_STATEMENT = "LOADER_GB_ACCOUNT_STATEMENT"
//PORTFOLIO REPORT
export const LOADER_GB_PORTFOLIO_REPORT = "LOADER_GB_PORTFOLIO_REPORT"
//XRAY WITH SOILD UNITS
export const LOADER_GB_XRAY_WITH_REDEMPTION = "LOADER_GB_XRAY_WITH_REDEMPTION"
//MARKET ALLOCATION
export const LOADER_GB_MARKETCAP_ALLOCATION = "LOADER_GB_MARKETCAP_ALLOCATION"
//TAXACTION REPORT
export const LOADER_GB_CAPITAL_GAIN = "LOADER_GB_CAPITAL_GAIN"

//*....Action.....*

//ACCOUNT STATEMENT
export const GB_ACCOUNT_STATEMENT = "GB_ACCOUNT_STATEMENT"
//PORTFOLIO REPORT
export const GB_PORTFOLIO_REPORT = "GB_PORTFOLIO_REPORT"
//XRAY WITH SOILD UNITS
export const GB_XRAY_WITH_REDEMPTION = "GB_XRAY_WITH_REDEMPTION"
//MARKETCAP ALLOCATION
export const GB_MARKETCAP_ALLOCATION = "GB_MARKETCAP_ALLOCATION"
//TAXACTION REPORT
export const GB_CAPITAL_GAIN = "GB_CAPITAL_GAIN"

//*......Empty.....*

//ACCOUNT STATEMENT
export const EMPTY_GB_ACCOUNT_STATEMENT = "EMPTY_GB_ACCOUNT_STATEMENT"
export const EMPTY_LOADER_GB_ACCOUNT_STATEMENT = "EMPTY_LOADER_GB_ACCOUNT_STATEMENT"
//PORTFOLIO REPORT
export const EMPTY_GB_PORTFOLIO_REPORT = "EMPTY_GB_PORTFOLIO_REPORT"
export const EMPTY_LOADER_GB_PORTFOLIO_REPORT = "EMPTY_LOADER_GB_PORTFOLIO_REPORT"
//XRAY WITH SOILD UNITS
export const EMPTY_GB_XRAY_WITH_REDEMPTION = "EMPTY_GB_XRAY_WITH_REDEMPTION"
export const EMPTY_LOADER_GB_XRAY_WITH_REDEMPTION = "EMPTY_LOADER_GB_XRAY_WITH_REDEMPTION"
//MARKETCAP ALLOCATION
export const EMPTY_GB_MARKETCAP_ALLOCATION = "EMPTY_GB_MARKETCAP_ALLOCATION"
export const EMPTY_LOADER_GB_MARKETCAP_ALLOCATION = "EMPTY_LOADER_GB_MARKETCAP_ALLOCATION"
//empty Taxiaction report 
export const EMPTY_GB_CAPITAL_GAIN = "EMPTY_GB_CAPITAL_GAIN"

//PMS and AIF
export const EMPTY_AIF_LIST = "EMPTY_AIF_LIST"
export const LOADER_AIF_LIST = "LOADER_AIF_LIST"
export const AIF_LIST = "AIF_LIST"
export const DELETE_AIF_LIST = "DELETE_AIF_LIST"
export const UPDATE_AIF_LIST = "UPDATE_AIF_LIST"
export const ADD_UPDATE_DELETE_TRANSACTION = "ADD_UPDATE_DELETE_TRANSACTION"
export const EMPTY_VALUATION_LIST = "EMPTY_VALUATION_LIST"
export const LOADER_VALUATION_LIST = "LOADER_VALUATION_LIST"
export const ADD_VALUATION_LIST = "ADD_VALUATION_LIST"
export const DELETE_VALUATION_LIST = "DELETE_VALUATION_LIST"
export const UPDATE_VALUATION_LIST = "UPDATE_VALUATION_LIST"
export const EMPTY_TRANSACTION_LIST = "EMPTY_TRANSACTION_LIST"
export const LOADER_TRANSACTION_LIST = "LOADER_TRANSACTION_LIST"
export const TRANSACTION_LIST = "TRANSACTION_LIST"
export const DELETE_TRANSACTION_LIST = "DELETE_TRANSACTION_LIST"
export const UPDATE_TRANSACTION_LIST = "UPDATE_TRANSACTION_LIST"
export const EMPTY_PMS_LIST = "EMPTY_PMS_LIST"
export const LOADER_PMS_LIST = "LOADER_PMS_LIST"
export const PMS_LIST = "PMS_LIST"
export const DELETE_PMS_LIST = "DELETE_PMS_LIST"
export const UPDATE_PMS_LIST = "UPDATE_PMS_LIST"
export const ADD_UPDATE_DELETE_PMS_TRANSACTION = "ADD_UPDATE_DELETE_PMS_TRANSACTION"
export const EMPTY_PMS_VALUATION_LIST = "EMPTY_PMS_VALUATION_LIST"
export const LOADER_PMS_VALUATION_LIST = "LOADER_PMS_VALUATION_LIST"
export const ADD_PMS_VALUATION_LIST = "ADD_PMS_VALUATION_LIST"
export const DELETE_PMS_VALUATION_LIST = "DELETE_PMS_VALUATION_LIST"
export const UPDATE_PMS_VALUATION_LIST = "UPDATE_PMS_VALUATION_LIST"
export const EMPTY_PMS_TRANSACTION_LIST = "EMPTY_PMS_TRANSACTION_LIST"
export const LOADER_PMS_TRANSACTION_LIST = "LOADER_PMS_TRANSACTION_LIST"
export const PMS_TRANSACTION_LIST = "PMS_TRANSACTION_LIST"
export const DELETE_PMS_TRANSACTION_LIST = "DELETE_PMS_TRANSACTION_LIST"
export const UPDATE_PMS_TRANSACTION_LIST = "UPDATE_PMS_TRANSACTION_LIST"


//NPS
export const EMPTY_NPS_LIST = "EMPTY_NPS_LIST"
export const LOADER_NPS_LIST = "LOADER_NPS_LIST"
export const NPS_LIST = "NPS_LIST"
export const DELETE_NPS_LIST = "DELETE_NPS_LIST"
export const UPDATE_NPS_LIST = "UPDATE_NPS_LIST"
export const EMPTY_NPS_ADD_UPDATE_DELETE_TRANSACTION_LIST = "EMPTY_NPS_ADD_UPDATE_DELETE_TRANSACTION_LIST"
export const LOADER_NPS_ADD_UPDATE_DELETE_TRANSACTION_LIST = "LOADER_NPS_TRANSACTION_LIST"
export const NPS_ADD_UPDATE_DELETE_TRANSACTION_LIST = "NPS_ADD_UPDATE_DELETE_TRANSACTION_LIST"
export const EMPTY_NPS_VALUATION_LIST = "EMPTY_NPS_VALUATION_LIST"
export const LOADER_NPS_VALUATION_LIST = "LOADER_NPS_VALUATION_LIST"
export const NPS_VALUATION_LIST = "NPS_VALUATION_LIST"
export const NPS_DROPDOWN_LIST = "NPS_DROPDOWN_LIST"
export const EMPTY_NPS_DROPDOWN_LIST = "EMPTY_NPS_DROPDOWN_LIST"
export const EMPTY_NPS_INSURANCE_DROPDOWN_LIST = "EMPTY_NPS_INSURANCE_DROPDOWN_LIST"
export const NPS_INSURANCE_DROPDOWN_LIST = "NPS_INSURANCE_DROPDOWN_LIST"

//MLD
export const LOADER_MLD_TRANSACTION = "LOADER_MLD_TRANSACTION"
export const MLD_PORTFOLIO_X_RAY = "MLD_PORTFOLIO_X_RAY"
export const LOADER_MLD_PORTFOLIO_X_RAY = "LOADER_MLD_PORTFOLIO_X_RAY"
export const EMPTY_MLD_PORTFOLIO_X_RAY = "EMPTY_MLD_PORTFOLIO_X_RAY"
export const MLD_FILTER_XRAY_DATA = "MLD_FILTER_XRAY_DATA"
export const FILTER_TRAN_PROFIT_MLD = "FILTER_TRAN_PROFIT_MLD"
export const EMPTY_MLD_TRANSACTION = "EMPTY_MLD_TRANSACTION"
export const MLD_TRANSACTION = "MLD_TRANSACTION"

