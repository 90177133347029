import { useEffect, useReducer, useState } from "react";
import useKeyPress from "./useRoveFocus";
import { makeStyles } from '@material-ui/core/styles';
import selectedTick from '../../utils/assets/images/img29.png'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import colors from "../../utils/colors/colors";
import labels from "../../utils/constants/labels";
const initialState = { selectedIndex: '' };
const useStyles = makeStyles((theme) => {
  return {
    listStylewithoutcheckbox: {
      padding: "8px 0px 8px 10px",
      margin: "auto",
      maxWidth: "100% !important",
      fontSize: "0.6rem !important",
      fontWeight: 500,
      overflow: "hidden",
      '&:hover': {
        backgroundColor: window.globalConfig.color.RosyBrown,
        color: window.globalConfig.color.BROWN
      }
      // padding: "10px 0px 9px 11px"
    },
    containeritemwithoutcheckbox: {
      position: "relative",
      // paddingLeft: "30px !important",
      cursor: "pointer",
      userSelect: "none",
      alignItems: "center",
      margin: 0,
      width: "auto !important",
      //padding: "0px 0px 1.25rem 0px !important",
      fontSize: "14px",
      display: "block",
      fontWeight: "normal",
      textAlign: "left"
    },
    checkmark: {
      position: 'absolute',
      top: "4px",
      left: 0,
      height: "18px",
      width: "18px",
      marginLeft: '3px',
      borderRadius: '3px',
      backgroundColor: window.globalConfig.color.WHITE,
      border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER} !important`
    },
    selectedcheckmark: {
      position: 'absolute',
      top: "4px",
      left: 0,
      height: "18px",
      width: "18px",
      marginLeft: '3px',
      borderRadius: '3px',
      backgroundColor: window.globalConfig.color.WHITE,
      border: `1px solid ${window.globalConfig.color.BROWN}`,

      //  transform:`rotate(${1}deg)`,
      background: `url(${selectedTick}) center center no-repeat`,


    },
    scrolableDivnormalsearch: {
      //  padding: "0px 5px 14px 4px",
      overflow: "auto",
      maxHeight: 143,
      // paddingTop:10,
      // paddingBottom:10
      // height:143
    },
    scrolableDiv: {
      padding: 12,
      overflow: "auto",
      maxHeight: 245
    },
    normaldropdownselectedstyle: {
      backgroundColor: window.globalConfig.color.RosyBrown,
      color: window.globalConfig.color.BROWN
    },
    options: {
      border: 0,
      marginTop: "-37px",
      width: "102%",
      marginLeft: "-1%",
      boxShadow: "7px 7px 20px 0 rgb(0 0 0 / 20%)",
      borderRadius: "5px",
      backgroundColor: window.globalConfig.color.WHITE,
      padding: 0,
      position: "absolute",
      top: "100%",
      right: 0,
      left: 0,
      zIndex: 999,
      margin: "3px 0px"
      , listStyle: "none"
    },
    normaldropdownstyle: {
      backgroundColor: window.globalConfig.color.WHITE,
      '&:hover': {
        backgroundColor: window.globalConfig.color.RosyBrown,
        color: window.globalConfig.color.BROWN
      }
    },
    listStyle: {
      padding: "5px 0px",
      margin: "auto",
      maxWidth: "100% !important",
      fontSize: "0.6rem !important",
      fontWeight: 500,
      overflow: "hidden",
      // '&:focus': {
      //   backgroundColor: window.globalConfig.color.RosyDarkBrown
      // }
    },
    containeritem: {
      position: "relative",
      paddingLeft: "30px !important",
      cursor: "pointer",
      userSelect: "none",
      alignItems: "center",
      margin: 0,
      width: "auto !important",
      padding: "0.25rem 0px 0.25rem 33px !important",
      fontSize: "14px",
      display: "block",
      fontWeight: "normal",
      textAlign: "left"
    },
    normaldropdownoptions: {
      border: 0,
      // lineHeight: 3,
      marginTop: "1px",
      width: "102%",
      marginLeft: "-1%",
      boxShadow: "7px 7px 20px 0 rgb(0 0 0 / 20%)",
      borderRadius: "5px",
      backgroundColor: window.globalConfig.color.WHITE,
      padding: 0,
      position: "absolute",
      top: "100%",
      right: 0,
      left: 0,
      zIndex: 1,
      margin: "3px 0px"
      , listStyle: "none"
    },
  }
})
function reducer(state, action) {
  switch (action.type) {
    case "arrowUp":
      return {
        selectedIndex:
          state.selectedIndex !== '' ? state.selectedIndex - 1 : action.payload.length - 1
      };
    case "arrowDown":

      return {
        selectedIndex:
          state.selectedIndex !== action.payload.length - 1 ? state.selectedIndex == '' ? 0 + 1 : state.selectedIndex + 1 : 0
      };
    case "select":
      // 
      return { selectedIndex: action.payload.index };
    default:
      throw new Error();
  }
}
const List = (props) => {
  const arrowUpPressed = useKeyPress("ArrowUp");
  const arrowDownPressed = useKeyPress("ArrowDown");
  const EnterPressed = useKeyPress("Enter");
  const [state, dispatch] = useReducer(reducer, initialState);
  const [selectedIndex, setSelectedIndex] = useState("")

  useEffect(() => {
    if (arrowUpPressed) {
      dispatch({ type: "arrowUp", payload: props.List });
    }
    if (EnterPressed) {
      if (state.selectedIndex != undefined) {
        // 
        //   setSelectedIndex(state.selectedIndex)
        if (props.onKeyPress != undefined) {
          props.onKeyPress(state.selectedIndex)
        }
      }

    }
  }, [arrowUpPressed, EnterPressed]);

  useEffect(() => {
    if (arrowDownPressed) {
      dispatch({ type: "arrowDown", payload: props.List });
    }
    
  }, [arrowDownPressed, EnterPressed, props.List]);

  const classes = useStyles();
  // 

  return (
    <ClickAwayListener onClickAway={props.onClickAway}>

      <ul className={props.type === "withcheckbox" ? classes.options : classes.normaldropdownoptions} style={{ marginTop: props.top }}>
        {props.children}
        <div className={props.type === "withcheckbox" ? classes.scrolableDiv : classes.scrolableDivnormalsearch} style={{ maxHeight: props.height == undefined ? 245 : props.height }}>
          {props.loader == true ? <div style={{ paddingTop: 10, paddingBottom: 10, marginLeft: 10 }}>{labels.messages.loading}</div> : props.List.length == 0 ? <div style={{ paddingTop: 10, paddingBottom: 10, marginLeft: 10 }}>{labels.messages.Dataempty}</div> : props.List.map((item, i) => {            // 
            return (
              <div id={`${props.id}_${item.value}`} className={props.type == "withcheckbox" ? "" : item.isChecked ? classes.normaldropdownselectedstyle : classes.normaldropdownstyle}>
                <li
                  className={props.type === "withcheckbox" ? classes.listStyle : [classes.listStylewithoutcheckbox, classes.sample].join(' ')}
                  key={item.value}

                  onClick={() => {
                    dispatch({ type: "select", payload: { index: i, List: props.List } });
                    if (props.onClick != undefined) {
                      props.onClick(item,)
                    }
                  }}
                  style={{
                    cursor: "pointer",
                    backgroundColor: i === state.selectedIndex ? window.globalConfig.color.RosyBrown : "transparent",
                  }}
                  role="button"
                  aria-pressed={i === state.selectedIndex}
                  tabIndex={0}
                  onKeyPress={(e) => {
                    dispatch({ type: "select", payload: { index: i, List: props.List } });
                    if (props.onClick != undefined) {
                      props.onClick(item)

                    }
                  }}
                >
                  <label className={props.type === "withcheckbox" ? classes.containeritem : classes.containeritemwithoutcheckbox}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <span>
                        {item.label}
                      </span>
                      {item.subLabel != undefined && <span style={{ fontWeight: 100, fontSize: "smaller", color: colors.LABLE_GRAY }}>
                        {item.subLabel}
                      </span>}
                    </div>

                    {props.type === "withcheckbox" ?
                      item.isChecked ?
                        <span className={classes.selectedcheckmark}>

                        </span> : <span className={classes.checkmark}></span> : ""}
                  </label>
                </li>
              </div>
            )
          })}
        </div>
      </ul>
    </ClickAwayListener>
  );
};
export default List;
