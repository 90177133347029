import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
function getRiaFlowOffLinePaymentReportDetails(FormNo,EmailID,PlanCode,GstNumber,PaymentType,ClientID) {
    let data = {};
    data.FormNo = FormNo,
    data.EmailID = EmailID,
    data.PlanCode = PlanCode,
    data.GstNumber = GstNumber,
    data.PaymentType = PaymentType,
    data.ClientID = ClientID     
   
    return Networking.PostApi(ApiValues.RiaOffLineResponse, data).then(result => { return result })
.catch(function (error) {
  return { "status": "F", "message": labels.messages.apierror }
});
}
export default getRiaFlowOffLinePaymentReportDetails