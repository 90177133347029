import { replaceNull } from '../../../utils/commonfunction';
import * as types from '../../constants/actionsType'
import { bindActionCreators } from 'redux';
const initialState = {
    marketcapallocation: [],
    totalmarketcapallocation: [],
    isMarketCapLoading: false,
    //EQUITY
    equity_marketcapallocation: [],
    equity_totalmarketcapallocation: [],
    equity_isMarketCapLoading: false,
    equity_subTotalMarketCapAllocation: []
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.MARKETCAP_ALLOCATION:

            return {
                ...state,
                isMarketCapLoading: false,
                marketcapallocation: action.payload.data != null && action.payload.data.length != 0 ? action.payload.data.sort((a, b) => {
                    var textA = a.SchemeName.toUpperCase();
                    var textB = b.SchemeName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }) : [],
                totalmarketcapallocation: action.payload.table2 != null && action.payload.table2.length != 0 ? action.payload.table2 : [],

            }
        case types.EMPTY_MARKETCAP_ALLOCATION:
            return {
                marketcapallocation: [],
                totalmarketcapallocation: [],
                isMarketCapLoading: false
            }
        case types.LOADER_MARKETCAP_ALLOCATION:
            return {
                ...state,
                isMarketCapLoading: action.payload
            }
        case types.EQUITY_MARKETCAP_ALLOCATION:
            return {
                ...state,
                equity_marketcapallocation: replaceNull(action.payload.data, "NA") != "NA" ? replaceNull(action.payload.data, "NA")?.sort((a, b) => {
                    var textA = a.ScripName.toUpperCase();
                    var textB = b.ScripName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }).map((item) => {
                    return { ...item, SubTotal: replaceNull(action.payload.table1, "NA") != "NA" ? replaceNull(action.payload.table1, "NA").filter((val) => val.CapType.includes(item.CapType))[0]?.Amount || 0 : 0, SubTotalPercentage: replaceNull(action.payload.table1, "NA") != "NA" ? replaceNull(action.payload.table1, "NA").filter((val) => val.CapType.includes(item.CapType))[0]?.Per || 0 : 0 }
                }) : [],
                equity_subTotalMarketCapAllocation: replaceNull(action.payload.table1, "NA"),
                equity_totalmarketcapallocation: replaceNull(action.payload.table2, "NA") != "NA" ? replaceNull(action.payload.table2, "NA") : [],
                equity_isMarketCapLoading: false
            }
        case types.EMPTY_EQUITY_MARKETCAP_ALLOCATION:
            return {
                equity_marketcapallocation: [],
                equity_totalmarketcapallocation: [],
                equity_subTotalMarketCapAllocation: [],
                equity_isMarketCapLoading: false
            }
        case types.LOADER_EQUITY_MARKETCAP_ALLOCATION:
            return {
                ...state,
                equity_isMarketCapLoading: action.payload
            }

        default:
            return state
    }
}
export default reducer
