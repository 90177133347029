import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import Button from '../../components/custombutton/button';
import Text from '../../components/text/text';
import color from "../../utils/colors/colors";
import Label from '../../utils/constants/labels';
import { scrollToTopPage } from '../../utils/commonfunction';
import { withRouter } from 'react-router'
import moment from 'moment';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import colors from '../../utils/colors/colors';
import { NegativeFinding } from '../../utils/commonfunction';
const styles = theme => ({
  collabel: {
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: "14px",
    textAlign: "left",
    paddingBottom: 5
  },
  folioText: {
    fontSize: 14,
    color: window.globalConfig.color.black,
    fontWeight: 'normal'
  },
  btnApply: {
    width: "20%",
    [theme.breakpoints.between("1000", "1050")]: {
      marginRight: 23,
      width: "40%",
    },
    [theme.breakpoints.up("1050")]: {
      marginRight: 23,
    },
    // marginLeft:420,
    // [theme.breakpoints.between("1250","1300")]: {
    //   marginLeft:324,
    // },
    // [theme.breakpoints.between("1300","1400")]: {
    //   marginLeft:365,
    // },
  },
  btnBack: {
    width: "20%",
    marginLeft: 30,
    [theme.breakpoints.between("1000", "1050")]: {
      marginLeft: 20,
      width: "40%",
    },
  },
  divMain: {
    display: "flex",
    flexDirection: "row",
    marginTop: "3%",
    marginLeft: "9%",
    marginRight: "3%",
    marginBottom: "4%",
    paddingBottom: "3%"
  },
  divDetail: {
    height: 'auto',
    width: "60%",
    backgroundColor: window.globalConfig.color.WHITE,
    marginLeft: "7%",
    marginBottom: "4%",
    border: `1px solid ${window.globalConfig.color.BORDER}`,
    boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.3)",
    paddingBottom: "30px"
  },
  root: {
    marginLeft: 26.5,
    marginTop: 20
  },
  content: {
    display: 'block',
    width: "92.2%",
    marginLeft: "4%",
    marginTop: "2%",
    color: window.globalConfig.color.BLACK_PRIMARY,
    backgroundColor: window.globalConfig.color.RosyBrown,
    minHeight: 230,
    paddingBottom: "16%",
    borderRadius: 5
  },
  redeemFrom: {
    marginLeft: 15,
    float: "left",
    marginTop: "4%"
  },
  orderTypes: {
    marginLeft: 15,
    float: "left",
    textAlign: "left"
  },
  divLine: {
    height: "0.5px",
    width: "95%",
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    marginTop: 20,
    marginBottom: "-4%",
    marginLeft: 15
  },
  divLine1: {
    height: "1px",
    width: "100%",
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    marginTop: "5%",
    marginBottom: "-4%",
    // boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.3)",
  },
  //Added by Celsia on 5 Jun 2021 for Mobile Responsive Started
  desktopResponsive: {
    // display: 'block !important',
    // [theme.breakpoints.down("600")]: {
    //   display: 'none !important',
    // },
  },
  mobileResponsive: {
    display: 'none !important',
    [theme.breakpoints.down("600")]: {
      display: 'block!important',
    },
  },
  rootResponsive: {
    marginTop: 20
  },
  contentResponsive: {
    display: 'block',
    marginLeft: "3%",
    marginTop: "2%",
    color: window.globalConfig.color.PRIMARY_BLUE,
    backgroundColor: window.globalConfig.color.RosyBrown,
    height: "100%",
    marginRight: "3%",
    paddingBottom: "3%"
  },
  collabelResponsive: {
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: "12px",
  },
  divLineResponsive: {
    height: "0.5px",
    width: "100%",
    backgroundColor: window.globalConfig.color.lightGray,
    marginTop: 10,
    marginBottom: "2%",
  },
  valueResponsive: {
    fontSize: "12px",
  },
  leftResponsive: {
    textAlign: "left",
    padding: "10px"
  },
  rightResponsive: {
    textAlign: "right",
    padding: "10px"
  },
  centerResponsive: {
    textAlign: "center",
    padding: "10px"
  },
  flexResponsive: {
    marginTop: "8%",
    display: "flex",
    flexDirection: "row"
  },
  backResponsive: {
    flex: 4,
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: "4%"

  },
  confirmResponsive: {
    flex: 4,
    display: "flex",
    justifyContent: "flex-end",
    marginRight: "4%"
  }
  //Ended

});
let status = ""
class STPReview extends React.Component {
  onBackToConfirmOrder = () => {
    this.props.confirmOrder(false);
  }
  componentDidMount() {
    scrollToTopPage();
  }
  render() {
    const { classes } = this.props;
    return (<ErrorBoundary>
      <div className={classes.desktopResponsive}>
        <Grid container className={classes.root}>
          <Text label={Label.STP.ReviewSTPDetails} />
          {/* <Grid item xs={12} style={{ float: "left", fontWeight: "bolder" }}>Review STP Details</Grid> */}
        </Grid>
        <div className={classes.content}>
          <Grid container style={{ display: "block !important" }}>
            <Grid item xs={12} className={classes.redeemFrom}>
              <div style={{ textAlign: "left" }}>
                <div className={classes.collabel}>{Label.STP.STPoutFrom}</div>
                <div style={{ fontWeight: "bold" }}>{this.props.STPscheme} <span className={classes.folioText}>({Label.STP.Folio}: {this.props.folionumber})</span></div>
              </div>
            </Grid>
          </Grid>
          <div className={classes.divLine}></div>
          <Grid container>
            <Grid item xs={12} style={{ marginTop: 50, marginBottom: "2%", marginLeft: 15 }}>
              <div style={{ textAlign: "left" }}>
                <div className={classes.collabel}>{Label.STP.STPinto}</div>
                <div style={{ fontWeight: "bold" }}>{this.props.scheme}</div>
              </div>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20, marginLeft: 15, float: "left" }}>
            <Grid item xs={4}>
              <div style={{ textAlign: "left" }}>
                <div className={classes.collabel}> {Label.STP.STPType}</div>
                <div style={{ fontSize: 14 }}>{this.props.type}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div style={{ textAlign: "left" }}>
                <div className={classes.collabel}> {Label.STP.Amount}</div>
                <div style={{ fontSize: 14 }}>{this.props.amount}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div style={{ textAlign: "left" }}>
                <div className={classes.collabel}> {Label.STP.STPInstalments}</div>
                <div style={{ fontSize: 14 }}>{NegativeFinding(this.props.instalment,"","","","number")}</div>
              </div>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 20, marginLeft: 15, float: "left" }}>
            <Grid item xs={4}>
              <div style={{ textAlign: "left" }}>
                <div className={classes.collabel}> {Label.STP.StartDate}</div>
                <div style={{ fontSize: 14 }}>{`${moment(this.props.selecteddate).format("DD MMM YYYY")}`}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div style={{ textAlign: "left" }}>
                <div className={classes.collabel}>{Label.STP.firstOrder}</div>
                <div style={{ fontSize: 14 }}>{this.props.firstorder}</div>
              </div>
            </Grid>
            <Grid item xs={4}>
              <div style={{ textAlign: "left" }}>
                <div className={classes.collabel}>{Label.STP.UCC}</div>
                <div style={{ fontSize: 14 }}>{this.props.UCC}</div>
              </div>
            </Grid>
          </Grid>
        </div>
        <div className={classes.divLine1}></div>

        <div style={{ display: 'flex', marginTop: "10%", justifyContent: "space-between" }}>
          <div className={classes.btnBack}>
            {/* <Button name={"Back"} page={"normal"} onClick={() => this.props.onPlaceorder(false)}/> */}
            <Button name={"Back"} page={"normal"} disabled={this.props.loader} onClick={() => this.onBackToConfirmOrder()} />
          </div>
          <div className={classes.btnApply} >
            <Button name={"Confirm Order"} page={"primary"} disabled={this.props.loader} loader={this.props.loader} onClick={() => this.props.confirmPayment(true)} />
          </div>
        </div>
      </div>

    
    </ErrorBoundary>
    );
  }
}
export default withStyles(styles)(withRouter(connect(null, null)(STPReview)))
