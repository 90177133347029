import React from 'react';
import color from "../../utils/colors/colors";
const App = (props) => {
    const style = {
        header: {
            fontSize: props.size,
            padding: "0px 3px 6px 0px",
            color:props.color==undefined? window.globalConfig.color.BLACK_PRIMARY:window.globalConfig.color.red,
            textAlign: props.page == "payment" ? "center" : 'left',
            fontWeight: props.weight
        },
        headerRight: {
            fontSize: props.size,
            padding: "0px 3px 6px 0px",
            color:props.color==undefined? window.globalConfig.color.BLACK_PRIMARY:window.globalConfig.color.red,
            textAlign: 'right',
            fontWeight: props.weight
        },
    }
    return (
        <React.Fragment>
            <div style={(props.flag != undefined && props.flag == 'amount') ? style.headerRight : style.header}>{props.text}</div>
        </React.Fragment >
    )
}
export default App;
