import { CastForEducationSharp } from '@material-ui/icons'
import * as types from '../../constants/actionsType'
import secureStorage from '../../../utils/securestorage'
import labels from '../../../utils/constants/labels'
import { replaceNull } from '../../../utils/commonfunction'
const initialState = {
  addToCart: [],
  deleteCart: [],
  updateCart: [],
  cartData: [],
  getAllSipCart: [],
  getAllOnetimeCart: [],
  getCartByID: [],
  isDeleteLoading: false,
  allCart: true,
  isNetworkLow: false,
  isLoading: false
}
const colors = labels.schemeColors
// const repeatFor = (arr, size, data) => {
//   var newArr = new Array(size);

//   for (var i = 0; i < size; i++) {

//     newArr[i] = arr[i % i.length];
//     var b = arr[i];

//     newArr[i] = colors[Math.floor(Math.random() * colors.length)]
//   }
//   var a = data.map((val, index) => {
//     return val.color = newArr[index]
//   })
//   return data;
// }
const repeatFor = (arr, size, data) => {
  var newArr = new Array(size);

  for (var i = 0; i < size; i++) {
    newArr[i] = arr[i % i.length];
    var b = arr[i];
    newArr[i] = colors[Math.floor(Math.random() * colors.length)]
  }
  var a = secureStorage.getItem("amc");
  var a = a.map((val1, index1) => {
    data.map((val, index) => {
      if (val.AMCID == val1.ID) {
        return val.color = colors[index1]
      }
    })
  })
  return data;
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.ADD_TO_CART:
      return {
        ...state,
        addToCart: action.payload == null || action.payload.length == 0 ? state.addToCart : action.payload
      }
    case types.UPDATE_CART:
      return {
        ...state,
        updateCart: action.payload == null || action.payload.length == 0 ? state.updateCart : action.payload
      }

    case types.DELETE_CART:
      return {
        ...state,
        deleteCart: action.payload == null || action.payload.length == 0 ? state.deleteCart : action.payload
        , isDeleteLoading: false
      }
    case types.LOADER_ALL_CART:
      return {
        ...state,
        isLoading: action.payload
      }
    case types.GET_ALL_CART:
      // 
      // console.log(action.payload.data,"carttttt")
      var setcartlength = false

      // if (action.payload.data.filter(e => e.SipAllowed == true).length > 0) {

      //   setcartlength = true
      // }
      // else if (action.payload.data.filter(e => e.OneTimeAllowed == true).length > 0) {

      //   setcartlength = true
      // }
      // else {
      //   setcartlength = false
      // }
      // console.log(action.payload.data)
      state.allCart = action.payload.data != null && action.payload.data.length != 0 ? true : false
      let a = []
      // console.log("sjhfjsdhf")
      if (action.payload.data != null && action.payload.data.length > 0) {
        a = repeatFor(colors, action.payload.data.length, action.payload.data)

      } else {
        a = []
      }
      // console.log(a,action.payload == null || action.payload.length == 0 || action.payload.data == null ? [] : replaceNull(a?.filter(e => e.OneTimeAllowed == true).map((item) => {
      //   return {
      //     ...item, multipleSelect: false
      //   }
      // }), "NA"), action.payload)

      return {
        ...state,
        getAllSipCart: action.payload == null || action.payload.length == 0 || action.payload.data == null ? [] : replaceNull(a?.filter(e => e.SipAllowed == true).map((item) => {
          return {
            ...item, multipleSelect: false
          }
        }), "NA"),
        getAllOnetimeCart: action.payload == null || action.payload.length == 0 || action.payload.data == null ? [] : replaceNull(a?.filter(e => e.OneTimeAllowed == true).map((item) => {
          return {
            ...item, multipleSelect: false
          }
        }), "NA"),
        // allCart: setcartlength,
        isDeleteLoading: false,
        isNetworkLow: false,
      }
    case types.GET_ALL_CART_WITHOUT_API:


      let sip = state.getAllSipCart == "NA" ? [] : state.getAllSipCart

      let updatedSIP = []

      let onetime = state.getAllOnetimeCart
      let updatedOneTime = []
      if (action.flag === "addsip") {
        if (sip.length === 0) {
          let receivedsip = action.data
          receivedsip.multipleSelect = false
          if (Object.keys(action.data).length != 0) {
            updatedSIP = [receivedsip]
          } else {
            updatedSIP = []
          }

        } else {
          let receivedsip = action.data
          receivedsip.multipleSelect = false
          if (Object.keys(action.data).length != 0) {
            updatedSIP = [receivedsip, ...sip]
          } else {
            updatedSIP = []
          }

        }
        sip = updatedSIP
        // 
      } else {
        if (onetime.length === 0) {
          let receivedonetime = action.data
          receivedonetime.multipleSelect = false
          if (Object.keys(action.data).length != 0) {
            updatedOneTime = [receivedonetime]
          } else {
            updatedOneTime = []
          }

        } else {
          let receivedonetime = action.data
          receivedonetime.multipleSelect = false
          if (Object.keys(action.data).length != 0) {
            updatedOneTime = [receivedonetime, ...onetime]
          } else {
            updatedOneTime = []
          }

        }
        onetime = updatedOneTime
      }
      return {
        ...state,
        getAllSipCart: replaceNull(sip, "NA"),
        getAllOnetimeCart: replaceNull(onetime, "NA"),
        allCart: sip != "NA" ? sip.length != 0 : 0 || onetime != "NA" ? onetime.length != 0 : 0 ? true : false,
        isDeleteLoading: false,
        isNetworkLow: false,
      }
    case types.GET_CART_BY_ID:
      return {
        ...state,
        getCartByID: action.payload == null || action.payload.length == 0 ? state.getCartByID : action.payload.data
      }
    case types.SAVE_CART_VALUE:
      let savesipcart = state.getAllSipCart == "NA" ? [] : state.getAllSipCart
      let saveonetimecart = state.getAllOnetimeCart == "NA" ? [] : state.getAllOnetimeCart
      // if (action.flag == "sip") {
      if (savesipcart.length != 0) {
        savesipcart = savesipcart.map((item) => {
          return { ...item, multipleSelect: false }
        })
      }

      if (saveonetimecart.length != 0) {
        saveonetimecart = saveonetimecart.map((item) => {
          return { ...item, multipleSelect: false }
        })
      }
      // }
      return {
        ...state,
        getAllOnetimeCart: replaceNull(saveonetimecart, "NA"),
        getAllSipCart: replaceNull(savesipcart, "NA")
      }
    case types.DELETE_SIP_CART_REDUCER:
      // let selectedSipCarts = action.payload
      // 
      let allsip = state.getAllSipCart
      let updateddata = allsip
      if (allsip.length != 0) {
        let id = action.payload.search("|")
        if (id === -1) {
          updateddata = allsip.filter((tar) => {
            // 
            return action.payload != tar.ID;
          });
          if (updateddata.length != 0) {
            updateddata = updateddata.map((item) => {
              return { ...item, multipleSelect: false }
            })
          }
        } else {
          let spliting = action.payload.split("|")
          // 
          if (spliting != "") {
            spliting.map((item) => {
              updateddata = updateddata != "NA" && updateddata.length != 0 ? updateddata.filter((tar) => {
                return item != tar.ID;
              }) : [];
            })
            if (updateddata.length != 0) {
              updateddata = updateddata.map((item) => {
                return { ...item, multipleSelect: false }
              })
            }
          }
        }
      }
      let data = updateddata != "NA" ? updateddata : []
      if (state.getAllOnetimeCart.length == 0 && data.length == 0) {
        state.allCart = false
      }
      else if (state.getAllOnetimeCart.length > 0 || data.length > 0) {
        state.allCart = true
      }

      return {
        ...state,
        getAllSipCart: replaceNull(updateddata, "NA"),
        allCart: state.allCart,
        isDeleteLoading: false,
      }
    case types.NETWORK_ALL_CART:
      return {
        ...state,
        isDeleteLoading: false,
        isNetworkLow: action.payload,
      }
    case types.DELETE_ONETIME_CART_REDUCER:
      let allonetime = state.getAllOnetimeCart
      let updatedonetimedata = allonetime
      if (allonetime.length != 0) {
        let oneid = action.payload.search("|")
        if (oneid === -1) {
          updatedonetimedata = allonetime.filter((tar) => {
            // 
            return action.payload != tar.ID;
          });
          if (updatedonetimedata.length != 0) {
            updatedonetimedata = updatedonetimedata.map((item) => {
              return { ...item, multipleSelect: false }
            })
          }
        } else {
          let splitingonetime = action.payload.split("|")
          //   
          if (splitingonetime != "") {
            splitingonetime.map((item) => {
              //  
              updatedonetimedata = updatedonetimedata != "NA" && updatedonetimedata.length != 0 ? updatedonetimedata?.filter((tar) => {
                // 
                return item != tar.ID;
              }) : [];
            })
            if (updatedonetimedata.length != 0) {
              updatedonetimedata = updatedonetimedata.map((item) => {
                return { ...item, multipleSelect: false }
              })
            }
          }
        }
      }
      // let allonetime = state.getAllOnetimeCart
      // let updatedonetimedata = state.getAllOnetimeCart
      // if (allonetime.length != 0) {
      //   updatedonetimedata = allonetime.filter((tar) => {
      //     // 
      //     return action.payload != tar.ID;
      //   });

      // }

      // let selectedOnetimeCarts = action.payload.split('|')
      // for (let i = 0; i < selectedOnetimeCarts.length; i++) {
      //   let onetimeIndex = 0;
      //   for (let j = 0; j < state.getAllOnetimeCart.length; j++) {
      //     if (state.getAllOnetimeCart[j].ID == selectedOnetimeCarts[i]) {
      //       onetimeIndex = j
      //     }
      //   }
      //   if (onetimeIndex > -1) {
      //     state.getAllOnetimeCart.splice(onetimeIndex, 1);

      //   }
      // }
      if (state.getAllSipCart.length == 0 && updatedonetimedata.length == 0) {
        state.allCart = false
      }
      else if (updatedonetimedata.length > 0 || state.getAllSipCart.length > 0) {
        state.allCart = true
      }
      return {
        ...state,
        getAllOnetimeCart: replaceNull(updatedonetimedata, "NA"),
        allCart: state.allCart,
        isDeleteLoading: false
      }
    case types.EMPTY_CART:
      return {
        ...state,
        addToCart: [],
        deleteCart: [],
        updateCart: [],
        cartData: [],
        getAllSipCart: [],
        getAllOnetimeCart: [],
        getCartByID: [],
        isDeleteLoading: false,
        allCart: false,
        isNetworkLow: false
      }
    case types.UPDATE_ONETIME_CART_REDUCER:
      // let updateData = action.payload
      // let oldState = state.getAllOnetimeCart
      const elementsIndex = state.getAllOnetimeCart.findIndex((item) => item.ID === action.payload.ID)
      let newArray = [...state.getAllOnetimeCart]
      newArray[elementsIndex] = { ...newArray[elementsIndex], Amount: action.payload.amount }
      // oldState = newArray
      //
      return {
        ...state,
        getAllOnetimeCart: replaceNull(newArray, "NA")
      }

    case types.UPDATE_SIP_CART_REDUCER:
      // let updateData = action.payload

      let oldStatesip = state.getAllSipCart == "NA" ? [] : state.getAllSipCart
      const elementsIndexsip = oldStatesip.findIndex((item) => item.ID === action.payload.ID)
      let newArraysip = [...oldStatesip]
      newArraysip[elementsIndexsip] = { ...newArraysip[elementsIndexsip], Amount: action.payload.amount, Installments: action.payload.Installments, Sipdate: action.payload.Sipdate }
      oldStatesip = newArraysip
      //

      return {
        ...state,
        getAllSipCart: replaceNull(oldStatesip, "NA")
      }


    case types.LOADER_DELETE_CART:
      return {
        ...state,
        isDeleteLoading: action.payload
      }


    default:
      return state
  }
}
export default reducer
