// import labels from '../../utils/constants/labels';
// import { Asset } from '../../utils/mainasset';
// import secureStorage from '../../utils/securestorage';
// import ApiValues from '../url/apiurl'
// import fileDownload from 'js-file-download'
// var axios = require('axios');

// /* name should be camel case in all function */
// function getAllcommonDownloadDetails(url, ext, filename) {
// //-----new method------//
//     // const a = document.createElement('a');
//     // a.style.display = 'none';
//     // a.href = url;
//     // // a.download = filename;

//     // // Append the anchor to the body and click it
//     // document.body.appendChild(a);
//     // a.click();

//     // // Cleanup
//     // window.URL.revokeObjectURL(url);
//     // document.body.removeChild(a);


//     // let authToken = ''


//     // authToken = secureStorage.getItem(`authToken-${secureStorage.getItem("sessionID")}`)

//     // }
//     //  fileDownload(result.data, "Accountstatement2.xlsx")
//      window.open(url);
//     return new Promise((resolve, reject) => {
//         resolve('success')
//         // You can perform asynchronous operations inside this function.   
//         // For example, you can use setTimeout to simulate an asynchronous task.  
//         // setTimeout(() => {
//         //     const randomValue = Math.random();
//         //     if (randomValue < 0.5) {
//         //         resolve(randomValue); // Resolve the promise with a value  
//         //     } else {
//         //         reject(new Error('Promise rejected')); // Reject the promise with an error   
//         //     }
//         // }, 1000);
//         // Simulate a delay of 1 second 
//     });
//     // return axios(url, {
//     //     method: 'GET',
//     //     responseType: 'blob',
//     //     headers: {
//     //         Authorization: labels.apiKey.apiKeys,
//     //         // "SessionID": secureStorage.getItem("sessionID"),
//     //         // 'Access-Control-Allow-Origin': `${window.globalConfig.Domain}`,
//     //         // 'Source': Asset.source,
//     //         // 'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload'
//     //     }
//     //     //Force to receive data in a Blob Format
//     // })
//     //     .then(response => {
//     //         //Create a Blob from the PDF Stream
//     //         const file = new Blob(
//     //             [response.data],
//     //             { type: `${ext.toLowerCase().includes("g") == 1 ? "image/" : "application/"}${ext}` });
//     //         //Build a URL from the file
//     //         if (ext === "xlsx") {
//     //         } else {
//     //             const fileURL = URL.createObjectURL(file);
//     //             //Open the URL on new Window
//     //             window.open(fileURL);
//     //         }
//     //         // if (ext === "pdf") {
//     //         fileDownload(response.data, filename)

//     //         // }
//     //         // if (ext === "xlsx") {
//     //         //     fileDownload(response.data, filename)

//     //         // }
//     //         return 'success'
//     //     })
//     //     .catch(error => {
//     //         if (error?.response?.status == 440 && secureStorage.getItem(`invalidAuth${session}`) == null) {
//     //             secureStorage.setItem(`invalidAuth${session}`, "invalid")
//     //         }
//     //         return 'fail'
//     //     });
// }
// export default getAllcommonDownloadDetails
import Networking from '../../utils/api/apiaccess';
import { EncryptURL } from '../../utils/commonfunction';
import labels from '../../utils/constants/labels';
import { Asset } from '../../utils/mainasset';
import secureStorage from '../../utils/securestorage';
import ApiValues from '../url/apiurl'
import fileDownload from 'js-file-download'
var axios = require('axios');

/* name should be camel case in all function */
function getAllcommonDownloadDetails(url, ext, filename) {
    let authToken = ''

    authToken = secureStorage.getItem(`authToken-${secureStorage.getItem("sessionID")}`)
    const currentDateTime = new Date();
    const formattedDateTime = currentDateTime.toLocaleString();
    let nodeToken = secureStorage.getItem(`nodeToken-${secureStorage.getItem("sessionID")}`)

    return axios("/gw", {
        method: 'GET',
        responseType: 'blob',
        headers: {
            gw: EncryptURL(url),
            Authorization: `Bearer ${nodeToken}`,
            Token: labels.key.apiKey,
            Export:true
        }
        //Force to receive data in a Blob Format
    })
        .then(response => {
            const blob = new Blob([response.data]);       // Create a link and trigger the download
            fileDownload(blob, `${filename.split(".")[0]}-${formattedDateTime}.${ext}`)
            return 'success'
        })
        .catch(error => {
            //console.log(error)
            if (error?.response?.status == 440 && secureStorage.getItem(`invalidAuth${session}`) == null) {
                secureStorage.setItem(`invalidAuth${session}`, "invalid")
            }
            return 'fail'
        });
}
export default getAllcommonDownloadDetails