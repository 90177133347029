import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router';
import { withStyles } from '@material-ui/core';
import Subheader from '../../components/subheader/subheader';
import labels from '../../utils/constants/labels';
import { getL5reportDetails } from "../../redux/actions/L5Report/L5ReportAction";
import Dropdown from '../../components/dropdown/dropdownschemes';
import Textinput from '../../components/textinput/textinput';
import DatePicker from '../../components/datepickers/date_pickers';
import Button from '../../components/custombutton/button';
import colors from '../../utils/colors/colors';
import CommonSnackbar from '../../components/snackbar/snackbar';
import moment from 'moment';
import { scrollToTopPage } from '../../utils/commonfunction';
import { RealEstateListing, getRealEstateList, getBullionList } from '../../redux/actions/DataEntry/real_estate_report';
import { getAlterativeAsset } from '../../redux/actions/DataEntry/common_dropdown_equity';
import getReatEstateDataEntry from '../../api/real_estate_dataentey/real_estate_dataentry_api';
import getbullionDataEntry from '../../api/real_estate_dataentey/bullion_dataentry';
import { alphabetSpaceOnly, numbersandDeciamlOnly, amountCommaSeperator, replaceComma } from '../../utils/commonfunction';
const styles = theme => ({
    // main container style
    root: {
        height: '100%',
        display: 'grid',
        gridTemplateColumns: '10% 88% 10%',
        [theme.breakpoints.down('md')]: {
            gridTemplateColumns: '10% 88% 10%',
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '30px',
            display: 'grid',
            gridTemplateColumns: '5% 100% 0%',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '5px',
            display: 'grid',
            gridTemplateColumns: '4% 100% 0% ',
        },

    },
    // paper card container css
    card: {
        height: 'auto',
        padding: '3% 5% 5% 5%',
        width: "80%",
        margin: "30px 0px 35px 0px",
        boxShadow: 'rgb(19 89 161 / 10%) 0px 0px 20px 0px',
        transition: '0.3s',
        borderRadius: '5px',
        [theme.breakpoints.down('xs')]: {
            marginTop: "10px",
            padding: '3% 5% 3% 5%',
        },
    },
    // herizontal line css
    cardSpareaterLine: {
        height: 0.5,
        width: "100%",
        backgroundColor: 'black',
        marginBottom: '1pc',
        marginTop: '6px',
    },
    // main title css
    header: {
        textAlign: 'left',
        textTransform: 'uppercase',
    },
    //card container css
    rowContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(3,1fr)',
        columnGap: '20px',
        [theme.breakpoints.down("xs")]: {
            gridTemplateColumns: 'repeat(1,1fr)',
        },
    },
    bullionRowContainer: {
        width: '100%',
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)',
        columnGap: '20px',
        rowGap: '15px',
        [theme.breakpoints.down("xs")]: {
            gridTemplateColumns: 'repeat(1,1fr)',
        },
    },
    scendHeader: {
        textAlign: 'left',
        marginTop: '10px',
        textTransform: 'uppercase',
    },
    scendHeaderBuillon: {
        textAlign: 'left',
        marginTop: '10px',
        textTransform: 'uppercase',
    },
    footercontainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    buttonContaniner: {
        display: 'flex',
        flexDirection: 'row',
        margin: '2pc 0px 3pc 0px',
        width: '50%',
        gap: '20px',
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            margin: '1pc 0px 3pc 0px',
        },
    },
});
let timer = ''
class RealEstate extends Component {
    constructor() {
        super();
        this.myDiv = React.createRef();
        this.state = {
            // propery name textbox state
            propertyName: '',
            propertyNameError: '',
            // assetName dropdown
            assetName: '',
            assetNameError: '',
            assetNameId: "",
            //property type dropdown state
            propertyType: "",
            propertyTypeError: '',
            propertyTypeId: '',
            //buy price textbox
            buyPrice: '',
            buyPriceError: '',
            //buy date datepicker
            anchorEl: null,
            buyDate: '',
            buyDateSHowing: moment().format("DD MMM YYYY"),
            buyDateError: '',
            checked: true,
            // Location textbox
            location: '',
            locationError: '',
            //usage dropdown
            usage: '',
            usageError: '',
            usageId: '',
            // property size Textbox
            propertySize: '',
            propertySizeError: '',
            // latest value textbox
            latestValue: '',
            latestValueError: '',
            // latest value datepicker
            anchorElOne: null,
            latestValueDate: '',
            latestValueDateShowing: moment().format("DD MMM YYYY"),
            latestValueDateError: "",
            checkedOne: true,
            // loader
            loader: false,
            isFlag: true,
            openSnackBar: false,
            message: "",
            severity: '',
            ID: '',
        }
    }

    componentDidMount() {
        scrollToTopPage();
        let object = true;
        this.props.getRealEstateList(1, this.props.clientId);
        this.props.getBullionList(1, this.props.clientId);
        if (this.props.location.pathname == "/realestatedataentry") {

            object = true,
                this.setState({
                    isFlag: true,
                })

            if (this.props.location.state.isStatus == "update" && this.props.location.state.isStatus != null && this.props.location.state.isStatus != "" && this.props.location.state.isStatus != undefined && this.props.location.state.data != null && this.props.location.state.data != undefined && this.props.location.state.data != "") {
                var updateData;
                updateData = this.props.location.state.data;
                this.setState({
                    propertyName: updateData.PropertyName.name,
                    propertyType: updateData.PropertyName.subHeader1,
                    propertyTypeId: Number(updateData.PropertyTypeID.name),
                    buyPrice: updateData.BuyPrice.name,
                    buyDate: updateData.BuyPrice.subHeader1,
                    location: updateData.Location.name,
                    usage: updateData.Useage.name,
                    usageId: updateData.UseageID.name,
                    propertySize: updateData.PropertySize.name,
                    latestValue: updateData.ValuationsValue.name,
                    latestValueDate: updateData.ValuationsValue.subHeader1,
                    ID: updateData.ID.name
                })
            }
            else {
            }
        } else {
            object = false,
                this.setState({
                    isFlag: false,
                })
            if (this.props.location.state.isStatus == "update" && this.props.location.state.isStatus != null && this.props.location.state.isStatus != "" && this.props.location.state.isStatus != undefined && this.props.location.state.data != null && this.props.location.state.data != undefined && this.props.location.state.data != "") {
                var updateData;
                updateData = this.props.location.state.data;
                this.setState({
                    assetName: updateData.Bullion.name,
                    assetNameId: updateData.BullionID.name,
                    propertyType: updateData.BullionType.name,
                    propertyTypeId: Number(updateData.BullionTypeID.name),
                    buyPrice: updateData.InvestedValue.name,
                    buyDate: updateData.InvestedDate.name,
                    latestValue: updateData.ValuationsValue.name,
                    latestValueDate: updateData.ValuationsValueDate.name,
                    ID: updateData.ID.name
                })
            }
            else {
            }
        }
    }
    componentDidUpdate(prev) {
        const { location, } = this.props;
        const { isFlag } = this.state;
        // if you change the path means it will work realestate pathis true,bullion is false       
        let a = true
        if (location.pathname == "/realestatedataentry") {
            a = true
        } else {
            a = false
        }
        if (isFlag != a) {
        }
    }
    // propertyName textbox validation
    propertyNameTextboxhandleChange = (event) => {
        if (alphabetSpaceOnly(event.target.value)) {
            // if (event.target.value.length > 10) {
            //     event.preventDefault();
            // }
            this.setState({
                propertyName: event.target.value,
                propertyNameError: '',
            });
        }
        else {
            event.preventDefault();
        }
    }
    // property Dropdown validation
    propertyTypeHandleOnChange = (value) => {
        this.setState({
            propertyType: value[0].label,
            propertyTypeId: value[0].value,
            propertyTypeError: '',
        })
    }
    // assetName dropdown onchange
    assetNameHandleOnChange = (value) => {
        this.setState({
            assetName: value[0].label,
            assetNameId: value[0].value,
            assetNameError: '',
        })
    }
    BuyPriceTextboxhandleChange = (event) => {
        let removeComma = event.target.value == "" ? event.target.value : event.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnly(removeComma.trim(), 14)) {
            this.setState({
                buyPrice: amountCommaSeperator(removeComma.trim()),
                buyPriceError: '',
            });
        }



        // else {
        //     event.preventDefault();
        // }
    }
    // buydate date picker methods start
    handleClick = (e) => {
        this.setState({ anchorEl: e.currentTarget })
    }
    dateChange = (value) => {
        this.setState({ buyDateSHowing: moment(value).format("DD MMM YYYY") })
        // this.setState((prevState, props) => {
        //     return {buyDate: prevState,buyDateSHowing};
        // })

    }
    buyDateHandleApply = (date) => {
        this.setState({
            buyDate: moment(date).format("DD MMM YYYY"),
            anchorEl: null,
            buyDateError: '',
        })
    }
    handleClose1 = () => {
        this.setState({ anchorEl: null })
    }
    // end
    // locationTextbox validation
    LocationTextboxhandleChange = (event) => {
        if (alphabetSpaceOnly(event.target.value)) {
            this.setState({
                location: event.target.value,
                locationError: '',
            });
        }
        else {
            event.preventDefault();
        }
    }

    UsageTextboxhandleChange = (value) => {
        this.setState({
            usage: value[0].label,
            usageId: value[0].value,
            usageError: '',
        })
    }
    PropertySizeTextboxhandleChange = (event) => {
        let removeComma = event.target.value == "" ? event.target.value : event.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnly(removeComma.trim(), 14)) {
            // if (event.target.value.length > 10) {
            //     event.preventDefault();
            // }
            this.setState({
                propertySize: amountCommaSeperator(removeComma.trim()),
                propertySizeError: '',
            });
        }
        else {
            event.preventDefault();
        }
    }
    LatestValueTextboxhandleChange = (event) => {
        let removeComma = event.target.value == "" ? event.target.value : event.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnly(removeComma.trim(), 14)) {
            this.setState({
                latestValue: amountCommaSeperator(removeComma.trim()),
                latestValueError: '',
            });
        }
        else {
            event.preventDefault();
        }

    }
    // latestValueDate datePicker
    handleClickLatestValueDate = (e) => {
        this.setState({ anchorElOne: e.currentTarget })
    }
    latestValueDateChange = (value) => {
        this.setState({ latestValueDateShowing: moment(value).format("DD MMM YYYY") })
        // this.setState((prevState, props) => {
        //     return {latestValueDate: prevState,buyDateSHowing};
        // })
    }
    latestValueDateHandleApply = (date) => {
        this.setState({
            latestValueDate: moment(date).format("DD MMM YYYY"),
            anchorElOne: null,
            latestValueDateError: '',
        })
    }
    latestValueHandleClose1 = () => {
        this.setState({ anchorEl: null })
    }
    // end
    clearTheData = () => {
        this.setState({
            // propery name textbox state
            propertyName: '',
            propertyNameError: '',
            // bulionName dropdown
            // assetName: '',
            assetNameError: '',
            assetNameId: 1,
            //property type dropdown state
            propertyType: "",
            propertyTypeError: '',
            propertyTypeId: '',
            //buy price textbox
            buyPrice: '',
            buyPriceError: '',
            //buy date datepicker
            anchorEl: null,
            buyDate: ' ',
            buyDateSHowing: moment().format("DD MMM YYYY"),
            buyDateError: '',
            checked: true,
            // Location textbox
            location: '',
            locationError: '',
            //usage textbox
            usage: '',
            usageError: '',
            // property size Textbox
            propertySize: '',
            propertySizeError: '',
            // latest value textbox
            latestValue: '',
            latestValueError: '',
            // latest value datepicker
            anchorElOne: null,
            latestValueDate: '',
            latestValueDateShowing: moment().format("DD MMM YYYY"),
            latestValueDateError: "",
        })
    }
    submitClick = () => {
        var PropertyNameVailid = false;
        var PropertyTypeVailid = false;
        var BuyPriceVaild = false;
        var BuyDateVaild = false;
        var LocationVaild = false;
        var UsageVaild = false;
        var PropertySizeVaild = false;
        var LatestValueVaild = false;
        var LatestValueDateVaild = false;
        var AssetNameVaild = false;
        // reateestate textbox validation message
        if (this.state.isFlag == true) {
            if (this.state.propertyName == "" || this.state.propertyName == undefined || this.state.propertyName == null) {
                PropertyNameVailid = false;
                this.setState({
                    propertyNameError: labels.messages.required,
                })
            }
            else {
                PropertyNameVailid = true;
                this.setState({
                    propertyNameError: '',
                })
            }
        }
        // real estate property type dropdown validation
        if (this.state.propertyType == undefined || this.state.propertyType == "" || this.state.propertyType == null) {
            PropertyTypeVailid = false;
            this.setState({
                propertyTypeError: labels.messages.required,
            })
        }
        else {
            PropertyTypeVailid = true;
            this.setState({
                propertyTypeError: '',
            })
        }
        // real estate buy price text box validation
        if (this.state.buyPrice == "" || this.state.buyPrice == undefined || this.state.buyPrice <= 0 || this.state.buyPrice == null) {
            BuyPriceVaild = false;
            this.setState({
                buyPriceError: labels.messages.required
            });
        }
        else {
            BuyPriceVaild = true;
            this.setState({
                buyPriceError: "",
            });
        }
        // real estate Buy Date date picker validation
        if (this.state.buyDate == undefined || this.state.buyDate == "" || this.state.buyDate == null) {
            BuyDateVaild = false;
            this.setState({
                buyDateError: labels.messages.required,
            })
        }
        else {
            BuyDateVaild = true;
            this.setState({
                buyDateError: '',
            })
        }
        if (this.state.isFlag == true) {
            //real estate location textbox 
            if (this.state.location == "" || this.state.location == undefined || this.state.location == null) {
                LocationVaild = false;
                this.setState({
                    locationError: labels.messages.required,
                })
            }
            else {
                LocationVaild = true;
                this.setState({
                    LocationError: '',
                })
            }
            //real estate Usage textbox 
            if (this.state.usage == "" || this.state.usage == undefined || this.state.usage == null) {
                UsageVaild = false;
                this.setState({
                    usageError: labels.messages.required,
                })
            }
            else {
                UsageVaild = true;
                this.setState({
                    usageError: '',
                })
            }
            // real esate  Property Size textbox
            if (this.state.propertySize == "" || this.state.propertySize == undefined || this.state.propertySize == null) {
                PropertySizeVaild = false;
                this.setState({
                    propertySizeError: labels.messages.required,
                })
            }

            else {
                PropertySizeVaild = true;
                this.setState({
                    propertySizeError: " ",
                })
            }
        }
        // real estate latestValue textbox
        if (this.state.latestValue == "" || this.state.latestValue == null || this.state.latestValue == undefined) {
            LatestValueVaild = false;
            this.setState({
                latestValueError: labels.messages.required,
            })
        }
        else {
            LatestValueVaild = true;
            this.setState({
                latestValueError: "",
            })
        }
        // real estate latestValueDate Datepicker
        if (this.state.latestValueDate == "" || this.state.latestValueDate == null || this.state.latestValueDate == undefined) {
            LatestValueDateVaild = false;
            this.setState({
                latestValueDateError: labels.messages.required,
            })
        }
        else {
            LatestValueDateVaild = true;
            this.setState({
                latestValueDateError: "",
            })
        }
        if (this.state.isFlag == false) {
            if (this.state.assetName == undefined || this.state.assetName == "" && this.state.assetName.length == 0 || this.state.assetName == null) {
                AssetNameVaild = false;
                this.setState({
                    assetNameError: labels.messages.required,
                })
            }
            else {
                AssetNameVaild = true;
                this.setState({
                    assetNameError: "",
                })
            }
        }
        // validation process
        var isValidData = false;

        if (this.state.isFlag == true) {
            if (PropertyNameVailid &&
                PropertyTypeVailid &&
                BuyPriceVaild &&
                BuyDateVaild &&
                LocationVaild &&
                UsageVaild &&
                PropertySizeVaild &&
                LatestValueVaild &&
                LatestValueDateVaild) {

                isValidData = true;
            }
            else {
                isValidData = false;
            }
        }
        else {
            if (AssetNameVaild &&
                PropertyTypeVailid &&
                BuyPriceVaild &&
                BuyDateVaild &&
                LatestValueVaild &&
                LatestValueDateVaild) {
                isValidData = true;
            }
            else {
                isValidData = false;
            }
        }
        if (isValidData) {
            let data;
            if (this.state.isFlag == true) {
                data = {
                    id: this.props.location.state.isStatus != "update" ? 0 : this.state.ID,
                    propertyName: this.state.propertyName,
                    propertyTypeID: this.state.propertyTypeId,
                    buyPrice: Number(replaceComma(this.state.buyPrice)),
                    buyDate: this.state.buyDate,
                    location: this.state.location,
                    useageID: this.state.usageId,
                    propertySize: Number(replaceComma(this.state.propertySize)),
                    latestValue: Number(replaceComma(this.state.latestValue)),
                    latestValueDate: this.state.latestValueDate,
                    clientID: this.props.logindetails.ClientID,
                    valuationsTypeID: 1,
                    flag: this.props.location.state.isStatus != "update" ? 1 : 2,
                    sellDate: null,
                    sellPrice: 0.0,
                    accountID: this.props.login.L4_AccountTypeID,
                    accounttypeID: this.props.logindetails.L5ID

                }

                this.setState({ loader: true })
                getReatEstateDataEntry(data).then((resp) => {
                    this.setState({ loader: true })
                    if (resp.status === "S") {
                        this.setState({ loader: false })
                        this.setState({ openSnackBar: true, severity: 'success', message: labels.messages.transactionUpdatedMessage })

                        timer = setTimeout(() => {
                            this.props.history.push({
                                pathname: labels.Path.RealEstateListing,
                            })
                        }, 1000);
                        // this.props.RealEstateListing(resp.table1, "realestate", this.props.location.state.isStatus != "update" ? "add" : "update");
                        this.clearTheData();
                    }
                    else {
                        this.setState({ openSnackBar: true, severity: 'warning', message: resp.message })
                        this.clearTheData();
                    }
                })


                // this.clearTheData();
            }
            else {
                data = {
                    id: this.props.location.state.isStatus != "update" ? 0 : this.state.ID,
                    bullionID: this.state.assetNameId,
                    bullionTypeID: this.state.propertyTypeId,
                    investedValue: Number(replaceComma(this.state.buyPrice)),
                    investedDate: this.state.buyDate,
                    latestValue: Number(replaceComma(this.state.latestValue)),
                    latestValueDate: this.state.latestValueDate,
                    clientID: this.props.logindetails.ClientID,
                    flag: this.props.location.state.isStatus != "update" ? 1 : 2,
                    sellDate: null,
                    sellPrice: 0.0,
                    accountID: this.props.login.L4_AccountTypeID,
                    accounttypeID: this.props.logindetails.L5ID

                }
                this.setState({ loader: true })
                getbullionDataEntry(data).then((resp) => {
                    this.setState({ loader: true })
                    if (resp.status === "S") {
                        this.setState({ loader: false })
                        this.setState({ openSnackBar: true, severity: 'success', message: resp.message })
                        // this.props.RealEstateListing(resp.table1,"bullion","add");
                        // this.props.RealEstateListing(resp.table1, "bullion", this.props.location.state.isStatus != "update" ? "add" : "update");
                        timer = setTimeout(() => {
                            this.props.history.push({
                                pathname: labels.Path.Bullionlisting,
                            })
                        }, 1000);
                        this.clearTheData();
                    }
                    else {
                        this.setState({ loader: false })
                        this.clearTheData();
                    }
                })
                // // let storeData = [{
                // //     "propertyType": this.state.propertyTypeId,
                // //     "buyPrice": this.state.buyPrice,
                // //     "buyDate": this.state.buyDate,
                // //     "assetName": this.state.assetName,
                // //     "latestValue": this.state.propertySize,
                // //     "latestValueDate": this.state.latestValueDate,
                // // }]
                // // this.props.RealEstateListing(storeData, "bullion");

                // this.clearTheData();
            }
        }


    }
    componentWillUnmount() {
        clearTimeout(timer)
    }
    saveAndMoreClick = () => {
        var PropertyNameVailid = false;
        var PropertyTypeVailid = false;
        var BuyPriceVaild = false;
        var BuyDateVaild = false;
        var LocationVaild = false;
        var UsageVaild = false;
        var PropertySizeVaild = false;
        var LatestValueVaild = false;
        var LatestValueDateVaild = false;
        var AssetNameVaild = false;
        // reateestate textbox validation message
        if (this.state.isFlag == true) {
            if (this.state.propertyName == "" || this.state.propertyName == undefined || this.state.propertyName == null) {
                PropertyNameVailid = false;
                this.setState({
                    propertyNameError: labels.messages.required,
                })
            }
            else {
                PropertyNameVailid = true;
                this.setState({
                    propertyNameError: '',
                })
            }
        }
        // real estate property type dropdown validation
        if (this.state.propertyType == undefined || this.state.propertyType == "" || this.state.propertyType == null) {
            PropertyTypeVailid = false;
            this.setState({
                propertyTypeError: labels.messages.required,
            })
        }
        else {
            PropertyTypeVailid = true;
            this.setState({
                propertyTypeError: '',
            })
        }
        // real estate buy price text box validation
        if (this.state.buyPrice == "" || this.state.buyPrice == undefined || this.state.buyPrice <= 0 || this.state.buyPrice == null) {
            BuyPriceVaild = false;
            this.setState({
                buyPriceError: labels.messages.required
            });
        }
        else {
            BuyPriceVaild = true;
            this.setState({
                buyPriceError: "",
            });
        }
        // real estate Buy Date date picker validation
        if (this.state.buyDate == undefined || this.state.buyDate == "" || this.state.buyDate == null) {
            BuyDateVaild = false;
            this.setState({
                buyDateError: labels.messages.required,
            })
        }
        else {
            BuyDateVaild = true;
            this.setState({
                buyDateError: '',
            })
        }
        if (this.state.isFlag == true) {
            //real estate location textbox 
            if (this.state.location == "" || this.state.location == undefined || this.state.location == null) {
                LocationVaild = false;
                this.setState({
                    locationError: labels.messages.required,
                })
            }
            else {
                LocationVaild = true;
                this.setState({
                    LocationError: '',
                })
            }
            //real estate Usage textbox 
            if (this.state.usage == "" || this.state.usage == undefined || this.state.usage == null) {
                UsageVaild = false;
                this.setState({
                    usageError: labels.messages.required,
                })
            }
            else {
                UsageVaild = true;
                this.setState({
                    usageError: '',
                })
            }
            // real esate  Property Size textbox
            if (this.state.propertySize == "" || this.state.propertySize == undefined || this.state.propertySize == null) {
                PropertySizeVaild = false;
                this.setState({
                    propertySizeError: labels.messages.required,
                })
            }

            else {
                PropertySizeVaild = true;
                this.setState({
                    propertySizeError: " ",
                })
            }
        }
        // real estate latestValue textbox
        if (this.state.latestValue == "" || this.state.latestValue == null || this.state.latestValue == undefined) {
            LatestValueVaild = false;
            this.setState({
                latestValueError: labels.messages.required,
            })
        }
        else {
            LatestValueVaild = true;
            this.setState({
                latestValueError: "",
            })
        }
        // real estate latestValueDate Datepicker
        if (this.state.latestValueDate == "" || this.state.latestValueDate == null || this.state.latestValueDate == undefined) {
            LatestValueDateVaild = false;
            this.setState({
                latestValueDateError: labels.messages.required,
            })
        }
        else {
            LatestValueDateVaild = true;
            this.setState({
                latestValueDateError: "",
            })
        }
        if (this.state.isFlag == false) {
            if (this.state.assetName == undefined || this.state.assetName == "" || this.state.assetName == null) {
                AssetNameVaild = false;
                this.setState({
                    assetNameError: labels.messages.required,
                })
            }
            else {
                AssetNameVaild = true;
                this.setState({
                    assetNameError: "",
                })
            }
        }
        // validation process
        var isValidData = false;

        if (this.state.isFlag == true) {
            if (PropertyNameVailid &&
                PropertyTypeVailid &&
                BuyPriceVaild &&
                BuyDateVaild &&
                LocationVaild &&
                UsageVaild &&
                PropertySizeVaild &&
                LatestValueVaild &&
                LatestValueDateVaild) {

                isValidData = true;
            }
            else {
                isValidData = false;
            }
        }
        else {
            if (AssetNameVaild &&
                PropertyTypeVailid &&
                BuyPriceVaild &&
                BuyDateVaild &&
                LatestValueVaild &&
                LatestValueDateVaild) {
                isValidData = true;
            }
            else {
                isValidData = false;
            }
        }
        if (isValidData) {
            let data;
            if (this.state.isFlag == true) {
                data = {
                    id: this.props.location.state.isStatus != "update" ? 0 : this.state.ID,
                    propertyName: this.state.propertyName,
                    propertyTypeID: this.state.propertyTypeId,
                    buyPrice: Number(replaceComma(this.state.buyPrice)),
                    buyDate: this.state.buyDate,
                    location: this.state.location,
                    useageID: this.state.usageId,
                    propertySize: Number(replaceComma(this.state.propertySize)),
                    latestValue: Number(replaceComma(this.state.latestValue)),
                    latestValueDate: this.state.latestValueDate,
                    clientID: this.props.logindetails.ClientID,
                    valuationsTypeID: 1,
                    flag: this.props.location.state.isStatus != "update" ? 1 : 2,
                    sellDate: null,
                    sellPrice: 0,
                    accountID: this.props.login.L4_AccountTypeID,
                    accounttypeID: this.props.logindetails.L5ID

                }

                this.setState({ loader: true })
                getReatEstateDataEntry(data).then((resp) => {
                    this.setState({ loader: true })
                    if (resp.status === "S") {
                        this.setState({ loader: false })
                        this.setState({ openSnackBar: true, severity: 'success', message: labels.messages.transactionSucessMessage })

                        // this.props.RealEstateListing(resp.table1, "realestate", this.props.location.state.isStatus != "update" ? "add" : "update");
                        this.clearTheData();
                    }
                    else {
                        this.setState({ openSnackBar: true, severity: 'warning', message: resp.message })
                        this.clearTheData();
                    }
                })
            }
            else {
                data = {
                    id: this.props.location.state.isStatus != "update" ? 0 : this.state.ID,
                    bullionID: this.state.assetNameId,
                    bullionTypeID: this.state.propertyTypeId,
                    investedValue: Number(replaceComma(this.state.buyPrice)),
                    investedDate: this.state.buyDate,
                    latestValue: Number(replaceComma(this.state.latestValue)),
                    latestValueDate: this.state.latestValueDate,
                    clientID: this.props.logindetails.ClientID,
                    flag: this.props.location.state.isStatus != "update" ? 1 : 2,
                    sellDate: null,
                    sellPrice: 0,
                    accountID: this.props.login.L4_AccountTypeID,
                    accounttypeID: this.props.logindetails.L5ID

                }
                this.setState({ loader: true })
                getbullionDataEntry(data).then((resp) => {
                    this.setState({ loader: true })
                    if (resp.status === "S") {
                        this.setState({ loader: false })
                        this.setState({ openSnackBar: true, severity: 'success', message: labels.messages.transactionSucessMessage })
                        this.clearTheData();
                    }
                    else {
                        this.setState({ loader: false })
                        this.clearTheData();
                    }
                })

            }
        }


    }
    handleToastClose = () => {
        this.setState({ openSnackBar: false })
    }
    handleCancelClick = () => {
        // console.log("hi")
        if (this.state.isFlag == true) {
            this.props.history.push(labels.Path.RealEstateListing)
        } else {
            this.props.history.push(labels.Path.Bullionlisting)
        }
    }
    handleFocus = (event) => event.target.select();
    render() {
        const { classes } = this.props;
        try {
            return (
                <>
                    <Subheader heading={this.state.isFlag == true ? labels.productList.RealEstate : labels.productList.Bullion} description={this.state.isFlag == true ? labels.Transact.realEstate : labels.Transact.bullion} />
                    <div className={classes.root} ref={this.MyRealEstate}>
                        <div></div>
                        <div className={classes.card}>
                            <div className={classes.header}>{this.props.location.state.isStatus != "update" ? "Add" : "Update"} {this.state.isFlag == true ? labels.productList.RealEstate : labels.productList.Bullion} Information</div>
                            <div className={classes.cardSpareaterLine}></div>
                            <div className={this.state.isFlag == true ? classes.rowContainer : classes.bullionRowContainer}>
                                {this.state.isFlag == false &&
                                    <Dropdown type={"normalwithsearch"}
                                        label={labels.RealEstate.AssetName}
                                        autoselect={true}
                                        id={"realestate2"}
                                        loader={this.props.isLoading}
                                        isSearchable={false}
                                        value={this.state.assetName}
                                        List={this.props.assetypeList}
                                        placeholder={"Select" + " " + labels.RealEstate.BullionName}
                                        error={this.state.assetNameError}
                                        handleOnChange={this.assetNameHandleOnChange}
                                    />
                                }
                                {this.state.isFlag == true &&
                                    <Textinput
                                        page="normal"
                                        placeholder={"Enter" + " " + labels.RealEstate.propertyName}
                                        label={labels.RealEstate.propertyName}
                                        paddingAlign="5px"
                                        paddingLeftAlignment="-1px"
                                        
                                        value={this.state.propertyName}
                                        handleChange={(event) => this.propertyNameTextboxhandleChange(event)}
                                        error={this.state.propertyNameError}
                                        otherProps={{ autoFocus: true, onFocus: this.handleFocus,maxLength:75 }}
                                    />
                                }
                                <Dropdown type={"normalwithsearch"}
                                    label={this.state.isFlag == true ? labels.RealEstate.propertyType : labels.RealEstate.BulionType}
                                    autoselect={false}
                                    id={"realestate1"}
                                    loader={this.props.isLoading}
                                    isSearchable={false}
                                    value={this.state.propertyType}
                                    List={this.state.isFlag == true ? this.props.propertyTypeList : this.props.typeList}
                                    placeholder={"Select" + " " + (this.state.isFlag == true ? labels.RealEstate.propertyType : labels.RealEstate.BulionType)}
                                    error={this.state.propertyTypeError}
                                    handleOnChange={this.propertyTypeHandleOnChange}
                                />
                                <Textinput
                                    page="normal"
                                    placeholder={labels.currency.Rupee + "0"}
                                    label={this.state.isFlag == true ? labels.RealEstate.BuyPrice : labels.RealEstate.InvestedValue}
                                    paddingAlign="5px"
                                    align="right"
                                    paddingLeftAlignment="-1px"
                                    otherProps={{ maxLength: 20 }}
                                    value={this.state.buyPrice}
                                    handleChange={(event) => this.BuyPriceTextboxhandleChange(event)}
                                    error={this.state.buyPriceError}
                                />
                                <div className={classes.subContent}>
                                    <div >
                                        <div style={{
                                            color: colors.LABLE_GRAY,
                                            textAlign: "left",
                                            fontSize: '14px',
                                            fontWeight: 'normal',
                                            padding: '0px 0px 5px 0px',
                                        }}>{this.state.isFlag == true ? labels.RealEstate.BuyDate : labels.RealEstate.InvestedDate}</div>
                                    </div>
                                    {this.state.isFlag == true ?
                                        <DatePicker
                                            pages="FdForm"
                                            id={"realesteatdatepicker1"}
                                            handleClick={(e) => this.handleClick(e)}
                                            handleApply={this.buyDateHandleApply}
                                            showingdate={this.state.buyDateSHowing}
                                            dateChange={this.dateChange}
                                            handleClose={this.handleClose1}
                                            anchorEl={this.state.anchorEl}
                                            error={this.state.buyDateError}
                                            allowed={!this.state.checked}
                                            date={this.state.buyDate}
                                            maxdate={moment().format("DD MMM YYYY")}
                                            placeholder={labels.RealEstate.placeHolderBuyDate}
                                        />
                                        :
                                        <DatePicker
                                            pages="FdForm"
                                            id={"realesteatdatepicker3"}
                                            handleClick={(e) => this.handleClick(e)}
                                            handleApply={this.buyDateHandleApply}
                                            showingdate={this.state.buyDateSHowing}
                                            dateChange={this.dateChange}
                                            handleClose={this.handleClose1}
                                            anchorEl={this.state.anchorEl}
                                            error={this.state.buyDateError}
                                            allowed={!this.state.checked}
                                            date={this.state.buyDate}
                                            maxdate={moment().format("DD MMM YYYY")}
                                            placeholder={labels.RealEstate.PlaceHolderInvestedDate}
                                        />
                                    }
                                </div>
                                {this.state.isFlag == true &&
                                    <>
                                        <Textinput
                                            page="normal"
                                            placeholder={labels.RealEstate.LocationPlaceHolder}
                                            label={labels.RealEstate.Location}
                                            paddingAlign="5px"
                                            paddingLeftAlignment="-1px"
                                            value={this.state.location}
                                            handleChange={(event) => this.LocationTextboxhandleChange(event)}
                                            error={this.state.locationError}
                                        />
                                        <Dropdown type={"normalwithsearch"}
                                            label={labels.RealEstate.Usage}
                                            autoselect={false}
                                            id={"realestate3"}
                                            loader={this.props.isLoading}
                                            isSearchable={false}
                                            value={this.state.usage}
                                            List={this.props.usageList}
                                            placeholder={labels.RealEstate.UsagePlaceHolder}
                                            error={this.state.usageError}
                                            handleOnChange={this.UsageTextboxhandleChange}
                                        />
                                        <Textinput
                                            page="normal"
                                            placeholder={labels.messages.amountZeroPlaceholder}
                                            label={labels.RealEstate.PropertySize}
                                            paddingAlign="5px"
                                            paddingLeftAlignment="-1px"
                                            align="right"
                                            otherProps={{ maxLength: 20 }}
                                            value={this.state.propertySize}
                                            handleChange={(event) => this.PropertySizeTextboxhandleChange(event)}
                                            error={this.state.propertySizeError}
                                        />
                                    </>
                                }

                            </div>
                            {this.props.location.state.isStatus != "update" &&
                                <>
                                    < div className={classes.scendHeader}>{labels.RealEstate.Valuation}</div>
                                    <div className={classes.cardSpareaterLine}></div>
                                    <div className={this.state.isFlag == true ? classes.rowContainer : classes.bullionRowContainer}>
                                        <Textinput
                                            page="normal"
                                            placeholder={labels.messages.amountZeroPlaceholder}
                                            label={labels.RealEstate.latestValue}
                                            paddingAlign="5px"
                                            align="right"
                                            paddingLeftAlignment="-1px"
                                            otherProps={{ maxLength: 20 }}
                                            value={this.state.latestValue}
                                            handleChange={(event) => this.LatestValueTextboxhandleChange(event)}
                                            error={this.state.latestValueError}
                                        />

                                        <div className={classes.subContent}>
                                            <div >
                                                <div style={{
                                                    color: colors.LABLE_GRAY,
                                                    textAlign: "left",
                                                    fontSize: '14px',
                                                    fontWeight: 'normal',
                                                    padding: '0px 0px 5px 0px',
                                                }}>{labels.RealEstate.latestValueDate}</div>
                                            </div>
                                            <DatePicker
                                                pages="FdForm"
                                                id={"realesteatdatepicker2"}
                                                handleClick={(e) => this.handleClickLatestValueDate(e)}
                                                handleApply={this.latestValueDateHandleApply}
                                                showingdate={this.state.latestValueDateShowing}
                                                dateChange={this.latestValueDateChange}
                                                handleClose={this.latestValueHandleClose1}
                                                anchorEl={this.state.anchorElOne}
                                                error={this.state.latestValueDateError}
                                                allowed={!this.state.checkedOne}
                                                date={this.state.latestValueDate}
                                                mindate={this.state.buyDate}
                                                maxdate={moment().format("DD MMM YYYY")}
                                                placeholder={labels.RealEstate.PlaceHolderlatestValueDate}
                                            />
                                        </div>

                                    </div>
                                </>
                            }
                            <div className={classes.footercontainer}>
                                <div className={classes.buttonContaniner}>
                                    <Button name={this.props.location.state.isStatus != "update" ? "Save" : "Update"}
                                        onClick={this.submitClick}
                                        disabled={this.state.loader}
                                        page={this.props.location.state.isStatus == "update" ? "primary" : ""}
                                    />
                                    <Button disabled={this.state.loader} name={this.props.location.state.isStatus != "update" ? "Save & more" : "Cancel"} page={this.props.location.state.isStatus == "update" ? "" : "primary"} loading="true" loader={this.state.loader}
                                        onClick={this.props.location.state.isStatus != "update" ? this.saveAndMoreClick : this.handleCancelClick}
                                    />

                                </div>
                            </div>
                            <CommonSnackbar message={this.state.message} open={this.state.openSnackBar} severity={this.state.severity} handlesnanckClose={this.handleToastClose} />
                        </div>
                        <div></div>
                    </div>
                </>
            )
        }
        catch (e) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = (state) => {
    return {
        logindetails: state.Login.logindetails,
        login: state.Login,
        clientId: state.Login.logindetails.ClientID,
        //real estate dropdown list start
        propertyTypeList: state.Dataentry.alternativeAsset != undefined && state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset.table7 != "NA" ?
            state.Dataentry.alternativeAsset?.table7.map((item) => {
                return {
                    value: item.VALUE,
                    label: item.TEXT,
                }
            }) : [],
        usageList: state.Dataentry.alternativeAsset != undefined && state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset.table8 != "NA" ?
            state.Dataentry.alternativeAsset?.table8.map((item) => {
                return {
                    value: item.VALUE,
                    label: item.TEXT,
                }
            }) : [],
        // end

        //bullion dropdown list start
        assetypeList: state.Dataentry.alternativeAsset != undefined && state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset.table9 != "NA" ?
            state.Dataentry.alternativeAsset?.table9.map((item) => {
                return {
                    value: item.VALUE,
                    label: item.TEXT,
                }
            }) : [],
        typeList: state.Dataentry.alternativeAsset != undefined && state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset.table10 != "NA" ?
            state.Dataentry.alternativeAsset?.table10.map((item) => {
                return {
                    value: item.VALUE,
                    label: item.TEXT,
                }
            }) : [],
        //end

    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({
        RealEstateListing,
        getAlterativeAsset,
        getL5reportDetails,
        getRealEstateList,
        getBullionList
    }, dispatch)
};
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(RealEstate))