import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import color from "../../utils/colors/colors";
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(0),
    },
    justifyContent: "center",
  },
  button: {
    width: "auto",
    padding: "5px 22px",
    borderRadius: "22px",
    opacity: (p) => (p.disabled ? 0.5 : 1),
    borderColor: window.globalConfig.color.BLACK_PRIMARY,
    color: window.globalConfig.color.BLACK_PRIMARY,
    cursor: (p) => (p.disabled ? "not-allowed" : "pointer"),

    border: `1px solid ${window.globalConfig.color.BLACK_PRIMARY}`,
    textTransform: "none !important", // modified by dulcy
    "&:hover": {
      background: (p) =>
        p.disabled ? "transparent" : window.globalConfig.color.BLACK_PRIMARY,
      color: (p) =>
        p.disabled ? window.globalConfig.color.BLACK_PRIMARY : window.globalConfig.color.WHITE,
    },
  },
  outlineButton: {
    width: "auto",
    padding: "5px 22px",
    borderRadius: "22px",
    borderColor: window.globalConfig.color.BROWN,
    color: window.globalConfig.color.BLACK_PRIMARY,
    border:`1px solid ${window.globalConfig.color.BROWN}`,
    textTransform: "none !important", // modified by dulcy
    "&:hover": {
      cursor: "pointer",
      background: window.globalConfig.color.BROWN,
      color: window.globalConfig.color.WHITE,
    },
  },
}));
export default function OutlinedButtons(props) {
  const classes = useStyles(props);
  const onClick = () => {
    if(props.disabled){

    }else{
      props.onClick()
    }
  };
  return (
    <div className={classes.root}>
      <Button
      disableRipple={props.disabled}
        variant="outlined"
        tabIndex={props.tabIndex}
        className={classes.button}
        onClick={onClick}
      >
        {props.text}
      </Button>
    </div>
  );
  // }
}
