import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
//action created by dulcy
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
import apiurl from '../url/apiurl';
function getSubscriptionAddbank(ID, ClientID, AccountType, IFSCCOde, BranchMICRCode, BankAccountNumber, UserID,) {

  var raw = JSON.stringify({
    "bankID": ID,
    "clientID": ClientID,
    "accountType": AccountType,
    "iFSCCode": IFSCCOde,
    "branchMICRCode": BranchMICRCode,
    // "L5ID":L5ID,
    "bankAccountNumber": BankAccountNumber,
    "userID": UserID
  });
  //   var config = {
  //       method: 'post',
  //       url: ApiValues.Addbank,
  //       headers: { 
  //         'Content-Type': 'application/json'
  //       },
  //       data : raw
  //     };

  //  return   axios(config)
  //     .then(function (response) {
  //     return response.data
  //     })
  //     .catch(function (error) {
  //       return{status:"F",message:labels.messages.apierror}
  //     });
  return Networking.PostApi(apiurl.SubscriptionAddBank, raw).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default getSubscriptionAddbank
