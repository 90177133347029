import * as types from "../../constants/actionsType";
import moment from "moment";
import secureStorage from "../../../utils/securestorage";
import labels from "../../../utils/constants/labels";
import { replaceNull } from "../../../utils/commonfunction";
let products = [0, 1
  , 2, 3, 4, 5, 6, 7, 8, 10, 11
]
let productName = ["CommonAPI", "Mutual Fund"
  , "Equity", "Bonds", "Bullion", "FD", "AIF", "Real Estate", 'NPS', "MLD", "Trade"
]
const initialState = {
  logindetails: {},
  clientID: "",
  department: "",
  emailID: "",
  familyName: "",
  sessionID: "",
  userID: "",
  userName: "",
  loginError: "",
  formNo: 0,
  login: false,
  bankdetails: [],
  mandateDetails: [],
  mandateResponse: [],
  date: moment().format("DD MMM YYYY"),
  ResetFlag: false,
  adminmessage: "",
  familydetails: [],
  riaplandetails: [],
  companyDetails: [],
  productList: [],
  isfamilyhead: false,
  signup: false,
  signupmessage: "",
  loadercontinue: false,
  loadersignup: false,
  ekycmessage: "",
  loginFlag: false,
  planFeatures: [],
  L5ID: 0,
  TradingL5ID: 0,
  TradeUcccode: 0,
  UccCode: 0,
  uccAccount: "",
  apiPending: [],
  loginTime: "",
  permissionList: [],
  changedUserName: "",
  isReachedBottom: false,
  familyLoader:false
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_SCROLL:
      return {
        ...state,
        isReachedBottom: action.payload
      }
    case types.CLEAR_LOADER:
      return {
        ...state,
        loadercontinue: false,
        loadersignup: false,
      }
    case types.LOGIN_FLAG:
      return {
        ...state,
        loginFlag: action.payload,
        //  login:action.payload
      };
    case types.UPDATE_LOGIN_TIME:
      var updateLoginDate = new Date();

      var updateDate =
        updateLoginDate.getFullYear() +
        "-" +
        (updateLoginDate.getMonth() + 1) +
        "-" +
        updateLoginDate.getDate();
      var updateTime =
        updateLoginDate.getHours() +
        ":" +
        updateLoginDate.getMinutes()

      var updateLoginDateTime = updateDate + " " + updateTime;
      return {
        ...state,
        loginTime: updateLoginDateTime,
        //  login:action.payload
      };
    case types.UPDATE_TRADE_UCC_CODE:
      return {
        ...state,
        UccCode: action.payload,
      };
    case types.LOGIN:
      // secureStorage.setItem("familyname", action.payload.data[0].FamilyName);
      secureStorage.setItem("mainclientid", action.payload.data[0].ClientID);
      let logindata = action.payload.data[0];
      var nowDate = new Date();
      nowDate.setHours(nowDate.getHours() + 6);
      var date =
        nowDate.getFullYear() +
        "-" +
        (nowDate.getMonth() + 1) +
        "-" +
        nowDate.getDate();
      var time =
        nowDate.getHours() +
        ":" +
        nowDate.getMinutes() +
        ":" +
        nowDate.getSeconds();

      var dateTime = date + " " + time;
      var loginDate = new Date();

      var findDate =
        loginDate.getFullYear() +
        "-" +
        (loginDate.getMonth() + 1) +
        "-" +
        loginDate.getDate();
      var findTime =
        loginDate.getHours() +
        ":" +
        loginDate.getMinutes()

      var loginDateTime = findDate + " " + findTime;
      // var dateTime=moment()
      // .add(5, 'minutes')
      // .format('YYYY-MM-DD hh:mm:ss');

      secureStorage.setItem("expirationdate", dateTime);
      logindata.expire = dateTime;
      logindata.mainUser = logindata.UserName
      secureStorage.setItem("loginDetails", logindata);
      let data = labels.apiDetails
      secureStorage.setItem("apiPending", data)
      return {
        ...state,
        login: true,
        loginError: "",
        formNo: action.payload.data[0].KYCFormNo,
        logindetails: replaceNull(logindata, "NA"),
        loginTime: loginDateTime,
        mainClientID: action.payload.data[0].ClientID,
        clientID: action.payload.data[0].ClientID,
        department: action.payload.data[0].Department,
        emailID: action.payload.data[0].EmailID,
        familyName: action.payload.data[0].FamilyName,
        sessionID: action.payload.data[0].SessionID,
        userID: action.payload.data[0].UserID,
        userName: action.payload.data[0].UserName,
        changedUserName: action.payload.data[0].UserName,
        bankdetails: action.payload.bankDetails,
        date: moment().format("DD MMM YYYY"),
        mandateDetails: action.payload.mandateDetails,
        isfamilyhead: action.payload.data[0].IsFamilyHead,
        // familydetails: logindata.IsFamilyHead ? action.payload.familyDetails : [],
        riaplandetails: action.payload.riaPlanPaymenDetails,
        planFeatures: action.payload.planFeatures,
        companyDetails: replaceNull(action.payload.companieMaster, "NA"),
        productList: replaceNull(action.payload.product, "NA"),
        permissionList: action.payload.permission != undefined && action.payload.permission != null && action.payload.permission.length != 0 ? replaceNull(action.payload.permission, "NA")[0] : labels.permission[0],
        L5ID: action.payload.L5ID,
        apiPending: labels.apiDetails,
      };
    case types.STOREKYCFORMNO:
      return {
        ...state,
        formNo: Number(replaceNull(action.payload.KYCFormNo, 0)) || 0,
        EmailID: replaceNull(action.payload.EmailID)

      }
    case types.LOADER_CONTINUE:
      return {
        ...state,
        loadercontinue: action.payload,
      };
    case types.UCC_ACCOUNT:
      return {
        ...state,
        uccAccount: action.payload
      }
    case "NETWORK":
      return {
        ...state,
        loginError: action.payload,
      };
    case types.LOADER_SIGNUP:
      return {
        ...state,
        loadersignup: action.payload,
      };
    case types.STEP_SIGNUP:
      secureStorage.setItem("loginDetails", action.payload);
      return {
        ...state,
        // loadersignup: action.payload,?
        login: true,
        loginError: "",
        logindetails: action.payload,
      };

    case types.LOGIN_DATA:
      var a = action.user;
      a.expire = secureStorage.getItem("expirationdate");
      a.mainUser = a.UserName
      secureStorage.setItem("loginDetails", a);
      return {
        ...state,
        isfamilyhead: action.user.IsFamilyHead,
        // familydetails: action.familyDetails,
        clientID: action.user.ClientID,
        department: action.user.Department,
        emailID: action.user.EmailID,
        familyName: action.user.FamilyName,
        sessionID: action.user.SessionID,
        userID: action.user.UserID,
        userName: action.user.UserName,
        companyDetails: secureStorage.getItem("company"),
        logindetails: a,
      };
    case types.BACK_UP_PREVENT_API_CALL:

      return {
        ...state,
        apiPending: action.payload
      }
    case types.RESET_PREVENT_API_CALL:
      let resetData = products.map((item, index) => {
        return {
          productId: item, product: productName[index], apiList: labels.apiDetails.filter((value) => value.productId == item)[0].apiList.map((val) => {
            return { ...val, apiResponse: false }
          })
        }
      })
      secureStorage.setItem("apiPending", resetData);

      return {
        ...state,
        apiPending: resetData,
      };
    case types.LOGIN_FAILED:

      return {
        ...state,
        loginError: action.payload,
      };
    case types.CLIENTID_CHANGE:

      let login = state.logindetails;
      (login.ClientID = action.clientID),
        // login.mainUser = login.UserName;

        secureStorage.setItem("user", login);
      login.expire = secureStorage.getItem("expirationdate");

      if (action.FamilyName != undefined) {
        login.UserName = action.FamilyName;

      }
      secureStorage.setItem("loginDetails", login);
      return {
        ...state,
        clientID: action.clientID,
        changedUserName: action.FamilyName != undefined ? action.FamilyName : state.changedUserName,
        logindetails: login,
      };
    case types.ADMIN:
      return {
        ...state,
        adminmessage: action.payload,
      };
    case types.EKYC_REDIRECTION:
      return {
        ...state,
        ekycmessage: action.payload,
      };

    case types.SIGN_UP:

      return {
        ...state,
        signup: action.payload.status,
        signupmessage: action.payload.message,
      };
    case types.EMPTY_SIGN_UP:
      return {
        ...state,
        signup: false,
        signupmessage: "fail",
      };
    case types.LOGIN_RESET:
      return {
        ...state,
        ResetFlag: action.payload,
      };
    case types.HIDE_MSG:

      return {
        ...state,
        loginError: "",
      };
    case types.CLEAR_LOGINDATA:

      return {
        ...state,
        logindetails: {},
        clientID: "",
        department: "",
        emailID: "",
        familyName: "",
        sessionID: "",
        userID: "",
        userName: "",
        loginError: "",
        login: false,
        bankdetails: [],
        mandateDetails: [],
        mandateResponse: [],
        date: moment().format("DD MMM YYYY"),
        ResetFlag: false,
        adminmessage: "",
        familydetails: [],
        riaplandetails: [],
        isfamilyhead: false,
        signup: false,
        signupmessage: "",
        loadercontinue: false,
        loadersignup: false,
        ekycmessage: "",
        loginFlag: false,
        planFeatures: [],
        L5ID: 0,
        TradingL5ID: 0,
        TradeUcccode: 0,
        UccCode: 0,
        uccAccount: "",
        apiPending: [],
        companyDetails: [],
        productList: [],
        permissionList: []
      };
    case types.EMPTY_LOGIN:
      secureStorage.removeItem("apiPending");
      secureStorage.removeItem("loginDetails");
      return {
        ...state,
        logindetails: {},
        clientID: "",
        department: "",
        emailID: "",
        familyName: "",
        sessionID: "",
        userID: "",
        userName: "",
        loginError: "",
        login: false,
        bankdetails: [],
        mandateDetails: [],
        mandateResponse: [],
        date: moment().format("DD MMM YYYY"),
        ResetFlag: false,
        adminmessage: "",
        familydetails: [],
        riaplandetails: [],
        companyDetails: [],
        productList: [],
        isfamilyhead: false,
        signup: false,
        signupmessage: "",
        loadercontinue: false,
        loadersignup: false,
        ekycmessage: "",
        loginFlag: false,
        planFeatures: [],
        L5ID: 0,
        TradingL5ID: 0,
        TradeUcccode: 0,
        UccCode: 0,
        uccAccount: "",
        apiPending: [],
        loginTime: "",
        permissionList: []
      };
    case types.DATE_CHANGE:

      return {
        ...state,
        date: action.date,
      };
    case types.UPDATE_PROFILE_NEW_MANDATE:
      let mandate = {
        Amount: Number(action.Amount),
        BankAccNo: action.accountnumber,
        BankBranch: action.branchname,
        BankName: action.selectedBankName,
        MandateId: action.mandateid,
        MandateStatus: action.status,
        RegistrationDate: action.date,
        IFSC: action.ifsc,
        // "ID": action.data.ID
      };
      return {
        ...state,
        mandateDetails: [...state.mandateDetails, mandate].reverse(),
      };
    case types.UPDATE_RESET_FLAG:

      return {
        ...state,
        ResetFlag: action.payload,
      };
    case types.LOGIN_ACCOUNT_SELECTION:

      return {
        ...state,
        L5ID: action.payload,
      };
    case types.PREVENT_API_CALL:
      const elementsIndex = labels.apiDetails
        .filter((item) => item.productId == action.payload[0].ProductID)[0]
        .apiList.findIndex((item) => item.api === action.payload[0].api);
      let updatedRows = []
      updatedRows = state.apiPending;
      if (state.apiPending.length != 0) {
        if (Math.sign(elementsIndex) != -1) {
          updatedRows.filter(
            (item) => item.productId == action.payload[0].ProductID
          )[0].apiList[elementsIndex].apiResponse = true;
        }
      } else {
        updatedRows = products.map((item, index) => {

          return {
            productId: item, product: productName[index], apiList: labels.apiDetails.filter((value) => value.productId == item)[0].apiList.map((val) => {
              return { ...val, apiResponse: false }
            })
          }
        })
      }
      secureStorage.setItem("apiPending", updatedRows)
      return {
        ...state,
        apiPending: updatedRows
      };
    case types.UPDATE_TRADE_L5ID:
      return {
        ...state,
        TradingL5ID: action.L5ID,
      };
    case types.UPDATE_UCCCODE:
      return {
        ...state,
        TradeUcccode: action.uccode,
      };

    //Stored Data was login RiaPlanDetails
    case types.RIA_FLOW_OFFLINE_PAYMENT_LOGIN:
      return {
        ...state,
        riaplandetails:
          action.payload != null
            ? action.payload.riaPlanPaymenDetails
            : state.riaplandetails,
      };
    case types.RIA_ONLINE_PAYMENT_COMPLETE_LOGIN:
      return {
        ...state,
        riaplandetails:
          action.payload != null
            ? action.payload.riaPlanPaymenDetails
            : state.riaplandetails,
      };
    case types.RIA_FLOW_OFFLINE_PAYMENT_LOGIN_PLAN_FEATURE:
      return {
        ...state,
        planFeatures:
          action.payload != null
            ? action.payload.planFeatures
            : state.planFeatures,
      };
    case types.RIA_ONLINE_PAYMENT_COMPLETE_LOGIN_PLAN_FEATURE:
      return {
        ...state,
        planFeatures:
          action.payload != null
            ? action.payload.planFeatures
            : state.planFeatures,
      };
    case types.LOADER_FAMILY_DETAILS:
      return {
        ...state,
        familyLoader: action.payload
      }
    case types.FAMILY_DETAILS:
      return {
        ...state,
        familydetails: action.payload
      }
    case types.EMPTY_FAMILY_DETAILS:
      return {
        ...state,
        familydetails: action.payload,
        familyLoader: false
      }
    default:
      return state;
  }
};
export default reducer;
