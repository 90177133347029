import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router';
import { withStyles } from '@material-ui/core';
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle';
import DatePicker from '../../components/datepickers/date_pickers';
import CommonDropdown from '../../components/dropdown/dropdownschemes';
import CommonSnackbar from '../../components/snackbar/snackbar';
import Textinput from '../../components/textinput/textinput';
import labels from '../../utils/constants/labels';
import colors from '../../utils/colors/colors';
import Dynamic_row from '../../components/dynamic_row/dynamic_row';
import { getL5reportDetails } from "../../redux/actions/L5Report/L5ReportAction";
import OutlinedButton from '../../components/payment/button';
import CommonButton from '../../components/custombutton/button'
import moment from 'moment';
import PlusIcon from '../../../src/utils/assets/images/plus-46.png';
import Toggle from "../../components/toggle/toggle";
import { scrollToTopPage } from '../../utils/commonfunction';
import getAllStockList from '../../api/Dataentry/getstocks';
import Dialog from '@material-ui/core/Dialog';
import { numbersandDeciamlOnly, hasNull, round, notAllowdSpecialCharater, IFSCvalidation, alphabetSpaceOnly, replaceNull, replaceComma, amountCommaSeperator, NegativeFinding } from '../../utils/commonfunction';
import getAllStocksandTradeDetails from '../../api/stocksandtrade/stocksandtrade';
import Subheader from '../../components/subheader/subheader';
import getAIFTransaction from '../../api/aifTransaction/aifTransaction';
import DynamicList from '../../components/list/dynamicList';
import { Listing } from '../../redux/actions/aifandpms/aifandpms_action';
import GetAssetName from '../../api/fixed_deposit/asset_name';
import { updateAsset } from '../../redux/actions/DataEntry/common_dropdown_equity';
import { FlashAuto } from '@material-ui/icons';
import getAIFAddAsset from '../../api/aifandpms/addAifandPms';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import InformationTooltip from '../../components/tooltip/informationtooltip';
const styles = theme => ({

    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4,1fr)',
        columnGap: '20px',
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: 'repeat(1,1fr)',
        }
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down("920")]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
    },
    sideSpace: {
        width: '12%',
        [theme.breakpoints.down("920")]: {
            display: 'none'
        }
    },
    mainCard: {
        display: "flex", marginBottom: "3%",
        boxShadow: "rgb(19 89 161 / 10%) 0px 0px 20px 0px",
        borderRadius: 5,
        // minHeight: "",
        height: "auto",
        width: "89%", marginTop: "30px",
        padding: '4%',
        paddingTop: "1%",
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down("920")]: {
            margin: 'auto',

        }
    },
    subHeader: {
        textAlign: 'left',
        marginTop: '1pc',
        color: window.globalConfig.color.LABLE_GRAY,
        // marginLeft: 10,
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
    cardSpereaterLine: {
        height: 0.5,
        width: "100%",
        // backgroundColor: window.globalConfig.color.AliceBlue,
        backgroundColor: window.globalConfig.color.LABLE_GRAY,
        marginBottom: '1pc',
        marginTop: '6px',
        // marginLeft: 10,
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
    twoColumnGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)'
    },
    headerGrid: {
        textAlign: 'left',
        paddingLeft: 15
    },
    amountHeaderGrid: {
        textAlign: 'right',
        paddingRight: 26
    },
    buttonStyle: {
        width: "20%",
        [theme.breakpoints.down("920")]: {
            width: "50%"
        }
    },
    textboxWidth: {
        // width:"inherit !important"
    },
    TransactionContainerWithWidth: {
        [theme.breakpoints.up("920")]: {
            display: "grid",
            gridTemplateColumns: "35% 35% 30%",
            // paddingLeft: "10px"
            // marginLeft: "20px",
            width: "100%", marginRight: 0,
        },
        // paddingBottom: 11, paddingTop: 11,
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: 'repeat(2, [col-start] minmax(50%, 1fr) [col-end])',
            marginLeft: 5,
            display: "grid",
            //  width: "89.2%"
        }
    },
    ContainerWithWidth: {
        [theme.breakpoints.up("920")]: {
            display: "grid",
            gridTemplateColumns: "50% 50%",
            // paddingLeft: "10px"
            // marginLeft: "20px",
            width: "100%", marginRight: 0,
        },
        // paddingBottom: 11, paddingTop: 11,
        [theme.breakpoints.down("920")]: {

            marginTop: 10,
            marginLeft: 5,
            // display: "grid",
            //  width: "89.2%"
        }
    },
    commitedInstalmentContainerWithWidth: {
        [theme.breakpoints.up("920")]: {
            display: "grid",
            gridTemplateColumns: "50% 50%",
            // paddingLeft: "10px"
            // marginLeft: "20px",
            width: "100%", marginRight: 0,
        },
        // paddingBottom: 11, paddingTop: 11,
        [theme.breakpoints.down("920")]: {

            marginLeft: 5,
            // display: "grid",
            //  width: "89.2%"
        }
    },
    marginTopAlignment: {
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        marginBottom: 10
    },
    totalContainerWithWidth: {
        [theme.breakpoints.up("920")]: {
            display: "grid",
            gridTemplateColumns: "50% 50%",
            // paddingLeft: "10px"
            // marginLeft: "20px",
            width: "100%", marginRight: 0,
        },
        // paddingBottom: 11, paddingTop: 11,
        [theme.breakpoints.down("920")]: {

            marginLeft: 5,
            // display: "grid",
            //  width: "89.2%"
        }
    },
    popupWidth: {
        width: '320px',
        [theme.breakpoints.down("xs")]: {
            width: '255px',
        }
    },
    popupheader: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: "white",
        height: 50,
        fontWeight: 500
    },
    txtHeader: {
        padding: 15,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        [theme.breakpoints.down("600")]: {
            padding: '15px 20px 15px 15px',
            justifyContent: "revert",
        },
        [theme.breakpoints.down("600")]: {
            padding: '15px 20px 15px 15px',
            justifyContent: 'space-between',
        },
    },
    footercontainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    clearIcon: {
        cursor: "pointer",
        padding: 0,
        [theme.breakpoints.down("600")]: {
            padding: '0% 0% 0% 30%',
        },
        [theme.breakpoints.down("350")]: {
            padding: '0% 0% 0% 13% !important',

        },
    },
    popupBodyContainer: {
        margin: "4%",
        // [theme.breakpoints.down("xs")]: {
        //     // padding: '3% 0%',
        // },
    },
    popupButtonCantainer: {
        width: '90%',
        // display: 'flex',
        // textAlign: 'right',
        // /* position: relative; */
        // alignItems: 'end',
        // justifyContent: 'right',
        margin: '0% 4% 3% 5%',
    },
    popupfooterContainer: { display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" },
    AssetRow: {
        display: 'grid',
        gap: '7px',
        gridTemplateColumns: "91% 5%",
        minHeight: '90px',
        [theme.breakpoints.down('xs')]: {
            display: 'grid',
            gap: '10px',
            gridTemplateColumns: "91% 5%",
        }
    },
    centerAlignment: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        alignItem: 'center',
        cursor: 'pointer',
        // marginTop: '1.5pc',
    },
    valuationGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)',
        columnGap: '20px',
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: 'repeat(1,1fr)',
        },
    }
});
class Aif_DataEntry extends Component {
    constructor() {
        super();
        this.myDiv = React.createRef();

        this.state = {
            assetNameID: '',
            assetNameError: '',
            assetName: "",
            broker: '',
            brokerName: "",
            brokerError: '',
            brokerCode: '',
            brokerCodeError: '',
            folioNo: '',
            folioNoError: '',
            aifCategory: '',
            aifCategoryID: '',
            aifCategoryError: '',
            assetClass: '',
            assetClassName: '',
            assetClassError: '',
            investmentDate: '',
            investmentDateTemp: moment().format("DD MMM YYYY"),
            investmentDateError: '',
            commitmentAmount: '',
            commitmentAmountError: '',
            paidUpAmount: '',
            paidUpAmountError: '',
            pendingCommitment: '',
            pendingCommitmentError: '',
            currentValue: '',
            currentValueError: '',
            lastEvaluationDate: '',
            lastEvaluationDateTemp: moment().format("DD MMM YYYY"),
            lastEvaluationDateError: '',
            anchorEl: null,
            lastEvaluationAnchorEl: null,
            instalmentList: [],
            transactionList: [{
                Date: {
                    name: "",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "Select Date",
                    style: "date",
                    mindate: "",
                    maxdate: moment().format("DD MMM YYYY"),
                    label: ""
                },
                TransactionType: {
                    name: "Invested",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "Select Transaction Type",
                    style: "notEditableLabel",
                    label: "Transaction Type",

                },
                Amount: {
                    name: "0",
                    errormessage: "",
                    isClicked: true,
                    placeholder: "0.00",
                    style: "amount",
                    label: "",
                    enableDeleteIcon: false
                },
            }],
            transactionValue: [{
                Date: '', TransactionType: 0, Amount: ''
            }],
            instalmentValue: [],
            dropdownList: [],
            transactionListError: "",
            instalmentListError: "",
            message: '',
            openSnackBar: false,
            loader: false,
            addLoader: false,
            committedInstalments: '',
            severity: 'success',
            openPopup: false,
            category: "",
            categoryError: "",
            addAssetName: "",
            addAssetNameError: "",
            issuerName: "",
            issuerNameError: "",
            selected: false,
            flag: "AIF",
            schemeList: [],
            aifCategoryList: [],
            assetClassList: [],
            brokerList: [],
            popupLoader: false

        }

    }

    componentDidMount() {
        scrollToTopPage()
        if (this.props.location.state != undefined) {
            this.setState({ flag: this.props.location.state.data })
            if (this.props.location.state.data == "AIF") {
                this.setState({ selected: false })
            } else {
                this.setState({ selected: true })
            }
            this.setState({
                schemeList: this.props.assetNameList.length != 0 ? this.props.assetNameList.filter((item) => item.AssetClassCode === this.props.location.state.data)?.map((item) => {
                    return { "label": item.AssetName, "value": item.AssetID }
                }) : []
            })
            this.setState({
                aifCategoryList: this.props.aifCategoryList.length != 0 ? this.props.aifCategoryList.filter((item) => item.AssetClassCode === this.props.location.state.data).map((item) => {
                    return { "label": item.SubAssetClassName, "value": item.ID }
                }) : []
            })
            this.setState({ assetClassList: this.props.assetClassList })
            this.setState({
                brokerList: this.props.brokerList.map((item) => {
                    return { "label": item.BrokerName, "value": item.ID }
                })
            })
            if (this.props.transactionTypeList.length != 0) {
                this.setState({
                    transactionValue: [{
                        Date: '', TransactionType: this.props.transactionTypeList[0].value, Amount: ''
                    }],
                    transactionList: [{
                        Date: {
                            name: "",
                            errormessage: "",
                            isClicked: false,
                            placeholder: "Select Date",
                            style: "date",
                            mindate: "",
                            maxdate: moment().format("DD MMM YYYY"),
                            label: ""
                        },
                        TransactionType: {
                            name: this.props.transactionTypeList[0].label,
                            errormessage: "",
                            isClicked: false,
                            placeholder: "Select Transaction Type",
                            style: "notEditableLabel",
                            label: "Transaction Type",

                        },
                        Amount: {
                            name: "0",
                            errormessage: "",
                            isClicked: true,
                            placeholder: "0.00",
                            style: "amount",
                            label: "",
                            enableDeleteIcon: false
                        },
                    }]
                })
            }
        }
    }

    ///*** assetName
    //---start---
    handleAssetNameChange = (value) => {
        if (value.length != 0) {
            this.setState({ assetNameID: value[0].value, assetName: value[0].label, assetNameError: "" })
        } else {
            this.setState({ assetNameError: "" })
        }
    }
    ///---end---

    ///*** broker
    //---start---
    handleBrokerChange = (e) => {
        // if (notAllowdSpecialCharater(e.target.value)) {
        this.setState({ broker: e[0].value, brokerName: e[0].label, brokerError: "" })
        // }
    }
    ///---end---



    ///*** broker code
    //---start---
    handleBrokerCodeChange = (e) => {
        if (notAllowdSpecialCharater(e.target.value)) {
            this.setState({ brokerCode: e.target.value, brokerCodeError: "" })
        }
    }
    ///---end---

    ///*** folioNo/receipt no
    //---start---
    handleFolioNoChange = (e) => {
        if (notAllowdSpecialCharater(e.target.value)) {
            this.setState({ folioNo: e.target.value, folioNoError: "" })
        }
    }
    ///---end---
    ///*** Commitment Amount
    //---start---
    handleCommitmentAmountChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnly(removeComma.trim(), 14)) {
            this.setState({ commitmentAmount: amountCommaSeperator(removeComma), commitmentAmountError: "", transactionListError: "" })
            this.handleBlurPaidUpAmount(removeComma)
        }
        if (e.target.value === "") {
            this.handleBlurPaidUpAmount(removeComma)
            this.setState({ commitmentAmount: "" })
        }

    }
    ///---end---

    ///*** Paid Up Amount
    //---start---
    handlePaidUpAmountChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma)) {
            this.setState({ paidUpAmount: removeComma, paidUpAmountError: "" })
        }
    }
    ///---end---

    ///*** pending commitment
    //---start---
    handlePendingCommitmentChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma)) {
            this.setState({ pendingCommitment: removeComma, pendingCommitmentError: "" })
        }
    }
    ///---end---
    ///*** investment date
    //---start---
    handleInvestmentDateChange = (value) => {
        this.setState({ investmentDateTemp: moment(value).format("DD MMM YYYY") })

    }
    handleInvestmentDateApply = (date) => {
        this.setState({ investmentDate: moment(date).format("DD MMM YYYY"), investmentDateError: '' })
        let transaction = this.state.transactionList
        for (var i = 0; i < this.state.transactionList.length; i++) {
            transaction[i]["Date"].mindate = moment(date).format("DD MMM YYYY")
        }
        this.setState({ transactionList: transaction })
    }
    handleInvestmentDateClose = () => {
        this.setState({ anchorEl: null })
    }
    handleInvestmentDateClick = () => {
        this.setState({ anchorEl: e.currentTarget })

    }
    ///---end---

    ///*** asset Class
    //---start---
    handleAssetClassChange = (e) => {

        this.setState({ assetClass: e[0].value, assetClassName: e[0].label, assetClassError: "" })

    }
    ///---end---

    ///*** AIF Category
    //---start---
    handleAifCategoryChange = (e) => {

        this.setState({ aifCategoryID: e[0].value, aifCategory: e[0].label, aifCategoryError: "" })

    }
    ///---end---


    ///*** current Value
    //---start---
    handleCurrentValueChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma, 14)) {
            this.setState({ currentValue: amountCommaSeperator(removeComma), currentValueError: "" })
        }
    }
    ///---end---
    ///*** investment date
    //---start---
    handleLastEvaluationDateChange = (value) => {
        this.setState({ lastEvaluationDateTemp: moment(value).format("DD MMM YYYY") })

    }
    handleLastEvaluationDateApply = (date) => {
        this.setState({ lastEvaluationDate: moment(date).format("DD MMM YYYY"), lastEvaluationDateError: '' })

    }
    handleLastEvaluationDateClose = () => {
        this.setState({ lastEvaluationAnchorEl: null })
    }
    handleLastEvaluationDateClick = (e) => {
        this.setState({ lastEvaluationAnchorEl: e.currentTarget })
    }
    ///---end---
    handleFocus = (event) => event.target.select();
    callBack = (data, FullList) => {
        this.setState({ instalmentValue: data, instalmentList: FullList })

        let totalPrice = data.reduce(function (accumulator, item) {
            return accumulator + Number(item.Amount)
        }, 0);
        this.setState({ committedInstalments: totalPrice, instalmentListError: "" })

    }
    callBackTransaction = (data, FullList) => {
        let transaction = FullList

        for (var i = 0; i < FullList.length; i++) {
            transaction[i]["Date"].mindate = moment(this.state.investmentDateTemp).format("DD MMM YYYY")
        }
        this.setState({ transactionList: transaction })
        this.setState({ transactionValue: data })
        if (this.state.transactionValue.some(hasNull)) {

        } else {
            this.setState({ transactionListError: "" })
        }
        //console.log(transaction)
        // console.log(data)
        // console.log(data)
        let paidUpAmount = data.filter((value) => value.TransactionType == this.props.transactionTypeList[0].value).reduce(function (accumulator, item) {
            return accumulator + Number(item.Amount)
        }, 0);
        let currentValue = data.filter((value) => value.TransactionType == this.props.transactionTypeList[1].value).reduce(function (accumulator, item) {
            return accumulator + Number(item.Amount)
        }, 0);
        // let investedValue=
        this.setState({ paidUpAmount: paidUpAmount })

        // if (Number(paidUpAmount) > Number(replaceComma(this.state.commitmentAmount))) {
        //     this.setState({ transactionListError: this.state.commitmentAmount == "" || this.state.commitmentAmount == 0 || this.state.commitmentAmount == "0" ? "" : "Investment amount should be less than or equal to commitment amount" })
        // } else {

        //     if (currentValue > Number(replaceComma(this.state.currentValue))) {
        //         this.setState({ transactionListError: currentValue > Number(replaceComma(this.state.currentValue)) ? "Redemption amount should be less than Current Value" : "" })
        //     } else {
        //         this.setState({ transactionListError: '' })
        //     }
        // }

        this.setState({ pendingCommitment: Number(replaceComma(this.state.commitmentAmount)) > 0 ? Number(replaceComma(this.state.commitmentAmount)) - paidUpAmount : 0, })

    }
    addTransactionRow = () => {
        let isValid = true;
        isValid = this.state.transactionValue.some(hasNull) === true ? false : true
        let a = "";
        // console.log(this.props.transactionTypeList)
        if (isValid) {
            this.state.transactionValue.map((item) => {
                if (a == "") {
                    Object.keys(item).forEach(function (key) {
                        if (item[key] == 0) {
                            a = key
                            isValid = false
                        }
                    });
                }
            })
        }
        if (isValid === true) {
            this.setState(({
                transactionList: [...this.state.transactionList, {
                    Date: {
                        name: "",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "Select Date",
                        style: "date",
                        mindate: this.state.investmentDate == "" ? "" : moment(this.state.investmentDate).format("DD MMM YYYY"),
                        maxdate: moment().format("DD MMM YYYY"),
                        label: ""
                    },
                    TransactionType: {
                        name: "",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "Select Transaction Type",
                        style: "dropdown",
                        label: "",
                        list: this.props.transactionTypeList
                    },
                    Amount: {
                        name: "0",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "0.00",
                        style: "amount",
                        label: "",
                        enableDeleteIcon: true
                    },
                }],
                transactionValue: [...this.state.transactionValue, {
                    Date: "", TransactionType: "", Amount: ""
                }],
                transactionListError: ""
            }))
            // } else {
            //     this.setState({ transactionListError: this.state.pendingCommitment == '' ? '' : `Amount should be less than or equal to ${labels.currency.rupeesymbol}${Number(this.state.pendingCommitment).toLocaleString('en-IN')}` })
            // }
        } else {
            // if (a == "") {
            this.setState({ transactionListError: "All fields are mandatory" })

            // } else {
            // this.setState({ opensnackbar: true, message: `${a} should not be zero` })
            // }

        }
    }
    addInstalmentRow = () => {
        let isValid = true;
        // isValid = this.state.instalmentValue.some(hasNull) === true ? false : true
        let a = "";
        //  
        // if (isValid) {
        //     this.state.instalmentValue.map((item) => {
        //         if (a == "") {
        //             Object.keys(item).forEach(function (key) {
        //                 if (item[key] == 0) {
        //                     a = key
        //                     isValid = false
        //                 }
        //             });
        //         }
        //     })
        // }

        if (isValid === true) {
            // if (Number(this.state.committedInstalments) <= Number(this.state.pendingCommitment)) {
            this.setState(({
                instalmentList: [...this.state.instalmentList, {
                    Date: {
                        name: "",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "Select Date",
                        style: "date",
                        mindate: this.state.investmentDate == "" ? "" : moment(this.state.investmentDate).format("DD MMM YYYY"),
                        maxdate: "",
                        label: ""
                    }, Amount: {
                        name: "0",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "0.00",
                        style: "amount",
                        label: "",
                        enableDeleteIcon: true
                    },
                }],
                instalmentValue: [...this.state.instalmentValue, {
                    Date: "", Amount: ""
                }],
                instalmentListError: ""
            }))
            // } else {
            //     this.setState({ instalmentListError: this.state.pendingCommitment == '' ? '' : `Amount should be less than or equal to ${labels.currency.rupeesymbol}${Number(this.state.pendingCommitment).toLocaleString('en-IN')}` })
            // }
        } else {
            // if (a == "") {
            this.setState({ instalmentListError: "All fields are mandatory" })

            // } else {
            // this.setState({ opensnackbar: true, message: `${a} should not be zero` })
            // }

        }
    }
    handleBlurPaidUpAmount = (commitmentAmount) => {
        if (Number(commitmentAmount) > Number(this.state.paidUpAmount)) {
            this.setState({ pendingCommitment: commitmentAmount - Number(this.state.paidUpAmount), transactionListError: "" })
        } else {
            this.setState({ pendingCommitment: 0, })
            //  transactionListError: commitmentAmount == "" || commitmentAmount == "0" || commitmentAmount == 0 ? "" : "Investment amount should be less than or equal to commitment amount" })
        }

    }
    handleSubmit = (type) => {
        let isValid = true
        if (this.state.assetNameID == "") {
            isValid = false
            this.setState({ assetNameError: labels.messages.required })
        }
        if (this.state.broker == "") {
            isValid = false
            this.setState({ brokerError: labels.messages.required })
        }

        if (this.state.assetClass == "") {
            isValid = false
            this.setState({ assetClassError: labels.messages.required })
        }
        if (this.state.investmentDate.trim() == "") {
            isValid = false
            this.setState({ investmentDateError: labels.messages.required })
        }
        if (this.state.commitmentAmount.trim() == "") {
            isValid = false
            this.setState({ commitmentAmountError: labels.messages.required })
        } else {
            if (this.state.commitmentAmount != "") {
                if (!this.state.selected) {

                    if (Number(replaceComma(this.state.commitmentAmount).trim()) < 10000000) {
                        isValid = false
                        this.setState({ commitmentAmountError: `Minimum commitment amount ${labels.currency.rupeesymbol} 1,00,00,000 ` })
                    }
                } else {
                    if (Number(replaceComma(this.state.commitmentAmount.trim())) < 5000000) {
                        isValid = false
                        this.setState({ commitmentAmountError: `Minimum commitment amount ${labels.currency.rupeesymbol} 50,00,000 ` })
                    }
                }
            }


        }

        if (this.state.currentValue.toString().trim() == "") {
            isValid = false
            this.setState({ currentValueError: labels.messages.required })
        }
        if (this.state.lastEvaluationDate.trim() == "") {
            isValid = false
            this.setState({ lastEvaluationDateError: labels.messages.required })
        }

        // if (this.state.instalmentValue.some(hasNull)) {
        // } else {
        if (!this.state.instalmentValue.some(hasNull)) {
            let instalment = true
            this.state.instalmentValue.map((item) => {

                Object.keys(item).forEach(function (key) {
                    if (item[key] == 0 || item[key] == "") {
                        instalment = false
                        isValid = false
                    }
                });
            })
            if (!instalment) {
                this.setState({ instalmentListError: 'All fields are mandatory' })
            }
        } else {
            // if (this.state.committedInstalments != 0) {
            //     if (this.state.committedInstalments > this.state.pendingCommitment) {
            //         isValid = false
            //         this.setState({ instalmentListError: 'Total should be less than or equal to pending commitment' })
            //     }
            // }
        }

        // }
        let redeem = this.state.transactionList.filter((item) => item.TransactionType.name.toLowerCase() == 'redeem')
        let val = redeem.reduce((sum, { Amount }) => sum + Number(Amount.name), 0);
        if (this.state.transactionValue.some(hasNull)) {
            isValid = false

            this.setState({ transactionListError: 'All fields are mandatory' })
        } else if (Number(replaceComma(this.state.currentValue)) < val) {
            isValid = false
            this.setState({ transactionListError: 'Redemption amount should be less than or equal to current value' })
        }
        if (isValid) {
            let data = JSON.stringify({
                "schemeNameID": Number(this.state.assetNameID),
                "brokerID": this.state.broker,
                "brokerCode": this.state.brokerCode,
                "amcRefCode": this.state.folioNo,
                "categoryID": 0,
                "assetClassID": this.state.assetClass,
                "investmentDate": this.state.investmentDate,
                "commitmentAmount": Number(this.state.commitmentAmount == "" ? 0 : replaceComma(this.state.commitmentAmount)),
                "investedAmount": Number(this.state.paidUpAmount),
                "pendingCommitment": Number(this.state.pendingCommitment),
                "currentValue": Number(replaceComma(this.state.currentValue)),
                "lastValuationDate": this.state.lastEvaluationDate,
                "assetClassCode": this.props.productList.length == 0 ? 0 : this.props.productList.filter((item) => item.TEXT == this.state.flag)[0].VALUE,
                "clientID": this.props.logindetails.ClientID,
                "json": JSON.stringify(this.state.instalmentValue.length == 0 || this.state.instalmentValue.some(hasNull) ? [] : this.state.instalmentValue),
                "jsonTran": JSON.stringify(this.state.transactionValue),
                "L4AccountTypeID": this.props.logindetails.L4_AccountTypeID,
                "L5AccountID": this.props.L5ID

            });
            if (type == "save") {
                this.setState({ loader: true })
            } else {
                this.setState({ addLoader: true })
            }


            getAIFAddAsset(data).then((response) => {
                if (type == "save") {
                    this.setState({ loader: false })
                } else {
                    this.setState({ addLoader: false })
                }
                if (response.status == 'S') {

                    // this.props.Listing(response.table1, this.state.flag)
                    this.setState({ openSnackBar: true, severity: 'success', message: response.message })
                    this.handleClose()
                    if (type == "save") {
                        this.props.history.push({
                            pathname: labels.Path.Aif,
                            state: { data: this.state.flag }
                        })
                    }
                } else {
                    this.setState({ openSnackBar: true, severity: 'warning', message: response.message })

                }
            }).catch((err) => {
                // console.log(err)
                if (type == "save") {
                    this.setState({ loader: false })
                } else {
                    this.setState({ addLoader: false })
                }
                // this.setState({ loader: false })
                this.setState({ openSnackBar: true, severity: 'warning', message: labels.messages.apierror })

            })
        }
    }
    handleClose = () => {
        this.setState({
            assetName: '',
            assetNameError: '',
            assetNameID: '',
            assetClassName: '',
            aifCategory: '',
            brokerName: '',
            broker: '',
            brokerError: '',
            brokerCode: '',
            brokerCodeError: '',
            folioNo: '',
            folioNoError: '',
            aifCategory: '',
            aifCategoryError: '',
            assetClass: '',
            transactionList: [{
                Date: {
                    name: "",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "Select Date",
                    style: "date",
                    mindate: this.state.investmentDate == "" ? "" : moment(this.state.investmentDate).format("DD MMM YYYY"),
                    maxdate: moment().format("DD MMM YYYY"),
                    label: ""
                },
                TransactionType: {
                    name: "Invested",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "Select Transaction Type",
                    style: "notEditableLabel",
                    label: "Transaction Type"
                },
                Amount: {
                    name: "0",
                    errormessage: "",
                    isClicked: true,
                    placeholder: "0.00",
                    style: "amount",
                    label: "",
                    enableDeleteIcon: false
                },
            }],
            transactionValue: [{
                Date: '', TransactionType: 10045, Amount: ''
            }],
            assetClassError: '',
            investmentDate: '',
            investmentDateTemp: moment().format("DD MMM YYYY"),
            investmentDateError: '',
            commitmentAmount: '',
            commitmentAmountError: '',
            paidUpAmount: '',
            paidUpAmountError: '',
            pendingCommitment: '',
            pendingCommitmentError: '',
            currentValue: '',
            currentValueError: '',
            lastEvaluationDate: '',
            lastEvaluationDateTemp: moment().format("DD MMM YYYY"),
            lastEvaluationDateError: '',
            anchorEl: null,
            lastEvaluationAnchorEl: null,
            instalmentList: [],
            instalmentValue: [],
            instalmentListError: "",
            transactionListError: "",
            // message: '',
            // openSnackBar: false,
            loader: false,
            committedInstalments: ''
        })
    }
    handleToastClose = () => {
        this.setState({ openSnackBar: false })
    }
    handleCategoryChange = (value) => {
        this.setState({ category: value[0].value, categoryError: "" })
    }
    handleIssuerChange = (e) => {
        //   if (notAllowdSpecialCharater(e.target.value)) {
        this.setState({ issuerName: e[0].value, issuerNameError: "" })
        //}
    }
    handleAddAssetNameChange = (e) => {
        // console.log(e.target.value.length <= 75)
        if (e.target.value.length <= 75) {
            this.setState({ addAssetName: e.target.value, addAssetNameError: "" })
        }
    }
    handleCancel = () => {

        this.setState({ openPopup: false, category: "", categoryError: "", addAssetName: "", addAssetNameError: "", issuerName: "", issuerNameError: "" })
    }
    handleAddClick = () => {
        this.setState({ openPopup: true })
    }
    clearAddAsset = () => {
        this.setState({ category: '', categoryError: '', addAssetName: '', addAssetNameError: '', issuerName: '', issuerNameError: '' })
    }
    addAsset = () => {
        var isValid = true;
        if (this.state.category == "") {
            isValid = false
            this.setState({ categoryError: labels.messages.required })
        }
        if (this.state.addAssetName == "") {
            isValid = false
            this.setState({ addAssetNameError: labels.messages.required })
        }
        if (this.state.issuerName == "") {
            isValid = false
            this.setState({ issuerNameError: labels.messages.required })
        }
        if (isValid) {
            let data = {
                ClientID: this.props.logindetails.ClientID,
                AssetName: this.state.addAssetName,
                ProductID: this.props.productList.length == 0 ? 0 : this.props.productList.filter((item) => item.TEXT == this.state.flag)[0].VALUE,
                CategoryID: this.state.category,
                IssuerName: '',
                IssuerID: this.state.issuerName,
                Type: 1
            }
            this.setState({ popupLoader: true })
            GetAssetName(data).then((response) => {
                this.setState({ popupLoader: false })
                if (response.status == "S") {
                    this.setState({ openSnackBar: true, assetName: response.data[0].AssetName, assetNameError: "", assetNameID: response.data[0].AssetID, message: response.message, severity: 'success' })
                    this.props.updateAsset(response.data, this.state.flag)
                    this.handleCancel()

                } else {
                    this.clearAddAsset()
                    this.setState({ openSnackBar: true, message: response.message, severity: 'warning' })

                }
            }).catch((err) => {
                this.setState({ openSnackBar: true, message: labels.messages.apierror, severity: 'warning' })

            })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const { assetClassList, aifCategoryList, brokerList, assetNameList } = this.props
        const { flag } = this.state
        if (prevProps.assetClassList != assetClassList) {
            this.setState({ assetClassList: assetClassList })
        }
        //    if(prevProps.aifCategoryList!=aifCategoryList){
        //     this.setState({aifCategoryList:aifCategoryList})
        //    }
        if (prevProps.brokerList != brokerList) {
            this.setState({ brokerList: brokerList })
        }
        if (prevProps.assetNameList != assetNameList) {

            let list = assetNameList.filter((item) => item.AssetClassCode == flag).map((value) => {
                return { label: value.AssetName, value: value.AssetID }
            })
            this.setState({ schemeList: list }, () => {
            })

        }
    }

    toggleChange = () => {
        this.setState({ schemeList: [] })
        this.setState({ aifCategoryList: [] })
        this.setState({ assetClassList: [] })
        this.setState({ brokerList: [] })
        this.setState({ selected: !this.state.selected, flag: !this.state.selected ? "PMS" : "AIF" })

        let flag = this.state.selected ? 'AIF' : 'PMS'

        this.setState({
            schemeList: this.props.assetNameList.length != 0 ? replaceNull(this.props.assetNameList, 'NA').filter((item) => item.AssetClassCode == flag)?.map((item) => {
                return { "label": item.AssetName, "value": item.AssetID }
            }) : []
        })
        this.setState({
            aifCategoryList: this.props.aifCategoryList.length != 0 ? replaceNull(this.props.aifCategoryList, "NA").filter((item) => item.AssetClassCode == flag).map((item) => {
                return { "label": item.SubAssetClassName, "value": item.ID }
            }) : []
        })

        this.setState({ assetClassList: this.props.assetClassList != null ? replaceNull(this.props.assetClassList, 'NA') : [] })
        this.setState({
            brokerList: this.props.brokerList.length == 0 ? [] : replaceNull(this.props.brokerList, "NA")?.map((item) => {
                return { "label": item.BrokerName, "value": item.ID }
            })
        })
        this.handleClose()
    }
    render() {
        const { classes } = this.props;
        try {
            return (
                <ErrorBoundary>
                    <Subheader heading={labels.productList.Pms} description={labels.Transact.aifContent} />
                    <div className={classes.flex}>
                        <div className={classes.sideSpace}></div>
                        <div className={classes.mainCard} ref={this.myDiv}>
                            <div className={classes.marginTopAlignment}>
                                <Toggle
                                    label1={"AIF"}
                                    label2={"PMS"}
                                    selected={this.state.selected}
                                    toggleSelected={this.toggleChange}
                                />
                            </div>
                            <div className={classes.grid}>
                                <div className={classes.AssetRow}>
                                    <CommonDropdown
                                        //     autoselect={true}
                                        handleOnChange={this.handleAssetNameChange}
                                        //  values={this.state.BankAccounttype}
                                        error={this.state.assetNameError}
                                        type={"normalwithsearch"}
                                        id="ddl_assetName"
                                        placeholder={labels.PmsAndAif.schemeNamePlaceholder}
                                        label={labels.PmsAndAif.schemeName}
                                        height={190}
                                        value={this.state.assetName}
                                        List={this.state.schemeList.length == 0 ? [] : replaceNull(this.state.schemeList, "NA")}

                                    />
                                    <InformationTooltip
                                        title="Add New Scheme"
                                        Icon={<div className={classes.centerAlignment} onClick={this.handleAddClick}>
                                            <img src={PlusIcon} width="20px" height="20px" alt="AddIcon" />
                                        </div>}></InformationTooltip>

                                </div>
                                <CommonDropdown
                                    //  autoselect={true}
                                    handleOnChange={this.handleBrokerChange}
                                    value={this.state.brokerName}
                                    error={this.state.brokerError}
                                    type={"normalwithsearch"}
                                    id="ddl_broker"
                                    placeholder={labels.PmsAndAif.brokerPlaceholder}
                                    label={labels.PmsAndAif.broker}
                                    height={190}

                                    List={this.state.brokerList.length == 0 ? [] : replaceNull(this.state.brokerList, "NA")}
                                // value={this.state.broker}
                                />
                                <Textinput handleChange={this.handleBrokerCodeChange}
                                    value={this.state.brokerCode}
                                    error={this.state.brokerCodeError}
                                    otherProps={{ maxLength: 15 }}

                                    page={"normal"} label={labels.PmsAndAif.brokerCode}
                                    placeholder={labels.PmsAndAif.brokerCodePlaceholder}
                                />
                                <Textinput handleChange={this.handleFolioNoChange}
                                    value={this.state.folioNo}
                                    error={this.state.folioNoError}
                                    otherProps={{ maxLength: 15 }}

                                    page={"normal"} label={labels.PmsAndAif.folioNo}
                                    placeholder={labels.PmsAndAif.folioNoPlaceholder}
                                />
                                {/* <CommonDropdown
                                    //  autoselect={true}
                                    handleOnChange={this.handleAifCategoryChange}
                                    value={this.state.aifCategory}
                                    error={this.state.aifCategoryError}
                                    type={"normalwithsearch"}
                                    id="ddl_aifCategory"
                                    placeholder={labels.PmsAndAif.aifCategoryPlaceholder}
                                    label={labels.PmsAndAif.aifCategory}
                                    height={190}
                                    List={this.state.aifCategoryList}
                                // value={this.state.aifCategory}
                                /> */}
                                <CommonDropdown

                                    handleOnChange={this.handleAssetClassChange}
                                    value={this.state.assetClassName}
                                    error={this.state.assetClassError}
                                    type={"normalwithsearch"}
                                    placeholder={labels.PmsAndAif.assetClassPlaceholder}
                                    label={labels.PmsAndAif.assetClass}
                                    height={190}
                                    id="ddl_assetClass"
                                    List={this.state.assetClassList.length == 0 ? [] : replaceNull(this.state.assetClassList, "NA")}

                                />
                                <DatePicker
                                    handleClick={(e) => this.handleInvestmentDateClick(e)}
                                    handleApply={this.handleInvestmentDateApply}
                                    showingdate={this.state.investmentDateTemp}
                                    dateChange={this.handleInvestmentDateChange}
                                    handleClose={this.handleInvestmentDateClose}
                                    anchorEl={this.state.anchorEl}
                                    error={this.state.investmentDateError}
                                    maxdate={moment().format("DD MMM YYYY")}
                                    // allowed={false} 
                                    id="dp_aifInvestmentDate"
                                    label={labels.PmsAndAif.investmentDate}
                                    placeholder={labels.PmsAndAif.select + " " + labels.PmsAndAif.invDate}
                                    date={this.state.investmentDate} />

                                <Textinput handleChange={this.handleCommitmentAmountChange}
                                    value={this.state.commitmentAmount}
                                    error={this.state.commitmentAmountError}
                                    align={"right"}
                                    otherProps={{ autoFocus: true, name: "commitmentAmount", onFocus: this.handleFocus, onBlur: this.handleDecimal }}

                                    page={"normal"} label={labels.PmsAndAif.commitmentAmount}
                                    placeholder={labels.PmsAndAif.amountZeroPlaceholder}
                                />
                                <Textinput handleChange={this.handlePaidUpAmountChange}
                                    value={NegativeFinding(this.state.paidUpAmount, "", "", "", "number")}
                                    error={this.state.paidUpAmountError}
                                    align={"right"}
                                    disabled={true}
                                    otherProps={{ autoFocus: true, name: "paidUpAmount", onFocus: this.handleFocus, onBlur: this.handleBlurPaidUpAmount }}

                                    page={"normal"} label={labels.PmsAndAif.paidUpAmount}
                                    placeholder={labels.PmsAndAif.amountZeroPlaceholder}
                                />
                                <Textinput handleChange={this.handlePendingCommitmentChange}
                                    value={NegativeFinding(this.state.pendingCommitment, "", "", "", "number")}
                                    error={this.state.pendingCommitmentError}
                                    align={"right"}
                                    disabled={true}
                                    otherProps={{ autoFocus: true, name: "pendingCommitment", onFocus: this.handleFocus, onBlur: this.handleDecimal }}

                                    page={"normal"} label={labels.PmsAndAif.pendingCommitment}
                                    placeholder={labels.PmsAndAif.amountZeroPlaceholder}
                                />

                            </div>

                            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between" }}>

                                <div className={classes.subHeader}>{labels.PmsAndAif.transactionHeader}</div>
                                <div onClick={() => this.addTransactionRow()} style={{ textAlign: "right", color: window.globalConfig.color.PRIMARY, fontSize: 14, cursor: "pointer" }}><span style={{ textDecoration: "none" }}>+</span>{labels.PmsAndAif.addTransaction}</div>

                            </div>
                            <div className={classes.cardSpereaterLine}></div>
                            <div style={{ marginRight: 0 }} className={[classes.tableHeader, classes.twoColumnGrid, classes.tableMainHeader, classes.TransactionContainerWithWidth, classes.tableHeaderDisplay].join(' ')}>
                                {labels.PmsAndAif.transactionList.map((item) => {
                                    return <div className={item.label == "amount" ? classes.amountHeaderGrid : classes.headerGrid}> {item.label}</div>
                                })}
                            </div>
                            <Dynamic_row tooltipTitle="Delete Transaction" index={3000} dropdownList={this.props.transactionTypeList} deletePopupPosition="insideTextBox" preventDelete={"no"} textboxStyle={classes.textboxWidth} class={classes.TransactionContainerWithWidth} callBack={this.callBackTransaction} data={this.state.transactionValue} List={this.state.transactionList} count={3} />
                            <span style={{ fontSize: 13, color: window.globalConfig.color.red, textAlign: "left" }}>{this.state.transactionListError}</span>

                            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between" }}>
                                <div className={classes.subHeader}>{labels.PmsAndAif.valuation}</div>
                            </div>
                            <div className={classes.cardSpereaterLine}></div>
                            <div className={classes.valuationGrid}>
                                <Textinput handleChange={this.handleCurrentValueChange}
                                    value={this.state.currentValue}
                                    error={this.state.currentValueError}
                                    align={"right"}
                                    otherProps={{ autoFocus: true, name: "currentValue", onFocus: this.handleFocus, onBlur: this.handleDecimal }}

                                    page={"normal"} label={labels.PmsAndAif.currentValue}
                                    placeholder={labels.PmsAndAif.amountZeroPlaceholder}
                                />
                                <DatePicker
                                    handleClick={(e) => this.handleLastEvaluationDateClick(e)}
                                    handleApply={this.handleLastEvaluationDateApply}
                                    showingdate={this.state.lastEvaluationDateTemp}
                                    dateChange={this.handleLastEvaluationDateChange}
                                    handleClose={this.handleLastEvaluationDateClose}
                                    anchorEl={this.state.lastEvaluationAnchorEl}
                                    error={this.state.lastEvaluationDateError}
                                    id="dp_aifLastEvaluationDate"
                                    mindate={this.state.investmentDate == "" ? "" : moment(this.state.investmentDate).format("DD MMM YYYY")}
                                    maxdate={moment().format("DD MMM YYYY")}
                                    // allowed={false} 
                                    label={labels.PmsAndAif.lastEvaluationDate}
                                    placeholder={labels.PmsAndAif.lastEvaluationDatePlaceholder}
                                    date={this.state.lastEvaluationDate} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between", marginTop: 20 }}>
                                <div className={classes.subHeader}>{labels.PmsAndAif.header}</div>
                                <div onClick={() => this.addInstalmentRow()} style={{ textAlign: "right", color: window.globalConfig.color.PRIMARY, fontSize: 14, cursor: "pointer" }}><span style={{ textDecoration: "none" }}>+</span> Add Instalment</div>
                            </div><div className={classes.cardSpereaterLine}></div>
                            <div style={{ marginRight: 0 }} className={[classes.tableHeader, classes.twoColumnGrid, classes.tableMainHeader, classes.commitedInstalmentContainerWithWidth, classes.tableHeaderDisplay].join(' ')}>
                                {labels.PmsAndAif.list.map((item) => {
                                    return <div className={item.label == "amount" ? classes.amountHeaderGrid : classes.headerGrid}> {item.label}</div>
                                })}
                            </div>
                            <Dynamic_row tooltipTitle="Delete Instalment" index={2000} deletePopupPosition="insideTextBox" preventDelete={"no"} textboxStyle={classes.textboxWidth} class={classes.ContainerWithWidth} callBack={this.callBack} data={this.state.instalmentValue} List={this.state.instalmentList} count={2} />

                            <div className={classes.totalContainerWithWidth}>
                                <div style={{ display: "contents" }}>
                                    <span style={{ fontSize: 13, color: window.globalConfig.color.red, textAlign: "left" }}>{this.state.instalmentListError}</span>

                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "auto 5%", border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, textAlign: "center", alignItems: "center" }}>
                                    <Textinput handleChange={this.handlePendingCommitmentChange}
                                        value={NegativeFinding(this.state.committedInstalments, "", "", "", "number")}
                                        // error={this.state.pendingCommitmentError}
                                        align={"right"}
                                        disabled={true}
                                        // otherProps={{ autoFocus: true, name: "pendingCommitment", onFocus: this.handleFocus, onBlur: this.handleDecimal }}

                                        page={"normal"}
                                        // label={labels.PmsAndAif.pendingCommitment}
                                        placeholder={labels.PmsAndAif.amountZeroPlaceholder}
                                    />
                                    <div>{labels.currency.Rupee}</div>
                                </div>
                            </div>
                            <div style={{ marginTop: "2%", display: "flex", flexDirection: "row", marginBottom: "2%", justifyContent: "flex-end" }} >
                                <div className={classes.buttonStyle}>
                                    <CommonButton name={labels.PmsAndAif.save} page={"primary"}
                                        disabled={this.state.loader || this.state.addLoader}
                                        loader={this.state.loader}
                                        loading={"true"}
                                        onClick={() => this.handleSubmit("save")} />
                                    {/* <CommonButton name={"Cancel"} onClick={this.handleClose} /> */}
                                </div>
                                <div style={{ marginLeft: "5%" }} className={classes.buttonStyle}>
                                    <CommonButton name={labels.PmsAndAif.saveAndMore} page={"primary"}
                                        loader={this.state.addLoader}
                                        disabled={this.state.loader || this.state.addLoader}
                                        loading={"true"}
                                        onClick={() => this.handleSubmit("addMore")} />
                                </div>
                            </div>
                        </div>
                        <div className={classes.sideSpace}></div>

                    </div>
                    <CommonSnackbar message={this.state.message} open={this.state.openSnackBar} severity={this.state.severity} handlesnanckClose={this.handleToastClose} />
                    <Dialog
                        disablePortal={true}
                        maxWidth="xs"
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                        open={this.state.openPopup}
                        onClose={this.handleCancel}
                    >
                        <div className={classes.popupWidth}>
                            <div className={classes.popupheader}>
                                <div className={classes.txtHeader}>{labels.PmsAndAif.newScheme}
                                    <span className={classes.clearIcon} onClick={this.handleCancel}>&#x2715;</span>
                                </div>
                            </div>
                            <div className={classes.popupBodyContainer}>
                                <div style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: "20px",
                                    justifyContent: "center",
                                    alignContent: "center"
                                }}>
                                    <CommonDropdown
                                        id={"category3"}
                                        type={"normalwithsearch"}
                                        label={labels.fdForm.Category}
                                        autoselect={false}
                                        isSearchable={false}
                                        height={150}
                                        //value={this.state.category}
                                        List={this.state.aifCategoryList}
                                        // List={this.props.CategoryAssetNameList}
                                        placeholder={labels.PmsAndAif.select + " " + labels.fdForm.Category}
                                        handleOnChange={this.handleCategoryChange}
                                        error={this.state.categoryError}
                                    />

                                    <CommonDropdown
                                        //  autoselect={true}
                                        handleOnChange={this.handleIssuerChange}
                                        //  values={this.state.BankAccounttype}
                                        error={this.state.issuerNameError}
                                        type={"normalwithsearch"}
                                        id="ddl_issuername"
                                        pladdAssetNameaceholder={labels.PmsAndAif.selectIssuer}
                                        label={labels.PmsAndAif.issuer}
                                        height={150}

                                        List={this.props.issuerList}
                                    // value={this.state.aifCategory}
                                    />

                                    <Textinput
                                        page="normal"
                                        placeholder={labels.PmsAndAif.enterSchemeName}
                                        label={labels.PmsAndAif.schemeName}
                                        paddingAlign="5px"
                                        paddingLeftAlignment="-1px"
                                        otherProps={{ maxLength: 75 }}
                                        value={this.state.addAssetName}
                                        handleChange={(event) => this.handleAddAssetNameChange(event)}
                                        error={this.state.addAssetNameError}
                                    />
                                </div>
                            </div>
                            <div className={classes.cardSpareaterLinelight}></div>
                            <div className={classes.popupButtonCantainer}>
                                <div className={classes.popupfooterContainer}>
                                    <CommonButton name={labels.PmsAndAif.cancel} onClick={this.handleCancel} />
                                    <CommonButton name={labels.PmsAndAif.add} page={"primary"} loading="true" loader={this.state.popupLoader} disabled={this.state.popupLoader} onClick={() => { this.addAsset() }} />

                                </div>
                            </div>
                        </div>
                    </Dialog>
                </ErrorBoundary>
            )

        } catch (err) {
            // console.log(err)
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => {
    //
    return {
        sessionID: state.Login.sessionID,
        clientID: state.Login.logindetails.ClientID,
        logindetails: state.Login.logindetails,
        L5ID: state.Login.L5ID,
        productList: state.Dataentry.alternativeAsset != undefined && state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset.table12 != "NA" && state.Dataentry.alternativeAsset.table12.length != 0 ?
            state.Dataentry.alternativeAsset?.table12 : [],
        // catagories: state.Dataentry.alternativeAsset != undefined && state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset.table2 != null && state.Dataentry.alternativeAsset.table2.length != 0 ?
        //     state.Dataentry.alternativeAsset?.table2 : [],
        brokerList: state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset.length != 0 && state.Dataentry.alternativeAsset.table3 != "NA" && state.Dataentry.alternativeAsset.table3.length != 0 ? state.Dataentry.alternativeAsset.table3 : [],
        transactionTypeList: state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset.length != 0 && state.Dataentry.alternativeAsset.table6 != "NA" && state.Dataentry.alternativeAsset.table6.length != 0 ? state.Dataentry.alternativeAsset.table6.map((item) => {
            return { "label": item.TransactionType, "value": item.ID }
        }) : [],
        assetNameList: state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset.length != 0 && state.Dataentry.alternativeAsset.table5 != "NA" && state.Dataentry.alternativeAsset.table5.length != 0 ? state.Dataentry.alternativeAsset.table5 : [],
        assetClassList: state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset.length != 0 && state.Dataentry.alternativeAsset.table4 != "NA" && state.Dataentry.alternativeAsset.table4.length != 0 ? state.Dataentry.alternativeAsset.table4.map((item) => {
            return { "label": item.EnumCode, "value": item.EnumValue }
        }) : [],
        aifCategoryList: state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset.length != 0 && state.Dataentry.alternativeAsset.table2 != "NA" && state.Dataentry.alternativeAsset.table2.length != 0 ? state.Dataentry.alternativeAsset.table2 : [],
        issuerList: state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset.length != 0 && state.Dataentry.alternativeAsset.table11 != "NA" && state.Dataentry.alternativeAsset.table11.length != 0 ? state.Dataentry.alternativeAsset.table11.map((item) => {
            return { "label": item.TEXT, "value": item.VALUE }
        }) : [],

    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getL5reportDetails, Listing, updateAsset }, dispatch)
}
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(Aif_DataEntry))
