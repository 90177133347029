//page created by dulcy
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom'
import CommonHeaders from '../../components/report_common_header/report_common_header'
import Label from '../../utils/constants/labels';
import { withStyles } from '@material-ui/core';
import './afrstylesheet.css'
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom'//import Select from '@material-ui/core/Select';
import moment from 'moment';
import { Encryption, NegativeFinding } from '../../utils/commonfunction';
import NoData from '../../components/nodata/nodata';
import labels from '../../utils/constants/labels';
import getAllcommonDownloadDetails from '../../api/commondownload/commondownload';
import Loader from '../../components/loader/loader'
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyle from '../../utils/stylesheets/table/commontablestyle';
import colors from '../../utils/colors/colors';
import apiurl from '../../api/url/apiurl';
import CommonSnackbar from '../../components/snackbar/snackbar'
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import Shorttem from './shortterm';
var title;
const styles = theme => ({
    Categoryname: {
        fontSize: "16px",
        fontWeight: "bold",
        color: window.globalConfig.color.BLACK_PRIMARY
    },
    commonsubheading: {
        fontSize: "16px",
        fontWeight: "bold",
        color: window.globalConfig.color.BLACK_PRIMARY,
    },
    invested: {
        fontSize: "16px",
        fontWeight: "bold",
        color: window.globalConfig.color.BLACK_PRIMARY,
        textAlign: "left",
        width: 304,
        marginLeft: "25px"
    },
    Units: {
        fontSize: "13px",
        fontWeight: 500,
        color: window.globalConfig.color.BLACK_PRIMARY
    },
    topheader: {
        textAlign: "center",
        lineHeight: 2.0,
        // paddingBottom: "2em",
        fontSize: "15px", fontWeight: 500, color: window.globalConfig.color.LABLE_GRAY
    },
    rupeesymbol: {
        margin: "0px !important",
        fontSize: "13px !important",
        fontWeight: "normal !important",
        color: `${window.globalConfig.color.LABLE_GRAY} !important`,
        padding: "1px 0px",
    },
    subheadingrupeesymbol: {
        margin: "0px !important",
        fontSize: "16px !important",
        fontWeight: "normal !important",
        color: `${window.globalConfig.color.LABLE_GRAY} !important`,
        padding: "1px 0px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "14px !important",
        }
    },
    headerStyle: {
        // "& .elss0": {
        //     textAlign: "center",

        // },
        "& .elss1": {
            textAlign: "center"
        },
        "& .elss2": {
            textAlign: "right"
        },
        "& .elss3": {
            textAlign: "right",
            paddingRight: 10
        }
    },
    SchemeName: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "13px",
        fontWeight: 500
    },
    ElssSchemeName: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "14px",
        fontWeight: 500
    },
    exitload: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "13px"
    },
    total: {
        color: window.globalConfig.color.WHITE,
        fontSize: "15px"
    },

    HeaderBlock: {
        // [theme.breakpoints.down('xs')]: {
        //     display: "none !important"
        // }
    },
    // HeaderBlockResponsive: {
    //     display: "none !important",
    //     [theme.breakpoints.down('xs')]: {
    //         display: "block !important",
    //         paddingBottom: 40
    //     }
    // }

    //STLT

    STLT: {
        flex: 8,
        marginTop: "-30px",
        marginBottom: "3%",
        [theme.breakpoints.down('xs')]: {
            display: "none !important"
        }
    },
    STLTResponsive: {
        display: "none !important",
        [theme.breakpoints.down('xs')]: {
            display: "block !important",
            padding: "0px 10px"
            // margin: -16
            // marginTop:"-10px !important"
        }
    },
    ExitBlock: {
        [theme.breakpoints.down('xs')]: {
            display: "none !important"
        }
    },
    ExitResponsive: {
        display: "none !important",
        [theme.breakpoints.down('xs')]: {
            display: "block !important",
            margin: "0px 10px"
        }
    },
    ELSSBlock: {
        [theme.breakpoints.down('xs')]: {
            display: "none !important"
        }
    },
    ELSSResponsive: {
        display: "none !important",
        [theme.breakpoints.down('xs')]: {
            display: "block !important",
            margin: "0px 10px"
        }
    },
    flexrow: {
        display: "flex", flexDirection: "row", justifyContent: "space-between"
    },
    shorttermholdingheader: {
        display: "flex", marginLeft: "27%", justifyContent: "flex-start"
    },
    longtermholding: {
        display: "flex", marginLeft: "14%", justifyContent: "center"
    },
    totalholding: {
        display: "flex", marginLeft: "15%"

    },
    right: {
        textAlign: "right"
    },
    center: {
        textAlign: "center"
    },
    left: {
        textAlign: "left"
    },
    schemeNamecommonstyle: {
        textAlign: 'left'
    },
    InvestedAmount: {
        paddingTop: "18%"
    },
    CurrentAmount: {
        paddingTop: "5%"
    },
    categorycontainer: {
        backgroundColor: window.globalConfig.color.ZERO_BACKGROUND, flex: 1,
        height: "91px",
        alignItems: "center",
        borderRadius: 8, color: window.globalConfig.color.BLACK_PRIMARY,
    },
    grandtotalcontainer: {
        backgroundColor: window.globalConfig.color.BLACK_PRIMARY, paddingTop: "3%", height: 44, paddingBottom: "3%", borderRadius: 6, marginTop: '1%',
        marginRight: "1.5%"
    },
    grandtotaltext: {
        textAlign: 'left', color: window.globalConfig.color.WHITE, fontSize: 18
    },
    whitecolor: {
        color: window.globalConfig.color.WHITE
    },
    //Exitload
    ExitLoadcontainer: {
        display: "flex", marginRight: "21%", flex: 1, flexDirection: "row", justifyContent: "flex-end"
    }
    , totalunitsexitload: {
        marginLeft: -8,
    },
    gainlossexitload: {
        marginLeft: -11,
    },
    //ELSS
    Elsscontainer: {
        display: "flex", flexDirection: "row", backgroundColor: window.globalConfig.color.ZERO_BACKGROUND, flex: 1,
        paddingTop: "2%", paddingBottom: "2%", paddingLeft: "1%", paddingRight: "1%",
        borderRadius: 6, color: window.globalConfig.color.BLACK_PRIMARY,
        marginTop: "-8px",
        // marginRight: "1.5%"
    },
    amcnameelss: {
        display: 'flex', flex: 1, justifyContent: "flex-start", textTransform: "uppercase"
    },
    ElssAmccontainer: {
        backgroundColor: window.globalConfig.color.ZERO_BACKGROUND, paddingTop: "2%", paddingBottom: "2%",
        paddingLeft: "1%", paddingRight: "1%", borderRadius: 6, color: window.globalConfig.color.BLACK_PRIMARY,
        textAlign: "left",
        // marginRight: "1.5%"
    },

    directionColumn: {
        display: "flex",
        flexDirection: "column"
    },
    headerAlign: {
        marginTop: "3.5%",

        [theme.breakpoints.between('1250', '1300')]: {
            marginTop: "4%",
        }
    },
    noDataDesign: {
        display: "none !important",
        [theme.breakpoints.between('300', '600')]: {
            display: "block !important",
            width: "100%",
            height: 50,
            paddingTop: 10,
            paddingBottom: 30
        }
    },
    subAFRData: {
        display: "grid",
        gridTemplateColumns: "repeat(4,1fr)",
        gap: 10,
        paddingTop: "2%",
        paddingBottom: "2%",
        "& .afrcard":{
            paddingTop: "0% !important",
            paddingBottom: "0% !important",
        },
        "& .elss3": {
            paddingRight: 10
        },
        "& .schemeName": {
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            gap: 10
        },
        "& .transactionDate": {
            textAlign: "left",
            paddingLeft: 10
        },
        "& .totalInvestedValue": {
            textAlign: "right",
            width: "auto !important",
            margin: 0
        },
        "& .totalCurrentValue": {
            textAlign: "right"
        },
        "& .costOfAquisition": {
            textAlign: "right"
        },
        "& .currentValue": {
            textAlign: "right"
        }


    },
});
class ELSS extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownvalue: "ShortTerm/LongTerm Taxation",
            checked: true,
            accpdf: false,
            accexcel: false,
            subreportID: 1,
            showelss: true,
            Open: false,
        }
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ Open: false })
    };
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }
    render() {
        // 
        const { classes } = this.props
        let combainStyle = Object.assign({}, classes, this.props.externalClasses)
        // try {
        return (<ErrorBoundary>
            <div style={{ marginTop: "4%", marginBottom: "4%" }}>
                <div className={combainStyle.ELSSBlock}>
                    <div className={["columntemp2", combainStyle.tableHeader, classes.headerStyle].join(' ')} style={{ marginBottom: 7 }}>
                        {this.props.elsstitle.map((item, index) => {
                            return (
                                <div className={["subheadingelss", `elss${index}`].join(" ")} >
                                    {item.name}
                                </div>
                            )
                        })}
                    </div>
                    {Boolean(this.props.elssgroupdata) && this.props.elssgroupdata.length != 0 && this.props.elssgroupdata.map((elss, index) => {
                        return (<div key={index} style={{marginTop:index!=0&&"3%"}}>
                            <div className={combainStyle.Elsscontainer} >
                                <div className={[combainStyle.Categoryname, combainStyle.amcnameelss].join(' ')}>
                                    {elss.AmcName}
                                </div>
                            </div>

                            <div className={[classes.subAFRData, combainStyle.ElssAmccontainer].join(' ')} style={{ backgroundColor: "#f0f0f0" }}>
                                <div className="schemeName">
                                    <span className={combainStyle.ElssSchemeName}>  {elss.SchemeData.SchemeName}</span> 
                                    {/* <br></br> */}
                                    <span style={{ color: window.globalConfig.color.LABLE_GRAY }}>    Folio No: {elss.SchemeData.FolioNo}</span>
                                </div>
                                <div>

                                </div>
                                <div className={[combainStyle.invested, "totalInvestedValue"].join(" ")}>

                                    <span>{Label.currency.Rupee}</span>
                                    {NegativeFinding(elss.SchemeData.TotalInvestedValue, "", "", "", "number")}

                                </div>
                                <div className={[combainStyle.commonsubheading, "totalCurrentValue"].join(" ")}>
                                    {/* <div className="presentValue"> */}
                                    <span>{Label.currency.Rupee}</span>
                                    {NegativeFinding(elss.SchemeData.TotalCurrentValue, "", "", "", "number")}
                                    {/* </div> */}

                                </div>

                            </div>
                            {this.state.showelss &&
                                <>
                                    {
                                        elss.SchemeData.Data.map((value, index) => {
                                            return (
                                                <div key={index} className={[classes.subAFRData, "afrcard"].join(" ")} style={{paddingTop:"0%",paddingBottom:"0%"}}>

                                                    <div className='transactionDate'>
                                                        {moment(value.TransactionDate).format("DD MMM YYYY")}
                                                    </div>
                                                    <div className='maturityDate' style={{ textAlign: "center" }}>
                                                        {moment(value.MaturityDate).format("DD MMM YYYY")}
                                                    </div>
                                                    <div className='costOfAquisition'>
                                                        {/* <span className={combainStyle.rupeesymbol}>{Label.currency.Rupee}</span> */}
                                                        {NegativeFinding(value.CostOfAquisition, "", "", "", "rupee")}
                                                    </div>
                                                    <div style={{ paddingRight: 14 }} className='currentValue'>
                                                        {/* <span className={combainStyle.rupeesymbol}>{Label.currency.Rupee}</span> */}
                                                        {NegativeFinding(value.CurrentValue, "", "", "", "rupee")}
                                                    </div>

                                                </div>
                                            )
                                        })
                                    }

                                </>
                            }
                        </div>
                        )
                    })}
                    {this.props.totalelssdata.length != 0 && this.props.totalelssdata.map((item, index) => {
                        return (
                            <div key={index} className={[classes.subAFRData, combainStyle.displayTotalContainer].join(' ')} style={{padding:0}}>
                                <div style={{ textAlign: "left" }} className={[combainStyle.firstGrid, "transactionDate"].join(" ")}>
                                    {this.props.grandtotal}
                                </div>

                                <div className={[combainStyle.secondGrid, "maturityDate", combainStyle.desktopResponsive].join(" ")}>

                                </div>
                                <div style={{ textAlign: "right", }} className={[combainStyle.thirdGrid, "costOfAquisition", combainStyle.desktopResponsive].join(" ")}>
                                    <span className={combainStyle.totalRupee}>{Label.currency.Rupee}<span style={{ color: colors.WHITE, fontWeight: 500 }}>{NegativeFinding(item.totcos, "", "", "", "number")}</span></span>

                                </div>
                                <div className={[combainStyle.directionColumn, combainStyle.fourthGrid, "elss3", combainStyle.desktopResponsive].join(" ")} style={{ textAlign: "right" }}>
                                    <div>
                                        <span className={combainStyle.totalRupee}>{Label.currency.Rupee}</span>
                                        {NegativeFinding(item.totcur, "", "", "", "number")}
                                    </div>

                                </div>


                            </div>
                        )
                    })}
                </div>
            </div>

        </ErrorBoundary>

        )
    }
}
const mapStateToProps = state => {
    // 
    return {
        sessionID: state.Login.sessionID,
        clientID: state.Login.logindetails.ClientID,
        userID: state.Login.userID,
        date: state.Login.date,
        logindetails: state.Login.logindetails,
        L5ID: state.Login.L5ID,
        // shortgroupeddata: state.AFRdata.shortgroupeddata !== undefined ? state.AFRdata.shortgroupeddata : [],
        // exitload: state.AFRdata.exitload !== undefined ? state.AFRdata.exitload : [],
        // elssgroupdata: state.AFRdata.elssgroupdata !== undefined ? state.AFRdata.elssgroupdata : [],
        // totalshortterm: state.AFRdata.totalshortterm !== undefined ? state.AFRdata.totalshortterm : [],
        // totalexitload: state.AFRdata.totalexitload !== undefined ? state.AFRdata.totalexitload : [],
        // totalelssdata: state.AFRdata.totalelssdata !== undefined ? state.AFRdata.totalelssdata : [],
        // shortterm: state.AFRdata.shortterm !== undefined ? state.AFRdata.shortterm : [],
        // Elssdata: state.AFRdata.Elssdata !== undefined ? state.AFRdata.Elssdata : [],
        // isShorttermLoading: state.AFRdata.isShorttermLoading,
        // isExitloadLoading: state.AFRdata.isExitloadLoading,
        // isElssLoading: state.AFRdata.isElssLoading
    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch)
}
const mergedStyle = combineStyles(styles, CommonTableStyle)
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(ELSS))