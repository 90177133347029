import getEquityUcc from '../../../api/profile/equityucc';
import * as types from '../../constants/actionsType';
export function getEquityUccProfileDetails(ClientID) {
    return (dispatch) => {
        getEquityUccProfileDetail(dispatch, ClientID,)
    }
}
function getEquityUccProfileDetail(dispatch, ClientID,) {
    dispatch({
        type: types.EMPTY_EQUITY_PROFILE_DETAILS,
        payload: [],
    });
    dispatch({
        type: types.LOADER_EQUITY_PROFILE_DETAILS,
        payload: true
    })

    getEquityUcc(ClientID,).then((data) => {

        if (data.status == "S") {
            dispatch({
                type: types.PREVENT_API_CALL,
                payload: [{ ProductID: 0, api: "EquityProfileDetails" }],
            });
            dispatch({
                type: types.EQUITY_PROFILE_DETAILS,
                payload: data,
            });

        } else {
            dispatch({
                type: types.PREVENT_API_CALL,
                payload: [{ ProductID: 0, api: "EquityProfileDetails" }],
            });
            // dispatch({
            //     type: types.PREVENT_API_CALL,
            //     payload:"ProfileDetails",
            // });
            dispatch({
                type: types.EMPTY_EQUITY_PROFILE_DETAILS,
                payload: [],
            });
        }
        dispatch({
            type: types.LOADER_EQUITY_PROFILE_DETAILS,
            payload: false
        })
    }).catch((err) => {
        dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "EquityProfileDetails" }],
        });
        // dispatch({
        //     type: types.PREVENT_API_CALL,
        //     payload:"ProfileDetails",
        // });
        dispatch({
            type: types.LOADER_EQUITY_PROFILE_DETAILS,
            payload: false
        })
    })
}