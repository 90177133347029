import * as types from '../../constants/actionsType';
import getPortfolioDetails from '../../../api/portfolio/portfolioapi';
import getAssetWisePortfolioDetails from '../../../api/portfolio/assetwiseportfolio';
import { replaceNull } from '../../../utils/commonfunction';
export function getPortfolio(SessionID, ClientID, AsOnDate, ProductID, FamilyID, L5ID, L4ID,) {
  return (dispatch) => {
    getPortfolioAction(dispatch, SessionID, ClientID, AsOnDate, ProductID, FamilyID, L5ID, L4ID,);
  }
}
function getPortfolioAction(dispatch, SessionID, ClientID, AsOnDate, ProductID, FamilyID, L5ID, L4ID,) {
  dispatch({
    type: types.EMPTY_PORTFOLIO,
    payload: []
  });
  dispatch({
    type: types.LOADER_PORTFOLIO,
    payload: true
  });

  getPortfolioDetails(SessionID, ClientID, AsOnDate, ProductID, FamilyID, L5ID, L4ID).then((data) => {
    dispatch({
      type: types.LOADER_PORTFOLIO,
      payload: false
    });
    // console.log(data)
    try {
      if (data.status == "S") {
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: 1, api: "Portfolio Allocation" }],
        });
        dispatch({
          type: types.PORTFOLIO_ALLOCATION,
          payload: data,
          SessionID, ClientID, AsOnDate
        });
        dispatch({
          type: types.GET_CATEGORYALLOCATION,
          payload: data
        });


      } else {
        dispatch({
          type: types.PORTFOLIO_ALLOCATION,
          payload: [],

        });
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: 1, api: "Portfolio Allocation" }],
        });
        dispatch({
          type: types.GET_CATEGORYALLOCATION,
          payload: []
        });
      }
    } catch (err) {
      // console.log(err)
    }
  })
    .catch((error) => {
      // console.log(error)
      dispatch({
        type: types.LOADER_PORTFOLIO,
        payload: false
      });

      dispatch({
        type: types.PORTFOLIO_ALLOCATION,
        payload: [],

      });
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: 1, api: "Portfolio Allocation" }],
      });
    })
}
export function getAssetWisePortfolio(SessionID, ClientID, AsOnDate, ProductID, FamilyID, L5ID, L4ID,) {
  return (dispatch) => {
    getAssetWisePortfolioAction(dispatch, SessionID, ClientID, AsOnDate, ProductID, FamilyID, L5ID, L4ID,);
  }
}
function getAssetWisePortfolioAction(dispatch, SessionID, ClientID, AsOnDate, ProductID, FamilyID, L5ID, L4ID,) {
  dispatch({
    type: types.EMPTY_ASSET_PORTFOLIO_ALLOCATION,
    payload: []
  });
  dispatch({
    type: types.LOADER_PORTFOLIO_HEADER_DETAILS,
    payload: true
  });
  dispatch({
    type: types.EMPTY_PORTFOLIO_HEADER_DETAILS,
    payload: []
  });
  dispatch({
    type: types.LOADER_ASSET_PORTFOLIO_ALLOCATION,
    payload: true
  });
  getAssetWisePortfolioDetails(SessionID, ClientID, AsOnDate, ProductID, FamilyID, L5ID, L4ID).then((data) => {
    dispatch({
      type: types.LOADER_ASSET_PORTFOLIO_ALLOCATION,
      payload: false
    });
    dispatch({
      type: types.LOADER_PORTFOLIO_HEADER_DETAILS,
      payload: false
    });
    try {
      if (data.status == "S") {
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: 1, api: "Asset Portfolio Allocation" }],
        });
        dispatch({
          type: types.ASSET_PORTFOLIO_ALLOCATION,
          payload: data,

        });

        dispatch({
          type: types.PORTFOLIO_HEADER_DETAILS,
          payload: data.table4 != null && data.table4.length != 0 ? replaceNull(data.table4, "NA") : [],

        });

      } else {
        dispatch({
          type: types.ASSET_PORTFOLIO_ALLOCATION,
          payload: [],

        });
        dispatch({
          type: types.PORTFOLIO_HEADER_DETAILS,
          payload: [],

        });
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: 1, api: "Asset Portfolio Allocation" }],
        });

      }
    } catch (err) {
      console.log(err)
    }
  })
    .catch((error) => {
      console.log(error)
      dispatch({
        type: types.LOADER_ASSET_PORTFOLIO_ALLOCATION,
        payload: false
      });
      dispatch({
        type: types.LOADER_PORTFOLIO_HEADER_DETAILS,
        payload: false
      });
      dispatch({
        type: types.ASSET_PORTFOLIO_ALLOCATION,
        payload: [],

      });
      dispatch({
        type: types.PORTFOLIO_HEADER_DETAILS,
        payload: [],

      });
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: 1, api: "Asset Portfolio Allocation" }],
      });
    })
}
export const GETPortfolioDetail = (data) => {
  return {
    type: types.GET_PORTFOLIO,
    data
  }
}