import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import './loader.css'
export default class Loader extends React.Component {
    constructor(props) {
        super(props),
            this.state = {

            }
    }
    render() {
        if (this.props.page == "loadermandate") {
            return (
                <div style={{ width: '100%', height: '100%', textAlign: 'center', left: '50%' }} className="Loader" >
                    <CircularProgress size={this.props.size == undefined ? 40 : this.props.size} thickness={2} color={window.globalConfig.color.BROWN} />
                </div>
            )
        }
        else if (this.props.page == "floatingdot") {
            return (<div class="snippet" data-title="dot-floating">
                <div class="stage">
                    <div class="dot-floating"></div>
                </div>
            </div>)
        }
        else if (this.props.page == "skeletonreport") {
            // 
            return (
                <div>
                    <div style={{ width: "95%", height: "10vh", borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, borderTop: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, marginLeft: '2vw' }}>
                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <div style={{ height: "10vh", width: '10vw', marginTop: "2vh" }}>
                                <Skeleton style={{ width: '14vw', height: "5vh" }} />
                            </div>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div style={{ borderRight: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, height: "10vh", }}>

                                </div>
                                <div style={{ height: "10vh", width: '6vw' }}>
                                    <Skeleton style={{ height: "12vh", width: '4vw', marginLeft: '1vw', marginTop: "-1.5vh" }} />
                                </div>
                                <div style={{ borderLeft: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, height: "10vh", width: '6vw' }}>
                                    <Skeleton style={{ height: "12vh", width: '4vw', marginLeft: '1vw', marginTop: "-1.5vh" }} />
                                </div>

                            </div>

                        </div>
                    </div>
                    <Skeleton style={{ width: "95%", height: "20vh", marginLeft: "2vw" }} />
                    <Skeleton style={{ width: "95%", height: "20vh", marginLeft: "2vw", marginTop: "-5vh" }} />
                    <Skeleton style={{ width: "95%", height: "20vh", marginLeft: "2vw", marginBottom: "2vh", marginTop: "-5vh" }} />
                </div>
            )
        }
        else if (this.props.page == "chartnormal") {
            return (
                <ChartModel />
            )
        }
        else if (this.props.page == "tablerow") {
            return (
                <ReportModel />
            )
        }
        else if (this.props.page == "table") {
            return (
                <div style={{ width: "100%", height: "40vh", border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, paddingBottom: "4vh", borderRadius: "2vh" }}>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, height: "10vh", paddingLeft: "2vh", paddingRight: "2vh" }}>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Skeleton style={{ height: "4vh", width: "9vw" }}></Skeleton>
                            <Skeleton style={{ height: "4vh", width: "9vw" }}></Skeleton>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Skeleton style={{ height: "4vh", width: "9vw" }}></Skeleton>
                            <Skeleton style={{ height: "4vh", width: "9vw" }}></Skeleton>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Skeleton style={{ height: "4vh", width: "9vw" }}></Skeleton>
                            <Skeleton style={{ height: "4vh", width: "9vw" }}></Skeleton>
                        </div>
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <Skeleton style={{ height: "4vh", width: "9vw" }}></Skeleton>
                            <Skeleton style={{ height: "4vh", width: "9vw" }}></Skeleton>
                        </div>
                    </div>
                    <div>
                        <ReportModel />
                        <ReportModel />
                    </div>
                </div>
            )
        }
        else if (this.props.page == "switchdonut") {
            return (
                <>
                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }}>

                    </div>
                    <div><Skeleton style={{
                        height: "55vh",
                        width: "33vw",
                        marginLeft: "2vw",
                        marginTop: "-2vw"
                    }}></Skeleton></div></>
            )
        }
        else if (this.props.page == "barchart") {
            return (
                <div style={{ display: "flex", flexDirection: "column", paddingRight: '2vh', paddingLeft: "2vh", justifyContent: "center" }}>
                    <Skeleton style={{ height: "11vh", width: "24vw" }}></Skeleton>

                    <Skeleton style={{ height: "11vh", width: "20vw" }}></Skeleton>
                    <Skeleton style={{ height: "11vh", width: "14vw" }}></Skeleton>
                    <Skeleton style={{ height: "11vh", width: "10vw" }}></Skeleton>
                    <Skeleton style={{ height: "11vh", width: "4vw" }}></Skeleton>
                </div>
            )
        } else if (this.props.page == "reportmodel") {
            return (<>
                <Skeleton style={{ width: "95%", height: "20vh", marginLeft: "2vw" }} />
                <Skeleton style={{ width: "95%", height: "20vh", marginLeft: "2vw", marginTop: "-5vh" }} />
                <Skeleton style={{ width: "95%", height: "20vh", marginLeft: "2vw", marginBottom: "2vh", marginTop: "-5vh" }} />
            </>)
        } else if (this.props.page == "customLoader") {
            return (
                <Skeleton style={this.props.style == undefined ? { width: "95%", height: "20vh", marginLeft: "2vw" } : this.props.style} />
            )
        }
        else if (this.props.page == "progress") {
            return (<CircularProgressWithLabel value={this.props.value} />)
        }
        else {
            return (
                // <div style={{display:'flex', padding: '0px', width: '100%', height: '100%', textAlign: 'center',justifyContent:'center', }} className="Loader">
                <CircularProgress size={this.props.size == undefined ? 40 : this.props.size} thickness={this.props.thickness == undefined ? 2 : this.props.thickness} color={`${this.props.color}`} style={{ left: '50%',color:this.props.color }} />
                // </div>
            )
        }
    }
}
export class ReportModel extends React.Component {
    constructor() {
        super();
    }
    render() {
        return (
            <Skeleton style={{ width: "96%", height: "16vh", marginLeft: "1vw" }} />
        )
    }
}
class ChartModel extends React.Component {
    constructor() {
        super();
    }
    render() {
        return (
            <Skeleton style={{ width: "82%", height: "72vh", marginTop: "-10vh", borderRadius: "51%" }} />

        )
    }
}
function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="caption" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}
CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
};

// export default function CircularStatic() {
//   const [progress, setProgress] = React.useState(10);

//   React.useEffect(() => {
//     const timer = setInterval(() => {
//       setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
//     }, 800);
//     return () => {
//       clearInterval(timer);
//     };
//   }, []);

//   return <CircularProgressWithLabel value={progress} />;
// }