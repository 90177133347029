import * as types from '../../constants/actionsType'
const initialState = {
    storeGstAndBankDetails: [],
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        //GSt Number and Bank Details Stored
        case types.STORE_GST_BANK_DETAILS:                      
            if (action.payload != '') {
                return {
                    ...state,                  
                    storeGstAndBankDetails: action.payload != null ? action.payload : state.storeGstAndBankDetails
                }
            }
            else {
                return {
                    ...state,
                    storeGstAndBankDetails: action.payload
                }
            }
        default:
            return state
    }
}
export default reducer
