import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
function getRiaQuestionResponseDetails(data) {
  var value = data
  var config = {
    method: "post",
    url: ApiValues.RiaQuestionResponse,
    headers: {
      // 'Authorization':  `${"Bearer "}${JSON.parse(secureStorage.getItem("token"))}`, 
      'Content-Type': 'application/json',
      // 'api-key': labels.apiKey.apiKeys,
      "Authorization": labels.apiKey.apiKeys
    },
    data: value
  };
  return Networking.PostApi(ApiValues.RiaQuestionResponse, data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });



}
export default getRiaQuestionResponseDetails