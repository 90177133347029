import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from "react-router";
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import labels from '../../utils/constants/labels';
import { Redirect } from "react-router";
import AvatarBadge from "../../components/avatar/avatar";
import { ListItemIcon } from '@material-ui/core';
import '../../utils/assets/fontawesome/css/fontawesome.css';
import '../../utils/assets/fontawesome/css/brands.css';
import '../../utils/assets/fontawesome/css/solid.css'
import '../../utils/assets/fontawesome/webfonts/fa-brands-400.ttf'
import '../../utils/assets/fontawesome/webfonts/fa-solid-900.ttf';
import '../../utils/assets/fontawesome/webfonts/fa-regular-400.ttf';
import { connect } from 'react-redux';
import { replaceNull } from '../../utils/commonfunction';
const styles = theme => ({
    list: {
        width: 250,
        height: "100%",
        paddingTop: "40px",
        [theme.breakpoints.down("920")]: {
            width: 260,
            height: "105%",
            backgroundColor: "#e6e9eb",
            color: "black",
        },
        [theme.breakpoints.down("500")]: {
            width: 250,
            height: "105%",
            backgroundColor: "#e6e9eb",
            color: "black",
        },
        [theme.breakpoints.up("920")]: {
            display: "none"
        }
    },
    mobileResponsive: {
        display: "none !important",
        [theme.breakpoints.down('920')]: {
            display: "block !important",
            marginRight: '10px',
            color: window.globalConfig.color.WHITE
        }
        ,
        [theme.breakpoints.down("600")]: {
            marginTop: "0px",
            marginRight: '10px',
        }
    },
    fullList: {
        width: 'auto',
        //   backgroundColor: "red"
    },
    clearIcon: {
        cursor: "pointer"
    },
    avatarmenu: {
        width: "40px",
        height: "70%"
    },
    titleselect: {
        backgroundColor: "rgb(19, 89, 160)",

        color: "white",
    },
    title: {
        justifyContent: "center",
        "&:hover": {
            color: "white",
        },
    },
    logoSize: {
        width: "120px",
        [theme.breakpoints.up(920)]: {
            display: "none"
        }
    },
    logosize: {
        width: "230px",
        marginLeft: "8% !important",
        //....finnovate login logo only this property use that.....
        padding: '1% 0% 1% 0%',
        // height:"60px",
        objectFit: "cover",
        [theme.breakpoints.down("920")]: {
            width: "190px",
            marginTop: "8px",
            marginLeft: "8%",

        },
    },
    jadeLogo: {
        width: "100px",
        [theme.breakpoints.up(920)]: {
            display: "none",
        }
    },
    iconalign: {
        textAlign: "center"
    },
    iconHover: {
        [theme.breakpoints.down(920)]: {
            "&:hover": {
                backgroundColor: "rgb(19, 89, 160)",
                color: "white",
            },
            cursor: "pointer",
        }
    },
    iconSelected: {
        backgroundColor: "rgb(19, 89, 160)",
        color: "white",
        cursor: "pointer",
    },
    footer: {
        color: "#909090",
        fontSize: "14px",
        padding: "5px",
        marginTop: "8%"
    }


});
class SwipeableTemporaryDrawer extends React.Component {
    state = {
        top: false,
        left: false,
        bottom: false,
        right: false,
    };

    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };
    closeMenu = (side, close) => () => {
        this.setState({
            [side]: close,
        });
    }
    handleClick = (value) => {

        if (value === "DASHBOARD") {
            this.props.history.push(labels.Path.Dashboard)

        } else if (value === "TRACK") {
            this.props.history.push(labels.Path.Tracking)

        }
        else if (value === "TRANSACT") {
            this.props.history.push(labels.Path.Transact)

        }
        else if (value === "PORTFOLIO") {
            this.props.history.push(labels.Path.Portfolio)

        }
        else if (value === "MUTUAL FUNDS") {
            this.props.history.push({
                pathname: labels.Path.MutualTab,
                state: { data: "Summary", tabValue: 0 }
            })

        }
        else if (value === "STOCKS / ETF") {
            this.props.history.push({
                pathname: labels.Path.Equity,
                state: { data: "Summary", tabValue: 0 }
            })
            // this.props.history.push(labels.Path.Scheme)

        }
        else if (value === "BONDS") {
            this.props.history.push({
                pathname: labels.Path.BondsTab,
                state: { data: "Summary", tabValue: 0 }
            })
            // this.props.history.push(labels.Path.Scheme)

        }
        else if (value === "GOLD / SILVER") {
            this.props.history.push({
                pathname: labels.Path.GoldBondsTab,
                state: { data: "Summary", tabValue: 0 }
            })
            // this.props.history.push(labels.Path.Scheme)

        }
        else if (value === "FIXED DEPOSITS") {
            this.props.history.push({
                pathname: labels.Path.FDTab,
                state: { data: "Summary", tabValue: 0 }
            })
            // this.props.history.push(labels.Path.Scheme)

        }
        else if (value === "AIF / PMS") {
            this.props.history.push({
                pathname: labels.Path.AIFTab,
                state: { data: "Summary", tabValue: 0 }
            })
            // this.props.history.push(labels.Path.Scheme)

        }
        else if (value === "REAL ESTATE") {
            this.props.history.push({
                pathname: labels.Path.RealEstateTab,
                state: { data: "Summary", tabValue: 0 }
            })
            // this.props.history.push(labels.Path.Scheme)

        }
        else if (value === "NPS") {
            this.props.history.push({
                pathname: labels.Path.NPSTab,
                state: { data: "Summary", tabValue: 0 }
            })
            // this.props.history.push(labels.Path.Scheme)

        }
        else if (value === "INSURANCE") {
            this.props.history.push({
                pathname: labels.Path.InsuranceTab,
                state: { data: "Summary", tabValue: 0 }
            })
            // this.props.history.push(labels.Path.Scheme)

        }
        else if (value === "EPF") {
            this.props.history.push({
                pathname: labels.Path.EPFTab,
                state: { data: "Summary", tabValue: 0 }
            })
            // this.props.history.push(labels.Path.Scheme)

        }
        else if (value === "STRUCTRED PRODUCTS") {
            this.props.history.push({
                pathname: labels.Path.MLDTab,
                state: { data: "Summary", tabValue: 0 }
            })
            // this.props.history.push(labels.Path.Scheme)

        }
        else if (value === "MF TRADING") {
            this.props.history.push({
                pathname: labels.Path.MFTransaction,
                state: { data: "Summary", tabValue: 0 }
            })
            // this.props.history.push(labels.Path.Scheme)

        }
        else if (value === "BUY/SELL") {
            this.props.history.push(labels.Path.Scheme)

        }
    }

    render() {
        const { classes } = this.props;
        var familyName = replaceNull(this.props.logindetails.FamilyName,"NA")
        const sideList = (
            <div className={classes.list}>
                <div style={{ textAlign: "end", marginRight: "4%", marginTop: "-5%" }}>
                    <span className={classes.clearIcon} onClick={this.closeMenu}>&#x2715;</span>
                </div>
                {/* <div className={classes.iconalign}><img src={finnovate} className={classes.logoSize} /></div> */}
                <img src={`data:image/png;base64,${window.globalConfig.companyDetails.icon}`} className={classes.logosize} />

                <div style={{ display: "flex" }}>
                    <AvatarBadge
                        color={window.globalConfig.color.PRIMARY}
                        className={classes.avatarmenu}
                        avatar={familyName.substring(0, 2)}
                    />
                    <p style={{ textAlign: "center", fontWeight: 600 }}>{familyName}</p>
                </div>
                <List>
                    {this.props.isGuestUser == 9 ? ['DASHBOARD', 'TRANSACT',].map((text, index) => (
                        <ListItem button key={text} onClick={() => { this.handleClick(text) }}>
                            <ListItemText primary={text} />
                        </ListItem>
                    )) :
                        [{ name: 'DASHBOARD', pathname: labels.Path.Dashboard, icon: <i class="fa fa-tachometer"></i> },
                        { name: 'TRANSACT', pathname: labels.Path.Transact, icon: <i class="fa-solid fa-chart-pie" ></i> },
                        { name: "TRACK", pathname: labels.Path.Tracking, icon: <i class="fa fa-fighter-jet" ></i> },
                        { name: 'PORTFOLIO', pathname: labels.Path.Portfolio, icon: <i class="fa-solid fa-money-bill-transfer"></i> },
                        { name: "MUTUAL FUNDS", pathname: labels.Path.MutualTab, icon: <i class="fa fa-university" ></i> },
                        { name: "STOCKS / ETF", pathname: labels.Path.Equity, icon: <i class="fa-solid fa-hand-holding-droplet"></i> },
                        { name: "BONDS", pathname: labels.Path.BondsTab, icon: <i class="fa-solid fa-layer-group"></i> },
                        { name: "GOLD / SILVER", pathname: labels.Path.GoldBondsTab, icon: <i class="fa-solid fa-money-bill-trend-up"></i> },
                        { name: "FIXED DEPOSITS", pathname: labels.Path.FDTab, icon: <i class="fa-solid fa-handshake"></i> },
                        { name: "AIF / PMS", pathname: labels.Path.AIFTab, icon: <i class="fa-solid fa-sack-dollar"></i> },
                        { name: "REAL ESTATE", pathname: labels.Path.RealEstateTab, icon: <i class="fa-solid fa-hand-holding-dollar"></i> },
                        { name: "NPS", pathname: labels.Path.NPSTab, icon: <i class="fa-solid fa-money-check-dollar"></i> },
                        { name: "INSURANCE", pathname: labels.Path.InsuranceTab, icon: <i class="fas fa-shield-alt"></i> },
                        { name: "EPF", pathname: labels.Path.EPFTab, icon: <i class="fa-solid fa-wallet"></i> },
                        { name: "STRUCTRED PRODUCTS", pathname: labels.Path.MLDTab, icon: <i class="fa-solid fa-house-medical"></i> },
                        { name: "MF TRADING", pathname: labels.Path.MFTransaction, icon: <i class="fa-solid fa-comment-dollar"></i> }].map((text, index) => {
                            return <ListItem button key={text} onClick={() => { this.handleClick(text.name) }} className={[location.pathname == text.pathname ? classes.iconSelected : classes.iconHover].join('')}>
                                <ListItemIcon className={classes.title}>{text.icon}</ListItemIcon>
                                <ListItemText primary={text.name} />
                            </ListItem>
                        })}
                </List>
            </div>
        );

        try {
            return (
                <div>
                    <MenuIcon className={classes.mobileResponsive} onClick={this.toggleDrawer('left', true)}>Open Left</MenuIcon>
                    <Drawer
                        anchor="left"
                        open={this.state.left}
                        onClose={this.toggleDrawer('left', false)}
                        onOpen={this.toggleDrawer('left', true)}

                    >
                        <div
                            tabIndex={0}
                            role="button"
                            onClick={this.toggleDrawer('left', false)}
                            onKeyDown={this.toggleDrawer('left', false)}

                        >
                            {sideList}
                        </div>
                        {/* <div className={classes.iconalign} style={{ position: "sticky", bottom: "0" }}><img src={Jadelogo} className={classes.jadeLogo} /></div> */}
                        <div >
                            <footer className={classes.footer}>
                                {labels.messages.PoweredBy}
                            </footer>
                        </div>


                    </Drawer>
                </div>
            );
        } catch (err) {

            return <Redirect to={labels.Path.NotFound} />;
        }
    }
}
SwipeableTemporaryDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => {
    // 
    // a = state.Login.signupmessage
    return {
      sessionID: state?.Login?.sessionID,
      clientID: state?.Login?.logindetails.ClientID,
      date: state?.Login?.date,
      sipCartData: state?.CartReducer?.getAllSipCart == "NA" ? [] : state?.CartReducer?.getAllSipCart,
      onetimeCartData: state?.CartReducer?.getAllOnetimeCart == "NA" ? [] : state?.CartReducer?.getAllOnetimeCart,
      allCart: state?.CartReducer?.allCart == "NA" ? [] : state?.CartReducer?.allCart,
      logindetails: state?.Login?.logindetails,
      isfamilyhead: state?.Login?.isfamilyhead,
      familydetails: state.Login.familydetails,
      l5Report: state?.L5report?.L5report,
      apiPending: state?.Login?.apiPending,
      permissionList: state?.Login?.permissionList,
      isAlertHadData: state.Alert.isAlertHadData,
      uccList: state.ProfileDetails.AllProfileDetails.length != 0 && state.ProfileDetails.AllProfileDetails?.uccList != undefined && state.ProfileDetails.AllProfileDetails?.uccList?.length != 0 ? state.ProfileDetails.AllProfileDetails.uccList != "NA" ? state.ProfileDetails.AllProfileDetails.uccList.filter(
        (ele, ind) =>
          ind ===
          state.ProfileDetails.AllProfileDetails.uccList.findIndex((elem) => elem.ClientCode === ele.ClientCode)
      ).map((item) => {
        return { ...item, "Name": `${item.DisplayName} ( ${item.ClientCode} )` }
      }) : [] : [],
      companyDetails: state.Login.companyDetails,
  
    }
  };
export default withStyles(styles)(withRouter(connect(mapStateToProps,null) (SwipeableTemporaryDrawer)));
