import * as types from '../../constants/actionsType';
import VerifyOTP from '../../../api/login/verifyotp';
export function VerifyOTPChangePassword(email, ClientID,otp) {
    return (dispatch) => {
        VerifyOTPs(dispatch, email, ClientID,otp);
        // dispatch({
        //   type: types.GET_PROFITLOSS_DATA,
        //   payload: Summary,
        // })
    }
}
function VerifyOTPs(dispatch, email,ClientID,otp) {
    VerifyOTP(email, ClientID,otp).then((data) => {
        if (data != undefined) {
            if (data.status == "S") {
                dispatch({
                    type: types.VERIFYOTP,
                    payload: data,
                });
            }
            else{
                dispatch({
                    type: types.VERIFYOTP,
                    payload: data,
                });
            }
        }
    })
}
