import React, { useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Typography } from '@material-ui/core';
import LoopIcon from '@material-ui/icons/Loop';
import Button from '@material-ui/core/Button';
import { withRouter } from "react-router";
import { getESignDownloadReport } from "../../redux/actions/esigndownload/esigndownload";
import { OpenEsignPopUP } from "@surepass/esign";
import getESignDownloadPdfOpenView from "../../api/esignopenviewpdfdownload/esignopenviewpdfdownload"
import Buttons from "../custombutton/button";
import { getPurchasedPlan } from "../../redux/actions/profile/profile";
import getInitializeESignReportDetails from "../../api/initializeesignapi/initializeesignapi"
import CommonSnackbar from '../snackbar/snackbar';
import ErrorBoundary from '../Errorhandling/Errorhandle';
const useStyles = makeStyles((theme) => ({

    gridContainerSecond: {
        display: "grid",
        gridTemplateColumns: '50% 50%',
        marginRight: '5%',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            display: "grid",
            gridTemplateColumns: '100%',
            marginLeft: '5%'
        },
        [theme.breakpoints.between('950', '1090')]: {
            display: "grid",
            gridTemplateColumns: '60% 40%',
        }
    },
    mobileResponsive: {
        [theme.breakpoints.down('xs')]: {
            display: "grid",
            gridTemplateColumns: '100%',
            textAlign: 'center',
        }
    },
    gridContainerFour: {
        border: `1px solid ${window.globalConfig.color.LIGHT_ASH}`,
        display: "grid",
        gridTemplateColumns: '100%',
        marginRight: '5%',
        textAlign: 'left',
        backgroundColor: 'white',
        height: 65,
        width: 300,
        borderRadius: 4,
    },
    headerText: {
        fontSize: 14,
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontWeight: 'bold',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            display: "grid",
            gridTemplateColumns: '100%',
            marginLeft: '5%'
        },
    },
    kycText: {
        fontSize: 10,
        paddingTop: 8,
        paddingLeft: 8
    },
    verifiedText: {
        fontSize: 14,
        paddingTop: 8,
        paddingLeft: 8,
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontWeight: 'bold'
    },

    buttonStyle: {
        color: window.globalConfig.color.PRIMARY,
        backgroundColor: window.globalConfig.color.WHITE,
        border: 'none',
        textTransform: "none !important",
        cursor: "pointer"

    },
    mobileResponsive1: {
        [theme.breakpoints.down('xs')]: {
            display: "grid",
            gridTemplateColumns: '100%',
            marginLeft: '4%',
            textAlign: 'center'
        },
    },
    mobileResponsive2: {
        [theme.breakpoints.down('xs')]: {
            display: "grid",
            gridTemplateColumns: '100%',
            marginLeft: '-38%'
        },
    },

}))
let SubscriptionID = 0

function SubInvestmentAdvisory(props) {
    const [buttonVisible, setButtonVisible] = React.useState(false);
    const [buttonTextVisible, setButtonTextVisible] = React.useState(true);
    // const [viewAllSignPopup, setViewAllSignPopup] = React.useState(false);
    const [planNames, setPlanNames] = React.useState();
    const [SID, setSubscriptionID] = React.useState(0);
    const [planCodes, setPlanCodes] = React.useState();
    const [downloadVisible, setDownloadVisible] = React.useState(true);
    const [surePassLoader, setSurePassLoader] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [messages, setMessages] = React.useState('');


    useEffect(() => {
        // scrollToTopPage();
        setDownloadVisible(true)
        if (props.location.state.data != undefined && props.location.state.data != null && props.location.state.data != "") {
            // 
            setPlanNames(props.location.state.data.Data[0].plan_name)
            setPlanCodes(props.location.state.data.Data[0].plan_code)
        }
        // console.log(props.eSignDownloadFile, props.isSigned)
        if (props.eSignDownloadFile.data != undefined && props.eSignDownloadFile.data != null && props.eSignDownloadFile.data != '') {
            setButtonTextVisible(false)
            props.disableSubscribeButton(false)
            setButtonVisible(true)
            // console.log(props.eSignDownloadFile)
        }
        else if (props.isSigned) {
            setButtonTextVisible(false)
            props.disableSubscribeButton(false)
            setButtonVisible(true)
        } else {
            setButtonTextVisible(true)
            props.disableSubscribeButton(true)
        }


    }, [props.eSignDownloadFile, props.isSigned]);

    //SUREPASS FUNCTIONALITY  
    var message = []
    const openEsign = (onSuccess, onError) => {
        setSurePassLoader(true);
        var token = []
        getInitializeESignReportDetails(planCodes, props.logindetails.ClientID).then((res) => {

            if (res.data.status == "S") {
                setSurePassLoader(false)
                SubscriptionID = res.data?.secondaryData
                props.getSubscriptionID(res.data?.secondaryData)
                // console.log(res.data.secondaryData,res.data)
                if (surePassLoader == false) {
                    token = res.data.data;
                    //this message is used for success response Api call parameter
                    message = res.data.message;
                    const options = {
                        token,
                        window_name: "Surepass",
                        dimension: { width: "450", height: "850" }
                    };
                    const esign = new OpenEsignPopUP(options);
                    esign.openWindow(onSuccess, onError);
                }
            }
            else if (res.data.status == "F") {
                setSurePassLoader(false)
                setOpen(true)
                setMessages(res.data.message)
                //commented by dulcy.
                // if (surePassLoader == false) {
                //     token = res.data.data;
                //     //this message is used for success response Api call parameter
                //     message = res.data.message;
                //     const options = {
                //         token,
                //         window_name: "Surepass",
                //         dimension: { width: "450", height: "850" }
                //     };
                //     const esign = new OpenEsignPopUP(options);
                //     esign.openWindow(onSuccess, onError);
                // }
            } else {
                setSurePassLoader(false)
                setOpen(true)
                setMessages(labels.messages.apierror)
            }
        })

    }

    const onSuccess = (response) => {
        // console.log(SubscriptionID)
        if (response.success == true) {
            //Parameters are - FormNo = props.logindetails.KYCFormNo, PlanCode = planCode(this value get from state)(Send to API Parameter for Get Download url),clientID = message           

            if (downloadVisible == true && props.formNo != undefined && planCodes != undefined && message != undefined) {
                setDownloadVisible(false);
                props.getESignDownloadReport(props.formNo, planCodes, message, props.logindetails.ClientID, SubscriptionID)
            }
        }
        if (props.eSignDownloadFile.status == "S") {
            setButtonVisible(false)
            props.disableSubscribeButton(true)
            setButtonTextVisible(true)
            //This value is passed to stepper page InvestmentAdvisory Tag(for show the button)
            props.visible('true')
        }
    }

    const onError = (response) => {
        // 
        if (response.success == false) {
            setButtonVisible(true)
            props.disableSubscribeButton(false)
            setButtonTextVisible(false)
            setOpen(true)
            setMessages(response.message)
        }
    }


    // viewAllSignPopup functionality(pdf file View in browser)
    const showModal = () => {
        // 
        // console.log(props.eSignDownloadFile)
        if (props.eSignDownloadFile.data != undefined && props.eSignDownloadFile.data != null && props.eSignDownloadFile.data != '') {
            // window.open(props.eSignDownloadFile.data)
            var ext = "pdf"
            // This API is used for show the pdf file View in Browser        
            getESignDownloadPdfOpenView(props.eSignDownloadFile.data, ext).then((response) => {
                if (response != undefined) {
                    return 'success'
                }
            })
        } else if (props.isSigned) {
            var ext = "pdf"
            // This API is used for show the pdf file View in Browser        
            getESignDownloadPdfOpenView(props.url, ext).then((response) => {
                if (response != undefined) {
                    return 'success'
                }
            })
        }
    }

    const changeButton = () => {
        //To do Empty the Reducer, stored PlanCode,PlanName,Amount
        //props.getPurchasedPlan()
        props.history.push({
            pathname: labels.Path.MyPlan,
        })
        props.visible()
    }

    const handlesnanckClose = () => {
        setOpen(false);
    }


    //
    // 
    const classes = useStyles();

    return (
        <ErrorBoundary>

            <div style={{ minHeight: '0vh' }}>
                <div style={{ width: '100%' }} >
                    {/* {} */}
                    <CommonSnackbar severity={"warning"} handlesnanckClose={handlesnanckClose} open={open} message={messages} />
                    <Typography variant="h4" style={{ fontWeight: 'bold', color: window.globalConfig.color.BLACK_PRIMARY, fontSize: 18, marginTop: '1vh' }} className={classes.mobileResponsive} align={'left'}>{labels.stepper.investmentAdvisoryDetails}</Typography>
                    <div style={{ marginTop: "5%" }} >
                        <div>
                            <div className={classes.headerText}>{labels.stepper.PlanDetails}</div>
                            <div className={classes.gridContainerSecond}>

                                <div style={{ marginLeft: '0%', marginTop: '3%', }} className={classes.gridContainerFour} >
                                    <div >
                                        <div className={classes.kycText}>{labels.stepper.PlanName}</div>
                                        <div className={classes.verifiedText}>
                                            {props.location.state.data.Data[0].plan_name != null && props.location.state.data.Data[0].plan_name != undefined && props.location.state.data.Data[0].plan_name != "" ? props.location.state.data.Data[0].plan_name : labels.messages.NA}
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginLeft: '-2%', marginTop: '8%', }}>
                                    <Button className={classes.buttonStyle} onClick={changeButton}><LoopIcon style={{ color: window.globalConfig.color.PRIMARY, }} />{labels.stepper.ChangePlan}</Button>
                                </div>
                            </div>
                            <div style={{ marginTop: '3%', textAlign: 'left', width: 130, height: 50 }} className={classes.mobileResponsive1}>
                                {/* <Buttons ></Buttons> */}
                                {/* {buttonVisible == true ? */}
                                <Buttons disabled={buttonVisible} loader={surePassLoader} onClick={() => openEsign(onSuccess, onError)} page="primary" name={labels.stepper.ViewSign} />
                                {/* :
                                    <Buttons page="secondary" name={labels.stepper.Signed} />
                                } */}
                            </div>

                            <div style={{ marginTop: '3%', textAlign: 'left', }} className={classes.mobileResponsive2}>
                                <Buttons page="linkButton" onClick={showModal} disabled={buttonTextVisible} name={labels.stepper.ViewAllSignedAgreements}></Buttons>
                                {/* {buttonTextVisible == false ?
                                    <Button disabled className={classes.buttonStyle} style={{ marginLeft: '-1%', }}>{labels.stepper.ViewAllSignedAgreements}</Button>
                                    :
                                    <button onClick={showModal} className={classes.buttonStyle} style={{ marginLeft: '-1.5%', }}>{labels.stepper.ViewAllSignedAgreements}</button>
                                    // <a href={props.eSignDownloadFile.data} style={{ marginLeft: '-1.5%', }}>{labels.stepper.ViewAllSignedAgreements}</a>
                                } */}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ErrorBoundary>
    )

}
class InvestmentAdvisory extends React.Component {
    constructor() {
        super();
        this.state = {
            isSigned: false
        }
    }
    render() {
        try {
            return (
                <SubInvestmentAdvisory {...this.props} history={this.props.history} isSigned={this.props.isSigned} url={this.props.url} getPurchasedPlan={this.props.getPurchasedPlan} getESignDownloadReport={this.props.getESignDownloadReport} logindetails={this.props.logindetails} location={this.props.location} eSignDownloadFile={this.props.eSignDownloadFile} visible={this.props.visible} disableSubscribeButton={this.props.disableSubscribeButton} />
            )
        } catch (err) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => ({
    eSignDownloadFile: state.ESignDownload.eSignDownloadOrder != null ? state.ESignDownload.eSignDownloadOrder.length != 0 ? state.ESignDownload.eSignDownloadOrder : [] : [],
    logindetails: state.Login.logindetails,
    isSigned: state.ESignDownload.isSigned,
    url: state.ESignDownload.url,
    formNo: state.Login.formNo,

})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getESignDownloadReport, getPurchasedPlan }, dispatch)
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InvestmentAdvisory))
