import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import loginImage from '../../utils/assets/LoginImage.png';
import EBI from '../../utils/assets/EBI.png';
import AMF from '../../utils/assets/AMF.png';
import STR from '../../utils/assets/STR.png';
import { Asset } from "../../utils/mainasset";
import labels from '../../utils/constants/labels'
import color from "../../utils/colors/colors"
const styles = theme => ({

  subContentBlock: {
    marginTop: 30,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10
    }
  },
  loginMain: {
    marginTop: -30,
    [theme.breakpoints.down('sm')]: {
      marginTop: 'revert',
    },
    [theme.breakpoints.down('xs')]: {
      // marginTop: 15,
      marginLeft: '8%',

    },
    [theme.breakpoints.between('1000', '1500')]: {
      marginTop: 0
    }
  },
  logoImage: {
    width: 140,
    // height:33
    // marginTop:10
  },
  secondaryLogo: {
    marginTop: 30, display: 'flex',
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: 50,
    [theme.breakpoints.down('xs')]: {
      marginTop: 10
    }
  },
  topContent: {
    textAlign: 'left',
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      marginTop: 5,
    }
  },
  firstSubContent: {
    fontSize: 20,
    color: window.globalConfig.color.GREEN,
    fontWeight: 500,
    // [theme.breakpoints.between('1000','1300')]: {
    //   fontSize:28,
    // },
    [theme.breakpoints.between('1000', '1200')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    }

  },
  secSubContent: {
    fontSize: 20,
    color: window.globalConfig.color.LABLE_GRAY,
    fontWeight: 'normal',
    [theme.breakpoints.between('1200', '1300')]: {
      fontSize: 20,
    },
    [theme.breakpoints.between('1000', '1200')]: {
      fontSize: 20,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    }
  },
  centerImg: {
    width: 400,
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      width: 250,
    }
  },
  subContent: {
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: 20,
    [theme.breakpoints.down('xs')]: {
      fontSize: 16,
    }
  },
  finnovateEnd: {
    color: window.globalConfig.color.GREEN, fontSize: 16,
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {

      fontSize: 12,
      paddingBottom: 50
    }
  },
  firstLogo: {
    marginLeft: -10, fontSize: 11, color: window.globalConfig.color.LABLE_GRAY
  },
  secondLogo: {
    marginLeft: -7, fontSize: 11, color: window.globalConfig.color.LABLE_GRAY
  },
  imgSpace: {
    // marginLeft: 50,
    [theme.breakpoints.down('xs')]: {
      // marginLeft: 40,
    }
  },
  endBlock: {
    display: "block !important",
    [theme.breakpoints.down('xs')]: {
      display: "none !important",
    }
  },
  image: {
    // backgroundColor: window.globalConfig.color.FreeSpeechBlue,
    width: '100%',
    height: '100%',
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      flex: "100%",
      flexDirection: "row",
      boxShadow: 'revert',
      justifyContent: "revert",
      alignItems: "revert",
      backgroundSize: 'revert',
      // height: '44%',
      display: 'revert',
    }
  },
})
class CommonLogin extends React.Component {
  constructor() {
    super();

    this.state = {
    }
  }
  render() {
    const { classes } = this.props
    return (
      <div xs={12} className={classes.image}>
        <div className={classes.loginMain} xl={6} md={6} sm={6} xs={12}>
          <div style={{ textAlign: 'left' }}>
            <img src={Asset.logoUrlLogin} alt={Asset.companyName} className={classes.logoImage} />
          </div>
          <div className={classes.topContent}>
            <span className={classes.firstSubContent}>{labels.Login.welcome}</span> <span className={classes.secSubContent}>{labels.Login.joinUs}</span>
            <span className={classes.secSubContent}>{labels.Login.subText}</span>
          </div>
          <div>
            <img src={loginImage} className={classes.centerImg} />
          </div>
          <div className={classes.endBlock}>
            <div className={classes.subContentBlock}>
              <span className={classes.subContent}>{labels.Login.invest}</span>
            </div>
            <div className={classes.secondaryLogo}>
              <div className={classes.imgSpace}>
                <img src={EBI} style={{ width: 40 }} /><br />
                {window.globalConfig.isTradeAllowed && <span className={classes.firstLogo}>{labels.Login.firstLabel}</span>}
              </div>
              <div className={classes.imgSpace}>
                <img src={AMF} style={{ width: 35 }} /><br />
                {window.globalConfig.isTradeAllowed && <span className={classes.secondLogo}>{labels.Login.secondLabel}</span>}
              </div>
              <div className={classes.imgSpace}>
                <img src={STR} style={{ width: 50 }} /><br />
                {window.globalConfig.isTradeAllowed && <span className={classes.secondLogo}>{labels.Login.thirdLabel}</span>}
              </div>
            </div>
          </div>
          <div className={classes.finnovateEnd}>{labels.Login.endState}</div>

        </div>
      </div>
    )
  }
}
export default withStyles(styles)(CommonLogin)