import { NegativeFinding } from "../../../utils/commonfunction";
import labels from "../../../utils/constants/labels";
import * as types from "../../constants/actionsType";
const initialState = {
  summary: [],
  chart: [],
  categorySummary: [],
  isLoadingcategorySummary: false,
  Donut: [],
  isLoadingReport: false,
  catchmessage: false,
  grandtotalIV: "",
  getdeptdata: [],
  isNetworkLow: false,
  //Equity
  equity_summary: [],
  equity_chart: [],
  equity_categorySummary: [],
  equity_isLoadingcategorySummary: false,
  equity_Donut: [],
  equity_isLoadingReport: false,
  equity_catchmessage: false,
  equity_getdeptdata: [],
  equity_grandtotalIV: "",
  isLoadingMarketCapEquity: false,
  isLoadingMarketCapDebt: false,
  isLoadingCategoryEquity: false,
  isLoadingCategoryDebt: false,

  //Bonds
  bonds_summary: [],
  bonds_chart: [],
  bonds_categorySummary: [],
  bonds_isLoadingcategorySummary: false,
  bonds_Donut: [],
  bonds_isLoadingReport: false,
  bonds_catchmessage: false,
  bonds_grandtotalIV: "",
  isLoadingBondsMarketCapEquity: false,
  isLoadingBondsMarketCapDebt: false,
  isLoadingBondsCategoryEquity: false,
  isLoadingBondsCategoryDebt: false,

  //Gold Bonds
  gb_summary: [],
  gb_chart: [],
  gb_categorySummary: [],
  gb_isLoadingcategorySummary: false,
  gb_Donut: [],
  gb_isLoadingReport: false,
  gb_catchmessage: false,
  gb_grandtotalIV: "",
  isLoadingGbMarketCapEquity: false,
  isLoadingGbMarketCapDebt: false,
  isLoadingGbCategoryEquity: false,
  isLoadingGbCategoryDebt: false,


  //REAL ESTATE
  realEstate_summary: [],
  realEstate_chart: [],
  realEstate_categorySummary: [],
  realEstate_isLoadingcategorySummary: false,
  realEstate_Donut: [],
  realEstate_isLoadingReport: false,
  realEstate_catchmessage: false,
  realEstate_grandtotalIV: "",
  isLoadingRealEstateMarketCapEquity: false,
  isLoadingRealEstateMarketCapDebt: false,
  isLoadingRealEstateCategoryEquity: false,
  isLoadingRealEstateCategoryDebt: false,

  //FD
  fd_summary: [],
  fd_chart: [],
  fd_categorySummary: [],
  fd_isLoadingcategorySummary: false,
  fd_Donut: [],
  fd_isLoadingReport: false,
  fd_catchmessage: false,
  fd_grandtotalIV: "",
  isLoadingFdMarketCapEquity: false,
  isLoadingFdMarketCapDebt: false,
  isLoadingFdCategoryEquity: false,
  isLoadingFdCategoryDebt: false,


  //AIF
  aif_summary: [],
  aif_chart: [],
  aif_categorySummary: [],
  aif_isLoadingcategorySummary: false,
  aif_Donut: [],
  aif_isLoadingReport: false,
  aif_catchmessage: false,
  aif_grandtotalIV: "",
  isLoadingAifMarketCapEquity: false,
  isLoadingAifMarketCapDebt: false,
  isLoadingAifCategoryEquity: false,
  isLoadingAifCategoryDebt: false,


  //mld
  mld_summary: [],
  mld_chart: [],
  mld_categorySummary: [],
  mld_isLoadingcategorySummary: false,
  mld_Donut: [],
  mld_isLoadingReport: false,
  mld_catchmessage: false,
  mld_grandtotalIV: "",
  isLoadingMldMarketCapEquity: false,
  isLoadingMldMarketCapDebt: false,
  isLoadingMldCategoryEquity: false,
  isLoadingMldCategoryDebt: false,
  //NPS
  nps_summary: [],
  nps_chart: [],
  nps_categorySummary: [],
  nps_isLoadingcategorySummary: false,
  nps_Donut: [],
  nps_isLoadingReport: false,
  nps_catchmessage: false,
  nps_grandtotalIV: "",
  isLoadingNpsMarketCapEquity: false,
  isLoadingNpsMarketCapDebt: false,
  isLoadingNpsCategoryEquity: false,
  isLoadingNpsCategoryDebt: false,

  //Insurance
  insurance_summary: [],
  insurance_chart: [],
  insurance_categorySummary: [],
  insurance_isLoadingcategorySummary: false,
  insurance_Donut: [],
  insurance_isLoadingReport: false,
  insurance_catchmessage: false,
  insurance_grandtotalIV: "",
  isLoadingInsuranceMarketCapEquity: false,
  isLoadingInsuranceMarketCapDebt: false,
  isLoadingInsuranceCategoryEquity: false,
  isLoadingInsuranceCategoryDebt: false,
};
const colors = labels.chartColor
const chartColor = labels.chartColor
const repeatFor = (arr, size) => {
  var newArr = new Array(size);

  for (var i = 0; i < size; i++) {
    newArr[i] = arr[i % i.length];
    var b = arr[i];

    newArr[i] = b;
  }
  return newArr;
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_SUMMARY_DATA:

      return {
        ...state,
        summary:
          action.payload == null ||
            action.payload == undefined ||
            action.payload.length == 0
            ? []
            : action.payload.map((i, index) => {
              var colorarray = colors;
              const randomElement = colorarray[index];

              return {
                Sector: i.Sector,
                CurrentValue: i.CurrentValue,
                percentage: i.Percentofallocation,
                color: randomElement,
              };
            }),
      };

    case types.GET_CATEGORYALLOCATION:
      let product =
        action.payload == null ||
          action.payload == undefined ||
          action.payload.length == 0 || action.payload.summary == null
          ? []
          : action.payload.summary.filter(
            (ele, ind) =>
              ind ===
              action.payload.summary.findIndex(
                (elem) => elem.AssetClass === ele.AssetClass
              )
          );

      let assetClassMutualFund =
        product.length != 0
          ? product.filter((item) => item.ProductId == 1).map((i) => {
            return {
              product: i.AssetClass,
              productId: i.ProductId,
              value: action.payload.summary.filter(
                (item) =>
                  item.AssetClass == i.AssetClass && item.ProductId == 1
              ),
            };
          })
          : [];

      let assetClassEquity =
        product.length != 0
          ? product.filter((item) => item.ProductId == 2).map((i) => {
            return {
              product: i.AssetClass,
              productId: i.ProductId,
              value: action.payload.summary.filter(
                (item) =>
                  item.AssetClass == i.AssetClass && item.ProductId == 2
              ),
            };
          })
          : [];

      let assetClassBonds =
        product.length != 0
          ? product.filter((item) => item.ProductId == 3).map((i) => {
            return {
              product: i.AssetClass,
              productId: i.ProductId,
              value: action.payload.summary.filter(
                (item) =>
                  item.AssetClass == i.AssetClass && item.ProductId == 3
              ),
            };
          })
          : [];
      let assetClassMLD =
        product.length != 0
          ? product.filter((item) => item.ProductId == 10).map((i) => {
            return {
              product: i.AssetClass,
              productId: i.ProductId,
              value: action.payload.summary.filter(
                (item) =>
                  item.AssetClass == i.AssetClass && item.ProductId == 10
              ),
            };
          })
          : [];

      let assetClassGB =
        product.length != 0
          ? product.filter((item) => item.ProductId == 4).map((i) => {
            return {
              product: i.AssetClass,
              productId: i.ProductId,
              value: action.payload.summary.filter(
                (item) =>
                  item.AssetClass == i.AssetClass && item.ProductId == 4
              ),
            };
          })
          : [];
      //real estate
      let assetClassRealEstate =
        product.length != 0
          ? product.filter((item) => item.ProductId == 7).map((i) => {
            return {
              product: i.AssetClass,
              productId: i.ProductId,
              value: action.payload.summary.filter(
                (item) =>
                  item.AssetClass == i.AssetClass && item.ProductId == 7
              ),
            };
          })
          : [];
      let assetClassFD =
        product.length != 0
          ? product.filter((item) => item.ProductId == 5).map((i) => {
            return {
              product: i.AssetClass,
              productId: i.ProductId,
              value: action.payload.summary.filter(
                (item) =>
                  item.AssetClass == i.AssetClass && item.ProductId == 5
              ),
            };
          })
          : [];
      let assetClassAIF =
        product.length != 0
          ? product.filter((item) => item.ProductId == 6).map((i) => {
            return {
              product: i.AssetClass,
              productId: i.ProductId,
              value: action.payload.summary.filter(
                (item) =>
                  item.AssetClass == i.AssetClass && item.ProductId == 6
              ),
            };
          })
          : [];

      let productColor =
        product.length != 0
          ? product.map((i, index) => {
            return {
              product: i.AssetClass,
              color: chartColor[index],
            };
          })
          : [];

      return {
        ...state,

        chart:
          assetClassMutualFund.length != 0
            ? assetClassMutualFund
              .filter((val) => val.productId == 1)
              .map((i, index) => {
                return {
                  name: i.product,
                  value: i.value.reduce(
                    (a, v) => (a = a + v.InvestmentValue),
                    0
                  ),
                  color: productColor.filter(
                    (item) => item.product == i.product
                  )[0].color,
                };
              })
            : [],
        equity_chart:
          assetClassEquity.length != 0
            ? assetClassEquity
              .filter((val) => val.productId == 2)
              .map((i, index) => {
                return {
                  name: i.product,
                  value: i.value.reduce(
                    (a, v) => (a = a + v.InvestmentValue),
                    0
                  ),
                  color: productColor.filter(
                    (item) => item.product == i.product
                  )[0].color,
                };
              })
            : [],
        bonds_chart:
          assetClassBonds.length != 0
            ? assetClassBonds
              .filter((val) => val.productId == 3)
              .map((i, index) => {
                return {
                  name: i.product,
                  value: i.value.reduce(
                    (a, v) => (a = a + v.InvestmentValue),
                    0
                  ),
                  color: productColor.filter(
                    (item) => item.product == i.product
                  )[0].color,
                };
              })
            : [],
        gb_chart:
          assetClassGB.length != 0
            ? assetClassGB
              .filter((val) => val.productId == 4)
              .map((i, index) => {
                return {
                  name: i.product,
                  value: i.value.reduce(
                    (a, v) => (a = a + v.InvestmentValue),
                    0
                  ),
                  color: productColor.filter(
                    (item) => item.product == i.product
                  )[0].color,
                };
              })
            : [],
        //real estate
        realEstate_chart:
          assetClassRealEstate.length != 0
            ? assetClassRealEstate
              .filter((val) => val.productId == 7)
              .map((i, index) => {
                return {
                  name: i.product,
                  value: i.value.reduce(
                    (a, v) => (a = a + v.InvestmentValue),
                    0
                  ),
                  color: productColor.filter(
                    (item) => item.product == i.product
                  )[0].color,
                };
              })
            : [],
        fd_chart:
          assetClassFD.length != 0
            ? assetClassFD
              .filter((val) => val.productId == 5)
              .map((i, index) => {
                return {
                  name: i.product,
                  value: i.value.reduce(
                    (a, v) => (a = a + v.InvestmentValue),
                    0
                  ),
                  color: productColor.filter(
                    (item) => item.product == i.product
                  )[0].color,
                };
              })
            : [],
        aif_chart:
          assetClassAIF.length != 0
            ? assetClassAIF
              .filter((val) => val.productId == 6)
              .map((i, index) => {
                return {
                  name: i.product,
                  value: i.value.reduce(
                    (a, v) => (a = a + v.InvestmentValue),
                    0
                  ),
                  color: productColor.filter(
                    (item) => item.product == i.product
                  )[0].color,
                };
              })
            : [],
        mld_chart:
          assetClassMLD.length != 0
            ? assetClassMLD
              .filter((val) => val.productId == 10)
              .map((i, index) => {
                return {
                  name: i.product,
                  value: i.value.reduce(
                    (a, v) => (a = a + v.InvestmentValue),
                    0
                  ),
                  color: productColor.filter(
                    (item) => item.product == i.product
                  )[0].color,
                };
              })
            : [],
        categorySummary:
          assetClassMutualFund.length != 0
            ? assetClassMutualFund
              .filter((item) => item.productId == 1)
              .map((i, index) => {
                return {
                  id: index,
                  category: i.product,
                  isExpand: true,
                  color: productColor.filter(
                    (item) => item.product == i.product
                  )[0].color,
                  amount: i.value.filter((item) => item.ProductId == 1)[0]
                    .CategoryCurrentValue,
                  categoryPercentage: "-",
                  holdingPercentage: i.value.filter(
                    (item) => item.ProductId == 1
                  )[0].TotalHoldingPercentage,
                  rowitem: i.value
                    .filter((item) => item.ProductId == 1)
                    .map((item) => {
                      return {
                        category: item.SubAssetClass,
                        amount: item.CurrentValue,
                        categoryPercentage: item.CategoryPercent,
                        holdingPercentage: item.HoldingPercent,
                      };
                    }),
                };
              })
            : [],
        equity_categorySummary:
          assetClassEquity.length != 0
            ? assetClassEquity
              .filter((item) => item.productId == 2)
              .map((i, index) => {
                return {
                  id: index,
                  category: i.product,
                  isExpand: true,
                  color: productColor.filter(
                    (item) => item.product == i.product
                  )[0].color,
                  amount: i.value.filter((item) => item.ProductId == 2)[0]
                    .CategoryCurrentValue,
                  categoryPercentage: "-",
                  holdingPercentage: i.value.filter(
                    (item) => item.ProductId == 2
                  )[0].TotalHoldingPercentage,
                  rowitem: i.value
                    .filter((item) => item.ProductId == 2)
                    .map((item) => {
                      return {
                        category: item.SubAssetClass,
                        amount: item.CurrentValue,
                        categoryPercentage: item.CategoryPercent,
                        holdingPercentage: item.HoldingPercent,
                      };
                    }),
                };
              })
            : [],
        bonds_categorySummary:
          assetClassBonds.length != 0
            ? assetClassBonds
              .filter((item) => item.productId == 3)
              .map((i, index) => {
                return {
                  id: index,
                  category: i.product,
                  isExpand: true,
                  color: productColor.filter(
                    (item) => item.product == i.product
                  )[0].color,
                  amount: i.value.filter((item) => item.ProductId == 3)[0]
                    .CategoryCurrentValue,
                  categoryPercentage: "-",
                  holdingPercentage: i.value.filter(
                    (item) => item.ProductId == 3
                  )[0].TotalHoldingPercentage,
                  rowitem: i.value
                    .filter((item) => item.ProductId == 3)
                    .map((item) => {
                      return {
                        category: item.SubAssetClass,
                        amount: item.CurrentValue,
                        categoryPercentage: item.CategoryPercent,
                        holdingPercentage: item.HoldingPercent,
                      };
                    }),
                };
              })
            : [],

        gb_categorySummary:
          assetClassGB.length != 0
            ? assetClassGB
              .filter((item) => item.productId == 4)
              .map((i, index) => {
                return {
                  id: index,
                  category: i.product,
                  isExpand: true,
                  color: productColor.filter(
                    (item) => item.product == i.product
                  )[0].color,
                  amount: i.value.filter((item) => item.ProductId == 4)[0]
                    .CategoryCurrentValue,
                  categoryPercentage: "-",
                  holdingPercentage: i.value.filter(
                    (item) => item.ProductId == 4
                  )[0].TotalHoldingPercentage,
                  rowitem: i.value
                    .filter((item) => item.ProductId == 4)
                    .map((item) => {
                      return {
                        category: item.SubAssetClass,
                        amount: item.CurrentValue,
                        categoryPercentage: item.CategoryPercent,
                        holdingPercentage: item.HoldingPercent,
                      };
                    }),
                };
              })
            : [],
        realEstate_categorySummary:
          assetClassRealEstate.length != 0
            ? assetClassRealEstate
              .filter((item) => item.productId == 7)
              .map((i, index) => {
                return {
                  id: index,
                  category: i.product,
                  isExpand: true,
                  color: productColor.filter(
                    (item) => item.product == i.product
                  )[0].color,
                  amount: i.value.filter((item) => item.ProductId == 7)[0]
                    .CategoryCurrentValue,
                  categoryPercentage: "-",
                  holdingPercentage: i.value.filter(
                    (item) => item.ProductId == 7
                  )[0].TotalHoldingPercentage,
                  rowitem: i.value
                    .filter((item) => item.ProductId == 7)
                    .map((item) => {
                      return {
                        category: item.SubAssetClass,
                        amount: item.CurrentValue,
                        categoryPercentage: item.CategoryPercent,
                        holdingPercentage: item.HoldingPercent,
                      };
                    }),
                };
              })
            : [],
        fd_categorySummary:
          assetClassFD.length != 0
            ? assetClassFD
              .filter((item) => item.productId == 5)
              .map((i, index) => {
                return {
                  id: index,
                  category: i.product,
                  isExpand: true,
                  color: productColor.filter(
                    (item) => item.product == i.product
                  )[0].color,
                  amount: i.value.filter((item) => item.ProductId == 5)[0]
                    .CategoryCurrentValue,
                  categoryPercentage: "-",
                  holdingPercentage: i.value.filter(
                    (item) => item.ProductId == 5
                  )[0].TotalHoldingPercentage,
                  rowitem: i.value
                    .filter((item) => item.ProductId == 5)
                    .map((item) => {
                      return {
                        category: item.SubAssetClass,
                        amount: item.CurrentValue,
                        categoryPercentage: item.CategoryPercent,
                        holdingPercentage: item.HoldingPercent,
                      };
                    }),
                };
              })
            : [],
        aif_categorySummary:
          assetClassAIF.length != 0
            ? assetClassAIF
              .filter((item) => item.productId == 6)
              .map((i, index) => {
                return {
                  id: index,
                  category: i.product,
                  isExpand: true,
                  color: productColor.filter(
                    (item) => item.product == i.product
                  )[0].color,
                  amount: i.value.filter((item) => item.ProductId == 6)[0]
                    .CategoryCurrentValue,
                  categoryPercentage: "-",
                  holdingPercentage: i.value.filter(
                    (item) => item.ProductId == 6
                  )[0].TotalHoldingPercentage,
                  rowitem: i.value
                    .filter((item) => item.ProductId == 6)
                    .map((item) => {
                      return {
                        category: item.SubAssetClass,
                        amount: item.CurrentValue,
                        categoryPercentage: item.CategoryPercent,
                        holdingPercentage: item.HoldingPercent,
                      };
                    }),
                };
              })
            : [],
        mld_categorySummary:
          assetClassMLD.length != 0
            ? assetClassMLD
              .filter((item) => item.productId == 10)
              .map((i, index) => {
                return {
                  id: index,
                  category: i.product,
                  isExpand: true,
                  color: productColor.filter(
                    (item) => item.product == i.product
                  )[0].color,
                  amount: i.value.filter((item) => item.ProductId == 10)[0]
                    .CategoryCurrentValue,
                  categoryPercentage: "-",
                  holdingPercentage: i.value.filter(
                    (item) => item.ProductId == 10
                  )[0].TotalHoldingPercentage,
                  rowitem: i.value
                    .filter((item) => item.ProductId == 10)
                    .map((item) => {
                      return {
                        category: item.SubAssetClass,
                        amount: item.CurrentValue,
                        categoryPercentage: item.CategoryPercent,
                        holdingPercentage: item.HoldingPercent,
                      };
                    }),
                };
              })
            : [],
        grandtotalIV:
          action.payload.summary != null
            ? action.payload.summary.length != 0
              ? NegativeFinding(action.payload.summary
                .filter((item) => item.ProductId == 1)[0]
                ?.TotalCurrentValue, "", "", "", "number")
              : []
            : [],
        fd_grandtotalIV: action.payload.summary != null
          ? action.payload.summary.length != 0
            ? NegativeFinding(action.payload.summary
              .filter((item) => item.ProductId == 5)[0]
              ?.TotalCurrentValue, "", "", "", "number")
            : []
          : [],
        aif_grandtotalIV: action.payload.summary != null
          ? action.payload.summary.length != 0
            ? NegativeFinding(action.payload.summary
              .filter((item) => item.ProductId == 6)[0]
              ?.TotalCurrentValue, "", "", "", "number")
            : []
          : [],
        mld_grandtotalIV: action.payload.summary != null
          ? action.payload.summary.length != 0
            ? NegativeFinding(action.payload.summary
              .filter((item) => item.ProductId == 10)[0]
              ?.TotalCurrentValue, "", "", "", "number")
            : []
          : [],
        bonds_grandtotalIV: action.payload.summary != null
          ? action.payload.summary.length != 0
            ? NegativeFinding(action.payload.summary
              .filter((item) => item.ProductId == 3)[0]
              ?.TotalCurrentValue, "", "", "", "number")
            : []
          : [],
        equity_grandtotalIV: action.payload.summary != null
          ? action.payload.summary.length != 0
            ? NegativeFinding(action.payload.summary
              .filter((item) => item.ProductId == 2)[0]
              ?.TotalCurrentValue, "", "", "", "number")
            : []
          : [],
        gb_grandtotalIV: action.payload.summary != null
          ? action.payload.summary.length != 0
            ? NegativeFinding(action.payload.summary
              .filter((item) => item.ProductId == 4)[0]
              ?.TotalCurrentValue, "", "", "", "number")
            : []
          : [],
        isLoadingcategorySummary: false,
        realEstate_grandtotalIV: action.payload.summary != null
          ? action.payload.summary.length != 0
            ? NegativeFinding(action.payload.summary
              .filter((item) => item.ProductId == 7)[0]
              ?.TotalCurrentValue, "", "", "", "number")
            : []
          : [],
        realEstate_isLoadingcategorySummary: false,
      };
    case types.GET_DONUT:
      return {
        ...state,
        Donut:
          action.payload == null ||
            action.payload == undefined ||
            action.payload.length == 0
            ? state.Donut
            : action.payload.map((i) => {
              return { name: i.SubAssetClass, value: i.Percentofallocation };
            }),
      };
    case types.GET_DEBT_DONUT:
      //

      return {
        ...state,
        getdeptdata:
          action.payload == null ||
            action.payload == undefined ||
            action.payload.length == 0
            ? state.getdeptdata
            : action.payload.map((i) => {
              return { name: i.SubAssetClass, value: i.Percentofallocation };
            }),
      };
    case types.GET_SUMMARY:
      return {
        ...state,
      };
    case types.EMPTY_SUMMARY:
      return {
        ...state,
        summary: [],
        chart: [],
        categorySummary: [],
        isLoadingcategorySummary: false,
        Donut: [],
        isLoadingReport: false,
        catchmessage: false,
        grandtotalIV: "",
        isLoadingCategoryEquity: false,
        isLoadingCategoryDebt: false,
      };
    case types.LOADER_SUMMARY:
      return {
        ...state,
        isLoadingcategorySummary: action.payload,
      };
    case types.MF_CATEGORY_ALLOCATION_EXPAND:
      let newArray = [...action.data];
      //this.state.amcList=""
      newArray[action.rowId] = {
        ...newArray[action.rowId],
        isExpand: !newArray[action.rowId].isExpand,
      };
      return {
        ...state,
        categorySummary: newArray,
      };
    case types.EQUITY_CATEGORY_ALLOCATION_EXPAND:
      let newEquityArray = [...action.data];

      newEquityArray[action.rowId] = {
        ...newEquityArray[action.rowId],
        isExpand: !newEquityArray[action.rowId].isExpand,
      };
      return {
        ...state,
        equity_categorySummary: newEquityArray,
      };
    case types.BONDS_CATEGORY_ALLOCATION_EXPAND:
      let newBondsArray = [...action.data];

      newBondsArray[action.rowId] = {
        ...newBondsArray[action.rowId],
        isExpand: !newBondsArray[action.rowId].isExpand,
      };
      return {
        ...state,
        bonds_categorySummary: newBondsArray,
      };
    case types.GB_CATEGORY_ALLOCATION_EXPAND:
      let newGbArray = [...action.data];

      newGbArray[action.rowId] = {
        ...newGbArray[action.rowId],
        isExpand: !newGbArray[action.rowId].isExpand,
      };
      return {
        ...state,
        gb_categorySummary: newGbArray,
      };
    case types.FD_CATEGORY_ALLOCATION_EXPAND:
      let newFdArray = [...action.data];
      newFdArray[action.rowId] = {
        ...newFdArray[action.rowId],
        isExpand: !newFdArray[action.rowId].isExpand,
      };
      return {
        ...state,
        fds_categorySummary: newFdArray,
      };
    //Real state
    case types.REALESTATE_CATEGORY_ALLOCATION_EXPAND:
      let newRealEstateArray = [...action.data];
      newRealEstateArray[action.rowId] = {
        ...newRealEstateArray[action.rowId],
        isExpand: !newRealEstateArray[action.rowId].isExpand,
      };
      return {
        ...state,
        realEstate_categorySummary: newRealEstateArray,
      };
    case types.EMPTY_DONUT:
      return {
        ...state,
        Donut: [],
      };
    case types.EMPTY_DEBT_DONUT:
      return {
        ...state,
        getdeptdata: [],
      };
    case types.NETWORK_SUMMARY_DATA:
      return {
        ...state,
        isNetworkLow: true,
      };
    case types.EXPORTREPORT:
      return {
        ...state,
        isLoadingReport: action.payload == "" ? true : false,
        catchmessage: action.payload === "fail" ? true : false,
      };

    case types.EQUITY_SUMMARY:
      return {
        ...state,
        equity_summary:
          action.payload != null &&
            action.payload != undefined &&
            action.payload.length != 0
            ? action.payload.map((i, index) => {
              var equity_colorarray = colors
              const equity_randomElement = equity_colorarray[index];
              return {
                Sector: i.Sector,
                CurrentValue: i.CurrentValue,
                percentage: i.Percentofallocation,
                color: equity_randomElement,
              };
            })
            : state.equity_summary,
      };
    case types.EMPTY_EQUITY_SUMMARY:
      return {
        ...state,
        equity_summary: [],
        equity_chart: [],
        equity_categorySummary: [],
        equity_isLoadingcategorySummary: false,
        equity_Donut: [],
        equity_isLoadingReport: false,
        equity_catchmessage: false,
        equity_grandtotalIV: "",
        isLoadingMarketCapEquity: false,
        isLoadingMarketCapDebt: false,
      };
    case types.LOADER_EQUITY_SUMMARY:
      return {
        ...state,
        equity_isLoadingcategorySummary: action.payload,
      };
    case types.LOADER_SPLIT_BY_MARKET_CAP_EQUITY:
      return {
        ...state,
        isLoadingMarketCapEquity: action.payload,
      };
    case types.LOADER_SPLIT_BY_MARKET_CAP_DEBT:
      return {
        ...state,
        isLoadingMarketCapDebt: action.payload,
      };
    case types.LOADER_SPLIT_BY_CATEGORY_DEBT:
      return {
        ...state,
        isLoadingCategoryDebt: action.payload,
      };
    case types.LOADER_SPLIT_BY_CATEGORY_EQUITY:
      return {
        ...state,
        isLoadingCategoryEquity: action.payload,
      };
    case types.EMPTY_EQUITY_INSTRUMENT:
      return {
        ...state,
        equity_isLoadingcategorySummary: false,
        equity_Donut: [],
        isLoadingMarketCapEquity: false,
      };
    case types.EMPTY_DEBT_INSTRUMENT:
      return {
        ...state,
        equity_isLoadingcategorySummary: false,
        equity_getdeptdata: [],

        isLoadingMarketCapDebt: false,
      };
    case types.EQUITY_INSTRUMENT:
      //
      return {
        ...state,
        equity_Donut:
          action.payload != null &&
            action.payload != undefined &&
            action.payload.length != 0
            ? action.payload.map((i) => {
              return { name: i.SubAssetClass, value: i.Percentofallocation };
            })
            : state.equity_Donut,
      };
    case types.DEBT_INSTRUMENT:
      //
      return {
        ...state,
        equity_getdeptdata:
          action.payload == null ||
            action.payload == undefined ||
            action.payload.length == 0
            ? state.equity_getdeptdata
            : action.payload.map((i) => {
              return { name: i.SubAssetClass, value: i.Percentofallocation };
            }),
      };
    default:
      return state;
  }
};
export default reducer;
