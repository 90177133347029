/* name should be camel case in all function */
//const BaseUrl='https://jsonplaceholder.typicode.com/todos';
import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
 function getInstrumentDetails(ClientID,AsOnDate,SessionID,InstrumentType,ProductID,FamilyID,L5ID,L4ID) {
  //let BaseUrl="https://ms.zoifintech.com/Dashboard/GetMFInstrumentDetails"
  let data={};
  data.ClientID=AsOnDate
  data.AsOnDate=SessionID
  data.SessionID=ClientID
  data.InstrumentType=InstrumentType
  data.ProductID = ProductID
  data.FamilyID = FamilyID
  data.L5ID = L5ID
  data.L4ID = L4ID
//  var config = {
//    method: 'post',
//    url: ApiValues.InstrumentDetails,
//    headers: { 
//      'Content-Type': 'application/json'
//    },
//    data : data
//  };
 
 
//   //  (data,"split")
//  return axios(config)
//  .then(function (response) {
//   return response.data;
//  })
//  .catch(function (error) {
//   return {"status":"F","message":labels.messages.apierror}
// });
return Networking.PostApi(ApiValues.InstrumentDetails, data).then(result => { return result })
.catch(function (error) {
  return { "status": "F", "message": labels.messages.apierror }
});

}
 export {getInstrumentDetails};
