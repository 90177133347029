import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import TextInput from '../../components/textinput/textinput'
import labels from '../../utils/constants/labels';
// import { updatePassword, closeUpdatePasswordMsgAction } from '../../redux/actions/authActions';
// import MessagesForm from '../Forms/MessagesForm';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setNewPassword } from '../../redux/actions/login/loginaction'
import { PostSendOtp } from '../../redux/actions/resetpassword/otpsend'
import colors from '../../utils/colors/colors';
const styles = (theme) => ({
    paper: {
        maxWidth: "30%",
        [theme.breakpoints.between('xs', 'md')]: {
            maxWidth: "80%",
        },
        // [theme.breakpoints.up('sm')]: {
        //     maxWidth : "30%"
        // },

    },

    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    input: {
        font: "inherit",
        //color: "currentColor";
        maxWidth: "100%",
        border: 0,
        height: "1.1876em",
        margin: 0,
        display: "block",
        padding: "6px 0 7px 7px",
        minWidth: 0,
        background: "none",
        boxSizing: "content - box",
        marginLeft: "-2%",
        animationName: "mui - auto - fill - cancel",
        letterSpacing: "inherit",
        animationDuration: "10ms",
        // -webkit - tap - highlight - color: transparent;
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    header: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: window.globalConfig.color.WHITE,
        height: 50,
        fontWeight: 500
    },
    txtHeader: {
        padding: 15,
        paddingLeft: 30,
    },
    clearIcon: {
        float: 'right',
        cursor: 'pointer'
    },
    body: {
        paddingLeft: 28,
        textAlign: 'center',
        paddingRight: 40
    },
    footer: {
        padding: "14px 20px 20px 20px",
        marginTop: -5,
    },
    btnParent: {
        padding: "15px 10px 10px 10px",
        textAlign: 'center'
    },
    btnContinue: {

        [theme.breakpoints.up('xs')]: {
            color: "white",
            width: 250,
            height: 40,
            backgroundColor: window.globalConfig.color.BROWN,
            borderWidth: 0,
            borderRadius: 5,
        },
        [theme.breakpoints.up('330')]: {
            width: 200,
        }

    },
});
export const requireValidator = value => {
    if (!value || value.length <= 0) return labels.messages.required;
    return '';
};
export const confirmPasswordValidator = (value, account) => {
    if (!value || value.length <= 0) return labels.messages.required;
    if (value != account) return 'New password and Confirm password does not match'
    return '';
};
function ChangePassword(props) {
    const [newPassword, setNewPassword] = useState({ value: '', error: '' })
    const [confirmPassword, setconfirmPassword] = useState({ value: '', error: '' })

    const handleSubmit = () => {
        const newPasswordError = requireValidator(newPassword.value);
        const confirmPasswordError = confirmPasswordValidator(confirmPassword.value, newPassword.value);
        //    ("confirmPasswordError",confirmPasswordError)
        setconfirmPassword({ ...confirmPassword, error: confirmPasswordError })
        if (newPasswordError || confirmPasswordError) {
            setNewPassword({ ...newPassword, error: newPasswordError })
        } else {
            if (newPassword.value != null && newPassword.value != '' && confirmPassword.value != null && confirmPassword.value != '') {
                props.updatePassword(confirmPassword.value)
                setNewPassword({ value: '', error: '' })
                setconfirmPassword({ value: '', error: '' })
                props.close()
                props.setNewPassword(false)
            }
        }
    }
    const onClose = () => {
        props.close()
        props.setNewPassword(false)
    }
    const { classes } = props;
    return (
        <div>
            <Dialog onClose={onClose}
                classes={{ paper: classes.paper }}
                maxWidth="sm"
                open={props.open}>
                {/* ===================NEW DESIGN - AFTER CHANGE DESIGN================================ */}
                <div>
                    <div className={classes.header}>
                        <div className={classes.txtHeader}>
                            Reset Password
                            <span className={classes.clearIcon} onClick={() => onClose()}>&#x2715;</span>
                        </div>
                    </div>
                    <div className={classes.body}>
                        <form >
                            <div>
                                <FormControl style={{ width: "100%", padding: 5 }}>
                                    <TextInput page="TextFieldpassword"
                                        //  type={showPassword}
                                        label="New Password"
                                        otherProps={{ name: "newPassword" }}
                                        classes={{ input: classes.input }}
                                        value={newPassword.value}
                                        // onKeyDown={this.onPasswordKeypress}
                                        onChange={(e) => setNewPassword({ value: e.target.value, error: "" })}
                                        errormessage={newPassword.error}
                                    // onClick={this.handleClickShowPassword}
                                    // onMouseDown={this.handleMouseDownPassword}
                                    />
                                    {/* <TextField
                                        id="standard-basic"
                                        name="newPassword"
                                        label="New Password"
                                        type="password"
                                        className="text-field-cursor"
                                        value={newPassword.value}
                                        onChange={(e) => { const error = requireValidator(e.target.value); setNewPassword({ value: e.target.value, error: error }) }}
                                        error={newPassword.error.length > 0}
                                        helperText={newPassword.error}
                                    /> */}
                                </FormControl>
                                <FormControl style={{ width: "100%", padding: 5 }}>
                                    {/* <TextField
                                        id="standard-basic-width"
                                        name="confirm Password"
                                        type="password"
                                        label="Confirm Password"
                                        className="text-field-cursor"
                                        value={confirmPassword.value}
                                        onChange={(e) => { const error = confirmPasswordValidator(e.target.value, newPassword.value); setconfirmPassword({ value: e.target.value, error: error }) }}
                                        error={confirmPassword.error.length > 0}
                                        helperText={confirmPassword.error}
                                    /> */}
                                    <TextInput page="TextFieldpassword"

                                        label="Confirm Password"
                                        classes={{ input: classes.input }}
                                        value={confirmPassword.value}
                                        otherProps={{ name: "confirmPassword" }}

                                        onChange={(e) => setconfirmPassword({ value: e.target.value, error: "" })}
                                        errormessage={confirmPassword.error}

                                    />
                                </FormControl>

                            </div>
                        </form>
                    </div>
                    <div className={classes.body}>

                    </div>
                </div>
                <div className={classes.footer}>
                    <div className={classes.btnParent}>
                        <button className={classes.btnContinue} onClick={() => handleSubmit()} >
                            SUBMIT
                        </button>
                    </div>
                </div>



            </Dialog>
        </div >
    );
}
const dispatchToProps = (dispatch) => {
    return {
        updatePassword: (userId, password) => dispatch(updatePassword(userId, password)),
        closeMsg: () => dispatch(closeUpdatePasswordMsgAction()),
    };
};
const mapStateToProps = state => ({
    logindetails: state.Login.logindetails,
    login: state.Login.login,
    isSendMail: state.ResetPassword.VerifyOTP,
    // isChangePassword: state.isChangePassword,
    // isSendMail: state.isSendMail,
    // isVerifyOTP: state.isVerifyOTP,
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ PostSendOtp, setNewPassword }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ChangePassword))
