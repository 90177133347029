import React, { Component } from "react";
import colors from '../../utils/colors/colors';
import { connect } from 'react-redux';
import Menu from '../../components/menu/menu';
import { withStyles } from '@material-ui/core/styles';
import Mutual from '../../utils/assets/mutual.png';
import Stock from '../../utils/assets/equity.png';
import Stockgray from '../../utils/assets/equitygray.png';
import Insurance from '../../utils/assets/insurance.png';
import InsuranceGray from '../../utils/assets/images/insurancegray.png';
import Gold from '../../utils/assets/gold.png';
import Fixed from '../../utils/assets/fixed.png';
import FixedGray from '../../utils/assets/fixedGrayTransact.png';
import NPS from '../../utils/assets/NPS.png';
import NPSGray from '../../utils/assets/NPSGray.png';
import NCD from '../../utils/assets/NCD.png';
import NCDGray from '../../utils/assets/NCDGray.png';
import Bonds from '../../utils/assets/bonds.png';
import BondsGray from '../../utils/assets/bondsGray.png';
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import Button from '../../components/custombutton/button';
import CommonCard from '../../components/tracking_card/cards';
import { InputAdornment } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import SearchIcon from "@material-ui/icons/Search";
// import Button from '../../components/image_button/image_button'
import { scrollToTopPage } from '../../utils/commonfunction';
const styles = theme => ({
    root: {
        width: "100%",
        // height: '100%',
    },
    container: {
        display: 'grid',
        gridTemplateColumns: 'repeat(4,25%)',
        columnGap: '7px',
        margin: '2% 11% 2% 10%',
        [theme.breakpoints.down('sm')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3,33%)',
            margin: '2% 5% 2% 5%',
        },
        [theme.breakpoints.down('600')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(1,1fr)',
            margin: '0% 5% 0% 5%',
        },
        [theme.breakpoints.between('1000', '1300')]: {
            margin: '2% 11% 2% 10%',
        },
        [theme.breakpoints.between('300', '600')]: {
            marginTop: 10
        }
    },
    transact: {
        width: "100%",
        height: "21vh",
        marginTop: 60,
        backgroundColor: window.globalConfig.color.PRIMARY,
        [theme.breakpoints.between('1270', '1290')]: {
            height: "20vh",
        },
        // [theme.breakpoints.between('1000', '1250')]: {
        //     height: "16vh",
        // },

        [theme.breakpoints.between('300', '400')]: {
            marginTop: 30,
            // height: "36vh"
            height: '100%',
            paddingBottom: '30px',
        },
        [theme.breakpoints.between('400', '600')]: {
            marginTop: 30,
            height: "28vh"
        },
        [theme.breakpoints.between('600', '1000')]: {
            // marginTop: 46,
            // height: "28vh",
            // marginBottom: 30
            height: '25vh',
            marginTop: '46px',
            marginBottom: '0px',
        }
    },

    contentBlock: {
        lineHeight: '25px',
        paddingTop: 10,
        // [theme.breakpoints.between('lg')]: {
        //     lineHeight: '22px',
        //     paddingTop: 10,
        // },
        [theme.breakpoints.up('md')]: {
            lineHeight: '1.5em',
            paddingTop: 14,
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: "3%",
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: "5%",
        },
        // [theme.breakpoints.between('1270','1290')]: {
        //     paddingTop:30,
        // }
        // [theme.breakpoints.between('300', '600')]: {
        //     lineHeight: '1.5em',
        // },
        // [theme.breakpoints.between('320', '425')]: {
        //     lineHeight: '1.5em',
        //     paddingTop:"12%"
        // }
    },
    searchBox: {
      //  margin: '-25px 0px 0px 0px',
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      marginTop:"-2%"
        // margin: '-23px 0px 0px 0px',
        // [theme.breakpoints.down('sm')]: {
        //     margin: '-23px 0px 16px 0px',
        // },
        // [theme.breakpoints.down('sm')]: {
        //     margin: '-23px 0px 0px 0px',
        // },
        // [theme.breakpoints.between('1270', '1290')]: {
        //     // marginTop: 5
        //     margin: '-23px 0px 0px 0px',
        // }
    },
    topContent: {
        fontSize: 25,
        color: window.globalConfig.color.WHITE,
        margin: '0px 0px 5px 0px',
        // fontWeight: 400,
        [theme.breakpoints.between('300', '600')]: {
            fontSize: '1.3em',
        }
    },
    subContent: {
        fontSize: '1em',
        color: window.globalConfig.color.WHITE,
        margin: 0,
    },
    textField: {
        width: "32%",
        backgroundColor: window.globalConfig.color.WHITE,
        boxShadow: "rgb(19 89 161 / 10%) 0px 0px 20px 0px",
        caretColor: window.globalConfig.color.BROWN,
        padding: 10, borderRadius: 8,
        [theme.breakpoints.between('1250', '1300')]: {
            width: "35%",
        },
        [theme.breakpoints.between('300', '600')]: {
            width: "85%",
            padding: 5,
        }
    },
    endText: {
        color: window.globalConfig.color.BROWN, width: "70%",
        marginTop: 12
    },
    btnDesign: {
        width: "5%",
        [theme.breakpoints.down('sm')]: {
            width: "8%",
        },
        [theme.breakpoints.between('300', '600')]: {
            width: "15%",
        },
    },
    customizedButton: {
        color: window.globalConfig.color.LABLE_GRAY,
    },
    buttonContainer: {
        width: "100%", margin: '1% 10.5% 3% 10.5%', display: 'flex',
        [theme.breakpoints.down('600')]: {
            margin: '1% 10.5% 10% 10.5%'
        },
    },
    h1FontColor: {
        color: window.globalConfig.color.WHITE,
        fontSize: '25px',
        margin: '0px 0px 10px 0px',
        [theme.breakpoints.down('600')]: {
            fontSize: '23px',
        },
    },
    trackerHeader: {
        color: window.globalConfig.color.WHITE,
        textAlign: "center",
        width: '60%',
        marginLeft: 'auto',
        marginRight: 'auto',
        [theme.breakpoints.down('800')]: {
            width: '80%',
        },
    },
    h2FontSize: {
        fontSize: '1em !important',
        color: window.globalConfig.color.WHITE,
    },
    subHeaders: {
        backgroundColor:window.globalConfig.color.PRIMARY,
        color: window.globalConfig.color.WHITE,
        fontSize: '1em !important',
        textAlign: "center",
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: '1.5%',
        paddingBottom: '2%',
        [theme.breakpoints.down('md')]: {
            paddingTop: '7%',
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: '10%',
            paddingBottom: '3%',
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: '20%',
            paddingBottom: '3%',
        },
    },
}
)
class Transact extends Component {
    constructor() {
        super();
        this.state = {

        }
    }

    componentDidMount() {
        document.title = window.globalConfig.companyDetails.companyName +" - Transact"
        scrollToTopPage();
    }

    handlePortfolioRedirection = () => {
        this.props.history.push(labels.Path.Scheme)
    }
    handleequityRedirection = () => {
        if (this.props.logindetails.EntityTypeID != 9) {
            this.props.history.push(labels.Path.Stocks)
        }
    }
    handleFixedDeposits = () => {
        if (this.props.logindetails.EntityTypeID != 9) {
            this.props.history.push(labels.Path.FdDebentures)
        }
    }
    handleDebenture = () => {
        if (this.props.logindetails.EntityTypeID != 9) {
            this.props.history.push(labels.Path.Debentures)
        }
    }
    render() {
        const { classes } = this.props;
        try {
            return (
                <div className={classes.root}>
                    <div className={classes.subHeaders}>
                        <h1 className={classes.h1FontColor}>{labels.Transact.finvat}</h1>
                        <div className={classes.trackerHeader}>
                            {/* <Typography className={classes.h2FontSize}> */}
                            <p>{labels.Transact.subContent}</p>
                            <p>{labels.Transact.nextSubContent}</p>
                            {/* </Typography> */}
                        </div>
                    </div>

                    <div style={{ cursor: "pointer" }} className={classes.searchBox}>
                        <TextField
                            placeholder={labels.Transact.searchPlaceholder}
                            className={classes.textField}
                            size="small"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment className={classes.customizedButton} position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                                disableUnderline: true,
                            }}
                        />
                    </div>
                    <div className={classes.container}>
                        <CommonCard title="cards" src={Mutual} label={labels.productList.MutualFunds} onClick={this.handlePortfolioRedirection} cursor={"pointer"} imgWidth={"36%"} disabled={false}>
                        </CommonCard>
                        <CommonCard title="cards" src={Stockgray} label={labels.productList.Equity} imgWidth={"36%"} onClick={this.handleequityRedirection} disabled={true}>
                        </CommonCard>
                        <CommonCard title="cards" src={FixedGray} label={labels.productList.Fixed} imgWidth={"36%"} onClick={this.handleFixedDeposits} disabled={true}>

                        </CommonCard>
                        <CommonCard title="cards" src={NCDGray} label={labels.productList.NCD} imgWidth={"36%"} onClick={this.handleDebenture} disabled={true}>

                        </CommonCard>


                        <CommonCard title="cards" src={InsuranceGray} label={labels.productList.Insurance} imgWidth={"35%"} disabled={true}>
                        </CommonCard>
                        <CommonCard title="cards" src={Gold} label={labels.productList.Gold} imgWidth={"35%"} disabled={true}>

                        </CommonCard>

                        <CommonCard title="cards" src={NPSGray} label={labels.productList.NPS} imgWidth={"36%"} disabled={true}>

                        </CommonCard>

                        <CommonCard title="cards" src={BondsGray} label={labels.productList.TradableBonds} imgWidth={"36%"} disabled={true}>

                        </CommonCard>
                    </div>
                    <div className={classes.buttonContainer} >
                        <div className={classes.btnDesign}>
                            <Button page={"primary"} name={labels.Transact.faqs}></Button>
                        </div>
                        <div className={classes.endText}>
                            <span>{labels.Transact.endContent}</span>
                        </div>
                    </div>
                </div>
            )
        }
        catch (err) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapDispatchToProps = dispatch => ({

});
const mapStateToProps = state => ({
    sessionId: state.Login.sessionID,
    clientId: state.Login.logindetails.ClientID,
    date: state.Login.date,
    logindetails: state.Login.logindetails,
});
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Transact));