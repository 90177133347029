//Added by Celsia on 29-Jun-2021 for New Development
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import color from "../../utils/colors/colors";
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import { Grid } from '@material-ui/core';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle'
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import MyFamilyDetailsCard from '../../components/profile/myfamilydetailscard';
import Label from '../../utils/constants/labels';
import labels from '../../utils/constants/labels';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import { connect } from 'react-redux';
import Color from '../../utils/colors/colors';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router';
import InformationTooltip from '../tooltip/informationtooltip';
import colors from '../../utils/colors/colors';
const styles = theme => ({
  // container: {
  //   height: "auto",
  //   maxHeight: "auto",
  //   color: color.PRIMARY_BLUE,
  // },
  profileCard: {
    height: 70,
    marginTop: '2.6%',
    padding: '3.6%',
    padding: '1% 0% 3.6% 0%',
    backgroundColor: color.PRIMARY, color: "red", display: "flex", flexDirection: "column",
    [theme.breakpoints.down('sm')]: {
      paddingTop: "6.5%",
      paddingLeft: '3.5%',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: "10.5%",
      paddingLeft: '5.5%',
      height: 'auto',
    },
  },
  userNameDiv: {
    paddingLeft: "7.5%",
    textAlign: "left",
    marginTop: "4%",
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },

  profileTitle: {
    paddingLeft: '7.5%',
    textAlign: "left",
    fontSize: 25,
    fontWeight: "bolder",
    color: color.WHITE,
    marginTop: "0.6%",
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },
  },
  divDetail: {
    height: '-webkit-fill-available',
    backgroundColor: color.WHITE,
    border: `1px solid ${color.WHITE}`,
    // boxShadow: "0 0 5px 8px rgba(200, 200, 250, 0.1)",
    paddingBottom: "30px",
    borderRadius: 6,
    padding: "20px",
    paddingTop: 0,
    paddingLeft: 0,
    // marginTop: "4vh",
    //  marginLeft: "13vh",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "0px",
      padding: '0px',
      justifyContent: 'center',
      // marginBottom: "20vh"

    }
  },
  mainText: {
    fontSize: "18px",
    textAlign: "left",
    fontWeight: 500,
    color: color.DARK_LABLE
  },
  subText: {
    fontSize: "15px",
    fontWeight: 500
  },
  left: {
    textAlign: "left",
    [theme.breakpoints.between('320', '374')]: {
      marginLeft: "1%",
    },
    [theme.breakpoints.between('375', '424')]: {
      marginLeft: "1%",
    },
    [theme.breakpoints.between('425', '600')]: {
      marginLeft: "1%",
    },
  },
  detailsContainer: {
    width: "40%",
    color: color.SECONDARY_BLUE,
    fontSize: "14px",
    fontWeight: 500,
    borderCollapse: "collapse",
    marginTop: "2em",
    // marginBottom: "15%",
    borderRadius: "10px 10px 10px 10px",
    boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
    padding: "20px",
    margin: 10,
    textAlign: "left"
  },
  image: {
    marginTop: 5,
    paddingLeft: 10
  },
  flex: {
    flexDirection: 'row',
    display: "flex"
  },
  width: {
    flexDirection: 'row',
    display: "flex",
    width: "100%"
  },
  textFlex: {
    marginTop: 10,
    flexDirection: 'row',
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "space-between",
    }
  },
  padding: {
    padding: 10,
    // paddingLeft: "0px"
  },
  paddingTop: {
    paddingTop: "10px"
  },
  paddingLeft: {
    paddingLeft: "20px"
  },
  clearIcon: {
    float: 'right',
    cursor: 'pointer'
  },
  txtHeader: {
    padding: 15,
  },
  modelBox: {
    width: 200,
    padding: 30,
    height: 'auto',
    borderRadius: 10
  },
  header: {
    backgroundColor: color.PRIMARY,
    color: "white",
    height: 50,
    fontWeight: 500
  },
  popupcard: {
    width: "100%",
    height: "100%"
  },
  dropDownPopup:
  {
    width: '50%',
    display: "flex", flexDirection: "column", marginRight: "4%",
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  containerGrid: {
    margin: "2vh",
    width: "70px",
    [theme.breakpoints.down('xs')]: {
      width: 'unset',
    },
  },
  container: {
    // margin: "2vh",
    marginTop: 20,
    //height:'100vh',
    [theme.breakpoints.down('xs')]: {
      display: "grid",
    },

  },
  buttonDivNoIFSC: {
    float: 'right',
    // marginRight: '6%',
    marginTop: "13vh",
    [theme.breakpoints.down('xs')]: {
      float: 'none',
      margin: "10px 0px",
      padding: "20px 0px",
      [theme.breakpoints.between('500', '600')]: {
        padding: "10px 0px",
      },
    },
  },
  desktopResponsive: {
    display: 'block !important',
    [theme.breakpoints.down("600")]: {
      display: 'none !important',
    },
  },
  mobileResponsive: {
    display: 'none !important',
    [theme.breakpoints.down("600")]: {
      display: 'block!important',
      // width: "100%",
      // marginLeft: 15
    },
  },
  popupFooterDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    [theme.breakpoints.down('xs')]: {
      justifyContent: "space-between"
    },
  },
  padding: {
    padding: "0px 10px"
  },
  imageContainer: {
    width: 100,
    height: 100,
    justifyContent: 'center',
    veriticalAlign: 'middle',
  },
  popupContainer: {
    width: 320,
    height: '100%',
    overflow: 'hidden',
    justifyContent: 'center',
    veriticalAlign: 'middle',
  },
  dialogPopup: {
    backgroundColor: 'white',
    overflow: 'hidden',
    "& .MuiDialog-paperWidthXs": {
      maxWidth: "320px !important",
      width: "320px",
    },
  },
  popupWidth: {
    width: '320px',
  },
  popupheader: {
    backgroundColor: color.PRIMARY,
    color: "white",
    height: 50,
    fontWeight: 500
  },
  paddingcontainer: {
    padding: '10px 15px 10px 15px',
    [theme.breakpoints.down('380px')]: {
      // padding: '10px 30px 10px 15px !important',
      margin: '10px 15px 10px 15px !mportant',
    },
  },
  textBoxWidth: {
    // minWidth: 272,
    // width:'100%',
    width: 272,
    position: 'relative',
    padding: '5px 0px',
    [theme.breakpoints.down(350)]: {
      width: 218,
    },
  },
  dropDownWidth: {
    width: 286,
    position: 'relative',
    padding: '5px 0px',
    [theme.breakpoints.down(350)]: {
      width: 218,
    },
  },
  riskHeader: {
    textAlign: "left",
    // paddingBottom: "12px",
    margin: "0px",

    fontSize: "21px",
    color: colors.BLACK_PRIMARY,
    fontWeight: "bolder",
    paddingTop: "7px"
  },
  iconDesign: {
    marginLeft: 10,
    cursor: 'pointer'
  },
})
class MyFamily extends React.Component {
  isComponentMounted = false
  constructor(props) {
    super(props)
    this.state = {
      openPopup: false,
      name: "",
      dob: "",
      open: false,
    }
  }
  componentDidMount() {
    window.scrollTo(0, 0)

    document.title = window.globalConfig.companyDetails.companyName + " - MyFamily"
    this.isComponentMounted = true
  }
  componentWillUnmount() {
    this.isComponentMounted = false
  }
  handleClickOpen = () => {
    this.setState({
      open: true,
    })
  };
  handleClickclose = () => {
    this.setState({
      open: false,
    })
  };
  handlePopupClose = () => {
    this.setState({ openPopup: false })
  }

  render() {
    const { classes } = this.props

    try {
      return (<>
        <div className={classes.container}>
          <ErrorBoundary>
            <Grid container className={classes.divDetail}>
              <Grid item xs={12} className={[classes.flex, classes.padding].join(' ')} style={{ paddingLeft: 0 }}>
                <div style={{ display: 'flex' }}>
                  <div>
                    <span className={classes.riskHeader}>{Label.myFamilyDetails.MyFamily}</span>
                  </div>
                  <div>
                    <InformationTooltip title={Label.myFamilyDetails.MyFamily}></InformationTooltip>
                    {/* <span className={classes.iconDesign}><InfoSharpIcon color="disabled" fontSize="small" /></span><br /> */}
                  </div>
                </div>
              </Grid>
              {this.props.familyDetails.length != 0 &&
                <Grid item xs={12} style={{ paddingLeft: 0 }} className={[classes.leftResponsive, classes.textFlex, classes.padding].join(' ')} >
                  {this.props.familyDetails.length > 1 ?
                    <>
                      <Grid className={classes.paddingTop}>
                        <span className={classes.subText}>{this.props.familyDetails.length} Members</span>
                      </Grid>
                    </> :
                    <>
                      <Grid className={classes.paddingTop}>
                        <span className={classes.subText}>{this.props.familyDetails.length} Member</span>
                      </Grid>
                    </>
                  }
                </Grid>}
              <div>
                <MyFamilyDetailsCard />
              </div>

            </Grid>
          </ErrorBoundary>
        </div>
      </>
      )
    } catch (err) {
      return <Redirect to={labels.Path.NotFound} />
    }
  }
}
const mergedStyle = combineStyles(styles, CommonTableStyles)
const mapStateToProps = state => ({
  login: state.Login.loginError,
  userDetails: state.ProfileDetails.AllProfileDetails.length == 0 ? [] : state.ProfileDetails.AllProfileDetails.mainDetails != "NA" ? state.ProfileDetails.AllProfileDetails.mainDetails : [],
  familyDetails: state.Login.familydetails != "NA" && state.Login.familydetails.length == 0 ? [] : state.Login.familydetails != "NA" ? state.Login.familydetails : [],
})
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(MyFamily))