import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
import { Asset } from '../../utils/mainasset';
function ResendOTP(EmailID) {

    let data = {};
    data.EmailID = EmailID;
    data.Source=Asset.source
    return Networking.PostApi(ApiValues.ReSendOtpPassword,data,labels.apiKey.apiKeys,labels.apiKey.apiKeys,true).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
    
    // var config = {
    //   method: 'post',
    //   url:ApiValues.ReSendOtpPassword,
    //   headers: { 
    //     'Content-Type': 'application/json'
    //   },
    //   data : data
    // };
    
// return    axios(config)
//     .then(function (response) {
//     return JSON.stringify(response.data);
//     })
//     .catch(function (error) {
//       return { "status": "F", "message": labels.messages.apierror }
//     });
    

}
export default ResendOTP
