import React, { Component } from 'react'
import { Card } from "@material-ui/core";
import { Checkbox } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { height, width } from '@material-ui/system';
import { Grid } from "@material-ui/core";
import InformationTooltip from '../tooltip/informationtooltip'
import labels from '../../utils/constants/labels';
import { withRouter } from 'react-router-dom';
const styles = theme => ({
    cardDesign: {
        paddingLeft: '4%',
        paddingRight: '4%',
    },
    root: {
        // minWidth: 275,
        border: "1px solid rgba(199, 199, 199, 0.3)",
        // padding: "10px",
        boxShadow: "rgba(19, 89, 161, 0.1) 0px 0px 20px 0px",
        display: "flex",
        flexDirection: "row",
        gap: 21,
        padding: "18px 10px",
        borderRadius: 10,
        justifyContent: "space-evenly",
        alignItems: "center"
        //boxShadow: "5px 10px red"
    },
    card: {
        // boxShadow: '0 4px 8px rgba(255, 0, 0, 0.5)', // Adjust these values as needed  
    },
    CardLine: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: 70,
        // padding: '10px 20px',
        borderRight: '1px solid rgb(230, 231, 234)'
    },
    checkBox: {
        width: '11px',
        height: '11px',
        display: 'flex',
        padding: '2px',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        border: '1px solid rgba(234, 236, 240, 0.8)',
        backgroundColor: 'rgba(234, 236, 240, 0.8)',
        // marginTop: "17%"
    },
    CardAlign: {
        display: 'flex',
        // paddingLeft: '8px',
        // textAlign: 'left',
        // marginTop: "14%"
    },
    labelOne: {
        fontSize: "14px"
    },
    labelTwo: {
        fontSize: "medium"
    },
    CardDiv: {
        // marginLeft: "3%"
        display: "flex",
        flexDirection: "column",
        gap: 10,
        cursor:"pointer"
    },
    CardDiv1: {
        display: "flex",
        flexDirection: "column",
        gap: 10,
        cursor: 'default',
    },
    CardHeader: {
        display: "flex",
        flexDirection: "row",
        gap: 5
    },
    InvestmentAmount: {
        display: "flex",
        flexDirection: 'row-reverse',
        fontSize: 'larger',
        fontFamily: 'PlusJakartaSansBold',
        textAlign: "right"
    },
    CommonAmount: {
        // display: "flex",
        textAlign: "right"
    }
})
class Cardportfolio extends React.Component {
    constructor() {
        super();
        this.state = {

        };
    }
    handleCardClick = (item, index) => {
        const redirectPaths = [{ name: 'Life Insurance', path: labels.Path.InsuranceTab },
        { name: 'Real Estate', path: labels.Path.RealEstateTab },
        { name: 'Liqui Loan', path: labels.Path.LiquiloansTab },
        { name: 'NPS', path: labels.Path.NPSTab },
        { name: 'EPFO', path: labels.Path.EPFTab },
        { name: 'MLD', path: labels.Path.MLDTab }
        ]
        let searchItem = [item];
        // console.log(searchItem)
        if (index != 0) {
            searchItem.map((item) => {
                redirectPaths.map((value) => {
                    // console.log(item,item.label.includes(value.name) )

                    if (item.label.includes(value.name) > 0) {
                        this.props.history.push(value.path)
                    }
                })
            })
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {
                    this.props.data != "NA" && this.props.data.map((item, index) => {
                        return <>

                            <div onClick={() => { this.handleCardClick(item, index) }}
                                className={[classes.CardDiv, classes[`CardDiv${index + 1}`]].join(" ")}>
                                <div className={classes.CardHeader}>
                                    {index != 0 && <span className={classes.checkBox}></span>}
                                    <span className={[classes.CardAlign, index == 0 ? classes.labelTwo : classes.labelOne].join(" ")}>{item.label}</span>
                                </div>
                                <div className={index == 0 ? classes.InvestmentAmount : classes.CommonAmount}>{item.value}</div>
                            </div>
                            {index == 0 && <span className={classes.CardLine}></span>}
                        </>
                    })
                }
            </div>
        )
    }
}
export default withRouter(withStyles(styles)(Cardportfolio));
