import ApiValues from '../url/apiurl'
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
import { NegativeFinding, replaceNull } from '../../utils/commonfunction';
function DeleteCart(ID, ClientID, SessionID, CartIDPipeSeprated, IsOrderPlaced, FolioList) {
  let BaseUrl = ApiValues.DeleteCart
  let data = {};
  data.ID = ID,
    data.ClientID = ClientID,
    data.SessionID = SessionID,
    data.CartIDPipeSeprated = CartIDPipeSeprated,
    data.IsOrderPlaced = IsOrderPlaced,
    data.FolioList = replaceNull(FolioList, "")
  // return fetch(BaseUrl, {
  //     method: 'POST',
  //     headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(data),
  // })
  //     .then((response) => response.json())
  //     //If response is in json then in success
  //     .then((responseJson) => {
  //         //  //  ("dropdown",responseJson)
  //         return responseJson

  //     })
  //     //If response is not in json then in error
  //     .catch((error) => {
  //         //Error 
  //         return { "status": "F", "message": labels.messages.apierror }

  //     });
  return Networking.PostApi(BaseUrl, data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default DeleteCart
