import * as types from "../../constants/actionsType";
import { bindActionCreators } from "redux";
import { FormatColorReset } from "@material-ui/icons";
import { replaceNull } from "../../../utils/commonfunction";
import labels from "../../../utils/constants/labels";
const initialState = {
  brokerList: [],
  transactionTypeDropDown: [],
  AssetNameList: [],
  isLoading: false,
  isLoadingBroker: false,
  // FdrNumber: [],
  alternativeAsset: [],
  npsAndInsuranceDropdownList: []
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.EQUITY_BROKER:
      //
      return {
        ...state,
        brokerList:
          action.payload != null
            ? replaceNull(action.payload.data, labels.messages.NA)
            : state.brokerList,
      };

    case types.EMPTY_EQUITY_BROKER:
      return {
        ...state,
        brokerList: [],
      };
    case types.LOADER_EQUITY_BROKER:
      return {
        ...state,
        isLoadingBroker: action.payload,
      };
    case types.EMPTY_TRANSACTION_TYPE_DROPDOWN:
      return {
        ...state,
        transactionTypeDropDown: [],
        AssetNameList: [],
      };
    case types.LOADER_TRANSACTION_TYPE_DROPDOWN:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.GET_TRANSACTION_TYPE_DROPDOWN:
      return {
        ...state,
        transactionTypeDropDown: action.payload != null ? action.payload : state.transactionTypeDropDown,
      };

    //add asset name
    case types.GET_ASSET_NAME:
      var copyAssetList = state.alternativeAsset.table1;
      let CopyTransactionTypeDropDown = state.alternativeAsset;

      let newArray = {
        ID: action.AssetID,
        AssetName: action.AssetName,
        AssetClassCode: action.AssetClassCode,
      }
      CopyTransactionTypeDropDown.table1 = [...copyAssetList, newArray];

      return {
        ...state,
        alternativeAsset: replaceNull(CopyTransactionTypeDropDown, "NA")

      }

    //fdr number dropdown value reducer
    // case types.LOADER_FDR_NUMBER_DROPDOWN_LIST:
    //   return {
    //     ...state,
    //     isLoading: action.payload,
    //   };
    // case types.EMPTY_FIXED_DEPOSIT_FDR_NUMBER:
    //   return {
    //     ...state,
    //     FdrNumber: [],
    //   };
    // case types.FIXED_DEPOSIT_FDR_NUMBER:
    //   return {
    //     ...state,
    //     FdrNumber:action.payload != null ? action.payload.data.map((item)=>{
    //       return{
    //       value:item.ID,
    //       label:item.Text,
    //       }
    //     }) : state.FdrNumber
    //   };

    //Alternative dropdown value reducer
    case types.LOADER_ALTERNATIVE_LIST:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.EMPTY_ALTERNATIVE_ASSET:
      return {
        ...state,
        alternativeAsset: [],
      };
    case types.GET_ALTERNATIVE_LIST:
      return {
        ...state,
        alternativeAsset: action.payload != null ? action.payload : state.alternativeAsset

      };
    case types.LOADER_ALTERNATIVE_LIST:
      return {
        ...state,
        isLoading: action.payload,
      };
    case types.EMPTY_ALTERNATIVE_ASSET:
      return {
        ...state,
        alternativeAsset: [],
      };
    case types.GET_ALTERNATIVE_LIST:
      return {
        ...state,
        alternativeAsset: action.payload != null ? action.payload : state.alternativeAsset
      };
    case types.NPS_INSURANCE_DROPDOWN_LIST:
      return {
        ...state,
        npsAndInsuranceDropdownList: action.payload != null ? action.payload : state.npsAndInsuranceDropdownList
      }

    case types.UPDATE_ALTERNATIVE_LIST:
      let fullList = state.alternativeAsset
      let alternative_asset = state.alternativeAsset.table5
      fullList.table5 = alternative_asset == null || alternative_asset.length == 0 ? [...action.payload] : [...action.payload, ...alternative_asset]
      return {
        ...state,

        alternativeAsset: action.payload != null ? fullList : state.alternativeAsset
      };
    default:
      return state;
  }
};
export default reducer;
