import ApiValues from '../url/apiurl'
import labels from '../../utils/constants/labels';
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
function getPortfolioxraySchemeDetails(ClientID,AccountID,SchemeID,IsRedemption,TransactionType) {
   
    var data = {};
    data.ClientID=ClientID;
    data.AccountID=AccountID;
    data.SchemeID=SchemeID;
    data.IsRedemption=IsRedemption;
    data.TransactionType = TransactionType
   
    return Networking.PostApi(ApiValues.GetTradeDetailsForAction, data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
  //   var config = {
  //     method: 'post',
  //     url: ApiValues.GetTradeDetailsForAction,
  //     headers: { 
  //       'Content-Type': 'application/json'
  //     },
  //     data : data
  //   };
    
  // return  axios(config)
  //   .then(function (response) {
  //    return response.data
  //   })
  //   .catch(function (error) {
  //     return { "status": "F", "message": labels.messages.apierror }
  //   });

}
export default getPortfolioxraySchemeDetails
