import labels from '../../utils/constants/labels';
import apivalues from '../url/apiurl'
import Networking from '../../utils/api/apiaccess';
import secureStorage from '../../utils/securestorage';
function getOneTimeOrder(TransCode, OrderId, ClientCode, SchemeCd, SchemeID, BuySell, BuySellType, DPTxn, OrderVal, Qty, AllRedeem, FolioNo,
  Remarks, KYCStatus, RefNo, SubBrCode, EUIN, EUINVal, MinRedeem, DPC, IPAdd, Param1, Param2, Param3, ClientID, Source) {
  let BaseUrl = apivalues.OneTimeOrders;
  var IpAddress = secureStorage.getItem('ipaddress');
  let data = {};
  data.TransCode = TransCode,
    data.OrderId = OrderId == null ? 0 : OrderId,
    data.ClientCode = ClientCode,
    data.SchemeCd = SchemeCd,
    data.SchemeID = SchemeID,
    data.BuySell = BuySell,
    data.BuySellType = FolioNo != "" && FolioNo != undefined && FolioNo != "NA" && FolioNo.toLowerCase() != "new" ? "ADDITIONAL" : "FRESH",
    data.DPTxn = DPTxn,
    data.OrderVal = OrderVal,
    data.Qty = Qty,
    data.AllRedeem = AllRedeem,
    data.FolioNo = FolioNo != "" && FolioNo != undefined && FolioNo != "NA" && FolioNo.toLowerCase() != "new" ? FolioNo : "",
    data.Remarks = Remarks,
    data.KYCStatus = KYCStatus,
    data.RefNo = RefNo,
    data.SubBrCode = "",
    data.EUIN = EUIN,
    data.EUINVal = EUINVal,
    data.MinRedeem = MinRedeem,
    data.DPC = DPC,
    data.IPAdd = IpAddress,
    data.Param1 = Param1,
    data.Param2 = Param2,
    data.Param3 = Param3,
    data.ClientID = ClientID
  data.Source = Source;
  return Networking.PostApi(BaseUrl, data,labels.apiKey.apiKeys).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
}
export default getOneTimeOrder
