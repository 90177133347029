import React from "react";
import { withRouter } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { Avatar } from "@material-ui/core";
import { scrollToTopPage } from '../../utils/commonfunction';
import ScrollTop from '../../utils/assets/upArrow.png';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import { useEffect } from "react";
import { ArrowDownwardRounded, ArrowUpwardRounded } from "@material-ui/icons"
class ScrollToTopView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      opacity: 0,
      visibility: "hidden"
    }
    // this.handleScroll = this.handleScroll.bind(this);
    this._componentUpdate = false
  }
  componentDidUpdate(prevProps) {
    // if (
    //   this.props.location.pathname !== prevProps.location.pathname
    // ) {
    //   this._componentUpdate && scrollToTopPage();
    // }
  }

  componentDidMount() {
    this._componentUpdate = true
    scrollToTopPage()
    document.getElementById('scroller').addEventListener('scroll', () => { this.handleScroll() });
  }

  componentWillUnmount() {
    this._componentUpdate = false
    document.getElementById('scroller').removeEventListener('scroll', () => { this.handleScroll() });
  }

  handleScroll = () => {
    if (this._componentUpdate) {
      const scrolled = document.getElementById('scroller').scrollTop;
      // console.log(scrolled)
      if (scrolled > 50) {
        this.setState({ opacity: 2, visibility: "visible" })
      }
      else {
        this.setState({ opacity: 0, visibility: "hidden" })
      }
    }
    // console.log("hihih")

  }
  //  ScrollButton = () =>{

  //   const [visible, setVisible] = useState(false)

  //   const toggleVisible = () => {
  //     const scrolled = document.documentElement.scrollTop;
  //     if (scrolled > 300){
  //      this.setstate({visible:true})
  //     } 
  //     else if (scrolled <= 300){
  //       this.setState({visible:false})
  //     }
  //   };

  //   window.addEventListener('scroll',toggleVisible);
  // }
  onClick = () => {

    if (this.props.onClick != undefined) {
      this.props.onClick()
    }
    scrollToTopPage()
  }
  render() {
    // console.log(this.state.opacity, this.state.visibility)
    return (
      <div style={{
        position: "absolute",
        bottom: "25px",
        right: "20px",
        padding: "5px",
        width: "29px",
        backgroundColor: "rgb(189 189 189 / 35%)",
        borderRadius: "50%",
        opacity: this.state.opacity,
        visibility: this.state.visibility,
        cursor: "pointer"
      }}
        onClick={this.onClick}
      // onScroll={this.handleScroll}
      >
        <ArrowUpwardRoundedIcon />
      </div>
    )
  }
}
export default withRouter(ScrollToTopView);