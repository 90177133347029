import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
//action created by dulcy
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
function getAddCountry(TaxPayingCountryID, ClientID, Country, IdentificationType, TIN, L5ID) {


  var raw = JSON.stringify({
    "TaxPayingCountryID": TaxPayingCountryID,
    "ClientID": ClientID,
    "Country": Country,
    "L5ID": L5ID,
    "IdentificationType": IdentificationType,
    "TIN": TIN
  });
  // var config = {
  //     method: 'post',
  //     url: ApiValues.AddCountry,
  //     headers: {
  //         'Content-Type': 'application/json'
  //     },
  //     data: raw
  // };

  // return axios(config)
  //     .then(function (response) {
  //         return response.data
  //     })
  //     .catch(function (error) {
  //         return{status:"F",message:labels.messages.apierror}
  //     });
  return Networking.PostApi(ApiValues.AddCountry, raw).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default getAddCountry
