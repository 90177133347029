import * as types from '../../constants/actionsType'
import { bindActionCreators } from 'redux';
import { replaceNull } from '../../../utils/commonfunction';
import labels from '../../../utils/constants/labels';
const initialState = {
    runningSIP: [],
    totalrunningSIP: [],
    isRunningSipLoading: false,
    //EQUITY
    equity_runningSIP: [],
    equity_totalrunningSIP: [],
    equity_isRunningSipLoading: false,
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RUNNINGSIP:
            // 
            
            return {
                ...state,
                runningSIP: action.payload.dataTable != null ? replaceNull(action.payload.dataTable, labels.messages.NA).sort((a, b) => {
                    var textA = a.SchemeName.toUpperCase();
                    var textB = b.SchemeName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }) : state.runningSIP,
                totalrunningSIP: action.payload.totalTable != null ? replaceNull(action.payload.totalTable, labels.messages.NA) : state.totalrunningSIP
                , isRunningSipLoading: false
            }
        case types.EMPTY_RUNNINGSIP:
            return {
                ...state,
                runningSIP: [],
                totalrunningSIP: []
                , isRunningSipLoading: false
            }
        case types.LOADER_RUNNING_SIP:
            return {
                ...state,
                // runningSIP: [],
                // totalrunningSIP:[]
                isRunningSipLoading: action.payload
            }
        case types.EQUITY_RUNNING_SIP:
            // 
            return {
                ...state,
                equity_runningSIP: action.payload.dataTable != null ? replaceNull(action.payload.dataTable, labels.messages.NA).sort((a, b) => {
                    var textA = a.SchemeName.toUpperCase();
                    var textB = b.SchemeName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }) : state.runningSIP,
                equity_totalrunningSIP: action.payload.totalTable != null ? replaceNull(action.payload.totalTable, labels.messages.NA) : state.totalrunningSIP
                , equity_isRunningSipLoading: false
            }
        case types.EMPTY_EQUITY_RUNNING_SIP:
            return {
                ...state,
                equity_runningSIP: [],
                equity_totalrunningSIP: []
                , equity_isRunningSipLoading: false
            }
        case types.LOADER_EQUITY_RUNNING_SIP:
            return {
                ...state,
                // runningSIP: [],
                // totalrunningSIP:[]
                equity_isRunningSipLoading: action.payload
            }
        default:
            return state
    }
}
export default reducer
