import React from "react";
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle';
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import { withStyles } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Delete from '../../utils/assets/delete.png';
import Edit from '../../utils/assets/edit.png';
import NoData from "../nodata/nodata";
import labels from "../../utils/constants/labels";
import colors from '../../utils/colors/colors';
import Loader from "../loader/loader";
import ErrorBoundary from "../Errorhandling/Errorhandle";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { NegativeFinding } from "../../utils/commonfunction";
const styles = theme => ({
    cardStyle: {
        // display: "flex", flexDirection: "row", 
        // justifyContent: "space-between",
        alignItems: "center",
        paddingRight: 10,
        paddingLeft: 10, textAlign: "left !important",
        marginRight: "0px !important",

    },
    firstGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: 'myArea',
            width: "fit-content"
        }
    },
    iconShowMobile: {
        display: "none",
        cursor: "pointer",
        [theme.breakpoints.down("920")]: {
            display: "block"
        }
    },
    iconShowDesktop: {
        display: "block",
        cursor: "pointer",
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
    desktopResponsive: {
        [theme.breakpoints.down("920")]: {
            display: "none !important"
        }
    },

    NegativeColor: {
        color: window.globalConfig.color.red,
        fontWeight: 'bold',
    },
})
class DynamicList extends React.Component {
    constructor() {
        super();
        this.state = {
            anchorEl: null,
            selectedList: []
        }
    }
    handleClose = () => {
        this.setState({ anchorEl: null })
    }
    handleScroll = () => {
        this.setState({ anchorEl: null })

    }
    handleClick = (e, item) => {
        if (this.props.moreOptionCallBack != undefined) {
            this.props.moreOptionCallBack(item)

        }
        this.setState({ anchorEl: e.currentTarget, selectedList: item })
    }
    render() {
        const { classes, style } = this.props

        return (<ErrorBoundary>
            {this.props.header.length != 0 &&
                <div className={[classes.tableHeader, classes.cardStyle, this.props.grid, classes.desktopResponsive].join(" ")}>
                    {this.props.header.map((item) => {
                        return <div style={{ display: "flex", flexDirection: "column" }}> <div style={{ textAlign: item.align }}>{item.label}</div>
                            {item.subLabel != undefined && <div style={{ textAlign: item.align }}>{item.subLabel}</div>}
                        </div>
                    })}

                </div>}


            {this.props.loader ? <Loader page="reportmodel"></Loader> : this.props.list != undefined && this.props.list != "NA" && this.props.list.length != 0 ? this.props.list.map((item, mainindex) => {
                return <div className={[classes.subCategoryGroupContainers, classes.cardStyle, this.props.grid].join(" ")}  >
                    <>
                        {this.props.enableIcon &&
                            <MoreVertIcon className={classes.iconShowMobile} onClick={(e) => this.handleClick(e, item)}></MoreVertIcon>
                        }
                        {item.enableIcon == undefined ? "" : item.enableIcon.name ?
                            <MoreVertIcon className={classes.iconShowMobile} onClick={(e) => this.handleClick(e, item)}></MoreVertIcon> : <div className={[classes.NegativeColor, classes.iconShowMobile].join(" ")}>
                                {item.enableIcon.soldOut}
                            </div>}
                        {[...Array(this.props.count)].map((elementInArray, index) => {
                            let header = Object.keys(item)
                            let dynamicList = style != undefined ? style[`DynamicList${index}`] != undefined ? style[`DynamicList${index}`] : "" : ""
                            return (
                                <div className={index == 0 ? ["myArea", classes.firstGrid].join(" ") : ""} >
                                    <div className={[classes.iconShowMobile, dynamicList != undefined ? dynamicList : ""].join(" ")} style={{ fontSize: 14, minWidth: "max-content", color: colors.LABLE_GRAY, textAlign: this.props.header[index]?.align == undefined ? 'left' : this.props.header[index]?.align }}>{item[header[index]]?.label}</div>
                                    <div className={dynamicList != undefined ? dynamicList : ""} style={{ textAlign: this.props.header[index]?.align == undefined ? 'left' : this.props.header[index]?.align, overflowWrap: "break-word" }}>{item[header[index]]?.type == "amount" ? NegativeFinding(item[header[index]].name.toString().replace(/,/g, ""), "", "", "", "rupee") : item[header[index]].name}</div>
                                    <div style={item[header[index]].subHeader2 != undefined && item[header[index]].subHeader2 != "" && item[header[index]].subHeader2 != "NA" ? { display: "flex", flexDirection: "row", gap: 2 } : {}}>
                                        {item[header[index]].subHeader1 != "NA" &&
                                            <div style={item[header[index]].subHeaderType != undefined && item[header[index]].subHeaderType == 'link' ? { fontSize: 11, color: window.globalConfig.color.PRIMARY, textDecoration: "underline", cursor: "pointer", textAlign: this.props.header[index]?.align == undefined ? 'left' : this.props.header[index]?.align } : { fontSize: 12, textAlign: this.props.header[index]?.align == undefined ? 'left' : this.props.header[index]?.align }} onClick={() => {
                                                if (this.props.transactionListClick != undefined) {
                                                    this.props.transactionListClick(item)
                                                }
                                            }}>{item[header[index]].subHeaderType == 'amount' && labels.currency.rupeesymbol} {item[header[index]].subHeader1 == "" || item[header[index]].subHeader1 == "NA" ? "" : `${typeof (item[header[index]].subHeader1) == 'number' ? NegativeFinding(item[header[index]].subHeader1.toString().replace(/,/g, ""), "", "", "", "number") : item[header[index]].subHeader1}`}</div>
                                        }
                                        {item[header[index]].subHeader2 != undefined && item[header[index]].subHeader2 != "" && item[header[index]].subHeader2 != "NA" && <div>{`${" | "}`}</div>}
                                        {item[header[index]].subHeader2 != undefined && item[header[index]].subHeader2 != "" && item[header[index]].subHeader2 != "NA" &&
                                            <div style={item[header[index]].subHeader2Type != undefined && item[header[index]].subHeader2Type == 'link' ? { fontSize: 11, color: window.globalConfig.color.PRIMARY, textDecoration: "underline", cursor: "pointer", textAlign: this.props.header[index]?.align == undefined ? 'left' : this.props.header[index]?.align } : { fontSize: 12, textAlign: this.props.header[index]?.align == undefined ? 'left' : this.props.header[index]?.align }} onClick={() => {
                                                if (this.props.instalmentListClick != undefined) {
                                                    this.props.instalmentListClick(item)
                                                }
                                            }}>{item[header[index]].subHeader2Type == 'amount' && labels.currency.rupeesymbol} {item[header[index]].subHeader2 == "" || item[header[index]].subHeader2 == "NA" ? "" : `${typeof (item[header[index]].subHeader2) == 'number' ? NegativeFinding(item[header[index]].subHeader2.toString().replace(/,/g, ""), "", "", "", "number") : item[header[index]].subHeader2}`}</div>
                                        }
                                    </div>
                                </div>

                            )
                        })}

                        {item.enableIcon == undefined ? "" : item.enableIcon.name ?
                            <MoreVertIcon className={classes.iconShowDesktop} onClick={(e) => this.handleClick(e, item)}></MoreVertIcon> : <div className={classes.NegativeColor}>
                                {item.enableIcon.soldOut}
                            </div>}
                        {this.props.enableIcon &&
                            <MoreVertIcon className={classes.iconShowDesktop} onClick={(e) => this.handleClick(e, item)}></MoreVertIcon>
                        }
                    </>
                </div>
            }) : <div style={{ marginTop: 30, marginBottom: 30 }}><NoData /></div>
            }

            <Popover
                //    disableScrollLock={true}
                classes={classes.popperDisablePortal}
                open={Boolean(this.state.anchorEl)}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',

                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                onScroll={this.handleScroll}
            // disablePortal={true}
            >
                {this.props.popupList.map((item) => {
                    return <ListItem
                        button
                        className="popoverlist"
                        onClick={() => {
                            item.onClick(this.state.selectedList)
                            this.handleClose()
                        }}
                    >
                        <ListItemText primary={item.name} />
                    </ListItem>
                })}



            </Popover>


        </ErrorBoundary>
        )
    }
}
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles(mergedStyle)(DynamicList)

