import * as types from '../../constants/actionsType';
import addtocartApi from '../../../api/cart/addtocartapi'
export function AddToCart(ID, SchemeID, ClientID, Amount, AUM, Instalments, IsBSEStar, OneTimeAllowed, OneWeek, OneYear, Ranking, Risk,
    SipAllowed, SipFrequency, Sipdate, SipdateID, ThreeYear, ratings, AMCID, AssetClass, ISIN, RTAID, RTAProdCode, SchemeCode, ISFirstPayment, SessionID) {
  return (dispatch) => {
    addToCartAction(dispatch,ID, SchemeID, ClientID, Amount, AUM, Instalments, IsBSEStar, OneTimeAllowed, OneWeek, OneYear, Ranking, Risk,
        SipAllowed, SipFrequency, Sipdate, SipdateID, ThreeYear, ratings, AMCID, AssetClass, ISIN, RTAID, RTAProdCode, SchemeCode, ISFirstPayment, SessionID);
  }
}
function addToCartAction(dispatch,ID, SchemeID, ClientID, Amount, AUM, Instalments, IsBSEStar, OneTimeAllowed, OneWeek, OneYear, Ranking, Risk,
    SipAllowed, SipFrequency, Sipdate, SipdateID, ThreeYear, ratings, AMCID, AssetClass, ISIN, RTAID, RTAProdCode, SchemeCode, ISFirstPayment, SessionID) {
    addtocartApi(ID, SchemeID, ClientID, Amount, AUM, Instalments, IsBSEStar, OneTimeAllowed, OneWeek, OneYear, Ranking, Risk,
        SipAllowed, SipFrequency, Sipdate, SipdateID, ThreeYear, ratings, AMCID, AssetClass, ISIN, RTAID, RTAProdCode, SchemeCode, ISFirstPayment, SessionID)
    .then((data) => {
     //   (data)
      if (data != undefined) {
        if (data.status == "S") {
          dispatch({
            type: types.ADD_TO_CART,
            payload: data,
          });
        } else {
          dispatch({
            type: types.ADD_TO_CART,
            payload: [],
          });
        }
      }
    })
    .catch((error) => {

    })
}
