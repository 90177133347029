import React from 'react';
import { withStyles, } from '@material-ui/core/styles';
import colors from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Typography } from '@material-ui/core';
import Buttons from "../custombutton/button";
import getESignDownloadReportDetails from "../../api/esigndownloadapi/esigndownloadapi";
import { isSignedCheck } from '../../redux/actions/esigndownload/esigndownload'
import Popup from "./popup";
import encryptKYC from '../../api/profile/encryptionkyc';
import ErrorBoundary from '../Errorhandling/Errorhandle';
import CommonSnackBar from '../../components/snackbar/snackbar'
import apiurl from '../../api/url/apiurl';
import { scrollToTopPage } from '../../utils/commonfunction';
const styles = theme => ({

    gridContainerSecond: {
        display: "grid",
        gridTemplateColumns: '49%  49%',
        gap: 10,
        // marginRight: '5%',
        textAlign: 'left',
        [theme.breakpoints.down('xs')]: {
            display: "grid",
            gridTemplateColumns: '100%',
            marginLeft: '5%'
        },
    },
    mobileResponsive: {
        fontWeight: 'bold', color: window.globalConfig.color.black, fontSize: 18, marginTop: '1vh',
        [theme.breakpoints.down('xs')]: {
            display: "grid",
            gridTemplateColumns: '100%',
            textAlign: 'center',
        }
    },
    gridContainerThird: {
        border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        display: "grid",
        gridTemplateColumns: '60% 40%',
        // marginRight: '5%',
        textAlign: 'left',
        backgroundColor: 'white',
        height: 60,
        // width: 300,
        borderRadius: 4,
        [theme.breakpoints.between('950', '1090')]: {
            display: "grid",
            height: 60,
            width: 280,
            borderRadius: 4,
        }
    },
    gridContainerFour: {
        border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        display: "grid",
        gridTemplateColumns: '100%',
        // marginRight: '5%',
        textAlign: 'left',
        backgroundColor: 'white',
        height: 60,
        // width: 300,
        borderRadius: 4,
        //1024 px Responsive
        [theme.breakpoints.between('950', '1090')]: {
            display: "grid",
            height: 60,
            width: 275,
            borderRadius: 4,
        }
    },
    headerText: {
        fontSize: 14,
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontWeight: 'bold',
        [theme.breakpoints.down('xs')]: {
            display: "grid",
            gridTemplateColumns: '100%',
            marginTop: '2vh'
        },
    },
    kycText: {
        fontSize: 10,
        paddingTop: 8,
        paddingLeft: 8
    },
    verifiedText: {
        fontSize: 14,
        paddingTop: 8,
        paddingLeft: 8,
        color: window.globalConfig.color.InkBlack,
        fontWeight: 'bold'
    },


})
class AdvisoryAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            questionPopUp: false,
            AssessButtonVisible: true,
            loader: false, isSigned: false,
            riskfailureloader: false,
            openToast: false,
            message: '',
            severity: '',
            open: false

        }
    }
    componentDidMount() {
        scrollToTopPage();
        if (this.props.location.state.data != undefined && this.props.location.state.data != "" && this.props.formNo != "" && this.props.formNo != 0) {
            // 
            getESignDownloadReportDetails(this.props.logindetails.ClientID, 0).then((data) => {

                if (data != undefined) {
                    //    
                    if (data.data.status == "S") {
                        var ext = "pdf"
                        if (data.data.data != undefined && data.data.data != null && data.data.data != "") {
                            this.setState({ isSigned: true })
                            this.props.isSignedCheck(true, data.data.data)
                            this.props.visible("true")
                        }


                    }
                    else if (data.data.status == "F") {
                        this.setState({ isSigned: false })
                        this.props.isSignedCheck(false)
                        this.props.visible()
                        //
                        //  dispatch({
                        //      type: types.ESIGN_DOWNLOAD,
                        //      payload: data.data,
                        //  });
                    }
                }
                else {
                    this.setState({ isSigned: false })
                    this.props.isSignedCheck(false)

                }
            })
                .catch((error) => {
                    this.setState({ isSigned: false })
                    this.props.isSignedCheck(false)
                })
        }
    }

    componentDidUpdate(prevProps) {

    }


    assessButton = () => {
        this.setState({
            questionPopUp: true
        })

    }

    count = (value) => {
        if (value != undefined) {
            // this props value is pass to the stepperadvisory.js page
            this.props.count("14")
        }
    }
    onsubmitClick = (data) => {

        if (data.status_type?.toLowerCase() == "success") {
            this.setState({
                openToast: true,
                message: data.status_message,
                severity: 'success'
            })
        } else {
            this.setState({
                openToast: true,
                message: data.status_message,
                severity: 'error'
            })
        }
    }
    verifyKyc = () => {
        this.setState({ loader: true })
        encryptKYC(this.props.logindetails.EmailID, this.props.logindetails.PhoneNumber).then((res) => {
            this.setState({ loader: false, })
            if (res.status === "F") {
                this.setState({ open: true, message: labels.messages.apierror })
            } else {
                window.open(`${apiurl.KYCDomain}${"/MFSelfKYC/ClientSignUp?x1="}${res.encryptedEmail}&x2=${res.encryptedMobile}`)

            }

        })
        //      var a= KYCRedirection(this.props.logindetails.EmailID,this.props.logindetails.PhoneNumber)
    }

    closePopup = () => {
        this.setState({
            questionPopUp: false
        })
    }
    closeToast = () => {
        this.setState({
            openToast: false
        })
    }
    render() {
        try {
            var RiskDescription = '';
            if (this.props.logindetails != undefined && this.props.logindetails != null && this.props.logindetails != "") {
                RiskDescription = this.props.logindetails.RiskDescription
            }
            if (this.props.riskAnswers.response_data != undefined && this.props.riskAnswers.response_data != null && this.props.riskAnswers.response_data != "") {
                RiskDescription = this.props.riskAnswers.response_data.risk_description
            }

            const { classes } = this.props;
            // 
            return (
                <ErrorBoundary>
                    <CommonSnackBar open={this.state.openToast} handlesnanckClose={this.closeToast} severity={this.state.severity} message={this.state.message} />

                    <div style={{ minHeight: '0vh' }}>
                        <div style={{ width: '100%' }} >
                            <div>
                                <Typography variant="h4" className={classes.mobileResponsive} align={'left'}>{labels.stepper.advisoryAccountDetails}</Typography>

                                <div className={classes.gridContainerSecond} style={{ marginTop: "5%" }} >
                                    <div>
                                        <div className={classes.headerText}>{labels.stepper.PrimaryHolder}</div>

                                        <div style={{ marginLeft: '0%', marginTop: '5%', }} className={classes.gridContainerFour} >
                                            <div >
                                                <div className={classes.kycText}>{labels.stepper.FullName}</div>
                                                <div className={classes.verifiedText}>
                                                    {this.props.mainDetails != null && this.props.mainDetails != undefined && this.props.mainDetails != '' ?
                                                        <>
                                                            {this.props.mainDetails[0].ClientName != null && this.props.mainDetails[0].ClientName != undefined && this.props.mainDetails[0].ClientName != '' ? this.props.mainDetails[0].ClientName : labels.messages.NA}
                                                        </>
                                                        :
                                                        <>{labels.messages.NA}</>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginLeft: '0%', marginTop: '6%', }} className={classes.gridContainerFour} >
                                            <div >
                                                <div className={classes.kycText}>{labels.stepper.EmailID}</div>
                                                <div className={classes.verifiedText}>
                                                    {this.props.mainDetails != null && this.props.mainDetails != undefined && this.props.mainDetails != '' ?
                                                        <>
                                                            {this.props.mainDetails[0].EmailID != null && this.props.mainDetails[0].EmailID != undefined && this.props.mainDetails[0].EmailID != "" ? this.props.mainDetails[0].EmailID : labels.messages.NA}
                                                        </>
                                                        :
                                                        <>{labels.messages.NA}</>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ marginLeft: '0%', marginTop: '6%', }} className={classes.gridContainerFour} >
                                            <div >
                                                <div className={classes.kycText}>{labels.stepper.Mobile}</div>
                                                <div className={classes.verifiedText}>
                                                    {this.props.mainDetails != null && this.props.mainDetails != undefined && this.props.mainDetails != '' ?
                                                        <>
                                                            {this.props.mainDetails[0].MobileNo != null && this.props.mainDetails[0].MobileNo != undefined && this.props.mainDetails[0].MobileNo != "" ? this.props.mainDetails[0].MobileNo : labels.messages.NA}
                                                        </>
                                                        :
                                                        <>{labels.messages.NA}</>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div>
                                        <div className={classes.headerText}>{labels.stepper.PreliminaryCheck}</div>
                                        <div style={{ marginLeft: '0%', marginTop: '5%', }} className={classes.gridContainerThird} >
                                            <div >
                                                <div className={classes.kycText}>{labels.stepper.KYCSTATUS}</div>
                                                {this.props.logindetails.KYCValid ?
                                                    <div className={classes.verifiedText}>{labels.stepper.Verified}</div>
                                                    :
                                                    <div className={classes.verifiedText}>{labels.stepper.notVerified}</div>
                                                }
                                            </div>

                                        </div>


                                        <div style={{ marginLeft: '0%', marginTop: '6%', }} className={classes.gridContainerThird} >
                                            <div >
                                                <div className={classes.kycText}>{labels.stepper.RISKPROFILE}</div>
                                                {this.props.logindetails.RiskDescription == "" && this.props.riskAnswers == "" ?
                                                    <div className={classes.verifiedText}>{labels.stepper.NOTCOMPLETED}</div>
                                                    :
                                                    <div className={classes.verifiedText}>
                                                        {RiskDescription != undefined && RiskDescription != null && RiskDescription != "" ? RiskDescription : 'NA'}
                                                    </div>
                                                }
                                            </div>
                                            <div style={{ marginTop: 8, marginLeft: 10 }} >
                                                {this.props.logindetails.RiskDescription == "NA" && this.props.riskAnswers == "" ?
                                                    <div style={{ width: 120, }} >
                                                        <Buttons onClick={this.assessButton} page="primary" name={labels.stepper.AssessNow} />
                                                    </div>
                                                    :
                                                    <div style={{ width: 120, }} >
                                                        <Buttons page="secondary" name={labels.stepper.AssessNow} disabled={true} />
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                        <div style={{ marginLeft: '0%', marginTop: '6%', }} className={classes.gridContainerThird} >
                                            <div >
                                                <div className={classes.kycText}>{labels.stepper.BANKDETAILS}</div>
                                                {/* {} */}
                                                {this.props.bankDetails.bankDetails != undefined && this.props.bankDetails.bankDetails != null && this.props.bankDetails.bankDetails != "NA" && this.props.bankDetails.bankDetails.length != 0 ?
                                                    <>
                                                        {this.props.bankDetails.bankDetails.filter((item) => item.IsDefault == true).length != 0 ? this.props.bankDetails.bankDetails.filter((item) => item.IsDefault == true)[0].IsDefault == true &&
                                                            <div className={classes.verifiedText}>{this.props.bankDetails.bankDetails[0].BankName}</div> : <div>{labels.messages.NA}</div>
                                                        }
                                                    </>
                                                    :
                                                    <div className={classes.verifiedText}>{labels.stepper.NA}</div>
                                                }
                                                {/* <div className={classes.verifiedText}>{labels.stepper.ICICIBANK}</div> */}
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>

                        {/* ----- Question PopUp Design ------- */}
                        <div >
                            <Popup count={this.count} onsubmitClick={this.onsubmitClick} open={this.state.questionPopUp} onClose={this.closePopup} />
                        </div>
                    </div>
                </ErrorBoundary>
            )
        } catch (err) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => ({
    mainDetails: state.ProfileDetails?.AllProfileDetails?.mainDetails != "NA" ? state.ProfileDetails?.AllProfileDetails?.mainDetails?.length != 0 ? state.ProfileDetails?.AllProfileDetails?.mainDetails : [] : [],
    riskAnswers: state.RiskScore.answerOrder != null ? state.RiskScore.answerOrder.length != 0 ? state.RiskScore.answerOrder : [] : [],
    logindetails: state.Login.logindetails,
    formNo: state.Login.formNo,

    bankDetails: state.ProfileDetails.AllProfileDetails != "NA" ? state.ProfileDetails.AllProfileDetails.length != 0 ? state.ProfileDetails.AllProfileDetails : [] : [],
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ isSignedCheck, }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AdvisoryAccount))