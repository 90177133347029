import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Labels from '../../utils/constants/labels';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Divider } from '@material-ui/core';
import Loader from '../../components/loader/loader'
import labels from '../../utils/constants/labels';
import colors from '../../utils/colors/colors';
const useStyles = makeStyles((theme) => ({

    root: {
        "& .MuiTypography-body1": {
            fontSize: "13px !important",
            color: window.globalConfig.color.LABLE_GRAY,
            marginTop: -2
        },
        "& .MuiSvgIcon-root": {
            width: "0.9em"
        }

    },
    container: {
        maxheight: 500,
        // width: 320,
        // [theme.breakpoints.down("600")]: {
        //     width: 262,
        // },
    },

    header: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: window.globalConfig.color.WHITE,
        height: 50,
        fontWeight: 500,
        position: "sticky",
        top: "0px",
        zIndex: "1"
    },

    txtHeader: {
        padding: "15px 15px 15px 25px",

    },
    clearIcon: {
        float: 'right',
        cursor: 'pointer'
    },
    btnContinue: {
        color: window.globalConfig.color.WHITE,
        width: 250,
        height: 45,
        backgroundColor: window.globalConfig.color.BROWN,
        borderWidth: 0,
        borderRadius: 5,
        fontWeight: 500,
        cursor: "pointer"
    },

    newFolio: {
        fontSize: 12,
        cursor: 'pointer',
        color: window.globalConfig.color.PRIMARY,
        marginTop: 10

    },
    footer: {
        // textAlign: "center"
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginLeft: 10,
        marginRight: 10
    },
    body: {
        // padding: "10px 30px 20px 34px",
        maxHeight: "250px",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
        // alignItems: "center",
        paddingLeft: 10
    },
    contentMargin: {
        margin: "-10px 5px 17px 27px"
    },
    txtFolio: {
        fontSize: 14, fontWeight: 500
    },
    txtScheme: {
        fontSize: 14,
        color: window.globalConfig.color.LABLE_GRAY,
        fontWeight: "400",
        float: "right"
    }


}))
export default function folioPopup(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(props.folioNumbers.length == 0 ? "" : props.folioNumbers[0].ClientCode);


    useEffect(() => {
        if (value == "") {
            setValue(props.folioNumbers.length == 0 ? "" : props.folioNumbers[0].ClientCode)
        }
    })
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const onClose = () => {
        props.onClose()
        // setValue
        setValue("")

    }
    return (
        <>
            <Dialog maxHeight={'md'} maxWidth={'md'}

                aria-labelledby="simple-dialog-title" open={props.openFolioPopup}
                onClose={onClose}>
                <div className={classes.container}>
                    <div className={classes.header}>
                        <div className={classes.txtHeader}>{Labels.folioPopup.selectFolio}<span className={classes.clearIcon} onClick={props.onClose}>&#x2715;</span></div>
                    </div>
                    <div className={classes.body}>
                        <RadioGroup name="gender1" value={value} classes={classes} onChange={handleChange}>
                            {props.folioNumbers.map((item, index) => {
                                return (
                                    <div style={{ marginTop: 5 }}>
                                        <FormControlLabel value={item.label} control={<Radio style={{ color: window.globalConfig.color.BROWN }} className={classes.txtFolio} />} label={item.label} />
                                        <div className={classes.contentMargin}>
                                            <span>{item.DisplayName}</span>
                                            {/* <span className={classes.txtScheme}>{index==0?"latest":""}</span> */}
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </RadioGroup>
                    </div>
                    <div className={classes.footer}>

                        <button className={classes.btnContinue} onClick={() => props.onClick(value)}>
                            {props.loader ? <Loader size={30}></Loader> : Labels.folioPopup.continue}</button>
                        {/* <div style={{ marginTop: 10 }} onClick={()=>props.onClick("")}>
                            <u className={classes.newFolio} >{Labels.folioPopup.newFolio}</u>
                        </div> */}
                    </div>
                    <div style={{ marginTop: 25 }}>

                    </div>
                </div>
            </Dialog>
        </>
    )
}
