import * as types from '../../constants/actionsType';
const initialState = {
  linePerformance: [],
  sixMonthPerformance: [],
  threeYearPerformance: [],
  maxYeadPerformance: [],
}
const reducer = (state = initialState, action) => {

  switch (action.type) {
    case types.LINEPERFORMANCE:
      return {
        ...state,
        linePerformance: action.payload == null || action.payload.length == 0 ? state.linePerformance : action.payload,
      }
    case types.EMPTY_LINEPERFORMANCE:
      return {
        ...state,
        linePerformance: []
      }
    case types.SIX_MONTH_LINEPERFORMANCE:

      return {
        ...state,
        sixMonthPerformance: action.payload == null || action.payload.length == 0 ? state.sixMonthPerformance : action.payload,
      }
    case types.EMPTY_SIX_MONTH_LINEPERFORMANCE:
      return {
        ...state,
        sixMonthPerformance: []
      }
    case types.THREE_YEAR_LINEPERFORMANCE:

      return {
        ...state,
        threeYearPerformance: action.payload == null || action.payload.length == 0 ? state.threeYearPerformance : action.payload,
      }
    case types.EMPTY_THREE_YEAR_LINEPERFORMANCE:
      return {
        ...state,
        threeYearPerformance: []
      }
    case types.MAX_LINEPERFORMANCE:

      return {
        ...state,
        maxYeadPerformance: action.payload == null || action.payload.length == 0 ? state.maxYeadPerformance : action.payload,
      }
    case types.EMPTY_MAX_LINEPERFORMANCE:
      return {
        ...state,
        maxYeadPerformance: []
      }
    default:
      return state
  }
}
export default reducer
