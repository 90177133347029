import * as types from '../../constants/actionsType';
import getAllHeaderDetails from '../../../api/cardmenu/cardmenuapi';
import getAlertNotification from '../../../api/alert/alertnotification';
export function getAlertNotificationDetails(ClientID,) {
    return (dispatch) => {
        getAllAlertNotificationAction(dispatch, ClientID,);
    };
}
function getAllAlertNotificationAction(dispatch, ClientID,) {
    dispatch({
        type: types.LOADER_ALERT,
        payload: true,
    });
    dispatch({
        type: types.EMPTY_ALERT,
        payload: [],
    });

    getAlertNotification(ClientID)
        .then((data) => {
            //  console.log(data)
            dispatch({
                type: types.LOADER_ALERT,
                payload: false,
            });

            if (data.status == "S") {
                // 
                dispatch({
                    type: types.PREVENT_API_CALL,
                    payload: [{ ProductID: 0, api: "alert" }],
                });
                dispatch({
                    type: types.ALERT_DATA,
                    payload: data,
                });
            } else {
                dispatch({
                    type: types.EMPTY_ALERT,
                    payload: [],
                });
                dispatch({
                    type: types.PREVENT_API_CALL,
                    payload: [{ ProductID: 0, api: "alert" }],
                });


            }

        })
        .catch((error) => {
            // console.log(error)
            // dispatch({
            //     type: types.PREVENT_API_CALL,
            //     payload: [{ ProductID: 0, api: "alert" }],
            // });
            dispatch({
                type: types.LOADER_ALERT,
                payload: false,
            });

        })
}
export const loaderAlert = (data) => {
    return {
        type: types.LOADER_ALERT_LIST,
        payload: data,
    }
}