import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import colors from '../../utils/colors/colors';
import Menu from '../menu/menu'
// import ProfileTab from '../../components/profile/profiletab';
import ProfileTab from '../../containers/tabs/profile_tab';
import Usercard from './usercard';
import { withRouter } from 'react-router';
import color from "../../utils/colors/colors";
import Text from '../text/text';
import ErrorBoundary from '../Errorhandling/Errorhandle';
import { replaceNull, scrollToTopPage } from '../../utils/commonfunction';
import labels from '../../utils/constants/labels';
import Profile_tab_equity from '../../containers/tabs/profile_tab_equity';
const styles = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: 30,
    position: 'fixed',
    top: 0,
    zIndex: 2,
    backgroundColor: window.globalConfig.color.PRIMARY,
    boxShadow: 'none',
    width: "100%"
    // marginTop: "-15px"
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },

  toolbars: {
    flex: 1,
    minHeight: 30,
    alignItems: 'flex-start',
    //marginLeft:20
  },
  title: {
    paddingRight: 40,
    fontSize: 15,
    color: window.globalConfig.color.cyanBluish,
    cursor: "pointer"
  },

  titles: {
    justifyContent: 'flex-start',
    fontSize: 24,
    //fontFamily: "Roboto",
    fontWeight: 'bold',
    color: window.globalConfig.color.WHITE
  },
  icon: {
    fontSize: 26,
    paddingRight: 43,
    color: window.globalConfig.color.cyanBluish,
    marginBottom: -12
  },
  menusection: {
    flex: 1,
    flexDirection: 'row',
    paddingTop: 20,
    paddingBottom: 10,
    //paddingLeft:20,
    cursor: "pointer",
    float: 'right',
    marginRight: -15,
  },
  txtmenu: {
    flex: 1,
    flexDirection: 'row'
  },
  iconmenu: {
    flex: 1,
    flexDirection: 'row'
  },
  menu: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'left',
    marginLeft: "8%"
  },
  card: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: 30,
    marginRight: 10,
  },
  tab: {
    justifyContent: 'flex-start',
    paddingTop: 10,
  },
  logo: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    float: 'left',
    marginLeft: -11,
  },
  iconsection: {
    float: 'right',
    paddingTop: 16,
    cursor: "pointer",
    marginRight: -7,
    marginBottom: 10,
    display: "flex"
  },
  span: {
    // marginTop: "-10px"
  },
  protxt: {
    display: 'block',
    color: window.globalConfig.color.BLACK_PRIMARY,

  },
  protext: {
    display: 'block',
    fontSize: 14,
    color: window.globalConfig.color.BLACK_PRIMARY,

  },
  username: {
    fontSize: 10,
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontWeight: 500,
  },
  popup: {
    boxShadow: '7px 7px 10px 0 rgba(0, 0, 0, 0.1)',
    backgroundColor: window.globalConfig.color.WHITE,
    fontSize: 10,
    width: 240,
    cursor: "pointer"
  },
  welcome: {
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: 10,
    // marginTop: "-10px"
  },
  link: {
    textDecoration: 'none',
    color: window.globalConfig.color.LABLE_GRAY,
  }, handsign: {
    cursor: "pointer"
  },
  root1: {
    backgroundColor: window.globalConfig.color.PRIMARY,
    // marginTop: '-1.9em',
    paddingTop: "6%",
    height: "100px"
  },
  toolbars1: {
    flex: 1,
    minHeight: 30,
    alignItems: 'flex-start',
    marginLeft: 20
  },
  titles1: {
    justifyContent: 'flex-start',
    fontSize: 24,
    fontWeight: 'bold',
    color: window.globalConfig.color.WHITE,
    paddingTop: '18px 0',
    float: "left",
    marginLeft: -20
  },

  tab1: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-start',
    //marginLeft:25
  },
  card1: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: 5,
    marginTop: 1,
    marginRight: 10,
    borderRadius: 1
  },
  menu1: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 25,
    marginBottom: 20,
    marginLeft: -20
  },

  date1: {
    margin: 5,
    fontWeight: "bold"
  },
  dateContainer1: {
    flex: 1,
  },
  cardroot1: {
    minWidth: 275,
  },
  cardbullet1: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  cardtitle1: {
    fontSize: 14,
  },
  cardpos1: {
    marginBottom: 12,
  },
  container: {
    // height: "108vh", 
    maxHeight: "auto",
    color: window.globalConfig.color.BLACK_PRIMARY,
    [theme.breakpoints.down('sm')]: {
      height: 'revert',
      maxHeight: "auto", color: window.globalConfig.color.BLACK_PRIMARY,
    },
  },
  profileCard: {
    height: 70,
    marginTop: '1.6%',
    // padding: '3.6%',
    padding: '0% 0% 3.6% 0%',
    backgroundColor: window.globalConfig.color.PRIMARY, color: window.globalConfig.color.red, display: "flex", flexDirection: "column",
    [theme.breakpoints.down('sm')]: {
      paddingTop: "6.5%",
      paddingLeft: '3.5%',
    },
    [theme.breakpoints.down('xs')]: {
      paddingTop: "10.5%",
      // paddingLeft: '5.5%',
      height: 'auto',
    },
  },
  userNameDiv: {
    paddingLeft: "7.5%",
    textAlign: "left",
    marginTop: "4%",
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px',
    },
  },

  profileTitle: {
    paddingLeft: '7.5%',
    textAlign: "left",
    fontSize: 25,
    fontWeight: "bolder",
    color: window.globalConfig.color.WHITE,
    marginTop: "0.6%",
    [theme.breakpoints.down('sm')]: {
      fontSize: 22,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 20,
    },

  },
  usercard: {
    display: "grid", gridTemplateColumns: "40% 60%",
    marginTop: 20,
    // margin: "3%", 
    // marginLeft: '1vw',
    [theme.breakpoints.down('xs')]: {
      display: "unset",
      margin: "unset",
      width: '100%',
    },
    [theme.breakpoints.between("601", "999")]: {
      display: "unset",
      margin: "unset",
      width: '100%',
    },
  },
  // profileTab: {
  //   height: '100%',
  //   width: "56%",
  //   backgroundColor: window.globalConfig.color.WHITE,
  //   marginLeft: "2.5%",
  //   marginBottom: "6%",
  //   border: `1px solid ${window.globalConfig.color.WHITE}`,
  //   boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
  //   borderRadius: 6,
  //   minHeight: 'maxcontent',
  //   [theme.breakpoints.down('xs')]: {
  //     width: "94%",
  //     maxWidth: '100%',
  //     // overflowX:'scroll',
  //     marginTop: '3%',
  //   },
  //   [theme.breakpoints.between('601', '999')]: {
  //     width: "100%",
  //   },
  //   [theme.breakpoints.between('1000', '1030')]: {
  //     width: "57%",
  //   },

  // },
  usercardwidth: {
    // [theme.breakpoints.up("920")]: {
    //   width: "36%",
    // },
    // [theme.breakpoints.between("920", "620")]: {
    //   width: "90%",
    // },
    // [theme.breakpoints.down('600')]: {
    //   width: "100%",
    // },
  },
  usercardRow: {
    // display: "flex",
    flexDirection: "row",
    marginTop: '10px',
    // margin: "3%",
    // marginLeft: '1vw',
    [theme.breakpoints.down('xs')]: {
      display: "unset",
      margin: "unset",
      width: '100%',
    },
    [theme.breakpoints.between("601", "999")]: {
      display: "unset",
      margin: "unset",
      width: '100%',
    },
  },
  profileTab: {
    display: 'flex',
    flexDirection: 'column',
    // height: '30%',
    // width: "56%",
    backgroundColor: window.globalConfig.color.WHITE,
    marginLeft: "2.5%",
    marginBottom: "6%",
    border: `1px solid ${window.globalConfig.color.WHITE}`,
    boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
    borderRadius: 6,
    minHeight: 'maxcontent',
    [theme.breakpoints.down('xs')]: {
      width: "94%",
      maxWidth: '100%',
      // overflowX:'scroll',
      marginTop: '3%',
    },
    [theme.breakpoints.between('601', '999')]: {
      width: "100%",
    },
    [theme.breakpoints.between('1000', '1030')]: {
      width: "57%",
    },

  },
  usercardwidth: {
    // [theme.breakpoints.up("920")]: {
    //   width: "36%",
    // },
    // [theme.breakpoints.between("920", "620")]: {
    //   width: "90%",
    // },
    // [theme.breakpoints.down('600')]: {
    //   width: "100%",
    // },
  },
  profileTabEquity: {
    marginLeft: '3%',
    marginTop: "1%",
    textAlign: 'left'
  }
});
let timer = ""
class MyAccount extends React.Component {
  isComponentMounted = false
  constructor(props) {
    super(props)
    this.state = {
      show: false
    }
  }
  async componentDidMount() {
    this.isComponentMounted = true
    scrollToTopPage()
    if (this.isComponentMounted) {
      timer = setTimeout(() => {
        this.setState({ show: true })
      }, 7000);
    }
  }
  componentWillUnmount() {
    this.isComponentMounted = false
    clearTimeout(timer)
  }
  componentDidUpdate(prevProps) {
  }
  render() {
    const { classes } = this.props;
    return (<><div className={classes.container}>

      <ErrorBoundary>

        <div className={classes.usercard} >
          <div xs={12} className={classes.usercardwidth}>
            <Usercard page="Profile" />
          </div>
          <div xs={12}  >
            <div className={classes.profileTab}>
              <ProfileTab location={this.props.location} />

            </div>
            {replaceNull(this.props.ProfileDetails?.data, "NA") != "NA" && <div className={classes.usercardRow} >
              <div xs={12} className={classes.usercardwidth}></div>
              <div xs={12} className={classes.profileTab}>
                <div className={classes.profileTabEquity}>

                  <Text label={labels.dematDetails.EquityAccount} />
                  {/* <span style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: "small" }}>UCC Details</span> */}
                </div>
                <Profile_tab_equity location={this.props.location} />
              </div>
            </div>}

          </div>

        </div>

      </ErrorBoundary>

    </div>
    </>
    );
  }
}
const mapStateToProps = state => ({
  login: state.Login.loginError,
  logindetails: state.Login.logindetails,
  ProfileDetails:
    state.ProfileDetails.AllEquityProfileDetails != "NA"
      ? state.ProfileDetails.AllEquityProfileDetails
      : []
  ,
})
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(MyAccount)))
