import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
//action created by dulcy
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
function getSearchBranch(BankID, SearchValue) {
 

    var raw = JSON.stringify({
        "BankID": BankID,
        "SearchValue": SearchValue,
       
    });
    return Networking.PostApi(ApiValues.Searchapi, raw).then(result => { return result })
.catch(function (error) {
  return { "status": "F", "message": labels.messages.apierror }
});
  //   var config = {
  //       method: 'post',
  //       url: ApiValues.Searchapi,
  //       headers: { 
  //         'Content-Type': 'application/json'
  //       },
  //       data : raw
  //     };
      
  //  return   axios(config)
  //     .then(function (response) {
  //     return response.data
  //     })
  //     .catch(function (error) {
  //       return{status:"F",message:labels.messages.apierror}
  //     });
      
}
export default getSearchBranch
