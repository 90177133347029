// mutual funds created by yogitha on 23/07/21
import React, { Component } from "react";
import colors from '../../utils/colors/colors';
import { withStyles } from '@material-ui/core/styles';
import Menu from '../../components/menu/menu';
import Equity from '../../components/equity-trading/equity-tracking';
import { getL5reportDetails } from "../../redux/actions/L5Report/L5ReportAction";
import { withRouter } from "react-router";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
const styles = theme => ({
    root: {
        backgroundColor: window.globalConfig.color.WHITE,
        width: "100%",
        height:"100%",
        display:"flex",
        flexDirection:"column",
        justifyContent:"center"
        // minHeight:'80vh',
    //    height:'100%',
    // minHeight: '78vh',
    },
    marginTopBody:{
        // marginTop:"100px",
        [theme.breakpoints.down("xs")]: {
            marginTop:"4em",
        },
    }
}
)
class EduityTrading extends Component {
    constructor() {
        super();
        this.state = {
        }
    }
    render() {

        const { classes } = this.props;
        return (
            <div className={[classes.root,"equityTrack"].join(' ')}>
                <div xs={12} className={classes.marginTopBody}>
                <Equity getL5reportDetails={this.props.getL5reportDetails}/>
                </div>
           
            </div>
        )
    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getL5reportDetails }, dispatch)
};
export default withStyles(styles)(withRouter(connect(null, mapDispatchToProps)(EduityTrading)));