import * as types from "../../constants/actionsType";
import getOneTimeOrder from "../../../api/purchaseorder/onetimeorderapi";
import secureStorage from "../../../utils/securestorage";
import getPaymentGatewayDetails from "../../../api/paymentgateway/paymentgateway";
import { openRequestedSinglePopup, sleep } from "../../../utils/commonfunction";
export function OneTimeOrder(
  TransCode,
  OrderId,
  ClientCode,
  SchemeCd,
  SchemeID,
  BuySell,
  BuySellType,
  DPTxn,
  OrderVal,
  Qty,
  AllRedeem,
  FolioNo,
  Remarks,
  KYCStatus,
  RefNo,
  SubBrCode,
  EUIN,
  EUINVal,
  MinRedeem,
  DPC,
  IPAdd,
  Param1,
  Param2,
  Param3,
  ClientID,
  totalschemelength,
  currentlength,
  Source,
  ID,
  SuccessMethod,
  failure
) {
  return (dispatch) => {
    onetimeOrderAction(
      dispatch,
      TransCode,
      OrderId,
      ClientCode,
      SchemeCd,
      SchemeID,
      BuySell,
      BuySellType,
      DPTxn,
      OrderVal,
      Qty,
      AllRedeem,
      FolioNo,
      Remarks,
      KYCStatus,
      RefNo,
      SubBrCode,
      EUIN,
      EUINVal,
      MinRedeem,
      DPC,
      IPAdd,
      Param1,
      Param2,
      Param3,
      ClientID,
      totalschemelength,
      currentlength,
      Source,
      ID,
      SuccessMethod,
      failure
    );
  };
}
function onetimeOrderAction(
  dispatch,
  TransCode,
  OrderId,
  ClientCode,
  SchemeCd,
  SchemeID,
  BuySell,
  BuySellType,
  DPTxn,
  OrderVal,
  Qty,
  AllRedeem,
  FolioNo,
  Remarks,
  KYCStatus,
  RefNo,
  SubBrCode,
  EUIN,
  EUINVal,
  MinRedeem,
  DPC,
  IPAdd,
  Param1,
  Param2,
  Param3,
  ClientID,
  totalschemelength,
  currentlength,
  Source,
  ID,
  SuccessMethod,
  failure
) {
  dispatch({
    type: types.LOADER_PURCHASE_ORDER,
    payload: true,
  });
  getOneTimeOrder(
    TransCode,
    OrderId,
    ClientCode,
    SchemeCd,
    SchemeID,
    BuySell,
    BuySellType,
    DPTxn,
    OrderVal,
    Qty,
    AllRedeem,
    FolioNo,
    Remarks,
    KYCStatus,
    RefNo,
    SubBrCode,
    EUIN,
    EUINVal,
    MinRedeem,
    DPC,
    IPAdd,
    Param1,
    Param2,
    Param3,
    ClientID,
    Source
  )
    .then((data) => {
      //
      let response = data
      if (data != undefined) {
        if (data.status == "S" || data.status == "F") {
          if (data.status == "S") {
            response.FolioNo = FolioNo
            SuccessMethod(SchemeID, data.status, totalschemelength === currentlength, response,ID);

          } else {
            response.FolioNo = FolioNo
            failure(SchemeID, data.message, false, response,ID)
          }
          sleep(1000)
          let statuscheck = secureStorage.getItem("purchaseorderstatus");
          if (
            statuscheck == null ||
            statuscheck == undefined ||
            statuscheck == ""
          ) {
            secureStorage.setItem("purchaseorderstatus", data.status);
          } else {
            let statuscontainer = [];
            statuscontainer.push(data.status);
            statuscontainer.push(statuscheck);
            secureStorage.setItem("purchaseorderstatus", statuscontainer);
          }

          //

          if (totalschemelength > 1) {

            let multipleSchemeOutput = data;
            multipleSchemeOutput.SchemeID = SchemeID;
            dispatch({
              type: types.PURCHASE_ORDER,
              payload: multipleSchemeOutput,
            });

            if (totalschemelength === currentlength) {
              dispatch({
                type: types.LOADER_PURCHASE_ORDER,
                payload: false,
              });
              setTimeout(() => {
                let countofstatus = secureStorage.getItem(
                  "purchaseorderstatus"
                );
                for (var i = 0; i < countofstatus.length; i++) {
                  if (countofstatus[i].indexOf("S") != -1) {
                    //

                    getPaymentGatewayDetails(ClientCode, data.orderID).then(
                      (item) => {
                        if (item.status == "S") {
                          secureStorage.setItem("purchaseorderstatus", "");
                          // window.open(item.data, "_self");
                          openRequestedSinglePopup(item.data)
                        }
                      }
                    );
                    break;
                  }
                }
              }, 3000);
            }
          } else {
            dispatch({
              type: types.LOADER_PURCHASE_ORDER,
              payload: false,
            });
            if (data.status == "S") {
              //
              let output = data;
              output.SchemeID = SchemeID;
              dispatch({
                type: types.PURCHASE_ORDER,
                payload: output,
              });

              dispatch({
                type: types.LOADER_PURCHASE_ORDER,
                payload: false,
              });
              getPaymentGatewayDetails(ClientCode, data.orderID).then(
                (item) => {
                  if (item.status == "S") {
                    secureStorage.setItem("purchaseorderstatus", "");
                    setTimeout(() => {
                      openRequestedSinglePopup(item.data)
                    }, 3000);
                  }
                }
              );
            } else {
              let output = data;
              output.SchemeID = SchemeID;
              dispatch({
                type: types.PURCHASE_ORDER,
                payload: output,
              });
              if (data.status == "S") {
                response.FolioNo = FolioNo
                SuccessMethod(SchemeID, data.status, true, response,ID);
              }
              else {
                response.FolioNo = FolioNo
                failure(SchemeID, data.message, true, response,ID)
              }
            }
          }
        } else {
          dispatch({
            type: types.PURCHASE_ORDER,
            payload: [],
          });
          dispatch({
            type: types.LOADER_PURCHASE_ORDER,
            payload: false,
          });
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: types.LOADER_PURCHASE_ORDER,
        payload: false,
      });
    });
}

// export const SetPurchaseResponseEmpty = () => {
//   return {
//     type: types.PURCHASE_ORDER_EMPTY,
//   };
// };
