import * as types from '../../constants/actionsType'
import { replaceNull } from '../../../utils/commonfunction';
import labels from '../../../utils/constants/labels';
import moment from 'moment';
const initialState = {
    alertData: [],
    isAlertHadData: false,
    isLoading: false,
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.ALERT_DATA:
            return {
                ...state,
                alertData: action.payload.table1 == null || action.payload.table1.length == 0 ? [] : action.payload.table1 != null && action.payload.table1.length != 0 ? replaceNull(action.payload.table1, labels.messages.NA).map((item) => {
                    return { status: item.Status, loader: false, statusText: item.MailSubject, statusDescription: item.Message, days: item.DateOfMail == "NA" ? labels.messages.NA : moment(item.DateOfMail).format('DD MMM YYYY') || labels.apiKey.apiKeys, Category: item.Category, mailBody: item.MailBody, ID: item.ID }
                }) : [],
                isAlertHadData: action.payload.table1 == null || action.payload.table1.length == 0 ? false : true
            }
        // case types.LOADER_ALERT_LIST:
        //     return {
        //         ...state,
        //         alertData: state.alertData.map((item) => {
        //             return { ...item, loader: item.ID == action.payload.id ? action.payload.loader : false }
        //         })
        //     }
        case types.EMPTY_ALERT:
            return {
                ...state,
                alertData: [],
                // isAlertHadData: false,
                // isLoading: false,
            }
        case types.LOADER_ALERT:
            return {
                ...state,
                isLoading: action.payload,
                alertData: [],

            }

        default:
            return state
    }
}
export default reducer
