import React from 'react'
import labels from '../../utils/constants/labels';
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
     console.log(error)
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    //  console.log(error)
    // You can also log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (<div style={{ marginTop: "10vw" }}>
        {labels.messages.apierror}.
      </div>)
    } else {
      //
      return (<div>{this.props.children}</div>)
    }

  }
}
export default ErrorBoundary