import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
// import styles from '../login/user-jss';
import Successfull from "../../utils/assets/images/successfull.jpg";
import Button from '../../components/custombutton/button'
import colors from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
const styles = theme => ({})
let counting = ''
class SuccessPage extends React.Component { // eslint-disable-line
    constructor() {
        super();
        //      this.myDiv = React.createRef();

        this.state = {
            count: 10
        }
        // this.submit = this.submit.bind(this)
        // this.submitSignup = this.submitSignup.bind(this)

    }
    componentDidMount() {
        counting = setInterval(() => {
            if (this.state.count <= 0) {
                // counting.clearInterval()
                this.setState({ count: 10 })
            } else {
                this.setState({ count: this.state.count - 1 })
                if (this.state.count == 0) {
                    this.props.history.push({
                        pathname: "/",
                        state: {
                            data: "from welcome"

                        }
                    })
                }
            }
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(counting)
    }
    render() {


        return (
            <div >
                <img src={Successfull} style={{ height: 200, width: 200, position: "absolute", bottom: 105, left: 0 }} />
                <h1 style={{ marginTop: "8%" }}>{labels.Login.congratulations}</h1>
                <h3>{labels.Login.successMsg}</h3>
                <div style={{ width: "15%", marginLeft: "42%", marginTop: "2%" }}>
                    <Button name={labels.Login.clickHereToLogin} onClick={() => {
                        this.props.history.push({
                            pathname: "/",
                            state: {
                                data: "from welcome"

                            }
                        })
                    }} page={"primary"} />
                </div>
                <p style={{ color: window.globalConfig.color.BROWN, fontWeight: 500, fontSize: 12 }}>{labels.Login.redirecting} {this.state.count} {labels.Login.sec}</p>


            </div >
        );
    }
}
const mapStateToProps = state => {
    // 
    // a = state.Login.signupmessage
    return {
        messagesAuth: state.Login.loginError,
        signup: state.Login.signup,
        signupmessage: state.Login.signupmessage,
        loader: state.Login.loadercontinue,
        loadersignup: state.Login.loadersignup
    };
};
const dispatchToProps = (dispatch) => {
    return {
    };
};
const SuccessPageMapped = connect(
    mapStateToProps,
    dispatchToProps
)(SuccessPage);
export default withStyles(styles)(SuccessPageMapped);
