import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
import Networking from '../../utils/api/apiaccess';
import moment from 'moment';
function UpdateCart(ID, SchemeID, ClientID, Amount, AUM, Instalments, IsBSEStar, OneTimeAllowed, OneWeek, OneYear, Ranking, Risk,
    SipAllowed, SipFrequency, Sipdate, SipdateID, ThreeYear, ratings, AMCID, AssetClass, ISIN, RTAID, RTAProdCode, SchemeCode, ISFirstPayment, SessionID,TransactionTypeID) {
    let BaseUrl = ApiValues.UpdateCart
    let data = {};
    data.ID = ID,
        data.SchemeID = SchemeID,
        data.ClientID = ClientID,
        data.Amount = Amount,
        data.AUM = AUM,
        data.Instalments = Instalments,
        data.IsBSEStar = IsBSEStar,
        data.OneTimeAllowed = OneTimeAllowed,
        data.OneWeek = OneWeek,
        data.OneYear = OneYear,
        data.Ranking = Ranking,
        data.Risk = Risk,
        data.SipAllowed = SipAllowed,
        data.SipFrequency = SipFrequency,
        data.Sipdate = moment(Sipdate).format("DD/MM/YYYY"),
        data.SipdateID = SipdateID,
        data.ThreeYear = ThreeYear,
        data.ratings = ratings,
        data.AMCID = AMCID,
        data.AssetClass = AssetClass,
        data.ISIN = ISIN
    data.RTAID = RTAID,
        data.RTAProdCode = RTAProdCode,
        data.SchemeCode = SchemeCode,
        data.ISFirstPayment = ISFirstPayment,
        data.SessionID = SessionID,
        data.TransactionTypeID = TransactionTypeID

    return Networking.PostApi(BaseUrl, data).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });
}
export default UpdateCart
