import Login from './assets/images/finnoNewLogo.png'
import Logo from './assets/03.png'

export const Asset = {
    "companyName": "Finnovate",
    "logoUrl": Logo,
    "logoUrlLogin": Login,
    "primaryWidth": "50px",
    "secondaryWidth": "60px",
    "source": "Finnovate Web"
}
