import moment from 'moment';
import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
var axios = require('axios');
/* name should be camel case in all function */
// function getTransactionOrderReportDetails(ClientID, SessionID, AsOnDate)
import Networking from '../../utils/api/apiaccess';
function getRedeemParameterDetails(TransactionNo) {
    // let userDetails = secureStorage.getItem('user')


    return Networking.GetApi(`${ApiValues.RedeemParameter}${TransactionNo}`, "").then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });

}
export default getRedeemParameterDetails