import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
//action created by dulcy
import Networking from '../../utils/api/apiaccess';
function getEquityUcc(ClientID,) {

    return Networking.GetApi(`${ApiValues.EquityUCC}?clientId=${ClientID}`, "").then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });

}
export default getEquityUcc
