//page created by dulcy bankdetails
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import OutlinedButtons from '../payment/button';
import './popup.css'
import moment from 'moment';
import Text from '../text/text';
import CommonCheckbox from '../checkbox/checkbox';
import Popup from '../mandate/mandatepopup'
import CommonSnackbar from '../snackbar/snackbar';
import labels from '../../utils/constants/labels';
import { updatebankdetails, getSearchBranchDetails, getMarkAsDefault, updateMandateStatus } from '../../redux/actions/profile/profile'
import color from "../../utils/colors/colors";
import ErrorBoundary from '../Errorhandling/Errorhandle';
import label from "../../utils/constants/labels";
import NoData from '../nodata/nodata';
import DownArrow from '../../utils/assets/downArrow.png';
import HoverDownArrow from '../../utils/assets/downArrow1.png';
import UpArrow from '../../utils/assets/upArrow.png';
import HoverUpArrow from '../../utils/assets/upArrow1.png';
import Loader from '../../components/loader/loader';
import { NegativeFinding, openRequestedSinglePopup, replaceNull } from '../../utils/commonfunction';
import MandateStatusCheck from '../../api/mandate/mandatestatuscheck'
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom'
import Addbankpopup from '../popup/Addbankpopup';
import Deletepopup from '../popup/deletepopup';
import MarkAsPrimarydetails from '../../api/profile/markasprimary';
import { Redirect } from 'react-router';
import MandateRedirection from '../../api/mandate/mandateredirection';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
const styles = theme => ({

    heading2: {
        color: window.globalConfig.color.PRIMARY_BLUE,
        fontSize: 17,
        fontWeight: 400,
        textAlign: "left"
    },
    headingMandate: {
        color: window.globalConfig.color.PRIMARY_BLUE,
        fontSize: 15,
        fontWeight: 600,
        textAlign: "left"
    },


    header: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: window.globalConfig.color.WHITE,
        height: 50,
        fontWeight: 500
    },

    bankName: {
        display: 'flex',
        flexDirection: "row",
        marginTop: "3%",
        flex: 1
    },
    title: {
        flex: "5 1 25%",
        display: "flex",
        justifyContent: "flex-start"
    },
    bankdetailsCardFirstRow: {
        display: 'Grid',
        borderRadius: 8,
        padding: '15px 30px',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gridGap: '25px',
        backgroundColor: 'rgb(244,246,249)',
        marginTop: "1%",
        [theme.breakpoints.down('xs')]: {
            display: 'Grid',
            gridTemplateColumns: 'repeat(2, 50%)',
            // gridGap: '25px',
            gridColumnGap: '25px',
            gridRowGap: '10px',
        },

    },

    branchNameDiv: {
        // display: "flex",
        // flexDirection: "column",
        // flex: 0.857,
        // marginTop: '-0.4%',
        marginLeft: '8%',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, [col-start] 65% [col-end])',
        gridGap: '25px',
        // marginTop: '2%',
        [theme.breakpoints.down('xs')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 100%)',
            gridGap: '10px',
            marginTop: '4%',
            marginBottom: '6%',
        },
    },
    branchName: {
        display: "flex",
        flexDirection: "row",
        marginTop: "3%",
        [theme.breakpoints.down('xs')]: {
            // justifyContent: 'center',
        },
    },

    mandateCard: {
        padding: "1% 0 2% 2%",
        marginTop: "3% 0 3% 0",
        width: "92%",
        backgroundColor: window.globalConfig.color.RosyBrown,

        [theme.breakpoints.down('xs')]: {
            width: "96%",
            padding: "1% 0 2% 1%",
        },
    },
    CardDiv: {

        marginTop: "3%",
        display: 'grid',
        gridTemplateColumns: 'repeat(2,69%)',
        // flex: 1,
        [theme.breakpoints.down('xs')]: {
            marginTop: "3%",
            display: 'grid',
            gridTemplateColumns: 'repeat(2, [col-start] 50% [col-end])',
        },
    },
    downloadMandateDiv: {
        flex: "0.5 1 25%",
        display: "flex",
        justifyContent: "flex-start",
        color: window.globalConfig.color.PRIMARY,
        '&:hover': {
            cursor: 'pointer',

        }
    },
    headingMandateDownload: {
        color: window.globalConfig.color.highlightcolor,
        fontSize: 15,
        fontWeight: 600,
        textAlign: "left",
        textDecoration: "underline",
        cursor: 'pointer',
        // '&:hover': {

        //     color: window.globalConfig.color.highlightcolor,
        // }
    },
    cardSpareaterLine: {
        height: 0.5,
        width: "100%",
        backgroundColor: window.globalConfig.color.AliceBlue
        // , marginTop: "3%",
    },
    cardSpareaterLines: {
        height: 0.5,
        width: "100%",
        backgroundColor: window.globalConfig.color.PRIMARY_BORDER,
        marginTop: "3%",
        width: "96%",
    },
    madatebtn: {
        float: "left",
    },
    addBankbtn: {
        display: "flex",
        flexDirection: "row",
        //marginTop: "3%",
        marginBottom: "3%",
        justifyContent: "center",

    },
    outlinebtn: {
        marginRight: "5%",
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
        },
    },
    popupcard: {
        width: "100%",
    },
    popupFooterDiv: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        [theme.breakpoints.down('xs')]: {
            justifyContent: "space-between"
        },
    },
    ifscCodeMainCard: {
        padding: "5% 3% 3% 3%",
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    ifscCodeCard: {
        display: "flex",
        flexDirection: "row",
        marginBottom: "4%",
        marginLeft: '2%',
        [theme.breakpoints.down('xs')]: {
            // justifyContent: 'center',
            marginLeft: '0%',
        },

    },
    ifscCodeCardResponsive: {
        display: "flex",
        flexDirection: "row",
        marginBottom: "4%",
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },

    },
    iFSCCode: {
        marginRight: "4%", color: window.globalConfig.color.LABLE_GRAY, fontSize: 14,
    },
    dropDownPopup:
    {
        width: '46.5%',
        display: "flex", flexDirection: "column", marginRight: "4%",
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    iFSCCodeDiv: {
        marginLeft: '2%',
        display: "flex", flexDirection: "row", marginTop: "5%",
        [theme.breakpoints.down('xs')]: {
            display: "flex", flexDirection: "column", marginTop: "4%", marginLeft: '0%',
        },
    },
    dropdownDiv: {
        marginRight: "4%",
        [theme.breakpoints.down('xs')]: {
            width: '98%',
        },
    },
    dropdownBank: {
        width: "260px !important",
        //marginTop:23
    },
    reAccountNumber: {
        marginRight: "4%",
        [theme.breakpoints.down('xs')]: {
            width: '95%',
        },
    },
    // footerCard: {
    //     display: "flex", flex: "0.5 0.5 42%",
    // },
    noDatastyle: {
        marginTop: '7%',
        marginBottom: '7%',
        marginLeft: '-5%'
    },
    button: {
        width: 'auto',
        padding: "7px 22px",
        borderRadius: '22px',
        borderColor: window.globalConfig.color.BLACK_PRIMARY,
        color: window.globalConfig.color.BLACK_PRIMARY,
        textTransform: "none",
        [theme.breakpoints.down('xs')]: {
            width: '95%',
        },
    },
    clearIcon: {
        float: 'right',
        cursor: 'pointer'
    },
    marginTopTextAlign: {
        width: '50%',
        [theme.breakpoints.between('320', '374')]: {
            marginTop: '4%',
            width: '94%',
        },
        [theme.breakpoints.between('375', '424')]: {
            marginTop: '4%',
            width: '96%',
        },
        [theme.breakpoints.between('425', '600')]: {
            marginTop: '4%',
            width: '98%',
        },
    },
    buttonStyle: {
        width: "122px",
        marginRight: "49px"
    },
    marginTopTextBoxAlign: {
        // marginRight: '10%',
        // [theme.breakpoints.down('xs')]: {
        //     marginTop: '4%',
        //     width: '0',
        //     marginLeft: '0%'
        //     // width:'95%',
        // },
        width: '42.7%',
        marginLeft: '4%',
        [theme.breakpoints.between('320', '374')]: {
            marginTop: '4%',
            width: '101%',
            marginLeft: '0%',
        },
        [theme.breakpoints.between('375', '424')]: {
            marginTop: '4%',
            width: '102%',
            marginLeft: '0%',
        },
        [theme.breakpoints.between('425', '600')]: {
            marginTop: '4%',
            marginLeft: '0%',
            width: '103%',
        },
    },
    marginAlignText: {
        marginLeft: '4%',
        width: '100%',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '0%',
            width: '95%',
            marginTop: '4%',
        },
    },
    marginAlignmenHeading: {
        //  marginLeft: "6%",
        [theme.breakpoints.down('350px')]: {
            // "& .Text-col": {
            //     "& .Text-header1":{
            marginLeft: "0px !important",
            //     },
            // },
        },
    },
    nomineeStyle: {
        fontSize: 12
    },
    nomineeDetail: {
        display: 'flex',
        flexDirection: 'column'
    },
    nomineeName: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '28px',
        alignItems: 'left',
        fontSize: 14
    },
    nomineeGrid: {
        display: 'grid',
        gridGap: '5px',
        gridTemplateColumns: 'repeat(3, 1fr)',
        flexDirection: 'row',
        marginLeft: '3%',
        alignItems: 'left',
        textAlign: 'left'
    },
    nomineeRelation: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '3%',
        alignItems: 'left',
        fontSize: 14
    },
    nomineeAllocation: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: '5%',
        alignItems: 'left',
        fontSize: 14
    },
    buttonDivNoIFSC: {
        float: 'right',
        //  marginRight: '6%',
        marginTop: 60,
        [theme.breakpoints.down('xs')]: {
            float: 'none',
            margin: 10,
            padding: "20px 0px",
            [theme.breakpoints.between('500', '600')]: {
                padding: "10px 0px",
            },
        },
    },
    selectedbankDetails: {
        // height: '26%'
    },
    imageDesign: {
        width: 30,
        height: 30,
        cursor: "pointer"
    }

});
let branchList = []

class EquityDematDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            expand: false,
            openbankpopup: false,
            selected: false,
            checked: false,
            popupOpen: false,
            open: false,
            severity: "",
            message: "",
            BankAccounttype: "",
            BankAccounttypeID: 0,
            accountnumber: "",
            iFSCCode: "",
            errorbankaccounttype: "",
            erroraccountnumber: "",
            errorifsccode: "",
            bankarray: [],
            bankname: "",
            bankid: "",
            errorbankName: "", branchName: "",
            reenteraccountnumber: "",
            errorreenteraccountnumber: "",
            branchFullList: [],
            errorbranchname: "",
            loader: false,
            branchList: [],
            downArrow: DownArrow,
            showDataCount: 3,
            showAllDatas: false,
            showUpArrow: false,
            upArrow: UpArrow,
            markAsPrimary: false,
            markasprimaryloader: false,
            markasprimarydetails: {},
            bankMandateID: '',
            loaderApproveMandate: false,
            hideLink: true,
            showContent: 0
        }
    }

    showBankDetails = (value, val) => {
        this.closePopup()
        //     
        if (value.status === "S") {
            this.setState({ severity: "success" })
        } else {
            this.setState({ severity: "warning" })
        }
        this.setState({ open: true, message: value.message })
    }
    // function created by dulcy--start
    handlebankclose = () => {
        this.setState({
            openbankpopup: false,
            loader: false
        })
    }
    handleExpandClick = () => {
        this.setState({
            expand: !this.state.expand
        })
    };
    handleClick = () => {
        this.setState({
            BankAccounttype: "",
            accountnumber: "",
            iFSCCode: "",
            reenteraccountnumber: "",
            errorbankaccounttype: "",
            erroraccountnumber: "",
            errorbankaccounttype: "",
            errorreenteraccountnumber: "",
            errorifsccode: "",
            errorbankName: "",
            errorbranchname: "", branchList: [],
            branchFullList: [],
            openbankpopup: true,

        })
    }
    handlebankclick = () => {
        this.setState({ openbankpopup: false })
    }
    onCheck = () => {
        //this.setState({ checked: !this.state.checked }) commented by dulcy
    }
    Addmandateclick = (value) => {
        if (this.props.BankList != null && this.props.BankList != undefined && this.props.BankList.length != 0) {
            if (this.props.BankList.filter((item) => item.label.toUpperCase() == value.BankName.toUpperCase()).length != 0) {
                this.setState({
                    bankid: this.props.BankList.filter((item) => item.label.toUpperCase() == value.BankName.toUpperCase())[0].value,
                })
            }
        }
        this.setState({ popupOpen: !this.state.popupOpen, bankname: value.BankName, branchName: value.BranchName, BankAccounttype: value.AccountType, accountnumber: value.BankAccountNumber, iFSCCode: value.IFSCCode, bankMandateID: value.ID })
    }
    closePopup = () => {
        this.setState({ popupOpen: false })
    }

    MouseHover = () => {
        this.setState({
            downArrow: HoverDownArrow
        })
    }
    MouseLeave = () => {
        this.setState({
            downArrow: DownArrow
        })
    }
    upMouseHover = () => {
        this.setState({
            upArrow: HoverUpArrow
        })
    }
    upMouseLeave = () => {
        this.setState({
            upArrow: UpArrow
        })
    }
    ShowDatas = () => {
        this.setState({
            //showDataCount : this.state.showDataCount + 1
            showAllDatas: true,
            showUpArrow: true
        })
    }
    HideDatas = () => {
        this.setState({
            //showDataCount : this.state.showDataCount + 1
            showAllDatas: false,
            showUpArrow: false
        })
    }
    expandMore = () => {
        expand: !this.state.expand
    }
    approveMandate = (MandateId, UCC, isLoading) => {
        if (!isLoading) {
            let mandateList = this.props.allProfileDetails
            const elementsIndex = this.props.allProfileDetails.mandateDetails.findIndex(
                (item) => item.MandateId === MandateId
            );
            let newArray = [...this.props.allProfileDetails.mandateDetails];
            newArray[elementsIndex] = {
                ...newArray[elementsIndex],
                isLoading: true,
            };
            mandateList.mandateDetails = newArray;
            this.props.updateMandateStatus(mandateList)

            MandateStatusCheck(MandateId, UCC).then((data) => {
                if (data.status === "S") {
                    if (data.data[0].status.toLowerCase() == "registered by member" || data.data[0].status.toLowerCase() == "MANDATE REGISTRATION DONE SUCCESSFULLY".toLowerCase() || data.data[0].status.toLowerCase() == 'WAITING FOR CLIENT AUTHENTICATION'.toLowerCase()) {

                        MandateRedirection(MandateId, UCC).then((res) => {

                            //  
                            if (res.status === "S") {
                                openRequestedSinglePopup(res.data)
                                newArray[elementsIndex] = {
                                    ...newArray[elementsIndex],
                                    isLoading: false,
                                };
                                mandateList.mandateDetails = newArray;
                                this.props.updateMandateStatus(mandateList)

                            } else {
                                newArray[elementsIndex] = {
                                    ...newArray[elementsIndex],
                                    isLoading: false,

                                };
                                mandateList.mandateDetails = newArray;
                                this.props.updateMandateStatus(mandateList)
                                this.setState({
                                    open: true,
                                    message: res.message,
                                    severity: "warning",
                                })
                            }
                        })
                    } else {
                        newArray[elementsIndex] = {
                            ...newArray[elementsIndex],
                            isLoading: false,
                            isMandateNotApproved: false
                        };
                        mandateList.mandateDetails = newArray;
                        this.props.updateMandateStatus(mandateList)
                        this.setState({
                            open: true,
                            message: data.data[0].status,
                            severity: "warning",

                        })
                    }
                } else {
                    newArray[elementsIndex] = {
                        ...newArray[elementsIndex],
                        isLoading: false,

                    };
                    mandateList.mandateDetails = newArray;
                    this.props.updateMandateStatus(mandateList)
                    this.setState({

                        open: true,
                        message: data.message,
                        severity: "warning",

                    })
                }
            })

        }

    }
    markAsPrimary = () => {

        this.setState({ markasprimaryloader: true })
        MarkAsPrimarydetails(this.state.markasprimarydetails.ID, true, this.props.logindetails.ClientID).then((res) => {
            if (res.status === "S") {
                this.setState({
                    markasprimaryloader: false,
                    open: true,
                    message: res.message,
                    severity: "success",
                    markAsPrimary: false
                })
                this.props.getMarkAsDefault(this.state.markasprimarydetails.ID, !this.state.markasprimarydetails.IsDefault)
            } else {
                this.setState({ markasprimaryloader: false, open: true, message: res.message, severity: "warning", markAsPrimary: false })

            }
        })
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    }
    handleArrowClick = (index) => {
        this.setState(prevState => ({
            showContent: prevState.showContent === index ? null : index
        }));
    };
    //end
    render() {

        try {
            const { classes } = this.props;
            return (<ErrorBoundary>
                <div>
                    <div >
                        <div className={classes.marginAlignmenHeading}>
                            <Text label="DP Details" />
                        </div>
                        <div  >
                            {/* {console.log(this.props.dpDetails)} */}
                            {this.props.isLoading ? <Loader page="tablerow" /> :
                                this.props.dpDetails != "NA" && this.props.dpDetails.length != 0 ? this.props.dpDetails.map((item, index) => {

                                    return (
                                        <div key={index}>
                                            <div style={{display:'flex',flexDirection:'column',gap:5}} >
                                                <div style={{}} className={classes.bankdetailsCardFirstRow}>
                                                    <Text label={label.dematDetails.DpID} text={item.DPID} page={"normal"} />
                                                    <Text label={label.dematDetails.ClientID} text={item.ClientID} page={"normal"} />
                                                    <Text label={label.dematDetails.Depository} text={item.DPCode || labels.messages.NA} page={"normal"} />
                                                    <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-end', alignItems: 'center   ' }}>
                                                        {this.state.showContent === index ?
                                                            <ExpandLessIcon onClick={() => { this.handleArrowClick(index) }} /> :
                                                            <KeyboardArrowDownIcon onClick={() => { this.handleArrowClick(index) }} />}
                                                    </div>

                                                    {/* <Text label={label.dematDetails.Depositary} text={typeof (item.IFSCCode) != 'object' ? item.IFSCCode : labels.messages.NA} page={"normal"} /> */}
                                                    {/* <div onClick={() => { this.expandMore() }} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-end', alignItems: 'center   ' }}>
                                                        <BiChevronDown style={{ width: 25, height: 25 }} />
                                                    </div> */}
                                                </div>

                                                {this.state.showContent === index &&
                                                    this.props.nomineeDetails!='NA'&& this.props.nomineeDetails.filter((val) => val.DematID === item.DematID
                                                    ).map((demo, index) => {
                                                        return (
                                                            <div style={{ marginTop: 0, padding:'7px 0px', boxShadow: "0 0 5px 0 rgb(19 89 161 / 6%)", borderRadius: 8 }}>
                                                                <div className={classes.branchNameDiv}>
                                                                    <div className={classes.nomineeStyle}>
                                                                        <Text label={label.dematDetails.Nominee + ' ' + (index + 1)} />
                                                                    </div>
                                                                </div>

                                                                <div xs={12} className={classes.nomineeDetail}>
                                                                    <div xs={12} className={classes.nomineeGrid}>
                                                                        <div xs={4} className={classes.nomineeName}>
                                                                            <Text page={'normal'} label={'Name'} />
                                                                            <Text page={'normal'} text={demo.NomineeName} />
                                                                        </div>
                                                                        <div xs={4} className={classes.nomineeRelation}>
                                                                            <Text page={'normal'} label={label.dematDetails.Relationship} />
                                                                            <Text page={'normal'} text={'Brother'} />
                                                                        </div>
                                                                        <div xs={4} className={classes.nomineeAllocation}>
                                                                            <Text page={'normal'} label={'% Allocation'} />
                                                                            <Text page={'normal'} text={demo.Allocation} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                                {/* <div className={classes.bankdetailsCardFirstRow}>
                                                        <Text text={item.AccountType} page={"normal"} />
                                                        <Text text={item.BankAccountNumber} page={"normal"} />
                                                        <Text text={typeof (item.IFSCCode) != 'object' ? item.IFSCCode : labels.messages.NA} page={"normal"} /> */}
                                                {/* <Text  text={typeof (item.IFSCCode) != 'object' ? item.IFSCCode : labels.messages.NA} page={"normal"} /> */}
                                                {/* </div> */}
                                            </div>
                                            <div className={classes.cardSpareaterLine}>
                                            </div>
                                        </div>


                                    )
                                }) : <div className={classes.noDatastyle}  ><NoData /></div>}

                        </div>
                    </div>

                    {/* <div>
                        <div >

                            <div className={classes.addBankbtn} style={{ marginTop: "5%" }}>
                                <div className={classes.outlinebtn}>
                                   
                                    <OutlinedButtons disabled={this.props.Ucc!="NA"?false:true} text={"Add Bank"} onClick={this.handleClick} />
                                   
                                    <Addbankpopup accountno={this.props.accountno} UCC={this.props.Ucc} open={this.state.openbankpopup} onClose={this.handlebankclose} />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {/* deletepopup */}
                    <Deletepopup onClick={() => { this.markAsPrimary() }} loader={this.state.markasprimaryloader} header={"Mark As Primary"} open={this.state.markAsPrimary} text={"Are you sure you want to change this Bank as your default Bank?"} onClose={() => { this.setState({ markAsPrimary: false }) }} />
                    {/* mandatepopup */}
                    {/* {this.state.popupOpen &&
                        <Popup open={this.state.popupOpen}
                            close={this.closePopup}
                            popupName={"profile"}
                            accountno={this.props.accountno}
                            UCC={this.props.Ucc}
                            mainName={'sip&onetime'}
                            bankname={this.state.bankname}
                            bankid={this.state.bankid}
                            flag={"profile"}
                            branchName={this.state.branchName}
                            AccountType={this.state.BankAccounttype}
                            accountnumber={this.state.accountnumber}
                            ifsc={this.state.iFSCCode}
                            mandateResponseCallBack={this.showBankDetails}
                            bankmandateId={this.state.bankMandateID}

                        />
                    } */}
                    <CommonSnackbar severity={this.state.severity} handlesnanckClose={this.handlesnanckClose} open={this.state.open} message={this.state.message} />
                </div>
            </ErrorBoundary >
            );
        } catch (err) {
            // 
            // console.log(err)
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => ({
    login: state.Login.loginError,
    dpDetails: replaceNull(state.ProfileDetails.AllEquityProfileDetails.table2, "NA"),
    nomineeDetails: replaceNull(state.ProfileDetails.AllEquityProfileDetails.table4, "NA"),
    mandateDetails: state.ProfileDetails.AllProfileDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.mandateDetails != "NA" ? state.ProfileDetails.AllProfileDetails.mandateDetails : [] : [],
    logindetails: state.Login.logindetails,
    allProfileDetails: state.ProfileDetails.AllProfileDetails,
    BankList: state.Dropdown.dropdowndetails.banks != null ? state.Dropdown.dropdowndetails.banks.length != 0 ? state.Dropdown.dropdowndetails.banks.map((item) => {
        return { "label": item.BankName, "value": item.BankID }
    }) : [] : [],
    isLoading: state.ProfileDetails.isEquityProfileDetailsLoading,
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ updatebankdetails, updateMandateStatus, getSearchBranchDetails, getMarkAsDefault }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(EquityDematDetails))
