import Networking from '../../utils/api/apiaccess';
import labels from '../../utils/constants/labels';
import apiurl from '../url/apiurl';
var axios = require('axios');
function updateProcessStatus(batchNo) {

    return Networking.GetApi(`${apiurl.updateProcessStatus}?batchNumber=${batchNo}`, "",labels.apiKey.apiKeys).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });

}
export default updateProcessStatus
