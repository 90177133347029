import * as types from "../../constants/actionsType";
import getLoginDetails from "../../../api/login/loginapi";
import Signup from "../../../api/login/signup";
import secureStorage from "../../../utils/securestorage";
import labels from "../../../utils/constants/labels";
import moment from "moment";
import { changeColor } from "../../../utils/colors/colors";
import { Encryption, replaceNull } from "../../../utils/commonfunction";
import getL5report from "../../../api/L5report/L5report";
import getFamilyDetails from '../../../api/login/familydetails'
import { Asset } from "../../../utils/mainasset";
export function getLogin(email, password, history, successResponse, failureResponse, response) {
  return (dispatch) => {
    getLoginDetailsAction(dispatch, email, password, history, successResponse, failureResponse, response);

  };
}
export function getFamily(ClientID) {
  return (dispatch) => {
    dispatch({
      type: types.EMPTY_FAMILY_DETAILS,
      payload: [],
    })
    dispatch({
      type: types.LOADER_FAMILY_DETAILS,
      payload: true,
    })
    getFamilyDetails(ClientID).then((res) => {
      let response = replaceNull(res, "NA")
      dispatch({
        type: types.LOADER_FAMILY_DETAILS,
        payload: false,
      })
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: 1, api: "FamilyDetails" }],
      });
      if (response.status == "S" && response.data != "NA") {
        dispatch({
          type: types.FAMILY_DETAILS,
          payload: response.data,
        })
      } else {
        dispatch({
          type: types.EMPTY_FAMILY_DETAILS,
          payload: [],
        })
      }
    })
    // dispatch({
    //   type: types.GET_PROFITLOSS_DATA,
    //   payload: Summary,
    // })
  };
}
export const clearLoader = () => {
  return {
    type: types.CLEAR_LOADER
  }
}
export const closeMsgAction = () => {
  return {
    type: types.HIDE_MSG,
  };
};
export const ResetPreventAPICallAction = () => {
  return {
    type: types.RESET_PREVENT_API_CALL,
    payload: [],
  };
};
export const storeLoginData = (data) => {
  return {
    type: types.LOGIN,
    payload: data,
  };
};
export const closeLogin = (data) => {
  return {
    type: types.LOGIN_FLAG,
    payload: data,
  };
};
export const backupAPIStatus = (data) => {
  return {
    type: types.BACK_UP_PREVENT_API_CALL,
    payload: data,
  };
};
export const updateLoginTime = () => {
  return {
    type: types.UPDATE_LOGIN_TIME,
    payload: "",
  };
};
export const getEmptyAction = (params, enableLoader, flag) => {
  return (dispatch) => {
    
    dispatch({
      type: types.EMPTY_ASSET_PORTFOLIO_ALLOCATION,
      payload: []
    });
    if (params === "logout" || params === "admin") {
      dispatch({
        type: types.EMPTY_LOGIN,
        payload: "",
      });
      dispatch({
        type: types.EMPTY_DASHBOARD_ASSET,
        payload: []
      })
      dispatch({
        type: types.EMPTY_L5REPORT,
        payload: [],
      });
      dispatch({
        type: types.EMPTY_KYC_PROFILE_DETAILS,
        payload: [],
      });
      dispatch({
        type: types.EMPTY_PROFILE_DETAILS,
        payload: [],
      });
      dispatch({
        type: types.EMPTY_KYC,
        payload: [{ status: "" }],
      });
    }

    dispatch({
      type: types.EMPTY_REALESTATE_PORTFOLIO_X_RAY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_ALERT,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_NPS_LIST,
      payload: []
    });
    dispatch({
      type: types.EMPTY_AIF_LIST,
      payload: []
    });
    dispatch({
      type: types.EMPTY_TRACK_DETAILS,
      payload: []
    });
    dispatch({
      type: types.EMPTY_REAL_ESTATE_LIST,
      payload: []
    });
    dispatch({
      type: types.EMPTY_BULLION_LIST,
      payload: []
    });
    dispatch({
      type: types.RESET_PREVENT_API_CALL,
      payload: [],
    });
    if (params != "report") {
      dispatch({
        type: types.STORE_GST_BANK_DETAILS,
        payload: [],
      });

      dispatch({
        type: types.RIA_ONLINE_PAYMENT_COMPLETE,
        payload: [],
      });

      dispatch({
        type: types.GET_PURCHASED_PLAN,
        payload: [],
      });

      dispatch({
        type: types.RIA_FLOW_OFFLINE_PAYMENT,
        payload: [],
      });

      dispatch({
        type: types.RIA_FLOW_ONLINE_PAYMENT,
        payload: [],
      });
      dispatch({
        type: types.RIA_QUESTION_RESPONSE,
        payload: [],
      });

      dispatch({
        type: types.ESIGN_DOWNLOAD,
        payload: [],
      });
      dispatch({
        type: types.INITIALIZE_ESign,
        payload: [],
      });
      dispatch({
        type: types.RISK_QUESTION,
        payload: [],
      });
      dispatch({
        type: types.RISK_SCORE,
        payload: [],
      });

      dispatch({
        type: types.EMPTY_CART,
        payload: [],
      });

      dispatch({
        type: types.EMPTY_FILTERDATA,
        payload: [],
      });

      // added by yogitha
      dispatch({
        type: types.EMPTY_TRACK_EQUITY_DETAILS,
        payload: [],
      });
      dispatch({
        type: types.EMPTY_TRACK_EQUITY_IMPORT_DETAILS,
        payload: [],
      });

      dispatch({
        type: types.ADMIN,
        payload: "",
      });
    }
    dispatch({
      type: types.EMPTY_RUNNINGSIP,
      payload: [],
    });

    dispatch({
      type: types.EMPTY_PORTFOLIO,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_ACCOUNT_STATEMENT,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_DOWNLOAD_REPORT,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_UPCOMMING_SIP,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_PORTFOLIO_REPORT,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_ASSET_ALLOCATION,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_PORTFOLIOXRAY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_ELSS,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_EXIT_LOAD,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_MANDATE_REPORT,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_MARKETCAP_ALLOCATION,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_PRODUCT,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_SHORT_TERM,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_SUMMARY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_PROFITLOSS,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_TRANSACTION,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_CARD,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_CAPITAL_GAIN,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_SIP_TRACKER,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_XRAY_WITH_REDEMPTION,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_BONDS_MARKET_CAP_DEBT,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_BONDS_MARKET_CAP_EQUITY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_FD_MARKET_CAP_DEBT,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_FD_MARKET_CAP_EQUITY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_GB_MARKET_CAP_DEBT,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_GB_MARKET_CAP_EQUITY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_BONDS_SUMMARY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_FD_SUMMARY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_GB_SUMMARY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_REALESTATE_SUMMARY,
      payload: [],
    });

    dispatch({
      type: types.EMPTY_BONDS_PORTFOLIO_X_RAY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_FD_PORTFOLIO_X_RAY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_GB_PORTFOLIO_X_RAY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_BONDS_TRANSACTION,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_FD_TRANSACTION,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_GB_TRANSACTION,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_BONDS_PROFIT_LOSS,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_FD_PROFIT_LOSS,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_GB_PROFIT_LOSS,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_EQUITY_SIP_TRACKER,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_EQUITY_ACCOUNT_STATEMENT,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_BONDS_ACCOUNT_STATEMENT,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_EQUITY_PORTFOLIOXRAY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_EQUITY_PROFIT_LOSS,
      payload: [],
    });

    dispatch({
      type: types.EMPTY_EQUITY_MARKETCAP_ALLOCATION,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_EQUITY_ASSET_ALLOCATION,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_EQUITY_XRAY_WITH_REDEMPTION,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_EQUITY_PORTFOLIO_REPORT,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_EQUITY_RUNNING_SIP,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_EQUITY_TRANSACTION,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_EQUITY_CAPITAL_GAIN,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_REAL_ESTATE_LIST,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_BULLION_LIST,
      payload: [],
    })
    dispatch({
      type: types.EMPTY_EQUITY_EXIT_LOAD,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_EQUITY_SHORT_TERM,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_EQUITY_ELSS,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_EQUITY_SUMMARY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_EQUITY_INSTRUMENT,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_DEBT_INSTRUMENT,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_EPF_LIST,
      payload: []
    })
    dispatch({
      type: types.EMPTY_EQUITY_PORTFOLIO,
      payload: [],
    });
    dispatch({
      type: types.LOADER_EQUITY_BROKER,
      payload: false,
    });
    dispatch({
      type: types.EMPTY_TRANSACTION_TYPE_DROPDOWN,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_AIF_PROFITLOSS,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_NPS_PROFIT_LOSS,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_INSURANCE_PROFIT_LOSS,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_INSURANCE_PORTFOLIO_X_RAY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_NPS_PORTFOLIO_X_RAY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_MATURITY_TRACKER,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_MATURITY_TRACKER_BONDS,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_AIF_PORTFOLIO_X_RAY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_AIF_TRANSACTION,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_MLD_PORTFOLIO_X_RAY,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_MLD_TRANSACTION,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_REALESTATE_TRANSACTION,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_NPS_TRANSACTION,
      payload: [],
    });
    dispatch({
      type: types.EMPTY_INSURANCE_TRANSACTION,
      payload: [],
    });

    if (params != "date" && params != "l5" && params != "family") {
      dispatch({
        type: types.EMPTY_DROPDOWN,
        payload: [],
      });
    }

    //


  };
};
function getLoginDetailsAction(dispatch, email, password, history, successResponse, failureResponse, response) {

  dispatch({
    type: types.LOADER_CONTINUE,
    payload: true,
  });
  //  console.log(response)
  // getLoginDetails(email, password)
  //   .then(async (response) => {
  // secureStorage.removeItem('invalidAuth')
  let data = replaceNull(response, "NA")
  dispatch({
    type: types.LOADER_CONTINUE,
    payload: false,
  });

  if (data.status == "S") {
    dispatch({
      type: types.LOADER_CONTINUE,
      payload: false,
    });
    if (data.data[0].length === 0) {

      dispatch({
        type: types.LOGIN_FLAG,
        payload: false,
      });
    } else {
      dispatch({
        type: types.LOGIN_FLAG,
        payload: true,
      });
    }
    secureStorage.setItem("Source", data.data[0]?.Source || Asset.source)
    secureStorage.setItem(`authToken-${data.data[0]?.SessionID}`, `${data.data[0]?.AuthToken}`)
    secureStorage.setItem(`nodeToken-${data.data[0]?.SessionID}`, `${data.token}`)
    secureStorage.setItem("sessionID", data.data[0]?.SessionID)
    secureStorage.setItem("user", data.data[0]);
    if (data.permission == "NA") {
      secureStorage.removeItem("permission");
    } else {
      secureStorage.setItem("permission", replaceNull(data.permission, "NA")[0]);
    }
    if (
      data.companieMaster == "NA"
    ) {
      secureStorage.removeItem("company");
    } else {
      secureStorage.setItem("company", replaceNull(data.companieMaster, "NA")[0]);

      window.globalConfig.color = { ...window.globalConfig.color, PRIMARY: replaceNull(data.companieMaster, "NA")[0].ClientPrimaryColor, BROWN: replaceNull(data.companieMaster, "NA")[0].ClientSecondaryColor, RosyBrown: replaceNull(data.companieMaster, "NA")[0].ClientTertiaryColor }
      window.globalConfig.companyDetails = { ...window.globalConfig.companyDetails, companyName: replaceNull(data.companieMaster, "NA")[0].NameOfCompany, icon: replaceNull(data.companieMaster, "NA")[0].Icon }
      secureStorage.setItem("colors", window.globalConfig.color)

    }
    secureStorage.setItem("mainclientid", data.data[0].ClientID)
    let L5Report = []
    getL5report(data.data[0].ClientID).then(async (res) => {
      dispatch({
        type: types.EMPTY_L5REPORT,
        payload: []
      });
      if (res.status == "S") {

        L5Report = res.data == null || res.data == undefined ? [] : res.data
        await dispatch({
          type: types.L5REPORT,
          payload: L5Report
        });
      } else {
        L5Report = []
        dispatch({
          type: types.L5REPORT,
          payload: []
        });
      }
    })
    data.L5ID = L5Report?.length == 1 ? L5Report[0]?.L5ID : 0

    dispatch({
      type: types.LOGIN,
      payload: replaceNull(data, "NA"),
      email,
      password,
    });

    dispatch({
      type: types.RESET_PREVENT_API_CALL,
      payload: [],

    });
    const currentTime = new Date().getTime();  //current unix timestamp
    const execTime = new Date().setHours(9, 30, 0, 0);  //API call time = today at 20:00
    let timeLeft;
    if (currentTime < execTime) {

      timeLeft = execTime - currentTime;
    } else {

      timeLeft = execTime + 86400000 - currentTime
    }
    try {
      // console.log(successResponse,L5Report)
      if (successResponse == undefined) {
        if (window.globalConfig.isDashboardAllowed) {
          history.push(labels.Path.Dashboard);
        } else {
          history.push(labels.Path.Portfolio);
        }

      } else {
        if (successResponse.current == undefined) {
          setTimeout(function () {
            successResponse(data.data[0].SessionID, data.data[0].ClientID, moment().format("DD MMM YYYY"), L5Report.length == 1 ? L5Report[0].L5ID : 0, "none")
          }, timeLeft);
          setTimeout(() => {
            successResponse(data.data[0].SessionID, data.data[0].ClientID, moment().format("DD MMM YYYY"), L5Report.length == 1 ? L5Report[0].L5ID : 0, "none")
          }, 1000);

        } else {
          setTimeout(() => {
            successResponse.current(data.data[0].SessionID, data.data[0].ClientID, moment().format("DD MMM YYYY"), L5Report.length == 1 ? L5Report[0].L5ID : 0, "none")
          }, 1000);
          setTimeout(function () {
            successResponse.current(data.data[0].SessionID, data.data[0].ClientID, moment().format("DD MMM YYYY"), L5Report.length == 1 ? L5Report[0].L5ID : 0, "none")
          }, timeLeft);
          if (window.globalConfig.isDashboardAllowed) {
            history.push(labels.Path.Dashboard);
          } else {
            history.push(labels.Path.Portfolio);
          }
        }
      }
    } catch (err) {
      console.log(err)
    }


  } else if (data.status == "P") {
    secureStorage.setItem("UserID", data.userID)
    dispatch({
      type: types.STEP_SIGNUP,
      payload: replaceNull(data.data, "NA"),
      email,
      password,
    });
    dispatch({
      type: types.LOGIN_FLAG,
      payload: true,
    });
    dispatch({
      type: types.LOADER_CONTINUE,
      payload: false,
    });
    history.push({
      pathname: labels.Path.Signup,
      state: {
        data: 4,

        name: data.data.username,
      },
    });
  } else if (data.status == "R") {
    dispatch({
      type: types.UPDATE_RESET_FLAG,
      payload: false,
    });
    dispatch({
      type: types.LOGIN_FLAG,
      payload: false,
    });

    dispatch({
      type: types.LOADER_CONTINUE,
      payload: false,
    });
    dispatch({
      type: types.LOGIN_RESET,
      payload: true,
    });
    // if (successResponse.current == undefined) {

    //   successResponse("R")

    // } else {
    //   successResponse("R")

    // }
  } else if ((data.status = "N")) {
    if (failureResponse == undefined) {
      dispatch({
        type: "NETWORK",
        payload: data.message,
      });

      dispatch({
        type: types.LOADER_CONTINUE,
        payload: false,
      });
      dispatch({
        type: types.LOGIN_FLAG,
        payload: false,
      });
    } else {
      failureResponse(data)
    }

  } else {
    if (failureResponse == undefined) {
      dispatch({
        type: types.LOGIN_FAILED,
        payload: data.message,
      });
    } else {
      failureResponse(data)
    }

    dispatch({
      type: types.LOGIN_FLAG,
      payload: false,
    });

    dispatch({
      type: types.LOADER_CONTINUE,
      payload: false,
    });
  }
  // })
  // .catch((error) => {
  //   if (failureResponse != undefined) {
  //     let data = {}
  //     data.message = labels.messages.apierror
  //     failureResponse(data)
  //   }
  //   dispatch({
  //     type: types.LOADER_CONTINUE,
  //     payload: false,
  //   });
  //   dispatch({
  //     type: types.LOGIN_FLAG,
  //     payload: false,
  //   });
  // });
}
export const updateScroll = (data) => {
  return {
    type: types.UPDATE_SCROLL,
    payload: data
  };
}
export const loginsession = (user, familyDetails) => {
  return {
    type: types.LOGIN_DATA,
    user,
    familyDetails
  };
};
export const uccSelection = (ucc) => {

  return {
    type: types.UCC_ACCOUNT,
    payload: ucc
  };
};
export const storeFormNo = (formNo) => {
  return {
    type: types.STOREKYCFORMNO,
    payload: formNo
  };
};
export const accountselection = (data) => {

  return {
    type: types.LOGIN_ACCOUNT_SELECTION,
    payload: data,
  };
};
export const clearLoginData = (user) => {
  return {
    type: types.CLEAR_LOGINDATA,
    user,
  };
};
export const setNewPassword = (payload) => {
  return {
    type: types.UPDATE_RESET_FLAG,
    payload,
  };
};
export const dateChage = (date) => {
  return {
    type: types.DATE_CHANGE,
    date,
  };
};
export const clientIDchange = (clientID, FamilyName) => {
  return {
    type: types.CLIENTID_CHANGE,
    clientID,
    FamilyName,
  };
};
export const TradeL5IDDetails = (L5ID) => {
  return {
    type: types.UPDATE_TRADE_L5ID,
    L5ID,
  };
};
export const TradeUCCDetails = (UCC) => {
  return {
    type: types.UPDATE_TRADE_UCC_CODE,
    UCC,
  };
};
export const SignupAction = (email, mobileno, panunumber, password) => {
  return (dispatch) => {
    dispatch({
      type: types.EMPTY_SIGN_UP,
      payload: [],
    });
    dispatch({
      type: types.LOADER_SIGNUP,
      payload: true,
    });
    Signup(email, mobileno, panunumber, password).then((data) => {
      dispatch({
        type: types.LOADER_SIGNUP,
        payload: false,
      });
      dispatch({
        type: types.SIGN_UP,
        payload: data,
      });
    });
  };
};

