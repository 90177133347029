import React, { Component } from 'react'
import labels from '../../utils/constants/labels';
import Paper from '@material-ui/core/Paper';
import { Redirect } from 'react-router';
import Grid from '@material-ui/core/Grid';
import OutlineButton from '../payment/button'
import { NegativeFinding } from '../../utils/commonfunction';
import EFBarchart from './barchart'
import Donut from './donutchart'
import TreeTable from '../expandable-table/expandabletable'
import SwitchDoubleDonut from './switchdoubledonut'
import '../../utils/stylesheets/layout/layout.css'
import { connect } from 'react-redux';
import { getSummaryDetails, GetSummary } from '../../redux/actions/summary/summaryaction'
import { getAllInstrumentDetails } from '../../redux/actions/summary/switchdonutaction'
import { bindActionCreators } from 'redux';
import NoData from '../nodata/nodata'
import Loader from '../../components/loader/loader'
import { withStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors/colors';
import CommonHeader from '../../components/report_common_header/report_common_header'
var summaryData = [
]
const styles = theme => ({
  flexColumn: {
    // display: "flex",
    flexDirection: "column"
  },
  flexRow: {
    display: "flex",
    flexDirection: "row"
  },
  halfGridSeperation: {
    display: "grid",
    gridTemplateColumns: "48.5% 48.5%",
    gap: 30,
    marginTop: 50,
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "auto",
    },
  },
  assetAllocationContainer: {
    display: "grid", gridTemplateColumns: "37% 26% 22% 15%",
    padding: 17,
    fontSize: "large",
    fontWeight: "500",
    alignItems: "center",
    "& .dataOne": {
      display: "grid",
      gridTemplateColumns: "9% 91%"
    },
    "& .dataTwoSpecialChar": {
      fontSize: "1rem",
      color: colors.PRIMARY_BORDER
    },
    "& .dataTwo": {
      alignItems: "center",
      gap: 2
    },
    overflowWrap: "break-word",
    [theme.breakpoints.down('920',)]: {
      gridTemplateColumns: "50% 50%",
      gridTemplateAreas: `"dataOne dataTwo"
      "dataThree dataFour"`
    }
  },
  subAssetAllocationContainer: {
    display: "grid", gridTemplateColumns: "37% 24% 24% 15%",
    padding: "15px 18px 15px 41px",
    [theme.breakpoints.down('920',)]: {
      gridTemplateColumns: "50% 50%",
      gridTemplateAreas: `"subDataOne subDataTwo"
      "subDataThree subDataFour"`,
      "& .subDataOne": {
        overflowWrap: "break-word"
      }
    }
  },
  firstGridSeperation: {
    display: "grid",
    gridTemplateColumns: "40% 60%",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "auto",
    },
    switchHeader: {
      [theme.breakpoints.down("920")]: {
        marginBottom: "18%"
      }
    }
  }
})
class SummaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      summaryData: [],
      assetData: [],
      barChartData: [],
      data: [],
      btnLabel: "Show More",
      Flag: "More",
      clickcount: 0
    }
  }
  handleClick = (flag) => {
    this.setState({ clickcount: this.state.clickcount + 1 })
    if (this.state.clickcount === 0) {
      let ToptwentyData = []
      ToptwentyData = this.props.barChartData
      let summary = ToptwentyData
      this.setState({
        data: summary,
        Flag: "Less",
        btnLabel: "Show Less"
      })
    }
    else {
      if (this.props.barChartData != undefined && this.props.barChartData != null) {
        let TopFiveData = this.props.barChartData.slice(0, 5)
        let summary = TopFiveData
        this.setState({
          data: summary,
          Flag: "More",
          btnLabel: "Show More",
          clickcount: 0
        })
      }
    }
  }
  componentDidMount() {
    if (this.props.barChartData != undefined && this.props.barChartData != null && this.props.barChartData.length != 0) {
      let TopFiveData = []
      if (this.props.barChartData.length < 6) {
        TopFiveData = this.props.barChartData
      } else {
        TopFiveData = this.props.barChartData.slice(0, 5)
      }

      let summary = TopFiveData
      this.setState({
        data: summary
      })
    }
    if (this.props.categorySummary.length != 0) {
      this.setState({
        assetData: this.props.categorySummary.map((item) => {
          return {
            dataOne: item.category, dataTwo: NegativeFinding(item.amount, "", "", "", "rupee"), dataThree: item.categoryPercentage, dataFour: item.holdingPercentage, isExpand: item.isExpand, showCheckBox: true, color: item.color, subData: item.rowitem.map((value) => {
              return { dataOne: value.category, dataTwo: NegativeFinding(value.amount, "", "", "", "rupee"), dataThree: NegativeFinding(value.categoryPercentage, '', "", "", "percentage"), dataFour: value.holdingPercentage }
            })
          }
        })
      })
    }
  }


  componentDidUpdate(prevprops) {
    const { barChartData, categorySummary } = this.props
    if (prevprops.barChartData != barChartData) {
      if (barChartData != undefined && barChartData != null && barChartData.length != 0) {
        let TopFiveData = []

        if (barChartData.length < 1) {
          TopFiveData = barChartData

        } else {
          TopFiveData = barChartData.slice(0, 5)
        }
        let summary = TopFiveData
        this.setState({
          data: summary,
          Flag: "More",
        })
      }
    }
    if (prevprops.categorySummary != categorySummary) {
      this.setState({
        assetData: categorySummary.map((item) => {
          return {
            dataOne: item.category, dataTwo: NegativeFinding(item.amount, "", "", "", "rupee"), dataThree: item.categoryPercentage, dataFour: item.holdingPercentage, isExpand: item.isExpand, showCheckBox: true, color: item.color, subData: item.rowitem.map((value) => {
              return { dataOne: value.category, dataTwo: NegativeFinding(value.amount, "", "", "", "rupee"), dataThree: NegativeFinding(value.categoryPercentage, '', "", "", "percentage"), dataFour: value.holdingPercentage }
            })
          }
        })
      })
    }
  }
  render() {
    const { classes } = this.props
    try {
      return (
        <div style={{ marginBottom: "3%" }}>
          {/* {console.log(this.props.isLoadingportfolioSummary)} */}
          <CommonHeader page={"noHeader"} showfilter={false} />
          <div className={classes.flexColumn} >
            <div className={classes.flexRow}>
              <h2 className="summaryChartHeader" style={{ marginTop: "2vw" }}> {this.props.CategoryAllocation} </h2>
            </div>
            <div className={classes.firstGridSeperation}>
              {this.props.isLoadingportfolioChart == false ? (
                <Donut className="chartDesign" {...this.props} selectionField={this.props.report == "family" ? "productName" : "category"} flag={this.props.flag} summaryData={this.props.chart} data={this.props.categorySummary} />
              ) : <div><Loader page="chartnormal" /></div>}
              <div style={{ marginTop: "2em", maxHeight: "450px", position: "relative" }}>
                <TreeTable search={labels.currency.rupeesymbol}
                  grid={{ mainGrid: classes.assetAllocationContainer, subGrid: classes.subAssetAllocationContainer }}
                  summaryData={this.props.categorySummary}
                  collapseTable={this.props.portfolioSummaryFamily}
                  loader={this.props.isLoadingportfolioSummary}
                  tableHeader={this.props.tableHeader} flag={this.props.flag}
                  data={this.state.assetData} grandtotal={this.props.grandtotal} />
              </div>

            </div>
            {this.props.showChart && <div className={classes.halfGridSeperation}>
              <div className={classes.flexColumn} style={{ boxShadow: "0 0 6px 0 rgb(19 89 161 / 10%)", borderRadius: 10, height: 480, overflowX: "hidden" }}>
                <h4 style={{ paddingLeft: "1.5rem", width: '100%' }} className={["summaryHeader", classes.switchHeader].join(" ")}> {this.props.splitByInstrument} </h4>

                <SwitchDoubleDonut EquityLoading={this.props.EquityLoading} DebtLoading={this.props.DebtLoading} Deptdata={this.props.Deptdata} summaryData={this.props.DonutData} flag={this.props.flag} />

              </div>
              <div className={classes.flexColumn} style={{ justifyContent: "center", width: "100%", borderRadius: 10, boxShadow: "0 0 6px 0 rgb(19 89 161 / 10%)", borderRadius: '10px', height: this.state.Flag === "Less" ? '480px' : '480px', maxHeight: 950, }}>
                <h4 style={{ marginLeft: '2%', }} className="summaryHeader"> {this.props.EquityFundSector} </h4>
                {this.props.isLoadingcategorySummary == false ?
                  (<EFBarchart data={this.state.data} Flag={this.state.Flag} />) : <Loader page="barchart" />}



                {this.props.barChartData.length > 6 && <div style={{ marginBottom: 10 }}><OutlineButton onClick={() => this.handleClick(this.state.Flag)} text={this.state.btnLabel}></OutlineButton></div>

                }
              </div>
            </div>}
          </div>
        </div>

      );
    }
    catch (err) {
      // console.log(err)
      return <Redirect to={labels.Path.NotFound} />
    }
  }
}
const mapStateToProps = state => ({
  // sessionID: state.Login.sessionID,
  // clientID: state.Login.logindetails.ClientID,
  // userID: state.Login.userID,
  // date: state.Login.date,
  // barChartData: state.Summary.summary,
  // chart: state.Summary.chart,
  // categorySummary: state.Summary.categorySummary,
  // isLoadingcategorySummary: state.Summary.isLoadingcategorySummary,
  // DonutData: state.Summary.Donut,
  // grandtotal: state.Summary.grandtotalIV

})
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getSummaryDetails, GetSummary, getAllInstrumentDetails }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SummaryComponent))

