import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels'
import Networking from '../../utils/api/apiaccess';
function CheckDuplication(data) {

    return Networking.PostApi(ApiValues.CheckDuplication, data,labels.apiKey.apiKeys, labels.apiKey.apiKeys, true).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default CheckDuplication
