import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
//action created by dulcy
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
import apiurl from '../url/apiurl';
function getSubscriptionAutoCharge(SubscriptionID, mandateID, clientID, isAutoPay) {


    // var raw = JSON.stringify({
    //     "ID": ID, 
    //     "ClientID": ClientID,
    //     "AccountType": AccountType,
    //     "IFSCCOde": IFSCCOde,
    //     "BranchMICRCode": BranchMICRCode,
    //     "L5ID":L5ID,
    //     "BankAccountNumber": BankAccountNumber,
    //     "UserID": UserID
    // });
    let url = `${apiurl.SubscriptionAutoCharge}?subscriptionID=${SubscriptionID}&mandateID=${mandateID}&clientID=${clientID}&isAutoPay=${isAutoPay}`
    //   var config = {
    //       method: 'post',
    //       url: ApiValues.Addbank,
    //       headers: { 
    //         'Content-Type': 'application/json'
    //       },
    //       data : raw
    //     };

    //  return   axios(config)
    //     .then(function (response) {
    //     return response.data
    //     })
    //     .catch(function (error) {
    //       return{status:"F",message:labels.messages.apierror}
    //     });
    return Networking.GetApi(url, "").then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });

}
export default getSubscriptionAutoCharge
