import * as types from '../../constants/actionsType';
import getMandateApiDetails from '../../../api/mandate/mandatereport';
export function getMandateDetails(ClientID,ProductID,FamilyID,L5ID,L4ID,UCC) {
    return (dispatch) => {
        getAllMandateDetailsAction(dispatch, ClientID,ProductID,FamilyID,L5ID,L4ID,UCC);
        // dispatch({
        //   type: types.GET_PROFITLOSS_DATA,
        //   payload: Summary,
        // })
    }
}
function getAllMandateDetailsAction(dispatch, ClientID,ProductID,FamilyID,L5ID,L4ID,UCC) {
    dispatch({
        type: types.EMPTY_MANDATE_REPORT,
        payload: []
    });
    dispatch({
        type: types.LOADER_MANDATE_REPORT,
        payload: true
    });
    getMandateApiDetails(ClientID,ProductID,FamilyID,L5ID,L4ID,UCC).then((data) => {
        if (data.status == "S") {
            dispatch({
                type: types.PREVENT_API_CALL,
                payload: [{ProductID:11,api:"Mandate report"}],
              });
            dispatch({
                type: types.MANDATE_REPORT,
                payload: data.data
            });
          
        }else{
            dispatch({
                type: types.PREVENT_API_CALL,
                payload: [{ProductID:11,api:"Mandate report"}],
              });
            dispatch({
                type: types.MANDATE_REPORT,
                payload:[]
              });
           
        }

    })
        .catch((error) => {
            dispatch({
                type: types.PREVENT_API_CALL,
                payload: [{ProductID:11,api:"Mandate report"}],
              });
            dispatch({
                type: types.EMPTY_MANDATE_REPORT,
                payload: []
            });
           
        })
}
