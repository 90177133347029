// import { Repeat } from "@material-ui/icons";
import colors from "../colors/colors";

// import deco from 'enl-images/decoration/hexaMonochrome.svg';
const rootWraper = {
  display: 'flex',
  width: '100%',
  zIndex: 1,
  position: 'absolute'
};
const styles = theme => ({
mainheader:{
color:window.globalConfig.color.BLACK_PRIMARY,
fontSize:"18px",
alignItems:"flex-start",
fontWeight:"bold",
// marginLeft:"1%",
lineHeight:1.2,
textAlign:"left",
letterSpacing:"-0.00833em"
},
subheader:{

}
});
export default styles;
