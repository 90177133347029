import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
import Networking from '../../utils/api/apiaccess';
var axios = require('axios');
function getRunningSIPs(ClientID,AccountID,AsOnDate,ProductID,FamilyID,L5ID,L4ID) {
    let BaseUrl =ApiValues.RunningSip
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
     "ClientID":ClientID,
     "AccountID":AccountID,
     "AsOnDate":AsOnDate,
     "ProductID": ProductID,
     "FamilyID": FamilyID,
     "L5ID": L5ID,
     "L4ID": L4ID
    });

    return Networking.PostApi(BaseUrl, raw).then(result => { return result })
.catch(function (error) {
  return { "status": "F", "message": labels.messages.apierror }
});

}
export default getRunningSIPs
