import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';

import MessagesForm from './messagesform'
import styles from './user-jss';
import moment from "moment";
import { closeMsgAction, getLogin, SignupAction } from '../../redux/actions/login/loginaction';
import { emailValidator, passValidator, requireValidator } from '../../utils/commonfunction'
import Label from '../../utils/constants/labels'
import Grid from '@material-ui/core/Grid';
import labels from '../../utils/constants/labels';
import { numbersOnly } from '../../utils/commonfunction';
import CommonLogin from '../../components/commonLogin/common_login'
import Buttons from '../../components/custombutton/button'
import TextInput from '../../components/textinput/textinput'
import PlayStore from '../../utils/assets/playstore.png'
import AppStore from '../../utils/assets/comingsoontoappstore.png'
import AppStoreNew from '../../utils/assets/appstore.png'
import { Asset } from '../../utils/mainasset';
import VerifyOTPLoginWithEmail from '../../api/loginwithotp/VerifyOTPLoginWithEmail';
import getLoginDetails from '../../api/login/loginapi';
import OTPInputTextBox from '../otptextbox/otptextbox';
import EmailTimer from '../../components/timer/emailtimmer';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Snackbar from '../snackbar/snackbar';
import LoginWithSendOTP from '../../api/loginwithotp/loginwithotp';
import { bindActionCreators } from 'redux';
import colors from '../../utils/colors/colors';
let a = []
class LoginWithOTPForm extends React.Component { // eslint-disable-line
  _isComponentMounted = false
  constructor() {
    super();
    this.myDiv = React.createRef();
    this.state = {
      showPassword: false,
      isPasswordVisible: false,
      otp: "",
      loggedInEmail: '',
      eventDate: moment.duration().add({ minutes: 2, seconds: 0 }),
      mins: 1,
      secs: 59,
      otpTime: false,
      section: "email",
      showEmail: true,
      showPasswordField: false,
      email: "",
      password: "",
      deviceType: "",
      osType: "",
      timeStamp: "",
      timeZone: "",
      browserNameVersion: "",
      referrelUrl: "",
      emailError: '',
      passwordError: '',
      signupemail: '',
      pannumber: "",
      mobilenumber: "",
      newpassword: "",
      confirmpassword: "",
      signupemailerror: '',
      pannumbererror: "",
      mobilenumbererror: "",
      newpassworderror: "",
      confirmpassworderror: "",
      showsignup: false,
      showsetPassword: false,
      showconfirmPassword: false,
      open: false,
      signupmessage: "",
      errorMessage: "",
      page: "loginwithotp",
      loader: false,
      open: false, message: "", severity: "warning"
    }
    this.toggleSection = this.toggleSection.bind(this);
  }
  componentDidUpdate(prev, prevState) {
    const { signupmessage, signup, messagesAuth } = this.props

    if (signupmessage != prev.signupmessage) {
      this.setState({ errorMessage: signupmessage })
    }
  }
  toggleSection = () => {
    const { section, } = this.state;

    if (this.state.email.trim() != "") {
      this.setState({
        section: "otp",
        otpTime: true
      });
      this.signinwithotpsubmit()
    } else {
      this.setState({
        section: "email",
      });
    }
  };

  togglePasswordSection = () => {
    const { section } = this.state;
    const emailError = emailValidator(this.state.email);

    if (emailError) {

      this.setState({ emailError: emailError });
    } else {
      setTimeout(() => {
        this.setState({
          section: "password",
        });
      }, 50);

    }
  };

  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };
  handleMouseDownPassword = event => {
    event.preventDefault();
  };
  handleClicksetShowPassword = () => {
    const { showsetPassword } = this.state;
    this.setState({ showsetPassword: !showsetPassword });
  };
  handleClickShowconfirmPassword = () => {
    const { showconfirmPassword } = this.state;
    this.setState({ showconfirmPassword: !showconfirmPassword });
  };
  onClickChangeEmail = () => {
    this.setState({ password: "", passwordError: "", otp: "", otpError: "", section: "email" });

  }
  signinwithotpsubmit = (event) => {
    // event.preventDefault();
    const emailError = emailValidator(this.state.email);

    if (emailError) {

      this.setState({ emailError: emailError });
    } else {
      if (emailValidator(this.state.email) === '') {
        this.setState({ emailLoader: true })
        // this.props?.onSubmit(this.state.email.trim())
        LoginWithSendOTP(this.state.email).then((res) => {
          this.setState({ emailLoader: false })
          this.setState({ open: true, message: res.message, })
          if (res.status == "S") {
            // this.toggleSection();
            this.setState({ section: "otp", otpTime: true })
            this.setState({ severity: "success" })
          } else {
            this.setState({ severity: "warning" })
          }
        })
      }
      else {
        this.setState({ emailError: 'Invalid email format' });
      }

    }
  }


  Signinwithpasswordsubmit = (event) => {
    // event.preventDefault();
    const passwordError = requireValidator(this.state.password);
    const emailError = requireValidator(this.state.email);
    // if (this.state.section == "password") {
    if (passwordError || emailError) {
      this.setState({ passwordError: passwordError, emailError: emailError })
    }
    else {
      this.setState({ showsignup: false, })
      if (this.state.password.trim().length >= 7) {
        this.setState({ loader: true })
        getLoginDetails(this.state.email, this.state.password.trim()).then(async (res) => {
          this.setState({
            loader: false
          });
          await this.handleLoginResponse(res);
        })
        this.togglePasswordSection();
      } else {
        this.setState({ passwordError: labels.messages.MinimumPasswordError })
      }

    }
    // }
  }
  failure = () => {

  }
  onPasswordKeypress = (event) => {

    if (event.keyCode == 32) {
      event.preventDefault();
    }
    else {
    }
  }

  componentDidMount() {
    document.title = window.globalConfig.companyDetails.companyName
    this.props?.closeMsgAction()
    this._isComponentMounted = true
    this.myDiv.current.addEventListener('keyup', (e) => {
      if (e.key === "Enter" && this.state.page === "loginwithotp" && this.state.section == "email") {
        this.togglePasswordSection(e)
      }
      if (e.key === "Enter" && this.state.page === "loginwithotp" && this.state.section == "otp") {
        this.handleSubmit(e)
      }
      if (e.key === "Enter" && this.state.page === "loginwithotp" && this.state.section == "password") {
        this.Signinwithpasswordsubmit(e)
      }

    });
    this.myDiv.current.focus()
  }

  componentWillUnmount() {
    this._isComponentMounted = false
    this.myDiv.current.removeEventListener('keyup', (e) => {
      if (e.key === "Enter" && this.state.page === "loginwithotp" && this.state.section == "email") {
        this.togglePasswordSection(e)
      }
      if (e.key === "Enter" && this.state.page === "loginwithotp" && this.state.section == "otp") {
        this.handleSubmit(e)
      }
      if (e.key === "Enter" && this.state.page === "loginwithotp" && this.state.section == "password") {
        this.Signinwithpasswordsubmit(e)
      }

    });
  }

  forgetPassword = () => {
    const emailError = emailValidator(this.state.email);
    if (emailError == '') {
      //this.props?.GetEmail(this.state.email)
      this.props?.ResetPassword(this.state.email)
      this.setState({ emailError: "" })
    }
    else {
      //this.props?.GetEmail("F")
      this.setState({ emailError: emailError })
    }
  }
  signUp = () => {

    this.props?.history.push(Label.Path.Signup)
  }
  signIn = () => {
    this.setState({ showsignup: false, page: "loginwithotp", signupemail: "", errorMessage: "", signupemailerror: "", mobilenumbererror: "", pannumbererror: "", newpassworderror: "", confirmpassworderror: "", mobilenumber: "", pannumber: "", newpassword: "", confirmpassword: "" })
  }

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      this.submit(event)
    }
  }
  handlesnanckClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false })
  };
  handleLoginResponse = async (res) => {
    if (res.status == "F") {
      this.setState({ open: true, message: res.message, severity: "warning" })
      this.setState({ otp: "", password: "" })
    }
    if (res.status == "R") {
      this.setState({ open: true, message: res.message, severity: "warning" })

      this.props.openResetPassword(this.state.email)
    }
    if (res.status == "S" || res.status == "P") {
      await this.props.getLogin(this.state.email, this.state.password.trim(), this.props?.history, this.props?.childFunc, this.failure, res)
    }
  }
  handleSubmit = async () => {
    if (this.state.otp == "") {
      this.setState({ otpError: "Required" })

    } else if (this.state.otp.length < 5) {
      this.setState({ otpError: "Invalid OTP" })
    }
    else {
      this.setState({
        loader: true
      })
      VerifyOTPLoginWithEmail(this.state.email, this.state.otp).then(async (res) => {
        this.setState({
          loader: false
        });
        await this.handleLoginResponse(res);
      });
    }

  }
  updateTimer = () => {
    let x;
    x = setInterval(() => {
      let { eventDate } = this.state
      if (eventDate <= 0) {
        clearInterval(x)
      } else {
        eventDate = eventDate.subtract("s", 1)
        const mins = eventDate.minutes()
        const secs = eventDate.seconds()
        this.setState({
          mins,
          secs,
          eventDate
        })

        if (secs.toString().length === 1) {
          this.setState({
            secs: "0" + secs
          })
        }
        if (mins === 0 && secs === 0) {
          this.setState({
            otpTime: false
          })
        }
      }
    }, 2000)
  }
  resendOtp = () => {
    this.setState({
      eventDate: moment.duration().add({ minutes: 1, seconds: 59 }),
      otpTime: true
    }, () => {
      this.updateTimer()
    })

    this.signinwithotpsubmit()
    // this.props.reSendOtp(this.state.otp, this.props.userId)
    //    this.props.reSendOTP(this.state.otp)

  }
  resetOtp = (secs, mins) => {
    if (mins === 0 && secs === 0) {
      this.setState({
        otpTime: false
      })
    }
  }
  resetPasswordClick = (e) => {
    this.setState({
      eventDate: moment.duration().add({ minutes: 1, seconds: 59 }),
      otpTime: true
    }, () => {
      this.updateTimer()
    })
    this.signinwithotpsubmit(e)
  }


  handleChange = (otp) => {
    if (numbersOnly(otp)) {
      this.setState({ otp })
    }
    this.setState({ otpError: "" })
  };
  onChangeEmail(e) {
    this.setState({ email: e.target.value, emailError: '' });
    this.props?.onChangeEmail(e.target.value)
  }
  download = () => {

  }
  passwordChange = (e) => {
    const passwordError = passValidator(e.target.value);
    if (passwordError == '') {
      this.setState({ password: e.target.value, passwordError: '' })
    }
    else {
      this.setState({ passwordError: passwordError })
    }
  }
  onClose = () => {
    this.setState({ open: false })
  }
  joinWaitList = () => {
    window.location.href = "https://tally.so/r/3qVKzd?source=et.finnovate.in"
  }
  render() {
    const {
      classes,
      handleSubmit,
      pristine,
      submitting,
      intl,
      messagesAuth,
      closeMsg,
      loading
    } = this.props;
    const { showPassword, showsetPassword, showconfirmPassword, section } = this.state;

    return (
      <div tabIndex="0" ref={this.myDiv}>
        <Paper className={classes.rootFull} >
          <Grid container >
            <Grid item xs={12} sm={6} md={6} lg={6} xl={4} component={Paper} elevation={16} square id='cardImage' >
              <CommonLogin />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={8} component={Paper} elevation={1} square className={[classes.LoginFormCard, "loginFormControl"].join(' ')}>
              <div className={classes.paper}>
                <div className={classes.pageFormSideWrap}>
                  <div className={classes.formLogin} >

                    <div className={classes.cardBox}>
                      <h6 className={classes.subTitleNew}>{Label.Login.MemberText}</h6>
                      <h5 className={classes.titleNew}>

                        {Label.Login.FinnTrackText}
                      </h5>
                      {section === "email" && (
                        <FormControl className={[classes.formControl, "loginEmail"].join(' ')} style={{ marginTop: "1%" }}>
                          <TextInput
                            page="TextField"
                            name="email"
                            label={labels.Login.email}
                            autoFocus={true}
                            value={this.state.email}
                            inputProps={{ maxLength: 75, autoFocus: true, autoComplete: 'new-password' }}
                            onChange={(e) => { this.onChangeEmail(e) }}
                            onFocus={(e) => {
                              var val = e.target.value;
                              e.target.value = '';
                              e.target.value = val;
                            }}
                            errormessage={this.state.emailError}
                          />
                        </FormControl>
                      )}
                      {section === "otp" && (
                        <>
                          <Typography variant="subtitle1"><span>Please Enter 6 digit OTP {this.state.email}</span>
                            <br /><span style={{ textDecoration: "underline", cursor: "pointer", fontSize: "smaller", color: colors.BROWN }} onClick={() => { this.setState({ section: "email" }) }}>Change Email</span>
                          </Typography>
                          <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                            <OTPInputTextBox
                              onChange={this.handleChange}
                              value={this.state.otp}
                              label={Label.Login.EnterOTP}
                              disabled={false}
                              error={this.state.otpError}
                            />
                            <div style={{ display: 'flex', fontSize: 12, marginTop: 7, gap: 3, alignItems: "center" }}>{Label.Login.DidntreceivetheOTPyet}
                              {
                                this.state.otpTime ?
                                  <EmailTimer
                                    text={labels.Signup.resendOTP}
                                    onclick={this.resetPasswordClick}
                                    resetOtp={this.resetOtp}
                                  />
                                  :
                                  <div className={classes.optArea}>
                                    <Button size="small" onClick={(event) => this.resetPasswordClick(event)} style={{ color: window.globalConfig.color.BROWN }}>
                                      {labels.ForgotPassword.resend}
                                    </Button>
                                  </div>
                              }
                            </div>
                          </div>
                        </>
                      )}
                      <div>
                        {section === "password" && (
                          <>
                            <Typography variant="subtitle1"><span>Please Enter your password for {this.state.email}</span>
                              <br /><span style={{ textDecoration: "underline", cursor: "pointer", fontSize: "smaller", color: colors.BROWN }} onClick={this.onClickChangeEmail}>Change Email</span>
                            </Typography>

                            <FormControl className={[classes.formControl, "loginPassword"].join(' ')}>
                              <TextInput page="TextFieldpassword"
                                type={showPassword}
                                label={labels.Login.password}
                                classes={{ input: classes.input }}
                                value={this.state.password}
                                autoFocus={true}
                                inputProps={{ autoFocus: true, }}
                                onKeyDown={this.onPasswordKeypress}
                                onChange={this.passwordChange}
                                errormessage={this.state.passwordError}
                                onClick={this.handleClickShowPassword}
                                onMouseDown={this.handleMouseDownPassword}
                              />
                              <span className={classes.forget} onClick={this.forgetPassword} style={{ display: 'flex', fontSize: 12, paddingTop: 10, width: "fit-content" }} title={labels.Login.forgotPasswordToolTip}>{Label.Login.ForgetPassword}</span>
                            </FormControl>
                          </>
                        )}
                      </div>

                      {/* </> */}
                      <div className={classes.btnArea}>
                        {section === "email" && (
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Buttons page={"secondaryPrimary"} name={Label.Login.Signinwithpassword} onClick={this.togglePasswordSection} loader={this.props?.loader} >{Label.Login.Signinwithpassword} </Buttons>
                            </Grid>
                            <Grid item xs={12}>
                              <Button variant='outlined' style={{ padding: 12, width: '100%', borderColor: '#253858', color: '#253858' }} onClick={this.signinwithotpsubmit} loader={this.state.emailLoader} >{Label.Login.Signinwithotp} {!this.props.loader && <ArrowForward />}</Button>
                            </Grid>

                          </Grid>
                        )}
                        {section === "otp" && (
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Buttons page={"secondaryPrimary"} name={Label.Login.ConfirmOTP} onClick={this.handleSubmit} loader={this.state?.loader} />
                            </Grid>
                            <Grid item xs={12}>
                              <Button variant='outlined' style={{ padding: 12, width: '100%', borderColor: '#253858', color: '#253858' }} page={"secondaryPrimary"} name={Label.Login.Signinwithpassword} onClick={this.togglePasswordSection} loader={this.state?.loader} >{Label.Login.Signinwithpassword} {!this.props.loader && <ArrowForward />}</Button>
                            </Grid>
                          </Grid>
                        )}
                        {section === "password" && (
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Buttons page={"secondaryPrimary"} name={Label.Login.Continue} onClick={this.Signinwithpasswordsubmit} loader={this.state?.loader}>
                                {this.state.loader && <CircularProgress style={{ color: window.globalConfig.color.PRIMARY }} size={24} className={classes.buttonProgress} />}

                                {Label.Login.Continue}{!this.state.loader && <ArrowForward />}</Buttons>
                            </Grid>
                            <Grid item xs={12}>
                              <Button variant='outlined' page={"secondaryPrimary"} style={{ padding: 12, width: '100%', borderColor: '#253858', color: '#253858' }} name={Label.Login.Signinwithotp} onClick={this.toggleSection} loader={this.props?.loader} >{Label.Login.Signinwithotp}{!this.props.loader && <ArrowForward />}</Button>
                            </Grid>
                          </Grid>
                        )}
                      </div>

                      {
                        messagesAuth?.length > 0 &&
                        <MessagesForm
                          variant="error"
                          className={classes.msgUser}
                          message={messagesAuth}
                          onClose={() => closeMsg()}
                        />
                      }

                    </div>
                    {window.globalConfig.isAppEnable && <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", gap: 10 }} >
                      <div><img src={PlayStore} style={{ height: 47 }} className={classes.imageAppStoreStyle} onClick={this.props.PlayStoreClick} ></img></div>
                      <img src={AppStore} className={classes.imageAppStoreStyle} onClick={this.props.onAppStoreClick}></img>

                    </div>}
                    <div className={classes.btnArea} style={{ display: "flex", flexDirection: "row" }}>
                      <a className={classes.forget} onClick={this.forgetPassword} title={labels.Login.forgotPasswordToolTip}>{Label.Login.finnFitAccountText}</a>
                      {window.globalConfig.isUserCanAdd && <a style={{ fontWeight: 500, color: window.globalConfig.color.GREEN, marginLeft: "2%", cursor: "pointer", }} onClick={this.joinWaitList} >{"Join The Waitlist"}</a>}
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Paper>
        <Snackbar open={this.state.open} handlesnanckClose={this.onClose} severity={this.state.severity} message={this.state.message}></Snackbar>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    messagesAuth: state?.Login?.loginError,
    signup: state?.Login?.signup,
    signupmessage: state?.Login?.signupmessage,
    loader: state?.Login?.loadercontinue,
    loadersignup: state?.Login?.loadersignup
  };
};
// const dispatchToProps = (dispatch) => {
//   return {
//     getLogin
//     closeMsg: () => dispatch(closeMsgAction()),
//     SignupAction: (email, mobilenumber, pannumber, password) => dispatch(SignupAction(email, mobilenumber, pannumber, password))
//   };
// };
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {

      getLogin,
      closeMsgAction,
      SignupAction
    },
    dispatch
  );
};
const LoginFormMapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginWithOTPForm);
export default withStyles(styles)(withRouter(LoginFormMapped));
