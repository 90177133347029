import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import combineStyle from "../../utils/stylesheets/combainstyle/combainstyle";
import commonstyletable from "../../utils/stylesheets/table/commontablestyle";
import GetInsuranceReport from '../../api/insurance/insurance_report';
import { replaceNull } from '../../utils/commonfunction';
import '../../utils/stylesheets/tabs/tabs.css'
import secureStorage from '../../utils/securestorage';
import labels from '../../utils/constants/labels';
// import {
//   bondsFilterChange,
// } from "../../redux/actions/portfolioxray/portfolioxray";
import { InsuranceFilterChange } from '../../redux/actions/portfolioxray/portfolioxray';
import { bindActionCreators } from "redux";
import { insurance_transactionfilter, } from '../../redux/actions/transaction/actions';

import { insuranceProfitandlossfilter } from '../../redux/actions/profitloss/profitlossaction'
import Insurancereport from '../insurance/insurancereport';
import getFamilyInsuranceReport from '../../api/insurance/familyinsurance';
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Typography>{children}</Typography>      
        <div
          style={{
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: '0.00938rem',
          }}
        >{children}</div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const Styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
  },
  tabRoot: {
    "& .MuiTab-root:focus": {
      color: window.globalConfig.color.BROWN
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: window.globalConfig.color.BROWN
    }
  }
});
const portfolioxraytitle = [{ "name": labels.Tableheader.AssetName }, { "name": labels.Tableheader.quantity, "subheader": labels.Tableheader.Avgprice }, { "name": labels.Tableheader.InvestedValue, "subheader": labels.Tableheader.amount }, { "name": labels.Tableheader.CurrentValue, "subheader": labels.Tableheader.Subheadingcurrent }, { "name": labels.Tableheader.unrealised, "subheader": "Interest" }, { "name": labels.Tableheader.Net, "subheader": labels.Tableheader.SubheadingNet }]
class InsuranceTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      healthInsuranceList: [],
      healthInsuranceListBackup: [],
      healthInsuranceBackup: [],
      lifeInsuranceList: [],
      lifeInsuranceListBackup: [],
      lifeInsuranceBackup: [],
      generalInsuranceList: [],
      generalInsuranceBackup: [],
      generalInsuranceListBackup: [],
      loader: false,
    }
  }
  getInsurance = () => {
    let data = {}
    data.Id = 1,
      data.clientID = this.props.clientID,
      data.L5ID = this.props.L5ID,
      data.L4ID = this.props.L4ID,
      this.setState({
        loader: true,
      })
    //api call
    GetInsuranceReport(data).then((response) => {
      this.setState({
        loader: false,
      })
      if (response.status == "S") {
        var health = []
        var life = []
        var general = []
        health = response.table2 != null && response.table2.length != 0 ? response.table2 : []
        if (health != null && health.length != 0) {
          const identifyHealthInsuranceCategory = health != null ? health.length != 0 ? health : [] : []
          const groupByHealthCategory = identifyHealthInsuranceCategory != null ? identifyHealthInsuranceCategory.length != 0 ? replaceNull(identifyHealthInsuranceCategory.filter((ele, ind) => ind === identifyHealthInsuranceCategory.findIndex(elem => elem.Category === ele.Category)), labels.messages.NA) : [] : []
          var groupedHealth = groupByHealthCategory.length != 0 ? groupByHealthCategory.sort((a, b) => {
            var textA = a.Category.toUpperCase();
            var textB = b.Category.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          }).map((items) => {
            return {
              catagories: replaceNull(items.Category, labels.messages.NA),
              data: replaceNull(identifyHealthInsuranceCategory.filter((val) => val.Category === items.Category).map((value) => {
                return { ...value, PolicyName: value.InsurancePolicyName }
              }), labels.messages.NA),
            }
          }) : []
          this.setState({
            healthInsuranceList: groupedHealth, healthInsuranceListBackup: health.map((val) => {
              return { ...val, SchemeName: val.PolicyName }
            }), healthInsuranceBackup: health.map((val) => {
              return { ...val, SchemeName: val.PolicyName }
            })
          })
        } else {
          this.setState({ healthInsuranceList: [], healthInsuranceListBackup: [], healthInsuranceBackup: [] })
        }
        general = response.table1 != null && response.table1.length != 0 ? response.table1 : []
        if (general != null && general.length != 0) {
          const identifyGeneralInsuranceCategory = general != null ? general.length != 0 ? general : [] : []
          const groupByGeneralCategory = identifyGeneralInsuranceCategory != null ? identifyGeneralInsuranceCategory.length != 0 ? replaceNull(identifyGeneralInsuranceCategory.filter((ele, ind) => ind === identifyGeneralInsuranceCategory.findIndex(elem => elem.Category === ele.Category)), labels.messages.NA) : [] : []
          var groupedGeneral = groupByGeneralCategory.length != 0 ? groupByGeneralCategory.map((items) => {
            return {
              catagories: replaceNull(items.Category, labels.messages.NA),
              data: replaceNull(identifyGeneralInsuranceCategory.filter((val) => val.Category === items.Category).sort((a, b) => {
                var textA = a.Category.toUpperCase();
                var textB = b.Category.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }).map((value) => {
                return { ...value, PolicyName: value.InsurancePolicyName }
              }), labels.messages.NA),
            }
          }) : []
          this.setState({
            generalInsuranceList: groupedGeneral, generalInsuranceListBackup: general.map((val) => {
              return { ...val, SchemeName: val.InsurancePolicyName }
            }), generalInsuranceBackup: general.map((val) => {
              return { ...val, SchemeName: val.InsurancePolicyName }
            })
          })
        } else {
          this.setState({ generalInsuranceList: [], generalInsuranceListBackup: [], generalInsuranceBackup: [] })
        }
        life = response.table3 != null && response.table3.length != 0 ? response.table3 : []
        if (life != null && life.length != 0) {
          const identifyLifeInsuranceCategory = life != null ? life.length != 0 ? life : [] : []
          const groupByLifeCategory = identifyLifeInsuranceCategory != null ? identifyLifeInsuranceCategory.length != 0 ? replaceNull(identifyLifeInsuranceCategory.filter((ele, ind) => ind === identifyLifeInsuranceCategory.findIndex(elem => elem.Category === ele.Category)), labels.messages.NA) : [] : []
          var groupedLife = groupByLifeCategory.length != 0 ? groupByLifeCategory.sort((a, b) => {
            var textA = a.Category.toUpperCase();
            var textB = b.Category.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          }).map((items) => {
            return {
              catagories: replaceNull(items.Category, labels.messages.NA),
              data: replaceNull(identifyLifeInsuranceCategory.filter((val) => val.Category === items.Category), labels.messages.NA),
            }
          }) : []
          this.setState({
            lifeInsuranceList: groupedLife, lifeInsuranceListBackup: life.map((val) => {
              return { ...val, SchemeName: val.PolicyName }
            }), lifeInsuranceBackup: life.map((val) => {
              return { ...val, SchemeName: val.PolicyName }
            })
          })
        } else {
          this.setState({ lifeInsuranceList: [], lifeInsuranceListBackup: [], lifeInsuranceBackup: [] })
        }
      } else {
        this.setState({ loader: false, })
      }
    }).catch((err) => {
      this.setState({ loader: false })
    })
  }
  getFamilyInsurance = () => {
    let data = {}
    data.Id = 1,
      data.clientID = this.props.clientID,
      data.L5ID = this.props.L5ID,
      data.L4ID = this.props.L4ID,
      this.setState({
        loader: true,
      })
    //api call
    getFamilyInsuranceReport(data).then((response) => {
      this.setState({
        loader: false,
      })
      if (response.status == "S") {
        var health = []
        var life = []
        var general = []
        health = response.table2 != null && response.table2.length != 0 ? response.table2 : []
        if (health != null && health.length != 0) {
          const identifyHealthInsuranceCategory = health != null ? health.length != 0 ? health : [] : []
          const groupByHealthCategory = identifyHealthInsuranceCategory != null ? identifyHealthInsuranceCategory.length != 0 ? replaceNull(identifyHealthInsuranceCategory.filter((ele, ind) => ind === identifyHealthInsuranceCategory.findIndex(elem => elem.Category === ele.Category)), labels.messages.NA) : [] : []
          var groupedHealth = groupByHealthCategory.length != 0 ? groupByHealthCategory.sort((a, b) => {
            var textA = a.Category.toUpperCase();
            var textB = b.Category.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          }).map((items) => {
            return {
              catagories: replaceNull(items.Category, labels.messages.NA),
              data: replaceNull(identifyHealthInsuranceCategory.filter((val) => val.Category === items.Category).map((value) => {
                return { ...value, PolicyName: value.InsurancePolicyName }
              }), labels.messages.NA),
            }
          }) : []
          this.setState({
            healthInsuranceList: groupedHealth, healthInsuranceListBackup: health.map((val) => {
              return { ...val, SchemeName: val.PolicyName }
            }), healthInsuranceBackup: health.map((val) => {
              return { ...val, SchemeName: val.PolicyName }
            })
          })
        } else {
          this.setState({ healthInsuranceList: [], healthInsuranceListBackup: [], healthInsuranceBackup: [] })
        }
        general = response.table1 != null && response.table1.length != 0 ? response.table1 : []
        if (general != null && general.length != 0) {
          const identifyGeneralInsuranceCategory = general != null ? general.length != 0 ? general : [] : []
          const groupByGeneralCategory = identifyGeneralInsuranceCategory != null ? identifyGeneralInsuranceCategory.length != 0 ? replaceNull(identifyGeneralInsuranceCategory.filter((ele, ind) => ind === identifyGeneralInsuranceCategory.findIndex(elem => elem.Category === ele.Category)), labels.messages.NA) : [] : []
          var groupedGeneral = groupByGeneralCategory.length != 0 ? groupByGeneralCategory.map((items) => {
            return {
              catagories: replaceNull(items.Category, labels.messages.NA),
              data: replaceNull(identifyGeneralInsuranceCategory.filter((val) => val.Category === items.Category).sort((a, b) => {
                var textA = a.Category.toUpperCase();
                var textB = b.Category.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }).map((value) => {
                return { ...value, PolicyName: value.InsurancePolicyName }
              }), labels.messages.NA),
            }
          }) : []
          this.setState({
            generalInsuranceList: groupedGeneral, generalInsuranceListBackup: general.map((val) => {
              return { ...val, SchemeName: val.InsurancePolicyName }
            }), generalInsuranceBackup: general.map((val) => {
              return { ...val, SchemeName: val.InsurancePolicyName }
            })
          })
        } else {
          this.setState({ generalInsuranceList: [], generalInsuranceListBackup: [], generalInsuranceBackup: [] })
        }
        life = response.table3 != null && response.table3.length != 0 ? response.table3 : []
        if (life != null && life.length != 0) {
          const identifyLifeInsuranceCategory = life != null ? life.length != 0 ? life : [] : []
          const groupByLifeCategory = identifyLifeInsuranceCategory != null ? identifyLifeInsuranceCategory.length != 0 ? replaceNull(identifyLifeInsuranceCategory.filter((ele, ind) => ind === identifyLifeInsuranceCategory.findIndex(elem => elem.Category === ele.Category)), labels.messages.NA) : [] : []
          var groupedLife = groupByLifeCategory.length != 0 ? groupByLifeCategory.sort((a, b) => {
            var textA = a.Category.toUpperCase();
            var textB = b.Category.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          }).map((items) => {
            return {
              catagories: replaceNull(items.Category, labels.messages.NA),
              data: replaceNull(identifyLifeInsuranceCategory.filter((val) => val.Category === items.Category), labels.messages.NA),
            }
          }) : []
          this.setState({
            lifeInsuranceList: groupedLife, lifeInsuranceListBackup: life.map((val) => {
              return { ...val, SchemeName: val.PolicyName }
            }), lifeInsuranceBackup: life.map((val) => {
              return { ...val, SchemeName: val.PolicyName }
            })
          })
        } else {
          this.setState({ lifeInsuranceList: [], lifeInsuranceListBackup: [], lifeInsuranceBackup: [] })
        }
      } else {
        this.setState({ loader: false, })
      }
    }).catch((err) => {
      this.setState({ loader: false })
    })
  }
  componentDidMount() {
    document.title = window.globalConfig.companyDetails.companyName + " - Insurance"
    const { page, xray } = this.props;
    // 
    // console.log(page)
    //if (prev.page != page) {
    if (page.state != undefined) {
      // if (prev.page.state.tabValue != page.state.tabValue) {
      this.setState({ value: page.state.tabValue })
      // }
    }
    if(this.props.report=="FamilyReport"){
      this.getFamilyInsurance()
    }else{
      this.getInsurance()
    }
    //}
  }
  componentDidUpdate(prev) {
    const { page } = this.props;
    // 
    if (prev.page != page) {
      if (prev.page.state != undefined) {
        if (prev.page.state.tabValue != page.state.tabValue) {
          this.setState({ value: page.state.tabValue })
        }
      }
    }
    const { clientID, date, L5ID } = this.props;
    if (prev.clientID != clientID || prev.date != date || prev.L5ID != L5ID) {
      if (clientID != null || date != null) {
        if(this.props.report=="FamilyReport"){
          this.getFamilyInsurance()
        }else{
          this.getInsurance()
        }
      }
    }
    //
  }
  commonFunction = (val) => {
    if (val != undefined && val != null && val != "") return true;
    return false;
  }
  handleChange = (event, newValue) => {
    // 
    this.setState({ value: newValue })
    if (newValue == 0) {
      const identifyHealthInsuranceCategory = this.state.healthInsuranceBackup != null ? this.state.healthInsuranceBackup.length != 0 ? this.state.healthInsuranceBackup : [] : []
      const groupByHealthCategory = identifyHealthInsuranceCategory != null ? identifyHealthInsuranceCategory.length != 0 ? replaceNull(identifyHealthInsuranceCategory.filter((ele, ind) => ind === identifyHealthInsuranceCategory.findIndex(elem => elem.Category === ele.Category)), labels.messages.NA) : [] : []
      var groupedHealth = groupByHealthCategory.length != 0 ? groupByHealthCategory.sort((a, b) => {
        var textA = a.Category.toUpperCase();
        var textB = b.Category.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      }).map((items) => {
        return {
          catagories: replaceNull(items.Category, labels.messages.NA),
          data: replaceNull(identifyHealthInsuranceCategory.filter((val) => val.Category === items.Category).map((value) => {
            return { ...value, PolicyName: value.InsurancePolicyName }
          }), labels.messages.NA),
        }
      }) : []
      this.setState({ healthInsuranceList: groupedHealth, healthInsuranceListBackup: this.state.healthInsuranceBackup })
    } else if (newValue == 1) {
      // console.log(this.state.generalInsuranceListBackup)
      const identifyGeneralInsuranceCategory = this.state.generalInsuranceBackup != null ? this.state.generalInsuranceBackup.length != 0 ? this.state.generalInsuranceBackup : [] : []
      const groupByGeneralCategory = identifyGeneralInsuranceCategory != null ? identifyGeneralInsuranceCategory.length != 0 ? replaceNull(identifyGeneralInsuranceCategory.filter((ele, ind) => ind === identifyGeneralInsuranceCategory.findIndex(elem => elem.Category === ele.Category)), labels.messages.NA) : [] : []
      var groupedGeneral = groupByGeneralCategory.length != 0 ? groupByGeneralCategory.sort((a, b) => {
        var textA = a.Category.toUpperCase();
        var textB = b.Category.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      }).map((items) => {
        return {
          catagories: replaceNull(items.Category, labels.messages.NA),
          data: replaceNull(identifyGeneralInsuranceCategory.filter((val) => val.Category === items.Category).map((value) => {
            return { ...value, PolicyName: value.InsurancePolicyName }
          }), labels.messages.NA),
        }
      }) : []
      this.setState({ generalInsuranceList: groupedGeneral, generalInsuranceListBackup: this.state.generalInsuranceBackup })
    }
    else {
      const identifyLifeInsuranceCategory = this.state.lifeInsuranceBackup != null ? this.state.lifeInsuranceBackup.length != 0 ? this.state.lifeInsuranceBackup : [] : []
      const groupByLifeCategory = identifyLifeInsuranceCategory != null ? identifyLifeInsuranceCategory.length != 0 ? replaceNull(identifyLifeInsuranceCategory.filter((ele, ind) => ind === identifyLifeInsuranceCategory.findIndex(elem => elem.Category === ele.Category)), labels.messages.NA) : [] : []
      var groupedLife = groupByLifeCategory.length != 0 ? groupByLifeCategory.sort((a, b) => {
        var textA = a.Category.toUpperCase();
        var textB = b.Category.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      }).map((items) => {
        return {
          catagories: replaceNull(items.Category, labels.messages.NA),
          data: replaceNull(identifyLifeInsuranceCategory.filter((val) => val.Category === items.Category), labels.messages.NA),
        }
      }) : []
      this.setState({ lifeInsuranceList: groupedLife, lifeInsuranceListBackup: this.state.lifeInsuranceBackup })
    }
    this.lifeFilterSubmit([])
  };
  lifeFilterSubmit = (value) => {
    // console.log(value)
    let life = replaceNull(this.state.lifeInsuranceListBackup, "NA")
    let lifeFilter = replaceNull(value.Scheme, "").length != 0 ? value.Scheme.map((i) => {
      return i.label; // Taking only Schemes name from the labels as array
    }) : [];
    if (lifeFilter.length != 0) {
      life = life.filter((data) => {
        return lifeFilter.includes(data.PolicyName);
      });
    }
    const identifyLifeInsuranceCategory = life != null ? life.length != 0 ? life : [] : []
    const groupByLifeCategory = identifyLifeInsuranceCategory != null ? identifyLifeInsuranceCategory.length != 0 ? replaceNull(identifyLifeInsuranceCategory.filter((ele, ind) => ind === identifyLifeInsuranceCategory.findIndex(elem => elem.Category === ele.Category)), labels.messages.NA) : [] : []
    var groupedLife = groupByLifeCategory.length != 0 ? groupByLifeCategory.sort((a, b) => {
      var textA = a.Category.toUpperCase();
      var textB = b.Category.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    }).map((items) => {
      return {
        catagories: replaceNull(items.Category, labels.messages.NA),
        data: replaceNull(identifyLifeInsuranceCategory.filter((val) => val.Category === items.Category), labels.messages.NA),
      }
    }) : []
    this.setState({ lifeInsuranceList: groupedLife, lifeInsuranceBackup: life })
  }
  generalFilterSubmit = (value) => {
    let general = replaceNull(this.state.generalInsuranceListBackup, "NA")
    let generalFilter = replaceNull(value.Scheme, "").length != 0 ? value.Scheme.map((i) => {
      return i.label; // Taking only Schemes name from the labels as array
    }) : [];
    if (generalFilter.length != 0) {
      general = general.filter((data) => {
        return generalFilter.includes(data.InsurancePolicyName);
      });
    }
    const identifyGeneralInsuranceCategory = general != null ? general.length != 0 ? general : [] : []
    const groupByGeneralCategory = identifyGeneralInsuranceCategory != null ? identifyGeneralInsuranceCategory.length != 0 ? replaceNull(identifyGeneralInsuranceCategory.filter((ele, ind) => ind === identifyGeneralInsuranceCategory.findIndex(elem => elem.Category === ele.Category)), labels.messages.NA) : [] : []
    var groupedGeneral = groupByGeneralCategory.length != 0 ? groupByGeneralCategory.sort((a, b) => {
      var textA = a.Category.toUpperCase();
      var textB = b.Category.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    }).map((items) => {
      return {
        catagories: replaceNull(items.Category, labels.messages.NA),
        data: replaceNull(identifyGeneralInsuranceCategory.filter((val) => val.Category === items.Category).map((value) => {
          return { ...value, PolicyName: value.InsurancePolicyName }
        }), labels.messages.NA),
      }
    }) : []
    this.setState({ generalInsuranceList: groupedGeneral, generalInsuranceBackup: general })
  }
  healthFilterSubmit = (value) => {
    let health = replaceNull(this.state.healthInsuranceListBackup, "NA")
    let healthFilter = replaceNull(value.Scheme, "").length != 0 ? value.Scheme.map((i) => {
      return i.label; // Taking only Schemes name from the labels as array
    }) : [];
    if (healthFilter.length != 0) {
      health = health.filter((data) => {
        return healthFilter.includes(data.InsurancePolicyName);
      });
    }
    const identifyHealthInsuranceCategory = health != null ? health.length != 0 ? health : [] : []
    const groupByHealthCategory = identifyHealthInsuranceCategory != null ? identifyHealthInsuranceCategory.length != 0 ? replaceNull(identifyHealthInsuranceCategory.filter((ele, ind) => ind === identifyHealthInsuranceCategory.findIndex(elem => elem.Category === ele.Category)), labels.messages.NA) : [] : []
    var groupedHealth = groupByHealthCategory.length != 0 ? groupByHealthCategory.sort((a, b) => {
      var textA = a.Category.toUpperCase();
      var textB = b.Category.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    }).map((items) => {
      return {
        catagories: replaceNull(items.Category, labels.messages.NA),
        data: replaceNull(identifyHealthInsuranceCategory.filter((val) => val.Category === items.Category).map((value) => {
          return { ...value, PolicyName: value.InsurancePolicyName }
        }), labels.messages.NA),
      }
    }) : []
    this.setState({ healthInsuranceList: groupedHealth, healthInsuranceBackup: health })
  }
  render() {
    // //   
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            classes={{ root: classes.tabRoot }}
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{ style: { background: window.globalConfig.color.BROWN } }}>
            <Tab label="Health Insurance" {...a11yProps(0)} />
            <Tab label="General Insurance" {...a11yProps(1)} />
            <Tab label="Life Insurance" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        <TabPanel value={this.state.value} index={0}>
          <Insurancereport flag="Health" header="Scheme" submit={this.healthFilterSubmit} loader={this.state.loader} insuranceList={this.state.healthInsuranceList} insuranceListBackup={this.state.healthInsuranceListBackup} backup={this.state.healthInsuranceBackup}></Insurancereport>
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
          <Insurancereport flag="General" header="Scheme" submit={this.generalFilterSubmit} loader={this.state.loader} insuranceList={this.state.generalInsuranceList} insuranceListBackup={this.state.generalInsuranceListBackup} backup={this.state.generalInsuranceBackup}></Insurancereport>
        </TabPanel>
        <TabPanel value={this.state.value} index={2}>
          <Insurancereport flag="Life" header="Scheme" submit={this.lifeFilterSubmit} loader={this.state.loader} insuranceList={this.state.lifeInsuranceList} insuranceListBackup={this.state.lifeInsuranceBackup} backup={this.state.lifeInsuranceBackup}></Insurancereport>
        </TabPanel>
      </div>
    );
  }
}
const mapStateToProps = state => {
  // //
  return {
    //COMMON STATE
    sessionID: state.Login.sessionID,
    clientID: state.Login.logindetails.ClientID,
    L5ID: state.Login.L5ID,
    date: state.Login.date,
    L4ID: state.Login.logindetails.L4_AccountTypeID,
    login: state.Login.logindetails,
    // insuranceReduxTable: state.Dataentry.npsAndInsuranceDropdownList,
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      InsuranceFilterChange,
      insurance_transactionfilter,
      insuranceProfitandlossfilter
    },
    dispatch
  );
};
const mergestyles = combineStyle(Styles, commonstyletable);

export default withStyles(mergestyles)(connect(mapStateToProps, mapDispatchToProps)(InsuranceTab))