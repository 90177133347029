import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import Button from '../../components/custombutton/button';
import Text from '../../components/text/text';
import color from "../../utils/colors/colors";
import labels from '../../utils/constants/labels';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import colors from '../../utils/colors/colors';
import { NegativeFinding } from '../../utils/commonfunction';
import Radio from '@material-ui/core/Radio';
import { scrollToTopPage } from '../../utils/commonfunction';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import NoData from '../../components/nodata/nodata';
const styles = theme => ({
  collabel: {
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: "12px",
    textAlign: "left",
    marginBottom: 10
  },
  bankDetailsGrid:{
    gap: 46,
    marginTop: 10,
    textAlign: "left",
    display: "grid",
    gridTemplateColumns: "repeat(3,1fr)",
    [theme.breakpoints.down("600")]: {
      gridTemplateColumns: "repeat(2,1fr)",
    }
  },
  btnApply: {
    // width:"20%",marginLeft:420,
    // [theme.breakpoints.between("1250","1300")]: {
    //   marginLeft:324,
    // },
    // [theme.breakpoints.between("1300","1400")]: {
    //   marginLeft:322,
    // },
  },
  btnBack: {
    // width:"20%",marginLeft:30
  },
  folio:{
    fontSize: 14, 
    color: window.globalConfig.color.BLACK_PRIMARY, 
    fontWeight: 'normal' 
  },
  divMain: {
    display: "flex",
    flexDirection: "row",
    marginTop: "3%",
    marginLeft: "9%",
    marginRight: "3%",
    marginBottom: "4%",
    paddingBottom: "3%"
  },
  divDetail: {
    height: 'auto', width: "60%", backgroundColor: window.globalConfig.color.WHITE, marginLeft: "7%", marginBottom: "4%", border: `1px solid ${window.globalConfig.color.BORDER}`, boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.3)", paddingBottom: "30px"
  },
  root: {
    // marginLeft: 27,
    // marginTop: 20,
    color:window.globalConfig.color.BLACK_PRIMARY
  },
  reviewHeader: {
    fontWeight: "bolder",
    marginLeft: "-4%"
  },
  divContent: {
    display: 'block',
    // width: "92%",
    // marginLeft: "4%",
    // marginTop: "2%",
    color:window.globalConfig.color.BLACK_PRIMARY,
    backgroundColor: window.globalConfig.color.RosyBrown,
    // height: "88%",
    paddingBottom: "10%",
    borderRadius:5
  },
  radeemFrom: {
    marginLeft: 15,
    marginTop: "3%"
  },
  orderType: {
    marginTop: "4%",
    marginBottom: "2%"
  },
  divLine: {
    height: "1px",
    width: "95%",
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
     marginTop: "5%",
    marginBottom: "0%",
    marginLeft: 15,
    [theme.breakpoints.down("600")]: {
      backgroundColor: window.globalConfig.color.PRIMARY_BORDER,

    }
  },
  divLine1: {
    height: "0.5px",
    width: "100%",
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    [theme.breakpoints.down("600")]: {
      backgroundColor: window.globalConfig.color.PRIMARY_BORDER,

    }
    ,
    marginTop: "6%",
    marginBottom: "-4%",
    //boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.3)",
  },
  //Added by Celsia on 5 Jun 2021 for Mobile Responsive Started
  desktopResponsive: {
    // display: 'block !important',
    // [theme.breakpoints.down("600")]: {
    //   display: 'none !important',
    // },
  },
  mobileResponsive: {
    display: 'none !important',
    [theme.breakpoints.down("600")]: {
      display: 'block!important',
    },
  },
  divContentResponsive: {
    display: 'block',
    width: "94%",
    marginLeft: "3%",
    marginTop: "2%",
    color:window.globalConfig.color.BLACK_PRIMARY,
    backgroundColor: window.globalConfig.color.RosyBrown,
  },
  collabelresponsive: {
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: "12px",
    marginBottom: 10
  },
  redeemFromResponsive: {
    padding: "10px"
  },
  leftResponsive: {
    padding: "10px",
    textAlign: "left"
  },
  rightResponsive: {
    padding: "10px",
    textAlign: "right"
  },
  valueResponsive: {
    fontSize: 12,
    color: window.globalConfig.color.BLACK_PRIMARY
  },
  divLineResponsive: {
    height: "0.5px",
    width: "100%",
    backgroundColor: window.globalConfig.color.PRIMARY_BORDER,
  },
  rootResponsive: {
    marginTop: 20,
  },
  centerResponsive: {
    textAlign: "center",
    padding: "10px"
  },
  radioButton:{
    "& .MuiFormControlLabel-root":{
      display:"flex",
      alignItems:"flex-start"
    },
    "& .MuiIconButton-root":{
      paddingTop:0
    }
  }
});
class SWPReview extends React.Component {
  componentDidMount() {
    scrollToTopPage();
  }
  render() {
    const { classes } = this.props;
    return (<ErrorBoundary>
    <Grid className={classes.desktopResponsive}>
      <Grid container className={classes.root}>
        <Text label={labels.SWP.ReviewSWPDetails}/>
      </Grid>
      <div className={classes.divContent}>
        <Grid container style={{ display: "block !important" }}>
          <Grid item xs={12} className={classes.radeemFrom}>
            <div style={{ textAlign: "left" }}>
              <div className={classes.collabel}>{labels.SWP.RedeemFrom}</div>
              <div style={{ fontWeight: "bold", fontSize: 17 }}>{this.props.schemevalue}<span className={classes.folio}> ({labels.SWP.Folio}: {this.props.folionumber})</span></div>
            </div>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 20, marginLeft: 15, }}>
          <Grid item xs={4}>
            <div style={{ textAlign: "left" }}>
              <div className={classes.collabel}> {labels.SWP.SWPtype}</div>
              <div style={{ fontSize: 14 }}>{this.props.Swptype}</div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ textAlign: "left" }}>
              <div className={classes.collabel}> {labels.SWP.Amount}</div>
              <div style={{ fontSize: 14 }}>{this.props.amount}</div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ textAlign: "left" }}>
              <div className={classes.collabel}> {labels.SWP.SWPInstalments}</div>
              <div style={{ fontSize: 14 }}>{this.props.installment}</div>
            </div>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 20, marginLeft: 15,}}>
          <Grid item xs={4}>
            <div style={{ textAlign: "left" }}>
              <div className={classes.collabel}> {labels.SWP.SWPStartDate}</div>
              <div style={{ fontSize: 14 }}>{this.props.swpdate}</div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ textAlign: "left" }}>
              <div className={classes.collabel}> {labels.SWP.FirstOrderToday}</div>
              <div style={{ fontSize: 14 }}>{this.props.firstorder?"Yes":"No"}</div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <div style={{ textAlign: "left" }}>
              <div className={classes.collabel}> {labels.STP.UCC}</div>
              <div style={{ fontSize: 14 }}>{this.props.UCC}</div>
            </div>
          </Grid>
        </Grid>
        <div className={classes.divLine}></div>
        {this.props.bankdetails.length != 0 ? this.props.bankdetails.filter((items, index) => items.UCC == this.props.UCC)?.map((item) => {
          return (<div style={{marginTop:10,padding:5}}>

            <RadioGroup className={classes.radioButton} value={this.props.value} aria-label="bank" name="bank" onChange={this.props.bankChange}>
              <FormControlLabel value={item.BankAccountNumber} control={<Radio style={{ color: window.globalConfig.color.BROWN}}/>} label={<>  <div item xs={12} className={classes.bankBranch}>
                <div style={{ textAlign: "left" }}>
                  <div style={{ fontSize: 15, marginBottom: 10, fontWeight: 550 }}>{item.BankName}</div>
                  <div style={{ fontSize: 12 }}>{labels.Redemption.BranchName}:<span> {item.BranchName}</span></div>
                </div>
              </div>

                <div container className={classes.bankDetailsGrid}>
                  {/* <div item xs={4}> */}
                  <div style={{ textAlign: "left" }}>
                    <div className={classes.collabel}> {labels.Redemption.BankAccountType}</div>
                    <div style={{ fontSize: 14 }}>{item.AccountType}</div>
                  </div>
                  {/* </div> */}
                  {/* <div item xs={4}> */}
                  <div style={{ textAlign: "left" }}>
                    <div className={classes.collabel}> {labels.Redemption.AccountNumber}</div>
                    <div style={{ fontSize: 14 }}>{item.BankAccountNumber}</div>
                  </div>
                  {/* </div> */}
                  {/* <div item xs={4}> */}
                  <div style={{ textAlign: "left" }}>
                    <div className={classes.collabel}> {labels.Redemption.IFSCCode}</div>
                    <div style={{ fontSize: 14 }}>{item.IFSCCode}</div>
                  </div>
                  {/* </div> */}
                </div></>} />
             
            </RadioGroup>
            <div className={classes.divLine}></div>
          </div>)
        }) : <div style={{ marginTop: "5%" }}><NoData /></div>}

      </div>
      <div className={classes.divLine1}></div>
     
      <div style={{display:'flex', marginTop:"10%",flexDirection:"row",justifyContent:"space-between"}}>
        <div className={classes.btnBack}>
          <Button name={"Back"} page={"normal"} disabled={this.props.loader} onClick={() => this.props.onPlaceorder(false)}/>
        </div>
        <div className={classes.btnApply} >
          <Button name={"Confirm Order"} page={"primary"} disabled={this.props.loader} loader={this.props.loader} onClick={() => this.props.confirmPayment(true)}/>
        </div>
      </div>
    </Grid>

 
    </ErrorBoundary>
    );
  }
}
const mapStateToProps = state => {
  //////
  return {
    bankdetails:state.ProfileDetails.AllProfileDetails.length!=0?state.ProfileDetails.AllProfileDetails.bankDetails!="NA"&&state.ProfileDetails.AllProfileDetails.bankDetails.length!=0?state.ProfileDetails.AllProfileDetails.bankDetails.filter((val) => val.IsDefault === true):[]:[]
  }
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, null)(SWPReview)))