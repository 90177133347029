import React from 'react';
import PropTypes from 'prop-types';
import { TradeL5IDDetails } from '../../redux/actions/login/loginaction'
import { changeUcccode } from '../../redux/actions/profile/profile'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles'
import colors from '../../utils/colors/colors';
import Alert_tabs from '../tabs/alert_tabs';
import Subheader from '../../components/subheader/subheader';
import labels from '../../utils/constants/labels';
import getAlertNotification from '../../api/alert/alertnotification';
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import CommonSnackbar from '../../components/snackbar/snackbar';
import { replaceNull } from '../../utils/commonfunction';
import moment from 'moment';
import Scrolltotop from '../../components/scrolltotop/scrolltotop';
import Loader from '../../components/loader/loader';
import { getAlertNotificationDetails } from '../../redux/actions/alert/alertaction';
const styles = (theme) => ({
    header: {
        fontSize: 20,
        fontWeight: "bold",
        textAlign: "left",
        textTransform: "uppercase",
        color: window.globalConfig.color.BLACK_PRIMARY
    },
    subHeader: {
        fontSize: 13,
        textAlign: "left",
        marginTop: 10,
        // fontWeight:"bold",
        // textDecoration:"uppercase",
        color: window.globalConfig.color.BROWN
    },
    card: {
        padding: "2%", borderRadius: 10, boxShadow: `0 0 20px 0 ${window.globalConfig.color.BOX_SHADOW}`,
        marginTop: "3%",
        marginBottom: "6%"
    },
    notificationGrid: {
        display: "grid", gridTemplateColumns: "9% 81% 9%", gap: 10,
        [theme.breakpoints.down("900")]: {
            gridTemplateColumns: "4% 88% 4%"
        }
    }
})
class AlertNotification extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            list: [],
            open: false,
            message: "",
            severity: "success",
            loader: false
        }
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };
    componentDidMount() {
        document.title = window.globalConfig.companyDetails.companyName + " - Notifications"
        this.props.getAlertNotificationDetails(this.props.logindetails.ClientID)
        this.setState({ loader: this.props.isAlertLoading })
      
    }
    componentDidUpdate = (prev) => {
        const { ClientID, date, L5ID, isAlertLoading } = this.props
        if (prev.ClientID != ClientID) {
            this.props.getAlertNotificationDetails(ClientID)

        }
        if (prev.isAlertLoading != isAlertLoading) {

            this.setState({ loader: isAlertLoading })
        }
    }
    render() {
        const { classes } = this.props
        return (
            <div >
                <Subheader showImage={true} icon={<NotificationsNoneIcon></NotificationsNoneIcon>} heading={labels.messages.allAlerts}></Subheader>
                <div className={classes.notificationGrid}>
                    <div></div>
                    <div className={classes.card}>
                        <div className={classes.header}>{labels.messages.notification}</div>
                        <div className={classes.subHeader}>{this.props.alertData?.length} messages</div>
                        {this.state.loader == true ? <Loader page={'reportmodel'}></Loader> :
                            <Alert_tabs list={this.props.alertData} loader={this.state.loader}></Alert_tabs>}
                        {/* <Scrolltotop>   </Scrolltotop> */}
                    </div>
                    <div></div>
                </div>
                <CommonSnackbar open={this.state.open} message={this.state.message} severity={this.state.severity} handlesnanckClose={this.handlesnanckClose}></CommonSnackbar>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    logindetails: state.Login.logindetails,
    ClientID: state.Login.logindetails.ClientID,
    L5ID: state.Login.L5ID,
    date: state.Login.date,
    alertData: state.Alert.alertData,
    isAlertHadData: state.Alert.isAlertHadData,
    isAlertLoading: state.Alert.isLoading

})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ changeUcccode, TradeL5IDDetails, getAlertNotificationDetails }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AlertNotification))