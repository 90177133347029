import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router';
import { withStyles } from '@material-ui/core';
import CommonSnackbar from '../../components/snackbar/snackbar';
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle';
import labels from '../../utils/constants/labels';
import colors from '../../utils/colors/colors';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import Subheader from '../../components/subheader/subheader';
import Toggle from "../../components/toggle/toggle";
import moment from 'moment';
import DeletePopup from '../../components/popup/deletepopup';
import OutlinedButton from '../../components/payment/button';
import DynamicList from '../../components/list/dynamicList';
import getInsuranceList from "../../api/insurance/insurance_list";
import getAddInsurance from "../../api/insurance/add_insurance";
import getAddUpdateDeleteInsurance from "../../api/insurance/add_update_life_insurance";
import Three_State_Toggle_Switch from "../../components/switchbutton/three_state_toggle_switch";
import { scrollToTopPage } from '../../utils/commonfunction';
const styles = theme => ({
    flex: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down("920")]: {
            flexDirection: 'column'
        }
    },
    sideSpace: {
        width: '12%',
        [theme.breakpoints.down("920")]: {
            display: 'none'
        }
    },
    mainCard: {
        display: "flex", marginBottom: "3%",
        boxShadow: "rgb(19 89 161 / 10%) 0px 0px 20px 0px",
        borderRadius: 5,
        height: "auto",
        width: "89%", marginTop: "18px",
        padding: '4%',
        paddingTop: "13px !important",
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down("920")]: {
            margin: 'auto'
        }
    },
    buttonAlignment: {
        display: "flex",
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "end",
        columnGap: 10
    },
    marginTopAlignment: {
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
    },
    // main title css
    header: {
        textAlign: 'left',
        textTransform: 'uppercase',
    },
    // herizontal line css
    cardSpareaterLine: {
        height: 0.5,
        width: "100%",
        backgroundColor: window.globalConfig.color.LABLE_GRAY,
        marginBottom: '1pc',
        marginTop: '6px',
    },
    assetGrid: {
        gridTemplateColumns: "12% 12% 12% 12% 10% 12% 15% 12% 3%",
        display: "grid",
        wordWrap: "break-word",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(3,1fr)",
            gridTemplateAreas: `"${"myArea myArea."}"`,
            wordWrap: "break-word",
            height: "auto !important",
            rowGap: "20px",
            paddingTop: 10,
            display: "grid",
        }
    },
    assetGeneralGrid: {
        gridTemplateColumns: "15% 15% 15% 10% 16% 16% 10% 3%",
        display: "grid",
        wordWrap: "break-word",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(3,1fr)",
            gridTemplateAreas: `"${"myArea myArea."}"`,
            wordWrap: "break-word",
            height: "auto !important",
            rowGap: "20px",
            paddingTop: 10,
            display: "grid",
        }
    }
})
class Insurance_List extends Component {
    constructor(props) {
        super(props);
        this.state = {
            listLoader: false,
            selected: false,
            values: ['Health', 'Life', 'General'],
            flag: 'Health',
            grid: "",
            gridGeneral: "",
            count: 8,
            countGeneral: 7,
            countLife: 8,
            popupList: [],
            enableIcon: true,
            healthList: [],
            generalList: [],
            lifeList: [],
            headerList: [{ label: "Policy Name", align: 'left' }, { label: "Policy No", align: 'left' }, { label: "Insured For", align: 'left' },
            { label: "Policy Type", align: 'left' }, { label: "Start Date", align: 'left' }, { label: "Cover Amount", align: 'right' },
            { label: "Premium Amount", align: 'right' }, { label: "Policy Term", align: 'right' }],

            headerGeneralList: [{ label: "Policy Name", align: 'left' }, { label: "Policy No", align: 'left' }, { label: "Insured For", align: 'left' },
            { label: "Start Date", align: 'left' }, { label: "Cover Amount", align: 'right' },
            { label: "Premium Amount", align: 'right' }, { label: "Policy Term", align: 'right' }],

            headerLifeList: [{ label: "Policy Name", align: 'left' }, { label: "Policy No", align: 'left' }, { label: "Name", align: 'left' },
            { label: "Policy For", align: 'left' }, { label: "Start Date", align: 'left' }, { label: "Cover Amount", align: 'right' },
            { label: "Premium Amount", align: 'right' }, { label: "Policy Term", align: 'right' }],

            // Insurance List Delete ID
            deleteID: "",
            deleteType: "",

            // Delete Popup
            deletePopupLoader: false,
            openDeletePopup: false,
            deletePopupText: labels.Insurance.confirmationInsuranceText,

            // Snackbar
            message: "",
            openSnackBar: false,
            severity: "success"
        }
    }

    componentDidMount() {
        document.title = window.globalConfig.companyDetails.companyName +" - Insurance"
        scrollToTopPage()
        const { classes } = this.props
        this.setState({
            grid: classes.assetGrid,
            gridGeneral: classes.assetGeneralGrid,
            popupList: [{ "name": "Edit", "onClick": this.editInsurance }, { "name": "Delete", "onClick": this.deleteInsurance }]
        })

        // Flag come from Start_tracking_cards.js file (Come From Insurance card click) and insurance_data_entry.js file(API call success Response)
        if (this.props.location.state != undefined) {
            // this.setState({ flag: this.props.location.state.flag }, () => {
            //     if (this.state.flag == "Health") {
            //         this.setState({
            //             selected: false, // Health Flag selected false
            //         })
            //     }
            //     else {
            //         this.setState({
            //             selected: true, // General Flag selected true
            //         })
            //     }
            // })
            this.setState({
                flag: this.props.location.state.flag
            }, () => {
                // Insurance Listing API function call
                this.insuranceList(this.state.flag);
            })
        }
        else {
            // Insurance Listing API function call
            this.insuranceList(this.state.flag);
        }
    }

    // Insurance Listing API Integration
    insuranceList = (flag) => {
        this.setState({
            listLoader: true,
        })

        var data;
        if (flag == "Life") {
            data = {
                clientID: this.props.clientID,
                flag: 2
            }
        }
        else {
            data = {
                clientID: this.props.clientID
            }
        }

        // Listing API
        getInsuranceList(data).then((resp) => {
            if (resp.status == "S") {
                if (flag == "Life") {
                    if (this.commonFunction(resp.table1)) {
                        this.setState({
                            lifeList: resp.table1.map((item) => {
                                return {
                                    "policyName": { name: this.commonFunction(item.PolicyName) ? item.PolicyName : "NA", subHeader1: "", subHeader2: "", type: '' },
                                    "policyNo": { name: this.commonFunction(item.PolicyNo) ? item.PolicyNo : 'NA', subHeader1: "", subHeader2: "", type: '' },
                                    "name": { name: this.commonFunction(item.Name) ? item.Name : "NA", subHeader1: "", subHeader2: "", type: '' },
                                    "policyFor": { name: this.commonFunction(item.PolicyFor) ? item.PolicyFor : "NA", subHeader1: "", subHeader2: "", type: '' },
                                    "startDate": { name: this.commonFunction(item.StartDate) ? moment(item.StartDate).format("DD MMM YYYY") : "NA", subHeader1: "", subHeader2: "", type: '' },
                                    "coverAmount": { name: this.commonFunction(item.CoverAmount) ? item.CoverAmount : 0, subHeader1: '', subHeader2: "", type: 'amount' },
                                    "premiumAmount": { name: this.commonFunction(item.PremiumAmount) ? item.PremiumAmount : 0, subHeader1: "", subHeader2: "", type: 'amount' },
                                    "policyTerm": { name: this.commonFunction(item.PolicyTerm) ? item.PolicyTerm : "NA", subHeader1: "", subHeader2: "", type: '' },
                                    "ID": { name: item.ID, subHeader1: "", subHeader2: "", type: '' },
                                    "Type": { name: item.Type, subHeader1: "", subHeader2: "", type: '' },
                                }
                            }),
                            listLoader: false,
                        })
                    }
                    else {
                        this.setState({
                            lifeList: [],
                            listLoader: false,
                        })
                    }
                }
                else {
                    if (this.commonFunction(resp.table1)) {
                        var healthListFilter = resp.table1.filter((item) => (item.Type == "Health"))

                        var GeneralListFilter = resp.table1.filter((item) => (item.Type == "General"))
                        this.setState({
                            healthList: healthListFilter.map((item) => {
                                return {
                                    "policyName": { name: this.commonFunction(item.PolicyName) ? item.PolicyName : "NA", subHeader1: "", subHeader2: "", type: '' },
                                    "policyNo": { name: this.commonFunction(item.PolicyNumber) ? item.PolicyNumber : 'NA', subHeader1: "", subHeader2: "", type: '' },
                                    "insuredFor": { name: this.commonFunction(item.InsuredFor) ? item.InsuredFor : "NA", subHeader1: "", subHeader2: "", type: '' },
                                    "policyType": { name: this.commonFunction(item.PolicyType) ? item.PolicyType : "NA", subHeader1: "", subHeader2: "", type: '' },
                                    "startDate": { name: this.commonFunction(item.StartDate) ? item.StartDate : "NA", subHeader1: "", subHeader2: "", type: '' },
                                    "coverAmount": { name: this.commonFunction(item.CoverAmount) ? item.CoverAmount : 0, subHeader1: '', subHeader2: "", type: 'amount' },
                                    "premiumAmount": { name: this.commonFunction(item.PremiumAmount) ? item.PremiumAmount : 0, subHeader1: "", subHeader2: "", type: 'amount' },
                                    "policyTerm": { name: this.commonFunction(item.PolicyTerm) ? item.PolicyTerm : "NA", subHeader1: "", subHeader2: "", type: '' },
                                    "ID": { name: item.ID, subHeader1: "", subHeader2: "", type: '' },
                                    "Type": { name: item.Type, subHeader1: "", subHeader2: "", type: '' },
                                }
                            }),
                            generalList: GeneralListFilter.map((item) => {
                                return {
                                    "policyName": { name: this.commonFunction(item.PolicyName) ? item.PolicyName : "NA", subHeader1: "", subHeader2: "", type: '' },
                                    "policyNo": { name: this.commonFunction(item.PolicyNumber) ? item.PolicyNumber : 'NA', subHeader1: "", subHeader2: "", type: '' },
                                    "insuredFor": { name: this.commonFunction(item.InsuredFor) ? item.InsuredFor : "NA", subHeader1: "", subHeader2: "", type: '' },
                                    // "policyType": { name: this.commonFunction(item.PolicyType) ? item.PolicyType : "NA", subHeader1: "", subHeader2: "", type: '' },
                                    "startDate": { name: this.commonFunction(item.StartDate) ? item.StartDate : "NA", subHeader1: "", subHeader2: "", type: '' },
                                    "coverAmount": { name: this.commonFunction(item.CoverAmount) ? item.CoverAmount : 0, subHeader1: '', subHeader2: "", type: 'amount' },
                                    "premiumAmount": { name: this.commonFunction(item.PremiumAmount) ? item.PremiumAmount : 0, subHeader1: "", subHeader2: "", type: 'amount' },
                                    "policyTerm": { name: this.commonFunction(item.PolicyTerm) ? item.PolicyTerm : "NA", subHeader1: "", subHeader2: "", type: '' },
                                    "ID": { name: item.ID, subHeader1: "", subHeader2: "", type: '' },
                                    "Type": { name: item.Type, subHeader1: "", subHeader2: "", type: '' },
                                }
                            }),
                            listLoader: false,
                        })
                    }
                    else {
                        this.setState({
                            healthList: [],
                            generalList: [],
                            listLoader: false,
                        })
                    }
                }

            }
            else {
                this.setState({
                    healthList: [],
                    generalList: [],
                    lifeList: [],
                    listLoader: false,
                })
            }
        }).catch((err) => {
            this.setState({
                healthList: [],
                generalList: [],
                lifeList: [],
                listLoader: false,
            })
        })

    }

    commonFunction = (val) => {
        if (val != undefined && val != "" && val != null) return true;
        return false;
    }

    // Edit Insurance
    editInsurance = (item) => {
        this.props.history.push({
            pathname: labels.Path.InsuranceDataEntry,
            state: { data: this.state.flag, id: item.ID.name }
        })
    }

    // Delete Insurance
    deleteInsurance = (item) => {
        this.setState({
            openDeletePopup: true, // Delete Popup open
            deleteID: item.ID.name,
            deleteType: item.Type.name
        })
    }

    // Delete API Integration
    // Insurance List Delete Functionality
    handleYesClick = () => {
        var data = {
            clientID: this.props.clientID,
            id: this.state.deleteID,
            flag: 3 // Delete Flag
        }

        if (this.state.flag == "Life") {
            getAddUpdateDeleteInsurance(data).then((resp) => {
                if (resp.status == "S") {
                    var deletedLifeData = this.state.lifeList.filter((item) => ((this.state.deleteID != item.ID.name)))
                    this.setState({
                        lifeList: deletedLifeData,
                        openDeletePopup: false,
                        openSnackBar: true,
                        message: resp.message,
                        severity: "success"
                    })
                }
                else {
                    this.setState({
                        openDeletePopup: false,
                        openSnackBar: true,
                        message: resp.message,
                        severity: "warning"
                    })
                }
            })
        }
        else {
            getAddInsurance(data).then((resp) => {
                if (resp.status == "S") {
                    if (this.state.deleteType == "Health") {
                        var deletedHealthData = this.state.healthList.filter((item, ind) => (this.state.deleteID != item.ID.name))
                        this.setState({
                            healthList: deletedHealthData,
                            openDeletePopup: false,
                            openSnackBar: true,
                            message: resp.message,
                            severity: "success"
                        })
                    }
                    else if(this.state.deleteType == "General") {
                        var deletedGeneralData = this.state.generalList.filter((item, ind) => (this.state.deleteID != item.ID.name))
                        this.setState({
                            generalList: deletedGeneralData,
                            openDeletePopup: false,
                            openSnackBar: true,
                            message: resp.message,
                            severity: "success"
                        })
                    }
                }
                else {
                    this.setState({
                        openDeletePopup: false,
                        openSnackBar: true,
                        message: resp.message,
                        severity: "warning"
                    })
                }
            })
        }
    }

    // Popup close
    handlePopupClose = () => {
        this.setState({ openDeletePopup: false })
    }

    // Snack bar
    handleToastClose = () => {
        this.setState({
            openSnackBar: false
        })
    }

    // Toggle button click
    toggleChange = (val) => {
        this.setState({
            flag: val
        }, () => {
            // Insurance Listing API function call
            this.insuranceList(this.state.flag);
        })
    }


    // Add Health or General Button
    addHealthandGeneral = () => {
        // if (this.state.selected) {
        //     // General Flag selected true
        //     this.props.history.push({
        //         pathname: labels.Path.InsuranceDataEntry,
        //         state: { data: "General" }
        //     })
        // } else {
        //     // Health Flag selected false
        //     this.props.history.push({
        //         pathname: labels.Path.InsuranceDataEntry,
        //         state: { data: "Health" }
        //     })

        // }

        if (this.state.flag == "Health") {
            // Health Flag 
            this.props.history.push({
                pathname: labels.Path.InsuranceDataEntry,
                state: { data: "Health" }
            })
        }
        else if (this.state.flag == "Life") {
            // Life Flag 
            this.props.history.push({
                pathname: labels.Path.InsuranceDataEntry,
                state: { data: "Life" }
            })
        }
        else {
            // General Flag 
            this.props.history.push({
                pathname: labels.Path.InsuranceDataEntry,
                state: { data: "General" }
            })
        }
    }

    render() {
        const { classes } = this.props
        try {
            return (
                <ErrorBoundary>
                    <Subheader heading={labels.productList.healthInsurance} description={labels.Transact.healthInsurance} />
                    <div className={classes.flex}>
                        <div className={classes.sideSpace}></div>
                        <div className={classes.mainCard}>
                            <div className={classes.buttonAlignment}>
                                <div style={{ textAlignLast: "left", marginLeft: 10, marginTop: 10, marginBottom: 10 }}>
                                    <OutlinedButton text={this.state.flag == "Health" ? "Add Health Insurance" : this.state.flag == "General" ? 'Add General Insurance' : 'Add Life Insurance'} onClick={this.addHealthandGeneral} />
                                </div>
                                {/* <div className={classes.marginTopAlignment}>
                                    <Toggle
                                        label1={"Health"}
                                        label2={"General"}
                                        selected={this.state.selected}
                                    // toggleSelected={this.toggleChange}
                                    />
                                </div> */}
                                <div>
                                    <Three_State_Toggle_Switch
                                        values={this.state.values}
                                        handleChangeToggle={(val) => { this.toggleChange(val) }}
                                        selectedFlag={this.state.flag}
                                    />
                                </div>
                            </div>
                            {/* <DynamicList loader={!this.state.selected ? this.props.loader : this.props.pmsLoader} transactionListClick={this.transactionListClick} list={!this.state.selected ? this.props.aifList : this.props.pmsList} grid={this.state.grid} header={this.state.headerList} count={this.state.count} enableIcon={this.state.enableIcon} popupList={this.state.popupList}></DynamicList> */}
                            <DynamicList loader={this.state.listLoader} list={this.state.flag == "Health" ? this.state.healthList : this.state.flag == "General" ? this.state.generalList : this.state.lifeList} grid={this.state.flag == "Health" || this.state.flag == "Life" ? this.state.grid : this.state.gridGeneral} header={this.state.flag == "Health" ? this.state.headerList : this.state.flag == "General" ? this.state.headerGeneralList : this.state.headerLifeList} count={this.state.flag == "Health" ? this.state.count : this.state.flag == "General" ? this.state.countGeneral : this.state.countLife} enableIcon={this.state.enableIcon} popupList={this.state.popupList}></DynamicList>
                            <CommonSnackbar message={this.state.message} open={this.state.openSnackBar} severity={this.state.severity} handlesnanckClose={this.handleToastClose} />
                            <DeletePopup header={"Confirmation"} onClick={this.handleYesClick} loader={this.state.deletePopupLoader} open={this.state.openDeletePopup} text={this.state.deletePopupText} onClose={this.handlePopupClose} />
                        </div>
                        <div className={classes.sideSpace}></div>
                    </div>
                </ErrorBoundary>
            );
        }
        catch (e) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => {
    return {
        sessionID: state.Login.sessionID,
        clientID: state.Login.logindetails.ClientID,
        logindetails: state.Login.logindetails,
        L5ID: state.Login.L5ID,
    }
}
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles(mergedStyle)(connect(mapStateToProps, null)(Insurance_List));
