import React from 'react';
import Button from '../custombutton/button'
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import colors from '../../utils/colors/colors';
import color from '../../utils/colors/colors';
import label from '../../utils/constants/labels';
import { updatebankdetails, getSearchBranchDetails } from '../../redux/actions/profile/profile'
import { bindActionCreators } from 'redux';
import { Tooltip } from '@material-ui/core';
import InformationTooltip from '../tooltip/informationtooltip';
const styles = theme => ({

    col: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.between('320', '374')]: {
            marginTop: '4%',
            width: '94%',
        },
        [theme.breakpoints.between('375', '424')]: {
            marginTop: '4%',
            width: '96%',
        },
        [theme.breakpoints.between('425', '600')]: {
            marginTop: '4%',
            width: '98%',
        },
    },
    // col2: {
    //     display: "flex",
    //     flexDirection: "row", modified by dulcy
    //     marginLeft: "5%",
    //     justifyContent: "flex-end"
    // },
    cols: {
        marginLeft: '2%',
        marginTop: '5%',
        width: '100%',
        display: "flex", flexDirection: "row",
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: ' wrap',
            justifyContent: 'center',
            marginLeft: '0%',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: ' wrap',
            justifyContent: 'center',
        },
    },
    // col3: {
    //     display: "flex",
    //     flexDirection: "row",modified by dulcy
    //     marginLeft: "20%"
    // },
    colheader1: {
        color: window.globalConfig.color.PRIMARY_BORDER,
        fontSize: "14px",
        textAlign: "left"
    },
    colheader2: {
        textAlign: "left",
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "14px"
    },

    header: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: "white",
        height: 50,
        fontWeight: 500,
        position: "sticky",
        top: "0px",
        zIndex: "1"
    },
    txtHeader: {
        padding: 15,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    dialogPaper: {
        width: "400px",
        height: "174px",
        // [theme.breakpoints.down('md')]: {
        //     width: '55%',
        // },
        [theme.breakpoints.down('920')]: {
            width: '100%',
        },

        //  overflowY: "hidden"
    },
    dialogPaper2: {
        width: "auto",
        height: "auto",
        [theme.breakpoints.down('920')]: {
            width: '100%',
        },

        //  overflowY: "hidden"
    },
    footerStyle: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        bottom: 0,
        right: "18px",
        position: "absolute",
        paddingBottom: 10,
        justifyContent: "flex-end"
    },
    clearIcon: {
        cursor: "pointer"
    }



});
class DeletePopup extends React.Component {
    constructor() {
        super();
        this.state = {

            open: false,

        }
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };
    onClosePopup = () => {
        if (this.props.onClose != undefined) {
            this.props.onClose()

        }
    }
    render() {
        const { classes } = this.props;
        return (
            <Dialog

                open={this.props.open}
                onClose={this.onClosePopup}
                onScroll={this.props.onScroll}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: this.props.showdefault == undefined ? classes.dialogPaper : this.props.className == undefined ? classes.dialogPaper2 : this.props.className }}
                style={this.props.style}

            >
                <div className={classes.popupcard}>
                    {this.props.header != undefined && <div className={classes.header}>
                        {
                            <div className={classes.txtHeader} >
                                <InformationTooltip type="normal" title={this.props.header} length={60}></InformationTooltip>
                                <span className={this.props.closeIcon != undefined ? this.props.closeIcon : classes.clearIcon} onClick={this.onClosePopup}>&#x2715;</span>
                            </div>
                        }
                    </div>}
                    <div style={{ padding: this.props.showdefault == undefined ? 15 : 0, marginTop: this.props.showdefault == undefined ? 7 : 0 }}>
                        {this.props.text}
                        {this.props.children}
                    </div>
                    {this.props.showdefault == undefined &&
                        <div className={classes.footerStyle}>
                            <div style={{ width: "22%" }}>
                                <Button page={"secondary"} name={label.messages.Nobutton} onClick={this.onClosePopup} />
                            </div>
                            <div style={{ width: "22%", marginLeft: 10 }}>
                                <Button page={"primary"} name={label.messages.YesButton} onClick={this.props.onClick} loader={this.props.loader} show={this.props.loader} loading={"true"} />
                            </div>

                        </div>}
                </div>
                {/* <CommonSnackbar severity={this.state.severity} handlesnanckClose={this.handlesnanckClose} open={this.state.open} message={this.props.message} /> */}

            </Dialog>
        )
    }
}
const mapStateToProps = state => ({
    login: state.Login.loginError,
    bankDetails: state.ProfileDetails.AllProfileDetails.bankDetails != "NA" ? state.ProfileDetails.AllProfileDetails.bankDetails : [],
    mandateDetails: state.ProfileDetails.AllProfileDetails.mandateDetails != "NA" ? state.ProfileDetails.AllProfileDetails.mandateDetails : [],
    logindetails: state.Login.logindetails,
    branchDetail: state.ProfileDetails.BranchName != null ? state.ProfileDetails.BranchName.length != 0 ? state.ProfileDetails.BranchName.data : [] : [],
    BankList: state.Dropdown.dropdowndetails.banks != null ? state.Dropdown.dropdowndetails.banks.length != 0 ? state.Dropdown.dropdowndetails.banks.map((item) => {
        return { "label": item.BankName, "value": item.BankID }
    }) : [] : []
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ updatebankdetails, getSearchBranchDetails }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(DeletePopup))

