import * as types from '../../constants/actionsType'
const initialState = {
    siptracker: [],
    siptrackerFilter: [],
    groupsiptracker: [],
    totalsiptracker: [],
    backupSiptracker: [],
    isSipTrackerLoading: false,
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SIP_TRACKER:
            let grp = action.payload.length == 0 ? [] : action.payload.filter((ele, ind) => ind === action.payload.findIndex(elem => elem.CategoryName === ele.CategoryName))
            return {
                ...state,
                siptracker: action.payload != null || action.payload.length != 0 ? action.payload.map((item) => {
                    return { ...item, "schemeName": item.SchemeName }
                }) : state.siptracker
                ,
                backupSiptracker: action.payload != null || action.payload.length != 0 ? action.payload.map((item) => {
                    return { ...item, "Scheme": item.SchemeName }
                }) : state.siptracker
                ,
                groupsiptracker: grp.length === 0 ? state.groupsiptracker : grp.map((item) => {
                    let a = action.payload.filter((value) => value.CategoryName === item.CategoryName)
                    return { "CategoryName": item.CategoryName, "CategoryCurrentvalue": item.CategoryCurrentvalue, "CategorySIPAmount": item.CategorySIPAmount, "count": a.length > 1 ? `${a.length} ${"Schemes"}` : `${a.length} ${"Scheme"}` }
                }),
                totalsiptracker: action.payload != null || action.payload.length != 0 ? [{ "Totalscheme": action.payload.length == 1 ? `${action.payload.length} ${"Scheme"}` : `${action.payload.length} ${"Schemes"}`, "TotalCurrentvalue": typeof (action.payload[0].TotalCurrentvalue) != "object" ? action.payload[0].TotalCurrentvalue : 0, "Totalsipamount": action.payload[0].TotalSIPAmount != null || action.payload[0].TotalSIPAmount != "" || typeof (action.payload[0].TotalSIPAmount) != "object" ? action.payload[0].TotalSIPAmount : 0 }] : state.totalsiptracker
                , isSipTrackerLoading: false
            }
        case types.SIPTRACKER_SORT_ALPHA:
            let siptracker = []
            // state.siptracker = []
            if (action.payload) {
                siptracker = state.siptracker.toSorted((a, b) => {
                    var textA = a.SchemeName.toUpperCase();
                    var textB = b.SchemeName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                })
            } else {

                siptracker = state.siptracker.toSorted((a, b) => {
                    var textA = a.SchemeName.toUpperCase();
                    var textB = b.SchemeName.toUpperCase();
                    return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
                })

            }
            
            return {
                ...state,
                siptracker: siptracker
            }
        case types.SIPTRACKER_SORT_BY_CURRENT_VALUE:
            if (action.payload == true) {

                return {
                    ...state,
                    siptracker: state.siptracker.toSorted((a, b) => {
                        return b.CurrentValue - a.CurrentValue;
                    }),
                }
            } else {
                return {
                    ...state,
                    siptracker: state.siptracker.toSorted((a, b) => {
                        return a.CurrentValue - b.CurrentValue;
                    }),
                }
            }
        case types.SIPTRACKER_SORT_BY_SIPAMOUNT:
            if (action.payload == true) {
                return {
                    ...state,
                    siptracker: state.siptracker.toSorted((a, b) => {
                        return b.SIPAmount - a.SIPAmount;
                    }),
                }
            } else {
                return {
                    ...state,
                    siptracker: state.siptracker.toSorted((a, b) => {
                        return a.SIPAmount - b.SIPAmount;
                    }),
                }
            }
        case types.SIPTRACKER_FILTER:
            var filterResult = []
            var filterdata = state.backupSiptracker
            // console.log(action.payload)
            if (action.payload.Scheme != '' && action.payload.Scheme != null) {
                var xraySchemeName = action.payload.Scheme.map(item => {
                    return item.label
                })
                if (xraySchemeName != null) {
                    filterResult = filterdata.filter(i => {
                        return xraySchemeName.includes(i.SchemeName)
                    })
                }
                else {
                    filterResult = filterdata
                }

            }
            else {
                filterResult = filterdata
            }

            state.siptracker = filterResult
            return {
                ...state,
                isSipTrackerLoading: false
            }
        case types.EMPTY_SIP_TRACKER:
            return {
                ...state,
                siptracker: [],
                groupsiptracker: [],
                totalsiptracker: [],
                backupSiptracker: [],
                isSipTrackerLoading: false
            }
        case types.LOADER_SIP_TRACKER:
            return {
                ...state,
                isSipTrackerLoading: action.payload
            }
        case types.SIPTRACKER_CLEAR:
            return {
                ...state,
                siptracker: state.backupSiptracker
            }

        default:
            return state
    }
}
export default reducer
