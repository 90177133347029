import React, { Component } from 'react';
import '../../utils/stylesheets/common/global/filters/categoryfilter.css';
import { connect } from 'react-redux';
import SchemeDropdown from '../../components/dropdown/dropdownschemes'
import { bindActionCreators } from 'redux';
import labels from '../../utils/constants/labels';
import { replaceNull } from '../../utils/commonfunction';
class XrayFilter extends Component {
    constructor() {
        super();
        this.state = {
            selectScheme: [],
            schemeid: 0,
            schemeShowClose: false,
            List: [], openDropdown: false,
            selectedListDropdown: [],
            searchvalue: "", backupList: [],
            amcList: []


        }
    }
    componentDidMount = () => {
        // console.log(this.props.List)
        let data = this.props.List.length == 0 ? [] : this.props.List.filter((ele, ind) => ind === this.props.List.findIndex(elem => elem.Scheme == undefined ? elem.SchemeName == ele.SchemeName : elem.Scheme === ele.Scheme)).map((item, index) => {
            // console.log(item.Scheme)
            return {
                ...item,
                label: item.Scheme || item.SchemeName

                , value: item.SchemeID || index, isChecked: false
            }
        })
        let amcData = this.props.List.length == 0 ? [] : this.props.List.filter((ele, ind) => ind === this.props.List.findIndex(elem => elem.AMC === ele.AMC)).map((item, index) => {

            return { ...item, label: item.AMC, value: item.AMC, isChecked: false }
        })
        if (this.props.selectvalue != undefined) {
            if (this.props.selectvalue.length != 0) {
                let ID = this.props.selectvalue.map((item) => {
                    return item.value
                })

                data = data.map((i) => {
                    return { ...i, isChecked: ID.includes(i.value) }
                })
            }
        }
        if (this.props.selectedAmcList != undefined) {
            if (this.props.selectedAmcList?.length != 0) {
                let ID = this.props.selectedAmcList.map((item) => {
                    return item.value
                })
                amcData = amcData.map((item) => {

                    return { ...item, label: item.label, value: item.value, isChecked: ID.includes(item.value) }
                })

            }
        }
        this.setState({
            amcList: [...amcData], List: replaceNull([...data], "NA")
        })
        if (this.props.accessClearAll != undefined) {
            this.props.accessClearAll.current = this.clearAll
        }
    }
    componentDidUpdate(prev, prevState) {
        const { selectvalue, List, selectedAmcList } = this.props
        let data = List
        // console.log(List)

        if (prev.selectvalue.length != selectvalue.length) {
            // console.log("updated", prev.selectvalue.length, selectvalue.length)
            data = List.filter((ele, ind) => ind === List.findIndex(elem => elem.Scheme === ele.Scheme)).map((item, index) => {
                return {
                    ...item,
                    label: item.Scheme || item.SchemeName
                    , value: item.SchemeID || index, isChecked: false
                }
            })
            // console.log(selectvalue.length)
            // if (selectvalue != undefined) {
            if (selectvalue.length != 0) {
                let ID = selectvalue.map((item) => {
                    return item.value
                })
                // console.log(ID)
                data = data.filter((ele, ind) => ind === data.findIndex(elem => elem.Scheme != undefined ? elem.Scheme === ele.Scheme : elem.SchemeName === ele.SchemeName)).map((item, index) => {
                    return {
                        ...item,
                        label: item.Scheme || item.SchemeName
                        , value: item.SchemeID || index, isChecked: ID.includes(item.SchemeID || index)
                    }
                })

            } else {
                data = data.filter((ele, ind) => ind === data.findIndex(elem => elem.Scheme != undefined ? elem.Scheme === ele.Scheme : elem.SchemeName === ele.SchemeName)).map((item, index) => {
                    return {
                        ...item,
                        label: item.Scheme || item.SchemeName
                        , value: item.SchemeID || index, isChecked: false
                    }
                })
            }
            // console.log(data)
            // }
            this.setState({ List: [...data] })

        }
        if (prev.selectedAmcList.length != selectedAmcList.length) {
            data = data.filter((ele, ind) => ind === data.findIndex(elem => elem.AMC === ele.AMC)).map((item) => {
                return {
                    ...item,
                    label: item.AMC
                    , value: item.AMC, isChecked: false
                }
            })
            if (selectedAmcList != undefined) {

                if (selectedAmcList?.length != 0) {
                    let ID = selectedAmcList.map((item) => {
                        return item.value
                    })
                    data = data.filter((ele, ind) => ind === data.findIndex(elem => elem.AMC === ele.AMC)).map((item) => {
                        return {
                            ...item,
                            label: item.AMC
                            , value: item.AMC, isChecked: ID.includes(item.AMC)
                        }
                    })
                }
            }
            // console.log(data)

            this.setState({ amcList: [...data] })
        }
    }
    clearAll = () => {
        this.setState({
            List: this.state.List?.map((item) => {
                return { ...item, isChecked: false }
            }),
            amcList: this.state.amcList.map((item) => {
                return { ...item, isChecked: false }
            })
        })
    }
    handleSchemeChange = (value) => {
        let ID = value.map((item) => {
            return item.value
        })
        this.setState({
            List: this.state.List.map((item) => {
                return { ...item, isChecked: ID.includes(item.value) }
            })
        })
        this.props.onSchemeChange(value)
    }
    render() {

        return (
            <div>
                <SchemeDropdown
                    type="Searchable"
                    handleOnChange={this.handleSchemeChange}
                    handleOutsideClick={this.props.handleOutsideClick}
                    List={this.state.List}
                    label={this.state.List.length >= 1 ? this.props.header : `${this.props.header}s`}
                    placeholder={labels.xRayFilter.all}
                    isSearchable={true}
                    //  value={this.props.selectvalue}
                    showborder={true}
                    filter={"close"}
                    searchPlaceholder={labels.xRayFilter.search} />

                {this.props.flag != labels.ManuHeading.FamilyReport && this.props.page == 'PortfolioX-Ray' && this.props.header == "Scheme" && <SchemeDropdown
                    type="Searchable"
                    handleOnChange={this.props.amcOnChange}
                    handleOutsideClick={this.props.handleOutsideClick}
                    List={this.state.amcList}
                    label={`AMC`}
                    placeholder={labels.xRayFilter.all}
                    isSearchable={true}
                    showborder={true}
                    filter={"close"}
                    searchPlaceholder={labels.xRayFilter.search} />}
            </div>

        )
    }
}
const mapStateToProps = state => ({
    dropdown: state.Dropdown.dropdowndetails,
    sessionID: state.Login.sessionID,
    clientID: state.Login.logindetails.ClientID,
    userID: state.Login.userID,
    date: state.Login.date,

})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(XrayFilter)













