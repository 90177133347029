import * as types from '../../constants/actionsType'
const initialState = {
    onetimeOrder:[]
  }
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case types.ONETIME_ORDER:
          return {
            ...state,
            onetimeOrder: action.payload
          }
      default:
        return state
    }
  }
  
  export default reducer
