import axios from 'axios';
import Networking from '../../utils/api/apiaccess';
import labels from '../../utils/constants/labels';
import apivalues from '../url/apiurl'
function getTransactionDetailsList(ProductID) {
  let BaseUrl = apivalues.SchemeDetail+`${encodeURIComponent(ProductID)}`

  let data = {};
  return Networking.GetApi(BaseUrl , "").then(result => { return result })
  .catch(function (error) {
    return { "status": "F", "message": labels.messages.apierror }
  });


}
export default getTransactionDetailsList;
