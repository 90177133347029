import React from 'react';
import color from '../../utils/colors/colors';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Warning from '../../utils/assets/images/Alert.svg'
import CommonSnackbar from '../../components/snackbar/snackbar'
import Button from '../../components/custombutton/button'
import DialogBox from '../dialog/dialog';
import colors from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
const styles = theme => ({
    image: {
        width: 30,
        height: 30,
        marginTop: 4,
        // [theme.breakpoints.down('xs')]: {
        //     width: 17,
        //     height: 17,
        // },
    },
    topBlock: {
        width: "100%",
        display: 'flex',
        backgroundColor: window.globalConfig.color.RosyBrown,
        //  paddingTop: '75px',
        // marginTop: "65px",
        textAlign: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('600')]: {
            // marginTop: "47px",
        }
    }, clearIcon: {
        float: 'right',
        cursor: 'pointer'
    },
    waringText: {
        color: window.globalConfig.color.BROWN,
        fontSize: 14,
        fontWeight: 500,
    },
    waringButton: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: window.globalConfig.color.WHITE,
        marginLeft: 30,
        borderRadius: 3,
        border: 'none',
    },
    topBlockEmpty: {
        backgroundColor: window.globalConfig.color.WHITE,
        width: "100%",
        display: 'flex',
        paddingTop: '75px',
        textAlign: 'center',
        paddingBottom: "3px",
    },
    headerBlock: {
    },
    GridBlockHearder: {
        display: 'flex',
        alignItems: "center",
        gap: 10,
        // padding: 12,
        [theme.breakpoints.down('xs')]: {
            gap: '5px',
        },
    },
    buttonContainer: {
    },
    hideDiv: {
        // marginTop: '67px',
    },
    dynamicBullet: {
        "&:before": {
            content: "",  /* Add content: \2022 is the CSS Code/unicode for a bullet */
            color: "red", /* Change the color */
            fontWeight: "bold", /* If you want it to be bold */
            display: "inline-block",/* Needed to add space between the bullet and the text */
            width: "1em", /* Also needed for space (tweak if needed) */
            marginLeft: "-1em",/* Also needed for space (tweak if needed) */

        }
    }

})
const List = [{ mainText: "PAN Card", subText: "" },
{ mainText: "Aadhaar Card - ", subText: "Front & Back" },
{ mainText: "Signature", subText: "" },
{ mainText: "Photo - ", subText: "Passport Size" }

]
class NotificationBlock extends React.Component {
    constructor() {
        super();

    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };
    handleClose = () => {

    }
    render() {
        const { classes } = this.props
        return (<>
            {this.props.show ?
                <div className={classes.topBlock}>
                    <div >
                        <div className={classes.GridBlockHearder} style={{ padding: this.props.onClick == undefined ? 0 : 10 }}>
                            <div>
                                <img src={Warning} className={classes.image} />
                            </div>
                            <div className={classes.headerBlock}>
                                <span className={classes.waringText}>{this.props.text}</span>
                            </div>
                            {this.props.onClick != undefined && <div className={classes.buttonContainer}>
                                <Button name={this.props.buttonName} onClick={this.props.onClick} page={"primary"} loader={this.props.loader} disabled={this.props.loader} />
                            </div>}
                        </div>
                    </div>
                </div>
                : <div className={classes.hideDiv} ></div>
            }
            <CommonSnackbar open={this.props.open} message={this.props.message} severity={this.props.severity} handlesnanckClose={this.props.handlesnanckClose} />
            <DialogBox open={this.props.dialogBoxOpen} handleClose={this.handleClose}>
                <div className={classes.txtHeader}><span className={classes.clearIcon} onClick={this.props.handleClose}>&#x2715;</span></div>

                <div style={{ display: "flex", flexDirection: "column", padding: 10, gap: 10 }}>
                    <h4 style={{ margin: 0, fontWeight: 600, color: window.globalConfig.color.BLACK_PRIMARY, fontSize: 20 }}>{labels.notificationBlock.startingKYC}</h4>
                    <h6 style={{ margin: 0, color: window.globalConfig.color.PRIMARY_BORDER, fontWeight: 100 }}>{labels.notificationBlock.ensureDocument}</h6>
                    {List.map((item, index) => {
                        return <div key={index} style={{ display: "flex", flexDirection: "row", alignItems: "center", columnGap: 10, height: 30 }}>
                            <span style={{ fontSize: 35, color: window.globalConfig.color.GREEN }}>&#8226;</span>
                            <div>
                                <span style={{ fontSize: 15, fontWeight: 600 }}>{item.mainText}</span>
                                <span>{item.subText}</span>
                            </div>
                        </div>
                    })}
                    <h6 style={{ margin: 0, color: window.globalConfig.color.LABLE_GRAY, fontWeight: 100 }}>{labels.notificationBlock.documentFormat}</h6>
                    <p style={{ color: window.globalConfig.color.BROWN, fontWeight: 500, fontSize: 12, textAlign: "center" }}>{labels.Login.redirecting} {this.props.count} {labels.Login.sec}</p>
                </div>

            </DialogBox>
        </>
        );
    }
}
const mapStateToProps = state => ({
    userDetails: state.Login.logindetails != null ? state.Login.logindetails : [],

    formNo: state.Login.formNo,
    permissionList: state.Login.companyDetails.length != 0 ? state.Login.companyDetails[0] : [],
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NotificationBlock))