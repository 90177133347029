import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
import Networking from '../../utils/api/apiaccess';
var axios = require('axios');
function cancelRunningSIP(SIPRegNo, FolioNo, ClientID) {
    let BaseUrl = ApiValues.CancelRunningSIP
    // var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
        "SIPRegNo": SIPRegNo,
        "FolioNo": FolioNo,
        "ClientID": ClientID,

    });
    return Networking.PostApi(BaseUrl, raw).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });

}
export default cancelRunningSIP
