import * as types from '../../constants/actionsType';
import Getlineperformance from '../../../api/schemedetails/performace';
export function SchemeLinePerformationDetails(schemecode, year) {
  return (dispatch) => {
    postLinePerformanceeDetails(dispatch, schemecode, year);
  }
}
function postLinePerformanceeDetails(dispatch, schemecode, year) {
  Getlineperformance(schemecode, "1Y")
    .then((data) => {
      if (data.status == "S") {
        if (data.data != undefined) {
          if (data.data.length != 0) {
            dispatch({
              type: types.LINEPERFORMANCE,
              payload: data.data,
            });
          } else {
            dispatch({
              type: types.EMPTY_LINEPERFORMANCE,
              payload: [],
            });
          }
        }
      }

    })
    .catch((error) => {

    })
  Getlineperformance(schemecode, "6M")
    .then((data) => {
      if (data.status == "S") {
        if (data.data != undefined) {
          if (data.data.length != 0) {
            dispatch({
              type: types.SIX_MONTH_LINEPERFORMANCE,
              payload: data.data,
            });
          } else {
            dispatch({
              type: types.EMPTY_SIX_MONTH_LINEPERFORMANCE,
              payload: [],
            });
          }
        }
      }
    })
    .catch((error) => {

    })
  Getlineperformance(schemecode, "3Y")
    .then((data) => {
      if (data.status == "S") {
        if (data.data != undefined) {
          if (data.data.length != 0) {
            dispatch({
              type: types.THREE_YEAR_LINEPERFORMANCE,
              payload: data.data,
            });
          } else {
            dispatch({
              type: types.EMPTY_THREE_YEAR_LINEPERFORMANCE,
              payload: [],
            });
          }
        }
      }

    })
    .catch((error) => {

    })
  Getlineperformance(schemecode, "5Y")
    .then((data) => {
      if (data.status == "S") {
        if (data.data != undefined) {
          if (data.data.length != 0) {
            dispatch({
              type: types.MAX_LINEPERFORMANCE,
              payload: data.data,
            });
          } else {
            dispatch({
              type: types.EMPTY_MAX_LINEPERFORMANCE,
              payload: [],
            });
          }
        }
      }

    })
    .catch((error) => {

    })
}
export const NetworkErrorSchemeDetails = (online) => {
  return {
    type: types.NETWORK_SCHEME_DETAILS,
    payload: online
  }
}
