/* name should be camel case in all function */
//const BaseUrl='https://jsonplaceholder.typicode.com/todos';
import Networking from '../../utils/api/apiaccess';
import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
var axios = require('axios');
function getPerformanceDetails(schemecode,year) {
  
  return Networking.GetApi(`${ApiValues.Performance}${schemecode}${"/"}${year}`, "").then(result => { return result })
  .catch(function (error) {
    return { "status": "F", "message": labels.messages.apierror }
  });


}
export default getPerformanceDetails;
