import React, { Component } from 'react'
// import Tabs from '../tabs/tabs';
import '../../utils/stylesheets/layout/layout.css'
import Toolbar from '../../components/toolbar/toolbar';
import Label from '../../utils/constants/labels'
import { connect } from 'react-redux';
import { loginsession } from '../../redux/actions/login/loginaction'
import { bindActionCreators } from 'redux';
import secureStorage from '../../utils/securestorage';
import Tabs from '../tabs/mutual_fund_tabs'
import { scrollToTopPage } from '../../utils/commonfunction';
import { withStyles } from '@material-ui/core/styles';
const styles = (theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "10% 80% 10%",
    marginTop: "5%",
    [theme.breakpoints.down("920")]: {
    gridTemplateColumns:"3% 91% 3%"
    },
    // [theme.breakpoints.down("600")]: {
    //   marginTop: "50%",
    // }
  }
})
class container extends Component {

  componentDidMount() {
    scrollToTopPage()
  }


  // componentDidUpdate(prevProps) {
  //   const { clientID, date, location } = this.props;
  //   if (prevProps.location != location) {
  //     // 
  //   }
  // }
  render() {
    const { classes } = this.props
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Toolbar title={Label.menu.mutualFunds} productID={1} childFunc={this.props.childFunc} data={this.props.mfHeader2}></Toolbar>
        {/* <Tabs page={this.props.location} /> */}

        <div className={classes.root}>
          <div ></div>
          <div >
            <Tabs page={this.props.location}  isReachedBottom={this.props.isReachedBottom}/>
          </div>
          <div ></div>
        </div>

        {/* <Footer></Footer> */}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  date: state.Login.date,
  mfHeader2: state.Card.mutualfund,
  sessionID: state.Login.sessionID,
  clientID: state.Login.logindetails.ClientID,
  userID: state.Login.userID,
  date: state.Login.date,
  logindetails: state.Login.logindetails
})
export default withStyles(styles)(connect(mapStateToProps, null)(container))