import React, { Component } from 'react';
import '../../utils/stylesheets/common/global/x-ray/transaction.css';
import { connect } from 'react-redux';
import { MContext } from '../../components/pagination/renderpage';
import { getTransactionListing, transactionfilter, equity_transactionfilter, GetTransaction } from '../../redux/actions/transaction/actions';
import { bindActionCreators } from 'redux';
import Header from '../../components/report_common_header/report_common_header';
import NoData from '../../components/nodata/nodata';
import Loader from '../../components/loader/loader';
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import apivalues from '../../api/url/apiurl';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import getAllcommonDownloadDetails from '../../api/commondownload/commondownload';
import CommonSnackbar from '../../components/snackbar/snackbar'
import commonstyletable from '../../utils/stylesheets/table/commontablestyle'
import { withStyles } from '@material-ui/core/styles';
import combineStyle from '../../utils/stylesheets/combainstyle/combainstyle'
import constants from '../../utils/constants/apiconstant';
import colors from '../../utils/colors/colors';
import { NegativeFinding, replaceNull } from '../../utils/commonfunction';
import Pagination from '../../components/pagination/renderpage';
import { async } from 'q';
import Networking from '../../utils/api/apiaccess';
const styles = theme => ({
  dateStyle: {
    textAlign: "left",
    paddingLeft: "10px",
    [theme.breakpoints.down("920")]: {
      gridArea: "dategrid",
      padding: 0
    }
  },
  schemeStyle: {
    textAlign: "left",
    // width: "14vw",
    wordBreak: "break-word",
    paddingRight: 10,
    [theme.breakpoints.down("920")]: {
      gridArea: "schemegrid",
      padding: 0,
      width: "max-content"
    }
  },
  assetClassStyle: {
    textAlign: "left",
    [theme.breakpoints.down("920")]: {
      gridArea: "assetClassgrid"
    }
  },
  transactionTypeStyle: {
    textAlign: "left",
    [theme.breakpoints.down("920")]: {
      gridArea: "transactionTypegrid",
      textAlign: "right"
    }
  },
  folioNumberStyle: {
    textAlign: "right",
    [theme.breakpoints.down("920")]: {
      gridArea: "folioNumbergrid",
      textAlign: "left"
    }

  },
  unitsStyle: {
    textAlign: "right",
    [theme.breakpoints.down("920")]: {
      gridArea: "unitgrid",
      textAlign: "right"
    }

  },
  navStyle: {
    textAlign: "right",
    [theme.breakpoints.down("920")]: {
      gridArea: "navgrid",
      textAlign: "left"
    }

  },
  amountStlye: {
    textAlign: "right",
    paddingRight: "10px",
    [theme.breakpoints.down("920")]: {
      gridArea: "amountgrid",
      textAlign: "right",
      paddingRight: 0
    }

  },
  scrolled: {
    [theme.breakpoints.up("920")]: {
      position: "fixed",
      top: 65,
      // left: "25%",
      width: "77.5%"
    }
  },
  rightAlignHeader: {
    [theme.breakpoints.down("920")]: {
      textAlign: "right",
      fontWeight: 500,
      color: colors.LABLE_GRAY,
      fontSize: 11
    }
  },
  leftAlignheader: {
    [theme.breakpoints.down("920")]: {
      textAlign: "left",
      fontWeight: 500,
      color: colors.LABLE_GRAY
      , fontSize: 11
    }

  },
  desktopResponsive: {
    display: "block",
    [theme.breakpoints.down('920')]: {
      display: 'none',
      // gridArea:"none !important"
    }
  },
  headerMobileResponsive: {
    display: "none",
    [theme.breakpoints.down('920')]: {
      display: 'block',
      // gridArea:"none !important"
    }
  }
})
class TransactionGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactionList: [],
      transactionLazyloadlist: [],
      Filters: 0,
      timecount: 6000,
      open: false,
      accexcel: false,
      accpdf: false,
      addStickyClass: false,
      productID: 1,
      pdfUrl: "",
      excelUrl: "",
      LazyLoadtransactionlist: [],
      isDesktopView: true
    }
    this.handleScroll = this.handleScroll.bind(this);
  }
  resize() {
    let currentHideNav = (window.innerWidth >= 920);
    if (currentHideNav !== this.state.isDesktopView) {
      // 
      this.setState({ isDesktopView: currentHideNav });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this))
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    // this.setState({
    //   transactionList: this.props.transactionList.slice(0, 10),
    // })
    // window.addEventListener('scroll', this.handleScroll)
    this.setState({ open: this.props.catchmessage })
    if (this.props.flag != labels.ManuHeading.familyPortfolio) {
      this.props.GetTransaction(this.props.dropdowntransaction, this.props.flag)
      this.props.filterSubmit([])
    }

    switch (this.props.flag) {
      case "mutualfunds":
        return this.setState({ productID: 1, pdfUrl: apivalues.pdftran, excelUrl: apivalues.exceltran })
        break;
      case "equity":
        return this.setState({ productID: 2, pdfUrl: apivalues.pdfEquityTrans, excelUrl: apivalues.excelEquityTrans })
        break;
      case constants.Common.Bonds:
        return this.setState({ productID: 3, pdfUrl: apivalues.pdfBondsTrans, excelUrl: apivalues.excelBondsTrans })
        break;
      case constants.Common.GB:
        return this.setState({ productID: 4, pdfUrl: apivalues.pdfGBTrans, excelUrl: apivalues.excelGBTrans })
        break;
      case constants.Common.FD:
        return this.setState({ productID: 5, pdfUrl: apivalues.pdftran, excelUrl: apivalues.exceltran })
        break;
      case labels.ManuHeading.familyPortfolio:
        return this.setState({ productID: 13, pdfUrl: apivalues.pdfFamilyTran, excelUrl: apivalues.excelFamilyTran })
        break;

      default:
        break;
    }
  }
  handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 250) {
      this.setState({ addStickyClass: true });
    }
    else {
      this.setState({ addStickyClass: false });
    }
  }


  componentDidUpdate(prevProps) {
    window.addEventListener("scroll", this.handleScroll);
    const { clientID, date, sessionID, dataTab, transactionList, catchmessage } = this.props
    if (prevProps.dataTab !== dataTab) {
    }
    if (this.state.transactionList !== transactionList) {
      this.setState({
        transactionList: transactionList
      })
    }
    if (prevProps.catchmessage !== catchmessage) {
      this.setState({ open: catchmessage })

    }
  }
  downloadclick = async (ext) => {
    var pdfexcel = false
    var filename = ""
    var url = ""
    filename = `${labels.DownloadLabel.transaction}${ext}`
    let data = {}
    // if () {
    if (this.state.accpdf == false && ext === "pdf") {
      pdfexcel = true;
      this.setState({ accpdf: true })
      data = {
        "ClientID": this.props.logindetails.ClientID, "AccountID": 0, "ReportID": labels.Report.transaction, "WithRedeem": false, "AsOnDate": moment(this.props.date).format("DD MMM YYYY"),
        "SubReport": 0, "DownloadAs": 1, "ProductID": this.state.productID, "FamilyID": 0, "L5ID": 0, "L4ID": 0
      }
      //format(MM/DD/YYYY)
      url = this.state.pdfUrl + `?clientId=${this.props.logindetails.ClientID}&asOnDate=${moment(this.props.date).format("DD MMM YYYY")}&ProductID=${this.state.productID}&FamilyID=${0}&L5ID=${this.props.L5ID}&L4ID=${0}`
    }
    // }
    // if () {
    if (this.state.accexcel == false && ext === "xlsx") {
      pdfexcel = true;
      this.setState({ accexcel: true })
      data = {
        "ClientID": this.props.logindetails.ClientID, "AccountID": 0, "ReportID": labels.Report.transaction, "WithRedeem": false, "AsOnDate": moment(this.props.date).format("DD MMM YYYY"),
        "SubReport": 0, "DownloadAs": 2, "ProductID": this.state.productID, "FamilyID": 0, "L5ID": 0, "L4ID": 0
      }
      url = this.state.excelUrl + `?clientId=${this.props.logindetails.ClientID}&asOnDate=${moment(this.props.date).format("DD MMM YYYY")}&ProductID=${this.state.productID}&FamilyID=${0}&L5ID=${this.props.L5ID}&L4ID=${0}`
    }
    // }
    // else {

    // }
    // url = await Networking.PostApi(apivalues.ExportDownloadURL, data).then((res) => {
    //   let a = ""
    //   if (res.status == "S" && res.data != "") {
    //     a = res.data
    //   } else {
    //     if (ext === "pdf") {
    //       this.setState({ accpdf: false });
    //     }
    //     if (ext === "xlsx") {
    //       this.setState({ accexcel: false });
    //     }
    //   }
    //   return a
    // }).catch((er) => {
    //   if (ext === "pdf") {
    //     this.setState({ accpdf: false });
    //   }
    //   if (ext === "xlsx") {
    //     this.setState({ accexcel: false });
    //   }
    //   return ""
    // })
    if (pdfexcel == true && url.length != 0) {
      getAllcommonDownloadDetails(url, ext, filename, this.props.logindetails?.SessionID).then((response) => {
        if (response === "success") {
          if (ext === "pdf") {

            this.setState({ accpdf: false })
          }
          if (ext === "xlsx") {
            this.setState({ accexcel: false })
          }
        } else {
          this.setState({ open: true })
          if (ext === "pdf") {
            this.setState({ accpdf: false })
          }
          if (ext === "xlsx") {
            this.setState({ accexcel: false })
          }
        }
      })
    } else {
      this.setState({ open: true })

    }
  }
  handlesnanckClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false })
  };
  handleclear = () => {
    this.setState({ transactionList: [], Filters: [] })
    this.props.filterSubmit([])
  }
  setTransaction = (value) => {
    this.setState({ List: value })
  }
  getSchemeHolding = (item) => {

  }
  filters = (value) => {
    this.props.filterSubmit(value)

  }
  render() {


    const { classes } = this.props
    try {
      return (
        <div>
          {this.props.isLoadingtransactionList == true ?
            <Loader page="skeletonreport" />
            :
            <>
              <Header
                showfilter={true}
                showdownload={this.props.showdownload == undefined ? true : this.props.showdownload}
                selected={false}
                filterHeader={this.props.filterHeader}
                List={this.props.List}
                disableExports={false}
                data={this.props.transactionList}
                Reportlist={this.props.ReportList}
                showdropdown={this.props.showdropdown}
                onclick={this.downloadclick}
                mindate={this.props.minDate}
                maxdate={this.props.maxDate}
                handleclear={this.handleclear}
                pdfload={this.state.accpdf}
                newxcelLoad={this.state.accexcel}
                className="contentcolTransactionTypeValue"
                page={"Transaction"}
                flag={this.props.flag}
                header={this.props.header}
                scheme={this.props.transactionList.length == 1 ? this.props.transactionList.length + " Transaction" : this.props.transactionList.length + " Transactions" && this.props.transactionList.length == 0 ? <NoData /> : this.props.transactionList.length + " Transactions"}
                filter={(value) => this.filters(value)}
                multifilterselect={this.state.Filters == 0 ? this.state.Filters : this.state.Filters} />

              {this.props.transactionList.length != 0 && <div style={{ flex: 8, marginBottom: "5%", marginTop: "2%", minHeight: "66vh" }}>
                {this.props.title.length > 0 &&
                  <div className={[this.props.grid, classes.tableHeader, this.state.addStickyClass && classes.scrolled].join(' ')}>
                    {/* {this.props} */}
                    {this.props.title[0]?.name != undefined && <div className={[classes.dateStyle, classes.desktopResponsive].join(" ")}>{this.props.title[0]?.name}</div>}
                    {this.props.title[1]?.name != undefined && <div className={classes.schemeStyle}>{this.props.title[1].name}</div>}
                    {this.props.flag == "mutualfunds" && this.props.title[2]?.name != undefined && <div className={[classes.assetClassStyle, classes.desktopResponsive].join(" ")}>{this.props.title[2]?.name}</div>
                    }
                    {this.props.title[3]?.name != undefined && <div className={[classes.transactionTypeStyle, classes.desktopResponsive].join(" ")}>{this.props.title[3]?.name}<br />{this.props.flag != "mutualfunds" ? this.props.title[3].subheader :
                      ""}</div>}
                    {this.props.flag == "mutualfunds" && this.props.title[4]?.name != undefined && <div className={[classes.folioNumberStyle, classes.desktopResponsive].join(" ")}>{this.props.title[4]?.name}</div>
                    }
                    {this.props.title[5]?.name != "" && <div className={[classes.unitsStyle, classes.desktopResponsive].join(" ")}>{this.props.title[5]?.name}</div>}
                    {this.props.title[6]?.name != "" && <div className={[classes.navStyle, classes.desktopResponsive].join(" ")}>{this.props.title[6]?.name}</div>}
                    {this.props.title[7]?.name != undefined && <div className={[classes.amountStlye, classes.desktopResponsive].join(" ")}>{this.props.title[7]?.name}</div>}
                    {this.props.title[8]?.name != undefined && <div className={[classes.amountStlye, classes.desktopResponsive].join(" ")}>{this.props.title[8]?.name}</div>}
                    {this.props.title[9]?.name != undefined && <div className={[classes.amountStlye, classes.desktopResponsive].join(" ")}>{this.props.title[9]?.name}</div>}
                    {this.props.title[10]?.name != undefined && <div className={[classes.amountStlye, classes.desktopResponsive].join(" ")}>{this.props.title[10].name}</div>}

                  </div>
                }
                {/* {console.log(this.props.transactionList)} */}
                <Pagination enableLazyLoading={false}
                  data={this.props.transactionList}>
                  <MContext.Consumer>
                    {(context) => {
                      return context.map((row, index) => {
                        // console.log(row.RedemptionLoad)
                        return <div key={index} className={[this.props.grid, classes.subCategoryGroupContainer, index == 0 && classes.borderRadiousFirstIndex,].join(' ')}>
                          {row.Date != undefined && <div className={[classes.dateStyle, "dategrid"].join(" ")}><div className={[classes.leftAlignheader, classes.headerMobileResponsive].join(" ")}>{this.props.title[0].name}</div>
                            <div className={[classes.subCatgoryValue,].join(' ')}>{row.Date}</div>
                          </div>
                          }
                          {row.Scheme != undefined && <div className={[classes.schemeStyle, "schemegrid"].join(" ")}><div className={[classes.leftAlignheader, classes.headerMobileResponsive].join(" ")}>{this.props.title[1].name}</div> <div className={[classes.subCatgoryValue,].join(' ')}>{
                            row.Scheme.length < 45 ?
                              <label>
                                {row.Scheme}
                              </label> : <Tooltip TransitionComponent={Zoom} title={row.Scheme}>
                                <label style={{ textOverflow: 'ellipsis' }}>
                                  {(((row.Scheme).substring(0, 45 - 3)) + '...')}
                                </label></Tooltip>}</div></div>}
                          {this.props.flag == "mutualfunds" && row.AssetClass != undefined && this.props.title[2]?.name != undefined &&
                            <div><div className={[classes.leftAlignheader, classes.headerMobileResponsive].join(" ")}>{this.props.title[2].name}</div> <div className={[classes.subCatgoryValue, classes.assetClassStyle, "assetClassgrid"].join(' ')}>{row.AssetClass}</div>
                            </div>}
                          {row.TransactionType != undefined && this.props.title[3]?.name != undefined &&
                            <div>
                              <div className={[this.props.flag == "mutualfunds" ? classes.rightAlignHeader : classes.leftAlignheader, classes.headerMobileResponsive].join(" ")}>
                                {this.props.title[3].name}
                              </div>
                              <div className={[classes.subCatgoryValue, classes.transactionTypeStyle, "transactionTypegrid", this.props.flag == "mutualfunds" ? classes.rightAlignHeader : classes.leftAlignheader].join(' ')} style={{ color: colors.BLACK_PRIMARY }}>
                                {row.TransactionType}
                                <br />
                                {this.props.flag != "mutualfunds" ?
                                  <><span style={{ color: row.BuyorSell == "" || row.BuyorSell == "NA" ? colors.PRIMARY_BLACK : row.BuyorSell.toLowerCase() == "buy" ? colors.GREEN : colors.red }}>{row.BuyorSell}</span></> : <></>}</div>
                            </div>}
                          {this.props.flag == "mutualfunds" && row.Folio != undefined && this.props.title[4]?.name != undefined && <div><div className={[classes.leftAlignheader, classes.headerMobileResponsive].join(" ")}>{this.props.title[4].name}</div> <div className={[classes.subCatgoryValue, classes.folioNumberStyle].join(' ')}>{row.Folio}</div></div>
                          }
                          {row.Unit != undefined && this.props.title[5]?.name != undefined && <div><div className={[classes.rightAlignHeader, classes.headerMobileResponsive].join(" ")}>{this.props.title[5].name}</div> <div className={[classes.subCatgoryValue, classes.unitsStyle, "unitsgrid"].join(' ')}>{this.props.flag != "mutualfunds" ? row.Unit : parseFloat(row.Unit).toFixed(3)}</div></div>}
                          {row.NAV != undefined && this.props.title[6]?.name != undefined && <div><div className={[classes.leftAlignheader, classes.headerMobileResponsive].join(" ")}>{this.props.title[6].name}</div> <div className={[classes.subCatgoryValue, classes.navStyle, "navgrid"].join(' ')}><span className={classes.subcategoryRupee}>{this.props.flag != "Fixed Deposits" && labels.currency.Rupee}</span>{parseFloat(row.NAV).toLocaleString('en-IN')}<span>{this.props.flag == "Fixed Deposits" && "%"}</span></div></div>}
                          {row.Value != undefined && <div><div className={[classes.rightAlignHeader, classes.headerMobileResponsive].join(" ")}>{this.props.title[7]?.name}</div><div className={[classes.subCatgoryValue, classes.amountStlye, "amountgrid"].join(' ')}><span className={classes.subcategoryRupee}>{labels.currency.Rupee}</span>{NegativeFinding(row.Value, "", "", "", "number")}</div>
                          </div>}
                          {row.StampDuty != undefined && this.props.title[8]?.name != undefined && <div><div className={[classes.rightAlignHeader, classes.headerMobileResponsive].join(" ")}>{this.props.title[8].name}</div><div className={[classes.subCatgoryValue, classes.amountStlye, "amountgrid"].join(' ')}><span className={classes.subcategoryRupee}>{labels.currency.Rupee}</span>{NegativeFinding(row.StampDuty, "", "", "", "number")}</div>
                          </div>}
                          {row.STT != undefined && this.props.title[9]?.name != undefined && <div><div className={[classes.rightAlignHeader, classes.headerMobileResponsive].join(" ")}>{this.props.title[9].name}</div><div className={[classes.subCatgoryValue, classes.amountStlye, "amountgrid"].join(' ')}><span className={classes.subcategoryRupee}>{labels.currency.Rupee}</span>{NegativeFinding(row.STT, "", "", "", "number")}</div>
                          </div>}

                          {row.Load != undefined && this.props.title[10]?.name != undefined && <div>
                            <div className={[classes.rightAlignHeader, classes.headerMobileResponsive].join(" ")}>{this.props.title[10]?.name}</div>
                            <div className={[classes.subCatgoryValue, classes.amountStlye, "amountgrid"].join(' ')}><span className={classes.subcategoryRupee}>{labels.currency.Rupee}</span>{NegativeFinding(replaceNull(row.Load, 0), "", "", "", "number")}</div>
                          </div>}
                        </div>
                      })
                    }

                    }
                  </MContext.Consumer>

                </Pagination>
              </div>}
            </>
          }

          <CommonSnackbar open={this.state.open} handlesnanckClose={this.handlesnanckClose} severity={'warning'} message={labels.messages.apierror} />

        </div>
      )
    }
    catch (err) {
      console.log(err)
      return <Redirect to={labels.Path.NotFound} />
    }
  }
}
const mapStateToProps = state => {
  return {
    logindetails: state.Login.logindetails
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getTransactionListing, equity_transactionfilter, transactionfilter, GetTransaction, }, dispatch)
}
const mergestyles = combineStyle(styles, commonstyletable)
export default withStyles(mergestyles)(connect(mapStateToProps, mapDispatchToProps)(TransactionGrid));