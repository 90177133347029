import React from 'react';
import {
  PieChart, Pie, Tooltip, Cell, Legend, ResponsiveContainer
} from 'recharts';
import { withRouter } from "react-router";
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import Loader from '../loader/loader';
import './donutchart.css';
// import { withStyles } from '@material-ui/core/styles';
import NoData from '../nodata/nodata';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CustomTooltip from '../tooltip/customtooltip'
import ErrorBoundary from '../Errorhandling/Errorhandle';
import colors from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import secureStorage from '../../utils/securestorage';
function Portfoliochart(props) {
  const classes = useStyles();
  let name = ""
  let value = ""
  let bordercolor = "'"
  const pieenter = (item) => {
    name = item.name
    value = item.displayValue == undefined ? item.value : item.displayValue
    bordercolor = item.fill
  }
  const piechartClick = (item) => {
    // 

    if (item.name.toLowerCase() === "mutual fund") {
      props.history.push(labels.Path.MutualTab)
    } else if (item.name.toLowerCase() === "stocks/etf" || item.name.toLowerCase() == "stocks / etfs" || item.name.toLowerCase() === "stocks and etf") {
      props.history.push(labels.Path.Equity)
    }

    else if (item.name.toLowerCase() === "gold") {
      props.history.push(labels.Path.GoldBondsTab)
    }
    else if (item.name.toLowerCase() === "bonds" || item.name.toLowerCase() == "traded bonds") {
      props.history.push(labels.Path.BondsTab)
    }
    else if (item.name.toLowerCase() === "aif/pms") {
      props.history.push(labels.Path.AIFTab)
    }
    else if (item.name.toLowerCase() === "fixed income") {
      props.history.push(labels.Path.FDTab)
    }
    else if (item.name.toLowerCase() === "bullion") {
      props.history.push(labels.Path.GoldBondsTab)
    }
    else if (item.name.toLowerCase() === "real estate") {
      props.history.push(labels.Path.RealEstateTab)
    }
   
  }
  const rendercustomtooltip = () => {
    return <CustomTooltip page={"barchart"} name={name} value={value} bordercolor={bordercolor} />
  }
  //
  return <div className={classes.root}>
    {props.loader == true ?

      <Loader page={"chartnormal"} />

      :
      <ErrorBoundary>
        {props.data.length > 0
          ? (
            <div>
              <div className={classes.desktopResponsive}>
                <div className={classes.chartWrap} id="chartWrap">
                  <div className={classes.chartFluid} id="chartFluid">
                    <ResponsiveContainer >
                      <PieChart>
                        <Pie dataKey="value" data={props.data} outerRadius={"65%"} innerRadius={65}
                          onMouseEnter={pieenter}
                          onClick={(e) => piechartClick(e)}
                        >
                          {
                            props.data.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} />
                            ))
                          }
                        </Pie>
                        <Tooltip content={rendercustomtooltip} wrapperStyle={{
                          paddingLeft: "30px", fontSize: "14px"
                        }} />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div >
              </div>
              <div className={classes.mobileResponsive}>
                <div className={classes.ResponsivechartContainerWrap} xs={12} sm={12}>
                  <div className={classes.ResponsivechartContainer}>
                    <ResponsiveContainer >
                      <PieChart>
                        <Pie dataKey="value" data={props.data}
                          outerRadius={"85%"} innerRadius={80}
                          onClick={(e) => piechartClick(e)}
                          onMouseEnter={pieenter}
                        >
                          {
                            props.data.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} />
                            ))
                          }
                        </Pie>
                        <Tooltip content={rendercustomtooltip} wrapperStyle={{
                          paddingLeft: "30px", fontSize: "14px"
                        }} />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div >
              </div>
            </div>

          )
          : (
            <div style={{ width: '86%', textAlign: 'center', display: "flex", alignItems: "center", justifyContent: "center", border: `1px solid ${window.globalConfig.color.BORDER}`, borderRadius: 8, height: props.height == undefined ? 235 : 148, }}>
              <NoData page="report" style={{ height: 0 }} />
            </div>
          )
        }
      </ErrorBoundary>
    }
  </div >
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  progress: { padding: '50px 0', width: '100%  ', height: '100%', textAlign: 'center' },
  chartWrap: {
    // overflow: 'hidden',
    [theme.breakpoints.down("600")]: {
      marginLeft: 10
    }
    //marginTop: theme.spacing(2)
  },
  desktopResponsive: {
    display: 'block !important',

    [theme.breakpoints.down("600")]: {
      display: 'none !important',
    },
  },
  mobileResponsive: {
    display: 'none !important',
    [theme.breakpoints.down("600")]: {
      display: 'block!important',
    },
  },
  ResponsivechartContainer: {
    height: 403,
    // minWidth: '338px',
    width: '100%'

  },
  chartContainer: {
    // width: '36%',
    // height:'100%',
    minWidth: 400,
    height: 432,
    marginLeft: theme.spacing(1) * -5.8,
    // marginLeft: '-44.4px',
    marginTop: "-2em",
    display: "flex",


  },
  ResponsivechartContainerWrap: {
    overflow: 'hidden',
    width: '100%',
  },
  chartContainerWrap: {
    overflow: 'hidden',
    marginTop: theme.spacing(2)
  },
  chartFluid: {
    width: '100%',
    height: '100%',
    minWidth: 406,
    height: 432,
    marginLeft: '-44.4px',
    // marginLeft: theme.spacing(1) * -5.8,
    marginTop: "-1.5em",
    display: "flex",
    // [theme.breakpoints.up("1024")]:{
    //   minWidth:"320px !important",
    //    height: "287px !important",
    //   marginTop: "-1em",
    // },
    [theme.breakpoints.up("769px")]: {
      width: '100%',
      height: '100%',
      minWidth: 410,
      height: 432,
      marginTop: "-2em",
      display: "flex",
      marginLeft: '0 !important',
    },
    // [theme.breakpoints.down("xs")]:{
    //   display:'flex',
    //   justifyContent:'center',
    // },

  },
}));
const mapStateToProps = state => {
  return {
    // portfolioChart: state.Sample.portfolioChart,
    // isLoadingportfolioChart: state.Sample.isLoadingportfolioChart,
  };
};
// const mapDispatchToProps = dispatch => ({
// });
export default withRouter(connect(mapStateToProps, null)(Portfoliochart));
