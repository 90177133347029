//created by yogitha
import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
import Networking from '../../utils/api/apiaccess';
var axios = require('axios');
function getTrackEquityImportedDetails(clientId,ProductID,FamilyID,L5ID,L4ID) {
    let data={};
    data.clientId=clientId;
    data.ProductID=ProductID;
    data.FamilyID=FamilyID;
    data.L5ID=L5ID;
    data.L4ID=L4ID;
    return Networking.PostApi(ApiValues.TrackImported , data).then(result => { return result })
  .catch(function (error) {
    return { "status": "F", "message": labels.messages.apierror }
  });

   
}
export default getTrackEquityImportedDetails