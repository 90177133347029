import React from "react";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import ToggleButton from "../../components/toggle/toggle";
import FilterImage from "../../utils/assets/images/filter.png";
import SchemeDropDown from "../../components/dropdown/dropdownschemes";
import moment from "moment";
import CommonCheckbox from "../checkbox/checkbox";
import Menu from "@material-ui/core/Menu";
import { bindActionCreators } from "redux";
import color from "../../utils/colors/colors";
import MenuItem from "@material-ui/core/MenuItem";
import DownArrowImage from "../../utils/assets/images/img14.png";
import XrayFilter from "../filter/xrayfilter";
import TransactionFilter from "../filter/transactionfilter";
import FilterPopup from "../../components/filter/filterpopup";
import hoverFilterIcons from "../../utils/assets/img23.png";
import SelectedFilterImage from "../../utils/assets/selectedfilter.png";
import hoverSelectedFilterImage2 from "../../utils/assets/img23-Copy.png";
import xlImage from "../../utils/assets/images/xls-open-file-format.png";
import xl2Image from "../../utils/assets/images/xls-open-file-format-1.png";
import pdfImage from "../../utils/assets/images/pdf-file-format-symbol-2.png";
import pdf2Image from "../../utils/assets/images/pdf-file-format-symbol-1.png";
import ClosefilterImage from "../../utils/assets/images/img15.jpg";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BiChevronDown } from "react-icons/bi";
import labels from "../../utils/constants/labels";
import colors from "../../utils/colors/colors";
import {
  xrayfilterChange,
  profitfilterChange,
  siptrackerfilterChange,
  TransactionfilterChange,
  datefilterChange,
} from "../../redux/actions/filter/reportsfilter";
import Dropdowns from "../dropdown/dropdownschemes";
import { thisExpression } from "@babel/types";
import Maturityfilter from "../filter/maturityfilter";
import { NegativeFinding, replaceNull } from "../../utils/commonfunction";
import NoData from "../nodata/nodata";
import Three_State_Toggle_Switch from "../switchbutton/three_state_toggle_switch";
const styles = (theme) => ({
  container: {
    //   maxWidth: "1140px",
    width: "97%",
    padding: "0px 20px",
    // marginTop: "-3px",
    [theme.breakpoints.down("600")]: {
      // padding: "0px"
    },
  },
  fillMain: {
    borderBottom: `1px solid ${window.globalConfig.color.BORDER}`,
    borderTop: `1px solid ${window.globalConfig.color.BORDER}`,
    width: "100%",
    marginLeft: "-6px",
    [theme.breakpoints.down("600")]: {
      width: "105%",
      marginLeft: "-28px",
    },
  },
  noHeader: {
    borderTop: `1px solid ${window.globalConfig.color.BORDER}`,
    width: "100%",
    marginLeft: "-6px",
  },
  FilResult: {
    float: "left",
    [theme.breakpoints.down("920")]: {
      display: "none"
    },
  },
  filSelected: {
    padding: "20px 0px",
    [theme.breakpoints.down("600")]: {
      // display: "none",
      padding: "5px 20px"
    },
  },
  filSelectedMobile: {
    display: "none !important",
    [theme.breakpoints.down("600")]: {
      display: "block !important",
      padding: "20px 0px",
      marginLeft: 20,
    },
  },

  filRight: {
    float: "right",
    display: "flex",
    [theme.breakpoints.down("600")]: {
      flex: 10,
      display: "flex",
      flexDirection: "row",
      placeContent: "flex-end",
      width: "100%",
    },
  },
  yesnoBox: {
    float: "left",
    // padding: "20px 0px",
    marginRight: "5px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("600")]: {
      padding: 0,
      width: "25%",
    },
    [theme.breakpoints.between("300", "350")]: {
      // padding: 0,
      width: "29%",
    },
  },
  sortbyBox: {
    float: "left",
    borderLeft: `1px solid ${window.globalConfig.color.BORDER}`,
    [theme.breakpoints.down("600")]: {
      //padding:0,
      width: "33%",
    },
  },
  dropdownbox: {
    float: "left",
    minWidth: 179,
    width: '100%',
    height: 69,
    borderLeft: `1px solid ${window.globalConfig.color.BORDER}`,
    paddingLeft: "10px",
    cursor: "pointer",
  },
  checkbox: {
    float: "left",
    width: 189,
    //height: 69,
    marginTop: "20px",
  },
  sortOff: {},
  filterBoxSHow: {
    borderLeft: `1px solid ${window.globalConfig.color.BORDER}`,
    position: "relative",
    float: "left",
    width: "62px",
    height: "68px",
    [theme.breakpoints.down("600")]: {
      width: "18%",
      height: 69,
    },
  },
  filterIcon: {
    background: `url(${FilterImage})no-repeat`,
    width: "29px",
    height: "29px",
    top: "15px",
    cursor: "pointer",
    position: "absolute",
    left: "20px",
  },
  clear: {
    clear: "both",
    height: 0,
    display: "block",
  },
  sortBy: {
    width: 120,
    paddingTop: 22,
    // cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    height: "45px",
    [theme.breakpoints.down("600")]: {
      width: "100%",
    },
  },
  dropdown: {
    width: 177,
    paddingTop: 13,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "45px",
    [theme.breakpoints.down("600")]: {
      width: "100%",
    },
  },
  filterhidedImagestyle: {
    [theme.breakpoints.up("600")]: {
      width: 35,
      height: 37,
      left: "3.5vh",
      marginLeft: "-11px",
      top: 13,
      background: `url(${FilterImage}) center center no-repeat`,
      position: "absolute",
      cursor: "not-allowed",
      opacity: 0.5,
      // '&:hover': {
      //     background: `url(${hoverFilterIcons}) center center no-repeat`,
      // },
    },
    [theme.breakpoints.down("600")]: {
      position: "unset",
      paddingTop: "27px",
      width: 35,
      height: 37,
      left: "18px",
      marginLeft: "68px",
      top: 21,
      marginLeft: 11,
      background: `url(${FilterImage}) center center no-repeat`,
      cursor: "not-allowed",
      opacity: 0.5,
    },
  },
  filterImagestyle: {
    [theme.breakpoints.up("600")]: {
      width: 35,
      height: 37,
      left: "3.5vh",
      marginLeft: "-11px",
      top: 13,
      background: `url(${FilterImage}) center center no-repeat`,
      position: "absolute",
      cursor: "pointer",
      "&:hover": {
        background: `url(${hoverFilterIcons}) center center no-repeat`,
      },
    },
    [theme.breakpoints.down("600")]: {
      position: "unset",
      paddingTop: "27px",
      width: 35,
      height: 37,
      left: "18px",
      marginLeft: "68px",
      top: 21,
      marginLeft: 11,
      background: `url(${FilterImage}) center center no-repeat`,
    },
  },
  selectedFilterImagestyle: {
    [theme.breakpoints.up("600")]: {
      width: 35,
      height: 37,
      left: "3.5vh",
      marginLeft: "-11px",
      top: 13,
      background: `url(${SelectedFilterImage}) center center no-repeat`,
      position: "absolute",
      cursor: "pointer",
      "&:hover": {
        background: `url(${hoverSelectedFilterImage2}) center center no-repeat`,
      },
    },
    [theme.breakpoints.down("600")]: {
      position: "unset",
      paddingTop: "27px",
      width: 35,
      height: 37,
      left: "18px",
      marginLeft: "68px",
      top: 21,
      marginLeft: 11,
      background: `url(${SelectedFilterImage}) center center no-repeat`,
    },
  },
  PdfImagestyle: {
    height: 35,
    width: 35,
    cursor: "pointer",
    [theme.breakpoints.down("600")]: {
      width: 28,
    },
  },
  cardSpareaterLine: {
    height: 0.5,
    width: "100%",
    backgroundColor: window.globalConfig.color.BORDER,
  },
  csvImagestyle: {
    height: 35,
    width: 35,
    cursor: "pointer",
  },
  filter: {
    margin: "12px",
    // cursor: "not-allowed",
    [theme.breakpoints.between("300", "600")]: {
      marginTop: "10px !important",
      margin: 0,
    },
  },
  filterContainer: {
    padding: 3,
  },
  myArrowClassName: {
    top: 31,
    background: `url(${DownArrowImage})  no-repeat !important`,
    [theme.breakpoints.between("300", "600")]: {
      top: 25,
      left: "90%",
    },
  },
  myControlClassName: {
    height: 75,
    paddingTop: 24,
    width: 290,
    border: "none !important",
    borderLeft: `1px solid ${window.globalConfig.color.BORDER} !important`,
    borderBottom: `1px solid ${window.globalConfig.color.BORDER} !important`,
    [theme.breakpoints.down("600")]: {
      width: 148,
      height: 65,
      paddingTop: 20,
      fontSize: 12,
      paddingLeft: 5,
      fontWeight: "bold",
      borderLeft: `1px solid ${window.globalConfig.color.BORDER} !important`,
    },
    // [theme.breakpoints.between('350', '400')]: {
    //     width: 160,
    //     height: '100%',
    //     paddingTop: 13,
    //     fontSize: 12,
    //     paddingLeft: 13,
    //     fontWeight: 'bold'
    // },
    // [theme.breakpoints.between('400', '600')]: {
    //     width: 170,
    //     height: '100%',
    //     paddingTop: 13,
    //     fontSize: 12,
    //     paddingLeft: 13,
    //     fontWeight: 'bold'
    // },
  },
  myMenuClassName: {
    [theme.breakpoints.between("300", "350")]: {
      textAlign: "left",
      fontSize: 14,
      paddingLeft: 0,
    },
  },
  sortbyList: {
    position: "absolute",
    transform: `translate3d(${0}, ${64}, ${0})`,
    top: 0,
    left: "0px",
    willChange: "transform",
    marginTop: -25,
    minWidth: 140,
    padding: 0,
    boxShadow: "7px 7px 20px 0 rgb(0 0 0 / 20%)",
    border: `1px solid ${window.globalConfig.color.BORDER}`,
    backgroundColor: window.globalConfig.color.WHITE,
  },
  listStyle: {
    fontSize: 14,
    paddingLeft: 20,
    height: 30,
    color: window.globalConfig.color.BLACK_PRIMARY,
    paddingTop: 6,
  },
  iconAlign: {
    marginLeft: 10,
  },
});
class XrayHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedvalue: [],
      selectedAmcList: [],
      selectedFinalList: [],
      maturityDate: "",
      startDate: "",
      sortByIndex: "",
      sortBy: null,
      sipsortby: true,
      sipamount: true,
      currentvalue: true,
      dropdownvalue: "",
      Report: null,
      Asset: [],
      Transaction: [],
      issuer: [],
      issuerList: [],
      statusList: [],
      status: [],
      scheme: [],
      selectedAsset: [],
      selectedTransaction: [],
      selectedScheme: [],
      selectedIssuerName: [],
      selectedStatus: [],
      AssetList: [],
      TransactionList: [],
      schemeList: [],
      datechange: "",
      dummyvalue: [],
      List: [],
      financialYear: [],
      startDate: "",
      endDate: "",
      tempstartDate: "",
      tempendDate: "",
      openafrdropdown: false,
      flag: "",
      reportList: [],
      disableFilter: true
    };
    this.accessClearAll = React.createRef(null)
    this.accessStartDate = React.createRef(null)
    this.accessToDate = React.createRef(null)

  }
  callBack = async () => {
    let schemes = [];
    if (this.props.page != "Transaction") {

    } else {
      let assetClass = [];
      let schemeslist = [];
      let transactionType = [];

      if (this.props.List.length != 0) {
        this.props.List.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex((elem) => elem.Scheme == ele.Scheme)
        ).map((value, index) => {
          schemeslist.push({
            value: index,
            label: value.Scheme,
            isChecked: false,
          });
        });
      }
      if (this.props.List.length != 0) {
        this.props.List.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex(
              (elem) => elem.AssetClass == ele.AssetClass
            )
        ).map((value, index) => {
          assetClass.push({
            value: index,
            label: value.AssetClass,
            isChecked: false,
          });
        });
      }
      if (this.props.List.length != 0) {
        this.props.List.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex(
              (elem) => elem.TransactionType == ele.TransactionType
            )
        ).map((value, index) => {
          transactionType.push({
            value: index,
            label: value.TransactionType,
            isChecked: false,
          });
        });
      }
      await this.setState({
        schemeList: schemeslist,
        AssetList: assetClass,
        TransactionList: transactionType,
      });
    }
  };
  handleOutsideClick = async () => {
    await this.setState(
      { selectedAmcList: [], selectedvalue: [], },
    );
  };
  componentDidUpdate(prevProps) {
    const { List } = this.props;
    if (prevProps.List != List) {
      if (List != "NA") {
        if (this.props.selected == "Scheme" || this.props.selected == "Scrip") {
          this.setState({ disableFilter: false })
        } else {
          if (!this.props.selected) {
            this.setState({ disableFilter: false })
          }
        }
      } else {

      }
    }
    // if(prevProps.Reportlist!=Reportlist){
    //   this.setState({reportList:Reportlist})
    // }

  }
  componentDidMount() {
    this.setState({
      startDate: this.props.mindate,
      endDate: `${this.props.maxdate}`,
    });
    if (this.props.Reportlist != undefined && this.props.Reportlist.length != 0) {
      this.setState({
        reportList: this.props.Reportlist.map((item) => {
          return { ...item, isChecked: false }
        })
      })
      this.setState({ dropdownvalue: this.props.Reportlist[0]?.label || labels.messages.NA })

    }
    let schemes = [];
    if (this.props.page == "maturitytracker") {
      let issuerlist = [];

      let status = [];
      if (this.props.List != undefined && this.props.List != "NA" && this.props.List.length != 0) {
        this.props.List.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex((elem) => elem.IssuerName == ele.IssuerName)
        ).map((value, index) => {
          issuerlist.push({
            value: index,
            label: value.IssuerName,
            isChecked: false,
          });
        });
      }
      if (this.props.List != undefined && this.props.List != "NA" && this.props.List.length != 0) {
        this.props.List.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex(
              (elem) => elem.Status == ele.Status
            )
        ).map((value, index) => {
          status.push({
            value: index,
            label: value.Status,
            isChecked: false,
          });
        });
      }
      this.setState({ issuerList: issuerlist, statusList: status })
    }
    else {
      let assetClass = [];
      let schemeslist = [];
      let transactionType = [];
      if (this.props.List != undefined && this.props.List != labels.messages.NA && this.props.List.length != 0) {
        this.props.List.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex((elem) => elem.Scheme == ele.Scheme)
        ).map((value, index) => {
          schemeslist.push({
            value: index,
            label: value.Scheme,
            isChecked: false,
          });
        });
      }
      if (this.props.List != undefined && this.props.List.length != 0) {
        this.props.List.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex(
              (elem) => elem.AssetClass == ele.AssetClass
            )
        ).map((value, index) => {
          assetClass.push({
            value: index,
            label: value.AssetClass,
            isChecked: false,
          });
        });
      }
      if (this.props.List != undefined && this.props.List.length != 0) {
        this.props.List.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex(
              (elem) => elem.TransactionType == ele.TransactionType
            )
        ).map((value, index) => {
          transactionType.push({
            value: index,
            label: value.TransactionType,
            isChecked: false,
          });
        });
      }
      this.setState({
        schemeList: schemeslist,
        AssetList: assetClass,
        TransactionList: transactionType,
        List: [
          ...this.state.schemeList,
          ...this.state.AssetList,
          ...this.state.TransactionList,
        ],
      });
    }
    // this.setState({disableFilter:(this.props.selected == "Scheme" || this.props.selected)})
    if (replaceNull(this.props.List, "NA") != "NA") {
      if (this.props.selected == "Scheme" || this.props.selected == "Scrip") {
        this.setState({ disableFilter: false })
      } else {
        if (!this.props.selected) {
          this.setState({ disableFilter: false })
        }
      }
    }
    else {
      this.setState({ disableFilter: true })
    }
  }

  handleClearFilter = () => {
    this.setState({ selectedvalue: [] });
    this.callBack();
    if (this.props.page == "maturitytracker") {
      this.setState({
        selectedFinalList: [],
        selectedvalue: [],
        selectedIssuerName: [],
        selectedStatus: [],
        startDate: "",
        maturityDate: ""


      });
      this.props.filter({
        Schemes: [],
        Transaction: [],
        Asset: [],
        startDate: "",
        maturityDate: ""
      });
    }
    else if (this.props.page != "Transaction") {
      this.setState({ selectedFinalList: [], selectedvalue: [], selectedAmcList: [] });
      this.props.submit([]);
    } else {
      this.setState({
        selectedFinalList: [],
        selectedvalue: [],
        selectedScheme: [],
        selectedAsset: [],
        selectedTransaction: [],
      });
      this.props.filter({
        Schemes: [],
        Transaction: [],
        Asset: [],
        startDate: this.props.mindate,
        endDate: this.props.maxdate,
        financialYear: this.state.dropdownvalue == "All" ? "" : `${this.state.financialYear[0].FromDate} - ${this.state.financialYear[0].ToDate}`
        // Date: `${this.props.mindate}-${this.props.maxdate}`,
      });
    }
  };
  handleClear = async () => {
    this.setState({
      startDate: this.props.mindate,
      maturityDate: this.props.maxdate,
    });
    let schemes = [];
    if (this.props.page == "maturitytracker") {
      let issuerlist = [];
      let status = [];

      if (this.props.List != undefined && this.props.List.length != 0) {
        this.props.List.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex((elem) => elem.IssuerName == ele.IssuerName)
        ).map((value, index) => {
          issuerlist.push({
            value: index,
            label: value.IssuerName,
            isChecked: false,
          });
        });
      }
      if (this.props.List != undefined && this.props.List.length != 0) {
        this.props.List.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex(
              (elem) => elem.Status == ele.Status
            )
        ).map((value, index) => {
          status.push({
            value: index,
            label: value.Status,
            isChecked: false,
          });
        });
      }
      this.setState({
        issuerList: issuerlist,
        statusList: status,
        selectedIssuerName: [],
        selectedStatus: [],
        issuer: [],
        selectedFinalList: null,
        status: []
      })
      this.props.filter({
        Schemes: [],
        issuerName: [],
        status: [],
        Transaction: [],
        Asset: [],
        startDate: "",
        maturityDate: ""
      });
    }
    else if (this.props.page != "Transaction") {
      // this.accessClearAll.current()
      await this.setState({ selectedvalue: [], selectedAmcList: [], selectedFinalList: null });
      this.props.submit([])
    } else {
      let assetClass = [];
      let schemeslist = [];
      let transactionType = [];
      if (this.props.List != undefined && this.props.List.length != 0) {
        this.props.List.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex((elem) => elem.Scheme != undefined ? elem.Scheme == ele.Scheme : elem.SchemeName == ele.SchemeName)
        ).map((value, index) => {
          schemeslist.push({
            value: index,
            label: value.Scheme || value.SchemeName,
            isChecked: false,
          });
        });
      }
      if (this.props.List != undefined && this.props.List.length != 0) {
        this.props.List.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex(
              (elem) => elem.AssetClass == ele.AssetClass
            )
        ).map((value, index) => {
          assetClass.push({
            value: index,
            label: value.AssetClass,
            isChecked: false,
          });
        });
      }
      if (this.props.List != undefined && this.props.List.length != 0) {
        this.props.List.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex(
              (elem) => elem.TransactionType == ele.TransactionType
            )
        ).map((value, index) => {
          transactionType.push({
            value: index,
            label: value.TransactionType,
            isChecked: false,
          });
        });
      }
      await this.setState({
        schemeList: schemeslist,
        AssetList: assetClass,
        TransactionList: transactionType,
        selectedScheme: [],
        scheme: [],
        Transaction: [],
        Asset: [],
        selectedTransaction: [],
        selectedAsset: [],
        selectedFinalList: null
      });
      this.props.filter({
        Schemes: [],
        Transaction: [],
        Asset: [],
        startDate: this.props.mindate,
        endDate: this.props.maxdate,
        financialYear: this.state.financialYear[0] == undefined ? "" : this.state.dropdownvalue == "All" ? "" : `${this.state.financialYear[0].FromDate} to ${this.state.financialYear[0].ToDate}`
      });
    }
    if (this.accessClearAll.current != undefined) {
      this.accessClearAll.current()
    }
  };
  handleChange = (value) => {
    this.setState({ selectedvalue: value });
  };
  handleschemeschange = (value) => {
    //
    this.setState({ scheme: value });
  };
  handleAssetclassChange = (value) => {
    this.setState({ Asset: value });
  };
  handleIssuerNameChange = (value) => {
    //
    this.setState({ issuer: value });
  };
  handleStatusChange = (value) => {
    this.setState({ status: value });
  };
  handletransactiontype = (value) => {
    this.setState({ Transaction: value });
  };

  handledatechange = (value) => {
    this.setState({ datechange: value });
  };

  submit = async () => {
    this.setState({ selectedFinalList: [] });
    this.setState({
      schemeList: this.state.schemeList.map((item) => {
        item.isChecked = false;
        return item;
      }),
      AssetList: this.state.AssetList.map((item) => {
        item.isChecked = false;
        return item;
      }),
      TransactionList: this.state.TransactionList.map((item) => {
        item.isChecked = false;
        return item;
      }),
      issuerList: this.state.issuerList.map((item) => {
        item.isChecked = false;
        return item;
      }),
      statusList: this.state.statusList.map((item) => {
        item.isChecked = false;
        return item;
      }),
    });
    if (this.props.page === "Transaction") {
      //
      this.setState({
        selectedScheme: this.state.scheme,
        selectedAsset: this.state.Asset,
        selectedTransaction: this.state.Transaction,

      });
      //
      this.setState({
        schemeList: this.state.schemeList.map(
          (x) =>
            this.state.scheme
              .map((item) => {
                item.isChecked = true;
                return item;
              })
              .find(({ value }) => value === x.value) || x
        ),
      });
      this.setState({
        AssetList: this.state.AssetList.map(
          (x) =>
            this.state.Asset.map((item) => {
              item.isChecked = true;
              return item;
            }).find(({ value }) => value === x.value) || x
        ),
      });
      this.setState({
        TransactionList: this.state.TransactionList.map(
          (x) =>
            this.state.Transaction.map((item) => {
              item.isChecked = true;
              return item;
            }).find(({ value }) => value === x.value) || x
        ),
      });
      //
      this.props.filter({
        Schemes: this.state.scheme,
        Transaction: this.state.Transaction,
        Asset: this.state.Asset,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        financialYear: this.state.financialYear[0] == undefined ? "" : this.state.dropdownvalue == "All" ? "" : `${this.state.financialYear[0].FromDate} to ${this.state.financialYear[0].ToDate}`
        , Date: this.state.startDate + "-" + this.state.endDate,
      });
      if (this.state.scheme.length != 0 || this.state.Transaction.length != 0 || this.state.Asset.length != 0 || this.state.startDate != this.props.mindate || this.state.endDate != this.props.maxdate) {
        await this.setState({
          selectedFinalList: {
            Scheme: this.state.scheme,
            Transaction: this.state.Transaction,
            Asset: this.state.Asset,
            startDate: this.state.startDate,
            endDate: this.state.endDate,
            financialYear: this.state.financialYear[0] == undefined ? "" : this.state.dropdownvalue == "All" ? "" : `${this.state.financialYear[0].FromDate} to ${this.state.financialYear[0].ToDate}`
            , Date: this.state.startDate + "-" + this.state.endDate,
          }
        })
      } else {
        await this.setState({ selectedFinalList: null })
      }

    } else if (this.props.page === "maturitytracker") {
      //
      this.setState({
        selectedIssuerName: this.state.issuer,
        selectedStatus: this.state.status,
        // selectedTransaction: this.state.Transaction,
      });
      //
      this.setState({
        issuerList: this.state.issuerList.map(
          (x) =>
            this.state.issuer
              .map((item) => {
                item.isChecked = true;
                return item;
              })
              .find(({ value }) => value === x.value) || x
        ),
      });
      this.setState({
        statusList: this.state.statusList.map(
          (x) =>
            this.state.status.map((item) => {
              item.isChecked = true;
              return item;
            }).find(({ value }) => value === x.value) || x
        ),
      });

      this.props.filter({
        issuerName: this.state.issuer,
        status: this.state.status,
        startDate: this.state.startDate,
        maturityDate: this.state.maturityDate,
      });
      if (this.state.issuer.length != 0 || this.state.status.length != 0 || this.state.startDate != "" || this.state.maturityDate != "") {
        await this.setState({
          selectedFinalList: {
            issuerName: this.state.issuer,
            status: this.state.status,
            startDate: this.state.startDate,
            maturityDate: this.state.maturityDate,
          }
        })
      }
    } else {
      if (this.state.selectedAmcList.length != 0 || this.state.selectedvalue.length != 0) {
        await this.setState({ selectedFinalList: { Scheme: this.state.selectedvalue, AMC: this.state.selectedAmcList } });
      } else {
        await this.setState({ selectedFinalList: null })
      }
      await this.props.submit(this.state.selectedFinalList == null ? [] : this.state.selectedFinalList);

    }
  };
  onChangeStartDate = (item) => {
    this.setState({ startDate: item })
  };
  onChangeEndDate = (item) => {
    this.setState({ endDate: item })
  }
  //portfolio and other page sort
  sortByIndexClick = (event, index, value) => {
    this.setState({ sortBy: null, sortByIndex: index });
    // setSortByIndex(index)
    if (this.props.page == "PortfolioX-Ray") {
      this.props.datavalue(value, index);
    } else {
      if (index == 0) {
        //
        this.props.handleSortby(event);
        this.setState({ sipsortby: !this.state.sipsortby });
      }
      else if (index == 1) {
        //
        this.props.handleCurrentAmount(this.state.currentvalue);
        this.setState({ currentvalue: !this.state.currentvalue });
      }
      else if (index == 2) {
        //
        this.props.handleSipAmount(this.state.sipamount);
        this.setState({ sipamount: !this.state.sipamount });
      }
    }
    // }
  };
  sortByClick = (event) => {
    // setSortBy(event.currentTarget);
    if (!this.props.selected || this.props.selected == "Scheme") {
      this.setState({ sortBy: event.currentTarget });
    }
  };
  onChangeDateRangePicker = (item) => {
    //
    this.setState({
      startDate: moment(item.startDate).format("DD MMM YYYY"),
      endDate: moment(item.endDate).format("DD MMM YYYY"),
    });
  };
  openAFRDropdown = () => {
    this.setState({ openafrdropdown: true });
  };
  //Afr report dropdown
  onChangeDropdown = (item) => {
    this.setState({ dropdownvalue: item[0].label, Report: null, financialYear: item });

    if (this.props.onChange != undefined) {
      this.props.onChange(item);
    } else {
      this.props.filter({
        Schemes: this.state.selectedScheme,
        Transaction: this.state.selectedTransaction,
        Asset: this.state.selectedAsset,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        financialYear: item[0].label == "All" ? "" : `${item[0].FromDate} to ${item[0].ToDate}`,
        Date: this.state.startDate + "-" + this.state.endDate,
      });
      // this.handleClear()
    }

    // this.closeAFRDropdown();
  };
  closeAFRDropdown = () => {
    this.setState({ openafrdropdown: false });
  };

  dropdownClick = (event) => {
    this.setState({ Report: event.currentTarget });
  };

  onToggle = (value) => {
    this.setState({
      startDate: this.props.mindate,
      endDate: `${this.props.maxdate}`,
    });
    //}
    let schemes = [];
    if (this.props.page == "maturitytracker") {
      let issuerlist = [];
      let status = [];

      if (replaceNull(this.props.List, labels.messages.NA) !== "NA") {
        this.props.List?.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex((elem) => elem.IssuerName == ele.IssuerName)
        ).map((value, index) => {
          issuerlist.push({
            value: index,
            label: value.IssuerName,
            isChecked: false,
          });
        });
      }
      if (replaceNull(this.props.List, labels.messages.NA) !== "NA") {
        this.props.List.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex(
              (elem) => elem.Status == ele.Status
            )
        ).map((value, index) => {
          status.push({
            value: index,
            label: value.Status,
            isChecked: false,
          });
        });
      }
      this.setState({ issuerList: issuerlist, statusList: status, startDate: "", issuer: [], status: [], maturityDate: "", selectedIssuerName: [], selectedStatus: [] })
    }
    else if (this.props.page != "Transaction") {
      if (this.props.List != undefined && this.props.List.length != 0) {
        this.props.List.map((value, index) => {
          schemes.push({
            value: index,
            label: value.Scheme,
            isChecked: false,
          });
        });
        // this.props.EquityXrayfilterChange([], schemes)
        this.setState({ List: [...schemes] });
      }
    } else {
      let assetClass = [];
      let schemeslist = [];
      let transactionType = [];
      if (this.props.List != undefined && this.props.List.length != 0) {
        this.props.List.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex((elem) => elem.Scheme == ele.Scheme)
        ).map((value, index) => {
          schemeslist.push({
            value: index,
            label: value.Scheme,
            isChecked: false,
          });
        });
      }
      if (this.props.List != undefined && this.props.List.length != 0) {
        this.props.List.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex(
              (elem) => elem.AssetClass == ele.AssetClass
            )
        ).map((value, index) => {
          assetClass.push({
            value: index,
            label: value.AssetClass,
            isChecked: false,
          });
        });
      }
      if (this.props.List != undefined && this.props.List.length != 0) {
        this.props.List.filter(
          (ele, ind) =>
            ind ===
            this.props.List.findIndex(
              (elem) => elem.TransactionType == ele.TransactionType
            )
        ).map((value, index) => {
          transactionType.push({
            value: index,
            label: value.TransactionType,
            isChecked: false,
          });
        });
      }
      this.setState({
        schemeList: schemeslist,
        AssetList: assetClass,
        TransactionList: transactionType,
        List: [
          ...this.state.schemeList,
          ...this.state.AssetList,
          ...this.state.TransactionList,
        ],
      });
    }
    if (this.props.enableToggleList) {
      if (value != "Scheme") {
        this.setState({ disableFilter: true })
      } else {
        this.setState({ disableFilter: false })
      }
      this.props.categorySwitch(value)
    } else {
      if (!this.props.selected) {
        this.setState({ disableFilter: true })
      } else {
        this.setState({ disableFilter: false })
      }
      this.props.Onswitchchange();

    }
  };
  startDateChange = (value) => {
    this.setState({ startDate: moment(value).format("DD MMM YYYY") })
  }
  maturityDateChange = (value) => {
    this.setState({ maturityDate: moment(value).format("DD MMM YYYY") })

  }
  handleAmcChange = (value) => {
    this.setState({ selectedAmcList: value })
  }
  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.container}>
          {this.props.page != "noHeader" && (
            <div className={classes.fillMain}>
              <div className={classes.FilResult}>
                <div className={classes.filSelected}> {replaceNull(this.props.data, "NA") == "NA" ? <NoData></NoData> : `${NegativeFinding(this.props.data.length, "", "", "", "number")} ${this.props.multipleListHeader == undefined ? this.props.header + (this.props.data.length > 1 ? "s" : "") : this.props.data.length > 1 ? this.props.multipleListHeader : this.props.header} `} </div>
              </div>
              <div className={classes.filRight}>
                {this.props.showtoggle && (
                  <div className={classes.yesnoBox}>
                    {this.props.enableToggleList ? <Three_State_Toggle_Switch
                      values={this.props.switchList}
                      handleChangeToggle={this.onToggle}
                      selectedFlag={this.props.selected}
                    ></Three_State_Toggle_Switch> :
                      <ToggleButton

                        label1={this.props.header}
                        label2={this.props.toggleName || "Category"}
                        selected={this.props.selected}
                        toggleSelected={this.onToggle}
                      />
                    }
                  </div>
                )}
                {
                  Boolean(this.state.reportList) &&
                  this.state.reportList.length > 0 && (
                    <div
                      style={{ float: "left", cursor: "pointer" }}

                    >
                      <Dropdowns
                        type={"normalList"}
                        List={this.state.reportList}
                        handleOnChange={this.onChangeDropdown}
                      >
                        <div className={classes.dropdownbox}  >
                          <div
                            className={classes.dropdown}
                            style={{ color: window.globalConfig.color.black }}
                          >
                            <span>{this.props.value || this.state.dropdownvalue}</span>
                            {/* <span > */}
                            <BiChevronDown
                              style={{ width: 25, height: 25, minWidth: 25, minHeight: 25, color: "grey" }}
                            />
                          </div>
                        </div>
                      </Dropdowns>
                    </div>
                  )
                }
                {this.props.showcheckbox && (
                  <div className={classes.checkbox}>
                    <CommonCheckbox
                      label={
                        this.props.flag === "equity"
                          ? labels.checkBox.WithRedeemedQTY
                          : labels.checkBox.WithRedeemedUnits
                      }
                      checked={this.props.checked}
                      onChange={this.props.onclickCheck}
                    />
                  </div>
                )}
                {this.props.showsortby && (
                  <div className={classes.sortbyBox}>
                    {
                      replaceNull(this.props.List, "NA") != "NA" ? (
                        <div
                          className={classes.sortBy}
                          onClick={this.sortByClick}
                          style={{
                            color: !this.props.selected || this.props.selected == "Scheme"
                              ? window.globalConfig.color.BLACK_PRIMARY
                              : window.globalConfig.color.BORDER,
                            cursor: !this.props.selected || this.props.selected == "Scheme"
                              ? "pointer"
                              : "not-allowed",
                          }}
                        >
                          <span>{labels.xRayHeader.sortBy}</span>
                          <span className={classes.iconAlign}>
                            <BiChevronDown style={{ width: 25, height: 25 }} />
                          </span>
                        </div>
                      ) : (
                        <div
                          className={classes.sortBy}
                          style={{ color: window.globalConfig.color.BORDER }}
                        >
                          <span>{labels.xRayHeader.sortBy}</span>
                          <span className={classes.iconAlign}>
                            <BiChevronDown style={{ width: 25, height: 25 }} />
                          </span>
                        </div>
                      )}
                    <div>
                      <Menu
                        id="simple-menu"
                        anchorEl={this.state.sortBy}
                        keepMounted
                        open={Boolean(this.state.sortBy)}
                        onClose={() => this.setState({ sortBy: false })}
                        onScroll={() => this.setState({ sortBy: false })}
                      >
                        {this.props.page === "PortfolioX-Ray" ? (
                          !this.props.aToZ ? (
                            <MenuItem
                              className={
                                this.state.sortByIndex == 0
                                  ? "sortbylistclick"
                                  : "sortbylist"
                              }
                              onClick={(event) =>
                                this.sortByIndexClick(event, 0, labels.sortBy.Z_A)
                              }
                            >
                              {labels.sortBy.Z_A}
                            </MenuItem>
                          ) : (
                            <MenuItem
                              className={
                                this.state.sortByIndex == 0
                                  ? "sortbylistclick"
                                  : "sortbylist"
                              }
                              onClick={(event) =>
                                this.sortByIndexClick(event, 0, labels.sortBy.A_Z)
                              }
                            >
                              {labels.sortBy.A_Z}
                            </MenuItem>
                          )
                        ) : this.state.sipsortby ? (
                          <MenuItem
                            className={
                              this.state.sortByIndex == 0
                                ? "sortbylistclick"
                                : "sortbylist"
                            }
                            onClick={() => this.sortByIndexClick(true, 0, labels.sortBy.A_Z)}
                          >
                            {labels.sortBy.A_Z}
                          </MenuItem>
                        ) : (
                          <MenuItem
                            className={
                              this.state.sortByIndex == 0
                                ? "sortbylistclick"
                                : "sortbylist"
                            }
                            onClick={() => this.sortByIndexClick(false, 0, labels.sortBy.Z_A)}
                          >
                            {labels.sortBy.Z_A}
                          </MenuItem>
                        )}
                        <div className={classes.cardSpareaterLine}></div>
                        <MenuItem
                          className={
                            this.state.sortByIndex == 2
                              ? "sortbylistclick"
                              : "sortbylist"
                          }
                          onClick={(event) => this.sortByIndexClick(event, 2, this.props.page === "PortfolioX-Ray"
                            ? labels.sortBy.returns
                            : labels.sortBy.sipamount)}
                        >
                          {this.props.page === "PortfolioX-Ray"
                            ? labels.sortBy.returns
                            : labels.sortBy.sipamount}
                        </MenuItem>
                        <div className={classes.cardSpareaterLine}></div>
                        {this.props.page === "PortfolioX-Ray" &&
                          (<MenuItem
                            className={
                              this.state.sortByIndex == 1
                                ? "sortbylistclick"
                                : "sortbylist"
                            }
                            onClick={(event) => this.sortByIndexClick(event, 1, labels.sortBy.marketValue)}
                          >

                            {labels.sortBy.marketValue}

                          </MenuItem>)
                        }

                      </Menu>
                    </div>
                  </div>
                )}
                {this.props.showdownload ? (
                  (replaceNull(this.props.List, "NA") != "NA" && !this.props.disableExports && (this.props.showpdf == undefined || this.props.showpdf || !this.props.disableExports)) && (this.props.flag == "mutualfunds" || this.props.flag == "Family Report" || this.props.flag == "equity" || this.props.flag == "Bonds" || this.props.flag == "Fixed Deposits") ? (
                    <div
                      className={classes.filterBoxSHow}
                      style={{ cursor: "pointer" }}
                    >

                      <div className={classes.filterContainer}>
                        <div
                          onClick={() => this.props.onclick("pdf")}
                          className={classes.filter}
                        >
                          {this.props.pdfload == true && (
                            <CircularProgress
                              size={30}
                              thickness={1}
                              style={{
                                color: window.globalConfig.color.GRAY,
                                position: "absolute",
                              }}
                            />
                          )}
                          {!this.props.pdfload ? (
                            <img
                              src={pdfImage}
                              className={classes.PdfImagestyle}
                            />
                          ) : (
                            <img
                              src={pdf2Image}
                              className={classes.PdfImagestyle}
                            />
                          )}
                        </div>

                      </div>
                    </div>
                  ) : (

                    this.props.flag == undefined ?
                      <></>
                      :
                      <div
                        className={classes.filterBoxSHow}
                        style={{ cursor: "not-allowed" }}
                      >
                        <div className={classes.filterContainer}>
                          <div className={classes.filter}>
                            <img
                              src={pdf2Image}
                              style={{ cursor: "not-allowed" }}
                              className={classes.PdfImagestyle}

                            />
                          </div>
                        </div>
                      </div>
                  )
                ) : (
                  ""
                )}
                {this.props.showdownload ? (
                  (replaceNull(this.props.List, "NA") != "NA") && this.props.disableExports != true && (this.props.flag == "mutualfunds" || this.props.flag == "Family Report" || this.props.flag == "equity" || this.props.flag == "Bonds" || this.props.flag == "Fixed Deposits") ? (
                    <div className={classes.filterBoxSHow}>
                      <div className={classes.filterContainer}>
                        <div
                          onClick={() => this.props.onclick("xlsx")}
                          className={classes.filter}
                        >
                          {this.props.newxcelLoad == true && (
                            <CircularProgress
                              size={30}
                              thickness={1}
                              style={{
                                color: window.globalConfig.color.GRAY,
                                position: "absolute",
                              }}
                            />
                          )}
                          {!this.props.newxcelLoad ? (
                            <img
                              src={xlImage}
                              className={classes.PdfImagestyle}
                            />
                          ) : (
                            <img
                              src={xl2Image}
                              className={classes.PdfImagestyle}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    this.props.flag == undefined ?
                      <></>
                      :
                      <div
                        className={classes.filterBoxSHow}
                        style={{ cursor: "not-allowed" }}
                      >
                        <div className={classes.filterContainer}>
                          <div className={classes.filter}>
                            <img
                              src={xl2Image}
                              style={{ cursor: "not-allowed" }}
                              className={classes.csvImagestyle}
                            />
                          </div>
                        </div>
                      </div>
                  )
                ) : (
                  ""
                )}
                {this.props.showfilter === true && (

                  (this.state.disableFilter) ? (

                    <div className={classes.filterBoxSHow}>
                      <div className={classes.filterhidedImagestyle}></div>
                    </div>

                  ) : (
                    <div className={classes.filterBoxSHow}>
                      <FilterPopup
                        onClick={this.submit}
                        showclose={true}
                        filterImagestyle={classes.filterImagestyle}
                        // callBack={this.callBack}
                        selectedvalue={replaceNull(this.state.selectedFinalList, "")}
                        selectedFilterImagestyle={
                          classes.selectedFilterImagestyle
                        }
                        showselected={false}
                        onOutsideClick={this.handleOutsideClick}
                        //handleClick={this.handleappyclick}
                        handleClear={this.handleClear}
                        // handleButtonClear={this.handleClear}
                        page={this.props.page}
                      >
                        {this.props.page == "Transaction" ? (
                          <TransactionFilter
                            filterHeader={this.props.filterHeader}
                            // callBack={this.callBack}
                            flag={this.props.flag}
                            mindate={this.props.mindate}
                            maxdate={this.props.maxdate}
                            Asset={this.state.AssetList}
                            selectedAsset={this.state.selectedAsset}
                            selectedScheme={this.state.selectedScheme}
                            selectedTransaction={this.state.selectedTransaction}
                            scheme={this.state.schemeList}
                            startDate={this.state.startDate}
                            endDate={this.state.endDate}
                            Transaction={this.state.TransactionList}
                            onChange={this.onChangeDateRangePicker}
                            handleAssetclassChange={
                              this.handleAssetclassChange
                            }
                            handleschemeschange={this.handleschemeschange}
                            handletransactiontype={
                              this.handletransactiontype
                            }
                            Filters={this.props.multifilterselect}
                            selectvalue={this.state.selectedvalue}
                            header={this.props.header}
                          />
                        ) : this.props.page == "maturitytracker" ?
                          <Maturityfilter
                            startDate={this.state.startDate}
                            maturityDate={this.state.maturityDate}
                            handleIssuerNameChange={this.handleIssuerNameChange}
                            handleStatusChange={this.handleStatusChange}
                            issuerList={this.state.issuerList}
                            statusList={this.state.statusList}
                            maturityDateChange={this.maturityDateChange}
                            startDateChange={this.startDateChange}>
                          </Maturityfilter>
                          :
                          <XrayFilter
                            page={this.props.page}
                            List={this.props.List}
                            handleOutsideClick={this.handleOutsideClick}
                            // callBack={this.callBack}
                            accessClearAll={this.accessClearAll}
                            amcOnChange={this.handleAmcChange}
                            selectedAmcList={replaceNull(this.state.selectedFinalList?.AMC, "")}
                            selectvalue={replaceNull(this.state.selectedFinalList?.Scheme, "")}
                            onSchemeChange={this.handleChange}
                            flag={this.props.flag}
                            header={this.props.header}
                          />
                        }
                      </FilterPopup>
                    </div>
                  )

                )}

                <div className={classes.clear}></div>
              </div>
              <div className={classes.clear}></div>
            </div>
          )}
          {this.props.page == "noHeader" && (
            <div
              className={classes.noHeader}
            ></div>
          )}
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    sessionID: state.Login.sessionID,
    clientID: state.Login.logindetails.ClientID,
    xray: state.PortfolioXray.xraysort,
    Dropdownequityxrayreducer: state.PortfolioXray.equity_xrayfilter,
    Dropdownxrayreducer: state.PortfolioXray.xrayfilter,
    ProfitLossFilterReducer: state.ProfitLoss.profitLossfilter,
    Equity_ProfitLossFilterReducer: state.ProfitLoss.equity_profitLossfilter,
    SIPtracker: state.SipTracker.backupSiptracker,
    dropdown: state.Dropdown.dropdowndetails,
    schemedropdown: state.transactionList.dropdowntransaction,
    equityschemedropdown: state.transactionList.equity_dropdowntransaction,
    // Reportlist:
    //   state.Dropdown.dropdowndetails != null ||
    //     state.Dropdown.dropdowndetails != undefined
    //     ? state.Dropdown.dropdowndetails.subReports == null
    //       ? [{ label: "No Data Found", value: 1 }]
    //       : [...state.Dropdown.dropdowndetails.subReports
    //         .filter((val) => val.MainReportID == 2 && val.ID != 2)
    //         .map((item, index) => {
    //           return {
    //             label: item.ReportName,
    //             value: item.ID,
    //             isChecked: item.ID == 1 ? true : false,
    //           };
    //         }), { label: 'ELSS Transaction', value: 0, isChecked: false }]
    //     : [],
    SIPtrackerList: state.Filter.SIPtrackerList,
    ProfitList: state.Filter.ProfitList,
    Portfolioxraylist: state.Filter.Portfolioxraylist,
    transactionlist: state.Filter.transactionlist,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      xrayfilterChange,
      siptrackerfilterChange,
      TransactionfilterChange,
      datefilterChange,
      profitfilterChange,
    },
    dispatch
  );
};
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(XrayHeader)
);
