import * as types from '../../constants/actionsType';
import getAllSIPTrackerDetails from '../../../api/siptracker/siptracker';
import constants from '../../../utils/constants/apiconstant';
export function getSIPTrackerDetails(ClientID, L4, L5, SchemeID, FolioNo,AsOnDate,ProductID,FamilyID,L5ID,L4ID,flag) {
  return (dispatch) => {
    getAllSIPTrackerAction(dispatch, ClientID, L4, L5, SchemeID, FolioNo,AsOnDate,ProductID,FamilyID,L5ID,L4ID,flag);
  };
}
function getAllSIPTrackerAction(dispatch,ClientID, L4, L5, SchemeID, FolioNo,AsOnDate,ProductID,FamilyID,L5ID,L4ID,flag) {

  dispatch({
    type: types.EMPTY_SIP_TRACKER,
    payload: [],
  
  });
  dispatch({
    type: types.LOADER_SIP_TRACKER,
    payload: true,
  
  })
    getAllSIPTrackerDetails(ClientID, L4, L5, SchemeID, FolioNo,AsOnDate,ProductID,FamilyID,L5ID,L4ID)
    .then((data) => {
      dispatch({
        type: types.LOADER_SIP_TRACKER,
        payload: false,
      
      })
      if(data.status=="S"){
        dispatch({
          type: types.PREVENT_API_CALL,
        
          payload:[{ProductID:ProductID,api:"SipTracker"}],
        }); 
        //  if(ProductID==1){
        //   // 
          dispatch({
              type: types.SIP_TRACKER,
              payload:data.data!=null?data.data.length!=0? data.data.sort((a, b) => {
                var textA = a.SchemeName.toUpperCase();
                var textB = b.SchemeName.toUpperCase();
                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
              }):[]:[]
            })
         
          // }
            
      }else{
        dispatch({
          type: types.EMPTY_SIP_TRACKER,
          payload: [],
        
        });
      }
     
    })
    .catch((error) => {
      dispatch({
        type: types.PREVENT_API_CALL,
      
        payload:[{ProductID:ProductID,api:"SipTracker"}],
      }); 
      
      dispatch({
        type: types.EMPTY_SIP_TRACKER,
        payload: [],
      
      });
    
    
    })
}  
export const SIPtrackerSortBy = (flag) => {
  return {
    type: types.SIPTRACKER_SORT_ALPHA,
    payload: flag
  }
}
export const SIPTrackerClear = (flag) => {
  return {
    type: types.SIPTRACKER_CLEAR,
    payload: flag
  }
}
export const SIPTrackerFilter = (flag) => {
  return {
    type: types.SIPTRACKER_FILTER,
    payload: flag
  }
}
export const SIPtrackerCurrentvalue = (flag) => {
  return {
    type: types.SIPTRACKER_SORT_BY_CURRENT_VALUE,
    payload: flag
  }
}
export const SIPtrackerSipAmount = (flag) => {
  return {
    type: types.SIPTRACKER_SORT_BY_SIPAMOUNT,
    payload: flag
  }
}
