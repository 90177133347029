import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
function getFamilyXrayReport(ClientID, InceptionDate, SessionID, ProductID,) {
    //let BaseUrl = "https://ms.zoifintech.com/Dashboard/GetMutualFundDetails"
    let data = {};

    // data.Account = 0
    data.ClientID = ClientID
    data.InceptionDate = InceptionDate
    // data.FromXIRR = 0
    // data.ToXIRR = 100
    // data.Type = 0
    // data.AssetClass = 0
    // data.AccountLevel = 0
    data.SessionID = SessionID
    // data.SchemeID = 0
    data.ProductID = ProductID
    // data.FamilyID = 0
    // data.L5ID = 0
    // data.L4ID = 0
    //  (data)
    // var config = {
    //   method: 'post',
    //   url:apivalues.PortfolioXray,
    //   headers: { 
    //     'Content-Type': 'application/json'
    //   },
    //   data : data
    // };
    // return axios(config)
    // .then(function (response) {
    //  return response.data;
    // })
    // .catch(function (error) {
    //    if(error.message="Network Error"){
    //      var data={}
    //      data.status="N"
    //      data.message=error.message
    //      return  data
    //    }else

    //    {
    //     return { "status": "F", "message": labels.messages.apierror }

    //    }
    // });
    return Networking.PostApi(ApiValues.FamilyPortfolioXray, data).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });

}
export default getFamilyXrayReport;