import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import combineStyle from "../../utils/stylesheets/combainstyle/combainstyle";
import commonstyletable from "../../utils/stylesheets/table/commontablestyle";
import GetInsuranceReport from '../../api/insurance/insurance_report';
import { NegativeFinding, replaceNull } from '../../utils/commonfunction';
import '../../utils/stylesheets/tabs/tabs.css'
import secureStorage from '../../utils/securestorage';
import Toolbar from '../../components/toolbar/toolbar';

import labels from '../../utils/constants/labels';
// import {
//   bondsFilterChange,
// } from "../../redux/actions/portfolioxray/portfolioxray";
import { InsuranceFilterChange } from '../../redux/actions/portfolioxray/portfolioxray';
import { bindActionCreators } from "redux";
import EPFReport from '../epf/epfreport';
import getEPFReport from '../../api/epf/epfreport';
import { EpfList } from '../../redux/actions/epf/epfaction';
import getStructuredProducts from '../../api/structuredproducts/structuredproducts';
import moment from 'moment';
import colors from '../../utils/colors/colors';
import Loader from '../../components/loader/loader';
import NoData from '../../components/nodata/nodata';
import Report_common_header from '../../components/report_common_header/report_common_header';
import getFamilyLiquiloans from '../../api/structuredproducts/familyliquiloans';
import { Redirect } from 'react-router-dom/cjs/react-router-dom';
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                // <Typography>{children}</Typography>      
                <div
                    style={{
                        fontSize: '1rem',
                        fontWeight: 400,
                        lineHeight: 1.5,
                        letterSpacing: '0.00938rem',
                    }}
                >{children}</div>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}

const Styles = (theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        // backgroundColor: theme.palette.background.paper,
    },
    tableRoot: {
        marginTop: "0px !important",
        marginBottom: "0% !important"
    },
    activeTitleStatus: {
        background: "rgb(231, 246, 237)",
        color: window.globalConfig.color.GREEN,
        borderRadius: '5px',
        padding: '5px',
        // fontWeight: '600',
        width: '12%',
        // marginBottom: '10px',
        [theme.breakpoints.down("xs")]: {
            width: '30%',
        },
    },
    inactiveTitleStatus: {
        background: "rgb(252, 233, 233)",
        color: window.globalConfig.color.red,
        borderRadius: '5px',
        padding: '5px',
        // fontWeight: '600',
        width: '12%',
        // marginBottom: '10px',
        [theme.breakpoints.down("xs")]: {
            width: '30%',
        },
    },
    expirestatusTitle: {
        background: window.globalConfig.color.RosyBrown,
        color: window.globalConfig.color.BROWN,
        borderRadius: '5px',
        padding: '5px',
        // fontWeight: '600',
        width: '12%',
        // marginBottom: '10px',
        [theme.breakpoints.down("xs")]: {
            width: '30%',
        },
    },
    tabRoot: {
        "& .MuiTab-root:focus": {
            color: window.globalConfig.color.BROWN
        },
        "& .MuiTab-textColorPrimary.Mui-selected": {
            color: window.globalConfig.color.BROWN
        }
    },
    transaction: {
        display: "grid",
        gridTemplateColumns: "repeat(4,1fr)",
        gap: 10,
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(3,1fr)",
            gridTemplateAreas: `"${"dataOne"} . ."
            "${"dataTwo"} ${"dataThree"} ${"dataFour"}"
           `,
            padding: 10,
            gap: 10,
            "& .dataTwo": {
                alignItems: "start"
            },
            "& .dataThree": {
                // textAlign: "center !important"
                alignItems: "center"
            },
            "& .dataFour": {
                // textAlign: "center !important",
                alignItems: "end"
            }
        }
    },
    badge: {
        padding: "1px 9px",
        fontSize: 18,
        borderRadius: 9,
        backgroundColor: colors.PRIMARY_BORDER
    },
    portfolioDetails: {
        display: "grid",
        gridTemplateColumns: "12% 32% 13% 13% 13% 12%",
        gap: 10,
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(3,1fr)",
            gridTemplateAreas: `"dataOne . dataTwo"
            "dataThree dataFour dataFive"
            "dataSix dataSeven dataEight"
           `,
            padding: 10,
            gap: 10,
            "& .dataTwo": {
                alignItems: "end"
            },
            "& .dataOne": {
                // textAlign: "center !important"
                alignItems: "start"
            },
            "& .dataThree": {
                // textAlign: "center !important",
                alignItems: "start"
            },
            "& .dataFour": {
                // textAlign: "center !important",
                alignItems: "center"
            }
            ,
            "& .dataFive": {
                // textAlign: "center !important",
                alignItems: "end"
            }
            ,
            "& .dataSix": {
                // textAlign: "center !important",
                alignItems: "start"
            },
            "& .dataSeven": {
                // textAlign: "center !important",
                alignItems: "center"
            },
            "& .dataEight": {
                // textAlign: "center !important",
                alignItems: "end"
            }
        }
    }
});
let title = [{ "header": "Investment Date", "subHeader": "", align: "left" }, { header: "Scheme Details", align: "left", subHeader: "" }, { header: "Amount", align: "right", subHeader: "Current Rate" }, { header: "Int Redeem", align: "right", subHeader: "" }, { header: "Current Value", align: "right", subHeader: "" }, { header: "Maturity Date / Closure Date", align: "right", subHeader: "" }]
class LiquiLoans_tab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            portfolioDetails: [],
            bkp_PortfolioDetails: [],
            transaction: [],
            loader: false,
        }
    }
    componentDidMount() {
        const { page, xray } = this.props;
        // 
        // console.log(page)
        //if (prev.page != page) {
        if (page.state != undefined) {
            // if (prev.page.state.tabValue != page.state.tabValue) {
            this.setState({ value: page.state.tabValue })
            // }
        }
        if (this.props.report == "FamilyReport") {
            this.liquiloansFamilyReportAPI()
        } else {
            this.liquiloansReportAPI()

        }
        //}
        // if (this.props.clientID != "") {
        // }
    }
    componentDidUpdate(prev) {
        const { page, clientID, date, L5ID } = this.props;
        // 
        if (prev.page != page) {
            if (prev.page.state != undefined) {
                if (prev.page.state.tabValue != page.state.tabValue) {
                    this.setState({ value: page.state.tabValue })
                }
            }
        }
        if (prev.clientID != clientID || prev.date != date || prev.L5ID != L5ID) {
            // 
            // this.getLiquiloansDate(clientID, date, L5ID)
            if (this.props.report == "FamilyReport") {
                this.liquiloansFamilyReportAPI()
            } else {
                this.liquiloansReportAPI()

            }
        }
    }
    customStyle = (item) => {
        let style = ''
        if (item.toLowerCase() == 'approved') {
            style = "activeTitleStatus"
        } else if (item.toLowerCase() == "closed") {
            style = 'inactiveTitleStatus'
        } else {
            style = 'expirestatusTitle'
        }
        return style
    }
    commonFunction = (val) => {
        if (val != undefined && val != null && val != "") return true;
        return false;
    }
    handleChange = (event, newValue) => {
        // 
        this.setState({ value: newValue })
    };
    liquiloansReportAPI = () => {
        this.setState({ loader: true })
        let data = {}
        data.ClientID = this.props.clientID
        data.SessionID = this.props.sessionID
        data.L4ID = this.props.L4ID
        data.L5ID = this.props.L5ID
        data.FamilyID = 0
        data.ProductID = 0
        getStructuredProducts(data).then((response) => {
            if (response.status == "S") {
                let res = replaceNull(response, "NA")
                if (res.table1 != null && res.table1 != "NA") {
                    this.setState({
                        bkp_PortfolioDetails: res.table1,
                        portfolioDetails: res.table1.filter(
                            (ele, ind) =>
                                ind ===
                                res.table1.findIndex((elem) => elem["InvestmentStatus"] === ele["InvestmentStatus"])
                        ).map((item) => {
                            return {
                                InvestmentStatus: item.InvestmentStatus,
                                data: res.table1.filter((val) => val.InvestmentStatus == item.InvestmentStatus).map((value) => {
                                    return {
                                        ...value,
                                        dataOne: value.TransactionDate, dataTwo: value.SchemeDetails,
                                        dataThree: NegativeFinding(value.Amount, "", "", "", "rupee"),
                                        subDataThree: NegativeFinding(value.CurrentRate, "", "", "", "percentage"),
                                        dataFour: NegativeFinding(value.RedeemedInterest, "", "", "", "rupee"),
                                        dataFive: NegativeFinding(value.CurrentValue, "", "", "", "rupee"),
                                        dataSix: value.MaturityEndDate
                                    }
                                })
                            }
                        })
                    })
                } else {
                    this.setState({ portfolioDetails: [], bkp_PortfolioDetails: [] })
                }
                if (res.table2 != null && res.table2 != "NA") {
                    this.setState({
                        transaction: replaceNull(res.table2.map((item) => {
                            return {
                                ...item, dataOne: item.Date, dataTwo: item.TransactionType, dataThree: item.TransactionNature, dataFour: NegativeFinding(item.Amount, "", "", "", "rupee")
                                // , dataFive: item.Payout, dataSix: NegativeFinding(item.TransAmount, "", "", "", "rupee"), dataSeven: NegativeFinding(item.NPI, "", "", "", "rupee"), dataEight: NegativeFinding(item.Interest, "", "", "", "number")
                            }
                        }), "NA"),
                    })
                } else {
                    this.setState({ transaction: [] })
                }
            } else {
                this.setState({ transaction: [], portfolioDetails: [], bkp_PortfolioDetails: [] })
            }
            this.setState({ loader: false })
        }).catch(() => {
            this.setState({ loader: false })
        })
    }
    liquiloansFamilyReportAPI = () => {
        this.setState({ loader: true })
        let data = {}
        data.ClientID = this.props.clientID
        data.SessionID = this.props.sessionID
        data.L4ID = this.props.L4ID
        data.L5ID = this.props.L5ID
        data.FamilyID = 0
        data.ProductID = 0
        getFamilyLiquiloans(data).then((response) => {
            if (response.status == "S") {
                let res = replaceNull(response, "NA")
                if (res.table1 != null && res.table1 != "NA") {
                    this.setState({
                        bkp_PortfolioDetails: res.table1,
                        portfolioDetails: res.table1.filter(
                            (ele, ind) =>
                                ind ===
                                res.table1.findIndex((elem) => elem["InvestmentStatus"] === ele["InvestmentStatus"])
                        ).map((item) => {
                            return {
                                InvestmentStatus: item.InvestmentStatus,
                                data: res.table1.filter((val) => val.InvestmentStatus == item.InvestmentStatus).map((value) => {
                                    return {
                                        ...value,
                                        dataOne: value.TransactionDate, dataTwo: value.SchemeDetails,
                                        dataThree: NegativeFinding(value.Amount, "", "", "", "rupee"),
                                        subDataThree: NegativeFinding(value.CurrentRate, "", "", "", "percentage"),
                                        dataFour: NegativeFinding(value.RedeemedInterest, "", "", "", "rupee"),
                                        dataFive: NegativeFinding(value.CurrentValue, "", "", "", "rupee")
                                        , dataSix: value.MaturityEndDate
                                    }
                                })
                            }
                        })
                    })
                } else {
                    this.setState({ portfolioDetails: [], bkp_PortfolioDetails: [] })
                }
                if (res.table2 != null && res.table2 != "NA") {
                    this.setState({
                        transaction: replaceNull(res.table2.map((item) => {
                            return {
                                ...item, dataOne: item.Date, dataTwo: item.TransactionType, dataThree: item.TransactionNature, dataFour: NegativeFinding(item.Amount, "", "", "", "rupee")
                                // , dataFive: item.Payout, dataSix: NegativeFinding(item.TransAmount, "", "", "", "rupee"), dataSeven: NegativeFinding(item.NPI, "", "", "", "rupee"), dataEight: NegativeFinding(item.Interest, "", "", "", "number")
                            }
                        }), "NA"),
                    })
                } else {
                    this.setState({ transaction: [] })
                }
            } else {
                this.setState({ transaction: [], portfolioDetails: [], bkp_PortfolioDetails: [] })
            }
            this.setState({ loader: false })
        }).catch(() => {
            this.setState({ loader: false })
        })
    }
    render() {
        // //   

        const { classes } = this.props;
        try {
            return (
                <div className={classes.root}>
                    <AppBar position="static" color="default">
                        <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            classes={{ root: classes.tabRoot }}
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                            TabIndicatorProps={{ style: { background: window.globalConfig.color.BROWN } }}>
                            <Tab label="Portfolio Details" {...a11yProps(0)} />
                            <Tab label="Transaction" {...a11yProps(1)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={this.state.value} index={0}>
                        <div style={{ paddingBottom: "2%" }}>
                            {this.state.loader ? <Loader page="skeletonreport"></Loader>
                                : <div>
                                    <Report_common_header
                                        flag={"Liquiloans"}
                                        showfilter={false}
                                        showdownload={false}
                                        header={"Transaction"}
                                        // submit={this.props.submit}
                                        scheme={this.state.bkp_PortfolioDetails.length == 0 ? <NoData></NoData> : this.state.bkp_PortfolioDetails.length <= 1 ? `${this.state.bkp_PortfolioDetails.length} ${"Transaction"}` : `${this.state.bkp_PortfolioDetails.length} ${"Transactions"}`}
                                        page={this.props.flag}
                                        data={this.state.bkp_PortfolioDetails}
                                        List={[]}
                                        selected={false}
                                    />
                                    {this.state.portfolioDetails != "NA" ? this.state.portfolioDetails.map((item) => {
                                        return (
                                            <div style={{ marginTop: "2%" }}>
                                                <div style={{ textAlign: "center", justifyContent: "flex-start", display: "flex", borderTopRightRadius: 5, borderTopLeftRadius: 5 }}>
                                                    <span className={classes[`${this.customStyle(item.InvestmentStatus)}`]}> {item.InvestmentStatus.toLowerCase() == "approved" ? "Live" : item.InvestmentStatus}</span>
                                                </div>
                                                <div className={[classes.tableHeader, classes.portfolioDetails, classes.displayMobileResponsive].join(' ')} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                    {title.map((item, index) => {
                                                        return (
                                                            <div key={index} className={[classes.directionColumn, `title${index}`, index == 0 || title.length - 1 == index ? "" : classes.hideDiv].join(" ")} style={{ textAlign: item.name == "Scheme Name" || item.name == "Scrip Name" ? "left" : "right" }}>
                                                                <div style={{ textAlign: item?.align }}>{item.header}</div>
                                                                <div style={{ textAlign: item?.align }}>{item.subHeader}</div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                                <EPFReport root={classes.tableRoot} showHeader={false} singleScheme={"Company"} multipleScheme={"Companies"} grid={classes.portfolioDetails} loader={this.state.loader} data={item.data} title={title} showTitle={false}></EPFReport>
                                            </div>
                                        )
                                    }) : <NoData></NoData>}
                                </div>}
                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.value} index={1}>
                        <EPFReport showfilter={false} showHeader={true} singleScheme={"Transaction"} multipleScheme={"Transactions"} grid={classes.transaction} loader={this.state.loader} data={this.state.transaction} title={[{ "header": "DATE", "subHeader": "", align: "left" }, { header: "Txn Type", align: "left", subHeader: "" }, { header: "Txn Nature", align: "left", subHeader: "" }, { header: "Amount", align: "right", subHeader: "" },]}></EPFReport>
                    </TabPanel>
                </div>
            );
        } catch (err) {
            console.log(err)
            return <Redirect to={labels.Path.NotFound}></Redirect>
        }

    }
}
const mapStateToProps = state => {
    // //
    return {
        //COMMON STATE
        sessionID: state.Login.sessionID,
        clientID: state.Login.logindetails?.ClientID,
        L5ID: state.Login.L5ID,
        date: state.Login.date,
        L4ID: state.Login.logindetails.L4_AccountTypeID,
        login: state.Login.logindetails,
    }
}

const mergestyles = combineStyle(Styles, commonstyletable);

export default withStyles(mergestyles)(connect(mapStateToProps, null)(LiquiLoans_tab))