import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
import Networking from '../../utils/api/apiaccess';
import secureStorage from '../../utils/securestorage';
var axios = require('axios');
function getRedemptionApidetails(TransCode,OrderId,ClientCode,SchemeCd,BuySell,
  BuySellType,DPTxn,OrderVal,Qty,AllRedeem,FolioNo,Remarks,KYCStatus,RefNo,SubBrCode
  ,EUIN,EUINVal,MinRedeem,DPC,IPAdd,Param1,Param2,Param3,SchemeID,ClientID,Source,isResubmit) {
     var IpAddress = secureStorage.getItem('ipaddress');

     var data = {};
    data.TransCode=TransCode;
    data.OrderId= OrderId;
    data.ClientCode= ClientCode;
    data.SchemeCd=SchemeCd;
    data.BuySell= BuySell;
    data.BuySellType=BuySellType;
    data.DPTxn=DPTxn;
    data.OrderVal= OrderVal;
    data.Qty= Qty;
    data.AllRedeem= AllRedeem;
    data.FolioNo=FolioNo;
    data.Remarks=Remarks;
    data.KYCStatus=KYCStatus;
    data.RefNo=RefNo;
    data.SubBrCode="";
    data.EUIN=EUIN;
    data.EUINVal= EUINVal;
    data.MinRedeem= MinRedeem;
    data.DPC=DPC;
    data.IPAdd=IpAddress;
    data.Param1=Param1;
    data.Param2=Param2;
    data.Param3=Param3;
    data.SchemeID=SchemeID;
    data.ClientID=ClientID;
    data.Source=Source;
    data.isResubmit=isResubmit;
  //   var config = {
  //     method: 'post',
  //     url: ApiValues.Redeem,
  //     headers: { 
  //       'Content-Type': 'application/json'
  //     },
  //     data : data
  //   };
    
  // return  axios(config)
  //   .then(function (response) {
  //    return response.data
  //   })
  //   .catch(function (error) {
  //     return { "status": "F", "message": labels.messages.apierror }
  //   });
    
  return Networking.PostApi(ApiValues.Redeem, data,labels.apiKey.apiKeys).then(result => { return result })
  .catch(function (error) {
    return { "status": "F", "message": labels.messages.apierror }
  });

}
export default getRedemptionApidetails
