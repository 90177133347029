import Networking from "../../utils/api/apiaccess";
import labels from "../../utils/constants/labels";
import apiurl from "../url/apiurl";
function allProductsEtlprocess(clientID,batchNo) {
    return Networking.PostApi(`${apiurl.allProductsEtlProcess}?clientId=${clientID}&batchNo=${batchNo}`, "",labels.apiKey.apiKeys).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });
}
export default allProductsEtlprocess
