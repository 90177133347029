import React, { Component } from 'react';
import {
  BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList
} from 'recharts';
import NoData from '../nodata/nodata';
import '../../components/summary/progress.css';
import CustomTooltip from '../tooltip/customtooltip'
const CustomLabel = (props) => {
  
  const { x, y, value, width } = props;
  const labelText = `${value}`;

  return (
    <text x={x + width / 0.9} y={y} dy={10} fill="#8884d8" textAnchor="right" style={{ fontSize: '12px' }}>
      {labelText}
    </text>
  );
};
const CustomLabel1 = (props) => {
  const { x, y, value, width } = props;
  const labelText = `${value}`;

  return (
    <text x={x + width / 1} y={y} dy={10} fill="#8884d8" textAnchor="top" transform={`rotate(-90, ${x}, ${y})`} style={{ fontSize: '12px' }}>
      {labelText}
    </text>
  );
};
export default class Barchart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      activeIndex: 0,
      bordercolor: "",
      name: "",
      value: ""
    }
  }
  pieenter = (item) => {
    this.setState({ name: item.name, value: item.value, bordercolor: item.fill })
  }

  rendercusontooltip = () => {
    if(this.state.name!=""&&this.state.value!=""){
      return <CustomTooltip page={"barchart"} name={this.state.name} value={this.state.value} bordercolor={this.state.bordercolor} />
    }

  }

  handleClick = (data, index) => {
    this.setState({
      activeIndex: index,
    });
  }
  
  render() {
    const { activeIndex, data } = this.state;
    const activeItem = data[activeIndex];
    return (
      <>
        {this.state.data != null && this.state.data.length != 0 ?
          <ResponsiveContainer minHeight="100%" width="100%" height={500} className="barchart-rechart">
            <BarChart width={500} height={300} data={this.props.data} /*layout='vertical'*/>            
              {/* <YAxis dataKey="label" type="category" />
              <XAxis type="number" axisLine={false} tick={false}/> */}
              <Tooltip content={this.rendercusontooltip} 
                wrapperStyle={{
                  paddingLeft: "30px", 
                  fontSize: "14px"
                }} 
              />
              <Bar dataKey="value"
                onClick={this.handleClick}
                onMouseEnter={this.pieenter}>
                {
                  data.map((entry, index) => (
                    <Cell cursor="pointer" fill={index === activeIndex ? '#82ca9d' : '#8884d8'} key={`cell-${index}`} />
                  ))
                }
                <LabelList dataKey="name" 
                content={<CustomLabel1 />}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer> : <NoData height={30} />}
        {activeItem != undefined ? <p className="content">{`${activeItem.name} : ${activeItem.value}%`}</p> : ""}
      </>
    );
  }
}
