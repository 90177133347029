import * as types from '../../constants/actionsType';
import SendOTP from '../../../api/login/sendotp'; 
export function PostSendOtp(email) {
    return (dispatch) => {
        Sendotp(dispatch, email);
        // dispatch({
        //   type: types.GET_PROFITLOSS_DATA,
        //   payload: Summary,
        // })
    }
}
function Sendotp(dispatch,email) {
    SendOTP(email).then((data) => {
        if (data != undefined) {
            if (data.status == "S") {
                dispatch({
                    type: types.SENDOTP,
                    payload: data
                });
            } else {
                dispatch({
                    type: types.SENDOTP,
                    payload:data,
                });
            }
        } else {
            dispatch({
                type: types.SENDOTP,
                payload: data,
            });
        }
    })
        .catch((error) => {
            dispatch({
                type: types.SENDOTP,
                payload: error,
            });
        })
}
