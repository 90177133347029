import Networking from '../../utils/api/apiaccess';
import { labelcolor } from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import Apivalues from '../url/apiurl'
function UpdateFolioCart(FolioList) {
    let BaseUrl = Apivalues.FolioCartUpdate
    let data = {};
    data.FolioList = JSON.stringify(FolioList)
    //    data.SessionID = SessionID

    return Networking.PostApi(BaseUrl, data).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });

}
export default UpdateFolioCart
