import axios from 'axios'
import ApiValues from '../url/apiurl'
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
/* name should be camel case in all function */
function getOnlineSTP(data) {
  const BaseUrl = ApiValues.OnlineSTPReport 

  return Networking.PostApi(BaseUrl,data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }

      // 
    });

}
export default getOnlineSTP
