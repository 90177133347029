import React from 'react';
import { withStyles } from '@material-ui/core/styles';
// import Logo from '../../utils/assets/images/03.png';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Delete from '../../utils/assets/delete.png';
import CommonDropdown from '../../components/dropdown/dropdownschemes';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Popover from "@material-ui/core/Popover";

// import colors from '../../utils/colors/colors';
import colors from '../../utils/colors/colors';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Autocomplete from '../../components/autocomplete/autocomplete';
import DeletePopup from '../../components/popup/deletepopup'
import labels from '../../utils/constants/labels';
import getAllStockList from '../../api/Dataentry/getstocks';
import DatePicker from '../../components/datepickers/date_pickers';
import CommonSnackbar from '../../components/snackbar/snackbar';
import moment from 'moment';
import { amountCommaSeperator, AmountOnly, AmountValidation, NameValidation, numbersandDeciamlOnly, replaceNull, round } from '../../utils/commonfunction';
import { TimerSharp } from '@material-ui/icons';
import InformationTooltip from '../tooltip/informationtooltip';
const styles = theme => ({
    text: {
        height: "38px",
        //  width: 241,
        border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        color: window.globalConfig.color.BLACK_PRIMARY,
        paddingLeft: "13px",
        outline: "none !important",
        fontSize: "15px",


        [theme.breakpoints.down("600")]: {
            width: "98%",
            height: "38px",
            border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
            color: window.globalConfig.color.BLACK_PRIMARY,
            outline: "none !important",
            fontSize: "13px",

            fontWeight: "600"
        },
    },
    textInput: {
        height: "38px",
        width: "80%",
        border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        color: window.globalConfig.color.BLACK_PRIMARY,
        outline: "none !important",
        fontSize: "15px",
        paddingLeft: "13px",
        paddingTop: "2.5px",
        paddingBottom: "2.5px",

        [theme.breakpoints.between("320", "499")]: {
            width: "95%",
            height: "38px",
            border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
            color: window.globalConfig.color.BLACK_PRIMARY,
            outline: "none !important",
            fontSize: "14px",

        },
        [theme.breakpoints.between("500", "600")]: {
            width: "96%",
            height: "38px",
            border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
            color: window.globalConfig.color.BLACK_PRIMARY,
            outline: "none !important",
            fontSize: "14px",

        },

    },
    textInputprofile: {
        height: "40.8px",
        width: "100%",
        border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        color: window.globalConfig.color.BLACK_PRIMARY,
        outline: "none !important",
        fontSize: "15px",
        paddingLeft: "13px",

        [theme.breakpoints.between("320", "499")]: {
            width: "94%",
            height: "38px",
            border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
            color: window.globalConfig.color.BLACK_PRIMARY,
            outline: "none !important",
            fontSize: "14px",

        },
        [theme.breakpoints.between("500", "600")]: {
            width: "96%",
            height: "38px",
            border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
            color: window.globalConfig.color.BLACK_PRIMARY,
            outline: "none !important",
            fontSize: "14px",

        },
    },
    otherTextbox: {

    },
    Flex: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            marginRight: "10px"

        },
    },
    txtDisabled: {
        display: "flex",
        flexDirection: "column",
        opacity: 0.5,
        cursor: 'not-allowed',
        [theme.breakpoints.down("xs")]: {
            marginRight: "10px"

        },
    },
    mobileResponsiveAutocomplete: {
        [theme.breakpoints.down("920")]: {
            height: 58
        },
        '&:focus': {
            border: `1px solid ${window.globalConfig.color.BROWN} !important`,
        }
    },
    mobileResponsiveAutoCompleteLabel: {
        [theme.breakpoints.down("920")]: {
            height: 58
        }
    },
    label: {
        color: window.globalConfig.color.LABLE_GRAY, fontSize: 14, textAlign: 'left', paddingBottom: 8,
        [theme.breakpoints.down("xs")]: {
            fontSize: 12,
            fontWeight: "500",
            width: "48vw"
        },
    },
    mobileResponsive: {
        [theme.breakpoints.down("920")]: {
            gridColumnEnd: "span 2",

        },
    },
    overallDiv: {
        [theme.breakpoints.down("920")]: {
            border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
            padding: "10px",
            width: "86%"
        },
    },
    labelStyle: {
        display: "none",
        [theme.breakpoints.down("920")]: {
            color: window.globalConfig.color.LABLE_GRAY, fontSize: 14, paddingBottom: "5px", textAlign: "left", display: "block !important"
        }
    },
    labelPrimaryContainer: {
        width: "97.7%", outline: "none", padding: "14px 0px 14.5px 7px",
        border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, color: window.globalConfig.color.BLACK_PRIMARY, fontSize: 13, minHeight: 15,
        textAlign: "left", paddingLeft: 10, paddingRight: 0,
        [theme.breakpoints.down("1054")]: {
            width: "96.1%"
        },
        [theme.breakpoints.down("920")]: {
            width: "95.1%"
        }
    },
    labelSecondaryContainer: {
        //width:"97%",
        width: "-webkit-fill-available",

        outline: "none", padding: "14px 0px 13.6px 7px",
        border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, color: window.globalConfig.color.BLACK_PRIMARY, fontSize: 13, minHeight: 15, textAlign: "end", paddingLeft: 0, paddingRight: 8

        ,
        '&:focus-within': {
            border: `1px solid ${window.globalConfig.color.BROWN} !important`,
        }
    },
    '@-moz-document url-prefix()': {
        labelSecondaryContainer: {

            width: "-moz-available",
            outline: "none", padding: "14px 0px 13.6px 7px",
            border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, color: window.globalConfig.color.BLACK_PRIMARY, fontSize: 13, minHeight: 15, textAlign: "end", paddingLeft: 0, paddingRight: 8

            ,
            '&:focus-within': {
                border: `1px solid ${window.globalConfig.color.BROWN} !important`,
            }
        }
    },
    widthPrimaryProperty: {
        width: "100%",


    },
    widthSecondaryProperty: {
        // width: "99%"
        // cursor: "pointer"
        // , 
        [theme.breakpoints.down("1054")]: {
            width: "-webkit-fill-available", width: "-moz-available",
        }

    }
    , marginLeftProperty: {
        [theme.breakpoints.down("920")]: {
            //   marginLeft: "-16px"
        }
    },
    image: {
        height: 18,
        width: 17,
        marginLeft: "13%",
        cursor: "pointer"
    },

});
let status = ""
class DynamicRow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            show: false, List: [], data: [], autoCompleteData: [], open: false,
            indexposition: "",
            AnchorEl: false,
            isLoading: false,
            assetname: "",
            showlabel: false,
            date: moment().format('DD MMM YYYY'),
            dateError: '',
            dateAnchorEl: null, openSnackBar: false, message: "", severity: "warning", dropdownList: []
        }
    }
    componentWillUnmount() {
        window.removeEventListener("resize", this.resize.bind(this));
    }
    async componentDidMount() {
        window.addEventListener("resize", this.resize.bind(this));
        this.resize();
        await this.setState({ List: this.props.List, dropdownList: this.props.dropdownList, data: this.props.data, autoCompleteData: this.props.autoCompleteData })
    }
    componentDidUpdate(prevProps) {
        const { List, autoCompleteData, data } = this.props;
        if (prevProps.List != List) {
            this.setState({ List: [...List] })
        }
        if (prevProps.data != data) {
            this.setState({ data: data })
        }
    }
    handleChange = async (i, e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        let formValues = this.state.List;
        let actualdata = this.state.data;
        let valid = false
        if (formValues[i][e.target.name].style == "name") {
            valid = NameValidation(removeComma)
        }
        else {
            valid = numbersandDeciamlOnly(removeComma)
        }
        if (valid) {
            if (removeComma === "") {
                formValues[i][e.target.name].name = "0";
                formValues[i][e.target.name].placeholder = "0";
                actualdata[i][e.target.name] = 0;
            } else {
                if (formValues[i][e.target.name].style == "name") {
                    formValues[i][e.target.name].name = removeComma;
                    formValues[i][e.target.name].placeholder = removeComma;
                    actualdata[i][e.target.name] = removeComma;
                } else {
                    formValues[i][e.target.name].name = removeComma;
                    formValues[i][e.target.name].placeholder = amountCommaSeperator(removeComma);
                    actualdata[i][e.target.name] = removeComma;
                }
            }

            // 
            await this.setState({ List: formValues });
            this.props.callBack(actualdata, formValues)
        }

    }
    handleOnFocus = async (i, e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        // // 
        let formValues = this.state.List;
        let actualdata = this.state.data;
        //sif()hr
        // 
        if (removeComma === "" || removeComma == "0") {
            formValues[i][e.target.name].name = "0";
            formValues[i][e.target.name].placeholder = "0";
            actualdata[i][e.target.name] = 0;
        } else {
            formValues[i][e.target.name].name = round(formValues[i][e.target.name].name, 2);

            formValues[i][e.target.name].placeholder = amountCommaSeperator(round(formValues[i][e.target.name].name, 2));
            actualdata[i][e.target.name] = removeComma;
        }

        // 
        await this.setState({ List: formValues });
        this.props.callBack(actualdata, formValues)

    }
    handleBlur = async (i, e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        // 
        let formValues = this.state.List;
        let actualdata = this.state.data;
        //sif()hr
        // 
        let valid = false
        // 
        if (formValues[i][e.target.name].style == "name") {
            valid = NameValidation(removeComma)
        }
        else {
            valid = numbersandDeciamlOnly(removeComma)
        }
        //
        if (valid) {
            if (removeComma === "" || removeComma === 0) {
                formValues[i][e.target.name].name = "0";
                formValues[i][e.target.name].placeholder = "0.00";
                actualdata[i][e.target.name] = 0;
            } else {
                formValues[i][e.target.name].name = removeComma
                formValues[i][e.target.name].placeholder = amountCommaSeperator(round(Number(removeComma), 2));
                actualdata[i][e.target.name] = removeComma;
            }

            // 
            await this.setState({ List: formValues });
            this.props.callBack(actualdata, formValues)
        }

    }
    handleClick = async (i, name, fullobject) => {
        //
        let formValues = this.state.List;
        // //sif()hr
        let List = this.state.List.map((Value, index) => {

            return Object.keys(Value).map((item) => {
                return formValues[index][item].isClicked = false;
            })
        })
        List = formValues;
        formValues[i][name].isClicked = !formValues[i][name].isClicked;

        await this.setState({ List: formValues });

    }
    handleClickAway = async () => {
        //
        let formValues = this.state.List;
        let List = this.state.List.map((Value, index) => {

            return Object.keys(Value).map((item) => {
                return formValues[index][item].isClicked = false;
            })
        })
        List = formValues;
        // formValues[i][name].isClicked = !formValues[i][name].isClicked;

        await this.setState({ List: formValues });
    }
    handleFocus = (event) => event.target.select();
    // handleOnChange = () => {
    handleOnChange = async (e) => {
        // 
        this.setState({ assetname: e })
        let a = [];
        if (e.length >= 3) {
            this.setState({ isLoading: true })
            await getAllStockList(e, this.props.location.pathname == "/Stocks" ? 2 : 3).then((res) => {
                if (res.status === "S") {
                    // this.setState({ autoCompleteData: res.assetClass })
                    if (res.data != null && res.data.length != 0) {

                        a = res.data.map((item) => {
                            return { "label": item.ScripName, "value": item.ID }
                        })
                        this.setState({ autoCompleteData: a, isLoading: false })

                    } else {
                        this.setState({ autoCompleteData: [], isLoading: false })
                    }

                    //
                } else {
                    this.setState({ autoCompleteData: [], isLoading: false })
                }

            }).catch((err) => {
                this.setState({ isLoading: false })
            })
            this.setState({ isLoading: false })
        }
        else {
            this.setState({ isLoading: false })
            this.setState({ autoCompleteData: [] })
        }

        // 
    }

    //}
    handleAutoCompleteChange = async (i, e) => {
        // 
        let formValues = this.state.List;
        let actualdata = this.state.data;
        //sif()hr
        // 
        if (e != null) {
            formValues[i]["AssetID"].name = e.label;
            formValues[i]["AssetID"].placeholder = e.label;
            actualdata[i]["AssetID"] = e.value;
            // 
            await this.setState({ List: formValues, assetname: e.label });
        }

        this.props.callBack(actualdata, formValues)
    }
    handleYesClick = async () => {
        //   
        //  if (this.state.List.length > 1) {
        let formValues = this.state.List.filter((item, index) => index != this.state.indexposition)
        //
        let actualdata = this.state.data.filter((item, index) => index != this.state.indexposition)
        //  
        this.props.callBack(actualdata, formValues)
        await this.setState({ List: formValues, AnchorEl: null, open: false });
        //  }
    }
    handleNoClick = () => {
        this.setState({ open: false })
    }
    handleDeletePopup = () => {
        this.setState({ open: true })
    }
    handleMenuClose = () => {
        this.setState({
            AnchorEl: null
        })
    }
    handleArrowClick = async (i, name, fullobject, e) => {
        // 
        // if (i != this.props.preventDelete) {
        let formValues = this.state.List
        let list = this.state.List.map((Value, index) => {

            return Object.keys(Value).map((item) => {
                return formValues[index][item].isClicked = false;
            })
        })
        list = formValues
        // //sif()hr
        this.setState({ indexposition: i })
        list[i][name].isClicked = !list[i][name].isClicked;

        await this.setState({ List: list, AnchorEl: e.currentTarget });
        // }


    }
    handleDeleteRowClose = () => {
        let formValues = this.state.List
        let list = this.state.List.map((Value, index) => {

            return Object.keys(Value).map((item) => {
                return formValues[index][item].isClicked = false;
            })
        })
        list = formValues
        this.setState({ List: list, AnchorEl: null });

    }
    resize() {
        let currentHideNav = (window.innerWidth <= 920);
        if (currentHideNav !== this.state.showlabel) {
            // 
            this.setState({ showlabel: currentHideNav });
        }
    }
    handleDateApply = async (data, i) => {

        let formValues = this.state.List;
        let actualdata = this.state.data;
        //sif()hr
        // 
        if (data != null) {
            formValues[i]["Date"].name = moment(data).format("DD MMM YYYY");
            formValues[i]["Date"].placeholder = moment(data).format("DD MMM YYYY");
            actualdata[i]["Date"] = moment(data).format("DD MMM YYYY");
            // 
            await this.setState({ List: formValues, });
        }

        this.props.callBack(actualdata, formValues)
    }
    handleDateChange = (value) => {
        this.setState({ date: moment(value).format("DD MMM YYYY") })

    }
    handleDateClick = (e) => {
        this.setState({ dateAnchorEl: e.currentTarget })
    }
    handleClose = () => {
        this.setState({ dateAnchorEl: null })
    }
    handlesnanckClose = () => {
        this.setState({ openSnackBar: false })
    }
    handleDropdown = async (value, i, key) => {
        let formValues = this.state.List;
        let actualdata = this.state.data;
        if (i != 0) {
            if (this.state.date != null) {
                formValues[i][key].name = value[0].label == "Select" ? "" : value[0].label;
                formValues[i][key].placeholder = value[0].label;
                actualdata[i][key] = value[0].value == "NA" ? "" : value[0].value;
                // 
                await this.setState({ List: formValues, });
            }
        } else {
            formValues[i][key].name = value[0].label == "Select" ? "" : value[0].label;
            formValues[i][key].placeholder = value[0].label;
            actualdata[i][key] = value[0].value == "NA" ? "" : value[0].value;
            // 
            await this.setState({ List: formValues, });
        }

        this.props.callBack(actualdata, formValues)
    }
    render() {
        const { classes } = this.props;
        return (<ClickAwayListener onClickAway={this.handleClickAway}>
            <div className={[this.props.class, classes.overallDiv].join(' ')} >

                {this.state.List.map((item, mainindex) => {

                    let main;
                    if (mainindex === 0) {
                        main = 1
                    } else {
                        main = mainindex + 1
                    }
                    let a = this.props.index * main;
                    return [...Array(this.props.count)].map((elementInArray, index) => {
                        let header = Object.keys(item)
                        let gridStyle = item[header[index]].enableDeleteIcon && this.props.deletePopupPosition == "insideTextBox" ? { display: "grid", gridTemplateColumns: "auto 24px", border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, textAlign: "center", alignItems: "center" } : {}
                        let textboxStyle = item[header[index]].enableDeleteIcon && this.props.deletePopupPosition == "insideTextBox" ? { borderRight: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`, borderTop: "none", borderLeft: "none", borderBottom: "none" } : {}
                        let arrowStyle = item[header[index]].enableDeleteIcon && this.props.deletePopupPosition == "insideTextBox" ? { fontSize: "xx-small" } : {}
                        let popupStyle = item[header[index]].enableDeleteIcon && this.props.deletePopupPosition == "insideTextBox" ? { marginLeft: 0, backgroundColor: window.globalConfig.color.WHITE, width: "107px", zIndex: 5, position: "absolute", padding: "10px 1px", textAlign: "center", border: `1px solid ${window.globalConfig.color.BORDER}`, boxShadow: "rgb(19 89 161 / 10%) 0px 0px 20px 0px", borderRadius: 5 } : { backgroundColor: window.globalConfig.color.WHITE, width: "107px", position: "absolute", marginLeft: "184px", padding: "10px 1px", textAlign: "center", border: `1px solid ${window.globalConfig.color.BORDER}`, boxShadow: "rgb(19 89 161 / 10%) 0px 0px 20px 0px", borderRadius: 5 }

                        return (
                            // 
                            <div key={index} style={{ cursor: index === this.props.count - 1 && this.props.preventDelete != 'no' ? "default" : "pointer" }} className={index == 0 ? [classes.mobileResponsive, classes.widthPrimaryProperty].join(' ') : classes.widthSecondaryProperty}>
                                {index === this.props.count - 1 && this.props.preventDelete != 'no' ? <>
                                    <div className={classes.labelStyle}>{item[header[index]].label}</div>
                                    <div className={index == 0 ? classes.labelPrimaryContainer : classes.labelSecondaryContainer}>{round(item[header[index]].placeholder, 2).toLocaleString("en-IN", { minimumFractionDigits: 2 })}
                                        {mainindex === this.props.preventDelete ? '' : <span style={{ cursor: "pointer" }} onClick={(e) => this.handleArrowClick(mainindex, header[index], this.props.preventDelete, e)}>&#x25BC;
                                            {item[header[index]].isClicked &&

                                                <Popover

                                                    open={Boolean(this.state.AnchorEl)}
                                                    anchorEl={this.state.AnchorEl}
                                                    onClose={() => { this.setState({ AnchorEl: false }) }}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "left",
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center",
                                                    }}
                                                //   disablePortal={true}
                                                >
                                                    <ClickAwayListener onClickAway={this.handleDeleteRowClose}>
                                                        <div style={{ width: 200 }}>


                                                            <ListItem
                                                                button

                                                                className="popoverlist"
                                                                onClick={() => {
                                                                    // this.handleDeleteRowClose()
                                                                    this.setState({ open: true, AnchorEl: false })
                                                                }}
                                                            >
                                                                <ListItemText primary={"Delete Row"} />
                                                            </ListItem>

                                                        </div>
                                                    </ClickAwayListener>




                                                </Popover>
                                            }
                                        </span>}
                                    </div></> : <>
                                    {item[header[index]].style == "name" ? <div className={classes.mobileResponsiveAutocomplete}>
                                        {/* {} */}
                                        <Autocomplete
                                            label={this.state.showlabel ? item[header[index]].label : ""}
                                            onChange={value => this.handleOnChange(value,)}
                                            options={this.state.autoCompleteData}
                                            selectvalue={value => this.handleAutoCompleteChange(mainindex, value)}
                                            error=""
                                            value={item[header[index]].name}
                                            placeholder={labels.dynamicRow.typeScripName}
                                            Loading={this.state.isLoading}

                                        />
                                    </div> : item[header[index]].style == "dropdown" ? <CommonDropdown

                                        handleOnChange={(value) => this.handleDropdown(value, mainindex, header[index])}

                                        value={item[header[index]].name}
                                        type={"normalwithsearch"}
                                        placeholder={item[header[index]].placeholder}

                                        height={190}
                                        id={`ddl_dropdown_${header[index]}`}
                                        List={item[header[index]].list == undefined || item[header[index]].list == null || item[header[index]].list.length == 0 ? [] : replaceNull(item[header[index]].list, "NA")}

                                    /> : item[header[index]].style == "notEditableLabel" ? <div style={{
                                        border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
                                        height: "96%",
                                        fontSize: "14px",
                                        textAlign: "center",
                                        display: "flex",
                                        paddingLeft: "10px",
                                        alignItems: "center", color: window.globalConfig.color.BLACK_PRIMARY
                                    }}>{item[header[index]].name}</div>
                                        : item[header[index]].style == "date" ? <DatePicker
                                            handleClick={(e) => this.handleDateClick(e,)}
                                            handleApply={(data) => this.handleDateApply(data, mainindex)}
                                            showingdate={item[header[index]].name == "" || item[header[index]].name == "0" ? moment().format("DD MMM YYYY") : item[header[index]].name}
                                            dateChange={this.handleDateChange}
                                            handleClose={this.handleClose}
                                            anchorEl={this.state.dateAnchorEl}
                                            error={this.state.dateError}
                                            mindate={item[header[index]].mindate}
                                            maxdate={item[header[index]].maxdate}
                                            // allowed={false} 
                                            // label={item[header[index]].label}
                                            placeholder={item[header[index]].placeholder}
                                            date={item[header[index]].name} /> : <div style={gridStyle}>
                                            <div >
                                                {item[header[index]].label != "" && <div className={classes.labelStyle}>{item[header[index]].label}</div>
                                                }<input
                                                    onBlur={(e) => { this.handleBlur(mainindex, e) }}
                                                    // tabIndex={a + index + 1}
                                                    type="text"
                                                    onClick={(e) => { this.handleFocus(e) }}
                                                    onFocus={(e) => {
                                                        this.handleOnFocus(mainindex, e)

                                                    }}
                                                    style={textboxStyle}
                                                    className={index === 0 ? classes.labelPrimaryContainer : [classes.labelSecondaryContainer, classes.marginLeftProperty, this.props.textboxStyle != undefined && this.props.textboxStyle].join(' ')}
                                                    name={header[index]} value={item[header[index]].placeholder || ""}
                                                    onChange={e => this.handleChange(mainindex, e)} />
                                            </div>
                                            {this.props.deletePopupPosition == "insideTextBox" && item[header[index]].enableDeleteIcon &&
                                                <InformationTooltip
                                                    title={this.props.tooltipTitle}
                                                    Icon={
                                                        <div>
                                                            <span style={arrowStyle} onClick={(e) => this.handleArrowClick(mainindex, header[index], this.props.preventDelete, e)}>  <div onClick={() => {

                                                                this.setState({ open: true })

                                                            }} >
                                                                <img src={Delete} className={classes.image}></img>
                                                            </div>

                                                            </span>
                                                        </div>
                                                    }
                                                >
                                                </InformationTooltip>}
                                        </div>}

                                </>}
                            </div>
                            //
                        )

                    })
                })
                }

                <CommonSnackbar message={this.state.message} open={this.state.openSnackBar} severity={this.state.severity} handlesnanckClose={this.handlesnanckClose} />

                <DeletePopup header={labels.deletePopup.confirmation} open={this.state.open}
                    onClick={this.handleYesClick} onClose={this.handleNoClick} text={labels.messages.deleteMsg} />

                {/* </ClickAwayListener> */}
            </div>
        </ClickAwayListener >
        );
    }
}
const mapStateToProps = state => ({
    login: state.Login.loginError,
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch)
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(DynamicRow)))
