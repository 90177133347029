import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import OtpInput from 'react-otp-input';
import moment from "moment";
// import styless from '../Forms/user-jss';
// import { closeVerifyOTPMsgAction, reSendOtp } from '../../redux/actions/authActions';
// import MessagesForm from '../Forms/MessagesForm';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Colors from '../../utils/colors/colors'
import { numbersOnly } from '../../utils/commonfunction';
import labels from '../../utils/constants/labels';
import { PostSendOtp } from '../../redux/actions/resetpassword/otpsend';
import CircularProgress from '@material-ui/core/CircularProgress';
import EmailTimer from '../../components/timer/emailtimmer';
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        // color: theme.palette.grey[500],
        color: window.globalConfig.color.WHITE,
    },
    header: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: window.globalConfig.color.WHITE,
    },
    btnSubmit: {
        backgroundColor: window.globalConfig.color.BROWN,
        color: window.globalConfig.color.WHITE,
        '&:hover': {
            background: window.globalConfig.color.PRIMARY_LIGHT,
            color: window.globalConfig.color.WHITE,
        },
    },
    btnCancel: {
        backgroundColor: window.globalConfig.color.RosyBrown,
        color: window.globalConfig.color.BLACK_PRIMARY,
        '&:hover': {
            background: window.globalConfig.color.PRIMARY_LIGHT,
            color: window.globalConfig.color.WHITE,

        }
    }
});
const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});
const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);
const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);
let x = ''
class SendOTP extends React.Component {
    state = {
        otp: '',
        eventDate: moment.duration().add({ minutes: 2, seconds: 0 }),
        mins: 1,
        secs: 59,
        otpTime: true,
        loader: false
    }
    componentDidMount() {
        this.updateTimer()

    }
    componentWillUnmount() {
        clearInterval(x)
    }
    updateTimer = () => {
        x = setInterval(() => {
            let { eventDate } = this.state
            if (eventDate <= 0) {
                clearInterval(x)
            } else {
                eventDate = eventDate.subtract("s", 1)
                const mins = eventDate.minutes()
                const secs = eventDate.seconds()
                this.setState({
                    mins,
                    secs,
                    eventDate
                })

                if (secs.toString().length === 1) {
                    this.setState({
                        secs: "0" + secs
                    })
                }
                if (mins === 0 && secs === 0) {
                    this.setState({
                        otpTime: false
                    })
                }
            }
        }, 2000)
    }

    resendOtp = () => {
        this.setState({
            eventDate: moment.duration().add({ minutes: 1, seconds: 59 }),
            otpTime: true
        }, () => {
            this.updateTimer()
        })
        this.props.reSendOTP(this.state.otpTime)

        // this.props.reSendOtp(this.state.otp, this.props.userId)
        //    this.props.reSendOTP(this.state.otp)
    }
    resetPasswordClick = (value) =>{
        this.props.resetPassword(this.props.emailvalue)
    }

    handleSubmit = () => {
        //    
        if (this.state.otp == "" || this.state.otp.length <= 5) {

        } else {
            this.props.onSubmit(this.state.otp)
            this.setState({
                loader: true
            })
        }

    }

    handleChange = (otp) => {
        if (numbersOnly(otp)) {
            this.setState({ otp })
        }
    };

    render() {
        const { mins, secs } = this.state
        const { classes } = this.props
        return (
            <div>
                <Dialog onClose={() => this.props.close()}

                    maxWidth={"sm"} aria-labelledby="customized-dialog-title" open={this.props.open}>
                    <div className={classes.header}>
                        <DialogTitle id="customized-dialog-title" onClose={() => this.props.close()}>
                            {labels.ForgotPassword.verifyOtp}
                        </DialogTitle>
                    </div>
                    <DialogContent dividers>
                        <form >
                            <div>
                                <Typography style={{ padding: 5 }}>{labels.ForgotPassword.digit}</Typography>

                                <OtpInput
                                    value={this.state.otp}
                                    onChange={this.handleChange}
                                    numInputs={6}
                                    isInputNum={true}
                                    inputStyle={{ margin: 3, width: 30, height: 30 }}
                                    containerStyle={{ margin: 5 }}
                                    separator={<span style={{ padding: 5 }}></span>}
                                />
                                {
                                    this.state.otpTime ?
                                        //<Typography align={'right'} style={{ padding: 5 }}> {`0${mins} : ${secs}`}</Typography>
                                        <EmailTimer
                                        text={labels.Signup.resendOTP}
                                        onclick={this.resetPasswordClick}
                                    />
                                        :
                                        <div className={classes.optArea}>
                                            <Button size="small" onClick={() => this.resetPasswordClick()} className={classes.buttonLink}>
                                                {labels.ForgotPassword.resend}
                                            </Button>
                                        </div>
                                }

                            </div>

                        </form>

                    </DialogContent>

                    <DialogActions>
                        <Button autoFocus
                            // style={{ color: 'rgb(109, 110, 112)' }}
                            className={classes.btnCancel}
                            onClick={() => this.props.close()}>
                            {labels.ForgotPassword.cancel}
                        </Button>
                        <Button className={classes.btnSubmit} autoFocus onClick={() => this.handleSubmit()}>
                            {this.state.loader == true &&
                                <CircularProgress size={24} style={{ color: window.globalConfig.color.WHITE, position: "absolute" }} />
                            }

                            {labels.ForgotPassword.submit}
                        </Button>
                    </DialogActions>

                </Dialog>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    logindetails: state.Login.logindetails,
    login: state.Login.login,
    isSendMail: state.ResetPassword.VerifyOTP,
    // isChangePassword: state.isChangePassword,
    // isSendMail: state.isSendMail,
    // isVerifyOTP: state.isVerifyOTP,
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ PostSendOtp }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SendOTP))

