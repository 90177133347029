var axios = require('axios');
import ApiValues from '../url/apiurl'
import labels from '../../utils/constants/labels';
/* name should be camel case in all function */
import Networking from '../../utils/api/apiaccess';
function GetPortfolioReport(ClientID, AccountID , AsOnDate, WithRedemption,ProductID,FamilyID,L5ID,L4ID) {
 // let BaseUrl = "https://ms.zoifintech.com/Dashboard/PortfolioAllocation"
  let data = {};
  
  data.ClientID = ClientID
  data.AccountID = AccountID
  data.AsOnDate = AsOnDate
  data.WithRedemption = WithRedemption
  data.ProductID = ProductID
  data.FamilyID = FamilyID
  data.L5ID = L5ID
  data.L4ID = L4ID
  //   ("portfolioReport",data)
//   var config = {
//     method: 'post',
//     url: ApiValues.PortfolioReport,
//     headers: { 
//       'Content-Type': 'application/json'
//     },
//     data : data
//   };
  
//  return axios(config)
//   .then(function (response) {
//    return response.data;
//   })
//   .catch(function (error) {
//     return { "status": "F", "message": labels.messages.apierror }
//   });
return Networking.PostApi(ApiValues.PortfolioReport, data).then(result => { return result })
.catch(function (error) {
  return { "status": "F", "message": labels.messages.apierror }
});
  
}
export default GetPortfolioReport
