import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Text from '../text/text';
import { connect } from 'react-redux';
import './popup.css';
import ErrorBoundary from '../Errorhandling/Errorhandle';
import label from "../../utils/constants/labels";
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import colors from '../../utils/colors/colors';
import Button from '../custombutton/button';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
import { getKycProfileAction } from '../../redux/actions/profile/kyc_details_action';
import NoData from '../nodata/nodata';
import Approved from '../../utils/assets/approved.png';
import Dialogue from '@material-ui/core/Dialog';
import Badge from '../badge/badge';
import moment from 'moment';
import scheduleIPV from '../../api/profile/scheduleipv';
import CommonSnackBar from '../../components/snackbar/snackbar'
import EyeIcon from '../../utils/assets/eyeIcon.png'
import Cross from '../../utils/assets/images/cross.jpg';
import encryptKYC from '../../api/profile/encryptionkyc';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';
import NotFound from '../../utils/assets/noImage.png';
import Skeleton from '@material-ui/lab/Skeleton';
import InformationTooltip from '../tooltip/informationtooltip';
import apiurl from '../../api/url/apiurl';
import { scrollToTopPage } from '../../utils/commonfunction';
import { WarningRounded } from '@material-ui/icons';
const styles = theme => ({
    root: {
        marginTop: 10,
        [theme.breakpoints.up(920)]: {
            marginTop: "3.5vh",
            // marginLeft: "2vw",
            marginBottom: '10vh',

        },
        [theme.breakpoints.down(920)]: {
            // marginTop: "10vh", 
            // marginLeft: "3vw",
            marginBottom: '10vh'
            // , marginRight: '6vw'
        },
        [theme.breakpoints.between('1250', '1300')]: {
            marginLeft: "0vw",
        },
        [theme.breakpoints.between('1000', '1100')]: {
            marginLeft: "-10px !important",
        },
    },
    OneSplitContainer: {
        [theme.breakpoints.up(920)]: {
            display: "grid",
            gridTemplateColumns: "repeat(1,1fr)",
        }
        // paddingLeft: "10px"
    },
    TwoSplitContainer: {
        [theme.breakpoints.up(920)]: {
            display: "grid",
            gridTemplateColumns: "repeat(2,1fr)",
        },

        // paddingLeft: "10px"
    },
    TwoSplitContainer: {
        [theme.breakpoints.up(920)]: {
            display: "grid",
            gridTemplateColumns: "repeat(2,1fr)",
        },

        // paddingLeft: "10px"
    },
    ThreeSplitAddress: {
        [theme.breakpoints.up(920)]: {
            display: "grid",
            gridTemplateColumns: "50% 50%",
            // height: "2vw",
            textAlign: "left"
        },
        [theme.breakpoints.down(920)]: {
            display: "grid",
            gridTemplateColumns: "50% 50%",
        },
    },
    ThreeSplitContainer: {
        [theme.breakpoints.up(920)]: {
            display: "grid",
            gridTemplateColumns: "36% 28% 18% 8%",
            //height: "3vw",
            paddingBottom: "10px",
            alignItems: "center"
        },
        [theme.breakpoints.down(920)]: {
            display: "grid",
            gridTemplateColumns: "repeat(3,1fr)",
            height: "13vw"
        },
        [theme.breakpoints.between('1250', '1300')]: {
            paddingBottom: "6px",
        },

        // paddingLeft: "10px"
    },
    FourSplitContainer: {
        [theme.breakpoints.up(920)]: {
            display: "grid",
            gridTemplateColumns: "repeat(4,1fr)",
        }
    },

    investorContainer: {
        [theme.breakpoints.up(920)]: {
            display: "flex",
            // marginTop:"2vw",
            // justifyContent: "space-between",
            flexDirection: "column"
        },
        [theme.breakpoints.down(920)]: {
            width: "100%",
            height: "100%",
            borderRadius: "8px",
            //border: "1px solid",
            marginTop: "5vh",
            paddingLeft: "10px", paddingTop: "10px",
            boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)"
        }
    },
    headerStyleinvestor: {
        [theme.breakpoints.up(920)]: {
            color: window.globalConfig.color.LABLE_GRAY,
            fontWeight: 500,
            fontSize: 'larger',
            textAlign: "left",
            // marginBottom: "2vh",
            display: "flex",
            flexDirection: "row",
        },
        [theme.breakpoints.down(920)]: {
            color: window.globalConfig.color.BLACK_PRIMARY,
            fontWeight: 920,
            fontSize: 'larger',
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            margin: "2%"
            // marginBottom: "2vh"
        }
    },
    profilemainlabelone: {
        [theme.breakpoints.up(920)]: {
            color: window.globalConfig.color.LABLE_GRAY,
            fontSize: "14px",
            width: '5vw',
            paddingRight: 15

        },
        [theme.breakpoints.down(920)]: {
            textAlign: "left",
            width: '100%',
            fontSize: 14,
            color: window.globalConfig.color.LABLE_GRAY,

        }

    },
    pendingstatusstyle: {
        [theme.breakpoints.down(920)]: {
            marginTop: "2vh",
            marginLeft: "18px",
            marginRight: "9px"
        }
    },
    headerStyleothers: {
        // [theme.breakpoints.up(920)]: {

        paddingBottom: "1vw",
        paddingTop: "1vw",
        display: "flex",
        flexDirection: "row",
        color: window.globalConfig.color.LABLE_GRAY,
        fontWeight: 500,
        fontSize: 'larger',
        textAlign: "left",
        [theme.breakpoints.down(920)]: {
            padding: "2vw"
        }
        // }
    },
    accountSetupStyle: {
        [theme.breakpoints.up(920)]: {
            color: window.globalConfig.color.BLACK_PRIMARY,
            fontSize: 16,
            textAlign: "left",
            display: "flex",
            flexDirection: "row",
            marginBottom: "4vh"
        },
        [theme.breakpoints.down(920)]: {
            display: "flex",
            flexDirection: "row",
            fontSize: 13,
            paddingLeft: "4px",
            marginRight: "3px",
            marginBottom: "20px",
            justifyContent: "space-between"
        }
    },
    investorCardStyle: {
        [theme.breakpoints.up(920)]: {
            height: "auto", borderRadius: "10px",
            width: "30vw", boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
            marginRight: "2vw", paddingTop: "26px"
        }
    },

    uploadPhotoStyle: {
        [theme.breakpoints.up(1000)]: {
            height: "166px", borderRadius: "30vw",
            width: "68%",
            backgroundColor: window.globalConfig.color.cyanBluish, marginLeft: '2vw',
            marginTop: '9%'
        },
        [theme.breakpoints.only(1024)]: {
            width: "90%"
        },
        [theme.breakpoints.down(920)]: {
            height: "28vh", borderRadius: "30vh", width: "28vh", backgroundColor: window.globalConfig.color.cyanBluish,
        }
    },
    uploadPhoto: {
        [theme.breakpoints.up(920)]: {
            height: "166px", borderRadius: "30vw",
            width: "68%",
            backgroundColor: window.globalConfig.color.cyanBluish,
            marginTop: '19%'
        },
        [theme.breakpoints.down(920)]: {
            height: "28vh", borderRadius: "30vh", width: "28vh",
        }
    },
    divForPhoto: {
        [theme.breakpoints.down(920)]: {
            display: "flex",
            //modified by yogitha on 21=07-2021 for photo circle
            justifyContent: "center",
            marginTop: "2vh",
        },
        [theme.breakpoints.between('920', '800')]: {
            display: "flex",
            marginTop: "5vh",
        },
        [theme.breakpoints.between('800', '1000')]: {
            display: "flex",
            marginTop: "3vh",
        }
    },

    uploadIcon: {
        width: 20, cursor: 'pointer'
    },
    crossIcon: {
        width: 24,
        height: 24,
    },
    kycContainer: {
        [theme.breakpoints.up(920)]: {
            width: "76vw", paddingLeft: '2.3vw', boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
            height: "auto", borderRadius: '10px',
            // paddingBottom:"20px"
        },
        [theme.breakpoints.down(920)]: {
            paddingRight: "15px",
            paddingLeft: "10px"
        }
    },
    flexDirection: {
        display: "flex", flexDirection: "column"
    },
    addressContainer: {
        [theme.breakpoints.up(920)]: {
            minHeight: "22vh",
            height: "auto", borderRadius: "10px",
            display: "flex", flexDirection: "column",
            gap: 10,
            width: "45vw", boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)", marginRight: "4vw", paddingTop: '1.3vw', paddingBottom: '1.3vw'
        },
        [theme.breakpoints.down(920)]: {
            display: "flex", flexDirection: "column",
            gap: 10,
            margin: '2vw'
        }
    },
    residentialAddressStyle: {
        marginLeft: "2vw",
        fontSize: 14,
        color: window.globalConfig.color.LABLE_GRAY,
        textAlign: "left",
        [theme.breakpoints.down(920)]: {
            // paddingLeft: '10px',
            textAlign: "left",
            // height: "13vh",
            // marginTop: "10px"
        },
        [theme.breakpoints.down('420')]: {
            // width:"10px"
        }
        // [theme.breakpoints.down()]
    },
    correspondanceAddressStyle: {
        fontSize: 14,
        color: window.globalConfig.color.LABLE_GRAY,
        marginLeft: "2vw",
        textAlign: "left",
        [theme.breakpoints.down(920)]: {
            // paddingRight: '10px',
            // textAlign: "right",
            //  height: "13vh",

        }
    },
    documentContainer: {
        [theme.breakpoints.up(920)]: {
            minHeight: '23vh',
            height: "auto", borderRadius: "10px",
            width: "27vw", boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
            marginRight: "4vw", paddingTop: "1vw",
            paddingLeft: "2vw",
            paddingBottom: "0.5vw"
        },
        [theme.breakpoints.down(920)]: {
            marginTop: "2vw"
        }
    },
    documentLastDiv: {
        [theme.breakpoints.down(920)]: {
            marginLeft: "2vw"
        }
    },
    Direction: {
        [theme.breakpoints.up(920)]: {
            display: "flex",
            flexDirection: "row",
            // height: "8vh",
            textAlign: 'left',
        },
        [theme.breakpoints.down(920)]: {
            display: "flex",
            flexDirection: "row",
            //marginBottom: "5vh",
            justifyContent: "space-between"
        }
        //paddingLeft: '2vw'
    },
    investormobileresponsive: {
        [theme.breakpoints.down(920)]: {
            display: "flex",
            flexDirection: "column-reverse",
            borderRadius: "8px",
            alignItems: "center"
        },

    },
    marginRight: {
        [theme.breakpoints.up(920)]: {
            // marginLeft: '-4vh'
        }
    },
    kycmobileresponsivemaincontainer: {
        [theme.breakpoints.down(920)]: {
            height: "auto",
            width: "100%",
            //border: "1px solid",
            marginTop: "5vh",
            paddingLeft: "12px",
            // paddingRight: "10px",
            paddingTop: "15px", boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
            borderRadius: "8px"
        }
    },
    addressmobileresponsivemaincontainer: {
        [theme.breakpoints.down(920)]: {
            height: "100%",
            width: "100%",
            // border: "1px solid",
            marginTop: "5vh",
            paddingTop: "10px",
            paddingLeft: "10px", boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
            borderRadius: "8px"
        }
    },
    documentMobileResponsiveContainer: {
        fontSize: 15,
        [theme.breakpoints.down(920)]: {
            height: "100%",
            width: "100%",
            // border: "1px solid",
            marginTop: "5vh",
            paddingTop: "10px",
            paddingLeft: "10px", boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
            marginBottom: "15%",
            borderRadius: "8px"
        }
    },
    kycContainerFirstDiv: {
        display: "flex", flexDirection: "row", justifyContent: "space-between", paddingRight: "15px"
    },
    showDesktopView: {
        textAlign: "left !important",
        [theme.breakpoints.up(920)]: {
            display: "block"
        },
        [theme.breakpoints.down(920)]: {
            display: "none"
        }
    },
    uploadnowStyle: {
        //marginLeft: 40,
        lineHeight: 2,
        [theme.breakpoints.down(800)]: {
            fontSize: 13,
            color: window.globalConfig.color.LABLE_GRAY
        },
        [theme.breakpoints.up(800)]: {
            fontSize: 14,
            color: window.globalConfig.color.LABLE_GRAY
        },

    },
    uploadnowStyles: {

        [theme.breakpoints.down(800)]: {
            fontSize: 13,
            color: window.globalConfig.color.LABLE_GRAY
        },
        [theme.breakpoints.up(800)]: {
            fontSize: 14,
            color: window.globalConfig.color.LABLE_GRAY
        },

    },
    uploadedStyle: {
        [theme.breakpoints.down(800)]: {
            fontSize: 13,
            color: window.globalConfig.color.BLACK
        },
        [theme.breakpoints.up(800)]: {
            fontSize: 14,
            color: window.globalConfig.color.BLACK
        },
    },
    showMobileView: {
        [theme.breakpoints.up(920)]: {
            display: "none"
        },
        [theme.breakpoints.down(920)]: {
            display: "block"
        }
    },
    left: {
        textAlign: "left",
        marginLeft: "2vw",
        fontSize: 15,
        color: window.globalConfig.color.BLACK_PRIMARY,
        [theme.breakpoints.between('920', '769')]: {
            marginTop: '10%'
        }
    },

    selfStyle: {
        [theme.breakpoints.up(920)]: {
            fontSize: 18, color: window.globalConfig.color.LABLE_GRAY, marginTop: "1px", marginLeft: "4px", fontWeight: 200
        },
        [theme.breakpoints.down(920)]: {
            fontSize: 18, color: window.globalConfig.color.LABLE_GRAY, marginLeft: "3px", fontWeight: 200
        }
    },
    iconStyle: {
        [theme.breakpoints.up(920)]: {
            marginLeft: 10,
            marginTop: 6,
            height: 18,
            width: 18
        },
        [theme.breakpoints.down(920)]: {
            marginLeft: 10,
            marginTop: 3,
            height: 18,
            width: 18
        }

    },
    ipvstatus: {
        marginTop: '2vh',
        fontSize: 14,
        [theme.breakpoints.down(920)]: {
            marginLeft: 10
        }
    },
    addressDesign: {
        fontSize: 14,
        color: window.globalConfig.color.black,
        overflowWrap: "break-word",
    },
    btnSchedule: {
        width: "57%",
        marginTop: "-13px",
        [theme.breakpoints.between(1000, 1250)]: {
            width: "80%"
        }
    },

    paper: {
        overflowY: 'unset',
    },
    customizedButton: {
        position: 'absolute',
        left: '95%',
        right: 0,
        top: '-7%',
        backgroundColor: 'lightgray',
        color: 'gray',
        width: 40,
        height: 40

    },
    iconDesign: {
        marginLeft: 10,
        cursor: 'pointer'
    },
    noDataStyle: {
        paddingBottom: 50,
        paddingTop: 30,
        // marginLeft: 100,
        width: "100%",
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        }
    },
    responsiveNoDate: {
        display: 'block !important',
        paddingBottom: 50,
        paddingTop: 30,
        //  marginLeft: 100,
        width: "100%",
        [theme.breakpoints.down('xs')]: {
            display: 'none !important'
        },
        [theme.breakpoints.between('920', '1000')]: {
            marginLeft: '40%',
            width: "130px",

        }
    },
    noDataAlign: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: 20,
            // textAlign: 'center',
            marginTop: -20
        },
        [theme.breakpoints.between('920', '770')]: {
            marginTop: '10%'
        }
    }
});
let status = ""
class MainScreenProfile extends React.Component {
    isComponentMounted = false
    constructor(props) {
        super(props)
        this.state = {
            close: true,
            open: false,
            imageValue: 0,
            opensnackbar: false,
            status: "",
            message: "", loader: false,
            loaderschedule: false
        }
    }
    componentDidMount() {
        document.title = window.globalConfig.companyDetails.companyName + " - Profile"
        this.isComponentMounted = true

        scrollToTopPage();
        // this.props.getKycProfileAction(this.props.logindetails.KYCFormNo)
    }
    componentWillUnmount() {
        this.isComponentMounted = false
    }
    componentDidUpdate(prevProps) {
    }
    // createNow() {
    //     window.open(apiurl.Domain3, "_self");
    // }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ opensnackbar: false })
    };
    PopupOpen = (value) => {
        this.setState({
            open: true,
            close: false
        })
        if (value == 1) {
            this.setState({
                imageValue: 1
            })
        }
        if (value == 2) {
            this.setState({
                imageValue: 2
            })
        }
        if (value == 3) {
            this.setState({
                imageValue: 3
            })
        }
        if (value == 4) {
            this.setState({
                imageValue: 4
            })
        }
        if (value == 5) {
            this.setState({
                imageValue: 5
            })
        }
    }
    closePopup = () => {
        this.setState({
            open: false,
            close: true
        })
    }
    ScheduleIPV = () => {
        this.setState({ loaderschedule: true })
        scheduleIPV(this.props.formNo).then((res) => {
            this.setState({ loaderschedule: false })
            this.setState({ opensnackbar: true, message: res.message, status: res.status })
        })
    }
    createNow = () => {
        this.setState({ loader: true })
        encryptKYC(this.props.logindetails.EmailID, this.props.logindetails.PhoneNumber).then((res) => {
            this.setState({ loader: false, })
            if (res.status === "F") {
                this.setState({ open: true, message: labels.messages.apierror })
            } else {
                window.open(`${apiurl.KYCDomain}${"/MFSelfKYC/ClientSignUp?x1="}${res.encryptedEmail}&x2=${res.encryptedMobile}`)

            }

        })
    }

    isBase64(isvalid) {
        const base64Pattern = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;
        return base64Pattern.test(isvalid);
    }

    addDefaultSrc(ev) {
        ev.target.src = NotFound
    }
    render() {
        const { classes } = this.props;
        try {
            return (
                <ErrorBoundary>
                    <div className={classes.root}>

                        <div className={[classes.TwoSplitContainer, classes.investormobileresponsive].join(' ')}>
                            <div className={classes.investorContainer}>
                                <div className={classes.headerStyleinvestor}>
                                    <div>{label.profile.investor}</div>
                                    <div className={classes.selfStyle}> ({label.profile.self})</div>
                                    <div style={{ marginTop: 4 }}>
                                        <InformationTooltip title={label.profile.investor}></InformationTooltip>
                                    </div>
                                </div>
                                <div className={classes.accountSetupStyle}>


                                    {this.props.formNo == "" || this.props.formNo == 0 && <>
                                        <div style={{ marginTop: "2vh", textAlign: "left", fontWeight: 500, padding: "0px 5px" }}>{label.profile.accountsetup}</div>
                                        <div style={{ width: "23%", marginLeft: 3 }}>
                                            <Button page={"primary"} name={label.profile.createnow} onClick={this.createNow} />
                                        </div></>}</div>
                                <div className={[classes.TwoSplitContainer, classes.marginRight].join(' ')} >
                                    <div className={[classes.investorCardStyle].join(' ')}>
                                        {this.props.isKycProfileDetailLoading ? <><Skeleton style={{ width: "28vw", height: "19vh", marginLeft: "1vw", marginTop: "-3vw" }} />
                                        </> :
                                            this.props.KYCDetails.kycDetails != null && this.props.KYCDetails.kycDetails != "NA" && this.props.KYCDetails.kycDetails != undefined && this.props.KYCDetails.kycDetails != 0 ?
                                                <>
                                                    {
                                                        this.props.KYCDetails.kycDetails.map((items, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <Text page={"profilestyletwo"} addon={"height"} label={label.profile.fullname} text={items.UserName == '' ? label.messages.NA : items.UserName} />
                                                                    <Text page={"profileStyleImage"} addon={"height"} label={label.profile.EmailID} text={items.Email == '' ? label.messages.NA : items.Email} src={Approved} />
                                                                    <Text page={"profileStyleImage"} addon={"height"} label={label.profile.Mobile} text={items.Mobile == '' ? label.messages.NA : items.Mobile} src={Approved} />
                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </>
                                                :

                                                <div className={classes.noDataStyle}>
                                                    <NoData />
                                                </div>


                                        }

                                    </div>
                                    <div className={[classes.investorCardStyle].join(' ')}>
                                        {this.props.isKycProfileDetailLoading ? <>
                                            <Skeleton style={{ width: "28vw", height: "19vh", marginLeft: "1vw", marginTop: "-3vw" }} />
                                        </> :
                                            this.props.KYCDetails.kycDetails != null && this.props.KYCDetails.kycDetails != "NA" && this.props.KYCDetails.kycDetails != undefined && this.props.KYCDetails.kycDetails.length != 0 ?
                                                <>
                                                    {
                                                        this.props.KYCDetails.kycDetails.map((items, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <Text page={"profilestyletwo"} addon={"height"} label={label.profile.DOB} text={items.DOB == '' ? label.messages.NA : moment(items.DOB).format("DD MMM YYYY")} />
                                                                    <Text page={"profilestyletwo"} addon={"height"} label={label.profile.Gender} text={items.Gender == '' ? label.messages.NA : items.Gender} />
                                                                    <Text page={"profilestyletwo"} addon={"height"} label={label.profile.Maritalstatus} text={items.MaritalStatus == '' ? label.messages.NA : items.MaritalStatus} />

                                                                </div>
                                                            )
                                                        })
                                                    }

                                                </>
                                                :
                                                <>
                                                    <div className={[classes.responsiveNoDate].join('')}>
                                                        <NoData />
                                                    </div>

                                                </>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div className={classes.divForPhoto}> */}
                            {this.props.isKycProfileDetailLoading ? <><Skeleton variant="circle" style={{ width: "18vw", height: "25vh" }} /></> :
                                this.props.KYCDetails.documents != null && this.props.KYCDetails.documents != "NA" && this.props.KYCDetails.documents != undefined && this.props.KYCDetails.documents.length != 0 ?
                                    this.props.KYCDetails.documents.map((img, index) => {
                                        return (
                                            // <div key={index}>
                                            // {
                                            img.UserPhoto != "" && img.UserPhoto != "NA" ?
                                                // <div>
                                                <img src={`data:image/png;base64,${img.UserPhoto}`} className={classes.uploadPhoto} />
                                                // </div>
                                                :
                                                // <div>
                                                <div className={classes.uploadPhotoStyle}>
                                                </div>
                                            // </div>
                                            // }
                                            // </div>
                                        )
                                    })
                                    :
                                    <div>
                                        <div className={classes.uploadPhotoStyle}>
                                        </div>
                                    </div>
                            }

                            {/* </div> */}
                        </div>

                        <div className={[classes.flexDirection, classes.kycmobileresponsivemaincontainer].join(' ')}>

                            <div className={classes.kycContainerFirstDiv}>
                                <div className={classes.headerStyleothers}>
                                    {/* {console.log(this.props.KYCDetails.kycDetails)} */}
                                    <div>{label.profile.Kyc}</div> <div className={classes.selfStyle}> ({label.profile.self})</div> {
                                        this.props.KYCDetails.kycDetails != null && this.props.KYCDetails.kycDetails != "" && this.props.userDetails != null && this.props.userDetails != "NA" && this.props.userDetails != undefined && this.props.userDetails.length != 0 &&


                                        <div className={classes.showDesktopView} style={{ marginLeft: 20 }}>
                                            <Badge verified={this.props.userDetails[0]?.KYCValid} text={this.props.userDetails[0].KYCValid
                                                ? labels.userCard.KYCVerified
                                                : labels.userCard.KYCNotVerified} page={"primary"} />
                                        </div>

                                    }
                                </div>
                                <div className={classes.showMobileView}>
                                    <Button page={"secondary"} disabled={true} name={label.profile.schedulenow} />
                                </div>

                            </div>
                            <div className={classes.kycContainer}>
                                {this.props.isKycProfileDetailLoading ? <><Skeleton style={{
                                    width: "74vw",
                                    height: "60vh", marginTop: "-7vw", marginBottom: "-4vw"
                                }} /></> :
                                    this.props.KYCDetails.kycDetails != null && this.props.KYCDetails.kycDetails != "NA" && this.props.KYCDetails.kycDetails.length != 0 ?
                                        <>
                                            {
                                                this.props.KYCDetails.kycDetails.map((items, index) => {
                                                    return (
                                                        <div key={index}>

                                                            <div className={[classes.FourSplitContainer].join('')} style={{ marginTop: 40 }}>

                                                                <Text page={"profilestyletwo"} label={"PAN"} text={items.PAN == '' ? label.messages.NA : items.PAN} />
                                                                <Text page={"profilestyletwo"} label={label.profile.ipvstatus} text={items.IPVRemark == '' ? label.profile.pending : items.IPVRemark} />
                                                                <div className={classes.showDesktopView}>
                                                                    <div className={classes.btnSchedule}>
                                                                        <Button loader={this.state.loaderschedule} page={items.IPVStatus == 1 ? "secondary" : "primary"} name={label.profile.schedulenow} onClick={this.ScheduleIPV} disabled={items.IPVStatus == 1 ? true : false} />
                                                                    </div>
                                                                </div>

                                                                <div></div>
                                                                <Text page={"profilestyletwo"} label={label.profile.Occupation} text={items.Occupation || label.messages.NA} />
                                                                <Text page={"profilestyletwo"} label={label.profile.Incomerange} text={items.AnualIncome || label.messages.NA} />
                                                                <Text page={"profilestyletwo"} label={label.profile.Fathersname} text={items.FatherName || label.messages.NA} />
                                                                <Text page={"profilestyletwo"} label={label.profile.Mothersname} text={items.MotherName || label.messages.NA} />
                                                                <Text page={"profilestyletwo"} label={label.profile.COB} text={items.CityOfBirth || label.messages.NA} />
                                                                <Text page={"profilestyletwo"} label={label.profile.Citizenship} text={items.Citizenship || label.messages.NA} />
                                                                <Text page={"profilestyletwo"} label={label.profile.taxresidency} text={items.TaxResidency || label.messages.NA} />
                                                                <Text page={"profilestyletwo"} label={label.profile.residentialstatus} text={items.ResidentialStatus || label.messages.NA} />
                                                                <Text page={"profilestyletwo"} label={label.profile.PoliticalStatus} text={items.PoliticalExperence || label.messages.NA} />
                                                                <Text page={"profilestyletwo"} label={label.profile.wealth} text={items.WealthSource || label.messages.NA} />

                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                        </>
                                        :
                                        <>
                                            <div style={{ marginTop: 50, marginBottom: 50 }}>
                                                <NoData />
                                            </div>

                                        </>
                                }
                            </div>
                        </div>

                        <div className={classes.TwoSplitContainer}>
                            <div className={[classes.flexDirection, classes.addressmobileresponsivemaincontainer].join(' ')}>
                                <div className={classes.headerStyleothers}>
                                    {label.profile.Address}
                                </div>
                                <div className={[classes.addressContainer].join(' ')} >

                                    <div className={classes.ThreeSplitAddress}>
                                        <div className={classes.residentialAddressStyle}>{label.profile.Residentialaddress}</div>

                                        <div className={classes.correspondanceAddressStyle}>
                                            {label.profile.correspondanceaddress}
                                        </div>
                                    </div>
                                    <div className={[classes.ThreeSplitAddress, classes.Mobile].join(" ")} >
                                        <div className={classes.left}>
                                            {this.props.isKycProfileDetailLoading ? <><Skeleton style={{ width: "17vw", height: "29vh", marginTop: "-5vw" }} /></> :
                                                this.props.KYCDetails.currentAddress != null && this.props.KYCDetails.currentAddress != "NA" && this.props.KYCDetails.currentAddress != undefined && this.props.KYCDetails.currentAddress.length != 0 ?
                                                    <div>
                                                        {
                                                            this.props.KYCDetails.currentAddress.map((item, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <div style={{ paddingBottom: 5 }}>
                                                                            <span className={classes.addressDesign}>{item.AddressLine1 == '' ? label.messages.NA : item.AddressLine1},</span>
                                                                        </div>
                                                                        <div style={{ paddingBottom: 5 }}>
                                                                            <span className={classes.addressDesign}>{item.AddressLine2 == '' ? label.messages.NA : item.AddressLine2},</span>
                                                                        </div>
                                                                        <div style={{ paddingBottom: 5 }}>
                                                                            <span className={classes.addressDesign}>{item.City == '' ? label.messages.NA : item.City},</span>  <span>{item.State},</span>
                                                                        </div>
                                                                        <div>
                                                                            <span className={classes.addressDesign}>{item.Country == '' ? label.messages.NA : item.Country},</span>  <span>{item.Pincode}</span>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                    :
                                                    <div className={classes.noDataAlign}>
                                                        {label.messages.NA}
                                                    </div>
                                            }

                                        </div>
                                        <div className={classes.left}>
                                            {this.props.isKycProfileDetailLoading ? <><Skeleton style={{ width: "20vw", height: "29vh", marginTop: "-5vw", marginLeft: "-1vw" }} /></> :
                                                this.props.KYCDetails.correspondenceAddress != null && this.props.KYCDetails.correspondenceAddress != "NA" && this.props.KYCDetails.correspondenceAddress != undefined && this.props.KYCDetails.correspondenceAddress.length != 0 ?
                                                    <div>
                                                        {
                                                            this.props.KYCDetails.correspondenceAddress.map((item, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <div>
                                                                            <div style={{ paddingBottom: 5 }}>
                                                                                <span className={classes.addressDesign}>{item.AddressLine1 == '' ? label.messages.NA : item.AddressLine1},</span>
                                                                            </div>
                                                                            <div style={{ paddingBottom: 5 }}>
                                                                                <span className={classes.addressDesign}>{item.AddressLine2 == '' ? label.messages.NA : item.AddressLine2},</span>
                                                                            </div>
                                                                            <div style={{ paddingBottom: 5 }}>
                                                                                <span className={classes.addressDesign}>{item.City == '' ? label.messages.NA : item.City},</span>  <span>{item.State},</span>
                                                                            </div>
                                                                            <div>
                                                                                <span className={classes.addressDesign}>{item.Country == '' ? label.messages.NA : item.Country},</span>  <span>{item.Pincode}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }

                                                    </div>
                                                    :
                                                    <div className={classes.noDataAlign}>
                                                        {label.messages.NA}
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={[classes.flexDirection, classes.documentMobileResponsiveContainer].join(' ')} >
                                <div className={classes.headerStyleothers}>
                                    {label.profile.Document}
                                </div>
                                <div className={classes.documentContainer}>
                                    <div className={classes.documentLastDiv}>
                                        {this.props.isKycProfileDetailLoading ? <><Skeleton style={{ width: "25vw", height: "29vh", marginTop: "-3vw", marginBottom: "-1vw" }} /></> :
                                            this.props.KYCDetails.documents != null && this.props.KYCDetails.documents != "NA" && this.props.KYCDetails.documents != undefined && this.props.KYCDetails.documents.length != 0 ?

                                                this.props.KYCDetails.documents.map((img, index) => {
                                                    return (
                                                        <div key={index}>
                                                            <div style={{ marginTop: 5 }}>
                                                                {
                                                                    img.UserPhoto != 'NA' ?
                                                                        <>
                                                                            <div className={classes.ThreeSplitContainer}>
                                                                                <div style={{ textAlign: "left" }}>{label.profile.photo}</div>
                                                                                <div className={classes.uploadedStyle}>{label.profile.uploaded}</div>
                                                                                {/* <div><img src={EyeIcon} className={classes.uploadIcon} onClick={() => this.PopupOpen(1)}></img></div> */}
                                                                                <div>{this.isBase64(img.UserPhoto) ? (<img src={Approved} className={classes.crossIcon}></img>) : (<WarningRounded style={{ color: "#ff8100" }} />)}</div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className={classes.ThreeSplitContainer}>
                                                                                <div style={{ textAlign: "left" }}>{label.profile.photo}</div>
                                                                                <div className={classes.uploadnowStyles} >{label.profile.noducment}</div>
                                                                                {/* <div><img src={EyeIcon} style={{ opacity: 0.5, width: 20, cursor: "not-allowed" }}></img></div> */}
                                                                                <div><img src={Cross} className={classes.crossIcon}></img></div>
                                                                            </div>
                                                                        </>
                                                                }
                                                            </div>
                                                            <div>
                                                                {
                                                                    img.PANCard != 'NA' ?
                                                                        <>
                                                                            <div className={classes.ThreeSplitContainer}>
                                                                                <div style={{ textAlign: "left" }}>{label.profile.pancard}</div>
                                                                                <div className={classes.uploadedStyle} >{label.profile.uploaded}</div>
                                                                                {/* <div><img src={EyeIcon} className={classes.uploadIcon} onClick={() => this.PopupOpen(2)}></img></div> */}
                                                                                <div>{this.isBase64(img.PANCard) ? (<img src={Approved} className={classes.crossIcon}></img>) : (<WarningRounded style={{ color: "#ff8100" }} />)}</div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className={classes.ThreeSplitContainer}>
                                                                                <div style={{ textAlign: "left" }}>{label.profile.pancard}</div>
                                                                                <div className={classes.uploadnowStyles} >{label.profile.noducment}</div>
                                                                                {/* <div><img src={EyeIcon} style={{ opacity: 0.5, width: 20, cursor: "not-allowed" }}></img></div> */}
                                                                                <div><img src={Cross} className={classes.crossIcon}></img></div>
                                                                            </div>
                                                                        </>
                                                                }
                                                            </div>
                                                            <div>
                                                                {
                                                                    img.Address != 'NA' ?
                                                                        <>
                                                                            <div className={classes.ThreeSplitContainer}>
                                                                                <div style={{ textAlign: "left" }}>{label.profile.AddressProof}</div>
                                                                                <div className={classes.uploadedStyle}>{label.profile.uploaded}</div>
                                                                                {/* <div><img src={EyeIcon} className={classes.uploadIcon} onClick={() => this.PopupOpen(3)}></img></div> */}
                                                                                <div>{this.isBase64(img.Address) ? (<img src={Approved} className={classes.crossIcon}></img>) : (<WarningRounded style={{ color: "#ff8100" }} />)}</div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className={classes.ThreeSplitContainer}>
                                                                                <div style={{ textAlign: "left" }}>{label.profile.AddressProof}</div>
                                                                                <div className={classes.uploadnowStyles}>{label.profile.noducment}</div>
                                                                                {/* <div><img src={EyeIcon} style={{ opacity: 0.5, width: 20, cursor: "not-allowed" }}></img></div> */}
                                                                                <div><img src={Cross} className={classes.crossIcon}></img></div>
                                                                            </div>
                                                                        </>
                                                                }
                                                            </div>
                                                            {/* <div>
                                                                {
                                                                    img.Signature != '' ?
                                                                        <>
                                                                            <div className={classes.ThreeSplitContainer}>
                                                                                <div style={{ textAlign: "left" }}>{label.profile.signature}</div>
                                                                                <div className={classes.uploadedStyle}>{label.profile.uploaded}</div>
                                                                                <div><img src={EyeIcon} className={classes.uploadIcon} onClick={() => this.PopupOpen(4)}></img></div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className={classes.ThreeSplitContainer}>
                                                                                <div style={{ textAlign: "left" }}>{label.profile.signature}</div>
                                                                                <div className={classes.uploadnowStyles}>{label.profile.noducment}</div>
                                                                                <div><img src={Cross} className={classes.crossIcon}></img></div>
                                                                            </div>
                                                                        </>
                                                                }
                                                            </div> */}
                                                            <div>
                                                                {
                                                                    img.AadharCard != 'NA' ?
                                                                        <>
                                                                            <div className={classes.ThreeSplitContainer}>
                                                                                <div style={{ textAlign: "left" }}>{label.profile.aadhar}</div>
                                                                                <div className={classes.uploadedStyle} >{label.profile.uploaded}</div>
                                                                                {/* <div><img src={EyeIcon} className={classes.uploadIcon} onClick={() => this.PopupOpen(5)}></img></div> */}
                                                                                <div>{this.isBase64(img.Address) ? (<img src={Approved} className={classes.crossIcon}></img>) : (<WarningRounded style={{ color: "#ff8100" }} />)}</div>
                                                                            </div>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <div className={classes.ThreeSplitContainer}>
                                                                                <div style={{ textAlign: "left" }}>{label.profile.aadhar}</div>
                                                                                <div className={classes.uploadnowStyles} >{label.profile.noducment}</div>
                                                                                {/* <div><img src={EyeIcon} style={{ opacity: 0.5, width: 20, cursor: "not-allowed" }}></img></div> */}
                                                                                <div><img src={Cross} className={classes.crossIcon}></img></div>
                                                                            </div>
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div>
                                                    <div className={classes.ThreeSplitContainer}>
                                                        <div style={{ textAlign: "left" }}>{label.profile.photo}</div>
                                                        <div className={classes.uploadnowStyle}>{label.profile.noducment}</div>

                                                    </div>
                                                    <div className={classes.ThreeSplitContainer}>
                                                        <div style={{ textAlign: "left" }}>{label.profile.pancard}</div>
                                                        <div className={classes.uploadnowStyle} >{label.profile.noducment}</div>

                                                    </div>
                                                    <div className={classes.ThreeSplitContainer}>
                                                        <div style={{ textAlign: "left" }}>{label.profile.AddressProof}</div>
                                                        <div className={classes.uploadnowStyle}>{label.profile.noducment}</div>

                                                    </div>
                                                    <div className={classes.ThreeSplitContainer}>
                                                        <div style={{ textAlign: "left" }}>{label.profile.signature}</div>
                                                        <div className={classes.uploadnowStyle}>{label.profile.noducment}</div>

                                                    </div>
                                                    <div className={classes.ThreeSplitContainer}>
                                                        <div style={{ textAlign: "left" }}>{label.profile.aadhar}</div>
                                                        <div className={classes.uploadnowStyle}>{label.profile.noducment}</div>

                                                    </div>
                                                </div>
                                        }

                                        <Dialogue

                                            onClose={this.closePopup}
                                            maxHeight={'md'} maxWidth={'lg'}
                                            aria-labelledby="simple-dialog-title"
                                            open={this.state.open}
                                            classes={{ paper: classes.paper }}
                                        // style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
                                        >

                                            {/* <div style={{alignItems: 'baseline'}}>
                                            <IconButton>
                                                <CloseIcon />
                                            </IconButton>
                                        </div> */}
                                            {this.props.KYCDetails.documents != null && this.props.KYCDetails.documents != "NA" && this.props.KYCDetails.documents != undefined && this.props.KYCDetails.documents.length != 0 &&


                                                this.props.KYCDetails.documents.map((img, index) => {
                                                    return (
                                                        <div key={index}>
                                                            {
                                                                this.state.imageValue == 1 &&
                                                                <img src={`data:image/${img.UserPhotoExtension};base64,${img.UserPhoto}`} style={{ width: 300 }} onError={this.addDefaultSrc} />
                                                            }
                                                            {
                                                                this.state.imageValue == 2 &&
                                                                <img src={`data:image/${img.PANCardExtension};base64,${img.PANCard}`} style={{ width: 300 }} onError={this.addDefaultSrc} />
                                                            }
                                                            {
                                                                this.state.imageValue == 3 &&
                                                                <img src={`data:image/${img.AddressExtension};base64,${img.Address}`} style={{ width: 300 }} onError={this.addDefaultSrc} />
                                                            }
                                                            {/* {
                                                                this.state.imageValue == 4 &&
                                                                <img src={`data:image/${img.SignatureExtension};base64,${img.Signature}`} style={{ width: 300 }} onError={this.addDefaultSrc} />
                                                            } */}
                                                            {
                                                                this.state.imageValue == 5 &&
                                                                <img src={`data:image/${img.AadharCardExtension};base64,${img.AadharCard}`} style={{ width: 300 }} onError={this.addDefaultSrc} />
                                                            }
                                                        </div>
                                                    )
                                                })
                                            }
                                            {/* <DialogActions> */}
                                            <IconButton className={classes.customizedButton} onClick={this.closePopup}>
                                                <CloseIcon />
                                            </IconButton>
                                            {/* </DialogActions> */}
                                        </Dialogue>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <CommonSnackBar handlesnanckClose={this.handlesnanckClose} open={this.state.opensnackbar} message={this.state.message} severity={this.state.status == "F" ? "warning" : "success"} />
                </ErrorBoundary>
            );
        } catch (err) {
            // console.log(err)
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => ({
    userDetails:
        state.ProfileDetails.AllProfileDetails.length != 0
            ? state.ProfileDetails.AllProfileDetails.mainDetails != "NA"
                ? state.ProfileDetails.AllProfileDetails.mainDetails
                : []
            : [],
    mainDetails: state.ProfileDetails.AllProfileDetails.length == 0 ? [] : state.ProfileDetails.AllProfileDetails.mainDetails != "NA" ? state.ProfileDetails.AllProfileDetails.mainDetails : [],
    personalDetails: state.ProfileDetails.AllProfileDetails.length == 0 ? [] : state.ProfileDetails.AllProfileDetails.personalDetails != "NA" ? state.ProfileDetails.AllProfileDetails.personalDetails : [],
    logindetails: state.Login.logindetails,
    KYCDetails: state.KYCDetails.KycDatas,
    isKycProfileDetailLoading: state.KYCDetails.isKycProfileDetailsLoading,
    formNo: state.Login.formNo,

    // JointHolder: state.ProfileDetails.AllProfileDetails.jointHolderDetails != null ? state.ProfileDetails.AllProfileDetails.jointHolderDetails : [],
})
const mapDispatchToProps = dispatch => ({
    getKycProfileAction: (FormNo) => dispatch(getKycProfileAction(FormNo))
    // return bindActionCreators({}, dispatch)
})
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(MainScreenProfile))