import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PostSendOtp } from '../../redux/actions/resetpassword/otpsend';
import Color from "../../utils/colors/colors";
import labels from '../../utils/constants/labels';
export const emailValidator = email => {
    const re = /\S+@\S+\.\S+/;
    if (!email || email.length <= 0) return labels.messages.required
    if (!re.test(email)) {
        return 'Enter valid Email';
    }
    return '';
};
const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    header: {
        backgroundColor:window.globalConfig.color.PRIMARY,
        color: window.globalConfig.color.WHITE,
        height: 50,
        fontWeight: 500
    },
    txtHeader: {
        padding: 15,
        paddingLeft:30,
    },
    clearIcon: {
        float: 'right',
        cursor: 'pointer'
    },
    txtBody: {
        padding: 10,
        fontSize: 13,
        color: window.globalConfig.color.LABLE_GRAY
    },
    body: {
        margin: 20,
        textAlign: 'center'

    },
    textAligns: {
        width: 201,
        textAlign: "flex-start"
    },
    footer: {
        padding: "14px 20px 20px 20px",
        marginTop: -15,

    },
    btnParent: {
        padding: "15px 10px 10px 10px",
        textAlign: 'center'
    },
    btnContinue: {
        color:window.globalConfig.color.WHITE,
        width: 201,
        height: 40,
        backgroundColor:window.globalConfig.color.BROWN,
        borderWidth: 0,
        borderRadius: 5,
        '&:hover': {
            background: window.globalConfig.color.PRIMARY_BORDER,
            color: window.globalConfig.color.WHITE,
        },
    },
    textFieldCursor:{
        caretColor:window.globalConfig.color.BROWN ,
      },
});
function SendOTP(props) {
    const [email, setEmail] = useState({ value: '', error: '' })
    const [loading, setLoading]= useState(false)
    useEffect(() => {
        // props.forgotPassword(props.email)
        setEmail({ value: props.email, error: '' })

    }, []);

    const handleSubmit = () => {
        const newPasswoedError = emailValidator(email.value);
        setLoading(true)
        if (newPasswoedError) {
            setEmail({ ...email, error: newPasswoedError })
        } else {
            props.onSubmit(email.value.trim())
        }
    }
    const { classes } = props;
    return (
        <div>
            <Dialog 
            
            onClose={() => props.close()} maxWidth={'md'}
             aria-labelledby="simple-dialog-title" open={props.open}>

                {/* ===================NEW DESIGN - AFTER CHANGE DESIGN================================ */}
                <div>
                    <div className={classes.header}>
                        <div className={classes.txtHeader}>
                            {labels.ForgotPassword.ForgotPassword}
                        <span className={classes.clearIcon} onClick={() => props.close()}>&#x2715;</span>
                        </div>
                    </div>
                    <div className={classes.body}>
                        {/* <div className={classes.txtBody}>* Click on submit to get the OTP </div> */}
                        <FormControl >                            
                            <TextField
                                id="standard-width"                               
                                className={[classes.textAligns,classes.textFieldCursor]}
                                margin="normal"
                                name="Email ID"
                                label={labels.ForgotPassword.emailID}
                                value={email.value}
                                onChange={(e) => { props.close(); const error = emailValidator(e.target.value); setEmail({ value: e.target.value, error: error }) }}
                                error={email.error.length > 0}
                                helperText={email.error}
                                disabled={false}
                            />
                        </FormControl>
                    </div>
                </div>
                <div className={classes.footer}>
                    <div className={classes.btnParent}>
                        <button className={classes.btnContinue} onClick={() => handleSubmit()}>  {loading == true &&
                            <CircularProgress size={24} style={{color:window.globalConfig.color.WHITE,position:"absolute", marginLeft:15}} />
                        }
                        {labels.ForgotPassword.submit}
                        </button>
                    </div>
                </div>

                {/* =================== BEFORE CHANGE DESIGN================================ */}
                {/* 
                <DialogTitle id="customized-dialog-title" style={{backgroundColor:'red'}} align={'center'} onClose={() => props.close()}>
                    Forgot Password
                 </DialogTitle>
                <DialogContent Dividers>
                    <form >
                        <div>
                            <Typography style={{ padding: 5 }} variant="p">* Click on submit to get the OTP
                            </Typography>
                            <FormControl style={{ width: "70%", padding: 5, paddingTop: 20 }}>
                                <TextField
                                    id="standard-basic"
                                    name="Email ID"
                                    label="Email ID"
                                    value={email.value}
                                    onChange={(e) => { props.closeMsg(); const error = emailValidator(e.target.value); setEmail({ value: e.target.value, error: error }) }}
                                    error={email.error.length > 0}
                                    helperText={email.error}
                                    disabled={true}
                                />
                            </FormControl>

                        </div>
                    </form>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus
                        style={{ color: 'rgb(109, 110, 112)' }}
                        onClick={() => props.close()} style={{ backgroundColor: window.globalConfig.color.brown, color: window.globalConfig.color.WHITE }}>
                        CANCEL
                    </Button>
                    <Button autoFocus disabled={props.isLoadingSendMail} onClick={() => handleSubmit()} style={{ backgroundColor: window.globalConfig.color.green, color: window.globalConfig.color.WHITE }}>
                        {props.isLoadingSendMail ?
                            <CircularProgress size={24} />
                            :
                            "SUBMIT"
                        }
                    </Button>
                </DialogActions> */}

            </Dialog>
        </div>
    );
}
const mapStateToProps = state => ({
    logindetails: state.Login.logindetails,
    login: state.Login.login,
    isSendMail: state.ResetPassword.VerifyOTP,
    // isChangePassword: state.isChangePassword,
    // isSendMail: state.isSendMail,
    // isVerifyOTP: state.isVerifyOTP,
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ PostSendOtp }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SendOTP))
