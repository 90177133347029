//page created by dulcy
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom'
import CommonHeaders from '../../components/report_common_header/report_common_header'
import Label from '../../utils/constants/labels';
import { withStyles } from '@material-ui/core';
import './afrstylesheet.css'
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom'//import Select from '@material-ui/core/Select';
import moment from 'moment';
import { Encryption, NegativeFinding, replaceNull } from '../../utils/commonfunction';
import NoData from '../../components/nodata/nodata';
import labels from '../../utils/constants/labels';
import getAllcommonDownloadDetails from '../../api/commondownload/commondownload';
import Loader from '../../components/loader/loader'
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyle from '../../utils/stylesheets/table/commontablestyle';
import colors from '../../utils/colors/colors';
import apiurl from '../../api/url/apiurl';
import CommonSnackbar from '../../components/snackbar/snackbar'
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import Pagination from '../../components/pagination/renderpage';
import { MContext } from '../../components/pagination/renderpage';
import InformationTooltip from '../../components/tooltip/informationtooltip';
var title;
const styles = theme => ({
    Categoryname: {
        fontSize: "18px",
        fontWeight: "bold",
        color: window.globalConfig.color.BLACK_PRIMARY
    },
    commonsubheading: {
        fontSize: "16px",
        fontWeight: "bold",
        color: window.globalConfig.color.BLACK_PRIMARY,
    },
    invested: {
        fontSize: "16px",
        fontWeight: "bold",
        color: window.globalConfig.color.BLACK_PRIMARY,
        textAlign: "left",
        width: 304,
        marginLeft: "25px"
    },
    Units: {
        fontSize: "13px",
        fontWeight: 500,
        color: window.globalConfig.color.BLACK_PRIMARY
    },
    topheader: {
        textAlign: "center",
        lineHeight: 2.0,
        // paddingBottom: "2em",
        fontSize: "15px", fontWeight: 500, color: window.globalConfig.color.LABLE_GRAY
    },
    rupeesymbol: {
        margin: "0px !important",
        fontSize: "13px !important",
        fontWeight: "normal !important",
        color: `${window.globalConfig.color.LABLE_GRAY} !important`,
        padding: "1px 0px",
    },
    subheadingrupeesymbol: {
        margin: "0px !important",
        fontSize: "16px !important",
        fontWeight: "normal !important",
        color: `${window.globalConfig.color.LABLE_GRAY} !important`,
        padding: "1px 0px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "14px !important",
        }
    },
    SchemeName: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "13px",
        fontWeight: 500
    },
    ElssSchemeName: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "14px",
        fontWeight: 500
    },
    exitload: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "13px"
    },
    total: {
        color: window.globalConfig.color.WHITE,
        fontSize: "15px"
    },

    HeaderBlock: {
        // [theme.breakpoints.down('xs')]: {
        //     display: "none !important"
        // }
    },
    // HeaderBlockResponsive: {
    //     display: "none !important",
    //     [theme.breakpoints.down('xs')]: {
    //         display: "block !important",
    //         paddingBottom: 40
    //     }
    // }

    //STLT

    STLT: {
        flex: 8,
        marginTop: "-30px",
        marginBottom: "3%",

    },
    STLTResponsive: {
        display: "none !important",
        [theme.breakpoints.down('xs')]: {
            display: "block !important",
            padding: "0px 10px"
            // margin: -16
            // marginTop:"-10px !important"
        }
    },
    firstGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "firstGrid"
        }
    },
    secondGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "secondGrid"
        }
    },
    thirdGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "thirdGrid"
        }
    },
    fourthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "fourthGrid"
        }
    },
    fifthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "fifthGrid"
        }
    },
    sixthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "sixthGrid"
        }
    },
    seventhGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "seventhGrid"
        }
    },
    eighthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "eighthGrid"
        }
    },
    ninthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "ninthGrid"
        }
    },
    tenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "tenthGrid"
        }
    },
    eleventhGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "eleventhGrid"
        }
    },
    twelfthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "twelfthGrid"
        }
    },
    thirteenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "thirteenthGrid"
        }
    },
    fourteenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "fourteenthGrid"
        }
    },
    fifteenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "fifteenthGrid"
        }
    },
    sixteenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "sixteenthGrid"
        }
    },
    seventeenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "seventeenthGrid"
        }
    },
    eighteenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "eighteenthGrid"
        }
    },
    ninteenthGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "ninteenthGrid"
        }
    },
    twentiethGrid: {
        [theme.breakpoints.down("920")]: {
            gridArea: "twentiethGrid"
        }
    },
    mobileResponsive: {
        display: "none",
        [theme.breakpoints.down("920")]: {
            display: "grid"
        }
    },
    labelStyle: {
        fontSize: 14,
        fontWeight: 500,
        color: colors.LABLE_GRAY
    },
    desktopResponsive: {
        display: "grid",
        [theme.breakpoints.down("920")]: {
            display: "none !important"
        }
    },
    ExitBlock: {
        [theme.breakpoints.down('xs')]: {
            display: "none !important"
        }
    },
    ExitResponsive: {
        display: "none !important",
        [theme.breakpoints.down('xs')]: {
            display: "block !important",
            margin: "0px 10px"
        }
    },
    ELSSBlock: {
        [theme.breakpoints.down('xs')]: {
            display: "none !important"
        }
    },
    ELSSResponsive: {
        display: "none !important",
        [theme.breakpoints.down('xs')]: {
            display: "block !important",
            margin: "0px 10px"
        }
    },
    flexrow: {
        display: "flex", flexDirection: "row", justifyContent: "space-between"
    },
    shorttermholdingheader: {
        display: "flex", marginLeft: "27%", justifyContent: "flex-start"
    },
    longtermholding: {
        display: "flex", marginLeft: "14%", justifyContent: "center"
    },
    totalholding: {
        display: "flex", marginLeft: "15%"

    },
    right: {
        textAlign: "right"
    },
    center: {
        textAlign: "center"
    },
    left: {
        textAlign: "left"
    },
    schemeNamecommonstyle: {
        textAlign: 'left'
    },
    InvestedAmount: {
        paddingTop: "18%"
    },
    CurrentAmount: {
        paddingTop: "5%"
    },
    categorycontainer: {
        backgroundColor: window.globalConfig.color.ZERO_BACKGROUND, flex: 1,
        height: "91px",
        alignItems: "center",
        borderRadius: 8, color: window.globalConfig.color.BLACK_PRIMARY,
    },
    grandtotalcontainer: {
        backgroundColor: window.globalConfig.color.BLACK_PRIMARY, paddingTop: "3%", height: 44, paddingBottom: "3%", borderRadius: 6, marginTop: '1%',
        marginRight: "1.5%"
    },
    grandtotaltext: {
        textAlign: 'left', color: window.globalConfig.color.WHITE, fontSize: 18
    },
    whitecolor: {
        color: window.globalConfig.color.WHITE
    },
    //Exitload
    ExitLoadcontainer: {
        display: "flex", marginRight: "21%", flex: 1, flexDirection: "row", justifyContent: "flex-end"
    }
    , totalunitsexitload: {
        marginLeft: -8,
    },
    gainlossexitload: {
        marginLeft: -11,
    },
    //ELSS
    Elsscontainer: {
        display: "flex", flexDirection: "row", backgroundColor: window.globalConfig.color.ZERO_BACKGROUND, flex: 1,
        paddingTop: "2%", paddingBottom: "2%", paddingLeft: "1%", paddingRight: "1%",
        borderRadius: 6, color: window.globalConfig.color.BLACK_PRIMARY,
        marginTop: "-20px",
        marginRight: "1.5%"
    },
    amcnameelss: {
        display: 'flex', flex: 1, justifyContent: "flex-start", textTransform: "uppercase"
    },
    ElssAmccontainer: {
        backgroundColor: window.globalConfig.color.ZERO_BACKGROUND, paddingTop: "2%", paddingBottom: "2%",
        paddingLeft: "1%", paddingRight: "1%", borderRadius: 6, color: window.globalConfig.color.BLACK_PRIMARY,
        textAlign: "left",
        marginRight: "1.5%"
    },

    directionColumn: {
        display: "flex",
        flexDirection: "column"
    },
    headerAlign: {
        marginTop: "3.5%",

        [theme.breakpoints.between('1250', '1300')]: {
            marginTop: "4%",
        }
    },
    noDataDesign: {
        display: "none !important",
        [theme.breakpoints.between('300', '600')]: {
            display: "block !important",
            width: "100%",
            height: 50,
            paddingTop: 10,
            paddingBottom: 30
        }
    }
});
class ShortTerm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownvalue: "ShortTerm/LongTerm Taxation",
            checked: true,
            accpdf: false,
            accexcel: false,
            subreportID: 1,
            showelss: true,
            Open: false,
        }
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ Open: false })
    };
    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
    }

    render() {
        // 
        const { classes } = this.props
        let combainStyle = Object.assign({}, classes, this.props.externalClasses)
        // try {
        return (

            <div >
                <div className={[combainStyle.FourSplitContainer, combainStyle.mainTitle, combainStyle.headerAlign, combainStyle.desktopResponsive].join(' ')}>
                    <div></div>
                    {this.props.title[0].mainTitle.map((item) => {
                        return <div>

                            {item.name}
                        </div>
                    })}
                </div>
                <div className={[combainStyle.TenSplitContainerFirstContainerAFR, combainStyle.tableHeader, combainStyle.desktopResponsive].join(' ')} style={{ paddingLeft: 10, paddingRight: 10 }}>
                    {
                        this.props.title[0].list.map((item, index) => {
                            return (<div key={index} style={{ display: "flex", flexDirection: "column", textAlign: item.name == "Scheme Name" || item.name == "Scrip Name" ? "left" : 'right', width: item.name == "Scheme Name" || item.name == "Scrip Name" ? 'auto' : 'auto' }}>
                                <div>
                                    {item.name}
                                </div>
                                {item.subheader != undefined ? <div>{item.subheader.toLowerCase() == "fathering" ? <InformationTooltip type="" title={"GrandFahtering for Long Term Equity & Indexation for Long Term Debt"} Icon={<span>{item.subheader + "..."}</span>}></InformationTooltip> : item.subheader}</div> : <div></div>}

                                {item.subheader1 != undefined ? <div>{item.subheader1}</div> : <div></div>}
                            </div>
                            )
                        })
                    }
                </div>
                <div className={[combainStyle.tableHeader, combainStyle.mobileResponsive].join(' ')} style={{ paddingLeft: 10, marginTop: "2%", textAlign: "left" }}>
                    <div>
                        {this.props.title[0].name}
                    </div>
                </div>
                {this.props.data.length != 0 && <Pagination data={this.props.data}>
                    <MContext.Consumer>
                        {(context) => {
                            // console.log(context)
                            return <>
                                {context.filter((ele, ind) => ind === context.findIndex(elem => elem.SubAssetClassName === ele.SubAssetClassName)).length != 0 && context.filter((ele, ind) => ind === context.findIndex(elem => elem.SubAssetClassName === ele.SubAssetClassName))?.map((item, mainindex) => {
                                    return <div key={mainindex}>
                                        <div className={[combainStyle.TenSplitContainerFirstContainerAFR, combainStyle.categoryGroupContainer, mainindex == 0 && combainStyle.borderRadiousFirstIndex].join(' ')} style={{ marginTop: mainindex === 0 ? 0 : '1%', }}>
                                            <div className={[combainStyle.directionColumn, combainStyle.categoryMainHeader, combainStyle.firstGrid, "firstGrid"].join(' ')}>
                                                <div style={{ fontSize: 14 }}>
                                                    {item.AssetClassName.length + item.SubAssetClassName.length < 25 ? `${item.AssetClassName}${replaceNull(item.AssetClassName, "NA") != "NA" ? "-" : ""}${item.SubAssetClassName}` : <Tooltip TransitionComponent={Zoom} title={item.SubAssetClassName}>
                                                        <label style={{ textOverflow: 'ellipsis' }}>
                                                            {window.globalConfig.isUserCanAdd == undefined || window.globalConfig.isUserCanAdd == true ?
                                                                ` ${item.AssetClassName}${item.AssetClassName != undefined && "-"}${((item.SubAssetClassName).substring(0, 11 - 3))}...` : `${((item.SubAssetClassName).substring(0, 20 - 3))}...`}
                                                        </label></Tooltip>}
                                                </div>
                                                <span className={combainStyle.categorySubHeader}>  {context.filter((value) => value.SubAssetClassName == item.SubAssetClassName).length > 1 ? `${context.filter((value) => value.SubAssetClassName == item.SubAssetClassName).length} ${this.props.flag == 'equity' ? "Scrips" : "Schemes"}` : `${context.filter((value) => value.SubAssetClassName == item.SubAssetClassName).length} ${this.props.flag == 'equity' ? "Scrip" : "Scheme"}`}
                                                </span>
                                            </div>
                                            {/* <div className={[combainStyle.secondGrid, "secondGrid"].join(" ")}>

                                            </div> */}
                                            <div className={[combainStyle.categoryMainValue, combainStyle.secondGrid, "secondGrid", combainStyle.desktopResponsive].join(" ")}>
                                                <span className={combainStyle.categoryRupee}>{Label.currency.Rupee}  <span style={{ color: colors.BLACK_PRIMARY, fontWeight: 500 }}>{NegativeFinding(item.SubAsset_STH_InvestedValue, "", "", "", "number")}</span>  </span>

                                            </div>

                                            <div className={[combainStyle.directionColumn, combainStyle.categoryMainValue, combainStyle.thirdGrid, "thirdGrid", combainStyle.desktopResponsive].join(' ')}>
                                                <div>
                                                    <span className={combainStyle.categoryRupee}>{Label.currency.Rupee}</span>
                                                    {NegativeFinding(item.SubAsset_STH_CurrentValue, "", '', "", "number")}
                                                </div>
                                                <div>
                                                    <span className={combainStyle.categoryRupee}>{Label.currency.Rupee}</span>

                                                    <span style={{ color: NegativeFinding(item.SubAsset_STH_GainLoss) }}>{NegativeFinding(item.SubAsset_STH_GainLoss, "", "", "", "number")}</span>
                                                </div>
                                            </div>
                                            <div className={[combainStyle.fourthGrid, "fourthGrid", combainStyle.desktopResponsive].join(" ")}>
                                                <span className={combainStyle.categoryRupee}>{Label.currency.Rupee} <span style={{ color: colors.BLACK_PRIMARY, fontWeight: 500 }}>{NegativeFinding(item.SubAsset_LTH_InvestedValue, "", "", "", "number")}</span></span>
                                            </div>
                                            {/* <div className={[combainStyle.categoryMainValue, combainStyle.sixthGrid, "sixthGrid", combainStyle.desktopResponsive].join(" ")}>
                                                <span className={combainStyle.categoryRupee}>{Label.currency.Rupee} <span style={{ color: colors.BLACK_PRIMARY, fontWeight: 500 }}>{NegativeFinding(item.SubAsset_LTH_InvestedValue, "", "", "", "number")}</span></span>

                                            </div> */}


                                            <div className={[combainStyle.directionColumn, combainStyle.categoryMainValue, combainStyle.fifthGrid, "fifthGrid", combainStyle.desktopResponsive].join(' ')}>
                                                <div>
                                                    <span className={combainStyle.categoryRupee}>{Label.currency.Rupee}</span>
                                                    {NegativeFinding(item.SubAsset_LTH_CurrentValue, "", "", "", "number")}
                                                </div>
                                                <div>
                                                    <span className={combainStyle.categoryRupee}>{Label.currency.Rupee}</span>
                                                    <span style={{ color: NegativeFinding(item.SubAsset_LTH_GainLoss) }}>{NegativeFinding(item.SubAsset_LTH_GainLoss, "", "", "", "number")}</span>
                                                </div>

                                            </div>
                                            {<div className={[combainStyle.directionColumn, combainStyle.categoryMainValue, combainStyle.eighthGrid, "eighthGrid"].join(' ')} style={{ paddingRight: 10 }}>
                                                <div style={{ color: NegativeFinding(item.SubAsset_GrandFathering) }}>
                                                    <span className={combainStyle.categoryRupee}>{Label.currency.Rupee}</span>
                                                    {NegativeFinding(item.SubAsset_GrandFathering, "", "", "", "number")}
                                                </div>
                                                {<div className={[combainStyle.directionColumn, combainStyle.categoryMainValue, combainStyle.ninthGrid, "ninthGrid"].join(' ')} >
                                                    <div>
                                                        <span className={combainStyle.categoryRupee}>{Label.currency.Rupee}</span>
                                                        <span style={{ color: NegativeFinding(item.SubAsset_Indexation) }}>{NegativeFinding(item.SubAsset_Indexation, "", "", "", "number")}</span>
                                                    </div>
                                                </div>}
                                            </div>}

                                            {/* <div className={[combainStyle.sixthGrid, combainStyle.categoryMainValue, "sixthGrid", combainStyle.desktopResponsive].join(" ")}>
                                                <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>Total</span>
                                                <div style={{ display: "flex", flexDirection: "row", textAlign: "right", justifyContent: "end" }}>
                                                    <span className={combainStyle.categoryRupee}>{Label.currency.Rupee}</span>
                                                    {NegativeFinding(item.SubAsset_InvestedValue, "", "", "", "number")}
                                                </div>
                                            </div> */}
                                            {/* <div className={[combainStyle.categoryMainValue, combainStyle.ninthGrid, "ninthGrid"].join(" ")}>
                                                <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>Total</span>
                                                <span className={combainStyle.categoryRupee}>{Label.currency.Rupee}</span>
                                                {NegativeFinding(item.subtotalinvested, "", "", "", "number")}
                                            </div> */}

                                            {/* <div className={[combainStyle.directionColumn, combainStyle.categoryMainValue, combainStyle.seventhGrid, "seventhGrid"].join(' ')} style={{ paddingRight: 10 }}>
                                                <div>
                                                    <span className={combainStyle.categoryRupee}>{Label.currency.Rupee}</span>
                                                    {NegativeFinding(item.SubAsset_CurrentValue, "", "", "", "number")}
                                                </div>
                                                <div>
                                                    <span className={combainStyle.categoryRupee}>{Label.currency.Rupee}</span>
                                                    <span style={{ color: NegativeFinding(item.SubAsset_GainLoss) }}>

                                                        {NegativeFinding(item.SubAsset_GainLoss, "", "", "", "number")}
                                                    </span>
                                                </div>
                                            </div> */}

                                        </div>
                                        {context.filter((value) => value.SubAssetClassName == item.SubAssetClassName).map((val, subindex) => {
                                            return (
                                                <div key={subindex} className={[combainStyle.TenSplitContainerSecondContainerAFR, combainStyle.subCategoryGroupContainer, subindex == 0 && combainStyle.borderRadiousFirstIndex].join(' ')}>
                                                    <div className={[combainStyle.subCatgoryValue, combainStyle.eleventhGrid, "eleventhGrid"].join(" ")} style={{ textAlign: "left", }}>
                                                        {val.SchemeName}
                                                    </div>

                                                    <div className={[combainStyle.subCatgoryValue, combainStyle.twelfthGrid, "twelfthGrid", combainStyle.desktopResponsive].join(" ")}>

                                                        <div>
                                                            <span className={combainStyle.subcategoryRupee}>{Label.currency.Rupee}</span>
                                                            {NegativeFinding(val.STH_InvestedValue, "", "", "", "number")}
                                                        </div>
                                                        <div>
                                                            <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>Balanced Units</span>
                                                            {NegativeFinding(val.STH_TotalQuantity, "", "", "", "number")}
                                                        </div>
                                                    </div>
                                                    {/* <div className={[combainStyle.subCatgoryValue, combainStyle.thirteenthGrid, "thirteenthGrid", combainStyle.desktopResponsive].join(" ")}>
                                                       
                                                    </div> */}
                                                    <div className={[combainStyle.directionColumn, combainStyle.thirteenthGrid, "thirteenthGrid", combainStyle.desktopResponsive].join(" ")}>
                                                        <div className={combainStyle.subCatgoryValue}>
                                                            <span className={combainStyle.subcategoryRupee}>{Label.currency.Rupee}</span>
                                                            {NegativeFinding(val.STH_CurrentValue, "", "", "", "number")}
                                                        </div>
                                                        <div className={combainStyle.subCatgoryValue}>
                                                            <span className={combainStyle.subcategoryRupee}>{Label.currency.Rupee}</span>
                                                            <span style={{ color: NegativeFinding(val.STH_GainLoss) }}>      {NegativeFinding(val.STH_GainLoss, "", "", "", "number")}</span>
                                                        </div>
                                                    </div>

                                                    <div className={[combainStyle.subCatgoryValue, combainStyle.fourteenthGrid, "fourteenthGrid", combainStyle.desktopResponsive].join(" ")}>

                                                        <div>
                                                            <span className={combainStyle.subcategoryRupee}>{Label.currency.Rupee}</span>
                                                            {NegativeFinding(val.LTH_InvestedValue, '', "", "", "number")}
                                                        </div>
                                                        <div>
                                                            <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>Redeemed Units</span>
                                                            {NegativeFinding(val.LTH_TotalQuantity, "", "", "", "number")}
                                                        </div>
                                                    </div>
                                                    {/* <div className={[combainStyle.subCatgoryValue, combainStyle.fifteenthGrid, "fifteenthGrid", combainStyle.desktopResponsive].join(" ")}>
                                                        <div>
                                                            <span className={combainStyle.subcategoryRupee}>{Label.currency.Rupee}</span>
                                                            {NegativeFinding(val.LTH_InvestedValue, '', "", "", "number")}
                                                        </div>

                                                    </div> */}
                                                    <div className={[combainStyle.directionColumn, combainStyle.fifteenthGrid, "fifteenthGrid", combainStyle.desktopResponsive].join(" ")}>
                                                        <div className={combainStyle.subCatgoryValue}>
                                                            <span className={combainStyle.subcategoryRupee}>{Label.currency.Rupee}</span>
                                                            {NegativeFinding(val.LTH_CurrentValue, "", "", "", "number")}
                                                        </div>
                                                        <div className={combainStyle.subCatgoryValue}>
                                                            <span className={combainStyle.subcategoryRupee}>{Label.currency.Rupee}</span>
                                                            <span style={{ color: NegativeFinding(val.LTH_GainLoss) }}>   {NegativeFinding(val.LTH_GainLoss, "", "", "", "number")}</span>
                                                        </div>

                                                    </div>
                                                    {<div className={[combainStyle.directionColumn, combainStyle.subCatgoryValue, combainStyle.eighthGrid, "eighthGrid"].join(' ')}  >
                                                        <div style={{ color: NegativeFinding(val.GrandFathering) }}>
                                                            {NegativeFinding(val.GrandFathering, "", "", "", "rupee")}
                                                        </div>
                                                        {<div className={[combainStyle.directionColumn, combainStyle.subCatgoryValue, combainStyle.ninthGrid, "ninthGrid"].join(' ')} >
                                                            <div style={{ color: NegativeFinding(val.Indexation) }}>
                                                                {NegativeFinding(val.Indexation, "", "", "", "rupee")}
                                                            </div>
                                                        </div>}
                                                    </div>}

                                                    {/* <div className={[combainStyle.subCatgoryValue, combainStyle.sixteenthGrid, "sixteenthGrid"].join(" ")}>

                                                        <div>
                                                            <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>Invested   </span>
                                                            <span className={combainStyle.subcategoryRupee}>{Label.currency.Rupee}</span>
                                                            {NegativeFinding(val.TotalInvestedValue, "", "", "", "number")}
                                                        </div>
                                                        <div>
                                                            <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>Units     </span>
                                                            {NegativeFinding(val.TotalQuantity, "", '', "", "number")}
                                                        </div>
                                                    </div> */}
                                                    {/* <div className={[combainStyle.subCatgoryValue, combainStyle.ninteenthGrid, "ninteenthGrid"].join(" ")}>
                                                        <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>Invested   </span>
                                                        <span className={combainStyle.subcategoryRupee}>{Label.currency.Rupee}</span>
                                                        {NegativeFinding(val.TotalInvestedValue, "", "", "", "number")}
                                                    </div> */}
                                                    {/* <div className={[combainStyle.directionColumn, combainStyle.seventeenthGrid, "seventeenthGrid"]}>
                                                        <div className={combainStyle.subCatgoryValue}>
                                                            <span className={combainStyle.subcategoryRupee}>{Label.currency.Rupee}</span>
                                                            {NegativeFinding(val.TotalCurrentValue, "", "", "", "number")}
                                                        </div>
                                                        <div className={combainStyle.subCatgoryValue}>
                                                            <span className={combainStyle.subcategoryRupee}>{Label.currency.Rupee}</span>
                                                            <span style={{ color: NegativeFinding(val.TotalGainLoss) }}> {NegativeFinding(val.TotalGainLoss, "", "", "", "number")}</span>
                                                        </div>
                                                    </div> */}

                                                </div>
                                            )
                                        })}
                                    </div>
                                })}
                                {this.props.totalshortterm.length != 0 && this.props.totalshortterm.map((item, index) => {
                                    return (
                                        <div key={index} className={[combainStyle.TenSplitContainerFirstContainerAFR, combainStyle.displayTotalContainer].join(' ')}>
                                            <div style={{ textAlign: "left" }} className={[combainStyle.firstGrid, "firstGrid"].join(" ")}>
                                                {this.props.grandtotal}
                                            </div>

                                            <div className={[combainStyle.secondGrid, "secondGrid", combainStyle.desktopResponsive].join(" ")}>
                                                <span className={combainStyle.totalRupee}>{Label.currency.Rupee}<span style={{ color: colors.WHITE, fontWeight: 500 }}>{NegativeFinding(item.STH_InvestedValue, "", "", "", "number")}</span></span>

                                            </div>
                                            {/* <div style={{ textAlign: "right", }} className={[combainStyle.thirdGrid, "thirdGrid", combainStyle.desktopResponsive].join(" ")}>
                                                <span className={combainStyle.totalRupee}>{Label.currency.Rupee}<span style={{ color: colors.WHITE, fontWeight: 500 }}>{NegativeFinding(item.STH_InvestedValue, "", "", "", "number")}</span></span>

                                            </div> */}
                                            <div className={[combainStyle.directionColumn, combainStyle.thirdGrid, "thirdGrid", combainStyle.desktopResponsive].join(" ")} style={{ textAlign: "right" }}>
                                                <div>
                                                    <span className={combainStyle.totalRupee}>{Label.currency.Rupee}</span>
                                                    {NegativeFinding(item.STH_CurrentValue, "", "", "", "number")}
                                                </div>
                                                <div>
                                                    <span className={combainStyle.totalRupee}>{Label.currency.Rupee}</span>
                                                    <span style={{ color: NegativeFinding(item.STH_GainLoss) }}>  {NegativeFinding(item.STH_GainLoss, "", "", "", "number")}</span>
                                                </div>
                                            </div>

                                            <div className={[combainStyle.fourthGrid, "fourthGrid", combainStyle.desktopResponsive].join(" ")}>
                                                <span className={combainStyle.totalRupee}>{Label.currency.Rupee} <span style={{ color: colors.WHITE }}>{NegativeFinding(item.LTH_InvestedValue, "", "", "", "number")}
                                                </span></span>
                                            </div>
                                            {/* <div style={{ textAlign: "right" }} className={[combainStyle.sixthGrid, "sixthGrid", combainStyle.desktopResponsive].join(" ")}>
                                                <span className={combainStyle.totalRupee}>{Label.currency.Rupee} <span style={{ color: colors.WHITE }}>{NegativeFinding(item.LTH_InvestedValue, "", "", "", "number")}
                                                </span></span>
                                            </div> */}
                                            <div className={[combainStyle.directionColumn, combainStyle.fifthGrid, "fifthGrid", combainStyle.desktopResponsive].join(" ")} style={{ textAlign: "right" }}>
                                                <div>
                                                    <span className={combainStyle.totalRupee}>{Label.currency.Rupee}</span>
                                                    {NegativeFinding(item.LTH_CurrentValue, "", "", "", 'number')}
                                                </div>
                                                <div>
                                                    <span className={combainStyle.totalRupee}>{Label.currency.Rupee} <span style={{ color: NegativeFinding(item.LTH_GainLoss) }}>   {NegativeFinding(item.LTH_GainLoss, "", "", "", "number")}</span></span>

                                                </div>
                                            </div>
                                            {<div className={[combainStyle.directionColumn, combainStyle.categoryMainValue, combainStyle.eighthGrid, "eighthGrid"].join(' ')} style={{ paddingRight: 10 }}>
                                                <div style={{ color: NegativeFinding(item.TotalGrandFathering) }}>
                                                    {NegativeFinding(item.TotalGrandFathering, "", "", "", "rupee")}
                                                </div>
                                                <div className={[combainStyle.directionColumn, combainStyle.categoryMainValue, combainStyle.ninthGrid, "ninthGrid"].join(' ')} >
                                                    <span style={{ color: NegativeFinding(item.TotalIndexation) }}>
                                                        {NegativeFinding(item.TotalIndexation, "", "", "", "rupee")}
                                                    </span>
                                                </div>
                                            </div>}
                                            {/* <div className={[combainStyle.sixthGrid, "sixthGrid"].join(' ')}>
                                                <span className={combainStyle.totalRupee}>{Label.currency.Rupee} <span style={{ color: colors.WHITE, fontWeight: 500 }}>{NegativeFinding(item.TotalInvestedValue, "", "", "", "number")}</span></span>

                                            </div> */}
                                            {/* <div style={{ textAlign: "right" }} className={[combainStyle.ninthGrid, "ninthGrid"].join(' ')}>
                                                <span className={combainStyle.totalRupee}>{Label.currency.Rupee} <span style={{ color: colors.WHITE, fontWeight: 500 }}>{NegativeFinding(item.TotalInvestedValue, "", "", "", "number")}</span></span>

                                            </div> */}
                                            {/* 
                                            <div className={[combainStyle.directionColumn, combainStyle.seventhGrid, "seventhGrid"].join(" ")} style={{ textAlign: "right", paddingRight: 10 }}>
                                                <div >
                                                    <span className={combainStyle.totalRupee}>{Label.currency.Rupee}
                                                        <span style={{ color: colors.WHITE, fontWeight: 500 }}>{NegativeFinding(item.TotalCurrentValue, "", "", "", "number")}</span>
                                                    </span>

                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "end" }}>
                                                    <span className={combainStyle.totalRupee}>{Label.currency.Rupee}</span>
                                                    <span style={{ color: NegativeFinding(item.TotalGainLoss) }}>     {NegativeFinding(item.TotalGainLoss, "", "", "", "number")}</span>
                                                </div>
                                            </div> */}


                                        </div>
                                    )
                                })}
                            </>
                        }}
                    </MContext.Consumer>
                </Pagination>}


            </div >
        )
    }
}
const mapStateToProps = state => {
    // 
    return {
        sessionID: state.Login.sessionID,
        clientID: state.Login.logindetails.ClientID,
        userID: state.Login.userID,
        date: state.Login.date,
        companyDetails: state.Login.companyDetails.length != 0 ? state.Login.companyDetails[0] : [],
        logindetails: state.Login.logindetails,
        L5ID: state.Login.L5ID,

    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch)
}
const mergedStyle = combineStyles(styles, CommonTableStyle)
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(ShortTerm))