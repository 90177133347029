import * as types from "../../constants/actionsType";
import getxSipOrder from "../../../api/purchaseorder/xsiporderapi";
export function XSipOrder(
  TransactionCode,
  ClientCode,
  SchemeCode,
  InternalRefNo,
  TransMode,
  DpTxnMode,
  StartDate,
  FrequencyType,
  FrequencyAllowed,
  InstallmentAmount,
  NoOfInstallment,
  Remarks,
  FolioNo,
  FirstOrderFlag,
  SubberCode,
  Euin,
  EuinVal,
  DPC,
  XsipRegID,
  IPAdd,
  Param1,
  Param2,
  Param3,
  SchemeID,
  ClientID,
  MandateID,
  Source, ID, SuccessMethod, FailureMethod, totalSchemeLength, currentLength
) {
  return (dispatch) => {
    xSipOrderAction(
      dispatch,
      TransactionCode,
      ClientCode,
      SchemeCode,
      InternalRefNo,
      TransMode,
      DpTxnMode,
      StartDate,
      FrequencyType,
      FrequencyAllowed,
      InstallmentAmount,
      NoOfInstallment,
      Remarks,
      FolioNo,
      FirstOrderFlag,
      SubberCode,
      Euin,
      EuinVal,
      DPC,
      XsipRegID,
      IPAdd,
      Param1,
      Param2,
      Param3,
      SchemeID,
      ClientID,
      MandateID,
      Source, ID,
      SuccessMethod, FailureMethod, totalSchemeLength, currentLength
    );
  };

  function xSipOrderAction(
    dispatch,
    TransactionCode,
    ClientCode,
    SchemeCode,
    InternalRefNo,
    TransMode,
    DpTxnMode,
    StartDate,
    FrequencyType,
    FrequencyAllowed,
    InstallmentAmount,
    NoOfInstallment,
    Remarks,
    FolioNo,
    FirstOrderFlag,
    SubberCode,
    Euin,
    EuinVal,
    DPC,
    XsipRegID,
    IPAdd,
    Param1,
    Param2,
    Param3,
    SchemeID,
    ClientID,
    MandateID,
    Source, ID, SuccessMethod, FailureMethod, totalSchemeLength, currentLength
  ) {
    dispatch({
      type: types.LOADER_PURCHASE_ORDER,
      payload: true
    });
    getxSipOrder(
      TransactionCode,
      ClientCode,
      SchemeCode,
      InternalRefNo,
      TransMode,
      DpTxnMode,
      StartDate,
      FrequencyType,
      FrequencyAllowed,
      InstallmentAmount,
      NoOfInstallment,
      Remarks,
      FolioNo,
      FirstOrderFlag,
      SubberCode,
      Euin,
      EuinVal,
      DPC,
      XsipRegID,
      IPAdd,
      Param1,
      Param2,
      Param3,
      SchemeID,
      ClientID,
      MandateID,
      Source
    )
      .then((data) => {
        //   (data)
        let response = data
        response.FolioNo = FolioNo
        
        if (data != undefined) {
          if (data.status == "F" || data.status == "S") {
            if (totalSchemeLength > 1) {
              let output = data
              output.SchemeID = SchemeID
              dispatch({
                type: types.PURCHASE_ORDER,
                payload: output
              });
              if (data.status == "S") {

                SuccessMethod(SchemeID, data.status, totalSchemeLength === currentLength, response,ID)
              } else {

                FailureMethod(SchemeID,data.message, totalSchemeLength === currentLength, response,ID)
              }
              if (totalSchemeLength === currentLength) {
                dispatch({
                  type: types.LOADER_PURCHASE_ORDER,
                  payload: false
                });
              }


            } else {
              let output = data
              output.SchemeID = SchemeID
              dispatch({
                type: types.PURCHASE_ORDER,
                payload: output
              });
              dispatch({
                type: types.LOADER_PURCHASE_ORDER,
                payload: false
              });
              if (data.status == "S") {

                SuccessMethod(SchemeID, data.status, true, response,ID)
              }
              else {

                FailureMethod(SchemeID,data.message, true, response,ID)
              }
            }

          } else {
            dispatch({
              type: types.PURCHASE_ORDER,
              payload: [],
            });
            dispatch({
              type: types.LOADER_PURCHASE_ORDER,
              payload: false
            });
          }
        }
      })
      .catch((error) => {
        dispatch({
          type: types.LOADER_PURCHASE_ORDER,
          payload: false
        });
      });
  }
}
export const getPurchaseOrder = (data, schemeData) => {

  return {
    type: types.PURCHASE_ORDER,
    payload: data
    ,
  };
};
