import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router';
import { withStyles } from '@material-ui/core';
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle';
import CommonSnackbar from '../../components/snackbar/snackbar';
import labels from '../../utils/constants/labels';
import { getL5reportDetails } from "../../redux/actions/L5Report/L5ReportAction";
import DeletePopup from '../../components/popup/deletepopup';
import Subheader from '../../components/subheader/subheader';
import DynamicList from '../../components/list/dynamicList';
import Addtransactionandevaluation from '../../components/popup/addtransactionandevaluation';
import OutlinedButton from '../../components/payment/button';
import { deleteListing } from '../../redux/actions/aifandpms/aifandpms_action';
import deleteAIFList from '../../api/aifandpms/delete_aif_list';
import getAIFandPMSList from '../../api/aifandpms/aifandpms_list';
import { getAIFandPMSDetails } from "../../redux/actions/aifandpms/aifandpms_action";
import { replaceNull } from '../../utils/commonfunction';
import moment from 'moment';
import Loader from '../../components/loader/loader';
import getAddUpdateDeleteAssetTransaction from '../../api/aifandpms/add_update_delete_asset_transaction';
import Toggle from "../../components/toggle/toggle";
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import { Listing } from '../../redux/actions/aifandpms/aifandpms_action';
// 
import { scrollToTopPage } from '../../utils/commonfunction';
const styles = theme => ({

    grid: {
        display: 'grid',
        gridTemplateColumns: '27% 27% 25% 3%',
        gap: '16px',
        minHeight: "auto",
        padding: 10,
        //[theme.breakpoints.down('920')]:{
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateAreas: `"${"myArea ."}"`,
            height: "auto !important",
            rowGap: "20px",
            paddingTop: 10,
            display: "grid",
        }
        // }
    },

    commitmentGrid: {
        display: 'grid',
        gridTemplateColumns: '43% 47% 10%',
        gap: '16px',
        minHeight: "auto",
        padding: 10,
        //[theme.breakpoints.down('920')]:{
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateAreas: `"${"myArea"}"`,
            height: "auto !important",
            rowGap: "20px",
            paddingTop: 10,
            display: "grid",
        }
        // }
    },
    valuationGrid: {
        display: 'grid',
        gridTemplateColumns: '40% 40% 5%',
        gap: '20px'
    },
    transactionGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(5,1fr)',
        gap: '20px'
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down("920")]: {
            flexDirection: 'column'
        }
    },
    sideSpace: {
        width: '12%',
        [theme.breakpoints.down("920")]: {
            display: 'none'
        }
    },
    mainCard: {
        display: "flex", marginBottom: "3%",
        boxShadow: "rgb(19 89 161 / 10%) 0px 0px 20px 0px",
        borderRadius: 5,
        // minHeight: "",
        height: "auto",
        width: "89%", marginTop: "18px",
        padding: '4%',
        paddingTop: "13px !important",
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down("920")]: {
            margin: 'auto'
        }
    },
    subHeader: {
        textAlign: 'left',
        marginTop: '1pc',
        color: window.globalConfig.color.LABLE_GRAY,
        // marginLeft: 10,
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
    cardSpereaterLine: {
        height: 0.5,
        width: "100%",
        // backgroundColor: window.globalConfig.color.AliceBlue,
        backgroundColor: window.globalConfig.color.LABLE_GRAY,
        marginBottom: '1pc',
        marginTop: '6px',
        // marginLeft: 10,
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
    twoColumnGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)'
    },
    headerGrid: {
        textAlign: 'left',
        paddingLeft: 15
    },
    buttonStyle: {
        width: "20%",
        [theme.breakpoints.down("920")]: {
            width: "50%"
        }
    },
    textboxWidth: {
        // width:"inherit !important"
    },
    ContainerWithWidth: {
        [theme.breakpoints.up("920")]: {
            display: "grid",
            gridTemplateColumns: "50% 50%",
            // paddingLeft: "10px"
            // marginLeft: "20px",
            width: "100%", marginRight: 0,
        },
        // paddingBottom: 11, paddingTop: 11,
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "50% 50%",
            marginLeft: 5,
            display: "grid",
            //  width: "89.2%"
        }
    },
    assetGrid: {
        //  gridTemplateColumns: "19% auto auto auto auto auto auto auto",
        gridTemplateColumns: "21% 12% 17% 17% 16% 16% 1%",
        display: "grid",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateAreas: `"${"myArea ."}"`,
            height: "auto !important",
            rowGap: "20px",
            paddingTop: 10,
            display: "grid",
        }
    },
    dialogDesign: {
        [theme.breakpoints.up("920")]: {
            width: "48%",
            maxWidth: "58%",
        }
    },
    marginTopAlignment: {
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
        marginBottom: 10
    },
    buttonAlignment: {
        display: "flex",
        flexDirection: 'row',
        alignItems: "center",
        justifyContent: "end",
        columnGap: 10
    },

    DynamicList2: {
        [theme.breakpoints.down("920")]: {
            textAlign: "right !important"
            // color:"green !important"
        }
    },

    DynamicList5: {
        [theme.breakpoints.down("920")]: {
            textAlign: "left !important"
            // color:"red !important"
        }
    },
    transactionListDesign: {
        width: "90%", maxHeight: 318, overflowY: 'auto', marginBottom: 21, margin: "auto", paddingTop: "10px", paddingBottom: 10
    }
});
class Aif extends Component {
    constructor() {
        super();
        this.myDiv = React.createRef();

        this.state = {

            list: [],
            headerList: [],
            openListPopup: false,
            popupHeaderList: [],
            listingPopup: [],
            listingBackupPopup: [],
            deletePopupLoader: false,
            openDeletePopup: false,
            openAddPopup: false,
            header: "",
            grid: "",
            popupGrid: "",
            enableDelete: false,
            enableEdit: false,
            enableIcon: false,
            count: 7,
            popupList: [],
            flag: 'asset',
            id: 0,
            popupTransactionList: [],
            transactionID: 0,
            transactionFlag: "",
            selectedTransactionList: [],
            editList: [],
            transactionLoader: false,
            deletePopupText: '',
            listData: [],
            selected: false,
            loader: false,
            openAddCommitmentPopup: false,
            openCommitmentListPopup: false,
            commitmentLoader: false,
            listingCommitmentPopup: [],
            listingCommitmentBackupPopup: [], message: ""
        }

    }
    componentDidMount() {
        document.title = window.globalConfig.companyDetails.companyName + " - AIF/PMS"
        scrollToTopPage()
        const { classes } = this.props
        this.setState({
            grid: classes.assetGrid, enableDelete: false,
            enableEdit: false,
            count: 6,
            flag: "AIF",
            selected: false,
            list: this.props.aifList,
            popupList: [{ "name": "Add Transaction", "onClick": this.handleAddTransaction }, { "name": "Delete Asset", "onClick": this.handleDeleteAsset }, { "name": "Update Current Value", 'onClick': this.handleAddEvaluation }, { "name": "Add Committed Instalments", 'onClick': this.handleAddCommittedInstalments }],
            enableIcon: true,
            headerList: [{
                label: "Scheme Name",
                align: 'left'
            }, { label: "AMC Ref No", align: 'left' }, { label: "AIF Category", align: 'left' }, { label: "First Investment Date", align: 'left' }, { label: "Current Value", align: 'right' }, { label: "Total Invested Amount", align: 'right' }]
        })
        if (this.props.location.state != undefined) {

            this.props.getAIFandPMSDetails(0, this.props.logindetails.ClientID, this.props.productList.length == 0 ? 0 : this.props.productList.filter((item) => item.TEXT == this.props.location.state.data)[0].VALUE, 0)
            this.setState({ loader: this.props.location.state.data == "AIF" ? this.props.loader : this.props.pmsLoader, flag: this.props.location.state.data, selected: this.props.location.state.data == "AIF" ? false : true })
        } else {
            this.props.getAIFandPMSDetails(0, this.props.logindetails.ClientID, this.props.productList.length == 0 ? 0 : this.props.productList.filter((item) => item.TEXT == "AIF")[0].VALUE, 0)
            this.setState({ loader: this.props.loader, flag: "AIF", selected: false })

        }
    }
    handleAddCommittedInstalments = (item) => {
        this.setState({ id: item.ID.name, openAddCommitmentPopup: true, })

    }
    handleCommitmentClose = () => {
        this.setState({ openAddCommitmentPopup: false })
    }
    handleAddEvaluation = (item) => {
        this.setState({ flag: "Valuation", header: "Update Current Value", transactionFlag: "update", openAddPopup: true, id: item.ID.name })

    }
    handleAddTransaction = (item) => {
        // console.log(item, this.state.popupTransactionList, this.state.listingPopup)
        this.setState({ flag: "Transaction", editList: [{ "transactionDate": "", "transactionType": "", "amount": '', "IsInvested": this.state.listingPopup == "NA" ? 0 : item.IsInvested?.name }], transactionFlag: "Add", header: "Add Transaction", id: item.ID.name, openAddPopup: true, })
    }
    handleDeleteAsset = (item) => {
        this.setState({ openDeletePopup: true, id: item.ID.name, deletePopupText: labels.PmsAndAif.confirmationText, transactionFlag: "AssetList" })

    }
    handleYesClick = () => {

        let flag = !this.state.selected ? "AIF" : "PMS"
        if (this.state.transactionFlag == "AssetList") {
            //AIF and PMS full list Delete
            this.setState({ deletePopupLoader: true })
            let data = {}
            data.id = this.state.id
            data.clientID = this.props.logindetails.ClientID
            deleteAIFList(data).then((response) => {
                this.setState({ deletePopupLoader: false })
                if (response.status == "S") {
                    this.props.deleteListing(this.state.id, !this.state.selected ? "AIF" : "PMS")
                    this.setState({ openDeletePopup: false, openSnackBar: true, message: response.message, severity: "success" })
                    this.props.getAIFandPMSDetails(0, this.props.logindetails.ClientID, this.props.productList.length == 0 ? 0 : this.props.productList.filter((item) => item.TEXT == flag)[0].VALUE, 0)

                } else {
                    this.setState({ openDeletePopup: false, openSnackBar: true, message: response.message, severity: "warning" })

                }

            }).catch((err) => {
                this.setState({ deletePopupLoader: false })
                this.setState({ openDeletePopup: false, openSnackBar: true, message: response.message, severity: "warning" })
            })
        } else {
            //Transaction and commitment  list Delete
            let data = {}
            data.id = this.state.transactionID
            data.clientID = this.props.logindetails.ClientID,
                data.flag = this.state.transactionFlag == "deleteTransaction" ? 3 : 5
            this.setState({ deletePopupLoader: true })
            getAddUpdateDeleteAssetTransaction(data).then((response) => {
                this.setState({ deletePopupLoader: false })
                if (response.status === "S") {
                    if (this.state.transactionFlag === "deleteTransaction") {
                        let fullArray = this.state.listingBackupPopup.filter((item) => item.ID != this.state.transactionID)
                        let arrangeArray = fullArray != null ?
                            fullArray.length != 0 ? fullArray.map((item, index) => {
                                return {
                                    "Date": { name: item.TransactionDate != "" ? moment(item.TransactionDate).format("DD MMM YYYY") : "NA", subHeader1: "NA", subHeader2: '', type: "", label: "Date" },
                                    "TransactionType": { name: item.TransactionValue, subHeader1: "NA", subHeader2: "", type: "", label: "Transaction Type" },
                                    "Amount": { name: item.TransactionAmount, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Amount" },
                                    "ID": { name: item.ID, subHeader1: 'NA', subHeader2: '', type: "" },
                                    "TransactionTypeID": { name: item.TransactionType != "" ? item.TransactionType : "NA", subHeader1: "NA", subHeader2: "", type: "" },

                                }

                            }) : [] : []
                        this.setState({ listingPopup: replaceNull(arrangeArray, "NA"), listingBackupPopup: fullArray })

                    } else {
                        let fullInstalmentArray = this.state.listingCommitmentBackupPopup.filter((item) => item.ID != this.state.transactionID)
                        this.setState({
                            listingCommitmentBackupPopup:fullInstalmentArray
                        })
                        let arrangeInstalmentArray = fullInstalmentArray != null ?
                            fullInstalmentArray.length != 0 ? fullInstalmentArray.map((item, index) => {
                                return {
                                    "InstalmentDate": { name: item.Date != "" ? moment(item.Date).format("DD MMM YYYY") : "NA", subHeader1: "NA", subHeader2: '', type: "", label: "Date" },
                                    "InstalmentAmount": { name: item.Amount, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Amount" },
                                    "ID": { name: item.ID, subHeader1: 'NA', subHeader2: '', type: "" },

                                }

                            }) : [] : []
                        this.setState({ listingCommitmentPopup: replaceNull(arrangeInstalmentArray), listingBackupPopup: fullInstalmentArray })

                    }
                    this.setState({ openDeletePopup: false, openSnackBar: true, message: response.message, severity: "success" })

                    this.props.getAIFandPMSDetails(0, this.props.logindetails.ClientID, this.props.productList.length == 0 ? 0 : this.props.productList.filter((item) => item.TEXT == flag)[0].VALUE, 0)

                } else {

                    this.setState({ openDeletePopup: false, openSnackBar: true, message: response.message, severity: "warning" })

                }
                // this.props.Listing(response?.table1, !this.state.selected?"AIF":"PMS")
            }).catch((err) => {
                this.setState({ deletePopupLoader: false })
                this.setState({ openDeletePopup: false, openSnackBar: true, message: labels.messages.apierror, severity: "warning" })

            })
        }

    }
    handlePopupClose = () => {
        this.setState({ openDeletePopup: false })
    }
    handleTransactionClose = () => {
        this.setState({ openAddPopup: false })

    }
    editCallBack = (data, type) => {
        const elementsIndex = this.state.listingBackupPopup.findIndex(
            (item) => item.ID === data.id
        );
        let transactionList = [...this.state.listingBackupPopup];
        transactionList[elementsIndex] = {
            ...transactionList[elementsIndex],
            TransactionDate: data.transactionDate,
            TransactionType: data.transactionType,
            TransactionAmount: Number(data.transactionAmountValue),
            ID: data.id,
        };
        let arrangeUpdatedArray = transactionList != null ?
            transactionList.length != 0 ? transactionList.map((item, index) => {

                return {
                    "Date": { name: item.TransactionDate != "" ? moment(item.TransactionDate).format("DD MMM YYYY") : "NA", subHeader1: "NA", subHeader2: '', type: "", label: "Date" },
                    "TransactionType": { name: item.TransactionValue, subHeader1: "NA", subHeader2: "", type: "", label: "Transaction Type" },
                    "Amount": { name: item.TransactionAmount, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Amount" },
                    "ID": { name: item.ID, subHeader1: 'NA', subHeader2: '', type: "" },
                    "TransactionTypeID": { name: item.TransactionType != "" ? item.TransactionType : "NA", subHeader1: 'NA', subHeader2: '', type: "" },
                }
            })
                : [] : []
        this.setState({ listingPopup: arrangeUpdatedArray })
    }
    handleEdit = (item) => {
        this.setState({ openAddPopup: true })
        this.setState({ transactionFlag: "update", header: "Update Transaction", flag: "Transaction", transactionID: item.ID.name, editList: [{ "transactionDate": item.Date.name, "transactionType": item.TransactionType.name, "amount": item.Amount.name, "IsInvested": this.state.listData.IsInvested.name, }], id: this.state.listData.ID.name })

    }
    handleDelete = (item) => {
        this.setState({ transactionFlag: "deleteTransaction", deletePopupText: 'Are you sure want to delete this transaction ?', openDeletePopup: true, transactionID: item.ID.name })

    }
    transactionListClick = (item) => {
        this.setState({ listData: item, })
        let flag = this.state.selected ? "AIF" : "PMS"
        const { classes } = this.props
        if (item.SchemeName.subHeader1.includes("Add Transaction")) {
            this.handleAddTransaction(item)
        }
        else {
            this.setState({ transactionLoader: true })
            getAIFandPMSList(item.ID.name, this.props.logindetails.ClientID, this.props.productList.length == 0 ? 0 : this.props.productList.filter((item) => item.TEXT == !this.state.selected ? "AIF" : "PMS")[0].VALUE, 0).then((response) => {
                this.setState({ transactionLoader: false })
                if (response.status == "S") {
                    let removeNull = replaceNull(response.table1, "NA")
                    this.setState({ listingBackupPopup: removeNull })
                    let arrangeArray = removeNull != null ?
                        removeNull.length != 0 ? removeNull.map((item, index) => {
                            return {
                                "Date": { name: item.TransactionDate != "" ? moment(item.TransactionDate).format("DD MMM YYYY") : "NA", subHeader1: "NA", subHeader2: '', type: "", label: "Date" },
                                "TransactionType": { name: item.TransactionValue, subHeader1: "NA", subHeader2: "", type: "", label: "Transaction Type" },
                                "Amount": { name: item.TransactionAmount, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Amount" },
                                "ID": { name: item.ID, subHeader1: 'NA', subHeader2: '', type: "" },
                                "TransactionTypeID": { name: item.TransactionType != "" ? item.TransactionType : "NA", subHeader1: "NA", subHeader2: "", type: "" },

                            }

                        }) : [] : []
                    this.setState({ listingPopup: replaceNull(arrangeArray) })
                } else {
                }
            }).catch((err) => {
                this.setState({ transactionLoader: false })
            })
            this.setState({
                popupListingCount: 3, header: "Transactions", enablePopupIcon: true, openListPopup: true
            })
            this.setState({ popupGrid: classes.grid, popupTransactionList: [{ "name": "Edit", "onClick": this.handleEdit }, { "name": "Delete", "onClick": this.handleDelete },], popupHeaderList: [{ label: "Transaction Date", align: 'left' }, { label: "Transaction Type", align: 'left' }, { label: "Transaction Amount", align: 'right' }] })

        }
    }
    commitmentListClick = (item) => {
        this.setState({ listData: item, })
        let flag = this.state.selected ? "AIF" : "PMS"
        const { classes } = this.props
        if (item.SchemeName.subHeader2.includes("Add Instalment")) {
            this.handleAddCommittedInstalments(item)
        }
        else {
            this.setState({ commitmentLoader: true })
            getAIFandPMSList(item.ID.name, this.props.logindetails.ClientID, this.props.productList.length == 0 ? 0 : this.props.productList.filter((item) => item.TEXT == !this.state.selected ? "AIF" : "PMS")[0].VALUE, 0).then((response) => {
                this.setState({ commitmentLoader: false })
                if (response.status == "S") {
                    let removeNull = replaceNull(response.table2)
                    this.setState({ listingCommitmentBackupPopup: removeNull })
                    let arrangeArray = removeNull != null ?
                        removeNull.length != 0 ? removeNull.map((item, index) => {
                            return {
                                "InstalmentDate": { name: item.Date != "" ? moment(item.Date).format("DD MMM YYYY") : "NA", subHeader1: "NA", subHeader2: '', type: "", label: "Date" },
                                "InstalmentAmount": { name: item.Amount, subHeader1: 'NA', subHeader2: '', type: "amount", label: "Amount" },
                                "ID": { name: item.ID, subHeader1: 'NA', subHeader2: '', type: "" },
                                // "TransactionTypeID": { name: item.TransactionType != "" ? item.TransactionType : "NA", subHeader1: "NA", subHeader2: "", type: "" },

                            }

                        }) : [] : []
                    this.setState({ listingCommitmentPopup: replaceNull(arrangeArray) })
                } else {
                }
            }).catch((err) => {
                this.setState({ commitmentLoader: false })
            })
            this.setState({
                enablePopupIcon: true, openCommitmentListPopup: true
            })
            this.setState({ popupGrid: classes.grid, popupTransactionList: [{ "name": "Edit", "onClick": this.handleEdit }, { "name": "Delete", "onClick": this.handleDelete },], popupHeaderList: [{ label: "Transaction Date", align: 'left' }, { label: "Transaction Type", align: 'left' }, { label: "Transaction Amount", align: 'right' }] })

        }
    }
    handleListPopupClose = () => {
        this.setState({ openListPopup: false })
    }
    handleToastClose = () => {
        this.setState({ openSnackBar: false })
    }
    toggleChange = () => {
        let flag = this.state.selected ? "AIF" : "PMS"

        this.props.getAIFandPMSDetails(0, this.props.logindetails.ClientID, this.props.productList.length == 0 ? 0 : this.props.productList.filter((item) => item.TEXT == flag)[0].VALUE, 0)

        this.setState({
            selected: !this.state.selected, flag: !this.state.selected ? "AIF" : "PMS", headerList: this.state.selected ? [{
                label: "Scheme Name",
                align: 'left'
            }, { label: "AMC Ref No", align: 'left' }, { label: "AIF Category", align: 'left' }, { label: "First Investment Date", align: 'left' }, { label: "Current Value", align: 'right' }, { label: "Total Invested Amount", align: 'right' }] : [{
                label: "Scheme Name",
                align: 'left'
            }, { label: "AMC Ref No", align: 'left' }, { label: "PMS Category", align: 'left' }, { label: "First Investment Date", align: 'left' }, { label: "Current Value", align: 'right' }, { label: "Total Invested Amount", align: 'right' }]
        })

    }
    addAIFandPMS = () => {
        if (this.state.selected) {
            this.props.history.push({
                pathname: labels.Path.AifDataEntry,
                state: { data: "PMS" }
            })
        } else {
            this.props.history.push({
                pathname: labels.Path.AifDataEntry,
                state: { data: "AIF" }
            })

        }
    }
    handleCommitmentDelete = (item) => {
        this.setState({ transactionFlag: "deleteCommitment", deletePopupText: 'Are you sure want to delete this Instalment ?', openDeletePopup: true, transactionID: item.ID.name })

    }
    handleCommitmentListPopupClose = () => {
        this.setState({ openCommitmentListPopup: false })
    }

    render() {
        const { classes } = this.props;
        try {
            return (
                <ErrorBoundary>
                    <Subheader heading={labels.productList.Pms} description={labels.Transact.aifContent} />
                    <div className={classes.flex}>
                        <div className={classes.sideSpace}></div>
                        <div className={classes.mainCard}>
                            <div className={classes.buttonAlignment}>
                                <div style={{ textAlignLast: "left", marginLeft: 10, marginTop: 10, marginBottom: 10 }}>
                                    <OutlinedButton text={!this.state.selected ? "Add AIF" : 'Add PMS'} onClick={this.addAIFandPMS} />
                                </div>
                                <div className={classes.marginTopAlignment}>
                                    <Toggle
                                        label1={"AIF"}
                                        label2={"PMS"}
                                        selected={this.state.selected}
                                        toggleSelected={this.toggleChange}
                                    />
                                </div>

                            </div>
                            <DynamicList style={classes} loader={!this.state.selected ? this.props.loader : this.props.pmsLoader} instalmentListClick={this.commitmentListClick} transactionListClick={this.transactionListClick} list={!this.state.selected ? this.props.aifList : this.props.pmsList} grid={this.state.grid} header={this.state.headerList} count={this.state.count} enableIcon={this.state.enableIcon} popupList={this.state.popupList}></DynamicList>
                        </div>
                        <div className={classes.sideSpace}></div>
                    </div>
                    <CommonSnackbar message={this.state.message} open={this.state.openSnackBar} severity={this.state.severity} handlesnanckClose={this.handleToastClose} />
                    <DeletePopup header={"Confirmation"} onClick={this.handleYesClick} loader={this.state.deletePopupLoader} open={this.state.openDeletePopup} text={this.state.deletePopupText} onClose={this.handlePopupClose} />

                    <Addtransactionandevaluation page={!this.state.selected ? "AIF" : "PMS"} editCallBack={this.editCallBack} editList={this.state.editList} transactionFlag={this.state.transactionFlag}
                        id={this.state.transactionID} listID={this.state.id} header={this.state.header} flag={this.state.flag}
                        loader={this.state.loader} addMoreLoader={this.state.addmoreloader} open={this.state.openAddPopup} onClose={this.handleTransactionClose} >
                    </Addtransactionandevaluation>

                    <Addtransactionandevaluation page={!this.state.selected ? "AIF" : "PMS"} transactionFlag={'Add'}
                        id={this.state.transactionID} listID={this.state.id} header={'Add Commitment Instalments'} flag={'Commitment'}
                        open={this.state.openAddCommitmentPopup} onClose={this.handleCommitmentClose} >
                    </Addtransactionandevaluation>

                    <DeletePopup showdefault={true} className={classes.dialogDesign} header={"Transactions"} open={this.state.openListPopup} onClose={this.handleListPopupClose} >
                        <div style={{ textAlignLast: "right", marginLeft: 10, marginTop: 10, marginBottom: 10, marginRight: 26 }}>
                            <OutlinedButton text={"Add Transaction"} onClick={() => {
                                this.handleAddTransaction(this.state.listData)
                                this.setState({ openListPopup: false })
                            }} />
                        </div>
                        <div className={classes.transactionListDesign}>
                            <DynamicList loader={this.state.transactionLoader} list={this.state.listingPopup} grid={this.state.popupGrid} header={this.state.popupHeaderList} count={this.state.popupListingCount} enableIcon={this.state.enablePopupIcon} popupList={this.state.popupTransactionList}></DynamicList>
                        </div>
                    </DeletePopup>

                    <DeletePopup showdefault={true} className={classes.dialogDesign} header={"Commitment"} open={this.state.openCommitmentListPopup} onClose={this.handleCommitmentListPopupClose} >
                        <div style={{ textAlignLast: "right", marginLeft: 10, marginTop: 10, marginBottom: 10, marginRight: 26 }}>
                            <OutlinedButton text={"Add Instalment"} onClick={() => {
                                this.handleAddCommittedInstalments(this.state.listData)
                                this.setState({ openCommitmentListPopup: false })
                            }} />
                        </div>
                        <div className={classes.transactionListDesign}>
                            <DynamicList loader={this.state.commitmentLoader} list={this.state.listingCommitmentPopup} grid={classes.commitmentGrid} header={[{ label: "Commitment Instalment Date", align: 'left' }, { label: "Commitment Instalment Amount", align: 'right' }]} count={2} enableIcon={true} popupList={[{ "name": "Delete", "onClick": this.handleCommitmentDelete },]}></DynamicList>
                        </div>
                    </DeletePopup>

                </ErrorBoundary>
            )

        } catch (err) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => {
    //
    return {
        sessionID: state.Login.sessionID,
        logindetails: state.Login.logindetails,
        aifList: state.AIFandPMS.aifList,
        pmsList: state.AIFandPMS.pmsList,
        loader: state.AIFandPMS.isAIFLoading,
        pmsLoader: state.AIFandPMS.isPMSLoading,
        productList: state.Dataentry.alternativeAsset != undefined && state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset.table12 != "NA" && state.Dataentry.alternativeAsset.table12.length != 0 ?
            state.Dataentry.alternativeAsset?.table12 : [],
        brokerList: state.Dataentry.transactionTypeDropDown != null && state.Dataentry.transactionTypeDropDown.length != 0 && state.Dataentry.transactionTypeDropDown.table7 != null && state.Dataentry.transactionTypeDropDown.table7.length != 0 ? state.Dataentry.transactionTypeDropDown.table7.map((item) => {
            return { "label": item.BrokerName, "value": item.ID }
        }) : [],
        assetNameList: state.Dataentry.transactionTypeDropDown != null && state.Dataentry.transactionTypeDropDown.length != 0 && state.Dataentry.transactionTypeDropDown.table10 != null && state.Dataentry.transactionTypeDropDown.table10.length != 0 ? state.Dataentry.transactionTypeDropDown.table10.map((item) => {
            return { "label": item.AssetName, "value": item.ID }
        }) : [],
        assetClassList: state.Dataentry.transactionTypeDropDown != null && state.Dataentry.transactionTypeDropDown.length != 0 && state.Dataentry.transactionTypeDropDown.table9 != null && state.Dataentry.transactionTypeDropDown.table9.length != 0 ? state.Dataentry.transactionTypeDropDown.table9.map((item) => {
            return { "label": item.EnumCode, "value": item.EnumValue }
        }) : [],
        aifCategoryList: state.Dataentry.transactionTypeDropDown != null && state.Dataentry.transactionTypeDropDown.length != 0 && state.Dataentry.transactionTypeDropDown.table8 != null && state.Dataentry.transactionTypeDropDown.table8.length != 0 ? state.Dataentry.transactionTypeDropDown.table8.map((item) => {
            return { "label": item.SubAssetClassName, "value": item.ID }
        }) : [],
        transactionTypeList: state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset.length != 0 && state.Dataentry.alternativeAsset.table6 != "NA" && state.Dataentry.alternativeAsset.table6.length != 0 ? state.Dataentry.alternativeAsset.table6.map((item) => {
            return { "label": item.TransactionType, "value": item.ID }
        }) : [],


    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getL5reportDetails, deleteListing, Listing, getAIFandPMSDetails }, dispatch)
}
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(Aif))
