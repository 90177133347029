import * as types from '../../constants/actionsType'
const initialState = {
    EquityImporteddata:""
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
   
    case types.TRACK_EQUITY_IMPORT_DETAILS:
    
      return {
        ...state,

        EquityImporteddata: action.payload != null ? action.payload : state.EquityImporteddata,
      }
    case types.EMPTY_TRACK_EQUITY_IMPORT_DETAILS:

      return {
        ...state,
        EquityImporteddata:""
      }

    default:
      return state
  }
}
export default reducer