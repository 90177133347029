import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import Menu from "../../components/menu/menu";
import { amountCommaSeperator, numbersandDeciamlOnly, replaceNull, scrollToTopPage } from '../../utils/commonfunction';
import Usercard from "../../components/profile/usercard";
// import Footer from '../../components/footer/footer';
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import { withRouter } from "react-router";
import SWPDetails from "./swpdetails";
import SWPReview from "./swpreview";
import Common from "../../components/commonpaymentconfirm/common";
import { AmountValidation, NegativeFinding, ordinal } from "../../utils/commonfunction";
import { installmentValidation } from "../../utils/commonfunction";
import labels from "../../utils/constants/labels";
import { Redirect } from "react-router";
import getSWPApidetails from "../../api/swp/swpapi";
import constants from "../../utils/constants/apiconstant";
import moment from "moment";
import getPortfolioxraySchemeDetails from "../../api/portfolioxrayschemedetail/portfolioxrayschemedetail";
import color from "../../utils/colors/colors";
import CommonSnackbar from "../../components/snackbar/snackbar";
import {
  SchemeChange,
  TypeChange,
} from "../../redux/actions/filter/reportsfilter";
import { bindActionCreators } from "redux";
import ErrorBoundary from "../../components/Errorhandling/Errorhandle";
import colors from "../../utils/colors/colors";
import Deletepopup from "../../components/popup/deletepopup";
import getAllDeviceToken from "../../api/alert/getalldevicetoken";
import getSendPushNotification from "../../api/alert/sendpushnotification";
const styles = (theme) => ({
  collabel: {
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: "14px",
    textAlign: "left",
  },
  swpHeader: {
    height: 70,
    paddingLeft: "5.4%",
    // paddingTop: "5%",
    paddingRight: "6.5%",
    backgroundColor: window.globalConfig.color.PRIMARY,
    color: window.globalConfig.color.red,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",

    [theme.breakpoints.between("600", "851")]: {
      height: 60,
      paddingTop: "12%",
    },
  },
  swpText: {
    textAlign: "left",
    fontSize: 25,
    fontWeight: "bolder",
    color: window.globalConfig.color.WHITE,
    // marginTop: "1.6%",
    marginLeft: "6%",
  },
  btnApply: {
    background: window.globalConfig.color.brown,
    marginLeft: "7px",
    padding: 0,
    lineHeight: "36px",
    color: window.globalConfig.color.WHITE,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    width: "100px",
    float: "right",
    borderRadius: "5px",
    border: "1px solid transparent",
    "&:hover": {
      background: window.globalConfig.color.lightBlue,
      color: window.globalConfig.color.WHITE,
    },
  },
  btnBack: {
    background: window.globalConfig.color.RosyBrown,
    marginLeft: "7px",
    padding: 0,
    lineHeight: "36px",
    color: "black",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    width: "100px",
    float: "right",
    borderRadius: "5px",
    border: "1px solid transparent",
  },
  divMain: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    margin: "9%",
    marginTop: "3%",
    paddingBottom: "3%",
    [theme.breakpoints.down(1037)]: {
      flexDirection: "column"
    },
  },
  divDetail: {
    height: '-webkit-fill-available',
    width: '-webkit-fill-available',
    // width: "63%",
    backgroundColor: window.globalConfig.color.WHITE,
    marginLeft: "2.5%",
    padding: 10,
    marginBottom: "4%",
    border: `1px solid ${window.globalConfig.color.WHITE}`,
    boxShadow: "0 0 5px 8px rgba(200, 200, 250, 0.1)",
    paddingBottom: "30px",
    borderRadius: 6,
    // [theme.breakpoints.down(920)]: {
    //   width: "fit-content",
    // },
    [theme.breakpoints.down(600)]: {
      // width: "94%",
      margin: "auto",
      padding: 10
    },
  },
  divDetail1: {
    height: "-webkit-fill-available",
    width: "92%",
    backgroundColor: window.globalConfig.color.WHITE,
    border: `1px solid ${window.globalConfig.color.WHITE}`,
    boxShadow: "0 0 5px 8px rgba(200, 200, 250, 0.1)",
    paddingBottom: "30px",
    borderRadius: 6,
  },

  //Added by Celsia on 5 Jun 2021 for Mobile Responsive Started
  desktopResponsive: {
    display: "block !important",
    [theme.breakpoints.down("600")]: {
      display: "none !important",
    },
  },
  swpHeaderResponse: {
    height: 86,
    marginTop: "4.5%",
    paddingLeft: "3.4%",
    paddingTop: "2%",
    backgroundColor: window.globalConfig.color.PRIMARY,
    color: window.globalConfig.color.red,
    display: "flex",
    flexDirection: "column",
  },
  swpTextResponse: {
    textAlign: "left",
    fontSize: 17,
    fontWeight: "bolder",
    color: window.globalConfig.color.WHITE,
    marginTop: "10.6%",
  },
  mobileResponsive: {
    display: "none !important",
    [theme.breakpoints.down("600")]: {
      display: "block!important",
    },
  },
  divMainResponsive: {
    marginTop: "3%",
    marginLeft: "3%",
    marginRight: "3%",
  },
  divDetailResponsive: {
    height: "-webkit-fill-available",
    width: "100%",
    backgroundColor: window.globalConfig.color.WHITE,
    marginTop: "3%",
    marginBottom: "11%",
    border: `1px solid ${window.globalConfig.color.BORDER}`,
    boxShadow: "0 0 10px 0 rgb(19 89 161 / 17%)",
    paddingBottom: "30px",
  },
  divDetail1Responsive: {
    height: "-webkit-fill-available",
    width: "100%",
    backgroundColor: window.globalConfig.color.WHITE,
    border: `1px solid ${window.globalConfig.color.BORDER}`,
    boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.3)",
    paddingBottom: "30px",
    marginBottom: "2em",
  },

  //Ended
});
class SWP extends React.Component {
  constructor() {
    super();
    this.state = {
      confimFlag: false,
      paymentFlag: false,
      Selectedvalue: "",
      amount: "",
      installment: "",
      errorenable: false,
      erroramountmessage: "",
      errorinstallmentmessage: "",
      Swptype: "Weekly",
      swpdate: "",
      check: false,
      errorswptypemessage: "",
      errorswpdatemessage: "",
      errorSwpStartDateMessage: "",
      statusmessage: "",
      folionumber: "",
      StartDate: "",
      StartDateList: [],
      folionumberList: [],
      schemecode: "",
      loader: false,
      Selecteddate: moment().format("DD MMM YYYY"),
      selectedDataTable: [],
      schemecodeerror: "",
      open: false,
      orderID: "",
      status: "",
      BSESchemeType: "",
      totalTable: [],
      UCC: "NA",
      data: {},
      SWPAllowed: true,
      value: "",
      minimumInstalment: 1
    };
  }
  async componentDidMount() {
    await this.findFirstScheme(this.props.location.state.data.SchemeID)

    if (this.props.bankdetails.filter((items, index) => items.UCC == this.state.UCC && items.IsDefault).length == 0) {
      this.setState({ value: this.props.bankdetails.filter((items, index) => items.UCC == this.state.UCC)[0]?.BankAccountNumber })

    } else {
      this.setState({ value: this.props.bankdetails.filter((items, index) => items.UCC == this.state.UCC && items.IsDefault)[0]?.BankAccountNumber })

    }
    this.setState({
      Selectedvalue: this.props.location.state.data.SchemeName,
      data: this.props.location.state.data,
      BSESchemeType: this.props.location.state.data.BSESchemeType,
      AMCID: this.props.location.state.data.AMCID,
      UCC: this.state.UCC,
    });
    scrollToTopPage();
  }
  findFirstScheme = (SchemeID) => {

    getPortfolioxraySchemeDetails(
      this.props.logindetails.ClientID,
      0,
      SchemeID,
      false,
      "SWP"
    ).then((item) => {
      if (item.status == "S") {
        var a = [];
        var b = "";
        if (item.dataTable != undefined && item.dataTable != null) {
          if (item.dataTable.length != 0) {
            var a = item.dataTable[0].DatesAllowed.split(",").map(
              (values, index) => {
                return {
                  label: ordinal(Number(values)),
                  value: Number(values),
                };
              }
            );
            let selectedMappingDetails = item.dataTable
            b = selectedMappingDetails[0].SchemeCode;
            this.setState({ selectedDataTable: item.dataTable, schemecode: b, minimumInstalment: selectedMappingDetails?.MINIMUM_INSTALLMENT_NUMBERS });
          }
        } else {
          this.setState({ selectedDataTable: [], UCC: "" })
        }
        this.setState({ StartDateList: a, totalTable: item.totalTable });
        if (item.folioNumber.length != 0) {
          this.setState({
            folionumber: item.folioNumber[0].FolioNo,
            UCC: item.folioNumber[0].MutualFundUCC,
            folionumberList: item.folioNumber,
          });
        } else {
          this.setState({ UCC: "" })
        }
      } else {
        this.setState({ selectedDataTable: [], UCC: "" })
      }
    });
  }
  onPlaceorder = (value) => {
    var val = this.state.amount.replaceAll(",", "");
    var currancyvalue = val.replaceAll(labels.currency.rupeesymbol, "");
    var isValid = true;
    // 
    if (this.state.Swptype == "") {
      var isValid = false;
      this.setState({ errorswptypemessage: labels.messages.required });
    }
    if (this.state.swpdate == "Select date" || this.state.swpdate == "") {
      isValid = false;
      this.setState({ errorswpdatemessage: labels.messages.required });
    }
    if (currancyvalue < 1000) {
      isValid = false;
      this.setState({
        erroramountmessage: `Minimum Amount ${labels.currency.rupeesymbol} 1000`,
      });
    } else {
      if (this.state.totalTable.length != 0) {
        let targetCurrentValue =
          this.state.totalTable.length != 0 &&
            this.state.totalTable.filter(
              (item) => item.FolioNo == this.state.folionumber
            ).length != 0
            ? this.state.totalTable.filter(
              (item) => item.FolioNo == this.state.folionumber
            )[0].TotalAvailableAmount
            : 0;

        if (Number(currancyvalue) > targetCurrentValue) {
          isValid = false;
          this.setState({
            erroramountmessage: `Amount should be  less than ${NegativeFinding(targetCurrentValue, "", "", "", "rupee")}`,
          });
        }
      } else {
        isValid = false;
        this.setState({
          open: true,
          schemecodeerror: labels.messages.apierror,
        });
      }
    }
    if (Number(this.state.installment) < Number(this.state.minimumInstalment || 1)) {
      isValid = false;
      this.setState({ errorinstallmentmessage: `Minimum instalments ${this.state.minimumInstalment || 1}` });
    } else {
      var a = Number(this.state.installment) * Number(currancyvalue);
      if (this.state.totalTable.length != 0) {
        let targetCurrentValue =
          this.state.totalTable.length != 0 &&
            this.state.totalTable.filter(
              (item) => item.FolioNo == this.state.folionumber
            ).length != 0
            ? this.state.totalTable.filter(
              (item) => item.FolioNo == this.state.folionumber
            )[0].TotalAvailableAmount
            : 0;

        if (a > targetCurrentValue) {
          isValid = false;
          this.setState({
            errorinstallmentmessage: `Amount should be  less than ${NegativeFinding(targetCurrentValue, "", "", "", "rupee")}`,
          });
        }
      } else {
        isValid = true;

        this.setState({
          open: true,
          schemecodeerror: labels.messages.apierror,
        });
      }
    }
    if (isValid) {
      //
      if (this.props.bankdetails.filter((items, index) => items.UCC == this.state.UCC && items.IsDefault).length == 0) {
        this.setState({ value: this.props.bankdetails.filter((items, index) => items.UCC == this.state.UCC)[0]?.BankAccountNumber })
      } else {
        this.setState({ value: this.props.bankdetails.filter((items, index) => items.UCC == this.state.UCC && items.IsDefault)[0]?.BankAccountNumber })
      }
      // this was comment by Srinath,  because its taking to next page with out validating below
      // this.setState({
      //   confimFlag: value
      // })
      if (this.state.selectedDataTable.length > 0) {
        let selectedMappingDetails = this.state.selectedDataTable
        if (selectedMappingDetails.length > 0) {
          this.setState({
            schemecode: selectedMappingDetails[0].SchemeCode,
            confimFlag: value,
          });
        } else {
          this.setState({
            schemecodeerror: labels.investmentPopup.swpNotAllowed,
            open: true,
          });
        }
      } else {
        this.setState({
          schemecodeerror: labels.investmentPopup.swpNotAllowed,
          open: true,
          schemecode: "",

        });
      }
    }
  };
  onClickPayment = () => {
    this.setState({ loader: true, openConfirmPopup: false });
    //
    var val = this.state.amount.replaceAll(",", "");
    var currancyvalue = val.replaceAll(labels.currency.rupeesymbol, "");

    getSWPApidetails(
      this.state.UCC,
      this.state.schemecode,
      constants.SWP.TransactionMode,
      this.state.folionumber,
      moment(this.state.Selecteddate).format("DD/MM/YYYY"),
      constants.SWP.NoOfWithdrawls,
      this.state.Swptype,
      Number(currancyvalue),
      this.state.installment != "" ? Number(this.state.installment) : 0,
      this.state.check ? "Y" : "N",
      this.props.logindetails.SubBrokerCode,
      this.state.BSESchemeType === 1 ? "N" : constants.SWP.EUINDeclaration,
      this.state.BSESchemeType === 1 ? "" : constants.Common.EUINNumber,
      constants.SWP.Remarks,
      this.props.logindetails.ClientID,
      this.state.data.SchemeID == undefined
        ? this.state.data.ID
        : this.state.data.SchemeID,
      constants.Common.Source
    ).then(async (res) => {
      //
      this.setState({ paymentFlag: true, })
      if (res.status == "S") {
        this.setState({

          statusmessage: res.message,
          orderID: res.orderID,
          status: res.status,
        });
      } else {
        this.setState({

          statusmessage: res.message,
          status: res.status,
          open: true,
          schemecodeerror: res.message,
        });
      }
      if (this.props.logindetails.ClientID == 20427 || this.props.logindetails.ClientID == 21827) {
        // push notification on mobile.. commited by dulcy
        let data = {}
        data.ClientID = this.props.logindetails.ClientID
        await getAllDeviceToken(data).then(async (response) => {
          if (response.status == "S") {
            if (replaceNull(response.table1, "NA") != "NA") {

              let params = {}
              params.DeviceTokens = response.table1.map((item) => {
                return item.DeviceToken
              })
              params.Title = res.status == "S" ? "Trading completed successfully" : "Trading failed"
              params.Body = replaceNull(res.message, "NA")
              await getSendPushNotification(params).then((response) => {

              })
            }

          }
        })
      }
      this.setState({ loader: false });
    })
      .catch((err) => {
        this.setState({ paymentFlag: true, })
        this.setState({ loader: false });
      });
  };
  amountChange = (event) => {
    var val = event.target.value.replaceAll(",", "");
    var currancyvalue = val.replaceAll(labels.currency.rupeesymbol, "").trim();
    if (currancyvalue.trim() == "") {
      this.setState({ amount: "" });
    } else {
      // console.log(currancyvalue)
      if (!numbersandDeciamlOnly(currancyvalue.trim(), 16)) {
        event.preventDefault();
      } else {

        this.setState({ erroramountmessage: "", amount: `${labels.currency.rupeesymbol} ${amountCommaSeperator(currancyvalue)}` });
      }
    }
  };
  installmentchange = (event) => {
    if (installmentValidation(event.target.value)) {
      this.setState({ errorenable: false, errorinstallmentmessage: "" });

      this.setState({ installment: event.target.value });
    } else {
      event.preventDefault();
    }
  };
  onChangeSWP = (event) => {
    //this.props.TypeChange(event, labels.SWPtype)
    this.setState({ Swptype: event[0].label, errorswptypemessage: "" });
  };
  swpdatechange = (value) => {
    //
    this.setState({
      //swpdate: moment(value).format("DD MMM YYYY"),
      Selecteddate: moment(value).format("DD MMM YYYY"),
      errorswpdatemessage: "",
    });
    // this.setState({ swpdate: event.label, errorswpdatemessage: "" })
  };
  handleApplyClick = (date) => {
    this.setState({ swpdate: moment(date).format("DD MMM YYYY"), errorswpdatemessage: "" })
  }
  schemechange = (event) => {
    let schemedetail = [];
    schemedetail = replaceNull(this.props.SWPSchemeList, "NA") != "NA" ? this.props.SWPSchemeList.filter(
      (item) => item.SchemeID == event[0].value
    ) : []
    let schemeUCC = schemedetail.length != 0 ? schemedetail[0].UCC : ""
    this.setState({
      BSESchemeType:
        schemedetail.length != 0 ? schemedetail[0].BSESchemeType : "",
      UCC: schemeUCC,
    });
    this.setState({
      data: schemedetail.length != 0
        ? schemedetail[0]
        : ""
    });
    this.findFirstScheme(event[0].value)
    this.setState({
      Selectedvalue: event[0].label,
      errorinstallmentmessage: "",
    });
  };
  bankChange = (event) => {
    this.setState({ value: event.target.value })
  }
  onCheck = () => {

    if (!this.state.check) {
      this.setState({ swpdate: moment().format("DD MMM YYYY"), errorswpdatemessage: "", Selecteddate: moment().format("DD MMM YYYY") })
    } else {
      this.setState({ swpdate: "", errorswpdatemessage: "", Selecteddate: moment().format("DD MMM YYYY") })

    }
    this.setState({ check: !this.state.check });
  };
  handleClickfolio = (val) => {
    this.setState({ folionumber: val.FolioNo, UCC: val.MutualFundUCC });
  };
  handlesnanckClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };

  render() {
    const { classes } = this.props;
    try {
      return (
        <ErrorBoundary>
          <div
            style={{ height: "auto", maxHeight: "auto" }}
          // className={classes.desktopResponsive}
          >
            {/* <Menu childFunc={this.props.childFunc} /> */}
            <div className={classes.swpHeader}>
              <div className={classes.swpText}>{labels.SWP.SWP}</div>
              <div style={{ display: "flex", cursor: this.state.viewloader ? "not-allowed" : "pointer", opacity: this.state.loader ? 0.5 : 1, flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: "5%" }} onClick={() => {
                if (!this.state.loader) {
                  this.props.history.push({
                    pathname: labels.Path.MutualTab,
                    state: { tabValue: 1 }
                  })
                }
              }}>
                <ArrowBackIos
                  color={window.globalConfig.color.WHITE}
                  style={{ color: window.globalConfig.color.WHITE }}
                />
                <p
                  style={{
                    color: window.globalConfig.color.WHITE,

                  }}
                >
                  {"Back"}
                </p>
              </div>
            </div>
            {this.state.paymentFlag && (
              <div className={classes.divMain}>
                <div className={classes.divDetail1}>
                  <Common
                    page="SWP"
                    UCC={this.state.UCC}
                    AccountNumber={this.state.value}
                    message={this.state.statusmessage}
                    orderID={this.state.orderID}
                    status={this.state.status}
                    folionumber={this.state.folionumber}
                    data={this.state.data}
                    Swptype={this.state.Swptype}
                    firstorder={this.state.check}
                    swpdate={this.state.swpdate}
                    amount={this.state.amount}
                    installment={this.state.installment}
                  ></Common>
                </div>
              </div>
            )}
            {!this.state.paymentFlag && (
              <div className={classes.divMain}>
                <Usercard page={"swpDesktop"} />
                <div className={classes.divDetail}>
                  {!this.state.confimFlag && (
                    <div>
                      <SWPDetails
                        UCC={this.state.UCC}
                        data={this.state.data}
                        totalTable={this.state.totalTable}
                        Selecteddate={this.state.Selecteddate}
                        FullschemeList={this.props.FullschemeList}
                        handleClickfolio={this.handleClickfolio}
                        folionumberList={this.state.folionumberList}
                        StartDateList={this.state.StartDateList}
                        errorinstallmentmessage={
                          this.state.errorinstallmentmessage
                        }
                        minimumInstalment={this.state.minimumInstalment}
                        erroramountmessage={this.state.erroramountmessage}
                        errorswpdatemessage={this.state.errorswpdatemessage}
                        errorswptypemessage={this.state.errorswptypemessage}
                        handleApplyClick={this.handleApplyClick}
                        check={this.state.check}
                        onCheck={this.onCheck}
                        swpdate={this.state.swpdate}
                        swpdatechange={this.swpdatechange}
                        Swptype={this.state.Swptype}
                        onChange={this.schemechange}
                        schemeList={this.props.schemeList}
                        SWPonchange={this.onChangeSWP}
                        installmentchange={this.installmentchange}
                        amountChange={this.amountChange}
                        amount={this.state.amount}
                        installment={this.state.installment}
                        showOption={true}
                        placeholder={this.props.location.state.data.Scheme}
                        schemevalue={this.state.Selectedvalue}
                        folionumber={this.state.folionumber}
                        onPlaceorder={(value) => this.onPlaceorder(value)}
                      />
                    </div>
                  )}
                  {this.state.confimFlag && (
                    <div>
                      <SWPReview
                        loader={this.state.loader}
                        schemevalue={this.state.Selectedvalue}
                        folionumber={this.state.folionumber}
                        data={this.state.data}
                        bankChange={this.bankChange}
                        value={this.state.value}
                        UCC={this.state.UCC}
                        Swptype={this.state.Swptype}
                        firstorder={this.state.check}
                        swpdate={this.state.swpdate}
                        amount={this.state.amount}
                        installment={this.state.installment}
                        onPlaceorder={(value) => this.onPlaceorder(value)}
                        confirmPayment={(value) => this.setState({ openConfirmPopup: true })}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            {/* <Footer page='footer' /> */}
          </div>

          <Deletepopup header={"Confirmation"} text={labels.messages.placeOrderMsg} open={this.state.openConfirmPopup} onClose={() => this.setState({ openConfirmPopup: false })} onClick={this.onClickPayment}>

          </Deletepopup>
          <CommonSnackbar
            timer={9000}
            open={this.state.open}
            severity={"warning"}
            message={this.state.schemecodeerror}
            handlesnanckClose={this.handlesnanckClose}
          />
        </ErrorBoundary>
      );
    } catch (err) {
      return <Redirect to={labels.Path.NotFound} />;
    }
  }
}
const mapStateToProps = (state) => {
  //
  return {
    schemeList: state.PortfolioXray.XrayData
      // .filter(
      //   (item) => item.SWPAllowed == true
      // )
      .sort(function (a, b) {
        var textA = a.Scheme.toUpperCase();
        var textB = b.Scheme.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      })
      .filter((item, index, self) => {
        return index === self.findIndex((duplicateIndex) => duplicateIndex.SchemeID == item.SchemeID)
      })
      .map((val) => {
        return { label: val.Scheme, value: val.SchemeID, isChecked: false };
      }),
    SWPSchemeList: state.PortfolioXray.XrayData
      // .filter(
      //   (item) => item.SWPAllowed === true
      // )
      .sort(function (a, b) {
        var textA = a.Scheme.toUpperCase();
        var textB = b.Scheme.toUpperCase();
        return textA < textB ? -1 : textA > textB ? 1 : 0;
      }),
    bankdetails: state.ProfileDetails.AllProfileDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.bankDetails != "NA" && state.ProfileDetails.AllProfileDetails.bankDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.bankDetails.filter((val) => val.IsDefault === true) : [] : []
    ,
    FullschemeList:
      state.Dropdown.dropdowndetails.scheme != null
        ? state.Dropdown.dropdowndetails.scheme.length != 0
          ? state.Dropdown.dropdowndetails.scheme
            // .filter((item) => item.SWPAllowed === 1)
            .sort(function (a, b) {
              var textA = a.SN.toUpperCase();
              var textB = b.SN.toUpperCase();
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            })
            .map((val) => {
              return {
                label: val.SN,
                value: val.SI,
                isChecked: false,
              };
            })
          : []
        : [],

    logindetails: state.Login.logindetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ SchemeChange, TypeChange }, dispatch);
};
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(SWP))
);
