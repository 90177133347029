import * as types from '../../constants/actionsType'
import { bindActionCreators } from 'redux';
const initialState = {
    shortterm: [],
    exitload: [],
    Elssdata: [],
    shortgroupeddata: [],
    elssgroupdata: [],
    totalshortterm: [],
    totalexitload: [],
    totalelssdata: [],
    shorttermcount: "",
    exitloadcount: "",
    Elssdatacount: "",
    isShorttermLoading: false,
    isExitloadLoading: false,
    isElssLoading: false,
    elssMaturity: [],
    elssMaturityGroupedData: [],
    totalElssMaturity: [],
    isElssMaturityLoading: false



}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.SHORT_TERM:
            let grp = action.payload.dataTable != null && action.payload.dataTable.length != 0 ? action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SubAssetClassName === ele.SubAssetClassName)) : []
            // console.log(action.payload.dataTable)
            return {
                ...state,
                shortterm: action.payload.dataTable != null && action.payload.dataTable.length != 0 ? action.payload.dataTable : state.shortterm
                ,
                shortgroupeddata: grp != undefined || grp != null && grp.length != 0 ? grp.map((item) => {
                    return {
                        "Category": item.AssetClassName, "subcategory": item.SubAssetClassName, "subtotalquantity": item.SubAsset_TotalQuantity, "subtotalinvested": item.SubAsset_InvestedValue, "subtotalgainloss": item.SubAsset_GainLoss, "subtotalcurrentvalue": item.SubAsset_CurrentValue, "SubAsset_STH_GainLoss": item.SubAsset_STH_GainLoss, "SubAsset_STH_CurrentValue": item.SubAsset_STH_CurrentValue, "SubAsset_STH_InvestedValue": item.SubAsset_STH_InvestedValue, "SubAsset_LTH_InvestedValue": item.SubAsset_LTH_InvestedValue, "SubAsset_LTH_GainLoss": item.SubAsset_LTH_GainLoss, "SubAsset_LTH_CurrentValue": item.SubAsset_LTH_CurrentValue, "data": action.payload.dataTable.sort((a, b) => {
                            var textA = a.SchemeName.toUpperCase();
                            var textB = b.SchemeName.toUpperCase();
                            return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                        }).filter((items) => items.SubAssetClassName === item.SubAssetClassName)
                    }
                }) : [],

                totalshortterm: action.payload.dataTable != null && action.payload.totalTable.length != 0 ? action.payload.totalTable : state.totalshortterm
                , isShorttermLoading: false
            }

        case types.EMPTY_SHORT_TERM:
            return {
                ...state,
                shortterm: [],
                shortgroupeddata: [],
                totalshortterm: [],
                isShorttermLoading: false
            }
        case types.LOADER_SHORT_TERM:
            return {
                ...state,
                isShorttermLoading: action.payload,
                shortterm: [],
                shortgroupeddata: [],
                totalshortterm: [],
            }
        case types.ELSS_MATURITY:
            // let grpElssMaturity = action.payload.dataTable != null && action.payload.dataTable.length != 0 ? action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SubAssetClassName === ele.SubAssetClassName)) : []

            return {
                ...state,
                elssMaturity: action.payload.dataTable != null && action.payload.dataTable.length != 0 ? action.payload.dataTable : state.elssMaturity
                ,
                elssMaturityGroupedData: action.payload.dataTable,

                totalElssMaturity: action.payload.totalTable != null && action.payload.totalTable.length != 0 ? action.payload.totalTable : state.totalElssMaturity
                , isElssMaturityLoading: false
            }

        case types.EMPTY_ELSS_MATURITY:
            return {
                ...state,
                elssMaturity: [],
                elssMaturityGroupedData: [],
                totalElssMaturity: [],
                isElssMaturityLoading: false
            }
        case types.LOADER_SHORT_TERM:
            return {
                ...state,
                isShorttermLoading: action.payload,
                shortterm: [],
                shortgroupeddata: [],
                totalshortterm: [],
            }
        //     }
        // case types.EXIT_LOAD:
        //     return {
        //         ...state,
        //         exitload: action.payload.data != null ? action.payload.data : state.exitload,
        //         totalexitload: action.payload.data != null ? [{ "TotalCurrentAmount": action.payload.data[0].TotalCurrentAmount, "TotalGainLoss": action.payload.data[0].TotalGainLoss, "TotalMarketValue": action.payload.data[0].TotalMarketValue, "TotalPurchaseValue": action.payload.data[0].TotalPurchaseValue }]
        //             : state.totalexitload
        //         , isExitloadLoading: false
        //         // totalexitload: action.payload != null ? action.payload.data : state.exitload
        //         //,
        //     }

        // case types.EMPTY_EXIT_LOAD:
        //     return {
        //         ...state,
        //         exitload: [],
        //         totalexitload: [],
        //         isExitloadLoading: false
        //     }
        // case types.LOADER_EXIT_LOAD:
        //     return {
        //         ...state,
        //         isExitloadLoading: action.payload,
        //         exitload: [],
        //         totalexitload: [],
        //     }
        case types.ELSS:
            let fulldata = []
            let grpelss = action.payload.dataTable != null && action.payload.dataTable.length != 0 ? action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.AMCName === ele.AMCName)) : []
            if (grpelss.length != 0) {
                fulldata = grpelss.sort((a, b) => {
                    var textA = a.SchemeName.toUpperCase();
                    var textB = b.SchemeName.toUpperCase();
                    return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
                }).map((item) => {
                    let Scheme = action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SchemeName === ele.SchemeName) && ele.AMCName == item.AMCName).map((item) => {
                        return item.SchemeName
                    })
                    let TotalCurrentValue = action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SchemeName === ele.SchemeName) && ele.AMCName == item.AMCName).map((item) => {
                        return item.SchemeFolio_CurrentValue
                    })
                    let TotalInvestedValue = action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SchemeName === ele.SchemeName) && ele.AMCName == item.AMCName).map((item) => {
                        return item.SchemeFolio_InvestedValue
                    })
                    let FolioNo = action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SchemeName === ele.SchemeName) && ele.AMCName == item.AMCName).map((item) => {
                        return item.FolioNo
                    })
                    // 
                    return {
                        "AmcName": item.AMCName, SchemeData: {
                            "SchemeName": Scheme.toString(), "FolioNo": FolioNo.toString(), "TotalCurrentValue": TotalCurrentValue.toString(), "TotalInvestedValue": TotalInvestedValue.toString(), "Data": action.payload.dataTable.filter((val) => val.SchemeName === Scheme.toString())
                        }

                    }
                })
            }
            // 
            return {
                ...state,
                Elssdata: action.payload != null ? action.payload.dataTable != null && action.payload.dataTable.length != 0 ? action.payload.dataTable : [] : state.Elssdata
                ,
                elssgroupdata: fulldata,
                totalelssdata: action.payload.totalTable != null && action.payload.totalTable.length != 0 ? action.payload.totalTable : state.totalelssdata
                , isElssLoading: false
            }
        case types.LOADER_ELSS:
            return {
                ...state,
                isExitloadLoading: action.payload,
                Elssdata: [],
                elssgroupdata: [],
                totalelssdata: []
            }
        case types.EMPTY_ELSS:
            return {
                ...state,
                Elssdata: [],
                elssgroupdata: [],
                totalelssdata: []
                , isElssLoading: false
            }
        // case types.EQUITY_SHORT_TERM:
        //     let equity_grp = action.payload.dataTable != null && action.payload.dataTable.length != 0 ? action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SubAssetClassName === ele.SubAssetClassName)) : []

        //     return {
        //         ...state,
        //         equity_shortterm: action.payload.dataTable != null ? action.payload.dataTable : state.equity_shortterm
        //         ,
        //         equity_shortgroupeddata: equity_grp != undefined || equity_grp != null && equity_grp.length != 0 ? equity_grp.map((item) => {
        //             return {
        //                 "Category": item.AssetClassName, "subcategory": item.SubAssetClassName, "subtotalquantity": item.SubAsset_TotalQuantity, "subtotalinvested": item.SubAsset_InvestedValue, "subtotalgainloss": item.SubAsset_GainLoss, "subtotalcurrentvalue": item.SubAsset_CurrentValue, "SubAsset_STH_GainLoss": item.SubAsset_STH_GainLoss, "SubAsset_STH_CurrentValue": item.SubAsset_STH_CurrentValue, "SubAsset_STH_InvestedValue": item.SubAsset_STH_InvestedValue, "SubAsset_LTH_InvestedValue": item.SubAsset_LTH_InvestedValue, "SubAsset_LTH_GainLoss": item.SubAsset_LTH_GainLoss, "SubAsset_LTH_CurrentValue": item.SubAsset_LTH_CurrentValue, "data": action.payload.dataTable.sort((a, b) => {
        //                     var textA = a.SchemeName.toUpperCase();
        //                     var textB = b.SchemeName.toUpperCase();
        //                     return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        //                 }).filter((items) => items.SubAssetClassName === item.SubAssetClassName)
        //             }
        //         }) : [],

        //         equity_totalshortterm: action.payload != null ? action.payload.totalTable : state.equity_totalshortterm
        //         , equity_isShorttermLoading: false
        //     }

        // case types.EMPTY_EQUITY_SHORT_TERM:
        //     return {
        //         ...state,
        //         equity_shortterm: [],
        //         equity_shortgroupeddata: [],
        //         equity_totalshortterm: [],
        //         equity_isShorttermLoading: false
        //     }
        // case types.LOADER_EQUITY_SHORT_TERM:
        //     return {
        //         ...state,
        //         equity_isShorttermLoading: action.payload,
        //         equity_shortterm: [],
        //         equity_shortgroupeddata: [],
        //         equity_totalshortterm: [],
        //     }
        // case types.EQUITY_EXIT_LOAD:
        //     return {
        //         ...state,
        //         equity_exitload: action.payload.data != null ? action.payload.data : state.equity_exitload,
        //         equity_totalexitload: action.payload.data != null ? [{ "TotalCurrentAmount": action.payload.data[0].TotalCurrentAmount, "TotalGainLoss": action.payload.data[0].TotalGainLoss, "TotalMarketValue": action.payload.data[0].TotalMarketValue, "TotalPurchaseValue": action.payload.data[0].TotalPurchaseValue }]
        //             : state.equity_totalexitload
        //         , equity_isExitloadLoading: false
        //         // totalexitload: action.payload != null ? action.payload.data : state.exitload
        //         //,
        //     }

        // case types.EMPTY_EQUITY_EXIT_LOAD:
        //     return {
        //         ...state,
        //         equity_exitload: [],
        //         equity_totalexitload: [],
        //         equity_isExitloadLoading: false
        //     }
        // case types.LOADER_EQUITY_EXIT_LOAD:
        //     return {
        //         ...state,
        //         equity_isExitloadLoading: action.payload
        //     }
        // case types.EQUITY_ELSS:
        //     let equity_fulldata = []
        //     let equity_grpelss = action.payload.dataTable != null && action.payload.dataTable.length != 0 ? action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.AMCName === ele.AMCName)) : []
        //     if (equity_grpelss.length != 0) {
        //         fulldata = equity_grpelss.sort((a, b) => {
        //             var textA = a.SchemeName.toUpperCase();
        //             var textB = b.SchemeName.toUpperCase();
        //             return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
        //         }).map((item) => {
        //             let equity_Scheme = action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SchemeName === ele.SchemeName) && ele.AMCName == item.AMCName).map((item) => {
        //                 return item.SchemeName
        //             })
        //             let equity_TotalCurrentValue = action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SchemeName === ele.SchemeName) && ele.AMCName == item.AMCName).map((item) => {
        //                 return item.SchemeFolio_CurrentValue
        //             })
        //             let equity_TotalInvestedValue = action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SchemeName === ele.SchemeName) && ele.AMCName == item.AMCName).map((item) => {
        //                 return item.SchemeFolio_InvestedValue
        //             })
        //             let equity_FolioNo = action.payload.dataTable.filter((ele, ind) => ind === action.payload.dataTable.findIndex(elem => elem.SchemeName === ele.SchemeName) && ele.AMCName == item.AMCName).map((item) => {
        //                 return item.FolioNo
        //             })
        //             // 
        //             return {
        //                 "AmcName": item.AMCName, SchemeData: {
        //                     "SchemeName": equity_Scheme.toString(), "FolioNo": equity_FolioNo.toString(), "TotalCurrentValue": equity_TotalCurrentValue.toString(), "TotalInvestedValue": equity_TotalInvestedValue.toString(), "Data": action.payload.dataTable.filter((val) => val.SchemeName === equity_Scheme.toString())
        //                 }

        //             }
        //         })
        //     }
        //     // 
        //     return {
        //         ...state,
        //         equity_Elssdata: action.payload != null ? action.payload.dataTable != null && action.payload.dataTable.length != 0 ? action.payload.dataTable : [] : state.equity_Elssdata
        //         ,
        //         equity_elssgroupdata: equity_fulldata,
        //         equity_totalelssdata: action.payload.totalTable != null && action.payload.totalTable.length != 0 ? action.payload.totalTable : state.equity_totalelssdata
        //         , equity_isElssLoading: false
        //     }
        // case types.LOADER_EQUITY_ELSS:
        //     return {
        //         ...state,
        //         equity_isExitloadLoading: action.payload,
        //         equity_Elssdata: [],
        //         equity_elssgroupdata: [],
        //         equity_totalelssdata: []
        //     }
        // case types.EMPTY_EQUITY_ELSS:
        //     return {
        //         ...state,
        //         equity_Elssdata: [],
        //         equity_elssgroupdata: [],
        //         equity_totalelssdata: []
        //         , equity_isElssLoading: false
        //     }
        default:
            return state
    }
}
export default reducer
