import * as types from '../../constants/actionsType';
import getAllExitload from '../../../api/afrreport/exitload'
import constants from '../../../utils/constants/apiconstant';
export function getExitLoadaDetails(ClientID,AccountID,AsonDate,ProductID,FamilyID,L5ID,L4ID,flag) {
  return (dispatch) => {
    getAllExitLoadAction(dispatch, ClientID,AccountID,AsonDate,ProductID,FamilyID,L5ID,L4ID,flag);
  };
}
function getAllExitLoadAction(dispatch, ClientID,AccountID,AsonDate,ProductID,FamilyID,L5ID,L4ID,flag) {
//  if(ProductID==1){
//   dispatch({
//     type: types.EMPTY_EXIT_LOAD,
//     payload: [],
//   });
  dispatch({
    type: types.LOADER_EXIT_LOAD,
    payload: false,
  });
// }else{
//     dispatch({
//       type: types.EMPTY_EQUITY_EXIT_LOAD,
//       payload: [],
//     });
//     dispatch({
//       type: types.LOADER_EQUITY_EXIT_LOAD,
//       payload: [],
//     });
//   }
//   getAllExitload(ClientID,AccountID,AsonDate)
//     .then((data) => {
//       // 
//       dispatch({
//         type: types.PREVENT_API_CALL,
//         payload:[{ProductID:ProductID,api:"ExitLoad"}],
//     });
//       if(ProductID==1){
//       dispatch({
//         type: types.EXIT_LOAD,
//         payload: data,
//       });
    
//     }else{
//         dispatch({
//           type: types.EQUITY_EXIT_LOAD,
//           payload: data,
//         });
      
//       }
//         // if (data != undefined) {
//         //   if (data.status == "S") {
//         //       //
//         //     dispatch({
//         //         type: types.EXIT_LOAD,
//         //         payload: data,
//         //       });
//         //   } else {
//         //     dispatch({
//         //         type: types.EXIT_LOAD,
//         //         payload: [],
//         //       });
//         //   }
//         // }
//     })
//     .catch((error) => {
//       dispatch({
//         type: types.PREVENT_API_CALL,
//         payload:[{ProductID:ProductID,api:"ExitLoad"}],
//     });
//       if(ProductID==1){
//         dispatch({
//           type: types.EMPTY_EXIT_LOAD,
//           payload: [],
//         });
//       }else{
//           dispatch({
//             type: types.EMPTY_EQUITY_EXIT_LOAD,
//             payload: [],
//           });
       
//         }
//     })
}
