import ApiValues from '../url/apiurl'
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
import labels from '../../utils/constants/labels'
import { Asset } from '../../utils/mainasset';
function ChangeEmail(UserID,OldEmailID,NewEmailID) {

    //let url = "https://ms.zoifintech.com/Dashboard/Login"
   
    //   data.Password = password;
    let data = {};
    data.UserID = UserID;
    data.OldEmailID = OldEmailID;
    data.NewEmailID=NewEmailID
    data.Source = Asset.source

   // data.Resent=Resent

    // var config = {
    //     method: 'post',
    //     url: ApiValues.ChangeEmail,
    //     headers: {
    //         'Content-Type': 'application/json'
    //     },
    //     data: data
    // };

    // return axios(config)
    //     .then(function (response) {
    //         return response.data;
    //     })
    //     .catch(function (error) {
    //         return {"status":"F","message":labels.messages.apierror}
    //     });
    return Networking.PostApi(ApiValues.ChangeEmail, data,labels.apiKey.apiKeys, labels.apiKey.apiKeys, true).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default ChangeEmail
