
import React, { Component } from 'react'
// import Tabs from '../tabs/tabs';
import '../../utils/stylesheets/layout/layout.css'
import Toolbar from '../../components/toolbar/toolbar';
import Label from '../../utils/constants/labels'
import { connect } from 'react-redux';
import { loginsession } from '../../redux/actions/login/loginaction'
import { bindActionCreators } from 'redux';
import secureStorage from '../../utils/securestorage';
import Tabs from '../tabs/liquiloans_tab'
import { withStyles } from '@material-ui/core/styles';
import { NegativeFinding, replaceNull, scrollToTopPage } from '../../utils/commonfunction';
import Networking from '../../utils/api/apiaccess';
import ApiValues from '../../api/url/apiurl'
import getStructuredProducts from '../../api/structuredproducts/structuredproducts';
const styles = (theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "10% 80% 10%",
    marginTop: "5%",
    // [theme.breakpoints.down("920")]: {
    //   marginTop: "30%",
    // },
    // [theme.breakpoints.down("920")]: {
    //    marginTop: "55%",
    // }
  },
  cardsData: {
    display: "grid",
    gridTemplateColumns: "repeat(4,1fr) !important",
    "& .data3": {
      alignItems: "center"
    }
  }
})
class container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerData: [],
      loader: false,

    }
  }
  componentDidUpdate(prevProps) {
    const { clientID, date, L5ID, location } = this.props;
    if (prevProps.clientID != clientID || prevProps.date != date || prevProps.L5ID != L5ID) {
      // 
      if (this.props.report != "FamilyReport") {
        this.getLiquiloansDate(clientID, date, L5ID)
      } else {
        this.getFamilyLiquiloansDate(clientID, date, L5ID)
      }
    }
  }
  componentDidMount() {
    scrollToTopPage()
    if (this.props.report != "FamilyReport") {
      this.getLiquiloansDate(this.props.clientID, this.props.date, this.props.L5ID)

    } else {
      this.getFamilyLiquiloansDate(this.props.clientID, this.props.date, this.props.L5ID)
    }
  }
  getLiquiloansDate = (ClientID, AsOnDate, L5ID) => {
    let data = {
      L3ID: ClientID,
      L4ID: 0,
      L5ID: L5ID,
      AsOnDate: AsOnDate
    }
    this.setState({ loader: true })
    Networking.PostApi(ApiValues.LiquiloansHeader, data).then((response) => {
      let res = replaceNull(response, "NA")
      this.setState({ loader: false })
      if (res.status == "S" && res.data != "NA") {
        this.setState({
          headerData: [{ firstName: "Portfolio Value", lastName: NegativeFinding(res.data[0].PortfolioValue, "", "", "", "rupee") },
          { firstName: "Investment Amount", lastName: NegativeFinding(res.data[0].InvestmentAmount, "", "", "", "rupee") },
            { firstName: "Current Value", lastName: NegativeFinding(res.data[0].CurrentValue, "", "", "", "rupee"), val: `(${NegativeFinding(res.data[0].XIRR, "", "", "", "percentage")})` }
          ]
        })
      } else {
        this.setState({
          headerData: [{ firstName: "Portfolio Value", lastName: NegativeFinding(0, "", "", "", "rupee") },
          { firstName: "Investment Amount", lastName: NegativeFinding(0, "", "", "", "rupee") },
            { firstName: "Current Value", lastName: NegativeFinding(0, "", "", "", "rupee"), val: `(${NegativeFinding(0, "", "", "", "percentage")})` }
          ]
        })
      }
    })
  }
  getFamilyLiquiloansDate = (ClientID, AsOnDate, L5ID) => {
    let data = {
      L3ID: ClientID,
      L4ID: 0,
      L5ID: L5ID,
      AsOnDate: AsOnDate
    }
    this.setState({ loader: true })
    Networking.PostApi(ApiValues.LiquiloansHeader, data).then((response) => {
      let res = replaceNull(response, "NA")
      this.setState({ loader: false })
      if (res.status == "S" && res.data != "NA") {
        this.setState({
          headerData: [{ firstName: "Portfolio Value", lastName: NegativeFinding(res.data[0].PortfolioValue, "", "", "", "rupee") },
          { firstName: "Investment Amount", lastName: NegativeFinding(res.data[0].InvestmentAmount, "", "", "", "rupee") },
            { firstName: "Current Value", lastName: NegativeFinding(res.data[0].CurrentValue, "", "", "", "rupee"), val: `(${NegativeFinding(res.data[0].XIRR, "", "", "", "percentage")})` }
          ]
        })
      } else {
        this.setState({
          headerData: [{ firstName: "Portfolio Value", lastName: NegativeFinding(0, "", "", "", "rupee") },
          { firstName: "Investment Amount", lastName: NegativeFinding(0, "", "", "", "rupee") },
            { firstName: "Current Value", lastName: NegativeFinding(0, "", "", "", "rupee"), val: `(${NegativeFinding(0, "", "", "", "percentage")})` }
          ]
        })
      }
    })
  }

  render() {
    const { classes } = this.props
    return (
      <div >
        <Toolbar title={Label.menu.liquiloans} className={classes.cardsData} loader={this.state.loader} data={this.state.headerData} childFunc={this.props.childFunc}></Toolbar>
        <div className={classes.root}>
          <div ></div>
          <div >
            <Tabs page={this.props.location} report={this.props.report} />
          </div>
          <div ></div>
        </div>
      </div>
    )
  }
}


const mapStateToProps = state => ({
  date: state.Login.date,
  mfHeader2: state.Card.Liquiloans,
  sessionID: state.Login.sessionID,
  L5ID: state.Login.L5ID,
  clientID: state.Login.logindetails.ClientID,
  userID: state.Login.userID,
  date: state.Login.date,
  logindetails: state.Login.logindetails,
  epfList: state.Epf.epf_List
})

export default withStyles(styles)(connect(mapStateToProps, null)(container))