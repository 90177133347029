import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Tooltip } from "@material-ui/core";
import colors from "../../utils/colors/colors";
import "./progressline.css";
import { ImportExport } from "@material-ui/icons";
const styles = (theme) => ({
  ProgressContainer: {
    backgroundColor: "white",
    height: 10,
    width: "100%",
    marginLeft: "auto",
    marginRight: "auto",
    display: "block",
    borderRadius: 10,
    borderColor: "#bdbdbd",
    border: "1px solid"
  },
  progressBar: {
    left: 0,
    height: 10,
    borderRadius: 10,
    backgroundColor: "#bdbdbd",
    display: "block",
    transition: "width 0.3s ease-in",
  },
});
class ProgressBar extends React.Component {
  calculateProgress(progress, goal) {
    if (Number(progress) === 0) {
      return 0.75 + "%";
    }
    if (Number(goal) >= Number(progress)) {
      return (progress / goal) * 100 + "%";
    } else {
      return 100 + "%";
    }
  }

  render() {
    const { actualValue, maxValue, classes, label } = this.props;
    if (this.props.page == "primary") {
      return (<MultiColorProgressBar readings={readings} />)
    } else {
      return (<>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

          <div className="progressLabel">{label} - {actualValue}</div>

          <div style={{ fontSize: 13 }}>{maxValue}</div>

        </div>
        <div style={{ marginBottom: 19 }}>

        </div>
        <Tooltip title={maxValue} arrow placement="bottom-end">
          <div className={classes.ProgressContainer}>
            <Tooltip title={actualValue} arrow placement="top-start">
              <div
                className={classes.progressBar}
                style={{ width: this.calculateProgress(actualValue, maxValue) }}
              ></div>
            </Tooltip>

          </div>
        </Tooltip>
      </>
      );
    }

  }
}
class MultiColorProgressBar extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { readings } = this.props;
    let parent = [...readings, { name: "", }]
    let values = parent && parent.length && parent.map(function (item, i) {
      if (item.value > 0) {
        return (
          <div className="value" style={{ 'color': item.color, 'width': item.value + '%' }} key={i}>
            <span>{item.value}%</span>
          </div>
        )
      }
    }, this);

    let calibrations = parent && parent.length && parent.map(function (item, i) {
      if (item.value > 0) {
        return (
          <div className="graduation" style={{ 'color': item.color, 'width': item.value + '%' }} key={i}>
            <span>|</span>
          </div>
        )
      }
    }, this);
    let a = 0
    let bars = parent && parent.length && parent.map(function (item, i) {
      if (item.value != undefined) {
        a = a + item.value
      }
      // if (item?.value > 0) {
      return (
        <div className="bar" style={{ 'backgroundColor': item.color == undefined ? colors.BORDER : item.color, 'width': item.value == undefined ? `${100 - a}%` : item.value + '%', height: 387 }} key={i}>

        </div>
      )
      // }
    }, this);

    let legends = parent && parent.length && parent.map(function (item, i) {
      if (item?.value > 0 && item.name != "") {
        return (

          <div style={{ display: "flex", flexDirection: "row", fontSize: 13 }}>
            <span className="dot" style={{ 'color': item.color }}>● </span>
            <div className="legend" key={i} style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
              <span className="label">{item.name}</span>

              <span className="label" >{item.value}%</span>
            </div>
          </div>

        )
      }
    }, this);

    return (
      <div className="multicolor-bar" style={{ display: "flex", flexDirection: "column", gap: 10 }}>
        {/* <div className="values">
      		{values == ''?'':values}
      	</div>
      	<div className="scale">
      		{calibrations == ''?'':calibrations}
      	</div> */}
        <div className="bars">
          {bars == '' ? '' : bars}
        </div>
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          {legends == '' ? '' : legends}
        </div>
      </div>
    );
  }
}
let readings = [
  {
    name: 'Large Cap',
    value: 60,
    color: "#DB843D",

  },
  {
    name: 'Mid Cap',
    value: 7,
    color: '#f9ad95',

  },
  {
    name: 'Small Cap',
    value: 23,
    color: '#f78a69',
  },



];

// React.render(<MultiColorProgressBar readings={readings}/>, document.getElementById('root'));
export default withStyles(styles)(ProgressBar);
