import * as types from '../../constants/actionsType'
const initialState = {
  sipOrder: []
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SIP_ORDER:
      return {
        ...state,
        sipOrder: action.payload
      }
    default:
      return state
  }
}
export default reducer
