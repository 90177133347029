import * as types from '../../constants/actionsType';
import getTransactionList from '../../../api/transactionreport/transactionreportdetails';
import constants from '../../../utils/constants/apiconstant';
import { replaceNull } from '../../../utils/commonfunction';
import labels from '../../../utils/constants/labels';
export function getTransactionListing(Account, ClientID, InceptionDate, TransactionType, Type, UserID, SessionID, SchemeID, AssetClass, AccountType, FromDate, ToDate, ProductID, FamilyID, L5ID, L4ID, flag) {
  return (dispatch) => {
    getTransactionListAction(dispatch, Account, ClientID, InceptionDate, TransactionType, Type, UserID, SessionID, SchemeID, AssetClass, AccountType, FromDate, ToDate, ProductID, FamilyID, L5ID, L4ID, flag);

  }
}
function getTransactionListAction(dispatch, Account, ClientID, InceptionDate, TransactionType, Type, UserID, SessionID, SchemeID, AssetClass, AccountType, FromDate, ToDate, ProductID, FamilyID, L5ID, L4ID, flag) {
  if (ProductID === 1) {
    dispatch({
      type: types.EMPTY_TRANSACTION,
      payload: []
    });
    dispatch({
      type: types.LOADER_TRANSACTION,
      payload: true
    });
  } else if (ProductID === 2) {
    dispatch({
      type: types.EMPTY_EQUITY_TRANSACTION,
      payload: []
    });
    dispatch({
      type: types.LOADER_EQUITY_TRANSACTION,
      payload: true
    });
  } else if (ProductID === 3) {
    dispatch({
      type: types.EMPTY_BONDS_TRANSACTION,
      payload: []
    });
    dispatch({
      type: types.LOADER_BONDS_TRANSACTION,
      payload: true
    });
  }
  else if (ProductID === 4) {
    dispatch({
      type: types.EMPTY_GB_TRANSACTION,
      payload: []
    });
    dispatch({
      type: types.LOADER_GB_TRANSACTION,
      payload: true
    });
  }
  else if (ProductID === 5) {
    dispatch({
      type: types.EMPTY_FD_TRANSACTION,
      payload: []
    });
    dispatch({
      type: types.LOADER_FD_TRANSACTION,
      payload: true
    });
  } else if (ProductID === 6) {
    dispatch({
      type: types.EMPTY_AIF_TRANSACTION,
      payload: []
    });
    dispatch({
      type: types.LOADER_AIF_TRANSACTION,
      payload: true
    });
  }
  else if (ProductID === 7) {
    dispatch({
      type: types.EMPTY_REALESTATE_TRANSACTION,
      payload: []
    });
    dispatch({
      type: types.LOADER_REALESTATE_TRANSACTION,
      payload: true
    });
  }
  else if (ProductID === 8) {
    dispatch({
      type: types.EMPTY_NPS_TRANSACTION,
      payload: []
    });
    dispatch({
      type: types.LOADER_NPS_TRANSACTION,
      payload: true
    });
  }
  else if (ProductID === 10) {
    dispatch({
      type: types.EMPTY_MLD_TRANSACTION,
      payload: []
    });
    dispatch({
      type: types.LOADER_MLD_TRANSACTION,
      payload: true
    });
  }
  getTransactionList(Account, ClientID, InceptionDate, TransactionType, Type, UserID, SessionID, SchemeID, AssetClass, AccountType, FromDate, ToDate, ProductID, FamilyID, L5ID, L4ID).then((data) => {
    if (data != undefined) {
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: ProductID, api: "Transaction" }],
      });
      if (data.status == "S") {
        if (ProductID === 1) {
          dispatch({
            type: types.GET_TRANSACTIONLIST,
            payload: data.data == null || data.data.length == 0 ? [] : replaceNull(data.data, labels.messages.NA),
          });

        } else if (ProductID === 2) {

          dispatch({
            type: types.EQUITY_TRANSACTION,
            payload: data.data == null || data.data.length == 0 ? [] : replaceNull(data.data, labels.messages.NA),
          });

        }
        else if (ProductID === 3) {
          dispatch({
            type: types.BONDS_TRANSACTION,
            payload: data.data == null || data.data.length == 0 ? [] : replaceNull(data.data, labels.messages.NA),
          });

        }
        else if (ProductID === 4) {
          dispatch({
            type: types.GB_TRANSACTION,
            payload: data.data == null || data.data.length == 0 ? [] : replaceNull(data.data, labels.messages.NA),
          });

        }
        else if (ProductID === 5) {
          dispatch({
            type: types.FD_TRANSACTION,
            payload: data.data == null || data.data.length == 0 ? [] : replaceNull(data.data, labels.messages.NA),
          });

        } else if (ProductID === 6) {
          dispatch({
            type: types.AIF_TRANSACTION,
            payload: data.data == null || data.data.length == 0 ? [] : replaceNull(data.data, labels.messages.NA),
          });
        }
        else if (ProductID === 7) {
          dispatch({
            type: types.REALESTATE_TRANSACTION,
            payload: data.data == null || data.data.length == 0 ? [] : replaceNull(data.data, labels.messages.NA),
          });
        }
        else if (ProductID === 8) {
          dispatch({
            type: types.NPS_TRANSACTION,
            payload: data.data == null || data.data.length == 0 ? [] : replaceNull(data.data, labels.messages.NA),
          });
        }
        else if (ProductID === 10) {
          dispatch({
            type: types.MLD_TRANSACTION,
            payload: data.data == null || data.data.length == 0 ? [] : replaceNull(data.data, labels.messages.NA),
          });
        }
      } else {
        dispatch({
          type: types.PREVENT_API_CALL,
          payload: [{ ProductID: ProductID, api: "Transaction" }],
        });
        if (ProductID === 1) {
          dispatch({
            type: types.GET_TRANSACTIONLIST,
            payload: [],
          });
        } else if (ProductID === 2) {
          dispatch({
            type: types.EQUITY_TRANSACTION,
            payload: [],
          });
        }
        else if (ProductID === 3) {
          dispatch({
            type: types.BONDS_TRANSACTION,
            payload: [],
          });
        } else if (ProductID === 4) {
          dispatch({
            type: types.GB_TRANSACTION,
            payload: [],
          });
        }
        else if (ProductID === 5) {
          dispatch({
            type: types.FD_TRANSACTION,
            payload: [],
          });
        }
        else if (ProductID === 6) {

          dispatch({
            type: types.AIF_TRANSACTION,
            payload: [],
          });
        } else if (ProductID === 7) {
          dispatch({
            type: types.REALESTATE_TRANSACTION,
            payload: [],
          });
        }
        else if (ProductID === 8) {
          dispatch({
            type: types.NPS_TRANSACTION,
            payload: [],
          });
        } else if (ProductID === 10) {
          dispatch({
            type: types.MLD_TRANSACTION,
            payload: [],
          });
        }
      }
    } else {
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: ProductID, api: "Transaction" }],
      });
      if (ProductID === 1) {
        dispatch({
          type: types.GET_TRANSACTIONLIST,
          payload: [],
        });

      } else if (ProductID === 2) {
        dispatch({
          type: types.EQUITY_TRANSACTION,
          payload: [],
        });

      }
      else if (ProductID === 3) {
        dispatch({
          type: types.BONDS_TRANSACTION,
          payload: [],
        });

      } else if (ProductID === 4) {
        dispatch({
          type: types.GB_TRANSACTION,
          payload: [],
        });

      }
      else if (ProductID === 5) {
        dispatch({
          type: types.FD_TRANSACTION,
          payload: [],
        });

      } else if (ProductID === 6) {
        dispatch({
          type: types.AIF_TRANSACTION,
          payload: [],
        });
      }
      else if (ProductID === 7) {
        dispatch({
          type: types.REALESTATE_TRANSACTION,
          payload: [],
        });
      } else if (ProductID === 8) {
        dispatch({
          type: types.NPS_TRANSACTION,
          payload: [],
        });

      }
      else if (ProductID === 10) {
        dispatch({
          type: types.MLD_TRANSACTION,
          payload: [],
        });

      }
    }
  })
    .catch((error) => {
      //  console.log(error)
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: ProductID, api: "Transaction" }],
      });
      if (ProductID === 1) {
        dispatch({
          type: types.GET_TRANSACTIONLIST,
          payload: [],
        });

      } else if (ProductID === 2) {
        dispatch({
          type: types.EQUITY_TRANSACTION,
          payload: [],
        });

      }
      else if (ProductID === 3) {
        dispatch({
          type: types.BONDS_TRANSACTION,
          payload: [],
        });

      } else if (ProductID === 4) {
        dispatch({
          type: types.GB_TRANSACTION,
          payload: [],
        });

      }
      else if (ProductID === 5) {
        dispatch({
          type: types.FD_TRANSACTION,
          payload: [],
        });

      }
      else if (ProductID === 6) {
        dispatch({
          type: types.AIF_TRANSACTION,
          payload: [],
        });
      } else if (ProductID === 7) {
        dispatch({
          type: types.REALESTATE_TRANSACTION,
          payload: [],
        });
      }
      else if (ProductID === 8) {
        dispatch({
          type: types.NPS_TRANSACTION,
          payload: [],
        });

      }
      else if (ProductID === 10) {
        dispatch({
          type: types.MLD_TRANSACTION,
          payload: [],
        });

      }

    })

}
export const transactionfilter = (data) => {
  // console.log(data)
  return {
    type: types.FILTER_TRAN_PROFIT,
    payload: data
  }
}
export const equity_transactionfilter = (data) => {
  return {
    type: types.FILTER_TRAN_PROFIT_EQUITY,
    payload: data
  }
}
export const bonds_transactionfilter = (data) => {
  return {
    type: types.FILTER_TRAN_PROFIT_BONDS,
    payload: data
  }
}
export const aif_transactionfilter = (data) => {
  return {
    type: types.FILTER_TRAN_PROFIT_AIF,
    payload: data
  }
}
export const mld_transactionfilter = (data) => {
  return {
    type: types.FILTER_TRAN_PROFIT_MLD,
    payload: data
  }
}
export const realEstate_transactionfilter = (data) => {
  return {
    type: types.FILTER_TRAN_PROFIT_REALESTATE,
    payload: data
  }
}
export const nps_transactionfilter = (data) => {
  return {
    type: types.FILTER_TRAN_PROFIT_NPS,
    payload: data
  }
}
export const insurance_transactionfilter = (data) => {
  return {
    type: types.FILTER_TRAN_PROFIT_INSURANCE,
    payload: data
  }
}
export const gb_transactionfilter = (data) => {
  return {
    type: types.FILTER_TRAN_PROFIT_GB,
    payload: data
  }
}
export const fd_transactionfilter = (data) => {
  return {
    type: types.FILTER_TRAN_PROFIT_FD,
    payload: data
  }
}
export const GetTransaction = (data, flag) => {

  return {
    type: types.GET_TRANSACTION,
    data, flag
  }

}