import React from "react";
import { Grid } from "@material-ui/core";
import color from "../../utils/colors/colors";
import Label from "../../utils/constants/labels";
import { withStyles } from "@material-ui/core/styles";
import ErrorBoundary from "../../components/Errorhandling/Errorhandle";
import Typography from "@material-ui/core/Typography";
import MyPlanImage from "../../utils/assets/MyPlanimg1cd3dda33.jpg";
import MyPlanImage1 from "../../utils/assets/images/MyPlanimg1.jpg";
import MyPlanImage2 from "../../utils/assets/images/MyPlanimg2.jpg";
import Button from "../../components/custombutton/button";
import Badges from "../../components/badge/badge";
import { connect } from "react-redux";
import { GetMyPlanDetails } from "../../redux/actions/profile/profile";
import { withRouter } from "react-router";
//import * as types from "../../redux/constants/actionsType";
import { getESignDownloadReport } from "../../redux/actions/esigndownload/esigndownload";
import { getRiaFlowOnLinePaymentReport } from "../../redux/actions/riaflowonlinepayment/riaflowonlinepayment";
import ToggleButton from "../../components/toggle/toggle";
import NoData from "../nodata/nodata";
import { getPurchasedPlan } from "../../redux/actions/profile/profile";
import Skeleton from "@material-ui/lab/Skeleton";
import labels from "../../utils/constants/labels";
import { Redirect } from "react-router";
import colors from "../../utils/colors/colors";
import { NegativeFinding } from "../../utils/commonfunction";
const styles = (theme) => ({

});
class PlanListing extends React.Component {
    constructor() {
        super();
        this.state = {
            index: 0,
            checked: true,
            planCode: " ",
            planName: " ",
            selected: true,
            financialPlanVisible: true,
            equityAdvisorVisible: false,
            allInOne: false,
            isValid: true,
        };
    }

    componentDidMount() {
        if (this.props.item.length != 0) {
            if (this.props.item.Data.filter((val) => val.intervals >= 6).length) {
                this.setState({ selected: true })
            } else {
                this.setState({ selected: false })
            }
        } else {
            this.setState({ selected: true })
        }

    }
    render() {
        //
        const { classes, item, index, toggle } = this.props;
        try {
            return (
                <ErrorBoundary>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 30, marginBottom: "10%" }}>

                        {item.isPlanSelected ? item.Data.filter((val) => this.props.toggle ? val.intervals >= 6 : val.intervals < 6).length == 0 ? <div style={{
                           lineHeight: 2, borderRadius: 10, display: "flex", padding: 14, flexDirection: "column", gap: 30
                        }}> <NoData></NoData></div> : <>

                            <div style={{
                                width: "67%", backgroundColor: index % 2 != 0 ? window.globalConfig.color.PRIMARY : window.globalConfig.color.BROWN, lineHeight: 2, borderRadius: 10, display: "flex", padding: 14, flexDirection: "column", gap: 30
                            }}>
                                <div style={{ display: "grid", gridTemplateColumns: "80% 20%" }}>
                                    <div style={{ display: "flex", flexDirection: "column", color: window.globalConfig.color.WHITE, gap: 30 }}>
                                        <div style={{ textAlign: "left" }}>{item.ProductName}</div>
                                        <div style={{ textAlign: "left", fontSize: "smaller" }}>
                                            A Financial plan that encompasses the five main pillars of personal finance-risk management, financial goals, wealth management,taxation and wealth succession.
                                        </div>

                                    </div>
                                    <div>
                                        <img src={MyPlanImage} style={{ height: 130 }}></img>
                                    </div>
                                </div>
                                <div style={{ display: "grid", gridTemplateColumns: "30% 30% 30%", gap: 10 }}>
                                    {item.Data.filter((val) => this.state.selected ? val.intervals >= 6 : val.intervals < 6).map((value, ind) => {
                                        return <div style={{ display: "grid", gridTemplateRows: "69% 31%",border:"1px solid #e6e7ea",boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px", padding: "3px", backgroundColor: colors.WHITE, fontSize: 14, color: window.globalConfig.color.BLACK_PRIMARY, borderRadius: 5, boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px", textAlign: "left" }}>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <span style={{lineHeight:1.5}}>
                                                    {value.plan_name}
                                                </span>
                                                <span>
                                                    {NegativeFinding(value.recurring_price,"","","","rupee")}/-
                                                </span>
                                            </div>
                                            {/* <div style={{ fontSize: 12 }}>
                                                Premium Financial Plan - Half Yearly
                                            </div> */}
                                            <Button
                                                onClick={() => this.props.onClick(value.plan_code,
                                                    value.plan_name,
                                                    value.recurring_price)}
                                                page={"primary"}
                                                name={"Subscribe"}
                                            />
                                        </div>
                                    })}

                                </div>
                            </div>
                        </>
                            : ""}
                    </div>

                </ErrorBoundary>
            );
        } catch (err) {
            // console.log(err)
            return <Redirect to={labels.Path.NotFound} />;
        }
    }
}
const mapStateToProps = (state) => {
    return {
        myplandetails:
            state.ProfileDetails.myplandetails != null
                ? state.ProfileDetails.myplandetails.length != 0
                    ? state.ProfileDetails.myplandetails
                    : []
                : [],
        // intervaldetails: state.ProfileDetails.intervaldetails
        // riaOffLinePayment: state.RiaOffLinePayment.riaFlowOffLinePaymentOrder != null ? state.RiaOffLinePayment.riaFlowOffLinePaymentOrder.length != 0 ? state.RiaOffLinePayment.riaFlowOffLinePaymentOrder : [] : [],
        // riaOnLinePaymentDetails: state.RiaOnLinePaymentDetails.riaOnLinePaymentDetailsOrder != null ? state.RiaOnLinePaymentDetails.riaOnLinePaymentDetailsOrder.length != 0 ? state.RiaOnLinePaymentDetails.riaOnLinePaymentDetailsOrder : [] : [],
        purchasedPlans:
            state.Login.riaplandetails != null
                ? state.Login.riaplandetails.length != 0
                    ? state.Login.riaplandetails
                    : []
                : [],
        purchasedPlanProfile:
            state.ProfileDetails.purchasedPlan != null
                ? state.ProfileDetails.purchasedPlan.length != 0
                    ? state.ProfileDetails.purchasedPlan
                    : []
                : [],
        isMyPlanDetailsLoading: state.ProfileDetails.isMyPlanDetailsLoading,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        GetMyPlanDetails: (from, status) =>
            dispatch(GetMyPlanDetails(from, status)),
        getESignDownloadReport: () => dispatch(getESignDownloadReport()),
        getRiaFlowOnLinePaymentReport: () =>
            dispatch(getRiaFlowOnLinePaymentReport()),
        getPurchasedPlan: (planCode, planName, Amount) =>
            dispatch(getPurchasedPlan(planCode, planName, Amount)),
    };
};
export default withStyles(styles)(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(PlanListing))
);
