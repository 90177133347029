import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

const OTPInputTextBox = ({ onChange, customStyles, className, disabled, label, error, message }) => {
    const [otp, setOTP] = useState('');

    const handleChange = (value) => {
        if (/^\d*$/.test(value)) {
            setOTP(value);
            onChange(value);
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Backspace' && !otp) {
            const newValue = otp.slice(0, -1);
            setOTP(newValue);
            onChange(newValue);
        }
    };

    const inputStyle = { ...customStyles };

    return (
        <>

            <TextField
                variant="standard"
                margin="normal"
                fullWidth
                id="otp"
                label={label}
                name="otp"
                autoComplete="off"
                autoFocus
                inputProps={{ maxLength: 6 }}
                value={otp}
                onChange={(e) => handleChange(e.target.value)}
                onKeyDown={handleKeyDown}
                style={inputStyle}
            />
            {error === "" ?
                <span style={{ color: window.globalConfig.color.PRIMARY_BORDER, fontSize: 13 }}>{message}</span>
                :
                error !== "" && <span style={{ color: window.globalConfig.color.red, fontSize: 13 }}>{error}</span>}

        </>
    );
};

export default OTPInputTextBox;