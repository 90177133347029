import Apivalues from '../url/apiurl';
import axios from 'axios'
function getESignDownloadReportDetails(clientID, subscriptionID) {
  const BaseUrl = Apivalues.ESignDownloadFile + `?l3CLientID=${clientID}&subscriptionID=${subscriptionID}`

  return axios(BaseUrl, {
    method: 'GET',
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      return 'fail'
    });

}
export default getESignDownloadReportDetails;