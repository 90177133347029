import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels';
/* name should be camel case in all function */
import Networking from '../../utils/api/apiaccess';
function getPortfolioDetails(SessionID, ClientID, AsOnDate,ProductID,FamilyID,L5ID,L4ID) {
 // let BaseUrl = "https://ms.zoifintech.com/Dashboard/PortfolioAllocation"
  let data = {};

  data.sessionID = SessionID
  data.ClientID = ClientID
  data.AsOnDate = AsOnDate
  data.ProductID = ProductID
  data.FamilyID = FamilyID
  data.L5ID = L5ID
  data.L4ID = L4ID
;
return Networking.PostApi(ApiValues.PortfolioDetail, data).then(result => { return result })
.catch(function (error) {
  return { "status": "F", "message": labels.messages.apierror }
});

}
export default getPortfolioDetails
