import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router';
import { withStyles } from '@material-ui/core';
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle';
import DatePicker from '../../components/datepickers/date_pickers';
import CommonDropdown from '../../components/dropdown/dropdownschemes';
import CommonSnackbar from '../../components/snackbar/snackbar';
import Textinput from '../../components/textinput/textinput';
import labels from '../../utils/constants/labels';
import Dynamic_row from '../../components/dynamic_row/dynamic_row';
import { getL5reportDetails } from "../../redux/actions/L5Report/L5ReportAction";
import OutlinedButton from '../../components/payment/button';
import CommonButton from '../../components/custombutton/button'
import moment from 'moment';
import getAllStockList from '../../api/Dataentry/getstocks';
import { numbersandDeciamlOnly, hasNull, round, notAllowdSpecialCharater, amountCommaSeperator, replaceComma } from '../../utils/commonfunction';
import getAllStocksandTradeDetails from '../../api/stocksandtrade/stocksandtrade';
import Subheader from '../../components/subheader/subheader';
import colors from '../../utils/colors/colors';
import { scrollToTopPage } from '../../utils/commonfunction';
const styles = theme => ({
    stocksmaindiv: {
        display: "flex", flexDirection: "column", width: "98%", padding: "4% 4% 4% 4%",
        [theme.breakpoints.down("920")]: {
            width: "93%",
        },
    },
    firstContainer: {

        display: "grid",
        gridTemplateColumns: "repeat(4,1fr)",
        // paddingLeft: "10px",
        minHeight: "10%",
        width: "100%",
        [theme.breakpoints.down("920")]: {
            // marginTop: 70, display: "flex", flexDirection: "column", width: "80%",
            display: "grid",
            width: "92%",
            gridTemplateColumns: "repeat(1,1fr)",
        },

    },
    Spacing: {
        marginLeft: "5%", width: "95%",
        [theme.breakpoints.down("920")]: {
            marginLeft: 0,
            width: "100%"
        }
    },
    tableHeaderDisplay: {
        [theme.breakpoints.down("920")]: {
            display: "none !important"
        }
    },
    ContainerWithWidth: {

        [theme.breakpoints.up("920")]: {
            display: "grid",
            gridTemplateColumns: "40% 15% 13% 13% 19.5%",
            // paddingLeft: "10px"
            marginLeft: "20px",
            width: "100%", marginRight: 0, marginLeft: "0px !important"
        },
        // paddingBottom: 11, paddingTop: 11,
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "50% 50%",
            marginLeft: 5,
            display: "grid",
            rowGap: "12px"
            //  width: "89.2%"
        }
    },
    LastContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        width: "102%",
        [theme.breakpoints.down("920")]: {
            display: "grid",
            gridTemplateColumns: "repeat(1,1fr)",

        }
    },
    tableMainHeader: {
        // width: "99.2%",
        marginRight: 0, marginLeft: "0px !important", paddingBottom: 11, paddingTop: 11
    },
    MainHeader: {
        width: "99.2%",
        color: window.globalConfig.color.ZERO_BACKGROUND,
        marginTop: "8%",
        [theme.breakpoints.up("920")]: {
            width: "95.2%",
            display: "none !important"
        }
    },
    LastContainerPrimaryWidth: {
        marginLeft: "10px", width: "100%",
        [theme.breakpoints.down("920")]: {
            width: "94%", marginLeft: "3%"
        }
    }
    , LastContainerSecondaryWidth: {
        marginLeft: "5%", width: "95%",
        [theme.breakpoints.down("920")]: {
            marginLeft: "3%", width: "94%",
        }
    },
    card: {
        boxShadow: "rgb(19 89 161 / 10%) 0px 0px 20px 0px",
        borderRadius: 5,
        // minHeight: "",
        height: "auto",
        width: "89%", marginTop: "30px"
    },
    buttonStyle: {
        width: "20%",
        [theme.breakpoints.down("920")]: {
            width: "50%"
        }
    },
    mobileresponsiveWidthadjust: {
        [theme.breakpoints.down("600")]: {
            width: "2%"
        }
    },
    cardSpareaterLine: {
        height: 0.5,
        width: "100%",
        // backgroundColor: window.globalConfig.color.AliceBlue,
        backgroundColor: window.globalConfig.color.LABLE_GRAY,
        marginBottom: '1pc',
        marginTop: '6px',
        marginLeft: 0,
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
    scendHeader: {
        textAlign: 'left',
        marginTop: '1pc',
        color: window.globalConfig.color.LABLE_GRAY,
        marginLeft: 0,
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
});
class Stocks extends Component {
    constructor() {
        super();
        this.myDiv = React.createRef();

        this.state = {
            // accexcel: false,
            // open: false,
            // accpdf: false,
            stockerror: "",
            stockdate: "",
            stockdatetemp: moment().format("DD MMM YYYY"),
            anchorEl: null,
            broker: "",
            brokerList: [],
            brokererror: "",
            contractnumber: "",
            contractnumbererror: "",
            settlementnumber: "",
            settlementnumbererror: "",
            STT: "",
            STTerror: "",
            stampChanges: "",
            stampChangesError: "",
            otherChanges: "",
            otherChangesError: "",
            GST: "",
            GSTerror: "",
            transactionChanges: "",
            transactionChangesError: "",
            BrokerID: "",
            totalamount: "",
            totalamounterror: "",
            showlabel: true,
            Sale: [],
            formValues: [{
                AssetID: {
                    name: "",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "Type Scrip Name",
                    style: "name",
                    label: "Asset Name"
                }, Quantity: {
                    name: "0",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "0.00",
                    style: "amount",
                    label: "Quantity"
                }, Price: {
                    name: "0",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "0.00",
                    style: "amount",
                    label: "Price"
                }, Brokerage: {
                    name: "0",
                    errormessage: "0.00",
                    isClicked: false,
                    placeholder: "0.00",
                    style: "amount",
                    label: "Brokerage"
                }, TotalAmount: {
                    name: "0",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "0", style: "amount",
                    label: "Total Amount"
                }
            }],
            purchaseTotalAmount: 0,
            saleTotalAmount: 0,
            purchaseValue: [{
                AssetID: "", TypeID: 1, Quantity: "", Price: "", Brokerage: "", TotalAmount: ""
            }],
            autoCompleteData: [],
            saleValue: [],
            purchaseerror: "",
            saleerror: "",
            isStock: true,
            loader: false,
            opensnackbar: false,
            message: "",
            severity: "warning"    // purchase: [{ "Asset": "", "Quantity": "", "Price": "", "Brokerage": "", "Amount": "" }]
        }
        this.handleSubmit = this.handleSubmit.bind(this)

    }

    componentDidUpdate(prev) {
        const { location, brokerList } = this.props;
        const { isStock, autoCompleteData } = this.state;
        if (prev.brokerList != brokerList) {
            this.setState({ brokerList })
        }
        // if (prev.autoCompleteData != autoCompleteData) {
        //     this.setState({ autoCompleteData: autoCompleteData })
        // }
        // 
        let a = true
        if (location.pathname == "/Stocks") {
            a = true
        } else {
            a = false
        }
        if (isStock != a) {
            //   
            this.setState({
                isStock: a, stockerror: "",
                stockdate: "",
                stockdatetemp: moment().format("DD MMM YYYY"),
                anchorEl: null,
                broker: "",
                brokererror: "",
                contractnumber: "",
                contractnumbererror: "",
                settlementnumber: "",
                settlementnumbererror: "",
                STT: "",
                STTerror: "",
                stampChanges: "",
                stampChangesError: "",
                otherChanges: "",
                otherChangesError: "",
                GST: "",
                GSTerror: "",
                transactionChanges: "",
                transactionChangesError: "",
                totalamount: "",
                totalamounterror: "",
                showlabel: true,
                Sale: [],
                formValues: [{
                    AssetID: {
                        name: "",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "Type Scrip Name",
                        style: "name",
                        label: "Asset Name"
                    }, Quantity: {
                        name: "0",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "0.00",
                        style: "amount",
                        label: "Quantity"
                    }, Price: {
                        name: "0",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "0.00",
                        style: "amount",
                        label: "Price"
                    }, Brokerage: {
                        name: "0",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "0.00",
                        style: "amount",
                        label: "Brokerage"
                    }, TotalAmount: {
                        name: "0",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "0", style: "amount",
                        label: "Total Amount"
                    }
                }],
                purchaseTotalAmount: 0,
                saleTotalAmount: 0,
                purchaseValue: [{
                    AssetID: "", TypeID: 1, Quantity: "", Price: "", Brokerage: "", TotalAmount: ""
                }],
                autoCompleteData: [],
                saleValue: [],
                purchaseerror: "",
                saleerror: "",
                //  isStock: true,
                loader: false,
                opensnackbar: false,
                message: "",
                severity: "warning"    // purchase: [{ "Asset": "", "Quantity": "", "Price": "", "Brokerage": "", "Amount": "" }]
            })
        }

    }
    componentWillUnmount() {
        this.myDiv.current.removeEventListener('change', (e) => {
            // 
            // if (e.key === "Enter" && this.state.page === "login") {
            this.setState({ totalamount: Number(replaceComma(this.state.STT)) + Number(replaceComma(this.state.otherChanges)) + Number(replaceComma(this.state.transactionChanges)) + Number(replaceComma(this.state.GST)) + Number(replaceComma(this.state.stampChanges)) + Number(replaceComma(this.state.purchaseTotalAmount)) - Number(replaceComma(this.state.saleTotalAmount)) })
            //}

        });
    }
    componentDidMount() {
        ///  
        scrollToTopPage()
        this.setState({ brokerList: this.props.brokerList })
        //  
        this.setState({ isStock: this.props.location.pathname === "/trade/Stocks" ? true : false })
        //    
        //this.setState({ autoCompleteData: this.props.categoryFilterlist })
        this.myDiv.current.addEventListener('change', (e) => {
            // 
            // if (e.key === "Enter" && this.state.page === "login") {
            this.setState({ totalamount: Number(replaceComma(this.state.STT)) + Number(replaceComma(this.state.otherChanges)) + Number(replaceComma(this.state.transactionChanges)) + Number(replaceComma(this.state.GST)) + Number(replaceComma(this.state.stampChanges)) + Number(replaceComma(this.state.purchaseTotalAmount)) - Number(replaceComma(this.state.saleTotalAmount)) })
            //}

        });
    }
    callBackPurchase = (data, FullList) => {

        let formValues = FullList;
        let purchase = data

        let PurchaseList = purchase.map((value, index) => {
            return purchase[index]["TotalAmount"] = purchase[index]["Quantity"] * purchase[index]["Price"] + Number(purchase[index]["Brokerage"])
        })
        PurchaseList = purchase;
        this.setState({ purchaseValue: PurchaseList, purchaseerror: "" })

        let List = formValues.map((Value, index) => {

            //   return Object.keys(Value).map((item) => {
            return formValues[index]["TotalAmount"].name = formValues[index]["Quantity"].plac * formValues[index]["Price"].name + Number(formValues[index]["Brokerage"].name), formValues[index]["TotalAmount"].placeholder = formValues[index]["Quantity"].name * formValues[index]["Price"].name + Number(formValues[index]["Brokerage"].name);
            //})
        })
        List = formValues;
        // 
        let totalPrice = data.reduce(function (accumulator, item) {
            return accumulator + item.Quantity * item.Price + Number(item.Brokerage);
        }, 0);

        this.setState({ formValues: formValues, totalamount: this.state.saleTotalAmount != 0 ? totalPrice - this.state.saleTotalAmount : totalPrice, purchaseTotalAmount: totalPrice })
        ///  
    }
    callBackSale = (data, FullList) => {
        //this.setState({ saleValue: data, saleerror: "" })
        let formValues = FullList;
        let sale = data

        let saleList = sale.map((value, index) => {

            return sale[index]["TotalAmount"] = sale[index]["Quantity"] * sale[index]["Price"] - Number(sale[index]["Brokerage"])
        })
        saleList = sale;
        this.setState({ saleValue: saleList, saleerror: "" })
        // //sif()hr
        let List = formValues.map((Value, index) => {

            //   return Object.keys(Value).map((item) => {
            return formValues[index]["TotalAmount"].name = formValues[index]["Quantity"].name * formValues[index]["Price"].name + Number(formValues[index]["Brokerage"].name), formValues[index]["TotalAmount"].placeholder = formValues[index]["Quantity"].name * formValues[index]["Price"].name - Number(formValues[index]["Brokerage"].name);
            //})
        })
        List = formValues;
        // 
        let totalPrice = data.reduce(function (accumulator, item) {
            return accumulator + item.Quantity * item.Price - Number(item.Brokerage);
        }, 0);

        this.setState({ totalamount: this.state.purchaseTotalAmount != 0 ? this.state.purchaseTotalAmount - totalPrice : totalPrice, saleTotalAmount: totalPrice, Sale: List })
        ///  
    }
    handleOnChange = async (e) => {
        // 
        let a;
        if (e.length >= 3) {
            await getAllStockList(e, this.state.isStock ? 2 : 3).then((res) => {
                if (res.status === "S") {
                    // this.setState({ autoCompleteData: res.assetClass })
                    if (res.data != null && res.data.length != 0) {
                        a = res.data.map((item) => {
                            return { "label": item.ScripName, "value": item.ID }
                        })
                    }


                }
            })
            await this.setState({ autoCompleteData: a != undefined ? a : [] })
        } else {
            await this.setState({ autoCompleteData: [] })
        }


        // 
    }

    handleSubmit() {
        // this.setState({ loader: true })
        // event.preventDefault();
        // 
        let a = Number(this.state.purchaseTotalAmount) - Number(this.state.saleTotalAmount)
        let per = 2 * a / 100
        // 
        let isValid = true;

        if (this.state.stockdate === "" || this.state.stockdate === "Select Date") {
            // if (isValid) {
            isValid = false
            // }

            this.setState({ stockerror: labels.messages.required })
        }
        if (this.state.BrokerID == "" || this.state.broker == "") {
            // if (isValid) {
            isValid = false
            // }
            this.setState({ brokererror: labels.messages.required })
        }
        if (this.state.contractnumber == "") {
            // if (isValid) {
            isValid = false
            // }
            this.setState({ contractnumbererror: labels.messages.required })
        } if (this.state.purchaseValue.some(hasNull)) {
            // if (isValid) {
            isValid = false
            // }
            this.setState({ purchaseerror: "All fields are mandatory" })
        }
        //  
        //  
        if (this.state.saleValue.length != 0) {
            if (this.state.saleValue.some(hasNull)) {
                // if (isValid) {
                isValid = false
                // }
                this.setState({ saleerror: "All fields are mandatory" })
            }
        }

        if (this.state.STT != "") {
            if (Number(replaceComma(this.state.STT)) > per) {
                // if (isValid) {
                isValid = false
                // }
                this.setState({ STTerror: "STT percentage should be less than 2% of total amount" })
            }
        }
        if (this.state.GST != "") {
            if (Number(replaceComma(this.state.GST)) > per) {
                // if (isValid) {
                isValid = false
                // }
                this.setState({ GSTerror: "GST percentage should be less than 2% of total amount" })
            }
        } if (this.state.stampChanges != "") {
            if (Number(replaceComma(this.state.stampChanges)) > per) {
                // if (isValid) {
                isValid = false
                // }
                this.setState({ stampChangesError: "Stamp Charges percentage should be less than 2% of total amount" })
            }
        }
        if (this.state.otherChanges != "") {
            if (Number(replaceComma(this.state.otherChanges)) > per) {
                // if (isValid) {
                isValid = false
                // }
                this.setState({ otherChangesError: "Other Charges percentage should be less than 2% of total amount" })
            }
        }
        if (this.state.transactionChanges != "") {
            if (Number(replaceComma(this.state.transactionChanges)) > per) {
                // if (isValid) {
                isValid = false
                // }
                this.setState({ transactionChangesError: "Transaction Charges percentage should be less than 2% of total amount" })
            }
        }
        let BrokerageValidation
        isValid = isValid ? this.state.purchaseValue.some(hasNull) === true ? false : true : false

        let findingzero = "";
        //  
        if (isValid) {

            this.state.purchaseValue.map((item) => {
                if (findingzero == "") {
                    Object.keys(item).forEach(function (key) {

                        if (item[key] == 0 && key != "Brokerage") {
                            findingzero = `Purchase ${key} should not be Zero`
                            isValid = false
                        }
                    });
                }
            })
        }

        if (isValid) {

            BrokerageValidation = this.state.purchaseValue.some((value) => {
                // 
                return 2 * value.Quantity * value.Price / 100 > value.Brokerage
            })
            // if (isValid) {
            isValid = BrokerageValidation
            // }
            // 

            // console.log(BrokerageValidation)
            this.setState({ purchaseerror: BrokerageValidation === false ? "Purchase Brokerage Should be less than 2% of total Amount" : "" })

        }
        if (this.state.saleValue.length != 0) {
            isValid = this.state.saleValue.some(hasNull) === true ? false : true

            //  
            if (isValid && findingzero == "") {

                this.state.saleValue.map((item) => {
                    if (findingzero == "") {
                        Object.keys(item).forEach(function (key) {
                            if (item[key] == 0 && key != "Brokerage") {
                                findingzero = `Sale ${key} should not be Zero `
                                isValid = false
                            }
                        });
                    }
                })
            }

            if (isValid) {
                //  let BrokerageValidation = true
                // if (BrokerageValidation) {
                BrokerageValidation = this.state.saleValue.some((value) => {
                    // 
                    return 2 * value.Quantity * value.Price / 100 > value.Brokerage
                })
                // }
                // if (isValid) {
                isValid = BrokerageValidation
                // console.log(BrokerageValidation)
                // }
                this.setState({ saleerror: BrokerageValidation === false ? "Sale Brokerage Should be less than 2% of total Amount" : "" })

            }
        }
        //  
        // console.log(isValid)
        if (isValid == true) {
            let saleValueArray = this.state.saleValue.map((item) => {
                return { ...item, StockTradeEntryID: 0 }
            })
            let purchaseValueArray = this.state.purchaseValue.map((item) => {
                return { ...item, StockTradeEntryID: 0 }
            })
            var data = JSON.stringify({
                "StockID": 0, "ClientID": this.props.clientID,
                "StockTypeID": this.state.isStock ? 1 : 2, "TradeDate": this.state.stockdate,
                "BrokerID": this.state.BrokerID, "ContractNoteNo": this.state.contractnumber,
                "SettlementNo": this.state.settlementnumber, "STT": Number(replaceComma(this.state.STT)), "StampCharges": Number(replaceComma(this.state.stampChanges)), "OtherCharges": Number(replaceComma(this.state.otherChanges)), "GSTTax": Number(replaceComma(this.state.GST)), "TransactionCharges": Number(replaceComma(this.state.transactionChanges)), "TotalAmount": this.state.totalamount, "StockTradedBondTradeEntries": this.state.saleValue.length != 0 ? JSON.stringify([...purchaseValueArray, ...saleValueArray]) : JSON.stringify([...purchaseValueArray])
            });
            // 
            this.setState({ loader: true })
            getAllStocksandTradeDetails(data).then((response) => {
                // 
                if (response.status === "S") {
                    this.props.getL5reportDetails(this.props.clientID)
                    this.setState({ loader: false, opensnackbar: true, message: "Transaction Added Successfully", severity: "success" })
                    this.handleClear();
                    // this.setState({loader:true})
                } else {
                    this.setState({ loader: false, opensnackbar: true, message: response.message, severity: "warning" })

                }
            })
        } else {
            if (findingzero != "") {
                this.setState({ opensnackbar: true, message: findingzero, severity: "warning" })
            }

        }

    }
    ///***datepicker functions
    ///----start-----
    handleClick = (e) => {
        this.setState({ anchorEl: e.currentTarget })
        //
    }
    handleApply = (date) => {
        this.setState({ stockdate: moment(date).format("DD MMM YYYY"), anchorEl: null, stockerror: "" })
    }
    handleClose = () => {
        this.setState({ anchorEl: null })
    }
    dateChange = (value) => {
        this.setState({ stockdatetemp: moment(value).format("DD MMM YYYY") })
    }
    ///----end----

    ///***broker dropdown functions
    ///---start----
    handleBrokerChange = (e) => {
        ///
        this.setState({ broker: e[0].label, BrokerID: e[0].value, brokererror: "" })
    }
    ///---end---

    ///*** contract number  
    //---start---
    handleContractChange = (e) => {
        this.setState({ contractnumber: e.target.value, contractnumbererror: "" })
        if (notAllowdSpecialCharater(e.target.value)) {
            this.setState({ contractnumber: e.target.value, contractnumbererror: "" })
        }
    }
    ///----end----

    ///*** settlement number
    //---start---
    handleSettlementChange = (e) => {
        if (notAllowdSpecialCharater(e.target.value)) {
            this.setState({ settlementnumber: e.target.value, settlementnumbererror: "" })
        }
    }
    ///---end---
    handleSTTChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnly(removeComma)) {
            this.setState({ STT: amountCommaSeperator(removeComma), STTerror: "" })

        }
    }
    handleStampChanges = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnly(removeComma)) {
            this.setState({ stampChanges: amountCommaSeperator(removeComma), stampChangesError: "" })
        }
    }
    handleOtherChanges = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma)) {
            this.setState({ otherChanges: amountCommaSeperator(removeComma), otherChangesError: "" })
        }
    }
    handleGSTChanges = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnly(removeComma)) {
            this.setState({ GST: amountCommaSeperator(removeComma), GSTerror: "" })
        }

    }
    handleTransactionChanges = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnly(removeComma)) {
            this.setState({ transactionChanges: amountCommaSeperator(removeComma), transactionChangesError: "" })
        }

    }
    // handle
    //Add rows
    addFormFields() {
        let isValid = true;
        isValid = this.state.purchaseValue.some(hasNull) === true ? false : true
        let a = "";
        //  
        if (isValid) {
            this.state.purchaseValue.map((item) => {
                if (a == "") {
                    Object.keys(item).forEach(function (key) {
                        if (item[key] == 0 && key != "Brokerage") {
                            a = key
                            isValid = false
                        }
                    });
                }
            })
        }
        if (isValid === true) {
            this.setState(({
                formValues: [...this.state.formValues, {
                    AssetID: {
                        name: "",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "Type Scrip Name",
                        style: "name",
                        label: "Asset Name"
                    }, Quantity: {
                        name: "0",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "0.00",
                        style: "amount",
                        label: "Quantity"
                    }, Price: {
                        name: "0",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "0.00",
                        style: "amount",
                        label: "Price"
                    }, Brokerage: {
                        name: "0",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "0.00",
                        style: "amount",
                        label: "Brokerage"
                    }, TotalAmount: {
                        name: "0",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "0.00", style: "amount",
                        label: "Total Amount"
                    }
                }],
                purchaseValue: [...this.state.purchaseValue, {
                    AssetID: "", TypeID: 1, Quantity: "", Price: "", Brokerage: "", TotalAmount: ""
                }],
                autoCompleteData: []
            }))
        } else {
            if (a == "") {
                this.setState({ purchaseerror: "All fields are mandatory" })

            } else {
                this.setState({ opensnackbar: true, message: `Purchase ${a} should not be zero` })
            }

        }


    }
    handletoastClose = () => {
        this.setState({ opensnackbar: false })
    }
    addFormSaleFields() {
        let isValid = true;
        isValid = this.state.saleValue.length == 0 ? true : this.state.saleValue.some(hasNull) === true ? false : true

        let a = "";
        if (isValid) {

            this.state.saleValue.map((item) => {
                if (a == "") {
                    Object.keys(item).forEach(function (key) {
                        //   
                        if (item[key] == 0 && key != "Brokerage") {
                            a = key
                            isValid = false
                        }
                    });
                }
            })
        }
        if (isValid === true) {
            this.setState(({
                Sale: [...this.state.Sale, {
                    AssetID: {
                        name: "",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "Type Scrip Name",
                        style: "name",
                        label: "Asset Name"
                    }, Quantity: {
                        name: "0",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "0.00",
                        style: "amount",
                        label: "Quantity"
                    }, Price: {
                        name: "0",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "0.00",
                        style: "amount",
                        label: "Price"
                    }, Brokerage: {
                        name: "0",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "0.00",
                        style: "amount",
                        label: "Brokerage"
                    }, TotalAmount: {
                        name: "0",
                        errormessage: "",
                        isClicked: false,
                        placeholder: "0.00", style: "amount",
                        label: "Total Amount"
                    }
                }],
                saleValue: [...this.state.saleValue, { AssetID: "", TypeID: 2, Quantity: "", Price: "", Brokerage: "", TotalAmount: "" }
                ],
                autoCompleteData: []

            }))
        } else {
            if (a == "") {
                this.setState({ saleerror: "All fields are mandatory" })

            } else {
                this.setState({ opensnackbar: true, message: `Sale ${a} should not be zero` })
            }
        }
        // } else {

        // }

    }
    handleDecimal = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (e.target.value != "") {
            // 
            this.setState({ [e.target.name]: amountCommaSeperator(round(removeComma, 2)) })
        }

    }
    handleFocus = (event) => event.target.select();
    handleClear = () => {
        this.setState({
            stockerror: "",
            stockdate: "",
            stockdatetemp: moment().format("DD MMM YYYY"),
            anchorEl: null,
            broker: "",
            BrokerID: "",
            brokererror: "",
            contractnumber: "",
            contractnumbererror: "",
            settlementnumber: "",
            settlementnumbererror: "",
            STT: "",
            STTerror: "",
            stampChanges: "",
            stampChangesError: "",
            otherChanges: "",
            otherChangesError: "",
            GST: "",
            GSTerror: "",
            transactionChanges: "",
            transactionChangesError: "",
            totalamount: "",
            totalamounterror: "",
            showlabel: true,
            Sale: [],
            formValues: [],
            formValues: [{
                AssetID: {
                    name: "",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "Type Scrip Name",
                    style: "name",
                    label: "Asset Name"
                }, Quantity: {
                    name: "0",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "0.00",
                    style: "amount",
                    label: "Quantity"
                }, Price: {
                    name: "0",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "0.00",
                    style: "amount",
                    label: "Price"
                }, Brokerage: {
                    name: "0",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "0.00",
                    style: "amount",
                    label: "Brokerage"
                }, TotalAmount: {
                    name: "0",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "0.00", style: "amount",
                    label: "Total Amount"
                }
            }],
            purchaseTotalAmount: 0,
            saleTotalAmount: 0,
            purchaseValue: [],
            purchaseValue: [{
                AssetID: "", TypeID: 1, Quantity: "", Price: "", Brokerage: "", TotalAmount: ""
            }],
            autoCompleteData: [],
            saleValue: [],
            purchaseerror: "",
            saleerror: "",
            //isStock: true,
            // loader: false,
            // opensnackbar: false,
            // message: "",
            // severity: "warning"
        })   // purchase: [{ "Asset": "", "Quantity": "", "Price": "", "Brokerage": "", "Amount": "" }]

    }
    render() {
        const { classes } = this.props;
        //   
        try {
            return (
                <>
                    {this.state.isStock == true ?
                        <Subheader heading={labels.StocksandTrade.stocks} description={labels.Transact.stockssubContent} />
                        :
                        <Subheader heading={labels.StocksandTrade.tradedbond} description={labels.Transact.tradebondsContent} />
                    }
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ width: "12%" }}></div>
                        <div className={classes.card} style={{ display: "flex", marginBottom: "3%" }} ref={this.myDiv}>
                            <div className={classes.mobileresponsiveWidthadjust}></div>
                            <div className={classes.stocksmaindiv}>
                                <div className={classes.firstContainer}>
                                    <div style={{ width: "100%" }}>
                                        <DatePicker
                                            handleClick={(e) => this.handleClick(e)}
                                            handleApply={this.handleApply}
                                            showingdate={this.state.stockdatetemp}
                                            dateChange={this.dateChange}
                                            handleClose={this.handleClose}
                                            anchorEl={this.state.anchorEl}
                                            error={this.state.stockerror}
                                            maxdate={moment().format("DD MMM YYYY")}
                                            // allowed={false} 
                                            label={labels.StocksandTrade.date}
                                            placeholder={labels.StocksandTrade.selectDate}
                                            date={this.state.stockdate} />

                                    </div>
                                    <div className={classes.Spacing}>
                                        <CommonDropdown
                                            //  autoselect={true}
                                            handleOnChange={this.handleBrokerChange}
                                            //  values={this.state.BankAccounttype}
                                            error={this.state.brokererror}
                                            type={"normalwithsearch"}
                                            placeholder={"Select Broker"}
                                            label={labels.StocksandTrade.broker}
                                            height={190}

                                            List={this.props.brokerList}
                                            value={this.state.broker}
                                        /></div>
                                    <div className={classes.Spacing}>
                                        <Textinput handleChange={this.handleContractChange}
                                            value={this.state.contractnumber}
                                            error={this.state.contractnumbererror}
                                            page={"normal"} label={labels.StocksandTrade.contractNo}
                                            placeholder={labels.StocksandTrade.placeholderContractNo}
                                        /></div>
                                    <div className={classes.Spacing}>

                                        <Textinput handleChange={this.handleSettlementChange} value={this.state.settlementnumber}
                                            error={this.state.settlementnumbererror} page={"normal"}
                                            label={labels.StocksandTrade.settlementNo}
                                            placeholder={labels.StocksandTrade.placeholderSettlementNo} />
                                    </div>
                                </div>
                                <div >
                                    <div className={classes.scendHeader}>{labels.StocksandTrade.purchase}</div>
                                    <div className={classes.cardSpareaterLine}></div>
                                    <div className={[classes.tableHeader, classes.tableMainHeader, classes.ContainerWithWidth, classes.tableHeaderDisplay].join(' ')}>
                                        <div style={{ textAlign: "left", marginLeft: 10 }}>
                                            {labels.StocksandTrade.assetname}
                                        </div>
                                        <div style={{ textAlign: "end", marginRight: 10 }}>
                                            {labels.StocksandTrade.purchaseQty}
                                        </div>
                                        <div style={{ textAlign: "end", marginRight: 10 }}>
                                            {labels.StocksandTrade.purchasePrice}
                                        </div>
                                        <div style={{ textAlign: "end", marginRight: 10 }}>
                                            {labels.StocksandTrade.brokerage}
                                        </div>
                                        <div style={{ textAlign: "end", marginRight: 10 }}>
                                            {labels.StocksandTrade.purchaseAmount}
                                        </div>
                                    </div>
                                    <div className={[classes.MainHeader, classes.tableHeader].join(' ')} style={{ marginLeft: "1.5%" }}>
                                        <div style={{ textAlign: "left", marginLeft: 10 }}>{labels.StocksandTrade.purchase}</div>
                                    </div>
                                    <Dynamic_row tooltipTitle="Delete Purchase" index={10} preventDelete={0} handleOnChange={(e) => this.handleOnChange(e)} autoCompleteData={this.state.autoCompleteData} class={classes.ContainerWithWidth} callBack={this.callBackPurchase} data={this.state.purchaseValue} List={this.state.formValues} count={5} />
                                    <div style={{ display: "flex", flexDirection: "column", }}>
                                        <div style={{ color: window.globalConfig.color.red, fontSize: 13, textAlign: "left", minHeight: 12, padding: "10px 10px 2px 10px", minHeight: '20px' }}>{this.state.purchaseerror}</div>
                                        <div style={{ textAlignLast: "justify", marginLeft: 10, marginTop: 10 }}>
                                            <OutlinedButton text={labels.StocksandTrade.addRow} onClick={() => this.addFormFields()} />
                                        </div>
                                    </div>
                                </div>
                                <div >
                                    <div className={classes.scendHeader}>{labels.StocksandTrade.sale}</div>
                                    <div className={classes.cardSpareaterLine}></div>
                                    <div className={[classes.tableHeader, classes.ContainerWithWidth, classes.tableHeaderDisplay].join(' ')}>
                                        <div style={{ textAlign: "left", marginLeft: 10 }}>
                                            {labels.StocksandTrade.assetname}
                                        </div>
                                        <div style={{ textAlign: "end", marginRight: 10 }}>
                                            {labels.StocksandTrade.saleQTY}
                                        </div>
                                        <div style={{ textAlign: "end", marginRight: 10 }}>
                                            {labels.StocksandTrade.sale}
                                        </div>
                                        <div style={{ textAlign: "end", marginRight: 10 }}>
                                            {labels.StocksandTrade.brokerage}
                                        </div>
                                        <div style={{ textAlign: "end", marginRight: 10 }}>
                                            {labels.StocksandTrade.saleAmount}
                                        </div>
                                    </div>
                                    <div className={[classes.MainHeader, classes.tableHeader].join(' ')} style={{ marginLeft: "1.5%" }}>
                                        <div style={{ textAlign: "left", marginLeft: 10 }}>{labels.StocksandTrade.sale}</div>
                                    </div>
                                    <Dynamic_row tooltipTitle="Delete Sale" index={1000} preventDelete={""} handleOnChange={(e) => this.handleOnChange(e)} autoCompleteData={this.state.autoCompleteData} class={classes.ContainerWithWidth} callBack={this.callBackSale} data={this.state.saleValue} List={this.state.Sale} count={5} />

                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                        <div style={{ color: window.globalConfig.color.red, fontSize: 13, textAlign: "left", minHeight: 12, padding: "10px 10px 2px 10px" }}>{this.state.saleerror}</div>
                                        <div style={{ textAlignLast: "justify", marginLeft: 10, marginTop: 10 }}>
                                            <OutlinedButton text={labels.StocksandTrade.addRow} onClick={() => this.addFormSaleFields()} />
                                        </div>
                                    </div>
                                </div>
                                <div style={{ marginTop: "2%" }} className={[classes.LastContainer].join(' ')}>
                                    <div className={classes.LastContainerPrimaryWidth}>
                                        <Textinput handleChange={this.handleSTTChange}
                                            paddingAlign="5px"
                                            value={this.state.STT} error={this.state.STTerror}
                                            page={"normal"} label={labels.StocksandTrade.STT}
                                            align={"right"}
                                            otherProps={{ autoFocus: true, name: "STT", onFocus: this.handleFocus, onBlur: this.handleDecimal }}
                                            placeholder={"0.00"}
                                        />
                                    </div>
                                    <div className={classes.LastContainerSecondaryWidth}>
                                        <Textinput handleChange={this.handleStampChanges}
                                            paddingAlign="5px"
                                            value={this.state.stampChanges} error={this.state.stampChangesError}
                                            page={"normal"} label={labels.StocksandTrade.stampChanges}
                                            align={"right"}
                                            otherProps={{ autoFocus: true, name: "stampChanges", onFocus: this.handleFocus, onBlur: this.handleDecimal }}
                                            placeholder={"0.00"}
                                        />
                                    </div>
                                    <div className={classes.LastContainerSecondaryWidth} style={{ marginLeft: 7 }}>
                                        <Textinput handleChange={this.handleOtherChanges}
                                            paddingAlign="5px"
                                            value={this.state.otherChanges} error={this.state.otherChangesError}
                                            page={"normal"} label={labels.StocksandTrade.otherChanges}
                                            align={"right"}
                                            otherProps={{ autoFocus: true, name: "otherChanges", onFocus: this.handleFocus, onBlur: this.handleDecimal }}
                                            placeholder={"0.00"}
                                        />
                                    </div>
                                </div>
                                <div style={{ marginTop: "2%" }} className={[classes.LastContainer].join(' ')}>
                                    <div className={classes.LastContainerPrimaryWidth}>
                                        <Textinput handleChange={this.handleGSTChanges}
                                            paddingAlign="5px"
                                            placeholder={"0.00"}
                                            value={this.state.GST} error={this.state.GSTerror}
                                            page={"normal"} label={labels.StocksandTrade.GST}
                                            align={"right"}
                                            otherProps={{ autoFocus: true, name: "GST", onFocus: this.handleFocus, onBlur: this.handleDecimal }}
                                        /></div>
                                    <div className={classes.LastContainerSecondaryWidth}>
                                        <Textinput handleChange={this.handleTransactionChanges}
                                            paddingAlign="5px"
                                            value={this.state.transactionChanges} error={this.state.transactionChangesError}
                                            page={"normal"} label={labels.StocksandTrade.transactionChanges}
                                            placeholder={"0.00"}
                                            align={"right"}
                                            otherProps={{ autoFocus: true, name: "transactionChanges", onFocus: this.handleFocus, onBlur: this.handleDecimal }}

                                        /></div>
                                    <div className={classes.LastContainerSecondaryWidth} style={{ marginLeft: 7 }}>
                                        <Textinput
                                            paddingAlign="5px"
                                            value={Number(Math.abs(this.state.totalamount).toFixed(2)).toLocaleString("en-IN")} error={this.state.totalamounterror}
                                            page={"normal"} label={labels.StocksandTrade.totalamount}
                                            placeholder={"0.00"}
                                            align={"right"}
                                            message={Math.sign(Number(this.state.totalamount)) === -1 ? "(Payable)" : "(Receivable)"}
                                            otherProps={{ autoFocus: true, name: "totalamount", onFocus: this.handleFocus }}

                                        /></div>
                                </div>
                                <div style={{ marginTop: "2%", display: "flex", flexDirection: "row", marginBottom: "2%", justifyContent: "flex-end" }} >
                                    <div className={classes.buttonStyle}>
                                        <CommonButton name={"Cancel"} onClick={this.handleClear} />
                                    </div>
                                    <div style={{ marginLeft: "5%" }} className={classes.buttonStyle}>
                                        <CommonButton name={"Save"} page={"primary"}
                                            loader={this.state.loader}
                                            loading={"true"}
                                            onClick={this.handleSubmit} /></div>

                                </div>
                            </div>
                            <CommonSnackbar message={this.state.message} open={this.state.opensnackbar} severity={this.state.severity} handlesnanckClose={this.handletoastClose} />
                        </div>
                        <div style={{ width: "12%" }}></div>

                    </div>
                </>
            )

        } catch (e) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => {
    //
    return {
        sessionID: state.Login.sessionID,
        clientID: state.Login.logindetails.ClientID,
        categoryFilterlist: state.Dropdown.dropdowndetails == null || state.Dropdown.dropdowndetails.assetClass == undefined || state.Dropdown.dropdowndetails.assetClass == null ? [] : state.Dropdown.dropdowndetails.assetClass.map(val => {
            return { "label": val.AssetClass, "value": val.ID, "isChecked": false }
        }),
        brokerList: state.Dataentry.brokerList != null && state.Dataentry.brokerList.length != 0 ? state.Dataentry.brokerList.map((item) => {
            return { "label": item.BrokerName, "value": item.BrokerID }
        }) : []
        // userID: state.Login.userID,
        // date: state.Login.date,
        // logindetails: state.Login.logindetails,
        // assetallocationgrouped: state.Asset.assetallocationgrouped,
        // totalassetallocation: state.Asset.totalassetallocation,
        // assetallocation: state.Asset.assetallocation,
        // isAssetAllocationLoading: state.Asset.isAssetAllocationLoading,
        // isNetworkLow: state.Asset.isNetworkLow,
        // L5ID: state.Login.L5ID,

    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getL5reportDetails }, dispatch)
}
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(Stocks))
