import * as types from '../../constants/actionsType';
export const storeGstNumberBankDetails = (data) => {
   // 
    if (data != undefined) {   
        return {
            type: types.STORE_GST_BANK_DETAILS,
            payload: data

        }
    }
    else {      
        return {
            type: types.STORE_GST_BANK_DETAILS,
            payload: []

        }
    }

}