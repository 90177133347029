import * as types from '../../constants/actionsType';
import getAllCapitalGainDetails from '../../../api/capitalgain/capitalgain'
import constants from '../../../utils/constants/apiconstant';
import getAllDashboardAssetAllocation from '../../../api/dashboard/assetallocationapi';
import { replaceNull } from '../../../utils/commonfunction';
export function getDashboardAssetAllocation(ClientID, Date) {
    return (dispatch) => {
        getDashboardAssetAllocationAction(dispatch, ClientID, Date);
    };
}
function getDashboardAssetAllocationAction(dispatch, ClientID, Date) {

  
    dispatch({
        type: types.LOADER_DASHBOARD_ASSET,
        payload: true,
    });
    getAllDashboardAssetAllocation(ClientID, Date)
        .then((res) => {
            let data = replaceNull(res, "NA")
            // 
            try {
                dispatch({
                    type: types.LOADER_DASHBOARD_ASSET,
                    payload: false,
                });

                if (data.status == "S") {
                    dispatch({
                        type: types.PREVENT_API_CALL,
                        payload: [{ ProductID: 1, api: "Dashboard" }],
                    });
                    dispatch({
                        type: types.DASHBOARD_ASSET,
                        payload: replaceNull(data, "NA"),
                    });

                } else {
                    // dispatch({
                    //     type: types.DASHBOARD_ASSET,
                    //     payload: [],
                    // });

                }
            } catch (err) {
                dispatch({
                    type: types.DASHBOARD_ASSET,
                    payload: [],
                });

            }


        })
        .catch((error) => {
            dispatch({
                type: types.LOADER_DASHBOARD_ASSET,
                payload: false,
            });
            // dispatch({
            //     type: types.PREVENT_API_CALL,
            //     payload: [{ ProductID: 1, api: "Dashboard" }],
            // });
            dispatch({
                type: types.EMPTY_DASHBOARD_ASSET,
                payload: [],
            });

        })
}