import React, { useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router";
import { Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Button from '../../components/custombutton/button'
import moment from 'moment';
import MyPlanCard from './myPlanCard'
import ErrorBoundary from '../Errorhandling/Errorhandle';
import GetMyPlanDetailsapi from '../../api/profile/myplanapi';
import { NegativeFinding, replaceNull } from '../../utils/commonfunction';
import NoData from '../nodata/nodata';
import Loader from '../loader/loader';
import { v4 as uuid } from 'uuid';
import { scrollToTopPage } from '../../utils/commonfunction';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Snackbar from '../snackbar/snackbar';
import axios from 'axios';
import fileDownload from 'js-file-download';
import Dialog from '../dialog/dialog';
import RadioButtons from '../radiobutton/radiobutton';
import getSubscriptionAutoCharge from '../../api/subscription/autochargeapi';
const Styles = (theme) => ({



    download: {
        fontSize: 12,
        borderRadius: 5,
        border: `1px solid ${colors.GREEN}`,
        color: colors.GREEN,
        width: "fit-content",
        padding: 5,
        cursor: "pointer"
    },

    secondGrid: {
        display: 'grid',
        gap: 13,
        gridTemplateColumns: "repeat(6,1fr)",
        textAlign: "initial",
        padding: '13px 33px',
        fontWeight: "bold",
        "& .dataFour": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            textTransform: "capitalize"
        }

    },
    root: {
        width: "100%"
    },
    btn: {
        width: "fit-content"
    },
    myPlan: {
        textAlign: "left",
        // paddingBottom: "12px",
        marginBottom: "13px",

        fontSize: "21px",
        color: colors.BLACK_PRIMARY,
        fontWeight: "bolder",
        paddingTop: "7px"
    },
    subscriptionSecondGrid: {
        display: 'grid',
        gap: 13,
        gridTemplateColumns: "repeat(5,1fr)",
        textAlign: "initial",
        padding: '13px 33px',
        fontWeight: "bold",
        "& .dataFour": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: 10,
            textTransform: "capitalize"
        }

    },
    success: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: colors.GREEN
    },
    pending: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: colors.WARNING
    },
    failure: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: colors.red
    },
    normal: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: colors.BLACK_PRIMARY
    },
    header: {
        color: window.globalConfig.color.BLACK_PRIMARY, textAlign: 'left', fontSize: 'x-large'
    },
    subHeader: {
        margin: 0, textAlign: 'left', fontSize: 'smaller', fontWeight: 'bolder', color: window.globalConfig.color.BLACK_PRIMARY
    },
    shortLine: {
        height: '3.5px', width: '5%', marginTop: 10, backgroundColor: window.globalConfig.color.GREEN
    },
    longLine: {
        height: 0.5, backgroundColor: window.globalConfig.color.PRIMARY_BORDER, borderBottom: 10, marginBottom: 10, width: 'auto'
    }
})
class PurchasedPlan extends React.Component {
    constructor(props) {
        super(props);
        this.myPlanCard = React.createRef(null)
        this.state = {
            isLoading: false,
            data: [],
            active: [],
            subscriptionHistory: [],
            open: false,
            message: "",
            severity: "warning",
            planDetails: [],
            openDialog: false,
            MandateId: 0,
            list: {}
        }
    }

    componentDidMount() {
        const { classes } = this.props
        scrollToTopPage();
        this.getPlanDetails()
        if (Boolean(this.props.mandateList) && this.props.mandateList.length != 0) {

            this.setState({ MandateId: this.props.mandateList[0].MandateId.toString() })
        }
    }
    getPlanDetails = () => {
        this.setState({ isLoading: true })
        GetMyPlanDetailsapi(this.props.clientId).then((res) => {
            this.setState({ isLoading: false })
            if (res.status == "S") {
                this.planDetails(res)
                this.setState({ planDetails: res })
            } else {
                this.setState({ planDetails: [] })
            }
        }).catch((err) => {
            this.setState({ isLoading: false })

        }
        )
    }
    planDetails = (res, selected) => {
        const { classes } = this.props
        let active = res.table2 == null || res.table2.length == 0 ? [] : replaceNull(res.table2, labels.messages.NA).map((item) => {
            return {
                invoiceData: res.table4 == null || res.table4.length == 0 ? [] : replaceNull(res.table4, labels.messages.NA).filter((val) => val.SubscriptionID === item.ID).map((value, index) => {
                    return { ID: index + 1, invoiceDate: moment(value.InvoiceDate).format("DD MMM YYYY"), invoiceHash: value.InvoiceNo, Amount: NegativeFinding(value.Amount, "", "", "", "rupee"), Status: value.EnumCode, Invoice: value.DownloadInvoice, Pay: value.URL, dataFive: <div className={classes.download} style={{ opacity: value.EnumCode.toLowerCase() == "delete" || value.ID == selected ? 0.5 : 1 }} onClick={() => value.EnumCode.toLowerCase() == "delete" ? "" : this.downloadInvoice(value)}>Download</div>, dataSix: <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10, backgroundColor: colors.PRIMARY, color: colors.WHITE, borderRadius: 4, padding: 3, width: "fit-content", cursor: value.EnumCode?.toLowerCase() == "overdue" ? "pointer" : "not-allowed", opacity: value.EnumCode?.toLowerCase() == "overdue" ? 1 : 0.5 }} onClick={() => value.EnumCode?.toLowerCase() == "overdue" ? window.open(value.URL) : ""}> <div style={{ fontSize: "smaller" }}>Proceed</div>  <CreditCardIcon style={{ fontSize: "larger" }}></CreditCardIcon></div> }

                }), IsAutoChargeEnable: item.IsAutoChargeEnable, MandateID: item.MandateID, SubscriptionNo: item.ID, planNumber: item.PlanCode, startedDate: item.StartOn == "NA" ? labels.messages.NA : moment(item.StartOn).format("DD MMM YYYY"), status: item.STATUS, renewalDate: item.RenewalDate == "NA" ? labels.messages.NA : moment(item.RenewalDate).format("DD MMM YYYY"), planName: item.PlanName, planDescription: item.Description, yearlySubscriptionAmount: Number(item.Amount), yearlySubscriptionType: item.Subscriptions, invoiceID: item.ID
            }
        })
        if (active.length == 0) {
            this.props.history.push(labels.Path.MyPlan, {
                state: { data: true }
            })
        }
        let subscription = res.table3 == null || res.table3.length == 0 ? [] : replaceNull(res.table3, labels.messages.NA).map((item) => {
            return {
                invoiceData: res.table4 == null || res.table4.length == 0 ? [] : replaceNull(res.table4, labels.messages.NA).filter((val) => val.SubscriptionID === item.ID).map((value, index) => {
                    return { ID: index + 1, invoiceDate: moment(value.InvoiceDate).format("DD MMM YYYY"), pdfLoading: selected == value.ID ? true : false, invoiceHash: value.InvoiceNo, Amount: NegativeFinding(value.Amount, "", "", "", "rupee"), Status: value.EnumCode, Invoice: value.DownloadInvoice, Pay: value.URL, dataFive: <div className={classes.download} style={{ opacity: value.EnumCode.toLowerCase() == "delete" || value.ID == selected ? 0.5 : 1, cursor: value.EnumCode.toLowerCase() == "delete" ? "not-allowed" : "pointer" }} onClick={() => value.EnumCode.toLowerCase() == "delete" ? "" : this.downloadInvoice(value)}>Download</div>, }
                }), SubscriptionNo: item.ID, planNumber: item.PlanCode, startedDate: item.StartOn == "NA" ? labels.messages.NA : moment(item.StartOn).format("DD MMM YYYY"), status: item.STATUS, renewalDate: item.RenewalDate == "NA" ? labels.messages.NA : moment(item.RenewalDate).format("DD MMM YYYY"), planName: item.PlanName, planDescription: item.Description, yearlySubscriptionAmount: Number(item.Amount), yearlySubscriptionType: item.Subscriptions, invoiceID: item.ID
            }
        })
        this.setState({ active: active, subscriptionHistory: subscription })

    }
    downloadInvoice = (item) => {
        this.planDetails(this.state.planDetails, item.ID)
        if (!item.pdfLoading) {
            this.planDetails(this.state.planDetails, item.ID)
            axios(`${item.DownloadInvoice}`, {
                method: "GET",
                responseType: "blob", //Force to receive data in a Blob Format
            })
                .then((response) => {
                    this.planDetails(this.state.planDetails)
                    //Create a Blob from the PDF Stream
                    const file = new Blob([response.data], {
                        type: `${"application/"}${"pdf"}`,
                    });
                    const fileURL = URL.createObjectURL(file);
                    //Open the URL on new Window
                    window.open(fileURL);
                    fileDownload(
                        response.data,
                        "DownloadInvoice.pdf"
                    );
                })
                .catch((error) => {
                    this.setState({ open: true, message: labels.messages.apierror, severity: "warning" })

                });
        }
    }
    handleDialogBox = () => {
        if (this.props.mandateList.length != 0) {

            this.setState({ MandateId: this.props.mandateList[0].MandateId.toString() })
        }
        this.setState({ openDialog: !this.state.openDialog })
    }
    handleChange = (event,) => {
        // let item = this.props.mandateList.filter((value) => value.MandateId == event.target.value)
        this.setState({ MandateId: event.target.value })
    }
    autoCharge = (item) => {
        this.handleDialogBox()
        this.setState({ list: item })
    }
    onClickMandate = () => {
        this.setState({ loader: true })
        getSubscriptionAutoCharge(this.state.list?.SubscriptionNo, this.state.list?.IsAutoChargeEnable ? this.state.list?.MandateId : this.state.MandateId, this.props.logindetails.ClientID, this.state.list?.IsAutoChargeEnable).then((res) => {
            this.setState({ loader: false })
            if (res.status == "S") {
                this.getPlanDetails()
            }
            this.handleDialogBox()
        }).catch((err) => {
            this.setState({ loader: false })

        })
    }
    render() {
        const { classes } = this.props;
        try {
            return (
                <ErrorBoundary>
                    <div style={{ margin: "10px 40px 0px 13px" }}>
                        <div className={classes.myPlan}>{labels.PurchasedPlan.myPlan}</div>
                        <p className={classes.subHeader}>{labels.PurchasedPlan.activeSubscriptions}</p>
                        <div className={classes.shortLine}></div>
                        <div className={classes.longLine}></div>
                        <br />
                        
                        {this.state.isLoading ? <Loader page="reportmodel"></Loader> : this.state.active.length == 0 ? <NoData></NoData> : this.state.active.map((item) => {
                            return <MyPlanCard style={classes} pageName={"active"} list={item} onClickAutoCharge={() => this.autoCharge(item)} count={6} myPlanCard={this.myPlanCard} header={[{ "name": "Invoice Date", align: "center", type: "text" },
                            { "name": "Invoice#", align: "start", type: "text" },
                            { "name": "Amount", align: "start", type: "amount" },
                            { "name": "Status", align: "start", type: "text" },
                            { "name": "Invoice", align: "start", type: "outline button", content: labels.PurchasedPlan.download, flag: uuid() },
                            { "name": "Pay", align: "start", type: "solid button", content: "Proceed", disable: false }]} />

                        })}
                        <br />

                        <p className={classes.subHeader}>{labels.PurchasedPlan.subscriptionHistory}</p>
                        <div className={classes.shortLine}></div>
                        <div className={classes.longLine}></div>
                        <br />
                        {this.state.isLoading ? <Loader page="reportmodel"></Loader> : this.state.subscriptionHistory.length == 0 ? <NoData></NoData> : this.state.subscriptionHistory.map((item) => {
                            return <MyPlanCard style={{ secondGrid: classes.subscriptionSecondGrid }} pageName={"history"} list={item} count={6} myPlanCard={this.myPlanCard} header={[{ "name": "Invoice Date", align: "center", type: "text" },
                            { "name": "Invoice#", align: "center", type: "text" },
                            { "name": "Amount", align: "center", type: "amount" },
                            { "name": "Status", align: "center", type: "text" },
                            { "name": "Invoice", align: "center", type: "outline button", flag: uuid(), content: labels.PurchasedPlan.download },
                                // { "name": "Pay", align: "center", type: "solid button", content: labels.PurchasedPlan.proceed, disable: true }
                            ]} />

                        })}
                        <Snackbar open={this.state.open} severity={this.state.severity} message={this.state.message}></Snackbar>
                        <Dialog open={this.state.openDialog} root={classes.root} header={<div style={{ backgroundColor: colors.PRIMARY, padding: 10, color: colors.WHITE, display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                            <div>Auto Charge</div>
                            <div 
                            // onClick={this.handleDialogBox}
                            //  style={{ cursor: "pointer" }}
                             ><CloseIcon /></div>
                        </div>} 
                        
                        // handleClose={this.handleDialogBox}
                        >
                            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
                                <RadioButtons List={Boolean(this.props.mandateList) && this.props.mandateList.length != 0 ? this.props.mandateList.map((item) => {
                                    return { label: `${item.MandateId} (${item.BankName}-${item.BankAccNo})`, value: item.MandateId.toString() }
                                }) : []} value={this.state.MandateId} handleChange={(e) => this.handleChange(e)}></RadioButtons>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                                    <Button page="linkButton" name="Add Mandate" onClick={() =>
                                        this.props.handleChange("", 1)
                                    }></Button>
                                    <Button className={classes.btn} page="primary" loader={this.state.loader} disabled={this.state.disabled} name="Enable" onClick={this.onClickMandate
                                    } />
                                </div>
                            </div>
                        </Dialog>
                    </div>
                </ErrorBoundary>
            )
        } catch (err) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => ({
    clientId: state.Login.logindetails.ClientID,
    logindetails: state.Login.logindetails,
    // mandateDetails: state.ProfileDetails.AllProfileDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.mandateDetails != "NA" ? state.ProfileDetails.AllProfileDetails.mandateDetails.map((item) => {
    //     return { label: `${item.MandateId} (${item.BankName}-${item.BankAccNo})`, value: item.MandateId }
    // }) : [] : [],

    purchasedPlans: state.Login.riaplandetails != null ? state.Login.riaplandetails.length != 0 ? state.Login.riaplandetails : [] : [],
    planFeatures: state.Login.planFeatures != null ? state.Login.planFeatures.length != 0 ? state.Login.planFeatures : [] : []
})
// const mapDispatchToProps = dispatch => {

// }
export default withStyles(Styles)(withRouter(connect(mapStateToProps)(PurchasedPlan)))
