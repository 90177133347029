import Apivalues from '../url/apiurl';
import axios from 'axios'
function getInitializeESignReportDetails(PlanCode, ClientID) {
  const BaseUrl = Apivalues.InitializeESign + `?PlanCode=${PlanCode}&L3ClientID=${ClientID}`
  return axios(BaseUrl, {
    method: 'GET',
  })
    .then(response => {
      return response;
    })
    .catch(error => {
      return { data: { status: "C" } }
    });

}
export default getInitializeESignReportDetails;