import React from 'react';
import Popup from "../../components/popup/deletepopup";
import CardMenu from '../../containers/cardmenu/cardmenu';
import { connect } from 'react-redux';
import { getTransactionDetailsListing } from '../../redux/actions/transactiondetails/transactiondetailsaction';
import { bindActionCreators } from 'redux';
import { SimilarSchemeDetails } from '../../redux/actions/schemes/similarschemesaction';
import { SchemeLinePerformationDetails } from '../../redux/actions/schemes/lineperformancecard'
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router'
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import colors from '../../utils/colors/colors';
import UCCPopup from "../../components/popup/uccpopup";
import { NegativeFinding, replaceNull, scrollToTopPage } from '../../utils/commonfunction';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import getTransactionDetails from '../../api/transactiondetails/transactiondetailsapi';
import Avatars from '../../components/avatar/avatar';
import getsimilarSchemeDetails from '../../api/schemedetails/similarschemes';
import Sidetabs from '../../components/trading/sidetabs';
import Schemeloader from '../../components/trading/schemeloader';
import Dialog from '../dialog/dialog';
import getKYCDetails from '../../api/kyc/kycapi';
const styles = theme => ({
    header: {
        fontSize: 21,
        fontWeight: 600,
        textAlign: "start",
        color: colors.BLACK_PRIMARY
    },

    // cardMenu: {
    //     marginBottom: "0%",
    //     marginLeft: "1.8%",
    //     marginRight: "1.8%",
    //     // height: 82,
    //     gridTemplateColumns: "18% 18% 15% 18% 18% 13%",
    //     alignItems: "baseline",
    //     // paddingTop: 10,
    //     borderBottomLeftRadius: 0,
    //     borderBottomRightRadius: 0,
    //     "& .data1": {
    //         height: "48px !important",
    //         padding: 10,
    //         borderRight: `1px solid ${colors.BORDER}`,
    //         [theme.breakpoints.down("900")]: {
    //             borderBottom: `1px solid ${colors.BORDER}`
    //         }
    //     },
    //     "& .data2": {
    //         // height: "0px !important",
    //         padding: 10,
    //         borderRight: `1px solid ${colors.BORDER}`,
    //         [theme.breakpoints.down("900")]: {
    //             borderBottom: `1px solid ${colors.BORDER}`
    //         }
    //     },
    //     "& .data3": {
    //         padding: 10,
    //         // height: "0px !important",
    //         borderRight: `1px solid ${colors.BORDER}`,
    //         [theme.breakpoints.down("900")]: {
    //             borderBottom: `1px solid ${colors.BORDER}`
    //         }
    //     },
    //     "& .data4": {
    //         padding: 10,
    //         // height: "0px !important",
    //         borderRight: `1px solid ${colors.BORDER}`,
    //         [theme.breakpoints.down("900")]: {
    //             borderBottom: `1px solid ${colors.BORDER}`
    //         }
    //     },
    //     "& .data5": {
    //         padding: 10,
    //         // height: "0px !important",
    //         borderRight: `1px solid ${colors.BORDER}`,
    //         [theme.breakpoints.down("900")]: {
    //             borderBottom: `1px solid ${colors.BORDER}`
    //         }
    //     },
    //     [theme.breakpoints.down("900")]: {
    //         gridTemplateColumns: "100%",

    //     },


    // },
    firstContainer: {
        backgroundColor: window.globalConfig.color.PRIMARY, display: "grid", gridTemplateColumns: "11% 78% 10%"
    },
    schemeAndNavSpliting: {
        display: "grid", gridTemplateColumns: "50% 50%",
        [theme.breakpoints.down("900")]: {
            display: "flex",
            flexDirection: "column",
            // gridTemplateColumns: "100%",
            alignItems: "center"
        }

    },
    schemeAndAvatar: {
        display: "flex", flexDirection: "row", alignItems: "center", gap: 10, padding: "15px 0px"
    },
    navRsAndNavDate: {
        display: "flex", flexDirection: "column", gap: 10, alignItems: "flex-end", padding: "15px 0px",
        [theme.breakpoints.down("900")]: {
            alignItems: "flex-start"
        }
    },
    bodyContainer: {
        display: "grid", gridTemplateColumns: "11% 78% 10%", marginBottom: "3%"
    },
    secondContainer: {
        boxShadow: "rgb(19 89 161 / 10%) 0px 13px 20px 0px", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, width: '100%', overflowY: "auto", overflowX: "hidden", borderTop: `1px solid ${colors.BORDER}`
    },
    thirdContainer: {
        boxShadow: "0 0 20px 0 rgba(19, 89, 161, 0.1)", borderRadius: 10, overflowY: "auto", overflowX: "hidden"
    },
    fourthContainer: {
        display: "grid", gridTemplateColumns: "49% 49%", gap: 20, marginTop: 10,
        [theme.breakpoints.down("900")]: {
            gridTemplateColumns: "100%",
        }
    },
    cardContainer: {
        // minHeight: "450px",
        boxShadow: "0 0 20px 0 rgba(19, 89, 161, 0.1)", borderRadius: 10, overflowY: "auto", overflowX: "hidden", marginTop: 10, padding: 10
    },
    cardThirdContainer: {
        minHeight: "300px", boxShadow: "0 0 20px 0 rgba(19, 89, 161, 0.1)", borderRadius: 10, overflowY: "auto", overflowX: "hidden"
    },
    performanceAndSideTab: {
        display: "grid", gridTemplateColumns: "66% 34%",
        [theme.breakpoints.down("900")]: {
            gridTemplateColumns: "100%",
        }
    }
})
// let LineChartData = []
class Tradingschemedetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showmore: false,
            Performance: [],
            open: true,
            dataLineChart: [],
            schemeCode: "",
            schemeID: "",
            similarSchemes: [],
            schemeDetail: [],
            openUcc: false,
            FundDetail: [],
            returns: [],
            assetAllocation: [],
            sectorAllocation: [],
            topHoldings: [],
            loader: false,
            loaderSimilarSchemes: false
        }
    }
    // getSchemeDetails = (schemecode) => {
    //     this.setState({ loader: true })
    //     getTransactionDetails(schemecode).then((response) => {
    //         let res = replaceNull(response, labels.messages.NA)
    //         this.setState({ loader: false })
    //         if (res != undefined || res != null) {
    //             this.setState({
    //                 cardData: [{ "firstName": "Min SIP Amount", "lastName": res.mF_FundDetail?.minSIPAmount }, { "firstName": "Fund Started", "lastName": res.mF_FundDetail?._InceptDate }, { "firstName": "Expense Ratio", "lastName": res.mF_FundDetail?.expenseRatio }, { "firstName": "Fund Manager", "lastName": res.mF_FundDetail?.fundManager }, { "firstName": "Fund Size (Rs. in CR)", "lastName": NegativeFinding(res.mF_FundDetail?.fundSize, "", "", "", "rupee") }, { 'firstName': "CAGR (3 yrs)", "lastName": res.schemeDetail?._threeYearReturn }],
    //                 schemeDetail: res.schemeDetail, FundDetail: res.mF_FundDetail,
    //                 topHoldings: res.topHoldings,
    //                 returns: res.mfReturns.slice(0, 2), assetAllocation: res.mF_AssetAllocation, sectorAllocation: res.mF_OtherInformations
    //             })
    //         } else {
    //             this.setState({
    //                 schemeDetail: [],
    //                 FundDetail: [],
    //                 returns: [],
    //                 assetAllocation: [],
    //                 sectorAllocation: [],
    //                 topHoldings: []
    //             })
    //         }
    //     })
    // }
    // getSimilarSchemes = (schemecode) => {
    //     this.setState({ loaderSimilarSchemes: true })
    //     getsimilarSchemeDetails(schemecode).then((res) => {
    //         this.setState({ loaderSimilarSchemes: false })

    //         if (res.success == true) {
    //             this.setState({ similarSchemes: JSON.parse(res.schemeData) })
    //         } else {
    //             this.setState({ similarSchemes: [] })
    //         }
    //     })
    // }
    // onClickSchemes = (schemecode) => {

    //     this.state.Performance = []


    //     this.props.SchemeLinePerformationDetails(schemecode)
    //     this.setState({ schemeCode: schemecode })
    //     this.getSimilarSchemes(schemecode)
    //     this.getSchemeDetails(schemecode)
    //     scrollToTopPage();
    // }
    handlekycyesclick = () => {
        getKYCDetails(this.props.logindetails.PhoneNumber,
            this.props.logindetails.EmailID,
            "").then((res) => {
                if (res.status == "F") {
                    this.setState({ openKyc: true })
                } else {
                    window.open(res.data)
                }
            })


    }
    openKycPopup = () => {
        this.setState({ openKyc: true })
    }
    openUccPopup = () => {
        this.setState({ openUcc: true })
    }

    componentDidMount() {

        // if (this.props.location.state != undefined) {
        //     this.setState({ schemeCode: this.props.location.state.data.CMOTSSchemeCode })
        //     this.getSchemeDetails(this.props.location.state.data.CMOTSSchemeCode)
        //     this.getSimilarSchemes(this.props.location.state.data.CMOTSSchemeCode)

        //     //  this.props.getTransactionDetailsListing(this.props.location.state.data.CMOTSSchemeCode)
        //     this.props.SchemeLinePerformationDetails(this.props.location.state.data.CMOTSSchemeCode)
        //     //  this.props.SimilarSchemeDetails(this.props.location.state.data.CMOTSSchemeCode)

        // }
        scrollToTopPage();
    }
    Close = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    }
    onPopupClose = () => {
        this.setState({ openKyc: false, openUcc: false })
    }
    render() {
        const { classes } = this.props;
        try {
            return <React.Fragment>

                <ErrorBoundary>
                    {this.props.loader ? <Schemeloader></Schemeloader> :
                        this.props.list.length != 0 &&
                        <>
                            <div className={classes.firstContainer}>
                                <div></div>
                                <div>
                                    <div className={classes.schemeAndNavSpliting}>
                                        <div className={classes.schemeAndAvatar}>
                                            <Avatars loader={this.props.loader} url={this.props.list?.dataOne}
                                                color={this.props.data.color} avatar={this.props.list?.dataTwo}
                                                id={`scheme${this.props.dataTwo}`}></Avatars>
                                            <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>

                                                <span style={{ fontSize: 24, fontWeight: "bold", color: colors.WHITE, textAlign: "left" }}>{this.props.list.dataTwo}</span>
                                                <div style={{ display: "flex", alignItems: "center", gap: 10, color: colors.WHITE, fontSize: 14 }}>
                                                    {this.props.list.subDataOne != undefined && <span>{this.props.list.subDataOne || labels.messages.NA}  </span>}
                                                    {this.props.list.subDataTwo != undefined && <><span style={{ color: "#4275aa" }}>|</span>
                                                        <span style={{ color: "#c6d3e1" }}> Risk : <span style={{ color: colors.WHITE }}> {this.props.list.subDataTwo}</span> </span></>}
                                                </div>
                                            </div>

                                        </div>
                                        <div className={classes.navRsAndNavDate} >
                                            {this.props.list.dataThree != undefined && <span style={{ color: "white", fontWeight: "bold", fontSize: 24 }}>
                                                {this.props.list.dataThree || labels.messages.NA} {this.props.list.dataFour != undefined && <span style={{ fontWeight: 300 }}> {`${this.props.list.dataFour} ${this.props.list.dataFive} `}</span>}
                                            </span>}
                                            {this.props.list.subDataThree != undefined && <span style={{ fontSize: 14, color: "#c6d3e1" }}>{this.props.list.subDataThree}</span>}
                                        </div>
                                    </div>


                                    <CardMenu
                                        className="cardmenu"
                                        style={classes.cardMenu}
                                        flag="1"
                                        data={
                                            this.props.cardData
                                        }
                                        productID={15}
                                        loader={this.props.loader}
                                    />
                                </div>
                                <div></div>
                            </div>
                            <div className={classes.bodyContainer}>
                                <div></div>
                                <div style={{ gap: 15, display: "flex", flexDirection: "column" }}>
                                    <div className={classes.secondContainer}>
                                        <div className={classes.performanceAndSideTab}>
                                            <div style={{ padding: "12px 15px" }}>
                                                <div className={classes.header}>{this.props.firstHeader}</div>
                                                {this.props.firstContainer}
                                            </div>

                                            <div style={{ overflow: "hidden", paddingBottom: 10 }}>
                                                <Sidetabs disabled={this.props.disabled} openUccPopup={this.openUccPopup} schemeDetails={this.props.data} {...this.props} header={this.props.secondHeader} page={this.props.page} openKycPopup={this.props.openKycPopup} ></Sidetabs>
                                            </div>
                                        </div>

                                    </div>
                                    {this.props.page == "scheme" ?
                                        <div>
                                            <div className={classes.thirdContainer}>
                                                <div className={classes.header} style={{ paddingLeft: 15, paddingTop: 12, marginBottom: 10 }}> {this.props.thirdHeader}</div>
                                                {this.props.thirdContainer}
                                            </div>
                                            <div className={classes.fourthContainer}>
                                                <div className={classes.cardContainer}>
                                                    {this.props.fourthContainer}
                                                </div>
                                                <div className={classes.cardContainer}>
                                                    {this.props.fifthContainer}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            <div className={classes.cardContainer}>
                                                {this.props.sixthHeader}
                                                {this.props.sixthContainer}
                                            </div>
                                            <div className={classes.fourthContainer}>
                                                {this.props.thirdContainer != undefined && <div className={`${classes.cardContainer} ${classes.header}`} style={{ paddingLeft: 15, paddingTop: 12, marginBottom: 10 }}>
                                                    {this.props.thirdHeader}
                                                    {this.props.thirdContainer}
                                                </div>}
                                                {this.props.fifthContainer != undefined && <div className={classes.cardContainer} style={{ marginBottom: 10 }}>
                                                    {this.props.fifthContainer}
                                                </div>}
                                            </div>
                                            <div className={classes.fourthContainer}>
                                                {this.props.fourthContainer != undefined && <div className={classes.cardThirdContainer}>
                                                    {this.props.fourthContainer}
                                                </div>}
                                                <div className={classes.cardThirdContainer} style={{ paddingLeft: 15, paddingRight: 15, paddingTop: 12, marginBottom: 10 }}>
                                                    {this.props.seventhContainer}
                                                </div>
                                            </div>

                                        </div>}


                                </div>
                                <div></div>

                            </div></>}

                    <Popup
                        header={labels.deletePopup.confirmation}
                        open={this.state.openKyc}
                        onClick={this.handlekycyesclick}
                        onClose={this.onPopupClose}
                        text={
                            labels.schemes.kycnotverifiedmessage
                        }
                    />
                    <Dialog

                        flag="primary"
                        open={this.state.openUcc}
                        // handleClose={this.handleAccountClose}
                        onClick={this.onPopupClose}

                    ></Dialog>
                    <UCCPopup open={this.state.openUcc} onClose={this.onPopupClose}></UCCPopup>
                </ErrorBoundary>

            </React.Fragment >
        } catch (err) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }

}
const mapStateToProps = state => {

    return {
        logindetails: state.Login.logindetails,
    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getTransactionDetailsListing, SimilarSchemeDetails, SchemeLinePerformationDetails }, dispatch)
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(Tradingschemedetails)));
