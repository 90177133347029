import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels'
import Networking from '../../utils/api/apiaccess';
import { Asset } from '../../utils/mainasset';
function mobileVerification(UserID,MobileNumber) {

  //let url = "https://ms.zoifintech.com/Dashboard/Login"
  let data = {};
  data.UserID = UserID;
  data.MobileNumber = MobileNumber;
  data.Source = Asset.source

//   data.PANNo = pan;
//   data.Password = password;


  // var config = {
  //   method: 'post',
  //   url: ApiValues.MobileVerification,
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   data: data
  // };

  // return axios(config)
  //   .then(function (response) {
  //     return response.data;
  //   })
  //   .catch(function (error) {
  //     return {"status":"F","message":labels.messages.apierror}
  //   });
  return Networking.PostApi(ApiValues.MobileVerification, data,labels.apiKey.apiKeys, labels.apiKey.apiKeys, true).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default mobileVerification
