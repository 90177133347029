import * as types from '../../constants/actionsType';
import getProfileKYCDetails from '../../../api/profile/kyc_details';
import getProfileClientDetails from '../../../api/profile/clientdata';
import { replaceNull } from '../../../utils/commonfunction';
export const getKycProfileAction = (FormNo, flag,sessionID) => {
    return (dispatch) => {
        getAllKYCProfileDetail(dispatch, FormNo, flag,sessionID)
    };
}
function getAllKYCProfileDetail(dispatch, FormNo, flag,sessionID) {

    dispatch({
        type: types.EMPTY_KYC_PROFILE_DETAILS,
        payload: [],
    });
    dispatch({
        type: types.LOADER_KYC_PROFILE_DETAILS,
        payload: true,
    });
    if (flag == 1) {
        getProfileKYCDetails(FormNo).then((data) => {
            dispatch({
                type: types.LOADER_KYC_PROFILE_DETAILS,
                payload: false,
            });


            if (data.status === "S") {
                dispatch({
                    type: types.PREVENT_API_CALL,
                    payload: [{ ProductID: 0, api: "KycData" }],
                });
                dispatch({
                    type: types.KYC_PROFILE_DETAILS,
                    payload: replaceNull(data, "NA"),
                });

            }
            else {
                dispatch({
                    type: types.PREVENT_API_CALL,
                    payload: [{ ProductID: 0, api: "KycData" }],
                });
                dispatch({
                    type: types.KYC_PROFILE_DETAILS,
                    payload: data,
                });

            }


        })
            .catch((error) => {
                dispatch({
                    type: types.PREVENT_API_CALL,
                    payload: [{ ProductID: 0, api: "KycData" }],
                });
                dispatch({
                    type: types.LOADER_KYC_PROFILE_DETAILS,
                    payload: false,
                });

            })
    } else {
        getProfileClientDetails(FormNo,sessionID).then((data) => {
            dispatch({
                type: types.LOADER_KYC_PROFILE_DETAILS,
                payload: false,
            });


            if (data.status === "S") {
                dispatch({
                    type: types.PREVENT_API_CALL,
                    payload: [{ ProductID: 0, api: "KycData" }],
                });
                dispatch({
                    type: types.KYC_PROFILE_DETAILS,
                    payload: replaceNull(data,"NA"),
                });

            }
            else {
                dispatch({
                    type: types.PREVENT_API_CALL,
                    payload: [{ ProductID: 0, api: "KycData" }],
                });
                dispatch({
                    type: types.KYC_PROFILE_DETAILS,
                    payload: data,
                });

            }


        })
            .catch((error) => {
                dispatch({
                    type: types.PREVENT_API_CALL,
                    payload: [{ ProductID: 0, api: "KycData" }],
                });
                dispatch({
                    type: types.LOADER_KYC_PROFILE_DETAILS,
                    payload: false,
                });

            })
    }

}
export const emptyKycData = () => {
    return {
        type: types.EMPTY_KYC_PROFILE_DETAILS,
        payload: []
    }
}