import React from 'react'
import Label from '../../utils/constants/labels'
import color from "../../utils/colors/colors";
import CommonHeaders from '../../components/report_common_header/report_common_header'
let timerout = ''
export default class NoData extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showloading: true
        }
        this.isComponentMounted = false
    }
    componentDidMount() {
        this.isComponentMounted = true

        timerout = setTimeout(() => {
            if (this.isComponentMounted) {
                this.setState({ showloading: false })
            }
        }, this.props.time == undefined ? 6000 : this.props.time);

    }
    componentWillUnmount() {
        clearTimeout(timerout)
        this.isComponentMounted = false

    }
    render() {
        if (this.props.page == "report") {
            const { classes } = this.props
            return (

                <div className={this.props.className} style={this.props.style}>
                    <span style={{ display: "inline-block", fontSize: 16, color: window.globalConfig.color.LABLE_GRAY, fontWeight: 400 }}>{Label.messages.Dataempty}</span>
                </div>

            )
        }
        else if (this.props.page == "catch") {
            return (
                <div>
                    <CommonHeaders page={"noHeader"} />
                    <div style={{ marginTop: "10%" }}>{Label.messages.apierror}</div>
                </div>
            )
        }
        else {
            return (
                <div style={this.props.style != undefined ? this.props.style : { lineHeight: (this.props.height != null && this.props.height != undefined) ? this.props.height : '', width: '100%', height: '100%', textAlign: 'center', verticalAlign: 'middle', margin: '0 auto' }}>
                    <span style={{ display: "inline-block", fontSize: 16, color: window.globalConfig.color.LABLE_GRAY }}>{Label.messages.Dataempty}</span>
                </div>
            )
        }
    }
}
