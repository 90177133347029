import Networking from '../../utils/api/apiaccess';
import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
var axios = require('axios');

/* name should be camel case in all function */
function getAllCapitalGainDetails(ClientID,ProductID,FamilyID,L5ID,L4ID) {
  //let BaseUrl = "https://ms.zoifintech.com/Dashboard/GetProfitLossDetails"
  var myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
var raw = JSON.stringify({"ClientId":ClientID,
"ProductID": ProductID,
"FamilyID": FamilyID,
"L5ID": L5ID,
"L4ID": L4ID});
return Networking.PostApi(ApiValues.CapitalGain,raw).then(result => { return result })
.catch(function (error) {
  return { "status": "F", "message": labels.messages.apierror }
});


}
export default getAllCapitalGainDetails
