import * as types from '../../constants/actionsType';
import getESignDownloadReportDetails from "../../../api/esigndownloadapi/esigndownloadapi";
import getESignDownloadPdf from "../../../api/esignpdfdownload/esignpdfdownload";
export function getESignDownloadReport(FormNo, PlanCode, subscriptionClientID, clientID, subscriptionID) {
    return (dispatch) => {
        if (FormNo != undefined && PlanCode != undefined) {
            getAllESignDownloadReport(dispatch, FormNo, PlanCode, subscriptionClientID, clientID, subscriptionID);
        }
        else {
            getAllESignDownloadReportEmpty(dispatch)
        }
    };
}

//This function is used for To do Empty the payload(Selected New Plan(My Plan -  Profile) that time call this function in empty parameter )
function getAllESignDownloadReportEmpty(dispatch) {
    //  
    dispatch({
        type: types.ESIGN_DOWNLOAD,
        payload: [],
    });
}
// This function is called with Parameter
function getAllESignDownloadReport(dispatch, FormNo, PlanCode, subscriptionClientID, clientID, subscriptionID) {
    // 
    dispatch({
        type: types.LOADER_ESIGN_DOWNLOAD,
        payload: []
    });
    getESignDownloadReportDetails(clientID, subscriptionID).then((data) => {
        // 
        dispatch({
            type: types.ESIGN_DOWNLOAD,
            payload: [],
        });
        //
        if (data != undefined) {
            //             
            if (data.data.status == "S") {
                var ext = "pdf"
                getESignDownloadPdf(data.data.data, ext, data.data.fileName).then((response) => {
                    dispatch({
                        type: types.ESIGN_PDF_DOWNLOAD,
                        payload: [],
                    });
                    //  
                    if (response != undefined) {
                        //  
                        dispatch({
                            type: types.ESIGN_PDF_DOWNLOAD,
                            payload: response,
                        });
                        return 'success'
                    }
                })


                // 
                dispatch({
                    type: types.ESIGN_DOWNLOAD,
                    payload: data.data
                });
            }
            else if (data.data.status == "F") {
                //
                dispatch({
                    type: types.ESIGN_DOWNLOAD,
                    payload: data.data,
                });
            }
        }
        else {
            //  
            dispatch({
                type: types.ESIGN_DOWNLOAD,
                payload: [],
            });
        }
    })
        .catch((error) => {
            dispatch({
                type: types.ESIGN_DOWNLOAD,
                payload: [],
            });
        })

}
export const isSignedCheck = (status, url) => {
    return {

        type: types.IS_SIGNED,
        status, url

    }

}