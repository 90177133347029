import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { NavLink } from "react-router-dom";
import Button from "@material-ui/core/Button";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import Timer from "../../components/timer/timer";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import ArrowForward from "@material-ui/icons/ArrowForward";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Paper from "@material-ui/core/Paper";
import EmailTimer from "../../components/timer/emailtimmer";
import CircularProgress from "@material-ui/core/CircularProgress";
import logo from "../../utils/assets/zoi-black.png";
import Color from "../../utils/colors/colors";
import TextField from "@material-ui/core/TextField";
import MessagesForm from "../login/messagesform";
import styles from "../login/user-jss";
import {
  closeMsgAction,
  SignupAction,
} from "../../redux/actions/login/loginaction";
import {
  emailValidator,
  requireValidator,
  NameValidation,
  numbersOnly,
  passValidator,
  Encryption
} from "../../utils/commonfunction";
import Label from "../../utils/constants/labels";
import Grid from "@material-ui/core/Grid";
import CommonSnackbar from "../snackbar/snackbar";
import SignUp from "../../api/login/signup";
import emailOTPVerification from "../../api/signup/emailotpverification";
import mobileOTPVerification from "../../api/signup/mobileotpverification";
import mobilenumberVerification from "../../api/signup/mobileverification";
import secureStorage from "../../utils/securestorage";
import Textinput from "../textinput/textinput";
import labels from "../../utils/constants/labels";
import CheckDuplication from "../../api/signup/dupicationcheck";
import ChangeMail from "../../api/signup/changeemail";
import CommonLogin from "../../components/commonLogin/common_login";
import PlayStore from "../../utils/assets/playstore.png";
import AppStore from "../../utils/assets/comingsoontoappstore.png";
import AppStoreNew from "../../utils/assets/appstore.png"
import colors from "../../utils/colors/colors";
import { Asset } from "../../utils/mainasset";
import { Browser, DetectDeviceType } from "../../utils/commonfunction";
let a = [];
let timer = "";
let message = "";
class Signup extends React.Component {
  // eslint-disable-line
  constructor() {
    super();
    this.myDiv = React.createRef();

    this.state = {
      showPassword: false,
      name: "",
      password: "",
      nameError: "",
      passwordError: "",
      signupemail: "",
      pannumber: "",
      mobilenumber: "",
      newpassword: "",
      confirmpassword: "",
      deviceType: "",
      osType: "",
      timeStamp: "",
      timeZone: "",
      browserNameVersion: "",
      referrelUrl: "",
      signupemailerror: "",
      pannumbererror: "",
      mobilenumbererror: "",
      newpassworderror: "",
      confirmpassworderror: "",
      showsignup: false,
      showsetPassword: false,
      showconfirmPassword: false,
      open: false,
      signupmessage: "",
      errorMessage: "",
      page: "signup",
      loader: false,
      step: 1,
      emailotp: "",
      emailotperror: "",
      mobileotp: "",
      mobileotperror: "",
      message: "",
      newEmailAddress: "",
      newEmailAddressError: "",
      loaderemailotp: false,
      loaderphoneotp: false,
      loaderchangeemail: false,
      loadermobile: false,
      formisvalid: true,
      severity: "success",
      emailduplicate: false,
      panduplicate: false,
      mobileduplicate: false,
      changeemailflag: false,
      changemobilenumber: "",
      changemobilenumbererror: "",
      changemobileflag: "",
      loaderchangemobile: false,
    };
    // this.submit = this.submit.bind(this)
    this.submitSignup = this.submitSignup.bind(this);
  }
  componentDidMount() {
    this.myDiv.current.addEventListener("keydown", (e) => {
      //
      //   if (e.key === "Enter" && this.state.page === "login") {
      //     this.submit(e)
      //   }
      if (e.key === "Enter") {   //&& this.state.step === 1) {
        if (this.state.step === 1) {
          this.submitSignup(e);
        }
        else if (this.state.step === 2) {
          this.verifyemailotp(false);
        }
        else if (this.state.step === 3) {
          this.changeemailSubmit()
        }
        else if (this.state.step === 4) {
          this.verifymobileNumber()
        }
        else if (this.state.step === 5) {
          this.verifymobileotp(false)
        }
      }
    });
    if (this.props.location != undefined) {
      if (this.props.location.state != undefined) {
        if (this.props.location.state.data != undefined) {
          this.setState({ step: this.props.location.state.data });
        }
        //
        if (this.props.location.state.data != undefined) {
          this.setState({ name: this.props.location.state.name });
        }
      }
    }

    this.myDiv.current.focus();
  }

  componentWillUnmount() {
    this.myDiv.current.removeEventListener("keydown", (e) => {
      //   if (e.key === "Enter" && this.state.page === "login") {
      //     this.submit(e)
      //   }
      if (e.key === "Enter" && this.state.step === 1) {
        this.submitSignup(e);
      }
    });
    clearTimeout(timer)
  }

  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  handleClicksetShowPassword = () => {
    const { showsetPassword } = this.state;
    this.setState({ showsetPassword: !showsetPassword });
  };
  handleClickShowconfirmPassword = () => {
    const { showconfirmPassword } = this.state;
    this.setState({ showconfirmPassword: !showconfirmPassword });
  };

  panvalidation = (e) => {
    this.setState({ pannumber: e.target.value, pannumbererror: "" });
  };
  namevalidation = (e) => {
    if (NameValidation(e.target.value)) {
      this.setState({ name: e.target.value, nameError: "" });
    }
  };
  mobileotpvalidation = (e) => {
    if (numbersOnly(e.target.value)) {
      this.setState({ mobileotp: e.target.value, mobileotperror: "" });
    }
  };
  emailotpvalidation = (e) => {
    if (numbersOnly(e.target.value)) {
      this.setState({ emailotp: e.target.value, emailotperror: "" });
    }
  };
  confirmpasswordvalidation = (e) => {
    const passwordError = passValidator(e.target.value);
    if (passwordError == '') {
      this.setState({ confirmpassword: e.target.value, confirmpassworderror: '' })
    }
    else {
      this.setState({ confirmpassworderror: passwordError })
    }

  };
  mobilenumbervalidation = (e) => {
    this.setState({ mobilenumbererror: "" });
    var mob = /^\d*$/;
    if (mob.test(e.target.value)) {
      this.setState({ mobilenumber: e.target.value });
    } else {
      e.preventDefault();
    }
  };

  changemobilenumbervalidation = (e) => {
    this.setState({ changemobilenumbererror: "" });
    var mob = /^\d*$/;
    if (mob.test(e.target.value)) {
      this.setState({ changemobilenumber: e.target.value });
    } else {
      e.preventDefault();
    }
  };
  signIn = () => {
    this.props.history.push(Label.Path.Login);
    // this.setState({ showsignup: false, page: "login", signupemail: "", errorMessage: "", signupemailerror: "", mobilenumbererror: "", pannumbererror: "", newpassworderror: "", confirmpassworderror: "", mobilenumber: "", pannumber: "", newpassword: "", confirmpassword: "" })
  };
  submitSignup() {
    this.handlevalidation();
    this.setState({ message: "", errorMessage: "" });
    //
    if (
      this.state.loader === false &&
      this.handlevalidation() === true &&
      this.state.emailduplicate == false &&
      this.state.panduplicate === false
    ) {
      // const timestamp = new Date().getTime();
      //   const timezone = Intl.DateTimeFormat(undefined, { timeZoneName: 'short' }).resolvedOptions().timeZone;
      //   const referrelUrl = window.location.href
      //   const operatingSystem = navigator.userAgentData.platform;
      //   const userAgent = navigator.userAgent;
      //   let browser = Browser(userAgent)
      //   browser = `${browser.browserName} ${browser.browserVersion}`
      //   const deviceType = DetectDeviceType();
      //   this.setState({
      //     loader: true,
      //     deviceType:deviceType,
      //     osType:operatingSystem,
      //     timeStamp:timestamp,
      //     timeZone:timezone,
      //     browserNameVersion:browser,
      //     referrelUrl:referrelUrl,
      //   },()=>{

      //   })
      SignUp(
        this.state.name,
        this.state.signupemail.trim(),
        this.state.pannumber,
        Encryption(this.state.confirmpassword),
        // "",
        // "",
        // this.state.deviceType,
        // this.state.osType,
        // this.state.timeStamp,
        // this.state.timeZone,
        // this.state.browserNameVersion,
        // this.state.referrelUrl
      )
        .then((response) => {
          //
          this.setState({ loader: true });
          if (response.status === "S") {
            this.setState({ severity: "success" });
            secureStorage.setItem("Stepcompleted", this.state.step);
            this.setState({ step: 2 });
            secureStorage.setItem("UserID", response.userID);
            this.setState({
              message: response.message,
              showsignup: false,
              signupmessage: response.message,
              open: true,
            });
            this.setState({
              loader: false,
              errorMessage: "",
              signupemailerror: "",
              mobilenumbererror: "",
              pannumbererror: "",
              newpassworderror: "",
              confirmpassworderror: "",
            });
          } else {
            this.setState({ severity: "warning" });
            secureStorage.setItem("Stepcompleted", this.state.step);
            secureStorage.setItem("UserID", "");
            this.setState({
              errorMessage: response.message,
              showsignup: true,
              loader: false,
              open: true,
              signupmessage: response.message,
            });
          }
        })
        .catch((err) => {
          this.setState({ severity: "warning" });
          secureStorage.setItem("UserID", "");
          this.setState({
            loader: false,
            open: true,
            signupmessage: Label.messages.apierror,
          });
        });

    }
  }
  handlevalidation = () => {
    var email = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    var pan = /([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/;
    const hasUppercase = /[A-Z]/.test(this.state.newpassword);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(this.state.newpassword);
    const hasNumber = /\d/.test(this.state.newpassword);
    let formisvalid = true;
    if (this.state.signupemail.trim() == "") {
      formisvalid = false;
      this.setState({
        signupemailerror: Label.messages.required,
        formisvalid: false,
      });
    } else {
      if (!email.test(this.state.signupemail)) {
        formisvalid = false;
        this.setState({
          signupemailerror: "Invalid Email",
          formisvalid: false,
        });
      }
    }
    if (this.state.name.trim() == "") {
      formisvalid = false;
      this.setState({ nameError: Label.messages.required, formisvalid: false });
    } else {
      if (!NameValidation(this.state.name)) {
        formisvalid = false;
        this.setState({ nameError: "Invalid Name", formisvalid: false });
      }
    }
    if (this.state.pannumber.trim() == "") {
      formisvalid = false;
      this.setState({
        pannumbererror: Label.messages.required,
        formisvalid: false,
      });
    } else {
      if (!pan.test(this.state.pannumber)) {
        formisvalid = false;
        this.setState({ pannumbererror: "Invalid PAN", formisvalid: false });
      }
    }
    if (this.state.newpassword.trim() == "") {
      formisvalid = false;
      this.setState({
        newpassworderror: Label.messages.required,
        formisvalid: false,
      });
      //  return
    } else if (!hasNumber || !hasSpecialChar || !hasUppercase) {
      formisvalid = false;
      this.setState({
        newpassworderror: "Password must  contain at least one uppercase letter, one special character, and one number.",
        formisvalid: false,
      });
    }
    else {
      if (this.state.newpassword.length < 7) {
        formisvalid = false;
        this.setState({
          newpassworderror: Label.messages.MinimumPasswordError,
          formisvalid: false,
        });
      }
    }



    if (this.state.confirmpassword.trim() == "") {
      formisvalid = false;
      this.setState({
        confirmpassworderror: Label.messages.required,
        formisvalid: false,
      });
    } else {
      if (this.state.confirmpassword.length < 7) {
        formisvalid = false;
        this.setState({
          confirmpassworderror: Label.messages.MinimumPasswordError,
          formisvalid: false,
        });
      } else {
        if (this.state.newpassword != this.state.confirmpassword) {
          formisvalid = false;
          this.setState({
            confirmpassworderror: " Password mismatch",
            formisvalid: false,
          });
        }
      }
    }
    return formisvalid;
  };
  verifyemailotp = (resent) => {
    if (this.state.emailotp != "") {
      this.setState({ loaderemailotp: true });
      emailOTPVerification(
        secureStorage.getItem("UserID"),
        this.state.emailotp,
        this.state.signupemail.trim(),
        resent,
        this.state.changeemailflag
      ).then((response) => {
        this.setState({ loaderemailotp: false });
        if (response.status == "S") {
          this.setState({ severity: "success" });
          this.setState({
            open: true,
            loaderemailotp: false,
            signupmessage: response.message,
            step: 4,
          });
        } else {
          this.setState({ severity: "warning" });
          this.setState({
            open: true,
            loaderemailotp: false,
            signupmessage: response.message,
          });
        }
      });
    } else {
      this.setState({ emailotperror: labels.messages.required });
    }
    secureStorage.setItem("Stepcompleted", this.state.step);
  };
  verifymobileotp = (resent) => {
    if (this.state.mobileotp != "") {
      this.setState({ loaderphoneotp: true });
      mobileOTPVerification(
        secureStorage.getItem("UserID"),
        this.state.mobileotp,
        this.state.mobilenumber,
        resent
      )
        .then((response) => {
          this.setState({ loaderphoneotp: false });
          if (response.status == "S") {
            this.setState({ severity: "success" });
            this.setState({
              open: true,
              signupmessage: response.message,
              step: 5,
            });
            timer = setTimeout(() => {
              this.props.history.push(labels.Path.WelcomePage);
            }, 1000);
          } else {
            this.setState({ severity: "warning" });
            this.setState({ open: true, signupmessage: response.message });
          }
        })
        .catch((err) => {
          this.setState({ loaderphoneotp: false });
          this.setState({ severity: "warning" });
          this.setState({
            open: true,
            signupmessage: labels.messages.apierror,
          });
        });
    } else {
      this.setState({ mobileotperror: labels.messages.required });
    }
    secureStorage.setItem("Stepcompleted", this.state.step);
  };
  emailvalidation = (e) => {
    this.setState({
      signupemail: e.target.value,
      signupmessage: "",
      signupemailerror: "",
    });
  };
  newemailvalidation = (e) => {
    this.setState({
      newEmailAddress: e.target.value,
      newEmailAddressError: "",
    });
  };
  handlesnanckClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };

  onChangeEmail(e) {
    this.setState({ email: e.target.value, emailError: "" });
    this.props.onChangeEmail(e.target.value);
  }
  setpasswordvalidation = (e) => {
    const passwordError = passValidator(e.target.value);
    if (passwordError == '') {
      this.setState({ newpassword: e.target.value, newpassworderror: '' })
    }
    else {
      this.setState({ newpassworderror: passwordError })
    }
    // this.setState({ newpassword: e.target.value, newpassworderror: "" });
  };

  verifymobileNumber = () => {
    //
    if (this.state.mobilenumber != "") {
      var mob = /(6|7|8|9)\d{9}/;
      //
      if (mob.test(this.state.mobilenumber) === true) {
        if (this.state.mobilenumber.length == 10) {
          this.setState({ loadermobile: true });
          if (this.checkMobileDuplication() === false) {
            mobilenumberVerification(
              secureStorage.getItem("UserID"),
              this.state.mobilenumber
            )
              .then((response) => {
                if (response.status === "S") {
                  this.setState({ severity: "success" });
                  this.setState({ loadermobile: false });
                  this.setState({
                    signupmessage: response.message,
                    open: true,
                    step: 5,
                    message: response.message,
                  });
                } else {
                  this.setState({ severity: "warning" });
                  this.setState({ loadermobile: false });
                  this.setState({
                    signupmessage: response.message,
                    open: true,
                    message: response.message,
                  });
                }
              })
              .catch((errr) => {
                this.setState({ severity: "warning" });
                this.setState({ loadermobile: false });
                this.setState({
                  signupmessage: labels.messages.apierror,
                  message: "",
                  open: true,
                });
              });
          } else {
            this.setState({ severity: "warning" });
            this.setState({ loadermobile: false });
            //     this.setState({ signupmessage: labels.messages.apierror, message: "", open: true })
          }
        } else {
          this.setState({ mobilenumbererror: "Invalid Mobile Number" });
        }
      } else {
        this.setState({ mobilenumbererror: "Invalid Mobile Number" });
      }
    } else {
      this.setState({ mobilenumbererror: labels.messages.required });
    }

    secureStorage.setItem("Stepcompleted", this.state.step);
  };
  changeemail = () => {
    this.setState({
      step: 3,
      emailotp: "",
      emailotperror: "",
      newEmailAddress: "",
    });
  };
  changemobilenumber = () => {
    this.setState({
      step: 6,
      mobileotp: "",
      mobileotperror: "",
      changemobilenumber: "",
    });
  };
  changeemailSubmit = () => {
    if (this.state.newEmailAddress != "") {
      var email = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      if (email.test(this.state.newEmailAddress)) {
        this.setState({ loaderchangeemail: true });
        ChangeMail(
          secureStorage.getItem("UserID"),
          this.state.signupemail.trim(),
          this.state.newEmailAddress.trim()
        )
          .then((response) => {
            if (response.status == "S") {
              this.setState({ severity: "success", changeemailflag: true });
              this.setState({
                open: true,
                message: response.message,
                signupmessage: response.message,
                step: 2,
                signupemail: this.state.newEmailAddress,
              });
            } else {
              this.setState({ severity: "warning", changeemailflag: false });
              this.setState({
                open: true,
                message: response.message,
                signupmessage: response.message,
              });
            }
            this.setState({ loaderchangeemail: false });
          })
          .catch((err) => {
            this.setState({ severity: "warning" });
            this.setState({
              open: true,
              message: response.message,
              signupmessage: response.message,
              changeemailflag: false,
            });
          });
      } else {
        this.setState({ newEmailAddressError: "Invalid Email" });
      }
    } else {
      this.setState({ newEmailAddressError: labels.messages.required });
    }
  };
  changemobilenumberSubmit = () => {
    if (this.state.changemobilenumber != "") {
      var mob = /(7|8|9)\d{9}/;
      if (mob.test(this.state.changemobilenumber)) {
        if (this.state.changemobilenumber.length == 10) {
          CheckDuplication(data)
            .then((response) => {
              if (response.status == "S") {
                validation = true;
                this.setState({ step: 5 });
                this.setState({ mobilenumber: this.state.changemobilenumber });

                //  this.setState({ mobileduplicate: true })
              } else {
                validation = false;
                this.setState({
                  changemobilenumbererror: response.message,
                  mobileduplicate: false,
                });
              }
            })
            .catch((err) => {
              validation = false;
              this.setState({
                changemobilenumbererror: response.message,
                mobileduplicate: false,
              });
            });
        } else {
          this.setState({ changemobilenumbererror: "Invalid Mobile Number" });
        }
        this.setState({
          changemobilenumbererror: this.state.changemobilenumber,
        });
        // this.setState({ loaderchangeemail: true })
        // ChangeMail(secureStorage.getItem("UserID"), this.state.signupemail, this.state.newEmailAddress).then((response) => {
        //     if (response.status == "S") {
        //         this.setState({ severity: "success", changeemailflag: true })
        //         this.setState({ open: true, message: response.message, signupmessage: response.message, step: 2, signupemail: this.state.newEmailAddress })

        //     } else {
        //         this.setState({ severity: "warning", changeemailflag: false })
        //         this.setState({ open: true, message: response.message, signupmessage: response.message })

        //     }
        //     this.setState({ loaderchangeemail: false })

        // }).catch((err) => {
        //     this.setState({ severity: "warning" })
        //     this.setState({ open: true, message: response.message, signupmessage: response.message, changeemailflag: false })

        // })
      } else {
        this.setState({ changemobilenumbererror: "Invalid Email" });
      }
    } else {
      this.setState({ changemobilenumbererror: labels.messages.required });
    }
  };
  // verifyemailAddress = () => {
  //     this.setState({ step: 2 });
  // }
  // CheckDuplicate = (data) => {

  //     return [{ "status": status, "message": message }]

  // }
  checkMobileDuplication = () => {
    let data = {};
    let validation = false;
    data.MobileNo = this.state.mobilenumber;
    if (this.state.mobilenumber != "") {
      var mob = /(6|7|8|9)\d{9}/;
      if (mob.test(this.state.mobilenumber)) {
        if (this.state.mobilenumber.length == 10) {
          CheckDuplication(data)
            .then((response) => {
              if (response.status == "S") {
                validation = true;
                this.setState({ mobileduplicate: true });
              } else {
                validation = false;
                this.setState({
                  mobilenumbererror: response.message,
                  mobileduplicate: false,
                });
              }
            })
            .catch((err) => {
              validation = false;
            });
        } else {
          this.setState({ mobilenumbererror: "Invalid Mobile Number" });
        }
      } else {
        this.setState({ mobilenumbererror: "Invalid Mobile Number" });
      }
    }

    return validation;
  };
  checkEmailDuplication = (val, flag) => {
    let data = {};
    //
    data.EmailID = val.trim();
    var email = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

    // var pan = /([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/
    if (val != "") {
      if (email.test(val)) {
        CheckDuplication(data).then((response) => {
          if (response.status == "F") {
            if (flag == "signup") {
              this.setState({
                signupemailerror: response.message,
                emailduplicate: true,
              });
            } else {
              this.setState({
                newEmailAddressError: response.message,
                emailduplicate: true,
              });
            }
          } else {
            this.setState({ emailduplicate: false });
          }
        });
      } else {
        if (flag == "signup") {
          this.setState({ signupemailerror: "Invalid Email" });
        } else {
          this.setState({ newEmailAddressError: "Invalid Email" });
        }
      }
    }
  };
  checkPanDuplication = () => {
    let data = {};

    data.PANNo = this.state.pannumber;
    //var email = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/

    var pan = /([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/;
    if (this.state.pannumber != "") {
      if (pan.test(this.state.pannumber)) {
        CheckDuplication(data).then((response) => {
          if (response.status == "F") {
            this.setState({
              pannumbererror: response.message,
              panduplicate: true,
            });
          } else {
            this.setState({ panduplicate: false });
          }
        });
      }
    }
  };
  mobileresendotp = () => {
    this.setState({ mobileotp: "", mobileotperror: "" });
    mobileOTPVerification(
      secureStorage.getItem("UserID"),
      this.state.mobileotp,
      this.state.mobilenumber,
      true
    ).then((response) => {
      if (response.status == "S") {
        this.setState({
          open: true,
          signupmessage: response.message,
          severity: "success",
        });
        //this.props.history.push(labels.Path.Login)
      } else {
        this.setState({
          open: true,
          signupmessage: response.message,
          severity: "warning",
        });
      }
    });
  };
  emailresendotp = () => {
    this.setState({ emailotp: "", emailotperror: "" });
    emailOTPVerification(
      secureStorage.getItem("UserID"),
      this.state.emailotp,
      this.state.signupemail.trim(),
      true,
      this.state.changeemailflag
    ).then((response) => {
      if (response.status == "S") {
        this.setState({
          open: true,
          signupmessage: response.message,
          severity: "success",
        });
        //this.props.history.push(labels.Path.Login)
      } else {
        this.setState({
          open: true,
          signupmessage: response.message,
          severity: "warning",
        });
      }
    });
  };
  // reSendOtp = () => {
  //   ResendOTP(this.state.RestPassword).then((data) => {
  //     this.setState({
  //       isVerifiedOtp: true,
  //       isForgetpassword: false
  //     })
  //   })
  // }
  verifyOtp = (value) => {
    VerifyOTPs(this.state.RestPassword, this.state.ClientID, value).then(
      (data) => {
        if (data.status == "S") {
          this.setState({
            isVerifiedOtp: false,
            isForgetpassword: false,
            isChangePassword: true,
          });
        } else {
          this.setState({
            isVerifiedOtp: false,
            isForgetpassword: false,
            isChangePassword: false,
            Message: data.message,
            open: true,
            color: "warning",
          });
        }
      }
    );
  };
  onPasswordKeypress = (event) => {
    if (event.keyCode == 32) {
      event.preventDefault();
    } else {
    }
  };
  terms = () => {
    window.open(window.globalConfig.terms);
  };
  PlayStoreClick = () => {
    window.location.href = "https://play.google.com/store/apps/details?id=com.finnovate.wealth";
  };
  onAppStoreClick = () => {
    window.location.href = "https://apps.apple.com/my/app/finnovate-wealth/id6444800128"
  }
  render() {
    const {
      classes,
      handleSubmit,
      pristine,
      submitting,
      intl,
      messagesAuth,
      closeMsg,
      loading,
    } = this.props;
    const { showPassword, showsetPassword, showconfirmPassword } = this.state;
    return (
      <div tabIndex="0" ref={this.myDiv}>
        <Paper className={classes.rootFull}>
          <Grid container xs={12}>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              component={Paper}
              elevation={16}
              square
              id="cardImage"
            >
              <CommonLogin />
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              md={6}
              lg={6}
              xl={6}
              component={Paper}
              elevation={1}
              square
              className={[classes.LoginFormCard, "loginFormControl"].join(" ")}
            >
              {/* <div className={classes.paper}> */}
              <div className={classes.pageFormSideWrap}>
                {this.state.step == 1 && (
                  <div
                    className={classes.signupFormLogincard}
                    onSubmit={this.submitSignup}
                  >
                    {/* <h5 className={classes.title}>{labels.Login.Signup}</h5> */}
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                      }}
                    >
                      <div className={classes.cardBox}>
                        <h6 className={classes.subTitleNew}>{Label.Login.MemberText}</h6>
                        <h5 className={classes.titleNew}>

                          {Label.Login.FinnTrackText}
                        </h5>
                        <FormControl
                          className={[classes.formControl, "loginEmail"].join(
                            " "
                          )}
                        >
                          <Textinput
                            page={"TextField"}
                            value={this.state.name}
                            label={labels.Signup.Name}
                            onChange={this.namevalidation}
                            style={{ marginTop: "3%" }}
                            inputProps={{ maxLength: 40 }}
                            errormessage={this.state.nameError}
                          />
                        </FormControl>
                        <FormControl
                          className={[classes.formControl, "loginEmail"].join(
                            " "
                          )}
                        >
                          <Textinput
                            page={"TextField"}
                            onBlur={() =>
                              this.checkEmailDuplication(
                                this.state.signupemail,
                                "signup"
                              )
                            }
                            value={this.state.signupemail}
                            label={labels.Signup.Email}
                            onChange={this.emailvalidation}
                            style={{ marginTop: "3%" }}
                            errormessage={this.state.signupemailerror}
                            inputProps={{
                              maxLength: 75,
                              autoComplete: "new-password",
                            }}
                          />
                        </FormControl>
                        <FormControl
                          className={[classes.formControl, "loginEmail"].join(
                            " "
                          )}
                        >
                          <Textinput
                            page={"TextField"}
                            onBlur={this.checkPanDuplication}
                            value={this.state.pannumber}
                            label={labels.Signup.Pan}
                            onChange={this.panvalidation}
                            style={{ marginTop: "3%" }}
                            inputProps={{
                              maxLength: 10,
                              autoComplete: "off",
                              style: { textTransform: "uppercase" },
                            }}
                            errormessage={this.state.pannumbererror}
                          />
                        </FormControl>
                        <FormControl
                          className={[classes.formControl, "loginEmail"].join(
                            " "
                          )}
                        >
                          <Textinput
                            page={"TextFieldpassword"}
                            onKeyDown={this.onPasswordKeypress}
                            onMouseDown={this.handleMouseDownPassword}
                            onClick={this.handleClicksetShowPassword}
                            style={{ marginTop: "3%" }}
                            type={showsetPassword}
                            value={this.state.newpassword}
                            label={labels.Signup.setpassword}
                            onChange={this.setpasswordvalidation}
                            errormessage={this.state.newpassworderror}
                          />
                        </FormControl>
                        <FormControl
                          className={[classes.formControl, "loginEmail"].join(
                            " "
                          )}
                        >
                          <Textinput
                            page={"TextFieldpassword"}
                            onKeyDown={this.onPasswordKeypress}
                            onMouseDown={this.handleMouseDownPassword}
                            onClick={this.handleClickShowconfirmPassword}
                            style={{ marginTop: "3%" }}
                            type={showconfirmPassword}
                            value={this.state.confirmpassword}
                            label={labels.Signup.confirmpassword}
                            onChange={this.confirmpasswordvalidation}
                            errormessage={this.state.confirmpassworderror}
                          />
                        </FormControl>
                        <span style={{ paddingTop: "4%", color: window.globalConfig.color.red }}>
                          {this.state.errorMessage != "fail" &&
                            this.state.errorMessage != "Success" &&
                            this.state.errorMessage}
                        </span>
                        {window.globalConfig.terms != undefined && <div
                          style={{
                            fontSize: 12,
                            fontWeight: 800,
                            color: window.globalConfig.color.LABLE_GRAY,
                            marginTop: 10,
                          }}
                          onClick={this.terms}
                        >
                          {labels.Signup.byClickingSubmit}
                          <span
                            style={{
                              fontWeight: "bold",
                              color: window.globalConfig.color.GREEN,
                              cursor: "pointer",
                            }}
                          >
                            {labels.Signup.termsAndCondition}
                          </span>
                        </div>}
                        <div className={classes.btnArea}>
                          {/* <MuiThemeProvider theme={redTheme}> */}

                          <Button
                            variant="contained"
                            onClick={this.submitSignup}
                            disabled={this.state.loader}
                            fullWidth
                            style={{
                              backgroundColor: Color.GREEN,
                              color: Color.WHITE,
                              height: 52,
                            }}
                            size="large"
                            type="submit"
                            className="btnLoginSubmit"
                          >
                            {this.state.loader && (
                              <CircularProgress
                                style={{ color: Color.WHITE }}
                                size={24}
                                className={classes.buttonProgress}
                              />
                            )}
                            {labels.Signup.submit}
                            {!this.state.loader && (
                              <ArrowForward
                                className={classNames(
                                  classes.rightIcon,
                                  classes.iconSmall,
                                  classes.signArrow
                                )}
                                disabled={submitting || pristine}
                              />
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.state.step == 2 && (
                  <div
                    className={classes.signupFormLogin}
                  // style={{ marginTop: "26%" }}
                  >
                    {/* <h5 className={classes.title}> {labels.Login.Signup}</h5> */}
                    <div
                      className={classes.cardBox}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6 className={classes.subTitleNew}>{Label.Login.MemberText}</h6>
                      <h5 className={classes.titleNew}>

                        {Label.Login.FinnTrackText}
                      </h5>
                      <div style={{ textAlign: "center", lineHeight: 1.7 }}>
                        {this.state.message}
                        <hr />
                      </div>
                      <FormControl
                        className={[classes.formControl, "loginEmail"].join(
                          " "
                        )}
                      >
                        <Textinput
                          page={"TextField"}
                          value={this.state.emailotp}
                          errormessage={this.state.emailotperror}
                          label={labels.Signup.Emailotp}
                          onChange={this.emailotpvalidation}
                          inputProps={{ maxLength: 6 }}
                          style={{ marginTop: "3%" }}
                        />
                      </FormControl>
                      <div
                        className={classes.btnArea}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        {/* <MuiThemeProvider theme={redTheme}> */}
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "58%",
                            alignItems: "center",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {/* <Timer
                            text={"Resend OTP"}
                            onclick={this.emailresendotp}
                            userInputSecond={"true"}
                          />  */}
                          <EmailTimer
                            text={labels.Signup.resendOTP}
                            onclick={this.emailresendotp}
                          />

                          <div style={{ marginTop: "6%", textAlign: "right" }}>
                            <div
                              style={{
                                textDecorationLine: "underline",
                                textAlign: "right",
                                marginLeft: "-10%",
                                cursor: "pointer",
                              }}
                              onClick={this.changeemail}
                            >
                              {Label.Signup.changeemail}
                            </div>
                          </div>
                        </div>
                        <Button
                          variant="contained"
                          onClick={() => this.verifyemailotp(false)}
                          fullWidth
                          style={{
                            backgroundColor: Color.GREEN,
                            color: Color.WHITE,
                            height: 52,
                            opacity:
                              this.state.loaderemailotp == true ? 0.5 : 1,
                            cursor:
                              this.state.loaderemailotp == true
                                ? "not-allowed"
                                : "pointer",
                          }}
                          size="large"
                          type="submit"
                          className="btnLoginSubmit"
                        >
                          {this.state.loaderemailotp && (
                            <CircularProgress
                              style={{ color: Color.WHITE }}
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                          {labels.Signup.verify}
                          {!this.state.loaderemailotp && (
                            <ArrowForward
                              className={classNames(
                                classes.rightIcon,
                                classes.iconSmall,
                                classes.signArrow
                              )}
                              disabled={submitting || pristine}
                            />
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.step == 3 && (
                  <div
                    className={classes.signupFormLogin}
                  // style={{ marginTop: "26%" }}
                  >
                    <h5
                      className={classes.title}
                      style={{ textAlign: "center" }}
                    >
                      {/* {labels.Login.Signup} */}
                    </h5>

                    <div
                      className={classes.cardBox}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6 className={classes.subTitleNew}>{Label.Login.MemberText}</h6>
                      <h5 className={classes.titleNew}>

                        {Label.Login.FinnTrackText}
                      </h5>
                      <div style={{ paddingTop: 5, fontSize: 18 }}>
                        {labels.Signup.welcome}
                      </div>
                      <div style={{ display: "grid", alignItems: "center", gridTemplateColumns: '10% 90%', }}>
                        <ArrowBack
                          className={classNames(
                            classes.rightIcon,
                            classes.iconSmall,
                            classes.signArrow
                          )}
                          style={{ cursor: "pointer" }}
                          onClick={() => { this.setState({ step: 2 }) }}
                        />
                        <div style={{ padding: 10, fontSize: 18, display: 'flex', justifyContent: 'center', paddingRight: '12%' }}>
                          <span> {this.state.name}</span>
                        </div>
                      </div>
                      <FormControl
                        className={[classes.formControl, "loginEmail"].join("")}
                      >
                        <Textinput
                          page={"TextField"}
                          onBlur={() =>
                            this.checkEmailDuplication(
                              this.state.newEmailAddress,
                              "change"
                            )
                          }
                          value={this.state.newEmailAddress}
                          label={labels.Signup.Email}
                          onChange={this.newemailvalidation}
                          style={{ marginTop: "3%" }}
                          errormessage={this.state.newEmailAddressError}
                          inputProps={{
                            maxLength: 75,
                            autoComplete: "new-password",
                          }}
                        />
                      </FormControl>
                      <div
                        className={classes.btnArea}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        <Button
                          variant="contained"
                          onClick={this.changeemailSubmit}
                          disabled={this.state.loaderchangeemail}
                          fullWidth
                          style={{
                            backgroundColor: Color.GREEN,
                            color: Color.WHITE,
                            height: 52,
                          }}
                          size="large"
                          type="submit"
                          className="btnLoginSubmit"
                        >
                          {this.state.loaderchangeemail && (
                            <CircularProgress
                              style={{ color: Color.WHITE }}
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                          {labels.Signup.submit}
                          {!this.state.loaderchangeemail && (
                            <ArrowForward
                              className={classNames(
                                classes.rightIcon,
                                classes.iconSmall,
                                classes.signArrow
                              )}
                              disabled={submitting || pristine}
                            />
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.step == 4 && (
                  <div
                    className={classes.signupFormLogin}
                  // style={{ marginTop: "26%" }}
                  >
                    <h5
                      className={classes.title}
                      style={{ textAlign: "center" }}
                    >
                      {/* {labels.Login.Signup} */}
                    </h5>
                    <div
                      className={classes.cardBox}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6 className={classes.subTitleNew}>{Label.Login.MemberText}</h6>
                      <h5 className={classes.titleNew}>
                        {Label.Login.FinnTrackText}
                      </h5>
                      <div style={{ fontSize: 18 }}>
                        {labels.Signup.welcome}
                      </div>
                      <div style={{ padding: 10, fontSize: 18 }}>
                        {this.state.name}
                      </div>
                      <FormControl
                        className={[classes.formControl, "loginEmail"].join(
                          " "
                        )}
                      >
                        <Textinput
                          page={"TextField"}
                          value={this.state.mobilenumber}
                          label={labels.Signup.Mobile}
                          onChange={this.mobilenumbervalidation}
                          style={{ marginTop: "3%" }}
                          inputProps={{ maxLength: 10 }}
                          errormessage={this.state.mobilenumbererror}
                        />
                      </FormControl>
                      <div
                        className={classes.btnArea}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        {/* <MuiThemeProvider theme={redTheme}> */}
                        <div>
                          {/* <a style={{ textDecorationLine: "underline", marginLeft: "-10%", cursor: "pointer" }} onClick={this.signIn} >{Label.Login.Signin}</a> */}
                        </div>
                        <Button
                          variant="contained"
                          onClick={this.verifymobileNumber}
                          disabled={this.state.loadermobile}
                          fullWidth
                          style={{
                            backgroundColor: Color.GREEN,
                            color: Color.WHITE,
                            height: 52,
                          }}
                          size="large"
                          type="submit"
                          className="btnLoginSubmit"
                        >
                          {this.state.loadermobile && (
                            <CircularProgress
                              style={{ color: Color.WHITE }}
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                          {labels.Signup.submit}
                          {!this.state.loadermobile && (
                            <ArrowForward
                              className={classNames(
                                classes.rightIcon,
                                classes.iconSmall,
                                classes.signArrow
                              )}
                              disabled={submitting || pristine}
                            />
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.step == 5 && (
                  <div
                    className={classes.signupFormLogin}
                  // style={{ marginTop: "26%" }}
                  >
                    {/* <h5 className={classes.title}>{labels.Login.Signup}</h5> */}
                    <div
                      className={classes.cardBox}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <h6 className={classes.subTitleNew}>{Label.Login.MemberText}</h6>
                      <h5 className={classes.titleNew}>

                        {Label.Login.FinnTrackText}
                      </h5>
                      <div style={{ textAlign: "left", lineHeight: 1.7 }}>
                        {this.state.message}
                      </div>
                      <FormControl
                        className={[classes.formControl, "loginEmail"].join(
                          " "
                        )}
                      >
                        <Textinput
                          page={"TextField"}
                          value={this.state.mobileotp}
                          label={labels.Signup.Mobileotp}
                          onChange={this.mobileotpvalidation}
                          inputProps={{ maxLength: 6 }}
                          style={{ marginTop: "3%" }}
                          errormessage={this.state.mobileotperror}
                        />
                      </FormControl>
                      <div
                        classNsame={classes.btnArea}
                        style={{ display: "flex", flexDirection: "row" }}
                      >
                        {/* <MuiThemeProvider theme={redTheme}> */}
                        <div
                          style={{
                            width: "58%",
                            alignItems: "center",
                            marginTop: "4%",
                          }}
                        >
                          <Timer
                            text={labels.Signup.resendOTP}
                            onclick={this.mobileresendotp}
                          />
                        </div>
                        <Button
                          variant="contained"
                          onClick={() => this.verifymobileotp(false)}
                          disabled={this.state.loaderphoneotp}
                          fullWidth
                          style={{
                            backgroundColor: Color.GREEN,
                            color: Color.WHITE,
                            height: 52,
                            opacity:
                              this.state.loaderemailotp == true ? 0.5 : 1,
                            cursor:
                              this.state.loaderemailotp == true
                                ? "not-allowed"
                                : "pointer",
                          }}
                          size="large"
                          type="submit"
                          className="btnLoginSubmit"
                        >
                          {this.state.loaderphoneotp && (
                            <CircularProgress
                              style={{ color: Color.WHITE }}
                              size={24}
                              className={classes.buttonProgress}
                            />
                          )}
                          {labels.Signup.verify}
                          {!this.state.loaderphoneotp && (
                            <ArrowForward
                              className={classNames(
                                classes.rightIcon,
                                classes.iconSmall,
                                classes.signArrow
                              )}
                              disabled={submitting || pristine}
                            />
                          )}
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                <div style={{ marginTop: 15 }}>
                  <a
                    style={{
                      textDecorationLine: "underline",
                      marginLeft: "-10%",
                      cursor: "pointer",
                      textTransform: "capitalize",
                      marginTop: 20,
                    }}
                    onClick={this.signIn}
                  >
                    {Label.Login.Signin}
                  </a>
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    gap: 10
                  }}
                >
                  <div
                    onClick={() => {
                      this.PlayStoreClick();
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      style={{ height: 46 }}
                      src={PlayStore}
                      className={classes.imageAppStoreStyle}
                    ></img>
                  </div>
                  <img
                    onClick={this.onAppStoreClick}
                    src={AppStoreNew}
                    className={classes.imageAppStoreStyle}
                  ></img>
                </div>
                <CommonSnackbar
                  message={this.state.signupmessage}
                  handlesnanckClose={this.handlesnanckClose}
                  open={this.state.open}
                  severity={this.state.severity}
                />
              </div>
              {/* </div> */}
            </Grid>
          </Grid>
        </Paper>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  //
  a = state.Login.signupmessage;
  return {
    messagesAuth: state.Login.loginError,
    signup: state.Login.signup,
    signupmessage: state.Login.signupmessage,
    loader: state.Login.loadercontinue,
    loadersignup: state.Login.loadersignup,
  };
};
const dispatchToProps = (dispatch) => {
  return {
    closeMsg: () => dispatch(closeMsgAction()),
    SignupAction: (email, mobilenumber, pannumber, password) =>
      dispatch(SignupAction(email, mobilenumber, pannumber, password)),
  };
};
const SignupMapped = connect(mapStateToProps, dispatchToProps)(Signup);
export default withStyles(styles)(SignupMapped);
