//page created by dulcy
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom'
import CommonHeaders from '../../components/report_common_header/report_common_header'
import Label from '../../utils/constants/labels';
import { withStyles } from '@material-ui/core';
import './afrstylesheet.css'
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom'//import Select from '@material-ui/core/Select';
import moment from 'moment';
import { EncryptURL, Encryption, NegativeFinding } from '../../utils/commonfunction';
import NoData from '../../components/nodata/nodata';
import labels from '../../utils/constants/labels';
import getAllcommonDownloadDetails from '../../api/commondownload/commondownload';
import Loader from '../../components/loader/loader'
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyle from '../../utils/stylesheets/table/commontablestyle';
import colors from '../../utils/colors/colors';
import apiurl from '../../api/url/apiurl';
import CommonSnackbar from '../../components/snackbar/snackbar'
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import Elss from './elss';
import Shortterm from './shortterm';
import secureStorage from '../../utils/securestorage';
import { Asset } from '../../utils/mainasset';
var title;
const styles = theme => ({
    Categoryname: {
        fontSize: "18px",
        fontWeight: "bold",
        color: window.globalConfig.color.BLACK_PRIMARY
    },
    commonsubheading: {
        fontSize: "16px",
        fontWeight: "bold",
        color: window.globalConfig.color.BLACK_PRIMARY,
    },
    invested: {
        fontSize: "16px",
        fontWeight: "bold",
        color: window.globalConfig.color.BLACK_PRIMARY,
        textAlign: "left",
        // width: 304,
        // marginLeft: "25px"
    },
    Units: {
        fontSize: "13px",
        fontWeight: 500,
        color: window.globalConfig.color.BLACK_PRIMARY
    },
    topheader: {
        textAlign: "center",
        lineHeight: 2.0,
        // paddingBottom: "2em",
        fontSize: "15px", fontWeight: 500, color: window.globalConfig.color.LABLE_GRAY
    },
    rupeesymbol: {
        margin: "0px !important",
        fontSize: "13px !important",
        fontWeight: "normal !important",
        color: `${window.globalConfig.color.LABLE_GRAY} !important`,
        padding: "1px 0px",
    },
    subheadingrupeesymbol: {
        margin: "0px !important",
        fontSize: "16px !important",
        fontWeight: "normal !important",
        color: `${window.globalConfig.color.LABLE_GRAY} !important`,
        padding: "1px 0px",
        [theme.breakpoints.down('xs')]: {
            fontSize: "14px !important",
        }
    },
    SchemeName: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "13px",
        fontWeight: 500
    },
    ElssSchemeName: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "14px",
        fontWeight: 500
    },
    exitload: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "13px"
    },
    total: {
        color: window.globalConfig.color.WHITE,
        fontSize: "15px"
    },

    HeaderBlock: {
        // [theme.breakpoints.down('xs')]: {
        //     display: "none !important"
        // }
    },
    // HeaderBlockResponsive: {
    //     display: "none !important",
    //     [theme.breakpoints.down('xs')]: {
    //         display: "block !important",
    //         paddingBottom: 40
    //     }
    // }

    //STLT



    ExitBlock: {
        [theme.breakpoints.down('xs')]: {
            display: "none !important"
        }
    },
    ExitResponsive: {
        display: "none !important",
        [theme.breakpoints.down('xs')]: {
            display: "block !important",
            margin: "0px 10px"
        }
    },
    ELSSBlock: {
        [theme.breakpoints.down('xs')]: {
            display: "none !important"
        }
    },
    ELSSResponsive: {
        display: "none !important",
        [theme.breakpoints.down('xs')]: {
            display: "block !important",
            margin: "0px 10px"
        }
    },
    flexrow: {
        display: "flex", flexDirection: "row", justifyContent: "space-between"
    },
    shorttermholdingheader: {
        display: "flex", marginLeft: "27%", justifyContent: "flex-start"
    },
    longtermholding: {
        display: "flex", marginLeft: "14%", justifyContent: "center"
    },
    totalholding: {
        display: "flex", marginLeft: "15%"

    },
    right: {
        textAlign: "right"
    },
    center: {
        textAlign: "center"
    },
    left: {
        textAlign: "left"
    },
    schemeNamecommonstyle: {
        textAlign: 'left'
    },
    InvestedAmount: {
        paddingTop: "18%"
    },
    CurrentAmount: {
        paddingTop: "5%"
    },
    categorycontainer: {
        backgroundColor: window.globalConfig.color.ZERO_BACKGROUND, flex: 1,
        height: "91px",
        alignItems: "center",
        borderRadius: 8, color: window.globalConfig.color.BLACK_PRIMARY,
    },
    grandtotalcontainer: {
        backgroundColor: window.globalConfig.color.BLACK_PRIMARY, paddingTop: "3%", height: 44, paddingBottom: "3%", borderRadius: 6, marginTop: '1%',
        marginRight: "1.5%"
    },
    grandtotaltext: {
        textAlign: 'left', color: window.globalConfig.color.WHITE, fontSize: 18
    },
    whitecolor: {
        color: window.globalConfig.color.WHITE
    },
    //Exitload
    ExitLoadcontainer: {
        display: "flex", marginRight: "21%", flex: 1, flexDirection: "row", justifyContent: "flex-end"
    }
    , totalunitsexitload: {
        marginLeft: -8,
    },
    gainlossexitload: {
        marginLeft: -11,
    },
    //ELSS
    // Elsscontainer: {
    //     display: "flex", flexDirection: "row", backgroundColor: window.globalConfig.color.ZERO_BACKGROUND, flex: 1,
    //     paddingTop: "2%", paddingBottom: "2%", paddingLeft: "1%", paddingRight: "1%",
    //     borderRadius: 6, color: window.globalConfig.color.BLACK_PRIMARY,
    //     marginTop: "-8px",
    //     // marginRight: "1.5%"
    // },
    amcnameelss: {
        display: 'flex', flex: 1, justifyContent: "flex-start", textTransform: "uppercase"
    },
    // ElssAmccontainer: {
    //     backgroundColor: window.globalConfig.color.ZERO_BACKGROUND, paddingTop: "2%", paddingBottom: "2%",
    //     paddingLeft: "1%", paddingRight: "1%", borderRadius: 6, color: window.globalConfig.color.BLACK_PRIMARY,
    //     textAlign: "left",
    //     marginRight: "1.5%"
    // },

    directionColumn: {
        display: "flex",
        flexDirection: "column"
    },
    headerAlign: {
        marginTop: "3.5%",

        [theme.breakpoints.between('1250', '1300')]: {
            marginTop: "4%",
        }
    },
    noDataDesign: {
        display: "none !important",
        [theme.breakpoints.between('300', '600')]: {
            display: "block !important",
            width: "100%",
            height: 50,
            paddingTop: 10,
            paddingBottom: 30
        }
    }
});
class AFR extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownvalue: "ShortTerm/LongTerm Taxation",
            checked: true,
            accpdf: false,
            accexcel: false,
            subreportID: 1,
            showelss: true,
            Open: false,
        }
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ Open: false })
    };
    onClickPdf = (ext) => {
        //  
        var pdfexcel = false;
        var filename = `${this.state.dropdownvalue}.${ext}`
        if (this.state.accpdf == false) {
            if (ext === "pdf") {
                this.setState({ accpdf: true })
                pdfexcel = true;
            }
        }
        if (this.state.accexcel == false) {
            if (ext === "xlsx") {
                this.setState({ accexcel: true })
                pdfexcel = true;
            }
        }
        let authToken = ''
        if (!Boolean(secureStorage.getItem(`authToken-${secureStorage.getItem("sessionID")}`))) {
            authToken = labels.key.apiKey
        } else {
            authToken = secureStorage.getItem(`authToken-${secureStorage.getItem("sessionID")}`)
        }
        let nodeToken = secureStorage.getItem(`nodeToken-${secureStorage.getItem("sessionID")}`)

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append('Authorization', `Bearer ${nodeToken}`);
        myHeaders.append('Token', labels.key.apiKey);
        myHeaders.append('SessionID', secureStorage.getItem("sessionID"));
        myHeaders.append('Access-Control-Allow-Origin', `${window.globalConfig.Domain}`,
        );
        myHeaders.append('gw', EncryptURL(apiurl.DownloadReport))
        myHeaders.append('Source', Asset.source
        )
        myHeaders.append('Export', false)
        myHeaders.append('Strict-Transport-Security', 'max-age=31536000; includeSubDomains; preload')

        // myHeaders.append( 'ClientID',Encryption(this.props.logindetails.ClientID))
        var raw = JSON.stringify({
            "ClientID": this.props.logindetails.ClientID,
            "AccountID": 0,
            "ReportID": 2,
            "WithRedeem": false,
            "AsOnDate": moment().format("DD MMM YYYY"),
            "SubReport": this.state.subreportID,
            "DownloadAs": ext == "pdf" ? 1 : 2, "ProductID": this.props.flag === "equity" ? 2 : 1,
            "FamilyID": 0, "L5ID": this.props.L5ID, "L4ID": 0
        });
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };
        if (pdfexcel == true) {
            fetch("/gw", requestOptions)
                .then(response => response.json())
                .then(result => {
                    if (result.status == "S" && result.data != "") {
                        //  fileDownload(result.data, "Accountstatement2.xlsx")
                        getAllcommonDownloadDetails(result.data, ext, filename, this.props.logindetails?.SessionID).then((response) => {
                            if (response === "success") {
                                if (ext === "pdf") {
                                    this.setState({ accpdf: false })
                                }
                                if (ext === "xlsx") {
                                    this.setState({ accexcel: false })
                                }
                            } else {
                                this.setState({ Open: true })
                                if (ext === "pdf") {
                                    this.setState({ accpdf: false })
                                }
                                if (ext === "xlsx") {
                                    this.setState({ accexcel: false })
                                }
                            }

                        }).catch(error => {
                            this.setState({ Open: true })
                            if (ext === "pdf") {
                                this.setState({ accpdf: false })
                            }
                            if (ext === "xlsx") {
                                this.setState({ accexcel: false })
                            }
                        });
                    } else {
                        this.setState({ Open: true })
                        if (ext === "pdf") {
                            this.setState({ accpdf: false })
                        }
                        if (ext === "xlsx") {
                            this.setState({ accexcel: false })
                        }
                    }
                })
                .catch(error => {
                    if (error.response.status == 440 && secureStorage.getItem(`invalidAuth${session}`) == null) {
                        secureStorage.setItem(`invalidAuth${session}`, "invalid")
                    }
                    this.setState({ Open: true })
                    if (ext === "pdf") {
                        this.setState({ accpdf: false })
                    }
                    if (ext === "xlsx") {
                        this.setState({ accexcel: false })
                    }
                });
        }
    }

    onChangeSubReport = (e) => {
        this.setState({ dropdownvalue: e[0].label, subreportID: e[0].value })
    }
    render() {
        // 
        const { classes } = this.props
        let combainStyle = Object.assign({}, classes, this.props.externalClasses)
        // console.log(this.props.shortterm)
        // try {
        return (<ErrorBoundary>
            {this.props.isShorttermLoading == true || this.props.isElssLoading == true ?
                <Loader page="skeletonreport" />
                :
                <ErrorBoundary>
                    <div className={combainStyle.HeaderBlock}>
                        <div className={[combainStyle.stickyHeader].join(' ')}>
                            <CommonHeaders
                                flag={this.props.flag}
                                showfilter={false}
                                showdownload={true}
                                Reportlist={[{ label: "ShortTerm/LongTerm Taxation", value: 1, isChecked: true }, { label: "ELSS Holding", value: 2, isChecked: false }, { label: "ELSS Transaction", value: 3, isChecked: false }]}
                                disableExports={this.state.dropdownvalue == "ELSS Holding" ? true : false}
                                showdropdown={true}
                                dropdownafr={this.state.dropdownvalue == "ELSS Holding" ? true : false}
                                selected={true}
                                handleclear={this.handleclear}
                                pdfload={this.state.accpdf}
                                newxcelLoad={this.state.accexcel}
                                List={this.state.dropdownvalue === "ShortTerm/LongTerm Taxation" ? this.props.shortterm : this.state.dropdownvalue === "ELSS Holding" ? this.props.elssMaturity : this.state.dropdownvalue === "ELSS Transaction" ? this.props.Elssdata : []}
                                header={"Scheme"}
                                scheme={this.state.dropdownvalue === "ShortTerm/LongTerm Taxation" ? this.props.shortterm != undefined && this.props.shortterm.length > 0 ? `${this.props.shortterm.length} ${this.props.shortterm.length == 1 ? this.props.header : this.props.header + "s"}` : <NoData /> : this.state.dropdownvalue === "ELSS Holding" ? this.props.elssMaturity != undefined && this.props.elssMaturity.length > 0 ? `${this.props.elssMaturity.length} ${this.props.elssMaturity.length == 1 ? "Scheme" : 'Schemes'}` : <NoData /> : this.state.dropdownvalue === "ELSS Transaction" ? this.props.Elssdata != undefined && this.props.Elssdata.length > 0 ? `${this.props.Elssdata.length} ${this.props.Elssdata.length == 1 ? "Scheme" : 'Schemes'}` : <NoData /> : 0}
                                data={this.state.dropdownvalue === "ShortTerm/LongTerm Taxation" ? this.props.shortterm : this.state.dropdownvalue === "ELSS Holding" ? this.props.elssMaturity : this.state.dropdownvalue === "ELSS Transaction" ? this.props.Elssdata : []}
                                onChange={this.onChangeSubReport}
                                onclick={this.onClickPdf} value={this.state.dropdownvalue}
                                downloadLabel={this.state.dropdownvalue}
                                reportID={labels.Report.afr}
                                accountID={0}
                                subReport={this.state.subreportID}
                                date={this.props.date}
                                productID={this.props.flag === "equity" ? 2 : 1}
                                //onclick={this.onClickPdf} 

                                page={"afr"} checked={this.state.checked} />
                        </div>
                    </div>
                    <CommonSnackbar open={this.state.Open} severity={"warning"} handlesnanckClose={this.handlesnanckClose} message={labels.messages.apierror} />
                    {/* {console.log(this.props.shortterm)} */}
                    {this.state.dropdownvalue == "ShortTerm/LongTerm Taxation" && this.props.shortterm.length != 0 &&
                        <Shortterm {...this.props} data={this.props.shortterm} title={this.props.title} />
                    }
                    {/* {console.log(this.props.elssMaturity,this.props.elssgroupdata)} */}
                    {this.state.dropdownvalue == "ELSS Holding" && this.props.elssMaturity.length != 0 &&
                        <Shortterm {...this.props} data={this.props.elssMaturity} totalshortterm={this.props.totalElssMaturity} title={this.props.elssMaturityTitle} />
                    }
                    {/* {console.log(this.props.elssgroupdata)} */}
                    {this.state.dropdownvalue == "ELSS Transaction" && this.props.elssgroupdata.length != 0 &&
                        <Elss {...this.props} ></Elss>}
                </ErrorBoundary>

            }


        </ErrorBoundary>

        )
    }
}
const mapStateToProps = state => {
    // 
    return {
        sessionID: state.Login.sessionID,
        clientID: state.Login.logindetails.ClientID,
        userID: state.Login.userID,
        date: state.Login.date,
        logindetails: state.Login.logindetails,
        L5ID: state.Login.L5ID,

    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch)
}
const mergedStyle = combineStyles(styles, CommonTableStyle)
export default withStyles(mergedStyle)(connect(mapStateToProps, mapDispatchToProps)(AFR))