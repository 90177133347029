import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography'
import labels from '../../utils/constants/labels';
import explorebasket from '../../utils/assets/explorebasket.png'
import shopping from '../../utils/assets/shopping-bag.png'
import excelicon from '../../utils/assets/excelicon.png'
import { Grid, Divider } from '@material-ui/core';
const styles = theme => ({
    container: {
        boxShadow: " 0px 4px 11px rgba(155, 155, 155, 0.02);border-radius: 10px",
        padding: 8,
        background: "#fff",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        border: '1px solid #EDEFF2;',
        cursor: 'pointer'
    },
    image: {
        width: 36,
        height: 36
    },
    header: {
        textAlign: "start",
        fontWeight: 700,
        fontSize: 15
    },
    subHeader: {
        textAlign: "start",
        fontSize: 12
    }

})
class QuickActionCards extends React.Component {
    constructor() {
        super();
        this.state = {
            quickAction: [
                { id: 1, name: "Explore Products", image: explorebasket, subHeader: "Invest in top shortlisted funds", redirect: { pathname: labels.Path.Basket } },
                { id: 2, name: "Order Status", image: shopping, subHeader: "Check Orders/SIP/STP Status", redirect: { pathname: labels.Path.MFTransaction, state: { data: "MF Order Status", tabValue: 0 } } },
                { id: 3, name: "Mandate", image: excelicon, subHeader: "Setup investment Autopay", redirect: { pathname: labels.Path.Account, state: { data: "myAccount", tabValue: 2 } } }
            ]
        }
    }
    async componentDidMount() {
        await this.setState({
            quickAction: [
                { id: 1, name: "Explore Products", image: explorebasket, subHeader: "Invest in top shortlisted funds", redirect: { pathname: this.props.logindetails.ClientID == 0 ? "" : window.globalConfig.isBasketAllowed && this.props.logindetails.ClientID != 0 ? labels.Path.Basket : labels.Path.Scheme } },
                { id: 2, name: "Order Status", image: shopping, subHeader: "Check Orders/SIP/STP Status", redirect: { pathname: this.props.logindetails.ClientID == 0 ? "" : labels.Path.MFTransaction, state: { data: "MF Order Status", tabValue: 0 } } },
                { id: 3, name: "Mandate", image: excelicon, subHeader: "Setup investment Autopay", redirect: { pathname: this.props.logindetails.ClientID == 0 ? "" : labels.Path.Account, state: { data: "myAccount", tabValue: 2 } } }
            ]
        })
    }
    render() {
        const { classes } = this.props;
        return (
            <Grid container spacing={2}>
                {this.state.quickAction.map((i, index) => {
                    return (
                        <Grid key={index} item md={4} xs={6} lg={3}>
                            <div className={classes.container} style={{ cursor: i.redirect.pathname == '' ? 'default' : "pointer" }} onClick={() => {
                                if (this.props.logindetails.ClientID != 0) {
                                    this.props.history.push(i.redirect)
                                }
                            }} >
                                <Grid container>
                                    <Grid item xs={2}>
                                        <img src={i.image} className={classes.image}></img>
                                    </Grid>
                                    <Grid item xs={10} style={{ paddingLeft: 10 }}>
                                        <Typography className={classes.header}>{i.name}</Typography>
                                        <Typography className={classes.subHeader}>{i.subHeader}</Typography>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    )
                })}


            </Grid>
        )
    }
}
export default withStyles(styles)(QuickActionCards)