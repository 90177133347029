import React from 'react';
import Grid from '@material-ui/core/Grid';
import '../../utils/stylesheets/table/table.css'
import { withStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors/colors';
import { connect } from 'react-redux';
import NoData from "../nodata/nodata";
import '../../utils/stylesheets/common/global/x-ray/transaction.css';
import CommonHeader from '../../components/report_common_header/report_common_header'
import Mftable from '../table/tradingtable'
import Loader from '../loader/loader'
import DeletePopup from '../../components/popup/deletepopup';
import { bindActionCreators } from 'redux';
import combineStyle from '../../utils/stylesheets/combainstyle/combainstyle'
import commonstyletable from '../../utils/stylesheets/table/commontablestyle'
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import secureStorage from '../../utils/securestorage';
import ErrorBoundary from '../Errorhandling/Errorhandle';
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import moment from 'moment';
import getCheckTransactionStatus from '../../api/transactionorderapi/checktransactionstatus';
import constants from '../../utils/constants/apiconstant';
import CommonSnackbar from '../snackbar/snackbar';
import getxSipOrder from '../../api/purchaseorder/xsiporderapi'
import getOneTimeOrder from '../../api/purchaseorder/onetimeorderapi'
import getSTPApidetails from '../../api/stp/stp'
import getSwitchapidetails from '../../api/switch/switchapi'
import getSWPApidetails from '../../api/swp/swpapi';
import getRedemptionApidetails from '../../api/redemption/redemption'
import { render } from 'react-dom';
import { NegativeFinding, openRequestedSinglePopup, replaceNull } from '../../utils/commonfunction';
import Epfreport from '../../containers/epf/epfreport';
import getPaymentGatewayDetails from '../../api/paymentgateway/paymentgateway';
import getOrderStatusForInitPayment from '../../api/paymentgateway/checkforinitpayment';
import getPayment2FA from '../../api/paymentgateway/payment2FA';
import getCheckTransactionOrderStatus from '../../api/transactionorderapi/checktransactionorderstatus';
import Authorise from '../../utils/assets/authorize.png';
import AuthoriseSecondHolder from '../../utils/assets/protection.png';
import PaymentIcon from '@material-ui/icons/Payment';
import getRedeemParameterDetails from '../../api/transactionorderapi/redeemparameter';
let list = [];
const styles = theme => ({
    onGoingContainer: {
        display: "grid",
        gridTemplateColumns: "10% 30% 9% 11% 17% 13% 8% 2%",
        // gap: 10,
        alignItems: "center",
        "& .dataSix": {
            display: "flex",
            flexDirection: "row",
            alignItems: "center"
        },
        "& .dataSix": {
            backgroundColor: colors.BROWN,
            fontSize: 10,
            color: colors.WHITE,
            borderRadius: 5,
            alignItems: "center",
            cursor: "pointer",
            padding: 4,
            textAlign: "center"
        },
        "& .dataSeven": {
            backgroundColor: colors.BROWN,
            fontSize: 10,
            margin: 5,
            color: colors.WHITE,
            borderRadius: 5,
            alignItems: "center",
            cursor: "pointer",
            padding: 4,
            textAlign: "center"
        },
        "& .dataFive": {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: 1
        },
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateAreas: `"${"dataOne"} ${"dataSix"}"
            " ${"dataFour"} ${"dataTwo"}" 
            "${"dataFive"} ${"dataThree"}"
            "${"dataSeven"} ${"dataEight"}"
            " ${"dataNine"} ${"dataTen"}"   
          `,
        }
    },
    sipAndStpContainer: {
        display: "grid",
        gridTemplateColumns: "12% 30% 13% 14% 14% 9% 9%",
        "& .dataSix": {
            backgroundColor: colors.BROWN,
            fontSize: 10,
            margin: 5,
            color: colors.WHITE,
            borderRadius: 5,
            alignItems: "center",
            cursor: "pointer",
            padding: 4,
            textAlign: "center"
        },
        "& .dataSeven": {
            backgroundColor: colors.BROWN,
            fontSize: 10,
            margin: 5,
            color: colors.WHITE,
            borderRadius: 5,
            alignItems: "center",
            cursor: "pointer",
            padding: 4,
            textAlign: "center"
        },

        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateAreas: `"${"dataOne"} ${"dataSix"}"
            " ${"dataFour"} ${"dataTwo"}" 
            "${"dataFive"} ${"dataThree"}"
            "${"dataSeven"} ${"dataEight"}"
            " ${"dataNine"} ${"dataTen"}"   
          `,
            "& .dataFive": {
                alignItems: "baseline"
            },
        }
    },
    line: {
        height: 0.5,
        width: "100%",
        backgroundColor: colors.BORDER
    },
    success: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: colors.GREEN
    },
    pending: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: colors.WARNING
    },
    failure: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: colors.red
    },
    normal: {
        width: 10,
        height: 10,
        borderRadius: 10,
        backgroundColor: colors.BLACK_PRIMARY
    },
    ongoingOrder: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        marginTop: 10,
        [theme.breakpoints.down("920")]: {
            alignItems: "center"
        }
    },
    sip: {
        textAlign: "left",
        paddingTop: 10,
        paddingBottom: 10,
        [theme.breakpoints.down("920")]: {
            textAlign: "center"
        }
    },
    nodata: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        [theme.breakpoints.down("920")]: {
            alignItems: "center"
        }
    },
    epf: {
        textAlign: "left",
        paddingTop: 10,
        paddingBottom: 10,
        [theme.breakpoints.down("920")]: {
            textAlign: "center"
        }
    }
})
let data = []
let sipData = []
let statusStyle = []
class TransactionOrder extends React.Component {
    constructor(props) {
        super();
        this.state = {
            anchorEl: '',
            List: [],
            open: false,
            message: "",
            fullList: [], severity: "warning",
            openCancelPopup: false,
            cancelLoader: false,
            onGoingOrder: [],
            sipStpOrder: [],
            redeemParameter: [], openRedeemPopup: false, redeemLoader: false
        }
    }
    openResubmitPopup = (value) => {
        // console.log(value)
        getRedeemParameterDetails(value.TransNo).then((res) => {
            if (res.status == "S") {
                this.setState({ redeemParameter: { ...res.data, value }, openRedeemPopup: true })
            } else {
                this.setState({ open: true, message: res.message, })
            }
        })

    }
    dataofMFOrder = (transactionOrder) => {
        if (transactionOrder.length != 0) {

            this.setState({
                onGoingOrder: transactionOrder?.filter((val) => val.ReportID == 1).map((item) => {
                    let dataTwo = item.Type.search(/stp/i) != -1 || item.Type.search(/switch/i) != -1 ? `From : ${replaceNull(item.FROM_SCHEME_NAME, "NA")}` : item.SchemeName
                    let subDataTwo = item.Type.search(/stp/i) != -1 || item.Type.search(/switch/i) != -1 || item.Type == "SWP" ? `To : ${replaceNull(item.TO_SCHEME_NAME, "NA")}` : ""
                    let str = dataTwo.split("Failed")
                    let str1 = subDataTwo.split("Failed")
                    let buttonName = item.Type.search(/rede/i) != -1 || item.Type.search(/switch/i) != -1 ? "Authorise Now" : "Initiate Payment"
                    let buttonClick = item.Type.search(/rede/i) != -1 || item.Type.search(/switch/i) != -1 ? this.redemption2FA : this.initializePayment
                    return {
                        ...item, dataOne: item.Date || "NA", dataTwo: <div>{str[0]}<span style={{ color: colors.WARNING }}>{str[1] == "" && "Failed"}</span></div>,
                        subDataTwo: <div>{str1[0]}<span style={{ color: colors.WARNING }}>{str1[1] == "" && 'Failed'}</span></div>,
                        dataThree: item.Type,
                        dataFour: item.Type.search(/rede/i) != -1 || item.Type.search(/switch/i) != -1 ? item.Amount == 0 || item.Amount == "" || item.Amount == "NA" ? item.NoOfUnits == 0 || item.NoOfUnits == "" || item.NoOfUnits == "NA" ? item.AllUnits : NegativeFinding(item.NoOfUnits, "", "", "", "number") : NegativeFinding(item.Amount, "", "", "", "rupee") : NegativeFinding(item.Amount, "", "", "", "rupee"),
                        dataFive: item.Status,
                        dataFiveDot: this.returnClass(item),
                        subDataFive: item.Type == "Purchase" ? item.PaymentStatus : "",
                        dataSix: item.I2FA ? buttonName : "",
                        dataSixValidation: false,
                        dataSixClick: buttonClick,
                        dataSeven: item.IASH ? "Authorize 2nd holder" : "",
                        dataSevenClick: (val) => this.twoFAapi({ ...val, "holder": "2" }),

                        isShowVert: item.IEA,
                        dataSevenValidation: false,
                        isLoader: false
                    }
                })
            })

            this.setState({
                sipStpOrder: transactionOrder?.filter((val) => val.ReportID == 2).map((item) => {
                    // return { ...item, dataOne: item.RegisterDate, dataTwo: item.Type == "STP" || item.Type == "Switch" || item.Type == "SWP" ? `From : ${item.FROM_SCHEME_NAME}` : item.SchemeName, subDataTwo: item.Type == "STP" || item.Type == "Switch" || item.Type == "SWP" ? `To : ${item.TO_SCHEME_NAME}` : "", dataThree: item.Type, dataFour: item.StartDate, dataFive: NegativeFinding(item.Amount, "", "", "", "rupee"), dataSix: item.Type != "STP" && (item.Status == "PENDNEW" || item.Status == 'Pending') ? "Authorize Now" : "", dataSixValidation: item.Status != "PENDNEW" || item.Status != 'Pending' ? false : true, dataSixClick: (val) => this.twoFAapi({ ...val, "holder": "1" }), dataSeven: "Authorize 2nd holder", dataSevenClick: (val) => this.twoFAapi({ ...val, "holder": "2" }), dataSevenValidation: false, isShowVert: false, isLoader: false }
                    let dataTwo = item.Type.search(/stp/i) != -1 || item.Type.search(/switch/i) != -1 || item.Type == "SWP" ? `From : ${item.FROM_SCHEME_NAME}` : item.SchemeName
                    let subDataTwo = item.Type.search(/stp/i) != -1 || item.Type.search(/switch/i) != -1 || item.Type == "SWP" ? `To : ${item.TO_SCHEME_NAME}` : ""
                    let str = dataTwo.split("Failed")
                    let str1 = subDataTwo.split("Failed")

                    return {
                        ...item, dataOne: item.RegisterDate,
                        dataTwo: <div>{str[0]}<span style={{ color: colors.WARNING }}>{str[1] == "" && "Failed"}</span></div>, subDataTwo: <div>{str1[0]}<span style={{ color: colors.WARNING }}>{str1[1] == "" && 'Failed'}</span></div>,
                        dataThree: item.Type, dataFour: item.StartDate,
                        dataFive: NegativeFinding(item.Amount, "", "", "", "rupee"),
                        dataSix: item.I2FA ? "Authorize Now" : "", dataSixValidation: false,
                        dataSixClick: (val) => this.twoFAapi({ ...val, "holder": "1" }),
                        dataSeven: item.IASH ? "Authorize 2nd Holder" : "",
                        dataSevenClick: (val) => this.twoFAapi({ ...val, "holder": "2" }),
                        dataSevenValidation: false, isShowVert: false, isLoader: false
                    }
                })
            })
        } else {
            this.setState({ onGoingOrder: [], sipStpOrder: [] })
        }
    }
    componentDidMount() {
        this.dataofMFOrder(this.props.transactionOrder)
    }
    componentDidUpdate(prev) {
        const { transactionOrder } = this.props;
        if (prev.transactionOrder != transactionOrder) {
            this.dataofMFOrder(transactionOrder)
        }
    }
    redemption2FA = (value) => {
        this.setState({
            onGoingOrder: this.state.onGoingOrder.map((res) => {
                return { ...res, dataSixLoader: value.ID == res.ID ? true : res.dataSixLoader }
            })
        })
        let data = {
            "Clientcode": value.UCC,
            "primaryholder": "1",
            "loopbackURL": "",
            "Internalrefno": value?.UniqueReferenceNo?.toString() == "" ? value?.OrderNo?.toString() : value?.UniqueReferenceNo?.toString(),
            "allowloopBack": ""
        }
        getPayment2FA(data).then(
            (item) => {
                this.setState({
                    onGoingOrder: this.state.onGoingOrder.map((res) => {
                        return { ...res, dataSixLoader: value.ID == res.ID ? false : res.dataSixLoader }
                    })
                })
                // if () {
                if (item.status == "S" && item.data != null) {

                    openRequestedSinglePopup(item.data)

                } else {
                    this.setState({ open: true, message: item.message, severity: "warning" })
                }
            })
    }
    twoFAapi = (value) => {
        // console.log(value, "click")
        if (value.ReportID == 1) {
            if (value.holder == "1") {
                this.setState({
                    onGoingOrder: this.state.onGoingOrder.map((res) => {
                        return { ...res, dataSixLoader: value.ID == res.ID ? true : false }
                    })
                })
            } else {
                this.setState({
                    onGoingOrder: this.state.onGoingOrder.map((res) => {
                        return { ...res, dataSevenLoader: value.ID == res.ID ? true : false }
                    })
                })
            }
        } else {
            if (value.holder == "1") {
                this.setState({
                    sipStpOrder: this.state.sipStpOrder.map((res) => {
                        return { ...res, dataSixLoader: value.ID == res.ID ? true : false }
                    })
                })
            } else {
                this.setState({
                    sipStpOrder: this.state.sipStpOrder.map((res) => {
                        return { ...res, dataSevenLoader: value.ID == res.ID ? true : false }
                    })
                })
            }
        }

        let data = {
            // "loginId": "1754801",
            // "Password": "ABC123",
            // "Membercode": "17548",
            "Clientcode": value.UCC,
            "primaryholder": value.holder,
            "loopbackURL": "",
            "Internalrefno": value?.UniqueReferenceNo?.toString(),
            "allowloopBack": ""
        }
        getPayment2FA(data).then(
            (item) => {
                if (value.ReportID == 1) {
                    if (value.holder == "1") {
                        this.setState({
                            onGoingOrder: this.state.onGoingOrder.map((res) => {
                                return { ...res, dataSixLoader: value.ID == res.ID ? false : res.dataSixLoader }
                            })
                        })
                    } else {
                        this.setState({
                            onGoingOrder: this.state.onGoingOrder.map((res) => {
                                return { ...res, dataSevenLoader: value.ID == res.ID ? false : res.dataSevenLoader }
                            })
                        })
                    }
                } else {
                    if (value.holder == "1") {
                        this.setState({
                            sipStpOrder: this.state.sipStpOrder.map((res) => {
                                return { ...res, dataSixLoader: value.ID == res.ID ? false : res.dataSixLoader }
                            })
                        })
                    } else {
                        this.setState({
                            sipStpOrder: this.state.sipStpOrder.map((res) => {
                                return { ...res, dataSevenLoader: value.ID == res.ID ? false : res.dataSevenLoader }
                            })
                        })
                    }
                }
                if (item.status == "S") {
                    if (item.data != null) {
                        openRequestedSinglePopup(item.data)

                    } else {
                        this.setState({ open: true, message: item.message, severity: "warning" })

                    }
                } else {
                    this.setState({ open: true, message: item.message, severity: "warning" })
                }
            }
        );
    }
    initializePayment = (value) => {
        this.setState({
            onGoingOrder: this.state.onGoingOrder.map((res) => {
                return { ...res, dataSixLoader: value.ID == res.ID ? true : res.dataSixLoader }
            })
        })
        let data = {
            "Clientcode": value.UCC,
            "primaryholder": "1",
            "loopbackURL": "",
            "Internalrefno": value?.UniqueReferenceNo?.toString(),
            "allowloopBack": ""
        }
        getPayment2FA(data).then(
            (item) => {

                // if () {
                if (item.status == "S" && item.data != null) {
                    this.setState({
                        onGoingOrder: this.state.onGoingOrder.map((res) => {
                            return { ...res, dataSixLoader: value.ID == res.ID ? false : res.dataSixLoader }
                        })
                    })
                    openRequestedSinglePopup(item.data)

                } else {
                    getPaymentGatewayDetails(value.UCC, value.OrderNo).then(
                        (item) => {
                            this.setState({
                                onGoingOrder: this.state.onGoingOrder.map((res) => {
                                    return { ...res, dataSixLoader: value.ID == res.ID ? false : res.dataSixLoader }
                                })
                            })
                            if (item.status == "S") {

                                if (item.data != null) {
                                    openRequestedSinglePopup(item.data)

                                } else {
                                    this.setState({ open: true, message: item.message, severity: "warning" })

                                }
                            } else {
                                this.setState({ open: true, message: item.message, severity: "warning" })
                            }
                        }
                    );

                }
                // } else {
                //     this.setState({ open: true, message: item.message, severity: "warning" })
                // }
            }
        );
    }
    returnClass = (item) => {
        const { classes } = this.props
        if (item.OrderStatus != undefined) {
            if (item?.OrderStatus != undefined && item?.OrderStatus.toLowerCase() == "valid" && item.Status.toLowerCase() == "allotment done") {
                return classes.success
            } else if (item?.OrderStatus != undefined && item?.OrderStatus.toLowerCase() == "valid" && item.Status.toLowerCase() != "allotment done") {
                return classes.pending
            } else if (item?.OrderStatus != undefined && item?.OrderStatus.toLowerCase() == "invalid") {
                return classes.failure
            } else if (item.Status != undefined && item.Status == "Pending" || item.Status == "On-Going") {
                return classes.pending
            } else if (item.Status != undefined && item.Status == "Failed") {
                return classes.failure
            } else if (item.Status != undefined && item.Status == "Success") {
                return classes.success
            }
            else {
                return classes.normal
            }
        }
        else {
            return classes.normal
        }
    }

    handleClick = (value) => {
        // console.log(list)
        this.setState({
            openCancelPopup: true,
            List: value
        })
    };

    handleToastClose = (reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    }

    handleCancelOrder = () => {

        //This setState used for hide a popup
        this.setState({
            anchorEl: null,
        })
        //DID Loader ON FUNCTIONALITY
        if (this.state.List != null && this.state.List != "" && this.state.List != undefined) {
            let mfOrderStatus = this.state.onGoingOrder.map((i) => {

                return {
                    ...i, isLoader: this.state.List.OrderNo == i.OrderNo ? true : i.isLoader
                }


            })
            this.setState({
                onGoingOrder: mfOrderStatus.filter((val) => val.ReportID == 1),
                // sipStpOrder: mfOrderStatus.filter((val) => val.ReportID == 2),
            })
        }
        this.setState({ cancelLoader: true })
        // console.log(this.state.List)
        if (this.state.List.Type == "SIP" || this.state.List.Type == "XSP") {
            getxSipOrder(constants.sipPurchase.CancelTransactionCode, this.state.List.UCC, "", constants.sipPurchase.InternalRefNo, constants.sipPurchase.TransMode,
                constants.sipPurchase.DpTxnMode, "", "", constants.sipPurchase.FrequencyAllowed, Number(this.state.List.Amount), 0, constants.sipPurchase.Remarks, "",
                "N", this.props.logindetails.SubBrokerCode, this.props.logindetails.EUIN, "N", constants.sipPurchase.DPC, constants.sipPurchase.RegId, constants.sipPurchase.IPAdd, constants.sipPurchase.Param1,
                constants.sipPurchase.Param2, constants.sipPurchase.Param3, this.state.List.SchemeID, this.props.logindetails.ClientID, 0, constants.Common.Source).then((res) => {

                    //DID Loader OFF FUNCTIONALITY
                    this.setState({ cancelLoader: false, openCancelPopup: false })

                    if (this.state.List != null && this.state.List != "" && this.state.List != undefined) {
                        let mfOrderStatus = this.state.onGoingOrder.map((i) => {

                            return {
                                ...i, isLoader: this.state.List.OrderNo == i.OrderNo ? false : i.isLoader
                            }


                        })
                        this.setState({
                            onGoingOrder: mfOrderStatus.filter((val) => val.ReportID == 1),
                            // sipStpOrder: mfOrderStatus.filter((val) => val.ReportID == 2),
                        })
                    }
                    this.setState({ open: true, message: res.message, })
                    if (res.status === "S") {
                        //API RESPONSIVE STORED IN STATE
                        this.setState({ severity: "success" })
                    } else {
                        this.setState({ severity: "warning" })
                    }
                })
        } else if (this.state.List.Type === "Purchase") {
            getOneTimeOrder(constants.oneTime.CancelTransCode, this.state.List.OrderNo, this.state.List.UCC, "", this.state.List.SchemeID, constants.oneTime.BuySell, constants.oneTime.BuySellType, constants.oneTime.DPTxn, Number(this.state.List.Amount),
                constants.oneTime.Qty, constants.oneTime.AllRedeem, "", constants.oneTime.Remarks, constants.oneTime.KYCStatus, constants.oneTime.RefNo,
                this.props.logindetails.SubBrokerCode, this.props.logindetails.EUIN, "N", constants.oneTime.MinRedeem, constants.oneTime.DPC, constants.oneTime.IPAdd, constants.oneTime.Param1, constants.oneTime.Param2,
                constants.oneTime.Param3, this.props.logindetails.ClientID, constants.Common.Source).then((res) => {
                    // if(res.status==="S"){
                    //DID Loader OFF FUNCTIONALITY
                    this.setState({ cancelLoader: false, openCancelPopup: false })

                    if (this.state.List != null && this.state.List != "" && this.state.List != undefined) {
                        let mfOrderStatus = this.state.onGoingOrder.map((i) => {

                            return {
                                ...i, isLoader: this.state.List.OrderNo == i.OrderNo ? false : i.isLoader
                            }


                        })
                        this.setState({
                            onGoingOrder: mfOrderStatus.filter((val) => val.ReportID == 1),
                            // sipStpOrder: mfOrderStatus.filter((val) => val.ReportID == 2),
                        })
                    }

                    //API RESPONSIVE STORED IN STATE
                    this.setState({ open: true, message: res.message, })
                    // }
                })

        } else if (this.state.List.Type === "Redemption") {
            getRedemptionApidetails(constants.Redemption.CancelTransCode, this.state.List.OrderNo,
                this.state.List.UCC, "",
                constants.Redemption.BuySell, constants.Redemption.BuySellType,
                constants.Redemption.DPTxn, Number(this.state.List.Amount), 0,
                "N", "", constants.Redemption.Remarks
                , constants.Redemption.KYCStatus, "REF_0001", this.props.logindetails.SubBrokerCode,
                this.props.logindetails.EUIN, constants.Redemption.EUINVal,
                constants.Redemption.MinRedeem, constants.Redemption.DPC, secureStorage.getItem("ip"),
                constants.Redemption.Param1, constants.Redemption.Param2, constants.Redemption.Param3, this.state.List.SchemeID, this.props.logindetails.ClientID, constants.Common.Source).then((res) => {
                    // if(res.status==="S"){
                    //DID Loader OFF FUNCTIONALITY
                    this.setState({ cancelLoader: false, openCancelPopup: false })

                    if (this.state.List != null && this.state.List != "" && this.state.List != undefined) {
                        let mfOrderStatus = this.state.onGoingOrder.map((i) => {

                            return {
                                ...i, isLoader: this.state.List.OrderNo == i.OrderNo ? false : i.isLoader
                            }


                        })
                        this.setState({
                            onGoingOrder: mfOrderStatus.filter((val) => val.ReportID == 1),
                            // sipStpOrder: mfOrderStatus.filter((val) => val.ReportID == 2),
                        })
                    }

                    //API RESPONSIVE STORED IN STATE
                    this.setState({ open: true, message: res.message, })
                    // }
                })
        }
        else if (this.state.List.Type === "STP OUT") {
            getSTPApidetails(this.state.List.UCC, "", "",
                constants.STP.BuySellType, constants.STP.CancelTransCode, "",
                moment().format("DD/MM/YYYY"), "", 0,
                Number(this.state.List.Amount), "N", this.props.logindetails.SubBrokerCode,
                constants.STP.EUINDeclaration, this.props.logindetails.EUIN, constants.STP.Remarks,
                this.state.List.SchemeID, 0, this.props.logindetails.ClientID, constants.Common.Source, "cancel", this.state.List.OrderNo).then((res) => {
                    // if(res.status==="S"){
                    //DID Loader OFF FUNCTIONALITY
                    this.setState({ cancelLoader: false, openCancelPopup: false })

                    if (this.state.List != null && this.state.List != "" && this.state.List != undefined) {
                        let mfOrderStatus = this.state.onGoingOrder.map((i) => {

                            return {
                                ...i, isLoader: this.state.List.OrderNo == i.OrderNo ? false : i.isLoader
                            }


                        })
                        this.setState({
                            onGoingOrder: mfOrderStatus.filter((val) => val.ReportID == 1),
                            // sipStpOrder: mfOrderStatus.filter((val) => val.ReportID == 2),
                        })
                    }

                    //API RESPONSIVE STORED IN STATE
                    this.setState({ open: true, message: res.message, })
                    // }
                })

        }
        else if (this.state.List.Type == "SWP") {
            getSWPApidetails(this.state.List.UCC, "", constants.SWP.CancelTransCode, "", moment("").format("DD/MM/YYYY"), constants.SWP.NoOfWithdrawls, "", Number(this.state.List.Amount), 0,
                "N", this.props.logindetails.SubBrokerCode,
                constants.SWP.EUINDeclaration, this.props.logindetails.EUIN, constants.SWP.Remarks,
                this.props.logindetails.ClientID, this.state.List.SchemeID, constants.Common.Source, "cancel", this.state.List.OrderNo)
                .then((res) => {
                    // if(res.status==="S"){
                    //DID Loader OFF FUNCTIONALITY
                    this.setState({ cancelLoader: false, openCancelPopup: false })

                    if (this.state.List != null && this.state.List != "" && this.state.List != undefined) {
                        let mfOrderStatus = this.state.onGoingOrder.map((i) => {

                            return {
                                ...i, isLoader: this.state.List.OrderNo == i.OrderNo ? true : i.isLoader
                            }


                        })
                        this.setState({
                            onGoingOrder: mfOrderStatus.filter((val) => val.ReportID == 1),
                            // sipStpOrder: mfOrderStatus.filter((val) => val.ReportID == 2),
                        })
                    }

                    //API RESPONSIVE STORED IN STATE
                    this.setState({ open: true, message: res.message, })
                    // }
                })
        }
        else if (this.state.List.Type == "Switch Out") {
            getSwitchapidetails(this.state.List.OrderNo,
                constants.Switch.MemberId,
                this.props.logindetails.ClientID, this.state.List.SchemeID,
                this.state.List.UCC, Number(this.state.List.Amount),
                0, constants.Switch.BuySell,
                constants.Switch.BuySellType,
                constants.Switch.DPTxn, 0, 0,
                "N", constants.Switch.CancelTransCode,
                "",
                constants.Switch.KYCStatus,
                constants.Switch.FirstOrderToday, this.props.logindetails.SubBrokerCode, constants.Switch.EUINDeclaration, constants.Switch.MinRedeem, constants.Switch.EUINVal, secureStorage.getItem("ip")
                , constants.Switch.PassKey, constants.Switch.Password, constants.Switch.Param1, constants.Switch.Param2, constants.Switch.Param3, this.props.logindetails.EUIN
                , constants.Switch.Remarks, 0, constants.Common.Source, constants.Switch.TransCode).then((res) => {
                    // if(res.status==="S"){
                    //DID Loader OFF FUNCTIONALITY
                    this.setState({ cancelLoader: false, openCancelPopup: false })

                    if (this.state.List != null && this.state.List != "" && this.state.List != undefined) {
                        let mfOrderStatus = this.state.onGoingOrder.map((i) => {

                            return {
                                ...i, isLoader: this.state.List.OrderNo == i.OrderNo ? true : i.isLoader
                            }


                        })
                        this.setState({
                            onGoingOrder: mfOrderStatus.filter((val) => val.ReportID == 1),
                            // sipStpOrder: mfOrderStatus.filter((val) => val.ReportID == 2),
                        })
                    }

                    // API RESPONSIVE STORED IN STATE
                    this.setState({ open: true, message: res.message, })
                    // }
                })
        }
    }
    handleCheckStatus = (List) => {
        //This setState used for hide a popup
        this.setState({
            anchorEl: null,
        })

        // DID Loader ON FUNCTIONALITY  .....
        if (List != null && List != "" && List != undefined) {
            let mfOrderStatus = this.state.onGoingOrder.map((i) => {
                // if (List.OrderNo == i.OrderNo) {
                return {
                    ...i, isLoader: List.OrderNo == i.OrderNo ? true : i.isLoader
                }


            })

            this.setState({
                onGoingOrder: mfOrderStatus
            })
        }

        getCheckTransactionStatus(List.UCC, List.OrderNo, constants.TransactionOrderStatus.Segment).then((response) => {
            if (response.status === "S") {

                // DID Loader OFF FUNCTIONALITY
                if (List != null && List != "" && List != undefined) {
                    let mfOrderStatus = this.state.onGoingOrder.map((i) => {
                        // if (List.OrderNo == i.OrderNo) {
                        return {
                            ...i, isLoader: List.OrderNo == i.OrderNo ? true : i.isLoader, subDataFive: List.OrderNo == i.OrderNo ? response.data || labels.messages.NA : i.subDataFive
                        }
                    })

                    this.setState({
                        onGoingOrder: mfOrderStatus
                    })
                }
                this.setState({
                    open: true,
                    severity: "success",
                    message: response.data != "" && response.data != null ? response.data : response.message,
                })
            } else {
                if (List != null && List != "" && List != undefined) {
                    let mfOrderStatus = this.state.onGoingOrder.map((i) => {
                        // if (List.OrderNo == i.OrderNo) {
                        return {
                            ...i, isLoader: List.OrderNo == i.OrderNo ? true : i.isLoader
                        }


                    })

                    this.setState({
                        onGoingOrder: mfOrderStatus
                    })
                }
                this.setState({
                    open: true,
                    severity: "warning",
                    message: response.message,
                })
            }
        })
    }
    handleOrderStatus = (List) => {
        //This setState used for hide a popup
        this.setState({
            anchorEl: null,
        })

        // DID Loader ON FUNCTIONALITY  .....
        if (List != null && List != "" && List != undefined) {
            let mfOrderStatus = this.state.onGoingOrder.map((i) => {
                // if (List.OrderNo == i.OrderNo) {
                return {
                    ...i, isLoader: List.OrderNo == i.OrderNo ? true : i.isLoader
                }


            })

            this.setState({
                onGoingOrder: mfOrderStatus
            })
        }

        getCheckTransactionOrderStatus(List.UCC, List.OrderNo, constants.TransactionOrderStatus.Segment).then((response) => {
            if (response.status === "S") {

                // DID Loader OFF FUNCTIONALITY
                if (List != null && List != "" && List != undefined) {
                    let mfOrderStatus = this.state.onGoingOrder.map((i) => {
                        // if (List.OrderNo == i.OrderNo) {
                        return {
                            ...i, isLoader: List.OrderNo == i.OrderNo ? true : i.isLoader, dataFive: List.OrderNo == i.OrderNo ? response.data || labels.messages.NA : i.dataFive
                        }
                    })

                    this.setState({
                        onGoingOrder: mfOrderStatus
                    })
                }
                this.setState({
                    open: true,
                    severity: "success",
                    message: response.data != "" && response.data != null ? response.data : response.message,
                })
            } else {
                if (List != null && List != "" && List != undefined) {
                    let mfOrderStatus = this.state.onGoingOrder.map((i) => {
                        // if (List.OrderNo == i.OrderNo) {
                        return {
                            ...i, isLoader: List.OrderNo == i.OrderNo ? true : i.isLoader
                        }


                    })

                    this.setState({
                        onGoingOrder: mfOrderStatus
                    })
                }
                this.setState({
                    open: true,
                    severity: "warning",
                    message: response.message,
                })
            }
        })
    }
    reSubmitOrder = () => {
        // console.log(this.state.redeemParameter)
        this.setState({ redeemLoader: true })
        getRedemptionApidetails(this.state.redeemParameter.transCode, this.state.redeemParameter.orderId
            ,
            this.state.redeemParameter.value.UCC, this.state.redeemParameter.schemeCd
            ,
            this.state.redeemParameter.buySell, this.state.redeemParameter.buySellType,
            this.state.redeemParameter.dpTxn, Number(this.state.redeemParameter.orderVal), 0,
            "N", this.state.redeemParameter.folioNo, constants.Redemption.Remarks
            , constants.Redemption.KYCStatus, "REF_0001", this.props.logindetails.SubBrokerCode,
            this.state.redeemParameter.euin, this.state.redeemParameter.euinVal,
            this.state.redeemParameter.minRedeem, this.state.redeemParameter.dpc, secureStorage.getItem("ip"),
            constants.Redemption.Param1, constants.Redemption.Param2, constants.Redemption.Param3,
            this.state.redeemParameter.schemeID,
            this.props.logindetails.ClientID, constants.Common.Source, true).then((res) => {
                // if(res.status==="S"){
                //DID Loader OFF FUNCTIONALITY
                this.setState({ redeemLoader: false, openRedeemPopup: false })

                if (this.state.List != null && this.state.List != "" && this.state.List != undefined) {
                    let mfOrderStatus = this.state.onGoingOrder.map((i) => {

                        return {
                            ...i, isLoader: this.state.List.OrderNo == i.OrderNo ? false : i.isLoader
                        }


                    })
                    this.setState({
                        onGoingOrder: mfOrderStatus.filter((val) => val.ReportID == 1),
                        // sipStpOrder: mfOrderStatus.filter((val) => val.ReportID == 2),
                    })
                }

                //API RESPONSIVE STORED IN STATE
                this.setState({ open: true, message: res.message, })
                // }
            })
    }
    onClose = () => {
        this.setState({
            openCancelPopup: false, openRedeemPopup: false
        })
    };

    render() {
        const { classes } = this.props;
        // { console.log(this.state.onGoingOrder) }
        try {
            return (
                <div style={{ marginBottom: "3%" }}>
                    {this.props.loader == true ?
                        <Loader page="skeletonreport" />
                        :
                        <ErrorBoundary>
                            <div className={classes.line}></div>
                            <div className={classes.ongoingOrder}>
                                <span style={{ fontWeight: "bold" }}>Ongoing Orders</span>
                                {this.state.onGoingOrder.length != 0 && <span style={{ fontSize: 10 }}>{this.state.onGoingOrder.length} Order{this.state.onGoingOrder.length > 1 ? "s" : ""} in last 15 days</span>}
                            </div>
                            {this.state.onGoingOrder.length != 0 ?
                                <Epfreport showfilter={false} checkArrayIndex={4} checkKey={"Type"} checkValue="Purchase" showHeader={false} showdownload={false} singleScheme={"List"} popupList={[{ "label": "Check Payment Status", onClick: this.handleCheckStatus, showIn: "purchase" }, { "label": "Check Order Status", onClick: this.handleOrderStatus, showIn: "All" }, { "label": "Cancel Order", onClick: this.handleClick, showIn: "purchase sip order" }]} multipleScheme={"Lists"} grid={classes.onGoingContainer} loader={this.props.loader} data={this.state.onGoingOrder} title={[{ "header": "Date", "subHeader": "", align: "left" }, { header: "Scheme Name", align: "left", subHeader: "" }, { header: "Txn Type", align: "left", subHeader: "" }, { "header": "Amount / Units", align: "left", subHeader: "" }, { "header": "Status", align: "left", subHeader: "" }]}></Epfreport>
                                : <NoData page="report" className={classes.sip}></NoData>}<div className={classes.nodata}>
                                <span style={{ fontWeight: "bold" }}>SIPs / STPs </span>
                                {this.state.sipStpOrder.length != 0 && <span style={{ fontSize: 10 }}>{this.state.sipStpOrder.length} Pending Authorization{this.state.sipStpOrder.length > 1 ? "s" : ""}</span>}
                            </div>
                            {this.state.sipStpOrder.length != 0 ? <Epfreport showfilter={false} showHeader={false} showdownload={false} singleScheme={"List"} popupList={[{ "label": "Cancel Order", onClick: this.handleClick }]} multipleScheme={"Lists"} grid={classes.sipAndStpContainer} loader={this.props.loader} data={this.state.sipStpOrder} title={[{ "header": "Register Date", "subHeader": "", align: "left" }, { header: "Scheme Name", align: "left", subHeader: "" }, { header: "Type", align: "left", subHeader: "" }, { "header": "Start Date", align: "left", subHeader: "" }, { "header": "Amount", align: "right", subHeader: "" }]}></Epfreport>
                                : <NoData page="report" className={classes.epf}></NoData>}
                        </ErrorBoundary>
                    }
                    <DeletePopup header={"Confirmation"} onClick={this.reSubmitOrder} loader={this.state.redeemLoader} open={this.state.openRedeemPopup} text={"Are you sure want to submit order?"} onClose={this.onClose}></DeletePopup>
                    <DeletePopup header={"Confirmation"} onClick={this.handleCancelOrder} loader={this.state.cancelLoader} open={this.state.openCancelPopup} text={labels.runningSip.cancelOrderpopup} onClose={this.onClose} />
                    <CommonSnackbar severity={this.state.severity} timer={20000} handlesnanckClose={this.handleToastClose} open={this.state.open} message={this.state.message} />
                </div >
            )
        }
        catch (err) {
            // 
            return <Redirect to={labels.Path.NotFound} />

        }
    }
}
const mapStateToProps = state => ({
    schemeListing: state.TransactionOrderReport.transactionOrder,
    accountDetails: replaceNull(state.ProfileDetails.AllProfileDetails?.accountDetails, "NA") != "NA" && state.ProfileDetails.AllProfileDetails?.accountDetails,
    uccList: state.ProfileDetails.AllProfileDetails.length != 0 && state.ProfileDetails.AllProfileDetails?.uccList != undefined && state.ProfileDetails.AllProfileDetails?.uccList?.length != 0 ? replaceNull(state.ProfileDetails.AllProfileDetails.uccList != "NA" ? state.ProfileDetails.AllProfileDetails.uccList.filter(
        (ele, ind) =>
            ind ===
            state.ProfileDetails.AllProfileDetails.uccList.findIndex((elem) => elem.ClientCode === ele.ClientCode)
    ).map((item) => {
        return { ...item, "Name": `${item.DisplayName} ( ${item.ClientCode} )`, "L5ID": item.ClientCode, "Value": item.ClientCode }
    }) : [], "NA") : [],
    // isTransactionOrderLoading: state.TransactionOrderReport.isTransactionOrderLoading,
    logindetails: state.Login.logindetails
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({}, dispatch)
}
const mergestyles = combineStyle(styles, commonstyletable)
export default withStyles(mergestyles)(connect(mapStateToProps, mapDispatchToProps)(TransactionOrder));


// class ThreeDot extends React.Component {
//     render() {
//         return (
//             <div>
//                 {!this.props.loader ?
//                     <div className="icons" onClick={this.props.handleClick} ></div>
//                     :
//                     <CircularProgress style={{ color: window.globalConfig.color.brown, marginLeft: '1.2vw' }} size={24} />
//                 }

//             </div>
//         )
//     }
// }
