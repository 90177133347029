import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import "../../utils/stylesheets/sidemenu/sidemenu.css";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import TextInput from "../../components/textinput/textinput";
import labels from "../../utils/constants/labels";
// import colors, { LABLE_GRAY } from "../../utils/colors/colors";
import { getL5reportDetails, storeL5Report } from "../../redux/actions/L5Report/L5ReportAction";
import Dialog from "@material-ui/core/Dialog";
import storage from "redux-persist/lib/storage";
import { scrollToTopPage } from '../../utils/commonfunction';
import {
  clientIDchange,
  getFamily,
  TradeL5IDDetails,
  updateScroll
} from "../../redux/actions/login/loginaction";
import {
  loginsession,
  accountselection,
  getLogin,
  ResetPreventAPICallAction,
  backupAPIStatus,
  updateLoginTime
} from "../../redux/actions/login/loginaction";
import {
  getAllBrokerList,
  getTransactionDropdowns,
  getAlterativeAsset,
  //  getAssetNameListdata,
  // getFDRNumberList
} from "../../redux/actions/DataEntry/common_dropdown_equity";
import { getRealEstateList, getBullionList } from '../../redux/actions/DataEntry/real_estate_report';
import {
  getAllDropdownDetails,
  getAllSchemeDetails,
  getAllMasterDetails,
  dropdownvalue,
} from "../../redux/actions/dropdown/dropdownaction";
import { getPortfolioxrayDetails } from "../../redux/actions/portfolioxray/portfolioxray";
import { getPortfolio, getAssetWisePortfolio } from "../../redux/actions/portfolio/portfolioaction";
import { getHeaderDetails } from "../../redux/actions/cardmenu/cardmenuacion";
import { getSummaryDetails } from "../../redux/actions/summary/summaryaction";
import { getSIPTrackerDetails } from "../../redux/actions/siptracker/siptracteraction";
import { getCapitalGainDetails } from "../../redux/actions/capitalgain/capitalgain";
import { getAllInstrumentDetails } from "../../redux/actions/summary/switchdonutaction";
import { GetAllCart } from "../../redux/actions/cart/getallcartaction";
import { getMandateDetails } from "../../redux/actions/mandate/mandateaction";
import { dateChage } from "../../redux/actions/login/loginaction";
import { currentDate, replaceNull } from "../../utils/commonfunction";
import { getProfitLossDetails } from "../../redux/actions/profitloss/profitlossaction";
import { getTransactionListing } from "../../redux/actions/transaction/actions";
import { getTransactionOrderReport } from "../../redux/actions/transactionorderreport/transactionorderreport";
import { getUpcommingSIPReport } from "../../redux/actions/upcomming/upcommingsipaction";
import { getShorttermDetails } from "../../redux/actions/afr/shorttermaction";
import { getExitLoadaDetails } from "../../redux/actions/afr/exitloadaction";
import { getELSSDetails } from "../../redux/actions/afr/elssaction";
import moment from "moment";
// import { ProductAction } from "../../redux/actions/product/productaction";
import secureStorage from "../../utils/securestorage";
import { getEmptyAction } from "../../redux/actions/login/loginaction";
import { GetPortfolioReport } from "../../redux/actions/portfolioreport/portfolioreportaction";
import { getAssetAllocationDetails } from "../../redux/actions/assetallocation/assetallocationaction.js";
import { getMarketCapAllocationDetails } from "../../redux/actions/marketcapallocation/marketcapallocation";
import { getXraywithRedemption } from "../../redux/actions/xraywithredemption/xraywithredemptionaction";
import { getProfileDetails } from "../../redux/actions/profile/profile";
import { getRunningSIP } from "../../redux/actions/runningsip/runningsip";
import ErrorBoundary from "../../components/Errorhandling/Errorhandle";
import { getRiskQuestionReport } from "../../redux/actions/riskquestion/riskquestion";
import { getKycProfileAction, emptyKycData } from "../../redux/actions/profile/kyc_details_action";
import { GetMyPlanDetails } from "../../redux/actions/profile/profile";
import constants from "../../utils/constants/apiconstant";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Sessionlock from "../../utils//assets/sessionlockicon.png";
import Buttons from "../../components/custombutton/button";
import { passValidator, requireValidator } from "../../utils/commonfunction";
import CommonSnackBar from "../snackbar/snackbar";
import { Asset } from "../../utils/mainasset";
import { getNPSAndInsuranceDropdownList } from "../../redux/actions/DataEntry/common_dropdown_equity";
import { getTrackDetails } from "../../redux/actions/track/trackDetails";
import { getAlertNotificationDetails } from "../../redux/actions/alert/alertaction";
import { getMaturityTrackerDetails } from "../../redux/actions/maturitytracker/maturitytrackeraction";
import getL5report from "../../api/L5report/L5report";
// import { async } from "q";
// import { enableLoaderForAllPages } from "../../redux/actions/login/loginresetsuccessaction";
import logOutUser from "../../api/login/logout";
import IdleTimeOutHandler from "../../utils/idletimeouthandler";
import { getDashboardAssetAllocation } from "../../redux/actions/dashboard/dashboard_assetallocation";
import getLoginDetails from "../../api/login/loginapi";
import { getEquityUccProfileDetails } from "../../redux/actions/profile/equityucc_profile";
const styles = (theme) => ({
  sessionExpiredPopup: {
    '& .MuiDialog-container': {
      backdropFilter: 'blur(12px)',
    }
  },
  sessionBox: {
    maxheight: 250,
    width: 370,
    [theme.breakpoints.down(600)]: {
      width: "auto",
      paddingLeft: 11,
      paddingRight: 11
    },
  },
  squareImage: {
    display: "flex",
    borderRadius: "50% !important",
    flexDirection: "row-reverse",
    margin: "5px",
  },
  lockImageContainer: {
    position: "absolute",
    margin: "8px 26px",
  },
  header: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: window.globalConfig.color.PRIMARY,
    [theme.breakpoints.down('xs')]: {
      width: "115%",
      padding: "8px"
    }
  },
  userNametext: {
    color: window.globalConfig.color.PRIMARY,
    fontWeight: "bold",
    fontSize: "14px",
  },
  logoSize: {
    width: 210,
    // height: 44,
    // marginLeft: "13% !important",
    // marginTop: 10,
    // height:57,
    // padding: '5.5% 0% 1% 0%',
    //....finnovate login logo only this property use that.....
    padding: '2% 1% 2% 1%',
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    //  marginTop:8,
    [theme.breakpoints.down("xs")]: {
      width: 137,
      marginTop: "-4px",
    },
  },
  expiredMessage: {
    fontSize: "16px",
    color: window.globalConfig.color.LABLE_GRAY,
  },
  popupBody: {
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "2em",
  },
  icon: {
    fontSize: 26,
    color: window.globalConfig.color.cyanBluish,
    width: "150px",
    height: "114px",
  },
  Container: {
    display: "grid",
    gap: 5,
  },
  notButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    color: window.globalConfig.color.PRIMARY,
    fontWeight: "400px",
    cursor: "pointer",
    fontSize: "14px",
  },
  buttoncontainer: {
    display: "inline-flex",
    flexDirection: "column",
    gap: "10px",
    paddingBottom: "40px",
  },
});
let apiResponse = []
class GlobalApicall extends React.Component {
  constructor() {
    super();
    this.state = {
      index: 0,
      selectedcarosel: 0,
      opensession: false,
      opensessionTemp: true,

      startindex: 0,
      endindex: 4,
      showcarousel: false,
      loader: false,
      open: false,
      message: "",
      severity: "success",
      name: "",
      status: "S",
      password: "",

      passwordError: "",
      showPassword: false,

      confirmPassword: "",
      confirmPasswordError: "",
      showConfirmPassword: false,
      logindetails: secureStorage.getItem("loginDetails"),
      isActive: true,
      isLogout: false
    };
  }
  handleClickShowPassword = () => {
    const { showPassword } = this.state;
    this.setState({ showPassword: !showPassword });
  };
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  onPasswordKeypress = (event) => {
    if (event.keyCode == 32) {
      event.preventDefault();
    } else {
    }
  };
  passwordChange = (e) => {
    // if()
    const passwordError = passValidator(e.target.value);
    if (passwordError == "") {
      this.setState({ password: e.target.value, passwordError: "" });
    } else {
      this.setState({ passwordError: passwordError });
    }
  };
  handleClickConfirmPassword = () => {
    const { showConfirmPassword } = this.state;
    this.setState({ showConfirmPassword: !showConfirmPassword });
  };
  confirmPasswordChange = (e) => {
    // if()
    const confirmPasswordError = passValidator(e.target.value);
    if (confirmPasswordError == "") {
      this.setState({ confirmPassword: e.target.value, confirmPasswordError: "" });
    } else {
      this.setState({ confirmPasswordError: confirmPasswordError });
    }
  };
  onClose = () => {
    this.setState({ open: false });
  };

  handlesessionClose = async () => {
    logOutUser(this.state.logindetails.SessionID, this.props.logindetails.ClientID, "", true).then((res) => {

    }).catch((err) => {
    })
    secureStorage.removeItem(`invalidAuth${this.state.logindetails.SessionID}`)
    secureStorage.removeItem(`nodeToken-${this.state.logindetails.SessionID}`)
    secureStorage.removeItem(`authToken-${this.state.logindetails.SessionID}`)
    secureStorage.removeItem("sessionID")
    storage.removeItem('persist:root')
    this.props.getEmptyAction("logout")
    this.props.ResetPreventAPICallAction()
    secureStorage.removeItem("apiPending")
    secureStorage.removeItem("loginDetails")
    // secureStorage.setItem("apidate", "login")
    // secureStorage.setItem("logout", "done")
    secureStorage.removeItem('user')

    secureStorage.removeItem('clientid')
    secureStorage.removeItem('mainclientid')
    // secureStorage.removeItem('familydetails')
    // secureStorage.removeItem('admin')
    // secureStorage.removeItem('familyname')
    // secureStorage.removeItem('mappingDetails')
    // secureStorage.removeItem('selectedMappingDetails')
    // secureStorage.removeItem('folioNumber')
    // secureStorage.removeItem('details')
    // secureStorage.removeItem('purchaseDetails')
    // secureStorage.removeItem('productid')
    // secureStorage.removeItem('xray')
    // secureStorage.removeItem('mobileno')
    secureStorage.removeItem('email')
    secureStorage.removeItem('purchaseorderstatus')
    this.props.accountselection(0)
    this.props.history.push(labels.Path.Login);
    this.setState({ opensession: false });
  };
  reportapicall = (ClientID, SessionID, L5ID, Date, ProductID, flag, switchType, apiResponse) => {
    let condition = []
    if (switchType == "family") {
      condition = apiResponse.filter(
        (item) => item.productId == ProductID
      )[0]?.apiList.filter((value) => value.familySwitch === true);
    } else if (switchType == "L5") {
      condition = apiResponse.filter(
        (item) => item.productId == ProductID
      )[0]?.apiList.filter((value) => value.l5Switch === true);
    } else {

      condition = apiResponse.filter(
        (item) => item.productId == ProductID
      )[0]?.apiList;
    }
    condition?.filter((item) => item.apiResponse == false)?.map((item) => {
      switch (item.api) {

        case "FamilyDetails":
          return this.props.getFamily(ClientID)
          break;
        case "Dashboard":
          if (switchType != "date") {
            return this.props.getDashboardAssetAllocation(this.props.mainClientId, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"))
          }
          break;
        case "HeaderDetails":

          return this.props.getHeaderDetails(0, 0, ClientID, "", moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), 1, 0, L5ID, 0);
          break;
        case "Portfolio Allocation":
          return this.props.getPortfolio(SessionID, ClientID, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), 1, 0, L5ID, 0);
          break;
        case "Asset Portfolio Allocation":
          return this.props.getAssetWisePortfolio(SessionID, ClientID, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), 1, 0, L5ID, 0);
          break;
        case "PortfolioXray":
          this.props.getPortfolioxrayDetails(0, ClientID, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), 0, 0, 0, 0, SessionID, 100, 0, ProductID, 0, L5ID, 0, flag);
          break;
        case "ProfitLoss":
          if (!window.globalConfig.isUAT) {
            this.props.getProfitLossDetails(0, ClientID, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), "", "", 0, 0, SessionID, 0, ProductID, 0, L5ID, 0, flag);
          }
          break;
        case "Transaction":
          this.props.getTransactionListing(0, ClientID, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), 0, 0, 0, SessionID, 0, 0, 0, "", "", ProductID, 0, L5ID, 0, flag);
          break;
        case "SectorAllocation":
          this.props.getSummaryDetails(ClientID, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), SessionID, 1, ProductID, 0, L5ID, 0, flag);
          break;
        case "SplitByCategoryEquity":
          this.props.getAllInstrumentDetails(ClientID, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), SessionID, 1, ProductID, 0, L5ID, 0, flag);
          break;
        case "SplitByCategoryDebt":
          if (ProductID != 2) {
            this.props.getAllInstrumentDetails(ClientID, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), SessionID, 2, ProductID, 0, L5ID, 0, flag);
          }
          break;
        case "SipTracker":
          //.format("YYYY-MM-DD")
          this.props.getSIPTrackerDetails(ClientID, 0, 0, 0, "", moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), ProductID, 0, L5ID, 0, flag);
          break;
        case "ELSS":
          // format("DD-MM-YYYY")
          this.props.getShorttermDetails(ClientID, 0, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), ProductID, 0, L5ID, 0, true, flag);
          break;
        case "ELSSTransaction":
          // format("DD-MM-YYYY")
          this.props.getELSSDetails(ClientID, 0, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), ProductID, 0, L5ID, 0, flag);
          break;
        // case "ExitLoad":
        //   // format("DD-MM-YYYY")
        //      this.props.getExitLoadaDetails(ClientID,0,moment().format("DD MMM YYYY"),ProductID,0,L5ID,0,flag);
        //  break;
        case "ShortTerm":
          // format("DD-MM-YYYY")
          this.props.getShorttermDetails(ClientID, 0, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), ProductID, 0, L5ID, 0, false, flag);
          break;
        case "RunningSip":
          //format(YYYY-MM-DD)
          this.props.getRunningSIP(ClientID, 0, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), ProductID, 0, L5ID, 0, flag);
          break;
        case "AssetAllocation":
          //format(YYYY-MM-DD)
          this.props.getAssetAllocationDetails(ClientID, 0, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), ProductID, 0, L5ID, 0, flag);
          break;
        case "MaturityTracker":
          return this.props.getMaturityTrackerDetails(ClientID, 0, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), ProductID, 0, L5ID, 0, flag)

        case "MarketCapAllocation":
          //format(YYYY-MM-DD)
          this.props.getMarketCapAllocationDetails(ClientID, 0, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), ProductID, 0, L5ID, 0, flag);
          break;
        case "CapitalGain":
          this.props.getCapitalGainDetails(ClientID, ProductID, 0, L5ID, 0, flag);
          break;
        case "PortfolioReport":
          //.format("DD/MM/YYYY")
          if (ProductID == 1) {
            secureStorage.setItem("mfRedeemUnits", true)
          } else if (ProductID == 2) {
            secureStorage.setItem('stocksRedeemUnits', true)
          } else if (ProductID == 3) {
            secureStorage.setItem("bondsRedeemUnits", true)
          }
          this.props.GetPortfolioReport(ClientID, 0, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), true, ProductID, 0, L5ID, 0, flag);
          break;
        case "XrayWithRedemption":
          //format("YYYY-MM-DD")
          this.props.getXraywithRedemption(ClientID, 0, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), ProductID, 0, L5ID, 0, flag);
          break;
        default:
          break;
      }
    })
  };
  tradereportapicall = (ClientID, SessionID, L5ID, Date, ProductID, flag, switchType, apiResponse, ucc) => {
    let condition = []
    if (switchType == "family") {
      condition = apiResponse.filter(
        (item) => item.productId == ProductID
      )[0]?.apiList.filter((value) => value.familySwitch === true);
    } else if (switchType == "L5") {
      condition = apiResponse.filter(
        (item) => item.productId == ProductID
      )[0]?.apiList.filter((value) => value.l5Switch === true);
    } else {

      condition = apiResponse.filter(
        (item) => item.productId == ProductID
      )[0]?.apiList;
    }
    condition?.filter((item) => item.apiResponse == false)?.map((item) => {
      switch (item.api) {
        case "MFTransactionOrderReport":
          //format("YYYY-MM-DD")
          this.props.getTransactionOrderReport(SessionID, ClientID, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"), 1, 0, L5ID, 0, ucc);
          break;
        case "Upcomming SIP":
          this.props.getUpcommingSIPReport(ClientID, 1, 0, L5ID, 0, ucc);
          break;
        case "Mandate report":
          this.props.getMandateDetails(ClientID, 1, 0, L5ID, 0, ucc);
          break;
        default:
          break;
      }
    })
  };
  allapicall = async (SessionID, ClientID, Date, L5ID, switchType) => {
    // if (this.props.showDateDiff() < 1) {

    // } else {
    let L5report = []
    this.setState({ logindetails: secureStorage.getItem("loginDetails") })
    if (switchType != "none") {
      secureStorage.removeItem("apiPending")
      this.props.ResetPreventAPICallAction()
    }
    if (switchType == "date") {
      this.props.dateChage(Date)
    }
    if (switchType == "L5") {
      this.props.accountselection(L5ID)
    }
    if (switchType == "family") {
      secureStorage.removeItem("apiPending")
      this.props.ResetPreventAPICallAction()
    }
    if (secureStorage.getItem("apiPending") == null || secureStorage.getItem("apiPending").length == 0) {
      let data = labels.apiDetails
      secureStorage.setItem("apiPending", data)
      apiResponse = data
    } else {
      apiResponse = secureStorage.getItem("apiPending")
    }
    this.setState({ opensession: false });
    // this.setState({ opensession: true });
    let commonApiList = apiResponse.filter(
      (item) => item.productId == 0
    )[0]?.apiList;
    if (this.state.logindetails.ClientID != 0 || this.state.logindetails.EntityTypeID != 9) {
      commonApiList = commonApiList
    }
    else {
      commonApiList = commonApiList?.filter((item) => item.isGuestUser == true)
    }
    if (switchType == "L5") {
      commonApiList = commonApiList?.filter((item) => item.l5Switch == true)

    }
    if (switchType == "family") {
      commonApiList = commonApiList?.filter((item) => item.familySwitch == true)
    }
    if (switchType == "none" || this.props.L5report.length != 0 || L5report.length != 0) {


      if (this.state.logindetails.ClientID != 0 || this.state.logindetails.EntityTypeID != 9) {
        ///reports
        apiResponse.map((value) => {
          return (this.reportapicall(ClientID, SessionID, L5report.length == 1 ? L5report[0].L5ID : L5ID, Date, value.productId, value.product, switchType, apiResponse))
        }
        )
        ///trade report
        if (window.globalConfig.isTradeAllowed) {
          this.tradereportapicall(ClientID, SessionID, L5report.length == 1 ? L5report[0].L5ID : L5ID, Date, 11, "trade", switchType, apiResponse);

        }
        secureStorage.setItem("apidate", moment().format("DD MMM YYYY"));
      } else {
        this.props.getDashboardAssetAllocation(this.props.mainClientId, moment(Date, 'DD MMM YYYY').format("DD MMM YYYY"))
      }
      commonApiList
        ?.filter((item) => item.apiResponse == false)
        ?.map(async (item) => {
          switch (item.api) {
            case "BrokerList":
              return this.props.getAllBrokerList()
            case "TransactionTypeDropdown":
              return this.props.getTransactionDropdowns();
            case "NpsandInsuranceDropdown":
              return this.props.getNPSAndInsuranceDropdownList()
            // case "FdrNumberList":
            //   return this.props.getFDRNumberList(ClientID);
            case "AlternativeAsset":
              return this.props.getAlterativeAsset();
            case "alert":
              return this.props.getAlertNotificationDetails(ClientID)
            // case "realEstaeList":
            //   return this.props.getRealEstateList(1, ClientID);
            // case "buliionList":
            //   return this.props.getBullionList(1, ClientID);
            case "Dropdown":
              if (switchType != "date") {
                // return this.props.getAllDropdownDetails(SessionID, ClientID);
                let data = {}
                data.SessionID = SessionID;
                data.ClientID = ClientID;

                await this.props.getAllMasterDetails(data)

                await this.props.getAllSchemeDetails(data)

              } else {
                return ""
              }
            // case "Track":
            //   return this.props.getTrackDetails(ClientID, 1, 0, L5report.length == 1 ? L5report[0].L5ID : L5ID, 0)
            // case "L5Report":
            //   if (switchType != "none") {
            //     return this.props.getL5reportDetails(ClientID);

            //   } else {
            //     return ""

            //   }
            case "Cart":
              if (window.globalConfig.isTradeAllowed) {
                return this.props.GetAllCart(SessionID, ClientID);

              } else {
                return ""
              }
            case "ProfileDetails":
              return this.props.getProfileDetails(ClientID, this.state.logindetails.EmailID);
            case "EquityProfileDetails":
              return this.props.getEquityUccProfileDetails(ClientID,);
            case "RiskQuestionReport":
              if (window.globalConfig.isRiskProfileAllowed) {
                return this.props.getRiskQuestionReport(this.state.logindetails.EmailID, this.state.logindetails.UserName, this.state.logindetails.DOB, this.state.logindetails.PhoneNumber);

              }
              else {
                return ""
              }
            case "Plan":
              if (window.globalConfig.isPlanAllowed) {
                return this.props.GetMyPlanDetails(ClientID);

              } else {
                return ""
              }
            case "KycData":
              if (window.globalConfig.isKycAllowed) {
                if (this.props.formNo != 0 && this.props.formNo != "" && this.props.formNo != null && this.props.formNo != NaN) {
                  return this.props.getKycProfileAction(this.props.formNo, 1);
                }
                else {
                  return this.props.emptyKycData()
                }
              } else {
                // if (this.props.formNo != 0 && this.props.formNo != "" && this.props.formNo != null && this.props.formNo != NaN) {
                return this.props.getKycProfileAction(ClientID, 2);
                // }
              }


            default:
              break;
          }
        });
      // }
    }
  };
  componentDidUpdate(prev) {
    const { logindetails, companyDetails, isReachedBottom, permissionList } = this.props;
    if (prev.logindetails != logindetails) {
      if (logindetails != null) {
        window.globalConfig.enableLatest = logindetails.IsEquityAllowed
        window.globalConfig.enableEquity = logindetails.IsEquityAllowed
      }
    }
    if (prev.permissionList != permissionList) {
      // window.globalConfig.IsFamilyReportAllowed = permissionList[0].IsFamilyReportAllowed
      // window.globalConfig.isKycAllowed = permissionList[0]?.IsKYCAllowed
      // window.globalConfig.isRiskProfileAllowed = permissionList[0]?.isRiskProfileAllowed
      // window.globalConfig.isTradeAllowed = permissionList[0]?.isTradeAllowed
      // window.globalConfig.isBasketAllowed = permissionList[0]?.isBasketAllowed
      window.globalConfig.licenseDate = permissionList[0]?.LED

      // window.globalConfig.isUserCanAdd = permissionList[0]?.isUserCanAdd
      // window.globalConfig.isAppEnable = permissionList[0]?.isAppEnable
      window.globalConfig.isPlanAllowed = permissionList[0]?.isPlanAllowed
    }
    if (prev.isReachedBottom != isReachedBottom) {
      this.props.updateScroll(isReachedBottom)
    }
    if (prev.companyDetails != companyDetails) {
      if (companyDetails != null && Object.keys(companyDetails).length != 0) {
        window.globalConfig.IsFamilyReportAllowed = companyDetails.IsFamilyReportAllowed
        window.globalConfig.color = { ...window.globalConfig.color, PRIMARY: replaceNull(companyDetails.ClientPrimaryColor, "NA"), BROWN: replaceNull(companyDetails.ClientSecondaryColor, "NA"), RosyBrown: replaceNull(companyDetails.ClientTertiaryColor, "NA") }
        window.globalConfig.companyDetails = { ...window.globalConfig.companyDetails, companyName: replaceNull(companyDetails, "NA").NameOfCompany, ShortName: replaceNull(companyDetails, "NA").ShortName, icon: replaceNull(companyDetails, "NA").Icon }
        secureStorage.setItem("colors", window.globalConfig.color)
      }
    }
  }
  componentDidMount() {
    // if(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'){

    // }else{
    // }
    if (this.state.logindetails != null) {
      // window.globalConfig.enableLatest = this.state.logindetails.IsEquityAllowed
      // window.globalConfig.enableEquity = this.state.logindetails.IsEquityAllowed
      // window.globalConfig.isKycAllowed = this.props.permissionList[0]?.IsKYCAllowed
      // window.globalConfig.isRiskProfileAllowed = this.props.permissionList[0]?.isRiskProfileAllowed
      // window.globalConfig.isTradeAllowed = this.props.permissionList[0]?.isTradeAllowed
      // window.globalConfig.isBasketAllowed = this.props.permissionList[0]?.isBasketAllowed
      // // window.globalConfig.isUserCanAdd = this.props.permissionList[0]?.isUserCanAdd
      // // window.globalConfig.isAppEnable = this.props.permissionList[0]?.isAppEnable
      // window.globalConfig.isPlanAllowed = this.props.permissionList[0]?.isPlanAllowed
      // window.globalConfig.IsFamilyReportAllowed = this.props.permissionList[0].IsFamilyReportAllowed
      window.globalConfig.licenseDate = this.props.permissionList[0]?.LED
      if (this.props.companyDetails != null && Object.keys(this.props.companyDetails).length != 0) {
        window.globalConfig.IsFamilyReportAllowed = this.props.companyDetails.IsFamilyReportAllowed
        window.globalConfig.color = { ...window.globalConfig.color, PRIMARY: replaceNull(this.props.companyDetails.ClientPrimaryColor, "NA"), BROWN: replaceNull(this.props.companyDetails.ClientSecondaryColor, "NA"), RosyBrown: replaceNull(this.props.companyDetails.ClientTertiaryColor, "NA") }
        window.globalConfig.companyDetails = { ...window.globalConfig.companyDetails, companyName: replaceNull(this.props.companyDetails, "NA").NameOfCompany, ShortName: replaceNull(this.props.companyDetails, "NA").ShortName, icon: replaceNull(this.props.companyDetails, "NA").Icon }
        secureStorage.setItem("colors", window.globalConfig.color)
      }
    }
    scrollToTopPage();
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
    this.setState({ logindetails: secureStorage.getItem("loginDetails") })
    window.addEventListener('keydown', (e) => {
      if (e.key === "Enter" && this.state.opensession == true) {
        this.unLockUser()
      }
    })
    if (this.props.childFunc != undefined) {
      this.props.childFunc.current = this.allapicall
    }
    if (this.props.tradeFunc != undefined) {
      this.props.tradeFunc.current = this.tradereportapicall
    }
    var session = secureStorage.getItem("user");
    var session_store = session;
    var path = this.props.location.pathname.split("/");
    var expire = secureStorage.getItem("apidate");
    if (this.state.logindetails != null) {
      if (
        this.state.logindetails.expire != undefined ||
        this.state.logindetails.expire != null
      ) {
        this.setState({ opensession: false });
        var curdate = moment(currentDate());
        var exp = moment(this.state.logindetails.expire);
        if (moment(curdate._i).isAfter(exp._i) == false) {
          if (
            this.state.logindetails.SessionID != null &&
            this.state.logindetails.SessionID != undefined &&
            this.state.logindetails.SessionID != ""
          ) {
            if (this.state.logindetails.ClientID != 0 || this.state.logindetails.EntityTypeID != 9) {
              if (moment().format("DD MMM YYYY") != expire) {
                this.props.updateLoginTime()
                this.props.getEmptyAction("", "loader", true);
                this.props.dateChage(moment().format("DD MMM YYYY"));
                this.allapicall(this.state.logindetails.SessionID, this.state.logindetails.ClientID, moment().format("DD MMM YYYY"), this.props.L5ID, "none");
              } else {
                // this.props.loginsession(
                //   secureStorage.getItem("loginDetails"),
                //   secureStorage.getItem("familydetails")
                // );

                this.allapicall(this.state.logindetails.SessionID, this.state.logindetails.ClientID, moment().format("DD MMM YYYY"), this.props.L5ID, "none");
              }
            } else {
              if (moment().format("DD MMM YYYY") != expire) {
                this.props.updateLoginTime()
                this.props.dateChage(moment().format("DD MMM YYYY"));
                this.allapicall(this.state.logindetails.SessionID, this.state.logindetails.ClientID, moment().format("DD MMM YYYY"), this.props.L5ID, "none");
                secureStorage.setItem("apidate", moment().format("DD MMM YYYY"));
              } else {
                this.allapicall(this.state.logindetails.SessionID, this.state.logindetails.ClientID, moment().format("DD MMM YYYY"), this.props.L5ID, "none");
              }
            }
            if (
              (path[1] === "user" && path[2] == "") ||
              (path[1] === "user" && path[2] == undefined)
            ) {
              this.props.history.push(labels.Path.Dashboard);
            }
          } else {
            secureStorage.clear();
            this.props.history.push(labels.Path.Login);
          }
        } else {
          this.props.dateChage(moment().format("DD MMM YYYY"));
          if (window.location.pathname != "/" && !window.location.pathname.includes('/404') && !window.location.pathname.includes("/505") && !window.location.pathname.includes("/admin") && !window.location.pathname.includes("/ekyc")) {
            this.setState({ opensession: true });
          }
        }
      }
      else {
        if (window.location.pathname != "/" && !window.location.pathname.includes('/404') && !window.location.pathname.includes("/505") && !window.location.pathname.includes("/admin") && !window.location.pathname.includes("/ekyc")) {
          this.setState({ opensession: true });
        }
      }
    }
    document.addEventListener("click", (event) => {
      if (window.location.pathname === "/") {
      } else {
        this.setState({ logindetails: secureStorage.getItem("loginDetails") })
        if (this.state.logindetails != null) {
          var expire = this.state.logindetails.expire;
          if (expire == null || expire == undefined) {
            //
          } else {
            var curdate = moment(currentDate());
            var exp = moment(this.state.logindetails.expire);
            if (moment(curdate._i).isAfter(exp._i) === false) {
            } else {
              if (window.location.pathname != "/" && !window.location.pathname.includes('/404') && !window.location.pathname.includes("/505") && !window.location.pathname.includes("/admin") && !window.location.pathname.includes("/ekyc")) {
                this.setState({ opensession: true });
              }
            }
          }
        }
      }
    });
  }
  onPasswordKeypress = (event) => {
    if (event.keyCode == 32) {
      event.preventDefault();
    } else {
    }
  };
  passwordChange = (e) => {
    // if()
    const passwordError = passValidator(e.target.value);
    if (passwordError == "") {
      this.setState({ password: e.target.value, passwordError: "" });
    } else {
      this.setState({ passwordError: passwordError });
    }
  };
  resize() {
    let currentHideNav = window.innerWidth <= 600;
    if (currentHideNav !== this.state.showcarousel) {
      //
      this.setState({ showcarousel: currentHideNav });
    }
  }
  successResponse = (status) => {
    if (status == "R") {
      this.setState({ status: status, password: "" })
    } else {
      //  this.props.getEmptyAction("");
      secureStorage.removeItem("apiPending")
      this.props.dateChage(moment().format("DD MMM YYYY"))
      this.props.accountselection(0)
      this.setState({ opensession: false })
      if (this.state.logindetails != null) {
        window.globalConfig.enableLatest = this.state.logindetails.IsEquityAllowed
        window.globalConfig.enableEquity = this.state.logindetails.IsEquityAllowed
        if (this.props.companyDetails != null) {
          window.globalConfig.color = { ...window.globalConfig.color, PRIMARY: replaceNull(this.props.companyDetails.ClientPrimaryColor, "NA"), BROWN: replaceNull(this.props.companyDetails.ClientSecondaryColor, "NA"), RosyBrown: replaceNull(this.props.companyDetails.ClientTertiaryColor, "NA") }
          window.globalConfig.companyDetails = { ...window.globalConfig.companyDetails, companyName: replaceNull(this.props.companyDetails, "NA").NameOfCompany, ShortName: replaceNull(this.props.companyDetails, "NA").ShortName, icon: replaceNull(this.props.companyDetails, "NA").Icon }
          secureStorage.setItem("colors", window.globalConfig.color)
        }
        var session = secureStorage.getItem("user");
        var clientcode = secureStorage.getItem("mainclientid")
        // this.setState({ Clientcode: clientcode });
        if (this.state.logindetails.ClientID == clientcode) {
          this.allapicall(this.state.logindetails.SessionID, this.state.logindetails.ClientID, moment().format("DD MMM YYYY"), this.props.L5ID == null || this.props.L5ID == undefined ? 0 : this.props.L5ID, "none");
        }
        else {
          this.props?.clientIDchange(clientcode, session.mainUser)
          this.allapicall(this.state.logindetails.SessionID, clientcode, moment().format("DD MMM YYYY"), this.props.L5ID == null || this.props.L5ID == undefined ? 0 : this.props.L5ID, "none");

        }
      }
    }
  };
  failureResponse = (data) => {
    this.setState({
      opensession: true,
      open: true,
      message: data.message,
      severity: "warning",
    });
  };
  unLockUser = async () => {
    try {
      if (this.state.status == "R") {
        let isValid = true
        if (this.state.password == "") {
          isValid = false
          this.setState({ passwordError: labels.messages.required })
        }
        if (this.state.confirmPassword == "") {
          isValid = false
          this.setState({ confirmPasswordError: labels.messages.required })
        }
        if (this.state.password != this.state.confirmPassword) {
          isValid = false
          this.setState({ confirmPasswordError: labels.messages.passwordmismatch })
        }
        if (isValid) {
          this.setState({ loader: true })
          ResetPasswords(this.state.logindetails.EmailID, this.state.password, 0).then((response) => {
            this.setState({ loader: false })

            if (response.status == "S") {
              this.setState({ status: "S" })
            } else {
              this.setState({ open: true, severity: "warning", message: response.message })
            }
          })
        }
      } else {
        if (this.state.password != "") {
          await this.props.getEmptyAction("")
          await getLoginDetails(this.state.logindetails.EmailID,
            this.state.password,).then(async (res) => {
              await this.props.getLogin(
                this.state.logindetails.EmailID,
                this.state.password,
                this.props.history,
                this.successResponse,
                this.failureResponse, res
              );
            })

        } else {
          this.setState({
            passwordError: labels.messages.required
          });
        }
      }
    } catch (err) {
    }



  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this));
    window.removeEventListener("storage", this.localStorageUpdated)
    window.removeEventListener("keydown", (e) => {
      if (e.key === "Enter" && this.state.opensession == true) {
        this.unLockUser()
      }
    })
    document.removeEventListener("click", () => { })
  }
  handlesnanckClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };
  addDefaultSrc(ev) {
    ev.target.src = Asset.logoUrlLogin

  }
  render() {
    const { classes } = this.props;
    return (
      // <div>
      <ErrorBoundary>
        {/* {secureStorage.getItem("sessionID") != null && <IdleTimeOutHandler
          timeOutInterval={15 * 60 * 1000}
          onActive={() => { this.setState({ isActive: true }) }}
          onIdle={() => { this.setState({ opensession: true }) }}
          onLogout={() => { this.setState({ isLogout: false }) }}></IdleTimeOutHandler>} */}
        <Dialog
          className={classes.sessionExpiredPopup}
          open={this.state.opensession}
          // open={true}
          //  onClose={handlekycclose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <div className={classes.sessionBox} ref={this.mySessionPopupDiv}>
            <div className={classes.popupBody}>

              <div className={classes.header}>
                <img src={`data:image/png;base64,${window.globalConfig.companyDetails.icon}`} onError={this.addDefaultSrc} className={classes.logoSize} />
              </div>
              <div className={classes.userNametext}>
                {labels.session.welcomehai} {this.props.UserName}
              </div>
              <div className={classes.expiredMessage}>
                {labels.session.SessionExpieed}
              </div>
              <div className={classes.squareImage}>
                <AccountCircleIcon
                  // id="profile-icon"
                  className={classes.icon}
                />
                <div className={classes.lockImageContainer}>
                  <img src={Sessionlock} />
                </div>
              </div>
              <div className={classes.Container}>
                <TextInput
                  page="TextFieldpassword"
                  type={false}
                  label={this.state.status == "R" ? "New Password" : "Password"}
                  classes={{ input: classes.input }}
                  value={this.state.password}
                  onKeyDown={this.onPasswordKeypress}
                  onChange={this.passwordChange}
                  errormessage={this.state.passwordError}
                  onClick={this.handleClickShowPassword}
                  onMouseDown={this.handleMouseDownPassword}
                />
                {this.state.status == "R" && <TextInput
                  page="TextFieldpassword"
                  type={false}
                  label="Confirm Password"
                  classes={{ input: classes.input }}
                  value={this.state.confirmPassword}
                  onKeyDown={this.onPasswordKeypress}
                  onChange={this.confirmPasswordChange}
                  errormessage={this.state.confirmPasswordError}
                  onClick={this.handleClickConfirmPassword}
                  onMouseDown={this.handleMouseDownPassword}
                />}
                <div className={classes.buttoncontainer}>
                  <div
                    className={classes.notButton}
                    style={{
                      opacity: this.props.loader ? 0.5 : 1,
                      cursor: this.props.loader ? "not-allowed" : "pointer",
                    }}
                    onClick={() => {
                      if (!this.props.loader) {
                        this.handlesessionClose();
                      }
                    }}
                  >
                    {labels.session.Not}
                    <strong style={{ textIndent: "5px" }}>

                      {this.props.UserName}?
                    </strong>
                  </div>
                  <Buttons
                    page={"primary"}
                    onClick={this.unLockUser}
                    marginAlig="0px"
                    name={this.state.status == "R" ? "Reset" : labels.sideMenu.Login}
                    loader={this.props.loader || this.state.loader}
                  />
                </div>
              </div>
            </div>
          </div>
        </Dialog>
        <CommonSnackBar
          open={this.state.open}
          severity={this.state.severity}
          message={this.state.message}
          handlesnanckClose={this.onClose}
        ></CommonSnackBar>
      </ErrorBoundary>
      // </div>
      // </form>
    );
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getLogin,
      getRunningSIP,
      getDashboardAssetAllocation,
      getAlertNotificationDetails,
      getTransactionOrderReport,
      getELSSDetails,
      GetMyPlanDetails,
      GetPortfolioReport,
      getKycProfileAction,
      emptyKycData,
      getRiskQuestionReport,
      getProfileDetails,
      // getAccountStatementDetails,
      getAssetAllocationDetails,
      getProfitLossDetails,
      getMarketCapAllocationDetails,
      getExitLoadaDetails,
      getShorttermDetails,
      getXraywithRedemption,
      getNPSAndInsuranceDropdownList,
      getEmptyAction,
      loginsession,
      accountselection,
      GetAllCart,
      getMandateDetails,
      getCapitalGainDetails,
      getUpcommingSIPReport,
      dropdownvalue,
      getTrackDetails,
      getSummaryDetails,
      getAllInstrumentDetails,
      getSIPTrackerDetails,
      dateChage,
      getPortfolio,
      // ProductAction,
      getHeaderDetails,
      getAllBrokerList,
      getTransactionDropdowns,
      getRealEstateList,
      getBullionList,
      getAssetWisePortfolio,
      getMaturityTrackerDetails,
      updateScroll,
      // getAssetNameListdata,
      // getFDRNumberList,
      getAlterativeAsset,
      getAllDropdownDetails,
      getAllSchemeDetails,
      getAllMasterDetails,
      getPortfolioxrayDetails,
      getTransactionListing,
      getL5reportDetails,
      ResetPreventAPICallAction,
      clientIDchange,
      backupAPIStatus,
      updateLoginTime,
      storeL5Report,
      getFamily,
      getEquityUccProfileDetails
    },
    dispatch
  );
};
const mapStateToProps = (state) => ({
  // sessionId: state.Login.sessionID,
  formNo: state.Login?.formNo == undefined || state.Login.formNo == null ? 0 : state.Login.formNo,
  mainClientId: state.Login.mainClientID,
  // clientId: state.Login.logindetails.ClientID,
  UserName: state.Login.changedUserName,
  permissionList: state.Login.companyDetails.length != 0 ? state.Login.companyDetails : [],
  // loginTime: state.Login.loginTime,
  L5report:
    state.L5report.L5report.length != 0 && state.L5report.L5report != "NA"
      ? state.L5report.L5report.length == 1 ? [...state.L5report.L5report.map((item) => {
        return { ...item, Name: item.PortfolioName };
      })] : [

        ...state.L5report.L5report.map((item) => {
          return { ...item, Name: item.PortfolioName };
        }),
      ]
      : [],
  loader: state.Login.loadercontinue,
  getAllSipCart: state.CartReducer.getAllSipCart == "NA" ? [] : state.CartReducer.getAllSipCart,
  getAllOnetimeCart: state.CartReducer.getAllOnetimeCart == "NA" ? [] : state.CartReducer.getAllOnetimeCart,


});
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(GlobalApicall))
);
