import * as types from '../../constants/actionsType';
import getMarketCapAllocation from '../../../api/marketcapallocation/marketcapallocation'
import constants from '../../../utils/constants/apiconstant';
export function getMarketCapAllocationDetails(ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, flag) {
  return (dispatch) => {
    getAllMarketCapAllocationAction(dispatch, ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, flag);
  };
}
function getAllMarketCapAllocationAction(dispatch, ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, flag) {
  if (ProductID == 1) {
    dispatch({
      type: types.EMPTY_MARKETCAP_ALLOCATION,
      payload: [],
    });
    dispatch({
      type: types.LOADER_MARKETCAP_ALLOCATION,
      payload: true,
    });
  }
  else if (ProductID == 2) {
    dispatch({
      type: types.EMPTY_EQUITY_MARKETCAP_ALLOCATION,
      payload: [],
    });
    dispatch({
      type: types.LOADER_EQUITY_MARKETCAP_ALLOCATION,
      payload: true,
    });
  }

  getMarketCapAllocation(ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID)
    .then((data) => {
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: ProductID, api: "MarketCapAllocation" }],
      });
      try {
        if (ProductID == 1) {
          if (data.status == "S") {

            dispatch({
              type: types.MARKETCAP_ALLOCATION,
              payload: data,
            });

          } else {
            dispatch({
              type: types.MARKETCAP_ALLOCATION,
              payload: [],
            });

          }
        }
        else if (ProductID == 2) {

          try {
            if (data.status == "S") {
              dispatch({
                type: types.EQUITY_MARKETCAP_ALLOCATION,
                payload: data,
              });

            } else {
              dispatch({
                type: types.EQUITY_MARKETCAP_ALLOCATION,
                payload: [],
              });

            }
          } catch (err) {
          }
        }
      } catch (err) {
      }

      // else if(ProductID == 3) {

      //   if (data.status == "S") {
      //     dispatch({
      //       type: types.BONDS_ACCOUNT_STATEMENT,
      //       payload: data,
      //     });

      //   } else {
      //     dispatch({
      //       type: types.BONDS_ACCOUNT_STATEMENT,
      //       payload: [],
      //     });

      //   }
      // }
      //  if (data != undefined) {
      //   if (data.status == "S") {
      //     dispatch({
      //         type: types.MARKETCAP_ALLOCATION,
      //         payload: data,
      //       });
      //   } else {
      //     dispatch({
      //         type: types.MARKETCAP_ALLOCATION,
      //         payload: [],
      //       });
      //   }
      // }
    })
    .catch((error) => {

      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: ProductID, api: "MarketCapAllocation" }],
      });
      if (ProductID == 1) {
        dispatch({
          type: types.EMPTY_MARKETCAP_ALLOCATION,
          payload: [],
        });

      } else {
        dispatch({
          type: types.EMPTY_EQUITY_MARKETCAP_ALLOCATION,
          payload: [],
        });

      }
    })
}
