import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import '../../utils/stylesheets/common/global/x-ray/transaction.css';
import colors from '../../utils/colors/colors';
import { connect } from 'react-redux';
import NoData from "../../components/nodata/nodata";
import CommonHeader from '../../components/report_common_header/report_common_header';
import Loader from '../../components/loader/loader'
import Table from '../../components/table/tradingtable'
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import combineStyle from '../../utils/stylesheets/combainstyle/combainstyle'
import commonstyletable from '../../utils/stylesheets/table/commontablestyle'
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import { NegativeFinding, replaceNull } from '../../utils/commonfunction';
import Epfreport from '../epf/epfreport';
let list = []
const styles = theme => ({
    toolbars: {
        flex: 1,
        minHeight: 30,
        alignItems: 'flex-start',
        marginLeft: 20
    },
    root: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        paddingTop: "6%",
        height: "130px",
        zIndex: 1,
    },
    menu: {
        flex: 1,
        flexDirection: 'row',
        //justifyContent: 'left',
        marginTop: 30,
        //marginBottom: 20,
        // marginLeft: -20,
    },
    tab: {
        flex: 1,
        flexDirection: 'column',
        marginLeft: '6.2%'
    },
    tabs: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'left',

        //marginLeft:25
    },
    subtitles: {
        fontSize: 14,
        //marginLeft: 70,
        color: window.globalConfig.color.cyanBluish,
        paddingTop: '18px 0',

    },
    schemeName: {
        fontSize: 20,
        color: 'white',
        fontWeight: 'bold'
    },
    asOnDate: {
        fontSize: 14,
        //marginLeft: 70,
        color: window.globalConfig.color.cyanBluish,
        paddingTop: '18px',
        paddingRight: '17.8%'
    },
    textName: {
        fontSize: 14,
        color: window.globalConfig.color.LABLE_GRAY,
        fontWeight: 'medium'
    },
    textScheme: {
        fontSize: 14,
        color: window.globalConfig.color.PRIMARY,
        fontWeight: 'bold'
    },
    card: {
        minHeight: 0,
    },
    textAmount: {
        fontSize: 14,
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontWeight: 'bold'
    },
    headerBlock: {

        [theme.breakpoints.down('600')]: {
            width: "107%",
            // marginLeft: -48,
            marginTop: "-17px",
        },

    },
    desktopResponsive: {
        display: 'block !important',

        [theme.breakpoints.down("600")]: {
            display: 'none !important',
        },
    },
    nodataAvailable: {
        display: 'none !important',
        [theme.breakpoints.down("600")]: {
            display: 'block !important',
            marginTop: '2.35rem',
            marginBottom: '3rem'
        },
    },
    paragraph: {
        display: 'none !important',
        [theme.breakpoints.down("600")]: {
            display: 'block !important',
        }
    },
    upCommingSIPContainer: {
        display: "grid",
        /* margin-left: 20px; */
        gap: "20px",
        gridTemplateColumns: "12% 15% 36% 14% 15%"
    }
})
const title = [{ header: "Date", align: "left" }, { header: "Type", align: "left" }, { header: "Scheme Name", align: "left" }, { header: "Amount" }, { header: "AMC", align: "left" }]
class UpcommingSIP extends React.Component {
    constructor(props) {
        super();
        this.state = {
            upCommingSIP: [
            ]
        }

    }

    componentDidMount() {
        this.isComponentMounted = true;
        // console.log(this.props.UpcommingSIPdata)
        if (replaceNull(this.props.UpcommingSIPdata, "NA") != "NA") {
            this.setState({
                upCommingSIP: this.props.UpcommingSIPdata.length == 0 ? [] : this.props.UpcommingSIPdata.map((item) => {
                    return { ...item, dataOne: item.TransactionDate, dataTwo: item.TransactionType, dataThree: item.SchemeName, dataFour: NegativeFinding(item.Amount, "", "", "", "rupee"), dataFive: item.AMCName }
                })
            })
        } else {
            this.setState({ upCommingSIP: [] })
        }
    }
    componentDidUpdate(prev) {
        const { UpcommingSIPdata } = this.props;
        if (prev.UpcommingSIPdata != UpcommingSIPdata) {
            if (replaceNull(UpcommingSIPdata, "NA") != "NA") {
                this.setState({
                    upCommingSIP: this.props.UpcommingSIPdata.length == 0 ? [] : UpcommingSIPdata.map((item) => {
                        return { ...item, dataOne: item.TransactionDate, dataTwo: item.TransactionType, dataThree: item.SchemeName, dataFour: NegativeFinding(item.Amount, "", "", "", "rupee"), dataFive: item.AMCName }
                    })
                })
            } else {
                this.setState({ upCommingSIP: [] })
            }
        }

    }
    componentWillUnmount() {
        this.isComponentMounted = false;
    }

    render() {
        // this.props.upCommingSIP != null && this.props.upCommingSIP.length > 0 && this.props.upCommingSIP != undefined ? this.props.upCommingSIP.map((item) => {
        //     var data = {}
        //     data["Date"] = item.TransactionDate
        //     data["Type"] = item.TransactionType
        //     data["SchemeName"] = item.SchemeName
        //     data["Amount"] = item.Amount
        //     data["AMC"] = item.AMCName
        //     list.push(data)
        // }) : list = []
        const { classes } = this.props;
        try {
            return (
                <>
                    {this.props.isUpcomingSIPLoading == true ?

                        <Loader page="skeletonreport" />

                        :

                        <div style={{ marginBottom: "4%" }} >
                            <Epfreport singleScheme="List" showfilter={false} multipleScheme="Lists" loader={this.props.isUpcomingSIPLoading} grid={classes.upCommingSIPContainer} title={title} data={this.state.upCommingSIP}>

                            </Epfreport>
                            {/* <div className={classes.headerBlock}>
                                <p className={classes.paragraph}></p>
                                <CommonHeader scheme={this.props.upCommingSIP != undefined && this.props.upCommingSIP != null ? this.props.upCommingSIP.length == 1 ? `${this.props.upCommingSIP.length} List` : this.props.upCommingSIP.length > 0 ? `${this.props.upCommingSIP.length} Lists` : <NoData /> : <NoData />}
                                    showfilter={false} page={"Trading"} />


                            </div>
                            <ErrorBoundary>
                                {this.props.upCommingSIP != null && this.props.upCommingSIP.length > 0 && this.props.upCommingSIP != undefined ?
                                    <div style={{ marginBottom: "2%" }} >
                                        <div className={classes.desktopResponsive}>
                                            <div className={[classes.FiveSplitContainer, classes.tableHeader].join(' ')} style={{ marginTop: "1%" }}>
                                                <div style={{ width: "15vw", textAlign: "left", paddingLeft: 10 }}>{labels.upComingSIP.dates}</div>
                                                <div style={{ width: "12vw", textAlign: "left" }}>{labels.upComingSIP.type}</div>
                                                <div style={{ textAlign: "left", width: '30vw' }}>{labels.upComingSIP.schemenames}</div>
                                                <div style={{ textAlign: "right", width: '8vw', marginRight: '1.5vw' }}>{labels.upComingSIP.amounts}</div>
                                                <div style={{ textAlign: "left", width: '8vw', marginLeft: '25px' }}>{labels.upComingSIP.AMCName}</div>
                                                <div></div>
                                            </div>
                                            {this.props.upCommingSIP.map((item, index) => {
                                                return (
                                                    <div className={[classes.FiveSplitContainer, classes.subCategoryGroupContainer, index == 0 && classes.borderRadiousFirstIndex].join(' ')}>
                                                        <div style={{ width: "15vw", textAlign: "left", paddingLeft: 10 }} className={classes.subCatgoryValue}>
                                                            {item.TransactionDate == "" ? labels.messages.NA : item.TransactionDate}
                                                        </div >
                                                        <div style={{ textAlign: "left", width: '12vw' }} className={classes.subCatgoryValue}>
                                                            {item.TransactionType == "" ? labels.messages.NA : item.TransactionType}
                                                        </div>
                                                        <div style={{ width: "30vw", textAlign: "left" }} className={classes.subCatgoryValue}>
                                                            {item.SchemeName == "" ? labels.messages.NA : item.SchemeName}
                                                        </div>
                                                        <div style={{ width: "8vw", marginRight: "1.5vw", }} className={classes.subCatgoryValue}>
                                                            <div style={{ textAlign: "right" }}>{NegativeFinding(item.Amount, "", "", "", "rupee")}</div>

                                                        </div>
                                                        <div style={{
                                                            width: "8vw", marginLeft: "25px", textAlign: "left",
                                                            inlineSize: '100px',
                                                            overflowWrap: 'break-word',
                                                            hyphens: "manual",
                                                        }} className={classes.subCatgoryValue}>
                                                            {item.AMCName == "" ? labels.messages.NA : item.AMCName}
                                                        </div>

                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <Table list={list} header={["DATE", "TYPE", "SCHEME NAME", "AMOUNT", "AMC"]} />
                                    </div>
                                    :
                                    <Grid className={classes.nodataAvailable}>
                                        <NoData />
                                    </Grid>
                                }
                            </ErrorBoundary>
                            <Grid item xs={12}>
                               
                            </Grid> */}
                        </div>

                    }
                </>
            )
        }
        catch (err) {
            return <Redirect to={labels.Path.NotFound} />

        }
    }
}
const mapStateToProps = state => ({
    UpcommingSIPdata: state.UpcommingSIP.UpcommingSIPdata == "NA" ? [] : state.UpcommingSIP.UpcommingSIPdata,
    isUpcomingSIPLoading: state.UpcommingSIP.isUpcomingSIPLoading
})
const mergestyles = combineStyle(styles, commonstyletable)
export default withStyles(mergestyles)(connect(mapStateToProps)(UpcommingSIP));
