import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
import { Asset } from '../../utils/mainasset';
import { Encryption } from '../../utils/commonfunction';
function logOutUser(SessionID, ClientID, WadminSessionID, isSessionExpired) {



    return Networking.GetApi(`${ApiValues.LoginOut}${SessionID}&accessedBy=${ClientID}&wAdminSessionID=${WadminSessionID}&isSessionExpired=${isSessionExpired}`, "", labels.key.apiKey, labels.key.apiKey, true).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });
    // fetch method ends here

}
export default logOutUser
