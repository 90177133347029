import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels';
/* name should be camel case in all function */
import Networking from '../../utils/api/apiaccess';
function getBenchMarkDetails(SessionID, ClientID, AsOnDate, ProductID, FamilyID, L5ID, L4ID, PortfolioID, BenchMarkID) {
    // let BaseUrl = "https://ms.zoifintech.com/Dashboard/PortfolioAllocation"
    let data = {};

    data.sessionID = SessionID
    data.L3ID = ClientID
    data.AsOnDate = AsOnDate
    data.ProductID = ProductID||0
    data.FamilyID = FamilyID
    data.L5ID = L5ID
    data.L4ID = L4ID
    data.PortfolioType = 1;
    data.BenchMarkID = BenchMarkID
    return Networking.PostApi(ApiValues.BenchMarkDetails, data).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });

}
export default getBenchMarkDetails
