import ApiValues from '../url/apiurl'
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
var axios = require('axios');

function getFamilyNPSReport(data) {
    return Networking.PostApi(ApiValues.FamilyNPSReport, data).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });
}

export default getFamilyNPSReport
