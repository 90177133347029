import React from 'react';
import color from "../../utils/colors/colors";
import label from '../../utils/constants/labels';
const App =(props)=>{
    const style={
        header:{
           fontSize:20,
           padding:"20px",
           color:window.globalConfig.color.WHITE,
           fontWeight:500,
           textAlign:'left',
           background:window.globalConfig.color.PRIMARY,
           borderTopLeftRadius:10,
           borderTopRightRadius:10
        },
    }
    return(
        <React.Fragment>
            <div style={style.header}>{label.headerBackdround.Summary}</div> 
        </React.Fragment>
    )
}
export default App;
