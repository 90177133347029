const chart1=[
    {
        "NavDate": "28 Sep 2020",
        "NAVRs": 406.63
    },
    {
        "NavDate": "29 Sep 2020",
        "NAVRs": 406.54
    },
    {
        "NavDate": "30 Sep 2020",
        "NAVRs": 407.55
    },
    {
        "NavDate": "01 Oct 2020",
        "NAVRs": 414.28
    },
    {
        "NavDate": "05 Oct 2020",
        "NAVRs": 3417.24
    },
    {
        "NavDate": "06 Oct 2020",
        "NAVRs": 423.67
    },
    {
        "NavDate": "07 Oct 2020",
        "NAVRs": 426.93
    },
    {
        "NavDate": "08 Oct 2020",
        "NAVRs": 430.18
    },
    {
        "NavDate": "09 Oct 2020",
        "NAVRs": 433.68
    },
    {
        "NavDate": "12 Oct 2020",
        "NAVRs": 434.58
    },
    {
        "NavDate": "13 Oct 2020",
        "NAVRs": 434.91
    },
    {
        "NavDate": "14 Oct 2020",
        "NAVRs": 436.85
    },
    {
        "NavDate": "15 Oct 2020",
        "NAVRs": 425.43
    },
    {
        "NavDate": "16 Oct 2020",
        "NAVRs": 428.16
    },
    {
        "NavDate": "19 Oct 2020",
        "NAVRs": 432.96
    },
    {
        "NavDate": "20 Oct 2020",
        "NAVRs": 434.16
    },
    {
        "NavDate": "21 Oct 2020",
        "NAVRs": 435.91
    },
    {
        "NavDate": "22 Oct 2020",
        "NAVRs": 434.36
    },
    {
        "NavDate": "23 Oct 2020",
        "NAVRs": 436.15
    },
    {
        "NavDate": "26 Oct 2020",
        "NAVRs": 430.37
    },
    {
        "NavDate": "27 Oct 2020",
        "NAVRs": 434.4
    },
    {
        "NavDate": "28 Oct 2020",
        "NAVRs": 428.12
    },
    {
        "NavDate": "29 Oct 2020",
        "NAVRs": 426.31
    },
    {
        "NavDate": "30 Oct 2020",
        "NAVRs": 424.86
    },
    {
        "NavDate": "02 Nov 2020",
        "NAVRs": 426.39
    },
    {
        "NavDate": "03 Nov 2020",
        "NAVRs": 431.78
    },
    {
        "NavDate": "04 Nov 2020",
        "NAVRs": 435.58
    },
    {
        "NavDate": "05 Nov 2020",
        "NAVRs": 443.33
    },
    {
        "NavDate": "06 Nov 2020",
        "NAVRs": 449.24
    },
    {
        "NavDate": "09 Nov 2020",
        "NAVRs": 456.78
    },
    {
        "NavDate": "10 Nov 2020",
        "NAVRs": 464.06
    },
    {
        "NavDate": "11 Nov 2020",
        "NAVRs": 467.44
    },
    {
        "NavDate": "12 Nov 2020",
        "NAVRs": 464.9
    },
    {
        "NavDate": "13 Nov 2020",
        "NAVRs": 465.82
    },
    {
        "NavDate": "17 Nov 2020",
        "NAVRs": 471.28
    },
    {
        "NavDate": "18 Nov 2020",
        "NAVRs": 473.72
    },
    {
        "NavDate": "19 Nov 2020",
        "NAVRs": 467.5
    },
    {
        "NavDate": "20 Nov 2020",
        "NAVRs": 470.52
    },
    {
        "NavDate": "23 Nov 2020",
        "NAVRs": 472.61
    },
    {
        "NavDate": "24 Nov 2020",
        "NAVRs": 477.39
    },
    {
        "NavDate": "25 Nov 2020",
        "NAVRs": 469.94
    },
    {
        "NavDate": "26 Nov 2020",
        "NAVRs": 474.57
    },
    {
        "NavDate": "27 Nov 2020",
        "NAVRs": 473.39
    },
   
    {
        "NavDate": "24 Feb 2021",
        "NAVRs": 545.32
    },
    {
        "NavDate": "25 Feb 2021",
        "NAVRs": 543.58
    },
    {
        "NavDate": "26 Feb 2021",
        "NAVRs": 522.95
    },
    {
        "NavDate": "01 Mar 2021",
        "NAVRs": 530.92
    },
    {
        "NavDate": "02 Mar 2021",
        "NAVRs": 535.68
    },
    {
        "NavDate": "03 Mar 2021",
        "NAVRs": 547.89
    },
    {
        "NavDate": "04 Mar 2021",
        "NAVRs": 541.52
    },
    {
        "NavDate": "05 Mar 2021",
        "NAVRs": 536.83
    },
    {
        "NavDate": "08 Mar 2021",
        "NAVRs": 537.31
    },
    {
        "NavDate": "09 Mar 2021",
        "NAVRs": 543.53
    },
    {
        "NavDate": "10 Mar 2021",
        "NAVRs": 546.23
    },
    {
        "NavDate": "12 Mar 2021",
        "NAVRs": 541.04
    },
    {
        "NavDate": "15 Mar 2021",
        "NAVRs": 536.81
    },
    {
        "NavDate": "16 Mar 2021",
        "NAVRs": 531.97
    },
    {
        "NavDate": "17 Mar 2021",
        "NAVRs": 526.03
    },
    {
        "NavDate": "18 Mar 2021",
        "NAVRs": 519.85
    },
    {
        "NavDate": "19 Mar 2021",
        "NAVRs": 526.62
    },
    {
        "NavDate": "22 Mar 2021",
        "NAVRs": 525.7
    },
    {
        "NavDate": "23 Mar 2021",
        "NAVRs": 528.66
    },
    {
        "NavDate": "24 Mar 2021",
        "NAVRs": 514.95
    },
    {
        "NavDate": "25 Mar 2021",
        "NAVRs": 507.2
    },
    {
        "NavDate": "26 Mar 2021",
        "NAVRs": 513.15
    },
    {
        "NavDate": "30 Mar 2021",
        "NAVRs": 524.96
    },
    {
        "NavDate": "31 Mar 2021",
        "NAVRs": 518.39
    },
    {
        "NavDate": "01 Apr 2021",
        "NAVRs": 523.84
    },
    {
        "NavDate": "05 Apr 2021",
        "NAVRs": 514.72
    },
    {
        "NavDate": "06 Apr 2021",
        "NAVRs": 515.16
    },
    {
        "NavDate": "07 Apr 2021",
        "NAVRs": 519.98
    },
    {
        "NavDate": "08 Apr 2021",
        "NAVRs": 520.87
    },
    {
        "NavDate": "09 Apr 2021",
        "NAVRs": 519.24
    },
    {
        "NavDate": "12 Apr 2021",
        "NAVRs": 501.36
    },
    {
        "NavDate": "13 Apr 2021",
        "NAVRs": 508.27
    },
    {
        "NavDate": "15 Apr 2021",
        "NAVRs": 510.99
    },
    {
        "NavDate": "16 Apr 2021",
        "NAVRs": 511.29
    },
    {
        "NavDate": "19 Apr 2021",
        "NAVRs": 502.04
    },
    {
        "NavDate": "20 Apr 2021",
        "NAVRs": 499.49
    },
    {
        "NavDate": "22 Apr 2021",
        "NAVRs": 503.41
    },
    {
        "NavDate": "23 Apr 2021",
        "NAVRs": 501.3
    },
    {
        "NavDate": "26 Apr 2021",
        "NAVRs": 506.61
    },
    {
        "NavDate": "27 Apr 2021",
        "NAVRs": 512.45
    },
    {
        "NavDate": "28 Apr 2021",
        "NAVRs": 520.72
    },
    {
        "NavDate": "29 Apr 2021",
        "NAVRs": 521.14
    },
    {
        "NavDate": "30 Apr 2021",
        "NAVRs": 510.85
    },
    {
        "NavDate": "03 May 2021",
        "NAVRs": 510.18
    },
    {
        "NavDate": "04 May 2021",
        "NAVRs": 505.31
    },
    {
        "NavDate": "05 May 2021",
        "NAVRs": 509.75
    },
    {
        "NavDate": "06 May 2021",
        "NAVRs": 512.59
    },
    {
        "NavDate": "07 May 2021",
        "NAVRs": 515.28
    },
    {
        "NavDate": "10 May 2021",
        "NAVRs": 518.38
    },
    {
        "NavDate": "11 May 2021",
        "NAVRs": 514.81
    },
    {
        "NavDate": "12 May 2021",
        "NAVRs": 509.88
    },
    {
        "NavDate": "14 May 2021",
        "NAVRs": 510.31
    },
    {
        "NavDate": "17 May 2021",
        "NAVRs": 519.19
    },
    {
        "NavDate": "18 May 2021",
        "NAVRs": 525.6
    },
    {
        "NavDate": "19 May 2021",
        "NAVRs": 522.56
    },
    {
        "NavDate": "20 May 2021",
        "NAVRs": 519.02
    },
    {
        "NavDate": "21 May 2021",
        "NAVRs": 529.23
    },
    {
        "NavDate": "24 May 2021",
        "NAVRs": 530.4
    },
    {
        "NavDate": "25 May 2021",
        "NAVRs": 530.4
    },
    {
        "NavDate": "26 May 2021",
        "NAVRs": 534.37
    },
    {
        "NavDate": "27 May 2021",
        "NAVRs": 535.4
    },
    {
        "NavDate": "28 May 2021",
        "NAVRs": 538.62
    },
    {
        "NavDate": "31 May 2021",
        "NAVRs": 544.93
    },
    {
        "NavDate": "01 Jun 2021",
        "NAVRs": 544.9
    },
    {
        "NavDate": "02 Jun 2021",
        "NAVRs": 544
    },
    {
        "NavDate": "03 Jun 2021",
        "NAVRs": 548.16
    },
    {
        "NavDate": "04 Jun 2021",
        "NAVRs": 546.77
    },
    {
        "NavDate": "07 Jun 2021",
        "NAVRs": 549.16
    },
    {
        "NavDate": "08 Jun 2021",
        "NAVRs": 548.61
    },
    
    {
        "NavDate": "22 Jul 2021",
        "NAVRs": 555.97
    },
    {
        "NavDate": "23 Jul 2021",
        "NAVRs": 557.51
    },
    {
        "NavDate": "26 Jul 2021",
        "NAVRs": 556.21
    },
    {
        "NavDate": "27 Jul 2021",
        "NAVRs": 553.39
    },
    {
        "NavDate": "28 Jul 2021",
        "NAVRs": 552.18
    },
    {
        "NavDate": "29 Jul 2021",
        "NAVRs": 554.51
    },
    {
        "NavDate": "30 Jul 2021",
        "NAVRs": 553.82
    },
    {
        "NavDate": "02 Aug 2021",
        "NAVRs": 557.68
    },
    {
        "NavDate": "03 Aug 2021",
        "NAVRs": 566.84
    },
    {
        "NavDate": "04 Aug 2021",
        "NAVRs": 572.58
    },
    {
        "NavDate": "05 Aug 2021",
        "NAVRs": 573.93
    },
    {
        "NavDate": "06 Aug 2021",
        "NAVRs": 571.67
    },
    {
        "NavDate": "09 Aug 2021",
        "NAVRs": 572.98
    },
    {
        "NavDate": "10 Aug 2021",
        "NAVRs": 574.57
    },
    {
        "NavDate": "11 Aug 2021",
        "NAVRs": 574.28
    },
    {
        "NavDate": "12 Aug 2021",
        "NAVRs": 577.62
    },
    {
        "NavDate": "13 Aug 2021",
        "NAVRs": 583.86
    },
    {
        "NavDate": "16 Aug 2021",
        "NAVRs": 585.39
    },
    {
        "NavDate": "17 Aug 2021",
        "NAVRs": 587.62
    },
    {
        "NavDate": "18 Aug 2021",
        "NAVRs": 585.91
    },
    {
        "NavDate": "20 Aug 2021",
        "NAVRs": 582.75
    },
    {
        "NavDate": "23 Aug 2021",
        "NAVRs": 585.15
    },
    {
        "NavDate": "24 Aug 2021",
        "NAVRs": 589.39
    },
    {
        "NavDate": "25 Aug 2021",
        "NAVRs": 589.24
    },
    {
        "NavDate": "26 Aug 2021",
        "NAVRs": 589.29
    },
    {
        "NavDate": "27 Aug 2021",
        "NAVRs": 591.13
    },
    {
        "NavDate": "30 Aug 2021",
        "NAVRs": 599.19
    },
    {
        "NavDate": "31 Aug 2021",
        "NAVRs": 606.16
    },
    {
        "NavDate": "01 Sep 2021",
        "NAVRs": 603.91
    },
    {
        "NavDate": "02 Sep 2021",
        "NAVRs": 609.32
    },
    {
        "NavDate": "03 Sep 2021",
        "NAVRs": 612.24
    },
    {
        "NavDate": "06 Sep 2021",
        "NAVRs": 614
    },
    {
        "NavDate": "07 Sep 2021",
        "NAVRs": 613.81
    },
    {
        "NavDate": "08 Sep 2021",
        "NAVRs": 613.74
    },
    {
        "NavDate": "09 Sep 2021",
        "NAVRs": 614.32
    },
    {
        "NavDate": "13 Sep 2021",
        "NAVRs": 612.98
    },
    {
        "NavDate": "14 Sep 2021",
        "NAVRs": 613.71
    },
    {
        "NavDate": "15 Sep 2021",
        "NAVRs": 618.72
    },
    {
        "NavDate": "16 Sep 2021",
        "NAVRs": 623.12
    },
    {
        "NavDate": "17 Sep 2021",
        "NAVRs": 621.79
    },
    {
        "NavDate": "20 Sep 2021",
        "NAVRs": 616.26
    },
    {
        "NavDate": "21 Sep 2021",
        "NAVRs": 621.68
    },
    {
        "NavDate": "22 Sep 2021",
        "NAVRs": 620.85
    },
    {
        "NavDate": "23 Sep 2021",
        "NAVRs": 630.94
    },
    {
        "NavDate": "24 Sep 2021",
        "NAVRs": 632.66
    },
    {
        "NavDate": "27 Sep 2021",
        "NAVRs": 632.96
    },
    {
        "NavDate": "28 Sep 2021",
        "NAVRs": 628.64
    },
    {
        "NavDate": "29 Sep 2021",
        "NAVRs": 625.96
    },
    {
        "NavDate": "30 Sep 2021",
        "NAVRs": 622.94
    },
    {
        "NavDate": "01 Oct 2021",
        "NAVRs": 619.13
    },
    {
        "NavDate": "04 Oct 2021",
        "NAVRs": 624.75
    },
    {
        "NavDate": "05 Oct 2021",
        "NAVRs": 629.45
    },
    {
        "NavDate": "06 Oct 2021",
        "NAVRs": 623.6
    },
    {
        "NavDate": "07 Oct 2021",
        "NAVRs": 628.74
    },
    {
        "NavDate": "08 Oct 2021",
        "NAVRs": 632.75
    },
    {
        "NavDate": "11 Oct 2021",
        "NAVRs": 633.56
    },
    {
        "NavDate": "12 Oct 2021",
        "NAVRs": 635.12
    },
    {
        "NavDate": "13 Oct 2021",
        "NAVRs": 639.89
    },
    {
        "NavDate": "14 Oct 2021",
        "NAVRs": 645.95
    },
    {
        "NavDate": "18 Oct 2021",
        "NAVRs": 650.79
    },
    {
        "NavDate": "19 Oct 2021",
        "NAVRs": 650.26
    },
    {
        "NavDate": "20 Oct 2021",
        "NAVRs": 645.46
    },
    {
        "NavDate": "21 Oct 2021",
        "NAVRs": 642.02
    },
    {
        "NavDate": "22 Oct 2021",
        "NAVRs": 640.94
    },
    {
        "NavDate": "25 Oct 2021",
        "NAVRs": 642.47
    },
    {
        "NavDate": "26 Oct 2021",
        "NAVRs": 647.2
    },
    {
        "NavDate": "27 Oct 2021",
        "NAVRs": 645.01
    },
    {
        "NavDate": "28 Oct 2021",
        "NAVRs": 632.82
    },
    {
        "NavDate": "29 Oct 2021",
        "NAVRs": 625.68
    },
    {
        "NavDate": "01 Nov 2021",
        "NAVRs": 634.44
    },
    {
        "NavDate": "02 Nov 2021",
        "NAVRs": 633.28
    },
    {
        "NavDate": "03 Nov 2021",
        "NAVRs": 630.57
    },
    {
        "NavDate": "08 Nov 2021",
        "NAVRs": 638.72
    },
    {
        "NavDate": "09 Nov 2021",
        "NAVRs": 637.54
    },
    {
        "NavDate": "10 Nov 2021",
        "NAVRs": 636.68
    },
    {
        "NavDate": "11 Nov 2021",
        "NAVRs": 632.12
    },
    {
        "NavDate": "12 Nov 2021",
        "NAVRs": 640.2
    },
    {
        "NavDate": "15 Nov 2021",
        "NAVRs": 640.54
    },
    {
        "NavDate": "16 Nov 2021",
        "NAVRs": 636.36
    },
    {
        "NavDate": "17 Nov 2021",
        "NAVRs": 633.04
    },
    {
        "NavDate": "18 Nov 2021",
        "NAVRs": 629.11
    },
    {
        "NavDate": "22 Nov 2021",
        "NAVRs": 616.77
    },
    {
        "NavDate": "23 Nov 2021",
        "NAVRs": 618.86
    },
    {
        "NavDate": "24 Nov 2021",
        "NAVRs": 615.45
    },
    {
        "NavDate": "25 Nov 2021",
        "NAVRs": 620.24
    },
    {
        "NavDate": "26 Nov 2021",
        "NAVRs": 602.43
    },
    {
        "NavDate": "29 Nov 2021",
        "NAVRs": 604.04
    },
    {
        "NavDate": "30 Nov 2021",
        "NAVRs": 601.98
    },
    {
        "NavDate": "01 Dec 2021",
        "NAVRs": 608.52
    },
    {
        "NavDate": "02 Dec 2021",
        "NAVRs": 616.71
    },
    {
        "NavDate": "03 Dec 2021",
        "NAVRs": 608.64
    },
    {
        "NavDate": "06 Dec 2021",
        "NAVRs": 598.62
    },
    {
        "NavDate": "07 Dec 2021",
        "NAVRs": 607.97
    },
    {
        "NavDate": "08 Dec 2021",
        "NAVRs": 618.69
    },
    {
        "NavDate": "09 Dec 2021",
        "NAVRs": 620.35
    },
    {
        "NavDate": "10 Dec 2021",
        "NAVRs": 620.13
    },
    {
        "NavDate": "13 Dec 2021",
        "NAVRs": 614.82
    },
    {
        "NavDate": "14 Dec 2021",
        "NAVRs": 613.06
    },
    {
        "NavDate": "15 Dec 2021",
        "NAVRs": 609.59
    },
    {
        "NavDate": "16 Dec 2021",
        "NAVRs": 610.78
    },
    {
        "NavDate": "17 Dec 2021",
        "NAVRs": 601.37
    },
    {
        "NavDate": "20 Dec 2021",
        "NAVRs": 588.81
    },
    {
        "NavDate": "21 Dec 2021",
        "NAVRs": 594.05
    },
    {
        "NavDate": "22 Dec 2021",
        "NAVRs": 600.63
    },
    {
        "NavDate": "23 Dec 2021",
        "NAVRs": 604.69
    },
    {
        "NavDate": "24 Dec 2021",
        "NAVRs": 602.67
    },
    {
        "NavDate": "27 Dec 2021",
        "NAVRs": 605.79
    },
    {
        "NavDate": "28 Dec 2021",
        "NAVRs": 610.82
    },
    {
        "NavDate": "29 Dec 2021",
        "NAVRs": 609.85
    },
    {
        "NavDate": "30 Dec 2021",
        "NAVRs": 609.72
    },
    {
        "NavDate": "31 Dec 2021",
        "NAVRs": 614.57
    },
    {
        "NavDate": "03 Jan 2022",
        "NAVRs": 624.37
    },
    {
        "NavDate": "04 Jan 2022",
        "NAVRs": 631.46
    },
    {
        "NavDate": "05 Jan 2022",
        "NAVRs": 635.33
    },
    {
        "NavDate": "06 Jan 2022",
        "NAVRs": 628.78
    },
    {
        "NavDate": "07 Jan 2022",
        "NAVRs": 630.28
    },
    {
        "NavDate": "10 Jan 2022",
        "NAVRs": 637.15
    },
    {
        "NavDate": "11 Jan 2022",
        "NAVRs": 639.48
    },
    {
        "NavDate": "12 Jan 2022",
        "NAVRs": 645.1
    },
    {
        "NavDate": "13 Jan 2022",
        "NAVRs": 646
    },
    {
        "NavDate": "14 Jan 2022",
        "NAVRs": 645.87
    },
    {
        "NavDate": "17 Jan 2022",
        "NAVRs": 646.77
    },
    {
        "NavDate": "18 Jan 2022",
        "NAVRs": 640.92
    },
    {
        "NavDate": "19 Jan 2022",
        "NAVRs": 634.07
    },
    {
        "NavDate": "20 Jan 2022",
        "NAVRs": 627.48
    },
    {
        "NavDate": "21 Jan 2022",
        "NAVRs": 622.98
    },
    {
        "NavDate": "24 Jan 2022",
        "NAVRs": 606.67
    },
    {
        "NavDate": "25 Jan 2022",
        "NAVRs": 610.54
    },
    {
        "NavDate": "28 Jan 2022",
        "NAVRs": 603.6
    },
    {
        "NavDate": "31 Jan 2022",
        "NAVRs": 612.18
    },
    {
        "NavDate": "01 Feb 2022",
        "NAVRs": 621.13
    },
    {
        "NavDate": "02 Feb 2022",
        "NAVRs": 628.47
    },
    {
        "NavDate": "03 Feb 2022",
        "NAVRs": 620.52
    },
    {
        "NavDate": "04 Feb 2022",
        "NAVRs": 619.01
    },
    {
        "NavDate": "07 Feb 2022",
        "NAVRs": 608.21
    },
    {
        "NavDate": "08 Feb 2022",
        "NAVRs": 610.18
    },
    {
        "NavDate": "09 Feb 2022",
        "NAVRs": 617.19
    },
    {
        "NavDate": "10 Feb 2022",
        "NAVRs": 622.04
    },
    {
        "NavDate": "11 Feb 2022",
        "NAVRs": 613.88
    },
    {
        "NavDate": "14 Feb 2022",
        "NAVRs": 595.87
    },
    {
        "NavDate": "15 Feb 2022",
        "NAVRs": 614.19
    },
    {
        "NavDate": "16 Feb 2022",
        "NAVRs": 612.84
    },
    {
        "NavDate": "17 Feb 2022",
        "NAVRs": 611.73
    },
    {
        "NavDate": "18 Feb 2022",
        "NAVRs": 611.11
    },
    {
        "NavDate": "21 Feb 2022",
        "NAVRs": 609.53
    },
    {
        "NavDate": "22 Feb 2022",
        "NAVRs": 605.48
    },
    {
        "NavDate": "23 Feb 2022",
        "NAVRs": 604.76
    },
    {
        "NavDate": "24 Feb 2022",
        "NAVRs": 576.23
    },
    {
        "NavDate": "28 Feb 2022",
        "NAVRs": 594.36
    },
    {
        "NavDate": "02 Mar 2022",
        "NAVRs": 586.14
    },
    {
        "NavDate": "03 Mar 2022",
        "NAVRs": 582.27
    },
    {
        "NavDate": "04 Mar 2022",
        "NAVRs": 574.15
    },
    {
        "NavDate": "07 Mar 2022",
        "NAVRs": 558.4
    },
    {
        "NavDate": "08 Mar 2022",
        "NAVRs": 564.54
    },
    {
        "NavDate": "09 Mar 2022",
        "NAVRs": 577.46
    },
    {
        "NavDate": "10 Mar 2022",
        "NAVRs": 586.1
    },
    {
        "NavDate": "11 Mar 2022",
        "NAVRs": 587
    },
    {
        "NavDate": "14 Mar 2022",
        "NAVRs": 596.89
    },
    {
        "NavDate": "15 Mar 2022",
        "NAVRs": 589.39
    },
    {
        "NavDate": "16 Mar 2022",
        "NAVRs": 600.38
    },
    {
        "NavDate": "17 Mar 2022",
        "NAVRs": 611.44
    },
    {
        "NavDate": "21 Mar 2022",
        "NAVRs": 605.4
    },
    {
        "NavDate": "22 Mar 2022",
        "NAVRs": 612.77
    },
    {
        "NavDate": "23 Mar 2022",
        "NAVRs": 609.55
    },
    {
        "NavDate": "24 Mar 2022",
        "NAVRs": 608.6
    },
    {
        "NavDate": "25 Mar 2022",
        "NAVRs": 606.14
    },
    {
        "NavDate": "28 Mar 2022",
        "NAVRs": 608.58
    },
    {
        "NavDate": "29 Mar 2022",
        "NAVRs": 612.28
    },
    {
        "NavDate": "30 Mar 2022",
        "NAVRs": 620.1
    },
    {
        "NavDate": "31 Mar 2022",
        "NAVRs": 618.88
    },
    {
        "NavDate": "01 Apr 2022",
        "NAVRs": 626.36
    },
    {
        "NavDate": "04 Apr 2022",
        "NAVRs": 640.46
    },
    {
        "NavDate": "05 Apr 2022",
        "NAVRs": 635.93
    },
    {
        "NavDate": "06 Apr 2022",
        "NAVRs": 629.95
    },
    {
        "NavDate": "07 Apr 2022",
        "NAVRs": 623.87
    },
    {
        "NavDate": "08 Apr 2022",
        "NAVRs": 628.22
    },
    {
        "NavDate": "11 Apr 2022",
        "NAVRs": 623.12
    },
    {
        "NavDate": "12 Apr 2022",
        "NAVRs": 619.01
    },
    {
        "NavDate": "13 Apr 2022",
        "NAVRs": 616.5
    },
    {
        "NavDate": "18 Apr 2022",
        "NAVRs": 604.11
    },
    {
        "NavDate": "19 Apr 2022",
        "NAVRs": 596.68
    },
    {
        "NavDate": "20 Apr 2022",
        "NAVRs": 602.75
    },
    {
        "NavDate": "21 Apr 2022",
        "NAVRs": 612.01
    },
    {
        "NavDate": "22 Apr 2022",
        "NAVRs": 604.46
    },
    {
        "NavDate": "25 Apr 2022",
        "NAVRs": 597.94
    },
    {
        "NavDate": "26 Apr 2022",
        "NAVRs": 606.14
    },
    {
        "NavDate": "27 Apr 2022",
        "NAVRs": 600.46
    },
    {
        "NavDate": "28 Apr 2022",
        "NAVRs": 608.06
    },
    {
        "NavDate": "29 Apr 2022",
        "NAVRs": 603.2
    },
    {
        "NavDate": "02 May 2022",
        "NAVRs": 602.3
    },
    {
        "NavDate": "04 May 2022",
        "NAVRs": 588.48
    },
    {
        "NavDate": "05 May 2022",
        "NAVRs": 588.83
    },
    {
        "NavDate": "06 May 2022",
        "NAVRs": 579.68
    },
    {
        "NavDate": "09 May 2022",
        "NAVRs": 575.82
    },
    {
        "NavDate": "10 May 2022",
        "NAVRs": 574.7
    },
    {
        "NavDate": "11 May 2022",
        "NAVRs": 571.77
    },
    {
        "NavDate": "12 May 2022",
        "NAVRs": 560.17
    },
    {
        "NavDate": "13 May 2022",
        "NAVRs": 558.72
    },
    {
        "NavDate": "16 May 2022",
        "NAVRs": 560.62
    },
    {
        "NavDate": "17 May 2022",
        "NAVRs": 574.84
    },
    {
        "NavDate": "18 May 2022",
        "NAVRs": 573.67
    },
    {
        "NavDate": "19 May 2022",
        "NAVRs": 558.7
    },
    {
        "NavDate": "20 May 2022",
        "NAVRs": 574.92
    },
    {
        "NavDate": "23 May 2022",
        "NAVRs": 574.52
    },
    {
        "NavDate": "24 May 2022",
        "NAVRs": 572.02
    },
    {
        "NavDate": "25 May 2022",
        "NAVRs": 569.28
    },
    {
        "NavDate": "26 May 2022",
        "NAVRs": 575.11
    },
    {
        "NavDate": "27 May 2022",
        "NAVRs": 581.79
    },
    {
        "NavDate": "30 May 2022",
        "NAVRs": 592.79
    },
    {
        "NavDate": "31 May 2022",
        "NAVRs": 590.05
    },
    {
        "NavDate": "01 Jun 2022",
        "NAVRs": 588.09
    },
    {
        "NavDate": "02 Jun 2022",
        "NAVRs": 592.71
    },
    {
        "NavDate": "03 Jun 2022",
        "NAVRs": 592.19
    },
    {
        "NavDate": "07 Jun 2022",
        "NAVRs": 585.19
    },
    {
        "NavDate": "08 Jun 2022",
        "NAVRs": 582.92
    },
    {
        "NavDate": "09 Jun 2022",
        "NAVRs": 587.51
    },
    {
        "NavDate": "10 Jun 2022",
        "NAVRs": 576.75
    },
    {
        "NavDate": "13 Jun 2022",
        "NAVRs": 561.35
    },
    {
        "NavDate": "14 Jun 2022",
        "NAVRs": 559.72
    },
    {
        "NavDate": "15 Jun 2022",
        "NAVRs": 558.65
    },
    {
        "NavDate": "16 Jun 2022",
        "NAVRs": 547.59
    },
    {
        "NavDate": "17 Jun 2022",
        "NAVRs": 546.15
    },
    {
        "NavDate": "20 Jun 2022",
        "NAVRs": 548.66
    },
    {
        "NavDate": "21 Jun 2022",
        "NAVRs": 558.55
    },
    {
        "NavDate": "22 Jun 2022",
        "NAVRs": 551.04
    },
    {
        "NavDate": "23 Jun 2022",
        "NAVRs": 555.73
    },
    {
        "NavDate": "24 Jun 2022",
        "NAVRs": 560.62
    },
    {
        "NavDate": "27 Jun 2022",
        "NAVRs": 565.2
    },
    {
        "NavDate": "28 Jun 2022",
        "NAVRs": 565.37
    },
    {
        "NavDate": "29 Jun 2022",
        "NAVRs": 563.77
    },
    {
        "NavDate": "30 Jun 2022",
        "NAVRs": 563.74
    },
    {
        "NavDate": "01 Jul 2022",
        "NAVRs": 562.56
    },
    {
        "NavDate": "04 Jul 2022",
        "NAVRs": 566.03
    },
    {
        "NavDate": "05 Jul 2022",
        "NAVRs": 564.96
    },
    {
        "NavDate": "06 Jul 2022",
        "NAVRs": 571.5
    },
    {
        "NavDate": "07 Jul 2022",
        "NAVRs": 576.06
    },
    {
        "NavDate": "08 Jul 2022",
        "NAVRs": 579.31
    },
    {
        "NavDate": "11 Jul 2022",
        "NAVRs": 578.42
    },
    {
        "NavDate": "12 Jul 2022",
        "NAVRs": 573.02
    },
    {
        "NavDate": "13 Jul 2022",
        "NAVRs": 569.07
    },
    {
        "NavDate": "14 Jul 2022",
        "NAVRs": 568.21
    },
    {
        "NavDate": "15 Jul 2022",
        "NAVRs": 571.87
    },
    {
        "NavDate": "18 Jul 2022",
        "NAVRs": 579.93
    },
    {
        "NavDate": "19 Jul 2022",
        "NAVRs": 582.65
    },
    {
        "NavDate": "20 Jul 2022",
        "NAVRs": 589.34
    },
    {
        "NavDate": "21 Jul 2022",
        "NAVRs": 592.79
    },
    {
        "NavDate": "22 Jul 2022",
        "NAVRs": 596.93
    },
    {
        "NavDate": "25 Jul 2022",
        "NAVRs": 593.67
    },
    {
        "NavDate": "26 Jul 2022",
        "NAVRs": 588.38
    },
    {
        "NavDate": "27 Jul 2022",
        "NAVRs": 594.21
    },
    {
        "NavDate": "28 Jul 2022",
        "NAVRs": 605.28
    },
    {
        "NavDate": "29 Jul 2022",
        "NAVRs": 612.85
    },
    {
        "NavDate": "01 Aug 2022",
        "NAVRs": 618.71
    },
    {
        "NavDate": "02 Aug 2022",
        "NAVRs": 618.97
    },
    {
        "NavDate": "03 Aug 2022",
        "NAVRs": 621.32
    },
    {
        "NavDate": "04 Aug 2022",
        "NAVRs": 620.77
    },
    {
        "NavDate": "05 Aug 2022",
        "NAVRs": 621.72
    },
    {
        "NavDate": "08 Aug 2022",
        "NAVRs": 626.99
    },
    {
        "NavDate": "10 Aug 2022",
        "NAVRs": 626.74
    },
    {
        "NavDate": "11 Aug 2022",
        "NAVRs": 632.29
    },
    {
        "NavDate": "12 Aug 2022",
        "NAVRs": 633.67
    },
    {
        "NavDate": "17 Aug 2022",
        "NAVRs": 642.16
    },
    {
        "NavDate": "18 Aug 2022",
        "NAVRs": 642.82
    },
    {
        "NavDate": "19 Aug 2022",
        "NAVRs": 635.98
    },
    {
        "NavDate": "22 Aug 2022",
        "NAVRs": 626.69
    },
    {
        "NavDate": "02 Sep 2022",
        "NAVRs": 626.99
    },
    {
        "NavDate": "05 Sep 2022",
        "NAVRs": 631.71
    },
    {
        "NavDate": "06 Sep 2022",
        "NAVRs": 631.18
    },
    {
        "NavDate": "07 Sep 2022",
        "NAVRs": 629.39
    },
    {
        "NavDate": "08 Sep 2022",
        "NAVRs": 636.42
    },
    {
        "NavDate": "14 Sep 2022",
        "NAVRs": 643.43
    },
    {
        "NavDate": "15 Sep 2022",
        "NAVRs": 639.03
    },
    {
        "NavDate": "16 Sep 2022",
        "NAVRs": 627.37
    },
    {
        "NavDate": "19 Sep 2022",
        "NAVRs": 630.57
    },
    {
        "NavDate": "21 Sep 2022",
        "NAVRs": 633.93
    },
    {
        "NavDate": "22 Sep 2022",
        "NAVRs": 630.33
    },
    {
        "NavDate": "23 Sep 2022",
        "NAVRs": 619.45
    },
    {
        "NavDate": "30 Sep 2022",
        "NAVRs": 612.28
    },
    {
        "NavDate": "12 Oct 2022",
        "NAVRs": 614.39
    },
    {
        "NavDate": "25 Jul 2023",
        "NAVRs": 714.77
    }
]
const chart2=[
    {
        "NavDate": "26 Sep 2018",
        "NAVRs": 382.61
    },
    {
        "NavDate": "27 Sep 2018",
        "NAVRs": 380.32
    },
    {
        "NavDate": "28 Sep 2018",
        "NAVRs": 379.31
    },
    {
        "NavDate": "01 Oct 2018",
        "NAVRs": 382.43
    },
  
    {
        "NavDate": "14 Jan 2020",
        "NAVRs": 444.92
    },
    {
        "NavDate": "15 Jan 2020",
        "NAVRs": 444.07
    },
    {
        "NavDate": "16 Jan 2020",
        "NAVRs": 444.71
    },
    {
        "NavDate": "17 Jan 2020",
        "NAVRs": 444.84
    },
    {
        "NavDate": "20 Jan 2020",
        "NAVRs": 440.42
    },
    {
        "NavDate": "21 Jan 2020",
        "NAVRs": 438.25
    },
    {
        "NavDate": "22 Jan 2020",
        "NAVRs": 436.04
    },
    {
        "NavDate": "23 Jan 2020",
        "NAVRs": 438.96
    },
    {
        "NavDate": "24 Jan 2020",
        "NAVRs": 441.39
    },
    {
        "NavDate": "27 Jan 2020",
        "NAVRs": 436.53
    },
    {
        "NavDate": "28 Jan 2020",
        "NAVRs": 434.53
    },
    {
        "NavDate": "29 Jan 2020",
        "NAVRs": 436.99
    },
    {
        "NavDate": "30 Jan 2020",
        "NAVRs": 433.97
    },
    {
        "NavDate": "31 Jan 2020",
        "NAVRs": 431.95
    },
    {
        "NavDate": "03 Feb 2020",
        "NAVRs": 422.92
    },
    {
        "NavDate": "04 Feb 2020",
        "NAVRs": 1432.65
    },
    {
        "NavDate": "05 Feb 2020",
        "NAVRs": 436.39
    },
    {
        "NavDate": "06 Feb 2020",
        "NAVRs": 438.12
    },
    {
        "NavDate": "07 Feb 2020",
        "NAVRs": 436.38
    },
    {
        "NavDate": "10 Feb 2020",
        "NAVRs": 434.66
    },
    {
        "NavDate": "11 Feb 2020",
        "NAVRs": 437.17
    },
    {
        "NavDate": "12 Feb 2020",
        "NAVRs": 440.87
    },
    {
        "NavDate": "13 Feb 2020",
        "NAVRs": 439.75
    },
    {
        "NavDate": "14 Feb 2020",
        "NAVRs": 437.61
    },
    {
        "NavDate": "17 Feb 2020",
        "NAVRs": 435.58
    },
    {
        "NavDate": "18 Feb 2020",
        "NAVRs": 433.87
    },
    {
        "NavDate": "19 Feb 2020",
        "NAVRs": 438.41
    },
    {
        "NavDate": "20 Feb 2020",
        "NAVRs": 436.79
    },
    {
        "NavDate": "24 Feb 2020",
        "NAVRs": 428.23
    },
    {
        "NavDate": "25 Feb 2020",
        "NAVRs": 427.36
    },
    {
        "NavDate": "26 Feb 2020",
        "NAVRs": 423.2
    },
    {
        "NavDate": "27 Feb 2020",
        "NAVRs": 421.68
    },
    {
        "NavDate": "28 Feb 2020",
        "NAVRs": 406.32
    },
    {
        "NavDate": "02 Mar 2020",
        "NAVRs": 404.75
    },
    {
        "NavDate": "03 Mar 2020",
        "NAVRs": 410.05
    },
    {
        "NavDate": "04 Mar 2020",
        "NAVRs": 407.78
    },
    {
        "NavDate": "05 Mar 2020",
        "NAVRs": 408.43
    },
    {
        "NavDate": "06 Mar 2020",
        "NAVRs": 398.94
    },
    {
        "NavDate": "09 Mar 2020",
        "NAVRs": 378.35
    },
    {
        "NavDate": "11 Mar 2020",
        "NAVRs": 379.01
    },
    {
        "NavDate": "12 Mar 2020",
        "NAVRs": 348.03
    },
    {
        "NavDate": "13 Mar 2020",
        "NAVRs": 362.09
    },
    {
        "NavDate": "16 Mar 2020",
        "NAVRs": 333.44
    },
   
    {
        "NavDate": "24 Mar 2020",
        "NAVRs": 283.8
    },
    {
        "NavDate": "25 Mar 2020",
        "NAVRs": 303.59
    },
    {
        "NavDate": "26 Mar 2020",
        "NAVRs": 318.6
    },
    {
        "NavDate": "27 Mar 2020",
        "NAVRs": 317.21
    },
    {
        "NavDate": "30 Mar 2020",
        "NAVRs": 302.58
    },
    {
        "NavDate": "31 Mar 2020",
        "NAVRs": 313.52
    },
    {
        "NavDate": "01 Apr 2020",
        "NAVRs": 300.72
    },
    {
        "NavDate": "03 Apr 2020",
        "NAVRs": 293.55
    },
    {
        "NavDate": "07 Apr 2020",
        "NAVRs": 319.89
    },
    {
        "NavDate": "08 Apr 2020",
        "NAVRs": 3318.04
    },
    {
        "NavDate": "09 Apr 2020",
        "NAVRs": 331.51
    },
    {
        "NavDate": "13 Apr 2020",
        "NAVRs": 326.51
    },
    {
        "NavDate": "15 Apr 2020",
        "NAVRs": 323.21
    },
    {
        "NavDate": "16 Apr 2020",
        "NAVRs": 325.58
    },
    {
        "NavDate": "17 Apr 2020",
        "NAVRs": 336.07
    },
    {
        "NavDate": "20 Apr 2020",
        "NAVRs": 336.7
    },
    {
        "NavDate": "21 Apr 2020",
        "NAVRs": 325.94
    },
    {
        "NavDate": "22 Apr 2020",
        "NAVRs": 333.84
    },
    {
        "NavDate": "23 Apr 2020",
        "NAVRs": 3538.98
    },
    {
        "NavDate": "24 Apr 2020",
        "NAVRs": 333.28
    },
    {
        "NavDate": "27 Apr 2020",
        "NAVRs": 337.7
    },
    {
        "NavDate": "28 Apr 2020",
        "NAVRs": 341.66
    },
    {
        "NavDate": "29 Apr 2020",
        "NAVRs": 348.1
    },
    {
        "NavDate": "30 Apr 2020",
        "NAVRs": 358.71
    },
    {
        "NavDate": "04 May 2020",
        "NAVRs": 337.41
    },
    {
        "NavDate": "05 May 2020",
        "NAVRs": 334.62
    },
    {
        "NavDate": "06 May 2020",
        "NAVRs": 337.09
    },
    {
        "NavDate": "07 May 2020",
        "NAVRs": 2334.51
    },
    {
        "NavDate": "08 May 2020",
        "NAVRs": 336.63
    },
    {
        "NavDate": "11 May 2020",
        "NAVRs": 335.76
    },
    {
        "NavDate": "12 May 2020",
        "NAVRs": 333.73
    },
    {
        "NavDate": "13 May 2020",
        "NAVRs": 340.51
    },
    {
        "NavDate": "14 May 2020",
        "NAVRs": 331.09
    },
    {
        "NavDate": "15 May 2020",
        "NAVRs": 330.82
    },
    {
        "NavDate": "18 May 2020",
        "NAVRs": 319.44
    },
    {
        "NavDate": "19 May 2020",
        "NAVRs": 321.22
    },
    {
        "NavDate": "20 May 2020",
        "NAVRs": 327.84
    },
    {
        "NavDate": "21 May 2020",
        "NAVRs": 329.06
    },
    {
        "NavDate": "22 May 2020",
        "NAVRs": 326.29
    },
    {
        "NavDate": "26 May 2020",
        "NAVRs": 325.61
    },
    {
        "NavDate": "27 May 2020",
        "NAVRs": 336.21
    },
    {
        "NavDate": "28 May 2020",
        "NAVRs": 342.54
    },
    {
        "NavDate": "29 May 2020",
        "NAVRs": 345.26
    },
    {
        "NavDate": "01 Jun 2020",
        "NAVRs": 354.62
    },
    {
        "NavDate": "02 Jun 2020",
        "NAVRs": 360.17
    },
    {
        "NavDate": "03 Jun 2020",
        "NAVRs": 363.25
    },
    {
        "NavDate": "04 Jun 2020",
        "NAVRs": 361.88
    },
    {
        "NavDate": "05 Jun 2020",
        "NAVRs": 365.14
    },
    {
        "NavDate": "08 Jun 2020",
        "NAVRs": 366.03
    },
    {
        "NavDate": "09 Jun 2020",
        "NAVRs": 361.62
    },
    {
        "NavDate": "10 Jun 2020",
        "NAVRs": 364.71
    },
    {
        "NavDate": "11 Jun 2020",
        "NAVRs": 357.19
    },
    {
        "NavDate": "12 Jun 2020",
        "NAVRs": 359.77
    },
    {
        "NavDate": "15 Jun 2020",
        "NAVRs": 353.9
    },
    {
        "NavDate": "16 Jun 2020",
        "NAVRs": 357.9
    },
    {
        "NavDate": "17 Jun 2020",
        "NAVRs": 356.87
    },
    {
        "NavDate": "18 Jun 2020",
        "NAVRs": 364.32
    },
    {
        "NavDate": "19 Jun 2020",
        "NAVRs": 370
    },
    {
        "NavDate": "22 Jun 2020",
        "NAVRs": 371.91
    },
    {
        "NavDate": "23 Jun 2020",
        "NAVRs": 377.43
    },
    {
        "NavDate": "24 Jun 2020",
        "NAVRs": 371.46
    },
    {
        "NavDate": "25 Jun 2020",
        "NAVRs": 371.17
    },
    {
        "NavDate": "26 Jun 2020",
        "NAVRs": 374.67
    },
    {
        "NavDate": "29 Jun 2020",
        "NAVRs": 372.44
    },
    {
        "NavDate": "30 Jun 2020",
        "NAVRs": 371.95
    },
    {
        "NavDate": "01 Jul 2020",
        "NAVRs": 377.26
    },
    {
        "NavDate": "02 Jul 2020",
        "NAVRs": 382.03
    },
    {
        "NavDate": "03 Jul 2020",
        "NAVRs": 383.92
    },
    {
        "NavDate": "06 Jul 2020",
        "NAVRs": 389.74
    },
    {
        "NavDate": "07 Jul 2020",
        "NAVRs": 391.73
    },
    {
        "NavDate": "08 Jul 2020",
        "NAVRs": 388.05
    },
    {
        "NavDate": "09 Jul 2020",
        "NAVRs": 392.76
    },
    {
        "NavDate": "10 Jul 2020",
        "NAVRs": 391.23
    },
    {
        "NavDate": "13 Jul 2020",
        "NAVRs": 392.29
    },
    {
        "NavDate": "14 Jul 2020",
        "NAVRs": 385.25
    },
    {
        "NavDate": "15 Jul 2020",
        "NAVRs": 385.45
    },
    {
        "NavDate": "16 Jul 2020",
        "NAVRs": 390
    },
    {
        "NavDate": "17 Jul 2020",
        "NAVRs": 395.84
    },
    {
        "NavDate": "20 Jul 2020",
        "NAVRs": 400.09
    },
    {
        "NavDate": "21 Jul 2020",
        "NAVRs": 405.54
    },
    {
        "NavDate": "22 Jul 2020",
        "NAVRs": 404.91
    },
    {
        "NavDate": "23 Jul 2020",
        "NAVRs": 407.84
    },
    {
        "NavDate": "24 Jul 2020",
        "NAVRs": 407.71
    },
    {
        "NavDate": "27 Jul 2020",
        "NAVRs": 405.64
    },
    {
        "NavDate": "28 Jul 2020",
        "NAVRs": 411.59
    },
    {
        "NavDate": "29 Jul 2020",
        "NAVRs": 407.11
    },
    {
        "NavDate": "30 Jul 2020",
        "NAVRs": 403.54
    },
    {
        "NavDate": "31 Jul 2020",
        "NAVRs": 402.16
    },
    {
        "NavDate": "03 Aug 2020",
        "NAVRs": 395.06
    },
    {
        "NavDate": "04 Aug 2020",
        "NAVRs": 403.04
    },
    {
        "NavDate": "05 Aug 2020",
        "NAVRs": 402.88
    },
    {
        "NavDate": "06 Aug 2020",
        "NAVRs": 406.86
    },
    {
        "NavDate": "07 Aug 2020",
        "NAVRs": 407.02
    },
    {
        "NavDate": "10 Aug 2020",
        "NAVRs": 408.53
    },
    {
        "NavDate": "11 Aug 2020",
        "NAVRs": 410.93
    },
    {
        "NavDate": "12 Aug 2020",
        "NAVRs": 410.53
    },
    {
        "NavDate": "13 Aug 2020",
        "NAVRs": 410.09
    },
    {
        "NavDate": "14 Aug 2020",
        "NAVRs": 405.47
    },
    {
        "NavDate": "17 Aug 2020",
        "NAVRs": 407.31
    },
    {
        "NavDate": "18 Aug 2020",
        "NAVRs": 412.41
    },
    {
        "NavDate": "19 Aug 2020",
        "NAVRs": 413.34
    },
    {
        "NavDate": "20 Aug 2020",
        "NAVRs": 409.13
    },
    {
        "NavDate": "21 Aug 2020",
        "NAVRs": 411.41
    },
    {
        "NavDate": "24 Aug 2020",
        "NAVRs": 415.3
    },
    {
        "NavDate": "25 Aug 2020",
        "NAVRs": 415.77
    },
    {
        "NavDate": "26 Aug 2020",
        "NAVRs": 418.23
    },
    {
        "NavDate": "27 Aug 2020",
        "NAVRs": 418.65
    },
    {
        "NavDate": "28 Aug 2020",
        "NAVRs": 422.43
    },
    {
        "NavDate": "31 Aug 2020",
        "NAVRs": 413.47
    },
    {
        "NavDate": "01 Sep 2020",
        "NAVRs": 416.38
    },
    {
        "NavDate": "02 Sep 2020",
        "NAVRs": 418.45
    },
    {
        "NavDate": "03 Sep 2020",
        "NAVRs": 417.43
    },
    {
        "NavDate": "04 Sep 2020",
        "NAVRs": 410.66
    },
    {
        "NavDate": "07 Sep 2020",
        "NAVRs": 411.3
    },
    {
        "NavDate": "08 Sep 2020",
        "NAVRs": 410.74
    },
    {
        "NavDate": "09 Sep 2020",
        "NAVRs": 408.9
    },
    {
        "NavDate": "10 Sep 2020",
        "NAVRs": 415.82
    },
    {
        "NavDate": "11 Sep 2020",
        "NAVRs": 415.97
    },
    {
        "NavDate": "14 Sep 2020",
        "NAVRs": 414.94
    },
    {
        "NavDate": "15 Sep 2020",
        "NAVRs": 418.02
    },
    {
        "NavDate": "16 Sep 2020",
        "NAVRs": 420.79
    },
    {
        "NavDate": "17 Sep 2020",
        "NAVRs": 417.33
    },
    {
        "NavDate": "18 Sep 2020",
        "NAVRs": 415.89
    },
    {
        "NavDate": "21 Sep 2020",
        "NAVRs": 407.2
    },
    {
        "NavDate": "22 Sep 2020",
        "NAVRs": 403.99
    },
    {
        "NavDate": "23 Sep 2020",
        "NAVRs": 403.28
    },
    {
        "NavDate": "24 Sep 2020",
        "NAVRs": 391.35
    },
    {
        "NavDate": "25 Sep 2020",
        "NAVRs": 400.29
    },
    {
        "NavDate": "28 Sep 2020",
        "NAVRs": 406.63
    },
    {
        "NavDate": "29 Sep 2020",
        "NAVRs": 406.54
    },
    {
        "NavDate": "30 Sep 2020",
        "NAVRs": 407.55
    },
    {
        "NavDate": "01 Oct 2020",
        "NAVRs": 414.28
    },
    {
        "NavDate": "05 Oct 2020",
        "NAVRs": 417.24
    },
    {
        "NavDate": "06 Oct 2020",
        "NAVRs": 423.67
    },
    {
        "NavDate": "07 Oct 2020",
        "NAVRs": 426.93
    },
    {
        "NavDate": "08 Oct 2020",
        "NAVRs": 430.18
    },
    {
        "NavDate": "09 Oct 2020",
        "NAVRs": 433.68
    },
    {
        "NavDate": "12 Oct 2020",
        "NAVRs": 434.58
    },
    {
        "NavDate": "13 Oct 2020",
        "NAVRs": 434.91
    },
    {
        "NavDate": "14 Oct 2020",
        "NAVRs": 436.85
    },
    {
        "NavDate": "15 Oct 2020",
        "NAVRs": 425.43
    },
    {
        "NavDate": "16 Oct 2020",
        "NAVRs": 428.16
    },
    {
        "NavDate": "19 Oct 2020",
        "NAVRs": 432.96
    },
    {
        "NavDate": "20 Oct 2020",
        "NAVRs": 434.16
    },
    {
        "NavDate": "21 Oct 2020",
        "NAVRs": 435.91
    },
    {
        "NavDate": "22 Oct 2020",
        "NAVRs": 434.36
    },
    {
        "NavDate": "23 Oct 2020",
        "NAVRs": 436.15
    },
    {
        "NavDate": "26 Oct 2020",
        "NAVRs": 430.37
    },
    {
        "NavDate": "27 Oct 2020",
        "NAVRs": 434.4
    },
    {
        "NavDate": "28 Oct 2020",
        "NAVRs": 428.12
    },
    {
        "NavDate": "29 Oct 2020",
        "NAVRs": 426.31
    },
    {
        "NavDate": "30 Oct 2020",
        "NAVRs": 424.86
    },
    {
        "NavDate": "02 Nov 2020",
        "NAVRs": 426.39
    },
    {
        "NavDate": "03 Nov 2020",
        "NAVRs": 431.78
    },
    
    
    {
        "NavDate": "10 Aug 2021",
        "NAVRs": 574.57
    },
    {
        "NavDate": "11 Aug 2021",
        "NAVRs": 574.28
    },
    {
        "NavDate": "12 Aug 2021",
        "NAVRs": 577.62
    },
    {
        "NavDate": "13 Aug 2021",
        "NAVRs": 583.86
    },
    {
        "NavDate": "16 Aug 2021",
        "NAVRs": 585.39
    },
    {
        "NavDate": "17 Aug 2021",
        "NAVRs": 587.62
    },
    {
        "NavDate": "18 Aug 2021",
        "NAVRs": 585.91
    },
    {
        "NavDate": "20 Aug 2021",
        "NAVRs": 582.75
    },
    {
        "NavDate": "23 Aug 2021",
        "NAVRs": 585.15
    },
    {
        "NavDate": "24 Aug 2021",
        "NAVRs": 589.39
    },
    {
        "NavDate": "25 Aug 2021",
        "NAVRs": 589.24
    },
    {
        "NavDate": "26 Aug 2021",
        "NAVRs": 589.29
    },
    {
        "NavDate": "27 Aug 2021",
        "NAVRs": 591.13
    },
    {
        "NavDate": "30 Aug 2021",
        "NAVRs": 599.19
    },
    {
        "NavDate": "31 Aug 2021",
        "NAVRs": 606.16
    },
    {
        "NavDate": "01 Sep 2021",
        "NAVRs": 603.91
    },
    {
        "NavDate": "02 Sep 2021",
        "NAVRs": 609.32
    },
    {
        "NavDate": "03 Sep 2021",
        "NAVRs": 612.24
    },
    {
        "NavDate": "06 Sep 2021",
        "NAVRs": 614
    },
    {
        "NavDate": "07 Sep 2021",
        "NAVRs": 613.81
    },
    {
        "NavDate": "08 Sep 2021",
        "NAVRs": 613.74
    },
    {
        "NavDate": "09 Sep 2021",
        "NAVRs": 614.32
    },
    {
        "NavDate": "13 Sep 2021",
        "NAVRs": 612.98
    },
    {
        "NavDate": "14 Sep 2021",
        "NAVRs": 613.71
    },
    {
        "NavDate": "15 Sep 2021",
        "NAVRs": 618.72
    },
    {
        "NavDate": "16 Sep 2021",
        "NAVRs": 623.12
    },
    {
        "NavDate": "17 Sep 2021",
        "NAVRs": 621.79
    },
    {
        "NavDate": "20 Sep 2021",
        "NAVRs": 616.26
    },
    {
        "NavDate": "21 Sep 2021",
        "NAVRs": 621.68
    },
    {
        "NavDate": "22 Sep 2021",
        "NAVRs": 620.85
    },
    {
        "NavDate": "23 Sep 2021",
        "NAVRs": 630.94
    },
    {
        "NavDate": "24 Sep 2021",
        "NAVRs": 632.66
    },
    {
        "NavDate": "27 Sep 2021",
        "NAVRs": 632.96
    },
    {
        "NavDate": "28 Sep 2021",
        "NAVRs": 628.64
    },
    {
        "NavDate": "29 Sep 2021",
        "NAVRs": 625.96
    },
    {
        "NavDate": "30 Sep 2021",
        "NAVRs": 622.94
    },
    {
        "NavDate": "01 Oct 2021",
        "NAVRs": 619.13
    },
    {
        "NavDate": "04 Oct 2021",
        "NAVRs": 624.75
    },
    {
        "NavDate": "05 Oct 2021",
        "NAVRs": 629.45
    },
    {
        "NavDate": "06 Oct 2021",
        "NAVRs": 623.6
    },
    {
        "NavDate": "07 Oct 2021",
        "NAVRs": 628.74
    },
    {
        "NavDate": "08 Oct 2021",
        "NAVRs": 632.75
    },
    {
        "NavDate": "11 Oct 2021",
        "NAVRs": 633.56
    },
    {
        "NavDate": "12 Oct 2021",
        "NAVRs": 635.12
    },
    {
        "NavDate": "13 Oct 2021",
        "NAVRs": 639.89
    },
    {
        "NavDate": "14 Oct 2021",
        "NAVRs": 645.95
    },
    {
        "NavDate": "18 Oct 2021",
        "NAVRs": 650.79
    },
    {
        "NavDate": "19 Oct 2021",
        "NAVRs": 650.26
    },
    {
        "NavDate": "20 Oct 2021",
        "NAVRs": 645.46
    },
    {
        "NavDate": "21 Oct 2021",
        "NAVRs": 642.02
    },
    {
        "NavDate": "22 Oct 2021",
        "NAVRs": 640.94
    },
    {
        "NavDate": "25 Oct 2021",
        "NAVRs": 642.47
    },
    {
        "NavDate": "26 Oct 2021",
        "NAVRs": 647.2
    },
    {
        "NavDate": "27 Oct 2021",
        "NAVRs": 645.01
    },
    {
        "NavDate": "28 Oct 2021",
        "NAVRs": 632.82
    },
    {
        "NavDate": "29 Oct 2021",
        "NAVRs": 625.68
    },
    {
        "NavDate": "01 Nov 2021",
        "NAVRs": 634.44
    },
    {
        "NavDate": "02 Nov 2021",
        "NAVRs": 633.28
    },
    {
        "NavDate": "03 Nov 2021",
        "NAVRs": 630.57
    },
    {
        "NavDate": "08 Nov 2021",
        "NAVRs": 638.72
    },
    {
        "NavDate": "09 Nov 2021",
        "NAVRs": 637.54
    },
    {
        "NavDate": "10 Nov 2021",
        "NAVRs": 636.68
    },
    {
        "NavDate": "11 Nov 2021",
        "NAVRs": 632.12
    },
    {
        "NavDate": "12 Nov 2021",
        "NAVRs": 640.2
    },
    {
        "NavDate": "15 Nov 2021",
        "NAVRs": 640.54
    },
    {
        "NavDate": "16 Nov 2021",
        "NAVRs": 636.36
    },
    {
        "NavDate": "17 Nov 2021",
        "NAVRs": 633.04
    },
    {
        "NavDate": "18 Nov 2021",
        "NAVRs": 629.11
    },
    {
        "NavDate": "22 Nov 2021",
        "NAVRs": 616.77
    },
    {
        "NavDate": "23 Nov 2021",
        "NAVRs": 618.86
    },
    {
        "NavDate": "24 Nov 2021",
        "NAVRs": 615.45
    },
    {
        "NavDate": "25 Nov 2021",
        "NAVRs": 620.24
    },
    {
        "NavDate": "26 Nov 2021",
        "NAVRs": 602.43
    },
    {
        "NavDate": "29 Nov 2021",
        "NAVRs": 604.04
    },
    {
        "NavDate": "30 Nov 2021",
        "NAVRs": 601.98
    },
    {
        "NavDate": "01 Dec 2021",
        "NAVRs": 608.52
    },
    {
        "NavDate": "02 Dec 2021",
        "NAVRs": 616.71
    },
    {
        "NavDate": "03 Dec 2021",
        "NAVRs": 608.64
    },
    {
        "NavDate": "06 Dec 2021",
        "NAVRs": 598.62
    },
    {
        "NavDate": "07 Dec 2021",
        "NAVRs": 607.97
    },
    {
        "NavDate": "08 Dec 2021",
        "NAVRs": 618.69
    },
    {
        "NavDate": "09 Dec 2021",
        "NAVRs": 620.35
    },
    {
        "NavDate": "10 Dec 2021",
        "NAVRs": 620.13
    },
    {
        "NavDate": "13 Dec 2021",
        "NAVRs": 614.82
    },
    {
        "NavDate": "14 Dec 2021",
        "NAVRs": 613.06
    },
    {
        "NavDate": "15 Dec 2021",
        "NAVRs": 609.59
    },
    {
        "NavDate": "16 Dec 2021",
        "NAVRs": 610.78
    },
    {
        "NavDate": "17 Dec 2021",
        "NAVRs": 601.37
    },
    {
        "NavDate": "20 Dec 2021",
        "NAVRs": 588.81
    },
    {
        "NavDate": "21 Dec 2021",
        "NAVRs": 594.05
    },
    {
        "NavDate": "22 Dec 2021",
        "NAVRs": 600.63
    },
    {
        "NavDate": "23 Dec 2021",
        "NAVRs": 604.69
    },
    {
        "NavDate": "24 Dec 2021",
        "NAVRs": 602.67
    },
    {
        "NavDate": "27 Dec 2021",
        "NAVRs": 605.79
    },
    {
        "NavDate": "28 Dec 2021",
        "NAVRs": 610.82
    },
    {
        "NavDate": "29 Dec 2021",
        "NAVRs": 609.85
    },
    {
        "NavDate": "30 Dec 2021",
        "NAVRs": 609.72
    },
    {
        "NavDate": "31 Dec 2021",
        "NAVRs": 614.57
    },
    {
        "NavDate": "03 Jan 2022",
        "NAVRs": 624.37
    },
    {
        "NavDate": "04 Jan 2022",
        "NAVRs": 631.46
    },
    {
        "NavDate": "05 Jan 2022",
        "NAVRs": 635.33
    },
    {
        "NavDate": "06 Jan 2022",
        "NAVRs": 628.78
    },
    {
        "NavDate": "07 Jan 2022",
        "NAVRs": 630.28
    },
    {
        "NavDate": "10 Jan 2022",
        "NAVRs": 637.15
    },
    {
        "NavDate": "11 Jan 2022",
        "NAVRs": 639.48
    },
    {
        "NavDate": "12 Jan 2022",
        "NAVRs": 645.1
    },
    {
        "NavDate": "13 Jan 2022",
        "NAVRs": 646
    },
    {
        "NavDate": "14 Jan 2022",
        "NAVRs": 645.87
    },
    {
        "NavDate": "17 Jan 2022",
        "NAVRs": 646.77
    },
    {
        "NavDate": "18 Jan 2022",
        "NAVRs": 640.92
    },
    {
        "NavDate": "19 Jan 2022",
        "NAVRs": 634.07
    },
    {
        "NavDate": "20 Jan 2022",
        "NAVRs": 627.48
    },
    {
        "NavDate": "21 Jan 2022",
        "NAVRs": 622.98
    },
    {
        "NavDate": "24 Jan 2022",
        "NAVRs": 606.67
    },
    {
        "NavDate": "25 Jan 2022",
        "NAVRs": 610.54
    },
    {
        "NavDate": "28 Jan 2022",
        "NAVRs": 603.6
    },
    {
        "NavDate": "31 Jan 2022",
        "NAVRs": 612.18
    },
    {
        "NavDate": "01 Feb 2022",
        "NAVRs": 621.13
    },
    {
        "NavDate": "02 Feb 2022",
        "NAVRs": 628.47
    },
    {
        "NavDate": "03 Feb 2022",
        "NAVRs": 620.52
    },
    {
        "NavDate": "04 Feb 2022",
        "NAVRs": 619.01
    },
    {
        "NavDate": "07 Feb 2022",
        "NAVRs": 608.21
    },
    {
        "NavDate": "08 Feb 2022",
        "NAVRs": 610.18
    },
    {
        "NavDate": "09 Feb 2022",
        "NAVRs": 617.19
    },
    {
        "NavDate": "10 Feb 2022",
        "NAVRs": 622.04
    },
    {
        "NavDate": "11 Feb 2022",
        "NAVRs": 613.88
    },
    {
        "NavDate": "14 Feb 2022",
        "NAVRs": 595.87
    },
    {
        "NavDate": "15 Feb 2022",
        "NAVRs": 614.19
    },
    {
        "NavDate": "16 Feb 2022",
        "NAVRs": 612.84
    },
    {
        "NavDate": "17 Feb 2022",
        "NAVRs": 611.73
    },
    {
        "NavDate": "18 Feb 2022",
        "NAVRs": 611.11
    },
    {
        "NavDate": "21 Feb 2022",
        "NAVRs": 609.53
    },
    {
        "NavDate": "22 Feb 2022",
        "NAVRs": 605.48
    },
    {
        "NavDate": "23 Feb 2022",
        "NAVRs": 604.76
    },
    {
        "NavDate": "24 Feb 2022",
        "NAVRs": 576.23
    },
    {
        "NavDate": "28 Feb 2022",
        "NAVRs": 594.36
    },
    {
        "NavDate": "02 Mar 2022",
        "NAVRs": 586.14
    },
    {
        "NavDate": "03 Mar 2022",
        "NAVRs": 582.27
    },
    {
        "NavDate": "04 Mar 2022",
        "NAVRs": 574.15
    },
    {
        "NavDate": "07 Mar 2022",
        "NAVRs": 558.4
    },
    {
        "NavDate": "08 Mar 2022",
        "NAVRs": 564.54
    },
    {
        "NavDate": "09 Mar 2022",
        "NAVRs": 577.46
    },
    {
        "NavDate": "10 Mar 2022",
        "NAVRs": 586.1
    },
    {
        "NavDate": "11 Mar 2022",
        "NAVRs": 587
    },
    {
        "NavDate": "14 Mar 2022",
        "NAVRs": 596.89
    },
    {
        "NavDate": "15 Mar 2022",
        "NAVRs": 589.39
    },
    {
        "NavDate": "16 Mar 2022",
        "NAVRs": 600.38
    },
    {
        "NavDate": "17 Mar 2022",
        "NAVRs": 611.44
    },
    {
        "NavDate": "21 Mar 2022",
        "NAVRs": 605.4
    },
    {
        "NavDate": "22 Mar 2022",
        "NAVRs": 612.77
    },
    {
        "NavDate": "23 Mar 2022",
        "NAVRs": 609.55
    },
    {
        "NavDate": "24 Mar 2022",
        "NAVRs": 608.6
    },
    {
        "NavDate": "25 Mar 2022",
        "NAVRs": 606.14
    },
    {
        "NavDate": "28 Mar 2022",
        "NAVRs": 608.58
    },
    {
        "NavDate": "29 Mar 2022",
        "NAVRs": 612.28
    },
    {
        "NavDate": "30 Mar 2022",
        "NAVRs": 620.1
    },
    {
        "NavDate": "31 Mar 2022",
        "NAVRs": 618.88
    },
    {
        "NavDate": "01 Apr 2022",
        "NAVRs": 626.36
    },
    {
        "NavDate": "04 Apr 2022",
        "NAVRs": 640.46
    },
    {
        "NavDate": "05 Apr 2022",
        "NAVRs": 635.93
    },
    {
        "NavDate": "06 Apr 2022",
        "NAVRs": 629.95
    },
    {
        "NavDate": "07 Apr 2022",
        "NAVRs": 623.87
    },
    {
        "NavDate": "08 Apr 2022",
        "NAVRs": 628.22
    },
    {
        "NavDate": "11 Apr 2022",
        "NAVRs": 623.12
    },
    {
        "NavDate": "12 Apr 2022",
        "NAVRs": 619.01
    },
    {
        "NavDate": "13 Apr 2022",
        "NAVRs": 616.5
    },
    {
        "NavDate": "18 Apr 2022",
        "NAVRs": 604.11
    },
    {
        "NavDate": "19 Apr 2022",
        "NAVRs": 596.68
    },
    {
        "NavDate": "20 Apr 2022",
        "NAVRs": 602.75
    },
    {
        "NavDate": "21 Apr 2022",
        "NAVRs": 612.01
    },
    {
        "NavDate": "22 Apr 2022",
        "NAVRs": 604.46
    },
    {
        "NavDate": "25 Apr 2022",
        "NAVRs": 597.94
    },
    {
        "NavDate": "26 Apr 2022",
        "NAVRs": 606.14
    },
    {
        "NavDate": "27 Apr 2022",
        "NAVRs": 600.46
    },
    {
        "NavDate": "28 Apr 2022",
        "NAVRs": 608.06
    },
    {
        "NavDate": "29 Apr 2022",
        "NAVRs": 603.2
    },
    {
        "NavDate": "02 May 2022",
        "NAVRs": 602.3
    },
    {
        "NavDate": "04 May 2022",
        "NAVRs": 588.48
    },
    {
        "NavDate": "05 May 2022",
        "NAVRs": 588.83
    },
    {
        "NavDate": "06 May 2022",
        "NAVRs": 579.68
    },
    {
        "NavDate": "09 May 2022",
        "NAVRs": 575.82
    },
    {
        "NavDate": "10 May 2022",
        "NAVRs": 574.7
    },
    {
        "NavDate": "11 May 2022",
        "NAVRs": 571.77
    },
    {
        "NavDate": "12 May 2022",
        "NAVRs": 560.17
    },
    {
        "NavDate": "13 May 2022",
        "NAVRs": 558.72
    },
    {
        "NavDate": "16 May 2022",
        "NAVRs": 560.62
    },
    {
        "NavDate": "17 May 2022",
        "NAVRs": 574.84
    },
    {
        "NavDate": "18 May 2022",
        "NAVRs": 573.67
    },
    {
        "NavDate": "19 May 2022",
        "NAVRs": 558.7
    },
    {
        "NavDate": "20 May 2022",
        "NAVRs": 574.92
    },
    {
        "NavDate": "23 May 2022",
        "NAVRs": 574.52
    },
    {
        "NavDate": "24 May 2022",
        "NAVRs": 572.02
    },
    {
        "NavDate": "25 May 2022",
        "NAVRs": 569.28
    },
    {
        "NavDate": "26 May 2022",
        "NAVRs": 575.11
    },
    {
        "NavDate": "27 May 2022",
        "NAVRs": 581.79
    },
    {
        "NavDate": "30 May 2022",
        "NAVRs": 592.79
    },
    {
        "NavDate": "31 May 2022",
        "NAVRs": 590.05
    },
    {
        "NavDate": "01 Jun 2022",
        "NAVRs": 588.09
    },
    {
        "NavDate": "02 Jun 2022",
        "NAVRs": 592.71
    },
    {
        "NavDate": "03 Jun 2022",
        "NAVRs": 592.19
    },
    {
        "NavDate": "07 Jun 2022",
        "NAVRs": 585.19
    },
    {
        "NavDate": "08 Jun 2022",
        "NAVRs": 582.92
    },
    {
        "NavDate": "09 Jun 2022",
        "NAVRs": 587.51
    },
    {
        "NavDate": "10 Jun 2022",
        "NAVRs": 576.75
    },
    {
        "NavDate": "13 Jun 2022",
        "NAVRs": 561.35
    },
    {
        "NavDate": "14 Jun 2022",
        "NAVRs": 559.72
    },
    {
        "NavDate": "15 Jun 2022",
        "NAVRs": 558.65
    },
    {
        "NavDate": "16 Jun 2022",
        "NAVRs": 547.59
    },
    {
        "NavDate": "17 Jun 2022",
        "NAVRs": 546.15
    },
    {
        "NavDate": "20 Jun 2022",
        "NAVRs": 548.66
    },
    {
        "NavDate": "21 Jun 2022",
        "NAVRs": 558.55
    },
    {
        "NavDate": "22 Jun 2022",
        "NAVRs": 551.04
    },
    {
        "NavDate": "23 Jun 2022",
        "NAVRs": 555.73
    },
    {
        "NavDate": "24 Jun 2022",
        "NAVRs": 560.62
    },
    {
        "NavDate": "27 Jun 2022",
        "NAVRs": 565.2
    },
    {
        "NavDate": "28 Jun 2022",
        "NAVRs": 565.37
    },
    {
        "NavDate": "29 Jun 2022",
        "NAVRs": 563.77
    },
    {
        "NavDate": "30 Jun 2022",
        "NAVRs": 563.74
    },
    {
        "NavDate": "01 Jul 2022",
        "NAVRs": 562.56
    },
   
]
export {chart1,chart2}
