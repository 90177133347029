import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
import Networking from '../../utils/api/apiaccess';
var axios = require('axios');

/* name should be camel case in all function */
function getAllProfitLossDetails(Account, ClientID, CommonDate, Date, ToDate, AccountType, AssetClass, SessionID, Scheme, ProductID, FamilyID, L5ID, L4ID, FinantialYearId) {
  //let BaseUrl = "https://ms.zoifintech.com/Dashboard/GetProfitLossDetails"
  let data = {};
  data.Account = Account
  data.ClientID = ClientID
  data.CommonDate = CommonDate
  data.Date = Date
  data.ToDate = ToDate
  data.SessionID = SessionID
  data.AccountType = AccountType
  data.AssetClass = AssetClass
  data.Scheme = Scheme
  data.ProductID = ProductID
  data.FamilyID = FamilyID
  data.L5ID = L5ID
  data.L4ID = L4ID
  data.FinantialYearID = FinantialYearId || 0

  // var config = {
  //   method: 'post',
  //   url: ApiValues.ProfitAndLoss,
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   data: data
  // };

  // return axios(config)
  //   .then(function (response) {
  //     return response.data;
  //   })
  //   .catch(function (error) {
  //     return { "status": "F", "message": labels.messages.apierror }

  //   });
  return Networking.PostApi(ApiValues.ProfitAndLoss, data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default getAllProfitLossDetails
