// common card created by yogitha on 23/07/21
import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import Button from '../../components/custombutton/button';
import colors from "../../utils/colors/colors";
import Skeleton from "@material-ui/lab/Skeleton";
import labels from "../../utils/constants/labels";
import { NegativeFinding } from "../../utils/commonfunction";
const styles = theme => ({
    card: {
        boxShadow: 'rgb(19 89 161 / 10%) 0px 0px 20px 0px',
        transition: '0.3s',
        borderRadius: '5px',
        margin: '5%',
        // padding: '10%',
        minHeight: "195px",
        height: 'calc(100% - 11%)',
        lineHeight: "1.5em",
        position: 'relative',
    },
    cards: {
        boxShadow:'rgb(19 89 161 / 10%) 0px 0px 20px 0px',
        transition: '0.3s',
        borderRadius: '5px',
        margin: '5%',
        padding: '10%',
        minHeight: "125px",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: '10px',
    },
    buttonpaddingTop: {
        // // paddingTop: '25px',
        // position: 'absolute',
        // padding: '0% 13% 5% 13%',
        // minWidth: '75%',
        // bottom: 0,
        paddingTop: "25px",
    },

    normalcard: {
        boxShadow: 'rgb(19 89 161 / 10%) 0px 0px 20px 0px',
        transition: '0.3s',
        borderRadius: '5px',
        margin: '5%',
        padding: 7,
        // minHeight: "195px",
        minHeight: 'calc(100% - 16%)',
        lineHeight: "1.5em",
        position: 'relative',
        display: 'flex',
        gap:9,
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        // padding: '10%'
        [theme.breakpoints.down("md")]: {
            lineHeight: "revert",
        },
    },
    onClickCard: {
        boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
        transition: '0.3s',
        borderRadius: '5px',
        margin: '5%',
        padding: '10%',
        '&:hover': {
            // boxShadow: "0 0 20px 0 rgb(0 0 0 / 19%)",
            // transform: `scale(${1.1})`
            backgroundColor: window.globalConfig.color.RosyBrown
        },
        [theme.breakpoints.down("600")]: {
            padding: '7%',
        },
    },
    cardDisabled: {
        backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
        boxShadow: "0 0 20px 0 rgb(0 0 0 / 10%)",
        transition: '0.3s',
        borderRadius: '5px',
        margin: '5%',
        // padding: '10%',
        cursor: 'not-allowed',
        minHeight: "195px",
        height: 'calc(100% - 20%)',
        lineHeight: "1.5em",
        position: 'relative',
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        whiteSpace: 'normal',
        flexWrap: 'wrap',
        justifyContent: "center",
        lineHeight: "25px",
        fontSize: "14px",
    },
    flexRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        // lineHeight: "2.8em",
    },
    cardsDisabled: {
        backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
        boxShadow: "0 0 20px 0 rgb(0 0 0 / 10%)",
        transition: '0.3s',
        borderRadius: '5px',
        margin: '5%',
        padding: '10%',
        cursor: 'not-allowed',
        minHeight: "125px",
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: '10px',
    },
    imageTemplate: {
        // padding: '25px 0px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '13px',
        // paddingTop: '20px',
    },
    
    labelcolor: {
        color: window.globalConfig.color.LABLE_GRAY,
    },
    paddingAlignment: {
        padding: "10px 0px",
    },
    greenLableColor: {
        marginLeft: 5,
        display: 'flex',
        gap: '3px',
        // flexWrap:'initial',
    },
    outlineButtonPadding: {
        minWidth: '75%',
        padding: '0% 13% 0% 13%',
        display: 'flex',
        gap: '5px',
        // position: 'absolute',
        // bottom: '0px',
        // padding: "0% 13% 8% 9%",
        // [theme.breakpoints.down("md")]: {
        //     padding: "0% 13% 4% 9%",
        // },
        // [theme.breakpoints.down("sm")]: {
        //     padding: "0% 9% 9% 7%",
        // },
        // [theme.breakpoints.down("xs")]: {
        //     padding: "0% 13% 9% 8%",
        // },
    },
    
    totalCurrentValueSpan: {
        marginLeft: "0px",
    },
}
)
class CommonCard extends Component {
    constructor() {
        super();
        this.state = {


        }
    }
    render() {

        const { classes } = this.props;
        if (this.props.title === "card") {
            return (<div >
                <div className={this.props.disabled == true ? classes.cardDisabled : classes.card}>
                    <div className={classes.imageTemplate}>
                        <img src={this.props.src} width={70} height={70} />
                        <div>{this.props.label}</div>
                    </div>
                    <div className={classes.buttonpaddingTop}>
                        <Button name={this.props.buttonName} page={this.props.buttonpage} disabled={this.props.disabled} loader={this.props.loader} onClick={this.props.handleClick} />
                    </div>
                </div>
            </div>
            )
        }
        else if (this.props.title === "cards") {
            return (<div >
                <div className={[this.props.disabled ? classes.cardsDisabled : classes.cards].join(' ')} style={{ cursor: this.props.disabled ? "not-allowed" : "pointer" }}
                    onClick={() => {

                        if (this.props.disabled) {
                            // 
                        } else {
                            this.props.onClick()
                        }
                    }
                    }>
                    <img src={this.props.src} width={this.props.imgWidth} />
                    <div>{this.props.label}</div>
                </div>
            </div>
            )
        }
        else {
            return (
                <div className={this.props.disabled == true ? classes.cardDisabled : classes.normalcard}>
                    {this.props.loader?     <Skeleton
                                            style={{
                                                width: "calc(100% - 10px)",
                                                minHeight: "195px",
                                                marginLeft: "2%",
                                            }}
                                        />:
                    this.props.data.length == 0 || (this.props.imported && this.props.data[0].MF_CurrentValue_Imported == 0) ? <>       <div className={classes.imageTemplate}>
                        <img src={this.props.src} width={70} height={70} />
                        <div>{this.props.label}</div>
                    </div>
                        <div className={classes.outlineButtonPadding}>
                            <Button name={"Track Now"} page={"primary"} disabled={this.props.trackNowDisabled} loader={this.props.loader} onClick={this.props.trackNowDisabled?"":this.props.trackNowClick} />
                        </div></> : <>
                        {this.props.imported&&<div style={{fontSize:17,fontWeight:900}}>{this.props.label}</div>}
                        <div
                            className={[
                                classes.labelcolor,
                                classes.paddingAlignment,
                            ].join(" ")}
                        >
                            {labels.Common.currentValue}
                            <span style={{ fontSize: "smaller" }}>
                                {this.props.imported?"(Imported)":`(${window.globalConfig.companyDetails.companyName == null ? Asset.companyName : `${window.globalConfig.companyDetails.companyName}`})`}
                            </span>
                        </div>
                        <strong style={{ fontSize: 20 }}>

                            <span>
                                {this.props.imported?NegativeFinding(this.props.data[0].MF_CurrentValue_Imported, "", "", "", "rupee"):this.props.data[0].MF_CurrentValue != 0
                                    ? NegativeFinding(this.props.data[0].MF_CurrentValue, "", "", "", "rupee")
                                    : NegativeFinding(0, "", "", "", "rupee")}
                            </span>
                        </strong>
                        <div className={classes.flexRow}>
                            <div
                                className={[
                                    classes.labelcolor,
                                    classes.flex,
                                ].join(" ")}
                            >
                                {labels.Common.DayReturn}{" "}

                                <strong
                                    className={classes.greenLableColor}
                                >
                                    <div style={{ display: 'flex', flexWrap: 'wrap', wordBreak: 'mormal', justifyContent: "center" }}>
                                        <label
                                            style={{
                                                color: this.props.imported?NegativeFinding(this.props.data[0].MF_ReturnValue_Imported, "", "", "",""):NegativeFinding(
                                                    this.props.data[0].MF_ReturnValue != 0
                                                        ? this.props.data[0].MF_ReturnValue
                                                        : 0
                                                ),
                                            }}
                                        >

                                            {this.props.imported?NegativeFinding(this.props.data[0].MF_ReturnValue_Imported, "", "", "","rupee"):this.props.data[0].MF_ReturnValue != 0
                                                ? NegativeFinding(this.props.data[0].MF_ReturnValue, "", "", "", "rupee")
                                                : NegativeFinding(0, "", "", "", "rupee")}
                                        </label>
                                        <div
                                            style={{
                                                color:this.props.imported?NegativeFinding(this.props.data[0].MF_ReturnPercent_Imported, "", "", "",""): NegativeFinding(
                                                    this.props.data[0].MF_ReturnPercent
                                                ),
                                            }}
                                            className={[classes.labelcolor, classes.totalCurrentValueSpan,].join(" ")}
                                        >

                                            (
                                            {
                                             this.props.imported?NegativeFinding(this.props.data[0].MF_ReturnPercent_Imported, "", "", "","percentage"):    NegativeFinding(this.props.data[0].MF_ReturnPercent, "", "", "", "percentage")
                                            }
                                            )
                                        </div>
                                    </div>
                                </strong>

                            </div>
                        </div>
                        <div className={classes.outlineButtonPadding} style={{padding:this.props.imported?"0px":"0% 13% 0% 13%"}}>
                            {/* ........//navigation mutual funds report........... */}
                            <Button
                                name={this.props.imported?"Report":this.props.label}
                                page={"primary"}
                                disabled={this.props.reportDisabled}
                                loader={this.props.loader}
                                onClick={this.props.reportDisabled?"":this.props.reportClick}
                            />            
                            {this.props.imported&& <Button
                                name={"Track Now"}
                                page={"primary"}
                                disabled={this.props.trackNowDisabled}
                                loader={this.props.loader}
                                onClick={this.props.trackNowDisabled?"":this.props.trackNowClick}
                            />}
                        </div>
                    </>}
                </div>

            )
        }
    }
}
export default withStyles(styles)(CommonCard);