import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
function GetFDRNumber(ClientID) {
    return Networking.GetApi(ApiValues.GetFDRNumber+'?ClientID='+ClientID).then(result => {
        return result
    }).catch(function (error) {
        return { "status": "F", "message": labels.messages.apierror }
    });
}
export default GetFDRNumber