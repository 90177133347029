import * as types from '../../constants/actionsType';
import getAllXraywithRedemption from '../../../api/xraywithredemption/xraywithredemption'
import constants from '../../../utils/constants/apiconstant';
export function getXraywithRedemption(ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, flag) {
  return (dispatch) => {
    getAllXraywithRedemptionAction(dispatch, ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, flag);
  };
}
function getAllXraywithRedemptionAction(dispatch, ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, flag) {
  // empty  mf
  if (ProductID === 1) {
    dispatch({
      type: types.EMPTY_XRAY_WITH_REDEMPTION,
      payload: [],
    });
    //empty mf loader page
    dispatch({
      type: types.LOADER_XRAY_WITH_REDEMPTION,
      payload: true,
    });
  }
  else if (ProductID === 2) {
    // empty  equity report
    dispatch({
      type: types.EMPTY_EQUITY_XRAY_WITH_REDEMPTION,
      payload: [],
    });
    // empty equity  loader
    dispatch({
      type: types.LOADER_EQUITY_XRAY_WITH_REDEMPTION,
      payload: true,
    });
  }
  else if (ProductID === 3) {
    // empty  bonds report
    dispatch({
      type: types.EMPTY_BONDS_XRAY_WITH_REDEMPTION,
      payload: [],
    });
    // empty bonds  loader
    dispatch({
      type: types.LOADER_BONDS_XRAY_WITH_REDEMPTION,
      payload: true,
    });
  }
  else if (ProductID === 4) {
    // empty  gold bonds report
    dispatch({
      type: types.EMPTY_GB_XRAY_WITH_REDEMPTION,
      payload: [],
    });
    // empty golds bonds  loader
    dispatch({
      type: types.LOADER_GB_XRAY_WITH_REDEMPTION,
      payload: true,
    });
  }
  else {
    // empty fds report
    dispatch({
      type: types.EMPTY_FD_XRAY_WITH_REDEMPTION,
      payload: [],
    });
    // empty fds loader
    dispatch({
      type: types.LOADER_FD_XRAY_WITH_REDEMPTION,
      payload: true,
    });
  }
  getAllXraywithRedemption(ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID)
    .then((data) => {
      //mf prevent api  call
      dispatch({
        type: types.PREVENT_API_CALL,
        payload:[{ProductID:ProductID,api:"XrayWithRedemption"}]
      });
      if (ProductID === 1) {
        // dispatch({
        //   type: types.PREVENT_API_CALL,
        //   payload: "XrayWithRedemption"
        // });
        //mf report 
        dispatch({
          type: types.XRAY_WITH_REDEMPTION,
          payload: data,
        });

      }
      else if (ProductID === 2) {
        //empty  equity prevent api call
        // dispatch({
        //   type: types.PREVENT_API_CALL,
        //   payload: "XrayWithSoldUnits"
        // });
        // equity report
        dispatch({
          type: types.EQUITY_XRAY_WITH_REDEMPTION,
          payload: data,
        });

      }
      else if (ProductID === 3) {
        //empty  bonds prevent api call
        // dispatch({
        //   type: types.PREVENT_API_CALL,
        //   payload: "BondsXrayWithSoldUnits"
        // });
        // bonds report
        dispatch({
          type: types.BONDS_XRAY_WITH_REDEMPTION,
          payload: data,
        });
      }
      else if (ProductID === 4) {
        //empty  gold bonds prevent api call
        // dispatch({
        //   type: types.PREVENT_API_CALL,
        //   payload: "gbXrayWithSoldUnits"
        // });
        // gold bonds report
        dispatch({
          type: types.GB_XRAY_WITH_REDEMPTION,
          payload: data,
        });

      }
      else {
        //empty  fds bonds prevent api call
        // dispatch({
        //   type: types.PREVENT_API_CALL,
        //   payload: "fdXrayWithSoldUnits"
        // });
        //fds report
        dispatch({
          type: types.FD_XRAY_WITH_REDEMPTION,
          payload: data,
        });

      }

    })
    .catch((error) => {
      //mf privent api call
      dispatch({
        type: types.PREVENT_API_CALL,
        payload:[{ProductID:ProductID,api:"XrayWithRedemption"}]
      });
      if (ProductID === 1) {
        // dispatch({
        //   type: types.PREVENT_API_CALL,
        //   payload: "XrayWithRedemption"
        // });
        //mf report
        dispatch({
          type: types.XRAY_WITH_REDEMPTION,
          payload: [],
        });

      }
      //equity prevent api call
      else if (ProductID === 2) {
        // dispatch({
        //   type: types.PREVENT_API_CALL,
        //   payload: "XrayWithSoldUnits"
        // });
        //equity xray with sold report
        dispatch({
          type: types.EQUITY_XRAY_WITH_REDEMPTION,
          payload: [],
        });

      }
       //bonds prevent api call
       else if (ProductID === 3) {
        // dispatch({
        //   type: types.PREVENT_API_CALL,
        //   payload: "BondsXrayWithSoldUnits"
        // });
        //bonds xray with sold report
        dispatch({
          type: types.BONDS_XRAY_WITH_REDEMPTION,
          payload: [],
        });

      }
       //gold bonds prevent api call
       else if (ProductID === 4) {
        // dispatch({
        //   type: types.PREVENT_API_CALL,
        //   payload: "gbXrayWithSoldUnits"
        // });
        //gold bonds xray with sold report
        dispatch({
          type: types.GB_XRAY_WITH_REDEMPTION,
          payload: [],
        });

      }
       //fixed deposits prevent api call
       else {
        // dispatch({
        //   type: types.PREVENT_API_CALL,
        //   payload: "fdXrayWithSoldUnits"
        // });
        //fixed deposits xray with sold report
        dispatch({
          type: types.FD_XRAY_WITH_REDEMPTION,
          payload: [],
        });
      }

    })
}
