import React from 'react';
import '../../utils/stylesheets/table/table.css'
import { withStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors/colors';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { connect } from 'react-redux';
import labels from '../../utils/constants/labels';
const styles = theme => ({


    schemeName: {
        fontSize: 20,
        color: 'white',
        fontWeight: 'bold'
    },

    textName: {
        fontSize: 14,
        color: window.globalConfig.color.GRAY,
        fontWeight: 'medium'
    },
    textScheme: {
        fontSize: 14,
        color: window.globalConfig.color.BLUE,
        fontWeight: 'bold',
    },
    card: {
        minHeight: 0,

    },
    textAmount: {
        fontSize: 14,
        color: window.globalConfig.color.black,
        fontWeight: 'bold'
    },
    text: {
        fontSize: 14,
        color: window.globalConfig.color.BLACK,
        fontWeight: 'medium'
    }
})
class TradingTable extends React.Component {
    constructor(props) {
        super();
        this.state = {

        }
    }

    render() {
        const { classes } = this.props;
        return (
            <>
               
                <div className="tableContainerResponsive">

                    {this.props.list.map((value,index) => {
                        return (
                            <div key={index} className="mfOrder">
                                <div className="mfOrderBlock">
                                    <div className="mfOrderLeft">
                                        <span className={classes.textName}>{labels.transactionOrder.schemenames}</span><br />
                                        <span className={classes.textAmount}>{value.SchemeName}</span>
                                    </div>
                                </div>
                                <div className="mfOrderBlock">
                                    <div className="mfOrderLeft">
                                        <span className={classes.textName}>{labels.transactionOrder.dates}</span><br />
                                        <span className={classes.text}>{value.Date}</span>
                                    </div>
                                    <div className="mfOrderRight">
                                        <span className={classes.textName}>{labels.transactionOrder.amounts}</span><br />
                                        <span className={classes.text}>{labels.currency.rupeesymbol}{value.Amount}</span>
                                    </div>
                                </div>
                                <div className="mfOrderBlock">
                                    <div className="mfOrderLeft">
                                        {this.props.tableName == "MFTransaction" ?
                                            <div>
                                                <span className={classes.textName}>{labels.transactionOrder.statuses}<br /></span>
                                                <span className={classes.text} style={{ color: value.Status == "Failed" ? window.globalConfig.color.red : value.Status == "Success" ? window.globalConfig.color.GREEN : window.globalConfig.color.black }}>{value.Status}</span>
                                            </div>
                                            :
                                            <div>
                                                <span className={classes.textName}>{labels.upComingSIP.AMCName}</span><br />
                                                <span className={classes.text}>{value.AMC}</span>
                                            </div>
                                        }
                                    </div>
                                    <div className="mfOrderRight">
                                        <span className={classes.textName}>{labels.transactionOrder.type}</span><br />
                                        <span className={classes.text}>{value.Type}</span>
                                    </div>
                                </div>

                            </div>
                        )
                    })}

                </div>
            </>)
    }
}
export default withStyles(styles)(connect(null)(TradingTable));
