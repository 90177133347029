import * as types from '../../constants/actionsType';
import getTrackEquityImportedDetails from '../../../api/track/equity_trackimported_api';
export function getTrackDetails(clientID,ProductID,FamilyID,L5ID,L4ID) {
    return (dispatch) => {
        getTrackDetailsAction(dispatch,clientID,ProductID,FamilyID,L5ID,L4ID)
    };
}
function getTrackDetailsAction(dispatch,clientID,ProductID,FamilyID,L5ID,L4ID) {
    dispatch({
        type: types.EMPTY_TRACK_DETAILS,
        payload: [],
    });

    dispatch({
        type: types.LOADER_TRACK_DETAILS,
        payload: true,
    });
    getTrackEquityImportedDetails(clientID,ProductID,FamilyID,L5ID,L4ID)
    .then((data) => {
        // 
        
        dispatch({
            type: types.LOADER_TRACK_DETAILS,
            payload: false,
        });
        dispatch({
            type: types.PREVENT_API_CALL,
          
            payload:[{ProductID:0,api:"Track"}],
          }); 
        if(data.status==="S"){
            dispatch({
                type: types.TRACK_DETAILS,
                payload: [...data.data],
            });
        }
       else{
        dispatch({
            type: types.TRACK_DETAILS,
            payload: [],
        });
       }
       
       
    })
    .catch((error) => {
        dispatch({
            type: types.TRACK_DETAILS,
            payload: [],
        });
        dispatch({
            type: types.LOADER_TRACK_DETAILS,
            payload: false,
        });
       
    })
}
