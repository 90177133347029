import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
import Networking from '../../utils/api/apiaccess';
function getTransactionList(Account, ClientID, InceptionDate, TransactionType, Type, UserID, SessionID,SchemeID,AssetClass,AccountType,FromDate,ToDate,ProductID,FamilyID,L5ID,L4ID) {
 // let BaseUrl = "https://ms.zoifintech.com/Dashboard/PortfolioTransaction"

  let data = {};
  data.Account = Account
  data.ClientID = Number(ClientID)
  data.InceptionDate = InceptionDate
  data.TransactionType = TransactionType
  data.Type = Type
  data.UserID = Number(UserID)
  data.SessionID = SessionID
  data.SchemeID = SchemeID
  data.AssetClass = AssetClass
  data.AccountType = AccountType
  data.FromDate = FromDate
  data.ToDate = ToDate
  data.ProductID = ProductID
  data.FamilyID = FamilyID
  data.L5ID = L5ID
  data.L4ID = L4ID
//   (data)
return Networking.PostApi(ApiValues.Transactions , data).then(result => { return result })
.catch(function (error) {
  return { "status": "F", "message": labels.messages.apierror }
});
  // return fetch(ApiValues.Transactions, {
  //   method: 'POST',
  //   headers: {
  //     'Accept': 'application/json',
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify(data),
  // })
  //   .then((response) => response.json())
  //   .then((responseJson) => {
  //     //   ("transaction",responseJson)
  //     return responseJson

  //   })
  //   .catch((error) => {
  //     //Error 
  //     return {"status":"F","message":labels.messages.apierror}
  //   });

}
export default getTransactionList;