import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Textinput from "../../components/textinput/textinput";
import Dropdown from "../../components/dropdown/dropdownschemes";
import Autocomplete from "../../components/autocomplete/autocomplete";
import Buttons from "../../components/custombutton/button";
import Menu from "@material-ui/core/Menu";
import { scrollToTopPage } from '../../utils/commonfunction';
import MenuItem from "@material-ui/core/MenuItem";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Button from "@material-ui/core/Button";
import Question from "../../utils/assets/images/img5.png";
import color from "../../utils/colors/colors";
import labels from "../../utils/constants/labels";
import Text from "../../components/text/text";
import ErrorBoundary from "../../components/Errorhandling/Errorhandle";
import ArrowIcon from "../../utils/assets/images/img14.png";
import InformationTooltip from "../../components/tooltip/informationtooltip";
import colors from "../../utils/colors/colors";
import { NegativeFinding } from "../../utils/commonfunction";
const styles = (theme) => ({
  box: {
    border: `1px solid ${window.globalConfig.color.red}`,
    height: "fit-content",
    width: "42vw",
    marginLeft: 20,
    marginRight: 20,
    backgroundColor: window.globalConfig.color.RosyBrown,
    marginTop: 20,
    [theme.breakpoints.down("600")]: {
      width: "89%",
      height: "fit-content",
      marginLeft: 0,
      marginRight: 20,
    },
  },
  boxMain: {
    textAlign: "left",
    fontSize: 14,
    color: "red",
    paddingLeft: 10,
  },
  //   image: {
  //     height: 15,
  //     width: 15,
  // },
  boxSub: {
    textAlign: "left",
    fontSize: 12,
    paddingLeft: 10,
    paddingRight: 10,
  },
  schemeDropDown: {
    // width: "96.5%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  textInput: {
    height: "38px",
    width: "97.6%",
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    color: window.globalConfig.color.BLACK_PRIMARY,
    outline: "none !important",
    fontSize: "14px",
    marginTop: "1%",
    "&:hover": {
      border: `1px solid ${window.globalConfig.color.BROWN}`,
    },
  },
  root: {
    // marginLeft: 20,
    // marginTop: 20,
    textAlign: "left",
  },
  container: {
    // marginLeft: 15,
    // width: "100%",
    // [theme.breakpoints.down("md")]: {
    //   width: "100%",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   marginLeft: 15,
    //   width: "100%",
    // },
    //marginRight: -20
  },
  divLine1: {
    height: "0.5px",
    width: "100%",
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    marginTop: "3%",
    marginBottom: "-4%",
    //boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.3)",
  },
  btnApply: {
    background: window.globalConfig.color.BROWN,
    marginLeft: "7px",
    padding: 5,
    // lineHeight: "36px",
    color: window.globalConfig.color.WHITE,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    // width: "100px",
    float: "right",
    borderRadius: "5px",
    border: "1px solid transparent",
    "&:hover": {
      background: window.globalConfig.color.PRIMARY,
      color: window.globalConfig.color.WHITE,
    },
  },
  folio: {
    width: "93.9%",
    backgroundColor: window.globalConfig.color.RosyBrown,
    padding: 10,
    height: 35,
    paddingTop: 15,
  },
  switchErrorMsg: {
    color: window.globalConfig.color.red,
    fontSize: 14,
    float: "left",
    marginLeft: 0,
    marginTop: "1%",
  },
  label: {
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontSize: 14,
    marginLeft: -550,
  },

  label1: {
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: 14,
  },

  checkBox: {
    fontSize: 12,
    marginLeft: -235,
  },
  folioBlock: {
    float: "left",
    padding: 2,
    // paddingRight: 15,
    //paddingLeft: 15,
    backgroundColor: window.globalConfig.color.BORDER,
    borderRadius: 100,
    fontWeight: 400,
    fontSize: 13,
    minWidth: "30%",
    textTransform: "none !important",
    [theme.breakpoints.between("1000", "1050")]: {
      width: "60%",
    },
    [theme.breakpoints.down("900")]: {
      width: "60%",
    },
    [theme.breakpoints.down("600")]: {
      width: "100%",
    },
  },
  placeOrder: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "8%",
    // marginRight: "4.5%",
    // marginLeft: "3%",
    width: "20%",
    float: "right",
    [theme.breakpoints.between("1000", "1050")]: {
      width: "40%",
    },
    [theme.breakpoints.down("600")]: {
      width: "40%",
    },
  },
  foliono: {
    // width: "94%",
    paddingTop: "3%",
    backgroundColor: window.globalConfig.color.RosyBrown,
    minHeight: 35,
    padding: 10,
    gap: 10,
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "space-between",
    // [theme.breakpoints.down("600")]: {
    //   width: "94%",
    // },
    // [theme.breakpoints.down("350")]: {
    //   width: "92%",
    // },
    // [theme.breakpoints.between("1000", "1050")]: {
    //   width: "92%",
    // },
  },
  desktopResponsive: {
    display: 'block !important',
    height: 'auto',
    padding: 10
  },
  //Added by Celsia on 04 Jun 2021 for Mobile Responsive Started

  // mobileResponsive: {
  //   display: 'none !important',
  //   [theme.breakpoints.down("600")]: {
  //     display: 'block!important',
  //   },
  // },
  mobiletextResponsive: {
    display: "none !important",
    [theme.breakpoints.down("600")]: {
      display: "block!important",
      width: "88vw!important",
      height: "30vh!important",
    },
  },
  folio1: {
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    height: 30,
    padding: 10,
  },
  switchErrorMsg1: {
    color: window.globalConfig.color.red,
    fontSize: 14,
    float: "left",
    marginLeft: 10,
  },
  rootResponsive: {
    marginLeft: "15px",
    marginTop: 20,
    textAlign: "left",
    marginBottom: "15px",
  },
  textInputResponsive: {
    height: "38px",
    width: "95%",
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    color: window.globalConfig.color.BLACK_PRIMARY,
    outline: "none !important",
    fontSize: "14px",
    "&:hover": {
      border: `1px solid ${window.globalConfig.color.BROWN}`,
    },
    paddingLeft: "10px",
  },
  marginResponsive: {
    marginRight: 15,
    marginLeft: 15,
    textAlign: "left",
  },
  labelResponsive: {
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: 14,
  },
  folioBlockResponsive: {
    float: "left",
    padding: 2,
    paddingRight: 15,
    paddingLeft: 15,
    backgroundColor: window.globalConfig.color.BORDER,
    borderRadius: 100,
    width: "100%",
    textTransform: "none !important",
  },
  amountInput: {
    // width: "80%",
    // [theme.breakpoints.between("1000", "1050")]: {
    //   width: "75%",
    // },
    // [theme.breakpoints.down("600")]: {
    //   width: "93%",
    // },
    // [theme.breakpoints.down("350")]: {
    //   width: "89%",
    // },
  },
  properInput: {
    opacity: 0.5,
    // marginTop: '-5px',
    // [theme.breakpoints.between("1250", "1300")]: {
    //   marginTop: "-12px",
    // },
    // [theme.breakpoints.between("1000", "1050")]: {
    //   marginTop: "1px",
    // },
    // [theme.breakpoints.down("600")]: {
    //   minWidth: "374px",
    // },
    // [theme.breakpoints.down("400")]: {
    //   minWidth: "300px",
    // },
  },
  typeDropDown: {
    marginRight: "0",
    // [theme.breakpoints.down("600")]: {
    //   marginRight: "29px",
    // },
  },
  topResponsive: {
    marginTop: "16px",
    [theme.breakpoints.down("600")]: {
      marginTop: "revert",
    },
  },
  folioDropdownAndUnits: {
    display: "grid", gridTemplateColumns: "30% 70%", gap: 10, alignItems: "center", width: "-webkit-fill-available"
    , [theme.breakpoints.down("700")]: {
      gridTemplateColumns: "100%",
      alignItems: "flex-start",

    }
  },
  totalUnits: {
    display: "flex", flexDirection: "row", gap: 10, alignItems: "center",
    [theme.breakpoints.down("700")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    }
  }
  //Ended
});
let status = "";
class SwitchDetails extends React.Component {
  constructor() {
    super();
    this.state = {
      showOption: false,
      setAnchorEl: null,
    };
  }
  onConfirmOrder = () => {
    this.props.confirmOrder(true);
  };
  componentDidMount() {
    scrollToTopPage();
  }
  handleClick = (event) => {
    this.setState({
      setAnchorEl: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      setAnchorEl: null,
    });
  };
  handlefolio = (val) => {
    this.props.onchangefolio(val);
    this.handleClose();
  };

  render() {
    const { classes } = this.props;
    const open = Boolean(this.state.setAnchorEl);
    return (
      <ErrorBoundary>
        <div container className={classes.desktopResponsive}>
          <Grid container className={classes.root}>
            <Grid
              item
              xs={12}
              style={{ float: "left", fontWeight: "bolder" }}
            >
              {labels.switch.SwitchDetails}
              <InformationTooltip title={labels.switch.SwitchDetails} Icon={<img src={Question} style={{ marginLeft: 7 }}></img>}></InformationTooltip>

            </Grid>
          </Grid>
          <Grid
            container
            direction={"row"}
            spacing={3}
            className={classes.container}
          >
            <Grid item xs={12} >

              <div className={classes.schemeDropDown}>
                <Dropdown
                  type={"normalwithsearch"}
                  placeholder={"Select Schemes"}
                  label={labels.switch.SchemesSwitchout}
                  // error={this.props.errortypemessage}
                  autoselect={false}
                  value={this.props.Switchout}
                  List={this.props.schemeList}
                  handleOnChange={this.props.Switchoutchange}
                />
              </div>
              <div className={classes.foliono} onWheel={() => this.handleClose}>
                <div className={classes.folioDropdownAndUnits}>
                  <Button
                    onClick={this.handleClick}
                    className={classes.folioBlock}
                  >
                    {labels.switch.Folio}: {this.props.folionumber == "" ? "NA" : this.props.folionumber}
                    <img src={ArrowIcon} style={{ marginLeft: "5%" }}></img>

                  </Button>
                  {this.props.UCC != "" ? <p style={{ fontSize: 14,textAlign:"left" }}>UCC :<span> {this.props.UCC || "NA"}</span></p> : <div style={{ fontSize: 12, color: colors.red,textAlign:"left" }}>BSE star UCC is not mapped to this Folio</div>}
                </div>
                <div className={classes.totalUnits}>
                  <p style={{ fontSize: 14, paddingRight: 14 }}>Total Amount :<span> {this.props.totalTable.length != 0 && this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber).length != 0 ? <>{NegativeFinding(this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber)[0].TotalAvailableAmount, "", "", "", "rupee")}</> : `${labels.currency.rupeesymbol}0`}</span></p>
                  <p style={{ fontSize: 14 }}>Total Units :<span> {this.props.totalTable.length != 0 && this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber).length != 0 ? <> {NegativeFinding(this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber)[0].TotalAvailableUnits, "", "", "", "number")}</> : `0`}</span></p>
                </div>
              </div>

              {/* <div className={classes.totalUnits}>
                    <p style={{ fontSize: 14, paddingRight: 14 }}>Total Amount :<span> {this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber).length != 0 ? <>{NegativeFinding(this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber)[0].TotalAvailableAmount, "", "", "", "rupee")}</> : `${labels.currency.rupeesymbol}0`}</span></p>
                    <p style={{ fontSize: 14 }}>Total Units :<span> {this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber).length != 0 ? <> {this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber)[0].TotalAvailableUnits}</> : `0`}</span></p>
                    <p style={{ fontSize: 14 }}>UCC :<span> {this.props.UCC}</span></p>

                  </div>
                </div>
                {this.props.UCC == "" && <div style={{ fontSize: 12, color: colors.red }}>BSE star UCC is not mapped to this Folio</div>}

              </div> */}
              <ClickAwayListener>
                <Menu
                  id="simple-menu"
                  anchorEl={this.state.setAnchorEl}
                  keepMounted
                  open={Boolean(this.state.setAnchorEl)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  onClose={this.handleClose}
                >
                  {this.props.FolioList.map((item) => {
                    return (
                      <MenuItem onClick={() => this.handlefolio(item)}>
                        {labels.switch.Folio} : {item.FolioNo}
                      </MenuItem>
                    );
                  })}
                </Menu>
              </ClickAwayListener>
            </Grid>
            {/* {console.log(this.props.AMCID, this.props.FullschemeList)} */}
            <Grid item xs={12}>
              <Autocomplete
                label="Scheme to Switch-in to"
                placeholder="Select Schemes"
                value={this.props.Switchin}
                default={true}
                options={this.props.toSchemeList}
                selectvalue={(val) => this.props.Switchinchange(val)}
              />
              <label className={classes.switchErrorMsg}>
                {this.props.errorswitchinmessage}
              </label>
            </Grid>
            <Grid item sm={6} xs={12} className={classes.typeDropDown}>
              <Dropdown
                type={"normalwithsearch"}
                label={labels.switch.Type}
                value={this.props.type}
                List={labels.TradingType}
                handleOnChange={this.props.typeChange}
              />

            </Grid>
            {this.props.type === "Amount (Rs)" && (
              <Grid item sm={6} xs={12}>
                <div className={classes.amountInput}>
                  <Textinput
                    page={"stp"}
                    label={labels.switch.Amount}
                    message={"Minimum Amount ₹1,000"}
                    error={this.props.erroramountmessage}
                    value={this.props.amount}
                    handleChange={this.props.Amountchange}
                    placeholder={"₹ Enter amount"}
                  />
                </div>
              </Grid>
            )}
            {this.props.type === "Custom No. of Units" && (
              <Grid item sm={6} xs={12}>
                <div className={classes.amountInput}>
                  <Textinput
                    page={"stp"}
                    label={labels.switch.Units}
                    message={"Minimum Units 10"}
                    error={this.props.errorunitsmessage}
                    value={this.props.units}
                    handleChange={this.props.unitschange}
                    placeholder={"Enter Units"}
                  />
                </div>{" "}
              </Grid>
            )}
            {this.props.type == "All Available No. of Units" &&
              this.props.totalTable.filter((item) => item.FolioNo == this.props.folionumber)[0] != "" && (
                <Grid item xs={6} className={classes.topResponsive}>
                  <div className={classes.properInput}>
                    <div className={classes.amountInput}>
                      <Textinput
                        error=""
                        disabled={true}
                        message={`Approx. Value ${NegativeFinding(this.props.totalTable.filter((item) => item.FolioNo == this.props.folionumber)[0]?.TotalAvailableAmount || 0, "", "", "", "rupee")}`}
                        value={
                          this.props.totalTable.filter((item) => item.FolioNo == this.props.folionumber)[0]?.TotalAvailableUnits || 0
                        }
                        page="normal"
                      />
                    </div>
                  </div>
                </Grid>
              )}
            {this.props.type == "All Exit Load Free Units" &&
              // this.props.totalTable.filter((item) => item.FolioNo == this.props.folionumber)[0] != "" && (
              <Grid item xs={6} className={classes.topResponsive}>
                <div className={classes.properInput}>
                  <div className={classes.amountInput}>
                    <Textinput
                      disabled={true}
                      value={
                        this.props.totalTable.filter((item) => item.FolioNo == this.props.folionumber)[0]?.TotalExitLoadUnits || 0

                      }
                      page="stp"
                    />
                  </div>
                </div>
              </Grid>
              // )
            }
            {this.props.type == "All Short Term Gain Units" &&

              <Grid item xs={6} className={classes.topResponsive}>
                <div className={classes.properInput}>
                  <div className={classes.amountInput}>
                    <Textinput
                      disabled={true}
                      value={
                        this.props.totalTable.filter((item) => item.FolioNo == this.props.folionumber)[0]?.TotalShortTermUnits || 0

                      }
                      page="stp"
                    />
                  </div>
                </div>
              </Grid>
            }
            {this.props.type == "All Long Term Gain Units" &&
              // this.props.totalTable.filter((item) => item.FolioNo == this.props.folionumber)[0] != "" && (
              <Grid item xs={6} className={classes.topResponsive}>
                <div className={classes.properInput}>
                  <div className={classes.amountInput}>
                    <Textinput
                      disabled={true}
                      value={
                        this.props.totalTable.filter((item) => item.FolioNo == this.props.folionumber)[0]?.TotalLongTermUnits || 0

                      }
                      page="normal"
                    />
                  </div>
                </div>
              </Grid>
              // )
            }
            {this.props.type == "All Available No. of Units" &&
              this.props.totalTable != "" && (
                // <div>
                <div
                  className={[classes.box, classes.desktopResponsive].join(
                    " "
                  )}
                >
                  <p className={classes.boxMain}>
                    {`Approximate exits load for this transaction: ${this.props.totalTable.filter((item) => item.FolioNo == this.props.folionumber)[0]?.TotalExitLoadUnits || 0
                      }*`}
                  </p>
                  <p className={classes.boxSub}>
                    {labels.Redemption.editLoad}
                  </p>
                </div>
                // </div>
              )}
            {this.props.type == "All Exit Load Free Units" &&
              this.props.totalTable != "" && (
                // <div>
                <div
                  style={{
                    fontSize: "12px",
                    paddingLeft: 20,
                    paddingRight: 20,
                    paddingTop: 10,
                    lineHeight: 1.5,
                    color: window.globalConfig.color.LABLE_GRAY,
                  }}
                >
                  {` Based on NAV declared on as on ${this.props.data?.navdate}- Value at the time of transaction processing would vary`}
                  <br></br>
                  {labels.Redemption.nextExitLoad}
                </div>
                // </div>
              )}
          </Grid>

          <div className={classes.divLine1}></div>
          <div className={classes.placeOrder}>
            <Buttons
              name={"Next"}
              page={"primary"}
              disabled={this.props.viewloader || this.props.UCC == "" ? true : false}
              loader={this.props.viewloader}
              onClick={() => this.props.onPlaceorder(true)}
              className={classes.btnApply}
            />
          </div>
        </div>

      </ErrorBoundary>
    );
  }
}
const mapStateToProps = (state) => {
  //
  return {
    logindetails: state.Login.logindetails,
    typelist: state.Filter.Redemptionlist,
  };
};
export default withStyles(styles)(
  withRouter(connect(mapStateToProps, null)(SwitchDetails))
);