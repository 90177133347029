import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from "../../utils/constants/labels"
import Networking from '../../utils/api/apiaccess';
function getBillingDetails(data) {
    return Networking.PostApi(ApiValues.BillingDetails, data, "").then((result) => {
        return result
    }).catch((err) => {
        return { status: "F", messaage: labels.messages.apierror }
    })

}
export default getBillingDetails