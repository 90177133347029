import * as types from '../../constants/actionsType';
import getAIFandPMSList from '../../../api/aifandpms/aifandpms_list'
export const Listing = (data, flag) => {
    if (flag == 'AIF') {
        return {
            type: types.AIF_LIST,
            payload: data
        }
     
    } else {
        return {
            type: types.PMS_LIST,
            payload: data
        }
    }
}
export const deleteListing = (data, flag) => {
    if (flag == "AIF") {
        return {
            type: types.DELETE_AIF_LIST,
            payload: data
        }
    } else {
        return {
            type: types.DELETE_PMS_LIST,
            payload: data
        }
    }

}
export const addUpdateDeleteTransaction = (data, flag,page) => {
    if (page == "AIF") {
        return {
            type: types.ADD_UPDATE_DELETE_TRANSACTION,
            data, flag
        }
    } else {
        return {
            type: types.ADD_UPDATE_DELETE_PMS_TRANSACTION,
            data, flag
        }
    }


}
export const addUpdateDeleteCommitmentInstalment = (data, flag,page) => {
 
        return {
            type: types.ADD_UPDATE_DELETE_PMS_TRANSACTION,
            data, flag
        }

}
export const updateAsset = (data, flag) => {
    if (flag == "AIF") {
        return {
            type: types.UPDATE_AIF_LIST,
            payload: data
        }

    } else {
        return {
            type: types.UPDATE_PMS_LIST,
            payload: data
        }

    }

}
// export const addValuation = (data) => {

//     return {
//         type: types.ADD_VALUATION_LIST,
//         payload: data
//     }

// }
export function getAIFandPMSDetails(ID, ClientID, AssetClassCode, ProductID) {
    return (dispatch) => {
        getAIFandPMSDetailsAction(dispatch, ID, ClientID, AssetClassCode, ProductID);
    };
}
function getAIFandPMSDetailsAction(dispatch, ID, ClientID, AssetClassCode, ProductID) {
    
    //mf loading
    if (AssetClassCode === 27) {
        dispatch({
            type: types.EMPTY_AIF_LIST,
            payload: [],
        });
        dispatch({
            type: types.LOADER_AIF_LIST,
            payload: true,
        });
    }
    else {
        dispatch({
            type: types.EMPTY_PMS_LIST,
            payload: [],
        });
        dispatch({
            type: types.LOADER_PMS_LIST,
            payload: true,
        });
    }

    getAIFandPMSList(ID, ClientID, AssetClassCode, ProductID)
        .then((data) => {
            // 
            if (AssetClassCode === 27) {
                dispatch({
                    type: types.EMPTY_AIF_LIST,
                    payload: [],
                });
                dispatch({
                    type:types.LOADER_AIF_LIST,
                    payload:false
                })

            }else {
                dispatch({
                    type: types.EMPTY_PMS_LIST,
                    payload: [],
                });
                dispatch({
                    type:types.LOADER_PMS_LIST,
                    payload:false
                })
            }

            if (data.status == "S") {
                // mf report

                if (AssetClassCode === 27) {
                    dispatch({
                        type: types.AIF_LIST,
                        payload: data.table1,
                    });

                }
                else {
                    dispatch({
                        type: types.PMS_LIST,
                        payload: data.table1,
                    });

                }
            } else {
                if (AssetClassCode === 27) {
                    dispatch({
                        type: types.EMPTY_AIF_LIST,
                        payload: [],
                    });
                    dispatch({
                        type:types.LOADER_AIF_LIST,
                        payload:false
                    })
                }

                else {
                    dispatch({
                        type: types.EMPTY_PMS_LIST,
                        payload: [],
                    });
                    dispatch({
                        type:types.LOADER_PMS_LIST,
                        payload:false
                    })
                }
            }
        })
        .catch((error) => {
            // Mf empty

            if (AssetClassCode === 27) {
                dispatch({
                    type: types.EMPTY_AIF_LIST,
                    payload: [],
                });
                dispatch({
                    type:types.LOADER_AIF_LIST,
                    payload:false
                })
            }

            else {
                dispatch({
                    type: types.EMPTY_PMS_LIST,
                    payload: [],
                });
                dispatch({
                    type:types.LOADER_PMS_LIST,
                    payload:false
                })
            }
        })
}