import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
function GetAssetName(data) {
    return Networking.PostApi(ApiValues.AssetName, data).then(result => {
        return result
    }).catch(function (error) {
        return { "status": "F", "message": labels.messages.apierror }
    });
}
export default GetAssetName