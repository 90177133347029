import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
function getBulionListing(id,clientID) {
   let data={
        id:id,
        clientID:clientID,
    }
    return Networking.PostApi(ApiValues.bullionListing,data).then(result => {
        return result
    })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });
}
export default getBulionListing