import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { Chip } from "@material-ui/core";
import { Redirect } from "react-router";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Dropdown from "../../components/dropdown/dropdownschemes";
import { withStyles } from "@material-ui/core/styles";
import hoverFilterIcons from "../../utils/assets/img23.png";
import CommonCheckbox from "../../components/checkbox/checkbox";
import addtocart from "../../api/cart/addtocartapi";
import { AddToCart } from "../../redux/actions/cart/addtocartaction";
import {
  GetAllCart,
  GetAllCartValues,
} from "../../redux/actions/cart/getallcartaction";
// import Button from '../../components/custombutton/button';
import colors from "../../utils/colors/colors";
import "../../utils/stylesheets/common/global/schemes/schemefilter.css";
import "../../utils/stylesheets/common/global/x-ray-header/x-ray-header.css";
// import {
//   getAllDropdownDetails,
// } from "../../redux/actions/dropdown/dropdownaction";
import { getTransactionDetailsListing } from "../../redux/actions/transactiondetails/transactiondetailsaction";
import {
  SchemeFilters,
  Filterclick,
  TopTen,
} from "../../redux/actions/dropdown/dropdownaction";
import { bindActionCreators } from "redux";
import Popup from "../../components/popup/deletepopup";
import ResetIcon from "../../utils/assets/images/reset.png";
import Label from "../../../src/utils/constants/labels";
import labels from "../../utils/constants/labels";
import { withRouter } from "react-router";
import { SchemeDetails } from "../../redux/actions/schemes/schemeaction";
import {
  NegativeFinding,
  SchemeCodeFinding,
  replaceNull,
  scrollToTopPage,
  uniqueId,
} from "../../utils/commonfunction";
import { NetworkErrorSchemeDetails } from "../../redux/actions/schemes/schemeaction";
import CommonSnackbar from "../../components/snackbar/snackbar";
import FilterImage from "../../utils/assets/images/filter.png";
import SelectedFilterImage from "../../utils/assets/selectedfilter.png";
import hoverSelectedFilterImage2 from "../../utils/assets/img23-Copy.png";
import Button from "../../components/custombutton/button";
import {
  amcOnChange,
  AvailableinvestChange,
} from "../../redux/actions/filter/reportsfilter";
//import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from "@material-ui/core/CircularProgress";
import getPortfolioxraySchemeDetails from "../../api/portfolioxrayschemedetail/portfolioxrayschemedetail";
import FolioPopup from "../../components/tradingpopup/foliopopup";
import CommonDialogBox from "../../components/dialog/dialog";
import Filter_screen from "../../components/template/filter_screen";
import getKYCDetails from "../../api/kyc/kycapi";
import scrolltotop from "../../components/scrolltotop/scrolltotop";
const styles = (theme) => ({
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "5% 32% 8% 8% 8% 15% 10% 10%",
    gap: 5,
    alignItems: "center",
    cursor: "pointer",
    boxShadow: "0 0 5px 0 rgb(19 89 161 / 6%)",
    border: "solid 1px #ecf3fc",
    marginBottom: 5,
    borderRadius: 9,
    padding: 6,
    "& .secondGrid": {
      alignItems: "flex-start",
      textAlign: "left",
      gap: 5
    },
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(4,1fr)",
      gridTemplateAreas: `"firstGrid secondGrid secondGrid secondGrid"
      "thirdGrid fourthGrid fifthGrid sixthGrid"
      "seventhGrid . . eighthGrid"
                              `,
      "& .firstGrid": {
        alignItems: "center"
      },
      "& .secondGrid": {
        textAlign: "left"
      },
      gap: 2
    }

  },
  columnDirection: {
    display: "flex",
    flexDirection: "column"
  },
  rowDirection: {
    display: "flex",
    gap: 5,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row"

  },
  firstGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "firstGrid"
    }
  },
  secondGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "secondGrid"
    }
  },
  thirdGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "thirdGrid"
    }
  },
  fourthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "fourthGrid"
    }
  },
  fifthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "fifthGrid"
    }
  },
  sixthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "sixthGrid"
    }
  },
  seventhGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "seventhGrid"
    }
  },
  eighthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "eighthGrid"
    }
  },
  label: {
    color: colors.LABLE_GRAY,
    fontSize: 12
  },
  txtHeader: {
    padding: 15,
  },
  clearIcon: {
    float: 'right',
    cursor: 'pointer'
  },
  root: {
    "& .MuiDialogContent-root": {
      padding: "0px !important"
    },
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "none !important",
      width: "25% !important",
      overflow: "hidden"
    },
    "& .MuiDialogContent-root": {
      overflowY: "hidden",
      padding: "0px !important"
    }
  },
  outlineButton: {
    width: 109, cursor: "pointer", height: 39, alignItems: "center", borderRadius: 5, justifyContent: "center", border: `1px solid ${window.globalConfig.color.red}`, display: "flex", flexDirection: "column", color: window.globalConfig.color.red, fontWeight: 'bold'
  },
  // searchIcon: {
  //   color: window.globalConfig.color.BROWN,
  //   marginTop: "34px",
  //   height: "37px",
  // },

  resetIcon: {
    cursor: "pointer",
    height: 20,
    width: 16,
  },
  inputRoot: {
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontSize: 12,
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: window.globalConfig.color.ZERO_BACKGROUND,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: window.globalConfig.color.BROWN,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: window.globalConfig.color.BROWN,
    },
  },


  badges: {
    paddingTop: 10,
  },
  roots: {
    marginTop: "87px",
    // marginLeft: 20,
    // marginRight: 20,
  },
  cart: {
    fontSize: 12,
    cursor: "pointer",
    color: window.globalConfig.color.PRIMARY,
  },

  // btnClear: {

  //   padding: 0,
  //   lineHeight: "36px",

  //   color: window.globalConfig.color.BLACK_PRIMARY,
  //   fontWeight: 550,
  //   fontSize: "13px",
  //   width: "100px",
  //   marginLeft: "5px",
  //   // borderRadius: "5px",
  //   // border: "1px solid transparent",
  //   "&:hover": {
  //     color: window.globalConfig.color.WHITE,
  //   },
  //   "&:focus": {
  //     borderColor: "rgb(175,119,45)",
  //   },
  // },
  // btnApply: {
  //   marginLeft: "7px",
  //   padding: 0,
  //   lineHeight: "36px",
  //   color: window.globalConfig.color.WHITE,
  //   fontWeight: 600,
  //   fontSize: "14px",
  //   lineHeight: "36px",
  //   width: "100px",
  //   // borderRadius: "5px",
  //   // border: "1px solid transparent",
  //   "&:hover": {
  //     color: window.globalConfig.color.WHITE,
  //   },
  // },

  // tr: {
  //   boxShadow: "0px 1px 2px 1px #e0e5eb",
  //   // border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
  //   // padding: "10px 0",
  //   borderRadius: "8px",
  //   padding: "0px 4px",
  //   cursor: "pointer",
  //   alignItems: "center",
  //   display: "grid",
  //   gridTemplateColumns: "33% 10% 10% 10% 10% 20%",
  //   gap: "10px",
  //   [theme.breakpoints.down("md")]: {
  //     gridTemplateColumns: "33% 7% 7% 7% 11% 20%",
  //   },
  // },
  // rupees: {
  //   margin: "0px !important",
  //   fontSize: "17px !important",
  //   fontWeight: "normal !important",
  //   color: `${window.globalConfig.color.BLACK_PRIMARY} !important`,
  //   padding: "1px 0px",
  // },
  labelStyle: {
    margin: "0px !important",
    fontSize: "12px !important",
    fontWeight: "normal !important",
    color: `${window.globalConfig.color.LABLE_GRAY} !important`,
    padding: "1px 0px",
  },
  firstrow: {
    margin: "0px !important",
    fontSize: "16px !important",
    padding: "4px",
    fontWeight: "500"
  },
  textFieldCursor: {
    caretColor: window.globalConfig.color.BROWN,
  },
  // titlesecondrowheading: {
  //   margin: "0px !important",
  //   fontSize: "11px !important",
  //   fontWeight: "normal !important",
  //   color: `${window.globalConfig.color.LABLE_GRAY} !important`,
  // },
  btnParent: {
    // padding: "15px 10px 10px 10px",
    textAlign: "center",
    // [theme.breakpoints.down("900px")]: {
    //   padding: "10px 10px 10px 0px",
    // },
  },
  cartParent: {
    textAlign: "center",
    paddingBottom: 20,
  },
  titlesecondrow: {
    margin: "0px !important",
    fontSize: "12px !important",
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontWeight: 400,
  },
  vertical: {
    borderLeft: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    margin: "0px 5px",
    height: "9px",
  },
  schemeName: {
    fontSize: "16px",
  },



  header: {
    backgroundColor: window.globalConfig.color.PRIMARY,
    color: window.globalConfig.color.WHITE,
    height: 50,
    fontWeight: 500,
    position: "sticky",
    top: "0px"
  },

  // Added by Celsia on 24 May 2021 for Mobile Responsive   Started
  desktopResponsive: {
    display: "block !important",

    [theme.breakpoints.down("900")]: {
      display: "none !important",
    },
  },

  body: {
    margin: 20,
    textAlign: "center",
  },
  sipContainer: {
    maxheight: 470,
    // width: 360,
    //  [theme.breakpoints.down("920")]: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
    //},

  },
  txtBody: {
    padding: 10,
    fontSize: 13,
    color: window.globalConfig.color.BLACK_PRIMARY,
  },
  onetimeContainer: {
    maxheight: 400,
    width: 350,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.between("320", "374")]: {
      width: 240,
    },
    [theme.breakpoints.between("375", "424")]: {
      width: 300,
    },
  },

  footer: {
    // padding: "14px 20px 20px 20px",
    width: "80%",
    marginTop: -15,
    paddingLeft: 10,
    paddingRight: 10
  },

  trResponsive: {
    height: "auto",
    boxShadow: "0px 1px 2px 1px #e0e5eb",
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    padding: "10px 0",
    borderRadius: "8px",
    fontSize: "13px",
    fontWeight: 400,
    width: "100%",
  },
  flexResponsive: {
    display: "flex",
    flexDirection: "row",
  },
  btnApplyResponsive: {
    background: window.globalConfig.color.BROWN,
    lineHeight: "36px",
    color: window.globalConfig.color.WHITE,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    width: "120px",
    borderRadius: "5px",
    padding: 0,
    border: "1px solid transparent",
    "&:hover": {
      background: window.globalConfig.color.PRIMARY_LIGHT,
      color: window.globalConfig.color.WHITE,
    },
  },
  btnClearResponsive: {
    background: window.globalConfig.color.ZERO_BACKGROUND,
    lineHeight: "36px",
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontWeight: 600,
    padding: 0,
    fontSize: "14px",
    lineHeight: "36px",
    width: "120px",
    marginLeft: "5px",
    borderRadius: "5px",
    border: "1px solid transparent",
    "&:hover": {
      background: window.globalConfig.color.PRIMARY_LIGHT,
      color: window.globalConfig.color.WHITE,
    },
  },
  summary: {
    height: 110,
    backgroundColor: window.globalConfig.color.PRIMARY,
    paddingTop: 10
    // paddingTop: "33px",
  },
  filterImagestyle: {
    [theme.breakpoints.up("600")]: {
      width: 35,
      height: 37,
      left: "3.5vh",
      marginLeft: "-11px",
      top: 13,
      background: `url(${FilterImage}) center center no-repeat`,
      position: "absolute",
      cursor: "pointer",
      "&:hover": {
        background: `url(${hoverFilterIcons}) center center no-repeat`,
      },
    },
    [theme.breakpoints.down("600")]: {
      position: "unset",
      paddingTop: "27px",
      width: 35,
      height: 37,
      left: "18px",
      marginLeft: "68px",
      top: 21,
      marginLeft: 11,
      background: `url(${FilterImage}) center center no-repeat`,
    },
  },
  selectedFilterImagestyle: {
    [theme.breakpoints.up("600")]: {
      width: 35,
      height: 37,
      left: "3.5vh",
      marginLeft: "-11px",
      top: 13,
      background: `url(${SelectedFilterImage}) center center no-repeat`,
      position: "absolute",
      cursor: "pointer",
      "&:hover": {
        background: `url(${hoverSelectedFilterImage2}) center center no-repeat`,
      },
    },
    [theme.breakpoints.down("600")]: {
      position: "unset",
      paddingTop: "27px",
      width: 35,
      height: 37,
      left: "18px",
      marginLeft: "68px",
      top: 21,
      marginLeft: 11,
      background: `url(${SelectedFilterImage}) center center no-repeat`,
    },
  },
  container: {
    // maxWidth: "1070px",
    width: "79%",
    padding: "0px 20px",
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down(600)]: {
      width: "89%",
    },
  },

  clear: {
    clear: "both",
    height: "0px",
    display: "block",
  },
  sumBox: {
    // marginBottom: "50px",
    boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
    backgroundColor: window.globalConfig.color.WHITE,
    borderRadius: "10px",
    height: 97,
    display: "grid",
    gridTemplateColumns: "23% 20% 20% 20% 9% 8%",
    marginTop: "16px",
    [theme.breakpoints.down("sm")]: {
      height: "490px",
      boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
      display: "block",
      textAlign: "center",
    },
  },
  // sumBoxLeft: {
  //   maxWidth: "41%",
  //   width: "50%",
  //   position: "relative",
  //   float: "left",
  //   height: 97,
  //   borderTopLeftRadius: "10px",
  //   borderBottomLeftRadius: "10px",
  //   [theme.breakpoints.up(900)]: {
  //     borderRight: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
  //   },

  //   [theme.breakpoints.down(900)]: {
  //     width: "100%",
  //     maxWidth: "100%",
  //     borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
  //   },
  // },
  searchBox: {
    padding: "20px 0 10px 6px",
    verticalAlign: "baseline",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
    [theme.breakpoints.down(600)]: {
      borderTopLeftRadius: "10px",
      borderTopRightRadius: "10px",
      borderBottomLeftRadius: "0px",
    },
  },
  formControl: {
    display: "block",
    width: "75%",
    height: `calc(${"2.25rem"} + ${"2px"})`,
    padding: ".375rem .75rem",
    fontSize: "14px",
    lineHeight: 1.5,
    color: window.globalConfig.color.BLACK_PRIMARY,
    backgroundColor: window.globalConfig.color.WHITE,
    backgroundClip: "padding-box",
    border: 0,
    borderRadius: 0,
    transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
    fontWeight: 500,
    overflow: "visible",
    outline: "none",
    [theme.breakpoints.down(1344)]: {
      width: "85%",
    },
  },
  // sumBoxFil1: {
  //   width: "21%",
  //   float: "left",
  //   padding: "6px",
  //   paddingRight: "0px",
  //   borderRight: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
  //   minHeight: "86px",
  //   "&:hover": {
  //     backgroundColor: window.globalConfig.color.RosyBrown,
  //   },
  //   [theme.breakpoints.down(1344)]: {
  //     width: "19%",
  //   },
  //   [theme.breakpoints.down(900)]: {
  //     width: "98%",
  //     borderRight: `none`,
  //   },

  //   // height: 58
  // },
  // sumBoxFil2: {
  //   float: "left",
  //   width: "7%",
  //   height: 97,
  //   [theme.breakpoints.down(900)]: {
  //     width: "49%",
  //     display: "flex",
  //     justifyContent: "center",
  //     alignItems: "center",
  //   },
  // },
  labelStyle: {
    display: "block",
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: "14px",
    marginLeft: 12,
    padding: 0,
    border: 0,
    textAlign: "left",
  },
  // sumBoxFil3: {
  //   border: `1px solid ${window.globalConfig.color.WHITE}`,
  //   borderLeft: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
  //   width: "8%",
  //   float: "right",
  //   minHeight: "97px",
  //   borderRadius: "0 10px 10px 0",
  //   background: `${window.globalConfig.color.RosyBrown} url(${SearchIcon}) center center no-repeat`,
  //   [theme.breakpoints.between("1250", "1300")]: {
  //     width: "10%",
  //   },
  //   [theme.breakpoints.down(900)]: {
  //     width: "50%",
  //     minHeight: "86px",
  //     display: "flex",

  //     borderRadius: 0
  //   },
  // },
  mobileResponsiveDiv: {
    [theme.breakpoints.down(900)]: {
      height: "74vh",
      boxShadow: "0 0 20px 0 rgba(19, 89, 161, 0.1)",
    },
  },
  clearSelectfromDiv: {
    background: `url(${ResetIcon})  no-repeat`,
    width: "18px",
    height: "18px",
    zIndex: 0,
    position: "absolute",
    right: 10,
    // display: "none",
    top: "55px",
    [theme.breakpoints.down("900")]: {
      width: "37px",
    },
  },
  ButtonContainer: {
    display: "flex",
    // width: "22%",
    [theme.breakpoints.down("md")]: {
      margin: "13% 0%",
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
    },
    [theme.breakpoints.down("sm")]: {
      width: "22%",
      marginTop: "revert",
      gridTemplateColumns: "revert",
    },
  },

  sortBy: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "6%",
      display: "flex",
    },
  },

  flexDirectionSchemes: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("900")]: {
      marginTop: "5%",
    },
  },
  openImageblock: {
    display: 'inline-block',
  },
});
let folio = [];
function filterWorker() {
  self.addEventListener('message', (event) => {
    // if (event.data && event.data.data && event.data.conditions) {
    const { data, conditions } = event.data;
    let filteredData = data
    const conditionFunctions = conditions?.map((conditionStr) => eval(`(${conditionStr})`));
    filteredData = filteredData?.filter((item) => {
      return conditionFunctions.every((condition) => condition(item));
    });
    // const filteredData = data.filter((item) => {
    //   return conditions.every((condition) => condition(item));
    // });
    self.postMessage(filteredData);
    // }
  });
}
const worker = new Worker(URL.createObjectURL(new Blob([`(${filterWorker})()`])));
class Schemes extends PureComponent {
  isComponentMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      page: "",
      schemeCollection: [],
      category: false,
      risk: false,
      filter: false,
      categoryFilterlist: [],
      categorySelectedList: [],
      openkyc: false,
      riskSelectedList: [],
      searchContent: "",
      finalSearch: false,
      filterSearch: false,
      sortByList: [],
      openPopup: false,
      amount: "",
      cartData: [],
      openAlert: false,
      openAccount: false,
      alertMessage: "Add to cart Successfull",
      alertColor: "success",
      validationMessage: "",
      checked: false,
      openPopup: false,
      selectedAmcList: [],
      selectedinvestList: [],
      filter: [],
      RiskFilterlist: [],
      typeFilterlist: [],
      color: window.globalConfig.color.WHITE,
      sipPopup: false,
      installments: 180,
      frequency: "MONTHLY",
      selectedDate: "",
      loaderSIP: false,
      loaderOneTime: false,
      loaderOneTimeCart: false,
      loaderSIPCart: false,
      openFolioPopup: false,
      folioNumberList: [],
      conditionList: [],
      currentDateBackUp: "",
      startDate: "",
      folioLoader: false,
      dropDownValue: 1, selectedTypeList: [],
      AmcDataList: [],
      clearShow: true
    };
  }

  async componentDidMount() {

    // Handle messages from the web worker
    worker.addEventListener('message', (event) => {
      this.setState({ schemeCollection: event.data, finalSearch: true })
      // setFilteredData(event.data);
    });
    scrollToTopPage()
    let dropdownList = labels.Date
    this.setState({ startDate: dropdownList[0]?.label, AmcDataList: this.props.AmcDataList.sort((a, b) => a.label.localeCompare(b.label)) });
    this.dropdowndata(
      labels.Date.filter((item) => item.id == dropdownList[0].id)
    );
    var filterlist = replaceNull(this.props.listdata, "NA") != "NA" ? this.props.listdata?.filter(item => item.IvstT === "Growth").slice().sort((a, b) => a.Ranking - b.Ranking) : []

    var listFilter = filterlist.length != 0 ? filterlist.slice(0, 10) : []

    await this.setState({
      schemeCollection: listFilter,
      // transactionList: [
      //   { label: "One Time", value: 1 },
      //   { label: "SIP(Monthly)", value: 2 },
      // ],
      categoryFilterlist: this.props.categoryFilterlist.sort((a, b) => a.label.localeCompare(b.label)),
      RiskFilterlist: this.props.RiskFilterlist,
      selectedTypeList: ["growth"],
      typeFilterlist: [{ label: "All", value: 3, isChecked: false }, { label: "Growth", value: 1, isChecked: true }, { label: "Dividend", value: 2, isChecked: false }]
    });
    // this.props.amcOnChange(
    //   this.props.AmcFullList,
    //   this.props.AmcFullList,
    //   "create"
    // );
    // this.props.AvailableinvestChange(
    //   labels.transactionType,
    //   labels.transactionType,
    //   "create"
    // );
    // this.props.SetPurchaseResponseEmpty();
    window.addEventListener("resize", this.resize.bind(this));
    // document.getElementById("scroller").addEventListener("scroll", () => this.handleScroll());
    // this.resize();
    this.setState({
      filter: [
        { header: "Search", type: { name: "textbox", category: "" }, onChange: this.onSearchClick },
        { header: "Category", type: { name: "dropdown", category: "withoutsearchable" }, List: this.state.categoryFilterlist, onChange: this.onCategoryChange },
        { header: "Risk Type", List: this.state.RiskFilterlist, type: { name: "dropdown", category: "withoutsearchable" }, onChange: this.onRiskChange },
        { header: "Plan Type", List: this.state.typeFilterlist, type: { name: "dropdown", category: "withoutsearchable" }, onChange: this.onTypeChange, onClear: this.handleOnClearChange },
        { header: "filter", type: { name: "popup", category: "" }, },
        { header: "", type: { name: "Search", category: "" } }],
      sortByList: [{ name: "A-Z", key: "SN", isInversible: true }, { name: "AUM", key: "AUM", isInversible: false }]
    })
  }
  componentDidUpdate(prev) {
    const {
      listdata,
      categoryFilterlist,
      RiskFilterlist, AmcDataList } = this.props;
    if (prev.listdata != listdata) {
      this.setState({ schemeCollection: listdata });
    }
    if (prev.AmcDataList != AmcDataList) {
      this.setState({ AmcDataList: AmcDataList.sort((a, b) => a.label.localeCompare(b.label)) })
    }
    if (this.state.categoryFilterlist.length != categoryFilterlist.length) {
      this.setState({ categoryFilterlist: categoryFilterlist });
    }
    if (this.state.RiskFilterlist.length != RiskFilterlist.length) {
      this.setState({ RiskFilterlist: RiskFilterlist });
    }
  }
  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  };
  mappingDetailsErrorClose = () => {
    this.sippopupSideClose();
    this.popupSideClose()
    this.setState({
      sipPopup: false,
      amount: "",
      validationMessage: "",
      validation: false,
      checked: false,
      loaderOneTimeCart: false,
      loaderSIPCart: false,
      loaderOneTime: false,
      loaderSip: false,
      openPopup: false,
    });

  };
  oneTimePopup = (value) => {

    this.setState({ cartData: [] });
    this.setState({ loaderOne: false, amount: "" });
    // this.setState({ switch: false })
    if (this.props.logindetails.L4_AccountTypeID == 2 && !this.props.userDetails[0].KYCValid && this.props.companyDetails[0].IsKYCAllowed) {
      this.setState({ openkyc: true });
    } else {
      if (
        this.props.uccList.length != 0 &&
        this.props.uccList.filter((item) => item.ClientCode == "NA")
          .length != this.props.uccList.length
      ) {
        this.setState({ openPopup: true, popName: "onetime", cartData: value });
      } else {
        this.setState({
          openAccount: true,
          alertMessage: labels.messages.uccnotavailble,
          alertColor: "warning",
        });
      }
    }
  };
  //prem and Denis Changes Rupee symbol
  getAmount = (event) => {
    const regex = new RegExp("^[₹0-9]+$");
    var val = event.target.value.replaceAll(",", "");
    var val1 = val.replaceAll("₹", "");
    if (val1.trim() == "") {

      this.setState({ amount: "", validation: false });
    } else {
      if (!regex.test(val1.trim()) || val1.length > 16) {
        event.preventDefault();
        // this.setState({ validation: true })
      } else {
        this.setState({ validationMessage: "" });
        this.setState({ amount: NegativeFinding(val1, "", "", "", "rupee"), validation: false })

      }
    }
  };
  sipPopup = (value) => {

    this.setState({ cartData: [] });
    this.setState({ validation: false, loaderSip: false, amount: "" });
    if (this.props.logindetails.L4_AccountTypeID == 2 && !this.props.userDetails[0].KYCValid && this.props.companyDetails[0].IsKYCAllowed) {
      this.setState({ openkyc: true });
    } else {
      if (
        this.props.uccList.length != 0 &&
        this.props.uccList.filter((item) => item.ClientCode == "NA")
          .length != this.props.uccList.length
      ) {
        let currentList = labels.Date
        this.setState({
          sipPopup: true,
          popName: "sip",
          cartData: value,
          startDate: currentList[0].label,
        });
      } else {
        this.setState({
          openAccount: true,
          alertMessage: labels.messages.uccnotavailble,
          alertColor: "warning",
        });
      }
    }
  };
  popupClose = (value) => {
    this.setState({
      openPopup: false,
      sipPopup: false,
      amount: "",
      openAlert: true,
      alertMessage:
        value == "failed"
          ? "Already in the cart"
          : "Added to cart Successfully",
      alertColor: value == "failed" ? "warning" : "success",
      validation: false,
      // dropdownValidation: false,
      checked: false,
      loaderOneTimeCart: false,
      loaderSIPCart: false,
      loaderOneTime: false,
      loaderSip: false,
    });
  };
  popupSideClose = () => {
    this.setState({
      openPopup: false,
      amount: "",
      validationMessage: "",
      validation: false,
      // dropdownValidation: false,
      //openAlert: false,
      checked: false,
      sipPopup: false,
      loaderOneTimeCart: false,
      loaderSIPCart: false,
      loaderOneTime: false,
      loaderSip: false,
    });
  };
  sippopupSideClose = () => {
    this.setState({
      sipPopup: false,
      amount: "",
      validationMessage: "",
      validation: false,
      // dropdownValidation: false,
      //openAlert: false,
      checked: false,
      loaderOneTimeCart: false,
      loaderSIPCart: false,
      loaderOneTime: false,
      loaderSip: false,
    });
  };
  routeChange = (value) => {
    if (this.props.logindetails.L4_AccountTypeID == 2 && !this.props.userDetails[0].KYCValid && this.props.companyDetails[0].IsKYCAllowed) {
      this.setState({ openkyc: true });
    } else {
      // if (
      //   this.props.uccList.length != 0 &&
      //   this.props.uccList.filter((item) => item.ClientCode == "NA")
      //     .length != this.props.uccList.length
      // ) {

      this.props.history.push({
        pathname: Label.Path.TradingDetail,
        state: { data: value },
      });
      // } else {
      //   this.setState({
      //     openAccount: true,
      //     alertMessage: labels.messages.uccnotavailble,
      //     alertColor: "warning",
      //   });
      // }
    }
  };
  addvalue = (value) => {
    this.setState({ validation: false, validationMessage: "" });
    if (this.state.amount == "") {
      // value = value.replace("₹", "").replace(/,/g, "")
      value = Number(0) + value;
      this.setState({ amount: NegativeFinding(value, "", "", "", "rupee"), validation: false });
    } else {
      // value = value.replace("₹", "").replace(/,/g, "")
      let amounts = this.state.amount;
      value = Number(amounts.replace("₹", "").replace(/,/g, "")) + value;
      this.setState({ amount: NegativeFinding(value, "", "", "", "rupee"), validation: false });
      // this.setState({ amount: "₹" + (Number(this.state.amount.split("₹")[1]) + value) });
    }
  };
  amountValidation = () => {
    if (this.state.amount == "") {
      this.setState({
        validation: true,
        validationMessage: Label.investmentPopup.amountValidation,
      });
    } else {
      this.setState({ validation: false });
    }
  };
  dropdownValidation = (value) => {
    if (value === undefined || value == "") {
      this.setState({
        validation: true,
        validationMessage: Label.sipPopup.sipValidation,
      });
    } else {
      this.setState({ validation: false });
    }
  };
  handleCheckbox = () => {
    if (this.state.selectedDate == "") {
      this.setState({ validationMessage: "Please select SIP start date" });
    } else {
      this.setState({ checked: !this.state.checked, validationMessage: "" });

      this.dateSetting(
        this.state.dropDownValue
        ,
        !this.state.checked
      );
    }
  };
  onCategoryChange = async (item) => {
    this.setState({
      categorySelectedList: item.map((val) => {
        return val.label.toLowerCase()
      }),
      clearShow: false
    });

  };
  onRiskChange = async (item) => {
    this.setState({
      riskSelectedList: item.map((val) => {
        return val.label.toLowerCase()
      }),
      clearShow: false
    });
  };
  onTypeChange = async (item) => {
    await this.setState({
      selectedTypeList: item.map((val) => {
        return val.label == "All" ? `Dividend,Growth`.toLowerCase() : val.label.toLowerCase()
      }),
      clearShow: false
    })
    this.filterSubmit()

  }
  onSearchClick = async (event) => {
    this.setState({
      searchContent: event.target.value, clearShow: false
    }, () => {
      this.filterSubmit(this.state.searchContent)
    })

  }
  filterSubmit = (searchContent) => {
    let list = this.props.listdata
    let conditions = []
    if (this.state.selectedTypeList.length != 0 && !this.state.clearShow) {
      conditions.push(`item =>${JSON.stringify(this.state.selectedTypeList)}.includes(item.IvstT.toLowerCase())`)
    }
    if (searchContent != undefined) {
      if (searchContent != undefined && searchContent != "" && searchContent != null && searchContent.length != undefined) {
        conditions.push(`item =>item.SN.toLowerCase().includes(${JSON.stringify(searchContent)}.toLowerCase())`)
      }
      else if (searchContent.length == 0) {
        conditions = []
      }
    }
    else if (this.state.searchContent.length != 0) {
      conditions.push(`item =>item.SN.toLowerCase().includes(${JSON.stringify(this.state.searchContent)}.toLowerCase())`)
    }

    if (this.state.riskSelectedList.length != 0) {
      conditions.push(`item =>${JSON.stringify(this.state.riskSelectedList)}.includes(item.Risk.toLowerCase())`)
    }
    if (this.state.categorySelectedList.length != 0) {
      conditions.push(`item =>${JSON.stringify(this.state.categorySelectedList)}.includes(item.AC.toLowerCase())`)
    }
    if (this.state.selectedAmcList.length != 0) {
      conditions.push(`item =>${JSON.stringify(this.state.selectedAmcList)}.includes(item.AI)`)
    }
    // if(this.state.selectedinvestList)
    this.setState({
      AmcDataList: this.props.AmcDataList.map((item) => {
        return { ...item, isChecked: this.state.selectedAmcList.includes(item.value) }
      })
    })
    if (conditions.length != 0) {
      worker.postMessage({ data: list, conditions });
    } else {
      var filterlist = replaceNull(this.props.listdata, "NA") != "NA" ? this.props.listdata?.filter(item => item.IvstT === "Growth").slice().sort((a, b) => a.Ranking - b.Ranking) : []
      var listFilter = filterlist.length != 0 ? filterlist.slice(0, 10) : []
      this.setState({ schemeCollection: listFilter, finalSearch: false })
    }
  }
  // onFinalSearchClick = () => {
  //   let list = this.props.listdata
  //   let conditions = this.state.conditionList
  //   worker.postMessage({ data: list, conditions });
  //   // this.setState({ flag: "global", filterSearch: false });
  //   // if (
  //   //   this.state.searchContent != "" && this.state.searchContent.length >= 2 ||
  //   //   this.state.categorySelectedList.length > 0 ||
  //   //   this.state.riskSelectedList.length > 0 ||
  //   //   this.state.typeFilterlist.length > 0 ||
  //   //   this.state.selectedAmcList.length > 0 ||
  //   //   this.state.selectedinvestList.length > 0
  //   // ) {

  //   //   // var searchList = this.state.schemeCollection;
  //   //   var object = [
  //   //     this.state.searchContent,
  //   //     this.state.categorySelectedList,
  //   //     this.state.riskSelectedList,
  //   //     this.state.typeFilterlist,
  //   //     this.state.selectedAmcList,
  //   //     this.state.selectedinvestList,
  //   //     this.state.ratingList,
  //   //     false,
  //   //     0,
  //   //   ];
  //   //   this.props.SchemeFilters(object);
  //   //   this.state.topten = this.props.schemeList;
  //   //   // this.state.finalSearch = true;
  //   //   this.setState({ finalSearch: true })
  //   //   this.state.schemeCount =
  //   //     this.state.schemeCount + this.props.schemeList.length;

  //   // } else {
  //   //   // this.state.finalSearch = false;
  //   //   this.setState({ finalSearch: false })

  //   //   this.props.TopTen();
  //   // }
  // };



  setSelectedAmc = async (item) => {
    // let amc = item.map((val) => {
    //   return val.label
    // })
    // let conditions = [`item =>[${JSON.stringify(item.map((val) => {
    //   return val.value
    // }))}].toLowerCase().includes(item.AI).toLowerCase())`]
    // // let a =
    // let old = `item =>[${JSON.stringify(this.state.selectedAmcList)}].toLowerCase().includes(item.AI).toLowerCase())`
    this.setState({
      // conditionList: this.state.conditionList.map((item) => {
      //   return { ...item.replace(`${old}`, conditions) }
      // }),
      selectedAmcList: item.map((val) => {
        return val.value
      }),
      // AmcDataList: this.state.AmcDataList.map((val) => {
      //   return { ...val, isChecked: this.state.selectedAmcList.includes(val.value) }
      // })
    });
    //
  };
  setSelectedInvest = async (invest) => {
    // let conditions = [`item =>[${JSON.stringify(item.map((val) => {
    //   return val.label
    // }))}].toLowerCase().includes(item.IvstT).toLowerCase())`]
    // let old = `item =>[${JSON.stringify(this.state.selectedinvestList)}].toLowerCase().includes(item.IvstT).toLowerCase())`
    this.setState({
      // conditionList: this.state.conditionList.map((item) => {
      //   return { ...item.replace(`${old}`, conditions) }
      // }),
      selectedinvestList: invest.map((item) => {
        return item.label
      }
      )
    });
  };

  handlekycclose = () => {
    this.setState({ openkyc: false });
  };
  handlekycyesclick = () => {
    getKYCDetails(this.props.logindetails.PhoneNumber,
      this.props.logindetails.EmailID,
      "").then((res) => {
        if (res.status == "F") {
          this.setState({
            openAlert: true,
            alertMessage: labels.messages.apierror,
            alertColor: "warning",
          });
        } else {
          window.open(res.data)
        }
      })
    // this.props.getKYCActionDetails(
    //   this.props.logindetails.PhoneNumber,
    //   this.props.logindetails.EmailID,
    //   ""
    // );
    // if (this.props.Kycmessage === "F") {
    //   this.setState({
    //     openAlert: true,
    //     alertMessage: labels.messages.apierror,
    //     alertColor: "warning",
    //   });
    // }
    this.setState({ openkyc: false });
  };

  resetClick = async () => {
    var filterlist = this.props.listdata.filter(item => item.IvstT === "Growth").slice().sort((a, b) => a.Ranking - b.Ranking)
    var listFilter = filterlist.slice(0, 10)
    this.setState({
      //topten: listFilter,
      schemeCollection: listFilter,
      searchContent: "",
      risk: false,
      category: false,
      clearShow: false,
      finalSearch: false,
      // showdottedicon: false,
      selectedAmcList: [],
      selectedinvestList: [],
    });
    await this.setState({
      categoryFilterlist: this.props.categoryFilterlist.map((item) => {
        return { label: item.label, value: item.value, isChecked: false };
      }),
      RiskFilterlist: this.props.RiskFilterlist.map((item) => {
        return { label: item.label, value: item.value, isChecked: false };
      }),
      typeFilterlist: this.state.typeFilterlist.map((item) => {
        return { label: item.label, value: item.value, isChecked: false };
      })
    });
    this.setState({
      selectedinvestList: [],
      selectedAmcList: [],
      categorySelectedList: [],
      riskSelectedList: [],
      typeFilterlist: [{ label: "All", value: 3, isChecked: false }, { label: "Growth", value: 1, isChecked: true }, { label: "Dividend", value: 2, isChecked: false }]
    });
    this.setState({
      filter: [
        { header: "Search", type: { name: "textbox", category: "" }, onChange: this.onSearchClick },
        { header: "Category", type: { name: "dropdown", category: "withoutsearchable" }, List: this.state.categoryFilterlist, onChange: this.onCategoryChange },
        { header: "Risk Type", List: this.state.RiskFilterlist, type: { name: "dropdown", category: "withoutsearchable" }, onChange: this.onRiskChange },
        { header: "Plan Type", List: this.state.typeFilterlist, type: { name: "dropdown", category: "withoutsearchable" }, onChange: this.onTypeChange },
        { header: "filter", type: { name: "popup", category: "" }, },
        { header: "", type: { name: "search", category: "" } }]

    })
  };

  // handleclear = () => {
  //   if (
  //     this.state.searchContent != "" ||
  //     this.state.categorySelectedList.length > 0 ||
  //     this.state.riskSelectedList.length > 0
  //   ) {
  //     // var object = [
  //     //   this.state.searchContent,
  //     //   this.state.categorySelectedList,
  //     //   this.state.riskSelectedList,
  //     //   this.state.amcList,
  //     //   this.state.investList,
  //     //   this.state.ratingList,
  //     //   false,
  //     //   0,
  //     // ];
  //     // this.props.SchemeFilters(object);
  //     // this.state.topten = this.props.schemeList;
  //     this.state.finalSearch = true;
  //     this.state.schemeCount =
  //       this.state.schemeCount + this.props.schemeList.length;
  //   } else {
  //     // this.props.TopTen();
  //     this.state.finalSearch = false;
  //   }
  // };

  closePopupbox = () => {
    this.setState({ openfilterpopup: false });
  };
  handleClearFilter = async () => {
    await this.setState({
      // selectedinvestList: [],
      selectedAmcList: [],
      // categorySelectedList: [],
      // riskSelectedList: [],
      AmcDataList: this.props.AmcDataList,
      clearShow: true,
    });
    this.filterSubmit()
    // this.handleclear();
  };
  handleOnetimeContinue = () => {
    this.props.GetAllCart(this.props.logindetails.SessionID, this.props.logindetails.ClientID)
    if (
      this.props.uccList.length != 0 &&
      this.props.uccList.filter((item) => item.ClientCode == "NA").length !=
      this.props.uccList.length
    ) {
      this.setState({ page: "OneTimeContinue" });
      let finalAmount = this.state.amount.replace(/,/g, "").split("₹")[1];
      if (finalAmount != "" && finalAmount != undefined) {
        if (finalAmount > 4999) {
          if (this.props.uccList.length != 0) {
            // this.setState({ openPopup: false, loaderSIPCart: false });
            if (this.props.uccList.length == 1) {
              this.finalOneTimeContinue(this.props.uccList[0].ClientCode)
            } else {
              this.setState({
                folioNumberList: this.props.uccList.map((item) => {
                  return { ...item, label: item.ClientCode, value: item.ClientCode }
                })
              });

              this.setState({ openFolioPopup: true, openPopup: false });

            }


          } else {

          }
        } else {
          this.setState({
            validationMessage: `${labels.sipPopup.minimumValue} ${labels.currency.rupeesymbol}5,000`,
          });
        }
      } else {
        this.setState({
          validationMessage: labels.investmentPopup.amountValidation,
        });
      }
    } else {
      this.setState({
        openAccount: true,
        alertMessage: labels.messages.uccnotavailble,
        alertColor: "warning",
      });
    }
  };
  finalOneTimeContinue = async (ucc) => {
    let finalAmount = this.state.amount.replace(/,/g, "").split("₹")[1];
    this.setState({ loaderOneTime: true })

    let validationMessage = await SchemeCodeFinding(
      finalAmount,
      Label.investmentPopup.keyOneTime,
      this.state.cartData.ISIN,
      this.state.cartData.PC,
      this.props.logindetails.ClientID,
      "BUY"
    );
    if (validationMessage.status != "success") {

      this.setState({
        openAlert: this.state.folioLoader ? true : false,
        alertMessage: validationMessage.status,
        validationMessage: this.state.folioLoader ? "" : validationMessage.status,
        loaderOneTime: false,
        folioLoader: false,
        alertColor: "warning",
      });
    } else {
      if (this.props.onetimeCartData.length > 0) {
        this.finalOnetimeAddtoCart(ucc)

      } else {
        let folioList = await this.getFolioList(this.state.cartData, "BUY")
        let purchaseData = this.state.cartData;
        purchaseData.InvesmentType = "One time";
        purchaseData.SchemeID = this.state.cartData.SI;
        purchaseData.SchemeName = this.state.cartData.SN;
        purchaseData.AssetClass = this.state.cartData.AC;
        purchaseData.AUM = this.state.cartData.AUM;
        purchaseData.StepperActive = 0;
        purchaseData.Risk = this.state.cartData.Risk;
        purchaseData.Amount = finalAmount;
        purchaseData.ISIN = this.state.cartData.ISIN;
        purchaseData.threeYearReturn =
          typeof this.state.cartData.Nav === "object"
            ? 0
            : this.state.cartData.Nav;
        purchaseData.date = this.state.cartData.NavDate;
        purchaseData.SchemeCode = this.state.cartData.SC;
        purchaseData.BSESchemeType = this.state.cartData.BSEST;
        purchaseData.Ucc = ucc
        delete this.state.cartData.multipleScheme
        purchaseData.multipleScheme = [{ ...this.state.cartData, Instalments: 180, MutualFundUCC: ucc, FolioList: JSON.stringify(folioList) }]
        purchaseData.MutualFundUCC = ucc
        purchaseData.SipFrequency = "MONTHLY"
        purchaseData.isFromCart = false;
        purchaseData.FolioList = JSON.stringify(folioList)
        this.setState({ loaderOneTime: false, openFolioPopup: false, folioLoader: false });

        this.props.history.push({
          pathname: Label.Path.Payment,
          state: { data: purchaseData },
        });
      }
    }
    // } else {
    //   this.mappingDetailsErrorClose();
    // }
  };

  onClickSearchClear = () => {
    this.setState({ searchContent: "" });
    this.resetClick();
  };

  handleSipContinue = () => {
    this.props.GetAllCart(this.props.logindetails.SessionID, this.props.logindetails.ClientID)

    if (
      this.props.uccList.length != 0 &&
      this.props.uccList.filter((item) => item.ClientCode == "NA").length !=
      this.props.uccList.length
    ) {
      this.setState({ page: "SIPContinue" });
      let finalAmount = this.state.amount.replace(/,/g, "").split("₹")[1];
      if (finalAmount != "" && finalAmount != undefined) {
        if (finalAmount > 999) {
          if (this.state.selectedDate == "") {
            this.dropdownValidation(this.state.selectedDate);
          }
          else {
            if (this.props.uccList.length != 0) {
              if (this.props.uccList.length == 1) {
                this.finalSipContinue(this.props.uccList[0].ClientCode)
              } else {
                this.setState({
                  folioNumberList: this.props.uccList.map((item) => {
                    return { ...item, label: item.ClientCode, value: item.ClientCode }
                  })
                });
                this.setState({ openFolioPopup: true, sipPopup: false });
              }
            } else {
              this.setState({ loaderSIPCart: false });
            }
          }
        } else {
          this.setState({
            validationMessage: `${labels.sipPopup.minimumValue}${labels.currency.rupeesymbol} 1,000`,
          });
        }
      } else {
        this.setState({
          validationMessage: `${labels.investmentPopup.amountValidation}`,
        });
      }
    } else {
      this.setState({
        openAccount: true,
        alertMessage: labels.messages.uccnotavailble,
        alertColor: "warning",
      });
    }
  };

  folioContinue = (value) => {
    this.setState({ folioNumber: value, folioLoader: true });
    if (this.state.page == "SIPContinue") {
      this.finalSipContinue(value);
    } else if (this.state.page == "OneTimeContinue") {
      this.finalOneTimeContinue(value);
    } else if (this.state.page == "SipCart") {
      this.finalSipAddToCart(value);
    } else if (this.state.page == "OneTimeCart") {
      this.finalOnetimeAddtoCart(value,);
    }
  };
  finalSipContinue = async (ucc) => {
    let finalAmount = this.state.amount.replace(/,/g, "").split("₹")[1];
    if (
      this.props.uccList.length != 0 &&
      this.props.uccList.filter((item) => item.ClientCode == "NA").length !=
      this.props.uccList.length
    ) {
      this.setState({ loaderSIP: true })

      let validationMessage = await SchemeCodeFinding(
        finalAmount,
        Label.investmentPopup.keySIP,
        this.state.cartData.ISIN,
        this.state.cartData.PC,
        this.props.logindetails.ClientID,
        "SIP"
      );
      if (validationMessage.status != "success") {
        this.setState({ validationMessage: validationMessage.status, });
        if (this.state.folioLoader) {
          this.setState({
            openAlert: true,
            alertMessage: validationMessage.status, alertColor: "warning",
          })
        }
        this.setState({
          // openAlert: true,
          // alertMessage: validationMessage.status,
          loaderSIP: false,
          folioLoader: false,
          openFolioPopup: false
          // alertColor: "warning",
        });
      } else {

        if (this.props.sipCartData.length > 0) {
          this.finalSipAddToCart(ucc)

        } else {
          //
          let folioList = await this.getFolioList(this.state.cartData, "SIP")
          let purchaseData = this.state.cartData;
          purchaseData.InvesmentType = "Sip";
          purchaseData.SchemeID = this.state.cartData.SI;
          purchaseData.SchemeName = this.state.cartData.SN;
          purchaseData.AssetClass = this.state.cartData.AC;
          purchaseData.AUM = this.state.cartData.AUM;
          purchaseData.Risk = this.state.cartData.Risk;
          purchaseData.Amount = finalAmount;
          purchaseData.ISIN = this.state.cartData.ISIN;
          purchaseData.StepperActive = 0;
          purchaseData.ISFirstPayment = this.state.checked;
          purchaseData.threeYearReturn =
            typeof this.state.cartData.Nav === "object"
              ? 0
              : this.state.cartData.Nav;
          purchaseData.date = this.state.cartData.NavDate;
          purchaseData.SchemeCode = this.state.cartData.SC;
          purchaseData.Ucc = ucc
          purchaseData.MutualFundUCC = ucc
          purchaseData.minimumInstalment = validationMessage.response?.MINIMUM_INSTALLMENT_NUMBERS || 12
          purchaseData.SipFrequency = "MONTHLY"
          delete this.state.cartData.multipleScheme
          purchaseData.multipleScheme = [{ ...this.state.cartData, Instalments: 180, MutualFundUCC: ucc, Sipdate: this.state.selectedDate, FolioList: JSON.stringify(folioList) }]
          purchaseData.BSESchemeType = this.state.cartData.BSEST;
          purchaseData.Sipdate = this.state.selectedDate;
          purchaseData.btnFrequency = 1;
          purchaseData.FolioList = JSON.stringify(folioList)
          this.setState({ loaderSIP: false, amount: "", openFolioPopup: false, folioLoader: false });
          this.props.history.push({
            pathname: Label.Path.Payment,
            state: { data: purchaseData },
          });
        }
      }
    } else {
      this.setState({
        openAccount: true,
        alertMessage: labels.messages.uccnotavailble,
        alertColor: "warning",
      });
    }
  };
  getFolioList = async (value, flag) => {
    folio = []
    let res = []
    await getPortfolioxraySchemeDetails(
      this.props.logindetails.ClientID,
      0,
      value.SI,
      false, flag
    ).then((response) => {
      if (response.status == "S") {
        if (response.folioNumber.length != 0) {
          folio = response.folioNumber
          res = response.folioNumber
        } else {
          folio = []
          res = []
        }
      } else {
        folio = []
        res = []
        // this.setState({ folioListDetails: [] })
      }
    })
    return res
  }
  finalOnetimeAddtoCart = async (ucc) => {
    if (
      this.props.onetimeCartData.filter((item) => item.SchemeID == this.state.cartData.SI && item.MutualFundUCC == ucc).length > 0
    ) {
      this.setState({ openAlert: true, alertMessage: "Already in cart", alertColor: "warning", loaderOneTime: false, openPopup: false, openFolioPopup: false })
      setTimeout(() => {
        this.props.history.push({
          pathname: Label.Path.cartOneTime,
          state: { data: this.state.cartData },
        });
      }, 1000);

      // this.popupClose("failed");
    } else {
      if (
        this.props.uccList.length != 0 &&
        this.props.uccList.filter((item) => item.ClientCode == "NA").length !=
        this.props.uccList.length
      ) {
        this.setState({ loaderOneTime: true })
        let finalAmount = this.state.amount.replace(/,/g, "").split("₹")[1];
        let validationMessage = await SchemeCodeFinding(
          finalAmount,
          Label.investmentPopup.keyOneTime,
          this.state.cartData.ISIN,
          this.state.cartData.PC,
          this.props.logindetails.ClientID,
          "BUY"
        );
        if (validationMessage.status != "success") {
          this.setState({ validationMessage: validationMessage.status });
          if (this.state.folioLoader) {
            this.setState({
              openAlert: true,
              alertMessage: validationMessage.status,
              alertColor: "warning",
            })
          }
          this.setState({
            // openAlert: true,
            // alertMessage: validationMessage.status,
            // loaderOneTimeCart: false,
            loaderOneTime: false,
            folioLoader: false
            // alertColor: "warning",
          });
        } else {
          if (this.props.onetimeCartData.filter((item) => item.MutualFundUCC == ucc).length >= 10) {
            if (this.state.folioLoader) {
              this.setState({ openAlert: true, alertMessage: "Maximum Limit Exceed", alertColor: "warning" })
            }
            this.setState({
              // openAlert: true,
              loaderOneTime: false,
              validationMessage: "Maximum Limit Exceed",
              folioLoader: false,
              openFolioPopup: false
              // alertColor: "warning",
            });
          } else {
            // if (!this.state.loaderOneTimeCart) {
            // this.setState({ loaderOneTimeCart: true })
            let folioList = await this.getFolioList(this.state.cartData, "BUY")
            const cartID = uniqueId();

            let adddata = this.state.cartData;
            adddata.ID = Number(cartID);
            adddata.Instalments = this.state.installments;
            adddata.Amount = Number(finalAmount);
            adddata.SipFrequency = this.state.frequency;
            adddata.BSESchemeType = this.state.cartData.BSEST;
            adddata.folioList = folioList
            adddata.MutualFundUCC = ucc
            //setCountValue(1)
            addtocart(
              Number(cartID),
              this.state.cartData.SI,
              this.props.clientID,
              Number(finalAmount),
              this.state.cartData.AUM,
              this.state.installments,
              false,
              true,
              false,
              false,
              this.state.cartData.Ranking,
              this.state.cartData.Risk,
              false,
              this.state.frequency,
              "",
              1,
              false,
              this.state.cartData.ratings,
              this.state.cartData.AI,
              this.state.cartData.AC,
              this.state.cartData.ISIN,
              this.state.cartData.RI,
              this.state.cartData.PC,
              validationMessage.response.SchemeCode == undefined
                ? ""
                : validationMessage.response.SchemeCode,
              false,
              this.props.logindetails.SessionID,
              "",
              ucc
              , JSON.stringify(folioList),
              false, 0, this.props.transactionType?.filter((item) => item.TransactionType.toLowerCase() == "purchase")[0]?.ID || 0

            ).then((data) => {

              if (data != undefined) {
                if (data.status == "S") {
                  //    this.props.GetAllCartValues(adddata, "addonetime");
                  this.setState({
                    openAlert: true, alertMessage: "Added to cart successfully",
                    alertColor: "success", openPopup: false
                  })

                } else {
                  // this.popupSideClose();
                  this.setState({
                    openAlert: true,
                    alertMessage: data.message,
                    alertColor: "warning", openPopup: false
                  })
                  this.mappingDetailsErrorClose()

                }
              }
              this.setState({ loaderOneTime: false, folioLoader: false, openFolioPopup: false, openPopup: false });
            });
          }

          // }

        }
      } else {
        this.setState({
          openAccount: true,
          alertMessage: labels.messages.uccnotavailble,
          alertColor: "warning",
        });
        this.setState({ loaderOneTime: false });
      }
    }
  };

  addtoCartOnetime = async () => {
    if (
      this.props.uccList.length != 0 &&
      this.props.uccList.filter((item) => item.ClientCode == "NA").length !=
      this.props.uccList.length
    ) {
      this.setState({ page: "OneTimeCart" });

      let finalAmount = this.state.amount.replace(/,/g, "").split("₹")[1];
      if (finalAmount != "" && finalAmount != undefined) {
        if (finalAmount > 4999) {
          if (this.props.uccList.length != 0) {
            this.props.GetAllCart(this.props.logindetails.SessionID, this.props.logindetails.ClientID)

            // this.setState({ openPopup: false, loaderSIPCart: false });
            if (this.props.uccList.length == 1) {
              this.finalOnetimeAddtoCart(this.props.uccList[0].ClientCode)
            } else {
              this.setState({
                folioNumberList: this.props.uccList.map((item) => {
                  return { ...item, label: item.ClientCode, value: item.ClientCode }
                })
              });

              this.setState({ openFolioPopup: true, openPopup: false, });

            }

          } else {
            // this.setState({ loaderSIPCart: false });
          }

        } else {
          //    props.amountValidation()
          // setValidation(true)
          this.setState({
            validationMessage: `${labels.sipPopup.minimumValue} ${labels.currency.rupeesymbol}5,000`,
          });

          // setValidationMessage(Labels.messages.required)
        }
      } else {
        // props.amountValidation()
        // setValidation(true)
        this.setState({
          validationMessage: Label.investmentPopup.amountValidation,
        });
        // setValidationMessage(Labels.messages.minimumAmount)
      }
    } else {
      this.setState({
        openAccount: true,
        alertMessage: labels.messages.uccnotavailble,
        alertColor: "warning",
      });
    }
  };

  dropdowndata = async (value) => {
    let todayDate = moment(new Date()).format("DD MMM YYYY");
    if (value.length != 0) {
      this.setState({ currentDateBackUp: "" })
      this.setState({ validationMessage: "" });
      let dropdownDate = moment(value[0].value, "DD MMM YYYY").format(
        "DD MMM YYYY"
      );
      dropdownDate = moment(dropdownDate).isBefore(todayDate) ? moment(
        moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
      ).format("DD MMM YYYY") : dropdownDate
      this.setState({
        startDate: labels.Date.filter((item) => item.value == value[0].value)[0]
          .label,
        dropDownValue: value[0].value
        , currentDateBackUp: dropdownDate
      });
      await this.setState({ selectedDate: dropdownDate });
      this.dateSetting(value[0].value, this.state.checked);
    }
  };
  dateSetting = (date, isChecked) => {
    let todayDate = moment(new Date()).format("DD MMM YYYY");
    let dropdownDate = moment(date, "DD MMM YYYY").format("DD MMM YYYY");
    if (isChecked && !moment(dropdownDate).isBefore(todayDate) && moment(dropdownDate).diff(moment().format("DD MMM YYYY"), "days") <= 6 || moment(dropdownDate).diff(moment().format("DD MMM YYYY"), "days") > 6) {
      this.setState({
        selectedDate: moment(
          moment(dropdownDate, "DD MMM YYYY").toString()
        ).format("DD MMM YYYY"),
      });
    }
    else {
      //  if()
      this.setState({
        selectedDate: moment(
          moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
        ).format("DD MMM YYYY"),
      });
    }
    // let todayDate = moment(new Date()).format("DD MMM YYYY");
    // let dropdownDate = moment(date, "DD MMM YYYY").format("DD MMM YYYY");
    // if (!moment.isMoment(todayDate)) todayDate = moment(todayDate);
    // if (!moment.isMoment(dropdownDate)) dropdownDate = moment(dropdownDate);
    // dropdownDate = moment(dropdownDate).isBefore(todayDate) ? moment(
    //   moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
    // ).format("DD MMM YYYY") : dropdownDate
    // if ((!moment(dropdownDate).isAfter(todayDate) && !moment(dropdownDate).isSame(todayDate)) || moment(dropdownDate).diff(moment().format("DD MMM YYYY"), "days") <= 6) {
    //   this.setState({
    //     selectedDate: moment(
    //       moment(dropdownDate, "DD MMM YYYY").add(1, "months").toString()
    //     ).format("DD MMM YYYY"),
    //   });
    // } else {
    //   this.setState({ selectedDate: this.state.currentDateBackUp });
    // }
  };

  finalSipAddToCart = async (ucc) => {
    if (
      this.props.sipCartData.filter((item) => item.SchemeID == this.state.cartData.SI && item.MutualFundUCC == ucc).length > 0
    ) {
      this.setState({ openAlert: true, alertMessage: "Already in cart", alertColor: "warning", folioLoader: false, openFolioPopup: false, loaderSIP: false, sipPopup: false })
      setTimeout(() => {
        this.props.history.push({
          pathname: Label.Path.cartSip,
          state: { data: this.state.cartData },
        });
      }, 1000);

    } else {
      if (
        this.props.uccList.length != 0 &&
        this.props.uccList.filter((item) => item.ClientCode == "NA").length !=
        this.props.uccList.length
      ) {
        this.setState({ loaderSIP: true })

        let finalAmount = this.state.amount.replace(/,/g, "").split("₹")[1];
        let validationMessage = await SchemeCodeFinding(
          finalAmount,
          Label.investmentPopup.keySIP,
          this.state.cartData.ISIN,
          this.state.cartData.PC,
          this.props.logindetails.ClientID,
          "SIP"
        );
        // //
        if (validationMessage.status == "success") {

          if (this.props.sipCartData.filter((item) => item.MutualFundUCC == ucc).length >= 10) {

            this.setState({
              // openAlert: true,
              validationMessage: "Maximum Limit Exceed",
              // alertColor: "warning",
            });
            if (this.state.folioLoader) {
              this.setState({
                openAlert: true
                , alertMessage: "Maximum Limit Exceed",
                alertColor: "warning"
              })
            }
            this.setState({ loaderSIP: false, openFolioPopup: false, folioLoader: false })

          } else {
            // if (!this.state.loaderSIPCart) {
            // this.setState({ loaderSIPCart: true })
            let folioList = await this.getFolioList(this.state.cartData, "SIP")
            const cartID = uniqueId();

            let adddata = this.state.cartData;
            adddata.ID = Number(cartID);
            adddata.Instalments = this.state.installments;
            adddata.Amount = Number(finalAmount);
            adddata.SipFrequency = this.state.frequency;
            adddata.BSESchemeType = this.state.cartData.BSEST;
            adddata.Sipdate = moment(this.state.selectedDate, "DD MMM YYYY").format(
              "DD/MM/YYYY"
            );
            adddata.ISFirstPayment = this.state.checked;
            adddata.MutualFundUCC = ucc
            adddata.folioList = folioList

            //          setCountValue(1)
            addtocart(
              Number(cartID),
              this.state.cartData.SI,
              this.props.clientID,
              Number(finalAmount),
              this.state.cartData.AUM,
              this.state.installments,
              false,
              false,
              false,
              false,
              this.state.cartData.Ranking,
              this.state.cartData.Risk,
              true,
              this.state.frequency,
              moment(this.state.selectedDate, "DD MMM YYYY").format("DD/MM/YYYY"),
              1,
              false,
              this.state.cartData.ratings,
              this.state.cartData.AI,
              this.state.cartData.AC,
              this.state.cartData.ISIN,
              this.state.cartData.RI,
              this.state.cartData.PC,
              validationMessage.response.SchemeCode == undefined
                ? ""
                : validationMessage.response.SchemeCode,
              this.state.checked,
              this.props.logindetails.SessionID,
              "",
              ucc, JSON.stringify(folioList), false, 0, this.props.transactionType?.filter((item) => item.TransactionType.toLowerCase() == "sip")[0]?.ID || 0
            ).then((data) => {
              // if (data != undefined) {

              if (data.status == "S") {
                // this.popupClose("success");

                //  this.props.GetAllCartValues(adddata, "addsip");
                this.setState({
                  openAlert: true,
                  alertMessage: "Added to cart Successfully",
                  alertColor: "success",
                  folioLoader: false,
                  sipPopup: false
                })
              } else {
                this.setState({
                  openAlert: true,
                  alertMessage: data.message,
                  alertColor: "warning",
                  folioLoader: false,
                  openFolioPopup: false,
                  sipPopup: false
                })
              }
              this.setState({ loaderSIP: false, openFolioPopup: false });
            });
          }
        } else {
          if (this.state.folioLoader) {
            this.setState({
              openAlert: true
              , alertMessage: validationMessage.status,
              alertColor: "warning"
            })
          }
          await this.setState({
            // openAlert: true,
            loaderSIP: false,
            openFolioPopup: false,
            folioLoader: false,
            openFolioPopup: false,
            validationMessage: validationMessage.status,
            // loaderSIPCart: false,
            // alertColor: "warning",
          });
          // this.mappingDetailsErrorClose()
        }
      } else {
        await this.setState({
          openAlert: true,
          alertMessage: labels.messages.uccnotavailble,
          alertColor: "warning",
          loaderSIP: false,
          openFolioPopup: false,
          folioLoader: false

        });
        // this.mappingDetailsErrorClose()
      }
    }


    //}
  };
  addtoCartSip = () => {

    if (
      this.props.uccList.length != 0 &&
      this.props.uccList.filter((item) => item.ClientCode == "NA").length !=
      this.props.uccList.length
    ) {
      this.setState({ page: "SipCart" });
      let finalAmount = this.state.amount.replace(/,/g, "").split("₹")[1];
      if (finalAmount != "" && finalAmount != undefined) {
        if (finalAmount > 999) {
          if (this.state.selectedDate == "") {
            this.dropdownValidation(this.state.selectedDate);
          }
          else {
            this.props.GetAllCart(this.props.logindetails.SessionID, this.props.logindetails.ClientID)

            if (this.props.uccList.length != 0) {
              // this.setState({ loaderSIPCart: false });
              if (this.props.uccList.length == 1) {
                this.finalSipAddToCart(this.props.uccList[0].ClientCode)
              } else {
                this.setState({
                  folioNumberList: this.props.uccList.map((item) => {
                    return { ...item, label: item.ClientCode, value: item.ClientCode }
                  })
                });

                this.setState({ openFolioPopup: true, sipPopup: false, });

              }
            } else {
              this.setState({ loaderSIPCart: false });
            }
          }
        } else {
          this.setState({ validationMessage: Label.messages.minimumAmount });
        }
      } else {
        //props.sipMaxAmountValidation()
        this.setState({
          validationMessage: Label.investmentPopup.amountValidation,
        });
      }
    } else {
      this.setState({
        openAccount: true,
        alertMessage: labels.messages.uccnotavailble,
        alertColor: "warning",
      });
    }
  };
  onClose = () => {
    this.setState({ openFolioPopup: false });
  };
  resize() {
    let currentHideNav = window.innerWidth <= 920;
    if (currentHideNav !== this.state.showbutton) {
      //
      this.setState({ showbutton: currentHideNav });
    }
  }
  findDate = (val) => {
    let a = Number(moment().format("DD"))
    let arr = [...Array(6)].map((item, i) => {
      return a + i
    })
    return arr.includes(Number(val))

  }
  componentWillUnmount() {
    this.isComponentMounted = false;
    window.removeEventListener("resize", this.resize.bind(this));
  }
  handleAccountClose = () => {
    this.setState({
      openAccount: false
    })
  }
  sortByClose = () => {
    this.setState({ sortBy: null })
  }
  handleOnClearChange = () => {
    // var filterlist = this.props.listdata.filter(item => item.IvstT === "Growth")
    this.setState({
      // topten: listFilter,
      finalSearch: false,
      typeFilterlist: [{ label: "All", value: 3, isChecked: false }, { label: "Growth", value: 1, isChecked: true }, { label: "Dividend", value: 2, isChecked: false }]

    })
  }
  render() {
    const { classes } = this.props;
    try {
      return (
        <div >
          <Filter_screen {...this.state}  {...this.props}
            enableSortBy={true}
            // sortFlag={this.sortFlag}
            isLoading={this.props.isLoadingScheme}
            AmcList={this.state.AmcDataList}
            sortBy={this.state.sortByList}
            filterSearch={this.state.filterSearch}
            routeChange={this.routeChange}
            onClickSearchClear={this.onClickSearchClear}
            sortByClose={this.sortByClose}
            handleScroll={this.handleScroll}
            callBack={this.sortByIndexClick}
            oneTimePopup={this.oneTimePopup} sipPopup={this.sipPopup} onSearchClick={this.onSearchClick}
            handleClearFilter={this.handleClearFilter}
            setSelectedAmc={this.setSelectedAmc}
            setSelectedInvest={this.setSelectedInvest}
            subHeader={labels.schemeFilterLabels.trendingTop10MutualFund}
            handleappyclick={this.filterSubmit} onFinalSearchClick={this.filterSubmit} resetClick={this.resetClick} handleCloseAlert={this.handleCloseAlert} filter={this.state.filter}
            filterGrid={classes.sumBox} listdata={this.state.schemeCollection == "NA" || this.state.schemeCollection.length == 0 ? [] : this.state.schemeCollection?.map((item) => {
              return {
                ...item, dataOne: window.globalConfig.AmcImage + item.AMCImg, dataSeven: 1,
                dataSevenHeader: Label.schemes.OneTime, dataEightHeader: Label.schemes.startsip,
                dataThreeStyle: { color: NegativeFinding(item.OW) },
                dataFourStyle: { color: NegativeFinding(item.OY) },
                dataFiveStyle: {
                  color: NegativeFinding(item.TY)
                },
                dataEight: 1, dataSixHeader: Label.schemes.AUM, dataThreeHeader: Label.schemes.oneWeek,
                subDataTwoHeader: `${Label.schemes.Risk} :`, dataFourHeader: Label.schemes.OneYear,
                dataFiveHeader: Label.schemes.threeYear, dataTwo: item.SN, dataThree: NegativeFinding(item.OW, "", "", "", "percentage"),
                dataFour: NegativeFinding(item.OY, "", "", "", "percentage"), dataFive: NegativeFinding(item.TY, "", "", "", "percentage"), dataSix: NegativeFinding(item.AUM, "", "", "", "rupee"), isShowButton: true,
                subDataOne: item.AC, subDataTwo: item.Risk
              }
            })
            } pageHeader={labels.menu.explorepage} classes={classes}></Filter_screen>

          {/* <Footer /> */}


          {/*One Time Popup */}
          <Popup
            open={this.state.openPopup}
            onClose={this.popupSideClose}
            showdefault={false}
            header={labels.investmentPopup.heading}
          >

            <div className={classes.onetimeContainer}>
              <div className={[classes.body, "textbox-popup"].join(" ")}>
                <div className={classes.txtBody}>
                  {labels.investmentPopup.title}{" "}
                </div>

                <TextField
                  id="standard-full-width"
                  style={{ margin: 8, textAlign: "center" }}
                  className={[
                    classes.textAligns,
                    classes.inputRoot,
                    classes.textFieldCursor,
                  ].join(" ")}
                  margin="normal"
                  autoComplete="off"
                  placeholder="₹0"
                  autoFocus="autofocus"
                  value={this.state.amount}
                  onChange={this.getAmount}
                // otherProps={{ autoFocus: true, onFocus: this.handleFocus, }}
                />
                <div className={classes.badges}>
                  <Chip
                    color="secondary"
                    style={{
                      color: window.globalConfig.color.LABLE_GRAY,
                      backgroundColor: window.globalConfig.color.RosyBrown,
                      margin: 2,
                      height: 20,
                      fontSize: 12,
                      cursor: "pointer",
                    }}
                    label={labels.schemes.fiveThousand}
                    onClick={() => this.addvalue(5000)}
                  />
                  <Chip
                    color="secondary"
                    style={{
                      color: window.globalConfig.color.LABLE_GRAY,
                      backgroundColor: window.globalConfig.color.RosyBrown,
                      margin: 2,
                      height: 20,
                      fontSize: 12,
                      cursor: "pointer",
                    }}
                    label={labels.schemes.tenThousand}
                    onClick={() => this.addvalue(10000)}
                  />
                  <Chip
                    color="secondary"
                    style={{
                      color: window.globalConfig.color.LABLE_GRAY,
                      backgroundColor: window.globalConfig.color.RosyBrown,
                      margin: 2,
                      height: 20,
                      fontSize: 12,
                      cursor: "pointer",
                    }}
                    label={labels.schemes.fifteenThousand}
                    onClick={() => this.addvalue(15000)}
                  />
                </div>
              </div>
              <div className={classes.footer}>
                <span
                  style={{
                    fontSize: 12,
                    color: "red",
                    textAlign: "left",
                    marginLeft: "5%",
                  }}
                >
                  {this.state.validationMessage}
                </span>

                <div className={classes.btnParent}>
                  <Button
                    page={"primary"}
                    name={labels.schemes.continue}
                    onClick={this.handleOnetimeContinue}
                    // disabled={this.state.loaderOneTime}
                    disabled={this.state.loaderOneTime}
                    loader={this.state.loaderOneTime && this.state.page == "OneTimeContinue"}
                  />
                </div>
                <div className={classes.cartParent} style={{ opacity: this.state.loaderOneTime ? 0.5 : 1, cursor: this.state.loaderOneTime == true ? "not-allowed" : "pointer" }}>
                  <u
                    className={classes.cart}
                    style={{
                      cursor: this.state.loaderOneTime
                        ? "not-allowed"
                        : "pointer",
                    }}
                    onClick={() => {
                      if (!this.state.loaderOneTime) {
                        this.addtoCartOnetime();
                      }
                    }}
                  >
                    {labels.investmentPopup.cart}
                    {this.state.loaderOneTime && this.state.page == "OneTimeCart" && (
                      <CircularProgress
                        size={20}
                        thickness={1}
                        style={{ position: "absolute", marginLeft: -40 }}
                      />
                    )}
                  </u>
                </div>
              </div>
            </div>

          </Popup>
          {/*SIP popup */}
          <Popup
            open={this.state.sipPopup}
            onClose={this.sippopupSideClose}
            showdefault={false}
            header={labels.sipPopup.heading}
          >
            {/* <div>
              <div> */}
            <div className={classes.sipContainer}>
              <div className={[classes.body, "textbox-popup"].join(" ")}>
                <div className={classes.txtBody}>
                  {labels.sipPopup.title}{" "}
                </div>

                <TextField
                  id="standard-full-width"
                  style={{ margin: 8, textAlign: "center" }}
                  className={[
                    classes.textAligns,
                    classes.inputRoot,
                    classes.textFieldCursor,
                  ].join(" ")}
                  margin="normal"
                  autoComplete="off"
                  placeholder="₹0"
                  autoFocus="autofocus"
                  value={this.state.amount}
                  onChange={this.getAmount}
                // otherProps={{ autoFocus: true, onFocus: this.handleFocus, }}
                />
                <div className={classes.badges}>
                  <>
                    <Chip
                      color="secondary"
                      style={{
                        color: window.globalConfig.color.LABLE_GRAY,
                        backgroundColor: window.globalConfig.color.RosyBrown,
                        margin: 2,
                        height: 20,
                        fontSize: 12,
                        cursor: "pointer",
                      }}
                      label={labels.schemes.fiveHundred}
                      onClick={() => this.addvalue(500)}
                    />
                    <Chip
                      color="secondary"
                      style={{
                        color: window.globalConfig.color.LABLE_GRAY,
                        backgroundColor: window.globalConfig.color.RosyBrown,
                        margin: 2,
                        height: 20,
                        fontSize: 12,
                        cursor: "pointer",
                      }}
                      label={labels.schemes.thousand}
                      onClick={() => this.addvalue(1000)}
                    />
                    <Chip
                      color="secondary"
                      style={{
                        color: window.globalConfig.color.LABLE_GRAY,
                        backgroundColor: window.globalConfig.color.RosyBrown,
                        margin: 2,
                        height: 20,
                        fontSize: 12,
                        cursor: "pointer",
                      }}
                      label={labels.schemes.tenThousand}
                      onClick={() => this.addvalue(10000)}
                    />
                  </>
                </div>
              </div>
              <div className={classes.footer}>
                {/* <> */}
                <div style={{ textAlign: "center", padding: "0px 10px" }}>
                  <Dropdown
                    height={135}
                    id="ddl_startsip"
                    placeholder={labels.schemes.selectSIPDate}
                    type={"normalwithsearch"}
                    value={this.state.startDate}
                    List={labels.Date}
                    handleOnChange={this.dropdowndata}
                  />
                </div>
                <div style={{ marginTop: 10, padding: "0px 10px" }}>
                  <CommonCheckbox
                    label={labels.bigPopup.checkBox}
                    checked={this.state.checked}
                    onChange={this.handleCheckbox}
                  />
                </div>
                {this.state.selectedDate != undefined && (
                  <div style={{ fontSize: 14, marginTop: '0.7em', marginLeft: 10, textAlign: 'left', display: "flex", flexDirection: "column", gap: 10 }}>
                    <span>
                      SIP Start date will be <b> {this.state.selectedDate}</b>
                    </span>
                    <span>
                      Next installment will be <b> {
                        moment(moment(
                          this.state.selectedDate,
                          "DD MMM YYYY"
                        )
                          .add(1, "M")
                          .toString()
                        ).format("DD MMM YYYY")}</b>
                    </span>
                  </div>
                )}
                {/* </> */}
                <span
                  style={{
                    fontSize: 12,
                    color: "red",
                    textAlign: "left",
                    marginLeft: "5%",
                  }}
                >
                  {this.state.validationMessage}
                </span>

                <div className={classes.btnParent}>
                  <Button
                    page={"primary"}
                    name={labels.schemes.continue}
                    onClick={this.handleSipContinue}
                    disabled={this.state.loaderSIP}
                    loader={this.state.loaderSIP && this.state.page == "SIPContinue"}
                  />
                </div>
                <div className={classes.cartParent} style={{ opacity: this.state.loaderSIP == true ? 0.5 : 1, cursor: this.state.loaderSIP == true ? "not-allowed" : "pointer" }}>
                  <u
                    className={classes.cart}
                    style={{
                      cursor: this.state.loaderSIP
                        ? "not-allowed"
                        : "pointer",
                    }}
                    onClick={() => {
                      if (!this.state.loaderSIP) {
                        this.addtoCartSip();
                      }
                    }}
                  >
                    {labels.investmentPopup.cart}
                    {this.state.loaderSIP && this.state.page == "SipCart" && (
                      <CircularProgress
                        size={20}
                        thickness={1}
                        style={{ position: "absolute", marginLeft: -40 }}
                      />
                    )}
                  </u>
                </div>
              </div>
            </div>
            {/* </div>
            </div> */}
          </Popup>
          <CommonDialogBox
            flag="primary"
            open={this.state.openAccount}
            handleClose={this.handleAccountClose}
            onClick={this.handleAccountClose}
          />
          <Popup
            header={labels.deletePopup.confirmation}
            open={this.state.openkyc}
            onClick={this.handlekycyesclick}
            onClose={this.handlekycclose}
            text={
              labels.schemes.kycnotverifiedmessage
            }
          />
          <CommonSnackbar
            open={this.state.openAlert}
            message={this.state.alertMessage}
            severity={this.state.alertColor}
            handlesnanckClose={this.handleCloseAlert}
          />
          <FolioPopup
            onClick={this.folioContinue}
            onClose={this.onClose}
            loader={this.state.folioLoader}
            openFolioPopup={this.state.openFolioPopup}
            folioNumbers={this.state.folioNumberList}
          />
        </div >
      )

    } catch (err) {
      return <Redirect to={labels.Path.NotFound} />;
    }

  }
}
const mapStateToProps = (state) => {
  return {
    // sessionId: state.Login.sessionID,
    // clientId: state.Login.logindetails.ClientID,
    // dropdowndetails: state.Dropdown.dropdowndetails,
    userDetails:
      state.ProfileDetails.AllProfileDetails.length != 0
        ? state.ProfileDetails.AllProfileDetails.mainDetails != "NA"
          ? state.ProfileDetails.AllProfileDetails.mainDetails
          : []
        : [],
    categoryFilterlist:
      state.Dropdown.dropdowndetails == null ||
        state.Dropdown.dropdowndetails.assetClass == undefined ||
        state.Dropdown.dropdowndetails.assetClass == null
        ? []
        : state.Dropdown.dropdowndetails.assetClass.filter((value) => (value.AssetClass != "NA")).map((val) => {
          return {
            label: val.AssetClass,
            value: val.ID,
          };
        }),
    RiskFilterlist:
      state.Dropdown.dropdowndetails == null ||
        state.Dropdown.dropdowndetails.riskType == undefined
        ? []
        : state.Dropdown.dropdowndetails.riskType.filter((value) => (value.Data != "NA")).map((val) => {
          return {
            label: val.Data,
            value: val.ID,
            // isChecked: false
          };
        }),
    // AmcFullList:
    //   state.Dropdown.dropdowndetails == null ||
    //     state.Dropdown.dropdowndetails.amc == undefined
    //     ? []
    //     : state.Dropdown.dropdowndetails.amc.filter((value) => (value.AMC != "NA")).map((val) => {
    //       return { label: val.AMC, value: val.ID, isChecked: false };
    //     }),
    listdata:
      state.Dropdown.dropdowndetails == null ||
        state.Dropdown.dropdowndetails.scheme == undefined
        ? []
        : state.Dropdown.dropdowndetails.scheme/*.filter((value, i) => i < 10)*/,

    // schemeList: state.Dropdown.schemeFilteredList,
    // schemeFilteredCount: state.Dropdown.schemeIndex,
    // mappingDetails: state.SchemeDetails.mappingDetails,
    // clientID: state.Login.logindetails.ClientID,
    logindetails: state.Login.logindetails,
    // Kycmessage: state.Kyc.Kycmessage,
    transactionType: state.Dropdown.dropdowndetails.transactionType,
    AmcDataList: replaceNull(state.Dropdown.dropdowndetails.amc, "NA") == "NA"
      ? []
      : state.Dropdown.dropdowndetails.amc.filter((value) => (value.AMC != "NA")).map((val) => {
        return { label: val.AMC, value: val.ID, isChecked: false };
      }),
    isLoadingScheme: state.Dropdown.isLoadingScheme,
    sipCartData: state.CartReducer.getAllSipCart == "NA" ? [] : state.CartReducer.getAllSipCart.filter((item) =>
      item.hasOwnProperty("IsBasket") ? item.IsBasket == false : true
    ),
    onetimeCartData: state.CartReducer.getAllOnetimeCart == "NA" ? [] : state.CartReducer.getAllOnetimeCart.filter((item) =>
      item.hasOwnProperty("IsBasket") ? item.IsBasket == false : true
    ),
    clientID: state.Login.logindetails.ClientID,
    // l5Report: state.L5report.L5report,
    uccList: state.ProfileDetails.AllProfileDetails?.uccList != undefined && state.ProfileDetails.AllProfileDetails.length != 0 && state.ProfileDetails.AllProfileDetails?.uccList?.length != 0 ? state.ProfileDetails.AllProfileDetails.uccList != "NA" ? state.ProfileDetails.AllProfileDetails.uccList.filter(
      (ele, ind) =>
        ind ===
        state.ProfileDetails.AllProfileDetails.uccList.findIndex((elem) => elem.ClientCode === ele.ClientCode)
    ).map((item) => {
      return { ...item, "Name": item.ClientCode }
    }) : [] : [],
    companyDetails: state.Login.companyDetails,

  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getTransactionDetailsListing,
      GetAllCartValues,
      GetAllCart,
      AddToCart,
      amcOnChange,
      AvailableinvestChange,
      TopTen,
      // getAllDropdownDetails,
      // getKYCActionDetails,
      Filterclick,
      SchemeFilters,
      SchemeDetails,
      // SetPurchaseResponseEmpty,
      NetworkErrorSchemeDetails,
    },
    dispatch
  );
};
export default withStyles(styles)(withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Schemes))
);
