import React, { useState, useEffect, useRef } from "react";
import AppBar from "@material-ui/core/AppBar";
import CalenderImage from "../../utils/assets/images/img13-over.png";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import CardMenu from "../../containers/cardmenu/cardmenu";
import Label from "../../utils/constants/labels";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grid from "@material-ui/core/Grid";
import colors from "../../utils/colors/colors";
import { hexToRgba } from "../../utils/commonfunction";
import Calendar from "../daterangepicker/calender";
import Popover from "@material-ui/core/Popover";
import { bindActionCreators } from "redux";
import moment from "moment";
// icon added by yogitha 25/05/2021
import { getLast12Months, sortArray } from "../../utils/commonfunction";
import {
  dropdownvalue,
} from "../../redux/actions/dropdown/dropdownaction";
import {
  dateChage,
  clientIDchange,
  getEmptyAction,
  TradeUCCDetails,
  accountselection,
  ResetPreventAPICallAction,
  uccSelection
} from "../../redux/actions/login/loginaction";
import { connect } from "react-redux";
import secureStorage from "../../utils/securestorage";
import UccCodePopup from "../popup/popup";
import Button from "../custombutton/button";
import labels from "../../utils/constants/labels";
import { Redirect } from "react-router-dom";
import { NegativeFinding, replaceNull } from "../../utils/commonfunction";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: window.globalConfig.color.PRIMARY,
    // marginTop: '-1.9em',
    // paddingTop: "4.5%",
    height: "100%",
    // marginTop:10,
    width: "100%",
    [theme.breakpoints.down("920")]: {
      // height: "389px",
      width: "100%",
      borderBottomStyle: "none",
    },
  },
  toolbars: {
    flex: 1,
    minHeight: 30,
    alignItems: "flex-start",
    marginLeft: 20,
    marginBottom: 10,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginLeft: "revert",
      padding: "0px 0px 0px 6px",
    },
  },
  titles: {
    position: "relative",
    justifyContent: "flex-start",
    fontSize: 24,
    fontWeight: "bold",
    color: window.globalConfig.color.WHITE,
    paddingTop: "18px 0",
    float: "left",
    // marginLeft: -42,
    // marginTop: -9,
    [theme.breakpoints.down("xs")]: {
      // marginTop: '-3%',
      fontSize: 20,
      paddingBottom: "0%",
      textAlign: "left"
      // marginLeft: -16,
    },

    //Modified by Celsia  {Start}
    // [theme.breakpoints.between('350', '400')]: {
    //   marginTop: '18%',
    //   fontSize: 16,
    //   paddingBottom:"30%"
    // },
    // [theme.breakpoints.between('400', '600')]: {
    //   marginTop: '18%',
    //   fontSize: 21,
    //   paddingBottom:"25%"
    // },
    // [theme.breakpoints.down('350')]: {
    //   marginTop: '24% !important',
    //   fontSize: 16,
    //   paddingBottom:"30%"
    // },
    //  {end}
  },
  subtitles: {
    fontSize: 13,
    // marginLeft: -42,
    color: window.globalConfig.color.cyanBluish,
    // marginTop: "16px",
    margin: 0,
    // paddingTop: "18px",
    // whiteSpace: "nowrap !important",

    [theme.breakpoints.down("xs")]: {
      // whiteSpace: "nowrap !important",
      fontSize: 12,
      margin: 0
      // marginLeft: "-10% !important",
    },
  },
  btnAlignment: {
    display: "grid",
    gridTemplateColumns: "50% 50%",
    alignItems: "center",
    gap: 10
  },
  subtitlesF: {
    fontSize: 14,
    // marginLeft: -20,
    margin: 0,
    textAlign: "left",
    color: window.globalConfig.color.cyanBluish,
    // marginTop: "15px",
    // marginLeft: 3,
    [theme.breakpoints.down("xs")]: {
      // marginLeft: 1,
      // textAlign: 'left',
      // marginTop: 13,
      textAlign: "left",
      margin: 0
      // marginLeft: 0,
      // whiteSpace: 'nowrap !important',
    },
  },
  subtitlesU: {
    fontSize: 20,
    // marginLeft: 5,
    // marginTop: 14,
    color: window.globalConfig.color.WHITE,
    // paddingTop: "18px 0",
    textDecorationLine: "underline",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      cursor: "pointer",
      zIndex: 1,
    },
  },
  tab: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-start",
    // marginLeft:'1vw'
  },

  //  Modified by Celsia  {Start}
  tabresponsiveDiv: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    // marginLeft: "8%",

    [theme.breakpoints.down("900")]: {
      alignItems: "center"
      //   marginTop: "6%",
      //   // margin: '5% 24% 2% 24%',
    },
    // [theme.breakpoints.down("xs")]: {
    //   marginTop: "6%",
    //   marginBottom: "6%",
    //   marginLeft: "-2%",
    //   // margin: '10% 0% 33% 17%',
    // },
  },
  tabresponsive: {
    display: "flex",
    flexDirection: "row",
    // justifyContent: "flex-start",
    alignItems: "center",
    // marginLeft: "8%",
    [theme.breakpoints.down("900")]: {
      marginTop: 10,
      marginLeft: 0,
      textAlign: "left",
      flexDirection: "column",
      alignItems: "flex-start"
      // margin: '5% 24% 2% 24%',
    },
    // [theme.breakpoints.down("xs")]: {
    //   marginTop: "0%",
    //   marginBottom: "6%",
    //   marginLeft: "0%",
    //   // margin: '10% 0% 33% 17%',
    // },
  },
  cardcontainer: {
    width: "76vw",
    [theme.breakpoints.down("xs")]: {
      width: "85vw",
    },
  },
  //  {End}
  card: {
    flex: 1,
    justifyContent: "center",
    paddingTop: 5,
    marginRight: 10,
    // borderRadius: 1,
    // height: '100%',
    [theme.breakpoints.down("xs")]: {
      // paddingTop: 4,
      background: window.globalConfig.color.WHITE,
      width: "100%",
      height: "100%",
      margin: "0",
      padding: "0",
    },
  },
  emptycard: {
    height: '0px !important',
    minHeight: '0 !important',
  },
  menu: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    // marginTop: 15,
    //marginBottom: 20,
    marginLeft: -20,
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "center",
      marginTop: "3%",
      // marginLeft: -20,
    },
    [theme.breakpoints.down("xs")]: {
      flex: 1,
      flexDirection: "row",
      justifyContent: "center",
      marginTop: "7%",
      // marginLeft: -20,
    },
  },
  right: {
    float: "right",
    //paddingTop: "8px !important",
    color: window.globalConfig.color.cyanBluish,
    fontSize: 13,
    marginRight: "6%",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      marginTop: "6%",
    },
    [theme.breakpoints.down("xs")]: {
      color: window.globalConfig.color.cyanBluish,
      fontSize: 13,
      // marginTop: "24%",
      // marginBottom: "6%",
      marginRight: "-8%",
      // marginRight: "-18px",

      // marginRight: "-17px",
    },

    UccAcountBlock: {
      float: "right",
      [theme.breakpoints.down("xs")]: {
        padding: "15px 0px",
      },
    },
  },
  right1: {

    float: "right",
    // paddingTop: "8px !important",
    color: window.globalConfig.color.cyanBluish,
    fontSize: 13,
    marginRight: "6%",
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      marginTop: "6%",
    },
    [theme.breakpoints.down("xs")]: {
      color: window.globalConfig.color.cyanBluish,
      fontSize: 13,
      marginTop: "0%",
      marginBottom: "6%",
      marginRight: "-10%",
    },
  },
  date: {
    //margin: 5,
    fontWeight: "bold",
    fontSize: 12,
    margin: 0,
    padding: 3,
    [theme.breakpoints.down("xs")]: {
      marginTop: " 4%",
      fontWeight: "bold",
      fontSize: 12,
    },
    // [theme.breakpoints.down('350')]: {
    //   marginTop: "3px",
    //   paddingLeft: "20px"
    // },
    // [theme.breakpoints.down('400')]: {
    //   marginTop: "5px",
    //   paddingLeft: "20px"
    // },
    // [theme.breakpoints.between('400', '600')]: {
    //   marginTop: "5px",
    //   paddingLeft: "20px",
    //   fontSize: 14,

    // },
  },
  dateContainer: {
    flex: 1,
  },
  //Modified bu Celsia  {Start}
  calenderimg: {
    float: "right",
    clear: "right",
    marginTop: -3,
    paddingLeft: 3,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 1,
    },
  },
  //  {End}
  popup: {
    boxShadow: "7px 7px 10px 0 rgba(0, 0, 0, 0.1)",
    backgroundColor: window.globalConfig.color.WHITE,
    fontSize: 10,
    // width:'240px',
    width: "150px",
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      width: "150px",
    },
  },
  welcome: {
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: 10,
    marginLeft: 16,
    // marginTop: "-10px"
  },
  link: {
    textDecoration: "none",
    color: window.globalConfig.color.GRAY_DARK,
  },
  handsign: {
    cursor: "pointer",
  },
  rightMenu: {
    float: "right",
    lineHeight: 3,
  },
  protxt: {
    display: "block",
    color: window.globalConfig.color.BLACK_PRIMARY,

  },
  protext: {
    display: "block",
    fontSize: 14,
    color: window.globalConfig.color.BLACK_PRIMARY,
  },
  protextN: {
    display: "block",
    fontSize: 14,
    color: window.globalConfig.color.BROWN,
  },
  username: {
    fontSize: 16,
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontWeight: 500,
  },
  cardSpareaterLine: {
    height: 0.5,
    width: "100%",
    backgroundColor: window.globalConfig.color.PRIMARY_BORDER,
  },


  firstContainer: {
    display: "grid",
    // flexDirection: "row",
    // paddingTop: 15,
    alignItems: "center",
    gridTemplateColumns: "11.5% 40% 40% 8%"
    // [theme.breakpoints.down("md")]: {
    //   marginTop: "1pc",
    // },
    // [theme.breakpoints.down("sm")]: {
    //   marginTop: "1pc",
    // },
    // [theme.breakpoints.down("xs")]: {
    //   marginTop: "revert",
    // },
  },
  secondContainer: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      marginTop: "-3%",
    },
  },

}));
let showUcc = false;
function ToolBar(props) {
  const [name, setname] = useState("");
  const [familyName, setfamilyName] = useState("");
  const [List, setList] = useState([]);

  const classes = useStyles();
  //   (props.mfHeader2)
  const [anchorEl, setAnchorEl] = useState(null);
  const [Clientcode, setClientcode] = useState("");
  const [familymembers, setfamilymembers] = useState("");
  const [uccID, setUccID] = useState(props.ucc)
  const [ucc, setUcc] = useState();
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [account, setAccount] = useState(0);
  const [selectedaccount, setSelectedAccount] = useState("");

  const [date, setDate] = useState(props.date);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDate(props.date)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const dateChange = (val) => {
    let value = moment(val).format("DD MMM YYYY")
    let clientCode = props.logindetails.ClientID
    if (value != props.date) {
      props.dateChage(value);
      setDate(moment(value).format("DD MMM YYYY"))
      secureStorage.setItem("withRedeemChecked", true)
      props.childFunc.current(props.logindetails.SessionID, clientCode, value, props.L5ID, "date")
    }

    setAnchorEl(null);

  };

  const handleL5Click = (event) => {
    setAnchorEl2(event.currentTarget);
  };
  const handleL5Close = () => {
    setAnchorEl2(null);
  };
  const handleUccListClick = (val) => {
    if (ucc == val?.Name) {
    } else {
      setUcc(val?.Name)
      props.uccSelection(val?.Name == "All" ? "" : val?.L5ID)
    }
    handleL5Close();
  };
  const handlelistclick = (val, flag) => {

    setAccount("");

    if (selectedaccount === val.Name) {

    } else {
      setAccount(val.L5ID);
      setSelectedAccount(val.Name);
      // if(props.childFunc!=undefined){
      props.TradeUCCDetails(val.MutualFundUCC)
      props.childFunc.current(props.logindetails.SessionID, props.logindetails.ClientID, props.date, val.L5ID, "L5")
      props.accountselection(val.L5ID)
      // }else{
      // }

    }
    handleL5Close();
  };
  useEffect(() => {
    var session = secureStorage.getItem("user");
    var session_store = replaceNull(session, "NA");
    var family = props.logindetails.FamilyName
    //   (family)
    var color = hexToRgba(window.globalConfig.color.PRIMARY, 0.2)
    var element = document.getElementsByClassName("menu");
    if (element.length != 0) {
      element[0].style.borderBottom = `1px solid ${color}`
      element[0].style.backgroundColor = window.globalConfig.color.PRIMARY
    }

    setfamilyName(family);
    if (session_store != null) {
      setname(session_store.UserName);
    }
    var clientcode = props.mainClientID
    if (props.uccList != null && props.uccList != undefined && props.uccList.length != 0) {
      // setUcc(props.ucc == '' ? props.uccList[0]?.Name : props.uccList.filter((item) => item?.L5ID == props.ucc)[0]?.Name)

      if (ucc != props.ucc) {
        handleUccListClick(props.ucc == '' ? props.uccList[0] : props.uccList.filter((item) => item?.L5ID == props.ucc)[0])
      }
    }
    setClientcode(clientcode)
    var familydetails = props.familydetails
    if (familydetails != null) {
      setfamilymembers([...familydetails]);
    }
    if (props.L5report != null && props.L5report != undefined) {
      if (props.L5report.length != 0) {
        let defaultUcccode = props.L5report
        if (props.L5report.length > 1) {
          showUcc = true

          setList(defaultUcccode);
          if (
            defaultUcccode.filter((item) => item.L5ID === props.L5ID).length != 0
          ) {
            setSelectedAccount(
              defaultUcccode.filter((item) => item.L5ID === props.L5ID)[0].Name
            );
            setAccount(
              defaultUcccode.filter((item) => item.L5ID === props.L5ID)[0].L5ID
            );
          } else {
            setSelectedAccount("Combined")
            setAccount(0)
          }
        } else {
          setSelectedAccount(defaultUcccode[0].Name)
          setAccount(defaultUcccode[0].L5ID)
          showUcc = false;
        }

      } else {
        showUcc = false;
      }
    } else {
      showUcc = false;
    }
    if (props.l5Func != undefined) {
      props.l5Func.current = handlelistclick
    }
  }, [props.L5ID, selectedaccount, account, props.L5report, props.uccList, props.date, props.logindetails.ClientID, props.logindetails.EntityTypeID, props.ucc]);
  try {
    if (props.page == "NA") {
      return (<div></div>)
    } else {
      return (
        <div className={classes.root}>
          <div style={{ paddingTop: 10, paddingBottom: 10, display: "flex", flexDirection: "column", gap: 10 }}>
            <div className={classes.firstContainer}>
              <div></div>
              {props.title == labels.ManuHeading.familyPortfolio ? <div className={classes.tabresponsive}>
                <p className={classes.subtitlesF}>
                  {props.logindetails.FamilyName != "" && props.logindetails.FamilyName != "NA"
                    ? `Family Name : ${props.logindetails.FamilyName}`
                    : ""}
                </p>
              </div> : <div className={classes.tabresponsive}>
                <p className={classes.subtitles}>{props.userName}</p>
                <p className={classes.subtitlesF}>
                  {props.logindetails.FamilyName != "" && props.logindetails.FamilyName != "NA"
                    ? `(${props.logindetails.FamilyName})`
                    : ""}
                </p>
              </div>}
              <div item xs={5}>

                {props.title != labels.menu.trading && <div className={classes.right}>

                  <Typography variant="h3" noWrap style={{ cursor: "pointer" }}>

                    <a onClick={(e) => handleClick(e)}>

                      <p className={classes.date}>

                        {"As on "} {props.date}

                        <img

                          src={CalenderImage}

                          className={classes.calenderimg}

                        />

                      </p>

                    </a>

                  </Typography>

                </div>}

              </div>

              <div></div>




            </div>

            <div className={classes.firstContainer}>

              <div item xs={1}></div>




              <div className={classes.tabresponsiveDiv}>

                <div className={classes.titles}>{props.title}</div>

                {props.title == labels.ManuHeading.familyPortfolio && <UccCodePopup

                  text={props.name}
                  selectedAccount={props.name}

                  handleClick={props.handleMenuClick}

                  List={props.MenuList.map((item) => {

                    return { ...item, Name: item.name, L5ID: item.id }

                  })}
                  handlelistclick={props.handleMenuListClick}

                  handleClose={props.handleMenuClose}

                  AnchorEl={props.anchorEl}

                  Buttonstyle={{

                    fontSize: "13px",

                    color: window.globalConfig.color.cyanBluish,

                    fontWeight: "bold",

                  }}

                />}

              </div>




              {props.title != labels.ManuHeading.familyPortfolio && <div item xs={5}>




                {props.title == Label.menu.trading ? <div className={classes.right1}>

                  {props.uccList.length != 0 && <UccCodePopup

                    text={ucc}

                    // applyUccStyle={{}}

                    selectedAccount={ucc}

                    handleClick={handleL5Click}

                    List={props.uccList}




                    handlelistclick={handleUccListClick}

                    handleClose={handleL5Close}

                    AnchorEl={anchorEl2}

                    Buttonstyle={{

                      fontSize: "13px",

                      color: window.globalConfig.color.cyanBluish,

                      fontWeight: "bold",

                    }}

                  />}

                </div> :

                  props.L5report != null && props.L5report != undefined && props.L5report.length != 0 ? (

                    //  props.L5report.length > 1 && (

                    <div className={classes.right1}>

                      <UccCodePopup

                        text={selectedaccount}

                        selectedAccount={account}

                        handleClick={handleL5Click}

                        List={props.L5report}

                        handlelistclick={handlelistclick}

                        handleClose={handleL5Close}

                        AnchorEl={anchorEl2}

                        Buttonstyle={{

                          fontSize: "13px",

                          color: window.globalConfig.color.cyanBluish,

                          fontWeight: "bold",

                        }}

                      />

                    </div>

                    //)

                  ) : (

                    <div></div>

                  )}




              </div>}

              <div>

                <Popover

                  className="filterpop"

                  open={Boolean(anchorEl)}

                  anchorEl={anchorEl}

                  onClose={handleClose}

                  anchorOrigin={{

                    vertical: "bottom",

                    horizontal: "left",

                  }}

                  transformOrigin={{

                    vertical: "top",

                    horizontal: "left",

                  }}

                >

                  <ClickAwayListener onClickAway={handleClose}>

                    <div style={{ display: "flex", flexDirection: "row", gap: 10, marginBottom: 10 }}>

                      <div style={{ marginTop: 10, fontSize: "smaller" }} >

                        <span style={{ marginTop: 10, marginLeft: 10 }}>Last 12 Months</span>

                        <div style={{ display: "flex", flexDirection: "column", gap: 10, marginTop: 18, marginLeft: 10 }}>

                          {sortArray(getLast12Months(), "id", "DESC").map((item) => {

                            return <span onClick={() => dateChange(item.name)} style={{ borderBottom: "1px dashed", marginTop: 5, cursor: "pointer", textAlign: "center" }}>{item.name}</span>

                          })}

                        </div>

                      </div>

                      <div style={{ zIndex: 0 }}>

                        <div>

                          <Calendar

                            divStyle={{ padding: "0px" }}

                            date={date}

                            calenderpage={props.calenderpage}

                            onDateChange={(value) => dateChange(value)}

                            maxDates={moment().format("DD MMM YYYY")}

                          />
                        </div>

                        <div className={classes.cardSpareaterLine}></div>

                        <div>

                          <div

                            className={classes.btnAlignment}

                            style={{ padding: "8px 15px" }}

                          >

                            <span className="drp-selected">{date}</span>

                            <span style={{ textDecoration: "underline", textAlign: "center", color: window.globalConfig.color.PRIMARY, cursor: "pointer" }} onClick={() => dateChange(moment().format("DD MMM YYYY"))}>Today</span>

                            {/* <Button page="secondary" name={labels.button.cancel} onClick={handleClose}></Button>

                            <Button page="primary" name={labels.button.apply} onClick={() => handleApply()}></Button> */}

                          </div>

                        </div>

                      </div>

                    </div>

                  </ClickAwayListener>

                </Popover>

              </div>

              <div item xs={1}></div>

            </div>

          </div>

          {/* </Toolbar> */}

          {props.data != undefined &&

            <div style={{ display: "grid", gridTemplateColumns: "11% 78% 11%" }} >

              <div></div>

              <div >

                <CardMenu openBenchMarkPopup={props.openBenchMarkPopup} productID={props.productID} flag={props.showCard ? "primary" : ""} style={props.className} className="cardmenu" data={props.data} loader={props.isHeaderLoading || props.loader} search={`${labels.currency.rupeesymbol}`} />

              </div>

              <div></div>

            </div>

          }

          {/* </AppBar> */}

        </div>

      );

    }

  } catch (err) {
    console.log(err)
    return <Redirect to={labels.Path.NotFound}></Redirect>
  }

}
const mapStateToProps = (state) => {
  return {
    date: state.Login.date,
    mfHeader2: state.Card.mfHeader2,
    mfHeader1: state.Card.mfHeader1,
    mutualfund: state.Card.mutualfund,
    equity: state.Card.equity,
    familyName: state.Login.familyName,
    userName: state.Login.changedUserName,
    clientID: state.Login.logindetails.ClientID,
    isfamilyhead: state.Login.isfamilyhead,
    familydetails: state.Login.familydetails,
    logindetails: state.Login.logindetails,
    L5ID: state.Login.L5ID,
    ucc: state.Login.uccAccount,

    uccList: state.ProfileDetails.AllProfileDetails.length != 0 && state.ProfileDetails.AllProfileDetails?.uccList != undefined && state.ProfileDetails.AllProfileDetails?.uccList?.length != 0 ? state.ProfileDetails.AllProfileDetails.uccList != "NA" ? state.ProfileDetails.AllProfileDetails.uccList.length > 1 ? [{ Name: "All", L5ID: "", Value: "" }, ...state.ProfileDetails.AllProfileDetails.uccList.filter(
      (ele, ind) =>
        ind ===
        state.ProfileDetails.AllProfileDetails.uccList.findIndex((elem) => elem.ClientCode === ele.ClientCode)
    ).map((item) => {
      return { ...item, "Name": `${item.DisplayName} ( ${item.ClientCode} )`, "L5ID": item.ClientCode, Value: item.ClientCode }
    })] : [...state.ProfileDetails.AllProfileDetails.uccList.filter(
      (ele, ind) =>
        ind ===
        state.ProfileDetails.AllProfileDetails.uccList.findIndex((elem) => elem.ClientCode === ele.ClientCode)
    ).map((item) => {
      return { ...item, "Name": `${item.DisplayName} ( ${item.ClientCode} )`, "Value": item.ClientCode, "L5ID": item.ClientCode }
    })] : [] : [],
    L5report:
      state.L5report.L5report.length != 0 && state.L5report.L5report != "NA"
        ? state.L5report.L5report.length == 1 ? [...state.L5report.L5report.map((item) => {
          return { ...item, Name: item.PortfolioName };
        })] : [
          { Name: "Combined", L5ID: 0, Value: 0 },
          ...state.L5report.L5report.map((item) => {
            return { ...item, Name: item.PortfolioName, Value: item.L5ID };
          }),
        ]
        : [],
    ProfileDetails:
      state.ProfileDetails.AllProfileDetails.length != 0
        ? state.ProfileDetails.AllProfileDetails.accountDetails != "NA"
          ? state.ProfileDetails.AllProfileDetails.accountDetails.length != 0
            ? state.ProfileDetails.AllProfileDetails.accountDetails.map(
              (item) => {
                return { ...item, Name: ` Account -${item.L5ID}` };
              }
            )
            : []
          : []
        : [],
    isHeaderLoading: state.Card.isHeaderLoading,
    mainClientID: state.Login.mainClientID,
    familydetails: state.Login.familydetails
  };
};
const dispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getEmptyAction,
      dateChage,
      clientIDchange,

      dropdownvalue,

      dateChage,
      // enableLoaderForAllPages,
      accountselection,
      uccSelection,
      TradeUCCDetails,
      ResetPreventAPICallAction
    },
    dispatch
  );
};
export default connect(mapStateToProps, dispatchToProps)(ToolBar)
