import labels from '../../utils/constants/labels';
import apivalues from '../url/apiurl'
import Networking from '../../utils/api/apiaccess';
import moment from 'moment';
import secureStorage from '../../utils/securestorage';
function getxSipOrder(TransactionCode, ClientCode, SchemeCode, InternalRefNo, TransMode, DpTxnMode
  , StartDate, FrequencyType, FrequencyAllowed,
  InstallmentAmount, NoOfInstallment, Remarks, FolioNo, FirstOrderFlag, SubberCode, Euin, EuinVal,
  DPC, XsipRegID, IPAdd, Param1, Param2, Param3,
  SchemeID, ClientID, MandateID, Source) {

  let BaseUrl = apivalues.xSip
  // let BaseUrl="https://ms.zoiftspl.com/Trading/BSEStar/XSIPOrderEntry"
  var IpAddress = secureStorage.getItem('ipaddress');
  let data = {};
  data.TransactionCode = TransactionCode,
    data.ClientCode = ClientCode,
    data.SchemeCode = SchemeCode,
    data.InternalRefNo = InternalRefNo,
    data.TransMode = TransMode,
    data.DpTxnMode = DpTxnMode,
    data.StartDate = StartDate == null ? "" : StartDate.search("/") != -1 ? StartDate : moment(StartDate, "DD MMM YYYY").format("DD/MM/YYYY"),
    data.FrequencyType = FrequencyType,
    data.FrequencyAllowed = FrequencyAllowed,
    data.InstallmentAmount = InstallmentAmount,
    data.NoOfInstallment = NoOfInstallment,
    data.Remarks = Remarks,
    data.FolioNo = FolioNo != "" && FolioNo != undefined && FolioNo.toLowerCase() != "new" ? FolioNo : "",
    data.FirstOrderFlag = FirstOrderFlag,
    data.SubberCode = SubberCode,
    data.Euin = Euin,
    data.EuinVal = EuinVal,
    data.DPC = DPC,
    data.XsipRegID = XsipRegID,
    data.IPAdd = IpAddress,
    data.Param1 = Param1,
    data.Param2 = Param2,
    data.Param3 = Param3,
    data.SchemeID = SchemeID,
    data.ClientID = ClientID,
    data.MandateID = MandateID,
    //data.Brokerage=Brokerage
    data.Source = Source;
  return Networking.PostApi(BaseUrl, data,labels.apiKey.apiKeys).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default getxSipOrder
