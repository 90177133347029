const paymentLabels = {
    Header:{
        Investment:"Investment Details",
        Your:"Your Details",
        Bank:"Bank Details",
        PaymentDetails:"Account Details",
        Payment:"Payment & Confirmation"
    },
    Investment: {
      Link: "Change",
      Category: "Category:",
      Risk: "Risk:",
      AUM: "AUM:",
      Nav: "Nav as on ",
      Header:'Investment Details',
      Sip:"SIP",
      Onetime:"One Time",
      Amount:"Amount",
      ConfirmOrder:"Confirm Order",
      ConfirmSIP:"Confirm SIP",
      Back:"Back to explore page",
      Previous:'Previous'
    },
    Bank:{
        Header:'Account Details',
        DebtBank:'Debt Bank',
        Link:'Add New Bank Mandate',
        MandateID:'Mandate ID :',
        Branch:"Branch Name: ",
        Branchname:"Branch Name",
        Bank:"Bank : ",
        BankAccountType:'Bank Account Type',
        AccountNumber:'Account Number',
        IFSCCode:'IFSC Code',
        Amount:'Amount',
        AmountValidation:"Mandate Amount should be greater or equal to SIP Amount",
        status:'Status',
    },
    Summary:{
        rupeeSymbol:"₹ ",
        Main:"Summary",
        Scheme:"Scheme",
        Header1:"Investor Details",
        Header2:"Scheme Details",
        Folio:'Folio',
        OrderType:"Order Type",
        SIPAmount:'SIP Amount',
        OneTimeAmount:'One Time Amount',
        SIPFrequency:"SIP Frequency",
        SIPStartDate:"Next SIP Date",
        NOOfInstalments:"No. of Instalments",
        TotalSIPAmount:"Total SIP Amount for Month",
        FirstPayment:"First Payment by today",
        TotalOneTimeAmountForMonth:'Total One Time Amount for Month',
        Schemes:"Schemes"
    },
    Payment:{
       Submit:"Transaction Submitted Successfully",
       Faild :"Transaction Failed",
        Order:"Order ID : ",
       Folio:"(Folio: ",
       Header1:"Scheme Details",
       Header2:"Bank Details",
       OrderType:"Order Type",
       SIPAmount:'SIP Amount',
       ONETIMEAmount : 'Amount',
       SIPFrequency:"SIP Frequency",
       SIPStartDate:"Next SIP Date",
       NOOfInstalments:"No. of Instalments",
       FirstPayment:"First Payment is today",
       MandateID:'Mandate ID',
       BankAccountType:'Bank Account Type',
       AccountNumber:'Account Number',
       IFSCCode:'IFSC Code'
    }
  }
  
  
  export default paymentLabels
  
  
