import ApiValues from '../url/apiurl';
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
function getTransactionDropdown() {
    return Networking.GetApi(ApiValues.TransactionDropdown).then(result => {
        return result
    })
    .catch(function (error) {
        return { "status": "F", "message": labels.messages.apierror }
    });
}
export default getTransactionDropdown