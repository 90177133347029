import * as types from "../../constants/actionsType";
import getAllProfitLossDetails from "../../../api/profitloss/profitlossapi";
import constants from "../../../utils/constants/apiconstant";
import { replaceNull } from "../../../utils/commonfunction";
export function getProfitLossDetails(
  Account,
  ClientID,
  CommonDate,
  Date,
  ToDate,
  AccountType,
  AssetClass,
  SessionID,
  Scheme,
  ProductID,
  FamilyID,
  L5ID,
  L4ID,
  flag,YearCode
) {
  return (dispatch) => {
    getAllProfitLossDetailsAction(
      dispatch,
      Account,
      ClientID,
      CommonDate,
      Date,
      ToDate,
      AccountType,
      AssetClass,
      SessionID,
      Scheme,
      ProductID,
      FamilyID,
      L5ID,
      L4ID,
      flag,YearCode
    );
  };
}
export function filterChange() {
  return (dispatch) => {
    dispatch({
      type: types.PROFITLOSS_DATA,
    });
  };
}
function getAllProfitLossDetailsAction(
  dispatch,
  Account,
  ClientID,
  CommonDate,
  Date,
  ToDate,
  AccountType,
  AssetClass,
  SessionID,
  Scheme,
  ProductID,
  FamilyID,
  L5ID,
  L4ID,
  flag,YearCode
) {
  if (ProductID === 1) {
    dispatch({
      type: types.LOADER_PROFIT_LOSS,
      payload: true,
    });
  } else if (ProductID === 2) {
    dispatch({
      type: types.LOADER_EQUITY_PROFIT_LOSS,
      payload: true,
    });
  } else if (ProductID === 3) {
    dispatch({
      type: types.LOADER_BONDS_PROFIT_LOSS,
      payload: true,
    });
  } else if (ProductID === 4) {
    dispatch({
      type: types.LOADER_GB_PROFIT_LOSS,
      payload: true,
    });
  } else if (ProductID === 5) {
    dispatch({
      type: types.LOADER_FD_PROFIT_LOSS,
      payload: true,
    });
  } else if (ProductID === 6) {
    dispatch({
      type: types.LOADER_AIF_PROFITLOSS,
      payload: true,
    });
  }
  else if (ProductID === 7) {
    dispatch({
      type: types.LOADER_REALESTATE_PROFITLOSS,
      payload: true,
    });
  }

  getAllProfitLossDetails(
    Account,
    ClientID,
    CommonDate,
    Date,
    ToDate,
    AccountType,
    AssetClass,
    SessionID,
    Scheme,
    ProductID,
    FamilyID,
    L5ID,
    L4ID,
    YearCode
  )
    .then((res) => {
      let data = replaceNull(res, "NA")
    
        if (data.status == "S" && data.data != "NA") {
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: ProductID, api: "ProfitLoss" }]
          });
          if (ProductID === 1) {
            dispatch({
              type: types.EMPTY_PROFITLOSS,
              payload: [],
            });
            dispatch({
              type: types.GET_PROFITLOSS_DATA,
              payload: data.data.sort((a, b) => {
                var textA = a.Scheme.toUpperCase();
                var textB = b.Scheme.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }),
            });
            // dispatch({
            //   type: types.PREVENT_API_CALL,
            //   payload: "Profit&Loss",
            // });
          } else if (ProductID === 2) {
            dispatch({
              type: types.EMPTY_EQUITY_PROFIT_LOSS,
              payload: [],
            });
            dispatch({
              type: types.EQUITY_PROFIT_LOSS,
              payload: data.data.sort((a, b) => {
                var textA = a.ScripName.toUpperCase();
                var textB = b.ScripName.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }),
            });
            // dispatch({
            //   type: types.PREVENT_API_CALL,
            //   payload: "EquityProfit&Loss",
            // });
          } else if (ProductID === 3) {
            dispatch({
              type: types.EMPTY_BONDS_PROFIT_LOSS,
              payload: [],
            });
            dispatch({
              type: types.BONDS_PROFIT_LOSS,
              payload: data.data.sort((a, b) => {
                var textA = a.ScripName.toUpperCase();
                var textB = b.ScripName.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }),
            });
            // dispatch({
            //   type: types.PREVENT_API_CALL,
            //   payload: "BondsProfit&Loss",
            // });
          } else if (ProductID === 4) {
            dispatch({
              type: types.EMPTY_GB_PROFIT_LOSS,
              payload: [],
            });
            dispatch({
              type: types.GB_PROFIT_LOSS,
              payload: data.data.sort((a, b) => {
                var textA = a.ScripName.toUpperCase();
                var textB = b.ScripName.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }),
            });
            // dispatch({
            //   type: types.PREVENT_API_CALL,
            //   payload: "GBProfit&Loss",
            // });
          } else if (ProductID === 5) {
            dispatch({
              type: types.EMPTY_FD_PROFIT_LOSS,
              payload: [],
            });
            dispatch({
              type: types.FD_PROFIT_LOSS,
              payload: data.data.sort((a, b) => {
                var textA = a.ScripName.toUpperCase();
                var textB = b.ScripName.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }),
            });
            // dispatch({
            //   type: types.PREVENT_API_CALL,
            //   payload: "FDProfit&Loss",
            // });
          } else if (ProductID == 6) {
            dispatch({
              type: types.EMPTY_AIF_PROFITLOSS,
              payload: [],
            });
            dispatch({
              type: types.AIF_PROFITLOSS,
              payload: data.data.sort((a, b) => {
                var textA = a.ScripName.toUpperCase();
                var textB = b.ScripName.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }),
            });
          }
          else if (ProductID == 7) {
            dispatch({
              type: types.EMPTY_REALESTATE_PROFITLOSS,
              payload: [],
            });
            dispatch({
              type: types.REALESTATE_PROFIT_LOSS,
              payload: data.data.sort((a, b) => {
                var textA = a.ScripName.toUpperCase();
                var textB = b.ScripName.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }),
            });
          }
          else if (ProductID == 8) {
            dispatch({
              type: types.EMPTY_NPS_PROFIT_LOSS,
              payload: [],
            });
            dispatch({
              type: types.NPS_PROFIT_LOSS,
              payload: data.data.sort((a, b) => {
                var textA = a.ScripName.toUpperCase();
                var textB = b.ScripName.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }),
            });
          }
          else if (ProductID == 9) {
            dispatch({
              type: types.EMPTY_INSURANCE_PROFIT_LOSS,
              payload: [],
            });
            dispatch({
              type: types.INSURANCE_PROFIT_LOSS,
              payload: data.data.sort((a, b) => {
                var textA = a.ScripName.toUpperCase();
                var textB = b.ScripName.toUpperCase();
                return textA < textB ? -1 : textA > textB ? 1 : 0;
              }),
            });
          }
        } else {
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: ProductID, api: "ProfitLoss" }]
          });
          if (ProductID === 1) {
            dispatch({
              type: types.PROFITLOSS_DATA,
              payload: [],
            });
            // dispatch({
            //   type: types.PREVENT_API_CALL,
            //   payload: "Profit&Loss",
            // });
          } else if (ProductID === 2) {
            dispatch({
              type: types.EMPTY_EQUITY_PROFIT_LOSS,
              payload: [],
            });
            // dispatch({
            //   type: types.PREVENT_API_CALL,
            //   payload: "EquityProfit&Loss",
            // });
          } else if (ProductID === 3) {
            dispatch({
              type: types.EMPTY_BONDS_PROFIT_LOSS,
              payload: [],
            });
            // dispatch({
            //   type: types.PREVENT_API_CALL,
            //   payload: "BondsProfit&Loss",
            // });
          } else if (ProductID === 5) {
            dispatch({
              type: types.EMPTY_FD_PROFIT_LOSS,
              payload: [],
            });
            // dispatch({
            //   type: types.PREVENT_API_CALL,
            //   payload: "FDProfit&Loss",
            // });
          } else if (ProductID === 4) {
            dispatch({
              type: types.EMPTY_GB_PROFIT_LOSS,
              payload: [],
            });

          }
          else if (ProductID === 6) {
            dispatch({
              type: types.EMPTY_AIF_PROFITLOSS,
              payload: [],
            });

          } else if (ProductID === 7) {
            dispatch({
              type: types.EMPTY_REALESTATE_PROFITLOSS,
              payload: [],
            });

          }
          else if (ProductID === 8) {
            dispatch({
              type: types.EMPTY_NPS_PROFIT_LOSS,
              payload: [],
            });

          } else if (ProductID === 9) {
            dispatch({
              type: types.EMPTY_INSURANCE_PROFIT_LOSS,
              payload: [],
            });

          }
        }
     
    })
    .catch((error) => {
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: ProductID, api: "ProfitLoss" }]
      });
      if (ProductID === 1) {
        dispatch({
          type: types.EMPTY_PROFITLOSS,
          payload: [],
        });
        // dispatch({
        //   type: types.PREVENT_API_CALL,
        //   payload: "Profit&Loss",
        // });
      } else if (ProductID === 2) {
        dispatch({
          type: types.EMPTY_EQUITY_PROFIT_LOSS,
          payload: [],
        });
        // dispatch({
        //   type: types.PREVENT_API_CALL,
        //   payload: "EquityProfit&Loss",
        // });
      } else if (ProductID === 3) {
        dispatch({
          type: types.EMPTY_BONDS_PROFIT_LOSS,
          payload: [],
        });
        // dispatch({
        //   type: types.PREVENT_API_CALL,
        //   payload: "BondsProfit&Loss",
        // });
      } else if (ProductID === 5) {
        dispatch({
          type: types.EMPTY_FD_PROFIT_LOSS,
          payload: [],
        });
        // dispatch({
        //   type: types.PREVENT_API_CALL,
        //   payload: "FDProfit&Loss",
        // });
      } else if (ProductID === 4) {
        dispatch({
          type: types.EMPTY_GB_PROFIT_LOSS,
          payload: [],
        });

      }
      else if (ProductID === 6) {
        dispatch({
          type: types.EMPTY_AIF_PROFITLOSS,
          payload: [],
        });

      }
      else if (ProductID === 7) {
        dispatch({
          type: types.EMPTY_REALESTATE_PROFITLOSS,
          payload: [],
        });

      }
      else if (ProductID === 8) {
        dispatch({
          type: types.EMPTY_NPS_PROFIT_LOSS,
          payload: [],
        });

      } else if (ProductID === 9) {
        dispatch({
          type: types.EMPTY_INSURANCE_PROFIT_LOSS,
          payload: [],
        });

      }
      //  ("erroraaaaa", error)
    });
}
export const profitandlossfilter = (datas) => {
  //    ("FILTER_PROFIT_PROFIT",datas)
  return {
    type: types.FILTER_PROFIT_PROFIT,
    payload: datas,
  };
};
export const equityprofitandlossfilter = (datas) => {
  //    ("FILTER_PROFIT_PROFIT",datas)
  return {
    type: types.FILTER_EQUITY_PROFIT_PROFIT,
    payload: datas,
  };
};
export const bondsprofitandlossfilter = (datas) => {
  //    ("FILTER_PROFIT_PROFIT",datas)
  return {
    type: types.FILTER_BONDS_PROFIT_PROFIT,
    payload: datas,
  };
};
export const Fdprofitandlossfilter = (datas) => {
  //    ("FILTER_PROFIT_PROFIT",datas)
  return {
    type: types.FILTER_FD_PROFIT_PROFIT,
    payload: datas,
  };
};
export const Gbprofitandlossfilter = (datas) => {
  //    ("FILTER_PROFIT_PROFIT",datas)
  return {
    type: types.FILTER_GB_PROFIT_PROFIT,
    payload: datas,
  };
};
export const RealEstateprofitandlossfilter = (datas) => {
  return {
    type: types.FILTER_REALESTATE_PROFIT_PROFIT,
    payload: datas,
  };
};
export const aifProfitandlossfilter = (datas) => {
  //    ("FILTER_PROFIT_PROFIT",datas)
  return {
    type: types.FILTER_AIF_PROFIT_PROFIT,
    payload: datas,
  };
};
export const npsProfitandlossfilter = (datas) => {
  //    ("FILTER_PROFIT_PROFIT",datas)
  return {
    type: types.FILTER_NPS_PROFIT_PROFIT,
    payload: datas,
  };
};
export const insuranceProfitandlossfilter = (datas) => {
  //    ("FILTER_PROFIT_PROFIT",datas)
  return {
    type: types.FILTER_INSURANCE_PROFIT_PROFIT,
    payload: datas,
  };
};
//save
export const GetProfitLoss = (data) => {
  return {
    type: types.GET_PROFIT_LOSS,
    data,
  };
};
