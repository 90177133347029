import React from 'react';
import { ThemeProvider, makeStyles,createTheme } from "@material-ui/core/styles";
const theme = createTheme({
  typography: {
    fontFamily: "Inter",
  },
 
  
});
function Theme(props) {
  return (
    <ThemeProvider theme={theme}>
   {props.children}
     </ThemeProvider>
 );
}
export default Theme;
