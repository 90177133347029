import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
/* name should be camel case in all function */
function GetSchemeList(data) {
    //   let data={};
    //   data.SessionID=sessionID
    //   data.ClientID=clientID
    //   data.L5ID=l5
    //   data.L4ID=l4
    //   data.ProductID=productID
    //   data.FamilyID=familyID

    return Networking.PostApi(ApiValues.GetSchemeDetails, data).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });




}
export default GetSchemeList