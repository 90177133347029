import React, { useEffect } from 'react';
import SmallHeader from './smallheader'
import BigText from './bigtext';
import Grid from '@material-ui/core/Grid';
import Header from './header';
import SmallText from './smalltext';
import Label from '../../utils/constants/paymentlabels'
import CommonLabel from '../../utils/constants/labels'
import Dropdown from '../dropdown/dropdownschemes'
// import MobileDropdown from '../tradingdropdown/bankdropdown'
import Popup from '../mandate/mandatepopup'
import { getMandateList } from '../../redux/actions/dropdown/dropdownaction'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MandateStatusCheck from '../../api/mandate/mandatestatuscheck'
import secureStorage from '../../utils/securestorage'
import CommonSnackbar from '../../components/snackbar/snackbar'
import color from "../../utils/colors/colors";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';
import { Redirect } from 'react-router';
import InformationTooltip from '../tooltip/informationtooltip';
import TurnedInNotIcon from '@material-ui/icons/TurnedInNot';
import TurnedInIcon from '@material-ui/icons/TurnedIn';
import { TrendingUpRounded } from '@material-ui/icons';
import NoData from '../nodata/nodata';
import labels from '../../utils/constants/labels';
import { NegativeFinding } from '../../utils/commonfunction';
import colors from '../../utils/colors/colors';
import { scrollToTopPage } from '../../utils/commonfunction';
const styles = (theme) => ({
    dropdownDesign: {
        //    width: "70%"
        display: "grid",
        gridTemplateColumns: "50% 50%",
        gap: 10,
        [theme.breakpoints.down('920')]: {
            alignItems: "center"
        }

    },
    link: {
        paddingTop: 15,
        color: window.globalConfig.color.swatch,
        textTransform: 'capitalize',
        textDecoration: 'underline',
        fontSize: 14,
        cursor: "pointer",
        // width: 154,
        // [theme.breakpoints.down('600')]: {
        //     marginTop: 45,
        // }
    },
    gridContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        backgroundColor: "rgb(255, 251, 243)",
        borderRadius: "10px",
        padding: 10,
        margin: 10,
        gap: 10,
        gridTemplateAreas: `"${"firstGrid"} ${"firstGrid"} ${"secondGrid"}"
        "${"thirdGrid"} ${"thirdGrid"} ${"thirdGrid"}"
        "${"fourthGrid"} ${"fifthGrid"} ${"sixthGrid"}"
        "seventhGrid eighthGrid ."
       `,
        "& .firstGrid": {
            textAlign: "left"
        },
        "& .secondGrid": {
            display: "flex",
            flexDirection: "row"
        },
        "& .thirdGrid": {
            display: "flex",
            flexDirection: "row"
        },
    },
    statusOfMandate: {
        textAlign: "left",
        paddingLeft: 10,
        paddingBottom: 10,
        color: colors.red, fontSize: 14
    },
    firstGrid: {
        gridArea: "firstGrid"
    },
    secondGrid: {
        gridArea: "secondGrid"
    },
    thirdGrid: {
        gridArea: "thirdGrid"
    },
    fourthGrid: {
        gridArea: "fourthGrid"
    },
    fifthGrid: {
        gridArea: "fifthGrid"
    },
    sixthGrid: {
        gridArea: "sixthGrid"
    },
    seventhGrid: {
        gridArea: "seventhGrid"
    },
    eighthGrid: {
        gridArea: "eighthGrid"
    },
    accountDetailsContainer: {
        display: "grid", gridTemplateColumns: "90% 10%", padding: "18px",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "100%"
        }
    }


})
// const User = secureStorage.getItem("user");
class PaymentDetails extends React.Component {
    constructor() {
        super();
        this.state = {

        }
    }
    componentDidMount() {

    }
    componentDidUpdate() {

    }
    render() {
        const { classes, User } = this.props
        let options = this.props.mandateFullListing.filter((value) => value.UCC == this.props.data?.Ucc && value.MandateId != "BSE000000972213").map((item) => {

            return { "label": `${item.MandateId}-${item.BankName}`, "value": item.MandateId }

        })
        return (<>
            <div className={classes.accountDetailsContainer}>
                <div style={{ gap: 10, display: "flex", flexDirection: "column" }}>
                    <SmallHeader text={Label.Bank.Header} />

                    <BigText text={Label.Summary.Header1} size="15px" />
                    <SmallText text={User.UserName != ""
                        ? `${User.UserName || labels.messages.NA}`
                        : ""} weight="500" size="15px" />
                    <BigText text={labels.payment.bseMfAccount} size="15px" />
                    <SmallText text={`${this.props.uccList?.filter((item) => item.ClientCode == this.props.data?.Ucc).length == 0 ? "NA" : this.props.uccList?.filter((item) => item.ClientCode == this.props.data?.Ucc)[0]?.DisplayName} ( ${this.props.data?.Ucc} )` || labels.messages.NA} weight="500" size="14px" />

                    {this.props.data.InvesmentType == "Sip" &&
                        <div className={classes.dropdownDesign}>
                            <Dropdown type={"normalwithsearch"}
                                label={labels.Mandate.MandateAccount}
                                autoselect={false}
                                id={"paymentdetails1"}
                                value={this.props.mandateAccountNumber}
                                List={options}
                                placeholder={labels.payment.selectMandate}
                                error={this.props.errorMandateMessage}
                                handleOnChange={this.props.onMandateChange}
                            />
                            <span className={[classes.link, "cursor"].join(' ')} onClick={this.props.mandateOpenAndClosePopup} >{Label.Bank.Link}</span>
                        </div>
                    }
                    <Popup open={this.props.open}
                        close={this.props.mandateOpenAndClosePopup}
                        popupName={""}
                        fillMandate={this.props.onMandateChange}
                        mainName={'sip&onetime'}
                        mandateResponseCallBack={this.props.onSuccess}
                        bankname={""}
                        bankid={""}
                        displayName={`${this.props.uccList?.filter((item) => item.ClientCode == this.props.data?.Ucc)[0]?.DisplayName ?? "NA"} ( ${this.props.data?.Ucc} )`}
                        UCC={this.props.data.Ucc}
                        flag={"payment"}
                        AccountType={""}
                        accountnumber={""}
                        ifsc={""}
                    />
                    {this.props.loader ?
                        <Skeleton style={{ height: 250, width: "97%", margin: "auto" }} ></Skeleton>
                        :
                        this.props.showBankDetails &&

                        <React.Fragment >
                            {this.props.mandateDetails.map((item) => {
                                let amount = item?.amount != "NA" ? Number(item?.amount) : 0
                                // console.log(amount)
                                return (
                                    <div style={{ backgroundColor: window.globalConfig.color.RosyBrown }} className={classes.gridContainer}>
                                        <div className={[classes.firstGrid, "firstGrid"].join(" ")}>
                                            <Header text={item.bankName || labels.messages.NA} />
                                        </div>
                                        <div className={[classes.secondGrid, "secondGrid"].join(" ")}>
                                            <Header text={Label.Bank.MandateID} />
                                            <Header text={item.mandateId || labels.messages.NA} />
                                        </div>
                                        <div className={[classes.thirdGrid, "thirdGrid"].join(" ")}>
                                            <BigText weight="normal" text={Label.Bank.Branch} size="14px" />
                                            <SmallText weight="normal" size="14px" text={item.bankBranch || labels.messages.NA} />
                                        </div>
                                        <div className={[classes.fourthGrid, "fourthGrid"].join(" ")}>
                                            <BigText text={Label.Bank.BankAccountType} size="14px" />
                                            <SmallText weight="normal" text={item.AccountType || labels.messages.NA} size="14px" />
                                        </div>
                                        <div className={[classes.fifthGrid, "fifthGrid"].join(" ")}>
                                            <BigText text={Label.Bank.AccountNumber} size="14px" />
                                            <SmallText weight="normal" text={item.bankAccNo || labels.messages.NA} size="14px" />
                                        </div>

                                        <div className={[classes.sixthGrid, "sixthGrid"].join(" ")}>
                                            <BigText text={Label.Bank.IFSCCode} size="14px" />
                                            <SmallText weight="normal" text={item.IFSC_Code || labels.messages.NA} size="14px" />
                                        </div>
                                        <div className={[classes.seventhGrid, "seventhGrid"].join(" ")}>

                                            <BigText text={Label.Bank.Amount} size="14px" />
                                            <SmallText weight="normal" text={(amount != 'NA') ? NegativeFinding(amount, "", "", "", "rupee") : "0"}
                                                size="14px" style={{ textAlign: 'left' }} />

                                        </div>
                                        <div className={[classes.eighthGrid, "eighthGrid"].join(" ")}>
                                            <BigText text={Label.Bank.status} size="14px" />
                                            <SmallText weight="normal" text={item.status || labels.messages.NA} size="14px" />
                                        </div>


                                    </div>
                                )
                            })}
                            <div className={classes.statusOfMandate}>{this.props.validationMessage}</div>
                        </React.Fragment >
                    }
                </div>
                <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                    {this.props.data?.isFromCart && <>
                        {!this.props.showSaveForLater ?
                            <InformationTooltip title="Save For Later" Icon={<TurnedInNotIcon fontSize="medium" style={{ color: window.globalConfig.color.BROWN, cursor: "pointer" }} onClick={this.props.saveForLater}></TurnedInNotIcon>
                            }>
                            </InformationTooltip>

                            :
                            <InformationTooltip title="Saved" Icon={<TurnedInIcon fontSize="medium" style={{ color: window.globalConfig.color.BROWN, }}></TurnedInIcon>
                            }>
                            </InformationTooltip>
                        }</>}
                </div>





            </div>

        </>

        )
    }
}
const mapStateToProps = state => {
    return {
        bankList: state.ProfileDetails.AllProfileDetails?.bankDetails == labels.messages.NA ? [] : state.ProfileDetails.AllProfileDetails?.bankDetails?.filter((value) => value.BankName != "").map((item) => {

            return { "label": item.BankName, "value": item.ID }

        }),
        bankListing: state.ProfileDetails.AllProfileDetails.bankDetails == labels.messages.NA ? [] : state.ProfileDetails.AllProfileDetails.bankDetails,
        mandateListing: state.ProfileDetails.AllProfileDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.mandateDetails == labels.messages.NA ? [] : state.ProfileDetails.AllProfileDetails.mandateDetails.filter((value) => value.BankName != labels.messages.NA).map((item) => {

            return { "label": `${item.MandateId}-${item.BankName}`, "value": item.MandateId }

        }) : [],
        mandateFullListing: state.ProfileDetails.AllProfileDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.mandateDetails == labels.messages.NA ? [] : state.ProfileDetails.AllProfileDetails.mandateDetails.filter((value) => value.BankName != labels.messages.NA) : [],

        L5List: state.ProfileDetails.AllProfileDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.accountDetails != labels.messages.NA ? state.ProfileDetails.AllProfileDetails.accountDetails.map((item) => {
            return { "label": ` Account -${item.L5ID}`, "value": item.L5ID }
        }) : [] : [],
        BSEMFAccountList: state.L5report.L5report != null && state.L5report.L5report != undefined && state.L5report.L5report != labels.messages.NA && state.L5report.L5report.length != 0 ? [...state.L5report.L5report.filter((value) => value.MutualFundUCC != labels.messages.NA).map((item) => {
            return {
                "label": `${item.PortfolioName}(${item.MutualFundUCC})`, "value": item.L5ID
            }
        })] : [],
        uccList: state.ProfileDetails.AllProfileDetails.length != 0 && state.ProfileDetails.AllProfileDetails?.uccList != undefined && state.ProfileDetails.AllProfileDetails?.uccList?.length != 0 ? state.ProfileDetails.AllProfileDetails.uccList != "NA" ? state.ProfileDetails.AllProfileDetails.uccList.filter(
            (ele, ind) =>
                ind ===
                state.ProfileDetails.AllProfileDetails.uccList.findIndex((elem) => elem.ClientCode === ele.ClientCode)
        ).map((item) => {
            return { ...item, "Name": item.ClientCode }
        }) : [] : [],
        User: state.Login.logindetails
    }
}
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getMandateList }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(PaymentDetails))
