import ApiValues from '../url/apiurl'
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
var axios = require('axios');
function getAIFandPMSList(ID,ClientID,AssetClassCode) {
    var raw = JSON.stringify({
        "ID": ID,
        "ClientID": ClientID,
        "AssetClassCode": AssetClassCode,
       
    });

    return Networking.PostApi(ApiValues.AIFandPMSList,raw).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default getAIFandPMSList
