import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl';
import { EncryptClientID, Encryption } from '../../utils/commonfunction';
var axios = require('axios')
import Networking from '../../utils/api/apiaccess';
function getProfileClientDetails(ClientID, sessionID) {
    let encryptClientID = Encryption(ClientID.toString())
    return Networking.GetApi(ApiValues.ClientData + encryptClientID, "", sessionID).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });
   
}
export default getProfileClientDetails