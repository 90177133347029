import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import colors from "../../utils/colors/colors";
import labels from "../../utils/constants/labels";
// import { hasNull } from '../../utils/commonfunction';
const useStyles = makeStyles((theme) => ({
  input: {
    height: "28px",
    paddingLeft: "10px !important",
    "&hover": {
      border: "0px",
    },
  },
 
  root: {
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    minHeight:43,
    '&:focus-within': {
      border: `1px solid ${window.globalConfig.color.BROWN}!important`,
    }
  },
  option: {
    boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
    //backgroundColor:"red"
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
  },
  noOptions: {
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
  },
  dropBoxLabel: {
    paddingBottom: 6,
    fontWeight: "normal",
    display: "block",
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: "14px",
    textAlign: "left",
  },
}));
export default function AutoComplete(props) {
  const [value, setValue] = React.useState(props.value);
  const [text, setText] = React.useState(null);

  useEffect(() => {
    // Update the document title using the browser API
    setValue(props.value);
  }, [props.value]);
  const handleChange = (event) => {
    //
    setText(event);
    if(props.onChange!=undefined){
      props.onChange(event);
    }
  };
  const classes = useStyles();
  //
  return (
    <React.Fragment>
      {props.label != undefined && props.label != "" ? (
        <label className={classes.dropBoxLabel}>{props.label}</label>
      ) : (
        ""
      )}
      {/* <div className={classes.borderColorBox}> */}
      <Autocomplete
        value={value}
        classes={{
          input: classes.input,
          noOptions: classes.noOptions,
          listbox: classes.option,
          root: classes.root,
        }}
        onChange={(event, newValue) => {
          if (newValue == null) {
            setText(newValue);
          } else {
            setText(newValue.label);
          }
          setValue(newValue);
          if (props.selectvalue != undefined) {
            props.selectvalue(newValue);
          }
        }}
        // filterOptions={(options, params) => {
        //     const filtered = filter(options, params);

        //     if (params.inputValue !== '') {
        //         filtered.push({
        //             inputValue: params.inputValue,
        //             title: `Add "${params.inputValue}"`,
        //         });
        //     }

        //     return filtered;
        // }}
        // id="free-solo-dialog-demo"
        options={props.default?props.options:text != null ? (props.default||text.length >= 3 ? props.options : []) : []}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === "string") {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option.label;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        popupIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 0 24 24"
            width="24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
          </svg>
        }
        renderOption={(option) => <li>{option.label}</li>}
        sx={{ width: 300 }}
        //freeSolo
        noOptionsText={
            props.default?<div>{labels.messages.Dataempty}</div>:
          <div>
            {props.Loading ? (
              <div>{labels.messages.loading}</div>
            ) : text == null || text.length < 3 ? (
              <div>
                {props.startingmessage === undefined
                  ? labels.messages.searchStocks
                  : props.startingmessage}
              </div>
            ) : (
              props.options.length == 0 && <div>{labels.messages.Dataempty}</div>
            )}
          </div>
        }
        renderInput={(params) => (
          <TextField
            {...params}
            //   label="Free solo dialog"
            // style={{paddingLeft:8}}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
            }}
            placeholder={props.placeholder}
            onChange={(e) => handleChange(e.target.value)}
          />
          
        )}
      />
      {/* </div> */}
    </React.Fragment>
  );
}
