import React from 'react';
import { TradeL5IDDetails } from '../../redux/actions/login/loginaction'
import { changeUcccode } from '../../redux/actions/profile/profile'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { makeStyles, withStyles } from '@material-ui/core/styles'
import colors from '../../utils/colors/colors';
import CheckIcon from '@material-ui/icons/Check';
import NoData from '../nodata/nodata';
import labels from '../../utils/constants/labels';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import DialogBox from '../dialog/dialog';
import Networking from '../../utils/api/apiaccess';
import getAlertTemplate from '../../api/alert/viewtemplate';
import CommonSnackbar from "../../components/snackbar/snackbar";
import { loaderAlert } from '../../redux/actions/alert/alertaction';
import CircularProgress from '@material-ui/core/CircularProgress';
import pdfImage from "../../utils/assets/images/pdf-file-format-symbol-2.png";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import InformationTooltip from '../tooltip/informationtooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
import getAllcommonDownloadDetails from '../../api/commondownload/commondownload';
import Pagination, { MContext } from '../pagination/renderpage';
import apivalues from '../../api/url/apiurl'
const styles = (theme) => ({
    grid: {
        display: "grid",
        gridTemplateColumns: "5% 77% 18%",
        borderBottom: `1.5px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        paddingBottom: "1.5%",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 12,
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(2,1fr)",

        }
    },
    row: {
        display: "flex",
        flexDirection: "column",
        gap: 15
    },
    mainHeaderMessage: {
        fontSize: "large",
        fontWeight: "bold", textAlign: "left",
        color: window.globalConfig.color.BLACK_PRIMARY
    },
    subHeaderMessage: {
        fontSize: "14px",
        textAlign: "left",
        color: window.globalConfig.color.LABLE_GRAY,
        fontWeight: 500
    },
    message: {
        fontSize: "smaller",
        color: window.globalConfig.color.LABLE_GRAY
    },
    badge: {
        color: window.globalConfig.color.WHITE,
        borderRadius: 52,
        height: 25,
        width: 25,
    },
    badgeBackground: {
        // backgroundColor: (p) => p.status == "success" ? window.globalConfig.color.LIGHT_GREEN_BACKGROUND : window.globalConfig.color.WARNING,
        borderRadius: 52,
        height: 40,
        width: 40,
        display: "flex",
        justifyContent: "center",
        // marginLeft: "21%",
        alignItems: "center"
    },
    header: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: "white",
        height: 50,
        fontWeight: 500
    },
    txtHeader: {
        padding: 15,
        position: "sticky",
        top: "0px"
    },
    clearIcon: {
        float: 'right',
        cursor: 'pointer'
    },
    viewContainer: {
        display: "flex", justifyContent: "flex-end",
        [theme.breakpoints.down("920")]: {
            justifyContent: "flex-start"
        }
    },
    root: {
        "& .MuiDialogContent-root": {
            padding: "0px !important"
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "none !important",
            width: "76% !important",
            overflow: "hidden"
        },
        "& .MuiDialogContent-root": {
            overflowY: "hidden",
            padding: "0px !important"
        }
    },
    outlineButton: {
        width: 109, cursor: "pointer", height: 39, alignItems: "center", borderRadius: 5, justifyContent: "center", border: `1px solid ${window.globalConfig.color.GREEN}`, display: "flex", flexDirection: "column", color: window.globalConfig.color.GREEN, fontWeight: 'bold'
    }

})
let icon = [{ name: ".pdf", Image: <img src={pdfImage} style={{ width: 22 }}></img> }, { name: "others", Image: <AttachFileIcon></AttachFileIcon> }]
class NotificationList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openReviewHtml: false,
            htmlContent: "",
            header: "",
            open: "",
            message: "",
            severity: "warning", fileList: [],
            loader: false
        }
    }
    componentDidMount() {

    }
    handleClose = () => {
        this.setState({ openReviewHtml: false })
    }
    onViewClick = (item) => {
        let data = {
            id: item.ID,
            loader: true
        }
        item.loader = true
        this.props.loaderAlert(data)
        this.setState({ htmlContent: [], fileList: [] })
        getAlertTemplate(item.ID, this.props.logindetails.ClientID).then((response) => {
            data = {
                id: item.ID,
                loader: false
            }
            item.loader = false
            this.props.loaderAlert(data)
            if (response.status == "S") {
                this.setState({ htmlContent: response.data.htmlContent, fileList: response.fileList != null ? response.fileList : [] })

                this.setState({ openReviewHtml: true, header: item.statusText })
            } else {
                this.setState({ open: true, message: response.message })
            }
        })
    }
    handlesnanckClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }
        this.setState({ open: false });
    };
    dowloadAttachment = (item) => {
        let url = `${apivalues.downloadAttachments}?ID=${item.value}&fileName=${item.text}`

        this.setState({ openReviewHtml: false })
        getAllcommonDownloadDetails(url, item.extension?.split(".")[1], item.text, this.props.logindetails?.SessionID).then((res) => {

            if (res == "success") {
                this.setState({ open: true, message: "File downloaded successfully", severity: "success" })
            } else {
                this.setState({ open: true, message: res.message })
            }
        })
    }
    render() {
        const { classes } = this.props
        return (
            <>

                {this.props.List.length != 0 ?
                    <Pagination data={this.props.List}>
                        <MContext.Consumer>
                            {(context) => {
                                return context.map((item) => {

                                    return <div className={classes.grid}>
                                        <div className={classes.badgeBackground} style={{
                                            backgroundColor: item.status.includes("Success") ? window.globalConfig.color.LIGHT_GREEN_BACKGROUND : window.globalConfig.color.RosyBrown
                                        }}>
                                            <div className={classes.badge} style={{
                                                backgroundColor: item.status.includes("Success") ? window.globalConfig.color.GREEN : window.globalConfig.color.WARNING
                                            }}>

                                                {item.status.includes("Success") ?
                                                    <CheckIcon></CheckIcon> : <PriorityHighIcon></PriorityHighIcon>}
                                            </div>
                                        </div>
                                        <div className={classes.row}>
                                            <div className={classes.mainHeaderMessage}>{item.statusText}</div>
                                            {/* <div className={classes.subHeaderMessage}>{item.statusDescription}</div> */}
                                            <div className={classes.subHeaderMessage}>{item.days}</div>

                                        </div>
                                        <div className={classes.viewContainer}>{<div className={classes.outlineButton} style={{ cursor: item.loader ? "not-allowed" : "pointer", opacity: item.loader ? 0.5 : 1 }} onClick={() => { this.onViewClick(item) }}>
                                            View
                                            {item.loader && <CircularProgress size={20} thickness={1} style={{ color: window.globalConfig.color.GREEN, position: "absolute" }} />}

                                        </div>}</div>

                                    </div>
                                })
                            }}
                        </MContext.Consumer>
                    </Pagination>

                    : <NoData></NoData>}
                <DialogBox root={classes.root} open={this.state.openReviewHtml} handleClose={this.handleClose}>
                    <div className={classes.header}>
                        <div className={classes.txtHeader}>{this.state.header}
                            <span className={classes.clearIcon} onClick={this.handleClose}>&#x2715;</span>
                        </div>
                    </div>
                    <div style={{ padding: "10px", overflowY: "auto", height: 350 }}>
                        <div dangerouslySetInnerHTML={{ __html: this.state.htmlContent }} />

                    </div>
                    {this.state.fileList.length != 0 && <footer>
                        <span style={{ marginTop: 10, marginBottom: 10 }}>Attachments</span>
                        <div style={{ display: "grid", gridTemplateColumns: "repeat(5,1fr)", gap: 10, marginLeft: 10, marginBottom: 10 }}>

                            {this.state.fileList.map((item) => {

                                return <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 10, width: "fit-content", padding: 10, borderRadius: 5, boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px" }}>
                                    <div>{icon.filter((val) => val.name == item.extension).length != 0 ? icon.filter((val) => val.name == item.extension)[0].Image : icon[1].Image} </div>
                                    {<InformationTooltip type="trim" title={item.text} length={15}></InformationTooltip>}
                                    <GetAppIcon style={{ cursor: "pointer" }} onClick={() => this.dowloadAttachment(item)}></GetAppIcon>
                                </div>

                            })}
                        </div>
                    </footer>}
                </DialogBox>
                <CommonSnackbar
                    open={this.state.open}
                    severity={this.state.severity}
                    handlesnanckClose={this.handlesnanckClose}
                    message={this.state.message}
                />
            </>
        )
    }
}
const mapStateToProps = state => ({
    TradingL5ID: state.Login.TradingL5ID,
    ProfileDetails: state.L5report.L5report.length != 0 && state.L5report.L5report != "NA"
        ? [...state.L5report.L5report.map((item) => {
            return { ...item, "Name": item.PortfolioName }
        })] : []
    ,
    L5report: state.L5report.L5report != null && state.L5report.L5report != undefined && state.L5report.L5report != "NA" && state.L5report.L5report.length != 0 ? [...state.L5report.L5report.filter((item) => item.L4_AccountTypeID != 3)?.map((item) => {
        return { ...item, "Name": item.PortfolioName }
    })] : []
    ,
    JointHolder: state.ProfileDetails.AllProfileDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.jointHolderDetails != "NA" ? state.ProfileDetails.AllProfileDetails.jointHolderDetails : [] : [],
    logindetails: state.Login.logindetails,
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ changeUcccode, TradeL5IDDetails, loaderAlert }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NotificationList))

