import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
function getKYCDetails(mobile, email,pan) {
  
  let data = {};
  data.MobileNo=mobile;
  data.EmailID=email;
  data.PANNo=pan;

  // var config = {
  //   method: 'post',
  //   url:ApiValues.KycLink
  //   ,
  //   headers: { 
  //     'Content-Type': 'application/json'
  //   },
  //   data : data
  // };
  
  
  return Networking.PostApi(ApiValues.KycLink,data,labels.apiKey.apiKeys).then(result => { return result })
  .catch(function (error) {
    return { "status": "F", "message": labels.messages.apierror }
  });
  // return axios(config)
  // .then(function (response) {
  // return response.data;
  // })
  // .catch(function (error) {
  //   return { "status": "F", "message": labels.messages.apierror }
  // });


  // fetch method ends here

}
export default getKYCDetails
