import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import combineStyle from "../../utils/stylesheets/combainstyle/combainstyle";
import commonstyletable from "../../utils/stylesheets/table/commontablestyle";
import GetInsuranceReport from '../../api/insurance/insurance_report';
import { NegativeFinding, replaceNull } from '../../utils/commonfunction';
import '../../utils/stylesheets/tabs/tabs.css'
import secureStorage from '../../utils/securestorage';
import Toolbar from '../../components/toolbar/toolbar';
import labels from '../../utils/constants/labels';
// import {
//   bondsFilterChange,
// } from "../../redux/actions/portfolioxray/portfolioxray";
import { InsuranceFilterChange } from '../../redux/actions/portfolioxray/portfolioxray';
import { bindActionCreators } from "redux";
import EPFReport from '../epf/epfreport';
import getEPFReport from '../../api/epf/epfreport';
import { EpfList } from '../../redux/actions/epf/epfaction';
function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                // <Typography>{children}</Typography>      
                <div
                    style={{
                        fontSize: '1rem',
                        fontWeight: 400,
                        lineHeight: 1.5,
                        letterSpacing: '0.00938rem',
                    }}
                >{children}</div>

            )}
        </div>
    );
}
TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};
function a11yProps(index) {
    return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
}
const Styles = (theme) => ({
    root: {
        flexGrow: 1,
        width: '100%',
        // backgroundColor: theme.palette.background.paper,
    },
    tabRoot: {
        "& .MuiTab-root:focus": {
            color: window.globalConfig.color.BROWN
        },
        "& .MuiTab-textColorPrimary.Mui-selected": {
            color: window.globalConfig.color.BROWN
        }
    },
    portfolioDetails: {
        display: "grid",
        gridTemplateColumns: "repeat(5,1fr)",
        gap: 10,
        [theme.breakpoints.down("920")]: {

            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateAreas: `"${"dataOne"} ${"dataOne"} ${"dataFive"}"
            "${"dataThree"} ${"dataFour"} ${"dataTwo"}"
           `,
            padding: 10,
            gap: 10,
            "& .dataTwo": {
                alignItems: "end"
            },
            "& .dataFive": {
                alignItems: "end"
            },
            "& .dataThree": {
                textAlign: "left !important"
            },
            "& .dataFour": {
                textAlign: "center !important",
                alignItems: "center"
            }
        }
    },
    transaction: {
        display: "grid",
        gridTemplateColumns: "repeat(6,1fr)",
        gap: 10,
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(2,1fr)",
            gridTemplateAreas: `"${"dataTwo"} ${"dataTwo"} ${"dataTwo"}"
            "${"dataOne"} . ${"dataThree"}"
            "${"dataFour"} ${"dataFive"} ${"dataSix"}"
           `,
            padding: 10,
            gap: 10,
            "& .dataTwo": {
                alignItems: "start"
            },
            "& .dataOne": {
                alignItems: "flex-start"
            },
            "& .dataThree": {
                alignItems: "end"
            },
            "& .dataFour": {
                textAlign: "center !important",
                alignItems: "start"
            },
            "& .dataFive": {
                textAlign: "center !important",
                alignItems: "center"
            },
            "& .dataSix": {
                textAlign: "center !important",
                alignItems: "end"
            }
        }
    }
});
class EPF_tab extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: 0,
            portfolioDetails: [],
            transaction: [],

            loader: false,
        }
    }

    componentDidMount() {
        document.title = window.globalConfig.companyDetails.companyName + " - EPF"
        const { page, xray } = this.props;
        // 
        // console.log(page)
        //if (prev.page != page) {
          if (page.state != undefined) {
            // if (prev.page.state.tabValue != page.state.tabValue) {
              this.setState({ value: page.state.tabValue })
            // }
          }
        //}
        this.epfReportAPI()
        // if (this.props.clientID != "") {

        // }
    }
    epfReportAPI = () => {
        this.setState({ loader: true })
        let data = {}
        data.loader = true
        data.response = []
        this.props.EpfList(data)
        // getEPFReport(1, 1, 1, 10).then((response) => {

        getEPFReport(this.props.clientID, this.props.L4ID, this.props.L5ID, this.props.login.UserID).then((response) => {
            data.loader = false
            if (response.status == "S") {
                let res = response.data
                data.response = res.totalDetails != null && res.totalDetails.length != 0 ? [{
                    firstName: "Total Value", lastName: NegativeFinding(res.totalDetails[0].totalValue,"","","","rupee")
                },
                { firstName: "Total Contribution", lastName: NegativeFinding(res.totalDetails[0].totalContribution,"","","","rupee") },
                { firstName: "Employee Share", lastName: NegativeFinding(res.totalDetails[0].totalEmployeeShare,"","","","rupee") },
                { firstName: "Employer Share", lastName: NegativeFinding(res.totalDetails[0].totalEmployerShare,"","","","rupee") },
                { firstName: "Latest Value", lastName: NegativeFinding(res.totalDetails[0].totalLatestValue,"","","","rupee"), val: `(${res.totalDetails[0]?.totalGainPercentage == undefined ? "0.00" : res.totalDetails[0]?.totalGainPercentage}%)`, checkPercentage: res.totalDetails[0]?.totalGainPercentage == undefined ? 0 : res.totalDetails[0]?.totalGainPercentage },

                ] : []

                // this.props.accessAddHeader.current(response.data)
                if (res.portfolioDetails != null && res.portfolioDetails.length != 0) {
                    this.setState({
                        portfolioDetails: res.portfolioDetails.map((item) => {
                            return {
                                ...item, dataOne: item.companyName, dataTwo: NegativeFinding(item.employeeContribution, "", "", "", "rupee"), dataThree: NegativeFinding(item.employerContribution, "", "", "", "rupee"), dataFour: NegativeFinding(item.totalShare, "", "", "", "rupee"), dataFive: NegativeFinding(item.latestValue, "", "", "", "rupee")
                            }
                        })
                    })

                }
                if (res.transactionDetails != null && res.transactionDetails.length != 0) {
                    this.setState({
                        transaction: res.transactionDetails.map((item) => {
                            return {
                                ...item, dataOne: item.date, dataTwo: item.companyName, dataThree: item.txnType, dataFour: NegativeFinding(item.employeeContribution, "", "", "", "rupee"), dataFive: NegativeFinding(item.employerContribution, "", "", "", "rupee"), dataSix: item.particulars
                            }
                        }),

                    })
                }

            } else {
               
                data.response = [{firstName: "Total Value", lastName: NegativeFinding(0,"","","","rupee")},
                               { firstName: "Total Contribution", lastName:  NegativeFinding(0,"","","","rupee") },
                               { firstName: "Employee Share", lastName:  NegativeFinding(0,"","","","rupee") },
                               { firstName: "Employer Share", lastName: NegativeFinding(0,"","","","rupee")},
                               { firstName: "Latest Value", lastName:  NegativeFinding(0,"","","","rupee"), val:"(0)", checkpercentage: 0, checkColor: false },
                               ]
                this.setState({ transaction: [], portfolioDetails: [] })
            }
            this.props.EpfList(data)
            this.setState({ loader: false })

        }).catch(() => {
            this.setState({ loader: false })

        })
    }
    componentDidUpdate(prev) {
        const { page } = this.props;
        // 

        if (prev.page != page) {
            if (prev.page.state != undefined) {
                if (prev.page.state.tabValue != page.state.tabValue) {
                    this.setState({ value: page.state.tabValue })
                }
            }
        } const { clientID, date, L5ID } = this.props;
        if (prev.clientID != clientID || prev.date != date || prev.L5ID != L5ID) {
            // if (clientID != null || date != null) {
            this.epfReportAPI()
            // }
        }


    }

    commonFunction = (val) => {
        if (val != undefined && val != null && val != "") return true;
        return false;
    }

    handleChange = (event, newValue) => {
        // 

        this.setState({ value: newValue })
    };

    render() {
        // //   
        const { classes } = this.props;
        return (<>
            <div className={classes.root}>
                <AppBar position="static" color="default">
                    <Tabs
                        value={this.state.value}
                        onChange={this.handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        classes={{ root: classes.tabRoot }}
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                        TabIndicatorProps={{ style: { background: window.globalConfig.color.BROWN } }}>
                        <Tab label="Portfolio Details" {...a11yProps(0)} />
                        <Tab label="Transaction" {...a11yProps(1)} />
                    </Tabs>
                </AppBar>
                <TabPanel value={this.state.value} index={0}>
                    <EPFReport showHeader={true} singleScheme={"Transaction"} multipleScheme={"Transactions"} grid={classes.portfolioDetails} loader={this.state.loader} data={this.state.portfolioDetails} title={[{ "header": "Company Name", "subHeader": "", align: "left" }, { header: "Employee", align: "right", subHeader: "Contribution" }, { header: "Employer", align: "right", subHeader: "Contribution" }, { header: "Total", align: "right", subHeader: "Contribution" }, { "header": "Latest Value", align: "right", subHeader: "" }]}></EPFReport>
                </TabPanel>
                <TabPanel value={this.state.value} index={1}>
                    <EPFReport showHeader={true} singleScheme={"Transaction"} multipleScheme={"Transactions"} grid={classes.transaction} loader={this.state.loader} data={this.state.transaction} title={[{ "header": "Date", "subHeader": "", align: "left" }, { header: "Company Name", subHeader: "", align: "left" }, { header: "TXN TYPE", subHeader: "", align: "right" }, { header: "Employee", subHeader: "Contribution", align: "right" }, { "header": "Employer", subHeader: "Contribution", align: "right" }, { "header": "Particular", subHeader: "", align: "right" }]}></EPFReport>
                </TabPanel>

            </div>
        </>

        );
    }
}
const mapStateToProps = state => {
    // //

    return {

        //COMMON STATE
        sessionID: state.Login.sessionID,
        clientID: state.Login.logindetails?.ClientID,
        L5ID: state.Login.L5ID,
        date: state.Login.date,
        L4ID: state.Login.logindetails.L4_AccountTypeID,
        login: state.Login.logindetails,
        // insuranceReduxTable: state.Dataentry.npsAndInsuranceDropdownList,

    }
}
const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            EpfList
            //   InsuranceFilterChange,
            //   insurance_transactionfilter,
            //   insuranceProfitandlossfilter
        },
        dispatch
    );
};
const mergestyles = combineStyle(Styles, commonstyletable);
export default withStyles(mergestyles)(connect(mapStateToProps, mapDispatchToProps)(EPF_tab))