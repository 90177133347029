import * as types from '../../constants/actionsType';
import getAllHeaderDetails from '../../../api/cardmenu/cardmenuapi';
export function getHeaderDetails(Account, AccountLevel, ClientID, ReportDate, AsOnDate,ProductID,FamilyID,L5ID,L4ID) {
  return (dispatch) => {
    getAllHeaderDetailsAction(dispatch, Account, AccountLevel, ClientID, ReportDate, AsOnDate,ProductID,FamilyID,L5ID,L4ID);
  };
}
function getAllHeaderDetailsAction(dispatch, Account, AccountLevel, ClientID, ReportDate, AsOnDate,ProductID,FamilyID,L5ID,L4ID) {
  dispatch({
    type: types.LOADER_HEADERDETAILS,
    payload: true,
  }); 
  
  getAllHeaderDetails(Account, AccountLevel, ClientID, ReportDate, AsOnDate,ProductID,FamilyID,L5ID,L4ID)
    .then((data) => {
      if (data != undefined) {
        if (data.status == "S") {
          // 
          dispatch({
            type: types.PREVENT_API_CALL,
            payload:[{ProductID:1,api:"HeaderDetails"}],
        });
          dispatch({
            type: types.GET_HEADERDETAILS,
            payload: data,
          });
       
          dispatch({
            type: types.LOADER_HEADERDETAILS,
            payload: false,
          }); 
        } else {
          dispatch({
            type: types.PREVENT_API_CALL,
            payload:[{ProductID:1,api:"HeaderDetails"}],
        });
          dispatch({
            type: types.LOADER_HEADERDETAILS,
            payload: false,
          }); 
        
        }
      }
    })
    .catch((error) => {
      dispatch({
        type: types.PREVENT_API_CALL,
        payload:[{ProductID:1,api:"HeaderDetails"}],
    });
      dispatch({
        type: types.LOADER_HEADERDETAILS,
        payload: false,
      }); 
    
    })
}  