import React from 'react';
import Button from '../custombutton/button'
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import colors from '../../utils/colors/colors';
import color from '../../utils/colors/colors';
import label from '../../utils/constants/labels';
import DialogBox from '../dialog/dialog';
import { updatebankdetails, getSearchBranchDetails } from '../../redux/actions/profile/profile'
import { bindActionCreators } from 'redux';
const styles = theme => ({
    root: {
        "& .MuiDialogContent-root": {
            padding: "0px !important"
        },
        "& .MuiDialog-paperWidthSm": {
            maxWidth: "none !important",
            width: "25% !important",
            overflow: "hidden"
        },
        "& .MuiDialogContent-root": {
            overflowY: "hidden",
            padding: "0px !important"
        }
    },

    header: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: "white",
        height: 50,
        fontWeight: 500
    },
    txtHeader: {
        padding: 15,
    },
    clearIcon: {
        float: 'right',
        cursor: 'pointer'
    },
    outlineButton: {
        width: 109, cursor: "pointer", height: 39, alignItems: "center", borderRadius: 5, justifyContent: "center", border: `1px solid ${window.globalConfig.color.red}`, display: "flex", flexDirection: "column", color: window.globalConfig.color.red, fontWeight: 'bold'
    },
});
class UCCPopup extends React.Component {
    constructor() {
        super();
        this.state = {

            open: false,

        }
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };
    onClosePopup = () => {

        this.props.onClose()
    }
    render() {
        const { classes } = this.props;
        return (
            <DialogBox root={classes.root} open={this.state.openAccount} handleClose={this.onClosePopup}>

                <div className={classes.header}>
                    <div className={classes.txtHeader}>{"Account Status"}
                        <span className={classes.clearIcon} onClick={this.onClosePopup}>&#x2715;</span>
                    </div>

                </div>
                <div style={{ display: "flex", color: window.globalConfig.color.PRIMARY_BLACK, flexDirection: "column", padding: 12, gap: 10, justifyContent: "space-between", alignItems: "center" }}>
                    <div className={classes.outlineButton}>
                        Not Active
                    </div>
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 6 }}>
                        <span>For futher details.</span>
                        <span>Please call us at <b>{window.globalConfig.isUAT ? "899*****09" : "9152022446"}</b></span>
                    </div>
                </div>
            </DialogBox>
        )
    }
}
const mapStateToProps = state => ({
    login: state.Login.loginError,
    bankDetails: state.ProfileDetails.AllProfileDetails.bankDetails != "NA" ? state.ProfileDetails.AllProfileDetails.bankDetails : [],
    mandateDetails: state.ProfileDetails.AllProfileDetails.mandateDetails != "NA" ? state.ProfileDetails.AllProfileDetails.mandateDetails : [],
    logindetails: state.Login.logindetails,
    branchDetail: state.ProfileDetails.BranchName != null ? state.ProfileDetails.BranchName.length != 0 ? state.ProfileDetails.BranchName.data : [] : [],
    // BranchName: state.ProfileDetails.BranchName != null ? state.ProfileDetails.BranchName.length != 0 ? state.ProfileDetails.BranchName.data.length != 0 ? state.ProfileDetails.BranchName.data.map((item) => {
    //     return item.BranchName
    // }) : [] : [] : []
    //,
    BankList: state.Dropdown.dropdowndetails.banks != null ? state.Dropdown.dropdowndetails.banks.length != 0 ? state.Dropdown.dropdowndetails.banks.map((item) => {
        return { "label": item.BankName, "value": item.BankID }
    }) : [] : []
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ updatebankdetails, getSearchBranchDetails }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(UCCPopup))

