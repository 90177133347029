import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
import Networking from '../../utils/api/apiaccess';
import secureStorage from '../../utils/securestorage';
var axios = require('axios');
function getSwitchapidetails(OrderId, MemberId, ClientID, SchemeID, ClientCode,
  FromSchemeCd, ToSchemeCd, BuySell, BuySellType, DPTxn, OrderVal, SwitchUnits,
  AllUnitsFlag, TransactionMode, FolioNo, KYCStatus, FirstOrderToday, SubBrCode, EUINDeclaration, MinRedeem, EUINVal,
  IPAdd, PassKey, Password, Param1, Param2, Param3, EUINNumber, Remarks, ToSchemeID, Source, TransCode) {
    var IpAddress = secureStorage.getItem('ipaddress')
  var data = {};
  data.OrderId = OrderId,
    data.MemberId = MemberId,
    data.ClientID = ClientID,
    data.SchemeID = SchemeID,
    data.ClientCode = ClientCode,
    data.FromSchemeCd = FromSchemeCd,
    data.ToSchemeCd = ToSchemeCd,
    data.BuySell = BuySell,
    data.BuySellType = BuySellType,
    data.DPTxn = DPTxn,
    data.OrderVal = OrderVal,
    data.SwitchUnits = SwitchUnits == null ? 0 : SwitchUnits,
    data.AllUnitsFlag = AllUnitsFlag,
    data.TransactionMode = TransactionMode
  data.FolioNo = FolioNo,
    data.KYCStatus = KYCStatus,
    //data.InternalRefNumber=InternalRefNumber
    data.FirstOrderToday = FirstOrderToday
  data.SubBrCode = ""
  data.EUINDeclaration = EUINDeclaration
  data.MinRedeem = MinRedeem
  data.EUINVal = EUINVal
  data.IPAdd = IpAddress
  data.PassKey = PassKey
  data.Password = Password
  data.Param1 = Param1
  data.Param2 = Param2
  data.Param3 = Param3
  data.EUIN = EUINNumber
  data.Remarks = Remarks
  // data.SubBrokerARN=SubBrokerARN
  data.ToSchemeID = ToSchemeID
  data.Source = Source
  data.TransCode = TransCode
  // var config = {
  //   method: 'post',
  //   url: ApiValues.Switch,
  //   headers: {
  //     'Content-Type': 'application/json'
  //   },
  //   data: data
  // };

  // return axios(config)
  //   .then(function (response) {
  //     return response.data
  //   })
  //   .catch(function (error) {
  //     return {"status":"F","message":labels.messages.apierror}
  //   });
  return Networking.PostApi(ApiValues.Switch, data,labels.apiKey.apiKeys).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });


}
export default getSwitchapidetails
