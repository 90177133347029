import React, { Component } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import Zoom from "@material-ui/core/Zoom"; //import Select from '@material-ui/core/Select';
import InfoSharpIcon from '@material-ui/icons/InfoSharp';
export default class InformationTooltip extends React.Component {
  render() {
    let split = this.props.title.split(".")
    if (this.props.type == "trim") {
      return (
        <Tooltip
          TransitionComponent={Zoom}
          title={this.props.title}
        >
          <span>{this.props.title.length > this.props.length ? split[0].substring(0, this.props.length - 3) + "..." + split[1] : this.props.title}</span>
          {/* {this.props.title.length > this.props.length ? split[0].substring(0, this.props.length - 3) + "..." + split[1] : this.props.title} */}
        </Tooltip>
      );
    }

    else if (this.props.type == "normal") {
      return (
        <Tooltip
          TransitionComponent={Zoom}
          title={this.props.title}
        >
          <span>{this.props.title.length > this.props.length ? this.props.title.substring(0, this.props.length - 3) + "..." : this.props.title}</span>
          {/* {this.props.title.length > this.props.length ? split[0].substring(0, this.props.length - 3) + "..." + split[1] : this.props.title} */}
        </Tooltip>
      );
    }
    else {
      return (
        <Tooltip
          TransitionComponent={Zoom}
          title={this.props.title}
        >
          {this.props.Icon == undefined ?
            <span style={{ cursor: "pointer", marginLeft: 10 }}>
              <InfoSharpIcon color="disabled" fontSize="small" />
            </span> : this.props.Icon}
         
          {/* <br /> */}
        </Tooltip>
      );
    }

  }
}
