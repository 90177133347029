import ApiValues from '../url/apiurl'
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
var axios = require('axios');

/* name should be camel case in all function */
function getAllShorttermtaxation(ClientID, AccountID, AsOnDate, ProductID, FamilyID, L5ID, isELSS, L4ID) {
  //let BaseUrl = "https://ms.zoifintech.com/Dashboard/GetProfitLossDetails"
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  var raw = JSON.stringify({
    "ClientID": ClientID,
    "AccountID": AccountID,
    "AsOnDate": AsOnDate,
    "ProductID": ProductID,
    "FamilyID": FamilyID,
    "L5ID": L5ID,
    "L4ID": L4ID,
    "isELSS": isELSS
  });

  return Networking.PostApi(ApiValues.Shortterm, raw).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
}
export default getAllShorttermtaxation