import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, Grid } from '@material-ui/core';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Button, ListItem, ListItemText, List } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import lable from '../../utils/constants/labels';
import { NegativeFinding } from '../../utils/commonfunction';
import Zoom from '@material-ui/core/Zoom'//import Select from '@material-ui/core/Select';
import Tooltip from '@material-ui/core/Tooltip';
import colors from '../../utils/colors/colors';
import Avatars from '../avatar/avatar';
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: "320px",
        // height: "130px",
        margin: '0% 3%',
        borderRadius: "10px !important",
        [theme.breakpoints.down('xs')]: {
            maxWidth: "200px",
            height: "100px",
            borderRadius: "10px !important",
        },
        cursor: "pointer"
        // minHeight : 150,
        // maxHeight : 150,
        // paddingLeft : 10
    },
    root2: {
        maxWidth: "220px",
        height: "130px",
        borderRadius: "10px !important",
        [theme.breakpoints.down('xs')]: {
            maxWidth: "300px",
            height: "130px",
            borderRadius: "10px !important",
        },
        cursor: "pointer"
        // minHeight : 150,
        // maxHeight : 150,
        // paddingLeft : 10
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },

    secondrow: {
        margin: "0px !important",
        fontSize: "11px !important",
        fontWeight: "normal !important",
        color: window.globalConfig.color.LABLE_GRAY,
        padding: "1px 0px",
        //marginLeft:"-5px !important",
        paddingTop: "3px"

    },
    firstrow: {
        margin: "0px !important",
        fontSize: "14px !important",
        padding: "4px",
        color: window.globalConfig.color.GREEN,
        //marginLeft:"-10px !important" ,
        paddingTop: "10px"
    },
    list: {
        // width: '100%',
        // maxWidth: 360,
        marginTop: "-10px",
        // marginLeft : -10,
        // minHeight : 100
        // backgroundColor: theme.palette.background.paper,
    },
    listitem: {
        paddingLeft: 20
    },
    h4Font: {
        margin:0,
        textAlign: 'left',
        fontWeight: 500,
        [theme.breakpoints.down('xs')]: {
            fontSize: 15,
        },
    },
    tableAlign: {
        marginLeft: '-3%',
        width: '20vw',
        [theme.breakpoints.down('xs')]: {
            marginLeft: '-4.5%',
        },
    }
}));
export default function OutlinedCard(props) {
    const classes = useStyles();
    const matches = useMediaQuery('(max-width:1024px)');

    return (
        <Card key={props.key} onClick={() => props.onClick(props.Schemecode)} className={matches == true ? classes.root2 : classes.root} variant="outlined">
            <CardContent style={{ padding: 16 }}>
                <Grid container>
                    <Grid item xs={12} style={{ height: 55, display:"flex", flexDirection:'row', textAlign:'center',alignItems:'center'}}>
                        <div>
                            <Avatars color={props.color} avatar={props.title} id={props.title} url="" />
                        </div>
                        <h5 className={classes.h4Font}>
                            {props.title.length < 45 ? props.title : <Tooltip TransitionComponent={Zoom} title={props.title}>
                                <label style={{ textOverflow: 'ellipsis' }}>
                                    {(props.title).substring(0, 45 - 3) + '...'}
                                </label></Tooltip>}
                        </h5>
                    </Grid>
                    <Grid item xs={12}>
                        <table className={[classes.tableAlign, "carosule-table"].join(' ')}>
                            <tbody>
                                <tr className={classes.tr} key={props.key}>
                                    <td style={{ width: "10%", textAlign: 'left' }}>
                                        <p className={classes.firstrow} style={{ color: NegativeFinding(props.W) }}>{props.W}%</p>
                                        <p className={classes.secondrow} style={{ paddingLeft: 5 }}>{lable.tranactionSchemesDetails.oneWeek}</p>
                                    </td>
                                    <td style={{ width: "10%" }}>
                                        <p className={classes.firstrow} style={{ color: NegativeFinding(props.Y) }}>{props.Y}%</p>
                                        <p className={classes.secondrow} style={{ paddingRight: 10 }}>{lable.tranactionSchemesDetails.OneYear}</p>
                                    </td>
                                    <td style={{ width: "10%", textAlign: 'right' }}>
                                        <p className={classes.firstrow} style={{ color: NegativeFinding(props.TY) }}>{props.TY}%</p>
                                        <p className={classes.secondrow} style={{ paddingRight: 13 }}>{lable.tranactionSchemesDetails.ThreeYear}</p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}
