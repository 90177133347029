import * as types from "../../constants/actionsType";
export const xrayfilterChange = (data, list) => {
  return {
    type: types.GET_PORTFOLIOXRAYLIST,
    data,
    list,
  };
};
export const amcOnChange = (data,list,flag) => {

  return {
    type: types.GET_AMC_LIST,
    data,list,flag
  };
};
export const AvailableinvestChange=(data,list,flag)=>{
    return{
        type:types.GET_AVAILABLEINVEST,
        data,list,flag
    }
}
export const profitfilterChange = (data, list) => {
  return {
    type: types.GET_PROFITLIST,
    data,
    list,
  };
};
export const siptrackerfilterChange = (data, list) => {
  return {
    type: types.GET_SIPTRACKER,
    data,
    list,
  };
};
export const datefilterChange = (startdate, enddate) => {
  return {
    type: types.GET_DATECHANGE,
    startdate,
    enddate,
  };
};
export const TransactionfilterChange = (data, list) => {
  return {
    type: types.GET_TRANSACTIONDATALIST,
    data,
    list,
  };
};
export const RedemptionChange = (data, list) => {
  return {
    type: types.GET_REDEMPTIONLIST,
    data,
    list,
  };
};
export const TypeChange = (data, list) => {
  return {
    type: types.GET_TYPELIST,
    data,
    list,
  };
};
export const SchemeChange = (data, list) => {
  return {
    type: types.GET_SCHEMELIST,
    data,
    list,
  };
};
