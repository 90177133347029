import Networking from "../../utils/api/apiaccess";
import labels from "../../utils/constants/labels";
import Apivalues from '../url/apiurl'
function SplitBasketAmount(amount, basketID) {
  let BaseUrl = `${Apivalues.SplitBasketAmount}${basketID}&amount=${amount}`
  return Networking.GetApi(BaseUrl, '').then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
}
export default SplitBasketAmount