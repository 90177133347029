import * as types from '../../constants/actionsType';
export function LoginResetSuccess(Status) {
    return (dispatch) => {
        ResetSuccess(dispatch, Status);
        // dispatch({
        //   type: types.GET_PROFITLOSS_DATA,
        //   payload: Summary,
        // })
    }
}
function ResetSuccess(dispatch, Status) {
    if (Status == "S") {
        dispatch({
            type: types.LOGIN_RESET,
            payload: false
        });
    }
    //  ("erroraaaaa", error)
}