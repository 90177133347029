import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels';
/* name should be camel case in all function */
import Networking from '../../utils/api/apiaccess';
function getUpcommingSIPDetails(ClientID, ProductID, FamilyID, L5ID, L4ID, UCC) {
  // let BaseUrl = "https://ms.zoifintech.com/Dashboard/PortfolioAllocation"
  let data = {};

  data.ClientID = ClientID
  data.ProductID = ProductID
  data.FamilyID = FamilyID
  data.L5ID = L5ID
  data.L4ID = L4ID
  data.ClientCode = UCC == undefined ? "" : UCC
  //   ("portfolio",data)

  //   var config = {
  //     method: 'post',
  //     url: ApiValues.UpcommingSip,
  //     headers: { 
  //       'Content-Type': 'application/json'
  //     },
  //     data : data
  //   };

  //  return axios(config)
  //   .then(function (response) {
  //    return response.data;
  //   })
  //   .catch(function (error) {
  //     return { "status": "F", "message": labels.messages.apierror }
  //   });

  return Networking.PostApi(ApiValues.UpcommingSip, data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
  // let url=BaseUrl
  // if(userId!=undefined){
  //  url= url+userId
  // }

  // fetch method start here

  //   axios.get(url)
  //   .then(data => {
  //     //  (data)
  //     callback(data.data);
  //   }).catch((err) => {
  //     alert(`Error: ${err}`);
  //   });

  // fetch method ends here

  // fetch method ends here

}
export default getUpcommingSIPDetails
