import React from "react";
import { withRouter } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import labels from "../../utils/constants/labels";
import { withStyles } from "@material-ui/core/styles";
import colors from "../../utils/colors/colors";
const styles = theme => ({

  alertSuccess: {
    color: window.globalConfig.color.GREEN,
    backgroundColor: window.globalConfig.color.WHITE,
    border: `1px solid ${window.globalConfig.color.GREEN}`,
    [theme.breakpoints.down(600)]: {
      marginBottom: "26%"
    }
  },
  alertWarning: {
    color: window.globalConfig.color.WARNING,
    backgroundColor: window.globalConfig.color.WHITE,
    border: `1px solid ${window.globalConfig.color.WARNING}`,
    [theme.breakpoints.down(600)]: {
      marginBottom: "26%"
    }

  },
  alertError: {
    color: window.globalConfig.color.red,
    backgroundColor: window.globalConfig.color.WHITE,
    border: `1px solid ${window.globalConfig.color.red}`,
    [theme.breakpoints.down(600)]: {
      marginBottom: "26%"
    }
  }
});
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
let timer = ''
class CommonSnackbar extends React.Component {

  componentDidMount() {
    timer = setTimeout(() => {
      if (this.props.handlesnanckClose != undefined) {
        this.props.handlesnanckClose()
      }

    }, this.props.timer == undefined ? 4000 : this.props.timer);
  }
  componentWillUnmount() {
    clearTimeout(timer)
  }
  render() {

    const { classes } = this.props;
    return (
      <Snackbar open={this.props.open} autoHideDuration={this.props.timer == undefined ? 4000 : this.props.timer} onClose={this.props.handlesnanckClose} >

        <Alert onClose={this.props.handlesnanckClose} classes={{ root: this.props.severity == "success" ? classes.alertSuccess : this.props.severity == "warning" ? classes.alertWarning : this.props.severity === "error" ? classes.alertError : "" }} severity={this.props.severity} >
          {this.props.message != undefined && this.props.message != "" ? this.props.message.toLowerCase() == "Something went wrong".toLowerCase() ? labels.messages.apierror : this.props.message : "Failed"}
        </Alert>

      </Snackbar>
    )
  }
}
export default withStyles(styles)(withRouter(CommonSnackbar));
