import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Label from '../../utils/constants/labels'
import CommonMenu from '../menu/menu';
// import Footer from '../footer/footer'
import { connect } from 'react-redux';
import '../../utils/stylesheets/sidemenu/sidemenu.css';
import ErrorBountry from '../Errorhandling/Errorhandle';
// import Stocks from '../../containers/bonds/stocks'
import FdDebentures from '../../containers/fdDebentures/fd';
import Bonds from '../../containers/fdDebentures/fd';
// import MyAccount from '../../components/profile/my_account';
// import Risk from '../../components/profile/risk_profile';
// import MyPlan from '../../components/profile/myplan';
// import MainScreenProfile from '../profile/mainscreen_profile';
// import Notificationblock from '../../components/notificationblock/notificationblock';
// import encryptKYC from '../../api/profile/encryptionkyc';
import { Redirect } from 'react-router';
import labels from '../../utils/constants/labels';
import PortfolioImageNormal from '../../utils/assets/images/img7.png'
import PortfolioImageHover from '../../utils/assets/images/img7-3.png'
import PortfolioImageClick from '../../utils/assets/images/img7-2.png'
import ReportsNormal from '../../utils/assets/images/img8.png'
import ReportsHover from '../../utils/assets/images/img8-3.png'
import ReportsClick from '../../utils/assets/images/img8-2.png'
import BuysellNormal from '../../utils/assets/images/img9.png'
import BuysellHover from '../../utils/assets/images/img9-3.png'
import BuysellClick from '../../utils/assets/images/img9-2.png'
import MyFinancialPlan from '../../components/profile/my_financialplan';
import { Route, Link, Switch, Router } from 'react-router-dom';
import Subheader from '../../components/subheader/subheader';
// import RiaPlanComplete from '../riaplancomplete/riaplancomplete';
//import PurchasedPlan from '../purchasedplan/purchasedplan';
import RiaPlanComplete from '../advisoryaccount/riaplancomplete';
import PurchasedPlan from '../advisoryaccount/purchasedplan';
import colors from '../../utils/colors/colors';
const styles = theme => ({
    root: {
        height: '100%',
        width: '100%',
    },
    navLeftFix: {
        position: "fixed",
        top: "225px",
        zIndex: 10,
        marginTop: -90,
        boxShadow: "0 3px 20px 0 rgb(19 89 161 / 10%)",
        borderTopRightRadius: 9,
        [theme.breakpoints.down("800")]: {
            borderTopRightRadius: 9,
            borderTopLeftRadius: 9,
        }
        ,
        [theme.breakpoints.between("600", "800")]: {
            top: "auto",
            bottom: 59,
            left: "47%",
            margin: "0 0 0 -248px",
            boxShadow: "none",
            zIndex: 1,
        },
        [theme.breakpoints.down("600")]: {
            top: "auto",
            bottom: 59,
            left: "52%",
            margin: "0 0 0 -38vw",
            boxShadow: "none"
        },
    },

    //Vaikundamani
    navLeftFixSecond: {
        position: "fixed",
        top: "225px",
        marginTop: -90,
        boxShadow: "0 3px 23px 0 rgb(19 89 161 / 10%)",
        zIndex: 1,
        borderTopRightRadius: 9,
        [theme.breakpoints.down("800")]: {
            borderTopRightRadius: 9,
            borderTopLeftRadius: 9,
        },
        [theme.breakpoints.down("600")]: {
            top: "auto",
            bottom: 59,
            left: "66%",
            margin: "0 0 0 -38vw",
            boxShadow: 'none'
        },
        [theme.breakpoints.between("600", "800")]: {
            top: "auto",
            bottom: 59,
            left: "66%",
            margin: "0 0 0 -38vw",
            boxShadow: 'none',
            zIndex: 1,
        }
    },

    boxStyle: {
        width: '86px',
        height: '57px',
        display: "block",
        textAlign: "center",
        backgroundColor:window.globalConfig.color.WHITE,
        color: window.globalConfig.color.BLACK_PRIMARY,
        borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        fontSize: "11px",
        lineHeight: 1.36,
        paddingTop: "17px",
        cursor: "pointer",
        '&:hover': {
            backgroundColor:window.globalConfig.color.RosyBrown,
            color: window.globalConfig.color.BROWN
        },
        [theme.breakpoints.down("920")]: {//Vaikundamani - theme.breakpoints.down("900")
            top: "59px",
            width: '24vw',
            display: "block",
            textAlign: "center",
            backgroundColor: window.globalConfig.color.WHITE,
            color:window.globalConfig.color.BLACK_PRIMARY,
            borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
            fontSize: "11px",
            lineHeight: 1.36,
            paddingTop: "9px",
            cursor: "pointer",
            paddingBottom: '5px',
        },

    },
    //Vaikundamani End
    portfolioContainer: {
        '&:hover': {
            color:window.globalConfig.color.BROWN
        }
    },
    navDropBox: {
        width: 152,
        boxShadow: "7px 7px 20px 0 rgb(0 0 0 / 20%)",
        position: "absolute",
        top: '0px',
        left: '80px',
        cursor: "pointer",
        [theme.breakpoints.down("800")]: {
            top: "auto",
            bottom: '15vh',
            left: '-57px',
            width: 202,
            zIndex: 9,
            cursor: "default"
        },
        [theme.breakpoints.down("600")]: {
            top: "auto",
            bottom: 82,
            left: '-6vw',
            width: 202,
            zIndex: 9,
            cursor: "default"
        }
    },
    navDropBoxtwo: {
        width: 202,
        boxShadow: "7px 7px 20px 0 rgb(0 0 0 / 20%)",
        position: "absolute",
        top: '0px',
        left: '242px',
        maxHeight: 350,
        overflowY: "scroll",
        overflowX: 'none',
        cursor: "pointer",
        [theme.breakpoints.down("800")]: {
            top: "auto",
            bottom: '31vh',
            left: '-54px',
            maxHeight: 293,
            zIndex: 9,
            cursor: "default"
        },
        [theme.breakpoints.down("600")]: {
            top: "auto",
            bottom: '31vh',
            left: '-5vw',
            maxHeight: 250,
            zIndex: 9,
            cursor: "default"
        }
    },
    navDropBoxList: {
        width: "87%",
        height: 40,
        lineHeight: 3,
        borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        padding: 0,
        textAlign: "left",
        paddingLeft: 30,
        fontSize: 14,
        backgroundColor: window.globalConfig.color.WHITE,
        color: window.globalConfig.color.BLACK_PRIMARY,
        '&:hover': {
            backgroundColor: window.globalConfig.color.RosyBrown,
            color: window.globalConfig.color.BROWN
        }
    },
    selectedList: {
        backgroundColor: window.globalConfig.color.BROWN,
        color: window.globalConfig.color.WHITE,
        '&:hover': {
            backgroundColor:window.globalConfig.color.BROWN,
            color: window.globalConfig.color.WHITE,
        }
    },
    listStyle: {
        position: "relative",
        [theme.breakpoints.down("800")]: {
            float: "left",
            borderRight: `1px solid ${window.globalConfig.color.ZERO_BACKGROUND}`
        }
    },
    topRadius: {
        borderTopRightRadius: 9,
        [theme.breakpoints.down("800")]: {
            borderTopRightRadius: 0,
            borderTopLeftRadius: 9,
        }
    },
    bottomRadius: {
        borderBottomRightRadius: 9,
        [theme.breakpoints.down("800")]: {
            borderBottomRightRadius: 0,
            borderTopRightRadius: 9,
        }
    },
    Profile: {
        "& span": {
            height: 24,
            display: "block",
            // marginBottom: "8px",
            width: "100%",
            background: `url(${PortfolioImageClick}) no-repeat center top`,
        },
    },
    myfamily: {
        "& span": {
            height: 24,
            display: "block",
            // marginBottom: "8px",
            width: "100%",
            background: `url(${ReportsClick}) no-repeat center top`,
        },
    },
    myAccount: {
        "& span": {
            height: 27,
            display: "block",
            // marginBottom: "8px",
            width: "100%",
            background: `url(${BuysellClick}) no-repeat center top`,
        },
    },
    risk: {
        "& span": {
            height: 24,
            display: "block",
            // marginBottom: "8px",
            width: "100%",
            background: `url(${PortfolioImageClick}) no-repeat center top`,
        },
    },
    myplan: {
        "& span": {
            height: 24,
            display: "block",
            // marginBottom: "8px",
            width: "100%",
            background: `url(${ReportsClick}) no-repeat center top`,
        },
    },
    myFinancialPlans: {
        "& span": {
            height: 27,
            display: "block",
            // marginBottom: "8px",
            width: "100%",
            background: `url(${BuysellClick}) no-repeat center top`,
        },
    },
    hoverprofile: {
        "& span": {
            height: 24,
            display: "block",
            // marginBottom: "8px",
            width: "100%",
            background: `url(${PortfolioImageNormal}) no-repeat center top`,
        },
        '&:hover': {
            "& span": {
                height: 24,
                display: "block",
                // marginBottom: "8px",
                width: "100%",
                background: `url(${PortfolioImageHover}) no-repeat center top`,
            },
        }
    },
    hoverfamily: {
        "& span": {
            height: 24,
            display: "block",
            // marginBottom: "8px",
            width: "100%",
            background: `url(${ReportsNormal}) no-repeat center top`,
        },
        '&:hover': {
            "& span": {
                height: 24,
                display: "block",
                // marginBottom: "8px",
                width: "100%",
                background: `url(${ReportsHover}) no-repeat center top`,
            },
        }
    },
    hoveraccount: {
        "& span": {
            height: 27,
            display: "block",
            // marginBottom: "8px",
            width: "100%",
            background: `url(${BuysellNormal}) no-repeat center top`,
        },
        '&:hover': {
            "& span": {
                height: 27,
                display: "block",
                // marginBottom: "8px",
                width: "100%",
                background: `url(${BuysellHover}) no-repeat center top`,
            },
        }
    },
    hoverrisk: {
        "& span": {
            height: 24,
            display: "block",
            // marginBottom: "8px",
            width: "100%",
            background: `url(${PortfolioImageNormal}) no-repeat center top`,
        },
        '&:hover': {
            "& span": {
                height: 24,
                display: "block",
                // marginBottom: "8px",
                width: "100%",
                background: `url(${PortfolioImageHover}) no-repeat center top`,
            },
        }
    },
    hoverplans: {
        "& span": {
            height: 24,
            display: "block",
            // marginBottom: "8px",
            width: "100%",
            background: `url(${ReportsNormal}) no-repeat center top`,
        },
        '&:hover': {
            "& span": {
                height: 24,
                display: "block",
                // marginBottom: "8px",
                width: "100%",
                background: `url(${ReportsHover}) no-repeat center top`,
            },
        }
    },
    hoverfinance: {
        "& span": {
            height: 27,
            display: "block",
            // marginBottom: "8px",
            width: "100%",
            background: `url(${BuysellNormal}) no-repeat center top`,
        },
        '&:hover': {
            "& span": {
                height: 27,
                display: "block",
                // marginBottom: "8px",
                width: "100%",
                background: `url(${BuysellHover}) no-repeat center top`,
            },
        }
    },
    marginLeft: {

    },
    topAlignmentDeskTop: {
        // marginTop: "-10px",

    },
    topAlignmentMobile: {
        marginTop: "134px",
        [theme.breakpoints.down('xs')]: {
            marginTop: "60px",
        },

    }
})
class BondSideMenu extends React.Component {
    constructor() {
        super();
        this.state = {
            index: 0,
            loader: false,
            open: false,
            message: ""
        }
    }
    handleClick = (value) => {
        this.setState({ index: value })
        if (value == 2) {
            this.props.history.push({
                pathname: labels.Path.FdDebentures,
                state: { data: "fd" }
            })
        }
        if (value == 3) {
            this.props.history.push({
                pathname: labels.Path.Bonds,
                state: { data: "Bonds" }
            })
        }
    }
    componentDidMount() {
        const { index } = this.state;
       
        let a = ""
        if (this.props.location.pathname == "/trade/FixedDeposit") {
            a = 2
        }
        if (this.props.location.pathname == "/Bonds") {
            a = 3
        }
        this.setState({ index: a })
    }
    componentDidUpdate(prevProps) {
        const { data, location } = this.props;
        const { index } = this.state
       
        let a = ""
        if (location.pathname == "/trade/FixedDeposit") {
            a = 2
        }
        if (location.pathname == "/Bonds") {
            a = 3
        }
        if (index != a) {
            this.setState({ index: a })
        }
    }

    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };
    render() {
        // 
        const { classes } = this.props;
        return (
            <div className={classes.root} tabIndex="0" location={this.props.location}>
                <div>
                    <ErrorBountry>
                        <div className={classes.navLeftFix}>
                            <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                                <li style={{ position: "relative" }} className={[classes.boxStyle, classes.portfolioContainer, classes.listStyle, this.state.index === 2 && classes.selectedList, this.state.index == 2 ? classes.risk : classes.hoverrisk].join(' ')} onClick={() => {
                                    this.handleClick(2)
                                }} >
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                                    >
                                        <span style={{ marginBottom: 10 }}></span>
                                        <div>{Label.productList.FD}</div>
                                    </div>
                                </li>

                                <li style={{ position: "relative" }} className={[classes.boxStyle, classes.portfolioContainer, classes.listStyle, this.state.index == 3 ? classes.selectedList : '', this.state.index == 3 ? classes.myfamily : classes.hoverfamily].join(' ')} onClick={() => {
                                    this.handleClick(3)
                                }} >
                                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                                    >
                                        <span style={{ marginBottom: 10 }}></span>
                                        <div>{Label.productList.physicalBonds}</div>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div className={[classes.marginLeft, classes.topAlignmentDeskTop].join(' ')} >
                            <Switch>
                                <Route path={Label.Path.FdDebentures} component={FdDebentures} exact/>
                                <Route path={Label.Path.Bonds} component={Bonds} />
                                <Redirect from='*' to={Label.Path.PageNotFound} />
                            </Switch>
                        </div>
                    </ErrorBountry>
                </div>
                {/* <Footer /> */}
            </div >
        );
    }
}
const mapDispatchToProps = dispatch => ({
});
const mapStateToProps = state => ({
    sessionId: state.Login.sessionID,
    clientId: state.Login.logindetails.ClientID,
    date: state.Login.date,
    logindetails: state.Login.logindetails,
    
});
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(BondSideMenu));
