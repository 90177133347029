var axios = require('axios');
import ApiValues from '../url/apiurl';
import labels from "../../utils/constants/labels"
import Networking from '../../utils/api/apiaccess';
/* name should be camel case in all function */
function GetMyPlanDetailsapi( clientID) {  
  let data = {};
  data.id = 0
  data.clientID = clientID

//   var config = {
//     method: 'post',
//     url: ApiValues.MyPlan,
//     headers: {
//       'Content-Type': 'application/json',
//       'api-key': labels.apiKey.apiKeys
//       // 'api-key': 'L]ak;YzY!47FqLUbhcC:4b8Zaw' 
//     },
//     data: data
//   };
  
//  return axios(config)
//   .then(function (response) {
//     //
//    return response.data;
//   })
//   .catch(function (error) {
//   //  
//   return{status:"F",message:labels.messages.apierror}
// });
return Networking.PostApi(ApiValues.MyPlan, data).then(result => { return result })
.catch(function (error) {
  return { "status": "F", "message": labels.messages.apierror }
});
}
export default GetMyPlanDetailsapi
