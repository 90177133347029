import moment from 'moment';
import ApiValues from './url/apiurl';
import secureStorage from '../utils/securestorage'
import labels from '../utils/constants/labels';
import Networking from '../utils/api/apiaccess';
function generateMandateType(Amount, MandateType, BankID, additionalBankDetails, BSEMFAccount, UCC) {
  // 
  let userDetails = secureStorage.getItem('user')
  //
  let data = {};
  data.ClientCode = UCC,
    data.ClientAccountID = BSEMFAccount == "" || BSEMFAccount == "NA" ? 0 : BSEMFAccount
  data.Amount = parseFloat(Amount),
    data.MandateType = MandateType,
    // data.AccountNo = "001801502806",
    data.AccountNo = additionalBankDetails.BankAccountNumber,
    data.AccountType = additionalBankDetails.AccountType,
    data.IFSC_Code = additionalBankDetails.IFSCCode,
    data.MICR_Code = additionalBankDetails.BranchMICRCode == "NA" ? "" : String(additionalBankDetails.BranchMICRCode),
    data.StartDate = moment().utcOffset('+05:30').format('DD/MM/YYYY'),//additionalBankDetails.StartDate,
    data.EndDate = moment().add(30, "years").subtract(3, 'days').format("DD/MM/YYYY"),
    data.BankID = additionalBankDetails.BankID,
    data.ClientID = userDetails.ClientID
  return Networking.PostApi(ApiValues.mandateCreate, data, labels.apiKey.apiKeys).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default generateMandateType
