import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
/* name should be camel case in all function */

 function getDropdownDetails(sessionID,clientID) {
  let data={};
  data.SessionID=sessionID
  data.ClientID=clientID
return Networking.PostApi(ApiValues.FilterDetail,data).then(result => { return result })
.catch(function (error) {
  return { "status": "F", "message": labels.messages.apierror }
});
   



  }
  
 export default getDropdownDetails