import * as types from '../../constants/actionsType';
import GetAllcartApi from '../../../api/cart/getallcartapi'
export function GetAllCart(SessionID, ClientID) {
  return (dispatch) => {
    GetAllCartAction(dispatch, SessionID, ClientID);
  }
}
function GetAllCartAction(dispatch, SessionID, ClientID) {
  dispatch({
    type: types.LOADER_ALL_CART,
    payload: true,
  });
  GetAllcartApi(SessionID, ClientID)
    .then((data) => {
      //   (data)
      dispatch({
        type: types.LOADER_ALL_CART,
        payload: false,
      });
      //var dropdowndata=getState().Dropdown.dropdowndetails
      // if (data != undefined) {
      try {
        if (data.status == "S") {
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "Cart" }],
          });
          // { console.log(data) }
          dispatch({
            type: types.GET_ALL_CART,
            payload: data,
          });
        }
        else {
          dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 0, api: "Cart" }],
          });
          dispatch({
            type: types.NETWORK_ALL_CART,
            payload: false,
          });
          dispatch({
            type: types.GET_ALL_CART,
            payload: [],
          });
          // }
        }
      } catch (er) {
        // console.log(er)
      }
    })
    .catch((error) => {
      dispatch({
        type: types.LOADER_ALL_CART,
        payload: false,
      });
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: 0, api: "Cart" }],
      });
    })
}
export const GetAllCartValues = (data, flag) => {
  return {
    type: types.GET_ALL_CART_WITHOUT_API,
    data, flag,
  }
}
export const SaveCartValues = () => {
  return {
    type: types.SAVE_CART_VALUE,

  }
}