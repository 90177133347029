import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Label from "../../utils/constants/labels";
import { withStyles, Grid } from "@material-ui/core";
import Pdflogo from "../../utils/assets/images/icons8-pdf-100.png";
import excelLogo from "../../utils/assets/images/icons8-file-excel-96.png";
import axios from "axios";
import fileDownload from "js-file-download";
import CircularProgress from "@material-ui/core/CircularProgress";
import CommonSnackbar from "../../components/snackbar/snackbar";
import NoData from "../../components/nodata/nodata";
import Loader from "../../components/loader/loader";
import combineStyles from "../../utils/stylesheets/combainstyle/combainstyle";
import CommonTableStyle from "../../utils/stylesheets/table/commontablestyle";
import ErrorBoundary from "../../components/Errorhandling/Errorhandle";
import CommonHeader from "../../components/report_common_header/report_common_header";
import labels from "../../utils/constants/labels";
import secureStorage from "../../utils/securestorage";
import { Redirect } from "react-router";
import colors from "../../utils/colors/colors";
import { EncryptURL } from "../../utils/commonfunction";
//import secureStorage from "../../utils/securestorage";
const styles = (theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  table: {
    width: "100%",
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontSize: "14px",
    flex: "4 1",
    fontWeight: 500,
    borderCollapse: "collapse",
    marginTop: "2em",
    marginBottom: "15%",
    // display: "block",
    // [theme.breakpoints.down('xs')] : {
    //     width: '100%',
    //     height: '100%',
    //     marginLeft: '0 !important',
    // }
  },
  td: {
    verticalAlign: "bottom",
    paddingBottom: "1em",
  },
  right: {
    textAlign: "right",
    lineHeight: 1.5,
    // paddingBottom: "1em",
    // fontSize: "12px", fontWeight: 500,
  },
  left: {
    textAlign: "left",
    // paddingBottom: "1em",
    // fontSize: "12px", fontWeight: 500,
    // paddingLeft:"2%"
  },
  thead_tr: {
    height: 55,
    // verticalAlign: "bottom"
  },
  //style changed by dulcy
  pdfimg: {
    height: 37,
    width: 33,
    // marginBottom: 3,
    marginRight: 14,
    cursor: "pointer",
  },
  circular: {
    color: window.globalConfig.color.LABLE_GRAY,
    position: "absolute",
    // marginLeft: " -2.5%",
    marginTop: "0.5%",
  },
  exlimg: {
    height: 36,
    width: 33,
    cursor: "pointer",
  },
  center: {
    textAlign: "center",
  },
  end: {
    textAlign: "end",
  },
  pdfAndExcelIcon: {
    display: "flex", position: "relative", flexDirection: "column"
  },
  // Added by Celsia on 15/05/2021 for Mobile Responsive Started
  // desktopResponsive: {
  //   display: "block !important",
  //   // marginBottom:"11.5%",
  //   [theme.breakpoints.down("600")]: {
  //     display: "none !important",
  //   },
  // },
  // mobileResponsive: {
  //   display: "none !important",
  //   [theme.breakpoints.down("600")]: {
  //     display: "block!important",
  //     padding: "0px 10px",
  //     // height: 50,
  //     paddingBottom: 30,
  //     // paddingTop: 30,
  //     width: "100%",
  //   },
  // [theme.breakpoints.between("300", "350")]: {
  //     marginLeft: 70
  // },
  // [theme.breakpoints.between("350", "400")]: {
  //     marginLeft: 100
  // },
  // [theme.breakpoints.between("400", "600")]: {
  //     marginLeft: 150
  // },
  // },
  firstGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "firstGrid"
    }
  },
  secondGrid: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("920")]: {
      gridArea: "secondGrid",
      flexDirection: "column",
      textAlign: "left"
    }
  },
  thirdGrid: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("920")]: {
      gridArea: "thirdGrid",
      flexDirection: "column",
      textAlign: "left"
    }
  },
  fourthGrid: {
    display: "flex",
    justifyContent: "center",
    [theme.breakpoints.down("920")]: {
      gridArea: "fourthGrid",
      flexDirection: "column",
      textAlign: "left"
    }
  },
  mobileResponsive: {
    display: "none",
    [theme.breakpoints.down("920")]: {
      display: "grid"
    }
  },
  desktopResponsive: {
    display: "grid",
    [theme.breakpoints.down("920")]: {
      display: "none !important"
    }
  },
  mobileContainer: {
    width: "93%",
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontSize: "14px",
    fontWeight: 500,
    borderCollapse: "collapse",
    marginTop: "2em",
    marginBottom: "15%",
    borderRadius: "10px 10px 10px 10px",
    boxShadow: "0 0 20px 0 rgb(19 89 161 / 10%)",
  },
  mobileRoot: {
    flex: "flex-row",
    textAlign: "center",
  },
  mobileCard: {
    padding: "2%",
    boxShadow: "1px",
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: "14px",
    marginTop: "2.5em",
    fontWeight: "400",
    [theme.breakpoints.between("320", "400")]: {
      fontSize: "11px",
      marginBottom: "2.5em",
    },
    [theme.breakpoints.between("401", "600")]: {
      fontSize: "13px",
      marginBottom: "2.5em",
    },
  },
  mobileMainCard: {
    padding: "5px",
    boxShadow: "1px",
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: "17px",
    marginTop: "2.5em",
    fontWeight: "400",
    maxWidth: "100%",
    [theme.breakpoints.between("320", "400")]: {
      fontSize: "13px",
    },
    // [theme.breakpoints.between('320', '400')]: {
    //     fontSize: "11px"
    // },
    // [theme.breakpoints.between('401', '600')]: {
    //     fontSize: "13px"
    // },
  },
  labelStyle: {
    color: colors.LABLE_GRAY,
    textTransform: "uppercase",
    fontSize: "12px"
  },
  taxationColumn: {
    display: "flex",
    flexDirection: "row"
  }
  //   End
});
class CapitalGain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: true,
      anchorEl: null,
      xraysort: [],
      sortbyAtoZ: false,
      sortbyMarkertValue: false,
      sortbyReturns: false,
      selectFilter: 0,
      open: false,
      accpdf: false,
      accexcel: false,
      valpdf: false,
      valexcel: false,
      gainpdf: false,
      gainxcel: false,
      open: false,
      dividendpdf: false,
      dividendxcel: false,
      capitalGain: [],
    };
  }
  componentDidMount() {
    this.setState({
      capitalGain:
        this.props.capitalGain != null && this.props.capitalGain.length != 0
          ? this.props.capitalGain.map((item, index) => {
            return {
              ...item,
              id: index,
              accpdf: false,
              accexcel: false,
              valpdf: false,
              valexcel: false,
              gainpdf: false,
              gainxcel: false,
              dividendpdf: false,
              dividendxcel: false,
            };
          })
          : [],
    });
  }
  componentDidUpdate(prev) {
    const { capitalGain } = this.props
    if (prev.capitalGain != capitalGain) {
      this.setState({
        capitalGain:
          capitalGain != null && capitalGain.length != 0
            ? capitalGain.map((item, index) => {
              return {
                ...item,
                id: index,
                accpdf: false,
                accexcel: false,
                valpdf: false,
                valexcel: false,
                gainpdf: false,
                gainxcel: false,
                dividendpdf: false,
                dividendxcel: false,
              };
            })
            : [],
      });
    }
  }
  onclickpdf = (url, ext, filename, value) => {
    // window.open(url);
    if (filename === `${value}-${Label.DownloadLabel.capitalgainvaluationreport}pdf`) {
      var data = []
      const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
      data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
      data[index] = {
        ...data[index],
        accpdf: true
      }
      this.setState({ capitalGain: data })
      // this.setState({ accpdf: true });
    }
    if (filename === `${value}-${Label.DownloadLabel.capitalgainvaluationreport}xlsx`) {
      var data = []
      const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
      data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
      data[index] = {
        ...data[index],
        accexcel: true
      }
      this.setState({ capitalGain: data })
    }
    if (filename === `${value}-${Label.DownloadLabel.mainreportcapitalgain}pdf`) {
      var data = []
      const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
      data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
      data[index] = {
        ...data[index],
        gainpdf: true
      }
      this.setState({ capitalGain: data })
    }
    if (filename === `${value}-${Label.DownloadLabel.mainreportcapitalgain}xlsx`) {
      var data = []
      const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
      data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
      data[index] = {
        ...data[index],
        gainxcel: true
      }
      this.setState({ capitalGain: data })
    }
    if (filename === `${value}-${Label.DownloadLabel.capitalgaindividend}pdf`) {
      var data = []
      const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
      data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
      data[index] = {
        ...data[index],
        dividendpdf: true
      }
      this.setState({ capitalGain: data })
    }
    if (filename === `${value}-${Label.DownloadLabel.capitalgaindividend}xlsx`) {
      var data = []
      const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
      data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
      data[index] = {
        ...data[index],
        dividendxcel: true
      }
      this.setState({ capitalGain: data })
    }
    let authToken = ''
    if (!Boolean(secureStorage.getItem(`authToken-${secureStorage.getItem("sessionID")}`))) {
      authToken = labels.key.apiKey
    } else {
      authToken = secureStorage.getItem(`authToken-${secureStorage.getItem("sessionID")}`)
    }
    let nodeToken = secureStorage.getItem(`nodeToken-${secureStorage.getItem("sessionID")}`)
    axios("/gw", {
      method: "GET",
      headers: {
        Authorization: `Bearer ${nodeToken}`,
        Token: authToken,
        Export: true,
        "SessionID": secureStorage.getItem("sessionID"),
        'Access-Control-Allow-Origin': `${window.globalConfig.Domain}`,
        'Source': 'Web',
        "gw": EncryptURL(url),
        'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload'
      },
      responseType: "blob", //Force to receive data in a Blob Format
    })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], {
          type: `${"application/"}${ext}`,
        });
        //
        //Build a URL from the file
        if (ext === "xlsx") {
        } else {
          const fileURL = URL.createObjectURL(file);
          //Open the URL on new Window
          window.open(fileURL);
        }
        if (
          filename === `${value}-${Label.DownloadLabel.capitalgainvaluationreport}pdf`
        ) {
          fileDownload(
            response.data,
            `${value}-${Label.DownloadLabel.capitalgainvaluationreport}pdf`
          );
          var data = []
          const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
          data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
          data[index] = {
            ...data[index],
            accpdf: false
          }
          this.setState({ capitalGain: data })
        }
        if (
          filename === `${value}-${Label.DownloadLabel.capitalgainvaluationreport}xlsx`
        ) {
          fileDownload(
            response.data,
            `${value}-${Label.DownloadLabel.capitalgainvaluationreport}xlsx`
          );
          var data = []
          const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
          data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
          data[index] = {
            ...data[index],
            accexcel: false
          }
          this.setState({ capitalGain: data })
        }
        // if (
        //   filename === `${Label.DownloadLabel.capitalgainvaluationreport}pdf`
        // ) {
        //   fileDownload(
        //     response.data,
        //     `${Label.DownloadLabel.capitalgainvaluationreport}pdf`
        //   );
        //   var data = []
        //   const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
        //   data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
        //   data[index] = {
        //     ...data[index],
        //     valpdf: false
        //   }
        //   this.setState({ capitalGain: data })
        // }
        // if (
        //   filename === `${Label.DownloadLabel.capitalgainvaluationreport}xlsx`
        // ) {
        //   fileDownload(
        //     response.data,
        //     `${Label.DownloadLabel.capitalgainvaluationreport}xlsx`
        //   );
        //   var data = []
        //   const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
        //   data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
        //   data[index] = {
        //     ...data[index],
        //     valexcel: false
        //   }
        //   this.setState({ capitalGain: data })
        // }
        if (filename === `${value}-${Label.DownloadLabel.mainreportcapitalgain}pdf`) {
          fileDownload(
            response.data,
            `${value}-${Label.DownloadLabel.mainreportcapitalgain}pdf`
          );
          var data = []
          const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
          data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
          data[index] = {
            ...data[index],
            gainpdf: false
          }
          this.setState({ capitalGain: data })
        }
        if (filename === `${value}-${Label.DownloadLabel.mainreportcapitalgain}xlsx`) {
          fileDownload(
            response.data,
            `${value}-${Label.DownloadLabel.mainreportcapitalgain}xlsx`
          );
          var data = []
          const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
          data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
          data[index] = {
            ...data[index],
            gainxcel: false
          }
          this.setState({ capitalGain: data })
        }
        if (filename === `${value}-${Label.DownloadLabel.capitalgaindividend}pdf`) {
          fileDownload(
            response.data,
            `${value}-${Label.DownloadLabel.capitalgaindividend}pdf`
          );
          var data = []
          const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
          data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
          data[index] = {
            ...data[index],
            dividendpdf: false
          }
          this.setState({ capitalGain: data })
        }
        if (filename === `${value}-${Label.DownloadLabel.capitalgaindividend}xlsx`) {
          fileDownload(
            response.data,
            `${value}-${Label.DownloadLabel.capitalgaindividend}xlsx`
          );
          var data = []
          const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
          data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
          data[index] = {
            ...data[index],
            dividendxcel: false
          }
          this.setState({ capitalGain: data })
        }
        return "success";
      })
      .catch((error) => {
        if (error.response?.status == 440 && secureStorage.getItem(`invalidAuth${session}`) == null) {
          secureStorage.setItem(`invalidAuth${session}`, "invalid")
        }
        this.setState({ open: true });
        if (
          filename === `${value}-${Label.DownloadLabel.capitalgainvaluationreport}pdf`
        ) {
          var data = []
          const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
          data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
          data[index] = {
            ...data[index],
            accpdf: false
          }
          this.setState({ capitalGain: data })
          this.setState({ accpdf: false });
        }
        if (
          filename === `${value}-${Label.DownloadLabel.capitalgainvaluationreport}xlsx`
        ) {
          var data = []
          const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
          data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
          data[index] = {
            ...data[index],
            accexcel: false
          }
          this.setState({ capitalGain: data })
          this.setState({ accexcel: false });
        }
        // if (
        //   filename === `${Label.DownloadLabel.capitalgainvaluationreport}pdf`
        // ) {
        //   var data = []
        //   const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
        //   data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
        //   data[index] = {
        //     ...data[index],
        //     valpdf: false
        //   }
        //   this.setState({ capitalGain: data })
        //   this.setState({ valpdf: false });
        // }
        // if (
        //   filename === `${Label.DownloadLabel.capitalgainvaluationreport}xlsx`
        // ) {
        //   var data = []
        //   const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
        //   data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
        //   data[index] = {
        //     ...data[index],
        //     valexcel: false
        //   }
        //   this.setState({ capitalGain: data })
        //   this.setState({ valexcel: false });
        // }
        if (filename === `${value}-${Label.DownloadLabel.mainreportcapitalgain}pdf`) {
          var data = []
          const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
          data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
          data[index] = {
            ...data[index],
            gainpdf: false
          }
          this.setState({ capitalGain: data })
          this.setState({ gainpdf: false });
        }
        if (filename === `${value}-${Label.DownloadLabel.mainreportcapitalgain}xlsx`) {
          var data = []
          const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
          data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
          data[index] = {
            ...data[index],
            gainxcel: false
          }
          this.setState({ capitalGain: data })
          this.setState({ gainxcel: false });
        }
        if (filename === `${value}-${Label.DownloadLabel.capitalgaindividend}pdf`) {
          var data = []
          const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
          data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
          data[index] = {
            ...data[index],
            dividendpdf: false
          }
          this.setState({ capitalGain: data })
          this.setState({ dividendpdf: false });
        }
        if (filename === `${value}-${Label.DownloadLabel.capitalgaindividend}xlsx`) {
          var data = []
          const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
          data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
          data[index] = {
            ...data[index],
            dividendxcel: false
          }
          this.setState({ capitalGain: data })
          this.setState({ dividendxcel: false });
        }
      });
  };
  // onclickpdf = (url, ext, filename, value) => {
  //  window.open(url);    // = secureStorage.getItem(`authToken-${secureStorage.getItem("sessionID")}`)
  //   // if (filename === `${value}-${Label.DownloadLabel.capitalgainvaluationreport}pdf`) {
  //   //   var data = []
  //   //   const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //   data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //   data[index] = {
  //   //     ...data[index],
  //   //     accpdf: true
  //   //   }
  //   //   this.setState({ capitalGain: data })
  //   //   // this.setState({ accpdf: true });
  //   // }
  //   // if (filename === `${value}-${Label.DownloadLabel.capitalgainvaluationreport}xlsx`) {
  //   //   var data = []
  //   //   const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //   data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //   data[index] = {
  //   //     ...data[index],
  //   //     accexcel: true
  //   //   }
  //   //   this.setState({ capitalGain: data })
  //   // }
  //   // if (filename === `${value}-${Label.DownloadLabel.mainreportcapitalgain}pdf`) {
  //   //   var data = []
  //   //   const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //   data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //   data[index] = {
  //   //     ...data[index],
  //   //     gainpdf: true
  //   //   }
  //   //   this.setState({ capitalGain: data })
  //   // }
  //   // if (filename === `${value}-${Label.DownloadLabel.mainreportcapitalgain}xlsx`) {
  //   //   var data = []
  //   //   const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //   data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //   data[index] = {
  //   //     ...data[index],
  //   //     gainxcel: true
  //   //   }
  //   //   this.setState({ capitalGain: data })
  //   // }
  //   // if (filename === `${value}-${Label.DownloadLabel.capitalgaindividend}pdf`) {
  //   //   var data = []
  //   //   const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //   data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //   data[index] = {
  //   //     ...data[index],
  //   //     dividendpdf: true
  //   //   }
  //   //   this.setState({ capitalGain: data })
  //   // }
  //   // if (filename === `${value}-${Label.DownloadLabel.capitalgaindividend}xlsx`) {
  //   //   var data = []
  //   //   const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //   data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //   data[index] = {
  //   //     ...data[index],
  //   //     dividendxcel: true
  //   //   }
  //   //   this.setState({ capitalGain: data })
  //   // }
  //   // axios(url, {
  //   //   method: "GET",
  //   //   headers:{
  //   //     Authorization: labels.apiKey.apiKeys,
  //   //     'gw':url,
  //   //     "SessionID": secureStorage.getItem("sessionID"),
  //   //     'Access-Control-Allow-Origin': `${window.globalConfig.Domain}`,
  //   //     'Source': 'Web',
  //   //     'Strict-Transport-Security': 'max-age=31536000; includeSubDomains; preload'
  //   //   },
  //   //   responseType: "blob", //Force to receive data in a Blob Format
  //   // })
  //   //   .then((response) => {
  //   //     //Create a Blob from the PDF Stream
  //   //     const file = new Blob([response.data], {
  //   //       type: `${"application/"}${ext}`,
  //   //     });
  //   //     //
  //   //     //Build a URL from the file
  //   //     // if (ext === "xlsx") {
  //   //     // } else {
  //   //     //   const fileURL = URL.createObjectURL(file);
  //   //     //   //Open the URL on new Window
  //   //     //   window.open(fileURL);
  //   //     // }
  //   //     if (
  //   //       filename === `${value}-${Label.DownloadLabel.capitalgainvaluationreport}pdf`
  //   //     ) {
  //   //       fileDownload(
  //   //         response.data,
  //   //         `${value}-${Label.DownloadLabel.capitalgainvaluationreport}pdf`
  //   //       );
  //   //       var data = []
  //   //       const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //       data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //       data[index] = {
  //   //         ...data[index],
  //   //         accpdf: false
  //   //       }
  //   //       this.setState({ capitalGain: data })
  //   //     }
  //   //     if (
  //   //       filename === `${value}-${Label.DownloadLabel.capitalgainvaluationreport}xlsx`
  //   //     ) {
  //   //       fileDownload(
  //   //         response.data,
  //   //         `${value}-${Label.DownloadLabel.capitalgainvaluationreport}xlsx`
  //   //       );
  //   //       var data = []
  //   //       const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //       data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //       data[index] = {
  //   //         ...data[index],
  //   //         accexcel: false
  //   //       }
  //   //       this.setState({ capitalGain: data })
  //   //     }
  //   //     // if (
  //   //     //   filename === `${Label.DownloadLabel.capitalgainvaluationreport}pdf`
  //   //     // ) {
  //   //     //   fileDownload(
  //   //     //     response.data,
  //   //     //     `${Label.DownloadLabel.capitalgainvaluationreport}pdf`
  //   //     //   );
  //   //     //   var data = []
  //   //     //   const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //     //   data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //     //   data[index] = {
  //   //     //     ...data[index],
  //   //     //     valpdf: false
  //   //     //   }
  //   //     //   this.setState({ capitalGain: data })
  //   //     // }
  //   //     // if (
  //   //     //   filename === `${Label.DownloadLabel.capitalgainvaluationreport}xlsx`
  //   //     // ) {
  //   //     //   fileDownload(
  //   //     //     response.data,
  //   //     //     `${Label.DownloadLabel.capitalgainvaluationreport}xlsx`
  //   //     //   );
  //   //     //   var data = []
  //   //     //   const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //     //   data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //     //   data[index] = {
  //   //     //     ...data[index],
  //   //     //     valexcel: false
  //   //     //   }
  //   //     //   this.setState({ capitalGain: data })
  //   //     // }
  //   //     if (filename === `${value}-${Label.DownloadLabel.mainreportcapitalgain}pdf`) {
  //   //       fileDownload(
  //   //         response.data,
  //   //         `${value}-${Label.DownloadLabel.mainreportcapitalgain}pdf`
  //   //       );
  //   //       var data = []
  //   //       const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //       data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //       data[index] = {
  //   //         ...data[index],
  //   //         gainpdf: false
  //   //       }
  //   //       this.setState({ capitalGain: data })
  //   //     }
  //   //     if (filename === `${value}-${Label.DownloadLabel.mainreportcapitalgain}xlsx`) {
  //   //       fileDownload(
  //   //         response.data,
  //   //         `${value}-${Label.DownloadLabel.mainreportcapitalgain}xlsx`
  //   //       );
  //   //       var data = []
  //   //       const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //       data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //       data[index] = {
  //   //         ...data[index],
  //   //         gainxcel: false
  //   //       }
  //   //       this.setState({ capitalGain: data })
  //   //     }
  //   //     if (filename === `${value}-${Label.DownloadLabel.capitalgaindividend}pdf`) {
  //   //       fileDownload(
  //   //         response.data,
  //   //         `${value}-${Label.DownloadLabel.capitalgaindividend}pdf`
  //   //       );
  //   //       var data = []
  //   //       const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //       data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //       data[index] = {
  //   //         ...data[index],
  //   //         dividendpdf: false
  //   //       }
  //   //       this.setState({ capitalGain: data })
  //   //     }
  //   //     if (filename === `${value}-${Label.DownloadLabel.capitalgaindividend}xlsx`) {
  //   //       fileDownload(
  //   //         response.data,
  //   //         `${value}-${Label.DownloadLabel.capitalgaindividend}xlsx`
  //   //       );
  //   //       var data = []
  //   //       const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //       data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //       data[index] = {
  //   //         ...data[index],
  //   //         dividendxcel: false
  //   //       }
  //   //       this.setState({ capitalGain: data })
  //   //     }
  //   //     return "success";
  //   //   })
  //   //   .catch((error) => {
  //   //     if (error.response?.status == 440 && secureStorage.getItem(`invalidAuth${session}`) == null) {
  //   //       secureStorage.setItem(`invalidAuth${session}`, "invalid")
  //   //   }
  //   //     this.setState({ open: true });
  //   //     if (
  //   //       filename === `${value}-${Label.DownloadLabel.capitalgainvaluationreport}pdf`
  //   //     ) {
  //   //       var data = []
  //   //       const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //       data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //       data[index] = {
  //   //         ...data[index],
  //   //         accpdf: false
  //   //       }
  //   //       this.setState({ capitalGain: data })
  //   //       this.setState({ accpdf: false });
  //   //     }
  //   //     if (
  //   //       filename === `${value}-${Label.DownloadLabel.capitalgainvaluationreport}xlsx`
  //   //     ) {
  //   //       var data = []
  //   //       const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //       data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //       data[index] = {
  //   //         ...data[index],
  //   //         accexcel: false
  //   //       }
  //   //       this.setState({ capitalGain: data })
  //   //       this.setState({ accexcel: false });
  //   //     }
  //   //     // if (
  //   //     //   filename === `${Label.DownloadLabel.capitalgainvaluationreport}pdf`
  //   //     // ) {
  //   //     //   var data = []
  //   //     //   const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //     //   data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //     //   data[index] = {
  //   //     //     ...data[index],
  //   //     //     valpdf: false
  //   //     //   }
  //   //     //   this.setState({ capitalGain: data })
  //   //     //   this.setState({ valpdf: false });
  //   //     // }
  //   //     // if (
  //   //     //   filename === `${Label.DownloadLabel.capitalgainvaluationreport}xlsx`
  //   //     // ) {
  //   //     //   var data = []
  //   //     //   const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //     //   data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //     //   data[index] = {
  //   //     //     ...data[index],
  //   //     //     valexcel: false
  //   //     //   }
  //   //     //   this.setState({ capitalGain: data })
  //   //     //   this.setState({ valexcel: false });
  //   //     // }
  //   //     if (filename === `${value}-${Label.DownloadLabel.mainreportcapitalgain}pdf`) {
  //   //       var data = []
  //   //       const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //       data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //       data[index] = {
  //   //         ...data[index],
  //   //         gainpdf: false
  //   //       }
  //   //       this.setState({ capitalGain: data })
  //   //       this.setState({ gainpdf: false });
  //   //     }
  //   //     if (filename === `${value}-${Label.DownloadLabel.mainreportcapitalgain}xlsx`) {
  //   //       var data = []
  //   //       const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //       data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //       data[index] = {
  //   //         ...data[index],
  //   //         gainxcel: false
  //   //       }
  //   //       this.setState({ capitalGain: data })
  //   //       this.setState({ gainxcel: false });
  //   //     }
  //   //     if (filename === `${value}-${Label.DownloadLabel.capitalgaindividend}pdf`) {
  //   //       var data = []
  //   //       const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //       data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //       data[index] = {
  //   //         ...data[index],
  //   //         dividendpdf: false
  //   //       }
  //   //       this.setState({ capitalGain: data })
  //   //       this.setState({ dividendpdf: false });
  //   //     }
  //   //     if (filename === `${value}-${Label.DownloadLabel.capitalgaindividend}xlsx`) {
  //   //       var data = []
  //   //       const index = this.state.capitalGain.findIndex(emp => emp.FinancialYear == value)
  //   //       data = [...this.state.capitalGain] // important to create a copy, otherwise you'll modify state outside of setState call
  //   //       data[index] = {
  //   //         ...data[index],
  //   //         dividendxcel: false
  //   //       }
  //   //       this.setState({ capitalGain: data })
  //   //       this.setState({ dividendxcel: false });
  //   //     }
  //   //   });
  // };
  handlesnanckClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ open: false });
  };
  render() {
    const { classes } = this.props;
    let combainStyle = Object.assign({}, classes, this.props.externalClasses)
    try {
      return (
        <ErrorBoundary>
          {this.props.isCapitalgainLoading ? (
            <>
              <Loader page="skeletonreport" />
            </>
          ) : (
            <>
              <CommonHeader page={"noHeader"} showfilter={false} />
              {/* Inline Styles are converted into classes by Celsia on 15/05/2021  table changed by dulcy 06/06/2021*/}
              <div>
                <div
                  style={{ marginTop: "5%", width: "100%", marginBottom: "5%" }}
                >
                  <div>
                    {this.state.capitalGain != null &&
                      this.state.capitalGain.length != 0 ? (
                      <>
                        <div
                          className={[
                            combainStyle.taxationFourSplitContainer,
                            combainStyle.tableHeader,
                            combainStyle.desktopResponsive
                          ].join(" ")}
                        >
                          {this.props.title.map((item, index) => {
                            return (
                              <div
                                key={index}
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  textAlign:
                                    item.name == "FINANCIAL YEAR"
                                      ? "left"
                                      : "center",
                                  paddingLeft:
                                    item.name == "FINANCIAL YEAR" ? 10 : 0,
                                }}
                              >
                                {item.name}
                              </div>
                            );
                          })}
                        </div>
                        {this.state.capitalGain.map((val, mainindex) => {
                          return (
                            <div
                              key={mainindex}
                              className={[
                                combainStyle.taxationFourSplitContainer,
                                combainStyle.subCategoryGroupContainer,
                                mainindex == 0 &&
                                combainStyle.borderRadiousFirstIndex,
                              ].join(" ")}
                              style={{ textAlign: "center" }}
                            >
                              <div
                                className={[combainStyle.subCatgoryValue, combainStyle.firstGrid, "firstGrid"].join(" ")}
                                style={{ textAlign: "left", paddingLeft: 10 }}
                              >
                                <span >{val.FinancialYear}</span>
                              </div>
                              <div className={[combainStyle.secondGrid, "secondGrid"].join(" ")}>
                                <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>Valuation Report</span>
                                <div className={combainStyle.taxationColumn}>
                                  <div className={combainStyle.pdfAndExcelIcon}>
                                    <img
                                      src={Pdflogo}
                                      className={combainStyle.pdfimg}
                                      style={{
                                        opacity: val.accpdf ? 0.5 : 1,
                                      }}
                                      onClick={() =>
                                        this.onclickpdf(
                                          val.AccountStatementPDF,
                                          "pdf",
                                          `${val.FinancialYear}-${Label.DownloadLabel.capitalgainvaluationreport}pdf`
                                          , val.FinancialYear)
                                      }
                                    ></img>
                                    {val.accpdf && (
                                      <CircularProgress
                                        size={30}
                                        thickness={1}
                                        className={combainStyle.circular}
                                      />
                                    )}
                                  </div>
                                  <div className={combainStyle.pdfAndExcelIcon}>
                                    <img
                                      src={excelLogo}
                                      className={combainStyle.exlimg}
                                      style={{
                                        opacity: val.accexcel ? 0.5 : 1,
                                      }}
                                      onClick={() =>
                                        this.onclickpdf(
                                          val.AccountStatementExcel,
                                          "xlsx",
                                          `${val.FinancialYear}-${Label.DownloadLabel.capitalgainvaluationreport}xlsx`
                                          , val.FinancialYear)
                                      }
                                    ></img>
                                    {val.accexcel && (
                                      <CircularProgress
                                        size={30}
                                        thickness={1}
                                        className={combainStyle.circular}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className={[combainStyle.thirdGrid, "thirdGrid"].join(" ")}>
                                <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>Capital Gains</span>
                                <div className={combainStyle.taxationColumn}>
                                  <div className={combainStyle.pdfAndExcelIcon}>
                                    <img
                                      src={Pdflogo}
                                      className={combainStyle.pdfimg}
                                      style={{
                                        opacity: val.gainpdf ? 0.5 : 1,
                                      }}
                                      onClick={() =>
                                        this.onclickpdf(
                                          val.CaptialGainPDF,
                                          "pdf",
                                          `${val.FinancialYear}-${Label.DownloadLabel.mainreportcapitalgain}pdf`
                                          , val.FinancialYear)
                                      }
                                    ></img>
                                    {val.gainpdf && (
                                      <CircularProgress
                                        size={30}
                                        thickness={1}
                                        className={combainStyle.circular}
                                      />
                                    )}
                                  </div>
                                  <div className={combainStyle.pdfAndExcelIcon}>
                                    <img
                                      src={excelLogo}
                                      className={combainStyle.exlimg}
                                      style={{
                                        opacity: val.gainxcel ? 0.5 : 1,
                                      }}
                                      onClick={() =>
                                        this.onclickpdf(
                                          val.CaptialGainExcel,
                                          "xlsx",
                                          `${val.FinancialYear}-${Label.DownloadLabel.mainreportcapitalgain}xlsx`
                                          , val.FinancialYear)
                                      }
                                    ></img>
                                    {val.gainxcel && (
                                      <CircularProgress
                                        size={30}
                                        thickness={1}
                                        className={combainStyle.circular}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/* {this.props.flag != "equity" && */}
                              <div className={[combainStyle.fourthGrid, "fourthGrid"].join(" ")}>
                                <span className={[combainStyle.mobileResponsive, combainStyle.labelStyle].join(" ")}>Dividend Report</span>
                                <div className={combainStyle.taxationColumn}>
                                  <div className={combainStyle.pdfAndExcelIcon}>
                                    <img
                                      src={Pdflogo}
                                      className={combainStyle.pdfimg}
                                      style={{
                                        opacity: val.dividendpdf ? 0.5 : 1,
                                      }}
                                      onClick={() =>
                                        this.onclickpdf(
                                          val.DividendPDF,
                                          "pdf",
                                          `${val.FinancialYear}-${Label.DownloadLabel.capitalgaindividend}pdf`
                                          , val.FinancialYear)
                                      }
                                    ></img>
                                    {val.dividendpdf && (
                                      <CircularProgress
                                        size={30}
                                        thickness={1}
                                        className={combainStyle.circular}
                                      />
                                    )}
                                  </div>
                                  <div className={combainStyle.pdfAndExcelIcon}>
                                    <img
                                      src={excelLogo}
                                      className={combainStyle.exlimg}
                                      style={{
                                        opacity: val.dividendxcel ? 0.5 : 1,
                                      }}
                                      onClick={() =>
                                        this.onclickpdf(
                                          val.DividendExcel,
                                          "xlsx",
                                          `${val.FinancialYear}-${Label.DownloadLabel.capitalgaindividend}xlsx`
                                          , val.FinancialYear)
                                      }
                                    ></img>
                                    {val.dividendxcel && (
                                      <CircularProgress
                                        size={30}
                                        thickness={1}
                                        className={combainStyle.circular}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                              {/* } */}
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <div
                        style={{
                          marginTop: "-3.2%",
                          borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
                          paddingBottom: "1.9%",
                          float: "left",
                          marginLeft: "1.5%",
                          width: "97%",
                          textAlign: "left !important",
                        }}
                      >
                        <div style={{ justifyContent: "left", marginLeft: "-80%" }}>
                          {" "}
                          <NoData page={"captialgain"} />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <CommonSnackbar
                handlesnanckClose={this.handlesnanckClose}
                severity={"warning"}
                message={Label.messages.apierror}
                open={this.state.open}
              />
            </>
          )}
        </ErrorBoundary>
      );
    } catch (err) {
      return <Redirect to={labels.Path.NotFound} />;
    }
  }
}

export default withStyles(
  (theme) => ({
    ...styles(theme),
    ...CommonTableStyle(theme),
  }),
  { withTheme: true }
)(connect(null, null)(CapitalGain));
