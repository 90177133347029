import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
import { Encryption } from '../../utils/commonfunction';
import { Asset } from '../../utils/mainasset';
function ResetPassword(email, Password, ClientID) {

  let data = {};
  data.EmailID = email;
  data.ClientID = ClientID;
  data.Password = Encryption(Password)
  data.Source = Asset.source
  // console.log(data)
  // Encryption(Password);

  //   var config = {
  //     method: 'post',
  //     url: ApiValues.ResetPassword,
  //     headers: { 
  //       'Content-Type': 'application/json'
  //     },
  //     data : data
  //   };

  //  return axios(config)
  //   .then(function (response) {
  //     return JSON.stringify(response.data);
  //   })
  //   .catch(function (error) {
  //     return { "status": "F", "message": labels.messages.apierror }
  //   });
  return Networking.PostApi(ApiValues.ResetPassword, data, labels.apiKey.apiKeys, labels.apiKey.apiKeys, true).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default ResetPassword

