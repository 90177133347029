import React, { useLayoutEffect, useState } from 'react';
import PaymentStepper from '../../components/payment/stepper'
import { withRouter } from "react-router";
import Menu from '../../components/menu/menu'
import Footer from '../../components/footer/footer';
function Payment(props) {
  // const [width, height] = useWindowSize();
  return (<>
      <div style={{height:"100%"}}>
          <PaymentStepper/>
      </div>
        </>

  );
}
export default withRouter(Payment);