import React from 'react';
import Carousel from 'react-elastic-carousel';
import Card from './cardcarousel';
import { withStyles } from '@material-ui/core/styles';
import '../../utils/stylesheets/layout/layout.css';
const useStyles = (theme) => ({
  //styles added by yogitha on 31/05/2021 for mobile responsive
  desktopResponsive: {
    display: 'block !important',
    [theme.breakpoints.down("600")]: {
      display: 'none !important',
    },
  },
  mobileResponsive: {
    display: 'none !important',
    [theme.breakpoints.down("600")]: {
      display: 'block !important',
      width: '105%',
    },
  },
});
class Carosal extends React.Component {
  render() {
    const { classes } = this.props;
    return (<div >
      <div id="carousal-desktopResponsive" className={classes.desktopResponsive}>
        <Carousel itemsToShow={3} >
          {this.props.data.map((val,index) => {
            return <Card key={index} onClick={this.props.onClick} Schemecode={val?.mf_schcode||0} color={val.color} title={val.SchemeName} W={val.M6Return != "" && typeof (val.M6Return) != "object" ? val.M6Return.toFixed(2) : 0} Y={val.Y1Return != "" && typeof (val.Y1Return) != "object"  ? val.Y1Return.toFixed(2) : 0} TY={val.Y3Return != "" && typeof (val.Y3Return) != "object" ? val.Y3Return.toFixed(2) : 0} ></Card>

          })}
        </Carousel>
      </div>
      <div id="carousal-mobileResponsive" className={classes.mobileResponsive}>
        <Carousel className={classes.mobileResponsive} itemsToShow={1} >
          {this.props.data.map((val,index) => {
            return <Card key={index} onClick={this.props.onClick} Schemecode={val?.mf_schcode||0} color={val.color} title={val.SchemeName} W={val.M6Return != "" && typeof (val.M6Return) != "object"? val.M6Return.toFixed(2) : 0} Y={val.Y1Return != "" && typeof (val.Y1Return) != "object" != 0 ? val.Y1Return.toFixed(2) : 0} TY={val.Y3Return != "" && typeof (val.Y3Return) != "object" ? val.Y3Return.toFixed(2) : 0} ></Card>

          })}
        </Carousel>
      </div>
    </div>
    );
  }
}
export default withStyles(useStyles)(Carosal);
