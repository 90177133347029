import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
//action created by dulcy
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
function encryptKYC(Email, Mobile) {
  const BaseUrl = ApiValues.EncryptedParameter + `?email=${Email}&mobile=${Mobile}`


  //AsOnDate
  return Networking.GetApi(BaseUrl, "").then(responseJson => {
    
      return responseJson
   
  })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
  // return fetch(BaseUrl, {
  //   method: 'GET',
  //   headers: {
  //     'Accept': 'application/json',
  //     'Content-Type': 'application/json',
  //   },
  // })
  //   .then((response) => response.json())
  //   .then((responseJson) => {
  //     if(responseJson.status=="S"){
  //       window.open(`${ApiValues.KYCDomain}${"/MFSelfKYC/ClientSignUp?x1="}${responseJson.encryptedEmail}&x2=${responseJson.encryptedMobile}`,"_self")
  //       return  responseJson
  //     }else{
  //     return  responseJson
  //     }
  //   })
  //   .catch((error) => {
  //     return{status:"F",message:labels.messages.apierror}
  //   });
}
export default encryptKYC
