import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
import { Encryption } from '../../utils/commonfunction';
function getL5report(clientid) {
    let encryptClientID = Encryption(clientid?.toString())
    const BaseUrl = ApiValues.L5reportdata + `?clientID=${encryptClientID}`
    return Networking.GetApi(BaseUrl).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });

    // fetch method ends here

}
export default getL5report
