import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Text from '../text/text';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import './popup.css';
import CommonPopup from '../popup/popup';
import NoData from '../nodata/nodata';
import { changeUcccode } from '../../redux/actions/profile/profile'
import color from "../../utils/colors/colors";
import ErrorBoundary from "../Errorhandling/Errorhandle";
import label from "../../utils/constants/labels";
import labels from "../../utils/constants/labels";
import Loader from "../../components/loader/loader";
import { Redirect } from "react-router";
import Skeleton from '@material-ui/lab/Skeleton'
import Badge from '../badge/badge';
const styles = (theme) => ({

  col: {
    display: "flex",
    flexDirection: "column",
  },
  colheader1: {
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: "14px",
    textAlign: "left",
  },
  colheader2: {
    textAlign: "left",
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontSize: "14px",
  },

  accountDetailsCard: {
    [theme.breakpoints.down("xl")]: {
      display: "flex",
      flexDirection: "column",
      marginBottom: "3%",
    },
  },

  cardSpareaterLine: {
    height: 0.5,
    width: "100%",
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
  },

  accountDetailsRow: {
    display: "grid",
    // marginLeft:'45%',
    //marginTop:'-2%',
    gridTemplateColumns: "repeat(1,1fr)",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-3%",
      gridTemplateColumns: "repeat(auto-fill, 159px)",
    },
  },
  accountHeader: {
    display: "flex",
    alignItems: "center",
    gap: 5,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      whiteSpace: "nowrap",
      flexWrap: "wrap",
    },
  },

  textAlignmentResponsive: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-4%",
    },
  },

  UccStyle: {
    fontSize: 14,
    color: window.globalConfig.color.BLACK_PRIMARY,
    // marginTop: 5,
    // marginLeft: 10,
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    gap: 5,
    whiteSpace: "nowrap",
    [theme.breakpoints.down("350")]: {
      marginLeft: "-1px",
    },
  },
});
class AccountDetails extends React.Component {
  isComponentMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      AnchorEl: null,
      account: "",
      accountno: "",
      AccountDetails: [],
      JointHolder: [],
      UCCCode: "",
    };
  }
  async componentDidMount() {
    this.isComponentMounted = true;
    //   if (this.isComponentMounted) {
    if (
      this.props.ProfileDetails != labels.messages.NA &&
      this.props.ProfileDetails.length != 0
    ) {
      this.setState({
        account: `${this.props.ProfileDetails[0].Name}`,
        AccountDetails: this.props.ProfileDetails,
        accountno: this.props.ProfileDetails[0].L5ID,
        UCCCode: this.props.ProfileDetails[0].MutualFundUCC,
      });
    }
    if (this.props.JointHolder != labels.messages.NA && this.props.JointHolder.length != 0) {
      this.setState({ JointHolder: this.props.JointHolder });
    }
  }
  componentWillUnmount() {
    this.isComponentMounted = false;
  }
  componentDidUpdate(prevProps) {
    const { ProfileDetails, JointHolder } = this.props;
    if (prevProps.ProfileDetails != ProfileDetails) {
      this.setState({ AccountDetails: ProfileDetails })
    }
    if (prevProps.JointHolder != JointHolder) {
      this.setState({ JointHolder: JointHolder })
    }
  }
  handleClose = () => {
    //  if (this.isComponentMounted) {
    this.setState({ AnchorEl: null });
    //}
  };

  handleClick = (event) => {
    // if (this.isComponentMounted) {
    this.setState({ AnchorEl: event.currentTarget });
    //  }
  };
  render() {
    // 
    const { classes } = this.props;
    try {
      return (
        <>
          <ErrorBoundary>
            <div>
              <div >
                <div className={classes.accountDetailsRow}>
                  <div className={classes.accountHeader}>

                    <Text label={label.accountDetails.AccountDetails} />

                    {
                      !this.props.isLoading ? this.props.Ucc != "" && this.props.Ucc != null && this.props.uccList.length != 0 && (<span className={classes.UccStyle}>
                        ({labels.profile.ucc} {this.props.Ucc})
                        <Badge
                          page={"primary"}
                          verified={this.props.uccList.filter((item) => item.ClientCode == this.props.Ucc)[0]?.UCCStatus == "ACTIVE" ? true : false}
                          text={
                            this.props.uccList.filter((item) => item.ClientCode == this.props.Ucc)[0]?.UCCStatus || labels.messages.NA
                          }></Badge>
                      </span>) : <Skeleton style={{ width: "55%", height: "5vh" }} />
                    }
                  </div>
                </div>
                {this.props.isLoading ? (
                  <Loader page="tablerow" />
                ) : this.state.AccountDetails != null ? (
                  this.state.AccountDetails.length != 0 &&
                    this.state.AccountDetails.filter(
                      (item) => item.UCC === this.props.Ucc
                    ).length != 0 ? (
                    // this.state.AccountDetails.filter((item) => item.MutualFundUCC === this.props.Ucc).map((val, index) => {
                    //     return (
                    <div className={classes.accountDetailsCard}>
                      <div className={"contentcolumnProfile"}>
                        <>
                          {/* {console.log(this.state.AccountDetails)} */}
                          <Text
                            label={label.accountDetails.ModeofHoldings}
                            text={
                              this.state.AccountDetails.filter(
                                (item) => item.UCC === this.props.Ucc
                              )[0].HoldingNature
                            }
                            page={"normal"}
                          />

                          <Text
                            label={label.accountDetails.TaxStatus}
                            text={
                              this.state.AccountDetails.filter(
                                (item) => item.UCC === this.props.Ucc
                              )[0].TaxStatus
                            }
                            page={"normal"}
                          />
                          <Text
                            label={label.accountDetails.MemberSince}
                            text={
                              this.state.AccountDetails.filter(
                                (item) => item.UCC === this.props.Ucc
                              )[0].MemberSince
                            }
                            page={"normal"}
                          />

                          <Text
                            label={label.accountDetails.Gender}
                            text={
                              this.state.AccountDetails.filter(
                                (item) => item.UCC === this.props.Ucc
                              )[0].Gender
                            }
                            page={"normal"}
                          />
                          <Text
                            label={label.accountDetails.Nationality}
                            text={
                              this.state.AccountDetails.filter(
                                (item) => item.UCC === this.props.Ucc
                              )[0].Nationality
                            }
                            page={"normal"}
                          />
                          {/* {console.log(this.state.AccountDetails.filter(
                            (item) => item.UCC === this.props.Ucc
                          ))} */}
                          <Text
                            label={"Email"}
                            text={
                              this.state.AccountDetails.filter(
                                (item) => item.UCC === this.props.Ucc
                              )[0].EmailID || labels.messages.NA
                            }
                            page={"normal"}
                          />
                          <Text
                            label={"Mobile No"}
                            text={
                              this.state.AccountDetails.filter(
                                (item) => item.UCC === this.props.Ucc
                              )[0].MobileNo || labels.messages.NA
                            }
                            page={"normal"}
                          />
                        </>
                      </div>
                    </div>
                  ) : (
                    //     )
                    // }
                    // )
                    <div style={{ paddingTop: 30, paddingBottom: 40 }}>
                      <NoData />
                    </div>
                  )
                ) : (
                  <div>
                    <NoData />
                  </div>
                )}
              </div>
              <div className={classes.cardSpareaterLine}></div>
              <div

                style={{ marginBottom: 7, marginTop: 24 }}
              >
                <div className={classes.textAlignmentResponsive}>
                  <Text label={label.accountDetails.JointHolder} />
                </div>
                {/* {} */}
                {this.props.isLoading ? (
                  <Loader page="tablerow" />
                ) : this.state.JointHolder.length === 0 ||
                  this.state.JointHolder.filter(
                    (item) => item.UCC === this.props.Ucc
                  ).length == 0 ? (
                  <div style={{ paddingTop: 30, paddingBottom: 30 }}>
                    <NoData />
                  </div>
                ) : (
                  this.state.JointHolder.filter(
                    (item) => item.UCC === this.props.Ucc
                  ).map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={classes.textAlignmentResponsive}
                      >
                        <div className={"desktopResponsive"}>
                          <div className={"contentcolumnProfileRowColumn"}>
                            <Text
                              label={label.accountDetails.Name}
                              text={item.ClientName}
                              page={"normal"}
                            />

                            <Text
                              label={label.accountDetails.PAN}
                              text={item.PAN}
                              page={"normal"}
                            />
                            <Text
                              label={label.accountDetails.DateofBirth}
                              text={item.DOB}
                              page={"normal"}
                            />
                            <Text
                              label={"Email"}
                              text={item.EmailID || labels.messages.NA}
                              page={"normal"}
                            />
                            <Text
                              label={"Mobile No"}
                              text={item.MobileNo || labels.messages.NA}
                              page={"normal"}
                            />
                          </div>
                        </div>
                        <div className={"mobileResponsive"}>
                          <div className={"contentcolumnProfileRowColumn"}>
                            <Text
                              label={label.accountDetails.Name}
                              text={item.ClientName}
                              page={"normal"}
                            />
                            <div className={classes.col}>
                              <span className={classes.colheader1}></span>
                              <span className={classes.colheader2}></span>
                            </div>
                            <Text
                              label={label.accountDetails.PAN}
                              text={item.PAN}
                              page={"normal"}
                            />
                            <Text
                              label={label.accountDetails.DateofBirth}
                              text={item.DOB}
                              page={"normal"}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          </ErrorBoundary>
        </>
      );
    } catch (err) {
      // console.log(err)
      return <Redirect to={labels.Path.NotFound} />;
    }
  }
}
const mapStateToProps = (state) => ({
  ProfileDetails:
    state.ProfileDetails.AllProfileDetails.length != 0
      ? state.ProfileDetails.AllProfileDetails.accountDetails != "NA"
        ? state.ProfileDetails.AllProfileDetails.accountDetails
        : []
      : [],
  JointHolder:
    state.ProfileDetails.AllProfileDetails.length != 0
      ? state.ProfileDetails.AllProfileDetails.jointHolderDetails != "NA"
        ? state.ProfileDetails.AllProfileDetails.jointHolderDetails
        : []
      : [],
  logindetails: state.Login.logindetails,
  isLoading: state.ProfileDetails.isLoading,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ changeUcccode }, dispatch);
};
export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(AccountDetails)
);
