import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import Dropdown from '../../components/dropdown/dropdownschemes'
import CustomButton from '../../components/custombutton/button'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import labels from '../../utils/constants/labels'
import Textinput from '../../components/textinput/textinput';
import Text from '../../components/text/text';
import Button from '@material-ui/core/Button';
import Question from "../../utils/assets/images/img5.png";
import ArrowIcon from "../../utils/assets/images/img14.png";
import color from "../../utils/colors/colors";
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import { RedemptionChange } from '../../redux/actions/filter/reportsfilter'
import { bindActionCreators } from 'redux';
import { scrollToTopPage } from '../../utils/commonfunction';
import colors from '../../utils/colors/colors';
import { NegativeFinding } from '../../utils/commonfunction';
const styles = theme => ({
  collabel: {
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: "14px",
    textAlign: "left",
  },
  btnApply: {
    background: window.globalConfig.color.BROWN,
    marginLeft: "7px",
    padding: 0,
    lineHeight: "36px",
    color: window.globalConfig.color.WHITE,
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    width: "100px",
    float: "right",
    borderRadius: "5px",
    border: "1px solid transparent",
    '&:hover': {
      background: window.globalConfig.color.PRIMARY_LIGHT,
      color: window.globalConfig.color.WHITE,
    }
  },
  btnBack: {
    background: window.globalConfig.color.RosyBrown,
    marginLeft: "7px",
    padding: 0,
    lineHeight: "36px",
    color: "black",
    fontWeight: 600,
    fontSize: "14px",
    lineHeight: "36px",
    width: "100px",
    float: "right",
    borderRadius: "5px",
    border: "1px solid transparent",

  },
  divMain: {
    display: "flex", flexDirection: "row", marginTop: "3%", marginLeft: "9%", marginRight: "3%", marginBottom: "4%", paddingBottom: "3%"
  },
  divDetail: {
    height: 'auto', width: "60%", backgroundColor: window.globalConfig.color.WHITE, marginLeft: "7%", marginBottom: "4%", border: `1px solid ${window.globalConfig.color.BORDER}`, boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.3)", paddingBottom: "30px"
  },
  divLine: {
    height: "0.5px",
    width: "100%",
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    marginTop: "3%",
    marginBottom: "-4%"
  },
  redemptionHeader: {
    float: "left",
    fontWeight: "bolder"
  },
  redemptionText: {
    fontSize: 14,
    marginTop: '-2.3%',
    marginLeft: '-68%',
    color: window.globalConfig.color.LABLE_GRAY
  },
  schemeText: {
    fontSize: 14,
    color: window.globalConfig.color.LABLE_GRAY
  },
  redeemBank: {
    marginTop: "8%",
    marginLeft: "2.5%"
  },

  schemeNamealignResponsive: {
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
    textAlign: 'left',
    padding: 10,
    fontSize: "14px"
  },
  folionoResponsive: {
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
    height: 30,
    padding: 10,

  },
  redeemFromResponsive: {
    margin: "4.5%"
  },


  boxSub: {
    textAlign: 'left',
    fontSize: 12,
    paddingLeft: 10,
    paddingRight: 10
  },
  redeemptionTitle: {
    padding: '2% 5%',
    [theme.breakpoints.down("xs")]: {
      padding: '4% 3% 3% 3%',
    },
  },
  redeemtionBody: {
    padding: '1% 5% 1% 5%'
  },
  foliono: {
    width: "94.3%",
    paddingTop: '3%',
    backgroundColor: window.globalConfig.color.RosyBrown,
    minHeight: 35,
    padding: 10,
    gap: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    [theme.breakpoints.down('600')]: {
      backgroundColor: window.globalConfig.color.RosyBrown,
      // height: 30,
      padding: 10,
      flexDirection: "column",
      height: "max-content"
    },
  },
  schemeNamealign: {
    width: "94%",
    border: `1px solid ${window.globalConfig.color.BORDER}`,
    textAlign: 'left',
    padding: 10,
  },
  folioBlock: {
    float: 'left',
    padding: 2,
    backgroundColor: window.globalConfig.color.BORDER,
    borderRadius: 100,
    fontSize: 13,
    fontWeight: 400,
    minWidth: '40%',
    textTransform: "none !important",
    [theme.breakpoints.down("md")]: {
      minWidth: "50%",
    },
    [theme.breakpoints.down("600")]: {
      minWidth: "100%",
    },
  },
  redeemdropdownContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '47% 47%',
    gap: 20,
    marginTop: '4%',
    // columnCount: '2',
    [theme.breakpoints.down("600")]: {
      Width: "96%",
      display: 'grid',
      gridTemplateColumns: '100%',
      gap: 0,
      // columnCount: '1',
    },
  },
  btnBlock: {
    marginTop: "8%", marginRight: 21,
    [theme.breakpoints.down("600")]: {
      marginRight: 'revert'
    },
  },
  amountInput: {
    marginTop: "1.4rem",

  },
  folioDropdownAndUnits: {
    display: "grid", gridTemplateColumns: "30% 70%", gap: 10, alignItems: "center", width: "-webkit-fill-available"
    , [theme.breakpoints.down("700")]: {
      gridTemplateColumns: "100%",
      alignItems: "flex-start",

    }
  },
  totalUnits: {
    display: "flex", flexDirection: "row", gap: 10, alignItems: "center",
    [theme.breakpoints.down("700")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    }
  }
  // end
});
// class name redemptiondetails to RedemptionDetails ///changed by dulcy
class RedemptionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setAnchorEl: null,
      amountValue: "",
      availableUnit: 0,
      amountDisabled: false,
      isAllAvailableUnit: false,
      errorenable: false, errormessage: "",
      redemptiontype: "Amount (Rs)",
      units: "",
      redemptionTypeList: []
    }
  }
  componentDidMount() {

    scrollToTopPage();
    if (this.props.Redemptionlist != undefined) {
      if (this.props.Redemptionlist.length != 0) {
        if (this.props.Redemptionlist.filter((item) => item.isChecked == true
        ).length != 0) {
          this.setState({ redemptionTypeList: [...this.props.Redemptionlist] })
        }
      }
      else {
        this.props.RedemptionChange([], labels.TradingType)
        //  this.setState({ redemptionTypeList: [...this.props.Redemptionlist] })
      }
    }
    else {
      this.props.RedemptionChange([], labels.TradingType)
      //this.setState({ redemptionTypeList: [...this.props.Redemptionlist] })
    }
  }
  onConfirmOrder = () => {
    if (this.state.redemptiontype === "Amount (Rs)") {
      if (this.state.amountValue > 1000) {
        if (this.state.amountValue < this.props.data.currentvalue) {
          this.props.confirmOrder(true);

        } else {
          this.setState({ errorenable: true, errormessage: `Amount should be less than ${NegativeFinding(this.props.data.currentvalue, "", "", "", "rupee")}` })

        }

      } else {
        this.setState({ errorenable: true, errormessage: `minimum Amount ${NegativeFinding(1000, "", "", "", "rupee")}` })
      }
    } else if (this.state.redemptiontype === "Custom No. of Units") {
      if (this.state.units > 10) {
        if (this.state.units < this.props.data.units) {
          this.props.confirmOrder(true);

        } else {
          this.setState({ errorenable: true, errormessage: `Units should be less than  ${NegativeFinding(this.props.data.units, "", "", "", "number")}` })

        }
      } else {
        this.setState({ errorenable: true, errormessage: `minimum Units 10` })

      }
    }
  }

  handleClick = (event) => {
    this.setState({
      setAnchorEl: event.currentTarget
    })
  };

  handleClose = () => {
    this.setState({
      setAnchorEl: null
    })
  };
  handlefolio = (val) => {
    this.props.handleClickfolio(val)
    this.handleClose()
  }

  render() {
    const { classes } = this.props;
    const open = Boolean(this.state.setAnchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      <ErrorBoundary>
        <Grid>
          <Grid xs={12} >
            <Grid xs={1} ></Grid>
            <Grid xs={10} className={classes.redeemptionTitle} >
              <Text label={labels.Redemption.redempationHeader} />
            </Grid>
            <Grid xs={1} ></Grid>
          </Grid>
          <Grid xs={12}>
            <Grid item xs={12} className={classes.redeemtionBody} >
              <div>
                <div style={{ textAlign: "left" }}>
                  <span className={classes.schemeText}>{labels.Redemption.schemeTo}</span>
                </div>
                <div className={classes.schemeNamealign} style={{ marginTop: '1.2%' }}>
                  <span style={{ textAlign: 'left', fontSize: 14 }}>{this.props.data.SchemeName}</span>
                </div>
                <div className={classes.foliono}>
                  <div className={classes.folioDropdownAndUnits}>
                    <Button onClick={this.handleClick} className={classes.folioBlock}>
                      Folio: {this.props.folionumber == "" ? "NA" : this.props.folionumber} <img src={ArrowIcon} style={{ marginLeft: '5%' }}></img>
                    </Button>
                    {this.props.UCC != "" ? <p style={{ fontSize: 14, textAlign: "left" }}>UCC :<span> {this.props.UCC || "NA"}</span></p> : <div style={{ fontSize: 12, color: colors.red, textAlign: "left" }}>BSE star UCC is not mapped to this Folio</div>}
                  </div>
                  <div className={classes.totalUnits}>
                    <p style={{ fontSize: 14, paddingRight: 14 }}>Total Amount :<span> {this.props.totalTable.length != 0 && this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber).length != 0 ? <>{NegativeFinding(this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber)[0].TotalAvailableAmount, "", "", "", "rupee")}</> : `${labels.currency.rupeesymbol}0`}</span></p>
                    <p style={{ fontSize: 14 }}>Total Units :<span> {this.props.totalTable.length != 0 && this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber).length != 0 ? <> {NegativeFinding(this.props.totalTable.filter((item) => item.FolioNo === this.props.folionumber)[0].TotalAvailableUnits, "", "", "", "number")}</> : `0`}</span></p>
                  </div>
                  {this.props.foliodropdown.length != 0 &&

                    <ClickAwayListener>
                      <Menu
                        id="simple-menu"
                        anchorEl={this.state.setAnchorEl}
                        keepMounted
                        open={Boolean(this.state.setAnchorEl)}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                        onClose={this.handleClose}
                      >
                        {this.props.foliodropdown.map((item) => {
                          return <MenuItem onClick={() => this.handlefolio(item)}>Folio : {item.FolioNo}</MenuItem>

                        })}
                      </Menu>
                    </ClickAwayListener>}
                </div>

              </div>




              <Grid className={classes.redeemdropdownContainer}>

                <Grid xs={12}>
                  <Dropdown
                    type={"normalwithsearch"}
                    value={this.props.redemptiontype}
                    autoselect={true}
                    handleOnChange={this.props.redemTypeChange}
                    label={labels.Redemption.redemptionType}
                    List={this.props.Redemptionlist} />
                </Grid>


                <Grid xs={12} className={classes.amountInput}>

                  {this.props.redemptiontype == "Amount (Rs)" && <div  >

                    <Textinput
                      error={this.props.errormessage}
                      message={this.props.availableUnit == 0 ? `${"Minimum Amount "} ${labels.currency.Rupee} 1000 ` : `All Available No. of Units: ${this.props.availableUnit}`}
                      disabled={this.props.amountDisabled}
                      placeholder={`${labels.currency.Rupee}${" Enter Amount"}`}
                      handleChange={this.props.amountChange}
                      value={this.props.amountValue}
                      onKeyPress={this.currencyruppe}
                      page="normal" />

                  </div>}
                  {/* {console.log(this.props.totalTable)} */}
                  {this.props.redemptiontype == "Custom No. of Units" && <div>

                    <Textinput error={this.props.errormessage} message={"Minimum Units 10"} disabled={false} placeholder="Enter Units" handleChange={this.props.unitsChange} value={this.props.units} page="normal" />
                  </div>}
                  {this.props.redemptiontype == "All Available No. of Units" &&

                    <Textinput disabled={true} value={this.props.totalTable?.length == 0 ? 0 : this.props.totalTable.filter((item) => item.FolioNo == this.props.folionumber)[0]?.TotalAvailableUnits || 0} message={`${"Approx. Value"}${this.props.totalTable.length == 0 ? NegativeFinding(0, "", "", "", "rupee") : this.props.totalTable.filter((item) => item.FolioNo == this.props.folionumber)[0]?.TotalAvailableAmount != "" ? NegativeFinding(this.props.totalTable.filter((item) => item.FolioNo == this.props.folionumber)[0]?.TotalAvailableAmount, "", "", "", "rupee") : NegativeFinding(0, "", "", "", "rupee")}`} error="" page="normal" />


                  }
                  {this.props.redemptiontype == "All Exit Load Free Units" &&

                    <Textinput disabled={true} value={this.props.totalTable?.length == 0 ? 0 : this.props.totalTable.filter((item) => item.FolioNo == this.props.folionumber)[0]?.TotalExitLoadUnits || 0} page="normal" />

                  }
                  {this.props.redemptiontype == "All Short Term Gain Units" &&
                    <Textinput disabled={true} value={this.props.totalTable?.length == 0 ? 0 : this.props.totalTable.filter((item) => item.FolioNo == this.props.folionumber)[0]?.TotalShortTermUnits || 0} page="normal" />

                  }
                  {this.props.redemptiontype == "All Long Term Gain Units" &&

                    <Textinput disabled={true} value={this.props.totalTable?.length == 0 ? 0 : this.props.totalTable.filter((item) => item.FolioNo == this.props.folionumber)[0]?.TotalLongTermUnits || 0} page="normal" />
                  }

                </Grid>
              </Grid>

            </Grid>
          </Grid>

        </Grid>
        <div className={classes.divLine}></div>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <div className={classes.btnBlock}>
            <CustomButton page="primary" onClick={this.props.onConfirmOrder} name={"Next"} disabled={this.props.UCC == "" ? true : false}></CustomButton>

          </div>

        </div>

      </ErrorBoundary>



    );
  }
}
const mapStateToProps = state => {
  return {
    Redemptionlist: state.Filter.Redemptionlist
  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ RedemptionChange }, dispatch)
}
export default withStyles(styles)(withRouter(connect(mapStateToProps, mapDispatchToProps)(RedemptionDetails)))
