import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { connect } from 'react-redux';
import Summary from '../../components/summary/index';
import Table from '../../components/table/profitlossreport';
import Portifolio from '../portfolio-x-ray/portfolio-x-ray'
import CapitalGain from '../capitalgain/capitalgain'
import AccountStatement from '../accountstatement/accountstatement'
import { replaceNull, sortArray } from '../../utils/commonfunction';
import Transaction from '../transaction/transactionreport'
import '../../utils/stylesheets/tabs/tabs.css'
import colors from '../../utils/colors/colors';
import secureStorage from '../../utils/securestorage';
import labels from '../../utils/constants/labels';
import Label from '../../utils/constants/labels';
import constants from '../../utils/constants/apiconstant';
import combineStyle from "../../utils/stylesheets/combainstyle/combainstyle";
import commonstyletable from "../../utils/stylesheets/table/commontablestyle";
import MaturityTracker from '../maturity_tracker/maturitytracker'
import {
  FDFilterChange,
} from "../../redux/actions/portfolioxray/portfolioxray";
import { bindActionCreators } from "redux";
import { fd_transactionfilter, } from '../../redux/actions/transaction/actions';
import { Fdprofitandlossfilter, getProfitLossDetails } from '../../redux/actions/profitloss/profitlossaction'
import { filterMaturityTracker } from '../../redux/actions/maturitytracker/maturitytrackeraction';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Typography>{children}</Typography>      
        <div
          style={{
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: '0.00938rem',
          }}
        >{children}</div>

      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const Styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
  },
  tabRoot: {
    "& .MuiTab-root:focus": {
      color: window.globalConfig.color.BROWN
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: window.globalConfig.color.BROWN
    }
  },
  portfolioXrayCatrgorySevenSplitContainer: {
    display: "grid",
    gridTemplateColumns: "36% 16% 16% 16% 16% !important",
    // paddingLeft: "10px"
    marginLeft: "20px",
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr) !important',
      gridTemplateAreas: `"categorySchemeGrid categoryInvestedGrid"
      ". categoryNetpl"
     `,
    }
  },
  portfolioXraySevenSplitContainer: {
    display: "grid",
    gridTemplateColumns: "36% 16% 16% 16% 16%",
    marginLeft: 20,
    [theme.breakpoints.down("920")]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr) !important',
      gap: 1,
      gridTemplateRows: 'auto',
      paddingRight: 10,
      gridTemplateAreas: `"schemeFirstGrid  . schemeFifthGrid"
  "schemeSecondGrid . schemeSixthGrid"
  "schemeThirdGrid . investedAmountGrid"`,
      "& .schemeFourthGrid": {
        display: "none"
      }
    },
  },
  profitLossContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(6,1fr)",
    marginLeft: "7px",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr)",
      gridTemplateAreas: `"${"firstGrid"} ${"sixthGrid"}"
                         "${"thirdGrid"} ${"fourthGrid"}"
                         "${"secondGrid"} ${"fifthGrid"}"`,
    }
  },
  transactionContainer: {
    display: "grid",
    gridTemplateColumns: "10% 35% 15% 20% 20%",
    marginLeft: "20px",
    alignContent: "center",
    [theme.breakpoints.down("920")]: {
      gridTemplateColumns: "repeat(2,1fr)",
      gridTemplateAreas: `"${"dategrid"} ${"dategrid"}"
      "${"schemegrid"} ${"schemegrid"}"
      "${"assetClassgrid"} ${"transactionTypegrid"}"
      "${"folioNumbergrid"} ${"unitgrid"}"
      "${"navgrid"} ${"amountgrid"}"`,
      padding: 10,
      gap: 10
    }
  },
});
const portfolioxraytitle = [{ "name": labels.Tableheader.AssetName },
{ "name": labels.Tableheader.InvestedValue, "subheader": labels.Tableheader.amount },
{ "name": labels.Tableheader.CurrentValue, "subheader": labels.Tableheader.Subheadingcurrent }, { "name": labels.Tableheader.unrealisedint, "subheader": "Interest Payout" }, { "name": labels.Tableheader.Net, "subheader": labels.Tableheader.ROI }]
const accountstatementtitle = [{ "name": labels.Accountstatement.Date }, { "name": labels.Accountstatement.Txntype, "subheader": labels.Accountstatement.Txnnature }, { "name": labels.Accountstatement.Amount }, { "name": labels.Accountstatement.quantity }, { "name": labels.Accountstatement.Price }, { "name": labels.Accountstatement.Balquantity }]
class FDTab extends React.Component {
  // const classes = useStyles();
  // const{page}=props;
  // const [value, setValue] = React.useState(0);
  //const [data, setData]= React.useState('');

  // useEffect(()=>{
  // //setValue(props.page.state.tabValue);
  //   // return()=>{
  //   //   handleChange
  //   // }
  // },[props])
  constructor(props) {
    super(props);
    this.state = {
      value: 0,
      YearCode: "All"
    }
  }

  componentDidMount() {
    document.title = window.globalConfig.companyDetails.companyName + " - Fixed Income"
    const { page, xray } = this.props;
    // 
    // console.log(page)
    //if (prev.page != page) {
    if (page.state != undefined) {
      // if (prev.page.state.tabValue != page.state.tabValue) {
      this.setState({ value: page.state.tabValue })
      // }
    }
    //}


  }

  componentDidUpdate(prev) {
    const { page } = this.props;
    // 

    if (prev.page != page) {
      if (prev.page.state != undefined) {
        if (prev.page.state.tabValue != page.state.tabValue) {
          this.setState({ value: page.state.tabValue })
        }
      }
    }
    //

  }


  handleChange = (event, newValue) => {
    this.setState({ value: newValue })
  };
  onChange = (item) => {
    this.setState({ YearCode: item[0]?.label })

    this.props.getProfitLossDetails(0, this.props.logindetails.ClientID, this.props.date, this.props.date,
      this.props.date, 0, 0, this.props.logindetails.SessionID, 0, 5, 0, this.props.L5ID, 0, "", item[0]?.YearCode)
  }
  render() {
    //   
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <AppBar position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            classes={{ root: classes.tabRoot }}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{ style: { background: window.globalConfig.color.BROWN } }}>
            <Tab label="Summary" {...a11yProps(0)} />
            <Tab label="Portfolio X-Ray" {...a11yProps(1)} />
            <Tab label="Transactions" {...a11yProps(2)} />
            <Tab label="Booked G/L" {...a11yProps(3)} />
            <Tab label="Maturity Tracker" {...a11yProps(4)} />

          </Tabs>
        </AppBar>
        <TabPanel value={this.state.value} index={0}>
          <Summary CategoryAllocation={labels.summaryPage.CategoryAllocation}
            Deptdata={this.props.Deptdata}
            splitByInstrument={labels.summaryPage.splitByMarketCap}
            EquityFundSector={labels.summaryPage.EquitySector}
            flag={constants.Common.FD}
            showChart={false}
            barChartData={this.props.barChartData}
            chart={this.props.chart}
            isLoadingportfolioSummary={this.props.isLoadingportfolioSummary}
            isLoadingportfolioChart={this.props.isLoadingportfolioChart}
            categorySummary={this.props.categorySummary}
            isLoadingcategorySummary={this.props.isLoadingcategorySummary}
            DonutData={this.props.DonutData}

            grandtotal={[labels.messages.grandtotal, labels.currency.rupeesymbol + this.props.grandtotalsummary, "", this.props.categorySummary.length == 0 ? "0%" : "100.0%"]}
            EquityLoading={this.props.isLoadingMarketCapEquity}
            DebtLoading={this.props.isLoadingMarketCapDebt}
            tableHeader={[Label.summaryPage.Category, Label.summaryPage.Amount, Label.summaryPage.categoryPercentage, Label.summaryPage.holdingPercentage]
            }
          />
        </TabPanel>
        <TabPanel value={this.state.value} index={1}>
          <Portifolio
            flag={constants.Common.FD}
            externalClasses={classes}
            hideGrandTotalUnitsColumn={false}
            Total={labels.ProfitLoss.Total}
            MarketValue={labels.Tableheader.MarketValue}
            CurrentGain={labels.Tableheader.CurrentGain}
            amountLabel={labels.Tableheader.price}
            units={labels.Tableheader.Quantity}
            Subheadingunits={labels.Tableheader.Subheadingprice}
            InvestedValue={labels.Tableheader.InvestedValue}
            AsOn={labels.Tableheader.AsOn}
            totaltext={labels.Tableheader.totaltext}
            Sip={labels.Tableheader.Sip}
            xray={this.props.xray}
            header={"Asset"}
            filterSubmit={this.props.FDFilterChange}

            List={this.props.List}

            isLoadinggroupXray={this.props.isLoadinggroupXray}

            logindetails={this.props.logindetails}

            dropdowndetails={this.props.dropdowndetails}
            title={portfolioxraytitle}
            schemeList={this.props.schemeList}
            grid={classes.fdSixSplitContainer} />
        </TabPanel>
        <TabPanel value={this.state.value} index={2}>
          {/* TRANSACTION */}
          <Transaction
            header={"Transaction"}
            filterHeader={"Asset"}
            grid={classes.transactionContainer}
            ReportList={this.props.financialYearsList.length == 0 ? [] : sortArray([{ label: "All", value: 0, isChecked: false }, ...this.props.financialYearsList.map((item, index) => {
              return { ...item, label: item.Year, value: index + 1, isChecked: false }
            }),], "ID", "DESC")}

            title={[{ 'name': labels.transactionSummary.date }, { 'name': labels.Tableheader.AssetName }, { 'name': labels.transactionSummary.assetClass }, { 'name': labels.transactionSummary.transactionType, "subheader": labels.transactionSummary.TransactionNature }, { 'name': labels.transactionSummary.contractno },  { 'name': labels.Tableheader.ROI }, { 'name': labels.transactionSummary.amount }]}
            flag={constants.Common.FD} isLoadingtransactionList={this.props.isLoadingtransactionList}
            L5ID={this.props.L5ID} maxDate={this.props.maxDate} minDate={this.props.minDate}
            schemeName={labels.transactionSummary.scripName} TransactionxcelLoad={this.props.TransactionxcelLoad} TransactionLoad={this.props.TransactionLoad} logindetails={this.props.logindetails} catchmessage={this.props.catchmessage}
            date={this.props.date} userID={this.props.userID} clientID={this.props.clientID} sessionID={this.props.sessionID}
            transactionList={this.props.transactionList != "NA" && this.props.transactionList.length != 0 ? this.props.transactionList : []}
            dropdowntransaction={this.props.dropdowntransaction}
            List={this.props.dropdowntransaction}
            filterSubmit={this.props.fd_transactionfilter} />
        </TabPanel>
        <TabPanel value={this.state.value} index={3}>
          {/* PROFIT&LOSS */}
          <Table header={"Asset"} flag={constants.Common.FD} GainValue={labels.ProfitLoss.GainValue} Units={labels.ProfitLoss.quantity} SellValueLabel={labels.ProfitLoss.redeemAmount}
            PurchaseValueLabel={labels.ProfitLoss.investedAmount} DividendValueLabel={labels.ProfitLoss.Interest} SchemeName={labels.Tableheader.AssetName}
            Total={labels.ProfitLoss.Total}
            grid={classes.profitLossContainer}
            ReportList={this.props.financialYearsList.length == 0 ? [] : sortArray([{ label: "All", value: 0, }, ...this.props.financialYearsList.map((item, index) => {
              return { ...item, label: item.Year, value: index + 1 }
            }),], "ID", "DESC")}
            YearCode={this.state.YearCode}
            onChange={this.onChange}
            filterSubmit={this.props.Fdprofitandlossfilter}
            ProfitLoss={this.props.ProfitLoss} profitLossfilter={this.props.profitLossfilter}
            isLoadingProfitLoss={this.props.isLoadingProfitLoss} PurchaseValue={this.props.PurchaseValue}
            DividendValue={this.props.DividendValue} SellValue={this.props.SellValue}
            glAmount={this.props.glAmount} GainPercentage={this.props.GainPercentage}
            isProfitLossLoading={this.props.isProfitLossLoading}
            List={this.props.profitLossfilter} />
        </TabPanel>
        <TabPanel value={this.state.value} index={4}>
          <MaturityTracker header={"Issuer"} flag="maturitytracker" filterSubmit={this.props.filterMaturityTracker} maturityTrackerBackupList={this.props.maturityTrackerBackupList} List={this.props.maturityTrackerList} isLoading={this.props.isLoading}></MaturityTracker>
        </TabPanel>


      </div>
    );
  }
}
const mapStateToProps = state => {
  // //
  return {

    //COMMON STATE
    sessionID: state.Login.sessionID,
    clientID: state.Login.logindetails.ClientID,
    userID: state.Login.userID,
    date: state.Login.date,
    logindetails: state.Login.logindetails,
    L5ID: state.Login.L5ID,
    //MATURITY TRACKER
    maturityTrackerList: state.MaturityTracker.maturityTracker == "NA" ? [] : state.MaturityTracker.maturityTracker,
    isLoading: state.MaturityTracker.isMaturityTrackerLoading,
    maturityTrackerBackupList: state.MaturityTracker.maturityTrackerBackup,
    //TRANSACTION
    transactionList: state.transactionList.fd_transactionList,
    dropdowntransaction: state.transactionList.fd_dropdowntransaction,
    isLoadingtransactionList: state.transactionList.fd_isLoadingtransactionList,
    // sessionID: state.Login.sessionID,
    // clientID: state.Login.logindetails.ClientID,
    // userID: state.Login.userID,
    // date: state.Login.date,
    catchmessage: state.transactionList.fd_catchmessage,
    TransactionLoad: state.transactionList.fd_isLoadingPdf,
    TransactionxcelLoad: state.transactionList.fd_isLoadingExcel,
    minDate: state.transactionList.fd_minDate,
    maxDate: state.transactionList.fd_maxDate,


    //SUMMARY
    barChartData: state.Summary.fd_summary != undefined ? state.Summary.fd_summary : [],
    chart: state.Summary.fd_chart != undefined ? state.Summary.fd_chart : [],
    categorySummary: state.Summary.fd_categorySummary != undefined ? state.Summary.fd_categorySummary : [],
    isLoadingcategorySummary: state.Summary.fd_isLoadingcategorySummary != undefined ? state.Summary.fd_isLoadingcategorySummary : false,
    DonutData: state.Summary.fd_Donut != undefined ? state.Summary.fd_Donut : [],
    grandtotalsummary: state.Summary.fd_grandtotalIV != undefined ? state.Summary.fd_grandtotalIV : [],
    Deptdata: state.Summary.fd_getdeptdata,
    isLoadingportfolioChart: state.Sample.isLoadingportfolioChart,
    isLoadingportfolioSummary: state.Sample.isLoadingportfolioSummary,
    isLoadingMarketCapEquity: state.Summary.isLoadingMarketCapEquity,
    isLoadingMarketCapDebt: state.Summary.isLoadingMarketCapDebt,


    // PROFITANDLOSS
    ProfitLoss: state.ProfitLoss.fd_profitLoss,
    profitLossfilter: state.ProfitLoss.fd_profitLossfilter,
    isLoadingProfitLoss: state.ProfitLoss.fd_isLoadingProfitLoss,
    PurchaseValue: state.ProfitLoss.fd_grandpurchasetotal,
    DividendValue: state.ProfitLoss.fd_granddivdend,
    SellValue: state.ProfitLoss.fd_grandselltotal,
    glAmount: state.ProfitLoss.fd_grandglamount,
    GainPercentage: state.ProfitLoss.fd_GainPercentage,
    isProfitLossLoading: state.ProfitLoss.fd_isProfitLossLoading,




    //PORTFOLIO-X-RAY
    xray: state.PortfolioXray.fd_xray,
    isLoadinggroupXray: state.PortfolioXray.fd_isXrayLoading,
    List: state.PortfolioXray.fd_XrayData.length == 0 ? [] : state.PortfolioXray.fd_XrayData.filter(
      (ele, ind) =>
        ind ===
        state.PortfolioXray.fd_XrayData.findIndex((elem) => elem.Scheme === ele.Scheme)
    ),

    unrealised: state.Card.mfHeader2.filter((val) => val.firstName === "Unrealised G / L (%)").map((val) => {
      return val.lastName
    }),
    margetvalue: state.Card.mfHeader2.filter((val) => val.firstName === "MF Market Value").map((val) => {
      return val.lastName
    }),
    investedvalue: state.Card.mfHeader2.filter((val) => val.firstName === "Investment Value").map((val) => {
      return val.lastName
    }),
    unrealisedpercentage: state.Card.mfHeader2.filter((val) => val.firstName === "Unrealised G / L (%)").map((item) => {
      return item.val
    }),

    logindetails: state.Login.logindetails,

    dropdowndetails: state.Dropdown.dropdowndetails.scheme,
    financialYearsList: replaceNull(state.Dropdown.dropdowndetails.financialYearsList, "").length != 0 ? state.Dropdown.dropdowndetails.financialYearsList : [],

    // xray: state.PortfolioXray.xraysort,
    schemeList: state.PortfolioXray.fd_XrayData.sort(function (a, b) {
      var textA = a.Scheme.toUpperCase();
      var textB = b.Scheme.toUpperCase();
      return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
    })

  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {

      FDFilterChange,
      fd_transactionfilter,
      Fdprofitandlossfilter,
      filterMaturityTracker,
      getProfitLossDetails
    },
    dispatch
  );
};
const mergestyles = combineStyle(Styles, commonstyletable);
export default withStyles(mergestyles)(connect(mapStateToProps, mapDispatchToProps)(FDTab))