import React from 'react';
import '../../utils/stylesheets/switchbutton/switchbutton.css'
import { withStyles } from '@material-ui/core/styles'
const styles = (theme) => ({
    switchContainers: {
        width: "160px",
        backgroundColor: window.globalConfig.color.BORDER,
        height: "40px",
        borderRadius: "20px",
        display: "flex",
        justifyContent: "space-evenly",
        fontSize: "14px",
        float: "right",
        boxShadow: "0 3px 1px 0 rgba(0, 0, 0, 0.06)",
        border: "solid 0.5px rgba(189, 189, 189, 0.04)",
        cursor: "pointer",
        "& label": {
            color: window.globalConfig.color.BLACK_PRIMARY,
            textAlign: "center",
            padding: "4px",
            paddingTop: "2px",
            margin: "2px",
            // marginLeft: "2px",
            // marginRight: "2px",

            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center"
        }

    },
    active: {
        color: `${window.globalConfig.color.BROWN} !important`,
        backgroundColor: `${window.globalConfig.color.WHITE} !important`,
        width: "176px",
        borderRadius: "20px",

    }

})
class Switch extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedEquity: this.props.selectedEquity,
            selectedDebt: this.props.selectedDebt
        }
    }
    componentDidMount() {
        if (this.props.page == "paymentCart") {
            this.setState({
                selectedEquity: false,
                selectedDebt: true
            })
            this.props.onSwitchDonut(false)

        } else {
            this.setState({
                selectedEquity: true,
                selectedDebt: false
            })
            this.props.onSwitchDonut(true)
        }
    }
    onClick(e) {
        if (e.valueOf().currentTarget.innerText === this.props.label1) {
            this.setState({
                selectedEquity: true,
                selectedDebt: false
            })
            this.props.onSwitchDonut(true)
        }
        else {
            this.setState({
                selectedEquity: false,
                selectedDebt: true
            })
            this.props.onSwitchDonut(false)
        }
    }
    componentWillMount() {
        this.setState({ isChecked: this.props.isChecked });
    }


    render() {
        const { classes } = this.props;
        return (
            <div className={classes.switchContainers}>
                {this.state.selectedEquity ? <label className={classes.active} onClick={this.onClick.bind(this)}>{this.props.label1}</label> : <label className="switcherLabel" onClick={this.onClick.bind(this)}>{this.props.label1}</label>}
                {this.state.selectedDebt ? <label className={classes.active} onClick={this.onClick.bind(this)}>{this.props.label2}</label> : <label className="switcherLabel" onClick={this.onClick.bind(this)}>{this.props.label2}</label>}
            </div>
        );
    }


    _handleChange() {
        this.setState({ isChecked: !this.state.isChecked });
    }

}
class SwitchButton extends React.Component {
    render() {
        const { classes } = this.props
        return (
            <Switch classes={classes} selectedEquity={this.props.selectedEquity} label1={this.props.label1} label2={this.props.label2} onSwitchDonut={this.props.onSwitchDonut} selectedDebt={this.props.selectedDebt} page={this.props.page} />
        )
    }
}
export default withStyles(styles)(SwitchButton)

