// import React from 'react'
// import { useState, useEffect } from 'react';

// const EmailTimer = (props) => {
//     const {initialMinute = 0,initialSeconds = 0} = props;
//     const [ minutes, setMinutes ] = useState(initialMinute);
//     const [seconds, setSeconds ] =  useState(initialSeconds);
//     useEffect(()=>{
//     let myInterval = setInterval(() => {
//             if (seconds > 0) {
//                 setSeconds(seconds - 1);
//             }
//             if (seconds === 0) {
//                 if (minutes === 0) {
//                     clearInterval(myInterval)
//                 } else {
//                     setMinutes(minutes - 1);
//                     setSeconds(119);
//                 }
//             } 
//         }, 2000)
//         return ()=> {
//             clearInterval(myInterval);
//           };
//     });

//     return (
//         <div>
//         {/* { minutes === 0 && seconds === 0
//             ? null
//             :  */}
//             // <h1> {minutes}:{seconds < 10 ?  `0${seconds}` : seconds}</h1> 
//             {seconds == 0 ? <div onClick={resend} style={{ color: colors.brown, textDecorationLine: "underline", cursor: "pointer" }}>{props.text}</div> : seconds > 9 ? `00:${seconds}` : `00:0${seconds}`}
//         {/* } */}
//         </div>
//     )
// }

// export default EmailTimer;
import React from 'react'
import colors from '../../utils/colors/colors';
class EmailTimer extends React.Component {
    constructor() {
        super();
        this.state = { time: {}, seconds: 119 };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
    }

    componentDidMount() {
        this.startTimer();
        let timeLeftVar = this.secondsToTime(this.state.seconds);
        this.setState({ time: timeLeftVar });
        
    }
    //   timer start in minsts 
    startTimer = () => {
        if (this.timer == 0 && this.state.seconds >= 0) {
            this.timer = setInterval(this.countDown, 1000);
        }

    }
    componentDidUpdate = () => {
        if (this.state.time.s == 0 && this.state.time.m == 0) {
            this.props.resetOtp(this.state.time.s, this.state.time.m)
        }
    }
    componentWillUnmount() {
        clearInterval(this.timer)
    }
    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds == 0) {
            clearInterval(this.timer);
            this.timer = 0;
            this.setState({
                seconds: 119
            })
        }
    }
    resend = () => {
        this.startTimer();
        this.props.onclick()
    }

    render() {
        return (
            <div>
                {/* {this.state.time.s == 0 && this.state.time.m == 0 ? <div onClick={this.resend} style={{ color: colors.brown, textDecorationLine: "underline", cursor: "pointer" }} >{this.props.text}</div>
                    :
                    <>
                        {this.state.time.s > 9 ? `0${this.state.time.m}:${this.state.time.s}` : `0${this.state.time.m}:0${this.state.time.s}`}
                    </>
                } */}
                {this.state.time.s > 9 ? `0${this.state.time.m}:${this.state.time.s}` : `0${this.state.time.m}:0${this.state.time.s}`}

            </div>
        );
    }
}
export default EmailTimer;