import Networking from '../../../utils/api/apiaccess';
import labels from '../../../utils/constants/labels';
import apiurl from '../../url/apiurl';
var axios = require('axios');
function camsHoldings(clientID,batchNo) {
   
    return Networking.PostApi(`${apiurl.camsHoldings}?clientId=${clientID}&batchNo=${batchNo}`,"",labels.apiKey.apiKeys).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default camsHoldings
