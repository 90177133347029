import React from 'react'
import labels from '../../utils/constants/labels';
import { withStyles } from '@material-ui/core/styles';
import NotfoundImage from '../../utils/assets/1630646331589.jpeg';
import Button from '../custombutton/button';
import { Redirect, withRouter } from "react-router";
import secureStorage from '../../utils/securestorage';
import colors from '../../utils/colors/colors';
const styles = theme => ({
    errorPage: {
        backgroundImage: `url(${NotfoundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center center",
        height: "100%",
        margin: 0,
        padding: 0,
        position: "fixed",
        width: "100%"
    },
    errorWrapper: {
        maxWidth: "50%",
        margin: "6% auto",
        zIndex: 10,
        textAlign: "center",
        [theme.breakpoints.down('sm')]: {
            maxWidth: "100%",
        }
    },
    overlay: {
        position: "absolute",
        width: "100%",
        height: "100%",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(99, 95, 184 ,0.5)"
    },
    header1: {
        fontSize: "10em",
        color: window.globalConfig.color.ZERO_BACKGROUND,
        margin: 0
    },
    header2: {
        fontSize: "2em",
        color: window.globalConfig.color.WHITE,
        lineHeight: "1.5em",
        margin: 0
    }
})
let session = secureStorage.getItem('user');
let session_store = session;
class NotFound extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }


    render() {
        const { classes } = this.props
        // if (this.state.hasError) {
        // You can render any custom fallback UI
        if (session_store != null) {
            return (<div className={classes.errorPage}>
                <div className={classes.overlay}>
                    <div className={classes.errorWrapper}>
                        <p className={classes.header1}>
                           {labels.errorHandle.errorCode}
                        </p>
                        <p className={classes.header2}>
                            <span>{labels.errorHandle.whoops}</span>
                            <br />

                            {labels.errorHandle.errorConfessionMsg}
                            <br />
                           {labels.errorHandle.errorConfessionMsg2}

                        </p>
                        <div style={{ width: "35%", justifyContent: "center", marginLeft: "32%" }}>
                            <Button page={"primary"} name={labels.errorHandle.letsGoDashboard} onClick={() => { this.props.history.push(labels.Path.Dashboard) }} /></div>
                    </div></div>
            </div>)
        } else {
            <Redirect to={labels.Path.Login} />
        }

    }
}
export default withStyles(styles)(withRouter(NotFound))
