import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
import Networking from '../../utils/api/apiaccess';
import { EncryptClientID, Encryption } from '../../utils/commonfunction';
function getFamilyDetails(ClientID,) {
    let ID = Encryption(ClientID?.toString())
    return Networking.GetApi(`${ApiValues.FamilyDetails}${ID}`, "").then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }
        });
}
export default getFamilyDetails
