import * as types from '../../constants/actionsType';
import UpdatecartApi from '../../../api/cart/updatecartapi'
export function UpdateCart(ID, SchemeID, ClientID, Amount, AUM, Instalments, IsBSEStar, OneTimeAllowed, OneWeek, OneYear, Ranking, Risk,
    SipAllowed, SipFrequency, Sipdate, SipdateID, ThreeYear, ratings, AMCID, AssetClass, ISIN, RTAID, RTAProdCode, SchemeCode, ISFirstPayment, SessionID) {
  return (dispatch) => {
    UpdateCartAction(dispatch,ID, SchemeID, ClientID, Amount, AUM, Instalments, IsBSEStar, OneTimeAllowed, OneWeek, OneYear, Ranking, Risk,
        SipAllowed, SipFrequency, Sipdate, SipdateID, ThreeYear, ratings, AMCID, AssetClass, ISIN, RTAID, RTAProdCode, SchemeCode, ISFirstPayment, SessionID);
  }
}
function UpdateCartAction(dispatch,ID, SchemeID, ClientID, Amount, AUM, Instalments, IsBSEStar, OneTimeAllowed, OneWeek, OneYear, Ranking, Risk,
    SipAllowed, SipFrequency, Sipdate, SipdateID, ThreeYear, ratings, AMCID, AssetClass, ISIN, RTAID, RTAProdCode, SchemeCode, ISFirstPayment, SessionID) {
        UpdatecartApi(ID, SchemeID, ClientID, Amount, AUM, Instalments, IsBSEStar, OneTimeAllowed, OneWeek, OneYear, Ranking, Risk,
        SipAllowed, SipFrequency, Sipdate, SipdateID, ThreeYear, ratings, AMCID, AssetClass, ISIN, RTAID, RTAProdCode, SchemeCode, ISFirstPayment, SessionID)
    .then((data) => {
     //   (data)
      if (data != undefined) {
        if (data.status == "S") {    
          dispatch({
            type: types.UPDATE_CART,
            payload: data,
          });
        } else {
          dispatch({
            type: types.UPDATE_CART,
            payload: [],
          });
        }
      }
    })
    .catch((error) => {

    })
}
export const UpdateSipCartReducer = (updateddata) => {
  return {
    type: types.UPDATE_SIP_CART_REDUCER,
    payload:updateddata
  }
}
export const UpdateOnetimeCartReducer = (updateData) => {
  return {
    type: types.UPDATE_ONETIME_CART_REDUCER,
    payload:updateData
  }
}
