import moment from "moment";
import labels from "../../../utils/constants/labels";
import * as types from "../../constants/actionsType";
const initialState = {
  transactionList: [],
  isLoadingtransactionList: false,
  dropdowntransaction: [],
  isLoadingPdf: false,
  isLoadingExcel: false,
  catchmessage: false,
  minDate: moment().format("DD MMM YYYY"),
  maxDate: moment().format("DD MMM YYYY"),

  //EQUITY

  equity_transactionList: [],
  equity_isLoadingtransactionList: true,
  equity_dropdowntransaction: [],
  equity_isLoadingPdf: false,
  equity_isLoadingExcel: false,
  equity_catchmessage: false,
  equity_minDate: moment().format("DD MMM YYYY"),
  equity_maxDate: moment().format("DD MMM YYYY"),

  //bonds
  bonds_transactionList: [],
  bonds_isLoadingtransactionList: true,
  bonds_dropdowntransaction: [],
  bonds_isLoadingPdf: false,
  bonds_isLoadingExcel: false,
  bonds_catchmessage: false,
  bonds_minDate: moment().format("DD MMM YYYY"),
  bonds_maxDate: moment().format("DD MMM YYYY"),

  //gold bonds
  gb_transactionList: [],
  gb_isLoadingtransactionList: true,
  gb_dropdowntransaction: [],
  gb_isLoadingPdf: false,
  gb_isLoadingExcel: false,
  gb_catchmessage: false,
  gb_minDate: moment().format("DD MMM YYYY"),
  gb_maxDate: moment().format("DD MMM YYYY"),

  //FD
  fd_transactionList: [],
  fd_isLoadingtransactionList: true,
  fd_dropdowntransaction: [],
  fd_isLoadingPdf: false,
  fd_isLoadingExcel: false,
  fd_catchmessage: false,
  fd_minDate: moment().format("DD MMM YYYY"),
  fd_maxDate: moment().format("DD MMM YYYY"),

  //AIF
  aif_transactionList: [],
  aif_isLoadingtransactionList: true,
  aif_dropdowntransaction: [],
  aif_isLoadingPdf: false,
  aif_isLoadingExcel: false,
  aif_catchmessage: false,
  aif_minDate: moment().format("DD MMM YYYY"),
  aif_maxDate: moment().format("DD MMM YYYY"),

  //MLD
  mld_transactionList: [],
  mld_isLoadingtransactionList: true,
  mld_dropdowntransaction: [],
  mld_isLoadingPdf: false,
  mld_isLoadingExcel: false,
  mld_catchmessage: false,
  mld_minDate: moment().format("DD MMM YYYY"),
  mld_maxDate: moment().format("DD MMM YYYY"),


  //REAL ESTATE
  realEstate_transactionList: [],
  realEstate_isLoadingtransactionList: true,
  realEstate_dropdowntransaction: [],
  realEstate_isLoadingPdf: false,
  realEstate_isLoadingExcel: false,
  realEstate_catchmessage: false,
  realEstate_minDate: moment().format("DD MMM YYYY"),
  realEstate_maxDate: moment().format("DD MMM YYYY"),

  //NPS
  // nps_transactionList: [],
  // nps_isLoadingtransactionList: true,
  // nps_dropdowntransaction: [],
  // nps_isLoadingPdf: false,
  // nps_isLoadingExcel: false,
  // nps_catchmessage: false,
  // nps_minDate: moment().format("DD MMM YYYY"),
  // nps_maxDate: moment().format("DD MMM YYYY"),

  //INSURANCE
  // insurance_transactionList: [],
  // insurance_isLoadingtransactionList: true,
  // insurance_dropdowntransaction: [],
  // insurance_isLoadingPdf: false,
  // insurance_isLoadingExcel: false,
  // insurance_catchmessage: false,
  // insurance_minDate: moment().format("DD MMM YYYY"),
  // insurance_maxDate: moment().format("DD MMM YYYY"),
};
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TRANSACTIONLIST:
      let dates = [];
      let transactionFullList =
        action.payload != undefined &&
          action.payload != null && action.payload != labels.messages.NA &&
          action.payload.length != 0 ?
          action.payload : [];
      if (transactionFullList.length != 0) {
        transactionFullList.map((value) => {
          dates.push(new Date(moment(value.Date).format("YYYY/MM/DD")));
        });
      }
      const max = moment(new Date(Math.max.apply(null, dates))).format(
        "DD MMM YYYY"
      );
      const min = moment(new Date(Math.min.apply(null, dates))).format(
        "DD MMM YYYY"
      );
      //
      return {
        ...state,
        transactionList:
          action.payload == undefined ||
            action.payload == null || action.payload == labels.messages.NA ||
            action.payload.length == 0
            ? state.transactionList
            : action.payload.sort((a, b) => new Date(b.Date) - new Date(a.Date)),
        minDate: min,
        maxDate: max,
        dropdowntransaction: action.payload,
        isLoadingtransactionList: false,
        isTransactionLoading: false,
      };
    case types.GET_TRANSACTION:
      if (action.flag == "mutualfunds") {
        return {
          ...state,
          transactionList:
            action.data == undefined ||
              action.data == null ||
              action.data.length == 0
              ? state.transactionList
              : action.data,

          //        isLoadingtransactionList: false,
        };
      } else if (action.flag == "equity") {
        return {
          ...state,
          equity_transactionList:
            action.data == undefined ||
              action.data == null ||
              action.data.length == 0
              ? state.equity_transactionList
              : action.data,

          //        isLoadingtransactionList: false,
        };
      } else if (action.flag == "Bonds") {
        return {
          ...state,
          bonds_transactionList:
            action.data == undefined ||
              action.data == null ||
              action.data.length == 0
              ? state.bonds_transactionList
              : action.data,

          //        isLoadingtransactionList: false,
        };
      }
      else if (action.flag == "Fixed Deposits") {
        return {
          ...state,
          fd_transactionList:
            action.data == undefined ||
              action.data == null ||
              action.data.length == 0
              ? state.fd_transactionList
              : action.data,

          //        isLoadingtransactionList: false,
        };
      }
      else if (action.flag == "mld") {
        return {
          ...state,
          mld_transactionList:
            action.data == undefined ||
              action.data == null ||
              action.data.length == 0
              ? state.mld_transactionList
              : action.data,

          //        isLoadingtransactionList: false,
        }
      } else if (action.flag == "Bullion") {
        return {
          ...state,
          gb_transactionList:
            action.data == undefined ||
              action.data == null ||
              action.data.length == 0
              ? state.gb_transactionList
              : action.data,

          //        isLoadingtransactionList: false,
        }
      }
      else if (action.flag == "Real Estate") {
        return {
          ...state,
          realEstate_transactionList:
            action.data == undefined ||
              action.data == null ||
              action.data.length == 0
              ? state.realEstate_transactionList
              : action.data,

          //        isLoadingtransactionList: false,
        }
      }
      else if (action.flag == "AIF / PMS") {
        return {
          ...state,
          aif_transactionList:
            action.data == undefined ||
              action.data == null ||
              action.data.length == 0
              ? state.aif_transactionList
              : action.data,

          //        isLoadingtransactionList: false,
        }
      }



    case types.FILTER_TRAN_PROFIT:
      var filterdata = state.dropdowntransaction;
      var filteredList = filterdata;
      var schemeName;
      var transationType;
      var assetClass;
      // MultiSelect Scheme Filter
      if (action.payload.financialYear != "" &&
        action.payload.financialYear != null &&
        action.payload.financialYear != undefined) {
        var datespliting = action.payload.financialYear.split("to");

        var date1 = new Date(datespliting[0].trim()).getTime();
        var date2 = new Date(datespliting[1].trim()).getTime();
        filteredList = filteredList.filter((data) => {
          var time = new Date(data.SortDate).getTime();
          return time >= date1 && time <= date2;
        });
      }
      if (
        action.payload.Schemes != undefined &&
        action.payload.Schemes != null &&
        action.payload.Schemes != 0
      ) {
        schemeName = action.payload.Schemes.map((i) => {
          return i.label; // Taking only Schemes name from the labels as array
        });
        if (schemeName != undefined) {
          filteredList = filteredList.filter((data) => {
            return schemeName.includes(data.Scheme);
          });
        }
      }

      // MultiSelect Transaction Filter
      if (
        action.payload.Transaction != undefined &&
        action.payload.Transaction != null &&
        action.payload.Transaction != 0
      ) {
        transationType = action.payload.Transaction.map((i) => {
          return i.label; // Taking only Transaction name from the labels as array
        });
        if (transationType != undefined) {
          filteredList = filteredList.filter((data) => {
            return transationType.includes(data.TransactionType);
          });
        }
      }

      // MultiSelect Asset Filter
      //
      if (
        action.payload.Asset != undefined &&
        action.payload.Asset != null &&
        action.payload.Asset.length != 0
      ) {
        assetClass = action.payload.Asset.map((i) => {
          return i.label; // Taking only Asset name from the labels as array
        });
        if (assetClass != undefined) {
          filteredList = filteredList.filter((data) => {
            return assetClass.includes(data.AssetClass);
          });
        }
      }
      // console.log(action.payload.Date)
      if (
        action.payload.Date != 0 &&
        action.payload.Date != "" &&
        action.payload.Date != null
      ) {
        //    (action.payload.Date.split('-'))
        var datespliting = action.payload.Date.split("-");
        var date1 = new Date(datespliting[0]).getTime();
        var date2 = new Date(datespliting[1]).getTime();
        filteredList = filteredList.filter((data) => {
          var time = new Date(data.SortDate).getTime();
          return time >= date1 && time <= date2;
        });
      }

      state.transactionList = filteredList;
      return {
        ...state,
        // transactionList: action.payload
      };
    case types.FILTER_TRAN_PROFIT_EQUITY:
      var filterdata_equity = state.equity_dropdowntransaction;
      var filteredList_equity = filterdata_equity;
      var schemeName_equity;
      var transationType_equity;
      var assetClass_equity;
      if (action.payload.financialYear != "" &&
        action.payload.financialYear != null &&
        action.payload.financialYear != undefined) {
        var datespliting = action.payload.financialYear.split("to");

        var date1 = new Date(datespliting[0].trim()).getTime();
        var date2 = new Date(datespliting[1].trim()).getTime();
        filteredList_equity = filteredList_equity.filter((data) => {
          var time = new Date(data.SortDate).getTime();
          return time >= date1 && time <= date2;
        });
      }
      // MultiSelect Scheme Filter
      if (action.payload.financialYear != "" &&
        action.payload.financialYear != null &&
        action.payload.financialYear != undefined) {
        var datespliting = action.payload.financialYear.split("to");

        var date1 = new Date(datespliting[0].trim()).getTime();
        var date2 = new Date(datespliting[1].trim()).getTime();
        filteredList_equity = filteredList_equity.filter((data) => {
          var time = new Date(data.SortDate).getTime();
          return time >= date1 && time <= date2;
        });
      }
      if (
        action.payload.Schemes != undefined &&
        action.payload.Schemes != null &&
        action.payload.Schemes != 0
      ) {
        schemeName_equity = action.payload.Schemes.map((i) => {
          return i.label; // Taking only Schemes name from the labels as array
        });
        if (schemeName_equity != undefined) {
          filteredList_equity = filteredList_equity.filter((data) => {
            return schemeName_equity.includes(data.Scheme);
          });
        }
      }

      // MultiSelect Transaction Filter
      if (
        action.payload.Transaction != undefined &&
        action.payload.Transaction != null &&
        action.payload.Transaction != 0
      ) {
        transationType_equity = action.payload.Transaction.map((i) => {
          return i.label; // Taking only Transaction name from the labels as array
        });
        if (transationType_equity != undefined) {
          filteredList_equity = filteredList_equity.filter((data) => {
            return transationType_equity.includes(data.TransactionType);
          });
        }
      }

      // MultiSelect Asset Filter
      //
      if (
        action.payload.Asset != undefined &&
        action.payload.Asset != null &&
        action.payload.Asset.length != 0
      ) {
        assetClass_equity = action.payload.Asset.map((i) => {
          return i.label; // Taking only Asset name from the labels as array
        });
        if (assetClass_equity != undefined) {
          filteredList_equity = filteredList_equity.filter((data) => {
            return assetClass_equity.includes(data.AssetClass);
          });
        }
      }

      if (
        action.payload.Date != 0 &&
        action.payload.Date != "" &&
        action.payload.Date != null
      ) {
        //    (action.payload.Date.split('-'))
        var datespliting_equity = action.payload.Date.split("-");
        var date1_equity = new Date(datespliting_equity[0]).getTime();
        var date2_equity = new Date(datespliting_equity[1]).getTime();
        filteredList_equity = filteredList_equity.filter((data) => {
          var time_equity = new Date(data.SortDate).getTime();
          return time_equity >= date1_equity && time_equity <= date2_equity;
        });
      }

      state.equity_transactionList = filteredList_equity;
      return {
        ...state,
        // transactionList: action.payload
      };
    //Bonds
    case types.FILTER_TRAN_PROFIT_BONDS:
      var filterdata_bonds = state.bonds_dropdowntransaction;
      var filteredList_bonds = filterdata_bonds;
      var schemeName_bonds;
      var transationType_bonds;
      var assetClass_bonds;
      if (action.payload.financialYear != "" &&
        action.payload.financialYear != null &&
        action.payload.financialYear != undefined) {
        var datespliting = action.payload.financialYear.split("to");

        var date1 = new Date(datespliting[0].trim()).getTime();
        var date2 = new Date(datespliting[1].trim()).getTime();
        filteredList_bonds = filteredList_bonds.filter((data) => {
          var time = new Date(data.SortDate).getTime();
          return time >= date1 && time <= date2;
        });
      }
      // MultiSelect Scheme Filter
      if (
        action.payload.Schemes != undefined &&
        action.payload.Schemes != null &&
        action.payload.Schemes != 0
      ) {
        schemeName_bonds = action.payload.Schemes.map((i) => {
          return i.label; // Taking only Schemes name from the labels as array
        });
        if (schemeName_bonds != undefined) {
          filteredList_bonds = filteredList_bonds.filter((data) => {
            return schemeName_bonds.includes(data.Scheme);
          });
        }
      }

      // MultiSelect Transaction Filter
      if (
        action.payload.Transaction != undefined &&
        action.payload.Transaction != null &&
        action.payload.Transaction != 0
      ) {
        transationType_bonds = action.payload.Transaction.map((i) => {
          return i.label; // Taking only Transaction name from the labels as array
        });
        if (transationType_bonds != undefined) {
          filteredList_bonds = filteredList_bonds.filter((data) => {
            return transationType_bonds.includes(data.TransactionType);
          });
        }
      }

      // MultiSelect Asset Filter
      //
      if (
        action.payload.Asset != undefined &&
        action.payload.Asset != null &&
        action.payload.Asset.length != 0
      ) {
        assetClass_bonds = action.payload.Asset.map((i) => {
          return i.label; // Taking only Asset name from the labels as array
        });
        if (assetClass_bonds != undefined) {
          filteredList_bonds = filteredList_bonds.filter((data) => {
            return assetClass_bonds.includes(data.AssetClass);
          });
        }
      }

      if (
        action.payload.Date != 0 &&
        action.payload.Date != "" &&
        action.payload.Date != null
      ) {
        //    (action.payload.Date.split('-'))
        var datespliting_bonds = action.payload.Date.split("-");
        var date1_bonds = new Date(datespliting_bonds[0]).getTime();
        var date2_bonds = new Date(datespliting_bonds[1]).getTime();
        filteredList_bonds = filteredList_bonds.filter((data) => {
          var time_bonds = new Date(data.SortDate).getTime();
          return time_bonds >= date1_bonds && time_bonds <= date2_bonds;
        });
      }

      state.bonds_transactionList = filteredList_bonds;
      return {
        ...state,
        // transactionList: action.payload
      };
    //Gold bonds
    case types.FILTER_TRAN_PROFIT_GB:
      var filterdata_gb = state.gb_dropdowntransaction;
      var filteredList_gb = filterdata_gb;
      var schemeName_gb;
      var transationType_gb;
      var assetClass_gb;
      if (action.payload.financialYear != "" &&
        action.payload.financialYear != null &&
        action.payload.financialYear != undefined) {
        var datespliting = action.payload.financialYear.split("to");

        var date1 = new Date(datespliting[0].trim()).getTime();
        var date2 = new Date(datespliting[1].trim()).getTime();
        filteredList_gb = filteredList_gb.filter((data) => {
          var time = new Date(data.SortDate).getTime();
          return time >= date1 && time <= date2;
        });
      }
      // MultiSelect Scheme Filter
      if (
        action.payload.Schemes != undefined &&
        action.payload.Schemes != null &&
        action.payload.Schemes != 0
      ) {
        schemeName_gb = action.payload.Schemes.map((i) => {
          return i.label; // Taking only Schemes name from the labels as array
        });
        if (schemeName_gb != undefined) {
          filteredList_gb = filteredList_gb.filter((data) => {
            return schemeName_gb.includes(data.Scheme);
          });
        }
      }

      // MultiSelect Transaction Filter
      if (
        action.payload.Transaction != undefined &&
        action.payload.Transaction != null &&
        action.payload.Transaction != 0
      ) {
        transationType_gb = action.payload.Transaction.map((i) => {
          return i.label; // Taking only Transaction name from the labels as array
        });
        if (transationType_gb != undefined) {
          filteredList_gb = filteredList_gb.filter((data) => {
            return transationType_gb.includes(data.TransactionType);
          });
        }
      }

      // MultiSelect Asset Filter
      //
      if (
        action.payload.Asset != undefined &&
        action.payload.Asset != null &&
        action.payload.Asset.length != 0
      ) {
        assetClass_gb = action.payload.Asset.map((i) => {
          return i.label; // Taking only Asset name from the labels as array
        });
        if (assetClass_gb != undefined) {
          filteredList_gb = filteredList_gb.filter((data) => {
            return assetClass_gb.includes(data.AssetClass);
          });
        }
      }

      if (
        action.payload.Date != 0 &&
        action.payload.Date != "" &&
        action.payload.Date != null
      ) {
        //    (action.payload.Date.split('-'))
        var datespliting_gb = action.payload.Date.split("-");
        var date1_gb = new Date(datespliting_gb[0]).getTime();
        var date2_gb = new Date(datespliting_gb[1]).getTime();
        filteredList_gb = filteredList_gb.filter((data) => {
          var time_gb = new Date(data.SortDate).getTime();
          return time_gb >= date1_gb && time_gb <= date2_gb;
        });
      }

      state.gb_transactionList = filteredList_gb;
      return {
        ...state,
        // transactionList: action.payload
      };

    //REALESTATE
    case types.FILTER_TRAN_PROFIT_REALESTATE:
      var filterdata_realEstate = state.realEstate_dropdowntransaction;
      var filteredList_realEstate = filterdata_realEstate;
      var schemeName_realEstate;
      var transationType_realEstate;
      var assetClass_realEstate;
      if (action.payload.financialYear != "" &&
        action.payload.financialYear != null &&
        action.payload.financialYear != undefined) {
        var datespliting = action.payload.financialYear.split("to");

        var date1 = new Date(datespliting[0].trim()).getTime();
        var date2 = new Date(datespliting[1].trim()).getTime();
        filteredList_realEstate = filteredList_realEstate.filter((data) => {
          var time = new Date(data.SortDate).getTime();
          return time >= date1 && time <= date2;
        });
      }
      // MultiSelect Scheme Filter
      if (
        action.payload.Schemes != undefined &&
        action.payload.Schemes != null &&
        action.payload.Schemes != 0
      ) {
        schemeName_realEstate = action.payload.Schemes.map((i) => {
          return i.label; // Taking only Schemes name from the labels as array
        });
        if (schemeName_realEstate != undefined) {
          filteredList_realEstate = filteredList_realEstate.filter((data) => {
            return schemeName_realEstate.includes(data.Scheme);
          });
        }
      }

      // MultiSelect Transaction Filter
      if (
        action.payload.Transaction != undefined &&
        action.payload.Transaction != null &&
        action.payload.Transaction != 0
      ) {
        transationType_realEstate = action.payload.Transaction.map((i) => {
          return i.label; // Taking only Transaction name from the labels as array
        });
        if (transationType_realEstate != undefined) {
          filteredList_realEstate = filteredList_realEstate.filter((data) => {
            return transationType_realEstate.includes(data.TransactionType);
          });
        }
      }

      // MultiSelect Asset Filter
      //
      if (
        action.payload.Asset != undefined &&
        action.payload.Asset != null &&
        action.payload.Asset.length != 0
      ) {
        assetClass_realEstate = action.payload.Asset.map((i) => {
          return i.label; // Taking only Asset name from the labels as array
        });
        if (assetClass_realEstate != undefined) {
          filteredList_realEstate = filteredList_realEstate.filter((data) => {
            return assetClass_realEstate.includes(data.AssetClass);
          });
        }
      }

      if (
        action.payload.Date != 0 &&
        action.payload.Date != "" &&
        action.payload.Date != null
      ) {
        //    (action.payload.Date.split('-'))
        var datespliting_realEstate = action.payload.Date.split("-");
        var date1_realEstate = new Date(datespliting_realEstate[0]).getTime();
        var date2_realEstate = new Date(datespliting_realEstate[1]).getTime();
        filteredList_realEstate = filteredList_realEstate.filter((data) => {
          var time_realEstate = new Date(data.SortDate).getTime();
          return time_realEstate >= date1_realEstate && time_realEstate <= date2_realEstate;
        });
      }

      state.realEstate_transactionList = filteredList_realEstate;
      return {
        ...state,
        // transactionList: action.payload
      };



    //AIF
    case types.FILTER_TRAN_PROFIT_AIF:
      var filterdata_aif = state.aif_dropdowntransaction;
      var filteredList_aif = filterdata_aif;
      var schemeName_aif;
      var transationType_aif;
      var assetClass_aif;
      if (action.payload.financialYear != "" &&
        action.payload.financialYear != null &&
        action.payload.financialYear != undefined) {
        var datespliting = action.payload.financialYear.split("to");

        var date1 = new Date(datespliting[0].trim()).getTime();
        var date2 = new Date(datespliting[1].trim()).getTime();
        filteredList_aif = filteredList_aif.filter((data) => {
          var time = new Date(data.SortDate).getTime();
          return time >= date1 && time <= date2;
        });
      }
      // MultiSelect Scheme Filter
      if (
        action.payload.Schemes != undefined &&
        action.payload.Schemes != null &&
        action.payload.Schemes != 0
      ) {
        schemeName_aif = action.payload.Schemes.map((i) => {
          return i.label; // Taking only Schemes name from the labels as array
        });
        if (schemeName_aif != undefined) {
          filteredList_aif = filteredList_aif.filter((data) => {
            return schemeName_aif.includes(data.Scheme);
          });
        }
      }

      // MultiSelect Transaction Filter
      if (
        action.payload.Transaction != undefined &&
        action.payload.Transaction != null &&
        action.payload.Transaction != 0
      ) {
        transationType_aif = action.payload.Transaction.map((i) => {
          return i.label; // Taking only Transaction name from the labels as array
        });
        if (transationType_aif != undefined) {
          filteredList_aif = filteredList_aif.filter((data) => {
            return transationType_aif.includes(data.TransactionType);
          });
        }
      }

      // MultiSelect Asset Filter
      //
      if (
        action.payload.Asset != undefined &&
        action.payload.Asset != null &&
        action.payload.Asset.length != 0
      ) {
        assetClass_aif = action.payload.Asset.map((i) => {
          return i.label; // Taking only Asset name from the labels as array
        });
        if (assetClass_aif != undefined) {
          filteredList_aif = filteredList_aif.filter((data) => {
            return assetClass_aif.includes(data.AssetClass);
          });
        }
      }

      if (
        action.payload.Date != 0 &&
        action.payload.Date != "" &&
        action.payload.Date != null
      ) {
        //    (action.payload.Date.split('-'))
        var datespliting_aif = action.payload.Date.split("-");
        var date1_aif = new Date(datespliting_aif[0]).getTime();
        var date2_aif = new Date(datespliting_aif[1]).getTime();
        filteredList_aif = filteredList_aif.filter((data) => {
          var time_aif = new Date(data.SortDate).getTime();
          return time_aif >= date1_aif && time_aif <= date2_aif;
        });
      }

      state.aif_transactionList = filteredList_aif;
      return {
        ...state,
        // transactionList: action.payload
      };
    //MLD
    case types.FILTER_TRAN_PROFIT_MLD:
      var filterdata_mld = state.mld_dropdowntransaction;
      var filteredList_mld = filterdata_mld;
      var schemeName_mld;
      var transationType_mld;
      var assetClass_mld;
      if (action.payload.financialYear != "" &&
        action.payload.financialYear != null &&
        action.payload.financialYear != undefined) {
        var datespliting = action.payload.financialYear.split("to");

        var date1 = new Date(datespliting[0].trim()).getTime();
        var date2 = new Date(datespliting[1].trim()).getTime();
        filteredList_mld = filteredList_mld.filter((data) => {
          var time = new Date(data.SortDate).getTime();
          return time >= date1 && time <= date2;
        });
      }
      // MultiSelect Scheme Filter
      if (
        action.payload.Schemes != undefined &&
        action.payload.Schemes != null &&
        action.payload.Schemes != 0
      ) {
        schemeName_mld = action.payload.Schemes.map((i) => {
          return i.label; // Taking only Schemes name from the labels as array
        });
        if (schemeName_mld != undefined) {
          filteredList_mld = filteredList_mld.filter((data) => {
            return schemeName_mld.includes(data.Scheme);
          });
        }
      }

      // MultiSelect Transaction Filter
      if (
        action.payload.Transaction != undefined &&
        action.payload.Transaction != null &&
        action.payload.Transaction != 0
      ) {
        transationType_mld = action.payload.Transaction.map((i) => {
          return i.label; // Taking only Transaction name from the labels as array
        });
        if (transationType_mld != undefined) {
          filteredList_mld = filteredList_mld.filter((data) => {
            return transationType_mld.includes(data.TransactionType);
          });
        }
      }

      // MultiSelect Asset Filter
      //
      if (
        action.payload.Asset != undefined &&
        action.payload.Asset != null &&
        action.payload.Asset.length != 0
      ) {
        assetClass_mld = action.payload.Asset.map((i) => {
          return i.label; // Taking only Asset name from the labels as array
        });
        if (assetClass_mld != undefined) {
          filteredList_mld = filteredList_mld.filter((data) => {
            return assetClass_mld.includes(data.AssetClass);
          });
        }
      }

      if (
        action.payload.Date != 0 &&
        action.payload.Date != "" &&
        action.payload.Date != null
      ) {
        //    (action.payload.Date.split('-'))
        var datespliting_mld = action.payload.Date.split("-");
        var date1_mld = new Date(datespliting_mld[0]).getTime();
        var date2_mld = new Date(datespliting_mld[1]).getTime();
        filteredList_mld = filteredList_mld.filter((data) => {
          var time_mld = new Date(data.SortDate).getTime();
          return time_mld >= date1_mld && time_mld <= date2_mld;
        });
      }

      state.mld_transactionList = filteredList_mld;
      return {
        ...state,
        // transactionList: action.payload
      };
    // //NPS
    // case types.FILTER_TRAN_PROFIT_NPS:
    //   var filterdata_nps = state.nps_dropdowntransaction;
    //   var filteredList_nps = filterdata_nps;
    //   var schemeName_nps;
    //   var transationType_nps;
    //   var assetClass_nps;
    //   // MultiSelect Scheme Filter
    //   if (
    //     action.payload.Schemes != undefined &&
    //     action.payload.Schemes != null &&
    //     action.payload.Schemes != 0
    //   ) {
    //     schemeName_nps = action.payload.Schemes.map((i) => {
    //       return i.label; // Taking only Schemes name from the labels as array
    //     });
    //     if (schemeName_nps != undefined) {
    //       filteredList_nps = filteredList_nps.filter((data) => {
    //         return schemeName_nps.includes(data.Scheme);
    //       });
    //     }
    //   }

    //   // MultiSelect Transaction Filter
    //   if (
    //     action.payload.Transaction != undefined &&
    //     action.payload.Transaction != null &&
    //     action.payload.Transaction != 0
    //   ) {
    //     transationType_aif = action.payload.Transaction.map((i) => {
    //       return i.label; // Taking only Transaction name from the labels as array
    //     });
    //     if (transationType_aif != undefined) {
    //       filteredList_aif = filteredList_aif.filter((data) => {
    //         return transationType_aif.includes(data.TransactionType);
    //       });
    //     }
    //   }

    //   // MultiSelect Asset Filter
    //   //
    //   if (
    //     action.payload.Asset != undefined &&
    //     action.payload.Asset != null &&
    //     action.payload.Asset.length != 0
    //   ) {
    //     assetClass_nps = action.payload.Asset.map((i) => {
    //       return i.label; // Taking only Asset name from the labels as array
    //     });
    //     if (assetClass_nps != undefined) {
    //       filteredList_nps = filteredList_nps.filter((data) => {
    //         return assetClass_nps.includes(data.AssetClass);
    //       });
    //     }
    //   }

    //   if (
    //     action.payload.Date != 0 &&
    //     action.payload.Date != "" &&
    //     action.payload.Date != null
    //   ) {
    //     //    (action.payload.Date.split('-'))
    //     var datespliting_nps = action.payload.Date.split("-");
    //     var date1_nps = new Date(datespliting_nps[0]).getTime();
    //     var date2_nps = new Date(datespliting_nps[1]).getTime();
    //     filteredList_nps = filteredList_nps.filter((data) => {
    //       var time_nps = new Date(data.SortDate).getTime();
    //       return time_nps >= date1_nps && time_nps <= date2_nps;
    //     });
    //   }

    //   state.nps_transactionList = filteredList_nps;
    //   return {
    //     ...state,
    //     // transactionList: action.payload
    //   };

    // //INSURANCE
    // case types.FILTER_TRAN_PROFIT_INSURANCE:
    //   var filterdata_insurance = state.insurance_dropdowntransaction;
    //   var filteredList_insurance = filterdata_insurance;
    //   var schemeName_insurance;
    //   var transationType_insurance;
    //   var assetClass_insurance;
    //   // MultiSelect Scheme Filter
    //   if (
    //     action.payload.Schemes != undefined &&
    //     action.payload.Schemes != null &&
    //     action.payload.Schemes != 0
    //   ) {
    //     schemeName_insurance = action.payload.Schemes.map((i) => {
    //       return i.label; // Taking only Schemes name from the labels as array
    //     });
    //     if (schemeName_insurance != undefined) {
    //       filteredList_insurance = filteredList_insurance.filter((data) => {
    //         return schemeName_insurance.includes(data.Scheme);
    //       });
    //     }
    //   }

    //   // MultiSelect Transaction Filter
    //   if (
    //     action.payload.Transaction != undefined &&
    //     action.payload.Transaction != null &&
    //     action.payload.Transaction != 0
    //   ) {
    //     transationType_insurance = action.payload.Transaction.map((i) => {
    //       return i.label; // Taking only Transaction name from the labels as array
    //     });
    //     if (transationType_insurance != undefined) {
    //       filteredList_insurance = filteredList_insurance.filter((data) => {
    //         return transationType_insurance.includes(data.TransactionType);
    //       });
    //     }
    //   }

    //   // MultiSelect Asset Filter
    //   //
    //   if (
    //     action.payload.Asset != undefined &&
    //     action.payload.Asset != null &&
    //     action.payload.Asset.length != 0
    //   ) {
    //     assetClass_insurance = action.payload.Asset.map((i) => {
    //       return i.label; // Taking only Asset name from the labels as array
    //     });
    //     if (assetClass_insurance != undefined) {
    //       filteredList_insurance = filteredList_insurance.filter((data) => {
    //         return assetClass_insurance.includes(data.AssetClass);
    //       });
    //     }
    //   }

    //   if (
    //     action.payload.Date != 0 &&
    //     action.payload.Date != "" &&
    //     action.payload.Date != null
    //   ) {
    //     //    (action.payload.Date.split('-'))
    //     var datespliting_insurance = action.payload.Date.split("-");
    //     var date1_insurance = new Date(datespliting_insurance[0]).getTime();
    //     var date2_insurance = new Date(datespliting_insurance[1]).getTime();
    //     filteredList_insurance = filteredList_insurance.filter((data) => {
    //       var time_insurance = new Date(data.SortDate).getTime();
    //       return time_insurance >= date1_insurance && time_insurance <= date2_insurance;
    //     });
    //   }

    //   state.insurance_transactionList = filteredList_insurance;
    //   return {
    //     ...state,
    //     // transactionList: action.payload
    //   };


    //FD
    case types.FILTER_TRAN_PROFIT_FD:
      var filterdata_fd = state.fd_dropdowntransaction;
      var filteredList_fd = filterdata_fd;
      var schemeName_fd;
      var transationType_fd;
      var assetClass_fd;
      if (action.payload.financialYear != "" &&
        action.payload.financialYear != null &&
        action.payload.financialYear != undefined) {
        var datespliting = action.payload.financialYear.split("to");

        var date1 = new Date(datespliting[0].trim()).getTime();
        var date2 = new Date(datespliting[1].trim()).getTime();
        filteredList_fd = filteredList_fd.filter((data) => {
          var time = new Date(data.SortDate).getTime();
          return time >= date1 && time <= date2;
        });
      }
      // MultiSelect Scheme Filter
      if (
        action.payload.Schemes != undefined &&
        action.payload.Schemes != null &&
        action.payload.Schemes != 0
      ) {
        schemeName_fd = action.payload.Schemes.map((i) => {
          return i.label; // Taking only Schemes name from the labels as array
        });
        if (schemeName_fd != undefined) {
          filteredList_fd = filteredList_fd.filter((data) => {
            return schemeName_fd.includes(data.Scheme);
          });
        }
      }

      // MultiSelect Transaction Filter
      if (
        action.payload.Transaction != undefined &&
        action.payload.Transaction != null &&
        action.payload.Transaction != 0
      ) {
        transationType_fd = action.payload.Transaction.map((i) => {
          return i.label; // Taking only Transaction name from the labels as array
        });
        if (transationType_fd != undefined) {
          filteredList_fd = filteredList_fd.filter((data) => {
            return transationType_fd.includes(data.TransactionType);
          });
        }
      }

      // MultiSelect Asset Filter
      //
      if (
        action.payload.Asset != undefined &&
        action.payload.Asset != null &&
        action.payload.Asset.length != 0
      ) {
        assetClass_fd = action.payload.Asset.map((i) => {
          return i.label; // Taking only Asset name from the labels as array
        });
        if (assetClass_fd != undefined) {
          filteredList_fd = filteredList_fd.filter((data) => {
            return assetClass_fd.includes(data.AssetClass);
          });
        }
      }

      if (
        action.payload.Date != 0 &&
        action.payload.Date != "" &&
        action.payload.Date != null
      ) {
        //    (action.payload.Date.split('-'))
        var datespliting_fd = action.payload.Date.split("-");
        var date1_fd = new Date(datespliting_fd[0]).getTime();
        var date2_fd = new Date(datespliting_fd[1]).getTime();
        filteredList_fd = filteredList_fd.filter((data) => {
          var time_fd = new Date(data.SortDate).getTime();
          return time_fd >= date1_fd && time_fd <= date2_fd;
        });
      }

      state.fd_transactionList = filteredList_fd;
      return {
        ...state,
        // transactionList: action.payload
      };
    case types.EMPTY_TRANSACTION:
      return {
        ...state,
        transactionList: [],
        //  isLoadingtransactionList: false,
        dropdowntransaction: [],
        //isLoadingPdf: false,
        //isLoadingExcel: false,
        //catchmessage: false,
        minDate: moment().format("DD MMM YYYY"),
        maxDate: moment().format("DD MMM YYYY"),
      };
    case types.LOADER_TRANSACTION:
      return {
        ...state,
        isLoadingtransactionList: action.payload,
      };
    case types.EQUITY_TRANSACTION:
      let equity_dates = [];
      let equity_transactionFullList =
        action.payload != undefined &&
          action.payload != null && action.payload != labels.messages.NA &&
          action.payload.length != 0
          ? action.payload
          : [];
      if (equity_transactionFullList.length != 0) {
        equity_transactionFullList.map((value) => {
          equity_dates.push(new Date(moment(value.Date).format("YYYY/MM/DD")));
        });
      }
      const equity_max = moment(
        new Date(Math.max.apply(null, equity_dates))
      ).format("DD MMM YYYY");
      const equity_min = moment(
        new Date(Math.min.apply(null, equity_dates))
      ).format("DD MMM YYYY");
      //
      return {
        ...state,
        equity_transactionList:
          action.payload != undefined &&
            action.payload != null && action.payload != labels.messages.NA &&
            action.payload.length != 0
            ? action.payload.sort((a, b) => new Date(b.Date) - new Date(a.Date))
            : state.equity_transactionList,
        equity_minDate: equity_min,
        equity_maxDate: equity_max,
        equity_dropdowntransaction: action.payload,
        equity_isLoadingtransactionList: false,
        equity_isTransactionLoading: false,
      };
    case types.EMPTY_EQUITY_TRANSACTION:
      return {
        ...state,
        equity_transactionList: [],
        equity_isLoadingtransactionList: false,
        equity_dropdowntransaction: [],
        equity_isLoadingPdf: false,
        equity_isLoadingExcel: false,
        equity_catchmessage: false,
        equity_minDate: moment().format("DD MMM YYYY"),
        equity_maxDate: moment().format("DD MMM YYYY"),
      };
    case types.LOADER_EQUITY_TRANSACTION:
      return {
        ...state,
        equity_isLoadingtransactionList: action.payload,
      };

    //Bonds
    case types.BONDS_TRANSACTION:
      let bonds_dates = [];
      let bonds_transactionFullList =
        action.payload != undefined &&
          action.payload != null && action.payload != labels.messages.NA &&
          action.payload.length != 0
          ? action.payload
          : [];

      if (bonds_transactionFullList.length != 0) {
        bonds_transactionFullList.map((value) => {
          bonds_dates.push(new Date(moment(value.Date).format("YYYY/MM/DD")));
        });
      }
      const bonds_max = moment(
        new Date(Math.max.apply(null, bonds_dates))
      ).format("DD MMM YYYY");
      const bonds_min = moment(
        new Date(Math.min.apply(null, bonds_dates))
      ).format("DD MMM YYYY");
      //
      return {
        ...state,
        bonds_transactionList:
          action.payload != undefined &&
            action.payload != null && action.payload != labels.messages.NA &&
            action.payload.length != 0
            ? action.payload.sort((a, b) => new Date(b.Date) - new Date(a.Date))
            : state.bonds_transactionList,
        bonds_minDate: bonds_min,
        bonds_maxDate: bonds_max,
        bonds_dropdowntransaction: action.payload,
        bonds_isLoadingtransactionList: false,
        bonds_isTransactionLoading: false,
      };
    case types.EMPTY_BONDS_TRANSACTION:
      return {
        ...state,
        bonds_transactionList: [],
        bonds_isLoadingtransactionList: false,
        bonds_dropdowntransaction: [],
        bonds_isLoadingPdf: false,
        bonds_isLoadingExcel: false,
        bonds_catchmessage: false,
        bonds_minDate: moment().format("DD MMM YYYY"),
        bonds_maxDate: moment().format("DD MMM YYYY"),
      };
    case types.LOADER_BONDS_TRANSACTION:
      return {
        ...state,
        bonds_isLoadingtransactionList: action.payload,
      };

    //REAL ESTATE
    case types.EMPTY_REALESTATE_TRANSACTION:
      return {
        ...state,
        realEstate_transactionList: [],
        realEstate_isLoadingtransactionList: false,
        realEstate_dropdowntransaction: [],
        realEstate_isLoadingPdf: false,
        realEstate_isLoadingExcel: false,
        realEstate_catchmessage: false,
        realEstate_minDate: moment().format("DD MMM YYYY"),
        realEstate_maxDate: moment().format("DD MMM YYYY"),
      };

    case types.REALESTATE_TRANSACTION:
      let realEstate_dates = [];
      let realEstate_transactionFullList =
        action.payload != undefined &&
          action.payload != null && action.payload != labels.messages.NA &&
          action.payload.length != 0
          ? action.payload
          : [];
      if (realEstate_transactionFullList.length != 0) {
        realEstate_transactionFullList.map((value) => {
          realEstate_dates.push(new Date(moment(value.Date).format("YYYY/MM/DD")));
        });
      }
      const realEstate_max = moment(
        new Date(Math.max.apply(null, realEstate_dates))
      ).format("DD MMM YYYY");
      const realEstate_min = moment(
        new Date(Math.min.apply(null, realEstate_dates))
      ).format("DD MMM YYYY");
      return {
        ...state,
        realEstate_transactionList:
          action.payload != undefined &&
            action.payload != null && action.payload != labels.messages.NA &&
            action.payload.length != 0
            ? action.payload.sort((a, b) => new Date(b.Date) - new Date(a.Date))
            : state.realEstate_transactionList,
        realEstate_minDate: realEstate_min,
        realEstate_maxDate: realEstate_max,
        realEstate_dropdowntransaction: action.payload,
        realEstate_isLoadingtransactionList: false,
        realEstate_isTransactionLoading: false,
      };

    case types.LOADER_REALESTATE_TRANSACTION:
      return {
        ...state,
        realEstate_isLoadingtransactionList: action.payload,
      };



    /// AIF
    case types.AIF_TRANSACTION:
      let aif_dates = [];
      let aif_transactionFullList =
        action.payload != undefined &&
          action.payload != null && action.payload != labels.messages.NA &&
          action.payload.length != 0
          ? action.payload
          : [];
      if (aif_transactionFullList.length != 0) {
        aif_transactionFullList.map((value) => {
          aif_dates.push(new Date(moment(value.Date).format("YYYY/MM/DD")));
        });
      }
      const aif_max = moment(
        new Date(Math.max.apply(null, aif_dates))
      ).format("DD MMM YYYY");
      const aif_min = moment(
        new Date(Math.min.apply(null, aif_dates))
      ).format("DD MMM YYYY");
      //

      return {
        ...state,
        aif_transactionList:
          action.payload != undefined &&
            action.payload != null && action.payload != labels.messages.NA &&
            action.payload.length != 0
            ? action.payload.sort((a, b) => new Date(b.Date) - new Date(a.Date)).map(({ Unit, NAV, ...item }) => {
              return { ...item }
            })
            : state.aif_transactionList,
        aif_minDate: aif_min,
        aif_maxDate: aif_max,
        aif_dropdowntransaction: action.payload != undefined &&
          action.payload != null && action.payload != labels.messages.NA &&
          action.payload.length != 0
          ? action.payload.sort((a, b) => new Date(b.Date) - new Date(a.Date)).map(({ Unit, NAV, ...item }) => {
            return { ...item }
          }) : [],
        aif_isLoadingtransactionList: false,
        aif_isTransactionLoading: false,
      };
    case types.EMPTY_AIF_TRANSACTION:
      return {
        ...state,
        aif_transactionList: [],
        aif_isLoadingtransactionList: false,
        aif_dropdowntransaction: [],
        aif_isLoadingPdf: false,
        aif_isLoadingExcel: false,
        aif_catchmessage: false,
        aif_minDate: moment().format("DD MMM YYYY"),
        aif_maxDate: moment().format("DD MMM YYYY"),
      };
    case types.LOADER_AIF_TRANSACTION:
      return {
        ...state,
        aif_isLoadingtransactionList: action.payload,
      };
    ///MLD
    case types.MLD_TRANSACTION:
      let mld_dates = [];
      let mld_transactionFullList =
        action.payload != undefined &&
          action.payload != null && action.payload != labels.messages.NA &&
          action.payload.length != 0
          ? action.payload
          : [];
      if (mld_transactionFullList.length != 0) {
        mld_transactionFullList.map((value) => {
          mld_dates.push(new Date(moment(value.Date).format("YYYY/MM/DD")));
        });
      }
      const mld_max = moment(
        new Date(Math.max.apply(null, mld_dates))
      ).format("DD MMM YYYY");
      const mld_min = moment(
        new Date(Math.min.apply(null, mld_dates))
      ).format("DD MMM YYYY");
      //

      return {
        ...state,
        mld_transactionList:
          action.payload != undefined &&
            action.payload != null && action.payload != labels.messages.NA &&
            action.payload.length != 0
            ? action.payload.sort((a, b) => new Date(b.Date) - new Date(a.Date)).map(({ Unit, NAV, ...item }) => {
              return { ...item }
            })
            : state.mld_transactionList,
        mld_minDate: mld_min,
        mld_maxDate: mld_max,
        mld_dropdowntransaction: action.payload != undefined &&
          action.payload != null && action.payload != labels.messages.NA &&
          action.payload.length != 0
          ? action.payload.sort((a, b) => new Date(b.Date) - new Date(a.Date)).map(({ Unit, NAV, ...item }) => {
            return { ...item }
          }) : [],
        mld_isLoadingtransactionList: false,
        mld_isTransactionLoading: false,
      };
    case types.EMPTY_MLD_TRANSACTION:
      return {
        ...state,
        mld_transactionList: [],
        mld_isLoadingtransactionList: false,
        mld_dropdowntransaction: [],
        mld_isLoadingPdf: false,
        mld_isLoadingExcel: false,
        mld_catchmessage: false,
        mld_minDate: moment().format("DD MMM YYYY"),
        mld_maxDate: moment().format("DD MMM YYYY"),
      };
    case types.LOADER_MLD_TRANSACTION:
      return {
        ...state,
        mld_isLoadingtransactionList: action.payload,
      };
    //Gold bonds
    case types.GB_TRANSACTION:
      let gb_dates = [];
      let gb_transactionFullList =
        action.payload != undefined &&
          action.payload != null && action.payload != labels.messages.NA &&
          action.payload.length != 0
          ? action.payload
          : [];
      if (gb_transactionFullList.length != 0) {
        gb_transactionFullList.map((value) => {
          gb_dates.push(new Date(moment(value.Date).format("YYYY/MM/DD")));
        });
      }
      const gb_max = moment(
        new Date(Math.max.apply(null, gb_dates))
      ).format("DD MMM YYYY");
      const gb_min = moment(
        new Date(Math.min.apply(null, gb_dates))
      ).format("DD MMM YYYY");
      //
      return {
        ...state,
        gb_transactionList:
          action.payload != undefined &&
            action.payload != null && action.payload != labels.messages.NA &&
            action.payload.length != 0
            ? action.payload.sort((a, b) => new Date(b.Date) - new Date(a.Date))
            : state.gb_transactionList,
        gb_minDate: gb_min,
        gb_maxDate: gb_max,
        gb_dropdowntransaction: action.payload,
        gb_isLoadingtransactionList: false,
        gb_isTransactionLoading: false,
      };
    case types.EMPTY_GB_TRANSACTION:
      return {
        ...state,
        gb_transactionList: [],
        gb_isLoadingtransactionList: false,
        gb_dropdowntransaction: [],
        gb_isLoadingPdf: false,
        gb_isLoadingExcel: false,
        gb_catchmessage: false,
        gb_minDate: moment().format("DD MMM YYYY"),
        gb_maxDate: moment().format("DD MMM YYYY"),
      };
    case types.LOADER_GB_TRANSACTION:
      return {
        ...state,
        gb_isLoadingtransactionList: action.payload,
      };

    //FD
    case types.FD_TRANSACTION:
      let fd_dates = [];
      let fd_transactionFullList =
        action.payload != undefined &&
          action.payload != null && action.payload != labels.messages.NA &&
          action.payload.length != 0
          ? action.payload.map(({ Unit, ...item }) => {
            return item
          })
          : [];
      if (fd_transactionFullList.length != 0) {
        fd_transactionFullList.map((value) => {
          fd_dates.push(new Date(moment(value.Date).format("YYYY/MM/DD")));
        });
      }
      const fd_max = moment(
        new Date(Math.max.apply(null, fd_dates))
      ).format("DD MMM YYYY");
      const fd_min = moment(
        new Date(Math.min.apply(null, fd_dates))
      ).format("DD MMM YYYY");
      //
      return {
        ...state,
        fd_transactionList:
          action.payload != undefined &&
            action.payload != null && action.payload != labels.messages.NA &&
            action.payload.length != 0
            ? action.payload.sort((a, b) => new Date(b.Date) - new Date(a.Date)).map(({ Unit, ...item }) => {
              return item
            })
            : [],
        fd_minDate: fd_min,
        fd_maxDate: fd_max,
        fd_dropdowntransaction: action.payload?.map(({ Unit, ...item }) => {
          return item
        }),
        fd_isLoadingtransactionList: false,
        fd_isTransactionLoading: false,

      };
    case types.EMPTY_FD_TRANSACTION:
      return {
        ...state,
        fd_transactionList: [],
        fd_isLoadingtransactionList: false,
        fd_dropdowntransaction: [],
        fd_isLoadingPdf: false,
        fd_isLoadingExcel: false,
        fd_catchmessage: false,
        fd_minDate: moment().format("DD MMM YYYY"),
        fd_maxDate: moment().format("DD MMM YYYY"),
      };
    case types.LOADER_FD_TRANSACTION:
      return {
        ...state,
        fd_isLoadingtransactionList: action.payload,
      };
    //NPS
    // case types.NPS_TRANSACTION:
    //   let nps_dates = [];
    //   let nps_transactionFullList =
    //     action.payload != undefined &&
    //       action.payload != null && action.payload != labels.messages.NA &&
    //       action.payload.length != 0
    //       ? action.payload
    //       : [];
    //   if (nps_transactionFullList.length != 0) {
    //     nps_transactionFullList.map((value) => {
    //       nps_dates.push(new Date(moment(value.Date).format("YYYY/MM/DD")));
    //     });
    //   }
    //   const nps_max = moment(
    //     new Date(Math.max.apply(null, nps_dates))
    //   ).format("DD MMM YYYY");
    //   const nps_min = moment(
    //     new Date(Math.min.apply(null, nps_dates))
    //   ).format("DD MMM YYYY");
    //   //
    //   return {
    //     ...state,
    //     nps_transactionList:
    //       action.payload != undefined &&
    //         action.payload != null && action.payload != labels.messages.NA &&
    //         action.payload.length != 0
    //         ? action.payload.sort((a, b) => new Date(b.Date) - new Date(a.Date))
    //         : state.nps_transactionList,
    //     nps_minDate: nps_min,
    //     nps_maxDate: nps_max,
    //     nps_dropdowntransaction: action.payload,
    //     nps_isLoadingtransactionList: false,
    //     nps_isTransactionLoading: false,
    //   };
    // case types.EMPTY_NPS_TRANSACTION:
    //   return {
    //     ...state,
    //     nps_transactionList: [],
    //     nps_isLoadingtransactionList: false,
    //     nps_dropdowntransaction: [],
    //     nps_isLoadingPdf: false,
    //     nps_isLoadingExcel: false,
    //     nps_catchmessage: false,
    //     nps_minDate: moment().format("DD MMM YYYY"),
    //     nps_maxDate: moment().format("DD MMM YYYY"),
    //   };
    // case types.LOADER_NPS_TRANSACTION:
    //   return {
    //     ...state,
    //     nps_isLoadingtransactionList: true,
    //   };

    //Insurance
    // case types.INSURANCE_TRANSACTION:
    //   let insurance_dates = [];
    //   let insurance_transactionFullList =
    //     action.payload != undefined &&
    //       action.payload != null && action.payload != labels.messages.NA &&
    //       action.payload.length != 0
    //       ? action.payload
    //       : [];
    //   if (insurance_transactionFullList.length != 0) {
    //     insurance_transactionFullList.map((value) => {
    //       insurance_dates.push(new Date(moment(value.Date).format("YYYY/MM/DD")));
    //     });
    //   }
    //   const insurance_max = moment(
    //     new Date(Math.max.apply(null, insurance_dates))
    //   ).format("DD MMM YYYY");
    //   const insurance_min = moment(
    //     new Date(Math.min.apply(null, insurance_dates))
    //   ).format("DD MMM YYYY");
    //   //
    //   return {
    //     ...state,
    //     insurance_transactionList:
    //       action.payload != undefined &&
    //         action.payload != null && action.payload != labels.messages.NA &&
    //         action.payload.length != 0
    //         ? action.payload.sort((a, b) => new Date(b.Date) - new Date(a.Date))
    //         : state.insurance_transactionList,
    //     insurance_minDate: insurance_min,
    //     insurance_maxDate: insurance_max,
    //     insurance_dropdowntransaction: action.payload,
    //     insurance_isLoadingtransactionList: false,
    //     insurance_isTransactionLoading: false,
    //   };
    // case types.EMPTY_INSURANCE_TRANSACTION:
    //   return {
    //     ...state,
    //     insurance_transactionList: [],
    //     insurance_isLoadingtransactionList: false,
    //     insurance_dropdowntransaction: [],
    //     insurance_isLoadingPdf: false,
    //     insurance_isLoadingExcel: false,
    //     insurance_catchmessage: false,
    //     insurance_minDate: moment().format("DD MMM YYYY"),
    //     insurance_maxDate: moment().format("DD MMM YYYY"),
    //   };
    // case types.LOADER_INSURANCE_TRANSACTION:
    //   return {
    //     ...state,
    //     insurance_isLoadingtransactionList: true,
    //   };

    default:
      return state;
  }
};
export default reducer;
