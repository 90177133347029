import React, { useState, useMemo } from 'react';
import { useEffect } from 'react';
import Dropdownschemes from '../dropdown/dropdownschemes';
import Pagination from './pagination';
// import data from './data/mock-data.json';
import './pagination.css';
import colors from '../../utils/colors/colors';
import Scrolltotop from '../scrolltotop/scrolltotop';
import Loader from '../loader/loader';
import { connect } from 'react-redux';
import { scrollToTopPage } from '../../utils/commonfunction';
export const MContext = React.createContext();
let PageSize = 100;
let scroll = false
function renderpage(props) {
    const [currentPage, setCurrentPage] = useState(1);
    const [count, setCount] = React.useState(PageSize);
    const [loader, setLoader] = React.useState(false)
    const [pageCount, setPageCount] = React.useState([])
    const handleChange = (event) => {
        PageSize = event.target.value

        setCount(event.target.value);
    };
    useEffect(() => {
        // setLoader(true)
        if (props.enableLazyLoading) {
            //    console.log(props.isReachedBottom)
            if (props.isReachedBottom) {
                // console.log(pageCount, currentPage, pageCount[pageCount.length] >= currentPage + 1, pageCount[pageCount.length - 1], currentPage + 1)
                if (pageCount[pageCount.length - 1] >= currentPage + 1) {
                    setLoader(true)
                    setTimeout(() => {
                        setCurrentPage(currentPage + 1)
                        document.getElementById("scroller").scrollTo(0, 14000)
                        setLoader(false)
                    }, 100);

                }
            } else {
                //setLoader(false) 
            }
        }

    }, [props.isReachedBottom,])
    const pageChange = (page) => {
        setCurrentPage(page)
    }
    const getPagination = (val) => {
        setPageCount(val)
    }
    const firstPageIndex = (currentPage - 1) * count;
    const lastPageIndex = firstPageIndex + count;
    let currentTableData = props.data != "NA" && props.data.length != 0 ? props.data.slice(props.enableLazyLoading ? 0 : firstPageIndex, scroll ? PageSize : lastPageIndex) : [];

    const scrollClick = () => {
        if (props.enableLazyLoading) {
            scroll = true
            scrollToTopPage()
        }

    }
    return (
        <>

            <MContext.Provider value={currentTableData}>
                {props.children}
                {loader && <Loader></Loader>}
            </MContext.Provider>
            {/* <Scrolltotop onClick={scrollClick}></Scrolltotop> */}
            <Pagination
                handleChange={handleChange}
                className="pagination-bar"
                callbackPagination={getPagination}
                isReachedBottom={props.isReachedBottom}
                currentPage={currentPage}
                totalCount={props.data.length}
                pageSize={count}
                onPageChange={pageChange}
                enableLazyLoading={props.enableLazyLoading}
            />
        </>
    );
}
const mapStateToProps = state => {
    return {
        isReachedBottom: state.Login.isReachedBottom
    }
}
export default connect(mapStateToProps, null)(renderpage);
