import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Labels from '../../utils/constants/labels';
import NoData from '../nodata/nodata'
import Skeleton from '@material-ui/lab/Skeleton';
import colors from '../../utils/colors/colors';
const useStyles = makeStyles((theme) =>({
  table: {
    // minWidth: 650,
  },
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 434,
    minWidth: 473,
    [theme.breakpoints.down("600")]: {
      minWidth:"revert",
    },
  },
  h2lable:{
    textAlign: 'left', 
    // marginLeft: 20, 
    marginTop: 0, 
    // paddingTop: "6%" ,
    fontWeight: 'bold',
    color:window.globalConfig.color.BLACK_PRIMARY,
    fontSize: 18,
  }
}));
function createData(company, sector, instrument, assets) {
  return { company, sector, instrument, assets };
}
export default function DynamicTable(props) {
  // const [row, setRow] = useState([]);
  const classes = useStyles();

  let rows = [];
  //   (props.data)
  props.data != undefined && props.data != null && props.data.length != 0 && props.data.map(value => {
    rows.push(createData(value.CompanyName, value.SectorName, value.Asset, value.HoldPercentage))
  });
  // 

  return (<>
    <h2 className={classes.h2lable} style={{paddingLeft:15,paddingTop:12}}>{Labels.schemedetails.Title}</h2>
    {props.isTransactionDetailsLoading ? <><Skeleton style={{ height: "10vh",}} /><Skeleton style={{  height: "10vh", }} /> <Skeleton style={{ height: "10vh", }} /> <Skeleton style={{  height: "10vh", }} /></>
      :
      <TableContainer className={classes.container}>

        {rows.length != 0 ?
          <Table className={[classes.table,"table"].join(" ")} stickyHeader aria-label="sticky table" >
            <TableHead>
              <TableRow >
                <TableCell align="left" >{Labels.schemedetails.company}</TableCell>
                <TableCell align="left" width="30%">{Labels.schemedetails.sector}</TableCell>
                <TableCell align="left" width="30%">{Labels.schemedetails.instrument}</TableCell>
                <TableCell align="right" width="60%" style={{whiteSpace:'nowrap'}}>%{" " +Labels.schemedetails.assets}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflowX: "scroll" }}>
              {rows.map((row, index) => {
                if (index <= 9) {
                  return <TableRow key={row.company}>
                    <TableCell align="left" scope="row">
                      {row.company}
                    </TableCell>
                    <TableCell align="left">{row.sector}</TableCell>
                    <TableCell align="left">{row.instrument}</TableCell>
                    <TableCell align="right">{row.assets} %</TableCell>
                  </TableRow>
                }

              })}
            </TableBody>
          </Table> : <NoData height={25} />}<br />
      </TableContainer>}
  </>
  );
}
