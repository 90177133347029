import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import '../../utils/stylesheets/tabs/tabs.css'
import colors from '../../utils/colors/colors';
import secureStorage from '../../utils/securestorage';
import { connect } from 'react-redux';
import PortfolioList from '../list/portfoliolist';
import { bindActionCreators } from "redux";
import { replaceNull } from '../../utils/commonfunction';
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        // <Typography>{children}</Typography>      
        <div
          style={{
            fontSize: '1rem',
            fontWeight: 400,
            lineHeight: 1.5,
            letterSpacing: '0.00938rem',
          }}
        >{children}</div>

      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}
const Styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    // backgroundColor: theme.palette.background.paper,
  },
  tabRoot: {
    "& .MuiTab-root:focus": {
      color: window.globalConfig.color.BROWN
    },
    "& .MuiTab-textColorPrimary.Mui-selected": {
      color: window.globalConfig.color.BROWN
    }
  },
});
class PortfolioTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 0
    }
  }

  componentDidMount() {
    // if (tabValue != null) {
    //   this.setState({ value: tabValue })
    // } else {
    this.setState({ value: 0 })
    // }

  }

  componentDidUpdate(prev) {
    const { page } = this.props;
    // 

    if (prev.page != page) {
      if (prev.page.state != undefined) {
        if (prev.page.state.tabValue != page.state.tabValue) {
          this.setState({ value: page.state.tabValue })
        }
      }
    }


  }


  handleChange = (event, newValue) => {
    this.setState({ value: newValue })
  };
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar style={{ borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}` }} position="static" color="default">
          <Tabs
            value={this.state.value}
            onChange={this.handleChange}
            indicatorColor="primary"
            classes={{ root: classes.tabRoot }}
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{ style: { background: window.globalConfig.color.BROWN } }}
          >
            <Tab label="Upcoming Transactions" {...a11yProps(0)} />
            <Tab label="Recent Transactions" {...a11yProps(1)} />

          </Tabs>
        </AppBar>
        <TabPanel value={this.state.value} index={0}>
          <PortfolioList tab="upcoming" data={this.props?.upComingTransaction} isLoading={this.props?.isLoadingUpComingTransaction}></PortfolioList>
        </TabPanel>
        {/* {console.log(this.props.transactionList)} */}
        <TabPanel value={this.state.value} index={1}>
          <PortfolioList tab="recent" data={this.props.transactionList} isLoading={this.props.isLoadingTransactionList}></PortfolioList>
        </TabPanel>

      </div>
    );
  }
}
const mapStateToProps = state => {
  // //
  // console.log(state.UpcommingSIP.UpcommingSIPdata)
  return {
    upComingTransaction: replaceNull(state.UpcommingSIP.UpcommingSIPdata, "NA") == "NA" ? [] : state.UpcommingSIP.UpcommingSIPdata.length > 5 ? state.UpcommingSIP.UpcommingSIPdata.slice(0, 10).map((item) => {
      return { Date: item.TransactionDate, Type: item.TransactionType, Scheme: item.SchemeName, Rupee: item.Amount, Amc: item.AMCName }
    }) : state.UpcommingSIP.UpcommingSIPdata.map((item) => {
      return { Date: item.TransactionDate, Type: item.TransactionType, Scheme: item.SchemeName, Rupee: item.Amount, Amc: item.AMCName }
    }),
    // recentTransaction: state.Sample.recentTransaction,
    transactionList: state.transactionList.transactionList != null || state.transactionList.transactionList.length != 0 ? state.transactionList.transactionList.length >= 5 ? state.transactionList.transactionList.sort(function (a, b) {
      return new Date(b.Date) - new Date(a.Date);
    }).slice(0, 5).map((item) => {
      return { Date: item.Date, Type: item.TransactionType, Scheme: item.Scheme, Rupee: item.Value, Amc: item.AMC, Name: item.Name }
    }) : state.transactionList.transactionList.sort(function (a, b) {
      return new Date(b.Date) - new Date(a.Date);
    }).map((item) => {
      return { Date: item.Date, Type: item.TransactionType, Scheme: item.Scheme, Rupee: item.Value, Amc: item.AMC, Name: item.Name }
    }) : [],
    isLoadingUpComingTransaction: state.UpcommingSIP.isUpcomingSIPLoading,
    isLoadingTransactionList: state.transactionList.isLoadingtransactionList,

  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {

    },
    dispatch
  );
};
export default withStyles(Styles)(connect(mapStateToProps, mapDispatchToProps)(PortfolioTab))

