import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router';
import { withStyles } from '@material-ui/core';
import CommonSnackbar from '../../components/snackbar/snackbar';
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle';
import labels from '../../utils/constants/labels';
import colors from '../../utils/colors/colors';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import Subheader from '../../components/subheader/subheader';
import Toggle from "../../components/toggle/toggle";
import Selectedcheckbox from "../../utils/assets/selectedcheckbox.png"
import Textinput from '../../components/textinput/textinput';
import DatePicker from '../../components/datepickers/date_pickers';
import CommonDropdown from '../../components/dropdown/dropdownschemes';
import moment from 'moment';
import { amountCommaSeperator, numbersandDeciamlOnly, hasNull, round, notAllowdSpecialCharater, IFSCvalidation, alphaNumericOnly, alphabetSpaceOnly, replaceNull, uniqueId, accountNumberOnly, numbersandDeciamlOnlyExtraLength, NegativeFinding } from '../../utils/commonfunction';
import Dynamic_row from '../../components/dynamic_row/dynamic_row';
import PlusIcon from '../../../src/utils/assets/images/plus-46.png';
import Dialog from '@material-ui/core/Dialog';
import CommonButton from '../../components/custombutton/button';
import DynamicList from '../../components/list/dynamicList';
import getNPSDropdown from '../../api/nps/nps_dropdown';
import getInsuranceAddbank from "../../api/insurance/add_bank";
import getAddInsurance from "../../api/insurance/add_insurance";
import getAddUpdateDeleteInsurance from "../../api/insurance/add_update_life_insurance";
import getEditInsurance from "../../api/insurance/edit_insurance";
import DeletePopup from '../../components/popup/deletepopup';
import Three_State_Toggle_Switch from "../../components/switchbutton/three_state_toggle_switch";
import CommonCheckbox from "../../components/checkbox/checkbox";
import GetRiderNameDropdown from "../../api/insurance/get_rider_name_dropdown";
import { scrollToTopPage } from '../../utils/commonfunction';
import InformationTooltip from '../../components/tooltip/informationtooltip';
const styles = theme => ({
    flex: {
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down("920")]: {
            flexDirection: 'column'
        }
    },
    sideSpace: {
        width: '12%',
        [theme.breakpoints.down("920")]: {
            display: 'none'
        }
    },
    mainCard: {
        display: "flex", marginBottom: "3%",
        boxShadow: "rgb(19 89 161 / 10%) 0px 0px 20px 0px",
        borderRadius: 5,
        height: "auto",
        width: "89%", marginTop: "18px",
        padding: '4%',
        paddingTop: "13px !important",
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down("920")]: {
            margin: 'auto'
        }
    },

    mainCardNominee: {
        display: "flex", marginBottom: "3%",
        boxShadow: "rgb(19 89 161 / 10%) 0px 0px 20px 0px",
        borderRadius: 5,
        height: "auto",
        width: "100%", marginTop: "13px",
        padding: '0%',
        paddingTop: "13px !important",
        paddingBottom: "13px !important",
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down("920")]: {
            margin: 'auto'
        }
    },
    nomineeTypeRow: {
        // marginTop: 4,
        display: 'grid',
        gridTemplateColumns: 'repeat(5,1fr)',
        columnGap: '20px',
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: 'repeat(3,1fr)',
        }
    },
    marginTopAlignment: {
        display: "flex",
        flexDirection: "column",
        alignItems: "end",
    },
    // main title css
    header: {
        textAlign: 'left',
        textTransform: 'uppercase',
    },
    // herizontal line css
    cardSpareaterLine: {
        height: 0.5,
        width: "100%",
        backgroundColor: window.globalConfig.color.LABLE_GRAY,
        marginBottom: '1pc',
        marginTop: '6px',
    },
    firstRow: {
        marginTop: "2%"
    },
    insuredLabel: {
        color: window.globalConfig.color.LABLE_GRAY,
        textAlign: "left",
        fontSize: '14px',
        fontWeight: 'normal',
        flexDirection: "row"
    },
    insuredTypeTextboxRow: {
        marginTop: 4,
        display: 'flex',
        //   flexDirection: 'row',
        // gridTemplateColumns: 'repeat(5,1fr)',
        // gridTemplateColumns:"15% 15% 15% 15% 15%",
        columnGap: '20px',
        flexWrap: 'wrap',
        rowGap: '10px',
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: 'repeat(1,1fr)',
        }
    },
    insuredTypeTextbox: {
        minWidth: '100px',
        gap: '10px',
        borderRadius: 5,
        border: "1px solid",
        color: window.globalConfig.color.LABLE_GRAY,
        // marginTop: 2,
        borderColor: window.globalConfig.color.lightGray,
        height: 22,
        padding: 10,
        textAlign: 'left',
        fontSize: '14px',
        flexDirection: 'row',
        cursor: "pointer",
        boxShadow: "3px 3px 3px 0 rgb(0 0 0 / 10%)",
        whiteSpace: 'nowrap',
        alignItems: 'center',
        display: 'flex',
    },
    insuredTypeTextboxSecond: {
        alignItems: 'center',
        whiteSpace: 'nowrap',
        gap: '10px',
        minWidth: '100px',
        borderRadius: 5,
        border: "1px solid",
        backgroundColor: window.globalConfig.color.RosyBrown,
        // marginTop: 2,
        borderColor: window.globalConfig.color.PRIMARY_BORDER,
        height: 22,
        padding: 10,
        textAlign: 'left',
        color: window.globalConfig.color.brown,
        fontSize: '14px',
        flexDirection: 'row',
        display: 'flex',
        cursor: "pointer",
        boxShadow: "3px 3px 3px 0 rgb(0 0 0 / 10%)",
        justifyContent: 'space-between',

    },
    insuredTypeTextboxDisable: {
        minWidth: '100px',
        gap: '10px',
        borderRadius: 5,
        border: "1px solid",
        color: window.globalConfig.color.LABLE_GRAY,
        // marginTop: 2,
        borderColor: window.globalConfig.color.PRIMARY_BORDER,
        height: 22,
        padding: 10,
        textAlign: 'left',
        fontSize: '14px',
        flexDirection: 'row',
        cursor: "pointer",
        boxShadow: "3px 3px 3px 0 rgb(0 0 0 / 10%)",
        whiteSpace: 'nowrap',
        alignItems: 'center',
        display: 'flex',
        backgroundColor: "rgb(135 135 135 / 32%)"
    },
    insuredText: {
        // flexDirection: 'column',
        // width: '80%',
    },
    insuredTextImage: {
        flexDirection: 'column',
        width: '20%',
    },
    grid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3,1fr)',
        columnGap: '20px',
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: 'repeat(1,1fr)',
        }
    },
    riderGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(3,1fr)",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: 'repeat(2, [col-start] minmax(50%, 1fr) [col-end])',
            marginLeft: 5,
            display: "grid",
        }
    },
    riderGridGeneral: {
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: 'repeat(1,1fr)',
            marginLeft: 5,
            display: "grid",
        }
    },
    TransactionContainerWithWidth: {
        [theme.breakpoints.up("920")]: {
            display: "grid",
            gridTemplateColumns: "35% 35% 30%",
            width: "100%", marginRight: 0,
        },
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: 'repeat(2, [col-start] minmax(50%, 1fr) [col-end])',
            marginLeft: 5,
            display: "grid",
        }
    },
    twoColumnGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)'
    },
    threeColumnGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(3,1fr)'
    },
    headerGrid: {
        textAlign: 'left',
        paddingLeft: 15
    },
    amountHeaderGrid: {
        textAlign: 'right',
        paddingRight: 15
    },

    AssetRow: {
        display: 'grid',
        gap: '7px',
        gridTemplateColumns: "91% 5%",
        minHeight: '90px',
        [theme.breakpoints.down('xs')]: {
            display: 'grid',
            gap: '10px',
            gridTemplateColumns: "91% 5%",
        }
    },
    centerAlignment: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        alignItem: 'center',
        cursor: 'pointer',
        // marginTop: '1.5pc',
    },
    popupWidth: {
        width: '320px',
        [theme.breakpoints.down("xs")]: {
            width: '255px',
        }
    },
    popupheader: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: "white",
        height: 50,
        fontWeight: 500
    },
    txtHeader: {
        padding: 15,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        [theme.breakpoints.down("600")]: {
            padding: '15px 20px 15px 15px',
            justifyContent: "revert",
        },
        [theme.breakpoints.down("600")]: {
            padding: '15px 20px 15px 15px',
            justifyContent: 'space-between',
        },
    },
    footercontainer: {
        display: 'flex',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
    },
    clearIcon: {
        cursor: "pointer",
        padding: 0,
        [theme.breakpoints.down("600")]: {
            padding: '0% 0% 0% 30%',
        },
        [theme.breakpoints.down("350")]: {
            padding: '0% 0% 0% 13% !important',

        },
    },
    popupBodyContainer: {
        margin: "4%",
    },
    popupButtonCantainer: {
        width: '90%',
        margin: '0% 4% 3% 5%',
    },
    popupfooterContainer: { display: "flex", flexDirection: "row", gap: 10, justifyContent: "space-between" },
    buttonStyle: {
        width: "20%",
        [theme.breakpoints.down("920")]: {
            width: "50%"
        }
    },
    riderHeader: {
        display: "flex", flexDirection: "row", alignItems: "baseline", justifyContent: "space-between",
    },
    riderHeaderSecond: { textAlign: "right", color: window.globalConfig.color.PRIMARY, fontSize: 14, cursor: "pointer" },
    assetGrid: {
        //  gridTemplateColumns: "19% auto auto auto auto auto auto auto",
        gridTemplateColumns: "20% 20% 20% 18% 20% 2%",
        display: "grid",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(3,1fr)",
            gridTemplateAreas: `"${"myArea myArea."}"`,
            height: "auto !important",
            rowGap: "20px",
            paddingTop: 10,
            display: "grid",
        }
    },
    gridTrusteeGrid: {
        gridTemplateColumns: "33% 33% 32% 2%",
        display: "grid",
        [theme.breakpoints.down("920")]: {
            gridTemplateColumns: "repeat(3,1fr)",
            gridTemplateAreas: `"${"myArea myArea."}"`,
            height: "auto !important",
            rowGap: "20px",
            paddingTop: 10,
            display: "grid",
        }
    }
})
var riderNameList = [];
let timerx = ''
let timery = ''
class Insurance_Data_Entry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: false,
            // values: ['Health', 'Life', 'General'],
            flag: 'Health',

            // Insured For
            insuredType: [],
            insuredTypeGeneral: [],
            insuredTypeError: "",

            // Policy Name
            policyNameList: "",
            policyNameError: "",
            policyName: "",
            policy: "",

            // Policy No
            policyNo: "",
            policyNoError: "",

            // Policy Type
            policyTypeList: "",
            policyTypeError: "",
            policyTypeName: "",
            policyType: "",

            // Nick Name
            nickName: "",
            nickNameError: "",

            // Start Date
            startDateTemp: moment().format("DD MMM YYYY"),
            anchorEl: null,
            startDateError: "",
            startDate: "",

            // Cover Amount
            coverAmount: "",
            coverAmountError: "",

            // Premium Amount
            premiumAmount: "",
            premiumAmountError: "",

            // Policy Term
            policyTerm: "",
            policyTermError: "",

            // Bank Account,
            bankAccountList: "",
            bankAccountError: "",
            bankAccount: "",
            bank: "",

            // bank Popup
            openPopup: false,
            // Bank Popup Bank Name
            bankNameList: "",
            bankNamePopupError: "",
            bankNameValue: "",
            bankName: "",
            // Bank Popup Account Number
            accountNumber: "",
            accountNumberError: "",
            // Bank Popup IFSC Code
            ifscCode: "",
            ifscCodeError: "",

            // Nominee Name
            nomineeName: "",
            nomineeNameError: "",

            // Nominee Relationship
            nomineeRelationshipValue: "",
            nomineeRelationshipError: "",
            nomineeRelationshipList: "",
            nomineeRelationshipListCopy: "",
            nomineeRelationship: "",

            // Date of Birth
            dateOfBirthTemp: moment().format("DD MMM YYYY"),
            anchorBirthDateEl: null,
            dateOfBirthError: "",
            dateOfBirth: "",

            // Guardian
            guardian: "",
            guardianError: "",
            nomineeGuardianVisible: false,

            // Nominee Percentage
            nomineePercentage: 100,
            nomineePercentageCalculate: 0,
            nomineePercentageError: "",
            nomineeListLengthManage: "", // Nominee List Length handle

            nomineeList: [],
            headerList: [
                { label: "Nominee Name", align: 'left' },
                { label: "Relationship", align: 'center' }, { label: "DOB", align: 'center' }, { label: "Guardian", align: 'center' }, { label: "Percentage", align: 'right' }],
            grid: "",
            gridTrustee: "",
            popupList: [],
            enableIcon: true,
            nomineeButtonVisible: true, //  Add Nominee button visible or Nominee Textbox, Dropdown visible
            nomineeID: "",
            updateFlag: "add",

            //rider Health
            riderList: [{
                riderName: {
                    name: "",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "Select Rider Name",
                    style: "dropdown",
                    list: [],
                    label: ""
                },
                riderFor: {
                    name: "",
                    errormessage: "",
                    isClicked: true,
                    placeholder: "Select Rider For",
                    style: "dropdown",
                    list: this.props.riderForDropdownList,
                    enableDeleteIcon: false
                },
                riderSum: {
                    name: "0",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "0",
                    style: "amount",
                    label: "",
                    enableDeleteIcon: true
                },
            }],
            riderValue: [{
                riderName: "",
                riderFor: "",
                riderSum: "",
            }],

            // Rider General
            riderListGeneral: [{
                riderName: {
                    name: "",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "Select Rider Name",
                    style: "dropdown",
                    list: [],
                    label: ""
                },
                riderSum: {
                    name: "0",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "0",
                    style: "amount",
                    label: "",
                    enableDeleteIcon: true
                },

            }],
            riderValueGeneral: [{
                riderName: "",
                riderSum: "",
            }],

            //Rider Life
            riderListLife: [{
                riderName: {
                    name: "",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "Select Rider Name",
                    style: "dropdown",
                    list: [],
                    label: ""
                },
                riderTerm: {
                    name: "",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "0",
                    style: "amount",
                    label: "",
                    enableDeleteIcon: false
                },
                riderSum: {
                    name: "0",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "0",
                    style: "amount",
                    label: "",
                    enableDeleteIcon: true
                },
            }],
            riderValueLife: [{
                riderName: "",
                riderTerm: "",
                riderSum: "",
            }],

            ridersListError: "",

            // Button
            loader: false,
            addLoader: false,

            // Nominee Delete ID
            deleteID: "",

            // Delete Popup
            deletePopupText: labels.Insurance.confirmationText,
            deletePopupLoader: false,
            openDeletePopup: false,

            // Delete Popup Beneficiary
            deletePopupTextBeneficiary: labels.Insurance.confirmationTextBeneficiary,
            deletePopupLoaderBeneficiary: false,
            openDeletePopupBeneficiary: false,

            // Delete Popup Trustee
            deletePopupTextTrustee: labels.Insurance.confirmationTextTrustee,
            deletePopupLoaderTrustee: false,
            openDeletePopupTrustee: false,

            // Delete Popup ULIP
            deletePopupTextULIP: labels.Insurance.confirmationTextULIP,
            deletePopupLoaderULIP: false,
            openDeletePopupULIP: false,

            popupLoader: false,
            openSnackBar: false,
            message: "",
            severity: "success",
            EditID: 0,

            // Policy For
            policyForList: "",
            policyForError: "",
            policyForLabel: "",
            policyFor: "",

            // Name
            name: "", nameError: "",

            // Gender
            genderList: "",
            genderError: "",
            genderLabel: "",
            gender: "",

            // Information Date of Birth
            informationDateOfBirthTemp: moment().format("DD MMM YYYY"),
            informationAnchorBirthDateEl: null,
            informationDateOfBirthError: "",
            informationDateOfBirth: "",

            // Premium Frequency
            premiumFrequencyList: "", premiumFrequencyError: "", premiumFrequencyLabel: "", premiumFrequency: "",

            // Premium Paying Term
            premiumPayingTerm: "", premiumPayingTermError: "",

            // Checkbox
            checkboxChecked: false,

            // Trustee Name
            trusteeName: "", trusteeNameError: "",

            // Trustee Relationship
            trusteeRelationshipList: "", trusteeRelationshipError: "", trusteeRelationshipValue: "", trusteeRelationship: "",
            trusteeRelationshipListCopy: "",

            // Trustee Date of Birth
            trusteeDateOfBirthTemp: moment().format("DD MMM YYYY"), anchorTrusteeBirthDateEl: "", trusteDateOfBirthError: "", trusteeDateOfBirth: "",

            trusteeList: [],
            headerTrusteeList: [
                { label: "Trustee Name", align: 'left' },
                { label: "Relationship", align: 'center' }, { label: "DOB", align: 'right' }],
            trusteePopupList: [],
            trusteeID: "",
            trusteeUpdateFlag: "add",

            // Trustee DeleteID
            trusteeDeleteID: "",

            // Beneficiary Name
            beneficiaryName: "", beneficiaryNameError: "",

            // Beneficiary  Relationship
            beneficiaryRelationshipList: "", beneficiaryRelationshipError: "", beneficiaryRelationshipValue: "", beneficiaryRelationship: "",
            beneficiaryRelationshipListCopy: "",

            // Beneficiary Date of Birth
            beneficiaryDateOfBirthTemp: moment().format("DD MMM YYYY"), anchorBeneficiaryBirthDateEl: "",
            beneficiaryDateOfBirthError: "", beneficiaryDateOfBirth: "",

            // Beneficiary Guardian
            beneficiaryGuardian: "", beneficiaryGuardianError: "",
            beneficiaryGuardianVisible: false,

            // Beneficiary Percentage
            beneficiaryPercentage: 100, beneficiaryPercentageError: "",
            beneficiaryPercentageCalculate: 0,
            beneficiaryListLengthManage: "", // beneficiary List Length handle

            beneficiaryList: [],
            headerBeneficiaryList: [
                { label: "Beneficiary Name", align: 'left' },
                { label: "Relationship", align: 'center' }, { label: "DOB", align: 'center' }, { label: "Guardian", align: 'center' }, { label: "Percentage", align: 'right' }],
            beneficiaryPopupList: [],
            beneficiaryButtonVisible: true, //  Add beneficiary button visible or beneficiary Textbox, Dropdown visible
            beneficiaryID: "",
            beneficiaryUpdateFlag: "add",

            // Beneficiary Delete ID
            beneficiaryDeleteID: "",

            // ULIP PARTS
            // As on Date
            asOnDateTemp: moment().format("DD MMM YYYY"), anchorAsOnDateEl: "", asOnDateError: "", asOnDate: moment().format("DD MMM YYYY"),

            // Scheme Name
            schemeNameList: "", schemeNameError: "", schemeNamelabel: "", schemeName: "",
            schemeNameUpdate: "",

            // Units
            units: "", unitsError: "",

            // Latest NAV
            latestNAV: "", latestNAVError: "",

            // Latest Value
            latestValue: "", latestValueError: "",

            uLIPList: [],
            headerULIPList: [
                { label: "Scheme Name", align: 'left' }, { label: "Date", align: 'center' }, { label: "Units", align: 'center' },
                { label: "Latest NAV", align: 'center' }, { label: "Latest Value", align: 'right' }],
            uLIPPopupList: [],
            uLIPID: "",
            uLIPUpdateFlag: "add",

            // ULIP DeleteID
            uLIPDeleteID: "",
        }
    }

    npsDropdown = () => {
        // This api call for Policy Name and Rider Name Dropdown and  Bank Account List Dropdown
        let data = {}
        data.clientID = this.props.clientID
        //  data.clientID = 20427
        getNPSDropdown(data).then((res) => {
            if (res.status == "S") {
                // Policy Name Dropdown   
                if (this.props.location.state.data != undefined) {
                    if (this.props.location.state.data == "Life") {
                        if (this.commonFunction(res.table5) && res.table5.length != 0) {
                            if (this.commonFunction(this.props.insuranceReduxTable.table5)) {
                                var assetClassData = this.props.insuranceReduxTable.table5.filter((item) => (item.AssetClassCode == "INSLI"))

                                var PolicyNameData = res.table5.filter((item) => (assetClassData[0].ID == item.CategoryID))
                                if (this.commonFunction(PolicyNameData)) {
                                    this.setState({
                                        policyNameList: PolicyNameData.map((item) => {
                                            return { "label": item.Name, "value": item.ID }
                                        })
                                    })
                                }
                                else {
                                    this.setState({
                                        policyNameList: ""
                                    })
                                }
                            }
                        }
                    }
                    else {
                        if (this.commonFunction(res.table3) && res.table3.length != 0) {
                            if (this.props.location.state.data == "Health") {
                                if (this.commonFunction(this.props.insuranceReduxTable.table5)) {
                                    var assetClassData = this.props.insuranceReduxTable.table5.filter((item) => (item.AssetClassCode == "INSHI"))

                                    var PolicyNameHealthData = res.table3.filter((item) => (assetClassData[0].ID == item.CategoryID))
                                    if (this.commonFunction(PolicyNameHealthData)) {
                                        this.setState({
                                            policyNameList: PolicyNameHealthData.map((item) => {
                                                return { "label": item.Name, "value": item.ID }
                                            })
                                        })
                                    }
                                    else {
                                        this.setState({
                                            policyNameList: ""
                                        })
                                    }
                                }
                            }
                            else {
                                if (this.commonFunction(this.props.insuranceReduxTable.table5)) {
                                    var assetClassData = this.props.insuranceReduxTable.table5.filter((item) => (item.AssetClassCode == "INSGI"))
                                    var PolicyNameGeneralData = res.table3.filter((item) => (assetClassData[0].ID == item.CategoryID))

                                    if (this.commonFunction(PolicyNameGeneralData)) {
                                        this.setState({
                                            policyNameList: PolicyNameGeneralData.map((item) => {
                                                return { "label": item.Name, "value": item.ID }
                                            })
                                        })
                                    }
                                    else {
                                        this.setState({
                                            policyNameList: ""
                                        })
                                    }
                                }
                            }

                        }
                    }
                }

                // if (this.commonFunction(res.table2) && res.table2.length != 0) {
                //     riderNameList = res.table2.map((item) => {
                //         return { "label": item.Name, "value": item.ID }
                //     })

                //     console.log(riderNameList,'riderNameList')
                // }

                // Bank Account List Dropdown
                if (this.commonFunction(res.table4) && res.table4.length != 0) {
                    this.setState({
                        bankAccountList: res.table4.sort(function (a, b) {
                            if (a.Name.toLowerCase() < b.Name.toLowerCase()) return -1;
                            if (a.Name.toLowerCase() > b.Name.toLowerCase()) return 1;
                            return 0;
                        })
                            .map((item) => {
                                return { "label": item.Name, "value": item.ID }
                            })
                    })
                }

                // SchemeName List Dropdown                
                if (this.commonFunction(res.table6) && res.table6.length != 0) {
                    this.setState({
                        schemeNameList: res.table6.map((item) => {
                            return { "label": item.Name, "value": item.ID }
                        })
                    })
                }
                this.riderListValue();

            }
        }).catch((err) => {

        })
    }

    componentDidMount() {
        scrollToTopPage()
        const { classes } = this.props
        this.setState({
            grid: classes.assetGrid,
            gridTrustee: classes.gridTrusteeGrid,
            popupList: [{ "name": "Edit", "onClick": this.editNominee }, { "name": "Delete", "onClick": this.deleteNominee }],
            beneficiaryPopupList: [{ "name": "Edit", "onClick": this.editBenificiary }, { "name": "Delete", "onClick": this.deleteBenificiary }],
            trusteePopupList: [{ "name": "Edit", "onClick": this.editTrustee }, { "name": "Delete", "onClick": this.deleteTrustee }],
            uLIPPopupList: [{ "name": "Edit", "onClick": this.editULIP }, { "name": "Delete", "onClick": this.deleteULIP }],
        })

        //  This Function call for Policy Name and Rider Name Dropdown Bank Account List Dropdown
        this.npsDropdown();

        // Insured Type and Insured Type General Button List
        this.insureTypeDropdown();

        // Policy Type Dropdown List
        if (this.commonFunction(this.props.insuranceReduxTable.table2)) {
            var policyTypeDropdown = this.props.insuranceReduxTable.table2.map((item) => {
                return { "label": item.Name, "value": item.ID }
            })
            this.setState({
                policyTypeList: policyTypeDropdown
            })
        }

        // Nominee Relationship Dropdown List
        if (this.commonFunction(this.props.insuranceReduxTable.table4)) {
            var nomineeRelationshipDropdown = this.props.insuranceReduxTable.table4.map((item) => {
                return { "label": item.Name, "value": item.ID }
            })
            this.setState({
                // Nominee Relationship
                nomineeRelationshipList: nomineeRelationshipDropdown,
                nomineeRelationshipListCopy: nomineeRelationshipDropdown,
                // Beneficiary Relationship
                beneficiaryRelationshipList: nomineeRelationshipDropdown,
                beneficiaryRelationshipListCopy: nomineeRelationshipDropdown,
                // Trustee Relationship
                trusteeRelationshipList: nomineeRelationshipDropdown,
                trusteeRelationshipListCopy: nomineeRelationshipDropdown,
            })
        }

        // Bank Account List Dropdown
        if (this.commonFunction(this.props.insuranceReduxTable.table7)) {
            var bankNameListDropdown = this.props.insuranceReduxTable.table7.map((item) => {
                return { "label": item.BankName, "value": item.ID }
            })
            this.setState({
                bankNameList: bankNameListDropdown
            })
        }

        // Gender List Dropdown
        if (this.commonFunction(this.props.insuranceReduxTable.table9)) {
            var genderListDropdown = this.props.insuranceReduxTable.table9.map((item) => {
                return { "label": item.Name, "value": item.ID }
            })
            this.setState({
                genderList: genderListDropdown
            })
        }

        // Premium Frequency List Dropdown
        if (this.commonFunction(this.props.insuranceReduxTable.table10)) {
            var premiumListDropdown = this.props.insuranceReduxTable.table10.map((item) => {
                return { "label": item.Name, "value": item.ID }
            })
            this.setState({
                premiumFrequencyList: premiumListDropdown
            })
        }

        if (this.props.location.state != undefined) {
            if (this.props.location.state.data != undefined) {
                // this.setState({ flag: this.props.location.state.data }, () => {
                //     if (this.state.flag == "Health") {
                //         this.setState({
                //             selected: false, // Health Flag selected false
                //         })
                //     }
                //     else {
                //         this.setState({
                //             selected: true, // General Flag selected true
                //         })
                //     }
                // })
                this.setState({
                    flag: this.props.location.state.data
                })
            }

            // Policy For
            if (this.commonFunction(this.props.riderForDropdownListSecond)) {
                this.setState({
                    policyForList: this.props.riderForDropdownListSecond
                })
            }
            else {
                this.setState({
                    policyForList: ""
                })
            }

            if (this.props.location.state.data != undefined && this.props.location.state.id != undefined) {
                var data1;
                if (this.props.location.state.data == "Life") {
                    data1 = {
                        clientID: this.props.clientID,
                        id: this.props.location.state.id,
                        flag: 2
                    }
                }
                else {
                    data1 = {
                        clientID: this.props.clientID,
                        id: this.props.location.state.id
                    }
                }

                // Edit API Integartion
                getEditInsurance(data1).then(response => {
                    if (response.status == "S") {
                        let resp = replaceNull(response, "NA")
                        // LIFE FLAG - EDIT                        
                        if (this.props.location.state.data == "Life") {
                            if (this.commonFunction(resp.table1) && resp.table1 != "NA") {
                                this.setState({
                                    policyName: resp.table1[0].PolicyName,
                                    policy: resp.table1[0].PolicyID,
                                    policyNo: resp.table1[0].PolicyNo,
                                    policyForLabel: resp.table1[0].PolicyForName,
                                    policyFor: resp.table1[0].PolicyFor,
                                    name: resp.table1[0].Name,
                                    genderLabel: resp.table1[0].GenderName,
                                    gender: resp.table1[0].Gender,
                                    informationDateOfBirth: moment(resp.table1[0].DOB).format("DD MMM YYYY"),
                                    bankAccount: resp.table1[0].BankName == "" || typeof (resp.table1[0].BankName) == "object" || resp.table1[0].BankName == undefined || resp.table1[0].BankName == null ? "" : resp.table1[0].BankName,
                                    bank: resp.table1[0].BankAccountTypeID,
                                    checkboxChecked: resp.table1[0].IsMWPA
                                })
                            }
                            if (this.commonFunction(resp.table2) && resp.table2 != "NA") {
                                this.setState({
                                    startDate: moment(resp.table2[0].TransactionStartDate).format("DD MMM YYYY"),
                                    coverAmount: resp.table2[0].CoverAmount,
                                    premiumAmount: resp.table2[0].PremiumAmount,
                                    policyTerm: resp.table2[0].PolicyTerm,
                                    premiumFrequencyLabel: resp.table2[0].PremiumFrequencyName,
                                    premiumFrequency: resp.table2[0].PremiumFrequency,
                                    premiumPayingTerm: resp.table2[0].PremiumPayingTerm,
                                })
                            }
                            // table3 - Trustee List
                            if (this.commonFunction(resp.table3) && resp.table3 != "NA") {
                                var trusteeListData = resp.table3.map((item) => {
                                    return {
                                        "trusteeName": { name: item.Name, subHeader1: "", subHeader2: "", type: '' },
                                        "trusteeRelationship": { name: item.Relationship, subHeader1: "", subHeader2: "", type: '' },
                                        "trusteeDateOfBirth": { name: moment(item.DOB).format("DD MMM YYYY"), subHeader1: "", subHeader2: "", type: '' },
                                        "id": { name: item.ID, subHeader1: '', subHeader2: "", type: '' },
                                        "trusteeRelationshipID": { name: item.RelationshipID, subHeader1: "", subHeader2: "", type: '' },
                                    }
                                })

                                this.setState({
                                    trusteeList: trusteeListData
                                })
                            }
                            // table4 - RiderLife
                            if (this.commonFunction(resp.table4) && resp.table4 != "NA") {
                                this.setState({
                                    riderListLife: resp.table4.map((item) => {
                                        return {
                                            riderName: {
                                                name: item.RiderName,
                                                errormessage: "",
                                                isClicked: false,
                                                placeholder: "Select Rider Name",
                                                style: "dropdown",
                                                list: riderNameList,
                                                label: ""
                                            },
                                            riderTerm: {
                                                name: item.RiderTerm,
                                                errormessage: "",
                                                isClicked: false,
                                                placeholder: item.RiderTerm,
                                                style: "amount",
                                                label: "",
                                                enableDeleteIcon: false
                                            },
                                            riderSum: {
                                                name: item.RiderSumAssured,
                                                errormessage: "",
                                                isClicked: false,
                                                placeholder: item.RiderSumAssured,
                                                style: "amount",
                                                label: "",
                                                enableDeleteIcon: true
                                            },
                                        }
                                    })
                                    ,
                                    riderValueLife: resp.table4.map((item) => {
                                        return {
                                            riderName: item.RiderNameID,
                                            riderTerm: item.RiderTerm,
                                            riderSum: item.RiderSumAssured,
                                        }
                                    })
                                })
                            }
                            //table5 - Nominee Details
                            if (this.commonFunction(resp.table5) && resp.table5 != "NA") {
                                var nomineeListData = resp.table5.map((item) => {
                                    return {
                                        "nomineeName": { name: item.NomineeName, subHeader1: "", subHeader2: "", type: '' },
                                        "nomineeRelationship": { name: item.NomineeRelationship, subHeader1: "", subHeader2: "", type: '' },
                                        "dateOfBirth": { name: typeof (item.DOB) == "object" || item.DOB == "" ? "" : item.DOB, subHeader1: "", subHeader2: "", type: '' },
                                        "guardian": { name: typeof (item.NomineeGuardianName) == 'object' || item.NomineeGuardianName == "" ? "NA" : item.NomineeGuardianName, subHeader1: "", subHeader2: "", type: '' },
                                        "nomineePercentage": { name: item.Percentage, subHeader1: "", subHeader2: "", type: 'number' },
                                        "id": { name: item.ID, subHeader1: '', subHeader2: "", type: '' },
                                        "nomineeRelationshipID": { name: item.NomineeRelationshipId, subHeader1: "", subHeader2: "", type: '' },
                                        "nomineeType": { name: item.NomineeTypeID, subHeader1: "", subHeader2: "", type: '' }, // Major-1, Minor-2
                                    }
                                })
                                if (nomineeListData.length > 0) {
                                    var percentage = nomineeListData.map((item) => {
                                        return item.nomineePercentage.name
                                    })
                                    this.setState({
                                        nomineeList: nomineeListData,
                                        nomineePercentageCalculate: percentage.reduce((a, b) => {
                                            return Number(a) + Number(b)
                                        })
                                    }, () => {

                                        var relation = nomineeListData.map((item) => {
                                            return item.nomineeRelationship.name
                                        })

                                        // Nominee Relationship Dropdown List
                                        if (this.commonFunction(this.props.insuranceReduxTable.table4)) {
                                            var nomineeRelationshipDropdown = this.props.insuranceReduxTable.table4.map((item) => {
                                                return { "label": item.Name, "value": item.ID }
                                            })
                                            this.setState({
                                                nomineeRelationshipList: nomineeRelationshipDropdown,
                                                nomineeRelationshipListCopy: nomineeRelationshipDropdown,
                                            }, () => {
                                                var relationListDropdownData = this.state.nomineeRelationshipListCopy.filter((item) => !relation.includes(item.label))
                                                this.setState({
                                                    nomineeRelationshipList: relationListDropdownData // In Edit click Nominee Dropdown List showing
                                                })
                                            })
                                        }


                                        this.setState({
                                            nomineePercentage: 100 - Number(this.state.nomineePercentageCalculate) // Nominee Percentage textbox shows in Edit click
                                        })
                                        // Nominee List length less than three and percentage less than 100
                                        if (this.state.nomineeList.length < 3 && this.state.nomineePercentageCalculate < 100) {
                                            this.setState({
                                                nomineeButtonVisible: true,// Add nominee button
                                            })
                                        }

                                        // Nominee List length greater than or equal three OR Percentage greater than or equal 100
                                        if (this.state.nomineeList.length >= 3 || this.state.nomineePercentageCalculate >= 100) {

                                            if (this.state.nomineeList.length >= 3) {
                                                this.setState({
                                                    nomineeButtonVisible: false,
                                                })
                                            }

                                            if (this.state.nomineePercentageCalculate == 100) {
                                                this.setState({
                                                    nomineeButtonVisible: false,
                                                })
                                            }
                                        }

                                    })
                                }
                            }
                            //table6 - Beneficiary Details
                            if (this.commonFunction(resp.table6) && resp.table6 != "NA") {
                                var beneficiaryListData = resp.table6.map((item) => {
                                    return {
                                        "beneficiaryName": { name: item.BeneficiaryName, subHeader1: "", subHeader2: "", type: '' },
                                        "beneficiaryRelationship": { name: item.Relationship, subHeader1: "", subHeader2: "", type: '' },
                                        "beneficiaryDateOfBirth": { name: moment(item.DOB).format("DD MMM YYYY"), subHeader1: "", subHeader2: "", type: '' },
                                        "beneficiaryGuardian": { name: item.GuardianName == "" ? "NA" : item.GuardianName, subHeader1: "", subHeader2: "", type: '' },
                                        "beneficiaryPercentage": { name: item.BeneficiaryPercentage, subHeader1: "", subHeader2: "", type: 'number' },
                                        "id": { name: item.ID, subHeader1: '', subHeader2: "", type: '' },
                                        "beneficiaryRelationshipID": { name: item.RelationshipID, subHeader1: "", subHeader2: "", type: '' },
                                        "beneficiaryType": { name: item.GuardianName == "" ? 1 : 2, subHeader1: "", subHeader2: "", type: '' }, // Major-1, Minor-2
                                    }
                                })

                                if (beneficiaryListData.length > 0) {
                                    var percentage = beneficiaryListData.map((item) => {
                                        return item.beneficiaryPercentage.name
                                    })
                                    this.setState({
                                        beneficiaryList: beneficiaryListData,
                                        beneficiaryPercentageCalculate: percentage.reduce((a, b) => {
                                            return Number(a) + Number(b)
                                        })
                                    }, () => {
                                        var relation = beneficiaryListData.map((item) => {
                                            return item.beneficiaryRelationship.name
                                        })

                                        // Beneficiary Relationship Dropdown List
                                        if (this.commonFunction(this.props.insuranceReduxTable.table4)) {
                                            var beneficiaryRelationshipDropdown = this.props.insuranceReduxTable.table4.map((item) => {
                                                return { "label": item.Name, "value": item.ID }
                                            })
                                            this.setState({
                                                beneficiaryRelationshipList: beneficiaryRelationshipDropdown,
                                                beneficiaryRelationshipListCopy: beneficiaryRelationshipDropdown,
                                            }, () => {
                                                var relationListDropdownData = this.state.beneficiaryRelationshipListCopy.filter((item) => !relation.includes(item.label))
                                                this.setState({
                                                    beneficiaryRelationshipList: relationListDropdownData // In Edit click Beneficiary Dropdown List showing
                                                })
                                            })
                                        }

                                        this.setState({
                                            beneficiaryPercentage: 100 - Number(this.state.beneficiaryPercentageCalculate) // Beneficiary Percentage textbox shows in Edit click
                                        })
                                        // Beneficiary List length less than three and percentage less than 100
                                        if (this.state.beneficiaryList.length < 3 && this.state.beneficiaryPercentageCalculate < 100) {
                                            this.setState({
                                                beneficiaryButtonVisible: true,// Add beneficiary button
                                            })
                                        }

                                        // beneficiary List length greater than or equal three OR Percentage greater than or equal 100
                                        if (this.state.beneficiaryList.length >= 3 || this.state.beneficiaryPercentageCalculate >= 100) {

                                            if (this.state.beneficiaryList.length >= 3) {
                                                this.setState({
                                                    beneficiaryButtonVisible: false,
                                                })
                                            }

                                            if (this.state.beneficiaryPercentageCalculate == 100) {
                                                this.setState({
                                                    beneficiaryButtonVisible: false,
                                                })
                                            }
                                        }

                                    })
                                }
                            }
                            // table7 - ULIP List
                            if (this.commonFunction(resp.table7) && resp.table7 != "NA") {
                                var uLIPListData = resp.table7.map((item) => {
                                    return {
                                        "schemeName": { name: item.SchemeName, subHeader1: "", subHeader2: "", type: '' },
                                        "asOnDate": { name: moment(item.OnDate).format("DD MMM YYYY"), subHeader1: "", subHeader2: "", type: '' },
                                        "units": { name: item.Units == "" ? 0 : item.Units, subHeader1: "", subHeader2: "", type: '' },
                                        "latestNAV": { name: item.LatestNAV == "" ? 0 : item.LatestNAV, subHeader1: "", subHeader2: "", type: '' },
                                        "latestValue": { name: item.LatestValue, subHeader1: "", subHeader2: "", type: '' },
                                        "id": { name: item.ID, subHeader1: '', subHeader2: "", type: '' },
                                        "schemeNameID": { name: item.SchemeNameID, subHeader1: "", subHeader2: "", type: '' },
                                    }
                                })

                                this.setState({
                                    uLIPList: uLIPListData
                                })
                            }
                        }
                        // HEALTH AND  GENERAL FLAG - EDIT
                        else {
                            if (this.commonFunction(resp.table1) && resp.table1 != "NA") {
                                this.setState({
                                    insuredType: [],
                                    insuredTypeGeneral: [],
                                    policyName: resp.table1[0].InsurancePolicyName,
                                    policy: resp.table1[0].PolicyID,
                                    policyNo: resp.table1[0].PolicyNumber,
                                    policyTypeName: resp.table1[0].PolicyType,
                                    policyType: resp.table1[0].PolicyTypeID,
                                    nickName: typeof (resp.table1[0].NickName) == "object" ? "NA" : resp.table1[0].NickName,
                                    startDate: resp.table1[0].StartDate,
                                    coverAmount: resp.table1[0].CoverAmount,
                                    premiumAmount: resp.table1[0].PremiumAmount,
                                    policyTerm: resp.table1[0].PolicyTerm,
                                    bankAccount: resp.table1[0].BankName == "" || typeof (resp.table1[0].BankName) == "object" || resp.table1[0].BankName == undefined || resp.table1[0].BankName == null ? "" : resp.table1[0].BankName,
                                    bank: resp.table1[0].BankAccountID,
                                    flag: this.props.location.state.data,
                                }, () => {
                                    // table2
                                    if (this.commonFunction(resp.table2) && resp.table2 != "NA") {
                                        if (this.state.flag == "Health") {
                                            this.setState({
                                                riderList: resp.table2.map((item) => {
                                                    return {
                                                        riderName: {
                                                            name: item.RiderName,
                                                            errormessage: "",
                                                            isClicked: false,
                                                            placeholder: "Select Rider Name",
                                                            style: "dropdown",
                                                            list: riderNameList,
                                                            label: ""
                                                        },
                                                        riderFor: {
                                                            name: item.RiderFor,
                                                            errormessage: "",
                                                            isClicked: true,
                                                            placeholder: "Select Rider For",
                                                            style: "dropdown",
                                                            list: this.props.riderForDropdownList,
                                                            enableDeleteIcon: false
                                                        },
                                                        riderSum: {
                                                            name: item.RiderSumAssured,
                                                            errormessage: "",
                                                            isClicked: false,
                                                            placeholder: item.RiderSumAssured,
                                                            style: "amount",
                                                            label: "",
                                                            enableDeleteIcon: true
                                                        }
                                                    }
                                                }),
                                                riderValue: resp.table2.map((item) => {
                                                    return {
                                                        riderName: item.RiderNameId,
                                                        riderFor: item.RiderForID,
                                                        riderSum: item.RiderSumAssured,
                                                    }
                                                })
                                            })
                                        }
                                        else {
                                            this.setState({
                                                riderListGeneral: resp.table2.map((item) => {
                                                    return {
                                                        riderName: {
                                                            name: item.RiderName,
                                                            errormessage: "",
                                                            isClicked: false,
                                                            placeholder: "Select Rider Name",
                                                            style: "dropdown",
                                                            list: riderNameList,
                                                            label: ""
                                                        },
                                                        riderSum: {
                                                            name: item.RiderSumAssured,
                                                            errormessage: "",
                                                            isClicked: false,
                                                            placeholder: item.RiderSumAssured,
                                                            style: "amount",
                                                            label: "",
                                                            enableDeleteIcon: true
                                                        },
                                                    }
                                                }),
                                                riderValueGeneral: resp.table2.map((item) => {
                                                    return {
                                                        riderName: item.RiderNameId,
                                                        riderSum: item.RiderSumAssured,
                                                    }
                                                })
                                            })
                                        }
                                    }
                                    else {
                                        if (this.state.flag == 'Health') {
                                            this.setState({
                                                riderValue: [{
                                                    riderName: "",
                                                    riderFor: "",
                                                    riderSum: "",
                                                }]
                                            })
                                        }
                                        else {
                                            this.setState({
                                                riderValueGeneral: [{
                                                    riderName: "",
                                                    riderSum: "",
                                                }]
                                            })
                                        }
                                    }
                                    // table4
                                    if (this.commonFunction(resp.table4) && resp.table4 != "NA") {
                                        if (this.state.flag == "Health") {
                                            if (this.commonFunction(this.props.insuranceReduxTable.table6)) {
                                                var filterInsuredType = this.props.insuranceReduxTable.table6.filter((item) => (item.Type == 1))
                                                var insuredTypeDropdown = filterInsuredType.map((item) => {
                                                    item = { ...item, visible: false }
                                                    resp.table4.map((i) => {
                                                        if (i.InsuredFor == item.ID) {
                                                            item = { ...item, visible: true }
                                                        }
                                                    })
                                                    return item;
                                                })

                                                this.setState({
                                                    insuredType: insuredTypeDropdown,
                                                })
                                            }
                                        }
                                        else {
                                            var InsuredForID = resp.table4.map((item) => {
                                                return {
                                                    InsuredFor: item.InsuredFor
                                                }
                                            })

                                            if (this.commonFunction(this.props.insuranceReduxTable.table6)) {
                                                var filterInsuredTypeGeneral = this.props.insuranceReduxTable.table6.filter((item) => (item.Type == 2))

                                                var insuredTypeGenralDropdown = filterInsuredTypeGeneral.map((item) => {
                                                    if (item.ID == InsuredForID[0].InsuredFor) {
                                                        return { ...item, visible: true }
                                                    }
                                                    else {
                                                        return { ...item, visible: false }
                                                    }
                                                })
                                                this.setState({
                                                    insuredTypeGeneral: insuredTypeGenralDropdown
                                                })
                                            }
                                        }
                                    }
                                    // table3
                                    if (this.commonFunction(resp.table3) && resp.table3 != "NA") {
                                        var nomineeListData = resp.table3.map((item) => {
                                            return {
                                                "nomineeName": { name: item.NomineeName, subHeader1: "", subHeader2: "", type: '' },
                                                "nomineeRelationship": { name: item.RelationShip, subHeader1: "", subHeader2: "", type: '' },
                                                "dateOfBirth": { name: item.DOB, subHeader1: "", subHeader2: "", type: '' },
                                                "guardian": { name: typeof (item.NomineeGuardianName) == 'object' || item.NomineeGuardianName == "" ? "NA" : item.NomineeGuardianName, subHeader1: "", subHeader2: "", type: '' },
                                                "nomineePercentage": { name: item.Percentage, subHeader1: "", subHeader2: "", type: 'number' },
                                                "id": { name: item.ID, subHeader1: '', subHeader2: "", type: '' },
                                                "nomineeRelationshipID": { name: item.NomineeRelationshipID, subHeader1: "", subHeader2: "", type: '' },
                                                "nomineeType": { name: item.NomineeTypeID, subHeader1: "", subHeader2: "", type: '' }, // Major-1, Minor-2
                                            }
                                        })

                                        if (nomineeListData.length > 0) {
                                            var percentage = nomineeListData.map((item) => {
                                                return item.nomineePercentage.name
                                            })
                                            this.setState({
                                                nomineeList: nomineeListData,
                                                nomineePercentageCalculate: percentage.reduce((a, b) => {
                                                    return Number(a) + Number(b)
                                                })
                                            }, () => {

                                                var relation = nomineeListData.map((item) => {
                                                    return item.nomineeRelationship.name
                                                })

                                                // Nominee Relationship Dropdown List
                                                if (this.commonFunction(this.props.insuranceReduxTable.table4)) {
                                                    var nomineeRelationshipDropdown = this.props.insuranceReduxTable.table4.map((item) => {
                                                        return { "label": item.Name, "value": item.ID }
                                                    })
                                                    this.setState({
                                                        nomineeRelationshipList: nomineeRelationshipDropdown,
                                                        nomineeRelationshipListCopy: nomineeRelationshipDropdown,
                                                    }, () => {
                                                        var relationListDropdownData = this.state.nomineeRelationshipListCopy.filter((item) => !relation.includes(item.label))
                                                        this.setState({
                                                            nomineeRelationshipList: relationListDropdownData // In Edit click Nominee Dropdown List showing
                                                        })
                                                    })
                                                }


                                                this.setState({
                                                    nomineePercentage: 100 - Number(this.state.nomineePercentageCalculate) // Nominee Percentage textbox shows in Edit click
                                                })
                                                // Nominee List length less than three and percentage less than 100
                                                if (this.state.nomineeList.length < 3 && this.state.nomineePercentageCalculate < 100) {
                                                    this.setState({
                                                        nomineeButtonVisible: true,// Add nominee button
                                                    })
                                                }

                                                // Nominee List length greater than or equal three OR Percentage greater than or equal 100
                                                if (this.state.nomineeList.length >= 3 || this.state.nomineePercentageCalculate >= 100) {

                                                    if (this.state.nomineeList.length >= 3) {
                                                        this.setState({
                                                            nomineeButtonVisible: false,
                                                        })
                                                    }

                                                    if (this.state.nomineePercentageCalculate == 100) {
                                                        this.setState({
                                                            nomineeButtonVisible: false,
                                                        })
                                                    }
                                                }

                                            })

                                        }
                                    }
                                })
                            }

                        }
                    }
                })

                this.setState({
                    flag: this.props.location.state.data,
                    EditID: this.props.location.state.id // EditID
                })
            }
        }

    }

    insureTypeDropdown = () => {
        if (this.commonFunction(this.props.insuranceReduxTable.table6)) {
            var filterInsuredType = this.props.insuranceReduxTable.table6.filter((item) => (item.Type == 1))
            var filterInsuredTypeGeneral = this.props.insuranceReduxTable.table6.filter((item) => (item.Type == 2))

            var insuredTypeDropdown = filterInsuredType.map((item) => {
                return { ...item, visible: false }
            })
            var insuredTypeGenralDropdown = filterInsuredTypeGeneral.map((item) => {
                return { ...item, visible: false }
            })
            this.setState({
                insuredType: insuredTypeDropdown,
                insuredTypeGeneral: insuredTypeGenralDropdown
            })
        }
    }

    riderListValue = () => {
        // Rider List - RiderName Dropdown value set this riderList state
        this.setState({
            riderList: [{
                riderName: {
                    name: "",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "Select Rider Name",
                    style: "dropdown",
                    list: riderNameList, // This List value only added from based on Policy Name selected api call
                    label: ""
                },
                riderFor: {
                    name: "",
                    errormessage: "",
                    isClicked: true,
                    placeholder: "Select Rider For",
                    style: "dropdown",
                    list: this.props.riderForDropdownList,
                    enableDeleteIcon: false
                },
                riderSum: {
                    name: "",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "0",
                    style: "amount",
                    label: "",
                    enableDeleteIcon: false
                },
            }],

            riderListGeneral: [{
                riderName: {
                    name: "",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "Select Rider Name",
                    style: "dropdown",
                    list: riderNameList, // This List value only added from based on Policy Name selected api call
                    label: ""
                },
                riderSum: {
                    name: "",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "0",
                    style: "amount",
                    label: "",
                    enableDeleteIcon: false
                },
            }],

            riderListLife: [{
                riderName: {
                    name: "",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "Select Rider Name",
                    style: "dropdown",
                    list: riderNameList,
                    label: ""
                },
                riderTerm: {
                    name: "",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "0",
                    style: "amount",
                    label: "",
                    enableDeleteIcon: false
                },
                riderSum: {
                    name: "",
                    errormessage: "",
                    isClicked: false,
                    placeholder: "0",
                    style: "amount",
                    label: "",
                    enableDeleteIcon: false
                },
            }],
        })
    }

    commonFunction = (val) => {
        if (val != undefined && val != null && val != "") return true;
        return false;
    }

    // Toggle Button Click
    // toggleChange = (val) => {
    //     this.setState({
    //         flag: val
    //     })

    //     // this.setState({ selected: !this.state.selected, flag: this.state.selected ? "Health" : "General" })
    //     // While change the toggle button all Textbox , Dropdowns and List are to do empty
    //     this.empty();
    //     // Insured Type and Insured Type General Button List
    //     this.insureTypeDropdown();
    // }

    insuredTypeClick = (Id) => {
        // Selected Tik button
        if (this.state.flag == "Health") {
            var data = this.state.insuredType.map((i) => {
                if (Id == i.ID) {
                    this.setState({
                        insuredTypeError: ""
                    })
                    return { ...i, visible: true }
                }
                else {
                    return i
                }
            })
            this.setState({
                insuredType: data
            })
        } else {
            var datasGeneral = this.state.insuredTypeGeneral.map((i) => {
                if (Id == i.ID) {
                    this.setState({
                        insuredTypeError: ""
                    })
                    return { ...i, visible: true }
                }
                else {
                    return { ...i, visible: false }
                }
            })
            this.setState({
                insuredTypeGeneral: datasGeneral
            })
        }
    }

    insuredTypeClickTik = (Id) => {
        // Selected Tik button remove and unselect
        if (this.state.flag == "Health") {
            var datas = this.state.insuredType.map((i) => {
                if (Id == i.ID) {
                    return { ...i, visible: false }
                }
                else {
                    return i
                }
            })
            this.setState({
                insuredType: datas
            })
        }
        else {
            var dataGeneral = this.state.insuredTypeGeneral.map((i) => {
                if (Id == i.ID) {
                    return { ...i, visible: false }
                }
                else {
                    return { ...i, visible: false }
                }
            })
            this.setState({
                insuredTypeGeneral: dataGeneral
            })
        }
    }

    // Policy Name
    handlePolicyNameChange = (e) => {
        this.setState({ policy: e[0].value, policyName: e[0].label, policyNameError: "" }, () => {
            var data = {
                "ID": this.state.policy,
                "clientID": this.props.clientID
            }
            GetRiderNameDropdown(data).then((resp) => {
                if (resp.status == "S") {
                    if (this.commonFunction(resp.table1)) {
                        var riderNameData = [{ Name: "Select", ID: "", }, ...resp.table1]

                        riderNameList = riderNameData.map((item) => {
                            return { "label": item.Name, "value": item.ID }
                        })
                        this.riderListValue();
                    }
                    else {
                        riderNameList = [];
                        this.riderListValue();
                    }
                }
                else {
                    riderNameList = [];
                    this.riderListValue();
                }

            })

        })
    }

    // Policy No
    handlePolicyNoChange = (e) => {
        if (alphaNumericOnly(e.target.value)) {
            this.setState({ policyNo: e.target.value, policyNoError: "" })
        }
    }

    // Policy Type
    handlePolicyTypeChange = (e) => {
        this.setState({ policyType: e[0].value, policyTypeName: e[0].label, policyTypeError: "" })
    }

    // Policy For
    handlePolicyForChange = (e) => {
        this.setState({ policyFor: e[0].value, policyForLabel: e[0].label, policyForError: "" })
    }


    // Nick Name
    handleNickNameChange = (e) => {
        let value = e.target.value;
        if (alphabetSpaceOnly(value)) {
            this.setState({ nickName: value, nickNameError: "" })
        }
        else {
            e.preventDefault();
        }
    }

    // Name
    handleNameChange = (e) => {
        let value = e.target.value;
        if (alphabetSpaceOnly(value)) {
            this.setState({ name: value, nameError: "" })
        }
        else {
            e.preventDefault();
        }
    }

    // Gender
    handleGenderChange = (e) => {
        this.setState({ gender: e[0].value, genderLabel: e[0].label, genderError: "" })
    }

    ///*** Date of Birth
    //---start---
    handleInformationDateOfBirthChange = (value) => {
        this.setState({ informationDateOfBirthTemp: moment(value).format("DD MMM YYYY") })
    }
    handleInformationDateOfBirthApply = (date) => {
        this.setState({ informationDateOfBirth: moment(date).format("DD MMM YYYY"), informationDateOfBirthError: '' })
    }
    handleInformationDateOfBirthClose = () => {
        this.setState({ informationAnchorBirthDateEl: null })
    }
    handleInformationDateOfBirthClick = (e) => {
        this.setState({ informationAnchorBirthDateEl: e.currentTarget })
    }
    ///---end---

    ///*** Start Date
    //---start---
    handleStartDateChange = (value) => {
        this.setState({ startDateTemp: moment(value).format("DD MMM YYYY") })
    }
    handleStartDateApply = (date) => {
        this.setState({ startDate: moment(date).format("DD MMM YYYY"), startDateError: '' })
    }
    handleStartDateClose = () => {
        this.setState({ anchorEl: null })
    }
    handleStartDateClick = (e) => {
        this.setState({ anchorEl: e.currentTarget })
    }
    ///---end---

    // Premium Frequency
    handlePremiumFrequencyChange = (e) => {
        this.setState({ premiumFrequency: e[0].value, premiumFrequencyLabel: e[0].label, premiumFrequencyError: "" })
    }

    ///*** Cover Amount
    //---start---
    handleCoverAmountChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnlyExtraLength(removeComma)) {
            this.setState({ coverAmount: removeComma, coverAmountError: "" })
        }
        else {
            e.preventDefault();
        }
    }
    ///---end---

    ///*** Premium Amount
    //---start---
    handlePremiumAmountChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnlyExtraLength(removeComma)) {
            this.setState({ premiumAmount: removeComma, premiumAmountError: "" })
        }
        else {
            e.preventDefault();
        }
    }
    ///---end---

    // Policy Term
    handlePolicyTermChange = (e) => {
        if (numbersandDeciamlOnlyExtraLength(e.target.value)) {
            this.setState({ policyTerm: e.target.value, policyTermError: "" })
        }
    }

    // Premium Paying Term
    handlePremiumPayingTermChange = (e) => {
        if (numbersandDeciamlOnlyExtraLength(e.target.value)) {
            this.setState({ premiumPayingTerm: e.target.value, premiumPayingTermError: "" })
        }
    }

    // Bank Account
    handleBankChange = (e) => {
        this.setState({ bank: e[0].value, bankAccount: e[0].label, bankAccountError: "" })
    }

    // Add Bank Popup Start \\
    // Bank Popup
    handleAddClick = () => {
        this.setState({
            openPopup: true,
            bankNamePopupError: "",
            accountNumberError: "",
            ifscCodeError: "",
            bankNameValue: "",
            accountNumber: "",
            ifscCode: "",
        })
    }

    handleCancel = () => {
        this.setState({ openPopup: false })
    }

    addBank = () => {
        // alert("bank")
        var bankNameValid = false;
        var accountNumberValid = false;
        var ifscCodeValid = false;

        // Bank Name
        if (this.commonFunction(this.state.bankNameValue)) {
            bankNameValid = true;
            this.setState({
                bankNamePopupError: ""
            })
        }
        else {
            bankNameValid = false;
            this.setState({
                bankNamePopupError: labels.Insurance.required
            })
        }

        // Account Number
        if (this.commonFunction(this.state.accountNumber)) {
            if (Number(this.state.accountNumber) > 0) {
                accountNumberValid = true;
                this.setState({
                    accountNumberError: ""
                })
            }
            else {
                accountNumberValid = false;
                this.setState({
                    accountNumberError: "Please enter valid account number"
                })
            }

        }
        else {
            accountNumberValid = false;
            this.setState({
                accountNumberError: labels.Insurance.required
            })
        }

        // IFSC Code
        if (this.commonFunction(this.state.ifscCode)) {
            if (IFSCvalidation(this.state.ifscCode)) {
                ifscCodeValid = true;
                this.setState({
                    ifscCodeError: ""
                })
            }
            else {
                ifscCodeValid = false
                this.setState({ ifscCodeError: labels.messages.Ifscerror })
            }

        }
        else {
            ifscCodeValid = true;
            this.setState({
                ifscCodeError: labels.Insurance.required
            })
        }

        var isValid = false;
        if (bankNameValid && accountNumberValid && ifscCodeValid) {
            isValid = true;
        }
        else {
            isValid = false;
        }

        if (isValid) {
            this.setState({ popupLoader: true })
            var data = {
                clientAccountID: this.props.clientID,
                accountType: 1,
                ifscCode: this.state.ifscCode,
                bankAccountNumber: this.state.accountNumber,
                accountHolderTypeID: 1,
                isDefault: false,
                bankID: this.state.bankName,
                createdBy: this.props.clientID
            }


            getInsuranceAddbank(data).then((resp) => {
                if (resp.status == "S") {
                    this.setState({
                        popupLoader: false,
                        message: resp.message,
                        openSnackBar: true,
                        severity: "success",
                        openPopup: false, // Popup close
                        bank: resp.table1[0].ID, // Id
                        bankAccount: this.state.bankNameValue + " (" + (this.state.accountNumber) + ")"// label
                    })
                    this.cancel();
                    //  This Function call for Policy Name and Rider Name Dropdown Bank Account List Dropdown
                    this.npsDropdown();
                }
                else {
                    this.setState({
                        popupLoader: false,
                        message: resp.message,
                        openSnackBar: true,
                        severity: "warning",
                        openPopup: false, // Popup close
                    })
                    this.cancel();
                }
            })

        }
    }

    // Snackbar
    handleToastClose = () => {
        this.setState({
            openSnackBar: false
        })
    }

    cancel = () => {
        this.setState({
            bankNameValue: "",
            bankNamePopupError: "",
            accountNumber: "",
            accountNumberError: "",
            ifscCode: "",
            ifscCodeError: ""
        })
    }

    // Bank Name
    handleBankNameChange = (e) => {
        this.setState({ bankName: e[0].value, bankNameValue: e[0].label, bankNamePopupError: "" })
    }

    // Account Number
    handleAccountNumberChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (accountNumberOnly(removeComma)) {
            this.setState({ accountNumber: removeComma, accountNumberError: "" })
        }
        else {
            e.preventDefault();
        }
    }

    // IFSC Code
    handleIFSCCodeChange = (e) => {
        if (notAllowdSpecialCharater(e.target.value)) {
            this.setState({ ifscCode: e.target.value, ifscCodeError: "" })
        }
    }
    // Add Bank Popup End \\

    // Rider(Rider Health, Rider General and Rider Life)
    callBackTransaction = (data, FullList) => {
        if (this.state.flag == "Health") {
            this.setState({ riderValue: data, riderList: FullList, ridersListError: "" })
        }
        else if (this.state.flag == "General") {
            this.setState({ riderValueGeneral: data, riderListGeneral: FullList, ridersListError: "" })
        }
        else {
            this.setState({ riderValueLife: data, riderListLife: FullList, ridersListError: "" })
        }
    }

    // Nominee Name
    handleNomineeNameChange = (e) => {
        let value = e.target.value;
        if (alphabetSpaceOnly(value)) {
            this.setState({ nomineeName: value, nomineeNameError: "" })
        }
        else {
            e.preventDefault();
        }
    }

    // Nominee Relationship
    handleNomineeRelationshipChange = (e) => {
        this.setState({ nomineeRelationship: e[0].value, nomineeRelationshipValue: e[0].label, nomineeRelationshipError: "" })
    }

    ///*** Date of Birth
    //---start---
    handleDateOfBirthChange = (value) => {
        this.setState({ dateOfBirthTemp: moment(value).format("DD MMM YYYY") })
    }
    handleDateOfBirthApply = (date) => {
        this.setState({ dateOfBirth: moment(date).format("DD MMM YYYY"), dateOfBirthError: '' }, () => {
            if (this.commonFunction(date)) {
                var Age = moment(new Date(), "DD.MM.YYYY").diff(date, 'years');
                // While select dateofbirth greater than or equal 18 yrs, to enable the guardian Textbox
                if (Age < 18) {
                    this.setState({
                        nomineeGuardianVisible: true,
                        guardianError: "", // For save / save & more btn click
                    })
                }
                else {
                    this.setState({
                        nomineeGuardianVisible: false,
                        guardianError: "",
                        guardian: "",
                    })
                }
            }
        })
    }
    handleDateOfBirthClose = () => {
        this.setState({ anchorBirthDateEl: null })
    }
    handleDateOfBirthClick = (e) => {
        this.setState({ anchorBirthDateEl: e.currentTarget })
    }
    ///---end---

    // Guardian
    handleGuardianChange = (e) => {
        let value = e.target.value;
        if (alphabetSpaceOnly(value)) {
            this.setState({ guardian: value, guardianError: "" })
        }
        else {
            e.preventDefault();
        }
    }

    // Nominee Percentage
    handleNomineePercentageChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnlyExtraLength(removeComma)) {
            this.setState({ nomineePercentage: removeComma, nomineePercentageError: "" })
        }
        else {
            e.preventDefault();
        }
    }

    // TRUSTEE DETAILS
    // Trustee Name
    handleTrusteeNameChange = (e) => {
        let value = e.target.value;
        if (alphabetSpaceOnly(value)) {
            this.setState({ trusteeName: value, trusteeNameError: "" })
        }
        else {
            e.preventDefault();
        }
    }

    // Trustee Relationship
    handleTrusteeRelationshipChange = (e) => {
        this.setState({ trusteeRelationship: e[0].value, trusteeRelationshipValue: e[0].label, trusteeRelationshipError: "" })
    }

    // Trustee DATE OF BIRTH
    //---start---
    handleTrusteeDateOfBirthChange = (value) => {
        this.setState({ trusteeDateOfBirthTemp: moment(value).format("DD MMM YYYY") })
    }
    handleTrusteeDateOfBirthApply = (date) => {
        this.setState({ trusteeDateOfBirth: moment(date).format("DD MMM YYYY"), trusteDateOfBirthError: '' })
    }
    handleTrusteeDateOfBirthClose = () => {
        this.setState({ anchorTrusteeBirthDateEl: null })
    }
    handleTrusteeDateOfBirthClick = (e) => {
        this.setState({ anchorTrusteeBirthDateEl: e.currentTarget })
    }
    ///---end---

    // BENEFICIARY DETAILS
    // Beneficiary Name
    handleBeneficiaryNameChange = (e) => {
        let value = e.target.value;
        if (alphabetSpaceOnly(value)) {
            this.setState({ beneficiaryName: value, beneficiaryNameError: "" })
        }
        else {
            e.preventDefault();
        }
    }

    // Beneficiary  Relationship
    handleBeneficiaryRelationshipChange = (e) => {
        this.setState({ beneficiaryRelationship: e[0].value, beneficiaryRelationshipValue: e[0].label, beneficiaryRelationshipError: "" })
    }

    ///*** Beneficiary Date of Birth
    //---start---
    handleBeneficiaryDateOfBirthChange = (value) => {
        this.setState({ beneficiaryDateOfBirthTemp: moment(value).format("DD MMM YYYY") })
    }
    handleBeneficiaryDateOfBirthApply = (date) => {
        this.setState({ beneficiaryDateOfBirth: moment(date).format("DD MMM YYYY"), beneficiaryDateOfBirthError: '' }, () => {
            if (this.commonFunction(date)) {
                var Age = moment(new Date(), "DD.MM.YYYY").diff(date, 'years');
                // While select dateofbirth greater than or equal 18 yrs, to enable the guardian Textbox
                if (Age < 18) {
                    this.setState({
                        beneficiaryGuardianVisible: true,
                        beneficiaryGuardianError: "", // For save / save & more btn click
                    })
                }
                else {
                    this.setState({
                        beneficiaryGuardianVisible: false,
                        beneficiaryGuardianError: "",
                        beneficiaryGuardian: "",
                    })
                }
            }
        })
    }
    handleBeneficiaryDateOfBirthClose = () => {
        this.setState({ anchorBeneficiaryBirthDateEl: null })
    }
    handleBeneficiaryDateOfBirthClick = (e) => {
        this.setState({ anchorBeneficiaryBirthDateEl: e.currentTarget })
    }
    ///---end---

    // Beneficiary Guardian
    handleBeneficiaryGuardianChange = (e) => {
        let value = e.target.value;
        if (alphabetSpaceOnly(value)) {
            this.setState({ beneficiaryGuardian: value, beneficiaryGuardianError: "" })
        }
        else {
            e.preventDefault();
        }
    }

    // Beneficiary Percentage
    handleBeneficiaryPercentageChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnlyExtraLength(removeComma)) {
            this.setState({ beneficiaryPercentage: removeComma, beneficiaryPercentageError: "" })
        }
        else {
            e.preventDefault();
        }
    }


    // ULIP DETAILS
    ///*** As On Date
    //---start---
    handleAsOnDateChange = (value) => {
        this.setState({ asOnDateTemp: moment(value).format("DD MMM YYYY") })
    }
    handleAsOnDateApply = (date) => {
        this.setState({ asOnDate: moment(date).format("DD MMM YYYY"), asOnDateError: '' })
    }
    handleAsOnDateClose = () => {
        this.setState({ anchorAsOnDateEl: null })
    }
    handleAsOnDateClick = (e) => {
        this.setState({ anchorAsOnDateEl: e.currentTarget })
    }
    ///---end---

    // Scheme Name
    handleSchemeNameChange = (e) => {
        this.setState({ schemeName: e[0].value, schemeNamelabel: e[0].label, schemeNameError: "" })
    }

    // Units
    handleUnitsChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnlyExtraLength(removeComma)) {
            this.setState({ units: removeComma, unitsError: "" })
        }
        else {
            e.preventDefault();
        }
    }

    // Latest NAV
    handleLatestNAVChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnlyExtraLength(removeComma)) {
            this.setState({ latestNAV: removeComma, latestNAVError: "" })
        }
        else {
            e.preventDefault();
        }
    }

    // Latest Value
    handlelatestValueChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")
        if (numbersandDeciamlOnlyExtraLength(removeComma)) {
            this.setState({ latestValue: removeComma, latestValueError: "" })
        }
        else {
            e.preventDefault();
        }
    }

    // Delete Nominee
    deleteNominee = (List) => {
        this.setState({
            openDeletePopup: true, // Delete Popup open
            deleteID: List.id.name
        })
    }

    // Nominee Delete Functionality (Popup Yes button Click)
    handleYesClick = () => {
        var data = this.state.nomineeList.filter((item, ind) => (this.state.deleteID != item.id.name))

        if (this.commonFunction(data) && data.length > 0) {
            var percentageData = data.map(i => {
                return i.nomineePercentage.name
            })
            if (percentageData.length > 0)
                var Percentage = percentageData.reduce((a, b) => {
                    return Number(a) + Number(b)
                })
            this.setState({
                nomineePercentageCalculate: Percentage,
                nomineePercentage: (100 - Percentage)
            })
            // If I select the Edit click, and I delete the same List . So I change the nomineeID and updateFlag
            this.setState({
                nomineeList: data,
                nomineeID: "", // To do empty the used for Add / Update functionality nomineeID
                updateFlag: "add",
                openDeletePopup: false,
                nomineePercentageError: "", // To do empty the nominee Percentage error msg,
                nomineeButtonVisible: true, // Nominee Parts will show
            }, () => {
                // Nominee Relationship List handling (Selected nominee Relationship don't come to Nominee Relationship dropdown)
                var relation = this.state.nomineeList.map((item) => {
                    return item.nomineeRelationship.name
                })

                if (this.commonFunction(this.state.nomineeRelationshipListCopy)) {
                    var relationListDropdown = this.state.nomineeRelationshipListCopy.filter((item) => !relation.includes(item.label))

                    this.setState({
                        nomineeRelationshipList: relationListDropdown
                    })
                }
            })
        }
        else {
            this.setState({
                nomineeList: data,
                openDeletePopup: false,
                nomineeID: "", // To do empty the used for Add / Update functionality nomineeID
                updateFlag: "add",
                nomineePercentageCalculate: 0, // Nominee List length is zero, So nomineePercentageCalculate is zeo
                nomineePercentage: 100,
                nomineeButtonVisible: true, // Nominee Parts will show
                nomineeRelationshipList: this.state.nomineeRelationshipListCopy // To Fill up the nomineeRelationshipList
            })
        }
    }

    // Popup close
    handlePopupClose = () => {
        this.setState({ openDeletePopup: false })
    }

    handlePopupCloseBeneficiary = () => {
        this.setState({ openDeletePopupBeneficiary: false })
    }

    handlePopupCloseTrustee = () => {
        this.setState({ openDeletePopupTrustee: false })
    }

    handlePopupCloseULIP = () => {
        this.setState({ openDeletePopupULIP: false })
    }

    // Edit Nominee
    editNominee = (List) => {
        var filterData = this.state.nomineeList.filter((item, ind) => (List.id.name == item.id.name))

        var mapData = filterData.map((item) => {
            return ({
                NomineeName: item.nomineeName.name,
                Relationship: item.nomineeRelationship.name,
                DOB: item.dateOfBirth.name,
                Guardian: item.guardian.name,
                Percentage: item.nomineePercentage.name,
                ID: item.id.name,
                relationshipID: item.nomineeRelationshipID.name,
            })
        })

        this.setState({
            nomineeName: mapData[0].NomineeName,
            nomineeRelationshipValue: mapData[0].Relationship,
            dateOfBirth: mapData[0].DOB,
            guardian: mapData[0].Guardian == "NA" ? "" : mapData[0].Guardian,
            nomineePercentage: mapData[0].Percentage,
            nomineeID: mapData[0].ID,
            nomineeRelationship: mapData[0].relationshipID,
            updateFlag: "Update",
            nomineeGuardianVisible: mapData[0].Guardian == "" || mapData[0].Guardian == "NA" ? false : true,
            nomineeButtonVisible: true, // To enable the AddNominee Button
        }, () => {
            this.nomineeEmpty();
        })

    }

    // Add Riders Row
    addRidersRow = () => {
        let isValid = true;
        if (this.state.flag == "Health") {
            isValid = this.state.riderValue.some(hasNull) === true ? false : true

        }
        else if (this.state.flag == "General") {
            isValid = this.state.riderValueGeneral.some(hasNull) === true ? false : true
        }
        else {
            isValid = this.state.riderValueLife.some(hasNull) === true ? false : true
        }

        if (isValid) {
            if (this.state.flag == "Health") {
                this.setState({
                    riderList: [...this.state.riderList, {
                        riderName: {
                            name: "",
                            errormessage: "",
                            isClicked: false,
                            placeholder: "Select Rider Name",
                            style: "dropdown",
                            list: riderNameList,
                            label: ""
                        },
                        riderFor: {
                            name: "",
                            errormessage: "",
                            isClicked: true,
                            placeholder: "Select Rider For",
                            style: "dropdown",
                            list: this.props.riderForDropdownList,
                            enableDeleteIcon: false
                        },
                        riderSum: {
                            name: "",
                            errormessage: "",
                            isClicked: false,
                            placeholder: "0",
                            style: "amount",
                            label: "",
                            enableDeleteIcon: true
                        },
                    }],
                    riderValue: [...this.state.riderValue, {
                        riderName: "", riderFor: "", riderSum: "",
                    }],
                    ridersListError: ""
                })
            }
            else if (this.state.flag == "General") {
                this.setState({
                    riderListGeneral: [...this.state.riderListGeneral, {
                        riderName: {
                            name: "",
                            errormessage: "",
                            isClicked: false,
                            placeholder: "Select Rider Name",
                            style: "dropdown",
                            list: riderNameList,
                            label: ""
                        },
                        riderSum: {
                            name: "",
                            errormessage: "",
                            isClicked: false,
                            placeholder: "0",
                            style: "amount",
                            label: "",
                            enableDeleteIcon: true
                        },
                    }],
                    riderValueGeneral: [...this.state.riderValueGeneral, {
                        riderName: "",
                        riderSum: "",
                    }],
                    ridersListError: ""
                })
            }
            else {
                this.setState({
                    riderListLife: [...this.state.riderListLife, {
                        riderName: {
                            name: "",
                            errormessage: "",
                            isClicked: false,
                            placeholder: "Select Rider Name",
                            style: "dropdown",
                            list: riderNameList,
                            label: ""
                        },
                        riderTerm: {
                            name: "",
                            errormessage: "",
                            isClicked: false,
                            placeholder: "0",
                            style: "amount",
                            label: "",
                            enableDeleteIcon: false
                        },
                        riderSum: {
                            name: "",
                            errormessage: "",
                            isClicked: false,
                            placeholder: "0",
                            style: "amount",
                            label: "",
                            enableDeleteIcon: true
                        },
                    }],
                    riderValueLife: [...this.state.riderValueLife, {
                        riderName: "",
                        riderTerm: "",
                        riderSum: "",
                    }],
                })
            }
        }

        else {
            this.setState({ ridersListError: labels.Insurance.allFieldsMandatory })
        }
    }

    // Add Nominee Row
    addNomineeRow = () => {
        var isValid = false;

        if (this.state.nomineeGuardianVisible == true) {
            if (this.commonFunction(this.state.nomineeName) && this.commonFunction(this.state.nomineeRelationshipValue) &&
                this.commonFunction(this.state.dateOfBirth) && this.commonFunction(this.state.guardian) && this.commonFunction(this.state.nomineePercentage)) {
                isValid = true;
            }
            else {
                this.nomineeEmpty();
                isValid = false;
            }
        }
        else {
            if (this.commonFunction(this.state.nomineeName) && this.commonFunction(this.state.nomineeRelationshipValue) &&
                this.commonFunction(this.state.dateOfBirth) && this.commonFunction(this.state.nomineePercentage)) {
                isValid = true;
            }
            else {
                this.nomineeEmpty();
                isValid = false;
            }
        }

        if (isValid) {
            // Add Nominee
            if (this.state.nomineeID == "" && this.state.updateFlag == "add") {
                let id = uniqueId();
                var addNomineeList = [{
                    "nomineeName": { name: this.state.nomineeName, subHeader1: "", subHeader2: "", type: '' },
                    "nomineeRelationship": { name: this.state.nomineeRelationshipValue, subHeader1: "", subHeader2: "", type: '' },
                    "dateOfBirth": { name: this.state.dateOfBirth, subHeader1: "", subHeader2: "", type: '' },
                    "guardian": { name: this.state.guardian == "" ? "NA" : this.state.guardian, subHeader1: "", subHeader2: "", type: '' },
                    "nomineePercentage": { name: this.state.nomineePercentage, subHeader1: "", subHeader2: "", type: 'number' },
                    "id": { name: id, subHeader1: '', subHeader2: "", type: '' },
                    "nomineeRelationshipID": { name: this.state.nomineeRelationship, subHeader1: "", subHeader2: "", type: '' },
                    "nomineeType": { name: this.state.guardian == "" ? false : true, subHeader1: "", subHeader2: "", type: '' }, // Major-1, Minor-2
                }]

                let nomineeListDummy;
                nomineeListDummy = this.state.nomineeList.concat(addNomineeList)
                var percentageDummy = nomineeListDummy.map(i => {
                    return i.nomineePercentage.name
                })

                if (percentageDummy.length > 0) {
                    var percentageCalculate = percentageDummy.reduce((a, b) => {
                        return Number(a) + Number(b)
                    })

                    if (percentageCalculate <= 100) {
                        this.setState({
                            nomineeListLengthManage: this.state.nomineeList.concat(addNomineeList)
                        }, () => {

                            if (this.state.nomineeListLengthManage.length >= 3 && percentageCalculate < 100) {
                                this.setState({
                                    nomineePercentageError: "Nominee total percentage should be 100%"
                                })
                            }
                            else {
                                this.setState({
                                    nomineeList: this.state.nomineeList.concat(addNomineeList)
                                }, () => {
                                    var percentage = this.state.nomineeList.map(i => {
                                        return i.nomineePercentage.name
                                    })

                                    // Nominee Relationship List handling (Selected nominee Relationship don't come to Nominee Relationship dropdown)
                                    var relation = this.state.nomineeList.map((item) => {
                                        return item.nomineeRelationship.name
                                    })

                                    if (this.commonFunction(this.state.nomineeRelationshipListCopy)) {
                                        var relationListDropdown = this.state.nomineeRelationshipListCopy.filter((item) => !relation.includes(item.label))

                                        this.setState({
                                            nomineeRelationshipList: relationListDropdown
                                        })
                                    }


                                    // Percentage handling
                                    if (percentage.length > 0) {
                                        this.setState({
                                            nomineePercentageCalculate: percentage.reduce((a, b) => {
                                                return Number(a) + Number(b)
                                            })
                                        }, () => {

                                            // Nominee List length less than three and percentage less than 100
                                            if (this.state.nomineeList.length < 3 && this.state.nomineePercentageCalculate < 100) {
                                                this.setState({
                                                    nomineeButtonVisible: true,// Add nominee button
                                                    nomineeName: "",
                                                    nomineeRelationshipValue: "",
                                                    dateOfBirth: "",
                                                    guardian: "",
                                                    nomineePercentage: (100 - this.state.nomineePercentageCalculate),
                                                    nomineePercentageError: "",
                                                    guardianError: ""
                                                })
                                            }

                                            // Nominee List length greater than or equal three OR Percentage greater than or equal 100
                                            if (this.state.nomineeList.length >= 3 || this.state.nomineePercentageCalculate >= 100) {
                                                this.setState({
                                                    nomineeButtonVisible: false,
                                                })

                                                if (this.state.nomineePercentageCalculate == 100) {
                                                    this.setState({
                                                        nomineeButtonVisible: false,
                                                        nomineePercentageError: "",
                                                        nomineeName: "",
                                                        nomineeRelationshipValue: "",
                                                        dateOfBirth: "",
                                                        guardian: "",
                                                        nomineePercentage: "",
                                                        guardianError: "",
                                                        nomineeID: "",
                                                        updateFlag: 'add'
                                                    })
                                                }
                                                else {
                                                    this.setState({
                                                        nomineePercentageError: "Nominee total percentage should be 100%"
                                                    })
                                                }
                                            }

                                        })

                                    }
                                    else {
                                        this.setState({
                                            nomineeButtonVisible: true
                                        })
                                    }
                                })
                            }
                        })
                    }
                    else {
                        this.setState({
                            nomineePercentageError: "Nominee total percentage should be 100%"
                        })
                    }
                }
            }
            // Update Nominee
            else {
                var nomineeListFindMap = this.state.nomineeList.map((item, index) => {
                    if (this.state.nomineeID == item.id.name) {
                        return {
                            ...item,
                            "nomineeName": { name: this.state.nomineeName, subHeader1: "", subHeader2: "", type: '' },
                            "nomineeRelationship": { name: this.state.nomineeRelationshipValue, subHeader1: "", subHeader2: "", type: '' },
                            "dateOfBirth": { name: this.state.dateOfBirth, subHeader1: "", subHeader2: "", type: '' },
                            "guardian": { name: this.state.guardian == "" ? "NA" : this.state.guardian, subHeader1: "", subHeader2: "", type: '' },
                            "nomineePercentage": { name: this.state.nomineePercentage, subHeader1: "", subHeader2: "", type: 'number' },
                            "id": { name: item.id.name, subHeader1: '', subHeader2: "", type: '' },
                            "nomineeRelationshipID": { name: this.state.nomineeRelationship, subHeader1: "", subHeader2: "", type: '' },
                            "nomineeType": { name: this.state.guardian == "" ? false : true, subHeader1: "", subHeader2: "", type: '' }, // Major-1, Minor-2
                        }
                    }
                    else {
                        return { ...item }
                    }
                })


                var percentageDummy = nomineeListFindMap.map(i => {
                    return i.nomineePercentage.name
                })

                if (percentageDummy.length > 0) {
                    var percentageCalculate1 = percentageDummy.reduce((a, b) => {
                        return Number(a) + Number(b)
                    })

                    if (percentageCalculate1 <= 100) {

                        this.setState({
                            nomineeListLengthManage: nomineeListFindMap
                        }, () => {
                            if (this.state.nomineeListLengthManage.length >= 3 && percentageCalculate1 < 100) {
                                this.setState({
                                    nomineePercentageError: "Nominee total percentage should be 100%"
                                })
                            }
                            else {
                                this.setState({
                                    nomineeList: nomineeListFindMap
                                }, () => {

                                    var percentage = this.state.nomineeList.map(i => {
                                        return i.nomineePercentage.name
                                    })
                                    if (percentage.length > 0) {
                                        this.setState({
                                            nomineePercentageCalculate: percentage.reduce((a, b) => {
                                                return Number(a) + Number(b)
                                            })
                                        }, () => {

                                            // Nominee Relationship List handling (Selected nominee Relationship don't come to Nominee Relationship dropdown)
                                            var relation = this.state.nomineeList.map((item) => {
                                                return item.nomineeRelationship.name
                                            })

                                            if (this.commonFunction(this.state.nomineeRelationshipListCopy)) {
                                                var relationListDropdown = this.state.nomineeRelationshipListCopy.filter((item) => !relation.includes(item.label))

                                                this.setState({
                                                    nomineeRelationshipList: relationListDropdown
                                                })
                                            }


                                            // Nominee List length less than three and percentage less than 100
                                            if (this.state.nomineeList.length < 3 && this.state.nomineePercentageCalculate < 100) {
                                                this.setState({
                                                    nomineeButtonVisible: true,// Add nominee button
                                                    nomineeName: "",
                                                    nomineeRelationshipValue: "",
                                                    dateOfBirth: "",
                                                    guardian: "",
                                                    nomineePercentage: (100 - this.state.nomineePercentageCalculate),
                                                    nomineePercentageError: "",
                                                    nomineeID: "",
                                                    updateFlag: 'add'
                                                })
                                            }

                                            // Nominee List length greater than or equal three OR Percentage greater than or equal 100
                                            if (this.state.nomineeList.length >= 3 || this.state.nomineePercentageCalculate >= 100) {

                                                if (this.state.nomineeList.length >= 3) {
                                                    this.setState({
                                                        nomineeButtonVisible: false,
                                                    })
                                                }

                                                if (this.state.nomineePercentageCalculate == 100) {
                                                    this.setState({
                                                        nomineeButtonVisible: false,
                                                        nomineePercentageError: "",
                                                        nomineeName: "",
                                                        nomineeRelationshipValue: "",
                                                        dateOfBirth: "",
                                                        guardian: "",
                                                        nomineePercentage: "",
                                                        nomineeID: "",
                                                        updateFlag: 'add'
                                                    })
                                                }
                                                else {
                                                    this.setState({
                                                        nomineePercentageError: "Nominee percentage should be 100%"
                                                    })
                                                }
                                            }

                                        })

                                    }
                                    else {
                                        this.setState({
                                            nomineeButtonVisible: true
                                        })
                                    }
                                })
                            }
                        })

                    }
                    else {
                        this.setState({
                            nomineePercentageError: "Nominee percentage should be 100%"
                        })
                    }
                }
            }
        }
        else {

        }
    }

    // Add Trustee Row
    addTrusteeRow = () => {
        var isValid = false;

        if (this.commonFunction(this.state.trusteeName) && this.commonFunction(this.state.trusteeRelationshipValue) &&
            this.commonFunction(this.state.trusteeDateOfBirth)) {
            isValid = true;
        }
        else {
            this.trusteeEmpty();
            isValid = false;
        }

        if (isValid) {
            // Add Trustee
            if (this.state.trusteeID == "" && this.state.trusteeUpdateFlag == "add") {
                let id = uniqueId();
                var addTrusteeList = [{
                    "trusteeName": { name: this.state.trusteeName, subHeader1: "", subHeader2: "", type: '' },
                    "trusteeRelationship": { name: this.state.trusteeRelationshipValue, subHeader1: "", subHeader2: "", type: '' },
                    "trusteeDateOfBirth": { name: this.state.trusteeDateOfBirth, subHeader1: "", subHeader2: "", type: '' },
                    "id": { name: id, subHeader1: '', subHeader2: "", type: '' },
                    "trusteeRelationshipID": { name: this.state.trusteeRelationship, subHeader1: "", subHeader2: "", type: '' },
                }]

                this.setState({
                    trusteeList: this.state.trusteeList.concat(addTrusteeList),
                    trusteeName: "",
                    trusteeRelationshipValue: "",
                    trusteeDateOfBirth: "",
                    trusteeNameError: "",
                    trusteeRelationshipError: "",
                    trusteDateOfBirthError: ""
                }, () => {
                    // Trustee Relationship List handling (Selected trustee Relationship don't come to Trustee Relationship dropdown)
                    if (this.commonFunction(this.state.trusteeList))
                        var relation = this.state.trusteeList.map((item) => {
                            return item.trusteeRelationship.name
                        })

                    if (this.commonFunction(this.state.trusteeRelationshipListCopy)) {
                        var relationListDropdown = this.state.trusteeRelationshipListCopy.filter((item) => !relation.includes(item.label))

                        this.setState({
                            trusteeRelationshipList: relationListDropdown
                        })
                    }
                })
            }
            // Update Trustee
            else {
                var trusteeListFindMap = this.state.trusteeList.map((item, index) => {
                    if (this.state.trusteeID == item.id.name) {
                        return {
                            ...item,
                            "trusteeName": { name: this.state.trusteeName, subHeader1: "", subHeader2: "", type: '' },
                            "trusteeRelationship": { name: this.state.trusteeRelationshipValue, subHeader1: "", subHeader2: "", type: '' },
                            "trusteeDateOfBirth": { name: this.state.trusteeDateOfBirth, subHeader1: "", subHeader2: "", type: '' },
                            "id": { name: item.id.name, subHeader1: '', subHeader2: "", type: '' },
                            "trusteeRelationshipID": { name: this.state.trusteeRelationship, subHeader1: "", subHeader2: "", type: '' },
                        }
                    }
                    else {
                        return { ...item }
                    }
                })
                this.setState({
                    trusteeList: trusteeListFindMap,
                    trusteeName: "",
                    trusteeRelationshipValue: "",
                    trusteeDateOfBirth: "",
                    trusteeNameError: "",
                    trusteeRelationshipError: "",
                    trusteDateOfBirthError: "",
                    trusteeID: "",
                    trusteeUpdateFlag: 'add'
                }, () => {
                    // Trustee Relationship List handling (Selected trustee Relationship don't come to Trustee Relationship dropdown)
                    if (this.commonFunction(this.state.trusteeList))
                        var relation = this.state.trusteeList.map((item) => {
                            return item.trusteeRelationship.name
                        })

                    if (this.commonFunction(this.state.trusteeRelationshipListCopy)) {
                        var relationListDropdown = this.state.trusteeRelationshipListCopy.filter((item) => !relation.includes(item.label))

                        this.setState({
                            trusteeRelationshipList: relationListDropdown
                        })
                    }
                })
            }

        }

    }

    // Edit Trustee Row
    editTrustee = (List) => {
        var filterData = this.state.trusteeList.filter((item, ind) => (List.id.name == item.id.name))

        var mapData = filterData.map((item) => {
            return ({
                TrusteeName: item.trusteeName.name,
                Relationship: item.trusteeRelationship.name,
                DOB: item.trusteeDateOfBirth.name,
                ID: item.id.name,
                relationshipID: item.trusteeRelationshipID.name
            })
        })

        this.setState({
            trusteeName: mapData[0].TrusteeName,
            trusteeRelationshipValue: mapData[0].Relationship,
            trusteeDateOfBirth: mapData[0].DOB,
            trusteeID: mapData[0].ID,
            trusteeRelationship: mapData[0].relationshipID,
            trusteeUpdateFlag: "Update",
        }, () => {
            this.trusteeEmpty();
        })
    }

    // Delete Trustee
    deleteTrustee = (List) => {
        this.setState({
            openDeletePopupTrustee: true, // Delete Popup open
            trusteeDeleteID: List.id.name
        })
    }

    // Trustee Delete Functionality (Popup Yes button Click)
    handleYesClickTrustee = () => {
        var data = this.state.trusteeList.filter((item, ind) => (this.state.trusteeDeleteID != item.id.name))

        if (this.commonFunction(data) && data.length > 0) {
            // If I select the Edit click, and I delete the same List . So I change the trusteeID and trusteeUpdateFlag
            this.setState({
                trusteeList: data,
                trusteeID: "", // To do empty the used for Add / Update functionality trusteeID
                trusteeUpdateFlag: "add",
                openDeletePopupTrustee: false,
            }, () => {
                // Trustee Relationship List handling (Selected trustee Relationship don't come to Trustee Relationship dropdown)
                if (this.commonFunction(this.state.trusteeList))
                    var relation = this.state.trusteeList.map((item) => {
                        return item.trusteeRelationship.name
                    })

                if (this.commonFunction(this.state.trusteeRelationshipListCopy)) {
                    var relationListDropdown = this.state.trusteeRelationshipListCopy.filter((item) => !relation.includes(item.label))

                    this.setState({
                        trusteeRelationshipList: relationListDropdown
                    })
                }
            })
        }
        else {
            this.setState({
                trusteeList: data,
                openDeletePopupTrustee: false,
                trusteeID: "", // To do empty the used for Add / Update functionality trusteeID
                trusteeUpdateFlag: "add",
                trusteeRelationshipList: this.state.trusteeRelationshipListCopy // To fill up the trusteeRelationshipList
            })
        }
    }

    // Add Beneficiary Row
    addBeneficiaryRow = () => {
        var isValid = false;

        if (this.state.beneficiaryGuardianVisible == true) {
            if (this.commonFunction(this.state.beneficiaryName) && this.commonFunction(this.state.beneficiaryRelationshipValue) &&
                this.commonFunction(this.state.beneficiaryDateOfBirth) && this.commonFunction(this.state.beneficiaryGuardian) && this.commonFunction(this.state.beneficiaryPercentage)) {
                isValid = true;
            }
            else {
                this.beneficiaryEmpty();
                isValid = false;
            }
        }
        else {
            if (this.commonFunction(this.state.beneficiaryName) && this.commonFunction(this.state.beneficiaryRelationshipValue) &&
                this.commonFunction(this.state.beneficiaryDateOfBirth) && this.commonFunction(this.state.beneficiaryPercentage)) {
                isValid = true;
            }
            else {
                this.beneficiaryEmpty();
                isValid = false;
            }
        }

        if (isValid) {
            // Add Beneficiary
            if (this.state.beneficiaryID == "" && this.state.beneficiaryUpdateFlag == "add") {
                let id = uniqueId();
                var addBeneficiaryList = [{
                    "beneficiaryName": { name: this.state.beneficiaryName, subHeader1: "", subHeader2: "", type: '' },
                    "beneficiaryRelationship": { name: this.state.beneficiaryRelationshipValue, subHeader1: "", subHeader2: "", type: '' },
                    "beneficiaryDateOfBirth": { name: this.state.beneficiaryDateOfBirth, subHeader1: "", subHeader2: "", type: '' },
                    "beneficiaryGuardian": { name: this.state.beneficiaryGuardian == "" ? "NA" : this.state.beneficiaryGuardian, subHeader1: "", subHeader2: "", type: '' },
                    "beneficiaryPercentage": { name: this.state.beneficiaryPercentage, subHeader1: "", subHeader2: "", type: 'number' },
                    "id": { name: id, subHeader1: '', subHeader2: "", type: '' },
                    "beneficiaryRelationshipID": { name: this.state.beneficiaryRelationship, subHeader1: "", subHeader2: "", type: '' },
                    "beneficiaryType": { name: this.state.beneficiaryGuardian == "" ? 1 : 2, subHeader1: "", subHeader2: "", type: '' }, // Major-1, Minor-2
                }]

                let beneficiaryListDummy;
                beneficiaryListDummy = this.state.beneficiaryList.concat(addBeneficiaryList)
                var percentageDummy = beneficiaryListDummy.map(i => {
                    return i.beneficiaryPercentage.name
                })

                if (percentageDummy.length > 0) {
                    var percentageCalculate = percentageDummy.reduce((a, b) => {
                        return Number(a) + Number(b)
                    })

                    if (percentageCalculate <= 100) {
                        this.setState({
                            beneficiaryListLengthManage: this.state.beneficiaryList.concat(addBeneficiaryList)
                        }, () => {
                            if (this.state.beneficiaryListLengthManage.length >= 3 && percentageCalculate < 100) {
                                this.setState({
                                    beneficiaryPercentageError: "Beneficiary total percentage should be 100%"
                                })
                            }
                            else {
                                this.setState({
                                    beneficiaryList: this.state.beneficiaryList.concat(addBeneficiaryList)
                                }, () => {
                                    var percentage = this.state.beneficiaryList.map(i => {
                                        return i.beneficiaryPercentage.name
                                    })

                                    // Beneficiary Relationship List handling (Selected beneficiary Relationship don't come to Beneficiary Relationship dropdown)
                                    var relation = this.state.beneficiaryList.map((item) => {
                                        return item.beneficiaryRelationship.name
                                    })

                                    if (this.commonFunction(this.state.beneficiaryRelationshipListCopy)) {
                                        var relationListDropdown = this.state.beneficiaryRelationshipListCopy.filter((item) => !relation.includes(item.label))

                                        this.setState({
                                            beneficiaryRelationshipList: relationListDropdown
                                        })
                                    }

                                    // Percentage handling
                                    if (percentage.length > 0) {
                                        this.setState({
                                            beneficiaryPercentageCalculate: percentage.reduce((a, b) => {
                                                return Number(a) + Number(b)
                                            })
                                        }, () => {

                                            // Beneficiary List length less than three and percentage less than 100
                                            if (this.state.beneficiaryList.length < 3 && this.state.beneficiaryPercentageCalculate < 100) {
                                                this.setState({
                                                    beneficiaryButtonVisible: true,// Add Beneficiary button
                                                    beneficiaryName: "",
                                                    beneficiaryRelationshipValue: "",
                                                    beneficiaryDateOfBirth: "",
                                                    beneficiaryGuardian: "",
                                                    beneficiaryPercentage: (100 - this.state.beneficiaryPercentageCalculate),
                                                    beneficiaryPercentageError: "",
                                                    beneficiaryGuardianError: ""
                                                })
                                            }

                                            // Beneficiary List length greater than or equal three OR Percentage greater than or equal 100
                                            if (this.state.beneficiaryList.length >= 3 || this.state.beneficiaryPercentageCalculate >= 100) {
                                                this.setState({
                                                    beneficiaryButtonVisible: false,
                                                })

                                                if (this.state.beneficiaryPercentageCalculate == 100) {
                                                    this.setState({
                                                        beneficiaryButtonVisible: false,
                                                        beneficiaryPercentageError: "",
                                                        beneficiaryName: "",
                                                        beneficiaryRelationshipValue: "",
                                                        beneficiaryDateOfBirth: "",
                                                        beneficiaryGuardian: "",
                                                        beneficiaryPercentage: "",
                                                        beneficiaryGuardianError: "",
                                                        beneficiaryID: "",
                                                        beneficiaryUpdateFlag: 'add'
                                                    })
                                                }
                                                else {
                                                    this.setState({
                                                        beneficiaryPercentageError: "Beneficiary total percentage should be 100%"
                                                    })
                                                }
                                            }

                                        })

                                    }
                                    else {
                                        this.setState({
                                            beneficiaryButtonVisible: true
                                        })
                                    }
                                })

                            }
                        })
                    }
                    else {
                        this.setState({
                            beneficiaryPercentageError: "Beneficiary total percentage should be 100%"
                        })
                    }
                }
            }
            // Update Beneficiary
            else {
                var beneficiaryListFindMap = this.state.beneficiaryList.map((item, index) => {
                    if (this.state.beneficiaryID == item.id.name) {
                        return {
                            ...item,
                            "beneficiaryName": { name: this.state.beneficiaryName, subHeader1: "", subHeader2: "", type: '' },
                            "beneficiaryRelationship": { name: this.state.beneficiaryRelationshipValue, subHeader1: "", subHeader2: "", type: '' },
                            "beneficiaryDateOfBirth": { name: this.state.beneficiaryDateOfBirth, subHeader1: "", subHeader2: "", type: '' },
                            "beneficiaryGuardian": { name: this.state.beneficiaryGuardian == "" ? "NA" : this.state.beneficiaryGuardian, subHeader1: "", subHeader2: "", type: '' },
                            "beneficiaryPercentage": { name: this.state.beneficiaryPercentage, subHeader1: "", subHeader2: "", type: 'number' },
                            "id": { name: item.id.name, subHeader1: '', subHeader2: "", type: '' },
                            "beneficiaryRelationshipID": { name: this.state.beneficiaryRelationship, subHeader1: "", subHeader2: "", type: '' },
                            "beneficiaryType": { name: this.state.beneficiaryGuardian == "" ? 1 : 2, subHeader1: "", subHeader2: "", type: '' }, // Major-1, Minor-2
                        }
                    }
                    else {
                        return { ...item }
                    }
                })


                var percentageDummy = beneficiaryListFindMap.map(i => {
                    return i.beneficiaryPercentage.name
                })

                if (percentageDummy.length > 0) {
                    var percentageCalculate1 = percentageDummy.reduce((a, b) => {
                        return Number(a) + Number(b)
                    })

                    if (percentageCalculate1 <= 100) {
                        this.setState({
                            beneficiaryListLengthManage: beneficiaryListFindMap
                        }, () => {
                            if (this.state.beneficiaryListLengthManage.length >= 3 && percentageCalculate1 < 100) {
                                this.setState({
                                    beneficiaryPercentageError: "Beneficiary total percentage should be 100%"
                                })
                            }
                            else {
                                this.setState({
                                    beneficiaryList: beneficiaryListFindMap
                                }, () => {

                                    var percentage = this.state.beneficiaryList.map(i => {
                                        return i.beneficiaryPercentage.name
                                    })
                                    if (percentage.length > 0) {
                                        this.setState({
                                            beneficiaryPercentageCalculate: percentage.reduce((a, b) => {
                                                return Number(a) + Number(b)
                                            })
                                        }, () => {

                                            // Beneficiary Relationship List handling (Selected beneficiary Relationship don't come to Beneficiary Relationship dropdown)
                                            var relation = this.state.beneficiaryList.map((item) => {
                                                return item.beneficiaryRelationship.name
                                            })

                                            if (this.commonFunction(this.state.beneficiaryRelationshipListCopy)) {
                                                var relationListDropdown = this.state.beneficiaryRelationshipListCopy.filter((item) => !relation.includes(item.label))

                                                this.setState({
                                                    beneficiaryRelationshipList: relationListDropdown
                                                })
                                            }


                                            // Beneficiary List length less than three and percentage less than 100
                                            if (this.state.beneficiaryList.length < 3 && this.state.beneficiaryPercentageCalculate < 100) {
                                                this.setState({
                                                    beneficiaryButtonVisible: true,// Add nominee button
                                                    beneficiaryName: "",
                                                    beneficiaryRelationshipValue: "",
                                                    beneficiaryDateOfBirth: "",
                                                    beneficiaryGuardian: "",
                                                    beneficiaryPercentage: (100 - this.state.beneficiaryPercentageCalculate),
                                                    beneficiaryPercentageError: "",
                                                    beneficiaryID: "",
                                                    beneficiaryUpdateFlag: 'add'
                                                })
                                            }

                                            // Beneficiary List length greater than or equal three OR Percentage greater than or equal 100
                                            if (this.state.beneficiaryList.length >= 3 || this.state.beneficiaryPercentageCalculate >= 100) {

                                                if (this.state.beneficiaryList.length >= 3) {
                                                    this.setState({
                                                        beneficiaryButtonVisible: false,
                                                    })
                                                }

                                                if (this.state.beneficiaryPercentageCalculate == 100) {
                                                    this.setState({
                                                        beneficiaryButtonVisible: false,
                                                        beneficiaryPercentageError: "",
                                                        beneficiaryName: "",
                                                        beneficiaryRelationshipValue: "",
                                                        beneficiaryDateOfBirth: "",
                                                        beneficiaryGuardian: "",
                                                        beneficiaryPercentage: "",
                                                        beneficiaryID: "",
                                                        beneficiaryUpdateFlag: 'add'
                                                    })
                                                }
                                                else {
                                                    this.setState({
                                                        beneficiaryPercentageError: "Beneficiary percentage should be 100%"
                                                    })
                                                }
                                            }

                                        })

                                    }
                                    else {
                                        this.setState({
                                            beneficiaryButtonVisible: true
                                        })
                                    }
                                })
                            }
                        })

                    }
                    else {
                        this.setState({
                            beneficiaryPercentageError: "Beneficiary percentage should be 100%"
                        })
                    }
                }
            }
        }
    }

    // Edit Row Beneficiary
    editBenificiary = (List) => {
        var filterData = this.state.beneficiaryList.filter((item, ind) => (List.id.name == item.id.name))

        var mapData = filterData.map((item) => {
            return ({
                BeneficiaryName: item.beneficiaryName.name,
                Relationship: item.beneficiaryRelationship.name,
                DOB: item.beneficiaryDateOfBirth.name,
                Guardian: item.beneficiaryGuardian.name,
                Percentage: item.beneficiaryPercentage.name,
                ID: item.id.name,
                relationshipID: item.beneficiaryRelationshipID.name
            })
        })

        this.setState({
            beneficiaryName: mapData[0].BeneficiaryName,
            beneficiaryRelationshipValue: mapData[0].Relationship,
            beneficiaryDateOfBirth: mapData[0].DOB,
            beneficiaryGuardian: mapData[0].Guardian,
            beneficiaryPercentage: mapData[0].Percentage,
            beneficiaryID: mapData[0].ID,
            beneficiaryRelationship: mapData[0].relationshipID,
            beneficiaryUpdateFlag: "Update",
            beneficiaryButtonVisible: true, // To enable the AddBeneficiary Button
        }, () => {
            this.beneficiaryEmpty();
        })
    }

    // Delete Beneficiary
    deleteBenificiary = (List) => {
        this.setState({
            openDeletePopupBeneficiary: true, // Delete Popup open
            beneficiaryDeleteID: List.id.name
        })
    }

    // Beneficiary Delete Functionality (Popup Yes button Click)
    handleYesClickBeneficiary = () => {
        var data = this.state.beneficiaryList.filter((item, ind) => (this.state.beneficiaryDeleteID != item.id.name))

        if (this.commonFunction(data) && data.length > 0) {
            var percentageData = data.map(i => {
                return i.beneficiaryPercentage.name
            })
            if (percentageData.length > 0)
                var Percentage = percentageData.reduce((a, b) => {
                    return Number(a) + Number(b)
                })
            this.setState({
                beneficiaryPercentageCalculate: Percentage,
                beneficiaryPercentage: (100 - Percentage)
            })
            // If I select the Edit click, and I delete the same List . So I change the beneficiaryID and beneficiaryUpdateFlag
            this.setState({
                beneficiaryList: data,
                beneficiaryID: "", // To do empty the used for Add / Update functionality beneficiaryID
                beneficiaryUpdateFlag: "add",
                openDeletePopupBeneficiary: false,
                beneficiaryPercentageError: "", // To do empty the beneficiary Percentage error msg,
                beneficiaryButtonVisible: true, // Beneficiary Parts will show
            }, () => {
                // Beneficiary Relationship List handling (Selected beneficiary Relationship don't come to Beneficiary Relationship dropdown)
                var relation = this.state.beneficiaryList.map((item) => {
                    return item.beneficiaryRelationship.name
                })

                if (this.commonFunction(this.state.beneficiaryRelationshipListCopy)) {
                    var relationListDropdown = this.state.beneficiaryRelationshipListCopy.filter((item) => !relation.includes(item.label))

                    this.setState({
                        beneficiaryRelationshipList: relationListDropdown
                    })
                }
            })
        }
        else {
            this.setState({
                beneficiaryList: data,
                openDeletePopupBeneficiary: false,
                beneficiaryID: "", // To do empty the used for Add / Update functionality beneficiaryID
                beneficiaryUpdateFlag: "add",
                beneficiaryPercentageCalculate: 0, // Beneficiary List length is zero, So beneficiaryPercentageCalculate is zeo
                beneficiaryPercentage: 100,
                beneficiaryButtonVisible: true, // Beneficiary Parts will show
                beneficiaryRelationshipList: this.state.beneficiaryRelationshipListCopy // To Fill up the beneficiaryRelationshipList
            })
        }
    }

    // Add ULIP Row
    addULIPRow = () => {
        var isValid = false;

        if (this.commonFunction(this.state.uLIPList)) {
            if (this.commonFunction(this.state.schemeNamelabel) && this.commonFunction(this.state.latestValue)) {
                var filterDataULIP = this.state.uLIPList.filter((item) => (this.state.schemeNamelabel == item.schemeName.name))
                if (this.commonFunction(filterDataULIP) && filterDataULIP.length > 0) {
                    if (this.state.schemeNameUpdate == this.state.schemeNamelabel) {
                        isValid = true;
                        this.setState({
                            schemeNameError: ""
                        })
                    }
                    else {
                        isValid = false;
                        this.setState({
                            schemeNameError: labels.Insurance.schemeNameExists
                        })
                    }

                }
                else {
                    isValid = true;
                    this.setState({
                        schemeNameError: ""
                    })
                }
            }
            else {
                this.uLIPEmpty();
            }
        }
        else {
            if (this.commonFunction(this.state.asOnDate) && this.commonFunction(this.state.schemeNamelabel) &&
                this.commonFunction(this.state.latestValue)) {
                isValid = true;
            }
            else {
                this.uLIPEmpty();
                isValid = false;
            }
        }


        if (isValid) {
            // Add ULIP
            if (this.state.uLIPID == "" && this.state.uLIPUpdateFlag == "add") {
                let id = uniqueId();
                var addULIPList = [{
                    "schemeName": { name: this.state.schemeNamelabel, subHeader1: "", subHeader2: "", type: '' },
                    "asOnDate": { name: this.state.asOnDate, subHeader1: "", subHeader2: "", type: '' },
                    "units": { name: this.state.units == "" ? 0 : this.state.units, subHeader1: "", subHeader2: "", type: '' },
                    "latestNAV": { name: this.state.latestNAV == "" ? 0 : this.state.latestNAV, subHeader1: "", subHeader2: "", type: '' },
                    "latestValue": { name: this.state.latestValue, subHeader1: "", subHeader2: "", type: '' },
                    "id": { name: id, subHeader1: '', subHeader2: "", type: '' },
                    "schemeNameID": { name: this.state.schemeName, subHeader1: "", subHeader2: "", type: '' },
                }]

                this.setState({
                    uLIPList: this.state.uLIPList.concat(addULIPList),
                    asOnDate: moment().format("DD MMM YYYY"),
                    schemeNamelabel: "",
                    units: "",
                    latestNAV: "",
                    latestValue: "",
                    asOnDateError: "",
                    schemeNameError: "",
                    unitsError: "",
                    latestNAVError: "",
                    latestValueError: ""
                })
            }
            // Update ULIP
            else {
                var uLIPListFindMap = this.state.uLIPList.map((item, index) => {
                    if (this.state.uLIPID == item.id.name) {
                        return {
                            ...item,
                            "schemeName": { name: this.state.schemeNamelabel, subHeader1: "", subHeader2: "", type: '' },
                            "asOnDate": { name: this.state.asOnDate, subHeader1: "", subHeader2: "", type: '' },
                            "units": { name: this.state.units == "" ? 0 : this.state.units, subHeader1: "", subHeader2: "", type: '' },
                            "latestNAV": { name: this.state.latestNAV == "" ? 0 : this.state.latestNAV, subHeader1: "", subHeader2: "", type: '' },
                            "latestValue": { name: this.state.latestValue, subHeader1: "", subHeader2: "", type: '' },
                            "id": { name: item.id.name, subHeader1: '', subHeader2: "", type: '' },
                            "schemeNameID": { name: this.state.schemeName, subHeader1: "", subHeader2: "", type: '' },
                        }
                    }
                    else {
                        return { ...item }
                    }
                })
                this.setState({
                    uLIPList: uLIPListFindMap,
                    asOnDate: moment().format("DD MMM YYYY"),
                    schemeNamelabel: "",
                    units: "",
                    latestNAV: "",
                    latestValue: "",
                    asOnDateError: "",
                    schemeNameError: "",
                    unitsError: "",
                    latestNAVError: "",
                    latestValueError: "",
                    uLIPID: "",
                    uLIPUpdateFlag: 'add',
                    schemeNameUpdate: "" // After Update to do empty this state
                })
            }
        }
    }

    editULIP = (List) => {
        var filterData = this.state.uLIPList.filter((item, ind) => (List.id.name == item.id.name))

        var mapData = filterData.map((item) => {
            return ({
                AsOnDate: item.asOnDate.name,
                SchemeNameLabel: item.schemeName.name,
                Units: item.units.name,
                LatestNAV: item.latestNAV.name,
                LatestValue: item.latestValue.name,
                ID: item.id.name,
                SchemeNameID: item.schemeNameID.name
            })
        })

        this.setState({
            asOnDate: mapData[0].AsOnDate,
            schemeNamelabel: mapData[0].SchemeNameLabel,
            units: mapData[0].Units,
            latestNAV: mapData[0].LatestNAV,
            latestValue: mapData[0].LatestValue,
            uLIPID: mapData[0].ID,
            schemeName: mapData[0].SchemeNameID,
            uLIPUpdateFlag: "Update",
            // While do Update, this SchemeNameUpdate State is used for to allow selected scheme Name
            schemeNameUpdate: mapData[0].SchemeNameLabel
        }, () => {
            this.uLIPEmpty();
        })
    }

    // Delete ULIP
    deleteULIP = (List) => {
        this.setState({
            openDeletePopupULIP: true, // Delete Popup open
            uLIPDeleteID: List.id.name
        })
    }

    // ULIP Delete Functionality (Popup Yes button Click)
    handleYesClickULIP = () => {
        var data = this.state.uLIPList.filter((item, ind) => (this.state.uLIPDeleteID != item.id.name))

        if (this.commonFunction(data) && data.length > 0) {
            // If I select the Edit click, and I delete the same List . So I change the uLIPID and uLIPUpdateFlag
            this.setState({
                uLIPList: data,
                uLIPID: "", // To do empty the used for Add / Update functionality uLIPID
                uLIPUpdateFlag: "add",
                openDeletePopupULIP: false,
            })
        }
        else {
            this.setState({
                uLIPList: data,
                openDeletePopupULIP: false,
                uLIPID: "", // To do empty the used for Add / Update functionality uLIPID
                uLIPUpdateFlag: "add",
            })
        }
    }

    // clear and show the  Nominee errors
    nomineeEmpty = () => {
        // Nominee Name
        if (this.commonFunction(this.state.nomineeName)) {
            this.setState({ nomineeNameError: "" })
        }
        else {
            this.setState({ nomineeNameError: labels.Insurance.required })
        }
        // Nominee Relationship
        if (this.commonFunction(this.state.nomineeRelationshipValue)) {
            this.setState({ nomineeRelationshipError: "" })
        }
        else {
            this.setState({ nomineeRelationshipError: labels.Insurance.required })
        }
        // Date of Birth
        if (this.commonFunction(this.state.dateOfBirth)) {
            this.setState({ dateOfBirthError: "" })
        }
        else {
            this.setState({ dateOfBirthError: labels.Insurance.required })
        }
        // Guardian
        if (this.state.nomineeGuardianVisible == true) {
            if (this.commonFunction(this.state.guardian)) {
                this.setState({ guardianError: "" })
            }
            else {
                this.setState({ guardianError: labels.Insurance.required })
            }
        }
        // Nominee Percentage
        if (this.commonFunction(this.state.nomineePercentage)) {
            this.setState({ nomineePercentageError: "" })
        }
        else {
            this.setState({ nomineePercentageError: labels.Insurance.required })
        }
    }

    // clear and show the  Trustee errors 
    trusteeEmpty = () => {
        // Trustee Name
        if (this.commonFunction(this.state.trusteeName)) {
            this.setState({ trusteeNameError: "" })
        }
        else {
            this.setState({ trusteeNameError: labels.Insurance.required })
        }
        // Trustee Relationship
        if (this.commonFunction(this.state.trusteeRelationshipValue)) {
            this.setState({ trusteeRelationshipError: "" })
        }
        else {
            this.setState({ trusteeRelationshipError: labels.Insurance.required })
        }
        // Trustee Date of Birth
        if (this.commonFunction(this.state.trusteeDateOfBirth)) {
            this.setState({ trusteDateOfBirthError: "" })
        }
        else {
            this.setState({ trusteDateOfBirthError: labels.Insurance.required })
        }
    }

    // clear and show the  Beneficiary errors 
    beneficiaryEmpty = () => {
        // Beneficiary Name
        if (this.commonFunction(this.state.beneficiaryName)) {
            this.setState({ beneficiaryNameError: "" })
        }
        else {
            this.setState({ beneficiaryNameError: labels.Insurance.required })
        }
        // Beneficiary Relationship
        if (this.commonFunction(this.state.beneficiaryRelationshipValue)) {
            this.setState({ beneficiaryRelationshipError: "" })
        }
        else {
            this.setState({ beneficiaryRelationshipError: labels.Insurance.required })
        }
        // Beneficiary Date of Birth
        if (this.commonFunction(this.state.beneficiaryDateOfBirth)) {
            this.setState({ beneficiaryDateOfBirthError: "" })
        }
        else {
            this.setState({ beneficiaryDateOfBirthError: labels.Insurance.required })
        }
        // Beneficiary Guardian
        if (this.state.beneficiaryGuardianVisible == true) {
            if (this.commonFunction(this.state.beneficiaryGuardian)) {
                this.setState({ beneficiaryGuardianError: "" })
            }
            else {
                this.setState({ beneficiaryGuardianError: labels.Insurance.required })
            }
        }
        // Beneficiary Percentage
        if (this.commonFunction(this.state.beneficiaryPercentage)) {
            this.setState({ beneficiaryPercentageError: "" })
        }
        else {
            this.setState({ beneficiaryPercentageError: labels.Insurance.required })
        }
    }

    // clear and show the ULIP errors 
    uLIPEmpty = () => {
        // ULIP As On Date
        if (this.commonFunction(this.state.asOnDate)) {
            this.setState({ asOnDateError: "" })
        }
        else {
            this.setState({ asOnDateError: labels.Insurance.required })
        }
        // Trustee Relationship
        if (this.commonFunction(this.state.schemeNamelabel)) {
            this.setState({ schemeNameError: "" })
        }
        else {
            this.setState({ schemeNameError: labels.Insurance.required })
        }
        // Trustee Date of Birth
        if (this.commonFunction(this.state.latestValue)) {
            this.setState({ latestValueError: "" })
        }
        else {
            this.setState({ latestValueError: labels.Insurance.required })
        }
    }


    // To Empty the all data
    empty = () => {
        this.setState({
            insuredTypeError: "",
            policyName: "",
            policyNameError: "",
            policyNo: "",
            policyNoError: "",
            policyTypeName: "",
            policyTypeError: "",
            policyType: "",
            nickName: "",
            nickNameError: "",
            startDate: "",
            startDateError: "",
            coverAmount: "",
            coverAmountError: "",
            premiumAmount: "",
            premiumAmountError: "",
            policyTerm: '',
            policyTermError: "",
            bankAccount: "",
            bankAccountError: "",
            // riderValue: [],
            // riderValueGeneral: [],
            ridersListError: "",
            riderValue: [{
                riderName: "",
                riderFor: "",
                riderSum: "",
            }],
            riderValueGeneral: [{
                riderName: "",
                riderSum: "",
            }],
            riderValueLife: [{
                riderName: "",
                riderTerm: "",
                riderSum: "",
            }],
            nomineeName: "",
            nomineeNameError: "",
            nomineeRelationshipValue: "",
            nomineeRelationshipError: "",
            dateOfBirth: "",
            dateOfBirthError: "",
            guardian: "",
            guardianError: "",
            nomineeGuardianVisible: false,
            nomineePercentage: 100,
            nomineePercentageError: "",
            nomineeList: [],
            nomineePercentageCalculate: 0,
            nomineeButtonVisible: true,
            policyForError: "",
            policyForLabel: "",
            name: "", nameError: "",
            genderError: "",
            genderLabel: "",
            informationDateOfBirthError: "",
            informationDateOfBirth: "",
            premiumFrequencyError: "", premiumFrequencyLabel: "",
            premiumPayingTerm: "", premiumPayingTermError: "",
            checkboxChecked: false,
            trusteeName: "", trusteeNameError: "",
            trusteeRelationshipError: "", trusteeRelationshipValue: "",
            trusteDateOfBirthError: "", trusteeDateOfBirth: "",
            trusteeList: [],
            beneficiaryName: "", beneficiaryNameError: "",
            beneficiaryRelationshipError: "", beneficiaryRelationshipValue: "",
            beneficiaryDateOfBirthError: "", beneficiaryDateOfBirth: "",
            beneficiaryGuardian: "", beneficiaryGuardianError: "",
            beneficiaryGuardianVisible: false,
            beneficiaryPercentageError: "",
            beneficiaryPercentage: 100,
            beneficiaryPercentageCalculate: 0,
            beneficiaryList: [],
            beneficiaryButtonVisible: true,
            asOnDateError: "", asOnDate: moment().format("DD MMM YYYY"),
            schemeNameError: "", schemeNamelabel: "",
            units: "", unitsError: "",
            latestNAV: "", latestNAVError: "",
            latestValue: "", latestValueError: "",
            uLIPList: [],
        })
        this.riderListValue(); // RiderList Value to do empty
    }

    // Update Cancel
    updateCancel = () => {
        this.props.history.push({
            pathname: labels.Path.Insurance,
            state: { flag: this.state.flag }
        })
    }

    // onChange CheckBox
    handleCheckbox = () => {
        this.setState({
            checkboxChecked: !this.state.checkboxChecked
        }, () => {
            if (this.state.checkboxChecked == false) {
                this.setState({
                    trusteeList: [],
                    beneficiaryList: [],
                    beneficiaryButtonVisible: true,
                    trusteeNameError: "",
                    trusteeRelationshipError: "",
                    trusteDateOfBirthError: "",
                    beneficiaryNameError: "",
                    beneficiaryRelationshipError: "",
                    beneficiaryDateOfBirthError: "",
                    beneficiaryGuardianError: "",
                    beneficiaryPercentageError: ""
                })
            }
        })
    }

    // Save button click
    handleSubmit = (type) => {
        var insuredValid = false;
        var policyNameValid = false;
        var policyNoValid = false;
        var policyTypeValid = false;
        var nickNameValid = false;
        var startDateValid = false;
        var coverAmountValid = false;
        var premiumAmountValid = false;
        var policyTermValid = false;
        var policyForValid = false;
        var nameValid = false;
        var genderValid = false;
        var dateOfBirthValid = false;
        var premiumFrequencyValid = false;
        var premiumPayingTermValid = false;
        var riderValid = false;

        //  var isMWPACheckboxValid = false;

        // var bankAccountValid = false;
        // var nomineeDetailsValid = false;      
        // var uLIPDetailsValid = false;

        // Insured For
        if (this.state.flag == 'Health') {
            if (this.commonFunction(this.state.insuredType)) {
                var insureData = this.state.insuredType.filter((item) => (item.visible == true))
                if (insureData.length > 0) {
                    insuredValid = true;
                    this.setState({
                        insuredTypeError: ""
                    })
                }
                else {
                    insuredValid = false;
                    this.setState({
                        insuredTypeError: labels.Insurance.selectOneInsure
                    })
                }
            }
            else {
                insuredValid = false;
                this.setState({
                    insuredTypeError: labels.Insurance.required
                })
            }
        }
        else {
            if (this.commonFunction(this.state.insuredTypeGeneral)) {
                var insureDataGeneral = this.state.insuredTypeGeneral.filter((item) => (item.visible == true))
                if (insureDataGeneral.length > 0) {
                    insuredValid = true;
                    this.setState({
                        insuredTypeError: ""
                    })
                }
                else {
                    insuredValid = false;
                    this.setState({
                        insuredTypeError: labels.Insurance.selectOneInsure
                    })
                }
            }
            else {
                insuredValid = false;
                this.setState({
                    insuredTypeError: labels.Insurance.required
                })
            }
        }

        // Policy Name
        if (this.commonFunction(this.state.policyName)) {
            policyNameValid = true;
            this.setState({
                policyNameError: ""
            })
        }
        else {
            policyNameValid = false;
            this.setState({
                policyNameError: labels.Insurance.required
            })
        }

        // Policy No
        if (this.commonFunction(this.state.policyNo)) {
            policyNoValid = true;
            this.setState({
                policyNoError: ""
            })
        }
        else {
            policyNoValid = false;
            this.setState({
                policyNoError: labels.Insurance.required
            })
        }

        // Policy Type
        if (this.commonFunction(this.state.policyTypeName)) {
            policyTypeValid = true;
            this.setState({
                policyTypeError: ""
            })
        }
        else {
            policyTypeValid = false;
            this.setState({
                policyTypeError: labels.Insurance.required
            })
        }

        // Nick Name
        if (this.commonFunction(this.state.nickName)) {
            nickNameValid = true;
            this.setState({
                nickNameError: ""
            })
        }
        else {
            nickNameValid = false;
            this.setState({
                nickNameError: labels.Insurance.required
            })
        }

        // Policy For 
        if (this.commonFunction(this.state.policyForLabel)) {
            policyForValid = true;
            this.setState({
                policyForError: ""
            })
        }
        else {
            policyForValid = false;
            this.setState({
                policyForError: labels.Insurance.required
            })
        }

        // Name
        if (this.commonFunction(this.state.name)) {
            nameValid = true;
            this.setState({
                nameError: ""
            })
        }
        else {
            nameValid = false;
            this.setState({
                nameError: labels.Insurance.required
            })
        }

        // Gender
        if (this.commonFunction(this.state.genderLabel)) {
            genderValid = true;
            this.setState({
                genderError: "",
            })
        }
        else {
            genderValid = false;
            this.setState({
                genderError: labels.Insurance.required,
            })
        }

        // Life Insurance Information Date of Birth
        if (this.commonFunction(this.state.informationDateOfBirth)) {
            dateOfBirthValid = true;
            this.setState({
                informationDateOfBirthError: "",
            })
        }
        else {
            dateOfBirthValid = false;
            this.setState({
                informationDateOfBirthError: labels.Insurance.required,
            })
        }

        // Start Date
        if (this.commonFunction(this.state.startDate)) {
            startDateValid = true;
            this.setState({
                startDateError: ""
            })
        }
        else {
            startDateValid = false;
            this.setState({
                startDateError: labels.Insurance.required
            })
        }

        // Cover Amount
        if (this.commonFunction(this.state.coverAmount)) {
            if (this.state.coverAmount > 9999) {
                coverAmountValid = true;
                this.setState({
                    coverAmountError: ""
                })
            }
            else {
                coverAmountValid = false;
                this.setState({
                    coverAmountError: labels.Insurance.minimum10000

                })
            }

        }
        else {
            coverAmountValid = false;
            this.setState({
                coverAmountError: labels.Insurance.required
            })
        }

        // Premium Amount
        if (this.commonFunction(this.state.premiumAmount)) {
            if (this.state.premiumAmount > 999) {
                premiumAmountValid = true;
                this.setState({
                    premiumAmountError: ""
                })
            }
            else {
                premiumAmountValid = false;
                this.setState({
                    premiumAmountError: labels.Insurance.minimum1000
                })
            }

        }
        else {
            premiumAmountValid = false;
            this.setState({
                premiumAmountError: labels.Insurance.required
            })
        }

        // Policy Term
        if (this.commonFunction(this.state.policyTerm)) {
            policyTermValid = true;
            this.setState({
                policyTermError: ""
            })
        }
        else {
            policyTermValid = false;
            this.setState({
                policyTermError: labels.Insurance.required
            })
        }

        // Premium Frequency
        if (this.commonFunction(this.state.premiumFrequencyLabel)) {
            premiumFrequencyValid = true;
            this.setState({
                premiumFrequencyError: ""
            })
        }
        else {
            premiumFrequencyValid = false;
            this.setState({
                premiumFrequencyError: labels.Insurance.required
            })
        }

        // Premium Paying Term
        if (this.commonFunction(this.state.premiumPayingTerm)) {
            premiumPayingTermValid = true;
            this.setState({
                premiumPayingTermError: ""
            })
        }
        else {
            premiumPayingTermValid = false;
            this.setState({
                premiumPayingTermError: labels.Insurance.required
            })
        }



        // // Bank Account
        // if (this.commonFunction(this.state.bankAccount)) {
        //     bankAccountValid = true;
        //     this.setState({
        //         bankAccountError: ""
        //     })
        // }
        // else {
        //     bankAccountValid = false;
        //     this.setState({
        //         bankAccountError: labels.Insurance.required
        //     })
        // }


        // RIDERS
        if (this.state.flag == "Health") {
            if (this.commonFunction(this.state.riderValue)) {
                this.state.riderValue.map((item) => {
                    if (item.riderName == "" && item.riderFor == "" && (item.riderSum == "" || item.riderSum == "0")) {
                        this.setState({
                            ridersListError: ""
                        })
                        riderValid = true;
                    }
                    else {
                        if (item.riderName != "" && item.riderFor != "" && item.riderSum != "") {
                            this.setState({
                                ridersListError: ""
                            })
                            riderValid = true;
                        }
                        else {
                            this.setState({
                                ridersListError: labels.Insurance.allFieldsMandatory
                            })
                            riderValid = false;
                        }

                    }
                })
            }
            else {
                this.setState({
                    ridersListError: ""
                })
                riderValid = true;
            }
        }
        else if (this.state.flag == "General") {
            if (this.commonFunction(this.state.riderValueGeneral)) {
                this.state.riderValueGeneral.map((item) => {
                    if (item.riderName == "" && item.riderSum == "") {
                        this.setState({
                            ridersListError: ""
                        })
                        riderValid = true;
                    }
                    else {
                        if (item.riderName != "" && item.riderSum != "") {
                            this.setState({
                                ridersListError: ""
                            })
                            riderValid = true;
                        }
                        else {
                            this.setState({
                                ridersListError: labels.Insurance.allFieldsMandatory
                            })
                            riderValid = false;
                        }

                    }
                })
            }
            else {
                this.setState({
                    ridersListError: ""
                })
                riderValid = true;
            }
        }
        else {
            if (this.commonFunction(this.state.riderValueLife)) {
                this.state.riderValueLife.map((item) => {
                    if (item.riderName == "" && item.riderTerm == "" && item.riderSum == "") {
                        this.setState({
                            ridersListError: ""
                        })
                        riderValid = true;
                    }
                    else {
                        if (item.riderName != "" && item.riderTerm != "" && item.riderSum != "") {
                            this.setState({
                                ridersListError: ""
                            })
                            riderValid = true;
                        }
                        else {
                            this.setState({
                                ridersListError: labels.Insurance.allFieldsMandatory
                            })
                            riderValid = false;
                        }

                    }
                })
            }
            else {
                this.setState({
                    ridersListError: ""
                })
                riderValid = true;
            }
        }

        // Nominee Details
        // if (this.state.nomineeList.length > 0 && this.state.nomineePercentageCalculate == 100) {
        //     nomineeDetailsValid = true;
        //     this.setState({
        //         nomineeNameError: "", nomineeRelationshipError: "", dateOfBirthError: "",
        //         guardianError: "", nomineePercentageError: ""
        //     })
        // }
        // else {
        //     nomineeDetailsValid = false;

        //     if (this.state.nomineePercentageCalculate != 0) {

        //         // Nominee Name
        //         if (this.commonFunction(this.state.nomineeName)) {
        //             this.setState({ nomineeNameError: "" })
        //         }
        //         else {
        //             this.setState({ nomineeNameError: labels.Insurance.required })
        //         }
        //         // Nominee Relationship
        //         if (this.commonFunction(this.state.nomineeRelationshipValue)) {
        //             this.setState({ nomineeRelationshipError: "" })
        //         }
        //         else {
        //             this.setState({ nomineeRelationshipError: labels.Insurance.required })
        //         }
        //         // Date of Birth
        //         if (this.commonFunction(this.state.dateOfBirth)) {
        //             this.setState({ dateOfBirthError: "" })
        //         }
        //         else {
        //             this.setState({ dateOfBirthError: labels.Insurance.required })
        //         }
        //         // Guardian
        //         if (this.commonFunction(this.state.guardian)) {
        //             this.setState({ guardianError: "" })
        //         }
        //         else {
        //             this.setState({ guardianError: labels.Insurance.required })
        //         }

        //         if (this.state.nomineePercentageCalculate < 100) {
        //             this.setState({
        //                 nomineePercentageError: "Nominee total percentage should be 100%"
        //             })
        //         }
        //         else {
        //             this.setState({
        //                 nomineePercentageError: "Nominee total percentage should be 100%",
        //                 nomineeNameError: "",
        //                 nomineeRelationshipError: "",
        //                 dateOfBirthError: "",
        //                 guardianError: ""
        //             })
        //         }
        //     }
        //     else {
        //         // Nominee Name
        //         if (this.commonFunction(this.state.nomineeName)) {
        //             this.setState({ nomineeNameError: "" })
        //         }
        //         else {
        //             this.setState({ nomineeNameError: labels.Insurance.required })
        //         }
        //         // Nominee Relationship
        //         if (this.commonFunction(this.state.nomineeRelationshipValue)) {
        //             this.setState({ nomineeRelationshipError: "" })
        //         }
        //         else {
        //             this.setState({ nomineeRelationshipError: labels.Insurance.required })
        //         }
        //         // Date of Birth
        //         if (this.commonFunction(this.state.dateOfBirth)) {
        //             this.setState({ dateOfBirthError: "" })
        //         }
        //         else {
        //             this.setState({ dateOfBirthError: labels.Insurance.required })
        //         }
        //         // Guardian
        //         if (this.commonFunction(this.state.guardian)) {
        //             if (this.state.nomineeList.length <= 0) {
        //                 this.setState({ guardianError: "Please add atleast one nominee" })
        //             }
        //             else {
        //                 this.setState({ guardianError: "" })
        //             }

        //         }
        //         else {
        //             this.setState({ guardianError: labels.Insurance.required })
        //         }
        //         // Nominee Percentage
        //         if (this.commonFunction(this.state.nomineePercentage)) {
        //             if (this.state.nomineePercentage > 100) {
        //                 this.setState({ nomineePercentageError: "Nominee total percentage should be 100%" })
        //             }
        //             else if (this.state.nomineeGuardianVisible == false) {
        //                 if (this.state.nomineeList.length <= 0) {
        //                     this.setState({ nomineePercentageError: "Please add atleast one nominee" })
        //                 }
        //             }
        //             else {
        //                 this.setState({ nomineePercentageError: "" })
        //             }
        //         }
        //         else {
        //             this.setState({ nomineePercentageError: labels.Insurance.required })
        //         }
        //     }
        // }

        // Is MWPA checkbox
        // if (this.state.checkboxChecked == true) {
        //     if ((this.state.beneficiaryList.length > 0 && this.state.beneficiaryPercentageCalculate == 100) || (this.state.trusteeList.length > 0)) {
        //         isMWPACheckboxValid = true;
        //         this.setState({
        //             beneficiaryNameError: "", beneficiaryRelationshipError: "", beneficiaryDateOfBirthError: "",
        //             beneficiaryGuardianError: "", beneficiaryPercentageError: "",
        //             trusteeNameError: "", trusteeRelationshipError: "", trusteDateOfBirthError: ""
        //         })
        //     }
        //     else {
        //         isMWPACheckboxValid = false;
        //         // Trustee Details
        //         if (this.state.trusteeList == undefined || this.state.trusteeList == "" || this.state.trusteeList == null || this.state.trusteeList.length == 0) {

        //             // Trustee Name
        //             if (this.commonFunction(this.state.trusteeName)) {
        //                 if (this.state.trusteeList.length <= 0) {
        //                     this.setState({ trusteeNameError: "Please add atleast one beneficiary" })
        //                 }
        //                 else {
        //                     this.setState({ trusteeNameError: "" })
        //                 }
        //             }
        //             else {
        //                 this.setState({ trusteeNameError: labels.Insurance.required })
        //             }
        //             // Trustee Relationship
        //             if (this.commonFunction(this.state.trusteeRelationshipValue)) {
        //                 this.setState({ trusteeRelationshipError: "" })
        //             }
        //             else {
        //                 this.setState({ trusteeRelationshipError: labels.Insurance.required })
        //             }
        //             // Trustee Date of Birth
        //             if (this.commonFunction(this.state.trusteeDateOfBirth)) {
        //                 this.setState({ trusteDateOfBirthError: "" })
        //             }
        //             else {
        //                 this.setState({ trusteDateOfBirthError: labels.Insurance.required })
        //             }
        //         }

        //         // Beneficiary Details
        //         if (this.state.beneficiaryList == undefined || this.state.beneficiaryList == "" || this.state.beneficiaryList == null || this.state.beneficiaryList.length == 0 || this.state.beneficiaryPercentageCalculate != 100) {

        //             if (this.state.beneficiaryPercentageCalculate != 0) {

        //                 // Beneficiary Name
        //                 if (this.commonFunction(this.state.beneficiaryName)) {
        //                     this.setState({ beneficiaryNameError: "" })
        //                 }
        //                 else {
        //                     this.setState({ beneficiaryNameError: labels.Insurance.required })
        //                 }
        //                 // Beneficiary Relationship
        //                 if (this.commonFunction(this.state.beneficiaryRelationshipValue)) {
        //                     this.setState({ beneficiaryRelationshipError: "" })
        //                 }
        //                 else {
        //                     this.setState({ beneficiaryRelationshipError: labels.Insurance.required })
        //                 }
        //                 // Beneficiary Date of Birth
        //                 if (this.commonFunction(this.state.beneficiaryDateOfBirth)) {
        //                     this.setState({ beneficiaryDateOfBirthError: "" })
        //                 }
        //                 else {
        //                     this.setState({ beneficiaryDateOfBirthError: labels.Insurance.required })
        //                 }
        //                 // Beneficiary Guardian
        //                 if (this.commonFunction(this.state.beneficiaryGuardian)) {
        //                     this.setState({ beneficiaryGuardianError: "" })
        //                 }
        //                 else {
        //                     this.setState({ beneficiaryGuardianError: labels.Insurance.required })
        //                 }

        //                 if (this.state.beneficiaryPercentageCalculate < 100) {
        //                     this.setState({
        //                         beneficiaryPercentageError: "Beneficiary total percentage should be 100%"
        //                     })
        //                 }
        //                 else {
        //                     this.setState({
        //                         beneficiaryPercentageError: "Beneficiary total percentage should be 100%",
        //                         // beneficiaryNameError: "",
        //                         // beneficiaryRelationshipError: "",
        //                         // beneficiaryDateOfBirthError: "",
        //                         // beneficiaryGuardianError: ""
        //                     })
        //                 }
        //             }
        //             else {
        //                 // Beneficiary Name
        //                 if (this.commonFunction(this.state.beneficiaryName)) {
        //                     this.setState({ beneficiaryNameError: "" })
        //                 }
        //                 else {
        //                     this.setState({ beneficiaryNameError: labels.Insurance.required })
        //                 }
        //                 // Beneficiary Relationship
        //                 if (this.commonFunction(this.state.beneficiaryRelationshipValue)) {
        //                     this.setState({ beneficiaryRelationshipError: "" })
        //                 }
        //                 else {
        //                     this.setState({ beneficiaryRelationshipError: labels.Insurance.required })
        //                 }
        //                 // Beneficiary Date of Birth
        //                 if (this.commonFunction(this.state.beneficiaryDateOfBirth)) {
        //                     this.setState({ beneficiaryDateOfBirthError: "" })
        //                 }
        //                 else {
        //                     this.setState({ beneficiaryDateOfBirthError: labels.Insurance.required })
        //                 }
        //                 // Beneficiary Guardian
        //                 if (this.commonFunction(this.state.beneficiaryGuardian)) {
        //                     if (this.state.beneficiaryList.length <= 0) {
        //                         this.setState({ beneficiaryGuardianError: "Please add atleast one beneficiary" })
        //                     }
        //                     else {
        //                         this.setState({ beneficiaryGuardianError: "" })
        //                     }
        //                 }
        //                 else {
        //                     this.setState({ beneficiaryGuardianError: labels.Insurance.required })
        //                 }
        //                 // Beneficiary Percentage
        //                 if (this.commonFunction(this.state.beneficiaryPercentage)) {
        //                     if (this.state.beneficiaryPercentage > 100) {
        //                         this.setState({ beneficiaryPercentageError: "Beneficiary total percentage should be 100%" })
        //                     }
        //                     else if (this.state.beneficiaryGuardianVisible == false) {
        //                         if (this.state.beneficiaryList.length <= 0) {
        //                             this.setState({ beneficiaryPercentageError: "Please add atleast one beneficiary" })
        //                         }
        //                     }
        //                     else {
        //                         this.setState({ beneficiaryPercentageError: "" })
        //                     }
        //                 }
        //                 else {
        //                     this.setState({ beneficiaryPercentageError: labels.Insurance.required })
        //                 }
        //             }
        //         }
        //     }
        // }
        // else {
        //     isMWPACheckboxValid = true;
        // }

        // ULIP Details
        // if (this.commonFunction(this.state.uLIPList) && this.state.uLIPList.length > 0) {
        //     uLIPDetailsValid = true;
        //     this.setState({
        //         asOnDateError: "", schemeNameError: "", unitsError: "", latestNAVError: "", latestValueError: ""
        //     })
        // }
        // else {
        //     uLIPDetailsValid = false;
        //     // ULIP AS ON DATE
        //     if (this.commonFunction(this.state.asOnDate)) {
        //         this.setState({ asOnDateError: "", })
        //     }
        //     else {
        //         this.setState({ asOnDateError: labels.Insurance.required, })
        //     }
        //     // ULIP SCHEME NAME
        //     if (this.commonFunction(this.state.schemeNamelabel)) {
        //         this.setState({ schemeNameError: "", })
        //     }
        //     else {
        //         this.setState({ schemeNameError: labels.Insurance.required, })
        //     }

        //     // ULIP LATEST VALUE
        //     if (this.commonFunction(this.state.latestValue)) {
        //         if (this.state.uLIPList.length <= 0) {
        //             this.setState({ latestValueError: "Please add atleast one ULIP" })
        //         }
        //         else {
        //             this.setState({ latestValueError: "", })
        //         }

        //     }
        //     else {
        //         this.setState({ latestValueError: labels.Insurance.required, })
        //     }
        // }

        this.setState({
            trusteeName: "", trusteeNameError: "", trusteeRelationshipError: "", trusteeRelationshipValue: "",
            trusteDateOfBirthError: "", trusteeDateOfBirth: "",

            beneficiaryName: "", beneficiaryNameError: "", beneficiaryRelationshipError: "", beneficiaryRelationshipValue: "",
            beneficiaryDateOfBirthError: "", beneficiaryDateOfBirth: "", beneficiaryGuardian: "",
            beneficiaryGuardianError: "", beneficiaryGuardianVisible: false, beneficiaryPercentageError: "",

            schemeNameError: "", schemeNamelabel: "", units: "", unitsError: "", latestNAV: "", latestNAVError: "",
            latestValue: "", latestValueError: "",

            nomineeName: "", nomineeNameError: "", nomineeRelationshipValue: "", nomineeRelationshipError: "",
            dateOfBirth: "", dateOfBirthError: "", guardian: "", guardianError: "", nomineeGuardianVisible: false, nomineePercentageError: "",

            // ridersListError: "",
        })


        var isValid = false;

        if (this.state.flag == "Health") {
            if (insuredValid && policyNameValid && policyNoValid && policyTypeValid && startDateValid && coverAmountValid && premiumAmountValid && policyTermValid
                && riderValid
                // && bankAccountValid && nomineeDetailsValid
            ) {
                isValid = true;
            }
            else {
                isValid = false;
            }
        }
        else if (this.state.flag == "General") {
            if (insuredValid && policyNameValid && policyNoValid && nickNameValid && startDateValid && coverAmountValid && premiumAmountValid && policyTermValid
                && riderValid
                // && bankAccountValid && nomineeDetailsValid
            ) {
                isValid = true;
            }
            else {
                isValid = false;
            }
        }
        else {
            if (policyNameValid && policyNoValid && policyForValid && nameValid && genderValid && dateOfBirthValid &&
                startDateValid && coverAmountValid && premiumAmountValid && policyTermValid && premiumFrequencyValid && premiumPayingTermValid
                && riderValid
                // bankAccountValid && nomineeDetailsValid && uLIPDetailsValid &&  isMWPACheckboxValid               
            ) {
                isValid = true;
            }
            else {
                isValid = false;
            }
        }

        // API Integration
        if (isValid) {
            var insuredFilter;
            var insuredData;
            if (this.state.flag == "Health") {
                if (this.commonFunction(this.state.insuredType)) {
                    insuredFilter = this.state.insuredType.filter(item => (item.visible == true))
                    if (insuredFilter.length > 0) {
                        insuredData = insuredFilter.map((item) => {
                            return {
                                InsuredFor: item.ID
                            }
                        })
                    }
                }
            }
            else if (this.state.flag == "General") {
                if (this.commonFunction(this.state.insuredTypeGeneral)) {
                    insuredFilter = this.state.insuredTypeGeneral.filter(item => (item.visible == true))
                    if (insuredFilter.length > 0) {
                        insuredData = insuredFilter.map((item) => {
                            return {
                                InsuredFor: item.ID
                            }
                        })
                    }
                }
            }

            var nomineeListJson;
            if (this.state.flag == "Life") {
                if (this.commonFunction(this.state.nomineeList)) {
                    nomineeListJson = this.state.nomineeList.map((item) => {
                        return {
                            NomineeName: item.nomineeName.name,
                            NomineeRelationshipID: item.nomineeRelationshipID.name,
                            NomineeDOB: item.dateOfBirth.name,
                            GuardianName: item.guardian.name,
                            Percentage: item.nomineePercentage.name,
                            NomineeTypeId: 1,
                            IsMinor: item.nomineeType.name
                        }
                    })
                }
                else {
                    nomineeListJson = null;
                }
            }
            else {
                if (this.commonFunction(this.state.nomineeList)) {
                    nomineeListJson = this.state.nomineeList.map((item) => {
                        return {
                            nomineeName: item.nomineeName.name,
                            nomineeRelationshipID: item.nomineeRelationshipID.name,
                            dateOfBirth: item.dateOfBirth.name,
                            guardian: item.guardian.name,
                            nomineePercentage: item.nomineePercentage.name,
                            // nomineeType: item.nomineeType.name
                            nomineeType: 1
                        }
                    })
                }
                else {
                    nomineeListJson = null;
                }
            }

            var trusteeListJson;
            var beneficiaryListJson;
            var uLIPListJson;
            if (this.state.flag == "Life") {
                if (this.commonFunction(this.state.trusteeList)) {
                    trusteeListJson = this.state.trusteeList.map((item) => {
                        return {
                            TrusteeName: item.trusteeName.name,
                            TrusteeRelationshipID: item.trusteeRelationshipID.name,
                            TrusteeDOB: item.trusteeDateOfBirth.name,
                        }
                    })
                }
                if (this.commonFunction(this.state.beneficiaryList)) {
                    beneficiaryListJson = this.state.beneficiaryList.map((item) => {
                        return {
                            BeneficiaryName: item.beneficiaryName.name,
                            BeneficiaryRelationshipID: item.beneficiaryRelationshipID.name,
                            DOB: item.beneficiaryDateOfBirth.name,
                            BeneficiaryGuardianName: item.beneficiaryGuardian.name,
                            BeneficiaryPercentage: item.beneficiaryPercentage.name,
                        }
                    })
                }
                if (this.commonFunction(this.state.uLIPList)) {
                    uLIPListJson = this.state.uLIPList.map((item) => {
                        return {
                            SchemeNameID: item.schemeNameID.name,
                            Units: item.units.name,
                            LatestNAV: item.latestNAV.name,
                            LatestValue: item.latestValue.name,
                            ASonDate: item.asOnDate.name,
                        }
                    })
                }
            }

            var riderLife;
            if (this.commonFunction(this.state.riderValueLife)) {
                riderLife = this.state.riderValueLife.map((item) => {
                    return {
                        RiderNameID: item.riderName,
                        RiderSumAssured: item.riderSum,
                        RiderTerm: item.riderTerm
                    }
                })
            }

            let isValidRider = true;
            if (this.state.flag == "Health") {
                if (this.commonFunction(this.state.riderValue)) {
                    isValidRider = this.state.riderValue.some(hasNull) === true ? false : true
                }
                else {
                    isValidRider = false;
                }
            }
            else if (this.state.flag == "General") {
                if (this.commonFunction(this.state.riderValueGeneral)) {
                    isValidRider = this.state.riderValueGeneral.some(hasNull) === true ? false : true
                }
                else {
                    isValidRider = false;
                }
            }
            else {
                if (this.commonFunction(this.state.riderValueLife)) {
                    isValidRider = this.state.riderValueLife.some(hasNull) === true ? false : true
                }
                else {
                    isValidRider = false;
                }
            }

            if (this.state.flag == "Life") {
                var dataLife = {
                    id: this.state.EditID == 0 ? 0 : this.state.EditID,
                    createdBy: this.props.clientID,
                    name: this.state.name,
                    clientID: this.props.clientID,
                    accountTypeID: this.props.logindetails.L4_AccountTypeID,
                    accountID: this.props.L5ID,
                    bankAccountID: this.state.bank == "" ? 0 : this.state.bank,
                    policyID: this.state.policy,
                    policyNumber: this.state.policyNo,
                    policyForID: this.state.policyFor,
                    genderID: this.state.gender,
                    dob: this.state.informationDateOfBirth,
                    startDate: this.state.startDate,
                    premiumFrequencyID: this.state.premiumFrequency,
                    policyTerm: Number(this.state.policyTerm),
                    payingTerm: Number(this.state.premiumPayingTerm),
                    coverAmount: Number(this.state.coverAmount),
                    premiumAmount: Number(this.state.premiumAmount),
                    trusteeParam: this.state.trusteeList == "" ? null : JSON.stringify(trusteeListJson),
                    nomineeParam: this.state.nomineeList == "" ? null : JSON.stringify(nomineeListJson),
                    riderParam: isValidRider == true ? JSON.stringify(riderLife) : null,
                    ulipParam: this.state.uLIPList == "" ? null : JSON.stringify(uLIPListJson),
                    beneficiaryParam: this.state.beneficiaryList == "" ? null : JSON.stringify(beneficiaryListJson),
                    IsMWPA: this.state.checkboxChecked,
                    flag: this.state.EditID == 0 ? 1 : 2 // Add flag - 1 , Update flag - 2
                }


                if (type == "save") {
                    this.setState({ loader: true })
                } else {
                    this.setState({ addLoader: true })
                }

                getAddUpdateDeleteInsurance(dataLife).then((resp) => {
                    if (type == "save") {
                        this.setState({ loader: false })
                    } else {
                        this.setState({ addLoader: false })
                    }

                    if (resp.status == "S") {
                        this.setState({
                            openSnackBar: true, severity: 'success', message: resp.message
                        })

                        timerx = setTimeout(() => {
                            if (this.state.EditID == 0) {
                                this.empty();
                                // Insured Type and Insured Type General Button List
                                // this.insureTypeDropdown();
                            }
                            else {
                                this.props.history.push({
                                    pathname: labels.Path.Insurance,
                                    state: { flag: this.state.flag }
                                })
                                this.empty();
                                // Insured Type and Insured Type General Button List
                                // this.insureTypeDropdown();;
                            }

                            if (type == "save") {
                                this.props.history.push({
                                    pathname: labels.Path.Insurance,
                                    state: { flag: this.state.flag }
                                })
                            }
                        }, 2000);
                    }
                    else {
                        this.setState({ openSnackBar: true, severity: 'warning', message: resp.message })
                        if (this.state.EditID == 0) {
                            this.empty();
                            // Insured Type and Insured Type General Button List
                            // this.insureTypeDropdown();
                        }
                        else {
                            this.updateCancel();
                        }
                    }
                }).catch((err) => {
                    if (type == "save") {
                        this.setState({ loader: false })
                    } else {
                        this.setState({ addLoader: false })
                    }
                    // this.setState({ loader: false })
                    this.setState({ openSnackBar: true, severity: 'warning', message: labels.messages.apierror })
                    this.empty();
                    // Insured Type and Insured Type General Button List
                    // this.insureTypeDropdown();

                })
            }
            else {
                var data = {
                    id: this.state.EditID == 0 ? 0 : this.state.EditID,
                    clientID: this.props.clientID,
                    insuranceType: this.state.flag == "Health" ? 1 : 2,
                    policyID: this.state.policy,
                    policyNo: this.state.policyNo,
                    policyTypeID: this.state.policyType != "" ? this.state.policyType : 0,
                    nickName: this.state.nickName,
                    isuuerIDs: JSON.stringify(insuredData),
                    startDate: this.state.startDate,
                    coverAmount: Number(this.state.coverAmount),
                    premiumAmount: Number(this.state.premiumAmount),
                    policyTerm: String(this.state.policyTerm),
                    bankDetailsID: this.state.bank == "" ? 0 : this.state.bank,
                    ridersTable: this.state.flag == "Health" ? isValidRider == true ? JSON.stringify(this.state.riderValue) : null : isValidRider == true ? JSON.stringify(this.state.riderValueGeneral) : null,
                    nomineeTable: this.state.nomineeList == "" ? null : JSON.stringify(nomineeListJson),
                    accountID: this.props.L5ID,
                    accountTypeID: this.props.logindetails.L4_AccountTypeID,
                    flag: this.state.EditID == 0 ? 1 : 2 // Add flag - 1 , Update flag - 2
                }


                if (type == "save") {
                    this.setState({ loader: true })
                } else {
                    this.setState({ addLoader: true })
                }

                try {
                    getAddInsurance(data).then((resp) => {
                        if (type == "save") {
                            this.setState({ loader: false })
                        } else {
                            this.setState({ addLoader: false })
                        }
                        if (resp.status == "S") {
                            this.setState({
                                openSnackBar: true, severity: 'success', message: resp.message
                            })

                            timery = setTimeout(() => {
                                if (this.state.EditID == 0) {
                                    this.empty();
                                    // Insured Type and Insured Type General Button List
                                    this.insureTypeDropdown();
                                }
                                else {
                                    this.props.history.push({
                                        pathname: labels.Path.Insurance,
                                        state: { flag: this.state.flag }
                                    })
                                    this.empty();
                                    // Insured Type and Insured Type General Button List
                                    this.insureTypeDropdown();
                                }

                                if (type == "save") {
                                    this.props.history.push({
                                        pathname: labels.Path.Insurance,
                                        state: { flag: this.state.flag }
                                    })
                                }
                            }, 1000);
                        }
                        else {
                            this.setState({ openSnackBar: true, severity: 'warning', message: resp.message })
                            if (this.state.EditID == 0) {
                                this.empty();
                                // Insured Type and Insured Type General Button List
                                this.insureTypeDropdown();
                            }
                            else {
                                this.updateCancel();
                            }
                        }
                    }).catch((err) => {
                        // console.log(err)
                        if (type == "save") {
                            this.setState({ loader: false })
                        } else {
                            this.setState({ addLoader: false })
                        }
                        // this.setState({ loader: false })
                        this.setState({ openSnackBar: true, severity: 'warning', message: labels.messages.apierror })
                        this.empty();
                        // Insured Type and Insured Type General Button List
                        this.insureTypeDropdown();

                    })
                } catch (err) {
                    // console.log(err)
                }
            }


        }

    }
    componentWillUnmount() {
        clearTimeout(timerx)
        clearTimeout(timery)
    }
    render() {
        const { classes } = this.props;
        try {
            return (
                <ErrorBoundary>
                    <Subheader heading={labels.productList.healthInsurance} description={labels.Transact.healthInsurance} />
                    <div className={classes.flex}>
                        <div className={classes.sideSpace}></div>
                        <div className={classes.mainCard}>
                            <div className={classes.marginTopAlignment}>
                                {/* <Three_State_Toggle_Switch
                                    values={this.state.values}
                                    handleChangeToggle={(val) => { this.toggleChange(val) }}
                                    selectedFlag={this.state.flag}
                                /> */}
                                {/* <Toggle
                                    label1={"Health"}
                                    label2={"General"}
                                    selected={this.state.selected}
                                    toggleSelected={this.toggleChange}
                                    disable={this.state.EditID == 0 ? false : true}
                                /> */}
                            </div>
                            <div className={classes.header}> {this.state.EditID == 0 ? this.state.flag == "Health" ? labels.productList.healthHeader : this.state.flag == "General" ? labels.productList.generalHeader : labels.productList.lifeHeader : this.state.flag == "Health" ? labels.productList.healthHeaderUpdate : this.state.flag == "General" ? labels.productList.generalHeaderUpdate : labels.productList.lifeHeaderUpdate} </div>
                            <div className={classes.cardSpareaterLine}></div>
                            {this.state.flag != "Life" &&
                                <>
                                    {/* INSURED FOR */}
                                    <div className={classes.insuredLabel}>{labels.Insurance.insuredFor}</div>
                                    <div className={classes.insuredTypeTextboxRow}>
                                        {this.commonFunction(this.state.flag == "Health" ? this.state.insuredType : this.state.insuredTypeGeneral) && (this.state.flag == "Health" ? this.state.insuredType : this.state.insuredTypeGeneral).map((item) => {
                                            return (
                                                <>
                                                    {this.state.EditID == 0 ?
                                                        <>
                                                            {item.visible ?
                                                                <>
                                                                    <div className={classes.insuredTypeTextboxSecond} onClick={() => { this.insuredTypeClickTik(item.ID) }}>
                                                                        <div className={classes.insuredText}>{item.InsuredFor}</div>
                                                                        <div className={classes.insuredTextImage}><img src={Selectedcheckbox} width={16} /></div>
                                                                    </div>

                                                                </>
                                                                :
                                                                <div className={[classes.insuredTypeTextbox]} onClick={() => this.insuredTypeClick(item.ID)} >{item.InsuredFor}</div>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {item.visible ?
                                                                <div className={classes.insuredTypeTextboxSecond} >
                                                                    <div className={classes.insuredText}>{item.InsuredFor}</div>
                                                                    <div className={classes.insuredTextImage}><img src={Selectedcheckbox} width={16} /></div>
                                                                </div>
                                                                :
                                                                <div className={[classes.insuredTypeTextboxDisable]}>{item.InsuredFor}</div>
                                                            }
                                                        </>
                                                    }
                                                </>

                                            )
                                        })}
                                    </div>
                                    <div style={{ display: "contents" }}>
                                        <span style={{ fontSize: 13, color: window.globalConfig.color.red, textAlign: "left" }}> {this.state.insuredTypeError}</span>
                                    </div>
                                    <div className={classes.firstRow}></div>
                                </>
                            }

                            <div className={classes.grid}>
                                {/* POLICY NAME */}
                                {this.state.EditID == 0 ?
                                    <CommonDropdown
                                        //  autoselect={true}
                                        handleOnChange={this.handlePolicyNameChange}
                                        value={this.state.policyName}
                                        isSearchable={true}
                                        error={this.state.policyNameError}
                                        type={"normalwithsearch"}
                                        id="ddl_policyname"
                                        placeholder={labels.Insurance.policyNamePlaceHolder}
                                        label={labels.Insurance.policyName}
                                        height={190}
                                        List={this.state.policyNameList != null && this.state.policyNameList.length != 0 ? replaceNull(this.state.policyNameList, "NA") : []}
                                    />
                                    :
                                    <Textinput
                                        value={this.state.policyName}
                                        error={this.state.policyNameError}
                                        page={"normal"} label={labels.Insurance.policyName}
                                        placeholder={labels.Insurance.policyNamePlaceHolder}
                                        otherProps={{ maxLength: 40 }}
                                        disabled={true}
                                    />
                                }

                                {/* POLICY NO */}
                                <Textinput handleChange={this.handlePolicyNoChange}
                                    value={this.state.policyNo}
                                    error={this.state.policyNoError}
                                    page={"normal"} label={labels.Insurance.polcyNo}
                                    placeholder={labels.Insurance.policyNoPlaceHolder}
                                    otherProps={{ maxLength: 40 }}
                                    disabled={this.state.EditID == 0 ? false : true}
                                />

                                {/* POLICY TYPE */}
                                {this.state.flag == "Health" &&
                                    <>
                                        {this.state.EditID == 0 ?
                                            <CommonDropdown
                                                //  autoselect={true}

                                                handleOnChange={this.handlePolicyTypeChange}
                                                value={this.state.policyTypeName}
                                                error={this.state.policyTypeError}
                                                type={"normalwithsearch"}
                                                id="ddl_policyname"
                                                placeholder={labels.Insurance.policyTypePlaceHolder}
                                                label={labels.Insurance.policyType}
                                                height={190}
                                                List={this.state.policyTypeList != null && this.state.policyTypeList.length != 0 ? replaceNull(this.state.policyTypeList, "NA") : []}
                                            />
                                            :
                                            <Textinput
                                                value={this.state.policyTypeName}
                                                error={this.state.policyTypeError}
                                                page={"normal"} label={labels.Insurance.policyType}
                                                placeholder={labels.Insurance.policyTypePlaceHolder}
                                                otherProps={{ maxLength: 40 }}
                                                disabled={true}
                                            />
                                        }
                                    </>
                                }

                                {/* POLICY For */}
                                {this.state.flag == "Life" &&
                                    <>
                                        {/* Policy For */}
                                        {this.state.EditID == 0 ?
                                            <CommonDropdown
                                                //  autoselect={true}
                                                handleOnChange={this.handlePolicyForChange}
                                                value={this.state.policyForLabel}
                                                error={this.state.policyForError}
                                                type={"normalwithsearch"}
                                                id="ddl_policyfor"
                                                placeholder={labels.Insurance.policyForPlaceHolder}
                                                label={labels.Insurance.policyFor}
                                                height={190}
                                                List={this.state.policyForList != "NA" && this.state.policyForList.length != 0 ? replaceNull(this.state.policyForList, "NA") : []}
                                            />
                                            :
                                            <Textinput
                                                value={this.state.policyForLabel}
                                                error={this.state.policyForError}
                                                page={"normal"} label={labels.Insurance.policyFor}
                                                placeholder={labels.Insurance.policyForPlaceHolder}
                                                otherProps={{ maxLength: 40 }}
                                                disabled={true}
                                            />
                                        }

                                        {/* NAME */}
                                        <div style={{ marginTop: this.state.EditID == 0 ? "0%" : "5%" }}>
                                            <Textinput handleChange={this.handleNameChange}
                                                value={this.state.name}
                                                error={this.state.nameError}
                                                page={"normal"} label={labels.Insurance.name}
                                                placeholder={labels.Insurance.namePlaceHolder}
                                                otherProps={{ maxLength: 40 }}
                                                disabled={this.state.EditID == 0 ? false : true}
                                            />
                                        </div>

                                        {/* Gender */}
                                        {this.state.EditID == 0 ?
                                            <CommonDropdown
                                                //  autoselect={true}
                                                handleOnChange={this.handleGenderChange}
                                                value={this.state.genderLabel}
                                                error={this.state.genderError}
                                                type={"normalwithsearch"}
                                                id="ddl_policygender"
                                                placeholder={labels.Insurance.genderPlaceHolder}
                                                label={labels.Insurance.gender}
                                                height={190}
                                                List={this.state.genderList != null && this.state.genderList.length != 0 ? replaceNull(this.state.genderList, "NA") : []}
                                            />
                                            :
                                            <div style={{ marginTop: "5%" }}>
                                                <Textinput
                                                    value={this.state.genderLabel}
                                                    error={this.state.genderError}
                                                    page={"normal"} label={labels.Insurance.gender}
                                                    placeholder={labels.Insurance.genderPlaceHolder}
                                                    otherProps={{ maxLength: 40 }}
                                                    disabled={true}
                                                />
                                            </div>
                                        }

                                        {/* Information DATE OF BIRTH */}
                                        {this.state.EditID == 0 ?
                                            <DatePicker
                                                handleClick={(e) => this.handleInformationDateOfBirthClick(e)}
                                                handleApply={this.handleInformationDateOfBirthApply}
                                                showingdate={this.state.informationDateOfBirthTemp}
                                                dateChange={this.handleInformationDateOfBirthChange}
                                                handleClose={this.handleInformationDateOfBirthClose}
                                                anchorEl={this.state.informationAnchorBirthDateEl}
                                                error={this.state.informationDateOfBirthError}
                                                maxdate={moment().format("DD MMM YYYY")}
                                                mindate={moment("1950/01/01").format("DD MMM YYYY")}
                                                // allowed={false}
                                                id="dp_DOB"
                                                label={labels.Insurance.dateOfBirth}
                                                placeholder={labels.Insurance.dateOfBirthPlaceHolder}
                                                date={this.state.informationDateOfBirth} />
                                            :
                                            <div style={{ marginTop: "5%" }}>
                                                <Textinput
                                                    value={this.state.informationDateOfBirth}
                                                    error={this.state.informationDateOfBirthError}
                                                    page={"normal"} label={labels.Insurance.dateOfBirth}
                                                    placeholder={labels.Insurance.dateOfBirthPlaceHolder}
                                                    otherProps={{ maxLength: 40 }}
                                                    disabled={true}
                                                />
                                            </div>
                                        }
                                    </>
                                }

                                {/* Nick Name */}
                                {this.state.flag == "General" &&
                                    <Textinput handleChange={this.handleNickNameChange}
                                        value={this.state.nickName}
                                        error={this.state.nickNameError}
                                        page={"normal"} label={labels.Insurance.nickName}
                                        placeholder={labels.Insurance.nickNamePlaceHolder}
                                        otherProps={{ maxLength: 40 }}
                                        disabled={this.state.EditID == 0 ? false : true}
                                    />
                                }

                            </div>

                            {/* TRANSACTION */}
                            <div style={{ marginTop: this.state.EditID != 0 && "20px" }}></div>
                            <div className={classes.header}> {labels.Insurance.transaction} </div>
                            <div className={classes.cardSpareaterLine}></div>

                            <div className={classes.grid}>
                                {/* START DATE */}
                                <DatePicker
                                    handleClick={(e) => this.handleStartDateClick(e)}
                                    handleApply={this.handleStartDateApply}
                                    showingdate={this.state.startDateTemp}
                                    dateChange={this.handleStartDateChange}
                                    handleClose={this.handleStartDateClose}
                                    anchorEl={this.state.anchorEl}
                                    error={this.state.startDateError}
                                    maxdate={moment().format("DD MMM YYYY")}
                                    // allowed={false}
                                    id="dp_startDate"
                                    label={labels.Insurance.startDate}
                                    placeholder={labels.Insurance.startDatePlaceHolder}
                                    date={this.state.startDate} />

                                {/* PREMIUM FREQUENCY */}
                                {this.state.flag == "Life" &&
                                    <CommonDropdown
                                        handleOnChange={this.handlePremiumFrequencyChange}
                                        value={this.state.premiumFrequencyLabel}
                                        error={this.state.premiumFrequencyError}
                                        type={"normalwithsearch"}
                                        id="ddl_premiumfrequency"
                                        placeholder={labels.Insurance.premiumFrequencyPlaceHolder}
                                        label={labels.Insurance.premiumFrequency}
                                        height={190}
                                        List={this.state.premiumFrequencyList != null && this.state.premiumFrequencyList.length != 0 ? replaceNull(this.state.premiumFrequencyList, "NA") : []}
                                    />
                                }

                                {/* COVER AMOUNT */}
                                <Textinput handleChange={this.handleCoverAmountChange}
                                    value={NegativeFinding(this.state.coverAmount, "", "", "", "number")}
                                    error={this.state.coverAmountError}
                                    page={"normal"} label={labels.Insurance.coverAmount}
                                    placeholder={labels.Insurance.zero}
                                    align={"right"}
                                    otherProps={{ maxLength: 20 }}
                                />

                                {/*PREMIUM AMOUNT */}
                                <Textinput handleChange={this.handlePremiumAmountChange}
                                    value={NegativeFinding(this.state.premiumAmount, "", "", "", "number")}
                                    error={this.state.premiumAmountError}
                                    page={"normal"} label={labels.Insurance.premiumAmount}
                                    placeholder={labels.Insurance.zero}
                                    align={"right"}
                                    otherProps={{ maxLength: 20 }}
                                />

                                {/*POLICY TERM */}
                                <Textinput handleChange={this.handlePolicyTermChange}
                                    value={this.state.policyTerm}
                                    error={this.state.policyTermError}
                                    page={"normal"} label={labels.Insurance.policyTerm}
                                    placeholder={labels.Insurance.enterYears}
                                    otherProps={{ maxLength: 2 }}
                                />

                                {/*Premium Paying Term */}
                                {this.state.flag == "Life" &&
                                    <Textinput handleChange={this.handlePremiumPayingTermChange}
                                        value={this.state.premiumPayingTerm}
                                        error={this.state.premiumPayingTermError}
                                        page={"normal"} label={labels.Insurance.premiumPayingTerm}
                                        placeholder={labels.Insurance.enterPremiumPayingTerm}
                                        otherProps={{ maxLength: 2 }}
                                    />
                                }
                            </div>

                            {/* BANK DETAILS */}
                            <div className={classes.header}> {labels.Insurance.bankDetails} </div>
                            <div className={classes.cardSpareaterLine}></div>

                            <div className={classes.grid}>
                                {/* BANK ACCOUNT */}
                                <div className={classes.AssetRow}>
                                    {this.state.EditID == 0 ?
                                        <CommonDropdown
                                            autoselect={false}
                                            isSearchable={true}
                                            handleOnChange={this.handleBankChange}
                                            value={this.state.bankAccount}
                                            error={this.state.bankAccountError}
                                            type={"normalwithsearch"}
                                            id="ddl_bankname"
                                            placeholder={labels.Insurance.bankNamePlaceHolder}
                                            label={labels.Insurance.bankAccount}
                                            height={190}
                                            List={this.state.bankAccountList != null && this.state.bankAccountList.length != 0 ? replaceNull(this.state.bankAccountList, "NA") : []}
                                        />
                                        :
                                        <Textinput
                                            value={this.state.bankAccount}
                                            error={this.state.bankAccountError}
                                            page={"normal"} label={labels.Insurance.bankAccount}
                                            placeholder={labels.Insurance.bankNamePlaceHolder}
                                            otherProps={{ maxLength: 40 }}
                                            disabled={true}
                                        />
                                    }
                                    {this.state.EditID == 0 &&
                                        <InformationTooltip Icon={<div className={classes.centerAlignment} onClick={this.handleAddClick}>
                                            <img src={PlusIcon} width="20px" height="20px" alt="AddIcon" />
                                        </div>} title="Add new bank account">

                                        </InformationTooltip>
                                    }
                                </div>
                            </div>

                            {/* RIDER / ADD-ONS */}
                            <div className={classes.riderHeader}>
                                <div className={classes.header}> {labels.Insurance.riderAddOns} </div>
                                <div onClick={() => this.addRidersRow()} className={classes.riderHeaderSecond}><span style={{ textDecoration: "none" }}>+</span> Add more Riders</div>
                            </div>

                            <div className={classes.cardSpareaterLine}></div>

                            <div className={[classes.tableHeaderInsurance, (this.state.flag == "Health" || this.state.flag == "Life") ? classes.threeColumnGrid : classes.twoColumnGrid, classes.tableMainHeader, classes.commitedInstalmentContainerWithWidth, classes.tableHeaderDisplay].join(' ')}>
                                {(this.state.flag == "Health" ? labels.Insurance.riderDataList : this.state.flag == "General" ? labels.Insurance.riderDataListGeneral : labels.Insurance.riderDataListLife).map((item) => {
                                    return <div className={item.label == "Rider Sum Assured" ? classes.amountHeaderGrid : classes.headerGrid}> {item.label}</div>
                                })}
                            </div>
                            {this.state.flag == "Health" &&
                                <Dynamic_row tooltipTitle="Delete Rider" index={3000} count={3} deletePopupPosition="insideTextBox" preventDelete={"no"} class={classes.riderGrid} data={this.state.riderValue} List={this.state.riderList} callBack={this.callBackTransaction}></Dynamic_row>
                            }
                            {
                                this.state.flag == "General" &&
                                <Dynamic_row tooltipTitle="Delete Rider" index={2000} count={2} deletePopupPosition="insideTextBox" preventDelete={"no"} class={classes.riderGridGeneral} data={this.state.riderValueGeneral} List={this.state.riderListGeneral} callBack={this.callBackTransaction}></Dynamic_row>
                            }
                            {
                                this.state.flag == "Life" &&
                                <Dynamic_row tooltipTitle="Delete Rider" index={3000} count={3} deletePopupPosition="insideTextBox" preventDelete={"no"} class={classes.riderGrid} data={this.state.riderValueLife} List={this.state.riderListLife} callBack={this.callBackTransaction}></Dynamic_row>
                            }
                            <div style={{ display: "contents" }}>
                                <span style={{ fontSize: 13, color: window.globalConfig.color.red, textAlign: "left" }}>{this.state.ridersListError}</span>
                            </div>

                            {/* NOMINEE DETAILS */}
                            <div className={classes.firstRow}></div>
                            <div className={classes.riderHeader}>
                                <div className={classes.header}> {labels.Insurance.nomineeDetails} </div>
                                {/* {this.state.nomineeButtonVisible == true &&
                                    <div onClick={() => this.addNomineeRow()} className={classes.riderHeaderSecond}><span style={{ textDecoration: "none" }}>+</span> Add more Nominee</div>
                                } */}
                            </div>
                            <div className={classes.cardSpareaterLine}></div>
                            {this.state.nomineeButtonVisible == true &&
                                <div className={classes.grid}>

                                    {/*NOMINEE NAME */}
                                    <Textinput handleChange={this.handleNomineeNameChange}
                                        value={this.state.nomineeName}
                                        error={this.state.nomineeNameError}
                                        page={"normal"} label={labels.Insurance.nomineeName}
                                        placeholder={labels.Insurance.nomineeNamePlaceHolder}
                                        otherProps={{ maxLength: 40 }}
                                    />
                                    {/*NOMINEE RELATIONSHIP */}
                                    <CommonDropdown
                                        //  autoselect={true}
                                        handleOnChange={this.handleNomineeRelationshipChange}
                                        value={this.state.nomineeRelationshipValue}
                                        error={this.state.nomineeRelationshipError}
                                        type={"normalwithsearch"}
                                        id="ddl_nomineerelationship"
                                        placeholder={labels.Insurance.nomineeRelationshipPlaceHolder}
                                        label={labels.Insurance.nomineeRelationship}
                                        height={190}
                                        List={this.state.nomineeRelationshipList != null && this.state.nomineeRelationshipList.length != 0 ? replaceNull(this.state.nomineeRelationshipList, "NA") : []}
                                    />

                                    {/* DATE OF BIRTH */}
                                    <DatePicker
                                        handleClick={(e) => this.handleDateOfBirthClick(e)}
                                        handleApply={this.handleDateOfBirthApply}
                                        showingdate={this.state.dateOfBirthTemp}
                                        dateChange={this.handleDateOfBirthChange}
                                        handleClose={this.handleDateOfBirthClose}
                                        anchorEl={this.state.anchorBirthDateEl}
                                        error={this.state.dateOfBirthError}
                                        maxdate={moment().format("DD MMM YYYY")}
                                        mindate={moment("1950/01/01").format("DD MMM YYYY")}
                                        // allowed={false}
                                        id="dp_dateofbirth"
                                        label={labels.Insurance.dateOfBirth}
                                        placeholder={labels.Insurance.dateOfBirthPlaceHolder}
                                        date={this.state.dateOfBirth}
                                    />
                                    {/* {console.log(this.state.guardian,this.state.nomineeGuardianVisible)} */}
                                    {/*GUARDIAN */}
                                    {this.state.nomineeGuardianVisible &&
                                        <Textinput handleChange={this.handleGuardianChange}
                                            value={this.state.guardian}
                                            error={this.state.guardianError}
                                            page={"normal"} label={labels.Insurance.guardian}
                                            placeholder={labels.Insurance.guardianPlaceHolder}
                                            otherProps={{ maxLength: 40 }}
                                        />
                                    }

                                    {/*NOMINEE PERCENTAGE*/}
                                    <Textinput handleChange={this.handleNomineePercentageChange}
                                        value={this.state.nomineePercentage}
                                        error={this.state.nomineePercentageError}
                                        page={"normal"} label={labels.Insurance.nomineePercentage}
                                        placeholder={labels.Insurance.zero}
                                        align={'right'}
                                        otherProps={{ maxLength: 3 }}
                                    />


                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                                        <div>
                                            <CommonButton name={this.state.updateFlag == "add" ? "Add Nominee" : "Update Nominee"} page={"primary"}
                                                onClick={() => this.addNomineeRow()} />
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* NOMINEE LIST */}
                            {(this.commonFunction(this.state.nomineeList) && this.state.nomineeList.length > 0) &&
                                <>
                                    <DynamicList grid={this.state.grid} list={this.state.nomineeList} header={this.state.headerList} count={5} enableIcon={this.state.enableIcon} popupList={this.state.popupList}>
                                    </DynamicList>
                                    <div className={classes.firstRow}></div>
                                </>
                            }

                            {this.state.flag == "Life" &&
                                <>
                                    {/* Trustee Details / Beneficiary Details */}
                                    <CommonCheckbox
                                        label={labels.Insurance.mWPA}
                                        checked={this.state.checkboxChecked}
                                        onChange={this.handleCheckbox}
                                    />

                                    {this.state.checkboxChecked &&
                                        <>
                                            {/* Trustee Details*/}
                                            <div className={classes.firstRow}></div>
                                            <div className={classes.riderHeader}>
                                                <div className={classes.header}> {labels.Insurance.trusteeDetails} </div>
                                            </div>
                                            <div className={classes.cardSpareaterLine}></div>
                                            <div className={classes.grid}>
                                                {/* TRUSTEE NAME */}
                                                <Textinput handleChange={this.handleTrusteeNameChange}
                                                    value={this.state.trusteeName}
                                                    error={this.state.trusteeNameError}
                                                    page={"normal"} label={labels.Insurance.trusteeName}
                                                    placeholder={labels.Insurance.trusteeNamePlaceHolder}
                                                    otherProps={{ maxLength: 40 }}
                                                />

                                                {/* Trustee Relationship */}
                                                <CommonDropdown
                                                    //  autoselect={true}
                                                    handleOnChange={this.handleTrusteeRelationshipChange}
                                                    value={this.state.trusteeRelationshipValue}
                                                    error={this.state.trusteeRelationshipError}
                                                    type={"normalwithsearch"}
                                                    id="ddl_trusteerelationship"
                                                    placeholder={labels.Insurance.trusteeRelationshipPlaceHolder}
                                                    label={labels.Insurance.trusteeRelationship}
                                                    height={190}
                                                    List={this.state.trusteeRelationshipList != null && this.state.trusteeRelationshipList.length != 0 ? replaceNull(this.state.trusteeRelationshipList, "NA") : []}
                                                />

                                                {/* TRUSTEE DATE OF BIRTH */}
                                                <DatePicker
                                                    handleClick={(e) => this.handleTrusteeDateOfBirthClick(e)}
                                                    handleApply={this.handleTrusteeDateOfBirthApply}
                                                    showingdate={this.state.trusteeDateOfBirthTemp}
                                                    dateChange={this.handleTrusteeDateOfBirthChange}
                                                    handleClose={this.handleTrusteeDateOfBirthClose}
                                                    anchorEl={this.state.anchorTrusteeBirthDateEl}
                                                    error={this.state.trusteDateOfBirthError}
                                                    maxdate={moment().format("DD MMM YYYY")}
                                                    mindate={moment("1950/01/01").format("DD MMM YYYY")}
                                                    // allowed={false}
                                                    id="dp_trusteedateofbirth"
                                                    label={labels.Insurance.dateOfBirth}
                                                    placeholder={labels.Insurance.dateOfBirthPlaceHolder}
                                                    date={this.state.trusteeDateOfBirth}
                                                />
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end" }} >
                                                <div>
                                                    <CommonButton name={this.state.trusteeUpdateFlag == "add" ? "Add Trustee" : "Update Trustee"} page={"primary"}
                                                        onClick={() => this.addTrusteeRow()} />
                                                </div>
                                            </div>

                                            {/* TRUSTEE LIST */}
                                            {(this.commonFunction(this.state.trusteeList) && this.state.trusteeList.length > 0) &&
                                                <>
                                                    <div className={classes.firstRow}></div>
                                                    <DynamicList grid={this.state.gridTrustee} list={this.state.trusteeList} header={this.state.headerTrusteeList} count={3} enableIcon={this.state.enableIcon} popupList={this.state.trusteePopupList}>
                                                    </DynamicList>
                                                    <div className={classes.firstRow}></div>
                                                </>
                                            }

                                            {/* BENEFICIARY DETAILS */}
                                            <div className={classes.firstRow}></div>
                                            <div className={classes.riderHeader}>
                                                <div className={classes.header}> {labels.Insurance.beneficiaryDetails} </div>
                                            </div>
                                            <div className={classes.cardSpareaterLine}></div>
                                            {this.state.beneficiaryButtonVisible == true &&
                                                <div className={classes.grid}>
                                                    {/*BENEFICIARY NAME */}
                                                    <Textinput handleChange={this.handleBeneficiaryNameChange}
                                                        value={this.state.beneficiaryName}
                                                        error={this.state.beneficiaryNameError}
                                                        page={"normal"} label={labels.Insurance.beneficiaryName}
                                                        placeholder={labels.Insurance.beneficiaryNamePlaceHolder}
                                                        otherProps={{ maxLength: 40 }}
                                                    />
                                                    {/*BENEFICIARY RELATIONSHIP */}
                                                    <CommonDropdown
                                                        handleOnChange={this.handleBeneficiaryRelationshipChange}
                                                        value={this.state.beneficiaryRelationshipValue}
                                                        error={this.state.beneficiaryRelationshipError}
                                                        type={"normalwithsearch"}
                                                        id="ddl_beneficiaryrelationship"
                                                        placeholder={labels.Insurance.beneficiaryRelationshipPlaceHolder}
                                                        label={labels.Insurance.beneficiaryRelationship}
                                                        height={190}
                                                        List={this.state.beneficiaryRelationshipList != null && this.state.beneficiaryRelationshipList.length != 0 ? replaceNull(this.state.beneficiaryRelationshipList, "NA") : []}
                                                    />

                                                    {/* BENEFICIARY DATE OF BIRTH */}
                                                    <DatePicker
                                                        handleClick={(e) => this.handleBeneficiaryDateOfBirthClick(e)}
                                                        handleApply={this.handleBeneficiaryDateOfBirthApply}
                                                        showingdate={this.state.beneficiaryDateOfBirthTemp}
                                                        dateChange={this.handleBeneficiaryDateOfBirthChange}
                                                        handleClose={this.handleBeneficiaryDateOfBirthClose}
                                                        anchorEl={this.state.anchorBeneficiaryBirthDateEl}
                                                        error={this.state.beneficiaryDateOfBirthError}
                                                        maxdate={moment().format("DD MMM YYYY")}
                                                        mindate={moment("1950/01/01").format("DD MMM YYYY")}
                                                        // allowed={false}
                                                        id="dp_beneficiarydateofbirth"
                                                        label={labels.Insurance.dateOfBirth}
                                                        placeholder={labels.Insurance.dateOfBirthPlaceHolder}
                                                        date={this.state.beneficiaryDateOfBirth}
                                                    />

                                                    {/* BENEFICIARY GUARDIAN */}
                                                    {this.state.beneficiaryGuardianVisible &&
                                                        <Textinput handleChange={this.handleBeneficiaryGuardianChange}
                                                            value={this.state.beneficiaryGuardian}
                                                            error={this.state.beneficiaryGuardianError}
                                                            page={"normal"} label={labels.Insurance.guardian}
                                                            placeholder={labels.Insurance.guardianPlaceHolder}
                                                            otherProps={{ maxLength: 40 }}
                                                        />
                                                    }

                                                    {/*BENEFICIARY PERCENTAGE*/}
                                                    <Textinput handleChange={this.handleBeneficiaryPercentageChange}
                                                        value={this.state.beneficiaryPercentage}
                                                        error={this.state.beneficiaryPercentageError}
                                                        page={"normal"} label={labels.Insurance.beneficiaryPercentage}
                                                        placeholder={labels.Insurance.zero}
                                                        align={'right'}
                                                        otherProps={{ maxLength: 3 }}
                                                    />

                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                                                        <div>
                                                            <CommonButton name={this.state.beneficiaryUpdateFlag == "add" ? "Add Beneficiary" : "Update Beneficiary"} page={"primary"}
                                                                onClick={() => this.addBeneficiaryRow()} />
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {/* BENEFICIARY LIST */}
                                            {(this.commonFunction(this.state.beneficiaryList) && this.state.beneficiaryList.length > 0) &&
                                                <>
                                                    <DynamicList grid={this.state.grid} list={this.state.beneficiaryList} header={this.state.headerBeneficiaryList} count={5} enableIcon={true} popupList={this.state.beneficiaryPopupList}>
                                                    </DynamicList>
                                                    <div className={classes.firstRow}></div>
                                                </>

                                            }
                                        </>
                                    }


                                    {/* ULIP DETAILS */}
                                    {this.state.checkboxChecked == false &&
                                        <div className={classes.firstRow}></div>
                                    }
                                    <div className={classes.riderHeader}>
                                        <div className={classes.header}> {labels.Insurance.uLIPDetails} </div>
                                    </div>
                                    <div className={classes.cardSpareaterLine}></div>
                                    <div className={classes.grid}>
                                        {/* AS ON DATE */}
                                        <DatePicker
                                            handleClick={(e) => this.handleAsOnDateClick(e)}
                                            handleApply={this.handleAsOnDateApply}
                                            showingdate={this.state.asOnDateTemp}
                                            dateChange={this.handleAsOnDateChange}
                                            handleClose={this.handleAsOnDateClose}
                                            anchorEl={this.state.anchorAsOnDateEl}
                                            error={this.state.asOnDateError}
                                            maxdate={moment().format("DD MMM YYYY")}
                                            mindate={moment("1950/01/01").format("DD MMM YYYY")}
                                            // allowed={false}
                                            id="dp_asondate"
                                            label={labels.Insurance.asOnDate}
                                            placeholder={labels.Insurance.asOnDatePlaceHolder}
                                            date={this.state.asOnDate}
                                        />

                                        {/*SCHEME NAME*/}
                                        <CommonDropdown
                                            //  autoselect={true}
                                            handleOnChange={this.handleSchemeNameChange}
                                            value={this.state.schemeNamelabel}
                                            error={this.state.schemeNameError}
                                            type={"normalwithsearch"}
                                            id="ddl_schemename"
                                            placeholder={labels.Insurance.schemeNamePlaceHolder}
                                            label={labels.Insurance.schemeName}
                                            height={190}
                                            List={this.state.schemeNameList != null && this.state.schemeNameList.length != 0 ? replaceNull(this.state.schemeNameList, "NA") : []}
                                        />

                                        {/*UNITS*/}
                                        <Textinput handleChange={this.handleUnitsChange}
                                            value={this.state.units}
                                            error={this.state.unitsError}
                                            page={"normal"} label={labels.Insurance.units}
                                            placeholder={labels.Insurance.zero}
                                            align={'right'}
                                            otherProps={{ maxLength: 15 }}
                                        />

                                        {/*LATEST NAV*/}
                                        <Textinput handleChange={this.handleLatestNAVChange}
                                            value={this.state.latestNAV}
                                            error={this.state.latestNAVError}
                                            page={"normal"} label={labels.Insurance.latestNAV}
                                            placeholder={labels.Insurance.zero}
                                            align={'right'}
                                            otherProps={{ maxLength: 15 }}
                                        />

                                        {/*LATEST VALUE*/}
                                        <Textinput handleChange={this.handlelatestValueChange}
                                            value={this.state.latestValue}
                                            error={this.state.latestValueError}
                                            page={"normal"} label={labels.Insurance.latestValue}
                                            placeholder={labels.Insurance.zero}
                                            align={'right'}
                                            otherProps={{ maxLength: 15 }}
                                        />

                                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }} >
                                            <div>
                                                <CommonButton name={this.state.uLIPUpdateFlag == "add" ? "Add ULIP" : "Update ULIP"} page={"primary"}
                                                    onClick={() => this.addULIPRow()} />
                                            </div>
                                        </div>
                                    </div>

                                    {/* ULIP LIST */}
                                    {(this.commonFunction(this.state.uLIPList) && this.state.uLIPList.length > 0) &&
                                        <DynamicList grid={this.state.grid} list={this.state.uLIPList} header={this.state.headerULIPList} count={5} enableIcon={true} popupList={this.state.uLIPPopupList}>
                                        </DynamicList>
                                    }

                                </>
                            }


                            <div style={{ marginTop: "2%", display: "flex", flexDirection: "row", marginBottom: "2%", justifyContent: "flex-end" }} >
                                <div className={classes.buttonStyle}>
                                    {this.state.EditID == 0 ?
                                        <CommonButton name={"Save"} page={"primary"}
                                            loader={this.state.loader}
                                            loading={"true"}
                                            onClick={() => this.handleSubmit("save")} />
                                        :
                                        <CommonButton name={"Cancel"} page={"primary"}
                                            onClick={() => this.updateCancel()} />
                                    }
                                </div>
                                <div style={{ marginLeft: "5%" }} className={classes.buttonStyle}>
                                    <CommonButton name={this.state.EditID == 0 ? "Save & More" : "Update"} page={"primary"}
                                        loader={this.state.addLoader}
                                        loading={"true"}
                                        disabled={this.state.addLoader}
                                        onClick={() => this.handleSubmit("addMore")} />
                                </div>
                            </div>
                            <CommonSnackbar message={this.state.message} open={this.state.openSnackBar} severity={this.state.severity} handlesnanckClose={this.handleToastClose} />
                            <DeletePopup header={"Confirmation"} onClick={this.handleYesClick} loader={this.state.deletePopupLoader} open={this.state.openDeletePopup} text={this.state.deletePopupText} onClose={this.handlePopupClose} />
                            <DeletePopup header={"Confirmation"} onClick={this.handleYesClickBeneficiary} loader={this.state.deletePopupLoaderBeneficiary} open={this.state.openDeletePopupBeneficiary} text={this.state.deletePopupTextBeneficiary} onClose={this.handlePopupCloseBeneficiary} />
                            <DeletePopup header={"Confirmation"} onClick={this.handleYesClickTrustee} loader={this.state.deletePopupLoaderTrustee} open={this.state.openDeletePopupTrustee} text={this.state.deletePopupTextTrustee} onClose={this.handlePopupCloseTrustee} />
                            <DeletePopup header={"Confirmation"} onClick={this.handleYesClickULIP} loader={this.state.deletePopupLoaderULIP} open={this.state.openDeletePopupULIP} text={this.state.deletePopupTextULIP} onClose={this.handlePopupCloseULIP} />

                            {/* BANK POPUP */}
                            <Dialog
                                disablePortal={true}
                                maxWidth="xs"
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                open={this.state.openPopup}
                                onClose={this.handleCancel}
                            >
                                <div className={classes.popupWidth}>
                                    <div className={classes.popupheader}>
                                        <div className={classes.txtHeader}>{'Add New Bank'}
                                            <span className={classes.clearIcon} onClick={this.handleCancel}>&#x2715;</span>
                                        </div>
                                    </div>
                                    <div className={classes.popupBodyContainer}>
                                        <div style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            gap: "20px",
                                            justifyContent: "center",
                                            alignContent: "center"
                                        }}>
                                            {/*BANK NAME*/}
                                            <CommonDropdown
                                                autoselect={false}
                                                isSearchable={true}
                                                handleOnChange={this.handleBankNameChange}
                                                value={this.state.bankNameValue}
                                                error={this.state.bankNamePopupError}
                                                type={"normalwithsearch"}
                                                id="ddl_bankname"
                                                placeholder={labels.Insurance.bankNamePlaceHolderPopup}
                                                label={labels.Insurance.bankName}
                                                height={190}
                                                List={this.state.bankNameList != null && this.state.bankNameList.length != 0 ? replaceNull(this.state.bankNameList, "NA") : []}
                                            />
                                            <div style={{ marginTop: this.state.bankNamePopupError ? '-11%' : "-7%" }}></div>
                                            {/* ACCOUNT NUMBER */}
                                            <Textinput handleChange={this.handleAccountNumberChange}
                                                value={this.state.accountNumber}
                                                error={this.state.accountNumberError}
                                                page={"normal"} label={labels.Insurance.accountNumber}
                                                placeholder={labels.Insurance.accountNumberPlaceHolder}
                                                inputProps={{ maxLength: 20 }}
                                            />
                                            <div style={{ marginTop: '-14%' }}></div>

                                            {/*IFSC CODE */}
                                            <Textinput handleChange={this.handleIFSCCodeChange}
                                                value={this.state.ifscCode}
                                                error={this.state.ifscCodeError}
                                                page={"normal"} label={labels.Insurance.ifscCode}
                                                placeholder={labels.Insurance.ifscCodePlaceHolder}
                                                otherProps={{ maxLength: 12 }}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className={classes.cardSpareaterLinelight}></div> */}
                                    <div className={classes.popupButtonCantainer}>
                                        <div className={classes.popupfooterContainer}>
                                            <CommonButton name={"Cancel"} onClick={this.cancel} />
                                            <CommonButton name={"Add"} page={"primary"} loading="true" loader={this.state.popupLoader} onClick={this.addBank} />
                                        </div>
                                    </div>
                                </div>
                            </Dialog>
                        </div>
                        <div className={classes.sideSpace}></div>
                    </div>
                </ErrorBoundary >
            );
        }
        catch (e) {
            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => {
    return {
        sessionID: state.Login.sessionID,
        clientID: state.Login.logindetails.ClientID,
        logindetails: state.Login.logindetails,
        L5ID: state.Login.L5ID,
        insuranceReduxTable: state.Dataentry.npsAndInsuranceDropdownList,
        riderForDropdownList: state.Dataentry.npsAndInsuranceDropdownList != undefined && state.Dataentry.npsAndInsuranceDropdownList.length != 0 && state.Dataentry.npsAndInsuranceDropdownList.table3 != null && state.Dataentry.npsAndInsuranceDropdownList.table3.length != 0 ? [{ Name: "Select", ID: "", }, ...state.Dataentry.npsAndInsuranceDropdownList.table3].map((item) => {
            return { "label": item.Name, "value": item.ID }
        }) : [],
        // This redux data used only for policy For table
        riderForDropdownListSecond: state.Dataentry.npsAndInsuranceDropdownList != undefined && state.Dataentry.npsAndInsuranceDropdownList.length != 0 && state.Dataentry.npsAndInsuranceDropdownList.table3 != null && state.Dataentry.npsAndInsuranceDropdownList.table3.length != 0 ? state.Dataentry.npsAndInsuranceDropdownList.table3.map((item) => {
            return { "label": item.Name, "value": item.ID }
        }) : []
    }
}

// const mapDispatchToProps = dispatch => {
//     return bindActionCreators({  }, dispatch)
// }
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles(mergedStyle)(connect(mapStateToProps, null)(Insurance_Data_Entry));