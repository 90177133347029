import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Dialog from '@material-ui/core/Dialog';
import Text from '../text/text';
import Textinput from '../textinput/textinput';
import Button from '../custombutton/button'
import Delete from '../../utils/assets/delete.png'
import Edit from '../../utils/assets/edit.png'
import OutlinedButtons from '../payment/button';
import NoData from '../nodata/nodata';
import { getAddCountryDetails, updatepersonaldetails, deletecountrydetails } from '../../redux/actions/profile/profile'
import CommonSnackbar from '../snackbar/snackbar'
import color from "../../utils/colors/colors";
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import ErrorBoundary from '../Errorhandling/Errorhandle';
import { alphabetSpaceOnly, alphabetOnly, alphaNumericOnly, numbersOnly } from '../../utils/commonfunction'
import UpdatePersonalDetails from '../../api/profile/updatepersonaldetails';
import Deletepopup from '../popup/deletepopup';
import deleteCountry from '../../api/profile/deletecountry';
import getAddCountry from '../../api/profile/addcountry';
import Loader from '../../components/loader/loader';
const styles = theme => ({
    heading1: {
        color: window.globalConfig.color.PRIMARY_BLUE,
        fontSize: "larger",
        fontWeight: 600,
        textAlign: "left"
    },
    col: {
        display: "flex",
        flexDirection: "column"
    },
    colheader1: {
        color: window.globalConfig.color.PRIMARY_BORDER,
        fontSize: "14px",
        textAlign: "left",
    },
    colheader2: {
        textAlign: "left",
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontSize: "14px"
    },
    mainCard: {
        //marginLeft: '4.5%',
        [theme.breakpoints.down('xl')]: {
            // marginTop: "3%"
        },
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
        },
    },
    container: {
        // display: 'flex',
        // flexDirection: "row",
        // paddingLeft: "4%",
        display: 'grid',
        gridTemplateColumns: 'repeat(2, [col-start] minmax(52%, 1fr) [col-end])',
        [theme.breakpoints.down('xs')]: {
            display: 'grid',
            gridTemplateColumns: 'repeat(2, [col-start] minmax(100px, 1fr) [col-end])',
            marginLeft: '-4%',
        },
    },
    imageSpan: {
        marginLeft: "59%",
        marginTop: '0%',
    },
    image: {
        height: 20,
        width: 20,
        cursor: "pointer",
        marginTop: 10
    },
    deletespan: {
        // flex: 0.5,
        display: "flex",
        justifyContent: "flex-start",
        [theme.breakpoints.between('1000', '1030')]: {
            marginRight: -12
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: "-100%"
        },
        [theme.breakpoints.between('1250', '1300')]: {
            marginRight: -12
        },
    },
    editIcon: {
        textAlign: "right",
        marginRight: 30,
        [theme.breakpoints.between('1000', '1030')]: {
            marginRight: 10,
        },
    },
    incomeDetailsCardFirstRow: {
        display: "flex",
        flexDirection: "row",
        marginTop: "3%",
        justifyContent: "space-between",
        paddingLeft: "4%",
        paddingRight: "23.5%",
    },
    countryofBirthColumn: {
        paddingRight: "13%",
    },
    incomeDetailsCardSecondRow: {
        display: "flex",
        flexDirection: "row",
        marginTop: "4%",
        justifyContent: "space-between",
        paddingLeft: "4%",
    },
    cardSpareaterLine: {
        height: 0.5,
        width: "100%",
        backgroundColor: window.globalConfig.color.BORDER,
        marginTop: "4%"
    },
    countryCard: {
        display: 'flex',
        flexDirection: "row",
        marginTop: "3%",
        // paddingLeft: "4%",changed by dulcy
    },
    boldtext: {
        fontWeight: "bold",
    },
    countryCardRow: {
        display: "flex",
        flexDirection: "row",
        marginTop: "3%",
        marginBottom: "3%",
        justifyContent: "space-between",
        backgroundColor: window.globalConfig.color.BORDER,
        padding: "2% 0 2% 4%",
    },
    taxCountry: {
        paddingLeft: "4%",
    },
    taxIdentificationType: {
        paddingLeft: "12%"
    },
    taxIdentificationNumber: {
        marginLeft: "17%"
    },
    taxpayingbtn: {
        marginTop: "3%",
        marginBottom: "3%",
        // marginLeft: '-18%',
        [theme.breakpoints.down('xs')]: {
            marginLeft: 'unset',
        }
    },
    button: {
        width: 'auto',
        padding: "7px 22px",
        borderRadius: '22px',
        borderColor: window.globalConfig.color.PRIMARY_BLUE,
        color: window.globalConfig.color.PRIMARY_BLUE,
        textTransform: "none"
    },
    //popup design by dulcy

    header: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: "white",
        height: 50,
        fontWeight: 500,
        // styles added by yogitha on 05/06/2021 on mobile responsive
        width: '100%',
        // end
    },
    personalPopupheader: {
        backgroundColor: window.globalConfig.color.PRIMARY,
        color: "white",
        height: 50,
        fontWeight: 500,
        // styles added by yogitha on 05/06/2021 on mobile responsive
        width: '43%',
        position: "fixed",
        [theme.breakpoints.between('1000', '1250')]: {
            width: '57vw',
        },
        [theme.breakpoints.between('1250', '1300')]: {
            width: '43vw',
        },
        // end
    },
    txtHeader: {
        padding: 15,
    },
    dialogPaper: {
        height: 'auto',
        width: '600px',
        [theme.breakpoints.down('md')]: {
            width: '57%',
        },
        [theme.breakpoints.down('sm')]: {
            width: '75%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        [theme.breakpoints.between('1250', '1300')]: {
            width: '43%',
        },
    },
    clearIcon: {
        float: 'right',
        cursor: 'pointer'
    },
    popupFooterDiv: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
    },
    footerCard: {
        display: "flex", flex: "0.5 0.5 42%",
    },
    popupcard: {
        width: "100%",

    },
    addBankPopUpResponsive: {
        padding: "2%",
        [theme.breakpoints.down('xs')]: {
            height: 'auto',
            // width: '100%',
        },
    },
    addPersonalPopUpResponsive: {
        padding: "2%",
        marginTop: "50px",
        [theme.breakpoints.down('xs')]: {
            height: '225px',
            width: '100%',
        },
    },
    taxIdentityNumber: {
        width: "17.6vw",
        [theme.breakpoints.down('xs')]: {
            width: '92%',
            margin: '4% ',
        },
        [theme.breakpoints.between('1200', '1500')]: {
            width: "19vw",
        },
    },
    popOverTax: {
        display: "flex", marginBottom: "4%", flexDirection: "row", columnGap: "6%",
        [theme.breakpoints.down('xs')]: {
            display: "flex",
            flexDirection: "row",
            flexWrap: 'wrap',
            margin: '4% ',
            columnGap: "66%",
        },
    },
    marginTopTextAlign: {
        width: '45%',
        [theme.breakpoints.down('600')]: {
            marginTop: '4%',
            width: "100% !important"
        },
        [theme.breakpoints.down('920')]: {
            width: "33vw"
        },
        // [theme.breakpoints.down('sm')]: {
        //     marginTop:'4%'
        // },
    },
    btnCancel: {
        width: "20%", marginRight: 5,
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            // marginLeft: 20,
            // marginTop: 50
        },
    },
    footerButtons: {
        display: "flex", justifyContent: "flex-end", marginBottom: "3%", marginTop: "3%", marginRight: "4%",
        [theme.breakpoints.down('xs')]: {
            display: 'block',
            justifyContent: 'center',
            marginBottom: '5%',
            // marginRight: '0%',
            margin: "20px"
        },
        // [theme.breakpoints.down('sm')]: {
        //     display: 'flex',
        //     justifyContent: 'center',
        //     marginBottom: '5%',
        //     marginRight: '0%',
        // },
    },
    noDatastyle: {
        marginTop: '7%',
        marginBottom: '7%',
        // marginLeft: '-18%'
    },
    bottomButtonStyle: {
        marginLeft: '-10%'
    },
    personalAndIncomeDetails: {
        marginTop: '1.5%'
    },
    btnAddCountry: {
        [theme.breakpoints.between('1000', '1030')]: {
            marginRight: 5
        },
        width: "20%",
        marginRight: 5,
        [theme.breakpoints.down('xs')]: {
            width: "100%",
            marginLeft: "-7px",
            marginTop: 10
        },
    }
});
let status = ""
class IncomeDetails extends React.Component {
    isComponentMounted = false
    constructor(props) {
        super(props)
        this.state = {
            show: false,
            openpopup: false,
            taxCountry: "",
            taxtype: "",
            idnumber: "",
            errorcountry: "",
            erroridnumber: "",
            errortaxtype: "", open: false,
            openpersonalEdit: false,
            Nationality: "",
            COB: "",
            POB: "",
            Occupation: "",
            AnnualIncome: "",
            PoliticalStatus: "",
            errorNationality: "",
            errorCOB: "",
            errorPOB: "",
            errorOccupation: "",
            errorAnnualIncome: "",
            errorPoliticalStatus: "",
            disabled: false,
            Deletepopupopen: false,
            open: false,
            message: "",
            UserID: "",
            countryID: "",
            deletecountryloader: false,
            severity: "success",
            loader: false,
            personalIncomeloader: false

        }
    }
    componentDidMount() {
        this.isComponentMounted = true
    }
    componentWillUnmount() {
        this.isComponentMounted = false
    }
    componentDidUpdate(prevProps) {
    }
    handleClose = () => {
        this.setState({ openpopup: false, openpersonalEdit: false })
    }
    handleChange = () => {
    }
    handleClick = () => {
        this.setState({
            taxCountry: "",
            taxtype: "",
            idnumber: "",
            errorcountry: "",
            errortaxtype: "",
            erroridnumber: "",
            openpopup: true,
            disabled: false
        })
    }
    onChangecountry = (event) => {
        if (event.target.value.length < 31) {
            if (alphabetSpaceOnly(event.target.value)) {
                this.setState({ taxCountry: event.target.value, errorcountry: "" })
            }
        }
        else {
            event.preventDefault();
        }
    }

    onChangetype = (event) => {
        if (event.target.value.length < 31) {
            if (alphabetSpaceOnly(event.target.value)) {
                this.setState({ taxtype: event.target.value, errortaxtype: "" })
            }
        } else {
            event.preventDefault();
        }
    }
    handleIDchange = (event) => {
        if (event.target.value.length < 21) {
            if (alphaNumericOnly(event.target.value)) {
                this.setState({ idnumber: event.target.value, erroridnumber: "" })
            }
        }
        else {
            event.preventDefault();
        }
    }
    addcountry = () => {
        let isValid = true
        if (this.state.taxCountry == "") {
            isValid = false
            this.setState({ errorcountry: labels.messages.required })
        }
        if (this.state.taxtype == "") {
            isValid = false
            this.setState({ errortaxtype: labels.messages.required })
        }
        if (this.state.idnumber == "") {
            isValid = false
            this.setState({ erroridnumber: labels.messages.required })
        }
        if (isValid) {
            this.setState({ loader: true })
            getAddCountry(0, this.props.logindetails.ClientID, this.state.taxCountry, this.state.taxtype, this.state.idnumber, this.props.accountno).then((data) => {
                if (data.status == "S") {
                    this.props.getAddCountryDetails(0, this.props.logindetails.ClientID, this.state.taxCountry, this.state.taxtype, this.state.idnumber, data, this.props.Ucc, this.props.accountno)
                    this.setState({ open: true, message: "Country added successfully", severity: "success" })
                    this.setState({ openpopup: false })
                    this.setState({ disabled: true })
                    this.setState({ loader: false })
                } else {
                    this.setState({ open: true, message: data.message, severity: "warning" })
                    this.setState({ openpopup: false })
                    this.setState({ loader: false })
                }

            })
        }
    }
    onChangeNationality = (event) => {
        if (event.target.value.length < 51) {
            if (alphabetSpaceOnly(event.target.value)) {
                this.setState({ Nationality: event.target.value, errorNationality: "" })
            }
        }
        else {
            event.preventDefault();
        }
    }
    onChangeNationality = (event) => {
        if (event.target.value.length < 51) {
            if (alphabetSpaceOnly(event.target.value)) {
                this.setState({ Nationality: event.target.value, errorNationality: "" })
            }
        }
        else {
            event.preventDefault();
        }
    }
    onChangeCOB = (event) => {
        if (event.target.value.length < 51) {
            if (alphabetSpaceOnly(event.target.value)) {
                this.setState({ COB: event.target.value, errorCOB: "" })
            }
        }
        else {
            event.preventDefault();
        }
    }
    onChangePOB = (event) => {
        if (event.target.value.length < 51) {
            if (alphabetSpaceOnly(event.target.value)) {
                this.setState({ POB: event.target.value, errorPOB: "" })
            }
        }
        else {
            event.preventDefault();
        }
    }
    onChangeAnnualIncome = (event) => {
        if (event.target.value.length < 11) {
            // if (numbersOnly(event.target.value)) {
            this.setState({ AnnualIncome: event.target.value, errorAnnualIncome: "" })
            // }
        }
        else {
            if (event.keyCode != 8) {
                event.preventDefault();
            } else {

                this.setState({ AnnualIncome: event.target.value, errorAnnualIncome: "" })

            }
        }
    }
    onChangeOccupation = (event) => {
        if (event.target.value.length < 31) {
            if (alphabetSpaceOnly(event.target.value)) {
                this.setState({ Occupation: event.target.value, errorOccupation: "" })
            }
        }
        else {
            event.preventDefault();
        }
    }
    onChangePoliticalStatus = (event) => {
        if (event.target.value.length < 31) {
            if (alphabetSpaceOnly(event.target.value)) {
                this.setState({ PoliticalStatus: event.target.value, errorPoliticalStatus: "" })
            }
        }
        else {
            event.preventDefault();
        }
    }

    onClickEdit = () => {
        this.setState({
            openpersonalEdit: true, Nationality: this.props.IncomeDetails[0].CitizenshipID,
            COB: this.props.IncomeDetails[0].CountryOfBirth,
            POB: this.props.IncomeDetails[0].PlaceOfBirth,
            Occupation: this.props.IncomeDetails[0].Occupation,
            AnnualIncome: this.props.IncomeDetails[0].AnnualIncome,
            PoliticalStatus: this.props.IncomeDetails[0].PoliticalExperience
        })
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    }
    handleBackspace = (e) => {
        // if(e.key==="Backspace"){
        //     this.setState({ AnnualIncome: e.target.value })
        // }
    }
    deleteClick = (val) => {
        this.setState({ countryID: val.TaxPayingCountryID, Deletepopupopen: true })
    }
    handleCountryDelete = () => {
        this.setState({ deletecountryloader: true })
        deleteCountry(this.state.countryID, this.props.logindetails.ClientID).then((res) => {
            if (res.status == "S") {
                this.props.deletecountrydetails(this.state.countryID, this.props.logindetails.ClientID)

                this.setState({ deletecountryloader: false })
                this.setState({ open: true, message: res.message, severity: "success" })
                this.setState({ Deletepopupopen: false })
            } else {
                this.setState({ deletecountryloader: false })
                this.setState({ open: true, message: res.message, severity: "warning" })
                this.setState({ Deletepopupopen: false })
            }


        })
    }
    render() {
        const { classes } = this.props;
        //    
        try {
            return (
                <ErrorBoundary>
                    <div className={classes.mainCard} >
                        <div>
                            <div className={classes.container}>
                                <Text label={labels.personalIncome.PersonalIncomeDetails} />

                                <Dialog
                                    disablePortal={true}
                                    open={this.state.openpersonalEdit}
                                    onClose={() => { this.setState({ openpersonalEdit: false }) }}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    classes={{ paper: classes.dialogPaper }}
                                >
                                    <div className={classes.popupcard}>
                                        {/* header  */}
                                        <div className={classes.personalPopupheader}>
                                            <div className={classes.txtHeader}>{`${labels.personalIncome.Edit} ${labels.personalIncome.PersonalIncomeDetails}`}
                                                <span className={classes.clearIcon} onClick={() => { this.setState({ openpersonalEdit: false }) }}>&#x2715;</span>
                                            </div>
                                        </div>
                                        {/* body */}
                                        <div className={classes.addPersonalPopUpResponsive}>
                                            <div className={classes.popOverTax}>
                                                <Textinput handleChange={this.onChangeNationality} value={this.state.Nationality} error={this.state.errorNationality} page={"normal"} label={labels.personalIncome.Nationality} placeholder={labels.personalIncome.EnterNationality} />
                                                <div className={classes.marginTopTextAlign} >
                                                    <Textinput handleChange={this.onChangeCOB} value={this.state.COB} error={this.state.errorCOB} page={"normal"} label={labels.personalIncome.CountryofBirth} placeholder={labels.personalIncome.EnterCountryofBirth} />
                                                </div>
                                            </div>
                                            <div className={classes.popOverTax}>
                                                <Textinput handleChange={this.onChangePOB} value={this.state.POB} error={this.state.errorPOB} page={"normal"} label={labels.personalIncome.PlaceofBirth} placeholder={labels.personalIncome.EnterPlaceofBirth} />
                                                <div className={classes.marginTopTextAlign}>
                                                    <Textinput handleChange={this.onChangeOccupation} value={this.state.Occupation} error={this.state.errorOccupation} page={"normal"} label={labels.personalIncome.Occupation} placeholder={labels.personalIncome.EnterOccupation} />
                                                </div>
                                            </div>
                                            <div className={classes.popOverTax}>
                                                <Textinput otherProps={{ maxLength: 10, onKeyDown: this.handleBackspace }} handleChange={this.onChangeAnnualIncome} value={this.state.AnnualIncome} error={this.state.errorAnnualIncome} page={"normal"} label={labels.personalIncome.AnnualIncome} placeholder={labels.personalIncome.EnterAnnualIncome} />
                                                <div className={classes.marginTopTextAlign}>
                                                    <Textinput handleChange={this.onChangePoliticalStatus} value={this.state.PoliticalStatus} error={this.state.errorPoliticalStatus} page={"normal"} label={labels.personalIncome.PoliticalStatus} placeholder={labels.personalIncome.EnterPoliticalStatus} />
                                                </div>
                                            </div>
                                        </div>

                                        {/* footer */}
                                        <div className={classes.footerButtons}>
                                            <div className={classes.btnCancel}>
                                                <Button page={"secondary"} name={labels.personalIncome.Cancel} onClick={() => { this.setState({ openpopup: false }) }} />
                                            </div>
                                            <div style={{ width: "20%" }}>
                                                <Button page={"primary"} loader={this.state.personalIncomeloader} name={labels.personalIncome.Edit} onClick={this.UpdatepersonalDetails} />
                                            </div>
                                        </div>
                                    </div>
                                </Dialog>

                            </div>

                            {this.props.isLoading ? <Loader page="tablerow" /> :
                                this.props.IncomeDetails.length != 0 && this.props.IncomeDetails.filter((item) => item.UCC === this.props.Ucc).length != 0 ? this.props.IncomeDetails.filter((item) => item.UCC === this.props.Ucc).map((item, index) => {
                                    return (
                                        <div key={index}>
                                            <div className={classes.personalAndIncomeDetails}></div>
                                            <div className={"contentcolumnProfile"}>
                                                <Text label={labels.personalIncome.Nationality} text={item.CitizenshipID} page={"normal"} />
                                                <Text label={labels.personalIncome.CountryofBirth} text={item.CountryOfBirth} page={"normal"} />
                                                <Text label={labels.personalIncome.PlaceofBirth} text={item.PlaceOfBirth} page={"normal"} />
                                            </div>
                                            <div className={"contentcolumnProfile"} style={{ marginTop: "2%" }}>
                                                <Text label={labels.personalIncome.Occupation} text={item.Occupation} page={"normal"} />
                                                <Text label={labels.personalIncome.AnnualIncome} text={item.AnnualIncome} page={"normal"} />
                                                <Text label={labels.personalIncome.PoliticalStatus} text={item.PoliticalExperience} page={"normal"} />
                                            </div>
                                        </div>
                                    )
                                })
                                    : <div className={classes.noDatastyle}  ><NoData /></div>}

                        </div>
                        <div className={classes.cardSpareaterLine}>
                        </div>
                        <div >
                            <div className={classes.countryCard}>
                                <span><span className={classes.boldtext}>{labels.personalIncome.AreYouTaxResident}</span></span>
                                {/* <span>edit</span> */}
                            </div>
                            {this.props.isLoading ? <Loader page="tablerow" /> :
                                this.props.TaxDetails.length != 0 && this.props.TaxDetails.filter((item) => item.UCC === this.props.Ucc).length != 0 ?
                                    this.props.TaxDetails.filter((item) => item.UCC === this.props.Ucc).map((item, index) => {
                                        return (
                                            <div key={index} className={"contentCountryProfile"} style={{ marginTop: "2%" }}>
                                                <Text label={labels.personalIncome.TaxCountry} text={item.Country} page={"normal"} />
                                                <Text label={labels.personalIncome.TaxIdentificationType} text={item.IdentificationType} page={"normal"} />
                                                <div style={{ display: "grid", gridTemplateColumns: "auto 1%" }}>
                                                    <Text label={labels.personalIncome.TaxIdentificationNumber} text={item.TIN} page={"normal"} />

                                                    <div className={classes.deletespan} onClick={() => this.deleteClick(item)}>
                                                        <img src={Delete} className={classes.image}></img>
                                                    </div>
                                                </div>

                                            </div>

                                        )
                                    })
                                    : <div className={classes.noDatastyle}><NoData /></div>}
                            {/* {this.props.logindetails.KYCFormNo != "" && this.props.logindetails.KYCFormNo != 0 && */}

                            <div className={classes.taxpayingbtn} >
                                {/* <OutlinedButtons className={classes.bottomButtonStyle} text={labels.personalIncome.AddTaxPayingCountry} onClick={this.handleClick} /> */}
                                <Dialog

                                    open={this.state.openpopup}
                                    onClose={this.handleClose}
                                    aria-labelledby="alert-dialog-title"
                                    aria-describedby="alert-dialog-description"
                                    classes={{ paper: classes.dialogPaper }}
                                //  style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
                                >
                                    <div className={classes.popupcard}>
                                        {/* header  */}
                                        <div className={classes.header}>
                                            <div className={classes.txtHeader}>{"Add Tax Details"}
                                                <span className={classes.clearIcon} onClick={() => { this.setState({ openpopup: false }) }}>&#x2715;</span>
                                            </div>
                                        </div>
                                        {/* body */}
                                        <div className={classes.addBankPopUpResponsive}>
                                            <div className={classes.popOverTax}>
                                                <div className={classes.marginTopTextAlign}>
                                                    <Textinput handleChange={this.onChangecountry} value={this.state.taxCountry} error={this.state.errorcountry} page={"normal"} label={labels.personalIncome.TaxCountry} placeholder={labels.personalIncome.EnterCountryName} />
                                                </div>  <div className={classes.marginTopTextAlign}>
                                                    <Textinput handleChange={this.onChangetype} value={this.state.taxtype} error={this.state.errortaxtype} page={"normal"} label={labels.personalIncome.TaxIdentificationType} placeholder={labels.personalIncome.EnterTaxIdentificationType} />
                                                </div>
                                            </div>
                                            <div className={classes.popOverTax}>
                                                <div className={classes.marginTopTextAlign}>
                                                    <Textinput handleChange={this.handleIDchange} value={this.state.idnumber} error={this.state.erroridnumber} page={"normal"} label={labels.personalIncome.TaxIdentificationNumber} placeholder={labels.personalIncome.EnterTaxIdentificationNumber} />
                                                </div>
                                            </div>
                                        </div>

                                        {/* footer */}
                                        <div className={classes.footerButtons}>
                                            <div className={classes.btnCancel}>
                                                <Button page={"liq"} name={labels.personalIncome.Cancel} onClick={() => { this.setState({ openpopup: false }) }} />
                                            </div>
                                            <div className={classes.btnAddCountry}>
                                                <Button page={"primary"} disabled={this.state.disabled} loader={this.state.loader} name={"Submit"} onClick={this.addcountry} loderAlign={"align"} />
                                            </div>

                                        </div>
                                    </div>
                                </Dialog>
                                <Deletepopup onClick={this.handleCountryDelete} loader={this.state.deletecountryloader} header={"Delete Tax Details"} open={this.state.Deletepopupopen} text={"Are you sure you want to delete?"} onClose={() => { this.setState({ Deletepopupopen: false }) }} />
                                {/* // {this.props.status != "" && */}
                                {/* }  */}
                            </div>
                            {/* } */}
                        </div>
                    </div>
                    <CommonSnackbar message={this.state.message} handlesnanckClose={this.handlesnanckClose} open={this.state.open} severity={this.state.severity} />

                </ErrorBoundary>
            );
        } catch (err) {

            return <Redirect to={labels.Path.NotFound} />
        }
    }
}
const mapStateToProps = state => ({
    logindetails: state.Login.logindetails,
    status: state.ProfileDetails.status,
    isLoading: state.ProfileDetails.isLoading,
    loader: state.ProfileDetails.loaderprofile,
    isLoading: state.ProfileDetails.isLoading,
    IncomeDetails: state.ProfileDetails.AllProfileDetails?.length == 0 ? [] : state.ProfileDetails.AllProfileDetails?.personalDetails != "NA" ? state.ProfileDetails.AllProfileDetails?.personalDetails.filter(
        (ele, ind) =>
            ind ===
            state.ProfileDetails.AllProfileDetails?.personalDetails.findIndex((elem) => elem.UCC === ele.UCC)
    ).map((item) => {
        return { ...item, "PlaceOfBirth": state.ProfileDetails.AllProfileDetails.mainDetails != "NA" ? state.ProfileDetails.AllProfileDetails.mainDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.mainDetails[0].PlaceOfBirth : "NA" : "NA", "CountryOfBirth": state.ProfileDetails.AllProfileDetails.mainDetails != null ? state.ProfileDetails.AllProfileDetails.mainDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.mainDetails[0].CountryOfBirth : "NA" : "NA", "Occupation": state.ProfileDetails.AllProfileDetails.mainDetails != null ? state.ProfileDetails.AllProfileDetails.mainDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.mainDetails[0].Occupation : "NA" : "NA" }
    }) : [],
    TaxDetails: state.ProfileDetails.AllProfileDetails.length == 0 ? [] : state.ProfileDetails.AllProfileDetails.taxDetails != "NA" ? state.ProfileDetails.AllProfileDetails.taxDetails : []
})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ getAddCountryDetails, updatepersonaldetails, deletecountrydetails }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(IncomeDetails))
