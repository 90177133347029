import React from 'react';
import '../../utils/stylesheets/table/table.css';
//import Filters from '../../components/filter/filter'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { connect } from 'react-redux';
import { Divider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
const useStyles = (theme) => ({
    tabelRowResponsive: {
        fontWeight: "bold",
        whiteSpace: 'nowrap',
        marginLeft: '10px',
        [theme.breakpoints.between("300", "900")]: {
            marginLeft: 10

        },
    },
    tableValuesResponsive: {
        display: 'flex',
        flex: '100%',
        flexDirection: 'row',
        marginTop: '10px',
        [theme.breakpoints.between("300", "900")]: {
            marginLeft: 10
        },

    }
});
class TableComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [
                { id: 1, category: "Aditya Birla Sun Life", M: 5.5, OneYear: 12.9, ThreeYear: 12.5, FiveYear: 12.5, SinceInception: 26.54 },
                { id: 2, category: "DSP Top 100 Equity ", M: 1.5, OneYear: 12.6, ThreeYear: 18.5, FiveYear: 54.3, SinceInception: 16.43 },
                { id: 3, category: "Franklin India Bluechip", M: 5.5, OneYear: 15.6, ThreeYear: 6.6, FiveYear: 60.6, SinceInception: 18.4 },
            ],
            expandedRows: [],
            anchorEl: null,
            selectedIndex: ""
        };
        this.handleClick = this.handleClick.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }
    componentDidMount() {
        // this.props.getProfitLossDetails();
        //   ("reducer", this.props.ProfitLoss)
        let expandableArray = [];
        for (var i = 1; i <= this.state.data.length; i++) {
            expandableArray.push(i)
        }
        this.setState({ expandedRows: expandableArray })
    }

    handleClick(event) {
        this.setState({ anchorEl: event.currentTarget })
    };

    handleClose() {
        this.setState({ anchorEl: null })
    };



    renderItem(item) {
        const itemRows = [
            <TableRow
            >
                <TableCell style={{ fontWeight: "bold" }} scope="row" align="left">
                    {item.Description}
                </TableCell>
                <TableCell className="green" align="right">{item.OneYearReturn == "NA" ? "0.0%" : `${item.OneYearReturn}${"%"}`} </TableCell>
                <TableCell className="green" align="right">{item.OneYearReturn == "NA" ? "0.0%" : `${item.OneYearReturn}${"%"}`} </TableCell>
                <TableCell className="green" align="right">{item.ThreeYearReturn == "NA" ? "0.0%" : `${item.ThreeYearReturn}${"%"}`} </TableCell>
                <TableCell className="green" align="right">{item.FiveYearReturn == "NA" ? "0.0%" : `${item.FiveYearReturn}${"%"}`} </TableCell>
                <TableCell className="green" align="right">{item.SinceInception == "NA" ? "0.0%" : `${item.SinceInception}${"%"}`} </TableCell>
            </TableRow>
        ];



        return itemRows;
    }

    render() {
        let allItemRows = [];

        this.props.returns != undefined && this.props.returns.forEach(item => {
            const perItemRows = this.renderItem(item);
            allItemRows = allItemRows.concat(perItemRows);
        });
        const { classes } = this.props;
        return (
            <div>
                <div className="desktopResponsive">
                    <TableContainer component={Paper}  >
                        <Table aria-label="simple table" className="tablecontainers table-big">
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell className="tableheader" align="right">3M</TableCell>
                                    <TableCell className="tableheader" align="right">1Y</TableCell>
                                    <TableCell className="tableheader" align="right">3Y</TableCell>
                                    <TableCell className="tableheader" align="right">5Y</TableCell>
                                    <TableCell className="tableheader" align="right">SINCE INCEPTION</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allItemRows}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>



                {/* styles added by yogitha  on 01/06/2021 */}
                <div className="mobileResponsive">
                    {this.props.returns.map((item) => {
                        return (<><div style={{ margin: "10px" }}>
                            <TableRow className={classes.tabelRowResponsive} scope="row" align="left">
                                {item.Description}
                            </TableRow>

                            <div xs={12} className={classes.tableValuesResponsive}>
                                <div xs={3} style={{ width: '25%' }} className="tableheader" align="left">3M</div>
                                <div xs={2} style={{ width: '25%' }} className="tableheader" align="left">1Y</div>
                                <div xs={2} style={{ width: '25%' }} className="tableheader" align="left">3Y</div>
                                <div xs={2} style={{ width: '25%' }} className="tableheader" align="left">5Y</div>
                                <div xs={3} style={{ width: '23%', whiteSpace: 'nowrap' }} className="tableheader" align="left" >SINCE IN.</div>
                            </div>
                            <div xs={12} className={classes.tableValuesResponsive}>
                                <div xs={3} style={{ width: '25%' }} className="green" align="left">{item.OneYearReturn == "NA" ? "0.0%" : `${item.OneYearReturn}${"%"}`} </div>
                                <div xs={2} style={{ width: '25%' }} className="green" align="left">{item.OneYearReturn == "NA" ? "0.0%" : `${item.OneYearReturn}${"%"}`} </div>
                                <div xs={2} style={{ width: '25%' }} className="green" align="left">{item.ThreeYearReturn == "NA" ? "0.0%" : `${item.ThreeYearReturn}${"%"}`} </div>
                                <div xs={2} style={{ width: '25%' }} className="green" align="left">{item.FiveYearReturn == "NA" ? "0.0%" : `${item.FiveYearReturn}${"%"}`} </div>
                                <div xs={3} style={{ width: '23%' }} className="green" align="left">{item.SinceInception == "NA" ? "0.0%" : `${item.SinceInception}${"%"}`} </div>
                            </div>

                            <hr />
                        </div>
                        </>
                        )
                    })}
                </div>
            </div>
        );
    }
}


// const mapStateToProps = state => {
//       (state.TransactionDetails.transactionDetailsList.topHoldings)
//     return {
//         ProfitLoss: state.ProfitLoss.profitLoss,
//         returns: state.TransactionDetails.transactionDetailsList.mfReturns
//     }
// }
// const mapDispatchToProps = dispatch => {
//     return bindActionCreators({getProfitLossDetails}, dispatch)
// }
// export default connect(mapStateToProps, mapDispatchToProps)(TableComponent)
export default withStyles(useStyles)(TableComponent);
