import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
import Networking from '../../utils/api/apiaccess';
function getFamilyTransactionList(ClientID, InceptionDate, SessionID, ProductID, ) {
  // let BaseUrl = "https://ms.zoifintech.com/Dashboard/PortfolioTransaction"

  let data = {};
  // data.Account = 0
  data.ClientID = Number(ClientID)
  data.InceptionDate = InceptionDate
  // data.TransactionType = 0
  // data.Type = 0
  // data.UserID = 0
  data.SessionID = SessionID
  // data.SchemeID = 0
  // data.AssetClass = 0
  // data.AccountType = 0
  // data.FromDate = ""
  // data.ToDate = ""
  data.ProductID = ProductID
  // data.FamilyID = 0
  // data.L5ID = 0
  // data.L4ID = 0
  //   (data)
  return Networking.PostApi(ApiValues.FamilyTransactions, data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
  // return fetch(ApiValues.Transactions, {
  //   method: 'POST',
  //   headers: {
  //     'Accept': 'application/json',
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify(data),
  // })
  //   .then((response) => response.json())
  //   .then((responseJson) => {
  //     //   ("transaction",responseJson)
  //     return responseJson

  //   })
  //   .catch((error) => {
  //     //Error 
  //     return {"status":"F","message":labels.messages.apierror}
  //   });

}
export default getFamilyTransactionList;