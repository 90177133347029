import React, { Component } from "react";
import colors from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import { withStyles } from '@material-ui/core/styles';
import color from "../../utils/colors/colors";
const styles = theme => ({
    root: {
        width: '100%',
    },
    flexResponsive: {
        display: "flex",
        flexDirection: "column",
    },
    textInputprofile: {
        minHeight: '39px',
        height: "100%",
        width: "100%",
        border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
        color: window.globalConfig.color.BLACK_PRIMARY,
        outline: "none !important",
        fontSize: "15px",
        textIndent:'10px',
        '&:focus': {
            border: `1px solid ${window.globalConfig.color.BROWN} !important`,
        },
        [theme.breakpoints.between("320", "499")]: {
            width: "100%",
            minHeight: '39px',
            height:'100%',
            border:  `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
            color: window.globalConfig.color.BLACK_PRIMARY,
            outline: "none !important",
            fontSize: "14px",
          
        },
        [theme.breakpoints.between("500", "600")]: {
            width: "100%",
            minHeight: '39px',
            height:'100%',
            border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`,
            color: window.globalConfig.color.BLACK_PRIMARY,
            outline: "none !important",
            fontSize: "14px",
          
        },
    },
});
class TextArea extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { classes } = this.props;
        return (
            <div>
                <div className={classes.flexResponsive}>
                    <span style={{ color: color.LABLE_GRAY, fontSize: 14, paddingBottom: "5px", textAlign: "left" }}>{this.props.label}</span>
                    <input
                        type="text"
                        placeholder={this.props.placeholder}
                        // type="text"
                        value={this.props.value}
                        className={[classes.textInputprofile, "text-field-cursor"].join(' ')}
                        id={this.props.id}
                        disabled={this.props.disabled}
                        onChange={(e) => {
                            if (this.props.disable == true) {
                                e.preventDefault()
                            }
                            else {
                                this.props.handleChange(e)
                            }
                        }}
                        style={{ fontSize: 14, 
                            // fontFamily:'roboto'
                         }}
                        {...this.props.otherProps}
                    />
                </div>
            </div>
        )
    }
}
export default withStyles(styles)(TextArea);