import React from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import color from "../../utils/colors/colors";
const App =(props)=>{
    const style={
        headerContainer:{
            display:'flex',
        },
        header:{
           fontWeight:"bold",
           fontSize:20,
        },
        link:{
           paddingTop:4,
           color:window.globalConfig.color.PRIMARY_BORDER,
           textTransform:'capitalize',
           textDecoration:'underline',
           fontSize:14,
           cursor:"pointer"
        },
        spacing:{
            padding:5
        }
    }
    return(
        <React.Fragment>
            <div style={style.headerContainer}>
            <div style={style.header}>{props.text}</div> 
            <div style={style.spacing}></div>
            {/* <span style={style.link} className="cursor"><HelpOutlineIcon/></span> */}
            </div>
        </React.Fragment>
    )
}
export default App;
