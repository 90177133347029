import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
function getIndustryWiseXrayList(Account, ClientID, InceptionDate, FromXIRR, ToXIRR, Type, AssetClass, SessionID, AccountLevel, scheme, ProductID, FamilyID, L5ID, L4ID) {
  //let BaseUrl = "https://ms.zoifintech.com/Dashboard/GetMutualFundDetails"
  let data = {};

  // data.Account = Account
  data.ClientID = ClientID
  data.InceptionDate = InceptionDate
  // data.FromXIRR = FromXIRR
  data.ToXIRR = AccountLevel
  // data.Type = Type
  // data.AssetClass = AssetClass
  // data.AccountLevel = ToXIRR
  // data.SessionID = SessionID
  // data.SchemeID = scheme
  data.ProductID = ProductID
  // data.FamilyID = FamilyID
  data.L5ID = L5ID
  // data.L4ID = L4ID
  // //  (data)
  // var config = {
  //   method: 'post',
  //   url: ApiValues.FolioWisePortfolio,
  //   headers: {
  //     'Content-Type': 'application/json',
  //     'Authorization': "L]ak;YzY!47FqLUbhcC:4b8Zaw"
  //   },
  //   data: data
  // };
  // return axios(config)
  //   .then(function (response) {
  //     return response.data;
  //   })
  //   .catch(function (error) {
  //     if (error.message = "Network Error") {
  //       var data = {}
  //       data.status = "N"
  //       data.message = error.message
  //       return data
  //     } else {
  //       return { "status": "F", "message": labels.messages.apierror }

  //     }
  //   });
  return Networking.PostApi(ApiValues.IndustryWisePortfolio, data).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}

export default getIndustryWiseXrayList;