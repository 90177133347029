import * as types from '../../constants/actionsType'
import { bindActionCreators } from 'redux';
import { replaceNull } from '../../../utils/commonfunction';
const initialState = {
  mandatedata: [],
  isMandateLoading:false
}
const reducer = (state = initialState, action) => {
    switch (action.type) {        
        case types.MANDATE_REPORT:
            return {
                ...state,
                mandatedata: action.payload!=null&&action.payload.length!=0 ?replaceNull(action.payload,"NA") : state.mandatedata,
                isMandateLoading:false
            }
        case types.LOADER_MANDATE_REPORT:
            return {
                ...state,
                isMandateLoading:action.payload
            }
        case types.EMPTY_MANDATE_REPORT:
                return {
                    ...state,
                    mandatedata: [],
                    isMandateLoading:false
                }
            default:
                return state
    }
}
export default reducer