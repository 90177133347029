import ApiValues from '../url/apiurl'
import labels from '../../utils/constants/labels';
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
import { Asset } from '../../utils/mainasset';
function VerifyOTP(EmailID, ClientID, OTP) {

  let data = {};
  data.EmailID = EmailID;
  data.ClientID = ClientID;
  data.OTP = OTP;
  data.Source = Asset.source
  // var data = JSON.stringify({ "EmailID": "choksi.salil@gmail.com", "ClientID": 20299, "OTP": "123456" });

  // var config = {
  //     method: 'post',
  //     url: ApiValues.VerifyPassword,
  //     headers: {
  //         'Content-Type': 'application/json'
  //     },
  //     data: data
  // };

  // return axios(config)
  //     .then(function (response) {
  //         return response.data;
  //     })
  //     .catch(function (error) {
  //         return { "status": "F", "message": labels.messages.apierror }
  //     });

  return Networking.PostApi(ApiValues.VerifyPassword, data, labels.apiKey.apiKeys, labels.apiKey.apiKeys, true).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });


}
export default VerifyOTP

