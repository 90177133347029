import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Badges from '../../components/badge/badge';
import Label from '../../utils/constants/labels';
import { withStyles } from '@material-ui/core';
import { NegativeFinding } from '../../utils/commonfunction'
import NoData from '../../components/nodata/nodata';
import moment from 'moment';
import labels from '../../utils/constants/labels';
import { Redirect } from 'react-router';
import Headers from '../../components/report_common_header/report_common_header'
import getAllcommonDownloadDetails from '../../api/commondownload/commondownload';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import CommonSnackbar from '../../components/snackbar/snackbar'
import Loader from '../../components/loader/loader';
import './runningsipcss.css';
import combineStyles from '../../utils/stylesheets/combainstyle/combainstyle';
import CommonTableStyles from '../../utils/stylesheets/table/commontablestyle'
import colors from '../../utils/colors/colors'
import apiurl from '../../api/url/apiurl';
import Popover from '@material-ui/core/Popover';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DeletePopup from '../../components/popup/deletepopup';
import ErrorBoundary from '../../components/Errorhandling/Errorhandle';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TableFooter from '@material-ui/core/TableFooter';
import cancelRunningSIP from '../../api/runningsip/cancelsip';
import getxSipOrder from '../../api/purchaseorder/xsiporderapi';
import constants from '../../utils/constants/apiconstant';
import { MContext } from '../../components/pagination/renderpage';
import Pagination from '../../components/pagination/renderpage';
import { LabelSharp } from '@material-ui/icons';
import Networking from '../../utils/api/apiaccess';

//import { constant } from 'async';
const styles = theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  popperDisablePortal: {
    position: 'relative',
  },

  popoverlist: {
    width: "150px"
  },

  runningSIPBlock: {
    marginTop: '30px',
    [theme.breakpoints.down('920')]: {
      marginLeft: "1%"
    }
  },

  responsive: {
    display: "none !important",
    [theme.breakpoints.down('920')]: {
      display: "block !important",
      marginTop: 20,
      padding: "0px 10px"
    }
  },
  stickyHeader: {
    position: "sticky",
    height: "100%",
    width: "100%",
  },

  schemeNameWidth: {
    textAlign: "left",
    // width: '15vw',
    [theme.breakpoints.down("920")]: {
      // width:"65vw",
      textAlign: "left",
    }
  },
  otherWidth: {
    [theme.breakpoints.down("920")]: {
      marginTop: "10%",
    }
  },
  otherWidthAlignment: {
    [theme.breakpoints.down("920")]: {
      textAlign: "justify",
      marginTop: "3%",
    }

  },
  directionColumn: {
    display: "flex", flexDirection: "column"
  },

  hidediv: {
    [theme.breakpoints.down("920")]: {
      display: "none !important",
    }
  },

  cancePopup: {
    "& .MuiPaper-root": {
      // top: '287px !important',
      left: '1107px !important',
      maxWidth: '150px !important',

    },
  },
  noDataDesign: {
    display: "none !important",
    [theme.breakpoints.between('300', '920')]: {
      display: "block !important",
      width: "100%",
      height: 50,
      paddingTop: 30,
      paddingBottom: 30
    }
  },
  date: {
    // width:"10vw",
    display: "flex",
    flexDirection: "column",
  },
  schemeGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "firstGrid"
    }
  },
  secondGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "secondGrid"
    }
  },
  thirdGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "thirdGrid"
    }
  },
  fourthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "fourthGrid"
    }
  },
  fifthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "fifthGrid"
    }
  },
  sixthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "sixthGrid"
    }
  },
  seventhGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "seventhGrid"
    }
  },
  eighthGrid: {
    [theme.breakpoints.down("920")]: {
      gridArea: "eighthGrid"
    }
  },
  otherWidthAlignmentrupee: {
    marginRight: "5%",
  },
  dateGrid: {
    [theme.breakpoints.down("920")]: {
      // width:"25vw",
      textAlign: "left",
    }
  },
  totalGrid: {
    [theme.breakpoints.down("920")]: {
      textAlign: "justify",
      marginTop: "3%",
    }
  },
  amountGrid: {
    [theme.breakpoints.down("920")]: {
      marginTop: "10%",
    }
  },
  hideforDesktop: {
    [theme.breakpoints.up("920")]: {
      display: "none !important",
    }
  }

});
class RunningSIP extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false, accpdf: false,
      accexcel: false,
      anchorEl: null,
      cancelPopupOpen: false,
      List: [],
      folioNumber: "",
      message: "",
      SchemeDetails: [],
      loader: false,
      UCCCode: "",
      isDesktopView: true
    };
  }
  resize() {
    let currentHideNav = (window.innerWidth >= 920);
    if (currentHideNav !== this.state.isDesktopView) {
      // 
      this.setState({ isDesktopView: currentHideNav });
    }

  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.resize.bind(this))
  }
  componentDidMount() {
    window.addEventListener("resize", this.resize.bind(this));
    this.resize();
  }
  handleClick = (e, value) => {
    this.setState({ anchorEl: e.currentTarget })
    this.setState({ UCCCode: value.UCC })
    this.setState({ folioNumber: value.FolioNo })
    this.setState({ SchemeDetails: value })
    cancelRunningSIP(value.SIPRegNo, value.FolioNo, this.props.logindetails.ClientID).then((response) => {
      if (response.status == "S") {
        this.setState({ List: response.data })
      } else {

      }
    })
  }
  handleCancel = () => {
    this.setState({ loader: true })
    this.setState({ anchorEl: null })
    if (this.state.List != null && this.state.List.length != 0) {

      getxSipOrder(
        this.state.List[0]?.TransactionCode, this.state.UCCCode, this.state.List[0].SchemeCd, this.state.List[0].UniqueReferenceNumber,
        this.state.List[0].TRANS_MODE, this.state.List[0].DPTransactionMode, "", this.state.List[0].FREQUENCYTYPE,
        constants.sipPurchase.FrequencyAllowed, Number(this.state.List[0].INSTALLMENT_AMOUNT), Number(this.state.List[0].NO_OF_INSTALLMENTS), constants.sipPurchase.Remarks, this.state.SchemeDetails.FolioNo,
        this.state.List[0].FIRST_ORDER_FLAG, this.props.logindetails.SubBrokerCode, this.props.logindetails.EUIN, this.state.List[0].EUINNo, this.state.List[0].DPC,
        constants.sipPurchase.RegId, constants.sipPurchase.IPAdd, constants.sipPurchase.Param1,
        constants.sipPurchase.Param2, constants.sipPurchase.Param3, this.state.SchemeDetails.SchemeID, this.props.logindetails.ClientID, typeof (this.state.List[0].XSIPMANDATEID) == "string" ? parseInt(this.state.List[0].XSIPMANDATEID) : this.state.List[0].XSIPMANDATEID, constants.Common.Source
      ).then((response) => {
        //   
        if (response.status == "S") {
          this.setState({ open: true, message: response.message })

        } else {
          this.setState({ loader: false })
          this.setState({ open: true, message: response.message })

          this.onClose()
        }
      })


    } else {
      this.setState({ loader: false })

      this.setState({ open: true, message: labels.messages.apierror })
      this.onClose()
    }
  }
  handleClose = () => {

    this.setState({ anchorEl: null })

  };
  downloadclick = async (ext) => {
    var filename = ""
    var url = ""
    filename = `${labels.DownloadLabel.runningsip}${ext}`
    let data = {}
    if (ext === "pdf") {
      //MM/DD/YYYY
      this.setState({ accpdf: true })
      data = {
        "ClientID": this.props.logindetails.ClientID, "AccountID": 0, "ReportID": labels.Report.runningSip, "WithRedeem": false, "AsOnDate": moment(this.props.date).format("DD MMM YYYY"),
        "SubReport": 0, "DownloadAs": 1, "ProductID": 1, "FamilyID": 0, "L5ID": this.props.L5ID, "L4ID": 0
      }
        url = `${apiurl.RunningSippdf}${this.props.logindetails.ClientID}&asOnDate=${this.props.date == null || this.props.date == undefined ? moment().format("DD MMM YYYY") : moment(this.props.date).format("DD MMM YYYY")}&accountId=${0}&ProductID=${this.props.flag === "equity" ? 2 : 1}&FamilyID=${0}&L5ID=${this.props.L5ID}&L4ID=${0}`
    } else {
      this.setState({ accexcel: true })
      data = {
        "ClientID": this.props.logindetails.ClientID, "AccountID": 0, "ReportID": labels.Report.runningSip, "WithRedeem": false, "AsOnDate": moment(this.props.date).format("DD MMM YYYY"),
        "SubReport": 0, "DownloadAs": 2, "ProductID": 1, "FamilyID": 0, "L5ID": this.props.L5ID, "L4ID": 0
      }
       url = `${apiurl.RunningSipexcel}${this.props.logindetails.ClientID}&asOnDate=${this.props.date == null || this.props.date == undefined ? moment().format("DD MMM YYYY") : moment(this.props.date).format("DD MMM YYYY")}&accountId=${0}&ProductID=${this.props.flag === "equity" ? 2 : 1}&FamilyID=${0}&L5ID=${this.props.L5ID}&L4ID=${0}`

    }
    // url = await Networking.PostApi(apiurl.ExportDownloadURL, data).then((res) => {
    //   let a = ""
    //   if (res.status == "S" && res.data != "") {
    //     a = res.data
    //   }
    //   return a
    // }).catch((er) => {
    //   return ""
    // })
    if (url.length != 0) {
      getAllcommonDownloadDetails(url, ext, filename, this.props.logindetails?.SessionID).then((response) => {
        if (response === "success") {
          if (ext === "pdf") {
            this.setState({ accpdf: false })
          }
          if (ext === "xlsx") {
            this.setState({ accexcel: false })
          }
          this.setState({ message: response })
        } else {
          this.setState({ open: true })
          if (ext === "pdf") {
            this.setState({ accpdf: false })
          }
          if (ext === "xlsx") {
            this.setState({ accexcel: false })
          }
          this.setState({ message: labels.messages.apierror })
        }
      })
    } else {
      this.setState({ open: true, message: labels.messages.apierror })
      if (ext === "pdf") {
        this.setState({ accpdf: false })
      }
      if (ext === "xlsx") {
        this.setState({ accexcel: false })
      }
    }

  }
  handlesnanckClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ open: false })
  };
  handleListItemClick = () => {
    this.setState({ cancelPopupOpen: true, anchorEl: null })
  };

  onClose = () => {
    this.setState({ cancelPopupOpen: false })
  }
  // handleClick = (event, data) => {
  //   this.state.selectedList = []
  //   this.setState({ anchorEl: event.currentTarget })
  //   this.setState({ selectedList: data, stp: data.STPAllowed, switch: data.SwitchAllowed, swp: data.SWPAllowed, purchasemore: data.PurchaseAllowed, Redemption: data.RedemptionAllowed, })
  // };


  render() {
    const { classes } = this.props
    try {
      return (<>
        {this.props.isRunningSipLoading == true ?
          <>
            <Loader page="skeletonreport" />
          </>
          :
          <>
            {/* <div className={classes.SIPHeader}> */}
            <div className={[classes.stickyHeader].join(' ')}>
              <Headers
                flag={this.props.flag}
                showfilter={false}
                showdownload={true}
                header={this.props.header}
                List={this.props.runningSIP}
                pdfload={this.state.accpdf}
                newxcelLoad={this.state.accexcel}
                data={this.props.runningSIP}
                scheme={this.props.runningSIP != null
                  &&
                  this.props.runningSIP.length == 0 ? <NoData /> : `${this.props.runningSIP.length} ${this.props.header}s`
                    &&
                    this.props.runningSIP.length == 1 ? `${this.props.runningSIP.length} ${this.props.header}` : `${this.props.runningSIP.length} ${this.props.header}s`}
                onclick={this.downloadclick} page={"runningsip"} />
            </div>
            <ErrorBoundary>
              {/* {this.state.isDesktopView? */}
              <div className={classes.runningSIPBlock}>
                <div className={classes.runningSIPBlock}>
                  {this.props.runningSIP.length > 0 ? <>

                    <div className={[this.props.grid, classes.tableHeader].join(' ')} style={{ textAlign: "left", marginTop: "2%", paddingRight: 10 }}>

                      {this.props.title.map((item, titleindex) => {
                        // 
                        return (<div key={titleindex}
                          className={item.name == "SCHEME NAME" || item.name == "SCRIP NAME" ? classes.schemeNameWidth : "" && titleindex == 0 || this.props.title.length - 1 == titleindex ? "" : classes.hidediv} style={{ display: 'flex', flexDirection: 'column', textAlign: item.name == "SCHEME NAME" || item.name == "SCRIP NAME" ? "left" : "right", paddingLeft: item.name == "SCHEME NAME" || item.name == "SCRIP NAME" ? 10 : 0 }}>
                          <div>
                            {item.name}
                          </div>
                          {item.subheader != undefined ? <div>{item.subheader}</div> : <div></div>}

                        </div>
                        )
                      })}

                    </div>
                    {/* {console.log(this.props.runningSIP)} */}
                    <Pagination data={this.props.runningSIP}>
                      <MContext.Consumer>
                        {(context) => {
                          return context.map((value, mainindex) => {
                            return (
                              <div key={mainindex}>
                                <div className={[this.props.grid, classes.subCategoryGroupContainer, mainindex == 0 && classes.borderRadiousFirstIndex].join(' ')} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                  <div className={[classes.schemeNameWidth, classes.subCatgoryValue, "firstGrid", classes.schemeGrid, classes.directionColumn].join(' ')}>
                                    {value.SchemeName}
                                    <div>
                                      <Badges className="badges" color={window.globalConfig.color.ZERO_BACKGROUND} textcolor={window.globalConfig.color.LABLE_GRAY} text={`${value.FolioNo}`} />
                                    </div>

                                  </div>

                                  <div className={[classes.otherWidth, classes.subCatgoryValue, "secondGrid", classes.secondGrid, classes.directionColumn].join(' ')}>
                                    <div >
                                      <span className={classes.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                        {labels.summaryPage.sip}
                                      </span>
                                      <span className={classes.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                      {NegativeFinding(value.SIPAmount, "", "", "", "number")}
                                    </div>
                                    <div>
                                      <Badges className="badges" color={window.globalConfig.color.ZERO_BACKGROUND} textcolor={window.globalConfig.color.LABLE_GRAY} text={`${value.Frequency}`} />
                                    </div>
                                  </div>

                                  <div className={[classes.date, classes.dateGrid, "thirdGrid", classes.thirdGrid, classes.subCatgoryValue].join(' ')}>
                                    <span className={classes.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                      {labels.summaryPage.date}
                                    </span>
                                    {typeof (value.StartDate) != 'object' ? moment(value.StartDate).format("DD MMM YYYY") : labels.messages.NA}
                                    <span> {typeof (value.EndDate) != 'object' ? moment(value.EndDate).format("DD MMM YYYY") : labels.messages.NA}</span>

                                  </div>
                                  <div className={[classes.totalGrid, "fourthGrid", classes.fourthGrid, classes.subCatgoryValue].join(' ')}>
                                    <span className={classes.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                      {labels.summaryPage.totalunits}
                                    </span>
                                    {NegativeFinding(value.TotalUnits, "", "", "", "number")}
                                  </div >



                                  <div className={[classes.otherWidthAlignment, "fifthGrid", classes.fifthGrid, classes.subCatgoryValue].join(' ')}>
                                    <span className={classes.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                      {labels.summaryPage.Puramount}
                                    </span>
                                    <span className={classes.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                    {NegativeFinding(value.PurAmount, "", "", "", "number")}
                                    <br></br>
                                    <div className={[classes.subCatgoryValue].join(' ')}>
                                      <span className={classes.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                        {labels.summaryPage.PurNav}
                                      </span>
                                      {NegativeFinding(value.PurPrice, "", "", "", "number")}
                                    </div>
                                  </div>

                                  <div className={[classes.amountGrid, "sixthGrid", classes.sixthGrid, classes.subCatgoryValue].join(' ')}>
                                    <span className={classes.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                      {labels.summaryPage.Divamount}
                                    </span>
                                    <span className={classes.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                    {NegativeFinding(value.DivAmount, "", "", "", "number")}
                                  </div>



                                  <div className={["seventhGrid", classes.seventhGrid, classes.subCatgoryValue].join(' ')}>
                                    <span className={classes.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                      {labels.summaryPage.Curamount}
                                    </span>
                                    <span className={classes.subcategoryRupee}>{labels.currency.rupeesymbol}</span>
                                    <span>{NegativeFinding(value.CurAmount, "", "", "", "number")}</span>
                                    <br />
                                    <div className={[classes.subCatgoryValue].join(' ')}>
                                      <span className={classes.hideforDesktop} style={{ color: window.globalConfig.color.LABLE_GRAY, fontSize: 10, display: "block" }}>
                                        {labels.summaryPage.CurrentNav}
                                      </span>
                                      <span>{NegativeFinding(value.CurrentNAV, "", "", "", "number")}</span>
                                    </div>
                                  </div>

                                  <div className={["eighthGrid", classes.eighthGrid, classes.subCatgoryValue].join(' ')}>
                                    <span style={{ color: window.globalConfig.color.LABLE_GRAY }}>{labels.currency.rupeesymbol}</span>
                                    <span style={{ color: NegativeFinding(value.ProfitLoss) }}>{NegativeFinding(value.ProfitLoss, "", "", "", "number")}</span>
                                    <div>
                                      <Badges className="badges" color={NegativeFinding(value.XIRR, window.globalConfig.color.LIGHT_GREEN_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND, window.globalConfig.color.BORDER)} textcolor={NegativeFinding(value.XIRR, window.globalConfig.color.GREEN)} text={NegativeFinding(value.XIRR, "", "", "", "percentage")} />
                                    </div>
                                  </div>

                                  <div>
                                    {value.SIPRegNo != "" && value.SIPRegNo != "N.A" && <>
                                      {/* <div className="icons" onClick={(e) => this.handleClick(e, value)} ></div> */}
                                      <div onMouseLeave={() => this.handleClose()} onKeyUp={() => this.handleClose()} onKeyDown={() => this.handleClose()}>
                                      </div></>
                                    }
                                  </div>

                                </div>

                                {context.length - 1 == mainindex && this.props.totalrunningSIP.map((totalitem, index) => {
                                  return (
                                    <div key={index}>
                                      <div className={[classes.NineSplitContainer, classes.displayTotalContainer].join(' ')} style={{ paddingLeft: 10, paddingRight: 10 }}>
                                        <div className={[classes.schemeNameWidth, classes.directionColumn].join(' ')}>
                                          {Label.messages.grandtotal}

                                        </div>

                                        <div className={[classes.directionColumn].join(' ')}>
                                          <div>
                                            <span className={classes.totalRupee}>{labels.currency.rupeesymbol}</span>
                                            {NegativeFinding(totalitem.TotalSIPAmount, "", "", "", "number")}                        </div>

                                        </div>
                                        <div className={[].join(' ')}>
                                        </div >

                                        <div className={[].join(' ')}>
                                        </div>

                                        <div className={[].join(' ')}>
                                          <span className={classes.totalRupee}>{labels.currency.rupeesymbol}</span>
                                          {NegativeFinding(totalitem.TotalPurAmount, "", "", "", "number")}                      </div>

                                        <div className={[].join(' ')}>
                                          <span className={classes.totalRupee}>{labels.currency.rupeesymbol}</span>
                                          {NegativeFinding(totalitem.TotalDivAmount, "", "", "", "number")}
                                        </div>

                                        {/* <div className={[classes.otherWidthAlignment].join(' ')}>

                                        </div> */}
                                        <div className={[].join(' ')}>
                                          <span className={classes.totalRupee}>{labels.currency.rupeesymbol}</span>

                                          {NegativeFinding(totalitem.TotalCurAmount, "", "", "", "number")}                      </div>



                                        <div className={[classes.otherWidthAlignmentrupee].join(' ')}>
                                          <span style={{ color: NegativeFinding(totalitem.TotalXIRR, window.globalConfig.color.WHITE) }}><span className={classes.totalRupee} >{Label.currency.Rupee}</span>{NegativeFinding(totalitem.TotalProfitLoss, "", "", "", "number")}</span>
                                          <div>
                                            <Badges className="badges" color={NegativeFinding(totalitem.TotalXIRR, window.globalConfig.color.ZERO_BACKGROUND, window.globalConfig.color.LIGHT_RED_BACKGROUND)} textcolor={NegativeFinding(totalitem.TotalXIRR, window.globalConfig.color.LABLE_GRAY)} text={NegativeFinding(totalitem.TotalXIRR, "", "", "", "percentage")} />
                                          </div>
                                        </div>
                                      </div>


                                    </div>
                                  )
                                })}
                              </div>

                            )
                          })
                        }
                        }

                      </MContext.Consumer>
                    </Pagination>

                  </> : <div></div>
                  }
                </div>
              </div>




              {/* cancel popup added  */}
              <div className={classes.cancePopup}
                onMouseLeave={() => this.handleClose()}
                onKeyUp={() => this.handleClose()}
                onKeyDown={() => this.handleClose()}
                onWheel={() => this.handleClose()}
              >

                <Popover
                  //    disableScrollLock={true}
                  classes={classes.popperDisablePortal}
                  open={Boolean(this.state.anchorEl)}
                  anchorEl={this.state.anchorEl}
                  onClose={this.handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',

                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  onScroll={this.handleScroll}
                // disablePortal={true}
                >
                  <div className="xraypopover"  >

                    <div>
                      {/* {this.state.switch && */}
                      <ListItem
                        button
                        // selected={this.selectedIndex === 1}
                        className="popoverlist"
                        onClick={() => this.handleListItemClick()}
                      >
                        <ListItemText primary={"Cancel SIP"} />
                      </ListItem>
                      {/* } */}

                    </div>

                  </div>
                </Popover>
                <DeletePopup header={"Confirmation"} onClick={this.handleCancel} loader={this.state.loader} open={this.state.cancelPopupOpen} text={labels.runningSip.cencelpopup} onClose={this.onClose} />

              </div>
              <CommonSnackbar open={this.state.open} message={this.state.message} severity={'warning'} handlesnanckClose={this.handlesnanckClose} />
            </ErrorBoundary>
          </>
        }

      </>
      )
    }
    catch (err) {

      return <Redirect to={labels.Path.NotFound} />
    }
  }
}
const mapStateToProps = state => {
  return {
    // sessionID: state.Login.sessionID,
    // clientID: state.Login.logindetails.ClientID,
    // userID: state.Login.userID,
    // date: state.Login.date,
    logindetails: state.Login.logindetails,
    // runningSIP: state.RunningSIP.runningSIP,
    // totalrunningSIP: state.RunningSIP.totalrunningSIP,
    // isRunningSipLoading: state.RunningSIP.isRunningSipLoading,
    // L5ID: state.Login.L5ID,

  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({}, dispatch)
}
const mergedStyle = combineStyles(styles, CommonTableStyles)
export default withStyles((theme) => ({
  ...styles(theme),
  ...CommonTableStyles(theme),
}),
  { withTheme: true })(connect(mapStateToProps, mapDispatchToProps)(RunningSIP))
