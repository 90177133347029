import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from "../../utils/constants/labels"
import Networking from '../../utils/api/apiaccess';
function getRiskScoreReportDetails(set_id, email, form_data,dob, mobile,  fName, lName,) {
    // let data = {};
    // data.set_id = set_id
    // data.email = email
    // data.form_data = form_data
    // var config = {
    //     method: "post",
    //     url: ApiValues.RiskScoreReport,
    //     headers: {
    //         'Content-Type': 'application/json',
    //         //'api-key': 'L]ak;YzY!47FqLUbhcC:4b8Zaw',
    //         // 'api-key': labels.apiKey.apiKeys,
    //         "Authorization":labels.apiKey.apiKeys
    //     },
    //     data: data
    // };
    // return axios(config)
    //     .then(function (response) {
    //         return response.data;
    //     })
    //     .catch(function (error) {
    //         return { "status": "F", "message": labels.messages.apierror }
    //     });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "set_id": set_id,
        "fname": fName,
        "lname": lName,
        "mobile": mobile,
        "dob": dob,
        "email": email,
        "form_data": form_data
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    return fetch("https://planapi.finnovate.in/api/risk/generateriskscore", requestOptions)
        .then(response => response.json())
        .then(result => { return result })
        .catch(error => {
            return { "status": "F", "message": labels.messages.apierror }
        });

}
export default getRiskScoreReportDetails