import ApiValues from '../url/apiurl'
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
var axios = require('axios');
function getAlertTemplate(mailID,clientID) {
   
    return Networking.GetApi(`${ApiValues.alertTemplate}?mailerID=${mailID}&clientID=${clientID}`,"").then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });

}
export default getAlertTemplate
