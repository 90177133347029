import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './fonts/Inter-Medium.ttf'
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
let isUpdated = false
ReactDOM.render(
  <React.StrictMode>

    <div style={{ height: "100%" }} >
      <App />
    </div>

  </React.StrictMode>,
  document.getElementById('root')
);
const configuration = {
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      isUpdated = true
      document.getElementById("new-updates").style.display = "flex"
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      // window.location.reload();
      //}
    }
  }
};
serviceWorkerRegistration.register(configuration);
reportWebVitals();
// const configuration = {
//   onUpdate: (registration) => {
//     if (registration && registration.waiting) {
//       if (window.confirm('New version available!  refresh to update your app?')) {
//         registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//         window.location.reload();
//       }
//     }
//   }
// };

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//  ))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//  serviceWorker.register(configuration);
