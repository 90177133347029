import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepConnector from '@material-ui/core/StepConnector';
import Typography from '@material-ui/core/Typography';
import { HomeOutlined } from '@material-ui/icons';
import Button from '@material-ui/core/Button';
import colors from '../../utils/colors/colors';
import NoData from '../../components/nodata/nodata';
const ColorlibConnector = withStyles({

    alternativeLabel: {
        top: 22,
        backgroundColor: "#bdbdbd",
        // "& .MuiStepConnector-vertical":{
        padding: 0
        // }
    },
    active: {
        backgroundColor: window.globalConfig.color.GREEN,
        // width: 3,

        padding: 0,
        '& $line': {
            backgroundColor: window.globalConfig.color.GREEN,
            ///  width: 3,
            // "& .MuiStepConnector-vertical":{
            padding: 0
            // }
        },
        //   backgroundImage:
        //     'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
    },

    completed: {
        padding: 0,
        // width: 3,
        '& $line': {
            backgroundColor: window.globalConfig.color.GREEN,
            //  width: 3,
            // "& .MuiStepConnector-vertical":{
            padding: 0
            // }
            //   backgroundImage:
            //     'linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)',
        },

    },
    line: {
        height: 3,
        border: 0,
        //  width: 3,
        //   width: 3,
        padding: 0,
        // marginLeft: 10,
        // marginRight: 10,
        backgroundColor: '#eaeaf0',
        borderRadius: 1,
        "& .MuiStepConnector-vertical": {
            padding: 0
        }
    },
})(StepConnector);
const useColorlibStepIconStyles = makeStyles({
    root: {
        // backgroundColor: '#ccc',
        zIndex: 1,
        color: window.globalConfig.color.BLACK_PRIMARY,
        width: 40,
        height: 40,
        display: 'flex',
        borderRadius: '50%',
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${window.globalConfig.color.GREEN}`,

    },
    active: {
        border: `1px solid ${window.globalConfig.color.GREEN}`,
        // backgroundImage:
        //     'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    },
    completed: {
        backgroundColor: window.globalConfig.color.GREEN,
        color: window.globalConfig.color.WHITE,

        // backgroundImage:
        //     'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    },
    text: {

        "& .MuiStepLabel-root": {
            textAlign: "left",
            width: "max-content"
        },
        "& .MuiPaper-root": {
            backgroundColor: "none"
        },
        "& .MuiStepLabel-labelContainer": {
            width: "0px !important",
            textAlign: "left"
        },
        // "& .MuiStepLabel-root": {
        //     textAlign: "left"
        // },
        '& $line': {
            width: 4
        },
        "& .MuiStepConnector-vertical": {
            padding: "0px !important"
        },
        "& .MuiStepConnector-root": {
            flex: 0
        }
    }
});
function ColorlibStepIcon(props) {
    const classes = useColorlibStepIconStyles();
    const { active, completed } = props;
    const icons = props.icons
    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
            })}
        >
            {icons[String(props.icon)]}
        </div>
    );
}
ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    iconsList: PropTypes.object,
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        margin: "auto",

    },
    button: {
        marginRight: theme.spacing(1),
    },
    accessCustomSteps: {
        '& .MuiStepConnector-root > .Mui-disabled': {
            width: 3,
            backgroundColor: colors.BORDER,

        },
        "& .MuiStepConnector-active": {
            width: 3,
            backgroundColor: colors.BROWN,
        },
        '& .MuiStepConnector-completed': {
            width: 3,
            backgroundColor: colors.GREEN
        }
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));
export default function CustomizedSteppers(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const steps = props.steps;
    // const handleNext = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
    // };

    // const handleBack = () => {
    //     setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // };

    // const handleReset = () => {
    //     setActiveStep(0);
    // };
    //console.log(props.type)
    if (props.type == "cutomizedsteps") {
        return (
            steps.length != 0 ? <Stepper activeStep={props.activeStep} className={classes.accessCustomSteps} style={{ backgroundColor: "transparent", maxWidth: 'max-content', textAlign: "left" }} orientation={props.orientation} >
                {steps?.map((label, index) => (
                    <Step key={label} >
                        <StepLabel style={{ textAlign: "left" }} error={label.error} className={classes.text} {...label}>{label.name}</StepLabel>
                    </Step>
                ))}
            </Stepper> : <NoData style={{ margin: "20% 0% 16% 0%" }}></NoData>)
    } else {
        return (
            <div className={classes.root}>
                {props.flag == "horizontal" ?
                    <Stepper className={classes.root} style={{ backgroundColor: "transparent" }} alternativeLabel orientation={props.flag} activeStep={props.activeStep} connector={<ColorlibConnector />}>
                        {steps.map((label) => (
                            <Step key={label}>

                                <StepLabel StepIconComponent={ColorlibStepIcon} StepIconProps={{ icons: props.icons }}>{label}</StepLabel>

                            </Step>
                        ))}
                    </Stepper> :
                    <Stepper className={classes.root} activeStep={props.activeStep} style={{ backgroundColor: "transparent" }} orientation="vertical" >
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel style={{ textAlign: "left" }} className={classes.text}>{label}</StepLabel>
                            </Step>
                        ))}
                    </Stepper>}
                <div>

                </div>
            </div>
        );
    }

}




