import React, { PureComponent } from 'react';
import {
  PieChart, Pie, Sector, Cell, Tooltip, ResponsiveContainer
} from 'recharts';
import { withStyles } from '@material-ui/core/styles';
import './progress.css';
import NoData from '../nodata/nodata';
import Grid from '@material-ui/core/Grid';
import CustomTooltip from '../tooltip/customtooltip'
import ErrorBoundary from '../Errorhandling/Errorhandle';
import colors from '../../utils/colors/colors';
const styles = theme => ({
  chartWrap: {
    // overflow: 'hidden',
  },
  chartFluid: {
    width: '100%',
    minWidth: 300,
    height: 400,
  },
  root: {
    // marginTop: 40,
    // modifiedby yogitha
    marginTop: 0,
    paddingRight: 26, [theme.breakpoints.down('sm')]: {
      marginTop: 40,
      marginTop: 0,
      paddingRight: 0,
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      paddingRight: 0,
    }
  },

});
class Dount extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      name: "",
      bordercolor: "",
      value: ""
    }
  }
  pieenter = (item) => {
    this.setState({ name: item.name, value: item.value, bordercolor: item.fill })
  }
  rendercusontooltip = () => {
    return <CustomTooltip page={this.props.report != "family" ? "" : "barchart"} fill={this.state.bordercolor} item={this.props.data} {...this.props}{...this.state} />

  }

  render() {

    const { classes } = this.props;
    return (
      <ErrorBoundary>
        <div className={classes.root} id="root">
          <div className={classes.chartWrap} >
            {this.props.summaryData.length === 0 ? <div style={{ marginRight: '15px', textAlign: 'center', border: `1px solid ${window.globalConfig.color.BORDER}`, borderRadius: 8, height: 235, marginTop: 27 }}> <NoData height={15} /></div> :
              <div className={classes.chartFluid} id="chartfluid">
                <ResponsiveContainer height={400}>
                  <PieChart>
                    <Pie
                      data={this.props.summaryData}
                      outerRadius={"90%"} innerRadius={80}
                      dataKey="value"
                      onMouseEnter={this.pieenter}
                    >
                      {
                        this.props.summaryData.map((entry, index) => <Cell key={`cell-${index}`} fill={entry.color} />)
                      }
                    </Pie>
                    <Tooltip content={this.rendercusontooltip} wrapperStyle={{
                      paddingLeft: "10px", fontSize: "14px", Position: 'relative'
                    }} />
                  </PieChart>
                </ResponsiveContainer>
              </div>
            }
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}
export default withStyles(styles)(Dount);