import * as types from '../../constants/actionsType'
const initialState = {
    answerOrder: [],
    isAnswerOrderLoading: false
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.RISK_SCORE:
            // 
            return {
                ...state,
                answerOrder: action.payload != null ? action.payload : state.answerOrder,
                isQuestionOrderLoading: false
            }
        // case types.EMPTY_RISK_SCORE:
        //     return {
        //         answerOrder: [],
        //     }
        case types.LOADER_RISK_SCORE:
            return {
                ...state,
                isQuestionOrderLoading: true
            }
        default:
            return state
    }
}
export default reducer