import * as types from '../../constants/actionsType';
import getUpcommingSIPDetails from '../../../api/portfolio/upcommingsip';
import { replaceNull } from '../../../utils/commonfunction';
export function getUpcommingSIPReport(ClientID, ProductID, FamilyID, L5ID, L4ID, UCC) {
    return (dispatch) => {
        getAllUpcommingSIPReportAction(dispatch, ClientID, ProductID, FamilyID, L5ID, L4ID, UCC);
    };
}
function getAllUpcommingSIPReportAction(dispatch, ClientID, ProductID, FamilyID, L5ID, L4ID, UCC) {
    dispatch({
        type: types.EMPTY_UPCOMMING_SIP,
        payload: [],
    });
    dispatch({
        type: types.LOADER_UPCOMMING_SIP,
        payload: true,
    });
    getUpcommingSIPDetails(ClientID, ProductID, FamilyID, L5ID, L4ID, UCC).then((data) => {

        dispatch({
            type: types.UPCOMMING_SIP,
            payload: replaceNull(data, "NA"),
        });
        dispatch({
            type: types.PREVENT_API_CALL,
            payload: [{ ProductID: 11, api: "Upcomming SIP" }]
        });
    })
        .catch((error) => {
            // console.log(error)
            dispatch({
                type: types.UPCOMMING_SIP,
                payload: [],
            });
            dispatch({
                type: types.PREVENT_API_CALL,
                payload: [{ ProductID: 11, api: "Upcomming SIP" }]
            });
        })

}