//component created by dulcy
import React from "react";
import { withStyles } from '@material-ui/core';
import color from "../../utils/colors/colors";
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Buttons from '@material-ui/core/Button';
import colors from "../../utils/colors/colors";
const styles = theme => ({
    btnApply: {
        width: "-webkit-fill-available",
        position: "relative",
        // height: "auto",
        background: window.globalConfig.color.BROWN,
        padding: 11,
        textAlign: "center",
        ///  lineHeight: "36px",
        color: window.globalConfig.color.WHITE,
        fontWeight: 600,
        fontSize: "14px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        //  lineHeight: "36px",
        // marginLeft: "7px",
        borderRadius: "5px",
        border: "1px solid transparent",
        '&:hover': {
            background: window.globalConfig.color.PRIMARY,
            color: window.globalConfig.color.WHITE,
        },
        '&:focus': {
            border: ` 1px solid ${window.globalConfig.color.RosyBrown} !important`,
            outline: "none",
        },
        [theme.breakpoints.down('600')]: {
            fontSize: "12px",
        }

    },
    btnApplyDisabled: {
        width: "-webkit-fill-available",

        background: window.globalConfig.color.BROWN,
        padding: 11,
        ///  lineHeight: "36px",
        color: window.globalConfig.color.WHITE,
        fontWeight: 600,
        fontSize: "14px",
        //  lineHeight: "36px",

        opacity: 0.5,
        // marginLeft: "7px",
        borderRadius: "5px",
        border: "1px solid transparent",
        [theme.breakpoints.down('600')]: {
            fontSize: "12px",
        }
    },
    btnClear: {
        width: "-webkit-fill-available",
        position: "relative",
        // height: "auto",
        background: window.globalConfig.color.RosyBrown,
        padding: 11,
        textAlign: "center",
        ///  lineHeight: "36px",
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontWeight: 600,
        fontSize: "14px",
        //  lineHeight: "36px",
        // marginLeft: "7px",
        borderRadius: "5px",
        border: "1px solid transparent",

        '&:hover': {
            background: window.globalConfig.color.PRIMARY_LIGHT,
            color: window.globalConfig.color.WHITE,
            [theme.breakpoints.down('600')]: {
                fontSize: "12px",
            }

        },
        '&:focus': {
            borderColor: "rgb(175,119,45)",
            outline: "none",
        },
    },
    buttonProgress: {
        width: "-webkit-fill-available",
        color: theme.palette.text.secondary,
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },

    btnClearDisabled: {
        width: "-webkit-fill-available",
        background: window.globalConfig.color.RosyBrown,
        //  padding: 5,
        // lineHeight: "36px",
        color: window.globalConfig.color.BLACK_PRIMARY,
        // height: 46,
        padding: 11,
        height: "auto",
        fontWeight: 600,
        fontSize: "14px",
        // lineHeight: "36px",


        // marginLeft: "5px",
        borderRadius: "5px",
        border: "1px solid transparent",
        opacity: 0.5,
        cursor: 'not-allowed',
        [theme.breakpoints.down('600')]: {
            fontSize: "12px",
        }
    },

    btnContinue: {
        backgroundColor: window.globalConfig.color.GREEN,
        color: window.globalConfig.color.WHITE,
        width: "-webkit-fill-available",
        height: 52,
        padding: 11,
        fontWeight: 600,
        fontSize: "14px",

        borderRadius: "5px",
        border: "1px solid transparent",
        textTransform: "uppercase",
    },
    rightIcon: {
        marginLeft: theme.spacing(1)
    },
    iconSmall: {
        fontSize: 20,
    },
    signArrow: {
        transform: theme.direction === 'rtl' ? 'rotate(180deg)' : 'none',
        [theme.breakpoints.down('md')]: {
            display: 'none'
        },
    },
    disabled: {
        boxShadow: 'rgb(19 89 161 / 10%) 0px 0px 20px 0px',
        transition: '0.3s',
        borderRadius: '5px',

        width: 'max-content',
        justifyContent: 'flex-start',
        // margin: '2% 42%',
        padding: '2%',
        gap: '20px',
        display: 'grid',
        gridTemplateColumns: '23px 147px',
        cursor: 'not-allowed',
        [theme.breakpoints.down("md")]: {
            // width: '18%',
            margin: '4% 40%'
        },
        [theme.breakpoints.down("sm")]: {
            // width: '23%',
            margin: '4% 37%'
        },
        [theme.breakpoints.down("xs")]: {
            // width: '42%',
            margin: '6% 26%',
            padding: '3% 3% 3% 3%',
        },
        opacity: 0.5
    },
    card: {
        boxShadow: 'rgb(19 89 161 / 10%) 0px 0px 20px 0px',
        transition: '0.3s',
        borderRadius: '5px',

        width: 'max-content',
        justifyContent: 'flex-start',
        // margin: '2% 42%',
        padding: '2%',
        gap: '20px',
        display: 'grid',
        gridTemplateColumns: '23px 147px',
        cursor: 'pointer',
        [theme.breakpoints.down("md")]: {
            // width: '18%',
            margin: '4% 40%'
        },
        [theme.breakpoints.down("sm")]: {
            // width: '23%',
            margin: '4% 37%'
        },
        [theme.breakpoints.down("xs")]: {
            // width: '42%',
            margin: '6% 26%',
            padding: '3% 3% 3% 3%',
        },
    },
    buttonAlignment: {
        // textIndent: '1em',
        marginTop: '3px',
        textAlign: 'left',
        fontWeight: '600'
    },

});
class Button extends React.Component {
    constructor() {
        super();

    }
    onClick = () => {
        if (!this.props.disabled) {
            if (this.props.onClick != undefined) {
                this.props.onClick()
            }
        }
    }
    componentDidMount() {
        var element = document.getElementsByClassName("btnSecondary");
        // element[0].style.borderBottom = `1px solid ${color}`
        if (element.length != 0) {
            element[0].style.backgroundColor = window.globalConfig.color.BROWN
            document.getElementsByClassName("btnSecondary")[0].style.backgroundColor = window.globalConfig.color.RosyBrown
        }
    }
    componentDidUpdate() {
        var element = document.getElementsByClassName("btnSecondary");
        // element[0].style.borderBottom = `1px solid ${color}`
        if (element.length != 0) {
            element[0].style.backgroundColor = window.globalConfig.color.BROWN
            document.getElementsByClassName("btnSecondary")[0].style.backgroundColor = window.globalConfig.color.RosyBrown
        }
    }
    //  (props)
    render() {
        const { classes } = this.props
        if (this.props.page == "primary") {
            return (<div className={[this.props.disabled ? classes.btnApplyDisabled : classes.btnApply, this.props.className, "btn"].join(" ")} style={{ cursor: this.props.loader || this.props.disabled ? "not-allowed" : "pointer" }} onClick={this.onClick}>
                {this.props.loader &&
                    <CircularProgress size={20} thickness={1} style={{ color: this.props.disabled ? window.globalConfig.color.BROWN : window.globalConfig.color.WHITE, position: "absolute", left: "50%", top: 9 }} />
                }
                <span>{this.props.name}</span>
            </div>);
        }
        else if (this.props.page == "secondaryPrimary") {
            return (
                <Buttons variant="contained" onClick={this.props.onClick} disabled={this.props.loader} fullWidth style={{ backgroundColor: window.globalConfig.color.GREEN, color: window.globalConfig.color.WHITE, height: 52 }} size="large" type="submit" className="btnLoginSubmit">
                    {this.props.loader && <CircularProgress style={{ color: window.globalConfig.color.WHITE }} size={24} className={classes.buttonProgress} />}
                    {this.props.name}
                    {!this.props.loader && <ArrowForward className={[classes.rightIcon, classes.iconSmall, classes.signArrow].join('')} />}

                </Buttons>
            )
        }
        else if (this.props.page == "outlined") {
            return (
                <Buttons variant="outlined" onClick={this.props.onClick} disabled={this.props.loader} fullWidth style={{ padding: 12, width: '100%', borderColor: '#253858', color: '#253858' }} size="large" type="submit" className="btnLoginSubmit">
                    {this.props.loader && <CircularProgress style={{ color: window.globalConfig.color.WHITE }} size={24} className={classes.buttonProgress} />}
                    {this.props.name}

                </Buttons>
            )
        }
        else if (this.props.page == "uploadButton") {
            return (
                //<div style={{ color: colors.BLACK_PRIMARY, position: "relative", fontSize: 14, textDecoration: "underline", cursor: this.props.disabled ? "not-allowed" : "pointer", opacity: this.props.disabled || this.props.loader ? 0.5 : 1 }}>
                <div className={this.props.disabled ? classes.disabled : classes.card} onClick={this.props.onClick}>
                    {this.props.Icon}
                    <div className={classes.buttonAlignment}>
                        {this.props.label}
                    </div>
                </div>
                //</div>
            )
        }
        else if (this.props.page == "linkButton") {
            return (
                <div style={{ color: colors.BLACK_PRIMARY, position: "relative", fontSize: 14, textDecoration: "underline", cursor: this.props.disabled ? "not-allowed" : "pointer", opacity: this.props.disabled || this.props.loader ? 0.5 : 1 }} onClick={this.onClick}>
                    {this.props.loader &&
                        <CircularProgress size={20} thickness={1} style={{ color: this.props.disabled ? window.globalConfig.color.BROWN : window.globalConfig.color.WHITE, position: "absolute", left: "50%", top: 9 }} />
                    }
                    {this.props.name}</div>
            )
        }
        else {
            return (
                <button className={this.props.show || this.props.disabled ? [classes.btnClearDisabled, "btnSecondary"].join(" ") : [classes.btnClear, "btnSecondary"].join(" ")} style={{ opacity: this.props.disabled ? 0.5 : 1, cursor: this.props.disabled ? "not-allowed" : "pointer" }} disabled={this.props.disabled} onClick={
                    () => {
                        if (!this.props.disabled) {
                            if (this.props.onClick != undefined) {
                                this.props.onClick()
                            }
                        }
                    }
                } >
                    {this.props.show && <CircularProgress size={23} thickness={1} style={{
                        color: this.props.disabled ? window.globalConfig.color.BROWN : window.globalConfig.color.WHITE, position: "absolute", marginLeft: this.props.loderAlign == "align" ? 35 : 2, marginTop: "-3px"
                    }} />}{this.props.name}
                </button>


            );
        }

    }

}
export default withStyles(styles)(Button)
