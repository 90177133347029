import * as types from '../../constants/actionsType'
import { bindActionCreators } from 'redux';
import secureStorage from '../../../utils/securestorage'
import labels from '../../../utils/constants/labels';
import { replaceNull } from '../../../utils/commonfunction';
// import { removeUnwanted } from '../../../utils/commonfunction';
const initialState = {
    dropdowndetails: {},
    schemeFilteredList: [],
    schemeIndex: 0,
    TempList: [],
    isLoadingScheme: false,
    mandateTypeList: [],
    bankList: [],
    mandateCreation: {},
    mandateCollection: [],
    masterData: {},
    // pushing the response in to existing array
    mandateDetails: {},
    loaderdropdown: false,

}
const colors = labels.schemeColors
const repeatFor = (arr, size, data) => {

    data.amc.map((val1, index1) => {
        data.scheme.map((val, index) => {
            if (val.AMCID == val1.ID) {
                return val.color = colors[index1]
            }

        })
    })

    return data;
}
// const repeatFor = (arr, size, scheme, amc) => {
//     // let data = {}
//     amc?.map((val1, index1) => {
//         data = scheme?.map((val, index) => {
//             // if (val.AMCID == val1.ID) {
//             // console.log(val.AMCID == val1.ID ? colors[index1] : val?.color || window.globalConfig.PRIMARY)
//             return { ...val, color: val.AMCID == val1.ID ? colors[index1] : val.color || window.globalConfig.PRIMARY }
//             // }

//         })
//     })
//     // console.log(amc, data, scheme)
//     return data;
// }
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_DROPDOWN_DETAILS:
            var a = {}
            var temp = {}
            if (Object.keys(action.payload).length == 0) {

            } else {
                a = repeatFor(colors, action.payload.scheme.length, action.payload)
                temp = a.scheme
                a.scheme = temp

            }
            if (action.payload.amc.length == 0) {

            }
            else {
                secureStorage.setItem("amc", action.payload.amc);
            }
            return {
                ...state,
                dropdowndetails: Object.keys(action.payload).length == 0 ? state.dropdowndetails : a,
                // isLoadingScheme: false
            }
        case types.GET_DROPDOWN:
            return {
                ...state,
                dropdowndetails: action.payload.length == 0 ? state.dropdowndetails : action.payload,
                isLoadingScheme: false
            }
        case types.GET_MANDATE_DETAILS:
            return {
                ...state,
                mandateTypeList: action.data,
                bankList: action.data
            }
        case types.TOP_TEN:
            return {
                ...state,
                schemeFilteredList: state.dropdowndetails.scheme.filter((item) => item.Ranking < 11)
            }
        case types.FILTER_CLICK:

            var schemeTempFilteredList = state.dropdowndetails.scheme;
            if (action.payload[2]) {
                // 
                state.schemeFilteredList = state.TempList
                // 
            } else {
                if (action.payload[0] != null && action.payload[0] != undefined && action.payload[0].length > 0) {

                    schemeTempFilteredList = schemeTempFilteredList.filter((tar) => {
                        return action.payload[0].some((des) => {

                            return des.value === tar.AMCID;
                        });
                    });
                }
                if (action.payload[1] != null && action.payload[1] != undefined && action.payload[1].length > 0) {
                    schemeTempFilteredList = schemeTempFilteredList.filter((tar) => {
                        return action.payload[1].some((des) => {
                            return (des.value == 2) ? tar.OneTimeAllowed === 1 : (des.value == 3) ? tar.SipAllowed === 1 : ""
                        });
                    });
                }
                state.TempList = schemeTempFilteredList
                state.schemeIndex = state.TempList.length
                state.schemeFilteredList = schemeTempFilteredList
            }

            // 
            return {
                ...state,
                isLoadingScheme: false

            }
        case types.SCHEME_FILTER:
            var schemeTempFilteredList = [...state.dropdowndetails.scheme];
            if (action.payload[7]) {
                state.schemeFilteredList = state.TempList
            }
            else {
                //Search result
                if (action.payload[0] != null && action.payload[0] != "" && action.payload[0] != undefined && schemeTempFilteredList != undefined) {
                    schemeTempFilteredList = schemeTempFilteredList.filter(data => data.SchemeName.toUpperCase().includes(action.payload[0].toUpperCase()))
                }

                //Category selection
                if (action.payload[1] != null && action.payload[1] != undefined && action.payload[1].length > 0) {
                    schemeTempFilteredList = schemeTempFilteredList.filter((tar) => {
                        return action.payload[1].some((des) => {

                            return des.label.toUpperCase() === tar.AssetClass.toUpperCase();
                        });
                    });
                }

                //Risk type selection
                if (action.payload[2] != null && action.payload[2] != undefined && action.payload[2].length > 0) {
                    //   
                    schemeTempFilteredList = schemeTempFilteredList.filter((tar) => {
                        // 
                        return action.payload[2].some((des) => {
                            return des.label.toUpperCase() === tar.Risk.toUpperCase();
                        });
                    });
                }
                //Type selection
                if (action.payload[3] != null && action.payload[3] != undefined && action.payload[3].length > 0) {
                    //   
                    schemeTempFilteredList = schemeTempFilteredList.filter((tar) => {
                        // 
                        return action.payload[3].some((des) => {
                            if (des.label === "All") {
                                return schemeTempFilteredList;
                            }
                            else {
                                return des.label === tar.InvestmentType;
                            }
                        });
                    });
                }

                //AMC type selection 
                if (action.payload[4] != null && action.payload[4] != undefined && action.payload[4].length > 0) {
                    schemeTempFilteredList = schemeTempFilteredList.filter((tar) => {
                        return action.payload[4].some((des) => {
                            return des.value === tar.AMCID;
                        });
                    });
                }

                //OneTime allowed and Sip allowed filter
                if (action.payload[5] != null && action.payload[5] != undefined && action.payload[5].length > 0) {
                    schemeTempFilteredList = schemeTempFilteredList.filter((tar) => {
                        return action.payload[5].some((des) => {
                            return (des.value == 2) ? tar.OneTimeAllowed === 1 : (des.value == 3) ? tar.SipAllowed === 1 : ""
                        });
                    });
                }

                //Rating selection
                if (action.payload[6] != null && action.payload[6] != undefined && action.payload[6].length > 0) {
                    schemeTempFilteredList = schemeTempFilteredList.filter((tar) => {
                        return action.payload[6].some((des) => {
                            return des.label === tar.ratings.toString();
                        });
                    });
                }
                state.TempList = schemeTempFilteredList.filter((value, i) => (value.SipAllowed == 1 || value.OneTimeAllowed == 1))
                state.schemeIndex = state.TempList.length
                state.schemeFilteredList = schemeTempFilteredList
            }
            return {
                ...state,
                isLoadingScheme: false
            }
        case types.GENERATE_MANDATE:
            return {
                ...state,
                mandateDetails: action.payload
            }
        case types.GET_MANDATE_CREATION_RESPONSE:
            return {
                ...state,
                mandateCreation: action.data
            }
        case types.GET_MANDATE_COLLECTION:
            return {
                ...state,
                mandateCollection: action.data
            }
        case types.GET_MANDATE_TYPES:
            return {
                ...state,
                mandateTypeList: action.data,
                bankList: action.data
            }
        case types.EMPTY_DROPDOWN:
            return {
                ...state,
                dropdowndetails: {},
                schemeFilteredList: [],
                schemeIndex: 0,
                TempList: [],
                isLoadingScheme: true,
                mandateTypeList: [],
                bankList: [],
                mandateCreation: {},
                mandateCollection: [],
                // pushing the response in to existing array
                mandateDetails: {},
                loaderdropdown: false
            }
        case types.LOADER_DROPDOWN:
            return {
                ...state,
                loaderdropdown: action.payload
            }
        // case types.NETWORK_MANDATE:
        //     return {
        //         ...state,
        //         mandateDetails: action.payload
        //     }

        case types.EMPTY_SCHEMES_DETAILS:
            return {
                ...state,
                dropdowndetails: {
                },
                isLoadingScheme: false
            }
        case types.SCHEMES_DETAILS:
            var a = {}
            var temp = {}
            let dropdown = state.dropdowndetails
            dropdown.scheme = action.payload
            // console.log(action.payload, state.masterData)
            if (Object.keys(action.payload).length == 0) {

            } else {
                a = repeatFor(colors, dropdown.scheme.length, dropdown)
                temp = a.scheme
                a.scheme = temp
            }
            // console.log(a)
            return {
                ...state,
                dropdowndetails: a,
                isLoadingScheme: false
            }
        case types.LOADER_SCHEMES_DETAILS:
            return {
                ...state,
                isLoadingScheme: action.payload
            }
        case types.GET_MASTER:
            // console.log(action.payload)
            if (action.payload.amc?.length != 0) {
                secureStorage.setItem("amc", action.payload.amc);
            }
            return {
                ...state,
                dropdowndetails: replaceNull(action.payload, "NA"),
                isLoadingScheme: false
            }
        case types.EMPTY_MASTER:
            return {
                ...state,
                masterData: [],
                isLoadingScheme: false
            }
        case types.LOADER_MASTER:
            return {
                ...state,
                // masterData: [],
                isLoadingScheme:action.payload
                // isLoadingScheme: false
            }
        default:
            return state
    }
}
export default reducer;