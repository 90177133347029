import * as types from '../../constants/actionsType';
import getAllElss from '../../../api/afrreport/elss'
import constants from '../../../utils/constants/apiconstant';
export function getELSSDetails(ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, flag) {
  return (dispatch) => {
    getAllELSSAction(dispatch, ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, flag);
  };
}
function getAllELSSAction(dispatch, ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID, flag) {
  // if (ProductID == 1) {
    dispatch({
      type: types.EMPTY_ELSS,
      payload: [],
    });
    dispatch({
      type: types.LOADER_ELSS,
      payload: true,
    });
  // } else {
  //   dispatch({
  //     type: types.EMPTY_EQUITY_ELSS,
  //     payload: [],
  //   });
  //   dispatch({
  //     type: types.LOADER_EQUITY_ELSS,
  //     payload: true,
  //   });
  // }

  getAllElss(ClientID, AccountID, AsonDate, ProductID, FamilyID, L5ID, L4ID)
    .then((data) => {
      // 

      //
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: ProductID, api: "ELSSTransaction" }],
      });
      // if (ProductID == 1) {
        dispatch({
          type: types.ELSS,
          payload: data,
        });

      // } else {
      //   dispatch({
      //     type: types.EQUITY_ELSS,
      //     payload: data,
      //   });

      // }

    })
    .catch((error) => {
      dispatch({
        type: types.PREVENT_API_CALL,
        payload: [{ ProductID: ProductID, api: "ELSSTransaction" }],
      });

      dispatch({
        type: types.EMPTY_ELSS,
        payload: [],

      });


    })
}
