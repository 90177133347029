import React from 'react';
import Button from '../../components/custombutton/button'
import Dialog from '@material-ui/core/Dialog';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import colors from '../../utils/colors/colors';
import color from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import DatePicker from '../../components/datepickers/date_pickers';
import CommonDropdown from '../../components/dropdown/dropdownschemes';
import Textinput from '../../components/textinput/textinput';
import moment from 'moment';
import { updatebankdetails, getSearchBranchDetails } from '../../redux/actions/profile/profile'
import { getAIFandPMSDetails } from "../../redux/actions/aifandpms/aifandpms_action";
import { numbersandDeciamlOnly, hasNull, round, notAllowdSpecialCharater, amountCommaSeperator, replaceComma } from '../../utils/commonfunction';
import CommonSnackbar from '../../components/snackbar/snackbar';
import { Listing } from '../../redux/actions/aifandpms/aifandpms_action';
import { bindActionCreators } from 'redux';
import getAddUpdateDeleteAssetTransaction from '../../api/aifandpms/add_update_delete_asset_transaction';
import { addUpdateDeleteTransaction, updateAsset } from '../../redux/actions/aifandpms/aifandpms_action';
import getAddAssetValuation from '../../api/aifandpms/add_asset_valuation';
import getNPSTransactionDataEntry from '../../api/nps/nps_transaction_data_entry';
const styles = theme => ({

    col: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.between('320', '374')]: {
            marginTop: '4%',
            width: '94%',
        },
        [theme.breakpoints.between('375', '424')]: {
            marginTop: '4%',
            width: '96%',
        },
        [theme.breakpoints.between('425', '600')]: {
            marginTop: '4%',
            width: '98%',
        },
    },
    cols: {
        marginLeft: '2%',
        marginTop: '5%',
        width: '100%',
        display: "flex", flexDirection: "row",
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: ' wrap',
            justifyContent: 'center',
            marginLeft: '0%',
        },
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            flexWrap: ' wrap',
            justifyContent: 'center',
        },
    },
    // col3: {
    //     display: "flex",
    //     flexDirection: "row",modified by dulcy
    //     marginLeft: "20%"
    // },
    colheader1: {
        color: color.PRIMARY_BORDER,
        fontSize: "14px",
        textAlign: "left"
    },
    colheader2: {
        textAlign: "left",
        color: color.black,
        fontSize: "14px"
    },

    header: {
        backgroundColor: color.PRIMARY,
        color: "white",
        height: 50,
        fontWeight: 500,
        position: "sticky",
        top: "0px",
        zIndex: "1"
    },
    txtHeader: {
        padding: 15,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    },
    dialogPaper: {
        //width: "400px",
        // height: "174px",
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    allocationUnitsGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(4,1fr)",
        gap: 5,
        width: "50%"
    },
    subHeader: {
        textAlign: 'left',
        marginTop: '1pc',
        color: window.globalConfig.color.LABLE_GRAY,
        fontWeight: 500,
        // marginLeft: 10,
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
    subHeader1: {
        textAlign: 'left',
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: 14,
        marginTop: 0, marginBottom: 8,
        // marginLeft: 10,
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
    cardSeparatorLine: {
        height: 0.5,
        width: "100%",
        // backgroundColor: window.globalConfig.color.AliceBlue,
        backgroundColor: window.globalConfig.color.LABLE_GRAY,
        marginBottom: '1pc',
        marginTop: '6px',
        // marginLeft: 10,
        [theme.breakpoints.down("920")]: {
            display: "none"
        }
    },
    secondContainerFirstHalf: {
        display: "grid",
        gridTemplateColumns: "29% auto 32%",
        gap: 20,
        height: "23%"
    },
    dialogPaper2: {
        width: "auto",
        height: "auto",
    },
    footerStyle: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        bottom: 0,
        right: "18px",
        // position: "absolute",
        paddingBottom: 10,
        justifyContent: "flex-end"
    },
    twoGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2,1fr)',
        gap: 10,
        padding: 10
    },
    clearIcon: {
        cursor: "pointer"
    },
    popupWholeCard: {
        padding: 10
    },
    spanmsg: {
        width: "240%",
        marginTop: "-10%",
        marginLeft: "10%"
    },
    spanerr: {
        color: "rgb(225, 45, 45)",
        fontSize: "13px",
    }

});
let timer = ''
class AddNpsTransactionAndEvaluation extends React.Component {
    constructor() {
        super();
        this.state = {

            open: false,
            date: "",
            dateTemp: moment().format("DD MMM YYYY"),
            dateError: "",

            anchorEl: null,
            flag: "",
            message: "",
            severity: "success",
            openSnackBar: false,
            selectedList: [],
            amount: "",
            amountError: "",
            transactionTypeID: "",
            transactionTypeName: "",
            anchorEl: null,
            transactionTypeError: "",
            investmentDateTemp: moment().format("DD MMM YYYY"),
            investmentDate: "",
            investmentDateError: "",
            equity: "",
            equityError: "",
            commodity: "",
            commodityError: "",
            other: "",
            otherError: "",
            gold: "",
            goldError: "",
            goldNav: "",
            goldNavError: "",
            otherNav: "",
            otherNavError: "",
            commodityNav: "",
            commodityNavError: "",
            equityNav: "",
            equityNavError: "",
            ID: '',
            NPSID: '',
            commonError: ""
        }
    }
    handlesnanckClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.setState({ open: false })
    };
    clearState = () => {
        this.setState({
            open: false,

            anchorEl: null,
            flag: "",
            message: "",
            severity: "success",
            openSnackBar: false,
            loader: false,
            addMoreLoader: false,
            addSaveMore: false,
            amount: "",
            amountError: "",
            transactionTypeID: "",
            transactionTypeName: "",
            anchorEl: null,
            transactionTypeError: "",
            investmentDateTemp: moment().format("DD MMM YYYY"),
            investmentDate: "",
            investmentDateError: "",
            equity: "",
            equityError: "",
            commodity: "",
            commodityError: "",
            other: "",
            otherError: "",
            gold: "",
            goldError: "",
            goldNav: "",
            goldNavError: "",
            otherNav: "",
            otherNavError: "",
            commodityNav: "",
            commodityNavError: "",
            equityNav: "",
            equityNavError: "",
            ID: '',
            commonError: ""

        })
    }
    onClosePopup = () => {
        this.clearState()
        this.props.onClose()
    }


    componentDidMount() {
        // this.setState({ flag: this.props.transactionFlag, id: this.props.id })
        const { editList } = this.props;

        if (this.props.accessAddTransaction != undefined) {
            this.props.accessAddTransaction.current = this.setEditValues
        }

    }
    setEditValues = (editList) => {
        if (Object.keys(editList).length != 0) {
            this.setState({
                selectedList: editList, amount: editList.Amount.name,

                transactionTypeID: editList.TransactionTypeID.name,
                transactionTypeName: editList.TransactionType.name,

                investmentDate: editList.InvestmentDate.name,

                equity: editList.Equity.name,
                NPSID: editList.NPSID.name,
                ID: editList.ID.name,
                commodity: editList.Commodity.name,

                other: editList.Other.name,

                gold: editList.Gold.name,

                goldNav: editList.Gold.subHeader1,

                otherNav: editList.Other.subHeader1,

                commodityNav: editList.Commodity.subHeader1,

                equityNav: editList.Equity.subHeader1,
            })
        }

    }

    handleFocus = (event) => event.target.select();
    handleToastClose = () => {
        this.setState({ openSnackBar: false })
    }
    handleAmountChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 14)) {
            this.setState({ amount: amountCommaSeperator(removeComma.trim()), amountError: "" })
        }

    }
    handleEquityChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ equity: amountCommaSeperator(removeComma.trim()), equityError: "" })
        }
    }
    handleCommodityChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ commodity: amountCommaSeperator(removeComma.trim()), commodityError: "" })
        }
    }
    handleGoldChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ gold: amountCommaSeperator(removeComma.trim()), goldError: "" })
        }
    }
    handleOtherChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ other: amountCommaSeperator(removeComma.trim()), otherError: "" })
        }
    }
    handleEquityNavChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ equityNav: amountCommaSeperator(removeComma.trim()), equityNavError: "" })
        }
    }
    handleCommodityNavChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ commodityNav: amountCommaSeperator(removeComma.trim()), commodityNavError: "" })
        }
    }
    handleGoldNavChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ goldNav: amountCommaSeperator(removeComma.trim()), goldNavError: "" })
        }
    }
    handleOtherNavChange = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ otherNav: amountCommaSeperator(removeComma.trim()), otherNavError: "" })
        }
    }
    handleCommonError = (e) => {
        let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        if (numbersandDeciamlOnly(removeComma.trim(), 6)) {
            this.setState({ commonNav: amountCommaSeperator(removeComma.trim()), commonError: "" })
        }
    }
    handleTransactionTypeChange = (val) => {
        // let removeComma = e.target.value == "" ? e.target.value : e.target.value.replaceAll(",", "")

        // if (numbersandDeciamlOnly(removeComma)) {
        this.setState({ transactionTypeName: val[0].label, transactionTypeID: val[0].value, transactionTypeError: "" })
        // }
    }
    handleInvestmentDateClick = (e) => {
        this.setState({ anchorEl: e.currentTarget })
    }
    handleInvestmentDateClose = () => {
        this.setState({ anchorEl: null })
    }
    handleInvestmentDateChange = (value) => {
        this.setState({ investmentDateTemp: moment(value).format("DD MMM YYYY") })

    }
    handleInvestmentDateApply = (date) => {
        this.setState({ investmentDate: moment(date).format("DD MMM YYYY"), investmentDateError: "" })
    }
    handleSubmit = (type) => {
        const expression = "/^[0-9]*$/"
        let isValid = true
        if (this.state.amount == "") {
            isValid = false
            this.setState({ amountError: labels.messages.required })
        } else if (Number(replaceComma(this.state.amount)) == 0) {
            isValid = false
            this.setState({ amountError: labels.messages.Amount })
        }

        if (this.state.transactionTypeID == '') {
            isValid = false
            this.setState({ transactionTypeError: labels.messages.required })

        }
        if (this.state.investmentDate == "") {
            isValid = false
            this.setState({ investmentDateError: labels.messages.required })
        }
        if (this.state.equity == "") {
            isValid = false
            this.setState({ equityError: labels.messages.required })
        }
        if (this.state.equityNav == "") {
            isValid = false
            this.setState({ equityNavError: labels.messages.required })
        }
        if (this.state.commodity == "") {
            isValid = false
            this.setState({ commodityError: labels.messages.required })
        }
        if (this.state.commodityNav == "") {
            isValid = false
            this.setState({ commodityNavError: labels.messages.required })
        }
        if (this.state.gold == "") {
            isValid = false
            this.setState({ goldError: labels.messages.required })
        }
        if (this.state.goldNav == "") {
            isValid = false
            this.setState({ goldNavError: labels.messages.required })
        }
        if (this.state.other == "") {
            isValid = false
            this.setState({ otherError: labels.messages.required })
        }
        if (this.state.otherNav == "") {
            isValid = false
            this.setState({ otherNavError: labels.messages.required })
        }

        if (this.state.equity == "" && this.state.commodity == "" && this.state.gold == "" && this.state.other == "") {
            isValid = false
            this.setState({
                equityError: labels.messages.required,
                commodityError: labels.messages.required,
                goldError: labels.messages.required,
                otherError: labels.messages.required
            })
        }
        // else if (Number(replaceComma(this.state.equity)) == 0 && Number(replaceComma(this.state.commodity)) == 0 && Number(replaceComma(this.state.gold)) == 0 && Number(replaceComma(this.state.other)) == 0) {
        //     isValid = false
        //     // this.setState({ equityError: labels.messages.Amount,
        //     //     commodityError: labels.messages.Amount,
        //     //     goldError: labels.messages.Amount,
        //     //     otherError: labels.messages.Amount
        //     // })
        //     // this.setState({ openSnackBar: true, message: labels.messages.Error, severity: 'warning'})
        //     this.setState({ commonError: labels.messages.Error })
        // } else {
        //     isValid = true
        //     this.setState({
        //         equityError: "",
        //         commodityError: "",
        //         goldError: "",
        //         otherError: "",
        //         commonError: ""
        //     })
        // }
        if (isValid) {
            let data = {}
            data.NPSID = this.props.listID
            data.clientID = this.props.logindetails.ClientID
            data.transactionTypeID = this.state.transactionTypeID
            data.amount = Number(replaceComma(this.state.amount))
            data.investmentDate = this.state.investmentDate
            data.teUnits = Number(replaceComma(this.state.equity))
            data.tcUnits = Number(replaceComma(this.state.commodity))
            data.tgUnits = Number(replaceComma(this.state.gold))
            data.toUnits = Number(replaceComma(this.state.other))
            data.tenav = Number(replaceComma(this.state.equityNav))
            data.tcnav = Number(replaceComma(this.state.commodityNav))
            data.tgnav = Number(replaceComma(this.state.goldNav))
            data.tonav = Number(replaceComma(this.state.otherNav))
            { this.state.ID != "" && (data.id = this.state.ID) }

            data.flag = this.state.ID == '' ? 1 : 2
            if (type == 'save') {
                this.setState({ loader: true })
            } else {
                this.setState({ addMoreLoader: true })
            }
            getNPSTransactionDataEntry(data).then((response) => {
                // let transactionBackupListing = this.props.List != undefined ? this.props.List : []
                if (response.status == 'S') {
                    this.props.npsTransactionList(this.props.listID)

                    this.setState({ openSnackBar: true, message: response.message, severity: 'success' })

                } else {
                    this.setState({ openSnackBar: true, message: response.message, severity: 'warning' })
                }
                if (this.props.callBack != undefined) {
                    this.props.callBack(response.table2)
                }
                if (type == 'save') {
                    this.setState({ loader: false })
                    timer = setTimeout(() => {
                        this.onClosePopup()

                    }, 1000);
                } else {
                    this.clearState()
                    this.setState({ addMoreLoader: false })
                }
            }).catch((err) => {
                if (type == 'save') {
                    this.setState({ loader: false })
                } else {
                    this.setState({ addMoreLoader: false })
                }
                this.setState({ openSnackBar: true, message: labels.messages.apierror, severity: 'warning' })

            })
            // 
        }
    }
    componentWillUnmount() {
        clearTimeout(timer)
    }
    render() {
        // console.log(this.props.List)
        const { classes } = this.props;
        return (<div>
            <Dialog

                open={this.props.open}
                onClose={this.onClosePopup}
                onScroll={this.props.onScroll}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: classes.dialogPaper }}
                style={this.props.style}

            >
                <div className={classes.popupcard}>
                    <div className={classes.header}>
                        <div className={classes.txtHeader}>{this.props.header}
                            <span className={classes.clearIcon} onClick={this.onClosePopup}>&#x2715;</span>
                        </div>
                    </div>
                    <div className={classes.popupWholeCard}>
                        <div className={classes.secondContainerFirstHalf}>
                            <Textinput handleChange={this.handleAmountChange}
                                value={this.state.amount}
                                error={this.state.amountError}
                                align={"right"}
                                otherProps={{ autoFocus: true, maxLength: 14, name: "currentValue", onFocus: this.handleFocus, onBlur: this.handleDecimal }}

                                page={"normal"} label={labels.Nps.amount}
                                placeholder={labels.messages.amountZeroPlaceholder}
                            />
                            <CommonDropdown
                                //autoselect={this.props.List.length == 0 ? true : false}
                                handleOnChange={this.handleTransactionTypeChange}
                                value={this.props.dropData=="Add Transaction"?this.props.transactionTypeList[0].label : this.state.transactionTypeName}
                                error={this.state.transactionTypeError}
                                disabled={this.props.dropData == "Add Transaction" ? true : false}
                                type={"normalwithsearch"}
                                id="ddl_transactiontype"
                                placeholder={labels.Nps.transactionTypePlaceholder}
                                label={labels.Nps.transactionType}
                                height={190}
                                List={this.props.transactionTypeList.length != 0 ? this.props.transactionTypeList.map((item) => {
                                    return { ...item, id: item.label?.search(/inves/i) != -1 ? 1 : 0 }
                                }) : []}
                            // value={this.state.broker}
                            />
                            <DatePicker
                                style={{ minWidth: 140 }}
                                handleClick={(e) => this.handleInvestmentDateClick(e)}
                                handleApply={this.handleInvestmentDateApply}
                                showingdate={this.state.investmentDateTemp}
                                dateChange={this.handleInvestmentDateChange}
                                handleClose={this.handleInvestmentDateClose}
                                anchorEl={this.state.anchorEl}
                                error={this.state.investmentDateError}
                                maxdate={moment().format("DD MMM YYYY")}
                                // allowed={false} 
                                id="dp_aifInvestmentDate"
                                label={labels.Nps.investmentDate}
                                placeholder={labels.messages.invDatePlaceholder}
                                date={this.state.investmentDate} />

                        </div>
                        <div className={classes.subHeader1}>{labels.Nps.allocationUnits}</div>
                        <div className={classes.allocationUnitsGrid}>
                            <Textinput handleChange={this.handleEquityChange}
                                value={this.state.equity}
                                error={this.state.equityError}
                                // otherProps={{ autoFocus: true, }}
                                align='right'
                                page={"sideLabel"} label={labels.Nps.equity}
                            //  placeholder={labels.Nps.latestValuePlaceholder}
                            />
                            <Textinput handleChange={this.handleCommodityChange}
                                value={this.state.commodity}
                                error={this.state.commodityError}
                                // otherProps={{ autoFocus: true, }}
                                align='right'
                                page={"sideLabel"} label={labels.Nps.commodity}
                            //  placeholder={labels.Nps.latestValuePlaceholder}
                            />
                            <Textinput handleChange={this.handleGoldChange}
                                value={this.state.gold}
                                error={this.state.goldError}
                                // otherProps={{ autoFocus: true }}
                                align='right'
                                page={"sideLabel"} label={labels.Nps.gold}
                            //placeholder={labels.Nps.latestValuePlaceholder}
                            />
                            <Textinput handleChange={this.handleOtherChange}
                                value={this.state.other}
                                error={this.state.otherError}
                                // otherProps={{ autoFocus: true, }}
                                align='right'
                                page={"sideLabel"} label={labels.Nps.other}
                            //  placeholder={labels.Nps.latestValuePlaceholder}
                            />
                            <div className={classes.spanmsg}>
                                <span className={classes.spanerr}>{this.state.commonError}</span>
                            </div>
                        </div>
                        <div className={classes.subHeader1} >{labels.Nps.allocationNav}</div>
                        <div className={classes.allocationUnitsGrid}>
                            <Textinput handleChange={this.handleEquityNavChange}
                                value={this.state.equityNav}
                                error={this.state.equityNavError}
                                // otherProps={{ autoFocus: true, }}
                                align='right'
                                page={"sideLabel"} label={labels.Nps.equity}
                            //  placeholder={labels.Nps.latestValuePlaceholder}
                            />
                            <Textinput handleChange={this.handleCommodityNavChange}
                                value={this.state.commodityNav}
                                error={this.state.commodityNavError}
                                // otherProps={{ autoFocus: true, }}
                                align='right'
                                page={"sideLabel"} label={labels.Nps.commodity}
                            //  placeholder={labels.Nps.latestValuePlaceholder}
                            />
                            <Textinput handleChange={this.handleGoldNavChange}
                                value={this.state.goldNav}
                                error={this.state.goldNavError}
                                // otherProps={{autoFocus: true, }}
                                align='right'
                                page={"sideLabel"} label={labels.Nps.gold}
                            //placeholder={labels.Nps.latestValuePlaceholder}
                            />
                            <Textinput handleChange={this.handleOtherNavChange}
                                value={this.state.otherNav}
                                error={this.state.otherNavError}
                                // otherProps={{ autoFocus: true, }}
                                align='right'
                                page={"sideLabel"} label={labels.Nps.other}
                            //  placeholder={labels.Nps.latestValuePlaceholder}
                            />
                        </div>
                    </div>
                    <div className={classes.footerStyle}>

                        {this.state.ID != "" && <div style={{ width: "22%", marginLeft: 10, marginRight: 15 }}>
                            <Button page={"secondary"} disabled={this.state.loader || this.state.addMoreLoader} name={labels.messages.cancel} onClick={this.onClosePopup} loader={this.props.loader} show={this.props.loader} loading={"true"} />
                        </div>}
                        <div style={{ width: "22%", marginRight: 15 }}>
                            <Button page={"primary"} disabled={this.state.loader || this.state.addMoreLoader} name={this.state.ID != "" ? "Update" : labels.messages.saveButton} onClick={() => this.handleSubmit("save")} loader={this.props.loader} show={this.props.loader} loading={"true"} />
                        </div>
                        {this.state.ID == "" &&
                            <div style={{ marginLeft: 10, marginRight: 15 }}>
                                <Button page={"primary"} disabled={this.state.loader || this.state.addMoreLoader} name={labels.messages.saveAddMoreButton} onClick={() => this.handleSubmit("addMore")} loader={this.state.addMoreLoader} show={this.state.addMoreLoader} loading={"true"} />
                            </div>}

                    </div>
                </div>
            </Dialog>
            <CommonSnackbar message={this.state.message} open={this.state.openSnackBar} severity={this.state.severity} handlesnanckClose={this.handleToastClose} />

        </div>
        )
    }
}
const mapStateToProps = state => ({
    login: state.Login.loginError,
    productList: state.Dataentry.alternativeAsset != undefined && state.Dataentry.alternativeAsset != null && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset != "" && state.Dataentry.alternativeAsset.table12 != null && state.Dataentry.alternativeAsset.table12.length != 0 ?
        state.Dataentry.alternativeAsset?.table12 : [],
    logindetails: state.Login.logindetails,
    transactionTypeList: state.Dataentry.npsAndInsuranceDropdownList != null && state.Dataentry.npsAndInsuranceDropdownList.length != 0 && state.Dataentry.npsAndInsuranceDropdownList.table1 != null && state.Dataentry.npsAndInsuranceDropdownList.table1.length != 0 ? state.Dataentry.npsAndInsuranceDropdownList.table1.map((item) => {
        return { "label": item.TEXT, "value": item.VALUE }
    }) : [],
    aifList: state.AIFandPMS.aifList

})
const mapDispatchToProps = dispatch => {
    return bindActionCreators({ updateAsset, addUpdateDeleteTransaction, getAIFandPMSDetails, Listing }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AddNpsTransactionAndEvaluation))

