//created by yogitha
import ApiValues from '../url/apiurl'
var axios = require('axios');
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
function getTrackEquityDetails(url,file,pan,password,companyID) {
var data = new FormData();
data.append('File', file[0]);
// (companyID)
// data.append("CompanyID",companyID)
return Networking.PostApi(url+"?Password="+password+"&PAN="+pan+"&CompanyID="+companyID, data,labels.apiKey.apiKeys).then(result => { return result })
.catch(function (error) {
  return { "status": "F", "message": labels.messages.apierror }
});

}
export default getTrackEquityDetails