import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
import Networking from '../../utils/api/apiaccess';
import { uniqueId } from '../../utils/commonfunction';
function getSTPApidetails(placeOrder, ClientCode,
  FromSchemeCd, ToSchemeCd, BuySellType, TransactionMode, FolioNo, StartDate, FrequencyType, NoOfTransfers,
  InstallmentAmount, FirstOrderToday, SubBrCode, EUINDeclaration, EUINNumber, Remarks,
  SchemeID, ToSchemeID, ClientID, Source, flag, OrderId, STPRegNo) {
  var data = {
    "TransType": "NEW",

    "STPType": !placeOrder ? "AMC" : "BSE",

    "ClientCode": ClientCode,

    "FromBSESchemeCode": FromSchemeCd,

    "ToBSESchemeCode": ToSchemeCd,

    "BuySellType": BuySellType,

    "TransactionMode": TransactionMode,

    "FolioNo": FolioNo?.search("/") > 0 ? FolioNo?.split("/")[0] : FolioNo,

    "STPRegNo": STPRegNo,

    "IntRefNo": uniqueId(),

    "StartDate": StartDate,

    "FrequencyType": FrequencyType,

    "NoOfTransfers": NoOfTransfers.toString(),

    "InstAmount": InstallmentAmount.toString(),

    "InstUnit": "",

    "FirstOrderToday": FirstOrderToday,

    "SubBrokerCode": "",

    "EUINDeclaration": EUINDeclaration,

    "EUINNumber": EUINNumber,

    "Remarks": Remarks,

    "EndDate": "",

    "SubBrokerARN": "",

    "Filler1": "",

    "Filler2": "",

    "Filler3": "",

    "Filler4": "",

    "Filler5": "",

    "IsAMC": !placeOrder,
    "ClientID": ClientID,
    "SchemeID": SchemeID,
    "ToSchemeID": ToSchemeID,
    "Source": Source
  };

  // data.ClientCode=ClientCode,
  // data.FromBSESchemeCode=FromSchemeCd,
  // data.ToBSESchemeCode=ToSchemeCd,
  // data.BuySellType=BuySellType,
  // data.TransactionMode=TransactionMode
  // data.FolioNumber=FolioNo,
  // data.StartDate=StartDate,
  // data.FrequencyType=FrequencyType
  // data.NoOfTransfers=NoOfTransfers
  // data.InstallmentAmount=InstallmentAmount
  // data.FirstOrderToday=FirstOrderToday
  // data.SubBrokerCode=""
  // data.EUINDeclaration=EUINDeclaration
  // data.EUINNumber=EUINNumber
  // data.Remarks=Remarks
  // data.SchemeID=SchemeID
  // data.ToSchemeID=ToSchemeID
  // data.ClientID=ClientID
  // data.Source=Source
  // data.IsAMC=placeOrder=="AMC"?true:false
  // if(flag===undefined){

  // }else{
  //   data.OrderId=OrderId
  // }
  // return Networking.PostApi(ApiValues.STP, data).then(result => { return result })
  //   .catch(function (error) {
  //     return { "status": "F", "message": labels.messages.apierror }
  //   });
  return Networking.PostApi(ApiValues.STPNew, data,labels.apiKey.apiKeys).then(result => { return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
}
export default getSTPApidetails
