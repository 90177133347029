import * as types from '../../constants/actionsType'
const initialState = {
  folioNumber: [],
  mappingDetails: [],
  similarschemes:[],
  linePerformance:[],

  isNetworkLow:false
}
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SCHEME_ID:
      return {
        ...state,
        folioNumber: action.payload==null||action.payload.length==0 ? state.folioNumber : action.payload.folioNumber,
        mappingDetails: action.payload,
        isNetworkLow:false
      }
      case types.SIMILAR_SCHEMES:
      return {
        ...state,
        similarschemes: action.payload==null||action.payload.length==0 ? state.similarschemes : action.payload,
       }
     
      case types.NETWORK_SCHEME_DETAILS:
        return{
          ...state,
          isNetworkLow:action.payload
        }
       
    default:
      return state
  }
}
export default reducer
