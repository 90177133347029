import { replaceNull } from '../../../utils/commonfunction'
import * as types from '../../constants/actionsType'
const initialState = {
    status: " ",
    message: " ",
    kycDetails: [],
    currentAddress: [],
    correspondenceAddress: [],
    documents: [],
    KycDatas: [],
    isKycProfileDetailsLoading: false
}
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.KYC_PROFILE_DETAILS:
            return {
                ...state,
                KycDatas: action.payload != null ? replaceNull(action.payload) : state.KycDatas,
                // isKycProfileDetailsLoading: false
                // status: action.payload != null ? action.payload : state.status,
                // message: action.payload != null ? action.payload : state.message,
                // kycDetails: action.payload != null ? action.payload : state.kycDetails,
                // currentAddress: action.payload != null ? action.payload : state.currentAddress,
                // correspondenceAddress: action.payload != null ? action.payload : state.correspondenceAddress,
                // documents: action.payload != null ? action.payload : state.documents
            }
        case types.EMPTY_KYC_PROFILE_DETAILS:
            return {
                ...state,
                KycDatas: [],
                status: " ",
                message: " ",
                kycDetails: [],
                currentAddress: [],
                correspondenceAddress: [],
                documents: [],
                isKycProfileDetailsLoading: false
            }
        case types.LOADER_KYC_PROFILE_DETAILS:
            return {
                ...state,
                isKycProfileDetailsLoading: action.payload
            }
        default:
            return state
    }
}
export default reducer