import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import '../../utils/stylesheets/common/global/schemes/schemefilter.css'
import '../../utils/stylesheets/common/global/x-ray-header/x-ray-header.css'
import List from './List'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { getTransactionDetailsListing } from '../../redux/actions/transactiondetails/transactiondetailsaction';
import { SchemeFilters, Filterclick, TopTen } from '../../redux/actions/dropdown/dropdownaction'
import { bindActionCreators } from 'redux';
import ResetIcon from '../../utils/assets/images/reset.png';
import { SchemeDetails } from '../../redux/actions/schemes/schemeaction';
import { NetworkErrorSchemeDetails } from '../../redux/actions/schemes/schemeaction';
import SearchImage from '../../utils/assets/images/img30.png';
import NoData from '../../components/nodata/nodata';
import selectedTick from '../../utils/assets/images/img29.png'
//import Autocomplete from '@material-ui/lab/Autocomplete';
const styles = theme => ({
  clear: {
    clear: "both",
    height: "0px",
    display: "block"
  },

  dropBox: {
    // marginBottom: 20,
    // display: "flex",
    // flexDirection: "row",
    // alignItems: "center",
    cursor: 'pointer',
  },
  dropBoxLabel: {
    paddingBottom: 6,
    fontWeight: "normal",
    display: "block",
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: "14px",
    textAlign: "left"
  },
  easySelect: {
    borderRadius: 0,
    height: `calc(${"2.25rem"} + ${"2px"})`,
    padding: "6px",
    fontSize: "0.8rem !important",
    display: "block",
    position: "relative",
    color: window.globalConfig.color.BLACK_PRIMARY,
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER} !important`,
    height: "31px",
    '&:focus': {
      border: `1px solid ${window.globalConfig.color.BROWN} !important`,
    }
  },
  normaleasySelect: {
    borderRadius: 0,
    // height: `calc(${"2.25rem"} + ${"2px"})`,
    padding: "10px",
    fontSize: "0.8rem !important",
    cursor: "pointer",
    display: "block",
    position: "relative",
    color: window.globalConfig.color.BLACK_PRIMARY,
    //  border: "1px solid #cbcbcb !important",
    height: "31px"
  },
  clearSelectfromDiv: {
    background: `url(${ResetIcon})  no-repeat`,
    width: "18px",
    height: "18px",
    zIndex: 0,
    position: "absolute",
    right: 10,
    // display: "none",
    top: "12px"
  },
  downArrow: {
    background: `url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat`,
    width: "18px",
    height: "18px",
    zIndex: 0,
    position: "absolute",
    right: 10,
    // display: "none",
    top: "14px"
  },
  normaldropdownArrow: {
    background: `url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat`,
    width: "18px",
    height: "18px",
    zIndex: 0,
    position: "absolute",
    right: 1,
    // display: "none",
    top: "17px"
  },
  downArrowwithoutsearch: {
    background: `url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat`,
    width: "18px",
    height: "18px",
    zIndex: 0,
    position: "absolute",
    right: 10,
    // display: "none",
    top: "26px",
    [theme.breakpoints.down('600')]: {
      top: 21
    },
  },
  styledSelect: {
    fontSize: "14px",
    width: 282,
    paddingRight: 15, left: 10, top: 6, paddingLeft: 0,
    fontWeight: 500,
    backgroundColor: "transparent", borderRadius: "0.2rem", position: "absolute",
    right: 0, bottom: 0, paddingTop: 5, textOverflow: "ellipsis", whiteSpace: "nowrap",
    textAlign: "left",
    overflow: "hidden",
    '&::after': {
      background: `url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat`,
      width: "18px",
      height: "18px",
      zIndex: 0,
      position: "absolute",
      right: 10,
      // display: "none",
      top: "12px"
    },
    [theme.breakpoints.down('600')]: {
      width: 'calc(100% - 38px)',
    }
  },
  normaldropdownstyledSelect: {
    fontSize: "14px",
    paddingRight: 18, left: 10, top: 6, paddingLeft: 0,
    fontWeight: 500,
    backgroundColor: "transparent", borderRadius: "0.2rem", position: "absolute",
    right: 0, bottom: 0, paddingTop: 5, textOverflow: "ellipsis", whiteSpace: "nowrap", textAlign: "left",
    overflow: "hidden",
    '&::after': {
      background: `url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat`,
      width: "18px",
      height: "18px",
      zIndex: 0,
      position: "absolute",
      right: 10,
      // display: "none",
      top: "12px"
    }
  },
  options: {
    border: 0,
    marginTop: "-37px",
    width: "102%",
    marginLeft: "-1%",
    boxShadow: "7px 7px 20px 0 rgb(0 0 0 / 20%)",
    borderRadius: "5px",
    backgroundColor: window.globalConfig.color.WHITE,
    padding: 0,
    position: "absolute",
    top: "100%",
    right: 0,
    left: 0,
    zIndex: 999,
    margin: "3px 0px"
    , listStyle: "none"
  },
  normaldropdownoptions: {
    border: 0,
    // lineHeight: 3,
    marginTop: "1px",
    width: "102%",
    marginLeft: "-1%",
    boxShadow: "7px 7px 20px 0 rgb(0 0 0 / 20%)",
    borderRadius: "5px",
    backgroundColor: window.globalConfig.color.WHITE,
    padding: 0,
    position: "absolute",
    top: "100%",
    right: 0,
    left: 0,
    zIndex: 1,
    margin: "3px 0px"
    , listStyle: "none"
  },
  divSearcheasySelect: {
    borderBottom: `1px solid ${window.globalConfig.color.PRIMARY_BORDER} !important`,
    padding: 0,
    overflow: "hidden",
    background: `url(${SearchImage}) no-repeat 9px  center !important`,
    height: 50,
    borderRadius: 0,
    paddingLeft: "15px",
    position: "relative"
  },
  listStyle: {
    padding: "5px 0px",
    margin: "auto",
    maxWidth: "100% !important",
    fontSize: "0.6rem !important",
    fontWeight: 500,
    overflow: "hidden",
    // '&:focus': {
    //   backgroundColor: window.globalConfig.color.RosyDarkBrown
    // }
  },
  searchInputeasySelect: {
    width: "87%",
    marginRight: "14px",
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontSize: "12px",
    textIndent: "8px",
    fontWeight: 500,
    background: `url(${SearchImage}) no-repeat -19px  center !important`,
    border: 0,
    height: 50,
    borderRadius: 0,
    paddingLeft: "15px",
    outline: "none",
    position: "relative"
  },
  scrolableDiv: {
    padding: 12,
    overflow: "auto",
    maxHeight: 245
  },
  checkbox: {
    position: "absolute",
    opacity: 0,
    cursor: "pointer",
    height: 0,
    width: 0
  },
  checkmark: {
    position: 'absolute',
    top: "4px",
    left: 0,
    height: "18px",
    width: "18px",
    marginLeft: '3px',
    borderRadius: '3px',
    backgroundColor: window.globalConfig.color.WHITE,
    border: `1px solid ${window.globalConfig.color.PRIMARY_BORDER}`
  },
  containeritem: {
    position: "relative",
    paddingLeft: "30px !important",
    cursor: "pointer",
    userSelect: "none",
    alignItems: "center",
    margin: 0,
    width: "auto !important",
    padding: "0.25rem 0px 0.25rem 33px !important",
    fontSize: "14px",
    display: "block",
    fontWeight: "normal",
    textAlign: "left"
  },
  containeritemwithoutcheckbox: {
    position: "relative",
    // paddingLeft: "30px !important",
    cursor: "pointer",
    userSelect: "none",
    alignItems: "center",
    margin: 0,
    width: "auto !important",
    //padding: "0px 0px 1.25rem 0px !important",
    fontSize: "16px",
    display: "block",
    fontWeight: "normal",
    textAlign: "left"
  },
  selectedcheckmark: {
    position: 'absolute',
    top: "4px",
    left: 0,
    height: "18px",
    width: "18px",
    marginLeft: '3px',
    borderRadius: '3px',
    backgroundColor: window.globalConfig.color.WHITE,
    border: `1px solid ${window.globalConfig.color.BROWN} !important`,

    //  transform:`rotate(${1}deg)`,
    background: `url(${selectedTick}) center center no-repeat`,


  },
  containeritemwithoutsearch: {
    position: "relative",
    paddingLeft: "30px !important",
    cursor: "pointer",
    userSelect: "none",
    alignItems: "center",
    margin: 0,
    width: "auto !important",
    padding: "0.25rem 0px 0.25rem 33px !important",
    fontSize: "14px",
    display: "block",
    fontWeight: "normal",
    textAlign: "left"
  },
  clearSelectfromDivwithoutsearch: {
    background: `url(${ResetIcon})  no-repeat`,
    width: "18px",
    height: "18px",
    zIndex: 0,
    position: "absolute",
    right: 10,
    // display: "none",
    top: "20px"
  },
  dropBoxwithoutsearch: {
    //marginBottom: 20
    marginTop: "14px",
    marginLeft: "5px",
    marginRight: "5px",
    cursor: 'pointer',
  },
  dropBoxLabelwithoutsearch: {
    // paddingBottom: 8,
    fontWeight: "normal",
    display: "block",
    color: window.globalConfig.color.LABLE_GRAY,
    fontSize: "14px",
    textAlign: "left",
    cursor: 'pointer',
  },
  easySelectwithoutsearch: {
    borderRadius: 0,
    height: `calc(${"2.25rem"} + ${"2px"})`,
    padding: "6px",
    fontSize: "0.8rem !important",
    cursor: "default",
    display: "block",
    position: "relative",
    color: window.globalConfig.color.BLACK_PRIMARY,
    //border: "1px solid #cbcbcb !important",
    height: "31px"
  },
  placeholderStyle: {
    // color:window.globalConfig.color.GRAY_LIGHT,
    color: window.globalConfig.color.PRIMARY_BORDER,
    fontSize: "14px", width: 282,
    paddingRight: 15, left: 10, top: 6, paddingLeft: 0,
    fontWeight: 500,
    backgroundColor: "transparent", borderRadius: "0.2rem", position: "absolute",
    right: 0, bottom: 0, paddingTop: 5, textOverflow: "ellipsis", whiteSpace: "nowrap", textAlign: "left",
    overflow: "hidden",
    '&::after': {
      background: `url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat`,
      width: "18px",
      height: "18px",
      zIndex: 0,
      position: "absolute",
      right: 10,
      // display: "none",
      top: "12px"
    }
  },
  sample: {
    '&::focus': {
      color: "red"
    }
  },
  styledSelectwithoutsearch: {
    fontSize: "14px", paddingRight: 15, width: "79%", left: 0, top: 14,
    paddingLeft: 0, fontWeight: 500,
    backgroundColor: "transparent", borderRadius: "0.2rem", position: "absolute",
    right: 0, bottom: 0, paddingTop: 5, textOverflow: "ellipsis",
    whiteSpace: "nowrap", textAlign: "left",
    overflow: "hidden",
    '&::after': {
      background: `url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E") no-repeat`,
      width: "18px",
      height: "18px",
      zIndex: 0,
      position: "absolute",
      right: 10,
      // display: "none",
      top: "12px"
    },
    [theme.breakpoints.down('600')]: {
      top: 8
    },
  },
  optionswithoutsearch: {
    border: 0,
    marginTop: "-37px",
    width: "98%",
    marginLeft: "-1%",
    boxShadow: "7px 7px 20px 0 rgb(0 0 0 / 20%)",
    borderRadius: "5px",
    backgroundColor: window.globalConfig.color.WHITE,
    padding: 0,
    position: "absolute",
    top: "100%",
    right: 0,
    left: 0,
    zIndex: 1,
    margin: "3px 0px"
    , listStyle: "none"
  },

  listStylewithoutsearch: {
    padding: "5px 0px",
    margin: "auto",
    maxWidth: "100% !important",
    fontSize: "0.6rem !important",
    fontWeight: 500,
    overflow: "hidden"
  },
  listStylewithoutcheckbox: {
    padding: "8px 0px 8px 10px",
    margin: "auto",
    maxWidth: "100% !important",
    fontSize: "0.6rem !important",
    fontWeight: 500,
    overflow: "hidden",
    '&:hover': {
      backgroundColor: window.globalConfig.color.RosyBrown
    }
    // padding: "10px 0px 9px 11px"
  },
  cardSpareaterLine: {
    height: 0.5,
    width: "100%",
    backgroundColor: window.globalConfig.color.ZERO_BACKGROUND,
  },
  scrolableDivwithoutsearch: {
    padding: 12,
    overflow: "auto",
    maxHeight: 143
  },
  scrolableDivnormalsearch: {
    //  padding: "0px 5px 14px 4px",
    overflow: "auto",
    maxHeight: 143,
    // paddingTop:10,
    // paddingBottom:10
    // height:143
  },
  closeIconStyle: {
    marginRight: 0,
    top: -21,
    right: 18,
    float: "right",
    fontSize: "20px",
    cursor: "pointer",
    position: "relative",
    zIndex: 4,
    color: window.globalConfig.color.BLACK_PRIMARY
  },
  selectedItem: {
    backgroundColor: window.globalConfig.color.RosyBrown,
    color: window.globalConfig.color.BROWN
  },
  normaldropdownstyle: {
    backgroundColor: window.globalConfig.color.WHITE,
    '&:hover': {
      backgroundColor: window.globalConfig.color.RosyBrown
    }
  },
  errorDesign: {
    color: window.globalConfig.color.red,
    fontSize: 13,
    textAlign: 'left',
    float: 'left'
    // marginTop: '2%'
  }
});
const compositeRef = []
class SchemeDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.modalRef = React.createRef();
    this.myRef = React.createRef('');
    this._componentMount = false
    this.state = {
      List: [],
      backupList: [],
      openDropdown: false,
      selectedListDropdown: [],
      searchvalue: "",
      backup: [], style: '1px solid black'

    };
  }
  onMouseLeaveFunction = () => {
    if (this.props.filter === "close") {
      this.closeDropdown()
    }
  }
  componentWillUnmount() {
    this._componentMount = false
    window.removeEventListener("keydown", (e) => {
      var a = document.activeElement
      // 
      this.keyDownHandler(e, a)
    });

  }

  async componentDidMount() {
    this._componentMount = true
    window.addEventListener("keydown", (e) => {
      var a = document.activeElement
      //  
      this.keyDownHandler(e, a)
    });
    if (this.props.List.length != 0 && this.props.List != "NA") {
      if (typeof this.props.List[0].isChecked == "undefined") {
        let newArrayList = []
        if (this.props.autoselect) {
          this.props.List.filter((item) => item.label != "" && item.value != "").map((response, index) => {
            newArrayList.push({ ...response, "value": response.value, "subLabel": response.subLabel == undefined ? "" : response.subLabel, "label": response.label, "isChecked": index == 0 ? true : false })
          })
        }
        else {

          this.props.List.filter((item) => item.label != "" && item.value != "").map((response, index) => {
            newArrayList.push({ ...response, "value": response.value, "subLabel": response.subLabel == undefined ? "" : response.subLabel, "label": response.label, "isChecked": this.props.value != undefined && this.props.value != '' ? response.value == this.props.value || response.label == this.props.value ? true : response.isChecked : response.isChecked })
          })
          // 
        }
        this._componentMount && this.setState({
          List: [...newArrayList],
          backupList: [...newArrayList],

        }, () => {

        })
        let filteredvalue = newArrayList.filter((item) => item.isChecked === true)
        if (newArrayList.filter((item) => item.isChecked == true).length != 0) {
          this.props.handleOnChange(newArrayList.filter((item) => item.isChecked == true))
        }
        this._componentMount && this.setState({ selectedListDropdown: [...filteredvalue] }, () => {
        })
      } else {
        //  
        this._componentMount && this.setState({
          List: [...this.props.List],
          backupList: [...this.props.List],

        }, () => {

        })
        let filteredvalue = this.props.List.filter((item) => item.isChecked === true)
        this._componentMount && this.setState({ selectedListDropdown: [...filteredvalue] }, () => {

        })
      }
    }
    this._componentMount && this.setState({
      backup: this.state.List.map((item) => {
        item.isChecked = false
        return item
      })
    }, () => {

    })

  }
  componentDidUpdate(prev) {
    const { List, value, loader } = this.props;
    // 

    if (prev.List !== List) {

      if (List.length != 0 && List != "NA") {
        if (typeof (List[0].isChecked) != "undefined") {
          this.setState({
            List: List, backupList: List
          })

          let filteredvalue = List.filter((item) => item.isChecked === true)
          this.setState({ selectedListDropdown: [...filteredvalue] })
        } else {
          if (this.props.autoselect == true) {
            this.setState({
              List: List.map((item, index) => {
                return { ...item, "label": item.label, "subLabel": item.subLabel == undefined ? "" : item.subLabel, "value": item.value, "isChecked": index === 0 ? true : false }
              }), backupList: List.map((item, index) => {
                return { ...item, "label": item.label, "value": item.value, "isChecked": index === 0 ? true : false }
              })
            })
            let filteredvalue = List.map((item, index) => {
              return { ...item, "label": item.label, "subLabel": item.subLabel == undefined ? "" : item.subLabel, "value": item.value, "isChecked": index === 0 ? true : false }
            }).filter((item) => item.isChecked === true)
            this.setState({ selectedListDropdown: [...filteredvalue] }, () => {
            })
            this.props.handleOnChange(filteredvalue.filter((item) => item.isChecked == true))
          } else {
            this.setState({
              List: List.map((item, index) => {
                return { ...item, "label": item.label, "subLabel": item.subLabel == undefined ? "" : item.subLabel, "value": item.value, "isChecked": this.props.value != undefined && this.props.value != "" && item.label === this.props.value ? true : false }
              }), backupList: List.map((item, index) => {
                return { ...item, "label": item.label, "subLabel": item.subLabel == undefined ? "" : item.subLabel, "value": item.value, "isChecked": this.props.value != undefined && this.props.value != "" && item.label === this.props.value ? true : false }
              })
            }, () => {
            })
            let filteredvalue = List.map((item, index) => {
              return { ...item, "label": item.label, "subLabel": item.subLabel == undefined ? "" : item.subLabel, "value": item.value, "isChecked": false }
            }).filter((item) => item.isChecked === true)
            this.setState({ selectedListDropdown: [...filteredvalue] }, () => {
            })
          }
        }
      } else {
        this.setState({ List: [], backup: [], selectedListDropdown: [] }, () => {

        })
      }
    }

    if (value != undefined) {
      if (prev.value != value) {
        this.setState({
          List: List.filter((item) => item.label != "" && item.value != "").map((item) => {
            return { ...item, isChecked: item.value === value || item.label === value ? true : false }

          }), backupList: List
        }, () => {
        })
        let filteredvalue = List.filter((item) => item.label != "" && item.value != "").map((item) => {
          return { ...item, isChecked: item.value === value || item.label === value ? true : false }
        }).filter((val) => val.isChecked === true)
        this.setState({ selectedListDropdown: [...filteredvalue] }, () => {
        })
      }
    }

  }
  closeDropdown = () => {
    this.setState({ openDropdown: false }, () => {
    })
  }
  openListDropdown = () => {
    if (!this.props.disabled) {
      this.setState({ openDropdown: true }, () => {
        if (this.state.List.length != 0) {
          const scrollToElement = document.getElementById(`${this.props.id}_${this.state.List.filter((item) => item.isChecked == true).length != 0 ? this.state.List.filter((item) => item.isChecked == true)[0].value : this.state.List[0].value}`)
          if (scrollToElement != null || scrollToElement != undefined) {
            scrollToElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
          }

        }
      })
    }

  }
  setSelectedList = async (item) => {
    const elementsIndex = this.state.List.findIndex(element => element.value == item.value)
    let newArray = [...this.state.List]

    newArray[elementsIndex] = { ...newArray[elementsIndex], isChecked: !newArray[elementsIndex].isChecked }
    const elementsIndex2 = this.state.backupList.findIndex(element => element.value == item.value)
    let backuparray = [...this.state.backupList]
    backuparray[elementsIndex2] = { ...backuparray[elementsIndex2], isChecked: !backuparray[elementsIndex2].isChecked }
    try {
      this.props.handleOnChange(newArray.filter((item) => item.isChecked == true))
      this.setState({ List: newArray, backupList: backuparray, selectedListDropdown: newArray.filter((item) => item.isChecked == true) }, () => {

      })
    } catch (err) {
    }

  }
  onKeyPress = async (index) => {
    const elementsIndex = index
    let newArray = [...this.state.List]
    newArray[elementsIndex] = { ...newArray[elementsIndex], isChecked: !newArray[elementsIndex].isChecked }
    this.props.handleOnChange(newArray.filter((item) => item.isChecked === true))
    const elementsIndex2 = index
    let backuparray = [...this.state.backupList]
    backuparray[elementsIndex2] = { ...backuparray[elementsIndex2], isChecked: !backuparray[elementsIndex2].isChecked }
    this.setState({ List: newArray, backupList: backuparray, selectedListDropdown: newArray.filter((item) => item.isChecked == true) }, () => {

    })
  }
  onKeyPressList = async (index) => {
    if (this.state.List.length != 0) {
      let list = this.state.List.map(el => {
        return Object.assign({}, el, { isChecked: false })
      });
      let backuplist = this.state.backupList.map(el => {
        return Object.assign({}, el, { isChecked: false })
      });

      const elementsIndex = index != undefined && index != '' ? index : 0
      let newArray = [...list]
      newArray[elementsIndex] = { ...newArray[elementsIndex], isChecked: !newArray[elementsIndex].isChecked }
      const elementsIndex2 = index != undefined && index != '' ? index : 0
      let backuparray = [...this.state.backupList]
      backuparray[elementsIndex2] = { ...backuparray[elementsIndex2], isChecked: !backuparray[elementsIndex2].isChecked }
      this.setState({ List: newArray, backupList: backuparray, selectedListDropdown: newArray.filter((item) => item.isChecked == true) }, () => {

      })
      this.props.handleOnChange(newArray.filter((item) => item.isChecked == true))

    }
    this.closeDropdown()
  }
  SelectedList = async (item) => {
    let list = this.state.List.map(el => {
      return Object.assign({}, el, { isChecked: false })
    });
    const elementsIndex = list.findIndex(element => element.value == item.value)
    let newArray = [...list]
    newArray[elementsIndex] = { ...newArray[elementsIndex], isChecked: !newArray[elementsIndex].isChecked }
    const elementsIndex2 = this.state.backupList.findIndex(element => element.value == item.value)
    let backuparray = [...this.state.backupList]
    backuparray[elementsIndex2] = { ...backuparray[elementsIndex2], isChecked: !backuparray[elementsIndex2].isChecked }

    this.setState({ List: newArray, backupList: backuparray, selectedListDropdown: newArray.filter((item) => item.isChecked == true) }, () => {

    })

    await this.props?.handleOnChange(newArray.filter((item) => item.isChecked == true))
    this.closeDropdown()
  }
  NormalSelectedList = async (item) => {
    let list = this.state.List.map(el => {
      return Object.assign({}, el, { isChecked: false })
    });
    const elementsIndex = list.findIndex(element => element.value == item.value)
    let newArray = [...list]
    newArray[elementsIndex] = { ...newArray[elementsIndex], isChecked: !newArray[elementsIndex].isChecked }
    const elementsIndex2 = this.state.backupList.findIndex(element => element.value == item.value)
    let backuparray = [...this.state.backupList]
    backuparray[elementsIndex2] = { ...backuparray[elementsIndex2], isChecked: !backuparray[elementsIndex2].isChecked }
    this.setState({ List: newArray, backupList: backuparray, selectedListDropdown: newArray.filter((item) => item.isChecked == true) }, () => {
    })
    await this.props?.handleOnChange(newArray.filter((item) => item.isChecked == true))
    await this.closeDropdown()
  }
  clearSearch = () => {

    this.setState({ List: this.state.backupList, searchvalue: "" }, () => { })

  }
  searchOnchange = (event) => {
    if (this.props.onKeyDown != undefined) {
      this.props.onKeyDown(event)
    }
    this.setState({ searchvalue: event.target.value }, () => { })

    this.setState({
      List: this.state.backupList.filter(el =>
        el.label.toUpperCase().includes(event.target.value.toUpperCase())
      )
    }, () => { })

  }
  outsideClear = () => {
    if (this.props.callBack != undefined) {
      this.props.callBack()
      this.setState({ List: this.props.List, selectedListDropdown: this.props.List.filter((item) => item.isChecked == true) })
    } else if (this.props.handleOutsideClick != undefined) {
      this.props.handleOutsideClick()
      this.setState({
        selectedListDropdown: [], List: this.state.backupList.map(function (x) {
          x.isChecked = false;
          return x
        })
      }, () => { })
      // this.props.handleOnChange([])
    }
    else {
      this.setState({
        selectedListDropdown: [], List: this.state.backupList.map(function (x) {
          x.isChecked = false;
          return x
        })
      }, () => { })
      this.props.handleOnChange([])
    }



  }

  keyDownHandler = (e, activeTextarea) => {

    if (e.key === 'Tab') {
      if (activeTextarea.id == `${"normalwithsearch"}${this.props.id}`) {

      } else {
        this.closeDropdown()
      }
    }
    if (e.key === "Enter") {
      if (activeTextarea.id == `${"normalwithsearch"}${this.props.id}`) {
        this.openListDropdown()
      } else {
      }
    }
  }
  render() {
    const {
      color
    } = this.props;
    const { classes } = this.props;
    if (this.props.type == "Searchable") {
      let FullList = this.state.selectedListDropdown.length == 0 ? this.state.List : this.state.List.map(x => this.state.selectedListDropdown.map((item) => {
        item.isChecked = true
        return item
      }).find(({ value }) => value === x.value) || x)
      return (<>

        <div className={classes.dropBox}>
          <label className={classes.dropBoxLabel}>{this.props.label}</label>
          <div className={classes.easySelect} id={`normalwithsearch${this.props.id}`} style={{ width: this.props.width }} tabIndex="0" onClick={this.openListDropdown}>
            {this.state.selectedListDropdown.length == 0 ?
              <div className={classes.downArrow} onClick={this.openListDropdown}></div>
              : <div className={classes.clearSelectfromDiv} onClick={this.outsideClear}></div>
            }
            <div className={[this.state.selectedListDropdown.length == 0 ? classes.placeholderStyle : classes.styledSelect].join('')} onClick={this.openListDropdown}>
              {this.state.selectedListDropdown.length == 0 ?
                <span style={{ color: window.globalConfig.color.BORDER, fontWeight: "400" }}>{this.props.placeholder}</span> : this.state.selectedListDropdown.length > 1 ? `${this.state.selectedListDropdown.length} Selected` : this.state.selectedListDropdown[0].label}
            </div>
            {this.state.openDropdown &&

              <List type={"withcheckbox"} onClickAway={this.closeDropdown}
                onClick={this.setSelectedList} onKeyPress={this.onKeyPress}
                List={this.state.List} loader={this.props.loader}
                FullList={FullList}>
                {this.props.isSearchable && <div className={classes.divSearcheasySelect}>
                  <div></div>
                  <input type="text" onChange={this.searchOnchange} value={this.state.searchvalue} className={[classes.searchInputeasySelect]} placeholder={this.props.searchPlaceholder}>
                  </input>
                  {this.state.searchvalue.length != 0 ? <div className={classes.clearSelectfromDiv} style={{ marginTop: 6 }} onClick={this.clearSearch}></div> : <div></div>}

                </div>}
              </List>

            }
          </div>
        </div>

      </>
      )
    }
    else if (this.props.type === "normalList") {
      return (<>
        <ClickAwayListener onClickAway={this.closeDropdown} >
          <div className={classes.dropBox} onClick={this.openListDropdown}>
            {this.props.children}
            <div className={classes.normaleasySelect} style={this.props.style == undefined ? { width: this.props.width } : this.props.style}>
              {this.state.openDropdown &&
                <ul className={classes.normaldropdownoptions} >
                  <div className={classes.scrolableDivnormalsearch}>
                    {this.state.List.map((item) => {
                      return (
                        <>
                          <li className={[classes.listStylewithoutcheckbox, item.isChecked ? classes.selectedItem : ''].join(' ')} onClick={() => this.NormalSelectedList(item)}>
                            <label className={classes.containeritemwithoutcheckbox}>
                              {item.label}
                            </label>
                          </li>
                          <div className={classes.cardSpareaterLine}>
                          </div>
                        </>
                      )
                    })}

                  </div>
                </ul>}
            </div>
          </div>
        </ClickAwayListener >
      </>
      )
    }
    else if (this.props.type === "normalwithsearch") {

      return (<>
        <ClickAwayListener onClickAway={this.closeDropdown}>
          <div style={{ width: "-webkit-fill-available" }}>
            {this.props.label != "" && this.props.label != undefined &&

              <label className={classes.dropBoxLabel}>{this.props.label}</label>}
            <div id={`${"normalwithsearch"}${this.props.id}`}
              className={classes.easySelect}
              tabIndex={0}
              style={{ width: this.props.width, border: this.state.style, cursor: this.props.disabled ? "not-allowed" : "pointer", opacity: this.props.disabled ? 0.5 : 1 }}
              onClick={this.openListDropdown}>
              <div className={classes.normaldropdownArrow} onClick={this.openListDropdown}></div>
              <div className={classes.normaldropdownstyledSelect} onClick={this.openListDropdown}>
                {this.props.value != undefined && this.props.value != "" ? this.props.value : this.state.selectedListDropdown.length == 0 ?
                  <span style={{ color: window.globalConfig.color.BORDER, fontWeight: "400" }}>{this.props.placeholder}</span> : <span style={{ fontWeight: "400" }}> {this.state.selectedListDropdown.length > 1 ? `${this.state.selectedListDropdown.length} Selected` : this.state.selectedListDropdown[0].label}</span>}
              </div>
              {this.state.openDropdown &&
                <List id={this.props.id} type={"withoutcheckbox"} onClick={this.SelectedList}
                  height={this.props.height} loader={this.props.loader}
                  List={this.state.List}
                  onKeyPress={this.onKeyPressList}
                  onClickAway={this.closeDropdown}

                >
                  {this.props.isSearchable && <div className={classes.divSearcheasySelect}>
                    <input type="text" onChange={this.searchOnchange} value={this.state.searchvalue} className={[classes.searchInputeasySelect]} placeholder={this.props.searchPlaceholder}>
                    </input>
                    {this.state.searchvalue.length != 0 ? <div className={classes.clearSelectfromDiv} style={{ marginTop: 6 }} onClick={this.clearSearch}></div> : <div></div>}
                  </div>}

                </List>

              }

            </div>
            <span className={classes.errorDesign}>{this.props.error}</span>

          </div>
        </ClickAwayListener>
      </>
      )
    }
    else {
      return (
        <div className={classes.dropBoxwithoutsearch}
        >
          {this.props.label != "" && this.props.label != undefined &&
            <label className={classes.dropBoxLabelwithoutsearch}>{this.props.label}</label>}
          {this.state.selectedListDropdown.length != 0 &&
            <div className={classes.closeIconStyle} onClick={this.outsideClear}>×</div>}
          <div className={classes.easySelectwithoutsearch} style={{ border: this.props.showborder ? `1ps solid ${window.globalConfig.color.PRIMARY_BORDER}` : 0 }} >
            <div className={classes.downArrowwithoutsearch} style={{ top: this.props.arrowposition == undefined ? 26 : 16 }} onClick={this.openListDropdown}></div>

            <div className={classes.styledSelectwithoutsearch} style={{ top: this.props.placeholderposition == undefined ? 14 : 8 }} onClick={this.openListDropdown}>
              {this.state.selectedListDropdown.length == 0 ?
                this.props.placeholder : this.state.selectedListDropdown.length > 1 ? `${this.state.selectedListDropdown.length} Selected` : this.state.selectedListDropdown[0].label}

            </div>
            {this.state.openDropdown &&
              <ClickAwayListener onClickAway={this.closeDropdown}>
                <ul className={classes.optionswithoutsearch}
                >
                  {/* <div className={classes.scrolableDivwithoutsearch}> */}
                  {<List top={0} id={this.props.id} type={"withcheckbox"} onClick={this.setSelectedList}
                    height={this.props.height} loader={this.props.loader}
                    List={this.state.List}
                  // onKeyPress={this.onKeyPressList}
                  // onClickAway={this.closeDropdown}

                  >
                  </List>}
                  {/* {this.state.List.length == 0 ? <div>{labels.messages.Dataempty}</div> : this.state.List.map((item, index) => {
                      return (
                        <li className={classes.listStylewithoutsearch} style={{ background: index ? colors.RosyBrown : "white" }} onClick={() => this.setSelectedList(item)}>
                          <label className={classes.containeritemwithoutsearch}>
                            {item.label}
                            {item.isChecked ?
                              <span className={classes.selectedcheckmark}>

                              </span> : <span className={classes.checkmark}></span>}
                          </label>
                        </li>

                      )
                    })} */}

                  {/* </div> */}
                </ul>
              </ClickAwayListener>}
          </div>
        </div>

      )
    }
  }
}
const mapStateToProps = state => {
  return {

  }
}
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getTransactionDetailsListing, TopTen, Filterclick, SchemeFilters, SchemeDetails, NetworkErrorSchemeDetails }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(SchemeDropdown));
