import React, { Component } from 'react'
// import Tabs from '../tabs/tabs';
import '../../utils/stylesheets/layout/layout.css'
import Toolbar from '../../components/toolbar/toolbar';
import Label from '../../utils/constants/labels'
import { connect } from 'react-redux';
import { loginsession } from '../../redux/actions/login/loginaction'
import { bindActionCreators } from 'redux';
import { withStyles } from '@material-ui/core/styles';
import { scrollToTopPage } from '../../utils/commonfunction';
import secureStorage from '../../utils/securestorage';
import Tabs from '../tabs/epf_tabs'
const styles = (theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "10% 80% 10%",
    marginTop: "5%",
    // [theme.breakpoints.down("920")]: {
    //   marginTop: "30%",
    // },
    // [theme.breakpoints.down("600")]: {
    //   marginTop: "55%",
    // }
  },
  cardMenuContainer: {
    [theme.breakpoints.up("920")]: {
      gridTemplateColumns: "17% 17% 19% 18% 17% 12% !important",
      "& .data1": {
        borderRight: "none",
      }
    }
  },
  secondDivGrid: {
    width: "80%",
    [theme.breakpoints.down("920")]: {
      marginTop: 68
    }
  }

})
class container extends Component {

  componentDidUpdate(prevProps) {
    const { clientID, date, location } = this.props;
    if (prevProps.location != location) {
      // 
    }
  }

  componentDidMount() {
    scrollToTopPage()
  }

  render() {
    const { classes } = this.props;
    // console.log(this.props.epfList)
    return (
      <div >


        <Toolbar className={classes.cardMenuContainer} title={Label.menu.epf} childFunc={this.props.childFunc} loader={this.props.epfList?.loader} data={this.props.epfList?.response == "NA" ? [] : this.props.epfList?.response}></Toolbar>        {/* <Tabs page={this.props.location} /> */}
        <div >
          <div style={{ display: 'flex', width: "100%", marginTop: 80 }}>
            <div style={{ width: "10%" }}></div>
            <div className={classes.secondDivGrid}>
              <Tabs page={this.props.location} />
            </div>
            <div style={{ width: "10%" }}></div>
          </div>
        </div>

        {/* <Footer></Footer> */}
      </div>
    )
  }
}
const mapStateToProps = state => ({
  date: state.Login.date,
  mfHeader2: [],
  sessionID: state.Login.sessionID,
  clientID: state.Login.logindetails.ClientID,
  userID: state.Login.userID,
  date: state.Login.date,
  logindetails: state.Login.logindetails,
  epfList: state.Epf.epf_List
})
export default withStyles(styles)(connect(mapStateToProps, null)(container))
