import labels from '../../utils/constants/labels';
import ApiValues from '../url/apiurl'
//action created by dulcy
var axios = require('axios');
import Networking from '../../utils/api/apiaccess';
function scheduleIPV(FormNo) {
 
    var raw = JSON.stringify({
        "FormNo": FormNo, 
       
    });
    return Networking.PostApi(ApiValues.ScheduleIPV, raw).then(result => { 
      return result })
    .catch(function (error) {
      return { "status": "F", "message": labels.messages.apierror }
    });
  
      
}
export default scheduleIPV
