import * as types from '../../constants/actionsType';
import ResetPassword from '../../../api/login/resetpassword';
export function PasswordChange(email, Password, ClientID) {
    return (dispatch) => {
        PasswordChanges(dispatch, email, Password, ClientID);
        // dispatch({
        //   type: types.GET_PROFITLOSS_DATA,
        //   payload: Summary,
        // })
    }
}
function PasswordChanges(dispatch, email, Password, ClientID) {
    ResetPassword(email, Password, ClientID).then((data) => {
        if (data != undefined) {
            if (data.status == "S") {
                dispatch({
                    type: types.LOGIN_RESET,
                    payload: data.status === "S" ? false : true,
                });
            }
        }
    })
}
