import React, { useState } from 'react';
import { Card, CardContent, CardHeader, Collapse, Paper, Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Label from "../../utils/constants/labels";
import icon from "../../utils/assets/images/img10.jpg";
import colors from '../../utils/colors/colors';
const useStyles = makeStyles((theme) => {
    return {
     
        root: {
            display: 'flex',
            backgroundColor:window.globalConfig.color.ZERO_BACKGROUND,
            marginTop: 20,
            
        },
        card_root: {
            display: "table-caption",
            flexFlow: "wrap",
            textAlign: "center",
            marginRight: "50px",
            [theme.breakpoints.up(1024)]: {
                maxWidth: "250px",
            },
            width: "350px",
            paddingTop: 50,
            paddingRight: 5,
            [theme.breakpoints.down('xs')]: {
                width: '350px',
                display: 'table-caption',
                flexFow: 'wrap',
                textAlign: 'left',
                paddingLeft: '8px',
                 paddingTop: '0px',
                 marginRight: '0px', 
                 paddingRight: '0px', 
            },
        },
        card: {
            minWidth: "20%"
        },
        grid: {
            display: "flex"
        },
        cardCarosel: {
            paddingRight: 100
        },
        head: {
            fontSize: "16px",
            color: window.globalConfig.color.LABLE_GRAY,
            fontWeight: "bold",
            margin: "10px 0 10px",
            [theme.breakpoints.up(1024)]: {
                fontSize: "12px",
            }
        },
        paragraph: {
            lineHeight: "150%",
            color:window.globalConfig.color.LABLE_GRAY,
            fontSize: "14px",
            margin: "10px 0 10px",
            [theme.breakpoints.down(1024)]: {
                fontSize: "11px",
            },
        },
        exBox: {
            margin:'0 0% 0 2%',
            // marginRight: 10,
            boxShadow: "0 0 10px 0 rgba(19, 89, 161, 0.06)",
            backgroundColor: window.globalConfig.color.WHITE,
            borderRadius: "5px",
            textAlign: "center",
            padding: "36px 16px",
           // height: "166px",
            width: "150px",
            display: "inline-block",
            // [theme.breakpoints.down('lg')]: {
            // },
            [theme.breakpoints.down('md')]: {
                // margin:'3%',
                height: '100px',
                width: '100px',
            },
            [theme.breakpoints.down('sm')]: {
                // margin:'3%',
                height: '100px',
                width: '100px',
            },
            [theme.breakpoints.down('xs')]: {
                margin:'3%',
                height: '100px',
                width: '147px',
            },
            // [theme.breakpoints.up(768)]: {
            //     //height: "126px",
            //     width: "106px",
            //     float: "right"
            // }, [theme.breakpoints.(1024)]: {
            //    // height: "126px",
            //     width: "146px",
            //     float: "left"
            // }, [theme.breakpoints.up(1440)]: {
            //    // height: "166px",
            //     width: "150px",
            //     float: "left"
            // }, [theme.breakpoints.up(2560)]: {
            //   //  height: "186px",
            //     width: "196px",
            //     float: "left"
            // },
            // [theme.breakpoints.down('sm')]: {
            //     margin:'3%',
            //     height: '100px',
            //     width: '147px',
            // },
            // [theme.breakpoints.down('xs')]: {
            //     margin:'3%',
            //     height: '100px',
            //     width: '147px',
            // },
        },
        exploreRight: {
            flex: "auto",
            float: "left",
            width: "calc(100% - 350px)",
            [theme.breakpoints.between("600","920")]:{
                display:"grid",
                gridTemplateColumns:"repeat(2,50%)",
                gap:40
            }
        },
        exploreleft: {
            flex: "auto",
            paddingRight: "50px",
            float: "left",
            width: "350px",
        },
        cardContainer: {
            [theme.breakpoints.down('xl')]: {
                display: 'flex',
            flexDirection: "row",
            padding: "40px 0 60px 0",
            // margin: "auto",
            margin:"0% 4.5% 0% 4.5%",
            width: "100%", 
            },
            [theme.breakpoints.down('lg')]: {
                display: 'flex',
            flexDirection: "row",
            padding: "36px 69px 47px 54px",
            // margin: "auto",
            margin:"0% 3.5% 0% 0.5%",
            width: "100%", 
            },
            // [theme.breakpoints.down('md')]: {          
            // display: 'flex',
            // flexDirection: "row",
            // padding: "40px 0 50px 0",
            // // margin: "auto",
            // margin:"0% 9.5% 0% 10%",
            // width: "100%",
            // },
            [theme.breakpoints.down('xs')]: {
                display: 'flex',
                flexDirection: "row",
                padding: "8px 0 40px 0",
                margin: "auto",
                width: "100%",
            },
            // [theme.breakpoints.up(768)]: {
            //     width: "70%",
            // }, [theme.breakpoints.up(992)]: {
            //     width: "80%",
            // }, [theme.breakpoints.up(1024)]: {
            //     width: "79.5%",
            // }, [theme.breakpoints.up(1440)]: {
            //     width: "90%",
            // }, [theme.breakpoints.up(2560)]: {
            //     width: "90%",
            // },
          
           
        },
        flex1: { flex: 1 },
        flex2: { flex: 2 },
        flex6: { flex: 6 },

        desktopResponsive: {
            display:'block !important',
            marginTop:'65px',
           
            [theme.breakpoints.down("600")]:{
              display:'none !important',
            },
          },
          mobileResponsive: {
            display:'none !important', 
            [theme.breakpoints.down("600")]:{
              display:'block !important',
            },
          },
    }
});
export default function Portfolioblock() {
    const classes = useStyles();
    const [settings, setSettings] = useState({ dots: true, autoplay: true })
    return <div>
        <div className={classes.desktopResponsive}>
            <div container='true' className={classes.root}>
                <div className={classes.cardContainer} >
                    <div className={classes.exploreLeft}>
                        <div className={classes.card_root}>
                            <Typography style={{fontWeight:'bold',marginLeft: '7%',}} >{Label.block.head}</Typography>
                            {/* <Typography className={classes.paragraph}>{Label.block.paragraph}</Typography> */}
                        </div>
                    </div>
                    <div className={classes.exploreRight}>
                        <div className={classes.exBox}>
                            <div className="exImg">
                                <img src={icon} />
                            </div>
                            <h3 className={classes.head} >{Label.portfolio.Equityfunds}</h3>
                            {/* <p className={classes.paragraph}>{Label.portfolio.description}</p> */}
                        </div>
                        <div className={classes.exBox}>
                            <div className="exImg">
                                <img src={icon} />
                            </div>
                            <h3 className={classes.head}>{Label.portfolio.Deptfunds}</h3>
                            {/* <p className={classes.paragraph}>{Label.portfolio.description}</p> */}
                        </div>
                        <div className={classes.exBox}>
                            <div className="exImg">
                                <img src={icon} />
                            </div >
                            <h3 className={classes.head}>{Label.portfolio.Balancedfunds}</h3>
                            {/* <p className={classes.paragraph}>{Label.portfolio.description}</p> */}
                        </div>
                        <div className={classes.exBox}>
                            <div className="exImg">
                                <img src={icon} />
                            </div>
                            <h3 className={classes.head}>{Label.portfolio.Taxfunds}</h3>
                            {/* <p className={classes.paragraph}>{Label.portfolio.description}</p> */}
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className={classes.mobileResponsive}>
            <div className={[classes.cardContainer,classes.root].join(' ')} >
                <div sm={12} xs={12} style={{width:'100%'}}>
                    <div sm={12} xs={12}>
                    <div className={classes.card_root}>
                        <Typography style={{fontWeight:'bold'}} >{Label.block.head}</Typography>
                       
                    </div>
                       
                    </div>
                    <div sm={12} xs={12}>
                        <div sm={6} xs={6} className={classes.exBox}>
                            <div className="exImg">
                                <img src={icon} />
                            </div>
                            <h3 className={classes.head} >{Label.portfolio.Equityfunds}</h3>
                        
                        </div>
                        <div className={classes.exBox} xs={6} sm={6}>
                            <div className="exImg">
                                <img src={icon} />
                            </div>
                            <h3 className={classes.head}>{Label.portfolio.Deptfunds}</h3>
                         
                        </div>
                        <div className={classes.exBox} xs={6} sm={6}>
                            <div className="exImg">
                                <img src={icon} />
                            </div >
                            <h3 className={classes.head}>{Label.portfolio.Balancedfunds}</h3>
                           
                        </div>
                        <div className={classes.exBox} xs={6} sm={6}>
                            <div className="exImg">
                                <img src={icon} />
                            </div>
                            <h3 className={classes.head}>{Label.portfolio.Taxfunds}</h3>
                            
                        </div>
                    </div>
            </div>
        </div>
    </div>
</div>
  
}
