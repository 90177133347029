import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import '../../components/profile/popup.css';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import colors from '../../utils/colors/colors';
const styles = theme => ({
    balanceText: {
        display: "flex",
        alignContent: "center",
        justifyContent: "center",
        // flex: "60%",
        // marginLeft:20,
        // [theme.breakpoints.between('300', '350')]: {
        //     marginLeft: "120%"
        // },
        // [theme.breakpoints.between('350', '400')]: {
        //     marginLeft: "160%",
        // },
        // [theme.breakpoints.between('400', '600')]: {
        //     marginLeft: "180%"
        // },
        // fontWeight:'bold'
    },
    chart: {
        flex: "60%",
        width: "250px",
        height: "150px",
        marginLeft: -50
    },
    textLeft: {
        flex: "20%",
        marginTop: -20,
        marginLeft: -30,
        // fontWeight:'bold',
        width: 150,
        // textAlign:"left",
        [theme.breakpoints.between('1020', '1030')]: {
            // marginLeft:-55,
            marginTop: 10
        },
        [theme.breakpoints.between('1250', '1300')]: {
            marginLeft: -38,

        },
        [theme.breakpoints.between('300', '350')]: {
            marginTop: 20
        },
        [theme.breakpoints.between('350', '400')]: {
            marginTop: 20,
        },
        [theme.breakpoints.between('400', '600')]: {
            marginTop: 20
        },
    },
    textRight: {
        flex: "20%",
        marginTop: -20,
        marginRight: -25,
        // fontWeight:'bold',
        width: 150,
        // textAlign:"right",
        [theme.breakpoints.between('1020', '1030')]: {
            // marginRight:-50,
            marginTop: 10
        },
        [theme.breakpoints.between('1250', '1300')]: {
            marginRight: -40,

        },
        [theme.breakpoints.between('300', '350')]: {
            marginTop: 20, marginLeft: '240%'
        },
        [theme.breakpoints.between('350', '400')]: {
            marginTop: 20, marginLeft: '320%'
        },
        [theme.breakpoints.between('400', '600')]: {
            marginTop: 20, marginLeft: '340%'
        },
    },
    chartAlign: {
        [theme.breakpoints.between('1250', '1300')]: {
            marginLeft: 20

        }
    },
    chartTitle: {
        width:'100%',
        display: 'flex',
        gridTemplateColumns:'revert',
        gap:'revert',
        textIndent:'revert',
        [theme.breakpoints.down('xs')]: {
            display: 'grid',
            gridTemplateColumns: '10px 203px 10px',
            gap: '10px',
            textIndent:'-3em',
        },
    },
    chartEmptyCard: {
        flex: "60%",
        [theme.breakpoints.down('xs')]: {
            flex:'revert'
        },
    },

})
class RiskChart extends React.Component {
    render() {
        //
        var RiskValue = '';
        if (this.props.logindetails != undefined && this.props.logindetails != null && this.props.logindetails != "") {
            RiskValue = this.props.logindetails.CalcValue
        }
        if (this.props.riskAnswers.response_data != undefined && this.props.riskAnswers.response_data != null && this.props.riskAnswers.response_data != "") {
            RiskValue = this.props.riskAnswers.response_data.calc_value
        }

        const { classes } = this.props
        return (
            <div>
                <div className={classes.chartTitle} >
                    <div style={{ flex: "20%" }}></div>
                    <div className={classes.balanceText}>
                        Balanced
                    </div>
                    <div style={{ flex: "20%" }}></div>

                </div>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: "20%" }}></div>
                    <div className={classes.chart}>
                        <ReactSpeedometer
                            minValue={0}
                            maxValue={100}
                            startColor={window.globalConfig.color.GREEN}
                            endColor={window.globalConfig.color.red}
                            value={RiskValue != '' ? Number(RiskValue) : 0}
                            ringWidth={20}
                            maxSegmentLabels={0}
                            needleHeightRatio={0.5}
                            segments={1000}

                        />
                    </div>
                    <div style={{ flex: "20%" }}></div>
                </div>
                <div className={classes.chartTitle} >
                    <div  >Cautious</div>
                    <div className={classes.chartEmptyCard}>
                    </div>
                    <div >Agressive</div>

                </div>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    // mainDetails: state.ProfileDetails.AllProfileDetails.mainDetails != null ? state.ProfileDetails.AllProfileDetails.mainDetails.length != 0 ? state.ProfileDetails.AllProfileDetails.mainDetails : [] : [],
    riskAnswers: state.RiskScore.answerOrder != null ? state.RiskScore.answerOrder != undefined ? state.RiskScore.answerOrder : [] : [],
    logindetails: state.Login.logindetails,
})
// const mapDispatchToProps = dispatch => {

// }
export default withStyles(styles)(connect(mapStateToProps)(RiskChart))