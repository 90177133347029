import * as types from '../../constants/actionsType'
import { bindActionCreators } from 'redux';
import { replaceNull } from '../../../utils/commonfunction';
const initialState = {
 L5report:[],
 isL5reportLoading:false
}
const reducer = (state = initialState, action) => {
    switch (action.type) {        
        case types.L5REPORT:
            let arrangeArray=action.payload!=null?action.payload.length!=0?[...action.payload.filter((item)=>item.L4_AccountTypeID!=3),...action.payload.filter((item)=>item.L4_AccountTypeID==3)]:[]:[]
           
            return {
                ...state,
                L5report:action.payload!=null&&action.payload!=undefined?replaceNull(arrangeArray,"NA"):[],
                isL5reportLoading:false
            }
        case types.LOADER_L5REPORT:
            return {
                ...state,
                isL5reportLoading:true
            }
        case types.EMPTY_L5REPORT:
                return {
                    ...state,
                    L5report: [],
                    isL5reportLoading:false
                }
            default:
                return state
    }
}
export default reducer