import React, { PureComponent } from 'react';
import {
  PieChart, Pie, Cell, Tooltip, ResponsiveContainer
} from 'recharts';
import '../../utils/stylesheets/layout/layout.css'
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
//import { getSummaryDetails,  } from '../../redux/actions/summary/summaryAction'
import { getAllInstrumentDetails } from '../../redux/actions/summary/switchdonutaction'
import '../../utils/stylesheets/expandable-table/expandabletable.css'
import { bindActionCreators } from 'redux';
import NoData from '../nodata/nodata';
import Barchart from './barchart';
import lable from '../../utils/constants/labels';
import Paper from '@material-ui/core/Paper';
import CustomTooltip from '../tooltip/customtooltip'
import ErrorBoundary from '../Errorhandling/Errorhandle';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import CommonDescription from '../../components/chartdescription/chart_description'
import colors from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
class Switch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEquity: true,
      selectedDebt: false
    }
  }


  _handleChange() {
    this.setState({ isChecked: !this.state.isChecked });
  }
  onClick(e) {
    if (e.valueOf().currentTarget.innerText === "Asset") {
      this.setState({
        selectedEquity: true,
        selectedDebt: false
      })
      this.props.datas(0)
    }
    else {
      this.setState({
        selectedEquity: false,
        selectedDebt: true
      })
      this.props.datas(1)
    }
  }
  componentWillMount() {
    this.setState({ isChecked: this.props.isChecked });
  }


  render() {
    const { classes } = this.props;
    return (
      <div className="assetswitch switchContainers">
        {this.state.selectedEquity ? <label className="active" onClick={this.onClick.bind(this)}>{lable.tranactionSchemesDetails.switchAsset}</label> : <label className="switcherLabel" onClick={this.onClick.bind(this)}>{lable.tranactionSchemesDetails.switchAsset}</label>}
        {this.state.selectedDebt ? <label className="active" onClick={this.onClick.bind(this)}>{lable.tranactionSchemesDetails.switchSector}</label> : <label className="switcherLabel" onClick={this.onClick.bind(this)}>{lable.tranactionSchemesDetails.switchSector}</label>}
      </div>
    );
  }
}

// const labels.chartColor = ['#f66d44', '#f78a69', '#f9ad95','#FABDAA'];
// const labels.chartColor =labels.chartColor;
const styles = theme => ({
  chartWrap: {
    // overflow: 'hidden',
  },
  chartFluid: {
    width: '100%',
    minWidth: 300,
    height: 300,
    [theme.breakpoints.down('400')]: {
      minWidth: 200,
    },

  },
  cardHeading: {
    float: "left",
    margin: 0,
    // marginLeft: 30,
    // marginTop: 30,
    fontWeight: 'bold',
    color: window.globalConfig.color.BLACK_PRIMARY,
    fontSize: 18,
    [theme.breakpoints.down("600")]: {
      float: "unset",
      marginLeft: 10,
      marginTop: 0,
      paddingTop: '4%',
    },
  },
  switchAllocationButton: {
    marginRight: 10,
    [theme.breakpoints.down("600")]: {
      paddingTop: 25,
      marginRight: 8,
      display: 'flex',
      justifyContent: 'center',
    },
  },
  barChartCard: {
    paddingTop: 16, paddingLeft: 20,
    [theme.breakpoints.down("600")]: {
      paddingTop: 40,
    },
  },
  SwitchButtonDesign: {
    display: "flex",
    justifyContent: 'flex-end',
    marginTop: '-50px',
    [theme.breakpoints.down('350')]: {
      marginRight: '80px'
    },
    [theme.breakpoints.between('350', '400')]: {
      marginRight: '100px'
    },
    [theme.breakpoints.between('400', '600')]: {
      marginRight: '130px'
    },
  },
  root: {
    flexGrow: 1,

  },
  formRow: {
    paddingLeft: '8%',
    [theme.breakpoints.down("600")]: {
      paddingTop: "3em",
    },
  },
  button: {
    paddingTop: "30px",
    marginRight: "30px",
    height: "5vh",
    width: "30vw",
    marginLeft: "30px",
    borderRadius: "15px"
  },
  chartskeleton: {
    height: "55vh",
    // width:"37vw",
    marginLeft: "2vw",
    marginTop: "-2vw",
    marginRight: "2vw"
  },
  switchContentBlock: {
    width: '100%',
    justifyContent: 'flex-start',
    paddingBottom: '3em',
    paddingLeft: '5%',
    gap: 10,
    paddingRight: "5%",
    marginLeft: 5,
    marginRight: 5,
    width: 600,
    display: "grid",
    gridTemplateColumns: "50% 50%"
    // [theme.breakpoints.between('300', '350')]: {
    //   width: 300,
    //   marginLeft: 10
    // },
    // [theme.breakpoints.between('350', '400')]: {
    //   width: 350,
    //   marginLeft: 10
    // },
    // [theme.breakpoints.between('400', '600')]: {
    //   width: 350,
    //   marginLeft: 30
    // },
  }
});
class Dount extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      summaryData: [],
      selectChart: 0,
      summaryDatas: [{ name: "", value: 0 }],
      name: "",
      value: "",
      borderColor: "",

    }
  }


  data1 = (value) => {
    this.setState({
      selectChart: value
    })
  }
  pieenter = (item) => {
    this.setState({ name: item.name, value: item.value, bordercolor: item.fill })
  }
  componentDidMount() {
    if (this.props.summaryData != undefined && this.props.summaryData != null) {
      let summary = this.props.summaryData.map((item) => {
        // var a = item.value.split("%")
        // var b = Number(a[0])
        return { name: item.text, value: item.value }
      })
      this.setState({
        summaryData: summary
      })
      // this.setState({
      //   summaryDatas: [{ name: "Banks - Private Sector", value: 23.8 }, { name: "Computer - Software", value: 23.8 }, { name: "Tradding", value: 23.8 }, { name: "Finance & Investment", value: 23.8 }, { name: "Refinries", value: 23.8 }]
      // })

    }
  }
  rendercustomtooltip = () => {
    return <CustomTooltip page={"barchart"} name={this.state.name} value={this.state.value} bordercolor={this.state.bordercolor} />
  }
  pieenter = (item) => {
    //
    this.setState({ name: item.name, bordercolor: item.fill, value: item.value })
  }



  render() {
    //
    const { classes } = this.props;
    return (
      <ErrorBoundary>
        {this.props.isTransactionDetailsLoading ? <> <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
          <div className={classes.cardHeading} style={{ fontSize: 21, fontWeight: 600, padding: "12px 15px" }}>{this.props.header}</div>
          <Skeleton className={classes.button} ></Skeleton>
        </div><div><Skeleton className={classes.chartskeleton}></Skeleton></div></> :
          <div >
            {this.props.header != undefined && <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
              <h2 className={[classes.cardHeading, this.props.headerStyle].join(" ")} style={{ fontSize: 21, fontWeight: 600, padding: "12px 15px" }}>{this.props.header}</h2>
              {this.props.showSwitch == undefined && <div className={classes.switchAllocationButton} >
                <Switch datas={value => this.data1(value)} />
              </div>}
            </div>}

            {this.state.selectChart == 0 ? this.props.data.length != 0 ?
              <div style={{ maxHeight: "434px", overflowX: "hidden", overflowY: "auto" }}>
                <div className={classes.chartWrap}>
                  <div className={classes.chartFluid} >
                    <ResponsiveContainer>
                      <PieChart width={500} height={300}>
                        <Pie
                          data={this.props.data}
                          outerRadius={"80%"} innerRadius={60}
                          dataKey="value"
                          onMouseEnter={this.pieenter}
                        >
                          {this.props.data.map((entry, index) => {
                            return (<Cell key={`cell-${index}`} fill={labels.chartColor[index % labels.chartColor.length]} />)
                          })}
                        </Pie>
                        <Tooltip content={this.rendercustomtooltip} wrapperStyle={{
                          paddingLeft: "30px", fontSize: "14px"
                        }} />
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div className={classes.root}>
                  <Grid container spacing={2}>
                    <div container item xs={2} spacing={5} className={classes.switchContentBlock}>
                      {/* <div style={{ padding: '15px', display: "flex", justifyContent: "space-evenly" }} > */}
                      {this.props.data.length !== 0 && this.props.data.map((item, index) => {
                        //  if (index % 2 != 0) {
                        return (
                          <CommonDescription name={item.name} value={item.value} color={labels.chartColor[index % labels.chartColor.length]} />
                        )
                      })}
                    </div>
                  </Grid>

                </div>
              </div> : <div style={{ padding: "8px" }}>
                <NoData height={"365px"} time={200} />
              </div> :
              <div className={classes.barChartCard}>
                <Barchart data={this.props.barchartdata != null && this.props.barchartdata != undefined ? this.props.barchartdata.length != 0 ? this.props.barchartdata.map((val) => {
                  //
                  var a = val.HoldPercentage.toFixed(2)
                  // return { "name": `${val.SectName}(${a}%)`, "value": a }
                  return { "name": val.SectName, "value": a }
                }) : [] : []}></Barchart>
              </div>
            }
          </div>}
      </ErrorBoundary>
    );
  }
}
const mapStateToProps = state => ({
  AssetData: state.TransactionDetails.transactionDetailsList.length == 0 ? [] : state.TransactionDetails.transactionDetailsList.mF_AssetAllocation != null ? state.TransactionDetails.transactionDetailsList.mF_AssetAllocation.map((item) => {
    // var a = item.value.split("%")
    // var b = Number(a[0])
    return { name: item.text, value: item.value }
  }) : [],

})
const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getAllInstrumentDetails, }, dispatch)
}
export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Dount));