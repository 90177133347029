import axios from 'axios'
import ApiValues from '../url/apiurl'
import moment from 'moment';
import labels from '../../utils/constants/labels';
import Networking from '../../utils/api/apiaccess';
/* name should be camel case in all function */
function getCancelOnlineSTP(ClientCode, FromBSESchemeCode, ToBSESchemeCode, BuySellType, TransactionMode, FolioNo, STPRegNo, IntRefNo,
    StartDate, FrequencyType, NoOfTransfers, InstAmount, InstUnit, FirstOrderToday, SubBrokerCode, EUINDeclaration, EUINNumber, Remarks
    , EndDate, SubBrokerARN, Filler1, Filler2, Filler3, Filler4, Filler5, IsAMC, ClientID, SchemeID, ToSchemeID, Source) {
    const BaseUrl = ApiValues.CancelOnlineSTPReport
    let data = {
        "ClientCode": ClientCode,
        "FromBSESchemeCode": FromBSESchemeCode,
        "ToBSESchemeCode": ToBSESchemeCode,
        "BuySellType": BuySellType,
        "TransactionMode": TransactionMode,
        "FolioNo": FolioNo,
        "STPRegNo": STPRegNo,
        "IntRefNo": IntRefNo,
        "StartDate": moment(StartDate).format("DD/MM/YYYY"),
        "FrequencyType": FrequencyType,
        "NoOfTransfers": NoOfTransfers,
        "InstAmount": InstAmount,
        "InstUnit": InstUnit,
        "FirstOrderToday": FirstOrderToday,
        "SubBrokerCode": SubBrokerCode,
        "EUINDeclaration": EUINDeclaration,
        "EUINNumber": EUINNumber,
        "Remarks": Remarks,
        "EndDate": "",
        "SubBrokerARN": SubBrokerARN,
        "Filler1": "",
        "Filler2": "",
        "Filler3": "",
        "Filler4": "",
        "Filler5": "",
        "IsAMC": IsAMC,
        "ClientID": ClientID,
        "SchemeID": SchemeID,
        "ToSchemeID": ToSchemeID,
        "Source": Source
    }
    return Networking.PostApi(BaseUrl, data,labels.apiKey.apiKeys).then(result => { return result })
        .catch(function (error) {
            return { "status": "F", "message": labels.messages.apierror }

            // 
        });

}
export default getCancelOnlineSTP
