import React, { Component } from "react";
import colors from '../../utils/colors/colors';
import labels from '../../utils/constants/labels';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
const styles = theme => ({
    container: {
        // marginTop: 75,
        backgroundColor: window.globalConfig.color.PRIMARY,
        // width: "100%",
        // height: "17vh",
        // marginTop: 60,
        // backgroundColor: window.globalConfig.color.PRIMARY,
    },
    card: {
        width: '60%',
        textAlign: "center",
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingTop: '1%',
        paddingBottom: '1%',
        // [theme.breakpoints.down('md')]: {
        //     paddingTop: '7%',
        // },
        // [theme.breakpoints.down('sm')]: {
        //     paddingTop: '10%',
        //     paddingBottom: '1%',
        // },
        // [theme.breakpoints.down('xs')]: {
        //     paddingTop: '15%',
        //     paddingBottom: '1%',
        // },
    },
    cardContent: {
        fontSize: '0.9em !important',
        // fontSize: 21,
        color: window.globalConfig.color.WHITE,
        lineHeight: '25px',
        fontWeight: 400,
        margin: '0.5% 0% 0% 0%',
        [theme.breakpoints.between('300', '600')]: {
            fontSize: 20,
            lineHeight: '20px',
        }
    },
    contentBlock: {
        display: "grid",
        gridTemplateColumns: "50% 50%",
        justifyItems:"end",
        gap:"14%"
     
    },
    h1FontColor: {
        color: window.globalConfig.color.WHITE,
        fontSize: '25px',
        margin: '0px 0px 10px 0px',
    },
    backStyle: {
        display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", marginRight: "5%",
        [theme.breakpoints.down('600')]: {
            alignItems: "end"
        },
    },
    backArrowStyle: {
        color: window.globalConfig.color.WHITE,
        [theme.breakpoints.down('600')]: {
            [theme.breakpoints.down('600')]: {
                margin: 0
            },
        }
    }
});
class Subheader extends Component {
    constructor() {
        super();
        this.state = {

        }
    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.card}>
                    
                    <div className={this.props.redirectURL!=undefined&& classes.contentBlock}>
                        {this.props.showImage ? <div style={{ display: "flex", flexDirection: "row", gap: 13, color: window.globalConfig.color.WHITE, alignItems: "center", justifyContent: "center" }}>{this.props.icon}<h1 style={{ color: window.globalConfig.color.WHITE, margin: 0 }}>{this.props.heading}</h1></div> :
                            <><h1 className={classes.h1FontColor}>{labels.StocksandTrade.finvat} {this.props.heading}</h1>
                                <p className={classes.cardContent}>{this.props.description}</p></>}
                       {this.props.redirectURL!=undefined&& <div className={classes.backStyle} style={{ opacity: 1, cursor: "pointer" }} onClick={this.props.redirectURL}>
                            <ArrowBackIos
                                color={window.globalConfig.color.WHITE}

                                className={classes.backArrowStyle}
                            />
                            <p
                                className={classes.backArrowStyle}
                            >
                                {"Back"}
                            </p>
                        </div>}
                    </div>
                </div>

            </div>
        )
    }
}
export default withStyles(styles)(Subheader);