import React from 'react';
import PropTypes from 'prop-types';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import NoData from '../nodata/nodata';
import Loader from '../loader/loader';
import Label from '../../utils/constants/labels';
import TableCell from '@material-ui/core/TableCell';
import { TableRow, TableFooter, colors } from '@material-ui/core';
import { connect } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import ErrorBoundary from '../Errorhandling/Errorhandle';
import color from "../../utils/colors/colors";
import { NegativeFinding } from '../../utils/commonfunction';
require('../../utils/stylesheets/portfolio/portpolio.css')
const useStyles = makeStyles((theme) => ({
    root: {
        display: "table",
        width: "100%",
        height: "100%", //Modified by Celsia
        borderSpacing: "5px",
        // fontFamily: "inherit",
        [theme.breakpoints.down('500px',)]: {
            display: "table",
            width: "100%",
            height: "30em",
            borderSpacing: "5px",
            // fontFamily: "inherit",

        },
    },
    firstcell: {
        width: "12%",
        paddingLeft: "15px",
        display: "table-cell",
        whiteSpace: 'nowrap',
        padding: 20,
        [theme.breakpoints.down("600")]: {
            paddingLeft: "unset",

        },
    },

    fourcell: {
        // paddingLeft: "10px",
        fontSize: "12px",
        fontWeight: "bold",
        color: window.globalConfig.color.BLACK_PRIMARY,
        // fontFamily: "inherit",
        display: "table-cell",
        whiteSpace: 'nowrap',

    },
    fithcell: {
        width: "22%",
        fontWeight: "normal",
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: "12px",
        // fontFamily: "inherit",
        display: "table-cell",
        paddingLeft: "20px",
        [theme.breakpoints.down("600")]: {
            paddingLeft: "unset",
        },
    },
    sixthcell: {
        width: "15%",
        fontWeight: "normal",
        color: window.globalConfig.color.LABLE_GRAY,
        fontSize: "12px",
        // fontFamily: "inherit",
        display: "table-cell"
    },

    value: {
        color: window.globalConfig.color.BLACK_PRIMARY,
        fontWeight: 500,
        fontSize: "14px",
        textTransform: "capitalize"
    },

    list: {
        flexDirection: 'row',
        marginBottom: 2,
        alignItems: "center",
        justifyContent: "flex-start",
        borderRadius: 10,
        boxShadow: "0 0 5px 0 #579ee820",
        backgroundColor: window.globalConfig.color.WHITE
    },

    chip: {
        backgroundColor: window.globalConfig.color.RosyBrown,
        fontWeight: 500,
        color: window.globalConfig.color.BLACK_PRIMARY,
        // borderRadius : "10px"
    },

    chipSpan:{
        overflow:"hidden",
        whiteSpace:"breal-spaces"
    },

    padding: {
        padding: "5px",
        marginRight: 20
    },
    desktopResponsive: {
        display: 'block !important',

        [theme.breakpoints.down("600")]: {
            display: 'none !important',
        },
    },
    mobileResponsive: {
        display: 'none !important',
        [theme.breakpoints.down("600")]: {
            display: 'block !important',
        },
    },
    leftResponsive: {
        padding: "5px",
        textAlign: "left",
        fontSize: "12px"
    },
    rightResponsive: {
        padding: "5px",
        textAlign: "right",
        fontSize: "12px"

    },
    chipResponsive: {
        padding: "5px",
    },
    listResponsive: {
        flexDirection: 'row',
        marginBottom: 2,
        alignItems: "center",
        justifyContent: "flex-start",
        borderRadius: 10,
        boxShadow: "0 0 5px 0 #579ee820",
        backgroundColor: window.globalConfig.color.WHITE,
        padding: "5px"
    },
    noDataText: {
        //height:window.innerHeight,
        marginTop: '100px',
        paddingBottom: '50px'
    }
    // upcomingResponsiveAmount:{
    //     paddingLeft: "0px",
    // },
}));
function PortfolioList(props) {
    const classes = useStyles();

    return (
        <ErrorBoundary>
            <div className="list-Table">
                <div className={classes.desktopResponsive}>
                    <table className={classes.root}>
                        <tbody>
                            {/* {console.log(props.data)} */}
                            {props.isLoading ? <Loader page="reportmodel"></Loader> : props.data.length > 0
                                ? (
                                    props.data?.map((value, index) => {
                                        return (

                                            <tr className={classes.list} key={index}>
                                                <td style={{ textAlign: "left" }} className={[classes.fourcell, classes.firstcell].join(" ")}>
                                                    <Typography className="bold-table">{value.Date}</Typography>
                                                </td>
                                                <td style={{ textAlign: "left" }} className={classes.fourcell}>
                                                    {/* <Typography className={classes.chip} align="center"> {value.TransactionType}</Typography> */}
                                                    <Chip size="medium" className={classes.chip} label={<span className={classes.chipSpan}>{value.Type}</span>} />
                                                </td>
                                                <td style={{ textAlign: "left", paddingLeft: 30, whiteSpace:"normal" }} className={classes.fourcell}>
                                                    <Typography align="left" className="bold-table">{value.Scheme}</Typography>
                                                </td>
                                                <td style={{ textAlign: "left" }} className={classes.fourcell}>
                                                    <Typography className="bold-table">{NegativeFinding(value.Rupee, "", "", "", "rupee")}</Typography>
                                                </td>
                                                <td style={{ textAlign: "left", paddingLeft: 110 }} className={classes.fithcell}>
                                                    <Typography align="left" >{value.Amc || "-"}</Typography>
                                                </td>

                                            </tr>

                                        )
                                    })

                                )
                                : (
                                    <tr>
                                        <td>
                                            <div className={classes.noDataText}>
                                                <NoData time={1000} />
                                            </div>
                                        </td>
                                    </tr>
                                )

                            }
                        </tbody>
                    </table>
                </div>
                <div className={classes.mobileResponsive}>
                    <table className={classes.root}>
                        <tbody >
                            {props.data?.length > 0
                                ? (
                                    props.data?.map((value, index) => {
                                        return (


                                            <div className={classes.listResponsive} key={index}>
                                                <div style={{ display: "flex", flexDirection: "row" }}>
                                                    <div className={classes.leftResponsive}>
                                                        <Typography className="bold-value">{value.Date}</Typography>
                                                    </div>
                                                    <div className={classes.chipResponsive} >
                                                        <Chip size="small" className={classes.chip} label={value.Type} />
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                    <div className={classes.leftResponsive}>
                                                        <Typography align="left" className="bold-value">{value.Scheme}</Typography>
                                                    </div>
                                                    <div className={classes.rightResponsive} >
                                                        <Typography className="bold-value">{Label.currency.Rupee}{value.Rupee}</Typography>
                                                    </div>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                                    <div className={classes.leftResponsive}>
                                                        <Typography align="left" >{value.Amc || "-"}</Typography>
                                                    </div>
                                                    {/* {value.Name!=undefined&&
                                                <div className={classes.rightResponsive} >
                                                    <Typography className="bold-value">{value.Name}</Typography>
                                                </div>} */}
                                                </div>
                                            </div>


                                        )
                                    })
                                )
                                : (
                                    <tr>
                                        <td>
                                            <NoData height={"310px"} time={1000} />
                                        </td>
                                    </tr>

                                )

                            }
                        </tbody>

                    </table>

                </div>
            </div>
        </ErrorBoundary >

    )
}
export default connect(null, null)(PortfolioList);
