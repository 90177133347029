import * as types from '../../constants/actionsType'
import labels from '../../../utils/constants/labels';
import { NegativeFinding, replaceNull } from '../../../utils/commonfunction';
const initialState = {
    assetWisePortfolio: [],
    isLoading: false,
    productTable: [],
    productChart: [],
    assetChart: [],
    nonFinancial: []
}
const color = labels.chartColor
const reducer = (state = initialState, action) => {
    switch (action.type) {
        case types.PORTFOLIO_ALLOCATION_EXPAND:
            let newArray = [...action.data];
            //this.state.amcList=""
            newArray[action.rowId] = {
                ...newArray[action.rowId],
                isExpand: !newArray[action.rowId].isExpand,
            };
            return {
                ...state,
                assetWisePortfolio: newArray
            }

        case types.ASSET_PORTFOLIO_ALLOCATION:

            let grp = action.payload == undefined || action.payload == null || action.payload.length == 0 || action.payload.table2 == null || action.payload.table2.length == 0 ? [] : replaceNull(action.payload.table2, "NA").filter((ele, ind) => ind === replaceNull(action.payload.table2, "NA").findIndex(elem => elem.AssetClass === ele.AssetClass))

            let grp_product = grp.length != 0 ? grp.map((item) => {
                return {
                    category: item.AssetClass,
                    amount: item.AssetClassValue,
                    categoryPercentage: '-',
                    holdingPercentage: item.AssetClassWeightage,
                    grandTotal: item.GrandTotal,
                    isExpand: true,
                    color: action.payload == undefined || action.payload == null || action.payload.length == 0 || action.payload.table1 == null || action.payload.table1.length == 0 ? window.globalConfig.color.PRIMARY : action.payload.table1.filter((value) => value.AssetClass == item.AssetClass).length != 0 ? replaceNull(action.payload.table1, "NA").filter((value) => value.AssetClass == item.AssetClass)[0].ColorCode == "NA" ? window.globalConfig.color.PRIMARY : replaceNull(action.payload.table1, "NA").filter((value) => value.AssetClass == item.AssetClass)[0].ColorCode : window.globalConfig.color.PRIMARY,
                    rowitem: action.payload.table2.filter((value) => value.AssetClass == item.AssetClass).map((item) => {
                        return {
                            category: item.Category,
                            amount: item.Value,
                            categoryPercentage: item.Weightage,
                            holdingPercentage: "-"
                        }
                    })
                }
            }) : []
            return {
                ...state,
                nonFinancial: replaceNull(action.payload.table6, "NA"),
                assetWisePortfolio: grp_product.length != 0 ? grp_product : [],
                productTable: action.payload.table3 != undefined && action.payload.table3 != null && action.payload.table3.length != 0 ? replaceNull(action.payload.table3, "NA").map((i, index) => {
                    return {
                        scheme: [], ProductID: i.ProductID, isFinancial: replaceNull(action.payload.table5, "NA").filter((item) => item.ProductName.includes(i.Product)).length != 0 ? replaceNull(action.payload.table5, "NA").filter((item) => item.ProductName.includes(i.Product))[0].hasOwnProperty("isFinancial") ? item.ProductName.includes(i.Product)[0].IsFinancial : true : true, xirrPercentage: i.XIRR, investValue: i.InvestmentAmt, marketValue: i.CurrentValue, unrealised: i.GainLoss, name: i.Product, color: replaceNull(action.payload.table5, "NA").filter((item) => item.ProductName == i.Product).length != 0 ? replaceNull(action.payload.table5, "NA").filter((item) => item.ProductName == i.Product)[0].ColorCodes == "NA" ? window.globalConfig.color.PRIMARY : replaceNull(action.payload.table5, "NA").filter((item) => item.ProductName == i.Product)[0].ColorCodes : window.globalConfig.color.PRIMARY
                    }
                    //  replaceNull(action.payload.table1, "NA").filter((item) => item.AssetClass == i.Product).length != 0 ? replaceNull(action.payload.table1, "NA").filter((item) => item.AssetClass == i.Product)[0].ColorCode : window.globalConfig.color.PRIMARY }
                }) : [],
                productChart: action.payload.table3 != undefined && action.payload.table3 != null && action.payload.table3.length != 0 ? replaceNull(action.payload.table3, "NA").map((i, index) => {
                    return { name: i.Product, value: Math.round(i.CurrentValue), displayValue: NegativeFinding(i.CurrentValue, "", "", "", "rupee"), color: replaceNull(action.payload.table5, "NA").filter((item) => item.ProductName == i.Product).length != 0 ? replaceNull(action.payload.table5, "NA").filter((item) => item.ProductName == i.Product)[0].ColorCodes == "NA" ? window.globalConfig.color.PRIMARY : replaceNull(action.payload.table5, "NA").filter((item) => item.ProductName == i.Product)[0].ColorCodes : window.globalConfig.color.PRIMARY }
                }) : [],
                assetChart: action.payload != undefined && action.payload != null && action.payload.length != 0 && action.payload.table2 != null && action.payload.table2.length != 0 ? replaceNull(action.payload.table2, "NA").map((i, index) => {
                    return { name: i.AssetClass, value: Math.round(i.AssetClassWeightage), displayValue: NegativeFinding(i.AssetClassWeightage, "", "", "", "percentage"), color: replaceNull(action.payload.table1, "NA").filter((item) => item.AssetClass == i.AssetClass).length != 0 ? replaceNull(action.payload.table1, "NA").filter((item) => item.AssetClass == i.AssetClass)[0].ColorCode == "NA" ? window.globalConfig.color.PRIMARY : replaceNull(action.payload.table1, "NA").filter((item) => item.AssetClass == i.AssetClass)[0].ColorCode : window.globalConfig.color.PRIMARY }
                }) : [],
                isLoading: false
            };
        case types.LOADER_ASSET_PORTFOLIO_ALLOCATION: {
            return {
                ...state,
                isLoading: action.payload
            }
        }
        case types.EMPTY_ASSET_PORTFOLIO_ALLOCATION: {
            return {
                ...state,
                assetWisePortfolio: [],
                productChart: [],
                assetChart: [],
                productTable: [],
                assetWisePortfolio: [],
                productChart: [],
                assetChart: [],
                productTable: [],
                assetWisePortfolio: [],
                isLoading: false,
                nonFinancial: []
                
            }
        }
        default:
            return state
    }
}
export default reducer