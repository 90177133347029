import * as types from '../../constants/actionsType'
const initialState = {
    questionOrder: [],
    isQuestionOrderLoading: false
}
const reducer = (state = initialState, action) => {
    switch (action.type) {       
        case types.RISK_QUESTION:         
            return {
                ...state,
                questionOrder: action.payload != null ? action.payload : state.questionOrder ,
                isQuestionOrderLoading: false
            }
        case types.LOADER_RISK_QUESTION:
            return {
                ...state,
                isQuestionOrderLoading: true
            }
        default:
            return state
    }
}
export default reducer
